import React from 'react';
import { lang } from "../js/helpers/utils/languages/language";
import PubSub from 'pubsub-js'
const $= window.$;
export default class SingleSavedReportFilters extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reportId: props.reportId,
            filterfields: props.filterfields,
            hierarchy: props.hierarchy,
            customfield: props.customfield
        }
        this.singlemodalapply= this.singlemodalapply.bind(this);
    }
    componentDidMount(){
        $('.singlereportfiltercheckbox').click(function(e){
            e.stopPropagation();
        })
    }
    render() {
        return (
            <div className="modal fade" id="mySmallModal0" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-sm modal-sm-400 modal-dialog-sm-center modal-dialog-center">
                    <div className="modal-content">
                        <div className="modal-header boc-modal-hf-padding">
                            <button type="button" className="close white" id="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                            <h4 className="modal-title"><i className="fa fa-filter"></i> {lang["reports.choosefilter"]}</h4>
                        </div>
                        <div className="modal-body scrollsetnew">
                            <div className="container-fluid marg-top-5">
                                <input className="form-control form-control-single-filters search-image" placeholder={lang["reports.search"]} id="searchinput" type="text" onChange={this.props.onSearch} />
                            </div>
                            <div className="container-fluid">
                                <div className="row singlefiltercontent filtercontent dobyfilteritems">
                                    
                                    {
                                        (this.state.reportId === undefined) ? (
                                            <>
                                                {
                                                    this.state.filterfields.map((values) => {
                                                        return (
                                                            <ul className="list-group scrollsetul">
                                                                {
                                                                    values.map((valueObject, index) => {
                                                                        var displayname = valueObject.columnDisplayName.replace(/([;?&,%.+*~\':"!^$[\]()<=>`|\/@])/g, "");
                                                                        return (
                                                                            <>
                                                                                {(index === 0) ? (<li className="boc-list-item"><label>{valueObject.tableName}</label></li>) : null}
                                                                                <li className="boc-list-item filterliitem" data-type={valueObject.columnDisplayType}>
                                                                                    <label title={valueObject.columnDisplayName}>
                                                                                        <input type="checkbox" className="singlereportfiltercheckbox" id="singlereportfiltercheckbox" data-type={valueObject.columnDisplayType} data-parent={valueObject.tableName} data-child={valueObject.columnName} name={displayname} />
                                                                                        <span className="itemtype">
                                                                                        {(valueObject.tableDisplayType == 'date' || valueObject.tableDisplayType == 'datetime') ? (
                                                                                            <i className="fa fa-calendar"></i>) : (valueObject.tableDisplayType == 'string') ? ('ABC')
                                                                                                : (valueObject.tableDisplayType == 'number') ? ('123')
                                                                                                    : (valueObject.tableDisplayType == 'month') ? (<i className="fa fa-calendar"></i>)
                                                                                                        : (valueObject.tableDisplayType == 'day') ? (<i className="fa fa-calendar"></i>) : ('ABC')}
                                                                                        </span>{valueObject.columnDisplayName}</label></li>
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        );
                                                    })
                                                }
                                            </>
                                        ) : (this.state.reportId === -1 && this.state.hierarchy == "true") ? (
                                            <>
                                                {
                                                    this.state.filterfields.map((values) => {
                                                        return (
                                                            <ul className="list-group scrollsetul">
                                                                {
                                                                    values.map((valueObject, i) => {
                                                                        var displayname = valueObject.columnDisplayName.replace(/([;?&,%.+*~\':"!^$[\]()<=>`|\/@])/g, "");
                                                                        return (
                                                                            <>
                                                                                {(i === 0) ? (<li className="boc-list-item">{valueObject.tableName}</li>) : null}
                                                                                <li className="boc-list-item filterliitem" data-type={valueObject.tableDisplayType}>
                                                                                    <label title={valueObject.columnDisplayName}>
                                                                                        <input type="checkbox" className="singlereportfiltercheckbox" id="singlereportfiltercheckbox" data-type={valueObject.tableDisplayType} data-parent={valueObject.tableName} data-child={valueObject.columnName} name={displayname} />
                                                                                        <span className="itemtype">
                                                                                        {
                                                                                            (valueObject.tableDisplayType == 'date' || valueObject.tableDisplayType == 'datetime') ? (
                                                                                                <i className="fa fa-calendar"></i>
                                                                                            ) : (valueObject.tableDisplayType == 'string') ? (
                                                                                                'ABC'
                                                                                            ) : (valueObject.tableDisplayType == 'number') ? (
                                                                                                '123'
                                                                                            ) : (valueObject.tableDisplayType == 'month') ? (
                                                                                                <i className="fa fa-calendar"></i>
                                                                                            ) : (valueObject.tableDisplayType == 'day') ? (
                                                                                                <i className="fa fa-calendar"></i>
                                                                                            ) : ('ABC')
                                                                                        }
                                                                                        </span>{valueObject.columnDisplayName}
                                                                                    </label></li>
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        )
                                                    })
                                                }
                                            </>
                                        ) : (this.state.reportId === -1 && this.state.hierarchy == "false") ? (
                                            <ul className="list-group scrollsetul">
                                                {this.state.filterfields.map((valueObject) => {
                                                    var displayname = valueObject.columnDisplayName.replace(/([;?&,%.+*~\':"!^$[\]()<=>`|\/@])/g, "");

                                                    return(<li className="boc-list-item filterliitem" tite={valueObject.tableName} data-type={valueObject.tableDisplayType}>
                                                        <label title={valueObject.columnDisplayName}>
                                                            <input type="checkbox" className="singlereportfiltercheckbox" id="singlereportfiltercheckbox" data-type={valueObject.tableDisplayType} data-parent={valueObject.tableName} data-child={valueObject.columnName} name={displayname} />
                                                            <span className="itemtype">
                                                            {(valueObject.tableDisplayType == 'date' || valueObject.tableDisplayType == 'datetime') ? (
                                                                <i className="fa fa-calendar fa-filter-icon"></i>)
                                                                : (valueObject.tableDisplayType == 'string') ? ('ABC')
                                                                    : (valueObject.tableDisplayType == 'number') ? ('123')
                                                                        : (valueObject.tableDisplayType == 'month') ? (<i className="fa fa-calendar"></i>)
                                                                            : (valueObject.tableDisplayType == 'day') ? (<i className="fa fa-calendar"></i>) : ('ABC')}
                                                            </span>{valueObject.columnDisplayName}</label></li>);

                                                })}
                                            </ul>
                                        ) : (this.state.reportId != -1 && this.state.hierarchy == "true") ? (
                                            <>
                                                {
                                                    this.state.filterfields.map((values) => {
                                                        return (<ul className="list-group scrollsetul">
                                                            {
                                                                values.map((valueObject, i) => {
                                                                    var displayname = valueObject.columnDisplayName.replace(/([ ;?&,%.+*~\':"!^$[\]()<=>`|\/@])/g, "");
                                                                    return (
                                                                        <>
                                                                            {(i === 0) ? (<li className="boc-list-item">{valueObject.tableName}</li>) : null}
                                                                            <li className="boc-list-item filterliitem" data-type={valueObject.tableDisplayType}>
                                                                                <label title={valueObject.columnDisplayName}>
                                                                                    <input type="checkbox" className="singlereportfiltercheckbox" id="singlereportfiltercheckbox" data-type={valueObject.tableDisplayType} data-parent={valueObject.tableName} data-child={valueObject.columnName} name={displayname} />
                                                                                    <span className="itemtype">
                                                                                    {(valueObject.tableDisplayType == 'date' || valueObject.tableDisplayType == 'datetime') ? (
                                                                                        <i className="fa fa-calendar fa-filter-icon"></i>)
                                                                                        : (valueObject.tableDisplayType == 'string') ? ('ABC')
                                                                                            : (valueObject.tableDisplayType == 'number') ? ('123')
                                                                                                : (valueObject.tableDisplayType == 'month') ? (<i className="fa fa-calendar"></i>)
                                                                                                    : (valueObject.tableDisplayType == 'day') ? (<i className="fa fa-calendar"></i>) : ('ABC')}
                                                                                    </span>{valueObject.columnDisplayName}
                                                                                </label>
                                                                            </li>
                                                                        </>
                                                                    );
                                                                })
                                                            }
                                                        </ul>);
                                                    })
                                                }
                                            </>
                                        ) : (this.state.reportId != -1 && this.state.hierarchy == "false") ? (
                                            <ul className="list-group scrollsetul">
                                                {
                                                    this.state.filterfields.map((valueObject, i) => {
                                                        var displayname = valueObject.columnDisplayName.replace(/([;?&,%.+*~\':"!^$[\]()<=>`|\/@])/g, "");
                                                        return (
                                                            <li key={i} className="boc-list-item filterliitem" tite={valueObject.tableName} data-type={valueObject.tableDisplayType}>
                                                                <label title={valueObject.columnDisplayName}>
                                                                    <input type="checkbox" className="singlereportfiltercheckbox" id="singlereportfiltercheckbox" data-type={valueObject.tableDisplayType} data-parent={valueObject.tableName} data-child={valueObject.columnName} name={displayname} />
                                                                    <span className="itemtype">
                                                                    {(valueObject.tableDisplayType == 'date' || valueObject.tableDisplayType == 'datetime') ? (
                                                                        <i className="fa fa-calendar"></i>)
                                                                        : (valueObject.tableDisplayType == 'string') ? ('ABC')
                                                                            : (valueObject.tableDisplayType == 'number') ? ('123')
                                                                                : (valueObject.tableDisplayType == 'month') ? (<i className="fa fa-calendar"></i>)
                                                                                    : (valueObject.tableDisplayType == 'day') ? (<i className="fa fa-calendar"></i>) : ('ABC')}
                                                                    </span>{valueObject.columnDisplayName}</label></li>
                                                        );
                                                    })
                                                }
                                            </ul>
                                        ) : (<></>)
                                    }
                                    {
                                        (this.state.customfield != 0) ? (
                                            <ul className="list-group scrollsetul">
                                                {
                                                    this.state.customfield.map((customfield,i) => {
                                                        var displayname = customfield.name.replace(/([;?&,%.+*~\':"!^$[\]()<=>`|\/@])/g, "");
                                                        return (
                                                            <li key={i} className="boc-list-item filterliitem" tite={customfield.name} data-type={customfield.columnDisplayType}>
                                                                <label title={customfield.name}>
                                                                    <input type="checkbox" className="singlereportfiltercheckbox" id="singlereportfiltercheckbox" data-type={customfield.columnDisplayType} data-parent={customfield.name} data-child={customfield.name} name={displayname} />
                                                                    <span className="itemtype fx">
                                                                    {(customfield.columnDisplayType == 'date' || customfield.columnDisplayType == 'datetime') ? (<i className="fa fa-calendar"></i>) :
                                                                        (customfield.columnDisplayType == 'string') ? ('ABC') :
                                                                            (customfield.columnDisplayType == 'number') ? ('123') :
                                                                                (customfield.columnDisplayType == 'month') ? (<i className="fa fa-calendar"></i>) :
                                                                                    (customfield.columnDisplayType == 'day') ? (<i className="fa fa-calendar"></i>) : ('ABC')}</span>{customfield.name}</label></li>

                                                        );
                                                    })
                                                }

                                            </ul>
                                        ) : (<></>)
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer boc-modal-hf-padding">
                            <button type="button" className="btn btn-bird" id="btn-bird" data-dismiss="modal"><i className="fa fa-times"></i> {lang["reports.cancelbtn"]}</button>
                            <button type="button" className="btn btn-bird singlemodalapply" onClick={this.singlemodalapply} id="singlemodalapplyfilter"><i className="fa fa-check"></i> {lang["reports.applybtn"]}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    singlemodalapply(e){
    e.stopPropagation();
    PubSub.publish("singleFieldFilter");
    }
}