import React from 'react';
import { lang } from "../js/helpers/utils/languages/language";
export default class CreateConnectionHtml extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return(
            <>
            {/* <!-- createconnection.js --> */}
            <div className="connection-backdrop"></div>
            <div className="connection-container">
                <div 
                    className="closenewscopeconnection" 
                    id="closeconnectionpop" 
                    onClick={this.props.close}
                >
                    <i className="fa fa-times"></i>
                </div>
                <div className="otherconnections">
                    <h3>
                        <i className="fa fa-link marg-righ-10"></i>
                        {lang["datahub.filestitle"]}
                    </h3>
                    <div className="container-fluid advancedcontainer">
                        <div className="row">
                            <div className="col-xs-12">
                                <input
                                    className="search-image"
                                    placeholder={lang["datahub.searchfile"]}
                                    id="connectionsearch"
                                    type="text"
                                    onKeyUp={this.props.search}
                                />
                            </div>
                        </div>
                        <div className="row advancedconnections">
                            {
                                Object.keys(this.props.frequentlyused).map((data, index) => {
                                    return (
                                        <div className="col-sm-12" key={index}>
                                            <h4>
                                                {(this.props.frequentlyused[data][0].displayName === "To Flat File") ? (
                                                    <><i className="fa fa-file fa-solid"></i> {lang["datahub.flatfiles"]}</>) : null}
                                                {(this.props.frequentlyused[data][0].displayName === "To DataBase") ? (
                                                    <><i className="fa fa-database fa-solid"></i> {lang["datahub.database"]}</>) : null}
                                            </h4>
                                            <ul>
                                                {
                                                    this.props.frequentlyused[data].map((value1, index) => {
                                                        var cname=value1.connectionDisplayname
                                                    if(value1.connectionDisplayname=='EXCEL'||value1.connectionDisplayname=='excel'){
                                                      cname="EXCEL (xls / xlsx)"
                                                        }
                                                    if(value1.connectionDisplayname=='CSV'||value1.connectionDisplayname=='csv'){
                                                    cname="CSV (csv / txt / tsv)"
                                                              }
                                
                                                        return (
                                                            <li 
                                                                className="connectionrequest" 
                                                                connectionid={value1.connectionid} 
                                                                schemaname={value1.name} 
                                                                name={value1.type} 
                                                                displayname={value1.connectionDisplayname}
                                                                onClick={this.props.createnewsource}
                                                                key={index}
                                                            >
                                                                <img src={require(`../images/birdimages/${value1.name.toLocaleLowerCase()}.png`)} />
                                                                {cname}
                                                            </li>
                                                        );
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    );
                                })
                            }
                            {
                                Object.keys(this.props.notfrequentlyused).map((data, index) => {
                                    return (
                                        <div className="col-sm-12" key={index}>
                                            <h4>
                                                {(this.props.notfrequentlyused[data][0].displayName === "To Big Data") ? (
                                                    <><i className="fa fa-server fa-solid"></i> {lang["datahub.bigdata"]}</>) : null}
                                                {(this.props.notfrequentlyused[data][0].displayName === "To Service") ? (
                                                    <><i className="fa fa-cogs fa-solid"></i> {lang["datahub.services"]}</>) : null}
                                                {(this.props.notfrequentlyused[data][0].displayName === "To Streaming") ? (
                                                    <><i className="fa fa-line-chart fa-solid"></i> {lang["datahub.streaming"]}</>) : null}
                                                {(this.props.notfrequentlyused[data][0].displayName === "To Others") ? (
                                                    <><i className="fa fa-link fa-solid fa-flip-horizontal"></i>{lang["datahub.others"]}	</>) : null}
                                            </h4>
                                            <ul>
                                                {
                                                    this.props.notfrequentlyused[data].map((value1, index) => {
                                                        return (
                                                            <li 
                                                                key={index}
                                                                className="connectionrequest" 
                                                                connectionid={value1.connectionid} 
                                                                schemaname={value1.name} 
                                                                name={value1.type} 
                                                                displayname={value1.connectionDisplayname}
                                                                onClick={this.props.createnewsource}
                                                            >
                                                                <img src={require(`../images/birdimages/${value1.name.toLocaleLowerCase()}.png`)} />
                                                                {value1.connectionDisplayname}
                                                            </li>
                                                        );
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
        );
    }
}