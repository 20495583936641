import React from "react";
import ReactDOM from 'react-dom'
import { getMessage } from '../js/helpers/utils/BirdMessage';
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import * as TabInfo from "../js/helpers/utils/tabinfo";
import ViewStoryBookReportView from "../ViewStoryboard/Storyboard.jsx"
import SendRequest from '../SendRequest';
import ViewSaveStoryBookModel from './savestorybookmodel'
import ViewStorybookHeaderTemplate from './viewstorybookheader.jsx'
import ViewStoryBookNewTabModel from './viewstorybooknewtabmodel'
import ShareReportModal from '../ViewStoryboard/sharereportmodal'

const $ = window.$;
export default class StrorybookHeaderView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicdiv: props.dynamicdiv,
            savedcollection: props.savedcollection,
            isSaved: props.isSaved,
            title: props.title,
            features: props.features
        }
        this.render();
    }
    render() {
        ReactDOM.render(<ViewStorybookHeaderTemplate showsharemodel={this.showsharemodel} refreshtabs={this.refreshtabs} savestorybook={this.savestorybook} createnewstorybooktab={this.createnewstorybooktab} title={this.state.title} />, document.getElementById(this.state.dynamicdiv));
        return (<div></div>);
    }
    createnewstorybooktab = (e) => {
        var that = this;
        that.viewStoryBookNewTabModel = new ViewStoryBookNewTabModel({
            props: that.props
        });
    }
    savestorybook = () => {
        var savestorybookmodel = new ViewSaveStoryBookModel({
            options: this.props,
            savedcollection: this.state.savedcollection
        });
    }
    refreshtabs = (e) => {
        e.preventDefault();
        var that = this;
        this.state.features = ChartInfo.functions.getAllFeatures();
        var tabid;
        if (TabInfo.functions.getCurrentTabId() != undefined) {
            tabid = TabInfo.functions.getCurrentTabId();
        }
        if (tabid != undefined && tabid != null) {
            var tabData = ChartInfo.functions.getStoryBookTabDetails(tabid);

            TabInfo.functions.updateTabFiltersBeforeRefresh(tabid, ChartInfo.functions.getFilters(false));
            var tempObj = {};
            //				var drilldownChartNo=ChartInfo.functions.getDrillDownChartNumber();
            //				if (drilldownChartNo!=undefined && drilldownChartNo!=null && drilldownChartNo!="") {
            //					if(drilldownChartNo.split("_")[0]==tabid)
            //					tempObj[drilldownChartNo]=sessionStorage.getItem("drilldown"+drilldownChartNo);
            //				}
            TabInfo.functions.updateDrilldownFilterFromSession(tabid, tempObj);

            var reportid = tabData.reportId;
            if (tabData != undefined && Object.keys(tabData).length != 0) {
                var dataModelId = tabData.dataModelId
                var tabid = tabData.id;
                var reportType = tabData.reportType;

                $("#newtabcontainerid .tabheadli").removeClass("active");
                $('a#storybooktab' + tabid).parent().addClass("active");
                $(".tab-pane.storybookpagecontent").removeClass("active");
                $('#storybooktabcontent' + tabid).addClass("active");
                $(".tab-pane.storybookpagecontent .rightfilter").attr('id', "filteraccordiontemp");

                if (reportType != undefined && reportType == "ml_storyboard") {
                    // require(['views/viewmlmodel/viewsavedmlmodel'], function (ViewMLReportView) {
                    //     that.viewmlreportview = new ViewMLReportView({
                    //         reportId: reportid,
                    //         isDirect: 0,
                    //         features: that.state.features,
                    //         datamodelId: dataModelId,
                    //         el: 'storybooktabcontent' + tabid,
                    //         isFromStoryBook: true,
                    //         tabid: tabid
                    //     });
                    // });
                } else if (reportType != undefined && reportType == "smartinsight_storyboard") {
                        ReactDOM.unmountComponentAtNode(document.getElementById('storybooktabcontent' + tabid));
                        ReactDOM.render(<ViewStoryBookReportView
                            reportId={reportid}
                            isDirect={0}
                            features={that.state.features}
                            el={'storybooktabcontent' + tabid}
                            isFromStoryBook={true}
                            isFromSavedSmartInsight={true}
                            isFromSmartInsigt={true}
                            datamodelId={dataModelId}
                            tabid={tabid} />, document.getElementById('storybooktabcontent' + tabid));

                        $('#storybooktabcontent' + parseInt(tabid) + ' .quickfiltercontainer').attr("tab-id", tabid);
                        $(".tab-pane.storybookpagecontent .rightfilter").attr('id', "filteraccordiontemp");
                        $('#storybooktabcontent' + parseInt(tabid) + '.tab-pane.storybookpagecontent.active .tab-filters-container>.rightfilter').attr('id', "filteraccordion");
                } else {
                    ReactDOM.unmountComponentAtNode(document.getElementById('storybooktabcontent' + tabid));
                    ReactDOM.render(<ViewStoryBookReportView
                        reportId={reportid}
                        isDirect={0}
                        features={that.state.features}
                        el={'storybooktabcontent' + tabid}
                        isFromStoryBook={true}
                        tabid={tabid} />, document.getElementById('storybooktabcontent' + tabid));

                    //  TabInfo.functions.updateStoryBookViews(tabid, viewStoryBookReportView);
                    $('#storybooktabcontent' + parseInt(tabid) + ' .quickfiltercontainer').attr("tab-id", tabid);
                    $(".tab-pane.storybookpagecontent .rightfilter").attr('id', "filteraccordiontemp");
                    $('#storybooktabcontent' + parseInt(tabid) + '.tab-pane.storybookpagecontent.active .tab-filters-container>.rightfilter').attr('id', "filteraccordion");
                }
            }
            /*else {
                $(".tab-pane.storybookpagecontent .rightfilter").attr('id',"filteraccordiontemp");
                $("#storybooktabcontent"+tabid+".tab-pane.storybookpagecontent.active .tab-filters-container>.rightfilter").attr('id',"filteraccordion");
                //	$("#storybooktabcontent"+tabid+".tab-pane.storybookpagecontent.active .tab-filters-container>.rightfilter")
                var view = viewStorybookViews[tabid];
                console.log("test");
                if(view != undefined){
                    view.updateChartInfo(tabid);
                }
            }*/
        }
    }
    showsharemodel=()=>{
			if(!this.state.isSaved){
				$('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
				$('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
				$('.birdmessagebuttons').removeClass('show');
				$('.birdmessagebuttons').addClass('hide');
				$('.birdmessage-center').addClass("message-top-in");
				$('.details').removeClass('show');
				$('.details').addClass('hide');
				$('.birdmessage h2').empty().append('Information');
				$('.birdmessage-info').empty().text(getMessage('BirdInformation01'));
			}
			else{
                var storybookId= 0;
                var fragmentarray = window.location.pathname.split("/");
                if(fragmentarray.length==5){
                    storybookId= atob(fragmentarray[4]);
                }
				this.shareReportModel = new ShareReportModal({
					reportId:0,
					singlereport:false,
					dimensions:[],
					sharedFilters:[],
					schemaId: "",
					isViewModel:true,
					isFromStorybook:true,
					storybookId:storybookId,
					el:"storybooksharediv"
				});
				
			}
    }
}