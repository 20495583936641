export var customStoryboardInfo = {};
export var reportid= {};
export var customStoryboardchartdetails=[]
export var customstroyboardReportColumns=[]

export var functions = {


    setCustomStoryboardInfo: function (reportid, tabInfo) {
        customStoryboardInfo[reportid] = tabInfo;
    },
    getCustomStoryboardInfo: function (chartnumber) {
        return customStoryboardInfo[chartnumber];
    },
    setCustomStoryboardchartdetails: function (tabInfo) {
        customStoryboardchartdetails.push(tabInfo)
    },
    getCustomStoryboardchartdetails: function () {
        return customStoryboardchartdetails;
    },
    deleteCustomStoryboardchartdetails: function () {
        customStoryboardchartdetails=[]
    },
    setCustomStoryboardInfoWithchartnumber: function (chartnumber, tabInfo) {
        customStoryboardInfo[chartnumber] = tabInfo;
    },
    getCustomStoryboardInfoWithchartnumber: function (chartnumber) {
        return customStoryboardInfo[chartnumber];
    },
     deleteChartDetails: function (chartnumber) {
        delete customStoryboardchartdetails[chartnumber];
        delete customstroyboardReportColumns[chartnumber];
        console.log("deletd");
    },
    setCustomstroyboardReportColumns: function (reportColumns) {
        customstroyboardReportColumns.push(reportColumns)
    },
    getCustomstroyboardReportColumns: function () {
        return customstroyboardReportColumns;
    }
 
};
