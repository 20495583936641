import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'underscore';
import PubSub from 'pubsub-js'
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import SendRequest from '../../SendRequest';
import SmartInsightsTemplate from "./smartinsights.jsx"
import SmartInsightHeaderView from "./smartinsightheaderview"
import ViewReportFilterView from '../viewreportfilter';
import ViewReportAddFilterView from "../viewreportaddfilterview";
import * as SmartCharts from './smartinsightcharts'
import SmartInsightsSimulationView from './smartinsightssimulationview'
import SmartInsightsSettingsView from './smartinsightssettingsview';
import { getMessage } from '../../js/helpers/utils/BirdMessage';
import { lang } from "../../js/helpers/utils/languages/language";

const $ = window.$;
export default class SamrtInsightsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.state.el = ".smartinsightdatacontainer";
        this.smartinsightrightfiltersEvee = PubSub.subscribe('smartinsightrightfilters', this.smartinsightrightfiltersEve.bind(this));
        this.refreshchartsEve = PubSub.subscribe('refreshcharts', this.refreshchartsEve.bind(this));
        ChartInfo.functions.resetChartDetails();
        var globalfilterArray = ChartInfo.functions.getFilterArray();
        this.state.viewreportCollection = this.props.viewreportCollection;
        $('.spinner').show();
        $("body").addClass("smartinsightspage");
        this.state.measures = this.state.viewreportCollection.getMeasures();
        this.state.dimensions = this.state.viewreportCollection.getDimensions();
        this.state.reportId = this.props.reportId;
        this.state.datamodelId = this.props.datamodelId;
        this.state.schemaType = this.props.schemaType;
        this.state.globalfilterArray = globalfilterArray
        this.state.features = this.props.features;
        var that = this;
        if(window.localStorage.getItem("isStoryboardSmartInsight")){
           that.state.globalfilterArray = window.localStorage.getItem('smartinsights-filtersdata');
            ChartInfo.functions.setSmartInsightsTargetVal(window.localStorage.getItem('smartinsights-targetColumn'))
            ChartInfo.functions.setStoryboardSmartInsightFilterArray(JSON.parse(that.state.globalfilterArray));
            let customField = window.localStorage.getItem('smartinsights-customFieldata');
            sessionStorage.setItem("customFields",customField);
            ChartInfo.functions.setIsStoryboardSmartInsight(true);
         }
        var exclueValues = ChartInfo.functions.getSmartInsightsExcludeValues();
        var targetColumn = ChartInfo.functions.getSmartInsightsTargetVal();
        var reqdata = new FormData();
        reqdata.set("datamodelId", that.state.datamodelId);
        reqdata.set("schemaType", that.state.schemaType);
        reqdata.set("globalfilterArray", that.state.globalfilterArray);
        reqdata.set("reportId", that.state.reportId != undefined && that.state.reportId != null ? that.state.reportId : "");
        reqdata.set("keyInfluencers", false);
        reqdata.set("excludecolumn", exclueValues);
        reqdata.set("targetColumn", targetColumn);
        reqdata.set("mapForGetDuplicateName", null);
        reqdata.set("isFromRunModel", true);
        reqdata.set("selectedColumns",JSON.stringify(that.state.dimensions)) ;
        reqdata.set("customFields",sessionStorage.getItem("customFields"))
        ChartInfo.functions.setSmartinsightSelectedColumns(that.state.dimensions);
        let requestHeaders = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        $(".spinner").addClass("forceshow");
        SendRequest({
            url: "getsmartinsights",
            body: reqdata,
            sucessFunction: (response) => {
                that.state.collection = response;
                $('.spinner').show();
                $(".spinner").removeClass("forceshow");
                that.render();
            },
            rejectFunction: this.reportDataError,
            headers: requestHeaders
        });

    }
    refreshchartsEve() {
        ReactDOM.unmountComponentAtNode(document.querySelector(this.state.el));
        this.render();
    }
    render() {
        $(".maxcustomparameterfiltersdropdown").hide();
        ReactDOM.render(<SmartInsightsTemplate smartinsightoptions={this.smartinsightoptions} tabid={0} />, document.querySelector(this.state.el));
        var that = this;
        $("#reportheader").empty();
        var today = new Date();
        var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
        $("#reportheader").append("<div className='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
        that.state.viewReportHeader = new SmartInsightHeaderView({
            dynamicdiv: dynamicdiv,
            viewreportCollection: that.state.viewreportCollection,
            features: that.state.features,
            reportId: null,
            schemaId: "",
            isPurgedReport: false,
            reportRequestFrom: "viewsmartinsight",
            isDirect: ChartInfo.functions.getIsDirect(),
            tempID: that.props.entities,
            title:lang["smartsights.title"],
            name: "New Smart Insight Report",
            folder: "default",
            desc: "",
            datamodelId: that.props.datamodelId,
            collection: that.state.collection
        });

        $(".smartinsightintro-container").addClass("hide");
        that.state.viewreportfilterview = new ViewReportFilterView({
            features: that.state.features,
            tabid: that.state.tabid,
            isFromStoryBook: that.state.isFromStoryBook,
            scheduleid: that.props.scheduleid,
            multifact: that.state.multifact,
            facttables: that.state.facttables,
            multifactentities: that.state.multifactentities
        });
        $(".sidetheme").empty();
        $(".sidetheme").append(<div class="panel-group rightfilter" id="filteraccordion"></div>);
        if (that.state.viewReportAddFilterView != null) {
            that.state.viewReportAddFilterView = undefined;
        }
        var viewReportAddFilterCollection = [];
        that.state.viewReportAddFilterView = new ViewReportAddFilterView(
            {
                collection: viewReportAddFilterCollection,
                datamodelId: that.props.datamodelId,
                reportId: that.props.reportId,
                tabid: that.state.tabid,
                isFromStoryBook: that.state.isFromStoryBook,
                scheduleid: that.props.scheduleid,
                filterArray: that.state.globalfilterArray,
                viewreportCollection: that.state.viewreportCollection,
                multifact: that.state.multifact,
                facttables: that.state.facttables,
                multifactentities: that.state.multifactentities
            });
        if (that.state.globalfilterArray.length == 0) {
            $(".hidebutton").trigger(
                'click');
        } else {
            $(".showbutton").trigger('click');
            $('.instant-floating-filters').draggable({ cancel: '.col-lg-12.nopadding', containment: '#main>.boc-viewreport-section', handle: '.panel-heading' });
        }
        //Sample Data Sets
        var exclueValues = ChartInfo.functions.getSmartInsightsExcludeValues();
        var targetColumn = ChartInfo.functions.getSmartInsightsTargetVal();
        var smartInsightDataObj = {};
        smartInsightDataObj["excludeColumn"] = exclueValues;
        smartInsightDataObj["targeColumn"] = targetColumn;
        smartInsightDataObj["smarinsightData"] = JSON.stringify(this.state.collection);
        ChartInfo.functions.setSmartinsightDataObj(smartInsightDataObj);
        $(".storyboardtab").removeClass('active')
        $(".smartinsights").addClass('active')
        $("#storyboardtab").removeClass('active')
        $("#smartinsights0").addClass('active')
        $('body').removeClass('rightpanelopen')
        $(".tab-pane#storyboardtab .rightfilter").attr('id', "filteraccordiontemp");
        if (that.state.collection.success == false || that.state.collection.error != null) {
            $(".spinner").hide();
            $(".gridspinner").hide();
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('hide');
            $('.birdmessage h2').empty().append('Error : ');
            $('.birdmessage h2').append('<span class="errorcode">');
            $('.birdmessage .errorcode').empty().append(this.state.collection.errorCode);
            $('.birdmessage-info').empty().text(this.state.collection.error);
            $('.details').addClass('show');
            $('.messagedetails xmp').empty().append(this.state.collection.errorTrace)
        } else {
            var yaxisname;
            var insightdata = [that.state.collection[1].insightQuality];

            var categories = that.state.collection[0].correlation[0];
            var correlatedcolumns = that.state.collection[0].correlation[1].data;
            var keyinfluencers = that.state.collection[1].keyinfluencers.data;
            var keyinfluencersCategories = that.state.collection[1].keyinfluencers.categories;
            var targetColumn = that.state.collection[1].targetcolumn;
            var modelName = that.state.collection[1].modelname;
            var frameName = that.state.collection[1].framename;

            that.state.modelName = modelName;
            that.state.frameName = frameName;
            var targetColumnOriginal = that.state.collection[1].targetColumnOriginal;

            var outlierCount = that.state.collection[1].outliercount;
            var correlatedCoumnsCount = that.state.collection[0].correaltionCount;
            var keyInfluencersCount = keyinfluencersCategories.length;

            var predictiondata = that.state.collection[1].predictiondata;
            var predictiondataLables = that.state.collection[1].predictionlabel
            let chiSquareData = that.state.collection[10].chiSquareData[1].data;
            let chisquarecategories = that.state.collection[10].chiSquareData[0];

            _.each(that.state.collection[2], function (data) {
                $(".insightnlg .influenceschart .nlg-list").append(data);
                $(".insightnlg .influenceschart .nlg-list>li:last-child>span").prepend("&#9755; ")
            });
            $(".insightnlg .mltooltip").removeClass('hide');
            $(".insightnlg .mltooltip").attr("title", lang["smartsights.insightnlg-tooltip"]);
            /*Upate Initial Titles*/
            $(".whatinfluences>span").text(targetColumn + " ?");
            $(".insightavg>h3").html(targetColumn + " <span></span>");
            $(".insightavgsuggestion1>h3").html("Key Influencers");
            $(".insightavgsuggestion2>h3").html(targetColumn + " <span></span>");
            $(".insightnlg>h3>span, .simulationwith>span, .expectedvaluecontainer>h3>span").text(targetColumn);

            /*var findingsdata = [{
                name: 'Correlated Columns',
                y: correlatedCoumnsCount,
            }, {
                name: 'Key Influences',
                y: keyInfluencersCount
            }, {
                name: 'Outliers',
                y: outlierCount
            }]*/

            var jsondata = [predictiondata, { "sourceColumns": predictiondataLables }
            ]
            //\Sample Data Sets

            //DrawCharts
            var renderto;
            $("#smartinsights0").show()
            renderto = $(".insightquality .overviewchart");
            SmartCharts.charts.drawSmartSolidgaugeChart(insightdata, renderto);
            $(".insightquality .mltooltip").removeClass('hide');
            $(".insightquality .mltooltip").attr("title", lang["smartsights.insightquality-tooltip"]);


            /*SmartCharts.charts.drawSmartPieChart(findingsdata, renderto);*/
            renderto = $(".mlchisquaretable .overviewchart");
            if (chiSquareData != undefined && chiSquareData != null && chisquarecategories != undefined && chisquarecategories.length > 0) {
                SmartCharts.charts.drawSmartBubbleChart(chiSquareData, chisquarecategories, renderto);
            }else if (chiSquareData != undefined && chiSquareData != null && chiSquareData[1] != undefined && chiSquareData[1].length > 0) {
                let chisquredata = chiSquareData[1];
                $(".mlchisquaretable .overviewchart").append("<span class='mlnodatamessage'>" + chisquredata[0] + "</span>")
            }
            else {
                $(".mlchisquaretable .overviewchart").append("<span class='mlnodatamessage'>" + getMessage('BirdInformation48') + "</span>");
            }
            $(".mlchisquaretable .mltooltip").removeClass('hide');
            $(".mlchisquaretable .mltooltip").attr("title", lang["smartsights.chisquare-tooltip"]);

            renderto = $(".insightcorrelatedcolumns .overviewchart");
            if (correlatedcolumns != undefined && correlatedcolumns != null && categories[0] != undefined && categories[0].length > 0) {
                SmartCharts.charts.drawSmartBubbleChart(correlatedcolumns, categories, renderto);
              } else if (correlatedcolumns != undefined && correlatedcolumns != null && categories[1] != undefined && categories[1].length > 0) {
                $(".insightcorrelatedcolumns .overviewchart").append("<span class='mlnodatamessage'>" + correlatedcolumns[0] + "</span>")
            }
            else {
                $(".insightcorrelatedcolumns .overviewchart").append("<span class='mlnodatamessage'>" + getMessage('BirdInformation49') + "</span>")
            }
            $(".insightcorrelatedcolumns .mltooltip").removeClass('hide');
            $(".insightcorrelatedcolumns .mltooltip").attr("title", lang["smartsights.correlated-tooltip"]);

            renderto = $(".keyinfluencers .influenceschart");
            SmartCharts.charts.drawSmartBar(keyinfluencers, keyinfluencersCategories, renderto, targetColumn, modelName, frameName, "", targetColumnOriginal);
            $(".keyinfluencers[tabid='" + that.state.tabid + "'] .mltooltip").removeClass('hide');
            $(".keyinfluencers[tabid='" + that.state.tabid + "'] .mltooltip").attr("title", lang["smartsights.keyinfluencer-tooltip"]);

            renderto = $(".simulationselectioncontainer .simulationwaterfall");
            SmartCharts.charts.drawSmartSimulationWaterfall(that.state.collection[3].data, that.state.collection[3].data, renderto, targetColumn, modelName, frameName);


            _.each(jsondata[1].sourceColumns, function (i, j) {
                var  j=_.invert(i);
                 _.each(j,function(val){
                 $(".outliertable thead>tr").append("<th data-sortable='true' data-field='" + val + "' class='"+(i[val]==='number'?"align-right":"")+"'>" + val + "</th>");
                 });
             });

            $(".outliertable").bootstrapTable({ data: jsondata[0].data });
            if (ChartInfo.functions.getSmartInsightsTargetVal() == "" || ChartInfo.functions.getSmartInsightsTargetVal() == undefined) {
                ChartInfo.functions.setSmartInsightsTargetVal(that.state.collection[4].targetColumn);
            }
            ChartInfo.functions.setRdisplyColumnMap(JSON.stringify(that.state.collection[3].mapForGetDuplicateName));
            that.simulationsection();
        }
        $(".spinner").hide();
        return (<div></div>);
    }
    simulationsection() {
        var simulationdata = this.state.collection[3];
        var that = this;
        var today = new Date();
        var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
        $(".simulationcselections").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
        var smartInsightsSimulationView = new SmartInsightsSimulationView({
            dynamicdiv: dynamicdiv,
            measures: that.state.measures,
            dimensions: that.state.dimensions,
            reportId: that.props.reportId,
            datamodelId: that.props.datamodelId,
            data: simulationdata,
            modelName: that.state.modelName,
            frameName: that.state.frameName,
            targetColumn: that.state.collection[0].targetColumn,
            keyinfluencersList:that.state.collection[1].KeyInfluencerColumnsList,
            tabid: 0
        });
        $(".simulationcselections").html(document.getElementById(dynamicdiv));

        /*Expected Value*/
        // $(".expectedvalue>span.expectedsymbol").html("+");
        // $(".expectedvalue>span.expectednumber").html("350");
    }
    smartinsightrightfiltersEve(msg, data) {
        this.smartinsightrightfilters(data);
    }
    smartinsightrightfilters(e) {
        var flag = false;
        var filterAccordianLength = $('.acc-content').length;
        for (var i = 0; i < filterAccordianLength; i++) {
            var $this = $('.acc-content').eq(i);
            var fromRangeVal = $($this).find('.irs-min').text();
            var toRangeVal = $($this).find('.irs-max').text();
            var greaterThanValue = $($this).find('#greaterthanvalue').val();
            var lessThanValue = $($this).find('#lessthanvalue').val();
            $($this).find('.filtergreaterthanvalue.filter-error').addClass("hide");
            $($this).find('.filterlessthanvalue.filter-error').addClass("hide");
            if (fromRangeVal != undefined && toRangeVal != undefined && $($this).attr('id') != undefined) {
                $('.filteritem-container .acc-content[data-id="' + $($this).attr('id') + '"] .filteredvalues').text(fromRangeVal + " - " + toRangeVal).attr("title", fromRangeVal + " - " + toRangeVal);
            }
        }
        if (flag) {
            return false;
        }
        sessionStorage.setItem("changesdone", 1);
        var intelligentsearchwindow = $(".is-container").css("display");
        var intelligentsearchvalue = $("#birdintelligentsearch").val();

        $('.gridspinner').show();
        $('.trend-stats-container').remove();
        $('.viewdatafromchart').addClass('hide');
        $('.viewchartsummary').addClass('hide');
        $('.addInstanceFilterData').empty();
        if ($('.addInstanceFilterData').text().trim().length == 0) {
            $('.instant-floating-filters').hide();
        }
        ChartInfo.functions.setisFilterModalOpen(true);
        sessionStorage.removeItem("instantExcludeFilters");
        sessionStorage.removeItem("instantIncludeFilters");
        sessionStorage.removeItem("instantExcludeCustomFilters");
        ChartInfo.functions.setInstanceIncludeFilterData("");
        ChartInfo.functions.setInstanceFilterData("");
        ChartInfo.functions.setIncludeFilterData("");
        ChartInfo.functions.setInstanceFilterData("");

        var bool = $('.isexcludenulls').is(":checked")
        ChartInfo.functions.setIsExcludeNulls(bool);
        var filterArray = ChartInfo.functions.getFilters(false);
        //Updated Filtered Data in UI
        var filterCustomParametersArray = ChartInfo.functions.getCustomParametersFilters(),
            normalFilters = ChartInfo.functions.getFilters(false),
            totalfilterArray = $.merge($.merge([], normalFilters), filterCustomParametersArray);
        if (totalfilterArray != null && totalfilterArray != undefined && totalfilterArray != "" && totalfilterArray.length != 0) {
            _.each(totalfilterArray, function (filter, index) {
                if (filter.dataType != undefined) {
                    var filtercolumndisplayname = filter.columndisplayname.replace(/([;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "");
                    if (filter.dataType == "string") {
                        if (filter.customfield == undefined) {
                            var filtereddatatext = filter["data"].replace(/\@@/g, ', ');
                        } else {
                            var filtereddatatext = filter.customfield.sqlcondition.sqldata.replace(/\@@/g, ', ')
                        }
                        var TotalFilterItems = $(".filtervalue[data-name='" + filtercolumndisplayname + "']").length;
                        var unCheckedFilterItems = $(".filtervalue[data-name='" + filtercolumndisplayname + "']:not(:checked)").length;
                        if (unCheckedFilterItems == 0) {
                            $('.filteritem-container .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text("All").attr("title", "All");
                        }
                        //  else if (TotalFilterItems <= 5 || (unCheckedFilterItems > 5 && TotalFilterItems > 5)) {
                        //     $('.filteritem-container .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text(filtereddatatext).attr("title", filtereddatatext);
                        // } else if (unCheckedFilterItems <= 5) {
                        //     var unCheckedItems = [];
                        //     _.each($(".filtervalue[data-name='" + filtercolumndisplayname + "']:not(:checked)"), function (i) {
                        //         unCheckedItems.push($(i).attr("data-value"));
                        //     });
                        //     unCheckedItems = unCheckedItems.join(", ")
                        //     $('.filteritem-container .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text("Excluding : " + unCheckedItems + "").attr("title", "Excluding : " + unCheckedItems + "");
                        // }
                        else {
                            $('.filteritem-container .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text(filtereddatatext).attr("title", filtereddatatext);
                        } 
                    } else if (filter.dataType == "number") {
                        var fromRange = filter.fromRange,
                            toRange = filter.toRange,
                            customRange = filter.customfiltervalue;
                        if (fromRange == undefined && toRange == undefined) {
                            fromRange = filter.filtergreaterthanvalue;
                            toRange = filter.filterlessthanvalue;
                        }
                        if (customRange == undefined || customRange === "") {
                            $('.filteritem-container .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text(fromRange + " - " + toRange).attr("title", fromRange + " - " + toRange);
                        }
                    } else if (filter.dataType == "date") {
                        var fromDateRange = filter.fromRange,
                            toDateRange = filter.toRange,
                            dateRange = filter.daterange;
                        var dates = $('.daterange-center[displayname="' + filter.columndisplayname + '"] span').text();
                        //if(customRange== undefined || customRange===""){
                        $('.filteritem-container .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text(dates).attr("title", dates);
                        //}
                    }
                } else if (filter.parametername != undefined && filter.parametername != "") {
                    if (filter.valueType == "all" || filter.valueType == "range") {
                        $('.filteritem-container .acc-content[data-id="' + filter.parametername + '"] .filteredvalues').text(filter.parametercurrentvalue).attr("title", filter.parametercurrentvalue);
                    } else if (filter.valueType == "list") {
                        var selectedplistvalue = $('.filtercustomparamerelist[data-id="acc' + filter.parametername + '"] select').val()
                        $('.filteritem-container .acc-content[data-id="' + filter.parametername + '"] .filteredvalues').text(selectedplistvalue).attr("title", selectedplistvalue);
                    }
                }

                //Update Filter Display Name
                if (filter.filterDisplayName != null && filter.filterDisplayName != undefined) {
                    var targetName = filter.columndisplayname;
                    if (filter.parametername != undefined && filter.parametername != "") {
                        targetName = filter.parametername;
                    }
                    if (filter.filterDisplayName == "") {
                        $(".acc-content[data-id='" + targetName.replace(/([;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "") + "'] h4.panelboc-title").text(filter.columndisplayname).attr("title", filter.columndisplayname);
                        $(".itemname[data-name='" + targetName.replace(/([;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "") + "']").val(filter.columndisplayname);
                    } else {
                        $(".acc-content[data-id='" + targetName.replace(/([;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "") + "'] h4.panelboc-title").text(filter.filterDisplayName).attr("title", filter.filterDisplayName);
                    }
                }
            });
        }

        if (intelligentsearchwindow != "block") {
            var targetcolumn = ChartInfo.functions.getSmartInsightsTargetVal();
            var exclueValues = ChartInfo.functions.getSmartInsightsExcludeValues();
            var that = this;
            $(".smartinsightintro-container").removeClass("hide");
            var mainFilter = ChartInfo.functions.getFilterArray();
            var storyboardSmartInsightFilterArray = ChartInfo.functions.getStoryboardSmartInsightFilterArray();
            mainFilter = mainFilter.concat(storyboardSmartInsightFilterArray);
            var reqdata = new FormData();
            reqdata.set("datamodelId", that.state.datamodelId);
            reqdata.set("schemaType", that.state.schemaType);
            reqdata.set("globalfilterArray", JSON.stringify(mainFilter));
            reqdata.set("reportId", that.state.reportId != undefined && that.state.reportId != null ? that.state.reportId : "");
            reqdata.set("keyInfluencers", false);
            reqdata.set("excludecolumn", exclueValues);
            reqdata.set("targetColumn", targetcolumn);
            reqdata.set("mapForGetDuplicateName", null);
            reqdata.set("isFromRunModel", true);
            let selectedColumnsval = ChartInfo.functions.getSmartinsigtSelectedColumns();
            reqdata.set("selectedColumns",JSON.stringify(selectedColumnsval));
            reqdata.set("customFields",sessionStorage.getItem("customFields"))
            
            let requestHeaders = {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            SendRequest({
                url: "getsmartinsights",
                body: reqdata,
                sucessFunction: (response) => {
                    that.state.collection = response;
                    $(".smartinsightintro-container").addClass("hide");
                    ReactDOM.unmountComponentAtNode(document.querySelector(that.state.el));
                    that.render();
                    $('.gridspinner').hide();
                    var smartInsightDataObj = {};
                    smartInsightDataObj["excludeColumn"] = exclueValues;
                    smartInsightDataObj["targeColumn"] = targetcolumn;
                    smartInsightDataObj["smarinsightData"] = JSON.stringify(that.state.collection);
                    ChartInfo.functions.setSmartinsightDataObj(smartInsightDataObj);
                },
                rejectFunction: this.reportDataError,
                headers: requestHeaders
            });
        } else {
            $('.gridspinner').hide();
            sessionStorage.setItem("intelligentfilter", 1);
            if (intelligentsearchvalue != "") {
                $(".is-container #clicksearchbutton").click();
            }
        }
        $(".quickfiltercontainer").empty();
        $(".pie-menu").remove();
        $(".fullscreensample").removeClass("nopie");
        /*$(".instant-floating-filters.rightpane").removeClass("changesdone");
        $("#rightfiltersapplys").removeClass("btn-highlight");*/
    }
    smartinsightoptions = (e) => {
        e.preventDefault();
        //$('.spinner').show();
        var that = this;
        var targetColumn = "";
        var isOpen = $(".rightsmartattributes").hasClass("open");
        var targetcolumn = ChartInfo.functions.getSmartInsightsTargetVal();
        var isPublished='false';
        if (isOpen) {
            $(".rightsmartattributes").removeClass("open");
        } else if (that.state.collection.success == false) {
            let requestHeaders = {
                method: "get",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };

            SendRequest({
                url: "gettotaldatacount",
                queryString: "?entities= &reportId= &multifact=false&datamodelId=" + that.props.datamodelId+ "&isImportedReport=" + false+"&isPurgedReport="+false +"&isPublished="+"false",
                sucessFunction: response => {
                    if (response.hasOwnProperty('error')) {
                        $(".spinner").hide();
                        $(".gridspinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.errorTrace)
                    }
                    that.state.datacount = response.size;
                    $(".publishinfo").text("Dataset Count: " + response.size);
                    ChartInfo.functions.setDataSetCount(response.size);
                    $(".smartinsightintro-container").removeClass("hide");
                    var today = new Date();
                    var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                    dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                    $(".rightsmartattributes_container").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                    var smartInsightsSettingsView = new SmartInsightsSettingsView({
                        smartinsightoptions: that.smartinsightoptions,
                        dynamicdiv: dynamicdiv,
                        measures: that.state.dimensions,
                        dimensions: that.state.dimensions,
                        reportId: that.props.reportId,
                        datamodelId: that.props.datamodelId,
                        targetColumn: undefined,
                        totalRecords: that.state.datacount,
                        totalDBRecords: that.state.datacount,
                        schemaType: "",
                        dataSource: sessionStorage.datamodelname,
                        tabid: 0
                    });
                    $(".rightsmartattributes_container").html(document.getElementById(dynamicdiv));
                    $(".rightsmartattributes").addClass("open");
                    $(".smartinsightintro-container").addClass("hide");
                    sessionStorage.removeItem('datamodelname')
                },
                rejectFunction: () => { },
                headers: requestHeaders
            });

        } else {
            $(".smartinsightintro-container").removeClass("hide");
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $(".rightsmartattributes_container").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");

            var smartInsightsSettingsView = new SmartInsightsSettingsView({
                smartinsightoptions: that.smartinsightoptions,
                dynamicdiv: dynamicdiv,
                measures: that.state.dimensions,
                dimensions: that.state.dimensions,
                reportId: that.props.reportId,
                datamodelId: that.props.datamodelId,
                targetColumn: targetcolumn,
                totalRecords: that.state.collection[5].totalRecords,
                totalDBRecords: that.state.collection[5].totalDBRecords,
                schemaType: that.state.collection[5].schemaType,
                dataSource: that.state.collection[5].dataSource,
                tabid: 0
            });
            $(".rightsmartattributes_container").html(document.getElementById(dynamicdiv));
            $(".rightsmartattributes").addClass("open");
            $(".smartinsightintro-container").addClass("hide");
        }

        let isStoryboardSmartInsight = ChartInfo.functions.getIsStoryboardSmartInsight();
            if(isStoryboardSmartInsight){
                $(".smartyaxisattributes").addClass('disabled')
            }
    }
}