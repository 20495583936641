import React from "react"
import ReactDOM from "react-dom"
import _ from 'lodash';
import PubSub from 'pubsub-js'
import AddCustomParameterFilterTemplate from './viewreportaddcustomparameterfilter.jsx'
const $ = window.$;

export default class ViewReportAddCustomParameterFilters extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            customparameterfileds: props.customparameterfileds,
            schemaType: ""
        };
        this.render();
    }
    render() {
        if(this.state.customparameterfileds.length != 0){
            this.state.customparameterfileds.sort(function(a, b){
            var x = a.parametername.toLowerCase();
            var y = b.parametername.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0 ;
            });
           }

        ReactDOM.render(<AddCustomParameterFilterTemplate addCustomParameterFilter={this.addCustomParameterFilter} singleSavedFiltersSearch={this.singleSavedFiltersSearch} closeParameterModal={this.closeParameterModal} customparamterfields={this.state.customparameterfileds} />, document.getElementById("filtermodal"));
        _.each($(".acc-content"), function (element) {
            var child = $(element).attr('id').replace(/([ ;?&,%.+*~\':"!^$[\]()<=>|\/@])/g, "\\$1");
            var parent = $(element).attr("data-parent")
            $(".customparameterfiltercheckbox[name=" + child + "]").prop('checked', true);
        });
        return (<div></div>);
    }
    closeParameterModal = () => {
        ReactDOM.unmountComponentAtNode(document.getElementById("filtermodal"))
    }
    singleSavedFiltersSearch = (e) => {
        var $this = e.currentTarget;
        var valThis = $($this).val().toLowerCase();
        $("input:checkbox[class='customparameterfiltercheckbox']").parents(".boc-list-item").each(function () {
            var text = $(this).text().toLowerCase();
            (text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
        });
    }
    addCustomParameterFilter = () => {
        PubSub.publish('addCustomParameterFilter')
    }
}