import React from "react";
import { lang } from "../js/helpers/utils/languages/language";
export default class YaxisDropDownViewTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        var that = this.props;
        return (
            // <!-- yaxisdropdownview.js -->
            <li key={that.dynamicdiv} title={that.columnDisplayName} data-name={that.columnName} table-name={that.tableName} data-type={that.dataType} className={`xs-list-item ${that.classname} ${(that.disabled) ? " hide" : ""}`} onClick={that.classname == "referenceline" ? that.addreferencevalue : that.addyaxisvalue} iscustomfunction={that.customfunction} percentile-value={that.percentileValue}>
                <span className="itemtype">
                    {(that.dataType === 'number' && that.customfunction != 1) ? (
                        <>123</>
                    ) : ((that.dataType === 'date' || that.dataType === 'datetime') && that.customfunction != 1) ? (
                        <i className="fa fa-calendar"></i>
                    ) : (that.dataType === 'month' && that.customfunction != 1) ? (
                        <i className="fa fa-calendar"></i>
                    ) : (that.dataType === 'day' && that.customfunction != 1) ? (
                        <i className="fa fa-calendar"></i>
                    ) : (that.dataType === 'customhierarchy' && that.customfunction != 1) ? (
                        <i className="fa fa-list"></i>
                    ) : (that.dataType === 'custommeasurehierarchy' && that.customfunction != 1) ? (
                        <i className="fa fa-list"></i>
                    ) : (that.dataType === 'customaggregatedfield' || that.customfunction === 1) ? (
                        <span className="function">{lang["storyboard.fx"]}</span>
                    ) : (that.dataType == "CustomLine") ? (
                        <i className='fa fa-pencil'></i>
                    ) : (
                                                        <>{lang["storyboard.abc"]}</>
                                                    )}
                </span>

                <span className="itemname">{that.columnDisplayName}</span>
                {(that.dataType === 'customaggregatedfield' && that.chartType != 'mlmodel') ? (
                    <span className="itemedit createcustommeasure" data-edit="edit" data-name={that.columnName} title={lang["storyboard.edit"]}><i className="fa fa-pencil"></i></span>
                ) : (that.dataType === 'custommeasurehierarchy' && that.chartType != 'mlmodel') ? (
                    <span className="itemedit createcustomhierarchymeasure" data-edit="edit" data-name={that.columnName} title={lang["storyboard.edit"]}><i className="fa fa-pencil"></i></span>
                ) : null}

                {(that.dataType != 'customaggregatedfield' && that.dataType != 'custommeasurehierarchy' && that.classname != undefined && that.classname != 'referenceline' && that.chartType != 'mlmodel') ? (
                    <>
                        {(that.chartType == "predictionchart") ? (
                            <>
                                {(that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                                    <span className="boclabel boclabel-right-5 opacity-effect boclabel-info">[ {that.aggregation.toLowerCase() == "none" ? "count" : that.aggregation} ]</span>
                                ) : null}
                            </>
                        ) : (
                                <>
                                    {(that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                                        <span className="boclabel boclabel-right-5 opacity-effect boclabel-info">[ {that.aggregation} ]</span>
                                    ) : null}</>
                            )}
                    </>) : null}
            </li>
        );
    }
}