import _ from 'lodash';
import $ from 'jquery/dist/jquery';
import React, { Component } from "react";
import ReactDOM from "react-dom";
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import { lang } from "../js/helpers/utils/languages/language";

export default class CustomSolidGuageAttributes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicdiv: props.dynamicdiv,
            columnDisplayName: props.columnDisplayName,
            columnName: props.columnName,
            columnType: props.columnType,
            aggregation: props.aggregation,
            tableName: props.tableName,
            percentileValue: props.percentileValue,
            //	broker.on('changeaggregation',changeaggregation,this),
        };
        this.render();
    }
    render() {
        this.state.el = "#" + this.state.dynamicdiv;
        ReactDOM.render(<CustomSolidGaugeAttributesTemplate dynamicdiv={this.state.dynamicdiv} changeCustomSolidGaugeAggregation={this.changeCustomSolidGaugeAggregation}
            columnDisplayName={this.state.columnDisplayName} columnName={this.state.columnName}
            columnType={this.state.columnType} aggregation={this.state.aggregation} tableName={this.state.tableName} percentileValue={this.state.percentileValue}
        ></CustomSolidGaugeAttributesTemplate>, document.getElementById(this.state.dynamicdiv));

        var sortingAttributes = $.parseJSON(ChartInfo.functions.getChartDetails(sessionStorage.getItem('currentChartNumberForSort')));
        var colLimit = sortingAttributes.colLimit;
        var orderType = sortingAttributes.orderType;
        var sortType = sortingAttributes.sortType;
        var sortingColumn = sortingAttributes.sortingColumn;
        /*	if(this.state.yaxiscolumnName==sortingColumn){
        $(".yaxisitems").append('<option data-name="'+this.state.yaxiscolumnName+'" data-agg="'+this.state.aggregation+'"data-type="'+this.state.yaxiscolumnType+'" title="'+this.state.yaxiscolumnDisplayName+'" selected="selected">'+this.state.yaxiscolumnDisplayName+' </option>');
        }else{
        $(".yaxisitems").append('<option data-name="'+this.state.yaxiscolumnName+'" data-agg="'+this.state.aggregation+'"data-type="'+this.state.yaxiscolumnType+'" title="'+this.state.yaxiscolumnDisplayName+'">'+this.state.yaxiscolumnDisplayName+' </option>');	
        }*/
        if (sortType == 'desc') {
            $('input[id="sortby1"]').attr('checked', true);
        } else if (sortType == 'asc') {
            $('input[id="sortby2"]').attr('checked', true);
        }
        if (orderType == "top") {
            $('.ordertype option[value="top"]').attr('selected', 'selected');
        } else if (orderType == "bottom") {
            $('.ordertype option[value="bottom"]').attr('selected', 'selected');
        }
        if (colLimit != null && colLimit != "") {
            $('.collimit').attr('value', colLimit);
        }

        //Active drop down aggregation item
        if (this.state.aggregation === "sum" || this.state.aggregation === "Sum") {
            $(this.state.el).find(".aggsum").addClass("active");
        } else if (this.state.aggregation === "min" || this.state.aggregation === "Min") {
            $(this.state.el).find(".aggmin").addClass("active");
        } else if (this.state.aggregation === "max" || this.state.aggregation === "Max") {
            $(this.state.el).find(".aggmax").addClass("active");
        } else if (this.state.aggregation === "avg" || this.state.aggregation === "Avg") {
            $(this.state.el).find(".aggavg").addClass("active");
        } else if (this.state.aggregation === "variance" || this.state.aggregation === "Variance") {
            $(this.state.el).find(".aggvariance").addClass("active");
        } else if (this.state.aggregation === "standard deviation" || this.state.aggregation === "Standard Deviation") {
            $(this.state.el).find(".aggstandarddeviation").addClass("active");
        } else if (this.state.aggregation === "sum of squares" || this.state.aggregation === "Sum of Squares") {
            $(this.state.el).find(".aggsumofsquares").addClass("active");
        } else if (this.state.aggregation === "percentile" || this.state.aggregation === "Percentile") {
            $(this.state.el).find(".aggpercentile").addClass("active");
        } else if (this.state.aggregation === "median" || this.state.aggregation === "Median") {
            $(this.state.el).find(".aggmedian").addClass("active");
        } else if (this.state.aggregation === "count all" || this.state.aggregation === "Count (all)") {
            $(this.state.el).find(".aggcount").addClass("active");
        } else if (this.state.aggregation === "count" || this.state.aggregation === "Count (distinct)") {
            $(this.state.el).find(".aggcountdistinct").addClass("active");
        }
        return (<div></div>);

    }
    changeCustomSolidGaugeAggregation =(e)=>{
        $(this.state.el).find(".aggregation-li-item").removeClass("active");
        $(e.currentTarget).addClass("active");
        var columnName = $(this.state.el).find('.singleliitems').attr("data-name");
        var columnDisplayName = $(this.state.el).find('.singleliitems').attr("title");
        var aggregation = $(e.currentTarget).attr("title");
        var aggregationtext = $(e.currentTarget).text();
        var percentileValue=$(this.state.el).find(".percentilebox").val();
        var selectedAggregation =  $(e.currentTarget).text();
        if(aggregation==="percentile"){selectedAggregation= selectedAggregation+"("+percentileValue+")"}
        $(this.state.el).find(".aggregationtext").text("[ "+selectedAggregation+" ]");
        $(this.state.el).find('.singleliitems').attr("data-agg",$(e.currentTarget).text());
        this.state.aggregation = aggregation;
        
        if(this.state.columnName === columnName && this.state.columnDisplayName === columnDisplayName){
            $(".measures .singleliitems[data-name = '"+this.state.columnName+"']").attr("data-agg",aggregationtext);
            $(".measures .singleliitems[data-name = '"+this.state.columnName+"']").attr("percentile-value",percentileValue);
            $(".measures .singleliitems[data-name = '"+this.state.columnName+"'] .percentilebox").val(percentileValue);
            $(".measures .singleliitems[data-name = '"+this.state.columnName+"'] #dropdownaggregation").text(aggregationtext);
            $(".measures .singleliitems[data-name = '"+this.state.columnName+"'] .aggregation-li-item").removeClass('active');
            $(".measures .singleliitems[data-name = '"+this.state.columnName+"'] li[title='"+aggregation+"']").addClass('active');
            
        }
    }
}

class CustomSolidGaugeAttributesTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        var that = this.props;
        return (
            <li key={that.dynamicdiv} className="boc-list-item singleliitems" data-name={that.columnName} data-agg={that.aggregation}
                data-type={that.columnType} title={that.columnDisplayName} table-name={that.tableName} percentile-value={that.percentileValue}>
                <span className="itemcontainer">
                    <span className="itemtype">
                        {(that.columnType === 'customaggregatedfield') ? (
                            <span className="function">{lang["storyboard.fx"]}</span>
                        ) : (that.columnType === 'custommeasurehierarchy') ? (
                            <i className="fa fa-list"></i>
                        ) : (that.columnType === 'string') ? (
                            <span> {lang["storyboard.abc"]}</span>
                        ) : (that.columnType === 'number') ? (
                            <> 123</>
                        ) : (that.columnType === 'date') ? (
                            <i className="fa fa-calendar"></i>
                        ) : null}
                    </span>
                    <span className="itemname">{that.columnDisplayName}</span>
                </span>
                <span className="itemoptions">
                    <span className="itemremove">
                        <button className="btn btn-transparent deletecustomsolidgauge" onClick={this.deleteelement} id="deletecustomsolidgauges" type="button"><i className="fa fa-trash-o"></i></button>
                    </span>
                    <span className="itemaggregation dropup">
                        <span className="aggregationtext" id="aggregationtexts" aria-expanded="false" data-toggle="dropdown">{that.aggregation}</span>
                        <ul role="menu" className="dropdown-menu">
                            {(that.columnType != 'string' && that.columnType != 'date' && that.columnType != 'month') ? (
                                <>
                                    <li className="aggregation-li-item aggsum" onClick={that.changeCustomSolidGaugeAggregation} title={lang["storyboard.sum"]}>{lang["storyboard.sum"]}</li>
                                    <li className="aggregation-li-item aggmin" onClick={that.changeCustomSolidGaugeAggregation} title={lang["storyboard.minimum"]}>{lang["storyboard.minimum"]}</li>
                                    <li className="aggregation-li-item aggmax" onClick={that.changeCustomSolidGaugeAggregation} title={lang["storyboard.max"]}>{lang["storyboard.max"]}</li>
                                    <li className="aggregation-li-item aggavg" onClick={that.changeCustomSolidGaugeAggregation} title={lang["storyboard.avg"]}>{lang["storyboard.avg"]}</li>
                                    <li className="aggregation-li-item aggstandarddeviation" onClick={that.changeCustomSolidGaugeAggregation} title={lang["storyboard.standard"]}>{lang["storyboard.standard"]}</li>
                                    <li className="aggregation-li-item aggpercentile" onClick={that.changeCustomSolidGaugeAggregation} title={lang["storyboard.percentile"]} >{lang["storyboard.percentile"]}
                               <span className="opacity-effect" ><input type="number" className="percentilebox" placeholder="1" min="25" max="100" defaultValue="25" /></span>
                                    </li>
                                    <li className="aggregation-li-item aggmedian" onClick={that.changeCustomSolidGaugeAggregation} title="Median">{lang["storyboard.median"]}</li>
                                </>
                            ) : null}
                            <li className="aggregation-li-item aggcountdistinct" onClick={that.changeCustomSolidGaugeAggregation} title={lang["storyboard.countdis"]}>{lang["storyboard.countdistinct"]}</li>
                            <li className="aggregation-li-item aggcount" onClick={that.changeCustomSolidGaugeAggregation} title={lang["storyboard.count"]}>{lang["storyboard.countall"]}</li>
                        </ul>
                    </span>
                </span>
            </li>);
    }
    deleteelement(e){
        var $this=e.currentTarget;
        var currentvalue = $($this).closest('span').prev().prev().text();
        $(".customsolidgaugeitem option[title='"+currentvalue+"']").remove();
        $($this).closest('li').parent().remove();
    }
}