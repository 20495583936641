import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'underscore';
import DatasourceConnection from './DatasourceConnection';
import DatahubItemSettingsHtml from './DatahubItemSettingsHtml.jsx';
import DatahubModifyEntitiesHtml from './DatahubModifyEntitiesHtml.jsx';
import {getMessage} from '../js/helpers/utils/BirdMessage';
import { lang } from "../js/helpers/utils/languages/language";
const $=window.$;

export default class DatahubItemsSettings extends React.Component{
    constructor(props){
        super(props);
        this.state={
            entityMetaDataCollection:props.entityMetaDataCollection
        }
        this.selectedEntities={};
        this.datahubdetails={};
        this.tables = [];
        this.query = {};
        this.datahubentities={};
        this.reportcolumns = {};
        this.getAllFeildsJson=this.getAllFeildsJson.bind(this);
        this.createDataHubEntitiesObject=this.createDataHubEntitiesObject.bind(this);
        this.changedatatypes=this.changedatatypes.bind(this);
        this.showCustomDateFormat=this.showCustomDateFormat.bind(this);
        this.closesettingscontainer=this.closesettingscontainer.bind(this);
        this.addexistingcsvreport=this.addexistingcsvreport.bind(this);
    }
    componentDidMount(){
        if(this.props.entityId!==undefined){
            _.each(this.props.metadata,(i,j)=>{
                var metadata = JSON.parse(i);
                _.each(this.props.dataHubEntitiesCollection,(k,l)=>{
                    if(j==k.connAccessId+"&&&"+k.entityname){
                        metadata.entities=JSON.parse(k.entityColumns);
                        this.props.metadata[j]=JSON.stringify(metadata);
                    }
                });
            });
        }
        this.createDataHubEntitiesObject(this.props.metadata);
        this.schemaType= this.props.connectionName;
        this.isSaved = this.props.isSaved;
        this.connectiondata=this.props.connectiondata;
        _.each(this.props.metadata,(i,j)=>{
            var data = j.split("&&&");
            this.conAccessId= data[0];
            this.entity= data[1];
            this.selectedEntities[this.conAccessId+"&&&"+this.entity.trim()] = JSON.parse(this.connectiondata);  
            this.metadata = this.getAllFeildsJson(this.conAccessId,this.entity);
        })
        //Append DatabseModify Entities
        var dataTypeClass = "";
        if(this.schemaType == 'kafka' || this.schemaType == 'eventhub' || this.schemaType == 'websocket' || this.schemaType == 'mongodb'|| this.schemaType == 'rabbitmq' || this.schemaType == 'elasticsearch'){
            dataTypeClass = "datecontroller_streaming";
        }
        ReactDOM.render(
            <DatahubModifyEntitiesHtml
                reportdata={this.metadata}
                dataTypeClass={dataTypeClass}
                schemaType={this.schemaType}
                isCustomQuery={false}
                isStoredProcedure={false}
                importtempateflag={false}
                isSaved={this.isSaved}
                entity={this.entity}
                changedatatypes={this.changedatatypes}
            />,document.querySelector('.datahubmodifyentitiestablecontainer')
        );
        setTimeout(()=>{
            if(dataTypeClass!==""){
                _.each(this.metadata, (item,index)=>{
                    if(item.tableDisplayType=="date"){
                        if(item.dateFormat!=undefined){
                            $(".csvdateformatsdroppdown.date[id='"+item.columnName+"'] option[value='others']").prop("selected", true);
                            $(".csvdateformatsdroppdown.date[id='"+item.columnName+"'] option[value='others']").change();
                            $(".customdate-others[name='"+item.columnName+"']").show();
                            $(".customdate-others[name='"+item.columnName+"']").val(item.dateFormat);
                        }
                    }
                });
            }
        },800);
        $(".spinner").hide();
    }
    createDataHubEntitiesObject=(response)=>{
        if(response.success== false||response.hasOwnProperty("error")){
    		if(response.status!="fail"){
    		$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
			$('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
			$('.birdmessagebuttons').removeClass('show');
			$('.birdmessagebuttons').addClass('hide');
			$('.birdmessage-center').addClass("message-top-in");
			$('.details').removeClass('hide');
			$('.birdmessage h2').empty().append('Error : ');
			$('.birdmessage h2').append('<span class="errorcode">');
			$('.birdmessage .errorcode').empty().append(response.errorCode);
			$('.birdmessage-info').empty().text(lang["Datahub.unablefetch"]);
			$('.details').addClass('show');
			if(response.success== false||response.hasOwnProperty("error")){
				$('.messagedetails xmp').empty().append(response.errorTrace);
				}
				else{
					$('.messagedetails xmp').empty().append(response.error);
				}}
			setTimeout(function(){
				$("#datahubstepscontainer").steps("previous");
			//	$('.spinner').hide();
			},1000);
			//$('.spinner').hide();			
			if($("#datahubentitycontainer li").length>0){
				var emptyNameCount = 0;
				$('.spinner').show();
				$("#datahubentitycontainer>li").removeClass('warning');
				_.each($("#datahubentitycontainer li"),function(ent,index){
					var entity = $(ent).find("input.scopeitem");
					var entityid = $(entity).attr("data_parent");
					var entityname=	$(entity).attr("data-value")
					if(entityname.includes('.')){
						 entityname=$(entity).attr("data-value").split(".")[1];
					}
					if(entityname=="Twitter"){
						entityname="twitter";
					}
					var errorTablename=response.tablename.replace(/[`"]/g,'');
					if(entityname==errorTablename && entityid==response.connId){
						setTimeout(function(){
							$(ent).removeClass("active");
							$(ent).addClass("warning");
							emptyNameCount++;
						},2000);
											}
				});
				setTimeout(function(){if(emptyNameCount>0){
					$('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-infor').addClass('birdmessage-warning');
					$('.messageicon i').removeClass('fa-times-circle-o fa-check fa-info-circle').addClass('fa-exclamation-triangle');
					$('.birdmessagebuttons').removeClass('show');
					$('.birdmessagebuttons').addClass('hide');
					$('.birdmessage-center').addClass("message-top-in");
					$('.details').removeClass('show');
					$('.details').addClass('hide');
					$('.birdmessage h2').empty().append('Warning');
					$("#datahubstepscontainer").steps("previous");
					if(response.error=="No Data"){
					$('.birdmessage-info').empty().text(lang["Datahub.nodataentity"]);
					}
					else{
					$('.birdmessage-info').empty().text("The Column Name '"+response.columnName+"'" + ""+getMessage('BirdWarning149'));
					}
					$('.spinner').hide();
					return ;
				}
				},2000);
			}
			return
		}
    	this.datahubdetails={};
    	this.tables = [];
    	var k=0;
    	_.each(response,(i,j)=>{
    		var data = JSON.parse(i);
    		if(this.datahubentities!==undefined && Object.keys(this.datahubentities).length>0 && this.datahubentities[j]!==undefined && this.datahubentities[j].length>0 ){
    			data.entities=this.datahubentities[j];
    		}
    		if(this.reportcolumns!==undefined && Object.keys(this.reportcolumns).length>0 && this.reportcolumns[j]!==undefined && this.reportcolumns[j].length>0 ){
    			data.entities=this.reportcolumns[j];
    		}
    		this.datahubdetails[j] = data;
    		var key = j.split("&&&");
    		var connAccessId = key[0];
    		var entity = key[1];
    		var connectionEntity = {};
    		connectionEntity['connaccessid'] = connAccessId;
    		connectionEntity['name'] = entity;
    		var dupname = entity.replace(/([-_;?&.,%#+*~\ '"!^$[\]()<=>`|\/@])/g, "");
    		k++;
  		    dupname = dupname+k+new Date().getTime();
    		connectionEntity['dupname'] = dupname;
    	/*	if($(".viewreport-list-item.datahub-customquery-item[data_parent='"+connAccessId+"']").hasClass('query-exists')){
        		$(".viewreport-list-item.datahub-customquery-item").closest("li.query-exists").addClass('error');
    			$("#datahubstepscontainer").steps("previous");
    			$('.spinner').hide();
        	}*/
    		if(data.dbType!=null && data.dbType!=undefined){
    			connectionEntity['type'] = data.dbType;
            }
            
    		this.tables.push(connectionEntity);
    	});
    }
    getAllFeildsJson=(connectionid,entity)=>{
        var tableDetails = this.datahubdetails[connectionid+"&&&"+entity];
        var tableEntities = tableDetails.entities;
        var feildData = [];
        _.each(tableEntities,(i,j)=>{
                var entity = {};
                var dataType=i.dataType;
                if(i.dataType == undefined && i.tableDisplayType != undefined){
                    dataType= i.tableDisplayType;
                }
                if(dataType === "double" || dataType === "long"){
                    dataType = "number"
                }
                if(i.dataType === "double" || i.dataType === "long"){
                    i.dataType = "number"
                }
                if(i.dataType==='date'||i.tableDisplayType==='date'){
                    if(i.dateFormat!=undefined && i.dateFormat!=null && i.dateFormat!=null){
                        entity['dateFormat'] = i.dateFormat;
                    }
                }
                entity['visible'] = "yes";
                entity['tableAliasName'] = i.tableName;
                entity['columnDisplayName']=i.columnName;
                entity['dateFormat']=i.dateFormat;
                entity['tableDisplayName'] = i.tableName;
                entity['tableName'] = i.tableName;
                entity['orginalColumnName'] = i.columnName;
                entity['columnName'] = i.columnName;
                entity['tableDisplayType'] = dataType;
                if(i.isPartitionKey!=undefined){
                    entity['isPartitionKey'] = i.isPartitionKey;
                }
                entity['name']=i.columnName;
                entity['type']= dataType;
                entity['connaccessid'] = connectionid;
                entity['tablename'] = i.tableName;
                feildData.push(entity);
            
        });
        return feildData;
    }
    changedatatypes=(event)=>{
        var $this=event.currentTarget;
        var id=$this.name;
        var tablename=$this.id.replace(/([#;?&,%.+*~\':"!^$%[\]()<=>|\/@])/g, "\\$1");
        if($($this).find("option:selected").attr("value")=="datetime"){
            $this.not(".datetime#"+tablename).hide();
            $this.show();
        }
        else if($($this).find("option:selected").attr("value")=="date"){
            $(".additionaloptions#"+tablename).not(".date#"+tablename).hide();
            $(".date#"+tablename).show();
            if($('.additionaloptions').find("option:selected").val()=="others"){
                $(".customdate-others[name='"+tablename+"']").show();
            }else{
                $(".customdate-others[name='"+tablename+"']").hide();
            }
        }else {
            $(".additionaloptions#"+tablename).hide();
            $(".customdate-others[name='"+tablename+"']").hide();
        }
    }
    showCustomDateFormat=(e)=>{
        var $this= e.currentTarget;
        var name = $($this).attr("id");
        if($($this).find("option:selected").val()=="others"){
            $(".customdate-others[name='"+name+"']").show();
        }else{
            $(".customdate-others[name='"+name+"']").hide();
            $(".customdate-error").addClass("hide")
        }
    }
    closesettingscontainer=(e)=>{
        e.preventDefault();
        $("#settingscontainer").removeClass("showsettings");
        $("#datahubentitycontainer>li").removeClass('active');
        $("#datahubentitycontainer").removeClass("settingsopened");
        setTimeout(function(){$("#settingscontainer").remove();},1000);
    }
    addexistingcsvreport=(e)=>{
        e.preventDefault();
        var $this = e.currentTarget;
        //$(".addtoexistingcontainer").empty();
        ReactDOM.render(
            <DatasourceConnection
                schemaId={this.props.schemaId}
                schemaName={this.props.schemaName}
                fileName={this.props.filename}
                isFromEdit={true}
                connectionId={this.props.connectionId}
                metadata={this.props.metadata}
                entityName={this.props.entityName}
                entityId={this.props.entityId}
                connectiondata={this.connectiondata}
            />,
            document.querySelector('.addtoexistingcontainer')
        );	
        $('.closenewscopeconnection').remove();
    }
    render(){
			var entitySourceName="";
			if(this.props.connectiondata!=undefined && this.props.connectiondata!=null && this.props.connectiondata!=""){
				entitySourceName = "<i class='fa fa-link'></i>"+JSON.parse(this.props.connectiondata).text;
            }
            _.each(this.props.metadata,(i,j)=>{
                var data = j.split("&&&");
                this.entity= data[1];
                this.conAccessId= data[0];
            })
            return(
                <DatahubItemSettingsHtml
                connectionaccessdata={this.props.connectiondata}
                    conAccessId={this.conAccessId}
                    entity={this.entity}
                    schemaName={this.props.schemaName}
                    entityId={this.props.entityId}
                    entitySourceName={entitySourceName}
                    close={this.closesettingscontainer}
                    addexistingcsvreport={this.addexistingcsvreport}
                    applydatahubmodifyentities={this.props.applydatahubmodifyentities}
                />
            );
    }
}