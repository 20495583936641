import React from 'react'
import _ from 'underscore';
import SendRequest from '../../SendRequest';
import * as SmartCharts from './smartinsightcharts'
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
const $ = window.$;
export default class SamrtInsightsFilterDrilldown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.state.filterArray = this.props.filter;
        this.state.targetColumn = this.props.yaxis;
        this.state.excludeColumn = ChartInfo.functions.getSmartInsightsExcludeValues();;
        this.state.xaxisDisplay = this.props.xaxisDisplay;
        this.state.yaxisDisplay = this.props.yaxisDisplay;
        this.state.htmlnlgdata = this.props.htmlnlgdata;
        this.state.reportId = "";
        var fragmentArray = window.location.pathname.split("/");
        //this.state.entities=fragmentArray[2];
        if (fragmentArray.length == 2) {
            if (fragmentArray[0] === "viewreport" || fragmentArray[0] === "sharereport" || fragmentArray[0] === "embeddedreport") {
                //	this.state.reportId = atob(fragmentArray[1]);	
            }
            if (fragmentArray[0] === "customviewreport") {
                this.state.entities = sessionStorage.getItem("entities");
            }
        }
        if (window.location.pathname.indexOf('viewstorybook') < 0) {
            if (fragmentArray.length == 5) {
                this.state.datamodelId = atob(fragmentArray[4]);
            } else if (fragmentArray.length > 6) {
                this.state.datamodelId = atob(fragmentArray[6]);
            } else if (fragmentArray.length > 5) {
                this.state.datamodelId = atob(fragmentArray[5]);
            }
        }
        var that = this;
        _.each($(".smartxaxisattributes li"), function (listObj) {
            var exclueValue = $(listObj).attr("data-name");
            that.state.excludeColumn = that.state.excludeColumn + ","
            if (that.state.excludeColumn != null || that.state.excludeColumn != undefined || that.state.excludeColumn != "") {
                that.state.excludeColumn += exclueValue;
            }
        });
        var changedExcludeColumn = that.state.excludeColumn;
        if(that.state.xaxisDisplay!=undefined&&that.state.xaxisDisplay !=''){
             changedExcludeColumn = changedExcludeColumn+ ","+that.state.xaxisDisplay 
        }
        this.state.tabid = 0
        this.state.isFromStoryBook = this.props.isFromStoryBook
        if (this.state.isFromStoryBook != undefined && this.state.isFromStoryBook) {
            this.state.reportId = this.props.reportId;
            this.state.datamodelId = this.props.datamodelId;
            this.state.tabid = this.props.tabid
        }
        if(this.state.targetColumn==undefined){
            this.state.targetColumn = this.state.yaxisDisplay;
        }
        $(".insightavgsuggestion1[tabid='" + this.state.tabid + "'] #spinnerchart9").show();
        var globalfilterArray = ChartInfo.functions.getFilterArray();
        var mapForGetDuplicateName = ChartInfo.functions.getRdisplyColumnMap();
        var storyboardSmartInsightFilterArray = ChartInfo.functions.getStoryboardSmartInsightFilterArray();
        globalfilterArray = globalfilterArray.concat(storyboardSmartInsightFilterArray);
        var reqdata = new FormData();
        reqdata.set("datamodelId", that.state.datamodelId);
        reqdata.set("globalfilterArray", JSON.stringify(globalfilterArray));
        reqdata.set("reportId", that.state.reportId != undefined && that.state.reportId != null ? that.state.reportId : "");
        reqdata.set("keyInfluencers", true);
        reqdata.set("filter", JSON.stringify(this.state.filterArray));
        reqdata.set("excludecolumn", changedExcludeColumn);
        reqdata.set("targetColumn", this.state.targetColumn);
        reqdata.set("mapForGetDuplicateName", mapForGetDuplicateName);
        reqdata.set("isFromRunModel", true);
        let selectedColumnsval = ChartInfo.functions.getSmartinsigtSelectedColumns();
        reqdata.set("selectedColumns",JSON.stringify(selectedColumnsval));
        reqdata.set("customFields",sessionStorage.getItem("customFields"))

        let requestHeaders = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getsmartinsights",
            body: reqdata,
            sucessFunction: (response) => {
                that.state.collection = response;
                that.render();
            },
            rejectFunction: this.reportDataError,
            headers: requestHeaders
        });
    }
    render() {
        if (this.state.collection.success == false) {
            $(".spinner").hide();
            $(".insightavgsuggestion1 #spinnerchart9").hide();
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('hide');
            $('.birdmessage h2').empty().append('Error : ');
            $('.birdmessage h2').append('<span class="errorcode">');
            $('.birdmessage .errorcode').empty().append(this.state.collection.errorCode);
            $('.birdmessage-info').empty().text(this.state.collection.error);
            $('.details').addClass('show');
            $('.messagedetails xmp').empty().append(this.state.collection.errorTrace)
        } else {
            $(".spinner").hide();
            var smartInsightObj = ChartInfo.functions.getSmartinsightDataObj();
            var smartData = JSON.parse(smartInsightObj["smarinsightData"]);
            var keyfilterdrilldownObj = {};
            var keyinfluencers = this.state.collection[1].keyinfluencers.data;
            var keyinfluencersCategories = this.state.collection[1].keyinfluencers.categories;
            //Sample Data Sets
            var yaxisname;
            var insightdata = [this.state.collection[1].insightQuality];
            var modelName = this.state.collection[1].modelname;
            var frameName = this.state.collection[1].framename;
            var targetColumnOriginal = this.state.collection[1].targetColumnOriginal;
            var pointname = this.props.filter[0].data;
            $(".insightavgsuggestion1[tabid='" + this.state.tabid + "']  h3").html("Key Influencers for <span>" + this.state.yaxisDisplay + "</span> with <span>" + this.state.xaxisDisplay + "</span> '<span>" + pointname + "</span>'");
            var renderto;
            $("#smartinsights0[tabid='" + this.state.tabid + "']").show()
            sessionStorage.setItem("smartinsightsfilter", JSON.stringify(this.state.filterArray));
            renderto = $(".insightavgsuggestion1[tabid='" + this.state.tabid + "'] .influenceschart");
            yaxisname = this.state.targetColumn;
            SmartCharts.charts.drawSmartAVGBar(keyinfluencers, keyinfluencersCategories, renderto, yaxisname, this.state.yaxisDisplay, targetColumnOriginal, this.state.tabid, this.state.reportId, this.state.datamodelId, this.state.isFromStoryBook);
            keyfilterdrilldownObj["keyinfluencers"] = keyinfluencers
            keyfilterdrilldownObj["keyinfluencersCategories"] = keyinfluencersCategories
            //keyfilterdrilldownObj["renderto"]= renderto
            keyfilterdrilldownObj["yaxisname"] = yaxisname
            keyfilterdrilldownObj["yaxisDisplay"] = this.state.yaxisDisplay
            keyfilterdrilldownObj["targetColumnOriginal"] = targetColumnOriginal
            smartData[8] = keyfilterdrilldownObj;
            var htmldata = $("<div />").append($(".insightnlg .influenceschart .nlg-list").clone()).html();
            var drilldowndata = $(".insightnlg .influenceschart").html()
            if (htmldata != "") {
                $(".insightnlg[tabid='" + this.state.tabid + "'] .influenceschart").html(htmldata);
            } else {
                $(".insightnlg[tabid='" + this.state.tabid + "'] .influenceschart").html(this.state.htmlnlgdata);
            }
            /*_.each(this.state.collection[2],function(data) {
                $(".insightnlg .influenceschart").append(data);
            });*/
            $(".insightavgsuggestion1[tabid='" + this.state.tabid + "'] #spinnerchart9").hide();
            var smartInsightDataObj = {};
            smartInsightDataObj["excludeColumn"] = this.state.excludeColumn;
            smartInsightDataObj["targeColumn"] = this.state.targetColumn;
            smartInsightDataObj["smarinsightData"] = JSON.stringify(smartData);
            ChartInfo.functions.setSmartinsightDataObj(smartInsightDataObj);
        }
        return (<div></div>);
    }
}