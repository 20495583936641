import _ from 'lodash';
import React from "react";
import LegendSeriesAttributesTemplate from "./legendseriesattributes.jsx"
import ReactDOM from "react-dom";
import $ from 'jquery/dist/jquery';

export default class LegendAttrModalView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicdiv:props.dynamicdiv,
			columnDisplayName : props.columnDisplayName,
			columnName : props.columnName,
			columnType : props.columnType,
			tableName:props.tableName,
			iscustomfunction: props.iscustomfunction
        };
        this.render();
    }
render(){
	ReactDOM.render(<LegendSeriesAttributesTemplate deletelegendaxis={this.deletelegendaxis} dynamicdiv={this.state.dynamicdiv} columnDisplayName={this.state.columnDisplayName}columnName={this.state.columnName}columnType={this.state.columnType}tableName={this.state.tableName}iscustomfunction={this.state.iscustomfunction}></LegendSeriesAttributesTemplate>,document.getElementById(this.state.dynamicdiv));
    return(<div></div>);
}
deletelegendaxis=(e)=>{
    var $this = e.currentTarget;
    $($this).closest('li').parent().remove();
}
}