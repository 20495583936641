/**
* 
* Module: Bird
* File Name: countryCode.js
* author: Sriyam Software Solutions
* Created Date: 2019-07-24
* Last Modified Date: 2020-01-06
* copy: Source Code Copyright to Sriyam Software Solutions LLP.
* 
*/

import _ from 'underscore';
var isoCountries = {
    'Afghanistan':'AF',
    'Aland Islands':'AX',
    'Albania':'AL',
    'Algeria':'DZ',
    'American Samoa':'AS',
    'Andorra':'AD',
    'Angola':'AO',
    'Anguilla':'AI',
    'Antarctica':'AQ',
    'Antigua And Barbuda':'AG',
    'Argentina':'AR',
    'Armenia':'AM',
    'Aruba':'AW',
    'Australia':'AU',
    'Austria':'AT',
    'Azerbaijan':'AZ',
    'Bahamas':'BS',
    'Bahrain':'BH',
    'Bangladesh':'BD',
    'Barbados':'BB',
    'Belarus':'BY',
    'Belgium':'BE',
    'Belize':'BZ',
    'Benin':'BJ',
    'Bermuda':'BM',
    'Bhutan':'BT',
    'Bolivia':'BO',
    'Bosnia And Herzegovina':'BA',
    'Botswana':'BW',
    'Bouvet Island':'BV',
    'Brazil':'BR',
    'British Indian Ocean Territory':'IO',
    'Brunei Darussalam':'BN',
    'Bulgaria':'BG',
    'Burkina Faso':'BF',
    'Burundi':'BI',
    'Cambodia':'KH',
    'Cameroon':'CM',
    'Canada':'CA',
    'Cape Verde':'CV',
    'Cayman Islands':'KY',
    'Central African Republic':'CF',
    'Chad':'TD',
    'Chile':'CL',
    'China':'CN',
    'Christmas Island':'CX',
    'Cocos (Keeling) Islands':'CC',
    'Colombia':'CO',
    'Comoros':'KM',
    'Congo':'CG',
    'Congo, Democratic Republic':'CD',
    'Cook Islands':'CK',
    'Costa Rica':'CR',
    'Cote D\'Ivoire':'CI',
    'Croatia':'HR',
    'Cuba':'CU',
    'Cyprus':'CY',
    'Czech Republic':'CZ',
    'Denmark':'DK',
    'Djibouti':'DJ',
    'Dominica':'DM',
    'Dominican Republic':'DO',
    'Ecuador':'EC',
    'Egypt':'EG',
    'El Salvador':'SV',
    'Equatorial Guinea':'GQ',
    'Eritrea':'ER',
    'Estonia':'EE',
    'Ethiopia':'ET',
    'Falkland Islands (Malvinas)':'FK',
    'Faroe Islands':'FO',
    'Fiji':'FJ',
    'Finland':'FI',
    'France':'FR',
    'French Guiana':'GF',
    'French Polynesia':'PF',
    'French Southern Territories':'TF',
    'Gabon':'GA',
    'Gambia':'GM',
    'Georgia':'GE',
    'Germany':'DE',
    'Ghana':'GH',
    'Gibraltar':'GI',
    'Greece':'GR',
    'Greenland':'GL',
    'Grenada':'GD',
    'Guadeloupe':'GP',
    'Guam':'GU',
    'Guatemala':'GT',
    'Guernsey':'GG',
    'Guinea':'GN',
    'Guinea-Bissau':'GW',
    'Guyana':'GY',
    'Haiti':'HT',
    'Heard Island & Mcdonald Islands':'HM',
    'Holy See (Vatican City State)':'VA',
    'Honduras':'HN',
    'Hong Kong':'HK',
    'Hungary':'HU',
    'Iceland':'IS',
    'India':'IN',
    'Indonesia':'ID',
    'Iran, Islamic Republic Of':'IR',
    'Iraq':'IQ',
    'Ireland':'IE',
    'Isle Of Man':'IM',
    'Israel':'IL',
    'Italy':'IT',
    'Jamaica':'JM',
    'Japan':'JP',
    'Jersey':'JE',
    'Jordan':'JO',
    'Kazakhstan':'KZ',
    'Kenya':'KE',
    'Kiribati':'KI',
    'Korea':'KR',
    'Kuwait':'KW',
    'Kyrgyzstan':'KG',
    'Lao People\'s Democratic Republic':'LA',
    'Latvia':'LV',
    'Lebanon':'LB',
    'Lesotho':'LS',
    'Liberia':'LR',
    'Libyan Arab Jamahiriya':'LY',
    'Liechtenstein':'LI',
    'Lithuania':'LT',
    'Luxembourg':'LU',
    'Libya':'LY',
    'Macao':'MO',
    'Macedonia':'MK',
    'Madagascar':'MG',
    'Malawi':'MW',
    'Malaysia':'MY',
    'Maldives':'MV',
    'Mali':'ML',
    'Malta':'MT',
    'Marshall Islands':'MH',
    'Martinique':'MQ',
    'Mauritania':'MR',
    'Mauritius':'MU',
    'Mayotte':'YT',
    'Mexico':'MX',
    'Micronesia,Federated States Of':'FM', 
    'Moldova':'MD',
    'Monaco':'MC',
    'Mongolia':'MN',
    'Montenegro':'ME',
    'Montserrat':'MS',
    'Morocco':'MA',
    'Mozambique':'MZ',
    'Myanmar':'MM',
    'Namibia':'NA',
    'Nauru':'NR',
    'Nepal':'NP',
    'Netherlands':'NL',
    'Netherlands Antilles':'AN',
    'New Caledonia':'NC',
    'New Zealand':'NZ',
    'Nicaragua':'NI',
    'Niger':'NE',
    'Nigeria':'NG',
    'Niue':'NU',
    'Norfolk Island':'NF',
    'Northern Mariana Islands':'MP',
    'Norway':'NO',
    'Oman':'OM',
    'Pakistan':'PK',
    'Palau':'PW',
    'Palestinian Territory, Occupied':'PS',
    'Panama':'PA',
    'Papua New Guinea':'PG',
    'Paraguay':'PY',
    'Peru':'PE',
    'Philippines':'PH',
    'Pitcairn':'PN',
    'Poland':'PL',
    'Portugal':'PT',
    'Puerto Rico':'PR',
    'Qatar':'QA',
    'Reunion':'RE',
    'Romania':'RO',
    'Russian Federation':'RU',
    'Rwanda':'RW',
    'Saint Barthelemy':'BL',
    'Saint Helena':'SH',
    'Saint Kitts And Nevis':'KN',
    'Saint Lucia':'LC',
    'Saint Martin':'MF',
    'Saint Pierre And Miquelon':'PM',
    'Saint Vincent And Grenadines':'VC',
    'Samoa':'WS',
    'San Marino':'SM',
    'Sao Tome And Principe':'ST',
    'Saudi Arabia':'SA',
    'Senegal':'SN',
    'Serbia':'RS',
    'Seychelles':'SC',
    'Sierra Leone':'SL',
    'Singapore':'SG',
    'Slovakia':'SK',
    'Slovenia':'SI',
    'Solomon Islands':'SB',
    'Somalia':'SO',
    'South Africa':'ZA',
    'South Georgia And Sandwich Isl.':'GS',
    'Spain':'ES',
    'Sri Lanka':'LK',
    'Sudan':'SD',
    'Suriname':'SR',
    'Svalbard And Jan Mayen':'SJ',
    'Swaziland':'SZ',
    'Sweden':'SE',
    'Switzerland':'CH',
    'Syrian Arab Republic':'SY',
    'Syria':'SY',
    'Taiwan':'TW',
    'Tajikistan':'TJ',
    'Tanzania':'TZ',
    'Thailand':'TH',
    'Timor-Leste':'TL',
    'Togo':'TG',
    'Tokelau':'TK',
    'Tonga':'TO',
    'Trinidad And Tobago':'TT',
    'Tunisia':'TN',
    'Turkey':'TR',
    'Turkmenistan':'TM',
    'Turks And Caicos Islands':'TC',
    'Tuvalu':'TV',
    'Uganda':'UG',
    'Ukraine':'UA',
    'United Arab Emirates':'AE',
    'United Kingdom':'GB',
    'United States':'US',
    'United States Outlying Islands':'UM',
    'Uruguay':'UY',
    'Uzbekistan':'UZ',
    'Vanuatu':'VU',
    'Venezuela':'VE',
    'Viet Nam':'VN',
    'Virgin Islands, British':'VG',
    'Virgin Islands, U.S.':'VI',
    'Wallis And Futuna':'WF',
    'Western Sahara':'EH',
    'Yemen':'YE',
    'Zambia':'ZM',
    'Zimbabwe':'ZW'

};

export function getCountryName (countryCode) {
if(countryCode != undefined){
   countryCode = countryCode.trim();
}
if (isoCountries.hasOwnProperty(countryCode)) {	
   return isoCountries[countryCode].toLowerCase();
} else {
if((_.invert(isoCountries))[countryCode.toUpperCase()]!=undefined)	
   return countryCode.toLowerCase();
}
}

var States = {
   'Batticaloa':'lk-bc',
   'Mannar':'lk-mb',
   'Jaffna':'lk-ja',
   'Kilinochchi':'lk-kl',
   'Ampara':'lk-ap',
   'Kandi':'lk-ky',
   'Matale':'lk-mt',
   'Nuwara Eliya':'lk-nw',
   'Polonnaruwa':'lk-pr',
   'Trincomalee':'lk-tc',
   'Anuradhapura':'lk-ad',
   'Vavuniya':'lk-va',
   'Mullaitivu':'lk-mp',
   'Kurunegala':'lk-kg',
   'Kegalle':'lk-ke',
   'Puttalam':'lk-px',
   'Ratnapura':'lk-rn',
   'Galle':'lk-gl',
   'Hambantota':'lk-hb',
   'Matara':'lk-mh',
   'Mullativu':'lk-mp',
   'Badulla':'lk-bd',
   'Kalutara':'lk-kt',
   'Monaragala':'lk-mj',
   'Nuwara-Eliya':'lk-nw',
   'Kegalla':'lk-ke',
   'Kandy':'lk-ky',
   'Gampaha':'lk-gq',
   'Colombo':'lk-co',
   'Vavunia':'lk-va',
   'Hambantota':'lk-hb',
       
    'Alabama':'us-AL',
    'Alaska':'us-AK',
    'Arizona':'us-AZ',
    'Arkansas':'us-AR',
    'California':'us-CA',
    'Colorado':'us-CO',
    'Connecticut':'us-CT',
    'Delaware':'us-DE',
    'District Of Columbia':'us-DC',
    'Florida':'us-FL',
    'Georgia':'us-GA',
    'Hawaii':'us-HI',
    'Idaho':'us-ID',
    'Illinois':'us-IL',
    'Indiana':'us-IN',
    'Iowa':'us-IA',
    'Kansas':'us-KS',
    'Kentucky':'us-KY',
    'Louisiana':'us-LA',
    'Maine':'us-ME',
    'Maryland':'us-MD',
    'Massachusetts':'us-MA',
    'Michigan':'us-MI',
    'Minnesota':'us-MN',
    'Mississippi':'us-MS',
    'Missouri':'us-MO',
    'Montana':'us-MT',
    'Nebraska':'us-NE',
    'Nevada':'us-NV',
    'New Hampshire':'us-NH',
    'New Jersey':'us-NJ',
    'New Mexico':'us-NM',
    'New York':'us-NY',
    'North Carolina':'us-NC',
    'North Dakota':'us-ND',
    'Ohio':'us-OH',
    'Oklahoma':'us-OK',
    'Oregon':'us-OR',
    'Pennsylvania':'us-PA',
    'Rhode Island':'us-RI',
    'South Carolina':'us-SC',
    'South Dakota':'us-SD',
    'Tennessee':'us-TN',
    'Texas':'us-TX',
    'Utah':'us-UT',
    'Vermont':'us-VT',
    'Virginia':'us-VA',
    'Washington':'us-WA',
    'West Virginia':'us-WV',
    'Wisconsin':'us-WI',
    'Wyoming':'us-WY',

};
export function getStateName (stateCode) {
if(stateCode != undefined){
   stateCode = stateCode.trim();
}
if (States.hasOwnProperty(stateCode)) {
    var statecodes=States[stateCode].toLowerCase();
    var country=statecodes.split('-');
    if(country[0]=="lk"){
        return States[stateCode].toLowerCase();
    }
    else if(country[0]=="us"){
        return States[stateCode].toLowerCase();
    }
 
}else {
        
    }
 //return stateCode.toLowerCase();

}

var IndiaStates = {
    'andaman and nicobar':'andaman and nicobar',
    'andaman and nicobar islands':'andaman and nicobar',
    'an':'andaman and nicobar',
    'andhra pradesh':'andhra pradesh',
    'ap':'andhra pradesh',
    'arunachal pradesh':'arunanchal pradesh',
    'ar':'arunanchal pradesh',
    'assam':'assam',
    'as':'assam',
    'bihar':'bihar',
    'br':'bihar',
    'chandigarh':'chandigarh',
    'ch':'chandigarh',
    'chhattisgarh':'chhattisgarh',
    'ct':'chhattisgarh',
    'dadara and nagar havelli':'dadara and nagar havelli',
    'daman and diu':'daman and diu',
    'da':'daman and diu',
    'goa':'goa',
    'ga':'goa',
    'gujarat':'gujarat',
    'gj':'gujarat',
    'haryana':'haryana',
    'hr':'haryana',
    'himachal pradesh':'himachal pradesh',
    'hp':'himachal pradesh',
    'jammu and kashmir':'jammu and kashmir',
    'jk':'jammu and kashmir',
    'jharkhand':'jharkhand',
    'jh':'jharkhand',
    'karnataka':'karnataka',
    'ka':'karnataka',
    'kerala':'kerala',
    'kl':'kerala',
    'lakshadweep':'lakshadweep',
    'la':'lakshadweep',
    'madhya pradesh':'madhya pradesh',
    'mp':'madhya pradesh',
    'maharashtra':'maharashtra',
    'mh':'maharashtra',
    'manipur':'manipur',
    'mn':'manipur',
    'meghalaya':'meghalaya',
    'me':'meghalaya',
    'mizoram':'mizoram',
    'mz':'mizoram',
    'nagaland':'nagaland',
    'nl':'nagaland',
    'national capital territory of delhi':'nct of delhi',
    'delhi':'nct of delhi',
    'dl':'nct of delhi',
    'odisha':'odisha',
    'or':'odisha',
    'puducherry':'puducherry',
    'pu':'puducherry',
    'punjab':'punjab',
    'pb':'punjab',
    'rajasthan':'rajasthan',
    'rj':'rajasthan',
    'sikkim':'sikkim',
    'sk':'sikkim',
    'tamil nadu':'tamil nadu',
    'tn':'tamil nadu',
    'telangana':'telangana',
    'tg':'telangana',
    'tripura':'tripura',
    'tr':'tripura',
    'uttar pradesh':'uttar pradesh',
    'up':'uttar pradesh',
    'uttarakhand':'uttarakhand',
    'ut':'uttarakhand',
    'west bengal':'west bengal',
    'wb':'west bengal'
}
export function getIndiaStateName (stateCode) {
if(stateCode != undefined){
stateCode = stateCode.trim();
}
if (IndiaStates.hasOwnProperty(stateCode)) {
 var statecodes=IndiaStates[stateCode].toLowerCase();		
return IndiaStates[stateCode].toLowerCase();	
}else {
     
 }

}
