import React, { Component } from "react";
import ReactDOM from 'react-dom';
import NewRolesViewTemplate from "./newrolehtml.jsx";
import SendRequest from '../../SendRequest';
const $ =window.$;
export default class NewRolesView extends Component {
  constructor(props) {
    super(props);
      this.state = { };
    let featuresString = sessionStorage.getItem("features");
    featuresString = featuresString.slice(0, 3) + featuresString.slice(4);
    let decryptedString = window.atob(featuresString);
    this.state.parsedFeatureJson = JSON.parse(decryptedString);
    this.state.features= JSON.parse(decryptedString); 
  
    this.render();
  }
  render(){
    ReactDOM.render(<NewRolesViewTemplate roles={this.props.roles}  features={this.state.features}/>,document.querySelector(".newroleform"));
return(<div></div>)
  }
}