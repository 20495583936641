import React from 'react'
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;
export default class StoryboardFiltersListItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    componentDidMount() {
        $('.filtercheckboxitem').click(function (e) {
            e.stopPropagation();
        })
    }
    render() {
        var filteritem = this.props.filteritem;
        var itemtype = lang["storyboard.abc"];
        if (filteritem.dataType == "number") {
            itemtype = "123"
        } else if (filteritem.dataType == "date" || filteritem.dataType == "datetime") {
            itemtype = <i className='fa fa-calendar'></i>
        }
        return (
            <li className="boc-list-item xs-list-item storyboardfiltertoggleitem" data-type={filteritem.dataType} chartid={filteritem.chartid} columndisplayname={filteritem.columndisplayname}>
                <label title={filteritem.columndisplayname} className=""> <span className="itemtype">{itemtype}</span>{filteritem.columndisplayname}</label>
                <div className="drilldowntogglecheckbox filtercheckboxitem">
                    <div className="checkbox checkbox-slider--c checkbox-slider-sm">
                        <label><input type="checkbox" chart-id={filteritem.chartid} data-columndisplayname={filteritem.columndisplayname} data-columnname={filteritem.columnname} data-dataType={filteritem.dataType} /><span>&nbsp;</span></label>
                    </div>
                </div>
            </li>);
    }
}
