import _ from 'lodash';
import React from "react";
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import { lang } from "../js/helpers/utils/languages/language";
import ReactDOM from "react-dom";
import { getMessage } from '../js/helpers/utils/BirdMessage';
import CustomRangeTemplate from "./customrange.jsx"
import CustomRangeDropDown from "./customrangedropdownview"
import addDimensionToCustomHierarchyModa from "./adddimensiontohierarchymodal"
import customRangeFieldView from "./customrangefieldview"
const $ = window.$;

export default class CreateRange extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chartnumber: props.chartnumber,
        };
        this.addcustomrangevalue = this.addcustomrangevalue.bind(this);
        this.render();
    }
    render() {
        var that = this;
        this.state.el = ".append_customrange" + this.props.chartnumber;
        var convertedNames = JSON.parse(sessionStorage.getItem("convertedtypes"));
        var jsonObject = {};
        _.each(convertedNames, function (array) {
            jsonObject[array.columnName] = array.dataType;
        });
        var result = $.grep(ChartInfo.functions.getRange(), function (e) { return e.name == that.props.columnName });
        if (result != undefined && result.length > 0) {
            this.state.oldName = result[0].name;
        }
        var convertedNames = JSON.parse(sessionStorage.getItem('renameMeasuresorDimensions'));
        ReactDOM.render(<CustomRangeTemplate closeCustomRange={this.closeCustomRange} addtextbox={that.addtextbox} deleterange={this.deleterange}
            applyrange={this.applyrange} dropdownrangelist={this.dropdownrangelist} chartnumber={this.state.chartnumber} existingrange={result} convertedTypes={jsonObject} convertedNames={convertedNames}
        ></CustomRangeTemplate>, document.querySelector(".append_customrange" + this.props.chartnumber));

        return (<div></div>);
    }
    closeCustomRange = () => {
        ReactDOM.unmountComponentAtNode(document.querySelector(".append_customrange" + this.props.chartnumber));
    }
    addtextbox = () => {
        var that = this;
        var template = "<div id=\"range\" class=\"marg-top-5 marg-bot-5\">\n\t<div class=\"container-fluid nopadding\">\n\t\t<div class=\"col-xs-5 nopadding\">\n\t\t\t<input type=\"number\" name=\"from\" placeholder=\"From\"class=\"rangebox\" step=\"any\">\n\t\t</div>\n\t\t<div class=\"col-xs-5 nopadding\">\n\t\t\t<input class=\"rangebox\" name=\"to\" placeholder=\"To\" type=\"number\" step=\"any\">\n\t\t</div>\n\t\t<div class=\"col-xs-2 text-right nopadding\">\n\t\t\t<span type=\"button\" data-name=\"delete\" title=\"deleterange\" class=\"deleterange\"> <i class=\"fa fa-trash\"></i></span>\n\t\t</div>\n\t\t<div class=\"clear\"></div>\n\t\t<span class=\"help-block hide customrange_valid\">"+lang["storyboard.valuecompare"]+"</span>\n\t</div>\n</div>"
        $("#appendrangetextbox").append(template);
        $('.help-block.customrange_valid').addClass('hide');
        $(".deleterange").click(function (e) {
            e.preventDefault();
            that.deleterange(e);
        })
    }
    deleterange(e) {
        e.preventDefault();
        var $this = e.currentTarget;
        $($this).closest("#range").remove();
        if ($("#appendrangetextbox>#range").length === 0) { $("#appendrangetextbox").empty() }
    }
    dropdownrangelist = (e) => {
        e.preventDefault();
        var that = this;
        $(".dropdownrangelist").empty();
        $(".dropdownrangelist").append('<li class="xs-list-item"> <input class="form-control form-control-hierarchydropdown search-image" placeholder='+lang["storyboard.search"]+' id="searchinput" type="text"></li>');
        _.each($(".attributesdimensions"), function (e) {
            var $this = e;
            if (!$($this).hasClass("customhierarchyfields") && !$($this).hasClass("customrangefields")) {
                var today = new Date();
                var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                $('.dropdownrangelist').append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                var xAxisDropDown = new CustomRangeDropDown({
                    dynamicdiv: dynamicdiv,
                    addcustomrangevalue: that.addcustomrangevalue,
                    columnDisplayName: $($this).attr('title'),
                    columnName: $($this).attr("data-name"),
                    dataType: $($this).attr("data-type"),
                    tableName: $($this).attr("table-name"),
                    iscustomfunction: $($this).attr("iscustomfunction"),
                    cameFrom: "rangelistdrop"
                });
                $(".dropdownrangelist").append($("#" + dynamicdiv));
                $('.form-control-hierarchydropdown').click(function (e) {
                    e.stopPropagation();
                });
                $('.form-control-hierarchydropdown').keyup(function (e) {
                    e.stopPropagation();
                    var $this = e.currentTarget;
                    var valThis = $($this).val().toLowerCase();
                    $(".itemname").parents(".hierarchyitem").each(function () {
                        var text = $(this).attr("title").toLowerCase();
                        (text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
                    });
                });
            }
        });
    }
    addcustomrangevalue = (e) => {
        e.preventDefault();
        var $this = e.currentTarget;
        var columnName = $($this).attr("data-name");
        var columnDisplayName = $($this).attr("data-display-name");
        var datatype = $($this).attr("data-type");
        var tableName = $($this).attr("table-name");
        var iscustomfunction = $($this).attr("iscustomfunction");
        var today = new Date();
        var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
        $(".customhierarchyattributes").append("<div className='" + dynamicdiv + "hide' id='" + dynamicdiv + "'></div>");
        var xaxisAttrView = new addDimensionToCustomHierarchyModa({
            dynamicdiv: dynamicdiv,
            xaxiscolumnDisplayName: columnDisplayName,
            xaxiscolumnName: columnName,
            xaxiscolumnType: datatype,
            xaxistableName: tableName,
            iscustomfunction: iscustomfunction,
            cameFrom: "rangelistdrop"
        });
        $(".customhierarchyattributes").append($("#" + dynamicdiv));
        if ($('.rangeattributes li').length == 1 && $("#range").length == 0) {
            this.addtextbox();
        }
    }
    applyrange = (e) => {
        e.preventDefault();
        var count = 0;
        var that = this;
        this.state.close = true;
        $('#appendrangetextbox #range').find('[name=from]').each(function (index, value) {
            if ($(value).val().length <= 0) {
                that.state.close = false;
                $(value).addClass('bird-has-error');
                count++;
            }
        });
        $('#appendrangetextbox #range').find('[name=to]').each(function (index, value) {
            if ($(value).val().length <= 0) {
                that.state.close = false;
                $(value).addClass('bird-has-error');
                count++;
            }
        });
        $('#appendrangetextbox #range').find('[name=from]').each(function (index, value) {

        });
        var columnName = $('#range_name').val().trim().replace(/([#;?&,%. +*~\':"!^$%[\]()<=>|\/@])/g, "\\$1");
        if (ChartInfo.functions.isScriptData(columnName)) {
            return true;
        }
        var columnName=$('#range_name').val().trim().replace(/([#;?&,%. +*~\':"!^$%[\]()<=>|\/@])/g, "\\$1");
			var status=false;
			var reservedwordCheck=columnName.toLowerCase();
			var reservedwordslist=JSON.parse(sessionStorage.getItem("reservedwordslist"));
			_.each(reservedwordslist,function(reservedword,j){
				if(reservedwordCheck==reservedword){
				status=true
				}
			});					
		if(status== true){
			$(".spinner").hide();
			$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
			$('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
			$('.birdmessagebuttons').removeClass('show');
			$('.birdmessagebuttons').addClass('hide');
			$('.birdmessage-center').addClass("message-top-in");
			$('.details').removeClass('show');
			$('.details').addClass('hide');
			$('.birdmessage h2').empty().append('Warning');
			$('.birdmessage-info').empty().text("Custom Range "+ ""+getMessage('BirdWarning147'));
			$(".spinner").hide();
			//$('.applyattributes').addClass("disabled");
			return;
		}
        if (this.state.oldName != undefined && this.state.oldName.trim() == $('#range_name').val().trim()) {
            if (!$('#range_name').val().trim().length > 0 || ($('.attributesdimensions[title="' + columnName + '"]').length > 0
                && $('.customrangefields[title="' + columnName + '"]').length > 1) || $('.customhierarchyfields[title="' + columnName + '"]').length > 0
                || $('.customaggregatedfields[title="' + columnName + '"]').length > 0 || $('.custommeasurehierarchyfields[title="' + columnName + '"]').length > 0
                || $('.customparameterfield[title="' + columnName + '"]').length > 0) {
                $('.help-block.customrange').removeClass('hide');
                $('.help-block.customrange_li_items').addClass('hide');
            } else if (!$('.rangeattributes li').length > 0) {
                $('.help-block.customrange_li_items').removeClass('hide');
                $('.help-block.customrange').addClass('hide');
            } else if (!$('#appendrangetextbox #range').length > 0) {
                that.state.close = false;
                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Warning');
                $('.birdmessage-info').empty().text(lang["storyboard.addonerange"]);
                return false;
            }
            else if (count == 0) {
                this.createCustomHierarchyField(e);
            } else {
                $('.help-block.customrange_li_items').addClass('hide');
                $('.help-block.customrange').addClass('hide');
            }
        } else {
            if (!$('#range_name').val().trim().length > 0 || $('.attributesdimensions[title="' + columnName + '"][data-type!="customrange"]').length > 0
                || $('.customrangefields[title="' + columnName + '"]').length > 0 || $('.customhierarchyfields[title="' + columnName + '"]').length > 0) {
                this.state.close = false;
                $('.help-block.customrange').removeClass('hide');
                $('.help-block.customrange_li_items').addClass('hide');
            } else if (!$('.rangeattributes li').length > 0) {
                this.state.close = false;
                $('.help-block.customrange_li_items').removeClass('hide');
                $('.help-block.customrange').addClass('hide');
            } else if (!$('#appendrangetextbox #range').length > 0) {
                that.state.close = false;
                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Warning');
                $('.birdmessage-info').empty().text(getMessage('BirdWarning115'));
                return false;
            } else if (count == 0) {
                this.createCustomHierarchyField(e);
            } else {
                $('.help-block.customrange_li_items').addClass('hide');
                $('.help-block.customrange').addClass('hide');
            }
        }
        if (this.state.close) {
            this.closeCustomRange();
        }
    }
    createCustomHierarchyField() {
        var fieldNameTest = $('#range_name').val().trim();
        if ((/[!@#$%^&*()+\=\[\]{};':\\|,.<>\/?`"]/.test(fieldNameTest)) ||
            (fieldNameTest.toLowerCase().trim() == "min" || fieldNameTest.toLowerCase().trim() == "max"
                || fieldNameTest.toLowerCase().trim() == "avg" || fieldNameTest.toLowerCase().trim() == "sum"
                || fieldNameTest.toLowerCase().trim() == "count" || fieldNameTest.toLowerCase().trim() == "avgerage")) {
            this.state.close = false;
            $(".spinner").hide();
            $(".gridspinner").hide();
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Information');
            $('.birdmessage-info').empty().text(getMessage('BirdInformation46'));
            return;
        }
        var that = this;
        var jsonobj = {};
        var hierarchyListArray = [];
        var xAxisArray = [];
        var jsonarray = [];
        var result = false;
        $("#appendrangetextbox #range").each(function (index, list) {
            var jsonobjlist = {};
            jsonobjlist['from'] = $(list).find('input[name=from]').val();
            jsonobjlist['to'] = $(list).find('input[name=to]').val();
            if (parseInt(jsonobjlist['from']) > parseInt(jsonobjlist['to'])) {
                $($('#appendrangetextbox #range')[index]).find('[name=from]').addClass('bird-has-error');
                $($('#appendrangetextbox #range')[index]).find('[name=to]').addClass('bird-has-error');
                $($(".customrange_valid")[index]).removeClass("hide")
                result = true;
            }
            hierarchyListArray.push(jsonobjlist);
        });
        if (result) {
            that.state.close=false;
            return result;
        }
        var columnName = $('#range_name').val().trim();
        $('.help-block.customrange').addClass('hide');
        $('.help-block.customrange_li_items').addClass('hide');
        var deletevalue = $.grep(ChartInfo.functions.getRange(), function (e) { return e.name != that.state.oldName });
        if (deletevalue.length >= 0) {
            $('.customrangefields[title="' + this.state.oldName + '"]').remove();
            ChartInfo.functions.setRange(deletevalue);
        }
        var jsonarray = $.grep(ChartInfo.functions.getRange(), function (e) { return e.name != $('#range_name').val().trim() });
        jsonobj['name'] = $('#range_name').val().trim();
        $('.customrangefields[data-name="' + columnName + '"]').remove();
        jsonobj['rows'] = hierarchyListArray
        $('.rangeattributes #itemdisplay').each(function (k, list) {
            var jsonobjlist = {};
            jsonobjlist['columnDisplayName'] = $(list).attr('title');
            jsonobjlist["columnName"] = $(list).attr('data-name');
            jsonobjlist['tableName'] = $(list).attr('table-name');
            jsonobjlist['dataType'] = $(list).attr('data-type');
            xAxisArray.push(jsonobjlist);
        });
        jsonobj['xAxisName'] = xAxisArray;
        jsonarray.push(jsonobj);
        console.log(jsonarray);
        var today = new Date();
        var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
        $('.customrangedimension').append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
        var customRangefieldView = new customRangeFieldView({
            dynamicdiv: dynamicdiv,
            columnDisplayName: $('#range_name').val().trim(),
            columnName: $('#range_name').val().trim()
        });
        var fieldName = $('#range_name').val().trim();
        $(".attributescustomfields[data-name='" + that.state.oldName + "']").remove();
        var renameXaxisName = $('.xaxisattributes li[data-name="' + columnName + '"]');
        $(renameXaxisName).attr('data-name', fieldName);
        $(renameXaxisName).attr('title', fieldName);
        $(renameXaxisName).find('.itemname').text(fieldName);
        var renameXaxisName = $('.xaxisattributes li[data-name="' + that.state.oldName + '"]');
        $(renameXaxisName).attr('data-name', fieldName);
        $(renameXaxisName).attr('title', fieldName);
        $(renameXaxisName).find('.itemname').text(fieldName);
        if (that.state.oldName != undefined && that.state.oldName != fieldName) {
            $('.createrange [data-edit="edit"], [data-name="' + that.state.oldName + '"]').attr("data-name", fieldName);
            this.redrawChartIfRangeisOnXaxis(fieldName);
        }
        ChartInfo.functions.updateAxisName(that.state.oldName, fieldName, 'xAxis');
        $(".customrangedimension").append($("#" + dynamicdiv));
        $(".dimensioncount").text(parseInt($('.dimensioncount').text()) + 1);
        ChartInfo.functions.setRange(jsonarray);
        if (that.state.oldName != undefined && that.state.oldName != fieldName) {
            this.redrawChartIfRangeisOnXaxis(fieldName);
        }
        if (that.props.multifact == "true") {
            $("ul.xaxisattributes").empty();
        }
        $(".customrange" + this.state.chartnumber + "").modal("hide");
    }
    redrawChartIfRangeisOnXaxis(rangeName) {
        var chartInfoDetails = ChartInfo.functions.getAllChartDetails();
        var that = this;
        _.each(chartInfoDetails, function (i, j) {
            var storedObject = i;

            if (j != that.state.chartnumber) {
                var storedParsedObject = JSON.parse(storedObject);
                var axisData = storedParsedObject["xAxis"];
                _.each(axisData, function (axis, index) {
                    if (axisData[index].columnName == rangeName) {
                        $('.refreshsinglecharts1[data-num=' + j + ']').eq(0).click();
                        return false
                    }
                })
            }
        });
    }
}