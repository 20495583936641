import React from "react";
import _ from 'lodash';
import SendRequest from '../SendRequest';
import PubSub from 'pubsub-js'
import { getMessage } from '../js/helpers/utils/BirdMessage';
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import AttrDimensionModalTemplate from "./attributesdimensions.jsx";
import { lang } from "../js/helpers/utils/languages/language";
import ReactDOM from "react-dom";

const $ = window.$;
export default class AttrModalDimensionsview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicdiv: props.dynamicdiv,
            features: props.features,
            broker: props.broker,
            columnDisplayName: props.columnDisplayName,
            columnName: props.columnName,
            tableName: props.tableName,
            dataType: props.dataType,
            chartData: props.jsonData,
            isDirect: ChartInfo.functions.getIsDirect(),
            isStoredProcedure: ChartInfo.functions.getIsStoredProcedure(),
            reportId: props.reportId,
            datamodelId: props.datamodelId
        };
        this.render();
    }
    render() {
        const elementTarget = document.getElementById(this.state.dynamicdiv);
        if(elementTarget) {
            ReactDOM.render(<AttrDimensionModalTemplate createdatedropdown={this.createdatedropdown} datamodelId={this.state.datamodelId} reportId={this.state.reportId} dynamicdiv={this.props.dynamicdiv} columnDisplayName={this.state.columnDisplayName} columnName={this.state.columnName}
            dataType={this.state.dataType} converted={this.props.converted} tableName={this.state.tableName} allfeatures={this.state.features} isDirect={this.state.isDirect} isStoredProcedure={this.state.isStoredProcedure}/>, elementTarget);
        }else{
            console.log(this.state.dynamicdiv);
        }
            //this.$el.append(this.template({columnDisplayName:this.columnDisplayName,columnName:this.columnName,
        //	dataType:this.dataType,converted:this.props.converted,tableName:this.tableName,allfeatures:this.features,isDirect:this.isDirect,isStoredProcedure:this.isStoredProcedure}));

        return (<div></div>);
    }
    createdatedropdown = (e) => {
        var that = this;
        var $this = e.currentTarget;
        var lihtml = ""
        var datefunctionsMap = {};
        var tempdatefunctionsMap = {};
        var datefunctionsFormulasArray = ['toDateTime(@replace)', 'toYear(@replace)', 'toMonth(@replace)', 'toDayOfMonth(@replace)', 'toDayOfWeek(@replace)', 'toHour(@replace)', 'toMinute(@replace)', 'toSecond(@replace)', 'toMonday(@replace)', 'toStartOfMonth(@replace)', 'toStartOfQuarter(@replace)', 'toStartOfYear(@replace)', 'toStartOfMinute(@replace)',
            'toStartOfFiveMinute(@replace)', 'toStartOfHour(@replace)', 'toStartOfDay(@replace)', 'toTime(@replace)', 'toRelativeYearNum(@replace)', 'toRelativeMonthNum(@replace)', 'toRelativeWeekNum(@replace)', 'toRelativeDayNum(@replace)',
            'toRelativeHourNum(@replace)', 'toRelativeMinuteNum(@replace)', 'toRelativeSecondNum(@replace)', "formatDateTime(@replace,'%D')", "formatDateTime(@replace,'%F')", "formatDateTime(@replace,'%m/%d/%y')", "formatDateTime(@replace,'%d/%m/%Y')", "formatDateTime(@replace,'%d-%m-%Y')", "toDate(@replace)-toStartOfQuarter(@replace)", "formatDateTime(@replace,'%m/%Y')",
            'toQuarter(@replace)', 'toWeek(@replace)',
            "Case When toDayOfWeek(@replace) = 1 Then  'Mon' When toDayOfWeek(@replace) = 2 Then  'Tue' When toDayOfWeek(@replace) = 3 Then  'Wed' When toDayOfWeek(@replace) = 4 Then  'Thu' When toDayOfWeek(@replace) = 5 Then  'Fri' When toDayOfWeek(@replace) = 6 Then  'Sat' Else 'Sunday' End ",
            "Case When toDayOfWeek(@replace) = 1 Then  'Monday' When toDayOfWeek(@replace) = 2 Then  'Tuesday' When toDayOfWeek(@replace) = 3 Then  'Wednesday' When toDayOfWeek(@replace) = 4 Then  'Thursday' When toDayOfWeek(@replace) = 5 Then  'Friday' When toDayOfWeek(@replace) = 6 Then  'Saturday'  Else 'Sunday' End ",
            "Case  When  toMonth(@replace) = 1 Then 'Jan' When  toMonth(@replace) = 2 Then 'Feb' When  toMonth(@replace) = 3 Then 'Mar' When  toMonth(@replace) = 4 Then 'Apr' When  toMonth(@replace) = 5 Then 'May' When  toMonth(@replace) = 6 Then 'Jun' When  toMonth(@replace) = 7 Then 'Jul' When  toMonth(@replace) = 8 Then 'Aug' When  toMonth(@replace) = 9 Then 'Sep' When  toMonth(@replace) = 10 Then 'Oct' When  toMonth(@replace) = 11 Then 'Nov' Else 'Dec' End || ' ' || formatDateTime(@replace,'%Y') ",
            "Case  When  toMonth(@replace) = 1 Then 'January' When  toMonth(@replace) = 2 Then 'February' When  toMonth(@replace) = 3 Then 'March' When  toMonth(@replace) = 4 Then 'April' When  toMonth(@replace) = 5 Then 'May' When  toMonth(@replace) = 6 Then 'June' When  toMonth(@replace) = 7 Then 'July'  When  toMonth(@replace) = 8 Then 'August'  When  toMonth(@replace) = 9 Then 'September' When  toMonth(@replace) = 10 Then 'October' When  toMonth(@replace) = 11 Then 'November' Else 'December' End || ' ' || formatDateTime(@replace,'%Y') "];
        var datefunctionsNamesArray = ['toDateTime()', 'toYear()', 'toMonth()', 'toDayOfMonth()', 'toDayOfWeek()', 'toHour()', 'toMinute()', 'toSecond()', 'toMonday()', 'toStartOfMonth()', 'toStartOfQuarter()', 'toStartOfYear()', 'toStartOfMinute()',
            'toStartOfFiveMinute()', 'toStartOfHour()', 'toStartOfDay()', 'toTime()', 'toRelativeYearNum()', 'toRelativeMonthNum()', 'toRelativeWeekNum()', 'toRelativeDayNum()',
            'toRelativeHourNum()', 'toRelativeMinuteNum()', 'toRelativeSecondNum()', 'Date(mm/dd/yyyy)', 'Date(yyyy/dd/mm)', 'Date(m/d/yy)', 'Date(dd/mm/yyyy)', 'Date(dd-mm-yyyy)', 'toDayOfQuarter()', 'Date(Month/year)',
            'toQuarter()', 'toWeek()', 'toDayOfWeek(Mon-Sun)',
            'toDayOfWeek(Monday-Sunday)', 'toMonth(Jan-Dec)', 'toMonth(January-December)',];
        _.each(datefunctionsNamesArray, function (val, index) {
            datefunctionsMap[datefunctionsNamesArray[index]] = datefunctionsFormulasArray[index]
        });
        ChartInfo.functions.setDatefunctionsMap(datefunctionsMap);
        tempdatefunctionsMap = _.invert(datefunctionsMap);
        _.each(datefunctionsFormulasArray, function (val, index) {
            var name = $($this).attr('data-value') + '_' + tempdatefunctionsMap[datefunctionsFormulasArray[index]].replace('()', '')
            lihtml += '<li class="aggregation-li-item createdateitem" title='+lang["storyboard.create"]+' ' + name + '" data-name="' + $($this).attr('data-name') + '" data-value="' + name + '"><span class="function marg-righ-5">'+lang["storyboard.fx"]+'</span>' + tempdatefunctionsMap[datefunctionsFormulasArray[index]] + '</li>';
        });
        $(".createdatedropdownul").empty();
        $(".createdatedropdownul").append(lihtml);
        if ($('.createdatedropdown').parent().hasClass('open')) {
            $('.createdatedropdown').parent().removeClass('open');
        } else {
            $($this).parent().toggleClass('open');
        }
        $(".createdateitem").click(function (e) {
            that.createdateitem(e);
        });
    }
    createdateitem(e) {
        var $this = e.currentTarget;
        var that = this;
        var customFields = sessionStorage.getItem("customFields");
        var customObject = [], fieldNamesArray = [];
        var tempExp = "";
        var priorityColumn = false;
        var fieldName = ""
        var matchCount = 0;
        if (customFields != undefined && customFields != null && customFields != 'null') {
            customObject = JSON.parse(customFields);
            _.each(customObject, function (val) {
                if (val.name != undefined && (val.name.indexOf($($this).attr('data-value')) != -1 || ($($this).attr('data-value').indexOf(".") != -1 && val.name.indexOf($($this).attr('data-value').split(".")[1]) != -1))) {
                    matchCount++;
                }
                fieldNamesArray.push(val.name);
            });
        }
        var datefunctionsMap = ChartInfo.functions.getDatefunctionsMap();
        var usedFeildArray = [];
        var timeinMilliseconds = new Date().getTime();
        if (fieldNamesArray.indexOf($($this).attr('data-value')) != -1) {
            fieldName = $($this).attr('data-value') + "_" + matchCount;
        } else {
            fieldName = $($this).attr('data-value');
        }
        if (fieldName.indexOf(".") != -1) {
            if (fieldNamesArray.indexOf(fieldName.split(".")[1]) != -1) {
                fieldName = fieldName.split(".")[1] + "_" + matchCount;
            }
            else {
                fieldName = fieldName.split(".")[1]
            }
        }
        var fnValue = datefunctionsMap[$($this).contents()[1].textContent];
        var columnName = $($this).attr('data-name');
        var textareaData = datefunctionsMap[$($this).contents()[1].textContent].replace(/@replace/g, "\"" + $($this).attr('data-name') + "\"")
        var fieldstextareaData = datefunctionsMap[$($this).contents()[1].textContent].replace(/@replace/g, "[" + $($this).attr('data-name') + "]")
        var tempExp = "";
        var formula = "def val0 = doc['" + columnName + "'].value; if(val0 == null ) { val0 = ''};" + fnValue.replace(/@replace/g, "(val0)");
        var scriptCondition = fnValue.replace(/@replace/g, "(val0)")
        usedFeildArray.push($($this).attr('data-name'));
        var htmlTextValue = '<span class="customfieldaggrlist" data-word="' + fnValue.replace(/@replace/g, '') + '" contenteditable="false">' + fnValue.replace(/@replace/g, '</span><span>[<span class="fa-orange customfieldlist" displayname="' + $($this).attr('data-name') + '" columnname="' + $($this).attr('data-name') + '">' + $($this).attr('data-name') + '</span>]') + '</span>';
        var that = this;

        var requestbody = new FormData();
        requestbody.set("reportId", that.props.reportId != undefined && that.props.reportId != null ? that.props.reportId : "");
        requestbody.set("script", formula);
        requestbody.set("entities", "");
        requestbody.set("datamodelId", that.props.datamodelId);
        requestbody.set("customType", "customfield");
        requestbody.set("isImportedReport", false);
        requestbody.set("sqlformula", textareaData);
        requestbody.set("customFormulaFieldsArray", JSON.stringify(usedFeildArray));

        let requestHeaders = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "testcustomformula",
            body: requestbody,
            sucessFunction: (response) => {
                if (response.valid) {
                    var datatype = (response.datatype == undefined) ? "String" : response.datatype;
                    tempExp += "def val0 = doc['" + columnName + "'].value; ";
                    if (datatype == 'number') {
                        tempExp += "if(val0 == null ) { val0 = 0};";
                    }
                    else {
                        tempExp += "if(val0 == null ) { val0 = ''};";
                    }
                    formula = tempExp
                    var tempCustomObject = {
                        "name": fieldName, "dataType": datatype, "type": "dimension", "formula": formula, "htmltext": htmlTextValue, "fields": fieldstextareaData, "sqlformula": textareaData, "scriptcondition": scriptCondition,
                        "priorityColumn": priorityColumn, "functiontype": "string", "parametrFieldsArray": [], "usedFields": usedFeildArray
                    };
                    customObject.push(tempCustomObject);
                    sessionStorage.setItem("customFields", JSON.stringify(customObject));
                    PubSub.publish("addcustomfield", tempCustomObject);
                    $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                    $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('show');
                    $('.details').addClass('hide');
                    $('.birdmessage h2').empty().append('Success');
                    $('.birdmessage-info').empty().text(getMessage('BirdSuccess31'));
                    $('.createdatedropdown').parent().removeClass('open');
                }
            },
            rejectFunction: this.reportDataError,
            headers: requestHeaders
        })
    }
}