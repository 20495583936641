import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'underscore';
import SendRequest from '../SendRequest';
import {getMessage} from '../js/helpers/utils/BirdMessage';
import DataHub from './Datahub';
import GenericJDBCViewHtml from './GenericJDBCViewHtml.jsx';
const $=window.$;

export default class GenericJDBCView extends React.Component {
    constructor(props) {
        super(props);
        this.schemaName = props.schemaName;
		this.schemaDisplayName= props.schemaDisplayName;
		this.connection_access_id=props.connection_access_id;
		this.schemaId= props.schemaId;
		this.value= props.value;
        this.entityResponse =props.response;
        this.connectionjson=props.connectionjson;
        this.newConnection = false;
			
			if(this.connectionjson==undefined || Object.keys(this.connectionjson).length<=0){
				this.newConnection = true;
			}
		//	this.$el.html(template({schemaName:this.schemaName,schemaDisplayName:this.schemaDisplayName,connectionjson:this.options.connectionjson,isnewConnection:newConnection}));
		
    }
    componentDidMount(){
        if(this.newConnection == true) {
            		$('.datasourceconnection-container').find("#fileupload").fileinput({'showUpload':false,'showPreview':false});	
                }
    }
    testconnection=(e,bool)=>{
        $('.spinner').show();
        e.preventDefault();
        var that = this;
        if(this.isValid()){
            var file_data = null;
            
            if($("#fileupload").prop("files")!= undefined){
                file_data = $("#fileupload").prop("files")[0];
            }
            var connectionString = $("#connectionstringnamescope").val();
            var schemaName = $("#genericjdbcconnectionscope").val();
            var driverClass = $("#newdriverclass").val();
            var username = $("#user").val();
            var password = $("#pass").val();
            var form_data = new FormData();
            form_data.append("file", file_data);
            if(file_data==null){
                var fileName = $("#fileupload").val();
                form_data.append("name",fileName);	
            }
            else {
                form_data.append("name",file_data.name);	
            }
            form_data.append("schemaName",schemaName);	
            form_data.append("connectionString",connectionString);
            form_data.append("driverClass",driverClass);
            form_data.append("username",username);
            form_data.append("password",password);
            if(that.connection_access_id!=undefined) {
                form_data.append("connection_access_id",that.connection_access_id);
            }
            if(that.schemaId !=undefined){
                form_data.append("schemaId",that.schemaId);
            }
            
            
            form_data.append("commandName","testConnection");
            
         
                    SendRequest({
                        url:"genericjdbc",
                        queryString:"",
                        body:form_data,
                        sucessFunction:(response)=>{
                            if(response.length>0 || !response.hasOwnProperty("error")){
                                if(bool){
                                    that.saveGenericDataBaseConnection();
                                }
                                $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                                $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                                $('.birdmessagebuttons').removeClass('show');
                                $('.birdmessagebuttons').addClass('hide');
                                $('.birdmessage-center').addClass("message-top-in");
                                $('.details').removeClass('show');
                                $('.details').addClass('hide');
                                $('.birdmessage h2').empty().append('Success');
                                $('.birdmessage-info').empty().text(getMessage('BirdSuccess41'));
                            }else{
                                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                                $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                                $('.birdmessagebuttons').removeClass('show');
                                $('.birdmessagebuttons').addClass('hide');
                                $('.birdmessage-center').addClass("message-top-in");
                                $('.details').addClass('hide');
                                $('.birdmessage h2').empty().append('Error');
                                $('.birdmessage .errorcode').empty().append(response.errorCode);
                                $('.birdmessage-info').empty().text(response.error);
                                $('.details').addClass('show').text("Details");
                                $('.messagedetails xmp').empty().append(response.errorTrace)
                                $('.elasticsearchform').find('.first a').click();//To click back to first page is validation failed;
                            }
                            $(".spinner").hide();
                    },
                    rejectFunction:(error)=>{},
                    headers:{
                        method: "post",
                        headers: new Headers({
                                "Content-Type": "application/json"
                            })
                        }
                }); 
        }
    }
    showDatabaseErrorMessage=(response,stepBack)=>{
        $(".spinner").hide();
        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
        $('.birdmessagebuttons').removeClass('show');
        $('.birdmessagebuttons').addClass('hide');
        $('.birdmessage-center').addClass("message-top-in");
        $('.details').removeClass('hide');
        $('.birdmessage h2').empty().append('Error : ');
        $('.birdmessage h2').append('<span class="errorcode">');
        $('.birdmessage .errorcode').empty().append(response.errorCode);
        $('.birdmessage-info').empty().text(response.error);
        $('.details').addClass('show').text("Details");
        $('.messagedetails xmp').empty().append(response.errorTrace)
    }
    
    isValid=()=>{//TODO add validation
        var that = this;
        
        return true;
    }
    dbsavenewconnection=(e)=>{
        $(".spinner").show();
        var that =this;
        that.testconnection(e,true);
    }
    
    saveGenericDataBaseConnection=(e)=>{
        e.preventDefault();
        var that = this;
        $('.spinner').show();
            if ($(".webdataconnectorscopename").val() === "") {
                $(".nameerrors").hide();
                $(".nameerror").show();
                $('.spinner').hide();
                return;
            }else if($(".webdataconnectorscopename").val()!=undefined && $(".webdataconnectorscopename").val().replace(/\s+/g, '').length == 0){
                $(".nameerror").hide();
                $(".nameerrors").show();
                $('.spinner').hide();
                  return;
            }
            else{
            	if(/[^a-zA-Z0-9_ ]/.test($(".webdataconnectorscopename").val())){
               	$(".nameerror").hide();
               	$(".nameerrors").show();
               	$('.spinner').hide();
             		return;
             	}else{
            	$(".nameerror").hide();
             	$(".nameerrors").hide();
               	}
            }
        
        var file_data = null;
        
        if($("#fileupload").prop("files")!= undefined && $("#fileupload").prop("files")!=null){
            file_data = $("#fileupload").prop("files")[0];
        }
        var connectionString = $("#connectionstringnamescope").val();
        var schemaName = $("#genericjdbcconnectionscope").val();
        var driverClass = $("#newdriverclass").val();
        var username = $("#user").val();
        var password = $("#pass").val();
        
        var connectiondetails = {};
        
        if(file_data==null){
            var fileName = $("#fileupload").val();
            connectiondetails['name'] = fileName
        }
        else {
            connectiondetails['name'] = file_data.name
        }
        
        connectiondetails["schemaName"] = schemaName;
        connectiondetails["connectionString"] =connectionString;
        connectiondetails["driverClass"]=driverClass;
        connectiondetails["username"]=username;
        connectiondetails["password"]=password;
        connectiondetails["commandName"]="saveconnection";
        
        if(that.connection_access_id!=undefined) {
            connectiondetails["connection_access_id"]=that.connection_access_id;
        }
        if(that.schemaId !=undefined){
            connectiondetails["schemaId"]=that.schemaId;	
        }
        var form_data = new FormData();
        form_data.append("connectionObject", JSON.stringify(connectiondetails));
   
        SendRequest({
            url:"savegenericjdbc",
            queryString:"",
            body:form_data,
            sucessFunction:(collection)=>{
                $(".connection-center").remove()
					if(collection.hasOwnProperty('error')){
						$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
						$('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
						$('.birdmessagebuttons').removeClass('show');
						$('.birdmessagebuttons').addClass('hide');
						$('.birdmessage-center').addClass("message-top-in");
						$('.details').removeClass('hide');
						$('.birdmessage h2').empty().append('Error : ');
						$('.birdmessage h2').append('<span class="errorcode">');
						$('.birdmessage .errorcode').empty().append(collection.errorCode);
						$('.birdmessage-info').empty().text(collection.error);
						$('.details').addClass('show');
						$('.messagedetails xmp').empty().append(collection.error)
						setTimeout(function() {
							$('.birderror').remove();	
						}, 3000);
					}else{
						$(".spinner").hide();
						$('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
						$('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
						$('.birdmessagebuttons').removeClass('show');
						$('.birdmessagebuttons').addClass('hide');
						$('.birdmessage-center').addClass("message-top-in");
						$('.details').removeClass('show');
						$('.details').addClass('hide');
						$('.birdmessage h2').empty().append('Success');
						$('.birdmessage-info').empty().text(getMessage('BirdSuccess41'));
						sessionStorage.setItem("changesdone",0);
						$('.browselogfiles').removeClass('disabled');
						$(".connection-center").removeClass("connection-in");
                        setTimeout(function(){$("body").removeClass("connection-open creating-new-connection");},800);
                        this.datahub=new DataHub();
                        this.datahub.loadConnections(collection.generatedKey);
					//	that.options.broker.trigger("loadConnections",that.connection_access_id!=undefined?that.connection_access_id:collection.generatedKey)
//						require(['views/datahub/datahubview'], function (DataHubView) {
//							new DataHubView({selectedConnectionId:collection.connectionAccessId})
//						});
                }
            },
            rejectFunction:(error)=>{},
            headers:{
                method: "post",
                headers: new Headers({
                        "Content-Type": "application/json"
                    })
                }
		});
    }
    render() {
        return (
            <GenericJDBCViewHtml
                schemaName={this.schemaName}
                schemaDisplayName={this.schemaDisplayName}
                connectionjson={this.connectionjson}
                isnewConnection={this.newConnection}
                testconnection={this.testconnection}
                saveGenericDataBaseConnection={this.saveGenericDataBaseConnection}
                close={this.props.close}
            />
        );
    }
    
}