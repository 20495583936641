import React, { Component } from "react";
import ReactDOM from 'react-dom';
import OtherSettingstemplate from './OtherSettingsTemplate';
import SendRequest from '../../SendRequest';
import MailSettingsView from './MailSettings/MailSettingsView';
import PasswordPolicyView from './PasswordPolicy/PasswordPolicyView';
import EmailDisclaimerView from './EmailDisclaimer/EmailDisclaimerView';
import SamlView from './Saml/samlview';
import LdapView from './Ldap/ldapview';
import { lang } from "../../js/helpers/utils/languages/language";
const $ = window.$;

export default class OtherSettingsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            response: null
        }
        //this.getCurrentUserRole();
    }
    componentDidMount() {
        this.getCurrentUserRole();
        var path = '<li><a class="navDashboard" href="#"><i class="fa  fa-home"></i>'+lang["admin.header.dashtitle"]+'</a></li><li><a href="#admin"><i class="fa fa-user-secret"></i> ' + lang["admin.header.admintitle"] + '</a></li><li><a><i class="fa fa-wrench"></i> ' + lang["admin.settings.title"] + '</a></li>';
		$(".navbar.navbar-bird ul.nav-path").html(path);
    }
    getCurrentUserRole() {
        var that = this;
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        setTimeout(() => {
            SendRequest({
                url: "getcurrentuserrole",
                queryString: "",
                sucessFunction: (response) => {
                    this.setState({
                        response: response
                    });
                    //that.render(response)
                },
                rejectFunction: () => { },
                headers: requestHeaders

            });
        }, 1000);

    }
    mailSettingsView = (event) => {
        var $this = event.currentTarget;
        $('.selected').removeClass('selected');
        $($this).addClass('selected');
        $($this).addClass("active");
        var mailSettingsView = new MailSettingsView({});
    }

    passwordPolicyView = (event) => {
        var $this = event.currentTarget;
        $('.selected').removeClass('selected');
        $($this).addClass('selected');
        $($this).addClass("active");
        var passwordpolicyview = new PasswordPolicyView({});
    }
    emailDisclaimerView = (event) => {
        var $this = event.currentTarget;
        $('.selected').removeClass('selected');
        $($this).addClass('selected');
        $($this).addClass("active");
        var emailDisclaimerView = new EmailDisclaimerView({});
    }
    samlView = (event) => {
        var $this = event.currentTarget;
        $('.selected').removeClass('selected');
        $($this).addClass('selected');
        $($this).addClass("active");
        var samlview = new SamlView({});
    }
    ldapView = (event) => {
        var $this = event.currentTarget;
        $('.selected').removeClass('selected');
        $($this).addClass('selected');
        $($this).addClass("active");
        var ldapView = new LdapView({ features: this.props.features });
    }
    render() {
        var isLdapEnable = false;
        $.each(this.props.features[0], function (index, feature) {
            if (feature.featureName == 'admin_view_ldap' && feature.allow == true) {
                isLdapEnable = true;
                setTimeout(function () {
                    $('.ldapsettingsmain').click();
                }, 10);
            }
        });
        if (isLdapEnable == false) {
            setTimeout(function () {
                $('.mailsettingsmain').click();
            }, 10);
        }
        $('a.boc-maxscreen-left-item.other_settings').addClass('showactive')
        $('.boc-admin-main .maxscreen-lft-filter-items a').not('.other_settings').removeClass('showactive');
        return (
            this.state.response == null ? null : <OtherSettingstemplate features={this.props.features} currentRole={this.state.response.current_user_role} ldapView={this.ldapView}
                mailSettingsView={this.mailSettingsView} samlView={this.samlView} passwordPolicyView={this.passwordPolicyView} emailDisclaimerView={this.emailDisclaimerView} />
        );

    }
}