import React from 'react'
import _ from 'lodash';
import SendRequest from '../SendRequest';
import ReactDOM from 'react-dom'
import GovernanceViewTemplate from './governance.jsx'
import DataModelShareView from './datamodelshareview'

const $ = window.$;
export default class GovernanceView extends React.Component {
    constructor(props) {
        $(".spinner").show();
        super(props);
        this.state = {};
        $("body").addClass("datagovernancepage").removeClass("datamainpage datahubpage dataworkspacepage datamodelspage searchpage");
        $("body").removeClass("rightpanelopen");
        $('#ui-datepicker-div').hide();
        $('.clockpicker-popover').remove();
        sessionStorage.setItem("changesdone", 0);
        sessionStorage.removeItem("convertedtypes");
        sessionStorage.removeItem("isconvettedtypesapplied");
        sessionStorage.removeItem("pos");
        sessionStorage.removeItem("renameMeasuresorDimensions");
        sessionStorage.removeItem("linkedreports");
        $(".pivotdataview").remove();
    }
    render() {
        return (<div></div>);
    }
    componentDidMount() {
        $(".spinner").show();
        //Control Footer UI Based on Parameters in URL
        var that = this
        that.state.displayFooter = true;
        var urlparameters = window.location.search.replace("?", "").split("&");
        $.each(urlparameters, function (i, u) {
            var parameter = u.split("=");
            if (parameter[0].toLowerCase() == "bottomfooter" && parameter[1].toLowerCase() == "false") {
                that.state.displayFooter = false;
            }
        });
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getavailabledatamodels",
            queryString: "",
            sucessFunction: (response) => {
                _.each(response, function (i) {
                    if (!(i.is_shared)) {
                        i.modelshare = <button className="btn btn-bird data-modal-share" is-shared={i.is_shared.toString()} multifact={i.multifact.toString()} onClick={that.datamodalshare} data-model-name={i.DMTitle} data-model-id={i.DMID} data-model-index={i.dataModelIndex}> <i className="fa fa-share"></i></button>;
                    }
                });
                ReactDOM.render(<GovernanceViewTemplate dataModelsList={response} searchKeyup={this.searchkeyup} displayFooter={that.state.displayFooter} />, document.getElementById("main"));
                $(".spinner").hide();
            },
            rejectFunction: () => {$(".spinner").hide(); },
            headers: requestHeaders,
        })
        

    }
    datamodalshare = (e) => {
        //this.hideScopes();
        $(".spinner").show();
        var $this = e.currentTarget;
        var modelId = $($this).attr("data-model-id");
        var dataModelInfo = [],
            dataModelName = $($this).attr("data-model-name"),
            dataModelId = $($this).attr("data-model-id"),
            multifact = $($this).attr("multifact"),
            isShared = $($this).attr("is-shared"),
            dataModelIndex = $($this).attr("data-model-index");
        dataModelInfo.push({ dataModelName: dataModelName, dataModelId: dataModelId, dataModelIndex: dataModelIndex, multifact: multifact, isShared: isShared })
        if (dataModelId != undefined) {
            let requestHeaders = {
                method: "get",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            SendRequest({
                url: "getcolumns",
                queryString: "?datamodelId=" + dataModelId + "&multifact=" + multifact + "&isfrom=" + "governance",
                sucessFunction: (response) => {
                    if (response.hasOwnProperty('error')) {
                        $(".spinner").hide();
                    } else {
                        if (multifact == "true") {
                            SendRequest({
                                url: "getdatamodelrelationinfo",
                                queryString: "?reportId= &datamodelId=" + dataModelId + "&isImportedReport=" + false,
                                sucessFunction: modelresponse => {
                                    if (modelresponse.hasOwnProperty('error')) {
                                        $(".spinner").hide();
                                    } else {
                                        var multifactentities = modelresponse['entities'];
                                        var facttables = modelresponse['facttables'];
                                        var DataModelShareViewContainer = new DataModelShareView({
                                            dataModelInfo: dataModelInfo,
                                            modelColumns: response,
                                            multifactentities: multifactentities,
                                            facttables: facttables
                                        });
                                        $(".spinner").hide();
                                    }
                                },
                                rejectFunction: () => { },
                                headers: requestHeaders

                            });

                        } else {
                            var DataModelShareViewContainer = new DataModelShareView({
                                dataModelInfo: dataModelInfo,
                                modelColumns: response
                            });
                            $(".spinner").hide();
                        }
                    }
                },
                rejectFunction: () => { },
                headers: requestHeaders
            });
        }
    }
    searchkeyup = (e) => {
        var $this = e.currentTarget;
            var valThis = $($this).val().toLowerCase();
            $('#datagovernancemaincontainer tbody tr').each(function () {
                var text = $(this).find('td:first-child').text().toLowerCase();
                (text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
            });
    
        $("#datagovernancemaincontainer").focus();
      }
}