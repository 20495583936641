import React from "react";
import ReactDOM from 'react-dom';

export default class SessionexpiryTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        var that = this.props;
        return (
            <div className="modal fade" id="changepasswordmodel" tabindex="-1"
                role="dialog" aria-labelledby="Change password" aria-hidden="true">
                <div className="modal-dialog modal-sm modal-dialog-sm-center modal-dialog-center">
                    <div className="modal-content">
                        <div className="modal-header boc-modal-hf-padding">
                            <button type="button" className="close white" data-dismiss="modal"
                                aria-hidden="true">&times;</button>
                            <h4 className="modal-title"><i className="fa fa-key"></i> Sign in</h4>
                        </div>
                        <div className="modal-body">
                            <div className="container-fluid">
                                <div className="row filtercontent">
                                    <form action="" method="post" className="form-horizontal col-xs-12">
                                        <div className="form-group form-group-sm">
                                            <span className="required right labels"></span>
                                            <label for="oldpassword" className="col-xs-12">Username</label>
                                            <div className="col-xs-12">
                                                <input className="form-control" type="text" autocomplete="on" id="user" name="username"
                                                    autofocus="autofocus" spellcheck="false" autocorrect="off"
                                                    required />
                                                <span className="help-block hidden usernameerror fa-help-dark">Username is required</span>
                                            </div>
                                        </div>
                                        <div className="form-group form-group-sm">
                                            <span className="required right labels"></span>
                                            <label for="oldpassword" className="col-xs-12">Password</label>
                                            <div className="col-xs-12">
                                                <input className="form-control" type="password" autocomplete="off" id="pwd" name="password" required />
                                                <span className="help-block hidden pwderror fa-help-dark">Password is required</span>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="sessionexpiryerror" id="sessionexpiryerror"  style={{display: "none",color:'#ff0707',textAlign: 'center',opacity: 1, marginBottom: '30px'}} ></div>
                        <div className="modal-footer boc-modal-hf-padding">
                            <button type="submit" className="btn btn-bird changepasswordsubmit" onClick={that.changepasswordsubmit}><i className="fa fa-check"></i> Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}