import React from "react";
import {Link} from 'react-router-dom';
import Mlicon from "../images/birdimages/ml.svg";
import { lang } from "../js/helpers/utils/languages/language";
import SendRequest from '../SendRequest';
import {getMessage} from '../js/helpers/utils/BirdMessage';
const $=window.$;

export default class Storyboards extends React.Component {
  render() {
    return (
      <React.Fragment>
        {this.props.storyboards.map((data, index) => {
          return (
            <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2 item-main-container">
              <div className="data-item" title={data.reportname}>
                <div className="data-item-headers">
                  <div className="data-item-name">
                    {data.type === "ml_storyboard" ? (
                      <Link
                        className=""
                        title={`View ${data.reportname}`}
                        to={`${this.props.url}/viewmodel/${btoa(data.reportId)}/${btoa(data.dataModelId)}`}
                        data-num={data.reportId}
                        tab-id="0"
                        tab-name=""
                      >
                        {data.reportname}
                      </Link>
                    ):(data.type === "smartinsight_storyboard")?(
                      <Link
                      className=""
                      title={`View ${data.reportname}`}
                      to={`${this.props.url}/viewsmartinsight/${btoa(data.reportId)}/${btoa(data.dataModelId)}`}
                      data-num={data.reportId}
                      tab-id="0"
                      tab-name=""
                    >
                      {data.reportname}
                    </Link>
                    ) : (
                      <Link
                        className=""
                        title={`View ${data.reportname}`}
                        to={`${this.props.url}/viewreport/${btoa(data.reportId)}`}
                        data-num={data.reportId}
                        tab-id="0"
                        tab-name=""
                      >
                        {data.reportname}
                      </Link>
                    )}
                  </div>
                  <div className="data-item-subtitle">
                    <i className="fa fa-folder"></i>&nbsp;
                    {data.reportFolder}
                  </div>
                  <div className="data-item-actions">
                    <button
                      type="button"
                      className="btn btn-transparent dropdown-toggle dropdown"
                      title={lang["visual.favourites.more"]}
                      data-toggle="dropdown"
                      aria-expanded="true"
                    >
                      <i className="fa fa-ellipsis-v"></i>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-right">
                      {data.type === "ml_storyboard" ? (
                        <li>
                          <Link
                            type="button"
                            className="btn btn-data-item-actions"
                            title={`View ${data.reportname}`}
                            to={`${this.props.url}/viewmodel/${btoa(data.reportId)}/${btoa(data.dataModelId)}`}
                            data-num={data.reportId}
                            tab-id="0"
                            tab-name=""
                          >
                            <i className="fa fa-arrow-right marg-righ-10"></i>{" "}
                            {lang["visual.favourites.viewreport"]}
                          </Link>
                        </li>
                      ):(data.type === "smartinsight_storyboard")?(
<li>
                          <Link
                            type="button"
                            className="btn btn-data-item-actions"
                            title={`View ${data.reportname}`}
                            to={`${this.props.url}/viewsmartinsight/${btoa(data.reportId)}/${btoa(data.dataModelId)}`}
                            data-num={data.reportId}
                            tab-id="0"
                            tab-name=""
                          >
                            <i className="fa fa-arrow-right marg-righ-10"></i>{" "}
                            {lang["visual.favourites.viewreport"]}
                          </Link>
                        </li>
                      ) : (
                        <li>
                          <Link
                            type="button"
                            className="btn btn-data-item-actions"
                            title={`View ${data.reportname}`}
                            to={`${this.props.url}/viewreport/${btoa(data.reportId)}`}
                            data-num={data.reportId}
                            tab-id="0"
                            tab-name=""
                          >
                            <i className="fa fa-arrow-right marg-righ-10"></i>{" "}
                            {lang["visual.favourites.viewreport"]}
                          </Link>
                        </li>
                      )}
                      <li>
                        <Link
                          type="button"
                          title={`Delete ${data.reportname}`}
                          id="storyboardview"
                          className="btn btn-data-item-actions deltevisualreports"
                          data-num={data.reportId}
                          tab-id="0"
                          tab-name={data.reportname}
                          is-shared={data.isShared}
                          is-favourite={data.isUserFavourite}
                          onClick={()=>{this.deleteReport(data.reportId,data.isUserFavourite,data.isShared,index,this)}}
                        >
                          <i className="fa fa-trash-o marg-righ-10"></i> {lang["visual.favourites.delete"]}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="data-item-info">
                  <div className="data-item-info-name"></div>
                </div>
                <div className="data-item-more-info">
                  <div className="data-item-desc-box">
                    <div className="data-item-info-description"></div>
                  </div>
                  <div className="data-item-img-box">
                    {data.type === "ml_storyboard" || data.type === "smartinsight_storyboard"? (
                      <img src={Mlicon} className="mlicon" />
                    ) : (
                      <i className="fa fa-bar-chart"></i>
                    )}
                  </div>
                </div>
                <div className="data-item-footer">
                <div className="data-sync-info">
                  <i className="fa fa-clock-o"></i> {lang["visual.favourites.createon"]} {data.date}
                </div>
                <div className="data-item-sync-status">
                  <span className="folderview-bottom-options pull-right">
                    {data.isUserFavourite === "Y" ? (<i className="fa fa-star fa-green"></i>) : null}
                    {data.isScheduled === "Y" ? (<i className="fa fa-clock-o fa-gold"></i> ) : null}
                    {data.isShared === "Y" ? (<i className="fa fa-share-alt fa-violet"></i> ) : null}
                    {data.isLiveData === "Y" ? (<i className="fa fa-clock-o fa-flip-horizontal fa-green"></i>) : null}
                    {data.ispublished === "true" ? (
                          <i className="fa fa-send fa-publish publishsb published"></i>
                        ) : null}
                    {
                      this.props.features.map((feature1, index) => {
                          if (feature1.moduleName == "story_board" && data.reportType == "Y") {
                            feature1.features.map((feature2, index) => {
                              if (feature2.featureName == "audit_meta_data" && feature2.allow == true) {
                                if (data.isMetaDataAudit == "Y" && data.type != "ml_storyboard") {
                                  return (<i className="fa fa-circle-o fa-orchid"></i>);
                                }
                                if (data.isDataAudit == "Y" && data.type != "ml_storyboard") {
                                  return (<i className="fa fa-circle-o fa-skyblue"></i>);
                                }
                              }
                            });
                          }
                          if (feature1.moduleName === "my_report" && data.reportType != "Y") {
                            feature1.features.map((feature2, index) => {
                              if (feature2.featureName === "audit_meta_data_maximize" && feature2.allow === true) {                             if (data.isMetaDataAudit == "Y") {
                                  return (<i className="fa fa-circle-o fa-orchid"></i>);
                                }
                                if (data.isDataAudit === "Y") {
                                  return (<i className="fa fa-circle-o fa-skyblue"></i>);
                                }
                              }
                            });
                          }
                      })
                    }
                    {data.isAlert=="Y" ? (<i className="fa fa-exclamation-triangle fa-warning marg-righ-10"></i>): null}
                    <span className="marg-left-5">
                      <i className="fa fa-eye"></i>&nbsp;
                      {data.numberOFViews}&nbsp;
                    </span>
                  </span>
                </div>
                </div>
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  }
  deleteReport=(reportid,isFavourite,isShared,index,e)=>{
    let requestHeaders = {
      method: "post",
      headers: new Headers({
        "Content-Type": "application/json"
      })
    };
    var that=this;
    $(".deleteyes").unbind( "click" );
    $(".deleteno").unbind( "click" );
    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
    $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
    $('.birdmessagebuttons').addClass('show');
    $('.birdmessage-center').addClass("message-top-in");
    $('.details').removeClass('show');
    $('.details').addClass('hide');
    $('.birdmessage h2').empty().append('Warning');
    $('.birdmessage-info').empty().text(getMessage('BirdWarning18'));
    $( ".deleteyes" ).click(function() {
      $('.birdmessage-center').removeClass("message-top-in");
        var modeldata = {};
        $('.spinner').show(); 
        modeldata["reportId"] = reportid;
        if(isFavourite=="Y"){
          modeldata["isFavourite"] = true;
        }else{
          modeldata["isFavourite"] = false;
        } 
        if(isShared=="Y"){
          modeldata["isShared"] = true;
        }else{
          modeldata["isShared"] = false;
        }
        modeldata["isReportOwner"] = true;

        var data=new FormData();

        data.set("reportId",modeldata.reportId);
        data.set("isFavourite",modeldata.isFavourite);
        data.set("isSharedReport",modeldata.isShared);
        data.set("isReportOwner",modeldata.isReportOwner);
        
        SendRequest({
          url: "deletereport",
          body: data,
          sucessFunction: response => {
            if(response.hasOwnProperty('error')){
              $(".spinner").hide();
              $(".gridspinner").hide();
              $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
              $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
              $('.birdmessagebuttons').removeClass('show');
              $('.birdmessagebuttons').addClass('hide');
              $('.birdmessage-center').addClass("message-top-in");
              $('.details').removeClass('hide');
              $('.birdmessage h2').empty().append('Error : ');
              $('.birdmessage h2').append('<span class="errorcode">');
              $('.birdmessage .errorcode').empty().append(response.errorCode);
              $('.birdmessage-info').empty().text(response.error);
              $('.details').addClass('show');
              $('.messagedetails xmp').empty().append(response.errorTrace)
              /*$('#main').prepend('<div class="alert alert-danger birderror alert-dismissable affix-top" style="margin:0 auto;width: 60%;top: 44px;position: fixed;z-index: 1;left: 20%;">'+response.error+'</div>');
                    setTimeout(function() {
                      $('.birderror').remove();	
                    }, 3000);*/
            }else{
            //$($this).closest(".boc-snapshot").remove();
            $(".spinner").hide();
              $(".gridspinner").hide();
            $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
            $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Success');
            $('.birdmessage-info').empty().text(getMessage('BirdSuccess42'));
            const reportsArray=Object.assign([],that.props.state.storyboards);
            reportsArray.splice(index,1);
            that.props.TargetThis.setState({storyboards:reportsArray});
            
            }
          },
          rejectFunction: ()=>{},
          headers: requestHeaders
        });

        
      });
    $( ".deleteno" ).click(function() {
       $('.birdmessage-center').removeClass("message-top-in");
      });
  }
}
