import React, { Component } from "react";
import ReactDOM from 'react-dom';
import UsersAccountFormHtml from "./useraccountformhtml.jsx";
import { lang} from "../../js/helpers/utils/languages/language";
import SendRequest from '../../SendRequest';
const $ =window.$;
class CreateUsersView extends Component {
  constructor(props) {
    super(props);
      this.state = { };
    let featuresString = sessionStorage.getItem("features");
    featuresString = featuresString.slice(0, 3) + featuresString.slice(4);
    let decryptedString = window.atob(featuresString);
    this.state.parsedFeatureJson = JSON.parse(decryptedString);
    this.state.features= JSON.parse(decryptedString); 
    this.state.groupsresponse= {};
    this.state.rolesresponse= {};
    this.getGroupslist();
    this.getRoleslist();
    
  }
getGroupslist(){
  let requestHeaders = {
    method: "get",
    headers: new Headers({
        "Content-Type": "application/json"
    })
};
   SendRequest({
    url: "getAllGroups",
    queryString: "",
    sucessFunction: (response) => {
        if (response.hasOwnProperty('error')) {
            $(".spinner").hide();
        } else {
          this.state.groupsresponse=response;

        }
      },
      rejectFunction: () => { },
      headers: requestHeaders
    })
}
getRoleslist(){
  let requestHeaders = {
    method: "get",
    headers: new Headers({
        "Content-Type": "application/json"
    })
};
   SendRequest({
    url: "getRoles",
    queryString: "",
    sucessFunction: (response) => {
        if (response.hasOwnProperty('error')) {
            $(".spinner").hide();
        } else {
       this.state.rolesresponse=response;
       this.render();
        }
      },
      rejectFunction: () => { },
      headers: requestHeaders
    })
}
  render(){
    var that=this.props;
   
    ReactDOM.render(<UsersAccountFormHtml groupsresponse={this.state.groupsresponse} rolesresponse={this.state.rolesresponse} features={this.state.features}/>,document.querySelector(".newuser"));
    $(".group-select").chosen({
      no_results_text: lang["admin.users.oops"],
      width: "100%",
      search_contains: true
    });
  return (
<div></div>
    );
};
}
export default CreateUsersView;