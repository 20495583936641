import React from "react";
import { lang } from "../js/helpers/utils/languages/language";
export default class TooltipDropDownTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        var that = this.props;
        return (<div key={that.dynamicdiv}>
            {/* <!-- tooltipdropdownview.js --> */}
            {(that.dataType != 'customaggregatedfield' && that.dataType != 'custommeasurehierarchy') ? (
                <li key={that.dynamicdiv} title={that.columnDisplayName} data-name={that.columnName} table-name={that.tableName} data-type={that.dataType} className={`xs-list-item tooltipitem ${that.disabled ? " hide" : ""}`} onClick={that.addtooltipvalue} percentile-value={that.percentileValue}>

                    <span className="itemtype">
                        {(that.dataType === 'number') ? (
                            <>123</>
                        ) : (that.dataType === 'date' || that.dataType === 'datetime' || that.dataType === 'month' || that.dataType === 'day') ? (
                            <i className="fa fa-calendar"></i>
                        ) :
                                <>{lang["storyboard.abc"]}</>
                        }
                    </span>
                    <span className="itemname">{that.columnDisplayName}</span>
                    {(that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                        <span className="boclabel boclabel-right-5 opacity-effect boclabel-info" title={that.aggregation}>[ {that.aggregation} ]</span>
                    ) : null}
                </li>

            ) : null}

        </div>);
    }
}