import React from 'react';
import _ from 'underscore';
import { lang } from "../js/helpers/utils/languages/language";
export default class WorkspaceAccordianHtml extends React.Component {
    componentDidMount(){
        if(this.props.addDateTimeFilter){
            this.props.addDateTimeFilter(this.props.columnDisplayName,this.props.filterValues);
        }
        
    }
    render() {
        var time = new Date().getTime();
        return (
            <div id={this.props.columnDisplayName} data-id={this.props.columnDisplayName} className="acc-content" data-parent={this.props.tableName}>
                <div className="boc-accordian-options">
                    <div className="btn-group pull-right" role="group" aria-label="...">
                        {(this.props.columnType != 'date' && this.props.columnType != 'datetime' && this.props.columnType != 'number' && !(this.props.columnType == 'all' || this.props.columnType == 'list' || this.props.columnType == 'range')) ? (
                            <button type="button" className="btn btn-accordian dropdown-toggle" data-toggle="dropdown" aria-expanded="false" id="selecthelper">
                                <i className="fa fa-cog"></i>
                            </button>) : null
                        }
                        <ul className="dropdown-menu checkhelp" role="menu" aria-labelledby="selecthelper">
                            <li title="" className="aggregation-li-item">
                                <label><input type="radio" id="checkall" name={this.props.columnName} onClick={(e)=>{this.props.selectall(e);}} />&nbsp;{lang["workspace.select"]}</label>
                            </li>
                            <li title="" className="aggregation-li-item">
                                <label><input type="radio" id="uncheckall" name={this.props.columnName} onClick={(e)=>{this.props.unselectall(e);}} />&nbsp;{lang["workspace.deselect"]}</label>
                            </li>
                        </ul>
                        <button type="button" className="btn btn-accordian" id="delete" name={this.props.columnDisplayName} onClick={(e)=>{this.props.deleteaccordian(e)}}>
                            <i className="fa fa-close"></i>
                        </button>
                    </div>
                </div>
                <div className="panelboc">
                    <div className="panelboc-heading">
                        {
                            (this.props.columnType == 'date' || this.props.columnType == 'datetime') ? (
                                <i className="fa fa-calendar fa-accordion"></i>
                            ) : (this.props.columnType == 'string') ? (
                                <i className="fa fa-sort-alpha-asc fa-accordion"></i>
                            ) : (this.props.columnType == 'number') ? (
                                <i className="fa fa-sort-numeric-asc fa-accordion"></i>
                            ) : (
                                <i className="fa fa-sort-numeric-asc fa-accordion"></i>
                            )
                        }
                        {
                            (this.props.columnType != 'number') ? (
                                <h4 className="panelboc-title accordion-toggle collapsed" title={this.props.columnDisplayName} id={`filtersrename${this.props.columnName}`} data-toggle="collapse" data-parent="#filteraccordion" href={`#acc${this.props.columnDisplayNames}.${time}`} aria-expanded="false">{this.props.columnDisplayName}</h4>
                            ) : (this.props.columnType == 'all' || this.props.columnType == 'list' || this.props.columnType == 'range') ? (
                                <h4 className="panelboc-title accordion-toggle collapsed" title={this.props.columnName} data-toggle="collapse" data-parent="#filteraccordion" href={`#acc${this.props.columnName}.${time}`} aria-expanded="false">{this.props.columnName}</h4>
                            ) : (
                                <h4 className="panelboc-title accordion-toggle" title={this.props.columnDisplayName} id={`filtersrename${this.props.columnName}`} data-toggle="collapse" data-parent="#filteraccordion" href={`#acc${this.props.columnDisplayNames}.${time}`} aria-expanded="false">{this.props.columnDisplayName}</h4>
                            )
                        }
                    </div>
                    {
                        (this.props.columnType != 'date' && this.props.columnType != 'datetime' && this.props.columnType != 'number' && !(this.props.columnType == 'all' || this.props.columnType == 'list' || this.props.columnType == 'range')) ? (
                            <div id={`acc${this.props.columnDisplayNames}`} className={`${time} panel-collapse collapse panelboc-content filterStringvalues`}>
                                <div className="panelboc-body">
                                    <div className="advancedfilters">
                                        <button className="btn btn-link pull-right btn-sm showadvancedfilters" onClick={this.props.showadvancedfilters}>{lang["workspace.advanced"]}</button>
                                    </div>
                                    <div className="container-fluid marg-top-15" style={{marginTop:"20px"}}>
                                        <div className="row marg-bot-10 filtersearching " style={{ display: "none" }}>
                                            {
                                                (this.props.filterValues!==undefined)? (
                                                    (this.props.filterValues.length !== 0) ? (
                                                        <>
                                                            <div className="stringfilters searching"> {lang["workspace.startswith"]} <input type="text" id={`${this.props.columnDisplayNames}start`} className="searchword" name={this.props.columnName} data-type={this.props.columnType} data-parent={this.props.tableName} data-child={this.props.columnName} data-name={this.props.columnDisplayNames} displayname={this.props.columnDisplayName} /></div>
                                                            <div className="stringfilters searching"> {lang["workspace.endswith"]} <input type="text" id={`${this.props.columnDisplayNames}end`} name={this.props.columnName} data-type={this.props.columnType} data-parent={this.props.tableName} data-child={this.props.columnName} data-name={this.props.columnDisplayNames} displayname={this.props.columnDisplayName} /></div>
                                                            <div className="stringfilters searching col-lg-10 col-md-10 col-sm-12 col-xs-12" style={{marginLeft:"-5px"}}>
                                                            {lang["workspace.contains"]} <input type="text" id={`${this.props.columnDisplayNames}contains`} name={this.props.columnName} data-type={this.props.columnType} data-parent={this.props.tableName} data-child={this.props.columnName} data-name={this.props.columnDisplayNames} displayname={this.props.columnDisplayName} />
                                                            </div>
                                                            <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12" style={{marginTop:"1px"}}>
                                                                <button type="submit" id="searchfiltersdata" name={this.props.columnName} data-type={this.props.columnType} data-parent={this.props.tableName} data-child={this.props.columnName} data-name={this.props.columnName} displayname={this.props.columnDisplayName} script={this.props.script} className="btn btn-sm btn-bird marg-top-20 btn-highlight" onClick={(e)=>{this.props.searchfiltersdata(e)}}>{lang["workspace.go"]}</button>
                                                            </div>
                                                        </>
                                                    ) : null
                                                ):null
                                            }
                                        </div>

                                                <ul className="list-group nomargin checkhelpitems">
                                                        {
                                                            this.props.filterValues.map((filterValue,index)=>{
                                                                var i = 0;
                                                                _.each(this.props.filterArray,  (filterData) =>{
                                                                    if (filterData.columndisplayname == this.props.columnDisplayName && filterValue.value == filterData.data) {
                                                                        i++;
                                                                    }
                                                                });
                                                                var value = filterValue.value;
                                                                var encodedvalue = (value + '').replace(/([;?&,%#+*~\'"!^$[\]()<=>`|\/@])/g, "");
                                                                if (i === 0) {
                                                                    return(<li key={index} className="boc-list-item valueoffilters">
                                                                        <label title={filterValue.value}>
                                                                            <input type="checkbox" className={(this.props.className == undefined) ? 'filtervalue':this.props.className} data-type={this.props.columnType} data-parent={this.props.tableName} data-child={this.props.columnName} data-name={this.props.columnDisplayNames} displayname={this.props.columnDisplayName} data-value={encodedvalue} script={this.props.script} />
                                                                            {filterValue.value}
                                                                        </label>
                                                                    </li>);
                                                                } else {
                                                                    return(<li key={index} className="boc-list-item valueoffilters">
                                                                        <label title={filterValue.value}>
                                                                            <input type="checkbox" className={(this.props.className == undefined) ? 'filtervalue':this.props.className} data-type={this.props.columnType} data-parent={this.props.tableName} data-child={this.props.columnName} data-name={this.props.columnDisplayNames} displayname={this.props.columnDisplayName} data-value={encodedvalue} script={this.props.script} defaultChecked />
                                                                            {filterValue.value}
                                                                        </label>
                                                                    </li>);
                                                                }
                                                            })
                                                        }
                                                    </ul>
                                          
                                        
                                        
                                    </div>
                                </div>
                            </div>
                        ) : (this.props.columnType != 'date' && this.props.columnType != 'datetime' && this.props.columnType != 'number' && !(this.props.columnType == 'all' || this.props.columnType == 'list' || this.props.columnType == 'range')) ? (
                            <div id={`acc${this.props.columnDisplayNames}`} className={`${time} panel-collapse collapse panelboc-content filterStringvalues`}>
                                <div className="panelboc-body">
                                    <div className="advancedfilters">
                                        <button className="btn btn-link pull-right btn-sm showadvancedfilters" onClick={this.props.showadvancedfilters}>{lang["workspace.advanced"]}</button>
                                    </div>
                                    <div className="container-fluid marg-top-15">
                                        <div className="row marg-bot-10 filtersearching" style={{display:"none"}}>
                                            {
                                                (this.props.filterValues!==undefined)? (
                                                    (this.props.filterValues.length != 0) ? (
                                                        <>
                                                            <div className="stringfilters searching ">
                                                            {lang["workspace.startswith"]} <input type="text" id={`${this.props.columnDisplayNames}start`} className="searchword" name={this.props.columnName} data-type={this.props.columnType} data-parent={this.props.tableName} data-child={this.props.columnName} data-name={this.props.columnDisplayNames} displayname={this.props.columnDisplayName} />
                                                            </div>
                                                            <div className="stringfilters searching">
                                                            {lang["workspace.endswith"]} <input type="text" id={`${this.props.columnDisplayNames}end`} name={this.props.columnName} data-type={this.props.columnType} data-parent={this.props.tableName} data-child={this.props.columnName} data-name={this.props.columnDisplayNames} displayname={this.props.columnDisplayName} />
                                                            </div>
                                                            <div className="stringfilters searching col-lg-10 col-md-10 col-sm-12 col-xs-12" style={{marginLeft:"-5px"}}>
                                                            {lang["workspace.contains"]} <input type="text" id={`${this.props.columnDisplayNames}contains`} name={this.props.columnName} data-type={this.props.columnType} data-parent={this.props.tableName} data-child={this.props.columnName} data-name={this.props.columnDisplayNames} displayname={this.props.columnDisplayName} />
                                                            </div>
                                                            <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12" style={{marginTop:"1px"}}>
                                                                <button type="submit" id="searchfiltersdata" name={this.props.columnName} data-type={this.props.columnType} data-parent={this.props.tableName} data-child={this.props.columnName} data-name={this.props.columnName} displayname={this.props.columnDisplayName} script={this.props.script} className="btn btn-sm btn-bird marg-top-20 btn-highlight" onClick={(e)=>{this.props.searchfiltersdata(e)}}>{lang["workspace.go"]}</button>
                                                            </div>
                                                        </>
                                                    ) : null
                                                ):null
                                            
                                            }
                                            <ul className="list-group nomargin checkhelpitems">
                                                {
                                                    _.each(this.props.filterValues, (filterValue) =>{
                                                        var i = 0;
                                                        _.each(this.props.filterArray, (filterData) =>{
                                                            if (filterData.columndisplayname == this.props.columnDisplayName && filterValue.value == filterData.data) {
                                                                i++;
                                                            }
                                                        });
                                                        var value = filterValue.value;
                                                        var encodedvalue = (value + '').replace(/([;?&,%#+*~\'"!^$[\]()<=>`|\/@])/g, "");
                                                        if (i == 0) {
                                                            return(<li className="boc-list-item valueoffilters">
                                                                <label title={filterValue.value}>
                                                                    <input type="checkbox" className={ (this.props.className == undefined) ? 'filtervalue':this.props.className} data-type={this.props.columnType} data-parent={this.props.tableName} data-child={this.props.columnName} data-name={this.props.columnDisplayNames} displayname={this.props.columnDisplayName} data-value={encodedvalue} script={this.props.script} onClick={(e)=>{this.props.filterchecked(e)}} />
                                                                    {filterValue.value}
                                                                </label>
                                                            </li>);
                                                        }
                                                        else {
                                                            return(<li className="boc-list-item valueoffilters">
                                                                <label title={filterValue.value}>
                                                                    <input type="checkbox" className={ (this.props.className == undefined) ? 'filtervalue':this.props.className} data-type={this.props.columnType} data-parent={this.props.tableName} data-child={this.props.columnName} data-name={this.props.columnDisplayNames} displayname={this.props.columnDisplayName} data-value={encodedvalue} checked script={this.props.script} onClick={(e)=>{this.props.filterchecked(e)}} />
                                                                    {filterValue.value}
                                                                </label>
                                                            </li>);
                                                        }
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (this.props.columnType === 'number') ? (
                            <div id={`acc${this.props.columnDisplayNames}`} data-id={`acc${this.props.columnDisplayNames}`} className={`${time} panel-collapse collapse  in panelboc-content ${(this.props.className == undefined) ? 'filterrangevalues':this.props.className}`}>
                                <div className="panelboc-body">
                                    <div className="advancedfilters">
                                        <button className="btn btn-link pull-right btn-sm showadvancedfilters" id="showadvancedfilters" onClick={this.props.showadvancedfilters}>{lang["workspace.advanced"]}</button>
                                    </div>
                                    <div className="clear"></div>
                                    <div className="marg-righ-15 marg-left-15">
                                        <div id="numberrangeslider">
                                            <input data-type={this.props.columnType} data-parent={this.props.tableName} data-child={this.props.columnName} displayname={this.props.columnDisplayName} data-name={this.props.columnDisplayNames} />
                                        </div>
                                    </div>
                                    <div className="row-fluid">
                                        <div className="intfiltercontainer" style={{display:"none"}}>
                                            <span>{lang["workspace.or"]}<br /></span>
                                            <div className="intfiltervalue">
                                                <label>{lang["workspace.greater"]}</label>
                                                <input type="number" className="filtergreaterthanvalue" id="greaterthanvalue" />
                                                <span className="filtergreaterthanvalue filter-error pull-left hide"></span>
                                            </div>
                                            <div className="intfiltervalue">
                                                <label>{lang["workspace.lesser"]}</label>
                                                <input type="number" className="filterlessthanvalue" id="lessthanvalue" />
                                                <span className="filterlessthanvalue filter-error pull-left hide"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container-fluid"><br />
                                        <input type="text" className="hidden" placeholder={lang["workspace.placeholdcomma"]} id={`customfiltervalue${this.props.columnDisplayNames}`} />
                                    </div>
                                </div>
                            </div>
                        ) : (this.props.columnType == "all") ? (
                            <div id={`acc${this.props.columnName}`} className={`${time} panel-collapse collapse in panelboc-content filtercustomparamerevalues`}>
                                <div className="panelboc-body">
                                    <div className="row-fluid">
                                        <div className="intfiltercontainer ">
                                            <div className="intfiltervalue">
                                                <label>{lang["workspace.currentvalue"]}</label>
                                                <input type="number" className="filtergreaterthanvalue" id="parametervalue" displayname={this.props.columnDisplayName} data-name={this.props.columnName} data-value={this.props.value} data-columnType={this.props.columnType} value={this.props.value} name="parametervalue" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (this.props.columnType == "list") ? (
                            <div id={`acc${this.props.columnName}`} className={`${time} panel-collapse collapse in panelboc-content filtercustomparamerelist`}>
                                <div className="panelboc-body">
                                    <div className="container-fluid">
                                        <select placeholder="" className="form-control selectedparametervalue" name="roleId">
                                            {
                                                _.each(this.props.parameterlistvlaues,(values)=>{
                                                    if(values.parameterlistvalue===this.props.parametercurrentvalue){
                                                        return <option value={values.parameterlistvalue} selected>{values.parameterlistdisplayname}</option>
                                                    }else{
                                                        return <option value={values.parameterlistvalue}>{values.parameterlistdisplayname}</option>
                                                    }
                                                })
                                            }
                                        </select>
                                        <div className="irs-hidden-input">
                                            <input type="number" className="filtergreaterthanvalue" id="parametervalue" displayname={this.props.columnDisplayName} data-name={this.props.columnName} data-value={this.props.value} data-columnType={this.props.columnType} value={this.props.value} name="parametervalue" disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (this.props.columnType == "range") ? (
                            <div id={`acc${this.props.columnName}`} className={`${time} panel-collapse collapse  in panelboc-content filtercustomparamererangevalues`}>
                                <div className="panelboc-body">
                                    <div className="marg-righ-10 marg-left-10">
                                        <div id="numberrangeslider">
                                            <input data-child={this.props.columnName} displayname={this.props.columnDisplayName} data-name={this.props.columnName} data-columnType={this.props.columnType} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div id={`acc${this.props.columnDisplayNames}`} className={`${time} panel-collapse collapse in panelboc-content filterdatevalues`}>
                                <div className="panelboc-body nopadding">
                                    <ul className="list-group nomargin">
                                        <li className="boc-list-item singleliitems">
                                            <div className={ (this.props.className == undefined) ? 'daterange-center':this.props.className} id={`daterange${this.props.columnDisplayNames}`} data-type={this.props.columnType} data-parent={this.props.tableName} data-child={this.props.columnName} displayname={this.props.columnDisplayName} data-name={this.props.columnDisplayNames} date-range={this.props.daterange} onClick={(e)=>{this.props.daterangepicker(e)}}>
                                                <i className="glyphicon glyphicon-calendar fa fa-calendar"></i>
                                                <span></span>
                                                <b className="caret"></b>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }
}