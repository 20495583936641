/**
* 
* Module: Bird
* File Name: usstates.js
* author: Sriyam Software Solutions
* Created Date: 2019-07-24
* Last Modified Date: 2020-01-06
* copy: Source Code Copyright to Sriyam Software Solutions LLP.
* 
*/
var geoCodes={
    'ALABAMA':[32.318231,-86.902298],
    'ALASKA':[64.2008413,-149.4936733],
    'ARIZONA':[34.0489281,-111.09373110000001],
    'ARKANSAS':[35.2010,-91.8318],
    'CALIFORNIA':[36.7783, -119.4179],
    'COLORADO':[39.550051,-105.782067],
    'CONNECTICUT':[41.603221,-73.087749],
    'DELAWARE':[38.9108,-75.5277],
    'DISTRICT OF COLUMBIA':[38.9072,-77.0369],
    'FLORIDA':[27.6648274,-81.51575350000002],
    'GEORGIA':[32.1656,-82.9001],
    'HAWAII':[19.8968,-155.5828],
    'IDAHO':[44.0682019,-114.74204079999998],
    'ILLINOIS':[40.6331249,-89.39852830000001],
    'INDIANA':[40.2671941,-86.13490189999999],
    'IOWA':[41.8780025,-93.09770200000003],
    'KENTUCKY':[37.8393332,-84.27001789999997],
    'KANSAS':[39.011902,-98.48424649999998],
    'MAINE':[45.253783,-69.44546889999998],
    'LOUISIANA':[30.9842977,-91.96233269999999],
    'MARYLAND':[39.0457549,-76.6412712],
    'MASSACHUSETTS':[42.4072107,-71.38243740000001],
    'MICHIGAN':[44.3148443,-85.60236429999998],
    'MINNESOTA':[46.729553,-94.68589980000002],
    'MISSISSIPPI':[32.3546679,-89.39852830000001],
    'MISSOURI':[37.9642529,-91.8318334],
    'NEBRASKA':[41.4925374,-99.90181310000003],
    'MONTANA':[46.8796822,-110.36256579999997],
    'NEVADA':[38.8026097,-116.41938900000002],
    'NEW HAMPSHIRE':[43.1938516,-71.57239529999998],
    'NEW MEXICO':[34.5199402,-105.87009009999997],
    'NEW JERSEY':[40.0583238,-74.4056612],
    'NEW YORK':[40.7127837,-74.00594130000002],
    'NORTH CAROLINA':[35.7595731,-79.01929969999998],
    'NORTH DAKOTA':[47.5514926,-101.00201190000001],
    'OHIO':[40.4172871,-82.90712300000001],
    'OKLAHOMA':[35.0077519,-97.09287699999999],
    'OREGON':[43.8041334,-120.55420119999997],
    'RHODE ISLAND':[41.5800945,-71.4774291],
    'PENNSYLVANIA':[41.2033216,-77.19452469999999],
    'SOUTH CAROLINA':[33.836081,-81.1637245],
    'SOUTH DAKOTA':[43.9695148,-99.90181310000003],
    'TENNESSEE':[35.5174913,-86.5804473],
    'TEXAS':[31.9685988,-99.90181310000003],
    'UTAH':[39.3209801,-111.09373110000001],
    'VIRGINIA':[37.4315734,-78.65689420000001],
    'VERMONT':[44.5588028,-72.57784149999998],
    'WASHINGTON':[47.7510741,-120.74013860000002],
    'WEST VIRGINIA':[38.5976262,-80.45490259999997],
    'WYOMING':[43.0759678,-107.29028390000002],
    'WISCONSIN':[43.7844397,-88.78786780000001],
    'AL':[32.318231,-86.902298],
    'AK':[64.2008413,-149.4936733],
    'AZ':[34.0489281,-111.09373110000001],
    'AR':[35.2010,-91.8318],
    'CA':[36.7783, 119.4179],
    'CO':[39.550051,-105.782067],
    'CT':[41.603221,-73.087749],
    'DE':[38.9108,-75.5277],
    'DC':[38.9072,-77.0369],
    'FL':[27.6648274,-81.51575350000002],
    'GA':[32.1656,-82.9001],
    'HI':[19.8968,-155.5828],
    'ID':[44.0682019,-114.74204079999998],
    'IL':[40.6331249,-89.39852830000001],
    'IN':[40.2671941,-86.13490189999999],
    'IA':[41.8780025,-93.09770200000003],
    'KY':[37.8393332,-84.27001789999997],
    'KS':[39.011902,-98.48424649999998],
    'ME':[45.253783,-69.44546889999998],
    'LA':[30.9842977,-91.96233269999999],
    'MD':[39.0457549,-76.6412712],
    'MA':[42.4072107,-71.38243740000001],
    'MI':[44.3148443,-85.60236429999998],
    'MN':[46.729553,-94.68589980000002],
    'MS':[32.3546679,-89.39852830000001],
    'MO':[37.9642529,-91.8318334],
    'NE':[41.4925374,-99.90181310000003],
    'MT':[46.8796822,-110.36256579999997],
    'NV':[38.8026097,-116.41938900000002],
    'NH':[43.1938516,-71.57239529999998],
    'NM':[34.5199402,-105.87009009999997],
    'NJ':[40.0583238,-74.4056612],
    'NY':[40.7127837,-74.00594130000002],
    'NC':[35.7595731,-79.01929969999998],
    'ND':[47.5514926,-101.00201190000001],
    'OH':[40.4172871,-82.90712300000001],
    'OK':[35.0077519,-97.09287699999999],
    'OR':[43.8041334,-120.55420119999997],
    'RI':[41.5800945,-71.4774291],
    'PA':[41.2033216,-77.19452469999999],
    'SC':[33.836081,-81.1637245],
    'SD':[43.9695148,-99.90181310000003],
    'TN':[35.5174913,-86.5804473],
    'TX':[31.9685988,-99.90181310000003],
    'UT':[39.3209801,-111.09373110000001],
    'VT':[37.4315734,-78.65689420000001],
    'VA':[44.5588028,-72.57784149999998],
    'WA':[47.7510741,-120.74013860000002],
    'WV':[38.5976262,-80.45490259999997],
    'WY':[43.0759678,-107.29028390000002],
    'WI':[43.7844397,-88.78786780000001]
    

};


export function getGeoUSACodes(location) {
 if (geoCodes.hasOwnProperty(location.toUpperCase())) {
  return geoCodes[location.toUpperCase()];;
 } else {
  return undefined;
 }
}