import React from 'react';
import { lang } from "../js/helpers/utils/languages/language";
const $=window.$;

export default class NLPSinglereportSavemodal extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount(){
       // $("#modal-backdrop").addClass('modal-backdrop fade in');
       $(".form-control").keyup(function(){
        var valThis = $(this).val().toLowerCase();
          var valLength = valThis.length;
            var maxCount = 45;
            var errormsg="#errmsg"
            if(valLength>=maxCount){
                $(errormsg).html("");
                $(errormsg).html(lang["search.nameexceeds"]+maxCount+"" ).show();
                $(this).val($(this).val().substring(0, $(this).val().length - 1));
            }else{
                $(errormsg).html("");
            }
    });
    }
    render() {
        return (
            <div className="modal fade in" id="savereportgraph" tabIndex="-1" role="dialog" aria-labelledby="savereportgraph" aria-hidden="true" style={{display:"block "}}>
               <div className="modal-backdrop fade in" id="modal-backdrop" onClick={this.props.cancelmodal}></div>
                <div className="modal-dialog modal-lg modal-save-report modal-dialog-lg-center modal-dialog-center" style={{ zIndex: "1050" }}>
                    <div className="modal-content">
                        <div className="modal-header boc-modal-hf-padding">
                            <button type="button" className="close white" onClick={this.props.cancelmodal} id="singlestoryboardclose" data-dismiss="modal" aria-hidden="true">&times;</button>
                            <h4 className="modal-title"><i className="fa fa-floppy-o"></i> {lang["search.savemyreport"]}</h4>
                        </div>
                        <div className="modal-body scrollset">
                            <div className="container-fluid">
                                <div className="row filtercontent">
                                    <fieldset className="savereport-border">
                                        <legend className="savereport-border">{lang["search.reportdetails"]}</legend>
                                        <div className="control-group ">
                                            <div className="validationgroup form-group" style={{ marginTop: "-2px" }}>
                                                <span className="required right legend"></span>
                                                <label className="subhead">{lang["search.name"]} </label>
                                                <input type="name" id="reportname" name="storyboardname" defaultValue={""} className="form-control input-sm" placeholder={lang["search.reportname"]} />
                                                <span className="help-block marg-left-10" id="storyboardname-help-block"></span>
                                            </div>
                                            <div className="validationgroup form-group">
                                                <label className="subhead" >{lang["search.folder"]} </label>
                                                <input type="folder" className="form-control input-sm" defaultValue={""} id="foldername" maxLength="45" name="storyboardfolder" id="reportFolder" list="maximizefolders" placeholder={lang["search.slctfolder"]} />
                                                <span style={{ color: "#ff0000" }} id="errmsg"></span>
                                                <span className="help-block hidden marg-left-10"></span>
                                                <datalist id="maximizefolders">
                                                </datalist>
                                            </div>
                                            <div className="validationgroup hidden-lg hidden-md hidden-sm hidden-xs">
                                                <legend className="subhead">{lang["search.description"]}</legend>
                                                <textarea id="description" placeholder={lang["search.placeholdescription"]}
                                                    name="storyboarddesc" className="modal-form-fields resize-vertical"></textarea>
                                                <span className="help-block hidden"></span>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer boc-modal-hf-padding">
                            <button type="button" className="btn btn-bird" onClick={this.props.cancelmodal} id="btn-birdsingle"><i className="fa fa-times"></i> {lang["search.cancelbtn"]}</button>
                            <button type="button" className="btn btn-bird savesinglereport" id="savesinglereportes" onClick={this.props.savereport}><i className="fa fa-check"></i> {lang["search.applybtn"]}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}