import React from 'react'
import _ from 'lodash';
import $ from 'jquery/dist/jquery';
import jstz from 'jstz';
import SendRequest from '../../SendRequest';
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import ReactDOM from 'react-dom'
import { getMessage } from '../../js/helpers/utils/BirdMessage';
import SaveMLModelTemplate from './savemlstoryboardmodal.jsx'

export default class SaveMLModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.state.dynamicdiv = this.props.dynamicdiv;
        this.state.viewreportCollection = this.props.viewreportCollection;
        this.state.reportId = this.props.reportId;
        this.state.datamodelId = this.props.datamodelId;
        this.state.schemaType = this.props.schemaType;
        this.state.reportname = this.props.name;
        this.state.title = this.props.title;
        this.state.reportdesc = this.props.desc;
        this.state.folder = this.props.folder;
        this.state.isSyncEnable = true;
        this.state.syncConfigData = this.props.syncConfigData;
        this.state.features = this.props.features;
        this.state.isImportedReport = this.props.isImportedReport;
        this.state.exportSchemaId = undefined;
        if (this.props.isViewModel) {
            this.state.isSyncEnable = false;
        }
        this.state.syncType = "";
        this.state.syncVal = "";
        if (this.props.chartnumber == undefined) {
            this.props.chartnumber = "-1";
        }
        this.viewreportCollection = this.props.viewreportCollection;
        this.render();
    }
    render() {
        sessionStorage.setItem('syncTypeForHtml', this.syncVal);
        var columns = [];
        var purgedConfig = ChartInfo.functions.getPurgeConfig();
        var maxage = purgedConfig["maxage"];
        var maxageunits = purgedConfig["maxageunits"];
        var purgetime = purgedConfig["purgetime"];
        var maxpurgeindex = purgedConfig["maxpurgeindex"];
        var checked = false;
        var localtimezone = new Date().toString().match(/([A-Z]+[\+-][0-9]+.*)/)[1].substr(0, 8) + "(" + jstz.determine().name() + ")";
        if (maxage != undefined && maxage != "" || maxpurgeindex != undefined && maxpurgeindex != "") {
            checked = true;
        }
        if (this.state.folder != undefined && this.state.folder === "Default") {
            this.state.folder = "";
        }

        ReactDOM.render(<SaveMLModelTemplate applymlstoryboardchanges={this.applymlstoryboardchanges} close={this.close} reportname={this.state.reportname} folder={this.state.folder} />, document.getElementById(this.state.dynamicdiv));

        //	this.$el.append(this.template({reportId:this.reportId,reportname:this.reportname, folder:this.viewreportCollection.at(0).get('reportfolder'),reportdesc:this.reportdesc,desc:this.desc,"syncType":this.syncType,"syncVal":this.syncVal,isSyncEnable:this.isSyncEnable,"dateColumns":this.dateColums.concat(this.numberColumns),syncTime:this.syncTime,"schemaType":this.schemaType,
        //		"maxageunits":maxageunits, "checked":checked , "maxage":maxage,"isDirect":this.options.isDirect,localtimezone:localtimezone,servertimezone:this.options.servertimezone, purgetime:purgetime,"maxpurgeindex":maxpurgeindex,timeZoneType:this.options.timeZoneType}));
        var selectedDateFileds = [];
        var refreshType = "";
        return (<div></div>)
    }
    close = () => {
        ReactDOM.unmountComponentAtNode(document.getElementById(this.state.dynamicdiv));
    }
    applymlstoryboardchanges = () => {
        var storyboardname = $("#" + this.state.dynamicdiv).find("#storyboardname").val();
        var storyboardfolder = $("#" + this.state.dynamicdiv).find("#storyboardfolder").val();
        var storyboarddesc = $("#" + this.state.dynamicdiv).find("#storyboarddesc").val();
        if (storyboardfolder === "") {
            storyboardfolder = "Default"
        }
        // this.options.model.set('storyboardname',storyboardname);
        // this.options.model.set('storyboardfolder',storyboardfolder);
        // this.options.model.set('storyboarddesc',storyboarddesc);
        var mlreportdata = [];
        if ((true)) {
            $(".spinner").show();
            var reporttitle = $(".boc-viewreport-title:visible").text();
            var reportDataArray = [];
            var reportdetails = { "name": storyboardname, "folder": storyboardfolder != undefined ? storyboardfolder : "", "desc": storyboarddesc != undefined ? storyboarddesc : "", "title": reporttitle };
            var axisDataArray = [];
            var detailsObject = {};
            var filterArray = ChartInfo.functions.getFilters(false);
            var filterObject;
            var that = this;
            $.each(this.state.features, function (index, featuresModule) {
                if (featuresModule.moduleName == 'story_board') {
                    $.each(featuresModule.features, function (index, feature) {
                        if (feature.featureName == 'viewreport_filters' && feature.allow == false && filterArray.length == 0) {
                            if (that.viewreportCollection.getFilterObject != undefined) {
                                filterArray = that.viewreportCollection.getFilterObject();
                            }
                        }
                    });
                }
            });
            var axisDataArray = [];
            var chartInfoDetails = ChartInfo.functions.getAllMLChartDetails();
            _.each(chartInfoDetails, function (i, j) {
                var storedObject = i;
                var storedParsedObject = storedObject;
                var xAxisData = storedParsedObject["xAxis"];
                var legend = storedParsedObject["legend"];
                var yAxisData = storedParsedObject["yAxis"];
                var zAxisData = storedParsedObject["zAxis"];
                var tooltipData = storedParsedObject["tooltip"];
                var chartType = storedParsedObject["chartType"];
                var chartTitle = storedParsedObject["reporttitle"];
                var dobyselectedcolumns = storedParsedObject["dobyselectedcolumns"]
                var dobyrowlimit = storedParsedObject["dobyrowlimit"];
                var metricdatetype = storedParsedObject["metricdatetype"];
                var metricdatefield = storedParsedObject["metricdatefield"];
                var metricprefix = storedParsedObject["metricprefix"];
                var metricsuffix = storedParsedObject["metricsuffix"];
                var solidgaugemin = storedParsedObject["solidgaugemin"];
                var solidgaugemax = storedParsedObject["solidgaugemax"];
                var richtextcontent = storedParsedObject["richtextcontent"];
                var colorThemeIndex = storedParsedObject["colorThemeIndex"];
                var reportcolortheme = storedParsedObject["reportcolortheme"];
                var chartSettings = storedParsedObject["chartSettings"];
                var percentileValue = storedParsedObject["percentileValue"];
                var jsonObject = {};
                jsonObject["xaxis"] = xAxisData;
                jsonObject["yaxis"] = yAxisData;
                jsonObject["zaxis"] = zAxisData;
                jsonObject["tooltip"] = tooltipData;
                jsonObject["legend"] = legend;
                jsonObject["chartType"] = chartType;
                jsonObject["chartnumber"] = j;
                jsonObject["reporttitle"] = chartTitle;
                jsonObject["dobyselectedcolumns"] = dobyselectedcolumns;
                jsonObject["dobyrowlimit"] = dobyrowlimit;
                jsonObject["metricdatetype"] = metricdatetype;
                jsonObject["metricdatefield"] = metricdatefield;
                jsonObject["metricprefix"] = metricprefix;
                jsonObject["metricsuffix"] = metricsuffix;
                jsonObject["solidgaugemin"] = solidgaugemin;
                jsonObject["solidgaugemax"] = solidgaugemax;
                jsonObject["richtextcontent"] = richtextcontent;
                jsonObject["colorThemeIndex"] = colorThemeIndex;
                jsonObject["reportcolortheme"] = reportcolortheme;
                jsonObject["chartSettings"] = chartSettings;
                jsonObject["percentileValue"] = percentileValue;

                axisDataArray.push(jsonObject);
            });
            var axisDataObject = {};
            var allChartDetails = ChartInfo.functions.getAllMLChartDetails();
            axisDataObject["axis"] = allChartDetails;
            reportDataArray.push(axisDataObject);
            var detailsObject = {};
            detailsObject["details"] = reportdetails;
            reportDataArray.push(detailsObject);

            var filterArray = ChartInfo.functions.getFilters(false);
            var filterObject;
            var that = this;
            $.each(this.state.features, function (index, featuresModule) {
                if (featuresModule.moduleName == 'story_board') {
                    $.each(featuresModule.features, function (index, feature) {
                        if (feature.featureName == 'viewreport_filters' && feature.allow == false && filterArray.length == 0) {
                            if (that.viewreportCollection.getFilterObject != undefined) {
                                filterArray = that.viewreportCollection.getFilterObject();
                            }
                        }
                    });
                }
            });
            filterObject = { "filter": filterArray };
            reportDataArray.push(filterObject);
            var gridStackData = _.map($('.grid-stack .grid-stack-item.tileview'), function (el) {
                try {
                    el = $(el);
                    var node = el.data('_gridstack_node');
                    return {
                        id: el.attr('data-custom-id'),
                        num: el.attr('data-num'),
                        x: node.x,
                        y: node.y,
                        width: node.width,
                        height: node.height
                    };
                } catch (e) {

                }
            });
            var gridStackObject = {};
            gridStackObject = { "grid": gridStackData };
            reportDataArray.push(gridStackObject);

            var customFields = sessionStorage.getItem("customFields");
            var customFieldsObject = { "customFields": customFields };
            reportDataArray.push(customFieldsObject);

            var mlModelDetails = ChartInfo.functions.getMLModelDetails();
            reportDataArray.push(mlModelDetails);
            var finalColumns = this.viewreportCollection.getDimensions();
            var setMLSelectedColumns = ChartInfo.functions.getMLSelectedColumns();
            reportDataArray.push(setMLSelectedColumns);
            var customParameter = JSON.stringify(ChartInfo.functions.getCustomParameter());
            var customParameter = { "customParameter": customParameter };
            reportDataArray.push(customParameter);

            var customParameterFilterArray = ChartInfo.functions.getCustomParametersFilters();
            var customParameterfilterObject = { "customParameterFilters": customParameterFilterArray };
            reportDataArray.push(customParameterfilterObject);

            var temp = JSON.parse(customFields);
            var res;
            _.each(temp, function (customfields) {
                _.each(customfields.parametrFieldsArray, function (value) {
                    var customparameter = ChartInfo.functions.getCustomParameter();
                    var customParameterFilterArray = ChartInfo.functions.getCustomParametersFilters(undefined);
                    if (customParameterFilterArray != undefined && customParameterFilterArray.length != 0) {
                        customparameter = customParameterFilterArray;
                    }
                    res = $.grep(customparameter, function (e) {
                        return e.parametername == value;
                    })
                    var formula = customfields.formula;
                    var scriptcondition = customfields.scriptcondition;
                    var fields = customfields.fields;
                    var sqlformula = customfields.sqlformula;
                    if (res.length != 0) {
                        customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
                        customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
                        customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
                        customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
                    } else {
                        _.each(ChartInfo.functions.getCustomParameter(), function (values) {
                            if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
                                customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
                                customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                            }
                        });
                    }
                });
            });
            var updateCustomFieldsWithCustomParameters = JSON.stringify(temp);

            //				this.reportId=this.reportId!=null ?this.reportId:parseInt(0)
            this.saveReport(this.state.reportId, JSON.stringify(reportDataArray), JSON.stringify(finalColumns), this.state.datamodelId, updateCustomFieldsWithCustomParameters);
        }
    }
    saveReport(reportId, reportdata, columnsData, datamodelId, updateCustomFieldsWithCustomParameters) {
        var fragmentArray = window.location.pathname.split("/");
        var entities = "";
        var schemaId = "";
        var that = this;
        if (fragmentArray.length > 5 && reportId != undefined) {
            schemaId = 0;
        } if (fragmentArray.length == 5 && reportId != undefined) {
            //  reportId = reportId;
            schemaId = 0;
        } else {
            //schemaId  = fragmentArray[1];
        }
        if (fragmentArray.length == 5 && fragmentArray[4] === "customviewreport") {
            entities = sessionStorage.getItem("entities");
        }
        // var tableName = fragmentArray[3];
        var modeldata = {};
        var delimiter = sessionStorage.getItem("delimiter");
        if (reportId != undefined) {
            modeldata["reporId"] = reportId;
        }
        modeldata["reportdata"] = reportdata;
        var linkreportId = $(".navigatereporturl option:selected").val();
        //var convertedTypes = sessionStorage.getItem("convertedtypes");
        //modeldata["columns"] = columnsData;
        //modeldata["customFieldsScript"] = customFieldsScript;
        //modeldata["entities"] = entities;
        //  viewreportsavestoryboardmodel.set(modeldata);
        //this.reset();
        // this.add(viewreportsavestoryboardmodel);

        var formdata = new FormData();

        formdata.set("reportId", reportId != null && reportId != undefined &&reportId!==''? reportId : 0);
        formdata.set("dataModelId ", datamodelId != null ? datamodelId : parseInt(0));
        formdata.set("reportdata", reportdata);
        formdata.set("linkreportId", linkreportId != undefined && linkreportId != null ? linkreportId : "");
        formdata.set("customFieldsScript ", updateCustomFieldsWithCustomParameters);
        let requestHeaders = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "savemlstoryboard",
            body: formdata,
            sucessFunction: (response) => {
                if (response.hasOwnProperty("error")) {
                    $(".spinner").hide();
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('hide');
                    $('.birdmessage h2').empty().append('Error : ');
                    $('.birdmessage h2').append('<span class="errorcode">');
                    $('.birdmessage .errorcode').empty().append(response.errorCode);
                    $('.birdmessage-info').empty().text(response.error);
                    $('.details').addClass('show').text("Details");
                    $('.messagedetails xmp').empty().append(response.errorTrace)
                } else {

                    if (response.reportId != 0) {
                        if (response.success == true) {
                            $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                            $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                            $('.birdmessagebuttons').removeClass('show');
                            $('.birdmessagebuttons').addClass('hide');
                            $('.birdmessage-center').addClass("message-top-in");
                            $('.details').removeClass('show');
                            $('.details').addClass('hide');
                            $('.birdmessage h2').empty().append('Success');
                            $('.birdmessage-info').empty().text(getMessage('BirdSuccess01'));
                            sessionStorage.setItem("changesdone", 0);
                        }
                        if (that.state.reportId == null || that.state.reportId == undefined || that.state.reportId == '') {
                            setTimeout(function () {
                                $('.birdmessage-container .close').click()
                                var href = window.location.origin + "/" + window.localStorage.appName + "/welcome/visualization"
                                window.open(href, '_self')
                            }, 2000);
                        }
                    } else {
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error');
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                    }
                    $(".spinner").hide();
                    that.close();
                }
            },
            rejectFunction: this.fail,
            headers: requestHeaders,
        });
    }
}