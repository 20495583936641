import React from 'react';
import { lang } from "../../js/helpers/utils/languages/language";
export default class SummaryTableValuesDropdownHtml extends React.Component {
    render() {
        return (
            <>
                {
                    this.props.values.map((value, index) => {
                        var dataType = value.tableDisplayType;
                        return (
                            <li key={index} title={value.columnName} onClick={(e) => { this.props.addValue(e) }} className={`xs-list-item summarytablevalueitem ${value.visible == 'no' ? 'hide' : ''}`} data-parent={value.tableName} data-child={value.columnName} data-type={value.tableDisplayType} data-name={value.columnDisplayName}>
                                <span className="itemtype">
                                    {
                                        (dataType === 'number') ? (
                                            <>123</>
                                        ) : (dataType === 'date' || dataType === 'datetime' || dataType === 'month' || dataType === 'day') ? (
                                            <i className="fa fa-calendar"></i>
                                        ) : (dataType === 'customhierarchy' || dataType === 'custommeasurehierarchy') ? (
                                            <i className="fa fa-list"></i>
                                        ) : (<>{lang["storyboard.abc"]}</>)
                                    }
                                </span>
                                <span className="itemname " data-parent={value.tableName} data-child={value.columnName} data-type={value.tableDisplayType}>{value.columnDisplayName}</span>
                                {
                                    (this.props.isDirect == 0 || this.props.isStoredProcedure == 0) ? (
                                        <span className="boclabel boclabel-right-5 opacity-effect boclabel-info">
                                            {
                                                (value.aggregation == undefined || value.aggregation == "" || value.aggregation.toLowerCase() == "none") ? <>count</> : value.aggregation
                                            }
                                        </span>
                                    ) : null
                                }
                            </li>
                        )
                    })
                }
                {
                    (this.props.customFields != undefined) ? (
                        <>
                            {this.props.customFields.map((row, index) => {
                                var dataType = row.dataType;
                                return (
                                    <li key={index} title={row.name} data-parent={row.name} data-child={row.name} data-type={row.dataType == undefined ? 'string' : row.dataType} data-name={row.name} className="xs-list-item summarytablevalueitem" onClick={(e) => { this.props.addValue(e) }}>
                                        <span className="itemtype fx">
                                            {
                                                (dataType === 'number') ? (
                                                    <>123</>
                                                ) : (dataType === 'date' || dataType === 'datetime' || dataType === 'month' || dataType === 'day') ? (
                                                    <i className="fa fa-calendar"></i>
                                                ) : (dataType === 'customhierarchy' || dataType === 'custommeasurehierarchy') ? (
                                                    <i className="fa fa-list"></i>
                                                ) : (<>{lang["storyboard.abc"]}</>)
                                            }
                                        </span>
                                        <span className="itemname" data-parent={row.name} data-child={row.name} data-type={row.dataType == undefined ? 'string' : row.dataType}>{row.name}</span>
                                        <span className="boclabel boclabel-right-5 opacity-effect boclabel-info">{lang["storyboard.count"]}</span>
                                    </li>
                                )
                            })}
                        </>
                    ) : null
                }
                {
                    (this.props.measurehierarachy != undefined) ? (
                        <>
                            {
                                this.props.measurehierarachy.map((row, index) => {
                                    return (
                                        <li key={index} title={row.name} data-parent={row.name} data-child={row.name} data-type="custommeasurehierarchy" data-name={row.name} className="xs-list-item summarytablevalueitem" onClick={(e) => { this.props.addValue(e) }}>
                                            <span className="itemtype"><i className="fa fa-list"></i></span>
                                            <span className="itemname" data-parent={row.name} data-child={row.name} data-type="custommeasurehierarchy">{row.name}</span>
                                        </li>
                                    )
                                })
                            }
                        </>
                    ) : null
                }
            </>
        );
    }
}