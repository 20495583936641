import _ from 'lodash';
import React, { Component } from "react";
import { lang } from "../../js/helpers/utils/languages/language";
import ReactDOM from "react-dom";
const $ = window.$;


export default class PivotRowAttribute extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columnName: props.columnName,
            columnDisplayName: props.columnDisplayName,
            aggregation: props.aggregation,
            dataType: props.dataType,
            tableName: props.tableName,
            chartnumber: props.chartnumber,
        }
        this.render();
    }
    render() {
        this.state.el = ".pivotrow";
        var datatype = this.state.dataType;
        var convertedValues = JSON.parse(sessionStorage.getItem("convertedtypes"));
        var that = this;
        if (convertedValues != null && convertedValues != undefined) {
            _.each(convertedValues, function (convertValue) {
                if (convertValue.columnName == that.state.columnDisplayName) {
                    datatype = convertValue.dataType;
                }
            });
        }
        var today = new Date();
        var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
        $(this.state.el).append("<div className='" + dynamicdiv + "hide' id='" + dynamicdiv + "'></div>");

        ReactDOM.render(
            <PivotRowAttributeTemplate dynamicdiv={dynamicdiv} chartnumber={this.state.chartnumber} columnName={this.state.columnName}
                aggregation={this.state.aggregation} dataType={datatype} tableName={this.state.tableName} columnDisplayName={this.state.columnDisplayName} ></PivotRowAttributeTemplate>
            , document.getElementById(dynamicdiv));

        return (<div></div>);
    }
}

class PivotRowAttributeTemplate extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        var that = this.props;
        return (<li key={that.dynamicdiv} className="boc-list-item singleliitems" title={that.columnDisplayName} data-parent={that.tableName} data-child={that.columnName} data-type={that.dataType}>
            {/* <!-- pivotrowattribute.js --> */}
            <span className="itemtype">
                {(that.dataType === 'number') ? (
                    123
                ) : (that.dataType === 'date' || that.dataType === 'datetime' || that.dataType === 'month' || that.dataType === 'day') ? (
                    <i className="fa fa-calendar"></i>
                ) : (that.dataType === 'customhierarchy' || that.dataType === 'custommeasurehierarchy') ? (
                    <i className="fa fa-list"></i>
                ) :
                lang["storyboard.abc"]
                }
            </span>
            <span className="itemname pivotrowattribute" data-parent={that.tableName} data-child={that.columnName} data-type={that.dataType}>{that.columnDisplayName}</span>
            <span className="btn-group pull-right">
                <button className="btn btn-transparent deleterow" onClick={this.deleteRow} type="button"><i className="fa fa-trash"></i></button>
            </span>
        </li>);
    }
    deleteRow(e) {
        var $this = e.currentTarget;
        var currentvalue = $($this).closest('span').prev().text();
        $(".pivotrow li[title='" + currentvalue + "']").parent().remove();
    }
}