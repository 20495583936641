import React from 'react'
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;
export default class StoryboardFiltersList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    componentDidMount() {
        var that = this;
        $('.drilldowntogglecheckbox').click(function (e) {
            e.stopPropagation();
            that.props.storyboardfilterstoggle(e);
        });
    }
    render() {
        var that = this.props;
        return (
            <div className="permissioncontainer dobyfilteritems" tile-id={that.chartid}>
                <div className="col-xs-12 head"><i className="fa fa-filter"></i> {lang["storyboard.boardfilters"]}
            <div className="drilldowntogglecheckbox"  tile-id={that.chartid}>
                        <div className="checkbox checkbox-slider--c checkbox-slider-sm">
                            <label><input type="checkbox" id="storyboardfilterstoggle" className="storyboardfilterstoggle" defaultChecked="checked" /><span>&nbsp;</span></label>
                        </div>
                    </div>
                </div>
                <div className="clear"></div>
                <ul className="list-group scrollsetul permissionlist entitytype storyboardfilterslist"></ul>
            </div>);
    }
}