import React, { Component } from "react";
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;

export default class NotificationBadgeViewTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    componentDidMount(){
        var that=this;
        $('.notifications-new').click(function(e){
            var $this =e.currentTarget;
            that.props.marknotificationsForHref($($this).find('.markread'));
        });
    }
    render() {
        var that = this.props;
        var i = 0;
        return (<><a
            className="dropdown-toggle notifications"
            id="newnotifications"
            title={lang["user.header.notify"]}
            data-toggle="dropdown"
            href="javascript:void(0);"
        >
            <i className="fa fa-bell"></i>
            <span className="badge notifications-badge">
                {that.count !== 0
                    ? that.count
                    : null}
            </span>
        </a>
            <div className="dropdown-menu dropdown-menu-right notifyer">
                {/* <%  if(count != 0) {%> 
             <!-- <li className="dropdown-header"><span className="markallread" status="0" title="">Mark All as Read</span>
                                      <span className="clearallread" title="" status="1">Clear All Read</span></li>
                                      <li className="divider"></li> --> 
                  <%}%> 
            */}
                <ul className="notification-ul">
                    
                    {
                        (that.notifications.data!=undefined)? (
                            <>
                            {that.notifications.data.map((notification, index) => {
                        let date = notification.create_date.split(" ");
                        let time = date[1];
                        if(date[1].indexOf(".") != -1){
                            time = date[1].split(".")[0];
                        }
                        return (
                            <React.Fragment key={index}>
                                {(
                                    notification.notification_status !==
                                    2 &&
                                    notification.notification_status !==
                                    1
                                ) ? (
                                        notification.vizType === "storybook" ?
                                            (<li className="notifications-list clearfix notifications-new" key={index} >
                                                <a href={`${that.url}/sharestorybook/${btoa(notification.storybook_id)}`} id="viewsharenotificationstorybook" className="sharereportnavigation"
                                                    title={notification.storybook_name}>
                                                        <p>{notification.storybook_name}</p>
                                                    <p className="name" title={notification.sharedUserName}><i className="fa fa-user"></i>&nbsp;{notification.sharedUserName}</p>
                                                    <span className="datetime" title = {date[0]}>{date[0]}<span className="time"> {time} </span></span>
                                                    <p className="msg">
                                                        <img src={require(`../images/birdimages/charts/hc-multi1.png`)} className="mail-avatar" alt="avatar" />
                                                        <span title={notification.user_comments}>{notification.user_comments}</span>
                                                    </p>
                                                    <p className="markread" onClick={that.marknotifications} title="" reportid={notification.reportId} status={notification.notification_status} storybookid={notification.storybook_id} is_share_storybook_notification={true}></p>
                                                </a>
                                            </li>
                                            )
                                            :

                                            (<li className="notifications-list clearfix notifications-new" key={index} >
                                                <a href={`${that.url}/${notification.vizType === "smartinsight_storyboard" ? "sharesmartinsightreport" : "sharereport"}/${notification.vizType === "smartinsight_storyboard" ? btoa(notification.vizid) : btoa(notification.vizid)}${
                                                    notification.vizType === "smartinsight_storyboard" || notification.vizType === "ml_storyboard"
                                                        ? "/" +btoa(notification.dataModelId)
                                                        : ""
                                                    }`}
                                                    className="sharereportnavigation"
                                                    title={
                                                        notification.reportName
                                                    }
                                                ><p>{notification.reportName}</p>
                                                    <p
                                                        className="name"
                                                        title={
                                                            notification.sharedUserName
                                                        }
                                                    >
                                                        <i className="fa fa-user"></i>
                                    &nbsp;
                                    {
                                                            notification.sharedUserName
                                                        }
                                                    </p>
                                                    <span className="datetime" title={date[0]}>
                                                        {date[0]}
                                                        <span className="time">
                                                            {time}
                                                        </span>
                                                    </span>
                                                    <p className="msg">
                                                        <img src={require(`../../public/images/birdimages/charts/${
                                                            notification.vizType === "ml_storyboard" || notification.vizType === "smartinsight_storyboard" ?
                                                                "ml.svg" :
                                                                notification.vizType !== "story" ?
                                                                    notification.vizType + ".png"
                                                                    : "hc-multi1" + ".png"
                                                            }`)}
                                                            className="mail-avatar"
                                                            alt="avatar"
                                                        />
                                                        <span
                                                            title={
                                                                notification.user_comments
                                                            }
                                                        >
                                                            {
                                                                notification.user_comments
                                                            }
                                                        </span>
                                                    </p>
                                                            <div class="newmarkasread">
                                                    <div class="checkbox checkbox-slider--c checkbox-slider-sm"><span>Mark as Read</span>
                                                    <label><input type="checkbox" className="markread"
                                                    onClick={that.marknotifications}
                                                    title=""
                                                    reportid={notification.reportId}
                                                    status={notification.notification_status}/><span>&nbsp;</span>
                                                    </label>
                                                    </div>
                                                    </div>
                                                    {/* <p
                                                        className="markread"
                                                        onClick={that.marknotifications}
                                                        title=""
                                                        reportid={
                                                            notification.reportId
                                                        }
                                                        status={
                                                            notification.notification_status
                                                        }
                                                    ></p> */}
                                                </a>
                                            </li>
                                            )
                                    ) : null}


                            </React.Fragment>
                        );
                    }
                    )}
                            </>
                        ):null
                    }
                    {that.alertNotificationCollection.map((value, index) => {
                        if (value.notification_status != 1) {
                            return (
                                <li className="notifications-list clearfix notifications-new" key={index}>
                                    <a
                                        href={`${that.url}/sharereport/${btoa(value.reportId)}`}
                                        className="sharereportnavigation"
                                        title=""
                                    >
                                        <p
                                            className="name"
                                            title={
                                                value.alertname
                                            }
                                        >
                                            <i className="fa fa-bell-o"></i>
                                            {value.alertname}
                                        </p>
                                        <span className="datetime" title={value.createDate.split(" ")[0]}>
                                            {value.createDate.indexOf(".") != -1 ? value.createDate.split(".")[0] : value.createDate}
                                        </span>
                                        <p className="msg">
                                            <span className="bird-alert">
                                                <i className="fa fa-exclamation-triangle"></i>
                                            </span>
                                            <span>
                                                {value.message}
                                            </span>
                                        </p>
                                        <div class="newmarkasread">
                                            <div class="checkbox checkbox-slider--c checkbox-slider-sm"><span>Mark as Read</span>
                                            <label><input type="checkbox" className="markread"
                                            onClick={that.marknotifications}
                                            title=""
                                            status={
                                                value.notification_status
                                            }
                                            isalert="true"
                                            publishinfoid={
                                                value.publishinfo_id
                                            }/><span>&nbsp;</span>
                                            </label>
                                            </div>
                                        </div>
                                        {/* <p
                                            className="markread"
                                            onClick={that.marknotifications}
                                            title=""
                                            status={
                                                value.notification_status
                                            }
                                            isalert="true"
                                            publishinfoid={
                                                value.publishinfo_id
                                            }
                                        ></p> */}
                                    </a>
                                </li>
                            );
                        }
                    })
                    }
                </ul>
                {that.count !== 0 ? (
                    <li className="notifications-more clearfix">
                        <a
                            href={`/${window.localStorage.getItem("appName")}/welcome/notifications`}
                            className="viewallread"
                            onClick={that.viewNotifications}
                            title={lang["user.header.viewnotify"]}
                            id="notificationsviewall"
                        >
                            <i className="fa fa-bell-o"></i> {lang["user.viewall"]}
                </a>
                        <a
                            href="javascript.void(0)"
                            className="clearallread"
                            onClick={that.marknotifications}
                            title={lang["user.header.clearnotify"]}
                            id="notificationsclearall"
                            status="1"
                        >
                            <i className="fa fa-trash-o"></i> {lang["user.clearall"]}
                </a>
                    </li>
                ) : (
                        <>
                            <li className="notifications-empty">
                            {lang["user.nonotify"]}
                </li>
                            <li className="notifications-more clearfix">
                                <a
                                    href={`/${window.localStorage.getItem("appName")}/welcome/notifications`}
                                    className="viewallread"
                                    onClick={that.viewNotifications}
                                    title={lang["user.header.viewnotify"]}
                                    id="notificationsviewall"
                                >
                                    <i className="fa fa-bell-o"></i> {lang["user.viewall"]}
                  </a>
                                <a
                                    href="javascript.void(0)"
                                    className="clearallread"
                                    onClick={that.marknotifications}
                                    title={lang["user.header.clearnotify"]}
                                    id="notificationsclearall"
                                    status="1"
                                >
                                    <i className="fa fa-trash-o"></i> {lang["user.clearall"]}
                  </a>
                            </li>
                        </>
                    )}
            </div></>);
    }
}