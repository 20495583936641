import React from "react";
import ReactDOM from "react-dom";
import _ from 'lodash';
import $ from 'jquery/dist/jquery';
import PubSub from 'pubsub-js'
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import { lang } from "../js/helpers/utils/languages/language";


export default class DataTablePopupView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			multifactentities: props.multifactentities,
			facttables: props.facttables,
			multifact: props.multifact != undefined && props.multifact != null ? props.multifact : false,
			dimension: props.dimensions,
			target: props.target,
			chartnumber: props.currentChartNumber,
		}
		this.closepopup = this.closepopup.bind(this);
		this.showdobyselectedcolumns = this.showdobyselectedcolumns.bind(this);
		this.applydobysettings = this.applydobysettings.bind(this);
		this.dobyColumnsSearch = this.dobyColumnsSearch.bind(this);
	}

	componentDidMount() {
		$('.filtercheckbox1').click(function (e) {
			e.stopPropagation();
		})
	}
	closepopup() {
		$('.pivotdataviewwrap').remove();
		//$('.doby-offcanvas-columnlist-viewdata').removeClass('show');
		if(document.getElementById("datatablepopupmodal" + this.state.chartnumber)!=null){
			ReactDOM.unmountComponentAtNode(document.getElementById("datatablepopupmodal" + this.state.chartnumber));
		}
		$('.datatablepopupview'+this.state.chartnumber).remove();
		$('#datatablepopupmodal'+this.state.chartnumber).remove();
		$("#chartmetric" + this.state.chartnumber).removeClass('hide');
	}
	showdobyselectedcolumns(event) {
		event.preventDefault();
		var currenttarget = event.currentTarget;
		var that = this.state;
		var thiscopy = this;
		that.dimension.sort(function(a, b){
			var x = a.columnDisplayName.toLowerCase();
			var y = b.columnDisplayName.toLowerCase();
			return x < y ? -1 : x > y ? 1 : 0 ;
			});
		var chartnumber = $(currenttarget).attr("data-num");
		var chartData = ChartInfo.functions.getChartDetails(chartnumber);
		var data = JSON.parse(chartData);
		var yaxisdata = data["yAxis"];
		var yentities = [];
		_.each(yaxisdata, function (col) {
			yentities.push(col.columnName);
		});
		$('.innerdobyselectedcolumnnames' + chartnumber).empty();
		var data = that.dimension;
		if ($(currenttarget).hasClass("closeselectedcolumns")) {
			$('.doby-offcanvas-columnlist-viewdata').toggleClass("show");
		} else {
			var that = this.state;
			var disable = false;
			$('.doby-offcanvas-columnlist-viewdata').toggleClass("show");
			$('.innerdobyselectedcolumnnames' + chartnumber)
				.append("<li class='doby-list-item boc-list-item' ><input class='form-control form-control-dobycolumns-single search-image' placeholder="+lang["storyboard.search"]+" id='searchinput' type='text'></li>");
			var renameMeasuresorDimensions = sessionStorage.getItem('renameMeasuresorDimensions');
			var parsedData;
			var columnarray = [];
			if (that.multifact == 'true') {
				_.each(that.dimension, function (i, j) {
					var columnDisplayName = data[j].columnDisplayName;
					if (renameMeasuresorDimensions != null && renameMeasuresorDimensions != "null") {
						parsedData = JSON.parse(renameMeasuresorDimensions);
						if (parsedData[data[j].columnName] != undefined) {
							columnDisplayName = parsedData[data[j].columnName];
						}
					}
					disable = ChartInfo.functions.getAllowedEntitiesForInstantFilters(JSON.parse(that.multifactentities), columnDisplayName, undefined, yentities, undefined);
					if (!disable) {
						columnarray.push(i.attributes);
					}
					var columnName = data[j].columnName;
					var datatype = data[j].tableDisplayType;
				})
				_.each(columnarray, function (i, j) {
					$('.innerdobyselectedcolumnnames' + chartnumber)
						.append("<li class='boc-list-item' title= '" + i.columnName + "' ><label><input type='checkbox' data-num ='" + chartnumber + "'  class = 'filtercheckbox1' " + " name= '" + i.columnName + "' displayname= '" + i.columnDisplayName + "' datatype= '" + i.tableDisplayType + "'> </i>&nbsp;" + i.columnName + "</label></li>");
				})
			} else {
				/*$('.innerdobyselectedcolumnnames'+chartnumber)
				.append("<li class='doby-list-item boc-list-item' ><input class='form-control form-control-dobycolumns-single search-image' placeholder='Search' id='searchinput' type='text'></li>");*/
				var renameMeasuresorDimensions = sessionStorage.getItem('renameMeasuresorDimensions');
				var parsedData;
				_.each(that.dimension, function (i, j) {
					var columnDisplayName = data[j].columnDisplayName;
					if (renameMeasuresorDimensions != null && renameMeasuresorDimensions != "null") {
						parsedData = JSON.parse(renameMeasuresorDimensions);
						if (parsedData[data[j].columnName] != undefined) {
							columnDisplayName = parsedData[data[j].columnName];
						}
					}
					var columnName = data[j].columnName;
					var datatype = data[j].tableDisplayType;
					$('.innerdobyselectedcolumnnames' + chartnumber)
						.append("<li class='boc-list-item' title= '" + columnDisplayName + "' ><label><input type='checkbox' data-num ='" + chartnumber + "'  class = 'filtercheckbox1' " + " name= '" + columnName + "' displayname= '" + columnDisplayName + "'  datatype= '" + datatype + "'> </i>&nbsp;" + columnDisplayName + "</label></li>");
				})
			}
			$('.filtercheckbox1').click(function (event) {
				var $this = event.currentTarget;
				var yAxisNamesArray = [];
				yAxisNamesArray.push($($this).attr('name'))
				var chartnumber = $($this).attr('data-num')
				if ($(".innerdobyselectedcolumnnames" + chartnumber + " .filtercheckbox1:checked").length == 0) {
					$($this).prop("checked", true);
				}
			})
			var getdobyselecteddata = JSON.parse(ChartInfo.functions.getChartDetails(chartnumber))
			var dobyselecteddata = getdobyselecteddata["dobyselectedcolumns"];
			$(dobyselecteddata).each(function (i, j) {
				var displayname;

				if (renameMeasuresorDimensions != null && renameMeasuresorDimensions != "null" && parsedData[dobyselecteddata[i].actualnames] != undefined) {
					displayname = parsedData[dobyselecteddata[i].actualnames].replace(/([#;?&,%. +*~\':"!^$[\]()<=>|\/@])/g, "\\$1");
				} else {
					displayname = dobyselecteddata[i].displaynames.replace(/([#;?&,. +*~\':"!^$[\]()<=>|\/@])/g, "\\$1");
				}
				$('.innerdobyselectedcolumnnames' + chartnumber + ' .boc-list-item label input[displayname=' + displayname + ']').prop("checked", true)
			})
		}
		$('.form-control-dobycolumns-single').keyup(function (e) {
			thiscopy.dobyColumnsSearch(e);
		});
		$('.filtercheckbox1').click(function (e) {
			e.stopPropagation();
		})
	}

	applydobysettings(event) {
		event.preventDefault();
		var that = this.state;
		var currenttarget = event.currentTarget;
		var chartnumber = $(currenttarget).attr("data-num");
		var selecteddata = [];
		$('.innerdobyselectedcolumnnames' + chartnumber + ' .filtercheckbox1').each(function () {
			if ($(this).is(":checked")) {
				var temp = {};
				temp["actualnames"] = $(this).attr("name");
				temp["displaynames"] = $(this).attr("displayname");
				temp["datatype"] = $(this).attr("datatype");
				selecteddata.push(temp);
			}
		});
		var chartData = ChartInfo.functions.getChartDetails(chartnumber);
		var data = JSON.parse(chartData);
		data["dobyselectedcolumns"] = selecteddata;
		/*selecteddata.push(data["xAxis"]);
		selecteddata.push(data["yAxis"]);*/
		ChartInfo.functions.updateChartDetails(chartnumber, JSON.stringify(data));
		$('.pivotdataview[data-number=' + chartnumber + '] .doby-grid').remove();

		var viewDataTableObj = {};
		viewDataTableObj["currentChartNumber"] = chartnumber;
		viewDataTableObj["selectedcolumns"] = selecteddata;
		viewDataTableObj["state"] = "";
		viewDataTableObj["pos"] = "max";
		viewDataTableObj["popupwindow"] = "popupview";
		viewDataTableObj["target"] = this.state.target;
		viewDataTableObj["applyattributes"] = undefined;
		viewDataTableObj["filters"] = undefined;
		viewDataTableObj["tableInfo"] = undefined;
		PubSub.publish("viewDataTable", viewDataTableObj);


		//	this.broker.trigger("viewDataTable",chartnumber,selecteddata,"","max","popupview",this.target);
		$('.doby-offcanvas-columnlist-viewdata').toggleClass("show");
	}
	dobyColumnsSearch(e) {
		var $this = e.currentTarget;
		var valThis = $($this).val().toLowerCase();
		$("input:checkbox[class='filtercheckbox1']").parents(".boc-list-item").each(function () {
			var text = $(this).text().toLowerCase();
			(text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
		});
	}
	render() {
		var that = this.state;
		that.appendheaders = this.props.appendheaders != undefined ? this.props.appendheaders : true;
		var currentChartNumber = that.chartnumber;
		$('.doby-popup-container[data-num=' + that.chartnumber + ']').empty();
		$('.pivotdataviewwrap').remove();
		//$(".pivotdataview[data-number='" + that.chartnumber + "'], .pivotdataviewwrap[data-number='" + that.chartnumber + "']").remove();
		if (that.multifact=="true"||that.multifact==true) {
			that.appendheaders = false;
		}
		return (
			<div>
				{/* <!-- DataTablePopupView.js --> */}
				<div className='pivotdataviewwrap' data-number={currentChartNumber}>
					<div className="pivotdataview" data-number={currentChartNumber}>
						<div className="ball-pulse"><div></div><div></div><div></div></div>
						<div className="pivotdataviewclose" onClick={this.closepopup} id="pivotdataviewdatapop">
							<i className='fa fa-times'></i>
						</div>
						<div className="pivottile">{lang["storyboard.Dataview"]}</div>
						{/*   <span className="summarytable_datasetcount"></span><span> | </span><span>Filtered Count: <span className="summarytable_filtered_datasetcount"></span></span><br/>
 <span>Filter Values: <span className="summarytable_filtervalues"></span></span> */}
						<div className="container-fluid marg-bot-10">
							<div className="row">
								{(that.appendheaders !== false) ? (
									<>
										<div className="col-lg-8 nopadding summarydatacountcontainer">
											<span className="summarytable_datasetcount"></span><span> | </span><span>{lang["storyboard.Filtercount"]} <span className="summarytable_filtered_datasetcount"></span></span><br />
											<span>{lang["storyboard.Filtervalues"]} <span className="summarytable_filtervalues"></span></span>
										</div>
										<div className="col-lg-4">
											<div className="opacitydiv">
												<button className="btn btn-bird pull-right btn-sm singlereportselectedcolumns" onClick={this.showdobyselectedcolumns} id="singlereportselectedcolumnspop" data-num={currentChartNumber} tab-id="0" tab-name="">
													<i className="fa fa-columns"></i>
												</button>
											</div>
										</div></>
								) : null}
								<div id={`pivotdataview${currentChartNumber}`}></div>
							</div>
						</div>

					</div>
				</div>
				{(that.appendheaders !== false) ? (
					<>
						<div className='doby-popup-container' data-num={currentChartNumber} tab-id="0" tab-name="">
							<div className="doby-offcanvas-columnlist-viewdata backdrop" data-num={currentChartNumber} tab-id="0" tab-name=""></div>
							<div className='doby-offcanvas-columnlist-viewdata'>
								<span className={`dobyselectcolumns${currentChartNumber} closedobycolumns singlereportselectedcolumns closeselectedcolumns`} onClick={this.showdobyselectedcolumns} data-num={currentChartNumber} id="close"><i className="fa fa-times"></i></span>
								<div className='container-fluid'>
									<div className='row dobycolumnscontent'>
										<legend className="subhead"><i className="fa fa-columns marg-righ-5"></i>{lang["storyboard.choosecolumns"]}</legend>
										<div className={`innerdobyselectedcolumnnames${currentChartNumber} scrollsetdobycolumns`}>
											<li className="doby-list-item boc-list-item">
												<input className='form-control form-control-dobycolumns-single search-image' onKeyUp={this.dobyColumnsSearch} placeholder={lang["storyboard.search"]} id='searchinput' autoFocus type='text'></input></li>

										</div>
									</div>
									<div className='dobycolumnslimitbuttons'>
										<button type='button' className='btn btn-bird btn-sm singlereportselectedcolumns closeselectedcolumns' onClick={this.showdobyselectedcolumns} data-num={currentChartNumber} id="singlereportselectedcolumnsdata"><i className="fa fa-times marg-righ-5"></i>{lang["storyboard.cancelbtn"]}</button>
										<button type='button' className='btn btn-bird btn-sm applydobyviewdatasettings' onClick={this.applydobysettings} id="applydobyviewdatasettingsdata" data-num={currentChartNumber} tab-id="0" tab-name=""><i className="fa fa-check marg-righ-5"></i>{lang["storyboard.applybtn"]}</button>
									</div>
								</div>
							</div>
						</div>
					</>
				) : null}
			</div>
		);
	}
}