import React from 'react';
import { lang } from "../js/helpers/utils/languages/language";
export default class WorkspaceDatahubItemsContainerHtml extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        var icon = "";
        return (
            <React.Fragment>
                {/* <!-- workspacedatahubtablesview.js --> */}
                <div className="col-xs-12 nopadding">
                    <div className="panel-group">
                        <div className="acc-content">
                            <div className="panelboc">
                                <div className="panelboc-heading ws_cq">
                                    <h4 className="panelboc-title accordion-toggle" title={lang["workspace.customquery"]} data-toggle="collapse">
                                        <i className="fa fa-code"></i>{lang["workspace.customquery"]}
                                        <button className="btn btn-transparent workspace-addcustomquery" hub_id={this.props.datahubid} onClick={(e) => { this.props.workspaceaddcustomquery(e) }}><i className="fa fa-plus"></i></button>
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className="acc-content" id="workspacedatahubitemscontainer">
                            <div className="panelboc">
                                <div className="panelboc-heading">
                                    <h4 className="panelboc-title accordion-toggle" title="" id="tablecolllapse"><i className="fa fa-database"></i>{lang["workspace.entities"]}</h4>
                                </div>
                                <div id="workspacedbitems" className="panel-collapse panelboc-content filterStringvalues newreportdbitems collapse in" aria-expanded="true" style={{}}>
                                    <div className="panelboc-body">
                                        <div className="filtercontent" id="scopeItem">
                                            <div className="container-fluid marg-bot-10">
                                                <div className="row">
                                                    <input className="form-control search-image" name="Scope_Production" placeholder={lang["workspace.search"]} id="searchinput" type="text" onChange={(e) => { this.props.search(e) }} />
                                                </div>
                                            </div>
                                            < div className="container-fluid" id="workspacehubenttityfluidcontainer">
                                                <div className="row" id="workspacehubenttityrow">
                                                    <div id="finalconnectionlistcontainer" className="col-xs-12">
                                                        <div className="panel-group panel">
                                                            {this.props.entities != undefined && this.props.entities != null ?
                                                                this.props.entities.map((sources, counti) => {
                                                                    return <>
                                                                        <h4 href={`#${sources.sourceType}${counti}`} className="panelboc-title accordion-toggle" title={sources.sourceDisplayName} data-id={counti} data-toggle="collapse" data-parent="#finalconnectionlistcontainer" aria-expanded="false" aria-controls={sources.sourceType}><img src={require(`../images/birdimages/${sources.sourceType}.png`)} alt="" />{sources.sourceDisplayName}</h4>
                                                                        <div id={`${sources.sourceType}${counti}`} name={sources.sourceDisplayName} data-id={counti} className="panel-collapse panelboc-content collapse" aria-expanded="false">
                                                                            <div className="panelboc-body" style={{ maxHeight: "initial" }}>
                                                                                {sources.connectionsData.map((connection, i) => {
                                                                                    return <>
                                                                                        <h4 title={connection.connectionDisplayName}><i className="fa fa-link"></i>{connection.connectionDisplayName}</h4>
                                                                                        <ul className="list-group">
                                                                                            {connection.entitiesData.map((entity, li) => {
                                                                                                return <li key={li} className="viewreport-list-item">
                                                                                                    <label title={entity.entityname}><span className="source"><i className="fa fa-link"></i>{entity.connName}</span>
                                                                                                        <i className={`fa ${entity.entityType === "storedprocedure" ? "fa-list-alt" : "fa-table"}`}></i>
                                                                                                        <input type="checkbox" durable="false" data_type="new" className="scopeitem" entity_id={entity.entityId} hub_id={entity.datahubid} data_parent={entity.connAccessId} name={entity.entityviewname} id={entity.entityviewname} data-value={entity.entityviewname} />{entity.entityviewname}</label>
                                                                                                    <button className="btn btn-transparent btn-sm editworkspaceitem" data_type="new" entity_id={entity.entityId} hub_id={entity.datahubid} data_parent={entity.connAccessId} name={entity.entityviewname} type="button" title="Settings"><i className="fa fa-cog"></i></button>
                                                                                                    <button className="btn btn-transparent btn-sm joinremove" data_type="new" entity_id={entity.entityId} hub_id={entity.datahubid} data_parent={entity.connAccessId} name={entity.entityviewname} type="button"><i className="fa fa-times"></i></button>
                                                                                                    <div className="form-check checkbox-slider--b-flat checkbox-slider-sm" title="Set As Fact Table"><label><input class="fact" type="checkbox" data_type="new" entity_id={entity.entityId} hub_id={entity.datahubid} data_parent={entity.connAccessId} name={entity.entityviewname} /><span></span></label></div>
                                                                                                </li>
                                                                                            })}
                                                                                        </ul>
                                                                                    </>
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                })
                                                                : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <ul className="list-group scrollsetul checkhelpitems">
                                                {
                                                    (this.props.entities !== undefined && this.props.entities !== null) ? (
                                                        this.props.entities.map((entity, index) => {
                                                            var icon = "fa-table";
                                                            if (entity.entityType === "storedprocedure") {
                                                                icon = "fa-list-alt";
                                                            }
                                                            return (
                                                                <li className="viewreport-list-item" key={index}>
                                                                    <label title={entity.entityname}>
                                                                        <span className="source">
                                                                            <i className="fa fa-link"></i>
                                                                            {entity.connName}
                                                                        </span>
                                                                        <i className={`fa ${icon}`}></i>
                                                                        <input
                                                                            type="checkbox"
                                                                            durable="false"
                                                                            data_type="new"
                                                                            className="scopeitem"
                                                                            entity_id={entity.entityId}
                                                                            hub_id={entity.datahubid}
                                                                            data_parent={entity.connAccessId}
                                                                            name={entity.entityname}
                                                                            id={entity.entityname}
                                                                            data-value={entity.entityname}
                                                                        />
                                                                        {entity.entityname}
                                                                    </label>
                                                                    <button className="btn btn-transparent btn-sm editworkspaceitem" data_type="new" entity_id={entity.entityId} hub_id={entity.datahubid} data_parent={entity.connAccessId} name={entity.entityname} type="button" title="Settings" onClick={(e)=>{this.props.editworkspaceitem(e);}}><i className="fa fa-cog"></i></button>
                                                                    <button className="btn btn-transparent btn-sm joinremove" data_type="new" entity_id={entity.entityId} hub_id={entity.datahubid} data_parent={entity.connAccessId} name={entity.entityname} type="button"><i className="fa fa-times"></i></button>
                                                                    <div className="form-check checkbox-slider--b-flat checkbox-slider-sm" title="Set As Fact Table">
                                                                        <label>
                                                                            <input
                                                                                className="fact"
                                                                                type="checkbox"
                                                                                data_type="new"
                                                                                entity_id={entity.entityId}
                                                                                hub_id={entity.datahubid}
                                                                                data_parent={entity.connAccessId}
                                                                                name={entity.entityname}
                                                                            />
                                                                            <span></span>
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                            );
                                                        })
                                                    ) : null
                                                }
                                            </ul> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- workspacedatahubtablesview.js --> */}
            </React.Fragment>
        );
    }
}