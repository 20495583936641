import ReactDOM from "react-dom";
import React, { Component } from "react";
import { lang } from "../js/helpers/utils/languages/language";
import PubSub from 'pubsub-js'

export default class AttrModalCustomFieldsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicdiv: props.dynamicdiv,
            columnDisplayName: props.columnDisplayName,
            columnName: props.columnName,
            dataType: props.dataType,
            aggregation: props.aggregation,
        };
        this.render();
    }
    render() {
        var isViewModel = false;
        // if(Backbone.history.getFragment().indexOf("viewmodel")>=0){
        // 	isViewModel = true;
        // }
        ReactDOM.render(<AttributesCustomfields deletecustomfield={this.props.deletecustomfield} dynamicdiv={this.state.dynamicdiv}
            columnDisplayName={this.state.columnDisplayName} columnName={this.state.columnName}
            dataType={this.state.dataType} aggregation={this.state.aggregation} isViewModel={isViewModel}
        ></AttributesCustomfields>, document.getElementById(this.state.dynamicdiv));

        return (<div></div>);
    }
  
}

class AttributesCustomfields extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.createCustomFiled = this.createCustomFiled.bind(this);
    }
    render() {
        var that = this.props;
        return (
            //    <!-- attrmodalcustomfieldview.js -->
            <li key={that.dynamicdiv} className="boc-list-item singleliitems  attributesdimensions attributescustomfields" iscustomfunction="1" data-name={that.columnName} data-type={that.dataType} title={that.columnDisplayName}>

                {(that.dataType === 'number') ? (
                    <span className="itemtype fx"> 123</span>
                ) : (that.dataType === 'date' || that.dataType === 'datetime' || that.dataType === 'month' || that.dataType === 'day') ? (
                    <span className="itemtype fx"> <i className="fa fa-calendar"></i></span>
                ) :
                        <span className="itemtype fx"> {lang["storyboard.abc"]}</span>
                }
                <span className="itemname">{that.columnDisplayName}</span>
                <span type="button" data-name={that.columnName} iscustomfunction="1" title={that.columnDisplayName} className="opacity-effect callcustommeasure createfeatureengineeringfield" onClick={this.createCustomFiled} id="callcustommeasures" data-edit={lang["storyboard.edit"]}>
                    <i className="fa fa-pencil"></i>
                </span>
                <span type="button" data-name={that.columnName} data-displayname={that.columnName} data-type={that.dataType} className="boclabel opacity-effect previewcolumndata" title={lang["storyboard.previewdata"]} onClick={(e)=>{this.PreviewData(e,that.columnName,that.columnName,that.dataType)}}>
                    <i className="fa fa-table"></i>
                </span>
                <span type="button" data-name={that.columnName} iscustomfunction="1" title={that.columnDisplayName} onClick={that.deletecustomfield} className="deletecustomfield opacity-effect pull-right marg-left-10">
                    <i className="fa fa-trash-o"></i>
                </span>
                {(!that.isViewModel) ? (
                    <></>
                    //    <!-- <span data-toggle="dropdown" aria-expanded="false" 	id="dropdownaggregation" type="button"   class="boclabel boclabel-right-5 opacity-effect pull-right marg-righ-10">
                    //        <i class="fa fa-cog"></i>
                    //    </span>
                    //    <!-- <ul aria-labelledby="dropdownaggregation" role="menu" 		class="dropdown-menu dropdown-inline">
                    //        <li class="aggregation-li-item converttomeasure"  data-name={that.columnName} data-type="<%= that.dataType==undefined ?'string': that.dataType%>" title={that.columnDisplayName}>Create a Measure</li>
                    //        <li class="aggregation-li-item converttomonth"  data-name={that.columnName} data-type="<%= that.dataType==undefined ?'string': that.dataType%>" title={that.columnDisplayName}>Convert to Month</li>
                    //        <li class="aggregation-li-item converttoday"  data-name={that.columnName} data-type="<%= that.dataType==undefined ?'string': that.dataType%>" title={that.columnDisplayName}>Convert to Day</li>
                    //    </ul> -->
                ) : null}
            </li>);
    }
    createCustomFiled(e){
        PubSub.publish('createCustomFiled', e.currentTarget);
    }
    PreviewData(e,name,dispalyname,datatype){
        var appObj={};
        appObj["name"]=name;
        appObj["displayname"]=dispalyname;
        appObj["datatype"]=datatype;
        appObj["target"]=e;

        PubSub.publish("previewcolumndata",appObj);
    }
   
}
