import React from "react";
import { Link } from 'react-router-dom';
import $ from "jquery";
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import { lang } from "../js/helpers/utils/languages/language";
import SendRequest from "../SendRequest";
import Favourites from "./Favourites.jsx";
import Storyboards from "./Storyboards.jsx";
import StoryboardListView from './StoryboardListView';
import StorybookListView from './StorybookListView'; 
import MyReports from "./MyReports.jsx";
import SharedReports from "./SharedReports.jsx";
import StoryBooks from "./StoryBooks.jsx";
import CustomStoryboards from "./CustomStoryboards.jsx";
import FavoritesListView from "./FavoritesListView";
import MyreportsListView from "./MyReportsListView";
import SharedReportListView from "./SharedReportsListView";
import FoldersTemplate from "./FoldersHtml.jsx"
import CustomstoryboardListView from "./CustomStoryboardsListView";
import _ from 'lodash';
import Header from "../Home/Header";

export default class Visualization extends React.Component {
  constructor(props) {
    $('.spinner').show();
    super(props);
    this.state = {
      displayFooter: true,
      favourites: [],
      storyboards: [],
      storybooks: [],
      customstoryboards: [],
      reports: [],
      sharedreports: [],
      features: [],
      isStoryboardListview: false,
      isfavoriteListView: false,
      favoritesList: [],
      storyboardsList: [],
      myreportsList: [],
      sharedReportsList: [],
      isStoryBookListView: false,
      isCustomStoryboardListView: false,
      isSharedReportListView: false,
      isMyReportListView: false,
      storyBookList: [],
      foldersList:[],
      folderreports:[],
      isNotFolderView:true,
      customstoryboard :"hide",
      createcustomstoryboard:"hide",
      deletecustomstoryboard:"hide"
    };
    
    var that= this;
    let requestHeaders = {
      method: "get",
      headers: new Headers({
        "Content-Type": "application/json"
      })
    };
    SendRequest({
      url: "getfeatures",
      queryString: "",
      sucessFunction: props => {
        that.state.features=props 
        ChartInfo.functions.setAllFeatures(props);
        _.each(that.state.features, function (featuresModule) {
          if (featuresModule.moduleName == 'story_board') {
              _.each(featuresModule.features, function (feature) {
                if (feature.featureName == 'view_customstoryboard' && feature.allow == true) {
                  that.state.customstoryboard = "";
                }
                if (feature.featureName == 'create_customstoryboard' && feature.allow == true) {
                  that.state.createcustomstoryboard = "";
                }
                if (feature.featureName == 'delete_customstoryboard' && feature.allow == true) {
                  that.state.deletecustomstoryboard = "";
                }
              })
            }
          })
      },
      rejectFunction: () => { },
      headers: requestHeaders
    });
  }
  UNSAFE_componentWillMount() {
    document.body.setAttribute("data-spy", "scroll");
    document.body.setAttribute("data-target", "#myScrollspy");
    document.body.classList.add("day");
  }
  favoritegridviewclick = (e) => {
    this.setState({ isfavoriteListView: false });
    $("#favlisttable").remove();
    $(".favoritelistview").removeClass('active');
    $(".favoritegridview").addClass('active');
    $("#favouritesgridsmaincontainer>.btn").removeClass("hide");
  }
  favoritelistviewclick = (e) => {
    this.setState({ isfavoriteListView: true })
    $("#favouritesgridsmaincontainer").addClass("favoritelistviewsection");
    $(".favoritegridview").removeClass('active');
    $(".favoritelistview").addClass('active');
    $("#favouritesgridsmaincontainer>.btn").addClass("hide");
  }
  storyboardgridviewclick = (e) => {
    this.setState({ isStoryboardListview: false });
    $("#storyboardlisttable").remove();
    $(".storyboardlistview").removeClass('active');
    $(".storyboardgridview").addClass('active');
    $("#mystoryboardsgridsmaincontainer>.btn").removeClass("hide");
  }
  storyboardlistviewclick = (e) => {
    this.setState({ isStoryboardListview: true })
    $("#mystoryboardsgridsmaincontainer").addClass("storyboardlistviewsection");
    $(".storyboardgridview").removeClass('active');
    $(".storyboardlistview").addClass('active');
    $("#mystoryboardsgridsmaincontainer>.btn").addClass("hide");
  }
  customstoryboardgridviewclick = (e) => {
    this.setState({ isCustomStoryboardListView: false });
    $("#customstoryboardlisttable").remove();
    $(".customstoryboardslistview").removeClass('active');
    $(".customstoryboardsgridview").addClass('active');
    $("#customstoryboardgridsmaincontainer>.btn").removeClass("hide");
  }
  customstoryboardlistviewclick = (e) => {
    this.setState({ isCustomStoryboardListView: true });
    $("#customstoryboardgridsmaincontainer").addClass("customstoryboardviewsection");
    $(".customstoryboardsgridview").removeClass('active');
    $(".customstoryboardslistview").addClass('active');
    $("#customstoryboardgridsmaincontainer>.btn").addClass("hide");
  }
  storybookgridviewclick = (e) => {
    this.setState({ isStoryBookListView: false });
    $("#storybooklisttable").remove();
    $(".storybookslistview").removeClass('active');
    $(".storybooksgridview").addClass('active');
    $("#mystorybooksgridsmaincontainer>.btn").removeClass("hide");
  }
  storybooklistviewclick = (e) => {
    this.setState({ isStoryBookListView: true });
    $("#mystorybooksgridsmaincontainer").addClass("storybooklistviewsection");
    $(".storybooksgridview").removeClass('active');
    $(".storybookslistview").addClass('active');
    $("#mystorybooksgridsmaincontainer>.btn").addClass("hide");
  }
  myreportsgridviewclick = (e) => {
    this.setState({ isMyReportListView: false });
    $("#reportlisttable").remove();
    $(".myreportlistview").removeClass('active');
    $(".myreportgridview").addClass('active');
    $("#myreportgridsmaincontainer>.btn").removeClass("hide");
  }
  myreportslistviewclick = (e) => {
    this.setState({ isMyReportListView: true });
    $("#myreportgridsmaincontainer").addClass("myreportlistviewsection");
    $(".myreportgridview").removeClass('active');
    $(".myreportlistview").addClass('active');
    $("#myreportgridsmaincontainer>.btn").addClass("hide");
  }
  sharedreportsgridviewclick = (e) => {
    this.setState({ isSharedReportListView: false });
    $("#sharereportlisttable").remove();
    $(".sharedreportslistview").removeClass('active');
    $(".sharedreportsgridview").addClass('active');
    $("#sharedreportgridsmaincontainer>.btn").removeClass("hide");
  }
  sharedreportslistviewclick = (e) => {
    this.setState({ isSharedReportListView: true });
    $("#sharedreportgridsmaincontainer").addClass("sharedreportslistviewsection");
    $(".sharedreportsgridview").removeClass('active');
    $(".sharedreportslistview").addClass('active');
    $("#sharedreportgridsmaincontainer>.btn").addClass("hide");
  }
  folderviewClick=(e) =>{
    this.setState({isNotFolderView:!this.state.isNotFolderView})
  }
  createcustomstoryboard = (e) => {
    var $this = e.currentTarget;
    $('.spinner').show();
    var href = "/" + window.localStorage.getItem("appName") + "/welcome/customstoryboard/";
    this.props.history.push(href);
    $('.spinner').hide();
   
}
  componentDidMount() {
    $('.spinner').show();
    $(document).on('click','.data-item-name>a, .data-item-actions>ul>li:first-child>a',function (e) {
      $(".spinner").show();
      setTimeout(function(){
        if(window.location.pathname=="/bird/welcome" || window.location.pathname=="/bird/welcome/visualization"){
          $(".spinner").hide();
        }
      },1000);
    });
    $(document).on('click','.createstorybook>a, button#createcustomstoryboard',function (e) {
      $(".spinner").show();
      // setTimeout(function(){$(".spinner").hide();},1000);
    });
    let requestHeaders = {
      method: "get",
      headers: new Headers({
        "Content-Type": "application/json"
      })
    };
    SendRequest({
      url: "getfeatures",
      queryString: "",
      sucessFunction: props => {
        this.setState({ features: props });
        ChartInfo.functions.setAllFeatures(props);
      },
      rejectFunction: () => { },
      headers: requestHeaders
    });
    SendRequest({
      url: "getuserfavourites",
      queryString: "",
      sucessFunction: props => {
        this.setState({ favourites: props });
      },
      rejectFunction: () => { },
      headers: requestHeaders
    });
    SendRequest({
      url: "getuserstoryboards",
      queryString: "?isFromDashboard="+true,
      sucessFunction: props => {
        this.setState({ storyboards: props });
      },
      rejectFunction: () => { },
      headers: requestHeaders
    });
    SendRequest({
      url: "getuserstorybooks",
      queryString: "",
      sucessFunction: props => {
        this.setState({ storybooks: props });
      },
      rejectFunction: () => { },
      headers: requestHeaders
    });
    SendRequest({
      url: "getusercustomstoryboards",
      queryString: "",
      sucessFunction: props => {
        this.setState({ customstoryboards: props });
      },
      rejectFunction: () => { },
      headers: requestHeaders
    });
    SendRequest({
      url: "getuserreports",
      queryString: "?isFromDashboard="+true,
      sucessFunction: props => {
        this.setState({ reports: props });
      },
      rejectFunction: () => { },
      headers: requestHeaders
    });
    SendRequest({
      url: "getusersharedreports",
      queryString: "",
      sucessFunction: props => {
        this.setState({ sharedreports: props });
      },
      rejectFunction: () => { },
      headers: requestHeaders
    });
    SendRequest({
      url: "getusersfolder",
      queryString: "",
      sucessFunction: props => {
        props.sort(function(a, b){
          var x = a.Folder;
          var y = b.Folder;
          return x < y ? -1 : x > y ? 1 : 0 ;
         })
        this.setState({ foldersList: props });
      },
      rejectFunction: () => { },
      headers: requestHeaders
    });
    SendRequest({
      url: "getReportsByFolder",
      queryString: "",
      sucessFunction: props => {
        this.setState({ folderreports: props });
        setTimeout(function(){$(".spinner").hide();},1000);
      },
      rejectFunction: () => { },
      headers: requestHeaders
    });
    var that = this;
    $(".dashboardview").click(function (e) {
      that.folderviewClick(e);
    })
  }
  setActiveSection=(e)=>{
    $('ul.nav-tabs>li').removeClass("active");
    $(e.currentTarget).addClass("active");
  }

  render() {
    var that=this;
    return (
      <React.Fragment>
      <Header {...this.props} url={this.props.url} pageType={this.props.pageType} />
      <div id="main">
        <section className="boc-main-section">
          <div
            className="boc-home"
            data-spy="scroll"
            data-target="#myScrollspy"
          >
            {/* <!--<div className="boc-home-header">Some Header Data if needed</div>--> */}
            <div className="container-fluid">
              <div className="row">
                <div
                  className="col-xs-12 sub-nav"
                  style={{
                    position: "fixed",
                    top: "46px",
                    zIndex: 9,
                    background: "#fff",
                    textAlign: "center",
                    height: "33px"
                  }}
                >
                  {this.state.isNotFolderView?
                  <div className="scrollfix" id="myScrollspy">
                    <ul className="nav nav-tabs nav-stacked affix-top" data-spy="affix" data-offset-top="0">
                      <li className="active" id="favorite" title={lang["visual.favourites.title"]} onClick={this.setActiveSection}>
                        <a href="#section-1">
                          <i className="fa fa-star fa-fw sidepin"></i>
                          <span>{lang["visual.favourites.title"]}</span>
                        </a>
                      </li>
                      <li title="My Storyboards" id="mystoryboard" onClick={this.setActiveSection}>
                        <a href="#section-2">
                          <i className="fa fa-th-large fa-fw sidepin"></i>
                          <span>{lang["visual.favourites.storyboard"]}</span>
                        </a>
                      </li>
                      {/* <li title="My Storybooks" id="mystorybook" onClick={this.setActiveSection}>
                        <a href="#section-3">
                          <i className="fa fa-book fa-fw sidepin"></i>
                          <span>{lang["visual.favourites.storybooks"]}</span>
                        </a>
                      </li> */}
                      {/* <li title="Custom Storyboards" id={`customstoryboard ${that.state.customstoryboard}`} class={`customstoryboard ${that.state.customstoryboard}`}>
                        <a href="#section-6">
                          <i className="fa fa-book fa-fw sidepin"></i>
                          <span>Custom Storyboards</span>
                        </a>
                      </li> */}
                      <li title="My Reports" id="myreport" onClick={this.setActiveSection}>
                        <a href="#section-3">
                          <i className="fa fa-bar-chart fa-fw sidepin"></i>
                          <span>{lang["visual.favourites.reports"]}</span>
                        </a>
                      </li>
                      <li title="Shared Reports" id="sharedreports" onClick={this.setActiveSection}>
                        <a href="#section-4">
                          <i className="fa fa-share fa-fw sidepin"></i>
                          <span>{lang["visual.favourites.shared"]}</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  :<React.Fragment></React.Fragment>
                  }
                  {/* <!-- <buttton className="btn btn-transparent btn-sm" id="toggledashboardsearch" title="Search"><i className="fa fa-search fa-flip-horizontal"></i> SEARCH</buttton> --> */}
                  <div className="checkbox pull-right folderview">
                    <span title={lang["visual.favourites.gridview"]}>
                      <i className="fa fa-th-large"></i>
                    </span>
                    <label className="checkbox-slider--c">
                      <input type="checkbox" id="dashboardview"
                        className="dashboard-view-style dashboardview" defaultChecked={this.state.isFolderView}
                      />
                      <span></span>
                    </label>
                    <div title={lang["visual.favourites.folderview"]}>
                      <i className="fa fa-folder"></i>
                    </div>
                  </div>
                </div>
                <div
                  className="col-xs-12"
                  id="birdintelligentstoryboardsearch-container"
                >
                  <div className="input-group is-search-group-container">
                    <input id="birdintelligentstoryboardsearch" />
                    <span
                      className="input-group-addon is-get-results searchbutton"
                      id="searchdashboards"
                    >
                      <i className="fa fa-search fa-flip-horizontal"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {this.state.isNotFolderView?
            <div className="boc-home-content smallthumbview regularviewcontainer">
              <ul className="boc-carousel-home" id="dashboardList">
                <div id="favouritesdiv">
                  <div id="section-1"></div>
                  <div id="favouritesgridsmaincontainer">
                    <header className="container-fluid">
                      <h2 className="datamain-title">
                        <i className="fa fa-star"></i>&nbsp; {lang["visual.favourites.title"]}
                        <div className="btn-group pull-right" role="group">
                          <button
                            className="btn btn-bird active favoritegridview"
                            id="favoritegridviewgrid"
                            type="button"
                            title={lang["visual.favourites.gridview"]}
                            onClick={this.favoritegridviewclick}
                          >
                            <i className="fa fa-th-large"></i>
                          </button>
                          <button
                            className="btn btn-bird favoritelistview"
                            id="favoritelistviewlist"
                            type="button"
                            title={lang["visual.favourites.listview"]}
                            onClick={this.favoritelistviewclick}
                          >
                            <i className="fa fa-th-list"></i>
                          </button>
                        </div>
                      </h2>
                    </header>
                    <div className="data-items-container"> 
                      {
                        this.state.isfavoriteListView ? 
                          <FavoritesListView url={this.props.url} foldersArray={this.state.foldersList}/>  
                          : 
                          <React.Fragment>                     
                           <Favourites state={this.state} TargetThis={this} url={this.props.url} favourites={this.state.favourites} features={this.state.features} />
                          
                            </React.Fragment>
                      }
                     </div> 
                     {this.state.favourites.length !== undefined &&
                        this.state.favourites.length > 10 ? (
                          <button
                            toggle="View More..."
                            className="btn btn-bird-group-transaprent viewallfavoritesreports  pull-right"
                            id="dashviewallfavoritesreports" onClick={this.favoritelistviewclick}
                          >
                            {lang["visual.favourites.viewmore"]}
                          </button>
                        ) : null} 
                  </div>
                </div>
                <div id="storyboarddiv">
                  <div id="section-2"></div>
                  <div id="mystoryboardsgridsmaincontainer">
                    <header className="container-fluid">
                      <h2 className="datamain-title pull-left">
                        <i className="fa fa-th-large"></i>&nbsp; {lang["visual.favourites.myboards"]}
                      </h2>
                      <div
                        className="btn-group pull-right marg-left-5 h2"
                        role="group"
                      >
                        <button
                          className="btn btn-bird active storyboardgridview"
                          id="storyboardgridview"
                          type="button"
                          title={lang["visual.favourites.gridview"]}
                          onClick={this.storyboardgridviewclick}
                        >
                          <i className="fa fa-th-large"></i>
                        </button>
                        <button
                          className="btn btn-bird storyboardlistview"
                          id="storyboardlistviewlist"
                          type="button"
                          title={lang["visual.favourites.listview"]}
                          onClick={this.storyboardlistviewclick}
                        >
                          <i className="fa fa-th-list"></i>
                        </button>
                      </div>
                    </header>
                    <div className="data-items-container">
                      {
                        this.state.isStoryboardListview ?
                             <StoryboardListView url={this.props.url} foldersArray={this.state.foldersList}/>                  
                          :
                          <React.Fragment>
                          <Storyboards state={this.state} TargetThis={this} storyboards={this.state.storyboards} features={this.state.features} url={this.props.url} />
                          
                            </React.Fragment>
                      }
                      </div>
                      {this.state.storyboards.length !== undefined &&
                            this.state.storyboards.length > 10 ? (
                              <button
                                toggle="View More..."
                                className="btn btn-bird-group-transaprent viewallstoryboardreports  pull-right"
                                id="dashviewallfavoritesreports" onClick={this.storyboardlistviewclick}
                              >
                                {lang["visual.favourites.viewmore"]}
                              </button>
                            ) : null}
                  </div>
                </div>
                <div id="customstoryboarddiv" class={`customstoryboarddiv ${that.state.customstoryboard}`}>
                  <div id="customstoryboardgridsmaincontainer">
                    <header className="container-fluid">
                      <h2 className="datamain-title pull-left">
                        <i className="fa fa-th-large"></i>&nbsp; {lang["visual.favourites.customboards"]}
                      </h2>
                      <div
                        className="btn-group pull-right marg-left-5 h2"
                        role="group"
                      >
                       {/* //</div> if (feature.moduleName == "data_hub") { */}
                       {this.state.features.map((feature) => {
                                if (feature.moduleName == "data_hub") {
                                    return   <button
                                    className={`btn btn-bird createcustomstoryboard tooltip-left ${that.state.createcustomstoryboard}`}
                                    data-tooltip={lang["visual.favourites.createboard"]}
                                    id="createcustomstoryboard"
                                    type="button"
                                    title={lang["visual.favourites.createboard"]}
                                    onClick={this.createcustomstoryboard}
                                   >
                                  {/* //<a href={this.props.url + "/viewcustomstoryboard"}>
                                    */}
                                      <i className="fa fa-plus"></i>
                                  </button>
                                }
                            })}
                      
                        <button
                          className="btn btn-bird active customstoryboardsgridview"
                          id="customstoryboardsgridview"
                          type="button"
                          title={lang["visual.favourites.gridview"]}
                          onClick={this.customstoryboardgridviewclick}
                        >
                          <i className="fa fa-th-large"></i>
                        </button>
                        <button
                          className="btn btn-bird customstoryboardslistview"
                          id="customstoryboardsviewlist"
                          type="button"
                          title={lang["visual.favourites.listview"]}
                          onClick={this.customstoryboardlistviewclick}
                        >
                          <i className="fa fa-th-list"></i>
                        </button>
                      </div>
                    </header>
                    <>
                    <div className="data-items-container">
                      {
                        this.state.isCustomStoryboardListView?
                          <CustomstoryboardListView  url={this.props.url} deletecustomstoryboard={this.state.deletecustomstoryboard}/>
                          :
                          <React.Fragment>
                            <CustomStoryboards state={this.state} TargetThis={this} url={this.props.url} customstoryboards={this.state.customstoryboards} features={this.state.features} deletecustomstoryboard={this.state.deletecustomstoryboard}/>
                            
                              </React.Fragment>
                      }
                      </div>
                      {this.state.customstoryboards.length !== undefined &&
                        this.state.customstoryboards.length > 10 ? (
                          <button
                            toggle="View More..."
                            className="btn btn-bird-group-transaprent viewallcustomstoryboards  pull-right"
                            id="dashviewallfavoritesreports"
                              onClick={this.customstoryboardlistviewclick}
                          >
                            {lang["visual.favourites.viewmore"]}
                          </button>
                        ) : null}
                    </>
                  </div>
                </div>
                {/* <div id="storybooksdiv">
                  <div id="section-3"></div>
                  <div id="mystorybooksgridsmaincontainer">
                    <header className="container-fluid">
                      <h2 className="datamain-title pull-left">
                        <i className="fa fa-book"></i>&nbsp; {lang["visual.favourites.mybooks"]}
                      </h2>
                      <div
                        className="btn-group pull-right marg-left-5 h2"
                        role="group"
                      >
                           {this.state.features.map((feature) => {
                                if (feature.moduleName == "data_hub") {
                                    return   <button
                                    className="btn btn-bird createstorybook tooltip-left"
                                    data-tooltip={lang["visual.favourites.createbook"]}
                                    id="createstorybook"
                                    type="button"
                                    title={lang["visual.favourites.createbook"]}
                                  > <a href={this.props.url + "/viewstorybook"}>
                                      <i className="fa fa-plus"></i></a>
                                  </button>
                                }
                            })}
                       
                        <button
                          className="btn btn-bird active storybooksgridview"
                          id="storybooksgridview"
                          type="button"
                          title={lang["visual.favourites.gridview"]}
                          onClick={this.storybookgridviewclick}
                        >
                          <i className="fa fa-th-large"></i>
                        </button>
                        <button
                          className="btn btn-bird storybookslistview"
                          id="storybookslistviewlist"
                          type="button"
                          title={lang["visual.favourites.listview"]}
                          onClick={this.storybooklistviewclick}
                        >
                          <i className="fa fa-th-list"></i>
                        </button>
                      </div>
                    </header>
                    <>
                    <div className="data-items-container">
                      {
                        this.state.isStoryBookListView?
                          <StorybookListView  url={this.props.url}/>
                          :
                          <React.Fragment>
                            <StoryBooks state={this.state} TargetThis={this} url={this.props.url} storybooks={this.state.storybooks} features={this.state.features} />
                            
                              </React.Fragment>
                      }
                      </div>
                      {this.state.storybooks.length !== undefined &&
                              this.state.storybooks.length > 10 ? (
                                <button
                                  toggle="View More..."
                                  className="btn btn-bird-group-transaprent viewallstorybooks  pull-right"
                                  id="dashviewallfavoritesreports" onClick={this.storybooklistviewclick}
                                >
                                  {lang["visual.favourites.viewmore"]}
                                </button>
                              ) : null}
                    </>
                  </div>
                </div> */}
            
                <div id="myreportsdiv">
                  <div id="section-3"></div>
                  <div id="myreportgridsmaincontainer">
                    <header className="container-fluid">
                      <h2 className="datamain-title pull-left">
                        <i className="fa fa-bar-chart"></i>&nbsp; {lang["visual.favourites.myreports"]}
                      </h2>
                      <div
                        className="btn-group pull-right marg-left-5 h2"
                        role="group"
                      >
                        <button
                          className="btn btn-bird active myreportgridview"
                          id="myreportgridviewgrid"
                          type="button"
                          title={lang["visual.favourites.gridview"]}
                          onClick={this.myreportsgridviewclick}
                        >
                          <i className="fa fa-th-large"></i>
                        </button>
                        <button
                          className="btn btn-bird myreportlistview"
                          id="myreportlistviewlist"
                          type="button"
                          title={lang["visual.favourites.listview"]}
                          onClick={this.myreportslistviewclick}
                        >
                          <i className="fa fa-th-list"></i>
                        </button>
                      </div>
                    </header>
                    <div className="data-items-container">
                      {this.state.isMyReportListView ?
                        <MyreportsListView url={this.props.url} foldersArray={this.state.foldersList}/>
                        :
                        <React.Fragment>
                          <MyReports state={this.state} TargetThis={this} url={this.props.url} myreports={this.state.reports} features={this.state.features} />
                          
                            </React.Fragment>
                      }
                      </div>
                      {this.state.reports.length !== undefined &&
                            this.state.reports.length > 10 ? (
                              <button
                                toggle="View More..."
                                className="btn btn-bird-group-transaprent viewallreports  pull-right"
                                id="dashviewallfavoritesreports" onClick={this.myreportslistviewclick}
                              >
                                {lang["visual.favourites.viewmore"]}
                              </button>
                            ) : null}
                  </div>
                </div>
                <div id="sharedreportsdiv">
                  <div id="section-4"></div>
                  <div id="sharedreportgridsmaincontainer">
                    <header className="container-fluid">
                      <h2 className="datamain-title pull-left">
                        <i className="fa fa-share"></i>&nbsp; {lang["visual.favourites.sharereports"]}
                      </h2>
                      <div
                        className="btn-group pull-right marg-left-5 h2"
                        role="group"
                      >
                        <button
                          className="btn btn-bird active sharedreportsgridview"
                          id="sharedreportsgridviewgrid"
                          type="button"
                          title={lang["visual.favourites.gridview"]}
                          onClick={this.sharedreportsgridviewclick}
                        >
                          {" "}
                          <i className="fa fa-th-large"></i>
                        </button>
                        <button
                          className="btn btn-bird sharedreportslistview"
                          id="sharedreportslistviewlist"
                          type="button"
                          title={lang["visual.favourites.listview"]}
                          onClick={this.sharedreportslistviewclick}
                        >
                          {" "}
                          <i className="fa fa-th-list"></i>
                        </button>
                      </div>
                    </header>
                    <div className="data-items-container">
                      {this.state.isSharedReportListView ?
                        <SharedReportListView  url={this.props.url} foldersArray={this.state.foldersList}/>
                        :
                        <React.Fragment>
                        <SharedReports state={this.state} TargetThis={this} sharedreports={this.state.sharedreports} features={this.state.features} url={this.props.url} />
                        
                          </React.Fragment>
                      }
                    </div>
                    {this.state.sharedreports.length !== undefined &&
                          this.state.sharedreports.length > 10 ? (
                            <button
                              toggle="View More..."
                              className="btn btn-bird-group-transaprent viewallsharereports  pull-right"
                              id="dashviewallfavoritesreports" onClick={this.sharedreportslistviewclick}
                            >
                              {lang["visual.favourites.viewmore"]}
                            </button>
                          ) : null}
                  </div>
                </div>
              </ul>
            </div>
            :
            <div className="boc-home-content smallthumbview folderviewcontainer">
              <FoldersTemplate state={this.state} TargetThis={this} folders={this.state.folderreports} features={this.state.features} url={this.props.url}/>
              </div>
             }
          </div>
          {this.state.displayFooter1 === false ? (
            <footer className="footer">
              <div className="container-fluid">
                {/* <!-- <p className="text-muted"><%=whitelableMap["footertext"]%></p> --> */}
                <div className="row">
                  <div className="col-xs-7">
                    {/* <!-- <p className="text-muted"><%=whitelableMap["footertext"]%></p> -->
						<!-- <div className="timezone hide pull-right">
								<span className="servertime"><b>Server Time:</b></span> <span className="servertimezone" style="margin-right:20px">Fri Sep 27 2019 10:37:00 GMT+0530 </span>
								<span className="localtime"><b>Client Time:</b></span> <span className="localtimezone"> Fri Sep 27 2019 10:37:00 GMT+0530 </span>
						</div> --> */}
                  </div>
                </div>
              </div>
            </footer>
          ) : null}
        </section>
      </div>
      </React.Fragment>
    );
  }
}
