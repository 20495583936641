import React from 'react';
import { lang } from "../js/helpers/utils/languages/language";
export default class NLPReportSearchTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        var that = this.props;
        return (
            <div className="is-container" style={{ display: 'none' }}>
                <div className="is-close" id="intelligentcloseicon"><i className="fa fa-times"></i></div>
                <div className="is-title">{lang["reports.intelligentsearch"]}</div>
                <div className="input-group is-search-group-container">
                    <div contentEditable="true" id="birdintelligentsearch" onClick={that.getnlpqueryresult} onKeyUp={that.queryTyping} onPaste={that.formatquery} className="resize-vertical custommeasurequerydisplay easy-autocomplete"
                        ></div>
                    <span className="input-group-addon is-get-results hide" id="clickclearbutton" onClick={that.clearQuery} title={lang["reports.clear"]}><i className="fa fa-times"></i></span>
                    <span className="input-group-addon is-get-results searchbutton" onClick={that.getnlpqueryresult} id="clicksearchbutton"><i className="fa fa-search"></i></span>
                </div>
                <div id="Intelligencyerror" style={{ color: 'red', marginLeft: '385px' }}></div>
                <div className="is-chart-message">
                    <span className="is-add">
                        <button className="btn btn-bird btn-sm pull-right addchart hide" id="addintelligentchart" onClick={that.addChart}><i className="fa fa-bar-chart"></i> {lang["reports.addchart"]}</button>
                    </span>
                </div>
                <div className="container-fluid">
                    <div className="row height-100">
                        <div className="col-xs-12 col-lg-2 is-fields-container">
                            <div className="is-title">{lang["reports.availfields"]}</div>
                            <ul className="list-group nlp-available-columns scrollset" ></ul>
                        </div>
                        <div className="col-xs-12">
                            <div className="is-chart-container">
                                <div id="nlpchart" className="width-100 maxanim10 intelligentsearchchart"></div>
                                <div className="drawbootstraptable">
                                    <div id="datatableresults" className="notifications-table-container  hidden">
                                        <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                                            <table id="datasearch"
                                                className="table drawbootstraptable"
                                                data-toggle="table" data-page-size="10"
                                                data-smart-display="true" data-search="true"
                                                data-pagination-v-align="bottom"></table>
                                        </div>
                                    </div>
                                    <div className="clear"></div>

                                    <div id="paginate" className="notifications-table-container col-xs-12 hidden">

                                        <div className="bootstrap-table">
                                            <div className="fixed-table-pagination">

                                                <div className="pull-left pagination-detail">
                                                    <span className="pagination-info">{lang["reports.showing"]} </span>
                                                    <span className="page-list">
                                                        <span className="btn-group dropup">
                                                            <button type="button" className="btn btn-default  dropdown-toggle auditrowlimtbutton" id="auditingtablerecords" data-toggle="dropdown">
                                                                <span className="page-size">10</span>
                                                                <span className="caret"></span>
                                                            </button>
                                                            <ul className="dropdown-menu btablerowlimit" role="menu" data-num="" tab-id="0" tab-name="">
                                                                <li className="btablerowlimit1" value="25"><a href="javascript:void(0)">25</a></li>
                                                                <li className="btablerowlimit1" value="50"><a href="javascript:void(0)">50</a></li>
                                                                <li className="btablerowlimit1" value="100"><a href="javascript:void(0)">100</a></li>
                                                            </ul>
                                                        </span> {lang["reports.recordspage"]}
					</span>
                                                </div>
                                                <div className="pull-right pagination">
                                                    <ul id="datatable-pagination-view" className="pagination-sm"></ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        )
    }
}