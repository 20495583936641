import $ from 'jquery';
export function resizeModalDialog() {
    $('.modal').each(function () {
        if ($(this).hasClass('in') === false) {
            $(this).show();
        }
        var contentHeight = $(window).height() - 60;
        if ($(window).width() <= 500) { contentHeight = contentHeight + 40 }
        var headerHeight = $(this).find('.modal-header').outerHeight() || 2;
        var footerHeight = $(this).find('.modal-footer').outerHeight() || 2;



        $(this).find('.modal-content').css({
            'max-height': function () {
                return contentHeight;
            }
        });

        $(this).find('.modal-body').css({
            'max-height': function () {
                return contentHeight - (headerHeight + footerHeight) - 50;

            }
        });

        if ($(this).find('.modal-height-fix').length === 1) {
            $(this).find('.modal-height-fix').css({ //this is to fix attributes modal inner content height
                'max-height': function () {
                    return contentHeight - (headerHeight + footerHeight) - 150;
                },
                'height': 'auto'
            });
        } else {
            $(this).find('.modal-height-fix').css({ //this is to fix attributes modal inner content height
                'max-height': function () {
                    return contentHeight - (headerHeight + footerHeight) - 150;
                },
                'height': '100vh'
            });
        }



        $(this).find('.modal-dialog').addClass('modal-dialog-center').css({
            'margin-top': function () {
                return -($(this).outerHeight() / 2);
            },
            'margin-left': function () {
                return -($(this).outerWidth() / 2);
            }
        });
        if ($(this).hasClass('in') === false) {
            $(this).hide();
        }
    });
}
export function resize() {
    if ($(window).height() >= 250) {
        $(window).resize(resizeModalDialog());
    }
}
