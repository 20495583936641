import React from "react";
import ReactDOM from "react-dom";
import _ from 'lodash';
import moment from 'moment';
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import { lang } from "../js/helpers/utils/languages/language";
import PubSub from 'pubsub-js';
import * as ModalProperties from "../js/helpers/utils/modalproperties";
import { getMessage } from "../js/helpers/utils/BirdMessage";
const $ = window.$;
export default class CompareDateRangeTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedChartStyleName:"Bar"
        };
        this.cancel = this.cancel.bind(this);
        this.changeChartType = this.changeChartType.bind(this);
        this.createRanges = this.createRanges.bind(this);
        this.deleteRange = this.deleteRange.bind(this);
        this.applyComparision = this.applyComparision.bind(this);
        this.RemoveCompareSeries = this.RemoveCompareSeries.bind(this);
        let chartType ;
        try{
        let chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(this.props.state_data.chartNumber + ""))
        chartType = chartDetails.chartType
        }catch(err){

        }
        let chartObj = {"bar":"Bar","stackedbar":"Bar","line":"Line","spline":"Spline","area":"Area","stackedarea":"Area"};
        this.state.selectedChartStyleName = chartType!=undefined?chartObj[chartType.toLowerCase()]!=undefined?chartObj[chartType.toLowerCase()]:"Bar":"Bar"
    }
    componentDidMount() {
        this.state.multifact = (this.props.multifact == 'true');
        this.state.multifactentities = (this.props.multifact == 'true') ? JSON.parse(this.props.multifactentities) : this.props.multifactentities;
        this.state.facttables = this.props.facttables
        var dateColumns = [];
        var that = this.props
        var thatvar = this.state;
        var finalDateColumns = [];
        var disable = false;
        var finalcolumnarray = [];
        let customFieldColumns = [];
        let customField =[];
        if(sessionStorage.customFields!=null&&sessionStorage.customFields!="null"&&sessionStorage.customFields!=""&&sessionStorage.customFields!=undefined){
           customField = JSON.parse(sessionStorage.customFields);
         }
         _.each(customField,function(val,index){
             if(val.dataType=="date"){
                let customFieldObj = {}
                customFieldObj["visible"] = "yes";
                customFieldObj["tableAliasName"] = "";
                customFieldObj["columnDisplayName"] = val.name;
                customFieldObj["tableDisplayName"] = "";
                customFieldObj["type"] = val.dataType;
                customFieldObj["tableName"] = "";
                customFieldObj["orginalColumnName"] = val.name;
                customFieldObj["tableDisplayType"] = val.dataType;
                customFieldObj["name"] = val.name;
                customFieldObj["tablename"] = "";
                customFieldObj["columnName"] = val.name;
                customFieldColumns.push(customFieldObj)
                }
            })
            if (thatvar.multifact) {
                _.each(this.props.dimensions, function (val, index) {
                    disable = ChartInfo.functions.getAllowedEntities(thatvar.multifactentities, val.columnName, 0, undefined, undefined, thatvar.facttables, "xdropdown");
                    if (!disable) {
                        finalcolumnarray.push(val);
                    }
                })
                _.each(customFieldColumns, function (val, index) {
                    var customFieldObject = $.grep(customField, function (e) { return e.name == val.columnName });
                    if (customFieldObject != undefined && customFieldObject.length > 0) {
                        _.each(customFieldObject[0].usedFields, function (hierarchy) {
                            disable = ChartInfo.functions.getAllowedEntities(thatvar.multifactentities,hierarchy, 1, undefined, undefined, thatvar.facttables, "xdropdown");
                            if (!disable) {
                                finalcolumnarray.push(val);
                            }
                        })
                    }
                })
            } else {
                finalcolumnarray = this.props.dimensions;
                finalcolumnarray = finalcolumnarray.concat(customFieldColumns);
            }
        _.each(finalcolumnarray, function (val) {
            if (val.tableDisplayType === "date") {
                dateColumns.push(val)
            }
        })
        var hasfoundxaxis = false;
        _.each(dateColumns, function (val, index) {
            var newObj = { attributes: {} }
            newObj.label = val.columnDisplayName;
            newObj.value = val.columnDisplayName;
            newObj.attributes = val;
            newObj["attributes"]["imageSrc"] = "<i className='fa fa-calendar'></i>";
            var selected = false;
            if (!_.isEmpty(that.xAxisData) && that.xAxisData.tableDisplayType == val.tableDisplayType && that.xAxisData.columnDisplayName == val.columnDisplayName) {
                selected = true;
                hasfoundxaxis = true;
            }
            if (dateColumns.length - 1 == index && !hasfoundxaxis) {
                selected = true;
            }
            newObj.selected = selected;
            finalDateColumns.push(newObj);
        });
        var dateHistogramArrayObj = [];
        var datehistogramlist = ["Years", "Quarters", "Months", "Weeks", "Days", "Reset"];
        _.each(datehistogramlist, function (val) {
            let datehistogramval = "";
            let datehistogramObj = {};
            switch (val) {
                case "Years": datehistogramval = "y"; break;
                case "Quarters": datehistogramval = "q"; break;
                case "Months": datehistogramval = "M"; break;
                case "Weeks": datehistogramval = "w"; break;
                case "Days": datehistogramval = "d"; break;
               /* case "Hours": datehistogramval = "h"; break;
                case "Minutes": datehistogramval = "m"; break;
                case "Seconds": datehistogramval = "s"; break;*/
                default: datehistogramval = "";
            }
            datehistogramObj["name"] = val;
            datehistogramObj["interval"] = datehistogramval;
            dateHistogramArrayObj.push(datehistogramObj);
        })
        var finalDateHistogram = [];
        _.each(dateHistogramArrayObj, function (val, index) {
            let newObj = { attributes: {} }
            newObj.label = val.name;
            newObj.value = val.name;
            newObj.attributes = val;
            newObj["attributes"]["imageSrc"] = "<i className='fa fa-calendar'></i>";
            var selectedval = false;
            if (index == 0) {
                selectedval = true;
            }
            newObj.selected = selectedval;
            finalDateHistogram.push(newObj);
        });
        
        var columns = [];
        $('#existing-date-histogram-list').multiselect({
            onChange: function (option, checked) {
                $(".existing-date-histogram-list ul.multiselect-container>li").removeClass('active');
                $(".existing-date-histogram-list ul.multiselect-container>li input[type='checkbox']").prop("checked", false);
                $(".existing-date-histogram-list ul.multiselect-container>li input[type='checkbox'][value='" + $(option).val() + "']").prop("checked", true);
                $(".existing-date-histogram-list ul.multiselect-container>li input[type='checkbox'][value='" + $(option).val() + "']").closest("li").addClass("active");
                $('#existing-date-histogram-list option:checked').prop("selected", false);
                $('.existing-date-histogram-list button.multiselect').click();
                $(option).prop("selected", true);
            }
        });
        $('#existing-date-columns-list').multiselect({
            onChange: function (option, checked) {
                $(".existing-date-columns-list ul.multiselect-container>li").removeClass('active');
                $(".existing-date-columns-list ul.multiselect-container>li input[type='checkbox']").prop("checked", false);
                $(".existing-date-columns-list ul.multiselect-container>li input[type='checkbox'][value='" + $(option).val() + "']").prop("checked", true);
                $(".existing-date-columns-list ul.multiselect-container>li input[type='checkbox'][value='" + $(option).val() + "']").closest("li").addClass("active");
                $('#existing-date-columns-list option:checked').prop("selected", false);
                $('.existing-date-columns-list button.multiselect').click();
                $(option).prop("selected", true);
            }
        });
        $('#existing-date-columns-list').multiselect('dataprovider', finalDateColumns);
        $('#existing-date-histogram-list').multiselect('dataprovider', finalDateHistogram);
        this.applylibraries("current-comparision-daterange", "current-date-range")
        $(".addcomparison").click();
        var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(that.state_data.chartNumber + ""))
        var finalYCompareObj = chartDetails["compareDateRangeObj"] != undefined && chartDetails["compareDateRangeObj"] != null && chartDetails["compareDateRangeObj"] != "null" ? chartDetails["compareDateRangeObj"] : {}
        if (!_.isEmpty(finalYCompareObj)) {
            var yjsObj = finalYCompareObj["0"];
            if (yjsObj != null && yjsObj.hasOwnProperty(that.state_data.yaxiscolumnName)) {
                var ycompareDetailsObj = yjsObj[that.state_data.yaxiscolumnName];
                var columnObj = {};
                var compareRange = JSON.parse(ycompareDetailsObj["compareDateRange"])
                var comparedRange = JSON.parse(ycompareDetailsObj["compareToDateRange"])
                var dateHistogramInterval = ycompareDetailsObj["compareDateHistogramInterval"];
                var columnObj = JSON.parse(ycompareDetailsObj["selectedCompareFieldObj"]);
                var selectedColumnName = columnObj["columnname"];
                
                _.each(finalDateHistogram, function (val) {
                    val.selected = false;
                    if (val.attributes.interval == dateHistogramInterval) {
                        val.selected = true;
                        $(".existing-date-histogram-list ul.multiselect-container>li").removeClass('active');
                        $(".existing-date-histogram-list ul.multiselect-container>li input[type='checkbox']").prop("checked", false);
                        $('#existing-date-histogram-list option:checked').prop("selected", false);
                        $('#existing-date-histogram-list option[data-interval="'+dateHistogramInterval+'"]').prop("selected", true);
                        $(".existing-date-histogram-list span.multiselect-selected-text").text(val.attributes.name)
                        $(".existing-date-histogram-list ul.multiselect-container>li input[type='checkbox'][value='" + val.attributes.name + "']").prop("checked", true);
                        $(".existing-date-histogram-list ul.multiselect-container>li input[type='checkbox'][value='" + val.attributes.name + "']").closest("li").addClass("active");
                
                    }
                })
                _.each(finalDateColumns, function (val) {
                    val.selected = false;
                    if (val.attributes.columnDisplayName == columnObj.columndisplayname) {
                        val.selected = true;
                        $(".existing-date-columns-list span.multiselect-selected-text").text(val.attributes.columnDisplayName)
                        $(".existing-date-columns-list ul.multiselect-container>li").removeClass('active');
                        $(".existing-date-columns-list ul.multiselect-container>li input[type='checkbox']").prop("checked", false);
                        $(".existing-date-columns-list ul.multiselect-container>li input[type='checkbox'][value='" +selectedColumnName + "']").prop("checked", true);
                        $(".existing-date-columns-list ul.multiselect-container>li input[type='checkbox'][value='" + selectedColumnName + "']").closest("li").addClass("active");
                        $('#existing-date-columns-list option:checked').prop("selected", false);
                        $('#existing-date-columns-list option[data-columnname="'+selectedColumnName+'"]').prop("selected", true);
                
                    }
                })
                let daterangeArr = ['Today','Yesterday','Last 7 Days','This Week','Last Week','Last 30 Days','This Month','Last Month','First Fiscal Quarter','Second Fiscal Quarter','Third Fiscal Quarter','Fourth Fiscal Quarter','This Year','Year To Date','Last Year' ,'Custom'];
                let dateRangeObj = {};
                _.each(daterangeArr,function(val){
                    dateRangeObj[val.toLowerCase()] = val;
                })
                _.each(compareRange,function(val){
                    var filterdata = JSON.parse(val.filterArrayObj)[0];
                    var charttype = val.ChartType
                    var seriscolor = val.seriescolor
                    var start = moment(filterdata.fromRange).format('MMMM D, YYYY');
                    var end = moment(filterdata.toRange).format('MMMM D, YYYY');
                    $('#current-comparision-daterange:nth-child(1n) span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
                    $('#current-comparision-daterange').data('daterangepicker').setStartDate(moment(start.format("YYYY/MM/DD")));
                    $('#current-comparision-daterange').data('daterangepicker').setEndDate(moment(end.format("YYYY/MM/DD")));    
                    let dateRangeVal = dateRangeObj[filterdata.daterange]!=undefined?dateRangeObj[filterdata.daterange]:"Custom";
                    $("#current-date-range span.itemtype").html(dateRangeVal);
                    $('#current-comparision-daterange').attr('date-range', filterdata.daterange);
                    $("#current-date-range .seriestype").text(charttype);
                    $(".current-compare-series-color").find('input').val(seriscolor);
                    $(".current-compare-series-color").find(".maxtheme3").css('background-color', seriscolor);
                });
                _.each(comparedRange,function(val,index){
                    var filterdata = JSON.parse(val.filterArrayObj)[0];
                    var charttype = val.ChartType
                    var seriscolor = val.seriescolor
                    if(index!=0){
                        $(".addcomparison").click();
                    }
                    var start = moment(filterdata.fromRange).format('MMMM D, YYYY');
                    var end = moment(filterdata.toRange).format('MMMM D, YYYY');
                    $('#current-comparable-daterange[data_num="' + (index+1) + '"]:nth-child(1n) span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
                    $('#current-comparable-daterange[data_num="' + (index+1) + '"]').data('daterangepicker').setStartDate(moment(start.format("YYYY/MM/DD")));
                    $('#current-comparable-daterange[data_num="' + (index+1) + '"]').data('daterangepicker').setEndDate(moment(end.format("YYYY/MM/DD")));
                    let dateRangeVal = dateRangeObj[filterdata.daterange]!=undefined?dateRangeObj[filterdata.daterange]:"Custom";
                    $("#compare-date-range .singleliitems[data_num=" + (index+1) + "] span.itemtype").html(dateRangeVal);
                    $("#current-comparable-daterange[data_num="+ (index+1) + "]").attr('date-range', filterdata.daterange);
                    $(".seriestype[data_num='" + (index+1) + "']").text(charttype);
                    $(".current-compare-series-color[data_num='" +(index+1) + "']").find('input').val(seriscolor);
                    $(".current-compare-series-color[data_num='" +(index+1) + "']").find(".maxtheme3").css('background-color', seriscolor);
                })
            }
        }
        $(".xaxisattributes .singleliitems").each(function(){
            $(".existing-date-histogram-list").removeClass("disabled");
            let datatype = $(this).attr('data-type');
            if(datatype!=undefined && datatype.toLowerCase()!="date"){
                $(".existing-date-histogram-list").addClass("disabled");
            }
        })
        ModalProperties.resize();
        $(window).on("resize",function(){
            ModalProperties.resize();	
        });
    }
    render() {
        var that = this.props;
        return (
            <React.Fragment>
                <div className="modal fade in" id="comparedatemodal" tabindex="-1" role="dialog" aria-labelledby="comparedatemodal" aria-hidden="false" style={{ display: 'block' }}>
                    <div className="modal-backdrop fade in"></div>
                    <div className="modal-dialog modal-lg modal-save-report modal-dialog-lg-center modal-dialog-center" style={{ zIndex: '1050' }}>
                        <div className="modal-content">
                            <div className="modal-header boc-modal-hf-padding">
                                <button type="button" className="close white" id="comparedatemodalclose" aria-hidden="true" data-dismiss="modal" onClick={this.cancel}>×</button>
                                <h4 className="modal-title"><i className="fa fa-calendar-o"></i>Compare <span className="compareyfield">{that.state_data.yaxiscolumnDisplayName + `[` + that.state_data.aggregation + `]`}</span></h4>
                            </div>
                            <div className="modal-body scrollset">
                                <div className="container-fluid">
                                    <div className="row filtercontent">
                                        <legend className="subhead">Date Column</legend>
                                        <div className="col-xs-12 existing-date-columns-list">
                                            <select id="existing-date-columns-list" multiple="multiple"></select>
                                        </div>
                                        <legend className="subhead">Date Histogram</legend>
                                        <div className="col-xs-12 existing-date-histogram-list">
                                            <select id="existing-date-histogram-list" multiple="multiple"></select>
                                        </div>
                                        <legend className="subhead">Current Date Range</legend>
                                        <div className="col-xs-12">
                                            <ul id="current-date-range" className="list-group">
                                                <li className="boc-list-item singleliitems">
                                                    <span className="itemcontainer">
                                                        <span className="itemtype">Last 30 Days</span>
                                                        <span className="daterange-center" id="current-comparision-daterange">
                                                            <i className="glyphicon glyphicon-calendar fa fa-calendar"></i>
                                                            <span className="currenttime">July 15, 2021 - August 13, 2021</span>
                                                            <b className="caret"></b>
                                                        </span>
                                                    </span>
                                                    <span className="itemoptions">
                                                    {/* <input type="text" style={{textAlign:'left!important'}}defaultValue={that.state_data.yaxiscolumnDisplayName}/> */}
                                                        <span className="seriestypes">
                                                            <span className="seriestype" aria-expanded="false" data-toggle="dropdown">{this.state.selectedChartStyleName}</span>
                                                            <ul role="menu" className="dropdown-menu">
                                                                <li className="combochart-li-item" title="" onClick={this.changeChartType} is-from="current-date-range">Bar</li>
                                                                <li className="combochart-li-item combinationchartright conbiantionchartaxisposintion" title="right" onClick={this.changeChartType} is-from="current-date-range">Line</li>
                                                                <li className="combochart-li-item combinationchartright conbiantionchartaxisposintion" title="right" onClick={this.changeChartType} is-from="current-date-range">Area</li>
                                                                <li className="combochart-li-item combinationchartright conbiantionchartaxisposintion" title="right" onClick={this.changeChartType} is-from="current-date-range">Spline</li>
                                                            </ul>
                                                            <span className="input-group current-compare-series-color">
                                                                <input type="text" className="form-control hidden" id="current-compare-series-color" defaultValue="" />
                                                                <span id="current-compare-series-color-i" className="input-group-addon"><i className="maxtheme3" style={{ backgroundColor: 'rgb(25,213,237)' }}></i></span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                        <legend className="subhead">Compare Date Range <button type="button" name="button" className="addcomparison" onClick={this.createRanges} data_num="0"><i className="fa fa-plus"></i></button></legend>
                                        <div className="col-xs-12">
                                            <ul id="compare-date-range" className="list-group">
                                                {/* <li className="boc-list-item singleliitems" data_num="0"> 
                                                    <span className="itemcontainer">
                                                        <span className="itemtype">Today</span>
                                                        <span className="daterange-center" id="current-comparable-daterange" data_num="0">
                                                            <i className="glyphicon glyphicon-calendar fa fa-calendar"></i>
                                                            <span className="currenttime">July 15, 2021 - August 13, 2021</span>
                                                            <b className="caret"></b>
                                                        </span>
                                                    </span>
                                                    <span className="itemoptions">
                                                        <span className="itemremove">
                                                            <button className="btn btn-transparent" type="button" title="Remove" onClick={this.deleteRange}><i className="fa fa-trash-o"></i></button>
                                                        </span>
                                                        <span className="seriestypes">
                                                            <span className="seriestype" aria-expanded="false" data-toggle="dropdown">Bar</span>
                                                            <ul role="menu" className="dropdown-menu">
                                                                <li className="combochart-li-item" title="" onClick={this.changeChartType} is-from="comapre-date-range" >Bar</li>
                                                                <li className="combochart-li-item combinationchartright conbiantionchartaxisposintion" title="right" onClick={this.changeChartType} is-from="compare-date-range" >Line</li>
                                                            </ul>
                                                            <span className="input-group current-compare-series-color">
                                                                <input type="text" className="form-control hidden" id="current-compare-series-color" />
                                                                <span id="current-compare-series-color-i" className="input-group-addon"><i style={{ backgroundColor: '#424242' }}></i></span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                 </li>*/}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer boc-modal-hf-padding">
                                <button type="button" className="btn btn-bird resetCompareSeries pull-left" data-num="" tab-id="0" tab-name="" aria-hidden="true" onClick={this.RemoveCompareSeries}><i class="fa fa-trash"></i>Remove Compare Series</button>
                                <button type="button" className="btn btn-bird" id="btn-birdsave" data-dismiss="modal" onClick={this.cancel}><i className="fa fa-times"></i> Cancel</button>
                                <button type="button" className="btn btn-bird applystoryboard" id="applystoryboards" onClick={this.applyComparision}><i className="fa fa-check"></i> Apply</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
    cancel = (e) => {
        ReactDOM.unmountComponentAtNode(document.getElementById(this.props.createdNodeElement))
    }
    changeChartType = (e) => {
        var $this = e.currentTarget;
        var chartText = $($this).text();
        var isfrom = $($this).attr('is-from')
        var data_num = $($this).attr('data_num')
        if (isfrom == "current-date-range") {
            $("#current-date-range .seriestype").text(chartText);
        } else {
            $("#compare-date-range .singleliitems[data_num='" + data_num + "'] .seriestype").text(chartText)
        }
    }
    createRanges = (e) => {
        var $this = e.currentTarget;
        var that = this;
        var num = parseInt($($this).attr('data_num')) + 1;
        let templateval = '<li class="boc-list-item singleliitems" data_num=' + num + '>' +
            '<span class="itemcontainer"><span class="itemtype">Last 30 Days</span>' +
            '<span class="daterange-center" id="current-comparable-daterange" data_num=' + num + '>' +
            '<i class="glyphicon glyphicon-calendar fa fa-calendar"></i>' +
            '<span class="currenttime">July 14, 2021 - August 13, 2021</span><b class="caret"></b>' +
            '</span></span><span class="itemoptions"><span class="itemremove">' +
            '<button class="btn btn-transparent delete-range" type="button" title="Remove">' +
            '<i class="fa fa-trash-o"></i></button></span><span class="seriestypes">' +
            '<span class="seriestype" data_num=' + num + ' aria-expanded="false" data-toggle="dropdown">'+ this.state.selectedChartStyleName+'</span>' +
            '<ul role="menu" class="dropdown-menu">' +
            '<li class="combochart-li-item change-chart-type"  data_num=' + num + ' title=""  is-from="comapre-date-range" >Bar</li>' +
            '<li  data_num=' + num + ' class="combochart-li-item combinationchartright conbiantionchartaxisposintion change-chart-type" title="right"  is-from="compare-date-range" >Line</li>' +
            '<li  data_num=' + num + ' class="combochart-li-item combinationchartright conbiantionchartaxisposintion change-chart-type" title="right"  is-from="compare-date-range" >Area</li>' +
            '<li  data_num=' + num + ' class="combochart-li-item combinationchartright conbiantionchartaxisposintion change-chart-type" title="right"  is-from="compare-date-range" >Spline</li>' +
            '</ul><span class="input-group current-compare-series-color" data_num=' + num + '><input type="text" class="form-control hidden" id="current-compare-series-color" value="" data_num=' + num + '/>' +
            '<span id="current-compare-series-color-i" class="input-group-addon">' +
            '<i class="maxtheme3" style="background-color:#19D5ED"></i></span></span></span></span></li>';
        $("#compare-date-range").append(templateval);
        $(".change-chart-type").click(
            function (e) {
                that.changeChartType(e)
            }
        )
        $(".delete-range").click(
            function (e) {
                that.deleteRange(e);
            }
        )
        $($this).attr('data_num', num);
        this.applylibraries("current-comparable-daterange[data_num='" + num + "']", "compare-date-range", num);
    }
    deleteRange = (e) => {
        var $this = e.currentTarget;
        $($this).closest('li').remove();
    }
    applylibraries(daterangeClassName, ulid, data_num) {
       var picker =  $("#" + daterangeClassName).daterangepicker({
            alwaysShowCalendars:true,
            format: 'MM/DD/YYYY',
            startDate: moment().subtract(29, 'days'),
            endDate: moment(),
            //  minDate: minDate,
            //maxDate: maxDate,
            showDropdowns: true,
            showWeekNumbers: true,
            timePicker: false,
            timePickerIncrement: 1,
            autoUpdateInput: true,
            timePicker12Hour: true,
            linkedCalendars: false,
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'This Week': [moment().startOf('week'), moment().endOf('week')],
                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                'First Fiscal Quarter': [moment().startOf('year'), moment().month(2).endOf('month')],
                'Second Fiscal Quarter': [moment().month(3).startOf('month'), moment().month(5).endOf('month')],
                'Third Fiscal Quarter': [moment().month(6).startOf('month'), moment().month(8).endOf('month')],
                'Fourth Fiscal Quarter': [moment().month(9).startOf('month'), moment().month(11).endOf('month')],
                'This Year': [moment().startOf('year'), moment().endOf('year')],
                'Year To Date': [moment().startOf('year'), moment()],
                'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]

            },
            opens: 'left',
            drops: 'down',
            buttonClasses: ['btn'],
            applyClass: 'btn-bird',
            cancelClass: 'btn-bird',
            separator: ' to ',
            locale: {
                applyLabel: lang["storyboard.submitbtn"],
                cancelLabel: lang["storyboard.cancelbtn"],
                fromLabel: 'From',
                toLabel: 'To',
                customRangeLabel: 'Custom',
                daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
                monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                firstDay: 1,
            }
        }, function (start, end, label) {
            console.log(start.toISOString(), end.toISOString(), label);
            $('#' + daterangeClassName + ':nth-child(1n) span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
            $('#' + daterangeClassName).attr('date-range', label.toLowerCase());
            if (data_num) {
                $("#" + ulid + " .singleliitems[data_num=" + data_num + "] span.itemtype").html(label);
            } else {
                $("#" + ulid + " span.itemtype").html(label);
            }
        }).data('daterangepicker');
        picker.container.addClass('daterangepicker_comparedaterange');
        $('.current-compare-series-color').colorpicker({ format: "rgba" }).on('changeColor.colorpicker', function (event) {
            var $this = event.currentTarget;
            $($this).parent().find(".maxtheme3").css('color', $this.value);
        });
        let startDateval = moment().subtract(29, 'days');
        let endDateval = moment();
        $('#' + daterangeClassName + ':nth-child(1n) span').html(startDateval.format('MMMM D, YYYY') + ' - ' + endDateval.format('MMMM D, YYYY'));
        $('#' + daterangeClassName).attr('date-range', 'last 30 days');
        if (data_num) {
            $('#' + daterangeClassName).attr('data_num', data_num);
        }
    }
    applyComparision = (e) => {
        var that = this;
        var finalYCompareObj = {};
        var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(that.props.state_data.chartNumber + ""))
        var yAxisArray = chartDetails["yAxis"]
        finalYCompareObj = chartDetails["compareDateRangeObj"] != undefined && chartDetails["compareDateRangeObj"] != null && chartDetails["compareDateRangeObj"] != "null" ? chartDetails["compareDateRangeObj"] : {}
        _.each(yAxisArray, function (val, index) {
            if (index==0) {
                var ycompareDetailsObj = {};
                var columnObj = {};
                ycompareDetailsObj["compareDateRange"] = JSON.stringify(that.getDateRangeObj("#current-comparision-daterange"));
              var finalArray = [];
               $("#compare-date-range .singleliitems").each(function () {
                    let data_num = $(this).attr('data_num')
                    let tempArray = that.getDateRangeObj("#current-comparable-daterange[data_num='"+data_num+"']");
                    finalArray.push(tempArray[0]);
               });
                // ycompareDetailsObj["compareToDateRange"] = JSON.stringify(that.getDateRangeObj("#current-comparable-daterange"));
                ycompareDetailsObj["compareToDateRange"] = JSON.stringify(finalArray);
                ycompareDetailsObj["compareDateHistogramInterval"] = $('#existing-date-histogram-list option:checked').attr('data-interval');
                ycompareDetailsObj["resetDateHistogramInterval"] =  $('#existing-date-histogram-list option:checked').attr('data-interval');
                var selectedColumn = $('#existing-date-columns-list option:checked');
                columnObj["dataType"] = $(selectedColumn).attr("data-tabledisplaytype");
                columnObj["tablename"] = $(selectedColumn).attr("data-tablename");
                columnObj["columndisplayname"] = $(selectedColumn).attr("data-columndisplayname");
                columnObj["columnname"] = $(selectedColumn).attr("data-columnname");
                ycompareDetailsObj["selectedCompareFieldObj"] = JSON.stringify(columnObj);
                var yjsObj = {}
                yjsObj[that.props.state_data.yaxiscolumnName] = ycompareDetailsObj;
                finalYCompareObj[index + ""] = yjsObj;
                let selectedvalue = $('#existing-date-histogram-list option:checked').attr('data-name').toLowerCase();
                let selectedInterval = $('#existing-date-histogram-list option:checked').attr('data-interval');
                if(selectedvalue=="reset"){
                    selectedInterval = selectedvalue;
                    ChartInfo.functions.setDateHistogramStatus(that.props.state_data.chartNumber, false);
                }else{
                    ChartInfo.functions.setDateHistogramStatus(that.props.state_data.chartNumber, true);
                }
				ChartInfo.functions.setDateHistogramXaxisData(chartDetails['xAxis']);
                ChartInfo.functions.setDateHistogramState(selectedInterval, that.props.state_data.chartNumber);
            }
        })
        if(yAxisArray.length>1){
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Information');
            $('.birdmessage-info').empty().text(getMessage("BirdInformation60"))
           
        }
        chartDetails["compareDateRangeObj"] = finalYCompareObj;
        let chartsettings = chartDetails["chartSettings"];
        if(chartsettings.hasOwnProperty("chartNarrative")){
            delete chartsettings["chartNarrative"] 
        }
        if(chartsettings.hasOwnProperty("chartPareto")){
            delete chartsettings["chartPareto"] 
        }
        chartDetails["chartSettings"] = chartsettings;
        ChartInfo.functions.updateChartDetails(that.props.state_data.chartNumber + "", JSON.stringify(chartDetails));
        ReactDOM.unmountComponentAtNode(document.getElementById(this.props.createdNodeElement))
        if(!$('.yaxisattributes .singleliitems').first().hasClass('has-compare-date')){
            $('.yaxisattributes .singleliitems').first().addClass('has-compare-date')
        }
        $("#chartNarrative").prop('checked', false);
        $("#chartNarrative").closest(".col-xs-12").addClass("hide");
        $("#chartPareto").prop('checked', false);
        $("#chartPareto").closest(".col-xs-12").addClass("hide");
       
        var applyObj = {};
        applyObj["pos"] = undefined;
        applyObj["currentchartnum"] = that.props.state_data.chartNumber;
        applyObj["applyattributes"] = "applyattributes";
        applyObj["dateHistogramInterval"] = undefined;
        applyObj["calculation"] = undefined;
        applyObj["isExcludeorIncludeFilters"] = undefined;
        applyObj["isChartSummaryData"] = undefined;
        applyObj["selecteddobycolumns"] = undefined;
        applyObj["filterArray"] = [];
        applyObj["navigatefrom"] = undefined;
        applyObj["isCustomparameterFilter"] = undefined;
        applyObj["tableInfo"] = undefined;
        PubSub.publish('applyfiltesandredrawchart', applyObj);
    }
    getDateRangeObj(DateClassName) {
        var filterArray = [];
        var dataNumFilterArray = [];
        $(DateClassName).each(function () {
            filterArray = [];
            var $this = this;
            var selectedColumn = $('#existing-date-columns-list option:checked');
            var filterObject = {};
            filterObject["dataType"] = $(selectedColumn).attr("data-tabledisplaytype");
            filterObject["tablename"] = $(selectedColumn).attr("data-tablename");
            filterObject["columndisplayname"] = $(selectedColumn).attr("data-columndisplayname");
            filterObject["filterDisplayName"] = $(selectedColumn).attr("data-columndisplayname");
            filterObject["filterIgnore"] = false;
            filterObject["columnname"] = $(selectedColumn).attr("data-columnname");
            filterObject["daterange"] = $($this).attr("date-range");
            var data_num = $($this).attr("data_num");
            var text = $($this).text();
            var dateArray = text.split("-");
            var fromdate = dateArray[0].trim();//Date.parse(dateArray[0].trim());
            var todate = dateArray[1].trim() //Date.parse(dateArray[1].trim());
            if (filterObject["daterange"] == "custom") {
                var fromdate = dateArray[0].trim() + " 00:00:00";
                var todate = dateArray[1].trim() + " 23:59:59";
            }
            filterObject["fromRange"] = fromdate;
            filterObject["toRange"] = todate;
            var columnName = $(selectedColumn).attr("data-columnname");
            var isCustomField = false;
            var customFieldIndex = 0;
            var customparamerfield = ChartInfo.functions.getCustomParameter();
            var customFieldsArray = sessionStorage.getItem("customFields");
            var temp1 = JSON.parse(customFieldsArray);
            var res;
            _.each(temp1, function (customfields) {
                _.each(customfields.parametrFieldsArray, function (value) {
                    res = $.grep(customparamerfield, function (e) {
                        return e.parametername == value;
                    })
                    var formula = customfields.formula;
                    var scriptcondition = customfields.scriptcondition;
                    var fields = customfields.fields;
                    var sqlformula = customfields.sqlformula;
                    if (res.length != 0) {
                        customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
                        customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
                        customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
                        customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
                    } else {
                        _.each(customparamerfield, function (values) {
                            if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
                                customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
                                customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                            }
                        });
                    }
                });
            });
            customFieldsArray = JSON.stringify(temp1);
            var customFieldsParsedArray = [];
            if (customFieldsArray != "null" && customFieldsArray != undefined) {
                customFieldsParsedArray = JSON.parse(customFieldsArray);
            }
            $.each(customFieldsParsedArray, function (index, customField) {
                if (customField.name == columnName) {
                    isCustomField = true;
                    customFieldIndex = index;
                }
            });
            if (isCustomField == true) {
                var customFieldObject = customFieldsParsedArray[customFieldIndex];
                var finalScriptCondition = customFieldObject.scriptcondition;
                finalScriptCondition = ";" + "if( new Date(" + finalScriptCondition + ") >= new Date(" + fromdate + ")" + " &&  new Date(" + finalScriptCondition + ") <= new Date(" + todate + ")) return true else false";
                customFieldObject["scriptcondition"] = finalScriptCondition;
                customFieldObject["parameter"] = null;
                var sqlFilterObject = {};
                sqlFilterObject["fromRange"] = fromdate;
                sqlFilterObject["toRange"] = todate;
                customFieldObject["sqlcondition"] = sqlFilterObject;
                filterObject["customfield"] = customFieldObject;
                filterArray.push(filterObject);
            } else {
                filterArray.push(filterObject);
            }
            if (data_num) {
                var dataNumFilterObj = {};
                dataNumFilterObj["filterArrayObj"] = JSON.stringify(filterArray);
                dataNumFilterObj["ChartType"] = $(".seriestype[data_num='" + data_num + "']").text();
                let seriecolorval = $(".current-compare-series-color[data_num='" + data_num + "']").find('input').val();
                dataNumFilterObj["seriescolor"] = seriecolorval != undefined && seriecolorval != '' ? seriecolorval : "rgb(25,213,237)"
                dataNumFilterObj["data_num"] = data_num;
                dataNumFilterArray.push(dataNumFilterObj)
            } else {
                var dataNumFilterObj = {};
                dataNumFilterObj["filterArrayObj"] = JSON.stringify(filterArray);
                dataNumFilterObj["ChartType"] = $("#current-date-range .seriestype").text();
                let seriecolorval = $(".current-compare-series-color").find('input').val();
                dataNumFilterObj["seriescolor"] = seriecolorval != undefined && seriecolorval != '' ? seriecolorval : "rgb(25,213,237)"
                dataNumFilterArray.push(dataNumFilterObj);
            }
        });
        return dataNumFilterArray;
    }
    RemoveCompareSeries = (e) => {
        var that = this;
        var index = 0;
        var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(that.props.state_data.chartNumber + ""))
        var finalYCompareObj = chartDetails["compareDateRangeObj"] != undefined && chartDetails["compareDateRangeObj"] != null && chartDetails["compareDateRangeObj"] != "null" ? chartDetails["compareDateRangeObj"] : {}
        finalYCompareObj[index + ""] = null;
        chartDetails["compareDateRangeObj"] = finalYCompareObj;
        ChartInfo.functions.updateChartDetails(that.props.state_data.chartNumber + "", JSON.stringify(chartDetails));
        ReactDOM.unmountComponentAtNode(document.getElementById(this.props.createdNodeElement))
        var applyObj = {};
        applyObj["pos"] = undefined;
        applyObj["currentchartnum"] = that.props.state_data.chartNumber;
        applyObj["applyattributes"] = "applyattributes";
        applyObj["dateHistogramInterval"] = undefined;
        applyObj["calculation"] = undefined;
        applyObj["isExcludeorIncludeFilters"] = undefined;
        applyObj["isChartSummaryData"] = undefined;
        applyObj["selecteddobycolumns"] = undefined;
        applyObj["filterArray"] = [];
        applyObj["navigatefrom"] = undefined;
        applyObj["isCustomparameterFilter"] = undefined;
        applyObj["tableInfo"] = undefined;
        PubSub.publish('applyfiltesandredrawchart', applyObj);
        if($('.yaxisattributes .singleliitems').first().hasClass('has-compare-date')){
            $('.yaxisattributes .singleliitems').first().removeClass('has-compare-date')
        }
        $("#chartNarrative").closest(".col-xs-12").removeClass("hide");
        $("#chartPareto").closest(".col-xs-12").removeClass("hide");
    }
}