import React from "react";
import { lang } from "../js/helpers/utils/languages/language";
export default class LegendDropDownTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        var that = this.props;
        return (
            // <!-- legenddropdownview.js -->
            <li key={that.dynamicdiv} className={`xs-list-item legenddropdownitem ${that.disable ? " hide" : ""}`} onClick={that.addlegendsvalue} data-name={that.columnName} table-name={that.tableName} iscustomfunction={that.customfunction} data-type={that.dataType} title={that.columnDisplayName}>
                <span className="itemtype">
                    {(that.customfunction === 1 && that.customfunction != undefined) ? (
                        <span className="function">{lang["storyboard.fx"]}</span>
                    ) : (that.customfunction === 3) ? (
                        <i className="fa fa-arrows-h"></i>
                    ) :
                            (that.dataType === 'number') ? (
                                <>123</>
                            ) : (that.dataType === 'date' || that.dataType === 'datetime' || that.dataType === 'month' || that.dataType === 'day') ? (
                                <i className="fa fa-calendar"></i>
                            ) : (that.dataType == 'customhierarchy') ? (
                                <i className="fa fa-list-ol"></i>
                            ) : (
                                            <>{lang["storyboard.abc"]}</>
                                        )
                    }
                </span>
                <span className="itemname">{that.columnDisplayName}</span>
            </li>
        );
    }
}