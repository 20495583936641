import React from "react";
import ReactDOM from "react-dom";
import SendRequest from '../../SendRequest';
import { lang } from "../../js/helpers/utils/languages/language";
import SmartInsightSpinner from '../../images/birdimages/smartinsightspinner.png';
import _ from 'underscore';
import ViewSettingModal from './viewreportsettingview';
import * as ModalProperties from '../../js/helpers/utils/modalproperties';
import * as collection from "../../js/helpers/utils/SavedReportCollection";
import AutovizSettingsView from "./autovizsettingsview";
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
const $ = window.$;
export default class AutovizReportView extends React.Component {
    constructor(props) {
        super(props);
        var modelname = sessionStorage.getItem("report_navigated_datamodelname");
        this.state = {
            modelresponse: "",
            targetcolumn: "",
            features: [],
            displayFooter: true,
            title: modelname != undefined && modelname != null ? modelname + " - " + "Autoviz Storyboard" : "Autoviz Storyboard",
            excludecolumn: ""
        };
    }
    componentDidMount() {
        var that = this;
        var path = '<li><a class="navDashboard" href="#visualization"><i class="fa fa-bar-chart fa-solid"></i>' + lang["smartsights.visualization"] + '</a></li><li><a href="javascript:void(0)"><i class="fa fa-bar-chart"></i> ' + lang["smartsights.newboard"] + '</a></li>'
        $(".navbar.navbar-bird ul.nav-path").html(path);
        $('.mlloadingbtn').removeClass("hide");
        that.state.datamodelId = atob(this.props.match.params.datamodelId)
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getfeatures",
            queryString: "",
            sucessFunction: props => {
                that.setState({ features: props });
                $(".settingmodal").click(function () {
                    that.showaddsettingmodal();
                })
            },
            rejectFunction: () => { },
            headers: requestHeaders
        })
        SendRequest({
            url: "getdatamodelrelationinfo",
            queryString: "?reportId= &datamodelId=" + this.state.datamodelId,
            sucessFunction: props => {
                that.state.multifact = props.multifact;
                that.state.multifactentities = props.entities;
                that.state.facttables = props.facttables;
                var shredmodel = false;
                that.state.isFromSharedModel = sessionStorage.getItem("isSharedModel" + that.state.datamodelId);
                if (that.state.isFromSharedModel == "true") {
                    shredmodel = "shredmodel";
                }
                SendRequest({
                    url: "getcolumns",
                    queryString: "?datamodelId=" + that.state.datamodelId + " &multifact=" + that.state.multifact + "&isfrom=" + shredmodel,
                    sucessFunction: cols => {
                        var that = this;
                        that.state.reportcolumns = cols;
                        that.state.dimensions = cols;
                        collection.createDataTypeBucketsForTemp(that.state);
                        that.state.collection = collection;
                        var datamodelId = atob(this.props.match.params.datamodelId)
                        var formdata = new FormData();
                        formdata.set('selectedModel', "autoviz");
                        formdata.set('reportId', "");
                        formdata.set('datamodelId', datamodelId);
                        formdata.set('parametersObj', JSON.stringify({}));
                        formdata.set('yValue', JSON.stringify([]));
                        formdata.set('xAxisArray', JSON.stringify([]));
                        formdata.set('customFields', "null");
                        formdata.set('filterObject', JSON.stringify([]));
                        formdata.set('selectedColumns', JSON.stringify(that.state.reportcolumns));
                        formdata.set('textColumnsArray', JSON.stringify([]));
                        formdata.set('dateColumnsArray', JSON.stringify([]));
                        formdata.set('isRunModel', true);
                        SendRequest({
                            url: "getAutovizData",
                            body: formdata,
                            sucessFunction: responseData => {
                                $(".spinner").hide();
                                $('.mlloadingbtn').addClass("hide");
                                if (responseData.hasOwnProperty("error")) {
                                    $(".spinner").hide();
                                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                                    $('.birdmessagebuttons').removeClass('show');
                                    $('.birdmessagebuttons').addClass('hide');
                                    $('.birdmessage-center').addClass("message-top-in");
                                    $('.details').removeClass('hide');
                                    $('.birdmessage h2').empty().append('Error : ');
                                    $('.birdmessage h2').append('<span class="errorcode">');
                                    $('.birdmessage .errorcode').empty().append(responseData.errorCode);
                                    $('.birdmessage-info').empty().text(responseData.error);
                                    $('.details').addClass('show').text("Details");
                                    $('.messagedetails xmp').empty().append(responseData.errorTrace);
                                    return;
                                }
                                $("#autoviz-container").html(responseData["modelresponse"]);
                                $("#autoviz-container").append('<style type="text/css">*, *::before, *::after{-webkit-box-sizing: border-box !important;-moz-box-sizing: border-box !important;box-sizing: border-box !important;}p{margin:0 0 10px!important}</style>');
                                that.state.targetcolumn = responseData["targetColumn"]
                            },
                            rejectFunction: () => { },
                            headers: {
                                method: "post",
                                headers: new Headers({
                                    "Content-Type": "application/json"
                                })
                            }
                        });
                    },
                    rejectFunction: () => { },
                    headers: requestHeaders
                });
            },
            rejectFunction: () => { },
            headers: requestHeaders
        });

    }
    showaddsettingmodal = (e) => {
        var today = new Date();
        var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
        $("#reportheader").find('.settingmodalheaderdisplay').html("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
        var settingModalView = new ViewSettingModal({
            dynamicdiv: dynamicdiv,
            viewreportCollection: this.props.collection,
            reportId: this.state.reportId,
            name: this.state.name,
            folder: this.state.folder,
            desc: this.state.desc,
            title: this.title,
        });
        ModalProperties.resize();
    }
    render() {
        var that = this;
        that.title = that.state.title;
        that.isSharedReport = false;
        that.isScheduleReport = false;
        var titlemode = "";
        _.each(that.state.features, function (featuresModule) {
            if (featuresModule.moduleName == 'story_board') {
                if (that.isSharedReport == false) {
                    if (that.isScheduleReport === true) {
                    } else {
                        _.each(featuresModule.features, function (feature) {
                            if (feature.featureName == 'viewreport_edit_title' && feature.allow == true) {
                                titlemode = "settingmodal titleeditmode";
                            }
                        })
                    }
                } else {
                    _.each(featuresModule.features, function (feature) {
                        if (feature.featureName == 'viewreport_save_shared_report' && feature.allow == true) {
                            _.each(featuresModule.features, function (feature) {
                                if (feature.featureName == 'viewreport_edit_title' && feature.allow == true) {
                                    titlemode = "settingmodal titleeditmode";
                                }
                            })
                        }
                    })
                }
            }
        })
        return (
            <section className="boc-viewreport-section mlreport" >
                <div className="boc-home boc-dark-background">
                    <div id="reportheader">
                        <div className="container-fluid">
                            <div className="row controlbar-main">
                                <div className="col-lg-7 col-xs-7 flex-baseline-div">
                                    {/* <!-- Edit Title --> */}
                                    <h2 className={`boc-viewreport-title ${titlemode}`} id="viewreport" data-num="storyboard" tab-id="0" tab-name="" title={that.title}>{that.title}</h2>
                                    {/* <!--\Edit Title  --> */}
                                    <div className="datacount storyboarddatacount hide">
                                        <span className="publishinfo"></span><span className="filterdatacount"></span>
                                        <span className="lastexecutedtimestamp"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="settingmodalheaderdisplay"></div>
                        </div>
                    </div>
                    <div className="container-fluid fill-height">
                        {/*<div className="row"><div className="col-xs-12 quickfiltercontainer"></div></div>*/}
                        <div className="row fill-height">
                            <div id="autoviz-container"></div>
                            {/* <!-- Attributes --> */}
                            <div className="rightsmartattributes">
                                <div className="smartinsightoptions" onClick={that.smartinsightoptions}><i className="fa fa-smartinsights-small"></i></div>
                                <div className="rightsmartattributes_container"></div>
                            </div>
                        </div>
                    </div>
                    {this.state.displayFooter != false ? <React.Fragment>
                        <footer className="footer">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-xs-7">

                                        <span>
                                            <button className="mlloadingbtn hide btn btn-bird ">
                                                <i className="fa fa-spinner fa-pulse" style={{ color: "#009688" }}></i> {lang["mlreports.modelrun"]}
                                            </button>
                                        </span>
                                    </div>

                                    <div className="col-xs-5">
                                        <div className="itsaudit hide">
                                            <span className="livecirclecontainer dataauditlivecontainer hide">
                                                <span className="livecircle"></span> {lang["mlreports.dataudit"]}
                                            </span>
                                            <span className="livecirclecontainer metadataauditlivecontainer hide">
                                                <span className="livecircle"></span>  {lang["mlreports.metadataaudit"]}
                                            </span>
                                        </div>
                                        <div className="itslive hide"><span className="liveupdatetime"></span><span className="livecirclecontainer"><span className="livecircle"></span>   {lang["mlreports.live"]}</span></div>
                                    </div>
                                </div>
                            </div>
                        </footer></React.Fragment>
                        : <React.Fragment></React.Fragment>
                    }
                </div>
            </section>
        )
    }
    smartinsightoptions = (e) => {
        e.preventDefault();
        var that = this;
        var isOpen = $(".rightsmartattributes").hasClass("open");
        var targetcolumn = that.state.targetcolumn ? that.state.targetcolumn : "";
        var isPublished='false';
        if (isOpen) {
            $(".rightsmartattributes").removeClass("open");
        } else {
            let requestHeaders = {
                method: "get",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            SendRequest({
                url: "gettotaldatacount",
                queryString: "?entities= &reportId= &multifact=false&datamodelId=" + that.state.datamodelId + "&isImportedReport=" + false + "&isPurgedReport=" + false+"&isPublished="+"false",
                sucessFunction: response => {
                    if (response.hasOwnProperty('error')) {
                        $(".spinner").hide();
                        $(".gridspinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.errorTrace)
                    }
                    that.state.datacount = response.size;
                    $(".publishinfo").text("Dataset Count: " + response.size);
                    $(".smartinsightintro-container").removeClass("hide");
                    var today = new Date();
                    var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                    dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                    $(".rightsmartattributes_container").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                    new AutovizSettingsView({
                        smartinsightoptions: that.smartinsightoptions,
                        dynamicdiv: dynamicdiv,
                        measures: that.state.dimensions,
                        dimensions: that.state.dimensions,
                        reportId: that.props.reportId,
                        datamodelId: that.state.datamodelId,
                        targetColumn: targetcolumn,
                        totalRecords: parseInt(that.state.datacount) > 100000 ? "100000" : that.state.datacount,
                        totalDBRecords: that.state.datacount,
                        schemaType: "",
                        dataSource: sessionStorage.getItem("report_navigated_datamodelname"),
                        tabid: 0,
                        runAutoViz: that.runAutoViz
                    });
                    $(".rightsmartattributes_container").html(document.getElementById(dynamicdiv));
                    $(".rightsmartattributes").addClass("open");
                    $(".smartinsightintro-container").addClass("hide");
                },
                rejectFunction: () => { },
                headers: requestHeaders
            });
        }
    }
    runAutoViz = () => {
        $(".spinner").show();
        $("#autoviz-container").empty();
        $('.mlloadingbtn').removeClass("hide");
        var that = this;
        var yAxisArray = [];
        _.each($(".smartyaxisattributes .singleliitems"), function (listObj) {
            var columnName = $(listObj).attr("data-name");
            var yAxis = {};
            var columnName = $(listObj).attr("data-name");
            var columnDisplayName = $(listObj).attr("title");
            var columnDataType = $(listObj).attr("data-type");
            var aggregation = $(listObj).attr("data-agg");
            var tableName = $(listObj).attr("table-name");
            yAxis["columnName"] = columnName;
            yAxis["displayName"] = columnDisplayName;
            yAxis["dataType"] = columnDataType;
            yAxis["aggregation"] = aggregation;
            yAxis["tableName"] = tableName;
            yAxisArray.push(yAxis);
        });
        var xAxisArray = [];
        _.each($(".smartxaxisattributes .singleliitems"), function (listObj) {
            var columnName = $(listObj).attr("data-name");
            var yAxis = {};
            var columnName = $(listObj).attr("data-name");
            var columnDisplayName = $(listObj).attr("title");
            var columnDataType = $(listObj).attr("data-type");
            var aggregation = $(listObj).attr("data-agg");
            var tableName = $(listObj).attr("table-name");
            yAxis["columnName"] = columnName;
            yAxis["displayName"] = columnDisplayName;
            yAxis["dataType"] = columnDataType;
            yAxis["aggregation"] = aggregation;
            yAxis["tableName"] = tableName;
            xAxisArray.push(yAxis);
        });
        var formdata = new FormData();
        formdata.set('selectedModel', "autoviz");
        formdata.set('reportId', "");
        formdata.set('datamodelId', that.state.datamodelId);
        formdata.set('parametersObj', JSON.stringify({}));
        formdata.set('yValue', JSON.stringify(yAxisArray));
        formdata.set('xAxisArray', JSON.stringify(xAxisArray));
        formdata.set('customFields', "null");
        formdata.set('filterObject', JSON.stringify([]));
        formdata.set('selectedColumns', JSON.stringify(that.state.reportcolumns));
        formdata.set('textColumnsArray', JSON.stringify([]));
        formdata.set('dateColumnsArray', JSON.stringify([]));
        formdata.set('isRunModel', true);
        SendRequest({
            url: "getAutovizData",
            body: formdata,
            sucessFunction: responseData => {
                $(".spinner").hide();
                $('.mlloadingbtn').addClass("hide");
                if (responseData.hasOwnProperty("error")) {
                    $(".spinner").hide();
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('hide');
                    $('.birdmessage h2').empty().append('Error : ');
                    $('.birdmessage h2').append('<span class="errorcode">');
                    $('.birdmessage .errorcode').empty().append(responseData.errorCode);
                    $('.birdmessage-info').empty().text(responseData.error);
                    $('.details').addClass('show').text("Details");
                    $('.messagedetails xmp').empty().append(responseData.errorTrace);
                    return;
                }
                $("#autoviz-container").html(responseData["modelresponse"]);
                $("#autoviz-container").append('<style type="text/css">*, *::before, *::after{-webkit-box-sizing: border-box !important;-moz-box-sizing: border-box !important;box-sizing: border-box !important;}p{margin:0 0 10px!important}</style>');
                that.state.targetcolumn = responseData["targetColumn"]
                that.state.excludecolumn = responseData["excludeColumn"];
                ChartInfo.functions.setSmartInsightsExcludeValues(that.state.excludecolumn)
            },
            rejectFunction: () => { },
            headers: {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            }
        });
    }
}