import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'underscore';
import SendRequest from '../SendRequest';
import { getMessage } from '../js/helpers/utils/BirdMessage';
import DatamodelItemSettingsHtml from './DatamodelItemSettingsHtml.jsx';
import DatamodelModifyEntitiesHtml from './DatamodelModifyentitiesHtml.jsx';
import DatamodelCustomquery from './DatamodelCustomquery';
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;

export default class DatamodelItemSettings extends React.Component {
    constructor(props) {
        super(props);
        this.entityName = this.props.entityName;
        this.workspaceentityid = this.props.workspaceentityid;
        this.entitycollection = this.props.entitycollection;
        this.dataModelMetaDataCollection = this.props.dataModelMetaDataCollection;
        this.type = this.props.type;
        this.workspaceCustomQueryCollection = this.props.workspaceCustomQueryCollection;
        this.isCustomQuery = false;
        this.isValidation = false;
        if (this.props.isCustomQuery != undefined) {
            this.isCustomQuery = this.props.isCustomQuery;
        }
    }
    componentDidMount() {
        var that = this;
        var columns;
        if (this.entitycollection.getEntityColumns != undefined) {
            if (this.isCustomQuery) {
                columns = this.entitycollection.getEntityColumns(this.entityName, this.workspaceentityid);
                if (columns != undefined && typeof (columns) == "object") {
                    columns = JSON.stringify(columns);
                }
                if (columns != undefined) {
                    $('#datamodelsymanticnamestab').parent().removeClass("disabled");
                }
            } else {
                if (that.dataModelMetaDataCollection != undefined && (that.dataModelMetaDataCollection.getMetadataEntities().length > 0 || that.dataModelMetaDataCollection.getMetadataEntities() != undefined)) {
                    var entitiesArray = that.dataModelMetaDataCollection.getMetadataEntities();
                    var isFound = false;
                    _.each(entitiesArray, function (i, j) {
                        if (i.workspaceentityid == that.workspaceentityid && i.entityname == that.entityName) {
                            columns = i.columns;
                            if(i.columns!=null &&  i.columns!='' &&  i.columns!=undefined && typeof (i.columns)==='string' ){
                                let col1 = JSON.parse(i.columns);
                                let col2 = [];
                                _.each(col1,function(val,index){
                                    if(val.isCustomField!=null && val.isCustomField!=undefined && val.isCustomField=='yes'){
                                       
                                    }else{
                                        col2.push(val)
                                    }
                                });
                                columns = JSON.stringify(col2);
                            }
                            isFound = true;
                        }
                    });
                    if (!isFound) {
                        columns = this.entitycollection.getEntityColumns(this.entityName, this.workspaceentityid);
                    }
                } else {
                    columns = this.entitycollection.getEntityColumns(this.entityName, this.workspaceentityid);
                }
            }
        }
        var reportdata = [];
        if (columns != undefined) {
            reportdata = JSON.parse(columns);
        }
        reportdata.sort(function(a, b){
            var x = a.columnDisplayName.toLowerCase();
            var y = b.columnDisplayName.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0 ;
         });
        //Append Modify Entities
        ReactDOM.render(
            <DatamodelModifyEntitiesHtml
                reportdata={reportdata}
                dataTypeClass=""
                schemaType="mysql"
                entityName={this.entityName}
                isCustomQuery={this.isCustomQuery}
                isStoredProcedure={false}
                importtempateflag={false}
                workspaceentityid={this.workspaceentityid}
                type={this.type}
                validateColName={this.validateColName}
            />,
            document.querySelector('.datamodelsymanticnamestablecontainer')
        );
        setTimeout(function () {
      //  if(that.props.type!="existing"){
            $(".modelitemvisible").removeAttr("disabled");
            $('.datamodelsymanticnamestablecontainer .tabledisplayname').removeClass('disabled');
       // }
    },500)
    }
    render() {
        var entitySourceName = "";
        return (
            <DatamodelItemSettingsHtml
                isCustomQuery={this.isCustomQuery}
                workspaceentityid={this.workspaceentityid}
                entityName={this.entityName}
                entitySourceName={entitySourceName}
                closesettingscontainer={this.closesettingscontainer}
                loadCustomQuery={this.loadCustomQuery}
                updateSymanticNames={this.updateSymanticNames}
            />
        );
    }
    closesettingscontainer = (e) => {
        e.preventDefault();
        $("#datamodelsentitycontainer>li").removeClass("active");
        $("#settingscontainer").removeClass("showsettings");
        $("#datamodelsentitycontainer").removeClass('settingsopened');
        $("a[href='#next']").parent().removeClass("disabled");
        setTimeout(function () { $("#settingscontainer").remove(); }, 1000);
    }
    loadCustomQuery = (e) => {
        var that = this;
        var query = "";
        if (this.entityName != undefined && this.entityName != "") {
            query = this.workspaceCustomQueryCollection.getwsCustomQueryQueries(this.entityName, that.workspaceentityid);
        }
        ReactDOM.render(
            <DatamodelCustomquery
                entityName={that.entityName}
                query={query}
                workspaceentityid={that.workspaceentityid}
                type={that.type}
                validatedatamodelcustomquery={this.props.validatedatamodelcustomquery}
                savedatamodelcustomquery={this.props.savedatamodelcustomquery}
            />,
            document.getElementById('customqueryaccordion')
        );
    }
    updateSymanticNames = (i) => {
        var that = this;
        var columns = [];
        var success = true;
        var datamodelcolumns = []
        var isvisible = false
        _.each($('.datamodelsymanticnamestablecontainer tbody tr'), function (html, index) {
            var colName = $(html).find('.tabledisplayname').val().toLowerCase().trim();
            datamodelcolumns.push(colName);
        });
        var status = false;
        var reservedwordcolumn = "";
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getreservedwords",
            queryString: "",
            sucessFunction: response => {
                if (!response.hasOwnProperty('error')) {
                    var reservedwordslist = response.rslist;
                    _.each(reservedwordslist, function (reservedword, j) {
                        if (datamodelcolumns.includes(reservedword)) {
                            status = true
                            reservedwordcolumn = reservedword
                        }
                    });
                }
                if (status == true) {
                    $(".spinner").hide();
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('show');
                    $('.details').addClass('hide');
                    $('.birdmessage h2').empty().append('Warning');
                    setTimeout(function () {
                        $("#datamodelsstepscontainer").steps("previous");
                        $('.spinner').hide();
                    }, 800);
                    $('.birdmessage-info').empty().text("The Column Name '" + reservedwordcolumn + "'" + "" + getMessage('BirdWarning149'));
                    $(".spinner").hide();
                    return;
                }
            },
            rejectFunction: () => { },
            headers: requestHeaders
        });

        _.each($('.datamodelsymanticnamestablecontainer tbody tr'), function (html, index) {
            var temp = {};
            var dateTypeFormatMap = {};
            var dateFormat = "";
            temp["id"] = index;
            temp["columnName"] = $(html).find('.tabledisplayname').val().trim();
            temp["tableAliasName"] = $(html).find('input[type=text]').attr("tablealiasname");
            temp["orginalColumnName"] = $(html).find('input[type=text]').attr("orginalcolumnname").trim();
            temp["columnDisplayName"] = $(html).find('.tabledisplayname').val().trim();
            temp["tableDisplayType"] = $(html).find('.datecontroller option:selected').val();

            if (temp["tableDisplayType"] == "date" || temp["tableDisplayType"] == "datetime") {
                if ($(html).find('.csvdateformatsdroppdown select option:selected').val() == "others") {
                    dateFormat = $(html).find(".csvcustomdatefield").val().trim();
                    if ($(html).find(".csvcustomdatefield").val().length == 0) {
                        $(html).find(".csvcustomdatefield").removeClass("hide");
                        success = false;
                    }
                } else {
                    dateFormat = $(html).find('.csvdateformatsdroppdown.' + temp["tableDisplayType"] + ' select option:selected').text().trim();
                }
            }
            temp["dateFormat"] = dateFormat;
            temp["tableDisplayName"] = $(html).find('input[type=text]').attr("tablename");
            temp["tableName"] = $(html).find('input[type=text]').attr("tablename");
            temp["tablename"] = $(html).find('input[type=text]').attr("tablename");
            if ($(html).find('input[type=text]').attr("isPartitionKey")) {
                temp["isPartitionKey"] = 'yes';
            }
            temp["isDisplayNameModified"]='no';

            var visible = $(html).find('.modelitemvisible').prop("checked") == false? 'no':'yes' ;
            temp["visible"]=visible;
            if (that.dataModelMetaDataCollection != undefined && (that.dataModelMetaDataCollection.getMetadataEntities().length > 0 || that.dataModelMetaDataCollection.getMetadataEntities() != undefined)) {
                var entitiesArray = that.dataModelMetaDataCollection.getMetadataEntities();
                _.each(entitiesArray, function (i, j) {
                    if (i.workspaceentityid == that.workspaceentityid && i.entityname == that.entityName) {
                        var savedCols = JSON.parse(i.columns);
                        _.each(savedCols, function (s, t) {
                            if(s.orginalColumnName == $(html).find('input[type=text]').attr("orginalcolumnname").trim() && s.columnDisplayName !=$(html).find('.tabledisplayname').val().trim()){
                                temp["isDisplayNameModified"]='yes';
                            }
                        });
                    }
                });
            }
            
            columns.push(temp);
            if (visible == "yes") {
                isvisible = true;
            }
        });
        if (!isvisible) {
            $('.spinner').hide();
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-infor').addClass('birdmessage-warning');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-info-circle').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            setTimeout(function () {
                $("#datamodelsstepscontainer").steps("previous");
                $('.spinner').hide();
            }, 800);
            $('.birdmessage-info').empty().text(lang["models.slctonecolm"]);
            success = false;
            return false;
        }

        //for checking display names
        $(".csvdisplaynameid-error").addClass("hide");
        $(".csvdisplayname-error").addClass("hide");
        _.each(columns, function (column) {
            if (column.columnDisplayName != undefined && (column.columnDisplayName.trim() === null || column.columnDisplayName.trim() === "")) {
                $('.spinner').hide();
                $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-infor').addClass('birdmessage-warning');
                $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-info-circle').addClass('fa-exclamation-triangle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Warning');
                $('.birdmessage-info').empty().text(lang["models.fillpropercolm"]);
                success = false;
                return false;
            }
            else if (/[~`!@#$%^&*()+\=\[\]{};':\\|,.<>\/?"`]/.test(column.columnDisplayName.trim())) {
                $('.spinner').hide();
                var replacedOriginalName = column.orginalColumnName.replace(/([-_;?&.,%#+*~\ '"!^$[\]()<=>`|\/@])/g, "");
                $(".csvdisplayname-error[replacedOriginalName='" + replacedOriginalName + "']").removeClass("hide");
                $("a[href='#next']").parent().addClass("disabled");
                $(".display-col").keyup(function (e) {
                    var $this = e.currentTarget
                    $("a[href='#next']").parent().removeClass("disabled");
                    var replacedOriginalName = $($this).next().attr("name").replace(/([-_;?&.,%#+*~\ '"!^$[\]()<=>`|\/@])/g, "");
                    $(".csvdisplayname-error[replacedOriginalName='" + replacedOriginalName + "']").addClass("hide");
                });
                success = false;
                return false;
            }
            else if ((column.columnDisplayName.toLowerCase().trim() == "min" || column.columnDisplayName.toLowerCase().trim() == "max"
                || column.columnDisplayName.toLowerCase().trim() == "avg" || column.columnDisplayName.toLowerCase().trim() == "sum"
                || column.columnDisplayName.toLowerCase().trim() == "count" || column.columnDisplayName.toLowerCase().trim() == "avgerage")) {
                $('.spinner').hide();
                $(".csvdisplayname-error[orginalcolumnname='" + column.orginalColumnName + "']").removeClass("hide");
                $("a[href='#next']").parent().addClass("disabled");
                $(".display-col").keyup(function (e) {
                    var $this = e.currentTarget
                    $("a[href='#next']").parent().removeClass("disabled");
                    $(".csvdisplayname-error[name='" + $($this).next().attr("name") + "']").addClass("hide");
                });
                success = false;
                return false;
            } else if (column.columnDisplayName.toLowerCase().trim() == "__id" || column.columnDisplayName.toLowerCase().trim() == "_id") {
                $('.spinner').hide();
                $(".csvdisplaynameid-error[orginalcolumnname='" + column.orginalColumnName + "']").removeClass("hide");
                $("a[href='#next']").parent().addClass("disabled");
                $(".display-col").keyup(function (e) {
                    var $this = e.currentTarget
                    $("a[href='#next']").parent().removeClass("disabled");
                    $(".csvdisplaynameid-error[name='" + $($this).next().attr("name") + "']").addClass("hide");
                });
                success = false;
                return false;
            } else if (that.isValidation) {
                $('.spinner').hide();
                $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-infor').addClass('birdmessage-warning');
                $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-info-circle').addClass('fa-exclamation-triangle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Warning');
                $('.birdmessage-info').empty().text(lang["models.duplicatecolm"]);
                success = false;
                return false;
            }
        });
        if (success) {
            $("a[href='#next']").parent().removeClass("disabled");
            $(".csvdisplaynameid-error").addClass("hide");
            $(".csvdisplayname-error").addClass("hide");
            that.entitycollection.updateEntityColumns(JSON.stringify(columns), that.entityName, that.workspaceentityid);
            $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
            $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Success');
            $('.birdmessage-info').empty().text(getMessage('BirdSuccess60'));
            $('.spinner').hide();
        } else {
            setTimeout(function () {
                $("#datamodelsstepscontainer").steps("previous");
                $('.spinner').hide();
            }, 800);
        }
    }
    validateColName = (e) => {
        e.preventDefault();
        var $this = e.currentTarget;
        var that = this;
        var count = 0;
        var customFields = [];
        if (this.entitycollection.getEntityCustomFeilds(this.entityName, this.workspaceentityid) != undefined && this.entitycollection.getEntityCustomFeilds(this.entityName, this.workspaceentityid) != "") {
            customFields = JSON.parse(this.entitycollection.getEntityCustomFeilds(this.entityName, this.workspaceentityid));
        }
        _.each($('.datamodelsymanticnamestablecontainer tbody tr'), function (html, index) {
            if ($(html).find('.tabledisplayname').val().toLowerCase().trim() == $this.value.toLowerCase().trim()) {
                count++;
            }
            if (count >= 2) {
                that.isValidation = true;
                $("a[href='#next']").parent().addClass("disabled");
                var replacedOriginalName = $this.id.replace(/([-_;?&.,%#+*~\ '"!^$[\]()<=>`|\/@])/g, "");
                $(".displayrename-error[replacedOriginalName='" + replacedOriginalName + "']").removeClass("hide");
                $this.focus();
                count = 0;
                $(".display-col").keyup(function (e) {
                    that.validateColName(e);
                    if (!that.isValidation) {
                        //$("a[href='#next']").parent().removeClass("disabled");
                        $(".displayrename-error").addClass("hide");
                    }
                });
                return false;
            }
        });
        _.each(customFields, function (cf) {
            if (cf.name.toLowerCase() == $this.value.toLowerCase().trim()) {
                count++;
            }
            if (count >= 2) {
                that.isValidation = true;
                $("a[href='#next']").parent().addClass("disabled");
                $(".displayrename-error[name='" + $this.id + "']").removeClass("hide");
                $this.focus();
                count = 0;
                $(".display-col").keyup(function (e) {
                    that.validateColName(e);
                    if (!that.isValidation) {
                        //$("a[href='#next']").parent().removeClass("disabled");
                        $(".displayrename-error").addClass("hide");
                    }
                });
                return false;
            }
        });
        if (count == 1) {
            that.isValidation = false;
        }
        if (that.isValidation == false) { $(".newreportpagescrolling").scrollTop(186); $(".csvdisplayname-error:not('.hide')").eq(0).parent().find("input").focus(); }
        return that.isValidation;
    }
}