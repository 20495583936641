import React from "react";
import ReactDOM from 'react-dom'
import SendRequest from "../SendRequest";
import { getMessage } from '../js/helpers/utils/BirdMessage';
import * as ModalProperties from '../js/helpers/utils/modalproperties';
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import ShareReportmodalTemplate from './sharereportmodal.jsx'
import AdvancedStoryboardSharePermissionsView from './advancedstoryboardsharepermissions'
import { lang } from "../js/helpers/utils/languages/language";
import _ from 'lodash';
const $ = window.$;

export default class ShareReportmodal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.state.el = ".sharediv";
        var that = this;
        this.state.isFromStoryBook = that.props.isFromStorybook!=undefined && that.props.isFromStorybook!=null?that.props.isFromStorybook:false;
		this.state.storybookId= that.props.storybookId!=undefined && that.props.storybookId!=null?that.props.storybookId:"0";
        this.state.isCustomStoryboard = that.props.isCustomStoryboard!=undefined && that.props.isCustomStoryboard!=null?that.props.isCustomStoryboard:false;
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };

        SendRequest({
            url: "getAllGroups",
            queryString: "",
            sucessFunction: groupcollection => {
                that.state.groupcollection = groupcollection;
                SendRequest({
                    url: "getUsersForSharing",
                    queryString: "",
                    sucessFunction: collection => {
                        that.state.collection = collection;
                        that.render();
                    },
                    rejectFunction: () => { },
                    headers: requestHeaders
                });
            },
            rejectFunction: () => { },
            headers: requestHeaders
        });

        SendRequest({
            url: "getsharedusers",
            queryString: "?reportId=" +that.props.reportId+"&isFromStoryBook="+that.state.isFromStoryBook+"&storybookId="+that.state.storybookId+"&isFromCustomStoryBoard="+that.state.isCustomStoryboard +"&customstoryboard_id="+that.props.reportId,
            sucessFunction: getAllSharedUsers => {
                that.state.getAllSharedUsers = getAllSharedUsers;
            },
            rejectFunction: () => { },
            headers: requestHeaders
        });

        SendRequest({
            url: "getsharedgroups",
            queryString: "?reportId=" + that.props.reportId+"&isFromStoryBook="+that.state.isFromStoryBook+"&storybookId="+that.state.storybookId+"&isFromCustomStoryBoard="+this.state.isCustomStoryboard +"&customstoryboard_id="+that.props.reportId,
            sucessFunction: getAllSharedReportsCollection => {
                that.state.getAllSharedReportsCollection = getAllSharedReportsCollection;
            },
            rejectFunction: () => { },
            headers: requestHeaders
        });

        this.state.reportId = this.props.reportId;
        this.state.singlereport = this.props.singlereport;

    }
    render() {
        var that= this;
        $("body").addClass("rightpanelopen");
        var tempUsers = this.state.collection;
        var tempGroups = this.state.groupcollection;
        tempUsers.sort(function (a, b) {
            var x = a.username.toLowerCase();
            var y = b.username.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
        });
        tempGroups.sort(function (a, b) {
            var x = a.groupName.toLowerCase();
            var y = b.groupName.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
        });
        ReactDOM.render(<ShareReportmodalTemplate sharereportapply={this.sharereportapply} ShowAdvancedShareReport={this.ShowAdvancedShareReport} closesharemodal={this.closesharemodal} users={tempUsers} groups={tempGroups} getAllSharedReportsGroups={this.state.getAllSharedReportsCollection} getAllsharedUsers={this.state.getAllSharedUsers}showadvanced={this.showadvanced} searchusersgroups={this.searchusersgroups} />, document.querySelector(this.state.el));
        this.showcontent();
        var that = this;
        $(".group-select").chosen({
            no_results_text: lang["storyboard.oops"],
            width: "100%",
            search_contains: true
        });

        if (this.state.singlereport) {
                that.showcontent();
                $("body").addClass("modal-open");
                ModalProperties.resize();
            //this.broker.trigger("onusersfetchedforsinglereport", this.$el);
        }
        else {
            $("#sharestoryboard").modal("show");
			ModalProperties.resize();
            // this.broker.trigger("onusersfetched");
        }
        if (this.state.isCustomStoryboard != undefined && this.state.isCustomStoryboard) {
            $('.showadvancedsharereport').addClass('hide');
        }
       else if(this.state.isFromStoryBook!=undefined && this.state.isFromStoryBook){
            $('.showadvancedsharereport').addClass('hide');
        } else {
            $('.showadvancedsharereport').removeClass('hide');
        }
        
        this.displaygroups();
        $('#sharestoryboard').on('hide.bs.modal', function (event) {
            $("body").removeClass("rightpanelopen");
            $(".leftpane").removeClass("slidetoleft");
        });

        return (<div></div>);
    }
    showadvanced = (e) => {
        e.preventDefault();
        var $this = e.currentTarget;
        $($this).parent().find('.filtersearching').toggle('slow');
    }
    searchusersgroups = (e) => {
        e.preventDefault();
   			var startswith = $('#searchnamestartswith').val();
			var endswith = $("#searchnameendswith").val();
			var contains = $("#searchnamecontains").val();
    
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getSearchedGroupsList",
            queryString: "?startswith=" + startswith + "&endswith=" + endswith + "&contains=" + contains,
            sucessFunction: (response) => {
               var groupOptionArray = [];
            $.each(response, function(i, group)
            {
                groupOptionArray[i] = "<option key='" + i + "' value='" + group.groupId + "'>" + group.groupName + "</option>";
            });
            $('.group-select>optgroup.groupids').find('option').not(':selected').remove()
            $('.group-select>optgroup.groupids').prepend(groupOptionArray.join(''));
            $('.group-select').trigger("chosen:updated");
            $('.group-select').trigger('chosen:open');
            },
            rejectFunction: () => { },
            headers: requestHeaders
        });
        SendRequest({
            url: "getsearchedusersforsharing",
            queryString: "?startswith=" + startswith + "&endswith=" + endswith + "&contains=" + contains +"&isFromGovernance=" + false,
            sucessFunction: (response) => {
                var userOptionsArray = [];
                $.each(response, function(i, user)
                        {	if(!user.isLoginUser){
                            userOptionsArray[i] = "<option key='" + i + "' value='" + user.userid + "'>" + user.username+" ("+user.email+")" + "</option>";
                            }
                        });
                $('.group-select>optgroup.userids').find('option').not(':selected').remove()
                $('.group-select>optgroup.userids').prepend(userOptionsArray.join(''));
                $('.group-select').trigger("chosen:updated");
                $('.group-select').trigger('chosen:open'); 
            },
            rejectFunction: () => { },
            headers: requestHeaders
        });
    }
    closesharemodal = () => {
        ReactDOM.unmountComponentAtNode(document.querySelector(this.state.el));
        $("body").removeClass("rightpanelopen");
        $(".leftpane").removeClass("slidetoleft");
        setTimeout(function(){
            $('.modal-backdrop').remove();
        },500);
    }
    showcontent() {
        $(this.state.el).find(".group-select").chosen({
            no_results_text: lang["storyboard.oops"],
            width: "100%",
            search_contains: true
        });
    }
    displaygroups() {
        _.each(this.state.getAllSharedReportsCollection, function (model, index) {
            if (model.isshared != "N") {
                $('.groupids option[value=' + model.groupid + ']').attr("selected", "selected");
                $(".group-select").trigger("chosen:updated");
            }
        })
        _.each(this.state.getAllSharedUsers, function (model, index) {
            $('.userids option[value=' + model.userid + ']').attr("selected", "selected");
            $(".group-select").trigger("chosen:updated");
        })
    }
    ShowAdvancedShareReport = (e) => {
        e.preventDefault();
        var $this = e.currentTarget;
        $(".sharereportselectuserserror").addClass("hide");
        //var currentChartNumber = this.props.chartnumber;
        var that = this;
        //Validation for Empty Users
        var grouping;
        if ($('.group-select .groupids option:selected').val() == undefined || $('.group-select .groupids option:selected').val() != undefined) {
            grouping = $('.group-select .groupids option:selected').val();
            //  that.props.model.set('grouping',grouping);
        }
        if ($('.group-select .groupids option:selected').val() == undefined || $('.group-select .userids option:selected').val() != undefined) {
            grouping = $('.group-select .userids option:selected').val();
            //   that.props.model.set('grouping',grouping);
        }
        if(grouping==undefined){
            $(".sharereportselectuserserror").removeClass("hide");
            return false;
        }
        // that.props.model.isValid(true);
        //\\Validation for Empty Users

        var choosengroupNames = $('.group-select .groupids option:selected');
        var choosenUserNames = $('.group-select .userids option:selected');
        // $('#advancedaccesspermissonscontainer').empty();
        var columns = that.props.dimensions;
        columns.sort(function(a, b){
            var x = a.columnDisplayName.toLowerCase();
            var y = b.columnDisplayName.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0 ;
            });
        var dimensionArray = [];
        _.each(columns, function (e, i) {
            var obj = {};
            obj["aggregation"] = columns[i].aggregation;
            obj["columnDisplayName"] = columns[i].columnDisplayName;
            obj["columnName"] = columns[i].columnName;
            obj["id"] = columns[i].id;
            obj["keycolumn"] = columns[i].keycolumn;
            obj["tableDisplayName"] = columns[i].tableDisplayName;
            obj["tableDisplayType"] = columns[i].tableDisplayType;
            obj["tableName"] = columns[i].tableName;
            obj["columnssrename"] = columns[i].columnssrename;
            obj["unique"] = columns[i].unique;
            obj["visible"] = columns[i].unique;
            obj["tableAliasName"] = columns[i].tableAliasName;
            obj["dateFormat"] = columns[i].dateFormat;
            obj["orginalColumnName"] = columns[i].orginalColumnName;
            dimensionArray.push(obj);
        });
        if (choosenUserNames.length != 0 || choosengroupNames.length != 0) {
            var advancedStoryboardSharePermissionsView = new AdvancedStoryboardSharePermissionsView({
                groupcollection: that.state.groupcollection,
                userscollection: that.state.collection,
                selectedgrouplist: choosengroupNames,
                selectuserslist: choosenUserNames,
                dimensions: dimensionArray,
                sharedtogroups: that.state.getAllSharedReportsCollection,
                sharedtousers: that.state.getAllSharedUsers,
                sharedFilters: that.props.sharedFilters,
                datamodelId: that.props.datamodelId,
                isViewModel: that.props.isViewModel
            });
            $("#advancedsharestoryboardpermissionmodal").modal("show");
            ModalProperties.resize();
            $(window).on("resize", function () { ModalProperties.resize(); })
            if (this.state.singlereport) {
                setTimeout(function(){
                    $('.modal-backdrop').remove();
                },500);
            }
        }
    }
    sharereportapply = () => {
        var grouping;
        $(".sharereportselectuserserror").addClass("hide");
        let requestHeaders = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        if ($('.group-select .groupids option:selected').val() == undefined || $('.group-select .groupids option:selected').val() != undefined) {
            grouping = $('.group-select .groupids option:selected').val();
            //       this.props.model.set('grouping', grouping);
        }
        if ($('.group-select .groupids option:selected').val() == undefined || $('.group-select .userids option:selected').val() != undefined) {
            grouping = $('.group-select .userids option:selected').val();
            //      this.props.model.set('grouping', grouping);
        }
       
        var comments = $(this.state.el).find("#sharecomments").val();
        //   this.props.model.set('comments', comments);
        var userfilterArray = [];
        userfilterArray = ChartInfo.functions.getAppliedSharedFilterArray();
        if (this.state.getAllSharedReportsCollection.length == 0 && this.state.getAllSharedUsers.length == 0) {
            if(grouping==undefined){
                $(".sharereportselectuserserror").removeClass("hide");
                return false;
            }
                var choosenUserNames = $('.group-select .userids option:selected');
                var choosenUserIds = "";
                _.each(choosenUserNames, function (groupId, i) {
                    choosenUserIds += $($('.group-select .userids option:selected')[i]).val() + ",";
                });

                choosenUserIds = choosenUserIds.substring(0, choosenUserIds.length - 1);
                var choosengroupNames = $('.group-select .groupids option:selected');
                var choosenGroupIds = "";
                _.each(choosengroupNames, function (groupId, i) {
                    choosenGroupIds += $($('.group-select .groupids option:selected')[i]).val() + ",";
                });
                choosenGroupIds = choosenGroupIds.substring(0, choosenGroupIds.length - 1);
                var shareModel = {};
                var modeldata = {};
                modeldata["reportId"] = this.state.reportId;
                //   shareModel.set(modeldata);
                // var count = this.state.collection.length;
                // for (var i = count - 1; i > -1; i--) {
                //     this.state.collection.remove(this.state.collection.at(i));
                // }
                //    this.state.collection.add(shareModel);
                var sharecomments = $("#sharecomments").val();
                var that = this;
                var selectedcolumns = ChartInfo.functions.getSelectedColumnsForSharedReports();
                var isViewOnly = []
                isViewOnly = ChartInfo.functions.getisViewOnlyOptions();
                if (selectedcolumns.length == 0) {
                    selectedcolumns = "";
                }
                else {
                    selectedcolumns = JSON.stringify(selectedcolumns)
                }
                var formData = new FormData();
                formData.set("reportId", that.state.reportId);
                formData.set("comment", sharecomments);
                formData.set("userIds", choosenUserIds);
                formData.set("groupIds", choosenGroupIds);
                formData.set("selectedcolumns", selectedcolumns);
                formData.set("userfilterArray", JSON.stringify(userfilterArray));
                formData.set("isViewOnlyarray", JSON.stringify(isViewOnly));
                formData.set("isFromStoryBook", that.state.isFromStoryBook);
                formData.set("storybookId", that.state.storybookId);
                formData.set("isFromCustomStoryboard", that.state.isCustomStoryboard);
                formData.set("customstoryboardId", that.state.reportId);

                SendRequest({
                    url: "shareReports",
                    body: formData,
                    sucessFunction: (response) => {
                        if (response.hasOwnProperty('error')) {
                            $(".spinner").hide();
                            $(".gridspinner").hide();
                            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                            $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                            $('.birdmessagebuttons').removeClass('show');
                            $('.birdmessagebuttons').addClass('hide');
                            $('.birdmessage-center').addClass("message-top-in");
                            $('.details').removeClass('hide');
                            $('.birdmessage h2').empty().append('Error : ');
                            $('.birdmessage h2').append('<span class="errorcode">');
                            $('.birdmessage .errorcode').empty().append(response.errorCode);
                            $('.birdmessage-info').empty().text(response.error);
                            $('.details').addClass('show');
                            $('.messagedetails xmp').empty().append(response.errorTrace)
                        } else {
                            $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                            $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                            $('.birdmessagebuttons').removeClass('show');
                            $('.birdmessagebuttons').addClass('hide');
                            $('.birdmessage-center').addClass("message-top-in");
                            $('.details').removeClass('show');
                            $('.details').addClass('hide');
                            $('.birdmessage h2').empty().append('Success');
                            $('.birdmessage-info').empty().text(getMessage('BirdSuccess03'));
                            //  $("#sharestoryboard").modal("hide");
                            that.closesharemodal();
                        }
                    },
                    rejectFunction: this.reportDataError,
                    headers: requestHeaders
                });
        } else {
            var choosenUserNames = $('.group-select .userids option:selected');
            var choosenUserIds = "";
            _.each(choosenUserNames, function (groupId, i) {
                choosenUserIds += $($('.group-select .userids option:selected')[i]).val() + ",";

            });
            choosenUserIds = choosenUserIds.substring(0, choosenUserIds.length - 1);
            var choosengroupNames = $('.group-select .groupids option:selected');
            var choosenGroupIds = "";
            _.each(choosengroupNames, function (groupId, i) {
                choosenGroupIds += $($('.group-select .groupids option:selected')[i]).val() + ",";

            });
            choosenGroupIds = choosenGroupIds.substring(0, choosenGroupIds.length - 1);
            var shareModel = {};
            var modeldata = {};
            modeldata["reportId"] = this.state.reportId;
            //  shareModel.set(modeldata);
            // var count = this.state.collection.length;
            // for (var i = count - 1; i > -1; i--) {
            //     this.state.collection.remove(this.state.collection.at(i));
            // }
            // this.state.collection.add(shareModel);
            var sharecomments = $("#sharecomments").val();
            var that = this;
            var selectedcolumns = ChartInfo.functions.getSelectedColumnsForSharedReports();
            var isViewOnly = []
            isViewOnly = ChartInfo.functions.getisViewOnlyOptions();
            if (selectedcolumns.length == 0) {
                selectedcolumns = "";
            }
            else {
                selectedcolumns = JSON.stringify(selectedcolumns)
            }

            var formdata = new FormData();
            formdata.set("reportId", that.state.reportId);
            formdata.set("comment", sharecomments);
            formdata.set("userIds", choosenUserIds);
            formdata.set("groupIds", choosenGroupIds);
            formdata.set("selectedcolumns", selectedcolumns);
            formdata.set("userfilterArray", JSON.stringify(userfilterArray));
            formdata.set("isViewOnlyarray", JSON.stringify(isViewOnly));
            formdata.set("isFromStoryBook", that.state.isFromStoryBook);
            formdata.set("storybookId", that.state.storybookId);
            formdata.set("isFromCustomStoryboard", that.state.isCustomStoryboard);
            formdata.set("customstoryboardId", that.state.reportId);
            SendRequest({
                url: "shareReports",
                body: formdata,
                sucessFunction: (response) => {
                    if (response.hasOwnProperty('error')) {
                        $(".spinner").hide();
                        $(".gridspinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.errorTrace)
                    } else {
                        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Success');
                        $('.birdmessage-info').empty().text(getMessage('BirdSuccess33'));
                        //  $("#sharestoryboard").modal("hide");
                        that.closesharemodal();
                    }
                },
                rejectFunction: this.reportDataError,
                headers: requestHeaders
            });
        }
    }
}