import React, { Component } from 'react'
import { lang } from "../js/helpers/utils/languages/language";
import ReactDOM from 'react-dom'
const $ = window.$;
export default class PageNavigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.state.href = this.props.href;
        this.render();
    }
    render() {
        ReactDOM.render(<PageNavigationHtml navigateyes={this.navigateyes} navigationclose={this.navigationclose} />, document.getElementById("urlnavigationmenu"));
        return (<div></div>);
    }
    navigateyes = (e) => {
        var data = $(e.currentTarget).val();
        var $this = e.currentTarget;
        e.preventDefault();
        if (data == "yes") {
            if (this.state.href == "") {
                this.spinner();
                sessionStorage.removeItem("customStoryBoardReportId");
                this.navigationclose();
            } else if (this.state.href == "logout") {
                this.navigationclose();
                this.props.logout();
            }
            else {
                this.props.navigatedfrom(this.props.id);
                this.spinner();
                this.props.history.push(this.props.href);
                this.navigationclose();
            }
        }
        else {
            this.navigationclose();
            $(".spinner").hide();
        }
    }

    navigationclose = () => {
        ReactDOM.unmountComponentAtNode(document.getElementById("urlnavigationmenu"));
    }
    spinner(e) {
        var a = $(".spinner").clone();
        a.find(".spinnermessage").remove();
        a.attr("class", "tempspinner");
        $("body").prepend(a);
        $(".spinner").addClass("stopfortemp");
        $(".tempspinner").show();
        setTimeout(function () { $(".tempspinner").remove(); $(".spinner").removeClass("stopfortemp"); }, 2200);
    }
}
class PageNavigationHtml extends Component {
    render() {
        return (
            <div className="modal fade in" id="pagenavigation" tabIndex="-1" role="dialog" aria-labelledby="pagenavigation" aria-hidden="true" style={{ zIndex: "9999" }} style={{ display: "block" }}>
                <div className="modal-backdrop fade in" onClick={this.props.navigationclose}></div>
                <div className="modal-dialog modal-sm modal-sm-400 modal-dialog-sm-center modal-dialog-center" style={{ zIndex: "1050" }}>
                    <div className="modal-content  scrollset">
                        <div className="modal-header boc-modal-hf-padding">
                            <button type="button" className="close white" id="navigationclose" onClick={this.props.navigationclose} aria-hidden="true" data-dismiss="modal">&times;</button>
                            <h4 className="modal-title"><i className="fa fa-exclamation-triangle fa-solid fa-warning"></i> {lang["reports.cnfnavigation"]}</h4>
                        </div>
                        <div className="modal-body scrollset">
                            <div className="container-fluid">
                                <div className="row">
                                    {/* <!-- <legend className="subhead">Query</legend> --> */}
                                    <div className="modal-form-fields confirm-dailog queryinfo " rows="6" disabled >{lang["reports.unsacedchanges"]}<br></br>{lang["reports.stillproceed"]}</div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer boc-modal-hf-padding">
                            <button type="button" className="btn btn-bird navigateyes" id="confirmnavigateyes" onClick={this.props.navigateyes} value="yes"><i className="fa fa-check"></i> {lang["reports.cnfyes"]}</button>
                            <button type="button" className="btn btn-bird navigateyes" id="confirmnavigateno" onClick={this.props.navigationclose} value="no"><i className="fa fa-times"></i> {lang["reports.cnfno"]}</button>
                        </div>
                    </div>
                    {/* <!-- /.modal-content --> */}
                </div>
                {/* <!-- /.modal-dialog --> */}
            </div>
            // {/* <!-- /.Page Navigation Modal --> */}
        );
    }
}