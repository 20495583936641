import React from 'react';
import _ from 'underscore';
import { lang } from "../js/helpers/utils/languages/language";

export default class WebDataConnectorFinalPreviewHtml extends React.Component {
	render() {
		return (
			<React.Fragment>
				<div className="databasemodifyentities marg-top-20">
					<div className="table-responsive">
						<table id='webdataconnectorentitiestable' className="table custom" data-toggle="table">
							<thead>
								<tr>
									<th>{lang["datahub.columnname"]}</th>
									<th>{lang["datahub.displayname"]}</th>
									<th>{lang["datahub.datatype"]}</th>
								</tr>
							</thead>
							<tbody>
								{
									this.props.columnslist.map((data,index)=>{
										return(
											<tr className="webdataconnectorcolumns">
											<td>{data.orginalColumnName}</td>
											<td><input type="text" id={data.orginalColumnName} index={index} defaultValue={data.columnName} className="csv-form-control display-col columndisplayname tabledisplayname" columnname={data.orginalColumnName} tablealiasname={data.tableAliasName} orginalcolumnname={data.orginalColumnName} />
												<span name={data.columnName} id={data.columnName} className="error-block pull-left csvdisplayname-error hide">{lang["datahub.colspclcharact"]}</span>
												<span name={data.columnName} id={data.columnName} className="displayrename-error  pull-left hide ">{lang["datahub.colnameexists"]}</span>
											</td>
											<td>
												<div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
													{(data.dataType.toLowerCase() == "number") ? (
														<select className="csv-form-control datecontroller" index={index} id={data.orginalColumnName} name={data.orginalColumnName} display-name={data.orginalColumnName}>
															<option value="Number" selected="selected">{lang["datahub.numtype"]}</option>
															<option value="String">{lang["datahub.strngtype"]}</option>
															<option value="date">{lang["datahub.dateformat"]}</option>
															<option value="datetime">{lang["datahub.timeformat"]}</option>
															<option value="Boolean">{lang["datahub.boolean"]}</option>
														</select>
													) : (data.dataType.toLowerCase() == "string") ? (
														<select className="csv-form-control datecontroller" index={index} id={data.orginalColumnName} name={data.orginalColumnName} display-name={data.orginalColumnName}>
															<option value="Number">{lang["datahub.numtype"]}</option>
															<option value="String" selected="selected">{lang["datahub.strngtype"]}</option>
															<option value="date">{lang["datahub.dateformat"]}</option>
															<option value="datetime">{lang["datahub.timeformat"]}</option>
															<option value="Boolean">{lang["datahub.boolean"]}</option>
														</select>
													) : (data.dataType.toLowerCase() == "date") ? (
														<select className="csv-form-control datecontroller" index={index} id={data.orginalColumnName} name={data.orginalColumnName} display-name={data.orginalColumnName}>
															<option value="Number">{lang["datahub.numtype"]}</option>
															<option value="String">{lang["datahub.strngtype"]}</option>
															<option value="date" selected="selected">{lang["datahub.dateformat"]}</option>
															<option value="datetime">{lang["datahub.timeformat"]}</option>
															<option value="Boolean">{lang["datahub.boolean"]}</option>
														</select>
													) : (data.dataType.toLowerCase() == "boolean") ? (
														<select className="csv-form-control datecontroller" index={index} id={data.orginalColumnName} name={data.orginalColumnName} display-name={data.orginalColumnName}>
															<option value="Number">{lang["datahub.numtype"]}</option>
															<option value="String">{lang["datahub.strngtype"]}</option>
															<option value="date">{lang["datahub.dateformat"]}</option>
															<option value="datetime">{lang["datahub.timeformat"]}</option>
															<option value="Boolean" selected="selected">{lang["datahub.boolean"]}</option>
														</select>
													) : null}
												</div>
												<div className="additionaloptions date col-lg-4 col-md-4 col-sm-12 col-xs-12 csvdateformatsdroppdown" index={index} id={data.orginalColumnName} name={data.orginalColumnName} style={{display:"none"}}>
													<select className="csv-form-control" name="date" id={data.orginalColumnName} name={data.orginalColumnName}>
														<option value="mdy">{lang["Datahub.d/m/yformat"]}</option>
														<option value="mdy">{lang["Datahub.m/d/yformat"]}</option>
														<option value="mdy">{lang["Datahub.y/m/dformat"]}</option>
														<option value="mdy">{lang["Datahub.d-m-yformat"]}</option>
														<option value="mdy">{lang["Datahub.m-d-yformat"]}</option>
														<option value="mdy">{lang["Datahub.y-m-dformat"]}</option>
														<option value="mdy">{lang["Datahub.dd/MM/yyyyformat"]}</option>
														<option value="mdy">{lang["Datahub.MM/dd/yyyyformat"]}</option>
														<option value="ymd">{lang["Datahub.yyyy/MM/ddformat"]}</option>
														<option value="mdy">{lang["Datahub.dd-MM-yyyyformat"]}</option>
														<option value="mdy">{lang["Datahub.MM-dd-yyyyformat"]}</option>
														<option value="ymd">{lang["Datahub.yyyy-MM-ddformat"]}</option>
														<option value="dmy">{lang["Datahub.dd-MM-yyformat"]}</option>
														<option value="mdy">{lang["Datahub.dd/MM/yyformat"]}</option>
														<option value="mdy">{lang["Datahub.d/M/yyformat"]}</option>
														<option value="others">{lang["datahub.others"]}</option>
													</select>
												</div>
												<div className="additionaloptions datetime col-lg-4 col-md-4 col-sm-12 col-xs-12 csvdateformatsdroppdown" index={index} id={data.orginalColumnName} name={data.orginalColumnName} style={{display:"none"}}>
													<select className="csv-form-control" name="datetime" id={data.orginalColumnName} name={data.orginalColumnName}>
														<option value="mdy">{lang["Datahub.d/m/y"]}</option>
														<option value="mdy">{lang["Datahub.m/d/y"]}</option>
														<option value="mdy">{lang["Datahub.y/m/d"]}</option>
														<option value="mdy">{lang["Datahub.d-m-y"]}</option>
														<option value="mdy">{lang["Datahub.m-d-y"]}</option>
														<option value="mdy">{lang["Datahub.y-m-d"]}</option>
														<option value="mdy">{lang["Datahub.dd/MM/yyyy"]}</option>
														<option value="mdy">{lang["Datahub.MM/dd/yyyy"]}</option>
														<option value="ymd">{lang["Datahub.yyyy/MM/dd"]}</option>
														<option value="mdy">{lang["Datahub.dd-MM-yyyy"]}</option>
														<option value="ymd">{lang["Datahub.sssz"]}</option>
														<option value="ymd">{lang["Datahub.ssss"]}</option>
														<option value="mdy">{lang["Datahub.MM-dd-yyyy"]}</option>
														<option value="ymd">{lang["Datahub.yyyy-MM-dd"]}</option>
														<option value="dmy">{lang["Datahub.dd-MM-yy"]}</option>
														<option value="mdy">{lang["Datahub.dd/MM/yy"]}</option>
														<option value="mdy">{lang["Datahub.d/M/yy"]}</option>
														<option value="mdy">{lang["Datahub.yyyy/MM/dd"]}</option>
														<option value="mdy">{lang["Datahub.dd/MM/yyyy"]}</option>
														<option value="mdy">{lang["Datahub.MM/dd/yyyy"]}</option>
														<option value="others">{lang["datahub.others"]}</option>
													</select>
												</div>
												<div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
													<input type="text" id={data.orginalColumnName} name={data.orginalColumnName} className="csv-form-control csvcustomdatefield" />
												</div>
											</td>
										</tr>
										);
									})
								}
							</tbody>
						</table>
					</div>
				</div>
			</React.Fragment>
		);
	}
}