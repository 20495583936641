import React from 'react';
import _ from 'lodash';
import $ from 'jquery';
import Highcharts from 'highcharts/highmaps';
import highchartsMap from "highcharts/modules/map";
import proj4 from 'proj4';
import Exporting from 'highcharts/modules/exporting';
import noDataDisplay from 'highcharts/modules/no-data-to-display'
import HighchartsReact from 'highcharts-react-official';
import Viewchartdrilldownmodal from "../../ViewStoryboard/ChartDrillDownModal";
import ViewMapdrilldownmodal from "../../ViewStoryboard/MapDrillDownModal";
import drilldow from "highcharts/modules/drilldown";
import dataModule from "highcharts/modules/data";
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import * as ChartUtil from "../../js/helpers/utils/chartutil";
import PubSub from 'pubsub-js';
import { whitelableMap } from "../../js/helpers/utils/whitelable";
import { getMessage } from '../../js/helpers/utils/BirdMessage';
import Leaflet from './leaflet';
import ReactDOM from 'react-dom';
import { getIndiaStateName } from '../helpers/utils/countryCode';
import * as inAll from '../../js/helpers/utils/in-all-disputed';
import { lang } from "../../js/helpers/utils/languages/language";
highchartsMap(Highcharts); 
Exporting(Highcharts);
dataModule(Highcharts);
drilldow(Highcharts);
//Highmaps(Highcharts);
noDataDisplay(Highcharts);
if (typeof window !== "undefined") {
    window.proj4 = window.proj4 || proj4;
}
var charts = {};
export default class Indiamap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        var compos = {};
        compos["chartComponent"] = this.refs.chartComponent;
        compos["chartparent"] = this.refs.chartparent;
        compos["chart"] = this.state.chart;
        compos["chartType"] = this.state.chartType;
        ChartInfo.functions.setChartComponents(this.state.chartnumber, compos);
    }
    render(){
        var data = this.props.data, xaxisName = this.props.xaxisName, xaxisColumnName = this.props.xaxisColumnName, xaxisDataType = this.props.xaxisDataType, yaxisnameArray = this.props.yaxisnameArray, chartId = this.props.chartId, chartType = this.props.chartType, legend = this.props.legend, position = this.props.position, features = this.props.features, isSharedReport = this.props.isSharedReport, colorThemeIndex = this.props.colorThemeIndex, chartSettings = this.props.chartSettings, tooltipNameArray = this.props.tooltipNameArray, trendstats = this.props.trendstats, iscustomstoryboard = this.props.iscustomstoryboard, xaxisidentity = this.props.xaxisidentity, yAxisDataTypeArray = this.props.yAxisDataTypeArray, ismultifact = this.props.ismultifact, multifactentities = this.props.multifactentities, datamodelId = this.props.datamodelId, reportid = this.props.reportid, facttables = this.props.facttables;
        var that = this.state;
        that.chartType = this.props.chartType;
        var chartnumber = chartId.replace("chart","");
        that.chartid = chartId;
        if(charts!=undefined && chartId!=undefined && charts[chartId]!=undefined){
            charts[chartId].destroy();
        }
        var columnsData = this.props.columnsData;
        var chartPoints = {};
        var viewChartDrillDownModal = null;
        var viewMapDrillDownModal = null;
        var isDrillDownEnable = true;
        var isViewDataEnable = true;
        var isExportChartEnable = false;
        var isFilterEnable = true;
        var isDrillThroughEnable = false;
        var chart;
        var isDirect = 0;
        var isStoredProcedure = 0;
        var colorThemesArray = ChartInfo.functions.getColorThemes();
        if(chartSettings.numberformat==undefined){
            var numberformat={};
            numberformat.decimals="0_2";
            chartSettings["numberformat"]=numberformat;
            }
         var xaxisNameArray=xaxisName.split(",");
         var xaxisDataTypes=xaxisDataType.split(",");
         var tooltipprefix="",tooltipsuffix="";
         var chartnumber = chartId.replace("chart", "");
         that.chartnumber = chartnumber;
						if ($('.tileview[data-num='+chartnumber+']').length===1){
							var setheight = $('.tileview[data-num='+chartnumber+']').height() - 42;
							$("#chart"+chartnumber).height(setheight);
						}
						
						var bgcolor = $('.maxanim7[data-num='+chartnumber+']').css('background-color');
						if(bgcolor==undefined || bgcolor==='rgba(0, 0, 0, 0)'){bgcolor='rgba(255, 255, 255, 1)'}
						
						sessionStorage.setItem('pos',position);
						isExportChartEnable = ChartUtil.checkExportChartEnable(features,position,isSharedReport);
						var map;
						var finaldata =[];
						if(xaxisNameArray.length==2 && xaxisDataTypes.length==2 && xaxisDataTypes[0]=="number" && xaxisDataTypes[1]=="number" ){
							_.each(data,function(d1,index){
								var newdata = [];
								_.each(d1.data,function(d,i){
							    var ob = {};
							    var latlang=d.name.split(",");
							    ob["lat"] = parseFloat(latlang[0]);
							    ob["lon"] = parseFloat(latlang[1]);
							    ob["value"] = d.y;
							    $.each(tooltipNameArray, function(j) {
									var tooltipvalue = "t"+j;
									var tooltipagg="ta"+j;
							    ob[tooltipagg] = d[tooltipagg];
							    ob[tooltipvalue]= d[tooltipvalue];
							    });
							    //ob["z"] = d.weight;
							    ob["ydatatype"] = d.ydatatype;
							    newdata.push(ob);
                                map = inAll;
							});
                            var themecolor = colorThemesArray[colorThemeIndex][0]
							_.each(newdata,function(d){
							    d.displayname = d1.displayname;
							    d.name = d1.name;
							    d.yaxisname = d1.yaxisname;
							    d.aggType = d1.aggType;
                                d.color = themecolor;
							});
							finaldata = finaldata.concat(newdata);
							});
							}else{
								$.map(data[0].data, function(i, j) {
								var seriesData = {};
								var stateNames = data[0].data[j].name;
								try{
								var stateName = stateNames.toLowerCase().replace(
										/\b\w/g, function(m) {
											return m.toLowerCase();
										})
								}
							    catch(e){
									$(".gridspinner").hide();
								}
							    var stateCode = getIndiaStateName(stateName);
							    if(stateCode != undefined){
									seriesData["hc-key"] = stateCode;
									seriesData["value"] = data[0].data[j].y;
									seriesData["aggType"] = data[0].data[j].aggType;
									seriesData['drilldown']= stateCode;
									seriesData['yaxisname']=data[0].yaxisname;
									seriesData['actualname']=data[0].data[j].name;
									seriesData['name']=data[0].data[j].name;
									$.each(tooltipNameArray,function(index,tooltip){
										seriesData["t"+index]=data[0].data[j]["t"+index];
										seriesData["ta"+index]=data[0].data[j]["ta"+index];
									});
									finaldata.push(seriesData);
                                    map = inAll;
							    }
							});
						}
                        var that = this;
                        function pointOnClick(e,top,left,xDataType,xName,yValue){
							ChartUtil.checkDrillDownEnable(features,position,isSharedReport,iscustomstoryboard);
							ChartUtil.viewDataEnable(features,position,isSharedReport,iscustomstoryboard);
							ChartUtil.checkFilterEnable(features,position,isSharedReport,iscustomstoryboard);
							ChartUtil.checkDrillThroughEnable(features,position,isSharedReport,iscustomstoryboard);
							var isDrillDownVisible="disabled";
							var isDrillDownLabel="disabled";
							if(xaxisDataType=="customrange"){
								isDrillDownEnable=false;
								isKeepOnlyVisible=false;
								isExcludeVisible=false;
							}

							var ydatatype="";
							_.each(yAxisDataTypeArray,function(value){
								if(value=="custommeasurehierarchy"){
									ydatatype=value;
								}
								else if(value=="customaggregatedfield"){
									ydatatype=value;
								}
							})
							if(xaxisDataType=="" || xaxisDataType==undefined || e.point!=undefined && e.point.ydatatype=="custommeasurehierarchy" || xaxisDataType=="customrange"){

								isDrillDownEnable=false;
							}else{
								isDrillDownEnable=true;
							}
							
							if(isDrillDownEnable){
								isDrillDownVisible="pie-drilldown";
								isDrillDownLabel="";
							}

							var isViewDataVisible="disabled";
							var isViewDataLabel="disabled";
							/*if(isViewDataEnable){
								isViewDataVisible="pie-viewdata";
								isViewDataLabel="";
							}*/
							
							//var dataLength=data[0].data.length;
							var isExcludeVisible="disabled";
							var isKeepOnlyLabel="disabled";
							var isKeepOnlyVisible="disabled";
							var isExcludeLabel="disabled";
							/*if(isFilterEnable && dataLength>1){
								isKeepOnlyVisible="keeponly-point";
								isKeepOnlyLabel="";
								isExcludeVisible="exclude-point";
								isExcludeLabel="";
							}*/
							var isydrilldownenable = "disabled";
							var yaxisname = yaxisnameArray[0];
							var drilldownmeasurearray = $.grep(ChartInfo.functions.getMeasureHierarchy(),function(e1){return e1.name == yaxisname});
							var hierarchylist = [];
							/*if(drilldownmeasurearray.length > 0){
								isydrilldownenable = "measuredilldown";
							}*/
							var isDrillThroughVisible="disabled";
							var isDrillThroughLabel="disabled";
							if(xaxisDataType=="customrange"){
								isDrillThroughEnable=false;
							}
							if(isDrillThroughEnable){
								isDrillThroughVisible="pie-drillthrough1";
								isDrillThroughLabel="";
							}
							var isthreedpie;
							if(chartType=="3dpie"){
								isthreedpie = "disabled";
							}
							
								
							if(sessionStorage.getItem("drilldown"+chartnumber)!=null && sessionStorage.getItem("drilldown"+chartnumber)!="null"){
							$('#chart'+chartnumber+'').before('<div class="pie-menu" style="top:'+top+'px;left:'+left+'px;"><div class="boc-pie-menu-start"></div><div class="boc-pie-menu-end"></div><div class="boc-piemenu-close" id="piemenuclose2"><i class="fa fa-times"></i></div>'
										+'<div class="pie-list-wrapper">'
										+'<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut " data-num="'+chartnumber+'"> <div class="boc-pie-menu-center">  </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
										+'<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut '+isViewDataVisible+'" data-num="'+chartnumber+'"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label '+isViewDataLabel+'" id="isviewdatalabel2"><span class="pietooltip">View<br>Data</span><i class="fa fa-table"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
										+'<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut '+isydrilldownenable+'"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label "><span class="pietooltip">Y-Drill<br>Down</span><i class="fa fa-level-down"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
										+'<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut '+isDrillThroughVisible+'" data-num="'+chartnumber+'"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label '+isDrillThroughLabel+'" id="isdrillthroughlabel1""><span class="pietooltip">Drill<br>Through</span><i class="fa fa-level-down fa-flip-horizontal"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
										+'<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut '+isDrillDownVisible+'"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label '+isDrillDownLabel+'" id="isdrilldownlabel2"><span class="pietooltip">Drill<br>Down</span><i class="fa fa-arrow-down"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
										+'<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut " data-num="'+chartnumber+'"> <div class="boc-pie-menu-center">  </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
										/*+'<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut"> <div class="boc-pie-menu-center">  </div></div>'	*/														
										+'<span class="piemenuwrapper"></span>'
										+'</div>'
										+'<span class="wrapper"></span>'
								);
							}
							else{
								$('#chart'+chartnumber+'').before('<div class="pie-menu" style="top:'+top+'px;left:'+left+'px;"><div class="boc-pie-menu-start"></div><div class="boc-pie-menu-end"></div><div class="boc-piemenu-close" id="piemenuclose2"><i class="fa fa-times"></i></div>'
										+'<div class="pie-list-wrapper">'
										+'<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut '+isKeepOnlyVisible+'" data-num="'+chartnumber+'"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label keeponly '+isKeepOnlyLabel+'" id="iskeeponlylabel2"><span class="pietooltip"> '+lang["storyboard.keep"]+' <br>'+lang["storyboard.only"]+'</span><i class="fa fa-check"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
										+'<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut '+isViewDataVisible+'" data-num="'+chartnumber+'"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label '+isViewDataLabel+'" id="isviewdatalabel2"><span class="pietooltip">'+lang["storyboard.view"]+'<br>'+lang["storyboard.data"]+'</span><i class="fa fa-table"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
										+'<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut '+isydrilldownenable+'"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label"><span class="pietooltip">'+lang["storyboard.y-drill"]+'<br>'+lang["storyboard.down"]+'</span><i class="fa fa-level-down"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
										+'<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut '+isDrillThroughVisible+'" data-num="'+chartnumber+'"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label '+isDrillThroughLabel+'" id="isdrillthroughlabel1""><span class="pietooltip">'+lang["storyboard.drill"]+'<br>'+lang["storyboard.through"]+'</span><i class="fa fa-level-down fa-flip-horizontal"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
										+'<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut '+isDrillDownVisible+'"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label '+isDrillDownLabel+'" id="isdrilldownlabel2"><span class="pietooltip">'+lang["storyboard.drill"]+'<br>'+lang["storyboard.down"]+'</span><i class="fa fa-arrow-down"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
										+'<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut '+isExcludeVisible+'" data-num="'+chartnumber+'"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label excludeonly '+isExcludeLabel+'" id="isexcludelabel2"><span class="pietooltip">'+lang["storyboard.exclude"]+'</span><i class="fa fa-times"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
										/*+'<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut"> <div class="boc-pie-menu-center">  </div></div>'*/
										+'<span class="piemenuwrapper"></span>'
										+'</div>'
										+'<span class="wrapper"></span>'
								);
							}
							setTimeout(function(){
								$('.boc-pie-menu-start, .boc-pie-menu-end, .boc-pie-menu-filter').toggleClass("openpie");
							},200);
							$('.boc-piemenu-close, .piemenuwrapper').on("click",function(){
								$('.boc-pie-menu-start, .boc-pie-menu-end, .boc-pie-menu-filter').toggleClass("openpie");
								setTimeout(function(){
									$('.pie-menu').remove();
								},800);
							});
							$('.wrapper').on("click",function(){
								$('.pie-menu').remove();
							});
							var filterArray=[];
							$('.pie-drillthrough1').on("click",function(e){
								$('.maxanim7[data-num='+chartnumber+'] .wrapper').css("z-index","99");
								var top  = e.pageY;
								var left = e.pageX;
								var $this = e.currentTarget;
								var that=this;
								// require(["views/viewreport/drillthroughoptionsview"],function(DrillThroughOptionsView){
								// 	new DrillThroughOptionsView({
								// 		currentChartNumber :chartnumber ,
								// 		postop:top,
								// 		posleft:left
								// 	});
								// })
								if(tooltipNameArray!=undefined && tooltipNameArray.length!=0){
									if(xaxisidentity.length!=2){
										var filterObj={};
										filterObj["columnname"]=xName
										filterObj["columndisplayname"]=xName
										filterObj["data"]= yValue.name
										filterObj["dataType"]= yValue.ydatatype									
										filterArray[0]=filterObj;
									
									}
									else if(xaxisidentity.split(",")[0]=="latitude"){
										var filterObj={};
											filterObj["columnname"]=xName.split(",")[0];
											filterObj["columndisplayname"]=xName.split(",")[0];
											filterObj["data"]= e.target._popup._content.split('<b>')[3].split(':')[1].split(",")[0].split("<br>")[0].trim();
											filterArray[0]=filterObj;
											filterObj={};
											filterObj["columnname"]=xName.split(",")[1];
											filterObj["columndisplayname"]=xName.split(",")[1];
											filterObj["data"]=e.target._popup._content.split('<b>')[3].split(':')[1].split(",")[0].split("<br>")[1].trim();
											filterArray[1]=filterObj;
									}
									else if(xaxisidentity.split(",")[0]=="longitude"){
										var filterObj={};
										filterObj["columnname"]=xName.split(",")[0];
										filterObj["columndisplayname"]=xName.split(",")[0];
										filterObj["data"]= e.target._popup._content.split('<b>')[3].split(':')[1].split(",")[0].split("<br>")[1].trim();										
										filterArray[0]=filterObj;
										filterObj={};
										filterObj["columnname"]=xName.split(",")[1];
										filterObj["columndisplayname"]=xName.split(",")[1];
										filterObj["data"]=e.target._popup._content.split('<b>')[3].split(':')[1].split(",")[0].split("<br>")[0].trim();
										filterArray[1]=filterObj;
									}
									
									
								}else{
									var filterObj={};
									if(xaxisidentity.length!=2){
										var filterObj={};
										filterObj["columnname"]=xName
										filterObj["columndisplayname"]=xName
										filterObj["data"]= yValue.name
										filterObj["dataType"]= yValue.ydatatype									
										filterArray[0]=filterObj;
									}
									else if(xaxisidentity.split(",")[0]=="latitude"){
										filterObj["columnname"]=xName.split(",")[0];
										filterObj["columndisplayname"]=xName.split(",")[0];
										filterObj["data"]= e.target._popup._content.split('<br>')[1].split(",")[0].trim();
										filterArray[0]=filterObj;
										filterObj={};
										filterObj["columnname"]=xName.split(",")[1];
										filterObj["columndisplayname"]=xName.split(",")[1];
										filterObj["data"]=e.target._popup._content.split('<br>')[1].split(",")[1].trim();
										filterArray[1]=filterObj;
									}
									else if(xaxisidentity.split(",")[0]=="longitude"){
										filterObj["columnname"]=xName.split(",")[0];
										filterObj["columndisplayname"]=xName.split(",")[0];
										filterObj["data"]= e.target._popup._content.split('<br>')[1].split(",")[1].trim();
										filterArray[0]=filterObj;
										filterObj={};
										filterObj["columnname"]=xName.split(",")[1];
										filterObj["columndisplayname"]=xName.split(",")[1];
										filterObj["data"]=e.target._popup._content.split('<br>')[1].split(",")[0].trim();
										filterArray[1]=filterObj;
									}
								}		
							});
							ChartInfo.functions.setExcludeFilterData(filterArray);
							$('.pie-drilldown').on("click",function(){
								$('.boc-pie-menu-start, .boc-pie-menu-end, .boc-pie-menu-filter').toggleClass("openpie");
								setTimeout(function(){
									$('.pie-menu').remove();
								},800);

								$('.wrapper').on("click",function(){
									$('.pie-menu').remove();
								});
							
						
							var point ={};
							point.name=yaxisnameArray[0];
							point.series={};
							point.series.options={};
							point.series.name=yaxisnameArray[0];
							point.options = yValue;
							point.series.options = yValue;
							point.series.options.yaxisname=yaxisnameArray[0];
							var value=yValue.lat;
							/*if(xaxisidentity.split(",").length==2 && tooltipNameArray!=undefined && tooltipNameArray.length!=0){
								if(xaxisidentity.split(",")[0]=="latitude"){
									value = e.target._popup._content.split('<b>')[3].split(':')[1].split(",")[0].split("<br>")[0]+","+e.target._popup._content.split('<b>')[3].split(':')[1].split(",")[0].split("<br>")[1]								
								}else if(xaxisidentity.split(",")[0]=="longitude"){
									value = e.target._popup._content.split('<b>')[3].split(':')[1].split(",")[0].split("<br>")[1]								
								}
							}else{
								
								if(xaxisidentity.split(",")[0]=="latitude"){
									value=e.target._popup._content.split('<br>')[1].split(",")[0];								
								}
								else if(xaxisidentity.split(",")[0]=="longitude"){
									value =value=e.target._popup._content.split('<br>')[1].split(",")[1];								
								}					
							}*/
							if(iscustomstoryboard){
								columnsData=ChartInfo.functions.getCustomstroyboardReportColumns(chartnumber);
							}
							viewMapDrillDownModal = new ViewMapdrilldownmodal({
								chartId : chartId,
								chartnumber : chartnumber,
								columns : JSON.stringify(columnsData),
								xaxisName :xaxisColumnName.split(",")[0], //e.target._popup._content.split('<br>')[0],
								xaxisDisplayName:xName.split(",")[0],//e.target._popup._content.split('<br>')[0],
								xaxisType : xDataType.split(",")[0],
								value:value,
								chart : charts["chart"+ chartnumber],
								point : point,
								pos:position,
								features:features,
								isSharedReport:isSharedReport,
								colorThemeIndex:colorThemeIndex,
								chartSettings:chartSettings,
								tooltipNameArray:tooltipNameArray,
								trendstats:trendstats,
								iscustomstoryboard:iscustomstoryboard,
								xaxisidentity:xaxisidentity,
								chartutils:that,
								displayName:xName.split(",")[0],
								ismultifact: ismultifact,
	                            multifactentities : multifactentities,
	                            datamodelId:datamodelId,
	                            facttables:facttables,
	                            yaxisnameArray:yaxisnameArray
								});
							});
						}
                        var options = {
                            lang: {
                                drillUpText: '\u25c1 Back to map'
                            },
                            chart: {
                                events: {
                                    click: function (event) {
                                        var $this = event.currentTarget;
                                        if (event.target.hasAttribute('href')) {
                                            var bgcolor = $('.maxanim7[data-num=' + chartnumber + ']').css('background-color');
                                            if (bgcolor == undefined || bgcolor === 'rgba(0, 0, 0, 0)') { bgcolor = 'rgba(255, 255, 255, 1)' }
                                            this.options.exporting.chartOptions.chart.backgroundColor = bgcolor;
                                        }
                                    },
                                    drillup: function (e) {
                                        var chartId = e.target.userOptions.chart.renderTo;
                                        var chartnumber = chartId.replace("chart",
                                            "");
                                        viewChartDrillDownModal.drillup(chartId,
                                            chartnumber, charts["chart"
                                            + chartnumber]);
                                        ChartUtil.navCorrection(e);
                                    },
                                    drilldown: function (e) {
                                        isDrillDownEnable = ChartUtil.checkDrillDownEnable(features, position, isSharedReport, iscustomstoryboard);
                                        isViewDataEnable = ChartUtil.viewDataEnable(features, position, isSharedReport, iscustomstoryboard);
                                        isFilterEnable = ChartUtil.checkFilterEnable(features, position, isSharedReport, iscustomstoryboard);
                                        isDrillThroughEnable = ChartUtil.checkDrillThroughEnable(features, position, isSharedReport, iscustomstoryboard);
                                        /*if(iscustomstoryboard){
                                        }*/
                                        if (!e.seriesOptions) {
            
                                            var chartId = e.target.userOptions.chart.renderTo;
                                            var chartnumber = chartId.replace(
                                                "chart", "");
                                            chartPoints[chartnumber] = e.point;
                                            var jsonobj;
                                            var jsonArray = [];
                                            var instantFilterArray = [];
                                            if (legend != undefined && legend.columnName.length > 0) {
                                                jsonobj = {}
                                                jsonobj["columnname"] = legend.columnName;
                                                jsonobj["dataType"] = legend.dataType;
                                                jsonobj["data"] = chartPoints[chartnumber].series.name.split('[')[0];
                                                jsonobj["columndisplayname"] = legend.displayName;
                                                jsonobj["isdrilldownfilter"] = true;
                                                var rangeObject = $.grep(ChartInfo.functions.getRange(), function (e) { return e.name == legend.columnName });
                                                if (rangeObject.length > 0) {
                                                    jsonobj["columnname"] = rangeObject[0]["xAxisName"][0].columnName;
                                                }
                                                jsonArray.push(jsonobj);
                                            }
                                            jsonobj = {};
                                            jsonobj["columnname"] = e.target.userOptions.xAxis[0].title.orginaltextName;
                                            //											jsonobj["dataType"]=e.target.userOptions.xAxis[0].type;
                                            jsonobj["dataType"] = (e.target.userOptions.xAxis[0].ctype == undefined) ? "string" : e.target.userOptions.xAxis[0].ctype;
                                            var rangeObject = $.grep(ChartInfo.functions.getRange(), function (e) { return e.name == jsonobj["columnname"] });
                                            if (rangeObject.length > 0) {
                                                jsonobj["columnname"] = rangeObject[0]["xAxisName"][0].columnName;
                                            }
                                            if (e.point.actualname == undefined) {
                                                e.point.actualname = e.point.name;
                                            }
                                            var value = e.point.actualname;
                                            if (jsonobj["dataType"] == "date") {
                                                if (value.length > 0) {
                                                    var newDate = value;//Date.parse(value.replace(/-/g,'/'));
                                                    if (isNaN(newDate)) {
                                                        value = value;
                                                    }
                                                    else {
                                                        value = newDate;//new Date(newDate).getTime();
                                                    }
                                                }
                                            }
                                            jsonobj["data"] = value;
                                            jsonobj["isdrilldownfilter"] = true;
                                            jsonobj["columndisplayname"] = e.target.userOptions.xAxis[0].title.text;
                                            jsonArray.push(jsonobj);
                                            sessionStorage.setItem('datatable_popup_filterdata', JSON.stringify(jsonArray));
                                            ChartInfo.functions.setInstanceIncludeFilterDataForDrillDown(chartnumber, jsonArray);
                                            instantFilterArray.push(jsonobj);
                                            _.each(instantFilterArray, function (data, i) {
                                                data["data"] = e.point.actualname;
                                                if (ChartInfo.functions.getExcludeFilterData() != undefined && ChartInfo.functions.getExcludeFilterData().length != 0) {
                                                    _.each(ChartInfo.functions.getExcludeFilterData(), function (ExcludeFilterData, j) {
                                                        ChartInfo.functions.setExcludeFilterData(instantFilterArray);
                                                    });
                                                }
                                                else {
                                                    ChartInfo.functions.setExcludeFilterData(instantFilterArray);
                                                }
            
                                            });
            
                                            var top = this.pointer.mouseDownY;
                                            var left = this.pointer.mouseDownX + 16;
                                            var chartwidth = this.pointer.chart.chartWidth - 160;
                                            var chartheight = this.pointer.chart.chartHeight - 130;
                                            if (top > chartheight) {
                                                top = chartheight;
                                            }
                                            if (left > chartwidth) {
                                                left = chartwidth;
                                            }
                                            $('#chart' + chartnumber + ' .highcharts-container .pie-menu').remove();
                                            $('.pie-menu').remove();
            
                                            var isDrillDownVisible = "disabled";
                                            var isDrillDownLabel = "disabled";
                                            if (xaxisDataType == "customrange") {
                                                isDrillDownEnable = false;
                                                isKeepOnlyVisible = false;
                                                isExcludeVisible = false;
                                            }
            
                                            if ((xaxisDataType == "" || xaxisDataType == undefined || xaxisColumnName == "Measure Names") || xaxisDataType == "customrange") {
                                                isDrillDownEnable = false;
                                            }
            
                                            if (isDrillDownEnable) {
                                                isDrillDownVisible = "pie-drilldown";
                                                isDrillDownLabel = "";
                                            }
            
                                            var isViewDataVisible = "disabled";
                                            var isViewDataLabel = "disabled";
                                            if (isViewDataEnable) {
                                                isViewDataVisible = "pie-viewdata";
                                                isViewDataLabel = "";
                                            }
            
                                            var isDrillThroughVisible = "disabled";
                                            var isDrillThroughLabel = "disabled";
                                            if (xaxisDataType == "customrange") {
                                                isDrillThroughEnable = false;
                                            }
                                            if (isDrillThroughEnable) {
                                                isDrillThroughVisible = "pie-drillthrough";
                                                isDrillThroughLabel = "";
                                            }
            
                                            var dataLength = data[0].data.length;
                                            var isExcludeVisible = "disabled";
                                            var isKeepOnlyLabel = "disabled";
                                            var isKeepOnlyVisible = "disabled";
                                            var isExcludeLabel = "disabled";
                                            if (isFilterEnable && dataLength > 1 && xaxisDataType !== "customrange") {
                                                isKeepOnlyVisible = "keeponly-point";
                                                isKeepOnlyLabel = "";
                                                isExcludeVisible = "exclude-point";
                                                isExcludeLabel = "";
                                            }
                                            var isydrilldownenable = "disabled";
                                            var yaxisname = e.point.name;
                                            var drilldownmeasurearray = $.grep(ChartInfo.functions.getMeasureHierarchy(), function (e1) { return e1.name == yaxisname });
                                            var hierarchylist = [];
                                            if (drilldownmeasurearray.length > 0) {
                                                isydrilldownenable = "measuredilldown";
                                            }
            
                                            var isthreedpie;
                                            if (chartType == "3dpie") {
                                                isthreedpie = "disabled";
                                            }
                                            //if(isDrillDownEnable || isViewDataEnable || isFilterEnable){
                                            if (sessionStorage.getItem("drilldown" + chartnumber) != null && sessionStorage.getItem("drilldown" + chartnumber) != "null") {
                                                $('#chart' + chartnumber + '').before('<div class="pie-menu" style="top:' + top + 'px;left:' + left + 'px;"><div class="boc-pie-menu-start"></div><div class="boc-pie-menu-end"></div><div class="boc-piemenu-close"><i class="fa fa-times"></i></div>'
                                                    + '<div class="pie-list-wrapper">'
                                                    + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut " data-num="' + chartnumber + '"> <div class="boc-pie-menu-center">  </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                                    + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isViewDataVisible + '" data-num="' + chartnumber + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label ' + isViewDataLabel + '"><span class="pietooltip">View<br>Data</span><i class="fa fa-table"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                                    + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isydrilldownenable + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label"><span class="pietooltip">Y-Drill<br>Down</span><i class="fa fa-level-down"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                                    + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isDrillThroughVisible + '" data-num="' + chartnumber + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label ' + isDrillThroughLabel + '" id="isdrillthroughlabel1""><span class="pietooltip">Drill<br>Through</span><i class="fa fa-level-down fa-flip-horizontal"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                                    + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isDrillDownVisible + ' ' + isthreedpie + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label ' + isDrillDownLabel + '"><span class="pietooltip">Drill<br>Down</span><i class="fa fa-arrow-down"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                                    + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut "data-num="' + chartnumber + '"> <div class="boc-pie-menu-center">  </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                                    /*+'<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut"> <div class="boc-pie-menu-center">  </div></div>'*/
                                                    + '<span class="piemenuwrapper"></span>'
                                                    + '</div>'
                                                    + '<span class="wrapper"></span>'
                                                );
                                            }
                                            else {
                                                $('#chart' + chartnumber + '').before('<div class="pie-menu" style="top:' + top + 'px;left:' + left + 'px;"><div class="boc-pie-menu-start"></div><div class="boc-pie-menu-end"></div><div class="boc-piemenu-close"><i class="fa fa-times"></i></div>'
                                                    + '<div class="pie-list-wrapper">'
                                                    + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isKeepOnlyVisible + '" data-num="' + chartnumber + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label keeponly ' + isKeepOnlyLabel + '"><span class="pietooltip"> '+lang["storyboard.keep"]+' <br>'+lang["storyboard.only"]+'</span><i class="fa fa-check"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                                    + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isViewDataVisible + '" data-num="' + chartnumber + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label ' + isViewDataLabel + '"><span class="pietooltip">'+lang["storyboard.view"]+'<br>'+lang["storyboard.data"]+'</span><i class="fa fa-table"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                                    + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isydrilldownenable + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label"><span class="pietooltip">'+lang["storyboard.y-drill"]+'<br>'+lang["storyboard.down"]+'</span><i class="fa fa-level-down"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                                    + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isDrillThroughVisible + '" data-num="' + chartnumber + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label ' + isDrillThroughLabel + '" id="isdrillthroughlabel1""><span class="pietooltip">'+lang["storyboard.drill"]+'<br>'+lang["storyboard.through"]+'</span><i class="fa fa-level-down fa-flip-horizontal"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                                    + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isDrillDownVisible + ' ' + isthreedpie + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label ' + isDrillDownLabel + '"><span class="pietooltip">'+lang["storyboard.drill"]+'<br>'+lang["storyboard.down"]+'</span><i class="fa fa-arrow-down"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                                    + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isExcludeVisible + '" data-num="' + chartnumber + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label excludeonly ' + isExcludeLabel + '"><span class="pietooltip">'+lang["storyboard.exclude"]+'</span><i class="fa fa-times"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                                    /*+'<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut"> <div class="boc-pie-menu-center">  </div></div>'*/
                                                    + '<span class="piemenuwrapper"></span>'
                                                    + '</div>'
                                                    + '<span class="wrapper"></span>'
                                                );
                                            }
            
                                            //}
                                            setTimeout(function () {
                                                $('.boc-pie-menu-start, .boc-pie-menu-end, .boc-pie-menu-filter').toggleClass("openpie");
                                            }, 200);
                                            $('.boc-piemenu-close, .piemenuwrapper').on("click", function () {
                                                $('.boc-pie-menu-start, .boc-pie-menu-end, .boc-pie-menu-filter').toggleClass("openpie");
                                                setTimeout(function () {
                                                    $('.pie-menu').remove();
                                                }, 800);
                                            });
                                            $('.wrapper').on("click", function () {
                                                $('.pie-menu').remove();
                                            });
                                            $('.pie-drilldown').on("click", function () {
                                                $('.boc-pie-menu-start, .boc-pie-menu-end, .boc-pie-menu-filter').toggleClass("openpie");
                                                setTimeout(function () {
                                                    $('.pie-menu').remove();
                                                }, 800);
                                                if (iscustomstoryboard) {
                                                    columnsData = ChartInfo.functions.getCustomstroyboardReportColumns(chartnumber);
                                                }
                                                charts["chart" + chartnumber] = e.target;
                                                chartPoints[chartnumber] = e.point;
                                                viewChartDrillDownModal = new Viewchartdrilldownmodal(
                                                    {
                                                        chartId: chartId,
                                                        chartnumber: chartnumber,
                                                        columns: JSON.stringify(columnsData),
                                                        xaxisName: e.target.userOptions.xAxis[0].title.orginaltextName,
                                                        xaxisDisplayName: e.target.userOptions.xAxis[0].title.text,
                                                        displayName: e.target.userOptions.xAxis[0].title.displayname,
                                                        xaxisType: e.target.userOptions.xAxis[0].ctype,
                                                        value: e.point.actualname,
                                                        chart: charts["chart"
                                                            + chartnumber],
                                                        point: chartPoints[chartnumber],
                                                        pos: position,
                                                        iscuststroyboard: iscustomstoryboard,
                                                        ismultifact: ismultifact,
                                                        multifactentities: multifactentities,
                                                        yaxisnameArray: yaxisnameArray,
                                                        datamodelId: datamodelId,
                                                        facttables: facttables,
                                                        drilldownyAxisSeries: yaxisname
                                                    });
                                            });
                                            $('.measuredilldown').on("click", function (event) {
                                                event.preventDefault();
                                                $('.boc-pie-menu-start, .boc-pie-menu-end, .boc-pie-menu-filter').toggleClass("openpie");
                                                setTimeout(function () {
                                                    $('g.highcharts-tooltip').css('opacity', '1');
                                                    $('.pie-menu').remove();
                                                }, 800);
                                                charts["chart" + chartnumber] = e.target;
                                                chartPoints[chartnumber] = e.point;
                                                viewChartDrillDownModal = new Viewchartdrilldownmodal(
                                                    {
                                                        chartId: chartId,
                                                        chartnumber: chartnumber,
                                                        columns: JSON.stringify(columnsData),
                                                        xaxisName: e.target.userOptions.xAxis[0].title.orginaltextName,
                                                        xaxisDisplayName: e.target.userOptions.xAxis[0].title.text,
                                                        displayName: e.target.userOptions.xAxis[0].title.displayname,
                                                        xaxisType: e.target.userOptions.xAxis[0].ctype,
                                                        value: e.point.actualname,
                                                        chart: charts["chart"
                                                            + chartnumber],
                                                        point: chartPoints[chartnumber],
                                                        pos: position,
                                                        drilldownType: "measuredrilldown",
                                                        iscuststroyboard: iscustomstoryboard,
                                                        ismultifact: ismultifact,
                                                        multifactentities: multifactentities,
                                                        yaxisnameArray: yaxisnameArray,
                                                        datamodelId: datamodelId,
                                                        facttables: facttables,
                                                        drilldownyAxisSeries: yaxisname
                                                    });
                                            });
                                            $('.pie-viewdata').on("click", function (e) {
                                                e.preventDefault();
                                                PubSub.publish('viewDataTablePopUp', e);
                                                setTimeout(function () {
                                                    $('g.highcharts-tooltip').css('opacity', '1');
                                                }, 1000);
                                            });
                                            $('.exclude-point').on("click", function (e) {
                                                e.preventDefault();
                                                PubSub.publish('exclude-point', e);
                                            });
                                            $('.keeponly-point').on("click", function (e) {
                                                e.preventDefault();
                                                PubSub.publish('keeponly-point', e);
                                            });
                                            $('.pie-drillthrough').on("click", function (e) {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                PubSub.publish('pie-drillthrough', e);
                                            });
                                        }
            
                                    }
                                },
                                map : map != undefined && map.default != undefined ? map.default : "",
                                renderTo: chartId,
                                reportId : reportid,
                                //backgroundColor: 'transparent',
                                animation:false,
                                className:'IndiaMap'
                            },
                            subtitle: {
                                text: null
                            },
                            title: {
                                text: ''
                            },
                            mapNavigation: {
                                enabled: false,
                                buttonOptions: {
                                    verticalAlign: 'bottom'
                                }
                            },
                            legend: {
                                layout: 'vertical',
                                align: 'left',
                                verticalAlign: 'bottom',
                                itemStyle: {},
                                enabled: false
                            },
                            colorAxis: {
                                enabled: false,
                                min: 0,
                                stops: [[0, colorThemesArray[colorThemeIndex][0]], [0.5, colorThemesArray[colorThemeIndex][1]], [1, colorThemesArray[colorThemeIndex][2]]]
                            },
                            subtitle : {
                                text : null
                            },
                            title : {
                                text : ''
                            },
                             mapNavigation: {
                                    enabled: false,
                                    buttonOptions: {
                                        verticalAlign: 'bottom'
                                    }
                                },
                            legend : {
                                layout : 'vertical',
                                align : 'left',
                                verticalAlign : 'bottom',
                                itemStyle: {},
                                     enabled: false
                            },
                            colorAxis : {
                                enabled: false,
                                min : 0,
                                stops : [ [ 0, colorThemesArray[colorThemeIndex][0] ], [ 0.5, colorThemesArray[colorThemeIndex][1] ],[ 1, colorThemesArray[colorThemeIndex][2] ] ]
                            },
                        drilldown: {
                            activeDataLabelStyle: {
                                color: '#1b1a1a',
                                textDecoration: 'none',
                                textOutline: '1px #000000'
                            },
                            drillUpButton: {
                                relativeTo: 'spacingBox',
                                position: {
                                    x: -30,
                                    y: 2
                                },
                                theme: {
                                    width: 80,
                                    height: 6,
                                    class: "highcharts-drillup-button",
                                    'stroke-width': 1,
                                    stroke: '#e8eaf1',
                                    r: 0,
                                    fill: '#fff',
                                    cursor: 'pointer',
                                    states: {
                                        hover: {
                                            fill: '#f9f9fd',
                                            stroke: '#e8eaf1'
                                        },
                                        select: {
                                            fill: '#f9f9fd',
                                            stroke: '#e8eaf1'
                                        }
                                    }
                                },
                                useHTML: true
                            }
                        },
                        plotOptions: {
                            map: {
                                states: {
                                    hover: {
                                        color: '#BADA55'
                                    }
                                }
                            },
                            series:{
                                dataLabels: {
                                    enabled: false,
                                    useHTML : true,
                                    style: {color:'#1b1a1a',textShadow:'1px 1px 1px #fff',textOutline:'1px #000000'},
                                    formatter : function() {
                                        var num = ChartInfo.functions.addFormatting(this.point.value, chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator)
                                        if (this.point.value != null && this.point.name != undefined)
                                    var label = '<b>' + this.point.name +'</b>:' + num;
                                    return label;
                                    //var num=ChartInfo.addFormatting(this.point.value,chartSettings.numberformat.abbrevation,chartSettings.numberformat.decimals,chartSettings.numberformat.seperator)
                                        // if(this.point.name!=undefined)
                                        // return  this.point.name;
                                        }
                                }
                            }
                        },
                            tooltip: {
                                formatter : function() {
                                    $("#chart"+chartnumber+" .highcharts-tooltip>span").css({"background-color":chart.tooltip.options.backgroundColor, "borderStyle":"solid" ,"borderWidth":"0", "border-color":chart.tooltip.options.borderColor});
                                    if($("#chart"+chartnumber+" .highcharts-tooltip>span").length==0){
                                        setTimeout(function(){$("#chart"+chartnumber+" .highcharts-tooltip>span").css({"background-color":chart.tooltip.options.backgroundColor, "borderStyle":"solid" ,"borderWidth":"0", "border-color":chart.tooltip.options.borderColor});},200)
                                    }
                                    var num=ChartInfo.functions.addFormatting(this.point.value,chartSettings.numberformat.abbrevation,chartSettings.numberformat.decimals,chartSettings.numberformat.seperator)
                                    if(this.point.lat != undefined && this.point.lon != undefined){
                                    var name='<span style="font-size:10px"><b>'+'Lat'+'</b>:'+this.point.lat+'<b>'+'  Lon'+'</b>:'+this.point.lon+'</span><table>';
                                    }else{
                                    var name='<span style="font-size:10px">'+this.key+'</span><table>';	
                                    }
                                    name += '<tr><td style="color:'+this.series.color+';padding:0">'+this.point.yaxisname+' <span class=bocaggtype>[';
                                    name += this.point.aggType=='customaggregatedfield'?'raw':this.point.aggType+'] </span>' + ': </td>';
                                    name += '<td style="padding:0"><b>'+ num+'</b></td></tr>';
                                    
                                    var that = this;
                                    if (this.series.type == 'map' || this.series.type == 'mappoint') {
                                    if (tooltipNameArray.length !== 0){
                                        $.each(tooltipNameArray, function(i) {
                                            var tooltipnow = tooltipNameArray[i];
                                            var tooltipvalue = "t"+i;
                                            var tooltipagg="ta"+i;
                                            name +='<tr><td style="color:'+that.series.color+';padding:0">'+tooltipnow+' <span class=bocaggtype>[';
                                            name +=that.point[tooltipagg]=='customaggregatedfield'?'raw':that.point[tooltipagg]+'] </span>' + ': </td>';
                                            if(that.point[tooltipvalue]==0){
                                                name += '<td style="padding:0"><b>'+that.point[tooltipvalue]+'</b></td></tr>'
                                            }else{
                                                var num=ChartInfo.functions.addFormatting(that.point[tooltipvalue],chartSettings.numberformat.abbrevation,chartSettings.numberformat.decimals,chartSettings.numberformat.seperator)
                                                name += '<td style="padding:0"><b>'+ num+'</b></td></tr>'
                                            }
                                        });
                                    }
                                }else{
                                    name = '<span style="font-size:10px">' + this.key + '</span><table>'
                            name += '<tr><td style="color:' + this.color + ';padding:0">' + this.series.name + ' <span class=bocaggtype>[' + this.series.options.aggType + '] </span>' + ':&nbsp; </td>'
                            if (this.point.ydatatype == 'date' && this.series.options.aggType.toLowerCase().indexOf("count") < 0) {
                                var date = new Date(this.y);
                                name += '<td style="padding:0"><b>' + date.toLocaleString() + '</b></td></tr>';
                            }
                            else if (this.y != 0 && this.y != undefined && this.y != null) {
                                var num = ChartInfo.functions.addFormatting(this.y, chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator)
                                name += '<td style="padding:0"><b>' + tooltipprefix + num + tooltipsuffix + '</b></td></tr>';
                            }
                            else {
                                name += '<td style="padding:0"><b>' + tooltipprefix + this.y + tooltipsuffix + '</b></td></tr>';
                            }
                            var that = this;
                            if (tooltipNameArray.length !== 0) {
                                $.each(tooltipNameArray, function (i) {
                                    var tooltipnow = tooltipNameArray[i];
                                    var tooltipvalue = "t" + i;
                                    var tooltipagg = "ta" + i;
                                    name += '<tr><td style="color:' + that.series.color + ';padding:0">' + tooltipnow + ' <span class=bocaggtype>[';
                                    name += that.point[tooltipagg] == 'customaggregatedfield' ? 'raw' : that.point[tooltipagg] + '] </span>' + ': </td>';
                                    if (that.point[tooltipvalue] == 0) {
                                        name += '<td style="padding:0"><b>' + that.point[tooltipvalue] + '</b></td></tr>'
                                    } else {
                                        var num = ChartInfo.functions.addFormatting(that.point[tooltipvalue], chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator)
                                        name += '<td style="padding:0"><b>' + num + '</b></td></tr>'
                                    }
                                });
                            }
                                }
                                    return name;
                                    },
                                    shared : false,
                                    useHTML : true
                            },
                            xAxis : [ {
                                ctype: xaxisDataType,
                                type : "category",
                                title : {
                                    text : xaxisName,
                                    orginaltextName:xaxisColumnName,
                                    displayname:xaxisName,
                                    style: {}
                                },
                                labels : {
                                    style: {}
                                },
                                visible:false
                            } ],
                            boost: {
                                enabled:false
                            },
                            exporting: {
                                enabled: false,
                                buttons: {
                                    contextButton: {
                                        y: 2,
                                        symbol: 'download',
                                        symbolFill:"#fff",
                                        symbolStrokeWidth:0,
                                        theme:{
                                            fill:'#ffffff',
                                            padding:5,
                                            stroke:'none',
                                            cursor:'pointer'
                                        },
                                        menuItems: [{
                                            textKey: 'printChart',
                                            onclick: function () {
                                                //console.log("printchart");
                                                var chartId = this.userOptions.chart.renderTo;
                                                var reportId = this.userOptions.chart.reportId;
                                                var msg = "Chart printed"
                                                if (reportId != undefined) {
                                                    ChartUtil.auditMessage(reportId, msg);
                                                }
                                                this.print();
                                            }
                                        }, {
                                            separator: true
                                        }, {
                                            textKey: 'downloadPNG',
                                            onclick: function () {
                                                //console.log("downloadPNG");
                                                var chartId = this.userOptions.chart.renderTo;
                                                var reportId = this.userOptions.chart.reportId;
                                                var msg = "Chart downloaded in PNG format"
                                                if (reportId != undefined) {
                                                    ChartUtil.auditMessage(reportId, msg);
                                                }
                                                this.exportChart();
                
                                            }
                                        }, {
                                            textKey: 'downloadJPEG',
                                            onclick: function () {
                                                //console.log("downloadJPEG");
                                                var chartId = this.userOptions.chart.renderTo;
                                                var reportId = this.userOptions.chart.reportId;
                                                var msg = "Chart downloaded in JPEG format";
                                                if (reportId != undefined) {
                                                    ChartUtil.auditMessage(reportId, msg);
                                                }
                                                this.exportChart({
                                                    type: 'image/jpeg'
                                                });
                                            }
                                        }, {
                                            separator: true
                                        }, {
                                            textKey: 'downloadPDF',
                                            onclick: function () {
                                                //console.log("downloadPDF");
                                                var chartId = this.userOptions.chart.renderTo;
                                                var reportId = this.userOptions.chart.reportId;
                                                var msg = "Chart downloaded in PDF format";
                                                if (reportId != undefined) {
                                                    ChartUtil.auditMessage(reportId, msg);
                                                }
                                                this.exportChart({
                                                    type: 'application/pdf'
                                                });
                                            }
                                        }, {
                                            textKey: 'downloadSVG',
                                            onclick: function () {
                                                //console.log("downloadSVG");
                                                var chartId = this.userOptions.chart.renderTo;
                                                var reportId = this.userOptions.chart.reportId;
                                                var msg = "Chart downloaded in SVG format";
                                                if (reportId != undefined) {
                                                    ChartUtil.auditMessage(reportId, msg);
                                                }
                                                this.exportChart({
                                                    type: 'image/svg+xml'
                                                });
                                            }
                                        }]
                
                                    }
                                },
                                chartOptions: {
                                    chart: {
                                        width: 800,
                                        backgroundColor: bgcolor
                                    }
                                }
                            },
                            credits : {
                                enabled : false
                            },
                            
                            series: [{
                                name: 'Basemap',
                                borderColor: '#606060',
                                nullColor: 'rgba(200, 200, 200, 0.2)',
                                showInLegend: false
                            }, {
                                name: 'Separators',
                                type: 'mapline',
                                color: '#101010',
                                nullColor: '#707070',
                                showInLegend: false,
                                enableMouseTracking: false
                            }, {
                                name: '',
                                data: finaldata,
                                mapData:  map != undefined && map.default != undefined ? map.default : "",
                                joinBy: 'hc-key',
                                point: {
                                    events: {
                                        // On click, look for a detailed map
                                        click: function (event) {
                                            var xName = this.series.chart.xAxis[0].userOptions.title.orginaltextName;
                                            if(xName.split(",").length == 2){
                                            var top = this.series.chart.mouseDownY;
                                            var left = this.series.chart.mouseDownX + 16;
                                            var chartwidth = this.series.chart.chartWidth - 160;
                                            var chartheight = this.series.chart.chartHeight - 130;
                                            if(top > chartheight){
                                                top = chartheight;
                                            }
                                            if(left > chartwidth){
                                                left = chartwidth;
                                            }
                                            var xType = this.series.chart.xAxis[0].userOptions.ctype;
                                            pointOnClick(event,top,left,xType,xName,this.options);
                                            }
                                        }
                                    }
                                }
                            }]
                    };
        if(xaxisNameArray.length==2 && xaxisDataTypes.length==2 && xaxisDataTypes[0]=="number" && xaxisDataTypes[1]=="number" ){
                        options.series[2].type = 'mappoint';
                    }else{
                        options.plotOptions.series.dataLabels.enabled = true;
                    }
                  //options.exporting.enabled = true;
                  if (isExportChartEnable) {
                    options.exporting.enabled = true;
                }
                  Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
                    var path = [
                        // Arrow stem
                        'M', x + w * 0.5, y,
                        'L', x + w * 0.5, y + h * 0.7,
                        // Arrow head
                        'M', x + w * 0.3, y + h * 0.5,
                        'L', x + w * 0.5, y + h * 0.7,
                        'L', x + w * 0.7, y + h * 0.5,
                        // Box
                        'M', x, y + h * 0.9,
                        'L', x, y + h,
                        'L', x + w, y + h,
                        'L', x + w, y + h * 0.9
                    ];
                    return path;
                };  
              var chart = new Highcharts.Map(options);
                    this.state.chart = chart;
                    charts[chartId] = chart;
                    $("#"+chartId+" svg>title").remove();
                    ChartInfo.functions.setChartTypeForMap(chartnumber,undefined);
                    return (
                        <div></div>
                        
                    );    


    }
}