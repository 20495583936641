import React from 'react';
import { lang } from "../../js/helpers/utils/languages/language";
export default class SummaryTableColumnAttributeHtml extends React.Component {
    render() {
        return (
            <li className="boc-list-item singleliitems" title={this.props.columnDisplayName} data-parent={this.props.tableName} data-child={this.props.columnName} data-type={this.props.dataType}>
                <span className="itemtype">
                    {
                        (this.props.dataType === 'number') ? (
                            <>123</>
                        ) : (this.props.dataType === 'date' || this.props.dataType === 'datetime' || this.props.dataType === 'month' || this.props.dataType === 'day') ? (
                            <i className="fa fa-calendar"></i>
                        ) : (this.props.dataType === 'customhierarchy' || this.props.dataType === 'custommeasurehierarchy') ? (
                            <i className="fa fa-list"></i>
                        ) : (<>{lang["storyboard.abc"]}</>)
                    }
                </span>
                <span className="itemname" data-parent={this.props.tableName} data-child={this.props.columnName} data-type={this.props.dataType}>{this.props.columnDisplayName}</span>
                <span className="btn-group pull-right">
                    <button className="btn btn-transparent deleterow" onClick={this.props.remove} type="button"><i className="fa fa-trash"></i></button>
                </span>
            </li>
        );
    }
}