import React from "react";
import _ from 'lodash';
import $ from 'jquery/dist/jquery';
import SendRequest from '../../SendRequest';
import { lang } from "../../js/helpers/utils/languages/language";
import ReactDOM from "react-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import CreateUpdateAlerts from './updatealertview'
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import PubSub from 'pubsub-js'


const addButtonEdit = (cell, row) => {
    return (
        <div>
            <button className="btn btn-transparent btn-sm aledit" id={row.id}><i className="fa fa-pencil"></i></button>
        </div>
    )
}
const addButtonDelete = (cell, row) => {
    return (
        <div>
            <button className="btn btn-transparent btn-sm aldelete" id={row.id}><i className="fa fa-trash"></i></button>
        </div>
    )
}
const columns = [
    { dataField: 'id', text: 'Alert ID', sort: true },
    { dataField: 'name', text: 'Alert Title', sort: true },
    { dataField: 'edit', text: 'Edit', sort: false, formatter: addButtonEdit, headerAlign: 'center', align: 'center' },
    { dataField: 'remove', text: 'Delete', sort: false, formatter: addButtonDelete, headerAlign: 'center', align: 'center' },
];


const RemotePagination = ({ data, page, sizePerPage, onTableChange, totalSize }) => (
    // <div className="notifications-table-container">
        <BootstrapTable
            remote
            keyField="id"
            data={data}
            columns={columns}
            pagination={paginationFactory({ page, sizePerPage, totalSize })}
            onTableChange={onTableChange}
            noDataIndication="No matching records found"
        />
    // </div>
);

export default class CreateAlertView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            sizePerPage: 10,
            jsonArray: props.jsonArray,
            storyboardalertse: props.storyboardalertse

        };
        this.redrawTableEve = PubSub.subscribe('redrawTable', this.redrawTable.bind(this));
        this.alerttableclose = this.alerttableclose.bind(this);
        this.createupdatealert = this.createupdatealert.bind(this);
    }
    
    componentDidMount() {
        var that = this;
        $(".aledit").click(function (e) {
            e.stopPropagation();
            e.preventDefault();
            $(".alertscontainer").empty();
            var $this = e.currentTarget;
            var id = $($this).attr("id");
            let requestHeaders = {
                method: "get",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            SendRequest({
                url: "getalertsinfo",
                queryString: "?alertId=" + id + "&reportId=" + that.props.reportId,
                sucessFunction: response => {
                    var filterid = window.localStorage.getItem("current")!=undefined ? atob(window.localStorage.getItem("current")):"";
					if(filterid!=undefined && filterid!=""){
						response = $.grep(response, function (e) { return e.filterid == filterid });
					}
                    if( $('.alertscontainer')!=undefined &&  $('.alertscontainer').length!=0){
                        $('.alertscontainer').empty();
                    }
                    var today = new Date();
                    var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                    dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                    $('.alertscontainer').append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                    var createUpdateAlerts = new CreateUpdateAlerts({
                        dynamicdiv: dynamicdiv,
                        reportId: that.props.reportId,
                        chartnumber: that.props.chartnumber,
                        id: id,
                        alertdetails: response,
                        features: that.props.features,
                        storyboardalertse: that.props.storyboardalertse
                    });
                },
                rejectFunction: () => { },
                headers: requestHeaders
            });
        });
        $('.aldelete').click(function (e) {
            var $this = e.currentTarget;
            var id = $($this).attr("id");
            var that = this;
            // $('.alertscontainer').hide("slow");
            let requestHeaders = {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            var requestbody = new FormData();
            requestbody.set("alertId", id)
            SendRequest({
                url: "deletealert",
                body: requestbody,
                sucessFunction: (props) => {
                    PubSub.publish('redrawTable');
                    //     $('#alertstable').bootstrapTable('removeByUniqueId', id);
                    ChartInfo.functions.getAlerts();
                    ChartInfo.functions.setAlerts($.grep(ChartInfo.functions.getAlerts(), function (e) { return e.id != id }));
                },
                rejectFunction: this.reportDataError,
                headers: requestHeaders
            });
        });
    }
    redrawTable() {
        var that = this;
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getalertsinfo",
            queryString: "?alertId=&reportId=" + that.props.reportId + "&chartnumber=" + that.props.chartnumber + "&chartType=" + that.props.chartType,
            sucessFunction: response => {
                var filterid = window.localStorage.getItem("current")!=undefined ? atob(window.localStorage.getItem("current")):"";
                if(filterid!=undefined && filterid!=""){
                    response = $.grep(response, function (e) { return e.filterid == filterid });
                }
                that.setState({ jsonArray: response })
                that.componentDidMount();
            },
            rejectFunction: () => { },
            headers: requestHeaders
        });
    }
    render() {
        var filterArray = this.state.jsonArray;
        var chartnumber = this.props.chartnumber;
        var chartType = this.props.chartType;
        var finalJson = [];
        var that = this;
        _.each(filterArray, function (value, i) {
            var singleChartnum = value.chartnumber;
            if (singleChartnum == chartnumber) {
                finalJson.push(value);
            }
        });
        this.state.data = finalJson;
        //   this.$el.html(this.template({ jsonArray: finalJson, chartnumber: this.props.chartnumber, chartType: this.props.chartType, reportId: this.props.reportId }));
        const { data, sizePerPage, page } = this.state;
        return (
            //   <!-- createalertview.js -->
            <div className={`modal fade alertsmodal${chartnumber} in`} chartType={chartType} id="alertsmodal" tabindex="-1" role="dialog" aria-labelledby="alertsmodal" aria-hidden="true" style={{ display: "block" }}>
                <div className="modal-backdrop fade in" onClick={this.alerttableclose}></div>
                <div className="modal-dialog modal-lg modal-dialog-lg-center modal-dialog-center" style={{ zIndex: "1050" }}>
                    <div className="modal-content">
                        <div className="modal-header boc-modal-hf-padding">
                            <button type="button" className="close white" id="createalertclose" onClick={this.alerttableclose} aria-hidden="true" data-dismiss="modal">&times;</button>
                            <h4 className="modal-title"><i className="fa fa-exclamation-triangle"></i> {lang["storyboard.alerts"]}</h4>
                        </div>
                        <div className="modal-body scrollset">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-xs-12 marg-top-10 marg-bot-20">
                                        <legend className="subhead alertheadtitle">
                                            <div className="attraxisnames">{lang["storyboard.alerts"]}</div>
                                            {(chartType != "summarytable" && chartType != "pivot" && chartType != "doby" && chartType != "predictionchart") ? (
                                                <button className="pull-right btn btn-bird btn-sm createalert" onClick={this.createupdatealert} id="createalertclick">
                                                    <i className="fa fa-plus-circle"></i> {lang["storyboard.createalert"]}
                                                </button>
                                            ) : null}
                                        </legend>
                                        <div className="alertscontainer" id="alertscontainer"></div>
                                        <div className="clear"></div>
                                        <div className="notifications-table-container marg-top-10">
                                            <div className="col-xs-12 marg-top-10 nopadding">
                                                {/* <div className="notifications-table-container data-item"> */}
                                                    <RemotePagination
                                                        data={data}
                                                        page={page}
                                                        sizePerPage={sizePerPage}
                                                        totalSize={finalJson.length}
                                                        onTableChange={this.handleTableChange}
                                                    />
                                                {/* </div> */}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="modal-footer boc-modal-hf-padding">
                            <button type="button" className="btn btn-bird" id="alerttableclose" onClick={this.alerttableclose} data-dismiss="modal"><i className="fa fa-times"></i> 
{lang["storyboard.cancelbtn"]}</button>
                        </div>
                    </div>
                    {/* <!-- /.modal-content --> */}
                </div>
                {/* <!-- /.modal-dialog --> */}
            </div>
            //    <!-- /.Page Settings Modal -->
            //    <!-- Storyboard Alerts Modal End -->
        );
    }
    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, data }) => {
        const currentIndex = (page - 1) * sizePerPage;
        {
            type == "sort" ?
                setTimeout(() => {
                    let result;
                    if (sortOrder === 'asc') {
                        result = data.sort((a, b) => {
                            if (a[sortField] > b[sortField]) {
                                return 1;
                            } else if (b[sortField] > a[sortField]) {
                                return -1;
                            }
                            return 0;
                        });
                    } else {
                        result = data.sort((a, b) => {
                            if (a[sortField] > b[sortField]) {
                                return -1;
                            } else if (b[sortField] > a[sortField]) {
                                return 1;
                            }
                            return 0;
                        });
                    }
                    this.setState(() => ({
                        data: result
                    }));
                }, 10) :
                setTimeout(() => {
                    this.setState(() => ({
                        page,
                        data: this.state.data.slice(currentIndex, currentIndex + sizePerPage),
                        sizePerPage
                    }));

                }, 10);
        }
    }
    createupdatealert() {
        var that = this;
        $('.alertscontainer').empty();
        var today = new Date();
        var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
        $('.alertscontainer').append("<div className='" + dynamicdiv + " ' id='" + dynamicdiv + "'></div>");
        var createUpdateAlerts = new CreateUpdateAlerts({
            dynamicdiv: dynamicdiv,
            reportId: that.props.reportId,
            chartnumber: that.props.chartnumber,
            features: that.props.features
        });
    }
    alerttableclose() {
        if(document.getElementById(`append_alertmodal${this.props.chartnumber}`)!=null){
            ReactDOM.unmountComponentAtNode(document.getElementById(`append_alertmodal${this.props.chartnumber}`));
        }
    }

}