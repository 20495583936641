import React, { Component } from 'react'
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import { lang } from "../js/helpers/utils/languages/language";
import ReactDOM from 'react-dom'
const $ = window.$;

export default class AuditMetadataView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.state.chartNumber = this.props.chartNumber;
        this.state.reportId = this.props.reportId;
        this.state.schemaType = this.props.schemaType;
        if (this.state.schemaType != undefined) {
            this.state.schemaType = this.state.schemaType.toLowerCase();
        }
        this.render();
    }
    render() {
        var auditMetaDataOptions = ChartInfo.functions.getAuditMetaDataOptions();
        ReactDOM.render(<AuditMetaDatatemplate changemetadataaudit={this.changemetadataaudit} closeauditmodal={this.closeauditmodal} schemaType={this.state.schemaType} />, document.getElementById("metadataauditview"));
        if (auditMetaDataOptions["metaDataAudit"] == 1) {
            $("#auditmetadatamodel #metadataaudit").prop("checked", true);
        }
        $("#auditmetadatamodel").show();
        $('.spinner').hide();
        return (<div></div>);
    }
    closeauditmodal = () => {
        ReactDOM.unmountComponentAtNode(document.getElementById("metadataauditview"));
    }
    changemetadataaudit = (events) => {
        sessionStorage.setItem("changesdone", 1);
        $(".itsaudit").addClass("hide");
        $(".metadataauditlivecontainer").addClass("hide");
        $(".dataauditlivecontainer").addClass("hide");
        var jsonObject = {};
        if ($('#metadataaudit').prop("checked") == true) {
            jsonObject["metaDataAudit"] = 1;
        } else {
            jsonObject["metaDataAudit"] = 0;
        }
        ChartInfo.functions.setAuditMetaDataOptions(jsonObject);
        this.closeauditmodal();
        if (jsonObject["metaDataAudit"] == 1) {
            $(".itsaudit").removeClass("hide");
            $(".metadataauditlivecontainer ").removeClass("hide");
            $(".dataauditlivecontainer").removeClass("hide");
        }
        if (jsonObject["metaDataAudit"] == 0) {
            $(".itsaudit").removeClass("hide");
            $(".dataauditlivecontainer").removeClass("hide");
        }
    }

}
class AuditMetaDatatemplate extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (<div className="modal fade in" id="auditmetadatamodel" tabindex="-1" role="dialog" aria-labelledby="Change password" aria-hidden="true" style={{ display: "block" }}>
            <div className="modal-backdrop fade in"></div>
            <div className="modal-dialog modal-sm modal-dialog-sm-center modal-dialog-center" style={{ zIndex: "1050" }}>
                <div className="modal-content">
                    <div className="modal-header boc-modal-hf-padding">
                        <button type="button" className="close white" data-dismiss="modal" onClick={this.props.closeauditmodal} aria-hidden="true">&times;</button>
                        <h4 className="modal-title auditmetadata"><span className="fa-stack fa-sm" aria-hidden="true" style={{ marginRight: "10px" }}><i className="fa fa-desktop fa-stack-2x" style={{ top: "5px" }}></i><i className="fa fa-search fa-stack-1x" style={{ top: "-3px" }}></i></span>{lang["storyboard.auditoptions"]}</h4>
                    </div>
                    <div className="modal-body">
                        <div className="container-fluid">
                            <div className="row filtercontent">
                                <div className="container-fluid">
                                    <div className="row nomargin">
                                        <div className="col-xs-12 nopadding">
                                            <label className="subhead marg-bot-10">
                                                <input type="checkbox" id="metadataaudit" name="metadataaudit" /> {lang["storyboard.metadataaudit"]}
                                        </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer boc-modal-hf-padding">
                        <button type="button" className="btn btn-bird" onClick={this.props.closeauditmodal} data-dismiss="modal"><i className="fa fa-times"></i> {lang["storyboard.cancelbtn"]}</button>
                        <button type="submit" className="btn btn-bird changemetadataaudit" onClick={this.props.changemetadataaudit}><i className="fa fa-check"></i> {lang["storyboard.applybtn"]}</button>
                    </div>
                </div>
            </div>
        </div>);
    }
}