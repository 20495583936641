import React from 'react';
import { lang } from "../js/helpers/utils/languages/language";
export default class DrilldownallSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        var that = this.props;
        return (
            <React.Fragment>
                {/* <!-- drilldownallsettingsview.js --> */}
                <div className="modal fade drilldownallsettingspop in" id="drilldownallsettingspop" tabIndex="-1" role="dialog" aria-labelledby="drilldownallsettingspop" aria-hidden="true" style={{ display: "block" }}>
                    <div className="modal-backdrop fade in"></div>
                    <div className="modal-dialog modal-sm modal-sm-400 modal-dialog-sm-center modal-dialog-center" style={{ zIndex: "1050" }}>
                        <div className="modal-content">
                            <div className="modal-header boc-modal-hf-padding">
                                <button type="button" className="close white" onClick={that.cancelDrilldownSetting} aria-hidden="true" data-dismiss="modal">&times;</button>
                                <h4 className="modal-title">
                                    <i className="fa fa-cog"></i> {lang["storyboard.drilldownsettings"]}
                                </h4>
                            </div>
                            <div className="modal-body">
                                <div className="container-fluid">
                                    <div className="row filtercontent">
                                        <legend className="subhead">{lang["storyboard.priorityclmns"]}</legend>
                                        <div className="col-xs-12 nopadding">
                                            <div className="validationgroup">
                                                <select multiple name="drilldownprioritycolumns" data-placeholder={lang["storyboard.slctclmns"]}  className="group-select modal-form-fields drilldownprioritycolumns">
                                                    <optgroup className="drilldownprioritycolumns_group" label={lang["storyboard.reportcolms"]}>
                                                        {
                                                            that.dimensions.map((dimension, i) => {
                                                                return (
                                                                    <option key={i}
                                                                        className={`data_type_${dimension.tableDisplayType}`}
                                                                        value={dimension.columnDisplayName}
                                                                        data-type={dimension.tableDisplayType}
                                                                        data-visible={dimension.visible}
                                                                        data-columnDisplayName={dimension.columnDisplayName}
                                                                        data-orginalColumnName={dimension.orginalColumnName}
                                                                    >
                                                                        {dimension.columnDisplayName}
                                                                    </option>
                                                                );
                                                            })
                                                        }
                                                    </optgroup>
                                                    <optgroup className="customfieldsprioritycolumns_group" label={lang["storyboard.customfields"]}>
                                                        {
                                                            that.calculatedFileds.map((valueObject, index) => {
                                                                return (
                                                                    <option key={index}
                                                                        className={`data_type_${valueObject.dataType} fx`}
                                                                        custom-field={"true"}
                                                                        data-type="string"
                                                                        data-parent={valueObject.name}
                                                                        data-child={valueObject.name}
                                                                        data-name={valueObject.name}
                                                                        data-columnDisplayName={valueObject.name}
                                                                        value={valueObject.name}
                                                                        data-formula=""
                                                                    >
                                                                        {valueObject.name}
                                                                    </option>
                                                                );
                                                            })
                                                        }
                                                    </optgroup>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="clear"></div>
                                        <legend className="subhead marg-top-15">{lang["storyboard.drilllist"]}</legend>
                                        <div className="col-xs-12 nopadding">
                                            <div className="validationgroup">
                                                <select multiple name="drillthroughselectreports" data-placeholder={lang["storyboard.slctreports"]} className="group-select modal-form-fields drillthroughselectreports">
                                                    <optgroup className="drillthroughselectreports_group">
                                                        {
                                                            that.userreports.map((report, index) => {
                                                                if (report.selected === false && report.visualizationType !== "customstoryboard" && report.visualizationType !== "ml_storyboard") {
                                                                    return (
                                                                        <option key={index}
                                                                            className="data_type_report"
                                                                            report-id={report.reportid}
                                                                            value={report.reportid}
                                                                            report-name={report.reportname}
                                                                        >
                                                                            {report.reportname}
                                                                        </option>
                                                                    );
                                                                }
                                                            })
                                                        }
                                                    </optgroup>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer boc-modal-hf-padding">
                                <button type="button" className="btn btn-bird" onClick={that.cancelDrilldownSetting} data-dismiss="modal">
                                    <i className="fa fa-times"></i> {lang["storyboard.cancelbtn"]}
                                </button>
                                <button type="button" className="btn btn-bird applydrilldownsettings" onClick={that.applydrilldownsettings} data-dismiss="modal">
                                    <i className="fa fa-check"></i> {lang["storyboard.applybtn"]}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}