import React from 'react';
import { lang } from "../js/helpers/utils/languages/language";
import _ from 'underscore';
import SendRequest from '../SendRequest';

export default class HamburgerWorkspacesList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            workspaces: [],
            features: []
        }
    }
    componentDidMount(){
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getavailableworkspaces",
            queryString: "?isFromModel= false",
            sucessFunction: (props) => {
                this.setState({ workspaces: props });
            },
            rejectFunction: () => { },
            headers: requestHeaders,
        })
    }
    render() {
        return (
            <>
                {
                    (this.state.workspaces.length > 0) ? (
                        <>
                            <h4 className="panelboc-title accordion-toggle" title={lang["user.header.workspace"]} id="" data-toggle="collapse" href="#hamworkspacetab" data-parent="#dashboardtab" aria-expanded="false" aria-controls="hamworkspacetab">
                                <div className="ripples">
                                    <span className="ripplesCircle"></span>
                                </div>
                                <i className="fa fa-cubes fa-solid"></i>{lang["user.header.workspace"]}
                                <span className="badge pull-right marg-righ-15">{this.state.workspaces.length}</span>
                            </h4>
                            <div id="hamworkspacetab" className="panel-collapse panelboc-content collapse" aria-expanded="false">
                                <div className="panelboc-body">
                                    <div className="container-fluid marg-bot-10 marg-top-10">
                                        <div className="row">
                                            <input onKeyUp={this.props.search} className="form-control search-image searchinput" name="searchreports" placeholder={lang["user.header.search"]} type="text" />
                                        </div>
                                    </div>
                                    <ul className="list-group scrollsetul">
                                        {
                                           this.state.workspaces.map((workspaceitem, j)=> {
                                                var wssaveStatus = 'ready';
                                                if (workspaceitem.wssaveStatus === 'completed') { wssaveStatus = 'ready' }
                                                else if (workspaceitem.wssaveStatus === 'inprogress') { wssaveStatus = 'processing' }
                                                else { wssaveStatus = 'error' }
                                                return (
                                                    <li className="viewreport-list-item" title={`${workspaceitem.WSTitle}${(workspaceitem.WSDescription !== '') ? <>. ${workspaceitem.WSDescription}</> : ''}`}>
                                                        <a className="navDashboard" href={`${this.props.url}/workspace/${btoa(workspaceitem.WSID)}`} data-num={workspaceitem.WSID} tab-id="0" tab-name={workspaceitem.WSTitle} className="boc-snapshot-thumbnail">
                                                            <div className="ripples">
                                                                <span className="ripplesCircle"></span>
                                                            </div>
                                                            <i className="fa fa-cubes fa-solid"></i>
                                                            <span>{workspaceitem.WSTitle}</span>
                                                            <div className={`data-item-sync-status ${wssaveStatus}`}>
                                                                <span className="data-item-sync-status-icon"></span>
                                                            </div>
                                                        </a>
                                                    </li>
                                                );
                                            })}
                                    </ul>
                                </div>
                            </div>
                        </>
                    ) : null
                }
            </>
        );
    }
}