import _ from 'lodash';
import React, { Component } from "react";
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import { lang } from "../js/helpers/utils/languages/language";
import ReactDOM from "react-dom";
const $ = window.$;

export default class CustomMeasureHierarchyDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicdiv: props.dynamicdiv,
            addcustomhierarchyvalue1:props.addcustomhierarchyvalue1,
            columnDisplayName: props.columnDisplayName,
            columnName: props.columnName,
            dataType: props.dataType,
            tableName: props.tableName,
            multifactentities: props.multifactentities,
            facttables: props.facttables,
            multifact: props.multifact,
            agg: props.agg,
        };
        this.render();
    }
    render() {
        var that = this;
        var disable = false;
        if (this.state.multifact == "true" && $($('.customhierarchyattributes li')).length != 0 && $($('.customhierarchyattributes li')[0]).attr('data-name') != undefined) {
            var yentities = [];
            _.each($('.customhierarchyattributes li'), function (i) {
                if ($(i).attr('data-name') != undefined) {
                    yentities.push($(i).attr('data-name'));
                }
            });
            var ismhvalid = false;
            var mhFields = [];
            var meassureHierarchyObj = $.grep(ChartInfo.functions.getMeasureHierarchy(), function (e) { return e.name == that.state.columnName });
            if (meassureHierarchyObj != undefined && meassureHierarchyObj.length > 0 && meassureHierarchyObj[0].hierarchelist != undefined) {
                _.each(meassureHierarchyObj[0].hierarchelist, function (hierarchy) {
                    var tablename = hierarchy.columnName;
                    mhFields.push(hierarchy.columnName.split(".")[0]);
                    disable = ChartInfo.functions.getAllowedEntitiesForInstantFilters(JSON.parse(that.state.multifactentities), tablename, undefined, yentities, undefined, that.state.facttables);
                    if (disable) {
                        ismhvalid = true;
                    }
                });
                if (ismhvalid) {
                    disable = true;
                }
            } else {
                disable = ChartInfo.functions.getAllowedEntitiesForInstantFilters(JSON.parse(that.state.multifactentities), that.state.columnName, undefined, yentities, undefined, that.state.facttables);
            }
            var facttab;
            if (that.state.facttables != undefined) {
                var meassureHierarchyObj = $.grep(ChartInfo.functions.getMeasureHierarchy(), function (e) { return e.name == that.state.columnName });
                var meassurehierarchyNamesMap = ChartInfo.functions.getMeassurehierachyMap();
                var isMhField = false;
                if (meassureHierarchyObj != undefined && meassureHierarchyObj.length > 0 && meassureHierarchyObj[0].hierarchelist != undefined) {
                    isMhField = true;
                }
                var tableval = that.state.columnName.split(".")[0];
                if (tableval != undefined && that.state.facttables.indexOf(tableval) != -1 && !isMhField) {
                    facttab = tableval;
                } else if (tableval != undefined && mhFields.length > 0 && isMhField) {
                    _.each(mhFields, function (mh) {
                        if (that.state.facttables.indexOf(mh) != -1 && meassurehierarchyNamesMap[mh] == undefined) {
                            facttab = mh;
                        } else if (meassurehierarchyNamesMap[mh] != undefined && meassurehierarchyNamesMap[mh].length > 0) {
                            _.each(meassurehierarchyNamesMap[mh], function (mhval) {
                                facttab = mhval;
                            })
                        }
                    });
                }
                if (facttab != undefined) {
                    var isymhvalid = false;
                    _.each(yentities, function (yval) {
                        var ytableval = yval.split(".")[0];
                        var meassureHierarchyObj = $.grep(ChartInfo.functions.getMeasureHierarchy(), function (e) { return e.name == yval });
                        var isMhField = false;
                        if (meassureHierarchyObj != undefined && meassureHierarchyObj.length > 0 && meassureHierarchyObj[0].hierarchelist != undefined) {
                            isMhField = true;
                        }
                        if (that.state.facttables.indexOf(ytableval) != -1 && facttab != ytableval && !isMhField) {
                            disable = true;
                            if (disable) {
                                isymhvalid = true;
                            }
                        } else if (facttab != undefined && isMhField) {
                            _.each(meassurehierarchyNamesMap[yval], function (mh) {
                                if (mh != facttab && that.state.facttables.indexOf(mh) != -1 && meassurehierarchyNamesMap[mh] == undefined) {
                                    disable = true;
                                    if (disable) {
                                        isymhvalid = true;
                                    }
                                } else if (meassurehierarchyNamesMap[mh] != undefined) {
                                    _.each(meassurehierarchyNamesMap[mh], function (mhval) {
                                        if (mhval != facttab && that.state.facttables.indexOf(mhval) != -1) {
                                            disable = true;
                                            if (disable) {
                                                isymhvalid = true;
                                            }
                                        }
                                    })
                                }
                            });

                        }
                    });
                    if (isymhvalid) {
                        disable = true;
                    }
                }
            }
            if (!disable) {
                ReactDOM.render(<HierarchyDropdownTemplate dynamicdiv={this.state.dynamicdiv} addcustomhierarchyvalue1={this.state.addcustomhierarchyvalue1}
                    columnDisplayName={this.state.columnDisplayName} columnName={this.state.columnName}
                    dataType={this.state.dataType} tableName={this.state.tableName} agg={this.props.agg} percentilevalue={this.props.percentilevalue}
                ></HierarchyDropdownTemplate>, document.getElementById(this.state.dynamicdiv))
            }
        } else {
            ReactDOM.render(<HierarchyDropdownTemplate dynamicdiv={this.state.dynamicdiv} addcustomhierarchyvalue1={this.state.addcustomhierarchyvalue1}
                columnDisplayName={this.state.columnDisplayName} columnName={this.state.columnName}
                dataType={this.state.dataType} tableName={this.state.tableName} agg={this.props.agg} percentilevalue={this.props.percentilevalue}
            ></HierarchyDropdownTemplate>, document.getElementById(this.state.dynamicdiv))
        }
        return (<div></div>);
    }
}
class HierarchyDropdownTemplate extends Component{
    constructor(props){
        super(props);
        this.state={};
    }
    render(){
        var that = this.props;
        var dataIcon =  lang["storyboard.abc"];
        if(that.dataType=="number"){
            dataIcon="123"
        }else if(that.dataType=="date" || that.dataType=="datetime" || that.dataType === "month" || that.dataType === "day"){
            dataIcon=<i className='fa fa-calendar'></i>
        }else if(that.dataType=="customhierarchy" || that.dataType === "custommeasurehierarchy"){
            dataIcon=<i className='fa fa-list'></i>
    }
        return(<li key={that.dynamicdiv} className="xs-list-item hierarchyitem" onClick={that.addcustomhierarchyvalue1} data-name={that.columnName} percentilevalue={that.percentilevalue} title={that.columnDisplayName} table-name={that.tableName} data-type={that.dataType} agg={that.agg}>
        {/* <!-- hierarchydropdownview.js --> */}
        <span className="itemtype">{dataIcon}</span>				
        <span className="itemname">{that.columnDisplayName}</span>
        {(that.dataType!='custommeasurehierarchy')?(
            <span className="boclabel boclabel-right-5 marg-righ-15 opacity-effect" type="button" id="dropdownaggregation">{that.agg}</span>
        ):null}
    </li>);
    }
}