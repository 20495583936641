import React from 'react';
import _ from 'underscore';
import * as ModalProperties from '../js/helpers/utils/modalproperties';
import SaveWorkspaceModalHtml from './SaveWorkspaceModalHtml.jsx';
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;

export default class SaveWorkspaceModal extends React.Component {
    constructor(props) {
        super(props);
        // Backbone.Validation.bind(this, {
        //     valid : function(view, attr, selector) {
        //         var $el = view.$('[name=' + attr + ']'),
        //         $group = $el.closest('.validationgroup');
        //         $group.removeClass('has-error');
        //         $group.find('.help-block').html('').addClass('hidden');
        //     },
        //     invalid : function(view, attr, error, selector) {
        //         var $el= view.$('[name=' + attr + ']'),
        //         $group = $el.closest('.validationgroup');
        //         $group.addClass('has-error');
        //         $group.find('.help-block').html(error).removeClass('hidden');
        //     }			
        // });
        this.workspaceName = this.props.workspaceName;
        this.workspaceDesc = this.props.workspaceDesc;
        this.dataSyncDetails = this.props.dataSyncDetails;
        this.syncConfigs = this.props.syncConfigs;
    }
    componentDidMount() {
        var that = this;
        //$("#savesworkspace").modal("show");
        ModalProperties.resize();
        if (this.dataSyncDetails != undefined) {
            setTimeout(function (e) {
                that.updateSyncConfigBucket(that.dataSyncDetails);
            }, 300);
        }
    }
    updateSyncConfigBucket=(datasyncdetails)=>{
        if(datasyncdetails!="0"){
            $(".syncdomelement").val(datasyncdetails).show();
        }
    }
    updateSyncConfigDetails = (datasyncdetails) => {
        var that = this;
        var datasyncdetailsobj = JSON.parse(datasyncdetails);
        if (datasyncdetailsobj.syncType == 'minutes') {
            $('input#custom').trigger("click");
            $(".syncreport").val("minutes").change();
            $(".synccontainer").not(".minutes").hide();
            $(".minutes").removeClass('hide');
            $(".minutes").show();
            $(".minutes").find("input").val(datasyncdetailsobj.syncVal);
        }
        else if (datasyncdetailsobj.syncType == 'hours') {
            $('input#custom').trigger("click");
            $(".syncreport").val("hours").change();
            $(".synccontainer").not(".hours").hide();
            $(".hours").removeClass('hide');
            $(".hours").show();
            $(".hours").find("input").val(datasyncdetailsobj.syncVal);
        }
        else if (datasyncdetailsobj.syncType == 'days') {
            $('input#custom').trigger("click");
            $(".syncreport").val("days").change();
            $(".synccontainer").not(".days").hide();
            $(".days").removeClass('hide');
            $(".days").show();
            $(".days").find("input[type=number]").attr("value", datasyncdetailsobj.syncVal);
            $('#sync-day-time-start').val(datasyncdetailsobj.synctime);
        }
        else if (datasyncdetailsobj.syncType == 'seconds') {
            $('input#custom').trigger("click");
            $(".syncreport").val("seconds").change();
            $(".synccontainer").not(".seconds").hide();
            $(".seconds").removeClass('hide');
            $(".seconds").show();
            $(".seconds").find("input[type=number]").val(datasyncdetailsobj.syncVal);
        }
        $('.sync-day-time').clockpicker({
            "default": 'now',
            placement: 'top',
            align: 'left',
            autoclose: true,
        });
    }
    saveworkspace = (e) => {
        var workspacename = $("#workspacename").val();
        var workspacedescr = $("#workspacedesc").val();
        var reservedwordslist = JSON.parse(sessionStorage.getItem("reservedwordslist"));
        //        this.props.model.set('workspacename',workspacename);
        //      this.props.model.set('workspacedescr',workspacedescr);
       
        var dataSyncType = $('.datasynctype:checked').attr("id");
        var syncType;
        var syncVal;
        var syncTime = $('#sync-day-time-start').val();
        if (workspacename== undefined || workspacename == "" || workspacename.length == 0 || /[~`!@#$%^&*()+\=\[\]{};':\\|†,.<>\/?"""`]/.test(workspacename)) {
			$(".wsname-error").html(lang["workspace.validname"])
			return;
		} else if (workspacename.length > 70) {
			$(".wsname-error").html(lang["workspace.reportnameexceeds"])
			return;
		} else if (reservedwordslist != null){ 
		if (reservedwordslist.indexOf(workspacename.toLowerCase()) != -1) {
			$(".wsname-error").html(lang["workspace.noreservewords"])
			return;
			}
		} else {
			$(".wsname-error").html('')
        }
        if (dataSyncType == 'defaultcontainer') {
            syncType = "default";
            syncVal = $('.defaultcontainer input').val();
        } else {
            syncType = $('.syncreport').find("option:selected").val();
            syncVal = $('.' + syncType + '').find("input").val();
        }
        if (syncType == 'days' && syncVal == "") {
            $('.syncerrordays').text(lang["workspace.inputdata"]);
            $('.syncerrordays').removeClass("hide");
            return false;
        } else {
            if (syncType == "") {
                syncType = "default";
            }
        }
        if (syncType == 'minutes') {
            if (syncVal.length == "") {
                $('.syncerror').text(lang["workspace.enternumber"]);
                $('.syncerror').removeClass("hide");
            }
            else if (syncVal < 1) {
                $('.syncerror').text(lang["workspace.minminutes"]);
                $('.syncerror').removeClass("hide");
                return false;
            } else if (syncVal > 59) {
                $('.syncerror').text(lang["workspace.maxminutes"]);
                $('.syncerror').removeClass("hide");
                return false;
            }
            else if (syncVal.indexOf(".") != -1) {
                $('.syncerror').text(lang["workspace.decimalval"]);
                $('.syncerror').removeClass("hide");
                return false;
            }
            else {
                $('.syncerror').addClass("hide");
            }
        } else if (syncType == 'hours') {
            if (syncVal.length == "") {
                $('.syncerror').text(lang["workspace.enternumber"]);
                $('.syncerror').removeClass("hide");
                return false;
            }
            else if (syncVal < 1) {
                $('.syncerror').text(lang["workspace.minhr"]);
                $('.syncerror').removeClass("hide");
                return false;
            } else if (syncVal > 23) {
                $('.syncerror').text(lang["workspace.maxhr"]);
                $('.syncerror').removeClass("hide");
                return false;
            }
            else if (syncVal.indexOf(".") != -1) {
                $('.syncerror').text(lang["workspace.decimalval"]);
                $('.syncerror').removeClass("hide");
                return false;
            }
            else {
                $('.syncerror').addClass("hide");
            }
        } else if (syncType == 'days') {
            if (syncVal.length == "") {
                $('.syncerrordays').text(lang["workspace.enternumber"]);
                $('.syncerrordays').removeClass("hide");
                return false;
            }
            else if (syncVal < 1) {
                $('.syncerrordays').text(lang["workspace.minday"] );
                $('.syncerrordays').removeClass("hide");
                return false;
            } else if (syncVal > 31) {
                $('.syncerrordays').text(lang["workspace.maxday"]);
                $('.syncerrordays').removeClass("hide");
                return false;
            }
            else if (syncVal.indexOf(".") != -1) {
                $('.syncerrordays').text(lang["workspace.decimalval"]);
                $('.syncerrordays').removeClass("hide");
                return false;
            }
            else {
                var timeSplit = $(".sync-day-time input").val();
                if (timeSplit == undefined || timeSplit.length <= 0) {
                    timeSplit = "00:00";
                }
                var errormsg = $("#errormsg")["0"].innerHTML.length;
                if (errormsg > 0 && timeSplit != "00:00") {
                    return false;
                }
                syncTime = timeSplit;
                $('.syncerrordays').addClass("hide");
            }
        } else if (syncType == 'seconds') {
            if (syncVal.length == "") {
                $('.syncerror').text(lang["workspace.enternumber"]);
                $('.syncerror').removeClass("hide");
                return false;
            }
            else if (syncVal < 5) {
                $('.syncerror').text(lang["workspace.minsec"]);
                $('.syncerror').removeClass("hide");
                return false;
            } else if (syncVal > 59) {
                $('.syncerror').text(lang["workspace.maxsec"] );
                $('.syncerror').removeClass("hide");
                return false;
            }
            else if (syncVal.indexOf(".") != -1) {
                $('.syncerror').text(lang["workspace.decimalval"]);
                $('.syncerror').removeClass("hide");
                return false;
            }
            else {
                $('.syncerror').addClass("hide");
            }
        }
        var syncScheduleObj = {};
        syncScheduleObj["dataSyncType"] = dataSyncType;
        syncScheduleObj["syncType"] = syncType;
        syncScheduleObj["syncVal"] = syncVal;
        syncScheduleObj["synctime"] = syncTime;

        if (true) {
            this.props.onsaveworkspace(workspacename, workspacedescr, JSON.stringify(syncScheduleObj));
            //this.broker.trigger('onsaveworkspace',workspacename,workspacedescr,JSON.stringify(syncScheduleObj));
        }
    }
    changeSyncOption = (e) => {
        $('.syncerror').addClass("hide");
        var $this = e.currentTarget;
        $($this).find("option:selected").each(function () {
            if ($(this).attr("value") === "minutes") {
                $(".synccontainer").not(".minutes").hide();
                $(".minutes").removeClass('hide');
                $(".minutes").show();
            } else if ($(this).attr("value") === "seconds") {
                $(".synccontainer").not(".seconds").hide();
                $(".seconds").removeClass('hide');
                $(".seconds").show();
            } else if ($(this).attr("value") === "hours") {
                $(".synccontainer").not(".hours").hide();
                $(".hours").removeClass('hide');
                $(".hours").show();
            }
            else if ($(this).attr("value") === "days") {
                $(".synccontainer").not(".days").hide();
                $(".days").removeClass('hide');
                $(".days").show();
            }
            else {
                $(".synccontainer").hide();
            }
            $('.sync-day-time').clockpicker({
                "default": 'now',
                placement: 'top',
                align: 'left',
                autoclose: true,
            }).on('keyup', function (e) {
                //if the letter is not digit then display error and don't type anything
                $('.sync-day-time').clockpicker('hide');
                var timeaftersplitting;
                var hours;
                var minutes;
                var timeSplit = $(".sync-day-time input").val();
                var errorbox;
                errorbox = "#errormsg";
                $(errorbox).html("").hide()
                var letters = /^[A-Za-z]+$/;
                if (letters.test(timeSplit) || letters.test(timeSplit.valueOf().charAt(1)) || letters.test(timeSplit.valueOf().charAt(4)) || letters.test(timeSplit.valueOf().charAt(0))) {
                    $(errorbox).html(lang["workspace.digitsonly"]).show().fadeOut(3000);
                    $("#sync-day-time-start").val("");
                    return false;
                } if ((timeSplit.valueOf().charAt(0) == ":" || timeSplit.valueOf().charAt(1) == ":" || timeSplit.valueOf().charAt(3) == ":" || timeSplit.valueOf().charAt(4) == ":") && timeSplit.length != 4) {
                    $(errorbox).html("").hide()
                    $(errorbox).html(lang["workspace.timeformat"]).show().fadeOut(3000);
                    $("#sync-day-time-start").val("");
                    return false;
                } else if (timeSplit.match(/([#;?&,%.+*~\'"!^$%[\]()<=>|\/@])/g)) {
                    $(errorbox).html("").hide()
                    $(errorbox).html(lang["workspace.spclchars"]).show().fadeOut(3000);
                    $("#sync-day-time-start").val("");
                    return false;
                } else if (timeSplit.match(/([-_{}])/g)) {
                    $(errorbox).html("").hide()
                    $(errorbox).html(lang["workspace.spclchars"]).show().fadeOut(3000);
                    $("#sync-day-time-start").val("");
                    return false;
                } else if (timeSplit.indexOf(":") > -1 && timeSplit.indexOf(":") != 2) {
                    $(errorbox).html("").hide()
                    $(errorbox).html(lang["workspace.timeformat"]).show();
                    return false;
                } else if (timeSplit.length > 0) {
                    if ((timeSplit.length == 5 || timeSplit.length == 4) && timeSplit.indexOf(":") < 0) {
                        $(errorbox).html("").hide()
                        $(errorbox).html(lang["workspace.timeformat"]).show().fadeOut(3000);
                        $("#sync-day-time-start").val("");
                        return false;
                    } else if (timeSplit.length == 1 && timeSplit > 2) {
                        timeSplit = 2;
                        $("#sync-day-time-start").val(timeSplit);
                        $(errorbox).html("").hide()
                        $(errorbox).html(lang["workspace.maxhrshould"]).show();
                        return false;
                    } else if (timeSplit.length == 1 && timeSplit <= 2) {
                        $(errorbox).html("").hide()
                        $(errorbox).html(lang["workspace.timeformat"]).show();
                        return false;
                    } else if (timeSplit.length == 2 && timeSplit > 23) {
                        timeSplit = 23;
                        $("#sync-day-time-start").val(timeSplit);
                        $(errorbox).html("").hide()
                        $(errorbox).html(lang["workspace.maxhrtimeformat"]).show();
                        return false;
                    } else if (timeSplit.length == 2 && timeSplit <= 23) {
                        $("#sync-day-time-start").val(timeSplit);
                        $(errorbox).html("").hide()
                        $(errorbox).html(lang["workspace.timeformat"]).show();
                        return false;
                    } else if (timeSplit.length == 4) {
                        if (timeSplit.indexOf(":") == 2) {
                            timeaftersplitting = timeSplit.split(":");
                            hours = timeaftersplitting[0];
                            minutes = timeaftersplitting[1];
                            if (hours.length < 2) {
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["workspace.timeformat"]).show();
                                return false;
                            } else if (hours > 23) {
                                hours = 23;
                                timeSplit = hours + ":" + minutes;
                                $("#sync-day-time-start").val(timeSplit);
                            } else if (minutes.length == 1 && minutes > 5) {
                                minutes = 5;
                                timeSplit = hours + ":" + minutes;
                                $("#sync-day-time-start").val(timeSplit);
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["workspace.maxminshould"]).show();
                                return false;
                            } else if (minutes.length == 1 && minutes <= 5) {
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["workspace.timeformat"]).show();
                                return false;
                            } else if (minutes.length == 2 && minutes > 59) {
                                minutes = 59;
                                timeSplit = hours + ":" + minutes;;
                                $("#sync-day-time-start").val(timeSplit);
                            } else if (letters.test(minutes.valueOf().charAt(0)) || letters.test(minutes.valueOf().charAt(1))) {
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["workspace.digitsonly"]).show().fadeOut(3000);
                                $("#sync-day-time-start").val("");
                                return false;
                            } else if (letters.test(hours.valueOf().charAt(0)) || letters.test(hours.valueOf().charAt(1))) {
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["workspace.digitsonly"]).show().fadeOut(3000);
                                $("#sync-day-time-start").val("");
                                return false;
                            } else if (minutes.length == 0) {
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["workspace.timeformat"]).show();
                                return false;
                            } else {
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["workspace.timeformat"]).show();
                                return false;
                            }

                        } else {
                            var timeSplit1 = timeSplit.valueOf().charAt(0) + timeSplit.valueOf().charAt(1);
                            var timeSplit2 = timeSplit.valueOf().charAt(3) + timeSplit.valueOf().charAt(4);
                            timeSplit = timeSplit1 + ":" + timeSplit2;
                            $("#sync-day-time-start").val(timeSplit);
                        }
                    } else if (timeSplit.length == 5 && timeSplit.indexOf(":") == 2) {
                        timeaftersplitting = timeSplit.split(":");
                        hours = timeaftersplitting[0];
                        minutes = timeaftersplitting[1];
                        if (hours > 23) {
                            hours = 23;
                            timeSplit = hours + ":" + minutes;
                            $("#sync-day-time-start").val(timeSplit);
                        } else if (minutes > 59) {
                            minutes = 59;
                            timeSplit = hours + ":" + minutes;
                            $("#sync-day-time-start").val(timeSplit);
                        } else if (hours.match(/([#;?&,%. +*~\'"!^$%[\]()<=>|\/@])/g) || hours.match(/([-_{}])/g)) {
                            $(errorbox).html("").hide()
                            $(errorbox).html(lang["workspace.spclchars"]).show().fadeOut(3000);
                            $("#sync-day-time-start").val("");
                            return false;
                        } else if (minutes.match(/([#;?&,%. +*~\'"!^$%[\]()<=>|\/@])/g) || minutes.match(/([-_{}])/g)) {
                            $(errorbox).html("").hide()
                            $(errorbox).html(lang["workspace.spclchars"]).show().fadeOut(3000);
                            $("#sync-day-time-start").val("");
                            return false;
                        } else if (letters.test(minutes.valueOf().charAt(0)) || letters.test(minutes.valueOf().charAt(1))) {
                            $(errorbox).html("").hide()
                            $(errorbox).html(lang["workspace.digitsonly"]).show().fadeOut(3000);
                            $("#sync-day-time-start").val("");
                            return false;
                        } else if (letters.test(hours.valueOf().charAt(0)) || letters.test(hours.valueOf().charAt(1))) {
                            $(errorbox).html("").hide()
                            $(errorbox).html(lang["workspace.digitsonly"]).show().fadeOut(3000);
                            $("#sync-day-time-start").val("");
                            return false;
                        }
                    } else if (timeSplit.length == 3) {
                        if (timeSplit.indexOf(":") == 2) {
                            timeaftersplitting = timeSplit.split(":");
                            hours = timeaftersplitting[0];
                            minutes = timeaftersplitting[1];
                            if (hours > 23) {
                                hours = 23;
                                timeSplit = hours + ":" + minutes;
                                $("#sync-day-time-start").val(timeSplit);
                            } else if (letters.test(hours.valueOf().charAt(0)) || letters.test(hours.valueOf().charAt(1))) {
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["workspace.digitsonly"]).show().fadeOut(3000);
                                $("#sync-day-time-start").val("");
                                return false;
                            } else if (hours.length < 2) {
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["workspace.timeformat"]).show();
                                return false;
                            } else if (minutes.length < 2) {
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["workspace.timeformat"]).show();
                                return false;
                            } else if (hours.match(/([#;?&,%. +*~\'"!^$%[\]()<=>|\/@])/g) || hours.match(/([-_])/g)) {
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["workspace.spclchars"]).show().fadeOut(3000);
                                $("#sync-day-time-start").val("");
                                return false;
                            } else if (minutes.match(/([#;?&,%. +*~\'"!^$%[\]()<=>|\/@])/g) || minutes.match(/([-_])/g)) {
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["workspace.spclchars"]).show().fadeOut(3000);
                                $("#sync-day-time-start").val("");
                                return false;
                            } else if (letters.test(minutes.valueOf().charAt(0)) || letters.test(minutes.valueOf().charAt(1))) {
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["workspace.digitsonly"]).show().fadeOut(3000);
                                $("#sync-day-time-start").val("");
                                return false;
                            }

                        } else if ((timeSplit.valueOf().charAt(0) + timeSplit.valueOf().charAt(1)) <= 23) {
                            var timeSplit1 = timeSplit.valueOf().charAt(0) + timeSplit.valueOf().charAt(1);
                            if (timeSplit.valueOf().charAt(2) > 5) {
                                var minute = 5;
                                timeSplit = timeSplit1 + ":" + minute;
                                $("#sync-day-time-start").val(timeSplit);
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["workspace.timeformat"]).show();
                                return false;
                            } else {
                                timeSplit = timeSplit1 + ":" + timeSplit.valueOf().charAt(2);
                                $("#sync-day-time-start").val(timeSplit);
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["workspace.timeformat"]).show();
                                return false;
                            }
                        } else {
                            $(errorbox).html("").hide()
                            $(errorbox).html(lang["workspace.timeformat"]).show();
                            return false;
                        }
                    } else {
                        if (timeSplit.length == 1) {
                            $(errorbox).html("").hide()
                            $(errorbox).html(lang["workspace.timeformat"]).show();
                            return false;
                        }
                    }
                }
            });
        });
    }
    changeSyncType = (e) => {
        $('.syncerror').addClass("hide");
        var $this = e.currentTarget;
        var datasyncchecked = $('.datasynctype:checked').attr("id");
        if (datasyncchecked === "defaultcontainer") {
            $(".custom").addClass('hide').removeClass('show');
            $(".defaultcontainer").addClass('show').removeClass('hide');
        }
        else if (datasyncchecked === "custom") {
            $(".defaultcontainer").addClass('hide').removeClass('show');
            $(".custom").addClass('show').removeClass('hide');
        }
        else {
            $(".datasync-container").hide();
        }
    }
    render() {
        return (
            <SaveWorkspaceModalHtml
                workspaceName={this.workspaceName}
                workspaceDesc={this.workspaceDesc}
                saveworkspace={this.saveworkspace}
                changeSyncType={this.changeSyncType}
                changeSyncOption={this.changeSyncOption}
                syncConfigs={this.syncConfigs}
                metadataObj={this.props.metadataObj}
                checkForCustomQueryOrStreamingEntiyPresent={this.props.checkForCustomQueryOrStreamingEntiyPresent}
            />
        );
    }
}