import React, { Component } from "react";
import LicenseView from "../admin/licenseviewhtml.jsx";
import Adminhtml from '../admin/adminhtml.jsx';
import SendRequest from '../SendRequest';
import UsersView from "./users/UsersView";
import GroupsView from "./groups/GroupsView";
import RolesView from "./roles/rolesview.js";
import ReportManagementView from "./reportmanagement/reportmanagementview.js";
import AuditingView from "./auditing/auditingview";
import OtherSettingsView from  './OtherSettings/OtherSettingsView';
import AccountsView from './accounts/accountsview';
import SyncConfigurationView from './SyncConfiguarations/SuncConfigurationView';
const $= window.$;

class License extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showleftnav: false,
            showLicense: false,
            showAcountInfo: false,
            accountsinfo: {},
            features: {},
            licenseDetails: {},
            displayFooter:true
        }
    };
    componentWillMount() {
        this.parsedFeatureJson=[];
        let featuresString = sessionStorage.getItem("features");
        if (featuresString != null && featuresString != undefined && featuresString != "") {
            featuresString = featuresString.slice(0, 3) + featuresString.slice(4);
            let decryptedString = window.atob(featuresString);
            this.parsedFeatureJson = JSON.parse(decryptedString);
        }

        let requestHeaders2 = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "leftNavigationOptionsForAdmin",
            queryString: "",
            sucessFunction: (accountsinfo) => {
                this.setState({ showAcountInfo: true, accountsinfo: accountsinfo });
            },
            rejectFunction: () => { },
            headers: requestHeaders2,
        })
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getalllicensedetails",
            queryString: "",
            sucessFunction: (props) => {
                if(props.allowApi =='true'){
                    props.allowApi='Allowed';
                }else{
                    props.allowApi='Not Allowed'
                }
                if(props.allowML =='true'){
                    props.allowML='Allowed';
                }else{
                    props.allowML="Not Allowed"
                }
                if(props.allowSmartInsights =='true'){
                    props.allowSmartInsights='Allowed';
                }else{
                    props.allowSmartInsights="Not Allowed";
                }
                if(props.allowWhiteLable =='true'){
                    props.allowWhiteLable='Allowed';
                }else{
                    props.allowWhiteLable="Not Allowed";
                }
                this.setState({ showLicense: true,showleftnav: true, licenseDetails: props });
            },
            rejectFunction: () => { },
            headers: requestHeaders,
        })
    }
    // componentDidMount() {
    //     let requestHeaders = {
    //       method: "get",
    //       headers: new Headers({
    //         "Content-Type": "application/json"
    //       })
    //     };
    //     SendRequest({
    //       url: "getServerDateTime",
    //       queryString: "",
    //       sucessFunction: (d) => {
    //         $(".servertimezone").text(d.serverDateTime).attr("title", "Server Time : " + d.serverDateTime);
    //         var dt = new Date();
    //         $(".localtimezone").text(dt).attr("title", "Local Time : " + dt);
    //         $(".timezone").removeClass("hide");
    //       },
    //       rejectFunction: () => { },
    //       headers: requestHeaders
    //     })
    //   }
    getLicenseView=()=>{
        this.props.history.push(this.props.url+"/licensemanagement");
    }
    getMainContent=()=>{
        var returnView=null;
        if(this.props.location.pathname.indexOf("users")!=-1){
             returnView= <UsersView/>
        }else if(this.props.location.pathname.indexOf("groups")!=-1){
            returnView= <GroupsView/>
        }else if(this.props.location.pathname.indexOf("roles")!=-1){
            returnView= <RolesView/>
        }else if(this.props.location.pathname.indexOf("settings")!=-1 && this.props.location.pathname.indexOf("sync")<0){
            returnView= <OtherSettingsView features={this.parsedFeatureJson}/>
        }else if(this.props.location.pathname.indexOf("reportmanagement")!=-1){
            returnView= <ReportManagementView/>
        }else if(this.props.location.pathname.indexOf("auditing")!=-1){
            returnView= <AuditingView/>
        }else if(this.props.location.pathname.indexOf("syncsettings")!=-1){
            returnView= <SyncConfigurationView/>
        }else if(this.props.location.pathname.indexOf("accounts")!=-1){
            returnView  = <AccountsView features={this.parsedFeatureJson}/> 
        }else if(this.props.location.pathname.indexOf("licensemanagement")!=-1){
         returnView = (this.state.showLicense) ? <LicenseView features={this.parsedFeatureJson} licenseDetails={this.state.licenseDetails} /> : null
        }else{  
            returnView = (this.state.showLicense) ? <LicenseView features={this.parsedFeatureJson} licenseDetails={this.state.licenseDetails} /> : null
        }
         return returnView;
    }
    groupsView=()=>{
        this.props.history.push(this.props.url+"/groups");
    }
    usersView=()=>{
        this.props.history.push(this.props.url+"/users");
    }
    rolesView=()=>{
        // var rolesView= new RolesView({});
        this.props.history.push(this.props.url+"/roles");
    }
    reportManagementView=()=>{
        this.props.history.push(this.props.url+"/reportmanagement");
    }
    auditingView=()=>{
        this.props.history.push(this.props.url+"/auditing");
    }
    otherSettingsView=()=>{
        this.props.history.push(this.props.url+"/settings");
    }
    accountsView=()=>{
        this.props.history.push(this.props.url+"/accounts");
    }
    syncsettingsView=()=>{
        this.props.history.push(this.props.url+"/syncsettings"); 
    }
    
    render() {
        var that=this;
        var urlparameters = window.location.search.replace("?", "").split("&");
        $.each(urlparameters, function (i, u) {
          var parameter = u.split("=");
          if (parameter[0].toLowerCase() == "bottomfooter" && parameter[1].toLowerCase() == "false") {
            that.state.displayFooter = false;
          }
        });
        return (
            <div>
                {(this.state.showleftnav && this.state.showAcountInfo) ? 
                <Adminhtml features={this.parsedFeatureJson}  displayFooter={this.state.displayFooter}
                accountsinfo={this.state.accountsinfo} groupsView={this.groupsView} syncsettingsView = {this.syncsettingsView} usersView={this.usersView} accountsView = {this.accountsView} rolesView={this.rolesView} reportManagementView={this.reportManagementView} auditingView={this.auditingView} otherSettingsView={this.otherSettingsView} licenseview={this.getLicenseView} maincontent={this.getMainContent} url={this.props.url}/> : null}
                
            </div>
        );
    }
}
export default License;