import React from "react";
import { lang } from "../js/helpers/utils/languages/language";
export default class ZaxisAttributesTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        var that = this.props;
        var agg=that.aggregation;
        if (that.aggregation == 'count'|| that.aggregation == 'Countdistinct') {
            agg = "Count (distinct)"
        } else if (that.aggregation == 'Countall') {
            agg = "Count (all)"

        } else if (that.aggregation == 'Percentile') {
            agg = "Percentile (" + that.percentileValue + ")"
        }
        return (
            //    <!-- zaxisattributesview.js -->
            <li key={that.dynamicdiv} className="boc-list-item singleliitems" data-name={that.yaxiscolumnName} data-agg={that.aggregation} data-type={that.yaxiscolumnType} title={that.yaxiscolumnDisplayName} table-name={that.yaxistableName} percentile-value={that.percentileValue}>
                <span className="itemcontainer">
                    <span className="itemtype">
                        {(that.yaxiscolumnType === 'customaggregatedfield') ? (
                            <span className="function">{lang["storyboard.fx"]}</span>
                        ) : (that.yaxiscolumnType === 'custommeasurehierarchy') ? (
                            <i className="fa fa-list"></i>
                        ) : (that.yaxiscolumnType === 'string') ? (
                            <span>{lang["storyboard.abc"]}</span>
                        ) : (that.yaxiscolumnType === 'number') ? (
                            <>123</>
                        ) : (that.yaxiscolumnType === 'date') ? (
                            <i className="fa fa-calendar"></i>
                        ) : null}
                    </span>
                    <span className="itemname">{that.yaxiscolumnDisplayName}</span>
                </span>
                <span className="itemoptions">
                    <span className="itemremove">
                        <button className="btn btn-transparent deletezaxis" onClick={that.deleteelement} type="button" title={lang["storyboard.remove"]}><i className="fa fa-trash-o"></i></button>
                    </span>
                    <span className="itemaggregation">

                        <span className="aggregationtext" aria-expanded="false" data-toggle="dropdown">[ {agg} ]</span>
                        <ul role="menu" className="dropdown-menu">
                            {(that.yaxiscolumnType != 'string' && that.yaxiscolumnType != 'date' && that.yaxiscolumnType != 'month' && that.yaxiscolumnType != 'day') ? (
                                <>
                                    {(that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                                        <li className="aggregation-li-item aggsum" onClick={that.changezAxisAggregation} title={lang["storyboard.sum"]}>{lang["storyboard.sum"]}</li>
                                    ) : null}
                                    {(that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                                        <li className="aggregation-li-item aggmin" onClick={that.changezAxisAggregation} title={lang["storyboard.minimum"]}>{lang["storyboard.minimum"]}</li>
                                    ) : null}
                                    {(that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                                        <li className="aggregation-li-item aggmax" onClick={that.changezAxisAggregation} title={lang["storyboard.max"]}>{lang["storyboard.max"]}</li>
                                    ) : null}
                                    {(that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                                        <li className="aggregation-li-item aggavg" onClick={that.changezAxisAggregation} title={lang["storyboard.avg"]}>{lang["storyboard.avg"]}</li>
                                    ) : null}
                                    {(that.isDirect == 0) ? (
                                        <li className="aggregation-li-item aggstandarddeviation" onClick={that.changezAxisAggregation} title={lang["storyboard.standard"]}>{lang["storyboard.standard"]}</li>
                                    ) : null}

                                    {(that.isDirect == 0) ? (
                                        <li className="aggregation-li-item aggpercentile" onClick={that.changezAxisAggregation} title={lang["storyboard.percentile"]}>{lang["storyboard.percentile"]}
                                            <span className="opacity-effect" ><input type="number" className="percentilebox" placeholder="1" min="25" max="100" defaultValue={that.percentileValue} /></span>
                                        </li>
                                    ) : null}
                                    {(that.isDirect == 0) ? (
                                        <li className="aggregation-li-item aggmedian" onClick={that.changezAxisAggregation} title={lang["storyboard.median"]}>{lang["storyboard.median"]}</li>
                                    ) : null}
                                </>
                            ) : null}

                            {(that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                                <li className="aggregation-li-item aggcountdistinct" onClick={that.changezAxisAggregation} title={lang["storyboard.countdis"]}>{lang["storyboard.countdistinct"]}</li>
                            ) : null}
                            {(that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                                <li className="aggregation-li-item aggcount" onClick={that.changezAxisAggregation} title={lang["storyboard.count"]}>{lang["storyboard.countall"]}</li>
                            ) : null}
                            {(that.isDirect == 1) ? (
                                <li className="aggregation-li-item pivotaggregation aggraw" onClick={that.changezAxisAggregation} title={lang["storyboard.Raw"]}>{lang["storyboard.Raw"]}</li>
                            ) : null}
                        </ul>
                    </span>
                </span>
            </li>
        );
    }

}