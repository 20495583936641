import React from "react";
import { lang } from "../js/helpers/utils/languages/language";
export default class XaxisAttributesTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        var that = this.props;
        return (
            // <!-- xaxisdropdownview.js -->
            <li key={that.dynamicdiv} className={`xs-list-item dimensionitem ${that.disable ? " hide" : ""}`} onClick={that.addxaxisvalue} data-name={that.columnName} xaxisidentity={that.xaxisidentity} iscustomfunction={that.customfunction} title={that.columnDisplayName} table-name={that.tableName} data-type={that.dataType}>
                <span className="itemtype">
                    {(that.customfunction === 1 && that.customfunction != undefined) ? (
                        <span className="function">fx</span>
                    ) : (that.customfunction === 3) ? (
                        <i className="fa fa-arrows-h"></i>
                    ) : (
                                (that.dataType === 'number') ? (
                                    <>123</>
                                ) : (that.dataType === 'date' || that.dataType === 'datetime' || that.dataType === 'month' || that.dataType === 'day') ? (
                                    <i className="fa fa-calendar"></i>
                                ) : (that.dataType == "customhierarchy") ? (
                                    <i className="fa fa-list"></i>
                                ) : (
                                                <>{lang["storyboard.abc"]}</>
                                            )
                            )}
                </span>
                <span className="itemname">{that.columnDisplayName}</span>
                {(that.customfunction === 1 && that.customfunction != undefined && that.chartType != 'mlmodel') ? (
                    <span className="itemedit callcustommeasure" data-edit={lang["storyboard.edit"]} data-name={that.columnName} title={lang["storyboard.edit"]}><i className="fa fa-pencil"></i></span>
                ) : (that.customfunction === 3 && that.chartType != 'mlmodel') ? (
                    <span className="itemedit createrange" data-edit={lang["storyboard.edit"]} data-name={that.columnName} title={lang["storyboard.edit"]}><i className="fa fa-pencil"></i></span>
                ) : (
                            (that.dataType == "customhierarchy" && that.chartType != 'mlmodel') ? (
                                <span className="itemedit callcustomhierarchy" data-edit={lang["storyboard.edit"]} data-name={that.columnName} title={lang["storyboard.edit"]}><i className="fa fa-pencil"></i></span>
                            ) : null
                        )}

                {(that.dataType === 'date' || that.dataType === 'datetime' || that.dataType === 'month' || that.dataType === 'day') ? (
                    // <!-- Custom Date Functions -->
                    // <!-- <span className="itemedit createdatedropdown" aria-expanded="false" type="button" data-edit="edit" data-name={that.columnName} title="Create New Date Field"><i className="fa fa-plus"></i></span>
                    // <ul role="menu" className="dropdown-menu dropdown-inline createdatedropdownul">
                    // 	<li className="aggregation-li-item createdateitem" title="Create <%=columnName%>_toYear"><span className="function marg-righ-5">fx</span> toYear</li>
                    // 	<li className="aggregation-li-item createdateitem" title="Create <%=columnName%>_toQuarter"><span className="function marg-righ-5">fx</span> toQuarter</li>
                    // 	<li className="aggregation-li-item createdateitem" title="Create <%=columnName%>_toMonth"><span className="function marg-righ-5">fx</span> toMonth</li>
                    // </ul> -->	
                    <></>
                ) : null}
            </li>
        );
    }
}