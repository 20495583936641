import React from 'react';
import { lang } from "../js/helpers/utils/languages/language";
export default class CustomQueryHtml extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
                <div className="workspacecustomquery-container">
                    <div className="modal-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xs-12">
                                    <form className="form-horizontal marg-top-10 form-inline">
                                        <div className="form-group form-group-sm required col-xs-6 nopadding" style={{float: "none"}}>
                                            <span class="required right legend"></span>
											<label class="subhead marg-righ-10" for="customqueryname">{lang["workspace.plcholdrcustom"]} </label>
                                                <input type="text" style={{display:"inline-block",width:"80%"}} defaultValue={this.props.wscustomqueryName !== undefined ? this.props.wscustomqueryName : ''} className={`form-control wscustomqueryname ${this.props.type == 'existing' ? 'disabled' : ''}`} placeholder={lang["workspace.plcholdrcustom"]} />
                                                <span className="cscqnamevalidate" style={{ display: "none", color: "red" }}>{lang["workspace.cscqnamevalidate"]}</span>
                                                <span className="cscqnamespecailcharacter" style={{ display: "none", color: "red" }}>{lang["workspace.cscqspecalchara"]}</span>
                                                <span className="cscqnameexists" style={{ display: "none", color: "red" }}>{lang["workspace.cscqnameexists"]}</span>
                                            
                                        </div>
                                    </form>
                                    <form className="form-horizontal marg-top-10">    
                                        <div className="form-group form-group-sm">
                                            <div className="col-xs-12">
                                                <i className="fa fa-info-circle ws-query-help">
                                                    <div className="hintdata">{lang["workspace.customqueryhelp"]}</div>
                                                </i>
                                                <div id="advancedcustomquery" className={`querydisplay clear scrollset wsadvancedcustomquery`} contentEditable="true" style={{ minHeight:'calc(50vh - 135px)'}}>
                                                    {this.props.wscustomquery !== undefined ? <>{this.props.wscustomquery}</> : null}
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="submit" className="btn btn-bird pull-left" id="validateworkspacecustomquery" onClick={(e)=>{this.props.validateworkspacecustomquery(e)}}><i className="fa fa-play"></i> {lang["workspace.validatebtn"]}</button>
                        <button className="btn btn-bird copycustomquery marg-left-10" id="copycustomquery" data-clipboard-text={this.props.wscustomquery!=undefined? this.props.wscustomquery:''}>
					     <i className="fa fa-clipboard" id="clipboards"></i>&nbsp;&nbsp;{lang["workspace.clipboard"]}
				    	</button>
                        <button type="button" className="btn btn-bird active" id="savescustomquery" onClick={(e)=>{this.props.saveworkspacecustomquery(e)}}><i className="fa fa-floppy-o"></i>{lang["workspace.savebtn"]}</button>
                    </div>
                </div>
        );
    }
}