import React from "react";
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;


export default class DrilldownHierarchyModalTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        var that = this.props;
        var dataIcon = "";
        return (
            //< !--drilldownhierarchymodal.js -- >
            <div className={`modal fade customfieldhierarchy${that.chartnumber} in`} id="customfieldhierarchy" tabindex="-1" role="dialog" aria-labelledby="summarytablesettingsmodal" aria-hidden="true" style={{ display: "block" }}>
                <div className="modal-backdrop fade in"></div>
                <div className="modal-dialog modal-sm modal-sm-400 modal-dialog-sm-center modal-dialog-center" style={{ zIndex: "1050" }}>
                    <div className="modal-content">
                        <div className="modal-header boc-modal-hf-padding">
                            <button type="button" className="close white" id="close" onClick={that.closehierarchymodal} aria-hidden="true" data-number={that.chartnumber} data-dismiss="modal">&times;</button>
                            <h4 className="modal-title" id="mySmallModalLabel"><i className="fa fa-list"></i> {lang["storyboard.drillhierarchy"]}</h4>
                        </div>
                        <div className="modal-body scrollset">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-xs-12 marg-top-10 marg-bot-10">
                                        <div className="modal-height-fix">
                                            <legend className="subhead">{lang["storyboard.hierarchyname"]}</legend>
                                            <div className="col-xs-12 nopadding marg-bot-10">
                                                <input type="text" id="hierarchyname" defaultValue={(that.existinghierarchy != null && that.existinghierarchy.length > 0) ? that.existinghierarchy[0].name : ''} className="modal-form-fields" name="hierarchyname" placeholder={lang["storyboard.hierarchyname"]} />
                                                <span className="help-block hide customhierarchy">{lang["storyboard.validname"]}</span>
                                            </div>
                                            <div className="clear"></div>
                                            <legend className="subhead">
                                            {lang["storyboard.hierarchyorder"]}
								<button className="pull-right btn btn-transparent dropdown-toggle tooltip-left" data-tooltip={lang["storyboard.add"]} onClick={that.dropdownhierarchylist} id="dropdownhierarchylist" aria-expanded="false" data-toggle="dropdown">
                                                    <i className="fa fa-plus-circle"></i>
                                                </button>
                                                <ul className="list-group dropdown-menu axis-add-menu-fix dropdownhierarchylist1" aria-labelledby="dropdownhierarchylist" role="menu">
                                                    <li className="xs-list-item"> <input className="form-control form-control-hierarchy-xaxisdropdown search-image" placeholder={lang["storyboard.search"]} id="searchinput" type="text" /></li>

                                                </ul>
                                            </legend>
                                            <span className="help-block hide customhierarchy_li_items marg-left-10">{lang["storyboard.drillhierarcy"]}</span>
                                            <div className="marg-top-10">
                                                <ul className="list-group customhierarchyattributes entitytype">
                                                    {(that.existinghierarchy != null && that.existinghierarchy.length > 0) ? (
                                                        that.existinghierarchy[0].hierarchelist.map((list, index) => {
                                                            var dataType = list.dataType;
                                                            var displayName = list.columnDisplayName;
                                                            if (that.convertedTypes.hasOwnProperty(list.columnName)) {
                                                                dataType = that.convertedTypes[list.columnName];
                                                            }
                                                            if (that.convertedNames != null) {
                                                                if (that.convertedNames.hasOwnProperty(list.columnName)) {
                                                                    displayName = that.convertedNames[list.columnName];
                                                                }
                                                            }
                                                            dataIcon = lang["storyboard.abc"];
                                                            if (dataType == "number") {
                                                                dataIcon = "123"
                                                            } else if (dataType == "date" || dataType == "datetime" || dataType === 'month' || dataType === 'day') {
                                                                dataIcon = <i className='fa fa-calendar'></i>
                                                            } else if (dataType == "customhierarchy") {
                                                                dataIcon = <i className='fa fa-list'></i>
                                                            }
                                                            return <li key={index} className="boc-list-item singleliitems" id="itemdisplay" data-name={list.columnName} title={displayName} data-type={dataType} table-name={list.tableName}>
                                                                <span className="itemtype">{dataIcon}</span>
                                                                <span className="itemname">{displayName}</span>
                                                                <span className="btn-group pull-right">
                                                                    <button className="btn btn-transparent deletecustomhierarchyfield" onClick={this.deleteelement} id="deletecustomhierarchyfields" type="button">
                                                                        <i className="fa fa-trash"></i>
                                                                    </button>
                                                                </span>
                                                            </li>
                                                        })
                                                    ) : null}
                                                </ul>
                                            </div>
                                        </div>
                                        {/* <!-- /Modal Height Fix --> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer boc-modal-hf-padding">
                            <button type="button" className="btn btn-bird" id="btn-bird" onClick={that.closehierarchymodal} data-dismiss="modal" aria-hidden="true"><i className="fa fa-times"></i> {lang["storyboard.cancelbtn"]}</button>
                            <button type="button" className="btn btn-bird customhierarchy_apply" onClick={that.applycustomhierarchy} id="customhierarchyapplys" data-num={that.chartnumber} tab-id="0" tab-name="" aria-hidden="true"><i className="fa fa-check"></i> {lang["storyboard.applybtn"]}</button>
                        </div>
                    </div>
                    {/* <!-- /.modal-content --> */}
                </div>
                {/* <!-- /.modal-dialog --> */}
            </div>
        );
    }
    deleteelement(e) {
        var $this = e.currentTarget;
        $($this).parent().parent().remove();
    }
}