import React from 'react';
import { lang } from "../../js/helpers/utils/languages/language";
export default class SummarytableGroupbyDropdownHtml extends React.Component {
    render() {
        return (
            <>
                {
                    this.props.columnvalues.map((column, index) => {
                        var dataType = column.tableDisplayType;
                        return (
                            <li key={index} title={column.columnName} data-parent={column.tableName} data-child={column.columnName} data-type={column.tableDisplayType} data-name={column.columnDisplayName} onClick={(e) => { this.props.addColumnValue(e) }} class={`xs-list-item summarytablegroupbyitem ${column.visible == 'no' ? 'hide' : ''}`}>
                                <span class="itemtype">
                                    {
                                        (dataType === 'number') ? (
                                            <>123</>
                                        ) : (dataType === 'date' || dataType === 'datetime' || dataType === 'month' || dataType === 'day') ? (
                                            <i className="fa fa-calendar"></i>
                                        ) : (dataType === 'customhierarchy' || dataType === 'custommeasurehierarchy') ? (
                                            <i className="fa fa-list"></i>
                                        ) : (<>{lang["storyboard.abc"]}</>)
                                    }
                                </span>
                                <span class="itemname" data-parent={column.tableName} data-child={column.columnName} data-type={column.tableDisplayType}>{column.columnDisplayName}</span>
                            </li>
                        )
                    })
                }
                {
                    (this.props.customFields != undefined) ? (
                        <>
                            {
                                this.props.customFields.map((column, index) => {
                                    var dataType = column.dataType;
                                    return (
                                        <li key={index} title={column.name} data-parent={column.name} data-child={column.name} data-type={column.dataType == undefined ? 'string' : column.dataType} data-name={column.name} class="xs-list-item summarytablegroupbyitem" onClick={(e) => { this.props.addColumnValue(e) }}>
                                            <span class="itemtype fx">
                                                {
                                                    (dataType === 'number') ? (
                                                        <>123</>
                                                    ) : (dataType === 'date' || dataType === 'datetime' || dataType === 'month' || dataType === 'day') ? (
                                                        <i className="fa fa-calendar"></i>
                                                    ) : (dataType === 'customhierarchy' || dataType === 'custommeasurehierarchy') ? (
                                                        <i className="fa fa-list"></i>
                                                    ) : (<>{lang["storyboard.abc"]}</>)
                                                }
                                            </span>
                                            <span class="itemname" data-parent={column.name} data-child={column.name} data-type={column.dataType == undefined ? 'string' : column.dataType}>{column.name}</span>
                                        </li>
                                    )
                                })
                            }
                        </>
                    ) : null

                }
                {
                    (this.props.customHierarchy != undefined) ? (
                        <>
                            {
                                this.props.customHierarchy.map((column, index) => {
                                    return (
                                        <li key={index} title={column.name} data-parent={column.name} data-child={column.name} data-type="customhierarchy" data-name={column.name} class="xs-list-item summarytablegroupbyitem" onClick={(e) => { this.props.addColumnValue(e) }}>
                                            <span class="itemtype"><i class="fa fa-list"></i></span>
                                            <span class="itemname" data-parent={column.name} data-child={column.name} data-type="customhierarchy">{column.name}</span>
                                        </li>
                                    )
                                })
                            }
                        </>
                    ) : null

                }
            </>
        );
    }
}