import _ from 'lodash';
import $ from 'jquery/dist/jquery';
import React from "react";
import ReactDOM from "react-dom";
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import YaxisDropDownTemplate from "./yaxisdropdown.jsx"
export default class YaxisDropDownView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicdiv: props.dynamicdiv,
            columnDisplayName: props.columnDisplayName,
            columnName: props.columnName,
            dataType: props.dataType,
            tableName: props.tableName,
            targerColumn: props.targerColumn,
            aggregation: props.aggregation,
            percentileValue: props.percentileValue,
            chartType: props.chartType,
            classname: props.classname == undefined ? 'measureitem' : props.classname,
            isDirect: ChartInfo.functions.getIsDirect(),
            isStoredProcedure: ChartInfo.functions.getIsStoredProcedure(),
            multifact: (props.multifact == 'true'),
            multifactentities: props.multifact == 'true' ? JSON.parse(props.multifactentities) : props.multifactentities,
            facttables: props.facttables,
            isfromreferenceline: props.isfromreferenceline,
            isfromsorting: props.isfromsorting,
            addyaxisvalue: props.addyaxisvalue,
            addreferencevalue: props.addreferencevalue,
            customfield:props.customfield
        }
        this.render();
    }
    render() {
        var that = this;
        var disable = false;
        var facttables = this.props.facttables;
        var isfromreferenceline = this.props.isfromreferenceline;
        var isfromsorting = this.props.isfromsorting;
        var dropdown;
        if (isfromreferenceline || isfromsorting) {
            dropdown = 'xdropdown'
        } else {
            dropdown = 'ydropdown'

        }
        if (this.state.multifact) {
            if (this.state.dataType === 'custommeasurehierarchy') {
                if (ChartInfo.functions.getMeasureHierarchy() != undefined) {
                    var meassureHierarchyObj = $.grep(ChartInfo.functions.getMeasureHierarchy(), function (e) { return e.name == that.state.columnName });
                    if (meassureHierarchyObj != undefined && meassureHierarchyObj[0].hierarchelist != undefined) {
                        var ismhvalid = false;
                        _.each(meassureHierarchyObj[0].hierarchelist, function (hierarchy) {
                            var customFields = JSON.parse(sessionStorage.getItem("customFields"));
                            if (customFields != null && customFields != undefined) {
                                var customFieldObject = $.grep(customFields, function (e) { return e.name == hierarchy.columnName });
                                if (customFieldObject != undefined && customFieldObject.length > 0) {
                                    _.each(customFieldObject[0].usedFields, function (hierarchy) {
                                        disable = ChartInfo.functions.getAllowedEntities(that.state.multifactentities, hierarchy, that.props.customfield, undefined, undefined, facttables, dropdown);
                                        if (disable) {
                                            ismhvalid = true;
                                        }
                                    });
                                } else {
                                    if (hierarchy.dataType != undefined && hierarchy.dataType === 'custommeasurehierarchy') {
                                        ismhvalid = that.checkInnerMeasureHierarchy(hierarchy.columnName);
                                    } else {
                                        disable = ChartInfo.functions.getAllowedEntities(that.state.multifactentities, hierarchy.columnName, that.props.customfield, undefined, undefined, facttables, dropdown);
                                        if (disable) {
                                            ismhvalid = true;
                                        }
                                    }
                                }
                            } else if (hierarchy.dataType != undefined && hierarchy.dataType === 'custommeasurehierarchy') {
                                ismhvalid = that.checkInnerMeasureHierarchy(hierarchy.columnName);
                            }
                            else {
                                disable = ChartInfo.functions.getAllowedEntities(that.state.multifactentities, hierarchy.columnName, that.props.customfield, undefined, undefined, facttables, dropdown);
                                if (disable) {
                                    ismhvalid = true;
                                }
                            }
                        });
                        if (ismhvalid) {
                            disable = true;
                        }
                    }
                }
            } else if (this.state.dataType === 'customaggregatedfield') {
                if (ChartInfo.functions.getCustomMeasureField() != undefined) {
                    var customMeassureObj = $.grep(ChartInfo.functions.getCustomMeasureField(), function (e) { return e.name == that.state.columnName });
                    if (customMeassureObj != undefined && customMeassureObj[0].usedFields != undefined) {
                        var iscmvalid = false;
                        _.each(customMeassureObj[0].usedFields.split(","), function (meassure) {
                            var customFields = JSON.parse(sessionStorage.getItem("customFields"));
                            if (customFields != null && customFields != undefined) {
                                var customFieldObject = $.grep(customFields, function (e) { return e.name == meassure });
                                if (customFieldObject != undefined && customFieldObject.length > 0) {
                                    _.each(customFieldObject[0].usedFields, function (customFieldObj) {
                                        disable = ChartInfo.functions.getAllowedEntities(that.state.multifactentities, customFieldObj, that.props.customfield, undefined, undefined, facttables, dropdown);
                                        if (disable) {
                                            iscmvalid = true;
                                        }
                                    });
                                } else {
                                    disable = ChartInfo.functions.getAllowedEntities(that.state.multifactentities, meassure, that.props.customfield, undefined, undefined, facttables, dropdown);
                                    if (disable) {
                                        iscmvalid = true;
                                    }
                                }
                            } else {
                                disable = ChartInfo.functions.getAllowedEntities(that.state.multifactentities, meassure, that.props.customfield, undefined, undefined, facttables, dropdown);
                                if (disable) {
                                    iscmvalid = true;
                                }
                            }
                        });
                        if (iscmvalid) {
                            disable = true;
                        }
                    }
                }
            } else if (that.props.customfield == '1') {
                //iscustomfunction
                var customFields = JSON.parse(sessionStorage.getItem("customFields"));
                var customFieldObject = $.grep(customFields, function (e) { return e.name == that.state.columnName });
                if (customFieldObject != undefined && customFieldObject.length > 0) {
                    var iscfvalid = false;
                    _.each(customFieldObject[0].usedFields, function (hierarchy) {
                        disable = ChartInfo.functions.getAllowedEntities(that.state.multifactentities, hierarchy, that.props.customfield, undefined, undefined, facttables, dropdown);
                        if (disable) {
                            iscfvalid = true;
                        }
                    });
                    if (iscfvalid) {
                        disable = true;
                    }
                }
            }
            else {
                disable = ChartInfo.functions.getAllowedEntities(this.state.multifactentities, this.state.columnName, this.props.customfield, undefined, undefined, facttables, dropdown);
            }
        }

        ReactDOM.render(<YaxisDropDownTemplate addreferencevalue={this.state.addreferencevalue} addyaxisvalue={this.state.addyaxisvalue} dynamicdiv={this.state.dynamicdiv} columnDisplayName={this.state.columnDisplayName} columnName={this.state.columnName}
            dataType={this.state.dataType} aggregation={this.state.aggregation} tableName={this.state.tableName} percentileValue={this.state.percentileValue} chartType={this.state.chartType}
            isDirect={this.state.isDirect} isStoredProcedure={this.state.isStoredProcedure} classname={this.state.classname} targerColumn={this.state.targerColumn} customfunction={this.state.customfield} disabled={disable}></YaxisDropDownTemplate>, document.getElementById(this.state.dynamicdiv));

        //</YaxisDropDownTemplate>  this.$el.append(this.template({columnDisplayName:this.state.columnDisplayName,columnName:this.state.columnName,
        //     dataType:this.state.dataType, aggregation:this.state.aggregation,tableName:this.state.tableName,percentileValue:this.state.percentileValue,chartType:this.state.chartType,
        //     isDirect:this.state.isDirect,isStoredProcedure:this.state.isStoredProcedure, classname:this.state.classname,targerColumn:this.state.targerColumn,customfunction:this.state.options.customfield, disabled:disable}));


        return (<div></div>);
    }

    checkInnerMeasureHierarchy(columnName) {
        var that = this;
        var ismhvalid = false;
        var customFields = [];
        var disable = false;
        var dropdown;
        if (this.state.isfromreferenceline || this.state.isfromsorting) {
            dropdown = 'xdropdown'
        } else {
            dropdown = 'ydropdown'

        }
        var innermeassureHierarchyObj = $.grep(ChartInfo.functions.getMeasureHierarchy(), function (e) { return e.name == columnName });
        if (sessionStorage.getItem("customFields") != undefined && sessionStorage.getItem("customFields") != null && sessionStorage.getItem("customFields") != "null") {
            customFields = JSON.parse(sessionStorage.getItem("customFields"));
        }
        if (innermeassureHierarchyObj != undefined && innermeassureHierarchyObj[0].hierarchelist != undefined) {
            _.each(innermeassureHierarchyObj[0].hierarchelist, function (innerhierarchy) {
                //if(customFields!=null && customFields!=undefined){
                var customFieldObject = $.grep(customFields, function (e) { return e.name == innerhierarchy.columnName });
                if (customFieldObject != undefined && customFieldObject.length > 0) {
                    _.each(customFieldObject[0].usedFields, function (innerHierarchyCustomFieldVal) {
                        disable = ChartInfo.functions.getAllowedEntities(that.state.multifactentities, innerHierarchyCustomFieldVal, that.props.customfield, undefined, undefined, that.state.facttables, dropdown);
                        if (disable) {
                            ismhvalid = true;
                        }
                    });
                } else {
                    if (innerhierarchy.dataType != undefined && innerhierarchy.dataType === 'custommeasurehierarchy') {
                        ismhvalid = that.checkInnerMeasureHierarchy(innerhierarchy.columnName);
                    } else {
                        disable = ChartInfo.functions.getAllowedEntities(that.state.multifactentities, innerhierarchy.columnName, that.props.customfield, undefined, undefined, that.state.facttables, dropdown);
                        if (disable) {
                            ismhvalid = true;
                        }
                    }
                }
            });
        }
        if (ismhvalid) {
            return true;
        } else {
            return false;
        }
    }
}

