import React, { Component } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { lang} from "../../js/helpers/utils/languages/language";
const $ = window.$;
const columns = [
    { dataField: 'sync_name', text: 'Sync Job Name', sort: true },
    { dataField: 'category', text: 'Category', sort: true },
    { dataField: 'create_date', text: 'Created Date', sort: true },
    { dataField: 'update_date', text: 'Updated Date', sort: true },
    { dataField: 'corn_details', text: 'Configuration', sort: true },
    { dataField: 'status', text: 'Status', sort: true },
    { dataField: 'edit', text: 'Edit', sort: false },
];

const RemotePagination = ({ data, page, sizePerPage, onTableChange, totalSize }) => (
    <BootstrapTable
        remote
        keyField="sync_name"
        data={data}
        columns={columns}
        pagination={paginationFactory({ page, sizePerPage, totalSize })}
        onTableChange={onTableChange}
    />
);

export default class SyncConfiguarationsMainTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            data: props.data.slice(0,  10),
            sizePerPage: 10,
            totalRecords: props.totalRecords,
        };
    }
    hideleft = () => {
        $('.leftarea').addClass('hide');

        $('.hideleft').removeClass('hide');
        $('.showleft').addClass('hide');

        $('.leftshow').removeClass('hide');
        $('.lefthide').addClass('hide');

        $('.w2ui-resizer').resize();
        $(".boc-admin-main .notifications-table-container table").bootstrapTable('resetView');
    }
    leftshow = () => {
        $('.leftarea').removeClass('hide');

        $('.hideleft').removeClass('hide');
        $('.showleft').addClass('hide');

        $('.leftshow').addClass('hide');
        $('.lefthide').removeClass('hide');

        $('.w2ui-resizer').resize();
        $(".boc-admin-main .notifications-table-container table").bootstrapTable('resetView');
    }
    lefthide = () => {
        $('#icontent').addClass('hide');
        $('.leftarea').addClass('shownsingle');
        $('.editrole').addClass('disabled');
        $('.editgroup').addClass('disabled');
        $('.editaccount3').addClass('disabled');
        $('.editschedule').addClass('disabled');
        $('.editmail').addClass('disabled');
        $('.viewhistory').addClass('disabled');

        $('.hideleft').addClass('hide');
        $('.showleft').removeClass('hide');

        $('.leftshow').addClass('hide');
        $('.lefthide').removeClass('hide');

        $('.w2ui-resizer').resize();
        $(".boc-admin-main .notifications-table-container table").bootstrapTable('resetView');
    }
    showleft = () => {
        $('#icontent').removeClass('hide');
        $('.leftarea').removeClass('shownsingle');
        $('.editrole').removeClass('disabled');
        $('.editgroup').removeClass('disabled');
        $('.editaccount3').removeClass('disabled');
        $('.editschedule').removeClass('disabled');
        $('.editmail').removeClass('disabled');
        $('.viewhistory').removeClass('disabled');

        $('.hideleft').removeClass('hide');
        $('.showleft').addClass('hide');

        $('.leftshow').addClass('hide');
        $('.lefthide').removeClass('hide');

        $('.w2ui-resizer').resize();
        $(".boc-admin-main .notifications-table-container table").bootstrapTable('resetView');
    }
    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, data }) => {
        const currentIndex = (page - 1) * sizePerPage;
        {
            type == "sort" ?
                setTimeout(() => {
                    let result;
                    if (sortOrder === 'asc') {
                        result = data.sort((a, b) => {
                            if (a[sortField] > b[sortField]) {
                                return 1;
                            } else if (b[sortField] > a[sortField]) {
                                return -1;
                            }
                            return 0;
                        });
                    } else {
                        result = data.sort((a, b) => {
                            if (a[sortField] > b[sortField]) {
                                return -1;
                            } else if (b[sortField] > a[sortField]) {
                                return 1;
                            }
                            return 0;
                        });
                    }
                    this.setState(() => ({
                        data: result
                    }));
                }, 10) :
                setTimeout(() => {
                    this.setState(() => ({
                        page,
                        data: this.props.data.slice(currentIndex, currentIndex + sizePerPage),
                        sizePerPage
                    }));

                }, 10);
        }
    }
    render() {
        var that = this.props;
        const { data, sizePerPage, page, totalRecords } = this.state;
        return (
            <div className="row in-back">
                <div className="row-same-height-inner">

                    <div className="col-xs-4 col-xs-height in-panes leftarea">
                        <div className="btn btn-transparent hideleft toggleleft" onClick={this.hideleft}><i className="fa fa-grey fa-angle-left"></i></div>
                        <div className="btn btn-transparent showleft toggleleft hide" onClick={this.showleft}><i className="fa fa-grey fa-angle-left"></i></div>
                        <div className="pull-right">
                            < button className="btn btn-bird btn-sm adduser btn-highlight" onClick={that.createSyncBucket} id="addsyncbucket"><i className="fa fa-plus marg-righ-5"></i>{lang["admin.addsyncbtn"]}</button>
                        </div>
                        <div className="listcontainer col-xs-12">
                            <div className="notifications-table-container">
                                <div class="bootstrap-table" id="syncbucketstable">
                                    <div class="fixed-table-toolbar">
                                        <RemotePagination
                                            data={data}
                                            page={page}
                                            sizePerPage={sizePerPage}
                                            totalSize={totalRecords}
                                            onTableChange={this.handleTableChange}
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="clear" className="icontent"></div>
                    </div>
                    <div className="col-xs-6 col-xs-height in-panes" id="icontent">
                        <div className="btn btn-transparent leftshow toggleright hide" onClick={this.leftshow}><i className="fa fa-grey fa-angle-right"></i></div>
                        <div className="btn btn-transparent lefthide toggleright" onClick={this.lefthide}><i className="fa fa-grey fa-angle-right"></i></div>
                        <div className="innerothersarea mailconfigform"> </div>
                    </div>
                </div>
            </div>


        );
    }
    componentDidMount(){
        this.setState({
            sizePerPage:10
        })
    }
}