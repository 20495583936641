import React, { Component } from "react";
import { lang } from "../../js/helpers/utils/languages/language";
import ReactDOM from "react-dom";
import _ from 'lodash';
import PubSub from 'pubsub-js'
//import $ from 'jquery/dist/jquery';
import SendRequest from '../../SendRequest';
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
const $ = window.$;

export default class UpdateAlertView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        var that = this;
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getAllGroups",
            queryString: "",
            sucessFunction: groupResponse => {
                SendRequest({
                    url: "getUsersForSharing",
                    queryString: "",
                    sucessFunction: userResponse => {
                        //  reportId:that.props.reportId
                        that.render(groupResponse, userResponse);
                    },
                    rejectFunction: () => { },
                    headers: requestHeaders
                });
            },
            rejectFunction: () => { },
            headers: requestHeaders
        });
    }
    render(groupResponse, userResponse) {
        var chartData = ChartInfo.functions.getChartDetails(this.props.chartnumber);
        var yAxisObjects = JSON.parse(chartData)["yAxis"];
        var yaxisArray = JSON.parse(chartData)["yAxis"];
        var xaxisArray = JSON.parse(chartData)["xAxis"];
        var legendArray = JSON.parse(chartData)["legend"];
        var selectedUsers = null;
        var selectedGroups = null;
        var alertName = "";
        var that = this;
        var message = "";
        var count = 0;
        var sendemail = "";
        var sendemailreporturl = "";
        var hieYfield = [];
        _.each(yAxisObjects, function (value, index) {
            if (value.dataType != undefined && value.dataType == "custommeasurehierarchy") {
                var hirerachyList = ChartInfo.functions.getMeasureHierarchy();
                _.each(hirerachyList, function (hievalue, inde) {
                    if (value.columnName != undefined && value.columnName == hievalue.name) {
                        var hieobj = hievalue.hierarchelist;
                        _.each(hieobj, function (obj, i) {
                            hieobj[i].displayName = hieobj[i].columnDisplayName;
                            hieYfield.push(hieobj[i]);
                        });
                    }
                });
            } else {
                hieYfield.push(yAxisObjects[index]);
            }
        });
        if (this.props.id != undefined) {
            var jsonArray = this.props.alertdetails;
            var conditionsArray = [];
            if (jsonArray != undefined) {
                yAxisObjects = _.clone(jsonArray[0]["conditions"]);
                conditionsArray = _.clone(jsonArray[0]["conditions"]);
                count = conditionsArray.length;
                selectedUsers = jsonArray[0]["users"];
                selectedGroups = jsonArray[0]["groups"];
                alertName = jsonArray[0].name;
                message = jsonArray[0].message;
                sendemail = jsonArray[0].sendemail;
                sendemailreporturl = jsonArray[0].sendemailreporturl;
                //hieYfield=yAxisObjects;
            }
            if (hieYfield.length >= count) {
                var yaxisArray = hieYfield;
                for (var i = 0; i < yaxisArray.length; i++) {
                    var temp = $.grep(conditionsArray, function (e) { return e.displayName == yaxisArray[i]["displayName"] && e.aggregation == yaxisArray[i]["aggregation"] })
                    if (temp != undefined && temp.length != 0) {
                        yAxisObjects[i] = temp[0];
                    } else {
                        yAxisObjects[i] = hieYfield[i];
                    }
                }
            } else {
                yAxisObjects = hieYfield;
            }
            var that = this;
            ChartInfo.functions.getAlerts();
            ChartInfo.functions.setAlerts($.grep(ChartInfo.functions.getAlerts(), function (e) { return e.id != that.props.id }));
            hieYfield = yAxisObjects;
        }
        if(document.getElementById(that.props.dynamicdiv)==null){
             return;
        }
        $(".alertscontainer").hide();
        //        ReactDOM.unmountComponentAtNode(document.getElementById(that.props.dynamicdiv));
        ReactDOM.render(<CreatealertTemplate selectalertcondition={this.selectalertcondition} closealert={this.closealert} applyalert={this.applyalert}
            groupResponse={groupResponse} userResponse={userResponse} yAxisObjects={hieYfield}
            selectedUsers={selectedUsers} selectedGroups={selectedGroups} alertName={alertName} message={message} features={this.props.features}
        ></CreatealertTemplate>, document.getElementById(that.props.dynamicdiv));
        /*this.$el.html(this.template({groupResponse:groupResponse, userResponse:userResponse, yAxisObjects:yAxisObjects, 
            selectedUsers:selectedUsers, selectedGroups:selectedGroups, alertName:alertName, message:message,features:this.props.features}));*/
        if (sendemail) {
            $('#emailalert').prop('checked', true);
        } else {
            $('#emailalert').prop('checked', false);
        }
        if(sendemailreporturl){
            $('#emailreporturl').prop('checked', true);
            $('.emailreporturl').show();
        }else{
            $('#emailreporturl').prop('checked', false);
        }
        $(".notify-users").chosen({
            no_results_text: "Oops, nothing found!",
            width: "80%",
            search_contains: true
        });
        _.each(selectedUsers, function (value, index) {
            $('.notify-users optgroup[label=Users] option[userId="' + value.userId + '"]').prop('selected', true);
            $(".notify-users").trigger("chosen:updated");
        });
        _.each(selectedGroups, function (value, index) {
            $('.notify-users optgroup[label=Groups] option[groupId="' + value.groupId + '"]').prop('selected', true);
            $(".notify-users").trigger("chosen:updated");
        });
        _.each(yAxisObjects, function (value, index) {
            //$(".alertcondition .form-inline").find(".selectalertcondition[columnName='"+value.columnName+"'] option[name='"+value.condition+"']").eq(index).prop('selected',true);
            $(".alertcondition .form-inline").find(".selectalertcondition").eq(index).find("option[name='" + value.condition + "']").prop('selected', true);
            if (yAxisObjects[0].condition=="between") {
                 $(".alertcondition .form-inline").find(".alertmultiplevalue").removeClass('hide');
                 $(".alertcondition .form-inline").find(".alertsinglevalue .singlevalue").addClass("hide")
  } 

        });
        $(".alertscontainer").show("slow");
        $(".selectalertcondition").trigger("change");
        return (<div></div>);
    }
    selectalertcondition = (e) => {
        var $this = e.currentTarget;
        var currentVal = $($this).val();
        if (currentVal === "6") {
            $($this).parent().next(".alertsinglevalue").addClass('hide');
            $($this).parent().next().next(".alertmultiplevalue").removeClass('hide');
        } else {
            $($this).parent().next(".alertsinglevalue").removeClass('hide');
            $($this).parent().next().next(".alertmultiplevalue").addClass('hide');
        }
    }
    applyalert = (e) => {
        e.preventDefault();
        e.stopPropagation();
        var groupArray = [];
        var userArray = [];
        var conditionsArray = [];
        var tempObj = {};
        try {
            $(".alertcondition .form-inline").find(".alertsinglevalue .singlevalue").removeClass("bird-has-error");
            $(".alertmainclass .alertName").removeClass("bird-has-error");
            $(".alertcondition .form-inline").find(".alertmultiplevalue .from").removeClass("bird-has-error");
            $(".alertcondition .form-inline").find(".alertmultiplevalue .to").removeClass("bird-has-error");
            $('.alertmainclass .chosen-container .chosen-choices').removeClass("bird-has-error");
            $(".contditon-container").removeClass("contditon-container-has-error").removeClass("bird-has-error")
            tempObj["name"] = $(".alertmainclass .alertName").val();
            if (tempObj["name"] == "" || tempObj["name"] == undefined || tempObj["name"].trim().length == 0) {
                throw new Error("nameempty");
            }
            tempObj["sendemail"] = $(".alertmainclass #emailalert").is(":checked");
            tempObj["sendemailreporturl"] = $(".alertmainclass #emailreporturl").is(":checked");
            tempObj["message"] = $(".alertmainclass .message").val();
            if (ChartInfo.functions.isScriptData(tempObj["name"]) || ChartInfo.functions.isScriptData(tempObj["message"])) {
                return true;
            }
            $('.notify-users optgroup[label=Groups] option:selected').each(function (index, value) {
                var groupObject = {};
                groupObject["groupName"] = $(value).attr("groupName");
                groupObject["groupId"] = $(value).attr("groupId");
                groupArray.push(groupObject);
            });
            $('.notify-users optgroup[label=Users] option:selected').each(function (index, value) {
                var userObject = {};
                userObject["userName"] = $(value).attr("userName");
                userObject["userEmail"] = $(value).attr("userEmail");
                userObject["userId"] = $(value).attr("userId");
                userObject["clientId"] = $(value).attr("clientId");
                userArray.push(userObject);
            });
            if (userArray.length == 0 && groupArray.length == 0) {
                throw new Error("notifytoempty");
            }
            var chartData = ChartInfo.functions.getChartDetails(this.props.chartnumber);
            var xaxisArray = JSON.parse(chartData)["xAxis"];
            var legendArray = JSON.parse(chartData)["legend"];
            var chartnumber = this.props.chartnumber;
            var reportId = this.props.reportId
            $(".alertcondition .form-inline").each(function (index, value) {
                var conditionObj = {};
                if ($(value).find(".isconditionselected").is(':checked') == true) {
                    conditionObj["columnName"] = $(value).find(".columnnameagg").attr("columnName");
                    conditionObj["aggregation"] = $(value).find(".columnnameagg").attr("aggregation");
                    conditionObj["dataType"] = $(value).find(".columnnameagg").attr("dataType");
                    conditionObj["tableName"] = $(value).find(".columnnameagg").attr("tableName");
                    conditionObj["displayName"] = $(value).find(".columnnameagg").attr("displayName");
                    conditionObj["condition"] = $(value).find(".selectalertcondition option:selected").attr("name");
                    conditionObj["legendArray"] = legendArray;
                    conditionObj["xaxisArray"] = xaxisArray;
                    conditionObj["chartnumber"] = chartnumber;
                    conditionObj["reportId"] = reportId;
                    if ($(value).find(".selectalertcondition option:selected").attr("name") == "between") {
                        conditionObj["from"] = $(value).find(".alertmultiplevalue .from").val();
                        conditionObj["to"] = $(value).find(".alertmultiplevalue .to").val();
                        if (conditionObj["from"] == "" || conditionObj["to"] == "") {
                            throw new Error("multivaluefromempty");
                        }
                        if (conditionObj["to"] == "" || conditionObj["to"] == "") {
                            throw new Error("multivaluetoempty");
                        }
                        if (conditionObj["to"] < conditionObj["from"]) {
                            throw new Error("multivaluetoempty");
                        }
                    } else {
                        conditionObj["value"] = $(value).find(".alertsinglevalue .singlevalue").val();
                        if (conditionObj["value"] == "") {
                            throw new Error("singlevalueempty");
                        }
                    }
                    conditionsArray.push(conditionObj);
                }
            });
            if (conditionsArray.length < 1) {
                throw new Error("select the alertcondition");
            }
            tempObj["chartnumber"] = chartnumber;
            tempObj["groups"] = groupArray;
            tempObj["users"] = userArray;
            tempObj["conditions"] = conditionsArray;
            $(".alertscontainer").hide("slow");
            var that = this;
            setTimeout(function () { $(".alertscontainer").empty().show() }, 600);
            var isAlertNew = true;
            var alert_Type = $(".alertcondition .form-inline").find(".selectalertcondition option:selected").attr("name");
            var alert_Value = {};
            var alert_condition = conditionsArray[0].condition;
            alert_Value["columnName"] = $(".alertcondition .form-inline").find(".columnnameagg").attr("columnName")
            if ($(".alertcondition .form-inline").find(".selectalertcondition option:selected").attr("name") == "between") {
                alert_Value["from"] = $(".alertcondition .form-inline").find(".alertmultiplevalue .from").val();
                alert_Value["to"] = $(".alertcondition .form-inline").find(".alertmultiplevalue .to").val();
            } else {
                alert_Value["value"] = $(".alertcondition .form-inline").find(".alertsinglevalue .singlevalue").val();
            }
            if (that.props.id != undefined) {
                isAlertNew = false;
            }
            if(tempObj["sendemail"] && tempObj["sendemailreporturl"]){
                tempObj["alerturl"] = window.location.origin + "/"+window.localStorage.getItem("appName")+"/welcome";
            }
            this.save(alert_Type, alert_Value, alert_condition, tempObj["message"], tempObj["name"], that.props.reportId, tempObj, that.props.id, isAlertNew);
        } catch (e) {
            if (e.message == "singlevalueempty") {
                $(".alertcondition .form-inline").find(".alertsinglevalue .singlevalue").addClass("bird-has-error");
            }
            if (e.message == "nameempty") {
                $(".alertmainclass .alertName").addClass("bird-has-error");
            }
            if (e.message == "notifytoempty") {
                $('.alertmainclass .chosen-container .chosen-choices').addClass("bird-has-error");
            }
            if (e.message == "multivaluefromempty") {
                $(".alertcondition .form-inline").find(".alertmultiplevalue .from").addClass("bird-has-error");
            }
            if (e.message == "multivaluetoempty") {
                $(".alertcondition .form-inline").find(".alertmultiplevalue .to").addClass("bird-has-error");
            }
            if (e.message == "select the alertcondition") {
                $(".contditon-container").addClass("contditon-container-has-error").addClass("bird-has-error");
            }
        }
    }
    closealert = (e) => {
        $(".alertscontainer").hide("slow");
        var that = this;
        setTimeout(function () {
            $(".alertscontainer").empty();
            $(".alertscontainer").show();
        }, 0);
    }
    save(alert_Type, alert_Value, alert_Condition, alert_message, alert_Name, reportId, details, alertId, isAlertNew) {
         
        // var filterid = window.localStorage.getItem("current")!=undefined ? atob(window.localStorage.getItem("current")):"";
        var filterid = "";
        var requestbody = new FormData();
        requestbody.set("alertType", alert_Type);
        requestbody.set("alertValue", JSON.stringify(alert_Value));
        requestbody.set("alertCondition", alert_Condition);
        requestbody.set("alertMessage", alert_message);
        requestbody.set("alertName", alert_Name);
        requestbody.set("alertDetails", JSON.stringify(details));
        requestbody.set("reportId", reportId);
        requestbody.set("alertId", alertId);
        requestbody.set("isAlertNew", isAlertNew);
        requestbody.set("filterid", filterid);

        let requestHeaders = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "createalert",
            body: requestbody,
            sucessFunction: (collection) => {
                PubSub.publish('redrawTable');
                var JsonArray = ChartInfo.functions.getAlerts();
                var id = collection.alertId
                details["id"] = id;
                JsonArray.push(details)
                ChartInfo.functions.setAlerts(JsonArray);
                // $('#alertstable').bootstrapTable('removeByUniqueId', id);
                // $('#alertstable').bootstrapTable('insertRow', {
                //     index: $('#alertstable tr').length - 1,
                //     row: {
                //         id: parseInt(id),
                //         name: alert_Name,
                //         edit: '<button class="btn btn-transparent btn-sm aledit" id=' + id + '><i class="fa fa-pencil"></i></button>',
                //         remove: '<button class="btn btn-transparent btn-sm aldelete" id=' + id + '><i class="fa fa-trash"></i></button>'
                //     }
                // });
            },
            rejectFunction: this.reportDataError,
            headers: requestHeaders
        });
    }
}

class CreatealertTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    emailalertcheck=(e)=>{
       if($("#emailalert").is(":checked")){
           $(".emailreporturl").show();
       }else{
        $(".emailreporturl").hide();
       }
    }
    render() {
        var that = this.props;
        return (
            //    <!-- updatealertview.js -->
            <div className="alertbox nopadding">
                <div className="col-lg-3 col-xs-12 alertmainclass">
                    <legend className="subhead nopadding marg-bot-10">{lang["storyboard.alerttitle"]}</legend>
                    <input type="name" className="form-control alertName input-sm" id="createtitles" defaultValue={that.alertName} placeholder={lang["storyboard.alerttitle"]}></input>
                    <legend className="subhead nopadding marg-top-10">{lang["storyboard.notifyto"]}</legend>
                    <div className="col-xs-12 nopadding text-center">
                        <select data-placeholder={lang["storyboard.placeholdgroups"]} className="notify-users form-control" id="createnotifyalert" multiple tabindex="6">
                            <option value=""></option>
                            <optgroup label={lang["storyboard.groups"]}>
                                {that.groupResponse.map((value, index) => {
                                    return <option key={index} groupId={value.groupId} groupName={value.groupName}>{value.groupName}</option>
                                })}
                            </optgroup>
                            <optgroup label={lang["storyboard.users"]}>
                                {that.userResponse.map((value, index) => {
                                    return <option userId={value.userid} userEmail={value.email} userName={value.loginname} clientId={value.clientId}>{value.username}</option>
                                })}
                            </optgroup>
                        </select>
                    </div>
                    <div className="clear"></div>
                    <legend className="subhead nopadding">{lang["storyboard.message"]}</legend>
                    <textarea className="form-control message input-sm resize-vertical" id="createmessagealert">{that.message}</textarea>
                    {that.features.map((feature, index) => {
                        if (feature.moduleName == 'my_report') {
                            return feature.features.map((feature1, index) => {
                                if (feature1.featureName == 'viewreport_maximize_alerts_email' && feature1.allow == true) {
                                    return <div className="col-xs-12 checkbox nopadding">
                                        <label className="subhead" for="emailalert"><input type="checkbox" id="emailalert" defaultChecked="checked" onClick={this.emailalertcheck}/>{lang["storyboard.alertemail"]}</label>
                                        <label className="subhead emailreporturl" for="emailreporturl" style={{ display: 'none' }}><input type="checkbox" id="emailreporturl" />{`Send Report Url`}</label>
                                    </div>
                                }
                            })
                        }
                    })}
                </div>
                <div className="col-lg-9 col-xs-12 alertcondition">
                    <legend className="subhead nopadding">{lang["storyboard.alertcondtn"]}</legend>
                    <div className="contditon-container">
                        {that.yAxisObjects.map((value, index) => {
                            return <div className="form-inline">
                                <div className="form-group">
                                    <input type="checkbox" name="title" className="form-control isconditionselected input-sm" id="createalertcondition" defaultChecked="checked" />
                                </div>
                                <div className="form-group">
                                    <input type="text" name="title" className="form-control columnnameagg input-sm" displayName={value.displayName} columnName={value.columnName} aggregation={value.aggregation} dataType={value.dataType} tableName={value.tableName}
                                        //    value={value.displayName}[{value.aggregation}] title={value.displayName}[{value.aggregation}] disabled="disabled"/> is
                                        value={`${value.displayName}[${value.aggregation}]`} title={`${value.displayName}[${value.aggregation}]`} disabled="disabled" />{lang["storyboard.is"]}
                               </div>
                                <div className="form-group">
                                    <select className="form-control  input-sm selectalertcondition" onChange={that.selectalertcondition} id="createselectalertcondition" columnName="<%= value.columnName%>">
                                        <option value="0" name="equal">{lang["storyboard.equal"]}</option>
                                        <option value="1" name="notequal">{lang["storyboard.notequal"]}</option>
                                        <option value="2" name="greaterthan">{lang["storyboard.grtrthan"]}</option>
                                        <option value="3" name="lessthan">{lang["storyboard.lserthan"]}</option>
                                        <option value="4" name="greaterthanorequal">{lang["storyboard.grtrthanequal"]}</option>
                                        <option value="5" name="lessthanorequal">{lang["storyboard.lserthanequal"]}</option>
                                        <option value="6" name="between">{lang["storyboard.between"]}</option>
                                    </select>
                                </div>
                                <div className="form-group alertsinglevalue">
                                {lang["storyboard.to"]} <input type="number" name="title" defaultValue={(value.value == undefined) ? '' : value.value} className="form-control singlevalue input-sm" autoComplete="off" />
                                </div>
                                <div className="form-group alertmultiplevalue hide">
                                    <input type="number" name="title" defaultValue={(value.from == undefined) ? '' : value.from} className="form-control from input-sm max-width-100" autoComplete="off" />
                                    {lang["storyboard.and"]}
                                   <input type="number" name="title" defaultValue={(value.to == undefined) ? '' : value.to} className="form-control to input-sm max-width-100" autoComplete="off" />
                                </div>
                            </div>
                        })}
                    </div>
                </div>
                <div className="text-right marg-top-10 nopadding alert-action-container">
                    <button className="btn btn-bird btn-sm closealert" onClick={that.closealert} id="createalertcancel"><i className="fa fa-times"></i> {lang["storyboard.cancelbtn"]}&nbsp;&nbsp;</button>
                    <button className="btn btn-bird btn-sm addalert" onClick={that.applyalert} id="createalertadd"><i className="fa fa-plus"></i> {lang["storyboard.addbtn"]}&nbsp;&nbsp;</button>
                </div>
            </div>);
    }
}