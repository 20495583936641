import React from 'react';
import ReactDOM from 'react-dom';
import AceEditor from 'react-ace';
import _ from 'underscore';
import { getMessage } from '../js/helpers/utils/BirdMessage';
import SendRequest from '../SendRequest';
import DatahubModifyEntitiesHtml from './DatahubModifyEntitiesHtml.jsx';
import CustomQueryHtml from './CustomQueryHtml.jsx';
import Clipboard from 'clipboard'
import DatahubItemsSettingsHtml from './DatahubItemSettingsHtml';
import * as ChartInfo from '../js/helpers/utils/chartinfo';
import { lang } from "../js/helpers/utils/languages/language";
import { format } from 'sql-formatter';
const ace = require('brace');
const $ = window.$;

export default class DatahubCustomQuery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datahubEntitiesCollection: props.dataHubEntitiesCollection
        }
        this.entityName = props.entityName;
        this.connId = props.connId;
        this.connectiondata = props.connectiondata;
        this.element = props.element
        this.isSavedCustomQuery = props.isSavedCustomQuery;
        this.datahubdetails = {};
        this.tables = [];
        this.query = {};
        this.datahubentities = {};
        this.reportcolumns = {};

    }
    createDataHubEntitiesObject = (response) => {
        if (response.success == false || response.hasOwnProperty("error")) {
            if (response.status != "fail") {
                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('hide');
                $('.birdmessage h2').empty().append('Error : ');
                $('.birdmessage h2').append('<span class="errorcode">');
                $('.birdmessage .errorcode').empty().append(response.errorCode);
                $('.birdmessage-info').empty().text(lang["Datahub.unablefetch"]);
                $('.details').addClass('show');
                if (response.success == false || response.hasOwnProperty("error")) {
                    $('.messagedetails xmp').empty().append(response.errorTrace);
                }
                else {
                    $('.messagedetails xmp').empty().append(response.error);
                }
            }
            setTimeout(function () {
                $("#datahubstepscontainer").steps("previous");
                //	$('.spinner').hide();
            }, 1000);
            //$('.spinner').hide();			
            if ($("#datahubentitycontainer li").length > 0) {
                var emptyNameCount = 0;
                $('.spinner').show();
                $("#datahubentitycontainer>li").removeClass('warning');
                _.each($("#datahubentitycontainer li"), function (ent, index) {
                    var entity = $(ent).find("input.scopeitem");
                    var entityid = $(entity).attr("data_parent");
                    var entityname = $(entity).attr("data-value")
                    if (entityname.includes('.')) {
                        entityname = $(entity).attr("data-value").split(".")[1];
                    }
                    if (entityname == "Twitter") {
                        entityname = "twitter";
                    }
                    var errorTablename = response.tablename.replace(/[`"]/g, '');
                    if (entityname == errorTablename && entityid == response.connId) {
                        setTimeout(function () {
                            $(ent).removeClass("active");
                            $(ent).addClass("warning");
                            emptyNameCount++;
                        }, 2000);
                    }
                });
                setTimeout(function () {
                    if (emptyNameCount > 0) {
                        $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-infor').addClass('birdmessage-warning');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-info-circle').addClass('fa-exclamation-triangle');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Warning');
                        $("#datahubstepscontainer").steps("previous");
                        if (response.error == "No Data") {
                            $('.birdmessage-info').empty().text(lang["Datahub.nodataentity"]);
                        }
                        else {
                            $('.birdmessage-info').empty().text("The Column Name '" + response.columnName + "'" + "" + getMessage('BirdWarning149'));
                        }
                        $('.spinner').hide();
                        return;
                    }
                }, 2000);
            }
            return
        }
        this.datahubdetails = {};
        this.tables = [];
        var k = 0;
        _.each(response, (i, j) => {
            var data = JSON.parse(i);
            if (this.datahubentities !== undefined && Object.keys(this.datahubentities).length > 0 && this.datahubentities[j] !== undefined && this.datahubentities[j].length > 0) {
                data.entities = this.datahubentities[j];
            }
            if (this.reportcolumns !== undefined && Object.keys(this.reportcolumns).length > 0 && this.reportcolumns[j] !== undefined && this.reportcolumns[j].length > 0) {
                data.entities = this.reportcolumns[j];
            }
            this.datahubdetails[j] = data;
            var key = j.split("&&&");
            var connAccessId = key[0];
            var entity = key[1];
            var connectionEntity = {};
            connectionEntity['connaccessid'] = connAccessId;
            connectionEntity['name'] = entity;
            var dupname = entity.replace(/([-_;?&.,%#+*~\ '"!^$[\]()<=>`|\/@])/g, "");
            k++;
            dupname = dupname + k + new Date().getTime();
            connectionEntity['dupname'] = dupname;
            /*	if($(".viewreport-list-item.datahub-customquery-item[data_parent='"+connAccessId+"']").hasClass('query-exists')){
                    $(".viewreport-list-item.datahub-customquery-item").closest("li.query-exists").addClass('error');
                    $("#datahubstepscontainer").steps("previous");
                    $('.spinner').hide();
                }*/
            if (data.dbType != null && data.dbType != undefined) {
                connectionEntity['type'] = data.dbType;
            }

            this.tables.push(connectionEntity);
        });
    }
    getAllFeildsJson = (connectionid, entity) => {
        var tableDetails = this.datahubdetails[connectionid + "&&&" + entity];
        var tableEntities = tableDetails.entities;
        var feildData = [];
        _.each(tableEntities, (i, j) => {
            var entity = {};
            var dataType = i.dataType;
            if (i.dataType == undefined && i.tableDisplayType != undefined) {
                dataType = i.tableDisplayType;
            }
            if (dataType === "double" || dataType === "long") {
                dataType = "number"
            }
            if (i.dataType === "double" || i.dataType === "long") {
                i.dataType = "number"
            }
            if (i.dataType === 'date' || i.tableDisplayType === 'date') {
                if (i.dateFormat != undefined && i.dateFormat != null && i.dateFormat != null) {
                    entity['dateFormat'] = i.dateFormat;
                }
            }
            entity['visible'] = "yes";
            entity['tableAliasName'] = i.tableName;
            entity['columnDisplayName'] = i.columnName;
            entity['dateFormat'] = i.dateFormat;
            entity['tableDisplayName'] = i.tableName;
            entity['tableName'] = i.tableName;
            entity['orginalColumnName'] = i.columnName;
            entity['columnName'] = i.columnName;
            entity['tableDisplayType'] = dataType;
            if (i.isPartitionKey != undefined) {
                entity['isPartitionKey'] = i.isPartitionKey;
            }
            entity['name'] = i.columnName;
            entity['type'] = dataType;
            entity['connaccessid'] = connectionid;
            entity['tablename'] = i.tableName;
            feildData.push(entity);

        });
        return feildData;
    }
    loadScript = function () {
        var tag = document.createElement('script');
        tag.type = 'text/javascript';
        tag.async = true;
        tag.innerHTML = "this.editor = ace.edit('advancedcustomquery')";
        document.body.appendChild(tag);
    }
    componentDidMount() {
        var that = this;
        $("#settingscontainer.showsettings").remove();
        $("#datahubentitycontainer").removeClass('settingsopened');
        $("#settingscontainer").remove();
        $("#datahubentitycontainer").after('<div id="settingscontainer"></div>');
        $('#settingscontainer').empty();
        if (this.isSavedCustomQuery) {
            that.renderExisitingData(that.isSavedCustomQuery, that.connectiondata);
        }
        SendRequest({
            url: "gethubentities",
            queryString: "?isFromWorkspace=" + false,
            sucessFunction: (resp) => {

                this.state.datahubEntitiesCollection = resp

            },
            rejectFunction: (error) => {
                console.log("can't process /gethubentities request" + error.message);
            },
            headers: {
                method: "get",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            }
        });
        if (!this.isSavedCustomQuery) {
            var that = this;
            var entitySourceName = "";
            if (this.props.connectiondata != undefined && this.props.connectiondata != null && this.props.connectiondata != "") {
                entitySourceName = <><i className='fa fa-link'></i>{this.props.connectiondata.text}</>
            }
            ReactDOM.render(
                <CustomQueryHtml
                    connId={this.props.connectiondata.value}
                    customqueryName={""}
                    entityname={""}
                    isCustomQuery={true}
                    isSaved={false}
                    entitySourceName={entitySourceName}
                    close={this.closesettingscontainer}
                    savescustomquery={this.savescustomquery}
                    validatedatahubcustomquery={this.validatedatahubcustomquery}
                    applydatahubmodifyentities={this.applydatahubmodifyentities}
                />,
                document.getElementById('settingscontainer')
            );
            var ua = window.navigator.userAgent;
            var edge = ua.indexOf("Edge");
            setTimeout(function () {
                if (edge < 0) {
                    var that = this;
                    ace.require("brace/ext/language_tools");
                    this.editor = ace.edit("advancedcustomquery");
                    this.editor.setTheme("ace/theme/sqlserver");
                    this.editor.session.setMode("ace/mode/sql");
                    this.editor.setOptions({
                        enableBasicAutocompletion: true,
                        enableSnippets: true,
                        enableLiveAutocompletion: true
                    });
                    this.editor.setOption("wrap", true);
                    this.editor.textInput.getElement().setAttribute('id', 'copyquery')
                    that.editor.getSession().on('change', function () {
                        $("#copycustomquery").attr('data-clipboard-text', that.editor.getSession().getValue())
                    });
                    $("#advancedcustomquery").on("paste",function(e){
                        that.editor.session.setValue(format(that.editor.session.getValue()))
                    })
                }
            }, 500)
            var clipboard = new Clipboard('.copycustomquery')
            $("#settingscontainer").addClass("showsettings");
        }
    }
    renderExisitingData = (isSaved, connectiondata) => {
        var connJsObj = JSON.parse(connectiondata.connectionjson)
        var isSaved = $(this.element).parent().find('input').attr('is-saved');
        var savedEntityName = $(this.element).parent().find('input').attr('data-value')
        var entitySourceName = "";
        var entityId;
        if (this.props.connectiondata != undefined && this.props.connectiondata != null && this.props.connectiondata != "") {
            entitySourceName = <><i className='fa fa-link'></i>{this.props.connectiondata.text}</>
        }
        ReactDOM.render(
            <CustomQueryHtml
                connId={connectiondata.value}
                customqueryName={connectiondata.customqueryname}
                entityname={connectiondata.customqueryname}
                isCustomQuery={true}
                isSaved={isSaved}
                entitySourceName={entitySourceName}
                close={this.closesettingscontainer}
                savescustomquery={this.savescustomquery}
                validatedatahubcustomquery={this.validatedatahubcustomquery}
                applydatahubmodifyentities={this.applydatahubmodifyentities}
            />,
            document.getElementById('settingscontainer')
        );

        setTimeout(function () {
            $(".advancedcustomquery[data-parent ='" + connectiondata.value + "' ][data-name ='" + connectiondata.customqueryname + "' ]").text(format(connectiondata.query));
            $("#copycustomquery").attr('data-clipboard-text', connectiondata.query)
            $(".customquerynavtab").append('<li role="presentation" class="tabheadli"><a href="#datahubmodifyentitiestabview" id="datahubmodifyentitiestab" aria-controls="datahubmodifyentitiestabview" role="tab" data-toggle="tab"><i class="fa fa-pencil"></i>'+ lang["datahub.mdfyentity"]+'</a></li>');
            if (isSaved) {
                $("input[data-parent ='" + connectiondata.value + "' ][data-name ='" + connectiondata.customqueryname + "' ]").prop('disabled', true);
                $(".advancedcustomquery[data-parent ='"+connectiondata.value+"' ][data-name ='"+connectiondata.customqueryname+"' ]").addClass('disabled');
            }
            var ua = window.navigator.userAgent;
            var edge = ua.indexOf("Edge");

            if (edge < 0) {
                var that = this;
                ace.require("brace/ext/language_tools");
                this.editor = ace.edit("advancedcustomquery");
                this.editor.setTheme("ace/theme/sqlserver");
                this.editor.session.setMode("ace/mode/sql");
                this.editor.setOptions({
                    enableBasicAutocompletion: true,
                    enableSnippets: true,
                    enableLiveAutocompletion: true
                });
                this.editor.setOption("wrap", true);
                this.editor.textInput.getElement().setAttribute('id', 'copyquery')
                that.editor.getSession().on('change', function () {
                    $("#copycustomquery").attr('data-clipboard-text', that.editor.getSession().getValue())
                });
                $("#advancedcustomquery").on("paste",function(e){
                    that.editor.session.setValue(format(that.editor.session.getValue()))
                })
            }
        }, 500)



        //Append DatabseModify Entities
        var connectionJsObj = JSON.parse(connectiondata.connectionjson)
        connectionJsObj["command"] = "getEntities";
        connectionJsObj["connectionaccessid"] = connectiondata.value;
        connectionJsObj["query"] = connectiondata.query;
        connectionJsObj["isCustomQuery"] = true
        connectionJsObj["import_template_flag"] = false
        connectionJsObj["tables"] = [];
        connectionJsObj["customqueryname"] = connectiondata.customqueryname
        connectiondata.connectionjson = JSON.stringify(connectionJsObj)
        $(".datahubmodifyentitiestablecontainer").empty();
        $('.spinner').show();
        var selectedEntities = {};
        _.each(this.state.datahubEntitiesCollection, (i, j) => {
            if (i.entityname == (this.entityName + "_CQ") && i.connAccessId == this.connId) {
                entityId = i.entityId;
            }
        });
        selectedEntities[connectiondata.value + "&&&" + connectiondata.customqueryname.trim()] = connectiondata;
        if (isSaved) {
            var data = new FormData();
            data.append("entities", JSON.stringify(selectedEntities));
            SendRequest({
                url: "getentitiesmetadata",
                queryString: "",
                body: data,
                sucessFunction: (response) => {
                    if (!response.hasOwnProperty('error')) {
                        var metadata = [];
                        if (entityId != undefined) {
                            _.each(response, (i, j) => {
                                var metadata = JSON.parse(i);
                                _.each(this.state.datahubEntitiesCollection, (k, l) => {
                                    if (j + "_CQ" == k.connAccessId + "&&&" + k.entityname) {
                                        metadata.entities = JSON.parse(k.entityColumns);
                                        response[j] = JSON.stringify(metadata);
                                    }
                                });
                            });
                        }
                        if (sessionStorage.getItem("customqueryEdited") != undefined && sessionStorage.getItem("customqueryEdited") != null && ChartInfo.functions.getExistingEntitiesMetaData() != undefined && ChartInfo.functions.getExistingEntitiesMetaData() != null) {
                            var csqEdited = sessionStorage.getItem("customqueryEdited").split(",");
                            if (csqEdited.indexOf(savedEntityName) != -1) {
                                var existingEntities = ChartInfo.functions.getExistingEntitiesMetaData();
                                _.each(existingEntities, (k, l) => {
                                    _.each(response, (i, j) => {
                                        var metadata = JSON.parse(i);
                                        if (j + "_CQ" == k.connAccessId + "&&&" + k.entityname) {
                                            k.actualquery = metadata.sqlquery;
                                            k.entityColumns = JSON.stringify(metadata.entities);
                                        }
                                    });
                                });
                                ChartInfo.functions.setExistingEntitiesMetaData(existingEntities);
                            }
                        }

                        this.createDataHubEntitiesObject(response);
                        _.each(response, (i, j) => {
                            var data = j.split("&&&");
                            var conAccessId = data[0];
                            var entity = data[1];
                            metadata = this.getAllFeildsJson(conAccessId, entity);
                        });

                        ReactDOM.render(
                            <DatahubModifyEntitiesHtml
                                reportdata={metadata}
                                dataTypeClass={""}
                                schemaType={connJsObj.dbType}
                                isCustomQuery={true}
                                isStoredProcedure={false}
                                importtempateflag={false}
                                isSaved={isSaved}
                            />,
                            document.querySelector('.datahubmodifyentitiestablecontainer')
                        );
                        $("#settingscontainer").addClass("showsettings");
                        $('.spinner').hide();
                    } else {
                        $(".spinner").hide();
                        $(".gridspinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.errorTrace)
                    }
                },
                rejectFunction: (error) => { },
                headers: {
                    method: "post",
                    headers: new Headers({
                        "Content-Type": "application/json"
                    })
                }
            })
        } else {
            var param = new FormData();
            param.append("entities", JSON.stringify(selectedEntities));
            SendRequest({
                url: "getentitiesmetadata",
                queryString: "",
                body: param,
                sucessFunction: (response) => {
                    if (!response.hasOwnProperty('error')) {
                        var metadata = [];
                        this.createDataHubEntitiesObject(response);
                        _.each(response, (i, j) => {
                            var data = j.split("&&&");
                            var conAccessId = data[0];
                            var entity = data[1];
                            metadata = this.getAllFeildsJson(conAccessId, entity);
                        });
                        ReactDOM.render(
                            <DatahubModifyEntitiesHtml
                                reportdata={metadata}
                                dataTypeClass={""}
                                schemaType={connJsObj.dbType}
                                isCustomQuery={true}
                                isStoredProcedure={false}
                                importtempateflag={false}
                                isSaved={isSaved}
                            />,
                            document.querySelector('.datahubmodifyentitiestablecontainer'));
                        $("#settingscontainer").addClass("showsettings");
                        $('.spinner').hide();

                    } else {
                        $(".spinner").hide();
                        $(".gridspinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.errorTrace)
                    }
                },
                rejectFunction: (error) => { },
                headers: {
                    method: "post",
                    headers: new Headers({
                        "Content-Type": "application/json"
                    })
                }
            })

        }
        setTimeout(function () {
            var clipboard = new Clipboard('.copycustomquery')

        }, 1000)
    }
    validatedatahubcustomquery = (e, isSave) => {
        var connAccessId = this.props.connectiondata.value;
        let aceeditval = ace.edit("advancedcustomquery");
        aceeditval.getSession().setValue(format(aceeditval.getValue()));
        var customQueryVal = ace.edit("advancedcustomquery").getValue().trim().replace(/\s+/g, ' ');
        //var customQueryVal=this.editor.getValue().trim().replace(/\s+/g, ' ');
        var customQueryVal = ace.edit("advancedcustomquery").getValue().trim().replace(/\s+/g, ' ');
        customQueryVal=customQueryVal.replace("! ","!")
        if (customQueryVal == "" || customQueryVal == undefined) {
            $(".advancedcustomquery").addClass("error");
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-infor').addClass('birdmessage-warning');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-info-circle').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(getMessage('BirdWarning135'));
            return
        } else {
            $(".advancedcustomquery").removeClass("error");
        }
        var cqName = $('.customqueryname').val();
        var connectionJsObj = JSON.parse(this.props.connectiondata.connectionjson)
        connectionJsObj["command"] = "validateQuery";
        connectionJsObj["connectionaccessid"] = connAccessId;
        connectionJsObj["query"] = customQueryVal;
        connectionJsObj["cqName"] = cqName;
        connectionJsObj["customqueryname"] = this.props.connectiondata.customqueryname
        this.connectionDetailsObj = connectionJsObj;
        var isValidQuery = this.isQueryContainsIDField(connectionJsObj["dbType"], connectionJsObj["query"]);
        if (!isValidQuery) {
            if (connectionJsObj["dbType"] != undefined && connectionJsObj["dbType"] == "hive") {
                $(".spinner").hide();
                $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-infor').addClass('birdmessage-warning');
                $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-info-circle').addClass('fa-exclamation-triangle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Warning');
                $('.birdmessage-info').empty().text(getMessage('BirdWarning116'));
                return false;
            } else {
                $(".spinner").hide();
                $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-infor').addClass('birdmessage-warning');
                $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-info-circle').addClass('fa-exclamation-triangle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Warning');
                $('.birdmessage-info').empty().text(getMessage('BirdWarning73'));
                return false;
            }
        }
        var tablesParam = new FormData();
        tablesParam.append("connectionObject", JSON.stringify(connectionJsObj));
        SendRequest({
            url: "tables",
            queryString: "",
            body: tablesParam,
            sucessFunction: (response) => {
                if (response.success == true) {
                    $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                    $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('show');
                    $('.details').addClass('hide');
                    $('.birdmessage h2').empty().append('Success');
                    $('.birdmessage-info').empty().text(getMessage('BirdSuccess49'));
                    $('.spinner').hide();
                    if (isSave) {
                        this.saveCustomQueryDetails();
                        $("#settingscontainer").removeClass("showsettings");
                        $("#datahubentitycontainer").removeClass('settingsopened');
                        setTimeout(function () {
                            $("#settingscontainer").remove();

                        }, 1000);
                    }
                    return true
                } else if (response[0] == "fail") {
                    $(".spinner").hide();
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('show');
                    $('.details').addClass('hide');
                    $('.birdmessage h2').empty().append('Warning');
                    $('.birdmessage-info').empty().text("Custom Query" + "" + getMessage('BirdWarning147'));
                    $(".spinner").hide();
                    return;
                }
                else if (response.status == "fail") {
                    $(".spinner").hide();
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('show');
                    $('.details').addClass('hide');
                    $('.birdmessage h2').empty().append('Warning');
                    $('.birdmessage-info').empty().text("The Column Name '" + response.columnName + "'" + "" + getMessage('BirdWarning149'));
                    $(".spinner").hide();
                    return;
                }
                else {
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').addClass('hide');
                    $('.birdmessage h2').empty().append('Error');
                    $('.birdmessage .errorcode').empty().append(response.errorCode);
                    $('.birdmessage-info').empty().text(response.error);
                    $('.details').addClass('show').text("Details");
                    $('.messagedetails xmp').empty().append(response.errorTrace);
                    $("#settingscontainer").addClass("showsettings");
                    setTimeout(function () { $("#settingscontainer").show(); }, 1000);
                    $(".spinner").hide();
                    return false;
                }
            },
            rejectFunction: (error) => {
                console.log("can't process /tables request" + error.message);
            },
            headers: {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            }
        });
    }
    savescustomquery = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.isValidName = true;
        var finalJsObj = {};
        this.connectionDetailsObj = JSON.parse(this.props.connectiondata.connectionjson);
        var customQueryName = $('.customqueryname').val();
        if (customQueryName == undefined || customQueryName == '') {
            $(".spinner").hide();
            $(".cscqnameexists").hide();
            $(".cscqnamespecailcharacter").hide()
            $(".cscqnamevalidate").show();
            this.isValidName = false;
            return
        } else if (customQueryName != undefined && customQueryName != '' && /[~`!@#$%^&*()+\=\[\]{};':\\|†,.<>\/?" ""`]/.test(customQueryName)) {
            $(".spinner").hide();
            $(".cscqnameexists").hide();
            $(".cscqnamevalidate").hide();
            $(".cscqnamespecailcharacter").show();
            this.isValidName = false;
            return
        }
        if ($(this.element).attr('name') === "Custom Query" || ($(this.element).attr('name') === this.props.customqueryName && this.props.customqueryName != undefined)) {
            var customquery = sessionStorage.getItem("customquerySessionObj");
            if (customquery != undefined && customquery != null) {
                var customqueryarr = customquery.split(",");
                if (customqueryarr.indexOf(customQueryName.trim() + "_CQ") >= 0 && this.props.customqueryName != customQueryName) {
                    $(".spinner").hide();
                    $(".cscqnamevalidate").hide();
                    $(".cscqnamespecailcharacter").hide()
                    $(".cscqnameexists").show();
                    this.isValidName = false;
                    return
                }
                customquery = customquery + "," + customQueryName.trim() + "_CQ";
            }
            else {
                customquery = customQueryName.trim() + "_CQ";
            }
            this.validatedatahubcustomquery(e, true);
        }
    }
    saveCustomQueryDetails = () => {
        this.isValidName = true;
        var customQueryName = $('.customqueryname').val();
        var connAccessId = this.props.connectiondata.value;
        var finalJsObj = {};
        if ($(this.element).attr('name') === "Custom Query" || ($(this.element).attr('name') === this.props.customqueryName && this.props.customqueryName != undefined)) {
            var customquery = sessionStorage.getItem("customquerySessionObj");
            if (customquery != undefined && customquery != null) {
                var customqueryarr = customquery.split(",");
                //	$(".viewreport-list-item.datahub-customquery-item").removeClass("query-exists");
                //	$(".viewreport-list-item.datahub-customquery-item[data_parent='"+connAccessId+"']").removeClass('error');
                if (customqueryarr.indexOf(customQueryName.trim() + "_CQ") >= 0 && this.props.customqueryName != customQueryName) {
                    return
                }
                customquery = customquery + "," + customQueryName.trim() + "_CQ";
            }
            else {
                customquery = customQueryName.trim() + "_CQ";
            }
            //For updated Entity/Customquery Name
            if (this.props.customqueryName != customQueryName && this.props.customqueryName != undefined) {
                var customQueries = sessionStorage.getItem("customquerySessionObj");
                if (customQueries != undefined && customQueries != null) {
                    var customqueryval = this.props.customqueryName.trim() + "_CQ";
                    sessionStorage.removeItem("customquerySessionObj");
                    if (customQueries.indexOf(customqueryval + ",") >= 0) {
                        var updatedCustomquery = customQueries.replace(customqueryval + ",", "");
                        sessionStorage.setItem("customquerySessionObj", updatedCustomquery);
                    } else if (customQueries.indexOf("," + customqueryval) >= 0) {
                        var updatedCustomquery = customQueries.replace("," + customqueryval, "");
                        sessionStorage.setItem("customquerySessionObj", updatedCustomquery);
                    } else if (customQueries.indexOf(customqueryval) >= 0) {
                        var updatedCustomquery = customQueries.replace(customqueryval);
                    }

                }
                sessionStorage.removeItem(this.props.customqueryName + "_CQ");
            }
            $(this.element).parent().find('label').contents().last()[0].textContent = customQueryName;
            $(this.element).parent().find('input').attr('id', customQueryName);
            $(this.element).parent().find('input').attr('name', customQueryName);
            $(this.element).parent().find('input').attr('data-value', customQueryName + "_CQ");
            $(this.element).parent().find('input').attr('is-valid', "true");
            $(this.element).parent().find('button[data_parent=' + this.connectiondata["value"] + ']').attr('name', customQueryName);
            $(this.element).parent().find('button[data_parent=' + this.connectiondata["value"] + ']').attr('isSaved', true)
            this.connectionDetailsObj["customqueryname"] = customQueryName;
            finalJsObj["query"] = this.connectionDetailsObj["query"]
            finalJsObj["entityName"] = customQueryName.trim() + "_CQ"
            finalJsObj["connAccessId"] = this.connectiondata["value"]
            sessionStorage.setItem(customQueryName.trim() + "_CQ", JSON.stringify(finalJsObj));
            sessionStorage.setItem("customquerySessionObj", customquery);
            var customqueryEdited = "";
            if (sessionStorage.getItem("customqueryEdited") != undefined && sessionStorage.getItem("customqueryEdited") != null) {
                customqueryEdited = sessionStorage.getItem("customqueryEdited");
            }
            if (customqueryEdited.indexOf(customQueryName.trim() + "_CQ" + ",") == -1) {
                customqueryEdited += customQueryName.trim() + "_CQ" + ",";
            }
            sessionStorage.setItem("customqueryEdited", customqueryEdited);
            var dataParent = $(this.element).attr('data_parent');
            var dataName = $(this.element).attr('name');
            setTimeout(function () {
                if ($(".editdatahubcustomquery[data_parent='" + dataParent + "'][name='" + dataName + "']") != undefined && $(".editdatahubcustomquery[data_parent='" + dataParent + "'][name='" + dataName + "']").length > 0) {
                    $(".editdatahubcustomquery[data_parent='" + dataParent + "'][name='" + dataName + "']").click();
                }
                setTimeout(function () {
                    $("#datahubmodifyentitiestab").click();
                }, 1000);
            }, 2000);
        }
        /*	$("#settingscontainer").removeClass("showsettings");
            setTimeout(function(){$("#settingscontainer").remove();},1000);*/
    }
    isQueryContainsIDField = (dbType, query) => {
        var validatequery = true;

        if ((dbType == "mysql" || dbType == "snowflake" || dbType == "vertica" || dbType == "hsqldb" || dbType == "presto" || dbType == 'cache' || dbType == 'hana') && query.match(/as\s+_id/i) == null) {
            validatequery = false;
        } else if (dbType == "sqlserver" && query.match(/as\s+_id/i) == null) {
            validatequery = false;
        } else if (dbType == "oracle" && query.match(/as\s+"_id"/i) == null) {
            validatequery = false;
        } else if ((dbType == "postgres" || (dbType && dbType.toLowerCase() == "peoplesoft")) && query.match(/as\s+_id/i) == null) {
            validatequery = false;
        } else if (dbType == "kudu" && query.match(/as\s+_id/i) == null) {
            validatequery = false;
        } else if (dbType == "cassandra" && query.match(/as\s+"_id"/i) == null) {
            validatequery = false;
        }
        else if (dbType == "teradata" && query.match(/as\s+_id/i) == null) {
            validatequery = false;
        }
        else if (dbType == "hive" && query.match(/as\s+`__id`/i) == null) {
            validatequery = false;
        }
        if ($(".isDirect").is(":checked")) {
            validatequery = true;
        }
        return validatequery;
    }
    closesettingscontainer = (e) => {
        $("#settingscontainer").removeClass("showsettings");
        $("#datahubentitycontainer").removeClass('settingsopened');
        setTimeout(function () { $("#settingscontainer").remove(); }, 1000);
    }
    applydatahubmodifyentities = (e, showMessage) => {
        if (e != undefined) {
            e.preventDefault();
        }
        var $this = e.currentTarget;
        var datahubitem = new DatahubItemsSettingsHtml({ connectionaccessdata: JSON.stringify(this.connectiondata) });
        datahubitem.applydatahubmodifyentities(e, showMessage);
        //this.broker.trigger("applydatahubmodifyentities");
    }
    render() {

        return (<></>);
    }
}