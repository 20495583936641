import React from 'react';
import UpdateLicenseView from './updateLicenseView';
const $ = window.$;

export default class UpdateLicenseForm extends React.Component{
    constructor(props){
        super(props);
    }
    isValid=(data)=>{
        var isValid = true;
        if($.trim($.trim(data.powerUsers))==""){
            $(".poweruserserror").removeClass('hidden');
            $(".poweruserserror").text("Creators is required");
            isValid = false;
        }
        if($.trim($.trim(data.readOnlyUsers))==""){
            $(".explorerserror").removeClass('hidden');
            $(".explorerserror").text("Explorers is required");
            isValid = false;
        }
        if($.trim($.trim(data.viewerUsers))==""){
            $(".viewerserror").removeClass('hidden');
            $(".viewerserror").text("Viewers is required");
            isValid = false;
        }
        if($.trim($.trim(data.dataSize))==""){
            $(".datasizeerror").removeClass('hidden');
            $(".datasizeerror").text("Data size is required");
            isValid = false;
        }
        return isValid;
    }
    updateLicense=(e)=>{
        // $('.spinner').show();
        var that=this;
        var licenseFormData = $(".licenseform").serializeArray();
        var licenseDetails={};
        $.each(licenseFormData,function(index,data){
            licenseDetails[data.name]=data.value;
        });
        if(that.props.licenseDetails.roleName=='ROLE_Super_Admin'){
            if($("#multiTenant").prop('checked') == undefined){
                licenseDetails["multiTenant"] = false;
            }
            else {
                licenseDetails["multiTenant"]=$("#multiTenant").prop('checked');
            }
        }else{
            licenseDetails["multiTenant"]=false;
        }
        var licenseType = $(".licensetype").val();
        var connectorsType = $(".licenseconnectoroptions").val();
        licenseDetails["licenseType"]=licenseType;
        licenseDetails["connectorsType"]=connectorsType;
        licenseDetails["myReports"]="-1";
        licenseDetails["storyBoards"]="-1";
        licenseDetails["allowapi"] = $("#allowapi").prop("checked");
        licenseDetails["allowsmartinsights"] = $("#allowsmartinsights").prop("checked");
        licenseDetails["allowml"] = $("#allowml").prop("checked");
        licenseDetails["allowwhitelabel"] = $("#allowwhitelabel").prop("checked");
        
        licenseDetails["csv"]=$("#csv").prop('checked');
        licenseDetails["xls"]=$("#xls").prop('checked');
        licenseDetails["fuse"]=$("#fuse").prop('checked');
        licenseDetails["embed"]=$("#embed").prop('checked');
        licenseDetails["schedule"]=$("#schedule").prop('checked');
        licenseDetails["pivot"]=$("#pivot").prop('checked');
        licenseDetails["ldap"]=$("#ldap").prop('checked');
        var isValid = this.isValid(licenseDetails);
        if(isValid) { 
        var updateLicense = new UpdateLicenseView({clientId:that.props.clientId,licenseDetails:licenseDetails,userId:that.props.userId}); 
        }
    }
    render(){
        var licenseDetails = this.props.licenseDetails;
        var superadminlicensedetails = this.props.superadminlicensedetails;
        var connectorOptions = this.props.connectorOptions;
        var licenseTypeOptions = this.props.licenseTypeOptions;
        return (
            <div class="container-fluid nopadding">
	<div class="row in-back">
		<div class="max-height-30vh">
			<h2 class="boc-main-title">License Management</h2>
			<form class="form-horizontal col-xs-12 licenseform">
				<div class="form-group form-group-sm">
					<label for="licensetypelabel" class="col-sm-3 control-label">License Type</label>
					<div class="col-sm-4">
                         <select class="form-control licensetype">
                        {licenseTypeOptions.map(function(val){
                          return <option value={val}>{val}</option>
                         })
                        }                        
                         </select>
					</div>
					
				</div>
				
				<div class="form-group form-group-sm">
					<label for="powerusers" class="col-sm-3 control-label">Creators</label>
					<div class="col-sm-4">
						<input type="name" class="form-control" id="powerusers"
							name="powerUsers" defaultValue={licenseDetails.powerUsers} />
							<span class="help-block poweruserserror hidden"></span>
					</div>
					<div class="col-sm-1 nopadding text-left marg-top-5">
						/{superadminlicensedetails.powerUsers}
					</div>
				</div>
				<div class="form-group form-group-sm">
					<label for="readonly" class="col-sm-3 control-label">Explorers</label>
					<div class="col-sm-4">
						<input type="name" class="form-control" id="readonly"
							name="readOnlyUsers" defaultValue={licenseDetails.readOnlyUsers} />
							<span class="help-block explorerserror hidden"></span>
					</div>
					<div class="col-sm-1 nopadding text-left marg-top-5">
						/{superadminlicensedetails.readOnlyUsers}
					</div>
				</div>
				<div class="form-group form-group-sm">
					<label for="viewers" class="col-sm-3 control-label">Viewers</label>
					<div class="col-sm-4">
						<input type="name" class="form-control" id="readonly"
							name="viewerUsers" defaultValue={licenseDetails.viewers} />
							<span class="help-block viewerserror hidden"></span>
					</div>
					<div class="col-sm-1 nopadding text-left marg-top-5">
						/{superadminlicensedetails.viewers}
					</div>
				</div>
				<div class="form-group form-group-sm hidden">
					<label for="storyboards" class="col-sm-3 control-label">Storyboards</label>
					<div class="col-sm-4">
						<input type="name" class="form-control" id="storyboards"
							name="storyBoards" defaultValue={licenseDetails.allowedStoryBoards} />
							<span class="help-block storyboardserror hidden" value = "-1"></span>
					</div>
					<div class="col-sm-1 nopadding text-left marg-top-5">
						/{superadminlicensedetails.allowedStoryBoards}
					</div>
				</div>
				<div class="form-group form-group-sm hidden">
					<label for="myreports" class="col-sm-3 control-label">MyReports</label>
					<div class="col-sm-4">
						<input type="name" class="form-control" id="myreports"
							name="myReports" defaultValue={licenseDetails.allowedMyReports} value="-1" />
							<span class="help-block myreportserror hidden"></span>
					</div>
					<div class="col-sm-1 nopadding text-left marg-top-5">
						/{superadminlicensedetails.allowedMyReports}
					</div>
				</div>
				<div class="form-group form-group-sm">
					<label for="datasize" class="col-sm-3 control-label">Max.Data
						Size (GB)</label>
					<div class="col-sm-4">
						<input type="name" class="form-control" id="datasize"
							name="dataSize" defaultValue={licenseDetails.dataSize} />
							<span class="help-block datasizeerror hidden"></span>
					</div>
					<div class="col-sm-1 nopadding text-left marg-top-5">
						/{superadminlicensedetails.dataSize}
					</div>
				</div>
				<div class="form-group form-group-sm marg-top-5">
					<label class="col-sm-3 control-label ">Expiry Date</label>
					<div class="col-sm-4 form-inline">
						<div class="input-group input-group-sm">
							<input type="text" class="form-control" name="expiryDate" id="expirydate" readonly="readonly"
								defaultValue={licenseDetails.expiryDate} />
							<div class="input-group-addon">
								<i class="fa fa-calendar"></i>
							</div>
							<span class="help-block expirydateerror hidden"></span>
						</div>
					</div>
				</div>
				<div class="form-group form-group-sm">
					<label for="allowapi" class="col-sm-3 control-label">Allow API</label>
					<div class="col-sm-2">
                        <input type="checkbox" id = "allowapi" />
					</div>
					<label for="licensesmartinsights" class="col-sm-3 control-label">Smart Insights</label>
					<div class="col-sm-2">
                        <input type="checkbox" id = "allowsmartinsights" />
					</div>
					<label for="licenseml" class="col-sm-3 control-label ">AI/ML</label>
					<div class="col-sm-2">
                        <input type="checkbox" id= "allowml" />
					</div>
					<label for="licensewhitelabel" class="col-sm-3 control-label">WhiteLabel</label>
					<div class="col-sm-2">
                        <input type="checkbox" id= "allowwhitelabel" />
					</div> 
				</div>
				<div class="form-group form-group-sm">
					<label for="licenseconnectorslabel" class="col-sm-3 control-label">Connectors</label>
					<div class="col-sm-4">
                         <select class="form-control licenseconnectoroptions">
                        {connectorOptions.map((val)=>{
                          return <option value={val}>{val}</option>
                        })
						}
                         </select>
					</div>
					
				</div>
				
				<div class="form-group form-group-sm">
				{licenseDetails.roleName=='ROLE_Super_Admin'?(licenseDetails.multiTenancy=="true" ? <><label for="poc" class="col-sm-3 control-label">Features</label>
					<div class="col-sm-9">
						<div>
							<input type="checkbox" id="multiTenant" name="multiTenant" checked="checked hide"/>
							<span class="help-block hidden"></span>
						</div>
					</div>
					</> : null):null}
				</div>
				<div class="form-group form-group-sm">
					<div class="col-sm-5">
						<button type="button" class="btn btn-bird pull-right updatelicensedetails" onClick = {this.updateLicense}>Update</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>
        );
    }
}