import React from 'react';
import _ from 'underscore';
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import CustomFieldView from "../attrmodalcustomfieldview";
import "bootstrap-duallistbox/dist/jquery.bootstrap-duallistbox.min.js";
import * as ModalProperties from "../../js/helpers/utils/modalproperties";
import { lang } from "../../js/helpers/utils/languages/language";
const $ = window.$;
export default class MlFeatureEngineeringView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			dimensions: this.props.dimensions
		}
		this.render()
	}
	render() {
		var select = '<select multiple="multiple" size="10" name="ml_duallistbox"></select>';
		var option = '<option value="@value" aggregation ="@aggregation" columnDisplayName ="@columnDisplayName" columnName="@columnName" columnssrename="@columnssrename" id="@id" dateFormat="@dateFormat" keycolumn="@keycolumn"'
			+ 'orginalColumnName="@orginalColumnName" priorityColumn="@priorityColumn" tableAliasName="@tableAliasName" tableDisplayName="@tableDisplayName" tableDisplayType="@tableDisplayType" tableName="@tableName" unique="@unique" visible="@visible">@displayValue</option>';
		$("#mlallcolumnscontainer").empty();
		$('#mlallcolumnscontainer').append(select);
		this.state.dimensions.sort(function (a, b) {
			var x = a.columnDisplayName.toLowerCase();
			var y = b.columnDisplayName.toLowerCase();
			return x < y ? -1 : x > y ? 1 : 0;
		});
		_.each(this.state.dimensions, function (value, key) {
			var tempOption = option.replace('@value', value.columnName);
			tempOption = tempOption.replace('@displayValue', value.columnDisplayName);
			tempOption = tempOption.replace('@aggregation', value.aggregation != undefined ? value.aggregation : "count");
			tempOption = tempOption.replace('@columnDisplayName', value.columnDisplayName);
			tempOption = tempOption.replace('@columnName', value.columnName);
			tempOption = tempOption.replace('@columnssrename', (value.columnssrename != undefined) ? value.columnssrename : "");
			tempOption = tempOption.replace('@dateFormat', (value.dateFormat != undefined) ? value.dateFormat : "");
			tempOption = tempOption.replace('@id', value.id);
			tempOption = tempOption.replace('@orginalColumnName', (value.orginalColumnName != undefined) ? value.orginalColumnName : "");
			tempOption = tempOption.replace('@priorityColumn', value.priorityColumn);
			tempOption = tempOption.replace('@tableAliasName', (value.tableAliasName != undefined) ? value.tableAliasName : "");
			tempOption = tempOption.replace('@tableDisplayName', value.tableDisplayName);
			tempOption = tempOption.replace('@tableDisplayType', value.tableDisplayType);
			tempOption = tempOption.replace('@tableName', value.tableName);
			tempOption = tempOption.replace('@unique', value.unique);
			tempOption = tempOption.replace('@visible', value.visible);
			$('select[name="ml_duallistbox"]').append(tempOption);
		});
		var customFields = sessionStorage.getItem("customFields");
		if (customFields != undefined && customFields.length > 0 && customFields != "null") {
			var customFeildsArray = JSON.parse(customFields);
			customFeildsArray.sort(function (a, b) {
				var x = a.name.toLowerCase();
				var y = b.name.toLowerCase();
				return x < y ? -1 : x > y ? 1 : 0;
			});
			var option = '<option value="@value" aggregation ="@aggregation" columnDisplayName="@columnDisplayName" tableDisplayType="@tableDisplayType" columnName="@columnName" '
				+ '>@displayValue</option>';
			_.each(customFeildsArray, function (customObject) {
				var tempOption = option.replace('@value', customObject.name);
				tempOption = tempOption.replace('@columnName', customObject.name);
				tempOption = tempOption.replace('@displayValue', customObject.name);
				tempOption = tempOption.replace('@tableDisplayType', customObject.dataType);
				tempOption = tempOption.replace('@aggregation', "sum");
				tempOption = tempOption.replace('@columnDisplayName', customObject.name);
				$('select[name="ml_duallistbox"]').append(tempOption);
			});
		}
		if ($(window).width() > 500) {
			var selectbox = $('select[name="ml_duallistbox"]').bootstrapDualListbox({
				nonSelectedListLabel: '<i class="fa fa-table"></i> &nbsp;'+lang["mlreports.listofcolms"]+'',
				selectedListLabel: '<i class="fa fa-table"></i> &nbsp;'+lang["mlreports.slctlistcolms"]+'',
				preserveSelectionOnMove: 'moved',
				moveOnSelect: false,
				moveSelectedLabel: 'Move selected',
			});
		}
		var that = this;
		// For appending the custom fields in the container
		$(".mlcustomfieldscontainer .customdimensions").empty();
		if (customFields != undefined && customFields.length > 0) {
			var customFeildsArray = JSON.parse(customFields);
			_.each(customFeildsArray, function (customObject) {
				var today = new Date();
				var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
				dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
				$(".mlcustomfieldscontainer .customdimensions").append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
				var customfieldView = new CustomFieldView({
					dynamicdiv: dynamicdiv,
					deletecustomfield: that.props.deletecustomfield,
					columnDisplayName: customObject.name,
					columnName: customObject.name,
					dataType: (customObject.dataType == undefined) ? "string" : customObject.dataType,
					aggregation: "Sum"
				});
				$(".mlcustomfieldscontainer .customdimensions").append($("#" + dynamicdiv));
			});
			$(".createfeatureengineeringfield").click(function(e){
				that.props.createFeatureEngineeringField(e)
			})
		}

		//For selecting the selected columns
		_.each(ChartInfo.functions.getMLSelectedColumns(), function (value, key) {
			$('select[name="ml_duallistbox"] option[columnname="' + value.columnName + '"]').attr("selected", "selected");
		});
		$('.move').trigger("click");
		if($(".mlstoryboardtabs.fill-height.loadingmlreport").length==0){
            $(".spinner").hide();
        }
		$(window).on("resize", function () {
			ModalProperties.resize();
		});
		return (
			<React.Fragment />
		);
	}
}