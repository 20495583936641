import React from "react";
import ReactDOM from 'react-dom';
import { whitelableMap } from "./js/helpers/utils/whitelable";
import BirdBackground from './images/birdimages/boc-bg.png';

const $ = window.$;
export default class OneTimePassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    componentDidMount() {
        $('title').text(whitelableMap["metatitle"]);
        $('meta[name="Keywords"]').attr('content', whitelableMap["metakeyword"]);
        $('meta[name="Description"]').attr('content', whitelableMap["metadescription"]);
        $('meta[name="Author"]').attr('content', whitelableMap["metaauthor"]);
        $('meta[name="theme-color"]').attr('content', whitelableMap["metatheme"]);
        $('link[name="Favicon"]').attr('href', whitelableMap["metafavicon"]);

        $('#birdimg').attr('alt', whitelableMap["alttextbrand"]);
        $('#birdimg').attr('src', whitelableMap["loginlogo"]);
        $('.copyright-footer').text(whitelableMap["loginfootertext"]);
    }
    render() {
        var message = "Please enter OTP which has been sent to your email";
        var response = this.props.response;
        if (response.message != undefined) {
            message = response.message;
        }
        return (<React.Fragment>
            {/* <div class="rightbg"></div>
            <div id="connections-animation"></div> */}
            <div className="container-fluid height-100 logincontainer-fluid">
                <div className="row height-100 logincontainerrow">
                    <div className="col-md-6 loginrightcontainer">
                        <div className="panelcontainer">
                            <div className="logincontainer">
                                <div className="logo">
                                    <img alt="BIRDonCloud" src={whitelableMap["loginlogo"]} id="birdimg" />
                                </div>

                                <div className="form">
                                    <div className="tab-content">
                                        <div id="otpconformation" style={{ display: "block" }}>
                                            <span className="header-message"></span>
                                            {/* <!-- <h1>Welcome Back!</h1> --> */}
                                            <p className="error active">{message}</p>
                                            <form>
                                                <div className="field-wrap">
                                                    <label>OTP<span className="req">*</span></label>
                                                    <input type="password" autoComplete="off" id="pwd" name="password" required />
                                                </div>
                                                <button className="button button-block" onClick={this.props.authenticateotp}>Sign in</button>
                                            </form>
                                        </div>

                                    </div>
                                    {/* <!-- tab-content --> */}

                                </div>
                                {/* <!-- /form --> */}
                            </div>
                        </div>
                        <div className="footer copyright-footer">{whitelableMap["footertext"]}</div>
                    </div>
                    <div class="col-md-6 loginleftcontainer"></div>
                    {/* <!-- /left --> */}
                </div>
            </div>
            <script>
            </script>
        </React.Fragment>);
    }
}