import React from 'react';
import SendRequest from '../../SendRequest';
import { getMessage } from '../../js/helpers/utils/BirdMessage';
const $ = window.$;

export default class UpdateLicenseView extends React.Component{
    constructor(props){
        super(props);
        this.updateLicense();
    }
    updateLicense(){
        this.licenseDetails=this.props.licenseDetails;
		this.clientId=this.props.clientId;
		this.userId = this.props.userId;
        var that=this;
        var that = this;
        let requestHeaders = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        var reqdata = new FormData();
        reqdata.set("multiTenancy",that.licenseDetails.multiTenant);
        reqdata.set("powerUsers",that.licenseDetails.powerUsers);
        reqdata.set("readOnlyUsers",that.licenseDetails.readOnlyUsers);
        reqdata.set("viewerUsers",that.licenseDetails.viewerUsers);
        reqdata.set("storyBoards",that.licenseDetails.storyBoards);
        reqdata.set("myReports",that.licenseDetails.myReports);
        reqdata.set("dataSize",that.licenseDetails.dataSize);
        reqdata.set("expiryDate",that.licenseDetails.expiryDate);
        reqdata.set("allowapi",that.licenseDetails.allowapi);
        reqdata.set("allowsmartinsights",that.licenseDetails.allowsmartinsights);
        reqdata.set("allowml",that.licenseDetails.allowml);
        reqdata.set("allowwhitelabel",that.licenseDetails.allowwhitelabel);
        reqdata.set("licenseType",that.licenseDetails.licenseType);
        reqdata.set("connectorsType",that.licenseDetails.connectorsType);
        reqdata.set("newClientId",that.clientId);
        reqdata.set("newUserId",that.userId);   
        SendRequest({
            url: "updateclientlicense",
            body: reqdata,
            sucessFunction: (response) => {
                this.render(response);
              },
              rejectFunction: () => { },
              headers: requestHeaders
            })
    }
    render(response){
        if(response.status=='success'){
            $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
            $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Success');
            $('.birdmessage-info').empty().text(getMessage('BirdSuccess34'));
               setTimeout(function() {
            $('.birdmessage-container .close').click();	
            }, 3000);
            setTimeout(function() {
              window.location.reload(false);
            },2000);
        }else if(response.hasOwnProperty('error')){
            $(".spinner").hide();
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('hide');
            $('.birdmessage h2').empty().append('Error : ');
            $('.birdmessage h2').append('<span class="errorcode">');
            $('.birdmessage .errorcode').empty().append(response.errorCode);
            $('.birdmessage-info').empty().text(response.error);
            $('.details').addClass('show');
            $('.messagedetails xmp').empty().append(response.errorTrace)
             setTimeout(function() {
            $('.birdmessage-container .close').click();	
            }, 3000);
        } 
        return (<div></div>) ;
    }
}