import React from "react";
import { lang } from "../js/helpers/utils/languages/language";
export default class StrorybookHeaderViewTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.state.isSharedStoryBook = false;
        if (window.location.pathname.indexOf("sharestorybook") >= 0) {
            this.state.isSharedStoryBook = true;
        }
    }
    render() {
        var that = this.props;
        return (
            <div className="container-fluid">
                {/* <!-- viewstorybookheaderview.js --> */}
                <div className="row controlbar-main">
                    <div className="col-lg-7 col-xs-7 flex-baseline-div">
                        {/* <!-- Edit Title --> */}
                        <h2 className="boc-viewreport-title " id="viewstorybook" data-num="storybook" tab-id="0" tab-name="" title="<%=title%>">{that.title}</h2>
                        {/* <!--\Edit Title  --> */}
                    </div>
                    <div className="pull-right marg-left-auto">
                        <div className="">
                            <div className="col-xs-12 storyboardtoolbarfull">
                                <div className="row">
                                    <div className="btn-group pull-right dropdown bgcommon" role="group" aria-label="...">
                                        {!this.state.isSharedStoryBook ?
                                            <button type="button" className="btn btn-bird-group createnewstorybooktab" onClick={that.createnewstorybooktab} id="" title={lang["storybook.createstorybook"]}><i className="fa fa-plus"></i></button>
                                            : null}
                                        <button type="button" className="btn btn-bird-group refreshtabs" onClick={that.refreshtabs} id="refreshstorybooktab" title={lang["storybook.refresh"]}><i className="fa fa-refresh"></i></button>
                                        {!this.state.isSharedStoryBook ?
                                            <> <button type="button" className="btn btn-bird-group tooltip-bottom dropdown-toggle dropdown" id="dropdownmore" title={lang["storybook.more"]} data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v"></i></button>
                                                <ul className="dropdown-menu dropdown-menu-right megadropdown">
                                                    <li className="dropdown-header">{lang["storybook.general"]}</li>
                                                    <li><a href="javascript:void(0)" className="savestorybook" onClick={that.savestorybook} id="savestorybook" title={lang["storybook.save"]}><i className="fa fa-floppy-o marg-righ-10"></i>{lang["storybook.savebook"]}</a></li>
                                                    <li className="dropdown-header">{lang["storybook.collaborate"]}</li>
                                                    <li><a href="javascript:void(0)" id="sharestorybook" onClick={that.showsharemodel} title={lang["storybook.share"]}><i className="fa fa-users marg-righ-10"></i>{lang["storybook.sharebook"]}</a></li>
                                                    {/* <!--<li><a href="javascript:void(0)" className="clonestorybook" id="clonestorybook" title="Clone"><i className="fa fa-files-o marg-righ-10"></i>Clone Storybook</a></li>
                                             <li className="divider"></li>
                                            <li className="dropdown-header">Customize</li>
                                            <li><a href="javascript:void(0)" id="storybookcolortheme" title="Storybook Theme" ><i className="fa fa-paint-brush marg-righ-10 fa-violet"></i>Storybook Theme</a></li>																
                                            <li><a href="javascript:void(0)" id="storybooktitle" title="Edit Title"><i className="fa fa fa-pencil marg-righ-10"></i>Edit Title</a></li>
                                            <li><a href="javascript:void(0)" id="setstorybookasfavourite" title="Favorite"><i className="fa fa-star fa-yellow marg-righ-10"></i>Favorite</a></li>
                                            <li className="divider"></li>
                                            <li className="dropdown-header">Collaborate</li>
                                            <li><a href="javascript:void(0)" id="sharestorybook" title="Share"><i className="fa fa-users marg-righ-10"></i>Share Storybook</a></li>
                                            <li><a href="javascript:void(0)" id="embedstorybook" title="Embed Storybook"><i className="fa fa-code marg-righ-10"></i>Embed Storybook</a></li>
                                            <li><a href="javascript:void(0)" id="downloadstorybook" title="Download"><i className="fa fa-download fa-flip-horizontal marg-righ-10"></i>Download</a></li> --> */}
                                                </ul>
                                            </> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="viewreportinfoview"></div>
                <div className="append_drilldownallsettingspop"></div>
                <div className="livereportmodaldisplay"></div>
                <div className="usercustomscriptmodaldisplay"></div>
                <div className="savehashcriteriamodaldisplay"></div>
                <div id="changetimezonemodelview"></div>
                <div id="metadataauditview"></div>
                <div id="purgedindicesmodelview"></div>
                <div className="queryinfomodaldisplay"></div>
                <div className="settingmodalheaderdisplay"></div>
                {/* <!-- <div id="filtermodal"></div> --> */}
                <div className="savestoryboarddiv"></div>
                <div className="sharediv" id="storybooksharediv"></div>
                <div className="savestorybookdiv"></div>
                <div className="newstorytabdiv"></div>
                <div className="addreportthememodal"></div>
                <div className="addstoryboardcolorthememodal"></div>
                <div className="embedreportdiv"></div>
                <div id="schedulereportdiv" className="schedulereportdiv"></div>
                <div id="advancedaccesspermissonscontainer"></div>
                <div id="advancedaccesspermissonscontainer"></div>
                <div className="alert alert-success clonesinglereportsuccess" style={{ display: "none" }} role="alert">{lang["storybook.clonesuccess"]}</div>
                <div className="alert alert-danger clonesinglereporterror" style={{ display: "none" }} role="alert">{lang["storybook.cloneerror"]}</div>
                <div id="singlereportchartsereiscolors"></div>
            </div>
        );
    }
}
