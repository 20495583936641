import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'underscore';
import CreateConnectionHtml from './CreateConnectionHtml.jsx';
import DataSourceConnection from './DatasourceConnection';
import SendRequest from '../SendRequest';
const $=window.$;

export default class CreateConnection extends React.Component{
    constructor(props){
        super(props);
        this.state={
            frequentlyused:{},
            notfrequentlyused:{},
            connectionJsonMap:{}
        }
        this.close=this.close.bind(this);
        this.search=this.search.bind(this);
        this.createnewsource=this.createnewsource.bind(this);
    }
    componentDidMount(){
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getConnectionsList",
            queryString: "",
            sucessFunction: (response) => {
                var frequentlyUsed={};
                var notfrequentlyUsed={};
                var connectionJsonMap={};
                var uniquetypes = _.unique(response[1]);
                _.each(uniquetypes, function(value, index){
                    if(value.frequentlyused === true){
                        frequentlyUsed[value.name] = [];
                    }else{
                        notfrequentlyUsed[value.name] = [];
                    }
                });
                _.each(response[1],function(value,index){
                    if(value.frequentlyused=== true){
                        frequentlyUsed[response[1][index].name].push(_.extend(response[1][index],response[0][index]));
                        if(value.typeid!==2){
                            connectionJsonMap[response[1][index].connectionid]  = (((JSON.parse(response[0][index].connectionDetails))));
                        }
                    }else{
                        notfrequentlyUsed[response[1][index].name].push(_.extend(response[1][index],response[0][index]));
                        if(value.typeid!==2){
                            connectionJsonMap[response[1][index].connectionid]  = (((JSON.parse(response[0][index].connectionDetails))));
                        }
                    }
                });
                this.setState({
                    frequentlyused:frequentlyUsed,
                    notfrequentlyused:notfrequentlyUsed,
                    connectionJsonMap:connectionJsonMap
                });
            },
            rejectFunction: () => { },
            headers: requestHeaders,
        })
    }
    close=(e)=>{
        e.preventDefault();
        document.body.classList.remove('connection-open','creating-new-connection');
        document.querySelector('.addconnection').classList.remove('connection-in');
        ReactDOM.unmountComponentAtNode(document.querySelector('.addconnection'));
    }
    search=(e)=>{
        var valThis = $(e.currentTarget).val().toLowerCase();
        if(valThis !=""){
            $(".advancedconnections .connectionrequest, .commonconnections .connectionrequest").each(function(){
                var text = $(this).text().toLowerCase();
                var schemaname = $(this).attr("schemaname").toLowerCase();
                (text.indexOf(valThis) > -1) ? $(this).addClass("search-result") : $(this).removeClass("search-result");
                if(text.indexOf(valThis) == -1)
                (schemaname.indexOf(valThis) > -1) ? $(this).addClass("search-result") : $(this).removeClass("search-result");
            });
        }else{
            $("li.connectionrequest").removeClass("search-result");
        }
    }
    createnewsource=(e)=>{
        var $this = e.currentTarget;
        var ConnectionID = $($this).attr("connectionid");
        var DisplayName = $($this).attr("displayname");
        var SchemaName = $($this).attr("schemaname");
        var connectionDetails=this.state.connectionJsonMap[ConnectionID];
        $(".connection-container").addClass("adding-new-source");
        $(".commonconnections, .otherconnections").hide();
        $('.connection-container').append("<div class='datasourceconnection-container'></div>");
        ReactDOM.render(<DataSourceConnection
            schemaId={ConnectionID}
            schemaName={SchemaName}
            schemaDisplayName={DisplayName}
            connectionjson={{}}
            connectiondetails={connectionDetails}
            connectiondata={connectionDetails}
        />,document.querySelector('.datasourceconnection-container'));
    }
    render(){
        return(
            <CreateConnectionHtml
                frequentlyused={this.state.frequentlyused}
                notfrequentlyused={this.state.notfrequentlyused}
                connectionJsonMap={this.state.connectionJsonMap}
                close={this.close}
                search={this.search}
                createnewsource={this.createnewsource}
            />
        );
    }
}