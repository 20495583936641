import React from "react";
import { lang } from "../js/helpers/utils/languages/language";
import PubSub from 'pubsub-js';
const $=window.$;

export default class ReferenceLineTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            referenceMenuVisible:false
        };
    }
    showOptions=()=>{
        this.setState({
            referenceMenuVisible: !this.state.referenceMenuVisible
        });
    }
    componentDidMount(){
        $('.reference-color').colorpicker({ format: "rgba" }).on('changeColor.colorpicker', function (event) {
            var $this = event.currentTarget;
            var currentValue = $($this).find('input').val();
            $($this).find(".input-group-addon>i").css("background-color", currentValue);
        });
    }
    render() {
        var that = this.props;
        return (

            //    <!-- yaxisattributesview.js -->
            <li className="boc-list-item singleliitems" data-name={that.yaxiscolumnName} column-name={that.referenceColumnName} id={that.id} data-agg={that.aggregation} data-label={that.referenceLinelabel} data-style={that.referenceStyle} data-color={that.referenceColor} data-num={that.chartnumber}
                tab-id="0" tab-name="" data-type={that.yaxiscolumnType} title={that.yaxiscolumnDisplayName} table-name={that.yaxistableName} percentile-value={that.percentileValue}>
                <span className="itemcontainer dropup">
                    <span className="itemtype">
                        {(that.yaxiscolumnType == 'CustomLine') ? (
                            <i className="fa fa-ellipsis-h"></i>
                        ) : (that.yaxiscolumnType === 'customaggregatedfield') ? (
                            <span className="function">{lang["storyboard.fx"]}</span>
                        ) : (that.yaxiscolumnType === 'custommeasurehierarchy') ? (
                            <i className="fa fa-list"></i>
                        ) : (that.yaxiscolumnType === 'string') ? (
                            <span>{lang["storyboard.abc"]}</span>
                        ) : (that.yaxiscolumnType === 'number') ? (
                            123
                        ) : (that.yaxiscolumnType === 'date') ? (
                            <i className="fa fa-calendar"></i>
                        ) : null}
                    </span>
                    <span className="itemname" title={that.yaxiscolumnDisplayName}>{that.yaxiscolumnDisplayName}</span>
                    <span className="itemedit" title="" aria-expanded="false" data-toggle="dropdown" onClick={this.showOptions}>
                        <span type="button" className="">
                            <i className="expandinghamburger"></i>
                        </span>
                    </span>
                    <div className="closingOptions" onClick={this.showOptions} style={{display:this.state.referenceMenuVisible? "block":"none",position:"fixed",top:"0",left:"0",bottom:"0",right:"0"}}></div>
                    <ul role="menu" className="dropdown-menu dropdown-menu-right referenceoptionsul" style={{display:this.state.referenceMenuVisible? "block":"none"}}>
                        <li className="list-title">{lang["storyboard.referenceline"]}</li>
                        <li className="aggregation-li-items">{lang["storyboard.name"]} <span><input type="text" defaultValue={that.yaxiscolumnDisplayName} className="referencelinename" /></span></li>
                        <span className="showError hide " style={{ color: "red" }}>{lang["storyboard.spclchar"]}</span>
                        <li className="aggregation-li-items">{lang["storyboard.label"]} <span><input type="text" defaultValue={that.referenceLinelabel} className="referencelinelabel" /></span></li>
                        <span className="showErrors hide" style={{ color: "red" }}>{lang["storyboard.spclchar"]}</span>
                        {(that.yaxiscolumnType == 'CustomLine') ? (
                            <li className="aggregation-li-items">{lang["storyboard.val"]} <span><input type="number" className="referencelinevalue" defaultValue={that.referencelinevalue == undefined ? 0 : that.referencelinevalue} placeholder="100000" /></span></li>
                        ) : (
                                <li className="aggregation-li-items">{lang["storyboard.val"]} <span><select className="agg"><option value={lang["storyboard.minimum"]}>{lang["storyboard.minimum"]}</option><option value={lang["storyboard.max"]}>{lang["storyboard.max"]}</option><option value={lang["storyboard.avg"]}>{lang["storyboard.avg"]}</option><option value="sum">{lang["storyboard.sum"]}</option><option value={lang["storyboard.countdistinct"]}>{lang["storyboard.countdistinct"]}</option><option value={lang["storyboard.count"]}>{lang["storyboard.countall"]}</option></select></span></li>
                            )}
                        {/* <!-- <input type="name" id="customaggregatedfieldname" value="" className="modal-form-fields" placeholder="Custom Name"> --> */}
                        <li className="aggregation-li-items">{lang["storyboard.style"]}
                   <span>
                                <select className="referencelinestyle">
                                    <option value={lang["storyboard.solid"]}>{lang["storyboard.solid"]}</option>/
                           <option value={lang["storyboard.shortdash"]}>{lang["storyboard.shortdash"]}</option>/
                           <option value={lang["storyboard.shortdot"]}>{lang["storyboard.shortdot"]}</option>
                                    <option value={lang["storyboard.ShortDashDot"]}>{lang["storyboard.ShortDashDot"]}</option>
                                    <option value={lang["storyboard.sdotdot"]}>{lang["storyboard.sdotdot"]}</option>
                                    <option value={lang["storyboard.dot"]}>{lang["storyboard.dot"]}</option>
                                    <option value={lang["storyboard.dash"]}>{lang["storyboard.dash"]}</option>
                                    <option value={lang["storyboard.longdash"]}>{lang["storyboard.longdash"]}</option>
                                    <option value={lang["storyboard.dashdot"]}>{lang["storyboard.dashdot"]}</option>
                                    <option value={lang["storyboard.ldashdot"]}>{lang["storyboard.ldashdot"]}</option>
                                    <option value={lang["storyboard.ldotdot"]}>{lang["storyboard.ldotdot"]}</option>
                                </select>
                            </span>
                        </li>
                        <li className="aggregation-li-items">{lang["storyboard.color"]}
                   <div className="input-group reference-color">
                                <input type="text" placeholder={lang["storyboard.#FF0000"]} defaultValue="#FF0000" className="form-control input-sm" id="referencecolor" />
                                <span className="input-group-addon" id="referencecolors"><i style={{ backgroundColor: "#FF0000" }}></i></span>
                            </div>
                        </li>
                        <li className="aggregation-li-items text-right">
                            <button className="btn btn-bird btn-sm cancelreferenceline" onClick={this.showOptions}>{lang["storyboard.cancelbtn"]}</button>
                            <button className="btn btn-bird btn-sm applyreferenceline" id={that.id} onClick={this.apply}>{lang["storyboard.applybtn"]}</button>
                        </li>
                    </ul>
                </span>
                <span className="itemoptions">
                    <span className="itemremove">
                        <button className="btn btn-transparent deletereferenceline" onClick={that.deletereferencelineelement} id={that.id} type="button" title={lang["storyboard.remove"]}><i className="fa fa-trash-o"></i></button>
                    </span>
                    <span className="itemaggregation">

                    </span>
                </span>
            </li>);
    }
    apply=()=>{
        PubSub.publish("applyreferenceline");
        this.showOptions();
    }
}