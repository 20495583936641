import _ from 'lodash';
import React from "react";
import ReactDOM from "react-dom";
import PubSub from "pubsub-js"
import SendRequest from '../../SendRequest';
import * as ModalProperties from "../../js/helpers/utils/modalproperties";
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import { getMessage } from '../../js/helpers/utils/BirdMessage';
import * as ChartUtil from "../../js/helpers/utils/chartutil";
import PivotModalTemplate from "./pivotmodal.jsx"
import PivotColumnsDropDownView from "./pivotcolumnsdropdown"
import PivotColumnAttributeView from "./pivotcolumnattribute"
import PivotRowsDropDownView from "./pivotrowsdropdown"
import PivotRowAttributeView from "./pivotrowattribute"
import PiotValuesDropDownView from "./pivotvaluesdropdown"
import PivotValueAttributeView from "./pivotvalueattribute"
const $ = window.$;


export default class PivotModalView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chartnumber: props.chartnumber,
            measures: props.measures,
            dimensions: props.dimensions,
            multifact: props.multifact,
            facttables: props.facttables,
            multifactentities: props.multifactentities,
        };
        this.render();
    }
    render() {
        this.state.el = ".pivotcolumnmodal" + this.props.chartnumber;

        ReactDOM.render(<PivotModalTemplate applyPivotChanges={this.applyPivotChanges} addPivotValues={this.addPivotValues} addRows={this.addRows} addColumns={this.addColumns} chartnumber={this.state.chartnumber} closePivotModal={this.closePivotModal} />, document.querySelector(this.state.el));
        $('#sortablepivotcolumns').sortable({});
        $('#sortablepivotrows').sortable({});
        $('#sortablepivotvalues').sortable({});
        var chartData = ChartInfo.functions.getChartDetails(this.state.chartnumber);
        this.state.isDirect = ChartInfo.functions.getIsDirect();
        this.state.isStoredProcedure = ChartInfo.functions.getIsStoredProcedure();
        var data = JSON.parse(chartData);
        var rowsArray = data['rows'];
        var columnsArray = data['columns'];
        var valuesArray = data['values'];
        var that = this;
        $('.pivotcolumn li').empty();
        $('.pivotrow li').empty();
        $('.pivotvalues li').empty();


        _.each(rowsArray, function (row) {
            var pivotRowAttrView = new PivotRowAttributeView({
                columnName: row['columnName'],
                columnDisplayName: row['displayName'],
                aggregation: row['aggregation'],
                dataType: row['dataType'],
                tableName: row['tableName'],
                chartnumber: that.state.chartnumber,
                multifact: that.state.multifact,
                multifactentities: that.state.multifactentities,
            });
        });

        _.each(columnsArray, function (column) {
            var pivotColumnAttrView = new PivotColumnAttributeView({
                columnName: column['columnName'],
                columnDisplayName: column['displayName'],
                aggregation: column['aggregation'],
                dataType: column['dataType'],
                tableName: column['tableName'],
                chartnumber: that.state.chartnumber,
                multifact: that.state.multifact,
                multifactentities: that.state.multifactentities,
            });
        });

        _.each(valuesArray, function (valueData) {
            var pivotValueAttrView = new PivotValueAttributeView({
                columnName: valueData['columnName'],
                columnDisplayName: valueData['displayName'],
                aggregation: valueData['aggregation'],
                dataType: valueData['dataType'],
                tableName: valueData['tableName'],
                percentilevalue: valueData['percentilevalue'],
                chartnumber: that.state.chartnumber,
                multifact: that.state.multifact,
                multifactentities: that.state.multifactentities,
            });
        });

        return (<div></div>);
    }
    closePivotModal = () => {
        $("body").removeClass("modal-open");
        ReactDOM.unmountComponentAtNode(document.querySelector(this.state.el));

    }
    addColumns = (e) => {
        var $this = e.currentTarget;
        var that = this;
        var chartnumber = $($this).attr("data-num");
        if (this.state.chartnumber == chartnumber) {
            var pivotcolumnsdropdownView = new PivotColumnsDropDownView({
                addColumnValue: that.addColumnValue,
                chartnumber: chartnumber,
                columnvalues: that.state.dimensions,
                multifact: that.state.multifact,
                facttables: that.state.facttables,
                multifactentities: that.state.multifactentities,
            });
        }
        $('.form-control-pivot-columns').click(function (e) {
            e.stopPropagation();
        });
    }
    addColumnValue = (e) => {
        var $this = e.currentTarget;
        var tableName = $($this).attr("data-parent");
        var columnName = $($this).attr("data-child");
        var dataType = $($this).attr("data-type");
        var columnDisplayName = $($this).attr("data-name");
        var that = this;
        var pivotColumnAttrView = new PivotColumnAttributeView({
            columnName: columnName,
            columnDisplayName: columnDisplayName,
            aggregation: "Count",
            dataType: dataType,
            tableName: tableName,
            chartnumber: that.state.chartnumber,
            multifact: that.state.multifact,
            multifactentities: that.state.multifactentities,
        });
    }
    addRows = (e) => {
        var $this = e.currentTarget;
        var that = this;
        var chartnumber = $($this).attr("data-num");
        if (this.state.chartnumber == chartnumber) {
            var pivotrowsdropdownView = new PivotRowsDropDownView({
                addRowValue: this.addRowValue,
                chartnumber: chartnumber,
                rowvalues: that.state.dimensions,
                multifact: that.state.multifact,
                facttables: that.state.facttables,
                multifactentities: that.state.multifactentities,
            });
        }
        $('.form-control-pivot-rows').click(function (e) {
            e.stopPropagation();
        });
    }
    addRowValue = (e) => {
        var $this = e.currentTarget;
        var tableName = $($this).attr("data-parent");
        var columnName = $($this).attr("data-child");
        var dataType = $($this).attr("data-type");
        var columnDisplayName = $($this).attr("data-name");
        var that = this;
        var pivotRowAttrView = new PivotRowAttributeView({
            columnName: columnName,
            columnDisplayName: columnDisplayName,
            aggregation: "Count",
            dataType: dataType,
            tableName: tableName,
            chartnumber: that.state.chartnumber,
            multifact: that.state.multifact,
            multifactentities: that.state.multifactentities,
        });
    }
    addPivotValues = (e) => {
        var $this = e.currentTarget;
        var that = this;
        var chartnumber = $($this).attr("data-num");
        if (this.state.chartnumber == chartnumber) {
            var pivotvaluesDropDown = new PiotValuesDropDownView({
                addValue: that.addValue,
                chartnumber: chartnumber,
                values: that.state.dimensions,
                multifact: that.state.multifact,
                facttables: that.state.facttables,
                multifactentities: that.state.multifactentities,
            });
        }
        $('.form-control-pivot-values').click(function (e) {
            e.stopPropagation();
        });
    }
    addValue = (e) => {
        var $this = e.currentTarget;
        var tableName = $($this).attr("data-parent");
        var columnName = $($this).attr("data-child");
        var dataType = $($this).attr("data-type");
        var columnDisplayName = $($this).attr("data-name");
        var that = this;
        var pivotValueAttrView = new PivotValueAttributeView({
            columnName: columnName,
            columnDisplayName: columnDisplayName,
            aggregation: "Count",
            dataType: dataType,
            tableName: tableName,
            chartnumber: that.state.chartnumber,
            multifact: that.state.multifact,
            multifactentities: that.state.multifactentities,
        });
    }
    applyPivotChanges = (e) => {
        var $this = e.currentTarget;
        var rowsArray = [];
        var columnsArray = [];
        var valuesArray = [];
        if (this.state.chartnumber == $($this).attr("data-num")) {
            //Resize Pivot
            var ua = navigator.userAgent;
            var is_mobile_chrome = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua));
            var navbar = $('.navbar-fixed-top').height();
            var toolbar = $('.reporttoolbar[data-num="' + this.state.chartnumber + '"] .controlbar-main').height();
            var optionh = $('.fullscreenmain[data-num="' + this.state.chartnumber + '"] .maxanim9').height();
            var quickfilterh = $(".quickfiltercontainer[data-num=" + this.state.chartnumber + "]").height();
            var footer = $('.footer').height();
            var finalh = navbar + toolbar + optionh + quickfilterh + footer + 48;
            if (is_mobile_chrome) { finalh = finalh + 60 }
            $("#chart" + this.state.chartnumber).css("height", "calc(100vh - " + finalh + "px)");
            $('#chart' + this.state.chartnumber + ' .pivotscroll').removeAttr('style');

            var that = this;
            $('#spinnerchart' + this.state.chartnumber).show();
            $(".pivotsettingsmain" + this.state.chartnumber).show();
            _.each($(".pivotcol" + this.state.chartnumber + " li"), function (listObj) {
                var columns = {};

                var columnName = $(listObj).attr("data-child");
                var columnDisplayName = $(listObj).attr("title");
                var columnDataType = $(listObj).attr("data-type");
                var tableName = $(listObj).attr("data-parent");
                columns["columnName"] = columnName;
                columns["displayName"] = columnDisplayName;
                columns["dataType"] = columnDataType;
                columns["aggregation"] = "Count";
                columns["tableName"] = tableName;
                columnsArray.push(columns);
            });

            _.each($(".pivotrow" + this.state.chartnumber + " li"), function (listObj) {
                var rows = {};

                var columnName = $(listObj).attr("data-child");
                var columnDisplayName = $(listObj).attr("title");
                var columnDataType = $(listObj).attr("data-type");
                var tableName = $(listObj).attr("data-parent");

                rows["columnName"] = columnName;
                rows["displayName"] = columnDisplayName;
                rows["dataType"] = columnDataType;
                rows["aggregation"] = "Count";
                rows["tableName"] = tableName;
                rowsArray.push(rows);
            });

            _.each($(".pivotval" + this.state.chartnumber + " li.pivotdisplayvalues"), function (listObj) {
                var values = {};

                var columnName = $(listObj).attr("data-child");
                var columnDisplayName = $(listObj).attr("title");
                var columnDataType = $(listObj).attr("data-type");
                var tableName = $(listObj).attr("data-parent");
                var percentileValue = $(listObj).find('.percentilebox').val()
                var aggregation = $(".pivotval" + that.state.chartnumber + " li.pivotdisplayvalues #pivotdropdownaggregation>span").text().replace(/[^A-Z0-9]/ig, "") + ",".trim();
                values["columnName"] = columnName;
                values["displayName"] = columnDisplayName;
                values["dataType"] = columnDataType;
                values["aggregation"] = aggregation.substring(0, aggregation.length - 1);
                values["tableName"] = tableName;
                values["percentilevalue"] = percentileValue;
                valuesArray.push(values);
            });

            if (columnsArray.length == 0) {
                $('#spinnerchart' + this.state.chartnumber).hide();
                $('.addpivotcolumns-error').removeClass('hide');
                setTimeout(function () {
                    $('.addpivotcolumns-error').addClass('hide');
                }, 3000);

            }
            else if (rowsArray.length == 0) {
                $('#spinnerchart' + this.state.chartnumber).hide();
                $('.addpivotrows-error').removeClass('hide');
                setTimeout(function () {
                    $('.addpivotrows-error').addClass('hide');
                }, 3000);

            }
            else if (valuesArray.length == 0) {
                $('#spinnerchart' + this.state.chartnumber).hide();
                $('.addpivotvalues-error').removeClass('hide');
                setTimeout(function () {
                    $('.addpivotvalues-error').addClass('hide');
                }, 3000);
            }
            else {
                var chartData = ChartInfo.functions.getChartDetails(this.state.chartnumber);
                var data = JSON.parse(chartData);
                data['rows'] = rowsArray;
                data['columns'] = columnsArray;
                data['values'] = valuesArray;
                var issame = false;
                _.each(columnsArray, function (columns) {
                    if (columns.displayName == valuesArray[0].displayName) {
                        issame = true;
                        return;
                    }
                });
                if (issame) {
                    $(".spinner").hide();
                    $(".gridspinner").hide();
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('show');
                    $('.details').addClass('hide');
                    $('.birdmessage h2').empty().append('Warning');
                    $('.birdmessage-info').empty().text(getMessage('BirdWarning118'));
                   // $('#pivotpagesettingsmodal').modal('show');
                } else {
                    var facttables = that.state.facttables;
                    var rowfact;
                    var valuefact;
                    var columnfact;
                    var counter = 0;
                    var customFields = sessionStorage.getItem("customFields");
                    var customFeildsArray = JSON.parse(customFields);
                    //for rows comparing
                    if (that.state.multifact == "true") {
                        _.each(rowsArray, function (rows, i) {
                            rowfact = rows.tableName
                            _.each(facttables, function (fact, j) {
                                if (fact == rowfact) {
                                    counter++;
                                }
                            });
                        });
                        //for values comparing
                        if (counter <= 0) {
                            _.each(valuesArray, function (values, i) {
                                valuefact = values.tableName
                                _.each(facttables, function (fact, j) {
                                    if (fact == valuefact) {
                                        counter++;
                                    }
                                });
                            });
                        }
                        //for columns comparing
                        if (counter <= 0) {
                            _.each(columnsArray, function (column, i) {
                                columnfact = column.tableName
                                _.each(facttables, function (fact, j) {
                                    if (fact == columnfact) {
                                        counter++;
                                    }
                                });
                            });
                        }
                        //for values with customfields comparing
                        if (counter <= 0) {
                            if (customFields != undefined && customFields.length > 0) {
                                var valuecustomFieldCheck;
                                _.each(customFeildsArray, function (i, j) {
                                    _.each(valuesArray, function (values, r) {
                                        if (values.columnName.split(".").shift() == i.name) {
                                            var usedFieldstest = i.usedFields;
                                            _.each(usedFieldstest, function (ii, jj) {
                                                valuecustomFieldCheck = ii;
                                                valuecustomFieldCheck = valuecustomFieldCheck.split(".").shift();
                                                _.each(facttables, function (fact, j) {
                                                    if (fact == valuecustomFieldCheck) {
                                                        counter++;
                                                    }
                                                })
                                            })
                                        }
                                    })
                                })
                            }
                        }
                        //for rows with customfields comparing
                        if (counter <= 0) {
                            if (customFields != undefined && customFields.length > 0) {
                                var rowcustomFieldCheck;
                                _.each(customFeildsArray, function (i, j) {
                                    _.each(rowsArray, function (rows, r) {
                                        if (rows.columnName.split(".").shift() == i.name) {
                                            var usedFieldstest = i.usedFields;
                                            _.each(usedFieldstest, function (ii, jj) {
                                                rowcustomFieldCheck = ii;
                                                rowcustomFieldCheck = rowcustomFieldCheck.split(".").shift();
                                                _.each(facttables, function (fact, j) {
                                                    if (fact == rowcustomFieldCheck) {
                                                        counter++;
                                                    }
                                                })
                                            })
                                        }
                                    })
                                })
                            }
                        }

                        //for columns with customfields comparing
                        if (counter <= 0) {
                            if (customFields != undefined && customFields.length > 0) {
                                var columncustomFieldCheck;
                                _.each(customFeildsArray, function (i, j) {
                                    _.each(columnsArray, function (column, r) {
                                        if (column.columnName.split(".").shift() == i.name) {
                                            var usedFieldstest = i.usedFields;
                                            _.each(usedFieldstest, function (ii, jj) {
                                                columncustomFieldCheck = ii;
                                                columncustomFieldCheck = columncustomFieldCheck.split(".").shift();
                                                _.each(facttables, function (fact, j) {
                                                    if (fact == columncustomFieldCheck) {
                                                        counter++;
                                                    }
                                                })
                                            })
                                        }
                                    })
                                })
                            }
                        }



                        if (counter > 0) {
                            ChartInfo.functions.updateChartDetails(this.state.chartnumber, JSON.stringify(data));
                        }
                        else {
                            $('.gridspinner').hide();
                            $(".spinner").hide();
                            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                            $('.birdmessagebuttons').removeClass('show');
                            $('.birdmessagebuttons').addClass('hide');
                            $('.birdmessage-center').addClass("message-top-in");
                            $('.details').removeClass('show');
                            $('.details').addClass('hide');
                            $('.birdmessage h2').empty().append('Warning');
                            $('.birdmessage-info').empty().text(getMessage('BirdWarning138'));
                            return
                        }
                    }
                    else {
                        ChartInfo.functions.updateChartDetails(this.state.chartnumber, JSON.stringify(data));
                    }
                    sessionStorage.setItem("changesdone", 1);
                    var obj={};
                    obj["chartNumber"]= this.state.chartnumber;
                    obj["pos"] ="max";
                    PubSub.publish("redrawPivot", obj);
this.closePivotModal();
                }
            }
        }
    }
}