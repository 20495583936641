var locale = {
    'en-US': 'en_US',
    'en_AU': 'en_AU',
    'en_CA': 'en_CA',
    'en-US': 'en_IN',
    'en-US': 'en_IE',
    'en-US': 'en_NZ',
    'en-US': 'en_ZA',
    'en_GB': 'en_GB',
    'fr_CA': 'fr_CA',
    'fr_FR': 'fr_FR',
    'fr_CH': 'fr_CH',
    'de_AT': 'de_AT',
    'de_DE': 'de_DE',
    'de_LU': 'de_LU',
    'de_CH': 'de_CH',
    'it_IT': 'it_IT',
    'it_CH': 'it_CH',
    'ja_JP': 'ja_JP',
    'ja_JP_JP': 'ja_JP_JP',
    'ar_IQ': 'ar_IQ',
    'ar_JO': 'ar_JO',
    'ar_KW': 'ar_KW',
    'ar_LB': 'ar_LB',
    'ar_LY': 'ar_LY',
    'ar_MA': 'ar_MA',
    'ar_SD': 'ar_SD',
    'zh_CNS': 'zh_CN',
    'zh_HK': 'zh_HK',
    'hi_IN': 'hi_IN',
    'es_AR': 'es_AR',
    'es_BO': 'es_BO',
    'es_CL': 'es_CL',
    'es_CO': 'es_CO',
    'es_CR': 'es_CR',
};

export function getLocaleName(localeCode) {
    if (locale.hasOwnProperty(localeCode)) {
        return locale[localeCode].toLowerCase();;
    } else {
        return localeCode.toLowerCase();
    }
}