import _ from 'lodash';
import React, { Component } from "react";
import ReactDOM from "react-dom";
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import { getMessage } from '../../js/helpers/utils/BirdMessage';
import { lang } from "../../js/helpers/utils/languages/language";
const $ = window.$;


export default class PivotColumnsDropDown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addColumnValue: props.addColumnValue,
            columnvalues: props.columnvalues,
            chartnumber: props.chartnumber,
            broker: props.broker,
            facttables: props.facttables,
            multifact: (props.multifact == 'true'),
            multifactentities: props.multifact == 'true' ? JSON.parse(props.multifactentities) : props.multifactentities,
        }
        //template : _.template(PivotColumnsTemplate),
        $(".pivotcolummsList").empty();
        this.render();
    }
    render() {
        this.state.el = '.pivotcolummsList';
        ReactDOM.unmountComponentAtNode(document.querySelector(this.state.el))
        $(".pivotcolummsList").append("<li class='xs-list-item'><input class='form-control form-control-pivot-columns search-image' placeholder="+lang['storyboard.search']+" id='searchinput' type=text'></li>");
        var tempColumnValues = _.clone(this.state.columnvalues);
        var count = 0;
        _.each($("ul#sortablepivotcolumns[chart-number='" + this.state.chartnumber + "'] .pivotcolumnattribute"), function () {
            count++;
        });

        if (count != 3) {
            var customFields = JSON.parse(sessionStorage.getItem("customFields"));
            var convertedTypes = {};
            _.each(JSON.parse(sessionStorage.getItem("convertedtypes")), function (list) {
                convertedTypes[list["columnName"]] = list["dataType"]
            });
            if (this.state.multifact) {
                var allowedEntities = ChartInfo.functions.getAllowedEntiesForPivotTable(this.state.multifactentities, customFields, this.state.facttables);
                if (allowedEntities != undefined && allowedEntities.length > 0) {
                    tempColumnValues = [];
                    var tesmpCustomFields = [];
                    _.each(this.state.columnvalues, function (column) {
                        if (column != undefined) {
                            var tempColumn = _.clone(column);
                            var columnName = tempColumn.columnName;
                            var tablename = columnName.split(".")[0];
                            if (allowedEntities.indexOf(tablename) >= 0) {
                                tempColumnValues.push(tempColumn)
                            }
                        }
                    });

                    if (customFields != null && customFields != undefined) {
                        _.each(customFields, function (cfObj) {
                            var disable = false;
                            _.each(cfObj.usedFields, function (hierarchy) {
                                var tablename = hierarchy.split(".")[0];
                                if (allowedEntities.indexOf(tablename) < 0) {
                                    disable = true;
                                }
                            });
                            if (!disable) {
                                tesmpCustomFields.push(cfObj);
                            }
                        });
                        customFields = tesmpCustomFields;
                    }
                }
            }

            tempColumnValues.sort(function(a, b){
                var x = a.columnDisplayName.toLowerCase();
                var y = b.columnDisplayName.toLowerCase();
                return x < y ? -1 : x > y ? 1 : 0 ;
				});
				if(customFields != undefined && customFields.length != 0){
				customFields.sort(function(a, b){
                var x = a.name.toLowerCase();
                var y = b.name.toLowerCase();
                return x < y ? -1 : x > y ? 1 : 0 ;
				});
				}
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $(".pivotcolummsList").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");

            ReactDOM.render(<PivotColumnsTemplate addColumnValue={this.state.addColumnValue} chartnumber={this.state.chartnumber} 
                columnvalues={tempColumnValues} customFields={customFields != undefined && customFields != null ? customFields : []} convertedTypes={convertedTypes}
            />, document.getElementById(dynamicdiv))
        } else {
            $(".spinner").hide();
            $(".gridspinner").hide();
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(getMessage('BirdWarning31'));
        }
        $('.form-control-pivot-columns').keyup(function (e) {
            e.stopPropagation();
            var $this = e.currentTarget;
            var valThis = $($this).val().toLowerCase();
            $(".itemname").parents(".xs-list-item").each(function () {
                var text = $(this).text().toLowerCase();
                (text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
            });
        });
        return (<div></div>)
    }

}

class PivotColumnsTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        var that = this.props;
        return (<div>
            {that.columnvalues.map((column, index) => {
                return <li key={index} title={column.columnName} data-parent={column.tableName} data-child={column.columnName} data-type={column.tableDisplayType} data-name={column.columnDisplayName} className={`xs-list-item pivotcolumnitem ${column.visible == 'no' ? "hide" : ""}`} onClick={that.addColumnValue} id="pivotcolumnitems">
                    <span className="itemtype">
                        {(column.tableDisplayType === 'number') ? (
                            123
                        ) : (column.tableDisplayType === 'date' || column.tableDisplayType === 'datetime' || column.tableDisplayType === 'month' || column.tableDisplayType === 'day') ? (
                            <i className="fa fa-calendar"></i>
                        ) : (column.tableDisplayType === 'customhierarchy' || column.tableDisplayType === 'custommeasurehierarchy') ? (
                            <i className="fa fa-list"></i>
                        ) :
                        lang["storyboard.abc"]
                        }</span>
                    <span className="itemname" data-parent={column.tableName} data-child={column.columnName}
                        data-type={column.tableDisplayType}>{column.columnDisplayName}</span>
                </li>
            })}

            {that.customFields.map((column, index) => {
                return <li key={index} title={column.name} data-parent={column.name} data-child={column.name} data-type={column.dataType == undefined ? 'string' : column.dataType} data-name={column.name} className="xs-list-item pivotcolumnitem" onClick={that.addColumnValue} id="pivotcolumnitems">
                    <span className="itemtype fx">
                        {(column.dataType === 'number') ? (
                            123
                        ) : (column.dataType === 'date' || column.dataType === 'datetime' || column.dataType === 'month' || column.dataType === 'day') ? (
                            <i className="fa fa-calendar"></i>
                        ) : (column.dataType === 'customhierarchy' || column.dataType === 'custommeasurehierarchy') ? (
                            <i className="fa fa-list"></i>
                        ) :
                        lang["storyboard.abc"]
                        }</span>
                    <span className="itemname" data-parent={column.name} data-child={column.name}
                        data-type={column.dataType == undefined ? 'string' : column.dataType}>{column.name}</span>
                </li>
            })}

        </div>

        );
    }
}