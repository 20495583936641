import React from 'react'
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;
export default class DataModelAdvanceShareViewTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        var that = this;
        $('.permitcolumndata').click(function (e) {
            that.props.permitcolumndata(e);
        })
        $('.permissionfiltercheckbox').click(function (e) {
            that.props.permissionfiltercheckbox(e);
        })

    }
    render() {
        var that = this.props;
        return (
            //    <!-- datamodelsadvancedshareview.js -->
            <div className="settingscontent columnspermissiondiv col-xs-12">
                <div className="modal-height-fix">
                    <div className="col-xs-12 marg-bot-10"><i className="fa fa-columns"></i> {lang["models.restrictedcolumns"]}</div>
                    <div className="clear"></div>
                    <div className="permissionscroller" id="datamodelshareuserlistcontainer">
                        <div className="panel-group panel">
                            {that.groupcollection.map((group) => {
                                return that.selectedgrouplists.map((selectedgroup, index) => {
                                    if (selectedgroup.groupid == group.groupId) {
                                        return <div key={index}> <h4 className="panelboc-title accordion-toggle collapsed" title={group.groupName} id={`showfacterror${group.groupId}`} data-toggle="collapse" href={`#group${group.groupId}`} aria-expanded="false" data-parent="#datamodelshareuserlistcontainer" aria-controls={`{group${group.groupId}`}>
                                            <i className="fa fa-users username"></i> {group.groupName}
                                            <span className="badge pull-right marg-righ-15"><i className="fa fa-lock"></i></span>
                                        </h4>
                                            <div id={`group${group.groupId}`} className="panel-collapse panelboc-content collapse" aria-expanded="false">
                                                <div className="panelboc-body">
                                                    <div className="permissioncontainer dobyfilteritems" data-userid={group.groupId} data-modelid={that.datamodelId} data-groupidandmodelid={`${group.groupId}_${that.datamodelId}`} user-type="groups">
                                                        <div className="col-xs-12 text-right permission-options">
                                                            <button className="btn btn-link btn-sm nopadding marg-righ-5 editallcheckeditems" onClick={that.editallcheckeditems} id="editallcheckeds" user-name={group.groupName}><i className="fa fa-pencil"></i> {lang["models.editchecked"]}</button>
                                                            <button className="btn btn-link btn-sm nopadding marg-righ-5 checkallshareitems" onClick={that.checkallshareitems} id="checkallshares" data-userid={group.groupId}><i className="fa fa-check"></i> {lang["models.checkall"]}</button>
                                                            <button className="btn btn-link btn-sm nopadding uncheckallshareitems" onClick={that.uncheckallshareitems} id="uncheckallshares" data-userid={group.groupId}><i className="fa fa-times"></i> {lang["models.uncheckall"]}</button>
                                                        </div>
                                                        <div className="clear"></div>
                                                        <div className="sharemodelviewonly">
                                                            <legend className="sharedviwer"><i className="fa fa-columns" aria-hidden="true"></i> {lang["models.columns"]}</legend>
                                                            {/* <!-- <div className="drilldowntogglecheckbox"> 
                                                       <div className="checkbox checkbox-slider--c checkbox-slider-sm">
                                                           <label> <input type="checkbox" id="btnReadonlyReport " className="btnReadonlyReport" data-userid="1" user-name="default" user-type="groups"><span>&nbsp;</span></label>
                                                      </div>
                                                   </div> --> */}
                                                        </div>
                                                        <ul className="list-group scrollsetul permissionlist">
                                                            {that.dimensions.map((dimension, i) => {
                                                                var hideClass = ""
                                                                if (dimension.unique) {
                                                                    hideClass = "hide"
                                                                }
                                                                if (dimension.tableDisplayType == 'date' || dimension.tableDisplayType == 'datetime') {
                                                                    return <li className="boc-list-item">
                                                                        <label title={dimension.columnDisplayName} className={hideClass}><input type="checkbox" className="permissionfiltercheckbox" data-id={dimension.id} data-keycolumn={dimension.keycolumn} data-agg={dimension.aggregation} data-type={dimension.tableDisplayType} data-parent={dimension.tableName} data-child={dimension.columnName} name={dimension.columnName} data-colrename={dimension.columnssrename} data-unique={dimension.unique} data-visible={dimension.visible} data-tableAliasName={dimension.tableAliasName} data-dateFormat={dimension.dateFormat} data-orginalColumnName={dimension.orginalColumnName} data-userid={group.groupId} defaultChecked /><i className="fa fa-calendar fa-filter-icon"></i>{dimension.columnDisplayName}</label>
                                                                        {that.isStoredProcedure == 0 ?
                                                                            <span className={`permitcolumndata ${hideClass}`} title={lang["models.edit"]} user-name={group.groupName}><i className="fa fa-pencil"></i></span>
                                                                            : null}
                                                                    </li>
                                                                } else if (dimension.tableDisplayType == 'number') {
                                                                    return <li className="boc-list-item">
                                                                        <label title={dimension.columnDisplayName} className={hideClass}><input type="checkbox" className="permissionfiltercheckbox" data-id={dimension.id} data-keycolumn={dimension.keycolumn} data-agg={dimension.aggregation} data-type={dimension.tableDisplayType} data-parent={dimension.tableName} data-child={dimension.columnName} name={dimension.columnName} data-colrename={dimension.columnssrename} data-unique={dimension.unique} data-visible={dimension.visible} data-tableAliasName={dimension.tableAliasName} data-dateFormat={dimension.dateFormat} data-orginalColumnName={dimension.orginalColumnName} data-userid={group.groupId} defaultChecked /><i className="fa fa-sort-numeric-asc fa-filter-icon"></i>{dimension.columnDisplayName}</label>
                                                                        {that.isStoredProcedure == 0 ?
                                                                            <span className={`permitcolumndata ${hideClass}`} title={lang["models.edit"]} user-name={group.groupName}><i className="fa fa-pencil"></i></span>
                                                                            : null}
                                                                    </li>
                                                                } else if (dimension.tableDisplayType == 'month') {
                                                                    return <li className="boc-list-item">
                                                                        <label title={dimension.columnDisplayName} className={hideClass}><input type="checkbox" className="permissionfiltercheckbox" data-id={dimension.id} data-keycolumn={dimension.keycolumn} data-agg={dimension.aggregation} data-type={dimension.tableDisplayType} data-parent={dimension.tableName} data-child={dimension.columnName} name={dimension.columnName} data-colrename={dimension.columnssrename} data-unique={dimension.unique} data-visible={dimension.visible} data-tableAliasName={dimension.tableAliasName} data-dateFormat={dimension.dateFormat} data-orginalColumnName={dimension.orginalColumnName} data-userid={group.groupId} defaultChecked /><i className="fa fa-calendar-o fa-filter-icon"></i>{dimension.columnDisplayName}</label>
                                                                        {that.isStoredProcedure == 0 ?
                                                                            <span className={`permitcolumndata ${hideClass}`} title={lang["models.edit"]} user-name={group.groupName}><i className="fa fa-pencil"></i></span>
                                                                            : null}
                                                                    </li>
                                                                } else if (dimension.tableDisplayType == 'day') {
                                                                    return <li className="boc-list-item">
                                                                        <label title={dimension.columnDisplayName} className={hideClass}><input type="checkbox" className="permissionfiltercheckbox" data-id={dimension.id} data-keycolumn={dimension.keycolumn} data-agg={dimension.aggregation} data-type={dimension.tableDisplayType} data-parent={dimension.tableName} data-child={dimension.columnName} name={dimension.columnName} data-colrename={dimension.columnssrename} data-unique={dimension.unique} data-visible={dimension.visible} data-tableAliasName={dimension.tableAliasName} data-dateFormat={dimension.dateFormat} data-orginalColumnName={dimension.orginalColumnName} data-userid={group.groupId} defaultChecked /><i className="fa fa-calendar-o fa-filter-icon"></i>{dimension.columnDisplayName}</label>
                                                                        {that.isStoredProcedure == 0 ?
                                                                            <span className={`permitcolumndata ${hideClass}`} title={lang["models.edit"]} user-name={group.groupName}><i className="fa fa-pencil"></i></span>
                                                                            : null}
                                                                    </li>
                                                                } else {
                                                                    return <li className="boc-list-item">
                                                                        <label title={dimension.columnDisplayName} className={hideClass}><input type="checkbox" className="permissionfiltercheckbox" data-id={dimension.id} data-keycolumn={dimension.keycolumn} data-agg={dimension.aggregation} data-type={dimension.tableDisplayType} data-parent={dimension.tableName} data-child={dimension.columnName} name={dimension.columnName} data-colrename={dimension.columnssrename} data-unique={dimension.unique} data-visible={dimension.visible} data-tableAliasName={dimension.tableAliasName} data-dateFormat={dimension.dateFormat} data-orginalColumnName={dimension.orginalColumnName} data-userid={group.groupId} defaultChecked /><i className="fa fa-sort-alpha-asc fa-filter-icon"></i>{dimension.columnDisplayName}</label>
                                                                        {that.isStoredProcedure == 0 ?
                                                                            <span className={`permitcolumndata ${hideClass}`} title={lang["models.edit"]}user-name={group.groupName}><i className="fa fa-pencil"></i></span>
                                                                            : null}
                                                                    </li>
                                                                }
                                                            })}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                })
                            })}

                            {that.userscollection.map((user) => {
                                return that.selecteduserslist.map((selecteduser, index) => {
                                    if (selecteduser.userid == user.userid) {
                                        return <>
                                            <h4 className="panelboc-title accordion-toggle collapsed" title={user.loginname} id={`showfacterror${user.userid}`} data-toggle="collapse" href={`#user${user.userid}`} aria-expanded="false" data-parent="#datamodelshareuserlistcontainer" aria-controls={`user${user.userid}`}>
                                                <i className="fa fa-user username"></i> {user.loginname}
                                                <span className="badge pull-right marg-righ-15"><i className="fa fa-lock"></i></span>
                                            </h4>
                                            <div id={`user${user.userid}`} className="panel-collapse panelboc-content collapse" aria-expanded="false">
                                                <div className="panelboc-body">
                                                    <div className="permissioncontainer dobyfilteritems" data-userid={user.userid} data-modelid={that.datamodelId} data-useridandmodelid={`${user.userid}_${that.datamodelId}`} user-type="users">
                                                        <div className="col-xs-12 text-right permission-options">
                                                            <button className="btn btn-link btn-sm nopadding marg-righ-5 editallcheckeditems" onClick={that.editallcheckeditems} id="editallcheckeds" user-name={user.loginname}><i className="fa fa-pencil"></i> {lang["models.editchecked"]}</button>
                                                            <button className="btn btn-link btn-sm nopadding marg-righ-5 checkallshareitems" onClick={that.checkallshareitems} id="checkallshares" data-userid={user.userid}><i className="fa fa-check"></i> {lang["models.checkall"]}</button>
                                                            <button className="btn btn-link btn-sm nopadding uncheckallshareitems" onClick={that.uncheckallshareitems} id="uncheckallshares" data-userid={user.userid}><i className="fa fa-times"></i> {lang["models.uncheckall"]}</button>
                                                        </div>
                                                        <div className="clear"></div>
                                                        <div className="sharemodelviewonly">
                                                            <legend className="sharedviwer"><i className="fa fa-columns" aria-hidden="true"></i> {lang["models.columns"]}</legend>
                                                            {/* <!-- <div className="drilldowntogglecheckbox"> 
                                                       <div className="checkbox checkbox-slider--c checkbox-slider-sm">
                                                           <label> <input type="checkbox" id="btnReadonlyReport " className="btnReadonlyReport" data-userid="1" user-name="default" user-type="users"><span>&nbsp;</span></label>
                                                                 </div>
                                                                  </div> --> */}
                                                        </div>
                                                        <ul className="list-group scrollsetul permissionlist">
                                                            {that.dimensions.map((dimension, i) => {
                                                                var hideClass = ""
                                                                if (dimension.unique) {
                                                                    hideClass = "hide"
                                                                }
                                                                if (dimension.tableDisplayType == 'date' || dimension.tableDisplayType == 'datetime') {
                                                                    return <li key={i} className="boc-list-item">
                                                                        <label title={dimension.columnDisplayName} className={hideClass} ><input type="checkbox" className="permissionfiltercheckbox" data-id={dimension.id} data-keycolumn={dimension.keycolumn} data-agg={dimension.aggregation} data-type={dimension.tableDisplayType} data-parent={dimension.tableName} data-child={dimension.columnName} name={dimension.columnName} data-colrename={dimension.columnssrename} data-unique={dimension.unique} data-visible={dimension.visible} data-tableAliasName={dimension.tableAliasName} data-dateFormat={dimension.dateFormat} data-orginalColumnName={dimension.orginalColumnName} data-userid={user.userid} defaultChecked /><i className="fa fa-calendar fa-filter-icon"></i>{dimension.columnDisplayName}</label>
                                                                        {that.isStoredProcedure == 0 ?
                                                                            <span className={`permitcolumndata ${hideClass}`} data-userid={user.userid} data-child={dimension.columnName} user-name={user.loginname} title={lang["models.edit"]}><i className="fa fa-pencil"></i></span>
                                                                            : null}
                                                                    </li>
                                                                } else if (dimension.tableDisplayType == 'number') {
                                                                    return <li key={i} className="boc-list-item">
                                                                        <label title={dimension.columnDisplayName} className={hideClass}><input type="checkbox" className="permissionfiltercheckbox" data-id={dimension.id} data-keycolumn={dimension.keycolumn} data-agg={dimension.aggregation} data-type={dimension.tableDisplayType} data-parent={dimension.tableName} data-child={dimension.columnName} name={dimension.columnName} data-colrename={dimension.columnssrename} data-unique={dimension.unique} data-visible={dimension.visible} data-tableAliasName={dimension.tableAliasName} data-dateFormat={dimension.dateFormat} data-orginalColumnName={dimension.orginalColumnName} data-userid={user.userid} defaultChecked /><i className="fa fa-sort-numeric-asc fa-filter-icon"></i>{dimension.columnDisplayName}</label>
                                                                        {that.isStoredProcedure == 0 ?
                                                                            <span className={`permitcolumndata ${hideClass}`} data-userid={user.userid} data-child={dimension.columnName} user-name={user.loginname} title={lang["models.edit"]}><i className="fa fa-pencil"></i></span>
                                                                            : null}
                                                                    </li>
                                                                } else if (dimension.tableDisplayType == 'month') {
                                                                    return <li key={i} className="boc-list-item">
                                                                        <label title={dimension.columnDisplayName} className={hideClass}><input type="checkbox" className="permissionfiltercheckbox" data-id={dimension.id} data-keycolumn={dimension.keycolumn} data-agg={dimension.aggregation} data-type={dimension.tableDisplayType} data-parent={dimension.tableName} data-child={dimension.columnName} name={dimension.columnName} data-colrename={dimension.columnssrename} data-unique={dimension.unique} data-visible={dimension.visible} data-tableAliasName={dimension.tableAliasName} data-dateFormat={dimension.dateFormat} data-orginalColumnName={dimension.orginalColumnName} data-userid={user.userid} defaultChecked /><i className="fa fa-calendar-o fa-filter-icon"></i>{dimension.columnDisplayName}</label>
                                                                        {that.isStoredProcedure == 0 ?
                                                                            <span className={`permitcolumndata ${hideClass}`} data-userid={user.userid} data-child={dimension.columnName} user-name={user.loginname} title={lang["models.edit"]}><i className="fa fa-pencil"></i></span>
                                                                            : null}
                                                                    </li>
                                                                } else if (dimension.tableDisplayType == 'day') {
                                                                    return <li key={i} className="boc-list-item">
                                                                        <label title={dimension.columnDisplayName} className={hideClass}><input type="checkbox" className="permissionfiltercheckbox" data-id={dimension.id} data-keycolumn={dimension.keycolumn} data-agg={dimension.aggregation} data-type={dimension.tableDisplayType} data-parent={dimension.tableName} data-child={dimension.columnName} name={dimension.columnName} data-colrename={dimension.columnssrename} data-unique={dimension.unique} data-visible={dimension.visible} data-tableAliasName={dimension.tableAliasName} data-dateFormat={dimension.dateFormat} data-orginalColumnName={dimension.orginalColumnName} data-userid={user.userid} defaultChecked /><i className="fa fa-calendar-o fa-filter-icon"></i>{dimension.columnDisplayName}</label>
                                                                        {that.isStoredProcedure == 0 ?
                                                                            <span className={`permitcolumndata ${hideClass}`} data-userid={user.userid} data-child={dimension.columnName} data-child={dimension.columnName} user-name={user.loginname} title={lang["models.edit"]}><i className="fa fa-pencil"></i></span>
                                                                            : null}
                                                                    </li>
                                                                } else {
                                                                    return <li key={i} className="boc-list-item">
                                                                        <label title={dimension.columnDisplayName} className={hideClass}><input type="checkbox" className="permissionfiltercheckbox" data-id={dimension.id} data-keycolumn={dimension.keycolumn} data-agg={dimension.aggregation} data-type={dimension.tableDisplayType} data-parent={dimension.tableName} data-child={dimension.columnName} name={dimension.columnName} data-colrename={dimension.columnssrename} data-unique={dimension.unique} data-visible={dimension.visible} data-tableAliasName={dimension.tableAliasName} data-dateFormat={dimension.dateFormat} data-orginalColumnName={dimension.orginalColumnName} data-userid={user.userid} defaultChecked /><i className="fa fa-sort-alpha-asc fa-filter-icon"></i>{dimension.columnDisplayName}</label>
                                                                        {that.isStoredProcedure == 0 ?
                                                                            <span className={`permitcolumndata ${hideClass}`} data-userid={user.userid} data-child={dimension.columnName} user-name={user.loginname} title={lang["models.edit"]}><i className="fa fa-pencil"></i></span>
                                                                            : null}
                                                                    </li>
                                                                }
                                                            })}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                })
                            })}
                        </div>

                    </div>
                </div>
                {/* <!-- /Modal Height Fix --> */}
            </div>);
    }
}