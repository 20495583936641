import React from 'react';

export default class DatamodelConnectionsListHtml extends React.Component {
    render() {
        return (
            <>
                <div className="connections-dropdown-container marg-top-0">
                    <select id="data-models-existing-connections-list"></select>
                </div>
                <div id="data-models-connections-tables-data-container"></div>
            </>
        );
    }
}