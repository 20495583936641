import React from "react";
// import tagit from 'tag-it';
import ReactDOM from "react-dom"
import _ from 'lodash';
import SendRequest from '../SendRequest';
import ScheduleReportTemplate from './ScheduleReportHtml.jsx';
import * as viewcollection from "../js/helpers/utils/SavedReportCollection";
import ScheduleDateFieldDropDownView from './metricdatefielddropdown'
import MetricDateFieldAttrView from './metricdatefieldattributesview'
import jstz from 'jstz'
import { getMessage } from '../js/helpers/utils/BirdMessage';
import * as ModalProperties from "../js/helpers/utils/modalproperties";
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;

export default class ViewScheduleReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        var that = this;
        if (this.props.reportId == undefined || this.props.reportId == "") {
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Information');
            $('.birdmessage-info').empty().text(getMessage('BirdInformation01'));
        } else {
            let requestHeaders = {
                method: "get",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            SendRequest({
                url: "schedulepublishreport",
                queryString: "?reportId=" + btoa(this.props.reportId)+"&pathName="+window.location.origin + "/"+window.localStorage.getItem("appName")+"/welcome",
                sucessFunction: response => {
                    SendRequest({
                        url: "getUsersForSharing",
                        queryString: "",
                        sucessFunction: collection => {
                            that.state.collection = collection;
                            that.render(response);
                        },
                        rejectFunction: () => { },
                        headers: requestHeaders
                    });
                },
                rejectFunction: () => { },
                headers: requestHeaders
            });
        }

    }
    render(response) {
        var tempUsers = this.state.collection;
        tempUsers.sort(function (a, b) {
            var x = a.username.toLowerCase();
            var y = b.username.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
        });
        var localtimezone = new Date().toString().match(/([A-Z]+[\+-][0-9]+.*)/)[1].substr(0, 8) + "(" + jstz.determine().name() + ")";
        ReactDOM.render(<ScheduleReportTemplate applyScheduleReportModel={this.applyScheduleReportModel} scheduledatefield={this.scheduledatefield} scheduleDropDown={this.scheduleDropDown} close={this.close} scheduleReportModel={response} reportName={this.props.reportName} localtimezone={localtimezone} servertimezone={this.props.servertimezone} users={tempUsers}  showadvanced={this.showadvanced} searchusersgroups={this.searchusersgroups}/>, document.getElementById("schedulereportdiv"));
        $('#scheduleurl').html(response.private);
        ModalProperties.resize();
        $(window).on("resize", function () {
            ModalProperties.resize();
        });
        var availableTags = [];
        $('.tagit-select').tagit({ tagSource: availableTags, select: true, sortable: true, editable: true });
        this.scheduleDropDown();
        $(".users-select").chosen({
            no_results_text: lang["storyboard.oops"],
            width: "100%",
            search_contains: true
        });
        if($(".publishurlforschedule.login:checked").attr('data-value')=="with login"){
            $("#selectemailuser ul.tagit-select.tag-select.tagit.ui-sortable").hide();
        }
        return (<div></div>);
    }
    close() {
        ReactDOM.unmountComponentAtNode(document.getElementById("schedulereportdiv"));
    }
    scheduleDropDown = (e) => {
        var $this = $('.schedule');
        $($this).find("option:selected").each(function () {
            if ($(this).attr("value") == "once") {
                $(".schedulecontainer").not(".once").hide();
                $(".once").show();
                $(".metricdatefields").show();
                $('#startdate').datepicker({
                    minDate: 0,
                    beforeShow: function (input, obj) { if ($(window).width() <= 780) $('#startdate').prop('readonly', 'readonly'); },
                    onClose: function () { $('#startdate').prop('readonly', false); }
                });

                $('#starttime').clockpicker({
                    autoclose: true
                });
            }
            else if ($(this).attr("value") == "daily") {
                $(".schedulecontainer").not(".daily").hide();
                $(".daily").show();
                $('#dailystartdate').datepicker({
                    minDate: 0,
                    beforeShow: function (input, obj) { if ($(window).width() <= 780) $('#dailystartdate').prop('readonly', 'readonly'); },
                    onClose: function () { $('#dailystartdate').prop('readonly', false); }
                });
                $('#dailyenddate').datepicker({
                    minDate: 0,
                });
                $('#dailystarttime').clockpicker({
                    autoclose: true
                });
                $('#dailyendtime').clockpicker({
                    autoclose: true
                });
            }
            else if ($(this).attr("value") == "weekly") {
                $(".schedulecontainer").not(".weekly").hide();
                $(".weekly").show();
                if ($(".weeks").attr("data-name") == "SUN") {
                    $(".weeks").prop('checked', true)
                }
                $(".metricdatefields").show();
                $('#weeklystartdate').datepicker({
                    minDate: 0,
                    beforeShow: function (input, obj) { if ($(window).width() <= 780) $('#weeklystartdate').prop('readonly', 'readonly'); },
                    onClose: function () { $('#weeklystartdate').prop('readonly', false); }
                });
                $('#weeklyenddate').datepicker({
                    minDate: 0,
                    beforeShow: function (input, obj) { if ($(window).width() <= 780) $('#weeklyenddate').prop('readonly', 'readonly'); },
                    onClose: function () { $('#weeklyenddate').prop('readonly', false); }
                });
                $('#weeklystarttime').clockpicker({
                    autoclose: true
                });
                $('#weeklyendtime').clockpicker({
                    autoclose: true
                });
            }
            else if ($(this).attr("value") == "monthly") {
                $(".schedulecontainer").not(".monthly").hide();
                $(".monthly").show();
                $(".metricdatefields").show();
                $('#monthlystartdate').datepicker({
                    minDate: 0,
                    beforeShow: function (input, obj) { if ($(window).width() <= 780) $('#monthlystartdate').prop('readonly', 'readonly'); },
                    onClose: function () { $('#monthlystartdate').prop('readonly', false); }
                });
                $('#monthlyenddate').datepicker({
                    minDate: 0,
                    beforeShow: function (input, obj) { if ($(window).width() <= 780) $('#monthlyenddate').prop('readonly', 'readonly'); },
                    onClose: function () { $('#monthlyenddate').prop('readonly', false); }
                });
                $('#monthlystarttime').clockpicker({
                    autoclose: true
                });
                $('#monthlyendtime').clockpicker({
                    autoclose: true
                });
            }
            else {
                $(".schedulecontainer").hide();
            }
        });
    }
    scheduledatefield = (e) => {
        var that = this;
        $(".scheduledatedropdown").empty();
        var columns = viewcollection.getDimensions();
        _.each(columns, function (e, i) {
            var $this = e;
            if (columns[i].tableDisplayType === "date" || columns[i].tableDisplayType === "datetime") {
                var today = new Date();
                var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                $(".scheduledatedropdown").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                var scheduleDateFieldDropDown = new ScheduleDateFieldDropDownView({
                    dynamicdiv: dynamicdiv,
                    addmetricdatefieldvalue: that.addmetricdatefieldvalue,
                    columnDisplayName: columns[i].columnName,
                    columnName: columns[i].columnName,
                    dataType: columns[i].tableDisplayType
                });
                $(".scheduledatedropdown").append($("#" + dynamicdiv));
            }
        });
    }
    addmetricdatefieldvalue = (event) => {
        var $this = event.currentTarget;
        if (!$($this).hasClass('active')) {
            if ($(".scheduledatefieldsAttributes li").length === 0) {
                var columnName = $($this).attr("data-name");
                var columnDisplayName = $($this).find('.itemname').text();
                var datatype = $($this).attr("data-type");
                var today = new Date();
                var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                $(".scheduledatefieldsAttributes").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                var scheduledatefieldattributeview = new MetricDateFieldAttrView({
                    dynamicdiv: dynamicdiv,
                    columnDisplayName: columnDisplayName,
                    columnName: columnName,
                    columnType: datatype
                });
                $(".scheduledatefieldsAttributes").append($("#" + dynamicdiv));
            } else {
                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Warning');
                $('.birdmessage-info').empty().text(getMessage('BirdWarning42'));
            }
        }
    }
    applyScheduleReportModel = (e) => {
        e.preventDefault();
        var reportId = this.props.reportId;
        var fromEmail = $("#frommail").attr('data-name');
        var toEmail = toEmail;
        var emailSubject = $('#titlenamesub').val();
        var url = $('#scheduleurl').html($($this).attr('purl')).val();
        var access_permissions = $("input[type='radio'][name='with login']:checked").attr('data-value');
        var $this = e.currentTarget;
        var customemailvalidation = true;
        var mailArray = [];
        var schedulestoryboardtimezonename = "";
        var schedulestoryboardtimezone = $('.schedulestoryboardtimezone:checked').attr("id");
        if (schedulestoryboardtimezone == "localtimezone") {
            schedulestoryboardtimezonename = jstz.determine().name();
        }
        if($(".publishurlforschedule.login:checked").attr('data-value')!="with login"){
        $('.tagit-select .tagit-choice').each(function (i, j) {
            var $this = this;
            var mailObject = {};
            var emails = $(j).find(".tagit-label").text();
            var data = emails.split("(");
            if (data.length > 1) {
                mailObject["email"] = data[1].substring(0, data[1].length - 1);
            }
            else {
                var value = $(j).find(".tagit-label").text();
                if (value.indexOf("@") != -1) {
                    mailObject["email"] = $(j).find(".tagit-label").text();
                }
                else {
                    customemailvalidation = false;
                }

            }
            mailArray.push(mailObject)
        });
    }else if($(".publishurlforschedule.login:checked").attr('data-value')=="with login"){
        $('.scheduletousers optgroup[label=Users] option:selected').each(function (index, value) {
            var userObject = {};
            userObject["email"] = $(value).attr("userEmail");
            mailArray.push(userObject);
        });
    }
        var toEmail = '';
        $.each(mailArray, function (i, j) {
            var emails = mailArray[i].email;
            toEmail += emails + ",";
        });
        if (toEmail != undefined) {
            toEmail = toEmail.substring(0, toEmail.length - 1);
        }
        if ($(".schedule").val() === "once") {
            var schedule = $(".schedule").val();
            //emailSubject=emailSubject+"("+schedule+")";
            //$('#titlename').attr("value",$('#titlename').attr('value')+"("+schedule+")")
            var startdate = $("#startdate").val();
            var enddate = $("#enddate").val();
            var starttime = $("#starttime").val();
            var endtime = $("#endtime").val();
            var filters = $(".metricitems").text().trim();
            var every = "";
            var daysofoptions = "";
            var everyweekday = ""
            if ($("#startdate").val() === "") {
                $(".sendschedulereportdate").show();
                setTimeout(function () {
                    $(".sendschedulereportdate").hide();
                }, 3000);
            }
            if ($("#starttime").val() === "") {
                $(".sendschedulereporttime").show();
                setTimeout(function () {
                    $(".sendschedulereporttime").hide();
                }, 3000);
            }
        }
        if ($(".schedule").val() === "daily") {
            var schedule = $(".schedule").val();
            var startdate = $("#dailystartdate").val();
            var enddate = $("#dailyenddate").val();
            var starttime = $("#dailystarttime").val();
            var endtime = $("#dailyendtime").val();
            var filters = $(".metricitems").text().trim();
            /*var every=$("#selecteddays").val();$("input[type='radio']:checked").attr('data-name')*/
            var every = $("#selecteddays").val();
            var daysofoptions = $(".appetitle input[type='radio']:checked").attr('data-name')
            if (daysofoptions == "everyweekday") {
                every = '1-5';
            }
            var everyweekday = $("#everyweekday").text();
            if ($("#dailystartdate").val() === "") {
                $(".sendschedulereportdailystartdate").show();
                setTimeout(function () {
                    $(".sendschedulereportdailystartdate").hide();
                }, 3000);
            }
            if ($("#dailystarttime").val() === "") {
                $(".sendschedulereportdailystarttime").show();
                setTimeout(function () {
                    $(".sendschedulereportdailystarttime").hide();
                }, 3000);
            }
            if ($("#dailyenddate").val() === "") {
                $(".sendschedulereportdailyenddate").show();
                setTimeout(function () {
                    $(".sendschedulereportdailyenddate").hide();
                }, 3000);
            }
            if ($("#dailyendtime").val() === "") {
                $(".sendschedulereportdailyendtime").show();
                setTimeout(function () {
                    $(".sendschedulereportdailyendtime").hide();
                }, 3000);
            }
        }
        if ($(".schedule").val() === "weekly") {
            var schedule = $(".schedule").val();
            var startdate = $("#weeklystartdate").val();
            var enddate = $("#weeklyenddate").val();
            var starttime = $("#weeklystarttime").val();
            var endtime = $("#weeklyendtime").val();
            var filters = $(".metricitems").text().trim();
            var weekdaysArray = [];
            var every = '';
            $('.weeks').each(function () {
                var $this = this;
                if ($($this).is(":checked")) {
                    every += $($this).attr('data-name') + ",";
                }

            });
            every = every.substring(0, every.length - 1)
            var selectedoptions = "";
            var everyweekday = every
            if ($("#weeklystartdate").val() === "") {
                $(".sendschedulereportweeklystartdate").show();
                setTimeout(function () {
                    $(".sendschedulereportweeklystartdate").hide();
                }, 3000);
            }
            if ($("#weeklystarttime").val() === "") {
                $(".sendschedulereportweeklystarttime").show();
                setTimeout(function () {
                    $(".sendschedulereportweeklystarttime").hide();
                }, 3000);
            }
            if ($("#weeklyenddate").val() === "") {
                $(".sendschedulereportweeklyenddate").show();
                setTimeout(function () {
                    $(".sendschedulereportweeklyenddate").hide();
                }, 3000);
            }
            if ($("#weeklyendtime").val() === "") {
                $(".sendschedulereportweeklyendtime").show();
                setTimeout(function () {
                    $(".sendschedulereportweeklyendtime").hide();
                }, 3000);
            }
        }
        if ($(".schedule").val() === "monthly") {
            var schedule = $(".schedule").val();
            var startdate = $("#monthlystartdate").val();
            var enddate = $("#monthlyenddate").val();
            var starttime = $("#monthlystarttime").val();
            var endtime = $("#monthlyendtime").val();
            var filters = $(".metricitems").text().trim();
            var every = $("#dayofmonth").val();
            var daysofoptions = "";
            var everyweekday = '';
            if ($("#monthlystartdate").val() === "") {
                $(".monthlystartdates").show();
                setTimeout(function () {
                    $(".monthlystartdates").hide();
                }, 3000);
            }
            if ($("#monthlystarttime").val() === "") {
                $(".monthlystarttimes").show();
                setTimeout(function () {
                    $(".monthlystarttimes").hide();
                }, 3000);
            }
            if ($("#monthlyenddate").val() === "") {
                $(".monthlyenddates").show();
                setTimeout(function () {
                    $(".monthlyenddates").hide();
                }, 3000);
            }
            if ($("#monthlyendtime").val() === "") {
                $(".monthlyendtimes").show();
                setTimeout(function () {
                    $(".monthlyendtimes").hide();
                }, 3000);
            }
        }

        if (toEmail === "" || startdate == undefined || starttime == undefined || startdate == "" || starttime == "" || startdate == enddate || customemailvalidation == false) {
            if ($("#startdate").val() == "") {

            }
            if (toEmail === undefined || toEmail === "") {
                $(".toemailusers").show();
                setTimeout(function () {
                    $(".toemailusers").hide();
                }, 3000);
            }
            if (customemailvalidation == false) {
                $(".customemails").show();
                setTimeout(function () {
                    $(".customemails").hide();
                }, 3000);
            }
            if (startdate == enddate) {
                $(".dateselection").show();
                setTimeout(function () {
                    $(".dateselection").hide();
                }, 3000);
            }
        } else {
            var formData = new FormData();
            formData.set("fromEmail", fromEmail);
            formData.set("toEmail ", toEmail);
            formData.set("emailSubject ", emailSubject != undefined ? emailSubject : "");
            formData.set("reportURL", url);
            formData.set("schedule ", schedule);
            formData.set("startdate", startdate != undefined ? startdate : "");
            formData.set("enddate", enddate != undefined ? enddate : "");
            formData.set("starttime", starttime);
            formData.set("endtime", endtime != undefined ? endtime : "");
            formData.set("filters", filters);
            formData.set("every", every);
            formData.set("access_permissions", access_permissions);
            formData.set("daysofoptions", daysofoptions);
            formData.set("schedulestoryboardtimezonename", schedulestoryboardtimezonename);
            formData.set("reportId", reportId);
            formData.set("reportName", $('#titlenamesub').attr('value') != undefined ? $('#titlenamesub').attr('value') : "");
            let requestHeaders = {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            SendRequest({
                url: "sendingSecheduleEmail",
                body: formData,
                sucessFunction: (SendJobSchedulingModel) => {
                    if (SendJobSchedulingModel.status == 'success') {
                        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Success');
                        $('.birdmessage-info').empty().text(getMessage('BirdSuccess05'));
                        setTimeout(function () {
                            $('.birdmessage-center').removeClass("message-top-in");
                            ReactDOM.unmountComponentAtNode(document.getElementById("schedulereportdiv"));
                        }, 3000);
                    } else if (SendJobSchedulingModel.status == 'error') {
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error');
                        $('.birdmessage-info').empty().text(getMessage('BirdError01'));
                        $('.details').addClass('show');
                        setTimeout(function () {
                            $('.birdmessage-center').removeClass("message-top-in");
                        }, 3000);
                    }
                    else if (SendJobSchedulingModel.status == 'fail' || SendJobSchedulingModel.status == "error") {
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error');
                        $('.birdmessage-info').empty().text(getMessage('BirdError01'));
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(SendJobSchedulingModel.error);
                    }
                    else if (SendJobSchedulingModel.status == 'mailError') {
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor  birdmessage-error').addClass(' birdmessage-warning');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o').addClass(' fa-exclamation-triangle');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Warning');
                        $('.birdmessage-info').empty().text(getMessage('BirdInformation05'));
                        setTimeout(function () {
                            $('.birdmessage-center').removeClass("message-top-in");
                        }, 3000);
                    }
                },
                rejectFunction: this.reportDataError,
                headers: requestHeaders
            });
        }
    }
    showadvanced = (e) => {
        e.preventDefault();
        var $this = e.currentTarget;
        $($this).parent().find('.filtersearching').toggle('slow');
    }
    searchusersgroups = (e) => {
        e.preventDefault();
   			var startswith = $('#searchnamestartswith').val();
			var endswith = $("#searchnameendswith").val();
			var contains = $("#searchnamecontains").val();
    
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getSearchedGroupsList",
            queryString: "?startswith=" + startswith + "&endswith=" + endswith + "&contains=" + contains,
            sucessFunction: (response) => {
               var groupOptionArray = [];
            $.each(response, function(i, group)
            {
                groupOptionArray[i] = "<option key='" + i + "' value='" + group.groupId + "'>" + group.groupName + "</option>";
            });
            $('.users-select>optgroup.groupids').find('option').not(':selected').remove()
            $('.users-select>optgroup.groupids').prepend(groupOptionArray.join(''));
            $('.users-select').trigger("chosen:updated");
            $('.users-select').trigger('chosen:open');
            },
            rejectFunction: () => { },
            headers: requestHeaders
        });
        SendRequest({
            url: "getsearchedusersforsharing",
            queryString: "?startswith=" + startswith + "&endswith=" + endswith + "&contains=" + contains +"&isFromGovernance=" + false,
            sucessFunction: (response) => {
                var userOptionsArray = [];
                $.each(response, function(i, user)
                        {	if(!user.isLoginUser){
                            userOptionsArray[i] = "<option key='" + i + "' value='" + user.userid + "' userEmail='"+user.email+"'>" + user.username+" ("+user.email+")" + "</option>";
                            }
                        });
                $('.users-select>optgroup.userids').find('option').not(':selected').remove()
                $('.users-select>optgroup.userids').prepend(userOptionsArray.join(''));
                $('.users-select').trigger("chosen:updated");
                $('.users-select').trigger('chosen:open'); 
            },
            rejectFunction: () => { },
            headers: requestHeaders
        });
    }
}
