import React from 'react';
import * as ace from 'brace';
import CustomQueryHtml from './CustomQueryHtml.jsx';
import 'brace/theme/sqlserver';
import 'brace/mode/sql';
import { format } from 'sql-formatter';
import Clipboard from 'clipboard'

const $= window.$;
export default class WorkspaceCustomQuery extends React.Component{
    constructor(props){
        super(props);
        this.entityName=this.props.entityName;
        this.query=this.props.query;
        this.type = this.props.type;
    }
    componentDidMount(){
        var that = this;
        var ua = window.navigator.userAgent; 
        var edge = ua.indexOf("Edge"); 
        if(edge <0) {  
            ace.require("brace/ext/language_tools");
            this.editor = ace.edit("advancedcustomquery");  
            this.editor.setTheme("ace/theme/sqlserver");  
            this.editor.session.setMode("ace/mode/sql");   
            this.editor.setOptions({          
                enableBasicAutocompletion: true, 
                enableSnippets: true,   
                enableLiveAutocompletion: true  
            }); 
            this.editor.setOption("wrap", true);
				this.editor.getSession().on('change', function() {
					$("#copycustomquery").attr('data-clipboard-text',that.editor.getSession().getValue())
				});
                $("#advancedcustomquery").on("paste",function(e){
                    that.editor.session.setValue(format(that.editor.session.getValue()))
                })
        }
        setTimeout(function(){
            var clipboard = new Clipboard('.copycustomquery')
            
        },1000)
    }
    render(){
        if(this.query!=undefined&&this.query.length>0){
            this.query = format(this.query)
        }
        return(
            <CustomQueryHtml
                wscustomqueryName={this.entityName}
                wscustomquery={this.query}
                type={this.type}
                validateworkspacecustomquery={this.props.validateworkspacecustomquery}
                saveworkspacecustomquery={this.props.saveworkspacecustomquery}
            />
        );
    }
}