import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import _ from 'underscore';
import PubSub from 'pubsub-js'
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import SendRequest from '../../SendRequest';
import MLDatasnapshotTemplate from "./viewmldatasnapshot";
import "twbs-pagination/jquery.twbsPagination";
import "bootstrap-table/dist/bootstrap-table";
import { getMessage } from '../../js/helpers/utils/BirdMessage';
import { lang } from "../../js/helpers/utils/languages/language";
import * as MLCharts from "./mlcharts";
const $ = window.$;
export default class MLDatasnapshotview extends React.Component {
    constructor(props) {
        super(props);
        this.options = props
        this.state = {
            tableColumnsArray:[],
            searchedColumnsArray:[],
            isfromsearch:false
        };
        this.render();
        this.afterRender();
    }
    afterRender() {
        var that = this;
        $(".tablerowlimitml li").click(function (e) {
            that.drawDataSnapShotforDataTablePage(e);
        });
        $("#mldatasnapshot .smartinsightintro-container").addClass("hide");
        $("#mlsnapshotdatacontainer").removeClass("hide")
        
        var width, windowWidth = $(window).width();
        if (windowWidth > 780) {
            width = $(window).width() / 3 + 100
        } else {
            width = $(window).width() / 4 + 50
        }
        this.width = width;
        var dataTableData = this.options.data.dataTableData;
        var correlationData = this.options.data.correlationData;
        var chiSquareData = this.options.data.chiSquareData;
        var binaryColumns = this.options.data.binaryColumns;
        var reportArrayOfMissingCol = this.options.data.reportArrayOfMissingCol;
        this.state.tableColumnsArray = this.options.data.tableColumnsArray
        this.options.reportId=this.options.reportId!=undefined?this.options.reportId:"";
        this.reportId=this.options.reportId
        if (reportArrayOfMissingCol != undefined) {
            ChartInfo.functions.setReportArrayMapOfMissingCol(this.options.reportId, reportArrayOfMissingCol[0] != undefined ? reportArrayOfMissingCol[0] : "");
        }
        if (binaryColumns != undefined && binaryColumns != null && binaryColumns.length > 0) {
            ChartInfo.functions.setColumnHavingTwoDistinctValues(binaryColumns);
        }
        this.tableInfo = {
            drilldownvalue: "",
            showTitle: true,
            paginationdiv: true
        }
        this.options.customfield=this.options.customfield!=undefined?this.options.customfield:null;
        this.options.globalfilterArray = this.options.globalfilterArray!=undefined?this.options.globalfilterArray:[]
        this.drawDataSnapShotBootstrapTable(this.options.reportId, this.options.customfield, this.options.globalfilterArray, this.fromIndex, this.toIndex, dataTableData, this.width, this.tableInfo, this.pageLimit);
        //Add Correlation Data
        ChartInfo.functions.setReportCorrelationData(this.options.reportId, correlationData);
        var renderto, categories, correlatedcolumns;
        if (correlationData != undefined && correlationData != null && correlationData[0] != undefined && correlationData[0].length > 0) {
            categories = correlationData[0];
            correlatedcolumns = correlationData[1];
            renderto = $(".mlsnapshotcorrelatedcolumns .overviewchart");
            MLCharts.charts.drawSmartBubbleChart(correlatedcolumns, categories, renderto);
        } else if (correlationData != undefined && correlationData != null && correlationData[1] != undefined && correlationData[1].length > 0) {
            correlatedcolumns = correlationData[1];
            $(".mlsnapshotcorrelatedcolumns .overviewchart").append("<span class='mlnodatamessage'>" + correlatedcolumns[0] + "</span>")
        }
        else {
            $(".mlsnapshotcorrelatedcolumns .overviewchart").append("<span class='mlnodatamessage'>" + getMessage('BirdInformation49') + "</span>")
        }
        //Add Chi Square Data
        /*if (chiSquareData != undefined && chiSquareData != null && chiSquareData.length > 0) {
            var col = [];
            var chiSquareDataWarning;
            for (var i = 0; i < chiSquareData.length; i++) {
                if (chiSquareData[i].Column == undefined) {
                    chiSquareDataWarning = chiSquareData[i];
                }
                if (col.indexOf(chiSquareData[i].Column) === -1) {
                    col.push(chiSquareData[i].Column);
                }
            }
            var colObj = _.invert(col);
            var row = [];
            for (var i = 0; i < chiSquareData.length; i++) {
                if (chiSquareData[i].Row == undefined) {
                    chiSquareDataWarning = chiSquareData[i];
                }
                if (row.indexOf(chiSquareData[i].Row) === -1) {
                    row.push(chiSquareData[i].Row);
                }
            }
            if (chiSquareDataWarning == undefined) {
                var rowObj = _.invert(row);
                var header = "";
                for (var i = 0; i < col.length; i++) {
                    header = header + '<th class="text-center"><b>' + col[i] + '</b></th>'
                }
                var chiSquareValueObj = {};
                for (var j = 0; j < chiSquareData.length; j++) {
                    var colIndex = colObj[chiSquareData[j].Column];
                    var rowIndex = rowObj[chiSquareData[j].Row];
                    if (chiSquareValueObj[rowIndex] != undefined && Object.keys(chiSquareValueObj[rowIndex]).length) {
                        chiSquareValueObj[rowIndex] = chiSquareValueObj[rowIndex];
                    } else {
                        chiSquareValueObj[rowIndex] = {};
                    }
                    if (chiSquareData[j]["Chi.Square"] != undefined) {
                        chiSquareValueObj[rowIndex][colIndex] = chiSquareData[j]["Chi.Square"];
                    }
                }
                var allRows = '';
                for (var i = 0; i < row.length; i++) {
                    var rowdata = '<tr><td align="right"><b>' + row[i] + '</b></td>'
                    var coldata = '';
                    for (var j = 0; j < col.length; j++) {
                        if (chiSquareValueObj[i][j] != undefined) {
                            coldata = coldata + '<td class="text-center">' + chiSquareValueObj[i][j] + '</td>'
                        } else {
                            coldata = coldata + '<td class="text-center">-</td>'
                        }
                    }
                    rowdata = rowdata + coldata + '</tr>';
                    allRows = allRows + rowdata;
                }
                $(".mlchisquaretable .overviewchart").append('<div class="notifications-table-container marg-top-15 "><table class="table" id="mlchisquretable" data-toggle="table" data-pagination="false" data-page-size="5" data-smart-display="false" data-search="false"><thead></thead><tbody></tbody></table></div>');
                $(".mlchisquaretable .overviewchart table>thead").append('<tr><th></th>' + header + '</tr>');
                $(".mlchisquaretable .overviewchart table>tbody").append(allRows);
                $(".mlchisquaretable .overviewchart table").bootstrapTable({ height: 300 });
            } else {
                $(".mlchisquaretable .overviewchart").append("<span class='mlnodatamessage'>" + chiSquareDataWarning + "</span>");
            }
        } else {
            $(".mlchisquaretable .overviewchart").append("<span class='mlnodatamessage'>" + getMessage('BirdInformation48') + "</span>");
        }*/
        var renderto, categories, correlatedcolumns;
        if (chiSquareData != undefined && chiSquareData != null && chiSquareData[0] != undefined && chiSquareData[0].length > 0) {
           let chisquarecategories = chiSquareData[0];
            let chisquredata = chiSquareData[1];
            renderto = $(".mlchisquaretable .overviewchart");
            MLCharts.charts.drawSmartBubbleChart(chisquredata, chisquarecategories, renderto);
        } else if (chiSquareData != undefined && chiSquareData != null && chiSquareData[1] != undefined && chiSquareData[1].length > 0) {
            let chisquredata = chiSquareData[1];
            $(".mlchisquaretable .overviewchart").append("<span class='mlnodatamessage'>" + chisquredata[0] + "</span>")
        }
        else {
            $(".mlchisquaretable .overviewchart").append("<span class='mlnodatamessage'>" + getMessage('BirdInformation48') + "</span>");
        }
    }
    drawDataSnapShotBootstrapTable(reportId, customfield, globalfilterArray, fromIndex, toIndex, dataTableData, width, tableInfo, pageLimit) {
        //Add Data to the table
        $(".variablestable").bootstrapTable("destroy")
        $(".variablestable thead").empty();
        $(".variablestable tbody").empty();
        $(".variablestable tbody").remove()
        $(".variablestable thead").append('<tr> <th data-field="mainatrribute" data-sortable="true">'+lang["mlreports.attributes"]+'</th> <th   data-field="Type" data-sortable="true">'+lang["mlreports.datatype"]+'</th> <th  data-field="MissingRows" >'+lang["mlreports.missingrows"]+'(%) </th> <th >'+lang["mlreports.stats"]+'</th> <th data-field="Distribution" class="text-center" data-width="' + width + 'px">'+lang["mlreports.distribution"]+'</th> </tr>')
        $(".variablestable").append("<tbody></tbody>");
        $('#datatable-pagination-view-ml').twbsPagination('destroy');
        $(".tablerowlimitml").removeClass('active');
        _.each($(".tablerowlimitml li"), function (value) {
            if (pageLimit == value.textContent) {
                $(value).addClass("active")
            }
        });
        var totalPages = 1;
        var pageDisplay;
        var page;
        var totalRecords = this.state.searchedColumnsArray.length>0?this.state.searchedColumnsArray.length:this.options.columnsArray.length;
        if (tableInfo != undefined && tableInfo.pageLimit != undefined) {
            pageDisplay = parseInt(tableInfo.pageLimit);
        }
        if (pageDisplay == undefined) {
            pageDisplay = 5;
        }
        if (totalRecords > 5) {
            totalPages = Math.ceil(totalRecords / pageDisplay);
        }
        if (tableInfo != undefined) {
            page = tableInfo.page;
        }
        var currentpage = page;
        if (currentpage == undefined) {
            currentpage = 1;
        }

        _.each(dataTableData, function (i) {
            var distributiondata, datasnapshotstats = "";
            if (i.Type.toLowerCase() == "numerical") {
                distributiondata = '<td data-sparkline="' + i.Distribution + '" data-width="' + width + 'px"></td>'
                datasnapshotstats = '<span><b>'+lang["mlreports.mean"]+' : </b>' + i.Mean + '<br><b>'+lang["mlreports.median"]+' : </b>' + i.Median + '<br><b>'+lang["mlreports.quantile"]+' : </b>' + i.Quantile + '</span>'
            } else if (i.Type.toLowerCase() == "categorical") {
                distributiondata = '<td data-treemap="' + i.Distribution + '" data-width="' + width + 'px"></td>'
                datasnapshotstats = '<span><b>'+lang["mlreports.nooflevels"]+' : </b> ' + i.NoOfLevels + ' <br><b>'+lang["mlreports.top5levels"]+' :</b>' + i.top5Levels + ' <br><b>'+lang["mlreports.levels80%data"]+' : </b> ' + i.noOfLevelsMaxData + ' </span>'
            } else if (i.Type.toLowerCase() == "date") {
                distributiondata = '<td data-datemap="' + i.Distribution + '" data-width="' + width + 'px"></td>'
            }
            $(".variablestable tbody").append('<tr>'
                + '<td>' + i.mainatrribute + '</td>'
                + '<td>' + i.Type + '</td>'
                + '<td>' + i.MissingRows + '</td>'
                + '<td>' + datasnapshotstats + '</td>'
                + distributiondata
                + '</tr>');
        });
        $(".variablestable").bootstrapTable({
            onAll: function () {
                MLCharts.charts.drawMLSparkLineChart("variablestable", width);
                MLCharts.charts.drawMLSparkColumnChart("variablestable", width);
                MLCharts.charts.drawMLTreeMapChart("variablestable", width);
            },
            onSort: function () {
                $(".spinner").show();
                setTimeout(function () {
                    $(".spinner").hide();
                }, 3500);
            },

        });
//        $(".spinner").hide();
        if ($("#datatable-pagination-view-ml").data("twbs-pagination")) {
            $("#datatable-pagination-view-ml").twbsPagination('destroy');
        }
        var that = this;
        $("#datatable-pagination-view-ml").twbsPagination({
            totalPages: totalPages,
            visiblePages: 5,
            startPage: currentpage,
            first: "«",
            prev: "‹",
            next: "›",
            last: "»"
        }).on('page', function (evt, pageSelected) {
            that.page = pageSelected;
            evt.stopPropagation();
            that.tableInfo = {
                groupBy: 'asc',
                sortBy: '',
                downloadtype: undefined,
                showTitle: true,
                page: pageSelected,
                pageLimit: pageLimit,
                paginationdiv: tableInfo != undefined && tableInfo.paginationdiv != undefined ? tableInfo.paginationdiv : undefined
            };
            $(".spinner").show();
            that.drawDataSnapShotforDataTable(reportId, customfield, globalfilterArray, fromIndex, toIndex, that.page, width, that.tableInfo, pageLimit)
        });
        if($(".mlstoryboardtabs.fill-height.loadingmlreport").length==0){
            $(".spinner").hide();
        }
    }
    drawDataSnapShotforDataTablePage = (e) => {
        e.preventDefault();
        var $this = e.currentTarget;
        this.pageLimit = parseInt($($this).find('a').text());
        this.page = 1;
        $(".tablerowlimitml li").removeClass("active");
        this.tableInfo = {
            groupBy: 'asc',
            sortBy: '',
            page: this.page,
            downloadtype: undefined,
            pageLimit: this.pageLimit,
            showTitle: true,
            paginationdiv: this.tableInfo != undefined && this.tableInfo.paginationdiv != undefined ? this.tableInfo.paginationdiv : undefined
        };
        this.options.reportId=this.options.reportId!=undefined?this.options.reportId:"";
        this.options.customfield=this.options.customfield!=undefined?this.options.customfield:null;
        this.options.globalfilterArray = this.options.globalfilterArray!=undefined?this.options.globalfilterArray:[];
        $(".spinner").show();
        this.options.page = this.page
        this.options.pageLimit = this.pageLimit
        this.drawDataSnapShotforDataTable(this.options.reportId, this.options.customfield, this.options.globalfilterArray, this.options.fromIndex, this.options.toIndex, this.page, this.width, this.tableInfo, this.pageLimit)
        $($this).addClass('active');
    }
    drawDataSnapShotforDataTable=(reportId, customfield, globalfilterArray, fromIndex, toIndex, pageSelected, width, tableInfo, pageLimit) =>{
        var that = this.options;
        pageLimit = pageLimit!=undefined?pageLimit:5;
        fromIndex = (pageSelected - 1) * pageLimit;
        toIndex = fromIndex + pageLimit;
        let requestHeaders = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        let formdata=new FormData();
            formdata.set("reportId",that.reportId);
            formdata.set("customFields", JSON.stringify(customfield));
            formdata.set("filterObject",JSON.stringify(globalfilterArray));
            formdata.set("fromIndex",fromIndex);
            formdata.set("toIndex",toIndex);
            formdata.set("isDataTableChange",true);
            formdata.set("isRunModel",that.reportId != undefined && that.reportId.length > 0? false : true);
            formdata.set("datamodelId",that.datamodelId);
            formdata.set("searchObj",JSON.stringify(this.state.searchedColumnsArray));
            formdata.set("isfromsearch",this.state.isfromsearch);
        SendRequest({
            url: "getsnapshotdata",
            body:formdata,
            sucessFunction: response => {
                if (response.hasOwnProperty('error')) {
                    $(".spinner").hide();
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('hide');
                    $('.birdmessage h2').empty().append('Error : ');
                    $('.birdmessage h2').append('<span class="errorcode">');
                    $('.birdmessage .errorcode').empty().append(response.errorCode);
                    $('.birdmessage-info').empty().text(response.error);
                    $('.details').addClass('show').text("Details");
                    $('.messagedetails xmp').empty().append(response.errorTrace)
                } else {
                    this.drawDataSnapShotBootstrapTable(reportId, customfield, globalfilterArray, fromIndex, toIndex, response.dataTableData, width, tableInfo, pageLimit);
                    $(".page-size").html(pageLimit);
                }
            },
            rejectFunction: () => { },
            headers: requestHeaders
        })
    }
    searchKeyUp = (e) =>{
        e.preventDefault();
        e.stopPropagation();
        let $this = e.currentTarget;
        let searchval = $($this).val().toLowerCase();
        this.page = 1;
        this.pageLimit = $(".tablerowlimitml1.active").text()!=""?parseInt($(".tablerowlimitml1.active").text()):5;
        if(searchval!=""){
            this.state.searchedColumnsArray = this.state.tableColumnsArray.filter(obj=>obj.columnDisplayName?obj.columnDisplayName.toLowerCase().indexOf(searchval)!=-1:obj.columnName.toLowerCase().indexOf(searchval)!=-1);
            this.state.isfromsearch = true;
        }else{
            this.state.searchedColumnsArray=[];
            this.state.isfromsearch = false;
        }
        $(".spinner").show();
        this.drawDataSnapShotforDataTable(this.options.reportId, this.options.customfield, this.options.globalfilterArray, this.options.fromIndex, this.options.toIndex, this.page, this.width, this.tableInfo, this.pageLimit)
    }
    render() {
        $("#mlsnapshotdatacontainer").empty();
        let today = new Date();
        let dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
        $("#mlsnapshotdatacontainer").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
        ReactDOM.render(<MLDatasnapshotTemplate searchKeyUp={this.searchKeyUp}/>, document.getElementById(dynamicdiv));
        return (
            <React.Fragment />
        )
    }
}