import React from 'react';
import ReactDOM from 'react-dom';
import * as ChartInfo from '../js/helpers/utils/chartinfo';
import SingleSavedReportFilter from './singlesavedreportfiltermodal.jsx';
import ViewReportFilterView from './viewreportfilter';
import _ from 'underscore';
import PubSub from 'pubsub-js';
const $=window.$;

export default class SingleSavedReportFilterModal extends React.Component{
    constructor(props){
        super(props);
        this.state={};
        this.state.features = props.features;
        this.state.datamodelId = props.datamodelId;
        this.state.reportId = props.reportId;
        this.state.filterArray = props.filterArray;
        this.state.sharedFilters = props.sharedFilters;
        this.state.customparameterfileterArray = props.customparameterfileterArray;
        this.render();
    }
    render(){
        ReactDOM.render(
            <SingleSavedReportFilter addCustomParamerFilter={this.props.addCustomParamerFilter} isdirect={ChartInfo.functions.getIsDirect()} />,
            document.getElementById('filtersmodaldiv0')
        )
        return(<></>);
    }
    addContent=(bool,isCustomParameter)=>{
        $(".modal.fade.singlesavedreportmodal").css("opacity","");
        var viewreportfilterview = new ViewReportFilterView({
            //broker : this.broker,
            filterArray:this.state.filterArray,
            features:this.state.features,
            schedule:this.state.schedule,
            isCustomParameter:isCustomParameter
        });
        var parsedSharedfilters;
        if(this.state.sharedFilters != undefined && this.state.sharedFilters.length > 2){
            parsedSharedfilters = JSON.parse(this.state.sharedFilters);
        }
        var initialfiterCount = ChartInfo.functions.getFilters();
        var that = this;
        $(".showbutton").trigger("click");
            $(".singlereportfiltercheckbox").each(function(element){
                var $this = this;
                if($($this).is(":checked")){
                    var tableName = $($this).attr("data-parent");
                    var columnDisplayName =  $($this).attr("data-child")
                    var filterDisplayName = $("input.filter-display-name[data-name='" + $($this).attr("data-child") + "']").val();
                    var filterIgnore = false;
                    var columnName = $($this).parent().attr("title").trim();
                    var dataType = $(this).attr("data-type");
                    var daterange = $(this).attr("date-range");
                    var existingObj = that.isfilterAlreadyexists(parsedSharedfilters, columnName);
                    if(existingObj!= undefined && Object.keys(existingObj).length>0){
                        var tempArray = [];
                        var dataType="";
                        var UsersFilterArray = [];
                        var filterObjColumnName="";
                        var toRange="";
                        var fromRange="";
                        tableName = existingObj.tablename;
                        columnDisplayName = existingObj.columndisplayname;
                        filterDisplayName = existingObj.filterDisplayName;
                        filterIgnore= existingObj.filterIgnore;
                        columnName = existingObj.columnname
                        dataType = existingObj.dataType
                        fromRange = existingObj.fromRange
                        toRange = existingObj.toRange
                        UsersFilterArray  = tempArray.push(existingObj);
                        daterange = existingObj.daterange
                        viewreportfilterview.addfilteritem(filterDisplayName,filterIgnore,tableName, columnName, columnDisplayName, dataType,fromRange,toRange,UsersFilterArray,that.state.datamodelId,"" ,daterange,undefined, undefined,undefined, that.state.sharedFilters);	
                    }else{
                        //viewreportfilterview.addfilteritem(tableName, columnName, columnDisplayName, dataType,that.options.filterObject,that.options.datamodelId,"" ,daterange,undefined, undefined,undefined,undefined,undefined, that.options.sharedFilters);	
                        viewreportfilterview.addfilteritem(filterDisplayName,filterIgnore,tableName, columnName, columnDisplayName, dataType,fromRange,toRange,"",that.state.datamodelId,"" ,daterange,undefined, undefined,undefined, that.state.sharedFilters);	

                    }
                }
                else{
                    var tableName = $($this).attr("data-parent");
                    var columnDisplayName = $($this).attr("name").replace(/([-;%?&,.+*~\':"!^$[\]()<=>|\/@])/g, "\\$1");
                    $('#'+columnDisplayName).remove();
                }
                
            });
            var that2=this;
            $(".singlereportcustomparameterfiltercheckbox").each(function(element){
                var $this = this;
                if($($this).is(":checked")){
                    var tableName='';
                    var columnDisplayName = $($this).attr("name");
                    var filterDisplayName = $("input.filter-display-name[data-name='"+$($this).attr("name")+"']").val();
                    var filterIgnore = false;
                    var columnName  = $($this).attr("name");
                    var valuetype = $(this).attr("data-valuetype");
                    var value = $(this).attr("data-value");
                    viewreportfilterview.addfilteritem(filterDisplayName,filterIgnore,tableName, columnName, columnDisplayName, valuetype,"","","" , that2.state.datamodelId,"",value,undefined, undefined,undefined, that2.state.sharedFilters);	
                }
                else{
                    var tableName = $($this).attr("data-parent");
                    var columnDisplayName = $($this).attr("name").replace(/([;?&,%#.+*~\':"!^$[\]()<=>|\/@])/g, "\\$1");
                    $('#'+columnDisplayName).remove();
                }
                
            });
            if(this.state.filterArray != undefined){
                var col_name="";
                var filtervalues="";
                var	isSharedReport=false;
                var that =this;
                if(window.location.pathname.indexOf( "sharereport")>=0){
                    isSharedReport=true;
                }
                
                if(isSharedReport == true ){
                    var sharedFilters1 =JSON.parse(this.state.sharedFilters);
                    var totalfilterArray= $.merge($.merge([], []),this.state.filterArray);
                        _.each(totalfilterArray,(filter1, index1)=>{
                            _.each(sharedFilters1,(filter, index)=>{
                                var sharedfiltercolumndisplayname = filter.columndisplayname.replace(/([;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g,"");
                                if(filter1!= undefined){													
                                if(filter1.columndisplayname== sharedfiltercolumndisplayname){
                                    if(filter.dataType != undefined){
                                        //var filtercolumndisplayname = filter.columndisplayname.replace(/([;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g,"");
                                        if(filter.dataType == "number"){
                                            var fromRange 	= filter.fromRange,
                                                toRange 	= filter.toRange,
                                                customRange = filter.customfiltervalue;
                                            var mainfromRange 	= filter1.fromRange,
                                            maintoRange 	= filter1.toRange,
                                            maincustomRange = filter1.customfiltervalue; 
                                            if(fromRange==undefined && toRange==undefined){
                                                fromRange 	= filter.filtergreaterthanvalue;
                                                toRange 	= filter.filterlessthanvalue;
                                            }
                                            if(fromRange>maintoRange ||mainfromRange > toRange){
                                            //delete reportFilterObject[index1];
                                            //reportFilterObject.pop();
                                            //reportFilterObject.slice();
                                                //reportFilterObject=reportFilterObject.slice(index1,1);
                                                if(this.state.filterArray.length != totalfilterArray.length){
                                                    this.state.filterArray.splice(index1-1,1);
                                                }
                                                else{
                                                    this.state.filterArray.splice(index1,1);
                                                }
                                                //accessa.push(reportFilterObject[index1]);

                                            }			}
                                        
                                        else if(filter.dataType == "date"){
                                            var fromDateRange 	= filter.fromRange,
                                                toDateRange 	= filter.toRange,
                                                dateRange 	= filter.daterange;
                                            var mainfromRange 	= filter1.fromRange,
                                            maintoRange 	= filter1.toRange,
                                            maindateRange = filter1.daterange; 
                                            var dates = $('.daterange-center[displayname="'+filter.columndisplayname+'"] span').text();
                                             var sharedFrom = Date.parse(fromDateRange);
                                             var mainTo = Date.parse(maintoRange);
                                             var sharedTo= Date.parse(toDateRange);
                                             var mainFrom= Date.parse(mainfromRange);
                                                if(sharedFrom > mainTo ||mainFrom > sharedTo){
                                                    if(this.state.filterArray.length != totalfilterArray.length){
                                                        this.state.filterArray.splice(index1-1,2);
                                                        }
                                                        else{
                                                            this.state.filterArray.splice(index1,2);
                                                        }																				//accessa.push(reportFilterObject[index1]);
                                                }
                                            //if(customRange== undefined || customRange===""){
                                                //$('.filteritem-container .acc-content[data-id="'+filtercolumndisplayname+'"] .filteredvalues').text(dates).attr("title",dates);
                                            //}
                                        }
                                        
                                        if(filter.dataType == "string"){
                                            var sharedYes= false;
                                            if(filter.customfield == undefined){
                                                var filtereddatatext = filter["data"].replace(/\@@/g, ', ');
                                                var newfiltereddatatext=filtereddatatext.split(",");
                                                var filtereddatatext1 = filter1["data"].replace(/\@@/g, ', ');
                                                var newfiltereddatatext1=filtereddatatext1.split(",");
                                                
                                                _.each(newfiltereddatatext,function(shared,i){
                                                    var filtereddatatext1 = filter1["data"].replace(/\@@/g, ', ');
                                                    if(filtereddatatext1.includes(shared)){
                                                        sharedYes=true
                                                        //accessa.push(reportFilterObject[index1]);
                                                    }
                                            });
                                                if(sharedYes==false){
                                                //	var rfobject=reportFilterObject.splice(index1,1,reportFilterObject[index1]);
                                                    if(that.state.filterArray.length != totalfilterArray.length){
                                                        that.state.filterArray.splice(index1-1,1);
                                                        }
                                                        else{
                                                            that.state.filterArray.splice(index1,1);
                                                        }
                                                    //reportFilterObject=$.merge($.merge([], accessa),reportFilterObject.splice(index1,1))
                                                }
                                            }else{
                                                var filtereddatatext = filter.customfield.sqlcondition.sqldata.replace(/\@@/g, ', ');
                                                var newfiltereddatatext=filtereddatatext.split(",");
                                                var filtereddatatext1 = filter1.customfield.sqlcondition.sqldata.replace(/\@@/g, ', ');
                                                var newfiltereddatatext1=filtereddatatext1.split(",");
                                            }
                                        }
                                        
                                    }}}
                            });
                        });
                        }
                // _.each(this.state.filterArray,function(filter){
                //     var filterObject = [];
                //     filterObject.push(filter);
                //     if(col_name===filter["tablename"]+"."+filter["columnname"]){
                        
                //     }
                //     else{
                //         col_name=filter["tablename"]+"."+filter["columnname"];
                //         viewreportfilterview.addfilteritem(filter["filterDisplayName"],filter["filterIgnore"],filter["tablename"], filter["columnname"],filter["columndisplayname"], filter["dataType"],filter['fromRange'],filter['toRange'],filterObject,that.state.datamodelId,"", filter["daterange"],undefined, undefined,undefined, that.state.sharedFilters);
                    
                //     filtervalues=filtervalues+filter['columndisplayname'].replace(/([#;?&,%.+*~\':"!^$%[\]()<=>`|\/@])/g, "")+",";
                //     }
                // });
                var filterObject = [];
                _.each(this.state.filterArray,function(filter){
                    filterObject.push(filter);
                });
                // if(bool===0){
                //     sessionStorage.setItem("applicablefilters", filtervalues);
                // }
                //$(".filtercontent").append(viewreportfilterview.el);

                if($(".singlereportview #filteraccordion.filteritem-container").length>0){
                    var reportFilterObject = this.state.filterArray;
                    if(reportFilterObject!=undefined && reportFilterObject.length>0){
                        $(".tab-filters-container").addClass("loading");
                        var totalFilterItemsCount = reportFilterObject.length;
                        var FilterCount = 0;
                        var checkColumnName = "";
                
                        //First filter item request
                        var filter = reportFilterObject[0];
                        if (col_name === filter["tablename"] + "." + filter["columnname"]) {
                        } else {
                            checkColumnName=filter["columnname"];
                            col_name = filter["tablename"] + "." + filter["columnname"];
                            //filterObjects = collection.getFilterObject();
                            viewreportfilterview.addfilteritem(filter["filterDisplayName"],filter["filterIgnore"],filter["tablename"], filter["columnname"],filter["columndisplayname"], filter["dataType"],filter['fromRange'],filter['toRange'],filterObject,that.state.datamodelId,"", filter["daterange"],undefined, undefined,undefined, that.state.sharedFilters);
                            filtervalues = filtervalues + filter["columndisplayname"].replace(/([#;?&,%.+*~\':"!^$%[\]()<=>`|\/@])/g, "") + ",";
                            FilterCount++
                        }
                
                        //Loop function for next filter items
                        function requestFilterItem(){
                            if($(".singlereportview #filteraccordion .panelboc-title[title='"+checkColumnName+"']").length!=0){
                                var filter = reportFilterObject[FilterCount];
                                if(filter!=undefined){
                                    if (col_name === filter["tablename"] + "." + filter["columnname"]) {
                                    } else {
                                        checkColumnName=filter["columnname"];
                                        col_name = filter["tablename"] + "." + filter["columnname"];
                                        //filterObjects = collection.getFilterObject();
                                        viewreportfilterview.addfilteritem(filter["filterDisplayName"],filter["filterIgnore"],filter["tablename"], filter["columnname"],filter["columndisplayname"], filter["dataType"],filter['fromRange'],filter['toRange'],filterObject,that.state.datamodelId,"", filter["daterange"],undefined, undefined,undefined, that.state.sharedFilters);
                                        filtervalues = filtervalues + filter["columndisplayname"].replace(/([#;?&,%.+*~\':"!^$%[\]()<=>`|\/@])/g, "") + ",";
                                        FilterCount++
                                        requestFilterItem();
                                    }
                                }else{
                                    $(".tab-filters-container").removeClass("loading");
                                }
                            }else{
                                if(filtervalues!=undefined && filtervalues!=""){
                                    sessionStorage.setItem("applicablefilters", filtervalues);
                                }
                                setTimeout(function(){
                                    if(totalFilterItemsCount!=FilterCount){
                                        requestFilterItem();
                                    }else{
                                        var spinnerCount = 0;
                                        function closeSpinner(){
                                            if($(".singlereportview.fullscreenmain.maxanim1").length>0){
                                                var appenedFilters = $(".tab-filters-container #filteraccordion [classname]").length
                                            }else if($("#storyboardtab .tab-filters-container").length>0){
                                                var appenedFilters = $("#storyboardtab .tab-filters-container #filteraccordion [classname]").length
                                            }

                                            if(appenedFilters==undefined || appenedFilters==FilterCount || spinnerCount==6){
                                                $(".tab-filters-container").removeClass("loading");
                                            }else{
                                                setTimeout(function(){
                                                    spinnerCount++
                                                    closeSpinner()
                                                },1000);
                                            }
                                        }
                                        closeSpinner();
                                    }
                                },1000)
                            }
                        }
                
                        //Initiate fiter Loop function
                        requestFilterItem();
                
                    }
                    if(bool===0){
                        sessionStorage.setItem("applicablefilters", filtervalues);
                    }
                }


            }
            if(this.state.customparameterfileterArray != undefined){
                var col_name="";
                var filtervalues="";
                _.each(this.state.customparameterfileterArray,function(filter){
                    if(col_name===filter["tablename"]+"."+filter["columnname"]){
                        
                    }
                    else{
                        col_name=filter.parametername;
                        viewreportfilterview.addfilteritem(filter["filterDisplayName"],filter["filterIgnore"],filter["tablename"], filter.parametername,filter.parametername, filter.valueType,filter['fromRange'],filter['toRange'],that.state.filterObject,that.state.datamodelId,"", filter.parametercurrentvalue,undefined, undefined,undefined, that.state.sharedFilters);
                    filtervalues=filtervalues+filter['columndisplayname']+",";
                    }
                
                    
                    });
                //$(".filtercontent").append(viewreportfilterview.el);
            }
            $('.isexcludenulls').prop('checked',this.state.isExcludeNulls); 
            var finalfiterCount = ChartInfo.functions.getFilters();
            if(initialfiterCount!=undefined && initialfiterCount.length >0 && finalfiterCount!=undefined && finalfiterCount.length==0){
                ChartInfo.functions.setIsFiltersAdded(false);
                sessionStorage.setItem("changesdone",1);
                $('.trend-stats-container').remove();
                $('.addInstanceFilterData').empty();
                if($('.acc-content').length==0 && $('.addInstanceFilterData').text().trim().length==0){
                    if($('.addURLInstanceFilterData').text().trim().length==0){
                        $('.instant-floating-filters').hide();
                        $('.allscopeslistcontainer').hide();
                    }
                }
                sessionStorage.removeItem("instantExcludeFilters");
                sessionStorage.removeItem("instantIncludeFilters");
                sessionStorage.removeItem("instantExcludeCustomFilters");
                ChartInfo.functions.setInstanceIncludeFilterData("");
                ChartInfo.functions.setInstanceFilterData("");
                ChartInfo.functions.setIncludeFilterData("");
                ChartInfo.functions.setdrillfitlerArray("");
                $('.refreshsinglecharts').trigger('click');
                ChartInfo.functions.getFilters(true);
                PubSub.publish("applyfiltesandredrawchart");
               // this.broker.trigger('applyfiltesandredrawchart');
            
            }
    }
    isfilterAlreadyexists =(parsedSharedfilters, columnName)=>{
        var obj;
        _.each(parsedSharedfilters,function(value){
            if(value.columnname == columnName){
                obj = value
            }
        });
        return obj;
    }
}