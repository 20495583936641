import React, { Component } from "react";
import _ from 'lodash';
import $ from 'jquery';
import * as Highcharts from 'highcharts/highstock';
import exporting from "highcharts/modules/exporting"
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import * as ChartUtil from "../../js/helpers/utils/chartutil";
import { whitelableMap } from "../../js/helpers/utils/whitelable";
import { getMessage } from '../../js/helpers/utils/BirdMessage';
//const $ = window.$;
exporting(Highcharts);
var charts = {};



export default class SparkLine extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        $('.highcharts-exporting-group').remove();
    }
    render() {
        var data = this.props.data, xaxisname = this.props.xaxisname, xaxisColumnName = this.props.xaxisColumnName, xaxisDataType = this.props.xaxisDataType,
            yaxisnameArray = this.props.yaxisnameArray, chartid = this.props.chartid, chartType = this.props.chartType, legend = this.props.legend, position = this.props.position, features = this.props.features, isSharedReport = this.props.isSharedReport, colorThemeIndex = this.props.colorThemeIndex, chartSettings = this.props.chartSettings, tooltipNameArray = this.props.tooltipNameArray,
            algorithm = this.props.algorithm, periodsvalue = this.props.periodsvalue, trendstats = this.props.trendstats, yAxisDataTypeArray = this.props.yAxisDataTypeArray, yAxisAggArray = this.props.yAxisAggArray, isMLReport = this.props.isMLReport, ismultifact = this.props.ismultifact, multifactentities = this.props.multifactentities, datamodelId = this.props.datamodelId, facttables = this.props.facttables;

        var colorThemesArray = ChartInfo.functions.getColorThemes();
        var isExportChartEnable = false;
        sessionStorage.setItem('pos', position);
        var chartnumber = chartid.replace("chart", "");
        this.state.chartnumber = chartnumber;
        if(charts!=undefined && chartid!=undefined && charts[chartid]!=undefined &&  charts[chartid].refs!=undefined &&  charts[chartid].refs.chartComponent!=undefined &&  charts[chartid].refs.chartComponent.chart!=undefined){
            charts[chartid].refs.chartComponent.chart.destroy();
        }
        if ($('.tileview[data-num=' + chartnumber + ']').length === 1) {
            var setheight = $('.tileview[data-num=' + chartnumber + ']').height() - 42;
            $("#chart" + chartnumber).height(setheight);
        }
        $("#chart" + chartnumber + " .highcharts-container").remove();
        isExportChartEnable = ChartUtil.checkExportChartEnable(features, position, isSharedReport);
        var userLang = navigator.language || navigator.userLanguage;
        var locale = "en_za";
        var that = this;
        var categories = [];
        var trendstatsdata = data;
        var legendData;
        var legendExceedDataInfo = false;
        if (legend != undefined && legend.columnName.length > 0) {
            _.each(data, function (dataObject, i) {
                if (dataObject.length > 0 && dataObject[0] != undefined && dataObject[0]["legendExceedDataInfo"] != undefined && dataObject[0]["legendExceedDataInfo"]) {
                    legendExceedDataInfo = true;
                }
            });
        }
        if (trendstats == "trendstats") {
            var tempDataArray = [];
            _.each(data, function (dataObject, i) {
                if (legend == undefined) {
                    if (dataObject["data"] != undefined) {
                        tempDataArray.push(dataObject);
                    }
                }
                else {
                    _.each(yaxisnameArray, function (yAxisObject, j) {
                        if (dataObject["data"] == undefined && dataObject[yAxisObject] != undefined) {
                            tempDataArray.push(dataObject);
                        }
                        else {
                            if (j == 0) {
                                tempDataArray.push(dataObject);
                            }

                        }
                    });
                }
            });
            data = tempDataArray;

        }
        if (legend != undefined && legend.columnName.length > 0) {
            categories = data[0];
            var tempdata = [];
            var drilldowndata = [];
            drilldowndata.push($.extend({}, data[0]));
            sessionStorage.setItem("drilldowndata" + chartid.split("chart")[1], JSON.stringify(drilldowndata));
            if (data.length > 1) {
                _.each(data, function (i, j) {
                    if (j & 1 == 1) {
                        tempdata = tempdata.concat(data[j]);
                    }
                });
                legendData = tempdata;
            } else {
                legendData = data[1];
            }
        }

        $('.sparklinecontainer[data-num="' + chartnumber + '"]').remove();
        var legendArray = [];
        var j = 0;
        var legendLength = 0;
        if (legend != undefined && legend.columnName.length > 0) {
            var xAxisPointNames = '';
            $('#' + chartid).append('<div class="sparklinecontainer" data-num="' + chartnumber + '"><table id="table-sparkline" class="table table-sparkline summarytablestyling" data-num=' + chartnumber + ' cellspacing="0" width="100%"><thead><tr></tr></thead><tbody></tbody></table></div>');
            $('#table-sparkline[data-num=' + chartnumber + '] thead tr').append('<th>' + legend.columnName + '</th>');
            $.each(yaxisnameArray, function (i) {
                $('#table-sparkline[data-num=' + chartnumber + '] thead tr').append('<th>' + yaxisnameArray[i] + '</th><th>' + yaxisnameArray[i] + ' By ' + xaxisname + '</th>');
            });
            if (xaxisname == 'Measure Names') {
                $.each(legendData, function (i) {
                    if (!($.inArray(legendData[i].name, legendArray) > -1)) {
                        legendLength = i;
                        var sparklineData = '';
                        $('#table-sparkline[data-num=' + chartnumber + '] tbody').append('<tr></tr>');
                        $('#table-sparkline[data-num=' + chartnumber + '] tbody tr:nth(' + i + ')').append('<td>' + legendData[i].name + '</td>');
                        $.each(legendData[i].data, function (j) {
                            var num = ChartInfo.functions.addFormatting(parseFloat(legendData[i].data[j].y), chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator)
                            $('#table-sparkline[data-num=' + chartnumber + '] tbody tr:nth(' + i + ')').append('<td align="right">' + num + '</td>');
                            sparklineData += legendData[i].data[j].y + ", ";
                            sparklineData = sparklineData.substring(0, sparklineData.length - 1)
                            $('#table-sparkline[data-num=' + chartnumber + '] tbody tr:nth(' + i + ')').append('<td data-sparkline="' + sparklineData + '" data-yname="' + legendData[i].data[j].name + '"/>');
                        });
                    }
                    else {
                        var sparklineData = '';
                        if (j > legendLength) {
                            j = 0;
                        }
                        $.each(legendData[i].data, function (j) {
                            var num = ChartInfo.functions.addFormatting(parseFloat(legendData[i].data[j].y), chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator)
                            $('#table-sparkline[data-num=' + chartnumber + '] tbody tr:nth(' + i + ')').append('<td align="right">' + num + '</td>');
                            sparklineData += legendData[i].data[j].y + ", ";
                            sparklineData = sparklineData.substring(0, sparklineData.length - 1)
                            $('#table-sparkline[data-num=' + chartnumber + '] tbody tr:nth(' + i + ')').append('<td data-sparkline="' + sparklineData + '" data-yname="' + legendData[i].data[j].name + '"/>');
                        });
                        j++;
                    }
                    legendArray.push(legendData[i].name);

                });
            } else {
                $.each(legendData, function (i) {
                    if (!($.inArray(legendData[i].name, legendArray) > -1)) {
                        legendLength = i;
                        var sparklineData = '';
                        $('#table-sparkline[data-num=' + chartnumber + '] tbody').append('<tr></tr>');
                        $('#table-sparkline[data-num=' + chartnumber + '] tbody tr:nth(' + i + ')').append('<td>' + legendData[i].name + '</td>');
                        var num = ChartInfo.functions.addFormatting(parseFloat(legendData[i].yValue), chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator)
                        $('#table-sparkline[data-num=' + chartnumber + '] tbody tr:nth(' + i + ')').append('<td align="right">' + num + '</td>');
                        $.each(legendData[i].data, function (j) {
                            sparklineData += legendData[i].data[j].y + ", ";
                        })
                        sparklineData = sparklineData.substring(0, sparklineData.length - 1)
                        $('#table-sparkline[data-num=' + chartnumber + '] tbody tr:nth(' + i + ')').append('<td data-sparkline="' + sparklineData + '" data-yname="' + legendData[i].yaxisname + '"/>');
                    }
                    else {
                        var sparklineData = '';
                        if (j > legendLength) {
                            j = 0;
                        }
                        var num = ChartInfo.functions.addFormatting(parseFloat(legendData[i].yValue), chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator)
                        $('#table-sparkline[data-num=' + chartnumber + '] tbody tr:nth(' + j + ')').append('<td align="right">' + num + '</td>');
                        $.each(legendData[i].data, function (j) {
                            sparklineData += legendData[i].data[j].y + ", ";
                        })
                        sparklineData = sparklineData.substring(0, sparklineData.length - 1);
                        $('#table-sparkline[data-num=' + chartnumber + '] tbody tr:nth(' + j + ')').append('<td data-sparkline="' + sparklineData + '" data-yname="' + legendData[i].yaxisname + '"/>');
                        j++;
                    }
                    legendArray.push(legendData[i].name);

                });
            }
            xAxisPointNames = data[0];
        }
        else {
            var xAxisPointNames = '';
            $('#' + chartid).append('<div class="sparklinecontainer" data-num="' + chartnumber + '"><table id="table-sparkline" class="table table-sparkline summarytablestyling" data-num=' + chartnumber + ' cellspacing="0" width="100%"><thead><tr></tr></thead><tbody><tr></tr></tbody></table></div>');
            $.each(yaxisnameArray, function (i) {
                $('#table-sparkline[data-num=' + chartnumber + '] thead tr').append('<th>' + yaxisnameArray[i] + '</th><th>' + yaxisnameArray[i] + ' By ' + xaxisname + '</th>');
            });
            $.each(data[0].data, function (j) {
                xAxisPointNames += data[0].data[j].name + ",";
            })
            xAxisPointNames = xAxisPointNames.substring(0, xAxisPointNames.length - 1);
            $.each(yaxisnameArray, function (i) {
                if (data[0].name == 'Measure Names') {
                    var sparklineData = '';
                    $.each(data[0].data, function (j) {
                        if (data[0].data[j].name == yaxisnameArray[i]) {
                            $('#table-sparkline[data-num=' + chartnumber + '] tbody tr').append('<td align="right">' + data[0].data[j].y + '</td>');
                            sparklineData += data[0].data[j].y + ", ";
                        }
                    });
                } else {
                    $('#table-sparkline[data-num=' + chartnumber + '] tbody tr').append('<td align="right">' + data[i].yValue + '</td>'); { }
                    var sparklineData = '';
                    $.each(data[i].data, function (j) {
                        sparklineData += data[i].data[j].y + ", ";
                    })
                }

                sparklineData = sparklineData.substring(0, sparklineData.length - 1);
                var j = 0;
                $('#table-sparkline[data-num=' + chartnumber + '] tbody tr').append('<td data-sparkline="' + sparklineData + '" data-yname="' + yaxisnameArray[i] + '"/>');
            });
        }



        Highcharts.SparkLine = function (a, b, c) {
            var singlewidth = $(a).width();
            //var singlewidth = $(a).parent().parent().parent().find("thead>tr>th").eq($(a).index()).width();

            if (singlewidth == null || singlewidth == undefined || singlewidth == "") { singlewidth = 160; }
            var hasRenderToArg = typeof a === 'string' || a.nodeName,
                options = arguments[hasRenderToArg ? 1 : 0],
                defaultOptions = {
                    chart: {
                        renderTo: (options.chart && options.chart.renderTo) || this,
                        backgroundColor: null,
                        borderWidth: 0,
                        type: 'area',
                        margin: [2, 0, 2, 0],
                        //width: singlewidth,
                        height: 50,
                        style: {
                            overflow: 'visible'
                        },
                        skipClone: true
                    },
                    title: {
                        text: ''
                    },
                    credits: {
                        enabled: false
                    },
                    xAxis: {
                        labels: {
                            enabled: false
                        },
                        title: {
                            text: null
                        },
                        startOnTick: false,
                        endOnTick: false,
                        tickPositions: [],
                        lineWidth: 0,
                        lineColor: '#e0e0e0'
                    },
                    yAxis: {
                        endOnTick: false,
                        startOnTick: false,
                        labels: {
                            enabled: false
                        },
                        title: {
                            text: null
                        },
                        tickPositions: [],
                        lineWidth: 0
                    },
                    legend: {
                        enabled: false
                    },
                    tooltip: {
                        /*backgroundColor: null,
                        borderWidth: 0,
                        shadow: false,*/
                        useHTML: true,
                        hideDelay: 0,
                        outside: true,
                        shared: true,
                        padding: 0,
                        /*positioner: function (w, h, point) {
                            return { x: point.plotX - w / 1, y: -2 };
                        }*/
                    },
                    boost: {
                        enabled: (isMLReport != undefined) ? isMLReport : false
                    },
                    plotOptions: {
                        series: {
                            animation: false,
                            lineWidth: 1,
                            shadow: false,
                            states: {
                                hover: {
                                    lineWidth: 1
                                }
                            },
                            marker: {
                                radius: 1,
                                states: {
                                    hover: {
                                        radius: 2
                                    }
                                }
                            },
                            fillOpacity: 0.25
                        },
                        column: {
                            negativeColor: '#910000',
                            borderColor: 'silver'
                        }
                    },
                    colors: colorThemesArray[colorThemeIndex],
                    exporting:false
                };

            options = Highcharts.merge(defaultOptions, options);

            return hasRenderToArg ?
                new Highcharts.Chart(a, options, c) :
                new Highcharts.Chart(options, b);
        };

        if (legendExceedDataInfo) {
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-infor').addClass('birdmessage-warning');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-info-circle').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(getMessage("BirdWarning110"));
            /*options.subtitle={text:"Series Exceeds"}; 
            options.subtitle.style={color:"red"} */
        }
        var start = +new Date();
        var $tds = $('td[data-sparkline]');
        var fullLen = $tds.length;
        var n = 0;

        // Creating large number of sparkline charts is quite fast in modern browsers, but IE8 and mobile
        // can take some seconds, so we split the input into chunks and apply them in timeouts
        // in order avoid locking up the browser process and allow interaction.
        var names = {};
        var namecount = 0;
        function doChunk() {
            var time = +new Date(),
                i,
                len = $tds.length,
                $td,
                stringdata,
                arr,
                data,
                chart;

            for (i = 0; i < len; i += 1) {
                $td = $($tds[i]);
                stringdata = $td.data('sparkline');
                arr = stringdata.split('; ');
                data = $.map(arr[0].split(', '), parseFloat);
                chart = {};
                var name = $($td).attr("data-yname");
                if (names[name] == undefined) { names[name] = namecount; namecount = namecount + 1 }
                var colors = colorThemesArray[colorThemeIndex][names[name]]
                $td.css("height", "50px");
                if (arr[1]) {
                    chart.type = arr[1];
                }
                chart.width = $("#table-sparkline[data-num='" + chartnumber + "']>thead>tr>th").eq($td.index()).width();
                if(chart.width==undefined || chart.width<160){chart.width=160}
                $td.width(chart.width);
                var newoptions = {
                    // $($td).highcharts({
                    // chart:{
                    //     type:"area"
                    // },
                    colors: [colors],
                    series: [{
                        data: data,
                        pointStart: 1
                    }],
                    tooltip: {
                        /*headerFormat: '<span style="font-size: 10px">'+xaxisname+' :</span><br/>',
                        pointFormat: '<span style="font-size: 10px">'+$($td).attr('data-yname')+' :</span><b>{point.y}</b>'*/
                        formatter: function () {
                            var xAxisPointArray;
                            var xName;
                            if (xaxisname == 'Measure Names') {
                                xName = "Measure Names";
                            }
                            else {
                                if (legend != undefined && legend.columnName.length > 0) {
                                    xAxisPointArray = xAxisPointNames;
                                } else {
                                    xAxisPointArray = xAxisPointNames.split(',');
                                }
                                xName = xAxisPointArray[this.x - 1];
                            }
                            var name = '<span style="font-size: 10px">' + xaxisname + ': </span><b>' + xName + '</b><br/>'
                            var pointvalue = ChartInfo.functions.addFormatting(this.y, chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator)
                            if (this.y == 0) { pointvalue = 0; }
                            name += '<tr><span style="font-size: 10px">' + $(this.series.chart.renderTo).attr('data-yname') + ' :</span><b>' + pointvalue + '</b></tr><br/>'
                            var that = this;
                            return name;
                        },

                        /*formatter: function () {
                            return '<b>' + this.series.yAxis.categories[this.point.y] + '</b><br>'+ '<b>' + this.series.xAxis.categories[this.point.x] + '</b> : <b>'+ this.point.value + '</b>';
                            return '<b>' + this.series.xAxis.categories[this.point.x] + '</b> sold <br><b>' +
                                this.point.value + '</b> items on <br><b>' + this.series.yAxis.categories[this.point.y] + '</b>';
                        },*/
                        shared: false,
                        useHTML: true
                    },
                    chart: chart,
                    exporting:false
                    // })
                }
                new Highcharts.SparkLine($td[0], newoptions);

                n += 1;

                // If the process takes too much time, run a timeout to allow interaction with the browser
                if (new Date() - time > 500) {
                    $tds.splice(0, i + 1);
                    setTimeout(doChunk, 0);
                    break;
                }

                // Print a feedback on the performance
                if (n === fullLen) {
                    //   $('#result').html('Generated ' + fullLen + ' sparklines in ' + (new Date() - start) + ' ms');
                }
            }
        }

        try {
            var table = $('.table-sparkline[data-num=' + chartnumber + ']').DataTable({
                "paging": true,
                "lengthMenu": [[10, 25, 50, 100], [10, 25, 50, 100]],
                "scrollY": '100%',
                "scrollX": true,
                "dom": 'lftrip',
            });
            doChunk();
            setTimeout(function () {
                function reflowSparkline() {
                    _.each($(".sparklinecontainer .dataTables_scrollBody>table>tbody>tr>td[data-sparkline]"), function (chart) {
                        if (chart.hasAttribute("data-sparkline")) { $(chart).highcharts().reflow(); }
                    });
                }
                function resizeSparkLine(currentChart) {
                    var currentChart = currentChart;
                    var numberWidth = 0;
                    _.each($(".sparklinecontainer[data-num=" + currentChart + "] .dataTables_scrollBody>table>tbody>tr:first-child>td:not([data-sparkline])"), function (item) {
                        numberWidth = numberWidth + $(item).outerWidth();
                    });
                    var tileWidth = $(".tileview[data-num=" + currentChart + "]").width() - 20;
                    var availableWidth = tileWidth - numberWidth - 60;
                    var totalcharts = $(".sparklinecontainer[data-num=" + currentChart + "] .dataTables_scrollBody>table>tbody>tr:first-child>td[data-sparkline]").length;
                    var singleChartWidth = availableWidth / totalcharts;

                    _.each($(".sparklinecontainer[data-num=" + currentChart + "] .dataTables_scrollBody>table>tbody>tr>td[data-sparkline]"), function (chart) {
                        if (chart.hasAttribute("data-sparkline")) { 
                           // $(chart).highcharts().setSize(singleChartWidth, 50);
                         }
                    });
                    setTimeout(function () {
                       // var table = $(".table-sparkline[data-num=" + currentChart + "]").DataTable();
                        table.draw();
                    }, 800);

                }
                if ($('.grid-stack').length > 0) {
                    //var grid = $(".grid-stack").gridstack();
                    $(".grid-stack").on('resizestop', function (event, element) {
                        var rechartnumber = element.element.attr('data-num');
                        resizeSparkLine(rechartnumber);
                        window.dispatchEvent(new Event('resize'));
                    });
                }

                $(".minanim[data-num=" + chartnumber + "], .maxanim[data-num=" + chartnumber + "]").on("click",function(){
                    if($(".sparklinecontainer[data-num=" + chartnumber + "]").length>0){
                        resizeSparkLine(chartnumber);
                        window.dispatchEvent(new Event('resize'));
                       // reflowSparkline();
                    }
                });
                //reflowSparkline();
                resizeSparkLine(chartnumber);
            }, 1500);
        } catch (err) { }
        return (
            <React.Fragment>
                {/*  <div style={{ height: "360px" }} ref="chartparent" >
                <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref="chartComponent"
                    />
            </div> */}
            </React.Fragment>
        );
    }
}
