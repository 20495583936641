import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'underscore';
import SendRequest from '../SendRequest';
import * as ChartInfo from './../js/helpers/utils/chartinfo';
import StoreProcedureParameters from './StoreProcedureParameters';
import ConnectionItemsDataContainer from './ConnectionItemsDataContainerHtml.jsx';
import ConnectionTableitemsDataContainer from './ConnectionTableitemsDatacontainerHtml.jsx';
import { lang } from "../js/helpers/utils/languages/language";
import DatahubCustomQuery from './DatahubCustomQuery';
const $ = window.$;

export default class DatabaseConnections extends React.Component{
    constructor(props){
        super(props);
        this.state={
            entityName:props.entityName,
            connId:props.connId,
            loadstoredprocedure:props.loadstoredprocedure,
            isCustomQuery:props.isCustomQuery,
            isSaved:props.isSaved,
            response:{},
            dataHubEntitiesCollection:props.dataHubEntitiesCollection
        }
        this.connectionJson = JSON.parse(props.connectionjson);
        this.connectionJson.command = "testConnection";
        this.connectionJson.connectionaccessid = props.connectionAccessId;
        this.loadcustomquerymodal=this.loadcustomquerymodal.bind(this);
        this.loadstoredproceduredetails=this.loadstoredproceduredetails.bind(this);
    }
    componentDidMount(){
        if(!this.state.loadstoredprocedure&&!this.state.isCustomQuery){
            var data=new FormData();
            data.append("connectionObject",JSON.stringify(this.connectionJson));
            $('.spinner').show();
            SendRequest({
                url: "tables",
                queryString: "",
                body: data,
                sucessFunction: (response) => {
                    if(response.success===true){
                        this.connectionJson.command = "getTables";
                        var info=new FormData();
                        info.append("connectionObject",JSON.stringify(this.connectionJson));
                        $('.spinner').show();
                        SendRequest({
                            url:"tables",
                            queryString:"",
                            body:info,
                            sucessFunction:(resp)=>{
                                if(resp.tables != undefined && resp.tables.length != 0){
                                    resp.tables.sort(function(a, b){
                                    var x = a.toLowerCase();
                                    var y = b.toLowerCase();
                                    if (x < y) {return -1;}
                                    if (x > y) {return 1;}
                                    return 0;
                                  });
                                }
                                if(resp.procedures != undefined && resp.procedures.length != 0){	
                                    resp.procedures.sort(function(a, b){
                                    var x = a.toLowerCase();
                                    var y = b.toLowerCase();
                                    if (x < y) {return -1;}
                                    if (x > y) {return 1;}
                                    return 0;
                                   });
                                }
                                if(resp.views != undefined && resp.views.length != 0){
                                    resp.views.sort(function(a, b){
                                    var x = a.toLowerCase();
                                    var y = b.toLowerCase();
                                    if (x < y) {return -1;}
                                    if (x > y) {return 1;}
                                    return 0;
                                  });
                                }
                              this.state.response=resp
                              $('.spinner').hide();
                              $("a[href='#next']").parent().removeClass("disabled");
                                    if(this.props.connectionType.toLowerCase()==="webdataconnector" || this.props.connectionType.toLowerCase()==="eventhub"){
                                        ReactDOM.render(
                                            <ConnectionTableitemsDataContainer
                                                connectionaccessid={this.props.connectionAccessId}
                                                connectiontype={this.props.connectionType}
                                                connectionname={this.props.connectionName}
                                                scopeitems={[this.state.response]}
                                                connectionJson={this.connectionJson}
                                                search={this.props.search}
                                                joinremove={this.props.joinremove}
                                                editdatahubitem={this.props.editdatahubitem}
                                                
                                            />,document.getElementById('connections-tables-data-container')
                                        )
                                    }else{
                                        ReactDOM.render(
                                        <ConnectionItemsDataContainer
                                            connectionaccessid={this.props.connectionAccessId}
                                            connectiontype={this.props.connectionType}
                                            connectionname={this.props.connectionName}
                                            scopeitems={[this.state.response]}
                                            connectionJson={this.connectionJson}
                                            datahubaddcustomquery={this.props.datahubaddcustomquery}
                                            loadstoredprocedure={this.state.loadstoredprocedure}
                                            isCustomQuery={this.state.isCustomQuery}
                                            joinremove={this.props.joinremove}
                                            editdatahubitem={this.props.editdatahubitem}
                                            exisitingEntities={this.props.exisitingEntities}
                                            connectionaccessdata={this.props.connectionaccessdata}
                                            dataHubEntitiesCollection={this.state.dataHubEntitiesCollection}
                                            search={this.props.search}
                                            loadstoredproceduredetails={this.props.loadstoredproceduredetails}
                                        />,document.getElementById('connections-tables-data-container')
                                    )
                                }
                            },
                            rejectFunction:(error)=>{},
                            headers:{
                                method: "post",
                                headers: new Headers({
                                    "Content-Type": "application/json"
                                })
                            }
                        })
                    }else if(response.success===false||response.hasOwnProperty("error")){
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(lang["Datahub.unablefetch"]);
                        $('.details').addClass('show');
                        if(response.success===false||response.hasOwnProperty("error")){
                            $('.messagedetails xmp').empty().append(response.errorTrace);
                            }
                            else{
                                $('.messagedetails xmp').empty().append(response.error);
                            }
                        $("#datahubstepscontainer").steps("previous");
                        $('.spinner').hide();
                    }
                },
                rejectFunction: (error) => {
                    console.log("can't process /tables request"+error.message);
                },
                headers: {
                    method: "post",
                    headers: new Headers({
                        "Content-Type": "application/json"
                    })
                }
            })
        }
        this.props.onEntitiesLoad(false);
        var existingEntitiesMetaData = ChartInfo.functions.getExistingEntitiesMetaData();
			_.each(existingEntitiesMetaData,(val,index)=>{
				if(val.connAccessId ===this.props.connectionAccessId && val.entityType!="customqueryentity"){
					$(".filtercontent input[data-value='"+val.entityname+"'][data_parent='"+val.connAccessId+"']").parent().parent().draggable({disabled:true}).addClass("disabled");
					$(".filtercontent input[data-value='"+val.entityname+"'][data_parent='"+val.connAccessId+"']").prop('checked', true);
				}
			});
            $('.spinner').hide();
    }
    loadstoredproceduredetails=(element, connectiondata)=>{
        var $this = element;
        var connId = $($this).attr("data_parent");
        var storedProcName = $($this).attr("name");
        var existingStoredProcDetails;
        var storedProcs = sessionStorage.getItem("storedprocs");
        if(storedProcs!==undefined){
            var storedProcsArray =storedProcs.split(",").map(function(item) {
                  return item.trim();
            });
            if(storedProcsArray.indexOf(storedProcName)>=0) {
                existingStoredProcDetails = JSON.parse(sessionStorage.getItem(storedProcName));
            }
        }
        ReactDOM.render(
            <StoreProcedureParameters
                connId={connId}
                storedProcName={storedProcName}
                connectiondata={connectiondata}
                existingStoredProcDetails={existingStoredProcDetails}
                isSaved={this.state.isSaved}
            />,
            document.getElementById('settingscontainer')
        ); 
    }
    loadcustomquerymodal=(element,connectiondata)=>{
			var $this = element;
			var connId = $($this).attr("data_parent");
			var customqueryName = $($this).attr("name");
			var isSavedCustomQuery = $($this).attr("isSaved")!=undefined? $($this).attr("isSaved"):false
			var existingCustomqueryDetails;
			var customqueryNames = sessionStorage.getItem("customquerySessionObj");
			if(customqueryNames!=undefined && customqueryNames!=null){
				var customqueryNamesArray = customqueryNames.split(",").map(function(item) {
					return item.trim(); 
				});
				if(customqueryNamesArray.indexOf(customqueryName.trim()+"_CQ")>=0) {
					var customqueryObj = JSON.parse(sessionStorage.getItem(customqueryName.trim()+"_CQ"));
					connectiondata["value"] = customqueryObj.connAccessId;
					connectiondata["customqueryname"] = customqueryObj.entityName.replace("_CQ","")
					connectiondata["query"]	= customqueryObj.query
				}
            }
            ReactDOM.render(
                <DatahubCustomQuery
                    connId={connId}
                    entityName={this.state.entityName}
					customqueryName={customqueryName}
					connectiondata={connectiondata}
					dataHubEntitiesCollection={this.props.dataHubEntitiesCollection}
					existingCustomqueryDetails={existingCustomqueryDetails}
					isSavedCustomQuery={isSavedCustomQuery}
					element={$this}
                />,document.getElementById('settingscontainer')
            );
    }
    render(){
        return(
            <></>
        );
    }
}