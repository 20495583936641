import React from 'react';
import _ from 'underscore';
import SendRequest from '../SendRequest';
import { lang } from "../js/helpers/utils/languages/language";
export default class HamburgerDatamodelsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            models: [],
            features: []
        }
    }
    componentDidMount(){
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getavailabledatamodels",
            queryString: "",
            sucessFunction: (props) => {
                this.setState({ models: props });
            },
            rejectFunction: () => { },
            headers: requestHeaders,
        })
    }
    render() {
        return (
            <>
                {
                    (this.state.models.length > 0) ? (
                        <>
                            <h4 className="panelboc-title accordion-toggle" title={lang["user.header.models"]} id="" data-toggle="collapse" href="#hamdatamodeltab" data-parent="#dashboardtab" aria-expanded="false" aria-controls="hamdatamodeltab">
                                <div className="ripples">
                                    <span className="ripplesCircle">
                                    </span>
                                </div>
                                <i className="fa fa-cube fa-solid"></i>{lang["user.header.models"]}
                                <span className="badge pull-right marg-righ-15">{this.state.models.length}</span>
                            </h4>
                            <div id="hamdatamodeltab" className="panel-collapse panelboc-content collapse" aria-expanded="false">
	                            <div className="panelboc-body">	
		                            <div className="container-fluid marg-bot-10 marg-top-10">
			                            <div className="row">
				                            <input onKeyUp={this.props.search} className="form-control search-image searchinput" name="searchreports" placeholder={lang["user.header.search"]} type="text"/>
			                            </div>
		                            </div>
		                            <ul class="list-group scrollsetul">
                                        {
                                           this.state.models.map((datamodelitem, j)=> {
                                                var dmsaveStatus = 'ready';
                                                if(datamodelitem.DMStatus.toLowerCase()==='completed' || datamodelitem.DMStatus.toLowerCase()==='ready'){ dmsaveStatus = 'ready' }
                                                else if(datamodelitem.DMStatus.toLowerCase()==='inprogress'){ dmsaveStatus = 'processing' }
                                                else{ dmsaveStatus = 'error'}
                                                return (
                                                <li className="viewreport-list-item" title={`${datamodelitem.DMTitle+""+(datamodelitem.DMDescription!=''?datamodelitem.DMDescription:'')}`}>
					                                    <a className="navDashboard" href={`${this.props.url}/models/${btoa(datamodelitem.DMID)}`} data-num={datamodelitem.DMID} tab-id="0" tab-name={datamodelitem.DMTitle} className="boc-snapshot-thumbnail">
                                                           <div className="ripples">
                                                              <span className="ripplesCircle"></span>
                                                           </div>
                                                           <i className="fa fa-cube fa-solid"></i>
                                                         <span> {datamodelitem.DMTitle}</span>
                                                         <div className={`data-item-sync-status ${dmsaveStatus}`}>
                                                            <span className="data-item-sync-status-icon"></span>
                                                         </div>
                                                        </a>
				                                    </li>
                                                );
                                            })}
                                    </ul>
                                </div>
                            </div>
                        </>
                    ) : null
                }
            </>
        );
    }
}