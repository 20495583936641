import React from "react";
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import SendRequest from '../SendRequest';
import * as TabInfo from "../js/helpers/utils/tabinfo";
import ViewStorybookView from './viewstorybookview'
const $ = window.$;
export default class StrorybookMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        var that = this;
        this.state.isSharedStoryBook= false;
        if(window.location.pathname.indexOf("sharestorybook")!=-1){
          this.state.isSharedStoryBook = true;
        }
        $(".spinner").addClass('white').show();
        $("body").removeClass("datamainpage datahubpage dataworkspacepage datamodelspage datagovernancepage searchpage");
        $("body").addClass("storybookpage");
        $("#main").removeClass("newreportpagescrolling");
        sessionStorage.removeItem("convertedtypes");
        sessionStorage.removeItem("isconvettedtypesapplied");
        sessionStorage.removeItem("pos");
        sessionStorage.removeItem("renameMeasuresorDimensions");
        sessionStorage.setItem("changesdone", 1);
        TabInfo.functions.updateURL(this.props.url);

        var storyBookId = this.props.match.params.storyBookId;

        if (storyBookId != undefined && storyBookId != null && storyBookId != "") {
            storyBookId = atob(storyBookId);
        }
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getfeatures",
            queryString: "",
            sucessFunction: features => {
                $(".spinner").removeClass("white");
                that.state.allfeatures = features;
                ChartInfo.functions.setAllFeatures(features);
                sessionStorage.setItem("state", "viewstorybook");
                if (storyBookId != undefined && storyBookId != null && storyBookId != "" && storyBookId != 0) {
                    SendRequest({
                        url: "getStoryBookInfo",
                        queryString: "?storyBookId=" + storyBookId+"&isSharedStoryBook="+that.state.isSharedStoryBook,
                        sucessFunction: response => {
                            if (response.hasOwnProperty('error')) {
                                $(".spinner").hide();
                                $(".gridspinner").hide();
                                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                                $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                                $('.birdmessagebuttons').removeClass('show');
                                $('.birdmessagebuttons').addClass('hide');
                                $('.birdmessage-center').addClass("message-top-in");
                                $('.details').removeClass('hide');
                                $('.birdmessage h2').empty().append('Error : ');
                                $('.birdmessage h2').append('<span class="errorcode">');
                                $('.birdmessage .errorcode').empty().append(response.errorCode);
                                $('.birdmessage-info').empty().text(response.error);
                                $('.details').addClass('show');
                                $('.messagedetails xmp').empty().append(response.errorTrace)
                            } else {
                                that.viewstorybookview = new ViewStorybookView({
                                    url: that.props.url,
                                    features: that.state.allfeatures,
                                    storyBookId: storyBookId,
                                    savedcollection: response
                                });
                            }
                        }, rejectFunction: () => { },
                        headers: requestHeaders
                    });
                } else {
                    that.viewstorybookview = new ViewStorybookView({
                        features: that.state.allfeatures,
                        url: that.props.url

                    });
                }
            },
            rejectFunction: () => {$(".spinner").removeClass("white") },
            headers: requestHeaders
        });
        $(".spinner").removeClass("white")
        return (<div></div>)
    }
}
