import React from 'react';
import { lang } from "./js/helpers/utils/languages/language";
const $ = window.$;

export default class BusinessModelsDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  componentDidMount() {
    // Collapse accordion every time dropdown is shown
    $('.dropdown-accordion').on('show.bs.dropdown', function (event) {
      var accordion = $(this).find($(this).data('accordion'));
      accordion.find('.panel-collapse.in').collapse('hide');
    });

    // Prevent dropdown to be closed when we click on an accordion link
    $('.dropdown-accordion').on('click', 'a[data-toggle="collapse"]', function (event) {
      event.stopPropagation();
      event.preventDefault();
      $($(this).data('parent')).find('.panel-collapse.in').collapse('hide');
      $($(this).attr('href')).collapse('show');
    });
  }
  render() {
    return (
      //   <><button type="button" class="btn btn-transparent dropdown-toggle dropdown" title="More" data-toggle="dropdown" aria-expanded="true"><i class="fa fa-ellipsis-v"></i></button>
      //     <ul class="dropdown-menu dropdown-menu-right megadropdown" id={this.props.DMID}>
      //       <li><button class="btn btn-data-item-actions editdatamodel" id={this.props.DMID} title="Edit Model" role="button" onClick={() => { this.props.history.push(`/bird/welcome/models/${this.props.DMID}`) }}><i class="fa fa-pencil marg-righ-10"></i>Edit</button></li>
      //       <li>
      //         <button class="btn btn-data-item-actions accordion-toggle collapsed" href={`#newdataitem${this.props.DMID}`} title="Create" role="button" data-toggle="collapse" aria-expanded="false"><i class="fa fa-plus marg-righ-10"></i>Create</button>
      //         <ul class="collapse" aria-expanded="false" id={`newdataitem${this.props.DMID}`}>
      //           <li><button class="btn btn-data-item-actions datamodelcreatestoryboard" onClick={this.createStoryBoard} datamodel-id={this.props.DMID} is_shared={this.props.is_shared} title="Create Storyboard" role="button"><i class="fa fa-th-large marg-righ-10"></i>Storyboard</button></li>
      //           <li><button class="btn btn-data-item-actions datamodelcreatereport" datamodel-id={this.props.DMID} is_shared={this.props.is_shared} title="Create Report" role="button"><i class="fa fa-th-large whitebox marg-righ-10"></i>Report</button></li>
      //           <li><button class="btn btn-data-item-actions datamodelcreatesmartinsights" datamodel-id={this.props.DMID} is_shared={this.props.is_shared} title="Create Smart Insights" role="button"><i class="fa fa-smartinsights small-14 marg-righ-10"></i>Smart Insight</button></li>
      //           <li><button class="btn btn-data-item-actions datamodelcreatemlreport" datamodel-id={this.props.DMID} is_shared={this.props.is_shared} title="ML Report"><i class="fa fa-mlreport marg-righ-10"></i>ML Report</button></li>
      //         </ul>
      //       </li>
      //       <li><button class="btn btn-data-item-actions datamodeldelete" id={this.props.DMID} title="Delete Business Model" role="button"><i class="fa fa-trash-o marg-righ-10"></i>Delete</button></li>
      //     </ul></>
      <div className="dropdown dropdown-accordion" data-accordion="#accordion">
        <button type="button" className="btn btn-transparent dropdown-toggle dropdown" title={lang["models.more"]} data-toggle="dropdown" aria-expanded="true"><i className="fa fa-ellipsis-v"></i></button>
        {/* <a className="btn dropdown-toggle" href="#" data-toggle="dropdown">
          Accordion Dropdown<span className="glyphicon glyphicon-option-vertical"></span>
        </a> */}
        <ul className="dropdown-menu dropdown-menu-right megadropdown" role="menu" aria-labelledby="dLabel">
          {this.props.editpermit != "" ?
            <li><button className="btn btn-data-item-actions editdatamodel" id={this.props.DMID} title={lang["models.editmodel"]} role="button" onClick={() => { this.props.history.push(`/${window.localStorage.getItem("appName")}/welcome/models/${btoa(this.props.DMID)}`) }}><i className="fa fa-pencil marg-righ-10"></i>{lang["models.edit"]}</button></li>
            : null}
          <li>
            <div className="panel-group" id="accordion" style={{ marginBottom: "0px" }}>
              <div className="panel panel-default" style={{ border: "none" }}>
                <div className="panel-heading" style={{ padding: "5px 15px", backgroundColor: "#fff", backgroundImage: "none" }}>
                  {/* <h4 className="panel-title"> */}
                  {/* <button class="btn btn-data-item-actions accordion-toggle collapsed" href={`#newdataitem${this.props.DMID}`} title="Create" role="button" data-toggle="collapse" aria-expanded="false"><i class="fa fa-plus marg-righ-10"></i>Create</button> */}
                  <a className="accordion-toggle" href={`#newdataitem${this.props.DMID}`} data-toggle="collapse" data-parent="#accordion" style={{ textDecoration: "none" }}><i className="fa fa-plus marg-righ-10"></i>{lang["models.create"]}</a>
                  {/* </h4> */}
                </div>
                <div className="panel-collapse collapse" id={`newdataitem${this.props.DMID}`}>
                  <ul>
                    {this.props.modelautoviz?
                      <li><button className="btn btn-data-item-actions datamodelcreateautoviz" onClick={this.props.createAutoViz} datamodel-id={this.props.DMID} is_shared={this.props.is_shared.toString()} title={lang["models.autoviz"]} role="button" datamodel-title={this.props.DMTitle} ><i className="fa fa-magic marg-righ-10"></i>{lang["models.autoviz"]}</button></li>
                      :null}
                      {this.props.smartSearch?
                      <li><button className="btn btn-data-item-actions datamodelsmartsearch" onClick={this.props.navigatetosearch} datamodel-id={this.props.DMID} is_shared={this.props.is_shared.toString()} title={lang["search.intelligent"]} role="button" datamodel-title={this.props.DMTitle} ><i className="fa fa-search marg-righ-10"></i>{lang["search.intelligent"]}</button></li>
                      :null}
                    {this.props.modelstoryboard ?
                      <li><button className="btn btn-data-item-actions datamodelcreatestoryboard" onClick={this.props.createstoryboard} datamodel-id={this.props.DMID} is_shared={this.props.is_shared.toString()} title={lang["models.createstoryboard"]} role="button"><i className="fa fa-th-large marg-righ-10"></i>{lang["models.storyboard"]}</button></li>
                      : null}
                    {this.props.modelreport ?
                      <li><button className="btn btn-data-item-actions datamodelcreatereport" datamodel-id={this.props.DMID} is_shared={this.props.is_shared.toString()} title={lang["models.createreport"]} role="button" onClick={this.props.createreport}><i className="fa fa-th-large whitebox marg-righ-10"></i>{lang["models.report"]}</button></li>
                      : null}
                    {this.props.modelsmartinsights ?
                      <li><button className="btn btn-data-item-actions datamodelcreatesmartinsights" onClick={this.props.createsmartinsights} datamodel-id={this.props.DMID} datamodel-title={this.props.DMTitle} is_shared={this.props.is_shared.toString()} title={lang["models.createsmartinsight"]} role="button"><i className="fa fa-smartinsights small-14 marg-righ-10"></i>{lang["models.smartinsights"]}</button></li>
                      : null}
                    {this.props.modelmlreport ?
                      <li><button className="btn btn-data-item-actions datamodelcreatemlreport" onClick={this.props.createMLReport} datamodel-id={this.props.DMID} is_shared={this.props.is_shared.toString()} title={lang["models.mlreport"]}><i className="fa fa-mlreport marg-righ-10"></i>{lang["models.mlreport"]}</button></li>
                      : null}
                       {this.props.modelimporttemplate ?
                      <li><button className="btn btn-data-item-actions importtemplatefile" onClick={this.props.importtemplatefile} title={lang["models.importtemplate"]} id="newreportimportfile" datamodel-id={this.props.DMID} is_shared={this.props.is_shared.toString()}><i className="fa fa-share-square-o fa-rotate-90 marg-righ-10"></i>{lang["models.importtemplate"]}</button></li>
                      : null}
                  </ul>
                </div>
              </div>
            </div>
          </li>
          {this.props.deletecache?
          <li><button className="btn btn-data-item-actions datamodeldelete" id={this.props.DMID} title={lang["models.deletecache"]} role="button" onClick={() => { this.props.deletemodelcache(this.props.DMID, this.props.indexval) }}><i className="fa fa-trash-o marg-righ-10"></i>{lang["models.deletecache"]}</button></li>
          :null}
          {this.props.modeldelete ?
            <li><button className="btn btn-data-item-actions datamodeldelete" id={this.props.DMID} title="Delete Business Model" role="button" onClick={() => { this.props.deletemodel(this.props.DMID, this.props.indexval) }}><i className="fa fa-trash-o marg-righ-10"></i>{lang["models.delete"]}</button></li>
            : null}
        </ul>
      </div>
      // 
    );
  }
}