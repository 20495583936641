import React from "react";
import ReactDOM from "react-dom";
import _ from 'lodash';
import SendRequest from '../SendRequest';
import PubSub from 'pubsub-js'
import { getMessage } from '../js/helpers/utils/BirdMessage';
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import CustomFieldModalViewTemplate from "./customfieldmodaltemplate.jsx"
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;


var columnDisplayNameMap = {};
var columnDisplayTypeMap = {};
var columnDisplaynameArray = [];
var multifactentities = [];
var multifact = false;
export default class CustomFieldModalView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicdiv: props.dynamicdiv,
            customfields: [],
            funcArray: [],
            customFormulaFieldsArray: [],
            fieldsArray: [],
            parametrFieldsArray: [],
            expression: {},
            isDirect: 0,
            dimensions: props.dimensions,
            customparameters: props.customparameters,
            chartnumber: props.chartnumber
        };
        setTimeout(function () {
            $('.functiontypes').trigger('change');
        }, 50);
        $('#custommeasurequerydisplay').empty();
        this.updateColumnDisplayNameMap();
        this.render();
    }
    render() {
        var customparameters = ChartInfo.functions.getCustomParameter();
        this.state.isDirect = ChartInfo.functions.getIsDirect();
        ReactDOM.render(<CustomFieldModalViewTemplate createCustomField={this.createCustomField} testformula={this.testformula} selectfunctiontypes={this.selectfunctiontypes} closeCustomfieldswindow={this.closeCustomfieldswindow} dimensions={this.state.dimensions} customparameters={customparameters} isDirect={this.state.isDirect} ></CustomFieldModalViewTemplate>, document.querySelector(".custommeasurediv" + this.props.chartnumber));
        return (<div></div>);
    }
    closeCustomfieldswindow = (e) => {
        ReactDOM.unmountComponentAtNode(document.querySelector(".custommeasurediv" + this.props.chartnumber));
    }
    updateColumnDisplayNameMap() {
        columnDisplaynameArray = [];
        columnDisplayNameMap = {};
        _.each(this.state.dimensions, function (dimension) {
            columnDisplayNameMap[dimension.columnDisplayName] = dimension.columnName;
            columnDisplayTypeMap[dimension.columnDisplayName] = dimension.tableDisplayType;
            columnDisplaynameArray.push(dimension.columnDisplayName);
        });
        _.each(this.state.customparameters, function (fields) {
            columnDisplayNameMap[fields.parametername] = fields.parametername;
            columnDisplaynameArray.push(fields.parametername);
            columnDisplayTypeMap[fields.parametername] = "parameter"
        });
    }
    selectfunctiontypes = (e) => {
        var $this = e.currentTarget;
        $('.helpmenu').empty();
        var selectedval = $("option:selected", $this).attr("value");
        if (selectedval == "int") {
            $(".functiondivs").hide();
            $(".intigerdiv").show();
        }
        if (selectedval == "string") {
            $(".functiondivs").hide();
            $(".stringdiv").show();
        }
        if (selectedval == "date") {
            $(".functiondivs").hide();
            $(".datediv").show();
        }
        if (selectedval == "logical") {
            $(".functiondivs").hide();
            $(".logicaldiv").show();
        }
        if (selectedval == "raw") {
            $(".functiondivs").hide();
            $(".rawdiv").show();
            $(".helpmenu").text(lang["storyboard.rcmdadvanceusers"]);
        }
    }
    addData() {
        var that = this;
        $(".stringdiv").show();
        if (this.props.isEdit) {
            $("#custommeasurename").val(this.props.columnName);
            var customfields = sessionStorage.getItem("customFields");
            var parsedCustomFields = JSON.parse(customfields);
            var name = this.props.columnName;
            _.each(parsedCustomFields, function (customField) {
                if (customField.name == name) {
                    var fields = customField.htmltext;
                    that.state.fieldsArray = customField.fieldsArray;
                    that.state.funcArray = customField.funcArray;
                    var usedFields = customField.usedFields;
                    if (that.state.funcArray != undefined) {
                        fields = customField.fields
                        _.each(that.state.funcArray, function (func) {
                            var fullfunc = func; //.replace("\'\'","\\\\'\\\\'");
                            func = func.substring(0, func.indexOf('('));
                            fields = fields.replace(func + "(", ' <span class="customfieldaggrlist" data-word ="' + fullfunc + '" contenteditable=false>' + func + '(</span> ')
                        });
                    }
                    if (that.state.fieldsArray != undefined) {
                        _.each(that.state.fieldsArray, function (func) {
                            var columnName = columnDisplayNameMap[func];
                            fields = fields.replace("[" + func + "]", " [<span class='fa-orange customfieldlist' displayName = '" + func + "' columnName='" + columnName + "'>" + func + "</span>] ")
                        });
                    }
                    // for updating the renamed dimensions in the CUSTOM FIELD  formula editor
                    if (usedFields != undefined && usedFields.length > 0) {
                        _.each(usedFields, function (columnName) {
                            var columnDisplayName = _.invert(columnDisplayNameMap)[columnName];
                            if (columnDisplayName != undefined) {
                                $(".renamedfields").empty();
                                $(".renamedfields").append(fields);
                                _.each($('.customfieldlist'), function (field, i) {
                                    if ($(field).attr("columnName") == columnName) {
                                        var oldFunction = field.outerHTML;
                                        $(field).attr("displayName", columnDisplayName);
                                        $(field).text(columnDisplayName);
                                        var newFunction = field.outerHTML;
                                        fields = fields.replace("[" + oldFunction + "]", "[" + newFunction + "]");
                                    }
                                });
                                $(".renamedfields").empty();
                            }
                        });
                    }
                    if (customField.parametrFieldsArray != undefined) {
                        _.each(customField.parametrFieldsArray, function (func) {
                            fields = fields.replace("[" + func + "]", " [<span class='fa-orange customfieldparameterlist' data-word ='" + func + "'>" + func + "</span>] ")
                        });
                    }
                    that.state.parametrFieldsArray = customField.parametrFieldsArray;
                    $('.functiontypes option[value=' + customField.functiontype + ']').attr("selected", "selected");

                    $('#custommeasurequerydisplay').html(fields);
                }
            });
        }
    }
    textAreaAutoComplete() {
        var that = this;
        $(".stringdiv").show();
        var words = ['empty()', 'notEmpty()', 'length()', 'reverse()', 'concat()', 'substring(,,)', 'appendTrailingCharIfAbsent(,)', 'position(,)', 'match(,)', 'lower()', 'upper()', 'like(,)', 'notLike(,)', 'replaceAll(,,)', 'toString()'
            , 'e()', 'pi()', 'exp()', 'log()', 'exp2()', 'log2()', 'exp10()', 'log10()', 'sqrt()', 'cbrt()', 'erf()', 'erfc()', 'sin()', 'cos()', 'tan()', 'atan()', 'acos()', 'asin()',
            'pow(,)', 'abs()', 'round()', 'ceil()', 'floor()'
            , 'toDateTime()', 'toYear()', 'toMonth()', 'toDayOfMonth()', 'toDayOfWeek()', 'toHour()', 'toMinute()', 'toSecond()', 'toMonday()', 'toStartOfMonth()', 'toStartOfQuarter()', 'toStartOfYear()', 'toStartOfMinute()',
            'toStartOfFiveMinute()', 'toStartOfHour()', 'toStartOfDay()', 'toTime()', 'toRelativeYearNum()', 'toRelativeMonthNum()', 'toRelativeWeekNum()', 'toRelativeDayNum()',
            'toRelativeHourNum()', 'toRelativeMinuteNum()', 'toRelativeSecondNum()', 'today()', 'now()', 'yesterday()', 'if(,,)', 'case()',
            'toFloat64OrZero()', 'toFloat32OrZero()', 'toUInt64OrZero()', 'toUInt32OrZero()', 'toInt64OrZero()', 'toInt32OrZero()', 'toTypeName()', 'replaceOne(,,)'];

        var multifact = (that.props.multifact == 'true');
        var multifactentities = (that.props.multifact == 'true') ? JSON.parse(that.props.multifactentities) : that.props.multifactentities;
        var facttables = that.props.facttables;
        var displayNameMap = columnDisplayNameMap;
        $('#custommeasurequerydisplay').on("mousedown change", function () {
            $('.customfieldlist:empty').remove();
        });
        $('#custommeasurequerydisplay').textcomplete([
            {
                words: words,
                match: /\b(\w{1,}\.{0,1}\w{0,})$/,
                index: 1,
                search: function (term, callback) {
                    if (multifact) {
                        var allowedTableNames = [];
                        var previoustablename;
                        _.each($('.customfieldlist'), function (elemtnt) {
                            if ($(elemtnt).not(':empty').length > 0) {
                                var columnName = $(elemtnt).attr('columnname');
                                var tableName = columnName.split(".")[0];
                                previoustablename = tableName;
                                allowedTableNames = multifactentities[tableName];
                                allowedTableNames.push(tableName);
                            }
                        });

                        if (previoustablename != undefined && facttables.indexOf(previoustablename) != -1) {
                            _.each(facttables, function (ft) {
                                if (ft != previoustablename) {
                                    _.each(allowedTableNames, function (allowed) {
                                        var index = allowedTableNames.indexOf(ft);
                                        if (index > -1) {
                                            allowedTableNames.splice(index, 1);
                                        }
                                    });
                                }
                            });
                        }

                        if (allowedTableNames.length > 0) {
                            var allowedColumnNames = [];
                            _.each(columnDisplaynameArray, function (columnDisplayName) {
                                var actualName = displayNameMap[columnDisplayName];
                                var actualTableName = actualName.split(".")[0];

                                if (allowedTableNames.indexOf(actualTableName) >= 0) {
                                    allowedColumnNames.push(columnDisplayName);
                                }
                            });
                            var customparameters = ChartInfo.functions.getCustomParameter();
                            _.each(customparameters, function (cp, i) {
                                var cpname = customparameters[i].parametername;
                                allowedColumnNames.push(cpname)
                            })
                            this.words = words;
                            this.words = this.words.concat(allowedColumnNames.sort());
                        }
                        else {
                            this.words = this.words.concat(columnDisplaynameArray.sort());
                        }

                    }
                    else {
                        this.words = this.words.concat(columnDisplaynameArray.sort());
                    }
                    callback($.map(this.words.sort(), function (word) {
                        return word.toLowerCase().indexOf(term.toLowerCase()) >= 0 ? word : null;
                    }));
                },
                template: function (word) {
                    if (word.indexOf('()') < 0 && word.indexOf('(,)') < 0 && word.indexOf('(,,)') < 0 && word.indexOf('(){}') < 0 && word.indexOf('{}') < 0 && word.indexOf('(,"")') < 0 && word.indexOf('(,,"")') < 0 && word.indexOf('( in )') < 0) {
                        var type = columnDisplayTypeMap[word];
                        var result = '';
                        if (type == 'number' || type == 'parameter') {
                            result = "<span class='itemtype number'>123</span>  <span class='itemname' title='" + word + "'>" + word + "</span>";
                        }
                        else if (type == 'date') {
                            result = "<span class='itemtype date'><i class='fa fa-calendar'></i></span>  <span class='itemname' title='" + word + "'>" + word + "</span>";
                        }
                        else if (type == 'day' || type == 'month') {
                            result = "<span class='itemtype date'><i class='fa fa-calendar'></i></span>  <span class='itemname' title='" + word + "'>" + word + "</span>";
                        }
                        else {
                            result = "<span class='itemtype string'>"+lang["storyboard.abc"]+"</span>  <span class='itemname' title='" + word + "'>" + word + "</span>";
                        }
                        return result;
                    } else {
                        return "<span class='fa-orange marg-righ-5'><i>f</i> <small>(x)</small></span> " + word;
                    }
                },
                replace: function (word) {
                    var tempWord = word;
                    var type = columnDisplayTypeMap[word];
                    if (word.indexOf('()') < 0 && word.indexOf('(,)') < 0 && word.indexOf('(,,)') < 0 && word.indexOf('(){}') < 0 && word.indexOf('{}') < 0 && word.indexOf("(,'')") < 0 && word.indexOf("(,,'')") < 0 && word.indexOf('( in )') < 0) {
                        var columnName = columnDisplayNameMap[word];
                        tempWord = "[<span class='fa-orange customfieldlist' displayName = '" + word + "' columnName='" + columnName + "'>" + word + "</span>]"
                        if (type == 'parameter') {
                            that.state.parametrFieldsArray.push(word);
                            sessionStorage.setItem("parametrFields", JSON.stringify(that.state.parametrFieldsArray));
                            tempWord = "[<span class='fa-orange customfieldparameterlist' data-word ='" + word + "'>" + word + "</span>]";
                        }
                    }
                    else {
                        if (word == "case()") {
                            var dataword = "CASE WHEN ";
                            //var dataWithInbrackets = "[Condition] THEN  [Statement] ELSE [Statement] END";
                            var dataWithInbrackets = "[<span class='fa-blue'>Condition</span>] THEN  [<span class='fa-blue'>Statement</span>] ELSE [<span class='fa-blue'>Statement</span>] END";
                            tempWord = "<span class='customfieldaggrlist' data-word ='" + word + "' contenteditable=false>" + dataword + "</span>" + dataWithInbrackets + "<span></span>";
                            that.showhelp(this, word);
                        } else {
                            var dataword = word.substring(0, word.indexOf('('));
                            var dataWithInbrackets = word.substring(word.indexOf('(') + 1, word.indexOf(')'));
                            tempWord = "<span class='customfieldaggrlist' data-word ='" + word + "' contenteditable=false>" + dataword + "(</span>" + dataWithInbrackets + "<span>)</span>";
                            that.showhelp(this, word);
                        }
                    }
                    return tempWord;
                }
            }
        ], { maxCount: 50 });
    }
    showhelp(e, func) {
        var $this = e.currentTarget;
        var functionName = $($this).text().toLowerCase()
        var value = $($this).text().toLowerCase();
        if (func != undefined) {
            value = func.split('(')[0].toLowerCase();
        }
        if (value === 'dateformat') {
            $('.helpmenu').empty();
            $(".dateformatdiv").show();
            $('.function').removeClass('active');
        }
        else {
            var txtToAdd = value + '(field_name)(ex: ' + value + '([rental_rate])';
            if (value == "concat") {
                txtToAdd = value + '(field_name, field_name,...)(ex:concat([title],[name]) NOTE: For non-string fields use toString(), ex:concat(toString(field_name), ...))'
            } else if (value == "empty") {
                txtToAdd = value + '(field_name)(ex:empty([name]))'
            } else if (value == "notempty") {
                txtToAdd = value + '(field_name)(ex:notEmpty([name]))'
            } else if (value == "length") {
                txtToAdd = value + '(field_name)(ex:length([name]))'
            } else if (value == "reverse") {
                txtToAdd = value + '(field_name)(ex:reverse([name]))'
            } else if (value == "substring") {
                txtToAdd = value + '(field_name, int start, int end)(ex:substring([name],1,7))'
            } else if (value == "appendtrailingcharifabsent") {
                txtToAdd = value + "(field_name, 'ch')(ex:appendTrailingCharIfAbsent([name],'C'))"
            } else if (value == "position") {
                txtToAdd = value + "(field_name, 'string/ch')(ex:position([name],'DON'))"
            } else if (value == "match") {
                txtToAdd = value + "(field_name, 'string/ch')(ex:match([name],'DON'))"
            } else if (value == "lower") {
                txtToAdd = value + "(field_name)(ex:lower([name]))"
            } else if (value == "upper") {
                txtToAdd = value + "(field_name)(ex:upper([name]))"
            } else if (value == "like") {
                txtToAdd = value + "(field_name, 'string/ch')(ex:like([name],'DON'))"
            } else if (value == "notlike") {
                txtToAdd = value + "(field_name, 'string/ch')(ex:notLike([name],'DON'))"
            } else if (value == "replaceall") {
                txtToAdd = value + "(field_name, 'string/ch','Replacement')(ex:replaceAll([name],'DON','HERO'))"
            } else if (value == "replaceone") {
                txtToAdd = value + "(field_name, 'string/ch','Replacement')(ex:replaceOne([name],'DON','HERO'))"
            } else if (value == "e") {
                txtToAdd = value + "()(ex:e())"
            } else if (value == "pi") {
                txtToAdd = value + "()(ex:pi())"
            } else if (value == "exp") {
                txtToAdd = value + "(field_name)(ex:exp([rental_rate]))"
            } else if (value == "log") {
                txtToAdd = value + "(field_name)(ex:log([rental_rate]))"
            } else if (value == "exp2") {
                txtToAdd = value + "(field_name)(ex:exp2([rental_rate]))"
            } else if (value == "log2") {
                txtToAdd = value + "(field_name)(ex:log2([rental_rate]))"
            } else if (value == "exp10") {
                txtToAdd = value + "(field_name)(ex:exp10([rental_rate]))"
            } else if (value == "log10") {
                txtToAdd = value + "(field_name)(ex:log10([rental_rate]))"
            } else if (value == "sqrt") {
                txtToAdd = value + "(field_name)(ex:sqrt([rental_rate]))"
            } else if (value == "cbrt") {
                txtToAdd = value + "(field_name)(ex:cbrt([rental_rate]))"
            } else if (value == "erf") {
                txtToAdd = value + "(field_name)(ex:erf([rental_rate]))"
            } else if (value == "erfc") {
                txtToAdd = value + "(field_name)(ex:erfc([rental_rate]))"
            } else if (value == "sin") {
                txtToAdd = value + "(field_name)(ex:sin([rental_rate]))"
            } else if (value == "cos") {
                txtToAdd = value + "(field_name)(ex:cos([rental_rate]))"
            } else if (value == "tan") {
                txtToAdd = value + "(field_name)(ex:tan([rental_rate]))"
            } else if (value == "asin") {
                txtToAdd = value + "(field_name)(ex:asin([rental_rate]))"
            } else if (value == "acos") {
                txtToAdd = value + "(field_name)(ex:acos([rental_rate]))"
            } else if (value == "atan") {
                txtToAdd = value + "(field_name)(ex:atan([rental_rate]))"
            } else if (value == 'pow') {
                txtToAdd = value + '(field_name, Number)(ex: pow([rental_rate] ,2))';
            } else if (value == "abs") {
                txtToAdd = value + "(field_name)(ex:abs([rental_rate]))"
            } else if (value == "round") {
                txtToAdd = value + "(field_name)(ex:round([rental_rate]))"
            } else if (value == "ceil") {
                txtToAdd = value + "(field_name)(ex:ceil([rental_rate]))"
            } else if (value == "floor") {
                txtToAdd = value + "(field_name)(ex:floor([rental_rate]))"
            } else if (value == "todatetime") {
                txtToAdd = value + "(field_name)(ex:toDateTime([payment_date]))"
            } else if (value == "toyear") {
                txtToAdd = value + "(field_name)(ex:toYear([payment_date]))"
            } else if (value == "tomonth") {
                txtToAdd = value + "(field_name)(ex:toMonth([payment_date]))"
            } else if (value == "todayofmonth") {
                txtToAdd = value + "(field_name)(ex:toDayOfMonth([payment_date]))"
            } else if (value == "todayofweek") {
                txtToAdd = value + "(field_name)(ex:toDayOfWeek([payment_date]))"
            } else if (value == "tohour") {
                txtToAdd = value + "(field_name)(ex:toHour([payment_date]))"
            } else if (value == "tominute") {
                txtToAdd = value + "(field_name)(ex:toMinute([payment_date]))"
            } else if (value == "tosecond") {
                txtToAdd = value + "(field_name)(ex:toSecond([payment_date]))"
            } else if (value == "tomonday") {
                txtToAdd = value + "(field_name)(ex:toMonday([payment_date]))"
            } else if (value == "tostartofmonth") {
                txtToAdd = value + "(field_name)(ex:toStartOfMonth([payment_date]))"
            } else if (value == "tostartofquarter") {
                txtToAdd = value + "(field_name)(ex:toStartOfQuarter([payment_date]))"
            } else if (value == "tostartofyear") {
                txtToAdd = value + "(field_name)(ex:toStartOfYear([payment_date]))"
            } else if (value == "tostartofminute") {
                txtToAdd = value + "(field_name)(ex:toStartOfMinute([payment_date]))"
            } else if (value == "tostartoffiveminute") {
                txtToAdd = value + "(field_name)(ex:toStartOfFiveMinute([payment_date]))"
            } else if (value == "tostartofhour") {
                txtToAdd = value + "(field_name)(ex:toStartOfHour([payment_date]))"
            } else if (value == "tostartofday") {
                txtToAdd = value + "(field_name)(ex:toStartOfDay([payment_date]))"
            } else if (value == "totime") {
                txtToAdd = value + "(field_name)(ex:toTime([payment_date]))"
            } else if (value == "torelativeyearnum") {
                txtToAdd = value + "(field_name)(ex:toRelativeYearNum([payment_date]))"
            } else if (value == "torelativemonthnum") {
                txtToAdd = value + "(field_name)(ex:toRelativeMonthNum([payment_date]))"
            } else if (value == "torelativeweeknum") {
                txtToAdd = value + "(field_name)(ex:toRelativeWeekNum([payment_date]))"
            } else if (value == "torelativedaynum") {
                txtToAdd = value + "(field_name)(ex:toRelativeDayNum([payment_date]))"
            } else if (value == "torelativehournum") {
                txtToAdd = value + "(field_name)(ex:toRelativeHourNum([payment_date]))"
            } else if (value == "torelativeminutenum") {
                txtToAdd = value + "(field_name)(ex:toRelativeMinuteNum([payment_date]))"
            } else if (value == "torelativesecondnum") {
                txtToAdd = value + "(field_name)(ex:toRelativeSecondNum([payment_date]))"
            } else if (value == 'today') {
                txtToAdd = value + "()" +
                    "  Example:Return current Date";
            } else if (value == 'now') {
                txtToAdd = value + "()" +
                    "  Example:Return current Date and Time";
            } else if (value == 'yesterday') {
                txtToAdd = value + "()" +
                    "  Example:Return yesterday Date";
            } else if (value == "tostring") {
                txtToAdd = value + "(field_name)(ex:timeSlot([film_id]))"
            } else if (value == 'if') {
                txtToAdd = "If" + "(Boolean_expression," +
                    "Executes when the Boolean expression is true" +
                    ",Executes when the Boolean expression is false);  ex:(film_id>45,'yes','no')"
            }
            else if (value == 'case') {
                txtToAdd = value.toUpperCase() + ' WHEN condition THEN statement ELSE default_value END (ex:CASE WHEN [film_id] < 45 THEN 0 ELSE 1 END )';
            }

            $(".helpmenu").text(txtToAdd);
            $('.function').removeClass('active');
            $(".dateformatdiv").hide();
            $($this).addClass('active');
        }
    }
    testformula = (e) => {
        var that = this;
        var textareaData = $("#custommeasurequerydisplay").text();
        var formula = this.validateFormula()[0];
        var scriptcondition = this.validateFormula()[1];
        var sqlFormula = this.validateFormula()[2];
        var customparameters = this.state.customparameters;
        var temp;
        this.state.isImportedReport = false;
        if (sessionStorage.getItem("isImportedReport") || window.location.pathname.indexOf("importreport") >= 0) {
            this.state.isImportedReport = true;
        }
        _.each($('.customfieldparameterlist'), function (value, i) {
            value = $(value).attr("data-word");
            temp = $.grep(customparameters, function (e) {
                return e.parametername == value;
            })
            if (temp.length != 0) {
                formula = formula.replace("[" + value + "]", temp[0].parametercurrentvalue);
                sqlFormula = sqlFormula.replace("[" + value + "]", temp[0].parametercurrentvalue);
            }
        });
        if (formula != "") {
            var that = this;
            var requestbody = new FormData();
            requestbody.set("reportId", that.props.reportId);
            requestbody.set("script", formula);
            requestbody.set("entities", that.props.entities);
            requestbody.set("datamodelId", that.props.datamodelId);
            requestbody.set("customType", "customfield");
            requestbody.set("isImportedReport", that.state.isImportedReport);
            requestbody.set("sqlformula", sqlFormula);
            requestbody.set("customFormulaFieldsArray", JSON.stringify(that.state.customFormulaFieldsArray));

            let requestHeaders = {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            SendRequest({
                url: "testcustomformula",
                body: requestbody,
                sucessFunction: (response) => {
                    if (response.hasOwnProperty('error')) {
                        $(".spinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.errorTrace);
                        setTimeout(function () {
                            $('.birderror').remove();
                        }, 3000);
                    } else if (response.valid) {
                        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Success');
                        $('.birdmessage-info').empty().text(getMessage('BirdSuccess32'));
                        setTimeout(function () {
                            $('.birdsuccess').remove();
                        }, 3000);
                        var fieldName = $("#custommeasurename").val().trim();
                        var htmlTextAreaData = $("#custommeasurequerydisplay").html();
                        var textareaData = $("#custommeasurequerydisplay").text();
                        // if (Backbone.history.getFragment().indexOf("viewmodel") >= 0) {
                        //     that.plotDistributionChart(that.props.reportId, fieldName, response.datatype, formula, htmlTextAreaData, textareaData, sqlFormula, scriptcondition, that.state.parametrFieldsArray, that.state.customFormulaFieldsArray);
                        // }
                    } else {
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Warning');
                        $('.birdmessage-info').empty().text(getMessage('BirdWarning30'));
                        setTimeout(function () {
                            $('.birderror').remove();
                        }, 3000);
                    }
                },
                rejectFunction: this.reportDataError,
                headers: requestHeaders
            });
        }
        else {
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(getMessage('BirdWarning30'));
        }
    }
    validateFormula() {
        var textareaData = $("#custommeasurequerydisplay").text();
        textareaData = encodeURIComponent(textareaData);
        textareaData = textareaData.replace(/%C2%A0/g, ' ');
        textareaData = decodeURIComponent(textareaData);
        this.state.customFormulaFieldsArray = [];
        var that = this;
        var tempExp = "";
        var sqlExp = textareaData;
        var expressionObject = {};
        if ($('.functiontypes').val() != "raw") {
            _.each($('.customfieldlist'), function (field, i) {
                var bool = false;
                var fieldDisplayName = $(field).attr("displayName");
                field = $(field).attr("columnName");

                if (!bool) {
                    var type = columnDisplayTypeMap[field];
                    if (type != 'parameter') {
                        tempExp += "def val" + i + " = doc['" + columnDisplayNameMap[fieldDisplayName] + "'].value; ";
                        if (type == 'number') {
                            tempExp += "if(val" + i + " == null ) { val" + i + " = 0};";
                        }
                        else {
                            tempExp += "if(val" + i + " == null ) { val" + i + " = ''};";
                        }
                        expressionObject[fieldDisplayName + '_' + i] = "val" + i;
                    }
                    var columnName = columnDisplayNameMap[fieldDisplayName];
                    that.state.customFormulaFieldsArray.push(columnName);
                    var startEndBrackets = that.getStartEndBrackets();
                    columnName = startEndBrackets["leftBracket"] + columnName + startEndBrackets["rightBracket"];
                    sqlExp = sqlExp.replace('[' + fieldDisplayName + ']', columnName + "");
                }
            });
            var tempExpObject = expressionObject;
            _.each(tempExpObject, function (val, field) {
                field = field.substring(0, field.lastIndexOf('_'));

                textareaData = textareaData.replace('[' + field + ']', val + "");
            });
            var foundList = [];
            _.each($('.customfieldaggrlist'), function (list) {
                list = $(list).attr("data-word");
            });
            var scriptCondition = textareaData.replace(/%C2%A0/g, '');
            if (textareaData.indexOf("/") !== -1) {
                textareaData = "try{Double.valueOf(" + textareaData + ")}catch(Exception e){" + textareaData + "}";
            }
            return [tempExp + textareaData, scriptCondition, sqlExp];
        } else {
            var textareaData = encodeURIComponent($("#custommeasurequerydisplay").text());
            textareaData = textareaData.replace(/%C2%A0/g, ' ');
            textareaData = decodeURIComponent(textareaData);
            //textareaData = textareaData.replace(/ /g,'');
            _.each($('.customfieldlist'), function (field, i) {
                field = $(field).attr("displayName");
                textareaData = textareaData.replace(' [' + field + '] ', columnDisplayNameMap[field]);
            });
            return [textareaData, textareaData, ""]
        }
    }

    createCustomField = (e) => {
        e.stopPropagation()
        var that = this;
        this.state.isImportedReport = false;
        if (sessionStorage.getItem("isImportedReport") || window.location.pathname.indexOf("importreport") >= 0) {
            this.state.isImportedReport = true;
        }
        var textareaData = $("#custommeasurequerydisplay").text();
        var columnName = $("#custommeasurename").val().trim();
        var status = false;
       // var reservedwordCheck = columnName.toLowerCase();
        var reservedwordCheck =$("#custommeasurename").val().trim().replace(/([#;?&,%•. +*~\':"!^$%[\]()<=>|\/@])/g, "");
        reservedwordCheck = reservedwordCheck.toLowerCase();
      //  var reservedwordslist = JSON.parse(sessionStorage.getItem("reservedwordslist"));
        // _.each(reservedwordslist, function (reservedword, j) {
        //     if (reservedwordCheck == reservedword) {
        //         status = true
        //     }
        // });
        // if (status == true) {
        //     $(".spinner").hide();
        //     $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
        //     $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
        //     $('.birdmessagebuttons').removeClass('show');
        //     $('.birdmessagebuttons').addClass('hide');
        //     $('.birdmessage-center').addClass("message-top-in");
        //     $('.details').removeClass('show');
        //     $('.details').addClass('hide');
        //     $('.birdmessage h2').empty().append('Warning');
        //     $('.birdmessage-info').empty().text("Custom Field " + "" + getMessage('BirdWarning147'));
        //     $(".spinner").hide();
        //     //$('.applyattributes').addClass("disabled");
        //     return;
        // }
        var sqlFormula = this.validateFormula()[2];
        var formula = "";
        var resultArray = this.validateFormula();
        formula = resultArray[0];
        var customparameters = this.state.customparameters;
        var temp;
        _.each($('.customfieldparameterlist'), function (value, i) {
            value = $(value).attr("data-word");
            temp = $.grep(customparameters, function (e) {
                return e.parametername == value;
            })
            if (temp.length != 0) {
                formula = formula.replace("[" + value + "]", temp[0].parametercurrentvalue);
                sqlFormula = sqlFormula.replace("[" + value + "]", temp[0].parametercurrentvalue);
            }
        });
        if (formula != "") {
            var that = this;
            var requestbody = new FormData();
            requestbody.set("reportId", that.props.reportId);
            requestbody.set("script", formula);
            requestbody.set("entities", that.props.entities);
            requestbody.set("datamodelId", that.props.datamodelId);
            requestbody.set("customType", "customfield");
            requestbody.set("isImportedReport", that.state.isImportedReport);
            requestbody.set("sqlformula", sqlFormula);
            requestbody.set("customFormulaFieldsArray", JSON.stringify(that.state.customFormulaFieldsArray));
            requestbody.set("fieldName", reservedwordCheck);
            let requestHeaders = {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            SendRequest({
                url: "testcustomformula",
                body: requestbody,
                sucessFunction: (response) => {
                    if (response.hasOwnProperty('error')) {
                        $(".spinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.errorTrace)
                        setTimeout(function () {
                            $('.birderror').remove();
                        }, 3000);
                    } else if (response.valid=="isreservedword") {
                        $(".spinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Warning');
                        $('.birdmessage-info').empty().text(lang["storyboard.customfield"] + "" + getMessage('BirdWarning147'));
                        $(".spinner").hide();
                        return;
                    }
                     else if (response.valid) {
                        var fieldName = $("#custommeasurename").val().trim();
                        var textareaData = $("#custommeasurequerydisplay").text();
                        var htmlTextAreaData = $("#custommeasurequerydisplay").html();
                        if (fieldName != undefined && fieldName.length <= 0) {
                            $(".spinner").hide();
                            $(".gridspinner").hide();
                            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
                            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
                            $('.birdmessagebuttons').removeClass('show');
                            $('.birdmessagebuttons').addClass('hide');
                            $('.birdmessage-center').addClass("message-top-in");
                            $('.details').removeClass('show');
                            $('.details').addClass('hide');
                            $('.birdmessage h2').empty().append('Information');
                            $('.birdmessage-info').empty().text(getMessage('BirdInformation22'));
                            return;
                        }
                        if ((/[!@#$%^&*()+\=\[\]{};':\\|,.<>\/?`"]/.test(fieldName)) || (fieldName.toLowerCase().trim() == "min" || fieldName.toLowerCase().trim() == "max"
                            || fieldName.toLowerCase().trim() == "avg" || fieldName.toLowerCase().trim() == "sum"
                            || fieldName.toLowerCase().trim() == "count" || fieldName.toLowerCase().trim() == "avgerage")) {
                            $(".spinner").hide();
                            $(".gridspinner").hide();
                            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
                            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
                            $('.birdmessagebuttons').removeClass('show');
                            $('.birdmessagebuttons').addClass('hide');
                            $('.birdmessage-center').addClass("message-top-in");
                            $('.details').removeClass('show');
                            $('.details').addClass('hide');
                            $('.birdmessage h2').empty().append('Information');
                            $('.birdmessage-info').empty().text(getMessage('BirdInformation46'));
                            return;

                        }
                        if (that.props.columnName != $('#custommeasurename').val()) {
                            if (!$('#custommeasurename').val().trim().length > 0 || $('.attributesdimensions[title="' + fieldName + '"]').length > 0 || $('.customaggregatedfields[title="' + fieldName + '"]').length > 0
                                || $('.customrangefields[title="' + fieldName + '"]').length > 0 || $('.customhierarchyfields[title="' + fieldName + '"]').length > 0 || $('.customparameterfield[title="' + fieldName + '"]').length > 0
                                || $('.custommeasurehierarchyfields[title="' + fieldName + '"]').length > 0 ||
                                $('.mlallcolumnscontainer select[name="ml_duallistbox"] option[columnname="' + fieldName + '"]').length > 0) {
                                $('.customaggregatedfieldname-error').removeClass('hide');
                                return;
                            }
                        }
                        if (textareaData != undefined && textareaData.length <= 0) {
                            alert("Formula cannot be empty");
                            return;
                        }
                        if (ChartInfo.functions.isScriptData(fieldName)) {
                            return true;
                        }
                        var customFields = sessionStorage.getItem("customFields");
                        var tempDimensionArray = [];
                        $('.dimensions .attributesdimensions').each(function (i, list) {
                            tempDimensionArray.push($(list).find('.itemname').text())
                        });
                        var customObject = [];
                        var scriptFilterArray = [];
                        if (customFields != undefined && customFields != null) {
                            var tempcustomObject = JSON.parse(customFields);
                            _.each(tempcustomObject, function (customfield) {
                                tempDimensionArray.push(customfield.name)
                                if (customfield.name != that.props.columnName) {
                                    customObject.push(customfield);
                                }
                            });
                        }
                        if (tempDimensionArray.indexOf(fieldName) == -1 || that.props.isEdit) {
                            $('.custommeasurename-error').addClass('hide');
                        } else {
                            $('.custommeasurename-error').removeClass('hide');
                            return;
                        }
                        var formula = "";
                        var scriptFormula = "";
                        var scriptcondition = "";
                        var sqlformula = "";
                        var resultArray = that.validateFormula();
                        formula = resultArray[0]
                        scriptcondition = resultArray[1];
                        sqlformula = resultArray[2];
                        _.each($('.customfieldparameterlist'), function (value, i) {
                            value = $(value).attr("data-word");
                            temp = $.grep(customparameters, function (e) {
                                return e.parametername == value;
                            })
                            if (temp.length != 0) {
                                formula = formula.replace("[" + value + "]", temp[0].parametercurrentvalue);
                                sqlFormula = sqlFormula.replace("[" + value + "]", temp[0].parametercurrentvalue);
                            }
                        });
                        var datatype = (response.datatype == undefined) ? "String" : response.datatype;
                        var priorityColumn = false;
                        if (customFields != undefined && customFields != null && that.props.isEdit) {
                            var tempcustomObject = JSON.parse(customFields);
                            _.each(tempcustomObject, function (customfield) {
                                tempDimensionArray.push(customfield.name)
                                if (customfield.name == that.props.columnName && customfield.priorityColumn != undefined) {
                                    priorityColumn = customfield.priorityColumn;
                                }
                                if (customfield.name == that.props.columnName && customfield.formula == that.state.formulaTemp) {
                                    datatype = (customfield.dataType == undefined) ? "String" : customfield.dataType;
                                }
                            });
                            // for updating the renamed custom fields in the custom measures (if custom measures contains the custom fields)
                            var customMeasures = ChartInfo.functions.getCustomMeasureField();
                            var isCustomFieldsInCustomMeasurs = false;
                            _.each(customMeasures, function (result) {
                                if (result != undefined) {
                                    var htmlScript = result.htmlText;
                                    var usedFields = result.usedFields.split(",");
                                    var functionMapping = [];
                                    var sqlformula = result.sqlformula;
                                    if (usedFields != undefined && usedFields.length > 0 && usedFields.includes(that.props.columnName)) {
                                        var columnsUsedInMeasure = "";
                                        _.each(usedFields, function (columnName) {
                                            var oldColumnName = that.props.columnName;
                                            if (oldColumnName != undefined && fieldName != undefined && oldColumnName == columnName) {
                                                isCustomFieldsInCustomMeasurs = true;;
                                                $(".renamedfields").empty();
                                                $(".renamedfields").append(htmlScript);
                                                _.each($('.custommeasurelist'), function (field, i) {
                                                    if ($(field).attr("data-measure") == oldColumnName) {
                                                        var oldFunction = field.outerHTML;
                                                        $(field).attr("displayName", fieldName);
                                                        $(field).text(fieldName);
                                                        $(field).attr("data-measure", fieldName);
                                                        var newFunction = field.outerHTML;
                                                        columnsUsedInMeasure += fieldName + ",";
                                                        htmlScript = htmlScript.replace("[" + oldFunction + "]", "[" + newFunction + "]");
                                                        result.htmlText = htmlScript;
                                                        result.sqlformula = sqlformula.replace(oldColumnName, fieldName + "");
                                                    }
                                                });
                                                $(result.functionMapping).each(function (index, jsonObj) {
                                                    if (jsonObj["columnName"] == oldColumnName) {
                                                        jsonObj["columnName"] = fieldName;
                                                    }
                                                });
                                                $(".renamedfields").empty();
                                            } else {
                                                columnsUsedInMeasure += columnName + ",";
                                            }
                                        });
                                        result.usedFields = columnsUsedInMeasure.substring(0, columnsUsedInMeasure.length - 1);
                                    }
                                }
                            });
                            if (isCustomFieldsInCustomMeasurs) {
                                ChartInfo.functions.setCustomMeasureField(customMeasures);
                            }
                            var customRanges = JSON.parse(JSON.stringify(ChartInfo.functions.getRange()));
                            _.each(customRanges, function (customRange, i) {
                                var rangeOldColumnName = that.props.columnName
                                if (customRange != undefined && rangeOldColumnName != undefined && fieldName != undefined && customRange.xAxisName[0].columnName == rangeOldColumnName) {
                                    customRange.xAxisName[0].columnName = fieldName;
                                }
                            })
                            ChartInfo.functions.setRange(customRanges);
                        }
                        var parametrFieldsArray = [];
                        if(sessionStorage.getItem("parametrFields") != undefined && that.state.parametrFieldsArray.length == 0){
                        parametrFieldsArray = JSON.parse(sessionStorage.getItem("parametrFields"));
                        }else{
                        parametrFieldsArray = that.state.parametrFieldsArray;
                        }
                        var tempCustomObject = {
                            "name": fieldName, "dataType": datatype, "type": "dimension", "formula": formula, "htmltext": htmlTextAreaData, "fields": textareaData, "sqlformula": sqlformula, "scriptcondition": scriptcondition,
                            "priorityColumn": priorityColumn, "functiontype": $('.functiontypes').val(), "parametrFieldsArray": parametrFieldsArray, "usedFields": that.state.customFormulaFieldsArray
                        };
                        if (formula.length >= 0) {
                            customObject.push(tempCustomObject);
                        }
                        sessionStorage.setItem("customFields", JSON.stringify(customObject));
                        sessionStorage.removeItem("parametrFields");
                        //sessionStorage.setItem("customfieldentities",  JSON.stringify(that.state.customfields))
                        $(".custommeasurediv" + that.props.chartnumber + " .createcustommeasure").modal("hide");
                        if (that.props.isEdit) {
                            var oldName = that.props.columnName;
                            var convertedvalues = sessionStorage.getItem("convertedvalues");
                            var convertedObject = JSON.parse(convertedvalues);
                            var tempObject = sessionStorage.getItem("renameMeasuresorDimensions") == null ? {} : JSON.parse(sessionStorage.getItem("renameMeasuresorDimensions"));
                            var values = [];
                            _.each(convertedObject, function (convertObj) {
                                if (convertObj.columnName == oldName) {
                                    $('.attributesmeasures').each(function (i, list) {
                                        if (oldName == $(list).find('.itemname').text()) {
                                            $(list).attr('title', fieldName);
                                            $(list).attr('data-name', fieldName);
                                            $(list).attr('id', fieldName);
                                            $(list).find('.itemname').text(fieldName);
                                        }
                                    });
                                    convertObj.columnName = fieldName;
                                }
                                values.push(convertObj);

                            });

                            _.each($(".yaxisdropdownlist"), function (yaxisAttr) {
                                if (oldName == $(yaxisAttr).attr('data-name')) {
                                    $(yaxisAttr).attr('data-name', fieldName);
                                    $(yaxisAttr).attr('id', fieldName);
                                    $(yaxisAttr).attr('title', fieldName);
                                    $(yaxisAttr).find(".itemname").text(fieldName);
                                    $(yaxisAttr).find(".itemname").attr('title', fieldName);
                                    ChartInfo.functions.updateAxisName(oldName, fieldName, 'yAxis');
                                }
                            });
                            _.each($("#legenditem"), function (legendItem) {
                                if (oldName == $(legendItem).attr('data-name')) {
                                    $(legendItem).attr('data-name', fieldName);
                                    $(legendItem).attr('id', fieldName);
                                    $(legendItem).attr('title', fieldName);
                                    $(legendItem).find(".itemname").text(fieldName);
                                    $(legendItem).find(".itemname").attr('title', fieldName);
                                }
                            });

                            _.each($(".rankingitem option"), function (rankingItem) {
                                if (oldName == $(rankingItem).attr('data-name')) {
                                    $(rankingItem).attr('data-name', fieldName);
                                    $(rankingItem).attr('id', fieldName);
                                    $(rankingItem).attr('title', fieldName);
                                    var agg = $(rankingItem).attr('data-agg');
                                    $(rankingItem).text(fieldName + "(" + agg + ")");
                                }
                            });
                            sessionStorage.setItem("convertedvalues", JSON.stringify(values));
                            $(".attributescustomfields[data-name='" + oldName + "']").remove();
                            var renameXaxisName = $('.xaxisattributes li[data-name="' + oldName + '"]');
                            $(renameXaxisName).attr('data-name', fieldName);
                            $(renameXaxisName).attr('id', fieldName);
                            $(renameXaxisName).attr('title', fieldName);
                            $(renameXaxisName).find('.itemname').text(fieldName);
                            $(".combinationchartslistattributes").find(".singleliitems[data-name='" + oldName + "']").find("span.itemname").text(fieldName);
                            $(".combinationchartslistattributes").find(".singleliitems[data-name='" + oldName + "']").attr("title", fieldName);
                            $(".combinationchartslistattributes").find(".singleliitems[data-name='" + oldName + "']").attr("data-name", fieldName);
                            $(".metricdatefieldsAttributes").find("span.itemname").text(fieldName);
                            if (oldName != undefined && oldName != fieldName) {
                                $('#callcustommeasures [data-edit="edit"], [data-name="' + oldName + '"]').attr("data-name", fieldName);
                            }
                            tempObject[oldName] = fieldName;
                            var newtempObject = {};
                            //for removing old renamed fields which is having same name 
                            if (tempObject != null && tempObject != undefined) {
                                _.each(tempObject, function (value, key) {
                                    if (key != undefined && key != fieldName) {
                                        newtempObject[key] = value;
                                    }
                                });
                            }
                            sessionStorage.setItem("renameMeasuresorDimensions", JSON.stringify(newtempObject))
                            ChartInfo.functions.updateAxisName(oldName, fieldName, 'xAxis');
                            /*	if(that.props.isEdit && that.props.multifact=="true"){
                                        $("ul.xaxisattributes").empty();
                                }*/
                            var customFields = sessionStorage.getItem("customFields");
                            var customFeildsArray = JSON.parse(customFields);
                            if (customFields != undefined && customFields.length > 0) {
                                _.each(customFeildsArray, function (i, j) {
                                    _.each($(".yaxisattributes .singleliitems"), function (yaxis) {
                                        if (that.props.isEdit && that.props.multifact == "true" && $(yaxis).attr("data-name") == i.name) {
                                            $("ul.yaxisattributes").empty();
                                            $("ul.combinationchartslistattributes").empty();
                                            $("ul.legendAttributes").empty();
                                        }
                                    });
                                    _.each($(".xaxisattributes .singleliitems"), function (xaxis) {
                                        if (that.props.isEdit && that.props.multifact == "true" && $(xaxis).attr("data-name") == i.name) {
                                            $("ul.xaxisattributes").empty();
                                        }
                                    });
                                });
                            }
                            var chartInfoDetails = ChartInfo.functions.getAllChartDetails();
                            _.each(chartInfoDetails, function (i, j) {
                                var chartData = JSON.parse(i);

                                _.each(chartData['yAxis'], function (value, index) {
                                    if (oldName === value.columnName) {
                                        value["displayName"] = fieldName;
                                        value["columnName"] = fieldName;
                                    }
                                });
                                _.each(chartData['xAxis'], function (value, index) {
                                    if (oldName === value.columnName) {
                                        value["displayName"] = fieldName;
                                        value["columnName"] = fieldName;
                                    }
                                });
                                _.each(chartData['tooltip'], function (value, index) {
                                    if (oldName === value.columnName) {
                                        value["displayName"] = fieldName;
                                        value["columnName"] = fieldName;
                                    }
                                });
                                if (chartData["rankingColumn"] != undefined) {
                                    if (oldName === chartData["rankingColumn"]) {
                                        chartData["rankingColumn"] = fieldName;
                                    }
                                }
                                if (chartData['legend'] != undefined) {
                                    var columnName = chartData['legend']["columnName"];
                                    if (oldName === chartData['legend'].columnName) {
                                        chartData['legend']["displayName"] = fieldName;
                                        chartData['legend']["columnName"] = fieldName;
                                    }
                                }
                                _.each(chartData["combinationchartslist"], function (val, i) {
                                    if (oldName === val.columnName) {
                                        val.displayName = fieldName;
                                    }
                                });


                                var chartType = chartData["chartType"];
                                if (chartType == "pivot") {
                                    var pivotrowvalues = chartData["rows"];
                                    _.each(pivotrowvalues, function (value) {
                                        if (value.columnName != undefined && value.columnName == oldName) {
                                            value.displayName = fieldName;
                                            value.columnName = fieldName;
                                            value.dataType = datatype;
                                        }
                                    });
                                    var pivotcolumnvalues = chartData["columns"];
                                    _.each(pivotcolumnvalues, function (value) {
                                        if (value.columnName != undefined && value.columnName == oldName) {
                                            value.displayName = fieldName;
                                            value.columnName = fieldName;
                                            value.dataType = datatype;
                                        }
                                    });
                                    var pivotvalues = chartData["values"];
                                    _.each(pivotvalues, function (value) {
                                        if (value.columnName != undefined && value.columnName == oldName) {
                                            value.displayName = fieldName;
                                            value.columnName = fieldName;
                                            value.dataType = datatype;
                                        }
                                    });
                                } else if (chartType == "summarytable") {
                                    var summarytablecolumns = chartData["summarytablecolumns"];
                                    _.each(summarytablecolumns, function (summaryColumn) {
                                        if (summaryColumn.columnName != undefined && summaryColumn.columnName == oldName) {
                                            summaryColumn.displayName = fieldName;
                                            summaryColumn.columnName = fieldName;
                                            summaryColumn.dataType = datatype;
                                        }
                                    });
                                    var summarytablevalues = chartData["summarytablevalues"];
                                    _.each(summarytablevalues, function (value) {
                                        if (value.columnName != undefined && value.columnName == oldName) {
                                            value.displayName = fieldName;
                                            value.columnName = fieldName;
                                            value.dataType = datatype;
                                        }
                                    });
                                    if (chartData["summarytablegroupbys"] != undefined && chartData["summarytablegroupbys"].columnName == oldName) {
                                        chartData["summarytablegroupbys"].displayName = fieldName;
                                        chartData["summarytablegroupbys"].columnName = fieldName;
                                        chartData["summarytablegroupbys"].dataType = datatype;
                                    }
                                }

                                ChartInfo.functions.updateChartDetails(j, JSON.stringify(chartData));

                                if (chartData["drillDownFilter"] != undefined && chartData["drillDownFilter"].length != 0) {
                                    _.each(chartData["drillDownFilter"], function (value, index) {
                                        if (value.columnname == oldName) {
                                            chartData["drillDownFilter"][index]['columnDisplayName'] = fieldName;
                                            chartData["drillDownFilter"][index]['columndisplayname'] = fieldName;
                                            chartData["drillDownFilter"][index]['columnname'] = fieldName;
                                            chartData["drillDownFilter"][index]['displayName'] = fieldName;
                                            chartData["drillDownFilter"][index]['name'] = fieldName;
                                        }
                                        if (chartData['customfieldFilterObject'] != undefined && chartData['customfieldFilterObject'].length != 0) {
                                            _.each(chartData['customfieldFilterObject'], function (value, index) {
                                                if (value.columnname == oldName) {
                                                    chartData["customfieldFilterObject"][index]['columnDisplayName'] = fieldName;
                                                    chartData["customfieldFilterObject"][index]['columndisplayname'] = fieldName;
                                                    chartData["customfieldFilterObject"][index]['columnname'] = fieldName;
                                                    chartData["customfieldFilterObject"][index]['customfield']["name"] = fieldName;
                                                }
                                            });

                                        }
                                        ChartInfo.functions.updateChartDetails(j, JSON.stringify(chartData));
                                    });
                                }

                                if (chartData["drillDownAllFilter"] != undefined && chartData["drillDownAllFilter"].length != 0) {
                                    _.each(chartData["drillDownAllFilter"], function (value, index) {
                                        if (value.columnname == oldName) {
                                            chartData["drillDownAllFilter"][index]['columnDisplayName'] = fieldName;
                                            chartData["drillDownAllFilter"][index]['columndisplayname'] = fieldName;
                                            chartData["drillDownAllFilter"][index]['columnname'] = fieldName;
                                            chartData["drillDownAllFilter"][index]['displayName'] = fieldName;
                                            chartData["drillDownAllFilter"][index]['name'] = fieldName;

                                        }
                                        if (chartData['customfieldFilterObject'] != undefined && chartData['customfieldFilterObject'].length != 0) {
                                            _.each(chartData['customfieldFilterObject'], function (value, index) {
                                                if (value.columnname == oldName) {
                                                    chartData["customfieldFilterObject"][index]['columnDisplayName'] = fieldName;
                                                    chartData["customfieldFilterObject"][index]['columndisplayname'] = fieldName;
                                                    chartData["customfieldFilterObject"][index]['columnname'] = fieldName;
                                                    chartData["customfieldFilterObject"][index]['displayName'] = fieldName;
                                                    chartData["customfieldFilterObject"][index]['customfield']["name"] = fieldName;
                                                }
                                            });
                                        }
                                        ChartInfo.functions.updateChartDetails(j, JSON.stringify(chartData));
                                    });
                                }

                            });

                            var customhierarchy = ChartInfo.functions.getcustomhierarchy();
                            if (customhierarchy != undefined && customhierarchy != null) {
                                _.each(customhierarchy, function (value, i) {
                                    _.each(value.hierarchelist, function (val, index) {
                                        if (oldName == value.hierarchelist[index].columnName) {
                                            value.hierarchelist[index].columnDisplayName = fieldName;
                                            value.hierarchelist[index].columnName = fieldName;
                                            value.hierarchelist[index].displayname = fieldName;
                                        }
                                    });
                                    //  customhierarchy[i].hierarchelist;
                                });
                                ChartInfo.functions.setcustomhierarchy(customhierarchy);
                            }
                        }

                        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Success');
                        $('.birdmessage-info').empty().text(getMessage('BirdSuccess31'));
                        setTimeout(function () {
                            $('.birdsuccess').remove();
                        }, 3000);
                        // if (Backbone.history.getFragment().indexOf("viewmodel") >= 0 && that.props.isEdit) {
                        //     $("#bootstrap-duallistbox-nonselected-list_ml_duallistbox option[value= '" + oldName + "']").remove();
                        //     $("#bootstrap-duallistbox-selected-list_ml_duallistbox option[value= '" + oldName + "']").remove();
                        //     $('select[name="ml_duallistbox"] option[value= "' + oldName + '" ]').remove();
                        // }


                        PubSub.publish("addcustomfield", tempCustomObject);
                        setTimeout(function () {
                            ReactDOM.unmountComponentAtNode(document.querySelector(".custommeasurediv" + that.props.chartnumber));
                        }, 3000);
                    } else {
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Warning');
                        $('.birdmessage-info').empty().text(getMessage('BirdWarning30'));
                        setTimeout(function () {
                            $('.birderror').remove();
                        }, 3000);
                    }
                }
                ,
                rejectFunction: this.reportDataError,
                headers: requestHeaders
            });
        }
        else {
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(getMessage('BirdWarning30'));
        }
    }

    getStartEndBrackets() {
        var startEndBrackets = {};
        if (this.state.isDirect == 1) {
            startEndBrackets["leftBracket"] = "`";
            startEndBrackets["rightBracket"] = "`";
            if (this.state.schemaType == 'mysql') {
                startEndBrackets["leftBracket"] = "`";
                startEndBrackets["rightBracket"] = "`";
            }
            else if (this.state.schemaType == 'sqlserver') {
                startEndBrackets["leftBracket"] = "[";
                startEndBrackets["rightBracket"] = "]";
            }
            else if (this.state.schemaType == 'oracle' || this.state.schemaType == 'postgres') {
                startEndBrackets["leftBracket"] = "\"";
                startEndBrackets["rightBracket"] = "\"";
            }
        } else {
            startEndBrackets["leftBracket"] = "\"";
            startEndBrackets["rightBracket"] = "\"";
        }
        return startEndBrackets;
    }
}