import React from 'react';
import ReactDOM, { unmountComponentAtNode } from 'react-dom';
import SendRequest from '../../../SendRequest';
import EditEmailConfigTemplate from './EditEmailConfigTemplate';
import { lang} from "../../../js/helpers/utils/languages/language";
import AddMailConfigView from './AddMailConfigView';
const $ = window.$;

export default class EditMailConfigView extends React.Component{
    constructor(props){
        super(props);
        this.getEditEmailConfigDetails(props.clientId);
    }

    getEditEmailConfigDetails=(clientId)=>{
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getEmailConfig",
            queryString: "?clientId=" + clientId,
            sucessFunction: (response) => {
                this.render(response);
            },
            rejectFunction: () => { },
            headers: requestHeaders,
        })
    }
    loadNextPage=(currentIndex,newIndex,that)=>{
        if(currentIndex === 0 && newIndex === 1){
            $('.header-message').html(lang["admin.mail.emailotp"]).addClass('fa-orange');
            $("#mailconfig-otp").on('keypress', function(e){
                if (e.which == 13) {
                   e.preventDefault();
                    return new AddMailConfigView();
               }
                })
            return this.addEmailConfig();
        }
    }
    isValid=(url,port,email,password)=>{
        var isValid = true;
        if (!url) {
            $(".smtpurlerror").removeClass('hidden');
            $(".smtpurlerror").text(lang["admin.mail.urlerror"] );
            isValid = false;
        }
        if(!port){
            $(".portnumbererror").removeClass('hidden');
            $(".portnumbererror").text(lang["admin.mail.porterror"]);
            isValid = false;
        }
        if(!email){
            $(".emailerror").removeClass('hidden');
            $(".emailerror").text(lang["admin.mail.emailerror"]);
            isValid = false;
        }
        if(!password){
            $(".passworderror").removeClass('hidden');
            $(".passworderror").text(lang["admin.mail.pwderror"]);
            isValid = false;
        }
          return isValid;
    }
    addEmailConfig=()=>{
        var boolean = false;
        boolean = this.signUp();
			return boolean;
    }
    signUp=()=>{
        var serialized = $('.newmailconfigform').serializeArray();		
			 var s = '';
		        var data = {};
		        for(s in serialized){
		            data[serialized[s]['name']] = serialized[s]['value']
                }
                var isValid = this.isValid(data.smtpurl,data.portnumber,data.email,data.password);
                if(isValid){
               let requestHeaders = {
                method: "get",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            SendRequest({
                url: "sendotp",
                //queryString:"?mailconfiguration="+result,
                queryString: "?smtpurl=" + data.smtpurl + "&email=" + data.email + "&portnumber=" + data.portnumber + "&password=" + data.password + 
			"&mailauthenticate=" + data.mailauthenticate + "&mailconnectiontype=" + data.mailconnectiontype,
                sucessFunction: (response) => {
                    
                },
                rejectFunction: () => { },
                headers: requestHeaders
            
            });
            return true;
        }
   return false;                    
    }
    render(response){
        if(document.querySelector(".innerothersarea")!=undefined && document.querySelector(".innerothersarea")!=null){
            unmountComponentAtNode(document.querySelector(".innerothersarea"))
        }
        ReactDOM.render(<EditEmailConfigTemplate editemailconfigdetails={response} />,document.querySelector(".innerothersarea"));
        var that=this;
			$("#example-form>div").steps({
    		    headerTag: "h2",
    		    bodyTag: "section",
    		    transitionEffect: "slideLeft",
    		    autoFocus: true,
    		    onStepChanging: function (event, currentIndex, newIndex)
	       	     {
    		    	if(currentIndex==0 && newIndex==1){
    		    		return that.loadNextPage(currentIndex,newIndex,that);
    		    	}else{
    		    		that.loadNextPage(currentIndex,newIndex,that);
    		    		return true;
    		    	}
	       	     },
	       	     onFinishing: function (event, currentIndex)
	       	     {
	       	         return true;
	       	     },
	       	     onFinished: function (event, currentIndex)
	       	     {
	       	    	new AddMailConfigView();
	       	     }
	       	 });
        return(<div></div>);
    }
}