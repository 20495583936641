import React from 'react';
import DatabaseConnection from './DatabaseConnections';
import { lang } from "../js/helpers/utils/languages/language";
export default class DatahubConnectionslistHtml extends React.Component{
    constructor(props){
        super(props);
        this.state={}
    }
    render(){
        return(
            <React.Fragment>
                <div className="datahub-connections">
                    <i className="fa fa-link fa-solid fa-flip-horizontal"></i> {lang["datahub.createcnctn"]}
                    <button className="btn btn-bird pull-right tooltip-left" data-tooltip="Create New Connection" id="createnewconnection" onClick={this.props.createnewconnection}>
                        <i className="fa fa-plus"></i>
                    </button>
                </div>
                <h2 class="connectionslist-title">{lang["datahub.existcnctn"]}</h2>
                <div className="connections-dropdown-container">
                <select id="existing-connections-list" multiple="multiple"></select>
                    <button className="btn btn-transparent btn-editconnection-abp" id="editconnections" onClick={this.props.editconnection}>
                        <i className="fa fa-cog"></i>
                    </button>
                </div>
                <div id="connections-tables-data-container">
                   
                </div>
            </React.Fragment>
        );
    }
}