import React from 'react';
import _ from 'underscore';
import { lang } from "../js/helpers/utils/languages/language";
import {getMessage} from '../js/helpers/utils/BirdMessage';
const $=window.$;

export default class ConnectionTableitemsDatacontainerHtml extends React.Component {
    componentDidMount(){
        var that=this;
        $( "#datahubentitycontainer" ).sortable({
            revert: "invalid",
        });
        $("#scopeItem ul.list-group>li").draggable({
            connectToSortable: "#datahubentitycontainer",
            revert: "invalid",
            scroll: false,
            helper: 'clone',
            addClasses:true,
        });
        _.each($("#datahubentitycontainer .scopeitem"), function(i,j) {
            var $this = $(i);
            $(".filtercontent input[data-value='"+$this.attr('id')+"'][data_parent='"+$this.attr('data_parent')+"']").parent().parent().draggable({disabled:true}).addClass("disabled");
            $(".filtercontent input[data-value='"+$this.attr('id')+"'][data_parent='"+$this.attr('data_parent')+"']").prop('checked', true);
        });
        $( "ul, li" ).disableSelection();
        $("#datahubentitycontainer").droppable({
            hoverClass: "highlight",
            activeClass: "highlight",
            out: function (event, ui) {
                var self = ui;
                var dragText = ui.draggable.find("input").attr("data-value");
            },
            drop: function(event, ui) {
                var dragText = ui.draggable.find("input").attr("data-value");
                var connectionId = ui.draggable.find("input").attr("data_parent");
                if($("#datahubentitycontainer").find("input[data-value='"+dragText+"'][data_parent='"+connectionId+"']").length===1){
                    $(".filtercontent input[data-value='"+dragText+"']").parent().parent().draggable({disabled:true}).addClass("disabled");
                    $(".filtercontent input[data-value='"+dragText+"']").prop('checked', true);
                }
                $('.joinremove').unbind().click((e)=>{
                    that.props.remove(e);
                });
                $('.editdatahubitem').unbind().click((e)=>{
                    that.props.editdatahubitem(e);
                })	
            },
            over: function(event, ui){
            }
        });
    }
    addEntityItem=(e)=>{
        var $this= e.currentTarget;
        if($($this).prop('checked')){
            var totalTempEntitiesAdded = $("#datahubentitycontainer>li:not(.is-saved-entity)").length;
            var totalEntitiesAdded = $("#datahubentitycontainer>li").length;
            if(totalEntitiesAdded>=1000){
                $($this).prop('checked',false);
                $($this).parent().parent().draggable({disabled:false}).removeClass('disabled');
                
                $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-infor').addClass('birdmessage-warning');
                $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-info-circle').addClass('fa-exclamation-triangle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Warning');
                $("#datahubstepscontainer").steps("previous");
                $('.birdmessage-info').empty().text(getMessage('BirdWarning151'));
                $('.birdmessage-center').addClass("message-top-in");
                $('.spinner').hide();
                return ;
            }else if(totalTempEntitiesAdded>=100){
                $($this).prop('checked',false);
                $($this).parent().parent().draggable({disabled:false}).removeClass('disabled');
                
                $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-infor').addClass('birdmessage-warning');
                $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-info-circle').addClass('fa-exclamation-triangle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Warning');
                $("#datahubstepscontainer").steps("previous");
                $('.birdmessage-info').empty().text(getMessage('BirdWarning157'));
                $('.birdmessage-center').addClass("message-top-in");
                $('.spinner').hide();
                return ;
            }else{
                var cloneEntity = $($this).parent().parent().clone();
                $("#datahubentitycontainer").append(cloneEntity);
             
              $($this).parent().parent().draggable({disabled:true}).addClass('disabled');
              $('.joinremove').unbind().click((e)=>{
                    this.props.joinremove(e);
                });
                $('.editdatahubitem').unbind().click((e)=>{
                    this.props.editdatahubitem(e)
                })
                
            }
            
        }else{
            
        }
    //	$("#datahubentitycontainer").append(existingEntities({item:entityName,connectionaccessid:connAccessId, showsettings:true,entity_type:entityType,entityId:entityId,entityType:entityType}));
    }
    render() {
        return (
            <React.Fragment>
                {/* <!-- csvconnectionview.js --> */}
                <div className="col-xs-12 nopadding">
                    <div className="panel-group">
                        <div className="acc-content">
                            <div className="panelboc">
                                <div className="panelboc-heading">
                                    <h4 className="panelboc-title accordion-toggle" title="" id="tablecolllapse" data-toggle="collapse" href="#dbscopetables" aria-expanded="true">
                                        <i className="fa fa-table"></i>{lang["datahub.entities"]}
                                    </h4>
                                </div>
                                <div id="dbscopetables" className="panel-collapse panelboc-content filterStringvalues newreportdbitems collapse in" aria-expanded="true" style={{}}>
                                    <div className="panelboc-body">
                                        <div className="filtercontent" id="scopeItem">
                                            <div className="container-fluid marg-bot-10">
                                                <div className="row">
                                                    <input
                                                        className="form-control search-image"
                                                        name="Scope_Production"
                                                        placeholder={lang["datahub.search"]}
                                                        id="searchinput"
                                                        type="text"
                                                        onChange={(e)=>{this.props.search(e)}}
                                                    />
                                                </div>
                                            </div>
                                            <ul className="list-group scrollsetul checkhelpitems">
                                                {
                                                    this.props.scopeitems[0].tables.map((item,index)=>{
                                                        var isDurable=(this.props.scopeitems[0].durability != undefined)? this.props.scopeitems[0].durability[item]:false;
                                                            return(
                                                                <li item-expanded='true' className="viewreport-list-item" key={index}>
                                                                    <label title={item}>
                                                                    <span className="source"><i className="fa fa-link"></i>{this.props.connectionname}</span>
                                                                        <i className="fa fa-table"></i>
                                                                        <input type="checkbox" durable={isDurable} onClick={this.addEntityItem} className={`scopeitem ${this.props.connectiontype.toLowerCase()}tablevalue`} data_parent={this.props.connectionaccessid} name={this.props.connectiontype} id={item} data-value={item} />
                                                                        {item}
                                                                    </label>
                                                                    <button className="btn btn-transparent btn-sm editdatahubitem" onClick={this.props.editdatahubitem} data_parent={this.props.connectionaccessid} name={item} type="button" title={lang["Datahub.settings"]}>
                                                                        <i className="fa fa-cog"></i>
                                                                    </button>
                                                                    <button className="btn btn-transparent btn-sm joinremove" type="button" onClick={this.props.joinremove}>
                                                                        <i className="fa fa-times"></i>
                                                                    </button>
                                                                </li>
                                                            );
                                                        }
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}