import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import DataModelListTemplate from "./DataModelListTemplateHtml.jsx";
import SendRequest from '../SendRequest';
import _ from 'underscore';
import { getMessage } from '../js/helpers/utils/BirdMessage';
import { lang } from "../js/helpers/utils/languages/language";
import BusinessModelsDropdownListView from '../BusinessModelsDropdownListView'
import ImportTemplateFile from './importtemplatefile';
const $ = window.$;

class DataModelListView extends Component {
    constructor(props) {
        super(props);
        this.state = { isFromModel: false,
        features: [] };
        this.navigatetosearch= this.navigatetosearch.bind(this);
        this.render();
    }
    componentDidMount() {
        this.fetchDataModelsAndWorkspaces();
    }
    getModelslist(page, search, pageDisplay, order, colName) {
        var index = 0;
        var that = this;
        var noOfRecords = $("#pageDropDown").text() == "" ? 10 : $("#pageDropDown").text();
        if (noOfRecords == " ") {
            noOfRecords = 10
        }
        var totalRecords = 0;
        if (page != undefined && page > 1) {
            index = (page - 1) * noOfRecords;
        }
        if (order == undefined) {
            order = 'asc';
        }
        if (colName == undefined) {
            colName = '';
        }
        this.dataFieldValue = colName;
        this.sortingOrder = order;
        this.page = page;
        var that = this
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getdatamodelslist",
            queryString: "?fromDate= " + "" + "&toDate= " + "" + "&index= " + index + "&noOfRecords=" + noOfRecords + "&searchCriteria=" + search + "&order=" + order + "&colName=" + colName + "&urltype=" + "getUserPaginationDataModels",
            sucessFunction: (response) => {
                if (response.hasOwnProperty('error')) {
                    $(".spinner").hide();
                } else {
                    var that = this
                    var headerlist = response["header"];
                    var data = response["data"];
                    totalRecords = response["totalRecords"];
                    var keys = Object.keys(headerlist);
                    var totalPages;
                    if (pageDisplay == undefined) {
                        pageDisplay = 25;
                    }
                    if (totalRecords > 25) {
                        totalPages = Math.ceil(totalRecords / pageDisplay);
                    } else {
                        totalPages = 1;
                    }

                    _.each(data, function (val, index) {
                        var editpermit = "";
                        var editid = "";
                        var modeldelete = false;
                        var modelstoryboard = true;
                        var modelreport = false;
                        var modelmlreport = false;
                        var modelsmartinsights = false;
                        var modelimporttemplate = false;
                        var deletecache = false;
                        var DMStatus = 'ready';
                        var modelautoviz = false;
                        var smartSearch= false;
                        if (val.DMStatus == 'Ready') {
                            DMStatus = 'ready'
                        } else if (val.DMStatus == 'inprogress') {
                            DMStatus = 'processing'
                        } else {
                            DMStatus = 'error'
                        }
                        _.each(that.state.features, function (featuresModule) {
                            if (featuresModule.moduleName == 'business_model') {
                                _.each(featuresModule.features, function (feature) {
                                    if (feature.featureName == 'create_model_storyboard' && feature.allow == true && DMStatus == 'ready') {
                                        modelstoryboard = true;
                                    }
                                    if (feature.featureName == 'create_model_report' && feature.allow == true && DMStatus == 'ready') {
                                        modelreport = true;
                                    } if (feature.featureName == 'create_model_smartinsights' && feature.allow == true && !val.multifact && DMStatus == 'ready') {
                                        modelsmartinsights = true;
                                    } if (feature.featureName == 'create_model_mlreport' && feature.allow == true && !val.multifact && DMStatus == 'ready') {
                                        modelmlreport = true;
                                    }
                                    if (feature.featureName == 'import_template_view' && feature.allow == true && DMStatus == 'ready') {
                                        modelimporttemplate = true;
                                    }if(feature.featureName == 'create_model_autovizreport' && feature.allow == true && !val.multifact && DMStatus == 'ready'){
                                        modelautoviz = true;
                                    }
                                    if(feature.featureName == 'advance_search' && feature.allow == true && !val.multifact && DMStatus == 'ready'){
                                        smartSearch = true;
                                    }
                                });
                            }
                        });
                        val.editModel = <a href={`${that.props.url}/models/${btoa(val.data_model_id)}`} data-num={val.data_model_id} class="boc-snapshot-thumbnail reportview viewdatamodel" tab-id="0" tab-name="" id="reportstoryboardview"><i class="fa fa-edit"></i></a>;
                        val.deleteModel = <button type="button" onClick={() => { that.datamodeldelete(val.data_model_id,index) }} DMID={val.data_model_id} id="deletedatamodellistiew" workspace-id={val.workspace_id} datahub-id={val.hub_id} workspace-title={val.workspace_name} data-num={val.workspace_id} tab-id="0" tab-name="" class="btn btn-transparent aldelete"><i class="fa fa-trash-o"></i></button>;
                        //   val.create=<a className="accordion-toggle" href={`#newdataitem${val.data_model_id}`} data-toggle="collapse" data-parent="#accordion" style={{ textDecoration: "none" }}><i className="fa fa-plus marg-righ-10"></i></a>
                        val.create = <BusinessModelsDropdownListView {...that.props} modelstoryboard={modelstoryboard} modelreport={modelreport} modelmlreport={modelmlreport} modelsmartinsights={modelsmartinsights}  DMTitle={val.name}  
                            modelimporttemplate={modelimporttemplate} modeldelete={modeldelete} DMID={val.data_model_id} is_shared={false} importtemplatefile={that.importtemplatefile} createsmartinsights={that.createsmartinsights} createstoryboard={that.createStoryBoard} deletemodel={that.datamodeldelete} createreport={that.createReport} indexval={index} editpermit={editpermit} createMLReport={that.createMLReport} navigatetosearch={that.navigatetosearch} createAutoViz={that.createAutoViz} smartSearch={smartSearch} modelautoviz={modelautoviz} deletecache={deletecache}/>
                            val.deleteModelCache = <button type="button" onClick={() => { that.deletemodelcache(val.data_model_id,index) }} DMID={val.data_model_id} id="deletedatamodelcachelistiew" workspace-id={val.workspace_id} datahub-id={val.hub_id} workspace-title={val.workspace_name} data-num={val.workspace_id} tab-id="0" tab-name="" class="btn btn-transparent aldelete"><i class="fa fa-trash-o"></i></button>;
                    });
                    // $("#datamodellisttable").remove();
                    // $("#datamodelcontentcontainer .data-items-container").append('<div id="datamodellisttable"></div>')
                    ReactDOM.unmountComponentAtNode(document.getElementById("datamodelstable"));
                    ReactDOM.render(<DataModelListTemplate searchkeyup={that.searchkeyup} datamodeldelete ={that.datamodeldelete} deleteReport={that.deleteReport} url={that.props.url} search={search} totalRecords={totalRecords} sizePerPage={pageDisplay} headerlist={headerlist} data={data} features={that.state.features} history={this.props.history} deletemodelcache={this.deletemodelcache} />, document.querySelector("#datamodelstable"));
                    if (search != '') {
                        $(".searchdatamodeltable").focus();
                    }
                    $(".spinner").hide();

                }
            },
            rejectFunction: () => { },
            headers: requestHeaders

        });


    }


    datamodeldelete = (modelId, index) => {
        var that = this;
        //        var $this = e.currentTarget;
        //        var datamodelId=$($this).attr("id");
        $(".deleteyes").unbind("click");
        $(".deleteno").unbind("click");
        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
        $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
        $('.birdmessagebuttons').addClass('show');
        $('.birdmessage-center').addClass("message-top-in");
        $('.details').removeClass('show');
        $('.details').addClass('hide');
        $('.birdmessage h2').empty().append('Warning');
        $('.birdmessage-info').empty().text(getMessage('BirdWarning129'));
        $(".deleteyes").click(function () {
            $('.spinner').show();
            $('.birdmessage-center').removeClass("message-top-in");
            SendRequest({
                url: "deletebusinessmodel",
                queryString: "?modelId=" + modelId,

                sucessFunction: (response) => {
                    if (!response.hasOwnProperty('error')) {
                        $('.spinner').hide();
                        $('.birdmessage-center').removeClass("message-top-in");
                        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Success');
                        $('.birdmessage-info').empty().text(getMessage('BirdWarning130'));
                        const modelsArray = Object.assign([], that.state.models);
                        modelsArray.splice(index, 1);
                        that.setState({ models: modelsArray });
                        // that.collection.fetch({reset:true,cache:false});
                        // that.render();
                    } else {
                        $(".spinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.errorTrace)
                    }
                },
                rejectFunction: (error) => { },
                headers: {
                    method: "post",
                    headers: new Headers({
                        "Content-Type": "application/json"
                    })
                }
            });
        });
        $(".deleteno").click(function () {
            $('.birdmessage-center').removeClass("message-top-in");
        });
    }
    createBusinessModel = (e) => {
        var $this = e.currentTarget;
        sessionStorage.setItem('workspaceId', $($this).attr("id"));
        this.props.history.push("/" + window.localStorage.getItem("appName") + "/welcome/models");
    }
    searchkeyup = (e) => {
        //console.log("in search block");
        var that = this;
        var delay = (function () {
            var timer = 0;
            return function (callback, ms) {
                clearTimeout(timer);
                timer = setTimeout(callback, ms);
            };
        })();
        var search = $(e.currentTarget).val().toLowerCase();
        var pageDisplaySearch = $("#pageDropDown").text();
        if (pageDisplaySearch == undefined || pageDisplaySearch == null || pageDisplaySearch == " ") {
            pageDisplaySearch = 10
        }
        delay(function () {
            that.searchValue = $(".searchworkspacetable").val();
            that.getModelslist(undefined, that.searchValue, pageDisplaySearch, that.sortingOrder, that.dataFieldValue);
        }, 100);

        $(".searchworkspacetable").focus();
    }
    fetchDataModelsAndWorkspaces() {
        var that = this;
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };

        SendRequest({
            url: "getfeatures",
            queryString: "",
            sucessFunction: (features) => {
                that.state.features = features;
                SendRequest({
                    url: "getavailabledatamodels",
                    queryString: "",
                    sucessFunction: (props) => {
                        that.setState({ models: props });
                    },
                    rejectFunction: () => { },
                    headers: requestHeaders,
                })
                SendRequest({
                    url: "getavailableworkspaces",
                    queryString: "?isFromModel=" + that.state.isFromModel,
                    sucessFunction: (props) => {
                        that.setState({ workspaces: props });
                    },
                    rejectFunction: () => { },
                    headers: requestHeaders,
                })
            },
            rejectFunction: () => { },
            headers: {
                method: "get",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            }
        });
    }
    foldersselectchange = (e) => {
        var pageDisplaySearch = $("#pageDropDown").text();
        this.getModelslist(undefined, "", pageDisplaySearch, 'asc', undefined);
    }
    createsmartinsights = (e) => {
        e.preventDefault();
        var that = this;
        var $this = e.currentTarget;
        var datamodelId = $($this).attr("datamodel-id");
        var datamodelTitle = $($this).attr("datamodel-title");
        if (datamodelId == null || datamodelId == undefined) {
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Information');
            $('.birdmessage-info').empty().text(getMessage('BirdInformation01'));
        } else {
            $(".spinner").show();
            $(".cancelmodal").click();
            $(".smartinsightsclick").click();
            $(".storyboardtabs ul.tabcontainer").removeClass("hide");
            $(".smartinsightintro-container").removeClass("hide");
            $(".smartinsightdatacontainer").removeClass("hide");
            var globalfilterArray = [];
            var requestbody = new FormData();
            requestbody.set("datamodelid", datamodelId + "");
            let requestHeaders = {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            SendRequest({
                url: "checkfordatatypes",
                body: requestbody,
                sucessFunction: (response) => {
                    if (!response.hasOwnProperty('error')) {
                        var reportrequestbody = new FormData();
                        reportrequestbody.set("datamodelid", datamodelId);
                        reportrequestbody.set("type", "");
                        SendRequest({
                            url: "createreportfrommodel",
                            body: reportrequestbody,
                            sucessFunction: (response) => {
                                var href = "/" + window.localStorage.getItem("appName") + "/welcome/viewsmartinsight/" + btoa(datamodelId)
                                sessionStorage.setItem("datamodelname", datamodelTitle);
                                that.props.history.push(href);
                            },
                            rejectFunction: this.reportDataError,
                            headers: requestHeaders
                        });
                        $(".spinner").hide();
                    } else {
                        $(".spinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').removeClass('show');
                    }
                },
                rejectFunction: this.reportDataError,
                headers: requestHeaders
            });
        }
    }
    createReport = (e) => {
        var $this = e.currentTarget;
        console.log($this);
        var datamodelId = $($this).attr("datamodel-id");
        var isShared = $($this).attr("is_shared");

        var bodyParams = new FormData();
        bodyParams.set("datamodelid", datamodelId);
        bodyParams.set("type", "report");

        SendRequest({
            url: "createreportfrommodel",
            body: bodyParams,
            sucessFunction: (response) => {
                var tempReportId = response['tempreportid'];
                sessionStorage.setItem("isSharedModel" + datamodelId, isShared);
                var href = "/" + window.localStorage.getItem("appName") + "/welcome/viewreport/" + btoa(datamodelId) + "/" + btoa(tempReportId);
                this.props.history.push(href);
            },
            rejectFunction: () => { },
            headers: {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            }
        });
    }
    createMLReport = (e) => {
        var $this = e.currentTarget;
        console.log($this);
        var datamodelId = $($this).attr("datamodel-id");
        var isShared = $($this).attr("is_shared");

        var bodyParams = new FormData();
        bodyParams.set("datamodelid", datamodelId);
        bodyParams.set("type", "report");

        SendRequest({
            url: "createreportfrommodel",
            body: bodyParams,
            sucessFunction: (response) => {
                var tempReportId = response['tempreportid'];
                sessionStorage.setItem("isSharedModel" + datamodelId, isShared);
                var location = window.location;
                var href = location.origin + "/" + window.localStorage.getItem("appName") + "/welcome/viewmodel/" + btoa(datamodelId);
                window.open(href, '_blank');
            },
            rejectFunction: () => { },
            headers: {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            }
        });
    }
    importtemplatefile = (e) => {
        e.preventDefault();
        var $this = e.currentTarget;
        var datamodelId = $($this).attr("datamodel-id");
        var isShared = $($this).attr("is_shared");
        var that = this;
        var importTemplateFileView = new ImportTemplateFile({
            featuresModule: that.state.features,
            datamodelId: datamodelId,
            isFromSharedModel: isShared != undefined ? isShared : false,
            history: that.props.history
        });
    }
    createStoryBoard = (e) => {
        var $this = e.currentTarget;
        $('.spinner').show();
        console.log($this);
        var datamodelId = $($this).attr("datamodel-id");
        var isShared = $($this).attr("is_shared") != undefined ? $($this).attr("is_shared") : false;

        var requestbody = new FormData();
        requestbody.set("datamodelid", datamodelId);
        requestbody.set("type", "storyboard");
        let requestHeaders = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "createreportfrommodel",
            body: requestbody,
            headers: requestHeaders,
            sucessFunction: (props) => {
                var tempReportId = props.tempreportid;
                sessionStorage.setItem("isSharedModel" + datamodelId, isShared);
                var href = "/" + window.localStorage.getItem("appName") + "/welcome/viewstoryboard/" + btoa(datamodelId) + "/" + btoa(tempReportId);
                this.props.history.push(href);
                $('.spinner').hide();
            },
            rejectFunction: (props) => {
                var response = props;
                $(".spinner").hide();
                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('hide');
                $('.birdmessage h2').empty().append('Error : ');
                $('.birdmessage h2').append('<span class="errorcode">');
                $('.birdmessage .errorcode').empty().append(response.errorCode);
                $('.birdmessage-info').empty().text(response.error);
                $('.details').addClass('show').text("Details");
                $('.messagedetails xmp').empty().append(response.errorTrace)
            }
        });
    }
    navigatetosearch=(e)=>{
        var $this = e.currentTarget;
        var datamodelId = $($this).attr("datamodel-id");
        var href = "/" + window.localStorage.getItem("appName") + "/welcome/search";
        this.props.history.push(href);
        setTimeout(function(){
            $(".is-container.datamodelsearchcontainer").removeClass("closemenu");
            $(".chosen-select#selectdatamodel").val(datamodelId).change()
            $(".chosen-select#selectdatamodel").val(datamodelId).trigger('chosen:updated');
        },500)
    }

    createAutoViz =(e)=>{
        var $this = e.currentTarget;
        console.log($this);
        var datamodelId = $($this).attr("datamodel-id");
        var isShared = $($this).attr("is_shared");
        var datamodelTitle = $($this).attr("datamodel-title");
        var bodyParams = new FormData();
        bodyParams.set("datamodelid", datamodelId);
        bodyParams.set("type", "report");

        SendRequest({
            url: "createreportfrommodel",
            body: bodyParams,
            sucessFunction: (response) => {
                var tempReportId = response['tempreportid'];
                sessionStorage.setItem("isSharedModel" + datamodelId, isShared);
                sessionStorage.setItem("report_navigated_datamodelname", datamodelTitle)
                var location = window.location;
                var href = location.origin + "/" + window.localStorage.getItem("appName") + "/welcome/viewautoviz_report/" + btoa(datamodelId);
                window.open(href, '_blank');
            },
            rejectFunction: () => { },
            headers: {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            }
        });
    }
    deletemodelcache = (modelId, index) => {
        var that = this;
        //        var $this = e.currentTarget;
        //        var datamodelId=$($this).attr("id");
        $(".deleteyes").unbind("click");
        $(".deleteno").unbind("click");
        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
        $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
        $('.birdmessagebuttons').addClass('show');
        $('.birdmessage-center').addClass("message-top-in");
        $('.details').removeClass('show');
        $('.details').addClass('hide');
        $('.birdmessage h2').empty().append('Warning');
        $('.birdmessage-info').empty().text(getMessage('BirdWarning158'));
        $(".deleteyes").click(function () {
            $('.spinner').show();
            $('.birdmessage-center').removeClass("message-top-in");
            SendRequest({
                url: "deleteCacheForDataModel",
                queryString: "?modelId=" + modelId,

                sucessFunction: (response) => {
                    if (!response.hasOwnProperty('error')) {
                        $('.spinner').hide();
                        $('.birdmessage-center').removeClass("message-top-in");
                        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Success');
                        $('.birdmessage-info').empty().text(getMessage('BirdSuccess69'));
                        
                    } else {
                        $(".spinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.errorTrace)
                    }
                },
                rejectFunction: (error) => { },
                headers: {
                    method: "post",
                    headers: new Headers({
                        "Content-Type": "application/json"
                    })
                }
            });
        });
        $(".deleteno").click(function () {
            $('.birdmessage-center').removeClass("message-top-in");
        });
    }

    render() {
        this.getModelslist(undefined, "", 10, 'asc', undefined);
        return (
            <div class="col-xs-12 marg-top-10">
                <div class="notifications-table-container data-item">
                    <div class="bootstrap-table">
                        <div class="fixed-table-toolbar">
                            <div class="pull-right search">
                                <input class="form-control searchworkspacetable" type="text" placeholder={lang["models.search"]} defaultValue={this.search} onKeyUp={this.searchkeyup}/>
                            </div>
                        </div>
                        <div id="datamodelstable">
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DataModelListView;