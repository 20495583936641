import React from "react";
import ReactDOM from "react-dom";
import _ from 'lodash';
import SendRequest from '../SendRequest';
import PubSub from 'pubsub-js'
import { getMessage } from '../js/helpers/utils/BirdMessage';
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import CustomMeasureTemplate from "./createcustommeasuremodel.jsx"
import customMeasureFieldView from "./custommeasurefieldview"
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;

var columnDisplaynameArray = [];
var customFields = [];
export default class CustomMeasureModalView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columnDisplayNameMap: {},
            columnDisplayTypeMap: {},
            chartnumber: props.chartnumber,
            broker: props.broker,
            dimensions: props.dimensions,
            reportId: props.reportId,
            datamodelId: props.datamodelId,
            schemaType: props.schemaType,
            isDirect: props.isDirect,
        };
        this.updateColumnDisplayNameMap();
        this.state.el = ".customaggregatedfield" + this.state.chartnumber;
        this.render();
        this.textAreaAutoComplete();
    }
    render() {
        var that = this;
        var name = "";
        var script = "";
        var htmlScript = "";
        var convertedNames = JSON.parse(sessionStorage.getItem("convertedtypes"));
        var jsonObject = {};
        _.each(convertedNames, function (array) {
            jsonObject[array.columnName] = array.dataType;
        });
        var result = $.grep(ChartInfo.functions.getCustomMeasureField(), function (e) { return e.name == that.props.columnName });
        if (result != undefined && result.length > 0) {
            this.state.oldName = result[0].name;
            name = result[0].name;
            script = result[0].script;
            htmlScript = result[0].htmlText;
            var pattern = /\[(.*?)\]/g;
            var match;
            var matches = [];
            var usedFields = result[0].usedFields.split(",");
            // for updating the renamed dimensions and custom fields in the formula editor
            if (usedFields != undefined && usedFields.length > 0) {
                _.each(usedFields, function (columnName) {
                    var columnDisplayName = _.invert(that.state.columnDisplayNameMap)[columnName];
                    var tempcustomfields = sessionStorage.getItem("customFields");
                    var temp = JSON.parse(tempcustomfields);
                    var isCustomfield = false;
                    _.each(temp, function (customField) {
                        if (customField.name == columnDisplayName) {
                            isCustomfield = true;
                        }
                    });
                    if (columnDisplayName != undefined) {
                        $(".renamedfields").empty();
                        $(".renamedfields").append(htmlScript);
                        _.each($('.custommeasurelist'), function (field, i) {
                            if ($(field).attr("data-measure") == columnName) {
                                var oldFunction = field.outerHTML;
                                $(field).attr("displayName", columnDisplayName);
                                $(field).text(columnDisplayName);
                                if (isCustomfield) {
                                    $(field).attr("data-measure", columnDisplayName);
                                }
                                var newFunction = field.outerHTML;
                                htmlScript = htmlScript.replace("[" + oldFunction + "]", "[" + newFunction + "]");
                            }
                        });
                        $(".renamedfields").empty();
                    }
                });
            }
        }
        ReactDOM.render(<CustomMeasureTemplate createCustomFilter={this.createCustomFilter} testformula={this.testformula} clearQueryArea={this.clearQueryArea} closeCustomMeasure={this.closeCustomMeasure} chartnumber={this.state.chartnumber} dimensions={this.props.dimensions} name={name} script={htmlScript} customfields={customFields} />, document.querySelector(this.state.el));
        if (htmlScript != undefined && htmlScript != null) {
            $('#customaggregatedfieldquerydisplay' + this.state.chartnumber).html(htmlScript);
        }
        return (<div></div>);
    }
    closeCustomMeasure = (e) => {
        ReactDOM.unmountComponentAtNode(document.querySelector(this.state.el));
    }
    updateColumnDisplayNameMap() {
        var that = this;
        var parsedCustomFields = [];
        var parsedConvertedTypes = [];
        columnDisplaynameArray = [];
        customFields = [];
        _.each(this.state.dimensions, function (dimension) {
            columnDisplaynameArray.push(dimension.columnDisplayName);
            that.state.columnDisplayTypeMap[dimension.columnDisplayName] = dimension.tableDisplayType;
            that.state.columnDisplayNameMap[dimension.columnDisplayName] = dimension.columnName;
        });
        var convertedTypes = sessionStorage.getItem("convertedtypes");
        var tempcustomfields = sessionStorage.getItem("customFields");
        var temp = JSON.parse(tempcustomfields);
        var res;
        _.each(temp, function (customfields) {
            _.each(customfields.parametrFieldsArray, function (value) {
                res = $.grep(ChartInfo.functions.getCustomParameter(), function (e) {
                    return e.parametername == value;
                })
                var formula = customfields.formula;
                var scriptcondition = customfields.scriptcondition;
                var fields = customfields.fields;
                var sqlformula = customfields.sqlformula;
                if (res.length != 0) {
                    customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
                    customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
                    customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
                    customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
                } else {
                    _.each(ChartInfo.functions.getCustomParameter(), function (values) {
                        if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
                            customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                            customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                            customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
                            customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                        }
                    });
                }
            });
        });
        tempcustomfields = JSON.stringify(temp);
        if (tempcustomfields != undefined) {
            parsedCustomFields = JSON.parse(tempcustomfields);
        }
        /*if(convertedTypes!=undefined){  
            parsedConvertedTypes =JSON.parse(convertedTypes);
        }*/
        _.each(parsedCustomFields, function (customField) {
            var temp = $.grep(parsedCustomFields, function (e) { return e.name == customField.name });
            columnDisplaynameArray.push(temp[0].name);
            that.state.columnDisplayNameMap[temp[0].name] = temp[0].name;
            that.state.columnDisplayTypeMap[temp[0].name] =  temp[0].dataType;
            customFields.push(temp[0]);
        });
    }
    textAreaAutoComplete() {
        var that = this;
        var multifact = (that.props.multifact == 'true');
        var multifactentities = (that.props.multifact == 'true') ? JSON.parse(that.props.multifactentities) : that.props.multifactentities;
        var facttables = that.props.facttables;
        var words = ["sum()", "min()", "max()", "avg()", "count()", "uniqExact()", "GlobaluniqExact()", "Globalsum()", "Globalcount()", "Globalmax()", "Globalmin()", "Globalavg()"]
        $('#customaggregatedfieldquerydisplay' + this.state.chartnumber).on("mousedown change", function () {
            $('.custommeasurelist:empty').remove();
        });
        $('#customaggregatedfieldquerydisplay' + this.state.chartnumber).textcomplete([
            {
                words: words,
                match: /\b(\w{1,}\.{0,1}\w{0,})$/,
                index: 1,
                search: function (term, callback) {
                    if (multifact) {
                        var allowedTableNames = [];
                        var previoustablename;
                        _.each($('.custommeasurelist'), function (elemtnt) {
                            if ($(elemtnt).not(':empty').length > 0) {
                                var columnName = $(elemtnt).attr('data-measure');
                                var tableName = columnName.split(".")[0];
                                var customFields = JSON.parse(sessionStorage.getItem("customFields"));
                                if (customFields != null && customFields != undefined) {
                                    var customFieldObject = $.grep(customFields, function (e) { return e.name == columnName });
                                    if (customFieldObject != undefined && customFieldObject.length > 0) {
                                        _.each(customFieldObject[0].usedFields, function (hierarchy) {
                                            var tableName = hierarchy.split(".")[0];
                                            previoustablename = tableName;
                                            allowedTableNames = multifactentities[tableName];
                                            allowedTableNames.push(tableName);
                                        });
                                    } else {
                                        previoustablename = tableName;
                                        allowedTableNames = multifactentities[tableName];
                                        allowedTableNames.push(tableName);
                                    }
                                } else {
                                    previoustablename = tableName;
                                    allowedTableNames = multifactentities[tableName];
                                    allowedTableNames.push(tableName);
                                }
                            }
                        });

                        if (previoustablename != undefined && facttables.indexOf(previoustablename) != -1) {
                            _.each(facttables, function (ft) {
                                if (ft != previoustablename) {
                                    _.each(allowedTableNames, function (allowed) {
                                        var index = allowedTableNames.indexOf(ft);
                                        if (index > -1) {
                                            allowedTableNames.splice(index, 1);
                                        }
                                    });
                                }
                            });
                        }

                        if (allowedTableNames.length > 0) {
                            var allowedColumnNames = [];
                            _.each(columnDisplaynameArray, function (columnDisplayName) {
                                var actualName = that.state.columnDisplayNameMap[columnDisplayName];
                                var actualTableName = actualName.split(".")[0];
                                var customFields = JSON.parse(sessionStorage.getItem("customFields"));
                                if (customFields != null && customFields != undefined) {
                                    var customFieldObject = $.grep(customFields, function (e) { return e.name == actualName });
                                    if (customFieldObject != undefined && customFieldObject.length > 0) {
                                        _.each(customFieldObject[0].usedFields, function (hierarchy) {
                                            var actualTableName = hierarchy.split(".")[0];
                                            if (allowedTableNames.indexOf(actualTableName) >= 0) {
                                                allowedColumnNames.push(columnDisplayName);
                                            }
                                        });
                                    } else {
                                        if (allowedTableNames.indexOf(actualTableName) >= 0) {
                                            allowedColumnNames.push(columnDisplayName);
                                        }
                                    }
                                } else {
                                    if (allowedTableNames.indexOf(actualTableName) >= 0) {
                                        allowedColumnNames.push(columnDisplayName);
                                    }
                                }
                            });
                            this.words = words;
                            this.words = this.words.concat(allowedColumnNames.sort());
                        }
                        else {
                            this.words = this.words.concat(columnDisplaynameArray.sort());
                        }

                    } else {
                        this.words = this.words.concat(columnDisplaynameArray.sort());
                    }
                    callback($.map(this.words, function (word) {
                        return word.toLowerCase().indexOf(term.toLowerCase()) >= 0 ? word : null;
                    }));
                },
                template: function (word) {
                    if (word.indexOf('()') < 0 && word.indexOf('(,)') < 0 && word.indexOf('(,,)') < 0 && word.indexOf('(){}') < 0 && word.indexOf('{}') < 0 && word.indexOf('(,"")') < 0 && word.indexOf('(,,"")') < 0) {
                        var type = that.state.columnDisplayTypeMap[word];
                        var result = '';
                        if (type == 'number' || type == 'parameter') {
                            result = "<span class='itemtype number'>123</span>  <span class='itemname' title='" + word + "'>" + word + "</span>";
                        }
                        else if (type == 'date' || type == 'day' || type == 'month') {
                            result = "<span class='itemtype date'><i class='fa fa-calendar'></i></span>  <span class='itemname' title='" + word + "'>" + word + "</span>";
                        }
                        else {
                            result = "<span class='itemtype string'>"+lang["storyboard.abc"]+"</span>  <span class='itemname' title='" + word + "'>" + word + "</span>";
                        }
                        return result;
                        /*return "<i class='fa fa-sort-numeric-asc'></i>  <span class='itemname'>"+word+"</span>";*/
                    } else {
                        return "<span class='fa-orange marg-righ-5'><i>f</i> <small>(x)</small></span> " + word;
                    }
                },
                replace: function (word) {
                    var tempWord = word;
                    if (word.indexOf('()') < 0 && word.indexOf('(,)') < 0 && word.indexOf('(,,)') < 0 && word.indexOf('(){}') < 0 && word.indexOf('{}') < 0 && word.indexOf("(,'')") < 0 && word.indexOf("(,,'')") < 0) {
                        var columnName = that.state.columnDisplayNameMap[word];
                        tempWord = "[<span class='fa-orange custommeasurelist' displayname = '" + word + "' data-measure='" + columnName + "'>" + word + "</span>]"

                    } else {
                        var dataword = word.substring(0, word.indexOf('('));
                        var tempword = "<span class='aggrlist'  data-word='" + dataword + "' contenteditable=false>" + dataword + "(</span><span>)</span>"
                        tempWord = tempword;
                    }
                    return tempWord;
                }
            }
        ], { maxCount: 50 });
    }
    clearQueryArea = (e) => {
        e.preventDefault();
        $('#customaggregatedfieldquerydisplay' + this.state.chartnumber).empty();
    }
    testformula = (e) => {
        e.preventDefault();
        var textareaData = $('#customaggregatedfieldquerydisplay' + this.state.chartnumber).text();
        this.state.isImportedReport = false;
        if (sessionStorage.getItem("isImportedReport") || window.location.pathname.indexOf("importreport") >= 0) {
            this.state.isImportedReport = true;
        }
        if (ChartInfo.functions.isScriptData(textareaData)) {
            return true;
        }
        var that = this;
        var formula = textareaData;
        if (formula != "") {
            var scriptmap = this.scriptFormula();
            if (this.state.customFormulaFieldsArray != undefined && this.state.customFormulaFieldsArray.length == 0) {
                return;
            }
            var tempcustomfields = sessionStorage.getItem("customFields");
            var temp = JSON.parse(tempcustomfields);
            var res;
            _.each(temp, function (customfields) {
                _.each(customfields.parametrFieldsArray, function (value) {
                    res = $.grep(ChartInfo.functions.getCustomParameter(), function (e) {
                        return e.parametername == value;
                    })
                    var formula = customfields.formula;
                    var scriptcondition = customfields.scriptcondition;
                    var fields = customfields.fields;
                    var sqlformula = customfields.sqlformula;
                    if (res.length != 0) {
                        customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
                        customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
                        customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
                        customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
                    } else {
                        _.each(ChartInfo.functions.getCustomParameter(), function (values) {
                            if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
                                customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
                                customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                            }
                        });
                    }
                });
            });
            tempcustomfields = JSON.stringify(temp);
            let requestHeaders = {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            var data = new FormData();
            data.set("reportId", that.props.reportId);
            data.set("custommeasurescript", JSON.stringify(scriptmap));
            data.set("script", tempcustomfields);
            data.set("datamodelId", that.props.datamodelId);
            data.set("customType", "custommessure");
            data.set("isImportedReport", that.state.isImportedReport);
            data.set("custommeassureFormulaFieldsArray ", JSON.stringify(that.state.customFormulaFieldsArray));
            SendRequest({
                url: "testcustommeassureformula",
                body: data,
                sucessFunction: (response) => {
                    if (response.hasOwnProperty('error')) {
                        $(".spinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.errorTrace);
                        setTimeout(function () {
                            $('.birderror').remove();
                        }, 3000);
                    } else if (response.valid) {
                        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Success');
                        $('.birdmessage-info').empty().text(getMessage('BirdSuccess32'));
                        setTimeout(function () {
                            $('.birdsuccess').remove();
                        }, 3000);
                    } else {
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        if (response.errorTrace === undefined) {
                            $('.details').removeClass('show');;
                        } else {
                            $('.details').addClass('show');
                            $('.messagedetails xmp').empty().append(response.errorTrace);
                        }
                        $('.birdmessage h2').empty().append('Warning');
                        $('.birdmessage-info').empty().text(getMessage('BirdWarning30'));
                        setTimeout(function () {
                            $('.birderror').remove();
                        }, 3000);
                    }
                },
                rejectFunction: this.reportDataError,
                headers: requestHeaders,
            });
        }
        else {
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(getMessage('BirdWarning30'));
        }
    }
    scriptFormula() {
        var that = this;
        var jsonobj = {};
        var textAreaTxt = $('#customaggregatedfieldquerydisplay' + this.state.chartnumber).text();
        var textAreaHtml = $('#customaggregatedfieldquerydisplay' + this.state.chartnumber).html();
        var pattern = /\[(.*?)\]/g;
        var match;
        var matches = [];
        var count = 0;
        var columnName = $('.customaggregatedfield' + that.props.chartnumber + ' #customaggregatedfieldname').val().replace(/([#;?&,%. +*~\':"!^$%[\]()<=>|\/@])/g, "\$1");
        $('.help-block.customaggregatedfieldname-error').addClass('hide');
        var jsonarray = $.grep(ChartInfo.functions.getCustomMeasureField(), function (e) { return e.name != $('.customaggregatedfield' + that.props.chartnumber + ' #customaggregatedfieldname').val() });
        var functionMapping = [];
        this.state.customFormulaFieldsArray = [];
        /*if(columnName != ""){
            $('.customaggregatedfields[data-name="'+columnName+'"]').remove();
        }*/
        var columnsUsedInMeasure = "";
        $('.aggrlist').each(function (i, j) {
            count++;
            var jsonObj = {};
            jsonObj['aggType'] = $(j).attr('data-word');
            jsonObj["columnName"] = $('.custommeasurelist').eq(i).attr('data-measure');
            jsonObj['displayname'] = $('.custommeasurelist').eq(i).attr('displayname');;
            columnsUsedInMeasure += jsonObj["columnName"] + ",";
            functionMapping.push(jsonObj);
        });
        if (count == $('.custommeasurelist').length) {
            var sqlExp = textAreaTxt;
            _.each(functionMapping, function (i, j) {
                textAreaTxt = textAreaTxt.replace(i.aggType, '');
                textAreaTxt = textAreaTxt.replace("[" + (_.invert(that.state.columnDisplayNameMap)[i.columnName]) + "]", 'value' + j);
                functionMapping[j]["bucketPathValue"] = "value" + j;
                var columnName = that.state.columnDisplayNameMap[i.displayname];
                that.state.customFormulaFieldsArray.push(columnName);
                var startEndBrackets = that.getStartEndBrackets();
                columnName = startEndBrackets["leftBracket"] + columnName + startEndBrackets["rightBracket"];
                //					var replaceText =  new RegExp("\\["+_.invert(that.columnDisplayNameMap)[i.displayname] + "\\]", "g");
                sqlExp = sqlExp.replace('[' + i.displayname + ']', columnName + "");
            });
            var tempTextArea = encodeURIComponent(textAreaTxt);
            tempTextArea = tempTextArea.replace(/%C2%A0/g, '');
            textAreaTxt = decodeURIComponent(tempTextArea);
            textAreaTxt = textAreaTxt.replace(/ /g, '');
            jsonobj['name'] = $('.customaggregatedfield' + that.props.chartnumber + ' #customaggregatedfieldname').val();
            jsonobj["functionMapping"] = functionMapping;
            jsonobj["htmlText"] = textAreaHtml;
            jsonobj['script'] = textAreaTxt;
            jsonobj['sqlformula'] = decodeURIComponent(encodeURIComponent(sqlExp).replace(/%C2%A0/g, ''));
            jsonobj["usedFields"] = columnsUsedInMeasure.substring(0, columnsUsedInMeasure.length - 1);
            jsonarray.push(jsonobj);
            return jsonobj;
        } else {
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').removeClass('hide');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(getMessage('BirdWarning30'));
            return;
            setTimeout(function () {
                $('.birderror').remove();
            }, 3000);
        }
    }
    getStartEndBrackets() {
        var startEndBrackets = {};
        if (this.isDirect == 1) {
            startEndBrackets["leftBracket"] = "`";
            startEndBrackets["rightBracket"] = "`";
            if (this.schemaType == 'mysql') {
                startEndBrackets["leftBracket"] = "`";
                startEndBrackets["rightBracket"] = "`";
            }
            else if (this.schemaType == 'sqlserver') {
                startEndBrackets["leftBracket"] = "[";
                startEndBrackets["rightBracket"] = "]";
            }
            else if (this.schemaType == 'oracle' || this.schemaType == 'postgres') {
                startEndBrackets["leftBracket"] = "\"";
                startEndBrackets["rightBracket"] = "\"";
            } else {
                startEndBrackets["leftBracket"] = "\"";
                startEndBrackets["rightBracket"] = "\"";
            }
        } else {
            startEndBrackets["leftBracket"] = "\"";
            startEndBrackets["rightBracket"] = "\"";
        }
        return startEndBrackets;
    }
    createCustomFilter = (e) => {
        e.preventDefault();
        var that = this;
        that.state.close = true;
        var fieldNameTest = $('.customaggregatedfield' + this.props.chartnumber + ' #customaggregatedfieldname').val();
        if ((/[!@#$%^&*()+\=\[\]{};':\\|,.<>\/?`"`]/.test(fieldNameTest)) ||
            (fieldNameTest.toLowerCase().trim() == "min" || fieldNameTest.toLowerCase().trim() == "max"
                || fieldNameTest.toLowerCase().trim() == "avg" || fieldNameTest.toLowerCase().trim() == "sum"
                || fieldNameTest.toLowerCase().trim() == "count" || fieldNameTest.toLowerCase().trim() == "avgerage")) {
            that.state.close = false;
            $(".spinner").hide();
            $(".gridspinner").hide();
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Information');
            $('.birdmessage-info').empty().text(getMessage('BirdInformation46'));
            return;
        }
        var columnName = $('.customaggregatedfield' + this.props.chartnumber + ' #customaggregatedfieldname').val().trim().replace(/([#;?&,%. +*~\':"!^$%[\]()<=>|\/@])/g, "\\$1");
        if (this.state.oldName != $('.customaggregatedfield' + this.props.chartnumber + ' #customaggregatedfieldname').val().trim() || $('.custommeasurequerydisplay').text() == "") {
            if (!$('.customaggregatedfield' + this.props.chartnumber + ' #customaggregatedfieldname').val().trim().length > 0 ||
                $('.attributesdimensions[title="' + columnName + '"]').length > 0 || $('.custommeasurehierarchyfields[title="' + columnName + '"]').length > 0
                || $('.customrangefields[title="' + columnName + '"]').length > 0 || $('.customhierarchyfields[title="' + columnName + '"]').length > 0
                || $('.customparameterfield[title="' + columnName + '"]').length > 0) {
                that.state.close = false;
                $('.help-block.customaggregatedfieldname-error ').removeClass('hide');
            } else if ($('.customaggregatedfields[data-name="' + columnName + '"]').length != 0 && $('.custommeasurequerydisplay').text() != "") {
                that.state.close = false;
                $('.help-block.customaggregatedfieldname-alreadyexist-error ').removeClass('hide');
            } else if ($('.custommeasurequerydisplay').text() == "") {
                that.state.close = false;
                $('.custommeasurequerydisplay').addClass('bird-has-error');
            } else {
                $('.help-block.customaggregatedfieldname-error').addClass('hide');
                this.createCustomFilterField(e);
            }
        } else {
            $('.custommeasurequerydisplay').removeClass('bird-has-error');
            this.createCustomFilterField(e);
            if (this.props.multifact == "true") {
                _.each($("ul.yaxisattributes li.singleliitems"), function (i, j) {
                    var chartData = i.dataset;
                    _.each(chartData, function (value, index) {
                        if (value == "customaggregatedfield") {
                            $("ul.yaxisattributes li.singleliitems[data-type='customaggregatedfield']").parent().remove();
                        }
                    });
                });
            }
        }
        if (this.state.close) {

            setTimeout(function () {
                that.closeCustomMeasure();
            }, 3000);
        }
    }
    createCustomFilterField(e) {
        var that = this;
        var jsonobj = {};
        var textAreaTxt = $('#customaggregatedfieldquerydisplay' + this.state.chartnumber).text();
        var textAreaHtml = $('#customaggregatedfieldquerydisplay' + this.state.chartnumber).html();
        var pattern = /\[(.*?)\]/g;
        var match;
        var matches = [];
        var columnName = $('.customaggregatedfield' + this.props.chartnumber + ' #customaggregatedfieldname').val().replace(/([#;?&,%. +*~\':"!^$%[\]()<=>|\/@])/g, "\\$1");
        if (ChartInfo.functions.isScriptData(columnName)) {
            return true;
        }
        var status = false;
        var reservedwordCheck = $('.customaggregatedfield' + this.props.chartnumber + ' #customaggregatedfieldname').val().trim().replace(/([#;?&,%. +*~\':"!^$%[\]()<=>|\/@])/g, "\\$1");
        var reservedwordslist = JSON.parse(sessionStorage.getItem("reservedwordslist"));
        _.each(reservedwordslist, function (reservedword, j) {
            if (reservedwordCheck.toLowerCase() == reservedword) {
                status = true
            }
        });
        if (status == true) {
            $(".spinner").hide();
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(lang["storyboard.custommeasure"]+ "" + getMessage('BirdWarning147'));
            $(".spinner").hide();
            that.state.close = false;
            return;
        }
        var isRankingItemAlreadyExist = false;
        _.each($('.rankingitem option'), function (rankingItem) {
            if (that.state.oldName != undefined && $(rankingItem).attr("data-name") == that.state.oldName) {
                isRankingItemAlreadyExist = true;
            }
        });
        if (isRankingItemAlreadyExist == true) {
            var colName = $('.customaggregatedfield' + this.props.chartnumber + ' #customaggregatedfieldname').val();
            $(".rankingitem option[data-name='" + that.state.oldName + "']").attr("title", colName);
            $(".rankingitem option[data-name='" + that.state.oldName + "']").text(colName);
            $(".rankingitem option[data-name='" + that.state.oldName + "']").attr("data-name", colName);
        }
        $(".combinationchartslistattributes").find(".singleliitems[data-name='" + that.state.oldName + "']").find("span.itemname").text(colName);
        $(".combinationchartslistattributes").find(".singleliitems[data-name='" + that.state.oldName + "']").attr("title", colName);
        $(".combinationchartslistattributes").find(".singleliitems[data-name='" + that.state.oldName + "']").attr("data-name", colName);
        $('.help-block.customaggregatedfieldname-error').addClass('hide');
        var deletevalue = $.grep(ChartInfo.functions.getCustomMeasureField(), function (e) { return e.name != that.state.oldName });
        if (that.state.oldName != undefined && that.state.oldName.length > 0) {
            that.state.oldName = that.state.oldName.replace(/([#;?&,%.+*~\':"!^$%[\]()<=>|\/@])/g, "\\$1")
        }
        if (deletevalue.length >= 0) {
            $('.customaggregatedfields[title="' + this.state.oldName + '"]').remove();
            ChartInfo.functions.setCustomMeasureField(deletevalue);
        }
        this.state.isImportedReport = false;
        if (sessionStorage.getItem("isImportedReport") || window.location.pathname.indexOf("importreport") >= 0) {
            this.state.isImportedReport = true;
        }
        var textareaData = $('#customaggregatedfieldquerydisplay' + this.state.chartnumber).text();
        var formula = textareaData;
        if (formula != "") {
            var scriptmap = this.scriptFormula();
            if (this.state.customFormulaFieldsArray != undefined && this.state.customFormulaFieldsArray.length == 0) {
                that.state.close = false;
                return;
            }
            var tempcustomfields = sessionStorage.getItem("customFields");
            var temp = JSON.parse(tempcustomfields);
            var res;
            _.each(temp, function (customfields) {
                _.each(customfields.parametrFieldsArray, function (value) {
                    res = $.grep(ChartInfo.functions.getCustomParameter(), function (e) {
                        return e.parametername == value;
                    })
                    var formula = customfields.formula;
                    var scriptcondition = customfields.scriptcondition;
                    var fields = customfields.fields;
                    var sqlformula = customfields.sqlformula;
                    if (res.length != 0) {
                        customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
                        customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
                        customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
                        customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
                    } else {
                        _.each(ChartInfo.functions.getCustomParameter(), function (values) {
                            if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
                                customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
                                customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                            }
                        });
                    }
                });
            });
            tempcustomfields = JSON.stringify(temp);
            var that = this;
            let requestHeaders = {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            var data = new FormData();
            data.set("reportId", that.props.reportId);
            data.set("custommeasurescript", JSON.stringify(scriptmap));
            data.set("script", tempcustomfields);
            data.set("datamodelId", that.props.datamodelId);
            data.set("customType", "custommessure");
            data.set("isImportedReport", that.state.isImportedReport);
            data.set("custommeassureFormulaFieldsArray ", JSON.stringify(that.state.customFormulaFieldsArray));
            SendRequest({
                url: "testcustommeassureformula",
                body: data,
                sucessFunction: (response) => {
                    if (response.hasOwnProperty('error')) {
                        that.state.close = false;
                        $(".spinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.errorTrace);
                        setTimeout(function () {
                            $('.birderror').remove();
                        }, 3000);
                    } else if (response.valid) {
                        $(".spinner").hide();
                        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Success');
                        $('.birdmessage-info').empty().text(getMessage('BirdSuccess52'));
                        setTimeout(function () {
                            $('.birdsuccess').remove();
                        }, 3000);
                    } else {
                        that.state.close = false;
                        $(".spinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Warning');
                        $('.birdmessage-info').empty().text(getMessage('BirdWarning30'));
                        setTimeout(function () {
                            $('.birderror').remove();
                        }, 3000);
                    }
                    if (response.valid) {
                        var jsonarray = $.grep(ChartInfo.functions.getCustomMeasureField(), function (e) { return e.name != $('.customaggregatedfield' + that.props.chartnumber + ' #customaggregatedfieldname').val() });
                        var functionMapping = [];
                        if (columnName != "") {
                            $('.customaggregatedfields[data-name="' + columnName + '"]').remove();
                        }
                        var columnsUsedInMeasure = "";
                        $('.aggrlist').each(function (i, j) {
                            var jsonObj = {};
                            jsonObj['aggType'] = $(j).attr('data-word');
                            jsonObj["columnName"] = $('.custommeasurelist').eq(i).attr('data-measure');
                            columnsUsedInMeasure += jsonObj["columnName"] + ",";
                            functionMapping.push(jsonObj);
                        });
                        var sqlExp = textAreaTxt;
                        _.each(functionMapping, function (i, j) {
                            textAreaTxt = textAreaTxt.replace(i.aggType, '');
                            textAreaTxt = textAreaTxt.replace("[" + (_.invert(that.state.columnDisplayNameMap)[i.columnName]) + "]", 'value' + j);
                            functionMapping[j]["bucketPathValue"] = "value" + j;
                            var columnName = that.state.columnDisplayNameMap[i.columnName];
                            var startEndBrackets = that.getStartEndBrackets();
                            columnName = startEndBrackets["leftBracket"] + columnName + startEndBrackets["rightBracket"];
                            sqlExp = sqlExp.replace('[' + i.columnName + ']', columnName + "");
                        });
                        var tempTextArea = encodeURIComponent(textAreaTxt);
                        tempTextArea = tempTextArea.replace(/%C2%A0/g, '');
                        textAreaTxt = decodeURIComponent(tempTextArea);
                        textAreaTxt = textAreaTxt.replace(/ /g, '');
                        jsonobj['name'] = $('.customaggregatedfield' + that.props.chartnumber + ' #customaggregatedfieldname').val();
                        jsonobj["functionMapping"] = functionMapping;
                        jsonobj["htmlText"] = textAreaHtml;
                        jsonobj['script'] = textAreaTxt;
                        sqlExp = encodeURIComponent(sqlExp);
                        sqlExp = sqlExp.replace(/%C2%A0/g, ' ');
                        sqlExp = decodeURIComponent(sqlExp);
                        jsonobj['sqlformula'] = scriptmap.sqlformula;
                        jsonobj["usedFields"] = columnsUsedInMeasure.substring(0, columnsUsedInMeasure.length - 1);
                        jsonarray.push(jsonobj);
                        var today = new Date();
                        var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                        $(".customaggregateddimension").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                        var custommeasurefieldView = new customMeasureFieldView({
                            dynamicdiv: dynamicdiv,
                            columnDisplayName: $('.customaggregatedfield' + that.props.chartnumber + ' #customaggregatedfieldname').val(),
                            columnName: $('.customaggregatedfield' + that.props.chartnumber + ' #customaggregatedfieldname').val()
                        });
                        var fieldName = $('.customaggregatedfield' + that.props.chartnumber + ' #customaggregatedfieldname').val();
                        $(".attributescustomfields[data-name='" + that.state.oldName + "']").remove();
                        var renameXaxisName = $('.yaxisattributes li[data-name="' + that.state.oldName + '"]');
                        $(renameXaxisName).attr('data-name', fieldName);
                        $(renameXaxisName).attr('title', fieldName);
                        $(renameXaxisName).find('.itemname').text(fieldName);
                        $(renameXaxisName).find('.itemtype').attr('data-name', fieldName);
                        ChartInfo.functions.updateAxisName(that.state.oldName, fieldName, 'yAxis');
                        var tempObject = sessionStorage.getItem("renameMeasuresorDimensions") == null ? {} : JSON.parse(sessionStorage.getItem("renameMeasuresorDimensions"));
                        tempObject[that.state.oldName] = fieldName;
                        //for removing old renamed fields which is having same name 
                        var newtempObject = {};
                        if (tempObject != null && tempObject != undefined) {
                            _.each(tempObject, function (value, key) {
                                if (key != undefined && key != fieldName) {
                                    newtempObject[key] = value;
                                }
                            });
                        }
                        sessionStorage.setItem("renameMeasuresorDimensions", JSON.stringify(newtempObject))
                        $(".customaggregateddimension").append($("#" + dynamicdiv));
                        $(".measurecount").text(parseInt($('.measurecount').text()) + 1);
                        ChartInfo.functions.setCustomMeasureField(jsonarray);
                        $(".createcustomaggregatedfield" + that.state.chartnumber).modal("hide");

                    }
                },
                rejectFunction: this.reportDataError,
                headers: requestHeaders,
            });
        }
        else {
            that.state.close = false;
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(getMessage('BirdWarning30'));
        }
    }
}
