import React from "react";
import _ from 'lodash';
//import $ from 'jquery/dist/jquery';
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import * as ChartUtils from '../js/helpers/utils/chartutil'
import { lang } from "../js/helpers/utils/languages/language";
import PubSub from 'pubsub-js'
const $ = window.$;

export default class AttributesTemplate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.cancelmodal = this.cancelmodal.bind(this);
        this.showyAxisDropDowninSorting = this.showyAxisDropDowninSorting.bind(this);
        this.createCustomFiled = this.createCustomFiled.bind(this);
        this.createDillDownHierarchy = this.createDillDownHierarchy.bind(this);
        this.onChangeChartNarrative = this.onChangeChartNarrative.bind(this);
        this.onChangeChartHeatmapMonoColor = this.onChangeChartHeatmapMonoColor.bind(this);
        this.onChangeChartPareto = this.onChangeChartPareto.bind(this);
        this.onChangeConnectNulls = this.onChangeConnectNulls.bind(this);
        this.validateDecimals = this.validateDecimals.bind(this);
        this.onChangedecimal = this.onChangedecimal.bind(this);
        this.onChangeChartSplitYAxis = this.onChangeChartSplitYAxis.bind(this);
        this.onChangeChartEqualYAxisScale = this.onChangeChartEqualYAxisScale.bind(this);
    }
    createCustomMeasure(e) {
        PubSub.publish("createcustommeasure", e);
    }
    createCustomMeassureHierarchy(e){
        PubSub.publish('createcustomhierarchymeasure',e);
    }
    createCustomRange(e){
        e.stopPropagation()
        PubSub.publish('createrange',e);
    }
    render() {
        var that = this;
        return (
            //    <!-- attrmodalview.js -->
            <div className="container-fluid newslideoutattributes" id="newslideoutattributes">
                <div className="row">
                    <div className="col-xs-12">
                        <h4 className="modal-title"><i className="fa fa-bar-chart"></i>&nbsp;&nbsp;{lang["storyboard.visualsetting"]}<button className="pull-right btn btn-sm btn-transparent cancelmodal" data-num={that.props.chartnumber} onClick={this.cancelmodal}><i className="fa fa-times"></i></button></h4>
                    </div>
                </div>
                <div className="row chartstylecontainerrow">
                    <div className="col-xs-12 chartstylecolumn">
                        <ul className="list-group chartstyles">
                            <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="bar" title={lang["storyboard.basicbar"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/basicbar-20.png')} alt="" /></li>
                            <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="stackedbar" title={lang["storyboard.stackedbar"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/stackedbar-20.png')} alt="" /></li>
                            <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="line" title={lang["storyboard.basicline"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/linechart-sm-20.png')} alt="" /></li>
                            <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="spline" title={lang["storyboard.spline"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/spline-20.png')} alt="" /></li>
                            <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="pie" title={lang["storyboard.basicpie"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/piechart-sm-20.png')} alt="" /></li>
                            <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="donut" title={lang["storyboard.donut"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/donut-20.png')} alt="" /></li>
                            <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="area" title={lang["storyboard.basicarea"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/areachartbasic-20.png')} alt="" /></li>
                            <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="stackedarea" title={lang["storyboard.stackedarea"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/stackedarea-20.png')} alt="" /></li>
                            <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="bubble" title={lang["storyboard.bubble"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/bubblechart-sm-20.png')} alt="" /></li>
                            <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="scatter" title={lang["storyboard.scatter"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/scatter-20.png')} alt="" /></li>
                            <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="funnel" title={lang["storyboard.funnel"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/funnel-20.png')} alt="" /></li>
                            <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="pyramid" title={lang["storyboard.pyramid"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/pyramid-20.png')} alt="" /></li>
                            <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="metric" title={lang["storyboard.metricchart"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/metric-20.png')} alt="" /></li>
                            <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="solidgauge" title={lang["storyboard.semiguage"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/solidgauge-20.png')} alt="" /></li>
                            <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="fullcirclegauge" title={lang["storyboard.fullguage"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/fullcirclegauge-20.png')} alt="" /></li>
                            <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="combinationchart" title={lang["storyboard.combchart"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/combinationcharts-20.png')} alt="" /></li>
                            <li className="boc-list-item charticons" id="advancess" data-toggle="collapse" data-target="#advancedvisualizations"><img className="chart-icons-18" src={require('../images/birdimages/charts/sankey-20.png')} alt="" />
                                <span className="boclabel boclabel-right-5 opacity-effect dropdown-toggle" type="button" title={lang["storyboard.advancevisual"]}><i className="fa fa-angle-down fa-solid"></i></span>
                                <ul id="advancedvisualizations" className="collapse charttypes">
                                    <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="sankey" title={lang["storyboard.sankey"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/sankey-20.png')} alt="" /></li>
                                    {/* <!-- <li className="boc-list-item charticons charttype" id="sunburst" title="Sunburst><img className="chart-icons-18" src={require('../images/birdimages/charts/sunburst-20.png')} alt="" /></li> --> */}
                                    <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="wordcloud" title={lang["storyboard.wordcloud"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/wordcloud-20.png')} alt="" /></li>
                                    <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="boxplot" title={lang["storyboard.boxplot"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/boxplot-20.png')} alt="" /></li>
                                </ul>
                            </li>
                            <li className="boc-list-item charticons" data-toggle="collapse" data-target="#mapcharttypes" title={lang["storyboard.mapcharts"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/mapchart-sm-20.png')} alt="" />
                                <span className="boclabel boclabel-right-5 opacity-effect dropdown-toggle" type="button" ><i className="fa fa-angle-down fa-solid"></i></span>
                                <ul id="mapcharttypes" className="collapse charttypes">
                                    <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="world" title={lang["storyboard.world"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/world-sm-20.png')} alt="" /></li>
                                    <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="countrymap" title={lang["storyboard.countrymap"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/mapchart-sm-20.png')} alt="" /></li>
                                    <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="leaflet" title={lang["storyboard.usmap"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/mapchart-sm-20.png')} alt="" /></li>
                                    <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="srilankamap" title={lang["storyboard.srilanka"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/srilanka-20.png')} alt="" /></li>
                                    <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="indiamap" title={lang["storyboard.india"]}><img class="chart-icons-18" src={require('../images/birdimages/charts/indiamap-20.png')} alt=""/></li>
                                </ul>
                            </li>
                            <li className="boc-list-item charticons" id="streamings" data-toggle="collapse" data-target="#streamingvisualizations" title={lang["storyboard.streaming"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/solidgauge-20.png')} alt="" />
                                <span className="boclabel boclabel-right-5 opacity-effect dropdown-toggle" type="button" ><i className="fa fa-angle-down fa-solid fa-solid"></i></span>
                                <ul id="streamingvisualizations" className="collapse charttypes">
                                    <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="bartimeseries" title={lang["storyboard.barseries"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/timeseriesbar-20.png')} alt="" /></li>
                                    <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="linetimeseries" title={lang["storyboard.lineseries"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/linetimeseries-20.png')} alt="" /></li>
                                    <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="areatimeseries" title={lang["storyboard.timeseries"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/timeseriesarea-20.png')} alt="" /></li>
                                    <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="metric" title={lang["storyboard.metricchart"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/metric-20.png')} alt="" /></li>
                                    <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="solidgauge" title={lang["storyboard.semiguage"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/solidgauge-20.png')} alt="" /></li>
                                    <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="fullcirclegauge" title={lang["storyboard.fullguage"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/fullcirclegauge-20.png')} alt="" /></li>
                                </ul>
                            </li>

                            <li className="boc-list-item charticons" data-toggle="collapse" data-target="#othercharttypes" title={lang["storyboard.othercharts"]}>
                                <i className="fa fa-ellipsis-h marg-righ-5 marg-left-5"></i>
                                <span className="boclabel boclabel-right-5 opacity-effect dropdown-toggle" type="button" ><i className="fa fa-angle-down fa-solid"></i></span>
                            </li>
                            <ul id="othercharttypes" className="collapse charttypes">
                                <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="invertedbar" title={lang["storyboard.invertedbar"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/invertedbar-20.png')} alt="" /></li>
                                <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="invertedstackedbar" title={lang["storyboard.invertedstack"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/invertedstackedbar-20.png')} alt="" /></li>
                                <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="multicolorbar" title={lang["storyboard.colorbar"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/multicolorbar-20.png')} alt="" /></li>
                                {(that.props.multifact != 'true') ? (
                                    <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="multixbar" title={lang["storyboard.xbar"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/multixbar-20.png')} alt="" /></li>
                                ) : ""}
                                <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="multiaxisbar" title={lang["storyboard.axes"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/lineplusbarchart-sm-20.png')} alt="" /></li>
                                <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="bartimeseries" title={lang["storyboard.seriestime"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/timeseriesbar-20.png')} alt="" /></li>
                                <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="3dbar" title={lang["storyboard.3dbar"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/3dbar-20.png')} alt="" /></li>
                                {(that.props.multifact != 'true') ? (
                                    <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="trellis" title={lang["storyboard.trellis"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/trellis-h-20.png')} alt="" /></li>
                                ) : ""}
                                <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="waterfall" title={lang["storyboard.waterfall"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/waterfall-20.png')} alt="" /></li>
                                {(that.props.multifact != 'true') ? (
                                    <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="multixline" title={lang["storyboard.xline"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/multixline-20.png')} alt="" /></li>
                                ) : ""}
                                <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="invertedline" title={lang["storyboard.lineinvented"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/lineinvertedaxis-20.png')} alt="" /></li>
                                <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="linetimeseries" title={lang["storyboard.lineseries"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/linetimeseries-20.png')} alt="" /></li>
                                <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="semicircle" title={lang["storyboard.semidonut"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/semi-circle-donut-20.png')} alt="" /></li>
                                <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="3dpie" title={lang["storyboard.3dpie"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/3dpie-20.png')} alt="" /></li>
                                {(that.props.multifact != 'true') ? (
                                    <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="multixarea" title={lang["storyboard.xarea"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/multixarea-20.png')} alt="" /></li>
                                ) : ""}
                                <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="invertedarea" title={lang["storyboard.invertedaxes"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/invertedaxesarea-20.png')} alt="" /></li>
                                <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="areatimeseries" title={lang["storyboard.timeseries"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/timeseriesarea-20.png')} alt="" /></li>
                                <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="spiderweb" title={lang["storyboard.radarchart"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/spiderweb-20.png')} alt="" /></li>
                                <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="polar" title={lang["storyboard.polarchart"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/polar-20.png')} alt="" /></li>
                                <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="windrose" title={lang["storyboard.windrose"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/windrose-20.png')} alt="" /></li>
                                <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="sparkline" title={lang["storyboard.sparkline"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/sparkline-20.png')} alt="" /></li>
                                <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="heatmap" title={lang["storyboard.heatmap"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/heatmap-20.png')} alt="" /></li>
                                <li className="boc-list-item charticons charttype" onClick={that.props.setcharttype} id="textchart" title={lang["storyboard.text"]}><img className="chart-icons-18" src={require('../images/birdimages/charts/richtext-20.png')} alt="" /></li>
                            </ul>
                        </ul>
                    </div>
                </div>
                <div className="row chartstylestabscontainerrow">
                    <div className="col-xs-12 chartstylestabscontainercolumn modal-body">
                        <div role="tabpanel" className="chartstyletabpanelcontainer">
                            <ul className="nav nav-tabs tabcontainer axissettings-tab-container" role="tablist">
                                <li role="presentation" className="tabheadli axissettingstab active" title={lang["storyboard.axessettings"]}>
                                    <a href="#axissettings" id="axissettingsclick" aria-controls="axissettings" role="tab" data-toggle="tab"><img className="" src={require('../images/birdimages/attributes-axessetting-20.png')} alt="" /></a>
                                </li>
                                <li role="presentation" className="tabheadli measuresdimensionstab" title={lang["storyboard.measures"]}>
                                    <a href="#mdsettings" id="axissettingsclick" aria-controls="axissettings" role="tab" data-toggle="tab"><img className="" src={require('../images/birdimages/attributes-measures-20.png')} alt="" /></a>
                                </li>
                                <li role="presentation" className="tabheadli axissortingtab" title={lang["storyboard.sorting"]}>
                                    <a href="#axissortingsettings" id="axissortingsettingsclick" aria-controls="axissortingsettings" role="tab" data-toggle="tab"><img className="" src={require('../images/birdimages/attributes-sorting-20.png')} alt="" /></a>
                                </li>
                                <li role="presentation" className="tabheadli themesettingstab" title={lang["storyboard.colorthemes"]}>
                                    <a href="#themesettings" id="themeettingsclick" aria-controls="themesettings" role="tab" data-toggle="tab"><img className="" src={require('../images/birdimages/attributes-theme-20.png')} alt="" /></a>
                                </li>
                                <li role="presentation" className="tabheadli chartsoptionsfinetuningtab" title={lang["storyboard.chartsettings"]}>
                                    <a href="#chartsoptionsfinetuning" id="chartsoptionsfinetuningclick" aria-controls="chartsoptionsfinetuning" role="tab" data-toggle="tab"><img className="" src={require('../images/birdimages/attributes-othersettings-20.png')} alt="" /></a>
                                </li>

                            </ul>
                            {/* <!---First Tab Content Start--> */}
                            <div className="tab-content">
                                {/* <!---First Tab Start--> */}
                                <div role="tabpanel" className="tab-pane active boc-snapshot-container tabviewitems boc-gray-inner" id="axissettings">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 axissetting settingscontent" id="axissetting">
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 axissettingdummy settingscontent hide" id="axissettingdummy">
                                    </div>
                                    {/* <!-- Parameters --> */}
                                    {/* <!-- <div className="parametersdiv modalaxiscontainer nonaxis_ct">
                                       <legend className="subhead fa-grey"><div className="attraxisnames">Parameters</div>
                                           <button className="pull-right btn btn-transparent tooltip-left" data-tooltip="Create Parameter" id="customparameter"><i className="fa fa-plus-circle"></i></button>
                                       </legend>
                                       <div className="container-fluid marg-top-5">
                                           <input className="form-control form-control-customparameters search-image" placeholder="Search" id="searchinput" type="text">
                                       </div>
                                       <div className="marg-top-10">
                                           <ul className="list-group customparameterfields marg-top-10"></ul>
                                       </div>	
                                   </div> --> */}
                                    {/* <!--\ Parameters --> */}
                                </div>
                                {/* <!---Second Tab Start--> */}
                                <div role="tabpanel" className="tab-pane boc-snapshot-container tabviewitems boc-gray-inner" id="mdsettings">
                                    <div className="col-xs-12 settingscontent">
                                        {/* <!-- Measures --> */}
                                        <div className="measuresdiv modalaxiscontainer">
                                            <div className="custommeasure nopadding">
                                                <legend className="subhead"><div className="attraxisnames">{lang["storyboard.measures"]}</div>
                                                    {that.props.allfeatures.map((featuresModule, index) => {
                                                        if (featuresModule.moduleName == 'my_report') {
                                                            return featuresModule.features.map((feature, index) => {
                                                                if (feature.featureName == 'viewreport_maximize_custom_measure' && feature.allow == true) {
                                                                    return (<button key={index} className="pull-right btn btn-transparent dropdown-toggle tooltip-left" data-tooltip={lang["storyboard.create"]} id="dropdowncustommeasure" aria-expanded="false" data-toggle="dropdown">
                                                                        <i className="fa fa-plus-circle"></i>
                                                                    </button>);
                                                                }
                                                            })
                                                        }
                                                    })}
                                                    <ul className="dropdown-menu dropdown-menu-right dropdowncustommeasure" id="dropdowncustomplusicon" aria-labelledby="dropdowncustommeasure" role="menu">
                                                        {that.props.allfeatures.map((featuresModule) => {
                                                            if (featuresModule.moduleName == 'my_report') {
                                                                return featuresModule.features.map((feature, index) => {
                                                                    if (feature.featureName == 'viewreport_maximize_custom_measure' && feature.allow == true) {
                                                                        return (<><li key={index} title="" className="xs-list-item createcustommeasure" id="createcustommeasures">
                                                                            <a href="javascript:void(0)" onClick={this.createCustomMeasure}><i>{lang["storyboard.fx"]}</i> {lang["storyboard.custommeasure"]}</a>
                                                                        </li>
                                                                            <li key={index} title="" className="xs-list-item createcustomhierarchymeasure" id="createcustomhierarchymeasures">
                                                                                <a href="javascript:void(0)" onClick={this.createCustomMeassureHierarchy}><i className="fa fa-list"></i> {lang["storyboard.measurehierarchy"]}</a>
                                                                            </li></>);
                                                                    }
                                                                    // if (feature.featureName == 'viewreport_maximize_custom_measure' && feature.allow == true) {
                                                                    //     return (<li key={index} title="" className="xs-list-item createcustomhierarchymeasure" id="createcustomhierarchymeasures">
                                                                    //         <a href="javascript:void(0)"><i className="fa fa-list"></i> Measure Hierarchy</a>
                                                                    //     </li>);
                                                                    // }
                                                                })
                                                            }
                                                        })}
                                                    </ul>
                                                </legend>
                                            </div>
                                            <div className="container-fluid marg-top-5">
                                                <input className="form-control form-control-measures search-image" onKeyUp={that.props.searchMeasures} placeholder={lang["storyboard.search"]} id="searchinput" type="text"></input>
                                            </div>
                                            <div className="scroller">
                                                <ul className="list-group customaggregateddimension"></ul>
                                                <ul className="list-group custommeasurehierarchydimension"></ul>
                                                <ul className="list-group measures"></ul>
                                            </div>
                                        </div>
                                        {/* <!--\ Measures --> */}

                                        {/* <!-- Dimensions --> */}
                                        <div className="dimensionsdiv modalaxiscontainer">
                                            <div className="custommeasure nopadding">
                                                <legend className="subhead"><div className="attraxisnames">{lang["storyboard.dimensions"]}</div>
                                                    {(that.props.isCustomFieldButtonEnable) ? (
                                                        <button className="pull-right btn btn-transparent dropdown-toggle tooltip-left" data-tooltip={lang["storyboard.create"]} id="dropdowncustomdimension" aria-expanded="false" data-toggle="dropdown">
                                                            <i className="fa fa-plus-circle"></i>
                                                        </button>
                                                    ) : ""}
                                                    <ul className="dropdown-menu dropdown-menu-right dropdowncustomdimension" aria-labelledby="dropdowncustomdimension" role="menu">
                                                        {that.props.allfeatures.map((featuresModule) => {
                                                            if (featuresModule.moduleName == 'my_report') {
                                                                return featuresModule.features.map((feature, index) => {
                                                                    if (feature.featureName == 'viewreport_maximize_custom_field' && feature.allow == true) {
                                                                        return (<li key={index} title="" className="xs-list-item callcustommeasure" onClick={this.createCustomFiled} id="callcustommeasuremax">
                                                                            <a href="javascript:void(0)"><i>{lang["storyboard.fx"]}</i> {lang["storyboard.customfield"]}</a>
                                                                        </li>);
                                                                    }
                                                                })
                                                            }
                                                        })}
                                                        {that.props.allfeatures.map((featuresModule) => {
                                                            if (featuresModule.moduleName == 'my_report') {
                                                                return featuresModule.features.map((feature, index) => {
                                                                    if (feature.featureName == 'viewreport_maximize_custom_hierarchy' && feature.allow == true) {
                                                                        return (<li key={index} title="" className="xs-list-item callcustomhierarchy" onClick={this.createDillDownHierarchy} id="callcustomhierarchymax">
                                                                            <a href="javascript:void(0)"><i className="fa fa-list"></i> {lang["storyboard.drillhierarchy"]}</a>
                                                                        </li>);
                                                                    }
                                                                })
                                                            }
                                                        })}
                                                        {that.props.allfeatures.map((featuresModule) => {
                                                            if (featuresModule.moduleName == 'my_report') {
                                                                return featuresModule.features.map((feature, index) => {
                                                                    if (feature.featureName == 'viewreport_maximize_custom_range' && feature.allow == true) {
                                                                        return (<li key={index} title="" className="xs-list-item createrange" id="createrangemax">
                                                                            <a href="javascript:void(0)" onClick={this.createCustomRange}><i className="fa fa-arrows-h"></i> {lang["storyboard.customrange"]}</a>
                                                                        </li>);
                                                                    }
                                                                })
                                                            }
                                                        })}
                                                    </ul>
                                                </legend>
                                            </div>
                                            <div className="container-fluid marg-top-5">
                                                <input className="form-control form-control-dimensions search-image" onKeyUp={that.props.searchDimensions} placeholder={lang["storyboard.search"]} id="searchinput" type="text"></input>
                                            </div>
                                            <div className="scroller">
                                                <ul className="list-group customdimensions"></ul>
                                                <ul className="list-group customhierarchydimensions"></ul>
                                                <ul className="list-group customrangedimension"></ul>
                                                <ul className="list-group dimensions"></ul>
                                            </div>
                                        </div>
                                        {/* <!--\ Dimensions--> */}

                                        {/* <!-- Parameters --> */}
                                        <div className="parametersdiv modalaxiscontainer">
                                            <legend className="subhead fa-grey"><div className="attraxisnames">{lang["storyboard.parameters"]}</div>
                                                <button className="pull-right btn btn-transparent tooltip-left" onClick={that.props.customparameter} data-tooltip={lang["storyboard.createparameter"]} id="customparameter"><i className="fa fa-plus-circle"></i></button>
                                            </legend>
                                            <div className="container-fluid marg-top-5">
                                                <input className="form-control form-control-customparameters search-image" onKeyUp={that.props.searchParameters} placeholder={lang["storyboard.search"]} id="searchinput" type="text"></input>
                                            </div>
                                            <div className="scroller">
                                                <ul className="list-group customparameterfields entitytype"></ul>
                                            </div>
                                        </div>
                                        {/* <!--\ Parameters --> */}
                                    </div>
                                </div>
                                {/* <!---Third Tab Start--> */}
                                <div role="tabpanel" className="tab-pane boc-snapshot-container tabviewitems boc-gray-inner" id="axissortingsettings">
                                    <div className="col-xs-12 settingscontent">
                                        <div className="sortingdiv modalaxiscontainer nonaxis_ct">
                                            <legend className="subhead"><div className="attraxisnames">{lang["storyboard.sortby"]}</div>
                                                <button
                                                    className="pull-right btn btn-transparent dropdown-toggle tooltip-left"
                                                    data-tooltip={lang["storyboard.add"]} id="dropdownsorting" onClick={this.showyAxisDropDowninSorting} aria-expanded="false"
                                                    data-toggle="dropdown">
                                                    <i className="fa fa-plus-circle"></i>
                                                </button>
                                                <ul className="list-group dropdown-menu dropdown-menu-right sortingdropdown" aria-labelledby="dropdownsorting" role="menu"></ul>
                                            </legend>
                                            <div className="marg-top-10">
                                                <ul className="list-group sortingattributes"></ul>
                                                <p className="marg-left-10" style={{ fontSize: "12px", padding: "0 0 0 10px", color: "#999", display: "block", float: "left" }}><i>{lang["storyboard.note"]}</i></p>
                                            </div>
                                        </div>
                                        <div className="rankingdiv modalaxiscontainer nonaxis_ct">
                                            <legend className="subhead fa-grey"><div className="attraxisnames">{lang["storyboard.ranking"]}</div></legend>
                                            <div className="marg-top-10">
                                                <ul className="list-group">
                                                    <div className="row">
                                                        <div className="col-xs-12">
                                                            <select className="sort-attribute-name ordertype" onChange={this.ordertype} id="ordertyperange">
                                                                <option selected="selected">{lang["storyboard.none"]}</option>
                                                                <option name="top" value="top">{lang["storyboard.top"]}</option>
                                                                <option name="bottom" value="bottom">{lang["storyboard.bottom"]}</option>
                                                            </select>
                                                            <input type="number" max="999999999" min="1" defaultValue="20" className="sort-attribute-limit collimit" id="collimitrange" />
                                                            <span className="valueExceedErrorForRanking hide" style={{ color: "red" }}>{lang["storyboard.valueerror"]}</span>
                                                            <span className="negitiveValueErrorForRanking hide" style={{ color: "red" }}>{lang["storyboard.positiverror"]}</span>
                                                            <span className="decimalValueErrorForRanking hide" style={{ color: "red" }}>{lang["storyboard.decimalerror"]}</span>
                                                            <select className="sort-attribute-name rankingitem" id="rankingitemsrange">
                                                            </select>
                                                        </div>
                                                    </div>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* <!-- <div className="modalaxiscontainer">
                                       <legend className="subhead fa-grey">Sort By
                                           <button
                                               className="pull-right btn btn-transparent dropdown-toggle tooltip-left"
                                               data-tooltip="ADD" id="dropdownsorting" aria-expanded="false"
                                               data-toggle="dropdown">
                                               <i className="fa fa-plus-circle"></i>
                                           </button>
                                           <ul className="list-group dropdown-menu axis-add-menu-fix sortingdropdown" aria-labelledby="dropdownsorting" role="menu">
                                               
                                           </ul>
                                       </legend>
                                       <div className="marg-top-10">
                                           <ul className="list-group sortingattributes">
                                           </ul>
                                       </div>
                                   </div>
                                   <div className="modalaxiscontainer">
                                       <legend className="subhead fa-grey">Order By</legend>
                                       <div className="marg-top-10">
                                           <ul className="list-group">
                                               <div className="row">
                                                   <div className="col-xs-12">
                                                       <div className="orderby">
                                                           <input type="radio" value="asc" name="sortby" id="sortby2"> Ascending<br>
                                                           <input type="radio" value="desc" name="sortby" id="sortby1"> Descending
                                                       </div>
                                                   </div>
                                               </div>
                                           </ul>
                                       </div>			
                                   </div> --> */}
                                        {/* <!-- <p className="marg-left-10" style="font-size:12px"><i>Note: Sorting of Data is Disabled when Legend Series is Applied</i></p> --> */}
                                        {/* <!-- <div className="modalaxiscontainer">
                                       <legend className="subhead fa-grey">Ranking</legend>
                                       <div className="marg-top-10">
                                           <ul className="list-group">
                                               <div className="row">
                                                   <div className="col-xs-12">
                                                       <select className="sort-attribute-name ordertype" id="ordertyperange">
                                                           <option selected="selected">None</option>
                                                           <option name="top" value="top">Top</option>
                                                           <option name="bottom" value="bottom">Bottom</option>
                                                       </select>
                                                       <input type="number" max="999999999" min="1" value="20" className="sort-attribute-limit collimit" id="collimitrange">
                                                       <select className="sort-attribute-name rankingitem" id="rankingitemsrange">
                                                       </select>
                                                   </div>
                                               </div>
                                           </ul>
                                       </div>
                                   </div> -->				 */}
                                    </div>
                                </div>
                                {/* <!---Fourth Tab Start--> */}
                                <div role="tabpanel" className="tab-pane boc-snapshot-container tabviewitems boc-gray-inner" id="themesettings">
                                    <div className="col-xs-12 settingscontent">
                                        <div className="colorsdiv modalaxiscontainer nonaxis_ct">
                                            <legend className="subhead"><div className="attraxisnames">{lang["storyboard.colorthemes"]}</div></legend>
                                            <div className="container-fluid nopadding marg-top-10">
                                                <ul role="menu" className="list-of-colors color-schemes-container">
                                                    {(that.props.colorThemes != undefined) ? (
                                                        that.props.colorThemes.map((i, index) => {
                                                            var lactive = "";
                                                            if ((that.props.colorThemeIndex == 0 || that.props.colorThemeIndex == undefined) && index == 0) { lactive = "legendactive" } else if (that.props.colorThemeIndex == index) { lactive = "legendactive" }
                                                            return (
                                                                <div key={index} className="col-lg-12">
                                                                    <div className="legendcpalete selectlegend" onClick={this.selectlegend} id={`colorthemes${index}`}>
                                                                        <i className={`fa fa-circle legendcpselect ${lactive}`} theme-index={index}></i>
                                                                        <div className="col-lg-12">
                                                                            {Array.apply(0, Array(5)).map(function (x, c) {
                                                                                return <div key={c} className="colors" style={{ backgroundColor: i[c] }}></div>;
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })) : ""}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!---Fifth Tab Start--> */}
                                <div role="tabpanel" className="tab-pane boc-snapshot-container tabviewitems boc-gray-inner" id="chartsoptionsfinetuning">
                                    <div className="col-xs-12 settingscontent">
                                        <div className="othersettingsdiv modalaxiscontainer nonaxis_ct">
                                            <legend className="subhead"><div className="attraxisnames">{lang["storyboard.chartsettings"]}</div>
                                                {/* <!-- Advanced Chart Settings --> */}
                                                <button className="pull-right btn btn-transparent tooltip-left" data-num={that.props.chartnumber} tab-id="0" tab-name=""
                                                    data-tooltip={lang["storyboard.advancdchart"]} id="viewadvancedchartsettingsmodal" onClick={() => { PubSub.publish("advancedchartsettingsmodal", this.props.chartnumber) }}>
                                                    <i className="fa fa-cog"></i>
                                                </button>
                                            </legend>
                                            <div className="container-fluid nopadding marg-top-10">
                                                <ul className="list-group">
                                                    <div className="container-fluid">
                                                        <div className="row">
                                                            <div className="col-xs-12">
                                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-7">
                                                                    <span className="toggle-title">{lang["storyboard.zoomstyle"]}</span>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-3 nopadding">
                                                                    <select className="chart_settings pull-right marg-top-5" id="chartZoomSettings">
                                                                        <option value="xy">{lang["storyboard.capxy"]}</option>
                                                                        <option value="x">{lang["storyboard.capx"]}</option>
                                                                        <option value="y">{lang["storyboard.capy"]}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-xs-12">
                                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-7">
                                                                    <span className="toggle-title">{lang["storyboard.charttitle"]}</span>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-3 nopadding">
                                                                    <div className="checkbox checkbox-slider--c pull-right">
                                                                        <>
                                                                            <label>
                                                                                {(that.props.chartSettings.chartTitle == true) ? (
                                                                                    <><input type="checkbox" defaultChecked id="chartTitle" className="chart_settings" /><span>&nbsp;</span></>
                                                                                ) : (
                                                                                        <> <input type="checkbox" id="chartTitle" className="chart_settings" /><span>&nbsp;</span></>
                                                                                    )}
                                                                            </label>
                                                                        </>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-xs-12">
                                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-7">
                                                                    <span className="toggle-title">{lang["storyboard.legend"]}</span>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-3 nopadding">
                                                                    <div className="checkbox checkbox-slider--c pull-right">
                                                                        <label>
                                                                            {(that.props.chartSettings.legend == true) ? (
                                                                                <> <input type="checkbox" defaultChecked id="legend" className="chart_settings" /><span>&nbsp;</span></>
                                                                            ) : (
                                                                                    <><input type="checkbox" id="legend" className="chart_settings" /><span>&nbsp;</span></>
                                                                                )}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-xs-12">
                                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-7">
                                                                    <span className="toggle-title">{lang["storyboard.x-axis"]}</span>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-3 nopadding">
                                                                    <div className="checkbox checkbox-slider--c pull-right">
                                                                        <label>
                                                                            {(that.props.chartSettings.xAxisLabel == true) ? (
                                                                                <> <input type="checkbox" defaultChecked id="xAxisLabel" className="chart_settings" /><span>&nbsp;</span></>
                                                                            ) : (
                                                                                    <> <input type="checkbox" id="xAxisLabel" className="chart_settings" /><span>&nbsp;</span></>
                                                                                )}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-xs-12">
                                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-7">
                                                                    <span className="toggle-title">{lang["storyboard.y-axis"]}</span>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-3 nopadding">
                                                                    <div className="checkbox checkbox-slider--c pull-right">
                                                                        <label>
                                                                            {(that.props.chartSettings.yAxisLabel == true) ? (
                                                                                <> <input type="checkbox" defaultChecked id="yAxisLabel" className="chart_settings" /><span>&nbsp;</span></>
                                                                            ) : (
                                                                                    <> <input type="checkbox" id="yAxisLabel" className="chart_settings" /><span>&nbsp;</span></>
                                                                                )}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-xs-12">
                                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-7">
                                                                    <span className="toggle-title">{lang["storyboard.datalabel"]}</span>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-3 nopadding">
                                                                    <div className="checkbox checkbox-slider--c pull-right">
                                                                        <label>
                                                                            {(that.props.chartSettings.dataLabel == true) ? (
                                                                                <> <input type="checkbox" defaultChecked id="dataLabel" className="chart_settings" /><span>&nbsp;</span></>
                                                                            ) : (
                                                                                    <> <input type="checkbox" id="dataLabel" className="chart_settings" /><span>&nbsp;</span></>
                                                                                )}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-xs-12">
                                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-7">
                                                                    <span className="toggle-title">{lang["storyboard.crosshair"]}</span>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-3 nopadding">
                                                                    <div className="checkbox checkbox-slider--c pull-right">
                                                                        <label>
                                                                            {(that.props.chartSettings.crossHairs == true) ? (
                                                                                <> <input type="checkbox" defaultChecked id="crossHairs" className="chart_settings" /><span>&nbsp;</span></>
                                                                            ) : (
                                                                                    <> <input type="checkbox" id="crossHairs" className="chart_settings" /><span>&nbsp;</span></>
                                                                                )}

                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-xs-12">
                                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-7">
                                                                    <span className="toggle-title">{lang["storyboard.gridline"]}</span>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-3 nopadding">
                                                                    <div className="checkbox checkbox-slider--c pull-right">
                                                                        <label>
                                                                            {(that.props.chartSettings.gridLine == true) ? (
                                                                                <><input type="checkbox" defaultChecked id="gridLine" className="chart_settings" /><span>&nbsp;</span></>
                                                                            ) : (
                                                                                    <> <input type="checkbox" id="gridLine" className="chart_settings" /><span>&nbsp;</span></>
                                                                                )}

                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xs-12">
                                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-7">
                                                                    <span className="toggle-title">{lang["storyboard.referenceline"]}</span>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-3 nopadding">
                                                                    <div className="checkbox checkbox-slider--c pull-right">
                                                                        <label>
                                                                            {(that.props.chartSettings.referenceLine == true) ? (
                                                                                <> <input type="checkbox" defaultChecked id="referenceLine" className="chart_settings" /><span>&nbsp;</span></>
                                                                            ) : (
                                                                                    <> <input type="checkbox" id="referenceLine" className="chart_settings" /><span>&nbsp;</span></>
                                                                                )}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xs-12">
                                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-7">
                                                                    <span className="toggle-title">{lang["storyboard.narrative"]}</span>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-3 nopadding">
                                                                    <div className="checkbox checkbox-slider--c pull-right">
                                                                        <label>
                                                                            {(that.props.chartSettings.chartNarrative == true) ? (
                                                                                <> <input type="checkbox" defaultChecked id="chartNarrative" onChange={this.onChangeChartNarrative} className="chart_settings" /><span>&nbsp;</span></>
                                                                            ) : (
                                                                                    <> <input type="checkbox" id="chartNarrative" onChange={this.onChangeChartNarrative} className="chart_settings" /><span>&nbsp;</span></>
                                                                                )}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xs-12">
                                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-7">
                                                                    <span className="toggle-title">{lang["storyboard.dynamicscaling"]}</span>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-3 nopadding">
                                                                    <div className="checkbox checkbox-slider--c pull-right">
                                                                        <label>
                                                                            {(that.props.chartSettings.trellisDynamicScaling == true) ? (
                                                                                <>	<input type="checkbox" defaultChecked="checked" id="trellisDynamicScaling" className="chart_settings" /><span>&nbsp;</span></>
                                                                            ) :
                                                                                <>	<input type="checkbox" id="trellisDynamicScaling" className="chart_settings" /><span>&nbsp;</span></>
                                                                            }
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xs-12">
                                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-7">
                                                                    <span className="toggle-title">{lang["storyboard.fittowidth"]}</span>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-3 nopadding">
                                                                    <div className="checkbox checkbox-slider--c pull-right">
                                                                        <label>
                                                                            {(that.props.chartSettings.trellisFitToWidth == true) ? (
                                                                                <>	<input type="checkbox" defaultChecked="checked" id="trellisFitToWidth" className="chart_settings" /><span>&nbsp;</span></>
                                                                            ) :
                                                                                <><input type="checkbox" id="trellisFitToWidth" className="chart_settings" /><span>&nbsp;</span></>
                                                                            }
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xs-12">
                                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-7">
                                                                    <span className="toggle-title">{lang["storyboard.pareto"]}</span>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-3 nopadding">
                                                                    <div className="checkbox checkbox-slider--c pull-right">
                                                                        <label>
                                                                            {(that.props.chartSettings.chartPareto == true) ? (
                                                                                <><input type="checkbox" defaultChecked="checked" onChange={this.onChangeChartPareto} id="chartPareto" className="chart_settings" /><span>&nbsp;</span></>
                                                                            ) : (
                                                                                    <><input type="checkbox" id="chartPareto" onChange={this.onChangeChartPareto} className="chart_settings" /><span>&nbsp;</span></>
                                                                                )}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xs-12">
                                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-7">
                                                                    <span className="toggle-title">{lang["storyboard.y-axis scales"]}</span>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-3 nopadding">
                                                                    <div className="checkbox checkbox-slider--c pull-right">
                                                                        <label>
                                                                            {that.props.chartSettings.chartSplitYAxis == true ?
                                                                                <><input type="checkbox" defaultChecked="checked" onChange={this.onChangeChartSplitYAxis} id="chartSplitYAxis" className="chart_settings" /><span>&nbsp;</span></>
                                                                                :
                                                                                <><input type="checkbox" id="chartSplitYAxis" onChange={this.onChangeChartSplitYAxis} className="chart_settings" /><span>&nbsp;</span></>
                                                                            }
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-xs-12">
                                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-7">
                                                                    <span className="toggle-title">{lang["storyboard.equaly-axis"]}</span>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-3 nopadding">
                                                                    <div className="checkbox checkbox-slider--c pull-right">
                                                                        <label>
                                                                            {that.props.chartSettings.chartEqualYAxisScale == true ?
                                                                                <><input type="checkbox" defaultChecked="checked" onChange={this.onChangeChartEqualYAxisScale} id="chartEqualYAxisScale" className="chart_settings" /><span>&nbsp;</span></>
                                                                                :
                                                                                <>	<input type="checkbox" id="chartEqualYAxisScale" onChange={this.onChangeChartEqualYAxisScale} className="chart_settings" /><span>&nbsp;</span></>
                                                                            }
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xs-12">
                                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-7">
                                                                    <span className="toggle-title">{lang["storyboard.heatmapmonocolor"]}</span>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-3 nopadding">
                                                                    <div className="checkbox checkbox-slider--c pull-right">
                                                                        <label>
                                                                            {(that.props.chartSettings.chartHeatmapMonoColor == true) ? (
                                                                                <> <input type="checkbox" defaultChecked id="chartHeatmapMonoColor" onChange={this.onChangeChartHeatmapMonoColor} className="chart_settings" /><span>&nbsp;</span></>
                                                                            ) : (
                                                                                    <> <input type="checkbox" id="chartHeatmapMonoColor" onChange={this.onChangeChartHeatmapMonoColor} className="chart_settings" /><span>&nbsp;</span></>
                                                                                )}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/* <div className="col-xs-12">
                                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-7">
                                                                    <span className="toggle-title">{lang["storyboard.connectnulls"]}</span>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-3 nopadding">
                                                                    <div className="checkbox checkbox-slider--c pull-right">
                                                                        <label>
                                                                            {(that.props.chartSettings.connectNulls == true) ? (
                                                                                <><input type="checkbox" defaultChecked="checked" onChange={this.onChangeConnectNulls} id="connectNulls" className="chart_settings" /><span>&nbsp;</span></>
                                                                            ) : (
                                                                                    <><input type="checkbox" id="connectNulls" onChange={this.onChangeConnectNulls} className="chart_settings" /><span>&nbsp;</span></>
                                                                                )}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* Number Formatt */}
                                        <div className="number-format-container">
                                            <div className="number-format-inside-box">
                                                <legend className="subhead marg-bot-10"><div className="attraxisnames lg">{lang["storyboard.numberformat"]}</div></legend>
                                                <legend className="subhead"><div className="attraxisnames toggle-title">{lang["storyboard.abbrevations"]}</div></legend>
                                                <div className="col-xs-12 marg-top-10">
                                                    <label className="marg-righ-10"><input type="checkbox" id="abbrevationT" name="abbrevation" className="" value="1T" /> {lang["storyboard.1T"]}</label>
                                                    <label className="marg-righ-10"><input type="checkbox" id="abbrevationB" name="abbrevation" className="" value="1B" /> {lang["storyboard.1B"]}</label>
                                                    <label className="marg-righ-10"><input type="checkbox" id="abbrevationM" name="abbrevation" className="" value="1M" /> {lang["storyboard.1M"]}</label>
                                                    <label className="marg-righ-10"><input type="checkbox" id="abbrevationK" name="abbrevation" className="" value="1K" /> {lang["storyboard.1K"]}</label>
                                                </div>
                                                <div className="clear"></div>
                                                <legend className="subhead marg-top-15"><div className="attraxisnames toggle-title">{lang["storyboard.decimalplaces"]}</div></legend>
                                                <div className="clear"></div>
                                                <div className="col-xs-12 marg-top-10">
                                                    <label className="marg-righ-10"><input type="radio" id="decimals0" name="decimals" onClick={this.onChangedecimal} className="decimalclass" defaultValue="0" /> {lang["storyboard.zero"]}</label>
                                                    <label className="marg-righ-10"><input type="radio" id="decimals00" name="decimals" defaultChecked="checked" onClick={this.onChangedecimal} className="decimalclass" defaultValue="00" /> {lang["storyboard.dzero"]}</label>
                                                    <label className="marg-righ-10 marg-top-10 marg-bot-15"><input type="radio" id="decimalsC" name="decimals" className="" defaultValue="custom" /> <input type="number" defaultValue="" className="form-control input-sm" min="0" max="5" id="decimalcustomvalue" onKeyUp={this.validateDecimals} style={{ display: "inline", width: "auto" }} /></label><br></br><span style={{ color: "#ff0000" }} id="errmsgmin"></span>
                                                </div>
                                                <div className="clear"></div>
                                                <legend className="subhead marg-top-15"><div className="attraxisnames toggle-title">{lang["storyboard.thousandssep"]}</div></legend>
                                                <div className="clear"></div>
                                                <div className="col-xs-12 marg-top-10 marg-bot-15">
                                                    <label className="marg-righ-10"><input type="radio" id="thousands1" name="thousands" className="" defaultChecked="checked" defaultValue="thousands1" /> {lang["storyboard.thosand"]}</label>
                                                    <label className="marg-righ-10"><input type="radio" id="thousands2" name="thousands" className="" defaultValue="thousands2" /> {lang["storyboard.dthousand"]}</label>
                                                </div>
                                                <div className="clear"></div>
                                            </div>
                                        </div>
                                        <div className="clear"></div>
                                        <div class="y-axis-options">
                                            <div class="y-axis-scale">
                                                <legend class="subhead"><div class="attraxisnames">{lang["storyboard.y-axismin"]}</div></legend>
                                                <div class="container-fluid marg-top-10 marg-bot-10">
                                                    <input type="number" name="yaxisscaleminvalue" id="yaxisscaleminvalue" class="form-control" placeholder="0" />
                                                </div>
                                                <legend class="subhead"><div class="attraxisnames">{lang["storyboard.y-axismax"]}</div></legend>
                                                <div class="container-fluid marg-top-10 marg-bot-10">
                                                    <input type="number" name="yaxisscalemaxvalue" id="yaxisscalemaxvalue" class="form-control" placeholder="1000" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="metricprefix hide">
                                            <div className="modalaxiscontainer nonaxis_ct">
                                                <div className="metric-options-container">
                                                    <legend className="subhead"><div className="attraxisnames">{lang["storyboard.metricfont"]}</div></legend>
                                                    <div className="container-fluid marg-top-10 marg-bot-10">
                                                        <fieldset className="advancedchartsettings-fieldset metric-customization">
                                                            <div className="col-xs-12 nopadding">
                                                                <div className="table-responsive">
                                                                    <table className="table table-custom">
                                                                        <tbody>
                                                                            <tr className="fontstyle">
                                                                                <td>
                                                                                    {/* <!-- FontFamily --> */}
                                                                                    <select className="fontfamily" id="metricfontfamily">
                                                                                        <option>{lang["storyboard.choosefont"]}</option>
                                                                                        <option value="&quot;Lato&quot;, &quot;Source Sans Pro&quot;,sans-serif" selected="">{lang["storyboard.lato"]}</option>
                                                                                        <option value="&quot;Source Sans Pro&quot;,sans-serif">{lang["storyboard.sanspro"]}</option>
                                                                                        <option value="verdana">{lang["storyboard.verdana"]}</option>
                                                                                        <option value="arial">{lang["storyboard.arial"]}</option>
                                                                                        <option value="tahoma">{lang["storyboard.tahoma"]}</option>
                                                                                        <option value="times new roman">{lang["storyboard.timesroman"]}</option>
                                                                                        <option value="cursive">{lang["storyboard.cursive"]}</option>
                                                                                        <option value="monospace">{lang["storyboard.monospace"]}</option>
                                                                                        <option vlaue="fantasy">{lang["storyboard.fantasy"]}</option>
                                                                                    </select>
                                                                                    {/* <!-- Font Size -->	 */}
                                                                                    <div className="grouped-input">
                                                                                        <input type="number" id="metricdatafontsize" className="small-input fontsize" defaultValue="28"></input>
                                                                                        <select className="fontsizeparameter" id="metricfontsizeparametertext">
                                                                                            <option value="px" selected="">{lang["storyboard.px"]}</option>
                                                                                            <option value="em">{lang["storyboard.em"]}</option>
                                                                                            <option value="rem">{lang["storyboard.rem"]}</option>
                                                                                        </select>
                                                                                    </div>
                                                                                    {/* <!-- Font Style --> */}
                                                                                    <div className="btn-group" data-toggle="buttons">
                                                                                        <label className="btn btn-bird-group" id="metricboldtext" title={lang["storyboard.bold"]}>
                                                                                            <input type="checkbox" className="metricfontweight" autoComplete="off" />
                                                                                            <i className="fa fa-bold fa-solid"></i>
                                                                                        </label>
                                                                                        <label className="btn btn-bird-group" id="metricitalictext" title={lang["storyboard.italic"]}>
                                                                                            <input type="checkbox" className="metricfontitalic" autoComplete="off" />
                                                                                            <i className="fa fa-italic fa-solid"></i>
                                                                                        </label>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="fontstyle">
                                                                                <td>
                                                                                    {/* <!-- Font Color --> */}
                                                                                    <div className="input-group metric-font-color-selector colorpicker-element">
                                                                                        <input type="text" id="metricfontcolor" placeholder="#00bcd4" defaultValue="" className="form-control fontcolor" />
                                                                                        <span className="input-group-addon" id="metricfontcolor">
                                                                                            <i style={{ backgroundColor: "#00bcd4" }}></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    </div>
                                                </div>
                                                {/* <!-- </div> --> */}

                                                {/* <!-- <div className="modalaxiscontainer nonaxis_ct">
                                           
                                           <div className="metricsuffix hide">
                                               <legend className="subhead"><div className="attraxisnames">Metric Suffix</div></legend>
                                               <div className="container-fluid marg-top-10 marg-bot-10">
                                                   <input type="text" name="suffix" id="metricsuffix" className="form-control metric-prifix"/>
                                               </div>
                                           </div>
                                       </div> --> */}
                                                {/* <!-- <div className="modalaxiscontainer nonaxis_ct"> --> */}
                                                <div className="metric-options-container">
                                                    <legend className="subhead"><div className="attraxisnames">{lang["storyboard.metricprefix"]}</div></legend>
                                                    <div className="container-fluid marg-top-10 marg-bot-10">
                                                        <input type="text" name="prifix" id="metricprefix" className="form-control metric-prifix" placeholder="" onKeyUp={that.props.metricprefixCheck}/>
                                                        <span className="help-block metricprefixerror hidden"></span>
                                                    </div>
                                                    {/* <!-- <legend className="subhead"><div className="attraxisnames">Prefix Font</div></legend> --> */}
                                                    <div className="container-fluid marg-top-10 marg-bot-10">
                                                        <fieldset className="advancedchartsettings-fieldset metric-customization">
                                                            <div className="col-xs-12 nopadding">
                                                                <div className="table-responsive">
                                                                    <table className="table table-custom">
                                                                        <tbody>
                                                                            <tr className="fontstyle">
                                                                                <td>
                                                                                    {/* <!-- FontFamily --> */}
                                                                                    <select className="fontfamily" id="metricprefixfontfamily">
                                                                                        <option>{lang["storyboard.choosefont"]}</option>
                                                                                        <option value="&quot;Lato&quot;, &quot;Source Sans Pro&quot;,sans-serif" selected="">{lang["storyboard.lato"]}</option>
                                                                                        <option value="&quot;Source Sans Pro&quot;,sans-serif">{lang["storyboard.sanspro"]}</option>
                                                                                        <option value="verdana">{lang["storyboard.verdana"]}</option>
                                                                                        <option value="arial">{lang["storyboard.arial"]}</option>
                                                                                        <option value="tahoma">{lang["storyboard.tahoma"]}</option>
                                                                                        <option value="times new roman">{lang["storyboard.timesroman"]}</option>
                                                                                        <option value="cursive">{lang["storyboard.cursive"]}</option>
                                                                                        <option value="monospace">{lang["storyboard.monospace"]}</option>
                                                                                        <option vlaue="fantasy">{lang["storyboard.fantasy"]}</option>
                                                                                    </select>
                                                                                    {/* <!-- Font Size -->	 */}
                                                                                    <div className="grouped-input">
                                                                                        <input type="number" id="metricprefixdatafontsize" className="small-input fontsize" defaultValue="28"></input>
                                                                                        <select className="fontsizeparameter" id="metricprefixfontsizeparametertext">
                                                                                            <option value="px" selected="">{lang["storyboard.px"]}</option>
                                                                                            <option value="em">{lang["storyboard.em"]}</option>
                                                                                            <option value="rem">{lang["storyboard.rem"]}</option>
                                                                                        </select>
                                                                                    </div>
                                                                                    {/* <!-- Font Style --> */}
                                                                                    <div className="btn-group" data-toggle="buttons">
                                                                                        <label className="btn btn-bird-group" id="metricprefixboldtext" title={lang["storyboard.bold"]}>
                                                                                            <input type="checkbox" className="metricprefixfontweight" autoComplete="off" />
                                                                                            <i className="fa fa-bold fa-solid"></i>
                                                                                        </label>
                                                                                        <label className="btn btn-bird-group" id="metricprefixitalictext" title={lang["storyboard.italic"]}>
                                                                                            <input type="checkbox" className="metricprefixfontitalic" autoComplete="off" />
                                                                                            <i className="fa fa-italic fa-solid"></i>
                                                                                        </label>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="fontstyle">
                                                                                <td>
                                                                                    {/* <!-- Font Color --> */}
                                                                                    <div className="input-group metric-font-color-selector colorpicker-element">
                                                                                        <input type="text" id="metricprefixfontcolor" placeholder="#00bcd4" defaultValue="" className="form-control fontcolor" />
                                                                                        <span className="input-group-addon" id="metricprefixfontcolor">
                                                                                            <i style={{ backgroundColor: "#00bcd4" }}></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    </div>
                                                </div>
                                                {/* <!-- </div>
                                       
                                       <div className="modalaxiscontainer nonaxis_ct"> --> */}
                                                <div className="metric-options-container">
                                                    <legend className="subhead"><div className="attraxisnames">{lang["storyboard.metricsuffix"]}</div></legend>
                                                    <div className="container-fluid marg-top-10 marg-bot-10">
                                                        <input type="text" name="suffix" id="metricsuffix" className="form-control metric-prifix" onKeyUp={that.props.metricsuffixCheck} />
                                                        <span className="help-block metricsuffixerror hidden"></span>
                                                    </div>
                                                    {/* <!-- <legend className="subhead"><div className="attraxisnames">Suffix Font</div></legend> --> */}
                                                    <div className="container-fluid marg-top-10 marg-bot-10">
                                                        <fieldset className="advancedchartsettings-fieldset metric-customization">
                                                            <div className="col-xs-12 nopadding">
                                                                <div className="table-responsive">
                                                                    <table className="table table-custom">
                                                                        <tbody>
                                                                            <tr className="fontstyle">
                                                                                <td>
                                                                                    {/* <!-- FontFamily --> */}
                                                                                    <select className="fontfamily" id="metricsuffixfontfamily">
                                                                                        <option>{lang["storyboard.choosefont"]}</option>
                                                                                        <option value="&quot;Lato&quot;, &quot;Source Sans Pro&quot;,sans-serif" selected="">{lang["storyboard.lato"]}</option>
                                                                                        <option value="&quot;Source Sans Pro&quot;,sans-serif">{lang["storyboard.sanspro"]}</option>
                                                                                        <option value="verdana">{lang["storyboard.verdana"]}</option>
                                                                                        <option value="arial">{lang["storyboard.arial"]}</option>
                                                                                        <option value="tahoma">{lang["storyboard.tahoma"]}</option>
                                                                                        <option value="times new roman">{lang["storyboard.timesroman"]}</option>
                                                                                        <option value="cursive">{lang["storyboard.cursive"]}</option>
                                                                                        <option value="monospace">{lang["storyboard.monospace"]}</option>
                                                                                        <option vlaue="fantasy">{lang["storyboard.fantasy"]}</option>
                                                                                    </select>
                                                                                    {/* <!-- Font Size -->	 */}
                                                                                    <div className="grouped-input">
                                                                                        <input type="number" id="metricsuffixdatafontsize" className="small-input fontsize" defaultValue="28"></input>
                                                                                        <select className="fontsizeparameter" id="metricsuffixfontsizeparametertext">
                                                                                            <option value="px" selected="">{lang["storyboard.px"]}</option>
                                                                                            <option value="em">{lang["storyboard.em"]}</option>
                                                                                            <option value="rem">{lang["storyboard.rem"]}</option>
                                                                                        </select>
                                                                                    </div>
                                                                                    {/* <!-- Font Style --> */}
                                                                                    <div className="btn-group" data-toggle="buttons">
                                                                                        <label className="btn btn-bird-group" id="metricsuffixboldtext" title={lang["storyboard.bold"]}>
                                                                                            <input type="checkbox" className="metricsuffixfontweight" autoComplete="off" />
                                                                                            <i className="fa fa-bold fa-solid"></i>
                                                                                        </label>
                                                                                        <label className="btn btn-bird-group" id="metricsuffixitalictext" title={lang["storyboard.italic"]}>
                                                                                            <input type="checkbox" className="metricsuffixfontitalic" autoComplete="off" />
                                                                                            <i className="fa fa-italic fa-solid"></i>
                                                                                        </label>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="fontstyle">
                                                                                <td>
                                                                                    {/* <!-- Font Color --> */}
                                                                                    <div className="input-group metric-font-color-selector colorpicker-element">
                                                                                        <input type="text" id="metricsuffixfontcolor" placeholder="#00bcd4" defaultValue="" className="form-control fontcolor" />
                                                                                        <span className="input-group-addon" id="metricsuffixfontcolor">
                                                                                            <i style={{ backgroundColor: "#00bcd4" }}></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        {/* <!-- Metric Layout 1 Target Colors --> */}
                                        <div className="metriclayout hide" layout-id="1">
                                            <div className="modalaxiscontainer nonaxis_ct">
                                                <div className="metric-options-container">
                                                    <legend className="subhead"><div className="attraxisnames">{lang["storyboard.customfont"]}</div></legend>
                                                    <div className="container-fluid marg-top-10 marg-bot-10">
                                                        <fieldset className="advancedchartsettings-fieldset metric-customization">
                                                            <div className="col-xs-12 nopadding">
                                                                <div className="table-responsive">
                                                                    <table className="table table-custom">
                                                                        <tbody>
                                                                            <tr className="fontstyle">
                                                                                <td>
                                                                                    {/* <!-- FontFamily --> */}
                                                                                    <select className="fontfamily" id="metrictargetfontfamily">
                                                                                        <option>{lang["storyboard.choosefont"]}</option>
                                                                                        <option value="&quot;Lato&quot;, &quot;Source Sans Pro&quot;,sans-serif" selected="">{lang["storyboard.lato"]}</option>
                                                                                        <option value="&quot;Source Sans Pro&quot;,sans-serif">{lang["storyboard.sanspro"]}</option>
                                                                                        <option value="verdana">{lang["storyboard.verdana"]}</option>
                                                                                        <option value="arial">{lang["storyboard.arial"]}</option>
                                                                                        <option value="tahoma">{lang["storyboard.tahoma"]}</option>
                                                                                        <option value="times new roman">{lang["storyboard.timesroman"]}</option>
                                                                                        <option value="cursive">{lang["storyboard.cursive"]}</option>
                                                                                        <option value="monospace">{lang["storyboard.monospace"]}</option>
                                                                                        <option vlaue="fantasy">{lang["storyboard.fantasy"]}</option>
                                                                                    </select>
                                                                                    {/* <!-- Font Size -->	 */}
                                                                                    <div className="grouped-input">
                                                                                        <input type="number" id="metrictargetdatafontsize" className="small-input fontsize" value="28"></input>
                                                                                        <select className="fontsizeparameter" id="metrictargetfontsizeparametertext">
                                                                                            <option value="px" selected="">{lang["storyboard.px"]}</option>
                                                                                            <option value="em">{lang["storyboard.em"]}</option>
                                                                                            <option value="rem">{lang["storyboard.rem"]}</option>
                                                                                        </select>
                                                                                    </div>
                                                                                    {/* <!-- Font Style --> */}
                                                                                    <div className="btn-group" data-toggle="buttons">
                                                                                        <label className="btn btn-bird-group" id="metrictargetboldtext" title={lang["storyboard.bold"]}>
                                                                                            <input type="checkbox" className="metrictargetfontweight" autoComplete="off" />
                                                                                            <i className="fa fa-bold fa-solid"></i>
                                                                                        </label>
                                                                                        <label className="btn btn-bird-group" id="metrictargetitalictext" title={lang["storyboard.italic"]}>
                                                                                            <input type="checkbox" className="metrictargetfontitalic" autoComplete="off" />
                                                                                            <i className="fa fa-italic fa-solid"></i>
                                                                                        </label>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="fontstyle">
                                                                                <td>
                                                                                    {/* <!-- Font Color --> */}
                                                                                    <div className="input-group metric-font-color-selector colorpicker-element">
                                                                                        <input type="text" id="metrictargetfontcolor" placeholder={lang["storyboard.#2737a3"]} value="" className="form-control fontcolor" />
                                                                                        <span className="input-group-addon" id="metrictargetfontcolor">
                                                                                            <i style={{ backgroundColor: "#2737a3" }}></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    </div>
                                                </div>
                                                <div className="metric-options-container">
                                                    <legend className="subhead"><div className="attraxisnames">{lang["storyboard.customprefix"]}</div></legend>
                                                    <div className="container-fluid marg-top-10 marg-bot-10">
                                                        <input type="text" name="prifix" id="metrictargetprefix" className="form-control metric-prifix" />
                                                    </div>
                                                    <div className="container-fluid marg-top-10 marg-bot-10">
                                                        <fieldset className="advancedchartsettings-fieldset metric-customization">
                                                            <div className="col-xs-12 nopadding">
                                                                <div className="table-responsive">
                                                                    <table className="table table-custom">
                                                                        <tbody>
                                                                            <tr className="fontstyle">
                                                                                <td>
                                                                                    {/* <!-- FontFamily --> */}
                                                                                    <select className="fontfamily" id="metrictargetprefixfontfamily">
                                                                                        <option>{lang["storyboard.choosefont"]}</option>
                                                                                        <option value="&quot;Lato&quot;, &quot;Source Sans Pro&quot;,sans-serif" selected="">{lang["storyboard.lato"]}</option>
                                                                                        <option value="&quot;Source Sans Pro&quot;,sans-serif">{lang["storyboard.sanspro"]}</option>
                                                                                        <option value="verdana">{lang["storyboard.verdana"]}</option>
                                                                                        <option value="arial">{lang["storyboard.arial"]}</option>
                                                                                        <option value="tahoma">{lang["storyboard.tahoma"]}</option>
                                                                                        <option value="times new roman">{lang["storyboard.timesroman"]}</option>
                                                                                        <option value="cursive">{lang["storyboard.cursive"]}</option>
                                                                                        <option value="monospace">{lang["storyboard.monospace"]}</option>
                                                                                        <option vlaue="fantasy">{lang["storyboard.fantasy"]}</option>
                                                                                    </select>
                                                                                    {/* <!-- Font Size -->	 */}
                                                                                    <div className="grouped-input">
                                                                                        <input type="number" id="metrictargetprefixdatafontsize" className="small-input fontsize" value="28"></input>
                                                                                        <select className="fontsizeparameter" id="metrictargetprefixfontsizeparametertext">
                                                                                            <option value="px" selected="">{lang["storyboard.px"]}</option>
                                                                                            <option value="em">{lang["storyboard.em"]}</option>
                                                                                            <option value="rem">{lang["storyboard.rem"]}</option>
                                                                                        </select>
                                                                                    </div>
                                                                                    {/* <!-- Font Style --> */}
                                                                                    <div className="btn-group" data-toggle="buttons">
                                                                                        <label className="btn btn-bird-group" id="metrictargetprefixboldtext" title={lang["storyboard.bold"]}>
                                                                                            <input type="checkbox" className="metrictargetprefixfontweight" autoComplete="off" />
                                                                                            <i className="fa fa-bold fa-solid"></i>
                                                                                        </label>
                                                                                        <label className="btn btn-bird-group" id="metrictargetprefixitalictext" title={lang["storyboard.italic"]}>
                                                                                            <input type="checkbox" className="metrictargetprefixfontitalic" autoComplete="off" />
                                                                                            <i className="fa fa-italic fa-solid"></i>
                                                                                        </label>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="fontstyle">
                                                                                <td>
                                                                                    {/* <!-- Font Color --> */}
                                                                                    <div className="input-group metric-font-color-selector colorpicker-element">
                                                                                        <input type="text" id="metrictargetprefixfontcolor" placeholder={lang["storyboard.#2737a3"]} value="" className="form-control fontcolor" />
                                                                                        <span className="input-group-addon" id="metrictargetprefixfontcolor">
                                                                                            <i style={{ backgroundColor: "#2737a3" }}></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    </div>
                                                </div>
                                                <div className="metric-options-container">
                                                    <legend className="subhead"><div className="attraxisnames">{lang["storyboard.customsuffix"]}</div></legend>
                                                    <div className="container-fluid marg-top-10 marg-bot-10">
                                                        <input type="text" name="suffix" id="metrictargetsuffix" className="form-control metric-prifix" value="Target" />
                                                    </div>
                                                    <div className="container-fluid marg-top-10 marg-bot-10">
                                                        <fieldset className="advancedchartsettings-fieldset metric-customization">
                                                            <div className="col-xs-12 nopadding">
                                                                <div className="table-responsive">
                                                                    <table className="table table-custom">
                                                                        <tbody>
                                                                            <tr className="fontstyle">
                                                                                <td>
                                                                                    {/* <!-- FontFamily --> */}
                                                                                    <select className="fontfamily" id="metrictargetsuffixfontfamily">
                                                                                        <option>{lang["storyboard.choosefont"]}</option>
                                                                                        <option value="&quot;Lato&quot;, &quot;Source Sans Pro&quot;,sans-serif" selected="">{lang["storyboard.lato"]}</option>
                                                                                        <option value="&quot;Source Sans Pro&quot;,sans-serif">{lang["storyboard.sanspro"]}</option>
                                                                                        <option value="verdana">{lang["storyboard.verdana"]}</option>
                                                                                        <option value="arial">{lang["storyboard.arial"]}</option>
                                                                                        <option value="tahoma">{lang["storyboard.tahoma"]}</option>
                                                                                        <option value="times new roman">{lang["storyboard.timesroman"]}</option>
                                                                                        <option value="cursive">{lang["storyboard.cursive"]}</option>
                                                                                        <option value="monospace">{lang["storyboard.monospace"]}</option>
                                                                                        <option vlaue="fantasy">{lang["storyboard.fantasy"]}</option>
                                                                                    </select>
                                                                                    {/* <!-- Font Size -->	 */}
                                                                                    <div className="grouped-input">
                                                                                        <input type="number" id="metrictargetsuffixdatafontsize" className="small-input fontsize" value="28"></input>
                                                                                        <select className="fontsizeparameter" id="metrictargetsuffixfontsizeparametertext">
                                                                                            <option value="px" selected="">{lang["storyboard.px"]}</option>
                                                                                            <option value="em">{lang["storyboard.em"]}</option>
                                                                                            <option value="rem">{lang["storyboard.rem"]}</option>
                                                                                        </select>
                                                                                    </div>
                                                                                    {/* <!-- Font Style --> */}
                                                                                    <div className="btn-group" data-toggle="buttons">
                                                                                        <label className="btn btn-bird-group" id="metrictargetsuffixboldtext" title={lang["storyboard.bold"]}>
                                                                                            <input type="checkbox" className="metrictargetsuffixfontweight" autoComplete="off" />
                                                                                            <i className="fa fa-bold fa-solid"></i>
                                                                                        </label>
                                                                                        <label className="btn btn-bird-group" id="metrictargetsuffixitalictext" title={lang["storyboard.italic"]}>
                                                                                            <input type="checkbox" className="metrictargetsuffixfontitalic" autoComplete="off" />
                                                                                            <i className="fa fa-italic fa-solid"></i>
                                                                                        </label>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="fontstyle">
                                                                                <td>
                                                                                    {/* <!-- Font Color --> */}
                                                                                    <div className="input-group metric-font-color-selector colorpicker-element">
                                                                                        <input type="text" id="metrictargetsuffixfontcolor" placeholder={lang["storyboard.#a6ace3"]} value="#a6ace3" className="form-control fontcolor" />
                                                                                        <span className="input-group-addon" id="metrictargetsuffixfontcolor">
                                                                                            <i style={{ backgroundColor: "#a6ace3" }}></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        {/* <!--\ Metric Layout 1 Target Colors --> */}

                                        {/* <!-- Metric Layout 1 Achievement Colors --> */}
                                        <div className="metriclayout hide" layout-id="1">
                                            <div className="modalaxiscontainer nonaxis_ct">
                                                <div className="metric-options-container">
                                                    <legend className="subhead"><div className="attraxisnames">{lang["storyboard.achievefont"]}</div></legend>
                                                    <div className="container-fluid marg-top-10 marg-bot-10">
                                                        <fieldset className="advancedchartsettings-fieldset metric-customization">
                                                            <div className="col-xs-12 nopadding">
                                                                <div className="table-responsive">
                                                                    <table className="table table-custom">
                                                                        <tbody>
                                                                            <tr className="fontstyle">
                                                                                <td>
                                                                                    {/* <!-- FontFamily --> */}
                                                                                    <select className="fontfamily" id="metricachievementfontfamily">
                                                                                        <option>{lang["storyboard.choosefont"]}</option>
                                                                                        <option value="&quot;Lato&quot;, &quot;Source Sans Pro&quot;,sans-serif" selected="">{lang["storyboard.lato"]}</option>
                                                                                        <option value="&quot;Source Sans Pro&quot;,sans-serif">{lang["storyboard.sanspro"]}</option>
                                                                                        <option value="verdana">{lang["storyboard.verdana"]}</option>
                                                                                        <option value="arial">{lang["storyboard.arial"]}</option>
                                                                                        <option value="tahoma">{lang["storyboard.tahoma"]}</option>
                                                                                        <option value="times new roman">{lang["storyboard.timesroman"]}</option>
                                                                                        <option value="cursive">{lang["storyboard.cursive"]}</option>
                                                                                        <option value="monospace">{lang["storyboard.monospace"]}</option>
                                                                                        <option vlaue="fantasy">{lang["storyboard.fantasy"]}</option>
                                                                                    </select>
                                                                                    {/* <!-- Font Size -->	 */}
                                                                                    <div className="grouped-input">
                                                                                        <input type="number" id="metricachievementdatafontsize" className="small-input fontsize" value="28"></input>
                                                                                        <select className="fontsizeparameter" id="metricachievementfontsizeparametertext">
                                                                                            <option value="px" selected="">{lang["storyboard.px"]}</option>
                                                                                            <option value="em">{lang["storyboard.em"]}</option>
                                                                                            <option value="rem">{lang["storyboard.rem"]}</option>
                                                                                        </select>
                                                                                    </div>
                                                                                    {/* <!-- Font Style --> */}
                                                                                    <div className="btn-group" data-toggle="buttons">
                                                                                        <label className="btn btn-bird-group" id="metricachievementboldtext" title={lang["storyboard.bold"]}>
                                                                                            <input type="checkbox" className="metricachievementfontweight" autoComplete="off" />
                                                                                            <i className="fa fa-bold fa-solid"></i>
                                                                                        </label>
                                                                                        <label className="btn btn-bird-group" id="metricachievementitalictext" title={lang["storyboard.italic"]}>
                                                                                            <input type="checkbox" className="metricachievementfontitalic" autoComplete="off" />
                                                                                            <i className="fa fa-italic fa-solid"></i>
                                                                                        </label>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="fontstyle">
                                                                                <td>
                                                                                    {/* <!-- Font Color --> */}
                                                                                    <div className="input-group metric-font-color-selector colorpicker-element">
                                                                                        <input type="text" id="metricachievementfontcolor" placeholder={lang["storyboard.#2737a3"]} value="" className="form-control fontcolor" />
                                                                                        <span className="input-group-addon" id="metricachievementfontcolor">
                                                                                            <i style={{ backgroundColor: "#2737a3" }}></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    </div>
                                                </div>
                                                <div className="metric-options-container">
                                                    <legend className="subhead"><div className="attraxisnames">{lang["storyboard.achievesymbol"]}</div></legend>
                                                    <div className="container-fluid marg-top-10 marg-bot-10">
                                                        <input type="text" name="prifix" id="metricachievementprefix" className="form-control metric-prifix" value="%" />
                                                    </div>
                                                    <div className="container-fluid marg-top-10 marg-bot-10">
                                                        <fieldset className="advancedchartsettings-fieldset metric-customization">
                                                            <div className="col-xs-12 nopadding">
                                                                <div className="table-responsive">
                                                                    <table className="table table-custom">
                                                                        <tbody>
                                                                            <tr className="fontstyle">
                                                                                <td>
                                                                                    {/* <!-- FontFamily --> */}
                                                                                    <select className="fontfamily" id="metricachievementprefixfontfamily">
                                                                                        <option>{lang["storyboard.choosefont"]}</option>
                                                                                        <option value="&quot;Lato&quot;, &quot;Source Sans Pro&quot;,sans-serif" selected="">{lang["storyboard.lato"]}</option>
                                                                                        <option value="&quot;Source Sans Pro&quot;,sans-serif">{lang["storyboard.sanspro"]}</option>
                                                                                        <option value="verdana">{lang["storyboard.verdana"]}</option>
                                                                                        <option value="arial">{lang["storyboard.arial"]}</option>
                                                                                        <option value="tahoma">{lang["storyboard.tahoma"]}</option>
                                                                                        <option value="times new roman">{lang["storyboard.timesroman"]}</option>
                                                                                        <option value="cursive">{lang["storyboard.cursive"]}</option>
                                                                                        <option value="monospace">{lang["storyboard.monospace"]}</option>
                                                                                        <option vlaue="fantasy">{lang["storyboard.fantasy"]}</option>
                                                                                    </select>
                                                                                    {/* <!-- Font Size -->	 */}
                                                                                    <div className="grouped-input">
                                                                                        <input type="number" id="metricachievementprefixdatafontsize" className="small-input fontsize" value="16"></input>
                                                                                        <select className="fontsizeparameter" id="metricachievementprefixfontsizeparametertext">
                                                                                            <option value="px" selected="">{lang["storyboard.px"]}</option>
                                                                                            <option value="em">{lang["storyboard.em"]}</option>
                                                                                            <option value="rem">{lang["storyboard.rem"]}</option>
                                                                                        </select>
                                                                                    </div>
                                                                                    {/* <!-- Font Style --> */}
                                                                                    <div className="btn-group" data-toggle="buttons">
                                                                                        <label className="btn btn-bird-group" id="metricachievementprefixboldtext" title={lang["storyboard.bold"]}>
                                                                                            <input type="checkbox" className="metricachievementprefixfontweight" autoComplete="off" />
                                                                                            <i className="fa fa-bold fa-solid"></i>
                                                                                        </label>
                                                                                        <label className="btn btn-bird-group" id="metricachievementprefixitalictext" title={lang["storyboard.italic"]}>
                                                                                            <input type="checkbox" className="metricachievementprefixfontitalic" autoComplete="off" />
                                                                                            <i className="fa fa-italic fa-solid"></i>
                                                                                        </label>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="fontstyle">
                                                                                <td>
                                                                                    {/* <!-- Font Color --> */}
                                                                                    <div className="input-group metric-font-color-selector colorpicker-element">
                                                                                        <input type="text" id="metricachievementprefixfontcolor" placeholder={lang["storyboard.#2737a3"]} value="" className="form-control fontcolor" />
                                                                                        <span className="input-group-addon" id="metricachievementprefixfontcolor">
                                                                                            <i style={{ backgroundColor: "#2737a3" }}></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    </div>
                                                </div>
                                                <div className="metric-options-container" style={{ marginBottom: "120px" }}>
                                                    <legend className="subhead"><div className="attraxisnames">{lang["storyboard.achievesuffix"]}</div></legend>
                                                    <div className="container-fluid marg-top-10 marg-bot-10">
                                                        <input type="text" name="suffix" id="metricachievementsuffix" className="form-control metric-prifix" value="Achievement" />
                                                    </div>
                                                    <div className="container-fluid marg-top-10 marg-bot-10">
                                                        <fieldset className="advancedchartsettings-fieldset metric-customization">
                                                            <div className="col-xs-12 nopadding">
                                                                <div className="table-responsive">
                                                                    <table className="table table-custom">
                                                                        <tbody>
                                                                            <tr className="fontstyle">
                                                                                <td>
                                                                                    {/* <!-- FontFamily --> */}
                                                                                    <select className="fontfamily" id="metricachievementsuffixfontfamily">
                                                                                        <option>{lang["storyboard.choosefont"]}</option>
                                                                                        <option value="&quot;Lato&quot;, &quot;Source Sans Pro&quot;,sans-serif" selected="">{lang["storyboard.lato"]}</option>
                                                                                        <option value="&quot;Source Sans Pro&quot;,sans-serif">{lang["storyboard.sanspro"]}</option>
                                                                                        <option value="verdana">{lang["storyboard.verdana"]}</option>
                                                                                        <option value="arial">{lang["storyboard.arial"]}</option>
                                                                                        <option value="tahoma">{lang["storyboard.tahoma"]}</option>
                                                                                        <option value="times new roman">{lang["storyboard.timesroman"]}</option>
                                                                                        <option value="cursive">{lang["storyboard.cursive"]}</option>
                                                                                        <option value="monospace">{lang["storyboard.monospace"]}</option>
                                                                                        <option vlaue="fantasy">{lang["storyboard.fantasy"]}</option>
                                                                                    </select>
                                                                                    {/* <!-- Font Size -->	 */}
                                                                                    <div className="grouped-input">
                                                                                        <input type="number" id="metricachievementsuffixdatafontsize" className="small-input fontsize" value="28"></input>
                                                                                        <select className="fontsizeparameter" id="metricachievementsuffixfontsizeparametertext">
                                                                                            <option value="px" selected="">{lang["storyboard.px"]}</option>
                                                                                            <option value="em">{lang["storyboard.em"]}</option>
                                                                                            <option value="rem">{lang["storyboard.rem"]}</option>
                                                                                        </select>
                                                                                    </div>
                                                                                    {/* <!-- Font Style --> */}
                                                                                    <div className="btn-group" data-toggle="buttons">
                                                                                        <label className="btn btn-bird-group" id="metricachievementsuffixboldtext" title={lang["storyboard.bold"]}>
                                                                                            <input type="checkbox" className="metricachievementsuffixfontweight" autoComplete="off" />
                                                                                            <i className="fa fa-bold fa-solid"></i>
                                                                                        </label>
                                                                                        <label className="btn btn-bird-group" id="metricachievementsuffixitalictext" title={lang["storyboard.italic"]}>
                                                                                            <input type="checkbox" className="metricachievementsuffixfontitalic" autoComplete="off" />
                                                                                            <i className="fa fa-italic fa-solid"></i>
                                                                                        </label>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="fontstyle">
                                                                                <td>
                                                                                    {/* <!-- Font Color --> */}
                                                                                    <div className="input-group metric-font-color-selector colorpicker-element">
                                                                                        <input type="text" id="metricachievementsuffixfontcolor" placeholder={lang["storyboard.#a6ace3"]} value="#a6ace3" className="form-control fontcolor"></input>
                                                                                        <span className="input-group-addon" id="metricachievementsuffixfontcolor">
                                                                                            <i style={{ backgroundColor: "#a6ace3" }}></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        {/* <!--\ Metric Layout 1 Achievement Colors --> */}
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 pull-left text-right actionscontainercolumn">
                        <button type="button" className="btn btn-bird cancelmodal" data-num={that.props.chartnumber} onClick={this.cancelmodal} tab-id="0" tab-name=""><i className="fa fa-times"></i> {lang["storyboard.cancelbtn"]}</button>
                        <button type="button" className="btn btn-bird btn-highlight applyattributes" onClick={that.props.applyAttributesAndDrawChart} id="applyattributesatyle" aria-hidden="true" data-num={that.props.chartnumber} tab-id="0" tab-name=""><i className="fa fa-check"></i> {lang["storyboard.applybtn"]}</button>
                    </div>
                </div>
            </div>
        );
    }
    cancelmodal(e) {
        e.preventDefault();
        var $this = e.currentTarget;
        var chartnum = $($this).attr("data-num");
        if ($(".rightattributes.open").length === 1) {
            $('body').removeClass('rightpanelopen');
            $(".rightattributes").removeClass("open");
            $(".maxanim1").removeClass("highlight");
            
            //unMountElelements
			if($('ul.dimensions>div').length>0){
				_.each($('ul.dimensions>div'),function(el){
					if(el!=null && el!=undefined){
						window.ReactDOM.unmountComponentAtNode(el)
					}
				});
                $('ul.dimensions').empty();
			}
			if($('ul.measures>div').length>0){
				_.each($('ul.measures>div'),function(el){
					if(el!=null && el!=undefined){
						window.ReactDOM.unmountComponentAtNode(el)
					}
				});
                $('ul.measures').empty();
			}
			if($(".rightattributes>div.dynodiv").length!=0){
				window.ReactDOM.unmountComponentAtNode(document.getElementById($(".rightattributes>div.dynodiv").attr("id")));
                $(".rightattributes>div.dynodiv").remove();
            }

            var currentChart = $(".charttype.active").attr("id");
            if (currentChart != undefined && (currentChart == "bartimeseries" || currentChart == "linetimeseries" || currentChart == "areatimeseries")) {
                $('.highcharts-range-selector.hasDatepicker').css({ width: "1px", height: "1px" });
            }
            $(".leftpane").removeClass("slidetoleft");
            var that = this;
            var currentTile = $(".fullscreenmain");
            setTimeout(function () {
                $('body').removeClass('rightpanelopen');
                $(".rightattributes").removeClass("open");
                $(".maxanim1").removeClass("highlight");
                var time = 10
                if ($(".fullscreenmain").length != 1) {
                    var acd = {};
                        acd["element"] = $(".tileview[data-num='"+chartnum+"']");;
                        acd["pos"] = "min";
                        PubSub.publish('adjustchartheight', acd);
                    _.each($(".tileview"), function (i, j) {
                        setTimeout(function () {
                            var acd = {};
                            acd["element"] = i;
                            acd["pos"] = "min";
                            //PubSub.publish('adjustchartheight', acd);
                        }, time)
                        time = time + 10;
                    });
                } else {
                    if ($(".singlereportview.fullscreenmain").length === 1) {
                        //$(".refreshsinglecharts").click();
                        var chart = $(".maxanim10").highcharts();
                        var width = $(".maxanim10").width() - $(".rightattributes.open").width();
                        var height = $(".maxanim10").height() - $('.narrativecontainer').height();
                        try {
                            ChartUtils.modifyChartHeightOnResize(that.props.chartnumber, "max");
                            // chart.setSize(width, height, true, true);
                        } catch (e) {
                            var chartData = JSON.parse(ChartInfo.functions.getChartDetails(that.props.chartnumber));
                            if (chartData.chartType == "sankey" || chartData.chartType == "trellis" || chartData.chartType == "leaflet" || chartData.chartType == "countrymap" || chartData.chartType == "indiamap" || chartData.chartType == "world" || chartData.chartType == "srilankamap") {
                                $(".refreshsinglecharts").click();
                            }
                        }
                        $(window).trigger('resize');
                    } else {
                        var acd = {};
                        acd["element"] = currentTile;
                        acd["pos"] = "max";
                        PubSub.publish('adjustchartheight', acd);
                    }
                }
            }, 0);

        }
    }
    createCustomFiled(e) {
        PubSub.publish('createCustomFiled', e.currentTarget);
        document.body.classList.add("modal-open");
    }
    createDillDownHierarchy(e) {
        PubSub.publish('createDillDownHierarchy', e.currentTarget);
        document.body.classList.add("modal-open");
    }
    showyAxisDropDowninSorting() {
        PubSub.publish('showyAxisDropDowninSorting');
    }
    selectlegend(e) {
        var $this = e.currentTarget;
        $('.selectlegend i.legendactive').removeClass('legendactive');
        $($this).find('i').addClass('legendactive');
        var newtheme = $($this).find("div.col-lg-12").html();
        $(".chartthemeli .color-container").empty().append(newtheme);
    }
    onChangeChartNarrative(e) {
        var $this = e.currentTarget;
        var isChecked = $("#chartNarrative").prop('checked');
        if (!isChecked) {
            var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(this.props.chartnumber));
            chartDetails['trendLine'] = "";
            chartDetails.chartSettings.chartNarrative = false;
            ChartInfo.functions.updateChartDetails(this.props.chartnumber, JSON.stringify(chartDetails));
        }
    }
    onChangeChartHeatmapMonoColor(e) {
        var $this = e.currentTarget;
        var isChecked = $("#chartHeatmapMonoColor").prop('checked');
        if (!isChecked) {
            var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(this.props.chartnumber));
            //chartDetails['chartHeatmapMonoColor'] = "";
            chartDetails.chartSettings.chartHeatmapMonoColor = false;
            ChartInfo.functions.updateChartDetails(this.props.chartnumber, JSON.stringify(chartDetails));
        }
    }
    onChangeChartPareto(e) {
        var $this = e.currentTarget;
        var isChecked = $("#chartPareto").prop('checked');
        if (!isChecked) {
            var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(this.props.chartnumber));
            chartDetails['chartPareto'] = "";
            ChartInfo.functions.updateChartDetails(this.props.chartnumber, JSON.stringify(chartDetails));
        }
    }
    onChangeConnectNulls(e) {
        var $this = e.currentTarget;
        var isChecked = $("#connectNulls").prop('checked');
        if (!isChecked) {
            var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(this.props.chartnumber));
            chartDetails['connectNulls'] = "";
            ChartInfo.functions.updateChartDetails(this.props.chartnumber, JSON.stringify(chartDetails));
        }
    }
    validateDecimals(e) {
        var $this = e.currentTarget,
            target = $($this).attr('id'),
            ebmi = "#errmsgmin",
            decimals = parseInt($("#decimalcustomvalue").val());
        if (decimals != "") {
            if (decimals <= 5) {
                $(ebmi).html("").hide();
            } else {
                $(ebmi).html("Value must be between 0 and 5").show().fadeOut(3000);
                $("#decimalcustomvalue").val("");
            }
        }
    }
    onChangedecimal(e) {
        var $this = e.currentTarget;
        $("#decimalcustomvalue").val("");
    }
    onChangeChartSplitYAxis(e) {
        var $this = e.currentTarget;
        var isChecked = $("#chartSplitYAxis").prop('checked');
        if (!isChecked) {
            var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(this.props.chartnumber));
            chartDetails['chartSplitYAxis'] = "";
            ChartInfo.functions.updateChartDetails(this.props.chartnumber, JSON.stringify(chartDetails));
        }
    }
    onChangeChartEqualYAxisScale(e) {
        var $this = e.currentTarget;
        var isChecked = $("#chartEqualYAxisScale").prop('checked');
        if (!isChecked) {
            var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(this.props.chartnumber));
            chartDetails['chartEqualYAxisScale'] = "";
            ChartInfo.functions.updateChartDetails(this.props.chartnumber, JSON.stringify(chartDetails));
        }
    }
    ordertype(e){
        if($(".ordertype").val()=="None"){
            $('.collimit').attr("disabled",true);
            $('.rankingitem').attr("disabled",true);

        }else{
            $('.collimit').attr("disabled",false);
            $('.rankingitem').attr("disabled",false);
        }
    }
}