import React, { Component } from "react";
import _ from 'lodash';
import $ from 'jquery';
import * as Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official'
import wordCloud from "highcharts/modules/wordcloud"
import PubSub from 'pubsub-js'
import drilldow from "highcharts/modules/drilldown";
import dataModule from "highcharts/modules/data";
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import * as ChartUtil from "../../js/helpers/utils/chartutil";
import HighchartsMore from 'highcharts/highcharts-more'
import Viewchartdrilldownmodal from "../../ViewStoryboard/ChartDrillDownModal";
import { whitelableMap } from "../../js/helpers/utils/whitelable";
import { getMessage } from '../../js/helpers/utils/BirdMessage';
import HC_exporting from 'highcharts/modules/exporting';
import { lang } from "../../js/helpers/utils/languages/language";
import StoryboardSmartInsightsView from "../../ViewStoryboard/storyboardsmartinsightsview";


drilldow(Highcharts);
dataModule(Highcharts);
HighchartsMore(Highcharts)
wordCloud(Highcharts)
HC_exporting(Highcharts);

var charts = {};

export default class WordCloud extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        var data = this.props.data, xaxisname = this.props.xaxisname, xaxisColumnName = this.props.xaxisColumnName, xaxisDataType = this.props.xaxisDataType,
            yaxisnameArray = this.props.yaxisnameArray, chartid = this.props.chartid, chartType = this.props.chartType, legend = this.props.legend, position = this.props.position, features = this.props.features, isSharedReport = this.props.isSharedReport, colorThemeIndex = this.props.colorThemeIndex, chartSettings = this.props.chartSettings, tooltipNameArray = this.props.tooltipNameArray, algorithm = this.props.algorithm, periodsvalue = this.props.periodsvalue, trendstats = this.props.trendstats, yAxisOriginalNameArray = this.props.yAxisOriginalNameArray, chartnumber = this.props.chartnumber, isMlChart = this.props.isMlChart, isMLReport = this.props.isMLReport, iscustomstoryboard = this.props.iscustomstoryboard, ismultifact = this.props.ismultifact, multifactentities = this.props.multifactentities, datamodelId = this.props.datamodelId, facttables = this.props.facttables, yAxisOriginalNameArray = this.props.yAxisOriginalNameArray;

        var that = this.state;
        //  charts = {};
        that.chartid = chartid;
        if(charts!=undefined && chartid!=undefined && charts[chartid]!=undefined){
            charts[chartid].destroy();
        }
        that.chartType = chartType;
        var chartPoints = {};
        var stringColumns = [];
        var columnsData = this.props.columnsData;
        var viewChartDrillDownModal = null;
        var isDrillDownEnable = false;
        var isViewDataEnable = false;
        var isExportChartEnable = false;
        var isFilterEnable = false;
        var isDrillThroughEnable = false;
        var issmartinsightlabel1 = "";
        var isSmartInsightLabel = "";
        var isSmartInsightVisible = "";
        var chart;
        var isDirect = 0;
        var isStoredProcedure = 0;
        var colorThemesArray = ChartInfo.functions.getColorThemes();
        var x, y, tooltipprefix = "", tooltipsuffix = "";
        if(chartSettings.numberformat==undefined){
            var numberformat={};
            numberformat.decimals="0_2";
            chartSettings["numberformat"]=numberformat;
            }
        sessionStorage.setItem('pos', position);

        /*Set Chart height to fix resize issues */
        if (typeof chartnumber != "number") {
            chartnumber = chartid.replace("chart", "");
        }
        this.state.chartnumber = chartnumber;
        if (data[0] != undefined && data[0].data != undefined && data[0].data.length > 100) {
            ChartUtil.showSeriesExeededWarning();
        }

        var that = this;
        if ($('.tileview[data-num=' + chartnumber + ']').length === 1) {
            var setheight = $('.tileview[data-num=' + chartnumber + ']').height() - 42;
            $("#" + chartid).height(setheight);
        }
        
        //Prefix/Suffix from Advanced Chart Settings
        var yaxislabelprefix = "", yaxislabelsuffix = "", datalabelprefix = "", datalabelsuffix = "", tooltipprefix = "", tooltipsuffix = "";
        if (chartSettings["AdvancedChartSettings"] != undefined) {
            var advanced = chartSettings["AdvancedChartSettings"];
            yaxislabelprefix = advanced.yAxis[0].axisLabelPrefix;
            yaxislabelsuffix = advanced.yAxis[0].axisLabelSuffix;

            datalabelprefix = advanced.plotOptions.series.dataLabels.dataLabelPrefix;
            datalabelsuffix = advanced.plotOptions.series.dataLabels.dataLabelSuffix;

            tooltipprefix = advanced.tooltip.tooltipPrefix;
            tooltipsuffix = advanced.tooltip.tooltipSuffix;
        }
        $("#" + chartid).empty();     //wordcloud in highcharts

        isExportChartEnable = ChartUtil.checkExportChartEnable(features, position, isSharedReport);

        var options = {
            lang: {
                drillUpText: '\u25c1 Back',
                contextButtonTitle: 'Download',
                decimalPoint: '.',
                thousandsSep: ',',
                BacktoMap_key: 'Back to Map'
            },
            chart: {
                events: {
                    selection: this.customSelection,
                    drillup: function (e) {
                        var chartId = e.target.userOptions.chart.renderTo;
                        ChartUtil.navCorrection(e);
                        var chartnumber = chartId.replace("chart", "");
                        viewChartDrillDownModal.drillup(chartId, chartnumber, charts["chart" + chartnumber], e.seriesOptions.id);
                        //that.autoLabelRotationReset(this);
                    },
                    drilldown: function (e) {
                        isDrillDownEnable = ChartUtil.checkDrillDownEnable(features, position, isSharedReport, iscustomstoryboard);
                        isViewDataEnable = ChartUtil.viewDataEnable(features, position, isSharedReport, iscustomstoryboard);
                        isFilterEnable = ChartUtil.checkFilterEnable(features, position, isSharedReport, iscustomstoryboard);
                        isDrillThroughEnable = ChartUtil.checkDrillThroughEnable(features, position, isSharedReport, iscustomstoryboard);
                        var isSmartInsightEnable = ChartUtil.checkSmartInsightsEnable(features, position, isSharedReport, iscustomstoryboard);
                        if (ChartInfo.functions.getDateHistogramStatus(chartid.split("chart")[1]) == true && xaxisDataType == "string") {
                            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
                            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
                            $('.birdmessagebuttons').removeClass('show');
                            $('.birdmessagebuttons').addClass('hide');
                            $('.birdmessage-center').addClass("message-top-in");
                            $('.details').removeClass('show');
                            $('.details').addClass('hide');
                            $('.birdmessage h2').empty().append('Information');
                            $('.birdmessage-info').empty().text(getMessage('BirdInformation23'));
                        }/*else if(iscustomstoryboard){
                           
                           }*/
                        else {
                            if (!e.seriesOptions) {
                                var chartId = e.target.userOptions.chart.renderTo;
                                var chartnumber = chartId.replace(
                                    "chart", "");
                                chartPoints[chartnumber] = e.point;
                                var dataString = "";
                                var legendDataString = "";
                                var legendDataStringArray = [];
                                _.each(this.getSelectedPoints(), function (value, index) {
                                    if (value.name != undefined && value.series.options.id != "NavigatorXZoom") {
                                        if (value.name.length != 0) {
                                            if (dataString.split("@@").indexOf(value.name) == -1) {
                                                dataString += value.name + "@@"
                                            }
                                        }
                                        else if (chartType === "wordcloud") {
                                            dataString += value.category.name + "@@";
                                        }
                                        else {
                                            dataString += value.name + "@@"
                                        }
                                        if (legend != undefined && legend.columnName.length > 0) {
                                            if (legendDataString != "" && legendDataString != undefined) {
                                                legendDataStringArray = legendDataString.split('@@');
                                                if (legendDataStringArray.length > 0) {
                                                    if (!legendDataStringArray.includes(value.series.name.split('[')[0])) {
                                                        legendDataString += value.series.name.split('[')[0] + "@@"
                                                    }
                                                }
                                            } else {
                                                legendDataString += value.series.name.split('[')[0] + "@@"
                                            }
                                        }
                                    }
                                });
                                var jsonobj;
                                var jsonArray = [];
                                var instantFilterArray = [];
                                if (legend != undefined && legend.columnName.length > 0) {
                                    jsonobj = {}
                                    jsonobj["columnname"] = legend.columnName;
                                    jsonobj["dataType"] = legend.dataType;
                                    if (legendDataString.length > 0) {
                                        legendDataString = legendDataString.substring(0, legendDataString.length - 2);
                                        jsonobj["data"] = legendDataString;
                                    } else {
                                        jsonobj["data"] = chartPoints[chartnumber].series.name.split('[')[0];
                                    }
                                    jsonobj["columndisplayname"] = legend.displayName;
                                    var rangeObject = $.grep(ChartInfo.functions.getRange(), function (e) { return e.name == legend.columnName });
                                    if (rangeObject.length > 0) {
                                        jsonobj["columnname"] = rangeObject[0]["xaxisColumnName"][0].columnName;
                                    }
                                    jsonobj["isdrilldownfilter"] = true;
                                    jsonArray.push(jsonobj);
                                }
                                jsonobj = {};
                                jsonobj["columnname"] = e.target.userOptions.xAxis[0].title.orginaltextName;
                                jsonobj["dataType"] = (e.target.userOptions.xAxis[0].ctype == undefined) ? "string" : e.target.userOptions.xAxis[0].ctype;
                                var rangeObject = $.grep(ChartInfo.functions.getRange(), function (e) { return e.name == jsonobj["columnname"] });
                                if (rangeObject.length > 0) {
                                    jsonobj["columnname"] = rangeObject[0]["xaxisColumnName"][0].columnName;
                                }
                                var value = e.point.name;
                                if (jsonobj["dataType"] == "date" && dataString == "") {
                                    if (value.length > 0) {
                                        var newDate = value;// Date.parse(value.replace(/-/g,'/'));
                                        if (isNaN(newDate)) {
                                            dataString += value + "@@";
                                        }
                                        else {
                                            dataString += newDate + "@@";//new Date(newDate).getTime();
                                        }
                                    }
                                }
                                if (dataString == "") {
                                    dataString = e.point.name + "@@";
                                }
                                dataString = dataString.substring(0, dataString.length - 2);
                                jsonobj["data"] = dataString;
                                jsonobj["isdrilldownfilter"] = true;
                                jsonobj["columndisplayname"] = e.target.userOptions.xAxis[0].title.text;
                                jsonArray.push(jsonobj);
                                instantFilterArray.push(jsonobj);
                                _.each(instantFilterArray, function (data, i) {
                                    if (e.point.name == "") {
                                        data["data"] = e.point.category.name;
                                    }
                                    if (dataString != undefined && dataString.length == 0) {
                                        data["data"] = e.point.name;
                                    } else {
                                        data["data"] = dataString;
                                    }
                                    if (ChartInfo.functions.getExcludeFilterData() != undefined && ChartInfo.functions.getExcludeFilterData().length != 0) {
                                        _.each(ChartInfo.functions.getExcludeFilterData(), function (ExcludeFilterData, j) {
                                            ChartInfo.functions.setExcludeFilterData(instantFilterArray);
                                        });
                                    }
                                    else {
                                        ChartInfo.functions.setExcludeFilterData(instantFilterArray);
                                    }

                                });
                                var yaxisName = e.point.series.userOptions.yaxisname;
                                sessionStorage.setItem('datatable_popup_yaxis', yaxisName);
                                sessionStorage.setItem('datatable_popup_filterdata', JSON.stringify(jsonArray));
                                ChartInfo.functions.setInstanceIncludeFilterDataForDrillDown(chartnumber, chartPoints[chartnumber])
                                //ChartInfo.functions.setInstanceIncludeFilterDataForDrillDown(chartnumber,jsonArray);
                                var top = this.pointer.mouseDownY;
                                var left = this.pointer.mouseDownX + 16;
                                var chartwidth = this.pointer.chart.chartWidth - 160;
                                var chartheight = this.pointer.chart.chartHeight - 130;
                                if (top > chartheight) {
                                    top = chartheight;
                                }
                                if (left > chartwidth) {
                                    left = chartwidth;
                                }
                                $('#chart' + chartnumber + ' .highcharts-container .pie-menu').remove();
                                $('.pie-menu').remove();

                                var isDrillDownVisible = "disabled";
                                var isDrillDownLabel = "disabled";
                                if (xaxisDataType == "customrange") {
                                    isDrillDownEnable = false;
                                    isKeepOnlyVisible = false;
                                    isExcludeVisible = false;
                                }

                                if ((xaxisDataType == "" || xaxisDataType == undefined || xaxisColumnName == "Measure Names") || xaxisDataType == "customrange") {
                                    isDrillDownEnable = false;
                                }

                                if (isDrillDownEnable) {
                                    isDrillDownVisible = "pie-drilldown";
                                    isDrillDownLabel = "";
                                }

                                var isViewDataVisible = "disabled";
                                var isViewDataLabel = "disabled";
                                if (isViewDataEnable) {
                                    isViewDataVisible = "pie-viewdata";
                                    isViewDataLabel = "";
                                }

                                var dataLength = data[0].data.length;
                                var isExcludeVisible = "disabled";
                                var isKeepOnlyLabel = "disabled";
                                var isKeepOnlyVisible = "disabled";
                                var isExcludeLabel = "disabled";
                                if (isFilterEnable && dataLength > 1 && xaxisDataType !== "customrange") {
                                    isKeepOnlyVisible = "keeponly-point";
                                    isKeepOnlyLabel = "";
                                    isExcludeVisible = "exclude-point";
                                    isExcludeLabel = "";
                                }
                                var isydrilldownenable = "disabled";
                                var yaxisname = e.point.name;
                                var drilldownmeasurearray = $.grep(ChartInfo.functions.getMeasureHierarchy(), function (e1) { return e1.name == yaxisname });
                                var chartDetails= ChartInfo.functions.getChartDetails(chartnumber) != undefined ? JSON.parse(ChartInfo.functions.getChartDetails(chartnumber)) : undefined;

                                if(iscustomstoryboard){
                                    if(ChartInfo.functions.getMeasureReportId(chartDetails.reportid)!=undefined){
                                        drilldownmeasurearray=$.grep(JSON.parse(ChartInfo.functions.getMeasureReportId(chartDetails.reportid)), function (e1) { return e1.name == yaxisname });
                                    }                                }
                                var hierarchylist = [];
                                if (drilldownmeasurearray.length > 0 && drilldownmeasurearray[0].hierarchelist.length > 1) {
                                    isydrilldownenable = "measuredilldown";
                                }
                                var isDrillThroughVisible = "disabled";
                                var isDrillThroughLabel = "disabled";
                                if (xaxisDataType == "customrange") {
                                    isDrillThroughEnable = false;
                                }
                                if (isDrillThroughEnable) {
                                    isDrillThroughVisible = "pie-drillthrough";
                                    isDrillThroughLabel = "";
                                }
                                var isNoXaxis = "";
                                if (e.target.options.series[0].name == "Measure Names" && xaxisDataType == "") {
                                    isNoXaxis = "disabled";
                                }
                                if (xaxisDataType == "" || xaxisDataType == undefined) {
                                    var isExcludeVisible = "disabled";
                                    var isKeepOnlyVisible = "disabled";
                                    var isDrillDownVisible = "disabled";
                                }
                                isSmartInsightVisible = "disabled";
                                if(isSmartInsightEnable){
                                    isSmartInsightVisible = "";
                                }
                                if(e.point.ydatatype!=undefined && e.point.ydatatype.toLowerCase()!="number"){
                                    isSmartInsightVisible = "disabled";
                                }
                                if(ismultifact!="false"){
                                    isSmartInsightVisible = "disabled";
                                }

                                //if(isDrillDownEnable || isViewDataEnable || isFilterEnable){
                                if ($(window).width() < 780) {
                                    if (!$("#chart" + chartnumber).parent().hasClass('nopie')) {
                                        setTimeout(function () {
                                            $('.pie-menu').fadeIn(500);
                                            $('g.highcharts-tooltip').css('opacity', '0');
                                            $('div.highcharts-tooltip span').empty();
                                        }, 2000);
                                    }
                                } else {
                                    if (!$("#chart" + chartnumber).parent().hasClass('nopie')) {
                                        $('g.highcharts-tooltip').attr("transform","translate(1,-9999)");
                                        $('.highcharts-tooltip').css('opacity', '0');
                                        setTimeout(function () { 
                                            $('.highcharts-tooltip span').empty() }, 0);
                                    }
                                }
                                if (sessionStorage.getItem("drilldown" + chartnumber) != null && sessionStorage.getItem("drilldown" + chartnumber) != "null") {
                                    $('#chart' + chartnumber + '').before('<div class="pie-menu" style="top:' + top + 'px;left:' + left + 'px;display:none;"><div class="boc-pie-menu-start"></div><div class="boc-pie-menu-end"></div><div class="boc-piemenu-close" id="piemenuclose10"><i class="fa fa-times"></i></div>'
                                        + '<div class="pie-list-wrapper">'
                                        + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut " data-num="' + chartnumber + '"> <div class="boc-pie-menu-center"> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                        + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isViewDataVisible + '" data-num="' + chartnumber + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label ' + isViewDataLabel + '" id="isviewdatalabel1"><span class="pietooltip">View<br>Data</span><i class="fa fa-table"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                        + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isydrilldownenable + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label"><span class="pietooltip">Y-Drill<br>Down</span><i class="fa fa-level-down"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                        + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isDrillThroughVisible + '" data-num="' + chartnumber + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label ' + isDrillThroughLabel + '" id="isdrillthroughlabel1""><span class="pietooltip">Drill<br>Through</span><i class="fa fa-level-down fa-flip-horizontal"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                        + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isDrillDownVisible + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label ' + isDrillDownLabel + '" id="isdrilldownlabel1"><span class="pietooltip">Drill<br>Down</span><i class="fa fa-arrow-down"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                        +'<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isSmartInsightVisible + ' ' + isNoXaxis + '" data-num="' + chartnumber + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label pie-smartinsights ' + isSmartInsightLabel + '" id="issmartinsightlabel1"><span class="pietooltip">'+lang["storyboard.smartinsights"]+'</span><i class="fa fa-smartinsights small-14 marg-righ-10"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                        + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut " data-num="' + chartnumber + '"> <div class="boc-pie-menu-center"> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                        /*+'<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut"> <div class="boc-pie-menu-center">  </div></div>'*/
                                        + '<span class="piemenuwrapper"></span>'
                                        + '</div>'
                                        + '<span class="wrapper"></span>'
                                    );
                                }
                                else {
                                    $('#chart' + chartnumber + '').before('<div class="pie-menu" style="top:' + top + 'px;left:' + left + 'px;display:none;"><div class="boc-pie-menu-start"></div><div class="boc-pie-menu-end"></div><div class="boc-piemenu-close" id="piemenuclose10"><i class="fa fa-times"></i></div>'
                                        + '<div class="pie-list-wrapper">'
                                        + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isKeepOnlyVisible + ' ' + isNoXaxis + '" data-num="' + chartnumber + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label keeponly ' + isKeepOnlyLabel + ' " id="iskeeponlylabel1"><span class="pietooltip"> '+lang["storyboard.keep"]+' <br>'+lang["storyboard.only"]+'</span><i class="fa fa-check"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                        + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isViewDataVisible + '" data-num="' + chartnumber + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label ' + isViewDataLabel + '" id="isviewdatalabel1"><span class="pietooltip">'+lang["storyboard.view"]+'<br>'+lang["storyboard.data"]+'</span><i class="fa fa-table"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                        + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isydrilldownenable + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label"><span class="pietooltip">'+lang["storyboard.y-drill"]+'<br>'+lang["storyboard.down"]+'</span><i class="fa fa-level-down"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                        + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isDrillThroughVisible + '" data-num="' + chartnumber + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label ' + isDrillThroughLabel + '" id="isdrillthroughlabel1""><span class="pietooltip">'+lang["storyboard.drill"]+'<br>'+lang["storyboard.through"]+'</span><i class="fa fa-level-down fa-flip-horizontal"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                        + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isDrillDownVisible + ' ' + isNoXaxis + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label ' + isDrillDownLabel + '" id="isdrilldownlabel1"><span class="pietooltip">'+lang["storyboard.drill"]+'<br>'+lang["storyboard.down"]+'</span><i class="fa fa-arrow-down"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                        + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isExcludeVisible + ' ' + isNoXaxis + '" data-num="' + chartnumber + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label excludeonly ' + isExcludeLabel + '" id="isexcludelabel1"><span class="pietooltip">'+lang["storyboard.exclude"]+'</span><i class="fa fa-times"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                        +'<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isSmartInsightVisible + ' ' + isNoXaxis + '" data-num="' + chartnumber + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label pie-smartinsights ' + isSmartInsightLabel + '" id="issmartinsightlabel1"><span class="pietooltip">'+lang["storyboard.smartinsights"]+'</span><i class="fa fa-smartinsights small-14 marg-righ-10"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                        /*+'<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut"> <div class="boc-pie-menu-center">  </div></div>'*/
                                        + '<span class="piemenuwrapper"></span>'
                                        + '</div>'
                                        + '<span class="wrapper"></span>'
                                    );
                                }
                                //}
                                if ($(window).width() < 780) {
                                    setTimeout(function () {
                                        $('.boc-pie-menu-start, .boc-pie-menu-end, .boc-pie-menu-filter').toggleClass("openpie");
                                    }, 2000);
                                } else {
                                    $('.pie-menu').fadeIn(1000);
                                    setTimeout(function () {
                                        $('.boc-pie-menu-start, .boc-pie-menu-end, .boc-pie-menu-filter').toggleClass("openpie");
                                    }, 200);
                                }
                                $('.boc-piemenu-close, .piemenuwrapper').on("click", function () {
                                    $('.boc-pie-menu-start, .boc-pie-menu-end, .boc-pie-menu-filter').toggleClass("openpie");
                                    setTimeout(function () {
                                        $('.pie-menu').remove();
                                        $('g.highcharts-tooltip').css('opacity', '1');
                                    }, 800);
                                });
                                $('.wrapper').on("click", function () {
                                    $('.pie-menu').remove();
                                });
                                $('.pie-drilldown').on("click", function () {
                                    $('.boc-pie-menu-start, .boc-pie-menu-end, .boc-pie-menu-filter').toggleClass("openpie");
                                    setTimeout(function () {
                                        $('g.highcharts-tooltip').css('opacity', '1');
                                        $('.pie-menu').remove();
                                    }, 800);
                                    if (iscustomstoryboard) {
                                        columnsData = ChartInfo.functions.getCustomstroyboardReportColumns(chartnumber);
                                    }
                                    var sourceType = "storyboard";
                                    if (ChartInfo.functions.getChartDetailsForLogReport(chartnumber) != undefined) {
                                        var chartdata = ChartInfo.functions.getChartDetailsForLogReport(chartnumber)["chartdetails"];
                                        sourceType = chartdata["sourceType"];
                                    }
                                    var columnsDataClone;
                                    stringColumns = [];
                                    if (columnsData != undefined && columnsData != null) {
                                        columnsDataClone = columnsData;
                                        _.each(columnsDataClone, function (col, i) {
                                            if (col.tableDisplayType != undefined && col.tableDisplayType == "string") {
                                                stringColumns.push(col);
                                            }
                                        });
                                    }
                                    charts["chart" + chartnumber] = e.target;
                                    chartPoints[chartnumber] = e.point;
                                    viewChartDrillDownModal = new Viewchartdrilldownmodal(
                                        {
                                            chartId: chartId,
                                            chartnumber: chartnumber,
                                            columns: JSON.stringify(stringColumns),
                                            xaxisName: e.target.userOptions.xAxis[0].title.orginaltextName,
                                            xaxisDisplayName: e.target.userOptions.xAxis[0].title.text,
                                            displayName: e.target.userOptions.xAxis[0].title.text,
                                            xaxisType: e.target.userOptions.xAxis[0].ctype,
                                            value: dataString,
                                            chart: charts["chart"
                                                + chartnumber],
                                            point: chartPoints[chartnumber],
                                            pos: position,
                                            iscuststroyboard: iscustomstoryboard,
                                            sourceType: sourceType,
                                            ismultifact: ismultifact,
                                            multifactentities: multifactentities,
                                            yaxisnameArray: yAxisOriginalNameArray,
                                            datamodelId: datamodelId,
                                            facttables: facttables,
                                            drilldownyAxisSeries: yaxisName
                                        });
                                });
                                $('.measuredilldown').on("click", function (event) {
                                    event.preventDefault();
                                    $('.boc-pie-menu-start, .boc-pie-menu-end, .boc-pie-menu-filter').toggleClass("openpie");
                                    setTimeout(function () {
                                        $('g.highcharts-tooltip').css('opacity', '1');
                                        $('.pie-menu').remove();
                                    }, 800);
                                    charts["chart" + chartnumber] = e.target;
                                    chartPoints[chartnumber] = e.point;
                                    viewChartDrillDownModal = new Viewchartdrilldownmodal(
                                        {
                                            chartId: chartId,
                                            chartnumber: chartnumber,
                                            columns: JSON.stringify(stringColumns),
                                            xaxisName: e.target.userOptions.xAxis[0].title.orginaltextName,
                                            xaxisDisplayName: e.target.userOptions.xAxis[0].title.text,
                                            displayName: e.target.userOptions.xAxis[0].title.text,
                                            xaxisType: e.target.userOptions.xAxis[0].ctype,
                                            value: e.point.name,
                                            chart: charts["chart"
                                                + chartnumber],
                                            point: chartPoints[chartnumber],
                                            pos: position,
                                            drilldownType: "measuredrilldown",
                                            ismultifact: ismultifact,
                                            multifactentities: multifactentities,
                                            yaxisnameArray: yaxisnameArray,
                                            datamodelId: datamodelId,
                                            facttables: facttables,
                                            drilldownyAxisSeries: yaxisName
                                        });
                                });
                                $('.pie-viewdata').on("click", function (e) {
                                    e.preventDefault();
                                    PubSub.publish('viewDataTablePopUp', e);
                                    setTimeout(function () {
                                        $('g.highcharts-tooltip').css('opacity', '1');
                                    }, 1000);
                                });
                                $('.exclude-point').on("click", function (e) {
                                    e.preventDefault();
                                    PubSub.publish('exclude-point', e);
                                });
                                $('.keeponly-point').on("click", function (e) {
                                    e.preventDefault();
                                    PubSub.publish('keeponly-point', e);
                                });
                                $('.pie-drillthrough').on("click", function (e) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    PubSub.publish('pie-drillthrough', e);
                                });
                                $('.pie-smartinsights').on("click", function (e1) {
                                    $('.boc-pie-menu-start, .boc-pie-menu-end, .boc-pie-menu-filter').toggleClass("openpie");
                                    setTimeout(function () {
                                        $('g.highcharts-tooltip').css('opacity', '1');
                                        $('.pie-menu').remove();
                                    }, 800);
                                    e1.preventDefault();
                                    e1.stopPropagation();
                                    if (iscustomstoryboard) {
                                        columnsData = ChartInfo.functions.getCustomstroyboardReportColumns(chartnumber);
                                    }
                                    var sourceType = "storyboard";
                                    if (ChartInfo.functions.getChartDetailsForLogReport(chartnumber) != undefined) {
                                        var chartdata = ChartInfo.functions.getChartDetailsForLogReport(chartnumber)["chartdetails"];
                                        sourceType = chartdata["sourceType"];
                                    }
                                    charts["chart" + chartnumber] = e.target;
                                    chartPoints[chartnumber] = e.point;
                                    let drilldownProps = {};
                                    drilldownProps["chartId"] = chartId;
                                    drilldownProps["chartnumber"] = chartnumber;
                                    drilldownProps["columns"] = JSON.stringify(columnsData);
                                    drilldownProps["xaxisName"] = e.target.userOptions.xAxis[0].title.orginaltextName;
                                    drilldownProps["xaxisDisplayName"] = e.target.userOptions.xAxis[0].title.text;
                                    drilldownProps["displayName"] = e.target.userOptions.xAxis[0].title.displayname;
                                    drilldownProps["xaxisType"] = e.target.userOptions.xAxis[0].ctype;
                                    drilldownProps["value"] = dataString;
                                    drilldownProps["chart"] = e.target;
                                    drilldownProps["point"] = e.point;
                                    drilldownProps["pos"] = position;
                                    drilldownProps["iscuststroyboard"] = iscustomstoryboard;
                                    drilldownProps["sourceType"] = sourceType;
                                    drilldownProps["datamodelId"] = datamodelId;
                                    drilldownProps["ismultifact"] = ismultifact;
                                    drilldownProps["multifactentities"] = multifactentities;
                                    drilldownProps["yaxisnameArray"] = yAxisOriginalNameArray;
                                    drilldownProps["facttables"] = facttables;
                                    drilldownProps["drilldownyAxisSeries"] = yaxisName;
                                    let storyboardmsmartinsightview = new StoryboardSmartInsightsView(drilldownProps)
                                });
                            }
                        }
                    }
                },
                type: 'wordcloud',
                inverted: false,
                renderTo: chartid,
                //zoomType : 'xy',
                options3d: {},
                backgroundColor: 'transparent',
                style: {
                    fontFamily: whitelableMap["fontfamily"],
                    fontWeight: '400',
                },
                animation: false,
                //height:'auto'
            },
            pane: {},

            title: {
                text: ''
            },
            navigator: {
                enabled: false,
                series: {
                    type: 'wordcloud',
                    color: '#eee',
                    lineColor: '#eee',
                    colorByPoint: false,
                    //id:"NavigatorXZoom"
                },
                margin: 10,
                outlineColor: '#eee',
                outlineWidth: 1,
                //maskFill: 'rgba(0, 0, 0, 0.05)',

            },

            xAxis: [{

                ctype: xaxisDataType,
                type: "category",
                title: {
                    text: xaxisname,
                    orginaltextName: xaxisColumnName,
                    style: {}
                },

            }],

            tooltip: {
                /*headerFormat : '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat : '<tr><td style="color:{series.color};padding:0">{series.name}: </td>'
                        + '<td style="padding:0"><b>{point.y:,.2f} </b></td></tr>',
                footerFormat : '</table>',*/
                formatter: function () {
                    var key = this.x.name;
                    if (key == undefined) {
                        key = this.key;
                    }
                    var name = '<span style="font-size:10px">' + key + '</span><table>'
                    var details;
                    if (chartid != "nlpchart") {
                        details = ChartInfo.functions.getChartDetails(chartnumber) != undefined ? JSON.parse(ChartInfo.functions.getChartDetails(chartnumber)) : undefined;
                    }
                    var that = this;
                    var aggrigationName = that.series.options.aggType;
                    if (details != undefined && details['yAxis'].length != 0) {
                        var seriesName = (this.series.options.displayname == undefined) ? this.series.name : this.series.options.displayname;
                        $.each(that.series.options.data, function (i, j) {
                            if (j.name == that.key && j.y == that.y && j.aggType != undefined && j.aggType == that.point.aggType) {
                                aggrigationName = j.aggType;
                            }
                        });
                    }
                    name += '<tr><td style="color:' + that.series.color + ';padding:0">' + key + ' <span class=bocaggtype>[' + aggrigationName + '] </span>' + ':&nbsp; </td>';
                    if (this.point.ydatatype == 'date' && this.series.options.aggType.toLowerCase().indexOf("count") < 0) {
                        var date = new Date(this.y);
                        name += '<td style="padding:0"><b>' + date.toLocaleString() + '</b></td></tr>';
                    }
                    else if (this.y != 0 && this.y != undefined && this.y != null) {
                        var num = ChartInfo.functions.addFormatting(this.y, chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator);
                        name += '<td style="padding:0"><b>' + tooltipprefix + num + tooltipsuffix + '</b></td></tr>';
                    }
                    else {
                        name += '<td style="padding:0"><b>' + tooltipprefix + this.y + tooltipsuffix + '</b></td></tr>';
                    }

                    var that = this;
                    if (tooltipNameArray.length != 0) {
                        $.each(tooltipNameArray, function (i) {
                            var tooltipnow = tooltipNameArray[i];
                            var tooltipvalue = "t" + i;
                            var tooltipagg = "ta" + i;
                            name +='<tr><td style="color:'+that.series.color+';padding:0">'+tooltipnow+' <span class=bocaggtype>['+that.point[tooltipagg]=='customaggregatedfield'?'raw':that.point[tooltipagg]+'] </span>' + ': </td>'
                            if (that.point[tooltipvalue] == 0) {
                                name += '<td style="padding:0"><b>' + tooltipprefix + that.point[tooltipvalue] + tooltipsuffix + '</b></td></tr>'
                            } else {
                                var num = ChartInfo.functions.addFormatting(that.point[tooltipvalue], chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator)
                                name += '<td style="padding:0"><b>' + tooltipprefix + num + tooltipsuffix + '</b></td></tr>'
                            }
                        });
                    }
                    return name;

                },
                shared: false,
                useHTML: true,
                followPointer: true
            },
            plotOptions: {
                wordcloud: {

                },

                annotations: [{
                    title: {
                        text: '<span style="">drag me anywhere <br> dblclick to remove</span>',
                        style: {
                            color: 'red'
                        }
                    },
                    anchorX: "left",
                    anchorY: "top",
                    allowDragX: true,
                    allowDragY: true,
                    x: 515,
                    y: 155
                }],

            },

            series: [{
                yaxisname: data[0].yaxisname,

                type: 'wordcloud',
                data: data[0].data,

                name: 'Occurrences',
                rotation: {
                    from: 0.1,
                    to: 0.2
                },
                spiral: 'rectangular',
            }],
            tooltip: {
                shared: true,
                useHTML: true,

                //pointFormat: '<tr><td style="color: {series.color}">Occurrences : </td>' +
                 //   '<td style="text-align: right"><b>{point.y}</b></td></tr>',
                //footerFormat: '</table>',
                pointFormatter:function(){
                    if (chartSettings.numberformat != undefined) {
                        var num = ChartInfo.functions.addFormatting(this.y, chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator)
                            return "Observation:" + datalabelprefix + num + datalabelsuffix;
                    }else{
                        return "Observation:" + this.y
                    }
                }
                //valueDecimals: 2
            },
            colors: colorThemesArray[colorThemeIndex],
            exporting: {
                enabled: false,
                buttons: {
                    contextButton: {
                        y: 2,
                        symbol: 'download',
                        symbolFill:"#fff",
                        symbolStrokeWidth:0,
                        theme:{
                            fill:'#ffffff',
                            padding:5,
                            stroke:'none',
                            cursor:'pointer'
                        },
                    }  
                },
                chartOptions: {
                    chart: {
                        width: 800,

                    },

                    legend: {
                        itemStyle: {
                            font: '9px ' + whitelableMap["fontfamily"],
                            fontWeight: 'normal',
                        }
                    }
                }
            },
            credits: {
                enabled: false
            }
        };

        if (isExportChartEnable) {
            options.exporting.enabled = true;
        }
        Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
            var path = [
                // Arrow stem
                'M', x + w * 0.5, y,
                'L', x + w * 0.5, y + h * 0.7,
                // Arrow head
                'M', x + w * 0.3, y + h * 0.5,
                'L', x + w * 0.5, y + h * 0.7,
                'L', x + w * 0.7, y + h * 0.5,
                // Box
                'M', x, y + h * 0.9,
                'L', x, y + h,
                'L', x + w, y + h,
                'L', x + w, y + h * 0.9
            ];
            return path;
        };
        var chart = new Highcharts.Chart(options);
        charts[chartid] = chart;
        this.state.chart = chart;
        $("#" + chartid + " svg>title").remove();

        return (
            <div style={{ height: "360px" }} ref="chartparent" >
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                    ref="chartComponent"
                />
            </div>
        );
    }
    componentDidMount() {
        var compos = {};
        compos["chartComponent"] = this.refs.chartComponent;
        compos["chartparent"] = this.refs.chartparent;
        compos["chart"] = this.state.chart;
        compos["chartType"] = this.state.chartType;
        ChartInfo.functions.setChartComponents(this.state.chartnumber, compos);
        if (this.state.chartid != undefined && this.state.chartid == "nlpchart") {
            this.refs.chartComponent.chart.setSize($("#nlpchart").width(),$("#nlpchart").height(),false);
        }
        else {
            if(this.refs!=undefined && this.refs.chartComponent!=undefined && this.refs.chartComponent.chart!=undefined){
                ChartUtil.charts[this.state.chartid]=this.refs.chartComponent.chart;
            }
            //ChartUtil.modifyChartHeightOnResize(this.state.chartnumber);
        }
    }
}