import React, { Component } from "react";
import _ from 'lodash';
import * as Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official'
import dataModule from "highcharts/modules/data";
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import * as ChartUtil from "../../js/helpers/utils/chartutil";
import HighchartsMore from 'highcharts/highcharts-more'
import { whitelableMap } from "../../js/helpers/utils/whitelable";
import { getMessage } from '../../js/helpers/utils/BirdMessage';
//import summernote from 'summernote'
const $ = window.$;

var charts = {};

export default class TextChart extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	componentDidMount() {
		var compos = {};
		compos["chartComponent"] = this.refs.chartComponent;
		compos["chartparent"] = this.refs.chartparent;
		ChartInfo.functions.setChartComponents(this.state.chartnumber, compos);
		//ChartUtil.modifyChartHeightOnResize(this.state.chartnumber);
		if(this.refs!=undefined && this.refs.chartComponent!=undefined && this.refs.chartComponent.chart!=undefined){
			ChartUtil.charts[this.state.chartid]=this.refs.chartComponent.chart;
		}
	}

	render() {
		var data = this.props.data, chartId = this.props.chartId, chartType = this.props.chartType, position = this.props.position, features = this.props.features, isSharedReport = this.props.isSharedReport, iscustomstoryboard = this.props.iscustomstoryboard;

		var that = this.state;
		//  charts = {};
		that.chartid = chartId;
		if(charts!=undefined && chartId!=undefined && charts[chartId]!=undefined &&  charts[chartId].refs!=undefined &&  charts[chartId].refs.chartComponent!=undefined &&  charts[chartId].refs.chartComponent.chart!=undefined){
            charts[chartId].refs.chartComponent.chart.destroy();
        }
		/*Set Initial Chart height to fix resize issues */
		var chartnumber = chartId.replace("chart", "");
		that.chartnumber = chartnumber;

		if($('.tileview[data-num=' + chartnumber + ']')!=null && $('.tileview[data-num=' + chartnumber + ']').length>0){
			//$('.richtextcontrols' + chartId).show();
		}
		if($(".maxanim1[data-num=" + chartnumber + "]").hasClass("fullscreenmain")){
			$('.richtextcontrols' + chartId).show();
		}

		if ($('.tileview[data-num=' + chartnumber + ']').length === 1) {
			var setheight = $("#tileview" + chartnumber).height() - $(".maxanim3[data-num=" + chartnumber + "]").height() - 14;
			$("#chart" + chartnumber).height(setheight);
		}
		var textChartHeight = $("#tileview" + chartnumber).height() - ($("#tileview" + chartnumber + " .note-toolbar.panel-heading").outerHeight() + $(".maxanim3[data-num=" + chartnumber + "]").outerHeight() + 24)
		
		if($('.maxanim1[data-num=' + chartnumber + ']').hasClass('singlereportview')){
			textChartHeight = $("#chart" + chartnumber).height() - 100;
		}
		if(textChartHeight<100){
			textChartHeight = 100;
		}
		/*Set Initial Chart height to fix resize issues */
		var chartnumber = chartId.replace("chart", "");

		if ($('.tileview[data-num=' + chartnumber + ']').length === 1) {
			var setheight = $("#tileview" + chartnumber).height() - $(".maxanim3[data-num=" + chartnumber + "]").height() - 14;
			$("#chart" + chartnumber).height(setheight);
		}

		$('#' + chartId).summernote({
			// callbacks: {
			// 	onKeyup: function (e) {
			// 		setTimeout(function(){
			// 		var num=0;
			// 		num = $('#' + chartId).summernote('code').replace(/(<([^>]+)>)/ig, "").replace(/( )/, " ").length;
			// 		var key = e.keyCode;
			// 		var allowed_keys = [37, 38, 39, 40, 46]
			// 		if ($.inArray(key, allowed_keys) != -1)
			// 			return true
			// 		else if (num > 14000) {
			// 			e.preventDefault();
			// 			e.stopPropagation();
			// 			$('.countcharacters').text("Characters Count Limit:" + (14000 - num));
			// 			$('.saverichtext[data-num=' + chartId + ']').addClass('disabled');
			// 		} else {
			// 			$('.countcharacters').text("Characters Count Limit:" + (14000 - num));
			// 			$('.saverichtext[data-num=' + chartId + ']').removeClass('disabled');
			// 		}
			// 		  },250);
					  
			// 	},
			// 	onPaste: function (e) {
			// 		setTimeout(function () {
			// 			var num = $('#' + chartId).summernote('code').replace(/(<([^>]+)>)/ig, "").replace(/( )/, " ").length;
			// 			var key = e.keyCode;
			// 			var allowed_keys = [8, 37, 38, 39, 40, 46]
			// 			if ($.inArray(key, allowed_keys) != -1)
			// 				return true
			// 			else if (num > 14000) {
			// 				e.preventDefault();
			// 				e.stopPropagation();
			// 				$('.countcharacters').text("Characters Count Limit:" + (14000 - num));
			// 				$('.saverichtext[data-num=' + chartId + ']').addClass('disabled');
			// 			} else {
			// 				$('.countcharacters').text("Characters Count Limit:" + (14000 - num));
			// 				$('.saverichtext[data-num=' + chartId + ']').removeClass('disabled');
			// 			}
			// 		}, 250);
			// 	}
			// },
			height: textChartHeight,
			focus: false,
			disableDragAndDrop: true,
			toolbar: [
				['style', ['style']],
				['font', ['bold', 'italic', 'underline', 'clear']],
				['fontname', ['fontname']],
				['color', ['color']],
				['para', ['ul', 'ol', 'paragraph']],
				['height', ['height']],
				['table', ['table']],
				['insert', ['link', 'picture', 'hr']],
				['view', ['fullscreen', 'codeview']],
			]
		});
		$('#' + chartId).on('summernote.keyup', function(we, e) {
			var num=0;
			num = $('#' + chartId).summernote('code').replace(/(<([^>]+)>)/ig, "").replace(/( )/, " ").length;
			var key = e.keyCode;
			var allowed_keys = [37, 38, 39, 40, 46]
			if ($.inArray(key, allowed_keys) != -1)
				return true
			else if (num > 14000) {
				e.preventDefault();
				e.stopPropagation();
				$('.countcharacters').text("Characters Count Limit:" + (14000 - num));
				$('.saverichtext[data-num=' + chartId + ']').addClass('disabled');
			} else {
				$('.countcharacters').text("Characters Count Limit:" + (14000 - num));
				$('.saverichtext[data-num=' + chartId + ']').removeClass('disabled');
			}
		});
		$('#' + chartId).on('summernote.paste', function(e) {
			var num = $('#' + chartId).summernote('code').replace(/(<([^>]+)>)/ig, "").replace(/( )/, " ").length;
			var key = e.keyCode;
			var allowed_keys = [8, 37, 38, 39, 40, 46]
			if ($.inArray(key, allowed_keys) != -1)
				return true
			else if (num > 14000) {
				e.preventDefault();
				e.stopPropagation();
				$('.countcharacters').text("Characters Count Limit:" + (14000 - num));
				$('.saverichtext[data-num=' + chartId + ']').addClass('disabled');
			} else {
				$('.countcharacters').text("Characters Count Limit:" + (14000 - num));
				$('.saverichtext[data-num=' + chartId + ']').removeClass('disabled');
			}
		});
		if (data['richtextcontent'] != undefined) {
			$('#' + chartId).html(decodeURIComponent(data['richtextcontent']));
			$('#' + chartId).summernote('code',decodeURIComponent(data['richtextcontent']));
		} else {
			//$('#'+chartId).destroy();
			$("#" + chartId).empty();
		}

		return (<div></div>);
	}

}