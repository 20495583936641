import React from 'react';
import ReactDom from 'react-dom';
import _ from 'underscore';
import SendRequest from '../SendRequest';
import DatasourceConnectionHtml from '../Hub/DatasourceConnectionHtml';
import { getMessage } from '../js/helpers/utils/BirdMessage';
import { lang } from "../js/helpers/utils/languages/language";
import DataModel from './Datamodels';
import AddNewAddressHtml from '../Hub/AddNewAddressHtml';
const $ = window.$;


export default class DatasourceConnection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            schemaName: props.schemaName,
            schemaDisplayName: props.schemaDisplayName,
            schemaId: props.schemaId,
            connectionbbbbbjson: props.connectionjson,
            connectiondetails: {}
        }
        this.datamodel = new DataModel();
    }
    requestHeaders = {
        method: "get",
        headers: new Headers({
            "Content-Type": "application/json"
        })
    };
    databaseconnectiondetails = {};
    componentDidMount() {
        var connectiondetails;
        SendRequest({
            url: "getConnectionDetailsById",
            queryString: "?schemaId=" + this.state.schemaId,
            sucessFunction: (response) => {
                if (!response.hasOwnProperty('error')) {
                    connectiondetails = JSON.parse(response.connection_details_json);
                    this.state.connectiondetails = connectiondetails
                    ReactDom.render(
                        <DatasourceConnectionHtml
                            schemaId={this.state.schemaId}
                            schemaName={this.state.schemaName}
                            schemaDisplayName={this.state.schemaDisplayName}
                            connectionjson={connectiondetails}
                            connection_access_id={this.state.connectionId}
                            connectiondetails={connectiondetails}
                            connectiondata={connectiondetails}
                            dbsavenewconnection={this.dbsavenewconnection}
                            testconnection={this.testconnection}
                            addnewaddress={this.addnewaddress}
                            removeaddresskafka={this.removeaddresskafka}
                            close={this.closeconnectionpop}
                        />,
                        document.querySelector('.datasourceconnection-container')
                    );
                }
            },
            rejectFunction: (error) => {
                console.log("can't process /getConnectionDetailsById request" + error.message);
            },
            headers: this.requestHeaders
        })
    }
    closeconnectionpop = (e) => {
        $(".connection-center").removeClass("connection-in");
    }
    addnewaddress = (e) => {
        e.preventDefault();
        var today = new Date();
        var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
        $('.appendnewaddress').append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
        ReactDom.render(
            <AddNewAddressHtml url="" port="" removeaddresskafka={this.removeaddresskafka} />, document.getElementById(dynamicdiv)
        );
    }
    removeaddresskafka = (e) => {
        e.preventDefault();
        var $this = e.currentTarget;
        $($this).parent().parent().parent().remove();
    }
    dbsavenewconnection = (e) => {
        e.preventDefault();
        $(".spinner").show();
        var connectionObj = {};
        var that = this;
        this.testconnection(e);
        if (this.isValid()) {
            $('.spinner').show();
            var connectionObj = this.databaseconnectiondetails;
            connectionObj["connectionaccessid"] = this.state.connectionId;
            connectionObj["command"] = "testConnection";
            if (this.databaseconnectiondetails.connectionname.length > 100) {
                $(".spinner").hide();
                $(".gridspinner").hide();
                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Warning');
                $('.birdmessage-info').empty().text(lang["models.connectionchar"]);
                return false;
            }
            var data = new FormData();
            data.append("connectionObject", JSON.stringify(connectionObj));
            SendRequest({
                url: "tables",
                queryString: "",
                body: data,
                sucessFunction: (response) => {
                    if (response.success != true) {
                        this.showDatabaseErrorMessage(response, true);
                        $('.spinner').hide();
                    } else {
                        connectionObj["connectionAccessName"] = this.state.schemaName;
                        connectionObj["connectionName"] = this.state.schemaName;
                        connectionObj["connectionId"] = this.state.schemaId;
                        connectionObj["connection_id"] = parseInt(this.state.schemaId);
                        connectionObj["connection_access_name"] = this.databaseconnectiondetails.connectionname;
                        connectionObj["connection_access_id"] = this.state.connectionId;
                        connectionObj["connection_details"] = JSON.stringify(this.databaseconnectiondetails);
                        var info = new FormData();
                        info.append("connectionObject", JSON.stringify(connectionObj));
                        info.append("isFromModel", 1);
                        SendRequest({
                            url: "saveConnectionDetails",
                            queryString: "",
                            body: info,
                            sucessFunction: (collection) => {
                                $(".connection-center").remove()
                                if (collection.hasOwnProperty('error')) {
                                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                                    $('.birdmessagebuttons').removeClass('show');
                                    $('.birdmessagebuttons').addClass('hide');
                                    $('.birdmessage-center').addClass("message-top-in");
                                    $('.details').removeClass('hide');
                                    $('.birdmessage h2').empty().append('Error : ');
                                    $('.birdmessage h2').append('<span class="errorcode">');
                                    $('.birdmessage .errorcode').empty().append(collection.errorCode);
                                    $('.birdmessage-info').empty().text(collection.error);
                                    $('.details').addClass('show');
                                    $('.messagedetails xmp').empty().append(collection.error)
                                    setTimeout(function () {
                                        $('.birderror').remove();
                                    }, 3000);
                                    $('.spinner').hide();
                                } else {
                                    $(".spinner").hide();
                                    $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                                    $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                                    $('.birdmessagebuttons').removeClass('show');
                                    $('.birdmessagebuttons').addClass('hide');
                                    $('.birdmessage-center').addClass("message-top-in");
                                    $('.details').removeClass('show');
                                    $('.details').addClass('hide');
                                    $('.birdmessage h2').empty().append('Success');
                                    $('.birdmessage-info').empty().text(getMessage('BirdSuccess41'));
                                    sessionStorage.setItem("changesdone", 0);
                                    $('.browselogfiles').removeClass('disabled');
                                    $(".connection-center").removeClass("connection-in");
                                    setTimeout(function () { $("body").removeClass("connection-open creating-new-connection"); }, 800);
                                    var condata = JSON.parse(collection.connectionjson);
                                    condata.command = "getTables";
                                    var data1 = new FormData();
                                    data1.append("connectionObject", JSON.stringify(condata));
                                    SendRequest({
                                        url: "tables",
                                        queryString: "",
                                        body: data1,
                                        sucessFunction: (collection1) => {
                                            collection.connectionjson = JSON.stringify(that.databaseconnectiondetails);
                                            var tables = collection1.tables;                                            
                                            _.each(tables, function (ent, index) {
                                                var entityname = ent;
                                                that.datamodel.dataModelConnectionsDetailsCollection.updateDataModelConnections(entityname, collection.connectionAccessId, collection);
                                                $("#dataworkspacetablesnew").append('<li item-expanded="true" class="viewreport-list-item ui-draggable ui-draggable-handle"><label title=' + entityname + '><i class="fa fa-link"></i><input type="checkbox" durable="false" class="scopeitem mysqltablevaluetemp" data_parent=' + collection.connectionAccessId + '  id=' + entityname + ' data-value="' + entityname + '">' + entityname + '</label><button class="btn btn-transparent btn-sm editdmconnection" data_parent=' + collection.connectionAccessId + ' data-value="' + entityname + '" type="button" title='+lang["models.settings"]+' ><i class="fa fa-cog"></i></button><button class="btn btn-transparent btn-sm joinremove" type="button" data_parent=' + collection.connectionAccessId + ' data-value="' + entityname + '"><i class="fa fa-times"></i></button></li>');
                                                $('.joinremove').unbind().click((e) => { that.datamodel.joinremove(e); });
                                                $('.editdmconnection').unbind().click((e)=>{that.datamodel.editdatamodelconnectionitem(e);});
                                                   that.datamodel.onModelEntitiesLoad();
                                            });
                                        },
                                        rejectFunction: (error) => { },
                                        headers: {
                                            method: "post",
                                            headers: new Headers({
                                                "Content-Type": "application/json"
                                            })
                                        }
                                    });
                                    //that.broker.trigger("loadConnections",that.connectionId!=undefined?that.connectionId:collection.connectionAccessId)
                                }
                            },
                            rejectFunction: (error) => { },
                            headers: {
                                method: "post",
                                headers: new Headers({
                                    "Content-Type": "application/json"
                                })
                            }
                        });
                    }
                },
                rejectFunction: (error) => { },
                headers: {
                    method: "post",
                    headers: new Headers({
                        "Content-Type": "application/json"
                    })
                }
            });
        }
        if (this.isValid() == false) {
            $('.spinner').hide();
        }

    }
    testconnection = (e) => {
        e.preventDefault();
        if (this.isValid()) {
            $('.spinner').show();
            var connectionObj = this.databaseconnectiondetails;
            connectionObj["connectionaccessid"] = this.props.connectionId;
            connectionObj["command"] = "testConnection";
            var data = new FormData();
            data.append("connectionObject", JSON.stringify(connectionObj));
            SendRequest({
                url: "tables",
                queryString: "",
                body: data,
                sucessFunction: (response) => {
                    if (response.success === true) {
                        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Success');
                        $('.birdmessage-info').empty().text(getMessage('BirdSuccess41'));
                        $('.spinner').hide();
                    } else {
                        this.showDatabaseErrorMessage(response, true);
                    }
                },
                rejectFunction: (error) => { },
                headers: {
                    method: "post",
                    headers: new Headers({ "Content-Type": "application/json" })
                }
            });
        }
    }
    showDatabaseErrorMessage = (response, stepBack) => {
        $(".spinner").hide();
        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
        $('.birdmessagebuttons').removeClass('show');
        $('.birdmessagebuttons').addClass('hide');
        $('.birdmessage-center').addClass("message-top-in");
        $('.details').removeClass('hide');
        $('.birdmessage h2').empty().append('Error : ');
        $('.birdmessage h2').append('<span class="errorcode">');
        $('.birdmessage .errorcode').empty().append(response.errorCode);
        $('.birdmessage-info').empty().text(response.error);
        $('.details').addClass('show').text("Details");
        $('.messagedetails xmp').empty().append(response.errorTrace);
    }

    isValid = () => {
        var patt = new RegExp("^[a-zA-Z0-9\ \_$]+$");
        var result = true;
        var bool = false;
        var windowsAuth = false;
        windowsAuth = $(".windowsauth").is(":checked");
        this.databaseconnectiondetails = {};
        this.databaseconnectiondetails["dbType"] = this.state.schemaName;
        var result = true;
        if (this.state.schemaName.toLowerCase() == "kafka") {
            $(".help-block").addClass("hide");
            this.databaseconnectiondetails["connectionname"] = $(".kafkaform").find(".connectionname").val();
            var servers = "";
            _.each($(".appendnewaddress .form-group"), function (html, index) {
                servers += $(html).find(".url").val() + ":" + $(html).find(".port").val() + ",";
                if ($(html).find(".url").val().length == 0) {
                    $(html).find("#url").closest(".form-group").addClass('has-error');

                    $(html).find("#url").removeClass("hide").text(getMessage("ValidationMessage1").replace("@replace", 'IP Address'));
                    result = false;
                } else {
                    $("#dbconnectionscontainer #url").closest(".form-group").removeClass('has-error');
                }
                if ($(html).find(".port").val().length == 0) {
                    $(html).find("#port").closest(".form-group").addClass('has-error');
                    $(html).find("#port").removeClass("hide").text(getMessage("ValidationMessage1").replace("@replace", 'Port'));
                    result = false;
                } else {
                    $("#dbconnectionscontainer #port").closest(".form-group").removeClass('has-error');
                }
            });
            servers = servers.substring(0, servers.length - 1);
            this.databaseconnectiondetails["servers"] = servers;
            this.databaseconnectiondetails["group"] = $(".kafkaform").find(".groupid").val();
            if (this.databaseconnectiondetails["group"].length == 0) {
                $("#dbconnectionscontainer #groupid").closest(".form-group").addClass('has-error');
                $(".kafkaform").find("#groupid").removeClass("hide").text(getMessage("ValidationMessage1").replace("@replace", 'Group ID'));
                result = false;
            } else {
                $("#dbconnectionscontainer #groupid").closest(".form-group").removeClass('has-error');
            }
            if ($(".kafkaform").find(".connectionname").val().length == 0) {
                $("#dbconnectionscontainer #connectionname").closest(".form-group").addClass('has-error');
                $(".kafkaform").find("#connectionname").removeClass("hide").text(getMessage("ValidationMessage1").replace("@replace", 'Connection Name'));
                result = false;
            } else {
                $("#dbconnectionscontainer #connectionname").closest(".form-group").removeClass('has-error');
            }
        } else {
            _.each(this.state.connectiondetails, (obj, index) => {
                var value = $(".databaseconnectioncontainer ." + obj.id).val();
                this.databaseconnectiondetails[obj.id] = value;
                if (obj.id == "connectionname") {
                    if (value.replace(/\s+/g, '').length == 0) {
                        result = false;
                        $(".databaseconnectioncontainer #" + obj.id).closest(".form-group").addClass('has-error');
                        $(".databaseconnectioncontainer #" + obj.id).text(getMessage("ValidationMessage2").replace("@replace", obj.name)).removeClass("hide");
                        return
                    }
                }

                if (obj.required) {
                    if (windowsAuth) {
                        if (obj.id == "dbuser" || obj.id == "dbPassword") {
                            bool = true;
                        }
                    }
                    if (!bool && value != undefined && value.length == 0) {
                        result = false;
                        $(".databaseconnectioncontainer #" + obj.id).closest(".form-group").addClass('has-error');
                        $(".databaseconnectioncontainer #" + obj.id).text(getMessage("ValidationMessage1").replace("@replace", obj.name)).removeClass("hide");
                    } else if (obj.pattern && !patt.test(value)) {
                        result = false;
                        $(".databaseconnectioncontainer #" + obj.id).closest(".form-group").addClass('has-error');
                        $(".databaseconnectioncontainer #" + obj.id).text(getMessage("ValidationMessage2").replace("@replace", obj.name)).removeClass("hide");
                    } else {
                        $(".databaseconnectioncontainer #" + obj.id).closest(".form-group").removeClass('has-error');
                        $(".databaseconnectioncontainer #" + obj.id).empty().addClass("hide");
                    }
                }
            });
        }
        return result;
    }
    render() {
        return (
            <>
                <div className="closenewscopeconnection" id="closeconnectionpop" onClick={this.closeconnectionpop}><i className="fa fa-times"></i></div>
                <div className="datasourceconnection-container"></div>
            </>
        );
    }
}