import React from 'react';
import _ from 'underscore';
import L from 'leaflet';
import $ from 'jquery';
import PubSub from 'pubsub-js';
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import * as ChartUtil from "../../js/helpers/utils/chartutil";
import ViewMapdrilldownmodal from "../../ViewStoryboard/MapDrillDownModal";
import { whitelableMap } from "../../js/helpers/utils/whitelable";
import { getGeoUSACodes } from '../helpers/utils/usstates';
import { lang } from "../../js/helpers/utils/languages/language";

var charts = {}

export default class Leaflet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        var data = this.props.data, xaxisName = this.props.xaxisName, xaxisColumnName = this.props.xaxisColumnName, xaxisDataType = this.props.xaxisDataType, yaxisnameArray = this.props.yaxisnameArray, chartId = this.props.chartId, chartType = this.props.chartType, legend = this.props.legend, position = this.props.position, features = this.props.features, isSharedReport = this.props.isSharedReport, colorThemeIndex = this.props.colorThemeIndex, chartSettings = this.props.chartSettings, tooltipNameArray = this.props.tooltipNameArray, trendstats = this.props.trendstats, iscustomstoryboard = this.props.iscustomstoryboard, xaxisidentity = this.props.xaxisidentity, yAxisDataTypeArray = this.props.yAxisDataTypeArray, ismultifact = this.props.ismultifact, multifactentities = this.props.multifactentities, datamodelId = this.props.datamodelId, facttables = this.props.facttables, reportid = this.props.reportid;
        var viewMapDrillDownModal;
        var chartPoints = {};
        var columnsData = this.props.columnsData;
        var viewChartDrillDownModal = null;
        var isDrillDownEnable = false;
        var isViewDataEnable = false;
        var isExportChartEnable = false;
        var isFilterEnable = false;
        var isDrillThroughEnable = false;
        var chart;
        var isDirect = 0;
        var isStoredProcedure = 0;
        var colorThemesArray = ChartInfo.functions.getColorThemes();
        var x, y;

        this.state.chartid = chartId;
        if(charts!=undefined && chartId!=undefined && charts[chartId]!=undefined &&  charts[chartId].refs!=undefined &&  charts[chartId].refs.chartComponent!=undefined &&  charts[chartId].refs.chartComponent.chart!=undefined){
            charts[chartId].refs.chartComponent.chart.destroy();
        }
        if(chartSettings.numberformat==undefined){
            var numberformat={};
            numberformat.decimals="0_2";
            chartSettings["numberformat"]=numberformat;
            }
        /*Set Chart height to fix resize issues */
        var chartnumber = chartId.replace("chart", "");
        this.state.chartnumber = chartnumber;
        //This is to remove previous chart from DOM
        //this.unbindEvents(chartnumber);					
        if ($('.tileview[data-num=' + chartnumber + ']').length === 1) {
            var setheight = $('.tileview[data-num=' + chartnumber + ']').height() - 42;
            $("#chart" + chartnumber).height(setheight);
        }
        sessionStorage.setItem('pos', position);

        $('#' + chartId).empty();
        $('#' + chartId).append('<div id="leaflet' + chartnumber + '" class="leafletcontainer"></div>');
        var map = new L.map('leaflet' + chartnumber + '');
        L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(map);

        var that = this;
        var markers = new L.featureGroup();
        var coordinates = "";
        var arr = [];
        var addressArray = [];
        var marker;
        var aggType = data[0].aggType;
        var xName = xaxisName;
        var xDataType = xaxisDataType;
        var yName = yaxisnameArray;
        var xaxisNameArray = xaxisName.split(",");
        var xaxisDataTypes = xaxisDataType.split(",");
        if (xaxisNameArray.length != 2 && xaxisDataTypes.length != 2 && xaxisDataTypes[0] != "number" && xaxisDataTypes[1] != "number") {
            $.map(data, function (value, l) {
                $.map(yaxisnameArray, function (name, k) {
                    $.map(data[0].data, function (i, j) {
                        var latlang = getGeoUSACodes(i.name);
                        if (latlang == undefined) {
                            latlang = i.name.split(",")
                        }
                        if (latlang != undefined && latlang.length == 2 && !isNaN(latlang[0]) && !isNaN(latlang[1]) && tooltipNameArray.lenth == 0) {
                            marker = new L.Marker([latlang[0], latlang[1]]).on('click', function (e) { markerOnClick(e, xName, xDataType, yName, i); }).addTo(map).bindPopup(i.name + "<br>" + yaxisnameArray[k] + " (" + value.aggType + "): " + i.y);
                            arr.push(marker);
                        }
                        else if (latlang != undefined && latlang.length == 2 && !isNaN(latlang[0]) && !isNaN(latlang[1])) {
                            var name = "";
                            if (tooltipNameArray != undefined && tooltipNameArray.length != 0) {
                                $.each(tooltipNameArray, function (l) {
                                    var tooltipnow = tooltipNameArray[l];
                                    var tooltipvalue = i[("t" + l)];
                                    var tooltipagg = i[("ta" + l)];
                                    name += '<b>' + tooltipnow + ' <span class=bocaggtype>[' + tooltipagg + '] </span>' + ':'
                                    if ("number" == "date" && tooltipagg.toLocaleString().toLowerCase().indexOf("count") < 0) {
                                        var d = new Date(tooltipvalue);
                                        name += '<b>' + d.toLocaleString() + '</b></b><br>'
                                    } else {
                                        var num = ChartInfo.functions.addFormatting(tooltipvalue, chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator)
                                        name += '<b>' + num + '</b></b><br>'
                                    }
                                });
                            }
                            var num = ChartInfo.functions.addFormatting(i.y, chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator)
                            name = name.substring(0, name.length - 4);
                            name += '<br><b>' + xaxisName + "[" + i.name + "]:" + (+latlang[0] + "<br>" + latlang[1]) + '<br>' + yaxisnameArray[k] + '[';
                            name += value.aggType == 'customaggregatedfield' ? 'raw' : value.aggType;
                            name += ']:' + num + '<b></span>';
                            marker = new L.Marker([latlang[0], latlang[1]]).on('click', function (e) {
                                markerOnClick(e, xName, xDataType, yName, i);
                            }).addTo(map).bindPopup(name);
                            arr.push(marker);
                        }

                        if (tooltipNameArray.length != 0 && marker != undefined) {
                            marker.on('mouseover', function (e) {
                                this.openPopup();
                            });
                        }
                    });

                });
            });
        } else {
            var nzoom = 12;
            if (data[0].data != undefined && data[0].data.length != 0) {
                $.map(data, function (value, l) {
                    $.map(data[0].data, function (i, j) {
                        var lanLong = i.name.split(",")
                        if (lanLong != undefined && lanLong.length == 2 && !isNaN(lanLong[0]) && !isNaN(lanLong[1])) {
                            if (tooltipNameArray.length == 0) {
                                var num = ChartInfo.functions.addFormatting(i.y, chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator)
                                marker = new L.Marker([lanLong[0], lanLong[1]]).on('click', function (e) { markerOnClick(e, xName, xDataType, yName, i); }).addTo(map).bindPopup("<b>" + xaxisNameArray + ":" + "<br>" + lanLong[0] + " , " + lanLong[1] + "<br>" + yaxisnameArray + " [" + value.aggType + "]: " + num + "<b>");
                                arr.push(marker);
                            }
                            else {
                                var name = "";
                                $.each(tooltipNameArray, function (l) {
                                    var tooltipnow = tooltipNameArray[l];
                                    var tooltipvalue = i[("t" + l)];
                                    var tooltipagg = i[("ta" + l)];
                                    name += '<b>' + tooltipnow + ' <span class=bocaggtype>[' + tooltipagg + '] </span>' + ':'
                                    if ("number" == "date" && tooltipagg.toLocaleString().toLowerCase().indexOf("count") < 0) {
                                        var d = new Date(tooltipvalue);
                                        name += '<b>' + d.toLocaleString() + '</b></b><br>'
                                    } else {
                                        var num = ChartInfo.functions.addFormatting(tooltipvalue, chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator)
                                        name += '<b>' + num + '</b></b><br>'
                                    }
                                });
                                name = name.substring(0, name.length - 4);
                                name += '<br><b>' + xaxisName + ":" + (+lanLong[0] + "<br>" + lanLong[1]) + '<br>' + yaxisnameArray[0] + "[" + value.aggType + ']:' + i.y + '<b></span>'
                                marker = new L.Marker([lanLong[0], lanLong[1]]).on('click', function (e) { markerOnClick(e, xName, xDataType, yName, i); }).addTo(map).bindPopup(name);
                                arr.push(marker);
                            }

                            if (tooltipNameArray.length != 0) {
                                marker.on('mouseover', function (e) {
                                    this.openPopup();
                                });
                            }
                        }

                    });
                });


            }
        }
        if (arr.length > 0) {
            var group = L.featureGroup(arr).addTo(map);
            map.fitBounds(group.getBounds());
        }
        else {
            map.fitBounds([
                [74.86788912917916, 254.88281250000003],
                [-9.102096738726443, -204.25781250000003]
            ]);
        }
        setTimeout(function () { map.invalidateSize() }, 400);
        map.on('resize', function (e) {
            map.fitBounds(map.getBounds());
        });
        $('#leaflet' + chartnumber).on("resize", function (e) {
            map.fitBounds(map.getBounds());
        });
        $(window).on("resize", function (e) {
            var group = L.featureGroup(arr).addTo(map);
            map.fitBounds(group.getBounds());
        });
        var isDrillDownVisible = "disabled";
        var that = this;
        function markerOnClick(e, xName, xDataType, yName, yValue) {
            isDrillDownEnable = ChartUtil.checkDrillDownEnable(features, position, isSharedReport, iscustomstoryboard);
            isViewDataEnable = ChartUtil.viewDataEnable(features, position, isSharedReport, iscustomstoryboard);
            isFilterEnable = ChartUtil.checkFilterEnable(features, position, isSharedReport, iscustomstoryboard);
            isDrillThroughEnable = ChartUtil.checkDrillThroughEnable(features, position, isSharedReport, iscustomstoryboard);
            var top = e.containerPoint.y - 15;
            var left = e.containerPoint.x + 10;
            var containerWidth = e.target._map._container.offsetWidth;
            var containerHeight = e.target._map._container.offsetHeight;
            if (top >= containerHeight - 50) { top = top - 50; }
            if (top < 100) { top = 100; }

            var isDrillDownVisible = "disabled";
            var isDrillDownLabel = "disabled";
            if (xaxisDataType == "customrange") {
                isDrillDownEnable = false;
                isKeepOnlyVisible = false;
                isExcludeVisible = false;
            }

            var ydatatype = "";
            _.each(yAxisDataTypeArray, function (value) {
                if (value == "custommeasurehierarchy") {
                    ydatatype = value;
                }
                else if (value == "customaggregatedfield") {
                    ydatatype = value;
                }
            })
            if (xaxisDataType == "" || xaxisDataType == undefined || e.point != undefined && e.point.ydatatype == "custommeasurehierarchy" || xaxisDataType == "customrange") {

                isDrillDownEnable = false;
            } else {
                isDrillDownEnable = true;
            }

            if (isDrillDownEnable) {
                isDrillDownVisible = "pie-drilldown";
                isDrillDownLabel = "";
            }

            var isViewDataVisible = "disabled";
            var isViewDataLabel = "disabled";
            /*if(isViewDataEnable){
                isViewDataVisible="pie-viewdata";
                isViewDataLabel="";
            }*/

            //var dataLength=data[0].data.length;
            var isExcludeVisible = "disabled";
            var isKeepOnlyLabel = "disabled";
            var isKeepOnlyVisible = "disabled";
            var isExcludeLabel = "disabled";
            /*if(isFilterEnable && dataLength>1){
                isKeepOnlyVisible="keeponly-point";
                isKeepOnlyLabel="";
                isExcludeVisible="exclude-point";
                isExcludeLabel="";
            }*/
            var isydrilldownenable = "disabled";
            var yaxisname = yName[0];
            var drilldownmeasurearray = $.grep(ChartInfo.functions.getMeasureHierarchy(), function (e1) { return e1.name == yaxisname });
            var hierarchylist = [];
            /*if(drilldownmeasurearray.length > 0){
                isydrilldownenable = "measuredilldown";
            }*/
            var isDrillThroughVisible = "disabled";
            var isDrillThroughLabel = "disabled";
            if (xaxisDataType == "customrange") {
                isDrillThroughEnable = false;
            }
            if (isDrillThroughEnable) {
                isDrillThroughVisible = "pie-drillthrough1";
                isDrillThroughLabel = "";
            }
            var isthreedpie;
            if (chartType == "3dpie") {
                isthreedpie = "disabled";
            }


            if (sessionStorage.getItem("drilldown" + chartnumber) != null && sessionStorage.getItem("drilldown" + chartnumber) != "null") {
                $('#chart' + chartnumber + '').before('<div class="pie-menu" style="top:' + top + 'px;left:' + left + 'px;"><div class="boc-pie-menu-start"></div><div class="boc-pie-menu-end"></div><div class="boc-piemenu-close" id="piemenuclose2"><i class="fa fa-times"></i></div>'
                    + '<div class="pie-list-wrapper">'
                    + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut " data-num="' + chartnumber + '"> <div class="boc-pie-menu-center">  </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                    + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isViewDataVisible + '" data-num="' + chartnumber + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label ' + isViewDataLabel + '" id="isviewdatalabel2"><span class="pietooltip">View<br>Data</span><i class="fa fa-table"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                    + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isydrilldownenable + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label "><span class="pietooltip">Y-Drill<br>Down</span><i class="fa fa-level-down"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                    + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isDrillThroughVisible + '" data-num="' + chartnumber + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label ' + isDrillThroughLabel + '" id="isdrillthroughlabel1""><span class="pietooltip">Drill<br>Through</span><i class="fa fa-level-down fa-flip-horizontal"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                    + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isDrillDownVisible + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label ' + isDrillDownLabel + '" id="isdrilldownlabel2"><span class="pietooltip">Drill<br>Down</span><i class="fa fa-arrow-down"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                    + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut " data-num="' + chartnumber + '"> <div class="boc-pie-menu-center">  </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                    /*+'<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut"> <div class="boc-pie-menu-center">  </div></div>'	*/
                    + '<span class="piemenuwrapper"></span>'
                    + '</div>'
                    + '<span class="wrapper"></span>'
                );
            }
            else {
                $('#chart' + chartnumber + '').before('<div class="pie-menu" style="top:' + top + 'px;left:' + left + 'px;"><div class="boc-pie-menu-start"></div><div class="boc-pie-menu-end"></div><div class="boc-piemenu-close" id="piemenuclose2"><i class="fa fa-times"></i></div>'
                    + '<div class="pie-list-wrapper">'
                    + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isKeepOnlyVisible + '" data-num="' + chartnumber + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label keeponly ' + isKeepOnlyLabel + '" id="iskeeponlylabel2"><span class="pietooltip"> '+lang["storyboard.keep"]+' <br>'+lang["storyboard.only"]+'</span><i class="fa fa-check"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                    + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isViewDataVisible + '" data-num="' + chartnumber + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label ' + isViewDataLabel + '" id="isviewdatalabel2"><span class="pietooltip">'+lang["storyboard.view"]+'<br>'+lang["storyboard.data"]+'</span><i class="fa fa-table"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                    + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isydrilldownenable + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label"><span class="pietooltip">'+lang["storyboard.y-drill"]+'<br>'+lang["storyboard.down"]+'</span><i class="fa fa-level-down"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                    + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isDrillThroughVisible + '" data-num="' + chartnumber + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label ' + isDrillThroughLabel + '" id="isdrillthroughlabel1""><span class="pietooltip">'+lang["storyboard.drill"]+'<br>'+lang["storyboard.through"]+'</span><i class="fa fa-level-down fa-flip-horizontal"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                    + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isDrillDownVisible + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label ' + isDrillDownLabel + '" id="isdrilldownlabel2"><span class="pietooltip">'+lang["storyboard.drill"]+'<br>'+lang["storyboard.down"]+'</span><i class="fa fa-arrow-down"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                    + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isExcludeVisible + '" data-num="' + chartnumber + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label excludeonly ' + isExcludeLabel + '" id="isexcludelabel2"><span class="pietooltip">'+lang["storyboard.exclude"]+'</span><i class="fa fa-times"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                    /*+'<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut"> <div class="boc-pie-menu-center">  </div></div>'*/
                    + '<span class="piemenuwrapper"></span>'
                    + '</div>'
                    + '<span class="wrapper"></span>'
                );
            }
            setTimeout(function () {
                $('.boc-pie-menu-start, .boc-pie-menu-end, .boc-pie-menu-filter').toggleClass("openpie");
            }, 200);
            $('.boc-piemenu-close, .piemenuwrapper').on("click", function () {
                $('.boc-pie-menu-start, .boc-pie-menu-end, .boc-pie-menu-filter').toggleClass("openpie");
                setTimeout(function () {
                    $('.pie-menu').remove();
                }, 800);
            });
            $('.wrapper').on("click", function () {
                $('.pie-menu').remove();
            });
            var filterArray = [];
            $('.pie-drillthrough1').on("click", function (e) {
                e.preventDefault();
                e.stopPropagation();
                $('.maxanim7[data-num=' + chartnumber + '] .wrapper').css("z-index", "99");
                var top = e.pageY;
                var left = e.pageX;
                var $this = e.currentTarget;
                var that = this;
                PubSub.publish('pie-drillthrough', e);
                // require(["views/viewreport/drillthroughoptionsview"], function (DrillThroughOptionsView) {
                //     new DrillThroughOptionsView({
                //         currentChartNumber: chartnumber,
                //         postop: top,
                //         posleft: left
                //     });
                // })
                if (tooltipNameArray != undefined && tooltipNameArray.length != 0) {
                    if (xaxisidentity.length != 2) {
                        var filterObj = {};
                        filterObj["columnname"] = xName
                        filterObj["columndisplayname"] = xName
                        filterObj["data"] = yValue.name
                        filterObj["dataType"] = yValue.ydatatype
                        filterArray[0] = filterObj;

                    }
                    else if (xaxisidentity.split(",")[0] == "latitude") {
                        var filterObj = {};
                        filterObj["columnname"] = xName.split(",")[0];
                        filterObj["columndisplayname"] = xName.split(",")[0];
                        filterObj["data"] = e.target._popup._content.split('<b>')[3].split(':')[1].split(",")[0].split("<br>")[0].trim();
                        filterArray[0] = filterObj;
                        filterObj = {};
                        filterObj["columnname"] = xName.split(",")[1];
                        filterObj["columndisplayname"] = xName.split(",")[1];
                        filterObj["data"] = e.target._popup._content.split('<b>')[3].split(':')[1].split(",")[0].split("<br>")[1].trim();
                        filterArray[1] = filterObj;
                    }
                    else if (xaxisidentity.split(",")[0] == "longitude") {
                        var filterObj = {};
                        filterObj["columnname"] = xName.split(",")[0];
                        filterObj["columndisplayname"] = xName.split(",")[0];
                        filterObj["data"] = e.target._popup._content.split('<b>')[3].split(':')[1].split(",")[0].split("<br>")[1].trim();
                        filterArray[0] = filterObj;
                        filterObj = {};
                        filterObj["columnname"] = xName.split(",")[1];
                        filterObj["columndisplayname"] = xName.split(",")[1];
                        filterObj["data"] = e.target._popup._content.split('<b>')[3].split(':')[1].split(",")[0].split("<br>")[0].trim();
                        filterArray[1] = filterObj;
                    }


                } else {
                    var filterObj = {};
                    if (xaxisidentity.length != 2) {
                        var filterObj = {};
                        filterObj["columnname"] = xName
                        filterObj["columndisplayname"] = xName
                        filterObj["data"] = yValue.name
                        filterObj["dataType"] = yValue.ydatatype
                        filterArray[0] = filterObj;
                    }
                    else if (xaxisidentity.split(",")[0] == "latitude") {
                        filterObj["columnname"] = xName.split(",")[0];
                        filterObj["columndisplayname"] = xName.split(",")[0];
                        filterObj["data"] = e.target._popup._content.split('<br>')[1].split(",")[0].trim();
                        filterArray[0] = filterObj;
                        filterObj = {};
                        filterObj["columnname"] = xName.split(",")[1];
                        filterObj["columndisplayname"] = xName.split(",")[1];
                        filterObj["data"] = e.target._popup._content.split('<br>')[1].split(",")[1].trim();
                        filterArray[1] = filterObj;
                    }
                    else if (xaxisidentity.split(",")[0] == "longitude") {
                        filterObj["columnname"] = xName.split(",")[0];
                        filterObj["columndisplayname"] = xName.split(",")[0];
                        filterObj["data"] = e.target._popup._content.split('<br>')[1].split(",")[1].trim();
                        filterArray[0] = filterObj;
                        filterObj = {};
                        filterObj["columnname"] = xName.split(",")[1];
                        filterObj["columndisplayname"] = xName.split(",")[1];
                        filterObj["data"] = e.target._popup._content.split('<br>')[1].split(",")[0].trim();
                        filterArray[1] = filterObj;
                    }
                }
            });
            ChartInfo.functions.setExcludeFilterData(filterArray);
            $('.pie-drilldown').on("click", function () {
                $('.boc-pie-menu-start, .boc-pie-menu-end, .boc-pie-menu-filter').toggleClass("openpie");
                setTimeout(function () {
                    $('.pie-menu').remove();
                }, 800);

                $('.wrapper').on("click", function () {
                    $('.pie-menu').remove();
                });


                var point = {};
                point.name = e.target._popup._content.split('<br>')[0];
                point.series = {};
                point.series.options = {};
                point.series.name = yName[0];
                point.options = yValue;
                point.series.options = yValue;
                point.series.options.yaxisname = yName[0];
                var value = "";
                if (xaxisidentity.split(",").length == 2 && tooltipNameArray != undefined && tooltipNameArray.length != 0) {
                    if (xaxisidentity.split(",")[0] == "latitude") {
                        value = e.target._popup._content.split('<b>')[3].split(':')[1].split(",")[0].split("<br>")[0]/*+","+e.target._popup._content.split('<b>')[3].split(':')[1].split(",")[0].split("<br>")[1]*/
                    } else if (xaxisidentity.split(",")[0] == "longitude") {
                        value = e.target._popup._content.split('<b>')[3].split(':')[1].split(",")[0].split("<br>")[1]
                    }
                } else {

                    if (xaxisidentity.split(",")[0] == "latitude") {
                        value = e.target._popup._content.split('<br>')[1].split(",")[0];
                    }
                    else if (xaxisidentity.split(",")[0] == "longitude") {
                        value = value = e.target._popup._content.split('<br>')[1].split(",")[1];
                    }
                }
                if (iscustomstoryboard) {
                    columnsData = ChartInfo.functions.getCustomstroyboardReportColumns(chartnumber);
                }
                viewMapDrillDownModal = new ViewMapdrilldownmodal({
                    chartId: chartId,
                    chartnumber: chartnumber,
                    columns: JSON.stringify(columnsData),
                    xaxisName: xaxisColumnName.split(",")[0], //e.target._popup._content.split('<br>')[0],
                    xaxisDisplayName: xName.split(",")[0],//e.target._popup._content.split('<br>')[0],
                    xaxisType: xDataType.split(",")[0],
                    value: value,
                    chart: charts["chart" + chartnumber],
                    point: point,
                    pos: position,
                    //broker: broker,
                    features: features,
                    isSharedReport: isSharedReport,
                    colorThemeIndex: colorThemeIndex,
                    chartSettings: chartSettings,
                    tooltipNameArray: tooltipNameArray,
                    trendstats: trendstats,
                    iscustomstoryboard: iscustomstoryboard,
                    xaxisidentity: xaxisidentity,
                    chartutils: that,
                    displayName: xName.split(",")[0],
                    ismultifact: ismultifact,
                    multifactentities: multifactentities,
                    datamodelId: datamodelId,
                    facttables: facttables,
                    yaxisnameArray: yaxisnameArray
                });
            });
        }
        try {
            var group = L.featureGroup(arr).addTo(map);
            map.fitBounds(group.getBounds());
            setTimeout(function () { map.invalidateSize() }, 400);
            map.on('resize', function (e) {
                map.fitBounds(map.getBounds());
            });
        } catch (e) {

        }
        var colors = colorThemesArray[0]
        var userOptions = {};
        userOptions["colors"] = colors
        map["userOptions"] = userOptions;
        charts[chartId] = map;
        ChartInfo.functions.setChartTypeForMap(chartnumber, "leaflet");
        return (<></>);
    }
    componentDidMount() {
        var compos = {};
        compos["map"] = charts[this.state.chartid];
        compos["chartType"]= this.props.chartType;
        ChartInfo.functions.setChartComponents(this.state.chartnumber, compos);
    }
}