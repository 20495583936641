import React from 'react';
import PubSub from 'pubsub-js'
import $ from "jquery";
import BootstrapTable from 'react-bootstrap-table-next';
import BootStrapTableComp from "./bootstraptable";

import paginationFactory from 'react-bootstrap-table2-paginator';
const RemotePagination = ({ data, page, columns, chartnumber, sizePerPage, onTableChange, totalSize,showTotal}) => (


    <div className={`drawbootstraptable${chartnumber}`}>
        <div className="notifications-table-container">
            <BootstrapTable
                remote
                keyField={columns[0].dataField}
                data={data}
                columns={columns}
                // pagination={paginationFactory({ page, sizePerPage, totalSize,showTotal})}
                pagination={paginationFactory({ page, sizePerPage, totalSize})}
                onTableChange={onTableChange}
                noDataIndication="No matching records found"
            />
        </div>
    </div>
);

export default class BootStrapTableHeaderTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: props.page,
            data: props.data,
            sizePerPage: props.sizePerPage,
            storedprops: props.storedprops,
            columns: props.columns,
            chartnumber: props.chartnumber,
            totalRecords: props.totalRecords,
            showTotal: true
        };
    }


    render() {
        const { data, sizePerPage, page, columns, totalRecords, chartnumber,showTotal} = this.state;
        return (
            <div className="col-xs-12 marg-top-10">
                <div className="notifications-table-container">
                    <RemotePagination
                        data={data}
                        page={page}
                        sizePerPage={sizePerPage}
                        totalSize={totalRecords}
                        onTableChange={this.handleTableChange}
                        columns={columns}
                        chartnumber={chartnumber}
                        showTotal={true}
                    />
                </div>
            </div>
        );
    }
    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder,showTotal}) => {
        if (this.props.storedprops != undefined && this.props.storedprops.tableInfo != undefined) {
            if (page != undefined) {
                this.props.storedprops.tableInfo.page = page;
            }
            if (sizePerPage != undefined) {
                this.props.storedprops.tableInfo.pageLimit = sizePerPage;
            }
            if (sortField != undefined) {
                this.props.storedprops.tableInfo.groupBy = sortField;
            }
            if (showTotal != undefined) {
                this.props.storedprops.tableInfo.showTotal = true;
            }
            if (sortOrder == undefined || sortOrder === 'asc') {
                this.props.storedprops.tableInfo.sortBy = 'asc';
            }else{
                this.props.storedprops.tableInfo.sortBy = 'desc';
            }

            var bootObj = {};
            bootObj["storedprops"] = this.props.storedprops;
            $(".pivotdataview .ball-pulse").show();
            $(".pivotdataview .ball-pulse").css({"z-index": "9"})
            var bootStrapTable = new BootStrapTableComp(bootObj);
            bootStrapTable.render();
        }
    }
}