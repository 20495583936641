import React from "react";
import _ from 'underscore';
import * as Highcharts from 'highcharts/highstock';
import PubSub from "pubsub-js";
import { whitelableMap } from "../../js/helpers/utils/whitelable";
import SendRequest from '../../SendRequest';
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import { lang } from "../../js/helpers/utils/languages/language";
import * as MLCharts from "./mlcharts";
const $=window.$;
export default class MLKeyInfluencersBodyView extends React.Component {
    constructor(props) {
        super(props);
        this.options = props;
        this.features = this.options.features;
        this.isDirect = this.options.isDirect;
        this.broker = this.options.broker;
        this.reportId = this.options.reportId;
        this.datamodelId = this.options.datamodelId;
        this.xaxis = this.options.xaxis;
        this.yaxis = this.options.yaxis;
        this.isYaxisCustomField = this.options.isYaxisCustomField
        this.chartnum = this.options.chartnum;
        this.response = this.options.response;
        this.xAxisDatatype = this.options.xAxisDatatype;
        this.collection = this.options.collection;
        this.topFourKeyInfluencers = this.options.topFourKeyInfluencers;
        this.datedropdown = this.options.datedropdown;
        this.datatableData = this.options.datatableData;
        this.topFiveKeyInfluencers = [];
        this.interceptForSimulation = this.options.interceptForSimulation;
        this.dimensions = this.options.dimensions;
        this.totalCharts = this.options.totalCharts;
        this.columnIdsToNamesMap = this.options.columnIdsToNamesMap;
        this.xAxisDisplayName = this.xaxis;
        this.yAxisDisplayName = this.yaxis;
        this.isFromStrybok = this.options.isFromStrybok
        this.tabid = this.options.tabid
        var that = this;
        _.each(that.dimensions, function (dimension, index) {
            if (dimension.columnName == that.xaxis) {
                that.xAxisDisplayName = dimension.columnDisplayName;
            }
            if (dimension.columnName == that.yaxis) {
                that.yAxisDisplayName = dimension.columnDisplayName;
            }
            if (dimension.columnDisplayName == that.yaxis) {
                that.yaxis = dimension.columnName;
            }
        });
        that.render();
        this.drawDateChartForMLEve=PubSub.subscribe("drawDateChartForML",this.drawDateChartForMLEve.bind(this));
    }
    drawDateChartForMLEve(message,chartnumber){
        this.drawDateChartForML(chartnumber);
    }
    render(){
        this.applyfiltesandredrawmlchart();
        return(
            <React.Fragment/>
        )
    }
    applyfiltesandredrawmlallchart(){
        var that = this;
        for(var i = 5; i < that.totalCharts ;i++){
            var incrementer = i;
            i = that.isFromStrybok?that.tabid+"_"+i:i;
            that.chartnum = i;
            $("#spinnerchart" + (i)).show();
            that.applyfiltesandredrawmlchart();
            i = incrementer;
        }
    }
    applyfiltesandredrawmlchart(){
        var that = this;
        var islegend = false;
        var yaxis = that.yaxis;
        var yAxisDisplayName = that.yAxisDisplayName;
        var yAxisDataType = "number";
        var xaxis = that.xaxis;
        var xAxisDisplayName = that.xAxisDisplayName;
        var xaxisdataType = that.xAxisDatatype;
        var legend = "";
        var legendDataType = "";
        var	percentileValue  = "25,";
        var aggregation="";
        var chartType = "";
        var orderType = "";
        var sortType = "";
        var rowLimit = 10000;
        var xaxisAggregation = "group";
        var dateHistogramInterval = "";
        var indexName = "";
        var toolTips = "";
        var zaxisField = "";
        var calculation = "";
        var zAxisDisplayName = "";
        var toolTipDisplayName = "";
        var legendcolumnDisplayName = "";
        var esType = "";
        var referencelineobj = ""; 
        var xAxisIdentity = "";
        var sortingColumnAggregation = "";
        var sortingColumns = "";
        var rankingColumn = "";
        var rankingColumnAggregation ="";
        var isPurgedReport = false;
        var isImportedReport = false;
        var selectedDate = $('.datedropdown'+that.chartnum+' .datelistformlchart').val();
        var selectedDateDataType = "date"
        var i = that.chartnum;
        var chartPosition = that.isFromStrybok?parseInt(i.split("_")[1]):i;
        var keyInfluencer ;
        var keyInfluencerDataType;
        aggregation = "sum";
        orderType = "top";
        rankingColumn = that.yaxis;
        rankingColumnAggregation = "sum";
        var zAxisObject={};
        var zAxisData =[];
        var combinationchartsData =[];
        var nlgDataFlagForKeyInfluencers ="";
        var clickedValueFilterObj = {};
        clickedValueFilterObj["dataType"]=xaxisdataType;
        clickedValueFilterObj["tablename"]=xaxis;
        clickedValueFilterObj["columndisplayname"]=xaxis;
        clickedValueFilterObj["columnname"]=xaxis;
        clickedValueFilterObj["data"]="Copiers";
        var outlierFilterObj = {};
        outlierFilterObj["dataType"]=yAxisDataType;
        outlierFilterObj["tablename"]=yaxis;
        outlierFilterObj["columndisplayname"]=yAxisDisplayName;
        outlierFilterObj["columnname"]=yaxis;
        outlierFilterObj["data"]="1";
        var rangeFilterObj = {};
        rangeFilterObj["dataType"]=yAxisDataType;
        rangeFilterObj["tablename"]=yaxis;
        rangeFilterObj["columndisplayname"]=yAxisDisplayName;
        rangeFilterObj["columnname"]=yaxis;
        rangeFilterObj["fromRange"]="0";
        rangeFilterObj["toRange"]="0";
        rangeFilterObj["notBetween"]=true;
        var customFields =  JSON.parse(sessionStorage.getItem("customFields"));
        var temp=customFields;
        var res;
        let getrequestHeaders = {
            method: "get",
            headers: new Headers({
              "Content-Type": "application/json"
            })
          };
          let postrequestHeaders = {
            method: "post",
            headers: new Headers({
              "Content-Type": "application/json"
            })
          };
        var customFieldsNamesList = [];
        _.each(temp,function(customfields){
            customFieldsNamesList.push(customfields.name);
            _.each(customfields.parametrFieldsArray,function(value){
                var customparameter=ChartInfo.functions.getCustomParameter();					
                res=$.grep(customparameter,function(e){
                    return e.parametername==value;
                })
                var formula=customfields.formula;
                var scriptcondition=customfields.scriptcondition;
                var fields=customfields.fields;
                var sqlformula = customfields.sqlformula;
                if(res.length!=0){
                    customfields.formula=formula.replace("["+value+"]",res[0].parametercurrentvalue);
                    customfields.scriptcondition=scriptcondition.replace("["+value+"]",res[0].parametercurrentvalue);
                    customfields.fields=fields.replace("["+value+"]",res[0].parametercurrentvalue);
                    customfields.sqlformula = sqlformula.replace("["+value+"]",res[0].parametercurrentvalue);
                }else{
                    _.each(ChartInfo.functions.getCustomParameter(), function(values){
                        if(customfields.parametrFieldsArray.indexOf(values.parametername)>=0){
                        customfields.formula=formula.replace("["+values.parametername+"]",values.parametercurrentvalue);
                        customfields.scriptcondition=scriptcondition.replace("["+values.parametername+"]",values.parametercurrentvalue);
                        customfields.fields=fields.replace("["+value+"]",values.parametercurrentvalue);
                        customfields.sqlformula = sqlformula.replace("["+values.parametername+"]",values.parametercurrentvalue);
                        }
                    });							
                }
            });
        });
        customFields=temp;
        if(chartPosition== 4){
            if(that.xAxisDatatype.toLowerCase() == "string"){
                chartType = "bar";
            }else{
                aggregation = "raw";
                chartType = "scatter";
            }
            $('.mlcharttitle[data-num="'+i+'"]').html(lang["mlreports.compareperform"]+" "+"<b>"+yAxisDisplayName+"</b>"+" "+lang["mlreports.acrosseach"]+ " " +"<b>"+xAxisDisplayName+"</b>");
            $('.mlcharttitle[data-num="'+i+'"]').attr('title',lang["mlreports.compareperform"]+" "+yAxisDisplayName+" "+lang["mlreports.acrosseach"]+" " +xAxisDisplayName);
        }else if(chartPosition == 5){
            if(this.datedropdown.length > 0 ){
                $("#datedropdown" + i).show();
            }else{
                return;
            }
            if(selectedDate != undefined && selectedDate != null && selectedDate != ""){
                if(ChartInfo.functions.getDateHistogramStateForML(i) == undefined || ChartInfo.functions.getDateHistogramXaxisDataForML().length == 0){
                    xaxis = selectedDate;
                    xaxisdataType = selectedDateDataType;
                }else{
                    var dateColumn = ChartInfo.functions.getDateHistogramXaxisDataForML();
                    xaxis = dateColumn[0].columnName;
                    xaxisdataType = dateColumn[0].dataType;
                    $(".datelistformlchart option[value='"+xaxis+"']").prop('selected', true);
                }
            }
            chartType = "line";
            if (that.xAxisDatatype.toLowerCase() == "string") {
                legend = that.xaxis;
                legendDataType = that.xAxisDatatype;
                _.each(that.dimensions,function(dimension,index){
                    if(dimension.columnName == xaxis){
                        xAxisDisplayName = dimension.columnDisplayName;
                    }
                    if(dimension.columnName ==yaxis){
                        yAxisDisplayName = dimension.columnDisplayName;
                    }
                    if(dimension.columnName == legend){
                        legendcolumnDisplayName = dimension.columnDisplayName;
                    }
                });
            }else{
                if(selectedDate != undefined && selectedDate != null && selectedDate != ""){
                    aggregation = aggregation +","+ aggregation;
                    yAxisDataType = yAxisDataType + " , "+ that.xAxisDatatype;
                    yaxis = that.yaxis+","+that.xaxis;
                    percentileValue += "25,";
                    var y2;
                    _.each(that.dimensions,function(dimension,index){
                        if(dimension.columnName == xaxis){
                            xAxisDisplayName = dimension.columnDisplayName;
                        }
                        if(dimension.columnName == that.yaxis){
                            yAxisDisplayName = dimension.columnDisplayName;
                        }
                        if(dimension.columnName == that.xaxis){
                            y2 = dimension.columnDisplayName;
                        }
                    });
                    yAxisDisplayName = yAxisDisplayName + ","+y2;
                }
            }
            xaxisAggregation = "dateHistogram";
            if(ChartInfo.functions.getDateHistogramStateForML(i) == undefined){
                ChartInfo.functions.setDateHistogramStateForML("M",i);
            }else if(ChartInfo.functions.getDateHistogramStateForML(i) == "reset"){
                xaxisAggregation = "group";
            }
            dateHistogramInterval = ChartInfo.functions.getDateHistogramStateForML(i);
            $('.mlcharttitle[data-num="'+i+'"]').html(lang["mlreports.compareperform"]+" "+"<b>"+yaxis+"</b>"+" "+lang["mlreports.acrosseach"]+ " " +"<b>"+xaxis +"</b>"+" "+lang["mlreports.overtime"]);
            $('.mlcharttitle[data-num="'+i+'"]').attr('title',lang["mlreports.compareperform"]+" "+yaxis+" "+lang["mlreports.acrosseach"]+" "+xaxis +" "+lang["mlreports.overtime"]);
        }else if(chartPosition == 6){	
            nlgDataFlagForKeyInfluencers ="nlgDataChart";
            chartType = "nlg";
            $("#mlchart"+i).empty();
            $("#spinnerchart" + i).show();
        }else if(chartPosition >= 7 && chartPosition<=10){
            var z = chartPosition-7;
            if(that.topFourKeyInfluencers[z]==undefined){
                $('.tileview[data-num="'+i+'"]').hide()
                return;
            }
            keyInfluencer = that.topFourKeyInfluencers[z].name;
            keyInfluencerDataType = that.topFourKeyInfluencers[z].ydatatype;
            $('.mltooltip[data-num='+i+']').removeClass("hide");
            $('.mltooltip[data-num='+i+']').attr('title',lang["mlreports.tooltiploading"]);
            if(that.xAxisDatatype.toLowerCase() == "string"){
                chartType = "bar";
                if(keyInfluencerDataType == "string"){
                    // heatmap
                    chartType = "heatmap";
                    nlgDataFlagForKeyInfluencers ="nlgDataForHeatMap";
                    xaxisAggregation = "group"
                    percentileValue += "25,";
                    legend = keyInfluencer;
                    legendDataType= keyInfluencerDataType;
                    //
                    _.each(that.dimensions,function(dimension,index){
                        if(dimension.columnName == xaxis){
                            xAxisDisplayName = dimension.columnDisplayName;
                        }else if(customFieldsNamesList.indexOf(xaxis)!=-1){
                            xAxisDisplayName = xaxis;
                        }
                        if(dimension.columnName == yaxis){
                            yAxisDisplayName = dimension.columnDisplayName;
                        }else if(customFieldsNamesList.indexOf(yaxis)!=-1){
                            yAxisDisplayName = yaxis;
                        }
                        if(dimension.columnName == legend){
                            legendcolumnDisplayName = dimension.columnDisplayName;
                        }else if(customFieldsNamesList.indexOf(legend)!=-1){
                            legendcolumnDisplayName = legend;
                        }
                    });
                    $('.mlcharttitle[data-num="'+i+'"]').html(lang["mlreports.compareperform"]+" " +"<b>"+yAxisDisplayName+"</b>"+" "+lang["mlreports.acrosseach"]+ " " +"<b>"+xAxisDisplayName+"</b>"+" "+lang["mlreports.overtime"]+" "+legendcolumnDisplayName);
                    $('.mlcharttitle[data-num="'+i+'"]').attr('title',lang["mlreports.compareperform"]+" "+yAxisDisplayName+" "+lang["mlreports.acrosseach"]+" " +xAxisDisplayName+" "+lang["mlreports.overtime"]+" "+legendcolumnDisplayName);
                }else{
                    /*combinationchart*/
                    
                    //for handling rename
                    var keyInfluencerDisplayname ="";
                    _.each(that.dimensions,function(dimension,index){
                        if(dimension.columnName == that.xaxis){
                            xAxisDisplayName = dimension.columnDisplayName;
                        }else if(customFieldsNamesList.indexOf( that.xaxis)!=-1){
                            xAxisDisplayName =  that.xaxis;
                        }
                        if(dimension.columnName == that.yaxis){
                            yAxisDisplayName = dimension.columnDisplayName;
                        }else if(customFieldsNamesList.indexOf( that.yaxis)!=-1){
                            yAxisDisplayName =  that.yaxis;
                        }
                        if(dimension.columnName == keyInfluencer){
                            keyInfluencerDisplayname = dimension.columnDisplayName;
                        }else if(customFieldsNamesList.indexOf(keyInfluencer)!=-1){
                            keyInfluencerDisplayname = keyInfluencer;
                        }
                    });
                    $('.mlcharttitle[data-num="'+i+'"]').html(lang["mlreports.relationb/w"]+" <b>"+yAxisDisplayName+"</b> and <b>"+keyInfluencerDisplayname+"</b>"+" " +lang["mlreports.acrosseach"]+" "+" <b>"+xAxisDisplayName+"</b>");
                    $('.mlcharttitle[data-num="'+i+'"]').attr('title',lang["mlreports.relationb/w"]+" "+" "+yAxisDisplayName+" and "+keyInfluencerDisplayname+""+" "+" "+ lang["mlreports.acrosseach"]+" "+""+xAxisDisplayName+"");
                    
                    yAxisDisplayName = yAxisDisplayName+","+keyInfluencerDisplayname;
                    xAxisDisplayName = xAxisDisplayName;
                    
                    chartType = "combinationchart";
                    nlgDataFlagForKeyInfluencers ="nlgDataForCombinationChart";
                    aggregation = aggregation +","+ aggregation;
                    yAxisDataType = yAxisDataType + " , "+ keyInfluencerDataType;
                    yaxis = that.yaxis+","+keyInfluencer;
                    xaxis = that.xaxis;
                    xaxisdataType = that.xAxisDatatype;;
                    percentileValue += "25,";
                    var combinationchartObjLeft = {};
                    combinationchartObjLeft["columnName"] = that.yaxis;
                    combinationchartObjLeft["chartType"] = "column";
                    combinationchartObjLeft["chartaxisposintion"] = "left";
                    combinationchartsData.push(combinationchartObjLeft);
                    var combinationchartObjRight = {};
                    combinationchartObjRight["columnName"] = keyInfluencer;
                    combinationchartObjRight["chartType"] = "column";
                    combinationchartObjRight["chartaxisposintion"] = "right";
                    combinationchartsData.push(combinationchartObjRight);
                }
            }else{
                if(keyInfluencerDataType == "string"){
                     /*combinationchart*/
                    //for handling rename
                    _.each(that.dimensions,function(dimension,index){
                        if(dimension.columnName == that.xaxis){
                            xAxisDisplayName = dimension.columnDisplayName;
                        }
                        if(dimension.columnName == that.yaxis){
                            yAxisDisplayName = dimension.columnDisplayName;
                        }
                        if(dimension.columnName == keyInfluencer){
                            keyInfluencerDisplayname = dimension.columnDisplayName;
                        }
                    });
                    
                    $('.mlcharttitle[data-num="'+i+'"]').html(lang["mlreports.relationb/w"]+" <b>"+yAxisDisplayName+"</b> and <b>"+xAxisDisplayName+"</b>"+" " +lang["mlreports.acrosseach"]+" "+"<b>"+keyInfluencerDisplayname+"</b>");
                    $('.mlcharttitle[data-num="'+i+'"]').attr('title',lang["mlreports.relationb/w"]+" "+" "+yAxisDisplayName+" and "+xAxisDisplayName+""+" " +lang["mlreports.acrosseach"]+" "+" "+keyInfluencerDisplayname+"");
                    
                    yAxisDisplayName = yAxisDisplayName+","+xAxisDisplayName;
                    xAxisDisplayName = keyInfluencerDisplayname;
                    
                    chartType = "combinationchart";
                    nlgDataFlagForKeyInfluencers ="nlgDataForCombinationChart";
                    aggregation = aggregation +","+ aggregation;
                    yAxisDataType = yAxisDataType + " , "+ that.xAxisDatatype;
                    yaxis = that.yaxis+","+that.xaxis;
                    xaxis = keyInfluencer;
                    xaxisdataType = keyInfluencerDataType;
                    percentileValue += "25,";
                    var combinationchartObjLeft = {};
                    combinationchartObjLeft["columnName"] = that.yaxis;
                    combinationchartObjLeft["chartType"] = "column";
                    combinationchartObjLeft["chartaxisposintion"] = "left";
                    combinationchartsData.push(combinationchartObjLeft);
                    var combinationchartObjRight = {};
                    combinationchartObjRight["columnName"] = that.xaxis;
                    combinationchartObjRight["chartType"] = "column";
                    combinationchartObjRight["chartaxisposintion"] = "right";
                    combinationchartsData.push(combinationchartObjRight);
                }else{
                    // "bubble";
                    orderType= keyInfluencer;
                    sortType= "desc"
                    rankingColumnAggregation = "raw"
                    chartType = "bubble";
                    nlgDataFlagForKeyInfluencers ="nlgDataForBubbleChart";
                    rankingColumn = keyInfluencer;
                    aggregation = "raw" ;
                    yAxisDataType = yAxisDataType ;
                    yaxis = that.yaxis;
                    xaxis = keyInfluencer;
                    xaxisdataType = keyInfluencerDataType;
                    percentileValue += "25,";
                    _.each(that.dimensions,function(dimension,index){
                        if(dimension.columnName == that.xaxis){
                            zAxisDisplayName = dimension.columnDisplayName;
                        }
                        if(dimension.columnName == that.yaxis){
                            yAxisDisplayName = dimension.columnDisplayName;
                        }
                        if(dimension.columnName == keyInfluencer){
                            xAxisDisplayName = dimension.columnDisplayName;
                        }
                    });
                    zAxisObject["columnDisplayName"]=zAxisDisplayName;
                    zAxisObject["columnName"]=that.xaxis;
                    zAxisObject["aggType"]= "avg";
                    zAxisObject["percentileValue"]= "25,";
                    zAxisData.push(zAxisObject);
                    $('.mlcharttitle[data-num="'+i+'"]').html(lang["mlreports.relationb/w"]+" "+"<b>"+yAxisDisplayName+"</b>,<b>"+xAxisDisplayName+"</b> "+ lang["mlreports.and"]+" <b>"+zAxisDisplayName+"</b>");
                    $('.mlcharttitle[data-num="'+i+'"]').attr('title',lang["mlreports.relationb/w"]+" "+""+yAxisDisplayName+","+xAxisDisplayName+" "+ lang["mlreports.and"]+" "+zAxisDisplayName+"");
                }
            }
        }else if(chartPosition==11 && that.options.isDataTableHasToLoad){
            // Code for plotting the Data table
            var chartnum = i;
            $("#mlchart"+chartnum).bootstrapTable("destroy")
            var data = this.datatableData;
            var keysArray = Object.keys(data["0"]);
            // for (var j = 0; j < valuesArray.length; j++) {
            //     var type = $.type(valuesArray[j]);
            //     if(type==="number"){
            //         dataType.push("text-right");
            //     }else if(type==="string"){
            //         dataType.push("text-left");
            //     }else{
            //         dataType.push("text-left");
            //     }
            // }


            
            var keys = [];
            if(keysArray.indexOf("Actual") > -1){
                keys.push("Actual");
            }
            if(keysArray.indexOf("Predicted_values") > -1){
                keys.push("Predicted_values");
            }
            if(keysArray.indexOf("difference_Actual_predicted") > -1){
                keys.push("difference_Actual_predicted");
            }
            if(keysArray.indexOf("percentage") > -1){
                keys.push("percentage");
            }
            for(var j1 = 0; j1 < keysArray.length; j1++){
                if(keys.indexOf(keysArray[j1]) === -1){
                    keys.push(keysArray[j1]);
                }
            }

            var valuesArray = data["0"]
            // valuesArray.push(data["0"]);
            var dataType = [];
            _.each(valuesArray,function(i,j){
                var type = $.type(i);
                if(type==="number"){
                    dataType[j] = "text-right";
                }else if(type==="string"){
                    dataType[j] = "text-left";
                }else{
                    dataType[j] = "text-left";
                }
            });
            var header="";
            for (var j = 0; j < keys.length; j++) {
                var align = dataType[keys[j]];
                header = header+'<th class="'+align+'" data-field="'+keys[j]+'"><b>'+keys[j]+'</b></th>'
            }
            $("#mlchart"+chartnum).parent().removeClass("fullscreensample nopadding");
            $("#mlchart"+chartnum).append('<div class="outliertable notifications-table-container" ><table class="table" id="outliertable'+chartnum+'" data-toggle="table" data-pagination="true" data-page-size="10" data-smart-display="true" data-search="true" data-pagination-v-align="bottom" data-search-align="right"><thead></thead><tbody></tbody></table></div>');
            $("#mlchart"+chartnum+" table>thead").append('<tr>'+header+'</tr>');
            $("#mlchart"+chartnum+" table").bootstrapTable({height:500,data:data});
            
            var titleData = lang["mlreports.predictvalues"];
            $('.mlcharttitle[data-num="'+chartnum+'"]').empty();
            $('.mlcharttitle[data-num="'+chartnum+'"]').text(titleData).attr("title",titleData);
            
            // that.broker.trigger('adjustMLChartHeight',chartnum,undefined ,undefined,"generalizedlinearmodeling");
        }else if(chartPosition==12){
            $("#mlchart"+i).empty();
            $("#mlchart"+i).append('<div class="mlheading-container" style="color: #58595b;font-weight: bold;font-size: 18px;" ><ul class="nlg-list" style=""></ul></div>');
            $("#mlchart"+i+" .mlheading-container>ul.nlg-list").append(lang["mlreports.understandeffect"]+" " +"<b>"+that.yaxis+"</b> "+ lang["mlreports.by"] +" <b>" +that.xaxis+"</b>");
            $("#mlchart"+i).css("overflow","visible");
            $("#mlchart"+i).parent().css("overflow","visible");
            $("#mlchart"+i+" .mlheading-container").css("margin-top","-25px");
            $("#spinnerchart" + (i)).hide();
            return;
        }else if(chartPosition==13){	
            $("#mlchart"+i).empty();
            $("#mlchart"+i).append('<div class="mlheading-container" style="color: #58595b;font-weight: bold;font-size: 18px;"><ul class="nlg-list" style=""></ul></div>');
            $("#mlchart"+i+" .mlheading-container>ul.nlg-list").append(lang["mlreports.understandeffect"]+" " +"<b>"+that.yaxis+"</b> "+ lang["mlreports.by"] +" <b>"+that.xaxis+"</b>"+" " +lang["mlreports.topkeyinfluencers"]);
            $("#mlchart"+i).css("overflow","visible");
            $("#mlchart"+i).parent().css("overflow","visible");
            $("#mlchart"+i+" .mlheading-container").css("margin-top","-25px");
            $("#spinnerchart" + (i)).hide();
            return;
        }else if(chartPosition==14){
            $("#mlchart"+i).empty();
            $("#mlchart"+i).append('<div class="mlheading-container" style="color: #2737a3;font-weight: bold;font-size: 26px;"><ul class="nlg-list" style=""></ul></div>');
            $("#mlchart"+i+" .mlheading-container>ul.nlg-list").append(lang["mlreports.linearmodel"]);
            $("#spinnerchart" + (i)).hide();
            return;
        }/*else if(i == 15){
            
        }else if(i == 16){
            chartType = "metric";
            var chartnum = i;
            var interceptForSimulation = that.interceptForSimulation;
            this.drawMetricChart(interceptForSimulation, "mlchart"+chartnum,chartType, "", this.pos,"","",chartnum,0,undefined,undefined,this.broker);
            $("#spinnerchart" + chartnum).hide();
        }else if(i == 17){
            
        }*/
        if(that.isYaxisCustomField){
            _.each(customFields, function(values){
                if(rangeFilterObj["columnname"].indexOf(values.name)>=0){
                    rangeFilterObj["customfield"]=values;
                }
            });	
        }
        var filterObj= ChartInfo.functions.getFilterArray();
        var bodymodel=new FormData();
        bodymodel.set("keyInfluencer",keyInfluencer);
        bodymodel.set("chartnum",i);
        bodymodel.set("islegend",islegend);
        bodymodel.set("yaxis",yaxis);
        bodymodel.set("yAxisDataType",yAxisDataType);
        bodymodel.set("xaxis",xaxis);
        bodymodel.set("xaxisdataType",xaxisdataType);
        bodymodel.set("legend",legend);
        bodymodel.set("legendDataType",legendDataType);
        bodymodel.set("percentileValue",percentileValue);
        bodymodel.set("aggregation",aggregation);
        bodymodel.set("chartType",chartType);
        bodymodel.set("orderType",orderType);
        bodymodel.set("sortType",sortType);
        bodymodel.set("rowLimit",rowLimit);
        bodymodel.set("xaxisAggregation",xaxisAggregation);
        bodymodel.set("dateHistogramInterval",dateHistogramInterval);
        bodymodel.set("indexName",indexName);
        bodymodel.set("toolTips",toolTips);
        bodymodel.set("zaxisField",zaxisField);
        bodymodel.set("calculation",calculation);
        bodymodel.set("xAxisDisplayName",xAxisDisplayName);
        bodymodel.set("yAxisDisplayName",yAxisDisplayName);
        bodymodel.set("zAxisDisplayName",zAxisDisplayName);
        bodymodel.set("toolTipDisplayName",toolTipDisplayName);
        bodymodel.set("legendcolumnDisplayName",legendcolumnDisplayName);
        bodymodel.set("esType",esType);
        bodymodel.set("referencelineobj",referencelineobj);
        bodymodel.set("xAxisIdentity",xAxisIdentity);
        bodymodel.set("sortingColumnAggregation",sortingColumnAggregation);
        bodymodel.set("sortingColumns",sortingColumns);
        bodymodel.set("rankingColumn",rankingColumn);
        bodymodel.set("rankingColumnAggregation",rankingColumnAggregation);
        bodymodel.set("isPurgedReport",isPurgedReport);
        bodymodel.set("isImportedReport",isImportedReport);
        bodymodel.set("selectedDate",selectedDate);
        bodymodel.set("zaxis",zAxisData);
        bodymodel.set("combinationchartsData",JSON.stringify(combinationchartsData));
        bodymodel.set("nlgDataFlagForKeyInfluencers",nlgDataFlagForKeyInfluencers);
        var bodyformdata= new FormData();
        bodyformdata.set("schemaId","");
        bodyformdata.set("reportId",that.reportId!=null&&that.reportId!=undefined?that.reportId:"");
        bodyformdata.set("xAxis",bodymodel.get("xaxis"));
        bodyformdata.set("xAxisdataType",bodymodel.get("xaxisdataType"));
        bodyformdata.set("yAxis",bodymodel.get("yaxis"));
        bodyformdata.set("yAxisDataType",bodymodel.get("yAxisDataType"));
        bodyformdata.set("filter",JSON.stringify(filterObj));
        bodyformdata.set("script",JSON.stringify(customFields));
        bodyformdata.set("aggregation",bodymodel.get("aggregation"));
        bodyformdata.set("chartType",bodymodel.get("chartType"));
        bodyformdata.set("orderType",bodymodel.get("orderType"));
        bodyformdata.set("percentileValue",bodymodel.get("percentileValue"));
        bodyformdata.set("legend",bodymodel.get("legend"));
        bodyformdata.set("legendDataType",bodymodel.get("legendDataType"));
        bodyformdata.set("rowLimit",bodymodel.get("rowLimit"));
        bodyformdata.set("xaxisAggregation",bodymodel.get("xaxisAggregation"));
        bodyformdata.set("dateHistogramInterval",bodymodel.get("dateHistogramInterval"));
        bodyformdata.set("indexName",bodymodel.get("indexName"));
        bodyformdata.set("toolTips",bodymodel.get("toolTips"));
        bodyformdata.set("zaxisField",bodymodel.get("zaxis"));
        bodyformdata.set("calculation",bodymodel.get("calculation"));
        bodyformdata.set("isPurgedReport",bodymodel.get("isPurgedReport"));
        bodyformdata.set("isImportedReport",bodymodel.get("isImportedReport"));
        bodyformdata.set("xAxisDisplayName",bodymodel.get("xAxisDisplayName"));
        bodyformdata.set("yAxisDisplayName",bodymodel.get("yAxisDisplayName"));
        bodyformdata.set("zAxisDisplayName",bodymodel.get("zAxisDisplayName"));
        bodyformdata.set("toolTipDisplayName",bodymodel.get("toolTipDisplayName"));
        bodyformdata.set("legendcolumnDisplayName",bodymodel.get("legendcolumnDisplayName"));
        bodyformdata.set("referencelineobj",bodymodel.get("referencelineobj"));
        bodyformdata.set("xAxisIdentity",bodymodel.get("xAxisIdentity"));
        bodyformdata.set("sortType",bodymodel.get("sortType"));
        bodyformdata.set("sortingColumnAggregation",bodymodel.get("sortingColumnAggregation"));
        bodyformdata.set("sortingColumns",bodymodel.get("sortingColumns"));
        bodyformdata.set("rankingColumn",bodymodel.get("rankingColumn"));
        bodyformdata.set("rankingColumnAggregation",bodymodel.get("rankingColumnAggregation"));
        bodyformdata.set("combinationchartsData",bodymodel.get("combinationchartsData"));
        bodyformdata.set("topFiveKeyInfluencers", JSON.stringify(ChartInfo.functions.getTopFiveKeyInfluencersForML()));
        bodyformdata.set("nlgDataFlagForKeyInfluencers", bodymodel.get("nlgDataFlagForKeyInfluencers"));
        bodyformdata.set("clickedValueFilterObj",JSON.stringify(clickedValueFilterObj));
        bodyformdata.set("outlierFilterObj",JSON.stringify(outlierFilterObj));
        bodyformdata.set("rangeFilterObj",JSON.stringify(rangeFilterObj));
        bodyformdata.set("topAndLeastCatagoricalKeyInfluencers",JSON.stringify(ChartInfo.functions.getTopLeastCatagoricalKeyInfluencers(that.reportId)));
        bodyformdata.set("topTwoNumericalKeyInfluencers",JSON.stringify(ChartInfo.functions.getTopTwoNumericalKeyInfluencers(that.reportId)));
        bodyformdata.set("topTwoCategoricalKeyInfluencers",JSON.stringify(ChartInfo.functions.getTopTwoCategoricalKeyInfluencers(that.reportId)));
        bodyformdata.set("datamodelId",that.options.datamodelId);
        SendRequest({
            url: "getmlkeyinfluencerdrilldown",
            body: bodyformdata,
            sucessFunction: response => {
                var chartType = bodymodel.get("chartType");
                var islegend = bodymodel.get("islegend");
                var chartNum = bodymodel.get("chartnum");
                var chartsplitnum = that.isFromStrybok?bodymodel.get("chartnum").split("_")[1]:bodymodel.get("chartnum")
                var keyInfluencer = bodymodel.get("keyInfluencer");
                var yAxis  = bodymodel.get("yaxis");
                var xAxis  = bodymodel.get("xaxis");
                var selectedDate = bodymodel.get("selectedDate");
                var xAxisDatatype = bodymodel.get("xaxisdataType");
                var xAxisDisplayName = bodymodel.get("xAxisDisplayName");
                var chartSettings;
                var tooltipNameArray = [];
                if(typeof response!=="string"){
                    response=JSON.stringify(response);
                }
                if(chartsplitnum == 4){
                    that.topFiveKeyInfluencers =[];
                    var topKeyInfluencers = [];
                    if(JSON.parse(JSON.parse(response).outcomeForKeyInfluencer)[0]!=undefined){
                        topKeyInfluencers = JSON.parse(JSON.parse(response).outcomeForKeyInfluencer)[0].data;
                    }
                     if(xAxisDatatype.toLowerCase() == "string"){
                         var topLeastCatagoricalKeyInfluencers = [];
                         var length = topKeyInfluencers.length;
                         topLeastCatagoricalKeyInfluencers.push(topKeyInfluencers[0]);
                         topLeastCatagoricalKeyInfluencers.push(topKeyInfluencers[length-1]);
                         ChartInfo.functions.setTopLeastCatagoricalKeyInfluencers(that.reportId,topLeastCatagoricalKeyInfluencers);
                         console.log(topLeastCatagoricalKeyInfluencers);
                     }
                    _.each(topKeyInfluencers,function(value,index){
                        if(index <5){
                            that.topFiveKeyInfluencers.push(value) 
                        }
                    });
                    ChartInfo.functions.setTopFiveKeyInfluencersForML(that.topFiveKeyInfluencers);
                    that.applyfiltesandredrawmlallchart();
                }
                if(chartSettings==undefined){
                    chartSettings={'chartTitle':true,'legend':true,'xAxisLabel':true,'yAxisLabel':true,'dataLabel':false,'crossHairs':false,'gridLine':true,'referenceLine':false};
                }
                var yAxisNameArray = yAxisDisplayName.split(",");
                var yAxisAggArray =  bodymodel.get("aggregation").split(",")
                var yAxisDataTypeArray = bodymodel.get("yAxisDataType").split(",")
                if(chartType == "bar"){
                    var categories = [];
                    that.drawSmartAVGBar(JSON.parse(JSON.parse(response).outcomeForKeyInfluencer)[0].data,JSON.parse(JSON.parse(response).outcomeForKeyInfluencer)[0].avgValue,categories, 'mlchart'+chartNum,yAxis,xAxisDisplayName,yAxisDisplayName);
                }else if(chartType == "scatter"){
                    that.drawSmartScatterChart(JSON.parse(JSON.parse(response).outcomeForKeyInfluencer)[0].data,'mlchart'+chartNum,xAxis,yAxis,xAxisDisplayName,yAxisDisplayName);
                }else if(chartType == "line"){
                    var legend = {};
                    if(that.xAxisDatatype.toLowerCase() == "string"){
                        legend["displayName"]= bodymodel.get("legend");
                        legend["columnName"]=bodymodel.get("legend");
                        legend["datatype"]=bodymodel.get("legendDataType");
                    }
                    MLCharts.charts.drawLineChart(JSON.parse(JSON.parse(response).outcomeForKeyInfluencer),xAxisDisplayName,xAxis,bodymodel.get("xaxisdataType"),yAxisNameArray,'mlchart'+chartNum, chartType,
                            legend, undefined,that.features,false,"22",chartSettings,tooltipNameArray,that.broker,undefined,zAxisNameArray,undefined,yAxisDataTypeArray,yAxisAggArray,true);
                    //that.drawSmartLineChart(JSON.parse(JSON.parse(response).outcomeForKeyInfluencer), "mlchart"+chartNum,islegend,selectedDate,yAxis);
                }else if(chartType == "heatmap"){
                    var legend = {};
                    legend["displayName"]= bodymodel.get("legendcolumnDisplayName");
                    legend["columnName"]=bodymodel.get("legend");
                    legend["datatype"]=bodymodel.get("legendDataType");
                    MLCharts.charts.drawHeatMapChart(JSON.parse(JSON.parse(response).outcomeForKeyInfluencer),bodymodel.get("xAxisDisplayName"), xAxis,bodymodel.get("xaxisdataType"),yAxisNameArray,'mlchart'+chartNum, chartType,
                            legend, undefined,that.features,false,"5",chartSettings,tooltipNameArray,undefined,undefined,that.broker,undefined,yAxisNameArray,undefined,yAxisDataTypeArray,yAxisAggArray,true);
                    // NLG Data for HeatMap
                    var nlgData = JSON.parse(response).nlgData;
                    if(nlgData!=null && Object.keys(JSON.parse(response).nlgData).length>0){
                        var highestDataText="";
                        var lowestDataText="";
                        
                        if(JSON.parse(response).nlgData.highestData!=null && Object.keys(JSON.parse(response).nlgData.highestData).length>0){
                            var highestData="";
                            _.each(JSON.parse(response).nlgData.highestData,function(obj,i){
                                for(var k1=0;k1<obj.length;k1++){
                                    highestData+=obj[k1]+",";
                                }
                                highestData=highestData.substring(0,highestData.length-1);
                                highestDataText+= i+" "+lang["mlreports.highest"]+" "+bodymodel.get("yAxisDisplayName")+lang["mlreports.in"]+highestData+". ";
                            });
                        }
                        if(Object.keys(JSON.parse(response).nlgData.lowestData)!=null && Object.keys(JSON.parse(response).nlgData.lowestData).length>0){
                            var lowestData="";
                            _.each(JSON.parse(response).nlgData.lowestData,function(obj,j){
                                for(var k1=0;k1<obj.length;k1++){
                                    lowestData+=obj[k1]+",";
                                }
                                lowestData=lowestData.substring(0,lowestData.length-1);
                                lowestDataText+= j+" "+lang["mlreports.lowest"]+" "+bodymodel.get("yAxisDisplayName")+lang["mlreports.in"]+lowestData+".";
                            });
                        }
                        ChartInfo.functions.getTopLeastCatagoricalKeyInfluencers(that.reportId);
                        var textArray = highestDataText+"\n"+lowestDataText;
                    
                        $('.mltooltip[data-num='+chartNum+']').attr("title",textArray);
                    }
                }else if(chartType == "combinationchart"){
                    if(response!=undefined && response!=null && JSON.parse(response).outcomeForKeyInfluencer!=undefined && JSON.parse(response).outcomeForKeyInfluencer!=null ){
                        MLCharts.charts.drawCombinationChart(JSON.parse(JSON.parse(response).outcomeForKeyInfluencer),bodymodel.get("xAxisDisplayName"), xAxis,bodymodel.get("xaxisdataType"),yAxisNameArray,lang["mlreports.mlchart"]+chartNum, chartType,
                                undefined, undefined,that.features,false,"22",chartSettings,tooltipNameArray,that.broker,undefined,zAxisNameArray,undefined,yAxisDataTypeArray,yAxisAggArray,true);
                        // NLG Data for Combinationchart
                        var nlgData = JSON.parse(response).nlgData;
                        if(nlgData!=null && Object.keys(nlgData).length>0 && nlgData.highestDifferenceData!=null &&  nlgData.highestDifferenceData.length>0  && yAxisNameArray!=undefined){
                            $('.mltooltip[data-num='+chartNum+']').attr("title",lang["mlreports.In"]+bodymodel.get("xAxisDisplayName")+","+" "+ lang["mlreports.the"]+" " +nlgData.highestDifferenceData+" "+ lang["mlreports.highestdiffb/w"]+" "+yAxisNameArray[0]+" "+lang["mlreports.and"]+" "+yAxisNameArray[1]);
                        }else{
                            if(nlgData!=undefined && yAxisNameArray!=undefined){
                                $('.mltooltip[data-num='+chartNum+']').attr("title",yAxisNameArray[0]+","+yAxisNameArray[1]+lang["mlreports.and"]+bodymodel.get("xAxisDisplayName"));
                            }
                        }
                    }
                }else if(chartType == "bubble"){
                    var zAxisNameArray = [];
                    var zaxis = bodymodel.get("zAxisDisplayName");
                    zAxisNameArray.push(zaxis)
                    MLCharts.charts.drawBubbleChart(JSON.parse(JSON.parse(response).outcomeForKeyInfluencer),bodymodel.get("xAxisDisplayName"), xAxis,bodymodel.get("xaxisdataType"),yAxisNameArray,'mlchart'+chartNum, chartType,
                            undefined, undefined,that.features,false,"27",chartSettings,tooltipNameArray,that.broker,undefined,zAxisNameArray,undefined,undefined,yAxisDataTypeArray,yAxisAggArray,true);
                    
                    // NLG Data for Bubble
                    var data="";
                    var correlationData = ChartInfo.functions.getReportCorrelationData(that.reportId!=null&&that.reportId!=undefined?that.reportId:"");
                    if(correlationData!=undefined){
                        var categories = correlationData[0];
                        var correlatedcolumns = correlationData[1];
                        var xaxis = bodymodel.get("xAxisDisplayName") ;
                        var yaxis = bodymodel.get("yAxisDisplayName");
                        var zValue = zaxis
                        data +=that.getCorrelatedData(xaxis,yaxis,correlatedcolumns,categories);
                        data +=that.getCorrelatedData(zValue,xaxis,correlatedcolumns,categories);
                        data +=that.getCorrelatedData(zValue,yaxis,correlatedcolumns,categories);
                    }
                    $('.mltooltip[data-num='+chartNum+']').attr("title",data);
                }else if(chartType == "nlg"){
                    if(xAxisDatatype == "string"){
                        var nlgResponse = JSON.parse(JSON.parse(response).nlgData).nlg;
                        var numericalCompareResponse = nlgResponse["NumericalCompare"];
                        var catagoricalResponse = nlgResponse["CatagoricalCompare"];
                        var outliers = nlgResponse["Outliers"];
                        var topLeastCatagoricalKeyInfluencers = ChartInfo.functions.getTopLeastCatagoricalKeyInfluencers(that.reportId);
                        var topTwoNumericalKeyInfluencers = ChartInfo.functions.getTopTwoNumericalKeyInfluencers(that.reportId);
                        var topTwoCatagoricalKeyInfluencers = ChartInfo.functions.getTopTwoCategoricalKeyInfluencers(that.reportId);
                        var topKeyInfluencer="";
                        var leastKeyInfluencer="";
                        if(topLeastCatagoricalKeyInfluencers!=undefined){
                            for(var i = 0; i < topLeastCatagoricalKeyInfluencers.length ;i++){
                                if(i == 0){
                                    topKeyInfluencer = topLeastCatagoricalKeyInfluencers[0].name;
                                }
                                if(i == 1){
                                    leastKeyInfluencer = topLeastCatagoricalKeyInfluencers[1].name;
                                }
                            }
                        }
                        var firstNumericalKeyInfluencer="";
                        var secondNumericalKeyInfluencer="";
                        var firstNumericalKeyInfluencerDisplayName="";
                        var secondNumericalKeyInfluencerDisplayName="";
                        if(topTwoNumericalKeyInfluencers!=undefined){
                            for(var i = 0; i < topTwoNumericalKeyInfluencers.length ;i++){
                                if(i == 0){
                                    firstNumericalKeyInfluencer = topTwoNumericalKeyInfluencers[0].name;
                                    firstNumericalKeyInfluencerDisplayName = topTwoNumericalKeyInfluencers[0].columnDisplayName;
                                }
                                if(i == 1){
                                    secondNumericalKeyInfluencer = topTwoNumericalKeyInfluencers[1].name;
                                    secondNumericalKeyInfluencerDisplayName = topTwoNumericalKeyInfluencers[1].columnDisplayName;
                                }
                            }
                        }
                        var firstCatagoricalKeyInfluencer="";
                        var secondCatagoricalKeyInfluencer="";
                        var firstCatagoricalKeyInfluencerDisplayName="";
                        var secondCatagoricalKeyInfluencerDisplayName="";
                        if(topTwoCatagoricalKeyInfluencers!=undefined){
                            for(var i = 0; i < topTwoCatagoricalKeyInfluencers.length ;i++){
                                if(i == 0){
                                    firstCatagoricalKeyInfluencer = topTwoCatagoricalKeyInfluencers[0].name;
                                    firstCatagoricalKeyInfluencerDisplayName = topTwoCatagoricalKeyInfluencers[0].columnDisplayName;
                                }
                                if(i == 1){
                                    secondCatagoricalKeyInfluencer = topTwoCatagoricalKeyInfluencers[1].name;
                                    secondCatagoricalKeyInfluencerDisplayName = topTwoCatagoricalKeyInfluencers[1].columnDisplayName;
                                }
                            }
                        }
                        var data=[];
                        var firstlowOrHigh = "low";
                        var secondLowOrHigh = "low";
                        if(numericalCompareResponse[firstNumericalKeyInfluencer]!=undefined){
                            var firstKeyInfPercentage = numericalCompareResponse[firstNumericalKeyInfluencer]["percentage"];
                            if(firstKeyInfPercentage < 0){
                                firstlowOrHigh = "high"
                                    firstKeyInfPercentage= Math.abs(firstKeyInfPercentage);
                            }
                        }
                        if(numericalCompareResponse[secondNumericalKeyInfluencer]!=undefined){
                            var secondKeyInfPercentage = numericalCompareResponse[secondNumericalKeyInfluencer]["percentage"];
                            if(secondKeyInfPercentage < 0){
                                secondLowOrHigh = "high"
                                secondKeyInfPercentage= Math.abs(secondKeyInfPercentage);
                            }
                        }
                        data.push("<li style='font-size: 16px ; font-weight: bold;text-align:left;margin-bottom:10px;color:#2737a3;'>"+lang["mlreports.knowdiff"]+"</li>");
                        if(topTwoNumericalKeyInfluencers.length > 0){
                            data.push("<li class=\"nlg-item\"><span class=\"nlgtext\">&#9755; "+lang["mlreports.comparisonto"]+" "+topKeyInfluencer+" "+lang["mlreports.theavg"]+" " +"<font style=\"color: #0089ff;\">"+firstNumericalKeyInfluencerDisplayName+"</font>"+lang["mlreports.in"]+leastKeyInfluencer+" "+lang["mlreports.is"]+" "+firstlowOrHigh+lang["mlreports.by"] +"<b>"+firstKeyInfPercentage+"%</b>"+ lang["mlreports.andtheaverage"] +"<font style=\"color: #0089ff;\">"+secondNumericalKeyInfluencerDisplayName+"</font>"+lang["mlreports.in"]+leastKeyInfluencer+" "+lang["mlreports.is"]+" "+secondLowOrHigh+" "+lang["mlreports.by"] +"<b>"+secondKeyInfPercentage+"%</b> .</span></li>");
                        }else{
                            data.push("<li class=\"nlg-item\"><span class=\"nlgtext\">&#9755;"+ lang["mlreports.nokeyinfluencer"]+"</span></li>");
                        }
                        if(topTwoCatagoricalKeyInfluencers.length > 0){
                            if(catagoricalResponse[firstCatagoricalKeyInfluencer]!=undefined){
                                data.push("<li class=\"nlg-item\"><span class=\"nlgtext\">&#9755; "+topKeyInfluencer+" "+lang["mlreports.occurlot"]+" "+"<font style=\"color: #0089ff;\">"+firstCatagoricalKeyInfluencerDisplayName+"</font>"+ lang["mlreports.of"] +"<b>"+catagoricalResponse[firstCatagoricalKeyInfluencer]["firstVal"]+"</b>"+ lang["mlreports.while"]+leastKeyInfluencer+lang["mlreports.occurlot"]+" "+"<b>"+catagoricalResponse[firstCatagoricalKeyInfluencer]["secondVal"]+"</b>.</span></li>");
                            }
                            if(catagoricalResponse[secondCatagoricalKeyInfluencer]!=undefined){
                                data.push("<li class=\"nlg-item\"><span class=\"nlgtext\">&#9755; "+topKeyInfluencer+" "+lang["mlreports.occurlot"]+" "+"<font style=\"color: #0089ff;\">"+secondCatagoricalKeyInfluencerDisplayName+"</font>"+ lang["mlreports.of"]+" <b>"+catagoricalResponse[secondCatagoricalKeyInfluencer]["firstVal"]+"</b>"+ lang["mlreports.while"] +leastKeyInfluencer+ lang["mlreports.occurlot"] +"<b>"+catagoricalResponse[secondCatagoricalKeyInfluencer]["secondVal"]+"</b>.</span></li>");
                            }
                        }else{
                            data.push("<li class=\"nlg-item\"><span class=\"nlgtext\">&#9755;"+ lang["mlreports.nocatagorical"]+"</span></li>");
                        }
                        
                        data.push("<li style='font-size: 16px ; font-weight: bold;text-align:left;margin-bottom:10px;color:#2737a3;'>"+lang["mlreports.outlierinfo"]+"</li>");
                        if(topTwoNumericalKeyInfluencers.length > 0){
                            if(outliers[topKeyInfluencer]!=undefined && outliers[topKeyInfluencer][firstNumericalKeyInfluencer] != undefined){
                                data.push("<li class=\"nlg-item\"><span class=\"nlgtext\" style=\"word-break: break-all\">&#9755; "+topKeyInfluencer+" "+lang["mlreports.negativeoutliers"]+" "+"<font style=\"color: #0089ff;\">"+firstNumericalKeyInfluencerDisplayName+"</font>"+ lang["mlreports.of"] +"<b>"+outliers[topKeyInfluencer][firstNumericalKeyInfluencer]+"</b>"+lang["mlreports.and"] +"<font style=\"color: #0089ff;\">"+secondNumericalKeyInfluencerDisplayName+"</font>"+" " +lang["mlreports.of"]+" " +"<b>"+outliers[topKeyInfluencer][secondNumericalKeyInfluencer]+"</b>.</span></li>");
                            }else{
                                data.push("<li class=\"nlg-item\"><span class=\"nlgtext\" style=\"word-break: break-all\">&#9755; "+topKeyInfluencer+" "+lang["mlreports.nonegativeoutliers"]+" "+" <font style=\"color: #0089ff;\">"+firstNumericalKeyInfluencerDisplayName+"</font>.</span></li>");
                            }
                            if(outliers[leastKeyInfluencer]!=undefined && outliers[leastKeyInfluencer][firstNumericalKeyInfluencer] != undefined){
                                data.push("<li class=\"nlg-item\"><span class=\"nlgtext\" style=\"word-break: break-all\">&#9755; "+leastKeyInfluencer+" "+lang["mlreports.positiveoutliers"]+" "+"<font style=\"color: #0089ff;\">"+firstNumericalKeyInfluencerDisplayName+"</font>"+ lang["mlreports.of"] +"<b>"+outliers[leastKeyInfluencer][firstNumericalKeyInfluencer]+"</b>"+lang["mlreports.and"] +"<font style=\"color: #0089ff;\">"+secondNumericalKeyInfluencerDisplayName+"</font>"+" " +lang["mlreports.of"]+" " +" <b>"+outliers[leastKeyInfluencer][secondNumericalKeyInfluencer]+"</b>.</span></li>");
                            }else{
                                data.push("<li class=\"nlg-item\"><span class=\"nlgtext\" style=\"word-break: break-all\">&#9755; "+leastKeyInfluencer+" "+lang["mlreports.nopositiveoutliers"]+" "+"<font style=\"color: #0089ff;\">"+firstNumericalKeyInfluencerDisplayName+"</font>.</span></li>");
                            }
                        }else{
                            data.push("<li class=\"nlg-item\"><span class=\"nlgtext\">&#9755;"+ lang["mlreports.nokeyinfluencer"]+"</span></li>");
                        }
                        
                        if(data!=undefined && data!=null && data.length>0){
                            $("#mlchart"+chartNum).empty();
                            $("#mlchart"+chartNum).append('<div class="nlgdata-container"><ul class="nlg-list" style="font-family: &quot;72-Web&quot;,Arial,Helvetica,sans-serif;"></ul></div>');
                            for(var k1=0;k1<data.length;k1++){
                                $("#mlchart"+chartNum+" .nlgdata-container>ul.nlg-list").append(data[k1]);
                            }
                        }
                        $("#spinnerchart" + chartNum).hide();
                    }else if(xAxisDatatype == "number"){
                        var data=[];
                        var correlationsValue = 0;
                        var highOrLow = "";
                        var positiveOrNegative = "";
                        var increaseOrDecrease = "";
                        var correlationData = ChartInfo.functions.getReportCorrelationData(that.reportId!=null&&that.reportId!=undefined?that.reportId:"");
                        if(correlationData!=undefined){
                            var categories = correlationData[0];
                            var correlatedcolumns = correlationData[1];
                            var xaxis = that.xaxis;
                            var yaxis = that.yaxis;
                            var xDisplayName = that.xAxisDisplayName;
                            var yDisplayName = that.yAxisDisplayName;
                            var xaxisIndex = categories.indexOf(xaxis);
                            var yaxisIndex = categories.indexOf(yaxis);
                            for(var k=0;k<correlatedcolumns.length;k++){
                                var correlateddata = correlatedcolumns[k];
                                if(correlateddata[0] == xaxisIndex && correlateddata[1] == yaxisIndex){
                                    correlationsValue = correlateddata[2];
                                }
                            }
                            if(correlationsValue < 0){
                                positiveOrNegative = "Negative";
                                increaseOrDecrease = "Decrease";
                                if(correlationsValue <= -0.5){
                                    highOrLow = "high";
                                }else if(correlationsValue <= -0.2 && correlationsValue > -0.5){
                                    highOrLow = "low";
                                }else{
                                    highOrLow = "very low";
                                }
                            }else{
                                positiveOrNegative = "Positive";
                                increaseOrDecrease = "Increase";
                                if(correlationsValue >= 0.5){
                                    highOrLow = "high";
                                }else if(correlationsValue >= 0.2 && correlationsValue < 0.5){
                                    highOrLow = "low";
                                }else{
                                    highOrLow = "very low";
                                }
                            }
                            data.push("<li style='font-size: 16px ; font-weight: bold;text-align:left;margin-bottom:10px;color:#2737a3;'>"+lang["mlreports.knowdiff"]+"</li>");
                            if(highOrLow=="high"){
                                data.push("<li class=\"nlg-item\"><span class=\"nlgtext\">&#9755; <font style=\"color: #0089ff;\">"+xDisplayName+"</font>"+" " +lang["mlreports.and"]+" "+"<font style=\"color: #0089ff;\">"+yDisplayName+"</font>"+" "+lang["mlreports.has a"]+" "+ highOrLow+" "+positiveOrNegative+" "+lang["mlreports.correlationas"]+" "+xDisplayName +" "+ increaseOrDecrease+" "+yDisplayName+" "+lang["mlreports.will"]+" "+increaseOrDecrease+".</span></li>");
                            }else if(highOrLow=="low"){
                                data.push("<li class=\"nlg-item\"><span class=\"nlgtext\">&#9755; <font style=\"color: #0089ff;\">"+xDisplayName+"</font>"+" " +lang["mlreports.and"]+" "+"<font style=\"color: #0089ff;\">"+yDisplayName+"</font>"+" "+lang["mlreports.has a"]+" "+highOrLow+" "+positiveOrNegative+" "+lang["mlreports.correlationas"]+" "+xDisplayName +" "+ increaseOrDecrease+" "+yDisplayName+" "+lang["mlreports.wouldchange"]+"</span></li>");
                            }else{
                                data.push("<li class=\"nlg-item\"><span class=\"nlgtext\">&#9755; <font style=\"color: #0089ff;\">"+xDisplayName+"</font>"+" " +lang["mlreports.and"]+" "+"<font style=\"color: #0089ff;\">"+yDisplayName+"</font>"+" "+lang["mlreports.has a"]+" "+highOrLow+" "+positiveOrNegative+" "+lang["mlreports.coreltn"]+"</span></li>");
                            }
                            if(data!=undefined && data!=null && data.length>0){
                                $("#mlchart"+chartNum).empty();
                                $("#mlchart"+chartNum).append('<div class="nlgdata-container"><ul class="nlg-list" style="font-family: &quot;72-Web&quot;,Arial,Helvetica,sans-serif;"></ul></div>');
                                for(var k1=0;k1<data.length;k1++){
                                    $("#mlchart"+chartNum+" .nlgdata-container>ul.nlg-list").append(data[k1]);
                                }
                            }
                            $("#spinnerchart" + chartNum).hide();
                        }
                    }
                    $('.mlcharttitle[data-num="'+chartNum+'"]').text("");
                }
                setTimeout(function() {
                    // that.broker.trigger('adjustMLChartHeight',chartNum);
                }, 1000);
            },
            rejectFunction: () => { },
            headers: postrequestHeaders
          });
    }
    drawSmartAVGBar(data,avgValue,categories, renderto,yaxisname,xAxisDisplayName,yAxisDisplayName){
        var that = this;
        var colorThemesArray = [["#00bcd4","#37c2d9","#50c8dd","#64cee2","#76d4e7","#86daeb","#95e0f0","#a3e6f4","#b1ecf9"],
        ["#00bcd4","#00CECC","#42DEB9","#7FEB9E","#BBF484"],
        ["#4e79a7","#fc7d0b","#a3acb9","#57606c","#5fa2ce","#c85200","#7b848f","#a3cce9","#ffbc79","#c8d0d9"],	
                                ["#4e79a7","#58606c","#a2abb8"],
                                ["#4e79a7","#f28e2b","#e15759","#76b7b2","#59a14f","#edc948","#b07aa1","#ff9da7","#9c755f","#bab0ac"]
                               ];
        var maxShownData = 20;
        if(data.length<20){
            var maxShownData = data.length-1;
        }
        
        var options = {
                chart: {
                    renderTo : renderto,
                    type: 'bar',
                    zoomType : 'xy',
                    backgroundColor: 'transparent',
                    style : {
                        fontFamily: whitelableMap["fontfamily"],
                        fontWeight: '400',
                    },
                    animation:false,
                    plotBorderWidth: 0,
                    spacing:[15,25,10,25]
                },
                title: {
                    text: ''
                },
                xAxis: {
                    type: 'category',
                    title: {
                        text: xAxisDisplayName,
                        enabled:false
                    },
                    lineWidth:0.3,
                    tickWidth: 0.3,
                    gridLineWidth:0,
                    labels: {
                        step: 1
                    },
                    min: 0,
                    max: maxShownData,
                    scrollbar: {
                        enabled: true
                    },
                },
                yAxis: {
                    title: {
                        text: yAxisDisplayName,
                        enabled:false
                    },
                    
                    lineWidth:0,
                    tickWidth: 0,
                    gridLineWidth:0,
                    plotLines: [{
                        color: 'red',
                        value: avgValue,
                        width: '1',
                        zIndex: 4,
                        dashStyle:'LongDash',
                        label:{
                            text:"Overall Sum ("+avgValue+")",
                            verticalAlign: 'top',
                            textAlign: 'center',
                            rotation:0,
                            y:-3,
                            x:-30
                        }
                    }]
                },
                drilldown: {
                    activeAxisLabelStyle: {
                    cursor: 'pointer',
                    textDecoration: 'none',
                    color: '#000',
                    fontWeight:'400'
                    },
                    activeDataLabelStyle: {
                    cursor: 'pointer',
                    textDecoration: 'none',
                    color: '#000',
                    fontWeight:'400'
                    },
                    series: []
                },
                plotOptions: {
                    bar: {
                        dataLabels: {
                            enabled: false
                        },
                    },
                    series: {
                        cursor: 'pointer',
                        pointPadding: 0,
                        states: {
                            hover: {
                                color: ['#fff']
                            },
                            select: {
                                color: colorThemesArray[0][0] 
                            }
                        },
                    }
                },
                legend: {
                    enabled: true,
                    align: 'center',
                    symbolHeight: 12,
                    symbolWidth: 12,
                    symbolRadius: 6
                },
                tooltip: {
                    useHTML: true,
                    hideDelay: 0,
                    shared: true,
                    padding: 0,
                    /*positioner: function (w, h, point) {
                        return { x: point.plotX - w / 1, y: -2 };
                    }*/
                    formatter: function(e){
                        var name='<span style="font-size:10px"><b>'+(this.points&&this.points[0]!=undefined&&this.points[0].key!=undefined?this.points[0].key:"")+ '</b><br><b>'+'Sum of '+yAxisDisplayName+''+'</b> : '+(this.y?this.y.toFixed(2):0)+'</span>';
                        return name
                    }
                },
                series: [{
                    xaxis:xAxisDisplayName,
                    yaxis:yAxisDisplayName,
                    name: "Sum of "+yAxisDisplayName+"",
                    data: data,
                    states: {
                        hover: {
                            color: colorThemesArray[0][0]
                        },
                        select: {
                            color: colorThemesArray[0][0]
                        }
                    }
                }],
                colors:['#d8d8d8'],
                exporting : {
                    enabled : false,
                },
                credits : {
                    enabled : false
                }
        }
        var insightkeyinfluencersavg = new Highcharts.Chart(options);
        try {
            var chartnum = renderto.replace("mlchart", "");
            ChartInfo.functions.setMLChartData(chartnum, insightkeyinfluencersavg);
        } catch (err) {

        }
    }
    
    drawSmartScatterChart(data,chartid,xaxisname,yaxisname,xAxisDisplayName,yAxisDisplayName){
        try{
            data.sort(function(a, b) {
            if(a.name&&b.name){
                let x1 = parseFloat(a.name);
                let x2 = parseFloat(b.name);
                return x1-x2;
            }
          });
        }catch(e){

        }
        var options={
                chart: {
                     renderTo : chartid,
                     type: 'scatter',
                     zoomType: 'xy',
                     inverted : false,
                     backgroundColor: 'transparent',
                     style : {
                        fontFamily: whitelableMap["fontfamily"],
                        fontWeight: '400',
                     },
                     animation:false,
                    
                },
                title: {
                    text: ''
                },
                xAxis: {
                    title: {
                        enabled: true,
                        text: xAxisDisplayName
                    },
                },
                yAxis: {
                    title: {
                        enabled: true,
                        text: yAxisDisplayName
                    }
                },
                drilldown: {
                    activeAxisLabelStyle: {
                    cursor: 'pointer',
                    textDecoration: 'none',
                    color: '#000',
                    fontWeight:'400'
                    },
                    activeDataLabelStyle: {
                    cursor: 'pointer',
                    textDecoration: 'none',
                    color: '#000',
                    fontWeight:'400'
                    },
                    series: []
                },
                tooltip: {
                    headerFormat: '',
                    formatter: function(e){
                        var name='<span style="font-size:10px"><b>'+xAxisDisplayName+'</b> : '+this.x+' <br><b>'+yAxisDisplayName+'</b> : '+this.y+'</span>';
                        return name
                    },
                    useHTML: true,
                    hideDelay: 0,
                    shared: true,
                    padding: 0,
                },
                legend: {
                    enabled:false,
                },
                boost :{
                    enabled:true
                },
                series: [{
                    color: 'rgba(0, 188, 212, 0.5)',
                    data: data,
                    turboThreshold:500000,
                }],
                credits : {
                    enabled : false
                }
            }
        var mlscatter = new Highcharts.Chart(options);
        try {
            var chartnum = chartid.replace("mlchart", "");
            ChartInfo.functions.setMLChartData(chartnum, mlscatter);
        } catch (err) {

        }
    }
    drawDateChartForML(chartnumber){
        var that = this;
        that.chartnum = chartnumber;
        that.applyfiltesandredrawmlchart();
    }
    getCorrelatedData(xaxis,yaxis,correlatedcolumns,categories){
        var data="";
        var correlationsValue = 0;
        var highOrLow = "";
        var positiveOrNegative = "";
        var increaseOrDecrease = "";
        var xaxisIndex = categories.indexOf(xaxis);
        var yaxisIndex = categories.indexOf(yaxis);
        for(var k=0;k<correlatedcolumns.length;k++){
            var correlateddata = correlatedcolumns[k];
            if(correlateddata[0] == xaxisIndex && correlateddata[1] == yaxisIndex){
                correlationsValue = correlateddata[2];
            }
        }
        if(correlationsValue < 0){
            positiveOrNegative = "Negative";
            increaseOrDecrease = "Decrease";
            if(correlationsValue <= -0.5){
                highOrLow = "high";
            }else if(correlationsValue <= -0.2 && correlationsValue > -0.5){
                highOrLow = "low";
            }else{
                highOrLow = "very low";
            }
        }else{
            positiveOrNegative = "Positive";
            increaseOrDecrease = "Increase";
            if(correlationsValue >= 0.5){
                highOrLow = "high";
            }else if(correlationsValue >= 0.2 && correlationsValue < 0.5){
                highOrLow = "low";
            }else{
                highOrLow = "very low";
            }
        }
        data = xaxis+" "+lang["mlreports.and"]+" "+yaxis+" "+lang["mlreports.has a"]+" "+highOrLow+" "+positiveOrNegative+" "+lang["mlreports.coreltn"];
        if(highOrLow=="high"){
            data += lang["mlreports.thismeans"]+" " +xaxis +" "+ increaseOrDecrease+" "+yaxis+" "+lang["mlreports.will"]+" "+increaseOrDecrease+". "
        }else if(highOrLow=="low"){
            data += lang["mlreports.thismeans"]+" " +xaxis +" "+ increaseOrDecrease+" "+yaxis+" "+lang["mlreports.wouldchange"]
        }
        return data;
    }
}