import React from "react";
import Mlicon from "../images/birdimages/ml.svg";
import SendRequest from '../SendRequest';
import { getMessage } from '../js/helpers/utils/BirdMessage';
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;

export default class SharedReports extends React.Component {
    render() {
        return (
            <React.Fragment>
                {(this.props.sharedreports.length>0)? (
                    <>
                    {
                        this.props.sharedreports.map((reportinfo, index) => {
                            return (
                                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2 item-main-container">
                                    <div className="data-item" title={reportinfo.reportname}>
                                        <div className="data-item-headers">
                                            <div className="data-item-name">
                                                {reportinfo.type === "ml_storyboard" ? (
                                                    <a className="" title={"View " + reportinfo.reportname}
                                                        href={this.props.url + "/sharereport/" + btoa(reportinfo.reportId) + "/" + btoa(reportinfo.dataModelId)}
                                                        data-num={reportinfo.reportId} tab-id="0" tab-name="">{reportinfo.reportname}
                                                    </a>) : (reportinfo.type === "smartinsight_storyboard") ? (
                                                        <a className="" title={"View " + reportinfo.reportname}
                                                            href={this.props.url + "/sharesmartinsightreport/" + btoa(reportinfo.reportId) + "/" + btoa(reportinfo.dataModelId)}
                                                            data-num={reportinfo.reportId} tab-id="0" tab-name="">{reportinfo.reportname}
                                                        </a>
                                                    ) :(reportinfo.type=="storybook")?(
                                                    <a className="viewsharestrybook" title={`View ${reportinfo.storybook_name}`} href={`${this.props.url}/sharestorybook/${btoa(reportinfo.storybook_id)}`} data-num={reportinfo.storybook_id} tab-id="0" tab-name="" is_shared={reportinfo.isShared} >{reportinfo.storybook_name}</a>
                                                    )
                                                    :(reportinfo.type=="customstoryboard")?(
                                                        <a className="viewcustomstoryboard" title={`View ${reportinfo.customstoryboard_name}`} href={`${this.props.url}/sharecustomstoryboard/${btoa(reportinfo.customstoryboard_id)}`} data-num={reportinfo.customstoryboard_id} tab-id="0" tab-name="" is_shared={reportinfo.isShared} >{reportinfo.customstoryboard_name}</a>
                                                        ): (<a className="" title={"View " + reportinfo.reportname}
                                                        href={this.props.url + "/sharereport/" + btoa(reportinfo.reportId)} data-num={reportinfo.reportId}
                                                        tab-id="0" tab-name="">{reportinfo.reportname}</a>)
                                                }
                                            </div>
                                            {reportinfo.type!="storybook"?
                                            <div className="data-item-subtitle"><i className="fa fa-folder"></i>&nbsp;{reportinfo.reportFolder}</div>
                                            :null}
                                            <div className="data-item-actions">
                                                <button type="button" className="btn btn-transparent dropdown-toggle dropdown" title={lang["visual.favourites.more"]} data-toggle="dropdown" aria-expanded="true"><i className="fa fa-ellipsis-v"></i></button>
                                                <ul className="dropdown-menu dropdown-menu-right">
                                                    {reportinfo.type === "ml_storyboard" ? (
                                                        <li><a type="button" className="btn btn-data-item-actions" title={"View " + reportinfo.reportname}
                                                            href={this.props.url + "/sharereport/" + btoa(reportinfo.reportId) + "/" + btoa(reportinfo.dataModelId)}
                                                            data-num={reportinfo.reportId} tab-id="0" tab-name="">
                                                            <i className="fa fa-arrow-right marg-righ-10"></i> {lang["visual.favourites.viewreport"]}
                                                                </a>
                                                        </li>) : (reportinfo.type === "smartinsight_storyboard") ? (
                                                            <li><a type="button" className="btn btn-data-item-actions" title={"View " + reportinfo.reportname}
                                                                href={this.props.url + "/sharesmartinsightreport/" + btoa(reportinfo.reportId) + "/" + btoa(reportinfo.dataModelId)}
                                                                data-num={reportinfo.reportId} tab-id="0" tab-name="">
                                                                <i className="fa fa-arrow-right marg-righ-10"></i> {lang["visual.favourites.viewreport"]}
             </a>
                                                            </li>
                                                        ) :(reportinfo.type=="storybook")? (
                                                        <li><a type="button" className="btn btn-data-item-actions" id="viewsharestrybook" title={`View ${reportinfo.storybook_name}`} href={`${this.props.url}/sharestorybook/${btoa(reportinfo.storybook_id)}`} data-num={reportinfo.storybook_id} tab-id="0" tab-name="" is_shared={reportinfo.isShared} ><i className="fa fa-arrow-right marg-righ-10"></i> {lang["visual.favourites.viewreport"]}</a></li>
                                                        ):(reportinfo.type=="customstoryboard")?(
                                                            <a type="button" className="btn btn-data-item-actions" id="viewsharecustomstoryboard" className="viewcustomstoryboard" title={`View ${reportinfo.customstoryboard_name}`} href={`${this.props.url}/sharecustomstoryboard/${btoa(reportinfo.customstoryboard_id)}`} data-num={reportinfo.customstoryboard_id} tab-id="0" tab-name="" is_shared={reportinfo.isShared} >
                                                            <i className="fa fa-arrow-right marg-righ-10"></i> {lang["visual.favourites.viewreport"]} </a>
                                                            )
                                                        :(<li>
                                                            <a type="button" className="btn btn-data-item-actions"
                                                                title={"View " + reportinfo.reportname} href={this.props.url + "/sharereport/" + btoa(reportinfo.reportId)}
                                                                data-num={reportinfo.reportId} tab-id="0" tab-name="">
                                                                <i className="fa fa-arrow-right marg-righ-10"></i> {lang["visual.favourites.viewreport"]}
                                                            </a></li>)
                                                    }
                                                    {reportinfo.type!="storybook" && reportinfo.type!="customstoryboard"?
                                                    <li><a href="javascript:void(0)" type="button" id="sharedreport" title={"Delete " + reportinfo.reportname}
                                                        className="btn btn-data-item-actions deltevisualreports" data-num={reportinfo.reportId}
                                                        tab-id="0" tab-name={reportinfo.reportname} is-shared={reportinfo.isShared}
                                                        is-favourite={reportinfo.isUserFavourite} onClick={() => { this.deleteSharedReport(reportinfo.reportId, reportinfo.isUserFavourite, reportinfo.isShared,index,this,reportinfo.type) }}><i className="fa fa-trash-o marg-righ-10"></i> {lang["visual.favourites.delete"]}</a></li>
                                                        :null}
                                                        {reportinfo.type=="customstoryboard"?
                                                    <li><a href="javascript:void(0)" type="button" id="sharedreport" title={"Delete " + reportinfo.customstoryboard_name}
                                                        className="btn btn-data-item-actions deltevisualreports" data-num={reportinfo.reportId}
                                                        tab-id="0" tab-name={reportinfo.reportname} is-shared={reportinfo.isShared}
                                                        is-favourite={reportinfo.isUserFavourite} onClick={() => { this.deleteSharedReport(reportinfo.customstoryboard_id, reportinfo.isUserFavourite, reportinfo.isShared,index,this,reportinfo.type) }}><i className="fa fa-trash-o marg-righ-10"></i> {lang["visual.favourites.delete"]}</a></li>
                                                        :null} </ul>
                                            </div>
                                        </div>
                                        <div className="data-item-info">
                                            <div className="data-item-info-name"></div>
                                        </div>
                                        <div className="data-item-more-info">
                                            <div className="data-item-desc-box">
                                                <div className="data-item-info-description"></div>
                                            </div>
                                            <div className="data-item-img-box">
                                                {reportinfo.type === "ml_storyboard" || reportinfo.type === "smartinsight_storyboard" ?
                                                    (<img src={Mlicon} className="mlicon" />) : ( reportinfo.isNlpReport==="Y"?(<i className="fa fa-search fa-flip-horizontal"></i>):<i className="fa fa-bar-chart"></i>)
                                                }
                                            </div>
                                        </div>
                                        <div className="data-item-footer">
                                            <div className="data-sync-info"><i className="fa fa-clock-o"></i> {lang["visual.favourites.createon"]} {reportinfo.date}</div>
                                            <div className="data-item-sync-status">
                                                <span className="folderview-bottom-options pull-right">
                                                    {reportinfo.isUserFavourite === 'Y' ?
                                                        (<i className="fa fa-star fa-green"></i>) : null
                                                    }
                                                    {reportinfo.isScheduled === 'Y' ?
                                                        (<i className="fa fa-clock-o fa-gold"></i>) : null
                                                    }
                                                    {reportinfo.isShared === 'Y' ?
                                                        (<i className="fa fa-share-alt fa-violet"></i>) : null
                                                    }
                                                    {reportinfo.isLiveData === 'Y' ?
                                                        (<i className="fa fa-clock-o fa-flip-horizontal fa-green"></i>) : null
                                                    }
                                                    {this.props.features.map((feature1) => {
                                                        if (feature1.moduleName == 'story_board' && reportinfo.reportType == 'Y') {
                                                            feature1.features.map((feature2) => {
                                                                if (feature2.featureName == 'audit_meta_data' && feature2.allow == true) {
                                                                    if (reportinfo.isMetaDataAudit == 'Y' && reportinfo.type != "ml_storyboard") {
                                                                        return (<i className="fa fa-circle-o fa-orchid"></i>);
                                                                    }
                                                                    if (reportinfo.isDataAudit == 'Y' && reportinfo.type != "ml_storyboard") {
                                                                        return (<i className="fa fa-circle-o fa-skyblue"></i>);
                                                                    }
                                                                }
                                                            })
                                                        }
                                                        if (feature1.moduleName == 'my_report' && reportinfo.reportType != 'Y') {
                                                            feature1.features.map((feature2) => {
                                                                if (feature2.featureName == 'audit_meta_data_maximize' && feature2.allow == true) {
                                                                    if (reportinfo.isMetaDataAudit == 'Y') {
                                                                        return (<i className="fa fa-circle-o fa-orchid"></i>);
                                                                    }
                                                                    if (reportinfo.isDataAudit == 'Y') {
                                                                        return (<i className="fa fa-circle-o fa-skyblue"></i>);
                                                                    }
                                                                }
                                                            });
                                                        }
                                                    })}
                                                    {reportinfo.isAlert === 'Y' ? (
                                                        <i className="fa fa-exclamation-triangle fa-warning marg-righ-10"></i>
                                                    ) : null}
                                                    {reportinfo.type!="storybook"?
                                                    <span className="marg-left-5"><i className="fa fa-eye"></i>&nbsp;{reportinfo.numberOFViews}&nbsp;</span>
                                                    :null}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    </>
                ):null
                }
            </React.Fragment>
        )
    }
    deleteSharedReport=(reportid, isFavourite, isShared,index,e,type)=> {
        let requestHeaders = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        var that = this;
        $(".deleteyes").unbind("click");
        $(".deleteno").unbind("click");
        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
        $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
        $('.birdmessagebuttons').addClass('show');
        $('.birdmessage-center').addClass("message-top-in");
        $('.details').removeClass('show');
        $('.details').addClass('hide');
        $('.birdmessage h2').empty().append('Warning');
        $('.birdmessage-info').empty().text(getMessage('BirdWarning18'));
        $(".deleteyes").click(function () {
            $('.birdmessage-center').removeClass("message-top-in");

            var modeldata = {};
            $('.spinner').show();

            modeldata["reportId"] = reportid;
            modeldata["isFavourite"] = true;
            modeldata["isShared"] = false;
            var isCustomSharedReport= false;
            if(type=="customstoryboard"){
                isCustomSharedReport= true;
            }
            var data = new FormData();
            data.set("reportId", reportid);
            data.set("isFavourite", false);
            data.set("isSharedReport", true);
            data.set("isReportOwner", false);
            data.set("isCustomSharedReport", isCustomSharedReport);

            SendRequest({
                url: "deletereport",
                body: data,
                sucessFunction: response => {
                    if (response.hasOwnProperty('error')) {
                        $(".spinner").hide();
                        $(".gridspinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.errorTrace)
                        /*$('#main').prepend('<div class="alert alert-danger birderror alert-dismissable affix-top" style="margin:0 auto;width: 60%;top: 44px;position: fixed;z-index: 1;left: 20%;">'+response.error+'</div>');
                            setTimeout(function() {
                                $('.birderror').remove();	
                            }, 3000);*/
                    } else {
                        //$($this).closest(".boc-snapshot").remove();
                        $(".spinner").hide();
                        $(".gridspinner").hide();
                        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Success');
                        $('.birdmessage-info').empty().text(getMessage('BirdSuccess42'));
                        const shareReportsArray=Object.assign([],that.props.state.sharedreports);
                        shareReportsArray.splice(index,1);
                        that.props.TargetThis.setState({sharedreports:shareReportsArray});
                    }
                },
                rejectFunction: () => { },
                headers: requestHeaders
            });

        });
        $(".deleteno").click(function () {
            $('.birdmessage-center').removeClass("message-top-in");
        });
    }
}