import React from 'react';
import { lang} from "../../../js/helpers/utils/languages/language";
export default class SAMLTemplate extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        var response = this.props.response;
        return(
            <div className="row in-back">
	<div className="row-same-height-inner">
		<div className="col-xs-6 col-xs-height in-panes" id="icontent">
			<div className="innerothersarea mailconfigform">
				<div className="row in-back max-height-40vh">
					<div className="max-height-30vh">
					<h2 className="boc-main-title">{lang["admin.saml.title"]}</h2>
						<form className="form-horizontal col-xs-12 oauth2configform" >
							<div className="form-group form-group-sm">
								<label for="loginurl" className="col-sm-3 control-label important">{lang["admin.saml.loginURL"]}</label>
								<div className="col-sm-6">
									<input type="text" className="form-control" id="loginurl" name="loginurl"
										placeholder={lang["admin.pholdloginURL"]} defaultValue={response.loginurl == undefined ?'' :response.loginurl} />
										<span className="help-block loginurlerror hidden"></span>
								</div>
							</div>
							<div className="form-group form-group-sm">
								<label for="logouturl" className="col-sm-3 control-label important">{lang["admin.saml.logoutURL"]}</label>
								<div className="col-sm-6">
									<input type="text" className="form-control" id="logouturl" name="logouturl"
										placeholder={lang["admin.pholdlogoutURL"]} defaultValue={response.logouturl == undefined ?'' :response.logouturl} />
										<span className="help-block logouturlerror hidden"></span>
								</div>
							</div>
						
							<div className="form-group form-group-sm">
								<label for="certificate" className="col-sm-3 control-label important">{lang["admin.saml.certifcate"]}</label>
								<div className="col-sm-6">
									<textarea rows="4" cols="50" className="form-control textarea" name="certificate" id="certificate" 
									placeholder={lang["admin.pholdcertifcate"]}>{response.certificate == undefined ?'' :response.certificate}</textarea>	
										<span className="help-block certificateerror hidden"></span>
								</div>
							</div>
							
							<div className="form-group form-group-sm">
								<label for="" class="col-sm-3 control-label"></label>
								<div className="col-sm-6">
									<button type="button" className="btn btn-bird  marg-righ-5" id="saveooauth2details" onClick={this.props.saveSamlDetails}><i className="fa fa-refresh marg-righ-5"></i> {lang["admin.saml.savebtn"]}</button>
								</div>
							</div>
						</form>
					</div>
				</div>
				
			</div>
		</div>
	</div>
</div>
		
			
		
        );
    }
}