import Properties from './Properties/ServerProperties'
import urls from './Properties/ControllerProperties'
import SendRequest from './SendRequest';
import $ from "jquery/dist/jquery";
import { Component } from 'react';
import { lang } from "./js/helpers/utils/languages/language";

var failedRequestsQueue = []

export default props => {
  let urlString = `/api/${props.url}${props.queryString != undefined ? props.queryString : ''}`;
  window.localStorage.setItem("appName", Properties.appName);
  let accesstoken = window.localStorage.getItem("accessToken");
  if (accesstoken != null) {
    var btokenObj = JSON.parse(atob(accesstoken.split('.')[1]));
    var btokenExp = btokenObj.exp;
    var local = new Date(Math.round(+new Date() / 1000) * 1000).toGMTString();
    var server = new Date(btokenExp * 1000).toGMTString();
    if (new Date(local).getTime() > new Date(server).getTime()) {
      var refreshToken = window.localStorage.getItem("refreshToken");
      // window.localStorage.removeItem("accessToken");
      let queryString = "?refreshToken=" + refreshToken;
      let url = "authenticate/refresh";
      let urlString = `/api/${url}${queryString != undefined ? queryString : ''}`;
      let requestHeaders = {
        method: "get",
        headers: new Headers({
          "Content-Type": "application/json"
        })
      };
      if (url == "authenticate/refresh") {
        const fetchresult = async () => {
          let response = await fetch(urlString, requestHeaders)
          let result = await response.json();
          window.localStorage.setItem("refreshToken", result.refreshToken);
          window.localStorage.setItem("accessToken", result.accessToken);
          window.localStorage.setItem("accessTokenExpiration", result.accessTokenExpiration);
          window.localStorage.setItem("serverTimezone", result.timezoneId);

          if (failedRequestsQueue.length > 0) {
            for (var f = 0; f < failedRequestsQueue.length; f++) {
              SendRequest({
                url: failedRequestsQueue[f].url,
                queryString: failedRequestsQueue[f].queryString,
                sucessFunction: failedRequestsQueue[f].sucessFunction,
                rejectFunction: failedRequestsQueue[f].rejectFunction,
                headers: failedRequestsQueue[f].headers,
              });
              failedRequestsQueue.splice(f, 1);
            }
          }
        };
        fetchresult();
      }
    }

    accesstoken = window.localStorage.getItem("accessToken");
    if (props.queryString != undefined && props.queryString.length > 0) {
      urlString += `&api_key=` + accesstoken;
    } else {
      urlString += `?api_key=` + accesstoken;
    }
  }
  props.headers.headers["api_key"] = window.localStorage.getItem("accessToken");
  //for Saving Failed Requests in a Queue when token Expired
  if (accesstoken == null && (props.url != "authenticate/" && props.url != "authenticate/refresh" && props.url != "authenticate/login" && props.url != "authenticate/resetUser")) {
    failedRequestsQueue.push(props);
    console.log(failedRequestsQueue);
  }
  if (props.url == "downloadDataTable") {
    fetch(urlString, {
      method: 'post',
      body: props.body
    })
      .then(function (response) {
        return response.blob();
      }).then(function (blob) {
        props.sucessFunction(blob);
      })
    return;
  }
  if (props.url == "authenticate/bypassloginpage") {
    fetch(urlString, {
      method: 'get',
    })
      .then(response => response.json()
      ).then( result => {
        props.sucessFunction(result);
      },
      error => {
        //props.rejectFunction();
      })
    return;
  }
  if (props.headers.method != undefined && props.headers.method == 'post' && urlString.indexOf("login") <= 0) {
    if (accesstoken != null || props.url == "authenticate/refresh" || props.url == "authenticate/login" || props.url == "authenticate/resetUser") {
      fetch(urlString, {
        method: 'post',
        body: props.body
      })
        .then(response => response.json())
        .then(
          result => {
            props.sucessFunction(result);
          },
          error => {
            //props.rejectFunction();

          }
        );
    } else if (props.url == "authenticate/public" || props.url === "authenticate/updaterootlicensekey") {
      fetch(urlString, {
        method: 'post',
        body: props.body
      }).then(response => response.json())
        .then(
          result => {
            props.sucessFunction(result);
          },
          error => {
            console.log(error);
            //props.rejectFunction();

          }
        );
    } else if (props.url == "authenticate/") {
      fetch(urlString, {
        method: 'post',
        body: props.body
      })
        .then(response => {
          if (response != undefined && response != null && response.status == '504') {
            document.getElementById('error').innerHTML = lang["login.servernotresponding"];
            $(".spinner").hide();
            return;
          } else {
            return response.json()
          }
        })
        .then(
          result => {
            props.sucessFunction(result);
          },
          error => {
            console.log(error);
            //props.rejectFunction();

          }
        );
    }
  } else {
    if (accesstoken == null || props.url == "authenticate/refresh" || props.url == "authenticate/login" || props.url == "authenticate/resetUser") {
      fetch(urlString, {
        method: 'post',
        body: props.body
      })
        .then(response => response.json())
        .then(
          result => {
            props.sucessFunction(result);
          },
          error => {
            if (error == 'TypeError: Failed to fetch' && document.getElementById('error') != null && document.getElementById('error') != undefined) {
              document.getElementById('error').innerHTML = lang["login.servernotresponding"];
              $(".spinner").hide();
            }
            console.log(error);
            //props.rejectFunction();

          });
    }

    else {
      if (accesstoken != null || props.url == "authenticate/refresh" || props.url == "authenticate/login") {
        fetch(urlString, props.headers)
          .then(response => {
            if (response != undefined && response != null && response.status == '504') {
                sessionStorage.removeItem("accessToken");
                sessionStorage.removeItem("reservedwordslist");
                window.localStorage.clear();
                window.location.replace("/");
                $(".spinner").hide();
                return;
            } else {
              return response.json()
            }
          })
          .then(
            result => {
              props.sucessFunction(result);
            },
            error => {
              if (error == 'TypeError: Failed to fetch' && document.getElementById('error') != null && document.getElementById('error') != undefined) {
                document.getElementById('error').innerHTML = lang["login.servernotresponding"];
                $(".spinner").hide();
              }
              console.log(error);
              //props.rejectFunction();

            });
      }
    }
  }
};