import React from "react";
import SendRequest from '../SendRequest';
import PubSub from 'pubsub-js'
import _ from 'lodash';
import { lang } from "../js/helpers/utils/languages/language";
import { getMessage } from '../js/helpers/utils/BirdMessage';
const $ = window.$;
export default class AttrModalDimensionsviewTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.converttostring = this.converttostring.bind(this);
        this.converttonumber= this.converttonumber.bind(this);
    }
    render() {
        var that = this.props;
        return (
            // <!-- attrmodaldimensionsview.js -->
            <li key={that.dynamicdiv} className="boc-list-item singleliitems attributesdimensions"
                data-name={that.columnName} data-type={that.dataType} table-name={that.tableName}
                title={that.columnDisplayName}>
                <span className="itemtype">
                    {(that.dataType === 'number') ? (
                        <>123</>
                    ) : (that.dataType === 'date' || that.dataType === 'datetime' || that.dataType === 'month' || that.dataType === 'day') ? (
                        <i className="fa fa-calendar"></i>
                    ) : (
                                <>{lang["storyboard.abc"]}</>
                            )}
                </span>
                {(that.dataType === 'date' || that.dataType === 'datetime') ? (
                    <>
                        <span className="itemname">{that.columnDisplayName}</span>
                        <span>
                            <span className="itemedit createdatedropdown" onClick={that.createdatedropdown} aria-expanded="false" type="button" data-edit={lang["storyboard.edit"]} data-name={that.columnName} data-value={that.columnName} title={lang["storyboard.createnewdate"]}><i className="fa fa-plus"></i></span>
                            <ul role="menu" className="dropdown-menu dropdown-inline createdatedropdownul" data-name={that.columnName}>
                                <li className="aggregation-li-item createdateitem" title={`{Create ${that.columnName}_toYear}`}><span className="function marg-righ-5">{lang["storyboard.fx"]}</span> {lang["storyboard.toyr"]}</li>
                                <li className="aggregation-li-item createdateitem" title={`{Create ${that.columnName}_toQuarter}`}><span className="function marg-righ-5">{lang["storyboard.fx"]}</span> {lang["storyboard.toquarter"]}</li>
                                <li className="aggregation-li-item createdateitem" title={`{Create ${that.columnName}_toMonth}`}><span className="function marg-righ-5">{lang["storyboard.fx"]}</span> {lang["storyboard.tomon"]}</li>
                            </ul>
                        </span>
                    </>
                ) : (
                        <span className="itemname">{that.columnDisplayName}</span>
                    )}

                {that.allfeatures.map((featuresModule, index) => {
                    if (featuresModule.moduleName == 'my_report') {
                        return featuresModule.features.map((feature, index) => {
                            if (feature.featureName == 'viewreport_maximize_rename_column' && feature.allow == true) {
                                if (that.isDirect == 0 || that.isStoredProcedure == 0) {
                                    return (<span type="button" data-name={that.columnDisplayName} title={that.columnDisplayName} className="opacity-effect editdimensions" data-edit={lang["storyboard.edit"]}>
                                        <i className="fa fa-pencil"></i>
                                    </span>);
                                }
                            }
                        })
                    }
                })}
                {(that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                    <span data-toggle="dropdown" aria-expanded="false" id="dropdownaggregation" type="button" className="boclabel boclabel-right-5 opacity-effect dropdown-toggle marg-righ-15">
                        <i className="fa fa-cog"></i>
                    </span>
                ) : null}
                {(that.converted === 'yes') ? (
                    <span className="btn-group pull-right">
                        <button className="btn btn-transparent deleteaggregation" type="button" displayname={that.columnDisplayName}>
                            <i className="fa fa-times fa-inverse"></i>
                        </button>
                    </span>
                ) : null}

                {(that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                    <ul aria-labelledby="dropdownaggregation" role="menu" className="dropdown-menu dropdown-inline">
                        {/* <!-- <li className="aggregation-li-item converttomeasure"
		data-name={that.columnName} data-type={that.dataType} table-name={that.tableName}
		title={that.columnDisplayName}>Create a Measure</li> -->  */}
                        {/* if(that.isDirect == 0 && (that.dataType !='date' || that.dataType != 'datetime')){ 
	<!-- <li className="aggregation-li-item converttodate"
		data-name={that.columnName} data-type={that.dataType} table-name={that.tableName}
    	title={that.columnDisplayName}>Convert to Date</li> --> 
}  */}
                        {(that.isDirect == 0 && (that.dataType != 'number' && that.dataType == 'string')) ? (
                            <li className="aggregation-li-item converttonumber" onClick={this.converttonumber} data-value={`${that.columnDisplayName}_numb`}
                                data-name={that.columnName} data-type={that.dataType} table-name={that.tableName}
                                title={that.columnDisplayName}>{lang["storyboard.convertnum"]}</li>
                        ) : null}
                        {/* if(that.isDirect == 0 && (that.dataType!= 'month')){
	<!-- <li className="aggregation-li-item converttoday"
		data-name={that.columnName} data-type={that.dataType} table-name={that.tableName}
		title={that.columnDisplayName}>Convert to Day</li> -->
		} */}
                        {/* if(that.isDirect == 0 && (that.dataType!= 'day')){
	<!-- <li className="aggregation-li-item converttomonth"
		data-name={that.columnName} data-type={that.dataType} table-name={that.tableName}
		title={that.columnDisplayName}>Convert to Month</li> --> 
        } */}
                        {(that.isDirect == 0 && (that.dataType === 'number' || that.dataType === 'date' || that.dataType === 'datetime' || that.dataType === 'month' || that.dataType === 'day')) ? (
                            <li className="aggregation-li-item converttostring" onClick={this.converttostring} data-value={`${that.columnDisplayName}_str`}
                                data-name={that.columnName} data-type={that.dataType} table-name={that.tableName}
                                title={that.columnDisplayName}>{lang["storyboard.convertstr"]}</li>
                        ) : null}
                    </ul>
                ) : null}
                <span type="button" className="boclabel boclabel-right-5 opacity-effect previewcolumndata" data-displayname={that.columnDisplayName} data-type={that.dataType} data-name={that.columnName} title="Preview Data" onClick={(e) => { this.PreviewData(e, that.columnName, that.columnDisplayName, that.dataType) }}>
                    <i className="fa fa-table"></i>
                </span>
            </li>
        );
    }
    PreviewData(e, name, dispalyname, datatype) {
        var appObj = {};
        appObj["name"] = name;
        appObj["displayname"] = dispalyname;
        appObj["datatype"] = datatype;
        appObj["target"] = e;

        PubSub.publish("previewcolumndata", appObj);
    }
    converttostring(e) {
        var $this = e.currentTarget;
        e.preventDefault();
        this.validateCreateConversion($this, 'string')
    }
    converttonumber(e){
        var $this=e.currentTarget;
        e.preventDefault();
        this.validateCreateConversion($this,'number')
    }
    validateCreateConversion($this, conversionType) {
        var that = this;
        var customFields = sessionStorage.getItem("customFields");
        var customObject = [], fieldNamesArray = [];
        var tempExp = "";
        var priorityColumn = false;
        var fieldName = ""
        var matchCount = 0;
        if (customFields != undefined && customFields != null && customFields != 'null') {
            customObject = JSON.parse(customFields);
            _.each(customObject, function (val) {
                if (val.name != undefined && (val.name.indexOf($($this).attr('data-value')) != -1 || ($($this).attr('data-value').indexOf(".") != -1 && val.name.indexOf($($this).attr('data-value').split(".")[1]) != -1))) {
                    matchCount++;
                }
                fieldNamesArray.push(val.name);
            });
        }
        var usedFeildArray = [];
        if (fieldNamesArray.indexOf($($this).attr('data-value')) != -1) {
            fieldName = $($this).attr('data-value') + "_" + matchCount;
        } else {
            fieldName = $($this).attr('data-value');
        }
        if (fieldName.indexOf(".") != -1) {
            if (fieldNamesArray.indexOf(fieldName.split(".")[1]) != -1) {
                fieldName = fieldName.split(".")[1] + "_" + matchCount;
            }
            else {
                fieldName = fieldName.split(".")[1]
            }
        }
        var fnValue
        if (conversionType === 'number') {
            fnValue = 'toFloat64OrZero(@replace)';
        } else if (conversionType === 'string') {
            fnValue = 'toString(@replace)';
        }

        var columnName = $($this).attr('data-name');
        var textareaData = fnValue.replace(/@replace/g, "\"" + $($this).attr('data-name') + "\"")
        var fieldstextareaData = fnValue.replace(/@replace/g, "[" + $($this).attr('data-name') + "]")
        var tempExp = "";
        var formula = "def val0 = doc['" + columnName + "'].value; if(val0 == null ) { val0 = ''};" + fnValue.replace(/@replace/g, "(val0)");
        var scriptCondition = fnValue.replace(/@replace/g, "(val0)")
        usedFeildArray.push($($this).attr('data-name'));
        var htmlTextValue = '<span class="customfieldaggrlist" data-word="' + fnValue.replace(/@replace/g, '') + '" contenteditable="false">' + fnValue.replace(/@replace/g, '</span><span>[<span class="fa-orange customfieldlist" displayname="' + $($this).attr('data-name') + '" columnname="' + $($this).attr('data-name') + '">' + $($this).attr('data-name') + '</span>]') + '</span>';
        var that = this;
        var requestbody = new FormData();
        requestbody.set("reportId", that.props.reportId != undefined && that.props.reportId != null ? that.props.reportId : "");
        requestbody.set("script", formula);
        requestbody.set("entities", "");
        requestbody.set("datamodelId", that.props.datamodelId);
        requestbody.set("customType", "customfield");
        requestbody.set("isImportedReport", false);
        requestbody.set("sqlformula", textareaData);
        requestbody.set("customFormulaFieldsArray", JSON.stringify(usedFeildArray));

        let requestHeaders = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "testcustomformula",
            body: requestbody,
            sucessFunction: (response) => {
                if (response.valid) {
                    var datatype = (response.datatype == undefined) ? "String" : response.datatype;
                    tempExp += "def val0 = doc['" + columnName + "'].value; ";
                    if (datatype == 'number') {
                        tempExp += "if(val0 == null ) { val0 = 0};";
                    }
                    else {
                        tempExp += "if(val0 == null ) { val0 = ''};";
                    }
                    formula = tempExp
                    var tempCustomObject = {
                        "name": fieldName, "dataType": datatype, "type": "dimension", "formula": formula, "htmltext": htmlTextValue, "fields": fieldstextareaData, "sqlformula": textareaData, "scriptcondition": scriptCondition,
                        "priorityColumn": priorityColumn, "functiontype": "string", "parametrFieldsArray": [], "usedFields": usedFeildArray
                    };
                    customObject.push(tempCustomObject);
                    sessionStorage.setItem("customFields", JSON.stringify(customObject));
                    PubSub.publish("addcustomfield", tempCustomObject);
                    $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                    $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('show');
                    $('.details').addClass('hide');
                    $('.birdmessage h2').empty().append('Success');
                    $('.birdmessage-info').empty().text(getMessage('BirdSuccess31'));
                    //							$('.convertto'+conversionType).parent().removeClass('open');
                }
            },
            rejectFunction: this.reportDataError,
            headers: requestHeaders
        })
    }
}