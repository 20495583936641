import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import SendRequest from '../SendRequest';
import _ from 'underscore';
const $ = window.$;
const columns = [
  { dataField: 'id', text: 'S.No.', sort: false },
  { dataField: 'report_visualization_name', text: 'Report Name', sort: true },
  { dataField: 'report_visualization_desc', text: 'Description', sort: true },
  { dataField: 'folder_name', text: 'Folder', sort: true },
  { dataField: 'create_date', text: 'Created Date', sort: true },
  { dataField: 'is_storyboard', text: 'Report Type', sort: true },
  { dataField: 'viewReport', text: 'View Report', sort: false },
  { dataField: 'deleteReport', text: 'Delete Report', sort: false }
];
const RemotePagination = ({ data, page, sizePerPage, onTableChange, totalSize }) => (
  <BootstrapTable
    remote
    keyField="id"
    data={data}
    columns={columns}
    pagination={paginationFactory({ page, sizePerPage, totalSize })}
    onTableChange={onTableChange}
  />
);
export default class FoldersListTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      data: props.data,
      sizePerPage: props.sizePerPage,
      totalRecords: props.totalRecords,
      search: props.search
    };
    this.state.search = props.search;
    var searchtext = this.state.search
  }

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, data, totalRecords }) => {
    const currentIndex = (page - 1) * sizePerPage;
    {
      var that = this;
      var foldername = this.props.foldername
      if (that.state.search == undefined) {
        that.state.search = "";
      }
      type == "sort" ?
        setTimeout(() => {
          let requestHeaders = {
            method: "get",
            headers: new Headers({
              "Content-Type": "application/json"
            })
          };
          SendRequest({
            url: "getdashboardreportslist",
            queryString: "?fromDate= " + " " + "&toDate= " + " " + "&index= " + currentIndex + "&noOfRecords=" + sizePerPage + "&searchCriteria=" + that.state.search + "&order=" + sortOrder + "&colName=" + sortField + "&folderName=" + foldername + "&urltype=" + "getUserPaginationReportsByFolder",
            sucessFunction: (response) => {
              if (response.hasOwnProperty('error')) {
                $(".spinner").hide();
              } else {
                var headerlist = response["header"];
                var data = response["data"];
                _.each(data, function (val, index) {
                  if (val.type == "ml_storyboard") {
                    val.viewReport = <a href={`${that.props.url}/viewmodel/${btoa(val.reportId)}/${btoa(val.dataModelId)}`} data-num={val.reportId} tab-id="0" tab-name="" class="boc-snapshot-thumbnail reportview" id="reportstoryboardview"><i class="fa fa-bar-chart"></i></a>;
                  } else if (val.type == "smartinsight_storyboard") {
                    val.viewReport = <a href={`${that.props.url}/viewsmartinsight/${btoa(val.reportId)}/${btoa(val.dataModelId)}`} data-num={val.reportId} class="boc-snapshot-thumbnail reportview" tab-id="0" tab-name="" id="reportstoryboardview"><i class="fa fa-bar-chart"></i></a>;
                  } else {
                    val.viewReport = <a href={`${that.props.url}/viewreport/${btoa(val.reportId)}`} data-num={val.reportId} class="boc-snapshot-thumbnail reportview" tab-id="0" tab-name="" id="reportstoryboardview"><i class="fa fa-bar-chart"></i></a>;
                  }
                  val.deleteReport = <button type="button" onClick={() => {that.props.deleteReport(val.reportId, val.isUserFavourite, val.isShared) }} id="favreportlistview" data-num={val.reportId} tab-id="0" tab-name="" is-shared={val.isShared} is-favourite={val.isUserFavourite} class="btn btn-transparent aldelete"><i class="fa fa-trash-o"></i></button>;
                });

                var keys = Object.keys(headerlist);
                this.setState(() => ({
                  page,
                  data: data,
                  sizePerPage,
                  totalSize: totalRecords
                }));
              }
            },
            rejectFunction: () => { },
            headers: requestHeaders
          })
        }, 10) :
        setTimeout(() => {
          let requestHeaders = {
            method: "get",
            headers: new Headers({
              "Content-Type": "application/json"
            })
          };
          SendRequest({
            url: "getdashboardreportslist",
            queryString: "?fromDate= " + "" + "&toDate= " + "" + "&index= " + currentIndex + "&noOfRecords=" + sizePerPage + "&searchCriteria=" + that.state.search + "&order=" + sortOrder + "&colName=" + "" + "&folderName=" + foldername + "&urltype=" + "getUserPaginationReportsByFolder",
            sucessFunction: (response) => {
              if (response.hasOwnProperty('error')) {
                $(".spinner").hide();
              } else {
                var headerlist = response["header"];
                var data = response["data"];
                _.each(data, function (val, index) {
                  if (val.type == "ml_storyboard") {
                    val.viewReport = <a href={`${that.props.url}/viewmodel/${btoa(val.reportId)}/${btoa(val.dataModelId)}`} data-num={val.reportId} tab-id="0" tab-name="" class="boc-snapshot-thumbnail reportview" id="reportstoryboardview"><i class="fa fa-bar-chart"></i></a>;
                  } else if (val.type == "smartinsight_storyboard") {
                    val.viewReport = <a href={`${that.props.url}/viewsmartinsight/${btoa(val.reportId)}/${btoa(val.dataModelId)}`} data-num={val.reportId} class="boc-snapshot-thumbnail reportview" tab-id="0" tab-name="" id="reportstoryboardview"><i class="fa fa-bar-chart"></i></a>;
                  } else {
                    val.viewReport = <a href={`${that.props.url}/viewreport/${btoa(val.reportId)}`} data-num={val.reportId} class="boc-snapshot-thumbnail reportview" tab-id="0" tab-name="" id="reportstoryboardview"><i class="fa fa-bar-chart"></i></a>;
                  }

                  val.deleteReport = <button type="button" onClick={() => { that.props.deleteReport(val.reportId, val.isUserFavourite, val.isShared) }} id="favreportlistview" data-num={val.reportId} tab-id="0" tab-name="" is-shared={val.isShared} is-favourite={val.isUserFavourite} class="btn btn-transparent aldelete"><i class="fa fa-trash-o"></i></button>;
                });
                var keys = Object.keys(headerlist);
                this.setState(() => ({
                  page,
                  data: data,
                  sizePerPage,
                  totalSize: totalRecords
                }));
              }
            },
            rejectFunction: () => { },
            headers: requestHeaders
          })

        }, 10);
    }
  }
  render() {
    const { data, sizePerPage, page, totalRecords } = this.state;
    return (
      <RemotePagination
                  data={data}
                  page={page}
                  sizePerPage={sizePerPage}
                  totalSize={totalRecords}
                  onTableChange={this.handleTableChange} />
    );
  }
}