import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'underscore';
import { lang } from "../js/helpers/utils/languages/language";
export default class DatamodelNewItemSettingsHtml extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        return(
            <div role="tabpanel" className="datamodelitemsettingstabscontainer" name={this.props.entity}>
	<div className="entityname">
		<i className="fa fa-table"></i>{this.props.entity}
		<button type="button" className="btn btn-transparent pull-right closesettingscontainer" onClick={this.props.close}><i className="fa fa-times"></i></button>
	</div>
	<ul className="nav nav-tabs tabcontainer" role="tablist">
		<li role="presentation" className="tabheadli active">
			<a href="#datamodelsymanticnamestabview" id="datamodelsymanticnamestab" aria-controls="datamodelsymanticnamestabview" role="tab" data-toggle="tab"><i className="fa fa-pencil"></i> {lang["models.modifyentity"]}</a>
		</li>
	</ul>
	<div className="tab-content">
		<div role="tabpanel" className="tab-pane active tabviewitems" id="datamodelsymanticnamestabview">
			<div className="container-fluid marg-top-10">
				<div className="row">
					<div className="col-xs-12">
						<button className="btn btn-bird btn-sm pull-right applydatamodelsmodifyentities marg-righ-10" id="" onClick={this.props.applydatamodelconmodifyentities}><i className="fa fa-check"></i> {lang["models.updatebtn"]}</button>
					</div>	
				</div>
			</div>
			<div className="container-fluid">
				<div className="row">
					<div className="col-xs-12">
						<div className="notifications-table-container marg-top-10 datamodelsymanticnamestablecontainer">
							
						</div>
					</div>
				</div>
			</div>	
		</div>
	</div>	
</div>
        );
    }
}