import React, { Component } from "react"
import _ from 'lodash';
import ReactDOM from "react-dom"
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;

export default class SingleReportSettingsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicdiv: props.dynamicdiv,
            chartnumber: props.chartnumber
        };
        this.render();
    }
    render() {
        ReactDOM.render(<SingleReportSettingsTemplate applysettingchanges={this.applysettingchanges} singletitleclose={this.singletitleclose} title={$(".boc-viewreport-title[data-num=" + this.props.chartnumber + "]").text()} />, document.getElementById(this.state.dynamicdiv));
        return (<div></div>)
    }
    singletitleclose = () => {
        ReactDOM.unmountComponentAtNode(document.getElementById(this.state.dynamicdiv));
//$(`#${this.state.dynamicdiv}`).empty();
        if ($('.modal-backdrop').length != 0) {
            $('.modal-backdrop').remove();
        }
    }
    applysettingchanges = () => {
        var titlename = $("#" + this.state.dynamicdiv).find("#titlename").val();
        var titledesc = $("#" + this.state.dynamicdiv).find("#titledescription").val();
        if (titlename == undefined || titlename == "" || titlename.length == 0 || /[~`!@#$%^*+\=\[\]{};':\\|†.<>\/?"""`]/.test(titlename)) {
            $("#tilename-help-block").html('Please Enter Valid Name');
            $("#tilename-help-block").removeClass('hidden');
			return;
		}
        //  this.options.model.set('titlename',titlename);
        // this.options.model.set('titledesc',titledesc);	

        //  if (this.options.model.isValid(true)) {
        //$("#pagesettingsmodal").remove();
        $(".boc-viewreport-title[data-num=" + this.props.chartnumber + "]").text(titlename).attr("title", titlename);
        $(".boc-viewreport-title[data-num=" + this.props.chartnumber + "]").attr("customtitle", "1");
        var chartInfodetails = ChartInfo.functions.getChartDetails(this.state.chartnumber);
        var parsedData = JSON.parse(chartInfodetails);
        parsedData["reporttitle"] = titlename;
        parsedData["reporttitledesc"] = titledesc;
        ChartInfo.functions.updateChartDetails(this.state.chartnumber, JSON.stringify(parsedData));
        $('.maxanim4[data-num=' + this.state.chartnumber + ']').text(titlename).attr("title", titlename);
        sessionStorage.setItem("changesdone", 1);
        //   }
        //this.singletitleclose();
        ReactDOM.unmountComponentAtNode(document.getElementById(this.state.dynamicdiv));
        //$(`#${this.state.dynamicdiv}`).empty();
    }
}
class SingleReportSettingsTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        var that = this.props;
        return (
            //    <!-- singlereportsettingmodal.js -->
            <div className="modal fade in" id="pagesettingsmodal" tabIndex="-1" role="dialog" aria-labelledby="pagesettingsmodal" aria-hidden="true" style={{ display: "block" }}>
                {/* <div className="modal-backdrop fade in"></div> */}
                <div className="modal-dialog modal-sm modal-sm-400 modal-dialog-sm-center">
                    <div className="modal-content">
                        <div className="modal-header boc-modal-hf-padding">
                            <button type="button" className="close white" id="singletitleclose" onClick={that.singletitleclose} aria-hidden="true" data-dismiss="modal">&times;</button>
                            <h4 className="modal-title"><i className="fa fa-pencil"></i> {lang["storyboard.edittitle"]}</h4>
                        </div>
                        <div className="modal-body scrollset">
                            <div className="container-fluid">
                                <div className="row filtercontent">
                                    <div className="validationgroup">
                                        <span className="required right legend"></span>
                                        <legend className="subhead">{lang["storyboard.myreporttitle"]}</legend>
                                        {/* <!-- <input type="name" id="titlename" value="<%=title%>" className="modal-form-fields" name="titlename" placeholder="Storyboard Title"> --> */}
                                        <div className="col-xs-12 nopadding"><textarea type="name" id="titlename" rows="1" className="modal-form-fields resize-vertical" name="titlename" placeholder={lang["storyboard.myreporttitle"]} defaultValue={that.title} /></div>
                                        <span className="help-block hidden marg-left-10" id="tilename-help-block"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer boc-modal-hf-padding">
                            <button type="button" className="btn btn-bird" id="btn-birdviewmaxi" onClick={that.singletitleclose} data-dismiss="modal"><i className="fa fa-times"></i> {lang["storyboard.cancelbtn"]}</button>
                            <button type="button" className="btn btn-bird applysetting" onClick={that.applysettingchanges} id="singleapplysetting"><i className="fa fa-check"></i> {lang["storyboard.applybtn"]}</button>
                        </div>
                    </div>
                </div>
            </div>);
    }
}