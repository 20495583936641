import React, { Component } from "react";
import _ from 'lodash';
import PubSub, { publish } from 'pubsub-js'
import $ from 'jquery';
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import ReportBody from './ReportBody.jsx';
import { getMessage } from '../js/helpers/utils/BirdMessage';
import SendRequest from '../SendRequest';
import ReactDOM from "react-dom";
import * as ModalProperties from "../js/helpers/utils/modalproperties";
import * as viewcollection from "../js/helpers/utils/SavedReportCollection";
import AlertsView from "./alerts/createalertview"
import CustomFieldModal from "./customfieldmodal"
import DrillThroughOptionsView from './drillthroughoptionsview'
import DrilldownAllSettingsview from './drilldownallsettingsview'
import { lang } from "../js/helpers/utils/languages/language";

class ReportGridHtml extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.drilldownsettingsEve = PubSub.subscribe('drilldownsettings', this.drilldownsettingsEve.bind(this));
        this.showcustomFieldDialogEve = PubSub.subscribe('showcustomfielddialog', this.showcustomFieldDialogEvee.bind(this));
        this.drillthroughEvee = PubSub.subscribe('pie-drillthrough', this.drillthroughEve.bind(this));
        this.storyboardalerts = this.storyboardalerts.bind(this);
        if (this.props != undefined) {
            var chartType = this.props.tileinfo.chartType;
            if (chartType === "metric" || chartType === "solidgauge" || chartType === "fullcirclegauge" || chartType == "summarytable" || chartType == "doby"
                || chartType == "textchart" || chartType == 'pivot' || chartType == 'summarytable' || chartType == 'sparkline'/*TODO add datehistogramstatus*/) {
                this.state = { storyboardbuttons: "hide" };
            }
            else {
                this.state = { storyboardbuttons: "show" };
            }
            var that = this;
            Object.keys(this.props).map(function (itm) {
                var val = props[itm];
                that.state[itm] = val;
            });
        }
    }
    componentWillUnmount() {
		PubSub.unsubscribe(this.drilldownsettingsEve);
    }
    render() {
        var that = this;
        var tileinfo = this.props.tileinfo;
        return (
            <div data-num={this.props.chartid} >
                <div className={`alert alert-danger clonesinglemaxreporterror${this.props.chartid}`} style={{ display: "none" }} role="alert">{lang["reports.errormsg"]}</div>
                <div className={`alert alert-success clonesinglemaxreportsuccess${this.props.chartid}`} style={{ display: "none" }} role="alert">{lang["reports.clonemsg"]}</div>
                <div className="boc-snapshot-auto maxanim2" >
                    <ReportBody style={{ height: "inherit", width: "inherit" }} storyboardalerts={this.storyboardalerts} chartid={that.props.chartid} chartitle={that.props.charttitle} multifact={that.props.multifact}
                        storyBookel={that.props.storyBookel} isFromTabClick={this.props.isFromTabClick} scheduleid={this.props.scheduleid} date={this.props.date} chartnumber={that.props.chartnumber} current_user_role="ROLE_Power_User" isEmbeddedReport="false" allfeatures={that.props.allfeatures}
                        isSharedReport="false" isScheduleReport="false" isViewOnlyFlags={that.props.view_Only_Flag} isPurgedReport="false" reportId={that.props.reportId}
                        isDirect={that.props.isDirect} isStoredProcedure={that.props.isStoredProcedure} chartType={that.props.chartType}
                        schemaType={that.props.schemaType} isMicrosoftEdge={that.props.isMicrosoftEdge} tileinfo={that.props.tileinfo} reportcolumns={that.props.reportcolumns}
                        isPublished={that.props.isPublished} multifactentities={that.props.multifactentities} facttables={that.props.facttables} datamodelId={that.props.datamodelId} grid={that.props.grid} />
                </div>
            </div>

        );
    }

    storyboardalerts = (e) => {
        e.preventDefault();
        var that = this;
        var $this = e.currentTarget;
        var chartnumber = $($this).closest('.maxanim1').attr("data-num");
        var reportId = that.props.reportId;
        var chartdetails = ChartInfo.functions.getChartDetails(chartnumber);
        var chartType = JSON.parse(chartdetails).chartType;
        if (reportId === undefined || reportId == "") {
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Information');
            $('.birdmessage-info').empty().text(getMessage('BirdInformation01'));
        }
        else {
            var that = this;
            let requestHeaders = {
                method: "get",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            SendRequest({
                url: "getalertsinfo",
                queryString: "?alertId=&reportId=" + reportId + "&chartnumber=" + chartnumber + "&chartType=" + chartType,
                sucessFunction: response => {
                    //    ReactDOM.unmountComponentAtNode(document.getElementById("append_alertmodal"+chartnumber));
                    //      $("#append_alertmodal"+chartnumber).empty();
                    var filterid = window.localStorage.getItem("current")!=undefined ? atob(window.localStorage.getItem("current")):"";
					if(filterid!=undefined && filterid!=""){
						response = $.grep(response, function (e) { return e.filterid == filterid });
					}
                    ReactDOM.render(<AlertsView
                        reportId={reportId}
                        chartnumber={chartnumber}
                        jsonArray={response}
                        features={that.props.allfeatures}
                        chartType={chartType}
                        facttables={that.props.facttables}
                        multifact={that.props.multifact}
                        multifactentities={that.props.multifactentities}
                    ></AlertsView>, document.getElementById("append_alertmodal" + chartnumber));
                    ChartInfo.functions.setAlerts(response);
                },
                rejectFunction: () => { },
                headers: requestHeaders
            });
        }
    }
    showcustomFieldDialogEvee(msg, data) {
        this.showcustomFieldDialog(data.chartnumber, data.dimensions, data.isEdit, data.columnName);
    }
    showcustomFieldDialog(chartnumber, dimensions, isEdit, columnName) {
        var that = this;
        var customParameterFields = ChartInfo.functions.getCustomParameter();
        var today = new Date();
        var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
        // dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
        // $(".custommeasurediv" + chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
        var customFieldModal = new CustomFieldModal({
            dynamicdiv: dynamicdiv,
            dimensions: dimensions,
            isEdit: isEdit,
            columnName: columnName,
            chartnumber: chartnumber,
            reportId: that.props.reportId,
            datamodelId: that.props.datamodelId,
            customparameters: customParameterFields,
            multifact: that.props.multifact,
            facttables: that.props.facttables,
            multifactentities: that.props.multifactentities,
        });
        //  $(".custommeasurediv" + chartnumber).append($("#"+dynamicdiv));
        if (isEdit) {
            customFieldModal.addData();
        }
        try {
            $('.modal-anim-left').prop('class', 'modal fade').addClass("left");
            //    $(".custommeasurediv" + chartnumber + " .createcustommeasure").modal("show");
            ModalProperties.resize();
            customFieldModal.textAreaAutoComplete();
        } catch (e) {
            console.log(e);
        }
    }
    drilldownsettingsEve() {
        var that = this;
        var dimensionArray = [];
        var columns = viewcollection.getDimensions();
        if (this.props.reportId == null || this.props.reportId == undefined || this.props.reportId == "") {
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Information');
            $('.birdmessage-info').empty().text(getMessage('BirdInformation01'));
            return;
        } else {
            _.each(columns, function (e, i) {
                var obj = {};
                obj["aggregation"] = columns[i].aggregation;
                obj["columnDisplayName"] = columns[i].columnDisplayName;
                obj["columnName"] = columns[i].columnName;
                obj["id"] = columns[i].id;
                obj["keycolumn"] = columns[i].keycolumn;
                obj["tableDisplayName"] = columns[i].tableDisplayName;
                obj["tableDisplayType"] = columns[i].tableDisplayType;
                obj["tableName"] = columns[i].tableName;
                obj["columnssrename"] = columns[i].columnssrename;
                obj["unique"] = columns[i].unique;
                obj["visible"] = columns[i].unique;
                obj["tableAliasName"] = columns[i].tableAliasName;
                obj["dateFormat"] = columns[i].dateFormat;
                obj["orginalColumnName"] = columns[i].orginalColumnName;
                obj["priorityColumn"] = columns[i].priorityColumn;
                dimensionArray.push(obj);
            });
            var drilldownallsettingsnew = new DrilldownAllSettingsview({
                dimensions: dimensionArray,
                reportId: that.props.reportId,
                multifact: that.props.multifact,
                facttables: that.props.facttables,
                multifactentities: that.props.multifactentities,
            });
        }
    }
    drillthroughEve(msg, data) {
        this.drillthrough(data);
    }
    drillthrough(e) {
        var top = e.pageY;
        var left = e.pageX;
        var $this = e.currentTarget;
        var that = this;
        var currentChartNumber = $($this).attr('data-num');
        var drillThroughOptionsView = new DrillThroughOptionsView({
            currentChartNumber: currentChartNumber,
            postop: top,
            posleft: left,
            multifact: that.props.multifact,
            facttables: that.props.facttables,
            multifactentities: that.props.multifactentities,
        });
    }
}

export default ReportGridHtml;


