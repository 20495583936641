import React from 'react';
import { lang } from "./js/helpers/utils/languages/language";
const $ = window.$;

export default class BusinessModelsDropdownListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  componentDidMount() {
    // Collapse accordion every time dropdown is shown
    $('.dropdown-accordion').on('show.bs.dropdown', function (event) {
      var accordion = $(this).find($(this).data('accordion'));
      accordion.find('.panel-collapse.in').collapse('hide');
    });

    // Prevent dropdown to be closed when we click on an accordion link
    $('.dropdown-accordion').on('click', 'a[data-toggle="collapse"]', function (event) {
      event.stopPropagation();
      event.preventDefault();
      $($(this).data('parent')).find('.panel-collapse.in').collapse('hide');
      $($(this).attr('href')).collapse('show');
    });
  }
  render() {
    return (
      //<div className="dropdown dropdown-accordion" data-accordion="#accordion">
            <div className="panel-group" id="accordion" style={{ marginBottom: "0px" }}>
              <div className="panel panel-default" style={{ border: "none" }}>
                  <a className="accordion-toggle" href={`#newdataitem${this.props.DMID}`} data-toggle="collapse" data-parent="#accordion" style={{ textDecoration: "none" }}><i className="fa fa-plus"></i></a>
                </div>
                <div className="connection-center importtemplatefilecontainer" datamodel-id={this.props.DMID}></div>
                <div className="panel-collapse collapse" id={`newdataitem${this.props.DMID}`}>
                  <ul>

                   {this.props.modelautoviz?
                      <li><button className="btn btn-data-item-actions datamodelcreateautoviz" onClick={this.props.createAutoViz} datamodel-id={this.props.DMID} is_shared={this.props.is_shared.toString()} title={lang["models.autoviz"]} role="button" datamodel-title={this.props.DMTitle} ><i className="fa fa-magic marg-righ-10"></i>{lang["models.autoviz"]}</button></li>
                      :null}
                      {this.props.smartSearch?
                      <li><button className="btn btn-data-item-actions datamodelsmartsearch" onClick={this.props.navigatetosearch} datamodel-id={this.props.DMID} is_shared={this.props.is_shared.toString()} title={lang["search.intelligent"]} role="button" datamodel-title={this.props.DMTitle} ><i className="fa fa-search marg-righ-10"></i>{lang["search.intelligent"]}</button></li>
                      :null}
                    {this.props.modelstoryboard ?
                      <li><button className="btn btn-data-item-actions datamodelcreatestoryboard" onClick={this.props.createstoryboard} datamodel-id={this.props.DMID} is_shared={this.props.is_shared.toString()} title={lang["models.createstoryboard"]} role="button"><i className="fa fa-th-large marg-righ-10"></i>{lang["models.storyboard"]}</button></li>
                      : null}
                    {this.props.modelreport ?
                      <li><button className="btn btn-data-item-actions datamodelcreatereport" datamodel-id={this.props.DMID} is_shared={this.props.is_shared.toString()} title={lang["models.createreport"]} role="button" onClick={this.props.createreport}><i className="fa fa-th-large whitebox marg-righ-10"></i>{lang["models.report"]}</button></li>
                      : null}
                    {this.props.modelsmartinsights ?
                      <li><button className="btn btn-data-item-actions datamodelcreatesmartinsights" onClick={this.props.createsmartinsights} datamodel-id={this.props.DMID} is_shared={this.props.is_shared.toString()} title={lang["models.createsmartinsight"]} role="button"><i className="fa fa-smartinsights small-14 marg-righ-10"></i>{lang["models.smartinsight"]}</button></li>
                      : null}
                    {this.props.modelmlreport ?
                      <li><button className="btn btn-data-item-actions datamodelcreatemlreport" onClick={this.props.createMLReport} datamodel-id={this.props.DMID} is_shared={this.props.is_shared.toString()} title={lang["models.mlreport"]}><i className="fa fa-mlreport marg-righ-10"></i>{lang["models.mlreport"]}</button></li>
                      : null}
                       {this.props.modelimporttemplate ?
                      <li><button className="btn btn-data-item-actions importtemplatefile" onClick={this.props.importtemplatefile} title={lang["models.importtemplate"]} id="newreportimportfile" datamodel-id={this.props.DMID} is_shared={this.props.is_shared.toString()}><i className="fa fa-share-square-o fa-rotate-90 marg-righ-10"></i>{lang["models.importtemplate"]}</button></li>
                      : null}
                  </ul>
                </div>
              </div>
           // </div>
     // </div>
    );
  }
}