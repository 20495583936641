import React from 'react';
import ReactDOM from 'react-dom';
import { lang } from "../../js/helpers/utils/languages/language";
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import _ from 'underscore';
import SummaryTableColumnDropdownHtml from './summarytablecolumndropdown.jsx';
const $=window.$;

export default class SummarytableColumnDropdown extends React.Component{
    constructor(props){
        super(props);
        this.rowvalues = props.rowvalues;
        this.chartnumber = props.chartnumber;
        this.multifact = (props.multifact == 'true');
        this.multifactentities = props.multifact == 'true'?JSON.parse(props.multifactentities):props.multifactentities;
    }
    render(){
        $(".summarytable-columnsList").append("<li class='xs-list-item'><input class='form-control form-control-pivot-rows search-image' placeholder="+lang['storyboard.search']+"  id='searchinput' type=text'></li>");
        var customhierarchy = ChartInfo.functions.getcustomhierarchy();
        var customFields = JSON.parse(sessionStorage.getItem("customFields"));
        var facttables= this.props.facttables;
        var convertedTypes = {};
        _.each(JSON.parse(sessionStorage.getItem("convertedtypes")),function(list){
            convertedTypes[list["columnName"]]=list["dataType"]
        });
        var tempRows =  _.clone(this.rowvalues);
        if(this.multifact){
            var allowedEntities =  ChartInfo.functions.getAllowedEntiesForSummaryTable(this.multifactentities, customFields,facttables,"textdrp");
            if(allowedEntities!= undefined && allowedEntities.length>0){
                tempRows = [];
                var tesmpCustomFields=[];
                var tempcustomhierarchy = [];
                _.each(this.rowvalues, function(column){ 
                    if(column!=undefined){
                        var tempColumn = _.clone(column);
                        var columnName = column.columnName;
                        var tablename = columnName.split(".")[0];
                        if(allowedEntities.indexOf(tablename) >=0){
                            tempRows.push(tempColumn);
                        }
                    }
                });
                
                if(customFields!=null && customFields!=undefined){
                    _.each(customFields ,function(cfObj){
                        var disable=false;
                        _.each(cfObj.usedFields ,function(hierarchy){
                            var tablename = hierarchy.split(".")[0];
                            if(allowedEntities.indexOf(tablename) <0){
                                disable=true;
                            }
                        });
                        if(!disable){
                            tesmpCustomFields.push(cfObj);
                        }
                    });
                    customFields=tesmpCustomFields;
                }
                
                if(customhierarchy!=undefined){
                    _.each(customhierarchy,function(customhierval){
                        var disable=false; 
                        var hasMatch = false;
                        _.each(customhierval.hierarchelist,function(hierarchyVal){
                            if(customFields!=null && customFields!=undefined){
                                var customFieldObject = $.grep(customFields, function(e){ return e.name ==hierarchyVal.columnName});
                                if(customFieldObject!=undefined && customFieldObject.length>0){
                                    _.each(customFieldObject[0].usedFields ,function(customFieldVal){
                                        var tablename = customFieldVal.split(".")[0];
                                        if(allowedEntities.indexOf(tablename) <0){
                                            disable=true;
                                        }
                                    });
                                    hasMatch = true
                                }
                            }
                            if(!hasMatch){
                                var tablename = hierarchyVal.columnName.split(".")[0];
                                if(allowedEntities.indexOf(tablename) <0){
                                    disable=true;
                                }
                            }
                        });
                        if(!disable){
                            tempcustomhierarchy.push(customhierval);
                        }
                    });
                    customhierarchy = tempcustomhierarchy
                }
                
            }
        }
        tempRows.sort(function(a, b){
            var x = a.columnDisplayName.toLowerCase();
            var y = b.columnDisplayName.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0 ;
            });
            if(customFields != undefined && customFields.length != 0){
            customFields.sort(function(a, b){
            var x = a.name.toLowerCase();
            var y = b.name.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0 ;
            });
            }
            if(customhierarchy != undefined && customhierarchy.length != 0){
            customhierarchy.sort(function(a, b){
            var x = a.name.toLowerCase();
            var y = b.name.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0 ;
            });
            }
        var today = new Date();
        var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
        $('.summarytable-columnsList').append("<div className='" + dynamicdiv + "hide' id='" + dynamicdiv + "'></div>");

        ReactDOM.render(
            <SummaryTableColumnDropdownHtml addRowValue={this.props.addRowValue} chartnumber={this.chartnumber} rowvalues={tempRows} customFields={customFields} convertedTypes={convertedTypes} customHierarchy={customhierarchy} />,document.getElementById(dynamicdiv)
        );
        return(<></>);        
    }

}