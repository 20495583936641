import React from 'react';
import SendRequest from '../../../SendRequest';
import {getMessage} from '../../../js/helpers/utils/BirdMessage';
const $ = window.$;
export default class SaveLdapView extends React.Component{
    constructor(props){
        super(props);
        this.saveLdap();
    }
    saveLdap(){
        var ldapFormData = $(".ldapform").serializeArray();
        var ldapModelData = {};
        $.each(ldapFormData, function(index, object) {
            ldapModelData[object.name] = object.value;
        });
        let requestHeaders = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        var reqdata = new FormData();
        reqdata.set("ldapName", ldapModelData.ldapname);
        reqdata.set("ldapUrl", ldapModelData.ldapurl);
        reqdata.set("ldapBindUser", ldapModelData.ldapbinduser);
		reqdata.set("ldapBindPassword", ldapModelData.ldapbindpassword);
        reqdata.set("ldapSearchBase", ldapModelData.ldapsearchbase);
        reqdata.set("ldapQuery", ldapModelData.ldapquery);
		reqdata.set("ldapUserIdAttribute", ldapModelData.ldapUserIdAttribute);
		reqdata.set("status", ldapModelData.status);
        SendRequest({
            url: "saveldapconfiguration",
            body: reqdata,
            sucessFunction: (response) => {
               this.render(response);
               $('.getldapusersfromad').removeAttr('disabled');
            },
            rejectFunction:() => { },
            headers: requestHeaders

        });
}
render(response) {
if(response.hasOwnProperty('error')){
    $(".spinner").hide();
    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
    $('.birdmessagebuttons').removeClass('show');
    $('.birdmessagebuttons').addClass('hide');
    $('.birdmessage-center').addClass("message-top-in");
    $('.details').removeClass('hide');
    $('.birdmessage h2').empty().append('Error : ');
    $('.birdmessage h2').append('<span class="errorcode">');
    $('.birdmessage .errorcode').empty().append(response.errorCode);
    $('.birdmessage-info').empty().text(response.error);
    $('.details').addClass('show');
    $('.messagedetails xmp').empty().append(response.errorTrace)
    setTimeout(function() {
            $('.birderror').remove();	
        }, 3000);
}
else {
    $('.ldapform input[name="ldapid"]').attr('value',response.result)
    $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
            $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Success');
            $('.birdmessage-info').empty().text(getMessage('BirdSuccess18'));
    setTimeout(function() {
            $('.birdsuccess').remove();	
        }, 3000);
}
return(<div></div>);
    }
}