import React from 'react';
import { lang } from "../js/helpers/utils/languages/language";
const $=window.$;

export default class DatasourceConnectionHtml extends React.Component {
    closeconnectionpop=()=>{
        $(".connection-center").removeClass("connection-in");
    }
    componentDidMount(){
       if(this.props.schemaName.toLowerCase() === "servicenow") {
        if(this.props.connectionjson.tablesType !=undefined){
        if(this.props.connectionjson.tablesType.toLowerCase()== "custom"){
            $("#Custom").val(this.props.connectionjson.tablesType).prop("checked",true);
            $(".tablestype").click();
        }
    }
       }
       $(".spinner").hide();
    }
    tablestype=(e)=>{
        var $this = e.currentTarget;
			var tablestype = $($this).val();
			if(tablestype =="Standard"){
				$('.servicetablename').addClass('hide');
				$('.tabname').hide();
			}else if(tablestype =="Custom"){
                $("#Custom").prop("checked",true);
				$('.servicetablename').removeClass('hide');
				$('.tabname').show();
			}
    }
    render() {
        var eventhubclassName = "";
        if (this.props.schemaName.toLowerCase() == "eventhub" ||
            this.props.schemaName.toLowerCase() == "websocket") {
            eventhubclassName = "eventhubwizard"
        }
        let connectiondetails = {};
        connectiondetails.servers = this.props.connectiondata.servers === undefined ? "" : this.props.connectiondata.servers;
        return (
            <>
                {/* <!-- datasourceconnectionview.js --> */}
                <div className="modal-header boc-modal-hf-padding">
                    <h4 className="modal-title">
                        <i className="fa fa-link marg-righ-10"></i>{lang["datahub.filestitle"]}
                </h4>
                </div>
                <div className="modal-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xs-12">
                                {/* <!-- <h3>Connect MySQL</h3> --> */}
                                <div className="databaseconnectioncontainer">
                                    <form className="form-horizontal marg-bot-20 kafkaform">
                                        <div className="form-group form-group-sm">
                                            <label htmlFor="" className="col-sm-4 control-label ">
                                                {(this.props.schemaName.toLowerCase() === "mysql") ? (
                                                    <img src={require('../images/birdimages/mysql.png')} alt="" />
                                                ) : null}
                                                {(this.props.schemaName.toLowerCase() === "sqlserver") ? (
                                                    <img src={require('../images/birdimages/sqlserver.png')} alt="" />
                                                ) : null}
                                                {(this.props.schemaName.toLowerCase() === "oracle") ? (
                                                    <img src={require('../images/birdimages/oracle.png')} alt="" />
                                                ) : null}
                                                {(this.props.schemaName.toLowerCase() === "postgres") ? (
                                                    <img src={require('../images/birdimages/postgresql.png')} alt="" />
                                                ) : null}
                                                {(this.props.schemaName.toLowerCase() === "progress") ? (
                                                    <img src={require('../images/birdimages/progressopenedge.png')} alt="" />
                                                ) : null}
                                                {(this.props.schemaName.toLowerCase() === "db2") ? (
                                                    <img src={require('../images/birdimages/db2.png')} alt="" />
                                                ) : null}
                                                {(this.props.schemaName.toLowerCase() === "mongodb") ? (
                                                    <img src={require('../images/birdimages/mongodb.png')} alt="" />
                                                ) : null}
                                                {(this.props.schemaName.toLowerCase() === "hadoop") ? (
                                                    <img src={require('../images/birdimages/hadoop.png')} alt="" />
                                                ) : null}
                                                {(this.props.schemaName.toLowerCase() === "custom-query") ? (
                                                    <img src={require('../images/birdimages/custom-query.png')} alt="" />
                                                ) : null}
                                                {(this.props.schemaName.toLowerCase() === "amazon") ? (
                                                    <img src={require('../images/birdimages/webservices.png')} alt="" />
                                                ) : null}
                                                {(this.props.schemaName.toLowerCase() === "fuse") ? (
                                                    <img src={require('../images/birdimages/birdfuse.png')} alt="" />
                                                ) : null}
                                                {(this.props.schemaName.toLowerCase() === "csv") ? (
                                                    <img src={require('../images/birdimages/csv.png')} alt="" />
                                                ) : null}
                                                {(this.props.schemaName.toLowerCase() === "pdf") ? (
                                                    <img src={require('../images/birdimages/pdf.png')} alt="" />
                                                ) : null}
                                                {(this.props.schemaName.toLowerCase() === "customstoryboard") ? (
                                                    <img src={require('../images/birdimages/customstoryboard.png')} alt="" />
                                                ) : null}
                                                {(this.props.schemaName.toLowerCase() === "xls") ? (
                                                    <img src={require('../images/birdimages/xls.png')} alt="" />
                                                ) : null}
                                                {(this.props.schemaName.toLowerCase() === "cassandra") ? (
                                                    <img src={require('../images/birdimages/cassandra.png')} alt="" />
                                                ) : null}
                                                {(this.props.schemaName.toLowerCase() === "elasticsearch") ? (
                                                    <img src={require('../images/birdimages/elasticsearch.png')} alt="" />
                                                ) : null}
                                                {(this.props.schemaName.toLowerCase() === "webdataconnector") ? (
                                                    <img src={require('../images/birdimages/webdataconnector.png')} alt="" />
                                                ) : null}
                                                {(this.props.schemaName.toLowerCase() === "hive") ? (
                                                    <img src={require('../images/birdimages/apachehive.png')} alt="" />
                                                ) : null}
                                                {(this.props.schemaName.toLowerCase() === "zendesk") ? (
                                                    <img src={require('../images/birdimages/zendesk.png')} alt="" />
                                                ) : null}
                                                {(this.props.schemaName.toLowerCase() === "rabbitmq") ? (
                                                    <img src={require('../images/birdimages/rabbitmq.png')} alt="" />
                                                ) : null}
                                                {(this.props.schemaName.toLowerCase() === "eventhub") ? (
                                                    <img src={require('../images/birdimages/eventhub.png')} alt="" />
                                                ) : null}
                                                {(this.props.schemaName.toLowerCase() === "json") ? (
                                                    <img src={require('../images/birdimages/json.png')} alt="" />
                                                ) : null}
                                                {(this.props.schemaName.toLowerCase() === "log") ? (
                                                    <img src={require('../images/birdimages/log.png')} alt="" />
                                                ) : null}
                                                {(this.props.schemaName.toLowerCase() === "kafka") ? (
                                                    <img src={require('../images/birdimages/kafka.png')} alt="" />
                                                ) : null}
                                                {(this.props.schemaName.toLowerCase() === "websocket") ? (
                                                    <img src={require('../images/birdimages/websocket.png')} alt="" />
                                                ) : null}
                                                {(this.props.schemaName.toLowerCase() === "nlp") ? (
                                                    <img src={require('../images/birdimages/nlpsearch.png')} alt="" />
                                                ) : null}
                                                {(this.props.schemaName.toLowerCase() === "kudu") ? (
                                                    <img src={require('../images/birdimages/kudu.png')} alt="" />
                                                ) : null}
                                                {(this.props.schemaName.toLowerCase() === "birdinmemory") ? (
                                                    <img src={require('../images/birdimages/birdinmemory.png')} alt="" />
                                                ) : null}
                                                {(this.props.schemaName.toLowerCase() === "teradata") ? (
                                                    <img src={require('../images/birdimages/teradata.png')} alt="" />
                                                ) : null}
                                                 {(this.props.schemaName.toLowerCase() === "servicenow") ? (
                                                    <img src={require('../images/birdimages/servicenow.png')} alt="" />
                                                ) : null}
                                                {(this.props.schemaName.toLowerCase() === "twitter") ? (
                                                    <img src={require('../images/birdimages/twitter.png')} alt="" />
                                                ) : null}
                                                {(this.props.schemaName.toLowerCase() === "genericjdbc") ? (
                                                    <img src={require('../images/birdimages/genericjdbc.png')} alt="" />
                                                ) : null}
                                                  {(this.props.schemaName.toLowerCase() === "presto") ? (
                                                    <img src={require('../images/birdimages/presto.png')} alt="" />
                                                ) : null}
                                                  {(this.props.schemaName.toLowerCase() === "neo4j") ? (
                                                    <img src={require('../images/birdimages/neo4j.png')} alt="" />
                                                ) : null}
                                                  {(this.props.schemaName.toLowerCase() === "oraclecloud") ? (
                                                    <img src={require('../images/birdimages/oraclecloud.png')} alt="" />
                                                ) : null}
                                                  {(this.props.schemaName.toLowerCase() === "hsqldb") ? (
                                                    <img src={require('../images/birdimages/hsqldb.png')} alt="" />
                                                ) : null}
                                                  {(this.props.schemaName.toLowerCase() === "snowflake") ? (
                                                    <img src={require('../images/birdimages/snowflake.png')} alt="" />
                                                ) : null}
                                                 {(this.props.schemaName.toLowerCase() === "cache") ? (
                                                    <img src={require('../images/birdimages/cache.png')} alt="" />
                                                ) : null}
                                                 {(this.props.schemaName.toLowerCase() === "sybase") ? (
                                                    <img src={require('../images/birdimages/sybase.png')} alt="" />
                                                ) : null}
                                                {(this.props.schemaName.toLowerCase() === "vertica") ? (
                                                    <img src={require('../images/birdimages/vertica.png')} alt="" />
                                                ) : null}
                                                {(this.props.schemaName.toLowerCase() === "salesforce") ? (
                                                    <img src={require('../images/birdimages/salesforce.png')} alt="" />
                                                ) : null}
                                                {(this.props.schemaName.toLowerCase() === "quickbooks") ? (
                                                    <img src={require('../images/birdimages/quickbooks.png')} alt="" />
                                                ) : null}
                                                {(this.props.schemaName.toLowerCase() === "jira") ? (
                                                    <img src={require('../images/birdimages/jira.png')} alt="" />
                                                ) : null}
                                                {(this.props.schemaName.toLowerCase() === "hana") ? (
                                                    <img src={require('../images/birdimages/hana.png')} alt="" />
                                                ) : null}
                                                {(this.props.schemaName.toLowerCase() === "postgresav") ? (
                                                    <img src={require('../images/birdimages/postgresav.png')} alt="" />
                                                ) : null}
                                                {(this.props.schemaName.toLowerCase() === "peoplesoft") ? (
                                                    <img src={require('../images/birdimages/peoplesoft.png')} alt="" />
                                                ) : null}
                                            </label>
                                            <div className="col-sm-6">
                                                <h3>{this.props.schemaName} {lang["datahub.deatils"]}</h3>
                                            </div>
                                        </div>
                                        {
                                            (this.props.schemaName.toLowerCase() === "kafka" ? (
                                                <>
                                                    <label htmlFor="" className="col-sm-4 control-label">{lang["datahub.cnctnname"]}</label>
                                                    <div className="form-group form-group-sm required">
                                                        <div className="col-sm-7">
                                                            <input type="text" defaultValue={this.props.connectionjson.connectionname === undefined ? '' : this.props.connectionjson.connectionname} className="form-control connectionname" placeholder="" />
                                                            <span id="connectionname" className="help-block hide">
                                                                <span></span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="appendnewaddress">
                                                        {connectiondetails.servers.split(",").map((value, index) => {
                                                            var value = value.split(":");
                                                            if (index === 0) {
                                                                return (
                                                                    <div className="form-group form-group-sm required">
                                                                        <label htmlFor="" className="col-sm-4 control-label">{lang["datahub.Ipadd"]}</label>
                                                                        <div className="col-sm-3">
                                                                            <input type="text" defaultValue={value[0] !== undefined ? value[0] : ''} className="form-control url" placeholder="" />
                                                                            <span id="url" className="help-block hide">
                                                                                <span></span>
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-sm-5 nopadding">
                                                                            <label htmlFor="" className="col-sm-1 control-label">{lang["datahub.port"]}</label>
                                                                            <div className="col-sm-8 ">
                                                                                <input type="number" defaultValue={value[1] !== undefined ? value[1] : ''} className="form-control port" placeholder="" />
                                                                                <span id="port" className="help-block hide">
                                                                                    <span></span>
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-sm-1 nopadding">
                                                                                <button className="pull-left btn btn-bird addaddress_kafka" onClick={this.props.addnewaddress}>
                                                                                    <i className="fa fa-plus-circle "></i> {lang["datahub.add"]}
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            } else {
                                                                return (
                                                                    <div className="form-group form-group-sm required">
                                                                        <label htmlFor="" className="col-sm-4 control-label">{lang["datahub.Ipadd"]}</label>
                                                                        <div className="col-sm-3">
                                                                            <input type="text" defaultValue={value[0] !== undefined ? value[0] : ''} className="form-control url" placeholder="" />
                                                                            <span id="url" className="help-block hide"><span></span></span>
                                                                        </div>
                                                                        <div className="col-sm-5 nopadding">
                                                                            <label for="" className="col-sm-1 control-label">{lang["datahub.port"]}</label>
                                                                            <div className="col-sm-8 ">
                                                                                <input type="number" defaultValue={value[1] != undefined ? value[1] : ''} className="form-control port" placeholder="" />
                                                                                <span id="port" className="help-block hide"><span></span></span>
                                                                            </div>
                                                                            <div className="col-sm-1 nopadding">
                                                                                <button className="pull-left btn btn-bird btn-sm removeaddress_kafka" onClick={this.props.removeaddresskafka}>
                                                                                    <i className="fa fa-minus-circle "></i> {lang["datahub.remove"]}
                                                            </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        })}
                                                    </div>
                                                    <div className="form-group form-group-sm required">
                                                        <label for="" className="col-sm-4 control-label">{lang["datahub.grpid"]}</label>
                                                        <div className="col-sm-6">
                                                            <input type="text" defaultValue={this.props.connectionjson.group == undefined ? '' : this.props.connectiondata.group} className="form-control groupid" placeholder="" />
                                                            <span id="groupid" className="help-block hide"><span></span></span>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                    <>
                                                        {(this.props.schemaName.toLowerCase() === "sqlserver") ? (
                                                            <div className="form-group form-group-sm">
                                                                <label for="windowsauth" className="control-label col-sm-8">
                                                                    <div className="col-sm-8">
                                                                    {lang["datahub.windauth"]}
                                                                    <input type="checkbox" className="windowsauth" id="windowsauth" value="windowsauth" />
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        ) : null}
                                                        {this.props.connectiondetails.map((obj, index) => {
                                                            if (obj.required) {
                                                                return (
                                                                    <div className={`form-group form-group-sm required ${obj.hide ? 'hide' : ''}`}>
                                                                        <label htmlFor="" className={`col-sm-4 control-label ${obj.hide ? 'hide' : ''}`}>{obj.name}</label>
                                                                        <div className="col-sm-6">
                                                                            <input type={obj.displaytype} defaultValue={this.props.connectionjson[obj.id]} className={`form-control ${obj.id}`} placeholder="" />
                                                                            <span id={obj.id} className="help-block hide"><span></span></span>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            } else {
                                                                return (
                                                                    <div className="form-group form-group-sm">
                                                                        <label htmlFor="" className="col-sm-4 control-label">{obj.name}</label>
                                                                        <div className="col-sm-6">
                                                                            <input type={obj.displaytype} defaultValue={this.props.connectionjson[obj.id]} className={`form-control ${obj.id} ${obj.disable ? 'disabled' : ''}`} placeholder="" />
                                                                            <span id={obj.id} className="help-block hide"></span>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        })}
                                                         {(this.props.schemaName.toLowerCase() === "servicenow")?(
                                                             <>
												<div class="form-group form-group-sm">
													<label for="tablestype" class="control-label col-sm-4">Tables Type</label>
													<div class="col-sm-6">  
														<label>
															<input type="radio" class="tablestype" id="Standard" name="tablestype" value="Standard" onClick = {this.tablestype} defaultChecked/> Standard
														</label>
														<label>
															<input type="radio" class="tablestype" id="Custom" name="tablestype" onClick = {this.tablestype} value="Custom"/> Custom 
														</label>
													</div>
												</div>
												<div class="form-group form-group-sm servicetablename hide">
													<label for="" class="col-sm-4 control-label ">Table Name</label>
													<div class="col-sm-6">
														<input type="text" defaultValue={(this.props.connectionjson.customTableName==undefined || this.props.connectionjson.customTableName=="")?'': this.props.connectionjson.customTableName} class="form-control tabname" placeholder=""/>
														<span id="servicetablename" class="help-block hide"><span></span></span>
													</div>
												</div>
                                                </>
												):null}
                                                    </>
                                                ))
                                        }
                                        
                                        {/* <!-- <div className="form-group form-group-sm">
													<div className="col-sm-offset-4 col-sm-6">
														<button className="btn btn-bird pull-left testconnection" id=""><i className="fa fa-list-alt"></i> Test Connection</button>
													</div>
												</div> --> */}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div></div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-bird pull-left testconnection" onClick={this.props.testconnection}>
                        <i className="fa fa-play"></i> {lang["datahub.testcnctn"]}
                </button>
                    <button type="button" className="btn btn-bird" id="closeconnectionpop" onClick={this.closeconnectionpop}>
                        <i className="fa fa-times"></i> {lang["datahub.canclbtn"]}
                </button>
                    <button type="button" className="btn btn-bird active dbsavenewconnection" id="savenewconnection" onClick={this.props.dbsavenewconnection}>
                        <i className="fa fa-floppy-o"></i> {lang["datahub.savebtn"]}
                </button>
                </div>
                {/* <!-- /datasourceconnectionview.js --> */}
            </>);
    }
}