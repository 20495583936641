import React from 'react';
import SendRequest from '../../SendRequest';
import { getMessage } from '../../js/helpers/utils/BirdMessage';
const $ = window.$;

export default class EditAccountTemplate extends React.Component{
    constructor(props){
        super(props);
    }
    isValid=(data)=>{
        var isValid = true;
        if($.trim($.trim(data.accountname))==""){
            $(".accountnameerror").removeClass('hidden');
            $(".accountnameerror").text("Account Name is required");
            isValid = false;
        }
        if($.trim($.trim(data.adminname))==""){
            $(".adminnameerror").removeClass('hidden');
            $(".adminnameerror").text("Admin Name is required");
            isValid = false;
        }
        if($.trim($.trim(data.Email))==""){
            $(".emailerror").removeClass('hidden');
            $(".emailerror").text("Email is required");
            isValid = false;
        }
        return isValid;
    }
    updateAccount=(e)=>{
        var serialized = $('form').serializeArray();
        var email=$('#accountemail').val();
        var s = '';
        var data = {};
        for (s in serialized) {
            data[serialized[s]['name']] = serialized[s]['value']
        }
        data['Email']=email;
        var result = JSON.stringify(data);
        var finaldata = JSON.parse(result); 
        var reqdata = new FormData();
        reqdata.set("clientId",finaldata.accountid);
        reqdata.set("clientName",finaldata.accountname);
        reqdata.set("clientDesc",finaldata.accountdesc);
        reqdata.set("partnerId",finaldata.accountparent);
        reqdata.set("userEmail",$('#accountemail').val());
        reqdata.set("status",finaldata.status);
        reqdata.set("oldAccountName",$('.editaccountform').attr('oldAccountName'));
        var isValid = this.isValid(finaldata);
        if(isValid) {
        let requestHeaders = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "updateClient",
            body: reqdata,
            sucessFunction: (response) => {
                if(response.hasOwnProperty('error')){
                    $(".spinner").hide();
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('hide');
                    $('.birdmessage h2').empty().append('Error : ');
                    $('.birdmessage h2').append('<span class="errorcode">');
                    $('.birdmessage .errorcode').empty().append(response.errorCode);
                    $('.birdmessage-info').empty().text(response.error);
                    $('.details').addClass('show');
                    $('.messagedetails xmp').empty().append(response.errorTrace)
                    setTimeout(function() {
                    $('.birdmessage-container .close').click();	
                    }, 3000);								
                }else if(response.status=="success"){
                $(".spinner").hide();
                $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Success');
                $('.birdmessage-info').empty().text(getMessage('BirdSuccess30'));
                setTimeout(function() {
                $('.birdmessage-container .close').click();	
                }, 3000);
                setTimeout(function() {
                  window.location.reload(false);
                },2000);
            }
              },
              rejectFunction: () => { },
              headers: requestHeaders
            })
        }
    }
    render(){
        var that = this ;
        var editaccountdetails = [];
        editaccountdetails.push(this.props.editaccountdetails);
        return(
            <div className="container-fluid nopadding">
	<div className="row in-back">
		<div className="max-height-30vh">
			<h2 className="boc-main-title">Edit Account</h2>
			<form className="form-horizontal col-xs-12 submitform">
				{editaccountdetails.map(function(accountdetails) {
				return <>
				<div className="form-group form-group-sm form-group">
					<label for="accountname" className="col-sm-3 control-label">Account
						Name</label>
					<div className="col-sm-6">
						<input type="hidden" className="form-control" id="accountname"
							name="accountid" placeholder=""
							value={accountdetails.clientId} /> <input type="name"
							className="form-control" id="accountname" name="accountname"
							placeholder="" value={accountdetails.clientName} /> <span
							className="help-block accountnameerror hidden"></span>
					</div>
				</div>
				<div className="form-group form-group-sm form-group">
					<label className="col-sm-3 control-label" for="accountdesc">Account
						Description</label>
					<div className="col-sm-6">
						<textarea className="form-control textarea" rows="1" id="accountdesc"
							name="accountdesc" placeholder="">{accountdetails.clientDesc}</textarea>
						<span className="help-block hidden"></span>
					</div>
				</div>
				
				<div className="form-group form-group-sm form-group">
					<label for="accountadmin" className="col-sm-3 control-label">Admin
						Name</label>
					<div className="col-sm-6">
						<input type="name" className="form-control" id="adminname"
							name="adminname" placeholder="" value={accountdetails.userFirstName} /> <span
							className="help-block adminnameerror hidden"></span>
					</div>
				</div>
				<div className="form-group form-group-sm form-group">
					<label for="accountemail" className="col-sm-3 control-label">Username
						(Email)</label>
					<div className="col-sm-6">

						<input type="email" className="form-control" id="accountemail"
							name="Email" placeholder=""
							value={accountdetails.clientAdminEmail} disabled="disabled"/> <span
							className="help-block emailerror hidden"></span>
					</div>
				</div>
				<div className="form-group form-group-sm">
					<label for="" className="col-sm-3 control-label">Status</label>
					<div className="col-sm-6">
						<div>
							{ accountdetails.status == "A" ? <><input type="radio" id="statusactive" name="status" value="A" checked="checked" />Active 
							<input type="radio" id="statusinactive" name="status" value="I" />Inactive </> : <><input type="radio" id="statusactive" name="status" value="A" className="statusactive" />Active
							 <input type="radio" id="statusinactive" name="status" value="I"	checked="checked" />Inactive </>}
						</div>
					</div>
				</div>
		
		<div className="form-group form-group-sm">
			<div className="col-sm-7">
				<button type="button"
					className="btn btn-bird pull-right editaccountform" oldAccountName={accountdetails.clientName} onClick={that.updateAccount}>Update</button>
			</div>
		</div>
		</>
		})
		}
		</form>
	</div>
</div>
</div>
        );
    }
}