import React, { Component } from "react";
import _ from 'underscore';
import moment from 'moment';
import ReactDOM from "react-dom";
import DatahubSyncLogBookTemplate from "./DatahubSyncLogBookTemplate.jsx";
import DatahubSyncLogBookHistory from './DatahubSyncLogBookHistory'
import { lang } from "../js/helpers/utils/languages/language";
import SendRequest from "../SendRequest";
import "twbs-pagination/jquery.twbsPagination";
import "bootstrap-table/dist/bootstrap-table";
import "bootstrap-table/dist/bootstrap-table.min.css";
import DataHubSyncLogBookBootstrapTemplate from "./DatahubSyncLogBookBootstrapTemplate.jsx";
const $ = window.$
export default class DatahubSyncLogBook extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        var that = this;
        $("body").removeClass("datamainpage")
        $("body").addClass("synclogpage");
        setTimeout(function () {
            that.getSyncLogs(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
        }, 100);
        setTimeout(function () {
            that.refreshTable();
        }, 200);
        return (<DatahubSyncLogBookTemplate {...this.props} datahubsynclog={this.datahubsynclog} workspacesynclog={this.workspacesynclog} features={this.props.features} />);
    }
    refreshTable(e) {
        this.getSyncLogs(e, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
    }
    getSyncLogs(e, page, startDate, endDate, search, pageDisplay, order, colName) {
        if (e != undefined) {
            e.stopPropagation();
        }
        if (startDate == undefined) {
            startDate = moment().subtract(29, 'days');
        }
        if (endDate == undefined) {
            endDate = moment();
        }
        $(".spinner").show();
        var that = this;
        var index = 0;
        var noOfRecords = $(".synclogrowlimtbutton .page-size").text() == "" ? 25 : $(".synclogrowlimtbutton .page-size").text();
        var totalRecords = 0;

        if (page != undefined && page > 1) {
            index = (page - 1) * noOfRecords;
        }
        if (order == undefined) {
            order = 'asc';
        }
        if (colName == undefined) {
            colName = '';
        }
        this.dataFieldValue = colName;
        this.sortingOrder = order;
        this.startDate = startDate;
        this.endDate = endDate;
        this.page = page;
        var that = this
        let requestHeaders1 = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        var formdata = new FormData();
        formdata.set('index', index);
        formdata.set('noOfRecords', noOfRecords)
        formdata.set('searchCriteria', search == undefined ? "" : search)
        formdata.set('order', order)
        formdata.set('colName', colName)
        SendRequest({
            url: "getDatahubSyncLogs",
            body: formdata,
            sucessFunction: (response) => {
                if (response.hasOwnProperty('error')) {
                    $(".spinner").hide();
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('hide');
                    $('.birdmessage h2').empty().append('Error : ');
                    $('.birdmessage h2').append('<span class="errorcode">');
                    $('.birdmessage .errorcode').empty().append(response.errorCode);
                    $('.birdmessage-info').empty().text(response.error);
                    $('.details').addClass('show').text("Details");
                    $('.messagedetails xmp').empty().append(response.errorTrace)
                } else {
                    $(".spinner").hide();
                    var headerlist = response["header"];
                    var dataTypeslist = response["dataTypes"];
                    var data = response["data"];
                    _.each(data, function (val, index) {
                        if (val.refresh_type != null && val.refresh_type != '') {
                            var refresh_type = JSON.parse(val.refresh_type);
                            if (refresh_type != undefined && refresh_type != null && refresh_type != '' && refresh_type.refreshType === 'fullrefresh') {
                                val.refresh_type = '<span title=' + val.refresh_type + '>' + lang["datahub.fullrefrsh"] + '</span>';
                            } else if (refresh_type != undefined && refresh_type != null && refresh_type != '' && refresh_type.refreshType === 'incrementalrefresh') {
                                val.refresh_type = '<span title=' + val.refresh_type + '>' + lang["datahub.incmntrfrsh"] + '</span>'
                            }
                        }
                        if (val.data_sync_details != null && val.data_sync_details != '') {
                            var data_sync_details = JSON.parse(val.data_sync_details);
                            if (data_sync_details != undefined && data_sync_details != null && data_sync_details != '') {
                                val.data_sync_details = "<span title='" + val.data_sync_details + "'>" + data_sync_details.cronDetails + "</span>"
                            }
                        }
                        if (val.last_sync_date != null && val.last_sync_date != undefined && val.last_sync_date != '') {
                            val.last_sync_date = "<span class='fa fa-clock-o' title='" + val.last_sync_date + "'>" + val.last_sync_date + "</span>"
                        }
                        val.sync_history = "<span class='fa fa-history hubsynchistory' title='History' sync_id='" + val.sync_id + "' connection_name='" + val.connection_name + "'></span>"
                    });
                    totalRecords = response["totalRecords"];
                    var keys = Object.keys(headerlist);

                    $(".synclogbookcontainer").empty();
                    let today = new Date();
                    let dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                    dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                    $(".synclogbookcontainer").append("<div  id='" + dynamicdiv + "'></div>");
                    ReactDOM.render(<DataHubSyncLogBookBootstrapTemplate keys={keys} headerlist={headerlist} search={search == undefined ? "" : search} dataTypeslist={dataTypeslist} />, document.getElementById(dynamicdiv))
                    $(".synclogrowlimtul li").removeClass('active');
                    $(".synclogrowlimtul li[value=" + noOfRecords + "]").addClass('active');
                    $(".synclogrowlimtbutton .page-size").text(noOfRecords);

                    //load Data
                    var $table = $(".synclog table");
                    $table.bootstrapTable({ height: 500 });
                    $(".synclog .table>thead>tr>th>.th-inner").addClass("sortable both")
                    $table.bootstrapTable('load', data);
                    $table.bootstrapTable('resetView');
                    $(window).on("resize", function () {
                        $table.bootstrapTable('resetView');
                        if (that.dataFieldValue != undefined && that.dataFieldValue != "") {
                            $(".synclog .table>thead>tr>th[data-field=" + that.dataFieldValue + "]>div.sortable").removeClass('asc').removeClass('desc').addClass(that.sortingOrder);
                        }
                    });
                    $('.spinner').hide();
                    //Show sorting in UI
                    if (that.dataFieldValue != undefined && that.dataFieldValue != "") {
                        $(".synclog .table>thead>tr>th[data-field=" + that.dataFieldValue + "]>div.sortable").removeClass('asc').removeClass('desc').addClass(that.sortingOrder);
                    }

                    var totalPages;
                    if (pageDisplay == undefined) {
                        pageDisplay = 25;
                    }
                    if (totalRecords > 25) {
                        totalPages = Math.ceil(totalRecords / pageDisplay);
                    } else {
                        totalPages = 1;
                    }
                    if ($('#pagination-view').data("twbs-pagination")) {
                        $('#pagination-view').twbsPagination('destroy');
                    }
                    var currentpage = page;
                    if (currentpage == undefined) {
                        currentpage = 1;
                    }
                    $('#pagination-view').twbsPagination({
                        totalPages: totalPages,
                        visiblePages: 5,
                        startPage: currentpage,
                        first: "«",
                        prev: "‹",
                        next: "›",
                        last: "»"
                    }).on('page', function (evt, page) {
                        var pageDisplayPage = $(".synclogrowlimtbutton .page-size").text();
                        that.getSyncLogs(e, page, startDate, endDate, that.searchValue, pageDisplayPage, that.sortingOrder, that.dataFieldValue);
                    });
                    $(".synclogrowlimtul li").click(function (e) {
                        that.rowlimitbutton(e)
                    })
                    $(".searchsynclogtable").keyup(function (e) {
                        that.search(e)
                    })
                    $(".synclog table>thead>tr>th").click(function (e) {
                        that.sorting(e)
                    });
                    $("#synclogrefreshbutton").click(function (e) {
                        that.refreshTable(e);
                    });
                    $("#synclogsettingsbutton").click(function (e) {
                        that.settingsPopup(e)
                    });
                    $(".hubsynchistory").click(function (e) {
                        that.hubsynchistory(e)
                    });
                    if (that.searchValue) {
                        $.fn.setCursorPosition = function (pos) {
                            this.each(function (index, elem) {
                                if (elem.setSelectionRange) {
                                    elem.setSelectionRange(pos, pos);
                                } else if (elem.createTextRange) {
                                    var range = elem.createTextRange();
                                    range.collapse(true);
                                    range.moveEnd('character', pos);
                                    range.moveStart('character', pos);
                                    range.select();
                                }
                            });
                            return this;
                        };
                        $('.searchsynclogtable').focus().setCursorPosition(that.searchValue.length);
                        $('.searchsynclogtable').get(0).scrollLeft = $('.searchsynclogtable').get(0).scrollWidth;
                    } else {
                        $('.searchsynclogtable').focus();
                    }
                    window.dispatchEvent(new Event('resize'));
                }
            },
            rejectFunction: () => { },
            headers: requestHeaders1,
        });

    }
    rowlimitbutton = (e) => {
        var $this = e.currentTarget;
        $(".synclogrowlimtul li").removeClass('active');
        var value = $($this).find('a').text();
        $($this).addClass('active');
        this.searchValue = $(".searchsynclogtable").val();
        $(".synclogrowlimtbutton .page-size").text(value);
        this.getSyncLogs(e, undefined, this.startDate, this.endDate, this.searchValue, value, this.sortingOrder, this.dataFieldValue);
    }
    search = (e) => {
        var that = this;
        /*Delay function for to wait until user stops typing*/
        var delay = (function () {
            var timer = 0;
            return function (callback, ms) {
                clearTimeout(timer);
                timer = setTimeout(callback, ms);
            };
        })();
        /*Delay function for to wait until user stops typing*/
        var asc = $(".usersgroupsaudit .table>thead>tr>th>div.asc").parent().attr("data-field");
        var desc = $(".usersgroupsaudit .table>thead>tr>th>div.desc").parent().attr("data-field");
        if (asc != undefined) {
            that.dataFieldValue = asc;
            that.sortingOrder = 'asc';
        } else if (desc != undefined) {
            that.dataFieldValue = desc;
            that.sortingOrder = 'desc';
        }
        $(".searchsynclogtable").keyup(function () {
            var search = $(e.currentTarget).val().toLowerCase();
            var pageDisplaySearch = $(".synclogrowlimtbutton .page-size").text();
            delay(function () {
                that.searchValue = $(".searchsynclogtable").val();
                that.getSyncLogs(e, undefined, that.startDate, that.endDate, that.searchValue, pageDisplaySearch, that.sortingOrder, that.dataFieldValue);
            }, 1000);
        });
        $(".searchsynclogtable").focus();
    }
    sorting = (e) => {
        var $this = e.currentTarget;
        $('.spinner').show();
        if ($($this).find(".th-inner").hasClass("asc")) {
            $(".synclog .table>thead>tr>th>.th-inner").removeClass("asc desc");
            $($this).find(".th-inner").removeClass("asc").addClass("desc");
        } else if ($($this).find(".th-inner").hasClass("desc")) {
            $(".synclog .table>thead>tr>th>.th-inner").removeClass("asc desc");
            $($this).find(".th-inner").removeClass("desc").addClass("asc");
        } else {
            $(".synclog .table>thead>tr>th>.th-inner").removeClass("asc desc");
            $($this).find(".th-inner").removeClass("asc").addClass("desc")
        }
        var that = this;
        var asc = $(".synclog .table>thead>tr>th>div.asc").parent().attr("data-field");
        var desc = $(".synclog .table>thead>tr>th>div.desc").parent().attr("data-field");
        if (asc != undefined) {
            that.dataFieldValue = asc;
            that.sortingOrder = 'asc';
        } else if (desc != undefined) {
            that.dataFieldValue = desc;
            that.sortingOrder = 'desc';
        }
        var pageDisplaySearch = $(".synclogrowlimtbutton .page-size").text();
        var searchValue = $(".searchsynclogtable").val();
        if (that.dataFieldValue != undefined && that.dataFieldValue == "target_name") {
            $('.spinner').hide();
            return;
        }
        that.getSyncLogs(e, that.page, that.startDate, that.endDate, searchValue, pageDisplaySearch, that.sortingOrder, that.dataFieldValue);

    }
    hubsynchistory = (e) => {
        var $this = e.currentTarget;
        var sync_id = $($this).attr('sync_id');
        var connection_name = $($this).attr('connection_name');
        ReactDOM.render(<DatahubSyncLogBookHistory syncId={sync_id} connectionName={connection_name} />, document.querySelector('.synclogbookhistorycontainer'));
    }
    cancelSynclogSettings = () => {
        ReactDOM.unmountComponentAtNode(document.querySelector(".append_alertmodal"))
    }
    maxDataCheck = (e) => {
        var $target = $(e.target);
        var selected = $target.is(':checked');
        if (selected == true) {
            $('#maxage').removeClass("disabled");
            $('#maxageunits').removeClass("disabled");
            $(".auditconfig").removeClass("disabled");
        } else {
            $('#maxage').val("");
            $('#maxage').addClass("disabled");
            $('#maxageunits').addClass("disabled");
            $(".auditconfig").addClass("disabled");
        }
    }
    settingsPopup = (e) => {
        e.stopPropagation();
        var that = this;
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getHubSynclogconfig",
            queryString: "",
            sucessFunction: (response) => {
                if (response.hasOwnProperty('error')) {
                    $(".spinner").hide();
                } else {
                    if (document.querySelector(".append_alertmodal") != null) {
                        ReactDOM.unmountComponentAtNode(document.querySelector(".append_alertmodal"))
                    }
                    var retentionpopupelement = that.retentionSettingPopup(response[0].data_age_number, response[0].data_age_duration);
                    ReactDOM.render(retentionpopupelement, document.querySelector(".append_alertmodal"));
                }
            },
            rejectFunction: () => { },
            headers: requestHeaders

        });
    }
    applySyncLogSettings = (e) => {
        e.preventDefault();
        var isSyncValid = true;
        var maxage = $("#maxage").val();
        var maxageunits = $("#maxageunits option:selected").val();
        var isChecked = $("#maxDataCheck").is(':checked');
        if (isChecked && (maxage == "" || maxage < 1 || this.isFloat(maxage))) {
            $('.maxagevalidation').text(lang["Datahub.maxageerror"]);
            $('.maxagevalidation').removeClass("hide");
            isSyncValid = false;
        } else {
            let requestHeaders = {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            var reqdata = new FormData();
            reqdata.set("maxage", $('.maxage').val());
            reqdata.set("maxageunits", $('.maxageunits').val());
            SendRequest({
                url: "datahubsynclogpurge",
                body: reqdata,
                sucessFunction: (response) => {
                    ReactDOM.unmountComponentAtNode(document.querySelector(".append_alertmodal"))
                },
                rejectFunction: () => { },
                headers: requestHeaders

            });
        }
    }
    isFloat(n) {
        return n % 1 !== 0;
    }
    retentionSettingPopup(data_age_number, data_age_duration) {
        var that = this.props;
        data_age_duration = data_age_duration != null && data_age_duration != "" ? data_age_duration : "W";
        var temp;

        var tempElement;
        if (data_age_number != null) {
            tempElement = <>
                <div style={{ display: "inline-block", float: "right", marginTop: "-8px", marginRight: "14px" }}>
                    <input type="number" className="form-control max-width-100-px maxage" id="maxage" placeholder="0" defaultValue={data_age_number} />
                    <div className="form-group">
                        <label for="exampleInputEmail2"></label>
                        <select id="maxageunits" className="form-control maxageunits">
                            {(data_age_duration == 'W') ? (
                                <><option value="W" selected>{lang["Datahub.weeks"]}</option>
                                    <option value="M">{lang["Datahub.months"]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</option>
                                    <option value="Y">{lang["Datahub.years"]}</option></>
                            ) : (data_age_duration == 'M') ? (
                                <><option value="W">{lang["Datahub.weeks"]}</option>
                                    <option value="M" selected>{lang["Datahub.months"]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</option>
                                    <option value="Y">{lang["Datahub.years"]}</option></>
                            ) : (data_age_duration == 'Y') ?
                                <><option value="W">{lang["Datahub.weeks"]}</option>
                                    <option value="M">{lang["Datahub.months"]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</option>
                                (<option value="Y" selected>{lang["Datahub.years"]}</option>)</>
                                :
                                <><option value="W" selected>{lang["Datahub.weeks"]}</option>
                                    <option value="M">{lang["Datahub.months"]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</option>
                                    <option value="Y">{lang["Datahub.years"]}</option></>}
                        </select>
                    </div>
                </div>
                <div style={{ color: "red" }} className="col-xs-12 maxagevalidation hide nopadding"></div>
            </>
        } else {

            tempElement = <>
                <div style={{ display: "inline-block", float: "right", marginTop: "-8px", marginRight: "14px" }}>
                    <input type="number" className="form-control max-width-100-px maxage disabled" id="maxage" placeholder="0" defaultValue={data_age_number} />
                    <div className="form-group">
                        <label for="exampleInputEmail2"></label>
                        <select id="maxageunits" className="form-control maxageunits disabled">
                            {(data_age_duration == 'W') ? (
                                <><option value="W" selected>{lang["Datahub.weeks"]}</option>
                                    <option value="M">{lang["Datahub.months"]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</option>
                                    <option value="Y">{lang["Datahub.years"]}</option></>
                            ) : (data_age_duration == 'M') ? (
                                <><option value="W">{lang["Datahub.weeks"]}</option>
                                    <option value="M" selected>{lang["Datahub.months"]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</option>
                                    <option value="Y">{lang["Datahub.years"]}</option></>
                            ) : (data_age_duration == 'Y') ?
                                <><option value="W">{lang["Datahub.weeks"]}</option>
                                    <option value="M">{lang["Datahub.months"]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</option>
                                (<option value="Y" selected>{lang["Datahub.years"]}</option>)</>
                                :
                                <><option value="W" selected>{lang["Datahub.weeks"]}</option>
                                    <option value="M">{lang["Datahub.months"]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</option>
                                    <option value="Y">{lang["Datahub.years"]}</option></>}
                        </select>
                    </div>
                </div>
                <div style={{ color: "red" }} className="col-xs-12 maxagevalidation hide nopadding"></div>
            </>
        }

        if (data_age_number != null) {
            temp = <div className="auditconfig">
                <form className="form-inline">
                    <div className="form-group width-100"> {lang["Datahub.maxdata"]}&nbsp;&nbsp;
                    {tempElement}
                    </div>
                </form>
            </div>
        }
        else {
            temp = <div className="auditconfig disabled">
                <form className="form-inline">
                    <div className="form-group width-100"> {lang["Datahub.maxdata"]}&nbsp;&nbsp;
                    {tempElement}
                    </div>
                </form>
            </div>
        }
        return (
            <div className="modal fade alertsmodal in" id="alertsmodal" tabIndex="-1" role="dialog" aria-labelledby="alertsmodal" aria-hidden="true" style={{ display: 'block' }}>
                <div className="modal-backdrop fade in"></div>
                <div className="modal-dialog modal-sm modal-sm-400 modal-dialog-sm-center" style={{ zIndex: "1050" }}>
                    <div className="modal-content">
                        <div className="modal-header boc-modal-hf-padding">
                            <button type="button" className="close white" id="settingscloseicon" onClick={this.cancelSynclogSettings} aria-hidden="true" data-dismiss="modal">&times;</button>
                            <h4 className="modal-title"><i className="fa fa-cogs marg-righ-10"></i>{lang["Datahub.syncsetting"]}</h4>
                        </div>
                        <div className="modal-body scrollset">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-xs-12 marg-top-10 marg-bot-20">
                                        <div className="auditretentionconfiguration scrollsetulviewer marg-top-10">
                                            <label className="auditretention subhead">  {lang["Datahub.synclogretn"]}
                                                <div className="drilldowntogglecheckbox">
                                                    <div className="checkbox checkbox-slider--c checkbox-slider-sm">
                                                        <label>
                                                            {(data_age_number != null) ? (<input id="maxDataCheck" type="checkbox" className="nomargin" defaultChecked onClick={this.maxDataCheck} />
                                                            ) : (<input id="maxDataCheck" type="checkbox" className="nomargin" onClick={this.maxDataCheck} />)}
                                                            <span>&nbsp;</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </label>
                                            {temp}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer boc-modal-hf-padding">
                            <button type="button" className="btn btn-bird" data-dismiss="modal" id="auditsettingclosebutton" onClick={this.cancelSynclogSettings}><i className="fa fa-times marg-righ-10"></i>{lang["datahub.canclbtn"]}</button>
                            <button type="button" className="btn btn-bird applyauditsettings" id="auditsettingapplybutton" onClick={this.applySyncLogSettings}><i className="fa fa-check marg-righ-10"></i>{lang["Datahub.apply"]}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}