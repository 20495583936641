import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { lang } from "../js/helpers/utils/languages/language";
import SendRequest from '../SendRequest';
import { getMessage } from '../js/helpers/utils/BirdMessage';
import BusinessModelsDropdownListView from '../BusinessModelsDropdownListView'
import ImportTemplateFile from './importtemplatefile'
import _ from 'underscore';
const $ = window.$;
const columns = [
    { dataField: 'id', text: 'S.No.', sort: false, headerAlign: 'center', align: 'center' },
    { dataField: 'name', text: 'Business Model Name', sort: true },
    { dataField: 'description', text: 'Description', sort: true },
    { dataField: 'create_date', text: 'Created Date', sort: true },
    { dataField: 'update_date', text: 'Updated Date', sort: true },
    { dataField: 'create', text: 'Create Business Model', sort: false, headerAlign: 'center', align: 'center', style: {width:'200px',minWidth:'200px',maxWidth:'200px'} },
    { dataField: 'editModel', text: 'Edit Business Model', sort: false, headerAlign: 'center', align: 'center' },
    { dataField: 'deleteModelCache', text: 'Clear Cache', sort: false, headerAlign: 'center', align: 'center' },
    { dataField: 'deleteModel', text: 'Delete Business Model', sort: false, headerAlign: 'center', align: 'center' }

];
const RemotePagination = ({ data, page, sizePerPage, onTableChange, totalSize }) => (
    <div className="notifications-table-container">
        <BootstrapTable
            remote
            keyField="id"
            data={data}
            columns={columns}
            pagination={paginationFactory({ page, sizePerPage, totalSize })}
            onTableChange={onTableChange}
        />
    </div>
);
export default class DataModelListTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            data: props.data,
            sizePerPage: props.sizePerPage,
            totalRecords: props.totalRecords,
            search: props.search,
            features : props.features
        };
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, data, totalRecords }) => {
        const currentIndex = (page - 1) * sizePerPage;
        {
            var that = this;
            type == "sort" ?
                setTimeout(() => {
                    let requestHeaders = {
                        method: "get",
                        headers: new Headers({
                            "Content-Type": "application/json"
                        })
                    };
                    SendRequest({
                        url: "getdatamodelslist",
                        queryString: "?fromDate= " + " " + "&toDate= " + " " + "&index= " + currentIndex + "&noOfRecords=" + sizePerPage + "&searchCriteria=" + that.state.search + "&order=" + sortOrder + "&colName=" + sortField +  "&urltype=" + "getUserPaginationDataModels",
                        sucessFunction: (response) => {
                            if (response.hasOwnProperty('error')) {
                                $(".spinner").hide();
                            } else {
                                var headerlist = response["header"];
                                var data = response["data"];
                                _.each(data, function (val, index) {
                                    var editpermit = "";
                        var editid = "";
                        var modeldelete = false;
                        var modelstoryboard = true;
                        var modelreport = false;
                        var modelmlreport = false;
                        var modelsmartinsights = false;
                        var modelimporttemplate = false;
                        var deletecache = false;
                        var DMStatus = 'ready';
                        var modelautoviz = false;
                        if (val.DMStatus == 'Ready') {
                            DMStatus = 'ready'
                        } else if (val.DMStatus == 'inprogress') {
                            DMStatus = 'processing'
                        } else {
                            DMStatus = 'error'
                        }
                        _.each(that.state.features, function (featuresModule) {
                            console.log(featuresModule)
                            if (featuresModule.moduleName == 'business_model') {
                                _.each(featuresModule.features, function (feature) {
                                    if (feature.featureName == 'create_model_storyboard' && feature.allow == true && DMStatus == 'ready') {
                                        modelstoryboard = true;
                                    }
                                    if (feature.featureName == 'create_model_report' && feature.allow == true && DMStatus == 'ready') {
                                        modelreport = true;
                                    } if (feature.featureName == 'create_model_smartinsights' && feature.allow == true && !val.multifact && DMStatus == 'ready') {
                                        modelsmartinsights = true;
                                    } if (feature.featureName == 'create_model_mlreport' && feature.allow == true && !val.multifact && DMStatus == 'ready') {
                                        modelmlreport = true;
                                    }if(feature.featureName == 'create_model_autovizreport' && feature.allow == true && !val.multifact && DMStatus == 'ready'){
                                        modelautoviz = true;
                                    }
                                    if (feature.featureName == 'import_template_view' && feature.allow == true && DMStatus == 'ready') {
                                        modelimporttemplate = true;
                                    }
                                });
                            }
                        });
                        val.editModel = <a href={`${that.props.url}/workspace/${btoa(val.data_model_id)}`} data-num={val.data_model_id} class="boc-snapshot-thumbnail reportview viewdatamodel" tab-id="0" tab-name="" id="reportstoryboardview"><i class="fa fa-edit"></i></a>;
                        val.deleteModel = <button type="button" onClick={() => { that.props.datamodeldelete(val.data_model_id,index) }} DMID={val.data_model_id} id="deletedatamodellistiew" workspace-id={val.workspace_id} datahub-id={val.hub_id} workspace-title={val.workspace_name} data-num={val.workspace_id} tab-id="0" tab-name="" class="btn btn-transparent aldelete"><i class="fa fa-trash-o"></i></button>;
                        val.deleteModelCache = <button type="button" onClick={() => { that.props.deletemodelcache(val.data_model_id,index) }} DMID={val.data_model_id} id="deletedatamodelcachelistiew" workspace-id={val.workspace_id} datahub-id={val.hub_id} workspace-title={val.workspace_name} data-num={val.workspace_id} tab-id="0" tab-name="" class="btn btn-transparent aldelete"><i class="fa fa-trash-o"></i></button>;
                        //   val.create=<a className="accordion-toggle" href={`#newdataitem${val.data_model_id}`} data-toggle="collapse" data-parent="#accordion" style={{ textDecoration: "none" }}><i className="fa fa-plus marg-righ-10"></i></a>
                        val.create = <BusinessModelsDropdownListView {...that.props} modelstoryboard={modelstoryboard} modelreport={modelreport} modelmlreport={modelmlreport} modelsmartinsights={modelsmartinsights}
                            modelimporttemplate={modelimporttemplate} modeldelete={modeldelete} DMID={val.data_model_id} is_shared={false} importtemplatefile={that.importtemplatefile} createsmartinsights={that.createsmartinsights} createstoryboard={that.createStoryBoard} deletemodel={that.datamodeldelete} createreport={that.createReport} indexval={index} editpermit={editpermit} createMLReport={that.createMLReport} modelautoviz={modelautoviz} createAutoViz={that.createAutoViz} deletecache={deletecache}/>
                    });

                                var keys = Object.keys(headerlist);
                                this.setState(() => ({
                                    page,
                                    data: data,
                                    sizePerPage,
                                    totalSize: totalRecords
                                }));
                            }
                        },
                        rejectFunction: () => { },
                        headers: requestHeaders
                    })
                }, 10) :
                setTimeout(() => {
                    let requestHeaders = {
                        method: "get",
                        headers: new Headers({
                            "Content-Type": "application/json"
                        })
                    };
                    SendRequest({
                        url: "getdatamodelslist",
                        queryString: "?fromDate= " + "" + "&toDate= " + "" + "&index= " + currentIndex + "&noOfRecords=" + sizePerPage + "&searchCriteria=" + that.state.search + "&order=" + sortOrder + "&colName=" + "" + "&urltype=" + "getUserPaginationDataModels",
                        sucessFunction: (response) => {
                            if (response.hasOwnProperty('error')) {
                                $(".spinner").hide();
                            } else {
                                var headerlist = response["header"];
                                var data = response["data"];
                                _.each(data, function (val, index) {
                                    var editpermit = "";
                                    var editid = "";
                                    var modeldelete = false;
                                    var modelstoryboard = true;
                                    var modelreport = false;
                                    var modelmlreport = false;
                                    var modelsmartinsights = false;
                                    var modelimporttemplate = false;
                                    var DMStatus = 'ready';
                                    var modelautoviz = false;
                                    var deletecache = false;
                                    if (val.DMStatus == 'Ready') {
                                        DMStatus = 'ready'
                                    } else if (val.DMStatus == 'inprogress') {
                                        DMStatus = 'processing'
                                    } else {
                                        DMStatus = 'error'
                                    }
                                    _.each(that.state.features, function (featuresModule) {
                                        if (featuresModule.moduleName == 'business_model') {
                                            _.each(featuresModule.features, function (feature) {
                                                if (feature.featureName == 'create_model_storyboard' && feature.allow == true && DMStatus == 'ready') {
                                                    modelstoryboard = true;
                                                }
                                                if (feature.featureName == 'create_model_report' && feature.allow == true && DMStatus == 'ready') {
                                                    modelreport = true;
                                                } if (feature.featureName == 'create_model_smartinsights' && feature.allow == true && !val.multifact && DMStatus == 'ready') {
                                                    modelsmartinsights = true;
                                                } if (feature.featureName == 'create_model_mlreport' && feature.allow == true && !val.multifact && DMStatus == 'ready') {
                                                    modelmlreport = true;
                                                }if(feature.featureName == 'create_model_autovizreport' && feature.allow == true && !val.multifact && DMStatus == 'ready'){
                                                    modelautoviz = true;
                                                }
                                                if (feature.featureName == 'import_template_view' && feature.allow == true && DMStatus == 'ready') {
                                                    modelimporttemplate = true;
                                                }
                                            });
                                        }
                                    });
                                    val.editModel = <a href={`${that.props.url}/workspace/${btoa(val.data_model_id)}`} data-num={val.data_model_id} class="boc-snapshot-thumbnail reportview viewdatamodel" tab-id="0" tab-name="" id="reportstoryboardview"><i class="fa fa-edit"></i></a>;
                                    val.deleteModel = <button type="button" onClick={() => { that.props.datamodeldelete(val.data_model_id,index) }} DMID={val.data_model_id} id="deletedatamodellistiew" workspace-id={val.workspace_id} datahub-id={val.hub_id} workspace-title={val.workspace_name} data-num={val.workspace_id} tab-id="0" tab-name="" class="btn btn-transparent aldelete"><i class="fa fa-trash-o"></i></button>;
                                    val.deleteModelCache = <button type="button" onClick={() => { that.props.deletemodelcache(val.data_model_id,index) }} DMID={val.data_model_id} id="deletedatamodelcachelistiew" workspace-id={val.workspace_id} datahub-id={val.hub_id} workspace-title={val.workspace_name} data-num={val.workspace_id} tab-id="0" tab-name="" class="btn btn-transparent aldelete"><i class="fa fa-trash-o"></i></button>;
                                    //   val.create=<a className="accordion-toggle" href={`#newdataitem${val.data_model_id}`} data-toggle="collapse" data-parent="#accordion" style={{ textDecoration: "none" }}><i className="fa fa-plus marg-righ-10"></i></a>
                                    val.create = <BusinessModelsDropdownListView {...that.props} modelstoryboard={modelstoryboard} modelreport={modelreport} modelmlreport={modelmlreport} modelsmartinsights={modelsmartinsights}
                                        modelimporttemplate={modelimporttemplate} modeldelete={modeldelete} DMID={val.data_model_id} is_shared={false} importtemplatefile={that.importtemplatefile} createsmartinsights={that.createsmartinsights} createstoryboard={that.createStoryBoard} deletemodel={that.datamodeldelete} createreport={that.createReport} indexval={index} editpermit={editpermit} createMLReport={that.createMLReport} modelautoviz={modelautoviz} createAutoViz={that.createAutoViz} deletecache={deletecache}/>
                                });
                                
                                var keys = Object.keys(headerlist);
                                this.setState(() => ({
                                    page,
                                    data: data,
                                    sizePerPage,
                                    totalSize: totalRecords
                                }));
                            }
                        },
                        rejectFunction: () => { },
                        headers: requestHeaders
                    })

                }, 10);
        }
    }
    createsmartinsights = (e) => {
        e.preventDefault();
        var that = this;
        var $this = e.currentTarget;
        var datamodelId = $($this).attr("datamodel-id");
        var datamodelTitle = $($this).attr("datamodel-title");
        if (datamodelId == null || datamodelId == undefined) {
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Information');
            $('.birdmessage-info').empty().text(getMessage('BirdInformation01'));
        } else {
            $(".spinner").show();
            $(".cancelmodal").click();
            $(".smartinsightsclick").click();
            $(".storyboardtabs ul.tabcontainer").removeClass("hide");
            $(".smartinsightintro-container").removeClass("hide");
            $(".smartinsightdatacontainer").removeClass("hide");
            var globalfilterArray = [];
            var requestbody = new FormData();
            requestbody.set("datamodelid", datamodelId + "");
            let requestHeaders = {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            SendRequest({
                url: "checkfordatatypes",
                body: requestbody,
                sucessFunction: (response) => {
                    if (!response.hasOwnProperty('error')) {
                        var reportrequestbody = new FormData();
                        reportrequestbody.set("datamodelid", datamodelId);
                        reportrequestbody.set("type", "");
                        SendRequest({
                            url: "createreportfrommodel",
                            body: reportrequestbody,
                            sucessFunction: (response) => {
                                var href = "/" + window.localStorage.getItem("appName") + "/welcome/viewsmartinsight/" + btoa(datamodelId)
                                sessionStorage.setItem("datamodelname", datamodelTitle);
                                that.props.history.push(href);
                            },
                            rejectFunction: this.reportDataError,
                            headers: requestHeaders
                        });
                        $(".spinner").hide();
                    } else {
                        $(".spinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').removeClass('show');
                    }
                },
                rejectFunction: this.reportDataError,
                headers: requestHeaders
            });
        }
    }
    createReport = (e) => {
        var $this = e.currentTarget;
        console.log($this);
        var datamodelId = $($this).attr("datamodel-id");
        var isShared = $($this).attr("is_shared");

        var bodyParams = new FormData();
        bodyParams.set("datamodelid", datamodelId);
        bodyParams.set("type", "report");

        SendRequest({
            url: "createreportfrommodel",
            body: bodyParams,
            sucessFunction: (response) => {
                var tempReportId = response['tempreportid'];
                sessionStorage.setItem("isSharedModel" + datamodelId, isShared);
                var href = "/" + window.localStorage.getItem("appName") + "/welcome/viewreport/" + btoa(datamodelId) + "/" + btoa(tempReportId);
                this.props.history.push(href);
            },
            rejectFunction: () => { },
            headers: {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            }
        });
    }
    createMLReport = (e) => {
        var $this = e.currentTarget;
        console.log($this);
        var datamodelId = $($this).attr("datamodel-id");
        var isShared = $($this).attr("is_shared");

        var bodyParams = new FormData();
        bodyParams.set("datamodelid", datamodelId);
        bodyParams.set("type", "report");

        SendRequest({
            url: "createreportfrommodel",
            body: bodyParams,
            sucessFunction: (response) => {
                var tempReportId = response['tempreportid'];
                sessionStorage.setItem("isSharedModel" + datamodelId, isShared);
                var location = window.location;
                var href = location.origin + "/" + window.localStorage.getItem("appName") + "/welcome/viewmodel/" + btoa(datamodelId);
                window.open(href, '_blank');
            },
            rejectFunction: () => { },
            headers: {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            }
        });
    }
    importtemplatefile = (e) => {
        e.preventDefault();
        var $this = e.currentTarget;
        var datamodelId = $($this).attr("datamodel-id");
        var isShared = $($this).attr("is_shared");
        var that = this;
        var importTemplateFileView = new ImportTemplateFile({
            featuresModule: that.state.features,
            datamodelId: datamodelId,
            isFromSharedModel: isShared != undefined ? isShared : false,
            history: that.props.history
        });
    }
    createStoryBoard = (e) => {
        var $this = e.currentTarget;
        $('.spinner').show();
        console.log($this);
        var datamodelId = $($this).attr("datamodel-id");
        var isShared = $($this).attr("is_shared") != undefined ? $($this).attr("is_shared") : false;

        var requestbody = new FormData();
        requestbody.set("datamodelid", datamodelId);
        requestbody.set("type", "storyboard");
        let requestHeaders = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "createreportfrommodel",
            body: requestbody,
            headers: requestHeaders,
            sucessFunction: (props) => {
                var tempReportId = props.tempreportid;
                sessionStorage.setItem("isSharedModel" + datamodelId, isShared);
                var href = "/" + window.localStorage.getItem("appName") + "/welcome/viewstoryboard/" + btoa(datamodelId) + "/" + btoa(tempReportId);
                this.props.history.push(href);
                $('.spinner').hide();
            },
            rejectFunction: (props) => {
                var response = props;
                $(".spinner").hide();
                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('hide');
                $('.birdmessage h2').empty().append('Error : ');
                $('.birdmessage h2').append('<span class="errorcode">');
                $('.birdmessage .errorcode').empty().append(response.errorCode);
                $('.birdmessage-info').empty().text(response.error);
                $('.details').addClass('show').text("Details");
                $('.messagedetails xmp').empty().append(response.errorTrace)
            }
        });
    }
    createAutoViz =(e)=>{
        var $this = e.currentTarget;
        console.log($this);
        var datamodelId = $($this).attr("datamodel-id");
        var isShared = $($this).attr("is_shared");
        var datamodelTitle = $($this).attr("datamodel-title");
        var bodyParams = new FormData();
        bodyParams.set("datamodelid", datamodelId);
        bodyParams.set("type", "report");

        SendRequest({
            url: "createreportfrommodel",
            body: bodyParams,
            sucessFunction: (response) => {
                var tempReportId = response['tempreportid'];
                sessionStorage.setItem("isSharedModel" + datamodelId, isShared);
                sessionStorage.setItem("report_navigated_datamodelname", datamodelTitle)
                var location = window.location;
                var href = location.origin + "/" + window.localStorage.getItem("appName") + "/welcome/viewautoviz_report/" + btoa(datamodelId);
                window.open(href, '_blank');
            },
            rejectFunction: () => { },
            headers: {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            }
        });
    }
    
    render() {
        const { data, sizePerPage, page, totalRecords } = this.state;
        return (
            <RemotePagination
            data={data}
            page={page}
            sizePerPage={sizePerPage}
            totalSize={totalRecords}           
            onTableChange={this.handleTableChange}/>
        );
    }
}