import React from "react";
import _ from 'underscore';
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import { lang } from "../js/helpers/utils/languages/language";
import PubSub from 'pubsub-js'
const $ = window.$;

export default class XaxisAttributesTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            features: props.features,
            is_storyboard: props.is_storyboard
        };
        this.createCustomFiled = this.createCustomFiled.bind(this);
    }
    render() {
        var that = this.props;
        var columnicon;
        if (that.iscustomfunction == 1 && that.iscustomfunction != undefined) {
            columnicon = <span className="function">{lang["storyboard.fx"]}</span>
        } else if (that.iscustomfunction == 3) {
            columnicon = <i className="fa fa-arrows-h"></i>
        } else {
            if (that.xaxiscolumnType === 'number') {
                columnicon = <>123</>
            } else if (that.xaxiscolumnType === 'date' || that.xaxiscolumnType === 'datetime' || that.xaxiscolumnType === 'month' || that.xaxiscolumnType === 'day') {
                columnicon = <i className="fa fa-calendar"></i>
            } else if (that.xaxiscolumnType == "customhierarchy") {
                columnicon = <i className="fa fa-list"></i>
            } else {
                columnicon = <span>{lang["storyboard.abc"]}</span>
            }
        }

        return (<div>

            {(that.viewmode === 'values' && that.viewmode != undefined) ? (

                <li className="boc-list-item singleliitems" id="itemdisplay" iscustomfunction={that.iscustomfunction} data-name={that.xaxiscolumnName} title={that.xaxiscolumnDisplayName} xaxisidentity={that.xaxisidentity} data-type={that.xaxiscolumnType} table-name={that.xaxistableName} >
                    <span className="itemcontainer">
                        {(that.iscustomfunction != undefined && that.iscustomfunction == 1) ? (
                            <span className="itemtype callcustommeasure" id="callcustommeasures" data-edit="edit" data-name={that.xaxiscolumnName} onClick={this.createCustomFiled} title={lang["storyboard.editfunction"]}>{columnicon}</span>
                        ) : (that.iscustomfunction == 2) ? (
                            <span className="itemtype callcustomhierarchy" data-edit="edit" data-name={that.xaxiscolumnName} onClick={this.createDillDownHierarchy} title={lang["storyboard.editfunction"]}>{columnicon}</span>
                        ) : (that.iscustomfunction == 3) ? (
                            <span className="itemtype createrange" onClick={this.createrange} data-edit="edit" data-name={that.xaxiscolumnName} title={lang["storyboard.editfunction"]}>{columnicon}</span>
                        ) : (
                                        <span className="itemtype">{columnicon}</span>
                                    )}

                        <span className="itemname" onDoubleClick={that.editname} title={that.xaxiscolumnDisplayName}>{that.xaxiscolumnDisplayName}</span>

                        <span className="itemedit" title="" aria-expanded="false" data-toggle="dropdown">
                            <span type="button" data-name={that.xaxiscolumnDisplayName} className="" >
                                <i className="expandinghamburger"></i>
                            </span>
                        </span>
                        <ul role="menu" className="dropdown-menu dropdown-menu-right">
                            {this.state.features.map((featuresModule, index) => {
                                if (this.state.is_storyboard == "N") {
                                    if (featuresModule.moduleName == 'my_report') {
                                        return featuresModule.features.map((feature, index) => {
                                            if (feature.featureName == 'viewreport_maximize_rename_column' && feature.allow == true) {
                                                return <li key={index} onClick={that.editname} className="xaxisrename" title={lang["storyboard.rename"]}>{lang["storyboard.rename"]}</li>
                                            }
                                        })
                                    }
                                }
                                if (this.state.is_storyboard == "Y") {
                                    if (featuresModule.moduleName == 'story_board') {
                                        return featuresModule.features.map((feature, index) => {
                                            if (feature.featureName == 'viewreport_rename_column' && feature.allow == true) {
                                                return <li key={index} onClick={that.editname} className="xaxisrename" title={lang["storyboard.rename"]}>{lang["storyboard.rename"]}</li>
                                            }
                                        })
                                    }
                                }
                            }
                            )}
                            {/* {(that.iscustomfunction=="0" || that.iscustomfunction=="1")?(
			// <!-- <li className="converttomeasure" data-name={that.xaxiscolumnName} data-type={that.xaxiscolumnType} table-name={that.xaxistableName} title={that.xaxiscolumnDisplayName}>Create Measure</li> -->
			):null} */}
                            {(that.iscustomfunction == "1") ? (
                                <li className="deletecustomfield" onClick={that.deletecustomfield} data-name={that.xaxiscolumnName} data-type={that.xaxiscolumnType} table-name={that.xaxistableName} title={that.xaxiscolumnDisplayName}>{lang["storyboard.delfield"]}</li>
                            ) : null}
                            {(that.iscustomfunction == "2") ? (
                                <li className="deletecustomhierarchyfield" onClick={this.deleteXcustomhierarchyfield} data-name={that.xaxiscolumnName} data-type={that.xaxiscolumnType} table-name={that.xaxistableName} title={that.xaxiscolumnDisplayName}>{lang["storyboard.deldrill"]}</li>
                            ) : null}
                            {(that.iscustomfunction == "3") ? (
                                <li className="deletecustomrangefield" onClick={this.deletecustomrangefield} data-name={that.xaxiscolumnName} data-type={that.xaxiscolumnType} table-name={that.xaxistableName} title={that.xaxiscolumnDisplayName}>{lang["storyboard.delrange"]}</li>
                            ) : null}
                            {(that.enableDrillThroughHiddenFilterOption == true) ? (
                                <li className="enabledrillthroughhiddenfilter" onClick={this.enabledrillthroughhiddenfilter} data-name={that.xaxiscolumnName} data-type={that.xaxiscolumnType} table-name={that.xaxistableName} title={that.xaxiscolumnDisplayName}>{lang["storyboard.hiddendrill"]}</li>
                            ) : null}
                            {/* <!-- <li className="divider"></li>
			<li>Show on Right Axis</li> --> */}
                        </ul>
                    </span>
                    <span className="itemoptions">
                        <span className="itemremove">
                            <button className="btn btn-transparent deletexaxis" onClick={that.deleteelement} type="button" title={lang["storyboard.remove"]}><i className="fa fa-trash-o"></i></button>
                        </span>
                        <span className="itemaggregation">
                            {(that.xaxiscolumnType == 'number' || that.xaxiscolumnType == "latitude" || that.xaxiscolumnType == "longitude") ? (
                                <><span className="aggregationtext hidden" aria-expanded="false" data-toggle="dropdown">[ {that.xaxisidentity} ]</span>
                                    <ul role="menu" className={`dropdown-menu ${that.xaxiscolumnName}`}>
                                        <li className="aggregation-li-item latitude" onClick={this.changexAxisAggregation} title={lang["storyboard.lat"]}>{lang["storyboard.lat"]}</li>
                                        <li className="aggregation-li-item longitude" onClick={this.changexAxisAggregation} title={lang["storyboard.long"]}>{lang["storyboard.long"]}</li>
                                    </ul>
                                </>
                            ) : null}
                        </span>
                    </span>
                </li>
            ) : (that.viewmode != undefined && (that.viewmode === 'smartvalues')) ? (
                <li class="boc-list-item singleliitems" id="itemdisplay" iscustomfunction={that.iscustomfunction} data-name={that.xaxiscolumnName} title={that.xaxiscolumnDisplayName} xaxisidentity={that.xaxisidentity} data-type={that.xaxiscolumnType} table-name={that.xaxistableName} >
                    <span class="itemtype">
                        {(that.iscustomfunction == 1 && that.iscustomfunction != undefined) ? (
                            <span class="function">fx</span>
                        ) : (that.iscustomfunction == 3) ? (
                            <i class="fa fa-arrows-h"></i>
                        ) : (
                                    (that.xaxiscolumnType === 'number') ? (
                                        <>123</>
                                    ) : (that.xaxiscolumnType === 'date' || that.xaxiscolumnType === 'datetime' || that.xaxiscolumnType === 'month' || that.xaxiscolumnType === 'day') ? (
                                        <i class="fa fa-calendar"></i>
                                    ) : (that.xaxiscolumnType == "customhierarchy") ? (
                                        <i class="fa fa-list"></i>
                                    ) : (
                                                    <span>{lang["storyboard.abc"]}</span>
                                                )
                                )}
                    </span>
                    <span class="itemname" >{that.xaxiscolumnDisplayName}</span>
                    <span class="btn-group pull-right">
                        <button class="btn btn-transparent deletexaxis" onClick={that.deleteelement} type="button">
                            <i class="fa fa-trash"></i>
                        </button>
                    </span>
                </li>
            ) : (
                        <li class="boc-list-item singleliitems" id="itemdisplay" iscustomfunction={that.iscustomfunction} data-name={that.xaxiscolumnName} title={that.xaxiscolumnDisplayName} xaxisidentity={that.xaxisidentity} data-type={that.xaxiscolumnType} table-name={that.xaxistableName} >
                            <span class="fa-stack fa-sm marg-righ-5">
                                <i class="fa fa-circle fa-stack-2x maxtheme5"></i>
                                {(that.xaxiscolumnType === 'number') ? (
                                    <i class="fa fa-sort-numeric-asc fa-stack-1x fa-inverse"></i>
                                ) : (that.xaxiscolumnType === 'date' || that.xaxiscolumnType === 'datetime') ? (
                                    <i class="fa fa-calendar fa-stack-1x fa-inverse"></i>
                                ) : (that.xaxiscolumnType === 'month') ? (
                                    <i class="fa fa-calendar-o fa-stack-1x fa-inverse"></i>
                                ) : (that.xaxiscolumnType === 'day') ? (
                                    <i class="fa fa-calendar-o fa-stack-1x fa-inverse"></i>
                                ) : (that.xaxiscolumnType == "customhierarchy") ? (
                                    <i class="fa fa-list-ol fa-stack-1x fa-inverse"></i>
                                ) : (
                                                        <i class="fa fa-sort-alpha-asc fa-stack-1x fa-inverse"></i>
                                                    )}
                            </span>
                            <span class="itemname " >{that.xaxiscolumnDisplayName}</span>
                            <span class="btn-group pull-right">
                                <button class="btn btn-transparent deletexaxis" onClick={that.deleteelement} type="button">
                                    <i class="fa fa-times fa-inverse"></i>
                                </button>
                            </span>
                            {(that.xaxiscolumnType == 'number' || that.xaxiscolumnType == "latitude" || that.xaxiscolumnType == "longitude") ? (
                                <>
                                    <span class="boclabel boclabel-right-10 opacity-effect aggregationtext mapchartInfo hidden" aria-expanded="false" data-toggle="dropdown">{that.xaxisidentity}&nbsp;<i class="fa fa-angle-down"></i></span>
                                    <ul role="menu" class={`dropdown-menu dropdown-inline mapchart ${that.xaxiscolumnName}`}>
                                        <li class="aggregation-li-item latitude" onClick={this.changexAxisAggregation} title={lang["storyboard.lat"]}>{lang["storyboard.lat"]}</li>
                                        <li class="aggregation-li-item longitude" onClick={this.changexAxisAggregation} title={lang["storyboard.long"]}>{lang["storyboard.long"]}</li>
                                    </ul>
                                </>
                            ) : null}
                        </li>
                    )}
        </div>);
    }
    createCustomFiled(e) {
        PubSub.publish('createCustomFiled', e.currentTarget);
    }
    createDillDownHierarchy(e) {
        PubSub.publish('createDillDownHierarchy', e.currentTarget);
        document.body.classList.add("modal-open");
    }
    createrange(e) {
        e.stopPropagation()
        PubSub.publish('createrange', e.currentTarget);
        document.body.classList.add("modal-open");
    }
    changexAxisAggregation = (e) => {
        var $this = e.currentTarget;
        $($this).closest('span').prev().text()
        $(this.props.ele).find(".aggregation-li-item").removeClass("active");
        $(e.currentTarget).addClass("active");
        $(this.props.ele).find(".aggregationtext").text("[ " + $(e.currentTarget).text() + " ]");
        var columnName = $(this.props.ele).find('.singleliitems').attr("data-name");
        var columnDisplayName = $(this.props.ele).find('.singleliitems').attr("title");
        var xaxisidentity = $(e.currentTarget).attr("title");
        var xaxisidentitytext = $(e.currentTarget).text();
        var percentileValue = $(this.props.ele).find(".percentilebox").val();
        $(this.props.ele).find('li.singleliitems').attr("percentile-value", percentileValue);
        $(this.props.ele).find('.singleliitems').attr("xaxisidentity", $(e.currentTarget).text());
        this.xaxisidentity = xaxisidentity;
        var index = $(e.currentTarget).parent().parent().parent().index();;
        var identityAxisfiled = { columnDisplayName: xaxisidentitytext }
    }
    deleteXcustomhierarchyfield(e) {
        var $this = e.currentTarget;
        var result = $.grep(ChartInfo.functions.getcustomhierarchy(), function (e) { return e.name != $($this).attr("data-name") });
        ChartInfo.functions.setcustomhierarchy(result);
        $('.dimensionitem[data-name="' + $($this).attr("data-name") + '"]').remove();
        $('.customhierarchyfields[data-name="' + $($this).attr("data-name") + '"]').remove();
        $('.xaxisattributes li[data-name=' + $($this).attr("data-name") + ']').remove();
        $('.yaxisattributes li[data-name=' + $($this).attr("data-name") + ']').remove();
        $('.legendAttributes li[data-name=' + $($this).attr("data-name") + ']').remove();
        $('.tooltipattributes li[data-name=' + $($this).attr("data-name") + ']').remove();
        $('.zaxisattributes li[data-name=' + $($this).attr("data-name") + ']').remove();
        var currentvalue = $($this).closest('li').attr("data-name");
        $(".xaxisitems option[title='" + currentvalue + "']").remove();
        $($this).closest('li').parent().parent().remove();
    }
    deletecustomrangefield(e) {
        var $this = e.currentTarget;
        var result = $.grep(ChartInfo.functions.getRange(), function (e) { return e.name != $($this).attr("data-name") });
        ChartInfo.functions.setRange(result);
        $('.dimensionitem[data-name="' + $($this).attr("data-name") + '"]').remove();
        $('.customrangedimension[data-name="' + $($this).attr("data-name") + '"]').remove();
        $('.singleliitems[data-name="' + $($this).attr("data-name") + '"]').remove();
        $('.xaxisattributes li[data-name=' + $($this).attr("data-name") + ']').remove();
        $('.yaxisattributes li[data-name=' + $($this).attr("data-name") + ']').remove();
        $('.legendAttributes li[data-name=' + $($this).attr("data-name") + ']').remove();
        $('.tooltipattributes li[data-name=' + $($this).attr("data-name") + ']').remove();
        $('.zaxisattributes li[data-name=' + $($this).attr("data-name") + ']').remove();
        $(e.currentTarget).parent().parent().remove();
    }
    enabledrillthroughhiddenfilter(e){
        var isChecked = $(".enabledrillthroughhiddenfilter").hasClass("active");
        if(isChecked){
            $(".enabledrillthroughhiddenfilter").removeClass("active");
        }else{
            $(".enabledrillthroughhiddenfilter").addClass("active");
        }
    }
}