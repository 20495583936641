import React from 'react';
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;
export default class DatamodelItemSettingsHtml extends React.Component {
    componentDidMount() {

        if (this.props.isCustomQuery) {
            ((e) => {
                this.props.loadCustomQuery(e);
            })();
        }
    }

    render() {
        return (
            <div role="tabpanel" className="datamodelitemsettingstabscontainer">
                <div className="entityname">
                    {
                        (this.props.isCustomQuery === true) ? <i className="fa fa-code"></i> : <i className="fa fa-table"></i>
                    }
                    {
                        (this.props.isCustomQuery == true && this.props.entityName == '') ? <>{lang["models.customquery"]}</> : <>{this.props.entityName}</>
                    }
                    <span className="entityconnectionsource">{this.props.entitySourceName}</span>
                    <button type="button" className="btn btn-transparent pull-right closesettingscontainer" onClick={this.props.closesettingscontainer}><i className="fa fa-times"></i></button>
                </div>
                <ul className="nav nav-tabs tabcontainer" role="tablist">
                    {
                        (this.props.isCustomQuery === true) ? (
                            <li role="presentation" className="tabheadli active">
                                <a href="#datamodelcustomquerytabview" id="datamodelcustomquerytab" onClick={this.props.loadCustomQuery} aria-controls="datamodelcustomquerytabview" role="tab" data-toggle="tab"><i className="fa fa-code"></i> {lang["models.customquery"]}</a>
                            </li>
                        ) : null
                    }
                    <li role="presentation" className={`tabheadli ${this.props.isCustomQuery == true ? '' : 'active'} ${this.props.isCustomQuery == true ? 'disabled' : ''}`}>
                        <a href="#datamodelsymanticnamestabview" id="datamodelsymanticnamestab" aria-controls="datamodelsymanticnamestabview" role="tab" data-toggle="tab"><i className="fa fa-pencil"></i>{lang["models.modifyentity"]}</a>
                    </li>
                </ul>
                <div className="tab-content">
                    <div role="tabpanel" className={`tab-pane ${this.props.isCustomQuery == true ? 'active' : ''} tabviewitems`} id="datamodelcustomquerytabview">
                        <div id="datamodelitemsettingscustomquerycontainer" >
                            <div id="customqueryaccordion"></div>
                        </div>
                    </div>
                    <div role="tabpanel" className={`tab-pane ${this.props.isCustomQuery == true ? '' : 'active'} tabviewitems`} id="datamodelsymanticnamestabview">
                        <div className="modal-body">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="notifications-table-container marg-top-10 datamodelsymanticnamestablecontainer"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-bird pull-right applydatamodelsymanticnames" onClick={this.props.updateSymanticNames} id="" workspaceentityid={this.props.workspaceentityid}><i className="fa fa-refresh"></i> {lang["models.updatebtn"]} </button>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}