import React from "react";
import LicenseRenewView from '../admin/licenserenewview.jsx';
import SendRequest from '../SendRequest';
import { lang } from "../js/helpers/utils/languages/language";
import ReactDOM from 'react-dom'
const $ = window.$;
export default class LicenseView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLicenseActive: false,
		}
		this.renewLicenseClose = this.renewLicenseClose.bind(this);
		this.licensemodelopen = this.licensemodelopen.bind(this);
	};
	componentDidMount(){
	// 	let requestHeaders = {
	// 		method: "get",
	// 		headers: new Headers({
	// 		  "Content-Type": "application/json"
	// 		})
	// 	  };
	// 	  SendRequest({
	// 		url: "getServerDateTime",
	// 		queryString: "",
	// 		sucessFunction: (d) => {
	// 		  $(".servertimezone").text(d.serverDateTime).attr("title", "Server Time : " + d.serverDateTime);
	// 		  var dt = new Date();
	// 		  $(".localtimezone").text(dt).attr("title", "Local Time : " + dt);
	// 		  $(".timezone").removeClass("hide");
	// 		},
	// 		rejectFunction: () => { },
	// 		headers: requestHeaders
	// 	  })
	 	var path = '<li><a class="navDashboard" href="#"><i class="fa  fa-home"></i>'+lang["admin.header.dashtitle"]+'</a></li><li><a href="#admin"><i class="fa fa-user-secret"></i> ' + lang["admin.header.admintitle"] + '</a></li><li><a><i class="fa fa-certificate"></i> ' + lang["admin.license.title"] + '</a></li>';
	 	$(".navbar.navbar-bird ul.nav-path").html(path);
	}
	render() {
		var props = this.props;
		$('a.boc-maxscreen-left-item.licensemanagement').addClass('showactive')
		$('.boc-admin-main .maxscreen-lft-filter-items a').not('.licensemanagement').removeClass('showactive');
		return (
			<div>
				<div className="container-fluid">
					<div className="row">
						<h1 className="boc-main-title" id="licenseinfo">
							<i className="fa fa-certificate fa-admin-sm"></i>{lang["admin.license.title"]}
					</h1>
						<div className="container-fluid">
							<div className="row in-back">
								<div className="row-same-height-inner">
									<div className="col-xs-12 col-xs-height in-panes" id="adminlicensepadding">
										<div className="col-xs-12 marg-top-10 nopadding">
											<div className="col-xs-12 marg-top-10 nopadding">
												<div className="col-xs-12">
													<div className="col-lg-3 col-xs-12 licensebox marg-top-5">
														<h4>
															<i className="fa fa-user fa-admin-sm"></i>{lang["admin.license.creatortitle"]}
													</h4>
														<table className="table">
															<thead>
																<tr>
																	<th>{lang["admin.license.allocated"]}</th>
																	<th>{lang["admin.license.used"]}</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	{
																		(props.licenseDetails.powerUsers == "&infin;") ? (

																			<td><span>Unlimited</span></td>
																		) : (
																				<td>{props.licenseDetails.powerUsers}</td>
																			)
																	}
																	<td>{props.licenseDetails.registeredPowerUsers}</td>
																</tr>
															</tbody>
														</table>
													</div>
													<div class="col-lg-3 col-xs-12 licensebox marg-top-5">
														<h4>
															<i className="fa fa-user fa-admin-sm"></i>{lang["admin.license.explortitle"]}
										</h4>
														<table className="table">
															<thead>
																<tr>
																	<th>{lang["admin.license.allocated"]}</th>
																	<th>{lang["admin.license.used"]}</th>
																</tr>
															</thead>
															<tbody>

																<tr>
																	{
																		(props.licenseDetails.readOnlyUsers == "&infin;") ? (

																			<td><span>Unlimited</span></td>
																		) : (
																				<td>{props.licenseDetails.readOnlyUsers}</td>
																			)
																	}
																	<td>{props.licenseDetails.registeredReadOnlyUsers}</td>
																</tr>
															</tbody>
														</table>
													</div>

													<div className="col-lg-1 hidden-xs"></div>

													<div className="col-lg-3 col-xs-12 licensebox marg-top-5">
														<h4>
															<i className="fa fa-user fa-admin-sm"></i>{lang["admin.license.viewertitle"]}
													</h4>
														<table className="table">
															<thead>
																<tr>
																	<th>{lang["admin.license.allocated"]}</th>
																	<th>{lang["admin.license.used"]}</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	{
																		(props.licenseDetails.readOnlyUsers == "&infin;") ? (

																			<td><span>Unlimited</span></td>
																		) : (
																				<td>{props.licenseDetails.readOnlyUsers}</td>
																			)
																	}
																	<td>{props.licenseDetails.registeredReadOnlyUsers}</td>
																</tr>
															</tbody>
														</table>
													</div>
													<div className="col-lg-1 hidden-xs"></div>
												</div>
											</div>
											<div className="col-xs-12 marg-top-10 nopadding">
												<div className="col-xs-12">
													<div class="col-lg-3 col-xs-12 licensebox marg-top-5">
														<h4>
															<i className="fa fa-bar-chart fa-admin-sm"></i>{lang["admin.license.editiontitle"]}
										</h4>
														<table className="table">
															<thead>
																<tr>
																	<th>&nbsp;</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>{props.licenseDetails.edition}</td>
																</tr>
															</tbody>
														</table>
													</div>
													<div className="col-lg-3 col-xs-12 licensebox marg-top-5">
														<h4>
															<i className="fa fa-database fa-admin-sm"></i>{lang["admin.license.datatitle"]}
													</h4>
														<table className="table">
															<thead>
																<tr>
																<th>{lang["admin.license.allocated"]}</th>
																<th>{lang["admin.license.used"]}</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	{
																		(props.licenseDetails.dataSize == "&infin;") ? (

																			<td><span>Unlimited</span></td>
																		) : (
																				<td>{props.licenseDetails.dataSize}</td>
																			)
																	}
																	<td>{props.licenseDetails.usedDataSize}</td>
																</tr>
															</tbody>
														</table>
													</div>
													<div className="col-lg-1 col-md-1 hidden-xs"></div>
													<div className="col-lg-3 col-xs-12 licensebox marg-top-5">
														<h4>
															<i className="fa fa-calendar fa-admin-sm"></i>{lang["admin.license.expirytitle"]}
													</h4>
														<table className="table">
															<thead>
																<tr>
																	<th>{lang["admin.license.expiry"]}</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>{props.licenseDetails.expiryDate}</td>
																</tr>
															</tbody>
														</table>
													</div>
												</div>
											</div>
											<div className="col-xs-12 marg-top-10 nopadding">
												<div className="col-xs-12">
													<div className="col-lg-7 col-xs-12 licensebox marg-top-5" id="licensetextoverflow" style={{ width: "calc(100% - 10px)" }}>
														<h4>
															<i className="fa fa-calendar fa-admin-sm"></i>{lang["admin.license.feattitle"]}
													</h4>
														<table class="table">
															<thead>
																<tr>
																	<th>{lang["admin.license.featsallow"]}</th>
																	<th>{lang["admin.license.featssmart"]}</th>
																	<th>{lang["admin.license.featml"]}</th>
																	<th>{lang["admin.license.featconnect"]}</th>
																	<th>{lang["admin.license.featlabel"]}</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>{props.licenseDetails.allowApi}</td>
																	<td>{props.licenseDetails.allowSmartInsights}</td>
																	<td>{props.licenseDetails.allowML}</td>
																	<td>{props.licenseDetails.connectors}</td>
																	<td>{props.licenseDetails.allowWhiteLable}</td>
																</tr>
															</tbody>
														</table>
													</div>
													{props.licenseDetails.multiTenancy == "true" && props.licenseDetails.isSuperAdmin == true ?
														<div className="col-lg-3 col-xs-12 licensebox marg-top-5">
															<h4>
																<i className="fa fa-list-alt fa-admin-sm"></i>Features
										</h4>
															<table className="table">
																<thead>
																	<tr>
																		<th>Multi-Tenancy</th>
																	</tr>
																</thead>
																<tr>
																	{props.licenseDetails.multiTenancy == "true" ?
																		<td><i className="fa fa-check fa-green"></i>&nbsp;Enabled</td>
																		:
																		<td><i className="fa fa-times fa-red"></i>&nbsp;Not Enabled</td>
																	}
																</tr>
															</table>
														</div>
														: null}
												</div>
											</div>
										</div>
										<div className="col-xs-12 pull-right">
											<button className="btn btn-bird showlicensemodel pull-right" onClick={this.licensemodelopen} id="licenserenew">{lang["admin.license.renewtitle"]}</button>
										</div>

									</div>
									<div className="licenserenewmodeldiv">

									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
	licensemodelopen(e) {
		ReactDOM.render(<LicenseRenewView close={this.renewLicenseClose} />, document.querySelector('.licenserenewmodeldiv'));
	}

	renewLicenseClose = () => {
		ReactDOM.unmountComponentAtNode(document.querySelector('.licenserenewmodeldiv'))
	}
};
