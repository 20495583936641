import React from 'react';
import { lang } from "../js/helpers/utils/languages/language";
export default class AddNewAddressHtml extends React.Component {
    constructor(props){
        super(props);
        this.state={
            url:props.url,
            port:props.port
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="form-group form-group-sm required">
                    <label htmlFor="" className="col-sm-4 control-label">{lang["datahub.Ipadd"]}</label>
                    <div className="col-sm-3">
                        <input type="text" value={(this.state.url!==undefined) ?this.state.url:""} className="form-control url" placeholder="" onChange={(e)=>{this.setState({url:e.target.value})}} />
                        <span id="url" className="help-block hide"><span></span></span>
  	                </div>
                    <div className="col-sm-5 nopadding">
                        <label htmlFor="" className="col-sm-1 control-label">{lang["datahub.port"]}</label>
                        <div className="col-sm-8 ">
                            <input type="number" value={(this.state.port==undefined) ? '' : this.state.port} className="form-control port" placeholder="" onChange={(e)=>{this.setState({port:e.target.value})}} />
                            <span id="port" className="help-block hide"><span></span></span>
                        </div>
                        <div className="col-sm-1 nopadding">
                            <button className="pull-left btn btn-bird btn-sm removeaddress_kafka" onClick={this.props.removeaddresskafka}><i className="fa fa-minus-circle "></i> {lang["datahub.remove"]} </button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}