import React from "react";
import SendRequest from './SendRequest';
import Properties from './Properties/ServerProperties'
export default class SamlRequest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        let urlString = window.location.origin + "/" + Properties.appName + "/welcome";
        if (window.location.search != undefined && window.location.search != "" && (window.location.search.indexOf("embeddedreport") >= 0 || window.location.search.indexOf("embeddedcustomstoryboard") >= 0 || window.location.search.indexOf("embeddedsmartinsightreport") >= 0 || window.location.search.indexOf("schedulereport") >= 0 || window.location.search.indexOf("alertreport") >=0)) {
            urlString= urlString+"/private/" + window.location.search.replace("?", "");
          }
        let requestHeaders = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        let form = new FormData();
        form.append('isFrom', "samlrequest");

        SendRequest({
            url: "authenticate/getloginstatus",
            body: form,
            sucessFunction: (result) => {
                if (result.hasOwnProperty('error')) {
                    document.getElementById('error').innerHTML = result.error;
                    return;
                } else {
                    window.localStorage.clear();
                    window.localStorage.setItem("refreshToken", result.refreshToken);
                    window.localStorage.setItem("accessToken", result.accessToken);
                    window.localStorage.setItem("accessTokenExpiration", result.accessTokenExpiration);
                    window.localStorage.setItem("serverTimezone", result.timezoneId);
                    sessionStorage.setItem("accessToken", result.accessToken);
                    if (result.samltoken != null && result.samltoken) {
                        window.open(urlString, '_self');
                    }
                }
            },
            rejectFunction: () => { },
            headers: requestHeaders
        });

        return (<React.Fragment></React.Fragment>);
    }
}