import React, { Component } from "react";
import ReactDOM from 'react-dom';
import EditUserAccountFormHtml from "./edituseraccountformhtml.jsx";
import SendRequest from '../../SendRequest';
const $ =window.$;
class EditUserAccountForm extends Component {
  constructor(props) {
    super(props);
      this.state = { };
    let featuresString = sessionStorage.getItem("features");
    featuresString = featuresString.slice(0, 3) + featuresString.slice(4);
    let decryptedString = window.atob(featuresString);
    this.state.parsedFeatureJson = JSON.parse(decryptedString);
    this.state.features= JSON.parse(decryptedString); 
    this.state.groupsresponse= {};
    this.state.rolesresponse= {};
    this.state.userdetailsresponse= {};
    this.state.userId=props.userId;
    this.getGroupslist();
    this.getRoleslist();
    this.getUserDetails();
    
  }
getGroupslist(){
  let requestHeaders = {
    method: "get",
    headers: new Headers({
        "Content-Type": "application/json"
    })
};
   SendRequest({
    url: "getAllGroups",
    queryString: "",
    sucessFunction: (response) => {
        if (response.hasOwnProperty('error')) {
            $(".spinner").hide();
        } else {
          this.state.groupsresponse=response;

        }
      },
      rejectFunction: () => { },
      headers: requestHeaders
    })
}
getRoleslist(){
  let requestHeaders = {
    method: "get",
    headers: new Headers({
        "Content-Type": "application/json"
    })
};
   SendRequest({
    url: "getRoles",
    queryString: "",
    sucessFunction: (response) => {
        if (response.hasOwnProperty('error')) {
            $(".spinner").hide();
        } else {
       this.state.rolesresponse=response;
       this.render();
        }
      },
      rejectFunction: () => { },
      headers: requestHeaders
    })
}
getUserDetails(){
  let requestHeaders = {
    method: "get",
    headers: new Headers({
        "Content-Type": "application/json"
    })
};
   SendRequest({
    url: "getGroup",
    queryString: "?groupId="+this.state.userId+"",
    sucessFunction: (response) => {
        if (response.hasOwnProperty('error')) {
            $(".spinner").hide();
        } else {
          this.state.userdetailsresponse=response;

        }
      },
      rejectFunction: () => { },
      headers: requestHeaders
    })
}
  render(){
    var that=this.props;
    setTimeout(() => {
      ReactDOM.unmountComponentAtNode(document.querySelector('.newuser'));
    ReactDOM.render(<EditUserAccountFormHtml edituserdetails={this.state.userdetailsresponse} groupsresponse={this.state.groupsresponse} rolesresponse={this.state.rolesresponse} features={this.state.features}/>,document.querySelector(".newuser"));
  
  }, 500);
  return (
<div></div>
    );
};
}
export default EditUserAccountForm;