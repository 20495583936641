import React, { Component } from "react";
import _ from 'underscore';
import SendRequest from '../../SendRequest';
import AccountsTemplate from './accounts';
import AddAccount from './addAccount';
import DeleteAccountForm from './deleteaccount';
import EditAccount from './editAccount';
import AddLicenseView from './AddLicenseView';
import ShowLicenseView from './showLicenseView';
import { getMessage } from '../../js/helpers/utils/BirdMessage';
import { lang  } from '../../js/helpers/utils/languages/language';
const $ = window.$;

export default class AccountsView extends Component{
    constructor(props){
        super(props);
        this.state = {
            response:null,
            features: this.props.features
        }
     
    }
    componentDidMount(){
        this.getClients();
        var path = '<li><a class="navDashboard" href="#"><i class="fa  fa-home"></i>'+lang["admin.header.dashtitle"]+'</a></li><li><a href="#admin"><i class="fa fa-user-secret"></i> ' + lang["admin.header.admintitle"] + '</a></li><li><a> <i class="fa fa-key"></i>' + "Manage Accounts" + '</a></li>';
		$(".navbar.navbar-bird ul.nav-path").html(path);
    }
    AddAccount=(e)=>{
    var addaccount = new AddAccount({});
    }
    viewLicense=(e)=>{
        var val = $(e.currentTarget).val();
        var viewlicense= new ShowLicenseView({clientId:val});
    }
    resetpasswordfromsuperadmin=(e)=>{
        $(".deleteyes").unbind( "click" );
    				$(".deleteno").unbind( "click" );
    				$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
    				$('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
    				$('.birdmessagebuttons').addClass('show');
    				$('.birdmessage-center').addClass("message-top-in");
    				$('.details').removeClass('show');
    				$('.details').addClass('hide');
    				$('.birdmessage h2').empty().append('Warning');
                    $('.birdmessage-info').empty().text(getMessage('BirdWarning56'));
                    var userId = $(e.currentTarget).val();
                    var clientId = $(e.currentTarget).attr('clientId');
    				$( ".deleteyes" ).click(function() {
                        $(".spinner").show();
    					$('.birdmessage-center').removeClass("message-top-in");
    		   		   	
                        let requestHeaders = {
                            method: 'post',
                            headers: new Headers({
                                "Content-Type": "application/json"
                            })
                        };
                        var reqdata = new FormData();
                        reqdata.set("userId", userId);
                        reqdata.set("clientId", clientId);
                        SendRequest({
                            url: "resetpasswordfromsuperadmin",
                            body: reqdata,
                            sucessFunction: (response) => {
                                if (response.hasOwnProperty('error')) {
                                $(".spinner").hide();
                                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                                $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                                $('.birdmessagebuttons').removeClass('show');
                                $('.birdmessagebuttons').addClass('hide');
                                $('.birdmessage-center').addClass("message-top-in");
                                $('.details').removeClass('hide');
                                $('.birdmessage h2').empty().append('Error : ');
                                $('.birdmessage h2').append('<span class="errorcode">');
                                $('.birdmessage .errorcode').empty().append(response.errorCode);
                                $('.birdmessage-info').empty().text(response.error);
                                $('.details').addClass('show');
                                $('.messagedetails xmp').empty().append(response.errorTrace)
                                }else{
                               $("#licenserenewmodel").modal("hide");
                                $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                                $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                                $('.birdmessagebuttons').removeClass('show');
                                $('.birdmessagebuttons').addClass('hide');
                                $('.birdmessage-center').addClass("message-top-in");
                                $('.details').removeClass('show');
                                $('.details').addClass('hide');
                                $('.birdmessage h2').empty().append('Success');
                                $('.birdmessage-info').empty().text(getMessage('BirdSuccess43'));
                               setTimeout(function() {
                                $('.birdmessage-container .close').click();	
                                }, 2000);
                                  setTimeout(function(){
                                    window.location.reload(false);                  
                                  },2000)
                                }
                                 },
                              rejectFunction: () => { },
                              headers: requestHeaders
                            })
             
                    })
                    $( ".deleteno" ).click(function() {
                        $('.birdmessage-center').removeClass("message-top-in");
                       });          
    }
    editAccount=(e)=>{
        var val = $(e.currentTarget).val();
       var editaccount  = new EditAccount({clientId : val});
    }
    addLicense=(e)=>{
        var val = $(e.currentTarget).val();
    		   var userId = $(e.currentTarget).attr('userId');
				var addlicense = new AddLicenseView({clientId:val,userId:userId});
    }
    deleteAccount=(e)=>{
        var that=this;
    		$(".deleteyes").unbind( "click" );
			$(".deleteno").unbind( "click" );
			$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
			$('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
			$('.birdmessagebuttons').addClass('show');
			$('.birdmessage-center').addClass("message-top-in");
			$('.details').removeClass('show');
			$('.details').addClass('hide');
			$('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(getMessage('BirdWarning24'));
            var data = $(e.currentTarget).val();
			$( ".deleteyes" ).click(function() {
			$('.birdmessage-center').removeClass("message-top-in");
        	 var deleteaccountpage=new DeleteAccountForm({
           		   clientId : data
           	   });
			});
			$( ".deleteno" ).click(function() {
				 $('.birdmessage-center').removeClass("message-top-in");
				});
    }
    getClients(){
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "showAllRegisterdClients",
            queryString: "",
            sucessFunction: (response) => {
                this.setState({
                    response:response
                })
            },
            rejectFunction: () => { },
            headers: requestHeaders
    });
    }
    searchkeyup = (e) => {
        var $this = e.currentTarget;
            var valThis = $($this).val().toLowerCase();
            $('#accountstable tbody tr').each(function () {
                var text = $(this).find('td:first-child').text().toLowerCase();
                (text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
            });
    
        $("#accountstable").focus();
      }
    render() {
        $('a.boc-maxscreen-left-item.Accounts').addClass('showactive')
        $('.boc-admin-main .maxscreen-lft-filter-items a').not('.Accounts').removeClass('showactive');
        var that = this;     
       
        if(this.state.response !== null && this.state.response.length>0){
            _.each(this.state.response, function (val, index) {
				val.addlicense = <button class="btn btn-admin addlicense" type="button" value={val.clientId} userId ={val.userId} onClick={that.addLicense}><i class="fa fa-certificate"></i></button>;
				val.showlicense = <button class="btn btn-admin viewlicense" type="button" value={val.clientId} onClick = {that.viewLicense}><i class="fa fa-certificate"></i></button>;
				val.resetpassword = <button	class="btn btn-admin resetpasswordfromsuperadmin" id="resetpassword" type="button"
				value={val.userId} clientId={val.clientId} onClick={that.resetpasswordfromsuperadmin}>	<i class="fa fa-repeat"></i> </button>;
              _.each(that.state.features[0].features, function (feature) {
                if(feature.featureName=='edit_account'&&feature.allow==true){
                  val.editaccount = <button class="btn btn-admin  editaccount1 " type="button" value={val.clientId} onClick={that.editAccount} > <i class="fa fa-pencil" va></i></button>;
                }
                if(feature.featureName=='delete_account'&&feature.allow==true){
                  val.deleteaccount = <button class="btn btn-admin deleteaccountform" type="button" value={val.clientId} onClick={that.deleteAccount}> <i class="fa fa-close"></i></button>;  
                }
              });
            });
        }
        return (
            this.state.response == null ? <div><span>Loading....</span></div> :
        <AccountsTemplate accountsList={this.state.response} features={this.state.features} AddAccount={this.AddAccount} searchKeyup={this.searchkeyup}/>           );   
        }
}