import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'underscore';
import PubSub from 'pubsub-js'
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import * as TabInfo from "../../js/helpers/utils/tabinfo";
import SendRequest from '../../SendRequest';
import SSmartInsightsTemplate from "./smartinsights.jsx"
import SmartInsightHeaderView from "./smartinsightheaderview"
import ViewReportFilterView from '../viewreportfilter';
import ViewReportAddFilterView from "../viewreportaddfilterview";
import * as SmartCharts from './smartinsightcharts'
import SmartInsightsSimulationView from './smartinsightssimulationview'
import SmartInsightsSettingsView from './smartinsightssettingsview'
import SmartInsightDrillDown from './smartinsightsdrilldown'
import { getMessage } from '../../js/helpers/utils/BirdMessage';
import { lang } from "../../js/helpers/utils/languages/language";

const $ = window.$;
export default class SavedSamrtInsightsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.refreshchartsEve = PubSub.subscribe('refreshsavedcharts', this.refreshchartsEve.bind(this));
        this.smartinsightrightfiltersEvee = PubSub.subscribe('smartinsightrightfilters', this.smartinsightrightfiltersEve.bind(this));
        this.state.el = "smartinsightdatacontainer";
        var globalfilterArray = ChartInfo.functions.getFilterArray();
        this.state.viewreportCollection = this.props.viewreportCollection;
        this.state.isFromStoryBook = this.props.isFromStoryBook == undefined ? false : this.props.isFromStoryBook;
        this.state.tabid = this.props.tabid == undefined ? 0 : this.props.tabid;
        $('.spinner').show();
        this.state.measures = this.state.viewreportCollection.getMeasures();
        this.state.dimensions = this.state.viewreportCollection.getDimensions();
        this.state.reportId = this.props.reportId;
        this.state.datamodelId = this.props.datamodelId;
        this.state.schemaType = this.props.schemaType;
        this.state.globalfilterArray = globalfilterArray
        this.state.features = this.props.features;
        this.state.collection = this.props.viewreportCollection;;
        this.state.smartinsightCollection = "";
        var exclueValues = ChartInfo.functions.getSmartInsightsExcludeValues();
        var targetColumn = ChartInfo.functions.getSmartInsightsTargetVal();
        ChartInfo.functions.setSmartinsightSelectedColumns(this.state.dimensions);
        if(this.props.isFromTabClick){
            this.updateLocalStorage();
            $("#"+this.props.dummyelement).remove();
        }else{
            this.render();
        }

    }
    refreshchartsEve() {
        ReactDOM.unmountComponentAtNode(document.getElementById(this.state.el));
        this.render();
    }
    render() {
        $(".maxcustomparameterfiltersdropdown").hide();
        if (this.props.isFromStoryBook != undefined && this.props.isFromStoryBook) {
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $("#" + this.props.element).find('.smartinsightdatacontainer').append("<div className='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
            this.state.el = dynamicdiv;
            ReactDOM.render(<SSmartInsightsTemplate smartinsightoptions={this.smartinsightoptions} tabid={this.state.tabid} />, document.getElementById(this.state.el));
          //  $("#" + this.props.element).find('.smartinsightdatacontainer').html(document.getElementById(this.state.el));
            $(".rightsmartattributes").addClass('hide');
        }
        else {
            ReactDOM.render(<SSmartInsightsTemplate smartinsightoptions={this.smartinsightoptions} tabid={this.state.tabid} />, document.getElementById(this.state.el));
        }
        var that = this;
        setTimeout(function (e) {
            that.render1();
        }, 500);
        return (<div></div>)
    }
    render1() {
        var that = this;
        this.state.collection.setFilterObject();
        var model = that.state.collection.model;
        var mlreportdata = [];
        var detailsObj = {};
        var name = "";
        var folder = "";
        var desc = "";
        var savedFilterObj = [];
        var customparameterfilters;
        var title;
        that.state.smartInsightata = [];
        // this.collection.updateLocalStorage();
        var exclueValues;
        var targetColumn;
        var smartInsightDrillDown = new SmartInsightDrillDown({
            isFromSave: true
        });
        if (model.smartinsightreportdata != undefined && model.smartinsightreportdata != null && model.smartinsightreportdata.length > 0) {
            $(".spinner").show();
            var reportdata = JSON.parse(model.smartinsightreportdata);
            detailsObj = reportdata[1]["details"];
            name = detailsObj["name"];
            folder = detailsObj["folder"];
            desc = detailsObj["desc"];
            title = detailsObj["title"];
            savedFilterObj = reportdata[2]["filter"];
            var storyboardSmartInsightFilterArray = [];
            try{
              storyboardSmartInsightFilterArray = reportdata[8]["storyboardSmartInsightFilters"]!=undefined?reportdata[8]["storyboardSmartInsightFilters"]:[];
              if(reportdata[8]["storyboardSmartInsightFilters"]!=undefined && storyboardSmartInsightFilterArray.length>0){
                ChartInfo.functions.setIsStoryboardSmartInsight(true);
              }
            }catch(err){}
            ChartInfo.functions.setStoryboardSmartInsightFilterArray(storyboardSmartInsightFilterArray);
            sessionStorage.setItem("customFields", reportdata[4]["customFields"]);
            var parseddata = JSON.parse(model.smartinsight_model_data);
            try {
                if (parseddata.length > 0) {
                    that.state.smartInsightata = parseddata
                    if (reportdata[6] != undefined) {
                        var parseddata = JSON.parse(reportdata[6]["smartdata"])
                        exclueValues = parseddata.excludeColumn
                        targetColumn = parseddata.targeColumn
                    }
                } else {
                    that.state.smartInsightata = JSON.parse(parseddata.smarinsightData);
                    exclueValues = parseddata.excludeColumn
                    targetColumn = parseddata.targeColumn
                }
            } catch (Exception) {
                that.state.smartInsightata = JSON.parse(parseddata.smarinsightData);
                exclueValues = parseddata.excludeColumn
                targetColumn = parseddata.targeColumn
            }
            if (reportdata[5] != undefined) {
                var customParameter = reportdata[5]["customParameter"];
                ChartInfo.functions.setCustomParameter(JSON.parse(customParameter));
            }
            if (reportdata[7] != undefined) {
                customparameterfilters = reportdata[7]['customParameterFilters'];
            }
        }
        var isEmbeddedReport = false
        if (window.location.pathname.indexOf("embeddedsmartinsightreport") >= 0 || window.location.pathname.indexOf("embeddedreport") >= 0) {
            isEmbeddedReport = true;
        }
        if (that.state.viewReportHeader != null) {
            that.state.viewReportHeader = undefined;
        }
        if (this.props.isFromStoryBook == undefined || !(this.props.isFromStoryBook)) {
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $("#reportheader").empty();
            $("#reportheader").append("<div className='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
            that.state.viewReportHeader = new SmartInsightHeaderView({
                dynamicdiv: dynamicdiv,
                viewreportCollection: that.state.collection,
                features: that.state.features,
                reportId: that.props.reportId,
                schemaId: "",
                isPurgedReport: false,
                reportRequestFrom: "viewsmartinsight",
                isDirect: ChartInfo.functions.getIsDirect(),
                tempID: that.props.entities,
                title: title,
                name: name,
                folder: folder,
                desc: desc,
                datamodelId: that.props.datamodelId,
                isEmbeddedReport: isEmbeddedReport,
            });

        }
        $(".smartinsightintro-container").addClass("hide");
        that.state.viewreportfilterview = new ViewReportFilterView({
            features: that.state.features,
            tabid: that.state.tabid,
            isFromStoryBook: that.state.isFromStoryBook,
            scheduleid: that.props.scheduleid,
            multifact: that.state.multifact,
            facttables: that.state.facttables,
            multifactentities: that.state.multifactentities,
        });
        $(".sidetheme").empty();
        $(".sidetheme").append(<div class="panel-group rightfilter" id="filteraccordion"></div>);
        if (this.props.isFromStoryBook != undefined && this.props.isFromStoryBook) {
            $('.sidetheme #filteraccordion').attr("id", "filteraccordiontemp");
            $(".tab-pane#storyboardtab .rightfilter").attr('id', "filteraccordiontemp");
        }
        var viewReportAddFilterCollection = [];

        var columns = that.state.collection.getColumns();
        var columnsArray = JSON.parse(columns);
        _.each(columnsArray, function (column) {
            var viewreportaddfiltermodel = {};
            viewreportaddfiltermodel["tableName"] = column["tableName"];
            viewreportaddfiltermodel["columnDisplayType"] = column["tableDisplayType"];
            viewreportaddfiltermodel["tableDisplayType"] = column["tableDisplayType"];
            viewreportaddfiltermodel["columnName"] = column["columnName"];
            viewreportaddfiltermodel["columnDisplayName"] = column["columnDisplayName"];
            viewreportaddfiltermodel["priorityColumn"] = column["priorityColumn"];
            viewReportAddFilterCollection.push(viewreportaddfiltermodel);
        });

        if (that.state.viewReportAddFilterView != null) {
            that.state.viewReportAddFilterView = undefined;
        }
        that.state.viewReportAddFilterView = new ViewReportAddFilterView(
            {
                collection: viewReportAddFilterCollection,
                datamodelId: that.props.datamodelId,
                reportId: that.props.reportId,
                tabid: that.state.tabid,
                isFromStoryBook: that.state.isFromStoryBook,
                scheduleid: that.props.scheduleid,
                filterArray: that.state.globalfilterArray,
                viewreportCollection: that.state.collection,
                multifact: that.state.multifact,
                facttables: that.state.facttables,
                multifactentities: that.state.multifactentities,
            });
        if (that.state.globalfilterArray.length == 0) {
            $(".hidebutton").trigger(
                'click');
        } else {
            $(".showbutton").trigger('click');
            $('.instant-floating-filters').draggable({ cancel: '.col-lg-12.nopadding', containment: '#main>.boc-viewreport-section', handle: '.panel-heading' });
        }
        var smartInsightDataObj = {};
        var reportFilterObject = that.state.collection.getFilterObject();
        var sharedFilters;
        var filtervalues = "";
        var col_name = "";
        var filterObjects;
        smartInsightDataObj["excludeColumn"] = exclueValues;
        smartInsightDataObj["targeColumn"] = targetColumn;
        smartInsightDataObj["smarinsightData"] = JSON.stringify(that.state.smartInsightata);
        ChartInfo.functions.setSmartinsightDataObj(smartInsightDataObj);
        if (exclueValues != null) {
            ChartInfo.functions.setSmartInsightsExcludeValues(exclueValues);
        }
        if (this.props.isFromStoryBook == undefined || !(this.props.isFromStoryBook)) {
            $(".storyboardtab").removeClass('active')
            $(".smartinsights").addClass('active')
            $("#storyboardtab").removeClass('active')
            $("#smartinsights0").addClass('active')
            $('body').removeClass('rightpanelopen')
            $(".tab-pane#storyboardtab .rightfilter").attr('id', "filteraccordiontemp");
        } else {
            $(".rightsmartattributes").addClass('hide');
            $("#"+this.props.element).find(".storyboardtab").removeClass('active');
            $("#"+this.props.element).find(".smartinsights").addClass('active')
            $("#"+this.props.element).find("#storyboardtab").removeClass('active')
            $("#"+this.props.element).find("#smartinsights0").addClass('active')
            $("#"+this.props.element).find('body').removeClass('rightpanelopen')

        }
        //			$("#storyboardtab").empty()
        var yaxisname;
        var insightdata = [that.state.smartInsightata[1].insightQuality];

        var categories = that.state.smartInsightata[0].correlation[0];
        var correlatedcolumns = that.state.smartInsightata[0].correlation[1].data;
        var keyinfluencers = that.state.smartInsightata[1].keyinfluencers.data;
        var keyinfluencersCategories = that.state.smartInsightata[1].keyinfluencers.categories;
        var targetColumn = that.state.smartInsightata[1].targetcolumn;
        var modelName = that.state.smartInsightata[1].modelname;
        var frameName = that.state.smartInsightata[1].framename;

        that.state.modelName = modelName;
        that.state.frameName = frameName;
        var targetColumnOriginal = that.state.smartInsightata[1].targetColumnOriginal;

        var outlierCount = that.state.smartInsightata[1].outliercount;
        var correlatedCoumnsCount = that.state.smartInsightata[0].correaltionCount;
        var keyInfluencersCount = keyinfluencersCategories.length;

        var predictiondata = that.state.smartInsightata[1].predictiondata;
        var predictiondataLables = that.state.smartInsightata[1].predictionlabel
        let chiSquareData = that.state.smartInsightata[10].chiSquareData[1].data
        let chisquarecategories = that.state.smartInsightata[10].chiSquareData[0];

        _.each(that.state.smartInsightata[2], function (data) {
            $(".insightnlg[tabid='" + that.state.tabid + "'] .influenceschart .nlg-list").append(data);
            $(".insightnlg[tabid='" + that.state.tabid + "'] .influenceschart .nlg-list>li:last-child>span").prepend("&#9755; ")
        });
        $(".insightnlg[tabid='" + that.state.tabid + "'] .mltooltip").removeClass('hide');
        $(".insightnlg[tabid='" + that.state.tabid + "'] .mltooltip").attr("title", lang["smartsights.insightnlg-tooltip"]);
        /*Upate Initial Titles*/
        $(".whatinfluences[tabid='" + that.state.tabid + "']>span").text(targetColumn + " ?");
        $(".insightavg[tabid='" + that.state.tabid + "']>h3").html(targetColumn + " <span></span>");
        $(".insightavgsuggestion1[tabid='" + that.state.tabid + "']>h3").html("Key Influencers");
        $(".insightavgsuggestion2[tabid='" + that.state.tabid + "']>h3").html(targetColumn + " <span></span>");
        $(".insightnlg[tabid='" + that.state.tabid + "']>h3>span, .simulationwith>span, .expectedvaluecontainer[tabid='" + that.state.tabid + "']>h3>span").text(targetColumn);

        /*var findingsdata = [{
            name: 'Correlated Columns',
            y: correlatedCoumnsCount,
        }, {
            name: 'Key Influences',
            y: keyInfluencersCount
        }, {
            name: 'Outliers',
            y: outlierCount
        }]*/

        var jsondata = [predictiondata, { "sourceColumns": predictiondataLables }
        ]
        //\Sample Data Sets

        //DrawCharts
        var renderto;
        $("#smartinsights0[tabid='" + that.state.tabid + "']").show()
        renderto = $(".insightquality[tabid='" + that.state.tabid + "'] .overviewchart");
        SmartCharts.charts.drawSmartSolidgaugeChart(insightdata, renderto);
        $(".insightquality[tabid='" + that.state.tabid + "'] .mltooltip").removeClass('hide');
        $(".insightquality[tabid='" + that.state.tabid + "'] .mltooltip").attr("title", lang["smartsights.insightquality-tooltip"]);

        renderto = $(".mlchisquaretable .overviewchart");
        if (chiSquareData != undefined && chiSquareData != null && chisquarecategories != undefined && chisquarecategories.length > 0) {
            SmartCharts.charts.drawSmartBubbleChart(chiSquareData, chisquarecategories, renderto);
        }else if (chiSquareData != undefined && chiSquareData != null && chiSquareData[1] != undefined && chiSquareData[1].length > 0) {
            let chisquredata = chiSquareData[1];
            $(".mlchisquaretable .overviewchart").append("<span class='mlnodatamessage'>" + chisquredata[0] + "</span>")
        }
        else {
            $(".mlchisquaretable .overviewchart").append("<span class='mlnodatamessage'>" + getMessage('BirdInformation48') + "</span>");
        }
        $(".mlchisquaretable .mltooltip").removeClass('hide');
        $(".mlchisquaretable .mltooltip").attr("title", lang["smartsights.chisquare-tooltip"]);
        // renderto = $(".insightfindings[tabid='" + that.state.tabid + "'] .overviewchart");
        /*SmartCharts.charts.drawSmartPieChart(findingsdata, renderto);*/

        renderto = $(".insightcorrelatedcolumns[tabid='" + that.state.tabid + "'] .overviewchart");
        if (correlatedcolumns != undefined && correlatedcolumns != null && categories[0] != undefined && categories[0].length > 0) {
            SmartCharts.charts.drawSmartBubbleChart(correlatedcolumns, categories, renderto);
        }else if (correlatedcolumns != undefined && correlatedcolumns != null && categories[1] != undefined && categories[1].length > 0) {
            $(".insightcorrelatedcolumns[tabid='" + that.state.tabid + "'] .overviewchart").append("<span class='mlnodatamessage'>" + correlatedcolumns[0] + "</span>")
        }
        else {
            $(".insightcorrelatedcolumns[tabid='" + that.state.tabid + "'] .overviewchart").append("<span class='mlnodatamessage'>" + getMessage('BirdInformation49') + "</span>")
        }
        $(".insightcorrelatedcolumns[tabid='" + that.state.tabid + "'] .mltooltip").removeClass('hide');
        $(".insightcorrelatedcolumns[tabid='" + that.state.tabid + "'] .mltooltip").attr("title", lang["smartsights.correlated-tooltip"]);

        renderto = $(".keyinfluencers[tabid='" + that.state.tabid + "'] .influenceschart");
        SmartCharts.charts.drawSmartBar(keyinfluencers, keyinfluencersCategories, renderto, targetColumn, modelName, frameName, "", targetColumnOriginal, that.state.tabid, that.state.reportId, that.state.datamodelId, that.state.isFromStoryBook);
        $(".keyinfluencers .mltooltip").removeClass('hide');
        $(".keyinfluencers .mltooltip").attr("title", lang["smartsights.keyinfluencer-tooltip"]);
        
        renderto = $(".simulationselectioncontainer[tabid='" + that.state.tabid + "'] .simulationwaterfall");
        SmartCharts.charts.drawSmartSimulationWaterfall(that.state.smartInsightata[3].data, that.state.smartInsightata[3].data, renderto, targetColumn, modelName, frameName);
        _.each(jsondata[1].sourceColumns, function (i, j) {
            var  j=_.invert(i);
             _.each(j,function(val){
             $(".outliertable thead>tr").append("<th data-sortable='true' data-field='" + val + "' class='"+(i[val]==='number'?"align-right":"")+"'>" + val + "</th>");
             });
         });

        $(".outliertable[tabid='" + that.state.tabid + "']").bootstrapTable({ data: jsondata[0].data });
        if (that.state.smartInsightata[7] != undefined && !_.isEmpty(that.state.smartInsightata[7])) {
            var tempObj = decodeURIComponent(that.state.smartInsightata[7])
            if (typeof tempObj != 'string') {
                renderto = $(".insightavgsuggestion0[tabid='" + that.state.tabid + "'] .influenceschart");
                $(".insightavg[tabid='" + that.state.tabid + "']>h3").html(tempObj.yaxisDisplay + " and <span>" + tempObj.xaxisDisplay + "</span>");
                $(".insightavgsuggestion1[tabid='" + that.state.tabid + "'] .influenceschart").empty().removeAttr("data-highcharts-chart");
                $(".insightavgsuggestion1[tabid='" + that.state.tabid + "']>h3").html("Key Influencers");
                $(".insightavgsuggestion2[tabid='" + that.state.tabid + "'] .influenceschart").empty().removeAttr("data-highcharts-chart");
                $(".insightavgsuggestion2[tabid='" + that.state.tabid + "']>h3").html(tempObj.yaxisDisplay);
                smartInsightDrillDown.xaxis = tempObj.xaxis
                smartInsightDrillDown.yaxis = tempObj.yaxis
                smartInsightDrillDown.drawSmartAVGBar(tempObj.keyInfluencesdata[0].data, tempObj.keyInfluencesdata[0].avgValue, tempObj.categories, renderto, tempObj.yaxisname, tempObj.htmlnlgdata, tempObj.xaxisDisplay, tempObj.yaxisDisplay, that.state.tabid, that.state.reportId, that.state.datamodelId, that.state.isFromStoryBook);
            } else {
                var parsedTempObj = JSON.parse(tempObj);
                renderto = $(".insightavgsuggestion0[tabid='" + that.state.tabid + "'] .influenceschart");
                $(".insightavg[tabid='" + that.state.tabid + "']>h3").html(parsedTempObj[0].yaxisname + " and <span>" + parsedTempObj[0].xaxisDisplay + "</span>");
                $(".insightavgsuggestion1[tabid='" + that.state.tabid + "'] .influenceschart").empty().removeAttr("data-highcharts-chart");
                $(".insightavgsuggestion1[tabid='" + that.state.tabid + "']>h3").html("Key Influencers");
                $(".insightavgsuggestion2[tabid='" + that.state.tabid + "'] .influenceschart").empty().removeAttr("data-highcharts-chart");
                $(".insightavgsuggestion2[tabid='" + that.state.tabid + "']>h3").html(parsedTempObj.displayname);
                smartInsightDrillDown.xaxis = parsedTempObj[0].xaxis
                smartInsightDrillDown.yaxis = parsedTempObj[0].yaxisname
                parsedTempObj[0].categories = [];
                _.each(parsedTempObj[0].data, function (data) {
                    parsedTempObj[0].categories.push(data.name);
                })
                smartInsightDrillDown.drawSmartAVGBar(parsedTempObj[0].data, parsedTempObj[0].avgValue, parsedTempObj[0].categories, renderto, parsedTempObj[0].yaxisname, '', parsedTempObj[0].xaxisDisplay, parsedTempObj[0].yaxisname, that.state.tabid, that.state.reportId, that.state.datamodelId, that.state.isFromStoryBook);
            }
        }
        if (that.state.smartInsightata[8] != undefined && !_.isEmpty(that.state.smartInsightata[8])) {
            var tempObj = that.state.smartInsightata[8]
            renderto = $(".insightavgsuggestion1[tabid='" + that.state.tabid + "'] .influenceschart");
            if (typeof tempObj != 'string') {
                SmartCharts.charts.drawSmartAVGBar(tempObj.keyinfluencers, tempObj.keyinfluencersCategories, renderto, tempObj.yaxisname, tempObj.yaxisDisplay, tempObj.targetColumnOriginal, that.state.tabid, that.state.reportId, that.state.datamodelId, that.state.isFromStoryBook);
            } else {
                var parsedTempObj = JSON.parse(tempObj);
                var finalkeyInfluencer = parsedTempObj[1]
                sessionStorage.removeItem("smartinsightsfilter");
                sessionStorage.setItem("smartinsightsfilter", finalkeyInfluencer.filter);
                var parsedFilter = JSON.parse(finalkeyInfluencer.filter)
                $(".insightavgsuggestion1[tabid='" + that.state.tabid + "']  h3").html("Key Influencers for <span>" + finalkeyInfluencer.targetcolumn + "</span> with <span>" + parsedFilter[0]["columndisplayname"] + "</span> '<span>" + parsedFilter[0]["data"] + "</span>'");
                SmartCharts.charts.drawSmartAVGBar(finalkeyInfluencer.keyinfluencers.data, finalkeyInfluencer.keyinfluencers.categories, renderto, finalkeyInfluencer.targetcolumn, finalkeyInfluencer.targetcolumn, finalkeyInfluencer.targetColumnOriginal, that.state.tabid, that.state.reportId, that.state.datamodelId, that.state.isFromStoryBook);
            }
        }
        if (that.state.smartInsightata[9] != undefined && !_.isEmpty(that.state.smartInsightata[9])) {
            var tempObj = decodeURIComponent(that.state.smartInsightata[9])
            renderto = $(".insightavgsuggestion2[tabid='" + that.state.tabid + "'] .influenceschart");
            if (typeof tempObj != 'string') {
                $(".insightavgsuggestion2[tabid='" + that.state.tabid + "']>h3").html(tempObj.yaxisDisplay + " and <span>" + tempObj.xaxisDisplay + "</span>");
                smartInsightDrillDown.xaxis = tempObj.xaxis
                smartInsightDrillDown.yaxis = tempObj.yaxis
                smartInsightDrillDown.drawSmartAVGBar2(tempObj.keyInfluencesdata[0].data, tempObj.keyInfluencesdata[0].avgValue, tempObj.categories, renderto, tempObj.yaxisname, tempObj.yaxisDisplay);
            } else {
                var parsedTempObj = JSON.parse(tempObj);
                $(".insightavgsuggestion2[tabid='" + that.state.tabid + "']>h3").html(parsedTempObj[0].yaxisname + " and <span>" + parsedTempObj[0].xaxisDisplay + "</span>");
                smartInsightDrillDown.xaxis = parsedTempObj[0].xaxis
                smartInsightDrillDown.yaxis = parsedTempObj[0].yaxis
                parsedTempObj[0].categories = [];
                _.each(parsedTempObj[0].data, function (data) {
                    parsedTempObj[0].categories.push(data.name);
                })
                smartInsightDrillDown.drawSmartAVGBar2(parsedTempObj[0].data, parsedTempObj[0].avgValue, parsedTempObj[0].categories, renderto, parsedTempObj[0].yaxisname, parsedTempObj[0].yaxisname);
            }
        }
        if (ChartInfo.functions.getSmartInsightsTargetVal() == "" || ChartInfo.functions.getSmartInsightsTargetVal() == undefined) {
            ChartInfo.functions.setSmartInsightsTargetVal(that.state.smartInsightata[4].targetColumn);
        }
        ChartInfo.functions.setRdisplyColumnMap(JSON.stringify(that.state.smartInsightata[3].mapForGetDuplicateName));
        that.simulationsection();
        ChartInfo.functions.setFilterArray(reportFilterObject);
        _.each(reportFilterObject, function (filter, i) {
            if (filter != undefined) {
                if (col_name === filter["tablename"] + "." + filter["columnname"]) {
                } else {
                    col_name = filter["tablename"] + "." + filter["columnname"];
                    filterObjects = that.state.collection.getFilterObject();
                    that.state.viewreportfilterview.addfilteritem(filter["filterDisplayName"], filter["filterIgnore"], filter["tablename"], filter["columnname"], filter["columndisplayname"], filter["dataType"], filter["fromRange"], filter["toRange"], filterObjects, that.props.datamodelId, "", filter["daterange"], "", undefined, undefined, sharedFilters);
                    filtervalues = filtervalues + filter["columndisplayname"].replace(/([#;?&,%.+*~\':"!^$%[\]()<=>`|\/@])/g, "") + ",";
                }
            }
        });
        sessionStorage.setItem('applicablefilters', filtervalues);
        $(".spinner").hide();
        $(".spinner").removeClass("forceshow");
    }
    smartinsightoptions = (e) => {
        e.preventDefault();
        //$('.spinner').show();
        var that = this;
        var targetColumn = "";
        var isOpen = $(".rightsmartattributes").hasClass("open");
        var targetcolumn = ChartInfo.functions.getSmartInsightsTargetVal();
        if (isOpen) {
            $(".rightsmartattributes").removeClass("open");
        } else {
            //$(".rightsmartattributes").addClass("open");
            $(".smartinsightintro-container").removeClass("hide");
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $(".rightsmartattributes_container").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            var smartInsightsSettingsView = new SmartInsightsSettingsView({
                smartinsightoptions: that.smartinsightoptions,
                dynamicdiv: dynamicdiv,
                measures: that.state.measures,
                dimensions: that.state.dimensions,
                reportId: that.props.reportId,
                datamodelId: that.props.datamodelId,
                targetColumn: targetcolumn,
                totalRecords: that.state.smartInsightata[5].totalRecords,
                totalDBRecords: that.state.smartInsightata[5].totalDBRecords,
                schemaType: that.state.smartInsightata[5].schemaType,
                dataSource: that.state.smartInsightata[5].dataSource,
                tabid: that.state.tabid
            });
            $(".rightsmartattributes_container").html(document.getElementById(dynamicdiv));
            $(".rightsmartattributes").addClass("open");
            $(".smartinsightintro-container").addClass("hide");
            let isStoryboardSmartInsight = ChartInfo.functions.getIsStoryboardSmartInsight();
            if(isStoryboardSmartInsight){
                $(".smartyaxisattributes").addClass('disabled')
            }
        }
    }
    simulationsection() {
        var simulationdata = this.state.smartInsightata[3];
        var that = this;
        var today = new Date();
        var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
        $(".simulationcselections[tabid='" + that.state.tabid + "']").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
        var smartInsightsSimulationView = new SmartInsightsSimulationView({
            dynamicdiv: dynamicdiv,
            measures: that.state.measures,
            dimensions: that.state.dimensions,
            reportId: that.props.reportId,
            datamodelId: that.props.datamodelId,
            data: simulationdata,
            modelName: that.state.modelName,
            frameName: that.state.frameName,
            targetColumn: that.state.smartInsightata[4].targetColumn,
            isFromStoryBook: that.state.isFromStoryBook,
            tabid: that.state.tabid != undefined && that.state.tabid != '' ? that.state.tabid : 0,
            keyinfluencersList:that.state.smartInsightata[1].KeyInfluencerColumnsList,
        });
        $(".simulationcselections[tabid='" + that.state.tabid + "']").html(document.getElementById(dynamicdiv));

        /*Expected Value*/
        //	$(".expectedvalue[tabid='"+that.tabid+"']>span.expectedsymbol").html("+");
        //	$(".expectedvalue[tabid='"+that.tabid+"']>span.expectednumber").html("350");
    }
    smartinsightrightfiltersEve(msg, data) {
        this.smartinsightrightfilters(data);
    }
    smartinsightrightfilters(e) {
        if (this.state.tabid != TabInfo.functions.getCurrentTabId()) {
			return;
		}
        var flag = false;
        var filterAccordianLength = $('.acc-content').length;
        for (var i = 0; i < filterAccordianLength; i++) {
            var $this = $('.acc-content').eq(i);
            var fromRangeVal = $($this).find('.irs-min').text();
            var toRangeVal = $($this).find('.irs-max').text();
            var greaterThanValue = $($this).find('#greaterthanvalue').val();
            var lessThanValue = $($this).find('#lessthanvalue').val();
            $($this).find('.filtergreaterthanvalue.filter-error').addClass("hide");
            $($this).find('.filterlessthanvalue.filter-error').addClass("hide");
            if (fromRangeVal != undefined && toRangeVal != undefined && $($this).attr('id') != undefined) {
                $('.filteritem-container .acc-content[data-id="' + $($this).attr('id') + '"] .filteredvalues').text(fromRangeVal + " - " + toRangeVal).attr("title", fromRangeVal + " - " + toRangeVal);
            }
        }
        if (flag) {
            return false;
        }
        sessionStorage.setItem("changesdone", 1);
        var intelligentsearchwindow = $(".is-container").css("display");
        var intelligentsearchvalue = $("#birdintelligentsearch").val();

        $('.gridspinner').show();
        $('.trend-stats-container').remove();
        $('.viewdatafromchart').addClass('hide');
        $('.viewchartsummary').addClass('hide');
        $('.addInstanceFilterData').empty();
        if ($('.addInstanceFilterData').text().trim().length == 0) {
            $('.instant-floating-filters').hide();
        }
        ChartInfo.functions.setisFilterModalOpen(true);
        sessionStorage.removeItem("instantExcludeFilters");
        sessionStorage.removeItem("instantIncludeFilters");
        sessionStorage.removeItem("instantExcludeCustomFilters");
        ChartInfo.functions.setInstanceIncludeFilterData("");
        ChartInfo.functions.setInstanceFilterData("");
        ChartInfo.functions.setIncludeFilterData("");
        ChartInfo.functions.setInstanceFilterData("");

        var bool = $('.isexcludenulls').is(":checked")
        ChartInfo.functions.setIsExcludeNulls(bool);
        var filterArray = ChartInfo.functions.getFilters(false);
        //Updated Filtered Data in UI
        var filterCustomParametersArray = ChartInfo.functions.getCustomParametersFilters(),
            normalFilters = ChartInfo.functions.getFilters(false),
            totalfilterArray = $.merge($.merge([], normalFilters), filterCustomParametersArray);
        if (totalfilterArray != null && totalfilterArray != undefined && totalfilterArray != "" && totalfilterArray.length != 0) {
            _.each(totalfilterArray, function (filter, index) {
                if (filter.dataType != undefined) {
                    var filtercolumndisplayname = filter.columndisplayname.replace(/([;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "");
                    if (filter.dataType == "string") {
                        if (filter.customfield == undefined) {
                            var filtereddatatext = filter["data"].replace(/\@@/g, ', ');
                        } else {
                            var filtereddatatext = filter.customfield.sqlcondition.sqldata.replace(/\@@/g, ', ')
                        }
                        var TotalFilterItems = $(".filtervalue[data-name='" + filtercolumndisplayname + "']").length;
                        var unCheckedFilterItems = $(".filtervalue[data-name='" + filtercolumndisplayname + "']:not(:checked)").length;
                        if (unCheckedFilterItems == 0) {
                            $('.filteritem-container .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text("All").attr("title", "All");
                        }
                        //  else if (TotalFilterItems <= 5 || (unCheckedFilterItems > 5 && TotalFilterItems > 5)) {
                        //     $('.filteritem-container .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text(filtereddatatext).attr("title", filtereddatatext);
                        // } else if (unCheckedFilterItems <= 5) {
                        //     var unCheckedItems = [];
                        //     _.each($(".filtervalue[data-name='" + filtercolumndisplayname + "']:not(:checked)"), function (i) {
                        //         unCheckedItems.push($(i).attr("data-value"));
                        //     });
                        //     unCheckedItems = unCheckedItems.join(", ")
                        //     $('.filteritem-container .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text("Excluding : " + unCheckedItems + "").attr("title", "Excluding : " + unCheckedItems + "");
                        // }
                        else {
                            $('.filteritem-container .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text(filtereddatatext).attr("title", filtereddatatext);
                        } 
                    } else if (filter.dataType == "number") {
                        var fromRange = filter.fromRange,
                            toRange = filter.toRange,
                            customRange = filter.customfiltervalue;
                        if (fromRange == undefined && toRange == undefined) {
                            fromRange = filter.filtergreaterthanvalue;
                            toRange = filter.filterlessthanvalue;
                        }
                        if (customRange == undefined || customRange === "") {
                            $('.filteritem-container .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text(fromRange + " - " + toRange).attr("title", fromRange + " - " + toRange);
                        }
                    } else if (filter.dataType == "date") {
                        var fromDateRange = filter.fromRange,
                            toDateRange = filter.toRange,
                            dateRange = filter.daterange;
                        var dates = $('.daterange-center[displayname="' + filter.columndisplayname + '"] span').text();
                        //if(customRange== undefined || customRange===""){
                        $('.filteritem-container .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text(dates).attr("title", dates);
                        //}
                    }
                } else if (filter.parametername != undefined && filter.parametername != "") {
                    if (filter.valueType == "all" || filter.valueType == "range") {
                        $('.filteritem-container .acc-content[data-id="' + filter.parametername + '"] .filteredvalues').text(filter.parametercurrentvalue).attr("title", filter.parametercurrentvalue);
                    } else if (filter.valueType == "list") {
                        var selectedplistvalue = $('.filtercustomparamerelist[data-id="acc' + filter.parametername + '"] select').val()
                        $('.filteritem-container .acc-content[data-id="' + filter.parametername + '"] .filteredvalues').text(selectedplistvalue).attr("title", selectedplistvalue);
                    }
                }

                //Update Filter Display Name
                if (filter.filterDisplayName != null && filter.filterDisplayName != undefined) {
                    var targetName = filter.columndisplayname;
                    if (filter.parametername != undefined && filter.parametername != "") {
                        targetName = filter.parametername;
                    }
                    if (filter.filterDisplayName == "") {
                        $(".acc-content[data-id='" + targetName.replace(/([;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "") + "'] h4.panelboc-title").text(filter.columndisplayname).attr("title", filter.columndisplayname);
                        $(".itemname[data-name='" + targetName.replace(/([;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "") + "']").val(filter.columndisplayname);
                    } else {
                        $(".acc-content[data-id='" + targetName.replace(/([;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "") + "'] h4.panelboc-title").text(filter.filterDisplayName).attr("title", filter.filterDisplayName);
                    }
                }
            });
        }

        if (intelligentsearchwindow != "block") {
            var targetcolumn = ChartInfo.functions.getSmartInsightsTargetVal();
            var exclueValues = ChartInfo.functions.getSmartInsightsExcludeValues();
            var that = this;
            $(".smartinsightintro-container").removeClass("hide");
            var mainFilter = ChartInfo.functions.getFilterArray();
            var storyboardSmartInsightFilterArray = ChartInfo.functions.getStoryboardSmartInsightFilterArray();
            mainFilter = mainFilter.concat(storyboardSmartInsightFilterArray);
            let requestHeaders = {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };

            var reqdata = new FormData();
            reqdata.set("datamodelId", that.state.datamodelId);
            reqdata.set("schemaType", that.state.schemaType);
            reqdata.set("globalfilterArray", JSON.stringify(mainFilter));
            reqdata.set("reportId", that.state.reportId != undefined && that.state.reportId != null ? that.state.reportId : "");
            reqdata.set("keyInfluencers", false);
            reqdata.set("excludecolumn", exclueValues);
            reqdata.set("targetColumn", targetcolumn);
            reqdata.set("mapForGetDuplicateName", null);
            reqdata.set("isFromRunModel", true);
            reqdata.set("selectedColumns", JSON.stringify(that.state.collection.getColumns()));
            reqdata.set("customFields",sessionStorage.getItem("customFields"))
            SendRequest({
                url: "getsmartinsights",
                body: reqdata,
                sucessFunction: (response) => {
                    that.state.smartinsightCollection = response;
                    $(".smartinsightintro-container").addClass("hide");
                    that.renderExistingData(that.state.smartinsightCollection);
                    var smartInsightDataObj = {};
                    smartInsightDataObj["excludeColumn"] = exclueValues;
                    smartInsightDataObj["targeColumn"] = targetcolumn;
                    smartInsightDataObj["smarinsightData"] = JSON.stringify(that.state.smartinsightCollection);
                    ChartInfo.functions.setSmartinsightDataObj(smartInsightDataObj);
                    $('.gridspinner').hide();
                },
                rejectFunction: this.reportDataError,
                headers: requestHeaders
            });
        } else {
            $('.gridspinner').hide();
            sessionStorage.setItem("intelligentfilter", 1);
            if (intelligentsearchvalue != "") {
                $(".is-container #clicksearchbutton").click();
            }
        }
        $(".quickfiltercontainer").empty();
        $(".pie-menu").remove();
        $(".fullscreensample").removeClass("nopie");
        /*$(".instant-floating-filters.rightpane").removeClass("changesdone");
        $("#rightfiltersapplys").removeClass("btn-highlight");*/
    }
    renderExistingData(collection) {
       ReactDOM.unmountComponentAtNode(document.getElementById(this.state.el));
        ReactDOM.render(<SSmartInsightsTemplate smartinsightoptions={this.smartinsightoptions} tabid={this.state.tabid} />, document.getElementById(this.state.el));
        var that = this
        var yaxisname;
        if (collection.success == false || collection.error != null) {
            $(".spinner").hide();
            $(".gridspinner").hide();
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('hide');
            $('.birdmessage h2').empty().append('Error : ');
            $('.birdmessage h2').append('<span class="errorcode">');
            $('.birdmessage .errorcode').empty().append(collection.errorCode);
            $('.birdmessage-info').empty().text(collection.error);
            $('.details').addClass('show');
            $('.messagedetails xmp').empty().append(collection.errorTrace)
        } else {
            var insightdata = [collection[1].insightQuality];

            var categories = collection[0].correlation[0];
            var correlatedcolumns = collection[0].correlation[1].data;
            var keyinfluencers = collection[1].keyinfluencers.data;
            var keyinfluencersCategories = collection[1].keyinfluencers.categories;
            var targetColumn = collection[1].targetcolumn;
            var modelName = collection[1].modelname;
            var frameName = collection[1].framename;

            that.state.modelName = modelName;
            that.state.frameName = frameName;
            var targetColumnOriginal = collection[1].targetColumnOriginal;

            var outlierCount = collection[1].outliercount;
            var correlatedCoumnsCount = collection[0].correaltionCount;
            var keyInfluencersCount = keyinfluencersCategories.length;

            var predictiondata = collection[1].predictiondata;
            var predictiondataLables = collection[1].predictionlabel
            let chiSquareData = collection[10].chiSquareData[1].data;
            let chisquarecategories = collection[10].chiSquareData[0];

            _.each(collection[2], function (data) {
                $(".insightnlg[tabid='" + that.state.tabid + "'] .influenceschart .nlg-list").append(data);
                $(".insightnlg[tabid='" + that.state.tabid + "'] .influenceschart .nlg-list>li:last-child>span").prepend("&#9755; ")
            });

            /*Upate Initial Titles*/
            $(".whatinfluences[tabid='" + that.state.tabid + "']>span").text(targetColumn + " ?");
            $(".insightavg[tabid='" + that.state.tabid + "']>h3").html(targetColumn + " <span></span>");
            $(".insightavgsuggestion1[tabid='" + that.state.tabid + "']>h3").html("Key Influencers");
            $(".insightavgsuggestion2[tabid='" + that.state.tabid + "']>h3").html(targetColumn + " <span></span>");
            $(".insightnlg[tabid='" + that.state.tabid + "']>h3>span, .simulationwith>span, .expectedvaluecontainer>h3>span").text(targetColumn);

            /*var findingsdata = [{
                name: 'Correlated Columns',
                y: correlatedCoumnsCount,
            }, {
                name: 'Key Influences',
                y: keyInfluencersCount
            }, {
                name: 'Outliers',
                y: outlierCount
            }]*/

            var jsondata = [predictiondata, { "sourceColumns": predictiondataLables }
            ]
            //\Sample Data Sets

            //DrawCharts
            var renderto;
            $("#smartinsights0[tabid='" + that.state.tabid + "']").show()
            renderto = $(".insightquality[tabid='" + that.state.tabid + "'] .overviewchart");
            SmartCharts.charts.drawSmartSolidgaugeChart(insightdata, renderto);

            /*renderto = $(".insightfindings[tabid='" + that.state.tabid + "'] .overviewchart");
            SmartCharts.charts.drawSmartPieChart(findingsdata, renderto);*/
            renderto = $(".mlchisquaretable .overviewchart");
            SmartCharts.charts.drawSmartBubbleChart(chiSquareData, chisquarecategories, renderto);

            renderto = $(".insightcorrelatedcolumns[tabid='" + that.state.tabid + "'] .overviewchart");
            if (correlatedcolumns != undefined && correlatedcolumns != null && categories[0] != undefined && categories[0].length > 0) {
                SmartCharts.charts.drawSmartBubbleChart(correlatedcolumns, categories, renderto);
            }else if (correlatedcolumns != undefined && correlatedcolumns != null && categories[1] != undefined && categories[1].length > 0) {
                    $(".insightcorrelatedcolumns[tabid='" + that.state.tabid + "'] .overviewchart").append("<span class='mlnodatamessage'>" + correlatedcolumns[0] + "</span>")
            }
            else {
                    $(".insightcorrelatedcolumns[tabid='" + that.state.tabid + "'] .overviewchart").append("<span class='mlnodatamessage'>" + getMessage('BirdInformation49') + "</span>")
            }

            renderto = $(".keyinfluencers[tabid='" + that.state.tabid + "'] .influenceschart");
            SmartCharts.charts.drawSmartBar(keyinfluencers, keyinfluencersCategories, renderto, targetColumn, modelName, frameName, "", targetColumnOriginal, that.state.tabid, that.state.reportId, that.state.datamodelId, that.state.isFromStoryBook);

            renderto = $(".simulationselectioncontainer[tabid='" + that.state.tabid + "'] .simulationwaterfall");
            SmartCharts.charts.drawSmartSimulationWaterfall(collection[3].data, collection[3].data, renderto, targetColumn, modelName, frameName);
            _.each(jsondata[1].sourceColumns, function (i, j) {
                var  j=_.invert(i);
                 _.each(j,function(val){
                 $(".outliertable thead>tr").append("<th data-sortable='true' data-field='" + val + "' class='"+(i[val]==='number'?"align-right":"")+"'>" + val + "</th>");
                 });
             });

            $(".outliertable[tabid='" + that.state.tabid + "']").bootstrapTable({ data: jsondata[0].data });
            if (ChartInfo.functions.getSmartInsightsTargetVal() == "" || ChartInfo.functions.getSmartInsightsTargetVal() == undefined) {
                ChartInfo.functions.setSmartInsightsTargetVal(collection[4].targetColumn);
            }
            ChartInfo.functions.setRdisplyColumnMap(JSON.stringify(collection[3].mapForGetDuplicateName));
            that.simulationExistingData(collection);
        }
    }
    simulationExistingData(collection) {
        var simulationdata = collection[3];
        var that = this;
        var today = new Date();
        var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
        $(".simulationcselections[tabid='" + that.state.tabid + "']").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
        var smartInsightsSimulationView = new SmartInsightsSimulationView({
            dynamicdiv: dynamicdiv,
            measures: that.state.measures,
            dimensions: that.state.dimensions,
            reportId: that.props.reportId,
            datamodelId: that.props.datamodelId,
            data: simulationdata,
            modelName: that.state.modelName,
            frameName: that.state.frameName,
            targetColumn: collection[4].targetColumn,
            tabid: that.state.tabid != undefined && that.state.tabid != '' ? that.state.tabid : 0,
            keyinfluencersList:collection[1].KeyInfluencerColumnsList,
        });
        $(".simulationcselections[tabid='" + that.state.tabid + "']").html(document.getElementById(dynamicdiv));

        /*Expected Value*/
        //   $(".expectedvalue[tabid='"+that.tabid+"']>span.expectedsymbol").html("+");
        //  $(".expectedvalue[tabid='"+that.tabid+"']>span.expectednumber").html("350");

    }
    updateLocalStorage(){
        var that = this;
        var exclueValues;
        var targetColumn;
        var model = that.state.collection.model;
        if (model.smartinsightreportdata != undefined && model.smartinsightreportdata != null && model.smartinsightreportdata.length > 0) {
            $(".spinner").hide();
            var reportdata = JSON.parse(model.smartinsightreportdata);
            var parseddata = JSON.parse(model.smartinsight_model_data);
            try {
                if (parseddata.length > 0) {
                    that.state.smartInsightata = parseddata
                    if (reportdata[6] != undefined) {
                        var parseddata = JSON.parse(reportdata[6]["smartdata"])
                        exclueValues = parseddata.excludeColumn
                        targetColumn = parseddata.targeColumn
                    }
                } else {
                    that.state.smartInsightata = JSON.parse(parseddata.smarinsightData);
                    exclueValues = parseddata.excludeColumn
                    targetColumn = parseddata.targeColumn
                }
            } catch (Exception) {
                that.state.smartInsightata = JSON.parse(parseddata.smarinsightData);
                exclueValues = parseddata.excludeColumn
                targetColumn = parseddata.targeColumn
            }
            var smartInsightDataObj = {};
            smartInsightDataObj["excludeColumn"] = exclueValues;
            smartInsightDataObj["targeColumn"] = targetColumn;
            smartInsightDataObj["smarinsightData"] = JSON.stringify(that.state.smartInsightata);
            ChartInfo.functions.setSmartinsightDataObj(smartInsightDataObj);
            if (exclueValues != null) {
                ChartInfo.functions.setSmartInsightsExcludeValues(exclueValues);
            }
        }
    }
}