import React from 'react';
import ReactDOM from 'react-dom';
import { lang } from "../../js/helpers/utils/languages/language";
const $ = window.$;

export default class SummaryTableSettingsModalHtml extends React.Component {
    render() {
        var chartnumber = this.props.chartnumber;
        return (
            <div className={`modal fade in summarytable_settingmodal${chartnumber}`} id="pivotpagesettingsmodal" tabIndex="-1" role="dialog" aria-labelledby="pivotpagesettingsmodal" aria-hidden="true" style={{ display: "block" }}>
                <div class="modal-backdrop fade in"></div>
                <div className="modal-dialog modal-sm modal-sm-400 modal-dialog-sm-center modal-dialog-center" style={{ zIndex: "1050" }}>
                    <div className="modal-content">
                        <div className="modal-header boc-modal-hf-padding">
                            <button type="button" className="close white" id="summarytableclose" aria-hidden="true" data-number={chartnumber} data-dismiss="modal" onClick={this.close}>&times;</button>
                            <h4 className="modal-title" id="mySmallModalLabel"><i className="fa fa-wrench fa-flip-horizontal"></i> {lang["storyboard.sumarysettings"]}</h4>
                        </div>
                        <div className="modal-body scrollset">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-xs-12  marg-top-10 marg-bot-10">
                                        <div className="modal-height-fix">
                                            <legend className="subhead marg-top-10">
                                            {lang["storyboard.aggrcolums"]}
                                                <button className="pull-right btn btn-transparent tooltip-left dropdown-toggle addsummarytable-aggregatedvalues" data-num={chartnumber} tab-id="0" tab-name="" onClick={(e) => { this.props.valuesdropdown(e) }} data-tooltip={lang["storyboard.add"]} id="dropdownlegendsummary" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-plus-circle"></i></button>
                                                <ul className="list-group dropdown-menu dropdown-inline marg-top-10-imp summarytable-valuesList" aria-labelledby="dropdownxaxis" role="menu" ></ul>
                                            </legend>
                                            <div className="marg-top-10">
                                                <ul className="list-group summarytable-value" id="sortablepivotcolumns"></ul>
                                                <span className='addpivotcolumns-error help-block marg-left-10 hide'>{lang["storyboard.oneaggcol"]}</span>
                                            </div>
                                            <legend className="subhead">
                                            {lang["storyboard.textcolums"]}
                                                <button className="pull-right btn btn-transparent dropdown-toggle tooltip-left addsummarytable-aggregatedrows" onClick={(e) => { this.props.columnsdropdown(e) }} data-num={chartnumber} tab-id="0" tab-name="" data-tooltip={lang["storyboard.add"]} id="dropdownxaxissummary" aria-expanded="false" data-toggle="dropdown"><i className="fa fa-plus-circle"></i></button>
                                                <ul className="list-group dropdown-menu dropdown-inline marg-top-10-imp summarytable-columnsList" aria-labelledby="dropdownxaxis" role="menu" ></ul>
                                            </legend>
                                            <div className="marg-top-10">
                                                <ul className="list-group summarytable-row ui-sortable" id="sortablepivotrows"></ul>
                                                <span className='addpivotrows-error help-block marg-left-10 hide'>{lang["storyboard.onetextcol"]}</span>
                                            </div>
                                            <legend className="subhead">
                                            {lang["storyboard.grpby"]}
                                                <button className="pull-right btn btn-transparent dropdown-toggle tooltip-left addsummarytable-groupby" onClick={(e) => { this.props.groupbydropdown(e) }} data-num={chartnumber} tab-id="0" tab-name="" data-tooltip={lang["storyboard.add"]} id="dropdownyaxissummary" aria-expanded="false" data-toggle="dropdown"><i className="fa fa-plus-circle"></i></button>
                                                <ul className="list-group dropdown-menu dropdown-inline marg-top-10-imp summarytable-groupbyList" aria-labelledby="dropdownyaxis" role="menu"></ul>
                                            </legend>
                                            <div className="marg-top-10">
                                                <ul className="list-group summarytable-groupby" id="sortablepivotvalues"></ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer boc-modal-hf-padding">
                            <button type="button" className="btn btn-bird" id="btn-birdcancelsummary" data-dismiss="modal" aria-hidden="true" onClick={this.close}><i className="fa fa-times"></i> {lang["storyboard.cancelbtn"]}</button>
                            <button type="button" className="btn btn-bird applysummarytablesettingchanges" id="applysummarytablesettingbutton" data-num={chartnumber} tab-id="0" tab-name="" aria-hidden="true" onClick={this.props.applyPivotChanges}><i className="fa fa-check"></i> {lang["storyboard.applybtn"]}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    close = () => {
        $("body").removeClass("modal-open");
        ReactDOM.unmountComponentAtNode(document.querySelector('.append_summarytablemodal'+this.props.chartnumber));
    }
}