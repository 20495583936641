import React from 'react';
import SendRequest from '../../../SendRequest';
import NewMailConfigView from './NewMailConfigView';
import {getMessage} from '../../../js/helpers/utils/BirdMessage';
const $ = window.$;

export default class AddMailConfigView extends React.Component{
    constructor(props){
        super(props);
        this.createMailConfig();
    }
    createMailConfig=()=>{
        var addMailConfigFormData = $(".newmailconfigform").serializeArray();
        var mailConfigModelData = {};
        $.each(addMailConfigFormData, function(index, object) {
            mailConfigModelData[object.name] = object.value;
        });
        mailConfigModelData["otp"]=$("#mailconfig-otp").val();
        let requestHeaders = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        }; 
            var reqdata = new FormData();
            reqdata.set("smtpurl", mailConfigModelData.smtpurl);
            reqdata.set("portnumber", mailConfigModelData.portnumber);
            reqdata.set("mailconfigemail", mailConfigModelData.email);
            reqdata.set("password", mailConfigModelData.password);
            reqdata.set("mailAuthenticate", mailConfigModelData.mailauthenticate);
            reqdata.set("connectiontype", mailConfigModelData.mailconnectiontype);
            reqdata.set("otp", mailConfigModelData.otp);
            SendRequest({
                url: "savemailconfigdata",
                body: reqdata,
                sucessFunction: (response) => {
                    this.render(response);
                    if(response.status=="fail"||response.status=="exists"){
                        $('.spinner').hide();
                    }
                },
                rejectFunction:() => { },
                headers: requestHeaders

            });
            
    }
    render(response){
        if(response.status=='success'){
            $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
            $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Success');
            $('.birdmessage-info').empty().text(getMessage('BirdSuccess24'));
            setTimeout(function() {
                $('.birdmessage-container .close').click();	
                }, 2000);
                setTimeout(function() {
                    window.location.reload(false);
                      }, 2000);
            
        }else if(response.status=="fail"||response.status=="exists"){
                $(".spinner").hide();
                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('hide');
                $('.birdmessage h2').empty().append('Error : ');
                $('.birdmessage h2').append('<span class="errorcode">');
                $('.birdmessage-info').empty().text(getMessage("BirdError11"));
                $('.details').addClass('show');
                setTimeout(function() {
                        $('.birderror').remove();	
                    }, 3000);
        }
        return(<div></div>);
    }
}