import React from "react";
import { lang } from "../../js/helpers/utils/languages/language";
const $ =window.$;
export default class ReportManagementTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {          
        };
    }
    render() {

        var that  = this.props;
        return (
            <div className="container-fluid" id="reportmanagementdiv">
                <div className="row">
                    <h1 className="boc-main-title">
                        <i className="fa fa-bar-chart fa-admin-sm"></i>{lang["admin.reports.title"]}
		            </h1>
                    <div className="marg-top-10">
                        <div className="clear"></div>
                        <div className="container-fluid othercontainer ldapcontainer reportmanagementcontainer" >
                            <div className="row in-back">
                                <div className="row-same-height-inner">
                                    <div className="col-xs-4 col-xs-height in-panes leftarea" >
                                    <div class="pull-left search marg-top-10 marg-left-10">
                                      <input class="form-control searchreportmanagementtable" onKeyUp={this.props.searchKeyup} type="text" placeholder={lang["admin.reports.search"]} value={this.props.search} />
                                    </div>
                                    <div id = "reportmanagementtable"></div>
                                        <div className="clear" classNamess="icontent"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}