import React from 'react';
import { lang } from "../js/helpers/utils/languages/language";
export default class LogfileConnectionHtml extends React.Component {
    componentDidMount(){
        console.log(this.props.schemaDisplayName+"data");
    }
    render() {
        var loglocation;
        if(this.props.uploadtype==="log") {
            loglocation=<>
            <label htmlFor="logfilelocation" className="control-label marg-righ-10" style={{fontSize:"15px",fontWeight:"400"}}>
               <input type="radio" className="logfilemethod" id="logfilelocation" name="logfilelocation" value="browsefile" defaultChecked onClick={this.props.logfilemethod} /> {lang["datahub.browsfile"]}
           </label>
            <label htmlFor="logftplocation" className="control-label marg-righ-10 disabled" style={{fontSize:"15px",fontWeight:"400"}}>
               <input type="radio" className="logfilemethod disabled" id="logftplocation" name="logfilelocation" value="ftp" onClick={this.props.logfilemethod} /> {lang["datahub.Ftp"]}
           </label>
           <label htmlFor="logsftplocation" className="control-label marg-righ-10 disabled" style={{fontSize:"15px",fontWeight:"400"}}>
               <input type="radio" className="logfilemethod disabled" id="logsftplocation" name="logfilelocation" value="sftp" onClick={this.props.logfilemethod} /> {lang["datahub.Sftp"]}
           </label></>
        }else if(this.props.uploadtype==="log_ftp") {
            loglocation=<>
                <label htmlFor="logfilelocation" className="control-label marg-righ-10 disabled" style={{fontSize:"15px",fontWeight:"400"}}>
                   <input type="radio" className="logfilemethod disabled" id="logfilelocation" name="logfilelocation" value="browsefile" onClick={this.props.logfilemethod} /> {lang["datahub.browsfile"]}
                </label>
                <label htmlFor="logftplocation" className="control-label marg-righ-10" style={{fontSize:"15px",fontWeight:"400"}}>
                   <input type="radio" className="logfilemethod" id="logftplocation" name="logfilelocation" value="ftp" defaultChecked onClick={this.props.logfilemethod} /> {lang["datahub.Ftp"]}
               </label>
               <label htmlFor="logsftplocation" className="control-label marg-righ-10 disabled" style={{fontSize:"15px",fontWeight:"400"}}>
                   <input type="radio" className="logfilemethod disabled" id="logsftplocation" name="logfilelocation" value="sftp" onClick={this.props.logfilemethod} /> {lang["datahub.Sftp"]}
               </label></>
            }else{
                loglocation=<>
                <label htmlFor="logfilelocation" className="control-label marg-righ-10 disabled" style={{fontSize:"15px",fontWeight:"400"}}>
				<input type="radio" className="logfilemethod disabled" id="logfilelocation" name="logfilelocation" value="browsefile" checked="checked" onClick={this.props.logfilemethod} /> {lang["datahub.browsfile"]}
				</label>
				<label htmlFor="logftplocation" className="control-label marg-righ-10 disabled" style={{fontSize:"15px",fontWeight:"400"}}>
				<input type="radio" className="logfilemethod disabled" id="logftplocation" name="logfilelocation" value="ftp" onClick={this.props.logfilemethod} /> {lang["datahub.Ftp"]}
				</label>
				<label htmlFor="logsftplocation" className="control-label marg-righ-10" style={{fontSize:"15px",fontWeight:"400"}}>
				<input type="radio" className="logfilemethod" id="logsftplocation" name="logfilelocation" value="sftp" defaultChecked onClick={this.props.logfilemethod} />{lang["datahub.Sftp"]}
				</label>
                </>
            }
        return (
            <React.Fragment>
                {(this.props.isFromEditFlatFiles === true) ? (
                    <React.Fragment>
                        <div className="modal-header boc-modal-hf-padding">
                            <h4 className="modal-title">
                                <i className="fa fa-link marg-righ-10"></i>{lang["datahub.filestitle"]}
                            </h4>
                        </div>
                        <div className="modal-body">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-xs-12">
                                        {/* <!-- <h3>Connect MySQL</h3> --> */}
                                        <form className="form-horizontal marg-top-10">
                                            <div className="form-group form-group-sm">
                                                <label htmlFor="" className="col-sm-3 control-label ">
                                                    <img className="pull-right" src={require(`../images/birdimages/${this.props.schemaName.toLowerCase()}.png`)}/>
                                                </label>
                                                <div className="col-sm-6">
                                                    <h3>{lang["datahub.upload"]} {this.props.schemaName} {lang["datahub.file"]}</h3>
                                                </div>
                                            </div>
                                            <div className="form-group form-group-sm required">
                                                <label htmlFor="" className="col-sm-3 control-label ">{lang["datahub.cnctnname"]}</label>
                                                <div className="col-sm-6">
                                                    <input type="text" defaultValue={this.props.schemaName} className="form-control logconnectionname" placeholder="" />
                                                    <span id="logconnectionname" className="help-block connectionnameerror hide fa-modal-alert text-center">{lang["datahub.namevalid"]}</span>
                                                    <span class="connectionnameerrors" style={{display: "none",color: "red"}}>{lang["datahub.nameerror"]}</span>
                                               </div>
                                            </div>
                                            <div className="form-group form-group-sm">
                                                <label htmlFor="" className="col-sm-3 control-label" style={{fontSize:"15px"}}>{lang["datahub.srclctn"]}</label>
				    	                        <div className="col-sm-6">
                                                    {loglocation}
                                                </div>
                                            </div>
                                            {(this.props.uploadtype==="log_ftp"||this.props.uploadtype!=="log")? (
                                            <>
                                                <div className="ftpurl">
                                                    <span className="previousexportedftpfiles col-sm-6 col-sm-offset-4 marg-bot-20"></span> 
                                                    <div className="form-group form-group-sm required">
                                                        <label htmlFor="" className="col-sm-3 control-label ftpurl" style={{fontSize:"15px"}}>{lang["datahub.ftpurl"]}</label>
                                                        <div className="col-sm-6">
                                                            <input type="text" value={this.props.ftpurl} className="form-control ftpfileurl" id="ftpfileurladds" placeholder="" />
                                                            <span id="ftpurl" className="help-block hide ftpurlemptyerror fa-modal-alert text-center">{lang["datahub.ftpvalid"]}</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-group form-group-sm required">
                                                        <label htmlFor="" className="col-sm-3 control-label">{lang["datahub.username"]}</label>
                                                        <div className="col-sm-6">
                                                            <input type="text" value={this.props.ftpusername} className="form-control ftpusername" id="ftpusernameadds" placeholder="" />
                                                            <span id="ftpusername" className="help-block hide ftpusernameemptyerror fa-modal-alert text-center">{lang["datahub.uservalid"]}</span>
                                                        </div>
						                            </div>
                                                    <div className="form-group form-group-sm">
                                                        <div className="col-sm-offset-3 col-sm-6">
                                                            <button className="btn btn-bird pull-left ftptestconnection" id="ftptestconnectionclick" onClick={this.props.ftptestconnection}>
                                                                <i className="fa fa-play"></i> {lang["datahub.testcnctn"]}
                                                            </button>
                                                            <button className="btn btn-bird marg-left-10 browselogfiles disabled" id="browselogfilesclick" onClick={this.props.browselogfiles}>
                                                                <i className="fa fa-file-text-o"></i> {lang["datahub.logfile"]}
                                                            </button>
                                                        </div>
						                            </div>
                                                    <div className="col-xs-12">
                                                        <div className="notifications-table-container hide"></div>	
                                                    </div>
                                                </div>
                                                <div className="ftpbrowse hide">
                                                    <div className="form-group form-group-sm required">
                                                        <span className="previousexportedfiles col-sm-6 col-sm-offset-4 marg-bot-20"></span>
                                                        <div className="col-sm-offset-3 col-sm-8">
                                                            <span className="required right scopelabels"></span>
                                                            <span>{lang["datahub.maxsize"]} {this.props.uploadsize}&nbsp;{lang["datahub.sizemb"]}</span>
                                                            <span className="previousexportedxlsfiles"></span> 
                                                            <br />
                                                            <div className="col-xs-12 nopadding">
                                                                <input id="fileupload" type="file" className="file" name="" data-show-preview="false" accept={this.props.allow} required="required" />
                                                                <span className="csverror" style={{display:"none",color:"red"}}>{lang["datahub.slctfile"]}</span>
                                                                <span className="csvcustomerror" style={{display:"none",color:"red"}}></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            ):null}
                                            {(this.props.uploadtype==="ftpbrowse")?(
                                                <div className="ftpbrowse">
                                                    <div className="form-group form-group-sm required">
				    	                                <label htmlFor="" className="col-sm-4 control-label">{lang["datahub.puf"]}</label>
                                                        <div className="col-sm-6">
                                                            <span><b>{this.props.editfilename}</b></span>	
                                                        </div>
				  	                                </div>
                                                    <div className="form-group form-group-sm required">
                                                        <span className="previousexportedfiles col-sm-6 col-sm-offset-4 marg-bot-20"></span>
                                                        <div className="col-sm-offset-4 col-sm-6">
                                                            <span className="required right scopelabels"></span>
                                                            <span>{lang["datahub.maxsize"]} {this.props.uploadsize}&nbsp;{lang["datahub.sizemb"]}</span>
                                                            <span className="previousexportedxlsfiles"></span> 
                                                            <br />
                                                            <div className="col-xs-12 nopadding">
                                                                <input id="fileupload" type="file" className="file" name="" data-show-preview="false" accept={this.props.allow} required="required" />
                                                                <span className="csverror" style={{display:"none",color:"red"}}>{lang["datahub.slctfile"]}</span>
                                                                <span className="csvcustomerror" style={{display:"none",color:"red"}}></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ):null}
                                        </form>
                                    </div>
                                </div>
                            </div>
                            {(this.props.uploadtype==="log_ftp" || this.props.uploadtype!=="log")? (
                                <div className="container-fluid disabled">
                                    <div className="row">
                                        <div className="col-md-offset-3 col-md-6">
                                            {/* <!-- <h3>Connect LOG File</h3> --> */}
                                            <form className="form-horizontal marg-top-20 kafkaform">
                                                <div className="form-group form-group-sm required">
                                                    <label htmlFor="" className="col-sm-3 control-label">{lang["datahub.logtyp"]}</label>
                                                    <div className="col-sm-6">
                                                        <select className="form-control logtypeselect" id="logtypeselectadd" onChange={this.props.logtypeselect}>
                                                            <option>{lang["datahub.slctlog"]}</option>
                                                            <option value="customlog">{lang["datahub.custom"]}</option>
                                                            {this.props.logscollection.map((log,index)=>{
                                                                return(
                                                                    <option
                                                                        title={log.scopename==undefined?log.logType:log.scopename}
                                                                        className="logtypeselectvalue"
                                                                        scope-value={log.scopename===undefined?log.logType:log.scopename}
                                                                        value={log.scopename===undefined?log.logType:log.scopename}>
                                                                            {log.scopename===undefined?log.logType:log.scopename}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group form-group-sm required customlogformatdiv hide">
                                                    <label htmlFor="" className="col-sm-4 control-label">{lang["datahub.cformat"]}</label>
                                                    <div className="col-sm-6">
                                                        <textarea
                                                            className="form-control customlogformat customlogformatdata"
                                                            id="customlogformatdataadd" rows="4">
                                                                {this.props.logpattern}
                                                        </textarea>
                                                    </div>
					                            </div>
                                                <div className="syntaxes hide">
                                                    <div className="form-group form-group-sm ">
                                                        <label htmlFor="" className="col-sm-4 control-label logsyntaxlabel">{lang["datahub.syntax"]}</label>
                                                        <div className="col-sm-6">
                                                            <p className="logsyntax"></p>
                                                        </div>
                                                    </div>
                                                    <div className="form-group form-group-sm logexamples hide">
                                                        <label htmlFor="" className="col-sm-4 control-label logexamplelabel">{lang["datahub.example"]}</label>
                                                        <div className="col-sm-6">
                                                            <p className="logexample"></p>
                                                        </div>
                                                    </div>
					                            </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            ):null}
                            {(this.props.uploadtype==="log")?(
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-xs-12">
                                            {/* <!-- <h3>Connect LOG File</h3> --> */}
                                            <form className="form-horizontal marg-top-20 kafkaform">
                                                <div className="form-group form-group-sm required">
                                                    <label htmlFor="" className="col-sm-4 control-label">{lang["datahub.logtyp"]}</label>
                                                    <div className="col-sm-6">
                                                        <select className="form-control logtypeselect" id="logtypeselectadd" onChange={this.props.logtypeselect}>
                                                            <option>{lang["datahub.slctlog"]}</option>
                                                            <option value="customlog">{lang["datahub.custom"]}</option>
                                                            {this.props.logscollection.map((log,index)=>{
                                                                return(
                                                                    <option
                                                                        title={log.scopename===undefined?log.logType:log.scopename}
                                                                        className="logtypeselectvalue"
                                                                        scope-value={log.scopename===undefined?log.logType:log.scopename}
                                                                        value={log.scopename===undefined?log.logType:log.scopename}>
                                                                            {log.scopename===undefined?log.logType:log.scopename}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group form-group-sm required customlogformatdiv hide">
                                                    <label htmlFor="" className="col-sm-4 control-label">{lang["datahub.cformat"]}</label>
                                                    <div className="col-sm-6">
                                                        <textarea
                                                            className="form-control customlogformat customlogformatdata"
                                                            id="customlogformatdataadd" rows="4">{this.props.logpattern}</textarea>
                                                    </div>
					                            </div>
                                                <div className="syntaxes hide">
                                                    <div className="form-group form-group-sm ">
                                                        <label htmlFor="" className="col-sm-4 control-label logsyntaxlabel">{lang["datahub.syntax"]}</label>
                                                        <div className="col-sm-6">
                                                            <p className="logsyntax"></p>
                                                        </div>
                                                    </div>
                                                    <div className="form-group form-group-sm logexamples hide">
                                                        <label htmlFor="" className="col-sm-4 control-label logexamplelabel">{lang["datahub.example"]}</label>
                                                        <div className="col-sm-6">
                                                            <p className="logexample"></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            ):null}
                        </div>
                        <div className="modal-footer marg-top-20">
	                        <button type="button" className="btn btn-bird" id="closeconnectionpop"><i className="fa fa-times"></i> {lang["datahub.canclbtn"]}</button>
	                        <button type="button" className="btn btn-bird active savenewlogconnection" id="savenewlogconnection" onClick={this.props.savenewlogconnection}><i className="fa fa-floppy-o"></i> {lang["datahub.savebtn"]}</button>
                        </div>
                    </React.Fragment>
                ) : (
                        <React.Fragment>
                            <div className="modal-header boc-modal-hf-padding">
                                <h4 className="modal-title"><i className="fa fa-link marg-righ-10"></i>{lang["datahub.filestitle"]}</h4>
                            </div>
                            <div className="modal-body">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-xs-12">
                                            {/* <!-- <h3>Connect MySQL</h3> --> */}
                                            <form className="form-horizontal marg-top-10">
                                                <div className="form-group form-group-sm">
                                                    <label htmlFor="" className="col-sm-3 control-label ">
                                                    <img className="pull-right" src={require(`../images/birdimages/${this.props.schemaName.toLowerCase()}.png`)}/> </label>
                                                    <div className="col-sm-6">
                                                        <h3>{lang["datahub.upload"]} {this.props.schemaName}{lang["datahub.file"]}</h3>
                                                    </div>
                                                </div>
                                                <div className="form-group form-group-sm required">
                                                    <label htmlFor="" className="col-sm-3 control-label">{lang["datahub.cnctnname"]}</label>
                                                    <div className="col-sm-6">
                                                        <input type="text" defaultValue={this.props.schemaDisplayName} className="form-control logconnectionname" placeholder="" />
                                                        <span id="logconnectionname" className="help-block connectionnameerror hide fa-modal-alert text-center">{lang["datahub.namevalid"]}</span>
                                                        <span class="connectionnameerrors" style={{display: "none",color: "red"}}>{lang["datahub.nameerror"]}</span>
                                                    </div>
                                                </div>
                                                <div className="form-group form-group-sm">
                                                    <label htmlFor="" className="col-sm-3 control-label" style={{fontSize:"15px"}}>{lang["datahub.srclctn"]}</label>
                                                    <div className="col-sm-6">
                                                        <label htmlFor="logfilelocation" className="control-label marg-righ-10" style={{fontSize:"15px",fontWeight:"400"}}>
                                                            <input type="radio" className="logfilemethod" id="logfilelocation" name="logfilelocation" value="browsefile" defaultChecked onChange={this.props.logfilemethod} /> {lang["datahub.browsfile"]}
                                                    </label>
                                                        <label htmlFor="logftplocation" className="control-label marg-righ-10" style={{fontSize:"15px",fontWeight:"400"}}>
                                                            <input type="radio" className="logfilemethod" id="logftplocation" name="logfilelocation" value="ftp" onChange={this.props.logfilemethod} /> {lang["datahub.Ftp"]}
                                                    </label>
                                                        <label htmlFor="logsftplocation" className="control-label marg-righ-10" style={{fontSize:"15px",fontWeight:"400"}}>
                                                            <input type="radio" className="logfilemethod" id="logsftplocation" name="logfilelocation" value="sftp" onChange={this.props.logfilemethod} /> {lang["datahub.Sftp"]}
                                                    </label>
                                                    </div>
                                                </div>
                                                <div className="ftpurl hide">
                                                    <span className="previousexportedftpfiles col-sm-6 col-sm-offset-4 marg-bot-20"></span>
                                                    <div className="form-group form-group-sm required">
                                                        <label htmlFor="" className="col-sm-3 control-label ftpurl" style={{fontSize:"15px"}}>{lang["datahub.ftpurl"]}</label>
                                                        <div className="col-sm-6">
                                                            <input type="text" defaultValue="" className="form-control ftpfileurl" id="ftpfileurladds" placeholder="" />
                                                            <span id="ftpurl" className="help-block hide ftpurlemptyerror fa-modal-alert text-center">{lang["datahub.ftpvalid"]}</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-group form-group-sm required">
                                                        <label htmlFor="" className="col-sm-3 control-label">{lang["datahub.username"]}</label>
                                                        <div className="col-sm-6">
                                                            <input type="text" defaultValue="" className="form-control ftpusername" id="ftpusernameadds" placeholder="" />
                                                            <span id="ftpusername" className="help-block hide ftpusernameemptyerror fa-modal-alert text-center">{lang["datahub.uservalid"]}</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-group form-group-sm required">
                                                        <label htmlFor="" className="col-sm-3 control-label">{lang["datahub.pwd"]}</label>
                                                        <div className="col-sm-6">
                                                            <input type="password" defaultValue="" className="form-control ftppassword" id="ftppasswordadds" placeholder="" />
                                                            <span id="ftppassword" className="help-block hide ftppasswordemptyerror fa-modal-alert text-center">{lang["datahub.pwdvalid"]}</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-group form-group-sm">
                                                        <div className="col-sm-offset-3 col-sm-6">
                                                            <button className="btn btn-bird pull-left ftptestconnection" id="ftptestconnectionclick" onClick={this.props.ftptestconnection}>
                                                                <i className="fa fa-play"></i> {lang["datahub.testcnctn"]}
                                                            </button>
                                                            <button className="btn btn-bird marg-left-10 browselogfiles disabled" id="browselogfilesclick" onClick={this.props.browselogfiles}>
                                                                <i className="fa fa-file-text-o"></i> {lang["datahub.logfile"]}
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-12">
                                                        <div className="notifications-table-container hide"></div>
                                                    </div>
                                                </div>
                                                <div className="ftpbrowse">
                                                    <div className="form-group form-group-sm required">
                                                        <span className="previousexportedfiles col-sm-6 col-sm-offset-4 marg-bot-20"></span>
                                                        <div className="col-sm-offset-3 col-sm-8">
                                                            <span className="required right scopelabels"></span>
                                                            <span>{lang["datahub.maxsize"]} {this.props.uploadsize}&nbsp;{lang["datahub.sizemb"]}</span>
                                                            <span className="previousexportedxlsfiles"> </span>
                                                            <br />
                                                            <div className="col-xs-12 nopadding">
                                                                <input id="fileupload" type="file" className="file" name="" data-show-preview="false" accept={this.props.allow} required="required" />
                                                                <span className="csverror" style={{display: "none",color: "red"}}>{lang["datahub.slctfile"]}</span>
                                                                <span className="csvcustomerror" style={{display: "none",color: "red"}}></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-xs-12">
                                            {/* <!-- <h3>Connect LOG File</h3> --> */}
                                            <form className="form-horizontal marg-top-20 kafkaform">
                                                <div className="form-group form-group-sm required">
                                                    <label htmlFor="" className="col-sm-3 control-label">{lang["datahub.logtyp"]}</label>
                                                    <div className="col-sm-6">
                                                        <select className="form-control logtypeselect" id="logtypeselectadd" onChange={this.props.logtypeselect}>
                                                            <option>{lang["datahub.slctlog"]}</option>
                                                            <option value="customlog">{lang["datahub.custom"]}</option>
                                                            {this.props.logscollection.map((log, index) => {
                                                                return (
                                                                    <option
                                                                        title={log.scopename === undefined ? log.logType : log.scopename}
                                                                        className="logtypeselectvalue"
                                                                        scope-value={log.scopename === undefined ? log.logType : log.scopename}
                                                                        value={log.scopename === undefined ? log.logType : log.scopename}
                                                                    >
                                                                        {log.scopename === undefined ? log.logType : log.scopename}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group form-group-sm required customlogformatdiv hide">
                                                    <label htmlFor="" className="col-sm-4 control-label">{lang["datahub.cformat"]}</label>
                                                    <div className="col-sm-6">
                                                        <textarea
                                                            className="form-control customlogformat customlogformatdata"
                                                            id="customlogformatdataadd" rows="4"></textarea>
                                                    </div>
                                                </div>
                                                <div className="syntaxes hide">
                                                    <div className="form-group form-group-sm ">
                                                        <label htmlFor="" className="col-sm-4 control-label logsyntaxlabel">{lang["datahub.syntax"]}</label>
                                                        <div className="col-sm-6">
                                                            <p className="logsyntax"></p>
                                                        </div>
                                                    </div>
                                                    <div className="form-group form-group-sm logexamples hide">
                                                        <label htmlFor="" className="col-sm-4 control-label logexamplelabel">{lang["datahub.example"]}</label>
                                                        <div className="col-sm-6">
                                                            <p className="logexample"></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer marg-top-20">
                                <button type="button" className="btn btn-bird" id="closeconnectionpop" onClick={this.props.close}>
                                    <i className="fa fa-times"></i> {lang["datahub.canclbtn"]}
                                </button>
                                <button type="button" className="btn btn-bird active savenewlogconnection" id="savenewlogconnection" onClick={this.props.savenewlogconnection}>
                                    <i className="fa fa-floppy-o"></i> {lang["datahub.savebtn"]}
                                </button>
                            </div>
                        </React.Fragment>)}
            </React.Fragment>
        );
    }
}