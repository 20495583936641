import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import _ from 'underscore';
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import XAxisAttrView from "../xaxisattributesview"
import YAxisAttrView from '../yaxisattributesview'
import XAxisDropDownView from "../xaxisdropdownview"
import YAxisDropDownView from '../yaxisdropdownview'
import { lang } from "../../js/helpers/utils/languages/language";

const $ = window.$;
export default class AutovizAxisSettingsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.state.dynamicdiv = this.props.dynamicdiv;
        this.state.measures = this.props.measures;
        this.state.dimensions = this.props.dimensions;
        this.state.targerColumn = this.props.targetColumn;
        this.state.chartnumber = this.props.chartnumber;
        this.state.metrictype = this.props.metricdatetype;
        this.render();
        $('.yaxisattributes').sortable();
        $('.xaxisattributes').sortable();
    }
    render() {
        this.state.measures.sort(function (a, b) {
            var x = a.columnDisplayName.toLowerCase();
            var y = b.columnDisplayName.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
        });
        this.state.dimensions.sort(function (a, b) {
            var x = a.columnDisplayName.toLowerCase();
            var y = b.columnDisplayName.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
        });

        ReactDOM.render(<AutovizSettingsViewTemplate showyAxisDropDown={this.showyAxisDropDown} showXAxisDropDown={this.showXAxisDropDown} measures={this.state.measures} dimensions={this.state.dimensions} metrictype={this.state.metrictype} targerColumn={this.state.targerColumn} />, document.getElementById(this.state.dynamicdiv))
        var that = this;
        if (ChartInfo.functions.getSmartInsightsExcludeValues() != "") {
            var excludeVals = ChartInfo.functions.getSmartInsightsExcludeValues().split(",");
            if (excludeVals != undefined && excludeVals.length > 0) {
                excludeVals = excludeVals.filter((v, i, a) => a.indexOf(v) === i);
            }
            _.each(this.state.dimensions, function (xAxisli) {
                for (var i = 0; i < excludeVals.length; i++) {
                    if (xAxisli.columnName == excludeVals[i]) {
                        var columnName = excludeVals[i];
                        var columnDisplayName = xAxisli.columnDisplayName;
                        var datatype = xAxisli.tableDisplayType;
                        var tableName = xAxisli.tableName;

                        var today = new Date();
                        var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                        $(".smartxaxisattributes").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                        var xaxisAttrView = new XAxisAttrView({
                            dynamicdiv: dynamicdiv,
                            xaxiscolumnDisplayName: columnDisplayName,
                            xaxiscolumnName: columnName,
                            xaxiscolumnType: datatype,
                            xaxistableName: tableName,
                            viewmode: 'smartvalues'
                        });
                        $(".smartxaxisattributes").append(document.getElementById(dynamicdiv));
                    }
                }
            });
        }
        _.each(this.state.measures, function (yAxisli) {
            if (yAxisli.columnName == ChartInfo.functions.getSmartInsightsTargetVal()) {
                var columnName = ChartInfo.functions.getSmartInsightsTargetVal();
                var columnDisplayName = yAxisli.columnDisplayName;
                var datatype = yAxisli.tableDisplayType;
                var aggregation = yAxisli.aggregation;
                var tableName = yAxisli.tableName;
                var percentileValue = "";
                var activeChartType = "";
                var today = new Date();
                var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                $(".smartyaxisattributes").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                var yAxisAttrView = new YAxisAttrView({
                    dynamicdiv: dynamicdiv,
                    yaxiscolumnDisplayName: columnDisplayName,
                    yaxiscolumnName: columnName,
                    yaxiscolumnType: datatype,
                    yaxisaggregation: aggregation,
                    yaxistableName: tableName,
                    percentileValue: percentileValue,
                    measures: that.measures
                });
                $(".smartyaxisattributes").html(document.getElementById(dynamicdiv));
            }
        });
        return (<div></div>);
    }
    showXAxisDropDown = () => {
        var that = this;
        $(".smartxaxisdropdown").empty();
        $(".smartxaxisdropdown").append('<li class="xs-list-item"> <input class="form-control form-control-xaxisdropdown search-image" placeholder='+lang["smartsights.search"]+' id="searchinput" type="text"></li>');

        _.each(this.state.dimensions, function (dimensions) {
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $(".smartxaxisdropdown").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");

            var xAxisDropDown = new XAxisDropDownView({
                dynamicdiv: dynamicdiv,
                addxaxisvalue: that.addxaxisvalue,
                columnDisplayName: dimensions.columnDisplayName,
                columnName: dimensions.columnName,
                dataType: dimensions.tableDisplayType,
                tableName: dimensions.tableName
            });
            $(".smartxaxisdropdown").append(document.getElementById(dynamicdiv));
            $('.form-control-xaxisdropdown').click(function (e) {
                e.stopPropagation();
            });
            $('.form-control-xaxisdropdown').keyup(function (e) {
                /*Delay function for to wait until user stops typing*/
                var delay = (function () {
                    var timer = 0;
                    return function (callback, ms) {
                        clearTimeout(timer);
                        timer = setTimeout(callback, ms);
                    };
                })();
                /*Delay function for to wait until user stops typing*/
                $(".form-control-xaxisdropdown").keyup(function () {
                    var valThis = $(this).val().toLowerCase();
                    delay(function () {
                        $(".itemname").parents(".dimensionitem").each(function () {
                            var text = $(this).text().toLowerCase();
                            (text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
                        });
                    }, 400);
                });
            });
        });
    }
    addxaxisvalue = (event) => {
        var $this = event.currentTarget;
        var isXaxisalreadyExist = false;
        var selectedTargetColumn = $(".smartyaxisattributes .singleliitems").find(".itemname").text();
        _.each($('.smartxaxisattributes li'), function (xAxisli) {
            if ($(xAxisli).attr("data-name") == $($this).attr("data-name")) {
                isXaxisalreadyExist = true;
            }
        });
        if (selectedTargetColumn == $($this).attr("data-name")) {
            $(".spinner").hide();
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-infor').addClass('birdmessage-warning');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-info-circle').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(lang["smartsights.colmslctd"]);
            isXaxisalreadyExist = true;
        }
        if (isXaxisalreadyExist == false) {
            if (!$($this).hasClass('active')) {
                var columnName = $($this).attr("data-name");
                var columnDisplayName = $($this).find(".itemname").text().trim();
                var datatype = $($this).attr("data-type");
                var tableName = $($this).attr("table-name");

                var today = new Date();
                var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                $(".smartxaxisattributes").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");

                var xaxisAttrView = new XAxisAttrView({
                    dynamicdiv: dynamicdiv,
                    xaxiscolumnDisplayName: columnDisplayName,
                    xaxiscolumnName: columnName,
                    xaxiscolumnType: datatype,
                    xaxistableName: tableName,
                    viewmode: 'smartvalues'
                });
                var activeChartType = $('.charttype.active').attr("id");
                $(".smartxaxisattributes").append(document.getElementById(dynamicdiv));
            }
        }
    }
    showyAxisDropDown = () => {
        var that = this;
        $(".smartyaxisdropdown").empty();
        $(".smartyaxisdropdown").append('<li class="xs-list-item"> <input class="form-control form-control-yaxisdropdown search-image" placeholder='+lang["smartsights.search"]+' id="searchinput" type="text"></li>');
        _.each(this.state.measures, function (measure) {
            if (measure.tableDisplayType != "date"  /*&& measure.attributes.columnName.toUpperCase().indexOf("ID")==-1*/) {
                var today = new Date();
                var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                $(".smartyaxisdropdown").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");

                var yAxisDropDown = new YAxisDropDownView({
                    dynamicdiv: dynamicdiv,
                    addyaxisvalue: that.addyaxisvalue,
                    columnDisplayName: measure.columnDisplayName,
                    columnName: measure.columnName,
                    targerColumn: that.targerColumn,
                    dataType: measure.tableDisplayType,
                    tableName: measure.tableName,
                    aggregation: measure.aggregation
                });

                $(".smartyaxisdropdown").append(document.getElementById(dynamicdiv));
                $('.form-control-yaxisdropdown').click(function (e) {
                    e.stopPropagation();
                });
                $('.form-control-yaxisdropdown').keyup(function (e) {
                    /*Delay function for to wait until user stops typing*/
                    var delay = (function () {
                        var timer = 0;
                        return function (callback, ms) {
                            clearTimeout(timer);
                            timer = setTimeout(callback, ms);
                        };
                    })();
                    /*Delay function for to wait until user stops typing*/
                    $(".form-control-yaxisdropdown").keyup(function () {
                        var valThis = $(this).val().toLowerCase();
                        delay(function () {
                            $(".itemname").parents(".measureitem").each(function () {
                                var text = $(this).text().toLowerCase();
                                (text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
                            });
                        }, 400);
                    });
                });
            }
        });
    }
    addyaxisvalue = (event) => {
        sessionStorage.setItem('currentChartNumberForSort', '');
        var $this = event.currentTarget;
        var isYaxisalreadyExist = false;
        _.each($('.yaxisattributes .singleliitems'), function (yxisli) {
            if ($(yxisli).find('.itemname').attr('title').trim() == $($this).find('.itemname').text().trim()) {
                isYaxisalreadyExist = true;
            }
        })
        _.each($('.smartxaxisattributes li'), function (xAxisli) {
            if ($(xAxisli).attr("data-name") == $($this).attr("data-name")) {
                $(".spinner").hide();
                $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-infor').addClass('birdmessage-warning');
                $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-info-circle').addClass('fa-exclamation-triangle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Warning');
                $('.birdmessage-info').empty().text("This column is alredy selected as Exclude");
                isYaxisalreadyExist = true;
            }
        });
        if (isYaxisalreadyExist == false) {
            if (!$($this).hasClass('active')) {
                var columnName = $($this).attr("data-name");
                var columnDisplayName = $($this).find('.itemname').text();
                var datatype = $($this).attr("data-type");
                var aggregation = $($this).find(".boclabel-info").text().replace(/[^A-Z0-9]/ig, "");
                var tableName = $($this).attr("table-name");
                var percentileValue = $($this).attr("percentile-value");
                //	var data = JSON.parse(ChartInfo.getChartDetails(this.options.chartnumber));
                var activeChartType = $('.charttype.active').attr("id");
                var today = new Date();
                var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                $(".smartyaxisattributes").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                var yAxisAttrView = new YAxisAttrView({
                    dynamicdiv: dynamicdiv,
                    yaxiscolumnDisplayName: columnDisplayName,
                    yaxiscolumnName: columnName,
                    yaxiscolumnType: datatype,
                    yaxisaggregation: aggregation,
                    yaxistableName: tableName,
                    percentileValue: percentileValue,
                    measures: this.state.measures,
                    viewmode: 'smartvalues'
                });
                $(".smartyaxisattributes").html(document.getElementById(dynamicdiv));
            }
        }
    }
}
class AutovizSettingsViewTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        var that = this.props;
        return (
            //    <!-- autovizattraxissettingview.js -->
            <div className="modal-height-fix">
                <div className="mainattibutes">
                    <div className="modalaxiscontainer">
                        <legend className="subhead">
                            <div className="attraxisnames">{lang["smartsights.target"]}</div>
                            <button
                                className="pull-right btn btn-transparent dropdown-toggle tooltip-left"
                                data-tooltip={lang["smartsights.add"]} id="dropdownsmartyaxis" onClick={that.showyAxisDropDown} aria-expanded="false"
                                data-toggle="dropdown">
                                <i className="fa fa-plus-circle"></i>
                            </button>
                            <ul className="list-group dropdown-menu axis-add-menu-fix smartyaxisdropdown" aria-labelledby="dropdownyaxis" role="menu"></ul>
                        </legend>
                        <div className="marg-top-10">
                            <ul className="list-group smartyaxisattributes nlp-available-columns"></ul>
                        </div>
                    </div>

                    <div className="modalaxiscontainer">
                        <legend className="subhead">
                            <div className="attraxisnames">{lang["smartsights.exclude"]}</div>
                            <button
                                className="pull-right btn btn-transparent dropdown-toggle tooltip-left"
                                data-tooltip={lang["smartsights.add"]} id="dropdownsmartxaxis" onClick={that.showXAxisDropDown} aria-expanded="false"
                                data-toggle="dropdown">
                                <i className="fa fa-plus-circle"></i>
                            </button>
                            <ul className="list-group dropdown-menu axis-add-menu-fix smartxaxisdropdown" aria-labelledby="dropdownxaxis" role="menu"></ul>
                        </legend>
                        <div className="marg-top-10">
                            <ul className="list-group smartxaxisattributes nlp-available-columns"></ul>
                        </div>
                    </div>
                </div>
            </div>);
    }
}