import React from "react";
import PubSub from 'pubsub-js'
import * as tabInfo from "../js/helpers/utils/tabinfo";
import * as ChartInfo from "../js/helpers/utils/chartinfo"
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;


export default class ViewReportAccordianTemplate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	componentDidMount() {
		var that = this;
		var tabid = tabInfo.functions.getCurrentTabId();
		$(".rightfiltersapply[tab-id=" + tabid + "]").click(function (e) {
			e.stopPropagation();
			e.preventDefault();
			PubSub.publish('rightfiltersapplyStory');
		})
		$(".applysavedfilter[tab-id=" + tabid + "]").click(function (e) {
			e.stopPropagation();
			e.preventDefault();
			PubSub.publish('rightfiltersapplyreport', e);
		})

		$(".valueoffilters").click(function (e) {
			e.stopPropagation();
		})
		$(".daterange-center, .advanced-access-daterange-center").click(function (e) {
			that.props.daterangepicker(e);
		})
		$(".filtervalue").click(function (event) {
			event.stopPropagation();
			var temp = event.currentTarget;
			$("#checkall[name='" + temp.getAttribute('data-name').replace(/([;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "") + "']").removeAttr('checked');
			$("#uncheckall[name='" + temp.getAttribute('data-name').replace(/([;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "") + "']").removeAttr('checked');
			var checkeditems = $(temp).closest("ul").find("input.filtervalue:checked").length,
				columndisplayname = temp.getAttribute('data-name').replace(/([;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "");
			if (checkeditems != undefined && checkeditems < 1) {
				$('#filteraccordion .acc-content[data-id="' + columndisplayname + '"] .filteredvalues').text("None").attr("None");
			} else { }
		})
		$('.filter-toggle').click(function (e) {
			e.stopImmediatePropagation();
			e.stopPropagation();
			var $this = e.currentTarget;
			if ($($this).hasClass("filter-on")) {
				$($this).removeClass("filter-on").addClass("filter-off").attr("title", lang["storyboard.turnonfilter"]);
				$($this).find("i").addClass("fa-flip-horizontal");
				$($this).closest('.acc-content').removeClass("filter-on").addClass("filter-off");
			} else {
				$($this).removeClass("filter-off").addClass("filter-on").attr("title", lang["storyboard.turnofffilter"]);;
				$($this).find("i").removeClass("fa-flip-horizontal");
				$($this).closest('.acc-content').removeClass("filter-off").addClass("filter-on");
			}
		})
		$('.drillthroughcheck').click(function (e) {
			e.stopImmediatePropagation();
			e.stopPropagation();
			var $this = e.currentTarget;
			var columnNamesArray = ChartInfo.functions.getDrillthroughColumnNamesArray();
			var columnname = $($this).attr('data-id');
			if ($($this).hasClass("drillthrough-on")) {
				$($this).removeClass("drillthrough-on").addClass("drillthrough-off").attr("title", lang["storyboard.addrillthrough"]);
				$($this).find("i").addClass("fa-flip-horizontal");
				$($this).closest('.acc-content').removeClass("drillthrough-on").addClass("drillthrough-off");
				var indexNumber = columnNamesArray.indexOf(columnname);
				if (indexNumber > -1) {
					columnNamesArray.splice(indexNumber, 1);
				}
			} else {
				$($this).removeClass("drillthrough-off").addClass("drillthrough-on").attr("title", lang["storyboard.remdrillthrough"]);;
				$($this).find("i").removeClass("fa-flip-horizontal");
				$($this).closest('.acc-content').removeClass("drillthrough-off").addClass("drillthrough-on");
				columnNamesArray.push(columnname)
			}
			ChartInfo.functions.setDrillthroughColumnNamesArray(columnNamesArray);
		})
		$('#checkall').click(function (e) {
		})
		$('#uncheckall').click(function (e) {
		})
	}
	validateInFilters(e) {
		//if the letter is not digit then display error and don't type anything
		var $this = e.currentTarget;
		var target = $($this).attr('id');
		if (e.which != 8 && e.which != 44 && e.which != 45 && e.which != 0 && e.which != 46 && (e.which < 48 || e.which > 57)) {
			//display error message
			e.preventDefault();
			return false;
		}
	}
	accordianToggle=(columnDisplayNamesAfterRename,time,e)=>{
		e.stopPropagation();
		var $this = $(e.currentTarget).parent();
        var currentElementID = $($this).parent().find(".panel-collapse").attr("id");
        $(this.state.element + " .panel-collapse.in").not("#" + currentElementID).collapse('hide');
        
        //set panel position
        var currentElementPosition = $($this).offset().left,
            windowWidth = $(window).width(),
            isRightMenuOpen = $("body").hasClass("rightpanelopen");
        if (isRightMenuOpen) {
            if ((windowWidth - currentElementPosition) > 675) {
                $($this).parent().find('.panelboc-content').css({ 'left': currentElementPosition, 'right': "auto" });
            } else {
                $($this).parent().find('.panelboc-content').css({ 'right': "380px", "left": "auto" });
            }
        } else {
            if ((windowWidth - currentElementPosition) > 300) {
                $($this).parent().find('.panelboc-content').css({ 'left': currentElementPosition, 'right': "auto" });
            } else {
                $($this).parent().find('.panelboc-content').css({ 'right': "5px", "left": "auto" });
            }
        }

        //For Maximised reports
        if ($('.fullscreenmain').length > 0 && $("#advancedsharestoryboardpermissionmodal.in").length === 0) {
            $(".tab-filters-container").css("z-index", "9999")
        } else {
            $(".tab-filters-container").css("z-index", "")
        }
		if($(`#acc${columnDisplayNamesAfterRename}_${time}`).is(':visible')){
			$(`#acc${columnDisplayNamesAfterRename}_${time}`).removeClass('in');
		}else{
		    $(`#acc${columnDisplayNamesAfterRename}_${time}`).toggleClass("in");
		}
	}
	render() {
		var that = this.props;
		var tabid = tabInfo.functions.getCurrentTabId();
		var time = new Date().getTime();
		var filterON = "filter-on"; var filterIconOff = ""; var filterText = "Turn OFF Filter";
		var drillthroughON = "drillthrough-on"; var drillthroughIconOff = ""; var drillthroughText = "Remove from Drill-Through"; var drillthroughIgnore = true;
		var columnDisplayNamesAfterRename = '';
		var columnNameAfterRename = '';
		var removedsplcharscolumnName = ''
		var removedsplcharscolumnNameExcludingSpace = '';
		removedsplcharscolumnName = that.columnName.replace(/([ ;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "");
		removedsplcharscolumnNameExcludingSpace = that.columnDisplayName;
		var isCustomFunction = "";
		var savedfilterDisplayName = that.columnDisplayName;
		var isFilterRenameAllwoed = "";
		var isStorybook = $("body.storybookpage").length;
		var isSingleReport;
		var dataType = that.columnType;
		var dataIcon = lang["storyboard.abc"];
		if (dataType == "number") {
			dataIcon = "123"
		} else if (dataType == "date" || dataType == "datetime" || dataType === 'month' || dataType === 'day') {
			dataIcon = "<i className='fa fa-calendar'></i>"
		} else if (dataType == "customhierarchy") {
			dataIcon = "<i className='fa fa-list'></i>"
		} else {
			dataIcon = lang["storyboard.abc"]
		}
		if (that.columnDisplayNames.indexOf(' ')) {
			columnDisplayNamesAfterRename = that.columnDisplayNames.replace(/([ ;?&,%#+*~\'"!^$[\]()<=>`|\/@])/g, "_")
		} else { columnDisplayNamesAfterRename = that.columnDisplayNames }

		if (that.columnName.indexOf(' ')) {
			columnNameAfterRename = that.columnName.replace(/([ ;?&,%#+*~\'"!^$[\]()<=>`|\/@])/g, "_")
		} else { columnNameAfterRename = that.columnName }


		if (isStorybook == 1) {
			isSingleReport = $(".storybookpagecontent.active section.boc-viewreport-section.newsinglereport").length
		} else {
			isSingleReport = $("section.boc-viewreport-section.newsinglereport").length
		}
		if (that.filterDisplayName != null && that.filterDisplayName != undefined) {
			savedfilterDisplayName = that.filterDisplayName
		}
		if (that.isFilterRename) {
			isFilterRenameAllwoed = "edit-filter-display-name"
		}
		if (drillthroughIgnore != null && drillthroughIgnore != undefined && drillthroughIgnore != "" && drillthroughIgnore == true) {
			drillthroughON = "drillthrough-off";
			drillthroughIconOff = "fa-flip-horizontal";
			drillthroughText = "Add to Drill-Through";
		}
		if(that.filterIgnore != null && that.filterIgnore != undefined && that.filterIgnore != "" && that.filterIgnore == true){
			filterON = "filter-off";
			filterIconOff = "fa-flip-horizontal";
			filterText = "Turn ON Filter";
		}
		if(that.script != undefined && that.script != "") {isCustomFunction = "fx"}
		return (<>
			{/* <!-- viewreportaccordianview.js --> */}
			{(that.templatetheme != null && that.templatetheme != undefined && that.templatetheme == "theme1") ? (
				<div id={that.columnDisplayNames} data-id={that.columnDisplayNames} className={`acc-content ${filterON}`} data-parent={that.tableName}>
						<div className="boc-accordian-options">
							<div className="btn-group pull-right" role="group" aria-label="...">
								{(that.columnType != 'date' && that.columnType != 'datetime' && that.columnType != 'number' && !(that.columnType == 'all' || that.columnType == 'list' || that.columnType == 'range')) ? (
									<button type="button" className="btn btn-accordian dropdown-toggle"
										data-toggle="dropdown" aria-expanded="false" id="selecthelper">
										<i className="fa fa-cog"></i>
									</button>
								) : null}

								<ul className="dropdown-menu checkhelp" role="menu"
									aria-labelledby="selecthelper">
									<li title="" className="aggregation-li-item"><label onClick={that.selectall} name={that.columnDisplayNames}><input type="radio"
										id="checkall" onClick={that.selectall} name={that.columnDisplayNames} />&nbsp;{lang["storyboard.slctall"]}</label></li>
									<li title="" className="aggregation-li-item"><label onClick={that.unselectall} name={that.columnDisplayNames}><input type="radio"
										id="uncheckall" onClick={that.unselectall} name={that.columnDisplayNames} />&nbsp;{lang["storyboard.dslctall"]}</label></li>
								</ul>
								<button type="button" className="btn btn-accordian" id="delete" onClick={that.deleteaccordian} name={that.columnDisplayName}>
									<i className="fa fa-close"></i>
								</button>
							</div>
						</div>
						<div className="panelboc">
							<div className="panelboc-heading" onClick={that.collapseother} data-type={that.columnType}>
								{/* {(that.script != undefined && that.script != "") ? (isCustomFunction = "fx") : null} */}
								<span className={`itemtype ${isCustomFunction != undefined ? isCustomFunction : ""}`}>
									{(that.columnType == 'date' || that.columnType == 'datetime') ? (
										<i className="fa fa-calendar"></i>
									) : (that.columnType == 'string') ? (
										lang["storyboard.abc"]
									) : (that.columnType == 'number') ? (
										123
									) :
												123
									}
								</span>
								{(that.columnType != 'number') ? (
									<><h4 className="panelboc-title accordion-toggle collapsed" title={that.columnDisplayName} id={`filtersrename${removedsplcharscolumnName}`} data-toggle="collapse" data-parent="#filteraccordion" href={`#acc${columnDisplayNamesAfterRename}_${time}`} aria-expanded="false">{that.columnDisplayName}</h4>
										<div className="values accordion-toggle collapsed" data-toggle="collapse" data-parent="#filteraccordion" href={`#acc${columnDisplayNamesAfterRename}_${time}`} aria-expanded="false" onClick={(event)=>{this.accordianToggle(columnDisplayNamesAfterRename,time,event)}}><span className="filteredvalues">{lang["storyboard.none"]}</span></div></>
								) : (that.columnType == 'all' || that.columnType == 'list') ? (
									<><h4 className="panelboc-title accordion-toggle collapsed" title={that.columnName} data-toggle="collapse" data-parent="#filteraccordion" href={`#acc${columnNameAfterRename}_${time}`} aria-expanded="false">{that.columnName}</h4>
										<div className="values accordion-toggle collapsed" onClick={(e)=>{this.accordianToggle(columnDisplayNamesAfterRename,time,e)}} data-toggle="collapse" data-parent="#filteraccordion" href={`#acc${columnDisplayNamesAfterRename}_${time}`} aria-expanded="false"><span className="filteredvalues"> </span></div></>
								) : (that.columnType == 'range') ? (
									<><h4 className="panelboc-title accordion-toggle" title={that.columnName} data-toggle="collapse" data-parent="#filteraccordion" href={`#acc${columnDisplayNamesAfterRename}_${time}`} aria-expanded="true">{that.columnName}</h4>
										<div className="values accordion-toggle" onClick={(e)=>{this.accordianToggle(columnDisplayNamesAfterRename,time,e)}} data-toggle="collapse" data-parent="#filteraccordion" href={`#acc${columnDisplayNamesAfterRename}_${time}`} aria-expanded="true"><span className="filteredvalues"> </span></div></>
								) :
											<><h4 className="panelboc-title accordion-toggle" title={that.columnDisplayName} id={`filtersrename${removedsplcharscolumnName}`} data-toggle="collapse" data-parent="#filteraccordion" href={`#acc${columnDisplayNamesAfterRename}_${time}`} aria-expanded="true">{that.columnDisplayName}</h4>
												<div className="values accordion-toggle" onClick={(e)=>{this.accordianToggle(columnDisplayNamesAfterRename,time,e)}} data-toggle="collapse" data-parent="#filteraccordion" href={`#acc${columnDisplayNamesAfterRename}_${time}`} aria-expanded="true"><span className="filteredvalues"></span></div></>
								}
							</div>
							{(that.columnType != 'date' && that.columnType != 'datetime' && that.columnType != 'number' && !(that.columnType == 'all' || that.columnType == 'list' || that.columnType == 'range')) ? (
								<div id={`acc${columnDisplayNamesAfterRename}_${time}`} data-id={`acc${that.columnDisplayNames}`} className={`${time} panel-collapse collapse panelboc-content filterStringvalues`}>
									<div className="panelboc-body">
										<span className={`filter-display-name-container ${isFilterRenameAllwoed}`}>
											<span className="actualfiltername" title={that.columnDisplayName}><span>{that.columnDisplayName}</span></span>
											<input className="filter-display-name itemname" defaultValue={savedfilterDisplayName} data-name={that.columnDisplayNames} />
											<button className={`btn btn-transparent filter-toggle ${filterON}`} title={filterText}><i className={`fa fa-toggle-on ${filterIconOff}`}></i></button>
											<span style={{ color: "#ff0000" }} id="errmsgforemptyfilter" data-name={that.columnDisplayNames}></span>
											<button className={`btn btn-transparent ${drillthroughON} drillthroughcheck`} title={drillthroughText} data-id={that.columnDisplayName} ><span>{lang["storyboard.filterdrill"]}</span><i class={`fa fa-toggle-on ${drillthroughIconOff}`}></i></button>
											<button className="btn btn-transparent btn-sm filter-titile-update rightfiltersapply"><i className="fa fa-check fa-green"></i></button>
										</span>
										<div className="advancedfilters">
											<div className="btn-group pull-left text-select-options">
												<li title="" className="aggregation-li-item"><label onClick={that.selectall} name={removedsplcharscolumnNameExcludingSpace}><input type="radio" id="checkall" onClick={that.selectall} name={removedsplcharscolumnNameExcludingSpace} />&nbsp;{lang["storyboard.slctall"]}</label></li>
												<li title="" className="aggregation-li-item"><label onClick={that.unselectall} name={removedsplcharscolumnNameExcludingSpace}><input type="radio" id="uncheckall" onClick={that.unselectall} name={removedsplcharscolumnNameExcludingSpace} />&nbsp;{lang["storyboard.clear"]}</label></li>
											</div>
											<button className="btn btn-link pull-right btn-sm showadvancedfilters" onClick={that.showadvancedfilters}>{lang["storyboard.advanced"]}</button>
										</div>
										<div className="container-fluid marg-top-15">
											<div className="row marg-bot-10 filtersearching " style={{ display: "none" }}>
												{(that.filterValues && that.filterValues.length != 0) ? (
													<><div className="stringfilters searching ">
														{lang["storyboard.startswith"]} <input type="text" id={`${that.columnDisplayNames}start`} data-id={`${that.columnDisplayNames}start`} className="searchword" name={that.columnName} data-type={that.columnType}
															data-parent={that.tableName} data-child={that.columnName} data-name={that.columnDisplayNames} displayname={that.columnDisplayName} />
													</div>
														<div className="stringfilters searching">
														{lang["storyboard.endswith"]} <input type="text" id={`${that.columnDisplayNames}end`} data-id={`${that.columnDisplayNames}end`} name={that.columnName} data-type={that.columnType}
																data-parent={that.tableName} data-child={that.columnName} data-name={that.columnDisplayNames} displayname={that.columnDisplayName} />
														</div>
														<div className="stringfilters searching col-lg-10 col-md-10 col-sm-12 col-xs-12" style={{ marginLeft: " -5px" }}>
														{lang["storyboard.contains"]} <input type="text" id={`${that.columnDisplayNames}contains`} data-id={`${that.columnDisplayNames}contains`} name={that.columnName} data-type={that.columnType}
																data-parent={that.tableName} data-child={that.columnName} data-name={that.columnDisplayNames} displayname={that.columnDisplayName} />
														</div>
														<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12" style={{ marginTop: "1px" }}>
															<button type="submit" id="searchfiltersdata" onClick={that.searchfiltersdata} name={that.columnName} data-type={that.columnType}
																data-parent={that.tableName} data-child={that.columnName} data-name={that.columnDisplayNames} displayname={that.columnDisplayName} script={that.script} className="btn btn-sm btn-bird marg-top-20 btn-highlight">{lang["storyboard.go"]}</button>
														</div></>
												) : null}
											</div>
										</div>
										<ul className="list-group nomargin checkhelpitems">
											{(that.filterValues && that.filterValues.length != 0) ? (
												that.filterValues.map((filterValue, index) => {
													var i = 0;
													if(that.filterArray!=undefined&&that.filterArray.length>=0){
														that.filterArray.map((filterData) => {
															if (filterData.columndisplayname == that.columnDisplayName && filterValue.value == filterData.data) {
																i++;
															}
													   })
												    }
													var value = filterValue.value;
													var encodedvalue = (value + '').replace(/([;?&,%#+*~\'"!^$[\]()<=>`|\/@])/g, "");
													return (i == 0) ? (
														<li key={index} className="boc-list-item valueoffilters"><label title={filterValue.value} ><input type="checkbox"
															className={(that.className == undefined) ? 'filtervalue' : that.className} data-type={that.columnType}
															data-parent={that.tableName} data-child={that.columnName} data-name={that.columnDisplayNames} displayname={that.columnDisplayName} data-value={encodedvalue} script={that.script} />{filterValue.value}</label></li>
													) :
														<li key={index} className="boc-list-item valueoffilters"><label title={filterValue.value} ><input type="checkbox"
															className={(that.className == undefined) ? 'filtervalue' : that.className} data-type={that.columnType}
															data-parent={that.tableName} data-child={that.columnName} data-name={that.columnDisplayNames} displayname={that.columnDisplayName} data-value={encodedvalue} defaultChecked script={that.script} />{filterValue.value}</label></li>

												})
											) : null}
										</ul>
										<button type="button" className="btn btn-transparent pull-left" id="delete" onClick={that.deleteaccordian} name={that.columnDisplayName} title={lang["storyboard.removefilter"]}><i className="fa fa-trash-o" style={{ fontSize: "17px" }}></i></button>
										{(isSingleReport == 1) ? (
											<button className="btn btn-transparent btn-sm pull-right applysavedfilter" tab-id={tabid} id="applysavedfilterview" title={lang["storyboard.applyfilters"]}><i className="fa fa-check marg-righ-5"></i>{lang["storyboard.applyfilters"]}</button>
										) :
											<button className="btn btn-transparent btn-sm pull-right rightfiltersapply" tab-id={tabid} id="rightfiltersapplys" title={lang["storyboard.applyfilters"]}><i className="fa fa-check marg-righ-5"></i>{lang["storyboard.applyfilters"]}</button>
										}
										{(that.columnType != 'number') ? (
											<button className="btn btn-transparent btn-sm pull-right" title={lang["storyboard.cancelbtn"]} id={`filtersrename${removedsplcharscolumnName}`} data-toggle="collapse" data-parent="#filteraccordion" href={`#acc${columnDisplayNamesAfterRename}_${time}`} aria-expanded="false"><i className="fa fa-times marg-righ-5"></i>{lang["storyboard.cancelbtn"]}</button>
										) : (that.columnType == 'all' || that.columnType == 'list' || that.columnType == 'range') ? (
											<button className="btn btn-transparent btn-sm pull-right" title={lang["storyboard.cancelbtn"]} data-toggle="collapse" data-parent="#filteraccordion" href={`#acc${columnDisplayNamesAfterRename}_${time}`} aria-expanded="false"><i className="fa fa-times marg-righ-5"></i>{lang["storyboard.cancelbtn"]}</button>
										) :
												<button className="btn btn-transparent btn-sm pull-right" title={lang["storyboard.cancelbtn"]} id={`filtersrename${removedsplcharscolumnName}`} data-toggle="collapse" data-parent="#filteraccordion" href={`#acc${columnDisplayNamesAfterRename}_${time}`} aria-expanded="false"><i className="fa fa-times marg-righ-5"></i>Cancel</button>
										}
									</div>
								</div>

							) : (that.columnType === 'number') ? (
								<div id={`acc${columnDisplayNamesAfterRename}_${time}`} data-id={`acc${that.columnDisplayNames}`} className={`${time} panel-collapse collapse in panelboc-content ${(that.className == undefined) ? 'filterrangevalues' : that.className}`}>
									<div className="panelboc-body">
										<span className={`filter-display-name-container ${isFilterRenameAllwoed}`}>
											<span className="actualfiltername" title={that.columnDisplayName}><span>{that.columnDisplayName}</span></span>
											<input className="filter-display-name itemname" defaultValue={savedfilterDisplayName} data-name={that.columnDisplayNames} />
											<button className={`btn btn-transparent filter-toggle ${filterON}`} title={filterText}><i className={`fa fa-toggle-on ${filterIconOff}`}></i></button>
											<span style={{ color: "#ff0000" }} id="errmsgforemptyfilter" data-name={that.columnDisplayNames}></span>
											<button className={`btn btn-transparent ${drillthroughON} drillthroughcheck`} title={drillthroughText} data-id={that.columnDisplayName} ><span>{lang["storyboard.filterdrill"]}</span><i class={`fa fa-toggle-on ${drillthroughIconOff}`}></i></button>
											<button className="btn btn-transparent btn-sm filter-titile-update rightfiltersapply"><i className="fa fa-check fa-green"></i></button>
										</span>
										<div className="advancedfilters">
											<button className="btn btn-link pull-right btn-sm showadvancedfilters" onClick={that.showadvancedfilters} id="showadvancedfilters">{lang["storyboard.advanced"]}</button>
										</div>
										<div className="clear"></div>
										<div className="marg-righ-15 marg-left-15">
											<div id="numberrangeslider">
												<input data-type={that.columnType} data-parent={that.tableName} data-child={that.columnName} displayname={that.columnDisplayName} data-name={that.columnDisplayNames} />
											</div>
										</div>
										<ul class="list-group scrollsetul">
											<div className="row-fluid">
												<div className="intfiltercontainer" style={{ display: "none" }}>
													<span>{lang["storyboard.or"]}<br></br></span>
													<div className="intfiltervalue">
														<label>{lang["storyboard.greater"]}</label>
														<input type="number" className="filtergreaterthanvalue" id="greaterthanvalue" />
														<span className="filtergreaterthanvalue filter-error pull-left hide"></span>
													</div>
													<div className="intfiltervalue">
														<label>{lang["storyboard.lesser"]}</label>
														<input type="number" className="filterlessthanvalue" id="lessthanvalue" />
														<span className="filterlessthanvalue filter-error pull-left  hide"></span>
													</div>
													<span>{lang["storyboard.or"]}<br></br></span>
													<div class="intfiltervalue">
														<label>{lang["storyboard.equalto"]}</label>
														<input type="number" class="filterequalsvalue" id="equalvalue" />
														<span class="filterequalsvalue filter-error pull-left hide"></span>
													</div>
													<span>{lang["storyboard.or"]}<br></br></span>
													<div class="intfiltervalue">
														<label>{lang["storyboard.in"]}</label>
														<input type="text" class="filterinvalue" onKeyPress={this.validateInFilters} id="invalue" />
														<span class="filterinvalue filter-error pull-left  hide"></span>
													</div>
												</div>
											</div>
										</ul>
										<div className="container-fluid"><br></br>
											<input type="text" className="hidden" placeholder="CommaSeparated Values" id={`customfiltervalue${that.columnDisplayNames}`} />
										</div>
										<button type="button" className="btn btn-transparent pull-left" id="delete" onClick={that.deleteaccordian} name={that.columnDisplayName} title={lang["storyboard.removefilter"]}><i className="fa fa-trash-o" style={{ fontSize: "17px" }}></i></button>
										{(isSingleReport == 1) ? (
											<button className="btn btn-transparent btn-sm pull-right applysavedfilter" tab-id={tabid} id="applysavedfilterview" title={lang["storyboard.applyfilters"]}><i className="fa fa-check marg-righ-5"></i>{lang["storyboard.applyfilters"]}</button>
										) :
											<button className="btn btn-transparent btn-sm pull-right rightfiltersapply" tab-id={tabid} id="rightfiltersapplys" title={lang["storyboard.applyfilters"]}><i className="fa fa-check marg-righ-5"></i>{lang["storyboard.applyfilters"]}</button>
										}
										{(that.columnType != 'number') ? (
											<button className="btn btn-transparent btn-sm pull-right" title={lang["storyboard.cancelbtn"]} id={`filtersrename${removedsplcharscolumnName}`} data-toggle="collapse" data-parent="#filteraccordion" href={`#acc${columnDisplayNamesAfterRename}_${time}`} aria-expanded="false"><i className="fa fa-times marg-righ-5"></i>{lang["storyboard.cancelbtn"]}</button>
										) : (that.columnType == 'all' || that.columnType == 'list' || that.columnType == 'range') ? (
											<button className="btn btn-transparent btn-sm pull-right" title={lang["storyboard.cancelbtn"]} data-toggle="collapse" data-parent="#filteraccordion" href={`#acc${columnDisplayNamesAfterRename}_${time}`} aria-expanded="false"><i className="fa fa-times marg-righ-5"></i>{lang["storyboard.cancelbtn"]}</button>
										) :
												<button className="btn btn-transparent btn-sm pull-right" title={lang["storyboard.cancelbtn"]} id={`filtersrename${removedsplcharscolumnName}`} data-toggle="collapse" data-parent="#filteraccordion" href={`#acc${columnDisplayNamesAfterRename}_${time}`} aria-expanded="false"><i className="fa fa-times marg-righ-5"></i>{lang["storyboard.cancelbtn"]}</button>
										}
									</div>
								</div>

							) : (that.columnType == "all") ? (

								<div id={`acc${columnNameAfterRename}_${time}`} data-id={`acc${that.columnName}`} className={`${time} panel-collapse collapse panelboc-content filtercustomparamerevalues`}>
									<div className="panelboc-body">
										<span className={`filter-display-name-container ${isFilterRenameAllwoed}`}>
											<span className="actualfiltername" title={that.columnDisplayNames}><span>{that.columnDisplayName}</span></span>
											<input className="filter-display-name itemname" defaultValue={savedfilterDisplayName} data-name={that.columnDisplayNames} />
											<button className={`btn btn-transparent filter-toggle ${filterON}`} title={filterText}><i className={`fa fa-toggle-on ${filterIconOff}`}></i></button>
											<span style={{ color: "#ff0000" }} id="errmsgforemptyfilter" data-name={that.columnDisplayNames}></span>
											<button className={`btn btn-transparent ${drillthroughON} drillthroughcheck`} title={drillthroughText} data-id={that.columnDisplayName} ><span>{lang["storyboard.filterdrill"]}</span><i class={`fa fa-toggle-on ${drillthroughIconOff}`}></i></button>
											<button className="btn btn-transparent btn-sm filter-titile-update rightfiltersapply"><i className="fa fa-check fa-green"></i></button>
										</span>
										<div className="clear"></div>
										<div className="row-fluid">
											<div className="intfiltercontainer ">
												<div className="intfiltervalue">
													<label><div className="attraxisnames">{lang["storyboard.currentval"]}</div></label>
													<input type="number" className="filtergreaterthanvalue" id="parametervalue" displayname={that.columnDisplayName} data-name={that.columnName} data-value={that.value} data-columnType={that.columnType} defaultValue={that.value} name="parametervalue" />
												</div>
											</div>
											<button type="button" className="btn btn-transparent pull-left" id="delete" onClick={that.deleteaccordian} name={that.columnDisplayName} title={lang["storyboard.removefilter"]}><i className="fa fa-trash-o" style={{ fontSize: "17px" }}></i></button>
											{(isSingleReport == 1) ? (
												<button className="btn btn-transparent btn-sm pull-right applysavedfilter" tab-id={tabid} id="applysavedfilterview" title={lang["storyboard.applyfilters"]}><i className="fa fa-check marg-righ-5"></i>{lang["storyboard.applyfilters"]}</button>
											) :
												<button className="btn btn-transparent btn-sm pull-right rightfiltersapply" tab-id={tabid} id="rightfiltersapplys" title={lang["storyboard.applyfilters"]}><i className="fa fa-check marg-righ-5"></i>{lang["storyboard.applyfilters"]}</button>
											}
											{(that.columnType != 'number') ? (
												<button className="btn btn-transparent btn-sm pull-right" title={lang["storyboard.cancelbtn"]} id={`filtersrename${removedsplcharscolumnName}`} data-toggle="collapse" data-parent="#filteraccordion" href={`#acc${columnDisplayNamesAfterRename}_${time}`} aria-expanded="false"><i className="fa fa-times marg-righ-5"></i>{lang["storyboard.cancelbtn"]}</button>
											) : (that.columnType == 'all' || that.columnType == 'list' || that.columnType == 'range') ? (
												<button className="btn btn-transparent btn-sm pull-right" title={lang["storyboard.cancelbtn"]} data-toggle="collapse" data-parent="#filteraccordion" href={`#acc${columnDisplayNamesAfterRename}_${time}`} aria-expanded="false"><i className="fa fa-times marg-righ-5"></i>{lang["storyboard.cancelbtn"]}</button>
											) :
													<button className="btn btn-transparent btn-sm pull-right" title={lang["storyboard.cancelbtn"]} id={`filtersrename${removedsplcharscolumnName}`} data-toggle="collapse" data-parent="#filteraccordion" href={`#acc${columnDisplayNamesAfterRename}_${time}`} aria-expanded="false"><i className="fa fa-times marg-righ-5"></i>{lang["storyboard.cancelbtn"]}</button>
											}
										</div>
									</div>
								</div>
							) : (that.columnType == "list") ? (
								<div id={`acc${columnNameAfterRename}_${time}`} data-id={`acc${that.columnName}`} className={`${time} panel-collapse collapse panelboc-content filtercustomparamerelist`}>
									<div className="panelboc-body">
										<span className={`filter-display-name-container ${isFilterRenameAllwoed}`}>
											<span className="actualfiltername" title={that.columnDisplayName}><span>{that.columnDisplayName}</span></span>
											<input className="filter-display-name itemname" defaultValue={savedfilterDisplayName} data-name={that.columnDisplayNames} />
											<button className={`btn btn-transparent filter-toggle ${filterON}`} title={filterText}><i className={`fa fa-toggle-on ${filterIconOff}`}></i></button>
											<span style={{ color: "#ff0000" }} id="errmsgforemptyfilter" data-name={that.columnDisplayNames}></span>
											<button className={`btn btn-transparent ${drillthroughON} drillthroughcheck`} title={drillthroughText} data-id={that.columnDisplayName} ><span>{lang["storyboard.filterdrill"]}</span><i class={`fa fa-toggle-on ${drillthroughIconOff}`}></i></button>
											<button className="btn btn-transparent btn-sm filter-titile-update rightfiltersapply"><i className="fa fa-check fa-green"></i></button>
										</span>
										<div className="clear"></div>
										<div className="row-fluid marg-bot-10">
											<label className="listparametervalues"><div className="attraxisnames">{lang["storyboard.currentval"]}</div></label>
											<select placeholder="" className="form-control selectedparametervalue" name="roleId">
												{that.parameterlistvlaues.map((values, index) => {
													return (values.parameterlistvalue == that.parametercurrentvalue) ? (
														<option key={index} value={values.parameterlistvalue} selected>{values.parameterlistdisplayname}</option>
													) :
														<option key={index} value={values.parameterlistvalue}>{values.parameterlistdisplayname}</option>

												})}
											</select>
											<div className="irs-hidden-input">
												<input type="number" className="filtergreaterthanvalue " id="parametervalue" displayname={that.columnDisplayName} data-name={that.columnName} data-value={that.value} data-columnType={that.columnType} defaultValue={that.value} name="parametervalue" disabled />
											</div>
										</div>
										<button type="button" className="btn btn-transparent pull-left marg-left-5" id="delete" onClick={that.deleteaccordian} name={that.columnDisplayName} title={lang["storyboard.removefilter"]}><i className="fa fa-trash-o" style={{ fontSize: " 17px" }}></i></button>
										{(isSingleReport == 1) ? (
											<button className="btn btn-transparent btn-sm pull-right applysavedfilter" tab-id={tabid} id="applysavedfilterview" title={lang["storyboard.applyfilters"]}><i className="fa fa-check marg-righ-5"></i>{lang["storyboard.applyfilters"]}</button>
										) :
											<button className="btn btn-transparent btn-sm pull-right rightfiltersapply" tab-id={tabid} id="rightfiltersapplys" title={lang["storyboard.applyfilters"]}><i className="fa fa-check marg-righ-5"></i>{lang["storyboard.applyfilters"]}</button>
										}
										{(that.columnType != 'number') ? (
											<button className="btn btn-transparent btn-sm pull-right" title={lang["storyboard.cancelbtn"]} id={`filtersrename${removedsplcharscolumnName}`} data-toggle="collapse" data-parent="#filteraccordion" href={`#acc${columnDisplayNamesAfterRename}_${time}`} aria-expanded="false"><i className="fa fa-times marg-righ-5"></i>{lang["storyboard.cancelbtn"]}</button>
										) : (that.columnType == 'all' || that.columnType == 'list' || that.columnType == 'range') ? (
											<button className="btn btn-transparent btn-sm pull-right" title={lang["storyboard.cancelbtn"]} data-toggle="collapse" data-parent="#filteraccordion" href={`#acc${columnDisplayNamesAfterRename}_${time}`} aria-expanded="false"><i className="fa fa-times marg-righ-5"></i>{lang["storyboard.cancelbtn"]}</button>
										) :
												<button className="btn btn-transparent btn-sm pull-right" title={lang["storyboard.cancelbtn"]} id={`filtersrename${removedsplcharscolumnName}`} data-toggle="collapse" data-parent="#filteraccordion" href={`#acc${columnDisplayNamesAfterRename}_${time}`} aria-expanded="false"><i className="fa fa-times marg-righ-5"></i>{lang["storyboard.cancelbtn"]}</button>
										}
									</div>
								</div>

							) : (that.columnType == "range") ? (

								<div id={`acc${columnNameAfterRename}_${time}`} data-id={`acc${that.columnName}`} className={`${time} panel-collapse collapse in panelboc-content filtercustomparamererangevalues`}>
									<div className="panelboc-body">
										<span className={`filter-display-name-container ${isFilterRenameAllwoed}`}>
											<span className="actualfiltername" title={that.columnDisplayName}><span>{that.columnDisplayName}</span></span>
											<input className="filter-display-name itemname" defaultValue={savedfilterDisplayName} data-name={that.columnDisplayNames} />
											<button className={`btn btn-transparent filter-toggle ${filterON}`} title={filterText}><i className={`fa fa-toggle-on ${filterIconOff}`}></i></button>
											<span style={{ color: "#ff0000" }} id="errmsgforemptyfilter" data-name={that.columnDisplayNames}></span>
											<button className={`btn btn-transparent ${drillthroughON} drillthroughcheck`} title={drillthroughText} data-id={that.columnDisplayName} ><span>{lang["storyboard.filterdrill"]}</span><i class={`fa fa-toggle-on ${drillthroughIconOff}`}></i></button>
											<button className="btn btn-transparent btn-sm filter-titile-update rightfiltersapply"><i className="fa fa-check fa-green"></i></button>
										</span>
										<div className="clear"></div>
										<div className="marg-righ-10 marg-left-10 marg-top-20">
											<div id="numberrangeslider">
												<input data-child={that.columnName} displayname={that.columnDisplayName} data-name={that.columnName} data-columnType={that.columnType} />
											</div>
										</div>
										<button type="button" className="btn btn-transparent pull-left" id="delete" onClick={that.deleteaccordian} name={that.columnDisplayName} title={lang["storyboard.removefilter"]}><i className="fa fa-trash-o" style={{ fontSize: "17px" }}></i></button>
										{(isSingleReport == 1) ? (
											<button className="btn btn-transparent btn-sm pull-right applysavedfilter" tab-id={tabid} id="applysavedfilterview" title={lang["storyboard.applyfilters"]}><i className="fa fa-check marg-righ-5"></i>{lang["storyboard.applyfilters"]}</button>
										) :
											<button className="btn btn-transparent btn-sm pull-right rightfiltersapply" tab-id={tabid} id="rightfiltersapplys" title={lang["storyboard.applyfilters"]}><i className="fa fa-check marg-righ-5"></i>{lang["storyboard.applyfilters"]}</button>
										}
										{(that.columnType != 'number') ? (
											<button className="btn btn-transparent btn-sm pull-right" title={lang["storyboard.cancelbtn"]} id={`filtersrename${removedsplcharscolumnName}`} data-toggle="collapse" data-parent="#filteraccordion" href={`#acc${columnDisplayNamesAfterRename}_${time}`} aria-expanded="false"><i className="fa fa-times marg-righ-5"></i>{lang["storyboard.cancelbtn"]}</button>
										) : (that.columnType == 'all' || that.columnType == 'list' || that.columnType == 'range') ? (
											<button className="btn btn-transparent btn-sm pull-right" title={lang["storyboard.cancelbtn"]} data-toggle="collapse" data-parent="#filteraccordion" href={`#acc${columnDisplayNamesAfterRename}_${time}`} aria-expanded="false"><i className="fa fa-times marg-righ-5"></i>{lang["storyboard.cancelbtn"]}</button>
										) :
												<button className="btn btn-transparent btn-sm pull-right" title={lang["storyboard.cancelbtn"]} id={`filtersrename${removedsplcharscolumnName}`} data-toggle="collapse" data-parent="#filteraccordion" href={`#acc${columnDisplayNamesAfterRename}_${time}`} aria-expanded="false"><i className="fa fa-times marg-righ-5"></i>{lang["storyboard.cancelbtn"]}</button>
										}
									</div>
								</div>
							) :
												<div id={`acc${columnDisplayNamesAfterRename}_${time}`} data-id={`acc${that.columnDisplayNames}`} className={`${time} panel-collapse collapse panelboc-content filterdatevalues`}>
													<div className="panelboc-body">
														<span className={`filter-display-name-container ${isFilterRenameAllwoed}`}>
															<span className="actualfiltername" title={that.columnDisplayName}><span>{that.columnDisplayName}</span></span>
															<input className="filter-display-name itemname" defaultValue={savedfilterDisplayName} data-name={that.columnDisplayNames} />
															<button className={`btn btn-transparent filter-toggle ${filterON}`} title={filterText}><i className={`fa fa-toggle-on ${filterIconOff}`}></i></button>
															<span style={{ color: "#ff0000" }} id="errmsgforemptyfilter" data-name={that.columnDisplayNames}></span>
															<button className={`btn btn-transparent ${drillthroughON} drillthroughcheck`} title={drillthroughText} data-id={that.columnDisplayName} ><span>{lang["storyboard.filterdrill"]}</span><i class={`fa fa-toggle-on ${drillthroughIconOff}`}></i></button>
															<button className="btn btn-transparent btn-sm filter-titile-update rightfiltersapply"><i className="fa fa-check fa-green"></i></button>
														</span>
														<div className="clear"></div>
														<ul className="list-group nomargin">
															<li className="boc-list-item singleliitems">
																<div className={(that.className == undefined) ? 'daterange-center' : that.className} id={`daterange${that.columnDisplayNames}`} data-type={that.columnType}
																	data-parent={that.tableName} data-child={that.columnName} displayname={that.columnDisplayName} data-name={that.columnDisplayNames} date-range={that.daterange}>
																	<i className="glyphicon glyphicon-calendar fa fa-calendar"></i> <span></span>
																	<b className="caret"></b>
																</div>
															</li>
														</ul>
														<button type="button" className="btn btn-transparent pull-left" id="delete" onClick={that.deleteaccordian} name={that.columnDisplayName} title={lang["storyboard.removefilter"]}><i className="fa fa-trash-o" style={{ fontSize: "17px" }}></i></button>
														{(isSingleReport == 1) ? (
															<button className="btn btn-transparent btn-sm pull-right applysavedfilter" tab-id={tabid} id="applysavedfilterview" title={lang["storyboard.applyfilters"]}><i className="fa fa-check marg-righ-5"></i>{lang["storyboard.applyfilters"]}</button>
														) :
															<button className="btn btn-transparent btn-sm pull-right rightfiltersapply" tab-id={tabid} id="rightfiltersapplys" title={lang["storyboard.applyfilters"]}><i className="fa fa-check marg-righ-5"></i>{lang["storyboard.applyfilters"]}</button>
														}
														{(that.columnType != 'number') ? (
															<button className="btn btn-transparent btn-sm pull-right" title={lang["storyboard.cancelbtn"]} id={`filtersrename${removedsplcharscolumnName}`} data-toggle="collapse" data-parent="#filteraccordion" href={`#acc${columnDisplayNamesAfterRename}_${time}`} aria-expanded="false"><i className="fa fa-times marg-righ-5"></i>{lang["storyboard.cancelbtn"]}</button>
														) : (that.columnType == 'all' || that.columnType == 'list' || that.columnType == 'range') ? (
															<button className="btn btn-transparent btn-sm pull-right" title={lang["storyboard.cancelbtn"]} data-toggle="collapse" data-parent="#filteraccordion" href={`#acc${columnDisplayNamesAfterRename}_${time}`} aria-expanded="false"><i className="fa fa-times marg-righ-5"></i>{lang["storyboard.cancelbtn"]}</button>
														) :
																<button className="btn btn-transparent btn-sm pull-right" title={lang["storyboard.cancelbtn"]} id={`filtersrename${removedsplcharscolumnName}`} data-toggle="collapse" data-parent="#filteraccordion" href={`#acc${columnDisplayNamesAfterRename}_${time}`} aria-expanded="true"><i className="fa fa-times marg-righ-5"></i>{lang["storyboard.cancelbtn"]}</button>
														}
													</div>
												</div>
							}
						</div>
				</div>
			) :
				<div id={that.columnDisplayNames} data-id={that.columnDisplayNames} className="acc-content" data-parent={that.tableName}>
					<div className="boc-accordian-options">
						<div className="btn-group pull-right" role="group" aria-label="...">
							{(that.columnType != 'date' && that.columnType != 'datetime' && that.columnType != 'number' && !(that.columnType == 'all' || that.columnType == 'list' || that.columnType == 'range')) ? (
								<button type="button" className="btn btn-accordian dropdown-toggle"
									data-toggle="dropdown" aria-expanded="false" id="selecthelper">
									<i className="fa fa-cog"></i>
								</button>
							) : null}

							<ul className="dropdown-menu checkhelp" role="menu"
								aria-labelledby="selecthelper">
								<li title="" className="aggregation-li-item"><label onClick={that.selectall} name={that.columnDisplayNames}><input type="radio"
									id="checkall" onClick={that.selectall} name={that.columnDisplayNames} />&nbsp;{lang["storyboard.slctall"]}</label></li>
								<li title="" className="aggregation-li-item"><label onClick={that.unselectall} name={that.columnDisplayNames}><input type="radio"
									id="uncheckall" onClick={that.unselectall} name={that.columnDisplayNames} />&nbsp;{lang["storyboard.dslctall"]}</label></li>
							</ul>
							<button type="button" className="btn btn-accordian" id="delete" onClick={that.deleteaccordian} name={that.columnDisplayName}>
								<i className="fa fa-close"></i>
							</button>
						</div>
					</div>

					<div className="panelboc">
						<div className="panelboc-heading" onClick={that.collapseother} data-type={that.columnType}>


							{(that.columnType == 'date' || that.columnType == 'datetime') ? (
								<h4 className="panelboc-title accordion-toggle collapsed" title={that.columnDisplayName} id={`filtersrename${removedsplcharscolumnName}`} data-toggle="collapse" data-parent="#filteraccordion" href={`#acc${columnDisplayNamesAfterRename}_${time}`} aria-expanded="true"><span className="itemtype"><i className='fa fa-calendar'></i></span>{that.columnDisplayName}</h4>
							) : (that.columnType != 'number') ? (
								<h4 className="panelboc-title accordion-toggle collapsed" title={that.columnDisplayName} id={`filtersrename${removedsplcharscolumnName}`} data-toggle="collapse" data-parent="#filteraccordion" href={`#acc${columnDisplayNamesAfterRename}_${time}`} aria-expanded="false"><span className="itemtype">{dataIcon}</span>{that.columnDisplayName}</h4>
							) : (that.columnType == 'all' || that.columnType == 'list' || that.columnType == 'range') ? (
								<h4 className="panelboc-title accordion-toggle collapsed" title={that.columnName} data-toggle="collapse" data-parent="#filteraccordion" href={`#acc${columnDisplayNamesAfterRename}_${time}`} aria-expanded="false"><span className="itemtype">{dataIcon}</span>{that.columnName}</h4>
							) :
										<h4 className="panelboc-title accordion-toggle" title={that.columnDisplayName} id={`filtersrename${removedsplcharscolumnName}`} data-toggle="collapse" data-parent="#filteraccordion" href={`#acc${columnDisplayNamesAfterRename}_${time}`} aria-expanded="true"><span className="itemtype">{dataIcon}</span>{that.columnDisplayName}</h4>
							}
						</div>
						{(that.columnType != 'date' && that.columnType != 'datetime' && that.columnType != 'number' && !(that.columnType == 'all' || that.columnType == 'list' || that.columnType == 'range')) ? (
							<div id={`acc${columnDisplayNamesAfterRename}_${time}`} data-id={`acc${that.columnDisplayNames}`} className={`${time} panel-collapse collapse panelboc-content filterStringvalues`}>
								<div className="panelboc-body">
									<div className="advancedfilters">
										<button className="btn btn-link pull-right btn-sm showadvancedfilters" onClick={that.showadvancedfilters}>{lang["storyboard.advanced"]}</button>
									</div>
									<div className="container-fluid marg-top-15">
										<div className="row marg-bot-10 filtersearching " style={{ display: "none" }}>
											{(that.filterValues && that.filterValues.length != 0) ? (
												<>	<div className="stringfilters searching ">
													{lang["storyboard.startswith"]} <input type="text" id={`${that.columnDisplayNames}start`} data-id={`${that.columnDisplayNames}start`} className="searchword" name={that.columnName} data-type={that.columnType}
														data-parent={that.tableName} data-child={that.columnName} data-name={that.columnDisplayNames} displayname={that.columnDisplayName} />
												</div>
													<div className="stringfilters searching">
													{lang["storyboard.endswith"]} <input type="text" id={`${that.columnDisplayNames}end`} data-id={`${that.columnDisplayNames}end`} name={that.columnName} data-type={that.columnType}
															data-parent={that.tableName} data-child={that.columnName} data-name={that.columnDisplayNames} displayname={that.columnDisplayName} />
													</div>
													<div className="stringfilters searching col-lg-10 col-md-10 col-sm-12 col-xs-12" style={{ marginLeft: "-5px" }}>
													{lang["storyboard.contains"]} <input type="text" id={`${that.columnDisplayNames}contains`} data-id={`${that.columnDisplayNames}contains`} name={that.columnName} data-type={that.columnType}
															data-parent={that.tableName} data-child={that.columnName} data-name={that.columnDisplayNames} displayname={that.columnDisplayName} />
													</div>
													<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12" style={{ marginTop: "1px" }}>
														<button type="submit" id="searchfiltersdata" onClick={that.searchfiltersdata} name={that.columnName} data-type={that.columnType}
															data-parent={that.tableName} data-child={that.columnName} data-name={that.columnDisplayNames} displayname={that.columnDisplayName} script={that.script} className="btn btn-sm btn-bird marg-top-20 btn-highlight">{lang["storyboard.go"]}</button>
													</div></>
											) : null}
										</div>
									</div>
									<ul className="list-group nomargin checkhelpitems">
										{(that.filterValues && that.filterValues.length != 0) ? (
											that.filterValues.map((filterValue) => {
												var i = 0;
												if(that.filterArray!=undefined&&that.filterArray.length>=0){
													that.filterArray.map((filterData) => {
														if (filterData.columndisplayname == that.columnDisplayName && filterValue.value == filterData.data) {
															i++;
														}
													})
											    }
												var value = filterValue.value;
												var encodedvalue = (value + '').replace(/([;?&,%#+*~\'"!^$[\]()<=>`|\/@])/g, "")
												return (i == 0) ? (
													<li className="boc-list-item valueoffilters"><label title={filterValue.value} ><input type="checkbox"
														className={(that.className == undefined) ? 'filtervalue' : that.className} data-type={that.columnType}
														data-parent={that.tableName} data-child={that.columnName} data-name={that.columnDisplayNames} displayname={that.columnDisplayName} data-value={encodedvalue} script={that.script} />{filterValue.value}</label></li>
												) :
													<li className="boc-list-item valueoffilters"><label title={filterValue.value} ><input type="checkbox"
														className={(that.className == undefined) ? 'filtervalue' : that.className} data-type={that.columnType}
														data-parent={that.tableName} data-child={that.columnName} data-name={that.columnDisplayNames} displayname={that.columnDisplayName} data-value={encodedvalue} defaultChecked script={that.script} />{filterValue.value}</label></li>

											})
										) : null}
									</ul>

								</div>
							</div>
						) : (that.columnType === 'number') ? (
							<div id={`acc${columnDisplayNamesAfterRename}_${time}`} data-id={`acc${that.columnDisplayNames}`} className={`${time} panel-collapse collapse  in panelboc-content ${(that.className == undefined) ? 'filterrangevalues' : that.className}`}>
								<div className="panelboc-body">
									<div className="advancedfilters">
										<button className="btn btn-link pull-right btn-sm showadvancedfilters" onClick={that.showadvancedfilters} id="showadvancedfilters">{lang["storyboard.advanced"]}</button>
									</div>
									<div className="clear"></div>
									<div className="marg-righ-15 marg-left-15">
										<div id="numberrangeslider">
											<input data-type={that.columnType} data-parent={that.tableName} data-child={that.columnName} displayname={that.columnDisplayName} data-name={that.columnDisplayNames} />
										</div>
									</div>
									<ul class="list-group scrollsetul">
										<div className="row-fluid">
											<div className="intfiltercontainer" style={{ display: "none" }}>
												<span>{lang["storyboard.or"]}<br></br></span>
												<div className="intfiltervalue">
													<label>{lang["storyboard.greater"]}</label>
													<input type="number" className="filtergreaterthanvalue" id="greaterthanvalue" />
													<span className="filtergreaterthanvalue filter-error pull-left hide"></span>
												</div>
												<div className="intfiltervalue">
													<label>{lang["storyboard.lesser"]}</label>
													<input type="number" className="filterlessthanvalue" id="lessthanvalue" />
													<span className="filterlessthanvalue filter-error pull-left  hide"></span>
												</div>
												<span>{lang["storyboard.or"]}<br></br></span>
												<div class="intfiltervalue">
													<label>{lang["storyboard.equalto"]}</label>
													<input type="number" class="filterequalsvalue" id="equalvalue" />
													<span class="filterequalsvalue filter-error pull-left hide"></span>
												</div>
												<span>{lang["storyboard.or"]}<br></br></span>
												<div class="intfiltervalue">
													<label>{lang["storyboard.in"]}</label>
													<input type="text" class="filterinvalue" onKeyPress={this.validateInFilters} id="invalue" />
													<span class="filterinvalue filter-error pull-left  hide"></span>
												</div>
											</div>
										</div>
									</ul>
									<div className="container-fluid"><br></br>
										<input type="text" className="hidden" placeholder={lang["storyboard.placeholdcomma"]} id={`customfiltervalue${that.columnDisplayNames}`} />
									</div>

								</div>
							</div>

						) : (that.columnType == "all") ? (
							<div id={`acc${columnNameAfterRename}_${time}`} data-id={`acc${that.columnName}`} className={`${time} panel-collapse collapse  in panelboc-content filtercustomparamerevalues`}>
								<div className="panelboc-body">
									<div className="row-fluid">
										<div className="intfiltercontainer ">
											<div className="intfiltervalue">
												<label>{lang["storyboard.currentval"]}</label>
												<input type="number" className="filtergreaterthanvalue" id="parametervalue" displayname={that.columnDisplayName} data-name={that.columnName} data-value={that.value} data-columnType={that.columnType} defaultValue={that.value} name="parametervalue" />
											</div>
										</div>
									</div>
								</div>
							</div>
						) : (that.columnType == "list") ? (
							<div id={`acc${columnNameAfterRename}_${time}`} data-id={`acc${that.columnName}`} className={`${time} panel-collapse collapse  in panelboc-content filtercustomparamerelist`}>
								<div className="panelboc-body">
									<div className="container-fluid">
										<select placeholder="" className="form-control selectedparametervalue" name="roleId">
											{that.parameterlistvlaues.map((values) => {
												return (values.parameterlistvalue == that.parametercurrentvalue) ? (
													<option value={values.parameterlistvalue} selected>{values.parameterlistdisplayname}</option>
												) :
													<option value={values.parameterlistvalue}>{values.parameterlistdisplayname}</option>

											})}
										</select>
										<div className="irs-hidden-input">
											<input type="number" className="filtergreaterthanvalue " id="parametervalue" displayname={that.columnDisplayName} data-name={that.columnName} data-value={that.value} data-columnType={that.columnType} defaultValue={that.value} name="parametervalue" disabled />
										</div>
									</div>
								</div>
							</div>
						) : (that.columnType == "range") ? (
							<div id={`acc${columnNameAfterRename}_${time}`} data-id={`acc${that.columnName}`} className={`${time} panel-collapse collapse  in panelboc-content filtercustomparamererangevalues`}>
								<div className="panelboc-body">
									<div className="marg-righ-10 marg-left-10">
										<div id="numberrangeslider">
											<input data-child={that.columnName} displayname={that.columnDisplayName} data-name={that.columnName} data-columnType={that.columnType} />
										</div>
									</div>
								</div>
							</div>
						) :
											<div id={`acc${columnDisplayNamesAfterRename}_${time}`} data-id={`acc${that.columnDisplayNames}`} className={`${time} panel-collapse collapse in panelboc-content filterdatevalues`}>
												<div className="panelboc-body nopadding">
													<ul className="list-group nomargin">
														<li className="boc-list-item singleliitems">
															<div className={(that.className == undefined) ? 'daterange-center' : that.className} id={`daterange${that.columnDisplayNames}`} data-type={that.columnType}
																data-parent={that.tableName} data-child={that.columnName} displayname={that.columnDisplayName} data-name={that.columnDisplayNames} date-range={that.daterange}>
																<i className="glyphicon glyphicon-calendar fa fa-calendar"></i> <span></span>
																<b className="caret"></b>
															</div>
														</li>

													</ul>

												</div>
											</div>
						}
					</div>
				</div>
			}

		</>);
	}
}