import React from 'react';
import { lang } from "../js/helpers/utils/languages/language";
import _ from 'underscore';
import { data } from 'jquery';
const $ = window.$;

export default class DatahubSyncconfigHtml extends React.Component {

    componentDidMount() {
        setTimeout(function () {
            $('.spinner').show();
        }, 400)
    }
    render() {
        var that = this;
        var counti = -1
        var active = '';
        return (
            <React.Fragment>
                <div>
                    <div class="col-xs-12 col-md-12 col-sm-12 col-lg-4">
                        <div id="finalconnectionlistcontainer">
                            <h2>{lang["datahub.slctdconnection"]} <input class="form-control search-image" onKeyUp={that.props.syncFinalConnectionsSearch} name="syncfinalconnectionssearch" placeholder={lang["datahub.search"]} id="syncfinalconnectionssearch" type="text" /></h2>
                            <div class="panel-group panel">
                                {
                                    Object.entries(this.props.groupedConnections).map(([datatype, connectionslist]) => {
                                        { counti++ }
                                        var cname=datatype
                                        if(datatype=='XLS'||datatype=='xls'){
                                            cname="EXCEL (xls / xlsx)"
                                        }
                                        return (
                                            <>
                                                <h4 href={`#${datatype}${counti}`} class="panelboc-title accordion-toggle collapsed" title={datatype} data-id={counti} data-toggle="collapse" data-parent="#finalconnectionlistcontainer" aria-expanded="false" aria-controls={datatype}><img src={require(`../images/birdimages/${datatype.toLocaleLowerCase()}.png`)} /> {cname}</h4>
                                                <div id={`${datatype}${counti}`} name={datatype} data-id={counti} class="panel-collapse panelboc-content collapse" aria-expanded="false" style={{ height: '0px' }}>
                                                    <div class="panelboc-body" style={{ maxHeight: "initial" }}>
                                                        <ul class="list-group">
                                                            {
                                                                connectionslist.map((connectioninfo, k) => {
                                                                    {
                                                                        (counti == 0 && k == 0) ? (
                                                                            active = "active"
                                                                        ) : (
                                                                                active = ""
                                                                            )
                                                                    }
                                                                    return (
                                                                        <>
                                                                            <li class={"list-group-item finalconnectionlist-item " + active} onClick={that.props.showSelectedEntities} title={connectioninfo.sourceName} source-id={connectioninfo.sourceID} source-type={connectioninfo.sourceType}><i class="fa fa-link fa-solid fa-flip-horizontal marg-righ-10"></i> {connectioninfo.sourceName}</li>
                                                                        </>
                                                                    );
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>

                                            </>

                                        )
                                    })

                                }
                            </div>
                        </div>
                    </div>


                    {/* <!-- datahubsyncconfigview.js --> */}


                    <div className="col-xs-12 col-md-12 col-sm-12 col-lg-6 nopadding">
                        {/* <div class="col-xs-12 col-md-12 col-sm-12 col-lg-6 center-block nopadding" style={{ float: "none" }}> */}
                        <div id="synctablescontainer">
                            <h2>{lang["datahub.syncconfigname"]} <b>'<span></span>'</b> {lang["datahub.connection"]} <input class="form-control search-image" name="syncfinalentitysearch" placeholder={lang["datahub.search"]} onKeyUp={that.props.syncFinalEntitySearch} id="syncfinalentitysearch" type="text" /></h2>
                            <div className="panel-group panel">
                                {/* <!-- Loop Tables --> */}
                                {
                                    this.props.entities.map((entity, index) => {
                                        if (entity.type !== "F" && entity.type !== 'birdinmemory' && entity.type !== 'rabbitmq' && entity.type !== 'websocket' && entity.type !== 'kafka' && entity.type !== 'eventhub') {
                                            return (
                                                <>
                                                    <h4
                                                        className="panelboc-title accordion-toggle"
                                                        title={entity.name}
                                                        data-id={entity.connaccessid}
                                                        onClick={this.props.showSelected}
                                                        id="conns"
                                                        data-toggle="collapse"
                                                        href={`#${entity.dupname}${entity.connaccessid}`}
                                                        data-parent="#synctablescontainer"
                                                        aria-expanded="false"
                                                        aria-controls={entity.name}
                                                        sync-status={entity.syncStatus!=undefined?entity.syncStatus:"Active"}
                                                    >
                                                        <i className="fa fa-table"></i>{entity.name}
                                                    </h4>
                                                    <div
                                                        id={`${entity.dupname}${entity.connaccessid}`}
                                                        name={entity.name}
                                                        data-id={entity.connaccessid}
                                                        className="panel-collapse panelboc-content collapse"
                                                        aria-expanded="false"
                                                    >
                                                        <div className="panelboc-body" style={{ maxHeight: "initial" }}>
                                                            {(entity.type !== "storedprocedure") ? (
                                                              <>  <fieldset className="savereport-border">
                                                                    <legend className="savereport-border">{lang["datahub.dataloadtitle"]}</legend>
                                                                    <div className="container-fluid marg-top-10">
                                                                        <div className="col-xs-12 marg-bot-10">
                                                                            <span className="title"><strong>{lang["datahub.removedata"]}</strong></span>
                                                                            <div className="checkbox checkbox-inline folderview" id="removeduplicatedataid" data-name={entity.name} data-id={entity.connaccessid}>
                                                                                <label className="checkbox-slider--c">
                                                                                    <input type="checkbox" name={`datasyncduplicatename${entity.dupname}${entity.connaccessid}`} data-name={entity.name} data-dupname={entity.dupname} data-id={entity.connaccessid}  onClick={this.props.datasyncduplicatefields} class="dashboard-view-style datasyncduplicatefields" />
                                                                                    <span></span>
                                                                                </label>

                                                                            </div>
                                                                        </div>
                                                                        <div className="uniquefieldcontainer hide" name={entity.name} data-id={entity.connaccessid}>
                                                                            <div className="col-xs-12">
                                                                                <label htmlFor="uniqueselect"><strong>{lang["datahub.uniqefield"]}</strong></label>
                                                                            </div>
                                                                            <div className="col-lg-8">
                                                                                <span className="required" style={{ position: "absolute", display: "block", float: "left", top: "-3px", left: "2px", fontSize: "24px", fontWeight: "400", textAlign: "right", color: "FD9800", width: "2px", height: "2px" }}>*</span>
                                                                                <select className="form-control input-sm" id="uniqueselect" className="uniqueselect" data-id={entity.connaccessid} name={entity.name} data-placeholder={lang["datahub.chooseclmn"]} style={{ maxWidth: "200px" }} tabIndex="-1" multiple>
                                                                                    <optgroup className="uniqueselectfield_group"></optgroup>
                                                                                </select>
                                                                                <span className="help-block uniqueselect_error hidden" name={entity.name} data-id={entity.connaccessid} style={{ color: "#ff0606 !important" }}>{lang["datahub.slctfield"]}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="clear"></div>
                                                                        <div className="row marg-bot-10 datasynccontrollers1">
                                                                            <div className="control-group col-lg-12">
                                                                                <div className="col-lg-12 marg-top-5">
                                                                                    <div className="syncselector">
                                                                                        <label><input type="radio" name={`${entity.name}${entity.connaccessid}`} id="loadmetadata" data-name={entity.name} data-id={entity.connaccessid} className="datasyncconfigtype datasyncconfiguration" defaultChecked onClick={(e) => { this.props.changedataloadconfigtype(e) }} />&nbsp;{lang["datahub.meta"]}</label>
                                                                                    </div>
                                                                                    <div className="syncselector">
                                                                                        <label><input type="radio" name={`${entity.name}${entity.connaccessid}`} id="loadalldata" data-name={entity.name} data-id={entity.connaccessid} className="datasyncconfigtype datasyncconfiguration"  onClick={(e) => { this.props.changedataloadconfigtype(e) }} />&nbsp;{lang["datahub.alldata"]}</label>
                                                                                    </div>
                                                                                    <div className="clear"></div>
                                                                                    <p className="fa-red" id="loadmetadatanoteitem" data-name={entity.name} data-id={entity.connaccessid}>
                                                                                        <i>{lang["datahub.loadmetadatanoteitem"]}</i>

                                                                                    </p>
                                                                                    <div className="clear"></div>
                                                                                    <div className="synfilterscselector hide" data-name={entity.name} data-id={entity.connaccessid}>
                                                                                        <label><input type="checkbox" name={`${entity.name}${entity.connaccessid}`} id="selecteddata" data-name={entity.name} data-id={entity.connaccessid} className="datasyncconfigtype datasyncconfiguration" onClick={(e) => { this.props.changedatasyncconfigtype(e) }} />&nbsp;{lang["datahub.daterange"]}</label>
                                                                                    </div>
                                                                                    <div className="synfilterscselector hide" data-name={entity.name} data-id={entity.connaccessid}>
                                                                                        <label><input type="checkbox" name={`${entity.name}${entity.connaccessid}`} id="selectednumericrange" data-name={entity.name} data-id={entity.connaccessid} className="datasyncconfigtype datasyncconfiguration" onClick={(e) => { this.props.changedatasyncconfigtype(e) }} />&nbsp;{lang["datahub.numrange"]}</label>
                                                                                    </div>
                                                                                </div>
                                                                                 {/* <!-- \All Options Container --> */}
                                                                     {/* <!-- Date Range Options Container --> */}
                                                                     <div className="row selectdatacontainer" data-id={entity.connaccessid} name={entity.name}>
                                                                         <div className="col-xs-12">
                                                                             <label><strong>{lang["datahub.choosedateclmn"]}</strong></label>
                                                                             <button className="btn btn-transparent marg-righ-10 dropdown-toggle tooltip-right" data-id={entity.connaccessid} data-name={entity.name} data-tooltip={lang["Datahub.ADDcolumn"]} id="addnewselecteddatesyncrange" onClick={(e) => { this.props.addnewselecteddatesyncrange(e) }}><i className="fa fa-plus-circle"></i></button>
                                                                             <div className="control-group form-inline marg-top-10 append_customsyncdatarangediv" data-id={entity.connaccessid} data-name={entity.name}>
                                                                                 {/* <!-- To be Dynamic --> */}
                                                                                 <div className="control-group form-inline marg-top-10 customsyncdatarangediv" data-num="0" data-id={entity.connaccessid} name={entity.name} tab-id="0" tab-name="">
                                                                                     <div className="validationgroup form-group">
                                                                                         <label>{lang["datahub.datecolumn"]}&nbsp;</label>
                                                                                         <select className="form-control input-sm datefield" data-id={entity.connaccessid} name={entity.name} style={{ maxWidth: "200px" }}>
                                                                                         </select>&nbsp;
                                                                                 </div>
                                                                                     <div className="validationgroup form-group">
                                                                                         <label>{lang["datahub.daterange"]}&nbsp;</label>
                                                                                         <input type="text" className="selectdatasyncdaterange form-control input-sm" defaultValue="" data-id={entity.connaccessid} name={entity.name} />
                                                                                     </div>
                                                                                     <button className="btn btn-transparent marg-righ-10 dropdown-toggle tooltip-right removenewselecteddatesyncrange" id="removenewselecteddatestoryboard" data-tooltip={lang["datahub.remove"]} onClick={(e) => { this.props.removenewselecteddatesyncrange(e) }}>
                                                                                         <i className="fa fa-minus-circle"></i>
                                                                                     </button>
                                                                                 </div>
                                                                                 {/* <!-- \To be Dynamic --> */}
                                                                             </div>
                                                                             <span className="help-block selectdatasync_error hidden">{lang["datahub.slctdatefield"]}</span>
                                                                             <span className="help-block selectdatasync_multiple_column_error hidden">{lang["datahub.csndrasprt"]}</span>
                                                                         </div>
                                                                     </div>
                                                                     {/* <!-- \Date Range Options Container --> */}
                                                                     {/* <!-- Numeric Range Options Container --> */}
                                                                     <div className="row numericdatacontainer" data-id={entity.connaccessid} name={entity.name}>
                                                                         <div className="col-xs-12">
                                                                             <label><strong>{lang["datahub.choosenumclmn"]}</strong></label>
                                                                             <button className="btn btn-transparent marg-righ-10 dropdown-toggle tooltip-right" data-tooltip={lang["Datahub.ADDcolumn"]} data-id={entity.connaccessid} data-name={entity.name} id="addnewnumericdatasyncrange" onClick={(e) => { this.props.addnewnumericdatasyncrange(e) }}>
                                                                                 <i className="fa fa-plus-circle"></i>
                                                                             </button>
                                                                             <div className="control-group form-inline marg-top-10 append_customnumericsyncdatarangediv" data-id={entity.connaccessid} data-name={entity.name}>
                                                                                 {/* <!-- To be Dynamic --> */}
                                                                                 <div className="control-group form-inline marg-top-10 customsyncnumericrangediv" data-num="0" data-id={entity.connaccessid} name={entity.name} tab-id="0" tab-name="">
                                                                                     <div className="validationgroup form-group">
                                                                                         <label>{lang["datahub.numericcolumn"]}&nbsp;</label>
                                                                                         <select className="form-control input-sm numericfeild" data-id={entity.connaccessid} name={entity.name} style={{ maxWidth: "90px" }}>
                                                                                         </select>&nbsp;
                                                                                 </div>
                                                                                     <div className="validationgroup form-group">
                                                                                         <label>{lang["datahub.rangefm"]}&nbsp;</label> <input type="number" className="form-control input-sm numericsyncrangefrom" defaultValue data-id="0" />
                                                                                         <label>{lang["datahub.rangeto"]}&nbsp;</label> <input type="number" className="form-control input-sm numericsyncrangeto" defaultValue data-id="0" />
                                                                                     </div>
                                                                                     <button className="btn btn-transparent marg-righ-10 dropdown-toggle tooltip-right removenewnumericsyncrange" id="removenewnumericstoryboard" data-tooltip={lang["datahub.remove"]} onClick={(e) => { this.props.removenewselecteddatesyncrange(e) }}>
                                                                                         <i className="fa fa-minus-circle"></i>
                                                                                     </button>
                                                                                 </div>
                                                                                 {/* <!-- \To be Dynamic --> */}
                                                                             </div>
                                                                             <span className="help-block numericdatasync_error hidden">{lang["datahub.slctnumfield"]}</span>
                                                                             <span className="help-block numericdatasync_multiple_column_error hidden">{lang["datahub.csndrasprt"]}</span>
                                                                         </div>
                                                                     </div>
                                                                     {/* <!-- \Numeric Range Options Container --> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </fieldset>
                                                                 <fieldset className="savereport-border">
                                                                 <legend className="savereport-border">{lang["datahub.datasynctitle"]}</legend>
                                                                 <div className="container-fluid marg-top-10">
                                                                     {/* <!-- All Options Container --> */}
                                                                     <div className="row alldatacontainer disabled" data-id={entity.connaccessid} name={entity.name}>
                                                                         <div className="control-group form-inline ">
                                                                             <div className="validationgroup form-group incrementalrefreshcontainer">
                                                                                 <div className="col-xs-12">
                                                                                     <label htmlFor="incrementalrefresh">
                                                                                         <label><input type="radio" name={`datasyncrefreshtype${entity.connaccessid}${entity.name}`} className="datasyncconfiguration" id="incrementalrefresh" data-id={entity.connaccessid} data-entityname={entity.name} defaultChecked />
                                                                                &nbsp;<strong>{lang["datahub.incmntrfrsh"]}</strong></label>&nbsp;
                                                                                         <i style={{ fontSize: "13px" }}>{lang["datahub.identify"]}</i>
                                                                                     </label>
                                                                                 </div>
                                                                                 <div className="clear"></div>
                                                                                 <div className="col-lg-8">
                                                                                     <select className="form-control input-sm" id="incrementalrefreshfield" className="incrementalrefreshfield" data-id={entity.connaccessid} name={entity.name} data-placeholder={lang["datahub.chooseclmns"]} style={{ maxWidth: "200px" }} tabIndex="-1" multiple>
                                                                                         <optgroup className="incrementalrefreshfield_group"></optgroup>
                                                                                     </select>
                                                                                     <span className="help-block incrementalsync_error hidden" name={entity.name} data-id={entity.connaccessid}>{lang["datahub.slctfield"]}</span>
                                                                                     <span className="help-block incrementalsync_multiple_column_error hidden">{lang["datahub.csndrasprt"]}</span>
                                                                                 </div>
                                                                             </div>
                                                                         </div>
                                                                         <div className="control-group form-inline">
                                                                             <div className="validationgroup form-group fullrefreshcontainer">
                                                                                 <div className="col-xs-12">
                                                                                     <label><input type="radio" name={`datasyncrefreshtype${entity.connaccessid}${entity.name}`} data-id={entity.connaccessid} className="datasyncconfiguration" id="fullrefresh" data-id={entity.connaccessid} data-entityname={entity.name} />&nbsp;<strong>{lang["datahub.fullrefrsh"]}</strong> <i style={{ fontSize: "13px" }}>{lang["datahub.rowsadded"]}</i></label>
                                                                                 </div>
                                                                             </div>
                                                                         </div>
                                                                     </div>
                                                                    
                                                                     <div className="row datasyncschedulecontrollers marg-top-20 disabled" data-name={entity.name} data-id={entity.connaccessid}>
                                                                    <div className="col-xs-12 marg-top-10">
                                                                    <label htmlFor="uniqueselect"><strong>{lang["datahub.syncschdule"]}</strong></label>

                                                                        <select className="modal-form-fields syncconfig syncdomelementhub" data-name={entity.name} data-id={entity.connaccessid} id="syncdomelements" onChange={this.props.changeSyncConfig}>
                                                                            {this.props.syncConfigs != undefined ?
                                                                                this.props.syncConfigs.map((config, index) => {
                                                                                    return <option key={index} value={config.sync_id} status={config.status}>{`${ config.sync_details!=undefined && JSON.parse(config.sync_details).cronDetails !=undefined ? JSON.parse(config.sync_details).cronDetails:""} -- [ ${config.sync_name} ]`}</option>
                                                                                })
                                                                                : null}
                                                                        </select>
                                                                    </div>
                                                                   
                                                                </div>
                                                                 </div>
                                                             </fieldset></>
                                                            ) : (
                                                                    <fieldset className="savereport-border">
                                                                        <div className="container-fluid marg-bot-10 marg-top-10">
                                                                            {(entity.isUniqueFeildRequired) ? (
                                                                                <>
                                                                                    <div className="col-xs-12">
                                                                                        <label htmlFor="uniqueselect"><strong>{lang["datahub.uniqefield"]}</strong>
                                                                                            <select id="uniqueselect" data-id={entity.connaccessid} name={entity.name}>
                                                                                            </select>&nbsp; </label>
                                                                                    </div>
                                                                                    <div className="clear"></div>
                                                                                </>
                                                                            ) : null}
                                                                            {/* <!-- All Options Container --> */}
                                                                            <div className="row alldatacontainer" data-name={entity.type} data-id={entity.connaccessid} name={entity.name}>
                                                                                <div className="control-group form-inline">
                                                                                    <div className="validationgroup form-group fullrefreshcontainer">
                                                                                        <div className="col-xs-12">
                                                                                            <label><input type="radio" name={`datasyncrefreshtype${entity.connaccessid}${entity.name}`} data-name={entity.type} data-id={entity.connaccessid} className="datasyncconfiguration" id="fullrefresh" defaultChecked />&nbsp;<strong>{lang["datahub.fullrefrsh"]}</strong> <i style={{ fontSize: "13px" }}>{lang["datahub.rowsadded"]}</i></label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <div className="row datasyncschedulecontrollers marg-top-20 disabled" data-name={entity.name} data-id={entity.connaccessid}>
                                                                    <div className="col-xs-12 marg-top-10">
                                                                    <label htmlFor="uniqueselect"><strong>{lang["datahub.syncschdule"]}</strong></label>
                                                                        <select className="modal-form-fields syncconfig syncdomelementhub" data-name={entity.name} data-id={entity.connaccessid} id="syncdomelements" onChange={this.props.changeSyncConfig}>
                                                                            {this.props.syncConfigs != undefined ?
                                                                                this.props.syncConfigs.map((config, index) => {
                                                                                    return <option key={index} value={config.sync_id} status={config.status}>{`${ config.sync_details!=undefined && JSON.parse(config.sync_details).cronDetails !=undefined ? JSON.parse(config.sync_details).cronDetails:""} -- [ ${config.sync_name} ]`}</option>
                                                                                })
                                                                                : null}
                                                                        </select>
                                                                    </div>
                                                                   
                                                                </div>
                                                                    </fieldset>
                                                                )}
                                                            {/* <fieldset className="savereport-border">
                                                                <legend className="savereport-border">Data Sync Schedule</legend>
                                                                <div className="row datasyncschedulecontrollers" data-name={entity.name} data-id={entity.connaccessid}>
                                                                    <div className="col-xs-12">
                                                                        <select className="modal-form-fields syncconfig syncdomelementhub" data-name={entity.name} data-id={entity.connaccessid} id="syncdomelements" onChange={this.props.changeSyncConfig}>
                                                                            {this.props.syncConfigs != undefined ?
                                                                                this.props.syncConfigs.map((config, index) => {
                                                                                    return <option key={index} value={config.sync_id} status={config.status}>{`${ config.sync_details!=undefined && JSON.parse(config.sync_details).cronDetails !=undefined ? JSON.parse(config.sync_details).cronDetails:""} -- [ ${config.sync_name} ]`}</option>
                                                                                })
                                                                                : null}
                                                                        </select>
                                                                    </div>
                                                                   
                                                                </div>
                                                            </fieldset> */}
                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        }
                                    })
                                }
                                {
                                    this.props.otherentities.map((entity, index) => {
                                        if (entity.type !== "storedprocedure") {
                                            return (
                                                <>
                                                    <h4 className="panelboc-title accordion-toggle" title={entity.name} id="" data-name={entity.type} data-id={entity.connaccessid} data-toggle="collapse" href={`#${entity.dupname}${entity.connaccessid}`} data-parent="#synctablescontainer" aria-expanded="false" aria-controls={entity.name}>
                                                        <i className="fa fa-table"></i> {entity.name}

                                                    </h4>
                                                    <div id={`${entity.dupname}${entity.connaccessid}`} name={entity.name} data-name={entity.type} data-id={entity.connaccessid} className="panel-collapse panelboc-content collapse" aria-expanded="false">
                                                        <div className="panelboc-body">
                                                            <div className="container-fluid marg-bot-10 marg-top-10">
                                                                {(entity.isUniqueFeildRequired) ? (
                                                                    <>
                                                                        <div className="col-xs-12">
                                                                            <label htmlFor="uniqueselect"><strong>{lang["datahub.uniqefield"]}</strong>
                                                                                <select id="uniqueselect" data-id={entity.connaccessid} name={entity.name}>
                                                                                </select>&nbsp; </label>
                                                                        </div>
                                                                        <div className="clear"></div>
                                                                    </>
                                                                ) : null}
                                                                {/* <!-- All Options Container --> */}
                                                                <div className="row alldatacontainer" data-name={entity.type} data-id={entity.connaccessid} name={entity.name}>
                                                                    <div className="control-group form-inline">
                                                                        <div className="validationgroup form-group fullrefreshcontainer">
                                                                            <div className="col-xs-12">
                                                                                <label><input type="radio" name={`datasyncrefreshtype${entity.connaccessid}${entity.name}`} data-name={entity.type} data-id={entity.connaccessid} className="datasyncconfiguration" id="fullrefresh" checked />&nbsp;<strong>{lang["datahub.fullrefrsh"]}</strong> <i style={{ fontSize: "13px" }}>{lang["datahub.rowsadded"]}</i></label>
                                                                            </div>
                                                                            {(entity.type == "rabbitmq" || entity.type == 'twitter' || entity.type == 'Twitter' || entity.type == 'websocket' || entity.type == 'kafka' || entity.type == 'eventhub') ? (
                                                                                <>
                                                                                    <div class="col-xs-12 marg-top-10 streamingsetting">
                                                                                        <span class="title">{lang["datahub.status"]}</span>
                                                                                        <div class="checkbox pull-left folderview">
                                                                                            <span><i class="fa fa-pause fa-solid"></i> {lang["datahub.pause"]} </span>
                                                                                            <label class="checkbox-slider--c">
                                                                                                <input type="checkbox" id="streamingstatus" onClick={this.props.streamingstatus} class="dashboard-view-style dashboardview" data-name={entity.name} data-id={entity.connaccessid} name={`${entity.dupname}${entity.connaccessid}`} />
                                                                                                <span></span>
                                                                                            </label>
                                                                                            <div><i class="fa fa-play fa-solid"></i> {lang["datahub.stream"]}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            ) : null}

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        }
                                    })
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </React.Fragment>
        );
    }
}