import React from "react";
import _ from 'underscore';
import ReactDOM from 'react-dom';
import UpdateUserDetails from "./updateuserdetails.js";
import { lang} from "../../js/helpers/utils/languages/language";
import { getMessage } from '../../js/helpers/utils/BirdMessage';
import SendRequest from '../../SendRequest';
const $ =window.$;

export default class EditUserAccountFormHtml extends React.Component{
    constructor(props){
        super(props);
        this.state={};
          }
          inactiveuser=()=>{
            $(".spinner").hide();
               $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
               $('.messageicon i').removeClass('fa-check  fa-info-circle fa-times-circle-o').addClass('fa-exclamation-triangle');
               $('.birdmessagebuttons').removeClass('show');
               $('.birdmessagebuttons').addClass('hide');
               $('.birdmessage-center').addClass("message-top-in");
               $('.details').removeClass('show');
               $('.details').addClass('hide');
               $('.birdmessage h2').empty().append('Warning');
               $('.birdmessage-info').empty().text(getMessage('BirdWarning02'));
               
        }
          updateuserdetails=(e)=>{
            var serialized = $('.row.in-back form').serializeArray();
            var s = '';
               var data = {};
               for(s in serialized){
                   data[serialized[s]['name']] = serialized[s]['value']
               }
              var result=JSON.stringify(data);
              var finaldata=JSON.parse(result); 
              var isValid = this.isValid(finaldata);
              if(isValid) {
                var updateaccount = new UpdateUserDetails();
              }                
        }
        isValid=(data)=>{
            var isValid = true;
            if($.trim($.trim(data.groupName))==""){
                $(".groupnameerror").removeClass('hidden');
                $(".groupnameerror").text(lang["admin.groups.namerror"] );
                isValid = false;
            }
            return isValid;
        }
render(){
              var that=this;
              var arr = [];
              arr.push(that.props.edituserdetails);
        return (<div class="container-fluid nopadding">
        <div class="row in-back">
            <div class="max-height-30vh">
                <h2 class="boc-main-title">{lang["admin.groups.edittitle"]}</h2>
                <form class="form-horizontal col-xs-12 submitform">
                {arr.map(function(userdetails){
                return <div>
                   <div class="form-group form-group-sm form-group">
					<label for="groupName" class="col-sm-3 control-label important">{lang["admin.groups.createname"]}</label>
                        <div class="col-sm-6">
						  <input type="hidden" class="form-control" id="groupId" name="groupId" placeholder="" defaultValue={userdetails.groupId}></input>
                             <input type="name" class="form-control" id="groupName" placeholder="" name="groupName" defaultValue={userdetails.groupName}></input>
							<span class="help-block groupnameerror hidden"></span>
                            </div>
                        </div>
                        
                        <div class="form-group form-group-sm">
					<label for="lastname" class="col-sm-3 control-label ">{lang["admin.groups.createdescrptn"]}</label>
					<div class="col-sm-6">
						<textarea class="form-control textarea" rows="2" id="groupDesc" name="groupDesc" placeholder="" defaultValue={userdetails.groupDesc}></textarea>
					</div>
				</div>
                
                <div class="form-group form-group-sm">
					<label for="" class="col-sm-3 control-label">{lang["admin.groups.status"]}</label>
					<div class="col-sm-6">
						<div>
                      {
                        (userdetails.status == "A") ?( 
                        <div>
							<input type="radio" id="statusa" name="status" value="A" defaultChecked />{lang["admin.groups.statusact"]}&nbsp;&nbsp;&nbsp; 
							<input type="radio" id="statusi" name="status" value="I" onClick={that.inactiveuser}/>{lang["admin.groups.statusdact"]} 		 
                        </div>
                        ) : (
                            <div>
							<input type="radio" id="statusa" name="status" value="A" />{lang["admin.groups.statusact"]}&nbsp;&nbsp;&nbsp; 
							<input type="radio" id="statusi" name="status" value="I" onClick={that.inactiveuser} defaultChecked  />{lang["admin.groups.statusdact"]}
                            </div>
                        ) 
                        }
						</div>
					</div>
				</div>
               
                <div class="form-group form-group-sm">
					<div class="col-sm-offset-3 col-sm-6">
						<button type="button" class="btn btn-bird updateuser" onClick={that.updateuserdetails} id="updateusers"><i class="fa fa-refresh marg-righ-5"></i>{lang["admin.groups.updatebtn"]}</button>
					</div>
				</div>
                        </div>
                })
            }

                </form>
                </div>
	</div>
</div>
                 );
        }
    }