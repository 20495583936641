import React, { Component } from "react";
import SendRequest from '../../SendRequest';
import CreateUsersView from "./createuserhtml.js";
import { getMessage } from '../../js/helpers/utils/BirdMessage';
import { lang } from "../../js/helpers/utils/languages/language";
const $ =window.$;

class UpdateUserDetails extends Component {
  constructor(props) {
    super(props);
      this.state = { };
    let featuresString = sessionStorage.getItem("features");
    featuresString = featuresString.slice(0, 3) + featuresString.slice(4);
    let decryptedString = window.atob(featuresString);
    this.state.parsedFeatureJson = JSON.parse(decryptedString);
    this.state.features= JSON.parse(decryptedString);
    var serialized = $('form').serializeArray();
    var s = '';
       var data = {};
       for(s in serialized){
           data[serialized[s]['name']] = serialized[s]['value']
       }
      var result=JSON.stringify(data);
      var finaldata=JSON.parse(result); 
      this.state.finaldata=finaldata;
     var userEmail =$('#loginemail').val();
     this.state.userEmail=userEmail;
    this.saveUser();
    this.render();
  }
  saveUser(){
      var that =this;
      var path= window.location.origin+"/"+window.localStorage.appName;
      var finaldata=that.state.finaldata;
    let requestHeaders = {
        method: "post",
        headers: new Headers({
            "Content-Type": "application/json"
        })
    };
       SendRequest({
        url: "saveUser",
        queryString: "?firstName=" + finaldata.firstname.trim() + "&lastName=" + finaldata.lastname + "&userId=" + finaldata.userId + "&userEmail=" + this.state.userEmail + "&groupId=" + finaldata.usergroup.toString() + "&role=" + finaldata.role + "&status=" + finaldata.status +"&path="+path,
        sucessFunction: (response) => {
            if(response.status=='success'){
                    $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                  $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                  $('.birdmessagebuttons').removeClass('show');
                  $('.birdmessagebuttons').addClass('hide');
                  $('.birdmessage-center').addClass("message-top-in");
                  $('.details').removeClass('show');
                  $('.details').addClass('hide');
                  $('.birdmessage h2').empty().append('Success');
                  $('.birdmessage-info').empty().text(getMessage('BirdSuccess07'));
                  setTimeout(function() {
                    $('.birdmessage-container .close').click();	
                    }, 3000);
                    setTimeout(function() {
                        window.location.reload(false);
                         },3000);
                }
                else if(response.hasOwnProperty('error')){
                    $(".spinner").hide();
                    //$('#main').prepend('<div class="alert alert-danger birderror alert-dismissable affix-top" style="margin:0 auto;width: 60%;top: 44px;position: fixed;z-index: 1;left: 20%;">'+response.error+'</div>');
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('hide');
                    $('.birdmessage h2').empty().append('Error : ');
                    $('.birdmessage h2').append('<span class="errorcode">');
                    $('.birdmessage .errorcode').empty().append(response.errorCode);
                    if(response.error==lang["admin.users.nameexists"]){
                        $('.birdmessage-info').empty().text(lang["admin.users.loginnameexists"]);
                    }
                    else{
                        $('.birdmessage-info').empty().text(response.error);
                    }
                    $('.details').addClass('show');
                    $('.messagedetails xmp').empty().append(response.errorTrace)
                      //   setTimeout(function(){
                      //  new CreateUsersView();
                      //   },2000)
                }
                else{
                    $(".spinner").hide();
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('show');
                    $('.details').addClass('hide');
                    $('.birdmessage h2').empty().append('Warning');
                    $('.birdmessage-info').empty().text(response.status);
                    }
              },
          rejectFunction: () => { },
          headers: requestHeaders
        })
  }
  render(){
    return(
        <div></div>
        )
  }
  }
  export default UpdateUserDetails;