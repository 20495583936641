import _ from 'lodash';
import $ from 'jquery/dist/jquery';
import React from "react";
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import ReactDOM from "react-dom";
import TooltioAttributesTemplate from "./tooltipattributes.jsx"

export default class ToolTipAttrModalView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicdiv: props.dynamicdiv,
            yaxiscolumnDisplayName: props.yaxiscolumnDisplayName,
            yaxiscolumnName: props.yaxiscolumnName,
            yaxiscolumnType: props.yaxiscolumnType,
            aggregation: props.yaxisaggregation,
            yaxistableName: props.yaxistableName,
            percentileValue: props.percentileValue,
            //	broker.on('changeaggregation',changeaggregation,this),
            isDirect: ChartInfo.functions.getIsDirect(),
            isStoredProcedure: ChartInfo.functions.getIsStoredProcedure(),
        };
        this.render();
    }
    render() {
        this.state.el = "#" + this.state.dynamicdiv;
       ReactDOM.render(<TooltioAttributesTemplate
        deleteelement={this.deleteelement} changetooltipAggregation={this.changetooltipAggregation} yaxiscolumnDisplayName={ this.state.yaxiscolumnDisplayName} yaxiscolumnName={ this.state.yaxiscolumnName}
        yaxiscolumnType={ this.state.yaxiscolumnType} aggregation={ this.state.aggregation} yaxistableName={ this.state.yaxistableName} percentileValue={ this.state.percentileValue} isDirect={ this.state.isDirect} isStoredProcedure={ this.state.isStoredProcedure}
        ></TooltioAttributesTemplate>,document.getElementById(this.state.dynamicdiv));
        var sortingAttributes = $.parseJSON(ChartInfo.functions.getChartDetails(sessionStorage.getItem('currentChartNumberForSort')));
        var colLimit = sortingAttributes.colLimit;
        var orderType = sortingAttributes.orderType;
        var sortType = sortingAttributes.sortType;
        var sortingColumn = sortingAttributes.sortingColumn;
        if (sortType == 'desc') {
            $('input[id="sortby1"]').attr('checked', true);
        } else if (sortType == 'asc') {
            $('input[id="sortby2"]').attr('checked', true);
        }
        if (orderType == "top") {
            $('.ordertype option[value="top"]').attr('selected', 'selected');
        } else if (orderType == "bottom") {
            $('.ordertype option[value="bottom"]').attr('selected', 'selected');
        }
        if (colLimit != null && colLimit != "") {
            $('.collimit').attr('value', colLimit);
        }

        //Active drop down aggregation item
        if (this.state.aggregation === "sum" || this.state.aggregation === "Sum") {
            $(this.state.el).find(".aggsum").addClass("active");
        } else if (this.state.aggregation === "min" || this.state.aggregation === "Min") {
            $(this.state.el).find(".aggmin").addClass("active");
        } else if (this.state.aggregation === "max" || this.state.aggregation === "Max") {
            $(this.state.el).find(".aggmax").addClass("active");
        } else if (this.state.aggregation === "avg" || this.state.aggregation === "Avg") {
            $(this.state.el).find(".aggavg").addClass("active");
        } else if (this.state.aggregation === "variance" || this.state.aggregation === "Variance") {
            $(this.state.el).find(".aggvariance").addClass("active");
        } else if (this.state.aggregation === "standard deviation" || this.state.aggregation === "Standard Deviation") {
            $(this.state.el).find(".aggstandarddeviation").addClass("active");
        } else if (this.state.aggregation === "sum of squares" || this.state.aggregation === "Sum of Squares") {
            $(this.state.el).find(".aggsumofsquares").addClass("active");
        } else if (this.state.aggregation === "percentile" || this.state.aggregation === "Percentile") {
            $(this.state.el).find(".aggpercentile").addClass("active");
        } else if (this.state.aggregation === "median" || this.state.aggregation === "Median") {
            $(this.state.el).find(".aggmedian").addClass("active");
        } else if (this.state.aggregation === "count all" || this.state.aggregation === "Count (all)") {
            $(this.state.el).find(".aggcount").addClass("active");
        } else if (this.state.aggregation === "count" || this.state.aggregation === "Count (distinct)") {
            $(this.state.el).find(".aggcountdistinct").addClass("active");
        }
        return (<div></div>);
    }
    changetooltipAggregation=(e)=>{
			$(this.state.el).find(".aggregation-li-item").removeClass("active");
			$(e.currentTarget).addClass("active");
			var aggregationtext = $(e.currentTarget).text();
			var percentileValue=$(this.state.el).find(".percentilebox").val();
			var selectedAggregation =  $(e.currentTarget).text();
			$(this.state.el).find(".aggregationtext").text("[ "+selectedAggregation+" ]");
			var columnName = $(this.state.el).find('.singleliitems').attr("data-name");
			var columnDisplayName = $(this.state.el).find('.singleliitems').attr("title");
			var aggregation = $(e.currentTarget).attr("title");
			var aggregationtext = $(e.currentTarget).text();
			var percentileValue=$(this.state.el).find(".percentilebox").val();
			$(this.state.el).find('.singleliitems').attr("data-agg",$(e.currentTarget).text());
			this.state.aggregation = aggregation;
			if(this.state.yaxiscolumnName === columnName && this.state.yaxiscolumnDisplayName === columnDisplayName){
				$(".measures .singleliitems[data-name = '"+this.state.yaxiscolumnName+"']").attr("data-agg",aggregationtext);
				$(".measures .singleliitems[data-name = '"+this.state.yaxiscolumnName+"']").attr("percentile-value",percentileValue);
				$(".measures .singleliitems[data-name = '"+this.state.yaxiscolumnName+"'] .percentilebox").val(percentileValue);
				$(".measures .singleliitems[data-name = '"+this.state.yaxiscolumnName+"'] #dropdownaggregation").text(aggregationtext);
				$(".measures .singleliitems[data-name = '"+this.state.yaxiscolumnName+"'] .aggregation-li-item").removeClass('active');
				$(".measures .singleliitems[data-name = '"+this.state.yaxiscolumnName+"'] li[title='"+aggregation+"']").addClass('active');
			}
    }
    deleteelement=(e)=>{
        var $this=e.currentTarget;
        var currentvalue = $($this).closest('span').prev().prev().text();
        $(".yaxisitems option[title='"+currentvalue+"']").remove();
        $($this).closest('li').parent().remove()
    }
}