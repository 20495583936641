import React from 'react';
import { lang } from "../js/helpers/utils/languages/language";
export default class StoredProcedureParametersHtml extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <React.Fragment>
                {/* <!-- storeprocedureparametersview.js --> */}
                <div role="tabpanel" className="datahubitemsettingstabscontainer" data-id={this.props.connId} name={this.props.storedProcName}>
                    <div className="entityname">
                        <i className="fa fa-list-alt"></i>{this.props.storedProcName}
                        <span className="entityconnectionsource"><i class='fa fa-link'></i>{this.props.entitySourceName}</span>
                        <button type="button" className="btn btn-transparent pull-right closesettingscontainer" onClick={this.props.closesettingscontainer}>
                            <i className="fa fa-times"></i>
                        </button>
                    </div>
                    <ul className="nav nav-tabs tabcontainer" role="tablist">
                        <li role="presentation" className="tabheadli active">
                            <a href="#datahubstoredprocedureparameterstabview" id="datahubstoredprocedureparameterstab" aria-controls="datahubstoredprocedureparameterstabview" role="tab" data-toggle="tab"><i className="fa fa-list-alt"></i> {lang["datahub.paramtrs"]}</a>
                        </li>
                        <li role="presentation" className="tabheadli disabled">
                            <a href="#datahubmodifyentitiestabview" id="datahubmodifyentitiestab" aria-controls="datahubmodifyentitiestabview" role="tab" data-toggle="tab"><i className="fa fa-pencil"></i> {lang["datahub.mdfyentity"]}</a>
                        </li>
                    </ul>
                    <div className="tab-content">
                        <div role="tabpanel" className="tab-pane tabviewitems active" id="datahubstoredprocedureparameterstabview">
                            <div className="modal-body">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <div className="notifications-table-container marg-top-10 datahubstoredprocedurecontainer">
                                                <div className="table-responsive">
                                                    <table className="table custom" data-toggle="table" data-pagination="true" data-page-size="10" data-smart-display="true" data-pagination-v-align="bottom">
                                                        <thead>
                                                            <tr>
                                                                <th>{lang["datahub.paramtr"]}</th>
                                                                <th>{lang["datahub.type"]}</th>
                                                                <th className="centertext">{lang["datahub.value"]}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(this.props.showvalues === true) ? (
                                                                this.props.response.map((values, index) => {
                                                                    return (
                                                                        <tr name={this.props.storedProcName} paramtype="in">
                                                                            <td className="spparametername" name={values.name}>{values.name}</td>
                                                                            <td className="spdatatype" name={values.datatype}>{values.datatype}</td>
                                                                            <td><input type="text" name={values.name} className={`form-control spparametervalue min-width-100 ${this.props.isSaved ? 'disabled' : ''}`} value={values.value} /></td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            ) : (
                                                                    this.props.response.map((values, index) => {
                                                                        if (values.parameterType.toLowerCase() !== 'out') {
                                                                            return (
                                                                                <tr name={values.storedPreocedureName} paramtype={values.parameterType}>
                                                                                    <td className="spparametername" name={values.parameterName}>{values.parameterName}</td>
                                                                                    <td className="spdatatype" name={values.datatype}>{values.datatype}</td>
                                                                                    <td><input type="text" name={values.parameterName} className="form-control spparametervalue min-width-100" /></td>
                                                                                </tr>
                                                                            );
                                                                        }
                                                                    })
                                                                )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-bird active pull-right storedprocparammodal" data-parent={this.props.connId} name={this.props.storedProcName} id="savestoredprocedureparameter" onClick={this.props.savestoredprocedureparameters}>
                                    <i className="fa fa-refresh"></i> {lang["admin.syncupdatebtn"]}
                                </button>
                            </div>
                        </div>
                        <div role="tabpanel" className="tab-pane tabviewitems" id="datahubmodifyentitiestabview">
                            <div className="modal-body">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <div className="notifications-table-container marg-top-10 datahubmodifyentitiestablecontainer">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-bird active pull-right applydatahubmodifyentities" id={this.props.connId} name={this.props.storedProcName} onClick={this.props.applydatahubmodifyentities}><i className="fa fa-refresh"></i> {lang["admin.syncupdatebtn"]}</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- storedprocedureparametersview.js --> */}
            </React.Fragment>
        );
    }
}