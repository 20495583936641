import React from "react";
import { lang } from "../../js/helpers/utils/languages/language";

export default class PivotModalTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        var that = this.props;
        return (
            //    <!-- pivotmodalview.js -->
            <div className={`modal fade pivotmodal${that.chartnumber} in`} id="pivotpagesettingsmodal" tabIndex="-1" role="dialog" aria-labelledby="pivotpagesettingsmodal" aria-hidden="true" style={{ display: "block" }}>
                <div className="modal-backdrop fade in"></div>
                <div className="modal-dialog modal-sm modal-sm-400 modal-dialog-sm-center modal-dialog-center" style={{ zIndex: "1050" }}>
                    <div className="modal-content">
                        <div className="modal-header boc-modal-hf-padding">
                            <button type="button" className="close white" id="pivotmodalclose" onClick={that.closePivotModal} aria-hidden="true" data-number={that.chartnumber} data-dismiss="modal">&times;</button>
                            <h4 className="modal-title" id="mySmallModalLabel"><i className="fa fa-wrench fa-flip-horizontal"></i> {lang["storyboard.pivotsettings"]}</h4>
                        </div>
                        <div className="modal-body scrollset">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-xs-12  marg-top-10 marg-bot-10">
                                        <div className="modal-height-fix">
                                            <legend className="subhead marg-top-10">{lang["storyboard.colms"]}
                                           <button className="pull-right btn btn-transparent tooltip-left dropdown-toggle addpivotcolumns" onClick={that.addColumns} id="addpivotcolumnssingle" data-num={that.chartnumber} tab-id="0" tab-name="" data-tooltip={lang["storyboard.add"]} id="dropdownlegend" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-plus-circle"></i></button>
                                                <ul className="list-group dropdown-menu dropdown-inline marg-top-10-imp pivotcolummsList" aria-labelledby="dropdownxaxis" role="menu" ></ul>
                                            </legend>
                                            <div className="marg-top-10">
                                                <ul className={`list-group pivotcolumn pivotcol${that.chartnumber}`} id="sortablepivotcolumns" chart-number={that.chartnumber}></ul>
                                                <span className='addpivotcolumns-error help-block marg-left-10 hide'>{lang["storyboard.addcolm"]}</span>
                                            </div>
                                            <legend className="subhead">{lang["storyboard.rows"]}
                                           <button className="pull-right btn btn-transparent dropdown-toggle tooltip-left addpivotrows" onClick={that.addRows} id="addpivotrowsview" data-num={that.chartnumber} tab-id="0" tab-name="" data-tooltip={lang["storyboard.add"]} id="dropdownxaxis" aria-expanded="false" data-toggle="dropdown"><i className="fa fa-plus-circle"></i></button>
                                                <ul className="list-group dropdown-menu dropdown-inline marg-top-10-imp pivotrowsList" aria-labelledby="dropdownxaxis" role="menu" ></ul>
                                            </legend>
                                            <div className="marg-top-10">
                                                <ul className={`list-group pivotrow  pivotrow${that.chartnumber} ui-sortable`} id="sortablepivotrows" chart-number={that.chartnumber}></ul>
                                                <span className='addpivotrows-error help-block marg-left-10 hide'>{lang["storyboard.addrows"]}</span>
                                            </div>
                                            <legend className="subhead">{lang["storyboard.values"]}
                                           <button className="pull-right btn btn-transparent dropdown-toggle tooltip-left addpivotvalue" onClick={that.addPivotValues} id="addpivotvalueview" data-num={that.chartnumber} tab-id="0" tab-name="" data-tooltip={lang["storyboard.add"]} id="dropdownyaxis" aria-expanded="false" data-toggle="dropdown"><i className="fa fa-plus-circle"></i></button>
                                                <ul className="list-group dropdown-menu dropdown-inline marg-top-10-imp pivotvaluesList" aria-labelledby="dropdownyaxis" role="menu"></ul>
                                            </legend>
                                            <div className="marg-top-10">
                                                <ul className={`list-group pivotvalues pivotval${that.chartnumber}`} id="sortablepivotvalues"></ul>
                                                <span className='addpivotvalues-error help-block marg-left-10 hide'>{lang["storyboard.addvalue"]}</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="modal-footer boc-modal-hf-padding">
                            <button type="button" className="btn btn-bird" id="btn-bird" onClick={that.closePivotModal} data-dismiss="modal" aria-hidden="true"><i className="fa fa-times"></i> {lang["storyboard.cancelbtn"]}</button>
                            <button type="button" className="btn btn-bird applypivotchanges" onClick={that.applyPivotChanges} id="applypivotchangespivot" data-num={that.chartnumber} tab-id="0" tab-name="" aria-hidden="true"><i className="fa fa-check"></i> {lang["storyboard.applybtn"]}</button>
                        </div>
                    </div>
                    {/* <!-- /.modal-content --> */}
                </div>
                {/* <!-- /.modal-dialog --> */}
            </div>);
    }
}