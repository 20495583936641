import React from "react";
import { lang } from "../js/helpers/utils/languages/language";
export default class ZaxisDropDownTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        var that = this.props;
        return (<div key={that.dynamicdiv}>
            {/* <!-- zaxisdropdownview.js --> */}
            {(that.dataType != 'customaggregatedfield' && that.dataType != 'custommeasurehierarchy') ? (
                <li title={that.columnDisplayName} data-name={that.columnName} table-name={that.tableName} data-type={that.dataType} className={`xs-list-item zaxisitem ${that.disable ? " hide" : ""}`} onClick={that.addzaxisvalue} percentile-value={that.percentileValue}>
                    <span className="itemtype">
                        {(that.dataType === 'number') ? (
                            <>123</>
                        ) : (that.dataType === 'date' || that.dataType === 'datetime') ? (
                            <i className="fa fa-calendar"></i>
                        ) : (that.dataType === 'month') ? (
                            <i className="fa fa-calendar"></i>
                        ) : (that.dataType === 'day') ? (
                            <i className="fa fa-calendar"></i>
                        ) :
                                        <>{lang["storyboard.abc"]}</>
                        }
                    </span>
                    <span className="itemname">{that.columnDisplayName}</span>
                    <span className="boclabel boclabel-right-5 opacity-effect boclabel-info">[ {that.aggregation} ]</span>
                </li>

            ) : null}
        </div>);
    }
}
