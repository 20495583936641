import React from "react";
import Mlicon from "../images/birdimages/ml.svg";
import { lang } from "../js/helpers/utils/languages/language";
import SendRequest from '../SendRequest';
import { getMessage } from '../js/helpers/utils/BirdMessage';
import FoldersListView from "./FoldersListView"
import _ from 'underscore';
const $ = window.$;
export default class FoldersTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = { folderNameArray: [] };
        this.render();
    }
    render() {
        return (
            <React.Fragment>
                <ul className="boc-carousel-home">
                    {this.props.folders.map((folderArray, index) => {
                        return (
                            <section className="marg-top-20 foldersgridsmaincontainer" data-foldername={folderArray[0].reportFolder}>
                                <header className="container-fluid">
                                    <h2 className="datamain-title">
                                        {folderArray[0] != null ?
                                            <React.Fragment><i className="fa fa-folder"></i> <span>{folderArray[0].reportFolder}</span></React.Fragment>
                                            : <React.Fragment></React.Fragment>}
                                        <div className="btn-group pull-right" role="group">
                                            <button onClick={() => { this.viewGridView(folderArray[0].reportFolder) }} className="btn btn-bird active foldersgridview" id="foldersgridviews" type="button" title={lang["visual.favourites.gridview"]} data-foldername={folderArray[0].reportFolder}><i className="fa fa-th-large"></i></button>
                                            <button onClick={() => { this.viewListView(folderArray[0].reportFolder) }} className="btn btn-bird folderslistview" id="folderslistviews" type="button" title={lang["visual.favourites.listview"]} data-foldername={folderArray[0].reportFolder}>	<i className="fa fa-th-list"></i></button>
                                        </div>
                                    </h2>
                                </header>
                                <div className="data-items-container" data-foldername={folderArray[0].reportFolder}>
                                    {this.state.folderNameArray.some(item => item.hasOwnProperty(folderArray[0].reportFolder)) ?
                                        <React.Fragment><FoldersListView url={this.props.url} foldername={folderArray[0].reportFolder} /></React.Fragment>
                                        :
                                        folderArray.map((reportinfo, k) => {
                                            return (
                                                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2 item-main-container">
                                                    <div className="data-item" title={reportinfo.reportname}>
                                                        <div className="data-item-headers">
                                                            <div className="data-item-name">
                                                                {reportinfo.type == "ml_storyboard" ?
                                                                    <a className="" title={`View${reportinfo.reportname}`} href={`${this.props.url}/viewmodel/${btoa(reportinfo.reportId)}/${btoa(reportinfo.dataModelId)}`} data-num={reportinfo.reportId} tab-id="0" tab-name="">{reportinfo.reportname}</a>
                                                                    : [(reportinfo.type == "smartinsight_storyboard" ?
                                                                        <a className="" title={`View${reportinfo.reportname}`} href={`${this.props.url}/viewsmartinsight/${btoa(reportinfo.reportId)}/${btoa(reportinfo.dataModelId)}`} data-num={reportinfo.reportId} tab-id="0" tab-name="">{reportinfo.reportname}</a>
                                                                        :
                                                                        reportinfo.type == "customstoryboard" ?
                                                                        <a className="" title={`View${reportinfo.reportname}`} href={`${this.props.url}/viewcustomstoryboard/${btoa(reportinfo.reportId)}`} data-num={reportinfo.reportId} tab-id="0" tab-name="">{reportinfo.reportname}</a>
                                                                        :
                                                                        <a className="" title={`View${reportinfo.reportname}`} href={`${this.props.url}/viewreport/${btoa(reportinfo.reportId)}`} data-num={reportinfo.reportId} tab-id="0" tab-name="">{reportinfo.reportname}</a>
                                                                    )]
                                                                }

                                                            </div>
                                                            <div className="data-item-subtitle"><i className="fa fa-folder"></i>&nbsp;{reportinfo.reportFolder}</div>
                                                            <div className="data-item-actions">
                                                                <button type="button" className="btn btn-transparent dropdown-toggle dropdown" title={lang["visual.favourites.more"]} data-toggle="dropdown" aria-expanded="true"><i className="fa fa-ellipsis-v"></i></button>
                                                                <ul className="dropdown-menu dropdown-menu-right">
                                                                    {reportinfo.type == "ml_storyboard" ?
                                                                        <li><a type="button" className="btn btn-data-item-actions" title={`View${reportinfo.reportname}`} href={`${this.props.url}/viewmodel/${btoa(reportinfo.reportId)}/${btoa(reportinfo.dataModelId)}`} data-num={reportinfo.reportId} tab-id="0" tab-name=""><i className="fa fa-arrow-right marg-righ-10"></i> {lang["visual.favourites.viewreport"]}</a></li>
                                                                        : [(reportinfo.type == "smartinsight_storyboard" ?
                                                                            <li><a type="button" className="btn btn-data-item-actions" title={`View${reportinfo.reportname}`} href={`${this.props.url}/viewsmartinsight/${btoa(reportinfo.reportId)}/${btoa(reportinfo.dataModelId)}`} data-num={reportinfo.reportId} tab-id="0" tab-name=""><i className="fa fa-arrow-right marg-righ-10"></i> {lang["visual.favourites.viewreport"]}</a></li>
                                                                            :
                                                                            reportinfo.type == "customstoryboard" ?
                                                                            <li><a type="button" className="btn btn-data-item-actions" title={`View${reportinfo.reportname}`} href={`${this.props.url}/viewcustomstoryboard/${btoa(reportinfo.reportId)}`} data-num={reportinfo.reportId} tab-id="0" tab-name=""><i className="fa fa-arrow-right marg-righ-10"></i> {lang["visual.favourites.viewreport"]}</a></li>
                                                                            :
                                                                            <li><a type="button" className="btn btn-data-item-actions" title={`View${reportinfo.reportname}`} href={`${this.props.url}/viewreport/${btoa(reportinfo.reportId)}`} data-num={reportinfo.reportId} tab-id="0" tab-name=""><i className="fa fa-arrow-right marg-righ-10"></i> {lang["visual.favourites.viewreport"]}</a></li>
                                                                        )]}
                                                                    <li><a href="javascript:void(0)" onClick={() => { this.deleteReport(reportinfo.reportId, reportinfo.isUserFavourite, reportinfo.isShared,index,k,this ) }} type="button" title={`Delete ${reportinfo.reportname}`} id=" folderviewreport" className="btn btn-data-item-actions deltevisualreports" data-num={reportinfo.reportId} tab-id="0" tab-name={reportinfo.reportname} is-shared={reportinfo.isShared} is-favourite={reportinfo.isUserFavourite}><i className="fa fa-trash-o marg-righ-10"></i> {lang["visual.favourites.delete"]}</a></li>
                                                                </ul >
                                                            </div >
                                                        </div >
                                                        <div className="data-item-info">
                                                            <div className="data-item-info-name"></div>
                                                        </div>
                                                        <div className="data-item-more-info">
                                                            <div className="data-item-desc-box">
                                                                <div className="data-item-info-description"></div>
                                                            </div>
                                                            <div className="data-item-img-box">
                                                                {reportinfo.type == "ml_storyboard" ?
                                                                    <img src={Mlicon} className="mlicon" />
                                                                    : [(reportinfo.type == "smartinsight_storyboard" ?
                                                                        <img src={Mlicon} className="mlicon" />
                                                                        :reportinfo.isNlpReport==="Y"?(<i className="fa fa-search fa-flip-horizontal"></i>):
                                                                        <i className="fa fa-bar-chart"></i>
                                                                    )]}
                                                            </div>
                                                        </div>
                                                        <div className="data-item-footer">
                                                            <div className="data-sync-info"><i className="fa fa-clock-o"></i> {lang["visual.favourites.createon"]} {reportinfo.date}</div>
                                                            <div className="data-item-sync-status">
                                                                <span className="folderview-bottom-options pull-right">
                                                                    <span className="marg-righ-5"><i className="fa fa-eye"></i>&nbsp;{reportinfo.numberOFViews}&nbsp;</span>
                                                                    {reportinfo.isUserFavourite == 'Y' ?
                                                                        <i className="fa fa-star fa-green"></i>
                                                                        : <React.Fragment />}
                                                                    {reportinfo.isScheduled == 'Y' ?
                                                                        <i className="fa fa-clock-o fa-gold"></i>
                                                                        : <React.Fragment />}
                                                                    {reportinfo.isShared == 'Y' ?
                                                                        <i className="fa fa-share-alt fa-violet"></i>
                                                                        : <React.Fragment />}
                                                                    {reportinfo.isLiveData == 'Y' ?
                                                                        <i className="fa fa-clock-o fa-flip-horizontal fa-green"></i>
                                                                        : <React.Fragment />}
                                                                    {this.props.features.map((feature1) => {
                                                                        if (feature1.moduleName == 'story_board' && reportinfo.reportType == 'Y') {
                                                                            feature1.features.map((feature2) => {
                                                                                if (feature2.featureName == 'audit_meta_data' && feature2.allow == true) {
                                                                                    if (reportinfo.isMetaDataAudit == 'Y' && reportinfo.type != "ml_storyboard") {
                                                                                        return (<i className="fa fa-circle-o fa-orchid"></i>)
                                                                                    }
                                                                                    if (reportinfo.isDataAudit == 'Y' && reportinfo.type != "ml_storyboard") {
                                                                                        return (<i className="fa fa-circle-o fa-skyblue"></i>)
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                        if (feature1.moduleName == 'my_report' && reportinfo.reportType != 'Y') {
                                                                            feature1.features.map((feature2) => {
                                                                                if (feature2.featureName == 'audit_meta_data_maximize' && feature2.allow == true) {
                                                                                    if (reportinfo.isMetaDataAudit == 'Y') {
                                                                                        return (<i className="fa fa-circle-o fa-orchid"></i>)
                                                                                    }
                                                                                    if (reportinfo.isDataAudit == 'Y') {
                                                                                        return (<i className="fa fa-circle-o fa-skyblue"></i>)
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    })}
                                                                    {reportinfo.isAlert == 'Y' ?
                                                                        <i className="fa fa-exclamation-triangle fa-warning marg-righ-10"></i>
                                                                        : <React.Fragment />}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                  {this.state.folderNameArray.some(item => item.hasOwnProperty(folderArray[0].reportFolder)) ?
                                    <React.Fragment></React.Fragment>
                                    :[(folderArray.length != undefined && folderArray.length > 10 ?
                                    <button onClick={() => { this.viewListView(folderArray[0].reportFolder) }} toggle="View More..." className="btn btn-bird-group-transaprent viewallfolderreports  pull-right" id="dashviewallfolderreports" data-foldername={folderArray[0].reportFolder}>{lang["visual.favourites.viewmore"]}</button>
                                    : <React.Fragment />)]
                                }
                                </div >
                            </section >
                        )
                    })}
                    <div className="clearfix"></div>
                </ul >
            </React.Fragment>
        );
    }
    deleteReport=(reportid, isFavourite, isShared,index,k,e) =>{
        let requestHeaders = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        var that = this;
        $(".deleteyes").unbind("click");
        $(".deleteno").unbind("click");
        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
        $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
        $('.birdmessagebuttons').addClass('show');
        $('.birdmessage-center').addClass("message-top-in");
        $('.details').removeClass('show');
        $('.details').addClass('hide');
        $('.birdmessage h2').empty().append('Warning');
        $('.birdmessage-info').empty().text(getMessage('BirdWarning18'));
        $(".deleteyes").click(function () {
            $('.birdmessage-center').removeClass("message-top-in");
            var modeldata = {};
            $('.spinner').show();
            modeldata["reportId"] = reportid;
            if (isFavourite == "Y") {
                modeldata["isFavourite"] = true;
            } else {
                modeldata["isFavourite"] = false;
            }
            if (isShared == "Y") {
                modeldata["isShared"] = true;
            } else {
                modeldata["isShared"] = false;
            }
            modeldata["isReportOwner"] = true;

            var data = new FormData();

            data.set("reportId", modeldata.reportId);
            data.set("isFavourite", modeldata.isFavourite);
            data.set("isSharedReport", modeldata.isShared);
            data.set("isReportOwner", modeldata.isReportOwner);

            SendRequest({
                url: "deletereport",
                body: data,
                sucessFunction: response => {
                    if (response.hasOwnProperty('error')) {
                        $(".spinner").hide();
                        $(".gridspinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.errorTrace)
                    } else {
                        $(".spinner").hide();
                        $(".gridspinner").hide();
                        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Success');
                        $('.birdmessage-info').empty().text(getMessage('BirdSuccess42'));
                        const allFolderReports=Object.assign([],that.props.state.folderreports)
                        const individualFolderReports = allFolderReports[index]
                        individualFolderReports.splice(k,1)
                        allFolderReports[index] = individualFolderReports;
                        that.props.TargetThis.setState({folderreports:allFolderReports})
                    }
                },
                rejectFunction: () => { },
                headers: requestHeaders
            });


        });
        $(".deleteno").click(function () {
            $('.birdmessage-center').removeClass("message-top-in");
        })
    }
    viewGridView(foldername) {
        var folderNamesArray = this.state.folderNameArray
        var tempArray=[]
        _.each(folderNamesArray,function(reportinfo){
                if(!reportinfo.hasOwnProperty(foldername)) 
                     tempArray.push(reportinfo)
        });
        this.setState({ folderNameArray: tempArray });
        $(".folderviewtableelement[data-foldername='" + foldername + "']").remove();
        $(".folderslistview[data-foldername='" + foldername + "']").removeClass('active');
        $(".foldersgridview[data-foldername='" + foldername + "']").addClass('active');
    }
    viewListView(folderName) {
        var folderNamesArray = this.state.folderNameArray
        var foldersMap = {};
        foldersMap[folderName] = true
        folderNamesArray.push(foldersMap);
        this.setState({folderNameArray: folderNamesArray });
        $(".foldersgridview[data-foldername='" + folderName + "']").removeClass('active');
        $(".folderslistview[data-foldername='" + folderName + "']").addClass('active');
    }
}