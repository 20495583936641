import React from 'react';
import _ from 'underscore';
import SendRequest from '../SendRequest';
import { lang } from "../js/helpers/utils/languages/language";
import Datahub from './Datahub';
import PubSub from 'pubsub-js';
const $=window.$;

export default class DatahubItemsSettingsHtml extends React.Component {
    constructor(props) {
        super(props);
        this.datahubentities={};
        this.reportcolumns = {};
    }
    CreateReportColumnsForWebdata=()=>{
        var columnsObj = {};
        var columns=[];
        var dateTypeFormatArray = [];
        var success = true
        var that=this;
        var Visibilitycount = 0;
        var isUnitAvailable=false;
        _.each($('.datahubmodifyentitiestablecontainer tbody tr'),function(html,index){
                var temp = {};
                var dateTypeFormatMap = {};
                var dateFormat = "";
                temp["id"]=index;
                temp["columnName"] = $(html).find('.tabledisplayname').val().trim();
                temp["tableAliasName"] = $(html).find('input[type=text]').attr("tablealiasname");
                temp["orginalColumnName"] = $(html).find('input[type=text]').attr("orginalcolumnname").trim();
                temp["columnDisplayName"] = $(html).find('.tabledisplayname').val().trim();
                temp["tableDisplayType"] = $(html).find('.datecontroller option:selected').val();
                
                if(temp["tableDisplayType"]=="date" || temp["tableDisplayType"] == "datetime"){
                    if($(html).find('.csvdateformatsdroppdown select option:selected').val()=="others"){
                        dateFormat = $(html).find(".customdate-others").val().trim();
                        if($(html).find(".customdate-others").val().length==0){
                            $(html).find(".customdate-error").removeClass("hide");
                            success = false;
                        }
                    }else{
                        dateFormat = $(html).find('.csvdateformatsdroppdown.'+temp["tableDisplayType"]+' select option:selected').text().trim();
                    }
                }
                temp["dateFormat"] = dateFormat;
                temp["tableDisplayName"] = $(html).find('input[type=text]').attr("tablename");
                temp["tableName"] = $(html).find('input[type=text]').attr("tablename");
                if($(html).find('input[type=text]').attr("isPartitionKey"))
                {
                    temp["isPartitionKey"] = 'yes';
                }
                temp["visible"]='yes';
                columns.push(temp);
            });
        return columns;
    }
    CreateReportColumns=()=>{
        var columnsObj = {};
        var columns=[];
        var dateTypeFormatArray = [];
        var success = true
        var that=this;
        var Visibilitycount = 0;
        var isUnitAvailable=false;
        _.each($('.datahubmodifyentitiestablecontainer tbody tr'),(html,index)=>{
                var temp = {};
                var dateTypeFormatMap = {};
                var dateFormat = "";
                temp["id"]=index;
                temp["columnName"] = $(html).find('.tabledisplayname').val().trim();
                temp["tableAliasName"] = $(html).find('input[type=text]').attr("tablealiasname");
                temp["orginalColumnName"] = $(html).find('input[type=text]').attr("orginalcolumnname").trim();
                temp["columnDisplayName"] = $(html).find('.tabledisplayname').val().trim();
                temp["tableDisplayType"] = $(html).find('.datecontroller option:selected').val();
                
                if(temp["tableDisplayType"]=="date"){
                    if($(html).find('.csvdateformatsdroppdown select option:selected').val()=="others"){
                        dateFormat = $(html).find(".customdate-others").val().trim();
                        if($(html).find(".customdate-others").val().length==0){
                            $(html).find(".customdate-error").removeClass("hide");
                            success = false;
                        }else{
                            $(html).find(".customdate-error").addClass("hide");
                        }
                    }else{
                        dateFormat = $(html).find('.csvdateformatsdroppdown select option:selected').text().trim();
                    }
                }
                temp["dateFormat"] = dateFormat;
                temp["tableDisplayName"] = $(html).find('input[type=text]').attr("tablename");
                temp["tableName"] = $(html).find('input[type=text]').attr("tablename");
                if($(html).find('input[type=text]').attr("isPartitionKey"))
                {
                    temp["isPartitionKey"] = 'yes';
                }
                temp["visible"]='yes';
                columns.push(temp);
            });
        return columns;
    }
    applydatahubmodifyentities=(e,showMessage)=>{
        var that=this;
        if(e!==undefined){
            e.preventDefault();
        }
        if(this.props.applydatahubmodifyentities != undefined){
            this.props.applydatahubmodifyentities(e,showMessage);
        }else{
       $(".spinner").show();
        var columns=[];
        var selectedEntities={};
        var conAccessId=$('.applydatahubmodifyentities').attr("id");
        var entity=$('.applydatahubmodifyentities').attr("name").trim();
        var isCustomQuery = $('.applydatahubmodifyentities').attr("data-entityIsCustomQuery")!==undefined?$('.applydatahubmodifyentities').attr("data-entityIsCustomQuery"):false;
        var connectiondata = JSON.parse(that.props.connectionaccessdata)
        if(entity === "webdataconnector"){
            columns = this.CreateReportColumnsForWebdata();
        }else{
            columns = this.CreateReportColumns();
        }
        var storedProcs = sessionStorage.getItem("storedprocs");
        var storedProcNames = undefined;
        if(storedProcs!==undefined && storedProcs != null){
            storedProcNames = storedProcs.split(",").map((item)=>{
                  return item.trim();
            });
        }
        if($('#datahubstoredprocedureparameterstab').length>0){
            if(storedProcNames!==undefined && storedProcNames.indexOf(entity)>=0){
                connectiondata['type'] = 'storedprocedure';
                var tempObj = {};
                var mainStoredProc =[];
                tempObj["params"] = JSON.parse(sessionStorage.getItem(entity));
                mainStoredProc.push(tempObj);
                connectiondata['storedprocparams'] = mainStoredProc;
            }else{
                // for constructing stored procedure object when we open the parameters pop up(when there are no session values)
                var storedProceduresEmptyParamCount=0;
                var storedProc = [];
                $('.spparametervalue').removeClass('bird-has-error');
                _.each($(".datahubstoredprocedurecontainer table tbody tr"),(html,index)=>{
                    var storedProcedureName = $(html).attr('name');
                    var paramType = $(html).attr('paramtype');
                    var parameterName = $(html).find('.spparametername').text();
                    var dataType =$(html).find('.spdatatype').text();
                    var parameterValue = $(html).find('.spparametervalue').val();
                    var storedProcParameters = {};
                    storedProcParameters['name'] = parameterName;
                    storedProcParameters['datatype'] = dataType;
                    storedProcParameters['value'] = parameterValue;
                    if(parameterValue.length===0){
                        $(html).find('.spparametervalue').addClass('bird-has-error');
                        storedProceduresEmptyParamCount++;
                    }
                    storedProc.push(storedProcParameters);
                });
                if(storedProceduresEmptyParamCount>0){
                    setTimeout(function(){
                        $("#datahubstepscontainer").steps("previous");
                        $('.spinner').hide();
                    },800);
                    $('.spinner').hide();
                    return false;
                }
                if(storedProc.length>0){
                    sessionStorage.setItem(entity.trim(),JSON.stringify(storedProc));
                    var storedProcs = sessionStorage.getItem("storedprocs");
                    if(storedProcs!=undefined){
                        storedProcs = storedProcs+","+entity;
                    }
                    else {
                        storedProcs = entity;
                    }
                    sessionStorage.setItem("storedprocs", storedProcs);
                    connectiondata['type'] = 'storedprocedure';
                    var tempObj = {};
                    var mainStoredProc =[];
                    tempObj["params"] = storedProc;
                    mainStoredProc.push(tempObj);
                    connectiondata['storedprocparams'] = mainStoredProc;
                }
            }
        }
        if(isCustomQuery){
            if(entity.indexOf("_CQ")<0){
                entity = entity+"_CQ"
            }
            var customQueryObj = JSON.parse(sessionStorage.getItem(entity.trim()));
            if(customQueryObj===null||customQueryObj===""){
                setTimeout(()=>{
                    $("#datahubstepscontainer").steps("previous");
                    $('.spinner').hide();
                },800);
                $('.spinner').hide();
                return false;
            }
            connectiondata = JSON.parse(that.props.connectionaccessdata)
            var connectiondetailsobj = JSON.parse(connectiondata.connectionjson)
            connectiondetailsobj["query"] = customQueryObj.query;
            connectiondetailsobj["isCustomQuery"] = isCustomQuery
            connectiondata.connectionjson = JSON.stringify(connectiondetailsobj);
            connectiondata = connectiondata;
        }
        //$('.applydatahubmodifyentities').attr("name")!=undefined  $('.applydatahubmodifyentities').attr("id")!=undefined
        selectedEntities[conAccessId+"&&&"+entity.trim()] = connectiondata;  
        _.each(selectedEntities,(i,j)=>{
            i.command="validateEntities";
            if(columns.length>0){
                i.report_columns=columns;
            }
        })
        var dbType = JSON.parse(connectiondata['connectionjson']).dbType;
        var dat=new FormData();
        dat.append("entities",JSON.stringify(selectedEntities));
        SendRequest({
            url:"getentitiesmetadata",
            queryString:"",
            body: dat,
            sucessFunction:(response)=>{
                var errorCode = response.errorCode;
                var error = response.error;
                var errorTrace = response.errorTrace;
                var isError = false;
                var key = conAccessId+"&&&"+entity;
                if(response[key] !== undefined && JSON.parse(response[key]).success === false){
                    var resp = 
                    isError = true;
                    errorCode = JSON.parse(response[key]).errorCode;
                    error = JSON.parse(response[key]).error;
                    errorTrace = JSON.parse(response[key]).errorTrace;
                }
                if(response.success===false || isError){
                    _.each(this.selectedEntities,(i,j)=>{
                        if(i["report_columns"]!==undefined){
                            delete i["report_columns"];
                        }
                        delete i["command"];
                    });
                    $(".spinner").hide();
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('hide');
                    $('.birdmessage h2').empty().append('Error : ');
                    $('.birdmessage h2').append('<span class="errorcode">');
                    $('.birdmessage .errorcode').empty().append(errorCode);
                    $('.birdmessage-info').empty().text(error);
                    $('.details').addClass('show').text("Details");
                    $('.messagedetails xmp').empty().append(errorTrace);
                    setTimeout(()=>{
                        $("#datahubstepscontainer").steps("previous");
                        $('.spinner').hide();
                    },800);
                }else{
                    _.each(this.selectedEntities,(i,j)=>{
                        delete i["command"];
                        if(i["report_columns"]!=undefined){
                            delete i["report_columns"];
                        }
                    });
                    // var datahub =new Datahub();
                    // datahub.updateDataHubEntitiesObject(conAccessId,entity,columns);
                  //  this.updateDataHubEntitiesObject(conAccessId,entity,columns);
                    // this.entityMetaDataCollection= entityMetaDataCollection
                    //  this.entityMetaDataCollection.updateDataHubEntitiesObject(conAccessId,entity,columns);
                    if(showMessage!==undefined && showMessage===false){
                        this.loadSyncConfiguration();	    		    
                    }else{
                        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Success');
                        $('.birdmessage-info').empty().text(lang["Datahub.validsuccess"]);
                        $('.spinner').hide();
                    }
                }
            },
            rejectFunction:(error)=>{},
            headers:{
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            }
        })
    }
    //Fix for datatypes not updating in hub
    var Objectval = {};
    Objectval["e"] = e;
    Objectval["message"]=showMessage
    PubSub.publish("applydatahubmodifyentities",Objectval);
    }
    render() {
        return (
            <React.Fragment>
                {/* <!--datahubitemsettingsview.js --> */}
                <div role="tabpanel" className="datahubitemsettingstabscontainer" data-id={this.props.conAccessId} name={this.props.entity}>
                    <div className="entityname" style={{position:"relative"}}>
                        <i className="fa fa-table"></i>{this.props.entity}
                        <span className="entityconnectionsource">
                        <i class="fa fa-link"></i>{this.props.schemaName}
                        </span>
                        <button type="button" className="btn btn-transparent pull-right closesettingscontainer" onClick={this.props.close}>
                            <i className="fa fa-times"></i>
                        </button>
                    </div>
                    <ul className="nav nav-tabs tabcontainer" role="tablist">
                        <li role="presentation" className="tabheadli active">
                            <a href="#datahubmodifyentitiestabview" id="datahubmodifyentitiestab" aria-controls="datahubmodifyentitiestabview" role="tab" data-toggle="tab">
                                <i className="fa fa-pencil"></i> {lang["datahub.mdfyentity"]}
                            </a>
                        </li>
                        {(this.props.schemaName != undefined && (this.props.schemaName == "csv" || this.props.schemaName == "pdf" || this.props.schemaName == "json") /*&& this.props.entityId != undefined*/) ? (
                            <li role="presentation" className="tabheadli">
                                <a
                                    href="#addtoexistingtabview"
                                    className="addexistingcsvreport"
                                    id="addtoexistingtab"
                                    aria-controls="addtoexistingtabview"
                                    role="tab"
                                    data-toggle="tab"
                                    onClick={(e)=>{this.props.addexistingcsvreport(e)}}
                                >
                                    <i className="fa fa-plus"></i> {lang["datahub.addexist"]}
                                </a>
                            </li>
                        ) : null}
                        {/* <!-- Close Button --> */}
                        {/* <!-- <li role="presentation" className="tabheadli closesettingsmodal">
			<a type="button" href="javascript:void(0)" role="tab" data-toggle="tab" className="closesettingscontainer"><i className="fa fa-times"></i></a>
		</li> --> */}
                        {/* <!--\ Close Button --> */}
                    </ul>
                    <div className="tab-content">
                        <div role="tabpanel" className="tab-pane active tabviewitems" id="datahubmodifyentitiestabview">
                            {/* <!-- <div className="container-fluid marg-top-10">
				<div className="row">
					<div className="col-xs-12">
						<button className="btn btn-bird btn-sm pull-right applydatahubmodifyentities marg-righ-10" id='<%=conAccessId%>' name='<%=entity%>'><i className="fa fa-check"></i> Apply </button>
					</div>	
				</div>
			</div> --> */}
                            <div className="modal-body">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <div className="notifications-table-container marg-top-10 datahubmodifyentitiestablecontainer">
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-bird active pull-right applydatahubmodifyentities" id={this.props.conAccessId} name={this.props.entity} onClick={this.applydatahubmodifyentities}>
                                    <i className="fa fa-refresh"></i> {lang["datahub.updatebtn"]}
                                </button>
                            </div>
                        </div>
                        {(this.props.schemaName != undefined && (this.props.schemaName == "csv" || this.props.schemaName == "pdf" || this.props.schemaName == "json" || this.props.schemaName == "xls")) ? (
                            <div role="tabpanel" className="tab-pane tabviewitems" id="addtoexistingtabview">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-xs-12 nopadding">
                                            <div className="addtoexistingcontainer"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
                {/* <!-- /datahubitemsettingsview.js --> */}
            </React.Fragment>
        );
    }
}