import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'underscore';
import { getMessage } from "../../js/helpers/utils/BirdMessage";
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import SummaryTableSettingsHtml from './summarytablesettingsmodal';
import SummaryTableValueAttribute from './summarytablevalueattribute';
import SummaryTableColumnAttribute from './summarytablecolumnattribute';
import SummaryTableGroupbyAttribute from './summarytablegroupbyattribute';
import SummaryTableValuesDropdown from './summarytablevaluesdropdown';
import SummarytableColumnDropdown from './summarytablecolumndropdown';
import SummarytableGroupbyDropdown from './summarytablegroupbydropdown';
import PubSub from "pubsub-js";
const $ = window.$;

export default class SummaryTableSettings extends React.Component {
    constructor(props) {
        super(props);
        this.chartnumber = this.props.chartnumber;
        this.measures = this.props.measures;
        this.dimensions = this.props.dimensions;
        this.multifact = this.props.multifact;
        this.facttables = this.props.facttables;
        this.multifactentities = this.props.multifactentities;
        this.applyPivotChanges = this.applyPivotChanges.bind(this);
        //  this.render();
        //  this.componentDidMount();
    }
    componentDidMount() {
        // ReactDOM.render(,document.querySelector(".append_summarytablemodal0"));
        $('#sortablepivotcolumns').sortable({});
        $('#sortablepivotrows').sortable({});
        $('#sortablepivotvalues').sortable({});
        var chartData = ChartInfo.functions.getChartDetails(this.chartnumber);
        var data = JSON.parse(chartData);
        var summarytablecolumnsArray = data['summarytablecolumns'];
        var summarytablegroupArray = data['summarytablegroupbys'];
        var summarytablevaluesArray = data['summarytablevalues'];
        var that = this;
        $('.addsummarytable-aggregatedvalues li').empty();
        $('.addsummarytable-aggregatedrows li').empty();
        $('.addsummarytable-aggregatedvalues li').empty();
        if (summarytablecolumnsArray != undefined) {
            summarytablecolumnsArray.map((row, index) => {
                var summaryTableColumnAttribute = new SummaryTableColumnAttribute({
                    columnName: row['columnName'],
                    columnDisplayName: row['displayName'],
                    aggregation: row['aggregation'],
                    dataType: row['dataType'],
                    tableName: row['tableName'],
                    chartnumber: that.chartnumber,
                    multifact: that.multifact,
                    multifactentities: that.multifactentities,
                    remove: this.deleteRow
                });

            });
        }

        if (summarytablegroupArray != undefined) {
            var summaryTableGroupbyAttribute = new SummaryTableGroupbyAttribute({
                columnName: summarytablegroupArray['columnName'],
                columnDisplayName: summarytablegroupArray['displayName'],
                aggregation: summarytablegroupArray['aggregation'],
                dataType: summarytablegroupArray['dataType'],
                tableName: summarytablegroupArray['tableName'],
                chartnumber: that.chartnumber,
                multifact: that.multifact,
                multifactentities: that.multifactentities,
                remove: this.deleteColumn
            });

        }
        _.each(summarytablevaluesArray, (valueData) => {
            var measurehierarachys = ChartInfo.functions.getMeasureHierarchy();
            var selectedmeasure = $.grep(measurehierarachys, function (e) { return e.name == valueData['displayName'] });
            if (selectedmeasure.length > 0) {
                var fisrtobj = selectedmeasure[0].hierarchelist[0];
                valueData['dataType'] = "custommeasurehierarchy";
            }
            var summaryTableValueAttribute = new SummaryTableValueAttribute({
                columnName: valueData['columnName'],
                columnDisplayName: valueData['displayName'],
                aggregation: valueData['aggregation'],
                dataType: valueData['dataType'],
                tableName: valueData['tableName'],
                chartnumber: that.chartnumber,
                multifact: that.multifact,
                multifactentities: that.multifactentities,
                remove: this.deleteValue,
                changeAggregation: this.changeAggregation
            })
        });

        //$('.applysummarytablesettingchanges').click()
        setTimeout(function (e) {
            that.summaryUIValidation();
        }, 500)
                var that = this;
        $(document).on('click', '.summarytablegroupbyitem, .summarytablecolumnitem, .summarytablevalueitem, .deletecolumn, .deleterow, .deletevalue', function (e) {
            setTimeout(function (e) {
                that.summaryUIValidation();
            }, 500)
        });
    }
    render() {
        return (
            <SummaryTableSettingsHtml chartnumber={this.chartnumber} applyPivotChanges={this.applyPivotChanges} columnsdropdown={this.columnsdropdown} groupbydropdown={this.groupbydropdown} valuesdropdown={this.valuesdropdown} />
        );
    }
    groupbydropdown = (e) => {
        e.preventDefault();
        var $this = e.currentTarget;
        var that = this;
        var chartnumber = $($this).attr("data-num");
        if (this.chartnumber == chartnumber) {
            ReactDOM.unmountComponentAtNode(document.querySelector('.summarytable-groupbyList'));
            ReactDOM.render(
                <SummarytableGroupbyDropdown addColumnValue={this.addColumnValue} chartnumber={chartnumber} columnvalues={this.dimensions} multifact={that.multifact} facttables={that.facttables} multifactentities={that.multifactentities} />, document.querySelector('.summarytable-groupbyList')
            );
        }
        $('.form-control-pivot-columns').click(function (e) {
            e.stopPropagation();
        });
        $('.form-control-pivot-columns').keyup(function (e) {
            e.stopPropagation();
            var $this = e.currentTarget;
            var valThis = $($this).val().toLowerCase();
            $(".itemname").parents(".xs-list-item").each(function () {
                var text = $(this).text().toLowerCase();
                (text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
            });
        });

    }
    columnsdropdown = (e) => {
        e.preventDefault();
        var $this = e.currentTarget;
        var that = this;
        var chartnumber = $($this).attr("data-num");
        if (this.chartnumber == chartnumber) {
            ReactDOM.unmountComponentAtNode(document.querySelector('.summarytable-columnsList'))
            ReactDOM.render(
                <SummarytableColumnDropdown addRowValue={this.addRowValue} chartnumber={chartnumber} rowvalues={this.dimensions} multifact={that.multifact} facttables={that.facttables} multifactentities={that.multifactentities} />, document.querySelector('.summarytable-columnsList')
            );
        }
        $('.form-control-pivot-rows').click(function (e) {
            e.stopPropagation();
        });
        $('.form-control-pivot-rows').keyup(function (e) {
            e.stopPropagation();
            var $this = e.currentTarget;
            var valThis = $($this).val().toLowerCase();
            $(".itemname").parents(".xs-list-item").each(function () {
                var text = $(this).text().toLowerCase();
                (text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
            });
        });
    }
    valuesdropdown = (e) => {
        e.preventDefault();
        var $this = e.currentTarget;
        var that = this;
        var chartnumber = $($this).attr("data-num");
        if (this.chartnumber == chartnumber) {
            ReactDOM.unmountComponentAtNode(document.querySelector('.summarytable-valuesList'));
            ReactDOM.render(
                <SummaryTableValuesDropdown addValue={this.addValue} chartnumber={chartnumber} values={this.dimensions} multifact={that.multifact} facttables={that.facttables} multifactentities={that.multifactentities} />, document.querySelector('.summarytable-valuesList')
            )
        }
        $('.form-control-pivot-values').click(function (e) {
            e.stopPropagation();
        });
        $('.form-control-pivot-values').keyup(function (e) {
            e.stopPropagation();
            var $this = e.currentTarget;
            var valThis = $($this).val().toLowerCase();
            $(".itemname").parents(".xs-list-item").each(function () {
                var text = $(this).text().toLowerCase();
                (text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
            });
        });
    }
    addColumnValue = (e) => {
        e.preventDefault();
        var $this = e.currentTarget;
        if ($('.summarytable-groupby li').length > 1) {
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Information');
            $('.birdmessage-info').empty().text(getMessage('BirdInformation27'));
        } else {
            var tableName = $($this).attr("data-parent");
            var columnName = $($this).attr("data-child");
            var dataType = $($this).attr("data-type");
            var columnDisplayName = $($this).attr("data-name");
            var that = this;
            var summaryTableGroupbyAttribute = new SummaryTableGroupbyAttribute({
                remove: this.deleteColumn,
                multifactentities: that.multifactentities,
                multifact: that.multifact,
                chartnumber: that.chartnumber,
                tableName: tableName,
                dataType: dataType,
                aggregation: "Count",
                columnName: columnName,
                columnDisplayName: columnDisplayName
            });
        }
    }
    addRowValue = (e) => {
        e.preventDefault();
        var $this = e.currentTarget;
        if ($('.summarytable-row li').length > 6) {
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Information');
            $('.birdmessage-info').empty().text(getMessage('BirdInformation27'));
        } else {
            var tableName = $($this).attr("data-parent");
            var columnName = $($this).attr("data-child");
            var dataType = $($this).attr("data-type");
            var columnDisplayName = $($this).attr("data-name");
            var that = this;
            var isRowValueAlreadyExist = false;
            _.each($('.summarytable-row li'), function (xAxisli) {
                if ($(xAxisli).attr("data-child") == $($this).attr("data-child")) {
                    isRowValueAlreadyExist = true;
                }
            });
            if (isRowValueAlreadyExist == false) {
                var summaryTableColumnAttribute = new SummaryTableColumnAttribute({
                    remove: this.deleteRow,
                    multifactentities: that.multifactentities,
                    multifact: that.multifact,
                    chartnumber: that.chartnumber,
                    tableName: tableName,
                    dataType: dataType,
                    aggregation: "Count",
                    columnName: columnName,
                    columnDisplayName: columnDisplayName
                });
            }
        }
    }
    addValue = (e) => {
        e.preventDefault();
        var $this = e.currentTarget;
        var tableName = $($this).attr("data-parent");
        var columnName = $($this).attr("data-child");
        var dataType = $($this).attr("data-type");
        var columnDisplayName = $($this).attr("data-name");
        var that = this;
        var isColumnValueAlreadyExist = false;
        _.each($('.summarytablevalueattribute'), function (xAxisli) {
            if ($(xAxisli).attr("data-child") == $($this).attr("data-child")) {
                isColumnValueAlreadyExist = true;
            }
        });
        if (isColumnValueAlreadyExist == false) {
            var summaryTableValueAttribute = new SummaryTableValueAttribute({
                remove: this.deleteValue,
                changeAggregation: this.changeAggregation,
                multifactentities: that.multifactentities,
                multifact: that.multifact,
                chartnumber: that.chartnumber,
                tableName: tableName,
                dataType: dataType,
                aggregation: "Count",
                columnName: columnName,
                columnDisplayName: columnDisplayName
            })
        }
    }
    deleteColumn(e) {
        e.preventDefault();
        var $this = e.currentTarget;
        var currentvalue = $($this).closest('span').prev().text();
        $(".summarytable-groupby li[title='" + currentvalue + "']").remove();
    }
    deleteRow(e) {
        e.preventDefault();
        var $this = e.currentTarget;
        var currentvalue = $($this).closest('span').prev().text();
        $(".summarytable-row li[title='" + currentvalue + "']").remove();
    }
    deleteValue(e) {
        e.preventDefault();
        var $this = e.currentTarget;
        $($this).parent().parent().remove();
    }
    changeAggregation = (e) => {
        e.preventDefault();
        $(".append_summarytablemodal" + this.chartnumber).find(".aggregation-li-item").removeClass("active");
        $(e.currentTarget).addClass("active");
        var displayname = $(e.currentTarget).parent().parent().attr('title').replace(/([#;?&,%. +*~\':"!^$%[\]()<=>|\/@])/g, "\\$1");
        $('.summarytabledisplayvalues[title="' + displayname + '"]').find('#summarytabledropdownaggregation>span').text($(e.currentTarget).text())
        var columnName = $(e.currentTarget).closest(".summarytablevalueattribute").attr("data-name");
        var columnDisplayName = $(e.currentTarget).closest(".summarytabledisplayvalues").attr("title");
        var aggregation = $(e.currentTarget).attr("title");
        var aggregationtext = $(e.currentTarget).text();
    }
    applyPivotChanges = (e) => {
        sessionStorage.setItem("changesdone", 1);
        e.preventDefault();
        var $this = e.currentTarget;
        var rowsArray = [];
        var groupbyArray;
        var valuesArray = [];
        if (this.chartnumber == $($this).attr("data-num")) {
            var that = this;
            $('#spinner' + this.chartnumber).show();
            if ($(".summarytable-groupby li").length > 0) {
                groupbyArray = {};
                _.each($(".summarytable-groupby li"), function (listObj) {
                    var columnName = $(listObj).attr("data-child");
                    var columnDisplayName = $(listObj).attr("title");
                    var columnDataType = $(listObj).attr("data-type");
                    var tableName = $(listObj).attr("data-parent");
                    groupbyArray["columnName"] = columnName;
                    groupbyArray["displayName"] = columnDisplayName;
                    groupbyArray["dataType"] = columnDataType;
                    groupbyArray["aggregation"] = "Count";
                    groupbyArray["tableName"] = tableName;
                });
            }
            _.each($(".summarytable-row li"), function (listObj) {
                var rows = {};

                var columnName = $(listObj).attr("data-child");
                var columnDisplayName = $(listObj).attr("title");
                var columnDataType = $(listObj).attr("data-type");
                var tableName = $(listObj).attr("data-parent");

                rows["columnName"] = columnName;
                rows["displayName"] = columnDisplayName;
                rows["dataType"] = columnDataType;
                rows["aggregation"] = "Count";
                rows["tableName"] = tableName;
                rowsArray.push(rows);
            });

            _.each($(".summarytable-value li.summarytabledisplayvalues"), function (listObj) {
                var values = {};

                var columnName = $(listObj).attr("data-child");
                var columnDisplayName = $(listObj).attr("title");
                var columnDataType = $(listObj).attr("data-type");
                var tableName = $(listObj).attr("data-parent");
                var percentileValue = $(listObj).find('.percentilebox').val();
                var tempName = columnDisplayName.replace(/([#;?&,%. +*~\':"!^$%[\]()<=>|\/@])/g, "\\$1");
                var aggregation = $(".summarytable-value li.summarytabledisplayvalues[title='" + tempName + "'] #summarytabledropdownaggregation>span").text().replace(/[^A-Z0-9]/ig, "") + ",".trim();
                if (columnDataType != undefined && columnDataType == "custommeasurehierarchy") {
                    aggregation = "customaggregatedfield" + ",".trim();
                }
                var measurehierarachys = ChartInfo.functions.getMeasureHierarchy();
                var selectedmeasure = $.grep(measurehierarachys, function (e) { return e.name == columnDisplayName })
                if (selectedmeasure.length > 0) {
                    var firstObj = selectedmeasure[0]["hierarchelist"][0];
                    values["columnName"] = firstObj.columnName;
                    values["displayName"] = columnDisplayName;
                    values["dataType"] = firstObj.dataType;
                    values["aggregation"] = firstObj.aggregation;
                    values["tableName"] = firstObj.tableName;
                    values["percentilevalue"] = firstObj.percentilevalue;
                    values["displayDataType"] = "custommeasurehierarchy";
                } else {
                    values["columnName"] = columnName;
                    values["displayName"] = columnDisplayName;
                    values["dataType"] = columnDataType;
                    values["aggregation"] = aggregation.substring(0, aggregation.length - 1);
                    values["tableName"] = tableName;
                    values["percentilevalue"] = percentileValue;
                }
                valuesArray.push(values);
            });

            if (rowsArray.length == 0) {
                $('#spinnerchart' + this.chartnumber).hide();
                $('.addpivotrows-error').removeClass('hide');
                setTimeout(function () {
                    $('.addpivotrows-error').addClass('hide');
                }, 3000);

            }
            else if (valuesArray.length == 0) {
                $('#spinnerchart' + this.chartnumber).hide();
                $('.addpivotcolumns-error').removeClass('hide');
                setTimeout(function () {
                    $('.addpivotcolumns-error').addClass('hide');
                }, 3000);
            }
            else {
                var chartData = ChartInfo.functions.getChartDetails(parseInt(this.chartnumber, 10));
                var facttables = that.facttables;
                var rowfact;
                var valuefact;
                var groupfact;
                var counter = 0;
                var data = JSON.parse(chartData);
                data['summarytablecolumns'] = rowsArray;
                data['summarytablegroupbys'] = groupbyArray;
                data['summarytablevalues'] = valuesArray;
                var customFields = sessionStorage.getItem("customFields");
                var customFeildsArray = JSON.parse(customFields);
                //for rows comparing
                if (that.multifact == "true") {
                    _.each(rowsArray, function (rows, i) {
                        rowfact = rows.columnName.split(".").shift();
                        _.each(facttables, function (fact, j) {
                            if (fact == rowfact) {
                                counter++;
                            }
                        });
                    });
                    //for rows with customfields comparing
                    if (counter <= 0) {
                        if (customFields != undefined && customFields.length > 0) {
                            var rowcustomFieldCheck;
                            _.each(customFeildsArray, function (i, j) {
                                _.each(rowsArray, function (rows, r) {
                                    if (rows.columnName.split(".").shift() == i.name) {
                                        var usedFieldstest = i.usedFields;
                                        _.each(usedFieldstest, function (ii, jj) {
                                            rowcustomFieldCheck = ii;
                                            rowcustomFieldCheck = rowcustomFieldCheck.split(".").shift();
                                            _.each(facttables, function (fact, j) {
                                                if (fact == rowcustomFieldCheck) {
                                                    counter++;
                                                }
                                            })
                                        })
                                    }
                                })
                            })
                        }
                    }
                    //for rows with customdrilldownheirarchy
                    if (counter <= 0) {
                        var customdrilldownhierarchyArray = ChartInfo.functions.getcustomhierarchy();
                        var rowsdrilldownhierarchycheck;
                        if (customdrilldownhierarchyArray != undefined && customdrilldownhierarchyArray != null) {
                            _.each(customdrilldownhierarchyArray, function (i, j) {
                                _.each(rowsArray, function (rows, r) {
                                    var hierarchelist = customdrilldownhierarchyArray[j].hierarchelist;
                                    _.each(hierarchelist, function (object, index) {
                                        if (customFields != undefined && customFields.length > 0) {
                                            var dhcustomFieldCheck;
                                            _.each(customFeildsArray, function (i, j) {
                                                if (hierarchelist[index].columnName == i.name) {
                                                    var usedFieldstest = i.usedFields;
                                                    _.each(usedFieldstest, function (ii, jj) {
                                                        dhcustomFieldCheck = ii;
                                                        dhcustomFieldCheck = dhcustomFieldCheck.split(".").shift();
                                                    })
                                                }
                                            })
                                        }
                                        if (customdrilldownhierarchyArray[j].name == rows.columnName.split(".").shift()) {
                                            rowsdrilldownhierarchycheck = hierarchelist[index].columnName.split(".").shift();
                                            _.each(facttables, function (fact, j) {
                                                if (fact == rowsdrilldownhierarchycheck || fact == dhcustomFieldCheck) {
                                                    counter++;
                                                }
                                            });
                                        }

                                    })
                                })
                            })
                        }
                    }

                    //for values comparing
                    if (counter <= 0) {
                        _.each(valuesArray, function (values, i) {
                            valuefact = values.columnName.split(".").shift();
                            _.each(facttables, function (fact, j) {
                                if (fact == valuefact) {
                                    counter++;
                                }
                            });
                        });

                        //for values with customfields comparing
                        if (counter <= 0) {
                            if (customFields != undefined && customFields.length > 0) {
                                var valuecustomFieldCheck;
                                _.each(customFeildsArray, function (i, j) {
                                    _.each(valuesArray, function (values, r) {
                                        if (values.columnName.split(".").shift() == i.name) {
                                            var usedFieldstest = i.usedFields;
                                            _.each(usedFieldstest, function (ii, jj) {
                                                valuecustomFieldCheck = ii;
                                                valuecustomFieldCheck = valuecustomFieldCheck.split(".").shift();
                                                _.each(facttables, function (fact, j) {
                                                    if (fact == valuecustomFieldCheck) {
                                                        counter++;
                                                    }
                                                })
                                            })
                                        }
                                    })
                                })
                            }
                        }
                        //for comparing values with custom measure 
                        if (counter <= 0) {
                            var cumstoMeasures = ChartInfo.functions.getCustomMeasureField();
                            if (cumstoMeasures != undefined && cumstoMeasures.length > 0) {
                                var customMeasureCheck;
                                _.each(cumstoMeasures, function (i, j) {
                                    _.each(valuesArray, function (values, v) {
                                        if (values.columnName.split(".").shift() == i.name) {
                                            var usedcustommeasures = i.usedFields.split(",");
                                            _.each(usedcustommeasures, function (ii, jj) {
                                                customMeasureCheck = ii;
                                                if (customFields != undefined && customFields.length > 0) {
                                                    var customFieldCheckInMeasure;
                                                    _.each(customFeildsArray, function (i, j) {
                                                        if (customMeasureCheck == i.name) {
                                                            var usedFieldstest = i.usedFields;
                                                            _.each(usedFieldstest, function (ii, jj) {
                                                                customFieldCheckInMeasure = ii;
                                                                customFieldCheckInMeasure = customFieldCheckInMeasure.split(".").shift();
                                                            })
                                                        }
                                                    })
                                                }
                                                customMeasureCheck = customMeasureCheck.split(".").shift();
                                                _.each(facttables, function (fact, j) {
                                                    if (fact == customMeasureCheck || fact == customFieldCheckInMeasure) {
                                                        counter++;
                                                    }
                                                });
                                            })
                                        }
                                    })
                                });
                            }
                        }

                        //for grouparray comapring
                        if (counter <= 0) {
                            _.each(groupbyArray, function (group, i) {
                                groupfact = groupbyArray.columnName.split(".").shift();
                                _.each(facttables, function (fact, j) {
                                    if (fact == groupfact) {
                                        counter++;
                                    }
                                });
                            });
                        }
                        //for group with customfields comparing
                        if (counter <= 0) {
                            if (customFields != undefined && customFields.length > 0) {
                                var groupcustomFieldCheck;
                                _.each(customFeildsArray, function (i, j) {
                                    _.each(groupbyArray, function (group, r) {
                                        if (groupbyArray.columnName.split(".").shift() == i.name) {
                                            var usedFieldstest = i.usedFields;
                                            _.each(usedFieldstest, function (ii, jj) {
                                                groupcustomFieldCheck = ii;
                                                groupcustomFieldCheck = groupcustomFieldCheck.split(".").shift();
                                                _.each(facttables, function (fact, j) {
                                                    if (fact == groupcustomFieldCheck) {
                                                        counter++;
                                                    }
                                                })
                                            })
                                        }
                                    })
                                })
                            }
                        }

                        //for group with customdrilldownheirarchy
                        if (counter <= 0) {
                            var customdrilldownhierarchyArray = ChartInfo.functions.getcustomhierarchy();
                            var groupdrilldownhierarchycheck;
                            if (customdrilldownhierarchyArray != undefined && customdrilldownhierarchyArray != null) {
                                _.each(customdrilldownhierarchyArray, function (i, j) {
                                    _.each(groupbyArray, function (group, r) {
                                        var hierarchelist = customdrilldownhierarchyArray[j].hierarchelist;
                                        _.each(hierarchelist, function (object, index) {
                                            if (customFields != undefined && customFields.length > 0) {
                                                var dhcustomFieldCheck;
                                                _.each(customFeildsArray, function (i, j) {
                                                    if (hierarchelist[index].columnName == i.name) {
                                                        var usedFieldstest = i.usedFields;
                                                        _.each(usedFieldstest, function (ii, jj) {
                                                            dhcustomFieldCheck = ii;
                                                            dhcustomFieldCheck = dhcustomFieldCheck.split(".").shift();
                                                        })
                                                    }
                                                })
                                            }
                                            if (customdrilldownhierarchyArray[j].name == groupbyArray.columnName.split(".").shift()) {
                                                groupdrilldownhierarchycheck = hierarchelist[index].columnName.split(".").shift();
                                                _.each(facttables, function (fact, j) {
                                                    if (fact == groupdrilldownhierarchycheck || fact == dhcustomFieldCheck) {
                                                        counter++;
                                                    }
                                                });
                                            }

                                        })
                                    })
                                })
                            }
                        }

                    }

                    if (counter > 0) {
                        ChartInfo.functions.updateChartDetails(this.chartnumber, JSON.stringify(data));
                    }
                    else {
                        $('.gridspinner').hide();
                        $(".spinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Warning');
                        $('.birdmessage-info').empty().text(getMessage('BirdWarning138'));
                        return
                    }
                }
                else {
                    ChartInfo.functions.updateChartDetails(this.chartnumber, JSON.stringify(data));
                }
                var applyObj = {};
                var that = this;
                applyObj["currentChartNumber"] = that.chartnumber;
                applyObj["measures"] = this.measures;
                applyObj["dimensions"] = this.dimensions;
                applyObj["pos"] = "";
                applyObj["chartDetails"] = data;
                applyObj["isExcludeorInclude"] = undefined;
                applyObj["isDateHistogram"] = undefined;
                applyObj["dateHistomgramColName"] = undefined;
                applyObj["applyattributes"] = undefined;
                //fix for summary table applying only for last recent chart chart number issue
                // if(that.props.viewsummarytabe === undefined){
                PubSub.publish('viewSummaryTabe', applyObj);
                // }else{
                // // this.broker.trigger("viewSummaryTabe",this.chartnumber, this.measures,this.dimensions,"",data);
                // that.props.viewsummarytabe(this.chartnumber, this.measures, this.dimensions, "", data, undefined, undefined, undefined, undefined);
                // }
                //this.viewSummaryTabe(this.chartnumber,this.measures,this.dimensions,this.pos, chartDetails);	
                $("#pivotpagesettingsmodal").removeClass('.in');
                $("body").removeClass("modal-open");
                //setTimeout(function () { $(".append_summarytablemodal" + this.chartnumber).empty(); }, 500);

            }

        }
        sessionStorage.removeItem("drilldown" + this.chartnumber);
        $(".breadcrumb[data-num=" + this.chartnumber + "]").remove();
        $("#chart" + this.chartnumber + "").before("<ul class='breadcrumb' data-num=" + this.chartnumber + "></ul>");
        if (this.chartnumber != undefined) { }
    }
    searchPivotColumns(e) {
        var $this = e.currentTarget;
        var valThis = $($this).val().toLowerCase();
        $(".itemname").parents(".xs-list-item").each(function () {
            var text = $(this).text().toLowerCase();
            (text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
        });
    }
    searchPivotRows(e) {
        var $this = e.currentTarget;
        var valThis = $($this).val().toLowerCase();
        $(".itemname").parents(".xs-list-item").each(function () {
            var text = $(this).text().toLowerCase();
            (text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
        });
    }
    searchPivotValues(e) {
        var $this = e.currentTarget;
        var valThis = $($this).val().toLowerCase();
        $(".itemname").parents(".xs-list-item").each(function () {
            var text = $(this).text().toLowerCase();
            (text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
        });
    }
    summaryUIValidation() {
        //Disable other options when there is no Aggregated Columns selected in Summary Settings
        var sAgg = $(".summarytabledisplayvalues").length,
            sText = $(".summarytable-row  .singleliitems").length,
            sGrpBy = $(".summarytable-groupby  .singleliitems").length;
        if (sAgg == 0) {
            $(".addsummarytable-aggregatedrows").addClass('disabled');
            $(".addsummarytable-groupby").addClass('disabled');
        } else {
            $(".addsummarytable-aggregatedrows").removeClass('disabled');
            $(".addsummarytable-groupby").removeClass('disabled');
        }
    }
}