import React from "react";
import { lang } from "../js/helpers/utils/languages/language";

export default class ToolTipAttrModalViewTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
render(){
    var that = this.props;
    return(
// <!-- tooltipattributesview.js -->
<li className="boc-list-item singleliitems" data-name={that.yaxiscolumnName} data-agg={that.aggregation} data-type={that.yaxiscolumnType} title={that.yaxiscolumnDisplayName} table-name={that.yaxistableName} percentile-value={that.percentileValue}>
	<span className="itemcontainer">
		<span className="itemtype">
			{(that.yaxiscolumnType ==='customaggregatedfield')?(
				<span className="function">{lang["storyboard.fx"]}</span>
            ):(that.yaxiscolumnType === 'custommeasurehierarchy')?(
				<i className="fa fa-list"></i>
            ):(that.yaxiscolumnType === 'string')?(
				<span>{lang["storyboard.abc"]}</span>
            ):(that.yaxiscolumnType === 'number')?(
				<>123</>	
            ):(that.yaxiscolumnType === 'date')?(
				<i className="fa fa-calendar"></i>	
            ):null}
		</span>
		<span className="itemname" title={that.yaxiscolumnDisplayName}>{that.yaxiscolumnDisplayName}</span>
	</span>
	<span className="itemoptions">
		<span className="itemremove">
			<button className="btn btn-transparent deletetooltip" onClick={that.deleteelement} type="button" title={lang["storyboard.remove"]}><i className="fa fa-trash-o"></i></button>
			
		</span>
		<span className="itemaggregation dropup">
		{(that.isDirect == 0 || that.isStoredProcedure == 0)?(
        <span className="aggregationtext" aria-expanded="false" data-toggle="dropdown">[ {that.aggregation} ]</span>
         ):null}
		<ul role="menu" className="dropdown-menu">
			{(that.yaxiscolumnType != 'string'&& that.yaxiscolumnType != 'date' && that.yaxiscolumnType != 'month' && that.yaxiscolumnType != 'day')?(
			(that.isDirect == 0 || that.isStoredProcedure == 0)?(
                <>
            <li className="aggregation-li-item aggsum" onClick={that.changetooltipAggregation} title={lang["storyboard.sum"]}>{lang["storyboard.sum"]}</li>
            <li className="aggregation-li-item aggmin" onClick={that.changetooltipAggregation} title={lang["storyboard.minimum"]}>{lang["storyboard.minimum"]}</li>
            <li className="aggregation-li-item aggmax" onClick={that.changetooltipAggregation} title={lang["storyboard.max"]}>{lang["storyboard.max"]}</li>
            <li className="aggregation-li-item aggavg" onClick={that.changetooltipAggregation} title={lang["storyboard.avg"]}>{lang["storyboard.avg"]}</li>
            <li className="aggregation-li-item aggstandarddeviation" onClick={that.changetooltipAggregation} title={lang["storyboard.standard"]}>{lang["storyboard.standard"]}</li>
            <li className="aggregation-li-item aggpercentile" onClick={that.changetooltipAggregation} title={lang["storyboard.percentile"]}>{lang["storyboard.percentile"]}
				<span className="opacity-effect" ><input type="number" className="percentilebox" placeholder="1" min="25" max="100" defaultValue="25"/></span>
            </li>
            <li className="aggregation-li-item aggmedian" onClick={that.changetooltipAggregation} title={lang["storyboard.median"]}>{lang["storyboard.median"]}</li>
            </>
            ):null
			// <!-- <li className="aggregation-li-item aggrunningsum" title="Running Sum">Running Sum</li>
			// <li className="aggregation-li-item aggrunningaverage" title="Running Average">Running Average</li> -->
		//	<!-- <%if(that.isDirect ==0){ %><li className="aggregation-li-item aggvariance" title="Variance">Variance</li><%}%> -->
			//<!-- <%if(that.isDirect ==0){ %><li className="aggregation-li-item aggsumofsquares" title="Sum of Squares">Sum of Squares</li><%}%> -->
			
            ):null}

			 {(that.yaxiscolumnType == 'date')?(
				(that.isDirect == 0 || that.isStoredProcedure == 0)?(<li className="aggregation-li-item aggmin" onClick={that.changetooltipAggregation} title={lang["storyboard.minimum"]}>{lang["storyboard.minimum"]}</li>):null
				(that.isDirect == 0 || that.isStoredProcedure == 0)?(<li className="aggregation-li-item aggmax" onClick={that.changetooltipAggregation} title={lang["storyboard.max"]}>{lang["storyboard.max"]}</li>):null
             ):null}
			{(that.isDirect == 0 || that.isStoredProcedure == 0)?(<li className="aggregation-li-item aggcountdistinct" onClick={that.changetooltipAggregation} title={lang["storyboard.countdistinct"]}>{lang["storyboard.countdistinct"]}</li>):null}
			{(that.isDirect == 0 || that.isStoredProcedure == 0)?(<li className="aggregation-li-item aggcount" onClick={that.changetooltipAggregation} title={lang["storyboard.count"]}>{lang["storyboard.countall"]}</li>):null}
			{(that.isDirect ==1)?(<li className="aggregation-li-item aggraw" onClick={that.changetooltipAggregation} title={lang["storyboard.Raw"]}>{lang["storyboard.Raw"]}</li>):null}
		</ul>
		</span>
	</span>
</li>
    );
}
}