import React from 'react';
import ReactDOM from 'react-dom';
import SendRequest from '../../SendRequest';
import ShowLicenseTemplate from './ShowLicenseTemplate';
const $ = window.$;

export default class ShowLicenseView extends React.Component{
    constructor(props){
        super(props);
        this.showlicense();
    }
    showlicense(){
        this.clientId=this.props.clientId;
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getalllicensedetailsformultitenantclient",
            queryString: "?clientId="+this.clientId,
            sucessFunction: (response) => { 
                this.render(response);
              },
              rejectFunction: () => { },
              headers: requestHeaders
            })
    }
    render(response){
        ReactDOM.unmountComponentAtNode(document.querySelector(".adminaccountform"));
        ReactDOM.render(<ShowLicenseTemplate  subClientsDetails={response} />,document.querySelector(".adminaccountform"));
        
        return(<div></div>);
    }
}