import React from "react";
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import _ from 'underscore';
import MLKeyInfluencersBodyView from "./mlkeyinfuencersbodyview";
import * as TabInfo from "../../js/helpers/utils/tabinfo";
const $ = window.$;
export default class MLKeyInfluencersDrillDown extends React.Component {
	constructor(props) {
		super(props)
		this.options = props;
		this.xaxis = this.options.categoryname;
		this.yaxis = this.options.targetColumn;
		this.features = this.options.features;
		this.filter = this.options.filter;
		this.datamodelId = this.options.datamodelId;
		this.keyinfluencer = this.options.keyinfluencer;
		this.htmlnlgdata = this.options.htmlnlgdata;
		this.chartnum = this.options.chartnum;
		this.reportId = this.options.reportId;
		this.topFourKeyInfluencers = [];
		this.topTwoNumericalKeyInfluencers = [];
		this.topTwoCategoricalKeyInfluencers = [];
		this.xAxisDatatype = this.options.datatype;
		this.entities = "";
		this.interceptForSimulation = this.options.interceptForSimulation;
		this.isYaxisCustomField = this.options.isTargetColumnCustomField;
		this.dimensions = this.options.dimensions;
		this.columnIdsToNamesMap = this.options.columnIdsToNamesMap;
		this.customFields = JSON.parse(sessionStorage.getItem("customFields"));
		this.isFromStrybok = false;
		var location = window.location;
		this.tabid = 0;
		if (location.pathname.indexOf("viewstorybook") > 0) {
			this.isFromStrybok = true;
			this.options.tabid = TabInfo.functions.getCurrentTabId();
			this.tabid = TabInfo.functions.getCurrentTabId();
		}
		var that = this;
		_.each(that.keyinfluencer, function (obj, index) {
			if (obj.name != that.xaxis && that.topFourKeyInfluencers.length < 4) {
				that.topFourKeyInfluencers.push(obj);
			}
			if (obj.name != that.xaxis && obj.ydatatype == "number" && that.topTwoNumericalKeyInfluencers.length < 2) {
				that.topTwoNumericalKeyInfluencers.push(obj);
			}
			if (obj.name != that.xaxis && obj.ydatatype == "string" && that.topTwoCategoricalKeyInfluencers.length < 2) {
				that.topTwoCategoricalKeyInfluencers.push(obj);
			}
		});
		ChartInfo.functions.setTopKeyInfluencers(that.reportId, that.keyinfluencer);
		ChartInfo.functions.setTopTwoNumericalKeyInfluencers(that.reportId, that.topTwoNumericalKeyInfluencers);
		ChartInfo.functions.setTopTwoCategoricalKeyInfluencers(that.reportId, that.topTwoCategoricalKeyInfluencers);
		that.render();
	}
	render() {
		var that = this;
		for (var i = 4; i < 5; i++) {
			var incrementer = i;
			i = that.isFromStrybok ? that.tabid + "_" + i : i;
			var chartnum = i;
			if (incrementer == 11 && !that.options.isDataTableHasToLoad) {
			} else {
				$('#mlchart' + (i)).empty();
				$("#spinnerchart" + (i)).show();
			}
			that.collection = null
			var mlKeyInfluencersBodyView = new MLKeyInfluencersBodyView({
				xAxisDatatype: that.xAxisDatatype,
				chartnum: chartnum,
				xaxis: that.xaxis,
				yaxis: that.yaxis,
				reportId: that.reportId,
				broker: that.broker,
				features: that.features,
				collection: that.collection,
				model: null,
				topFourKeyInfluencers: that.topFourKeyInfluencers,
				datedropdown: that.options.datedropdown,
				datatableData: that.options.datatableData,
				isDataTableHasToLoad: that.options.isDataTableHasToLoad,
				totalCharts: 15,
				interceptForSimulation: that.interceptForSimulation,
				isYaxisCustomField: that.isYaxisCustomField,
				dimensions: that.dimensions,
				columnIdsToNamesMap: that.columnIdsToNamesMap,
				datamodelId: that.datamodelId,
				isFromStrybok: that.isFromStrybok,
				tabid: that.tabid
			})
			i = incrementer;
		}
		return (
			<React.Fragment />
		)
	}
}