import React from 'react';
import { lang } from "../js/helpers/utils/languages/language";
export default class UserCustomscriptModal extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		return (
			// <!-- usercustomscriptview.js -->
			<div className="modal fade in" id="usercustomscriptmodal" tabIndex="-1" role="dialog" aria-labelledby="usercustomscriptmodal" aria-hidden="true" style={{ display: "block" }}>
				<div className="modal-backdrop fade in"></div>
				<div className="modal-dialog modal-lg modal-dialog-lg-center modal-dialog-center" style={{ zIndex: "1050" }}>
					<div className="modal-content">
						<div className="modal-header boc-modal-hf-padding">
							<button type="button" className="close white" id="livemodalclose" aria-hidden="true" data-dismiss="modal" onClick={this.props.close}>&times;</button>
							<h4 className="modal-title"><i className="fa fa-code-fork"></i> {lang["storyboard.customscript"]}</h4>
						</div>
						<div className="modal-body scrollset">
							<div className="container-fluid">
								<div className="row filtercontent marg-top-20 marg-bot-20">
									<div className="querydisplay clear scrollset advancedcustomquery" id="usercustomscriptdata" contentEditable="true"></div>
								</div>
							</div>
						</div>
						<div className="modal-footer boc-modal-hf-padding">
							<button type="button" className="btn btn-bird" id="" data-dismiss="modal" onClick={this.props.close}><i className="fa fa-times"></i> {lang["storyboard.cancelbtn"]}</button>
							<button type="button" className="btn btn-bird applyusercustomscript" id="applyusercustomscript" data-num={this.props.chartnumber} tab-id="0" tab-name="" onClick={this.props.applyusercustomscript}><i className="fa fa-check"></i> {lang["storyboard.applybtn"]}</button>
						</div>
					</div>{/* <!-- /.modal-content --> */}
				</div>{/* <!-- /.modal-dialog --> */}
			</div>
			// <!-- /.Page Settings Modal -->
		);
	}
}