import React from 'react';
import ReactDOM from 'react-dom';
import PubSub from 'pubsub-js'
import _ from 'underscore'
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import ViewReportFilterView from './viewreportfilter';
import SingleSavedReportFilterTemplate from './singlesavedreportfilters.jsx';
const $ = window.$;

export default class SingleSavedReportFilterView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        ReactDOM.unmountComponentAtNode(document.getElementById('singlefiltercontent'));
        this.state.el = "#singlefiltercontent";
        // $(this.state.el).empty();
        this.state.collection = this.props.collection;
        this.state.reportId = this.props.reportId;
        this.state.filterArray = this.props.filterArray;
        this.state.sharedFilters = this.props.sharedFilters;
        this.state.chartNumber = this.props.chartNumber;
        this.state.schemaType = "";
        this.state.schemaType = "";
        this.render();
    }
    render() {
        var finalCustomFieldArray = JSON.parse(JSON.stringify(this.state.collection));
        var parsedCustomFields = [];
        var customfields = sessionStorage.getItem("customFields");
        if (customfields != undefined && customfields != "null" && customfields != null) {
            parsedCustomFields = JSON.parse(customfields);
        }
        $.each(parsedCustomFields, function (index1, customField) {
            if (customField.dataType != undefined && customField.dataType != "null" && customField.dataType != null && customField.dataType != "") {
                customField["columnDisplayType"] = customField.dataType;
            } else {
                customField["columnDisplayType"] = "string";
            }
            parsedCustomFields.slice(index1, customField);
        });

        var filterObjectArray = this.state.filterArray;
        if (filterObjectArray != undefined && filterObjectArray != "null" && filterObjectArray != null) {
            $.each(filterObjectArray, function (index, filterObject) {
                $.each(parsedCustomFields, function (index1, customField) {
                    if (customField["name"] == filterObject["columnname"]) {
                        customField["columnDisplayType"] = filterObject["dataType"];
                        parsedCustomFields.slice(index1, customField);
                    }
                });
            });
        }
        var convertedTypes = sessionStorage.getItem("convertedtypes");
        var parsedConvertedTypes = [];
        if (convertedTypes != undefined && convertedTypes != "null" && convertedTypes != null) {
            parsedConvertedTypes = JSON.parse(convertedTypes);
        }
        $.each(parsedConvertedTypes, function (index, convertedType) {
            $.each(parsedCustomFields, function (index1, customField) {
                if (customField['name'] == convertedType.columnName) {
                    customField['columnDisplayType'] = convertedType.dataType;
                    parsedCustomFields.slice(index1, customField);
                }
            });
        });
        if (parsedCustomFields.length != 0 && parsedCustomFields != undefined) {
            parsedCustomFields.sort(function (a, b) {
                var x = a.name.toLowerCase();
                var y = b.name.toLowerCase();
                return x < y ? -1 : x > y ? 1 : 0;
            });
        }
        if (this.state.schemaType == "mysql") {
            var tableNamesArray = [];
            var finaltableNamesArray = [];
            var coll1 = finalCustomFieldArray;
            $.each(coll1, function (index, data) {
                if (tableNamesArray.indexOf(data.tableName) < 0) {
                    tableNamesArray.push(data.tableName);
                }
            });
            $.each(tableNamesArray, function (index, data) {
                var tableObj = [];
                $.each(coll1, function (index, data1) {
                    if (data == data1.tableName) {
                        tableObj.push(data1);
                    }
                });
                finaltableNamesArray.push(tableObj);
            });
            console.log(finaltableNamesArray);
            ReactDOM.render(
                <SingleSavedReportFilterTemplate reportId={this.state.reportId} filterfields={finaltableNamesArray} hierarchy={"true"} customfield={parsedCustomFields} />,
                document.querySelector(this.state.el)
            );
        } else {
            ReactDOM.render(
                <SingleSavedReportFilterTemplate onSearch={this.singleSavedFiltersSearch} reportId={this.state.reportId} filterfields={finalCustomFieldArray} hierarchy={"false"} customfield={parsedCustomFields} />,
                document.querySelector(this.state.el)
            );
        }
        return (<></>);
    }
    singleSavedFiltersSearch = (e) => {
        var $this = e.currentTarget;
        var valThis = $($this).val().toLowerCase();
        $("input:checkbox[class='singlereportfiltercheckbox']").parents(".boc-list-item").each(function () {
            var text = $(this).text().toLowerCase();
            (text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
        });
    }
    addContent(bool, isCustomParameter) {
        $(".modal.fade.singlesavedreportmodal").css("opacity", "");
        var viewreportfilterview = new ViewReportFilterView({
            filterArray: this.state.filterArray,
            features: this.state.features,
            schedule: this.props.schedule,
            isCustomParameter: isCustomParameter
        });
        var parsedSharedfilters;
        if (this.state.sharedFilters != undefined && this.state.sharedFilters.length > 2) {
            parsedSharedfilters = JSON.parse(this.state.sharedFilters);
        }
        var initialfiterCount = ChartInfo.functions.getFilters();
        var that = this;
        $(".showbutton").trigger("click");
        $(".singlereportfiltercheckbox").each(function (element) {
            var $this = this;
            if ($($this).is(":checked")) {
                var tableName = $($this).attr("data-parent");
                var columnDisplayName = $($this).parent().text().trim();
                var filterDisplayName = $($this).parent().text().trim();
                var filterIgnore = false;
                var columnName = $($this).attr("data-child");
                var dataType = $(this).attr("data-type");
                var daterange = $(this).attr("date-range");
                var existingObj = that.isfilterAlreadyexists(parsedSharedfilters, columnName);
                if (existingObj != undefined && Object.keys(existingObj).length > 0) {
                    var tempArray = [];
                    var dataType = "";
                    var UsersFilterArray = [];
                    var filterObjColumnName = "";
                    var toRange = "";
                    var fromRange = "";
                    tableName = existingObj.tablename;
                    columnDisplayName = existingObj.columndisplayname;
                    filterDisplayName = existingObj.filterDisplayName;
                    filterIgnore = existingObj.filterIgnore;
                    columnName = existingObj.columnname
                    dataType = existingObj.dataType
                    fromRange = existingObj.fromRange
                    toRange = existingObj.toRange
                    UsersFilterArray = tempArray.push(existingObj);
                    daterange = existingObj.daterange
                    viewreportfilterview.addfilteritem(filterDisplayName, filterIgnore, tableName, columnName, columnDisplayName, dataType, fromRange, toRange, UsersFilterArray, that.props.datamodelId, "", daterange, undefined, undefined, undefined, that.props.sharedFilters);
                } else {
                    //viewreportfilterview.addfilteritem(tableName, columnName, columnDisplayName, dataType,that.props.filterObject,that.props.datamodelId,"" ,daterange,undefined, undefined,undefined,undefined,undefined, that.props.sharedFilters);	
                    viewreportfilterview.addfilteritem(filterDisplayName, filterIgnore, tableName, columnName, columnDisplayName, dataType, fromRange, toRange, "", that.props.datamodelId, "", daterange, undefined, undefined, undefined, that.props.sharedFilters);

                }
            }
            else {
                var tableName = $($this).attr("data-parent");
                var columnDisplayName = $($this).attr("name").replace(/([-;%?&,.+*~\':"!^$[\]()<=>|\/@])/g, "\\$1");;
                $('#' + columnDisplayName).remove();
            }

        });
        var that2 = this;
        $(".singlereportcustomparameterfiltercheckbox").each(function (element) {
            var $this = this;
            if ($($this).is(":checked")) {
                var tableName = '';
                var columnDisplayName = $($this).attr("name");
                var filterDisplayName = $("input.filter-display-name[data-name='" + $($this).attr("name") + "']").val();
                var filterIgnore = false;
                var columnName = $($this).attr("name");
                var valuetype = $(this).attr("data-valuetype");
                var value = $(this).attr("data-value");
                viewreportfilterview.addfilteritem(filterDisplayName, filterIgnore, tableName, columnName, columnDisplayName, valuetype, "", "", "", that2.props.datamodelId, "", value, undefined, undefined, undefined, that2.props.sharedFilters);
            }
            else {
                var tableName = $($this).attr("data-parent");
                var columnDisplayName = $($this).attr("name").replace(/([;?&,%#.+*~\':"!^$[\]()<=>|\/@])/g, "\\$1");
                $('#' + columnDisplayName).remove();
            }

        });
        if (this.state.filterArray != undefined) {
            var col_name = "";
            var filtervalues = "";
            var isSharedReport = false;
            var that = this;
            if (window.location.pathname.indexOf("sharereport") >= 0) {
                isSharedReport = true;
            }

            if (isSharedReport == true) {
                var sharedFilters1 = JSON.parse(this.state.sharedFilters);
                var totalfilterArray = $.merge($.merge([], []), this.state.filterArray);
                _.each(totalfilterArray, function (filter1, index1) {
                    _.each(sharedFilters1, function (filter, index) {
                        var sharedfiltercolumndisplayname = filter.columndisplayname.replace(/([;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "");
                        if (filter1 != undefined) {
                            if (filter1.columndisplayname == sharedfiltercolumndisplayname) {
                                if (filter.dataType != undefined) {
                                    //var filtercolumndisplayname = filter.columndisplayname.replace(/([;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g,"");
                                    if (filter.dataType == "number") {
                                        var fromRange = filter.fromRange,
                                            toRange = filter.toRange,
                                            customRange = filter.customfiltervalue;
                                        var mainfromRange = filter1.fromRange,
                                            maintoRange = filter1.toRange,
                                            maincustomRange = filter1.customfiltervalue;
                                        if (fromRange == undefined && toRange == undefined) {
                                            fromRange = filter.filtergreaterthanvalue;
                                            toRange = filter.filterlessthanvalue;
                                        }
                                        if (fromRange > maintoRange || mainfromRange > toRange) {
                                            //delete reportFilterObject[index1];
                                            //reportFilterObject.pop();
                                            //reportFilterObject.slice();
                                            //reportFilterObject=reportFilterObject.slice(index1,1);
                                            if (this.state.filterArray.length != totalfilterArray.length) {
                                                this.state.filterArray.splice(index1 - 1, 1);
                                            }
                                            else {
                                                this.state.filterArray.splice(index1, 1);
                                            }
                                            //accessa.push(reportFilterObject[index1]);

                                        }
                                    }

                                    else if (filter.dataType == "date") {
                                        var fromDateRange = filter.fromRange,
                                            toDateRange = filter.toRange,
                                            dateRange = filter.daterange;
                                        var mainfromRange = filter1.fromRange,
                                            maintoRange = filter1.toRange,
                                            maindateRange = filter1.daterange;
                                        var dates = $('.daterange-center[displayname="' + filter.columndisplayname + '"] span').text();
                                        var sharedFrom = Date.parse(fromDateRange);
                                        var mainTo = Date.parse(maintoRange);
                                        var sharedTo = Date.parse(toDateRange);
                                        var mainFrom = Date.parse(mainfromRange);
                                        if (sharedFrom > mainTo || mainFrom > sharedTo) {
                                            if (this.state.filterArray.length != totalfilterArray.length) {
                                                this.state.filterArray.splice(index1 - 1, 2);
                                            }
                                            else {
                                                this.state.filterArray.splice(index1, 2);
                                            }																				//accessa.push(reportFilterObject[index1]);
                                        }
                                        //if(customRange== undefined || customRange===""){
                                        //$('.filteritem-container .acc-content[data-id="'+filtercolumndisplayname+'"] .filteredvalues').text(dates).attr("title",dates);
                                        //}
                                    }

                                    if (filter.dataType == "string") {
                                        var sharedYes = false;
                                        if (filter.customfield == undefined) {
                                            var filtereddatatext = filter["data"].replace(/\@@/g, ', ');
                                            var newfiltereddatatext = filtereddatatext.split(",");
                                            var filtereddatatext1 = filter1["data"].replace(/\@@/g, ', ');
                                            var newfiltereddatatext1 = filtereddatatext1.split(",");

                                            _.each(newfiltereddatatext, function (shared, i) {
                                                var filtereddatatext1 = filter1["data"].replace(/\@@/g, ', ');
                                                if (filtereddatatext1.includes(shared)) {
                                                    sharedYes = true
                                                    //accessa.push(reportFilterObject[index1]);
                                                }
                                            });
                                            if (sharedYes == false) {
                                                //	var rfobject=reportFilterObject.splice(index1,1,reportFilterObject[index1]);
                                                if (that.state.filterArray.length != totalfilterArray.length) {
                                                    that.state.filterArray.splice(index1 - 1, 1);
                                                }
                                                else {
                                                    that.state.filterArray.splice(index1, 1);
                                                }
                                                //reportFilterObject=$.merge($.merge([], accessa),reportFilterObject.splice(index1,1))
                                            }
                                        } else {
                                            var filtereddatatext = filter.customfield.sqlcondition.sqldata.replace(/\@@/g, ', ');
                                            var newfiltereddatatext = filtereddatatext.split(",");
                                            var filtereddatatext1 = filter1.customfield.sqlcondition.sqldata.replace(/\@@/g, ', ');
                                            var newfiltereddatatext1 = filtereddatatext1.split(",");
                                        }
                                    }

                                }
                            }
                        }
                    });
                });
            }
            _.each(this.state.filterArray, function (filter) {
                if (col_name === filter["tablename"] + "." + filter["columnname"]) {

                }
                else {
                    col_name = filter["tablename"] + "." + filter["columnname"];
                    viewreportfilterview.addfilteritem(filter["filterDisplayName"], filter["filterIgnore"], filter["tablename"], filter["columnname"], filter["columndisplayname"], filter["dataType"], filter['fromRange'], filter['toRange'], that.props.filterObject, that.props.datamodelId, "", filter["daterange"], undefined, undefined, undefined, that.props.sharedFilters);

                    filtervalues = filtervalues + filter['columndisplayname'].replace(/([#;?&,%.+*~\':"!^$%[\]()<=>`|\/@])/g, "") + ",";
                }


            });
            if (bool === 0) {
                sessionStorage.setItem("applicablefilters", filtervalues);
            }
            $(".filtercontent").append(viewreportfilterview.el);
        }
        if (this.state.customparameterfileterArray != undefined) {
            var col_name = "";
            var filtervalues = "";
            _.each(this.state.customparameterfileterArray, function (filter) {
                if (col_name === filter["tablename"] + "." + filter["columnname"]) {

                }
                else {
                    col_name = filter.parametername;
                    viewreportfilterview.addfilteritem(filter["filterDisplayName"], filter["filterIgnore"], filter["tablename"], filter.parametername, filter.parametername, filter.valueType, filter['fromRange'], filter['toRange'], that.props.filterObject, that.props.datamodelId, "", filter.parametercurrentvalue, undefined, undefined, undefined, that.props.sharedFilters);
                    filtervalues = filtervalues + filter['columndisplayname'] + ",";
                }


            });
            $(".filtercontent").append(viewreportfilterview.el);
        }
        $('.isexcludenulls').prop('checked', this.props.isExcludeNulls);
        var finalfiterCount = ChartInfo.functions.getFilters();
        if (initialfiterCount != undefined && initialfiterCount.length > 0 && finalfiterCount != undefined && finalfiterCount.length == 0) {
            ChartInfo.functions.setIsFiltersAdded(false);
            sessionStorage.setItem("changesdone", 1);
            $('.trend-stats-container').remove();
            $('.addInstanceFilterData').empty();
            if ($('.acc-content').length == 0 && $('.addInstanceFilterData').text().trim().length == 0) {
                if ($('.addURLInstanceFilterData').text().trim().length == 0) {
                    $('.instant-floating-filters').hide();
                    $('.allscopeslistcontainer').hide();
                }
            }
            sessionStorage.removeItem("instantExcludeFilters");
            sessionStorage.removeItem("instantIncludeFilters");
            sessionStorage.removeItem("instantExcludeCustomFilters");
            ChartInfo.functions.setInstanceIncludeFilterData("");
            ChartInfo.functions.setInstanceFilterData("");
            ChartInfo.functions.setIncludeFilterData("");
            ChartInfo.functions.setdrillfitlerArray("");
            $('.refreshsinglecharts').trigger('click');
            ChartInfo.functions.getFilters(true);
            PubSub.publish('applyfiltesandredrawchart');
        }
    }
}