import _ from 'lodash';
import React from "react";
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import ReactDOM from "react-dom";
import { getMessage } from '../js/helpers/utils/BirdMessage';
import PubSub from 'pubsub-js'
import YAxisAttrView from './yaxisattributesview'
import ZAxisAttrView from "./zaxisattributesview"
import XAxisAttrView from "./xaxisattributesview"
import YAxisDropDownView from './yaxisdropdownview'
import CombinationChartsView from "./combinationchartsview"
import XAxisDropDownView from "./xaxisdropdownview"
import ZAxisDropDownView from "./zaxisdropdownview"
import LegendAttrView from "./legendseriesattributesview"
import LegendDropDownView from "./legenddropdownview"
import tooltipAttrView from "./tooltipattributesview"
import tooltipDropDownView from "./tooltipdropdownview"
import MetricDateFieldDropDownView from "./metricdatefielddropdown"
import MetricDateFieldAttrView from "./metricdatefieldattributesview"
import AttrAxisContentModalTemplate from "./attributesaxiscontent.jsx"
import CustomSolidGaugeDropDownView from "./customsolidgaugedropdownview"
import CustomSolidGaugeAttributeView from "./customsolidgaugeattributeview";
import { lang } from "../js/helpers/utils/languages/language";
import * as ModalProperties from "../js/helpers/utils/modalproperties";
import DrilldownHierarchyModal from "./drilldownhierarchymodal"
import CustomRangeView from "./createRange"
import customaggregatedfieldView from './createcustommeasuremodel'
import CustomMeasureHierarchyModal from './createcustommeasurehierarchymodalview'
const $ = window.$;

export default class AttrModalView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            measures: props.measures,
            dimensions: props.dimensions,
            chartnumber: props.chartnumber,
            features: props.features,
            metrictype: props.metricdatetype,
            reportId: props.reportId,
            multifact: props.multifact,
            facttables: props.facttables,
            multifactentities: props.multifactentities,
            is_storyboard: props.is_storyboard,
            update: false
        };
        this.render();
        this.unbindEvents();
       var dropdownyaxisEve = PubSub.subscribe('dropdownyaxis', this.showyAxisDropDown.bind(this));
        this.showXAxisDropDownEve = PubSub.subscribe('dropdownxaxis', this.showXAxisDropDown.bind(this));
        this.showzAxisDropDownEve = PubSub.subscribe('dropdownzaxis', this.showzAxisDropDown.bind(this));
        this.legenddropdownEve = PubSub.subscribe('legenddropdown', this.showLegendSeriesDropDown.bind(this));
        this.dropdowntooltipEve = PubSub.subscribe('dropdowntooltip', this.showtooltipDropDown.bind(this));
        this.dropdownreferencelineEve = PubSub.subscribe('dropdownreferenceline', this.showReferenceLineDropDown.bind(this));
        this.dropdownmetricdatefieldEve = PubSub.subscribe('dropdownmetricdatefield', this.showMetricDateFieldDropDown.bind(this));
        this.dropdowncustomsolidgaugeEve = PubSub.subscribe('dropdowncustomsolidgauge', this.showCustomSolidGaugeDropDown.bind(this));
        this.showyAxisDropDowninSortingEve = PubSub.subscribe('showyAxisDropDowninSorting', this.showyAxisDropDowninSorting.bind(this));
        this.createCustomFiledEvee = PubSub.subscribe('createCustomFiled', this.createCustomFiledEve.bind(this));
        this.createDillDownHierarchyEve = PubSub.subscribe('createDillDownHierarchy', this.createDillDownHierarchyEvee.bind(this));
        this.createrangeEvee = PubSub.subscribe('createrange', this.createrangeEve.bind(this));
        this.createcustommeasureEvee = PubSub.subscribe('createcustommeasure', this.createcustommeasureEve.bind(this));
        this.createcustomhierarchymeasureEvee = PubSub.subscribe('createcustomhierarchymeasure', this.createcustomhierarchymeasureEve.bind(this));
        $('.yaxisattributes').sortable();
        $('.xaxisattributes').sortable();
    }
    render() {
        var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(this.props.chartnumber));
        var chartSettings = chartDetails['chartSettings'];
        var themeIndex = this.props.colorThemeIndex;
        if (chartDetails.colorThemeIndex != undefined) {
            themeIndex = chartDetails.colorThemeIndex;
        }
        if (themeIndex == undefined || themeIndex == "") {
            themeIndex = 0;
        }
        if (chartSettings == undefined) {
            if (chartDetails.chartType == "pie" || chartDetails.chartType == "3dpie" || chartDetails.chartType == "semicircle" || chartDetails.chartType == "donut") {
                chartSettings = { 'chartTitle': true, 'legend': false, 'xAxisLabel': true, 'yAxisLabel': true, 'dataLabel': false, 'crossHairs': false, 'gridLine': true, 'referenceLine': false };
            }
            else {
                chartSettings = { 'chartTitle': true, 'legend': true, 'xAxisLabel': true, 'yAxisLabel': true, 'dataLabel': false, 'crossHairs': false, 'gridLine': true, 'referenceLine': false };
            }
        }

        ReactDOM.render(<AttrAxisContentModalTemplate chartnumber={this.state.chartnumber} colorThemeIndex={themeIndex} colorThemes={ChartInfo.functions.getColorThemes()} chartSettings={chartSettings} measures={this.state.measures} dimensions={this.state.dimensions} metrictype={this.state.metrictype} isDirect={ChartInfo.functions.getIsDirect()}></AttrAxisContentModalTemplate>, document.getElementById('axissetting'));
        // $('.axissetting').html(this.template({ chartnumber: this.state.chartnumber, colorThemeIndex: themeIndex, colorThemes: ChartInfo.functions.getColorThemes(), chartSettings: chartSettings, measures: this.state.measures, dimensions: this.state.dimensions, metrictype: this.state.metrictype, isDirect: ChartInfo.functions.getIsDirect() }));

        //Update Selected Theme
        var newtheme = ChartInfo.functions.getColorTheme(themeIndex);
        var colorlist = $('.chartthemeli .color-container>.colors');
        _.each(colorlist, function (i, j) {
            $(i).css("background-color", newtheme[j])
        })
        //update Zoom Settings
        if (chartSettings != undefined && chartSettings.chartZoomSettings != undefined) {
            $("#chartZoomSettings").val(chartSettings.chartZoomSettings);
        }
        //For Abbrevations
        if (chartSettings != undefined && chartSettings.numberformat != undefined) {
            if (chartSettings.numberformat.abbrevation != undefined) {
                if (chartSettings.numberformat.abbrevation.indexOf("T") > 0) {
                    $("#abbrevationT").val(chartSettings.numberformat.abbrevationT).prop("checked", true);
                }
                if (chartSettings.numberformat.abbrevation.indexOf("B") > 0) {
                    $("#abbrevationB").val(chartSettings.numberformat.abbrevationB).prop("checked", true);
                }
                if (chartSettings.numberformat.abbrevation.indexOf("M") > 0) {
                    $("#abbrevationM").val(chartSettings.numberformat.abbrevationM).prop("checked", true);
                }
                if (chartSettings.numberformat.abbrevation.indexOf("K") > 0) {
                    $("#abbrevationK").val(chartSettings.numberformat.abbrevationK).prop("checked", true);
                }
            }
            //For Decimals
            if (chartSettings.numberformat.decimals == "0_1") {
                $("#decimals0").val(chartSettings.numberformat.decimals).prop("checked", true);
            } else if (chartSettings.numberformat.decimals == "0_2" || chartSettings.numberformat.decimals == undefined) {
                $("#decimals00").val(chartSettings.numberformat.decimals).prop("checked", true);
            } else {
                $("#decimalcustomvalue").val(chartSettings.numberformat.decimals)
                $("#decimalsC").val(chartSettings.numberformat.decimals).prop("checked", true);
            }

            //For Seperators
            if (chartSettings.numberformat.seperator == "thousands1") {
                $("#thousands1").val(chartSettings.numberformat.seperator).prop("checked", true);
            } else if (chartSettings.numberformat.seperator == "thousands2") {
                $("#thousands2").val(chartSettings.numberformat.seperator).prop("checked", true);
            }
        }

        //update yAxis scale values
        if (chartSettings != undefined && chartSettings.yAxis != undefined) {
            if (chartSettings.yAxis.min != "") {
                $("#yaxisscaleminvalue").val(chartSettings.yAxis.min);
            }
            if (chartSettings.yAxis.max != "") {
                $("#yaxisscalemaxvalue").val(chartSettings.yAxis.max);
            }
        }

        var data = JSON.parse(ChartInfo.functions.getChartDetails(this.props.chartnumber));
        var referencelineobj = ChartInfo.functions.getreferenceLineValues(this.state.chartnumber);
        var xaxisData = data["xAxis"];
        var yAxisData = "";
        var zAxisData = "";
        var customSolidGaugeData = "";
        var aggregation = "";
        var legendData = data["legend"];
        var metricData = data["metricdatefield"];
        var metricDataDisplay = data["metricdatefielddisplay"];
        var metricdatetype = data["metricdatetype"];
        var metricprefix = data["metricprefix"];
        var metricsuffix = data["metricsuffix"];
        var metricfont = data["metricfont"];
        var solidgaugemin = data["solidgaugemin"];
        var solidgaugemax = data["solidgaugemax"];
        var displayNameMap = {};
        var dataTypeMap = {};
        var tableNameMap = {};
        var reference = {};
        var chartType = data["chartType"];

        /*Reference Line*/
        if (referencelineobj != undefined) {
            var that = this;
            _.each(referencelineobj, function (val, index) {
                var today = new Date();
                var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                $('.axissetting').append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                if (referencelineobj[index] != undefined && (referencelineobj[index].referenceValue != undefined || referencelineobj[index].referenceLineagg != undefined)) {
                    var yAxisAttrView = new YAxisAttrView({
                        axisduplicate: that.axisduplicate,
                        dynamicdiv: dynamicdiv,
                        yaxiscolumnDisplayName: val.referenceName,
                        yaxiscolumnName: val.referenceName,
                        yaxiscolumnType: val.isCustomLine ? 'CustomLine' : 'string',
                        referencelinevalue: val.referenceValue,
                        measures: that.state.measures,
                        addreferencelinehtml: true,
                        id: index,
                        chartnumber: that.state.chartnumber,
                        referenceColumnName: val.columnname,
                        referenceLinelabel: val.referenceLabel,
                        referenceStyle: val.referenceStyle,
                        yaxisaggregation: "",
                        referenceLineagg: val.referenceLineagg,
                        referenceColor: val.referenceColor,
                        reportId: that.state.reportId,
                        multifact: that.state.multifact,
                        facttables: that.state.facttables,
                        multifactentities: that.state.multifactentities,
                        is_storyboard: that.state.is_storyboard,
                    });
                }
                $('.axissetting').find(".referenceattributes").append($('#' + dynamicdiv));
                $(".singleliitems[id=" + index + "] .agg").find('option[value=' + val.referenceLineagg + ']').prop("selected", true);
                $(".singleliitems[id=" + index + "] .referencelinestyle").find('option[value=' + val.referenceStyle + ']').prop("selected", true);
                $(".singleliitems[id=" + index + "] .reference-color input").val(val.referenceColor);
                $($('#' + dynamicdiv)).find('.reference-color').colorpicker({ format: "rgba" }).on('changeColor.colorpicker', function (event) {
                    var $this = event.currentTarget;
                    var currentValue = $($this).find('input').val();
                    $($this).find(".input-group-addon>i").css("background-color", currentValue);
                });
                $(document).on('click', '.colorpicker', function (e) {
                    e.stopPropagation();
                });
            });
        }
        /*Reference Line*/

        _.each(data["yAxis"], function (val) {
            yAxisData += val.columnName + ",";
            aggregation += val.aggregation + ",";
        });
        _.each(data["zAxis"], function (val) {
            zAxisData += val.columnName + ",";
            aggregation += val.aggregation + ",";
        });
        _.each(data["customSolidGauge"], function (val) {
            customSolidGaugeData += val.columnName + ",";
            aggregation += val.aggregation + ",";
        });
        yAxisData = yAxisData.substring(0, yAxisData.length - 1);
        zAxisData = zAxisData.substring(0, zAxisData.length - 1);
        customSolidGaugeData = customSolidGaugeData.substring(0, customSolidGaugeData.length - 1);
        aggregation = aggregation.substring(0, aggregation.length - 1);
        var that = this;
        var iscustomfunction = 0;
        var customFields = sessionStorage.getItem("customFields");
        var currentdatano = $('.yaxisdropdownlist').last().attr('data-num-yaxis');
        _.each(data["yAxis"], function (val) {
            iscustomfunction = 0;
            if (currentdatano === undefined) {
                currentdatano = 0;
            } else {
                currentdatano = parseInt(currentdatano) + 1;
            }
            if (val.uniqueid != undefined) {
                currentdatano = val.uniqueid;
            }
            if (customFields != undefined && customFields != null) {
                var tempcustomObject = JSON.parse(customFields);
                _.each(tempcustomObject, function (customfield) {
                    if (customfield.name === val.displayName) {
                        iscustomfunction = 1;
                    }
                });
            }
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $('.axissetting').append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            var yAxisAttrView = new YAxisAttrView({
                axisduplicate: that.axisduplicate,
                dynamicdiv: dynamicdiv,
                broker: that.broker,
                yaxiscolumnDisplayName: val.displayName,
                yaxiscolumnName: val.columnName,
                yaxiscolumnType: val.dataType,
                yaxisaggregation: val.aggregation,
                yaxistableName: val.tableName,
                measures: that.state.measures,
                dimensions: that.state.dimensions,
                percentileValue: val.percentileValue,
                viewmode: 'values',
                features: that.state.features,
                reportId: that.state.reportId,
                chartnumber: that.state.chartnumber,
                id: currentdatano,
                iscustomfunction: iscustomfunction,
                multifact: that.state.multifact,
                facttables: that.state.facttables,
                multifactentities: that.state.multifactentities,
                is_storyboard: that.state.is_storyboard,
            });

            $('.axissetting').find(".yaxisattributes").append($("#" + dynamicdiv));

            //Add Conditional Format Data
            var yaxisconditionalname = val.displayName
            var dataagg = val.aggregation
            var yaxisdatanum = $('.yaxisdropdownlist').last().attr('data-num-yaxis');

            var conditionFormateArrayAxis = ChartInfo.functions.getConditionalFormatData(that.state.chartnumber);
            if (conditionFormateArrayAxis != undefined && conditionFormateArrayAxis.length != 0) {
                conditionFormateArrayAxis = $.grep(conditionFormateArrayAxis, function (e) {
                    //return e.name +e.agg +e.dataNumYAxis == yaxisconditionalname+dataagg+yaxisdatanum 
                    return e.name == yaxisconditionalname
                });

                _.each(conditionFormateArrayAxis, function (conditionFormateArray) {
                    //$(".attrabutesformatting[data-name='"+yaxisconditionalname+"']").removeClass("hide");
                    //var templet=$(".attrabutesformatting[data-name='"+yaxisconditionalname+"']").find(".conditionalformat1[data-name='"+yaxisconditionalname+"']");
                    var templet = $(".yaxisattributes .attrabutesformatting[data-name='" + yaxisconditionalname + "'][data-num-yaxis='" + yaxisdatanum + "']").find(".conditionalformat1[data-name='" + yaxisconditionalname + "']");

                    if (conditionFormateArray.name == yaxisconditionalname) {
                        _.each(conditionFormateArray.conditions, function (val) {
                            var condtionformaterobj = val;
                            if (condtionformaterobj["range"] == "<") {
                                templet.append("<div class='conditionalformat' data-name='" + yaxisconditionalname + "'><select class='modal-form-fields conditionalformstselect'>" +
                                    "<option value='smaller' selected><</option><option value='greater'>></option></select><input type='number'  value='" + condtionformaterobj["value"] + "' class='form-control conditionalfx' placeholder='0' title="+lang["storyboard.enterval"]+"/>" +
                                    "<span class='conditionalformattheme'><span class='input-group'><input type='text' placeholder="+lang["storyboard.placeholdheader"]+" value='" + condtionformaterobj["color"] + "' class='form-control colorcodenumber'/>" +
                                    "<span class='input-group-addon formattheme'></span></span></span><button class='btn btn-transparent deletecondition' data-name=" + yaxisconditionalname + " type='button' title="+lang["storyboard.remove"]+">" +
                                    "<i class='fa fa-trash-o'></i></button></div>");
                                $('.conditionalformattheme>span.input-group').colorpicker();
                            }
                            else {
                                templet.append("<div class='conditionalformat' data-name='" + yaxisconditionalname + "'><select class='modal-form-fields conditionalformstselect'>" +
                                    "<option value='smaller'><</option><option value='greater' selected>></option></select><input type='number'  value='" + condtionformaterobj["value"] + "' class='form-control conditionalfx' placeholder='0' title="+lang["storyboard.enterval"]+"/>" +
                                    "<span class='conditionalformattheme'><span class='input-group'><input type='text' placeholder="+lang["storyboard.placeholdheader"]+" value='" + condtionformaterobj["color"] + "' class='form-control colorcodenumber'/>" +
                                    "<span class='input-group-addon formattheme'></span></span></span><button class='btn btn-transparent deletecondition' data-name=" + yaxisconditionalname + " type='button' title="+lang["storyboard.remove"]+">" +
                                    "<i class='fa fa-trash-o'></i></button></div>");
                                $('.conditionalformattheme>span.input-group').colorpicker();
                            }
                        });
                    }
                });
            }

        });

        var currentdatano = $('.combinationchartslist').last().attr('data-num-yaxis');
        _.each(data["combinationchartslist"], function (val) {
            var charttype;
            if (val.selectedChartType == "column") {
                charttype = "bar";
            }
            var chartaxisposintions;
            if (val.chartaxisposintion == undefined) {
                chartaxisposintions = "left";
            }
            else {
                chartaxisposintions = val.chartaxisposintion
            }
            if (currentdatano === undefined) {
                currentdatano = 0;
            } else {
                currentdatano = parseInt(currentdatano) + 1;
            }
            if (val.uniqueid != undefined) {
                currentdatano = val.uniqueid;
            }
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $('.axissetting').append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            var combinationChartsView = new CombinationChartsView({
                dynamicdiv: dynamicdiv,
                yaxiscolumnDisplayName: val.displayName,
                yaxiscolumnName: val.columnName,
                yaxiscolumnType: val.dataType,
                yaxisaggregation: val.chartType,
                charttype: charttype,
                yaxistableName: val.tableName,
                selectedChartType: val.selectedChartType,
                chartaxisposintion: chartaxisposintions,
                aggregationType: val.aggregation,
                id: currentdatano,
                facttables: that.state.facttables,
                multifact: that.state.multifact,
                multifactentities: that.state.multifactentities,
            });

            $('.axissetting').find(".combinationchartslistattributes").append($("#" + dynamicdiv));
        });

        _.each(data["yAxis"], function (value) {
            if (value.dataType != "customaggregatedfield") {
                if (data["rankingColumn"] == value.columnName) {
                    $(".rankingitem").append('<option data-name="' + value.columnName + '" data-agg="' + value.aggregation + '"data-type="' + value.dataType + '" title="' + value.displayName + '" selected="selected">' + value.displayName + ' (' + value.aggregation.replace(/[^A-Z0-9]/ig, "") + ') </option>');
                } else {
                    $(".rankingitem").append('<option data-name="' + value.columnName + '" data-agg="' + value.aggregation + '"data-type="' + value.dataType + '" title="' + value.displayName + '">' + value.displayName + ' (' + value.aggregation.replace(/[^A-Z0-9]/ig, "") + ') </option>');
                }
            } else {
                if (data["rankingColumn"] == value.columnName) {
                    $(".rankingitem").append('<option data-name="' + value.columnName + '" data-agg="' + value.aggregation + '"data-type="' + value.dataType + '" title="' + value.displayName + '" selected="selected">' + value.displayName + '' + value.aggregation.replace(/[^A-Z0-9]/ig, "") + '</option>');
                } else {
                    $(".rankingitem").append('<option data-name="' + value.columnName + '" data-agg="' + value.aggregation + '"data-type="' + value.dataType + '" title="' + value.displayName + '">' + value.displayName + '' + value.aggregation.replace(/[^A-Z0-9]/ig, "") + '</option>');
                }

            }
        });
        _.each(data["zAxis"], function (val) {
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $('.axissetting').append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            var zAxisAttrView = new ZAxisAttrView({
                dynamicdiv: dynamicdiv,
                yaxiscolumnDisplayName: val.displayName,
                yaxiscolumnName: val.columnName,
                yaxiscolumnType: val.dataType,
                yaxisaggregation: val.aggregation,
                percentileValue: val.percentileValue,
                yaxistableName: val.tableName,
                isStoredProcedure: ChartInfo.functions.getIsStoredProcedure(),
                isDirect: ChartInfo.functions.getIsDirect(),
                multifact: that.state.multifact,
                facttables: that.state.facttables,
                multifactentities: that.state.multifactentities,
            });
            $('.axissetting').find(".zaxisattributes").append($("#" + dynamicdiv));
        });
        _.each(data["customSolidGauge"], function (val) {
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $(".customsolidgaugeattributes").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            var customSolidGaugeAttributeView = new CustomSolidGaugeAttributeView({
                dynamicdiv: dynamicdiv,
                columnDisplayName: val.displayName,
                columnName: val.columnName,
                columnType: val.dataType,
                aggregation: val.aggregation,
                tableName: val.tableName,
                multifact: that.state.multifact,
                facttables: that.state.facttables,
                multifactentities: that.state.multifactentities,
            });
            $('.axissetting').find(".customsolidgaugeattributes").append($("#" + dynamicdiv));
        });

        _.each(data["tooltip"], function (val) {
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $('.axissetting').append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            var TooltipAttrView = new tooltipAttrView({
                dynamicdiv: dynamicdiv,
                yaxiscolumnDisplayName: val.displayName,
                yaxiscolumnName: val.columnName,
                yaxiscolumnType: val.dataType,
                yaxisaggregation: val.aggregation,
                yaxistableName: val.tableName,
                multifact: that.state.multifact,
                facttables: that.state.facttables,
                multifactentities: that.state.multifactentities,
            });
            $('.axissetting').find(".tooltipattributes").append($("#" + dynamicdiv));
        });

        _.each(this.state.measures, function (value) {
            // value = value.toJSON();
            _.each(data["yAxis"], function (value1) {
                if (value.columnName == value1.columnName) {
                    value['aggregation'] = value1.aggregation;
                }
            });
            displayNameMap[value.columnName] = value.columnDisplayName;
            dataTypeMap[value.columnName] = value.tableDisplayType;
            tableNameMap[value.columnName] = value.tableName;
            /*if(data["sortingColumn"]==value.columnName){
                 $('.sortingitem option[data-name="'+value.columnName+'"]').attr("selected","selected");
                //$(".sortingitem").attr('<option data-name="'+value.columnName+'" data-agg="'+value.aggregation+'"data-type="'+value.tableDisplayType+'" title="'+value.columnDisplayName+'" selected="selected">'+value.columnDisplayName+' ('+value.aggregation.replace(/[^A-Z0-9]/ig, "")+') </option>');
            }*/
        });

        _.each(xaxisData, function (xaxisData) {
            var customfield = 0
            if (sessionStorage.getItem("customFields") != undefined || sessionStorage.getItem("customFields") != null) {
                var customFieldList = JSON.parse(sessionStorage.getItem("customFields"));
                _.each(customFieldList, function (customField, i) {
                    if (xaxisData.columnName == customField.name) {
                        customfield = 1;
                    }
                });
            }
            if (xaxisData.dataType == "customhierarchy") {
                customfield = 2
            } else if (xaxisData.dataType == "customrange") {
                customfield = 3
            }
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $('.axissetting').append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            var xaxisAttrView = new XAxisAttrView({
                deletecustomfield: that.props.deletecustomfield,
                dynamicdiv: dynamicdiv,
                xaxiscolumnDisplayName: xaxisData.displayName,
                xaxiscolumnName: xaxisData.columnName,
                xaxiscolumnType: xaxisData.dataType,
                xaxistableName: xaxisData.tableName,
                xaxisidentity: xaxisData.xaxisidentity,
                activeChartType: chartType,
                viewmode: 'values',
                iscustomfunction: customfield,
                measures: that.state.measures,
                dimensions: that.state.dimensions,
                chartnumber: that.state.chartnumber,
                multifact: that.state.multifact,
                facttables: that.state.facttables,
                multifactentities: that.state.multifactentities,
                features: that.state.features,
                is_storyboard: that.state.is_storyboard,
                enableDrillThroughHiddenFilterOption:xaxisData.isHiddenMultixDrillthroughFilter
            });
            $('.axissetting').find(".xaxisattributes").append($("#" + dynamicdiv));
        });
        if (legendData != undefined && legendData.toString().length > 0) {
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $(".legendAttributes").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            var legendattributeview = new LegendAttrView({
                dynamicdiv: dynamicdiv,
                columnDisplayName: legendData.displayName,
                columnName: legendData.columnName,
                columnType: legendData.dataType,
                tableName: legendData.tableName,
                multifact: that.state.multifact,
                facttables: that.state.facttables,
                multifactentities: that.state.multifactentities,
            });
            $(".legendAttributes").append($("#" + dynamicdiv));
        }

        if (metricData != undefined && metricData.toString().length > 0) {
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $(".metricdatefieldsAttributes").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            var metricdatefieldattrview = new MetricDateFieldAttrView({
                dynamicdiv: dynamicdiv,
                columnName: metricData,
                columnDisplayName: metricDataDisplay,
                columnType: metricdatetype,
                viewmode: 'values',
                multifact: that.state.multifact,
                facttables: that.state.facttables,
                multifactentities: that.state.multifactentities,
            });
            $(".metricdatefieldsAttributes").append($("#" + dynamicdiv));
        }

        if (metricdatetype != undefined || metricdatetype != "") {
            $("#metricprefix").val(metricprefix);
            $("#metricsuffix").val(metricsuffix);
            if (metricfont != undefined && metricfont != null && metricfont != "") {
                if (metricfont != undefined) {
                    $("#metricfontfamily").val(metricfont.fontfamily);
                    $("#metricdatafontsize").val(metricfont.fontsize);
                    $("#metricfontsizeparametertext").val(metricfont.fontsizeparameter);
                    if (metricfont.fontweight == "bold") { $("input.metricfontweight").prop("checked", true); $('label#metricboldtext').addClass('active') } else { $("input.metricfontweight").prop("checked", false); $('label#metricboldtext').removeClass('active') }
                    if (metricfont.fontitalic == "italic") { $("input.metricfontitalic").prop("checked", true); $('label#metricitalictext').addClass('active') } else { $("input.metricfontitalic").prop("checked", false); $('label#metricitalictext').removeClass('active') }
                    $("#metricfontcolor").val(metricfont.fontcolor);
                }
                if (metricfont != undefined && metricfont.prefixfont != undefined) {
                    $("#metricprefixfontfamily").val(metricfont.prefixfont.fontfamily);
                    $("#metricprefixdatafontsize").val(metricfont.prefixfont.fontsize);
                    $("#metricprefixfontsizeparametertext").val(metricfont.prefixfont.fontsizeparameter);
                    if (metricfont.prefixfont.fontweight == "bold") { $("input.metricprefixfontweight").prop("checked", true); $('label#metricprefixboldtext').addClass('active') } else { $("input.metricprefixfontweight").prop("checked", false); $('label#metricprefixboldtext').removeClass('active') }
                    if (metricfont.prefixfont.fontitalic == "italic") { $("input.metricprefixfontitalic").prop("checked", true); $('label#metricprefixitalictext').addClass('active') } else { $("input.metricprefixfontitalic").prop("checked", false); $('label#metricprefixitalictext').removeClass('active') }
                    $("#metricprefixfontcolor").val(metricfont.prefixfont.fontcolor);
                }
                if (metricfont != undefined && metricfont.suffixfont != undefined) {
                    $("#metricsuffixfontfamily").val(metricfont.suffixfont.fontfamily);
                    $("#metricsuffixdatafontsize").val(metricfont.suffixfont.fontsize);
                    $("#metricsuffixfontsizeparametertext").val(metricfont.suffixfont.fontsizeparameter);
                    if (metricfont.suffixfont.fontweight == "bold") { $("input.metricsuffixfontweight").prop("checked", true); $('label#metricsuffixboldtext').addClass('active') } else { $("input.metricsuffixfontweight").prop("checked", false); $('label#metricsuffixboldtext').removeClass('active') }
                    if (metricfont.suffixfont.fontitalic == "italic") { $("input.metricsuffixfontitalic").prop("checked", true); $('label#metricsuffixitalictext').addClass('active') } else { $("input.metricsuffixfontitalic").prop("checked", false); $('label#metricsuffixitalictext').removeClass('active') }
                    $("#metricsuffixfontcolor").val(metricfont.suffixfont.fontcolor);
                }
                if (metricfont.customcomparision != undefined && metricfont.customcomparision.targetvalue != undefined && metricfont.customcomparision.targetvalue != "") {
                    $("#metrictargetvalue").val(metricfont.customcomparision.targetvalue);
                }

                var metriclayout = "0";
                if (metricfont != null && metricfont != undefined && metricfont.layout != null && metricfont.layout != undefined) {
                    metriclayout = metricfont.layout;
                }
                $(".metric-layout").removeClass("active");
                $(".metric-layout[layout-id=" + metriclayout + "]").addClass("active");

                if (chartType == "metric" && metriclayout === "1") {
                    $('.metriclayout').addClass("hide");
                    $('.metriclayout[layout-id="1"]').removeClass("hide");
                    $('ul.metricdatefieldsAttributes').empty();
                    $('select.metriccoparision').val("All");
                } else {
                    $('.metriclayout').removeClass("hide");
                    $('.metriclayout[layout-id="1"]').addClass("hide");
                    $("#metrictargetvalue").val("");
                }
                //Metric Target
                if (metricfont != undefined && metricfont.customcomparision != undefined && metricfont.customcomparision.fontfamily != undefined && metricfont.customcomparision.fontsize != undefined && metricfont.customcomparision.fontsizeparameter != undefined && metricfont.customcomparision.fontweight != undefined && metricfont.customcomparision.fontitalic != undefined) {
                    $("#metrictargetfontfamily").val(metricfont.customcomparision.fontfamily);
                    $("#metrictargetdatafontsize").val(metricfont.customcomparision.fontsize);
                    $("#metrictargetfontsizeparametertext").val(metricfont.customcomparision.fontsizeparameter);
                    if (metricfont.customcomparision.fontweight == "bold") { $("input.metrictargetfontweight").prop("checked", true); $('label#metrictargetboldtext').addClass('active') } else { $("input.metrictargetfontweight").prop("checked", false); $('label#metrictargetboldtext').removeClass('active') }
                    if (metricfont.customcomparision.fontitalic == "italic") { $("input.metrictargetfontitalic").prop("checked", true); $('label#metrictargetitalictext').addClass('active') } else { $("input.metrictargetfontitalic").prop("checked", false); $('label#metrictargetitalictext').removeClass('active') }
                    $("#metrictargetfontcolor").val(metricfont.customcomparision.fontcolor);
                }
                if (metricfont != undefined && metricfont.customcomparision != undefined && metricfont.customcomparision.prefixfont != undefined) {
                    $("#metrictargetprefix").val(metricfont.customcomparision.prefixfont.value);
                    $("#metrictargetprefixfontfamily").val(metricfont.customcomparision.prefixfont.fontfamily);
                    $("#metrictargetprefixdatafontsize").val(metricfont.customcomparision.prefixfont.fontsize);
                    $("#metrictargetprefixfontsizeparametertext").val(metricfont.customcomparision.prefixfont.fontsizeparameter);
                    if (metricfont.customcomparision.prefixfont.fontweight == "bold") { $("input.metrictargetprefixfontweight").prop("checked", true); $('label#metrictargetprefixboldtext').addClass('active') } else { $("input.metrictargetprefixfontweight").prop("checked", false); $('label#metrictargetprefixboldtext').removeClass('active') }
                    if (metricfont.customcomparision.prefixfont.fontitalic == "italic") { $("input.metrictargetprefixfontitalic").prop("checked", true); $('label#metrictargetprefixitalictext').addClass('active') } else { $("input.metrictargetprefixfontitalic").prop("checked", false); $('label#metrictargetprefixitalictext').removeClass('active') }
                    $("#metrictargetprefixfontcolor").val(metricfont.customcomparision.prefixfont.fontcolor);
                }
                if (metricfont != undefined && metricfont.customcomparision != undefined && metricfont.customcomparision.suffixfont != undefined) {
                    $("#metrictargetsuffix").val(metricfont.customcomparision.suffixfont.value);
                    $("#metrictargetsuffixfontfamily").val(metricfont.customcomparision.suffixfont.fontfamily);
                    $("#metrictargetsuffixdatafontsize").val(metricfont.customcomparision.suffixfont.fontsize);
                    $("#metrictargetsuffixfontsizeparametertext").val(metricfont.customcomparision.suffixfont.fontsizeparameter);
                    if (metricfont.customcomparision.suffixfont.fontweight == "bold") { $("input.metrictargetsuffixfontweight").prop("checked", true); $('label#metrictargetsuffixboldtext').addClass('active') } else { $("input.metrictargetsuffixfontweight").prop("checked", false); $('label#metrictargetsuffixboldtext').removeClass('active') }
                    if (metricfont.customcomparision.suffixfont.fontitalic == "italic") { $("input.metrictargetsuffixfontitalic").prop("checked", true); $('label#metrictargetsuffixitalictext').addClass('active') } else { $("input.metrictargetsuffixfontitalic").prop("checked", false); $('label#metrictargetsuffixitalictext').removeClass('active') }
                    $("#metrictargetsuffixfontcolor").val(metricfont.customcomparision.suffixfont.fontcolor);
                }

                //Metric Achievement
                if (metricfont != undefined && metricfont.customcomparision != undefined && metricfont.customcomparision.achievement != undefined && metricfont.customcomparision.achievement.fontfamily != undefined && metricfont.customcomparision.achievement.fontsize != undefined && metricfont.customcomparision.achievement.fontsizeparameter != undefined && metricfont.customcomparision.achievement.fontweight != undefined && metricfont.customcomparision.achievement.fontitalic != undefined) {
                    $("#metricachievementfontfamily").val(metricfont.customcomparision.achievement.fontfamily);
                    $("#metricachievementdatafontsize").val(metricfont.customcomparision.achievement.fontsize);
                    $("#metricachievementfontsizeparametertext").val(metricfont.customcomparision.achievement.fontsizeparameter);
                    if (metricfont.customcomparision.achievement.fontweight == "bold") { $("input.metricachievementfontweight").prop("checked", true); $('label#metricachievementboldtext').addClass('active') } else { $("input.metricachievementfontweight").prop("checked", false); $('label#metricachievementboldtext').removeClass('active') }
                    if (metricfont.customcomparision.achievement.fontitalic == "italic") { $("input.metricachievementfontitalic").prop("checked", true); $('label#metricachievementitalictext').addClass('active') } else { $("input.metricachievementfontitalic").prop("checked", false); $('label#metricachievementitalictext').removeClass('active') }
                    $("#metricachievementfontcolor").val(metricfont.customcomparision.achievement.fontcolor);
                }
                if (metricfont != undefined && metricfont.customcomparision != undefined && metricfont.customcomparision.achievement != undefined && metricfont.customcomparision.achievement.prefixfont != undefined) {
                    $("#metricachievementprefix").val(metricfont.customcomparision.achievement.prefixfont.value);
                    $("#metricachievementprefixfontfamily").val(metricfont.customcomparision.achievement.prefixfont.fontfamily);
                    $("#metricachievementprefixdatafontsize").val(metricfont.customcomparision.achievement.prefixfont.fontsize);
                    $("#metricachievementprefixfontsizeparametertext").val(metricfont.customcomparision.achievement.prefixfont.fontsizeparameter);
                    if (metricfont.customcomparision.achievement.prefixfont.fontweight == "bold") { $("input.metricachievementprefixfontweight").prop("checked", true); $('label#metricachievementprefixboldtext').addClass('active') } else { $("input.metricachievementprefixfontweight").prop("checked", false); $('label#metricachievementprefixboldtext').removeClass('active') }
                    if (metricfont.customcomparision.achievement.prefixfont.fontitalic == "italic") { $("input.metricachievementprefixfontitalic").prop("checked", true); $('label#metricachievementprefixitalictext').addClass('active') } else { $("input.metricachievementprefixfontitalic").prop("checked", false); $('label#metricachievementprefixitalictext').removeClass('active') }
                    $("#metricachievementprefixfontcolor").val(metricfont.customcomparision.achievement.prefixfont.fontcolor);
                }
                if (metricfont != undefined && metricfont.customcomparision != undefined && metricfont.customcomparision.achievement != undefined && metricfont.customcomparision.achievement.suffixfont != undefined) {
                    $("#metricachievementsuffix").val(metricfont.customcomparision.achievement.suffixfont.value);
                    $("#metricachievementsuffixfontfamily").val(metricfont.customcomparision.achievement.suffixfont.fontfamily);
                    $("#metricachievementsuffixdatafontsize").val(metricfont.customcomparision.achievement.suffixfont.fontsize);
                    $("#metricachievementsuffixfontsizeparametertext").val(metricfont.customcomparision.achievement.suffixfont.fontsizeparameter);
                    if (metricfont.customcomparision.achievement.suffixfont.fontweight == "bold") { $("input.metricachievementsuffixfontweight").prop("checked", true); $('label#metricachievementsuffixboldtext').addClass('active') } else { $("input.metricachievementsuffixfontweight").prop("checked", false); $('label#metricachievementsuffixboldtext').removeClass('active') }
                    if (metricfont.customcomparision.achievement.suffixfont.fontitalic == "italic") { $("input.metricachievementsuffixfontitalic").prop("checked", true); $('label#metricachievementsuffixitalictext').addClass('active') } else { $("input.metricachievementsuffixfontitalic").prop("checked", false); $('label#metricachievementsuffixitalictext').removeClass('active') }
                    $("#metricachievementsuffixfontcolor").val(metricfont.customcomparision.achievement.suffixfont.fontcolor);
                }

            }
            $("#solidgaugemin").val(solidgaugemin);
            $("#solidgaugemax").val(solidgaugemax);
            _.each($(".metric-font-color-selector"), function (i) {
                $(i).colorpicker({ container: i.parentElement });
            });
            //$(".metric-font-color-selector").colorpicker();

            //Hide metric color picker on scroll
            /*$('#chartsoptionsfinetuning').on('scroll', function(e){
                $(".metric-font-color-selector").colorpicker('reposition');
            });*/
        }

        var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(this.props.chartnumber));
        _.each($('.measureitem'), function (element) {
            var value = $(element).text().trim().split(" ");
            _.each(chartDetails["yAxis"], function (val) {
                if (val.columnName === value[0]) {
                    $(element).addClass("active");
                }
            });
        });
        var xaxisData = chartDetails["xAxis"];
        _.each($('.dimensionitem'), function (element) {
            var value = $(element).text().trim().split(" ");
            if (xaxisData.columnName === value[0]) {
                $(element).addClass("active");
                displayNameMap[value.columnName] = value.columnDisplayName;
            }

        });

        _.each(this.state.dimensions, function (value) {
            //   value = value.toJSON();
            displayNameMap[value.columnName] = value.columnDisplayName;
        });
        displayNameMap[data["sortingColumn"]] = data["sortingColumn"];
        // Add selected sorting column in sorting
        if (data["sortingColumn"] != undefined && data["sortingColumn"].length > 0) {
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $('.axissetting').append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            var yAxisAttrView = new YAxisAttrView({
                axisduplicate: that.axisduplicate,
                dynamicdiv: dynamicdiv,
                yaxiscolumnDisplayName: displayNameMap[data["sortingColumn"]] == undefined ? data["sortingColumn"] : displayNameMap[data["sortingColumn"]],
                yaxiscolumnName: data["sortingColumn"],
                yaxiscolumnType: dataTypeMap[data["sortingColumn"]],
                yaxisaggregation: data["sortingColumnAggregation"],
                yaxistableName: tableNameMap[data["sortingColumn"]],
                sortType: data["sortType"],
                measures: that.state.measures,
                dimensions: that.state.dimensions,
                percentileValue: "25",//TODO,
                reportId: this.state.reportId,
                viewmode: 'sortingvalues',
                chartnumber: this.state.chartnumber,
                multifact: that.state.multifact,
                facttables: that.state.facttables,
                multifactentities: that.state.multifactentities,
                is_storyboard: that.state.is_storyboard,
            });
            $(".sortingattributes").html($("#" + dynamicdiv));
        }
        if (chartType == "leaflet" || chartType == "world" || chartType == "srilankamap" || chartType == "countrymap" || chartType == "indiamap" ) {
            $(".xaxisattributes .singleliitems .aggregationtext").removeClass("hidden");
        }
        else {
            $(".xaxisattributes .singleliitems .aggregationtext").addClass("hidden");
        }

        //ShowAxis in Percent Feature
        if (chartType == "bar" || chartType == "invertedbar" || chartType == "line" || chartType == "invertedline" || chartType == "spline" || chartType == "area" || chartType == "stackedarea") {
            $(".axispercentcontainer").show("slow");
            if (chartSettings.showaxisinpercent && chartSettings.showaxisinpercent != undefined) {
                $(".axisinpercent[data-num=" + this.props.chartnumber + "]").prop("checked", true);
            } else {
                $(".axisinpercent[data-num=" + this.props.chartnumber + "]").prop("checked", false);
            }
        } else {
            $(".axispercentcontainer").hide("slow");
            $(".axisinpercent[data-num=" + this.props.chartnumber + "]").prop("checked", false);
        }
        //  $('.conditionalformattheme>span.input-group').colorpicker();

        //Condition for Chart Settings Narrative feature
        var narrativeAllowedChartTypes = ["bar", "stackedbar", "invertedbar", "invertedstackedbar", "multicolorbar", "3dbar", "line", "spline", "invertedline", "area", "stackedarea", "invertedarea", "spiderweb", "polar", "windrose"];

        if ($.inArray(chartType, narrativeAllowedChartTypes) >= 0) {
            //$("#chartNarrative").prop('checked',true);
            $("#chartNarrative").closest(".col-xs-12").removeClass("hide");
        } else {
            $("#chartNarrative").prop('checked', false);
            $("#chartNarrative").closest(".col-xs-12").addClass("hide");
        }
        //Trellis New Features
        if (chartType == "trellis") {
            $("#trellisDynamicScaling").closest(".col-xs-12").removeClass("hide");
            $("#trellisFitToWidth").closest(".col-xs-12").removeClass("hide");
        } else {
            $("#trellisDynamicScaling").prop('checked', false);
            $("#trellisDynamicScaling").closest(".col-xs-12").addClass("hide");

            $("#trellisFitToWidth").prop('checked', false);
            $("#trellisFitToWidth").closest(".col-xs-12").addClass("hide");
        }

        $('#axissettings.tab-pane').on('scroll', function () {
            if ($('.colorpicker.colorpicker-visible').length > 0) {
                $(".input-group.colorpicker-element").colorpicker('hide');
            }
        });

        //Pareto feature
        var paretoAllowedChartTypes = ["bar"];

        if ($.inArray(chartType, paretoAllowedChartTypes) >= 0) {
            //$("#chartPareto").prop('checked',true);
            $("#chartPareto").closest(".col-xs-12").removeClass("hide");
        } else {
            $("#chartPareto").prop('checked', false);
            $("#chartPareto").closest(".col-xs-12").addClass("hide");
        }

        //Connect Null point feature
        var connectNullsAllowedChartTypes = ["line","spline","multixline","invertedline"];

        if ($.inArray(chartType, connectNullsAllowedChartTypes) >= 0) {
            //$("#chartNulls").prop('checked',true);
            $("#connectNulls").closest(".col-xs-12").removeClass("hide");
        } else {
            $("#connectNulls").prop('checked', false);
            $("#connectNulls").closest(".col-xs-12").addClass("hide");
        }

        //Heatmap Mono Color feature
		var HeatmapMonoColorAllowedChartTypes = ["heatmap"];
		if ($.inArray(chartType, HeatmapMonoColorAllowedChartTypes) >= 0) {
			//$("#chartHeatmapMonoColor").prop('checked',true);
			$("#chartHeatmapMonoColor").closest(".col-xs-12").removeClass("hide");
		} else {
			$("#chartHeatmapMonoColor").prop('checked', false);
			$("#chartHeatmapMonoColor").closest(".col-xs-12").addClass("hide");
		}
        
        let comparedaterangecharttpes =  ["bar", "stackedbar", "line", "spline", "area", "stackedarea"];
        let compareDateRangeObj = chartDetails!=undefined && chartDetails["compareDateRangeObj"]!=null?chartDetails["compareDateRangeObj"]:null;
        let  tempYaxis = chartDetails!=undefined?chartDetails["yAxis"]:[];
        let checkconditionval = false;
        if(compareDateRangeObj!=null && compareDateRangeObj.hasOwnProperty("0") && compareDateRangeObj["0"]!=null){
             let yjsObj = compareDateRangeObj["0"]; 
            _.each(tempYaxis,function(val,index){
            if(val.columnName!=undefined&&val.columnName!=null&&yjsObj.hasOwnProperty(val.columnName)){
                checkconditionval = true;
            }
        });
        }
       if(checkconditionval&&comparedaterangecharttpes.indexOf(chartType)>=0){
        $("#chartNarrative").prop('checked', false);
        $("#chartNarrative").closest(".col-xs-12").addClass("hide");
        $("#chartPareto").prop('checked', false);
        $("#chartPareto").closest(".col-xs-12").addClass("hide");
        $('.yaxisattributes .singleliitems').first().addClass('has-compare-date')
       }
        //yAxis Scaling feature
        var yAxisAllowedChartTypes = ["bar", "stackedbar", "line", "spline", "area", "stackedarea", "invertedbar", "invertedstackedbar", "multicolorbar", "3dbar", "invertedline", "invertedarea","world","countrymap"];

        if ($.inArray(chartType, yAxisAllowedChartTypes) >= 0) {
            $(".y-axis-options").removeClass("hide");
        } else {
            $(".y-axis-options").addClass("hide");
        }
        //zoom x,y,xy allowed charts
		var zoomAllowedChartTypes = ["bar", "stackedbar", "line", "spline", "area", "stackedarea", "invertedbar", "invertedstackedbar", "multicolorbar", "3dbar", "invertedline", "invertedarea"];
		if ($.inArray(chartType, zoomAllowedChartTypes) >= 0) {
			$('#chartZoomSettings').closest(".col-xs-12").removeClass('hide');
		} else {
			$('#chartZoomSettings').closest(".col-xs-12").addClass('hide');
		}
        //Multiple Y-Axis Scaling feature (chartSplitYAxis)
        var chartSplitYAxisAllowedChartTypes = ["combinationchart"];
        if ($.inArray(chartType, chartSplitYAxisAllowedChartTypes) >= 0) {
            //$("#chartSplitYAxis").prop('checked',true);
            $("#chartSplitYAxis").closest(".col-xs-12").removeClass("hide");
        } else {
            $("#chartSplitYAxis").prop('checked', false);
            $("#chartSplitYAxis").closest(".col-xs-12").addClass("hide");
        }

        //Equal Y-Axis Scaling feature (chartEqualYAxisScale)
        var chartEqualYAxisScaleAllowedChartTypes = ["combinationchart"];
        if ($.inArray(chartType, chartEqualYAxisScaleAllowedChartTypes) >= 0) {
            //$("#chartEqualYAxisScale").prop('checked',true);
            $("#chartEqualYAxisScale").closest(".col-xs-12").removeClass("hide");
        } else {
            $("#chartEqualYAxisScale").prop('checked', false);
            $("#chartEqualYAxisScale").closest(".col-xs-12").addClass("hide");
        }
        return (<div></div>)
    }
    addyaxisvalue = (event) => {
        var $this = event.currentTarget;
        var that = this;
        if (event.target.className != "fa fa-pencil") {
            var isYaxisalreadyExist = false;
            if (isYaxisalreadyExist == false) {
                if (!$($this).hasClass('active')) {
                    var columnName = $($this).attr("data-name");
                    var columnDisplayName = $($this).find('.itemname').text();
                    var datatype = $($this).attr("data-type");
                    var aggregation = $($this).find(".boclabel-info").text().replace(/[^A-Z0-9]/ig, "");
                    var tableName = $($this).attr("table-name");
                    var percentileValue = $($this).attr("percentile-value");
                    var data = JSON.parse(ChartInfo.functions.getChartDetails(that.state.chartnumber));
                    var activeChartType = $('.charttype.active').attr("id");
                    var iscustomfunction = $($this).attr("iscustomfunction");
                    var currentdatano = 0;
                    var yaxisDropdownListValue = $('.yaxisdropdownlist[data-num-yaxis]').val();
                    if (yaxisDropdownListValue === undefined) {
                        currentdatano = 0;
                    } else {
                        currentdatano = Math.max.apply([], $('.yaxisdropdownlist[data-num-yaxis]').map(function () {
                            return $(this).attr('data-num-yaxis');
                        }).get());
                        if (currentdatano === undefined) {
                            currentdatano = 0;
                        } else {
                            currentdatano = parseInt(currentdatano) + 1;
                        }
                    }
                    var today = new Date();
                    var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                    dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                    $('.axissettingdummy').append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                    var yAxisAttrView = new YAxisAttrView({
                        axisduplicate: this.axisduplicate,
                        dynamicdiv: dynamicdiv,
                        yaxiscolumnDisplayName: columnDisplayName,
                        yaxiscolumnName: columnName,
                        yaxiscolumnType: datatype,
                        yaxisaggregation: aggregation,
                        yaxistableName: tableName,
                        percentileValue: percentileValue,
                        measures: this.state.measures,
                        dimensions: this.state.dimensions,
                        reportId: this.state.reportId,
                        viewmode: 'values',
                        chartnumber: this.state.chartnumber,
                        id: currentdatano,
                        iscustomfunction: iscustomfunction,
                        multifact: this.state.multifact,
                        facttables: this.state.facttables,
                        multifactentities: this.state.multifactentities,
                        features: this.state.features,
                        is_storyboard: this.state.is_storyboard,
                    });
                    var today = new Date();
                    var combdynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                    combdynamicdiv = combdynamicdiv + "" + Math.floor(Math.random() * 1000);
                    $('.axissettingdummy').append("<div className='" + combdynamicdiv + " hide' id='" + combdynamicdiv + "'></div>");
                    var combinationChartsView = new CombinationChartsView({
                        dynamicdiv: combdynamicdiv,
                        yaxiscolumnDisplayName: columnDisplayName,
                        yaxiscolumnName: columnName,
                        yaxiscolumnType: datatype,
                        yaxisaggregation: "bar",
                        yaxistableName: tableName,
                        percentileValue: percentileValue,
                        selectedChartType: "column",
                        chartaxisposintion: 'left',
                        reportId: this.state.reportId,
                        id: currentdatano,
                        multifact: this.state.multifact,
                        facttables: this.state.facttables,
                        multifactentities: this.state.multifactentities,
                    });
                    if ($(".yaxisattributes li").length > 1 && activeChartType === "heatmap") {
                        $(".spinner").hide();
                        $(".gridspinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Warning');
                        $('.birdmessage-info').empty().text(lang["storyboard.allowheatmap"]);
                    } else if (activeChartType == "metric" && datatype == "custommeasurehierarchy") {
                        $(".spinner").hide();
                        $(".gridspinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Warning');
                        $('.birdmessage-info').empty().text(getMessage('BirdWarning90'));
                    } else if ((activeChartType == "solidgauge" || activeChartType == "fullcirclegauge") && datatype == "custommeasurehierarchy") {
                        $(".spinner").hide();
                        $(".gridspinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Warning');
                        $('.birdmessage-info').empty().text(getMessage('BirdWarning91'));
                    } else {
                        $(".yaxisattributes").append($("#" + dynamicdiv));
                        if (activeChartType == "combinationchart") {
                            $(".combinationchartslistattributes").append($("#" + combdynamicdiv));
                        }
                    }

                }
                var isRankingItemAlreadyExist = false;
                _.each($('.rankingitem option'), function (rankingItem) {
                    if ($(rankingItem).attr("data-name") == $($this).attr("data-name")) {
                        isRankingItemAlreadyExist = true;
                    }
                });
                if (isRankingItemAlreadyExist == false) {
                    if ($($this).attr("data-type") != "customaggregatedfield") {
                        $(".rankingitem").append('<option data-name="' + $($this).attr("data-name") + '" data-agg="' + $($this).find(".boclabel-info").text().replace(/[^A-Z0-9]/ig, "") + '"data-type="' + $($this).attr("data-type") + '" title="' + $($this).find('.itemname').text() + '">' + $($this).find('.itemname').text() + ' (' + $($this).find(".boclabel-info").text().replace(/[^A-Z0-9]/ig, "") + ') </option>');
                    } else if ($($this).attr("data-type") == "customaggregatedfield") {
                        $(".rankingitem").append('<option data-name="' + $($this).attr("data-name") + '" data-agg="' + $($this).find(".boclabel-info").text().replace(/[^A-Z0-9]/ig, "") + '"data-type="' + $($this).attr("data-type") + '" title="' + $($this).find('.itemname').text() + '">' + $($this).find('.itemname').text() + '' + $($this).find(".boclabel-info").text().replace(/[^A-Z0-9]/ig, "") + '</option>');
                    }
                }
            }
            var jsonArray = ChartInfo.functions.getAlerts();
            var that = this;
            _.each(jsonArray, function (value, index) {
                if (jsonArray != undefined && jsonArray.length != 0) {
                    if (value.chartnumber == that.state.chartnumber) {
                        $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Information');
                        $('.birdmessage-info').empty().text(getMessage('BirdInformation30'));
                    }
                }
            });
        }

    }
    showyAxisDropDown() {
        var that = this;
        var measureaccess = false;
        _.each(this.state.features, function (featuresModule) {
            if (featuresModule.moduleName == 'my_report') {
                _.each(featuresModule.features, function (feature) {
                    if (feature.featureName == 'viewreport_maximize_custom_measure' && feature.allow == true) {
                        measureaccess = true;
                    }
                })
            }
        });

        $(".yaxisdropdown").empty();
        if (measureaccess === true) {
            $(".yaxisdropdown").append('<li class="list-title">'+lang["storyboard.axisy"]+'<button class="btn btn-transparent btn-sm createcustomhierarchymeasure tooltip-left" id="createcustomhierarchymeasures" data-tooltip="'+lang["storyboard.createhierarchy"]+'"><i class="fa fa-list"></i></button><button class="btn btn-transparent btn-sm createcustommeasure tooltip-left" id="createcustommeasures" data-tooltip="'+lang["storyboard.createmeasur"]+'"><i>'+lang["storyboard.fx"]+'</i></button></li><li class="xs-list-item"> <input class="form-control form-control-yaxisdropdown search-image" placeholder='+ lang["storyboard.search"]+'  id="searchinput" type="text"></li>');
        } else {
            $(".yaxisdropdown").append('<li class="list-title">'+lang["storyboard.axisy"]+'</li><li class="xs-list-item"> <input class="form-control form-control-yaxisdropdown search-image" placeholder="'+ lang["storyboard.search"]+'" id="searchinput" type="text"></li>');
        }
        $(".yaxisdropdown").append('<span class="axis-list-items"></span>');
        
        var columnsList = [];
         /*_.each($(".attributesmeasures"), function (e) {
            var $this = e;
            var customfield = 0
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $(".yaxisdropdown>.axis-list-items").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            if ($($this).hasClass("attributescustomfields")) {
                customfield = 1
            }
            if (columnsList.indexOf($($this).attr('title')) == -1) {
                var yAxisDropDown = new YAxisDropDownView({
                    dynamicdiv: dynamicdiv,
                    addyaxisvalue: that.addyaxisvalue,
                    addreferencevalue: that.addreferencevalue,
                    columnDisplayName: $($this).attr('title'),
                    columnName: $($this).attr("data-name"),
                    dataType: $($this).attr("data-type"),
                    tableName: $($this).attr("table-name"),
                    aggregation: 'count',
                    percentileValue: $($this).find(".percentilebox").val(),
                    customfield: customfield,
                    multifact: that.state.multifact,
                    facttables: that.state.facttables,
                    multifactentities: that.state.multifactentities,
                });
                columnsList.push($($this).attr('title'));
                $(".yaxisdropdown>.axis-list-items").append($("#" + dynamicdiv));
                $('.form-control-yaxisdropdown').click(function (e) {
                    e.stopPropagation();
                });
            }
        });*/
        /*_.each($(".attributesdimensions").slice(0, 1000), function (e) {
            var $this = e;
            var customfield = 0
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $(".yaxisdropdown>.axis-list-items").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            if ($($this).hasClass("attributescustomfields")) {
                customfield = 1
            }
            if (columnsList.indexOf($($this).attr('title')) == -1 && $($this).attr("data-type") != 'customhierarchy'
                && $($this).attr("data-type") != 'customrange') {
                var yAxisDropDown = new YAxisDropDownView({
                    dynamicdiv: dynamicdiv,
                    addyaxisvalue: that.addyaxisvalue,
                    addreferencevalue: that.addreferencevalue,
                    columnDisplayName: $($this).attr('title'),
                    columnName: $($this).attr("data-name"),
                    dataType: $($this).attr("data-type"),
                    tableName: $($this).attr("table-name"),
                    aggregation: 'count',
                    percentileValue: $($this).find(".percentilebox").val(),
                    customfield: customfield,
                    facttables: that.state.facttables,
                    multifact: that.state.multifact,
                    multifactentities: that.state.multifactentities,
                });

                $(".yaxisdropdown>.axis-list-items").append($("#" + dynamicdiv));
                $('.form-control-yaxisdropdown').click(function (e) {
                    e.stopPropagation();
                });
            }
        });*/
        var ycolumnsArray=[];
        _.each($(".attributesmeasures"), function (e) {
            var $this = e;
            var ycolumnsObj={}
            var customfield = 0
            if ($($this).hasClass("attributescustomfields")) {
                customfield = 1
            }
            if (columnsList.indexOf($($this).attr('title')) == -1) {
            ycolumnsObj= {
                    columnDisplayName: $($this).attr('title'),
                    columnName: $($this).attr("data-name"),
                    dataType: $($this).attr("data-type"),
                    tableName: $($this).attr("table-name"),
                    aggregation: 'count',
                    percentileValue: $($this).find(".percentilebox").val(),
                    customfield: customfield
                }
                ycolumnsArray.push(ycolumnsObj);
                columnsList.push($($this).attr('title'));
            }
        });
       
        var mincount = 1000;
        var maxcount = 1040;
        _.each($(".attributesdimensions").slice(0, 1000), function (e) {
            var $this = e;
            var ycolumnsObj={}
            var customfield = 0
            if ($($this).hasClass("attributescustomfields")) {
                customfield = 1
            }
            if (columnsList.indexOf($($this).attr('title')) == -1 && $($this).attr("data-type") != 'customhierarchy'
                && $($this).attr("data-type") != 'customrange') {
            ycolumnsObj = {
                    columnDisplayName: $($this).attr('title'),
                    columnName: $($this).attr("data-name"),
                    dataType: $($this).attr("data-type"),
                    tableName: $($this).attr("table-name"),
                    aggregation: 'count',
                    percentileValue: $($this).find(".percentilebox").val(),
                    customfield: customfield
              }
                ycolumnsArray.push(ycolumnsObj);
            }
        });
        ycolumnsArray.sort(function (a, b) {
			var x = a.columnDisplayName.toLowerCase();
			var y = b.columnDisplayName.toLowerCase();
			return x < y ? -1 : x > y ? 1 : 0;
		});
        _.each(ycolumnsArray,function(ycol){
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $(".yaxisdropdown>.axis-list-items").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            var yAxisDropDown = new YAxisDropDownView({
                dynamicdiv: dynamicdiv,
                addyaxisvalue: that.addyaxisvalue,
                addreferencevalue: that.addreferencevalue,
                columnDisplayName: ycol.columnDisplayName,
                columnName: ycol.columnName,
                dataType: ycol.dataType,
                tableName: ycol.tableName,
                aggregation: ycol.aggregation,
                percentileValue: ycol.percentileValue,
                customfield: ycol.customfield,
                facttables: that.state.facttables,
                multifact: that.state.multifact,
                multifactentities: that.state.multifactentities,
            });
            $(".yaxisdropdown>.axis-list-items").append($("#" + dynamicdiv));
                $('.form-control-yaxisdropdown').click(function (e) {
                    e.stopPropagation();
                });
        })
        
        $(".yaxisdropdown>.axis-list-items").scroll(function () {
            var searchVal = $(".form-control-yaxisdropdown").val();
            if (($(".yaxisdropdown>.axis-list-items").scrollTop() + $(".yaxisdropdown>.axis-list-items").height() + 25 >= $(".yaxisdropdown>.axis-list-items")[0].scrollHeight) && searchVal == "") {
                _.each($(".attributesdimensions").slice(mincount, maxcount), function (e) {
                    var $this = e;
                    var customfield = 0
                    if ($($this).hasClass("attributescustomfields")) {
                        customfield = 1
                    }
                    var today = new Date();
                    var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                    dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                    $(".yaxisdropdown>.axis-list-items").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                    if (columnsList.indexOf($($this).attr('title')) == -1 && $($this).attr("data-type") != 'customhierarchy'
                        && $($this).attr("data-type") != 'customrange') {
                        var yAxisDropDown = new YAxisDropDownView({
                            dynamicdiv: dynamicdiv,
                            addyaxisvalue: that.addyaxisvalue,
                            addreferencevalue: that.addreferencevalue,
                            columnDisplayName: $($this).attr('title'),
                            columnName: $($this).attr("data-name"),
                            dataType: $($this).attr("data-type"),
                            tableName: $($this).attr("table-name"),
                            aggregation: 'count',
                            percentileValue: $($this).find(".percentilebox").val(),
                            customfield: customfield,
                            multifact: that.state.multifact,
                            facttables: that.state.facttables,
                            multifactentities: that.state.multifactentities,
                        });
                        $(".yaxisdropdown>.axis-list-items").append($("#" + dynamicdiv));
                    }
                });

                mincount = mincount + 20;
                maxcount = maxcount + 20;

            }
        });

        $('.form-control-yaxisdropdown').keyup(function (e) {
            var $this = e.currentTarget;
            $("ul.yaxisdropdown li.searchresult").remove();
            setTimeout(function () {
                var valThis = $($this).val().toLowerCase();
                $(".itemname").parents(".measureitem:not('.searchresult')").hide();
                $("ul.yaxisdropdown li.searchresult").remove();
                if (valThis != "" && valThis != undefined) {
                    $(".attributesdimensions").each(function () {
                        if ($(this).attr("data-type") != "customrange" && $(this).attr("data-type") != "customhierarchy") {
                            var customfield = 0;
                            if ($(this).hasClass("attributescustomfields")) {
                                customfield = 1;
                            }
                            var text = $(this).find(".itemname").text().toLowerCase();
                            if (text.indexOf(valThis) >= 0) {
                                var today = new Date();
                                var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                                $(".yaxisdropdown>.axis-list-items").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                                var yAxisDropDown = new YAxisDropDownView({
                                    addyaxisvalue: that.addyaxisvalue,
                                    dynamicdiv: dynamicdiv,
                                    columnDisplayName: $(this).attr('title'),
                                    columnName: $(this).attr("data-name"),
                                    dataType: $(this).attr("data-type"),
                                    tableName: $(this).attr("table-name"),
                                    aggregation: 'count',
                                    percentileValue: $(this).find(".percentilebox").val(),
                                    customfield: customfield,
                                    facttables: that.state.facttables,
                                    multifact: that.state.multifact,
                                    multifactentities: that.state.multifactentities,
                                });
                                var result = $("#" + dynamicdiv).find("li").addClass("searchresult");
                                $(".yaxisdropdown>.axis-list-items").append(result);
                            }
                        }
                    });
                    $(".attributesmeasures").each(function () {
                        if ($(this).attr("data-type") == "customaggregatedfield" || $(this).attr("data-type") == "custommeasurehierarchy") {
                            var text = $(this).find(".itemname").text().toLowerCase();
                            if (text.indexOf(valThis) >= 0) {
                                var today = new Date();
                                var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                                $(".yaxisdropdown>.axis-list-items").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                                var yAxisDropDown = new YAxisDropDownView({
                                    dynamicdiv: dynamicdiv,
                                    columnDisplayName: $(this).attr('title'),
                                    columnName: $(this).attr("data-name"),
                                    dataType: $(this).attr("data-type"),
                                    tableName: $(this).attr("table-name"),
                                    aggregation: 'count',
                                    percentileValue: $(this).find(".percentilebox").val(),
                                    facttables: that.state.facttables,
                                    multifact: that.state.multifact,
                                    multifactentities: that.state.multifactentities,
                                    addyaxisvalue: that.addyaxisvalue,
                                });
                                var result = $("#" + dynamicdiv).find("li").addClass("searchresult");
                                $(".yaxisdropdown>.axis-list-items").append(result);
                            }
                        }
                    });
                } else {
                    $(".itemname").parents(".measureitem:not('searchresult')").show();
                    $("ul.yaxisdropdown li.searchresult").remove();
                }
            }, 500);
        });
        $('.createcustommeasure').click(function (e) {
            e.preventDefault();
            that.createcustomaggregatedfield(e.currentTarget);
        })
        $('.createcustomhierarchymeasure').click(function (e) {
            e.preventDefault();
            that.createcustommeasurehierarchy(e.currentTarget);
        })

    }
    axisduplicate = (e) => {
        var that = this;
        var $this = e.currentTarget;
        $this = $($this).closest("li.boc-list-item.singleliitems");
        var columnName = $($this).attr("data-name");
        var columnDisplayName = $($this).find('.itemname').text();
        var datatype = $($this).attr("data-type");
        var aggregation = $($this).attr("data-agg").replace(/[^A-Z0-9]/ig, "");
        var tableName = $($this).attr("table-name");
        var percentileValue = $($this).attr("percentile-value");
        var data = JSON.parse(ChartInfo.functions.getChartDetails(this.state.chartnumber));
        var activeChartType = $('.charttype.active').attr("id");
        var activechart = $(".charttype.active").attr("id");
        var iscustomfunction = 0;
        if ($($this).attr("iscustomfunction") != undefined && $($this).attr("iscustomfunction") == 1) {
            iscustomfunction = 1;
        }
        var currentdatano = Math.max.apply([], $('.yaxisdropdownlist[data-num-yaxis]').map(function () {
            return $(this).attr('data-num-yaxis');
        }).get());
        if (currentdatano === undefined) {
            currentdatano = 0;
        } else {
            currentdatano = parseInt(currentdatano) + 1;
        }
        if (activechart != "heatmap" && activechart != "metric" && activechart != "solidgauge" && activechart != "fullcirclegauge" && activechart != "funnel" && activechart != "pyramid"
            && activechart != "world" && activechart != "countrymap" && activechart != "indiamap" && activechart != "srilankamap" && activechart != "leaflet" && activechart != "boxplot") {
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $(".yaxisattributes").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            var yAxisAttrView = new YAxisAttrView({
                dynamicdiv: dynamicdiv,
                addyaxisvalue: that.addyaxisvalue,
                yaxiscolumnDisplayName: columnDisplayName,
                yaxiscolumnName: columnName,
                yaxiscolumnType: datatype,
                yaxisaggregation: aggregation,
                yaxistableName: tableName,
                percentileValue: percentileValue,
                measures: that.state.measures,
                dimensions: that.state.dimensions,
                viewmode: 'values',
                chartnumber: that.state.chartnumber,
                id: currentdatano,
                iscustomfunction: iscustomfunction,
                multifact: that.state.multifact,
                facttables: that.state.facttables,
                multifactentities: that.state.multifactentities,
                features: this.state.features,
                is_storyboard: this.state.is_storyboard,
                axisduplicate: that.axisduplicate
            });
            $(".yaxisattributes").append($("#" + dynamicdiv));

            if (activeChartType == "combinationchart") {
                var today = new Date();
                var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                $(".combinationchartslistattributes").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                var combinationChartsView = new CombinationChartsView({
                    dynamicdiv: dynamicdiv,
                    addyaxisvalue: this.addyaxisvalue,
                    yaxiscolumnDisplayName: columnDisplayName,
                    yaxiscolumnName: columnName,
                    yaxiscolumnType: datatype,
                    yaxisaggregation: "bar",
                    yaxistableName: tableName,
                    percentileValue: percentileValue,
                    selectedChartType: "column",
                    chartaxisposintion: 'left',
                    reportId: this.state.reportId,
                    id: currentdatano,
                    facttables: this.state.facttables,
                    multifact: this.state.multifact,
                    multifactentities: this.state.multifactentities,
                });
                $(".combinationchartslistattributes").append($("#" + dynamicdiv));
            }
        } else {
            $(".spinner").hide();
            $(".gridspinner").hide();
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(getMessage('BirdWarning08'));
            e.stopPropagation();
        }
    }
    showXAxisDropDown = (e) => {
        var that = this;
        var isCustomFieldButtonEnable = false;
        var custom_field_access = false, custom_hierarchy_access = false, custom_range_access = false;

        $.each(this.state.features, function (index, featuresModule) {
            if (featuresModule.moduleName == 'my_report') {
                $.each(featuresModule.features, function (inddex2, feature) {
                    if ((feature.featureName == 'viewreport_maximize_custom_field' && feature.allow == true) || (feature.featureName == 'viewreport_maximize_custom_hierarchy' && feature.allow == true || feature.featureName == 'viewreport_maximize_custom_range' && feature.allow == true)) {
                        isCustomFieldButtonEnable = true;
                    }
                });
            }
        });
        if (isCustomFieldButtonEnable) {
            _.each(this.state.features, function (featuresModule) {
                if (featuresModule.moduleName == 'my_report') {
                    _.each(featuresModule.features, function (feature) {
                        if (feature.featureName == 'viewreport_maximize_custom_field' && feature.allow == true) {
                            custom_field_access = true;
                        } else if (feature.featureName == 'viewreport_maximize_custom_hierarchy' && feature.allow == true) {
                            custom_hierarchy_access = true
                        } else if (feature.featureName == 'viewreport_maximize_custom_range' && feature.allow == true) {
                            custom_range_access = true
                        }
                    });
                }
            });
        }

        $(".xaxisdropdown").empty();
        if (isCustomFieldButtonEnable) {
            $(".xaxisdropdown").append('<li class="list-title">'+lang["storyboard.axisx"]+'<span class="dimensionoptions"></span></li><li class="xs-list-item"> <input class="form-control form-control-xaxisdropdown search-image" placeholder='+ lang["storyboard.search"]+' id="searchinput" type="text"></li>');
            if (custom_range_access === true) {
                $(".xaxisdropdown>.list-title>.dimensionoptions").append('<button class="btn btn-transparent btn-sm createrange tooltip-left" id="createrangemax" data-tooltip="'+lang["storyboard.customrange"]+'"><i class="fa fa-arrows-h"></i></button>')
            }
            if (custom_hierarchy_access === true) {
                $(".xaxisdropdown>.list-title>.dimensionoptions").append('<button class="btn btn-transparent btn-sm callcustomhierarchy tooltip-left" id="callcustommeasuremax" data-tooltip="'+lang["storyboard.drillhierarchy"]+'"><i class="fa fa-list"></i></button>')
            }
            if (custom_field_access === true) {
                $(".xaxisdropdown>.list-title>.dimensionoptions").append('<button class="btn btn-transparent btn-sm callcustommeasure tooltip-left" id="callcustommeasuremax" data-tooltip="'+lang["storyboard.customfield"]+'"><i>'+lang["storyboard.fx"]+'</i></button>')
            }

        } else {
            $(".xaxisdropdown").append('<li class="list-title">'+lang["storyboard.axisx"]+'</li><li class="xs-list-item"> <input class="form-control form-control-xaxisdropdown search-image" placeholder="'+ lang["storyboard.search"]+'" id="searchinput" type="text"></li>');
        }
        $(".xaxisdropdown").append('<span class="axis-list-items"></span>');
        var mincount = 1000;
        var maxcount = 1040;
        var elements = $(".attributesdimensions");


        _.each($(".attributesdimensions").slice(0, 1000), function (e) {
            var $this = e;
            var customfield = 0
            if ($($this).hasClass("attributescustomfields")) {
                customfield = 1
            } else if ($($this).hasClass("customhierarchyfields")) {
                customfield = 2
            } else if ($($this).hasClass("customrangefields")) {
                customfield = 3
            }
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $(".xaxisdropdown>.axis-list-items").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            var xAxisDropDown = new XAxisDropDownView({
                addxaxisvalue: that.addxaxisvalue,
                dynamicdiv: dynamicdiv,
                columnDisplayName: $($this).attr('title'),
                columnName: $($this).attr("data-name"),
                dataType: $($this).attr("data-type"),
                xaxisidentity: $($this).attr("data-type"),
                tableName: $($this).attr("table-name"),
                chartType: $('.charttype.active').attr("id"),
                customfield: customfield,
                multifact: that.state.multifact,
                facttables: that.state.facttables,
                multifactentities: that.state.multifactentities,
                reportId: that.props.reportId,
                datamodelId: that.props.datamodelId
            });
            $(".xaxisdropdown>.axis-list-items").append($("#" + dynamicdiv));
            $('.form-control-xaxisdropdown').click(function (e) {
                e.stopPropagation();
            });
        });

        $(".xaxisdropdown>.axis-list-items").scroll(function () {
            var searchVal = $(".form-control-xaxisdropdown").val();
            if (($(".xaxisdropdown>.axis-list-items").scrollTop() + $(".xaxisdropdown>.axis-list-items").height() + 25 >= $(".xaxisdropdown>.axis-list-items")[0].scrollHeight) && searchVal == "") {
                _.each($(".attributesdimensions").slice(mincount, maxcount), function (e) {
                    var $this = e;
                    var customfield = 0
                    if ($($this).hasClass("attributescustomfields")) {
                        customfield = 1
                    } else if ($($this).hasClass("customhierarchyfields")) {
                        customfield = 2
                    } else if ($($this).hasClass("customrangefields")) {
                        customfield = 3
                    }
                    var today = new Date();
                    var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                    dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                    $(".xaxisdropdown>.axis-list-items").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                    var xAxisDropDown = new XAxisDropDownView({
                        addxaxisvalue: this.addxaxisvalue,
                        dynamicdiv: dynamicdiv,
                        columnDisplayName: $($this).attr('title'),
                        columnName: $($this).attr("data-name"),
                        dataType: $($this).attr("data-type"),
                        xaxisidentity: $($this).attr("data-type"),
                        tableName: $($this).attr("table-name"),
                        chartType: $('.charttype.active').attr("id"),
                        customfield: customfield,
                        multifact: that.state.multifact,
                        facttables: that.state.facttables,
                        multifactentities: that.state.multifactentities,
                        reportId: that.props.reportId,
                        datamodelId: that.props.datamodelId
                    });
                    $(".xaxisdropdown>.axis-list-items").append($("#" + dynamicdiv));
                    $('.form-control-xaxisdropdown').click(function (e) {
                        e.stopPropagation();
                    });
                });
                mincount = mincount + 20;
                maxcount = maxcount + 20;
            }
        });

        /*var xAxisDropDown = new XAxisDropDownView({
            broker : that.broker,
            columnDisplayName : 'Measure Names',
            columnName :'Measure Names',
            dataType : 'string',
            tableName:'Measure Names'
        });
        $(".xaxisdropdown>.axis-list-items").append(xAxisDropDown.el);*/
        $('.form-control-xaxisdropdown').click(function (e) {
            e.stopPropagation();
        });
        $('.form-control-xaxisdropdown').keyup(function (e) {
            e.stopPropagation();
            var $this = e.currentTarget;
            $("ul.xaxisdropdown li.searchresult").remove();
            setTimeout(function () {
                var valThis = $($this).val().toLowerCase();
                $(".itemname").parents(".dimensionitem:not('.searchresult')").hide();
                $("ul.xaxisdropdown li.searchresult").remove();
                if (valThis != "" && valThis != undefined) {
                    $(".attributesdimensions").each(function () {
                        var customfield = 0
                        if ($(this).hasClass("attributescustomfields")) {
                            customfield = 1
                        } else if ($(this).hasClass("customhierarchyfields")) {
                            customfield = 2
                        } else if ($(this).hasClass("customrangefields")) {
                            customfield = 3
                        }
                        var text = $(this).find(".itemname").text().toLowerCase();
                        if (text.indexOf(valThis) >= 0) {
                            var today = new Date();
                            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                            $(".xaxisdropdown>.axis-list-items").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                            var xAxisDropDown = new XAxisDropDownView({
                                addxaxisvalue: that.addxaxisvalue,
                                dynamicdiv: dynamicdiv,
                                columnDisplayName: $(this).attr('title'),
                                columnName: $(this).attr("data-name"),
                                dataType: $(this).attr("data-type"),
                                xaxisidentity: $(this).attr("data-type"),
                                tableName: $(this).attr("table-name"),
                                chartType: $('.charttype.active').attr("id"),
                                customfield: customfield,
                                facttables: that.state.facttables,
                                multifact: that.state.multifact,
                                multifactentities: that.state.multifactentities,
                                reportId: that.props.reportId,
                                datamodelId: that.props.datamodelId,
                            });
                            var result = $("#" + dynamicdiv).find("li").addClass("searchresult");
                            $(".xaxisdropdown>.axis-list-items").append(result);
                        }
                    });
                } else {
                    $(".itemname").parents(".dimensionitem:not('searchresult')").show();
                    $("ul.xaxisdropdown li.searchresult").remove();
                }
            }, 500);
        });

        $('.callcustommeasure').click(function (e) {
            that.createCustomFiled(e.currentTarget);
        });
        $('.callcustomhierarchy').click(function (e) {
            that.createDillDownHierarchy(e.currentTarget);
        });
        $('.createrange').click(function (e) {
            that.createRange(e.currentTarget);
        });

    }
    addxaxisvalue = (event) => {
        var $this = event.currentTarget;
        var that = this;
        var activeChartType = $("li.boc-list-item.charticons.charttype.active").attr("id")
        if (event.target.className != "fa fa-pencil" && activeChartType != "metric" && activeChartType != "solidgauge" && activeChartType != "fullcirclegauge") {
            var isXaxisalreadyExist = false;
            _.each($('.xaxisattributes li'), function (xAxisli) {
                if ($(xAxisli).attr("data-name") == $($this).attr("data-name")) {
                    isXaxisalreadyExist = true;
                }
            });
            if (isXaxisalreadyExist == false) {
                if (!$($this).hasClass('active')) {
                    var columnName = $($this).attr("data-name");
                    var columnDisplayName = $($this).find(".itemname").text().trim();
                    var datatype = $($this).attr("data-type");
                    var tableName = $($this).attr("table-name");
                    var xaxisidentity = $($this).attr("xaxisidentity");
                    var iscustomfunction = $($this).attr("iscustomfunction");
                    var activeChartType = $('.charttype.active').attr("id");
                    var today = new Date();
                    var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                    dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                    $(".xaxisattributesdummy").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                    var xaxisAttrView = new XAxisAttrView({
                        deletecustomfield: this.props.deletecustomfield,
                        dynamicdiv: dynamicdiv,
                        xaxiscolumnDisplayName: columnDisplayName,
                        xaxiscolumnName: columnName,
                        xaxiscolumnType: datatype,
                        xaxistableName: tableName,
                        xaxisidentity: xaxisidentity,
                        activeChartType: activeChartType,
                        viewmode: 'values',
                        iscustomfunction: iscustomfunction,
                        measures: this.state.measures,
                        dimensions: this.state.dimensions,
                        chartnumber: this.state.chartnumber,
                        multifact: that.state.multifact,
                        facttables: that.state.facttables,
                        multifactentities: that.state.multifactentities,
                        features: that.state.features,
                        is_storyboard: that.state.is_storyboard,
                    });
                    var activeChartType = $('.charttype.active').attr("id");
                    try {
                        if ($(".xaxisattributes .singleliitems").length < 3) {
                            if ($(".xaxisattributes .singleliitems").length == 0) {
                                $(".xaxisattributes").append($("#" + dynamicdiv));
                            } else if (activeChartType == "multixbar" || activeChartType == "multixline" || activeChartType == "multixarea") {
                                $(".xaxisattributes").append($("#" + dynamicdiv));
                            }
                            else if ((activeChartType == "leaflet" || activeChartType == "world" || activeChartType == "srilankamap" || activeChartType == "countrymap" || activeChartType == "indiamap") && $(".xaxisattributes .singleliitems").length < 2) {
                                $(".xaxisattributes").append($("#" + dynamicdiv));
                            }
                            else if (activeChartType == "bar") {
                                throw new Error("Basic Bar");
                            }
                            else if (activeChartType == "stackedbar") {
                                throw new Error("Stacked Bar");
                            } else {
                                throw new Error($('.charticons.charttype.active').text());
                            }
                        } else {
                            throw new Error($('.charticons.charttype.active').text());
                        }
                    } catch (exception) {
                        if ($(".xaxisattributes .singleliitems").length == 2) {
                            $(".spinner").hide();
                            $(".gridspinner").hide();
                            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                            $('.birdmessagebuttons').removeClass('show');
                            $('.birdmessagebuttons').addClass('hide');
                            $('.birdmessage-center').addClass("message-top-in");
                            $('.details').removeClass('show');
                            $('.details').addClass('hide');
                            $('.birdmessage h2').empty().append('Warning');
                            $('.birdmessage-info').empty().text(lang["storyboard.2X-axisallowcharts"]);

                            /*$('.fullscreenmain').prepend('<div class="alert alert-danger birderror alert-dismissable affix-top" style="margin:0 auto;width: 60%;top: 44px;position: fixed;z-index: 1;left: 20%;"> Only Three X-Axis Fields are allowed for "'+ exception.message +'" </div>');
                            setTimeout(function() {
                                $('.birderror').remove();	
                            }, 3000);*/
                        }
                        else if ($(".xaxisattributes .singleliitems").length == 3) {
                            $(".spinner").hide();
                            $(".gridspinner").hide();
                            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                            $('.birdmessagebuttons').removeClass('show');
                            $('.birdmessagebuttons').addClass('hide');
                            $('.birdmessage-center').addClass("message-top-in");
                            $('.details').removeClass('show');
                            $('.details').addClass('hide');
                            $('.birdmessage h2').empty().append('Warning');
                            $('.birdmessage-info').empty().text(lang["storyboard.3X-axisallowcharts"]);

                            /*$('.fullscreenmain').prepend('<div class="alert alert-danger birderror alert-dismissable affix-top" style="margin:0 auto;width: 60%;top: 44px;position: fixed;z-index: 1;left: 20%;"> Only Three X-Axis Fields are allowed for "'+ exception.message +'" </div>');
                            setTimeout(function() {
                                $('.birderror').remove();	
                            }, 3000);*/
                        }
                        else {
                            $(".spinner").hide();
                            $(".gridspinner").hide();
                            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                            $('.birdmessagebuttons').removeClass('show');
                            $('.birdmessagebuttons').addClass('hide');
                            $('.birdmessage-center').addClass("message-top-in");
                            $('.details').removeClass('show');
                            $('.details').addClass('hide');
                            $('.birdmessage h2').empty().append('Warning');
                            $('.birdmessage-info').empty().text(lang["storyboard.mulaxischarts"] + exception.message);

                            /*$('.fullscreenmain').prepend('<div class="alert alert-danger birderror alert-dismissable affix-top" style="margin:0 auto;width: 60%;top: 44px;position: fixed;z-index: 1;left: 20%;"> Multiple X-Axis is not allowed for "'+ exception.message +'" </div>');
                                 setTimeout(function() {
                                     $('.birderror').remove();	
                                 }, 3000);*/
                        }
                    }
                }
            }
            if (activeChartType == "leaflet" || activeChartType == "world" || activeChartType == "srilankamap" || activeChartType == "countrymap" || activeChartType == "indiamap") {
                $(".xaxisattributes .singleliitems .aggregationtext").removeClass("hidden");
            }
            else {
                $(".xaxisattributes .singleliitems .aggregationtext").addClass("hidden");
            }
        }
        this.validatetimeseriesxaxis(event);
    }
    validatetimeseriesxaxis(e) {
        var chartType = $(".charttype.active").attr("id");
        if (chartType === "bartimeseries" || chartType === "linetimeseries" || chartType === "areatimeseries") {
            if ($('.xaxisattributes li').attr("data-type") != "date" && $('.xaxisattributes li').attr("data-type") != "datetime") {
                $(".spinner").hide();
                $(".gridspinner").hide();
                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Warning');
                $('.birdmessage-info').empty().text(getMessage('BirdWarning14'));
                /*$('.fullscreenmain').prepend('<div class="alert alert-danger birderror alert-dismissable affix-top" style="margin:0 auto;width: 60%;top: 44px;position: fixed;z-index: 1;left: 20%;">Time Series Charts Requires Date Attribute on X-Axis</div>');
                    setTimeout(function() {
                        $('.birderror').remove();	
                    }, 3000);*/
                $("#itemdisplay").remove();
                $(e.currentTarget).removeClass("active");
                $('.charticons[data-toggle="collapse"]').removeClass('active');
            }
        }
    }

    showzAxisDropDown() {
        var that = this;
        $(".zaxisdropdown").empty();
        $(".zaxisdropdown").append('<li class="list-title">'+lang["storyboard.axisz"]+'</li><li class="xs-list-item"> <input class="form-control form-control-zaxisdropdown search-image" placeholder='+ lang["storyboard.search"]+' id="searchinput" type="text"></li>');
        $(".zaxisdropdown").append('<span class="axis-list-items"></span>');
        var mincount = 1000;
        var maxcount = 1040;
        _.each($(".attributesmeasures").slice(0, 1000), function (e) {
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $(".zaxisdropdown>.axis-list-items").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            var $this = e;
            var zAxisDropDown = new ZAxisDropDownView({
                dynamicdiv: dynamicdiv,
                addzaxisvalue: that.addzaxisvalue,
                columnDisplayName: $($this).attr('title'),
                columnName: $($this).attr("data-name"),
                dataType: $($this).attr("data-type"),
                tableName: $($this).attr("table-name"),
                aggregation: $($this).find("#dropdownaggregation").text()==''||$($this).find("#dropdownaggregation").text()==undefined?'count' :$($this).find("#dropdownaggregation").text(),
                percentileValue: $($this).find(".percentilebox").val(),
                facttables: that.state.facttables,
                multifact: that.state.multifact,
                multifactentities: that.state.multifactentities,
            });
            $(".zaxisdropdown>.axis-list-items").append($("#" + dynamicdiv));
            $('.form-control-zaxisdropdown').click(function (e) {
                e.stopPropagation();
            });
        });

        $(".zaxisdropdown>.axis-list-items").scroll(function () {
            var searchVal = $(".form-control-zaxisdropdown").val();
            if (($(".zaxisdropdown>.axis-list-items").scrollTop() + $(".zaxisdropdown>.axis-list-items").height() + 25 >= $(".zaxisdropdown>.axis-list-items")[0].scrollHeight) && searchVal == "") {
                _.each($(".attributesmeasures").slice(mincount, maxcount), function (e) {
                    var today = new Date();
                    var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                    dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                    $(".zaxisdropdown>.axis-list-items").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                    var $this = e;
                    var zAxisDropDown = new ZAxisDropDownView({
                        dynamicdiv: dynamicdiv,
                        addzaxisvalue: that.addzaxisvalue,
                        columnDisplayName: $($this).attr('title'),
                        columnName: $($this).attr("data-name"),
                        dataType: $($this).attr("data-type"),
                        tableName: $($this).attr("table-name"),
                        aggregation: $($this).find("#dropdownaggregation").text(),
                        percentileValue: $($this).find(".percentilebox").val(),
                        multifact: that.state.multifact,
                        facttables: that.state.facttables,
                        multifactentities: that.state.multifactentities,
                    });
                    $(".zaxisdropdown>.axis-list-items").append($("#" + dynamicdiv));
                    $('.form-control-zaxisdropdown').click(function (e) {
                        e.stopPropagation();
                    });
                });
                mincount = mincount + 20;
                maxcount = maxcount + 20;
            }
        });
        $('.form-control-zaxisdropdown').keyup(function (e) {
            e.stopPropagation();
            var $this = e.currentTarget;
            $("ul.zaxisdropdown li.searchresult").remove();
            setTimeout(function () {
                var valThis = $($this).val().toLowerCase();
                $(".itemname").parents(".zaxisitem:not('.searchresult')").hide();
                $("ul.zaxisdropdown li.searchresult").remove();
                if (valThis != "" && valThis != undefined) {
                    $(".attributesmeasures").each(function () {
                        var text = $(this).find(".itemname").text().toLowerCase();
                        if (text.indexOf(valThis) >= 0) {
                            var today = new Date();
                            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                            $(".zaxisdropdown>.axis-list-items").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                            var zAxisDropDown = new ZAxisDropDownView({
                                dynamicdiv: dynamicdiv,
                                addzaxisvalue: that.addzaxisvalue,
                                columnDisplayName: $(this).attr('title'),
                                columnName: $(this).attr("data-name"),
                                dataType: $(this).attr("data-type"),
                                tableName: $(this).attr("table-name"),
                                aggregation: $(this).find("#dropdownaggregation").text(),
                                percentileValue: $(this).find(".percentilebox").val(),
                                multifact: that.state.multifact,
                                facttables: that.state.facttables,
                                multifactentities: that.state.multifactentities,
                            });
                            var result = $("#" + dynamicdiv).find("li").addClass("searchresult");
                            $(".zaxisdropdown>.axis-list-items").append(result);

                        }
                    });
                } else {
                    $(".itemname").parents(".zaxisitem:not('searchresult')").show();
                    $("ul.zaxisdropdown li.searchresult").remove();
                }
            }, 500);
        });
    }
    addzaxisvalue = (event) => {
        var $this = event.currentTarget;
        var that = this;
        var isZaxisalreadyExist = false;
        _.each($('.zaxisattributes .singleliitems'), function (zxisli) {
            if ($(zxisli).attr("data-name") == $($this).attr("data-name")) {
                isZaxisalreadyExist = true;
            }
        });
        if (isZaxisalreadyExist == false) {
            if (!$($this).hasClass('active')) {
                var columnName = $($this).attr("data-name");
                var columnDisplayName = $($this).find('.itemname').text();
                var datatype = $($this).attr("data-type");
                var aggregation = $($this).find(".boclabel-info").text().replace(/[^A-Z0-9]/ig, "");
                var tableName = $($this).attr("table-name");
                var percentileValue = $($this).attr("percentile-value");
                var today = new Date();
                var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                $(".zaxisattributesdummy").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                var zAxisAttrView = new ZAxisAttrView({
                    dynamicdiv: dynamicdiv,
                    yaxiscolumnDisplayName: columnDisplayName,
                    yaxiscolumnName: columnName,
                    yaxiscolumnType: datatype,
                    yaxisaggregation: aggregation,
                    yaxistableName: tableName,
                    percentileValue: percentileValue,
                    isStoredProcedure: ChartInfo.functions.getIsStoredProcedure(),
                    isDirect: ChartInfo.functions.getIsDirect(),
                    facttables: that.state.facttables,
                    multifact: that.state.multifact,
                    multifactentities: that.state.multifactentities,
                });
                var activeChartType = $('.charttype.active').attr("id");
                if ($(".zaxisattributes li").length > 1 && activeChartType === "bubble") {
                    $(".spinner").hide();
                    $(".gridspinner").hide();
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('show');
                    $('.details').addClass('hide');
                    $('.birdmessage h2').empty().append('Warning');
                    $('.birdmessage-info').empty().text(lang["storyboard.Z-axisallowchart"]);
                } else {
                    $(".zaxisattributes").append($("#" + dynamicdiv));
                }

            }
        }
    }
    showLegendSeriesDropDown() {
        var that = this;
        $(".legenddropdown").empty();
        $(".legenddropdown").append('<li class="list-title">'+lang["storyboard.LEGEND"]+'</li><li class="xs-list-item"> <input class="form-control form-control-legenddropdown search-image" placeholder='+ lang["storyboard.search"]+' id="searchinput" type="text"></li>');
        $(".legenddropdown").append('<span class="axis-list-items"></span>');
        var mincount = 1000;
        var maxcount = 1040;
        _.each($(".attributesdimensions").slice(0, 1000), function (e) {
            var $this = e;
            var customfield = 0
            if ($($this).hasClass("attributescustomfields")) {
                customfield = 1
            } else if ($($this).hasClass("customhierarchyfields")) {
                customfield = 2
            } else if ($($this).hasClass("customrangefields")) {
                customfield = 3
            }
            if (!$($this).hasClass("customhierarchyfields")) {
                var today = new Date();
                var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                $(".legenddropdown>.axis-list-items").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                var legendDropDown = new LegendDropDownView({
                    dynamicdiv: dynamicdiv,
                    addlegendsvalue: that.addlegendsvalue,
                    columnDisplayName: $($this).attr('title'),
                    columnName: $($this).attr("data-name"),
                    dataType: $($this).attr("data-type"),
                    tableName: $($this).attr("table-name"),
                    customfield: customfield,
                    multifact: that.state.multifact,
                    facttables: that.state.facttables,
                    multifactentities: that.state.multifactentities,
                });
                $(".legenddropdown>.axis-list-items").append($("#" + dynamicdiv));
                $('.form-control-legenddropdown').click(function (e) {
                    e.stopPropagation();
                });
            }
        });

        $(".legenddropdown>.axis-list-items").scroll(function () {
            var searchVal = $(".form-control-legenddropdown").val();
            if (($(".legenddropdown>.axis-list-items").scrollTop() + $(".legenddropdown>.axis-list-items").height() + 25 >= $(".legenddropdown>.axis-list-items")[0].scrollHeight) && searchVal == "") {
                _.each($(".attributesdimensions").slice(mincount, maxcount), function (e) {
                    var $this = e;
                    var customfield = 0
                    if ($($this).hasClass("attributescustomfields")) {
                        customfield = 1
                    } else if ($($this).hasClass("customhierarchyfields")) {
                        customfield = 2
                    } else if ($($this).hasClass("customrangefields")) {
                        customfield = 3
                    }
                    if (!$($this).hasClass("customhierarchyfields")) {
                        var today = new Date();
                        var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                        $(".legenddropdown>.axis-list-items").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                        var legendDropDown = new LegendDropDownView({
                            dynamicdiv: dynamicdiv,
                            addlegendsvalue: that.addlegendsvalue,
                            columnDisplayName: $($this).attr('title'),
                            columnName: $($this).attr("data-name"),
                            dataType: $($this).attr("data-type"),
                            tableName: $($this).attr("table-name"),
                            customfield: customfield,
                            multifact: that.state.multifact,
                            facttables: that.state.facttables,
                            multifactentities: that.state.multifactentities,
                        });
                        $(".legenddropdown>.axis-list-items").append($("#" + dynamicdiv));
                        $('.form-control-legenddropdown').click(function (e) {
                            e.stopPropagation();
                        });
                    }
                });
                mincount = mincount + 20;
                maxcount = maxcount + 20;
            }
        })
        $('.form-control-legenddropdown').keyup(function (e) {
            e.stopPropagation();
            var $this = e.currentTarget;
            $("ul.legenddropdown li.searchresult").remove();
            setTimeout(function () {
                var valThis = $($this).val().toLowerCase();
                $(".itemname").parents(".legenddropdownitem:not('.searchresult')").hide();
                $("ul.legenddropdown li.searchresult").remove();
                if (valThis != "" && valThis != undefined) {
                    $(".attributesdimensions").each(function () {
                        var $this = e;
                        var customfield = 0
                        if ($($this).hasClass("attributescustomfields")) {
                            customfield = 1
                        } else if ($($this).hasClass("customhierarchyfields")) {
                            customfield = 2
                        } else if ($($this).hasClass("customrangefields")) {
                            customfield = 3
                        }
                        var text = $(this).find(".itemname").text().toLowerCase();
                        if (!$($this).hasClass("customhierarchyfields") && $(this).attr("data-type") != "customhierarchy") {
                            if (text.indexOf(valThis) >= 0) {
                                var today = new Date();
                                var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                                $(".legenddropdown>.axis-list-items").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                                var legendDropDown = new LegendDropDownView({
                                    dynamicdiv: dynamicdiv,
                                    addlegendsvalue: that.addlegendsvalue,
                                    columnDisplayName: $(this).attr('title'),
                                    columnName: $(this).attr("data-name"),
                                    dataType: $(this).attr("data-type"),
                                    tableName: $(this).attr("table-name"),
                                    customfield: customfield,
                                    facttables: that.state.facttables,
                                    multifact: that.state.multifact,
                                    multifactentities: that.state.multifactentities,
                                });
                                var result = $("#" + dynamicdiv).find("li").addClass("searchresult");
                                $(".legenddropdown>.axis-list-items").append(result);
                            }
                        }
                    });
                } else {
                    $(".itemname").parents(".legenddropdownitem:not('searchresult')").show();
                    $("ul.legenddropdown li.searchresult").remove();
                }
            }, 500);
        });
    }
    addlegendsvalue = (event) => {
        var $this = event.currentTarget;
        var that = this;
        if (!$($this).hasClass('active')) {
            var columnName = $($this).attr("data-name");
            var columnDisplayName = $($this).find(".itemname").text().trim();
            var datatype = $($this).attr("data-type");
            var tableName = $($this).attr("table-name");
            var iscustomfunction = $($this).attr("iscustomfunction");

            if ($(".legendAttributes li").length === 0) {
                var today = new Date();
                var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                $(".legendAttributes").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                var legendattributeview = new LegendAttrView({
                    dynamicdiv: dynamicdiv,
                    columnDisplayName: columnDisplayName,
                    columnName: columnName,
                    columnType: datatype,
                    tableName: tableName,
                    iscustomfunction: iscustomfunction,
                    multifact: that.state.multifact,
                    facttables: that.state.facttables,
                    multifactentities: that.state.multifactentities,
                });
                $(".legendAttributes").append($("#" + dynamicdiv));
            }
            else {
                if ($('.charttype.active').attr("id") == "heatmap") {
                    $(".spinner").hide();
                    $(".gridspinner").hide();
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('show');
                    $('.details').addClass('hide');
                    $('.birdmessage h2').empty().append('Warning');
                    $('.birdmessage-info').empty().text(getMessage('BirdWarning53'));
                }
                else {
                    $(".spinner").hide();
                    $(".gridspinner").hide();
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('show');
                    $('.details').addClass('hide');
                    $('.birdmessage h2').empty().append('Warning');
                    $('.birdmessage-info').empty().text(getMessage('BirdWarning05'));
                }
            }
        }
    }
    showtooltipDropDown() {
        var that = this;
        $(".tooltipdropdown").empty();
        $(".tooltipdropdown").append('<li class="list-title">'+lang["storyboard.tooltip"]+'</li><li class="xs-list-item"> <input class="form-control form-control-tooltipdropdown search-image" placeholder='+ lang["storyboard.search"]+' id="searchinput" type="text"></li>');
        $(".tooltipdropdown").append('<span class="axis-list-items"></span>');
        var mincount = 1000;
        var maxcount = 1040;
        _.each($(".attributesmeasures").slice(0, 1000), function (e) {
            var $this = e;
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $(".tooltipdropdown>.axis-list-items").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            var tooltipDropDown = new tooltipDropDownView({
                dynamicdiv: dynamicdiv,
                addtooltipvalue: that.addtooltipvalue,
                columnDisplayName: $($this).attr('title'),
                columnName: $($this).attr("data-name"),
                dataType: $($this).attr("data-type"),
                tableName: $($this).attr("table-name"),
                //					aggregation :$($this).find("#dropdownaggregation").text(),
                aggregation: 'sum',
                percentileValue: $($this).find(".percentilebox").val(),
                multifact: that.state.multifact,
                facttables: that.state.facttables,
                multifactentities: that.state.multifactentities,
            });
            $(".tooltipdropdown>.axis-list-items").append($("#" + dynamicdiv));
            $('.form-control-tooltipdropdown').click(function (e) {
                e.stopPropagation();
            });
        });

        $(".tooltipdropdown>.axis-list-items").scroll(function () {
            var searchVal = $(".form-control-tooltipdropdown").val();
            if (($(".tooltipdropdown>.axis-list-items").scrollTop() + $(".tooltipdropdown>.axis-list-items").height() + 25 >= $(".tooltipdropdown>.axis-list-items")[0].scrollHeight) && searchVal == "") {
                _.each($(".attributesmeasures").slice(mincount, maxcount), function (e) {
                    var $this = e;
                    var today = new Date();
                    var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                    dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                    $(".tooltipdropdown>.axis-list-items").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                    var tooltipDropDown = new tooltipDropDownView({
                        dynamicdiv: dynamicdiv,
                        addtooltipvalue: that.addtooltipvalue,
                        columnDisplayName: $($this).attr('title'),
                        columnName: $($this).attr("data-name"),
                        dataType: $($this).attr("data-type"),
                        tableName: $($this).attr("table-name"),
                        //							aggregation :$($this).find("#dropdownaggregation").text(),
                        aggregation: 'sum',
                        percentileValue: $($this).find(".percentilebox").val(),
                        multifact: that.state.multifact,
                        facttables: that.state.facttables,
                        multifactentities: that.state.multifactentities,
                    });
                    $(".tooltipdropdown>.axis-list-items").append($("#" + dynamicdiv));
                    $('.form-control-tooltipdropdown').click(function (e) {
                        e.stopPropagation();
                    });
                });
                mincount = mincount + 20;
                maxcount = maxcount + 20;
            }
        });
        $('.form-control-tooltipdropdown').keyup(function (e) {
            e.stopPropagation();
            var $this = e.currentTarget;
            $("ul.tooltipdropdown li.searchresult").remove();
            setTimeout(function () {
                var valThis = $($this).val().toLowerCase();
                $(".itemname").parents(".tooltipitem:not('.searchresult')").hide();
                $("ul.tooltipdropdown li.searchresult").remove();
                if (valThis != "" && valThis != undefined) {
                    $(".attributesmeasures").each(function () {
                        var text = $(this).find(".itemname").text().toLowerCase();
                        if (text.indexOf(valThis) >= 0) {
                            var today = new Date();
                            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                            $(".tooltipdropdown>.axis-list-items").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                            var tooltipDropDown = new tooltipDropDownView({
                                dynamicdiv: dynamicdiv,
                                addtooltipvalue: that.addtooltipvalue,
                                columnDisplayName: $(this).attr('title'),
                                columnName: $(this).attr("data-name"),
                                dataType: $(this).attr("data-type"),
                                tableName: $(this).attr("table-name"),
                                aggregation: 'count',
                                percentileValue: $(this).find(".percentilebox").val(),
                                multifact: that.state.multifact,
                                facttables: that.state.facttables,
                                multifactentities: that.state.multifactentities,
                            });
                            var result = $("#" + dynamicdiv).find("li").addClass("searchresult");
                            $(".tooltipdropdown>.axis-list-items").append(result);
                        }
                    });
                } else {
                    $(".itemname").parents(".tooltipitem:not('searchresult')").show();
                    $("ul.tooltipdropdown li.searchresult").remove();
                }
            }, 500);
        });
    }
    addtooltipvalue = (event) => {
        var that = this;
        var $this = event.currentTarget;
        var istooltipalreadyExist = false;
        _.each($('.tooltipattributes .singleliitems'), function (tooltipli) {
            if ($(tooltipli).attr("data-name") == $($this).attr("data-name")) {
                istooltipalreadyExist = true;
            }
        });
        if (istooltipalreadyExist == false) {
            if (!$($this).hasClass('active')) {
                var columnName = $($this).attr("data-name");
                var columnDisplayName = $($this).find('.itemname').text();
                var datatype = $($this).attr("data-type");
                var aggregation = $($this).find(".boclabel-info").attr("title")
                var tableName = $($this).attr("table-name");
                var percentileValue = $($this).attr("percentile-value");
                var today = new Date();
                var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                $(".tooltipattributes").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                var TooltipAttrView = new tooltipAttrView({
                    dynamicdiv: dynamicdiv,
                    yaxiscolumnDisplayName: columnDisplayName,
                    yaxiscolumnName: columnName,
                    yaxiscolumnType: datatype,
                    yaxisaggregation: aggregation,
                    yaxistableName: tableName,
                    percentileValue: percentileValue,
                    multifact: that.state.multifact,
                    facttables: that.state.facttables,
                    multifactentities: that.state.multifactentities,
                });
                $(".tooltipattributes").append($("#" + dynamicdiv));
            }
        }
    }

    showReferenceLineDropDown() {
        var that = this;
        that.state.referenceArray = [];
        $(".referencelinedropdown").empty();
        $(".referencelinedropdown").append('<li class="list-title">'+lang["storyboard.referenceline"]+'</li><li class="xs-list-item"> <input class="form-control form-control-referencelinedropdown search-image" placeholder='+ lang["storyboard.search"]+' id="searchinput" type="text"></li>');
        //   $(".referencelinedropdown").append("<li title=CustomLine data-type='CustomLine' data-name=CustomLine class='xs-list-item referenceline'  percentile-value=25><span class='itemtype'><i class='fa fa-pencil'></i></span><span class=itemname>Custom Line</span></li>");
        $(".referencelinedropdown").append('<span class="axis-list-items"></span>');
        var mincount = 1000;
        var maxcount = 1040;

        var today = new Date();
        var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
        $(".referencelinedropdown>.axis-list-items").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
        var referenceLineDropDown = new YAxisDropDownView({
            dynamicdiv: dynamicdiv,
            addyaxisvalue: that.addyaxisvalue,
            addreferencevalue: that.addreferencevalue,
            columnDisplayName: "CustomLine",
            columnName: "CustomLine",
            referencecolumnName: "CustomLine",
            dataType: "CustomLine",
            tableName: "CustomLine",
            aggregation: 'count',
            classname: "referenceline",
            percentileValue: "25",
            multifact: that.state.multifact,
            facttables: that.state.facttables,
            isfromreferenceline: true,
            multifactentities: that.state.multifactentities,
        });
        $(".referencelinedropdown>.axis-list-items").append($("#" + dynamicdiv));

        _.each($(".attributesmeasures").slice(0, 1000), function (e) {
            var $this = e;
            if ($($this).attr('data-type') != "custommeasurehierarchy" && $($this).attr('data-type') != "customaggregatedfield") {
                var today = new Date();
                var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                $(".referencelinedropdown>.axis-list-items").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                var referenceLineDropDown = new YAxisDropDownView({
                    dynamicdiv: dynamicdiv,
                    addyaxisvalue: that.addyaxisvalue,
                    addreferencevalue: that.addreferencevalue,
                    columnDisplayName: $($this).attr('title'),
                    columnName: $($this).attr("data-name"),
                    referencecolumnName: $($this).attr("column-name"),
                    dataType: $($this).attr("data-type"),
                    tableName: $($this).attr("table-name"),
                    aggregation: 'count',
                    classname: "referenceline",
                    percentileValue: $($this).find(".percentilebox").val(),
                    multifact: that.state.multifact,
                    facttables: that.state.facttables,
                    isfromreferenceline: true,
                    multifactentities: that.state.multifactentities,
                });

                $(".referencelinedropdown>.axis-list-items").append($("#" + dynamicdiv));
            }
            $('.form-control-referencelinedropdown').click(function (e) {
                e.stopPropagation();
            });
        });

        $(".referencelinedropdown>.axis-list-items").scroll(function () {
            var searchVal = $(".form-control-referencelinedropdown").val();
            if (($(".referencelinedropdown>.axis-list-items").scrollTop() + $(".referencelinedropdown>.axis-list-items").height() + 25 >= $(".referencelinedropdown>.axis-list-items")[0].scrollHeight) && searchVal == "") {
                _.each($(".attributesmeasures").slice(mincount, maxcount), function (e) {
                    var $this = e;
                    if ($($this).attr('data-type') != "custommeasurehierarchy" && $($this).attr('data-type') != "customaggregatedfield") {
                        var today = new Date();
                        var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                        $(".referencelinedropdown>.axis-list-items").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                        var referenceLineDropDown = new YAxisDropDownView({
                            dynamicdiv: dynamicdiv,
                            addyaxisvalue: that.addyaxisvalue,
                            addreferencevalue: that.addreferencevalue,
                            columnDisplayName: $($this).attr('title'),
                            columnName: $($this).attr("data-name"),
                            referencecolumnName: $($this).attr("column-name"),
                            dataType: $($this).attr("data-type"),
                            tableName: $($this).attr("table-name"),
                            aggregation: 'count',
                            classname: "referenceline",
                            percentileValue: $($this).find(".percentilebox").val(),
                            multifact: that.state.multifact,
                            facttables: that.state.facttables,
                            isfromreferenceline: true,
                            multifactentities: that.state.multifactentities,
                        });
                        $(".referencelinedropdown>.axis-list-items").append($("#" + dynamicdiv));
                    }
                    $('.form-control-referencelinedropdown').click(function (e) {
                        e.stopPropagation();
                    });
                });
                mincount = mincount + 20;
                maxcount = maxcount + 20;
            }
        });
        $('.form-control-referencelinedropdown').keyup(function (e) {
            e.stopPropagation();
            var $this = e.currentTarget;
            $("ul.referencelinedropdown li.searchresult").remove();
            setTimeout(function () {
                var valThis = $($this).val().toLowerCase();
                $(".itemname").parents(".referenceline:not('.searchresult')").hide();
                $("ul.referencelinedropdown li.searchresult").remove();
                if (valThis != "" && valThis != undefined) {
                    $(".attributesmeasures:not('.customaggregatedfields'):not('.custommeasurehierarchyfields')").each(function () {
                        var text = $(this).find(".itemname").text().toLowerCase();
                        if (text.indexOf(valThis) >= 0) {
                            var today = new Date();
                            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                            $(".referencelinedropdown>.axis-list-items").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                            var yAxisDropDown = new YAxisDropDownView({
                                dynamicdiv: dynamicdiv,
                                addyaxisvalue: that.addyaxisvalue,
                                addreferencevalue: that.addreferencevalue,
                                columnDisplayName: $(this).attr('title'),
                                columnName: $(this).attr("data-name"),
                                dataType: $(this).attr("data-type"),
                                tableName: $(this).attr("table-name"),
                                aggregation: 'count',
                                classname: "referenceline",
                                percentileValue: $(this).find(".percentilebox").val(),
                                multifact: that.state.multifact,
                                facttables: that.state.facttables,
                                multifactentities: that.state.multifactentities,
                            });
                            var result = $("#" + dynamicdiv).find("li").addClass("searchresult referenceline").removeClass("measureitem");
                            $(".referencelinedropdown>.axis-list-items").append(result);
                        }
                    });
                } else {
                    $(".itemname").parents(".referenceline:not('searchresult')").show();
                    $("ul.referencelinedropdown li.searchresult").remove();
                }
            }, 500);
        });
    }
    addreferencevalue = (event) => {
        var $this = event.currentTarget;
        var isYaxisalreadyExist = false;
        $("input#referenceLine[type='checkbox']").prop('checked', true);
        if (isYaxisalreadyExist == false) {
            if (!$($this).hasClass('active')) {
                var columnName = $($this).attr("data-name");
                var referenceColumnName = $($this).attr("data-name");
                var columnDisplayName = $($this).find('.itemname').text();
                var datatype = $($this).attr("data-type");
                var aggregation = $($this).find(".boclabel-info").text().replace(/[^A-Z0-9]/ig, "");
                var percentileValue = $($this).attr("percentile-value");
                var activeChartType = $('.charttype.active').attr("id");
                var id = $(".referenceattributes .singleliitems:last").attr("id");
                if (id == undefined) {
                    id = 0;
                } else {
                    id = parseInt(id) + 1;
                }
                var today = new Date();
                var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                $(".referenceattributes").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                var yAxisAttrView = new YAxisAttrView({
                    dynamicdiv: dynamicdiv,
                    yaxiscolumnDisplayName: columnDisplayName,
                    yaxiscolumnName: columnName,
                    yaxiscolumnType: datatype,
                    yaxisaggregation: aggregation,
                    referenceColumnName: referenceColumnName,
                    percentileValue: percentileValue,
                    measures: this.state.measures,
                    dimensions: this.state.dimensions,
                    addreferencelinehtml: true,
                    id: id,
                    chartnumber: this.state.chartnumber,
                    multifact: this.state.multifact,
                    facttables: this.state.facttables,
                    multifactentities: this.state.multifactentities,
                });
                $(".referenceattributes").append($("#" + dynamicdiv));
                // $("#"+dynamicdiv).find('.reference-color').colorpicker({format:"rgba"}).on('changeColor.colorpicker', function(event){
                //     var $this=event.currentTarget;
                //     var currentValue = $($this).find('input').val();
                //     $($this).find(".input-group-addon>i").css("background-color",currentValue);
                // });
                $(document).on('click', '.colorpicker', function (e) {
                    e.stopPropagation();
                });
                $("input#referenceLine[type='checkbox']").prop('checked', true);

            }
        }
    }
    showMetricDateFieldDropDown() {
        var that = this;
        $(".metricdatedropdown").empty();
        _.each($(".attributesdimensions"), function (e) {
            var $this = e;
            if ($($this).attr("data-type") === "date" || $($this).attr("data-type") === "datetime") {
                var today = new Date();
                var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                $(".metricdatedropdown").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                var metricDateFieldDropDown = new MetricDateFieldDropDownView({
                    dynamicdiv: dynamicdiv,
                    addmetricdatefieldvalue: that.addmetricdatefieldvalue,
                    columnDisplayName: $($this).find('.itemname').text(),
                    columnName: $($this).attr("data-name"),
                    dataType: $($this).attr("data-type"),
                    multifact: that.state.multifact,
                    facttables: that.state.facttables,
                    multifactentities: that.state.multifactentities,
                });
                $(".metricdatedropdown").append($("#" + dynamicdiv));
            }
        });
    }
    addmetricdatefieldvalue = (event) => {
        var $this = event.currentTarget;
        var that = this;
        if (!$($this).hasClass('active')) {
            var columnName = $($this).attr("data-name");
            var columnDisplayName = $($this).find('.itemname').text();
            var datatype = $($this).attr("data-type");
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $(".metricdatefieldsAttributes").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            if ($(".metricdatefieldsAttributes li").length === 0) {
                var metricdatefieldattributeview = new MetricDateFieldAttrView({
                    dynamicdiv: dynamicdiv,
                    columnDisplayName: columnDisplayName,
                    columnName: columnName,
                    columnType: datatype,
                    viewmode: 'values',
                    multifact: that.state.multifact,
                    facttables: that.state.facttables,
                    multifactentities: that.state.multifactentities,
                });
                $(".metricdatefieldsAttributes").append($("#" + dynamicdiv));
            }
            else {
                $(".spinner").hide();
                $(".gridspinner").hide();
                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Warning');
                $('.birdmessage-info').empty().text(getMessage('BirdWarning42'));
            }
        }
    }
    showCustomSolidGaugeDropDown() {
        var that = this;
        $(".customsolidgaugedropdown").empty();
        $(".customsolidgaugedropdown").append('<li class="list-title">'+lang["storyboard.customvalue"]+'</li><li class="xs-list-item"> <input class="form-control form-control-customsolidgaugedropdown search-image" placeholder='+ lang["storyboard.search"]+' id="searchinput" type="text"></li>');
        $(".customsolidgaugedropdown").append('<span class="axis-list-items"></span>');
        _.each($(".attributesmeasures"), function (e) {
            var $this = e;
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $(".customsolidgaugedropdown>.axis-list-items").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            var customSolidGaugeDropDownView = new CustomSolidGaugeDropDownView({
                dynamicdiv: dynamicdiv,
                addcustomsolidgaugevalue: that.addcustomsolidgaugevalue,
                columnDisplayName: $($this).attr('title'),
                columnName: $($this).attr("data-name"),
                dataType: $($this).attr("data-type"),
                tableName: $($this).attr("table-name"),
                aggregation: $($this).find("#dropdownaggregation").text()!=undefined && $($this).find("#dropdownaggregation").text()!="" ? $($this).find("#dropdownaggregation").text():"count",
                percentileValue: $($this).find(".percentilebox").val(),
                multifact: that.state.multifact,
                facttables: that.state.facttables,
                multifactentities: that.state.multifactentities,
            });
            $(".customsolidgaugedropdown>.axis-list-items").append($("#" + dynamicdiv));
            $('.form-control-customsolidgaugedropdown').click(function (e) {
                e.stopPropagation();
            });
        });
        $('.form-control-customsolidgaugedropdown').keyup(function (e) {
            e.stopPropagation();
            var $this = e.currentTarget;
            var valThis = $($this).val().toLowerCase();
            $(".itemname").parents(".customsolidgaugeitem").each(function () {
                var text = $(this).text().toLowerCase();
                (text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
            });

        });
    }
    addcustomsolidgaugevalue = (event) => {
        var $this = event.currentTarget;
        var that = this;
        var isCustomSolidGaugeAlreadyExist = false;
        _.each($('.customsolidgaugeattributes .singleliitems'), function (zxisli) {
            if ($(zxisli).attr("data-name") == $($this).attr("data-name")) {
                isCustomSolidGaugeAlreadyExist = true;
            }
        });
        if (isCustomSolidGaugeAlreadyExist == false) {
            if (!$($this).hasClass('active')) {
                var that = this;
                var columnName = $($this).attr("data-name");
                var columnDisplayName = $($this).find('.itemname').text();
                var datatype = $($this).attr("data-type");
                var aggregation = $($this).find(".boclabel-info").text()
                var tableName = $($this).attr("table-name");
                var percentileValue = $($this).attr("percentile-value");
                var today = new Date();
                var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                $(".customsolidgaugeattributes").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                var customSolidGaugeAttributeView = new CustomSolidGaugeAttributeView({
                    dynamicdiv: dynamicdiv,
                    columnDisplayName: columnDisplayName,
                    columnName: columnName,
                    columnType: datatype,
                    aggregation: aggregation,
                    tableName: tableName,
                    percentileValue: percentileValue,
                    multifact: that.state.multifact,
                    facttables: that.state.facttables,
                    multifactentities: that.state.multifactentities,
                });
                $(".customsolidgaugeattributes").append($("#" + dynamicdiv));
            }
        }
    }

    showyAxisDropDowninSorting() {
        var that = this;
        $(".sortingdropdown").empty();
        $(".sortingdropdown").append('<li class="list-title">'+lang["storyboard.sortby"]+'</li><li class="xs-list-item"> <input class="form-control form-control-sortingdropdown search-image" placeholder='+ lang["storyboard.search"]+' id="searchinput" type="text"></li>');
        $(".sortingdropdown").append('<span class="axis-list-items"></span>')
        var mincount = 1020;
        var maxcount = 1040;
        _.each($(".attributesmeasures").slice(0, 1000), function (e) {
            var $this = e;
            if ($($this).attr("data-type") == "custommeasurehierarchy" || $($this).attr("data-type") == "customaggregatedfield") {
            }
            else {
                var today = new Date();
                var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                $(".sortingdropdown>.axis-list-items").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                var yAxisDropDown = new YAxisDropDownView({
                    dynamicdiv: dynamicdiv,
                    addyaxisvalue: that.addsortingvalue,
                    columnDisplayName: $($this).attr('title'),
                    columnName: $($this).attr("data-name"),
                    dataType: $($this).attr("data-type"),
                    tableName: $($this).attr("table-name"),
                    aggregation: $($this).find("#dropdownaggregation").text()==""?"count":$($this).find("#dropdownaggregation").text(),
                    percentileValue: $($this).find(".percentilebox").val(),
                    multifact: that.state.multifact,
                    facttables: that.props.facttables,
                    isfromsorting: true,
                    multifactentities: that.state.multifactentities,
                });
                $(".sortingdropdown>.axis-list-items").append($("#" + dynamicdiv));
                $('.form-control-sortingdropdown').click(function (e) {
                    e.stopPropagation();
                });
            }
        });

        $(".sortingdropdown>.axis-list-items").scroll(function () {
            var searchVal = $(".form-control-sortingdropdown").val();
            if (($(".sortingdropdown>.axis-list-items").scrollTop() + $(".sortingdropdown>.axis-list-items").height() + 25 >= $(".sortingdropdown>.axis-list-items")[0].scrollHeight) && searchVal == "") {
                _.each($(".attributesmeasures").slice(mincount, maxcount), function (e) {
                    var $this = e;
                    if ($($this).attr("data-type") == "custommeasurehierarchy" || $($this).attr("data-type") == "customaggregatedfield") {

                    }
                    else {
                        var today = new Date();
                        var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                        $(".sortingdropdown>.axis-list-items").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                        var yAxisDropDown = new YAxisDropDownView({
                            dynamicdiv: dynamicdiv,
                            addyaxisvalue: that.addsortingvalue,
                            columnDisplayName: $($this).attr('title'),
                            columnName: $($this).attr("data-name"),
                            dataType: $($this).attr("data-type"),
                            tableName: $($this).attr("table-name"),
                            aggregation: $($this).find("#dropdownaggregation").text(),
                            percentileValue: $($this).find(".percentilebox").val(),
                            multifact: that.state.multifact,
                            facttables: that.props.facttables,
                            isfromsorting: true,
                            multifactentities: that.state.multifactentities,
                        });
                        $(".sortingdropdown>.axis-list-items").append($("#" + dynamicdiv));
                        $('.form-control-sortingdropdown').click(function (e) {
                            e.stopPropagation();
                        });
                    }
                });
                mincount = mincount + 20;
                maxcount = maxcount + 20;
            }
        });
        $('.form-control-sortingdropdown').keyup(function (e) {
            e.stopPropagation();
            var $this = e.currentTarget;
            $("ul.sortingdropdown li.searchresult").remove();
            setTimeout(function () {
                var valThis = $($this).val().toLowerCase();
                $(".itemname").parents(".measureitem:not('.searchresult')").hide();
                $("ul.sortingdropdown li.searchresult").remove();
                if (valThis != "" && valThis != undefined) {
                    $(".attributesmeasures").each(function () {
                        var text = $(this).find(".itemname").text().toLowerCase();
                        if (text.indexOf(valThis) >= 0) {
                            var today = new Date();
                            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                            $(".sortingdropdown>.axis-list-items").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                            var yAxisDropDown = new YAxisDropDownView({
                                dynamicdiv: dynamicdiv,
                                columnDisplayName: $(this).attr('title'),
                                columnName: $(this).attr("data-name"),
                                dataType: $(this).attr("data-type"),
                                tableName: $(this).attr("table-name"),
                                aggregation: 'count',
                                percentileValue: $(this).find(".percentilebox").val(),
                                multifact: that.state.multifact,
                                multifactentities: that.state.multifactentities,
                            });
                            var result = $("#" + dynamicdiv).find("li").addClass("searchresult");
                            $(".sortingdropdown>.axis-list-items").append(result);
                        }
                    });
                } else {
                    $(".itemname").parents(".measureitem:not('searchresult')").show();
                    $("ul.sortingdropdown li.searchresult").remove();
                }
            }, 500);
        });
    }
    addsortingvalue = (event) => {
        var $this = event.currentTarget;
        if (!$($this).hasClass('active')) {
            var columnName = $($this).attr("data-name");
            var columnDisplayName = $($this).find('.itemname').text();
            var datatype = $($this).attr("data-type");
            var aggregation = $($this).find(".boclabel-info").text().replace(/[^A-Z0-9]/ig, "");
            var tableName = $($this).attr("table-name");
            var percentileValue = $($this).attr("percentile-value");
            var data = JSON.parse(ChartInfo.functions.getChartDetails(this.props.chartnumber));
            var activeChartType = $('.charttype.active').attr("id");
            var that = this;
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $(".sortingattributes").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            var yAxisAttrView = new YAxisAttrView({
                dynamicdiv: dynamicdiv,
                yaxiscolumnDisplayName: columnDisplayName,
                yaxiscolumnName: columnName,
                yaxiscolumnType: datatype,
                yaxisaggregation: aggregation,
                yaxistableName: tableName,
                percentileValue: percentileValue,
                measures: that.state.measures,
                dimensions: that.state.dimensions,
                viewmode: 'sortingvalues',
                multifact: that.state.multifact,
                multifactentities: that.state.multifactentities,
            });
            $(".sortingattributes").html($("#" + dynamicdiv));
        }
        //Initiate Disable other options when there is no axis fields selected
        //this.axisUIValidation();
    }
    createCustomFiledEve(msg, data) {
        this.createCustomFiled(data);
    }
    createCustomFiled = ($this) => {
        // var $this = e.currentTarget;
        var edit = $($this).attr("data-edit") != undefined;
        var columnname = $($this).attr("data-name");
        var columnNameOrg = $($this).attr("data-name");
        if (columnname != undefined) {
            columnname = columnname.replace(/([#;?&,%.+*~\':"!^$%[\]()<=>`|\/@])/g, "");
        }
        var isFiltersContainsCustomField = false;
        if ($('.singlereportview ').length == 0) {
            var filterAccordianLength = $('.acc-content').length;
            $('.acc-content').each(function () {
                var $this = this;
                if ($($this).attr("id") == columnname) {
                    isFiltersContainsCustomField = true;
                }
            });
        } else {
            var filterAccordianLength = $('.acc-content').length;
            if (filterAccordianLength > 0) {
                $('.acc-content').each(function () {
                    var $this = this;
                    if ($($this).attr("id") == columnname) {
                        isFiltersContainsCustomField = true;
                    }
                });
            } else {
                // var filterObjectArray = this.collection.getFilterObject(this.state.chartnumber, this.props.reportmodel);
                // $.each(filterObjectArray, function (index, filter) {
                //     if (filter["columnname"] == columnname) {
                //         isFiltersContainsCustomField = true;
                //     }
                // });
            }
        }
        var getAllTileLevelFilters = ChartInfo.functions.getAllTileLevelFilters();
        if (getAllTileLevelFilters != undefined) {
            for (var key in getAllTileLevelFilters) {
                if (getAllTileLevelFilters.hasOwnProperty(key)) {
                    var tileLevelFilters = getAllTileLevelFilters[key];
                    if (tileLevelFilters != undefined && tileLevelFilters[1] != undefined && tileLevelFilters[1].tileFiltersList != undefined) {
                        var tileFiltersList = tileLevelFilters[1].tileFiltersList;
                        $.each(tileFiltersList, function (index, filter) {
                            if (filter["columnname"] == columnname) {
                                isFiltersContainsCustomField = true;
                            }
                        });
                    }
                }
            }
        }
        if (edit && isFiltersContainsCustomField) {
            var that = this;
            $(".deleteyes").unbind("click");
            $(".deleteno").unbind("click");
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
            $('.birdmessage-info').attr('data-num', this.props.chartnumber);
            $('.birdmessagebuttons').addClass('show');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(getMessage('BirdWarning52'));
            $(".deleteyes").click(function (e1) {
                e1.preventDefault();
                var removableitem = columnname;
                var getAllTileLevelFilters = ChartInfo.functions.getAllTileLevelFilters();
                if (getAllTileLevelFilters != undefined) {
                    for (var key in getAllTileLevelFilters) {
                        if (getAllTileLevelFilters.hasOwnProperty(key)) {
                            var tileLevelFilters = getAllTileLevelFilters[key];
                            if (tileLevelFilters != undefined && tileLevelFilters[1] != undefined && tileLevelFilters[1].tileFiltersList != undefined) {
                                var tileFiltersList = tileLevelFilters[1].tileFiltersList;
                                $.each(tileFiltersList, function (index, filter) {
                                    if (filter["columnname"] == columnname) {
                                        tileFiltersList.splice(index, 1);
                                    }
                                });
                            }
                        }
                    }
                    PubSub.publish('rightfiltersapplyStory');
                }

                if ($('.singlereportview ').length == 0) {
                    var filterAccordianLength = $('.acc-content').length;
                    var isAplyingFiltersRequired = false;
                    $('.acc-content').each(function () {
                        var $this = this;
                        if ($($this).attr("id") == removableitem.replace(/([#;?&,%.+*~\':"!^$%[\]()<=>`|\/@])/g, "")) {
                            var $deleteButton = $($this).find('#delete');
                            $($deleteButton).click();
                            if (filterAccordianLength > 1) {
                                isAplyingFiltersRequired = true;
                            }
                        }
                    });
                    if (isAplyingFiltersRequired) {
                        $('.rightfiltersapply').click();
                    }
                } else {
                    var filterAccordianLength = $('.acc-content').length;
                    if (filterAccordianLength > 0) {
                        var isAplyingFiltersRequired = false;
                        $('.acc-content').each(function () {
                            var $this = this;
                            if ($($this).attr("id") == removableitem.replace(/([#;?&,%.+*~\':"!^$%[\]()<=>`|\/@])/g, "")) {
                                var $deleteButton = $($this).find('#delete');
                                $($deleteButton).click();
                                if (filterAccordianLength > 1) {
                                    isAplyingFiltersRequired = true;
                                }
                            }
                        });
                        if (isAplyingFiltersRequired) {
                            $(".applysavedfilter").click();
                        }
                    } else {
                        that.broker.trigger('checkWheatherDeletedCustomFieldExistInFilter', columnNameOrg);
                        that.broker.trigger('checkWheatherDeletedCustomFieldExistInFilter1', columnNameOrg);
                    }
                }
                $('.birdmessage-center').removeClass("message-top-in");
                var convertedTypes = sessionStorage.getItem('convertedtypes');
                if (convertedTypes != undefined) {
                    var parsedValues = JSON.parse(convertedTypes);
                    var actualDimensions = that.dimensions;
                    $.each(parsedValues, function (index, convertedType) {
                        $.each(actualDimensions, function (index1, filter) {
                            if (filter.get('columnName') == convertedType.columnName) {
                                filter.set({ 'tableDisplayType': convertedType.dataType });
                                actualDimensions.splice(index1, 1, filter)
                            }
                        });
                    });
                    that.state.dimensions = actualDimensions;
                }
                var tablename = $($this).attr("table-name");
                var custObj = {};
                custObj["chartnumber"] = that.props.chartnumber;
                custObj["dimensions"] = that.state.dimensions;
                custObj["isEdit"] = edit;
                custObj["columnName"] = columnNameOrg;
                PubSub.publish('showcustomfielddialog', custObj);
                //  that.broker.trigger('showcustomfielddialog', that.props.chartnumber, that.state.dimensions, edit, columnNameOrg, tablename);
            });
            $(".deleteno").click(function () {
                $('.birdmessage-center').removeClass("message-top-in");
            });
        } else {
            var convertedTypes = sessionStorage.getItem('convertedtypes');
            if (convertedTypes != undefined) {
                var parsedValues = JSON.parse(convertedTypes);
                var actualDimensions = this.state.dimensions;
                $.each(parsedValues, function (index, convertedType) {
                    $.each(actualDimensions, function (index1, filter) {
                        if (filter.columnName == convertedType.columnName) {
                            filter.tableDisplayType = convertedType.dataType;
                            actualDimensions.splice(index1, 1, filter)
                        }
                    });
                });
                this.state.dimensions = actualDimensions;
            }
            var tablename = $($this).attr("table-name");
            var custObj = {};
            custObj["chartnumber"] = this.props.chartnumber;
            custObj["dimensions"] = this.state.dimensions;
            custObj["isEdit"] = edit;
            custObj["columnName"] = columnNameOrg;
            PubSub.publish('showcustomfielddialog', custObj);
            //   this.broker.trigger('showcustomfielddialog', this.props.chartnumber, this.state.dimensions, edit, columnNameOrg, tablename);
        }
        document.body.classList.add("modal-open");
    }
    createDillDownHierarchyEvee(msg, data) {
        this.createDillDownHierarchy(data);
    }
    createDillDownHierarchy($this) {
        //  e.preventDefault();
        //var $this = e.currentTarget;
        var that = this;
        var currentChartNumber = this.state.chartnumber;
        var drilldownHierarchyModal = new DrilldownHierarchyModal({
            chartnumber: currentChartNumber,
            columnName: $($this).attr('data-name'),
            multifact: that.state.multifact,
            facttables: that.state.facttables,
            multifactentities: that.state.multifactentities,
        });
        //  $(".customfieldhierarchy" + currentChartNumber + "").modal("show");
        ModalProperties.resize();
        $('.dropdownhierarchylist1').sortable();
        document.body.classList.add("modal-open");
    }
    createrangeEve(msg, data) {
        this.createRange(data);
    }
    createRange($this) {
        var that = this;
        var currentChartNumber = this.state.chartnumber;
        var customRangeModal = new CustomRangeView({
            chartnumber: currentChartNumber,
            columnName: $($this).attr('data-name'),
            multifact: that.state.multifact,
            multifactentities: that.state.multifactentities,
        });
        //	$(".customrange"+currentChartNumber+"").modal("show");
        ModalProperties.resize();
        document.body.classList.add("modal-open");

    }
   createcustommeasureEve(msg, data) {
        this.createcustomaggregatedfield(data);
    }
    createcustomaggregatedfield($this) {
       var that = this;
        var currentChartNumber = this.props.chartnumber;
        //this.unbindEvents(currentChartNumber);
        var convertedTypes = sessionStorage.getItem('convertedtypes');
        var dimensions = this.state.dimensions;
        if (convertedTypes != undefined) {
            var parsedValues = JSON.parse(convertedTypes);
            var actualDimensions = this.state.dimensions;
            $.each(parsedValues, function (index, convertedType) {
                $.each(actualDimensions, function (index1, filter) {
                    if (filter.get('columnName') == convertedType.columnName) {
                        filter.set({ 'tableDisplayType': convertedType.dataType });
                        actualDimensions.splice(index1, 1, filter)
                    }
                });
            });
            dimensions = actualDimensions;
        }
        new customaggregatedfieldView({
            chartnumber: currentChartNumber,
            columnName: $($this).attr('data-name'),
            dimensions: dimensions,
            datamodelId: that.props.datamodelId,
            reportId: that.props.reportId,
            schemaType: that.props.schemaType,
            isDirect: that.props.isDirect,
            facttables: that.props.facttables,
            multifact: that.state.multifact,
            multifactentities: that.state.multifactentities,
        });
        document.body.classList.add("modal-open");
        //    $(".createcustomaggregatedfield" + currentChartNumber).modal("show");
        ModalProperties.resize();
        $(window).on("resize", function () {
            ModalProperties.resize();
        })
    }
    createcustomhierarchymeasureEve(msg, data) {
        this.createcustommeasurehierarchy(data);
    }
    createcustommeasurehierarchy($this) {
        var currentChartNumber = this.props.chartnumber;
        var that = this;
        new CustomMeasureHierarchyModal({
            chartnumber: currentChartNumber,
            columnName: $($this).attr('data-name'),
            measure: that.state.measures,
            multifact: that.state.multifact,
            facttables: that.props.facttables,
            multifactentities: that.state.multifactentities,
        });
        document.body.classList.add("modal-open");
        //	$(".custommeasurehierarchy"+currentChartNumber+"").modal("show");
        ModalProperties.resize();
        $('.dropdownhierarchylist1').sortable();

    }
    unbindEvents() {
        PubSub.unsubscribe('createcustomhierarchymeasure', this.createcustomhierarchymeasureEve.bind(this));
        PubSub.unsubscribe('createDillDownHierarchy', this.createDillDownHierarchyEvee.bind(this));
        PubSub.unsubscribe('createrange', this.createrangeEve.bind(this));
		PubSub.unsubscribe('createcustommeasure', this.createcustommeasureEve);
        PubSub.unsubscribe('createCustomFiled', this.createCustomFiledEve.bind(this));
	}
}