export var tabInfo = {};
export var columnsData = {};
export var currentTabId = 0;
export var tabFilters = {};
export var tabDrillDownAllStatus = {};
export var viewStorybookViews = {};
export var drilldownFilterFromSession = {};
export var viewreportBodyViews = {};
export var url = "";
export var functions = {

    updateChartInfoForTab: function (tabId, chartInfo) {
        tabInfo[tabId] = chartInfo;
    },
    getChartInfoForTab: function (tabId) {
        return tabInfo[tabId];
    },
    updatecolumnsDataForTab: function (tabId, columns) {
        columnsData[tabId] = columns;
    },
    getcolumnsDataForTab: function (tabId) {
        return columnsData[tabId];
    },
    setCurrentTabNumber: function (tabId) {
        currentTabId = tabId;
    },
    getCurrentTabId: function () {
        return currentTabId;
    },
    updateTabFiltersBeforeRefresh: function (tabId, filters) {
        tabFilters[tabId] = filters;
    },
    getTabFiltersBeforeRefresh: function (tabId) {
        return tabFilters[tabId];
    },
    updateStoryBookViews: function (tabId, view) {
        viewStorybookViews[tabId] = view;
    },
    getStoryBookViews: function (tabId) {
        return viewStorybookViews[tabId];
    },
    updateViewreportBodyViews: function (chartno, view) {
        viewreportBodyViews[chartno] = view;
    },
    getViewreportBodyViews: function (chartno) {
        return viewreportBodyViews[chartno];
    },
    updateDrilldownStatusForTab: function (tabId, status) {
        tabDrillDownAllStatus[tabId] = status;
    },
    getDrilldownStatusForTab: function (tabId) {
        return tabDrillDownAllStatus[tabId];
    },
    updateDrilldownFilterFromSession: function (tabId, drilldown) {
        drilldownFilterFromSession[tabId] = drilldown;
    },
    getDrilldownFilterFromSession: function (tabId) {
        return drilldownFilterFromSession[tabId];
    },
    updateURL: function (urll) {
        url = urll;
    },
    getURL: function () {
        return url;
    },
    resetTabInfo: function () {
        tabInfo = {};
        columnsData = {};
        currentTabId = 0;
        tabFilters = {};
        viewStorybookViews = {};
        tabDrillDownAllStatus = {};
        drilldownFilterFromSession = {};
        viewreportBodyViews = {};
    }
};
