import React from 'react';
import _ from 'underscore';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { lang} from "../js/helpers/utils/languages/language";
const $ = window.$;
const columns = [
    { dataField: 'entityName', text: lang["workspace.entityname"], sort: true },
    { dataField: 'customFieldName', text: lang["workspace.fieldname"], sort: true },
    { dataField: 'customFieldFormula', text: lang["workspace.fieldformula"], sort: true },
    { dataField: 'editcustomfields', text: lang["workspace.edit"], sort: false },
    { dataField: 'deletecustomfields', text: lang["workspace.delete"], sort: false }
];
const RemotePagination = ({ data, page, sizePerPage, onTableChange, totalSize }) => (
    // <div className="notifications-table-container">
        <BootstrapTable
            remote
            keyField="customFieldName"
            data={data}
            columns={columns}
            pagination={paginationFactory({ page, sizePerPage, totalSize })}
            onTableChange={onTableChange}
            noDataIndication={lang["workspace.nomatchsfnd"]}
        />
    // </div>
);



export default class WorkspaceCustomfieldsPreviewHtml extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            data: props.data.slice(0, 10),
            sizePerPage: 10
        };
    }
    componentDidMount() {
        if(this.state.data.length>=0 && this.state.data.length<=5){
            $(".workspace-customfields-table-container").addClass("showbottom");
        }else{
            $(".workspace-customfields-table-container").removeClass("showbottom");
        }
    }
    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, data }) => {
        const currentIndex = (page - 1) * sizePerPage;
        {
            type == "sort" ?
                setTimeout(() => {
                    let result;
                    if (sortOrder === 'asc') {
                        result = data.sort((a, b) => {
                            if (a[sortField] > b[sortField]) {
                                return 1;
                            } else if (b[sortField] > a[sortField]) {
                                return -1;
                            }
                            return 0;
                        });
                    } else {
                        result = data.sort((a, b) => {
                            if (a[sortField] > b[sortField]) {
                                return -1;
                            } else if (b[sortField] > a[sortField]) {
                                return 1;
                            }
                            return 0;
                        });
                    }
                    this.setState(() => ({
                        data: result
                    }));
                }, 10) :
                setTimeout(() => {
                    this.setState(() => ({
                        page,
                        data: this.props.data.slice(currentIndex, currentIndex + sizePerPage),
                        sizePerPage
                    }));
                    var tableDataLength = this.props.data.slice(currentIndex, currentIndex + sizePerPage).length;
                    if(tableDataLength>=0 && tableDataLength<=5){
                        $(".workspace-customfields-table-container").addClass("showbottom");
                    }else{
                        $(".workspace-customfields-table-container").removeClass("showbottom");
                    }
                }, 10);
        }
    }
    render() {
        const { data, sizePerPage, page } = this.state;

        return (
            <div class="col-xs-12 marg-top-10 nopadding">
                <div className="notifications-table-container workspace-customfields-table-container">
                    <RemotePagination
                        data={data}
                        page={page}
                        sizePerPage={sizePerPage}
                        totalSize={this.props.data.length}
                        onTableChange={this.handleTableChange}
                    />
                </div>
            </div>
        );
        
    }
}