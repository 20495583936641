import React from 'react';
import _ from 'underscore';
import SendRequest from '../SendRequest';
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;
export default class HamburgerReportsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            folders: [],
            reportscount: { favorites: 0, storyboards: 0, reports: 0 },
            features: []
        }
    }
    showAdvancedSearch =(e) => {
        e.preventDefault();
        var $this = e.currentTarget;
        var isFrom = $($this).attr("is-from");
        $($this).parent().find('.filtersearching').toggle('slow');
        if($($this).parent().hasClass('advancesearching')){
            $($this).parent().removeClass('advancesearching')
        }else{
            $($this).parent().addClass('advancesearching')
        }
        if(isFrom =='storyboard'){
            $('#hamstorytab ul>li').show();
        }else if(isFrom =='report'){
            $('#hamreptab ul>li').show();
        }else if(isFrom =='favorite'){
            $('#hamfavtab ul>li').show();
        }
    }
    searchfilteredreports= (e) =>{
        var $this = e.currentTarget;
        var isFrom = $($this).attr("is-from");
        var that = this;
        e.preventDefault();
        var tabIdElement = '';
        if(isFrom =='storyboard'){
            tabIdElement='#hamstorytab';
        }else if(isFrom =='report'){
            tabIdElement = '#hamreptab';
        }else if(isFrom =='favorite'){
            tabIdElement = '#hamfavtab';
        }
        var startswith = $('#reportnamestart[is-from="'+isFrom+'"]').val();
        var endswith = $("#reportnameend[is-from='"+isFrom+"']").val();
        var contains = $("#reportnamecontains[is-from='"+isFrom+"']").val();
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getsearchedreportsforhamburger",
            queryString: "?startswith="+startswith+"&endswith="+endswith+"&contains="+contains+"&isFrom="+isFrom,
            sucessFunction: (response) => {
                var reportinfoliArray = [];
                $.each(response, function(i, reportinfo){
                    reportinfoliArray[i] = '<li class="viewreport-list-item" title="'+reportinfo.reportname+'">';
                    $(tabIdElement+' ul>li>a[data-num="'+reportinfo.reportId+'"]').remove();
                    if(reportinfo.type=="ml_storyboard"){
                        reportinfoliArray[i] +='<a class="navDashboard" href="'+that.props.url+'/viewmodel/'+btoa(reportinfo.reportId)+'/'+btoa(reportinfo.dataModelId)+'" data-num="'+reportinfo.reportId+'" tab-id="0" tab-name="'+reportinfo.reportname+'" class="boc-snapshot-thumbnail"><div class="ripples"><span class="ripplesCircle"></span></div><i class="fa fa-file-o"></i>'+reportinfo.reportname+'</a>';
                    } else if(reportinfo.type=="smartinsight_storyboard"){
                        reportinfoliArray[i] +='<a class="navDashboard" href="'+that.props.url+'/viewsmartinsight/'+btoa(reportinfo.reportId)+'/'+btoa(reportinfo.dataModelId)+'" data-num="'+reportinfo.reportId+'" tab-id="0" tab-name="'+reportinfo.reportname+'" class="boc-snapshot-thumbnail"><div class="ripples"><span class="ripplesCircle"></span></div><i class="fa fa-file-o"></i>'+reportinfo.reportname+'</a>';
                    }else{
                        reportinfoliArray[i] +='<a class="navDashboard" href="'+that.props.url+'/viewreport/'+btoa(reportinfo.reportId)+'" data-num="'+reportinfo.reportId+'" tab-id="0" tab-name="'+reportinfo.reportname+'" class="boc-snapshot-thumbnail"><div class="ripples"><span class="ripplesCircle"></span></div><i class="fa fa-file-o"></i>'+reportinfo.reportname+'</a>';
                    }
                    reportinfoliArray[i] +='</li>';
                });

                $(tabIdElement+' ul>li').hide();
                $(tabIdElement+' ul').prepend(reportinfoliArray.join(''));
            },
            rejectFunction: () => { },
            headers: requestHeaders,
        })
    }
    componentDidMount() {
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getReportsByFolder",
            queryString: "",
            sucessFunction: (props) => {
                var favrepcount = 0,
                    storepcount = 0,
                    myrrepcount = 0,
                    shrrepcount = 0;
                _.each(props, function (report, i) {
                    _.each(report, function (rep, i) {
                        if (rep.isUserFavourite == "Y") { favrepcount++ }
                        if (rep.type == "story" || rep.type == "customstoryboard" || rep.type == "ml_storyboard") { storepcount++ }
                        else if (rep.type != "story" && rep.type != "customstoryboard" && rep.type != "ml_storyboard" && rep.type != "smartinsight_storyboard") { myrrepcount++ }
                        if (rep.sharedBy != undefined) { shrrepcount++ }
                    });
                });
                var reportscount = { favorites: favrepcount, storyboards: storepcount, reports: myrrepcount, sharedwithme: shrrepcount };
                this.setState({folders: props, reportscount: reportscount });
            },
            rejectFunction: () => { },
            headers: requestHeaders,
        })
    }
    render() {
        let isFavorite="favorite",isStoryboard="storyboard",isReport="report";
        return (
            <div role="tabpanel">
                <div className="tab-content">
                    <div role="tabpanel" className="tab-pane boc-snapshot-container tabviewitems boc-gray-inner active" id="dashboardtab">
                        <div className="panel-group panel">
                            {this.state.reportscount.favorites > 0 ?
                                [(
                                    <React.Fragment>
                                        <h4 className="panelboc-title accordion-toggle" title={lang["user.header.favorites"]} id="" data-toggle="collapse" href="#hamfavtab" data-parent="#dashboardtab" aria-expanded="true" aria-controls="hamfavtab"><div className="ripples"><span className="ripplesCircle"></span></div><i className="fa fa-star"></i>{lang["user.header.favorites"]}<span className="badge pull-right marg-righ-15">{this.state.reportscount.favorites}</span></h4>

                                        <div id="hamfavtab" className="panel-collapse panelboc-content collapse in" aria-expanded="true">
                                            <div className="panelboc-body">
                                                <div className="container-fluid marg-bot-10 marg-top-10">
                                                    <div className="row">
                                                        <input onKeyUp={this.props.search} className="form-control search-image searchinput" name="searchreports" placeholder={lang["user.header.search"]} type="text" />
                                                    </div>
                                                </div>
                                                <button onClick={this.showAdvancedSearch} className="btn btn-link pull-right btn-sm showadvanced" is-from={isFavorite}>{lang["user.header.advanced"]}</button>
                                                <div className="marg-bot-10 filtersearching " style={{ display: 'none' }}>
                                                    <div className="stringfilters searching ">
                                                    {lang["user.header.startswith"]} <input type="text" id="reportnamestart" data-id="reportnamestart" className="searchword" is-from={isFavorite} />
                                                    </div>
                                                    <div className="stringfilters searching">
                                                    {lang["user.header.endswith"]} <input type="text" id="reportnameend" data-id="reportnameend" is-from={isFavorite} />
                                                    </div>
                                                    <div className="stringfilters searching col-lg-10 col-md-10 col-sm-12 col-xs-12" style={{ marginLeft: '-5px' }}>
                                                    {lang["user.header.contains"]} <input type="text" id="reportnamecontains" data-id="reportnamecontains" is-from={isFavorite} />
                                                    </div>
                                                    <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12" style={{ marginTop: '1px', marginLeft:'-12px' }}>
                                                        <button onClick={this.searchfilteredreports} type="button" id="searchfilteredreports" className="btn btn-sm btn-bird marg-top-20 btn-highlight" is-from={isFavorite}>{lang["user.header.go"]}</button>
                                                    </div>
                                                </div>
                                                <ul className="list-group scrollsetul">
                                                    {this.state.folders.map((folderArray, j) => {
                                                        return (folderArray.map((folder, k) => {
                                                            return (folder.isUserFavourite == 'Y' ?
                                                                (<li className="viewreport-list-item" title={folder.reportname}>
                                                                    {folder.type=="ml_storyboard"?
                                                                    <a className="navDashboard" href={`${this.props.url}/viewmodel/${btoa(folder.reportId)}/${btoa(folder.dataModelId)}`} data-num={folder.reportId} tab-id="0" tab-name={folder.reportname} className="boc-snapshot-thumbnail"><div className="ripples"><span className="ripplesCircle"></span></div><i className="fa fa-file-o"></i> {folder.reportname}</a>
											                       :folder.type=="smartinsight_storyboard"?
                                                                    <a className="navDashboard" href={`${this.props.url}/viewsmartinsight/${btoa(folder.reportId)}/${btoa(folder.dataModelId)}`} data-num={folder.reportId} tab-id="0" tab-name={folder.reportname} className="boc-snapshot-thumbnail"><div className="ripples"><span className="ripplesCircle"></span></div><i className="fa fa-file-o"></i>{folder.reportname}</a>
							                                        :folder.type=="customstoryboard"?
                                                                    <a className="navDashboard" href={`${this.props.url}/viewcustomstoryboard/${btoa(folder.reportId)}`} data-num={folder.reportId} tab-id="0" tab-name={folder.reportname} className="boc-snapshot-thumbnail"><div className="ripples"><span className="ripplesCircle"></span></div><i className="fa fa-file-o"></i>{folder.reportname}</a>
							                                        :
                                                                    <a className="navDashboard" href={`${this.props.url}/viewreport/${btoa(folder.reportId)}`} data-num={folder.reportId} tab-id="0" tab-name={folder.reportname} className="boc-snapshot-thumbnail"><div className="ripples"><span className="ripplesCircle"></span></div><i className="fa fa-file-o"></i> {folder.reportname}</a>
                                                                    }
                                                                </li>) : <React.Fragment />)
                                                        }))
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    </React.Fragment>)] : <React.Fragment />}

                            {this.state.reportscount.storyboards > 0 ?
                                [(
                                    <React.Fragment>
                                        <h4 className="panelboc-title accordion-toggle" title={lang["user.header.storyboards"]} id="" data-toggle="collapse" href="#hamstorytab" aria-expanded="false" data-parent="#dashboardtab" aria-controls="hamstorytab"><div className="ripples"><span className="ripplesCircle"></span></div><i className="fa fa-th-large"></i>{lang["user.header.storyboards"]}<span className="badge pull-right marg-righ-15">{this.state.reportscount.storyboards}</span></h4>

                                        <div id="hamstorytab" className="panel-collapse panelboc-content collapse" aria-expanded="false">
                                            <div className="panelboc-body">
                                                <div className="container-fluid marg-bot-10 marg-top-10">
                                                    <div className="row">
                                                        <input onKeyUp={this.props.search} className="form-control search-image searchinput" name="searchreports" placeholder={lang["user.header.search"]} type="text" />
                                                    </div>
                                                </div>
                                                <button onClick={this.showAdvancedSearch} className="btn btn-link pull-right btn-sm showadvanced" is-from={isStoryboard}>{lang["user.header.advanced"]}</button>
                                                <div className="marg-bot-10 filtersearching " style={{display: 'none'}}>
                                                    <div className="stringfilters searching ">
                                                    {lang["user.header.startswith"]} <input type="text" id="reportnamestart" data-id="reportnamestart" className="searchword" is-from={isStoryboard} />
                                                    </div>
                                                    <div className="stringfilters searching">
                                                    {lang["user.header.endswith"]} <input type="text" id="reportnameend" data-id="reportnameend" is-from={isStoryboard} />
                                                    </div>
                                                    <div className="stringfilters searching col-lg-10 col-md-10 col-sm-12 col-xs-12" style={{ marginLeft: '-5px' }}>
                                                    {lang["user.header.contains"]} <input type="text" id="reportnamecontains" data-id="reportnamecontains" is-from={isStoryboard} />
                                                    </div>
                                                    <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12" style={{ marginTop: '1px', marginLeft:'-12px' }}>
                                                        <button onClick={this.searchfilteredreports} type="button" id="searchfilteredreports" className="btn btn-sm btn-bird marg-top-20 btn-highlight" is-from={isStoryboard}>{lang["user.header.go"]}</button>
                                                    </div>
                                                </div>
                                                <ul className="list-group scrollsetul">
                                                    {this.state.folders.map((folderArray, j) => {
                                                       return ( folderArray.map((folder, k) => {
                                                        return ( folder.type == "story" || folder.type == "customstoryboard" || folder.type == "ml_storyboard" || folder.type == "smartinsight_storyboard" ?
                                                                (<li className="viewreport-list-item" title={folder.reportname}>
                                                                    {folder.type=="ml_storyboard"?
                                                                    <a className="navDashboard" href={`${this.props.url}/viewmodel/${btoa(folder.reportId)}/${btoa(folder.dataModelId)}`} data-num={folder.reportId} tab-id="0" tab-name={folder.reportname} className="boc-snapshot-thumbnail"><div className="ripples"><span className="ripplesCircle"></span></div><i className="fa fa-file-o"></i>{folder.reportname}</a>
											                        :[(folder.type=="smartinsight_storyboard"?
                                                                    <a className="navDashboard" href={`${this.props.url}/viewsmartinsight/${btoa(folder.reportId)}/${btoa(folder.dataModelId)}`} data-num={folder.reportId} tab-id="0" tab-name={folder.reportname} className="boc-snapshot-thumbnail"><div className="ripples"><span className="ripplesCircle"></span></div><i className="fa fa-file-o"></i> {folder.reportname}</a>
                                                                    :folder.type=="customstoryboard"?
                                                                    <a className="navDashboard" href={`${this.props.url}/viewcustomstoryboard/${btoa(folder.reportId)}`} data-num={folder.reportId} tab-id="0" tab-name={folder.reportname} className="boc-snapshot-thumbnail"><div className="ripples"><span className="ripplesCircle"></span></div><i className="fa fa-file-o"></i> {folder.reportname}</a>
							                                       :
                                                                    <a className="navDashboard" href={`${this.props.url}/viewreport/${btoa(folder.reportId)}`} data-num={folder.reportId} tab-id="0" tab-name={folder.reportname} className="boc-snapshot-thumbnail"><div className="ripples"><span className="ripplesCircle"></span></div><i className="fa fa-file-o"></i> {folder.reportname}</a>
                                                                     )]}
                                                                </li>) : <React.Fragment />)
                                                        }))
                                                    })}
                                                </ul>
                                            </div>
                                        </div></React.Fragment>)] : <React.Fragment />}
                            {this.state.reportscount.reports > 0 ?
                                [(
                                    <React.Fragment>
                                        <h4 className="panelboc-title accordion-toggle" title={lang["user.header.reports"]} id="" data-toggle="collapse" href="#hamreptab" aria-expanded="false" data-parent="#dashboardtab" aria-controls="hamreptab"><div className="ripples"><span className="ripplesCircle"></span></div><i className="fa fa-bar-chart"></i>{lang["user.header.reports"]}<span className="badge pull-right marg-righ-15">{this.state.reportscount.reports}</span></h4>

                                        <div id="hamreptab" className="panel-collapse panelboc-content collapse" aria-expanded="false">
                                            <div className="panelboc-body">
                                                <div className="container-fluid marg-bot-10 marg-top-10">
                                                    <div className="row">
                                                        <input onKeyUp={this.props.search} className="form-control search-image searchinput" name="searchreports" placeholder={lang["user.header.search"]} type="text" />
                                                    </div>
                                                </div>
                                                <button onClick={this.showAdvancedSearch} className="btn btn-link pull-right btn-sm showadvanced" is-from={isReport}>{lang["user.header.advanced"]}</button>
                                                <div className="marg-bot-10 filtersearching " style={{display: 'none'}}>
                                                    <div className="stringfilters searching ">
                                                    {lang["user.header.startswith"]} <input type="text" id="reportnamestart" data-id="reportnamestart" className="searchword" is-from={isReport} />
                                                    </div>
                                                    <div className="stringfilters searching">
                                                    {lang["user.header.endswith"]} <input type="text" id="reportnameend" data-id="reportnameend" is-from={isReport} />
                                                    </div>
                                                    <div className="stringfilters searching col-lg-10 col-md-10 col-sm-12 col-xs-12" style={{marginLeft: '-5px'}}>
                                                    {lang["user.header.contains"]} <input type="text" id="reportnamecontains" data-id="reportnamecontains" is-from={isReport} />
                                                    </div>
                                                    <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12" style={{ marginTop: '1px', marginLeft:'-12px' }}>
                                                        <button onClick={this.searchfilteredreports} type="button" id="searchfilteredreports" className="btn btn-sm btn-bird marg-top-20 btn-highlight" is-from={isReport}>{lang["user.header.go"]}</button>
                                                    </div>
                                                </div>
                                                <ul className="list-group scrollsetul">
                                                    {this.state.folders.map((folderArray, j) => {
                                                        return (folderArray.map((folder, k) => {
                                                            return (folder.type != "story" && folder.type != "customstoryboard" && folder.type != "ml_storyboard" && folder.type != "smartinsight_storyboard" ?
                                                                [(<li className="viewreport-list-item" title={folder.reportname}>
                                                                    <a className="navDashboard" href={`${this.props.url}/viewreport/${btoa(folder.reportId)}`} data-num={folder.reportId} tab-id="0" tab-name={folder.reportname} className="boc-snapshot-thumbnail"><div className="ripples"><span className="ripplesCircle"></span></div><i className="fa fa-file-o"></i>{folder.reportname}</a>
                                                                </li>)] : <React.Fragment />)
                                                        }))
                                                    })}
                                                </ul>
                                            </div>
                                        </div></React.Fragment>)]
                                : <React.Fragment />}
                            {/*<% if(this.state.folders[0]!=null && this.state.folders[0]=="disable"){%>
				
				<!-- this.state.folders -->
				<%_.each(this.state.folders, function(folderArray,j) { %>
					<h4 className="panelboc-title accordion-toggle" title="<%=folderArray[0].reportFolder%>" id="" data-toggle="collapse" href="#hamfolder<%=j%>" aria-expanded="false" data-parent="#dashboardtab" aria-controls="hamreptab"><div className="ripples"><span className="ripplesCircle"></span></div><i className="fa fa-folder"></i> <%=folderArray[0].reportFolder%><span className="badge pull-right marg-righ-15"><%=_.keys(folderArray).length%></span></h4>
				
					<div id="hamfolder<%=j%>" className="panel-collapse panelboc-content collapse" aria-expanded="false" style="">
						<div className="panelboc-body">	
							<ul className="list-group scrollsetul">
								<%_.each(folderArray,function(folder,k) { %>
									<li className="viewreport-list-item" title="<%=folder.reportname%>">
										<a className="navDashboard" href="#viewreport/<%=btoa(folder.reportId)%>" data-num="<%=folder.reportId%>" tab-id="0" tab-name="<%=folder.reportname%>" className="boc-snapshot-thumbnail"><i className="fa fa-file-o"></i> <%=folder.reportname%></a>
									</li>
								<%})%>
							</ul>
						</div>
					</div>		
				
				<%})%>
				
                                <%}%>*/}
                        </div>
                    </div>
                    {/* <div role="tabpanel" classNameName="tab-pane boc-snapshot-container tabviewitems boc-gray-inner" id="folderstab">
                        test 2
		                </div> */}
                </div>
            </div>
        )
    }
}