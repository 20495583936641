import React from 'react';
import * as viewreportCollection from "../js/helpers/utils/SavedReportCollection";
import _ from 'lodash';
import $ from 'jquery/dist/jquery';
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import SendRequest from '../SendRequest';
import * as CustomStoryBoardInfo from "../js/helpers/utils/customstoryboardinfo";
import { lang } from "../js/helpers/utils/languages/language";

import { getMessage } from '../js/helpers/utils/BirdMessage';


export default class SaveCustomStoryboardModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			features: props.features,
			datamodelId: props.datamodelId,
			issharedreport: false
		};
		this.applystoryboardchanges = this.applystoryboardchanges.bind(this);
	}
	componentDidMount(){
		$(".form-control").keyup(function(){
			var valThis = $(this).val().toLowerCase();
			  var valLength = valThis.length;
				var maxCount = 45;
				var errormsg="#errmsg"
				if(valLength>=maxCount){
					$(errormsg).html("");
					$(errormsg).html(lang["customboard.namexceeds"]+maxCount+"" ).show();
					$(this).val($(this).val().substring(0, $(this).val().length - 1));
				}else{
					$(errormsg).html("");
				}
		});
	}
	render() {
		this.state.features = this.props.features;
		this.state.datamodelId = this.props.datamodelId;
		this.state.reportId=this.props.reportId;
		this.state.reportname = this.props.reportName;
			this.state.reportdesc = this.props.reportDesc;
			this.state.folder = this.props.folder;
			this.state.title = this.props.title;
		if (viewreportCollection != undefined && viewreportCollection.model != undefined && viewreportCollection.model != null) {
			if (viewreportCollection.model.reportfolder != undefined) {
				viewreportCollection.model.reportfolder = viewreportCollection.model.reportfolder == 'Default' ? "" : viewreportCollection.model.reportfolder
			}

		}
		return (
			// <!-- savestoryboardmodal.js -->

			<div className={`modal fade ${this.props.isDisplay ? "in" : ""}`} id="savestoryboard" tabIndex="-1" role="dialog" aria-labelledby="savestoryboard" aria-hidden={this.props.isDisplay ? "false" : "true"} style={{ display: this.props.isDisplay ? "block" : "none" }}>
				<div className="modal-backdrop fade in" onClick={this.props.close}></div>
				<div className="modal-dialog modal-lg modal-save-report modal-dialog-lg-center modal-dialog-center" style={{ zIndex: "1050" }}>
					<div className="modal-content">
						<div className="modal-header boc-modal-hf-padding">
							<button type="button" className="close white" id="storyboardclose" aria-hidden="true" data-dismiss="modal" onClick={this.props.close}>&times;</button>
							<h4 className="modal-title"><i className="fa fa-floppy-o"></i> {lang["customboard.saveboard"]}</h4>
						</div>
						<div className="modal-body scrollset">
							<div className="container-fluid">
								<div className="row filtercontent">
									<fieldset className="savereport-border">
										<legend className="savereport-border">{lang["customboard.reportdetails"]}</legend>
										<div className="control-group">
											<div className="validationgroup form-group" style={{ marginTop: "-2px" }}>
												<span className="required right legend"></span>
												<label className="subhead" for="storyboardname">{lang["customboard.name"]} </label>
												<input type="name" id="storyboardname" name="storyboardname" defaultValue={this.state.reportname} className="form-control input-sm" placeholder={lang["customboard.placeholdname"]} />
												<span className="help-block  marg-left-10" id="storyboardname-help-block"></span>
											</div>
											<div className="validationgroup form-group">
												<label className="subhead" for="storyboardfolder">{lang["customboard.folder"]}</label>
												<input type="folder" className="form-control input-sm" defaultValue={this.state.folder} id="storyboardfolder" name="storyboardfolder" list="folders" placeholder={lang["customboard.slctfolder"]} />
												<span style={{color:"#ff0000"}} id="errmsg"></span>
												<span className="help-block hidden marg-left-10"></span>
												<datalist id="folders">
												</datalist>
											</div>
											<div className="validationgroup form-group hidden-lg hidden-md hidden-sm hidden-xs">
												<label className="subhead">{lang["customboard.description"]}</label>
												<textarea id="storyboarddesc" placeholder={lang["customboard.placeholdescription"]} value={this.props.reportDesc} name="storyboarddesc" className="form-control input-sm resize-vertical">{this.props.reportDesc}</textarea>
												<span className="help-block hidden"></span>
											</div>
										</div>
									</fieldset>
								</div>
							</div>
						</div>
						<div className="modal-footer boc-modal-hf-padding">
							<button type="button" className="btn btn-bird" id="btn-birdsave" data-dismiss="modal" onClick={this.props.close}><i className="fa fa-times"></i> {lang["customboard.cancelbtn"]}</button>
							<button type="button" className="btn btn-bird applystoryboard" id="applystoryboards" onClick={this.applystoryboardchanges}><i className="fa fa-check"></i> {lang["customboard.applybtn"]}</button>
						</div>
					</div>
					{/* <!-- /.modal-content --> */}
				</div>
			</div>
		);
	}
	applystoryboardchanges() {
		var storyboardname = $("#storyboardname").val();
		var reservedwordslist = JSON.parse(sessionStorage.getItem("reservedwordslist"));
		if (storyboardname == undefined || storyboardname == "" || storyboardname.length == 0 || /[~`!@#$%^*+\=\[\]{};':\\|†.<>\/?"""`]/.test(storyboardname)) {
			$("#storyboardname-help-block").html(lang["customboard.validname"])
			return;
		} else if (storyboardname.length > 70) {
			$("#storyboardname-help-block").html(lang["customboard.reportnameexceeds"])
			return;
		} else if (reservedwordslist != null){ 
		if (reservedwordslist.indexOf(storyboardname.toLowerCase()) != -1) {
			$("#storyboardname-help-block").html(lang["customboard.noreservewords"])
			return;
			}
		} else {
			$("#storyboardname-help-block").html('')
		}
		var customFieldsScript = sessionStorage.customFields;
		var customFiltersScript = sessionStorage.newreportCustomFilters;
		var drillThroughReports = ChartInfo.functions.getDrillThroughReports();
		var storyboardfolder = $("#storyboardfolder").val() != undefined ? $("#storyboardfolder").val() : "";
		var storyboarddesc = $("#storyboarddesc").val();
		var isSyncValid = true;
		var smartInsightsCols = {};
		smartInsightsCols["targetColumns"] = ChartInfo.functions.getSmartInsightsTargetVal();
		smartInsightsCols["ExcludeColumns"] = ChartInfo.functions.getSmartInsightsExcludeValues();
		var smartInsightsObj = {};
		smartInsightsObj["smartInsightsObj"] = smartInsightsCols;
		console.log(smartInsightsObj);
		var that = this;
		// this.options.model.set('storyboardname', storyboardname);
		// this.options.model.set('storyboardfolder', storyboardfolder);
		// this.options.model.set('storyboarddesc', storyboarddesc);
		if (storyboardfolder === "") {
			storyboardfolder = "Default"
		}
		if (isSyncValid) {
			var reporttitle = $(".boc-viewreport-title:visible").text();
			var title= $(".boc-viewreport-title[data-num=storyboard]").attr('title');
			$(".spinner").show();
			var reportDataArray = [];
			var reportdetails = { "name": storyboardname, "folder": storyboardfolder, "desc": storyboarddesc, "title": reporttitle, "iscloned": false, "issharedreportsaved": that.state.issharedreport };
			var axisDataArray = [];
           var chartInfoDetails = ChartInfo.functions.getAllCSBchartdetails();
           // var chartInfoDetails=   CustomStoryBoardInfo.functions.getCustomStoryboardchartdetails()
			_.each(chartInfoDetails, function (i, j) {
				var storedObject = i;
				if(storedObject!= undefined){
				var storedParsedObject = (storedObject);
                var mainreportid = storedParsedObject["mainreportid"];
                var datamodelId = storedParsedObject["datamodelId"];
                var reportcolumnslist = storedParsedObject["reportcolumnslist"];
				var datamodelidlist = storedParsedObject["datamodelidlist"];
				var chartnumber = storedParsedObject["chartnumber"];
				var xaxisDataArray = [];
				var jsonObject = {};
				 jsonObject["chartnumber"] = chartnumber;
                jsonObject['mainreportid'] =mainreportid
                jsonObject['datamodelId'] =datamodelId
                jsonObject['reportcolumnslist'] =reportcolumnslist  
				jsonObject['datamodelidlist'] =datamodelidlist
				jsonObject['title'] =title
				axisDataArray.push(jsonObject);
			}
			});
			var axisDataObject = {};
			axisDataObject["axis"] = axisDataArray;
            reportDataArray.push(axisDataObject);
			var detailsObject = {};
			detailsObject["details"] = reportdetails;
			reportDataArray.push(detailsObject);
			var filterArray = ChartInfo.functions.getFilters(false);
			var filterObject;
			var that = this;
			$.each(this.state.features, function (index, featuresModule) {
				if (featuresModule.moduleName == 'story_board') {
					$.each(featuresModule.features, function (index, feature) {
						if (feature.featureName == 'viewreport_filters' && feature.allow == false && filterArray.length == 0) {
							if (that.viewreportCollection != undefined && that.viewreportCollection.getFilterObject != undefined) {
								filterArray = that.viewreportCollection.getFilterObject();
							}
						}
					});
				}
			});
			var renameMeasuresorDimensions = sessionStorage.getItem('renameMeasuresorDimensions');
			var parsedData = JSON.parse(renameMeasuresorDimensions);
			if (filterArray != undefined && parsedData != null && parsedData != undefined) {
				_.each(filterArray, function (obj) {
					if (parsedData[obj.columnname] != undefined) {
						obj['columndisplayname'] = parsedData[obj.columnname];
					}
				});
			}
			filterObject = { "filter": filterArray };
			reportDataArray.push(filterObject);

			var gridStackData = _.map($('.grid-stack .grid-stack-item.tileview'), function (el) {
				try {
					el = $(el);
					var node = el.data('_gridstack_node');
					return {
						id: el.attr('data-custom-id'),
						num: el.attr('data-num'),
						x: node.x,
						y: node.y,
						width: node.width,
						height: node.height
					};
				} catch (e) {

				}
			});

			var gridStackObject = { "grid": gridStackData };
			reportDataArray.push(gridStackObject);
			if (this.state.reportId == undefined || this.state.reportId === 0) {			
				this.saveReport(this.state.reportId, JSON.stringify(reportDataArray));
			} else {
				var renameMeasuresorDimensions = sessionStorage.getItem('renameMeasuresorDimensions');
				this.saveReport(this.state.reportId, JSON.stringify(reportDataArray));
			}
		}

		/*Navigate Update*/
		var getnavurlid = $('.navigatereporturl').val();
		if (getnavurlid != "") {
			$('.nextpage').removeClass('disabled');
			$('.nextpage').attr("href", "#viewreport/" + btoa(getnavurlid));
		} else {
			$('.nextpage').addClass('disabled');
		}
	}
	saveReport(reportId, reportdata) {
		var that = this;
		var entities = "";
		var datamodelId = this.state.datamodelId;
		var isSharedReport = false;
		var isViewModel = false;
		var isImportedReport = false;
		if (window.location.pathname.indexOf("importreport") > 0) {
			isImportedReport = true;
		}
		var requestbody = new FormData();
		var fragmentArray = window.location.pathname;
		if (window.location.pathname.indexOf("viewcustomstoryboard") > 0) {
			that.state.reportId=parseInt(atob(fragmentArray.split('/')[4]))
			requestbody.set("reportId", that.state.reportId);
		}
		else{
			reportId=0
			requestbody.set("reportId", 0);
		}
		requestbody.set("reportdata", (reportdata));
		requestbody.set("columns", "");
		let requestHeaders = {
			method: "post",
			headers: new Headers({
				"Content-Type": "application/json"
			})
		};
		SendRequest({
			url: "savecustomstoryboards",
			body: requestbody,
			sucessFunction: (props) => {
				$(".spinner").hide();
				console.log(props);
				if (props.reportId != 0) {
					if (props.success == true) {
						$('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
						$('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
						$('.birdmessagebuttons').removeClass('show');
						$('.birdmessagebuttons').addClass('hide');
						$('.birdmessage-center').addClass("message-top-in");
						$('.details').removeClass('show');
						$('.details').addClass('hide');
						$('.birdmessage h2').empty().append('Success');
						$('.birdmessage-info').empty().text(getMessage('BirdSuccess01'));
						sessionStorage.setItem("changesdone", 0);
					}
					// var reportdata = JSON.parse(model.get("reportdata"));
					// var details = reportdata[1]["details"];
					// this.broker.trigger("savestoryboardchanges", model.get("reportId"),details["name"], details["folder"], details["desc"],details["maxage"],details["maxageunits"],details["maxpurgeindex"]);
					if (reportId == undefined||reportId == 0  || reportId == "") {
						setTimeout(function () {
							var href = "/viewcustomstoryboard";
							if (props != undefined && props.reportId != undefined) {
								$('.birdmessage-container .close').click();
								href = "/" + window.localStorage.getItem("appName") + "/welcome/viewcustomstoryboard/" + btoa(props.reportId);
								//window.open(href, '_self')
								try{
									that.props.history.push(href);
								}catch(e){

								}
								window.location.reload();
							}
						}, 2000);
					}
					$("#savestoryboard").removeClass("in");
					var that = this;
					//	setTimeout(function(){that.destroyview();}, 1500);
				} else {
					$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
					$('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
					$('.birdmessagebuttons').removeClass('show');
					$('.birdmessagebuttons').addClass('hide');
					$('.birdmessage-center').addClass("message-top-in");
					$('.details').removeClass('hide');
					$('.birdmessage h2').empty().append('Error');
					$('.birdmessage-info').empty().text(props.error);
					$('.details').addClass('show');
				}
				$(".spinner").hide();
			},
			rejectFunction: (props) => {
				var response = props;
				$(".spinner").hide();
				$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
				$('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
				$('.birdmessagebuttons').removeClass('show');
				$('.birdmessagebuttons').addClass('hide');
				$('.birdmessage-center').addClass("message-top-in");
				$('.details').removeClass('hide');
				$('.birdmessage h2').empty().append('Error : ');
				$('.birdmessage h2').append('<span class="errorcode">');
				$('.birdmessage .errorcode').empty().append(response.errorCode);
				$('.birdmessage-info').empty().text(response.error);
				$('.details').addClass('show').text("Details");
				$('.messagedetails xmp').empty().append(response.errorTrace)
			},
			headers: requestHeaders
		});
	}
}