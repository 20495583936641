import React from 'react'
import { lang } from "../js/helpers/utils/languages/language";
export default class StrorybookNewTabModelTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        var that = this.props;
        return (
            <div className="modal fade in" id="viewstorybooknewtab" tabIndex="-1" role="dialog" aria-labelledby="pagesettingsmodal" aria-hidden="true" style={{ display: "block" }}>
                {/* <!-- viewstorybooknewtabmodel.js --> */}
                <div className="modal-backdrop fade in"></div>
                <div className="modal-dialog modal-sm modal-sm-400 modal-dialog-sm-center" style={{ zIndex: "1050" }}>
                    <div className="modal-content">
                        <div className="modal-header boc-modal-hf-padding">
                            <button type="button" className="close white" id="sharereportclose" onClick={that.closeNewTabModel} aria-hidden="true" data-dismiss="modal">&times;</button>
                            <h4 className="modal-title"><i className="fa fa-cog"></i>{lang["customboard.addreport"]}</h4>
                        </div>
                        <div className="modal-body">
                            <div className="container-fluid">
                                <div className="row filtercontent">
                                    {/* <span className="required right legend"></span>
                                    <legend className="subhead">Tile Title</legend>
                                    <div className="validationgroup">
                                        <div className="col-xs-12 nopadding">
                                            <input type="name" id="tabname" name="tabname" defaultValue={that.newtabname} className="form-control input-sm" placeholder="Tab Title" />
                                            <span className='tabname-error help-block marg-left-10 hide'>Please Enter A Valid Name</span>
                                        </div>
                                        <span className="help-block hidden"></span>
                                    </div> */}
                                    <div className="clear"></div>
                                    <span className="required right legend"></span>
                                    <legend className="subhead">{lang["customboard.slctreport"]}</legend>
                                    <div className="col-xs-12 nopadding">
                                        <div className="validationgroup">
                                            <select name="grouping" data-placeholder="Select Reports" className="report-select modal-form-fields" id="groupings" dat multiple tabIndex="6">
                                
                                                <optgroup className="storyboardids" label={lang["customboard.reports"]}>
                                                    {that.reports.map((report, index) => {
                                                        return (that.previousReportIds.indexOf(parseInt(report.reportId)) == -1) ? (
                                                            <option key={index} value={report.reportId} data={`${report.reportId}&&&&${report.type}&&&&${report.dataModelId}`}>{report.reportname}</option>
                                                        ) : null
                                                    })}
                                                </optgroup>
                                            </select>
                                            <span className='reportid-error help-block marg-left-10 hide'>{lang["customboard.plzslctreport"]}</span>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="modal-footer boc-modal-hf-padding">
                            <button type="button" className="btn btn-bird" id="btn-birdsharecancel" onClick={that.closeNewTabModel} data-dismiss="modal" aria-hidden="true">
                                <i className="fa fa-times"></i> {lang["customboard.cancelbtn"]}	</button>
                            <button type="button" className="btn btn-bird addnewtab" onClick={that.addnewtab} id="addnewtabapply" aria-hidden="true">
                                <i className="fa fa-check"></i> {lang["customboard.applybtn"]}</button>
                        </div>
                    </div>
                    {/* <!-- /.modal-content --> */}
                </div>
                {/* <!-- /.modal-dialog -->
           <!-- /.Page Settings Modal --> */}
            </div>);
    }
}