import _ from 'lodash';
import React from "react";
import ReactDOM from "react-dom";
import PivotChartSettingsModalTemplate from "./pivotchartsettingsmodal.jsx"
import PubSub from "pubsub-js"
import SendRequest from '../../SendRequest';
import * as ModalProperties from "../../js/helpers/utils/modalproperties";
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import { getMessage } from '../../js/helpers/utils/BirdMessage';
import * as ChartUtil from "../../js/helpers/utils/chartutil";
import $ from "jquery/dist/jquery";


export default class PivotChartSettingsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chartnumber: props.chartnumber,
            broker: props.broker,
            measures: props.measures,
            dimensions: props.dimensions
        }
        this.render();
    }
    render() {
        this.state.el = ".pivotchartsettingsicons" + this.props.chartnumber;

        var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(this.props.chartnumber));
        var chartSettings = chartDetails['pivotChartSettings'];
        if (chartSettings == undefined) {
            chartSettings = { 'chartTitle': true, 'legend': false, 'xAxisLabel': true, 'yAxisLabel': true, 'dataLabel': false, 'crossHairs': false, 'gridLine': true };
        }
        ReactDOM.render(<PivotChartSettingsModalTemplate applyPivotChartSettingChanges={this.applyPivotChartSettingChanges} cancelSettingsModal={this.cancelSettingsModal} chartnumber={this.state.chartnumber} chartSettings={chartSettings} />, document.querySelector(this.state.el));
        return (<div></div>);
    }
    cancelSettingsModal = () => {
        $("body").removeClass("modal-open");
        ReactDOM.unmountComponentAtNode(document.querySelector(this.state.el));
    }
    applyPivotChartSettingChanges = (e) => {
        e.preventDefault();
        var $this = e.currentTarget;
        var charttype = this.props.charttype;
        if (this.props.charttype == undefined) {
            $('.pivotcharttypes').attr('data-type', "pivotbar");
        }
        var currentChartNumber = $($this).attr("data-num");
        var pivotChartSettings = {};
        var values = {};
        _.each($('.pivotchart_settings'), function (listObject) {
            if ($(listObject).prop('checked') == true) {
                pivotChartSettings[$(listObject).attr('id')] = true;
            } else {
                pivotChartSettings[$(listObject).attr('id')] = false;
            }
        });
        var chartData = ChartInfo.functions.getChartDetails(this.state.chartnumber);
        var data = JSON.parse(chartData);
        data["pivotChartSettings"] = pivotChartSettings;
        ChartInfo.functions.updateChartDetails(this.state.chartnumber, JSON.stringify(data));
        //			ChartInfo.updateChartDetails(this.props.chartnumber, JSON.stringify(values));
        $('.applypivotchartsettingchanges').attr('data-type', charttype);
        var obj = {};
        obj["chartNumber"] = this.state.chartnumber;
        obj["chartType"] = charttype;
        PubSub.publish("showPivotchart", obj);
        this.cancelSettingsModal();
    }
}