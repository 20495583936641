import React from 'react'
import { lang } from "../../js/helpers/utils/languages/language";

export default class CustomParameterTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        var that = this.props;
        return (
            // <!-- summarytablemodalview.js -->
            <div className={`modal fade customparametermodal${that.chartnumber} in`} id="customparametermodal" tabindex="-1" role="dialog" aria-labelledby="summarytablesettingsmodal" aria-hidden="true" style={{ display: "block" }}>
                <div className="modal-backdrop fade in"></div>
                <div className="modal-dialog modal-sm modal-sm-400 modal-dialog-sm-center modal-dialog-center" style={{ zIndex: "1050" }}>
                    <div className="modal-content">
                        <div className="modal-header boc-modal-hf-padding">
                            <button type="button" className="close white" id="close" onClick={that.closeParameterModal} aria-hidden="true" data-number={that.chartnumber} data-dismiss="modal">&times;</button>
                            <h4 className="modal-title" id="mySmallModalLabel"><i className="fa fa-sliders fa-rotate-270 fa-solid"></i> {lang["storyboard.customparameter"]}</h4>
                        </div>
                        <div className="modal-body scrollset">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-xs-12 marg-top-10 marg-bot-10">
                                        <div className="modal-height-fix">
                                            <legend className="subhead">{lang["storyboard.name"]}</legend>
                                            <div className="col-xs-12 nopadding">
                                                {(that.isEdit == false) ? (
                                                    <>
                                                        <input type="text" id="parametername" defaultValue={that.parameterName} className="modal-form-fields" name="parametername" placeholder={lang["storyboard.name"]} />
                                                        <span className="help-block hide customhierarchy">{lang["storyboard.validname"]}</span>
                                                        <span className='customparameter help-block marg-left-10 hide'>{lang["storyboard.nameesxists"]}</span>
                                                    </>
                                                ) :
                                                    <>
                                                        <input type="text" id="parametername" defaultValue={that.parameterName} className="modal-form-fields" name="parametername" placeholder={lang["storyboard.name"]} disabled="disabled" />
                                                        <span className="help-block hide customhierarchy">{lang["storyboard.validname"]}</span>
                                                        <span className='customparameter help-block marg-left-10 hide'>{lang["storyboard.nameesxists"]}</span>
                                                    </>
                                                }
                                            </div>
                                            <div className="clear"></div>

                                            <legend className="subhead">{lang["storyboard.currentval"]}</legend>
                                            <div className="col-xs-12 nopadding">
                                                {(that.isEdit == false) ? (
                                                    <>
                                                        <input type="number" id="parametercurrentvalue" defaultValue="" className="modal-form-fields" name="parametervalue" placeholder="" />
                                                        <span className="help-block hide customparametervalueerror">{lang["storyboard.entervalue"]}</span>
                                                    </>
                                                ) :
                                                    <>
                                                        <input type="number" id="parametercurrentvalue" defaultValue={that.parametercurrentvalue} className="modal-form-fields" name="parametervalue" placeholder="" />
                                                        <span className="help-block hide customparametervalueerror">{lang["storyboard.entervalue"]}</span>
                                                    </>
                                                }
                                            </div>
                                            <div className="clear"></div>
                                            <legend className="subhead">{lang["storyboard.allowvalues"]}</legend>
                                            <div className="col-xs-12 nopadding">
                                                <div className="marg-top-10">
                                                    {(that.isEdit == true) ? (
                                                        (that.parameterValueType == "all") ? (
                                                            <>
                                                                <label className="marg-righ-5"><input type="radio" id="1" name="allowablevalues" onChange={that.changeAllowableValues} className="allowablevalues parametervaluetype" value="all" defaultChecked="checked" /> {lang["storyboard.all"]}</label>
                                                                <label className="marg-righ-5"><input type="radio" id="2" name="allowablevalues" onChange={that.changeAllowableValues} className="allowablevalues parametervaluetype" value="list" /> {lang["storyboard.list"]}</label>
                                                                <label><input type="radio" id="3" name="allowablevalues" onChange={that.changeAllowableValues} className="allowablevalues parametervaluetype" value="range" /> {lang["storyboard.range"]}</label>
                                                            </>
                                                        ) : (that.parameterValueType == "list") ? (
                                                            <>
                                                                <label className="marg-righ-5"><input type="radio" id="1" name="allowablevalues" onChange={that.changeAllowableValues} className="allowablevalues parametervaluetype" value="all" /> {lang["storyboard.all"]}</label>
                                                                <label className="marg-righ-5"><input type="radio" id="2" name="allowablevalues" onChange={that.changeAllowableValues} className="allowablevalues parametervaluetype" value="list" defaultChecked="checked" /> {lang["storyboard.list"]}</label>
                                                                <label><input type="radio" id="3" name="allowablevalues" onChange={that.changeAllowableValues} className="allowablevalues parametervaluetype" value="range" /> {lang["storyboard.range"]}</label>
                                                            </>
                                                        ) : (
                                                                    <>
                                                                        <label className="marg-righ-5"><input type="radio" id="1" name="allowablevalues" onChange={that.changeAllowableValues} className="allowablevalues parametervaluetype" value="all" /> {lang["storyboard.all"]}</label>
                                                                        <label className="marg-righ-5"><input type="radio" id="2" name="allowablevalues" onChange={that.changeAllowableValues} className="allowablevalues parametervaluetype" value="list" /> {lang["storyboard.list"]}</label>
                                                                        <label><input type="radio" id="3" name="allowablevalues" onChange={that.changeAllowableValues} className="allowablevalues parametervaluetype" value="range" defaultChecked="checked" /> {lang["storyboard.range"]}</label>
                                                                    </>)
                                                    ) : <>
                                                            <label className="marg-righ-5"><input type="radio" id="1" name="allowablevalues" onChange={that.changeAllowableValues} className="allowablevalues parametervaluetype" value="all" defaultChecked="checked" />{lang["storyboard.all"]}</label>
                                                            <label className="marg-righ-5"><input type="radio" id="2" name="allowablevalues" onChange={that.changeAllowableValues} className="allowablevalues parametervaluetype" value="list" /> {lang["storyboard.list"]}</label>
                                                            <label><input type="radio" id="3" name="allowablevalues" onChange={that.changeAllowableValues} className="allowablevalues parametervaluetype" value="range" /> {lang["storyboard.range"]}</label>
                                                        </>}
                                                </div>
                                            </div>
                                            <div className="clear"></div>
                                            <div className="avcontainer avlist hide">
                                                <div className="parameter-list-box">
                                                    <div className="table-responsive">
                                                        <table className="table">
                                                            <tbody>
                                                                {(that.parameterValue != "") ? (
                                                                    <><tr>
                                                                        <td className='parameterlistfield0'><input type="number" defaultValue="" className="form-control input-sm parameterlistitems parameterlistvalue" id="parameterlistvalues" placeholder={lang["storyboard.val"]} /></td>
                                                                        <td className='parameterdisplayfield0'><input type="text" defaultValue="" className="form-control input-sm parameterlistitems parameterlistdisplayname" id="parameterlistdisplaynames" placeholder={lang["storyboard.displayas"]}/></td>
                                                                        <td align="center"><button className="btn btn-transparent addparameterlistitem tooltip-left" onClick={that.addParameterListItem} data-tooltip={lang["storyboard.add"]}><i className="fa fa-plus-circle"></i></button></td>
                                                                    </tr>
                                                                        {that.parameterValue.map((values, i) => {
                                                                            return <tr key={i}>
                                                                                <td className={`parameterlistfield${i}`}>{values.parameterlistvalue}</td>
                                                                                <td className={`parameterdisplayfield${i}`}>{values.parameterlistdisplayname}</td>
                                                                                <td align="center"><button onClick={that.removeParameterListItem} className="btn btn-transparent removeparameterlistitem"><i className="fa fa-trash"></i></button></td>
                                                                            </tr>
                                                                        })}
                                                                    </>) :
                                                                    <tr>
                                                                        <td className='parameterlistfield0'><input type="number" defaultValue="" className="form-control input-sm parameterlistitems parameterlistvalue" placeholder={lang["storyboard.val"]}/></td>
                                                                        <td className='parameterdisplayfield0'><input type="text" defaultValue="" className="form-control input-sm parameterlistitems parameterlistdisplayname" placeholder={lang["storyboard.displayas"]} /></td>
                                                                        <td align="center"><button className="btn btn-transparent addparameterlistitem tooltip-left" onClick={that.addParameterListItem} data-tooltip={lang["storyboard.add"]}><i className="fa fa-plus-circle"></i></button></td>
                                                                    </tr>
                                                                }

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="avcontainer avrange hide">
                                                <div className="marg-top-10">
                                                    <div className="col-xs-12">
                                                        <div className="col-lg-4 col-xs-12"><label className="control-label">{lang["storyboard.mini"]}  : </label></div>
                                                        <div className="col-lg-6 col-xs-12 nopadding"><input type="number" defaultValue={that.parameterrangevalues.rangemin} className="form-control input-sm rangemin" id="rangemins" /></div>
                                                    </div><div className="col-xs-12 marg-top-10">
                                                        <div className="col-lg-4 col-xs-12"><label className="control-label">{lang["storyboard.maxi"]}  : </label></div>
                                                        <div className="col-lg-6 col-xs-12 nopadding"><input type="number" defaultValue={that.parameterrangevalues.rangemax} className="form-control input-sm rangemax" id="rangemaxs" /></div>
                                                    </div><div className="col-xs-12 marg-top-10  marg-bot-10">
                                                        <div className="col-lg-4 col-xs-12"><label className="control-label">{lang["storyboard.stepsize"]} : </label></div>
                                                        <div className="col-lg-6 col-xs-12 nopadding"><input type="number" defaultValue={that.parameterrangevalues.rangestersize} className="form-control input-sm rangestersize" id="rangestersizes" /></div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        {/* <!-- /Modal Height Fix --> */}
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="modal-footer boc-modal-hf-padding">
                            <button type="button" className="btn btn-bird" id="btn-bird" onClick={that.closeParameterModal} data-dismiss="modal" aria-hidden="true"><i className="fa fa-times"></i> {lang["storyboard.cancelbtn"]}</button>
                            <button type="button" className="btn btn-bird customparameter_apply" onClick={that.createcustomparameter} id="customparameterapplys" data-num={that.chartnumber} tab-id="0" tab-name="" aria-hidden="true"><i className="fa fa-check"></i> {lang["storyboard.applybtn"]}</button>
                        </div>
                    </div>
                    {/* <!-- /.modal-content --> */}
                </div>
                {/* <!-- /.modal-dialog --> */}
            </div>
        );
    }
}