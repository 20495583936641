import React from 'react';
import { lang } from "../js/helpers/utils/languages/language";
export default class AddtoExtends extends React.Component {
    render() {
        return (
            <>
                {/* <!-- datasourceconnectionview.js --> */}
                <div className="modal-header boc-modal-hf-padding">
                    <h4 className="modal-title">
                        <i className="fa fa-link marg-righ-10"></i>
                        {lang["datahub.filestitle"]}
                    </h4>
                </div>
                <div className="modal-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xs-12">
                                {/* <!-- <h3>Connect MySQL</h3> --> */}
                                <form className="form-horizontal marg-top-10">
                                    <div className="form-group form-group-sm">
                                        <label htmlFor="" className="col-sm-5 control-label">
                                            <img className="pull-right" src={require(`../images/birdimages/${this.props.schemaName.toLowerCase()}.png`)} />
                                        </label>
                                        <div className="col-sm-6">
                                            <h3>{lang["datahub.upload"]} {this.props.schemaName} {lang["datahub.file"]}</h3>
                                        </div>
                                    </div>
                                    {(this.props.isFromEditFlatFiles === true) ? (
                                        <div className="form-group form-group-sm required">
                                            <label htmlFor="" className="col-sm-3 control-label">{lang["datahub.cnctnname"]}</label>
                                            <div className="col-sm-6">
                                                <input type="text" defaultValue={this.props.schemaDisplayName} className="form-control connectionname" placeholder="" />
                                                <span id="connectionname" className="help-block hide"></span>
                                                <span className="csvnameerror" style={{display: "none",color: "red"}}>
                                            {lang["datahub.namevalid"]}
                                            </span>
                                            <span class="csvnameerrors" style={{display: "none",color: "red"}}>{lang["datahub.nameerror"]}</span>
                                            </div>
                                        </div>
                                    ) : null}
                                    <div className="form-group form-group-sm required">
                                        <label htmlFor="" className="col-sm-3 control-label">{lang["datahub.puf"]}</label>
                                        <div className="col-sm-6">
                                            <span><b>{this.props.fileName}</b></span>
                                        </div>
                                    </div>
                                    <div className="form-group form-group-sm required">
                                        {/* <!-- <div className="col-sm-4"></div> --> */}
                                        <div className="col-sm-offset-3 col-sm-8">
                                            <span className="required right scopelabels"></span>
                                            <span>{lang["datahub.maxsize"]}{this.props.uploadsize}&nbsp;{lang["datahub.sizemb"]}</span>
                                            <span className="previousexportedxlsfiles"></span>
                                            <br />
                                            <div className="col-xs-12 nopadding">
                                                <input
                                                    id="fileupload"
                                                    type="file"
                                                    className="file"
                                                    name=""
                                                    data-show-preview="false"
                                                    accept={this.props.allow}
                                                    required="required"
                                                />
                                                <span className="csverror" style={{display: "none",color: "red"}}>{lang["datahub.slctfile"]}</span>
                                                <span className="csvcustomerror" style={{display: "none",color: "red"}}></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-offset-4 col-xs-6 nopadding">
                                        {/* <!-- For PDF Connector --> */}
                                        {(this.props.schemaName !== undefined && this.props.schemaName === "pdf") ? (
                                            <div className="pdfoptionscontainer show" style={{padding: "0 0 0 10px !important",margin: 0}}>
                                                <h4 className="modal-title">{lang["datahub.scanpages"]}</h4>
                                                <p>{lang["datahub.spcfypages"]}</p>
                                                <form>
                                                    <span className="appetitle">
                                                        <label>
                                                            <input 
                                                                type="radio" 
                                                                name="scanpages" 
                                                                className="appetitle" 
                                                                value="all" 
                                                                checked="checked" 
                                                            />{lang["datahub.allpages"]}
                                                        </label>
                                                    </span>
                                                    <div className="clear"></div>
                                                    <span className="appetitle">
                                                        <label>
                                                            <input 
                                                                type="radio" 
                                                                name="scanpages" 
                                                                className="appetitle" 
                                                                value="single" 
                                                            />{lang["datahub.snglepage"]}
                                                        </label>
                                                    </span>
                                                    <input 
                                                        type="number" 
                                                        value="1" 
                                                        min="1" 
                                                        id="pagenumber" 
                                                        className="modal-form-fields" 
                                                    />
                                                    <div className="clear"></div>
                                                    <span className="appetitle">
                                                        <label>
                                                            <input 
                                                                type="radio" 
                                                                name="scanpages" 
                                                                className="appetitle" 
                                                                value="range" 
                                                            />{lang["datahub.range"]}
                                                        </label>
                                                    </span>
                                                    <div className="clear"></div>
                                                    <div className="col-xs-12 marg-left-15">
                                                        <span className="appetitle">{lang["datahub.rangefm"]}</span>
                                                        <input type="number" id="fromrange" defaultValue="1" min="1" className="modal-form-fields" />
                                                        <span className="appetitle">{lang["datahub.rangeto"]}</span>
                                                        <input type="number" id="torange" defaultValue="2" min="1" className="modal-form-fields" />
                                                    </div>
                                                </form>
                                            </div>
                                        ) : null}
                                        {/* <!--\For PDF Connector --> */}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer marg-top-20">
                    <button type="button" className="btn btn-bird" id="closeconnectionpop" onClick={this.props.close}>
                        <i className="fa fa-times"></i> {lang["datahub.canclbtn"]}
                    </button>
                    <button type="button" className="btn btn-bird active csvsavenewconnection" id="savenewconnection" onClick={this.props.csvsavenewconnection}>
                        <i className="fa fa-refresh"></i> {lang["datahub.updatebtn"]}
                    </button>
                </div>
                {/* <!-- /datasourceconnectionview.js --> */}
            </>
        );
    }
}