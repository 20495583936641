import React from 'react';
import { lang } from "../js/helpers/utils/languages/language";
export default class ExistingEntitiesHtml extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        if (!this.props.showsettings) {
            return (
                <li className="viewreport-list-item ui-draggable ui-draggable-handle is-saved-entity" style={{ position: "relative", width: "241px", right: "auto", height: "36px", bottom: "auto", left: "0px", top: "0px" }}>
                    <label title={this.props.item}>
                    <span className="source"><i className="fa fa-link"></i>{this.props.conAccessName}</span>
                        {(this.props.entityType === "storedprocedure") ? (
                            <i className="fa fa-list-alt"></i>
                        ) : ((this.props.entityType === "views")?(<i className="fa fa-search-plus"></i>):(
                                <i className="fa fa-table"></i>
                            ))}
                        <input type="checkbox" className="scopeitem" entity-id={this.props.entityId} data_parent={this.props.connectionaccessid} name={this.props.item} id={this.props.item} data-value={this.props.item} is-saved="true" />{this.props.item}
                    </label>
                    <button onClick={this.props.editdatahubitem} className="btn btn-transparent btn-sm editdatahubitem" entity-id={this.props.entityId} data_parent={this.props.connectionaccessid} name={this.props.item} is-saved="true" type="button" title={lang["Datahub.settings"]}>
                        <i className="fa fa-cog"></i>
                    </button>
                    <button className="btn btn-transparent btn-sm joinremove" type="button" onClick={this.props.joinremove}>
                        <i className="fa fa-times"></i>
                    </button>
                </li>
            );
        } else {
            return (
                <li className="viewreport-list-item ui-draggable ui-draggable-handle is-saved-entity" style={{ position: "relative", width: "248px", right: "auto", height: "36px", bottom: "auto", left: "0px", top: "0px" }}>
                    <label title={lang["Datahub.filmstock"]}>
                    <span className="source"><i className="fa fa-link"></i>{this.props.conAccessName}</span>
                        <i className="fa fa-list-alt"></i>
                        <input type="checkbox" className="scopeitem storedprocedure" entity-id={this.props.entityId} data_parent={this.props.connectionaccessid} name={this.props.item} id={this.props.item} data-value={this.props.item} is-saved="true" />
                        {this.props.item}
                    </label>
                    <button className="btn btn-transparent btn-sm editstoredprocedure" onClick={this.props.loadstoredproceduredetails} entity-id={this.props.entityId} data_parent={this.props.connectionaccessid} name={this.props.item} is-saved="true" data-type={this.props.entity_type} type="button" title={lang["Datahub.parasettings"]}>
                        <i className="fa fa-cog"></i>
                    </button>
                    <button className="btn btn-transparent btn-sm joinremove" type="button" onClick={this.props.joinremove}>
                        <i className="fa fa-times"></i>
                    </button>
                </li>
            );
        }
    }
}