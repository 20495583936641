import React from "react";
import _ from 'underscore';
import ReactDOM from 'react-dom';
import SendRequest from '../../SendRequest';
import FeaturesTemplate from "./featureshtml.jsx";
import AddnewRole from "./addnewrole.js";
import FeaturesView from "./featuresView";
import { lang } from "../../js/helpers/utils/languages/language";
const $ =window.$;

export default class NewRolesViewTemplate extends React.Component{
  constructor(props){
super(props);
this.state={};
this.state.features={};
this.render();
  }
  savenewrole(){
    var serialized = $('form').serializeArray();
    var addrole = new AddnewRole();
}
getFeatures(){
  var roleId=$('.inheritedrole').val();
  var getfeatures = new FeaturesView({roleId : roleId});
}
  render(){
    var that=this.props;
    return (
        <div class="container-fluid nopadding">
        {/* <div class="alert alert-success rolecreationsuccess" style="display: none" role="alert">Role Created Successfully</div>
        <div class="alert alert-danger rolecreationerror" style="display: none" role="alert">Role Name Already Exist.</div> */}
        <div class="row in-back">
            <div class="max-height-30vh">
                <h2 class="boc-main-title">{lang["admin.roles.createtitle"]}</h2>
                <form class="form-horizontal col-xs-12 newroleform">
                <div class="form-group form-group-sm">
					<label for="rolename" class="col-sm-3 control-label important">{lang["admin.roles.createname"]}
</label>
					<div class="col-sm-6">
						<input type="name" class="form-control" id="rolename" name="rolename"
							placeholder=""/>
						<span class="help-block rolenameerror hidden"></span>
					</div>
				</div>
        <div class="form-group form-group-sm">
					<label for="roledescription" class="col-sm-3 control-label" >{lang["admin.roles.createdescrptn"]}</label>
					<div class="col-sm-6">
						<textarea class="form-control textarea" rows="2"
							id="roledesc" placeholder="" ></textarea>
					</div>
				</div>
        <div class="form-group form-group-sm">
					<label for="roleinheritedgroup" class="col-sm-3 control-label important" >{lang["admin.roles.createinherit"]}</label>
					<div class="col-sm-6">
						<select placeholder="" class="form-control inheritedrole" onChange={this.getFeatures} id="parentroleid" name="parentroleid">
							<option value="">{lang["admin.roles.chooserole"]} </option>
              {this.props.roles.map((role)=>{  
                if(role.roleName!='ROLE_Admin'&& role.roleName!='ROLE_Viewer' && role.parentRoleId==null ){
                  return	<option value={role.roleId}>{role.roleDisplayName}</option>
                }
							})
                        }	
						</select>
						<span class="help-block roleerror hidden"></span>
					</div>
				</div>
        <div class="form-group form-group-sm">
					<label for="" class="col-sm-3 control-label" >{lang["admin.roles.createstatus"]}</label>
					<div class="col-sm-8">
						<div>
							<input type="radio" id="statusa"  value="A" name="status" defaultChecked/>
							{lang["admin.roles.statusact"]}&nbsp;&nbsp;&nbsp; <input type="radio" name="status" id="statusi"
								value="I" /> {lang["admin.roles.statusdact"]}
						<span class="help-block hidden"></span>
						</div>
					</div>
				</div>
				<div class="form-group form-group-sm">
					<div id="role-accordiondiv" class="col-md-offset-3 col-sm-12 col-md-8 col-lg-6 col-xs-12"></div>
				</div>
				<div class="form-group form-group-sm marg-top-10">
					<div class="col-sm-offset-3 col-sm-6">
						<button type="button" class="btn btn-bird addnewrolebutton" onClick={this.savenewrole} id="addnewrole"><i class="fa fa-check marg-righ-10"></i>{lang["admin.roles.addbtn"]}</button>
					</div>
				</div>
                    </form>
                    </div>
                    </div>
                    </div>
    )}

}