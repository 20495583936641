import _ from 'lodash';
import React, { Component } from "react";
import ReactDOM from "react-dom";
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import { getMessage } from '../../js/helpers/utils/BirdMessage';
import { calendarFormat } from 'moment';
import { lang } from "../../js/helpers/utils/languages/language";
const $ = window.$;


export default class PivotValuesDropDown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addValue: props.addValue,
            values: props.values,
            chartnumber: props.chartnumber,
            broker: props.broker,
            facttables: props.facttables,
            multifact: (props.multifact == 'true'),
            multifactentities: props.multifact == 'true' ? JSON.parse(props.multifactentities) : props.multifactentities,
        }
        this.state.isStoredProcedure = ChartInfo.functions.getIsStoredProcedure();
        this.state.isDirect = ChartInfo.functions.getIsDirect();
        $(".pivotvaluesList").empty();
        this.render();
    }
    render() {
        this.state.el = ".pivotvaluesList"
        ReactDOM.unmountComponentAtNode(document.querySelector(this.state.el))

        $(".pivotvaluesList").append("<li class='xs-list-item'><input class='form-control form-control-pivot-values search-image' placeholder="+lang['storyboard.search']+"  id='searchinput' type=text'></li>");
        var tempColumnValues = _.clone(this.state.values);
        if ($('.pivotvalueattribute').length === 0) {
            var customFields = JSON.parse(sessionStorage.getItem("customFields"));
            var convertedTypes = {};
            _.each(JSON.parse(sessionStorage.getItem("convertedtypes")), function (list) {
                convertedTypes[list["columnName"]] = list["dataType"]
            })
            if (this.state.multifact) {
                var allowedEntities = ChartInfo.functions.getAllowedEntiesForPivotTable(this.state.multifactentities, customFields, this.state.facttables);
                if (allowedEntities != undefined && allowedEntities.length > 0) {
                    tempColumnValues = [];
                    var tesmpCustomFields = [];
                    _.each(this.state.values, function (column) {
                        if (column != undefined) {
                            var tempColumn = column;
                            var columnName = tempColumn.columnName;
                            var tablename = columnName.split(".")[0];
                            if (allowedEntities.indexOf(tablename) >= 0) {
                                tempColumnValues.push(tempColumn)
                            }
                        }
                    });

                    if (customFields != null && customFields != undefined) {
                        _.each(customFields, function (cfObj) {
                            var disable = false;
                            _.each(cfObj.usedFields, function (hierarchy) {
                                var tablename = hierarchy.split(".")[0];
                                if (allowedEntities.indexOf(tablename) < 0) {
                                    disable = true;
                                }
                            });
                            if (!disable) {
                                tesmpCustomFields.push(cfObj);
                            }
                        });
                        customFields = tesmpCustomFields;
                    }
                }
            }

            tempColumnValues.sort(function(a, b){
                var x = a.columnDisplayName.toLowerCase();
                var y = b.columnDisplayName.toLowerCase();
                return x < y ? -1 : x > y ? 1 : 0 ;
				});
				if(customFields != undefined && customFields.length != 0){
				customFields.sort(function(a, b){
                var x = a.name.toLowerCase();
                var y = b.name.toLowerCase();
                return x < y ? -1 : x > y ? 1 : 0 ;
				});
				}
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $(this.state.el).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");

            ReactDOM.render(
                <PivotValuesDropDownTemplate addValue={this.state.addValue} chartnumber={this.state.chartnumber} values={tempColumnValues} customFields={customFields != undefined && customFields != null ? customFields : []} convertedTypes={convertedTypes} isDirect={this.state.isDirect} isStoredProcedure={this.state.isStoredProcedure}></PivotValuesDropDownTemplate>
                , document.getElementById(dynamicdiv));
        } else {
            $(".spinner").hide();
            $(".gridspinner").hide();
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(getMessage('BirdWarning33'));
            /*$('#main').prepend('<div class="alert alert-danger birderror alert-dismissable affix-top" style="margin:0 auto;width: 60%;top: 44px;position: fixed;z-index: 1;left: 20%;">Only One Attribute is allowed in Value</div>');
                setTimeout(function() {
                    $('.birderror').remove();	
                }, 2000);*/
        }

        $(".form-control-pivot-values").keyup(function (e) {
            var $this = e.currentTarget;
            var valThis = $($this).val().toLowerCase();
            $(".itemname").parents(".xs-list-item").each(function () {
                var text = $(this).text().toLowerCase();
                (text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
            });

        })
        return (<div></div>)
    }
}

class PivotValuesDropDownTemplate extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        var that = this.props;
        return (<div>
            {that.values.map((value, index) => {
                return <li key={index} title={value.columnName} onClick={that.addValue} className={`xs-list-item pivotvalueitem ${value.visible == 'no' ? "hide" : ""}`} data-parent={value.tableName} data-child={value.columnName} data-type={value.tableDisplayType} data-name={value.columnDisplayName}>
                    <span className="itemtype">
                        {(value.tableDisplayType === 'number') ? (
                            123
                        ) : (value.tableDisplayType === 'date' || value.tableDisplayType === 'datetime' || value.tableDisplayType === 'month' || value.tableDisplayType === 'day') ? (
                            <i className="fa fa-calendar"></i>
                        ) : (value.tableDisplayType === 'customhierarchy' || value.tableDisplayType === 'custommeasurehierarchy') ? (
                            <i className="fa fa-list"></i>
                        ) :
                        lang["storyboard.abc"]
                        }</span>
                    <span className="itemname " data-parent={value.tableName} data-child={value.columnName} data-type={value.tableDisplayType}>{value.columnDisplayName}</span>
                    {(that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                        <span className="boclabel boclabel-right-5 opacity-effect boclabel-info">
                            {value.aggregation == undefined || value.aggregation.toLowerCase() == "none" ? "count" : value.aggregation}</span>
                    ) : null}
                </li>
            })}

            {that.customFields.map((row, index) => {
                return <li key={index} title={row.name} onClick={that.addValue} data-parent={row.name} data-child={row.name} data-type={row.dataType == undefined ? 'string' : row.dataType} data-name={row.name} className="xs-list-item pivotvalueitem">
                    <span className="itemtype fx">
                        {(row.dataType === 'number') ? (
                            123
                        ) : (row.dataType === 'date' || row.dataType === 'datetime' || row.dataType === 'month' || row.dataType === 'day') ? (
                            <i className="fa fa-calendar"></i>
                        ) : (row.dataType === 'customhierarchy' || row.dataType === 'custommeasurehierarchy') ? (
                            <i className="fa fa-list"></i>
                        ) :
                        lang["storyboard.abc"]
                        }</span>
                    <span className="itemname" data-parent={row.name} data-child={row.name} data-type={row.dataType == undefined ? 'string' : row.dataType}>{row.name}</span>
                    <span className="boclabel boclabel-right-5 opacity-effect boclabel-info">{lang["storyboard.count"]}</span>
                </li>
            })}
        </div>);
    }
}