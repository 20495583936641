import React from "react";
import { lang } from "../js/helpers/utils/languages/language";

export default class LegendAttrModalViewTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        var that = this.props;
        return (
            //    <!-- legendseriesattributesview.js -->
            <li className="boc-list-item singleliitems" id="legenditem" title={that.columnDisplayName} data-name={that.columnName} data-type={that.columnType} table-name={that.tableName}>
                <span className="itemcontainer">
                    <span className="itemtype">
                        {(that.iscustomfunction == 1 && that.iscustomfunction != undefined) ? (
                            <span className="function">{lang["storyboard.fx"]}</span>
                        ) : (that.iscustomfunction == 3) ? (
                            <i className="fa fa-arrows-h"></i>
                        ) :
                                (that.columnType === 'number') ? (
                                    <>123</>
                                ) : (that.columnType === 'date' || that.columnType === 'datetime' || that.columnType === 'month' || that.columnType === 'day') ? (
                                    <i className="fa fa-calendar"></i>
                                ) : (that.columnType == "customhierarchy") ? (
                                    <i className="fa fa-list-ol"></i>
                                ) :
                                            <span>{lang["storyboard.abc"]}</span>
                        }
                    </span>
                    <span className="itemname">{that.columnDisplayName}</span>
                </span>
                <span className="itemoptions">
                    <span className="itemremove">
                        <button className="btn btn-transparent deletelegendaxis" onClick={that.deletelegendaxis} type="button" title={lang["storyboard.remove"]}><i className="fa fa fa-trash-o"></i></button>
                    </span>
                    <span className="itemaggregation"></span>
                </span>
            </li>
        );
    }
}