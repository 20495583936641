import React from "react";
import ReactDOM from "react-dom";
import _ from 'lodash';
import $ from 'jquery/dist/jquery';
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import LegendDropDownTemplate from "./legenddropdown.jsx"
export default class LegendDropDownView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicdiv:props.dynamicdiv,
            addlegendsvalue: props.addlegendsvalue,
			columnDisplayName : props.columnDisplayName,
			columnName : props.columnName,
			dataType : props.dataType,
			tableName: props.tableName,
			customfield: props.customfield,
			facttables: props.facttables,
			multifact : (props.multifact == 'true'),
			multifactentities : (props.multifact == 'true')?JSON.parse(props.multifactentities):props.multifactentities,
        };
        this.render();
    }
    render() {
        var disable = false;
        var that = this;
        if(this.state.multifact){
            if(this.state.customfield=="1"){
                //iscustomfunction
                var  customFields = JSON.parse(sessionStorage.getItem("customFields"));
                var customFieldObject = $.grep(customFields, function(e){ return e.name == that.state.columnName});
                if(customFieldObject!=undefined && customFieldObject.length>0){
                    var isallowed=true;
                    _.each(customFieldObject[0].usedFields ,function(hierarchy){
                        var tablename = hierarchy.split(".")[0];
                        disable =ChartInfo.functions.getAllowedEntities(that.state.multifactentities,hierarchy, that.props.customfield,undefined,undefined,that.state.facttables,"xdropdown");
                        if(disable){
                            isallowed=false;
                        }
                    });
                    if(!isallowed){
                        disable=true;
                    }
                }
            }else{
                var tablename = this.state.columnName.split(".")[0];
                disable =ChartInfo.functions.getAllowedEntities(this.state.multifactentities,this.state.columnName, this.props.customfield,undefined,undefined,that.state.facttables,"xdropdown");
            }
        }
        if(this.state.dataType!=undefined && this.state.dataType!="customrange"){
                 ReactDOM.render(<LegendDropDownTemplate
                    addlegendsvalue= {this.state.addlegendsvalue}
                    dynamicdiv={this.state.dynamicdiv}
                   columnDisplayName ={this.state.columnDisplayName}
                   columnName ={ this.state.columnName}
                   dataType ={ this.state.dataType}
                   tableName ={ this.state.tableName}
                   customfunction ={ this.state.customfield}
                   disable={disable}
                   ></LegendDropDownTemplate>,document.getElementById(this.state.dynamicdiv));
        }
        return(<div></div>);
    }
}