import React from 'react';
import PubSub from 'pubsub-js';
import TimeseriesChart from '../../js/charts/timeseries';
import { lang } from "../../js/helpers/utils/languages/language";
const $ = window.$;
export default class SummarytableDrilldownModal extends React.Component {
    constructor(props){
        super(props);
        this.summaryColumnsSearch=this.summaryColumnsSearch.bind(this);
    }
    render() {
        let convertedTypes = this.props.convertedTypes;
        let calculatedFiledslist ;
        if(this.props.calculatedFileds != null && this.props.calculatedFileds != undefined) {
        calculatedFiledslist = this.props.calculatedFileds.map((valueObject) => {
if(convertedTypes!= null && !valueObject.priorityColumn){
        if(convertedTypes[valueObject.name]!=undefined){
            return <li className="boc-list-item drilldownitem" title={valueObject.name} data-type={convertedTypes[valueObject.name]}>
            <label><input type="radio" name="drilldown" className="drilldowncheckbox" id="drilldowncheckboxes1" data-type={convertedTypes[valueObject.name]} data-parent={valueObject.name} data-child={valueObject.name} data-name={valueObject.name} data-formula=""/>
            (convertedTypes[valueObject.name] == 'date' || convertedTypes[valueObject.name] == 'datetime' || convertedTypes[valueObject.name] == 'month' || convertedTypes[valueObject.name] == 'day') ?
            (<span className="itemtype"><i className="fa fa-calendar"></i></span>) :  (
                (convertedTypes[valueObject.name] == 'string') ? (<span className="itemtype">{lang["storyboard.abc"]}</span>) : (
                    (convertedTypes[valueObject.name] == 'number') ? (<span className="itemtype">123</span>) : null)){valueObject}</label>
            </li>
        }else if(valueObject.functiontype!="raw"){
            return <li className="boc-list-item drilldownitem" title={valueObject.name} data-type={valueObject.dataType}>
            <label><input type="radio" name="drilldown" className="drilldowncheckbox" id="drilldowncheckboxes1" data-type={valueObject.dataType} data-parent={valueObject.name} data-child={valueObject.name} data-name={valueObject.name} data-formula=""/>
            {(valueObject.dataType=='date' || valueObject.dataType =='datetime' || valueObject.dataType=='month' || valueObject.dataType=='day') ?
            (<span className="itemtype fx"><i className="fa fa-calendar"></i></span>) :  (
                (valueObject.dataType=='string') ? (<span className="itemtype fx">{lang["storyboard.abc"]}</span>) : (
                    (valueObject.dataType=='number') ? (<span className="itemtype fx">123</span>) : null))}{valueObject.name}</label>
            </li>
        }else{
            return <li className="boc-list-item drilldownitem" title={valueObject.name} data-type={valueObject.dataType}>
            <label><input data="hello" type="radio" name="drilldown" className="drilldowncheckbox" data-type={valueObject.dataType} data-parent={valueObject.name} data-child={valueObject.name} data-name={valueObject.name} data-formula="" />&nbsp;
            {(valueObject.dataType=='date' || valueObject.dataType =='datetime' || valueObject.dataType=='month' || valueObject.dataType=='day') ?
            (<span className="itemtype"><i className="fa fa-calendar"></i></span>) :  (
                (valueObject.dataType=='string') ? (<span className="itemtype">{lang["storyboard.abc"]}</span>) : (
                    (valueObject.dataType=='number') ? (<span className="itemtype">123</span>) : null))}{valueObject.name}</label>
            </li>
        }
    }else if(valueObject.functiontype!="raw" && !valueObject.priorityColumn){
            return <li className="boc-list-item drilldownitem" title={valueObject.name} data-type={valueObject.dataType}>
            <label><input type="radio" name="drilldown" className="drilldowncheckbox" data-type={valueObject.dataType} data-parent={valueObject.name} data-child={valueObject.name} data-name={valueObject.name} data-formula="" />&nbsp;
            if(valueObject.dataType=='date' || valueObject.dataType =='datetime' || valueObject.dataType=='month' || valueObject.dataType=='day'){<span className="itemtype"><i className="fa fa-calendar"></i></span>} 
            else if(valueObject.dataType=='string') {<span className="itemtype">{lang["storyboard.abc"]}</span>} 
    else if(valueObject.dataType=='number') {<span className="itemtype">123</span>}{valueObject.name}</label>
            </li>
    }else if(!valueObject.priorityColumn){
            return <li className="boc-list-item drilldownitem" title={valueObject.name} data-type={valueObject.dataType}>
            <label><input type="radio" name="drilldown" className="drilldowncheckbox" data-type={valueObject.dataType} data-parent={valueObject.name} data-child={valueObject.name} data-name={valueObject.name} data-formula="" />&nbsp; if(valueObject.dataType=='date' || valueObject.dataType =='datetime' || valueObject.dataType=='month' || valueObject.dataType=='day'){<span className="itemtype"><i className="fa fa-calendar"></i></span>} else if(valueObject.dataType=='string') {<span className="itemtype">{lang["storyboard.abc"]}</span>} else if(valueObject.dataType=='number') {<span className="itemtype">123</span>}{valueObject.name}</label>
            </li>
    }
});
        }
        return (
            <div className="modal fade in" id="summarytable-drilldown" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-num={this.props.currentchartno} tab-id="0" tab-name="" style={{ display: "block" }}>
                {/* <div className="modal-backdrop fade in"></div> */}
                <div className="modal-dialog modal-sm modal-sm-400 modal-dialog-sm-center modal-dialog-center">
                    <div className="modal-content">
                        <div className="modal-header boc-modal-hf-padding">
                            <button type="button" className="close white" id="close" data-dismiss="modal" aria-hidden="true" onClick={()=>{this.props.close(this.props.currentchartno)}}>&times;</button>
                            <h4 className="modal-title" id="mySmallModalLabel"><i className="fa fa-arrow-down"></i> {lang["storyboard.choosedrilldown"]}</h4>
                        </div>
                        <div className="modal-body scrollset">
                            <div className="container-fluid marg-top-5">
                                <input className="form-control search-image form-control-summarycolumns" onKeyUp={this.summaryColumnsSearch} placeholder={lang["storyboard.search"]} id="searchinput" type="text" />
                            </div>
                            <div className="container-fluid">
                                <div className="row filtercontent summarytable-filteritems">
                                    <ul className="list-group scrollsetul">
                                        <li className="dropdown-header prioritycol prioritycolumns"><i className="fa fa-magic" aria-hidden="true"></i> &nbsp;{lang["storyboard.priorityclmns"]}</li>
                                        {
                                            this.props.columns.map((valueObject) => {
                                                if (valueObject.priorityColumn) {
                                                    return (
                                                        <li className="boc-list-item drilldownitem" title={valueObject.tablename} data-type={valueObject.tableDisplayType}>
                                                            <label><input type="radio" name="drilldown" className="drilldowncheckbox" id="drilldowncheckboxes1" data-type={valueObject.tableDisplayType} data-parent={valueObject.tableName} data-child={valueObject.columnName} data-name={valueObject.columnDisplayName} data-formula="" />&nbsp;
                                            {
                                                                    (valueObject.tableDisplayType == 'date' || valueObject.tableDisplayType == 'datetime' ||
                                                                        valueObject.tableDisplayType == 'month' || valueObject.tableDisplayType == 'day') ? (<span className="itemtype"><i className="fa fa-calendar"></i></span>)
                                                                        : (valueObject.tableDisplayType == 'string') ? (<span className="itemtype">{lang["storyboard.abc"]}</span>) :
                                                                            (valueObject.tableDisplayType == 'number') ? (<span className="itemtype">123</span>) : null
                                                                }
                                                                {valueObject.columnDisplayName}
                                                            </label>
                                                        </li>
                                                    )

                                                }
                                            })
                                        }
                                        {/* {
                                        (this.props.calculatedFileds != undefined) ? (<>
                                            {
                                                this.props.calculatedFileds.map((valueObject) => {
                                                    if (this.props.convertedTypes != null && valueObject.priorityColumn) {
                                                        if (this.props.convertedTypes[valueObject.name] != undefined) {
                                                            let convertedTypes = this.props.convertedTypes;

                                                            return (<li className="boc-list-item drilldownitem" title={valueObject.name} data-type={convertedTypes[valueObject.name]}>
                                                                <label><input type="radio" name="drilldown" className="drilldowncheckbox" data-type={convertedTypes[valueObject.name]} data-parent={valueObject.name} data-child={valueObject.name} data-name={valueObject.name} data-formula="" />&nbsp;
       {
                                                                        (convertedTypes[valueObject.name] == 'date' || convertedTypes[valueObject.name] == 'datetime' ||
                                                                            convertedTypes[valueObject.name] == 'month' || convertedTypes[valueObject.name] == 'day') ? (<span className="itemtype"><i className="fa fa-calendar"></i></span>) :
                                                                            (convertedTypes[valueObject.name] == 'string') ? (<span className="itemtype">ABC</span>) : (convertedTypes[valueObject.name] == 'number') ? (<span className="itemtype">123</span>) : null
                                                                    }
                                                                    {valueObject.name}
                                                                </label>
                                                            </li>)
                                                        } else if (valueObject.functiontype != "raw") {
                                                            return (<li className="boc-list-item drilldownitem" title={valueObject.name} data-type={valueObject.dataType}>
                                                                <label>
                                                                    <input type="radio" name="drilldown" className="drilldowncheckbox" data-type="string" data-parent={valueObject.name} data-child={valueObject.name} data-name={valueObject.name} data-formula="" />&nbsp;
            {(valueObject.dataType == 'date' || valueObject.dataType == 'datetime' || valueObject.dataType == 'month' || valueObject.dataType == 'day') ? (<span className="itemtype"><i className="fa fa-calendar"></i></span>) :
                                                                        (valueObject.dataType == 'string') ? (<span className="itemtype">ABC</span>) :
                                                                            (valueObject.dataType == 'number') ? (<span className="itemtype">123</span>) : null
                                                                    }
                                                                    {valueObject.name}</label>
                                                            </li>)
                                                        } else {
                                                            return (<li className="boc-list-item drilldownitem" title={valueObject.name} data-type={valueObject.dataType}>
                                                                <label>
                                                                    <input type="radio" name="drilldown" className="drilldowncheckbox" data-type="string" data-parent={valueObject.name} data-child={valueObject.name} data-name={valueObject.name} data-formula="" />&nbsp;
                {(valueObject.dataType == 'date' || valueObject.dataType == 'datetime' || valueObject.dataType == 'month' || valueObject.dataType == 'day') ? (<span className="itemtype"><i className="fa fa-calendar"></i></span>) :
                                                                        (valueObject.dataType == 'string') ? (<span className="itemtype">ABC</span>) :
                                                                            (valueObject.dataType == 'number') ? (<span className="itemtype">123</span>) : null
                                                                    }
                                                                    {valueObject.name}</label>
                                                            </li>)
                                                        }
                                                    } else if (valueObject.functiontype != "raw" && valueObject.priorityColumn) {
                                                        return (<li className="boc-list-item drilldownitem" title={valueObject.name} data-type={valueObject.dataType}>
                                                            <label>
                                                                <input type="radio" name="drilldown" className="drilldowncheckbox" data-type="string" data-parent={valueObject.name} data-child={valueObject.name} data-name={valueObject.name} data-formula="" />&nbsp;
                {(valueObject.dataType == 'date' || valueObject.dataType == 'datetime' || valueObject.dataType == 'month' || valueObject.dataType == 'day') ? (<span className="itemtype"><i className="fa fa-calendar"></i></span>) :
                                                                    (valueObject.dataType == 'string') ? (<span className="itemtype">ABC</span>) :
                                                                        (valueObject.dataType == 'number') ? (<span className="itemtype">123</span>) : null
                                                                }
                                                                {valueObject.name}</label>
                                                        </li>)
                                                    }
                                                    else if (valueObject.priorityColumn) {
                                                        return (<li className="boc-list-item drilldownitem" title={valueObject.name} data-type={valueObject.dataType}>
                                                            <label>
                                                                <input type="radio" name="drilldown" className="drilldowncheckbox" data-type="string" data-parent={valueObject.name} data-child={valueObject.name} data-name={valueObject.name} data-formula="" />&nbsp;
{(valueObject.dataType == 'date' || valueObject.dataType == 'datetime' || valueObject.dataType == 'month' || valueObject.dataType == 'day') ? (<span className="itemtype"><i className="fa fa-calendar"></i></span>) :
                                                                    (valueObject.dataType == 'string') ? (<span className="itemtype">ABC</span>) :
                                                                        (valueObject.dataType == 'number') ? (<span className="itemtype">123</span>) : null
                                                                }
                                                                {valueObject.name}</label>
                                                        </li>)
                                                    }
                                                })
                                            }
                                        </>) : null

                                    } */}

                                        <li className="dropdown-header prioritycol availablecolumns">
                                            <i className="fa fa-magic" aria-hidden="true"></i> &nbsp;{lang["storyboard.availcolums"]}
                                        </li>
                                        {
                                            this.props.columns.map((valueObject,i) => {
                                                if (!valueObject.priorityColumn) {
                                                    return (<li key={i} className="boc-list-item drilldownitem" title={valueObject.tablename} data-type={valueObject.tableDisplayType}>
                                                        <label><input type="radio" name="drilldown" className="drilldowncheckbox" id="drilldowncheckboxes1" data-type={valueObject.tableDisplayType} data-parent={valueObject.tableName} data-child={valueObject.columnName} data-name={valueObject.columnDisplayName} data-formula="" />
                                                            {(valueObject.tableDisplayType == 'date' || valueObject.tableDisplayType == 'datetime' ||
                                                                valueObject.tableDisplayType == 'month' || valueObject.tableDisplayType == 'day') ?
                                                                (<span className="itemtype"><i className="fa fa-calendar"></i></span>) :
                                                                (valueObject.tableDisplayType == 'string') ?
                                                                    (<span className="itemtype">{lang["storyboard.abc"]}</span>) :
                                                                    (valueObject.tableDisplayType == 'number') ? (<span className="itemtype">123</span>) : null}
                                                            {valueObject.columnDisplayName}</label>
                                                    </li>)
                                                }
                                            })
                                        }

                        { (this.props.calculatedFileds != null && this.props.calculatedFileds != undefined) ? calculatedFiledslist: null}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer boc-modal-hf-padding">
                            <button type="button" className="btn btn-bird" id="btn-bird" data-dismiss="modal" onClick={()=>{this.props.close(this.props.currentchartno)}}><i className="fa fa-times"></i> {lang["storyboard.cancelbtn"]}</button>
                            <button type="button" className="btn btn-bird summarytable-drilldownapply" id="summarytabledrilldownapplying" data-num={this.props.currentchartno} tab-id="0" tab-name="" index={this.props.index} drilldownon={this.props.drilldownon} data-dismiss="modal" onClick={this.applysummary}><i className="fa fa-check"></i> {lang["storyboard.applybtn"]}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    summaryColumnsSearch(e){
        var $this= e.currentTarget;
        var valThis = $($this).val().toLowerCase()
        $("input:radio[class='drilldowncheckbox']").parents(".boc-list-item").each(
                function() {
                    var text = $(this).text().toLowerCase();
                    (text.indexOf(valThis) >= 0) ? $(this).show(): $(this).hide();
                });
    }
    applysummarytable=(e)=>{
        $("#spinner"+this.props.currentchartno).show();
        var $this = e.currentTarget;
        var applyOnj={};
        applyOnj["target"]=$this;
        applyOnj["drilldownon"]=this.props.drilldownon;
        applyOnj["chartno"]=this.props.currentchartno;
        applyOnj["index"]=this.props.index;
        PubSub.publish("applysummarytabledrilldown",applyOnj);
        sessionStorage.setItem("summarydrilldowntrigger","0");
        //this.props.close(this.props.currentchartno);
    }
    applysummary=(e)=>{
        this.applysummarytable(e);
        //this.props.close(this.props.currentchartno);
        setTimeout(()=>{
            //$("#spinner"+this.props.currentchartno).hide();
        }, 1000);
        
    }
}