import React from 'react';
import PubSub from 'pubsub-js'
import $ from "jquery";
import BootstrapTable from 'react-bootstrap-table-next';
import BootStrapTableComp from "./bootstraptable";

import paginationFactory from 'react-bootstrap-table2-paginator';
const RemotePagination = ({ data, page, columns, chartnumber, sizePerPage, onTableChange, totalSize,showTotal }) => (

    <div className={`drawbootstraptable${chartnumber}`}>
        <div className="notifications-table-container">
            <BootstrapTable
                remote
                keyField={columns[0].dataField}
                data={data}
                columns={columns}
                pagination={paginationFactory({ page, sizePerPage, totalSize,showTotal })}
                onTableChange={onTableChange}
                noDataIndication="No matching records found"
            />
        </div>
    </div>
);

export default class SummaryViewHeaderTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            data: props.data.slice(0, 10),
            sizePerPage: 10,
            columns: props.columns,
            chartnumber: props.chartnumber,
            totalRecords: props.totalRecords,
            showTotal: true
        };
    }
    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, data ,showTotal}) => {
        const currentIndex = (page - 1) * sizePerPage;
        {
          type == "sort" ?
            setTimeout(() => {
              let result;
              if (sortOrder === 'asc') {
                result = data.sort((a, b) => {
                  if (a[sortField] > b[sortField]) {
                    return 1;
                  } else if (b[sortField] > a[sortField]) {
                    return -1;
                  }
                  return 0;
                });
              } else {
                result = data.sort((a, b) => {
                  if (a[sortField] > b[sortField]) {
                    return -1;
                  } else if (b[sortField] > a[sortField]) {
                    return 1;
                  }
                  return 0;
                });
              }
              this.setState(() => ({
                data: result
              }));
            }, 10) :
            setTimeout(() => {
              this.setState(() => ({
                page,
                data: data.slice(currentIndex, currentIndex + sizePerPage),
                sizePerPage
              }));
    
            }, 10);
        }
      }

    render() {
        const { data, sizePerPage, page, columns, totalRecords, chartnumber,showTotal } = this.state;
        return (
            <div className="col-xs-12 marg-top-10">
                <div className="notifications-table-container">
                    <RemotePagination
                        data={data}
                        page={page}
                        sizePerPage={sizePerPage}
                        totalSize={totalRecords}
                        onTableChange={this.handleTableChange}
                        columns={columns}
                        chartnumber={chartnumber}
                        showTotal= {false}
                    />
                </div>
            </div>
        );
    }
}