import React from 'react';
import { lang } from "../js/helpers/utils/languages/language";
const $=window.$;

export default class ViewReportInfo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			reportInfo: props.reportInfo,
			connectionInfo: props.connectionInfo,
			sharedInfo: props.sharedInfo,
			scheduleInfo: props.scheduleInfo,
			filtersArray: props.filtersArray,
			alertsInfo: props.alertsInfo,
			timeZone: props.timeZone,
			totalDataCount: props.totalDataCount,
			filterDataCount: props.filterDataCount,
			isStoryboard: props.isStoryboard,
			buildnumber: props.buildnumber,
			isMLModel: props.isMLModel
		}
	}
	close=()=>{
		var that = this;
		if(that.props.close.name!=""){
			that.props.close()
		}else{
			$('.quickinfocontainer').css("display","none");
		}
	}
	render() {
		return (
			// <!-- Quick Info Container -->
			// <!-- viewreportinfo.html -->
			<div className="row quickinfocontainer" style={{display: this.props.isDisplay? "block":"none"}}>
				<div className="col-xs-12 nopadding">
					<div className="quickinfoboxcontainer">
						<div className="quickinfoboxcaption">
							<i className="fa fa-info-circle marg-righ-5"></i> {lang["storyboard.quickinfo"]}
					<button type="button" className="btn btn-sm btn-transparent pull-right quickinfotoggle nopadding" id="" title={lang["storyboard.quickinfo"]} onClick={this.close}>
								<i className="fa fa-times"></i>
							</button>
						</div>
						<div className="quickinfoboxrow">
							{/* <!-- Quick Info 1 --> */}
							{/* <!-- Quick Info 2 --> */}
							<div className="quickinfobox">
								<div className="quickinfoboxcontentdiv">
									<h4 className="quickinfotitle">
										<i className="fa fa-signal"></i> {lang["storyboard.reportinfo"]}</h4>
									<table className="table">
										<tbody>
											{(this.state.reportInfo.reportName != undefined) ? (
												<tr>
													<td>{lang["storyboard.reportname"]}</td>
													<td>{this.state.reportInfo.reportName}</td>
												</tr>
											) : null}
											{(this.state.reportInfo.folder != undefined) ? (
												<tr>
													<td>{lang["storyboard.folder"]}</td>
													<td>{this.state.reportInfo.folder}</td>
												</tr>
											) : null}
											<tr>
												<td>{lang["storyboard.createdon"]}</td>
												<td>{this.state.reportInfo.createdOn}</td>
											</tr>
											{(this.state.reportInfo.lastUpdated != undefined) ? (
												<tr>
													<td>{lang["storyboard.lastupdated"]}</td>
													<td>{this.state.reportInfo.lastUpdated}</td>
												</tr>
											) : null}
											{/* {(this.state.reportInfo.lastUpdated != undefined) ? (
												<tr>
													<td>Last Updated</td>
													<td>{this.state.reportInfo.lastUpdated}</td>
												</tr>
											) : null} */}
											{(this.state.reportInfo.nextDataSyncTime != null) ? (
												<tr>
													<td>{lang["storyboard.nextdatasync"]}</td>
													<td>{this.state.reportInfo.nextDataSyncTime}</td>
												</tr>
											) : null}
											<tr>
												<td>{lang["storyboard.timezone"]}</td>
												<td>{this.state.reportInfo.timeZone}</td>
											</tr>
											{(this.state.reportInfo.totalViews != undefined) ? (
												<tr>
													<td>{lang["storyboard.totalviews"]}</td>
													<td>{this.state.reportInfo.totalViews}</td>
												</tr>
											) : null}
											<tr>
												<td>{lang["storyboard.favreport"]}</td>
												{this.state.reportInfo.favoriteReport ? (
													<td><i className="fa fa-star fa-green"></i> {lang["storyboard.yes"]}</td>
												) : (
														<td><i className="fa fa-star fa-red"></i>{lang["storyboard.no"]}</td>
													)}
											</tr>
											{(!this.state.isMLModel) ? (
												<tr>
													<td>{lang["storyboard.metaudit"]}</td>
													{(this.state.reportInfo.metaDataAudit) ? (
														<td><i className="fa fa-check fa-green"></i> {lang["storyboard.yes"]}</td>
													) : (
															<td><i className="fa fa-times fa-red"></i>{lang["storyboard.no"]}</td>
														)}
												</tr>
											) : null}
										</tbody>
									</table>
								</div>
							</div>
							{/* <!-- Quick Info 3 --> */}
							<div className="quickinfobox">
								<div className="quickinfoboxcontentdiv">
									<h4 className="quickinfotitle">
										<i className="fa fa-users"></i> {lang["storyboard.sharedinfo"]}
                            </h4>
									<div className="quickresponsive">
										{
											(this.state.sharedInfo.groups!=undefined || this.state.sharedInfo.users!=undefined)? (
												<>
												{(this.state.sharedInfo.groups.length != 0 || this.state.sharedInfo.users.length != 0) ? (
											<table className="table">
												<tbody>
													<tr>
														<td>
															<b><i className="fa fa-users"></i> {lang["storyboard.groups"]}</b>
														</td>
													</tr>
													{(this.state.sharedInfo.groups.map((group, index) => {
														var parsedpermitedColumns = JSON.parse(group.reportcolumns);
														var dataTypeClass = "";
														return (
															<tr>
																<td>
																	<span className="pull-left">{group.groupname}</span>
																	<span className="pull-right">
																		{(group.viewonly == 1) ? (
																			<span title={lang["storyboard.viewaccess"]}><i className="fa fa-eye-slash"></i></span>
																		) : null}
																		<span className="restrictedcolumns dropdown-toggle dropdown" data-toggle="dropdown" aria-expanded="false" title={lang["storyboard.permitcolumns"]}>
																			<i className="fa fa-columns"></i>
																		</span>
																		<ul className="dropdown-menu dropdown-menu-right megadropdown">
																			{(group.reportcolumns == null) ? (
																				<li><a href="javascript:void(0)"><span>{lang["storyboard.allowcolumns"]}</span></a></li>
																			) : (
																					<>
																						{parsedpermitedColumns.map((column, index) => {
																							if (column.tableDisplayType === "number") {
																								dataTypeClass = "fa fa-sort-numeric-asc marg-righ-10";
																							} else if (column.tableDisplayType == "string") {
																								dataTypeClass = "fa fa-sort-alpha-asc marg-righ-10";
																							} else {
																								dataTypeClass = "fa fa-calendar marg-righ-10";
																							}
																							return (
																								<>
																									<li className="dropdown-header">{lang["storyboard.permitcolumns"]}</li>
																									<li>
																										<a href="javascript:void(0)">
																											<i className={dataTypeClass}></i>
																											<span>{column.columnName}</span>
																										</a>
																									</li>
																								</>
																							);
																						})}

																					</>
																				)}
																		</ul>
																	</span>
																</td>
															</tr>
														);
													}))}
												</tbody>
												<tbody>
													<tr>
														<td><b><i className="fa fa-user"></i> {lang["storyboard.users"]}</b></td>
													</tr>
													{this.state.sharedInfo.users.map((user, index) => {
														var parsedpermitedColumns = JSON.parse(user.reportcolumns);
														return (
															<tr>
																<td>
																	<span className="pull-left">{user.useremail}</span>
																	<span className="pull-right">
																		{(user.viewonly == 1) ? (
																			<span title={lang["storyboard.viewaccess"]}>
																				<i className="fa fa-eye-slash"></i>
																			</span>
																		) : null}
																		<span className="restrictedcolumns dropdown-toggle dropdown" data-toggle="dropdown" aria-expanded="false" title={lang["storyboard.permitcolumns"]}><i className="fa fa-columns"></i></span>
																		<ul className="dropdown-menu dropdown-menu-right megadropdown">
																			{(user.reportcolumns === null) ? (
																				<li><a href="javascript:void(0)"><span>{lang["storyboard.allowcolumns"]}</span></a></li>
																			) : (
																					<>
																						<li className="dropdown-header">{lang["storyboard.permitcolumns"]}</li>
																						{
																							parsedpermitedColumns.map((column, index) => {
																								var dataTypeClass;
																								if (column.tableDisplayType == "number") {
																									dataTypeClass = "fa fa-sort-numeric-asc marg-righ-10";
																								} else if (column.tableDisplayType == "string") {
																									dataTypeClass = "fa fa-sort-alpha-asc marg-righ-10";
																								} else {
																									dataTypeClass = "fa fa-calendar marg-righ-10";
																								}
																								return (
																									<li>
																										<a href="javascript:void(0)">
																											<i className={dataTypeClass}></i>
																											<span>{column.columnName}</span>
																										</a>
																									</li>
																								);
																							})
																						}
																					</>
																				)}
																		</ul>
																	</span>
																</td>
															</tr>
														);
													})}
												</tbody>
											</table>
										) : (
												<table className="table text-center fa-grey" style={{ height: "50%" }}>
													<tbody>
														<tr><td>{lang["storyboard.noshareinfo"]}</td></tr>
													</tbody>
												</table>
											)}
												</>
											):null
										}
										
									</div>
								</div>
							</div>

							{/* <!-- Quick Info 4 --> */}
							{(!this.state.isMLModel) ? (
								<div className="quickinfobox">
									<div className="quickinfoboxcontentdiv">
										<h4 className="quickinfotitle"><i className="fa fa-clock-o"></i> {lang["storyboard.scheduleinfo"]}</h4>
										<div className="quickresponsive">
											{(this.state.scheduleInfo.length !== 0) ? (
												<table className="table">
													{this.state.scheduleInfo.map((i, k) => {
														return (
															<tbody>
																<tr>
																	<td colspan="2">
																		<b><i className="fa fa-user"></i> {lang["storyboard.scheduleto"]}</b>
																	</td>
																</tr>
																<tr>
																	<td colspan="2">
																		<span className="quickinfomailto">
																			<i className="fa fa-envelope-o"></i>{this.state.scheduleInfo[k].scheduledTo}</span>
																	</td>
																</tr>
																<tr>
																	<td><b><i className="fa fa-lock"></i> {lang["storyboard.access"]}</b></td>
																	<td>{this.state.scheduleInfo[k].accessPermission}</td>
																</tr>
																{(this.state.scheduleInfo[k].dateField != null && this.state.scheduleInfo[k].dateField != "") ? (
																	<tr>
																		<td><b><i className="fa fa-calendar"></i> {lang["storyboard.datefield"]}</b></td>
																		<td>{this.state.scheduleInfo[k].dateField}</td>
																	</tr>
																) : null}
																<tr>
																	<td><b><i className="fa fa-clock-o"></i> {lang["storyboard.scheduled"]}</b></td>
																	<td>{this.state.scheduleInfo[k].scheduled}</td>
																</tr>
																<tr>
																	<td>{lang["storyboard.startime"]}</td>
																	<td>{this.state.scheduleInfo[k].startTime}</td>
																</tr>
																{(this.state.scheduleInfo[k].endTime != null && this.state.scheduleInfo[k].endTime != "") ? (
																	<tr>
																		<td>{lang["storyboard.endtime"]}</td>
																		<td>{this.state.scheduleInfo[k].endTime}</td>
																	</tr>
																) : null}
															</tbody>
														);
													})}
												</table>
											) : (
													<table className="table text-center fa-grey" style={{ height: "50%" }}>
														<tbody>
															<tr><td>{lang["storyboard.noscheduleinfo"]}</td></tr>
														</tbody>
													</table>
												)}
										</div>
									</div>
								</div>
							) : null}

							{/* <!-- Quick Info 5 --> */}
							<div className="quickinfobox">
								<div className="quickinfoboxcontentdiv">
									<h4 className="quickinfotitle">
										<i className="fa fa-filter"></i> {lang["storyboard.filters"]}
							</h4>
									<div className="quickresponsive">
										{(this.state.filtersArray.length != 0) ? (
											<div className="notifications-table-container">
												<table className="table quikinfofilters" data-show-export="true" data-toggle="table" data-pagination="false" data-smart-display="false" data-search="true">
													<thead>
														<tr>
															<th data-field="cname" >{lang["storyboard.columname"]}</th>
															<th data-field="fdata">{lang["storyboard.filterdata"]}</th>
														</tr>
													</thead>
													<tbody>
														{this.state.filtersArray.map((i, k) => {
															return (
																<tr>
																	<td>{i.columnname}</td>
																	<td title={i.filterdata}>{i.filterdata}</td>
																</tr>
															);
														})}
													</tbody>
												</table>
											</div>
										) : (
												<table className="table text-center fa-grey" style={{ height: "50%" }}>
													<tbody>
														<tr><td>{lang["storyboard.nofilters"]}</td></tr>
													</tbody>
												</table>
											)}
									</div>
								</div>
							</div>
							{/* <!-- Quick Info 6 --> */}
							{(!this.state.isMLModel) ? (
								<div className="quickinfobox">
									<div className="quickinfoboxcontentdiv">
										<h4 className="quickinfotitle">
											<i className="fa fa-exclamation-triangle"></i> {lang["storyboard.alertsinfo"]}
							</h4>
										<div className="quickresponsive">
											{(this.state.alertsInfo.length > 0) ? (
												<table className="table quickalertinfo">
													{this.state.alertsInfo.map((alert, i) => {
														return (
															<tbody>
																<tr>
																	<td>{lang["storyboard.alertid"]}</td>
																	<td>{alert.alertId}</td>
																</tr>
																<tr>
																	<td>{lang["storyboard.alerttitle"]}</td>
																	<td>{alert.alertName}</td>
																</tr>
																<tr>
																	<td>{lang["storyboard.notifyto"]}</td>
																	<td>
																		{alert.userEmailOBj.map((emailObj, j) => {
																			if (emailObj.users) {
																				return (
																					<span className="quickinfomailto">
																						<i className="fa fa-envelope-o"></i>{emailObj.userEmail}
																					</span>
																				);
																			} else {
																				return (
																					<span className="quickinfomailto">
																						<i className="fa fa-users"></i>{emailObj.userEmail}
																					</span>
																				);
																			}
																		})}
																	</td>
																</tr>
															</tbody>
														);
													})}
												</table>
											) : (
													<table className="table text-center fa-grey" style={{ height: "50%" }}>
														<tbody>
															<tr><td>{lang["storyboard.noalerts"]}</td></tr>
														</tbody>
													</table>
												)}
										</div>
									</div>
								</div>
							) : null}
						</div>
					</div>
				</div>
			</div>
			// <!-- /Quick Info Container -->
		);
	}
}