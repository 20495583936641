import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { lang } from "../js/helpers/utils/languages/language";
import SendRequest from '../SendRequest';
import _ from 'underscore';
const $ = window.$;
const columns = [
    { dataField: 'id', text: 'S.No.', sort: false, headerAlign: 'center', align: 'center' },
    { dataField: 'customstoryboard_name', text: 'Report Name', sort: true },
    { dataField: 'customstoryboard_desc', text: 'Description', sort: true },
    { dataField: 'create_date', text: 'Created Date', sort: true },
    { dataField: 'is_customstoryboard', text: 'Report Type', sort: false },
    { dataField: 'viewReport', text: 'View Report', sort: false, headerAlign: 'center', align: 'center' },
    { dataField: 'deleteReport', text: 'Delete Report', sort: false, headerAlign: 'center', align: 'center' }
];

const RemotePagination = ({ data, page, sizePerPage, onTableChange, totalSize }) => (
    // <div className="notifications-table-container">
        <BootstrapTable
            remote
            keyField="id"
            data={data}
            columns={columns}
            pagination={paginationFactory({ page, sizePerPage, totalSize })}
            onTableChange={onTableChange}
            noDataIndication="No matching records found"
        />
    // </div>
);
export default class CustomstoryboardListTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            data: props.data,
            sizePerPage: props.sizePerPage,
            totalRecords: props.totalRecords,
            search: props.search
        };
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, data, totalRecords }) => {
        const currentIndex = (page - 1) * sizePerPage;
        {
            var that = this;
            type == "sort" ?
                setTimeout(() => {
                    let requestHeaders = {
                        method: "get",
                        headers: new Headers({
                            "Content-Type": "application/json"
                        })
                    };
                    SendRequest({
                        url: "getdashboardreportslist",
                        queryString: "?fromDate= " + " " + "&toDate= " + " " + "&index= " + currentIndex + "&noOfRecords=" + sizePerPage + "&searchCriteria=" + that.state.search + "&order=" + sortOrder + "&colName=" + sortField +  "&urltype=" + "getUserPaginationStorybooks",
                        sucessFunction: (response) => {
                            if (response.hasOwnProperty('error')) {
                                $(".spinner").hide();
                            } else {
                                var headerlist = response["header"];
                                var data = response["data"];
                                _.each(data, function (val, index) {
                                    val.viewReport = <a href={`${that.props.url}/viewcustomstoryboard/${btoa(val.storybook_id)}`} data-num={val.storybook_id} class="boc-snapshot-thumbnail reportview viewviewcustomstoryboard" tab-id="0" tab-name="" id="reportstoryboardview" is_shared={val.isShared}><i class="fa fa-bar-chart"></i></a>;
                                    val.deleteReport = <button type="button" onClick={that.props.deleteReport} id="deletestorybooklistiew" data-num={val.storybook_id} tab-id="0" tab-name="" is-shared={val.isShared} is-favourite={val.isUserFavourite} className={`class="btn btn-transparent aldelete" ${that.props.deletecustomstoryboard}`}><i class="fa fa-trash-o"></i></button>;
                                });

                                var keys = Object.keys(headerlist);
                                this.setState(() => ({
                                    page,
                                    data: data,
                                    sizePerPage,
                                    totalSize: totalRecords
                                }));
                            }
                        },
                        rejectFunction: () => { },
                        headers: requestHeaders
                    })
                }, 10) :
                setTimeout(() => {
                    let requestHeaders = {
                        method: "get",
                        headers: new Headers({
                            "Content-Type": "application/json"
                        })
                    };
                    SendRequest({
                        url: "getdashboardreportslist",
                        queryString: "?fromDate= " + "" + "&toDate= " + "" + "&index= " + currentIndex + "&noOfRecords=" + sizePerPage + "&searchCriteria=" + that.state.search + "&order=" + sortOrder + "&colName=" + "" + "&urltype=" + "getUserPaginationCustomstoryboards",
                        sucessFunction: (response) => {
                            if (response.hasOwnProperty('error')) {
                                $(".spinner").hide();
                            } else {
                                var headerlist = response["header"];
                                var data = response["data"];
                                _.each(data, function (val, index) {
                                    val.viewReport = <a href={`${that.props.url}/viewcustomstoryboard/${btoa(val.storybook_id)}`} data-num={val.storybook_id} class="boc-snapshot-thumbnail reportview viewcustomstoryboard" tab-id="0" tab-name="" id="reportstoryboardview" is_shared={val.isShared}><i class="fa fa-bar-chart"></i></a>;
                                    val.deleteReport = <button type="button" onClick={that.props.deleteReport} id="deletecustomstoryboardlistiew" data-num={val.storybook_id} tab-id="0" tab-name="" is-shared={val.isShared} is-favourite={val.isUserFavourite} className={`class="btn btn-transparent aldelete" ${that.props.deletecustomstoryboard}`}><i class="fa fa-trash-o"></i></button>;
                                });
                                var keys = Object.keys(headerlist);
                                this.setState(() => ({
                                    page,
                                    data: data,
                                    sizePerPage,
                                    totalSize: totalRecords
                                }));
                            }
                        },
                        rejectFunction: () => { },
                        headers: requestHeaders
                    })

                }, 10);
        }
    }

    render() {
        const { data, sizePerPage, page, totalRecords } = this.state;
        return (
            <RemotePagination
                    data={data}
                    page={page}
                    sizePerPage={sizePerPage}
                    totalSize={totalRecords}                          
                    onTableChange={this.handleTableChange} />
        );
    }
}