import React from 'react';
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;

export default class createconnectionhtml extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
		let notfrequentlyused1 = this.props.notfrequentlyused1["streaming"];
        return(
			<>
			<div className="connection-backdrop"></div>
			<div className="connection-container">
				<div className="closenewscopeconnection" id="closeconnectionpop" onClick={this.props.close}><i className="fa fa-times"></i></div>
				<div className="otherconnections">
					<div className="container-fluid advancedcontainer nopadding">
						<div className="row marg-bot-15">
							<div className="col-xs-12">
								<h3 style={{padding: '0 0 0 20px;'}}><i className="fa fa-link fa-solid fa-flip-horizontal  marg-righ-10"></i>{lang["models.addnewsource"]}</h3>
							</div>
						</div>
						<div className="row advancedconnections" style={{minHeight: "350px",overflow: "unset", margin: "15px 0 0 0", display: "block", float: "left",width: "100%"}}>
							<div className="col-sm-12">
								<h4><i className="fa fa-line-chart fa-solid"></i> {lang["models.streaming"]}	</h4>
								<ul>{
                                    notfrequentlyused1.map((value1,key)=>
                                    {return (value1.type=="streaming")?
                                    <li className="connectionrequest" connectionid = {value1.connectionid} schemaname = {value1.name} 
                                    name ={value1.type} displayname={value1.connectionDisplayname} onClick={this.props.createnewsource}>
                                        <img src={require(`../images/birdimages/${value1.name.toLocaleLowerCase()}.png`)} />
                                        {value1.connectionDisplayname}
                                    </li> : null 
								})
								}</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			</>
		);
	}
}