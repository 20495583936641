import React from "react";
import 'jquery-validation/dist/jquery.validate';
import SendRequest from './SendRequest';
// import "./js/loginpageanimation";
import BirdLogo from './images/whitelable/bird-logo-night.png'
import './Login.css';
import { whitelableMap } from "./js/helpers/utils/whitelable";
import _ from 'underscore';
const $=window.$;

export default class ChangeAdminUserName extends React.Component {
  constructor(props) {
	super(props);
	
  }
  updateEmail=(e)=>{
      var that =this;
    e.preventDefault();
    $("#spinner").show();
       var updateEmail = $('#email').val();
       var formdata = new FormData();
       formdata.append("email", updateEmail);
       let requestHeaders = {
		method: "post",
		headers: new Headers({
			"Content-Type": "application/json"
		})
	};
	SendRequest({
		url: "changeadminemail",
		queryString: "",
		body:formdata,
		sucessFunction: (data) => {	
			//var result=JSON.parse(data);
			var result=data;
			 $("#spinner").hide();
			// that.props.history.push(`/${Properties.appName}/login`);
			window.location.replace("/")
		},
			rejectFunction: () => { 
				$("#spinner").hide();
				alert("Password Change Failed ");
				
			},
  headers: requestHeaders,
})
  }
componentDidMount(){
	$(".spinner").hide();
}
render(){
	var arr=[];
	arr.push(this.props.questionslist);
    return(
        <React.Fragment>
        <>
        {/* <div id="connections-animation"></div>
<div class="rightbg"></div> */}
	<div class="container-fluid height-100 logincontainer-fluid">
    <div class="row height-100 logincontainerrow">
			<div class="col-md-6 loginrightcontainer">
            <div class="panelcontainer" id="change_password">
			<div class="logincontainer animation-delay1">
				<div class="logo">
					<img alt="BIRDonCloud" src={whitelableMap["loginlogo"]} id="birdimg"/>
                    </div>
                    <div class="form">
					
                    <div class="tab-content">
                      <div id="login" style={{display: "block"}}>   
                      {
								(this.props.admin!=null) ? (
                                    <> 	
                                    <p class="error active">{this.props.admin}</p>
                                    </>
									) : ( 
										""
										   )
                                    }
                      <span class="header-message"></span>
					  <form  method="post" id="changeadminemail">
						  <div class="field-wrap">
							<label>Email Address<span class="req">*</span></label>
							<input type="email"  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$"  title="Please Enter a valid email ex. example@gmail.com"   required  autocomplete="on" name="email" id="email"/>
						  </div>
					  	  <button class="button button-block updateEmail" onClick={this.updateEmail}>Update<div class="ripples buttonRipples"><span class="ripplesCircle"></span></div></button>
					  </form>
                      </div>
					
				  </div>
				  
				</div> 

				</div>
			</div>
			<div className="footer copyright-footer">{whitelableMap["footertext"]}</div>
			</div> 
			<div class="col-md-6 loginleftcontainer"></div>
		</div>
    </div>
        </>
        </React.Fragment>
    )
}
}