import React from "react";
import _ from 'underscore';
import ReactDOM from 'react-dom';
import { lang } from "../../js/helpers/utils/languages/language";
import SaveUserDetails from "./saveuserdetails.js";
const $ =window.$;

export default class ResetPasswordHtml extends React.Component{
    constructor(props){
        super(props);
        this.state={};
          }
          render(){
            var that=this.props;
            return (
                <div class="container-fluid nopadding">
	{/* <div class="alert alert-success passwordsresetsuccess"
		role="alert">Passwords Reset Done Successfully</div>
	<div class="alert alert-danger showwarning" 
		role="alert">Please choose any one group or any one role.</div> */}
       
        	<div class="row in-back inline">
                <div class="max-height-30vh ">
                <h2 class="boc-main-title choosegrouporrole">{lang["admin.users.rsettitle"]}</h2>
                <form class="form-horizontal col-xs-12 ">
                <div class="form-group form-group-sm  ">
                <label for="group" class="col-sm-3 control-label important">{lang["admin.users.rsetgrp"]}</label>
                <div class="col-sm-3">
                <select class="form-control" id="passwordresetgroup" name="passwordresetgroup">
						<option value="" id="passwordresetgroup" name="passwordresetgroup" selected>{lang["admin.users.choosegrp"]}</option>					
                                {this.props.groupsresponse.map((data)=>{
                               return	<option value={data.groupId}>{data.groupName}</option>   
                               })
                           }
						</select> <span class="help-block hidden" >{lang["admin.users.chooseany"]}</span>

                </div>
                    </div>
                    <div class="form-group form-group-sm">
                    <label for="group" class="col-sm-3 control-label important">{lang["admin.users.rsetrole"]}</label>
                    <div class="col-sm-3">
							<select placeholder="" class="form-control selectedroles" id="role" name="role">
								<option value="">{lang["admin.users.chooserole"]}</option>
                                {this.props.rolesresponse.map((role)=>{	
                                if(role.roleDisplayName!="Admin"&&role.status=="A"){ 
                               return	<option value={role.roleName}>{role.roleDisplayName}</option>
                                }
                               })
                           }
							</select>
							<span class="help-block hidden"></span>
					</div>
                    </div>
                    <div class="form-group form-group-sm">
					<div class="col-sm-offset-3 col-sm-3">
						<button type="button" class="btn btn-bird passwordsresetapply" id="passwordsresetapply"><i class="fa fa-check marg-righ-5"></i>{lang["admin.users.applybtn"]}</button>
					</div>
				</div>	
			<div id="passwordsresettable"></div>
                </form>
                </div>
            </div>
            </div>
            )
          }

}