import React from 'react'
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;
export default class DataModelShareView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        var that = this.props;
        return (
            <div className="modal-content">
                <div className="modal-header boc-modal-hf-padding">
                    <button type="button" className="close white" onClick={that.sharemodelclose} id="sharemodelclose" aria-hidden="true">&times;</button>
                    <h4 className="modal-title"><i className="fa fa-share"></i>  {lang["models.sharemodel"]}</h4>
                </div>
                <div className="modal-body">
                    <div className="container-fluid">
                        <div className="row filtercontent">
                            {/* <!-- Basic Items Container --> */}
                            <div className="basicshareinfo">
                                <legend className="subhead"><i className="fa fa-cube fa-solid"></i>  {lang["models.datamodel"]}</legend>
                                <div className="col-xs-12 nopadding">
                                    <input type="text" defaultValue={that.dataModelName} className="modal-form-fields disabled" />
                                </div>
                                <div className="clear"></div>
                                <span className="required right legend"></span>
                                <legend className="subhead"><i className="fa fa-users" style={{ fontSize: "15px" }}></i>  {lang["models.groups"]}</legend>
                                <div className="col-xs-12 nopadding">
                                    <div className="validationgroup">
                                        <select name="grouping" data-placeholder={lang["models.slctusers"]} className="group-select modal-form-fields" id="groupings" multiple tabIndex="6">
                                            <optgroup className="groupids" label={lang["models.group's"]}>
                                                {that.groups.map((group, index) => {
                                                    return <option key={index} value={group.groupId}>{group.groupName}</option>
                                                })}
                                            </optgroup>
                                            <optgroup className="userids" label={lang["models.users"]}>
                                                {that.users.map((user, index) => {
                                                    if (!user.isLoginUser) {
                                                        return <option key={index} value={user.userid}>{`${user.username}(${user.email})`}</option>
                                                    }
                                                })}
                                            </optgroup>

                                        </select>
                                        <span className="help-block hidden fa-modal-alert"></span>
                                        <button className="btn btn-link pull-right btn-sm showadvanced" onClick={that.showadvanced}>{lang["models.advanced"]}</button>
                										<div className="row marg-bot-10 filtersearching " style={{display:"none"}}>
                            								   <div className="stringfilters searching ">
                                                               {lang["models.startswith"]} <input type="text" id="searchnamestartswith" data-id="searchnamestartswith" className="searchword" />
                            									</div>
                            									<div className="stringfilters searching">
                                                                {lang["models.endswith"]} <input type="text" id="searchnameendswith" data-id="searchnameendswith"/>
                            									</div>
                            									<div className="stringfilters searching col-lg-10 col-md-10 col-sm-12 col-xs-12" style={{marginLeft:"-5px"}}>
                                                                {lang["models.contains"]} <input type="text" id="searchnamecontains" data-id="searchnamecontains"/>
                            									</div>
                            								  <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12" style={{marginTop: "1px"}}>
                            									<button type="button" id="searchnames" className="btn btn-sm btn-bird marg-top-20 btn-highlight" onClick={that.searchusersgroups}>{lang["models.go"]}</button>
                            								  </div>
						</div>
                                    </div>

                                </div>
                                <div className="clear"></div>
                                <legend className="subhead"><i className="fa fa-comment" style={{ fontSize: "15px" }}></i>  {lang["models.comment"]}</legend>
                                <div className="validationgroup">
                                    <div className="col-xs-12 nopadding"><textarea id="sharemodelcomments" placeholder={lang["models.placeholdcomment"]} name="comments" className="modal-form-fields resize-vertical"></textarea></div>
                                    <span className="help-block hidden"></span>
                                </div>
                                <div className="col-xs-12 nopadding">
                                    <button className="btn btn-link pull-right btn-sm showadvancedsharemodel" onClick={that.advancedsharemodels} id="advancedsharemodels"><i className="fa fa-lock"></i> {lang["models.advancedpermison"]}</button>
                                </div>
                                <div className="clear"></div>
                            </div>
                            {/* <!-- Advanced Items Container --> */}
                            <div id="showadvanceditems"></div>
                            {/* <!-- Edit Advanced Item Data --> */}
                            <div className="datapermissiondiv hide">
                                <div className="modal-height-fix">
                                    <div className="col-xs-12 marg-bot-10"><i className="fa fa-shield"></i> {lang["models.restricteddata"]} <button className="btn btn-transparent pull-right bactosharemodel" onClick={that.bactosharemodel}><i className="fa fa-arrow-left"></i></button></div>
                                    <div className="clear"></div>
                                    <div className="permissionscroller">

                                        <div className="permissioncontainer dobyfilteritems rightpermissioncontainer">
                                            <div className="col-xs-12 marg-bot-10"><i className="fa fa-user"></i> {lang["models.sharedto"]}</div>
                                            <div className="clear"></div>
                                            {/* <!-- Panel Container --> */}
                                            <div className="panel-group permissioncontainerrightfilter">
                                                {/* <!-- viewreportaccordianview.js --> */}
                                            </div>
                                            {/* <!--/ Panel Container -->		 */}
                                        </div>

                                    </div>
                                    {/* <!-- /PermissionScroll --> */}

                                </div>
                            </div>
                            {/* <!--/Edit Advanced Item Data --> */}
                        </div>
                    </div>
                </div>
                {/* <!--datamodelshareview.js --> */}
                <div className="modal-footer boc-modal-hf-padding">
                    <button type="button" className="btn btn-bird" id="sharemodelclose" onClick={that.sharemodelclose} data-dismiss="modal" aria-hidden="true"><i className="fa fa-times"></i> {lang["models.cancelbtn"]}</button>
                    <button type="button" className="btn btn-bird sharemodelapply" id="sharemodelapply" onClick={that.sharemodelapply} aria-hidden="true"><i className="fa fa-share"></i> {lang["models.sharebtn"]}</button>
                    <button type="button" className="btn btn-bird datapermissionupdate hide" onClick={that.datapermissionupdate} id="datapermissionupdate" aria-hidden="true"><i className="fa fa-refresh"></i>{lang["models.updatebtn"]} </button>
                </div>
            </div>);
    }
}