import React, { Component } from "react";
import { lang} from "../../../js/helpers/utils/languages/language";
const $ =window.$;
export default class MailSettingsTemplate extends Component{
    constructor(props){
        super(props);
	}
	hideleft = ()=> {
		$('.leftarea').addClass('hide');
	
		$('.hideleft').removeClass('hide');
		$('.showleft').addClass('hide');
	
		$('.leftshow').removeClass('hide');
		$('.lefthide').addClass('hide');
	
		$('.w2ui-resizer').resize(); 
		$(".boc-admin-main .notifications-table-container table").bootstrapTable('resetView');
	  }
	  leftshow  = ()=>  {
		$('.leftarea').removeClass('hide');
	
		$('.hideleft').removeClass('hide');
		$('.showleft').addClass('hide');
		
		$('.leftshow').addClass('hide');
		$('.lefthide').removeClass('hide');
		
		$('.w2ui-resizer').resize();
		$(".boc-admin-main .notifications-table-container table").bootstrapTable('resetView');
	  }
	  lefthide = ()=> {
		$('#icontent').addClass('hide');
		$('.leftarea').addClass('shownsingle');
		$('.editrole').addClass('disabled');
		$('.editgroup').addClass('disabled');
		$('.editaccount3').addClass('disabled');
		$('.editschedule').addClass('disabled');
		$('.editmail').addClass('disabled');
		$('.viewhistory').addClass('disabled');
		
		$('.hideleft').addClass('hide');
		$('.showleft').removeClass('hide');
		
		$('.leftshow').addClass('hide');
		$('.lefthide').removeClass('hide');
		
		$('.w2ui-resizer').resize();
		$(".boc-admin-main .notifications-table-container table").bootstrapTable('resetView');
	  }
	  showleft = ()=>  {
		$('#icontent').removeClass('hide');
		$('.leftarea').removeClass('shownsingle');
		$('.editrole').removeClass('disabled');
		$('.editgroup').removeClass('disabled');
		$('.editaccount3').removeClass('disabled');
		$('.editschedule').removeClass('disabled');
		$('.editmail').removeClass('disabled');
		$('.viewhistory').removeClass('disabled');
		
		$('.hideleft').removeClass('hide');
		$('.showleft').addClass('hide');
		
		$('.leftshow').addClass('hide');
		$('.lefthide').removeClass('hide');
		
		$('.w2ui-resizer').resize();
		$(".boc-admin-main .notifications-table-container table").bootstrapTable('resetView');
	  }
    render(){
        var that = this.props;
        return(
            <div className="row in-back">
	        <div className="row-same-height-inner">
	
		<div className="col-xs-4 col-xs-height in-panes leftarea">
					<div className="btn btn-transparent hideleft toggleleft" onClick={this.hideleft}><i className="fa fa-grey fa-angle-left"></i></div>
					<div className="btn btn-transparent showleft toggleleft hide" onClick={this.showleft}><i className="fa fa-grey fa-angle-left"></i></div>
			<div className="pull-right marg-bot-10">
				<button className="btn btn-bird btn-sm addemailconfig pull-right" id="addemailconfigs" onClick={that.newMailConfigView}><i className="fa fa-cog marg-righ-5"></i>{lang["admin.mail.config"]}</button>
			</div>
			<div className="listcontainer col-xs-12">
				<div className="notifications-table-container">
                <div className="pull-left search" style={{padding: "6px 14px 6px 10px"}}>
                    <input className="form-control" type="text" placeholder={lang["admin.mail.search"]}/></div>
					<table id="otherstable" data-toggle="table" className="marg-top-10" data-pagination="true" data-page-size="25" data-smart-display="true" data-search="true" data-pagination-v-align="bottom" data-search-align="left">
						<thead>						
							<tr>
								<th data-sortable="true">{lang["admin.mail.email"]}</th>
								<th>{lang["admin.mail.status"]}</th>
								<th className="centertext">{lang["admin.mail.edit"]}</th>
							</tr>
						</thead>
						<tbody>
						 {( that.email != null && that.email != 'null' && that.email != "") ? 
							(<tr>
								<td>{that.email}</td>
								<td>{(that.status=='A')?"Active":"Inactive"}</td>
								<td className="centertext">
								<button	className="btn btn-admin editmail" id="editmails" type="button" value={that.clientId} onClick={that.editMailConfig}>
										<i className="fa fa-pencil"></i>
									</button></td>
							</tr>):null
							}
						</tbody>
					</table>
				</div>
			</div>
			<div className="clear" className="icontent"></div>
			</div>
					<div className="col-xs-6 col-xs-height in-panes" id="icontent">
					<div className="btn btn-transparent leftshow toggleright hide"  onClick={this.leftshow}><i className="fa fa-grey fa-angle-right"></i></div>
					<div className="btn btn-transparent lefthide toggleright"  onClick={this.lefthide}><i className="fa fa-grey fa-angle-right"></i></div>
					<div className="innerothersarea mailconfigform"> </div>
					</div>
			</div>
		</div>
			
		
        );
    }
}