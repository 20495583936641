import React from "react";
import ReactDOM from "react-dom";
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import _ from 'lodash';
import $ from 'jquery/dist/jquery';
import { lang } from "../js/helpers/utils/languages/language";
import AttrMeasuresModalTemplate from "./attributesmeasures.jsx"


export default class AttrModalMeasuresView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicdiv: props.dynamicdiv,
            features: props.features,
            broker: props.broker,
            columnDisplayName: props.columnDisplayName,
            columnName: props.columnName,
            dataType: props.dataType,
            aggregation: props.aggregation,
            tableName: props.tableName,
            //	template : _.template(AttrMeasuresModalTemplate),
            chartData: props.jsonData,
            isDirect: ChartInfo.functions.getIsDirect(),
            isStoredProcedure: ChartInfo.functions.getIsStoredProcedure(),
        };
        this.render();
    }
    render() {
        var that = this;
        var customfields = sessionStorage.getItem("customFields");
        var temp = JSON.parse(customfields);
        var isCustomField = false;
        var res;
        _.each(temp, function (customfield) {
            if (customfield.name == that.state.columnDisplayName)
                isCustomField = true;
        })
        this.state.isCustomField = isCustomField;
        this.state.el = "#" + this.state.dynamicdiv;

        const elementTarget = document.getElementById(that.state.dynamicdiv);
        if(elementTarget) {
            ReactDOM.render(<AttrMeasuresModalTemplate dynamicdiv={this.state.dynamicdiv} columnDisplayName={ this.state.columnDisplayName} columnName={ this.state.columnName}
                dataType={ this.state.dataType} aggregation={ this.state.aggregation} tableName={ this.state.tableName} allfeatures={ this.state.features} isCustomField={ this.state.isCustomField} isDirect={ this.state.isDirect} isStoredProcedure={ this.state.isStoredProcedure}/>,document.getElementById(that.state.dynamicdiv));
        }else{
            console.log(that.state.dynamicdiv);
        }
       
        // this.$el.append(this.template({
        //     columnDisplayName: this.state.columnDisplayName, columnName: this.state.columnName,
        //     dataType: this.state.dataType, aggregation: this.state.aggregation, tableName: this.state.tableName, allfeatures: this.state.features, isCustomField: this.state.isCustomField, isDirect: this.state.isDirect, isStoredProcedure: this.state.isStoredProcedure
        // }));


        if (this.state.chartData != undefined && this.state.chartData["sortingColumn"] != undefined && this.state.chartData["sortingColumn"] == this.state.columnName) {
            $(".sortingitem").append('<option data-name="' + this.state.columnName + '" data-agg="' + this.state.aggregation + '"data-type="' + this.state.tableDisplayType + '" title="' + this.state.columnDisplayName + '" selected="selected">' + this.state.columnDisplayName + ' (' + this.state.aggregation.replace(/[^A-Z0-9]/ig, "") + ') </option>');
        }
        else {
            $(".sortingitem").append('<option data-name="' + this.state.columnName + '" data-agg="' + this.state.aggregation + '"data-type="' + this.state.tableName + '" title="' + this.state.columnDisplayName + '">' + this.state.columnDisplayName + ' (' + (this.state.aggregation != undefined ? this.state.aggregation.replace(/[^A-Z0-9]/ig, "") : this.state.aggregation) + ') </option>');
        }
        /*var customFields =JSON.parse( sessionStorage.getItem("customFields"));
        var that=this;
        _.each(customFields,function(customFieldData,j){
            if(that.chartData["sortingColumn"]==customFieldData.name){
                $('.sortingitem option[data-name="'+customFieldData.name+'"]').attr("selected","selected");	
            }
        })*/
        $(this.state.el).find(".aggregation-li-item").removeClass("active");
        if (this.state.aggregation === "sum" || this.state.aggregation === "Sum") {
            $(this.state.el).find(".aggsum").addClass("active");
            $(this.state.el).find("#dropdownaggregation").text("Sum");
        }
        else if (this.state.aggregation === "max" || this.state.aggregation === "Max") {
            $(this.state.el).find(".aggmax").addClass("active");
            $(this.state.el).find("#dropdownaggregation").text("Max");
        }
        else if (this.state.aggregation === "min" || this.state.aggregation === "Min") {
            $(this.state.el).find(".aggmin").addClass("active");
            $(this.state.el).find("#dropdownaggregation").text("Min");
        }
        else if (this.state.aggregation === "count" || this.state.aggregation === "Count" || this.state.aggregation === "Countall" || this.state.aggregation === "countall" || this.state.aggregation === "Count (all)") {
            $(this.state.el).find(".aggcount").addClass("active");
            $(this.state.el).find("#dropdownaggregation").text("Count (all)");
        }
        else if (this.state.aggregation === "countdistinct" || this.state.aggregation === "Countdistinct") {
            $(this.state.el).find(".aggcountdistinct").addClass("active");
            $(this.state.el).find("#dropdownaggregation").text("Count (distinct)");
        }
        else if (this.state.aggregation === "avg" || this.state.aggregation === "Avg" || this.state.aggregation === "Average" || this.state.aggregation === "average") {
            $(this.state.el).find(".aggavg").addClass("active");
            $(this.state.el).find("#dropdownaggregation").text("Average");
        }
        else if (this.state.aggregation === "Variance" || this.state.aggregation === "variance") {
            $(this.state.el).find(".aggvariance").addClass("active");
            $(this.state.el).find("#dropdownaggregation").text("Variance");
        }
        else if (this.state.aggregation === "StandardDeviation" || this.state.aggregation === "standarddeviation") {
            $(this.state.el).find(".aggstandarddeviation").addClass("active");
            $(this.state.el).find("#dropdownaggregation").text("Standard Deviation");
        }
        else if (this.state.aggregation === "SumofSquares" || this.state.aggregation === "sumofsquares" || this.state.aggregation === "sum of squares"
            || this.state.aggregation === "Sum of Squares") {
            $(this.state.el).find(".aggsumofsquares").addClass("active");
            $(this.state.el).find("#dropdownaggregation").text("Sum of Squares");
        }
        else if (this.state.aggregation === "Percentile" || this.state.aggregation === "percentile") {
            $(this.state.el).find(".aggpercentile").addClass("active");
            $(this.state.el).find("#dropdownaggregation").text("Percentile");
        }
        else if (this.state.aggregation === "Median" || this.state.aggregation === "median" || this.state.aggregation === "Average" || this.state.aggregation === "average") {
            $(this.state.el).find(".aggmedian").addClass("active");
            $(this.state.el).find("#dropdownaggregation").text("Median");
        }
        else if (this.state.aggregation === "none") {
            $(this.state.el).find(".aggnone").addClass("active");
            $(this.state.el).find("#dropdownaggregation").text("None");
            $("#dropdowntooltip").addClass('disabled');
			$("#dropdownlegend").addClass('disabled');	
			$("#dropdownsorting").addClass('disabled');
        }

        return (<div></div>);
    }
}