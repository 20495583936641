import React from 'react'
import { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { lang} from "../../js/helpers/utils/languages/language";
import SendRequest from '../../SendRequest';
import _ from 'underscore';
const $ = window.$;
const columns = [
    { dataField: 'roleDisplayName', text: lang["admin.roles.createname"], sort: true },
    { dataField: 'status', text: lang["admin.roles.createstatus"], sort: true },
    { dataField: 'edit', text: lang["admin.roles.edit"] , sort: false},
    { dataField: 'delete', text: lang["admin.roles.delete"], sort: false },
  ];
  const RemotePagination = ({ data, page, sizePerPage, onTableChange, totalSize }) => (
    // <div className="notifications-table-container">
      <BootstrapTable
        remote
        keyField="roleDisplayName"
        data={data}
        columns={columns}
        pagination={paginationFactory({ page, sizePerPage, totalSize })}
        onTableChange={onTableChange}
      />
    // </div>
  );
 
  export default class RolesListTemplate extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        page: 1,
        data: props.data,
        sizePerPage: props.sizePerPage,
        totalRecords: props.totalRecords,
        search: props.search,
      };
      let featuresString = sessionStorage.getItem("features");
      featuresString = featuresString.slice(0, 3) + featuresString.slice(4);
      let decryptedString = window.atob(featuresString);
      this.state.parsedFeatureJson = JSON.parse(decryptedString);
      this.state.features= JSON.parse(decryptedString);
    //  this.state.search=props.search;
    //  var searchtext=this.state.search
    }
    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, data ,totalRecords}) => {
        const currentIndex = (page - 1) * sizePerPage;
        {
          var that= this;
          if (that.state.search == undefined){
            that.state.search  ="";
          }
          if(type != 'sort'){
                let requestHeaders = {
                  method: "get",
                  headers: new Headers({
                      "Content-Type": "application/json"
                  })
              };
                 SendRequest({
                  url: "getRoles",
                  queryString: "",
                  sucessFunction: (response) => {
                      if (response.hasOwnProperty('error')) {
                          $(".spinner").hide();
                      } else {
                       
                        var data = response;
                        _.each(data,function(val,index)	{				
                          _.each(that.state.features[0].features,function(feature){
                            if(feature.featureName=='edit_user' && feature.allow==true){
                              val.edit = <button class="btn btn-admin editaccount3" onClick={that.props.editUsersView} id="edituser" type="button" value={val.userId}><i class="fa fa-pencil"></i></button>;          
                          }	
                            if(feature.featureName=='delete_user' && feature.allow==true){
                             val.delete = <button class="btn btn-admin  deleteuserform" onClick={that.props.deleteUsersView}  id="deleteuser" type="button" value={val.userId}><i class="fa fa-close"></i> </button>;                
                            }
                            if(feature.featureName=='reset_password' && feature.allow==true){
                              val.resetpassword = <button class="btn btn-admin resetpasswordfromadmin" onClick={that.props.resetpasswordfromadminview} id="resetpassword" type="button" value={val.userId}><i class="fa fa-repeat"></i> </button>
                              }
                          });
                        });
                      
                      this.setState(() => ({
                        page,
                        data: data,
                        sizePerPage,
                        totalSize:totalRecords
                      }));
                      }
                    },
                    rejectFunction: () => { },
                    headers: requestHeaders
                  })
                }
         // type == "sort" ? 
         type == "sort" ?
          
            setTimeout(() => {
                let result;
                if (sortOrder === 'asc') {
                  result = data.sort((a, b) => {
                    if (a[sortField] > b[sortField]) {
                      return 1;
                    } else if (b[sortField] > a[sortField]) {
                      return -1;
                    }
                    return 0;
                  });
                } else {
                  result = data.sort((a, b) => {
                    if (a[sortField] > b[sortField]) {
                      return -1;
                    } else if (b[sortField] > a[sortField]) {
                      return 1;
                    }
                    return 0;
                  });
                }
                this.setState(() => ({
                  data: result
                }));
              }, 10) :
              setTimeout(() => {
                this.setState(() => ({
                  page,
                  data: this.props.data,
                  sizePerPage
                }));
      
              }, 10);
           
        
      }
      }
    render() {
        const { data, sizePerPage, page,totalRecords } = this.state;
      var that = this.props;
      return (<>
       
{/* <div class="notifications-table-container"> */}

		<div class="bootstrap-table">
			<div class="fixed-table-toolbar">
            <RemotePagination
            data={data}
            page={page}
            sizePerPage={sizePerPage}
            totalSize={totalRecords}
            onTableChange={this.handleTableChange}
          />
				 
			</div>
		</div>
{/* </div> */}



      </>);
    }
  }