import React from 'react';
import SaveLicenseView from './SaveLicenseView';
const $ = window.$;

export default class NewLicenseForm extends React.Component{
    constructor(props){
        super(props);
    }
    isValid=(data)=>{
        var isValid = true;
        if($.trim($.trim(data.powerUsers))==""){
            $(".creatorserror").removeClass('hidden');
            $(".creatorserror").text("Creators is required");
            isValid = false;
        }
        if($.trim($.trim(data.readOnlyUsers))==""){
            $(".explorerserror").removeClass('hidden');
            $(".explorerserror").text("Explorers is required");
            isValid = false;
        }
        if($.trim($.trim(data.viewerUsers))==""){
            $(".viewerserror").removeClass('hidden');
            $(".viewerserror").text("Viewers is required");
            isValid = false;
        }
        if($.trim($.trim(data.storyBoards))==""){
            $(".storyboardserror").removeClass('hidden');
            $(".storyboardserror").text("Storyboards is required");
            isValid = false;
        }
        if($.trim($.trim(data.myReports))==""){
            $(".myreportserror").removeClass('hidden');
            $(".myreportserror").text("MyReports is required");
            isValid = false;
        }
        if($.trim($.trim(data.dataSize))==""){
            $(".datasizeerror").removeClass('hidden');
            $(".datasizeerror").text("Data size is required");
            isValid = false;
        }
        if($.trim($.trim(data.expiryDate))==""){
            $(".expirydateerror").removeClass('hidden');
            $(".expirydateerror").text("Expiry Date is required");
            isValid = false;
        }
        return isValid;
    }
    addLicense=(e)=>{
        e.preventDefault();
        //$('.spinner').show();
			var that=this;
			var licenseFormData = $(".licenseform").serializeArray();
			var licenseDetails={};
			$.each(licenseFormData,function(index,data){
				licenseDetails[data.name]=data.value;
            });            
			if(that.props.licenseDetails.roleName=='ROLE_Super_Admin'){
				if($("#multiTenant").prop('checked') == undefined){
					licenseDetails["multiTenant"] = false;
				}
				else {
					licenseDetails["multiTenant"]=$("#multiTenant").prop('checked');
				}
				
			}else{
				licenseDetails["multiTenant"]=false;
			}
			
			var licenseType = $(".licensetype").val();
			var connectorsType = $(".licenseconnectoroptions").val();
			licenseDetails["licenseType"]=licenseType;
			licenseDetails["connectorsType"]=connectorsType;
			licenseDetails["myReports"]="-1";
			licenseDetails["storyBoards"]="-1";
			licenseDetails["allowapi"] = $("#allowapi").prop("checked");
			licenseDetails["allowsmartinsights"] = $("#allowsmartinsights").prop("checked");
			licenseDetails["allowml"] = $("#allowml").prop("checked");
			licenseDetails["allowwhitelabel"] = $("#allowwhitelabel").prop("checked");
			
			licenseDetails["csv"]=$("#csv").prop('checked');
			licenseDetails["xls"]=$("#xls").prop('checked');
			licenseDetails["fuse"]=$("#fuse").prop('checked');
			licenseDetails["embed"]=$("#embed").prop('checked');
			licenseDetails["schedule"]=$("#schedule").prop('checked');
			licenseDetails["pivot"]=$("#pivot").prop('checked');
            licenseDetails["ldap"]=$("#ldap").prop('checked');
            var isValid = this.isValid(licenseDetails);
            if(isValid) {
            var savelicense = new SaveLicenseView({clientId:that.props.clientId,licenseDetails:licenseDetails,userId:that.props.userId});    
                }
    }
    render(){
        var licenseTypeOptions = this.props.licenseTypeOptions;
        var superadminlicensedetails = this.props.superadminlicensedetails;
        var connectorOptions = this.props.connectorOptions;
        return (
            <div className="container-fluid nopadding">
	<div className="row in-back">
		<div className="max-height-30vh">
			<h2 className="boc-main-title">License Management</h2>
			<form className="form-horizontal col-xs-12 licenseform">
				<div className="form-group form-group-sm">
					<label for="licensetypelabel" className="col-sm-3 control-label">License Type</label>
					<div className="col-sm-4">
                         <select className="form-control licensetype">
                        {licenseTypeOptions.map(function(val){
                          return <option value={val}>{val}</option>
                         })
                        }
                         </select>
					</div>					
				</div>
				<div className="form-group form-group-sm">
					<label for="powerusers" className="col-sm-3 control-label">Creators</label>
					<div className="col-sm-4">
						<input type="name" className="form-control" id="powerusers"
							name="powerUsers"/>
							<span className="help-block creatorserror hidden"></span>
					</div>
					<div className="col-sm-1 nopadding text-left marg-top-5">
						/{superadminlicensedetails.powerUsers}
					</div>
				</div>
				<div className="form-group form-group-sm">
					<label for="readonly" className="col-sm-3 control-label">Explorers</label>
					<div className="col-sm-4">
						<input type="name" className="form-control" id="readonly"
							name="readOnlyUsers"/>
							<span className="help-block explorerserror hidden"></span>
					</div>
					<div className="col-sm-1 nopadding text-left marg-top-5">
						/{superadminlicensedetails.readOnlyUsers}
					</div>
				</div>
				<div className="form-group form-group-sm">
				<label for="viewers" className="col-sm-3 control-label">Viewers</label>
					<div className="col-sm-4">
						<input type="name" className="form-control" id="viewers"
							name="viewerUsers"/>
							<span className="help-block viewerserror hidden"></span>
					</div>
					<div className="col-sm-1 nopadding text-left marg-top-5">
						/{superadminlicensedetails.viewers}
					</div>
				</div>
				<div className="form-group form-group-sm">
					<label for="storyboards" className="col-sm-3 control-label">Storyboards</label>
					<div className="col-sm-4">
						<input type="name" className="form-control" id="storyboards"
							name="storyBoards" />
							<span className="help-block storyboardserror hidden"></span>
					</div>
					<div className="col-sm-1 nopadding text-left marg-top-5">
						/{superadminlicensedetails.allowedStoryBoards}
					</div>
				</div>
				<div className="form-group form-group-sm">
					<label for="myreports" className="col-sm-3 control-label">MyReports</label>
					<div className="col-sm-4">
						<input type="name" className="form-control" id="myreports"
							name="myReports"/>
							<span className="help-block myreportserror hidden"></span>
					</div>
					<div className="col-sm-1 nopadding text-left marg-top-5">
						/{superadminlicensedetails.allowedMyReports}
					</div>
				</div>
				<div className="form-group form-group-sm">
					<label for="datasize" className="col-sm-3 control-label">Max.Data
						Size (GB)</label>
					<div className="col-sm-3">
						<input type="name" className="form-control" id="datasize"
							name="dataSize" />
							<span className="help-block datasizeerror hidden"></span>
					</div>
					<div className="col-sm-1 nopadding text-left marg-top-5">
						/{superadminlicensedetails.dataSize}
					</div>
				</div>
				<div className="form-group form-group-sm marg-top-5">
					<label className="col-sm-3 control-label ">Expiry Date</label>
					<div className="col-sm-3 form-inline">
						<div className="input-group input-group-sm">
							<input type="text" className="form-control" name="expiryDate" id="expirydate"
								placeholder="Date" autocomplete="off" readonly="readonly" />
							<div className="input-group-addon">
								<i className="fa fa-calendar"></i>
							</div>
						</div>
						<span className="help-block expirydateerror hidden"></span>
					</div>
				</div>
				<div className="form-group form-group-sm">
					<label for="allowapi" className="col-sm-3 control-label">Allow API</label>
					<div className="col-sm-4">
                        <input type="checkbox" id="allowapi"/>
					</div>
					<label for="licensesmartinsights" className="col-sm-3 control-label">Smart Insights</label>
					<div className="col-sm-2">
                        <input type="checkbox" id="allowsmartinsights"/>
					</div>
					<label for="licenseml" className="col-sm-3 control-label">AI/ML</label>
					<div className="col-sm-4">
                        <input type="checkbox" id="allowml"/>
					</div>
					<label for="licensewhitelabel" className="col-sm-3 control-label">WhiteLabel</label>
					<div className="col-sm-2">
                        <input type="checkbox" id = "allowwhitelabel"/>
					</div>
				</div>
				<div className="form-group form-group-sm">
					<label for="licenseconnectorslabel" className="col-sm-3 control-label">Connectors</label>
					<div className="col-sm-4">
                         <select className="form-control licenseconnectoroptions">
                        {connectorOptions.map(function(val){
                          return <option value={val}>{val}</option>
                        })
						}
                         </select>
					</div>
					
				</div>
				<div className="form-group form-group-sm">
					<div className="col-sm-4">
						<button type="button" className="btn btn-bird pull-right savelicensedetails" onClick={this.addLicense}>Add</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>
        );
    }
}