import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { lang } from "../js/helpers/utils/languages/language";
import SendRequest from '../SendRequest';
import _ from 'underscore';
const $ = window.$;
const columns = [
  { dataField: 'id', text: lang["storyboard.s.no"], sort: false },
  { dataField: 'report_visualization_name', text: lang["storyboard.reportname"], sort: true },
  { dataField: 'report_visualization_desc', text: lang["storyboard.description"], sort: true },
  { dataField: 'folder_name', text: lang["storyboard.folder"], sort: true },
  { dataField: 'create_date', text: lang["storyboard.createdate"], sort: true },
  { dataField: 'is_storyboard', text: lang["storyboard.reporttype"], sort: true },
  { dataField: 'viewReport', text: lang["storyboard.viewreport"], sort: false },
  { dataField: 'deleteReport', text: lang["storyboard.deletereport"], sort: false },
  { dataField: 'restore', text: lang["storyboard.restorereport"], sort: false }
];
const columnsnew = [
  { dataField: 'id', text: lang["storyboard.s.no"], sort: false },
  { dataField: 'report_visualization_name', text: lang["storyboard.reportname"], sort: true },
  { dataField: 'report_visualization_desc', text: lang["storyboard.description"], sort: true },
  { dataField: 'folder_name', text: lang["storyboard.folder"], sort: true },
  { dataField: 'create_date', text: lang["storyboard.createdate"], sort: true },
  { dataField: 'is_storyboard', text: lang["storyboard.reporttype"], sort: true },
  { dataField: 'viewReport', text: lang["storyboard.viewreport"], sort: false },
  { dataField: 'deleteReport', text: lang["storyboard.deletereport"], sort: false }
];
var headers=[];
const RemotePagination = ({ data, page, sizePerPage, onTableChange, totalSize }) => (
  <div className="drawbootstraptable">
  <div className="notifications-table-container">
    <BootstrapTable
      remote
      keyField="id"
      data={data}
      columns={headers}
      pagination={paginationFactory({ page, sizePerPage, totalSize })}
      onTableChange={onTableChange}
      
    />
    </div>
  </div>
);
export default class VersionsListHtml extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      data: props.data,
      sizePerPage: props.sizePerPage,
      totalRecords: props.totalRecords,
      search: props.search,
      urltype:props.urltype,
      reportId:props.reportId
    };
    if(this.props.from =='reports'){
      headers=columnsnew
    }else{
      headers=columns;
    }
  }

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, data, totalRecords }) => {
    const currentIndex = (page - 1) * sizePerPage;
    {
      var foldername = $(".storyfolders-select option:selected").val() == undefined || $(".storyfolders-select option:selected").val() == '' ? 'Default' : $(".storyfolders-select option:selected").val();
      var that = this;
      type == "sort" ?
        setTimeout(() => {
          let requestHeaders = {
            method: "get",
            headers: new Headers({
              "Content-Type": "application/json"
            })
          };
          SendRequest({
            url: "getversionedreportslist",
            queryString: "?fromDate= " + " " + "&toDate= " + " " + "&index= " + currentIndex + "&noOfRecords=" + sizePerPage + "&searchCriteria=" + that.state.search + "&order=" + sortOrder + "&colName=" + sortField + "&folderName=" + foldername + "&urltype=" + that.state.urltype + "&reportId=" + that.state.reportId,
            sucessFunction: (response) => {
              if (response.hasOwnProperty('error')) {
                $(".spinner").hide();
              } else {
                var headerlist = response["header"];
                var data = response["data"];
                _.each(data, function (val, index) {
                  if (val.type == "ml_storyboard") {
                    val.viewReport = <a href={`${that.props.url}/viewmodel/${btoa(val.reportId)}/${btoa(val.dataModelId)}`} data-num={val.reportId} tab-id="0" tab-name="" class="boc-snapshot-thumbnail reportview" id="reportstoryboardview"><i class="fa fa-bar-chart"></i></a>;
                  } else if (val.type == "smartinsight_storyboard") {
                    val.viewReport = <a href={`${that.props.url}/viewsmartinsight/${btoa(val.reportId)}/${btoa(val.dataModelId)}`} data-num={val.reportId} class="boc-snapshot-thumbnail reportview" tab-id="0" tab-name="" id="reportstoryboardview"><i class="fa fa-bar-chart"></i></a>;
                  } else {
                    val.viewReport = <a href={`/bird/welcome/viewreport/${btoa(val.reportId)}`} data-num={val.reportId} class="boc-snapshot-thumbnail reportview" tab-id="0" tab-name="" id="reportstoryboardview"><i class="fa fa-bar-chart"></i></a>;
                  }
                  val.deleteReport = <button type="button" onClick={that.props.deleteReport} id="deletestoryboardvlistiew" data-num={val.reportId} tab-id="0" tab-name="" is-shared={val.isShared} is-favourite={val.isUserFavourite} class="btn btn-transparent aldelete"><i class="fa fa-trash-o"></i></button>;
                  val.restore = <button type="button" onClick={that.props.restoreReport} id="restorereportlistview" data-num={val.reportId} tab-id="0" tab-name="" is-shared={val.isShared} is-favourite={val.isUserFavourite} class="btn btn-transparent"><i class="fa fa-undo"></i></button>;
                });

                var keys = Object.keys(headerlist);
                this.setState(() => ({
                  page,
                  data: data,
                  sizePerPage,
                  totalSize: totalRecords
                }));
              }
            },
            rejectFunction: () => { },
            headers: requestHeaders
          })
        }, 10) :
        setTimeout(() => {
          let requestHeaders = {
            method: "get",
            headers: new Headers({
              "Content-Type": "application/json"
            })
          };
          SendRequest({
            url: "getversionedreportslist",
            queryString: "?fromDate= " + "" + "&toDate= " + "" + "&index= " + currentIndex + "&noOfRecords=" + sizePerPage + "&searchCriteria=" + that.state.search + "&order=" + sortOrder + "&colName=" + "" + "&folderName=" + foldername + "&urltype=" + that.state.urltype + "&reportId=" + that.state.reportId,
            sucessFunction: (response) => {
              if (response.hasOwnProperty('error')) {
                $(".spinner").hide();
              } else {
                var headerlist = response["header"];
                var data = response["data"];
                _.each(data, function (val, index) {
                  if (val.type == "ml_storyboard") {
                    val.viewReport = <a href={`${that.props.url}/viewmodel/${btoa(val.reportId)}/${btoa(val.dataModelId)}`} data-num={val.reportId} tab-id="0" tab-name="" class="boc-snapshot-thumbnail reportview" id="reportstoryboardview"><i class="fa fa-bar-chart"></i></a>;
                  } else if (val.type == "smartinsight_storyboard") {
                    val.viewReport = <a href={`${that.props.url}/viewsmartinsight/${btoa(val.reportId)}/${btoa(val.dataModelId)}`} data-num={val.reportId} class="boc-snapshot-thumbnail reportview" tab-id="0" tab-name="" id="reportstoryboardview"><i class="fa fa-bar-chart"></i></a>;
                  } else {
                    val.viewReport = <a href={`/bird/welcome/viewreport/${btoa(val.reportId)}`} data-num={val.reportId} class="boc-snapshot-thumbnail reportview" tab-id="0" tab-name="" id="reportstoryboardview"><i class="fa fa-bar-chart"></i></a>;
                  }

                  val.deleteReport = <button type="button" onClick={that.props.deleteReport} id="deletestoryboardvlistiew" data-num={val.reportId} tab-id="0" tab-name="" is-shared={val.isShared} is-favourite={val.isUserFavourite} class="btn btn-transparent aldelete"><i class="fa fa-trash-o"></i></button>;
                  val.restore = <button type="button" onClick={that.props.restoreReport} id="restorereportlistview" data-num={val.reportId} tab-id="0" tab-name="" is-shared={val.isShared} is-favourite={val.isUserFavourite} class="btn btn-transparent"><i class="fa fa-undo"></i></button>;
                });
                var keys = Object.keys(headerlist);
                this.setState(() => ({
                  page,
                  data: data,
                  sizePerPage,
                  totalSize: totalRecords
                }));
                
              }
            },
            rejectFunction: () => { },
            headers: requestHeaders
          })

        }, 10);
    }
    $(".pivotdataview .ball-pulse").hide();
  }
  componentDidMount() {
    var that = this;
    $(".storyfolders-select").change(function (e) {
      that.props.foldersselectchange(e)
    })
  }
  render() {
    var that = this.props;
    const { data, sizePerPage, page, totalRecords } = this.state;
    return (
      <RemotePagination
                data={data}
                page={page}
                sizePerPage={sizePerPage}
                totalSize={totalRecords}
                onTableChange={this.handleTableChange} />
    );
  }
}