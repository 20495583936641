import DatahubHtml from "../../../../Hub/DatahubHtml";

export var lang = {};

//Login Page
lang["login.learnbtn"] = "Learn More"; // signin learnmore button in the left side top
lang["login.title"] = "Sign in"; //signin title
lang["login.username"] = "Username"; //signin username
lang["login.pwd"] = "Password"; //signin password
lang["login.signbtn"] = " Sign in"; // signin button
lang["login.servernotresponding"]= "Server is not responding, please wait a bit and try again. Please contact admin for further information."//If the server is not responding
lang["login.forgot.pwd"] = "Forgot Password?"; //forgotpassword
lang["login.forgot.title"] = "Forgot Password"; //forotpassword title
lang["login.forgot.userlname"] = "User Login Name"; // forgotpassword user loginname
lang["login.forgot.resetbtn"] = "Re-Set Password"; // forgotpassword resetbutton
lang["login.Terms1"] = "By clicking Sign in, you agree to our "; //signin terms & privacy
lang["login.Terms2"] = "Terms of Use"; //signin terms & privacy
lang["login.Terms3"] = "and that you have read our"; //signin terms & privacy
lang["login.Terms4"] = "Privacy Policy"; //signin terms & privacy

//Licence page (initial)
//Change password screens for first time login
//Security questions page
//Change email page for admin
//Super Admin

//To Do

//Admin page
//#### header
lang["admin.header.dashtitle"] = "Dashboard"; // header Dashboard title
lang["admin.header.admintitle"] = "Administration"; //header Administartion title
lang["admin.header.chngpwd"] = "Change Password"; //header change password button
lang["admin.header.logout"] = "Logout"; // header logout button
lang["admin.header.oldpwd"] = "Old Password"; //old password
lang["admin.oldpwdvalid"] = "Old Password is Invalid"; //old password validation
lang["admin.header.newpwd"] = "New Password"; // new password
lang["admin.newpwdvalid"] = "Current Password must be different with Old password"; //new password validation
lang["admin.header.pwdrules"] = "Password Rules"; // password rules
lang["admin.header.renterpwd"] = "Re-Enter New Password"; //reenter password
lang["admin.header.reentervalid"] = "The Passwords does not match"; // reenter password validation
lang["admin.header.security"] = "Answer is not matched"; // security match validation
lang["admin.header.version"] = "Version :"; // About Bird version
lang["admin.header.closebtn"] = "Close"; // close button
lang["admin.header.submitbtn"] = " Submit"; //submit button
lang["admin.viewdisplay"] = "To view Administration, A larger display is required."// to view Administration
lang["admin.localtime"] = "Local Time";// local time
lang["admin.servertime"] = "Server Time"// server time
lang["admin.time"] = "Fri Sep 27 2019 10:37:00 GMT+0530" // local time and server time format
lang["admin.cnclbtn"] = "Cancel";// cancel button

//user page header
lang["user.header.data"]="Data" //user header data title
lang["user.header.visual"]="Visualization" // user header visualization  title
lang["user.header.alerts"]="Alerts" // user header alerts title
lang["user.header.search"]="Search" // user header search title
lang["user.header.home"]="Home" // header hamburgermenu home
lang["user.header.adminstn"]="Administration"// header hamburgermenu adminstration 
lang["user.header.datahub"]="Data Hub"// header hamburgermenu datahub
lang["user.header.workspace"]="Business Workspaces"// header hamburgermenu business workspaces
lang["user.header.models"]="Business Models"// header hamburger business models
lang["user.header.favorites"]="Favorites"// header hamburger favourites
lang["user.header.storyboards"]="Storyboards"// header hamburger storyboards
lang["user.header.reports"]="Reports"// header hamburger reports
lang["user.header.advanced"]="Advanced"// header hamburger advanced option
lang["user.header.startswith"]="Starts with"// header hamburger starts with
lang["user.header.endswith"]="Ends with"// header hamburger ends with
lang["user.header.contains"]="Contains"// header hamburger contains
lang["user.header.go"]="GO"// header hamburger go
lang["user.header.governce"]="Governance"// goverance 
lang["user.header.dashboard"]="Dashboard"// dashboard
lang["user.header.newrprt"]="New Report" // new report
lang["user.header.newstory"]="New Storybook"// new storybook
lang["user.header.newcustombd"]="New Custom Storyboard"// new custom storyboard
lang["user.header.securityans"]="Enter Your Answer Here"// change password security answer placeholder
lang["user.viewall"]="View All" // Notification view all
lang["user.clearall"]="Clear All"// notification clear all
lang["user.nonotify"]=" No New Notifications" // no new notifications
lang["user.header.menu"]="Menu" // menu tooltip
lang["user.header.fullscreen"]="FullScreen"// full screen tooltip
lang["user.header.userinfo"]="User Info"// user info tooltip
lang["user.header.user"]="User"// user tooltip
lang["user.header.notify"]="Notifications"// notifications tooltip
lang["user.header.viewnotify"]="View All Notifications"// view all notifications tooltip
lang["user.header.clearnotify"]="Clear All Notifications"// clear all notifications tooltip
lang["user.header.notifysession"]="Your session will expire in a minute. Do you want to extend the session?" // session expire warning
lang["user.header.pwdchangefail"]="Password Change Failed" //password chage failed warning
lang["user.header.sessionexpire"]="Your session has been extended" //session extended warning
lang["user.header.details"]="The username or password you entered is incorrect" // username or pasdsword incorrect warning
//license

lang["admin.license.title"] = "License Info"; //licenseinfo title
lang["admin.license.creatortitle"] = "Report Creators"; //licenseinfo Report Creators title
lang["admin.license.explortitle"] = "Report Explorers"; //licenseinfo Report Explorers title
lang["admin.license.viewertitle"] = "Report Viewer"; //licenseinfo  Report Viewers title
lang["admin.license.editiontitle"] = "License Edition"; //licenseinfo License Edition title
lang["admin.license.datatitle"] = "Data Size (GB)"; //licenseinfo DataSize title
lang["admin.license.expirytitle"] = "Expiry Date"; //licenseinfo ExpiryDate title
lang["admin.license.expiry"] = "Valid Until"; //licenseinfo Expirydate valid
lang["admin.license.allocated"] = "Allocated"; //licenseinfo allocated option
lang["admin.license.used"] = "Used"; //licenseinfo used  option
lang["admin.license.feattitle"] = "Features"; //licenseinfo features title
lang["admin.license.featsallow"] = "API Access"; //features allow option
lang["admin.license.featssmart"] = "Smart Insights"; //features smart insights option
lang["admin.license.featml"] = "Advanced Analytics"; //features allowml option
lang["admin.license.featconnect"] = "Connectors"; //features connectors option
lang["admin.license.featlabel"] = "White Label"; //features whitelabel option
lang["admin.license.renewbtn"] = "Renew"; //   licenseinfo renew button
lang["admin.license.renewtitle"] = " Renew License"; //renew button -renew license title
lang["admin.license.renewkey"] = "License Key"; //renew button -renew license key
lang["admin.license.keyrqd"] = "License Key Required" // License key required
lang["admin.license.cnclbtn"] = "Cancel"; //renew button - Renew cancel button
lang["admin.license.renew"] = "Renew"; // renew button -renew button

//Role managment

lang["admin.roles.title"] = "Role Management"; //Rolemanagement title
lang["admin.roles.search"] = "Search"; // role search bar
lang["admin.roles.rolebtn"] = "Add Role"; //Addrole button
lang["admin.roles.createtitle"] = "Create a New Role"; //createrole title in addrole button
lang["admin.roles.createname"] = "Role Name"; //Role name
lang["admin.roles.createdescrptn"] = "Role Description"; //Role Description
lang["admin.roles.createinherit"] = "Role Inherited From"; //Role inherited
lang["admin.roles.createstatus"] = "Status"; // Role status
lang["admin.roles.statusact"] = "Active"; //Role status option - Active
lang["admin.roles.statusdact"] = "Inactive"; //Role status option - Inactive
lang["admin.roles.chooserole"] = "Choose a Role"; //choose a role select option
lang["admin.roles.addbtn"] = "Add"; // Role add button
lang["admin.roles.features"] = "Feature"; // module displayname Feature option
lang["admin.roles.Allow"] = "Allow"; //module displayname Allow option
lang["admin.roles.Deny"] = "Deny"; //module displayname Deny option
lang["admin.roles.edittitle"] = "Edit Role"; //Role Edit title in edit section
lang["admin.roles.updatebtn"] = "Update"; //Role edit update button
lang["admin.roles.updatesuccess"] = "Role Updated Successfully"; // role updated success Alert
lang["admin.roles.updateerror"] = "Error occurred while Updating Role.";// Error occurred while Updating Role Alert
lang["admin.roles.inactive"] = "If you inactivate this role, all users under this role will be Inactivated. Do you want to proceed?";// Inactivated role
lang["admin.roles.namerqd"] = "Role Name is required"; // Role name required validation
lang["admin.roles.edit"] ="Edit" // Edit
lang["admin.roles.delete"] ="Delete"// Delete

//Groups managment

lang["admin.groups.title"] = "Manage Groups"; //Group managment title
lang["admin.groups.search"] = "Search"; // group search bar
lang["admin.groups.grpbtn"] = "Add Group"; // Addgroup button
lang["admin.groups.createtitle"] = "Create a New Group"; //creategroup title in addgroup button
lang["admin.groups.createname"] = "Group Name"; //Group name
lang["admin.groups.createdescrptn"] = " Description"; //Group Description
lang["admin.groups.status"] = "Status"; // Group status
lang["admin.groups.statusact"] = "Active"; //Group status option - Active
lang["admin.groups.statusdact"] = "Inactive"; //Group status option - Inactive
lang["admin.groups.addbtn"] = "Add"; // Group add button
lang["admin.groups.edittitle"] = "Edit Group"; //Group Edit title
lang["admin.groups.updatebtn"] = "Update"; //Group edit update button
lang["admin.groups.namerror"] = "Group Name is required"; //Group name error validation
lang["admin.groups.edit"] ="Edit" // Edit
lang["admin.groups.delete"] ="Delete"// Delete
//Users managment

lang["admin.users.title"] = "Manage Users"; //Users managment title
lang["admin.users.search"] = "Search"; // users search bar
lang["admin.users.rsetpwd"] = "Global Password Reset"; //Users Resetpassword
lang["admin.users.rsettitle"] = "Reset Password for Group or Role"; //Resetpassword-Resetpassword title
lang["admin.users.rsetgrp"] = "Group:"; //Resetpassword-Reset group
lang["admin.users.rsetrole"] = "Role:"; //Resetpassword-Reset role
lang["admin.users.applybtn"] = "Apply"; //Resetpassword-Reset apply button
lang["admin.users.rsetbtn"] = "Reset"; //Resetpassword-Reset button
lang["admin.users.rsetuser"] = "User Name"; //Resetpassword-Reset username
lang["admin.users.rsetlgin"] = "Login Name"; //Resetpassword-Reset loginname
lang["admin.users.role"] = "Role"; //Resetpassword-reset role
lang["admin.users.userbtn"] = "Add User"; // Adduser button
lang["admin.users.createtitle"] = "Create a New User"; //Adduser-createuser title in adduser button
lang["admin.users.fname"] = "First Name"; // Adduser-createuser firstname
lang["admin.users.lname"] = "Last Name"; //Adduser-createuser lastname
lang["admin.users.email"] = "Login Name (Email)"; //Adduser-createuser email
lang["admin.users.grp"] = "Group"; // Adduser-createuser group
lang["admin.users.advncd"] = "Advanced"; // Adduser-createuser Advanced option
lang["admin.users.strtw"] = "Starts with"; //Adduser-advanced starts with
lang["admin.users.endw"] = " Ends with"; //Adduser-advanced ends with
lang["admin.users.contains"] = "Contains"; //Adduser- advanced contains
lang["admin.users.gobtn"] = "GO"; //Adduser-advanced go button
lang["admin.users.status"] = "Status"; //Adduser- createuser status
lang["admin.users.statusact"] = "Active"; //Adduser-user status option - Active
lang["admin.users.statusdact"] = "Inactive"; //Adduser-user status option - Inactive
lang["admin.users.crtrole"] = "Role"; // Adduser-createuser role
lang["admin.users.chooserole"] = "Choose a Role"; //Adduser-choose a role select option
lang["admin.users.choosegrp"] = "Choose Group"; //Adduser-choose  group select option
lang["admin.users.addbtn"] = "Add"; //Adduser- user add button
lang["admin.users.edittitle"] = "Edit User Account"; //User Edit title
lang["admin.users.updatebtn"] = "Update"; //user edit update button
lang["admin.users.chooseagrp"] = "Choose a Group" //Choose a Group
lang["admin.users.chooseany"] = "Please choose any one group or any one role, not both." // choose ay one group or any one role
lang["admin.users.nameexists"] = "Name Already Existing" //adduser-- Name already existing
lang["admin.users.loginnameexists"] = "Login Name/Email Already Existing";//Login Name/Email  Already Existing
lang["admin.users.oops"] = "Oops, nothing found!"//Oops, nothing found!
lang["admin.users.firstnamerqd"] = "First Name is required";//adduser--First Name is required
lang["admin.users.rolerqd"] = "Role is required";//adduser--Role is required
lang["admin.users.emailrqd"] = "Email is required";//adduser--Email is required
lang["admin.users.smgroup"] = "Choose a group";//adduser--Choose a group
lang["admin.users.createdate"]="Created Date";//Created Date
lang["admin.users.modifydate"]="Modified Date";//Modified Date
lang["admin.users.resetpwd"]="Reset Password";//Reset Password
lang["admin.users.edit"] ="Edit" // Edit
lang["admin.users.delete"] ="Delete"// Delete



//Report managment

lang["admin.reports.title"] = "Report Management"; //Report managment title
lang["admin.reports.search"]="Search"// search
lang["admin.reports.reportid"] = "Report ID";//Report ID
lang["admin.reports.reportname"] = "Report Name";//Report Name
lang["admin.reports.reporttype"] = "Report Type";//Report Type
lang["admin.reports.foldername"] = "Folder Name";//Folder Name
lang["admin.reports.createdby"] = "Created By";//Created By
lang["admin.reports.sharedto"] = "Shared To";//Shared To
lang["admin.reports.modelname"] = "DataModel Name";//DataModel Name
lang["admin.reports.workspacename"] = "Workspace Name";//Workspace Name

//Auditing

lang["admin.audit.title"] = "Auditing"; //Auditing title
lang["admin.audit.search"] = "Search"; // audit search bar
lang["admin.audit.account"] = "Account"; //Auditing Account option
lang["admin.audit.users"] = "Users/Groups"; //Auditing Users option
lang["admin.audit.mail"] = "Mail"; //Auditing Mail option
lang["admin.audit.ldap"] = "LDAP"; //Auditing LDAP option
lang["admin.audit.reports"] = "Reports"; //Auditing Reports option
lang["admin.audit.login"] = "Login"; //Auditing Login option
lang["admin.audit.conection"] = "Connections"; //Auditing Connections option
lang["admin.audit.role"] = "Role"; //Auditing Role option
lang["admin.audit.alerts"] = "Alerts"; //Auditing Alerts option
lang["admin.audit.datahub"] = "Data Hub"; //Auditing Data Hub option
lang["admin.audit.workspace"] = "Workspace"; //Auditing Workspace option
lang["admin.audit.datamodel"] = "Data Model"; //Auditing Data Model option
lang["admin.audit.goverance"] = "Governance"; //Auditing Governance option
lang["admin.audit.customstoryboard"] = "Custom Storyboard"; //Auditing Custom Storyboard option
lang["admin.audit.settings"] = "Audit Settings"; //audit settings
lang["admin.audit.refresh"] = "Refresh"; //audit refresh
lang["admin.auditlog"] = "Audit Log Disable/Enable"; //audit settings-audit log settings enable/disable
lang["admin.auditlogconfig"] = " Audit Log Retention Configuration"; //audit settings-audit log settings configuration
lang["admin.audit.canclbtn"] = "Cancel"; // audit settings cancel button
lang["admin.audit.applybtn"] = "Apply"; // audit settings apply button
lang["admin.audit.maxage"] = "Max Data Age"; //audit settings- audit max data age
lang["admin.audit.weeks"] = "Week(s)"; //audit settings- audit weeks option
lang["admin.audit.months"] = "Month(s)"; //audit settings-audit months option
lang["admin.audit.years"] = "Year(s)"; // audit settings-audit years option
lang["admin.audit.maxageerror"] = "Please enter valid Max data age" ;// max data age validation error
lang["admin.audit.submitbtn"] = "Submit"; //submit button
//other settings
lang["admin.settings.title"] = "Settings"; // Settings title
lang["admin.ldapbtn"] = "LDAP"; // LDAP button
lang["admin.mailbtn"] = "Mail Config"; // Mail button
lang["admin.samlbtn"] = "SAML/OAUTH 2.0"; //saml 2.0 btn
lang["admin.pwdpolicybtn"] = "Password Policy"; //password policy btn
lang["admin.emailbtn"] = "Email Disclaimer"; //email disclaimer btn
lang["admin.syncbtn"] = "Sync Bucket"; //sync bucket btn
lang["admin.syncsettings.title"] = "Sync Settings"; // Sync Settings Title
//LDAP
lang["admin.ldap.addbtn"] = "Add LDAP"; // ldap addbtn
lang["admin.ldap.search"] = "Search"; //ldap search
lang["admin.ldap.settingsname"] = "Settings Name"; //ldap settingsname
lang["admin.ldap.status"] = "Status"; //ldap status
lang["admin.ldap.showusers"] = "Show Users"; //ldap show users
lang["admin.ldap.edit"] = "Edit"; //ldap edit
lang["admin.ldap.delete"] = "Delete"; //ldap delete
lang["admin.ldap.title"] = "Add 'LDAP' Configuration"; //add ldap button-ldap title
lang["admin.ldap.enable"] = "Enable LDAP"; //add ldap button-Enable ldap
lang["admin.ldapact"] = "Active"; //add ldap button-ldap status active
lang["admin.ldapdact"] = " Inactive"; // add ldap button-ldap status inactive
lang["admin.ldapname"] = "LDAP Name"; //add ldap button-ldap name
lang["admin.ldapurl"] = "URL"; //add ldap button-ldap url
lang["admin.ldapuser"] = "Bind User"; //add ldap button-ldap bind user
lang["admin.ldappwd"] = "Bind Password"; //add ldap button-ldap bind password
lang["admin.ldapsearch"] = "Search Base"; //add ldap button-ldap searchbase
lang["admin.ldapquery"] = "Query"; //add ldap button-ldap query
lang["admin.ldapvalidbtn"] = "Validate"; //ldap validate btn
lang["admin.ldapsavebtn"] = "Save"; //ldap save btn
lang["admin.ldapshowusersbtn"] = "Show Users"; // ldap showusers btn
lang["admin.pholdname"] = "ldap name"; //placeholder ldap name
lang["admin.pholdurl"] = "ldap://ldap.someurl.com:123"; //placeholder ldap url
lang["admin.pholduser"] = "username"; //placeholder ldap binduser
lang["admin.pholdpwd"] = "password"; //placeholder ldap bindpassword
lang["admin.pholdbase"] = "OU=people,DC=bird,DC=local"; //placeholder search base
lang["admin.pholdquery"] = "(objectclassName=person)"; //placeholder ldap query
lang["admin.ldapnamerqd"] = "Please Enter LDAP Name"; //Enter ldap name validation
lang["admin.ldapurlrqd"] = "Please Enter URL"; //Enter url validation
lang["admin.ldapuserrqd"] = "Please Enter Bind User"; //Enter binduser validation
lang["admin.ldapspwdrqd"] = "Please Enter Bind Password"; //Enter bindpassword validation
lang["admin.ldapsearchrqd"] = "Please Enter search Base"; //Enter search validation
lang["admin.ldapsearchobj"] =
  "The DN of the search base object,defines the location in the directory from which the LDAP search begins."; //enter search object
lang["admin.ldapqueryobj"] =
  "LDAP query to run in order to identify user nodes under the searchbase."; // enter query object
lang["admin.ldap.edittitle"] = "Edit 'LDAP' Configuration"; // edit title
lang["admin.ldap.updatebtn"] = "Update"; //edit updatebtn
lang["admin.ldap.nameerror"] = "LDAP Name is required"// editldap--LDAP Name is required error validation
lang["admin.ldap.urlerror"] = "URL is required"// editldap--URL is required error validation
lang["admin.ldap.bindusererror"] = "Bind User is required"// editldap--Bind User is required error validation
lang["admin.ldap.bindpwderror"] = "Bind Password is required"// editldap--Bind Password is required error validation
lang["admin.ldap.searcherror"] = "Search Base is required"// editldap--Search Base is required error validation
lang["admin.ldap.invalidata"] = "Invalid Data"// editldap--Invalid Data error validation

//mailconfig
lang["admin.mail.config"] = "Mail Config"; // mailconfig btn
lang["admin.mail.search"] = "Search"; //mail search
lang["admin.mail.email"] = "Application Email"; // Email
lang["admin.mail.emailmsg"] = "Using this Email all Application Related Email Notifications will be Delivered from above Email id"// application email config message
lang["admin.mail.status"] = "Status"; //status
lang["admin.mail.edit"] = "Edit"; //Edit
lang["admin.mail.configtitle"] = "Mail Configuration"; // mail configuration title
lang["admin.mail.smtpurl"] = "SMTP URL"; // smtp url
lang["admin.mail.portno"] = "Port Number"; // mail port number
lang["admin.mail.pwd"] = "Password"; //mail password
lang["admin.mail.auth"] = "Mail Authentication"; // mail authentication
lang["admin.mail.authtrue"] = "True"; // mail authentication true
lang["admin.mail.authfalse"] = "False"; // mail authentication false
lang["admin.mail.connect"] = "Connection Type"; // mail coonection type
lang["admin.mail.connectTLS"] = "TLS"; // mail connection TLS
lang["admin.mail.connectSSL"] = "SSL"; // mail connection SSL
lang["admin.mail.otp"] = "OTP"; // OTP
lang["admin.mail.pholdname"] = "Enter Host Name"; //placeholder hostname
lang["admin.mail.pholdnum"] = "Enter Port Number"; //placeholder portnumber
lang["admin.mail.pholdemail"] = "Enter EmailAddress"; // placeholder email
lang["admin.mail.pholdpwd"] = "Enter Password"; // placeholder password
lang["admin.mail.pholdotp"] = "Enter OTP"; // placeholder otp
lang["admin.mail.emailotp"] = "Please check your email for OTP" // email otp validation
lang["admin.mail.urlerror"] = "URL is required"// url required validation
lang["admin.mail.porterror"] = "Port Number is required"// port number required validation
lang["admin.mail.emailerror"] = "Email is required"// Email required validation
lang["admin.mail.pwderror"] = "Password is required"// password required validation

//saml2.0
lang["admin.saml.title"] = "Add 'SAML 2.0' Configuration"; // saml title
lang["admin.saml.loginURL"] = "Remote Login URL"; //saml remote login
lang["admin.saml.logoutURL"] = "Remote Logout URL"; // saml remote logout
lang["admin.saml.certifcate"] = "Public x.509 Certificate"; // saml public certificate
lang["admin.pholdloginURL"] = "Remote Login URL"; // placeholder loginurl
lang["admin.pholdlogoutURL"] = "Remote Logout URL"; //placeholder logout url
lang["admin.pholdcertifcate"] = "The certificate of identity provider..."; //placeholder public certificate
lang["admin.saml.savebtn"] = " Save/Update"; // saml update

//password policy
lang["admin.pwd.title"] = "Password Policy"; // password policy title
lang["admin.pwd.minlen"] = "Minimum Length of Characters"; //pwd min length characters
lang["admin.pwd.maxlen"] = "Maximum Length of Characters"; // pwd max length characters
lang["admin.pwd.minlowch"] = "Minimum No. of Lowercase Characters"; // pwd min lowercase characters
lang["admin.pwd.minupch"] = "Minimum No. of Uppercase Characters"; //pwd min uppercase characters
lang["admin.pwd.mindig"] = "Minimum No. of Digits"; // pwd min digits
lang["admin.pwd.spclchar"] = "Minimum No. of Special Characters"; // pwd special characters
lang["admin.pwd.whtespce"] = "Whitespaces are Allowed or Not"; // pwd whitespaces
lang["admin.pwd.spcetrue"] = "True"; //pwd whitespace true
lang["admin.pwd.spcefalse"] = "False"; //pwd whitespace false
lang["admin.pwd.period"] = "Retention Period (In Months)"; // pwd period
lang["admin.pwd.loginfail"] = "No. of Login Fail Attempts"; // login fail attempts
lang["admin.pwd.auth"] = "Two Factor Authentication"; // two factor authentication
lang["admin.pwd.updatebtn"] = "Update"; // pwd update
lang["admin.pwd.minlenrqd"] = "Minimum Length Of Characters is required"; //pwd min length characters required validation
lang["admin.pwd.numonly"]="Enter Numbers Only"; //password-- numbers only validation text
lang["admin.pwd.decmlsn'tallow"]="Decimal values are not allowed"; //password- Decimal values are not allowed validation text
lang["admin.pwd.+venumonly"]="Enter Positive Numbers Only"; //password--- Enter  Positive  Numbers  Only validation text
lang["admin.pwd.minchar"]="Minimum Length Of Characters should be at least one (or) greater than one"; //minimum characters atleast one 
lang["admin.pwd.minchar16"]="Minimum Length Of Characters should be less than 16"; //minimum characters lessthan 16 
lang["admin.pwd.enternum"]="Enter a number that should not start with 0(Zero)"; // number shouldn't with zero validation text
lang["admin.pwd.sumofpwd"] = "Sum of lowercase, uppercase, digits and special characters length should be less than (or) equals to Minimum Length Of Characters."
lang["admin.pwd.retentionrqd"] = "Retention Period is required"; // retention period required
lang["admin.pwd.maxlenrqd"] = "Maximum Length Of Characters is required"; // pwd max length characters
lang["admin.pwd.maxchar16"] = "Maximum Length Of Characters should be less than (or) equal to 16"; // pwd min lowercase characters
lang["admin.pwd.minchar"] = "Maximum Characters length should be greater than (or) equals to Minimum Characters length"; //pwd min uppercase characters
lang["admin.pwd.lenexceeds"] = "Please select False, length exceeds minimum length of characters along with lowercase , uppercase , digits and special characters length"; // pwd policy validation
lang["admin.pwd.periodatleast"] = "Retention Period should be at least one month";// Retention Period should be at least one month
lang["admin.pwd.periodlessthan"] = "Retention Period must be less than 2,147,483, 647 months";//Retention Period must be less than 2,147,483, 647 months
lang["admin.pwd.failattemptslessthan"] = "Login fail attempts must be less than 100";//login fail attempts must be lessthan 100 validation


//Email disclaimer
lang["admin.emaild.title"] = "Email Disclaimer"; // email disclaimer title
lang["admin.emaild.applybtn"] = "Apply"; // email disclaimer btn

//sync configuration
lang["admin.addsyncbtn"] = "Add Sync Bucket"; // syncbucket button
lang["admin.synctitle"] = "Sync Bucket"; //sync bucket title
lang["admin.syncname"] = "Bucket Name"; // sync bucket name
lang["admin.synccategory"] = "Category"; //sync category
lang["admin.synchub"] = "Hub"; //sync category Hub
lang["admin.syncworkspace"] = "Workspace"; // sync category workspace
lang["admin.syncevery"] = "Every"; //sync every
lang["admin.syncdays"] = "Days"; // sync days
lang["admin.synchrs"] = "Hours"; // sync hours
lang["admin.syncmin"] = "Minutes"; // sync minutes
lang["admin.syncsec"] = "Seconds"; // sync seconds
lang["admin.sync.days"] = "Day (s)"; //sync day(s)
lang["admin.sync.hrs"] = "Hour (s)"; //sync Hour(s)
lang["admin.sync.min"] = "Minute (s)"; //sync Minute(s)
lang["admin.sync.sec"] = "Second (s)"; //sync second(s)
lang["admin.synctime"] = "Time"; // sync time
lang["admin.syncstatus"] = "Status"; // sync status
lang["admin.syncstatusact"] = " Active"; // sync status active
lang["admin.syncstatusdact"] = "Inactive"; // sync status Inactive
lang["admin.syncaddbtn"] = "Add"; // sync add button
lang["admin.synccanclbtn"] = "Cancel"; // sync cancel button
lang["admin.syncupdatebtn"] = "Update"; // sync edit update button
lang["admin.pholdsyncname"] = "Enter Sync Bucket Name"; // placeholder syncbucket name
lang["admin.pholdtime"] = "HH:mm"; // placeholder time format
lang["admin.sync.default"] = "Default";// Default
lang["admin.sync.custom"] = "Custom";// custom
lang["admin.sync.validname"] = "Please enter a valid name" // please enter a valid name
lang["admin.sync.inputdata"] = "Please give input data" //Please give input data 
lang["admin.sync.enternumber"] = "Please enter a number"//enter a number validation
lang["admin.sync.minminutes"] = "Minimum Value 1 Minute"//sync minimum value 1 minute
lang["admin.sync.maxminutes"] = "Maximum Value 59 Minutes"//sync maximum value 59 minute
lang["admin.sync.decimalval"] = "Decimal values are not allowed"//Decimal values not allowed
lang["admin.sync.minhr"] = "Minimum Value 1 Hour"//sync Minimum Value 1 Hour
lang["admin.sync.maxhr"] = "Maximum Value 23 Hours"//sync Maximum Value 23 Hours
lang["admin.sync.minday"] = "Minimum Value 1 Day"//sync Minimum Value 1 Day
lang["admin.sync.maxday"] = "Maximum Value 31 Days"//sync  Maximum Value 31 Days
lang["admin.sync.minsec"] = "Minimum Value 5 Seconds"//sync Minimum Value 5 Seconds
lang["admin.sync.maxsec"] = "Maximum Value 59 Seconds"//sync Maximum Value 59 Seconds
lang["admin.sync.digitsonly"] = "Please enter digits only"//Enter digits only
lang["admin.sync.timeformat"] = "Please enter the time in HH:mm format"//Enter time in HH:MM format
lang["admin.sync.spclchars"] = "Special characters are not allowed"//Special characters are not allowed
lang["admin.sync.maxhrshould"] = "Maximum hours should be 23"//max hours should be 23
lang["admin.sync.maxhrtimeformat"] = "Maximum hours should be 23 and time should be in HH:mm format"// max hours and time format
lang["admin.sync.maxminshould"] = "Maximum minutes should be 59"//Maximum minutes should be 59

//DATA HUB PAGE
lang["datahub.title"] = "Data Hub"; //Datahub title
lang["datahub.workspcetitle"] = "Workspaces"; //workspace title
lang["datahub.modeltitle"] = "Models"; //model  title
lang["datahub.notebook"] = "Notebook"; //notebook title
lang["datahub.synopsis"] = "Data Hub Synopsis"; // Datahub hub synopsis button
lang["datahub.slctentities"] = "Select Entities"; // select entities
lang["datahub.synconfig"] = " Sync Configuration"; // sync configuration
lang["datahub.synclog"] = "Data Hub Synopsis"; // Datahub sync log button
lang["datahub.entities"] = "Select Entities"; // select entities
lang["datahub.definesync"] = "Define Sync Configuration"; //define sync configuration
lang["datahub.createcnctn"] = "Create New Connection"; //Datahub create new connection
lang["datahub.existcnctn"] = "Existing Connections"; // existing connection
lang["datahub.search"] = "Search"; //datahub search
lang["datahub.showentity"] = "Show All Entities"; // show all entities
lang["datahub.filestitle"] = "Create Connection"; // files title
lang["datahub.searchfile"] = "Search..."; // files search
lang["datahub.database"] = "Database"; // database title
lang["datahub.flatfiles"] = "Flat Files"; // flatfiles title
lang["datahub.bigdata"] = "Big Data"; // Bigdata title
lang["datahub.services"] = "Services"; // services title
lang["datahub.streaming"] = "Streaming"; // streaming title
lang["datahub.others"] = "Others"; // others title
lang["datahub.upload"] = "Upload"; // flatfiles upload title
lang["datahub.file"] = "File"; // flatfiles  file title
lang["datahub.cnctnname"] = "Connection Name"; //files connection name
lang["datahub.namevalid"] = "Please Enter Connection Name"; // connection name validation
lang["datahub.nameerror"] = "Please Enter Valid Connection Name"; // connection name error validation
lang["datahub.maxsize"] = "Maximum upload file size:"; //upload file size
lang["datahub.sizemb"] = "MB"; // upload file size in MB
lang["datahub.slctfile"] = "Please Select A File"; //select a file error validation
lang["datahub.scanpages"] = "Scan Pages"; //pdf file scanpages title
lang["datahub.spcfypages"] = "Specify which pages to scan"; //pdf file specify text
lang["datahub.allpages"] = "All Pages"; // pdf all pages
lang["datahub.snglepage"] = "Single Page"; //pdf singlepage
lang["datahub.range"] = "Range"; //pdf range
lang["datahub.rangefm"] = "From"; // range from
lang["datahub.rangeto"] = "To"; // range to
lang["datahub.canclbtn"] = "Cancel"; //cancel button
lang["datahub.savebtn"] = "Save Connection"; //saveconnection button
lang["datahub.testcnctn"] = "Test Connection"; // testconnection
lang["datahub.deatils"] = "Connection Details"; // database connection details title
lang["datahub.Ipadd"] = "IP	Address"; // streaming kafka Ip address
lang["datahub.port"] = "Port"; // streaming kafka port
lang["datahub.add"] = "ADD"; // streaming kafka add port
lang["datahub.remove"] = " Remove"; // streaming kafka remove port
lang["datahub.grpid"] = "GroupID"; // streaming kafka groupid
lang["datahub.windauth"] = "Windows Authentication"; // streaming kafka windows authentication
lang["datahub.scopename"] = "Scope Name"; //database generic JDBC scopename
lang["datahub.cnctnstrng"] = "Connection String"; //database generic JDBC connection string
lang["datahub.jarfile"] = "Jar File"; //database generic JDBC jarfile
lang["datahub.drivername"] = "Driver Class Name"; //database generic JDBC driver class name
lang["datahub.username"] = "User Name"; //database generic JDBC username
lang["datahub.pwd"] = "Password"; //database generic JDBC password
lang["datahub.browsfile"] = "Browse File"; // log flatfile browserfile radio button
lang["datahub.Ftp"] = "FTP"; // log flatfile FTP radio button
lang["datahub.Sftp"] = "SFTP"; // log flatfile SFTP radio button
lang["datahub.srclctn"] = "Source Location"; // log flatfile source location
lang["datahub.ftpurl"] = "FTP URL"; // log flatfile FTP Url
lang["datahub.ftpvalid"] = "Please Enter FTP URL"; // log flatfile FTP error validation
lang["datahub.uservalid"] = "Please Enter UserName"; // log flatfile username validation
lang["datahub.pwdvalid"] = "Please Enter Password"; // log flatfile password validation
lang["datahub.logfile"] = "Browse Log Files"; // log flatfile browser log files
lang["datahub.puf"] = "Previously Uploaded File"; // log flatfile previously upload file
lang["datahub.logtyp"] = "LOG Type"; // log flatfile logtype
lang["datahub.slctlog"] = "Select Log Type"; // log flatfile selectlogtype
lang["datahub.custom"] = "CUSTOM"; // log flatfile custom
lang["datahub.cformat"] = "Custom Format"; // log flatfile custom format
lang["datahub.syntax"] = "Syntax"; // log flatfile syntax
lang["datahub.example"] = "Example"; // log flatfile example
lang["datahub.slct"] = "Select"; // log select option
lang["datahub.filename"] = "File Name"; // log file name
lang["datahub.filesize"] = "File Size"; // log file size
lang["datahub.lastupdtd"] = "Last Updated"; //log last updated
lang["datahub.weburl"] = "URL"; // services webdataconnector url
lang["datahub.endpoint"] = "End Point"; // services webdataconnector end point
lang["datahub.rqstformat"] = "Request Format"; // services webdataconnector Request format
lang["datahub.json"] = "JSON"; // services webdataconnector JSON
lang["datahub.rqstmetod"] = "Request Method"; // services webdataconnector Request method
lang["datahub.get"] = "GET"; // services webdataconnector get
lang["datahub.post"] = "POST"; // services webdataconnector post
lang["datahub.headerpmts"] = "Header Parameters"; // services webdataconnector Query parameters
lang["datahub.querypmts"] = "Query Parameters"; // services webdataconnector Query parameters
lang["datahub.addpmtr"] = "Add Parameter"; // services webdataconnector Add parameter
lang["datahub.formdata"] = "Form Data"; // services webdataconnector formdata
lang["datahub.auth"] = "Authentication"; // services webdataconnector Authentication
lang["datahub.none"] = "None"; // services webdataconnector none option
lang["datahub.windauth"] = "Windows Authentication"; // services webdataconnector windows authentication option
lang["datahub.2stpauth"] = "2 Step Authentication"; // services webdataconnector  2 step auth option
lang["datahub.domain"] = "Domain"; // services webdataconnector domain
lang["datahub.authurl"] = "Authentication URL"; // services webdataconnector authentication url
lang["datahub.tokename"] = "Token Key"; // services webdataconnector token name
lang["datahub.apikey"] = "Api Key"; // services webdataconnector API Key name
lang["datahub.authpmts"] = "Authentication Parameters"; // services webdataconnector authentication parameters
lang["datahub.validatebtn"] = "Validate"; // services webdataconnector validate button
lang["datahub.customqry"] = "Custom Query"; // custom query title
lang["datahub.cscqnamevalidate"] = "Please Provide a Valid Name"; //custom query validation
lang["datahub.cscqnamespecailcharacter"] =
  "Special Characters,White spaces Not Allowed"; // customquery special characters
lang["datahub.cscqnameexists"] = "Name Already Exists"; // custom query name exists
lang["datahub.plcholdrcustom"] = "Custom Query Name"; // placeholder customquery name
lang["datahub.clipboard"] = "Copy to Clipboard"; //custom copy clipboard
lang["datahub.datecolumn"] = "Date Column"; //custom sync date column
lang["datahub.daterange"] = "Date Range"; // custom date range
lang["datahub.numericcolumn"] = "Numeric Column"; //custom sync numeric column
lang["datahub.slctdconnection"] = "Selected Connections"; // define sync config selected connections
lang["datahub.syncconfigname"] = "Sync Configuration for"; // define sync config sync configuration name
lang["datahub.connection"] = "connection"; // define sync config sync configuration connection
lang["datahub.dataloadtitle"] = "Data Loading Configuration"; // define sync config title
lang["datahub.datasynctitle"] = "Data Sync Configuration"; // define sync config title
lang["datahub.removedata"] = "Remove Duplicate Data"; // define sync config remove duplicate data
lang["datahub.uniqefield"] = "Unique Field"; // define sync config unique field name
lang["datahub.slctfield"] = "Select at least one field"; // define sync config select field
lang["datahub.meta"] = "Meta Data (With 1000 Records)"; // meta data
lang["datahub.alldata"] = "All Data"; // define sync config all data
lang["datahub.loadmetadatanoteitem"] = "Note: Please consult our Professional Services team for bulk data loading options.";// Note for user on metadataselection
lang["datahub.numrange"] = "Numeric Range"; //  numeric range
lang["datahub.incmntrfrsh"] = "Incremental Refresh"; // define sync config sync incremental refresh
lang["datahub.identify"] = "(Identify new / modified rows using column(s))"; // define sync config incrementalrefresh text
lang["datahub.csndrasprt"] = "Cassandra does not support multiple columns"; // define sync config sync cassendra not support
lang["datahub.fullrefrsh"] = "Full Refresh"; // define sync config full refresh
lang["datahub.rowsadded"] = "(All rows will be added)"; // define sync config refresh text
lang["datahub.choosedateclmn"] = "Choose Date Column(s)"; // define sync config choose data columns
lang["datahub.choosenumclmn"] = "Choose Numeric Column(s)"; // define sync config choose numeric columns
lang["datahub.chooseclmn"] = "Choose Unique Column"; // define sync config choose unique name
lang["datahub.chooseclmns"] = "Choose Date/Numeric Column"; // define sync config choose data/numeric
lang["datahub.slctdatefield"] = "Select at least one Date field"; // define sync config select data field
lang["datahub.slctnumfield"] = "Select at least one Numeric field"; // define sync config select numeric field
lang["datahub.syncschdule"] = "Sync Job Schedule"; // define sync config data sync schedule
lang["datahub.status"] = "Streaming Status"; // define sync config streaming status
lang["datahub.pause"] = " Pause"; // define sync config pause
lang["datahub.stream"] = "Stream"; // define sync config stream
lang["datahub.entities"] = "Entities"; //datahub entities
lang["datahub.views"] = "Views"; // datahub views
lang["datahub.storedproced"] = "Stored Procedures"; // datahub story procedures
lang["datahub.paramtrs"] = "Parameters"; // datahub story procedures parameters
lang["datahub.paramtr"] = "Parameter"; // storyprocedure parameter
lang["datahub.type"] = "Type"; //storyprocedure type
lang["datahub.value"] = "Value"; //storyprocedure value
lang["datahub.updatebtn"] = "Update"; //update button
lang["datahub.savebtn"] = "Save"; // save button
lang["datahub.mdfyentity"] = "Modify Entities"; // modify entities
lang["datahub.addexist"] = "Add to Existing"; // add to existing
lang["datahub.entityname"] = "Entity Name"; // entity name
lang["datahub.columnname"] = "Column Name"; // column name
lang["datahub.displayname"] = "Display Name"; // display name
lang["datahub.colmndisply"] = "Column Display Name"; // column display name
lang["datahub.datatype"] = "DataType"; // datatype
lang["datahub.colspclcharact"] =
  "Special characters and Aggregation names are not allowed for column name"; // column special characters
lang["datahub.colnameexists"] =
  "Name Already Exists. Please Choose Another Name"; // column name exists
lang["datahub.idnotalow"] = "_id is not allowed for column name"; // column id not allowed
lang["datahub.strngtype"] = "String"; // string data type
lang["datahub.datetype"] = "Date"; // date data type
lang["datahub.numtype"] = "Number"; // number datatype
lang["datahub.curntformat"] = "Unable to parse with current format"; //current format
lang["datahub.dateformat"] = "Date Format"; // date format
lang["datahub.timeformat"] = "Date Time Format"; // Date time format
lang["datahub.boolean"] = "Boolean"; // boolean
lang["datahub.retentionperiod"] = "Sync Log Retention"; // synclog book retention settings
lang["datahub.refresh"] = "Refresh"; // synclogbook refresh button
lang["datahub.showing"] = "Showing "; // synclog book showing
lang["datahub.records"] = "records per page"; // records per page
lang["Datahub.weeks"] = "Week(s)"; // syncbook settings weeks
lang["Datahub.months"] = "Month(s)"; // syncbook settings months
lang["Datahub.years"] = "Year(s)"; // syncbook settings years
lang["Datahub.maxdata"] = "Retention Period"; // syncbook max data age
lang["Datahub.syncsetting"] = "Sync Log Retention"; //sync log settings title
lang["Datahub.synclogretn"] = "Enable Log Retention"; // synclog retention
lang["Datahub.apply"] = "Apply"; //apply button
lang["Datahub.settings"]="Settings"//settings tooltip
lang["Datahub.parasettings"]="Parameter Settings"// parameter settings
lang["Datahub.filmstock"]="film_in_stock"// film stock
lang["Datahub.unablefetch"]="Unable to Fetch Data";//Unable to Fetch Data warning
lang["Datahub.editcustomquery"]="Edit Custom Query"// customquery tooltip
lang["Datahub.enterstoredparamts"]="Please enter parameters for stored procedures" // enter stored procedures validation warning
lang["Datahub.validsuccess"]="Validated Sucessfully" // validated successfuly alert
lang["Datahub.nodataentity"]="This entity has no data to fetch";//This entity has no data to fetch
lang["Datahub.d/m/y"]="dd/MMM/yyyy HH:mm:ss" // date and time formats
lang["Datahub.m/d/y"]="MMM/dd/yyyy HH:mm:ss"
lang["Datahub.y/m/d"]="yyyy/MMM/dd HH:mm:ss"
lang["Datahub.d-m-y"]="dd-MMM-yyyy HH:mm:ss"
lang["Datahub.m-d-y"]="MMM-dd-yyyy HH:mm:ss"
lang["Datahub.y-m-d"]="yyyy-MMM-dd HH:mm:ss"
lang["Datahub.dd/MM/yyyy"]="dd/MM/yyyy HH:mm:ss"
lang["Datahub.MM/dd/yyyy"]="MM/dd/yyyy HH:mm:ss"
lang["Datahub.yyyy/MM/dd"]="yyyy/MM/dd HH:mm:ss"
lang["Datahub.dd-MM-yyyy"]="dd-MM-yyyy HH:mm:ss"
lang["Datahub.MM-dd-yyyy"]="MM-dd-yyyy HH:mm:ss"
lang["Datahub.yyyy-MM-dd"]="yyyy-MM-dd HH:mm:ss"
lang["Datahub.dd-MM-yy"]="dd-MM-yy HH:mm:ss"
lang["Datahub.dd/MM/yy"]="dd/MM/yy HH:mm:ss"
lang["Datahub.d/M/yy"]="d/M/yy HH:mm:ss"
lang["Datahub.yyyy/MM/dd"]="yyyy/MM/dd HH:mm"
lang["Datahub.dd/MM/yyyy"]="dd/MM/yyyy HH:mm"
lang["Datahub.MM/dd/yyyy"]="MM/dd/yyyy HH:mm"
lang["Datahub.sync.digitsonly"]  = "Please enter digits only" //Enter digits only
lang["Datahub.sync.timeformat"] = "Please enter the time in HH:mm format"//Enter time in HH:MM format
lang["Datahub.sync.spclchars"] = "Special characters are not allowed"//Special characters are not allowed
lang["Datahub.sync.maxhrshould"] = "Maximum hours should be 23"//max hours should be 23
lang["Datahub.sync.maxhrtimeformat"] = "Maximum hours should be 23 and time should be in HH:mm format"// max hours and time format
lang["Datahub.sync.maxminshould"] = "Maximum minutes should be 59"//Maximum minutes should be 59
lang["Datahub.inputdata"] = "Please give input data" //Please give input data 
lang["Datahub.enternumber"] = "Please enter a number"//enter a number validation
lang["Datahub.minminutes"] = "Minimum Value 1 Minute"// minimum value 1 minute
lang["Datahub.maxminutes"] = "Maximum Value 59 Minutes"// maximum value 59 minute
lang["Datahub.decimalval"] = "Decimal values are not allowed"//Decimal values not allowed
lang["Datahub.minhr"] = "Minimum Value 1 Hour"// Minimum Value 1 Hour
lang["Datahub.maxhr"] = "Maximum Value 23 Hours"// Maximum Value 23 Hours
lang["Datahub.minday"] = "Minimum Value 1 Day"//Minimum Value 1 Day
lang["Datahub.maxday"] = "Maximum Value 31 Days"//  Maximum Value 31 Days
lang["Datahub.minsec"] = "Minimum Value 5 Seconds"// Minimum Value 5 Seconds
lang["Datahub.maxsec"] = "Maximum Value 59 Seconds"// Maximum Value 59 Seconds
lang["Datahub.ADDcolumn"] = "ADD Column" // ADD column tooltip
lang["Datahub.maxageerror"] = "Please enter valid Max data age" ;// max data age validation error
lang["Datahub.exceedsmaxsize"] = "Exceeds maximum allowed upload size!!"// Exceeds maximum allowed upload size warning
lang["Datahub.filespclchar"] = "FileName must not contain Special Characters"//FileName must not contain Special Characters
lang["Datahub.connectionchar"]="Connection name should not contain more than 100 characters" // connection name not more than 100 characters
lang["Datahub.nameerror"] = "LDAP Name is required"//  Name is required error validation
lang["Datahub.urlerror"] = "URL is required"// URL is required error validation
lang["Datahub.usernameerror"] = "UserName is required"//UserName is required error validation
lang["Datahub.pwderror"] = "Password is required"//Password is required error validation
lang["Datahub.domainrqrd"] = "Domain is required"//Domain is required error validation
lang["Datahub.tokenrqrd"] = "Token is required"//Token is required error validation
lang["Datahub.apirqrd"] = "Api Key is required"//API key is required error validation
lang["Datahub.hostrqrd"] = "Host is required"//Host is required error validation
lang["Datahub.keyrqrd"] = "Key is required"//Key is required error validation
lang["Datahub.valuerqrd"] = "Value is required"//Value is required error validation
lang["Datahub.username"] = "Username"; //signin username error validation
lang["Datahub.pwd"] = "Password"; //signin password error validation
lang["Datahub.d/m/yformat"]="dd/MMM/yyyy" // Date formats
lang["Datahub.m/d/yformat"]="MMM/dd/yyyy"
lang["Datahub.y/m/dformat"]="yyyy/MMM/dd"
lang["Datahub.d-m-yformat"]="dd-MMM-yyyy"
lang["Datahub.m-d-yformat"]="MMM-dd-yyyy"
lang["Datahub.y-m-dformat"]="yyyy-MMM-dd"
lang["Datahub.dd/MM/yyyyformat"]="dd/MM/yyyy"
lang["Datahub.MM/dd/yyyyformat"]="MM/dd/yyyy"
lang["Datahub.yyyy/MM/ddformat"]="yyyy/MM/dd"
lang["Datahub.dd-MM-yyyyformat"]="dd-MM-yyyy"
lang["Datahub.MM-dd-yyyyformat"]="MM-dd-yyyy"
lang["Datahub.yyyy-MM-ddformat"]="yyyy-MM-dd"
lang["Datahub.dd-MM-yyformat"]="dd-MM-yy"
lang["Datahub.dd/MM/yyformat"]="dd/MM/yy"
lang["Datahub.d/M/yyformat"]="d/M/yy"
lang["Datahub.yyyy/MM/ddformat"]="yyyy/MM/dd"
lang["Datahub.dd/MM/yyyyformat"]="dd/MM/yyyy"
lang["Datahub.MM/dd/yyyyformat"]="MM/dd/yyyy"
lang["Datahub.sssz"] = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
lang["Datahub.ssss"] = "yyyy-MM-dd'T'HH:mm:ss.SSSSSSS"
lang["Datahub.submitbtn"] = " Submit"; //submit button




// Workspaces
lang["workspace.datahubtitle"] = "Data Hub"; //workspace title
lang["workspace.workspcetitle"] = "Workspaces"; //workspace title
lang["workspace.modeltitle"] = "Models"; //workspace model  title
lang["workspace.title"]="Business Workspaces" // business workspace title
lang["workspace.createon"]="Created on" //workspace create on
lang["workspace.edit"]="Edit" // workspace edit
lang["workspace.createmodel"]="Create Business Model" //create business model
lang["workspace.delete"]="Delete" // workspace delete
lang["workspace.updateon"]="Last Updated on " // last updated on
lang["workspace.models"]="Business Models" // business models
lang["workspace.goverance"]="Governance" // goverance
lang["workspace.createspace"]="Create Business Workspace"; // create business workspace
lang["workspace.business"]="Business Workspace";// business workspace
lang["workspace.synopsis"]="Workspace Synopsis";// workspace synopsis
lang["workspace.entities"]="Select Entities";// select emtities
lang["workspace.curntstp"]="current step:";// current step
lang["workspace.savespace"] = "Save Workspace"// save workspace button
lang["workspace.customquery"]="Custom Query";// custom query title
lang["workspace.entities"]="Entities";// entities
lang["workspace.search"]="Search";// search 
lang["workspace.forcesync"]="Force Sync";// force sync
lang["workspace.cscqnamevalidate"] = "Please Provide a Valid Name"; //custom query validation
lang["workspace.cscqspecalchara"] =
  "Special Characters,White spaces Not Allowed"; // customquery special characters
lang["workspace.cscqnameexists"] = "Name Already Exists"; // custom query name exists
lang["workspace.plcholdrcustom"] = "Custom Query Name"; // placeholder customquery name
lang["workspace.clipboard"] = "Copy to Clipboard"; //custom copy clipboard
lang["workspace.validatebtn"] = "Validate"; //  validate button
lang["workspace.savebtn"] = "Save"; // save btn
lang["workspace.filters"]="Filters";// filters
lang["workspace.customfield"]="Custom Fields";// custom fields
lang["workspace.createcustom"]="Create Custom Field";// create custom field
lang["workspace.previewdata"]="Preview Data";// preview data
lang["workspace.addfilter"]="Add Filter";// add filter btn
lang["workspace.applyfilter"]="Apply Filters";// apply filters
lang["workspace.preview"]="Previewing";// preview data previewing
lang["workspace.rows"]="Rows"// preview data rows
lang["workspace.details"]="Workspace Details"// save workspace title
lang["workspace.name"]="Name"// save workspace name
lang["workspace.placeholdname"]="Workspace Name" // placeholder name
lang["workspace.describe"]="Description"// workspace description
lang["workspace.livestatus"]="Real-time Data"// Live Streaming
lang["workspace.liveon"]="ON"// ON
lang["workspace.liveoff"]="OFF"// OFF

lang["workspace.placeholddescribe"]="Enter Your Workspace Description Here"// placeholder description
lang["workspace.syncschedule"]="Sync Job Schedule"// datasync schedule
lang["workspace.note"]=
"Note: For data consistency, the sync schedule at Workspace level overrides, the sync settings at each entity level configured as part of the Data Hub"// savw workspace note
lang["workspace.noteforlive"]="Note: If real-time data is enabled, only latest 24 hours of data will be shown as per '"+(window.localStorage.getItem("serverTimezone")!=null && window.localStorage.getItem("serverTimezone")!="" ? window.localStorage.getItem("serverTimezone"):'Local')+"' time zone.";
lang["workspace.cancelbtn"]="Cancel" // save workspace cancel btn
lang["workspace.applybtn"]="Apply"// save workspace apply btn
lang["workspace.select"]="Select All"// filter workspace accordian select all
lang["workspace.deselect"]="Deselect All"//filter workspace accordian Deselect all
lang["workspace.advanced"]="Advanced"// workspace filter accordian advanced option
lang["workspace.startswith"]="Starts with"// workspace filter accordian starts with
lang["workspace.endswith"]="Ends with"// workspace filter accordian ends with
lang["workspace.contains"]="Contains"// workspace filter accordian contains
lang["workspace.go"]="GO"// workspace filter accordian go
lang["workspace.or"]="(OR)"//or
lang["workspace.greater"]="Greater Than (or) Equal To (>=)"//workspace filter accordian greaterthan or equalto
lang["workspace.lesser"]="Less Than (or) Equal To (<=)"//workspace filter accordian lessthan or equal to
lang["workspace.placeholdcomma"]="CommaSeparated Values"//workspace filter accorian placeholder comma seperate values
lang["workspace.currentvalue"]="Current value"// current value
lang["workspace.fieldname"]="Custom Field Name"//workspace customfield filter field name
lang["workspace.formulaedit"]="Formula Editor"//workspace customfield filter formula editor
lang["workspace.customname"]="Custom Name"//workspace customfield filter custom name
lang["workspace.clear"]="Clear"//workspace customfield clear btn
lang["workspace.formula"]="Formula"//workspace customfield formula
lang["workspace.hint"]="Hint"//workspace customfield hint
lang["workspace.numbertype"]="Number"//workspace customfield number datatype
lang["workspace.strngtype"]="String"//workspace customfield string datatype
lang["workspace.datetype"]="Date"//workspace customfield date datatype
lang["workspace.logical"]="Logical"//workspace customfield logical datatype
lang["workspace.type"]="Type"//workspace customfield type
lang["workspace.function"]="Function"//workspace customfield function
lang["workspace.filter"]="Choose Filter"// choose filter
lang["workspace.synclogtitle"]="WORKSPACE SYNC LOG" // workspace synclog book title
lang["workspace.retentionperiod"] = "Sync Log Retention"; // synclog book retention
lang["workspace.refresh"] = "Refresh"; // synclogbook refresh button
lang["workspace.showing"] = "Showing "; // synclog book showing
lang["workspace.records"] = "records per page"; // records per page
lang["workspace.weeks"] = "Week(s)"; // syncbook settings weeks
lang["workspace.months"] = "Month(s)"; // syncbook settings months
lang["workspace.years"] = "Year(s)"; // syncbook settings years
lang["workspace.maxdata"] = "Retention Period"; // syncbook max data age
lang["workspace.syncsetting"] = "Sync Log Retention"; //sync log settings title
lang["workspace.synclogretn"] = "Enable Log Retention"; // synclog retention
lang["workspace.settings"]="Settings"// workspace settings tooltip
lang["workspace.facttable"]="Set As Fact Table" // workspace set as fact table tool tip
lang["workspace.gridview"]="Grid View" // grid view tooltip
lang["workspace.listview"]="List View"//list view tooltip
lang["workspace.more"]="More"// more tooltip
lang["workspace.editcustomquery"]="Edit Custom Query"// customquery tooltip
lang["workspace.errorinfo"]="Click to see the Error Information" // error information tooltip
lang["workspace.data"]="Data" // data navigation-edit
lang["workspace.editspace"]="Edit Workspace"// edit workspace tooltip
lang["workspace.deletespace"]="Delete Workspace"// delete workspace tooltip
lang["workspace.inputdata"] = "Please give input data" //Please give input data 
lang["workspace.enternumber"] = "Please enter a number"//enter a number validation
lang["workspace.minminutes"] = "Minimum Value 1 Minute"// minimum value 1 minute
lang["workspace.maxminutes"] = "Maximum Value 59 Minutes"// maximum value 59 minute
lang["workspace.decimalval"] = "Decimal values are not allowed"//Decimal values not allowed
lang["workspace.minhr"] = "Minimum Value 1 Hour"// Minimum Value 1 Hour
lang["workspace.maxhr"] = "Maximum Value 23 Hours"// Maximum Value 23 Hours
lang["workspace.minday"] = "Minimum Value 1 Day"//Minimum Value 1 Day
lang["workspace.maxday"] = "Maximum Value 31 Days"//  Maximum Value 31 Days
lang["workspace.minsec"] = "Minimum Value 5 Seconds"// Minimum Value 5 Seconds
lang["workspace.maxsec"] = "Maximum Value 59 Seconds"// Maximum Value 59 Seconds
lang["workspace.digitsonly"]  = "Please enter digits only" //Enter digits only
lang["workspace.timeformat"] = "Please enter the time in HH:mm format"//Enter time in HH:MM format
lang["workspace.spclchars"] = "Special characters are not allowed"//Special characters are not allowed
lang["workspace.maxhrshould"] = "Maximum hours should be 23"//max hours should be 23
lang["workspace.maxhrtimeformat"] = "Maximum hours should be 23 and time should be in HH:mm format"// max hours and time format
lang["workspace.maxminshould"] = "Maximum minutes should be 59"//Maximum minutes should be 59
lang["workspace.entervalidname"] = "PLEASE ENTER VALID NAME"//PLEASE ENTER VALID NAME
lang["workspace.unablefetch"]="Unable to Fetch Data";//Unable to Fetch Data
lang["workspace.nodataentity"]="This entity has no data to fetch";//This entity has no data to fetch
lang["workspace.filtersapplied"]="Filter(s) Applied"//Filter(s) Applied
lang["workspace.maxageerror"] = "Please enter valid Max data age" ;// max data age validation error
lang["workspace.entityname"] = "Entity Name"; // entity name
lang["workspace.fieldformula"] = "Custom Field Formula"
lang["workspace.nomatchsfnd"] = "No matching records found"
lang["workspace.submitbtn"] = " Submit"; //submit button
lang["workspace.validname"]="Please Enter Valid Name"// valid name error
lang["workspace.reportnameexceeds"]="Workspace Name Exceeds Maixmum Length 70" // Workspace name exceeds maximum
lang["workspace.noreservewords"]="Workspace Name should not contain 'Reserved Words'"//Workspace name not contain reserve words
lang["workspace.customqueryhelp"]="1. Table names and column names used in custom queries must be enclosed in double quotes.\n2. Double-quote any column name that contains a space or special character.\n3. In custom queries, the 'OA_timestamp' column should be included if real-time data option will be enabled." //Hint for Workspace custom query
              // Models
lang["models.business"]="Business Models";// business models title
lang["models.entities"]="Select Entities";// select entities
lang["models.joins"]="Define Joins";//  models define joins
lang["models.createjoin"]="Create Join";// models createjoin
lang["models.previewmodel"]="Preview Model";// models preview model
lang["models.gridview"]="Grid View" // grid view tooltip
lang["models.listview"]="List View"//list view tooltip
lang["models.createon"]="Created on" // create on
lang["models.updateon"]="Last Updated on " // last updated on
lang["models.addnewsource"]="Add New Source";// add new source
lang["models.customquery"]="Custom Query";// custom query
lang["models.addcusquery"]="Add Custom Query";// add customquery tooltip
lang["models.entities"]="Entities";// Tables
lang["models.search"]="Search"; // search 
lang["models.streaming"]="Streaming"; // add new source streaming
lang["models.cscqnamevalidate"] = "Please Provide a Valid Name"; //custom query validation
lang["models.cscqspecalchara"] =
  "Special Characters,White spaces Not Allowed"; // customquery special characters
lang["models.cscqnameexists"] = "Name Already Exists"; // custom query name exists
lang["models.plcholdrcustom"] = "Custom Query Name"; // placeholder customquery name
lang["models.clipboard"] = "Copy to Clipboard"; //custom copy clipboard
lang["models.validatebtn"] = "Validate"; //  validate button
lang["models.savebtn"] = "Save"; // save btn
lang["models.modifyentity"] = "Modify Entities"; // modify entities
lang["models.updatebtn"] = "Update"; // update btn
lang["models.entityname"] = "Entity Name"; // entity name
lang["models.columnname"] = "Column Name"; // column name
lang["models.colmndisply"] = "Column Display Name"; // column display name
lang["models.datatype"] = "DataType"; // datatype
lang["models.visibility"] = "Visibility"; // datatype
lang["models.colspclcharact"] =
  "Special characters and Aggregation names are not allowed for column name"; // column special characters
lang["models.colnameexists"] =
  "Name Already Exists. Please Choose Another Name"; // column name exists
lang["models.idnotalow"] = "_id is not allowed for column name"; // column id not allowed
lang["models.strngtype"] = "String"; // string data type
lang["models.datetype"] = "Date"; // date data type
lang["models.numtype"] = "Number"; // number datatype
lang["models.curntformat"] = "Unable to parse with current format"; //current format
lang["models.removejoin"]="Remove Join"//  models definejoin- removejoin
lang["models.factcolmn"]="Select Fact Column"//  models definejoin- select fact column
lang["models.slctcolmn"]="Select Column"//  models definejoin-select column
lang["models.jointype"]="JOIN TYPE"//  models definejoin- join type
lang["models.nojoins"]="No Joins Found"//  models definejoin- no joins found
lang["models.savemodel"]="Save Business Model"// savemodel-save business model
lang["models.details"]="Business Model Details"// save model title
lang["models.name"]="Name"// save model name
lang["models.placeholdname"]="Business Model Name" // placeholder name
lang["models.describe"]="Description"// model description
lang["models.placeholddescribe"]="Enter Your Business Model Description Here"// placeholder description
lang["models.cancelbtn"]="Cancel" // cancel btn
lang["models.applybtn"]="Apply"//  apply btn
lang["models.edit"]="Edit"// models-edit
lang["models.create"]="Create"// models-create
lang["models.storyboard"]="Storyboard"// models-storyboard
lang["models.report"]="Report"// models-report
lang["models.smartinsight"]="Smart Insight"// models-smart insight
lang["models.smartinsights"]="Smart Insights"// models-smart insight
lang["models.mlreport"]="ML Studio"// models-ml report
lang["models.importtemplate"]="Import Template"// models-import template
lang["models.autoviz"]="AutoViz"//models-autoviz
lang["models.delete"]="Delete"// models-delete
lang["models.deletecache"]="Clear Cache"// models-delete cache
lang["models.more"]="More"// models-more tooltip
lang["models.editmodel"]="Edit Model" //models-edit model tooltip
lang["models.createstoryboard"]="Create Storyboard"// models-storyboard
lang["models.createreport"]="Create Report"// models-report
lang["models.createsmartinsight"]="Create Smart Insights"// models-smart insight
lang["models.editcustomquery"]="Edit Custom Query"// customquery tooltip
lang["models.data"]="Data" // data navigation-editmodel
lang["models.goverance"]="Governance"// goverance title
lang["models.sharemodel"]="Share Model" // goverance-sharemodel
lang["models.datamodel"]="Data Model"// goverance-data model
lang["models.groups"]="Groups / Users"//goverance-groups/users
lang["models.group's"]="Groups"
lang["models.users"]="Users"
lang["models.slctusers"]="Select Users"// placeholder select users
lang["models.advanced"]="Advanced"// workspace filter accordian advanced option
lang["models.startswith"]="Starts with"// workspace filter accordian starts with
lang["models.endswith"]="Ends with"// workspace filter accordian ends with
lang["models.contains"]="Contains"// workspace filter accordian contains
lang["models.go"]="GO"// workspace filter accordian go
lang["models.comment"]="Comment" // goverance comment
lang["models.placeholdcomment"]="Enter Your Comment Here"// placeholder comment
lang["models.advancedpermison"]="Advanced Access Permission" //goverance advanced access permission
lang["models.restricteddata"]="RESTRICT DATA FOR SELECTED GROUP/USER"// goverance restricted common text
lang["models.sharedto"]="Shared To" // shared to
lang["models.sharebtn"]="Share"// goverance share button
lang["models.restrictedcolumns"]="RESTRICT COLUMNS FOR GROUPS/USER"// advancedpermission-restricted columns
lang["models.editchecked"]="Edit Checked"// advancedpermission-edit checked 
lang["models.checkall"]="Check All"// advancedpermission-check all
lang["models.uncheckall"]="Uncheck All"// advancedpermission-uncheck all
lang["models.columns"]="Columns"//advancedpermission- columns
lang["models.settings"]="Settings"//settings tooltip
lang["models.slctonecolm"]="Please select atleast one column as visible"// Please select atleast one column as visible 
lang["models.fillpropercolm"]="Please Fill Proper Column Display Name";// Please Fill Proper Column Display Name validation text
lang["models.duplicatecolm"]="Duplicate Column Display Names are not allowed";//Duplicate Column Display Names are not allowed validation text
lang["models.unablefetch"]="Unable to Fetch Data";//Unable to Fetch Data
lang["models.nodataentity"]="This entity has no data to fetch";//This entity has no data to fetch
lang["models.abc"]="ABC" // ABC
lang["models.connectionchar"]="Connection name should not contain more than 100 characters" // connection name not more than 100 characters
lang["models.entervalidname"]="PLEASE ENTER A VALID NAME"//valid name validation
lang["models.slctatleastgrp"]="Please select atleast one group (or) user to share model."// slct atleast one group/user validation
lang["models.oops"]="Oops, nothing found!" // Oops, nothing found!
lang["models.searchmodels"]="Search Datamodels" // goverance-placeholder search data models
lang["models.validname"]="Please Enter Valid Name"// valid name error
lang["models.reportnameexceeds"]="Model Name Exceeds Maixmum Length 70" // model name exceeds maximum
lang["models.noreservewords"]="Model Name should not contain 'Reserved Words'"//model name not contain reserve words
// visualization
// favourites
lang["visual.favourites.title"]="Favorites" // favourites title
lang["visual.favourites.storyboard"]="Storyboards" // storyboards title
lang["visual.favourites.storybooks"]="Storybooks"//storybooks title
lang["visual.favourites.reports"]="Reports"// reports title
lang["visual.favourites.shared"]="Shared"// shared title
lang["visual.favourites.gridview"]="Grid View" // grid view
lang["visual.favourites.folderview"]="Folder View"// folder view
lang["visual.favourites.listview"]="List View"// list view
lang["visual.favourites.viewmore"]="View More..."// view more
lang["visual.favourites.myboards"]="My Storyboards" // my storyboard title
lang["visual.favourites.customboards"]="Custom Storyboards"// custom storyboards title
lang["visual.favourites.createboard"]="Create Custom Storyboard" // create storyboard tooltip
lang["visual.favourites.mybooks"]="My Storybooks"// my storybooks title
lang["visual.favourites.createbook"]="Create Storybook"// create storybook tooltip
lang["visual.favourites.myreports"]="My Reports"// my reports title
lang["visual.favourites.sharereports"]="Shared Reports"// shared reports title
lang["visual.favourites.search"]="Search" // search
lang["visual.favourites.viewreport"]="View Report" // view report
lang["visual.favourites.remove"]="Remove"// remove 
lang["visual.favourites.createon"]="Created on" // created on
lang["visual.favourites.more"]="More"// favourites more tooltip
lang["visual.favourites.delete"]="Delete"// delete
lang["visual.favourites.viewbook"]="View Storybook" // view storybook
lang["visual.favourites.viewcustomboard"]="View CustomStoryboard" // view custom storyboard
lang["visual.slctfolders"]="Select Folders"
lang["visual.folders"]="Folders"
lang["visual.favourites.oops"]="Oops, nothing found!" // Oops, nothing found!

// Alerts
lang["alerts.notification"]="Notifications" // notification title
lang["alerts.alertnotification"]="Alert Notifications" // alert notification title
lang["alerts.search"]="Search" // search
lang["alerts.viewreport"]="View Report" // view report


// search
lang["search.businessmodel"]="Business Model"// business model title
lang["search.intelligent"]="Smart Search" // Smart Search
lang["search.availfields"]="Available Fields" // available fields
lang["search.showing"] = "Showing "; // showing
lang["search.records"] = "records per page"; // records per page
lang["search.clear"] = "Clear"; // clear tooltip
lang["search.savereport"] = "Save As MyReport"; // save as my report tooltip
lang["search.placeholder"] = "Start Typing Here For Type-ahead Feature" // placeholder
lang["search.savemyreport"]="Save MyReport" // save myreport -save myreport title
lang["search.reportdetails"]="Report Details"//  save myreport -report details title
lang["search.name"]="Name" //  save myreport -name
lang["search.reportname"]="Report Name" //save myreport - placeholder name
lang["search.folder"]="Folder"//  save myreport - folder
lang["search.slctfolder"]="Select/Enter folder name"//save myreport - placeholder folder name
lang["search.description"]="Description"// save myreport -description
lang["search.placeholdescription"]="Enter Your Report Description Here"// save myreport -placeholder description
lang["search.cancelbtn"]="Cancel"// save myreport -cancelbtn
lang["search.applybtn"]="Apply"// save myreport -applybtn
lang["search.nameexceeds"]="FOLDER NAME EXCEEDS MAXIMUM LENGTH "
lang["search.abc"]="ABC" // ABC
lang["search.serverconnect"]="Not able to connect to Server, please check the connection details"// server connect warning
lang["search.datahandlerconctn"]="Please Check Data Handler Connection" // check Data handler connection
lang["search.criterianotfound"]="Searched criteria is not found,Please enter valid search Criteria"// valid search criteria warning
lang["search.validname"]="Please Enter Valid Name"// valid name error
lang["search.reportnameexceeds"]="Report Name Exceeds Maixmum Length 70" // Report name exceeds maximum
lang["search.noreservewords"]="Report Name should not contain 'Reserved Words'"//storyboard name not contain reserve words

// storyboard
lang["storyboard.filters"]="Filters" // storyboard filters
lang["storyboard.addfilter"]="Add Filter" //  filters- Add filter
lang["storyboard.addparameter"]="Add Parameter" // filters -Add parameter
lang["storyboard.choosefilter"]="Choose Filter"//add filter-choose filter
lang["storyboard.search"]="Search"// search
lang["storyboard.cancelbtn"]="Cancel"// cancel
lang["storyboard.applybtn"]="Apply"// apply
lang["storyboard.chooseparameter"]="Choose Parameter"// add parameter-choose parameter
lang["storyboard.noparameters"]="No Parameters Available" // add parameter- no parameters available
lang["storyboard.quickinfo"]="Quick Information"// quick information tooltip
lang["storyboard.addnewfilter"]="Add New Filter" // add new filter
lang["storyboard.refresh"]="Refresh"// refresh
lang["storyboard.general"]="General"// more-general
lang["storyboard.saveboard"]="Save Storyboard"// more-save storyboard
lang["storyboard.saveversion"]="Save Version"// more-save  version
lang["storyboard.liveboard"]="LIVE Storyboard"// more-live storyboard
lang["storyboard.audit"]="Audit"// more-audit
lang["storyboard.drillsownall"]="Drilldown All"// more-drilldown all
lang["storyboard.drilldownsettings"]="Drilldown Settings"// more- drilldown settings
lang["storyboard.customscript"]="Custom Script"// more-custom script
lang["storyboard.customize"]="Customize"// more-customize
lang["storyboard.boardtheme"]="Storyboard Theme"// more-storyboard theme
lang["storyboard.edittitle"]="Edit Title"// more-edit title
lang["storyboard.newcharttile"]="New Chart Tile"// more-new chart tile
lang["storyboard.favorite"]="Favorite"// more-favourite
lang["storyboard.publishstoryboard"]="Publish Storyboard"// publishstoryboard
lang["storyboard.collaborate"]="Collaborate"// more-collaborate
lang["storyboard.shareboard"]="Share Storyboard"// more-share storyboard
lang["storyboard.embedboard"]="Embed Storyboard"// more-embed storyboard
lang["storyboard.scheduleboard"]="Schedule Storyboard"// more-schedule storyboard
lang["storyboard.download"]="Download"// more-download
lang["storyboard.export"]="Export"// more-export
lang["storyboard.more"]="More"// more tooltip
lang["storyboard.logout"]="Logout"// logout
lang["storyboard.boardtitle"]="Storyboard Title"// storyboard title
lang["storyboard.reportsuccess"]="Report Versioned Successfully" //report versioned sucessfully
lang["storyboard.erroroccur"]="Error Occured While Versioning the Report"// error occured
lang["storyboard.reportinfo"]="Report Info"// quickinformation---report info
lang["storyboard.reportname"]="Report Name"// quickinformation---report info----report name
lang["storyboard.folder"]="Folder"// quickinformation---report info---folder
lang["storyboard.createdon"]="Created On"// quickinformation---report info---created on
lang["storyboard.lastupdated"]="Last Updated"// quickinformation---report info---last updated
lang["storyboard.nextdatasync"]="Next Data Sync Time"// quickinformation----report info---next data sync time
lang["storyboard.timezone"]="Time Zone"// quickinformation----report info---time zone
lang["storyboard.totalviews"]="Total Views"// quickinformation----report info---total views
lang["storyboard.favreport"]="Favorite Report"// quickinformation----report info---favourite report
lang["storyboard.yes"]="Yes"// quickinformation----report info---yes
lang["storyboard.no"]="No"// quickinformation----report info---no
lang["storyboard.metaudit"]="Meta-Data Audit"// quickinformation----report info---meta data audit
lang["storyboard.sharedinfo"]="Shared Info"// quickinformation----shared info
lang["storyboard.groups"]="Groups"// quickinformation-----shared info----groups
lang["storyboard.viewaccess"]="View Only Access"// quickinformation-----shared info----view only access tooltip
lang["storyboard.permitcolumns"]="Permitted Columns"// quickinformation-----shared info----permitted columns
lang["storyboard.allowcolumns"]="ALLOWED ALL COLUMNS"// quickinformation-----shared info----allowed all columns
lang["storyboard.users"]="Users"// quickinformation-----shared info----users
lang["storyboard.noshareinfo"]="No Shared Info Available"// quickinformation-----shared info----no shared info available
lang["storyboard.scheduleinfo"]="Schedule Info"// quickinformation---Schedule Info
lang["storyboard.scheduleto"]="Scheduled To"// quickinformation----Schedule Info---scheduled to
lang["storyboard.access"]="Access Permission"// quickinformation----Schedule Info---access permission
lang["storyboard.datefield"]="Date Field"// quickinformation----Schedule Info----date field
lang["storyboard.scheduled"]="Scheduled"// quickinformation----Schedule Info----scheduled
lang["storyboard.startime"]="Start Time"// quickinformation----Schedule Info----start time
lang["storyboard.endtime"]="End Time"// quickinformation----Schedule Info---endtime
lang["storyboard.noscheduleinfo"]="No Schedule Info Available"// quickinformation-
lang["storyboard.columname"]="Column Name"// quickinformation------filter info---column name
lang["storyboard.filterdata"]="Filter Data"// quickinformation------filter info---filter data
lang["storyboard.nofilters"]="No Filters Available"// quickinformation-----filter info----no filters avilable
lang["storyboard.alertsinfo"]="Alerts Info"// quickinformation----alertinfo
lang["storyboard.alertid"]="Alert ID"// quickinformation----alertinfo----alert id
lang["storyboard.alerttitle"]="Alert Title"// quickinformation----alertinfo----alert title
lang["storyboard.notifyto"]="Notify To"// quickinformation----alertinfo----notify to
lang["storyboard.noalerts"]="No Alerts Info Available"// quickinformation---alertinfo----no alerts available
lang["storyboard.reportdetails"]="Report Details"//  save storyboard -report details title
lang["storyboard.name"]="Name" //  save storyboard -name
lang["storyboard.placeholdname"]="Storyboard Name" //save storyboard - placeholder name
lang["storyboard.folder"]="Folder"//  save storyboard - folder
lang["storyboard.slctfolder"]="Select/Enter folder name"//save storyboard - placeholder folder name
lang["storyboard.description"]="Description"// save storyboard -description
lang["storyboard.placeholdescription"]="Enter Your Storyboard Description Here"// save storyboard -placeholder description
lang["storyboard.versionboard"]="Version Storyboard"//  save version - version storyboard title
lang["storyboard.versionname"]="Version Name"//save version - placeholder version name
lang["storyboard.versionlist"]="Versions List"// save version -versions list
lang["storyboard.livereport"]="LIVE Report"// live report
lang["storyboard.none"]="None"// livereport--none
lang["storyboard.updatefrequency"]="Update Frequency" // livereport-
lang["storyboard.updatevery"]="Update Every" //// livereport-
lang["storyboard.hrs"] = "Hour(s)"; // // livereport-hours
lang["storyboard.min"] = "Minute(s)"; // // livereport- minutes
lang["storyboard.sec"] = "Second(s)"; // // livereport- seconds
lang["storyboard.streaming"] = "Streaming"; // // livereport-streaming
lang["storyboard.priorityclmns"] = "Priority Columns";// drill down settings--priority columns
lang["storyboard.slctclmns"] = "Select Columns";// drill down settings--select columns
lang["storyboard.drilllist"] = "Drill-Through Reports List";// drill down settings--drill through reports list
lang["storyboard.slctreports"] = "Select Reports";// drill down settings--select reports
lang["storyboard.auditoptions"] = "Audit Options"// audit--audit options
lang["storyboard.metadataaudit"] = "Meta-Data Audit"// audit--meta data audit
lang["storyboard.customscript"] = "User Custom Script"// custom script--user custom script
lang["storyboard.datacounttitle"] = "Data Count as Title Tooltip"// storyboardtheme--data count
lang["storyboard.show"] = "Show"// storyboardtheme--show
lang["storyboard.hide"] ="Hide"//storyboardtheme--hide
lang["storyboard.titlecolor"] ="Title Color"// storyboardtheme--title color
lang["storyboard.placeholdtitle"] ="#424242"// storyboardtheme--placeholder title color
lang["storyboard.header"] ="Header Background"// storyboardtheme--header background
lang["storyboard.placeholdheader"] ="#ebebeb"// storyboardtheme--placeholder header background
lang["storyboard.background"] ="Storyboard Background"// storyboardtheme--storyboard background
lang["storyboard.placeholdbackgrnd"] ="#FFFFFF"// storyboardtheme--placeholder storyboard background
lang["storyboard.footer"] ="Footer Background"// storyboardtheme--footer background
lang["storyboard.placeholdfooter"] ="#e2e3e4"// storyboardtheme--placeholder footer background
lang["storyboard.footertext"] ="Footer Text"// storyboardtheme--footer text
lang["storyboard.placeholdfoottext"] ="#424242"// storyboardtheme--placeholder footer text
lang["storyboard.myreporttitle"] ="My Report Title" // chart tile-my report title
lang["storyboard.sharereport"] ="Share Report" // share storyboard-share report title
lang["storyboard.slctusers"]="Please Select Groups/Users"// placeholder select users
lang["storyboard.advanced"]="Advanced"// share storyboard advanced option
lang["storyboard.startswith"]="Starts with"// share storyboard starts with
lang["storyboard.endswith"]="Ends with"// share storyboard ends with
lang["storyboard.contains"]="Contains"// share storyboard contains
lang["storyboard.go"]="GO"// share storyboard go
lang["storyboard.comment"]="Comment" // share storyboard comment
lang["storyboard.placeholdcomment"]="Enter Your Comment Here"//share storyboard placeholder comment
lang["storyboard.advancedpermison"]="Advanced Access Permission" //share storyboard advanced access permission
lang["storyboard.restricteddata"]="RESTRICT DATA FOR SELECTED GROUP/USER"// restricted data common text
lang["storyboard.restrictedcolumns"]="RESTRICT COLUMNS FOR GROUPS/USER"// advancedpermission-restricted columns
lang["storyboard.editchecked"]="Edit Checked"// advancedpermission-edit checked 
lang["storyboard.checkall"]="Check All"// advancedpermission-check all
lang["storyboard.uncheckall"]="Uncheck All"// advancedpermission-uncheck all
lang["storyboard.viewaccess"]="View Only Access"// advancedpermission-view only access
lang["storyboard.edit"]="Edit" // edit
lang["storyboard.update"]="Update" // update btn
lang["storyboard.slctall"]="Select All" // select all
lang["storyboard.dslctall"]="Deselect All" // deselect all
lang["storyboard.filterdrill"]="Add Filter to Drill-Through Reports" // add filter
lang["storyboard.clear"]="Clear"// clear
lang["storyboard.applyfilters"]="Apply Filters"// apply filters
lang["storyboard.or"]="(OR)"//or
lang["storyboard.greater"]="Greater Than (or) Equal To (>=)"// advancedpermission-greaterthan or equalto
lang["storyboard.lesser"]="Less Than (or) Equal To (<=)"// advancedpermission -lessthan or equal to
lang["storyboard.equalto"]="Equal To (==)"// equal to
lang["storyboard.in"]="IN"//in
lang["storyboard.currentval"]="Current Value"// current value
lang["storyboard.removefilter"]="Remove Filter"// remove filter tooltip
lang["storyboard.embedreport"]="Embed Report URL & Email"// embedreport-- embed report url and email
lang["storyboard.msgsuccess"]="Message has been sent successfully"// embedreport--message success
lang["storyboard.msgerror"]="Error occurred while sending an email. Please check your email configurations"// embedreport--msg error
lang["storyboard.permission"]="Access Permission"// embedreport--access permission
lang["storyboard.withlogin"]=" Access with Login"// embedreport--acess with login
lang["storyboard.withoutlogin"]="Access without Login"// embedreport--acess without login
lang["storyboard.urlexpiry"]="URL Expiry"// embedreport--url expiry
lang["storyboard.date"]="Date"// embedreport--date
lang["storyboard.time"]="Time"// embedreport--time
lang["storyboard.urlaccess"]="URL for accessing this report"// embedreport--url accessing report
lang["storyboard.withoutiframe"]="Without iframe"// embedreport--without iframe
lang["storyboard.withframe"]="With iframe"// embedreport--with frame
lang["storyboard.copyclip"]="Copy to Clipboard"// embedreport--copy clipboard
lang["storyboard.reporturl"]="Email Report URL"// embedreport--embed email report url
lang["storyboard.to"]="To"// embedreport--to
lang["storyboard.validemail"]="Please Enter Valid e-mail"// embedreport--valid email
lang["storyboard.validemailuser"]="Please Select Users"// embedreport--valid email
lang["storyboard.subject"]="Subject"// embedreport--subject
lang["storyboard.message"]="Message"// embedreport--message
lang["storyboard.addscreenshot"]="Add Screen Shot"// embedreport--add screenshot
lang["storyboard.send"]="Send"// send
lang["storyboard.schedulereport"]="Schedule Storyboard"// schedule report-- schedule report title
lang["storyboard.reportsuccess"]="Report Scheduled Successfully"// schedule report--report success message
lang["storyboard.reporterror"]="Start Date and End Date Should Not Be Same."// report error msg
lang["storyboard.reportfailed"]="Report Scheduled Failed."// schedule failed error
lang["storyboard.mailconfig"]="Please Configure Your Mail Settings"// configure mail settings
lang["storyboard.plzslctuser"]="Please Select User"// select user
lang["storyboard.schedule"]="Schedule"// schedule
lang["storyboard.login"]=" with Login"//  with login
lang["storyboard.outlogin"]=" without Login"// without  login
lang["storyboard.once"]="Once"// once
lang["storyboard.daily"]="Daily"// daily
lang["storyboard.weekly"]="Weekly"// weekly
lang["storyboard.monthly"]="Monthly"// monthly
lang["storyboard.dateerror"]="Date Field is Mandatory"// date field mandatory validation
lang["storyboard.slctdate"]="Please Select Date"// Please Select Date
lang["storyboard.slcttime"]="Please Select Time"// Please Select Time
lang["storyboard.every"]="Every"// every
lang["storyboard.days"]="Day(s)"// days
lang["storyboard.everyweekday"]="Every Weekday"//every weekday 
lang["storyboard.sunday"]="Sunday"// weekday  sunday
lang["storyboard.monday"]="Monday"// weekday Monday
lang["storyboard.tuesday"]="Tuesday"// weekday tuesday
lang["storyboard.wednesday"]="Wednesday"// weekday wednesday
lang["storyboard.thursday"]="Thursday"// weekday thursday
lang["storyboard.friday"]="Friday"// weekday friday
lang["storyboard.saturday"]="Saturday"// weekday saturday
lang["storyboard.day"]="Day"// day
lang["storyboard.everymonth"]="of every month"// every month
lang["storyboard.editchartseries"]="Edit Chart Series Colors" // edit chart series
lang["storyboard.chooseseries"]="Choose a Series" // choose series
lang["storyboard.remove"]="Remove" // remove
lang["storyboard.addbtn"]="Add" // addbtn
lang["storyboard.add"]="ADD" // ADD
lang["storyboard.movetile"]="Move Tile" // move tile tooltip
lang["storyboard.visualsetting"]="Visualization Settings" // visual settings tooltip
lang["storyboard.tilefilter"]="Tile Filter" 
lang["storyboard.maxmize"]="Maximize" //maximize tooltip
lang["storyboard.chartview"]="Chart View" // chart view
lang["storyboard.dataview"]="Data View" // Data View
lang["storyboard.summaryview"]="Summary View" // Summary View
lang["storyboard.clonetile"]="Clone Tile"//clone tile
lang["storyboard.alerts"]="Alerts"//alerts
lang["storyboard.createalert"]="Create Alert"// alerts-create alert
lang["storyboard.placeholdgroups"]="Select Groups/Users"// placeholdor select groups/users
lang["storyboard.alertemail"]="Send Alert Email" // alerts-send alert email
lang["storyboard.alertcondtn"]="Alert Condition"// alert condition
lang["storyboard.is"]="is"// is
lang["storyboard.equal"]="Equal"//alerts-equal
lang["storyboard.notequal"]="Not Equal"// /alerts-notequal
lang["storyboard.grtrthan"]="Greater Than"// /alerts-greater than
lang["storyboard.lserthan"]="Less Than"// /alerts-lessthan
lang["storyboard.grtrthanequal"]="Greater Than or Equal"//alerts-Greater Than or Equal
lang["storyboard.lserthanequal"]="Less Than or Equal"//alerts-less Than or Equal
lang["storyboard.between"]="Between"// between
lang["storyboard.and"]="and"// and
lang["storyboard.deltile"]="Delete Tile"//delete tile
lang["storyboard.toggle"]="Toggle Dropdown"// maximize -toggledropdown tooltip
lang["storyboard.chart"]="Chart"// chart
lang["storyboard.datatable"]="Data Table"// datatable
lang["storyboard.data"]="Data"//data 
lang["storyboard.summarytable"]="Summary Table"// summary table
lang["storyboard.pivotable"]="Pivot Table"//pivottable 
lang["storyboard.pivot"]="Pivot"// pivot
lang["storyboard.countname"]="count of NAME"// count name
lang["storyboard.dataset"]="Dataset Count: 1000"// dataset count
lang["storyboard.minimize"]="Minimize"// minimize tooltip
lang["storyboard.attributes"]="Attributes"// attributes tooltip
lang["storyboard.savemyreport"]="Save MyReport"// more-save my report
lang["storyboard.clonemyreport"]="Clone MyReport"//more-clone my report 
lang["storyboard.live"]="LIVE"// live tooltip
lang["storyboard.reporttheme"]="MyReport Theme"// myreport theme
lang["storyboard.sharemyreport"]="Share MyReport"//more--share myreport 
lang["storyboard.share"]="Share"// share
lang["storyboard.embedmyreport"]="Embed MyReport"// more--embedmyreport
lang["storyboard.schedulemyreport"]="Schedule MyReport"// more--schedulemyreport
lang["storyboard.selectrows"]="Select Rows"//data table --select rows
lang["storyboard.from"]="From"// from
lang["storyboard.csv"]="Download As CSV"// data table ---download as csv
lang["storyboard.xls"]="Download As XLS"// data table ---download as xls
lang["storyboard.pdf"]="Download As PDF"//data table ---download as pdf
lang["storyboard.choosecolumns"]="Choose Columns"// choose columns
lang["storyboard.sumarysettings"]="Summary Table Settings"// summary table settings tooltip
lang["storyboard.summary"]="summary"// summary
lang["storyboard.chartstyle"]="Chart Style"// chart style tooltip
lang["storyboard.trend"]="Trend"// trend tooltip
lang["storyboard.linear"]="Linear"// Trend-linear
lang["storyboard.lineartool"]="Linear Regression"//Trend-linear tooltip
lang["storyboard.smatool"]="Simple Moving Average"// Trend-sma tooltip
lang["storyboard.ematool"]="Exponential Moving Average"// Trend-ema tooltip
lang["storyboard.sma"]="SMA"// Trend-sma
lang["storyboard.ema"]="EMA"// Trend-ema
lang["storyboard.runsum"]="Running Sum"// Trend-running sum
lang["storyboard.runmin"]="Running Minimum"// Trend-running minimum
lang["storyboard.runmax"]="Running Maximum"// Trend-running maximum
lang["storyboard.runavg"]="Running Average"// Trend-running average
lang["storyboard.rundiff"]="Running Difference"// Trend-running difference
lang["storyboard.percent"]="Percentage"// Trend-percentage
lang["storyboard.stats"]="STATS"// Trend-stats
lang["storyboard.prev"]="Prev"// prev
lang["storyboard.previous"]="Previous"//previous tooltip 
lang["storyboard.next"]="Next"// next
lang["storyboard.pivotsettings"]="Pivot Settings"// pivot settings tooltip
lang["storyboard.chart"]="Chart"// chart tooltip
lang["storyboard.defaultbar"]="Default Bar"// chart-default bar
lang["storyboard.stackedbar"]="Stacked Bar"// chart-stacked bar
lang["storyboard.linechart"]="Line Chart"// chart-line chart
lang["storyboard.areachart"]="Area Chart"// chart-area chart
lang["storyboard.stackarea"]="Stacked Area Chart"// chart-stacked area chart
lang["storyboard.pivotchartsettings"]="Pivot Chart Settings"// pivot chart settings 
lang["storyboard.chartcustom"]="Chart Customization's"// chart customization
lang["storyboard.aggrcolums"]="Aggregated Columns"// sumary table--aggregated columns
lang["storyboard.textcolums"]="Text Columns"// sumary table--text columns
lang["storyboard.grpby"]="Group By"// sumary table--group by
lang["storyboard.oneaggcol"]="At least One aggregated column is required"// sumary table--aggregated column error validation
lang["storyboard.onetextcol"]="At least One text column is required"// sumary table--text column error validation
lang["storyboard.colms"]="Columns"// pivot table--columns
lang["storyboard.rows"]="Rows"// pivot  table--rows
lang["storyboard.values"]="Values"// pivot  table--values
lang["storyboard.addcolm"]="Add Column"// pivot  table--add column validation
lang["storyboard.addrows"]="Add Rows"// pivot  table--add rows validation
lang["storyboard.addvalue"]="Add Value"// pivot  table--add value validation
lang["storyboard.charttitle"]="Chart Title"//pivotchartsettings-chart title
lang["storyboard.legend"]="Legend"//pivotchartsettings-legend
lang["storyboard.x-axis"]="X-Axis Label"//pivotchartsettings-x-axis label
lang["storyboard.y-axis"]="Y-Axis Label"//pivotchartsettings-y-axis label
lang["storyboard.datalabel"]="Data Label"//pivotchartsettings-data label
lang["storyboard.crosshair"]="Cross Hair"//pivotchartsettings-cross hair
lang["storyboard.gridline"]="Grid Line"//pivotchartsettings-grid line
lang["storyboard.basicbar"]="Basic Bar"//visualsettings graphs tooltip
lang["storyboard.basicline"]="Basic Line"//visualsettings graphs tooltip
lang["storyboard.spline"]="Spline"//visualsettings graphs tooltip
lang["storyboard.basicpie"]="Basic Pie"//visualsettings graphs tooltip
lang["storyboard.donut"]="Donut"//visualsettings graphs tooltip
lang["storyboard.basicarea"]="Basic Area"//visualsettings graphs tooltip
lang["storyboard.stackedarea"]="Stacked Area"//visualsettings graphs tooltip
lang["storyboard.bubble"]="Bubble"//visualsettings graphs tooltip
lang["storyboard.scatter"]="Scatter"//visualsettings graphs tooltip
lang["storyboard.funnel"]="Funnel"//visualsettings graphs tooltip
lang["storyboard.pyramid"]="Pyramid"//visualsettings graphs tooltip
lang["storyboard.metricchart"]="Metric visualsettings graphs tooltipChart"//visualsettings graphs tooltip
lang["storyboard.semiguage"]="Semi-Circle Gauge"//visualsettings graphs tooltip
lang["storyboard.fullguage"]="Full Circle Gauge"//visualsettings graphs tooltip
lang["storyboard.combchart"]="Combination Chart"//visualsettings graphs tooltip
lang["storyboard.advancevisual"]="Advanced Visualizations"//visualsettings graphs tooltip
lang["storyboard.sankey"]="Sankey"//visualsettings graphs tooltip
lang["storyboard.wordcloud"]="Word Cloud"//visualsettings graphs tooltip
lang["storyboard.boxplot"]="Box Plot"//visualsettings graphs tooltip
lang["storyboard.mapcharts"]="Map Charts"//visualsettings graphs tooltip
lang["storyboard.world"]="World"//visualsettings graphs tooltip
lang["storyboard.countrymap"]="Country Map"//visualsettings graphs tooltip
lang["storyboard.usmap"]="US Map"//visualsettings graphs tooltip
lang["storyboard.srilanka"]="Sri Lanka Map"//visualsettings graphs tooltip
lang["storyboard.india"]="India Map"//visualsettings graphs tooltip
lang["storyboard.streaming"]="Streaming"//visualsettings graphs tooltip
lang["storyboard.barseries"]="Bar Time Series"//visualsettings graphs tooltip
lang["storyboard.lineseries"]="Line Time Series"//visualsettings graphs tooltip
lang["storyboard.timeseries"]="Time Series Area"//visualsettings graphs tooltip
lang["storyboard.othercharts"]="Other Charts"//visualsettings graphs tooltip
lang["storyboard.invertedbar"]="Inverted Bar"//visualsettings graphs tooltip
lang["storyboard.invertedstack"]="Inverted Stacked Bar"//visualsettings graphs tooltip
lang["storyboard.colorbar"]="Multi-Color Bar"//visualsettings graphs tooltip
lang["storyboard.xbar"]="Multi X Bar"//visualsettings graphs tooltip
lang["storyboard.axes"]="Multiple Axes"//visualsettings graphs tooltip
lang["storyboard.seriestime"]="Time Series"//visualsettings graphs tooltip
lang["storyboard.3dbar"]="3D Bar"//visualsettings graphs tooltip
lang["storyboard.trellis"]="Trellis"//visualsettings graphs tooltip
lang["storyboard.waterfall"]="Waterfall"//visualsettings graphs tooltip
lang["storyboard.xline"]="Multi X Line"//visualsettings graphs tooltip
lang["storyboard.lineinvented"]="Line Inverted Axis"//visualsettings graphs tooltip
lang["storyboard.semidonut"]="Semi Circle Donut"//visualsettings graphs tooltip
lang["storyboard.3dpie"]="3D Pie"//visualsettings graphs tooltip
lang["storyboard.xarea"]="Multi X Area"//visualsettings graphs tooltip
lang["storyboard.invertedaxes"]="Inverted Axes Area"//visualsettings graphs tooltip
lang["storyboard.radarchart"]="Radar Chart"//visualsettings graphs tooltip
lang["storyboard.polarchart"]="Polar Chart"//visualsettings graphs tooltip
lang["storyboard.windrose"]="Wind Rose Chart"//visualsettings graphs tooltip
lang["storyboard.sparkline"]="Sparkline Chart"//visualsettings graphs tooltip
lang["storyboard.heatmap"]="Heatmap"//visualsettings graphs tooltip
lang["storyboard.text"]="Text"//visualsettings graphs tooltip
lang["storyboard.axessettings"]="Axes Settings"//axes settings tooltip
lang["storyboard.measures"]="Measures and Dimensions"// measures and dimensions tooltip
lang["storyboard.sorting"]="Sorting and Ranking"// sorting and ranking tooltip
lang["storyboard.colorthemes"]="Color Themes"// color themes tooltip
lang["storyboard.chartsettings"]="Chart Settings"// chart settings tooltip
lang["storyboard.measures"]="Measures"//measures and dimensions-measures
lang["storyboard.custommeasure"]="Custom Measure"// measures and dimensions--custom measure
lang["storyboard.measurehierarchy"]="Measure Hierarchy"// measures and dimensions--measure hierarchy
lang["storyboard.create"]="Create"// measures and dimensions-create tooltip
lang["storyboard.dimensions"]="Dimensions"// measures and dimensions--dimensions
lang["storyboard.customfield"]="Custom Field"// measures and dimensions--custom field
lang["storyboard.drillhierarchy"]="Drill Down Hierarchy"// measures and dimensions--drilldown hierarchy
lang["storyboard.customrange"]="Custom Range"// measures and dimensions--customrange
lang["storyboard.parameters"]="Parameters"// measures and dimensions--parameters
lang["storyboard.createparameter"]="Create Parameter"// measures and dimensions---create parameter tooltip
lang["storyboard.sortby"]="Sort By"//sorting and ranking--sortby
lang["storyboard.note"]="Note: Sorting of Data is Disabled when Legend Series is Applied"//sorting and ranking--note
lang["storyboard.ranking"]="Ranking"//sorting and ranking--ranking
lang["storyboard.top"]="Top"//sorting and ranking--top
lang["storyboard.bottom"]="Bottom"//sorting and ranking--bottom
lang["storyboard.valueerror"]="Value must be less than or equal to 999999999"//sorting and ranking--value error
lang["storyboard.positiverror"]="Enter positive Numbers Only!"//sorting and ranking--positive numbers error
lang["storyboard.decimalerror"]="Decimal values are not allowed!"// //sorting and ranking--decimal values error
lang["storyboard.zoomstyle"]="Zoom Style"// zoom style
lang["storyboard.referenceline"]="Reference Line"// reference line
lang["storyboard.narrative"]="Narrative"//narrative
lang["storyboard.heatmapmonocolor"]="Heatmap Mono Color"//Heatmap Mono Color
lang["storyboard.dynamicscaling"]="Trellis Dynamic Scaling"//trellis dynamic scaling
lang["storyboard.fittowidth"]="Trellis Fit to Width"//trellis fit to width
lang["storyboard.pareto"]="Pareto"// pareto
lang["storyboard.connectnulls"]="Connect Nulls" //Connect lines when nulls
lang["storyboard.y-axis scales"]="Multiple Y-Axis Scales"// multiple y-axis scales
lang["storyboard.equaly-axis"]="Equal Y-Axis Scaling"// equal y-axis scaling
lang["storyboard.numberformat"]="Number Format"// number format
lang["storyboard.abbrevations"]="Abbrevations"// abbrevations
lang["storyboard.1T"]="1T"//  abbrevations-1T
lang["storyboard.1B"]="1B"// abbrevations-1B
lang["storyboard.1M"]="1M"// abbrevations-1M
lang["storyboard.1K"]="1K"// abbrevations-1K
lang["storyboard.zero"]=".0"
lang["storyboard.dzero"]=".00"
lang["storyboard.thosand"]="1000"
lang["storyboard.dthousand"]="1,000"
lang["storyboard.decimalplaces"]="Decimal Places"// decimal places
lang["storyboard.thousandssep"]="Thousands Separator"// thousands separator
lang["storyboard.y-axismin"]="Y-Axis Min Value"// y-axis min value
lang["storyboard.y-axismax"]="Y-Axis Max Value"// y-axis max value
lang["storyboard.metricfont"]="Metric Font"// metric font
lang["storyboard.choosefont"]="Choose Font Family"// choose font family
lang["storyboard.worksans"]="Work Sans"// source sans pro font family
lang["storyboard.lato"]="Lato" // lato font family
lang["storyboard.sanspro"]="Source Sans Pro"// source sans pro font family
lang["storyboard.verdana"]="Verdana"// verdana font family
lang["storyboard.arial"]="Arial"// arial font family
lang["storyboard.tahoma"]="Tahoma"// tahoma font family
lang["storyboard.timesroman"]="Times New Roman"// times new roman font family
lang["storyboard.cursive"]="Cursive"// cursive font family
lang["storyboard.monospace"]="Monospace"// monospace font family
lang["storyboard.fantasy"]="Fantasy"// fantasy font family
lang["storyboard.px"]="px"// pixels
lang["storyboard.em"]="em"// em
lang["storyboard.rem"]="rem"//rem
lang["storyboard.bold"]="Bold"// bold
lang["storyboard.italic"]="Italic"// italic
lang["storyboard.#2737a3"]="#2737a3"// placeholder color
lang["storyboard.metricprefix"]="Metric Prefix"// metric prefix
lang["storyboard.metricsuffix"]="Metric Suffix"// metric suffix
lang["storyboard.customfont"]="Custom Target Font"// custom target font
lang["storyboard.customprefix"]="Custom Target Prefix"// custom target prefix
lang["storyboard.customsuffix"]="Custom Target Suffix"// custom target suffix
lang["storyboard.#a6ace3"]="#a6ace3"// placeholder color
lang["storyboard.achievefont"]="Achievement Font" // achievement font
lang["storyboard.achievesymbol"]="Achievement Symbol"// achievement symbol
lang["storyboard.achievesuffix"]="Achievement Suffix"// achievement suffix
lang["storyboard.createmeasure"]="Create Custom Measure"// measure--create custom measure
lang["storyboard.fx"]="fx"// fx
lang["storyboard.validname"]="Please Enter Valid Name"// valid name error
lang["storyboard.entername"]="Please Enter Name" // please Enter name
lang["storyboard.exceptdot"]="Special characters are not allowed except one dot(.)"
lang["storyboard.spclcharacts"]="Special characters are not allowed"
lang["storyboard.nameesxists"]="Name Already Exists. Please Choose Another Name" // name exists error
lang["storyboard.availfields"]="Available Fields"// available fields
lang["storyboard.formula"]="Formula"// formula
lang["storyboard.validate"]="Validate"// validate
lang["storyboard.previewdata"]="Preview Data"// preview data
lang["storyboard.customname"]="Custom Name"//custom name
lang["storyboard.hierarchyname"]="Hierarchy Name"// measurehierarchy--hierarchy name
lang["storyboard.hierarchyorder"]=" Hierarchy Order"// hierarchy order
lang["storyboard.hierarchyerror"]="Hierarchy Can Not Be Empty"// hierarchy error validation
lang["storyboard.sum"]="Sum"// sum
lang["storyboard.minimum"]="Min"// min
lang["storyboard.max"]="Max"//max
lang["storyboard.avg"]="Average"// average
lang["storyboard.standard"]="Standard Deviation"// standard deviation
lang["storyboard.percentile"]="Percentile"// percentile
lang["storyboard.median"]="Median"// medain
lang["storyboard.countdistinct"]="Count (distinct)"// count distinct
lang["storyboard.countall"]="Count (all)"// count all
lang["storyboard.count"]="count"// count all title
lang["storyboard.countdis"]="countdistinct"// count distinct title
lang["storyboard.createcustom"]="Create Custom Field"// measure hierarchy-create custom field
lang["storyboard.operators"]="Operators"// operators
lang["storyboard.abc"]="ABC"// ABC
lang["storyboard.funtypes"]="Function Types" // function types
lang["storyboard.string"]="String"// string
lang["storyboard.number"]="Number"// number
lang["storyboard.logical"]="Logical"// logical
lang["storyboard.raw"]="RAW"// raw
lang["storyboard.concat"]="Concat" // function string div
lang["storyboard.empty"]="Empty"
lang["storyboard.notempty"]="NotEmpty"
lang["storyboard.length"]="Length"
lang["storyboard.revrse"]="Reverse"
lang["storyboard.substr"]="SubString"
lang["storyboard.appendtrail"]="appendTrailingCharIfAbsent"
lang["storyboard.position"]="Position"
lang["storyboard.match"]="Match"
lang["storyboard.lower"]="Lower"
lang["storyboard.upper"]="Upper"
lang["storyboard.like"]="Like"
lang["storyboard.notlike"]="NotLike"
lang["storyboard.tostring"]="ToString"
lang["storyboard.e"]="e" // function integer div
lang["storyboard.pi"]="Pi"
lang["storyboard.exp"]="Exp"
lang["storyboard.log"]="Log"
lang["storyboard.exp2"]="Exp2"
lang["storyboard.log2"]="Log2"
lang["storyboard.exp10"]="Exp10"
lang["storyboard.log10"]="Log10"
lang["storyboard.sqrt"]="Sqrt"
lang["storyboard.cbrt"]="Cbrt"
lang["storyboard.erf"]="Erf"
lang["storyboard.erfc"]="Erfc"
lang["storyboard.sin"]="Sin"
lang["storyboard.cos"]="Cos"
lang["storyboard.tan"]="Tan"
lang["storyboard.atan"]="atan"
lang["storyboard.acos"]="Acos"
lang["storyboard.asin"]="Asin"
lang["storyboard.power"]="Power"
lang["storyboard.abs"]="Absolute"
lang["storyboard.rnd"]="Round"
lang["storyboard.ceil"]="Ceil"
lang["storyboard.floor"]="Floor"
lang["storyboard.todate"]="ToDate" // function date div
lang["storyboard.todatetime"]="ToDateTime"
lang["storyboard.toyear"]="ToYear"
lang["storyboard.tomonth"]="ToMonth"
lang["storyboard.todaymon"]="ToDayOfMonth"
lang["storyboard.todayweek"]="ToDayOfWeek"
lang["storyboard.tohr"]="ToHour"
lang["storyboard.tomin"]="ToMinute"
lang["storyboard.tosec"]="ToSecond"
lang["storyboard.tostartday"]="ToStartOfDay"
lang["storyboard.tomonday"]="ToMonday"
lang["storyboard.tostartmon"]="ToStartOfMonth"
lang["storyboard.tostartquatr"]="ToStartOfQuarter"
lang["storyboard.tostartyear"]="ToStartOfYear"
lang["storyboard.tostartmin"]="ToStartOfMinute"
lang["storyboard.tostartfivemin"]="ToStartOfFiveMinute"
lang["storyboard.tostarthr"]="ToStartOfHour"
lang["storyboard.totime"]="ToTime"
lang["storyboard.relyearnum"]="ToRelativeYearNum"
lang["storyboard.relmonnum"]="ToRelativeMonthNum"
lang["storyboard.relweeknum"]="ToRelativeWeekNum"
lang["storyboard.reldaynum"]="ToRelativeDayNum"
lang["storyboard.relhrnum"]="ToRelativeHourNum"
lang["storyboard.relminnum"]="ToRelativeMinuteNum"
lang["storyboard.relsecnum"]="ToRelativeSecondNum"
lang["storyboard.today"]="Today"
lang["storyboard.now"]="Now"
lang["storyboard.yestrday"]="Yesterday"
lang["storyboard.case"]="CASE"    // function logical div
lang["storyboard.if"]="IF"
lang["storyboard.help"]="Help" // help
lang["storyboard.choosedatefmt"]="Choose Date Format"// choose date format
lang["storyboard.formulaeditor"]="Formula Editor" // formula editor
lang["storyboard.fundisable"]="Paste Functionality Disabled" //paste functionality disabled
lang["storyboard.placeholderstart"]="Start Typing Here For Type-ahead Feature" // placeholder text
lang["storyboard.dd/MM/yyyy"]="dd/MM/yyyy"// date format
lang["storyboard.MM/dd/yyyy"]="MM/dd/yyyy"// date format
lang["storyboard.yyyy/MM/dd"]="yyyy/MM/dd"// date format
lang["storyboard.yyyy/dd/MM"]="yyyy/dd/MM"// date format
lang["storyboard.drillhierarcy"]="Drill Down Hierarchy Can Not Be Empty" // drill hierarchy error
lang["storyboard.rangename"]="Range Name" // range name
lang["storyboard.customrangename"]="CustomRange Name" // placeholder custom range name
lang["storyboard.slctrange"]="Select Range Element" // select range element
lang["storyboard.fieldempty"]="Field Can Not Be Empty"// field not empty error validation
lang["storyboard.range"]="Range" // range
lang["storyboard.delrange"]="deleterange"// delete range
lang["storyboard.valuecompare"]="Max Value should be greater than Min Value"
lang["storyboard.customparameter"]="Custom Parameter" // parameters-custom parameter
lang["storyboard.entervalue"]="Please Enter Value" //parameters-enter value
lang["storyboard.allowvalues"]="Allowable Values"//parameters-allowable values
lang["storyboard.all"]="All"//parameters-all
lang["storyboard.list"]="List"//parameters-list
lang["storyboard.val"]="Value"//parameters-value
lang["storyboard.displayas"]="Display As"//parameters-display as
lang["storyboard.mini"]="Minimum"//parameters-minimum
lang["storyboard.maxi"]="Maximum"//parameters-maximum
lang["storyboard.stepsize"]="Step Size"//parameters-stepsize
lang["storyboard.advancdchart"]="Advanced Chart Settings" //chartsettings-advanced chart settings
lang["storyboard.textsettings"]="Text Settings"// advanced chart settings--text settings
lang["storyboard.globalfont"]="Global Font"
lang["storyboard.fontstyle"]="Font Style"
lang["storyboard.oncharttitle"]="On-Chart Title"
lang["storyboard.maintitle"]="Main Title"
lang["storyboard.title"]="Title"
lang["storyboard.widthadjust"]="Width Adjust"
lang["storyboard.entertitle"]="Enter Title Here"
lang["storyboard.font"]="Font"
lang["storyboard.#424242"]="#424242"
lang["storyboard.align"]="Align"
lang["storyboard.center"]="Center"
lang["storyboard.left"]="Left"
lang["storyboard.right"]="Right"
lang["storyboard.middle"]="Middle"
lang["storyboard.floating"]="Floating"
lang["storyboard.subtitle"]="Sub Title"
lang["storyboard.capxy"]="XY"
lang["storyboard.capx"]="X"
lang["storyboard.capy"]="Y"
lang["storyboard.chartintraction"]="Chart Interaction" // advanced chart settings--chart settings-- chart interaction
lang["storyboard.zooming"]="Zooming"
lang["storyboard.smxy"]="xy"
lang["storyboard.smx"]="x"
lang["storyboard.smy"]="y"
lang["storyboard.polarprotect"]="Polar Projection"
lang["storyboard.ignorehidden"]="Ignore Hidden Series"
lang["storyboard.chartarea"]="Chart Area"
lang["storyboard.bgcolor"]="Background Color"
lang["storyboard.transparent"]="transparent"
lang["storyboard.brdrcolor"]="Border Color"
lang["storyboard.brdrwidth"]="Border Width"
lang["storyboard.brdrradius"]="Border Radius"
lang["storyboard.spacing"]="Spacing"
lang["storyboard.plotarea"]="Plot Area"
lang["storyboard.axesetup"]="Axes Setup"
lang["storyboard.invertaxes"]="Inverted axes"
lang["storyboard.alignticks"]="Align Ticks"
lang["storyboard.mulaxes"]="(Works only with 'Multiple Axes' chart)"
lang["storyboard.stacking"]="Stacking"
lang["storyboard.normal"]="Normal"
lang["storyboard.per"]="Percent"
lang["storyboard.axisx"]="X-Axis"
lang["storyboard.visible"]="Visible"
lang["storyboard.reversed"]="Reversed"
lang["storyboard.opposite"]="Opposite"
lang["storyboard.axislabel"]="Axis Label"
lang["storyboard.color"]="Color"
lang["storyboard.axisline"]="Axis Line"
lang["storyboard.linewidth"]="Line Width"
lang["storyboard.majortick"]="Major Tick"
lang["storyboard.width"]="Width"
lang["storyboard.interval"]="Interval"
lang["storyboard.minortick"]="Minor Tick"
lang["storyboard.gridsettings"]="Grid Settings" // advanced chart settings---grid settings
lang["storyboard.dashstyle"]="Dash Style"
lang["storyboard.solid"]="Solid"
lang["storyboard.shortdash"]="ShortDash"
lang["storyboard.shortdot"]="ShortDot"
lang["storyboard.sdotdot"]="ShortDashDotDot"
lang["storyboard.dot"]="Dot"
lang["storyboard.dash"]="Dash"
lang["storyboard.longdash"]="LongDash"
lang["storyboard.dashdot"]="DashDot"
lang["storyboard.ldashdot"]="LongDashDot"
lang["storyboard.ldotdot"]="LongDashDotDot"
lang["storyboard.axisy"]="Y-Axis"
lang["storyboard.prefix"]="Prefix"
lang["storyboard.suffix"]="Suffix"
lang["storyboard.datalabelsettngs"]="Data Labels Settings" // advanced chart settings---datalabel settings
lang["storyboard.datalabels"]="Data Labels"
lang["storyboard.textoutline"]="Text Outline"
lang["storyboard.padding"]="Padding"
lang["storyboard.radius"]="Radius"
lang["storyboard.algnment"]="Alignment"
lang["storyboard.rotation"]="Rotation"
lang["storyboard.legendsettings"]="Legend Settings" //advanced chart settings-- legend settings
lang["storyboard.legendlayout"]="Legend Layout"
lang["storyboard.horizontal"]="Horizontal"
lang["storyboard.vertical"]="Vertical"
lang["storyboard.margin"]="Margin"
lang["storyboard.symbolstyle"]="Symbol Style"
lang["storyboard.height"]="Height"
lang["storyboard.tooltipsettings"]="Tooltip Settings" // advanced chart settings--tooltip settings
lang["storyboard.tooltip"]="Tooltip"
lang["storyboard.removesettings"]="Remove Advanced Settings" // remove advanced settings button
lang["storyboard.chartstatus"]="Chart Stats"// Trend-stats-chartstats
lang["storyboard.aggtype"]="Aggregation Type"// Trend-stats-Aggregation type
lang["storyboard.countcap"]="COUNT"// stats-count
lang["storyboard.count(d)"]="COUNT (D)"// stats-count(d)
lang["storyboard.sumcap"]="SUM"//stats---sum
lang["storyboard.maxcap"]="MAX"//stats--max
lang["storyboard.mincap"]="MIN"//stats--min
lang["storyboard.avgcap"]="AVG"//stats--avg
lang["storyboard.stddevcap"]="STD DEV"//stats--std-cev
lang["storyboard.percentilecap"]="PERCENTILE"//stats--percentile
lang["storyboard.mediancap"]="MEDIAN"//stats--median
lang["storyboard.axisz"]="Z-Axis"// visualization settings --z-axis
lang["storyboard.legendseries"]="Legend Series"// visualization settings --Legend series
lang["storyboard.chartlists"]="Combination ChartsList"// combination chartlist
lang["storyboard.customline"]="customLine"// custom line
lang["storyboard.layout"]="Layout"// layout
lang["storyboard.metriclayout1"]="Metric Layout 1" // metric layout1
lang["storyboard.metriclayout2"]="Metric Layout 2"// metric layout2
lang["storyboard.targetvalue"]="Compare with Custom Target Value"// compare custom target value
lang["storyboard.field"]="DATE FIELD"// date field
lang["storyboard.compare"]="Compare" // compare
lang["storyboard.quarterly"]="Quarterly"//quarterly
lang["storyboard.halfyearly"]="Half-Yearly"// half-yearly
lang["storyboard.yearly"]="Yearly"//yearly
lang["storyboard.customvalue"]="Custom Value"// custom value
lang["storyboard.themesettings"]="Theme Settings"// theme settings
lang["storyboard.createhierarchy"]="Create Measure Hierarchy"// create measure hierarchy tooltip
lang["storyboard.createmeasur"]="Create Measure"// create measure tooltip
lang["storyboard.reportdescription"]="Enter Your Report Description Here"// report placehold description
lang["storyboard.reportbg"]="MyReport Background" // myreport theme--myreport background
lang["storyboard.years"]="Years"// summary table --years
lang["storyboard.quarters"]="Quarters"// summary table --quarters
lang["storyboard.months"]="Months"// summary table --months
lang["storyboard.weeks"]="Weeks"// summary table --weeks
lang["storyboard.day's"]="Days"// summary table --days
lang["storyboard.hours"]="Hours"// summary table --hours
lang["storyboard.minutes"]="Minutes"// summary table --minutes
lang["storyboard.seconds"]="Seconds"// summary table --seconds
lang["storyboard.default"]="Default"// summary table --default 
lang["storyboard.choosedrilldown"]="Choose DrillDown Field" // summarytable--choose drilldown field
lang["storyboard.choosefields"]="Choose Fields" // Storyboard SmartInsights Choose Fields
lang["storyboard.availcolums"]="Available Columns"//summarytable---available columns  
lang["storyboard.enterval"]="Enter Value"// Enter value
lang["storyboard.remove"]="Remove" // remove tooltip
lang["storyboard.createnewdate"]="Create New Date Field"// attribute dimensions-- create new date field
lang["storyboard.toyr"]="toYear" // attribute dimensions--toyear
lang["storyboard.toquarter"]="toQuarter"// attribute dimensions--toQuarter
lang["storyboard.tomon"]="toMonth"// attribute dimensions--toMonth
lang["storyboard.convertnum"]="Convert to Number"// attribute dimensions--convert to number
lang["storyboard.convertstr"]="Convert to String"// attribute dimensions--convert to string
lang["storyboard.smleft"]="left"// combinationchart attributes-left
lang["storyboard.smright"]="right"// combinationchart attributes-right
lang["storyboard.bar"]="bar"// combinationchart attributes-bar
lang["storyboard.line"]="line"// combinationchart attributes-line
lang["storyboard.area"]="area"// combinationchart attributes-area
lang["storyboard.smspline"]="spline"// combinationchart attributes-spline
lang["storyboard.smcolumn"]="column"// combinationcharts attributes-column
lang["storyboard.Dataview"]="Dataview" //sumarytable popuptable-dataview
lang["storyboard.Filtervalues"]="Filter Values:"//sumarytable popuptable-filtered values
lang["storyboard.Filtercount"]="Filtered Count :"//sumarytable popuptable-filtered count
lang["storyboard.boardfilters"]="Storyboard Filters" // storyboard filters
lang["storyboard.tilefilters"]="Tile Filters" // tile filters
lang["storyboard.slctcharttile"]="Select A Chart Tile" // select a chart tile
lang["storyboard.Raw"]="Raw" //Raw attributes
lang["storyboard.versiondescribe"]="Enter Your Version Storyboard Description Here"// storyversion description
lang["storyboard.spclchar"]="special characters are not allowed" // special characters
lang["storyboard.label"]="Label" // label
lang["storyboard.style"]="Style"// style
lang["storyboard.ShortDashDot"]="ShortDashDot"
lang["storyboard.#FF0000"]="#FF0000"// placeholder color
lang["storyboard.editfunction"]="Edit Function" // edit function
lang["storyboard.rename"]="Rename" // attribbutes Rename
lang["storyboard.delfield"]="Delete Custom Field" // delete custom field
lang["storyboard.deldrill"]="Delete Drill Down Hierarchy" // delete drilldown hierarchy
lang["storyboard.delrange"]="Delete Custom Range" // delete custom range
lang["storyboard.hiddendrill"]="Set As Hidden Drill-Through Filter"
lang["storyboard.lat"]="latitude" // x-axis attributes --latitude
lang["storyboard.long"]="longitude"// x-axis attributes --longitude
lang["storyboard.editmeasure"]="Edit Measure" // edit measure
lang["storyboard.edithierarchy"]="Edit Hierarchy"// edit hierarchy
lang["storyboard.duplicate"]="Duplicate" // y-axios attributes duplicate
lang["storyboard.conditionalformat"]="Conditional Formatting"// y-axios attributes conditional formatting
lang["storyboard.sortingorder"]="Sorting Order" // sorting order
lang["storyboard.ascending"]="Ascending"// ascending
lang["storyboard.descending"]="Descending"// descending
lang["storyboard.Addcondition"]="ADD Condition" // Add condition
lang["storyboard.slctuser"]="Select Users"// placeholder select users
lang["storyboard.board"]="Storyboard"// Storyboard
lang["storyboard.smartinsights"]="Smart Insights" //Smart Insights
lang["storyboard.exclude"]="Exclude"// exclude
lang["storyboard.null"]="NULL"// Null
lang["storyboard.instantfilters"]="Instant Filters"// Instant filters
lang["storyboard.keeponly"]="Keep Only"// Keep only
lang["storyboard.findinsights"]="Finding Insights" // finding insights
lang["storyboard.dataaudit"]="Data Audit"
lang["storyboard.runinsights"]="Smart Insights running..." // alt -smart insights running
lang["storyboard.screenmsg"]="Please wait, the system is preparing a screenshot to attach with your e-mail"
lang["storyboard.showhidefilters"]="Show/Hide Filters Menu"// show hide filters menu
lang["storyboard.enteravalue"]="Please Enter A Value"
lang["storyboard.configpivot"]="Configure Pivot Table"
lang["storyboard.downloadcsv"]="Download CSV"
lang["storyboard.intelligentsearch"]="Smart Search" // Smart Search
lang["storyboard.datasetcount"]="Dataset Count:"
lang["storyboard.lastdatasync"]="Last Data Sync:"
lang["storyboard.visualization"]="Visualization"// visualization
lang["storyboard.newboard"]="New Storyboard" // New storyboard
lang["storyboard.filtersapplied"]="Filter(s) Applied"
lang["storyboard.nodatesavailble"]="No dates available"
lang["storyboard.turnonfilter"]="Turn ON Filter"
lang["storyboard.turnofffilter"]="Turn OFF Filter"
lang["storyboard.addrillthrough"]="Add to Drill-Through"
lang["storyboard.remdrillthrough"]="Remove from Drill-Through"
lang["storyboard.placeholdcomma"]="CommaSeparated Values"
lang["storyboard.namexceeds"]="FOLDER NAME EXCEEDS MAXIMUM LENGTH"// folder name exceeds max length
lang["storyboard.validname"]="Please Enter Valid Name"// valid name error
lang["storyboard.reportnameexceeds"]="Report Name Exceeds Maixmum Length 70" // Report name exceeds maximum
lang["storyboard.noreservewords"]="Storyboard Name should not contain 'Reserved Words'"//storyboard name not contain reserve words
lang["storyboard.restrictedviewaccess"]="RESTRICT VIEW ACCESS FOR GROUPS/USER"
lang["storyboard.allowheatmap"]="Only One Value Field is allowed for Heatmap"
lang["storyboard.2X-axisallowcharts"]="Maximum Two X-Axis Numeric Fields are allowed for Map charts"
lang["storyboard.3X-axisallowcharts"]="Only Three X-Axis Fields are allowed for this chart type"
lang["storyboard.mulaxischarts"]="Multiple X-Axis is not allowed for this chart"
lang["storyboard.Z-axisallowchart"]="Only One Z-Axis is allowed for Bubble Chart"
lang["storyboard.LEGEND"]="LEGEND"
lang["storyboard.maxvalgreater"]="MAX value must be greater than"
lang["storyboard.minvallower"]="MIN value must be lower than"
lang["storyboard.metricvalue"]="Metric Value"
lang["storyboard.slcty-axisattr"]="Plese select valid Attributes in Y-Axis"
lang["storyboard.xyfieldsnotsame"]="X-Axis field and Y-Axis filed should not be same for Sankey"
lang["storyboard.xlegendfieldsnotsame"]="X-Axis field and Legend Series filed should not be same for Sankey"
lang["storyboard.addonerange"]="Please add atleast one range"
lang["storyboard.rcmdadvanceusers"]="Recommended Only For Advanced Users"
lang["storyboard.customfields"]="Custom Fields";// custom fields
lang["storyboard.oops"]="Oops, nothing found!" // Oops, nothing found!
lang["storyboard.serverconnect"]="Not able to connect to Server, please check the connection details"// server connection warning
lang["storyboard.datahandlerconnect"]="Please Check Data Handler Connection"//check datahandler connection
lang["storyboard.searchcriteria"]="Searched criteria is not found,Please enter valid search Criteria" // valid search criteria warning
lang["storyboard.SMAP"]="Simple Moving Average Periods" // SMAP title
lang["storyboard.EMAP"]="Exponential Moving Average Periods"// EMAP title
lang["storyboard.seriescolors"]="Series Colors"
lang["storyboard.view"]="View"// view
lang["storyboard.down"]="Down"// Down
lang["storyboard.drill"]="Drill"// Drill
lang["storyboard.through"]="Through"// Through
lang["storyboard.serverresponse"]="The server response is taking time. Do you want to" // server response
lang["storyboard.wait"]="Wait"
lang["storyboard.close"]="CLOSE"
lang["storyboard.descriptionhere"]="Enter Your Description Here" // Description placeholder
lang["storyboard.configuresummary"]="Configure Summary Table"//Configure Summary Table
lang["storyboard.submitbtn"] = " Submit"; //submit button
lang["storyboard.s.no"] = "S.No."; 
lang["storyboard.createdate"]="Created Date";//Created Date
lang["storyboard.reporttype"] = "Report Type";//Report Type
lang["storyboard.viewreport"]="View Report" // view report
lang["storyboard.deletereport"]="Delete Report" // view report
lang["storyboard.restorereport"]="Restore Report"
lang["storyboard.reportcolms"]="Report Columns"
lang["storyboard.pxcontrast"]="1px contrast"
lang["storyboard.y-drill"]="Y-Drill";
lang["storyboard.keep"]="Keep"
lang["storyboard.only"]="Only"
lang["storyboard.addtext"]="Add Text"
lang["storyboard.smartinsights"] = "Smart Insights"






// reports"

lang["reports.filters"]="Filters" // report filters
lang["reports.addfilter"]="Add Filter" //  filters- Add filter
lang["reports.addparameter"]="Add Parameter" // filters -Add parameter
lang["reports.choosefilter"]="Choose Filter"//filters-choose filter
lang["reports.showhidefilters"]="Show/Hide Filters Menu"// show hide filters menu
lang["reports.savemsg"]="Report Saved Successfully" // report save msg
lang["reports.saverror"]="Error Occured While Saving Shared Report" // report saved error
lang["reports.namexists"]="Report Name Already Exists. Please Choose Another Name" // Report name exists
lang["reports.more"]="More" // more tooltip
lang["reports.toggle"]="Toggle Dropdown" // Toggle dropdown
lang["reports.chart"]="Chart"// chart
lang["reports.datatable"]="Data Table"// datatable
lang["reports.data"]="Data"//data 
lang["reports.summarytable"]="Summary Table"// summary table
lang["reports.pivotable"]="Pivot Table"//pivottable 
lang["reports.pivot"]="Pivot"// pivot
lang["reports.refresh"]="Refresh" // Refresh tooltip
lang["reports.quickinfo"]="Quick Information" //Quick information tooltip
lang["reports.intelligentsearch"]="Smart Search" // Smart Search
lang["reports.attributes"]="Attributes"// Attributes tooltip
lang["reports.general"]="General"// more-general
lang["reports.savereport"]="Save MyReport"// more-save MyReport
lang["reports.save"]="Save"// more-save 
lang["reports.saveversion"]="Save Version"// more-save version
lang["reports.versionmyreport"]="Version MyReport" // more--version Myreport
lang["reports.livereport"]="LIVE Report"// more-live Report
lang["reports.audit"]="Audit"// more-audit
lang["reports.drilldownsettings"]="Drilldown Settings"// more- drilldown settings
lang["reports.customscript"]="Custom Script"// more-custom script
lang["reports.customize"]="Customize"// more-customize
lang["reports.reporttheme"]="MyReport Theme"// more-MyReport theme
lang["reports.edittitle"]="Edit Title"// more-edit title
lang["reports.favorite"]="Favorite"// more-favourite
lang["reports.publish"]="Publish Report"// more-favourite
lang["reports.collaborate"]="Collaborate"// more-collaborate
lang["reports.sharereport"]="Share MyReport"// more-share MyReport
lang["reports.embedreport"]="Embed MyReport"// more-embed MyReport
lang["reports.schedulereport"]="Schedule MyReport"// more-schedule MyReport
lang["reports.alerts"]="Alerts"// alerts
lang["reports.download"]="Download"// more-download
lang["reports.export"]="Export"// more-export
lang["reports.selectrows"]="Select Rows"//data table --select rows
lang["reports.from"]="From"// from
lang["reports.to"]="To"// To
lang["reports.csv"]="Download As CSV"// data table ---download as csv
lang["reports.xls"]="Download As XLS"// data table ---download as xls
lang["reports.pdf"]="Download As PDF"//data table ---download as pdf
lang["reports.sumarysettings"]="Summary Table Settings"// summary table settings tooltip
lang["reports.summary"]="summary"// summary
lang["reports.chartview"]="Chart View" // chart view
lang["reports.dataview"]="Data View" // Data View
lang["reports.summaryview"]="Summary View" // Summary View
lang["reports.chartstyle"]="Chart Style"// chart style tooltip
lang["reports.trend"]="Trend"// trend tooltip
lang["reports.linear"]="Linear"// Trend-linear
lang["reports.lineartool"]="Linear Regression"//Trend-linear tooltip
lang["reports.smatool"]="Simple Moving Average"// Trend-sma tooltip
lang["reports.ematool"]="Exponential Moving Average"// Trend-ema tooltip
lang["reports.sma"]="SMA"// Trend-sma
lang["reports.ema"]="EMA"// Trend-ema
lang["reports.runsum"]="Running Sum"// Trend-running sum
lang["reports.runmin"]="Running Minimum"// Trend-running minimum
lang["reports.runmax"]="Running Maximum"// Trend-running maximum
lang["reports.runavg"]="Running Average"// Trend-running average
lang["reports.rundiff"]="Running Difference"// Trend-running difference
lang["reports.percent"]="Percentage"// Trend-percentage
lang["reports.stats"]="STATS"// Trend-stats
lang["reports.logout"]="Logout" //logout
lang["reports.prev"]="Prev"// prev
lang["reports.previous"]="Previous"//previous tooltip 
lang["reports.next"]="Next"// next
lang["reports.pivotsettings"]="Pivot Settings"// pivot settings tooltip
lang["reports.chart"]="Chart"// chart tooltip
lang["reports.defaultbar"]="Default Bar"// chart-default bar
lang["reports.stackedbar"]="Stacked Bar"// chart-stacked bar
lang["reports.linechart"]="Line Chart"// chart-line chart
lang["reports.areachart"]="Area Chart"// chart-area chart
lang["reports.stackarea"]="Stacked Area Chart"// chart-stacked area chart
lang["reports.pivotchartsettings"]="Pivot Chart Settings"// pivot chart settings 
lang["reports.chartcustom"]="Chart Customization's"// chart customization
lang["reports.edit"]="Edit"// edit
lang["reports.metadataaudit"] = "Meta-Data Audit"// audit--meta data audit
lang["reports.dataudit"]="Data Audit"// Data Audit
lang["reports.live"]="LIVE"// live
lang["reports.reportstext"]="Please wait, the system is preparing a screenshot to attach with your e-mail"
lang["reports.choosecolumns"]="Choose columns"//add filter-choose filter
lang["reports.search"]="Search"// search
lang["reports.cancelbtn"]="Cancel"// cancel
lang["reports.applybtn"]="Apply"// apply
lang["reports.chooseparameter"]="Choose Parameter"// add parameter-choose parameter
lang["reports.noparameters"]="No Parameters Available" // add parameter- no parameters available
lang["reports.errormsg"]="Error Occured While Cloning the Report" // Report clone errormsg
lang["reports.clonemsg"]="Report Cloned Successfully"// Report success msg
lang["reports.saveversion"]="Save VersionReport"// more-save a version
lang["reports.versiondetails"]="Version Report Details"//save versionreport- version report details
lang["reports.name"]="Name" //  save versionreport -name
lang["reports.versionname"]="Version Name" //save versionreport - placeholder name
lang["reports.folder"]="Folder"// save versionreport - folder
lang["reports.slctfolder"]="Select/Enter folder name"//save versionreport - placeholder folder name
lang["reports.description"]="Description"// save versionreport -description
lang["reports.placeholdescription"]="Enter Your Version Report Description Here"//save versionreport-placeholder description
lang["reports.versionlist"]=" Versions List"// version list
lang["reports.exclude"]="Exclude"// exclude
lang["reports.null"]="NULL"// Null
lang["reports.values"]="Values"// Values
lang["reports.add"]="ADD"// add tooltip
lang["reports.addnewfilter"]="Add New Filter"// Add new filter
lang["reports.applyfilters"]="Apply Filters"// apply filters
lang["reports.instantfilters"]="Instant Filters"// Instant filters
lang["reports.keeponly"]="Keep Only"// Keep only
lang["reports.parameterfilters"]="Parameter Filters"// parameter filters
lang["reports.visualization"]="Visualization"// visualization
lang["reports.newreport"]="New Report"// New report
lang["reports.clear"]="Clear"// clear tooltip
lang["reports.addchart"]="Add Chart"// Add chart
lang["reports.availfields"]="Available Fields"// Available fields
lang["reports.showing"]="Showing"// showing
lang["reports.recordspage"]="records per page"// Record per page
lang["reports.cnfnavigation"]="Confirm Navigation"// confirm navigation
lang["reports.unsacedchanges"]="Any Unsaved changes will be lost!!"// confirm navigation
lang["reports.stillproceed"]="Do you still want to proceed?"// confirm navigation
lang["reports.cnfyes"]="YES"// confirm navigation--yes
lang["reports.cnfno"]="NO"// confirm navigation--no
lang["reports.narrative.of"]="of"// narrative of
lang["reports.narrative.thetotal"]="The total"// narrative-The total
lang["reports.narrative.distributedacross"]="distributed across all"//narrative--distributed across all
lang["reports.narrative.theavgof"]="The Average of"// narrative-The average of
lang["reports.narrative.minvalof"]="The minimum value of"// narrative-The minimum value of
lang["reports.narrative.for"]="for"// narrative-for
lang["reports.narrative.isdistributedby"]="is distributed by"// narrative-is distributed by
lang["reports.narrative.maxvalof"]="% of the data, and the maximum value of"// narrative-% of the data, and the maximum value of
lang["reports.narrative.%ofdata"]="% of the data"// narrative-% of the data
lang["reports.narrative.For"]="The minimum value for"// narrative-For
lang["reports.narrative.minvalis"]="is"// narrative-the minimum value is
lang["reports.narrative.drivenby"]="driven by"// narrative-driven by
lang["reports.narrative.andmaxvalis"]="and the maximum value is"// narrative-and the maximum value is
lang["reports.narrative.thisanalysisshows"]="This analysis shows "// narrative-This analysis shows
lang["reports.narrative.by"]="by"// narrative-by
lang["reports.namexceeds"]="FOLDER NAME EXCEEDS MAXIMUM LENGTH"// folder name exceeds max length
lang["reports.validname"]="Please Enter Valid Name"// valid name error
lang["reports.reportnameexceeds"]="Report Name Exceeds Maixmum Length 70" // Report name exceeds maximum
lang["reports.noreservewords"]="Report Name should not contain 'Reserved Words'"//storyboard name not contain reserve words
lang["reports.maxageerror"] = "Please enter valid Max data age" ;// max data age validation error
lang["reports.purgeindices"] = "Please Enter Valid Number of Purge Indices"
lang["reports.maxunits"] = "Please enter Max Units"
lang["reports.minminutes"] = "Minimum Value 5 Minutes"// minimum value 1 minute
lang["reports.maxminutes"] = "Maximum Value 59 Minutes"// maximum value 59 minute
lang["reports.decimalval"] = "Decimal values are not allowed"//Decimal values not allowed
lang["reports.minhr"] = "Minimum Value 1 Hour"// Minimum Value 1 Hour
lang["reports.maxhr"] = "Maximum Value 23 Hours"// Maximum Value 23 Hours
lang["reports.minday"] = "Minimum Value 1 Day"//Minimum Value 1 Day
lang["reports.maxday"] = "Maximum Value 31 Days"//  Maximum Value 31 Days
lang["reports.minsec"] = "Minimum Value 5 Seconds"// Minimum Value 5 Seconds
lang["reports.maxsec"] = "Maximum Value 59 Seconds"// Maximum Value 59 Seconds
lang["reports.serverresponse"]="The server response is taking time. Do you want to" // server response
lang["reports.wait"]="Wait"
lang["reports.close"]="CLOSE"
lang["reports.remove"]="Remove"
lang["reports.SMAP"]="Simple Moving Average Periods" //SMAP title
lang["reports.EMAP"]="Exponential Moving Average Periods"//EMAP title
lang["reports.characterscount"]="Characters Count Limit"//Characters Count Limit
lang["reports.view"]="View"// view
lang["reports.data"]="Data"// Data
lang["reports.down"]="Down"// Down
lang["reports.drill"]="Drill"// Drill
lang["reports.through"]="Through"// Through

// custom storyboard
lang["customboard.movetile"]="Move Tile" // move tile tooltip
lang["customboard.tilefilter"]="Tile Filter" // tile filter tooltip
lang["customboard.maxmize"]="Maximize" //maximize tooltip
lang["customboard.more"]="More" // more tooltip
lang["customboard.chartview"]="Chart View" // chart view
lang["customboard.dataview"]="Data View" // Data View
lang["customboard.summaryview"]="Summary View" // Summary View
lang["customboard.deltile"]="Delete Tile"//Delete tile
lang["customboard.toggle"]="Toggle Dropdown"// maximize -toggledropdown tooltip
lang["customboard.chart"]="Chart"// chart
lang["customboard.datatable"]="Data Table"// datatable
lang["customboard.data"]="Data"//data 
lang["customboard.summarytable"]="Summary Table"// summary table
lang["customboard.pivotable"]="Pivot Table"//pivottable 
lang["customboard.pivot"]="Pivot"// pivot
lang["customboard.countname"]="count of NAME"// count name
lang["customboard.dataset"]="Dataset Count: 1000"// dataset count
lang["customboard.minimize"]="Minimize"// minimize tooltip
lang["customboard.attributes"]="Attributes"// attributes tooltip
lang["customboard.refresh"]="Refresh"// Refresh tooltip
lang["customboard.general"]="General"// more-general
lang["customboard.savemyreport"]="Save MyReport"// more-save MyReport
lang["customboard.clonemyreport"]="Clone MyReport"// more-Clone MyReport
lang["customboard.livereport"]="LIVE Report"// more-live Report
lang["customboard.live"]="LIVE"// live tooltip
lang["customboard.drilldownsettings"]="Drilldown Settings"// more- drilldown settings
lang["customboard.customize"]="Customize"// more-customize
lang["customboard.reporttheme"]="MyReport Theme"// more-MyReport theme
lang["customboard.edittitle"]="Edit Title"// more-edit title
lang["customboard.favorite"]="Favorite"// more-favourite
lang["customboard.collaborate"]="Collaborate"// more-collaborate
lang["customboard.sharereport"]="Share MyReport"// more-share MyReport
lang["customboard.share"]="Share";// share tooltip
lang["customboard.embedmyreport"]="Embed MyReport"// more-embed MyReport
lang["customboard.embedreport"]="Embed Report" // embed report tooltip
lang["customboard.schedulemyreport"]="Schedule MyReport"// more-schedule MyReport
lang["customboard.alerts"]="Alerts"// alerts
lang["customboard.download"]="Download"// more-download
lang["customboard.export"]="Export"// more-export
lang["customboard.search"]="Search"
lang["customboard.selectrows"]="Select Rows"//data table --select rows
lang["customboard.from"]="From"// from
lang["customboard.to"]="To"// To
lang["customboard.csv"]="Download As CSV"// data table ---download as csv
lang["customboard.xls"]="Download As XLS"// data table ---download as xls
lang["customboard.pdf"]="Download As PDF"//data table ---download as pdf
lang["customboard.choosecolms"]="Choose Columns" // Choose Columns
lang["customboard.sumarysettings"]="Summary Table Settings"// summary table settings tooltip
lang["customboard.summary"]="summary"// summary
lang["customboard.chartview"]="Chart View" // chart view
lang["customboard.dataview"]="Data View" // Data View
lang["customboard.summaryview"]="Summary View" // Summary View
lang["customboard.chartstyle"]="Chart Style"// chart style tooltip
lang["customboard.trend"]="Trend"// trend tooltip
lang["customboard.linear"]="Linear"// Trend-linear
lang["customboard.lineartool"]="Linear Regression"//Trend-linear tooltip
lang["customboard.smatool"]="Simple Moving Average"// Trend-sma tooltip
lang["customboard.ematool"]="Exponential Moving Average"// Trend-ema tooltip
lang["customboard.sma"]="SMA"// Trend-sma
lang["customboard.ema"]="EMA"// Trend-ema
lang["customboard.runsum"]="Running Sum"// Trend-running sum
lang["customboard.runmin"]="Running Minimum"// Trend-running minimum
lang["customboard.runmax"]="Running Maximum"// Trend-running maximum
lang["customboard.runavg"]="Running Average"// Trend-running average
lang["customboard.rundiff"]="Running Difference"// Trend-running difference
lang["customboard.percent"]="Percentage"// Trend-percentage
lang["customboard.stats"]="STATS"// Trend-stats
lang["customboard.smstats"]="stats"// Trend-stats tooltip
lang["customboard.prev"]="Prev"// prev
lang["customboard.previous"]="Previous"//previous tooltip 
lang["customboard.next"]="Next"// next
lang["customboard.pivotsettings"]="Pivot Settings"// pivot settings tooltip
lang["customboard.chart"]="Chart"// chart tooltip
lang["customboard.defaultbar"]="Default Bar"// chart-default bar
lang["customboard.stackedbar"]="Stacked Bar"// chart-stacked bar
lang["customboard.linechart"]="Line Chart"// chart-line chart
lang["customboard.areachart"]="Area Chart"// chart-area chart
lang["customboard.stackarea"]="Stacked Area Chart"// chart-stacked area chart
lang["customboard.pivotchartsettings"]="Pivot Chart Settings"// pivot chart settings 
lang["customboard.chartcustom"]="Chart Customization's"// chart customization
lang["customboard.edit"]="Edit"// edit
lang["customboard.cancelbtn"]="Cancel"// cancel
lang["customboard.applybtn"]="Apply"// apply
lang["customboard.remove"]="Remove" // remove
lang["customboard.metadataaudit"] = "Meta-Data Audit"// audit--meta data audit
lang["customboard.dataudit"]="Data Audit"// Data Audit
lang["customboard.errormsg"]="Error Occured While Cloning the Report" // Report clone errormsg
lang["customboard.clonemsg"]="Report Cloned Successfully"// Report success msg
lang["customboard.quickinfo"]="Quick Information" //Quick information tooltip
lang["customboard.filters"]="Filters" // report filters
lang["customboard.addfilter"]="Add Filter" //  filters- Add filter
lang["customboard.addparameter"]="Add Parameter" // filters -Add parameter
lang["customboard.intelligentsearch"]="Smart Search"// Smart Search
lang["customboard.savecustomboard"]="Save Custom Storyboard" // more-save custom storyboard
lang["customboard.cloneboard"]="Clone Storyboard" // more-clone storyboard
lang["customboard.liveboard"]="LIVE Storyboard" // more-live storyboard
lang["customboard.audit"]="Audit"// more-audit
lang["customboard.drilldownall"]="Drilldown All"//more-drilldown all
lang["customboard.customscript"]="Custom Script"// more-custom script
lang["customboard.newcharttile"]="New Chart Tile"// more-new chart tile
lang["customboard.sharecustomboard"]="Share Custom Storyboard" // more-Share Custom Storyboard
lang["customboard.shareboard"]="Share Storyboard"// more-share storyboard tooltip
lang["customboard.embedboard"]="Embed Storyboard"// more-embed storyboard tooltip
lang["customboard.embedcustomboard"]="Embed Custom Storyboard"/// more-Embed Custom Storyboard
lang["customboard.boardtheme"]="Storyboard Theme"// more-storyboard theme
lang["customboard.logout"]="Logout"// Logout
lang["customboard.addreport"]="Add New Report" // Add New Report
lang["customboard.boardtitle"]="Storyboard Title"// storyboard title
lang["customboard.customboard"]="Custom Storyboard"// Custom Storyboard
lang["customboard.board"]="Storyboard"// Storyboard
lang["customboard.smartinsights"]="Smart Insights" //Smart Insights
lang["customboard.exclude"]="Exclude"// exclude
lang["customboard.null"]="NULL"// Null
lang["customboard.values"]="Values"// Values
lang["customboard.add"]="ADD"// add tooltip
lang["customboard.addnewfilter"]="Add New Filter"// Add new filter
lang["customboard.applyfilters"]="Apply Filters"// apply filters
lang["customboard.instantfilters"]="Instant Filters"// Instant filters
lang["customboard.keeponly"]="Keep Only"// Keep only
lang["customboard.findinsights"]="Finding Insights" // finding insights
lang["customboard.runinsights"]="Smart Insights running..." // alt -smart insights running
lang["customboard.screenmsg"]="Please wait, the system is preparing a screenshot to attach with your e-mail"
lang["customboard.saveboard"]="Save Storyboard"// more-save storyboard
lang["customboard.reportdetails"]="Report Details"//  save storyboard -report details title
lang["customboard.name"]="Name" //  save storyboard -name
lang["customboard.placeholdname"]="Storyboard Name" //save storyboard - placeholder name
lang["customboard.folder"]="Folder"//  save storyboard - folder
lang["customboard.slctfolder"]="Select/Enter folder name"//save storyboard - placeholder folder name
lang["customboard.description"]="Description"// save storyboard -description
lang["customboard.placeholdescription"]="Enter Your Storyboard Description Here"// save storyboard -placeholder description
lang["customboard.addreport"]="Add Report" // Add report
lang["customboard.slctreport"]="Select a Report" //Add report-Select a Report
lang["customboard.plzslctreport"]="Please Select Report" //Add report-Please Select Report
lang["customboard.reports"]="Reports" // Reports
lang["customboard.oops"]="Oops, nothing found!" //Oops, nothing found! popup alert
lang["customboard.visualization"]="Visualization"// visualization
lang["customboard.newboard"]="New Storyboard" // New storyboard
lang["customboard.namexceeds"]="FOLDER NAME EXCEEDS MAXIMUM LENGTH"// folder name exceeds max length
lang["customboard.validname"]="Please Enter Valid Name"// valid name error
lang["customboard.reportnameexceeds"]="Report Name Exceeds Maixmum Length 70" // Report name exceeds maximum
lang["customboard.noreservewords"]="Storyboard Name should not contain 'Reserved Words'"//storyboard name not contain reserve words
lang["customboard.SMAP"]="Simple Moving Average Periods"// sma tooltip
lang["customboard.EMAP"]="Exponential Moving Average Periods"//Ema tooltip
// storybook
lang["storybook.savebook"]="Save Storybook" // save storybook
lang["storybook.bookdetails"]="Storybook Details"//  save storybook -Storybook Details title
lang["storybook.name"]="Name" //  save storybook -name
lang["storybook.placeholdname"]="Storybook Name" //save storybook - placeholder name
lang["storybook.validname"]="Please Enter A Valid Name" // name validation error
lang["storybook.description"]="Description"// save storybook -description
lang["storybook.placeholdescription"]="Enter Your Storybook Description Here"// save storybook -placeholder description
lang["storybook.cancelbtn"]="Cancel"// cancel
lang["storybook.applybtn"]="Apply"// apply
lang["storybook.tabsettings"]="Tab Settings"// tab settings 
lang["storybook.modifytab"]="Modify Tab"  // modify tab
lang["storybook.modifyreport"]="Modify Report" // modify Report 
lang["storybook.deltab"]="Delete Tab" // Delete tab
lang["storybook.createstorybook"]="Create New Storybook Tab"  // create new storybook tab
lang["storybook.refresh"]="Refresh" // Refresh
lang["storybook.more"]="More" // More
lang["storybook.save"]="Save" //save tooltip
lang["storybook.share"]="Share" // share tooltip
lang["storybook.sharebook"]="Share Storybook" // Share storybook
lang["storybook.general"]="General" // General
lang["storybook.collaborate"]="Collaborate" // collaborate
lang["storybook.clonesuccess"]="Report Cloned Successfully" // report cloned success msg
lang["storybook.cloneerror"]="Error Occured While Cloning the Report"// report cloned error msg
lang["storybook.tabtitle"]="Tab Title" // tab title
lang["storybook.selectareport"]="Select a Report" // select a report
lang["storybook.selectreports"]="Select Reports"// select reports
lang["storybook.storyboards"]="Storyboards"// storyboards
lang["storybook.reports"]="Reports"// reports
lang["storybook.plzslctreport"]="Please Select Report/Storyboard"// please select report/storyboard
lang["storybook.visualization"]="Visualization"// visualization
lang["storybook.title"]="Storybooks"// storybooks
lang["storybook.oops"]="Oops, nothing found!" // Oops, nothing found!


// Smart Insights
lang["smartsights.title"]="Smart Insights"// smart insights title
lang["smartsights.edittitle"]="Edit Title"// more-edit title
lang["smartsights.boardtitle"]="Storyboard Title"// storyboard title
lang["smartsights.cancelbtn"]="Cancel"// cancel
lang["smartsights.applybtn"]="Apply"// apply
lang["smartsights.filters"]="Filters" // filters
lang["smartsights.addfilter"]="Add Filter" //  filters- Add filter
lang["smartsights.addparameter"]="Add Parameter" // filters -Add parameter
lang["smartsights.refresh"]="Refresh" // Refresh
lang["smartsights.more"]="More"// more tooltip
lang["smartsights.general"]="General"// more-general
lang["smartsights.saveboard"]="Save Storyboard"// more-save storyboard
lang["smartsights.cloneboard"]="Clone Storyboard" // more-clone storyboard
lang["smartsights.customize"]="Customize"// more-customize
lang["smartsights.favorite"]="Favorite"// more-favourite
lang["smartsights.collaborate"]="Collaborate" // collaborate
lang["smartsights.shareboard"]="Share Storyboard"// more-share storyboard
lang["smartsights.embedboard"]="Embed Storyboard"// more-embed storyboard
lang["smartsights.save"]="Save" // save tooltip
lang["smartsights.boardtheme"]="Storyboard Theme"// more-storyboard theme
lang["smartsights.settings"]="Settings"//settings tooltip
lang["smartsights.newcharttile"]="New Chart Tile"// more-new chart tile
lang["smartsights.logout"]="Logout"// logout
lang["smartsights.quickinfo"]="Quick Information"// quick information tooltip
lang["smartsights.errormsg"]="Error Occured While Cloning the Report" // Report clone errormsg
lang["smartsights.clonemsg"]="Report Cloned Successfully"// Report success msg
lang["smartsights.simulation"]="Simulation Selection" // simulation selection title
lang["smartsights.search"]="Search" //search
lang["smartsights.Min"]="Min:"// min
lang["smartsights.Max"]="Max:"// max
lang["smartsights.updatebtn"]="Update"// update
lang["smartsights.sightsettings"]="Smart Insight Settings"// smart insight settings
lang["smartsights.datasource"]="Data Source"// data source
lang["smartsights.totaldataset"]="Total Data Set"// total data set
lang["smartsights.sampleset"]="Ideal Sample Set"// ideal sample set
lang["smartsights.setcolumns"]="Data Set Columns"// data set columns
lang["smartsights.discoverelem"]="Discover Elements"// discover elements
lang["smartsights.Cancel"]="Cancel"// CANCEL
lang["smartsights.run"]="Run"// RUN
lang["smartsights.runinsights"]="Smart Insights running..." // alt -smart insights running
lang["smartsights.overview"]="Overview" // overview title
lang["smartsights.quality"]="Insight Quality"// overview-Insight quality
lang["smartsights.findings"]="Insight Findings"// overview- Insight findings
lang["smartsights.corelatecolumns"]="Correlation Matrix"// overview- correlated columns
lang["smartsights.whatinfluences"]="What Influences"// what influences title
lang["smartsights.keyinfluences"]="Key Influencers"//what influences-Key Influencers
lang["smartsights.insightson"]="Insights on"//what influences-Insights on
lang["smartsights.predictvalues"]="Outcome Table with Actual Vs Predicted Values"// outcome table title
lang["smartsights.simulationwith"]="Simulation with"// simulation with title
lang["smartsights.expected"]="Expected"// Expected
lang["smartsights.visualization"]="Visualization"// visualization
lang["smartsights.newboard"]="New Storyboard" // New storyboard
lang["smartsights.target"]="Target"// Target
lang["smartsights.add"]="ADD"//ADD data tooltip
lang["smartsights.exclude"]="Exclude" // exclude
lang["smartsights.reportdetails"]="Report Details"//  save storyboard -report details title
lang["smartsights.name"]="Name" //  save storyboard -name
lang["smartsights.placeholdname"]="Storyboard Name" //save storyboard - placeholder name
lang["smartsights.folder"]="Folder"//  save storyboard - folder
lang["smartsights.slctfolder"]="Select/Enter folder name"//save storyboard - placeholder folder name
lang["smartsights.colmslctd"]="This column is alredy selected as Target"
//tile tooltips info
lang["smartsights.keyinfluencer-tooltip"]="Top 6 variables highly influencing the predictions of the target variable"
lang["smartsights.correlated-tooltip"]="Depicts the strength of linear relationship between two numerical variables"
lang["smartsights.chisquare-tooltip"]="Depicts the strength of correlation between two categorical variables"
lang["smartsights.insightnlg-tooltip"]="Statistical distributions of the Key Influencers"
lang["smartsights.insightquality-tooltip"]="Explained variance of target variable"
lang["smartsights.simulation-tooltip"]="Change any of the variable values to observe the change in predicted value"


//Ml Reports
lang["mlreports.analyticsboard"]="Augmented Analytics Storyboard" //Augmented analytics Storyboard title
lang["mlreports.datasnap"]="Data Snapshot" // Data snapshort title
lang["mlreports.insightsrun"]="Smart Insights running..." // smart Insights running alt
lang["mlreports.loadingdata"]="Loading Data Snapshot" // Loading Data snapshot
lang["mlreports.featureeng"]="Feature Engineering" // Feature engineering title
lang["mlreports.createcustom"]="Create Custom Field"//Feature engineering-create custom field
lang["mlreports.Runmodel"]="RUN Model"// RUN model title
lang["mlreports.AutoMLRun"]="RUN"//AutoML Run title
lang["mlreports.outcome"]="Outcome"// outcome title
lang["mlreports.reloadreports"]="Reload Reports" // Reload reports
lang["mlreports.runmodel"]="Run Model" // Run model btn
lang["mlreports.modelrun"]="Your Model is Running in the Background" //model running in  the background
lang["mlreports.metadataaudit"] = "Meta-Data Audit"// audit--meta data audit
lang["mlreports.dataudit"]="Data Audit"// Data Audit
lang["mlreports.live"]="LIVE"// live
lang["mlreports.text"]="Please wait, the system is preparing a screenshot to attach with your e-mail"
lang["mlreports.filters"]="Filters" //filters
lang["mlreports.exclude"]="Exclude"// exclude
lang["mlreports.null"]="NULL"// Null
lang["mlreports.values"]="Values"// Values
lang["mlreports.add"]="ADD"// add tooltip
lang["mlreports.addnewfilter"]="Add New Filter"// Add new filter
lang["mlreports.addparameter"]="Add Parameter" // filters -Add parameter
lang["mlreports.applyfilters"]="Apply Filters"// apply filters
lang["mlreports.instantfilters"]="Instant Filters"// Instant filters
lang["mlreports.parameterfilters"]="Parameter Filters"// parameter filters
lang["mlreports.keeponly"]="Keep Only"// Keep only
lang["mlreports.refresh"] = "Refresh"; //refresh
lang["mlreports.more"]="More"// more tooltip
lang["mlreports.general"]="General"// more-general
lang["mlreports.saveboard"]="Save Storyboard"// more-save storyboard
lang["mlreports.cloneboard"]="Clone Storyboard" // more-clone storyboard
lang["mlreports.customize"]="Customize"// more-customize
lang["mlreports.favorite"]="Favorite"// more-favourite
lang["mlreports.collaborate"]="Collaborate" // collaborate
lang["mlreports.shareboard"]="Share Storyboard"// more-share storyboard
lang["mlreports.embedboard"]="Embed Storyboard"// more-embed storyboard
lang["mlreports.save"]="Save" // save tooltip
lang["mlreports.boardtheme"]="Storyboard Theme"// more-storyboard theme
lang["mlreports.settings"]="Settings"//settings tooltip
lang["mlreports.edittitle"]="Edit Title"// more-edit title
lang["mlreports.newcharttile"]="New Chart Tile"// more-new chart tile
lang["mlreports.logout"]="Logout"// logout
lang["mlreports.quickinfo"]="Quick Information"// quick information tooltip
lang["mlreports.errormsg"]="Error Occured While Cloning the Report" // Report clone errormsg
lang["mlreports.clonemsg"]="Report Cloned Successfully"// Report success msg
lang["mlreports.edit"]="Edit" // Edit
lang["mlreports.movetile"]="Move Tile" // move tile tooltip
lang["mlreports.tooltiploading"]="Tooltip is Loading..." // Tooltip is loading
lang["mlreports.modelparameters"]="Model Parameters"//Run model--model parameters
lang["mlreports.choosemodel"]="Choose a Model"//Run model--choose a model
lang["mlreports.select"]="Select"//Run model--select dropdown
lang["mlreports.X-axis"]="X-Axis" //X-axis
lang["mlreports.Y-axis"]="Y-Axis"// Y-axis
lang["mlreports.textcolms"]="TEXT Columns" //Text columns
lang["mlreports.datecolums"]="Date Column"// Date columns
lang["mlreports.aggregator"]="Aggregator"// Aggregator
lang["mlreports.target_num"]="Target_num_exemplars"//Target_num_exemplars
lang["mlreports.rel_tol"]="Rel_tol_num_exemplars"//Rel_tol_num_exemplars
lang["mlreports.placeholdtarget"]="Enter Target num exemplars"// placeholder Enter Target num exemplars
lang["mlreports.placeholdrel_tol"]="Enter Num (0.0 TO 1.0)" // placeholder Enter Num (0.0 TO 1.0) 
lang["mlreports.categorical"]="Categorical Encoding"//clustering--Categorical Encoding
lang["mlreports.auto"]="Auto" // clustering--Auto
lang["mlreports.enum"]="Enum" //clustering--Enum
lang["mlreports.binary"]="Binary"//clustering--Binary
lang["mlreports.internal"]="OneHotInternal"// clustering--OneHotInternal
lang["mlreports.explicit"]="OneHotExplicit"//clustering--OneHotExplicit
lang["mlreports.eigen"]="Eigen"//clustering--Eigen
lang["mlreports.labelencode"]="LabelEncoder"//clustering--LabelEncoder
lang["mlreports.sortresponse"]="SortByResponse"//SortByResponse
lang["mlreports.enumltd"]="EnumLimited"//EnumLimited
lang["mlreports.randomforest"]="Random Forest"// select-Random Forest
lang["mlreports.neutaln/w"]="Neural Network"//select-Neural Network
lang["mlreports.features"]="Features"// Data snapshot-Feaures title
lang["mlreports.showing"]="Showing"// Data snapshot-showing
lang["mlreports.recordspage"]="records per page"// Data snapshot-records per page
lang["mlreports.corelatedcolms"]="Correlation Matrix"// Data snapshot- Correlated columns
lang["mlreports.chisquaretable"]="Chi Square Matrix"// Data snapshot- Chi square table
lang["mlreports.attributes"]="Attributes"// Data snapshot-Atrributes
lang["mlreports.datatype"]="Data Type"// Data snapshot-Data Type
lang["mlreports.missingrows"]="Missing Rows"// Data snapshot-Missing Rows
lang["mlreports.stats"]="Stats"// Data snapshot-Stats
lang["mlreports.distribution"]="Distribution"// Data snapshot-Distribution
lang["mlreports.mean"]="Mean" // Mean
lang["mlreports.median"]="Median"//Median
lang["mlreports.quantile"]="Quantile"//Quantile
lang["mlreports.nooflevels"]="No.of Levels"// Data snapshot-No of levels
lang["mlreports.top5levels"]="Top 5 Levels"// Data snapshot-Top 5 levels
lang["mlreports.levels80%data"]="No.of Levels with 80% Data"// Data snapshot-No.of Levels with 80% Data
lang["mlreports.reportdetails"]="Report Details"//  save storyboard -report details title
lang["mlreports.name"]="Name" //  save storyboard -name
lang["mlreports.placeholdname"]="Storyboard Name" //save storyboard - placeholder name
lang["mlreports.folder"]="Folder"//  save storyboard - folder
lang["mlreports.slctfolder"]="Select/Enter folder name"//save storyboard - placeholder folder name
lang["mlreports.cancelbtn"]="Cancel"// cancel
lang["mlreports.applybtn"]="Apply"// apply
lang["mlreports.visualization"]="Visualization"// visualization
lang["mlreports.newboard"]="New Storyboard" // New storyboard
lang["mlreports.mlreport"]="ML Report"// ml report title
lang["mlreports.search"]="Search" //search
lang["mlreports.TEXTCOLMS"]="TEXT COLUMNS" // TEXT COLUMNS
lang["mlreports.DATECOLMS"]="DATE COLUMNS" // DATE COLUMNS
lang["mlreports.listofcolms"]="List of Columns"//Feature engineering-List of Columns
lang["mlreports.slctlistcolms"]="Selected List of Columns"//Feature engineering-Selected List of Columns
lang["mlreports.frequencyof"]="Frequency of"//Frequency of
lang["mlreports.is"]="is"//is
lang["mlreports.notdisplay"]="No Words found to display"
lang["mlreports.frequencydistribution"]="Frequency Distribution of " // outcome-clusters-Frequency Distribution of
lang["mlreports.acrossclusters"]="across The Clusters"// outcome-clusters-across The Clusters
lang["mlreports.correlationfor"]="Correlation for"// outcome-clusters-Correlation for
lang["mlreports.and"]="and"//and
lang["mlreports.covaraiance"]="Correlation for"//Correlation for
lang["mlreports.printchart"]="printChart" // mlcharts--printchart
lang["mlreports.chartprinted"]="Chart printed"//mlcharts--Chart printed
lang["mlreports.pngdownload"]="downloadPNG"//mlcharts--downloadPNG
lang["mlreports.pngformat"]="Chart downloaded in PNG format"//mlcharts--Chart downloaded in PNG format
lang["mlreports.jpegdownload"]="downloadJPEG"//mlcharts--downloadJPEG
lang["mlreports.jpegformat"]="Chart downloaded in JPEG format"//mlcharts--Chart downloaded in JPEG format
lang["mlreports.pdfdownload"]="downloadPDF"//mlcharts--downloadPDF
lang["mlreports.pdfformat"]="Chart downloaded in PDF format"//mlcharts--Chart downloaded in PDF format
lang["mlreports.svgdownload"]="downloadSVG"//mlcharts--downloadSVG
lang["mlreports.svgformat"]="Chart downloaded in SVG format"//mlcharts--Chart downloaded in SVG format
lang["mlreports.jpegimg"]="image/jpeg"//mlcharts--image/jpeg
lang["mlreports.pdfapp"]="application/pdf"//mlcharts--application/pdf
lang["mlreports.svgimg"]="image/svg+xml"//mlcharts--image/svg+xml
lang["mlreports.view"]="View"// mlcharts -view
lang["mlreports.data"]="Data"// mlcharts -Data
lang["mlreports.y-drill"]="Y-Drill"// mlcharts -Y-Drill
lang["mlreports.down"]="Down"// mlcharts -Down
lang["mlreports.drill"]="Drill"// mlcharts -Drill
lang["mlreports.through"]="Through"// mlcharts -Through
lang["mlreports.keep"]="Keep"// mlcharts -keep
lang["mlreports.only"]="Only"// mlcharts -only
lang["mlreports.addtext"]="Add Text"
lang["mlreports.nlpprinted"]="NLP chart printed"//NLP chart printed
lang["mlreports.nlppngformat"]="NLP chart downloaded in PNG format"//NLP chart downloaded in PNG format
lang["mlreports.nlpjpegformat"]="NLP chart downloaded in JPEG format"//NLP chart downloaded in JPEG format
lang["mlreports.nlppdfformat"]="NLP chart downloaded in PDF format"//NLP chart downloaded in PDF format
lang["mlreports.nlpsvgformat"]="NLP chart downloaded in SVG format"//NLP chart downloaded in SVG format
lang["mlreports.weeks"]="Weeks"// weeks
lang["mlreports.days"]="Days"// days
lang["mlreports.hours"]="Hours"// hours
lang["mlreports.minutes"]="Minutes"// minutes
lang["mlreports.seconds"]="Seconds"// seconds
lang["mlreports.years"]="Years"// years
lang["mlreports.quarters"]="Quarters"// quarters
lang["mlreports.months"]="Months"// months
lang["mlreports.reset"]="Reset"// reset
lang["mlreports.modelfailed"]="Your model has failed to Run because of the following reason :"//model failed to run 
lang["mlreports.compareperform"]="Compare the performance of"// linear regression--Compare the performance of
lang["mlreports.acrosseach"]="across each"// linear regression--across each
lang["mlreports.title"]="title"// title
lang["mlreports.overtime"]="over Time"// linear regression--over Time
lang["mlreports.otherkeyinfluencr"]="and other key influencer "//  linear regression-and other key influencer
lang["mlreports.relationb/w"]="Understand the relationship between"//  linear regression-Understand the relationship between
lang["mlreports.predictvalues"]="Outcome Table with Actual Vs Predicted Values"// linear regression-Outcome Table with Actual Vs Predicted Values
lang["mlreports.understandeffect"]="Understand the affect on"//  linear regression-Understand the affect on
lang["mlreports.topkeyinfluencers"]="and one of the top most four Key Influencers"//  linear regression-and one of the top most four key Influencers
lang["mlreports.linearmodel"]="Linear Regression Model Dashboard"//  linear regression-Linear Regression Model Dashboard
lang["mlreports.mlchart"]="mlchart"// ml chart
lang["mlreports.highest"]="has the highest"// 
lang["mlreports.lowest"]="has the lowest"// 
lang["mlreports.the"]="The"// 
lang["mlreports.highestdiffb/w"]="has the highest difference between"// 
lang["mlreports.by"]="by"// 
lang["mlreports.knowdiff"]="Know the Difference between Top Category and the Rear Category to enable Improvement"//  linear regression
lang["mlreports.comparisonto"]="In comparison to"// 
lang["mlreports.theavg"]="the average"// 
lang["mlreports.in"]="in"// 
lang["mlreports.In"]="In"// 
lang["mlreports.andtheaverage"]="and the average"// 
lang["mlreports.nokeyinfluencer"]="No Numerical Key Influencer Found."// 
lang["mlreports.occurlot"]="occurred a lot in"// 
lang["mlreports.of"]="of"// 
lang["mlreports.while"]="while"// 
lang["mlreports.nocatagorical"]="No Catagorical Key Influencer Found."// 
lang["mlreports.outlierinfo"]="Outlier Information"// 
lang["mlreports.negativeoutliers"]="has negative outliers for"// 
lang["mlreports.nonegativeoutliers"]="has no negative outliers for"// 
lang["mlreports.positiveoutliers"]="has positive outliers for"// 
lang["mlreports.nopositiveoutliers"]="has no positive outliers for"// 
lang["mlreports.has a"]="has a"// 
lang["mlreports.correlationas"]="correlation. This means that as"
lang["mlreports.will"]="will"// 
lang["mlreports.wouldchange"]="would not change much."// 
lang["mlreports.coreltn"]="correlation."// 
lang["mlreports.thismeans"]="This means that as"// 
lang["mlreports.quality"]="Insight Quality"//linear regression-Insight quality
lang["mlreports.actualbypredictd"]="Actual Value by Predicted Value"// linear regression-Actual Value by Predicted Value
lang["mlreports.predictiveaccuracy"]="If your predictive model has high accuracy,all the points representing actual and predicted values would be close to this diagonal line"
lang["mlreports.rerunmodel"]="The Insight quality of below 8 shows that the model built need to be improved. It can be help to go back to feature engineering step and re-run the model."//linear regression-Insight quality-text 
lang["mlreports.modelbuilt"]="The Insight quality of above 8 shows that the model built is good to understand and the influencing factors on \"KPI\" and to predict the \"KPI\"."// 
lang["mlreports.summary"]="Summary"// linear regression--summary
lang["mlreports.keyinfluencechart"]="The Key Influencers chart tells that"// linear regression--The Key Influencers chart tells that--text
lang["mlreports.kpiinfluence"]="factors are the important ones influencing your KPI among the"//linear regression--factors are the important ones influencing your KPI among the --text
lang["mlreports.factorsdataset"]="number of factors in your dataset"// linear regression--number of factors in your dataset---text
lang["mlreports.kpiperform"]="Click on any Key Influencers to understand your KPI performance against the selected factor."//linear regression -text 
lang["mlreports.can'tcalculate"]="Cannot calculate variable importance if the variables are less than 2"// 
lang["mlreports.Keyinfluencers"]="Key Influencers"// 
lang["mlreports.pc1"]="PC1"//clusters
lang["mlreports.pc2"]="PC2"//clusters 
lang["mlreports.datadistributed"]="Data is distributed across"//clusters
lang["mlreports.clusters"]="clusters"// clusters title
lang["mlreports.pc1vspc2"]="PC1 VS PC2"//clusters-- PC1 VS PC2
lang["mlreports.clustr"]="Cluster" // cluster
lang["mlreports.predominantly"]="is predominantly represented by"//clusters 
lang["mlreports.summaryofinsight"]="Summary of the Insight"//clusters
lang["mlreports.clustering"]="clustering";//clusters
lang["mlreports.column"]="column";//clusters
lang["mlreports.mean&median"]="Average and Maximum ";//clusters
lang["mlreports.eachcluster"]=" across clusters";//clusters
lang["mlreports.keydifferentiators"]="Key Differentiators";//clusters
lang["mlreports.clustersdifferent"]="Heterogeneity of key Differentiators across Clusters";//clusters
lang["mlreports.clusteringmodel"]="Clustering Model Dashboard";//clusters
lang["mlreports.outcomesummary"]="Outcome Summary of the Clustering Model";//clusters
lang["mlreports.clusteringalgorithm"]="The clustering algorithm grouped data with similar characterstics into";//clusters
lang["mlreports.uniqueclusters"]="unique clusters";//clusters
lang["mlreports.eachclustersdifferent"]="Each of these clusters are most differentiated from each other because of the given";//clusters
lang["mlreports.factors"]="factors";//clusters
lang["mlreports.accuracy"]="Accuracy";// Decision tree--Accuracy
lang["mlreports.bar"]="bar";
lang["mlreports.variableimportance"]="Variable Importance";//Decision tree---Variable importance
lang["mlreports.confusionmatrix"]="Confusion Matrix";//Decision tree--Confusion Matrix
lang["mlreports.treemodeldashboard"]="Decision Tree Model Dashboard";//Decision tree--Decision Tree Model Dashboard
lang["mlreports.textanlysisdashboard"]="Text Analysis Model Dashboard"; // Text analysis--Text Analysis Model Dashboard
lang["mlreports.wordcloud"]="wordcloud";// Text analysis--wordcloud
lang["mlreports.wordcloudfor"]="Word Cloud for";//Text analysis--wordcloud for
lang["mlreports.positivewords"]="Positive Words In";//Text analysis--Positive Words In
lang["mlreports.negativewords"]="Negative Words In";//Text analysis--Negative Words In
lang["mlreports.date"]="date";// date
lang["mlreports.linetimeseries"]="linetimeseries";
lang["mlreports.understand"]="Understand";// forecasting--Understand
lang["mlreports.trend"]="Trend";// forecasting--Trend
lang["mlreports.mape"]="Total Actual";// forecasting--MAPE
lang["mlreports.mse"]="Total Predicted";// forecasting--MSE
lang["mlreports.me"]="Total Forecasted";// forecasting--ME
lang["mlreports.mae"]="MAPE";// forecasting--MAE
lang["mlreports.randomness"]="Randomness";// forecasting--Randomness
lang["mlreports.seasonality"]="Seasonality";// forecasting--Seasonality
lang["mlreports.forecastvalues"]="Forecast values";// forecasting--
lang["mlreports.forecaston"]="Forecast on your";// forecasting--
lang["mlreports.fornext"]="for next";// forecasting--for next
lang["mlreports.unitsand"]="units and";// units and
lang["mlreports.units"]="units";// units
lang["mlreports.modelforecastedthe"]="Model has Forecasted the";// forecasting--Model has Forecasted the
lang["mlreports.trendanalysison"]="Trend Analysis on";//forecasting--Trend Analysis on
lang["mlreports.forecastingmodel"]="Forecasting Model Dashboard";//forecasting--Forecasting Model Dashboard
lang["mlreports.logisticregression"]="Logistic Regression Model Dashboard"// logistic regression--Logistic Regression Model Dashboard
lang["mlreports.descisiontree"]="Decision Tree";// Decision tree
lang["mlreports.linearregression"]="Linear Regression"//Linear Regression
lang["mlreports.textanalysis"]="Sentiment Analysis"//Sentiment Analysis
lang["mlreports.LogisticRegression"]="Logistic Regression"//Logistic Regression
lang["mlreports.Forecasting"]="Forecasting"//Forecasting
lang["mlreports.onemodel"]="Please Select One Model"//Please Select One Model
lang["mlreports.withanavg"]="with an average"
lang["metric.scripttagerror"]="Invalid Data" // when user enters <script> tag 