import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
  Redirect
} from "react-router-dom";
import { createBrowserHistory } from 'history';
import Properties from './Properties/ServerProperties'
import Login from "./Login";
import Header from "./Home/Header";
import Visualization from "./Visualization/Visualization.jsx";
import Datamain from "./Home/Datamainhtml";
import Notifications from "./Notifications/Notifications.jsx";
import Storyboardview from "./ViewStoryboard/Storyboard.jsx";
import PublicStoryboard from './ViewStoryboard/public';
import ByPassLogindview from './ViewStoryboard/bypasslogin';
import Datahub from './Hub/Datahub';
import Admin from "./admin/admin";
import LicenseView from "./admin/licenseview";
import RolesView from "./admin/roles/rolesview";
import Workspace from './Workspace/Workspace';
import Datamodels from './Datamodels/Datamodels';
import StroybookMain from './ViewStorybook/storybookmain';
import CustomStoryboards from './CustomStoryboard/customstoryboards.jsx';
import Searchview from './DatamodelSearch/searchview';
import GovernanceView from './Governance/governanceview';
import MLReportView from "./ViewStoryboard/viewMLStoryboard/viewmlmodel";
import MLSavedReportView from "./ViewStoryboard/viewMLStoryboard/viewsavedmlmodel";
import * as attrchange from "attrchange";
import SamlRequest from './SamlRequest'
import DatahubSyncLogBook from "./Hub/DatahubSyncLogBook";
import WorkspaceSyncLogBook from "./Workspace/WorkspaceSyncLogBook";
import AutovizReportView from "./ViewStoryboard/Autoviz/autovizreportview";
const $ = window.$;
$(document).ready(function () {
  $('.spinner').attrchange({
    trackValues: true,
    callback: function (event) {
      $('.spinnermessage').empty();
      $('.spinnermessage').removeClass('showopacity')
      $('.spinnermessage').removeClass('oops');
      $('.spinnercontent img').removeClass('hide');

      setTimeout(function () {
        var state = $('.spinner').css('display');
        if (state === 'block') {
          var currenttext = $('.spinnermessage').html();
          if (currenttext != 'The server response is taking time. Do you want to <div class="btn btn-transparent mainspinnercontinue"><i class="fa fa-check-circle"></i>&nbsp;WAIT</div>&nbsp;(or)&nbsp;<div class="btn btn-transparent mainspinnerclose"><i class="fa fa-times-circle"></i>&nbsp;CLOSE</div>') {
            //$('.spinnermessage').text('We are processing your request...');	
          }
          $('.spinnermessage').addClass('showopacity');
          setTimeout(function () {
            $('.spinnercontent img').addClass('hide');
            $('.spinnermessage').html('<span><i class="fa fa-spinner fa-pulse"></i> We are processing your request...</span> Do you want to <div class="btn btn-transparent mainspinnercontinue"><i class="fa fa-check-circle"></i>&nbsp;WAIT</div>&nbsp;(or)&nbsp;<div class="btn btn-transparent mainspinnerclose"><i class="fa fa-times-circle"></i>&nbsp;CLOSE</div>');
            setTimeout(function () {
              $('.spinnermessage>span').html('The server response is taking time.');
            }, 40000);
            $('.spinnermessage').addClass('oops');
            $('.mainspinnerclose').on("click", function () {
              $('.spinnermessage').empty();
              $('.spinnermessage').removeClass('showopacity')
              $('.spinnermessage').removeClass('oops');
              $('.spinnercontent img').removeClass('hide');
              $('.spinner').removeClass('forceshow');
              $('.spinner').hide();
            });
            $('.mainspinnercontinue').on("click", function () {
              $('.spinnermessage').empty();
              $('.spinnermessage').removeClass('showopacity')
              $('.spinnermessage').removeClass('oops');
              $('.spinnercontent img').removeClass('hide');
              $('.spinner').hide();
              $('.spinner').show();
            });
          }, 5000);
        } else {
          $('.spinnermessage').empty();
          $('.spinnermessage').removeClass('showopacity');
          $('.spinnermessage').removeClass('oops');
          $('.spinnercontent img').removeClass('hide');
        }
      }, 8000);
    }
  });
  // $('.fullwidth').on("click", function () {
  //   if ($('.birdmessage-center').hasClass("full")) {
  //     $('.birdmessage-center').removeClass("full");
  //     $('.fullwidth>i').addClass("fa-expand").removeClass("fa-compress");
  //   } else {
  //     $('.birdmessage-center').addClass("full");
  //     $('.fullwidth>i').addClass("fa-compress").removeClass("fa-expand");
  //   }
  //   if ($('.birdmessage .details').hasClass('show')) {
  //     $('.birdmessagefull').addClass("height-250");
  //   }
  // });
});
export default () => {
  return (
    <Router>
      <Route exact path="/" render={() => (<Redirect to={`/${Properties.appName}`}/>)}/>
      <Route exact path={`/${Properties.appName}`} component={Login} />
      <Route exact path={`/${Properties.appName}/login`} component={Login} />

      <Switch>
        <Route path={`/${Properties.appName}/welcome`} >
          <App />
        </Route>
      </Switch>
      <Switch>
        <Route path={`/${Properties.appName}/welcome/admin`}>
          <AdminView />
        </Route>
      </Switch>
    </Router>
  );
};
function AdminView() {
  let { path, url } = useRouteMatch();
  let history = createBrowserHistory();
  return (
    <React.Fragment>
      <div className="birdmessage-center">
        <div className="birdmessage-backdrop" onClick={close}></div>
        <div className="birdmessage-container">
          <div className="fullwidth" onClick={expand}><i className="fa fa-expand"></i></div>
          <div className="close" onClick={close}>
            <i className="fa fa-times"></i>
          </div>
          <div className="messageicon birdmessage-error">
            <i className="fa fa-times-circle-o"></i>
          </div>
          <div className="birdmessage">
            <h2>
              Error : <span className="errorcode"></span>
            </h2>
            <p className="birdmessage-info"></p>
            <span className="details hide" onClick={detailsOpen}>Details</span>
            <span className="birdmessagefull">
              <span className="messagedetails">
                <xmp></xmp>
              </span>
            </span>
            <div className="container-fluid pull-right nopadding birdmessagebuttons hide">
              <button
                type="button"
                className="btn btn-bird btn-sm deleteyes"
                value="yes"
              >
                <i className="fa fa-check"></i> YES
              </button>
              <button
                type="button"
                className="btn btn-bird btn-sm deleteno"
                value="no"
              >
                <i className="fa fa-times"></i> NO
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="spinner" style={{ display: "none" }}>
        <div className="spinnercontent spinnermessage"></div>
        <div className="spinnercontent">
          {/* <!-- <img alt="Loading..." src="img/mainspinner.GIF"> --> */}
          <div className="ball-pulse">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
      <div className="boc-main">
        <div className="renamedfields" style={{ display: "none" }}></div>
        {sessionStorage.setItem("usession", "true")}
        <Header url={url} pageType="adminpage" />
        <Switch>
          <Route exact
            path={[path, `${path}/groups`, `${path}/roles`, `${path}/reportmanagement`, `${path}/auditing`, `${path}/settings`, `${path}/syncsettings`,
              `${path}/licensemanagement`, `${path}/users`, `${path}/users`, `${path}/accounts`, `/${Properties.appName}/welcome/admin/licensemanagement`]}
            render={(props) => (<LicenseView {...props} url={url} />)} />
          {/* <Route exact path={`${path}/users`} component={UsersView} />
          <Route exact path={`${path}/roles`} component={RolesView} /> */}
        </Switch>
        <div id="urlnavigationmenu"></div>
      </div>
    </React.Fragment>
  );

}
function expand() {
  if ($('.birdmessage-center').hasClass("full")) {
    $('.birdmessage-center').removeClass("full");
    $('.fullwidth>i').addClass("fa-expand").removeClass("fa-compress");
  } else {
    $('.birdmessage-center').addClass("full");
    $('.fullwidth>i').addClass("fa-compress").removeClass("fa-expand");
  }
  if ($('.birdmessage .details').hasClass('show')) {
    $('.birdmessagefull').addClass("height-250");
  }
}
function close() {
  $('.birdmessagefull').removeClass("height-250");
  if ($(".rightattributes.open").length === 0) {
    $('body').removeClass("modal-open");
  }
  $('.birdmessage-center').removeClass("message-top-in");
  $('.birdmessage-center').removeClass("full");
  $('.fullwidth>i').addClass("fa-expand").removeClass("fa-compress");
}

function detailsOpen() {
  $('.birdmessagefull').toggleClass("height-250");
}



function App() {
  let { path, url } = useRouteMatch();
  let history = createBrowserHistory();
  if(url !=undefined){
    if(url.endsWith("welcome/")){
      url=url.replace("welcome/","welcome")
    }
  }
  React.useEffect(() => {
    $('link[title="login"]').prop('disabled', true);
  }, []);
  return (
    <React.Fragment>
      <div className="birdmessage-center">
        <div className="birdmessage-backdrop" onClick={close}></div>
        <div className="birdmessage-container">
          <div className="fullwidth" onClick={expand}><i className="fa fa-expand"></i></div>
          <div className="close" onClick={close}>
            <i className="fa fa-times"></i>
          </div>
          <div className="messageicon birdmessage-error">
            <i className="fa fa-times-circle-o"></i>
          </div>
          <div className="birdmessage">
            <h2>
              Error : <span className="errorcode"></span>
            </h2>
            <p className="birdmessage-info"></p>
            <span className="details hide" onClick={detailsOpen}>Details</span>
            <span className="birdmessagefull">
              <span className="messagedetails">
                <xmp></xmp>
              </span>
            </span>
            <div className="container-fluid pull-right nopadding birdmessagebuttons hide">
              <button
                type="button"
                className="btn btn-bird btn-sm deleteyes"
                value="yes"
              >
                <i className="fa fa-check"></i> YES
              </button>
              <button
                type="button"
                className="btn btn-bird btn-sm deleteno"
                value="no"
              >
                <i className="fa fa-times"></i> NO
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="spinner" style={{ display: "none" }}>
        <div className="spinnercontent spinnermessage"></div>
        <div className="spinnercontent">
          {/* <!-- <img alt="Loading..." src="img/mainspinner.GIF"> --> */}
          <div className="ball-pulse">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
      <div className="boc-main">
        <div className="renamedfields" style={{ display: "none" }}></div>

        {/* <div id="main" className="main"> */}
        <Switch>
          <Route exact path={[`${path}/businessworkspaces`, `${path}/businessmodels`]} url={url} render={(props) => <React.Fragment><Datamain {...props} url={url} pageType="datapage" /></React.Fragment>} />
          <Route exact path={[path, `${path}/visualization`]} render={(props) => <React.Fragment>
            <Visualization {...props} url={url} pageType="dashboardpage"/></React.Fragment>} />
          <Route exact path={`${path}/notifications`} render={(props) => <React.Fragment>
            <Notifications {...props} url={url} pageType="notificationspage" /></React.Fragment>} />
          <Route exact path={`${path}/datahub`} render={(props) => <React.Fragment><Header {...props} url={url} pageType="datapage" /><div id="main" className="main"><Datahub {...props} url={url} /></div></React.Fragment>} />
          <Route exact path={`${path}/workspace/:WSID`} render={(props) => <React.Fragment><Header {...props} url={url} pageType="datapage" /><div id="main" className="main"><Workspace {...props} /></div></React.Fragment>} />
          <Route exact path={`${path}/workspace`} render={(props) => <React.Fragment><Header {...props} url={url} pageType="datapage" /><div id="main" className="main"><Workspace {...props} /></div></React.Fragment>} />
          <Route exact path={`${path}/viewreport/:reportId`} render={(props) => <React.Fragment><Header {...props} url={url} pageType="dashboardpage" /><div id="main" className="main"><Storyboardview {...props} /></div></React.Fragment>} />
          <Route exact path={`${path}/viewreport/:datamodelId/:tempReportId`} render={(props) => <React.Fragment><Header {...props} url={url} pageType="dashboardpage" /><div id="main" className="main"><Storyboardview {...props} tempReport={true} /></div></React.Fragment>} />
          <Route exact path={`${path}/viewstoryboard/:datamodelId/:tempReportId`} render={(props) => <React.Fragment><Header {...props} url={url} pageType="dashboardpage" /><div id="main" className="main"><Storyboardview {...props} /></div></React.Fragment>} />

          <Route exact path={`${path}/customstoryboard`} render={(props) => <React.Fragment><Header {...props} url={url} pageType="dashboardpage" /><div id="main" className="main"><CustomStoryboards {...props} /></div></React.Fragment>} />
          <Route exact path={`${path}/viewcustomstoryboard/:customstoryboard_id`} render={(props) => <React.Fragment><Header {...props} url={url} pageType="customstoryboardpage" /><div id="main" className="main"><CustomStoryboards url={url} {...props} /></div></React.Fragment>} />

          <Route exact path={`${path}/models`} render={(props) => <React.Fragment><Header {...props} url={url} pageType="datapage" /><div id="main" className="main"><Datamodels {...props} /></div></React.Fragment>} />
          <Route exact path={`${path}/models/:modelId`} render={(props) => <React.Fragment><Header {...props} url={url} pageType="datapage" /><div id="main" className="main"><Datamodels {...props} /></div></React.Fragment>} />
          <Route exact path={`${path}/public/embeddedreport/:reportId`} render={(props) => <div id="main" className="main"><PublicStoryboard {...props} /></div>} />
          <Route exact path={`${path}/private/embeddedreport/:reportId`} render={(props) => <div id="main" className="main"><Storyboardview {...props} /></div>} />
          <Route exact path={`${path}/private/alertreport/:reportId`} render={(props) => <div id="main" className="main"><Storyboardview {...props} /></div>} />
          <Route exact path={`${path}/public/schedulereport/:reportId`} render={(props) => <div id="main" className="main"><PublicStoryboard {...props} /></div>} />
          <Route exact path={`${path}/private/schedulereport/:reportId`} render={(props) => <div id="main" className="main"><Storyboardview {...props} /></div>} />
          <Route exact path={`${path}/sharereport/:reportId`} render={(props) => <React.Fragment><Header {...props} url={url} pageType="dashboardpage" /><div id="main" className="main"><Storyboardview {...props} /></div></React.Fragment>} />
          {/* <Route exact path={`${path}/viewcustomstoryboard`} render={(props) => <><Header {...props} url={url} pageType="customstoryboardpage" /><div id="main" className="main"><CustomStoryboardMain url={url} {...props} /></div></>} /> */}
          <Route exact path={`${path}/viewstorybook`} render={(props) => <React.Fragment><Header {...props} url={url} pageType="storybookpage" /><div id="main" className="main"><StroybookMain url={url} {...props} /></div></React.Fragment>} />
          <Route exact path={`${path}/viewstorybook/:storyBookId`} render={(props) => <React.Fragment><Header {...props} url={url} pageType="storybookpage" /><div id="main" className="main"><StroybookMain url={url} {...props} /></div></React.Fragment>} />
          <Route exact path={`${path}/sharestorybook/:storyBookId`} render={(props) => <React.Fragment><Header {...props} url={url} pageType="storybookpage" /><div id="main" className="main"><StroybookMain url={url} {...props} /></div></React.Fragment>} />
          <Route exact path={`${path}/sharecustomstoryboard/:customstoryboard_id`} render={(props) => <React.Fragment><Header {...props} url={url} pageType="customstoryboardpage" /><div id="main" className="main"><CustomStoryboards url={url} {...props} /></div></React.Fragment>} />
          <Route exact path={`${path}/viewsmartinsight/:datamodelId`} render={(props) => <React.Fragment><Header {...props} url={url} pageType="mlstoryboard" /><div id="main" className="main"><Storyboardview isFromSmartInsigt={true} {...props} /></div></React.Fragment>} />
          <Route exact path={`${path}/viewsmartinsight/:reportId/:datamodelId`} render={(props) => <React.Fragment><Header {...props} url={url} pageType="mlstoryboard" /><div id="main" className="main"><Storyboardview isFromSmartInsigt={true} isFromSavedSmartInsight={true} {...props} /></div></React.Fragment>} />
          <Route exact path={`${path}/sharesmartinsightreport/:reportId/:datamodelId`} render={(props) => <React.Fragment><Header {...props} url={url} pageType="mlstoryboard" /><div id="main" className="main"><Storyboardview isFromSmartInsigt={true} isFromSavedSmartInsight={true} {...props} /></div></React.Fragment>} />
          <Route exact path={`${path}/public/embeddedsmartinsightreport/:reportId/:datamodelId`} render={(props) => <div id="main" className="main"><PublicStoryboard isFromSmartInsigt={true} isFromSavedSmartInsight={true} {...props} /></div>} />
          <Route exact path={`${path}/private/embeddedsmartinsightreport/:reportId/:datamodelId`} render={(props) => <div id="main" className="main"><Storyboardview isFromSmartInsigt={true} isFromSavedSmartInsight={true} {...props} /></div>} />
          <Route exact path={`${path}/public/embeddedcustomstoryboard/:customstoryboard_id`} render={(props) => <div id="main" className="main"><PublicStoryboard isFromCustomStoryboard={true} {...props} /></div>} />
          <Route exact path={`${path}/private/embeddedcustomstoryboard/:customstoryboard_id`} render={(props) => <div id="main" className="main"><CustomStoryboards {...props} /></div>} />
          <Route exact path={`${path}/importreport/:reportId`} render={(props) => <React.Fragment><Header {...props} url={url} pageType="dashboardpage" /><div id="main" className="main"><Storyboardview isImportedReport={true} {...props} /></div></React.Fragment>} />
          <Route exact path={`${path}/search`} render={(props) => <React.Fragment><div id="main" className="main"><Searchview {...props} url={url} pageType="searchpage"/></div></React.Fragment>} />
          <Route exact path={`${path}/governance`} render={(props) => <React.Fragment><Header {...props} url={url} pageType="governancepage" /><div id="main" className="main"><GovernanceView {...props} /></div></React.Fragment>} />
          <Route exact path={`${path}/viewmodel/:datamodelId`} render={(props) => <React.Fragment><Header {...props} url={url} pageType="mlstoryboard" /><div id="main" className="main"><MLReportView {...props} /></div></React.Fragment>} />
          <Route exact path={`${path}/viewmodel/:reportId/:datamodelId`} render={(props) => <React.Fragment><Header {...props} url={url} pageType="mlstoryboard" /><div id="main" className="main"><MLSavedReportView {...props} /></div></React.Fragment>} />
          <Route exact path={`${path}/sharereport/:reportId/:datamodelId`} render={(props) => <React.Fragment><Header {...props} url={url} pageType="mlstoryboard" /><div id="main" className="main"><MLSavedReportView {...props} /></div></React.Fragment>} />      
          <Route exact path={`${path}/loginsuccess`} render={(props) => <div id="main" className="main"><SamlRequest {...props} /></div>} />
          <Route exact path={`${path}/datahub_sync_logbook`} render={(props) => <React.Fragment><Header {...props} url={url} pageType="datapage" /><div id="main" className="main"><DatahubSyncLogBook {...props} /></div></React.Fragment>} />
          <Route exact path={`${path}/workspace_sync_logbook/:WSID`} render={(props) => <React.Fragment><Header {...props} url={url} pageType="datapage" /><div id="main" className="main"><WorkspaceSyncLogBook {...props} /></div></React.Fragment>} />
          <Route exact path={`${path}/viewautoviz_report/:datamodelId`} render={(props) => <React.Fragment><Header {...props} url={url} pageType="mlstoryboard" /><div id="main" className="main"><AutovizReportView isFromSmartInsigt={true} {...props} /></div></React.Fragment>} />
          <Route exact path={`${path}/bypassloginpage`} render={(props) => <div id="main" className="main"><ByPassLogindview {...props} /></div>} />
          {window.location.pathname.indexOf("admin") < 0 ?
            <Route render={(props) => <h2 style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)" }}>Page Not Found(404)</h2>} />
            : null
          }

          {/* <h2 style={{position:"absolute",top:"50%",left:"50%",transform:"translate(-50%,-50%)"}}>Page Under Construction...</h2> */}
        </Switch>
        {/* </div> */}
        <div id="urlnavigationmenu"></div>
      </div>
      {/* <CreateConnection /> */}
    </React.Fragment>
  );
}