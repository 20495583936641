import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { lang } from "../js/helpers/utils/languages/language";
import paginationFactory from 'react-bootstrap-table2-paginator';
import SendRequest from '../SendRequest';
import _ from 'underscore';
const $ = window.$;
const RemotePagination = ({ data, page, sizePerPage, onTableChange, totalSize,columns }) => (
  // <div className="notifications-table-container">
    <BootstrapTable
      remote
      keyField="id"
      data={data}
      columns={columns}
      pagination={paginationFactory({ page, sizePerPage, totalSize })}
      onTableChange={onTableChange}
      noDataIndication="No matching records found"
    />
  // </div>
);
export default class NotificationsListTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      data: props.data,
      sizePerPage: props.sizePerPage,
      totalRecords: props.totalRecords,
      search: props.search,
      columns:props.columns
    };
  }

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, data, totalRecords }) => {
    const currentIndex = (page - 1) * sizePerPage;
    {
      var that = this;
      type == "sort" ?
        setTimeout(() => {
          let requestHeaders = {
            method: "get",
            headers: new Headers({
              "Content-Type": "application/json"
            })
          };
          SendRequest({
            url: "getdashboardreportslist",
            queryString: "?fromDate= " + " " + "&toDate= " + " " + "&index= " + currentIndex + "&noOfRecords=" + sizePerPage + "&searchCriteria=" + that.state.search + "&order=" + sortOrder + "&colName=" + sortField + "&urltype=" +that.props.urlType,
            sucessFunction: (response) => {
              if (response.hasOwnProperty('error')) {
                $(".spinner").hide();
              } else {
                var headerlist = response["header"];
                var data = response["data"];
                if(that.props.urlType==='getSharedPaginationNotifications'){
                  _.each(data, function (notification, index) {
                    if (notification.vizType == "ml_storyboard") {
                      notification.url = <a href={`${that.props.url}/sharereport/${btoa(notification.reportId)}/${btoa(notification.dataModelId)}`}>{lang["alerts.viewreport"]}</a>;
                    } else if (notification.vizType == "smartinsight_storyboard") {
                      notification.url = <a href={`${that.props.url}/sharesmartinsightreport/${btoa(notification.reportId)}/${btoa(notification.dataModelId)}`}>{lang["alerts.viewreport"]}</a>;
                    } else if (notification.vizType == 'storybook') {
                      notification.url = <a href={`${that.props.url}/sharestorybook/${btoa(notification.storybook_id)}`} class="sharereportnavigation" id="viewsharenotificationstorybook">{lang["alerts.viewreport"]}</a>
                    } else {
                      notification.url = <a href={`${that.props.url}/sharereport/${btoa(notification.vizid)}`} >{lang["alerts.viewreport"]}</a>
                    }
                  });
                }else if(that.props.urlType==='getAlertPaginationNotifications'){
                  _.each(data, function (notification, index) {
                    notification.url = <a href={`${that.props.url}/sharereport/${btoa(notification.reportId)}`}>{lang["alerts.viewreport"]}</a>
                  });
                }

                var keys = Object.keys(headerlist);
                this.setState(() => ({
                  page,
                  data: data,
                  sizePerPage,
                  totalSize: totalRecords
                }));
              }
            },
            rejectFunction: () => { },
            headers: requestHeaders
          })
        }, 10) :
        setTimeout(() => {
          let requestHeaders = {
            method: "get",
            headers: new Headers({
              "Content-Type": "application/json"
            })
          };
          SendRequest({
            url: "getdashboardreportslist",
            queryString: "?fromDate= " + "" + "&toDate= " + "" + "&index= " + currentIndex + "&noOfRecords=" + sizePerPage + "&searchCriteria=" + that.state.search + "&order=" + sortOrder + "&colName=" + "" +"&urltype="+that.props.urlType,
            sucessFunction: (response) => {
              if (response.hasOwnProperty('error')) {
                $(".spinner").hide();
              } else {
                var headerlist = response["header"];
                var data = response["data"];
                if(that.props.urlType==='getSharedPaginationNotifications'){
                _.each(data, function (notification, index) {
                  if (notification.vizType == "ml_storyboard") {
                    notification.url = <a href={`${that.props.url}/sharereport/${btoa(notification.reportId)}/${btoa(notification.dataModelId)}`}>{lang["alerts.viewreport"]}</a>;
                  } else if (notification.vizType == "smartinsight_storyboard") {
                    notification.url = <a href={`${that.props.url}/sharesmartinsightreport/${btoa(notification.reportId)}/${btoa(notification.dataModelId)}`}>{lang["alerts.viewreport"]}</a>;
                  } else if (notification.vizType == 'storybook') {
                    notification.url = <a href={`${that.props.url}/sharestorybook/${btoa(notification.storybook_id)}`} class="sharereportnavigation" id="viewsharenotificationstorybook">{lang["alerts.viewreport"]}</a>
                  } else {
                    notification.url = <a href={`${that.props.url}/sharereport/${btoa(notification.vizid)}`} >{lang["alerts.viewreport"]}</a>
                  }
                });
              }else if(that.props.urlType==='getAlertPaginationNotifications'){
                _.each(data, function (notification, index) {
                  notification.url = <a href={`${that.props.url}/sharereport/${btoa(notification.reportId)}`}>{lang["alerts.viewreport"]}</a>
                });
              }
                var keys = Object.keys(headerlist);
                this.setState(() => ({
                  page,
                  data: data,
                  sizePerPage,
                  totalSize: totalRecords
                }));
              }
            },
            rejectFunction: () => { },
            headers: requestHeaders
          })

        }, 10);
    }
  }
  render() {
    var that = this.props;
    const { data, sizePerPage, page, totalRecords,columns } = this.state;
    return (
              <RemotePagination
                data={data}
                page={page}
                sizePerPage={sizePerPage}
                totalSize={totalRecords}
                onTableChange={this.handleTableChange}
                columns={that.columns} />
    );
  }
}