import React from "react";
import ReactDOM from 'react-dom'
import * as collection from "../js/helpers/utils/SavedReportCollection";
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import * as TabInfo from "../js/helpers/utils/tabinfo";
import ViewSingleSavedReport from './singlesavedreportview'
import { lang } from "../js/helpers/utils/languages/language";
import SendRequest from '../SendRequest';
import QuickTemplate from "./drilldownfilters.jsx";
import _ from 'underscore';
import * as attrchange from "attrchange";
const $ = window.$;
export default class SingleReportView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isTempStoryBoard: props.isTempStoryBoard,
            scheduleid: props.scheduleid,
            date: props.date,
            model: props.model,
            instantExclFiltersData: [],
            allfeatures: props.features,
            features: props.features,
            reportInformation: props.reportInformation,
            multifactentities: props.multifactentities,
            facttables: props.facttables,
            multifact: props.multifact != undefined && props.multifact != null ? props.multifact : false,
            view_Only_Flag: props.view_Only_Flag,
            isViewOnly: props.view_Only_Flag,
            reportId: props.reportId,
            datamodelId: props.datamodelId,
            isDirect: props.isDirect,
            isStoredProcedure: false,
            isEmbeddedReport: false,
            isPurgedReport: false,
            isSharedReport: false,
            isScheduleReport: false,
            displayFooter: true,
            schemaType: null,
            isMicrosoftEdge: null,
            charttitle: null,
            grid: null,
            reportcolumns: props.reportcolumns,
            isForAddInstantFilters: false,
            grids: [],
            newgrids: [],
            update: false,
            tabid: props.tabid,
            isNLPReport:props.isNLPReport,
            isVersioned:props.isVersioned,
            versionname:props.versionname,
            userRole:props.userRole
        }
    }
    componentDidMount() {
        console.log("test");
        setTimeout(function(){
        $('.gridspinner').attrchange({
			trackValues: true, 
			callback: function (event) {
				var $this = event.target;
                var chartid = $($this).attr('data-num');
                chartid = "chart"+chartid;
				$('#spinner'+chartid+' .gridspinnermessage').empty();
				$('#spinner'+chartid+' .gridspinnermessage').removeClass('showopacity')
				$('#spinner'+chartid+' .gridspinnermessage').removeClass('oops');
				$('#spinner'+chartid+' .gridspinnercontent img').removeClass('hide');
				
				setTimeout(function(){
					var state = $('#spinner'+chartid+'').css('display');
					if (state==='block'){
						var currenttext = $('#spinner'+chartid+' .gridspinnermessage').html();
						if(currenttext!=lang["reports.serverresponse"]+' <div class="btn btn-transparent gridspinnercontinue singlespinnerwait'+chartid+'"><i class="fa fa-check-circle"></i>&nbsp;'+lang["reports.wait"]+'</div>&nbsp;(or)&nbsp;<div class="btn btn-transparent singlespinnerclose'+chartid+' gridspinnerclose"><i class="fa fa-times-circle"></i>&nbsp;'+lang["reports.close"]+'</div>'){
							$('#spinner'+chartid+' .gridspinnermessage').text('We are processing your request...');
						}
						$('#spinner'+chartid+' .gridspinnermessage').addClass('showopacity');
						setTimeout(function(){
							$('#spinner'+chartid+' .gridspinnercontent img').addClass('hide');
							$('#spinner'+chartid+' .gridspinnermessage').html(lang["reports.serverresponse"]+' <div class="btn btn-transparent gridspinnercontinue singlespinnerwait'+chartid+'"><i class="fa fa-check-circle"></i>&nbsp;'+lang["reports.wait"]+'</div>&nbsp;(or)&nbsp;<div class="btn btn-transparent singlespinnerclose'+chartid+' gridspinnerclose"><i class="fa fa-times-circle"></i>&nbsp;'+lang["reports.close"]+'</div>');
							$('#spinner'+chartid+' .gridspinnermessage').addClass('oops');
							
							$('.singlespinnerclose'+chartid+'').on("click",function(){
								$('#spinner'+chartid+' .gridspinnermessage').empty();
								$('#spinner'+chartid+' .gridspinnermessage').removeClass('showopacity')
								$('#spinner'+chartid+' .gridspinnermessage').removeClass('oops');
								$('#spinner'+chartid+' .gridspinnercontent img').removeClass('hide');
								$('#spinner'+chartid).hide(); 
							});
							$('.singlespinnerwait'+chartid).on("click",function(){
								$('#spinner'+chartid+' .gridspinnermessage').empty();
								$('#spinner'+chartid+' .gridspinnermessage').removeClass('showopacity')
								$('#spinner'+chartid+' .gridspinnermessage').removeClass('oops');
								$('#spinner'+chartid+' .gridspinnercontent img').removeClass('hide');
								$('#spinner'+chartid).hide(); 
								$('#spinner'+chartid).show(); 
							});
						},15000);
					}else{
						$('#spinner'+chartid+' .gridspinnermessage').empty();
						$('#spinner'+chartid+' .gridspinnermessage').removeClass('showopacity');
						$('#spinner'+chartid+' .gridspinnermessage').removeClass('oops');
						$('#spinner'+chartid+' .gridspinnercontent img').removeClass('hide');
					}
					
				},8000);
			}
        });
    },1000);
    }
    render() {
        var that = this
        that.state.displayFooter = true;
        var urlparameters = window.location.search.replace("?", "").split("&");
        $.each(urlparameters, function (i, u) {
            var parameter = u.split("=");
            if (parameter[0].toLowerCase() == "bottomfooter" && parameter[1].toLowerCase() == "false") {
                that.state.displayFooter = false;
            }
        });

        var isSharedReport = false,
            isViewModel = false,
            isEmbeddedReport = false,
            isScheduleReport = false,
            isPurgedReport = false;
        if (window.location.pathname.indexOf("sharereport") >= 0) { isSharedReport = true; }
        if (window.location.pathname.indexOf("viewmodel") >= 0) { isViewModel = true; }
        if (window.location.pathname.indexOf("embeddedreport") >= 0 || window.location.pathname.indexOf("alertreport") >= 0) { isEmbeddedReport = true; }
        if (window.location.pathname.indexOf("schedulereport") >= 0) { isScheduleReport = true; }
        if (window.location.pathname.indexOf("viewpurgereport") >= 0) { isPurgedReport = true; }

        var isDirect = collection.getIsDirect();
        var isViewOnly = false;
        if (collection.getSharedViewOnlyFlag() != undefined) {
            var parsedViewFlag = JSON.parse(collection.getSharedViewOnlyFlag());
            if (parsedViewFlag == "1") {
                isViewOnly = true;
            }
        }

        if (!this.props.isFromSavedSmartInsight) {
            if (this.props.isFromStoryBook != undefined && this.props.isFromStoryBook) {
                $('#' + this.props.el + " .row.grid-stack").attr("id", this.props.el + "charts");
                $('#storybooktabcontent' + this.props.tabid + ' .quickfiltercontainer').attr("tab-id", this.props.tabid);
            }

            ChartInfo.functions.setIsDirect(collection.getIsDirect());
            ChartInfo.functions.setIsStoredProcedure(this.state.isStoredProcedure);
            sessionStorage.setItem("applicablefilters", ",");
            sessionStorage.setItem("stroyboardcolortheme", "");
            this.state.showError = false;
            var reportdetails = this.state.reportInformation;
            var model = this.state.model;
            var detailsObj = reportdetails[1]["details"];
            var auditMetaDataObj = {};
            if (reportdetails[16] != undefined && reportdetails[16]["auditMetaDataOptions"] != undefined) {
                auditMetaDataObj = JSON.parse(reportdetails[16]["auditMetaDataOptions"]);
            } else {
                auditMetaDataObj["metaDataAudit"] = 1;
            }
            ChartInfo.functions.setAuditMetaDataOptions(auditMetaDataObj);

            var name = detailsObj["name"];
            var folder = detailsObj["folder"];
            var desc = detailsObj["desc"];
            var timeZoneType = detailsObj["timeZoneType"];
            if (collection.getIsDirect() != undefined) {
                this.state.isDirect = collection.getIsDirect();
            } else if (this.props.isDirect != undefined) {
                this.state.isDirect = this.props.isDirect;
            }
            ChartInfo.functions.setPurgeConfig(detailsObj["maxage"], detailsObj["maxageunits"], detailsObj["purgetime"], detailsObj["maxpurgeindex"]);
            var isStoryboardLIVE;
            //get drill through reports
            if (!this.state.isImportedReport) {
                ChartInfo.functions.setDrillThroughReports("[]");
                let requestHeaders = {
                    method: "get",
                    headers: new Headers({
                        "Content-Type": "application/json"
                    })
                };
                SendRequest({
                    url: "getDrillThroughReports",
                    queryString: "?reportId=" + that.props.reportId,
                    sucessFunction: response => {
                        if (response.success) {
                            ChartInfo.functions.setDrillThroughReports(response.drillThroughReports);
                        }
                    },
                    rejectFunction: response => {
                        console.log(response);
                    },
                    headers: requestHeaders
                });
            }
            if (reportdetails[0]["axis"].length > 1 || (model != undefined && model.visualizationtype === "story")) {

            } else {// single report
                var chartnumber = 0;
                var axisData = reportdetails[0]["axis"];
                _.each(axisData, function (axisdetails) {
                    chartnumber = axisdetails["chartnumber"];
                });
                var that = this;
                that.state.reportdetails = reportdetails;
                var jsonObject = {};
                if (reportdetails[reportdetails.length - 3]["auditMetaDataOptions"] != undefined) {
                    var auditMetaDataObj = JSON.parse(reportdetails[reportdetails.length - 3]["auditMetaDataOptions"]);
                    var metaDataAudit = auditMetaDataObj.metaDataAudit;
                    jsonObject["metaDataAudit"] = metaDataAudit;
                    ChartInfo.functions.setAuditMetaDataOptions(jsonObject);
                }
                else if (reportdetails[reportdetails.length - 1]["auditMetaDataOptions"] != undefined) {
                    var auditMetaDataObj = JSON.parse(reportdetails[reportdetails.length - 1]["auditMetaDataOptions"]);
                    var metaDataAudit = auditMetaDataObj.metaDataAudit;
                    jsonObject["metaDataAudit"] = metaDataAudit;
                    ChartInfo.functions.setAuditMetaDataOptions(jsonObject);
                }

                sessionStorage.removeItem("customhierarchy" + chartnumber);
                var dynamicdiv = "main";
                if (that.props.isFromStoryBook) {
                    dynamicdiv = this.props.el;
                }
                that.state.viewSingleSavedReport = new ViewSingleSavedReport(
                    {
                        ...this.props,
                        title: this.props.title,
                        dynamicdiv: dynamicdiv,
                        reportId: that.props.reportId,
                        isFromStoryBook: that.props.isFromStoryBook,
                        isFromTabClick: that.props.isFromTabClick,
                        tabid: that.props.tabid,
                        reportmodel: model,
                        chartnumber: chartnumber,
                        name: name,
                        folder: folder,
                        desc: desc,
                        features: that.state.features,
                        schedule: that.props.schedule,
                        scheduleid: that.props.scheduleid,
                        date: that.props.date,
                        schemaType: that.state.schemaType,
                        isPurgedReport: that.state.isPurgedReport,
                        datamodelId: that.props.datamodelId,
                        isImportedReport: that.state.isImportedReport,
                        multifact: that.state.multifact,
                        facttables: that.state.facttables,
                        multifactentities: that.state.multifactentities,
                        worker: this.props.worker,
                        isNLPReport:that.props.isNLPReport,
                        isVersioned:that.props.isVersioned,
                        versionname:that.props.versionname,
                        userRole:that.state.userRole
                    });
                if (that.state.viewSingleSavedReport.showError == true) {
                    return false
                }
                var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(chartnumber));
                if (that.props.isFromStoryBook != undefined && that.props.isFromStoryBook) {
                    var tabId = that.props.tabid;
                    TabInfo.functions.updateChartInfoForTab(tabId, ChartInfo.functions.getAllChartDetails());
                }
                var chartType = chartDetails.chartType;
                if ("doby" === chartType) {
                    $("#sinbleviewchartfromchartdataview[data-num='" + chartnumber + "']").hide();
                    $("#sinbleviewchartdataview[data-num='" + chartnumber + "']").hide();
                }

                // If report is saved text chart. I am calling textChart method manually
                //  that.state.viewSingleSavedReport.redrawTextChart(chartnumber)

                // that.startliveupdate(chartnumber, "singlereport");
                if (that.state.isImportedReport) {
                    var singleReportTitle = model.title;
                    $(".boc-viewreport-title[data-num=" + chartnumber + "]").text(singleReportTitle).attr("title", singleReportTitle);
                }
                /*for Safari Browser MyReport chart height Issue Fix*/
                function resizeForSafari() {
                    var browser = "";
                    var chrome = /chrom(e|ium)/.test(navigator.userAgent.toLowerCase());
                    var safari = /safari/.test(navigator.userAgent.toLowerCase());

                    if (chrome != true && safari == true) {
                        var actual = $("body").height();
                        var header = $(".navbar.navbar-bird.navbar-fixed-top").height();
                        var controlbar = $(".row.controlbar-main").height();
                        var chartcontrols = $(".row.maxanim9").height();
                        var setheight = actual - header - controlbar - chartcontrols - 70;
                        $(".singlereportview.fullscreenmain .maxanim10").css("height", setheight);
                    }
                }
                resizeForSafari();
                $(window).on("resize", function () { resizeForSafari(); })
                var auditMetaDataFeature = false;
                var auditDataFeature = false;
                $.each(that.state.features, function (index1, feature1) {
                    if (feature1.moduleName == 'my_report') {
                        $.each(feature1.features, function (index2, feature2) {
                            if (feature2.featureName == 'audit_meta_data_maximize' && feature2.allow == true) {
                                auditMetaDataFeature = true;
                                auditDataFeature = true;
                            }
                        });
                    }
                });
                ChartInfo.functions.displaymetadata(auditMetaDataObj, auditMetaDataFeature, auditDataFeature);
                //  });
            }
            if (that.state.isDirect != undefined) {
                ChartInfo.functions.setIsDirect(that.state.isDirect);
            }
            ChartInfo.functions.setIsStoredProcedure(that.state.isStoredProcedure);
            var drillDownAllFilter;
            var chartNumber = Object.keys(ChartInfo.functions.getAllChartDetails())[0];
            if (chartNumber != undefined) {
                var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(chartNumber));
                drillDownAllFilter = _.unique(chartDetails["drillDownAllFilter"], 'columnname');
            }
            var that = this;
            var isSharedReport = false;
            if (window.location.pathname.indexOf("sharereport") >= 0) {
                isSharedReport = true;
            }
            $(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").empty();
            $(".boc-dark-background>.container-fluid>.row>.quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").attr("drilled-chart", this.props.chartnumber);
            $(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").empty();
            $(".boc-dark-background>.container-fluid>.row>.quickfiltercontainer").attr("drilled-chart", this.props.chartnumber);
            if (drillDownAllFilter != undefined) {
                _.each(drillDownAllFilter, function (i, j) {
                    var name = decodeURIComponent(drillDownAllFilter[j].columndisplayname);
                    if (name === null) {
                        name = decodeURIComponent(drillDownAllFilter[j].name);
                    }
                    else if (name == "undefined") {
                        name = decodeURIComponent(drillDownAllFilter[j].displayName);
                        if (drillDownAllFilter[j].customfield != undefined) {
                            name = drillDownAllFilter[j].customfield.name;
                            if (drillDownAllFilter[j].data == undefined) {
                                drillDownAllFilter[j].data = drillDownAllFilter[j].customfield.parameter;
                            }
                        }
                    }
                    if (drillDownAllFilter[j].type == "date") {
                        name = $(name).find('li:first').attr('column-display-name');
                        if (name === undefined) {
                            name = drillDownAllFilter[j].displayName;
                        }
                    }
                    var today = new Date();
                    var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                    dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                    $(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").append("<div className='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
                    ReactDOM.render(<QuickTemplate dynamicdiv={dynamicdiv} columnDisplayName={name} filterValues={drillDownAllFilter[j].data} />, document.getElementById(dynamicdiv));
                    $(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").append(document.getElementById("drilldownfiltersjsxid" + dynamicdiv))
                    $("#" + dynamicdiv).remove();
                });
                if (isSharedReport == true && collection.getSharedViewOnlyFlag() != null && collection.getSharedViewOnlyFlag() != undefined && collection.getSharedViewOnlyFlag() == '1') {
                    $(".quickfilterremove").remove();
                } else {
                    $(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "] .quickfilterremove").html("<i title="+lang["reports.remove"]+" i class='fa fa-times'></i>")
                }
            }
        }
        if (this.props.isFromSavedSmartInsight != undefined && this.props.isFromSavedSmartInsight && this.props.isFromSmartInsigt != undefined && this.props.isFromSmartInsigt) {
            var globalfilterArray = ChartInfo.functions.getFilterArray();
            var that = this;
            // require(['views/viewreport/smartinsights/savedsmartinsightview'], function (SmartInsightsView) {
            //     that.smartInsightsView = new SmartInsightsView({
            //         viewreportCollection: collection,
            //         reportId: that.props.reportId,
            //         datamodelId: that.props.datamodelId,
            //         features: that.props.features,
            //         isPurgedReport: false,
            //         schemaType: null,
            //         globalfilterArray: JSON.stringify(globalfilterArray),
            //         broker: that.broker,
            //         element: that.props.tabid != undefined ? 'storybooktabcontent' + that.props.tabid : '',
            //         isFromStoryBook: that.props.isFromStoryBook,
            //         tabid: that.props.tabid,
            //     });
            // });
        }

        return (<div></div>);
    }
}