import React, { Component } from "react";
import ReactDOM from 'react-dom'
import SendRequest from '../SendRequest';
import SaveChangePasswordView from "./savechangepasswordview.js";
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;

export default class ChangePasswordTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  componentDidMount() {
    this.getPasswordHint();
    $(".passwordhint").click(function (e) {
      e.preventDefault();
      $(".phint").slideToggle("slow");
    });
  }
  getPasswordHint() {
    let requestHeaders = {
      method: "get",
      headers: new Headers({
        "Content-Type": "application/json"
      })
    };
    SendRequest({
      url: "getPasswordPolicyRulesHint",
      queryString: "",
      sucessFunction: (data) => {
        //var result=JSON.parse(data);
        var result = data;
        $("#spinner").hide();
        if (result != null && result != undefined) {
          var passwordhint = [];
          if (result.min_max_length != undefined && result.min_max_length.length > 0) {
            var length = [];
            length = JSON.parse(result.min_max_length);
            passwordhint.push('<li> Minimum of ' + length[0] + ' Characters in length. </li>');
            passwordhint.push('<li> Maximum of ' + length[1] + ' Characters in length. </li>');
          }
          if (result.min_no_of_digits != undefined && result.min_no_of_digits > 0) {
            passwordhint.push('<li> Must contain ' + result.min_no_of_digits + ' or more Digits.</li>');
          }
          if (result.min_no_of_lowercase_characters != undefined && result.min_no_of_lowercase_characters > 0) {
            passwordhint.push('<li> Must contain ' + result.min_no_of_lowercase_characters + ' or more Lowercase Characters. </li>');
          }
          if (result.min_no_of_uppercase_characters != undefined && result.min_no_of_uppercase_characters > 0) {
            passwordhint.push('<li> Must contain ' + result.min_no_of_uppercase_characters + ' or more Uppercase Characters. </li>');
          }
          if (result.min_no_of_special_characters != undefined && result.min_no_of_special_characters > 0) {
            passwordhint.push('<li> Must contain ' + result.min_no_of_special_characters + ' or more Special Characters. </li>');
          }
          if (result.allow_whitespaces != undefined && result.allow_whitespaces == 1) {
            passwordhint.push('<li> Must not contain Whitespaces. </li>');
          } else {
            passwordhint.push('<li> Can contain Whitespaces. </li>');
          }
          // $(".passwordhint").append('<div class="phint" style="display: block;"><h4> Password Hints</h4><ul class="phint-list" ></ul></div>');
          $(".phint>ul.phint-list").html(passwordhint);

        }

      },
      rejectFunction: () => {
        $("#spinner").hide();

      },
      headers: requestHeaders,
    })

  }
  changepasswordsubmit(events) {
    events.preventDefault();
    $(".phint").slideUp("slow");
    var serialized = $('.changepasswordform').serializeArray();
    var s = '';
    var data = {};
    for (s in serialized) {
      data[serialized[s]['name']] = serialized[s]['value']
    }


    var result = JSON.stringify(data);
    var finaldata = JSON.parse(result);
    //  this.model.set(finaldata);

    // if(this.model.isValid(true)){
    var answer = $("#answer").val();
    answer = $.trim(answer);
    var realanswer;
    var questionnumber = $(".questionnumber").val();
    if (questionnumber == 1) {
      realanswer = $("#realanswer1").val();
    } else if (questionnumber == 2) {
      realanswer = $("#realanswer2").val();
    } else if (questionnumber == 3) {
      realanswer = $("#realanswer3").val();
    }
    //$('.spinner').show();
    if ($("#oldpassword").val() == $("#newpassword").val()) {
      $(".currenPassword").show();
      setTimeout(function () {
        $(".currenPassword")
          .hide();
      }, 3000);

    } else if ($("#newpassword").val() != $('#repeatnewpasssword').val()) {
      $(".invalidpassword").hide();
      $(".reenterPassword").show();
      setTimeout(function () {
        $(".reenterPassword").hide();
      }, 3000);
    } else if (realanswer != undefined) {
      if (answer.toLowerCase() != realanswer.toLowerCase()) {
        $(".answermatch").show();
        setTimeout(function () {
          $(".answermatch").hide();
        }, 3000);
      } else {
        $('.spinner').show();
        new SaveChangePasswordView();
      }
    } else {
      $('.spinner').show();
      new SaveChangePasswordView();
    }

  }
  closePasswordChange() {
    if (document.getElementById("changepasswordmodelview") != null) {
      ReactDOM.unmountComponentAtNode(document.getElementById("changepasswordmodelview"));
    }
  }
  render() {
    var arr = [];
    if (this.props.securityQuestions.success != "false") {
      arr.push(this.props.securityQuestions);
    }
    return (
      <React.Fragment>
        <>
          <div class="modal fade in" id="changepasswordmodel" tabindex="-1"
            role="dialog" aria-labelledby="Change password" aria-hidden="true" style={{ display: "block" }}>
            <div className="modal-backdrop fade in"></div>
            <div class="modal-dialog modal-sm modal-sm-400 modal-dialog-sm-center modal-dialog-center" style={{ zIndex: "1050" }}>
              <div class="modal-content">
                <div class="modal-header boc-modal-hf-padding">
                  <button type="button" class="close white" id="passwordclose" data-dismiss="modal" onClick={this.closePasswordChange}
                    aria-hidden="true">&times;</button>
                  <h4 class="modal-title"><i class="fa fa-key"></i> {lang["admin.header.chngpwd"]}</h4>
                </div>
                <div class="modal-body">
                  <div class="container-fluid">
                    <div class="row filtercontent marg-top-10 marg-bot-10">
                      <form class="form-horizontal col-xs-12 changepasswordform">
                        <div class="form-group form-group-sm">
                          <span class="required right labels"></span>
                          <label for="oldpassword" class="col-xs-12">{lang["admin.header.oldpwd"]}</label>
                          <div class="col-xs-12">
                            <input type="password" class="form-control" name="oldpassword" id="oldpassword" placeholder={lang["admin.header.oldpwd"]} />
                            <span class="help-block hidden fa-help-dark"></span>
                            <div class="oldPassword changePassword-fields" style={{ display: "none" }} role="alert">{lang["admin.oldpwdvalid"]}</div>
                          </div>
                        </div>
                        <div class="form-group form-group-sm">
                          <span class="required right labels"></span>
                          <label class="col-xs-12" for="newpassword">{lang["admin.header.newpwd"]}</label>
                          <div class="col-xs-12">
                            <input type="password" name="newpassword" id="newpassword" class="form-control" placeholder={lang["admin.header.newpwd"]} />
                            <span class="help-block hidden fa-help-dark"></span>
                            <div class="currenPassword changePassword-fields" style={{ display: "none" }} role="alert">{lang["admin.newpwdvalid"]}</div>
                            <button class="btn btn-transparent pull-right passwordhint " onClick={this.getPasswordHint} title=''><span class=""><i class="fa fa-info"></i></span></button>
                            <div class="phint"><h4> {lang["admin.header.pwdrules"]}</h4><ul class="phint-list" ></ul></div>
                          </div>
                        </div>
                        <div class="form-group form-group-sm">
                          <span class="required right labels"></span>
                          <label class="col-xs-12" for="repeatnewpasssword">{lang["admin.header.renterpwd"]}</label>
                          <div class="col-xs-12">
                            <input type="password" placeholder={lang["admin.header.renterpwd"]} name="repeatnewpasssword" id="repeatnewpasssword" class="form-control" />
                            <span class="help-block hidden fa-help-dark"></span>
                            <div class="reenterPassword changePassword-fields" style={{ display: "none" }} role="alert">{lang["admin.header.reentervalid"]}</div>
                            <div class="invalidpassword changePassword-fields" style={{ display: "none" }} role="alert"></div>
                          </div>
                        </div>
                        {

                          arr.map((data, index) => {
                            return <div key={index} class="form-group form-group-sm">
                              <span class="required right labels"></span>
                              <div class="col-xs-12">
                                <select class="questionnumber form-control">
                                  <option value="1">{data.securityquestion1.question}</option>
                                  <option value="2">{data.securityquestion2.question}</option>
                                  <option value="3">{data.securityquestion3.question}</option>
                                </select>
                              </div>
                              <div class="col-xs-12">
                                <input type="text" placeholder={lang["user.header.securityans"]} name="answer" id="answer" class="form-control" />
                                <span class="help-block hidden fa-help-dark"></span>
                                <div class="answermatch changePassword-fields" style={{ display: "none" }} role="alert">{lang["admin.header.security"]}</div>
                                <input type="hidden" id="realanswer1" name="realanswer1" value={data.securityquestion1.answer} />
                                <input type="hidden" id="realanswer2" name="realanswer2" value={data.securityquestion2.answer} />
                                <input type="hidden" id="realanswer3" name="realanswer3" value={data.securityquestion3.answer} />
                              </div>
                            </div>
                          })
                        }
                      </form>
                    </div>
                  </div>
                </div>
                <div class="modal-footer boc-modal-hf-padding">
                  <button type="button" class="btn btn-bird" id="btn-birdchangepass" onClick={this.closePasswordChange} data-dismiss="modal"><i class="fa fa-times"></i> {lang["admin.header.closebtn"]}</button>
                  <button type="submit" class="btn btn-bird changepasswordsubmit" onClick={this.changepasswordsubmit} id="passwordchanges"><i class="fa fa-check"></i> {lang["admin.header.submitbtn"]}</button>
                </div>
              </div>
            </div>
          </div>
        </>
      </React.Fragment>
    )
  }
}