import React, { Component } from "react";
import _ from 'underscore';
import { lang} from "../../js/helpers/utils/languages/language";
export default class OtherSettingsTemplate extends Component{
    constructor(props){
        super(props);
    }
    render(){
        var that=this.props;
        var features = that.features;
        var ldapSettings = features[0].features.map((feature, index) =>{
            // if(feature.featureName=='admin_view_ldap'&&feature.allow==true){
            //     return <div className="col-xs-4 col-sm-2 col-lg-1 col-md-1 hvac">
			// <div className="sourcediv ldap">
			// 						<button  className="sourceitem ldapsettingsmain" id="ldapsettingsmainsladap" onClick = {that.ldapView}>
			// 							<i className="fa fa-sitemap fa-2x fa-green"></i>
			// 						</button>
			// 						<span className="sourcetitle" id="ldaps">{lang["admin.ldapbtn"]}</span>
			// 					</div>
			// 					</div>
            // }
        }
	);
        return (
            <div className="container-fluid">
	<div className="row">
		<div className="marg-top-10">
		{(that.currentRole!="ROLE_Super_Admin")?ldapSettings:null}
					<div className="col-xs-4 col-sm-2 col-lg-1 col-md-1 hvac">
 					<div className="sourcediv">
						<button className="sourceitem mailsettingsmain" id="mailsettingsmainsladap" onClick={that.mailSettingsView}>
						<i className="fa fa-envelope fa-2x fa-green"></i>
						</button>
						<span className="sourcetitle" id="mails">{lang["admin.mailbtn"]}</span>
				</div>
				</div>
			<div className="col-xs-4 col-sm-2 col-lg-1 col-md-1 hvac">
					<div className="sourcediv">
						<button className="sourceitem emaildisclaimermain" id="mailsettingsmainsladap" onClick={that.emailDisclaimerView}>
							<i className="fa fa-comment fa-2x fa-green"></i>
						</button>
						<span className="sourcetitle" id="emaildisclaimericon">{lang["admin.emailbtn"]}</span>
					</div>
				</div>
				{(that.currentRole!="ROLE_Super_Admin")?
				<div className="col-xs-4 col-sm-2 col-lg-1 col-md-1 hvac">
					<div className="sourcediv">
						<button className="sourceitem passwordpolicysettingsmain" id="mailsettingsmainsladap" onClick={that.passwordPolicyView}>
							<i className="fa fa-key fa-2x fa-green"></i>
						</button>
						<span className="sourcetitle" id="passwordpolicy">{lang["admin.pwdpolicybtn"]}</span>
					</div>
				</div>:null
				}
				<div className="col-xs-4 col-sm-2 col-lg-1 col-md-1 hvac">
								<div className="sourcediv ldap">
									<button  className="sourceitem ldapsettingsmain" id="ldapsettingsmainsladap" onClick = {that.ldapView}>
										<i className="fa fa-sitemap fa-2x fa-green"></i>
									</button>
									<span className="sourcetitle" id="ldaps">{lang["admin.ldapbtn"]}</span>
								</div>
				</div>

				<div className="col-xs-4 col-sm-2 col-lg-1 col-md-1 hvac">
					<div className="sourcediv">
						<button className="sourceitem oauthsettingsmain" id="mailsettingsmainsladap" onClick={that.samlView}>
							<i className="fa fa-unlock-alt fa-2x fa-green"></i>
						</button>
						<span className="sourcetitle" id="oauth2">{lang["admin.samlbtn"]}</span>
					</div>
				</div>
				
				{/* {(that.currentRole!="ROLE_Super_Admin")?
				<div className="col-xs-4 col-sm-2 col-lg-1 col-md-1 hvac">
					<div className="sourcediv">
						<button className="sourceitem syncconfig" id="syncconfigmain" onClick={that.syncConfiguarations}>
							<i className="fa fa-refresh fa-2x fa-green"></i>
						</button>
						<span className="sourcetitle" id="syncconfigs">{lang["admin.syncbtn"]}</span>
					</div>
				</div>:null
			}  */}
			</div>
			<div className="clear"></div>
			<div className="container-fluid othercontainer ldapcontainer othersettingscontainer">
			</div>
		</div>
</div>
        );
    }
}