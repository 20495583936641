import React, { Component } from "react";
import _ from 'underscore';
import { lang } from "../js/helpers/utils/languages/language";

export default class DataHubSyncLogBookHistoryBootstrapTemplate extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        var that = this.props;
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="marg-top-10">
                        <div className="clear"></div>
                        <div className="container-fluid">
                            <div className="auditpanels syncloghistory">
                                <div className="notifications-table-container">
                                    <div className="bootstrap-table">
                                        <div className="fixed-table-toolbar">
                                            <div className="pull-left search">
                                                <input className="form-control searchsyncloghistorytable" type="text"
                                                    placeholder={lang["datahub.search"]} defaultValue={that.search} />
                                            </div>
                                            <span className="pull-right">
                                                <span className="daterange-center" id="daterange-filter">
                                                    <i className="glyphicon glyphicon-calendar fa fa-calendar"></i>
                                                    <b className="caret"></b>
                                                    <span></span>
                                                </span>
                                            </span>
                                        </div>
                                        <div className="notifications-table-container marg-top-10">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        {that.keys.map((value, index) => {
                                                            return (
                                                                that.dataTypeslist[value] == 'number' ?
                                                                    <th key={index} data-field={value} className="align-right">{that.headerlist[value]}</th> :
                                                                    value === 'sync_time_taken' ?
                                                                        <th key={index} data-field={value} className="align-right">{that.headerlist[value]}</th> :
                                                                        <th key={index} data-field={value}>{that.headerlist[value]}</th>
                                                            )
                                                        })}
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                        <div className="notifications-table-container">
                                            <div className="bootstrap-table">
                                                <div className="fixed-table-pagination">
                                                    <div className="pull-left pagination-detail">
                                                        <span className="pagination-info">{lang["datahub.showing"]} </span> <span
                                                            className="page-list"> <span className="btn-group dropup">
                                                                <button type="button"
                                                                    className="btn btn-default  dropdown-toggle syncloghistoryrowlimtbutton"
                                                                    id="synclogtablerecords" data-toggle="dropdown">
                                                                    <span className="page-size">25</span> <span className="caret"></span>
                                                                </button>
                                                                <ul className="dropdown-menu syncloghistoryrowlimtul" role="menu">
                                                                    <li value="10"><a href="javascript:void(0)">10</a></li>
                                                                    <li className="active" value="25"><a href="javascript:void(0)">25</a></li>
                                                                    <li value="50"><a href="javascript:void(0)">50</a></li>
                                                                    <li value="100"><a href="javascript:void(0)">100</a></li>
                                                                </ul>
                                                            </span> {lang["datahub.records"]}</span>
                                                    </div>
                                                    <div className="pull-right pagination">
                                                        <ul id="pagination-view-history" className="pagination-sm"></ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}