import React, { Component } from "react";
import ReactDOM from "react-dom";
import _ from 'lodash';
import { getMessage } from '../js/helpers/utils/BirdMessage';
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;

export default class AddMeasuretoMeasureHierarchyModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            xaxiscolumnDisplayName: props.xaxiscolumnDisplayName,
            xaxiscolumnName: props.xaxiscolumnName,
            xaxiscolumnType: props.xaxiscolumnType,
            xaxistableName: props.xaxistableName,
            agg: props.agg
        };
        this.state.isDirect = ChartInfo.functions.getIsDirect();
        if ($('.customhierarchyattributes li').length == 0 && this.state.xaxiscolumnType == 'custommeasurehierarchy') {
            $(".spinner").hide();
            $(".gridspinner").hide();
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(getMessage('BirdWarning65'));
        } else {
            this.render();
        }
    }
    render() {
        var today = new Date();
        var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
        $('.customhierarchyattributes').append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
        ReactDOM.render(<AddToMeasureHierarchyModal
            xaxiscolumnDisplayName={this.state.xaxiscolumnDisplayName} xaxiscolumnName={this.state.xaxiscolumnName}
            xaxiscolumnType={this.state.xaxiscolumnType} xaxistableName={this.state.xaxistableName} agg={this.state.agg} percentilevalue={this.props.percentilevalue} isDirect={this.state.isDirect}
        ></AddToMeasureHierarchyModal>, document.getElementById(dynamicdiv));
        return (<div></div>);
    }
}
class AddToMeasureHierarchyModal extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        var that = this.props;
        var dataType = that.xaxiscolumnType;
        var dataIcon =  lang["storyboard.abc"];
        if (dataType == "number") {
            dataIcon = "123"
        } else if (dataType == "date" || dataType == "datetime" || dataType === "month" || dataType === "day") {
            dataIcon = <i className='fa fa-calendar'></i>
        } else if (dataType == "customhierarchy" || dataType === "custommeasurehierarchy") {
            dataIcon = <i className='fa fa-list'></i>
        }
        return (<li className="boc-list-item singleliitems" id="itemdisplay" data-name={that.xaxiscolumnName} percentilevalue={that.percentilevalue} title={that.xaxiscolumnDisplayName}
            data-type={that.xaxiscolumnType} table-name={that.xaxistableName} agg={(that.agg == '' ? 'Count' : that.agg)}>
            {/* <!-- addmeasuretomeasurehierarchymodal.js --> */}
            <span className="itemtype">{dataIcon}</span>
            <span className="itemname">{that.xaxiscolumnDisplayName}</span>
            {(that.xaxiscolumnType != 'custommeasurehierarchy') ? (
                <>
                    <span className="boclabel boclabel-right-5 marg-righ-15 aggregationtext" aria-expanded="false" data-toggle="dropdown" type="button" id="dropdownaggregation">
                        <span>{(that.agg == "" ? 'Count' : that.agg)}</span>&nbsp;<i className="fa fa-angle-down"></i>
                    </span>
                    <ul role="menu" className="dropdown-menu dropdown-inline ">
                        {(that.xaxiscolumnType != 'string' && that.xaxiscolumnType != 'date' && that.xaxiscolumnType != 'month' && that.xaxiscolumnType != 'day') ? (
                            <> <li className="aggregation-li-item aggsum" onClick={this.changeyAxisAggregation} title={lang["storyboard.sum"]}>{lang["storyboard.sum"]}</li>
                                <li className="aggregation-li-item aggmin" onClick={this.changeyAxisAggregation} title={lang["storyboard.minimum"]}>{lang["storyboard.minimum"]}</li>
                                <li className="aggregation-li-item aggmax" onClick={this.changeyAxisAggregation} title={lang["storyboard.max"]}>{lang["storyboard.max"]}</li>
                                <li className="aggregation-li-item aggavg" onClick={this.changeyAxisAggregation} title={lang["storyboard.avg"]}>{lang["storyboard.avg"]}</li>
                                <li className="aggregation-li-item aggstandarddeviation" onClick={this.changeyAxisAggregation} title={lang["storyboard.standard"]}>{lang["storyboard.standard"]}</li>
                                <li className="aggregation-li-item aggpercentile" onClick={this.changeyAxisAggregation} title={lang["storyboard.percentile"]}>{lang["storyboard.percentile"]}
                            <span className="opacity-effect" >
                                        <input type="number" className="percentilebox" placeholder="1" min="25" max="100" defaultValue={that.percentilevalue} /></span>
                                </li>
                                <li className="aggregation-li-item aggmedian" onClick={this.changeyAxisAggregation} title={lang["storyboard.median"]}>{lang["storyboard.median"]}</li></>
                        ) : null}
                        {(that.xaxiscolumnType == 'date') ? (
                            <>
                                <li className="aggregation-li-item aggmin" onClick={this.changeyAxisAggregation} title={lang["storyboard.minimum"]}>{lang["storyboard.minimum"]}</li>
                                <li className="aggregation-li-item aggmax" onClick={this.changeyAxisAggregation} title={lang["storyboard.max"]}>{lang["storyboard.max"]}</li></>
                        ) : null}
                        <>
                            <li className="aggregation-li-item aggcountdistinct" onClick={this.changeyAxisAggregation} title={lang["storyboard.countdis"]}>{lang["storyboard.countdistinct"]}</li>
                            <li className="aggregation-li-item aggcount" onClick={this.changeyAxisAggregation} title={lang["storyboard.count"]}>{lang["storyboard.countall"]}</li></>
                    </ul>
                </>
            ) : null}
            <span className="btn-group pull-right">
                <button className="btn btn-transparent deletehierarchyfield" onClick={this.deleteelement} type="button">
                    <i className="fa fa-trash"></i>
                </button>
            </span>
        </li>);
    }
    deleteelement(e) {
        var $this = e.currentTarget;
        $($this).parent().parent().parent().remove()
    }
    changeyAxisAggregation(e) {
        $(e.currentTarget).parent().parent().find(".aggregation-li-item").removeClass("active");
        $(e.currentTarget).addClass("active");
        $(e.currentTarget).parent().parent().find(".aggregationtext>span").text($(e.currentTarget).text());
        var percentileValue = $(e.currentTarget).parent().parent().find(".percentilebox").val();
        $(e.currentTarget).parent().parent().attr("percentile-value", percentileValue);
        $(e.currentTarget).parent().parent().attr("percentilevalue", percentileValue);
        $(e.currentTarget).parent().parent().attr("data-agg", $(e.currentTarget).attr("title"));
        $(e.currentTarget).parent().parent().attr("agg", $(e.currentTarget).text().trim());
    }
}