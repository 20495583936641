import React from 'react'
import { lang } from "../../js/helpers/utils/languages/language";
export default class SamrtInsightsSettingsViewTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        var that = this.props;
        return (
            // <!-- smartinsightssettingsview.js -->
            <div className="container-fluid newslideoutsmartinsightattributes">
                <div className="row">
                    <div className="col-xs-12">
                        <h4 className="modal-title"><i className="fa fa-smartinsights-small"></i> {lang["smartsights.sightsettings"]}<button className="pull-right btn btn-sm btn-transparent cancelmodal" onClick={that.smartinsightoptions}><i className="fa fa-times"></i></button></h4>
                    </div>
                </div>
                <div className="row smartinsights-attributes-keyinfo-row">
                    <div className="col-xs-12">
                        <div className="col-xs-12 marg-top-15">
                            <p><span>{lang["smartsights.datasource"]} :&nbsp; <b> {that.dataSource}</b></span></p>
                            <p><span>{lang["smartsights.totaldataset"]} :&nbsp; <b>{that.totalDBRecords}</b></span></p>
                            <p><span>{lang["smartsights.sampleset"]} :&nbsp; <b>{that.totalRecords}</b></span></p>
                            <p><span>{lang["smartsights.setcolumns"]} :&nbsp; <b>{that.columnSize}</b></span></p>
                        </div>
                    </div>
                </div>
                <div className="row smartattributestabscontainerrow">
                    <div className="col-xs-12 smartinsihtstabscontainer modal-body">
                        <div role="tabpanel" className="smartinsighttabpanelcontainer">
                            <ul className="nav nav-tabs tabcontainer axissettings-tab-container" role="tablist">
                                <li role="presentation" className="tabheadli active" title="">
                                    <a href="#smartinsightelements" aria-controls="smartinsightelements" role="tab" data-toggle="tab">{lang["smartsights.discoverelem"]}</a>
                                </li>
                            </ul>
                            <div className="tab-content">
                                {/* <!---First Tab Start--> */}
                                <div role="tabpanel" className="tab-pane active boc-snapshot-container tabviewitems boc-gray-inner" id="smartinsightelements">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 smartinsightaxissetting settingscontent">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 pull-left text-right actionscontainercolumn">
                        <button type="button" onClick={that.smartinsightoptions} className="btn btn-bird cancelmodal"><i class="fa fa-times"></i> {lang["smartsights.Cancel"]}</button>
                        <button type="button" className="btn btn-bird runsmartinsights" onClick={that.runsmartinsights} id="runsmartinsights" aria-hidden="true"><i class="fa fa-refresh"></i> {lang["smartsights.run"]}</button>
                    </div>
                </div>
            </div>
        );
    }
}