import React from 'react';
import { lang } from "../js/helpers/utils/languages/language";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
const $ = window.$;

export default class PreviewDataTable extends React.Component {
    renderShowsTotal(start, to, total) {
        return (
            <h6 style={{ margin: "0", padding: "0 8px", color: "#00abab" }}>
               {lang["workspace.preview"]} { to + 1 - start} {lang["workspace.rows"]}
            </h6>
        );
    }
    render() {
        const options = {
           // paginationShowsTotal: this.renderShowsTotal
           sizePerPage: 10
        }
        return (
            <React.Fragment>
            <h6 style={{ margin: "0", padding: "0 8px", color: "#00abab" }}>
            {lang["workspace.preview"]} { this.props.dataArray.length} {lang["workspace.rows"]}
            </h6>    
            <BootstrapTable data={this.props.dataArray} options={options} bordered={ false } striped hover pagination maxHeight={'calc(100% - 60px)'}> 
                {
                    this.props.opts.columns.map((column, index) => {
                        return (<TableHeaderColumn width={'150px'}  className={column.type==='number'?'align-right':''} thStyle={{textAlign:column.type==='number'?'right':'left'}}
                        tdStyle={ { whiteSpace: 'normal', minWidth:'100px', maxWidth:'200px',textAlign:column.type==='number'?'right':'left' } } isKey={(index === 0) ? true : false} dataField={column.name} dataSort={true}>{column.field}</TableHeaderColumn>);
                    })
                }
            </BootstrapTable>
            </React.Fragment>
        );
    }
}