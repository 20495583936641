import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'underscore';
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import SendRequest from '../../SendRequest';
import * as SmartCharts from './smartinsightcharts'
import SmartInsightsSettingsViewTtemplate from './smartinsightssettings.jsx'
import AxisSettingView from './smartinsightattraxissettingview'
import SmartInsightsSimulationView from './smartinsightssimulationview'
import YAxisAttrView from '../yaxisattributesview';
import { getMessage } from '../../js/helpers/utils/BirdMessage';

const $ = window.$;
export default class SamrtInsightsSettingsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.state.dynamicdiv = this.props.dynamicdiv;
        this.state.measures = this.props.measures;
        this.state.dimensions = this.props.dimensions;
        this.state.targetColumn = this.props.targetColumn;
        this.state.totalRecords = this.props.totalRecords;
        this.state.totalDBRecords = this.props.totalDBRecords;
        this.state.schemaType = this.props.schemaType;
        this.state.chartnumber = this.props.chartnumber;
        this.state.dataSource = this.props.dataSource;
        this.state.tabid = this.props.tabid;
        this.render();
    }
    render() {
        ReactDOM.render(<SmartInsightsSettingsViewTtemplate runsmartinsights={this.runsmartinsights} smartinsightoptions={this.props.smartinsightoptions} columnSize={this.state.dimensions.length} totalRecords={this.state.totalRecords} totalDBRecords={this.state.totalDBRecords} schemaType={this.state.schemaType.toUpperCase()} dataSource={this.state.dataSource != null && this.state.dataSource != '' ? this.state.dataSource.toUpperCase() : ""} />, document.getElementById(this.state.dynamicdiv));
        var today = new Date();
        var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
        $(".smartinsightaxissetting").append("<div className='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
        var axisContent = new AxisSettingView({
            dynamicdiv: dynamicdiv,
            measures: this.state.measures,
            dimensions: this.state.dimensions,
            targetColumn: this.state.targetColumn,
            chartnumber: this.state.chartnumber
        });
        $(".smartinsightaxissetting").html(document.getElementById(dynamicdiv));
        var columnDisplayName = this.props.targetColumn;
        sessionStorage.setItem('currentChartNumberForSort', '');
        var that = this;
        _.each(that.props.measures, function (value) {
            if (value.columnName == that.props.targetColumn) {
                columnDisplayName = value.columnDisplayName;
            }
        });

        var columnName = this.props.targetColumn;
        var datatype = "number";
        var aggregation = "";
        var tableName = this.props.targetColumn;
        var percentileValue = "";
        //	var data = JSON.parse(ChartInfo.getChartDetails(this.props.chartnumber));
        var activeChartType = $('.charttype.active').attr("id");
        if (this.props.targetColumn != undefined && this.props.targetColumn != '') {
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $(".smartyaxisattributes").append("<div className='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");

            var yAxisAttrView = new YAxisAttrView({
                dynamicdiv: dynamicdiv,
                yaxiscolumnDisplayName: columnDisplayName,
                yaxiscolumnName: columnName,
                yaxiscolumnType: datatype,
                yaxisaggregation: aggregation,
                yaxistableName: tableName,
                percentileValue: percentileValue,
                measures: this.state.measures,
                viewmode: 'smartvalues'
            });
            $(".smartyaxisattributes").html(document.getElementById(dynamicdiv));
        }
        return (<div></div>);
    }
    runsmartinsights = () => {
        var globalfilterArray = ChartInfo.functions.getFilterArray();
        var exclueValues = '';
        var targetCol = $(".smartyaxisattributes li").attr("data-name");
        if (this.props.isFrom != undefined && this.props.isFrom == 'refreshCharts' && this.state.targetColumn != undefined && this.state.targetColumn != '') {
            targetCol = this.state.targetColumn;
        }
        if (targetCol == undefined || targetCol == "") {
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Information');
            $('.birdmessage-info').empty().text("Please select Target column");
        } else {
            $(".smartinsightintro-container").removeClass("hide");
            $(".rightsmartattributes").removeClass("open");
            var renderto;
            _.each($(".smartxaxisattributes li"), function (listObj) {
                var exclueValue = $(listObj).attr("data-name");
                exclueValues += exclueValue + ",";
            });
            if (exclueValues != undefined) {
                exclueValues = exclueValues.substring(0, exclueValues.length - 1);
            }
            var that = this;
            let requestHeaders = {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            var storyboardSmartInsightFilterArray = ChartInfo.functions.getStoryboardSmartInsightFilterArray();
            globalfilterArray = globalfilterArray.concat(storyboardSmartInsightFilterArray);
            var reqdata = new FormData();
            reqdata.set("datamodelId", that.props.datamodelId);
            reqdata.set("schemaType", that.state.schemaType);
            reqdata.set("globalfilterArray", JSON.stringify(globalfilterArray));
            reqdata.set("reportId", that.props.reportId != undefined && that.props.reportId != null ? that.props.reportId : "");
            reqdata.set("keyInfluencers", false);
            reqdata.set("excludecolumn", exclueValues);
            reqdata.set("targetColumn", targetCol);
            reqdata.set("mapForGetDuplicateName", null);
            reqdata.set("isFromRunModel", true);
            let selectedColumnsval = ChartInfo.functions.getSmartinsigtSelectedColumns();
            reqdata.set("selectedColumns",JSON.stringify(selectedColumnsval));
            reqdata.set("customFields",sessionStorage.getItem("customFields"))

            SendRequest({
                url: "getsmartinsights",
                body: reqdata,
                sucessFunction: (response) => {
                    if (response.hasOwnProperty('error')) {
                        $(".smartinsightintro-container").addClass("hide");
                        $(".spinner").hide();
                        $(".gridspinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.errorTrace)
                    } else {
                        that.state.collection = response;
                        var smartInsightDataObj = {};
                        smartInsightDataObj["excludeColumn"] = exclueValues;
                        smartInsightDataObj["targeColumn"] = targetCol;
                        smartInsightDataObj["smarinsightData"] = JSON.stringify(that.state.collection);
                        ChartInfo.functions.setSmartinsightDataObj(smartInsightDataObj);
                        $(".influenceschart").empty();
                        ChartInfo.functions.setSmartInsightsExcludeValues(exclueValues);
                        ChartInfo.functions.setSmartInsightsTargetVal(targetCol);
                        $(".smartinsightintro-container").addClass("hide");
                        var yaxisname;

                        var insightdata = [that.state.collection[1].insightQuality];

                        var categories = that.state.collection[0].correlation[0];
                        var correlatedcolumns = that.state.collection[0].correlation[1].data;
                        var keyinfluencers = that.state.collection[1].keyinfluencers.data;
                        var keyinfluencersCategories = that.state.collection[1].keyinfluencers.categories;
                        var targetColumn = that.state.collection[1].targetcolumn;
                        var modelName = that.state.collection[1].modelname;
                        var frameName = that.state.collection[1].framename;
                        var targetColumnOriginal = that.state.collection[1].targetColumnOriginal
                        that.state.modelName = modelName;
                        that.state.frameName = frameName;

                        var outlierCount = that.state.collection[1].outliercount;
                        var correlatedCoumnsCount = that.state.collection[0].correaltionCount;
                        var keyInfluencersCount = keyinfluencersCategories.length;

                        var predictiondata = that.state.collection[1].predictiondata;
                        var predictiondataLables = that.state.collection[1].predictionlabel;
                        let chiSquareData = that.state.collection[10].chiSquareData[1].data;
                        let chisquarecategories = that.state.collection[10].chiSquareData[0];
                        _.each(that.state.collection[2], function (data) {
                            $(".insightnlg .influenceschart").append(data);
                        });
                        that.state.htmldrilldata = $(".insightnlg .influenceschart").html();

                        /*Upate Initial Titles*/
                        $(".whatinfluences>span").text(targetColumn + " ?");
                        $(".insightavg>h3").html(targetColumn + " <span></span>");
                        $(".insightavgsuggestion1>h3").html("Key Influencers");
                        $(".insightavgsuggestion2>h3").html(targetColumn + " <span></span>");
                        $(".insightnlg>h3>span, .simulationwith>span, .expectedvaluecontainer>h3>span").text(targetColumn);

                        /*var findingsdata = [{
                            name: 'Correlated Columns',
                            y: correlatedCoumnsCount,
                        }, {
                            name: 'Key Influences',
                            y: keyInfluencersCount
                        }, {
                            name: 'Outliers',
                            y: outlierCount
                        }]*/

                        var jsondata = [predictiondata, { "sourceColumns": predictiondataLables }
                        ]
                        //\Sample Data Sets

                        //DrawCharts
                        var renderto;
                        $("#smartinsights0").show()
                        renderto = $(".insightquality .overviewchart");
                        SmartCharts.charts.drawSmartSolidgaugeChart(insightdata, renderto);

                        /*SmartCharts.charts.drawSmartPieChart(findingsdata, renderto);*/
                        renderto = $(".mlchisquaretable .overviewchart");
                        if (chiSquareData != undefined && chiSquareData != null && chisquarecategories != undefined && chisquarecategories.length > 0) {
                            SmartCharts.charts.drawSmartBubbleChart(chiSquareData, chisquarecategories, renderto);
                        }else if (chiSquareData != undefined && chiSquareData != null && chiSquareData[1] != undefined && chiSquareData[1].length > 0) {
                            let chisquredata = chiSquareData[1];
                            $(".mlchisquaretable .overviewchart").append("<span class='mlnodatamessage'>" + chisquredata[0] + "</span>")
                        }
                        else {
                            $(".mlchisquaretable .overviewchart").append("<span class='mlnodatamessage'>" + getMessage('BirdInformation48') + "</span>");
                        }


                        renderto = $(".insightcorrelatedcolumns .overviewchart");
                        $(".insightcorrelatedcolumns .overviewchart").empty();
                        if (correlatedcolumns != undefined && correlatedcolumns != null && categories[0] != undefined && categories[0].length > 0) {
                            SmartCharts.charts.drawSmartBubbleChart(correlatedcolumns, categories, renderto);
                        }else if (correlatedcolumns != undefined && correlatedcolumns != null && categories[1] != undefined && categories[1].length > 0) {
                            $(".insightcorrelatedcolumns .overviewchart").append("<span class='mlnodatamessage'>" + correlatedcolumns[0] + "</span>")
                        }
                        else {
                            $(".insightcorrelatedcolumns .overviewchart").append("<span class='mlnodatamessage'>" + getMessage('BirdInformation49') + "</span>")
                        }
						/*renderto = $(".insightcovariance .overviewchart");
						SmartCharts.charts.drawSmartHeatmap(covarcolumns,categories,renderto);*/

                        renderto = $(".keyinfluencers .influenceschart");
                        SmartCharts.charts.drawSmartBar(keyinfluencers, keyinfluencersCategories, renderto, targetColumn, modelName, frameName, that.state.htmldrilldata, targetColumnOriginal);

                        renderto = $(".simulationselectioncontainer .simulationwaterfall");
                        SmartCharts.charts.drawSmartSimulationWaterfall(that.state.collection[3].data, that.state.collection[3].data, renderto, targetColumn, modelName, frameName);

                        $(".outliertable").bootstrapTable("destroy");
                        $(".outliertable thead>tr").empty();
                        _.each(jsondata[1].sourceColumns, function (i, j) {
                            var  j=_.invert(i);
                             _.each(j,function(val){
                             $(".outliertable thead>tr").append("<th data-sortable='true' data-field='" + val + "' class='"+(i[val]==='number'?"align-right":"")+"'>" + val + "</th>");
                             });
                         });

                        $(".outliertable").bootstrapTable();
                        $(".outliertable").bootstrapTable("load", { data: jsondata[0].data });
                        ChartInfo.functions.setRdisplyColumnMap(JSON.stringify(that.state.collection[3].mapForGetDuplicateName));

                        that.simulationsection();
                        $(".rightsmartattributes").removeClass("open");

                    }
                },
                rejectFunction: this.reportDataError,
                headers: requestHeaders
            });
        }
    }
    simulationsection() {
        var simulationdata = this.state.collection[3];
        var that = this;
        var today = new Date();
        var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
        $(".simulationcselections").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");

        var smartInsightsSimulationView = new SmartInsightsSimulationView({
            dynamicdiv: dynamicdiv,
            measures: that.state.measures,
            dimensions: that.state.dimensions,
            reportId: that.props.reportId,
            datamodelId: that.props.datamodelId,
            data: simulationdata,
            modelName: that.state.modelName,
            frameName: that.state.frameName,
            targetColumn: that.state.collection[0].targetColumn,
            tabid: that.state.tabid,
            keyinfluencersList:that.state.collection[1].KeyInfluencerColumnsList,
        });
        $(".simulationcselections").html(document.getElementById(dynamicdiv));

        /*Expected Value*/
        //   $(".expectedvalue>span.expectedsymbol").html("+");
        //   $(".expectedvalue>span.expectednumber").html("350");

    }
}
