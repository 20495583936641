/**
* 
* Module: Bird
* File Name: srilanka.js
* author: Sriyam Software Solutions
* Created Date: 2019-07-24
* Last Modified Date: 2020-01-06
* copy: Source Code Copyright to Sriyam Software Solutions LLP.
* 
*/
var geoSLCodes={'Batticaloa':[7.730997,81.674730],
		'Mannar':[8.980974,79.904415],
		'Jaffna':[9.661498,80.025547],
		'Kilinochchi':[9.380289,80.377000],
		'Ampara':[7.301756,81.674730],
		'Kandi':[23.946800,88.049714],
		'Matale':[7.467465,80.623416],
		'Nuwara Eliya':[6.949717,80.789107],
		'Polonnaruwa':[7.940338,81.018798],
		'Trincomalee':[8.587364,81.215212],
		'Anuradhapura':[8.311352,80.403651],
		'Vavuniya':[8.754203,80.498240],
		'Mulativ':[9.199583,80.588280],
		'Kurunegala':[7.472981,80.354729],
		'Kegalle':[7.251332,80.346375],
		'Puttalam':[8.040791,79.839386],
		'Ratnapura':[6.705574,80.384734],
		'Galle':[6.053519,80.220977],
		'Matara':[5.954920,80.554956],
		'Mullaitivu':[9.243092,80.765991],
		'Badulla':[6.993401,81.054981],
		'Kalutara':[6.585395,79.960740],
		'Monaragala':[6.86508, 81.35101],
		'Nuwara-Eliya':[6.949717,80.789107],
		'Kegalla':[7.251332,80.346375],
		'Kandy':[7.290572,80.633726],
		'Gampaha':[7.087310,80.014366],
		'Colombo':[6.927079,79.861243],
		'Sri Jayawardenepura':[6.894003,79.905156],
		'Pitipana Homagama':[6.844698,80.015101],
		'Malabe':[6.906079,79.969628],
		'Talawatugoda':[6.875865,79.939194],
		'Hokandara':[6.874319,79.969628],
		'Hambantota':[6.13714, 81.10245],
		'Battaramulla':[6.897994,79.922287],
		'Buttala':[6.75974, 81.24939],
		'Angoda':[6.936741, 79.927032],
		'Tissamaharama':[6.277484, 81.286362],
		'Gampola':[7.131043, 80.565659],
		'Koggala':[5.991491, 80.325531],
		'Tangalle':[6.028849, 80.785576],
		'Nilaveli':[8.692733, 81.188530],
		'Peliyagoda':[6.960784, 79.922676],
		'Negombo':[7.189464, 79.858734],
		'Galkissa':[6.8293, 79.863],
		'Ambalangoda':[6.2355, 80.0538],
		'Bentota':[6.42598, 79.99575],
		'Beruwala':[6.4788, 79.9828],
		'Dambulla':[7.86, 80.65167],
		'Eravur Town':[7.7782, 81.6038],
		'Hanwella Ihala':[6.9012, 80.0852],
		'Hatton':[6.8916, 80.5955],
		'Hendala':[6.9909, 79.883],
		'Kalmunai':[7.40902, 81.83472],
		'Kandana':[7.048, 79.8937],
		'Katunayaka':[7.16992, 79.88837],
		'Kelaniya':[6.9553, 79.922],
		'Kolonnawa':[6.9329, 79.8848],
		'Kotikawatta':[6.9269, 79.9095],
		'Kuliyapitiya':[7.4688, 80.0401],
		'Maharagama':[6.848, 79.9265],
		'Minuwangoda':[7.1663, 79.9533],
		'Mulleriyawa':[6.933, 79.9297],
		'Panadura':[6.7132, 79.9026],
};


export function getGeoSLCodes(location) {
	 if (geoSLCodes.hasOwnProperty(location)) {
		 var temp=geoSLCodes[location];
	  return temp;
	 } else {
		 return "";
	 }
	}