import React from "react";
import PubSub from 'pubsub-js';
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;

export default class CustomMeasureModalTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    PreviewData(e,name,dispalyname,datatype){
        var appObj={};
        appObj["name"]=name;
        appObj["displayname"]=dispalyname;
        appObj["datatype"]=datatype;
        appObj["target"]=e;

        PubSub.publish("previewcolumndata",appObj);
    }
    render() {
        var thiscopy = this;
        var that = this.props;
        return (
            //    <!-- Custom Measure Attributes createcustommeasure.js -->
            <div className={`modal fade left createcustomaggregatedfield${that.chartnumber} in`} id="createcustomaggregatedfield" tabindex="-1" role="dialog" aria-labelledby="createcustomaggregatedfield" style={{ display: "block" }}>
                <div className="modal-backdrop fade in"></div>
                <div className="modal-dialog modal-lg modal-dialog-lg-center modal-dialog-center" style={{ zIndex: "1050" }}>
                    <div className="modal-content">
                        <div className="modal-header boc-modal-hf-padding">
                            <button type="button" className="close white" id="custommeasureclose" onClick={that.closeCustomMeasure} aria-hidden="true" data-dismiss="modal">&times;</button>
                            <h4 className="modal-title"><i className="marg-righ-10 fa-orange">{lang["storyboard.fx"]}</i> {lang["storyboard.createmeasure"]}</h4>
                        </div>
                        <div className="modal-body scrollset">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="row-same-height">
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-xs-height settingscontent">
                                            <div className="modal-height-fix">
                                                <div className="container-fluid"><legend className="subhead">{lang["storyboard.custommeasure"]}</legend><input type="name" id="customaggregatedfieldname" defaultValue={that.name} className="modal-form-fields" placeholder={lang["storyboard.customname"]} /></div>
                                                <span className='customaggregatedfieldname-error help-block marg-left-10 hide'>{lang["storyboard.validname"]}</span>
                                                <span className='customaggregatedfieldname-alreadyexist-error help-block marg-left-10 hide'>{lang["storyboard.nameesxists"]}</span>
                                                <div className="marg-top-10" style={{ height: 'calc(100% - 120px)', overflow: "visible" }}>
                                                    <div className="container-fluid marg-top-5">
                                                        <legend className="subhead">{lang["storyboard.availfields"]}</legend>
                                                        <input className="form-control form-control-custom-field search-image" onKeyUp={this.searchField} placeholder={lang["storyboard.search"]} id="searchinput" type="text" />
                                                    </div>
                                                    <ul className="list-group entitytype">
                                                        {that.dimensions.map( (dimension, index)=> {
                                                            var dataType = dimension.tableDisplayType;
                                                            var dataIcon =  lang["storyboard.abc"];
                                                            if (dataType == "number") {
                                                                dataIcon = 123
                                                            } else if (dataType == "date" || dataType == "datetime" || dataType === 'month' || dataType === 'day') {
                                                                dataIcon = <i className='fa fa-calendar'></i>
                                                            }
                                                            return <li key={index} className="boc-list-item singleliitems customaggregatedfield" data-name={dimension.columnName} doc-name={`doc[${dimension.columnDisplayName}]`} data-type={dimension.tableDisplayType} title={dimension.columnDisplayName}>
                                                                <span className="itemtype">{dataIcon}</span>
                                                                <span className="itemname">{dimension.columnDisplayName}</span>
                                                                <span type="button" className="boclabel boclabel-right-5 opacity-effect previewcolumndata" data-name={dimension.columnName} data-type={dimension.tableDisplayType} data-displayname={dimension.columnDisplayName} title={lang["storyboard.previewdata"]} onClick={(e)=>{this.PreviewData(e,dimension.columnName,dimension.columnDisplayName,dataType)}}> <i className="fa fa-table"></i></span>
                                                            </li>
                                                        })}
                                                        {that.customfields.map(function (field, index) {
                                                            var dataType = field.dataType;
                                                            var dataIcon =  lang["storyboard.abc"];
                                                            if (dataType == "number") {
                                                                dataIcon = "123"
                                                            } else if (dataType == "date" || dataType == "datetime" || dataType === 'month' || dataType === 'day') {
                                                                dataIcon = <i className='fa fa-calendar'></i>                                                            }
                                                            return <li key={index} className="boc-list-item singleliitems customaggregatedfield" data-name={field.name} doc-name={field.name} data-type={field.dataType} title={field.name}>
                                                                <span className="itemtype fx">{dataIcon}</span>
                                                                <span className="itemname">{field.name}</span>
                                                                <span type="button" className="boclabel boclabel-right-5 opacity-effect previewcolumndata" data-name={field.name} data-type={field.dataType} data-displayname={field.name} title={lang["storyboard.previewdata"]} onClick={(e)=>{thiscopy.PreviewData(e,field.name,field.name,field.dataType)}}> <i className="fa fa-table"></i></span>
                                                            </li>
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="gap"></div>
                                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 col-xs-height settingscontent">
                                            <div className="modal-height-fix">
                                                <div className="col-xs-12 spsidemenu-height-100">
                                                    <legend className="subhead">{lang["storyboard.formula"]}</legend>
                                                    <div className="marg-top-10">
                                                        <div contentEditable="true" id={`customaggregatedfieldquerydisplay${that.chartnumber}`} className="resize-vertical custommeasurequerydisplay">{that.script}</div>
                                                        <button type="button" id="validateformula" onClick={that.testformula} className="btn pull-right testformula marg-top-5 marg-left-5"><i className="fa fa-play"></i> {lang["storyboard.validate"]}</button>
                                                        <button type="button" className="btn pull-right clearcustommeasuretext marg-top-5" onClick={that.clearQueryArea} id="clearcustommeasures"><i className="fa fa-cut fa-rotate-270"></i> {lang["storyboard.clear"]}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer boc-modal-hf-padding">
                            <button type="button" className="btn btn-bird" onClick={that.closeCustomMeasure} id="birdcustomaggres" data-dismiss="modal"><i className="fa fa-times"></i> {lang["storyboard.cancelbtn"]}</button>
                            <button type="button" className="btn btn-bird applycustomaggregatedfield" onClick={that.createCustomFilter} id="applycustomaggregatedfields" ><i className="fa fa-check"></i> {lang["storyboard.applybtn"]}</button>
                        </div>
                    </div>
                    {/* <!-- /.modal-content --> */}
                </div>
                {/* <!-- /.modal-dialog --> */}
                {/* <!-- /.Maximize Screen Attributes Settings -->
               <!-- Custom Measure Attributes --> */}
            </div>
        );
    }
    searchField(e){
        var $this= e.currentTarget;
        var valThis = $($this).val().toLowerCase();
        $(".itemname").parents(".customaggregatedfield").each(function(){
            var text =  $(this).attr("title").toLowerCase();
            (text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
        });
    }
}