import React, { Component } from "react";
import ReactDOM from 'react-dom'
import SendRequest from '../SendRequest';
import _ from 'lodash';
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import DrillDownAllSettingsTemplate from './DrilldownallSettings.jsx'
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;

export default class DrillDownAllSettingsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        var that = this;
        this.state.userReportsCollection = [];
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getallreportsdata",
            queryString: "?reportId= " + that.props.reportId,
            sucessFunction: props => {
                that.state.userReportsCollection = props;
                that.render();
            },
            rejectFunction: () => { },
            headers: requestHeaders
        });
    }
    render() {

        $('.pie-menu').remove();
        var customFields = JSON.parse(sessionStorage.getItem("customFields"));
        var temp = customFields;
        var res;
        _.each(temp, function (customfields) {
            _.each(customfields.parametrFieldsArray, function (value) {
                res = $.grep(ChartInfo.functions.getCustomParameter(), function (e) {
                    return e.parametername == value;
                })
                var formula = customfields.formula;
                var scriptcondition = customfields.scriptcondition;
                var fields = customfields.fields;
                var sqlformula = customfields.sqlformula;
                if (res.length != 0) {
                    customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
                    customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
                    customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
                    customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
                } else {
                    _.each(ChartInfo.functions.getCustomParameter(), function (values) {
                        if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
                            customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                            customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                            customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
                            customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                        }
                    });
                }
            });
        });
        customFields = temp;
        var convertedTypes = {};
        _.each(JSON.parse(sessionStorage.getItem("convertedtypes")), function (list) {
            convertedTypes[list["columnName"]] = list["dataType"]
        })
        this.state.userReportsCollection.sort(function(a, b){
            var x = a.reportname.toLowerCase();
            var y = b.reportname.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0 ;
            });
        this.props.dimensions.sort(function(a, b){
            var x = a.columnDisplayName.toLowerCase();
            var y = b.columnDisplayName.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0 ;
            });
        if(customFields != undefined && customFields != null && customFields.length != 0){
        customFields.sort(function(a, b){
            var x = a.name.toLowerCase();
            var y = b.name.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0 ;
            });
            }
        ReactDOM.render(<DrillDownAllSettingsTemplate applydrilldownsettings={this.applydrilldownsettings} cancelDrilldownSetting={this.cancelDrilldownSetting} dimensions={this.props.dimensions} userreports={this.state.userReportsCollection} calculatedFileds={customFields != undefined && customFields != null ? customFields : []} convertedTypes={convertedTypes} />, document.querySelector('.append_drilldownallsettingspop'))
        var that = this;
        $(".drilldownprioritycolumns").chosen({
            no_results_text: lang["storyboard.oops"],
            width: "100%",
            search_contains: true
        });
        $(".drillthroughselectreports").chosen({
            no_results_text: lang["storyboard.oops"],
            width: "100%",
            search_contains: true
        });
        var drillthroughreports = ChartInfo.functions.getDrillThroughReports();
        var priorityColumns = ChartInfo.functions.getPriorityColumns();
        var columns = [];
        if (priorityColumns != undefined && priorityColumns != null && priorityColumns.length > 0) {
            columns = priorityColumns;
        } else {
            columns = this.props.dimensions
        }
        this.updateDrillThroughReports(drillthroughreports);
        this.updatePriorityColumns(columns);
        this.updateCustomFieldsPriorityColumns(JSON.parse(sessionStorage.getItem("customFields")));
        return (<div></div>);
    }
    updatePriorityColumns(columns) {
        _.each(columns, function (column) {
            if (column.priorityColumn != undefined && column.priorityColumn != null && column.priorityColumn) {
                $('.drilldownprioritycolumns_group option[data-columnDisplayName="' + column.columnDisplayName + '"]').attr("selected", "selected");
                $(".drilldownprioritycolumns").trigger("chosen:updated");
            }
        });
    }
    updateCustomFieldsPriorityColumns(columns) {
        _.each(columns, function (column) {
            if (column.priorityColumn != undefined && column.priorityColumn != null && column.priorityColumn) {
                $('.customfieldsprioritycolumns_group option[data-columnDisplayName="' + column.name + '"]').attr("selected", "selected");
                $(".drilldownprioritycolumns").trigger("chosen:updated");
            }
        });
    }
    updateDrillThroughReports(reports) {
        if (reports != null && reports != undefined && reports.length > 0) {
            _.each(JSON.parse(reports), function (report) {
                if (report != undefined && report != null) {
                    $('.drillthroughselectreports_group option[report-id="' + report.report_id + '"]').attr("selected", "selected");
                    $(".drillthroughselectreports").trigger("chosen:updated");
                }
            });
        }
    }
    cancelDrilldownSetting = () => {
        ReactDOM.unmountComponentAtNode(document.querySelector('.append_drilldownallsettingspop'));
    }
    applydrilldownsettings = (e) => {
        var that = this;
        //priority columns
        var columns = this.props.dimensions;
        var customFields = JSON.parse(sessionStorage.getItem("customFields"));
        var temp = customFields;
        var drillThroughReportsArray = [];
        var drillDownPriorityColumns = $(".drilldownprioritycolumns option:selected");
        var columnsArray = [];
        _.each(columns, function (column, i) {
            var columnsObj = {};
            var isPriorityColumn = false;
            _.each(drillDownPriorityColumns, function (group, j) {
                var priorityColumnName = $($('.drilldownprioritycolumns  option:selected')[j]).attr("data-columnDisplayName");
                if (priorityColumnName == column.columnDisplayName) {
                    isPriorityColumn = true;
                }
            });
            columnsObj = column;
            columnsObj["priorityColumn"] = isPriorityColumn;
            columnsArray.push(columnsObj);
        });
        var customfieldsArray = [];
        _.each(temp, function (column, i) {
            var columnsObj = {};
            var isPriorityColumn = false;
            _.each(drillDownPriorityColumns, function (group, j) {
                if ($($('.drilldownprioritycolumns  option:selected')[j]).attr("custom-field") == "true") {
                    var priorityColumnName = $($('.drilldownprioritycolumns  option:selected')[j]).attr("data-columnDisplayName");
                    if (priorityColumnName == column.name) {
                        isPriorityColumn = true;
                    }
                }
            });
            columnsObj = column;
            columnsObj["priorityColumn"] = isPriorityColumn;
            customfieldsArray.push(columnsObj);
        });
        //drill through reports
        var drillThroughReportsArray = [];
        var drillThroughReports = $(".drillthroughselectreports option:selected");
        _.each(drillThroughReports, function (group, i) {
            var reportObj = {};
            reportObj["report_id"] = $($('.drillthroughselectreports  option:selected')[i]).attr("report-id");
            reportObj["report_name"] = $($('.drillthroughselectreports  option:selected')[i]).attr("report-name");
            drillThroughReportsArray.push(reportObj);
        });
        ChartInfo.functions.setDrillThroughReports(JSON.stringify(drillThroughReportsArray));
        ChartInfo.functions.setPriorityColumns(columnsArray);
        if (customfieldsArray.length > 0) {
            sessionStorage.setItem("customFields", JSON.stringify(customfieldsArray));
        }
        this.cancelDrilldownSetting();
    }
}