import React from "react";
import ReactDOM from "react-dom";
import $ from 'jquery/dist/jquery';
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import CombinationChartsAttributesTemplate from "./combinationchartsattributes.jsx"

export default class CombinationChartView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicdiv: props.dynamicdiv,
            yaxiscolumnDisplayName: props.yaxiscolumnDisplayName,
            yaxiscolumnName: props.yaxiscolumnName,
            yaxiscolumnType: props.yaxiscolumnType,
            aggregation: props.yaxisaggregation,
            yaxistableName: props.yaxistableName,
            percentileValue: props.percentileValue,
            aggType: props.aggregationType,
            id: props.id
            //		broker.on('changeaggregation',changeaggregation,this);
            //			template = _.template(CombinationChartsAttributesTemplate);
        };
        this.render();
    }
    render() {
        this.state.el = "#" + this.state.dynamicdiv;
            ReactDOM.render(<CombinationChartsAttributesTemplate dynamicdiv={this.state.dynamicdiv} yaxiscolumnDisplayName={ this.state.yaxiscolumnDisplayName} yaxiscolumnName={ this.state.yaxiscolumnName}
                changeCombinationCharttype={this.changeCombinationCharttype} changeCombinationAxisPosition={this.changeCombinationAxisPosition}  yaxiscolumnType={ this.state.yaxiscolumnType} aggregation={ this.state.aggregation} yaxistableName={ this.state.yaxistableName} percentileValue={ this.state.percentileValue} selectedChartType={ this.props.selectedChartType} chartaxisposintion={ this.props.chartaxisposintion} id={ this.state.id}></CombinationChartsAttributesTemplate>,document.getElementById(this.state.dynamicdiv));

        var sortingAttributes = $.parseJSON(ChartInfo.functions.getChartDetails(sessionStorage.getItem('currentChartNumberForSort')));
        var colLimit = sortingAttributes.colLimit;
        var orderType = sortingAttributes.orderType;
        var sortType = sortingAttributes.sortType;
        var sortingColumn = sortingAttributes.sortingColumn;
        $(this.state.el).find(".combinationchart[data-name='" + this.state.yaxiscolumnName + "']").attr("data-agg", this.state.yaxiscolumnName + "count");
        if (sortType == 'desc') {
            $('input[id="sortby1"]').attr('checked', true);
        } else if (sortType == 'asc') {
            $('input[id="sortby2"]').attr('checked', true);
        }
        if (orderType == "top") {
            $('.ordertype option[value="top"]').attr('selected', 'selected');
        } else if (orderType == "bottom") {
            $('.ordertype option[value="bottom"]').attr('selected', 'selected');
        }
        if (colLimit != null && colLimit != "") {
            $('.collimit').attr('value', colLimit);
        }
        //Active drop down aggregation item
        if (this.state.aggregation === "sum" || this.state.aggregation === "Sum") {
            $(this.state.el).find(".aggsum").addClass("active");
        } else if (this.state.aggregation === "min" || this.state.aggregation === "Min") {
            $(this.state.el).find(".aggmin").addClass("active");
        } else if (this.state.aggregation === "max" || this.state.aggregation === "Max") {
            $(this.state.el).find(".aggmax").addClass("active");
        } else if (this.state.aggregation === "avg" || this.state.aggregation === "Avg") {
            $(this.state.el).find(".aggavg").addClass("active");
        } else if (this.state.aggregation === "variance" || this.state.aggregation === "Variance") {
            $(this.state.el).find(".aggvariance").addClass("active");
        } else if (this.state.aggregation === "standard deviation" || this.state.aggregation === "Standard Deviation") {
            $(this.state.el).find(".aggstandarddeviation").addClass("active");
        } else if (this.state.aggregation === "sum of squares" || this.state.aggregation === "Sum of Squares") {
            $(this.state.el).find(".aggsumofsquares").addClass("active");
        } else if (this.state.aggregation === "percentile" || this.state.aggregation === "Percentile") {
            $(this.state.el).find(".aggpercentile").addClass("active");
        } else if (this.state.aggregation === "median" || this.state.aggregation === "Median") {
            $(this.state.el).find(".aggmedian").addClass("active");
        } else if (this.state.aggregation === "count all" || this.state.aggregation === "Count (all)") {
            $(this.state.el).find(".aggcount").addClass("active");
        } else if (this.state.aggregation === "count" || this.state.aggregation === "Count (distinct)") {
            $(this.state.el).find(".aggcountdistinct").addClass("active");
        }
        return (<div></div>);
    }
    changeCombinationCharttype =(e)=>{
        $(this.state.el).find(".aggregation-li-item").removeClass("active");
        $(e.currentTarget).addClass("active");
        $(this.state.el).find(".aggregationtext").text($(e.currentTarget).text());
        var columnName = $(this.state.el).find('.singleliitems').attr("data-name");
        var columnDisplayName = $(this.state.el).find('.singleliitems').attr("title");
        var aggregation = $(e.currentTarget).attr("title");
        var aggregationtext = $(e.currentTarget).text();
        var percentileValue=$(this.state.el).find(".percentilebox").val();
        $(this.state.el).find('.singleliitems').attr("data-charttype",$(e.currentTarget).text());
        $(this.state.el).find('.singleliitems').attr("data-selectedcharttype",aggregation);
        this.state.aggregation = aggregation;
        /*if(aggregation!=undefined){
            $(this.state.el).find(".singleliitems[data-name = '"+this.yaxiscolumnName+"']").attr("data-agg",aggregation);
        }*/
    }
    changeCombinationAxisPosition=(e)=>{
        $(this.state.el).find(".combochart-li-item").removeClass("active");
        $(e.currentTarget).addClass("active");
        $(this.state.el).find(".comboaxistext").html($(e.currentTarget).text());
        $(this.state.el).find('.singleliitems').attr("data-axisposition",$(e.currentTarget).attr("title"));
    }
}