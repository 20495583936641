import { takeRight } from 'lodash';
import React from 'react';
import { lang } from "../../js/helpers/utils/languages/language";
export default class MLDatasnapshotTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.render();
    }
    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xs-12">
                        <h2>{lang["mlreports.features"]}</h2>
                    </div>
                    <div className="col-xs-12 marg-top-15">
                        <div className="notifications-table-container">
                        <div className="fixed-table-toolbar">
                            <div className="pull-right search">
                                <input className="form-control searchsnapshottable" type="text"
                                    placeholder={lang["datahub.search"]} defaultValue={""} onKeyUp={this.props.searchKeyUp} />
                            </div>
                        </div>
                            <table className="variablestable" data-toggle="table" data-smart-display="true" data-pagination-v-align="bottom" data-show-columns="false">
                                <thead></thead>
                            </table>
                        </div>
                        <div className="notifications-table-container col-xs-12">
                            <div className="bootstrap-table">
                                <div className="fixed-table-pagination">
                                    <div className="pull-left pagination-detail">
                                        <span className="pagination-info">{lang["mlreports.showing"]} </span> <span
                                            className="page-list"> <span className="btn-group dropup">
                                                <button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown">
                                                    <span className="page-size">5</span> <span className="caret"></span>
                                                </button>
                                                <ul className="dropdown-menu tablerowlimitml" role="menu">
                                                    <li className="tablerowlimitml1" value="5"><a href="javascript:void(0)">5</a></li>
                                                    <li className="tablerowlimitml1" value="10"><a href="javascript:void(0)">10</a></li>
                                                    <li className="tablerowlimitml1" value="25"><a href="javascript:void(0)">25</a></li>
                                                    <li className="tablerowlimitml1" value="50"><a href="javascript:void(0)">50</a></li>
                                                    <li className="tablerowlimitml1" value="100"><a href="javascript:void(0)">100</a></li>
                                                </ul>
                                            </span> {lang["mlreports.recordspage"]}
							</span>
                                    </div>
                                    <div className="pull-right pagination">
                                        <ul id="datatable-pagination-view-ml" className="pagination-sm"></ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row marg-top-15">
                    <div className="col-xs-12 marg-top-15">
                        <div className="row">
                            <div className="row-same-height">
                                <div className="col-md-6 col-xs-12 col-xs-height nopadding">
                                    <div className="insightquality mlsnapshotcorrelatedcolumns">
                                        <h3>{lang["mlreports.corelatedcolms"]}</h3>
                                        <div className="overviewchart"></div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xs-12 col-xs-height nopadding">
                                    <div className="insightquality mlchisquaretable">
                                        <h3>{lang["mlreports.chisquaretable"]}</h3>
                                        <div className="overviewchart"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}