import React from 'react';
import ReactDOM from 'react-dom';
import SavedatamodelHtml from './SavedatamodelHtml.jsx';
import * as ModalProperties from '../js/helpers/utils/modalproperties';
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;

export default class Savedatamodel extends React.Component {
    constructor(props) {
        super(props);
        // Backbone.Validation.bind(this, {
        //     valid : function(view, attr, selector) {
        //         var $el = view.$('[name=' + attr + ']'),
        //         $group = $el.closest('.validationgroup');
        //         $group.removeClass('has-error');
        //         $group.find('.help-block').html('').addClass('hidden');
        //     },
        //     invalid : function(view, attr, error, selector) {
        //         var $el= view.$('[name=' + attr + ']'),
        //         $group = $el.closest('.validationgroup');
        //         $group.addClass('has-error');
        //         $group.find('.help-block').html(error).removeClass('hidden');
        //     }			
        // });
        this.dataModelName = this.props.dataModelName;
        this.dataModelDesc = this.props.dataModelDesc;
    }
    componentDidMount() {
       // $("#savedatamodel").modal("show");
        ModalProperties.resize();
    }
    savedatamodel = (e) => {
        var valid=false;
        var datamodelname = $("#datamodelname").val().trim();
        var datamodeldescr = $("#datamodeldesc").val();
        var reservedwordslist = JSON.parse(sessionStorage.getItem("reservedwordslist"));
        if (datamodelname== undefined || datamodelname == "" || datamodelname == 0 || /[~`!@#$%^&*()+\=\[\]{};':\\|†,.<>\/?"""`]/.test(datamodelname)) {
			$(".dmname-error").html(lang["models.validname"])
			return;
		} else if (datamodelname.length > 70) {
			$(".dmname-error").html(lang["models.reportnameexceeds"])
			return;
		} else if (reservedwordslist != null){ 
		if (reservedwordslist.indexOf(datamodelname.toLowerCase()) != -1) {
			$(".dmname-error").html(lang["models.noreservewords"])
			return;
			}
		} else {
			$(".dmname-error").html('')
        }
        if(datamodelname!==""){
            valid=true;
        }
        if(valid){
            this.props.onsavedatamodel(datamodelname,datamodeldescr);
        }else{
            $(".spinner").hide();
            // $(".dmname-error").removeClass("hidden");
            // $(".dmname-error").html(lang["models.entervalidname"]);
        }
       
       
        
    }
    canceldSaveModel=(e)=>{
        ReactDOM.unmountComponentAtNode(document.getElementById('savedatamodaldialog'));
    }
    render() {
        return (
            <SavedatamodelHtml
                dataModelName={this.dataModelName}
                dataModelDesc={this.dataModelDesc}
                savedatamodel={this.savedatamodel}
                canceldSaveModel={this.canceldSaveModel}
            />
        );
    }
}