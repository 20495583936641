import React, { Component } from "react";
import ReactDOM from 'react-dom';
import SendRequest from '../../SendRequest';
import { getMessage } from '../../js/helpers/utils/BirdMessage';
import SyncConfigMainTemplate from './SyncConfiguarationsMainTemplate.jsx';
import AddSyncConfigTemplate from './AddSyncConfiguaration'
import { lang} from "../../js/helpers/utils/languages/language";
import EditSyncConfigTemplate from './EditSyncconfiguaration.jsx';

const $ = window.$;

export default class SyncConfigMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            configs: props.configs
        }
        this.render();
    }
    editSyncConfig = (e) => {
        var that = this;
        var $this = e.currentTarget;
        var configObj = $.grep(this.state.configs, function (e) { return e.sync_id == $($this).val(); })
        if (configObj != null && configObj != undefined && configObj[0] != undefined && configObj[0].sync_details != undefined) {
            if (document.querySelector(".innerothersarea") != null && document.querySelector(".innerothersarea") != undefined) {
                ReactDOM.unmountComponentAtNode(document.querySelector(".innerothersarea"));
            }
            ReactDOM.render(<EditSyncConfigTemplate validateSyncConfigName={this.validateSyncConfigName} cancelsyncconfig={this.cancelsyncconfig} updateSyncConfig={this.updateSyncConfig} changeSyncType={this.changeSyncType} configObj={configObj[0]} changeSyncOption={this.changeSyncOption} />, document.querySelector(".innerothersarea"));
            setTimeout(function (e) {
                that.updateSyncConfigDetails(configObj[0].sync_details);
                if($(window).width()<=780){
                    $("html, body").animate({ scrollTop: $("#cancelsyncconfig").offset().top });
                }
            }, 500);
        }
        this.showleft();
    }
    showleft = () => {
        $('#icontent').removeClass('hide');
        $('.leftarea').removeClass('shownsingle');
        $('.editrole').removeClass('disabled');
        $('.editgroup').removeClass('disabled');
        $('.editaccount3').removeClass('disabled');
        $('.editschedule').removeClass('disabled');
        $('.editmail').removeClass('disabled');
        $('.viewhistory').removeClass('disabled');

        $('.hideleft').removeClass('hide');
        $('.showleft').addClass('hide');

        $('.leftshow').addClass('hide');
        $('.lefthide').removeClass('hide');

        $('.w2ui-resizer').resize();
        $(".boc-admin-main .notifications-table-container table").bootstrapTable('resetView');
    }
    
    createSyncBucket = (e) => {
        var that = this;
        if (document.querySelector(".innerothersarea") != null && document.querySelector(".innerothersarea") != undefined) {
            ReactDOM.unmountComponentAtNode(document.querySelector(".innerothersarea"));
        }
        ReactDOM.render(<AddSyncConfigTemplate validateAddSyncConfigName={this.validateAddSyncConfigName} cancelsyncconfig={this.cancelsyncconfig} addSyncConfig={this.addSyncConfig} changeSyncType={this.changeSyncType} changeSyncOption={this.changeSyncOption} />, document.querySelector(".innerothersarea"));
        var configObj = "{\"cronDetails\":\"Every 30 Minute(s)\",\"syncVal\":\"30\",\"dataSyncType\":\"custom\",\"syncType\":\"minutes\",\"synctime\":\"\"}";
        setTimeout(function (e) {
            that.updateSyncConfigDetails(configObj);
            $(".syncCategory").val("Hub").change();
            if($(window).width()<=780){
                $("html, body").animate({ scrollTop: $("#cancelsyncconfig").offset().top });
            }
        }, 500);
    }
    cancelsyncconfig = () => {
        if (document.querySelector(".innerothersarea") != null && document.querySelector(".innerothersarea") != undefined) {
            ReactDOM.unmountComponentAtNode(document.querySelector(".innerothersarea"));
        }
    }
    validateAddSyncConfigName = (e) => {
        var $this = e.currentTarget;
        var currentvalue = $($this).val();
        var syncConfigName = $("#syncconfignameid").val();
        if (this.state.configs != undefined) {
            var showValidation = false;
            this.state.configs.map((conf, index) => {
                if (conf.sync_name == currentvalue || /[~`!@#$%^&*()+\=\[\]{};':\\|†,.<>\/?" ""`]/.test(syncConfigName)) {
                    showValidation = true;
                }
            })
            if (showValidation) {
                $('.syncconfignameerror').removeClass('hidden');
                $('.syncconfignameerror').html(lang["admin.sync.validname"]);
                $('#addSyncConfig').addClass('disabled');
                return;
            } else {
                $('.syncconfignameerror').addClass('hidden');
                $('#addSyncConfig').removeClass('disabled');
            }
        }
    }
    validateSyncConfigName = (e) => {
        var $this = e.currentTarget;
        var category = $($this).attr('category');
        var syncid = $($this).attr('syncid');
        var currentvalue = $($this).val();
        var syncConfigName = $("#syncconfignameid").val();
        if (this.state.configs != undefined) {
            var showValidation = false;
            this.state.configs.map((conf, index) => {
                if (conf.category == category && conf.sync_id != syncid && conf.sync_name == currentvalue || /[~`!@#$%^&*()+\=\[\]{};':\\|†,.<>\/?" ""`]/.test(syncConfigName)) {
                    showValidation = true;
                }
            })
            if (showValidation) {
                $('.syncconfignameerror').removeClass('hidden');
                $('.syncconfignameerror').html(lang["admin.sync.validname"]);
                $('#updateSyncConfig').addClass('disabled');
                return;
            } else {
                $('.syncconfignameerror').addClass('hidden');
                $('#updateSyncConfig').removeClass('disabled');

            }
        }
    }
    addSyncConfig = (e) => {
        var that = this;
        var syncConfigName = $("#syncconfignameid").val();
        var dataSyncType = $('.datasynctype:checked').attr("id");
        var syncType;
        var syncVal;
        var isValid = true;
       
		if (syncConfigName != "" && /[~`!@#$%^&*()+\=\[\]{};':\\|†,.<>\/?" ""`]/.test(syncConfigName)) {
            $(".syncconfigcharacterror").removeClass('hidden');
            return;
		} 
		 else {
			$(".syncconfigcharacterror").addClass('hidden');
            
        }

        var syncTime = $('#sync-day-time-start').val();
        if (dataSyncType == 'defaultcontainer') {
            syncType = "default";
            syncVal = $('.defaultcontainer input').val();
        } else {
            syncType = $('.syncreport').find("option:selected").val();
            syncVal = $('.' + syncType + '').find("input").val();
        }
        if (syncType == 'days' && syncVal == "") {
            $('.syncerrordays').text(lang["admin.sync.inputdata"]);
            $('.syncerrordays').removeClass("hide");
            isValid = false;
        } else {
            if (syncType == "") {
                syncType = "default";
            }
        }
        if (syncType == 'minutes') {
            if (syncVal.length == "") {
                $('.syncerror').text(lang["admin.sync.enternumber"] );
                $('.syncerror').removeClass("hide");
                isValid = false;
            }
            else if (syncVal < 1) {
                $('.syncerror').text(lang["admin.sync.minminutes"]);
                $('.syncerror').removeClass("hide");
                isValid = false;
            } else if (syncVal > 59) {
                $('.syncerror').text(lang["admin.sync.maxminutes"]);
                $('.syncerror').removeClass("hide");
                isValid = false;
            }
            else if (syncVal.indexOf(".") != -1) {
                $('.syncerror').text(lang["admin.sync.decimalval"]);
                $('.syncerror').removeClass("hide");
                isValid = false;
            }
            else {
                $('.syncerror').addClass("hide");
            }
        } else if (syncType == 'hours') {
            if (syncVal.length == "") {
                $('.syncerror').text(lang["admin.sync.enternumber"] );
                $('.syncerror').removeClass("hide");
                isValid = false;
            }
            else if (syncVal < 1) {
                $('.syncerror').text(lang["admin.sync.minhr"]);
                $('.syncerror').removeClass("hide");
                isValid = false;
            } else if (syncVal > 23) {
                $('.syncerror').text(lang["admin.sync.maxhr"]);
                $('.syncerror').removeClass("hide");
                isValid = false;
            }
            else if (syncVal.indexOf(".") != -1) {
                $('.syncerror').text(lang["admin.sync.decimalval"]);
                $('.syncerror').removeClass("hide");
                isValid = false;
            }
            else {
                $('.syncerror').addClass("hide");
            }
        } else if (syncType == 'days') {
            if (syncVal.length == "") {
                $('.syncerrordays').text(lang["admin.sync.enternumber"] );
                $('.syncerrordays').removeClass("hide");
                isValid = false;
            }
            else if (syncVal < 1) {
                $('.syncerrordays').text(lang["admin.sync.minday"]);
                $('.syncerrordays').removeClass("hide");
                isValid = false;
            } else if (syncVal > 31) {
                $('.syncerrordays').text(lang["admin.sync.maxday"]);
                $('.syncerrordays').removeClass("hide");
                isValid = false;
            }
            else if (syncVal.indexOf(".") != -1) {
                $('.syncerrordays').text(lang["admin.sync.decimalval"]);
                $('.syncerrordays').removeClass("hide");
                isValid = false;
            }
            else {
                var timeSplit = $(".sync-day-time input").val();
                if (timeSplit == undefined || timeSplit.length <= 0) {
                    timeSplit = "00:00";
                }
                var errormsg = $("#errormsg")["0"].innerHTML.length;
                if (errormsg > 0 && timeSplit != "00:00") {
                    isValid = false;
                }
                syncTime = timeSplit;
                $('.syncerrordays').addClass("hide");
            }
        } else if (syncType == 'seconds') {
            if (syncVal.length == "") {
                $('.syncerror').text(lang["admin.sync.enternumber"] );
                $('.syncerror').removeClass("hide");
                isValid = false;
            }
            else if (syncVal < 5) {
                $('.syncerror').text(lang["admin.sync.minsec"]);
                $('.syncerror').removeClass("hide");
                isValid = false;
            } else if (syncVal > 59) {
                $('.syncerror').text(lang["admin.sync.maxsec"]);
                $('.syncerror').removeClass("hide");
                isValid = false;
            }
            else if (syncVal.indexOf(".") != -1) {
                $('.syncerror').text(lang["admin.sync.decimalval"]);
                $('.syncerror').removeClass("hide");
                isValid = false;
            }
            else {
                $('.syncerror').addClass("hide");
            }
        }
        var syncScheduleObj = {};
        syncScheduleObj["dataSyncType"] = dataSyncType;
        syncScheduleObj["syncType"] = syncType;
        syncScheduleObj["syncVal"] = syncVal;
        syncScheduleObj["synctime"] = syncTime;
        if (syncConfigName == "" || syncConfigName == undefined) {
            $('.syncconfignameerror').removeClass('hidden');
            $('.syncconfignameerror').html(lang["admin.sync.validname"]);
            isValid = false;
        }
        if (isValid) {
            var data = new FormData();
            data.append("syncConfigName", syncConfigName);
            data.append("category", $(".syncCategory").val());
            data.append("syncId", 0);
            data.append("syncScheduleData", JSON.stringify(syncScheduleObj));
            data.append("status", $("#statusactive").prop("checked")?"A":"I");
            SendRequest({
                url: "updatesyncconfig",
                queryString: "",
                body: data,
                sucessFunction: (collection) => {
                    if (collection.hasOwnProperty('error')) {
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(collection.errorCode);
                        $('.birdmessage-info').empty().text(collection.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(collection.errorTrace)
                        $("#savesworkspace").modal("show");
                        $(".spinner").hide();
                        return;
                    } else {
                        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Success');
                        $('.birdmessage-info').empty().text(getMessage('BirdSuccess66'));

                        ReactDOM.unmountComponentAtNode(document.querySelector(".innerothersarea"));
                        let requestHeaders = {
                            method: "get",
                            headers: new Headers({
                                "Content-Type": "application/json"
                            })
                        };
                        SendRequest({
                            url: "getSyncConfigDetails",
                            queryString: "",
                            sucessFunction: (response) => {
                                if (response.hasOwnProperty('error')) {
                                    $(".spinner").hide();
                                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                                    $('.birdmessagebuttons').removeClass('show');
                                    $('.birdmessagebuttons').addClass('hide');
                                    $('.birdmessage-center').addClass("message-top-in");
                                    $('.details').removeClass('hide');
                                    $('.birdmessage h2').empty().append('Error : ');
                                    $('.birdmessage h2').append('<span class="errorcode">');
                                    $('.birdmessage .errorcode').empty().append(response.errorCode);
                                    $('.birdmessage-info').empty().text(response.error);
                                    $('.details').addClass('show');
                                    $('.messagedetails xmp').empty().append(response.errorTrace)
                                } else {
                                    if (document.getElementById("syncconfig") != null) {
                                        ReactDOM.unmountComponentAtNode(document.getElementById("syncconfig"));
                                    }
                                    that.state.configs = response;
                                    var configs = [];
                                    if (this.state.configs != null && this.state.configs != 'null' && this.state.configs != "" && this.state.configs != undefined) {
                                        configs = this.state.configs;
                                        configs.map((conf, index) => {
                                            conf.corn_details = conf.sync_details != undefined && JSON.parse(conf.sync_details).cronDetails != undefined ? JSON.parse(conf.sync_details).cronDetails : "";
                                            conf.edit = <button className="btn btn-admin editconfig" id="editconfigid" type="button" value={conf.sync_id} onClick={that.editSyncConfig}>
                                                <i className="fa fa-pencil"></i>
                                            </button>
                                        });
                                    }
                                    ReactDOM.render(<SyncConfigMainTemplate createSyncBucket={that.createSyncBucket} editSyncConfig={that.editSyncConfig} data={configs} totalRecords={configs.length} />, document.getElementById("syncconfig"))
                                }
                            },
                            rejectFunction: () => { },
                            headers: requestHeaders

                        });
                    }
                },
                rejectFunction: (error) => { },
                headers: {
                    method: "post",
                    headers: new Headers({
                        "Content-Type": "application/json"
                    })
                }
            });
        }
    }
    updateSyncConfig = (e) => {
        var that = this;
        var syncConfigName = $("#syncconfignameid").val();
        var dataSyncType = $('.datasynctype:checked').attr("id");
        var syncType;
        var syncVal;
        var isValid = true;
        if ( syncConfigName != "" && /[~`!@#$%^&*()+\=\[\]{};':\\|†,.<>\/?" ""`]/.test(syncConfigName)) {
          $(".syncconfigcharacterror").removeClass('hidden');
            return;
		} 
		 else {
			$(".syncconfigcharacterror").addClass('hidden');
           
        }
        var syncTime = $('#sync-day-time-start').val();
        if (dataSyncType == 'defaultcontainer') {
            syncType = "default";
            syncVal = $('.defaultcontainer input').val();
        } else {
            syncType = $('.syncreport').find("option:selected").val();
            syncVal = $('.' + syncType + '').find("input").val();
        }
        if (syncType == 'days' && syncVal == "") {
            $('.syncerrordays').text(lang["admin.sync.inputdata"]);
            $('.syncerrordays').removeClass("hide");
            isValid = false;
        } else {
            if (syncType == "") {
                syncType = "default";
            }
        }
        if (syncType == 'minutes') {
            if (syncVal.length == "") {
                $('.syncerror').text(lang["admin.sync.enternumber"]);
                $('.syncerror').removeClass("hide");
                isValid = false;
            }
            else if (syncVal < 1) {
                $('.syncerror').text(lang["admin.sync.minminutes"]);
                $('.syncerror').removeClass("hide");
                isValid = false;
            } else if (syncVal > 59) {
                $('.syncerror').text(lang["admin.sync.maxminutes"]);
                $('.syncerror').removeClass("hide");
                isValid = false;
            }
            else if (syncVal.indexOf(".") != -1) {
                $('.syncerror').text(lang["admin.sync.decimalval"]);
                $('.syncerror').removeClass("hide");
                isValid = false;
            }
            else {
                $('.syncerror').addClass("hide");
            }
        } else if (syncType == 'hours') {
            if (syncVal.length == "") {
                $('.syncerror').text(lang["admin.sync.enternumber"]);
                $('.syncerror').removeClass("hide");
                isValid = false;
            }
            else if (syncVal < 1) {
                $('.syncerror').text(lang["admin.sync.minhr"]);
                $('.syncerror').removeClass("hide");
                isValid = false;
            } else if (syncVal > 23) {
                $('.syncerror').text(lang["admin.sync.maxhr"]);
                $('.syncerror').removeClass("hide");
                isValid = false;
            }
            else if (syncVal.indexOf(".") != -1) {
                $('.syncerror').text(lang["admin.sync.decimalval"]);
                $('.syncerror').removeClass("hide");
                isValid = false;
            }
            else {
                $('.syncerror').addClass("hide");
            }
        } else if (syncType == 'days') {
            if (syncVal.length == "") {
                $('.syncerrordays').text(lang["admin.sync.enternumber"]);
                $('.syncerrordays').removeClass("hide");
                isValid = false;
            }
            else if (syncVal < 1) {
                $('.syncerrordays').text(lang["admin.sync.minday"]);
                $('.syncerrordays').removeClass("hide");
                isValid = false;
            } else if (syncVal > 31) {
                $('.syncerrordays').text(lang["admin.sync.maxday"]);
                $('.syncerrordays').removeClass("hide");
                isValid = false;
            }
            else if (syncVal.indexOf(".") != -1) {
                $('.syncerrordays').text(lang["admin.sync.decimalval"]);
                $('.syncerrordays').removeClass("hide");
                isValid = false;
            }
            else {
                var timeSplit = $(".sync-day-time input").val();
                if (timeSplit == undefined || timeSplit.length <= 0) {
                    timeSplit = "00:00";
                }
                var errormsg = $("#errormsg")["0"].innerHTML.length;
                if (errormsg > 0 && timeSplit != "00:00") {
                    isValid = false;
                }
                syncTime = timeSplit;
                $('.syncerrordays').addClass("hide");
            }
        } else if (syncType == 'seconds') {
            if (syncVal.length == "") {
                $('.syncerror').text(lang["admin.sync.enternumber"]);
                $('.syncerror').removeClass("hide");
                isValid = false;
            }
            else if (syncVal < 5) {
                $('.syncerror').text(lang["admin.sync.minsec"]);
                $('.syncerror').removeClass("hide");
                isValid = false;
            } else if (syncVal > 59) {
                $('.syncerror').text(lang["admin.sync.maxsec"]);
                $('.syncerror').removeClass("hide");
                isValid = false;
            }
            else if (syncVal.indexOf(".") != -1) {
                $('.syncerror').text(lang["admin.sync.decimalval"]);
                $('.syncerror').removeClass("hide");
                isValid = false;
            }
            else {
                $('.syncerror').addClass("hide");
            }
        }
        var syncScheduleObj = {};
        syncScheduleObj["dataSyncType"] = dataSyncType;
        syncScheduleObj["syncType"] = syncType;
        syncScheduleObj["syncVal"] = syncVal;
        syncScheduleObj["synctime"] = syncTime;
        if (syncConfigName == "" || syncConfigName == undefined) {
            $('.syncconfignameerror').removeClass('hidden');
            $('.syncconfignameerror').html(lang["admin.sync.validname"]);
            isValid = false;
        }
        if (isValid) {
            var data = new FormData();
            data.append("syncConfigName", syncConfigName);
            data.append("category", $('#updateSyncConfig').attr('category'));
            data.append("syncId", $('#updateSyncConfig').attr('syncid'));
            data.append("syncScheduleData", JSON.stringify(syncScheduleObj));
            data.append("status", $("#statusactive").prop("checked")?"A":"I");
            SendRequest({
                url: "updatesyncconfig",
                queryString: "",
                body: data,
                sucessFunction: (collection) => {
                    if (collection.hasOwnProperty('error')) {
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(collection.errorCode);
                        $('.birdmessage-info').empty().text(collection.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(collection.errorTrace)
                        $("#savesworkspace").modal("show");
                        $(".spinner").hide();
                        return;
                    } else {
                        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Success');
                        $('.birdmessage-info').empty().text(getMessage('BirdSuccess66'));

                        ReactDOM.unmountComponentAtNode(document.querySelector(".innerothersarea"));
                        let requestHeaders = {
                            method: "get",
                            headers: new Headers({
                                "Content-Type": "application/json"
                            })
                        };
                        SendRequest({
                            url: "getSyncConfigDetails",
                            queryString: "",
                            sucessFunction: (response) => {
                                if (response.hasOwnProperty('error')) {
                                    $(".spinner").hide();
                                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                                    $('.birdmessagebuttons').removeClass('show');
                                    $('.birdmessagebuttons').addClass('hide');
                                    $('.birdmessage-center').addClass("message-top-in");
                                    $('.details').removeClass('hide');
                                    $('.birdmessage h2').empty().append('Error : ');
                                    $('.birdmessage h2').append('<span class="errorcode">');
                                    $('.birdmessage .errorcode').empty().append(response.errorCode);
                                    $('.birdmessage-info').empty().text(response.error);
                                    $('.details').addClass('show');
                                    $('.messagedetails xmp').empty().append(response.errorTrace)
                                } else {
                                    if (document.getElementById("syncconfig") != null) {
                                        ReactDOM.unmountComponentAtNode(document.getElementById("syncconfig"));
                                    }
                                    that.state.configs = response;
                                    var configs = [];
                                    if (this.state.configs != null && this.state.configs != 'null' && this.state.configs != "" && this.state.configs != undefined) {
                                        configs = this.state.configs;
                                        configs.map((conf, index) => {
                                            conf.corn_details = conf.sync_details != undefined && JSON.parse(conf.sync_details).cronDetails != undefined ? JSON.parse(conf.sync_details).cronDetails : "";
                                            conf.edit = <button className="btn btn-admin editconfig" id="editconfigid" type="button" value={conf.sync_id} onClick={that.editSyncConfig}>
                                                <i className="fa fa-pencil"></i>
                                            </button>
                                        });
                                    }
                                    ReactDOM.render(<SyncConfigMainTemplate createSyncBucket={that.createSyncBucket} editSyncConfig={that.editSyncConfig} data={configs} totalRecords={configs.length} />, document.getElementById("syncconfig"));
                                }
                            },
                            rejectFunction: () => { },
                            headers: requestHeaders

                        });
                    }
                },
                rejectFunction: (error) => { },
                headers: {
                    method: "post",
                    headers: new Headers({
                        "Content-Type": "application/json"
                    })
                }
            });
        }
    }
    updateSyncConfigDetails = (datasyncdetails) => {
        var that = this;
        var datasyncdetailsobj = JSON.parse(datasyncdetails);
        function triggerClick(selector,evt) {
            let ev = new Event(evt, { bubbles: true });
                let el = $(selector)[0]
                el.dispatchEvent(ev);
          };
        if (datasyncdetailsobj.syncType == 'minutes') {
            $('input#custom').trigger("click");
            triggerClick('input#custom','click');
            $(".syncreport").val("minutes").change();
            $(".synccontainer").not(".minutes").hide();
            $(".minutes").removeClass('hide');
            $(".minutes").show();
            $(".minutes").find("input").val(datasyncdetailsobj.syncVal);
        }
        else if (datasyncdetailsobj.syncType == 'hours') {
            $('input#custom').trigger("click");
            triggerClick('input#custom','click');
            $(".syncreport").val("hours").change();
            $(".synccontainer").not(".hours").hide();
            $(".hours").removeClass('hide');
            $(".hours").show();
            $(".hours").find("input").val(datasyncdetailsobj.syncVal);
        }
        else if (datasyncdetailsobj.syncType == 'days') {
            $('input#custom').trigger("click");
            triggerClick('input#custom','click');
            $(".syncreport").val("days").change();
            $(".synccontainer").not(".days").hide();
            $(".days").removeClass('hide');
            $(".days").show();
            $(".days").find("input[type=number]").attr("value", datasyncdetailsobj.syncVal);
            $('#sync-day-time-start').val(datasyncdetailsobj.synctime);
        }
        else if (datasyncdetailsobj.syncType == 'seconds') {
            $('input#custom').trigger("click");
            triggerClick('input#custom','click');
            $(".syncreport").val("seconds").change();
            $(".synccontainer").not(".seconds").hide();
            $(".seconds").removeClass('hide');
            $(".seconds").show();
            $(".seconds").find("input[type=number]").val(datasyncdetailsobj.syncVal);
        }
        $('.sync-day-time').clockpicker({
            "default": 'now',
            placement: 'top',
            align: 'left',
            autoclose: true,
        });
    }
    changeSyncType = (e) => {
        $('.syncerror').addClass("hide");
        var $this = e.currentTarget;
        var datasyncchecked = $('.datasynctype:checked').attr("id");
        if (datasyncchecked === "defaultcontainer") {
            $(".custom").addClass('hide').removeClass('show');
            $(".defaultcontainer").addClass('show').removeClass('hide');
        }
        else if (datasyncchecked === "custom") {
            $(".defaultcontainer").addClass('hide').removeClass('show');
            $(".custom").addClass('show').removeClass('hide');
        }
        else {
            $(".datasync-container").hide();
        }
    }
    changeSyncOption = (e) => {
        $('.syncerror').addClass("hide");
        var $this = e.currentTarget;
        $($this).find("option:selected").each(function () {
            if ($(this).attr("value") === "minutes") {
                $(".synccontainer").not(".minutes").hide();
                $(".minutes").removeClass('hide');
                $(".minutes").show();
            } else if ($(this).attr("value") === "seconds") {
                $(".synccontainer").not(".seconds").hide();
                $(".seconds").removeClass('hide');
                $(".seconds").show();
            } else if ($(this).attr("value") === "hours") {
                $(".synccontainer").not(".hours").hide();
                $(".hours").removeClass('hide');
                $(".hours").show();
            }
            else if ($(this).attr("value") === "days") {
                $(".synccontainer").not(".days").hide();
                $(".days").removeClass('hide');
                $(".days").show();
            }
            else {
                $(".synccontainer").hide();
            }
            $('.sync-day-time').clockpicker({
                "default": 'now',
                placement: 'top',
                align: 'left',
                autoclose: true,
            }).on('keyup', function (e) {
                //if the letter is not digit then display error and don't type anything
                $('.sync-day-time').clockpicker('hide');
                var timeaftersplitting;
                var hours;
                var minutes;
                var timeSplit = $(".sync-day-time input").val();
                var errorbox;
                errorbox = "#errormsg";
                $(errorbox).html("").hide()
                var letters = /^[A-Za-z]+$/;
                if (letters.test(timeSplit) || letters.test(timeSplit.valueOf().charAt(1)) || letters.test(timeSplit.valueOf().charAt(4)) || letters.test(timeSplit.valueOf().charAt(0))) {
                    $(errorbox).html(lang["admin.sync.digitsonly"]).show().fadeOut(3000);
                    $("#sync-day-time-start").val("");
                    return false;
                } if ((timeSplit.valueOf().charAt(0) == ":" || timeSplit.valueOf().charAt(1) == ":" || timeSplit.valueOf().charAt(3) == ":" || timeSplit.valueOf().charAt(4) == ":") && timeSplit.length != 4) {
                    $(errorbox).html("").hide()
                    $(errorbox).html(lang["admin.sync.timeformat"]).show().fadeOut(3000);
                    $("#sync-day-time-start").val("");
                    return false;
                } else if (timeSplit.match(/([#;?&,%.+*~\'"!^$%[\]()<=>|\/@])/g)) {
                    $(errorbox).html("").hide()
                    $(errorbox).html(lang["admin.sync.spclchars"]).show().fadeOut(3000);
                    $("#sync-day-time-start").val("");
                    return false;
                } else if (timeSplit.match(/([-_{}])/g)) {
                    $(errorbox).html("").hide()
                    $(errorbox).html(lang["admin.sync.spclchars"]).show().fadeOut(3000);
                    $("#sync-day-time-start").val("");
                    return false;
                } else if (timeSplit.indexOf(":") > -1 && timeSplit.indexOf(":") != 2) {
                    $(errorbox).html("").hide()
                    $(errorbox).html(lang["admin.sync.timeformat"]).show();
                    return false;
                } else if (timeSplit.length > 0) {
                    if ((timeSplit.length == 5 || timeSplit.length == 4) && timeSplit.indexOf(":") < 0) {
                        $(errorbox).html("").hide()
                        $(errorbox).html(lang["admin.sync.timeformat"]).show().fadeOut(3000);
                        $("#sync-day-time-start").val("");
                        return false;
                    } else if (timeSplit.length == 1 && timeSplit > 2) {
                        timeSplit = 2;
                        $("#sync-day-time-start").val(timeSplit);
                        $(errorbox).html("").hide()
                        $(errorbox).html(lang["admin.sync.maxhrshould"]).show();
                        return false;
                    } else if (timeSplit.length == 1 && timeSplit <= 2) {
                        $(errorbox).html("").hide()
                        $(errorbox).html(lang["admin.sync.timeformat"]).show();
                        return false;
                    } else if (timeSplit.length == 2 && timeSplit > 23) {
                        timeSplit = 23;
                        $("#sync-day-time-start").val(timeSplit);
                        $(errorbox).html("").hide()
                        $(errorbox).html(lang["admin.sync.maxhrtimeformat"]).show();
                        return false;
                    } else if (timeSplit.length == 2 && timeSplit <= 23) {
                        $("#sync-day-time-start").val(timeSplit);
                        $(errorbox).html("").hide()
                        $(errorbox).html(lang["admin.sync.timeformat"]).show();
                        return false;
                    } else if (timeSplit.length == 4) {
                        if (timeSplit.indexOf(":") == 2) {
                            timeaftersplitting = timeSplit.split(":");
                            hours = timeaftersplitting[0];
                            minutes = timeaftersplitting[1];
                            if (hours.length < 2) {
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["admin.sync.timeformat"]).show();
                                return false;
                            } else if (hours > 23) {
                                hours = 23;
                                timeSplit = hours + ":" + minutes;
                                $("#sync-day-time-start").val(timeSplit);
                            } else if (minutes.length == 1 && minutes > 5) {
                                minutes = 5;
                                timeSplit = hours + ":" + minutes;
                                $("#sync-day-time-start").val(timeSplit);
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["admin.sync.maxminshould"]).show();
                                return false;
                            } else if (minutes.length == 1 && minutes <= 5) {
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["admin.sync.timeformat"]).show();
                                return false;
                            } else if (minutes.length == 2 && minutes > 59) {
                                minutes = 59;
                                timeSplit = hours + ":" + minutes;;
                                $("#sync-day-time-start").val(timeSplit);
                            } else if (letters.test(minutes.valueOf().charAt(0)) || letters.test(minutes.valueOf().charAt(1))) {
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["admin.sync.digitsonly"] ).show().fadeOut(3000);
                                $("#sync-day-time-start").val("");
                                return false;
                            } else if (letters.test(hours.valueOf().charAt(0)) || letters.test(hours.valueOf().charAt(1))) {
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["admin.sync.digitsonly"] ).show().fadeOut(3000);
                                $("#sync-day-time-start").val("");
                                return false;
                            } else if (minutes.length == 0) {
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["admin.sync.timeformat"]).show();
                                return false;
                            } else {
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["admin.sync.timeformat"]).show();
                                return false;
                            }

                        } else {
                            var timeSplit1 = timeSplit.valueOf().charAt(0) + timeSplit.valueOf().charAt(1);
                            var timeSplit2 = timeSplit.valueOf().charAt(3) + timeSplit.valueOf().charAt(4);
                            timeSplit = timeSplit1 + ":" + timeSplit2;
                            $("#sync-day-time-start").val(timeSplit);
                        }
                    } else if (timeSplit.length == 5 && timeSplit.indexOf(":") == 2) {
                        timeaftersplitting = timeSplit.split(":");
                        hours = timeaftersplitting[0];
                        minutes = timeaftersplitting[1];
                        if (hours > 23) {
                            hours = 23;
                            timeSplit = hours + ":" + minutes;
                            $("#sync-day-time-start").val(timeSplit);
                        } else if (minutes > 59) {
                            minutes = 59;
                            timeSplit = hours + ":" + minutes;
                            $("#sync-day-time-start").val(timeSplit);
                        } else if (hours.match(/([#;?&,%. +*~\'"!^$%[\]()<=>|\/@])/g) || hours.match(/([-_{}])/g)) {
                            $(errorbox).html("").hide()
                            $(errorbox).html(lang["admin.sync.spclchars"]).show().fadeOut(3000);
                            $("#sync-day-time-start").val("");
                            return false;
                        } else if (minutes.match(/([#;?&,%. +*~\'"!^$%[\]()<=>|\/@])/g) || minutes.match(/([-_{}])/g)) {
                            $(errorbox).html("").hide()
                            $(errorbox).html(lang["admin.sync.spclchars"]).show().fadeOut(3000);
                            $("#sync-day-time-start").val("");
                            return false;
                        } else if (letters.test(minutes.valueOf().charAt(0)) || letters.test(minutes.valueOf().charAt(1))) {
                            $(errorbox).html("").hide()
                            $(errorbox).html(lang["admin.sync.digitsonly"] ).show().fadeOut(3000);
                            $("#sync-day-time-start").val("");
                            return false;
                        } else if (letters.test(hours.valueOf().charAt(0)) || letters.test(hours.valueOf().charAt(1))) {
                            $(errorbox).html("").hide()
                            $(errorbox).html(lang["admin.sync.digitsonly"] ).show().fadeOut(3000);
                            $("#sync-day-time-start").val("");
                            return false;
                        }
                    } else if (timeSplit.length == 3) {
                        if (timeSplit.indexOf(":") == 2) {
                            timeaftersplitting = timeSplit.split(":");
                            hours = timeaftersplitting[0];
                            minutes = timeaftersplitting[1];
                            if (hours > 23) {
                                hours = 23;
                                timeSplit = hours + ":" + minutes;
                                $("#sync-day-time-start").val(timeSplit);
                            } else if (letters.test(hours.valueOf().charAt(0)) || letters.test(hours.valueOf().charAt(1))) {
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["admin.sync.digitsonly"] ).show().fadeOut(3000);
                                $("#sync-day-time-start").val("");
                                return false;
                            } else if (hours.length < 2) {
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["admin.sync.timeformat"]).show();
                                return false;
                            } else if (minutes.length < 2) {
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["admin.sync.timeformat"]).show();
                                return false;
                            } else if (hours.match(/([#;?&,%. +*~\'"!^$%[\]()<=>|\/@])/g) || hours.match(/([-_])/g)) {
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["admin.sync.spclchars"]).show().fadeOut(3000);
                                $("#sync-day-time-start").val("");
                                return false;
                            } else if (minutes.match(/([#;?&,%. +*~\'"!^$%[\]()<=>|\/@])/g) || minutes.match(/([-_])/g)) {
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["admin.sync.spclchars"]).show().fadeOut(3000);
                                $("#sync-day-time-start").val("");
                                return false;
                            } else if (letters.test(minutes.valueOf().charAt(0)) || letters.test(minutes.valueOf().charAt(1))) {
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["admin.sync.digitsonly"] ).show().fadeOut(3000);
                                $("#sync-day-time-start").val("");
                                return false;
                            }

                        } else if ((timeSplit.valueOf().charAt(0) + timeSplit.valueOf().charAt(1)) <= 23) {
                            var timeSplit1 = timeSplit.valueOf().charAt(0) + timeSplit.valueOf().charAt(1);
                            if (timeSplit.valueOf().charAt(2) > 5) {
                                var minute = 5;
                                timeSplit = timeSplit1 + ":" + minute;
                                $("#sync-day-time-start").val(timeSplit);
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["admin.sync.timeformat"]).show();
                                return false;
                            } else {
                                timeSplit = timeSplit1 + ":" + timeSplit.valueOf().charAt(2);
                                $("#sync-day-time-start").val(timeSplit);
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["admin.sync.timeformat"]).show();
                                return false;
                            }
                        } else {
                            $(errorbox).html("").hide()
                            $(errorbox).html(lang["admin.sync.timeformat"]).show();
                            return false;
                        }
                    } else {
                        if (timeSplit.length == 1) {
                            $(errorbox).html("").hide()
                            $(errorbox).html(lang["admin.sync.timeformat"]).show();
                            return false;
                        }
                    }
                }
            });
        });
    }
    render() {
        var that = this;
        var configs = [];
        if (this.state.configs != null && this.state.configs != 'null' && this.state.configs != "" && this.state.configs != undefined) {
            configs = this.state.configs;
            configs.map((conf, index) => {
                conf.corn_details = conf.sync_details != undefined && JSON.parse(conf.sync_details).cronDetails != undefined ? JSON.parse(conf.sync_details).cronDetails : "";
                conf.edit = <button className="btn btn-admin editconfig" id="editconfigid" type="button" value={conf.sync_id} onClick={that.editSyncConfig}>
                    <i className="fa fa-pencil"></i>
                </button>
            });
        }
        ReactDOM.render(<SyncConfigMainTemplate createSyncBucket={this.createSyncBucket} editSyncConfig={this.editSyncConfig} data={configs} totalRecords={configs.length} />, document.getElementById("syncconfig"))
        return (<React.Fragment></React.Fragment>);
    }
}
