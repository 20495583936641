import React from "react";
import { lang } from "../js/helpers/utils/languages/language";
import PubSub from 'pubsub-js';
import _ from "underscore";
const $ = window.$;

export default class CustomFieldModalViewTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    PreviewData(e,name,dispalyname,datatype){
        var appObj={};
        appObj["name"]=name;
        appObj["displayname"]=dispalyname;
        appObj["datatype"]=datatype;
        appObj["target"]=e;

        PubSub.publish("previewcolumndata",appObj);
    }
    render() {
        var that = this.props;
        return (
            //    customfieldsmodal.js
            <div className="modal fade left createcustommeasure in" id="createcustomfield" tabIndex="-1" role="dialog" aria-labelledby="createcustommeasure" style={{display:"block"}}>
               <div className="modal-backdrop fade in"></div>
                <div className="modal-dialog modal-lg modal-dialog-lg-center modal-dialog-center" style={{zIndex:"1050"}}>
                    <div className="modal-content">
                        <div className="modal-header boc-modal-hf-padding">
                            <button type="button" className="close white" id="close" onClick={that.closeCustomfieldswindow} aria-hidden="true" data-dismiss="modal">&times;</button>
                            <h4 className="modal-title"><i className="marg-righ-10 fa-orange">{lang["storyboard.fx"]}</i> {lang["storyboard.createcustom"]}</h4>
                        </div>
                        <div className="modal-body scrollset">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="row-same-height">
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-xs-height settingscontent">
                                            <div className="modal-height-fix">
                                                <div className="container-fluid"><legend className="subhead">{lang["storyboard.customfield"]}</legend>
                                                    <input type="name" id="custommeasurename" defaultValue="" className="modal-form-fields" placeholder={lang["storyboard.customname"]} required /></div>
                                                <span className='customaggregatedfieldname-error help-block marg-left-10 hide'>{lang["storyboard.validname"]}</span>
                                                <span className='custommeasurename-error help-block marg-left-10 hide'>{lang["storyboard.nameesxists"]}</span>
                                                <div className="marg-top-10" style={{ height: 'calc(100% - 120px)', overflow: "visible" }}>
                                                    <div className="container-fluid marg-top-5">
                                                        <legend className="subhead">{lang["storyboard.availfields"]}</legend>
                                                        <input className="form-control form-control-custom-field search-image" onKeyUp={this.searchCustomField} placeholder={lang["storyboard.search"]} id="searchinput" type="text" />
                                                    </div>
                                                    <ul className="list-group entitytype">

                                                        {that.dimensions.map((dimension, index) => {
                                                            return <li key={index} className="boc-list-item singleliitems customfields" id="customfieldssinglemax" data-name={dimension.columnName} doc-name={`doc${dimension.columnDisplayName}]`} data-type={dimension.tableDisplayType} title={dimension.columnDisplayName}>
                                                                {/* <!-- <span className="fa-stack fa-sm marg-righ-5"> <i className="fa fa-circle fa-stack-2x maxtheme5"></i> 
                                                        <%if(dimension.get('tableDisplayType') ==='number'){ %> 
                                                           <i className="fa fa-sort-numeric-asc fa-stack-1x fa-inverse"></i>
                                                               <%} else if(dimension.get('tableDisplayType') === 'date' || dimension.get('tableDisplayType') === 'datetime') {%>
                                                                <i  className="fa fa-calendar fa-stack-1x fa-inverse"></i> <%} else {%> 
                                                                <i className="fa fa-sort-alpha-asc fa-stack-1x fa-inverse"></i> <%}%>
                                                        </span> --> */}

                                                                {(dimension.tableDisplayType == "number") ? (
                                                                    <span className="itemtype">123</span>
                                                                ) : (dimension.tableDisplayType == "date" || dimension.tableDisplayType == "datetime" || dimension.tableDisplayType === 'month' || dimension.tableDisplayType === 'day') ? (
                                                                    <span className="itemtype"><i className='fa fa-calendar'></i></span>
                                                                ) :
                                                                        <span className="itemtype">{lang["storyboard.abc"]}</span>
                                                                }
                                                                <span className="itemname">{dimension.columnDisplayName}</span>
                                                                <span type="button" className="boclabel boclabel-right-5 opacity-effect previewcolumndata" data-name={dimension.columnName} data-type={dimension.tableDisplayType} data-displayname={dimension.columnDisplayName} title={lang["storyboard.previewdata"]} onClick={(e)=>{this.PreviewData(e,dimension.columnName,dimension.columnDisplayName,dimension.tableDisplayType)}}> <i className="fa fa-table"></i></span>
                                                            </li>
                                                        })}

                                                        {that.customparameters.map((customparameter, index) => {
                                                            return <li key={index} className="boc-list-item singleliitems customfields" id="customfieldssinglemax" data-name={customparameter.parametername} data-type="number" title={customparameter.parametername}>
                                                                <span className="itemtype">123</span>
                                                                <span className="itemname">{customparameter.parametername}</span>
                                                            </li>
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="gap"></div>
                                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 col-xs-height settingscontent ">
                                            <div className="modal-height-fix">
                                                <div className="col-xs-12">
                                                    <div className="container-fluid">
                                                        <div className="row">
                                                            <div className="row-same-height">
                                                                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-xs-height settingscontent nopadding">
                                                                    <legend className="subhead">{lang["storyboard.operators"]}</legend>
                                                                    <div className="marg-top-10">
                                                                        <ul className="list-group">
                                                                            <li className="viewreport-list-item operator">*</li>
                                                                            <li className="viewreport-list-item operator">+</li>
                                                                            <li className="viewreport-list-item operator">-</li>
                                                                            <li className="viewreport-list-item operator">/</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 col-xs-height settingscontent nopadding">
                                                                    <legend className="subhead">{lang["storyboard.funtypes"]}</legend>
                                                                    <select className="function-type-dropdown functiontypes" onChange={that.selectfunctiontypes} id="functiontypescustompop">
                                                                        <option value="string">{lang["storyboard.string"]}</option>
                                                                        <option value="int">{lang["storyboard.number"]}</option>
                                                                        <option value="date">{lang["storyboard.date"]}</option>
                                                                        <option value="logical">{lang["storyboard.logical"]}</option>
                                                                        <option value="raw">{lang["storyboard.raw"]}</option>

                                                                    </select>
                                                                    <div className="marg-top-10 functiondivs stringdiv">
                                                                        <ul className="list-group functiongroup">
                                                                            <li className="viewreport-list-item function" data-main="concat()">{lang["storyboard.concat"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="empty()">{lang["storyboard.empty"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="notempty()">{lang["storyboard.notempty"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="length()">{lang["storyboard.length"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="reverse()">{lang["storyboard.revrse"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="substring(,,)">{lang["storyboard.substr"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="appendtrailingcharifabsent(,)">{lang["storyboard.appendtrail"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="position(,)">{lang["storyboard.position"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="match(,)">{lang["storyboard.match"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="lower()">{lang["storyboard.lower"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="upper()">{lang["storyboard.upper"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="like(,)">{lang["storyboard.like"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="notlike(,)">{lang["storyboard.notlike"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="tostring(,)">{lang["storyboard.tostring"]}</li>

                                                                        </ul>
                                                                    </div>
                                                                    <div className="marg-top-10 functiondivs intigerdiv">
                                                                        <ul className="list-group functiongroup">
                                                                            <li className="viewreport-list-item function" data-main="e()">{lang["storyboard.e"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="pi()">{lang["storyboard.pi"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="exp()">{lang["storyboard.exp"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="log()">{lang["storyboard.log"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="exp2()">{lang["storyboard.exp2"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="log2()">{lang["storyboard.log2"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="exp10()">{lang["storyboard.exp10"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="log10()">{lang["storyboard.log10"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="sqrt()">{lang["storyboard.sqrt"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="cbrt()">{lang["storyboard.cbrt"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="erf()">{lang["storyboard.erf"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="erfc()">{lang["storyboard.erfc"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="sin()">{lang["storyboard.sin"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="cos()">{lang["storyboard.cos"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="tan()">{lang["storyboard.tan"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="atan()">{lang["storyboard.atan"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="acos()">{lang["storyboard.acos"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="asin()">{lang["storyboard.asin"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="pow(,)">{lang["storyboard.power"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="abs()">{lang["storyboard.abs"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="round()">{lang["storyboard.rnd"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="ceil()">{lang["storyboard.ceil"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="floor()">{lang["storyboard.floor"]}</li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="marg-top-10 functiondivs datediv">
                                                                        <ul className="list-group functiongroup">
                                                                            <li className="viewreport-list-item function" data-main="toDate()">{lang["storyboard.todate"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="toDateTime">{lang["storyboard.todatetime"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="toYear()">{lang["storyboard.toyear"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="toMonth()">{lang["storyboard.tomonth"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="toDayOfMonth()">{lang["storyboard.todaymon"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="toDayOfWeek()">{lang["storyboard.todayweek"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="toHour()">{lang["storyboard.tohr"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="toMinute()">{lang["storyboard.tomin"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="toSecond()">{lang["storyboard.tosec"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="toStartOfDay()">{lang["storyboard.tostartday"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="toMonday()">{lang["storyboard.tomonday"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="toStartOfMonth()">{lang["storyboard.tostartmon"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="toStartOfQuarter()">{lang["storyboard.tostartquatr"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="toStartOfYear()">{lang["storyboard.tostartyear"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="toStartOfMinute()">{lang["storyboard.tostartmin"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="toStartOfFiveMinute()">{lang["storyboard.tostartfivemin"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="toStartOfHour()">{lang["storyboard.tostarthr"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="toTime()">{lang["storyboard.totime"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="toRelativeYearNum()">{lang["storyboard.relyearnum"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="toRelativeMonthNum()">{lang["storyboard.relmonnum"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="toRelativeWeekNum()">{lang["storyboard.relweeknum"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="toRelativeDayNum()">{lang["storyboard.reldaynum"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="toRelativeHourNum()">{lang["storyboard.relhrnum"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="toRelativeMinuteNum()">{lang["storyboard.relminnum"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="toRelativeSecondNum()">{lang["storyboard.relsecnum"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="today()">{lang["storyboard.today"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="now()">{lang["storyboard.now"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="yesterday()">{lang["storyboard.yestrday"]}</li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="marg-top-10 functiondivs logicaldiv">
                                                                        <ul className="list-group functiongroup">
                                                                            <li className="viewreport-list-item function" data-main="case()">{lang["storyboard.case"]}</li>
                                                                            <li className="viewreport-list-item function" data-main="if(,,)">{lang["storyboard.if"]}</li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="marg-top-10 functiondivs rawdiv">
                                                                    </div>

                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 col-xs-height settingscontent nopadding">
                                                                    <legend className="subhead">{lang["storyboard.help"]}</legend>
                                                                    <div className="helpmenu marg-top-10"></div>
                                                                    <div className="marg-top-10 functiondivs dateformatdiv">
                                                                        <legend className="subhead">{lang["storyboard.choosedatefmt"]}</legend>
                                                                        <select className="function-type-dropdown dateformat">
                                                                            <option value="1">{lang["storyboard.dd/MM/yyyy"]}</option>
                                                                            <option value="2">{lang["storyboard.MM/dd/yyyy"]}</option>
                                                                            <option value="3">{lang["storyboard.yyyy/MM/dd"]}</option>
                                                                            <option value="4">{lang["storyboard.yyyy/dd/MM"]}</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-xs-12">
                                                        <legend className="subhead">{lang["storyboard.formulaeditor"]}</legend>
                                                        <div className="marg-top-10">
                                                            <div contentEditable="true" id="custommeasurequerydisplay" className="resize-vertical custommeasurequerydisplay" onPaste={this.pastedisble} placeholder={lang["storyboard.placeholderstart"]}></div>
                                                            <span className="customfield_formula_errorblock help-block hide">{lang["storyboard.fundisable"]}</span>
                                                            <button type="button" className="btn pull-right testformula marg-top-5 marg-left-5" onClick={that.testformula} id="validatepop" ><i className="fa fa-play"></i> {lang["storyboard.validate"]}</button>
                                                            <button type="button" className="btn pull-right clearcustommeasuretext marg-top-5" onClick={this.clearQueryArea} id="clearpop" ><i className="fa fa-cut fa-rotate-270"></i> {lang["storyboard.clear"]}</button>
                                                        </div>
                                                        <div className="clear"></div>
                                                        <div id="previewcustomscriptchart" className="marg-top-15"></div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer boc-modal-hf-padding">
                            <button type="button" className="btn btn-bird" id="btn-bird" onClick={that.closeCustomfieldswindow} data-dismiss="modal"><i className="fa fa-times"></i> {lang["storyboard.cancelbtn"]}</button>
                            <button type="button" className="btn btn-bird applycustomfield" onClick={that.createCustomField} id="applycustomfieldpop" ><i className="fa fa-check"></i> {lang["storyboard.applybtn"]}</button>
                        </div>
                    </div>
                    {/* <!-- /.modal-content --> */}
                </div>
                {/* <!-- /.modal-dialog --> */}
            </div>

        );
    }
    clearQueryArea(e) {
        $('#custommeasurequerydisplay').text("");
        $("#previewcustomscriptchart").empty();
    }
    pastedisble = (e)=> {
        e.preventDefault();
        /*$('.customfield_formula_errorblock').removeClass('hide');
        setTimeout(function () {
            $('.customfield_formula_errorblock').addClass('hide');
        }, 2000);*/
        var pastedText = (e.originalEvent || e).clipboardData.getData('text/plain');
        var that = this.props;
        var finalText = pastedText;
        var result = "";
        var finalArray = that.dimensions;
        _.each(finalArray, function (item) {
            var word = item.columnDisplayName;
            var columnName = item.columnName;
            var type = item.type;
            if (type == undefined) { type = item.tableDisplayType }
            result = "<span class='fa-orange customfieldlist' displayName = '" + word + "' columnName='" + columnName + "'>" + word + "</span>"; 
            var rgxp = new RegExp(`\\b(?<![\w\-\\d])${word}(?![\w\d]|[^<]*>*<\/)\\b`, 'gmi');
            // var rgxp = new RegExp('(?<![\w\-\\d])'+word+'(?![\w\d]|[^<]*>*<\/)', 'gmi');
            var repl = result
            finalText = finalText.replace(rgxp, repl);
        });
        var spacer = new RegExp("</span> ", 'g');
        finalText = finalText.replace(spacer, " </span>");
        $("#custommeasurequerydisplay").html(finalText);
    }

    searchCustomField() {
        /*Delay function for to wait until user stops typing*/
        var delay = (function () {
            var timer = 0;
            return function (callback, ms) {
                clearTimeout(timer);
                timer = setTimeout(callback, ms);
            };
        })();
        /*Delay function for to wait until user stops typing*/
        $(".form-control-custom-field").keyup(function () {
            var valThis = $(this).val().toLowerCase();
            delay(function () {
                $(".itemname").parents(".customfields").each(function () {
                    var text = $(this).text().toLowerCase();
                    (text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
                });
            }, 400);
        });
    }
}