import React from 'react';
import { lang } from "../../js/helpers/utils/languages/language";
const $=window.$;

export default class SummaryTableValueAttributeHtml extends React.Component {
    componentDidMount(){
$('.summarytableaggregation').click((e)=>{this.props.changeAggregation(e)})
    }
    render() {
        return (
            <li className="boc-list-item singleliitems summarytabledisplayvalues" title={this.props.columnDisplayName} data-parent={this.props.tableName} data-child={this.props.columnName} data-type={this.props.dataType}>
                <span className="itemtype">
                    {
                        (this.props.dataType === 'number') ? (
                            <>123</>
                        ) : (this.props.dataType === 'date' || this.props.dataType === 'datetime' || this.props.dataType === 'month' || this.props.dataType === 'day') ? (
                            <i className="fa fa-calendar"></i>
                        ) : (this.props.dataType === 'customhierarchy' || this.props.dataType === 'custommeasurehierarchy') ? (
                            <i className="fa fa-list"></i>
                        ) : (<>{lang["storyboard.abc"]}</>)
                    }
                </span>
                <span className="itemname summarytablevalueattribute" data-parent={this.props.tableName} data-child={this.props.columnName} data-type={this.props.dataType}>{this.props.columnDisplayName}</span>
                {
                    (this.props.dataType != 'customaggregatedfield' && this.props.dataType != 'custommeasurehierarchy') ? (
                        <>
                            {
                                (this.props.isDirect == 0 || this.props.isStoredProcedure == 0) ? (
                                    <span className="boclabel boclabel-right-5 dropdown-toggle marg-righ-15 agrregation-sm" type="button" id="summarytabledropdownaggregation" aria-expanded="false" data-toggle="dropdown">
                                        <span>{this.props.aggregation}</span>&nbsp;<i className="fa fa-angle-down"></i>
                                    </span>
                                ) : null
                            }
                        </>
                    ) : null
                }
                <span className="btn-group pull-right">
                    <button className="btn btn-transparent deletevalue" type="button" onClick={this.props.remove}>
                        <i className="fa fa-trash"></i></button></span>
                {
                    (this.props.dataType != 'customaggregatedfield' && this.props.dataType != 'custommeasurehierarchy') ? (
                        <ul aria-labelledby="dropdownaggregation" role="menu" className="dropdown-menu dropdown-inline">
                            {
                                (this.props.dataType != 'string' && this.props.dataType != 'date') ? (
                                    <>
                                        {
                                            (this.props.isDirect == 0 || this.props.isStoredProcedure == 0) ? (
                                                <>
                                                    <li className="aggregation-li-item summarytableaggregation aggsum" title={lang["storyboard.sum"]}>{lang["storyboard.sum"]}</li>
                                                    <li className="aggregation-li-item summarytableaggregation aggmin" title={lang["storyboard.minimum"]}>{lang["storyboard.minimum"]}</li>
                                                    <li className="aggregation-li-item summarytableaggregation aggmax" title={lang["storyboard.max"]}>{lang["storyboard.max"]}</li>
                                                    <li className="aggregation-li-item summarytableaggregation aggavg" title={lang["storyboard.avg"]}>{lang["storyboard.avg"]}</li>
                                                </>
                                            ) : null
                                        }
                                        {
                                            (this.props.isDirect == 0) ? (
                                                <>
                                                    <li className="aggregation-li-item summarytableaggregation aggstandarddeviation" title={lang["storyboard.standard"]}>{lang["storyboard.standard"]}</li>
                                                    <li className="aggregation-li-item summarytableaggregation aggmedian" title={lang["storyboard.median"]}>{lang["storyboard.median"]}</li>
                                                    <li className="aggregation-li-item summarytableaggregation aggpercentile" title={lang["storyboard.percentile"]} >{lang["storyboard.percentile"]}
			<span><input type="number" className="percentilebox" placeholder="1" min="25" max="100" value="25" /></span>
                                                    </li>
                                                </>
                                            ) : null
                                        }
                                    </>
                                ) : null
                            }
                            {
                                (this.props.isDirect == 0 || this.props.isStoredProcedure == 0) ? (
                                    <li className="aggregation-li-item summarytableaggregation aggcountdistinct" title={lang["storyboard.countdis"]}>{lang["storyboard.countdistinct"]}</li>
                                ) : null
                            }
                            {
                                (this.props.isDirect == 0 || this.props.isStoredProcedure == 0) ? (
                                    <li className="aggregation-li-item summarytableaggregation aggcount" title={lang["storyboard.count"]}>{lang["storyboard.countall"]}</li>
                                ) : null
                            }
                        </ul>
                    ) : null
                }
            </li>
        );
    }
}