import React, { Component } from "react";
import PubSub from 'pubsub-js'
import ReactDOM from "react-dom";
import _ from 'lodash';
import { getMessage } from '../js/helpers/utils/BirdMessage';
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;

export default class CustomHierarchyFieldView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicdiv: props.dynamicdiv,
            columnDisplayName: props.columnDisplayName,
            columnName: props.columnName,
            aggregation: props.aggregation
        };
        this.render();
    }
    render() {
        ReactDOM.render(<CustomHierarchyFieldViewTemplate
            dynamicdiv={this.state.dynamicdiv}
            columnDisplayName={this.state.columnDisplayName}
            columnName={this.state.columnName}
            dataType={this.state.dataType}
        ></CustomHierarchyFieldViewTemplate>, document.getElementById(this.state.dynamicdiv));
        return (<div></div>);
    }
}
class CustomHierarchyFieldViewTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        var that = this.props;
        return (
            <li key={that.dynamicdiv} className="boc-list-item singleliitems  custommeasurehierarchyfields attributesmeasures" data-type="custommeasurehierarchy" data-name={that.columnName} title={that.columnDisplayName}>
                <span className="itemtype">
                    <i className="fa fa-list"></i>
                </span>
                <span className="itemname">{that.columnDisplayName}</span>
                <span type="button" data-name={that.columnName} title={that.columnDisplayName} onClick={this.createcustomhierarchymeasure} className="opacity-effect createcustomhierarchymeasure" data-edit={lang["storyboard.edit"]}>
                    <i className="fa fa-pencil"></i>
                </span>
                <span type="button" data-name={that.columnName} title={that.columnDisplayName} onClick={this.deletecustomhierarchyfield} className="deletecustomhierarchyfield opacity-effect pull-right marg-left-10">
                    <i className="fa fa-trash"></i>
                </span>
            </li>
        );
    }
    createcustomhierarchymeasure(e) {
        PubSub.publish('createcustomhierarchymeasure', e.currentTarget);
    }
    deletecustomhierarchyfield(e) {
        var $this = e.currentTarget;
        $(".deleteyes").unbind("click");
        $(".deleteno").unbind("click");
        var result = $.grep(ChartInfo.functions.getMeasureHierarchy(), function (e) { return e.name != $($this).parent().attr("data-name") });
        ChartInfo.functions.setMeasureHierarchy(result);
        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
        $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
        $('.birdmessagebuttons').addClass('show');
        $('.birdmessage-center').addClass("message-top-in");
        $('.details').removeClass('show');
        $('.details').addClass('hide');
        $('.birdmessage h2').empty().append('Warning');
        $('.birdmessage-info').empty().text(getMessage('BirdWarning77'));
        $(".deleteyes").click(function () {
            $('.birdmessage-center').removeClass("message-top-in");
            $($this).parent().parent().remove();
            $('.yaxisattributes li[data-type=custommeasurehierarchy]').remove();
            $(".measurecount").text(parseInt($('.measurecount').text()) - 1);
        });
        $(".deleteno").click(function () {
            $('.birdmessage-center').removeClass("message-top-in");
        });
    }
}