import React from "react";
import { lang } from "../../js/helpers/utils/languages/language";
import _ from "underscore";
const $ = window.$;

export default class ViewMLParametersTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    periodsForecastKeyUp = (e) =>{
        e.stopPropagation();
        let $this = e.currentTarget;
        $($this).removeClass("bird-has-error")
        let value = $this.value
        if(value!=undefined&&value!=""&&parseInt(value)<=0){
            $($this).addClass("bird-has-error")
        }
    }
    render() {
        return (
            <div className="container-fluid fill-height">
                <div className="row fill-height">
                    <div className="col-xs-12">
                        <h2>{lang["mlreports.modelparameters"]}</h2>
                    </div>
                    <div className="col-xs-12 fill-height" id="mlmodelparametersbox">
                        <div className="container-fluid modal-body fill-height">
                            <div className="row fill-height">
                                <div className="row-same-height fill-height">
                                    <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12 col-xs-height settingscontent">
                                        <div className="col-xs-12">
                                            <h2>{lang["mlreports.choosemodel"]}</h2>
                                            <div className="container-fluid nopadding">
                                                <div className="col-lg-12 nopadding marg-top-10">
                                                    <select ref={this.props.selectChange} className="sort-attribute-name mlpredicttionmodal nomargin" id="mlpredictionsettingpop" onChange={this.props.mlPredicttionmodalChange}>
                                                        <option value="mlselect">{lang["mlreports.select"]}</option>
                                                        {this.props.modelNames.map((modelName,i) => {
                                                            return <option key={i} value={modelName.modelType}>{modelName.modelName}</option>
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <br />
                                            <div className="mlxandyaxis hide">
                                                <legend className="subhead mlxaxis mlxaxisforparametermodel hide">
                                                    <div className="attraxisnames">{lang["mlreports.X-axis"]}</div>
                                                    <button className="pull-right btn btn-transparent dropdown-toggle tooltip-left" data-tooltip={lang["mlreports.add"]} id="dropdownxaxis" aria-expanded="false" data-toggle="dropdown" onClick={this.props.showXAxisDropDown}>
                                                        <i className="fa fa-plus-circle"></i>
                                                    </button>
                                                    <ul className="list-group dropdown-menu dropdown-menu-right xaxisdropdown" aria-labelledby="dropdownxaxis" role="menu">
                                                    </ul>
                                                </legend>
                                                <div className="marg-top-10 mlxaxisforparametermodel hide">
                                                    <ul className="list-group mlpreidctxaxisattributes"></ul>
                                                </div>
                                                <div className="mlyaxis">
                                                    <legend className="subhead">
                                                        <div className="attraxisnames">{lang["mlreports.Y-axis"]}</div>
                                                        <button className="pull-right btn btn-transparent dropdown-toggle tooltip-left" data-tooltip={lang["mlreports.add"]} id="dropdownyaxis" aria-expanded="false" data-toggle="dropdown" onClick={this.props.showyAxisDropDown}>
                                                            <i className="fa fa-plus-circle"></i>
                                                        </button>
                                                        <ul className="list-group dropdown-menu dropdown-menu-right yaxisdropdown" aria-labelledby="dropdownyaxis" role="menu">
                                                        </ul>
                                                    </legend>
                                                    <div className="marg-top-10">
                                                        <ul className="list-group mlpredictyaxisattributes"></ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mltextvals hide">
                                                {/* <legend className="subhead marg-top-10">
                                                    <div className="attraxisnames">{lang["mlreports.textcolms"]}</div>
                                                    <button className="pull-right btn btn-transparent dropdown-toggle tooltip-left" data-tooltip={lang["mlreports.add"]} id="dropdowntextcols" aria-expanded="false" data-toggle="dropdown" onClick={this.props.showTextColumnsDropDown}>
                                                        <i className="fa fa-plus-circle"></i>
                                                    </button>
                                                    <ul className="list-group dropdown-menu dropdown-menu-right textcolsdropdown" aria-labelledby="dropdowntextcols" role="menu">
                                                    </ul>
                                                </legend>
                                                <div className="marg-top-10">
                                                    <ul className="list-group mltextcolumns"></ul>
                                                </div> */}
                                                <div className="container-fluid nopadding">
                                                <h2>{`Analysis type`}</h2>
                                                <div className="col-lg-12 nopadding marg-top-10">
                                                    <select  className="sort-attribute-name nomargin sentiment-analysis-type" onChange={this.props.mlsentimentmodalChange}>
                                                        <option value="Knowledge-based">Knowledge-based Approach</option>
                                                        {/* <option value="statistical">Statistical Approach</option> */}
                                                    </select>
                                                </div>
                                                </div>
                                            </div>

                                            <div className="mldatevals hide">
                                                <legend className="subhead marg-top-10">
                                                    <div className="attraxisnames">{lang["mlreports.datecolums"]}</div>
                                                    <button className="pull-right btn btn-transparent dropdown-toggle tooltip-left" data-tooltip={lang["mlreports.add"]} id="dropdowndatecols" aria-expanded="false" data-toggle="dropdown" onClick={this.props.showDateColumnsDropDown}>
                                                        <i className="fa fa-plus-circle"></i>
                                                    </button>
                                                    <ul className="list-group dropdown-menu dropdown-menu-right datecolsdropdown" aria-labelledby="dropdowndatecols" role="menu">
                                                    </ul>
                                                </legend>
                                                <div className="marg-top-10">
                                                    <ul className="list-group mldatecolumns"></ul>
                                                </div>
                                            </div>
                                            {this.props.inputParameters.map((inputParam) => {
                                                if (inputParam.modelType == 'automl') {
                                                    var inputparamval = JSON.parse(inputParam.inputParameter);
                                                    return (<div className="automlsetting hide">
                                                            <legend className="subheadpredection">{inputparamval[0].displayname} (sec)</legend>
                                                            <input type={inputparamval[0].displaytype} className="modal-form-fields form-field timeinterval" name={inputparamval[0].id} min={inputparamval[0].minval} max={inputparamval[0].maxval}/>
                                                            <legend className="subheadpredection">{inputparamval[1].displayname} (%)</legend>
                                                            <input type={inputparamval[1].displaytype} className="modal-form-fields form-field trainedtestsplit" name={inputparamval[1].id} min={inputparamval[1].minval} max={inputparamval[1].maxval}/>
															<div className="pull-right marg-top-20">
                                                                <button className="btn btn-bird active " onClick={this.props.autommlrun}>{lang["mlreports.AutoMLRun"]}</button>
                                                            </div>
                                                        </div>)
															}})}
                                        </div>
                                    </div>
                                    <div className="gap hidden-sm hidden-xs"></div>
                                    <div className="col-lg-9 col-md-8 col-sm-12 col-xs-12 col-xs-height settingscontent">
                                        <div className="col-xs-12">
                                            <div className="mlparameterlists aggregator hide">
                                                <legend className="subhead">{lang["mlreports.aggregator"]} :</legend>
                                                <br />
                                                <legend className="subheadpredection">{lang["mlreports.target_num"]}</legend>
                                                <input type="number" className="modal-form-fields form-field" name="target_num_exemplars" placeholder={lang["mlreports.placeholdtarget"]} defaultValue="" />
                                                <div className="clear"></div>
                                                <legend className="subheadpredection">{lang["mlreports.rel_tol"]}</legend>
                                                <input type="number" className="modal-form-fields form-field" name="rel_tol_num_exemplars" placeholder={lang["mlreports.placeholdrel_tol"]} defaultValue="" />
                                                <div className="clear"></div>
                                                <legend className="subheadpredection ">{lang["mlreports.categorical"]}</legend>
                                                <select className="sort-attribute-name form-field" name="categorical_encoding">
                                                    <option value="auto">{lang["mlreports.auto"]}</option>
                                                    <option value="enum">{lang["mlreports.enum"]}</option>
                                                    <option value="binary">{lang["mlreports.binary"]}</option>
                                                    <option value="onehotinternal">{lang["mlreports.internal"]}</option>
                                                    <option value="onehotexplicit">{lang["mlreports.explicit"]}</option>
                                                    <option value="eigen">{lang["mlreports.eigen"]}</option>
                                                    <option value="labelencoder">{lang["mlreports.labelencode"]}</option>
                                                    <option value="sortbyresponse">{lang["mlreports.sortresponse"]}</option>
                                                    <option value="enumlimited">{lang["mlreports.enumltd"]}</option>
                                                </select>
                                            </div>
                                            {/* Forecasting */}
                                            {this.props.inputParameters.map((inputParam) => {
                                                if (inputParam.modelType == 'forecasting') {
                                                    var inputparamval = JSON.parse(inputParam.inputParameter);
                                                    return (<div className="mlparameterlists mlforecasting hide">
                                                        <h2>{inputParam.modelName}:</h2>
                                                        <br />
                                                        <div className="col-lg-5 col-xs-12">
                                                            <legend className="subheadpredection">{inputparamval[0].displayname}</legend>
                                                            <input type={inputparamval[0].displaytype} className="modal-form-fields form-field periods_forecast" name={inputparamval[0].id} min={inputparamval[0].minval} max={inputparamval[0].maxval} onKeyUp={this.periodsForecastKeyUp} />
                                                            <select className="sort-attribute-name form-field forecastselectperiod" name={`select-period`}>
                                                               <option value="Days">Days</option>
                                                               <option value="Weeks">Weeks</option>
                                                               <option value="Months">Months</option>
                                                               <option value="Years">Years</option>
                                                            </select>
                                                            <div className="clear"></div>
                                                            <legend className="subheadpredection">{inputparamval[1].displayname}</legend>
                                                            <select className="sort-attribute-name form-field forecastingfamily" name={inputparamval[1].id}>
                                                                {inputparamval[1].value.map((val) => {
                                                                    return <option value={val.value}>{val.name}</option>
                                                                })}
                                                            </select>
                                                            <div className="clear"></div>
                                                            <div className="col-md-12 modeldatatype">
                                                                <input type="hidden" className={inputparamval[2].id} name={inputparamval[2].name} value={inputparamval[2].displaytype} /></div>
                                                            <div className="clear"></div>
                                                        </div>
                                                    </div>)
                                                }
                                            })
                                            }
                                            {/* Forecasting */}
                                            {/* <!-- GLM (Generalized Linear Modeling) --> */}
                                            {this.props.inputParameters.map((inputParam) => {
                                                if (inputParam.modelType == 'generalizedlinearmodeling') {
                                                    var inputparamval = JSON.parse(inputParam.inputParameter);
                                                    return (<div className="mlparameterlists mlgeneralizedlinearmodeling hide">
                                                        <h2>{inputParam.modelName}:</h2>
                                                        <br />
                                                        <div className="col-lg-5 col-xs-12">
                                                            <legend className="subheadpredection">{inputparamval[0].displayname}</legend>
                                                            <input type={inputparamval[0].displaytype} className="modal-form-fields form-field nfolds" name={inputparamval[0].id} min={inputparamval[0].minval} />
                                                            <div className="clear"></div>
                                                            <legend className="subheadpredection">{inputparamval[1].displayname}</legend>
                                                            <select className="sort-attribute-name form-field gmlfamily" name="{inputparamval[1].id}">
                                                                {inputparamval[1].value.map((val) => {
                                                                    return <option value={val.value}>{val.name}</option>
                                                                })}
                                                            </select>
                                                            <div className="clear"></div>
                                                            {/* <legend className="subheadpredection">{inputparamval[2].displayname}</legend>
                                                            <select className="sort-attribute-name form-field glmbalanceclassNamees" name={inputparamval[2].id}>
                                                                {inputparamval[2].value.map((val) => {
                                                                    return <option value={val.name}>{val.name}</option>
                                                                })}
                                                            </select> */}
                                                            <div className="col-md-12 modeldatatype">
                                                                <input type="hidden" className={inputparamval[2].id} name={inputparamval[2].name} value={inputparamval[2].displaytype} /></div>
                                                        </div>
                                                    </div>)
                                                    // <!-- /GLM (Generalized Linear Modeling) -->
                                                }
                                            })}
                                            {/* <!-- Random Forest --> */}
                                            <div className="mlparameterlists mlrandomforest hide">
                                                <h2>{lang["mlreports.randomforest"]} :</h2>
                                                <br />
                                            </div>
                                            {/* <!-- /Random Forest --> */}

                                            {/* <!-- Text Analysis --> */}


                                            {/* <!-- Logistic Regression --> */}

                                            {this.props.inputParameters.map((inputParam) => {
                                                if (inputParam.modelType == 'logisticregression') {
                                                    var inputparamval = JSON.parse(inputParam.inputParameter);
                                                    return (<div className="mlparameterlists logisticregression hide">
                                                        <h2>{inputParam.modelName}:</h2>
                                                        <br />
                                                        <div className="col-lg-5 col-xs-12">
                                                            <div className="col-md-12 modeldatatype">
                                                                <input type="hidden" className={inputparamval[0].id} name={inputparamval[0].name} defaultValue={inputparamval[0].displaytype} />
                                                            </div>
                                                        </div>
                                                    </div>)
                                                }
                                            })}
                                            {/*<!-- /Logistic Regression -->*/}
                                            {/* <!-- Neural Network --> */}
                                            <div className="mlparameterlists mlneuralnetwork hide">
                                                <legend className="subhead">{lang["mlreports.neutaln/w"]} :</legend>
                                                <br />
                                            </div>
                                            {/* <!-- /Neural Network --> */}

                                            {/* <!-- Decision Tree --> */}
                                            {this.props.inputParameters.map((inputParam) => {
                                                if (inputParam.modelType == 'decisiontree') {
                                                    var inputparamval = JSON.parse(inputParam.inputParameter);
                                                    return (<div className="mlparameterlists mldecisiontree hide">
                                                        <h2>{inputParam.modelName}:</h2>
                                                        <br />
                                                        <div className="col-lg-5 col-xs-12">
                                                        <legend className="subhead">{inputparamval[2].displayname}</legend>
                                                            <br />
                                                            <div className="clear"></div>
                                                            {/*<legend className="subhead">{inputparamval[0].displayname}</legend>
                                                            <br />
                                                            <div className="clear"></div>
                                                             <legend className="subheadpredection">{inputparamval[1].displayname}</legend>
                                                            <select className="sort-attribute-name form-field decisiontreemethod" name={inputparamval[1].id}>
                                                                {inputparamval[1].value.map((val) => {
                                                                    return <option value={val.value}>{val.name}</option>
                                                                })}
                                                            </select>
                                                            <div className="clear"></div> */}
                                                            <legend className="subheadpredection">{inputparamval[1].displayname}</legend>
                                                            <input type={inputparamval[1].displaytype} className="modal-form-fields form-field decisiontreenumber" name={inputparamval[1].id} min={inputparamval[1].minval} max={inputparamval[1].maxval} />
                                                            <div className="clear"></div>
                                                            {/* <legend className="subheadpredection">{inputparamval[3].displayname}</legend>
                                                            <input type={inputparamval[3].displaytype} className="modal-form-fields form-field decisiontreerepeats" name={inputparamval[3].id} min={inputparamval[3].minval} max={inputparamval[3].maxval} />
                                                            <div className="clear"></div> */}
                                                            
                                                            <legend className="subheadpredection">{inputparamval[3].displayname}</legend>
                                                            <select className="sort-attribute-name form-field decisiontreesplit" name={inputparamval[3].id}>
                                                                {inputparamval[3].value.map((val) => {
                                                                    return <option value={val.value}>{val.name}</option>
                                                                })}
                                                            </select>
                                                            <div className="clear"></div>
                                                            <legend className="subheadpredection">{inputparamval[4].displayname}</legend>
                                                            <input type={inputparamval[4].displaytype} className="modal-form-fields form-field decisiontreetunelength" name={inputparamval[4].id} min={inputparamval[4].minval} max={inputparamval[4].maxval} />
                                                            <div className="clear"></div>
                                                            <div className="col-md-12 modeldatatype">
                                                                <input type="hidden" className={inputparamval[5].id} name={inputparamval[5].name} defaultValue={inputparamval[5].displaytype} /></div>
                                                        </div>
                                                    </div>
                                                    )
                                                }
                                            })}
                                            {/* <!-- Decision Tree --> */}
                                            {/* <!-- Clustering --> */}
                                            {this.props.inputParameters.map((inputParam) => {
                                                if (inputParam.modelType == 'clustering') {
                                                    var inputparamval = JSON.parse(inputParam.inputParameter);
                                                    return (<div className="mlparameterlists mlclustering hide">
                                                        <h2>{inputParam.modelName}:</h2>
                                                        <br />
                                                        <div className="col-lg-5 col-xs-12">
                                                            <legend className="subheadpredection">{inputparamval[0].displayname}</legend>
                                                            <input type={inputparamval[0].displaytype} className="modal-form-fields form-field kvalue" name={inputparamval[0].id} from={inputparamval[0].fromval} min={inputparamval[0].minval} max={inputparamval[0].maxval} />
                                                            <div className="clear"></div>

                                                            <legend className="subheadpredection">{inputparamval[1].displayname}</legend>
                                                            <select className="sort-attribute-name form-field categoricalencoding" name={inputparamval[1].id}>

                                                                {inputparamval[1].value.map((val) => {
                                                                    return <option value={val.value}>{val.name}</option>
                                                                })}
                                                            </select>
                                                            <div className="clear"></div>

                                                            {/* <legend className="subheadpredection">{inputparamval[2].displayname}</legend>
                                                            <select className="sort-attribute-name form-field ignore_const_cols" name={inputparamval[2].id}>
                                                                {inputparamval[2].value.map((val) => {
                                                                    return <option value={val.name}>{val.name}</option>
                                                                })}
                                                            </select> *
                                                            <div className="clear"></div>

                                                            <legend className="subheadpredection">{inputparamval[2].displayname}</legend>
                                                            <select className="sort-attribute-name form-field score_each_iteration" name={inputparamval[3].id}>
                                                                {inputparamval[2].value.map((val) => {
                                                                    return <option value={val.name}>{val.name}</option>
                                                                })}
                                                            </select>
                                                            <div className="clear"></div>

                                                            <legend className="subheadpredection">{inputparamval[3].displayname}</legend>
                                                            <select className="sort-attribute-name form-field estimate_k" name="{inputparamval[4].id}">
                                                                {inputparamval[3].value.map((val) => {
                                                                    return <option value={val.name}>{val.name}</option>
                                                                })}
                                                            </select>
                                                            <div className="clear"></div>*/}

                                                            <legend className="subheadpredection">{inputparamval[2].displayname}</legend>
                                                            <input type={inputparamval[1].displaytype} className="modal-form-fields form-field max_iterations" name={inputparamval[2].id} min={inputparamval[2].minval} max={inputparamval[2].maxval} />
                                                            <div className="clear"></div>

                                                            <legend className="subheadpredection">{inputparamval[3].displayname}</legend>
                                                            <select className="sort-attribute-name form-field standardize" name={inputparamval[3].id}>
                                                                {inputparamval[3].value.map((val) => {
                                                                    return <option value="{val.name}">{val.name}</option>
                                                                })}
                                                            </select>
                                                            <div className="clear"></div>

                                                            {/* <legend className="subheadpredection">{inputparamval[7].displayname}</legend>
                                                            <input type={inputparamval[1].displaytype} className="modal-form-fields form-field max_runtime_secs" name={inputparamval[7].id} min={inputparamval[7].minval} max={inputparamval[7].maxval} />
                                                            <div className="clear"></div> */}
                                                        </div>
                                                    </div>
                                                    )
                                                }
                                            })}
                                            {/* <!-- /Clustering --> */}
                                            <div className="automlsettingcontent hide">
                                                <div className="automlkpi-container">
                                                    <div className="col-md-3 col-xs-12 col-xs-height" id="trained-models"></div>
                                                    <div className="col-md-3 col-xs-12 col-xs-height" id="training-time-taken"></div>
                                                    <div className="col-md-3 col-xs-12 col-xs-height" id="trained-data"></div>
                                                    <div className="col-md-3 col-xs-12 col-xs-height" id="automl-test-data"></div>
                                                </div>
                                                <div  id="trained-models-summarytable"></div>
                                            </div>
                                            {/* Sentiment Analysis Parameter */}
                                                    <div className="mlparameterlists mltextvals hide">
                                                        <h2 className="knowledgeval">Knowledge-based Approach&nbsp;:</h2>
                                                        <h2 className="statisticval hide">Statistical Approach&nbsp;:</h2>
                                                        <br />
                                                        <div className="knowledgeval"><i className="fa fa-info-circle"></i>&nbsp;&nbsp;Classifies text based on words that emanate emotion. Pre-defined sentiment column is not required for this type of Analysis.</div>
                                                        <div className="statisticval hide"><i className="fa fa-info-circle"></i>&nbsp;&nbsp;Utilizes ML Algorithms to predict the sentiment. It requires data to have a target column (sentiment of each text row) and a text column.​</div>
                                                        <br />
                                                        <div className="col-lg-5 col-xs-12">
                                                        <legend className="subhead marg-top-10">
                                                    <div className="attraxisnames">{`Text Column`}</div>
                                                    <button className="pull-right btn btn-transparent dropdown-toggle tooltip-left" data-tooltip={lang["mlreports.add"]} id="dropdowntextcols" aria-expanded="false" data-toggle="dropdown" onClick={this.props.showTextColumnsDropDown}>
                                                        <i className="fa fa-plus-circle"></i>
                                                    </button>
                                                    <ul className="list-group dropdown-menu dropdown-menu-right textcolsdropdown" aria-labelledby="dropdowntextcols" role="menu">
                                                    </ul>
                                                </legend>
                                                <div className="marg-top-10">
                                                    <ul className="list-group mltextcolumns"></ul>
                                                </div>
                                                {/* <div className="mlyaxis statisticval hide">
                                                    <legend className="subhead">
                                                        <div className="attraxisnames">{`Target Column`}</div>
                                                        <button className="pull-right btn btn-transparent dropdown-toggle tooltip-left" data-tooltip={lang["mlreports.add"]} id="dropdownyaxis" aria-expanded="false" data-toggle="dropdown" onClick={this.props.showyAxisDropDown}>
                                                            <i className="fa fa-plus-circle"></i>
                                                        </button>
                                                        <ul className="list-group dropdown-menu dropdown-menu-right yaxisdropdown" aria-labelledby="dropdownyaxis" role="menu">
                                                        </ul>
                                                    </legend>
                                                    <div className="marg-top-10">
                                                        <ul className="list-group mlpredictyaxisattributes"></ul>
                                                    </div>
                                                </div> */}
                                                <div className="container-fluid nopadding">
                                                <h2>{`Model Type`}</h2>
                                                <div className="col-lg-12 nopadding marg-top-10">
                                                    <select  className="sort-attribute-name nomargin knowledgeval sentiment-model-type">
                                                        <option value="auto">Auto</option>
                                                        <option value="textblob">TextBlob</option>
                                                        <option value="sentiword">SentiWord</option>
                                                        <option value="vader">Vader</option>
                                                    </select>
                                                    <select  className="sort-attribute-name nomargin statisticval sentiment-model-type hide">
                                                        <option value="auto">Auto</option>
                                                        <option value="svm">SVM</option>
                                                        <option value="naive bayes">Naive Bayes</option>
                                                    </select>
                                                </div>
                                                </div>  
                                                        </div>
                                                    </div>
                                            {/* Sentiment Analysis Parameter */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}