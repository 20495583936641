import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { functions } from '../js/helpers/utils/chartinfo';
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;
export default class SingleSavedCustomParameterFilters extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.state.schemaType = "";
        this.state.schemaType = "";
        this.state.customparameterfileds = this.props.customparameterfileds;
        this.render();
    }
    render() {
        if (this.state.customparameterfileds.length != 0 && this.state.customparameterfileds != undefined) {
            this.state.customparameterfileds.sort(function (a, b) {
                var x = a.parametername.toLowerCase();
                var y = b.parametername.toLowerCase();
                return x < y ? -1 : x > y ? 1 : 0;
            });
        }
        ReactDOM.render(<SingleSavedCustomParameterFiltersTemplate singlemodalcustomparameterapply={this.props.singlemodalcustomparameterapply} searchparameter={this.searchparameter} closemodal={this.closemodal} customparamterfields={this.state.customparameterfileds} />, document.getElementById("singlefiltercontent"));
        return (<div></div>);
    }
    closemodal = () => {
        ReactDOM.unmountComponentAtNode(document.getElementById("singlefiltercontent"));
    }
    searchparameter = (e) => {
        var $this = e.currentTarget;
        var valThis = $($this).val().toLowerCase();
        $("input:checkbox[class='singlereportcustomparameterfiltercheckbox']").parents(".boc-list-item").each(function () {
            var text = $(this).text().toLowerCase();
            (text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
        });
    }
}
class SingleSavedCustomParameterFiltersTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    componentDidMount() {
        $('.singlereportcustomparameterfiltercheckbox').click(function (e) {
            e.stopPropagation();
        })
    }
    render() {
        var that = this.props;
        return (
            // viewsinglesavedcustomparameterfilterview.js
            <div className="modal fade in" id="mySmallModal0" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" style={{ display: "block" }}>
                <div className="modal-backdrop fade in"></div>
                <div className="modal-dialog modal-sm modal-sm-400 modal-dialog-sm-center modal-dialog-center" style={{ zIndex: "1050" }}>
                    <div className="modal-content">
                        <div className="modal-header boc-modal-hf-padding">
                            <button type="button" className="close white" id="close" onClick={that.closemodal} data-dismiss="modal" aria-hidden="true">&times;</button>
                            <h4 className="modal-title"><i className="fa fa-sliders fa-rotate-270 fa-solid"></i>{lang["storyboard.chooseparameter"]}</h4>
                        </div>
                        <div className="modal-body scrollsetnew">
                            {(that.customparamterfields != 0) ?
                                <div className="container-fluid marg-top-5 nopadding">
                                    <input className="form-control form-control-single-filters search-image" onKeyUp={that.searchparameter} placeholder={lang["storyboard.search"]} id="searchinput" type="text" />
                                </div>
                                : null}
                            <div className="container-fluid">
                                {(that.customparamterfields != 0) ?
                                    <div className="row singlefiltercontent filtercontent dobyfilteritems">
                                        <ul className="list-group scrollsetul">
                                            {that.customparamterfields.map((customparamterfields, index) => {
                                                return <li key={index} className="boc-list-item" tite={customparamterfields.parametername}>
                                                    <label title={customparamterfields.parametername}><input type="checkbox" className="singlereportcustomparameterfiltercheckbox" name={customparamterfields.parametername} data-valuetype={customparamterfields.parametervaluetype} data-value={customparamterfields.parametercurrentvalue} /><i className="fa fa-sort-numeric-asc fa-filter-icon"></i>{customparamterfields.parametername}</label>
                                                </li>
                                            })}
                                        </ul>
                                    </div>
                                    :
                                    <div className="confirm-dailog queryinfo ">{lang["storyboard.noparameters"]}</div>
                                }
                            </div>
                        </div>
                        <div className="modal-footer boc-modal-hf-padding">
                            <button type="button" className="btn btn-bird" onClick={that.closemodal} id="btn-bird" data-dismiss="modal"><i className="fa fa-times"></i>{lang["storyboard.cancelbtn"]}</button>
                            <button type="button" className="btn btn-bird singlemodalcustomparameterapply" onClick={that.singlemodalcustomparameterapply} id="singlemodalcustomparameterapplyfitler"><i className="fa fa-check"></i>{lang["storyboard.applybtn"]}</button>
                        </div>
                    </div>
                    {/* <!-- /.modal-content --> */}
                </div>
                {/* <!-- /.modal-dialog --> */}
            </div>
            //    <!-- /.modal -->
        );
    }
}