import React from "react";
import $ from 'jquery/dist/jquery';
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import ReactDOM from "react-dom";
import _ from 'lodash';
import XAxisDropDownTemplate from "./xaxisdropdown.jsx"


export default class XaxisDropDownView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicdiv: props.dynamicdiv,
            addxaxisvalue: props.addxaxisvalue,
            columnDisplayName: props.columnDisplayName,
            columnName: props.columnName,
            dataType: props.dataType,
            tableName: props.tableName,
            xaxisidentity: props.xaxisidentity,
            customfield: props.customfield,
            chartType: props.chartType,
            multifact: (props.multifact == 'true'),
            multifactentities: (props.multifact == 'true') ? JSON.parse(props.multifactentities) : props.multifactentities,
        };
        this.render();
    }
    render() {
        var that = this;
        var disable = false;
        if (this.state.multifact) {
            var facttables = this.props.facttables;
            if (this.state.customfield == "1") {
                //iscustomfunction
                var iscfvalid = false;
                var customFields = JSON.parse(sessionStorage.getItem("customFields"));
                var customFieldObject = $.grep(customFields, function (e) { return e.name == that.state.columnName });
                if (customFieldObject != undefined && customFieldObject.length > 0) {
                    _.each(customFieldObject[0].usedFields, function (hierarchy) {
                        disable = ChartInfo.functions.getAllowedEntities(that.state.multifactentities, hierarchy, that.props.customfield, undefined, undefined, facttables, "xdropdown");
                        if (disable) {
                            iscfvalid = true;
                        }
                    });
                }
                if (iscfvalid) {
                    disable = true;
                }
            } else if (this.state.customfield == "3") {
                var rangeObject = $.grep(ChartInfo.functions.getRange(), function (e) { return e.name == that.state.columnName });
                if (rangeObject != undefined && rangeObject.length > 0) {
                    var customFields = JSON.parse(sessionStorage.getItem("customFields"));
                    if (customFields != null && customFields != undefined) {
                        var iscrvalid = false;
                        var customFieldObject = $.grep(customFields, function (e) { return e.name == rangeObject[0]["xAxisName"][0].columnName });
                        if (customFieldObject != undefined && customFieldObject.length > 0) {
                            _.each(customFieldObject[0].usedFields, function (hierarchy) {
                                disable = ChartInfo.functions.getAllowedEntities(that.state.multifactentities, hierarchy, that.props.customfield, undefined, undefined, facttables, "xdropdown");
                                if (disable) {
                                    iscrvalid = true;
                                }
                            });
                            if (iscrvalid) {
                                disable = true;
                            }
                        } else {
                            disable = ChartInfo.functions.getAllowedEntities(this.state.multifactentities, rangeObject[0]["xAxisName"][0].columnName, this.props.customfield, undefined, undefined, facttables, "xdropdown");
                        }
                    } else {
                        disable = ChartInfo.functions.getAllowedEntities(this.state.multifactentities, rangeObject[0]["xAxisName"][0].columnName, this.props.customfield, undefined, undefined, facttables, "xdropdown");
                    }




                }
            } else if (this.state.customfield == "2") {
                if (ChartInfo.functions.getcustomhierarchy() != undefined) {
                    var drilldownHierarchyObj = $.grep(ChartInfo.functions.getcustomhierarchy(), function (e) { 
                        return e.name == that.props.columnName
                     });
                    if (drilldownHierarchyObj != undefined && drilldownHierarchyObj[0].hierarchelist != undefined) {
                        var isdhvalid = false;
                        _.each(drilldownHierarchyObj[0].hierarchelist, function (hierarchy) {
                            var customFields = JSON.parse(sessionStorage.getItem("customFields"));
                            if (customFields != null && customFields != undefined) {
                                var customFieldObject = $.grep(customFields, function (e) { return e.name == hierarchy.columnName });
                                if (customFieldObject != undefined && customFieldObject.length > 0) {
                                    _.each(customFieldObject[0].usedFields, function (hierarchy) {
                                        disable = ChartInfo.functions.getAllowedEntities(that.state.multifactentities, hierarchy, that.props.customfield, undefined, undefined, facttables, "xdropdown");
                                        if (disable) {
                                            isdhvalid = true;
                                        }
                                    });
                                } else {
                                    disable = ChartInfo.functions.getAllowedEntities(that.state.multifactentities, hierarchy.columnName, that.props.customfield, undefined, undefined, facttables, "xdropdown");
                                    if (disable) {
                                        isdhvalid = true;
                                    }
                                }
                            } else {
                                disable = ChartInfo.functions.getAllowedEntities(that.state.multifactentities, hierarchy.columnName, that.props.customfield, undefined, undefined, facttables, "xdropdown");
                                if (disable) {
                                    isdhvalid = true;
                                }
                            }
                        });
                        if (isdhvalid) {
                            disable = true;
                        }
                    }
                }
            } else {
                disable = ChartInfo.functions.getAllowedEntities(this.state.multifactentities, this.state.columnName, this.props.customfield, undefined, undefined, facttables, "xdropdown");
            }
        }
        if (this.state.chartType == "wordcloud") {
            if (this.state.dataType == "string") {
                ReactDOM.render(<XAxisDropDownTemplate
                    dynamicdiv={this.state.dynamicdiv}
                    addxaxisvalue={this.state.addxaxisvalue}
                    columnDisplayName={this.state.columnDisplayName}
                    columnName={this.state.columnName}
                    dataType={this.state.dataType}
                    tableName={this.state.tableName}
                    xaxisidentity={this.state.xaxisidentity}
                    customfunction={this.state.customfield}
                    chartType={this.state.chartType}
                    disable={disable}
                ></XAxisDropDownTemplate>, document.getElementById(this.state.dynamicdiv));
            }
        } else {
            ReactDOM.render(<XAxisDropDownTemplate
                dynamicdiv={this.state.dynamicdiv}
                addxaxisvalue={this.state.addxaxisvalue}
                columnDisplayName={this.state.columnDisplayName}
                columnName={this.state.columnName}
                dataType={this.state.dataType}
                tableName={this.state.tableName}
                xaxisidentity={this.state.xaxisidentity}
                customfunction={this.state.customfield}
                chartType={this.state.chartType}
                disable={disable}
            ></XAxisDropDownTemplate>, document.getElementById(this.state.dynamicdiv));
        }
        return (<div></div>);
    }

}