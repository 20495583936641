import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import WorkspaceListTemplate from "./WorkspaceListTemplateHtml.jsx";
import { lang } from "../js/helpers/utils/languages/language";
import SendRequest from '../SendRequest';
import _ from 'underscore';
import { getMessage } from '../js/helpers/utils/BirdMessage';
const $ = window.$;

class WorkspaceListView extends Component {
    constructor(props) {
        super(props);
        this.state = { isFromModel: false};
        this.render();
    }

    getWorkspaceslist(page, search, pageDisplay, order, colName) {
        var index = 0;
        var that = this;
        var noOfRecords = $(".workspacerowlimtbutton .page-size").text() == "" ? 25: $(".workspacerowlimtbutton .page-size").text();
        var totalRecords = 0;
        if (page != undefined && page > 1) {
            index = (page - 1) * noOfRecords;
        }
        if (order == undefined) {
            order = 'asc';
        }
        if (colName == undefined) {
            colName = '';
        }
        this.dataFieldValue = colName;
        this.sortingOrder = order;
        this.page = page;
        var that = this
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getworkspaceslist",
            queryString: "?fromDate= " + "" + "&toDate= " + "" + "&index= " + index + "&noOfRecords=" + noOfRecords + "&searchCriteria=" + search + "&order=" + order + "&colName=" + colName +"&urltype=" + "getUserPaginationWorkspaces",
            sucessFunction: (response) => {
                if (response.hasOwnProperty('error')) {
                    $(".spinner").hide();
                } else {
                    var headerlist = response["header"];
                    var data = response["data"];
                    totalRecords = response["totalRecords"];
                    var keys = Object.keys(headerlist);
                    var totalPages;
                    if (pageDisplay == undefined) {
                        pageDisplay = 25;
                    }
                    if (totalRecords > 25) {
                        totalPages = Math.ceil(totalRecords / pageDisplay);
                    } else {
                        totalPages = 1;
                    }

                    _.each(data,function(val,index){
                        val.editWorkspace = <a href={`${that.props.url}/workspace/${btoa(val.workspace_id)}`} data-num={val.workspace_id} class="boc-snapshot-thumbnail reportview viewworkspace" tab-id="0" tab-name="" id="reportstoryboardview"><i class="fa fa-edit"></i></a>;
                        val.deleteWorkspace = <button type="button" onClick={(e) => { that.deleteworkspace(index, e) }} id="deleteworkspacelistiew" workspace-id={val.workspace_id} datahub-id={val.hub_id} workspace-title={val.workspace_name} data-num={val.workspace_id} tab-id="0" tab-name="" class="btn btn-transparent aldelete"><i class="fa fa-trash-o"></i></button>;	
                        val.createBusinessModel=<button type="button" class="btn btn-transparent createmodel" id={val.workspace_id} title={lang["workspace.createmodel"]} onClick={that.createBusinessModel}><i class="fa fa-plus"></i></button>                   
                    });
                    // $("#workspacelisttable").remove();           
                    // $("#workspacecontentcontainer .data-items-container").append('<div id="workspacelisttable"></div>')
                    ReactDOM.unmountComponentAtNode(document.getElementById("workspacetable"));
                    ReactDOM.render(<WorkspaceListTemplate searchkeyup={that.searchkeyup} deleteworkspace={that.deleteworkspace} deleteReport={that.deleteReport} url={that.props.url} createBusinessModel={that.createBusinessModel} search={search}  totalRecords={totalRecords} sizePerPage={pageDisplay} headerlist={headerlist}  data={data} />,document.querySelector("#workspacetable"));
                    if (search != '') {
                    $(".searchworkspacetable").focus();
                    }
                    $(".spinner").hide();

                }
            },
            rejectFunction: () => { },
            headers: requestHeaders

        });


    }
    deleteworkspace = (index, e) => {
        var that = this;
        var $this = e.currentTarget;
        var workspaceId = $($this).closest('.aldelete').attr("workspace-id");
        var workspaceTitle = $($this).closest('.aldelete').attr("workspace-title");
        var datahubid = $($this).closest('.aldelete').attr("datahub-id");
        $(".deleteyes").unbind("click");
        $(".deleteno").unbind("click");
        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
        $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
        $('.birdmessagebuttons').addClass('show');
        $('.birdmessage-center').addClass("message-top-in");
        $('.details').removeClass('show');
        $('.details').addClass('hide');
        $('.birdmessage h2').empty().append('Warning');
        $('.birdmessage-info').empty().text(getMessage('BirdWarning127'));
        $(".deleteyes").click(function () {
            $('.birdmessage-center').removeClass("message-top-in");
            $('.spinner').show();
            var data = new FormData();
            data.append("workspaceId", workspaceId);
            data.append("datahubId", datahubid);
            data.append("workspaceTitle", workspaceTitle);
            SendRequest({
                url: "deleteworkspace",
                queryString: "",
                body: data,
                sucessFunction: (response) => {
                    if (response.success == true) {
                        $('.spinner').hide();
                        // $($this).closest('.item-main-container').remove();
                        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Success');
                        $('.birdmessage-info').empty().text(getMessage('BirdWarning131'));
                        const workspacesArray = Object.assign([], that.state.workspaces);
                        workspacesArray.splice(index, 1);
                        that.setState({ workspaces: workspacesArray });
                        that.fetchDataModelsAndWorkspaces();
                    } else {
                        $(".spinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.errorTrace)
                    }
                },
                rejectFunction: (error) => { },
                headers: {
                    method: "post",
                    headers: new Headers({
                        "Content-Type": "application/json"
                    })
                }
            });
        });
        $(".deleteno").click(function () {
            $('.birdmessage-center').removeClass("message-top-in");
        });
    }
    createBusinessModel = (e) => {
        var $this = e.currentTarget;
        sessionStorage.setItem('workspaceId', $($this).attr("id"));
        this.props.history.push("/" + window.localStorage.getItem("appName") + "/welcome/models");
    }
    searchkeyup = (e) => {
        //console.log("in search block");
        var that = this;
        var delay = (function () {
            var timer = 0;
            return function (callback, ms) {
                clearTimeout(timer);
                timer = setTimeout(callback, ms);
            };
        })();
        var search = $(e.currentTarget).val().toLowerCase();
        var pageDisplaySearch = $("#pageDropDown").text();
        delay(function () {
            that.searchValue = $(".searchworkspacetable").val();
            that.getWorkspaceslist(undefined, that.searchValue, pageDisplaySearch, that.sortingOrder, that.dataFieldValue);
        }, 100);

        $(".searchcustomstoryboardtable").focus();
    }
    fetchDataModelsAndWorkspaces() {
        var that = this;
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };

        SendRequest({
            url: "getfeatures",
            queryString: "",
            sucessFunction: (features) => {
                that.state.features = features;
                SendRequest({
                    url: "getavailabledatamodels",
                    queryString: "",
                    sucessFunction: (props) => {
                        that.setState({ models: props });
                    },
                    rejectFunction: () => { },
                    headers: requestHeaders,
                })
                SendRequest({
                    url: "getavailableworkspaces",
                    queryString: "?isFromModel=" + that.state.isFromModel,
                    sucessFunction: (props) => {
                        that.setState({ workspaces: props });
                    },
                    rejectFunction: () => { },
                    headers: requestHeaders,
                })
            },
            rejectFunction: () => { },
            headers: {
                method: "get",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            }
        });
    }
    foldersselectchange= (e) => {
        var pageDisplaySearch = $("#pageDropDown").text();
        this.getWorkspaceslist(undefined, "", pageDisplaySearch, 'asc', undefined);
    }
    render() {
        this.getWorkspaceslist(undefined, "", 25, 'asc', undefined);
        return(
            <div class="col-xs-12 marg-top-10">
                <div class="notifications-table-container data-item">
                    <div class="bootstrap-table">
                        <div class="fixed-table-toolbar">
                            <div class="pull-right search">
                                <input class="form-control searchworkspacetable" type="text" placeholder={lang["workspace.search"]} defaultValue={this.search} onKeyUp={this.searchkeyup}/>
                            </div>
                        </div>
                    <div id="workspacetable">
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default WorkspaceListView;