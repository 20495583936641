import React from 'react';
import { lang } from "../js/helpers/utils/languages/language";
import Properties from '../Properties/ServerProperties'
const $ = window.$;
export default class DatahubHtml extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displayFooter: props.displayFooter
        }
        this.navdatahub = this.navdatahub.bind(this);
        this.navbusinessworkspaces = this.navbusinessworkspaces.bind(this);
        this.navbusinessmodels = this.navbusinessmodels.bind(this);
        this.navnotebooks = this.navnotebooks.bind(this);
    }
    componentDidMount(){
        setTimeout(function () { $("a[href='#next']").parent().addClass("disabled")}, 10);
    }
    navbusinessworkspaces() {
        $("body").removeClass("datahubpage").addClass("datamainpage");
        this.props.history.push(`/${window.localStorage.getItem("appName")}/welcome/businessworkspaces`);
    }
    navdatahub() {
        $("body").removeClass("datamainpage").addClass("datahubpage")
        this.props.history.push(`/${window.localStorage.getItem("appName")}/welcome/datahub`);
    }
    navbusinessmodels() {
        $("body").removeClass("datahubpage").addClass("datamainpage")
        this.props.history.push(`/${window.localStorage.getItem("appName")}/welcome/businessmodels`);
    }
    navnotebooks(){
        window.open(Properties.sqlnotebook, '_blank');
    }
    datahubsynclogs=(e)=>{
        var location = window.location;
        var href = location.origin + "/" + window.localStorage.getItem("appName") + "/welcome/datahub_sync_logbook"
        window.open(href, '_blank');
    }
    render() {
        this.state.features = [];
        let featuresString = sessionStorage.getItem("features");
		if (featuresString != null && featuresString != undefined && featuresString != "") {
			featuresString = featuresString.slice(0, 3) + featuresString.slice(4);
			let decryptedString = window.atob(featuresString);
			this.state.features = JSON.parse(decryptedString);
		}
        return (
            <React.Fragment>
                {/* <!-- Start of datahubview.js --> */}
                <div className="col-xs-12 sub-nav" style={{ top: '46px', zIndex: '9', background: '#fff', textAlign: 'center', maxHeight: '33px' }}>
                    <div className="scrollfix" id="myScrollspy">
                        <ul className="nav nav-tabs nav-stacked affix-top" data-spy="affix" data-offset-top="0">
                            <li className="active" title={lang["datahub.title"]} id="navdatahub" onClick={this.navdatahub}><a><i className="fa fa-database fa-solid fa-fw sidepin"></i><span>{lang["datahub.title"]}</span></a></li>
                            <li title={lang["datahub.workspcetitle"]} id="navbusinessworkspaces" onClick={this.navbusinessworkspaces}><a><i className="fa fa-cubes fa-solid fa-fw sidepin"></i><span>{lang["datahub.workspcetitle"]}</span></a></li>
                            <li title={lang["datahub.modeltitle"]} id="navbusinessmodels" onClick={this.navbusinessmodels}><a><i className="fa fa-cube fa-solid fa-fw sidepin"></i><span>{lang["datahub.modeltitle"]}</span></a></li>
                            {this.state.features.map((featuresModule) => {
                                return  featuresModule.moduleName == "note_book"?
                                    featuresModule.features.map((feature,index) => {
                                         return (feature.featureName == 'new_note_book' && feature.allow == true) ?
                                     <li title={lang["datahub.notebook"]} id="navnotebooks" onClick={this.navnotebooks}><a><i className="fa fa-file fa-solid fa-fw sidepin"></i><span>{lang["datahub.notebook"]}</span></a></li>
                                     :<React.Fragment/>}):<React.Fragment/>})}
                        </ul>
                    </div>
                </div>
                <section className="datamain-section-container">
                    <div className="boc-home">
                        <div className="datamain-fixed-head-container">
                            <span className="subhead">
                                <i className="fa fa-database marg-righ-10 fa-solid"></i>
                                <span>{lang["datahub.title"]}</span>
                            </span>
                            {this.state.features.map((feature, index) => {
				                if (feature.moduleName === "data_hub") {
					                return feature.features.map((feature, index) => {
						                if (feature.featureName === "sync_log_book" && feature.allow === true) {
							                return (
                                                <button className="btn pull-right data-main-button" onClick={this.datahubsynclogs}><i className="fa fa-file-archive-o"></i> {lang["datahub.synopsis"]}</button>
                                            )
                                        }
                                    })
                                }
                             })
                            }
                            </div>
                        <div className="datamain-fixed-content-container">
                            <div id="datahubmaincontainer">
                                <div id="datahubstepscontainer">
                                    <h3>{lang["datahub.slctentities"]}</h3>
                                    <section>
                                        <div id="datahubconnectionscontainer"></div>
                                    </section>
                                    <h3>{lang["datahub.synconfig"]}</h3>
                                    <section>
                                        <div id="datahubsyncconfigcontainer"></div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {(this.state.displayFooter !== false) ? (
                    <footer className="footer">
                        <div className="container-fluid">
                            {/* <!-- <p className="text-muted"><%=whitelableMap["footertext"]%></p> --> */}
                            <div className="row">
                                <div className="col-xs-7">
                                    {/* <!-- <p className="text-muted"><%=whitelableMap["footertext"]%></p> --> */}
                                    {/* <!-- <div className="timezone hide pull-right">
                                    <span className="servertime"><b>Server Time:</b></span> <span
                                        className="servertimezone" style="margin-right: 20px">Fri Sep
                    27 2019 10:37:00 GMT+0530 </span> <span className="localtime"><b>Client
                        Time:</b></span> <span className="localtimezone"> Fri Sep 27 2019
                    10:37:00 GMT+0530 </span>
                                </div> --> */}
                                </div>
                            </div>
                        </div>
                    </footer>
                ) : null}
                <div id="storedprocmodal"></div>
                {/* <!-- End of datahubview.js --> */}
            </React.Fragment>
        );
    }
}