import React from 'react';
import ReactDOM from 'react-dom';
import DataHub from './Datahub';
import LogfilesTablesHtml from './LogfilesTablesHtml.jsx';
import LogfileConnectionHtml from './LogfileConnectionHtml.jsx';
import SendRequest from '../SendRequest';
import {getMessage} from '../js/helpers/utils/BirdMessage';
import { lang } from "../js/helpers/utils/languages/language";
const $=window.$;

export default class LogfileConnection extends React.Component{
    constructor(props){
        super(props);
        this.state={
            customLogsCollection:[],
            connectiondetails:{},
        }
        this.schemaId = props.schemaId;
        this.schemaName = props.schemaName;
        this.connectionId=props.connectionId;
        this.schemaDisplayName= props.schemaDisplayName;
        this.uploadsize=props.connectiondetails[0].uploadsize;
        this.allow=props.connectiondetails[0].allow;
        this.isFromEditFlatFiles=props.isFromEditFlatFiles;
        this.scopename=props.scopename;
        this.ftpurl=props.ftpurl;
        this.ftpusername=props.ftpusername;
        this.ftppassword=props.ftppassword;
        this.editfilename=props.editfilename;
        this.ftpfilename=props.ftpfilename;
        this.ftplogtype=props.logtype;
        this.logpattern=props.logpattern;
        this.uploadtype=props.uploadtype;
        this.connectionname=props.connectiondetails[0].connectionname;
    }
    requestHeaders = {
        method: "get",
        headers: new Headers({
            "Content-Type": "application/json"
        })
    };
    componentDidMount(){
        SendRequest({
            url:"getallcustomlogs",
            queryString:"?scopeName="+this.scopename,
            sucessFunction:(response)=>{
                this.setState({
                    customLogsCollection:response
                });
                SendRequest({
                    url:"schemadetails",
                    queryString:"?schemaId="+this.schemaId,
                    sucessFunction:(response)=>{
                        this.setState({
                            connectiondetails:response.connectiondetails
                        });
                    },
                    rejectFunction:(error)=>{},
                    headers:this.requestHeaders
                });
            },
            rejectFunction:(error)=>{},
            headers:this.requestHeaders
        });
        $('.datasourceconnection-container').find("#fileupload").fileinput({
            'showUpload':false,
            'showPreview':false, 
            'maxFileSize':this.uploadsize*1024,
            'msgSizeTooLarge':'<span class="text-danger"><i class="glyphicon glyphicon-exclamation-sign"></i>'+lang["Datahub.exceedsmaxsize"]+'</span>'
        });
        if(this.isFromEditFlatFiles===true){
            $("#logtypeselectadd").val(this.ftplogtype).change();
        }
    }
    logfilemethod=(e)=>{
        var $this = e.currentTarget;
        var val = $($this).val();
        if(val==="ftp"){
            $(".ftpurl").removeClass("hide");
            $(".ftpbrowse").addClass("hide");
        }else if( val==="sftp"){
            $(".ftpurl").removeClass("hide");
            $(".ftpbrowse").addClass("hide");
        }
        else{
            $(".ftpurl").addClass("hide");
            $(".ftpbrowse").removeClass("hide");
            $('.browselogfiles').addClass('disabled');
        }
    }
    browselogfiles=(e)=>{
        e.preventDefault();
        e.stopPropagation();
        var fileInfo;
        $('.spinner').show();
        this.ftptestconnection(e,false,true);
    }
    valid=()=>{
        var returnValue = true;
        if($('.logconnectionname').val()==="" || $('.logconnectionname').val()===undefined){
            $('.connectionnameerror').removeClass("hide");
            return false;
        }
        if($('.ftpfileurl').val()==='' ||$('.ftpfileurl').val()===undefined){
            $(".ftpurlemptyerror").removeClass("hide");
            returnValue = false;
        }
        if($('.ftpusername').val()===''){
            $(".ftpusernameemptyerror").removeClass("hide");
            returnValue = false;
        }
        if($('.ftppassword').val()===''){
            $(".ftppasswordemptyerror").removeClass("hide");
            returnValue = false;
        }
        return returnValue;
    }
    ftptestconnection=(e,successmessage,isbrowselogfiles)=>{
        e.preventDefault();
        e.stopPropagation();
        var $this = e.currentTarget;
        this.ftpurl=$('.ftpfileurl').val();
        this.ftpusername=$('.ftpusername').val();
        this.ftppassword=$('.ftppassword').val();
        this.selectedfiletype=$('input[name="logfilelocation"]:checked').val();
        if(this.selectedfiletype==="ftp"){
            this.uploadfiletype="log_ftp";
         }else if(this.selectedfiletype==="sftp"){
             this.uploadfiletype="log_sftp";
         }else{
             this.uploadfiletype="log";
         }
        if(this.valid()){
            $(".ftpurlemptyerror").addClass("hide");
            $(".ftpusernameemptyerror").addClass("hide");
            $(".ftppasswordemptyerror").addClass("hide");
            $('.connectionnameerror').addClass("hide");
            var tempObj = {};
            var data = {};
            tempObj["ftpurl"] = this.ftpurl;
            tempObj["delimiter"] = this.props.delimiter; 
            tempObj["connectionaccessid"] =null;
            tempObj["logPattern"] = this.logpattern;
            tempObj["ftpusername"] = this.ftpusername;
            tempObj["ftppassword"] = this.ftppassword;
            tempObj["command"] = "testConnection";
            tempObj["connectionName"] = "log";
            tempObj["uploadfiletype"]=this.uploadfiletype;
            data["connectionObject"] = tempObj;
            data["schemaId"] = this.schemaId;
            SendRequest({
                url:"entities",
                queryString: "?connectionObject="+encodeURIComponent(JSON.stringify(tempObj))+"&schemaId="+this.schemaId,
            // body:data,
                sucessFunction:(response)=>{
                    this.response=response;
                    if(response.status==='error'||response.hasOwnProperty('error')||response.success===false){
                        $('.spinner').hide();
                        setTimeout(function(){
                            $(".logfileprocessor").steps("previous");
                        },800);
                        $('.browselogfiles').addClass('disabled');
                        $('.notifications-table-container').empty();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Warning');
                        $('.birdmessage-info').empty().text(getMessage('BirdWarning83'));
                        return false;
                    }else{
                        if(successmessage===false){
                            $('.browselogfiles').removeClass('disabled');
                        }else{
                            $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                                $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                                $('.birdmessagebuttons').removeClass('show');
                                $('.birdmessagebuttons').addClass('hide');
                                $('.birdmessage-center').addClass("message-top-in");
                                $('.details').removeClass('show');
                                $('.details').addClass('hide');
                                $('.birdmessage h2').empty().append('Success');
                                $('.birdmessage-info').empty().text(getMessage('BirdSuccess41'));
                                sessionStorage.setItem("changesdone",0);
                                $('.browselogfiles').removeClass('disabled');
                        }
                        if(isbrowselogfiles){
                            $(".notifications-table-container").removeClass("hide");
                            var tempObj={};
                            var data={};
                            tempObj["ftpurl"] = this.ftpurl;
                            tempObj["delimiter"] = this.props.delimiter;
                            tempObj["connectionaccessid"] = null;
                            tempObj["connectionName"] = "log";
                            tempObj["logPattern"] = this.logpattern;
                            tempObj["ftpusername"] = this.ftpusername;
                            tempObj["ftppassword"] = this.ftppassword;
                            tempObj["command"] = "getListOfLogFiles";
                            tempObj["uploadfiletype"]=this.uploadfiletype;
                            tempObj["isFromEditFlatFiles"]=this.isFromEditFlatFiles;
                            tempObj["ftpfilename"]=this.ftpfilename;
                            data["connectionObject"] = tempObj;
                            data["schemaId"] = this.schemaId;
                            SendRequest({
                                url:"entities",
                                queryString: "?connectionObject="+encodeURIComponent(JSON.stringify(tempObj))+"&schemaId="+this.schemaId,
                               // body:data,
                                sucessFunction:(response)=>{
                                    if(response == undefined || response == null || response.length<=0){
                                        $(".spinner").hide();
                                        return;
                                    }
                                    this.response=response;
                                    var isFromEditFlatFiles=this.isFromEditFlatFiles;
                                    var fileName=response[0].fileName;
                                    var fileCreatedDate=response[0].fileCreatedDate;
                                    var fileSize=response[0].fileSize;
                                    if(response.hasOwnProperty('error')){
                                        $(".spinner").hide();
                                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                                        $('.birdmessagebuttons').removeClass('show');
                                        $('.birdmessagebuttons').addClass('hide');
                                        $('.birdmessage-center').addClass("message-top-in");
                                        $('.details').removeClass('hide');
                                        $('.birdmessage h2').empty().append('Error : ');
                                        $('.birdmessage h2').append('<span class="errorcode">');
                                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                                        $('.birdmessage-info').empty().text(response.error);
                                        $('.details').addClass('show');
                                        $('.messagedetails xmp').empty().append(response.errorTrace)
                                    }else{
                                        $('.spinner').hide();
                                        ReactDOM.render(
                                            <LogfilesTablesHtml
                                                filesCollection={response}
                                                fileName={fileName}
                                                isFromEditFlatFiles={isFromEditFlatFiles}
                                                fileCreatedDate={fileCreatedDate}
                                                fileSize={fileSize}
                                            />,
                                            document.querySelector('.notifications-table-container')
                                        );
                                        $("#logfiletable").bootstrapTable();
                                    }
                                },
                                rejectFunction:()=>{},
                                headers:this.requestHeaders
                            });
                        }
                    }
                },
                rejectFunction:()=>{},
                headers:this.requestHeaders
            })
        }
    }
    logtypeselect=(e)=>{
        e.preventDefault();
        e.stopPropagation();
        var $this = e.currentTarget;
        var logExample;
        var logSyntax;
        var customformat;
        this.logType=$($this).val();
        if(this.logType==="Select Log Type"){
            $(".customlogformatdiv").addClass("hide");
            $(".syntaxes").addClass("hide");
            $('.logexamples').addClass("hide");
            $(".customlogformatdata").empty();
            $('.logexample').empty();
            $('.logsyntax').empty();
            this.logpattern="";
        }else if(this.logType==="customlog"){
            $(".syntaxes").addClass("hide");
            $(".logexamples").addClass("hide");
            $(".customlogformatdiv").removeClass("hide");
            SendRequest({
                url:"getloginfo",
                queryString:"?logType="+this.logType+"&&scopeName="+this.schemaName,
                sucessFunction:(response)=>{
                    this.logpattern=response.logPattern;
                    if(this.logpattern!==undefined){
                        $('.customlogformatdata').html(this.logpattern);
                    }else if(this.isImportedReport && this.parsedConnectionData!==undefined && this.parsedConnectionData!==null && this.parsedConnectionData.logPattern!==undefined ){
                        $('.customlogformatdata').html(this.parsedConnectionData.logPattern);
                    }
                    $('.spinner').hide();
                },
                rejectFunction: (error) => {
                    console.log("can't process /getloginfo request"+error.message);
                },
                headers: this.requestHeaders
            });
        }else{
            $(".customlogformatdiv").addClass("hide");
            $(".syntaxes").removeClass("hide");
            $('.logexamples').removeClass("hide");
            SendRequest({
                url:"getloginfo",
                queryString:"?logType="+this.logType+"&&scopeName="+this.schemaName,
                sucessFunction:(response)=>{
                    this.logpattern=response.logPattern
                    logSyntax=response.logSyntax;
                    logExample=response.logExample;
                    $('.logsyntax').html(this.logpattern);
                    if(logExample===undefined){
                        $(".logexamples").addClass("hide");
                    }else{
                        $('.logexample').html(logExample);
                    }
                    $('.spinner').hide();
                },
                rejectFunction:(error)=>{},
                headers:this.requestHeaders
            });
        }
    }
    savenewlogconnection=(e)=>{
        $(".spinner").show();
        var connectionName=$('.logconnectionname').val();
        if(connectionName==="" || connectionName===undefined){
            $(".connectionnameerrors").hide();
            $(".connectionnameerror").removeClass("hide").show();
            $(".spinner").hide();
            return false;
        }
        else if($('.logconnectionname').val().replace(/\s+/g, '').length == 0){
            $(".connectionnameerror").hide();
            $(".connectionnameerrors").show();
            $('.spinner').hide();
              return;
        }else{
            if(/[^a-zA-Z0-9_ ]/.test(connectionName)){
               	$(".connectionnameerror").hide();
                $(".connectionnameerrors").show();
                $('.spinner').hide();
                return;
                }else{
                $(".connectionnameerror").hide();
                $(".connectionnameerrors").hide();
                }
        }
        this.selectedfiletype=$('input[name="logfilelocation"]:checked').val();
        if($('input[name="logfilelocation"]:checked').val()==="browsefile"){
            if($("#fileupload").val()===""||$("#fileupload").val()===undefined){
                $(".csverror").show();
                $(".spinner").hide();
                //return false;
            }else if(this.logType===undefined || this.logType==="Select Log Type"){
                $('.spinner').hide();
                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass('birdmessage-error');
                $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-times-circle-o');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Warning');
                $('.birdmessage-info').empty().text(getMessage('BirdWarning82'));
                return false;
            }else{
                $('.connectionnameerror').addClass("hide");
                $('.connectionnameerrors').hide();
                $('.csverror').hide();
                var fileName=$("#fileupload").prop("files")[0].name;
                var filesize=$("#fileupload").prop("files")[0].size;
                var text="";
                var filename=fileName.slice(0,fileName.lastIndexOf("."));
                if( /[^a-zA-Z0-9_ ]/.test( filename)){
                    text=" *.log ";
                    this.validateflatfilesForSpecialChars(text);
                    return false;
                }else{
                   $('.csverror').hide();
                     }
                if(fileName.substr(fileName.lastIndexOf(".") + 1)=="log" && filesize>0){
                    this.uploadlog("uploadcsv",false);
                    return true;
                }
                else{
                    $('.spinner').hide();
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('show');
                    $('.details').addClass('hide');
                    $('.birdmessage h2').empty().append('Warning');
                    $('.birdmessage-info').empty().text(getMessage('BirdWarning80'));
                    if(filesize==0){
                        $('.birdmessage-info').empty().text(getMessage('BirdWarning84'));
                    }
                    return false;
                }
            }
        }else{
            this.ftptestconnection(e,false);
            this.selectedfiletype=$('input[name="logfilelocation"]:checked').val();
            //that.browselogfiles(e,false);
            this.ftpfilename=$('input[name="selectedlogfile"]:checked').val();
            if(this.ftpfilename!==undefined){
                if(this.logType===undefined || this.logType==="Select Log Type"){
                    $('.spinner').hide();
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass('birdmessage-error');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-times-circle-o');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('show');
                    $('.details').addClass('hide');
                    $('.birdmessage h2').empty().append('Warning');
                    $('.birdmessage-info').empty().text(getMessage('BirdWarning82'));
                    return false;
                }else if(this.ftpfilename.substr(this.ftpfilename.lastIndexOf(".") + 1)==="log"){
                    var file_data = $("#fileupload").prop("files")[0];
                    if(this.logType==="customlog"){
                        this.logpattern=$('.customlogformatdata').val();
                    }
                    var tempObj  = {};
                    var data = {};
                    if(this.ftpfilename!=undefined){
                        tempObj["ftpurl"] = this.ftpurl;
                        tempObj["ftpusername"] = this.ftpusername;
                        tempObj["ftppassword"] = this.ftppassword;
                        tempObj["ftpfilename"] =this.ftpfilename;
                    }else{
                        tempObj["fileName"] = file_data.name;
                    }
                    tempObj["delimiter"] = this.props.delimiter; 
                    tempObj["connectionaccessid"] = null;
                    tempObj["connectionName"] = "log";
                    tempObj["logPattern"] = this.logpattern;
                    tempObj["uploadfiletype"]=this.uploadfiletype;
                    tempObj["command"] = "getTables";
                    data["connectionObject"] = tempObj;
                    data["schemaId"] = this.schemaId;
                    SendRequest({
                        url:"entities",
                        queryString: "?connectionObject="+encodeURIComponent(JSON.stringify(tempObj))+"&schemaId="+this.schemaId,
                     //   body:data,
                        sucessFunction:(response)=>{
                            if(response.hasOwnProperty('error')){
                                $(".spinner").hide();
                                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                                $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                                $('.birdmessagebuttons').removeClass('show');
                                $('.birdmessagebuttons').addClass('hide');
                                $('.birdmessage-center').addClass("message-top-in");
                                $('.details').removeClass('hide');
                                $('.birdmessage h2').empty().append('Error : ');
                                $('.birdmessage h2').append('<span class="errorcode">');
                                $('.birdmessage .errorcode').empty().append(response.errorCode);
                                $('.birdmessage-info').empty().text(response.error);
                                $('.details').addClass('show');
                                $('.messagedetails xmp').empty().append(response.errorTrace)
                            }else{
                                $(".spinner").hide();
                                this.tablenames=response;
                                this.csvdata=response.data;
                                if(response.columns.length==0){
                                    $("a[href='#next']").parent().addClass("disabled");
                                    $('.csvmodifyentities> .table').empty();
                                    $('.spinner').hide();
                                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass('birdmessage-error');
                                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-times-circle-o');
                                    $('.birdmessagebuttons').removeClass('show');
                                    $('.birdmessagebuttons').addClass('hide');
                                    $('.birdmessage-center').addClass("message-top-in");
                                    $('.details').removeClass('show');
                                    $('.details').addClass('hide');
                                    $('.birdmessage h2').empty().append('Warning');
                                    $('.birdmessage-info').empty().text(getMessage('BirdWarning82'));
                                    return false;
                                }else{
                                    this.saveconnection();
                                }
                            }
                        },
                        rejectFunction:(error)=>{},
                        headers:this.requestHeaders
                    })
                }
                else{
                    $('.spinner').hide();
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('show');
                    $('.details').addClass('hide');
                    $('.birdmessage h2').empty().append('Warning');
                    $('.birdmessage-info').empty().text(getMessage('BirdWarning80'));
                    return false;
                }
            }else{
                $('.spinner').hide();
                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Warning');
                $('.birdmessage-info').empty().text(getMessage('BirdWarning81'));
                return false;
            }
        }		
    }
    saveconnection=(e)=>{
        //For storing ftp connection details
        var connectionObject= {};
         var columns;
         var that=this;
             this.props.connectiondetails[0].scopeName=$('.logconnectionname').val();
             this.props.connectiondetails[0].connectionname=$('.logconnectionname').val();
             this.props.connectiondetails[0].logType= this.logType;
             this.props.connectiondetails[0].uploadfiletype= this.uploadfiletype;
             if(this.selectedfiletype=="ftp"){
                 this.props.connectiondetails[0].dbType ="log_ftp"; 
                 this.props.connectiondetails[0].uploadFileType ="log_ftp"; 
             }else if(this.selectedfiletype=="sftp"){
                 this.props.connectiondetails[0].dbType ="log_sftp"; 
                 this.props.connectiondetails[0].uploadFileType ="log_sftp"; 
             }else{
                 this.props.connectiondetails[0].dbType ="log"; 
                 this.props.connectiondetails[0].uploadFileType ="log"; 
             }
             if(this.ftpfilename!=undefined){
                 this.props.connectiondetails[0].ftpurl=this.ftpurl;
                 this.props.connectiondetails[0].ftpusername=this.ftpusername;
                 this.props.connectiondetails[0].ftppassword=this.ftppassword;
                 this.props.connectiondetails[0].ftpfilename=this.ftpfilename;
            }
             if(this.logType=="customlog"){
                 this.logpattern=$('.customlogformatdata').val();
             }
             this.props.connectiondetails[0].logPattern = this.logpattern;
             this.conObject=this.props.connectiondetails[0];
             connectionObject["connection_id"] = parseInt(this.schemaId);
             connectionObject["logPattern"]=this.logpattern;
             connectionObject["tablename"] = this.fileName;
             connectionObject["filePath"] = this.filePath;
             connectionObject["connection_access_name"] = $('.logconnectionname').val().trim();
             connectionObject["connectionname"] = $('.logconnectionname').val();
             connectionObject["connection_access_id"] = this.connectionId;
             connectionObject["connection_details"] = JSON.stringify(this.props.connectiondetails[0]);
             connectionObject["sqlquery"] = "";
             connectionObject["isForTemp"] = false;
             columns = JSON.stringify(this.reportcolumns);
             connectionObject["report_columns"] = columns;
             var data=new FormData();
             data.append("connectionObject",JSON.stringify(connectionObject));
             data.append("isFromModel",0);
             SendRequest({
                 url:"saveConnectionDetails",
                 queryString:"",
                 body:data,
                 sucessFunction:(response)=>{
                    $(".connection-center").remove()
                    if(response.hasOwnProperty('error')){
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(data.errorCode);
                        $('.birdmessage-info').empty().text(data.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(data.error)
                        setTimeout(function() {
                            $('.birderror').remove();	
                        }, 3000);
                    }else{
                        $(".spinner").hide();
                        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Success');
                        $('.birdmessage-info').empty().text(getMessage('BirdSuccess41'));
                        sessionStorage.setItem("changesdone",0);
                        $('.browselogfiles').removeClass('disabled');
                        $(".connection-center").removeClass("connection-in");
                        setTimeout(function(){$("body").removeClass("connection-open creating-new-connection");},800);
                      
                        this.datahub=new DataHub();
                            this.datahub.loadConnections(response.connectionAccessId);
                       
                    }
                 },
                 rejectFunction:(error)=>{},
                 headers:{
                    method: "post",
                    headers: new Headers({
                        "Content-Type": "application/json"
                    })
                }             });
      }
    validateflatfilesForSpecialChars=(text)=>{
        $(".spinner").hide();
        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
        $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
        $('.birdmessagebuttons').removeClass('show');
        $('.birdmessagebuttons').addClass('hide');
        $('.birdmessage-center').addClass("message-top-in");
        $('.details').removeClass('show');
        $('.details').addClass('hide');
        $('.birdmessage h2').empty().append('Warning');
        $('.birdmessage-info').empty().text(lang["Datahub.filespclchar"]);
        return false;
    }
    uploadlog=(url, redirect)=>{
        try{
        $('.spinner').show();
        var delimiter=this.delimiter;
        var file_data = $("#fileupload").prop("files")[0];
        var reportId="";
        if(true && !redirect){
             var form_data = new FormData();
                form_data.append("file", file_data);
                form_data.append("name",file_data.name);
                form_data.append("csvfiledata",JSON.stringify(this.jsonarray));
                form_data.append("delimiter",',');
                form_data.append("reportId",reportId);
                form_data.append("isFromMashUp",true);
                form_data.append("schemaname",this.props.schemaType);
                form_data.append("reportcolumns",JSON.stringify(this.reportcolumns));
                form_data.append("isTemp",redirect);
                SendRequest({
                    url:url,
                    queryString:"",
                    body:form_data,
                    sucessFunction:(response)=>{
                        if(response.hasOwnProperty('error')){
                            $(".spinner").hide();
                            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                            $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                            $('.birdmessagebuttons').removeClass('show');
                            $('.birdmessagebuttons').addClass('hide');
                            $('.birdmessage-center').addClass("message-top-in");
                            $('.details').removeClass('hide');
                            $('.birdmessage h2').empty().append('Error : ');
                            $('.birdmessage h2').append('<span class="errorcode">');
                            $('.birdmessage .errorcode').empty().append(response.errorCode);
                            $('.birdmessage-info').empty().text(response.error);
                            $('.details').addClass('show');
                            $('.messagedetails xmp').empty().append(response.error)
                            setTimeout(function() {
                                    $('.birderror').remove();	
                                }, 3000);
                        }else{
                            {
                                this.filePath=response.filePath;
                                var file_data = $("#fileupload").prop("files")[0];
                                this.fileName=file_data.name;
                                if(this.logType=="customlog"){
                                    this.logpattern=$('.customlogformatdata').val();
                                }
                                var tempObj  = {};
                                var data = {};
                                tempObj["fileName"] = file_data.name;
                                tempObj["filePath"] = this.filePath;
                                tempObj["delimiter"] = this.props.delimiter; 
                                tempObj["connectionaccessid"] = null;
                                tempObj["connectionName"] = "log";
                                tempObj["logPattern"] = this.logpattern;
                                tempObj["uploadfiletype"]=this.uploadfiletype;
                                tempObj["command"] = "getTables";
                                data["connectionObject"] = tempObj;
                                data["schemaId"] = this.schemaId;
                                SendRequest({
                                    url:"entities",
                                    queryString: "?connectionObject="+encodeURIComponent(JSON.stringify(tempObj))+"&schemaId="+this.schemaId,
                                   // body:data,
                                    sucessFunction:(response)=>{
                                        if(response.hasOwnProperty('error')){
                                            $(".spinner").hide();
                                            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                                            $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                                            $('.birdmessagebuttons').removeClass('show');
                                            $('.birdmessagebuttons').addClass('hide');
                                            $('.birdmessage-center').addClass("message-top-in");
                                            $('.details').removeClass('hide');
                                            $('.birdmessage h2').empty().append('Error : ');
                                            $('.birdmessage h2').append('<span class="errorcode">');
                                            $('.birdmessage .errorcode').empty().append(response.errorCode);
                                            $('.birdmessage-info').empty().text(response.error);
                                            $('.details').addClass('show');
                                            $('.messagedetails xmp').empty().append(response.errorTrace)
                                        }else{
                                            $(".spinner").hide();
                                            this.tablenames=response;
                                            this.csvdata=response.data;
                                            if(response.columns.length===0){
                                                $("a[href='#next']").parent().addClass("disabled");
                                                $('.csvmodifyentities> .table').empty();
                                                $('.spinner').hide();
                                                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass('birdmessage-error');
                                                $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-times-circle-o');
                                                $('.birdmessagebuttons').removeClass('show');
                                                $('.birdmessagebuttons').addClass('hide');
                                                $('.birdmessage-center').addClass("message-top-in");
                                                $('.details').removeClass('show');
                                                $('.details').addClass('hide');
                                                $('.birdmessage h2').empty().append('Warning');
                                                $('.birdmessage-info').empty().text(getMessage('BirdWarning82'));
                                                return false;
                                            }else{
                                                this.saveconnection();
                                            }

                                        }
                                    },
                                    rejectFunction:(error)=>{},
                                    headers:this.requestHeaders
                                })
                                    $('.spinner').hide();
                                }
                        }
                    },
                    rejectFunction:(error)=>{},
                    headers:{
                        method: "post",
                        headers: new Headers({
                            "Content-Type": "application/json"
                        })
                    }
                })
        }
        }catch (e) {
            $('.spinner').hide();
            
        }
    }
    render(){
        return(
            <LogfileConnectionHtml
                schemaName={this.schemaName}
                editfilename={this.editfilename}
                uploadsize={this.uploadsize}
                scopename={this.scopename}
                connectionId={this.connectionId}
                isFromEditFlatFiles={this.isFromEditFlatFiles}
                allow={this.allow}
                logpattern={this.logpattern}
                ftplogtype={this.ftplogtype}
                ftpfilename={this.ftpfilename}
                schemaDisplayName={this.schemaDisplayName}
                connectionname={this.connectionname}
                uploadtype={this.uploadtype}
                ftpurl={this.ftpurl}
                ftpusername={this.ftpusername}
                ftppassword={this.ftppassword}
                logscollection={this.state.customLogsCollection}
                logfilemethod={this.logfilemethod}
                browselogfiles={this.browselogfiles}
                logtypeselect={this.logtypeselect}
                ftptestconnection={this.ftptestconnection}
                savenewlogconnection={this.savenewlogconnection}
                close={this.props.close}
            />
        );
    }
}