import React, { Component } from 'react'
import Properties from '../Properties/ServerProperties'
import ReactDOM from 'react-dom'
import _ from 'underscore';
import SendRequest from '../SendRequest';
import { getMessage } from '../js/helpers/utils/BirdMessage';
const $ = window.$;

export default class ImporttempalteFile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
        var importedfiledata = undefined;
        this.state.isFromSharedModel = this.props.isFromSharedModel;
        this.render();
    }
    render() {
        var datamodelId = this.props.datamodelId;
        var today = new Date();
        var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
        $(".connection-center[datamodel-id='" + datamodelId + "']").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
        ReactDOM.render(<ImporttempalteFileTemplate uploadimporttemplate={this.uploadimporttemplate} cancelimporttemplate={this.cancelimporttemplate} allow={'.cidf'} datamodelId={datamodelId} />, document.getElementById(dynamicdiv));

        $(".connection-center[datamodel-id='" + datamodelId + "']").html(document.getElementById(dynamicdiv));
        $("#importtemplatefile[data_model_id='" + datamodelId + "']").fileinput({ 'showUpload': false, 'showPreview': false });
        $(".connection-center[datamodel-id='" + datamodelId + "']").addClass("connection-in");
        return (<div></div>);
    }
    cancelimporttemplate = (e) => {
        e.preventDefault();
        $(".connection-center").removeClass("connection-in");
        $('.importtemplatefilecontainer ').empty();
    }
    uploadimporttemplate = (e) => {
        e.preventDefault();
        e.stopPropagation();
        var $this = e.currentTarget;
        var that = this;
        if ($("input#importtemplatefile").prop("files")[0] != undefined) {
            $('.importtemplatefilerror').hide();
        } else {
            $('.importtemplatefilerror').show();
            return;
        }
        var fileName = $("input#importtemplatefile").prop("files")[0].name;
        var datamodelId = $($this).attr('data_model_id')
        if (fileName.substr(fileName.lastIndexOf(".") + 1) == "cidf") {
            var file_data = $("input#importtemplatefile").prop("files")[0];
            var form_data = new FormData();
            form_data.append("file", file_data);
            form_data.append("name", file_data.name);
            form_data.append("datamodelId", datamodelId);
            form_data.append("isFromSharedModel", that.state.isFromSharedModel)
            let requestHeaders = {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            SendRequest({
                url: "importtemplate",
                body: form_data,
                sucessFunction: function (data) {
                    if (data.hasOwnProperty('error')) {
                        $(".spinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(data.errorCode);
                        $('.birdmessage-info').empty().text(data.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(data.error)
                        setTimeout(function () {
                            $('.birderror').remove();
                        }, 3000);
                    } else {
                        that.state.importedfiledata = data;
                        //											that.addconnectiontodatabase(data);
                        var href = "/" + Properties.appName + "/welcome/importreport/" + btoa(that.state.importedfiledata.exportId);
                        //sessionStorage.setItem("isImportedReport",true);
                        that.props.history.push(href);
                    }
                },
                rejectFunction: this.reportDataError,
                headers: requestHeaders
            });
        } else {
            $(".spinner").hide();
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(getMessage('BirdWarning86'));
        }
        //			}
    }
}

class ImporttempalteFileTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        var that = this.props;
        return (<>
            <div className="connection-backdrop"></div>
            <div className="modal-backdrop fade in"></div>
            <div className="connection-container" title=""  style={{ zIndex: "1050" }}>
                <div className="closenewscopeconnection cancelimporttemplate" onClick={that.cancelimporttemplate} id="importcloseicon">
                    <i className="fa fa-times"></i>
                </div>
                <div className="col-xs-12">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xs-12">
                                <h3 style={{textAlign:"left" }}>Import Template File</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 marg-bot-10">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 marg-bot-15 nopadding"
                                    style={{ borderRadius: "30px",textAlign:"left" }}>
                                    <span className="file-input file-input-new">
                                        <h5>Browse File</h5>
                                        <input id="importtemplatefile" type="file" className="file" name="" data-show-preview="false" accept=".cidf" required="required" data_model_id={that.datamodelId} />
                                    </span> <span className="importtemplatefilerror" style={{ display: "none", color: "red" }}>Please Select A File</span>
                                </div>
                            </div>
                            <div className="col-sm-12 marg-top-10 marg-bot-10">
                                <div className="pull-right">
                                    <button type="button" className="btn btn-bird marg-righ-10 marg-top-5 cancelimporttemplate" onClick={that.cancelimporttemplate} id="cancelnewimportfile"><i className="fa fa-times"></i> Cancel</button>
                                    <button type="button" className="btn btn-bird marg-top-5 btn-highlight uploadimporttemplate" onClick={that.uploadimporttemplate} id="uploadnewimportflie" data_model_id={that.datamodelId}><i className="fa fa-upload"></i> Upload</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>);
    }
}