import React from "react";
import ReactDOM from 'react-dom';
import SessionexpiryTemplate from "./sessionexpirypopuptemplate.jsx";
import { lang } from "../js/helpers/utils/languages/language";
import SendRequest from "../SendRequest";
const $ = window.$;
export default class Sessionexpiry extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.unbindEvents()
        this.render()
    }
    unbindEvents() {
        $(".modal-backdrop.fade.in").remove();
        ReactDOM.unmountComponentAtNode(document.getElementById("changepasswordmodelview"));
    }
    render() {
        var that = this;
        $("#changepasswordmodel").remove();
        ReactDOM.render(<SessionexpiryTemplate changepasswordsubmit={this.changepasswordsubmit} />, document.getElementById("changepasswordmodelview"));
        $("#changepasswordmodel").modal({
            backdrop: 'static',
            keyboard: false
        })
        $("#changepasswordmodel").modal("show");
        $("#user[name='username']").val(that.props.loggedInUserDetails.UserLoginName);
        $("#user[name='username']").addClass("disabled");
        $('.spinner').hide();
        return (<React.Fragment />);
    }
    changepasswordsubmit = (event) => {
        // window.localStorage.clear();
        $(".modal-backdrop.fade.in").remove();
        event.preventDefault();
        let requestHeaders = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        let userName = $("#user").val()
        let password = $("#pwd").val()
        var data = new FormData();
        data.append("userName", userName);
        data.append("password", btoa(password));
        data.append("type", "login");
        let queryString = "?userName=" + $("#user").val() + "&password=" + $("#pwd").val();
        SendRequest({
            url: "authenticate/login",
            queryString: "",
            body: data,
            sucessFunction: this.loginSucessFunction,
            rejectFunction: this.loginRejectFunction,
            headers: requestHeaders,
        });
    }
    loginSucessFunction = result => {
        if (result.hasOwnProperty('error')) {
            document.getElementById('sessionexpiryerror').innerHTML = result.error;
            $(".sessionexpiryerror").show();
            return;
        }
        $('#changepasswordmodel').hide();
        $('.birdmessage-container').show();
        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
        $('.birdmessagebuttons').removeClass('show');
        $('.birdmessagebuttons').addClass('hide');
        $('.birdmessage-center').addClass("message-top-in");
        $('.details').removeClass('show');
        $('.details').addClass('hide');
        $('.birdmessage h2').empty().append('Success');
        $('.birdmessage-info').empty().text(lang["user.header.sessionexpire"]);
        $('.birdmessage-backdrop').show();
        // window.localStorage.clear();
        window.localStorage.setItem("refreshToken", result.refreshToken);
        window.localStorage.setItem("accessToken", result.accessToken);
        window.localStorage.setItem("accessTokenExpiration", result.accessTokenExpiration);
        window.localStorage.setItem("serverTimezone", result.timezoneId);
        sessionStorage.setItem("accessToken", result.accessToken);
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        //   SendRequest({
        //     url: "getfeatures",
        //     queryString: "",
        //     sucessFunction: (features) => {
        //       let refreshToken = window.localStorage.getItem("refreshToken");
        //       let featuresString = window.btoa(JSON.stringify(features));
        //       ChartInfo.functions.setAllFeatures(features);
        //     },
        //     rejectFunction: () => { },
        //     headers: {
        //       method: "get",
        //       headers: new Headers({
        //         "Content-Type": "application/json"
        //       })
        //     }
        //   });

        //   SendRequest({
        //     url: "getreservedwords",
        //     queryString: "",
        //     sucessFunction: response => {
        //       if (!response.hasOwnProperty('error')) {
        //         var reservedwordslist = response.rslist;
        //         sessionStorage.setItem("reservedwordslist", JSON.stringify(reservedwordslist));
        //       }
        //     },
        //     rejectFunction: () => { },
        //     headers: {
        //       method: "get",
        //       headers: new Headers({
        //         "Content-Type": "application/json"
        //       })
        //     }
        //   });


    }
    loginRejectFunction() {
        document.getElementById('error').innerHTML =lang["user.header.details"];

        setTimeout(function () {
            let requestHeaders1 = {
                method: "get",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            SendRequest({
                url: "logoutbird",
                queryString: "",
                sucessFunction: (props) => {
                    sessionStorage.removeItem("accessToken");
                    sessionStorage.removeItem("reservedwordslist");
                    window.localStorage.clear();
                    window.location.replace("/");
                },
                rejectFunction: () => { },
                headers: requestHeaders1,
            })
        }, 60000);
    }
}