import React from "react";
import ReactDOM from 'react-dom';
import $ from "jquery/dist/jquery";
import 'jquery-validation/dist/jquery.validate';
import ParticlesContainer from "./ParticlesContainer";
import SendRequest from './SendRequest';
// import "./js/loginpageanimation";
import BirdLogo from './images/whitelable/bird-logo-login.png'
import * as ChartInfo from './js/helpers/utils/chartinfo';
import './Login.css';
import ResetPassword from "./resetpassword.js";
import Rootlicense from './rootlicense';
import ChangeAdminUserName from "./changeadminusername.js"
import { whitelableMap } from "./js/helpers/utils/whitelable";
import { lang } from "./js/helpers/utils/languages/language";
import OneTimePassword from './onetimepassword.jsx'
import Properties from './Properties/ServerProperties'

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      loginEmail: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isLicensePresent();
  }
  UNSAFE_componentWillMount() {
    document.body.classList.add("loginbody");
  }
  componentDidMount() {
    $(".form")
      .find("input, textarea, select")
      .on("keyup blur focus change", function (e) {
        var $this = $(this),
          label = $this.prev("label");
        if (e.type === "keyup") {
          if ($this.val() === "") {
            label.removeClass("active highlight");
          } else {
            label.addClass("active highlight");
          }
        } else if (e.type === "blur") {
          if ($this.val() === "") {
            label.removeClass("active highlight");
          } else {
            label.removeClass("highlight");
          }
        } else if (e.type === "focus") {
          label.addClass("active highlight");
        }
        if (e.type === "change") {
          if ($this.val() === "initial") {
            label.removeClass("active highlight");
          } else if ($this.val() !== "") {
            label.addClass("active");
          }
          if ($this.val() !== "other") {
            $(".others").addClass("hide");
          } else if ($this.val() === "other") {
            $(".others").removeClass("hide");
          }
        }
      });
        $('title').text(whitelableMap["metatitle"]);
        $("link[name='Favicon']").attr("href",whitelableMap["metafavicon"]);
        $(".spinner").hide();
  }
  componentWillUnmount() {
    //document.body.classList.remove("loginbody");
  }
  isLicensePresent() {
    let requestHeaders = {
      method: "post",
      headers: new Headers({
        "Content-Type": "application/json"
      })
    };
    SendRequest({
      url: "authenticate/",
      queryString: "",
      sucessFunction: props => {
        if (props!=undefined && (props.rootlicense === "false" || props.hasOwnProperty('licenseError'))) {
          ReactDOM.render(<Rootlicense />, document.getElementById("root"));
          if (props.hasOwnProperty('licenseError')) {
            $('.error').html(props.licenseError);
          }
        } else {
          this.render();
        }
      },
      rejectFunction: () => { },
      headers: requestHeaders
    });
  }
  resetpassword = (event) => {
    var that = this;
    event.preventDefault();
    var userloginname = $("#currentuserName").val();
    if (userloginname.localeCompare("superadmin") == 0 || userloginname.localeCompare("admin") == 0 || /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(userloginname)) {
      if ($("#resetform").validate().element("#currentuserName")) {
        var username = $("#currentuserName").val();
        let requestHeaders = {
          method: "post",
          headers: new Headers({
            "Content-Type": "application/json"
          })
        };
        let x = new FormData();
        x.append('username', username);
        SendRequest({
          url: "authenticate/resetUser",
          //queryString: "?username= "+username+"  ",
          queryString: "",
          body: x,
          sucessFunction: (data) => {
            var value = data;
            if (value.hasOwnProperty('success')) {
              $('.header-message').html(value.success).addClass('header-success').show('1000');
              setTimeout(function () {
                $('.header-message').hide('1000');
                setTimeout(function () { $('.header-message').removeClass('header-success'); }, 800);
                //		location.href = "";
                //that.props.history.push(`/${Properties.appName}/login`);

                document.getElementById("login").style.display =
                  "block";
                document.getElementById(
                  "forgotpassword"
                ).style.display = "none";

              }, 5000);
            }
            else if (value.hasOwnProperty('error')) {
              $('.header-message').html(value.error).show('1000');
              setTimeout(function () {
                $('.header-message').hide('1000');
              }, 5000);
            } else if (value.hasOwnProperty('failure')) {
              $('.header-message').html(value.failure).show('1000');
              setTimeout(function () {
                $('.header-message').hide('1000');
                if (window.location.pathname === "/bird/" || window.location.pathname === "/bird") {
                  //		location.href = "";
                } else {
                  //		location.href = window.location.pathname.replace(window.location.pathname.split("/").pop(),"login");
                }
              }, 5000);
            }
            else {
              $('.header-message').html(value.warning).addClass('header-warning').show('1000');
              setTimeout(function () {
                $('.header-message').removeClass('header-warning').hide('1000');
                //			location.href = "";
              }, 5000);
            }

          },
          rejectFunction: () => { },
          headers: requestHeaders
        })

      } else {
        $("input[id=currentuserName]").removeClass('error')
        $("label[for='currentuserName']").addClass('active')
      }
    } else {
      if (userloginname == "") {
        $(".header-message").text("Please enter  login name").css("display", "block");
        setTimeout(function () { $(".header-message").hide() }, 3000);
        return false;
      } else {
        $(".header-message").text("Please enter a valid login name").css("display", "block");
        setTimeout(function () { $(".header-message").hide() }, 3000);
        return false;
      }
    }


  }
  loginSucessFunction = result => {
    $(".spinner").show();
    let urlString = window.location.origin+"/"+Properties.appName+"/welcome/loginsuccess";
    if (result.hasOwnProperty('error')) {
      $(".spinner").hide();
      document.getElementById('error').innerHTML = result.error;
      return;
    }else if(result.isSAML!=undefined && result.isSAML!=null && result.isSAML && result.loginurl!=null){
      window.open(result.loginurl, '_self');
      let requestHeaders = {
        method: "post",
        headers: new Headers({
          "Content-Type": "application/json"
        })
      };
      var path="";
      if (window.location.search != undefined && window.location.search != "" && (window.location.search.indexOf("embeddedreport") >= 0 || window.location.search.indexOf("embeddedcustomstoryboard") >= 0 || window.location.search.indexOf("embeddedsmartinsightreport") >= 0 || window.location.search.indexOf("schedulereport") >= 0 || window.location.search.indexOf("alertreport") >=0)) {
        var path =window.location.search;
      }
      let form = new FormData();
      form.append('isFrom', "login");
      form.append('path', path);
      
      SendRequest({
        url: "authenticate/getloginstatus",
        body:form,
        sucessFunction: this.loginSucessFunction,
        rejectFunction: () => { },
        headers: requestHeaders
      });
      return;
    }
    if(result.samltoken!=null && result.samltoken){
      window.open(urlString, '_self');
    }
    window.localStorage.clear();
    window.localStorage.setItem("refreshToken", result.refreshToken);
    window.localStorage.setItem("accessToken", result.accessToken);
    window.localStorage.setItem("accessTokenExpiration", result.accessTokenExpiration);
    window.localStorage.setItem("serverTimezone", result.timezoneId);
    sessionStorage.setItem("accessToken", result.accessToken);
    let requestHeaders = {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json"
      })
    };
    SendRequest({
      url: "welcomepage",
      queryString: "",
      sucessFunction: (response) => {
        if (response.page != undefined != undefined && (response.page == "resetpassword" || response.page == "changeadminusername")) {
          if (response.page == "resetpassword") {
            ReactDOM.render(<ResetPassword history={this.props.history} passwordexpired={response.passwordexpired} resetpasswordfromadmin={response.resetpasswordfromadmin} resetpassword={response.page} firsttimelogin={response.firsttimelogin} questionslist={response.questionslist} forgotpassword={response.forgotpassword}
              sq1={response.sq1} sq2={response.sq2} sq3={response.sq3}
              ans1={response.ans1} ans2={response.ans2} ans3={response.ans3}
            />, document.querySelector('.logincontainer-fluid'));
          }
          if (response.page == "changeadminusername") {
            ReactDOM.render(<ChangeAdminUserName history={this.props.history} admin={response.admin} />, document.querySelector('.logincontainer-fluid'));

          }

        } else if (response.page != undefined && response.page == "onetimepassword") {
          sessionStorage.setItem("2factorauthwelcome", true);
          ReactDOM.render(<OneTimePassword history={this.props.history} response={response} authenticateotp={this.authenticateotp} />, document.querySelector('.logincontainer-fluid'));
        } else {
          SendRequest({
            url: "getfeatures",
            queryString: "",
            sucessFunction: (features) => {
              document.body.classList.remove("loginbody");
              let refreshToken = window.localStorage.getItem("refreshToken");
              let featuresString = window.btoa(JSON.stringify(features));
              ChartInfo.functions.setAllFeatures(features);
              featuresString = featuresString.slice(0, 3) + "a" + featuresString.slice(3);
              sessionStorage.setItem("features", featuresString);
              featuresString = featuresString.slice(0, 3) + featuresString.slice(4);
              let decryptedString = window.atob(featuresString);
              this.parsedFeatureJson = JSON.parse(decryptedString);
              if (this.parsedFeatureJson[0].moduleName == 'admin') {
                this.props.history.push("/" + window.localStorage.getItem("appName") + "/welcome/admin");
              }
              if (window.location.search != undefined && window.location.search != "" && (window.location.search.indexOf("embeddedreport") >= 0 || window.location.search.indexOf("embeddedcustomstoryboard") >= 0 || window.location.search.indexOf("embeddedsmartinsightreport") >= 0 || window.location.search.indexOf("schedulereport") >= 0 || window.location.search.indexOf("alertreport") >=0)) {
                this.props.history.push("/" + window.localStorage.getItem("appName") + "/welcome/private/" + window.location.search.replace("?", ""));
              } else if (this.parsedFeatureJson[0].moduleName != 'admin') {
                this.props.history.push("/" + window.localStorage.getItem("appName") + "/welcome");
              }
            },
            rejectFunction: () => { },
            headers: {
              method: "get",
              headers: new Headers({
                "Content-Type": "application/json"
              })
            }
          });
          SendRequest({
            url: "getreservedwords",
            queryString: "",
            sucessFunction: response => {
              if (!response.hasOwnProperty('error')) {
                var reservedwordslist = response.rslist;
                sessionStorage.setItem("reservedwordslist", JSON.stringify(reservedwordslist));
              }
            },
            rejectFunction: () => { },
            headers: {
              method: "get",
              headers: new Headers({
                "Content-Type": "application/json"
              })
            }
          });
        }
      },
      rejectFunction: () => { },
      headers: requestHeaders,
    })

  }
  loginRejectFunction() {
    $(".spinner").hide();
    document.getElementById('error').innerHTML = "The username or password you entered is incorrect";

  }
  loginSucessRefreshFunction = result => {
    //const history = useHistory();
    sessionStorage.setItem("refreshToken", result.accessToken);
  }
  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }
  handleSubmit(event) {
    $(".spinner").show();
    window.localStorage.clear();
    event.preventDefault();
    let requestHeaders = {
      method: "post",
      headers: new Headers({
        "Content-Type": "application/json"
      })
    };
    let userName = $("#user").val()
    let password = $("#pwd").val()
    var data = new FormData();
    data.append("userName", userName);
    data.append("password", btoa(password));
    data.append("type", "login");
    let queryString = "?userName=" + $("#user").val() + "&password=" + $("#pwd").val();
    SendRequest({
      url: "authenticate/login",
      queryString: "",
      body: data,
      sucessFunction: this.loginSucessFunction,
      rejectFunction: this.loginRejectFunction,
      headers: requestHeaders,
    });
  }

  authenticateotp = (e) => {
    e.preventDefault();
    var that = this;
    if ($('#pwd').val() != undefined && $('#pwd').val() != null && $('#pwd').val() != "") {
      let requestHeaders = {
        method: "post",
        headers: new Headers({
          "Content-Type": "application/json"
        })
      };
      var data = new FormData();
      data.append("password", $('#pwd').val());

      SendRequest({
        url: "2factorauthwelcome",
        body: data,
        sucessFunction: (response) => {
          if (response != undefined && response.success == 'true') {
            sessionStorage.removeItem("2factorauthwelcome");
            SendRequest({
              url: "getfeatures",
              queryString: "",
              sucessFunction: (features) => {
                let refreshToken = window.localStorage.getItem("refreshToken");
                let featuresString = window.btoa(JSON.stringify(features));
                ChartInfo.functions.setAllFeatures(features);
                featuresString = featuresString.slice(0, 3) + "a" + featuresString.slice(3);
                sessionStorage.setItem("features", featuresString);
                featuresString = featuresString.slice(0, 3) + featuresString.slice(4);
                let decryptedString = window.atob(featuresString);
                this.parsedFeatureJson = JSON.parse(decryptedString);
                if (this.parsedFeatureJson[0].moduleName == 'admin') {
                  this.props.history.push("/" + window.localStorage.getItem("appName") + "/welcome/admin");
                }
                if (window.location.search != undefined && window.location.search != "" && (window.location.search.indexOf("embeddedreport") >= 0 || window.location.search.indexOf("embeddedcustomstoryboard") >= 0 || window.location.search.indexOf("embeddedsmartinsightreport") >= 0 || window.location.search.indexOf("schedulereport") >= 0 ||  window.location.search.indexOf("alertreport") >=0)) {
                  this.props.history.push("/" + window.localStorage.getItem("appName") + "/welcome/private/" + window.location.search.replace("?", ""));
                } else if (this.parsedFeatureJson[0].moduleName != 'admin') {
                  this.props.history.push("/" + window.localStorage.getItem("appName") + "/welcome");
                }
              },
              rejectFunction: () => { },
              headers: {
                method: "get",
                headers: new Headers({
                  "Content-Type": "application/json"
                })
              }
            });
            SendRequest({
              url: "getreservedwords",
              queryString: "",
              sucessFunction: response => {
                if (!response.hasOwnProperty('error')) {
                  var reservedwordslist = response.rslist;
                  sessionStorage.setItem("reservedwordslist", JSON.stringify(reservedwordslist));
                }
              },
              rejectFunction: () => { },
              headers: {
                method: "get",
                headers: new Headers({
                  "Content-Type": "application/json"
                })
              }
            });
          } else {
            if (document.querySelector('.logincontainer-fluid') != null) {
              ReactDOM.unmountComponentAtNode(document.querySelector('.logincontainer-fluid'));
            }
            ReactDOM.render(<OneTimePassword history={this.props.history} response={response} authenticateotp={this.authenticateotp} />, document.querySelector('.logincontainer-fluid'));
          }
          console.log(response);
        },
        rejectFunction: this.reportDataError,
        headers: requestHeaders

      });
    }
  }

  render() {

    return (
      <div>
        {/*<ParticlesContainer />*/}
        {/*<div className="rightbg" />*/}
        <div className="container-fluid height-100 logincontainer-fluid">
          <div className="row height-100 logincontainerrow">
            <div className="col-md-6 loginrightcontainer">
              <div className="panelcontainer">
                <div className="logincontainer">
                  <div className="logo" onClick={() => {
                    document.getElementById("login").style.display =
                      "block";
                    document.getElementById(
                      "forgotpassword"
                    ).style.display = "none";
                  }}>
                    <img alt="BIRDonCloud" src={whitelableMap["loginlogo"]} id="birdimg"  />
                    <span></span>
                  </div>
                  <div className="form">
                    {/* <ul className="tab-group hidden-lg hidden-md hidden-sm hidden-xs">
                      <li className="tab active">
                        <a href="#login">
                          Sign in
                          <div className="ripples buttonRipples">
                            <span className="ripplesCircle" />
                          </div>
                        </a>
                      </li>
                      <li className="tab">
                        <a href="#forgotpassword" />
                      </li>
                    </ul> */}
                    <div className="tab-content">
                      <div id="login" style={{ display: "block" }}>
                        <h1>{lang["login.title"]}</h1>
                        <p className="error active" id="error"></p>
                        <form method="post" id="loginformid" onSubmit={this.handleSubmit}>
                          <div className="field-wrap">
                            <label htmlFor="user">
                            {lang["login.username"]}<span className="req">*</span>
                            </label>
                            <input
                              type="text"
                              autoComplete="on"
                              id="user"
                              name="username"
                              autoFocus="autofocus"
                              spellCheck="false"
                              autoCorrect="off"
                              required
                              value={this.state.username}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="field-wrap">
                            <label htmlFor="pwd">
                            {lang["login.pwd"]} <span className="req">*</span>
                            </label>
                            <input
                              type="password"
                              autoComplete="off"
                              id="pwd"
                              name="password"
                              required
                              value={this.state.password}
                              onChange={this.handleChange}
                            />
                          </div>
                          <p className="forgot">
                            <a
                              className="forgottab"
                              onClick={() => {
                                document.getElementById("login").style.display =
                                  "none";
                                document.getElementById(
                                  "forgotpassword"
                                ).style.display = "block";
                              }}
                            >
                            {lang["login.forgot.pwd"]}
                            </a>
                          </p>
                          <button className="button button-block">
                          {lang["login.signbtn"]}
                            <div className="ripples buttonRipples">
                              <span className="ripplesCircle" />
                            </div>
                          </button>
                          <p className="loginterms">{lang["login.Terms1"]}<a href="docs/termsofuse.pdf" target="_blank">{lang["login.Terms2"]}</a> {lang["login.Terms3"]} <a href="docs/privacypolicy.pdf" target="_blank">{lang["login.Terms4"]}</a>.</p>
                        </form>
                      </div>
                      <div id="forgotpassword" style={{ display: "none" }}>
                        <h1>{lang["login.forgot.title"]}</h1>
                        <span className="header-message" />
                        <form id="resetform" >
                          <div className="field-wrap">
                            <label>
                            {lang["login.forgot.userlname"]}<span className="req">*</span>
                            </label>
                            <input
                              type="text"
                              required
                              autoComplete="on"
                              id="currentuserName"
                              value={this.state.loginEmail}
                              name="loginEmail"
                              onChange={this.handleChange}
                            />
                          </div>
                          <button className="button button-block resetpasswordbutton" onClick={this.resetpassword}>
                          {lang["login.forgot.resetbtn"]}
                            <div className="ripples buttonRipples">
                              <span className="ripplesCircle" />
                            </div>
                          </button>
                        </form>
                      </div>
                    </div>
                    {/* tab-content */}
                  </div>{" "}
                  {/* /form */}
                </div>
              </div>
              <div className="footer copyright-footer">{whitelableMap["loginfootertext"]}</div>
            </div>{" "}
            <div className="col-md-6 loginleftcontainer">
              {/* <div>
                <div>
                  <h1>{whitelableMap["loginproductname"]}</h1>
                  <p>{whitelableMap["loginproductdescription"]}</p>
                  <a href={whitelableMap["loginproductwebsite"]} type="button" className="btn-login">{lang["login.learnbtn"]}</a>
                </div>
                <div className="login-promo-image">
                  <div>
                    <img src={whitelableMap["loginpromoimage"]} alt="features" />
                  </div>
                </div>
              </div> */}
            </div>

            {/* /left */}
          </div>
        </div>
      </div>
    );
  }
}
