import _ from 'lodash';
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import { whitelableMap } from "../../js/helpers/utils/whitelable";
import * as Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official'
import solidGauge from "highcharts/modules/solid-gauge"
import highcharts3d from 'highcharts/highcharts-3d';
import drilldow from "highcharts/modules/drilldown";
import dataModule from "highcharts/modules/data";
import HighchartsMore from 'highcharts/highcharts-more'
import SmartInsightDrillDown from './smartinsightsdrilldown'

const $ = window.$;

highcharts3d(Highcharts);
drilldow(Highcharts);
dataModule(Highcharts);
HighchartsMore(Highcharts)
solidGauge(Highcharts);

export var smartinsightcharts = {};
export var chartPoints = {};
export var columnsData = {};
export var viewChartDrillDownModal = null;
export var smartInsightDrillDown = null;
export var isDrillDownEnable = false;
export var isViewDataEnable = false;
export var isExportChartEnable = false;
export var isFilterEnable = false;
export var dobydownload = {};
export var reportColumns = [];
export var colorThemesArray = ChartInfo.functions.getColorThemes();

export var charts = {

    drawSmartSolidgaugeChart: function (data, renderto) {
        var options = {
            chart: {
                renderTo: renderto[0],
                backgroundColor: 'transparent',
                style: {
                    fontFamily: whitelableMap["fontfamily"],
                    fontWeight: '400',
                },
                type: 'solidgauge',
                animation: false,
                spacing: [20, 20, 20, 20],
            },
            title: {
                text: ' ',
                align: 'left'
            },
            subtitle: {
                text: '',
                align: 'left'
            },
            pane: {
                center: ['50%', '70%'],
                size: '90%',
                startAngle: -90,
                endAngle: 90,
                background: {
                    backgroundColor: '#d8d8d8',
                    innerRadius: '60%',
                    outerRadius: '100%',
                    shape: 'arc',
                    borderWidth: 0
                }
            },
            yAxis: {
                lineWidth: 0,
                minorTickInterval: null,
                tickPixelInterval: 400,
                tickWidth: 0,
                title: {
                    text: "",
                    y: -70
                },
                labels: {
                    y: 5,
                    distance: 14,
                    formatter: function () {
                        if (this.value == 0) {
                            return "POOR"
                        } else if (this.value == 50) {
                            return "GOOD"
                        } else if (this.value == 100) {
                            return "BEST"
                        } else {
                            return this.value
                        }

                    },
                    style:{
                        fontSize:"0.8rem",
                        fontWeight:500
                    }
                },
                min: 0,
                max: 100,
                tickPositions: [0, 50, 100]
            },
            tooltip: {
                enabled: false,
                useHTML: true,
            },
            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        enabled: true,
                        borderWidth: 0,
                        useHTML: true,
                    },
                },
                series: {
                    stickyTracking: false
                }
            },
            legend: {
                layout: 'horizontal',
                align: 'right',
                verticalAlign: 'bottom',
                borderWidth: 0
            },
            series: [{
                data: data,
                name: 'Insight Quality',
                dataLabels: {
                    y: -20,
                    formatter: function () {
                        // return '<div style="text-align:center;"><span style="display:block;width:100%;font-size:200%;color:#757575;font-weight: 400;">' + this.y + '/10</span></div>';
                        return '<div style="text-align:center;"><span style="display:block;width:100%;font-size:200%;color:#757575;font-weight: 400;">' + this.y.toFixed(1) + '%</span></div>';
                    }
                },
            }],
            colors: colorThemesArray[0],
            exporting: {
                enabled: false,
            },
            credits: {
                enabled: false
            }
        };
        var insightquality = new Highcharts.Chart(options);

    },
    drawSmartPieChart: function (data, renderto) {
        var options = {
            chart: {
                renderTo: renderto[0],
                style: {
                    fontFamily: whitelableMap["fontfamily"],
                    fontWeight: '400',
                },
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                spacing: [10, 10, 10, 10]
            },
            title: {
                text: ' '
            },
            tooltip: {
                useHTML: true,
                formatter: function () {
                    return '' + this.series.name + '<br><b>' + this.point.name + ': ' + this.y + '</b>';
                }
            },
            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle',
                labelFormatter: function () {
                    return this.options.name + '(' + this.options.y + ')'
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: false,
                    //cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true
                }
            },
            series: [{
                name: 'Insight Findings',
                colorByPoint: true,
                data: data
            }],
            colors: colorThemesArray[0],
            exporting: {
                enabled: false,
            },
            credits: {
                enabled: false
            }
        }
        var insightfindings = new Highcharts.Chart(options);
    },
    drawSmartBubbleChart: function (data, categories, renderto) {

        var newdata = [];
        var positiveData = [];
        var negativeData = [];
        _.each(data, function (i) {
            var obj = {};
            obj.x = i[0];
            obj.y = i[1];
            obj.z = i[2];

            var val = obj.z.toFixed(1);

            if (val == "1.0") {
                obj.color = 'rgba(0, 188, 212,1)';
            } else if (val == "0.9") {
                obj.color = 'rgba(0, 188, 212,0.95)';
            } else if (val == "0.8") {
                obj.color = 'rgba(0, 188, 212,0.9)';
            } else if (val == "0.7") {
                obj.color = 'rgba(0, 188, 212,0.85)';
            } else if (val == "0.6") {
                obj.color = 'rgba(0, 188, 212,0.8)';
            } else if (val == "0.5") {
                obj.color = 'rgba(0, 188, 212,0.75)';
            } else if (val == "0.4") {
                obj.color = 'rgba(0, 188, 212,0.7)';
            } else if (val == "0.3") {
                obj.color = 'rgba(0, 188, 212,0.65)';
            } else if (val == "0.2") {
                obj.color = 'rgba(0, 188, 212,0.6)';
            } else if (val == "0.1") {
                obj.color = 'rgba(0, 188, 212,0.55)';
            } else if (val == "0.0") {
                obj.color = 'rgba(0, 188, 212,0.5)';
            } else if (val == "-0.9") {
                obj.color = 'rgba(255,87,34,1)';
            } else if (val == "-0.8") {
                obj.color = 'rgba(255,87,34,0.95)';
            } else if (val == "-0.7") {
                obj.color = 'rgba(255,87,34,0.9)';
            } else if (val == "-0.6") {
                obj.color = 'rgba(255,87,34,0.85)';
            } else if (val == "-0.5") {
                obj.color = 'rgba(255,87,34,0.8)';
            } else if (val == "-0.4") {
                obj.color = 'rgba(255,87,34,0.75)';
            } else if (val == "-0.3") {
                obj.color = 'rgba(255,87,34,0.7)';
            } else if (val == "-0.2") {
                obj.color = 'rgba(255,87,34,0.65)';
            } else if (val == "-0.1") {
                obj.color = 'rgba(255,87,34,0.6)';
            }

            newdata.push(obj);
            if(val>=0){
                positiveData.push(obj)
            }else{
                negativeData.push(obj)
            }
        })

        data = newdata;
        var maxlength = categories.length;
        if (maxlength >= 10) {
            maxlength = 10;
        }
        var options = {
            chart: {
                events: {
                    selection: this.customSelection,
                },
                renderTo: renderto[0],
                style: {
                    fontFamily: whitelableMap["fontfamily"],
                    fontWeight: '400',
                },
                type: 'bubble',
                plotBorderWidth: 0,
                zoomType: 'xy'
            },
            title: {
                text: ' '
            },
            xAxis: {
                categories: categories,
                title: "",
                lineWidth: 0,
                tickWidth: 0,
                gridLineWidth: 0,
                opposite: true,
                min: 0,
                max: maxlength - 1

            },
            yAxis: {
                title: "",
                min: 0,
                lineWidth: 0,
                tickWidth: 0,
                gridLineWidth: 0,
                startOnTick: false,
                endOnTick: false,
                categories: categories,
                reversed: true,
                max: maxlength - 1,
                tickInterval: 1,
                labels: {
                    align: 'right',
                    x: 20,
                    step: 1
                }
            },
            tooltip: {
                useHTML: true,
                formatter: function () {
                    return 'Correlation for <br><b>' + this.series.xAxis.categories[this.point.x] + '</b> and <b>' + this.series.yAxis.categories[this.point.y] + '</b><br>is <b>' + this.point.z.toFixed(2) + '</b>';
                }
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: false,
                    },
                    maxSize: 25,
                    states: {
                        inactive: {
                            opacity: 1
                        }
                    }
                }
            },
            legend: {
                enabled: false
            },
            series: [{
                data: data,
                marker: {
                    fillOpacity: 1
                },
            }],
            colors: colorThemesArray[0],
            exporting: {
                enabled: false,
            },
            credits: {
                enabled: false
            }
        }
        options.series[0].data = positiveData;
        options.series.push({
            data: negativeData,
            marker: {
                fillOpacity: 1
            },
        })
        var insightcorrelatedcolumns = new Highcharts.Chart(options);
    },
    drawSmartHeatmap: function (data, categories, renderto) {
        var options = {
            chart: {
                renderTo: renderto[0],
                type: 'heatmap',
                spacing: [20, 0, 0, 0],
                zoomType: 'xy',
                backgroundColor: 'transparent',
                style: {
                    fontFamily: whitelableMap["fontfamily"],
                    fontWeight: '400',
                },
                animation: false,
                plotBorderWidth: 0,
                plotBorderColor: "#000000"
            },
            title: {
                text: ''
            },

            xAxis: {
                categories: categories,
                lineWidth: 0,
                tickWidth: 0,
                gridLineWidth: 0,
            },

            yAxis: {
                categories: categories,
                title: null,
                lineWidth: 0,
                tickWidth: 0,
                gridLineWidth: 0,
            },

            colorAxis: {
                min: 0,
                minColor: '#FFFFFF',
                maxColor: colorThemesArray[0][0]
            },

            legend: {
                align: 'right',
                layout: 'vertical',
                margin: 0,
                verticalAlign: 'top',
                x: 10,
                y: 0,
                symbolHeight: 180
            },
            tooltip: {
                useHTML: true,
                formatter: function () {
                    return 'Covaraiance for <br><b>' + this.series.xAxis.categories[this.point.x] + '</b> and <b>' + this.series.yAxis.categories[this.point.y] + '</b><br>is <b>' + this.point.value + '</b>';
                }
            },
            colors: colorThemesArray[2],
            series: [{
                name: 'Covariance',
                borderWidth: 1,
                data: data,
                dataLabels: {
                    enabled: true,
                    color: '#000000'
                }
            }],
            exporting: {
                enabled: false,
            },
            credits: {
                enabled: false
            }
        }
        var insightcovariance = new Highcharts.Chart(options);
    },
    drawSmartBar: function (data, categories, renderto, targetColumn, modelName, frameName, htmlnlgdata, targetColumnOriginal, tabid, reportId, datamodelId, isFromStoryBook) {
        var categorydisplayNames = [];
        var categoryOriginalNames = {};
        for (var i = 0; i < categories.length; i++) {
            categoryOriginalNames[categories[i].columnDisplayName] = categories[i].columnName;
            categorydisplayNames.push(categories[i].columnDisplayName);
        }
        var options = {
            chart: {
                renderTo: renderto[0],
                type: 'bar',
                zoomType: 'xy',
                backgroundColor: 'transparent',
                style: {
                    fontFamily: whitelableMap["fontfamily"],
                    fontWeight: '400',
                },
                animation: false,
                plotBorderWidth: 0,
                spacing: [20, 10, 0, 20]
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: categorydisplayNames,
                title: {
                    text: null
                },
                lineWidth: 0,
                tickWidth: 0,
                gridLineWidth: 0,
            },
            yAxis: {
                min: 0,
                title: {
                    text: '',
                },
                labels: {
                    enabled: false
                },
                lineWidth: 0,
                tickWidth: 0,
                gridLineWidth: 0,
            },
            tooltip: {
                useHTML: true,
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: false
                    },
                },
                series: {
                    cursor: 'pointer',
                    pointPadding: 0,
                    groupPadding: 0.1,
                    minPointLength: 3,
                    states: {
                        select: {
                            color: colorThemesArray[0][0]
                        }
                    },
                    slicedOffset: 0,
                    point: {
                        events: {
                            click: function (event) {
                                var that = this;
                                if (this.series.chart.getSelectedPoints().length > 0) {
                                    this.series.chart.getSelectedPoints()[0].select(false, true)
                                }
                                //$(".insightavgsuggestion1 #spinnerchart9").show();
                                this.select(null, true);
                                smartInsightDrillDown = new SmartInsightDrillDown(
                                    {
                                        targetColumn: that.series.chart.series[0].userOptions.name,
                                        categoryname: categoryOriginalNames[this.series.chart.getSelectedPoints()[0].category.name],
                                        filter: false,
                                        htmlnlgdata: htmlnlgdata,
                                        targetColumnOriginal: targetColumnOriginal,
                                        categoryOriginalNames: this.series.chart.getSelectedPoints()[0].category.name,
                                        tabid: tabid,
                                        reportId: reportId,
                                        datamodelId: datamodelId,
                                        isFromStoryBook: isFromStoryBook,
                                        categoryType:this.series.chart.getSelectedPoints()[0].ydatatype
                                    });
                            }
                        }
                    }
                }
            },
            legend: {
                enabled: false
            },

            series: [{
                name: targetColumn,
                data: data,
                states: {
                    hover: {
                        color: Highcharts.color(colorThemesArray[0][0]).brighten(0.2).get()
                    },
                    select: {
                        color: Highcharts.color(colorThemesArray[0][0]).brighten(-0.4).get()
                    }
                }
            }],
            colors: ['#d8d8d8'],
            exporting: {
                enabled: false,
            },
            credits: {
                enabled: false
            }
        }
        //var newColor = $.merge($.merge([], []), colorThemesArray[0]);
        //newColor.splice(0, 2);
        options.colors=colorThemesArray[0];
        options.series[0].colorByPoint=true;

        var insightkeyinfluencers = new Highcharts.Chart(options);

        /*if(insightkeyinfluencers.getSelectedPoints().length===0){
            insightkeyinfluencers.series[0].data[0].select(true, true);
        }*/

    },
    drawSmartAVGBar: function (data, categories, renderto, yaxisname, yaxisDisplay, targetColumnOriginal, tabid, reportId, datamodelId, isFromStoryBook) {
        var categorydisplayNames = [];
        var categoryOriginalNames = {};
        for (var i = 0; i < categories.length; i++) {
            categoryOriginalNames[categories[i].columnDisplayName] = categories[i].columnName;
            categorydisplayNames.push(categories[i].columnDisplayName);
        }
        var options = {
            chart: {
                renderTo: renderto[0],
                type: 'bar',
                zoomType: 'xy',
                backgroundColor: 'transparent',
                style: {
                    fontFamily: whitelableMap["fontfamily"],
                    fontWeight: '400',
                },
                animation: false,
                plotBorderWidth: 0,
                spacing: [10, 5, 10, 25]
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: categorydisplayNames,
                title: {
                    text: null
                },
                lineWidth: 0.3,
                tickWidth: 0.3,
                gridLineWidth: 0,
                labels: {
                    step: 1
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: '',
                },
                labels: {
                    enabled: false
                },
                lineWidth: 0,
                tickWidth: 0,
                gridLineWidth: 0,
                plotLines: [{
                    color: 'red',
                    value: '98',
                    width: '1',
                    zIndex: 4,
                    dashStyle: 'LongDash',
                    label: {
                        text: "Overall Average (98)",
                        verticalAlign: 'top',
                        textAlign: 'left',
                        rotation: 0,
                        y: -1,
                        x: -33
                    }
                }]
            },
            tooltip: {

            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: false
                    },
                },
                series: {
                    cursor: 'pointer',
                    pointPadding: 0,
                    name: yaxisDisplay,
                    point: {
                        events: {
                            click: function (event) {
                                var that = this;
                                if (this.series.chart.getSelectedPoints().length > 0) {
                                    this.series.chart.getSelectedPoints()[0].select(false, true)
                                }
                                this.select(null, true);
                                smartInsightDrillDown = new SmartInsightDrillDown(
                                    {
                                        categoryname: categoryOriginalNames[event.point.category.name],
                                        targetColumn: yaxisDisplay,
                                        filter: true,
                                        yaxisDisplay: yaxisDisplay,
                                        targetColumnOriginal: targetColumnOriginal,
                                        categoryOriginalNames: event.point.category.name,
                                        tabid: tabid,
                                        reportId: reportId,
                                        datamodelId: datamodelId,
                                        isFromStoryBook: isFromStoryBook,
                                        categoryType:event.point.ydatatype
                                    });
                            }
                        }
                    },
                    states: {
                        hover: {
                            color: ['#fff']
                        },
                        select: {
                            color: colorThemesArray[0][0]
                        }
                    },
                }
            },
            legend: {
                enabled: true,
                align: 'center',
                symbolHeight: 12,
                symbolWidth: 12,
                symbolRadius: 6
            },
            series: [{

                data: data,
                states: {
                    hover: {
                        color: Highcharts.color(colorThemesArray[0][0]).brighten(0.2).get()
                    },
                    select: {
                        color: Highcharts.color(colorThemesArray[0][0]).brighten(-0.4).get()
                    }
                }
            }],
            colors: ['#d8d8d8'],
            exporting: {
                enabled: false,
            },
            credits: {
                enabled: false
            }
        }
        //var newColor = $.merge($.merge([], []), colorThemesArray[0]);
        //newColor.splice(0, 2);
        options.colors=colorThemesArray[0];
        options.series[0].colorByPoint=true;
        var insightkeyinfluencersavg = new Highcharts.Chart(options);

    },
    drawSmartSimulationWaterfall: function (data, categories, renderto, targetColumn, modelName, frameName) {
        var options = {
            chart: {
                renderTo: renderto[0],
                type: 'waterfall',
                zoomType: 'xy',
                backgroundColor: 'transparent',
                style: {
                    fontFamily: whitelableMap["fontfamily"],
                    fontWeight: '400',
                },
                animation: false,
                plotBorderWidth: 0,
                spacing: [20, 10, 0, 20]
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: categories,
                title: {
                    text: null
                },
                lineWidth: 0,
                tickWidth: 0,
                gridLineWidth: 0,
            },
            yAxis: {
                // min: 0,
                title: {
                    text: '',
                },
                labels: {
                    enabled: false
                },
                lineWidth: 0,
                tickWidth: 0,
                gridLineWidth: 0,
            },
            tooltip: {
                useHTML: true,
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: false
                    },
                },
                series: {
                    upColor: "#00bcd4",
                    color: "#ff9800",
                    pointPadding: 0,
                    groupPadding: 0.1,
                    minPointLength: 3,
                    states: {
                        select: {
                            color: '#00acc1'
                        }
                    },
                    slicedOffset: 0,
                }
            },
            legend: {
                enabled: false
            },

            series: [{
                name: targetColumn,
                data: data,
                states: {
                    hover: {
                        color: '#00acc1'
                    },
                    select: {
                        color: '#00acc1'
                    }
                }
            }],
            colors: colorThemesArray[0],
            exporting: {
                enabled: false,
            },
            credits: {
                enabled: false
            }
        }

        if($("body").width()<=768){
            options.chart.spacing = [0, 5, 0, 5]
        }
        options.xAxis.labels = {style:{fontSize:"12px"}}

        var insightsimulationwaterfall = new Highcharts.Chart(options);

        /*if(insightkeyinfluencers.getSelectedPoints().length===0){
            insightkeyinfluencers.series[0].data[0].select(true, true);
        }*/

    },
    updateReport: function (divId, i, height, width) {
        var $chart = charts[divId];
        if ($chart != undefined && $chart['0'] == undefined) {
            $chart.setSize(width, height);
        } else if ($chart != undefined && $chart['0'] != undefined) {
            $.each($chart, function (i, chart) {
                chart.setSize(width, height);
            });
        }
    },
    setReportColumnNames: function (reportColumns) {
        reportColumns = reportColumns;
    },
    getReportColumnNames: function () {
        return reportColumns;
    },

    invertColor: function (hex, bw) {
        if (hex.indexOf('#') === 0) {
            hex = hex.slice(1);
        }
        // convert 3-digit hex to 6-digits.
        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        if (hex.length !== 6) {
            throw new Error('Invalid HEX color.');
        }
        var r = parseInt(hex.slice(0, 2), 16),
            g = parseInt(hex.slice(2, 4), 16),
            b = parseInt(hex.slice(4, 6), 16);
        if (bw) {
            // http://stackoverflow.com/a/3943023/112731
            return (r * 0.299 + g * 0.587 + b * 0.114) > 186
                ? '#000000'
                : '#FFFFFF';
        }
        // invert color components
        r = (255 - r).toString(16);
        g = (255 - g).toString(16);
        b = (255 - b).toString(16);
        // pad each with zeros and return
        return "#" + this.padZero(r) + this.padZero(g) + this.padZero(b);
    },
    rgb2hex: function (rgb) {
        rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
        return (rgb && rgb.length === 4) ? "#" +
            ("0" + parseInt(rgb[1], 10).toString(16)).slice(-2) +
            ("0" + parseInt(rgb[2], 10).toString(16)).slice(-2) +
            ("0" + parseInt(rgb[3], 10).toString(16)).slice(-2) : '';
    },
    customSelection: function (e) {

        if (e.xAxis != undefined && e.yAxis != undefined) {
            var bool = true;
            if (e.xAxis[0].max < 0 || e.xAxis[0].min < 0 || e.xAxis[0].min < e.xAxis[0].axis.min || e.xAxis[0].min > e.xAxis[0].axis.max || e.xAxis[0].max < e.xAxis[0].axis.min || e.xAxis[0].max > e.xAxis[0].axis.max) {
                bool = false;
            }
            if (e.yAxis[0].max < 0 || e.yAxis[0].min < 0 || e.yAxis[0].min < e.yAxis[0].axis.min || e.yAxis[0].min > e.yAxis[0].axis.max || e.yAxis[0].max < e.yAxis[0].axis.min || e.yAxis[0].max > e.yAxis[0].axis.max) {
                bool = false;
            }
            return bool;
        }
        return true; // Don't zoom
    },
    padZero: function (str, len) {
        len = len || 2;
        var zeros = new Array(len).join('0');
        return (zeros + str).slice(-len);
    }
};