import React, { Component } from "react";
import ReactDOM from 'react-dom';
import SendRequest from '../../../SendRequest';
import MailSettingsTemplate from './MailSettingsTemplate';
import NewMailConfigView from './NewMailConfigView';
import EditMailConfigView from './EditMailConfigView';
const $ =window.$;

export default class MailSettingsView extends Component{
    constructor(props){
        super(props);
        this.getMailConfigDetails();
    }
    getMailConfigDetails(){
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
		SendRequest({
            url: "getEmailConfigDetails",
            queryString: "",
            sucessFunction: (response) => {
                this.render(response)
            },
            rejectFunction: () => { },
            headers: requestHeaders
        
        });
    }
    newMailConfigView=()=>{
        var newMailConfigView = new NewMailConfigView({});
    }

    editMailConfig=(e)=>{
        var clientId = e.currentTarget.value
        var editMailConfigView = new EditMailConfigView({clientId : clientId});
    }

    render(response){
        ReactDOM.render(<MailSettingsTemplate clientId = {response[0].clientId} email = {response[0].email} status = {response[0].status}
            newMailConfigView={this.newMailConfigView} editMailConfig={this.editMailConfig}/>,document.querySelector(".othersettingscontainer"))
        return(<div></div>);
    }    
}
