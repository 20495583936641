import React, { Component } from 'react'
import { lang } from "../../js/helpers/utils/languages/language";
import ReactDOM from 'react-dom'
const $ = window.$;
export default class ViewreportSettingsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicdiv: props.dynamicdiv
        };
        this.state.viewreportCollection = this.props.viewreportCollection;
        this.state.title = $(".boc-viewreport-title[data-num='storyboard']").text();
        this.render();
    }
    render() {
        ReactDOM.render(<ViewReportSettingsTemplate applysetting={this.applysetting} closeSettingsModal={this.closeSettingsModal} title={this.state.title} />, document.getElementById(this.state.dynamicdiv));
        return (<div></div>)
    }
    closeSettingsModal = () => {
        ReactDOM.unmountComponentAtNode(document.getElementById(this.state.dynamicdiv));
    }
    applysetting = () => {
        var titlename = $("#" + this.state.dynamicdiv).find("#titlename").val();
        if (titlename != undefined && titlename != "" && titlename.trim() != "") {
            $(".boc-viewreport-title[data-num=storyboard]").text(titlename).attr("title", titlename);
            sessionStorage.setItem("changesdone", 1);
            this.closeSettingsModal();
        }
    }
}

class ViewReportSettingsTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        var that = this.props;
        return (
            //    <!-- viewreportsettingview.js -->
            <div className="modal fade in" id="pagesettingsmodal" tabindex="-1" role="dialog" aria-labelledby="pagesettingsmodal" aria-hidden="true" style={{ display: "block" }}>
                <div className="modal-backdrop fade in"></div>
                <div className="modal-dialog modal-sm modal-sm-400 modal-dialog-sm-center" style={{ zIndex: "1050" }}>
                    <div className="modal-content">
                        <div className="modal-header boc-modal-hf-padding">
                            <button type="button" className="close white" id="viewreportsettingclose" onClick={that.closeSettingsModal} aria-hidden="true" data-dismiss="modal">&times;</button>
                            <h4 className="modal-title"><i className="fa fa-pencil"></i>{lang["smartsights.edittitle"]}</h4>
                        </div>
                        <div className="modal-body scrollset">
                            <div className="container-fluid">
                                <div className="row filtercontent">
                                    <div className="validationgroup">
                                        <span className="required right legend"></span>
                                        <legend className="subhead">{lang["smartsights.boardtitle"]}</legend>
                                        <div className="col-xs-12 nopadding"><textarea id="titlename" rows="1" name="titlename" className="modal-form-fields resize-vertical" placeholder={lang["smartsights.boardtitle"]}>{that.title}</textarea></div>
                                        <span className="help-block hidden marg-left-10"></span>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="modal-footer boc-modal-hf-padding">
                            <button type="button" className="btn btn-bird" id="btn-birdstorytitle" onClick={that.closeSettingsModal} data-dismiss="modal"><i className="fa fa-times"></i> {lang["smartsights.cancelbtn"]}</button>
                            <button type="button" className="btn btn-bird applysetting" onClick={that.applysetting} id="applysettings"><i className="fa fa-check"></i> {lang["smartsights.applybtn"]}</button>
                        </div>
                    </div>
                    {/* <!-- /.modal-content --> */}
                </div>
                {/* <!-- /.modal-dialog --> */}
            </div>
            //    <!-- /.Page Settings Modal -->
        );
    }
}