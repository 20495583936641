import React from 'react';

export default class SingleReportBodyHtml extends React.Component{
    render(){
        return(
            // <!-- viewsavedsinglereportbody.js -->
<div id="singlesavedchartdiv" style="width:100%; height:calc(100vh - 15vh);"> 

</div>
        );
    }
}