import React, { Component } from "react";
import SendRequest from '../../SendRequest'
import ReactDOM from 'react-dom'
import { lang } from "../../js/helpers/utils/languages/language";
const $ = window.$;
export default class AuditSettingsTemplate extends Component {
    constructor(props) {
        super(props);
    }
    cancelAuditSettings = () => {
        ReactDOM.unmountComponentAtNode(document.querySelector(".append_alertmodal"))
    }
    applyAuditSettings = (e) => {
        e.preventDefault();
        var isSyncValid = true;
        var maxage = $("#maxage").val();
        var maxageunits = $("#maxageunits option:selected").val();
        var isChecked = $("#maxDataCheck").is(':checked');
        if (isChecked && (maxage == "" || maxage < 1 || this.isFloat(maxage))) {
            $('.maxagevalidation').text(lang["admin.audit.maxageerror"]);
            $('.maxagevalidation').removeClass("hide");
            isSyncValid = false;
        } else {
            let requestHeaders = {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            var reqdata = new FormData();
            reqdata.set("enableordisableaudit", $('.btnReadonlyReport').is(':checked'));
            reqdata.set("maxage", $('.maxage').val());
            reqdata.set("maxageunits", $('.maxageunits').val());
            SendRequest({
                url: "enableordisableaudit",
                body: reqdata,
                sucessFunction: (response) => {
                    ReactDOM.unmountComponentAtNode(document.querySelector(".append_alertmodal"))
                },
                rejectFunction: () => { },
                headers: requestHeaders

            });
        }
    }
    isFloat(n) {
        return n % 1 !== 0;
    }
    maxDataCheck = (e) => {
        var $target = $(e.target);
        var selected = $target.is(':checked');
        if (selected == true) {
            $('#maxage').removeClass("disabled");
            $('#maxageunits').removeClass("disabled");
            $(".auditconfig").removeClass("disabled");
        } else {
            $('#maxage').val("");
            $('#maxage').addClass("disabled");
            $('#maxageunits').addClass("disabled");
            $(".auditconfig").addClass("disabled");
        }
    }
    render() {
        var that = this.props;
        var data_age_number = that.data_age_number;
        var data_age_duration = that.data_age_duration != null && that.data_age_duration != "" ? that.data_age_duration : "W";
        var temp;

        var tempElement;
        if (data_age_number != null) {
            tempElement = <><input type="number" className="form-control max-width-100-px maxage" id="maxage" placeholder="0" defaultValue={data_age_number} />
                <div className="form-group">
                    <label for="exampleInputEmail2"></label>
                    <select id="maxageunits" className="form-control maxageunits">
                        {(data_age_duration == 'W') ? (
                            <><option value="W" selected>{lang["admin.audit.weeks"]}</option>
                                <option value="M">{lang["admin.audit.months"]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</option>
                                <option value="Y">{lang["admin.audit.years"]}</option></>
                        ) : (data_age_duration == 'M') ? (
                            <><option value="W">{lang["admin.audit.weeks"]}</option>
                                <option value="M" selected>{lang["admin.audit.months"]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</option>
                                <option value="Y">{lang["admin.audit.years"]}</option></>
                        ) : (data_age_duration == 'Y') ?
                                    <><option value="W">{lang["admin.audit.weeks"]}</option>
                                        <option value="M">{lang["admin.audit.months"]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</option>
                                (<option value="Y" selected>{lang["admin.audit.years"]}</option>)</>
                                    :
                                    <><option value="W" selected>{lang["admin.audit.weeks"]}</option>
                                        <option value="M">{lang["admin.audit.months"]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</option>
                                        <option value="Y">{lang["admin.audit.years"]}</option></>}
                    </select>
                </div>
                <div style={{ color: "red" }} className="col-xs-12 maxagevalidation hide"></div>
            </>
        } else {

            tempElement = <><div style={{display: "inline-block", float: "right", marginTop: "-8px", marginRight: "14px"}}>
                <input type="number" className="form-control max-width-100-px maxage disabled" id="maxage" placeholder="0" defaultValue={data_age_number} />
                <div className="form-group">
                    <label for="exampleInputEmail2"></label>
                    <select id="maxageunits" className="form-control maxageunits disabled">
                        {(data_age_duration == 'W') ? (
                            <><option value="W" selected>{lang["admin.audit.weeks"]}</option>
                                <option value="M">{lang["admin.audit.months"]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</option>
                                <option value="Y">{lang["admin.audit.years"]}</option></>
                        ) : (data_age_duration == 'M') ? (
                            <><option value="W">{lang["admin.audit.weeks"]}</option>
                                <option value="M" selected>{lang["admin.audit.months"]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</option>
                                <option value="Y">{lang["admin.audit.years"]}</option></>
                        ) : (data_age_duration == 'Y') ?
                                    <><option value="W">{lang["admin.audit.weeks"]}</option>
                                        <option value="M">{lang["admin.audit.months"]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</option>
                                (<option value="Y" selected>{lang["admin.audit.years"]}</option>)</>
                                    :
                                    <><option value="W" selected>{lang["admin.audit.weeks"]}</option>
                                        <option value="M">{lang["admin.audit.months"]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</option>
                                        <option value="Y">{lang["admin.audit.years"]}</option></>}
                    </select>
                </div>
            </div>
            <div style={{ color: "red" }} className="col-xs-12 maxagevalidation hide"></div>
            </>
        }

        if (data_age_number != null) {
            temp = <div className="auditconfig">
                <form className="form-inline">
                    <div className="form-group width-100"> {lang["admin.audit.maxage"]}&nbsp;&nbsp;
{tempElement}
                    </div>
                </form>
            </div>
        }
        else {
            temp = <div className="auditconfig disabled">
                <form className="form-inline">
                    <div className="form-group width-100"> {lang["admin.audit.maxage"]}&nbsp;&nbsp;
{tempElement}
                    </div>
                </form>
            </div>
        }
        return (
            <div className="modal fade alertsmodal in" id="alertsmodal" tabIndex="-1" role="dialog" aria-labelledby="alertsmodal" aria-hidden="true">
                <div className="modal-backdrop fade in"></div>
                <div className="modal-dialog modal-sm modal-sm-400 modal-dialog-sm-center" style={{ zIndex: "1050" }}>
                    <div className="modal-content">
                        <div className="modal-header boc-modal-hf-padding">
                            <button type="button" className="close white" id="settingscloseicon" onClick={this.cancelAuditSettings} aria-hidden="true" data-dismiss="modal">&times;</button>
                            <h4 className="modal-title"><i className="fa fa-cogs marg-righ-10"></i>{lang["admin.audit.settings"]}</h4>
                        </div>
                        <div className="modal-body scrollset">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-xs-12 marg-top-20">
                                        <div className="auditenable scrollsetulviewer">
                                            <label className="auditenableviwer subhead">{lang["admin.auditlog"]}</label>
                                            <div className="drilldowntogglecheckbox">
                                                <div className="checkbox checkbox-slider--c checkbox-slider-sm">
                                                    <label> <input type="checkbox" id="btnReadonlyReport" className="btnReadonlyReport" data-userid="4" user-name="testinactive users" user-type="groups" /><span>&nbsp;</span></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 marg-top-10 marg-bot-20">
                                        <div className="auditretentionconfiguration scrollsetulviewer marg-top-10">
                                            <label className="auditretention subhead"> {lang["admin.auditlogconfig"]}
										    <div className="drilldowntogglecheckbox">
                                                    <div className="checkbox checkbox-slider--c checkbox-slider-sm">
                                                        <label>
                                                            {(data_age_number != null) ? (<input id="maxDataCheck" type="checkbox" className="nomargin" defaultChecked onClick={this.maxDataCheck} />
                                                            ) : (<input id="maxDataCheck" type="checkbox" className="nomargin" onClick={this.maxDataCheck} />)}
                                                            <span>&nbsp;</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </label>
                                            {temp}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer boc-modal-hf-padding">
                            <button type="button" className="btn btn-bird" data-dismiss="modal" id="auditsettingclosebutton" onClick={this.cancelAuditSettings}><i className="fa fa-times marg-righ-10"></i>{lang["admin.audit.canclbtn"]}</button>
                            <button type="button" className="btn btn-bird applyauditsettings" id="auditsettingapplybutton" onClick={this.applyAuditSettings}><i className="fa fa-check marg-righ-10"></i>{lang["admin.audit.applybtn"]}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

