import React from 'react';
import { lang } from "../js/helpers/utils/languages/language";
const $=window.$;

export default class WorkspaceItemSettingsFilterModalHtml extends React.Component {
    constructor(props) {
        super(props);
    }
    closeModal=()=>{
        $('#workspacefiltermodal').remove();
        $('.modal-backdrop').remove();
    }
    render() {
        return (
            <div className="modal fade" id="workspaceitemsfiltermodal" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
                <div className="modal-backdrop fade in"></div>
                <div className="modal-dialog modal-sm modal-dialog-center" style={{zIndex:"1050"}}>
                    <div className="modal-content">
                        <div className="modal-header boc-modal-hf-padding">
                            <button type="button" className="close white" id="close" data-dismiss="modal" aria-hidden="true" onClick={this.closeModal}>&times;</button>
                            <h4 className="modal-title"><i className="fa fa-filter"></i> {lang["workspace.filter"]}</h4>
                        </div>
                        <div className="modal-body scrollsetnew">
                            <div className="container-fluid marg-top-5 nopadding">
                                <input className="form-control search-image" placeholder={lang["workspace.search"]} id="searchinputfilter" type="text" onChange={(e)=>{this.props.search(e)}} />
                            </div>
                            <div className="container-fluid">
                                <div className="row filtercontent dobyfilteritems">
                                    <ul className="list-group scrollsetul">
                                        {
                                            (this.props.filterfields!==undefined)? (
                                                this.props.filterfields.map((valueObject, index) => {
                                                    return (
                                                        <>
                                                            {
                                                                (index === 0) ? (
                                                                    <>
                                                                        {(this.props.entityName === 'webdataconnector') ? (
                                                                            <li className="boc-list-item"><label>{this.props.entityName}</label></li>
                                                                        ) : (
                                                                                <li className="boc-list-item"><label>{valueObject.tablename}</label></li>
                                                                            )}
                                                                    </>
                                                                ) : null
                                                            }
                                                            <li className="boc-list-item">
                                                                <label title={valueObject.columnDisplayName}>
                                                                    <input type="checkbox" className="reportfiltercheckbox" id="reportfiltercheckboxs" data-type={valueObject.tableDisplayType} data-parent={valueObject.tablename} data-child={valueObject.orginalColumnName} name={valueObject.columnDisplayName} />
                                                                    {
                                                                        (valueObject.tableDisplayType === 'date' || valueObject.tableDisplayType === 'datetime') ? (<i className="fa fa-calendar fa-filter-icon"></i>) : null
                                                                    }
                                                                    {
                                                                        (valueObject.tableDisplayType === 'string') ? (<i className="fa fa-sort-alpha-asc fa-filter-icon"></i>) : null
                                                                    }
                                                                    {
                                                                        (valueObject.tableDisplayType === 'number') ? (<i className="fa fa-sort-numeric-asc fa-filter-icon"></i>) : null
                                                                    }
                                                                    {
                                                                        (valueObject.tableDisplayType === 'month') ? (<i className="fa fa-calendar-o fa-filter-icon"></i>) : null
                                                                    }
                                                                    {
                                                                        (valueObject.tableDisplayType == 'day') ? (<i className="fa fa-calendar-o fa-filter-icon"></i>) : null
                                                                    }
                                                                    {valueObject.columnDisplayName}
                                                                </label>
                                                            </li>
                                                        </>
                                                    );
                                                })
                                            ):null
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer boc-modal-hf-padding">
                            <button type="button" className="btn btn-bird" id="btn-bird" data-dismiss="modal" onClick={this.closeModal}><i className="fa fa-times"></i> {lang["workspace.cancelbtn"]}</button>
                            <button type="button" className="btn btn-bird workspacefiltersapply" id="workspacefiltersapply" data-parent={this.props.connId} name={this.props.entityName} onClick={(e)=>{this.props.WorkSpaceFilters(e)}}><i className="fa fa-check"></i> {lang["workspace.applybtn"]}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}