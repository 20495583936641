import React from 'react';
import ReactDom from 'react-dom';
import _ from 'underscore';
import SendRequest from '../SendRequest';
import DatasourceConnectionHtml from './DatasourceConnectionHtml.jsx';
import LogFileConnection from './LogfileConnection';
import AddtoExists from './AddtoExistsHtml.jsx';
import FileUploadHtml from './FileUploadHtml.jsx';
import WebDataConnection from './WebDataConnection';
import { getMessage } from '../js/helpers/utils/BirdMessage';
import AddNewAddressHtml from './AddNewAddressHtml.jsx';
import Datahub from './Datahub';
import GenericJDBCView from './genericjdbcview';
import { lang } from "../js/helpers/utils/languages/language";
const $=window.$;

export default class DatasourceConnection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            schemaName: props.schemaName,
            schemaDisplayName: props.schemaDisplayName,
            schemaId: props.schemaId,
            connectionId: props.connectionId,
            fileName: props.fileName,
            scopename: props.scopename,
            editfilename: props.editfilename,
            isFromEditFlatFiles: props.isFromEditFlatFiles,
            isFromEdit: props.isFromEdit,
            connectionjson: props.connectionjson,
            connectiondetails: props.connectiondetails,
            connectiondata: props.connectiondata
        }
     
    }
    requestHeaders = {
        method: "get",
        headers: new Headers({
            "Content-Type": "application/json"
        })
    };
    databaseconnectiondetails = {};
    componentDidMount() {
        if (this.state.schemaName.toLowerCase() === "csv" ||
            this.state.schemaName.toLowerCase() === "json" ||
            this.state.schemaName.toLowerCase() === "xls" ||
            this.state.schemaName.toLowerCase() === "pdf" ||
            this.state.schemaName.toLowerCase() === "log") {
            var connectiondetails;
            var uploadsize;
            var allow;
            SendRequest({
                url: "getConnectionDetailsById",
                queryString: "?schemaId=" + this.state.schemaId,
                sucessFunction: (response) => {
                    if (!response.hasOwnProperty('error')) {
                        connectiondetails = JSON.parse(response.connection_details_json);
                        uploadsize = connectiondetails[0].uploadsize;
                        allow = connectiondetails[0].allow;
                        if (this.state.schemaName.toLowerCase() === "log") {
                            ReactDom.render(
                                <LogFileConnection
                                    schemaId={this.state.schemaId}
                                    schemaName={this.state.schemaName}
                                    schemaDisplayName={this.state.schemaDisplayName}
                                    connectiondetails={connectiondetails}
                                    connectionjson={{}}
                                    connectionId={this.state.connectionId}
                                    scopename={this.props.scopename}
                                    ftpurl={this.state.connectionjson.ftpurl}
                                    ftpusername={this.state.connectionjson.ftpusername}
                                    ftppassword={this.state.connectionjson.ftppassword}
                                    uploadtype={this.state.connectionjson.uploadFileType}
                                    isFromEditFlatFiles={this.state.isFromEditFlatFiles}
                                    ftpfilename={this.state.connectionjson.ftpfilename}
                                    editfilename={this.state.editfilename}
                                    logpattern={this.state.connectionjson.logPattern}
                                    logtype={this.state.connectionjson.logType}
                                    isFromEdit={this.state.isFromEdit}
                                    close={this.closeconnectionpop}
                                />,
                                document.querySelector('.datasourceconnection-container')
                            )
                        } else if (this.state.isFromEditFlatFiles == true) {
                            ReactDom.render(
                                <AddtoExists
                                    schemaName={this.state.schemaName}
                                    editfilename={this.state.editfilename}
                                    isFromEditFlatFiles={this.state.isFromEditFlatFiles}
                                    schemaDisplayName={this.state.schemaDisplayName}
                                    allow={allow}
                                    uploadsize={uploadsize}
                                    fileName={this.state.editfilename}
                                    csvsavenewconnection={this.savenewconnectionforfiles}
                                    close={this.closeconnectionpop}
                                />,
                                document.querySelector('.datasourceconnection-container')
                            );
                            $('.datasourceconnection-container').find("#fileupload").fileinput({
                                'showUpload': false,
                                'showPreview': false,
                                'maxFileSize': uploadsize * 1024,
                                'msgSizeTooLarge': '<span class="text-danger"><i class="glyphicon glyphicon-exclamation-sign"></i>'+lang["Datahub.exceedsmaxsize"]+'</span>'
                            });
                            $('.datasourceconnection-container').find("#fileupload").on('fileerror', function(event, data, msg) {
                                setTimeout(function(){
                                    $(".form-control.file-caption.kv-fileinput-caption").attr("title","Exceeds maximum allowed upload size!!")
                                },600);
                            });
                        } else {
                            ReactDom.render(
                                <FileUploadHtml
                                    schemaName={this.state.schemaName}
                                    schemaDisplayName={this.state.schemaDisplayName}
                                    allow={allow}
                                    uploadsize={uploadsize}
                                    csvsavenewconnection={this.savenewconnectionforfiles}
                                    close={this.closeconnectionpop}
                                />,
                                document.querySelector('.datasourceconnection-container')
                            );
                            $('.datasourceconnection-container').find("#fileupload").fileinput({
                                'showUpload': false,
                                'showPreview': false,
                                'maxFileSize': uploadsize * 1024,
                                'msgSizeTooLarge': '<span class="text-danger"><i class="glyphicon glyphicon-exclamation-sign"></i>'+lang["Datahub.exceedsmaxsize"]+'</span>'
                            });
                            $('.datasourceconnection-container').find("#fileupload").on('fileerror', function(event, data, msg) {
                                setTimeout(function(){
                                    $(".form-control.file-caption.kv-fileinput-caption").attr("title","Exceeds maximum allowed upload size!!")
                                },600);
                            });
                        }
                        if ($('.datasourceconnection-container').parent().hasClass('addtoexistingcontainer')) {
                            ReactDom.render(
                                <AddtoExists
                                    schemaName={this.state.schemaName}
                                    isFromEditFlatFiles={this.state.isFromEditFlatFiles}
                                    schemaDisplayName={this.state.schemaDisplayName}
                                    allow={allow}
                                    uploadsize={uploadsize}
                                    fileName={this.state.fileName}
                                    close={this.closeconnectionpop}
                                    csvsavenewconnection={this.savenewconnectionforfiles}
                                />,
                                document.querySelector('.datasourceconnection-container')
                            );
                            $('.datasourceconnection-container').find("#fileupload").fileinput({
                                'showUpload': false,
                                'showPreview': false,
                                'maxFileSize': uploadsize * 1024,
                                'msgSizeTooLarge': '<span class="text-danger"><i class="glyphicon glyphicon-exclamation-sign"></i>'+lang["Datahub.exceedsmaxsize"]+'</span>'
                            });
                        }
                    }
                },
                rejectFunction: (error) => {
                    console.log("can't process /getConnectionDetailsById request" + error.message);
                },
                headers: this.requestHeaders
            });
        } else if (this.state.schemaName.toLowerCase() == "webdataconnector") {
            ReactDom.render(
                <WebDataConnection
                    schemaId={this.state.schemaId}
                    schemaName={this.state.schemaName}
                    schemaDisplayName={this.state.schemaDisplayName}
                    connectionjson={this.state.connectiondata}
                    connection_access_id={this.state.connectionId}
                    close={this.closeconnectionpop}
                />,
                document.querySelector('.datasourceconnection-container')
            );
        }
        else if (this.state.schemaName.toLowerCase() == "genericjdbc") {
            var that = this;
            ReactDom.render(
                <GenericJDBCView
                    schemaId={this.state.schemaId}
                    schemaName={this.state.schemaName}
                    schemaDisplayName={this.state.schemaDisplayName}
                    connectionjson={this.state.connectionjson}
                    connection_access_id={this.state.connectionId}
                    close={this.closeconnectionpop}
                />,
                document.querySelector('.datasourceconnection-container')
            );
        }
         else {
            ReactDom.render(
                <DatasourceConnectionHtml
                    schemaId={this.state.schemaId}
                    schemaName={this.state.schemaName}
                    schemaDisplayName={this.state.schemaDisplayName}
                    connectionjson={this.state.connectiondata}
                    connection_access_id={this.state.connectionId}
                    connectiondetails={this.state.connectiondetails}
                    connectiondata={this.state.connectiondata}
                    dbsavenewconnection={this.dbsavenewconnection}
                    testconnection={this.testconnection}
                    addnewaddress={this.addnewaddress}
                    removeaddresskafka={this.removeaddresskafka}
                    close={this.closeconnectionpop}
                    connectionjson={this.state.connectionjson}
                />,
                document.querySelector('.datasourceconnection-container')
            );
        }

    }
    closeconnectionpop=(e)=>{
        $(".connection-center").removeClass("connection-in");
    }
    validateflatfiles = (text) => {
        $(".spinner").hide();
        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
        $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
        $('.birdmessagebuttons').removeClass('show');
        $('.birdmessagebuttons').addClass('hide');
        $('.birdmessage-center').addClass("message-top-in");
        $('.details').removeClass('show');
        $('.details').addClass('hide');
        $('.birdmessage h2').empty().append('Warning');
        $('.birdmessage-info').empty().text(getMessage('BirdWarning123').replace('@replace', text));
    }
    validateflatfilesForSpecialChars = (text) => {
        $(".spinner").hide();
        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
        $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
        $('.birdmessagebuttons').removeClass('show');
        $('.birdmessagebuttons').addClass('hide');
        $('.birdmessage-center').addClass("message-top-in");
        $('.details').removeClass('show');
        $('.details').addClass('hide');
        $('.birdmessage h2').empty().append('Warning');
        $('.birdmessage-info').empty().text(lang["Datahub.filespclchar"]);
        return false;
    }
    savenewconnectionforfiles = (e) => {
        e.preventDefault();
        var that = this;
        //connectionname
        try {
            $('.spinner').show();
            var text = "";
            var connectionObject = {};
            if ($("#fileupload").val() === "") {
                $(".csverror").show();
            }else{
                $(".csverror").hide();
            }
            if ($(".connectionname").val() === "") {
                $(".csvnameerrors").hide();
                $(".csvnameerror").show();
                $('.spinner').hide();
                return;
            }else if($(".connectionname").val()!=undefined && $(".connectionname").val().replace(/\s+/g, '').length == 0){
                $(".csvnameerror").hide();
                $(".csvnameerrors").show();
                $('.spinner').hide();
                  return;
            }
            else{
            	if(/[^a-zA-Z0-9_ ]/.test( $(".connectionname").val())){
               	$(".csvnameerror").hide();
               	$(".csvnameerrors").show();
               	$('.spinner').hide();
             		return;
             	}else{
            	$(".csvnameerror").hide();
             	$(".csvnameerrors").hide();
               	}
            }
            var file_data = $("input#fileupload").prop("files")[0];
            var fileName = $("input#fileupload").prop("files")[0].name;
            var filename = fileName.slice(0, fileName.lastIndexOf("."));
            if (/[^a-zA-Z0-9_ ]/.test(filename)) {
                text = "*.xls, *.xlsx";
                this.validateflatfilesForSpecialChars(text);
                return false;
            }
            if (this.state.schemaName.toLowerCase() == "csv" &&
                (fileName.substr(fileName.lastIndexOf(".") + 1).toLowerCase() != "csv" &&
                    fileName.substr(fileName.lastIndexOf(".") + 1).toLowerCase() != "txt" &&
                    fileName.substr(fileName.lastIndexOf(".") + 1).toLowerCase() != "tsv")) {
                text = "*.CSV, *.TXT, *.TSV";
                this.validateflatfiles(text);
            } else if (this.state.schemaName.toLowerCase() == "xls" &&
                (fileName.substr(fileName.lastIndexOf(".") + 1).toLowerCase() != "xls" &&
                    fileName.substr(fileName.lastIndexOf(".") + 1).toLowerCase() != "xlsx")) {
                text = "*.xls, *.xlsx";
                this.validateflatfiles(text);
            } else if (this.state.schemaName.toLowerCase() == "pdf" &&
                (fileName.substr(fileName.lastIndexOf(".") + 1).toLowerCase() != "pdf")) {
                text = " *.pdf ";
                this.validateflatfiles(text);
            } else if (this.state.schemaName.toLowerCase() == "json" &&
                (fileName.substr(fileName.lastIndexOf(".") + 1).toLowerCase() != "json")) {
                text = " *.json ";
                this.validateflatfiles(text);
            } else if (this.state.schemaName.toLowerCase() == "log" &&
                (fileName.substr(fileName.lastIndexOf(".") + 1).toLowerCase() != "log")) {
                text = " *.log ";
                this.validateflatfiles(text);
            } else {
                var connectionjson = {};
                if (this.state.schemaName.toLowerCase() == "pdf") {
                    var scannedPageValue = $("input[name ='scanpages']:checked").val();
                    var pagenumber = 1;
                    var fromRange = 0;
                    var toRange = 1;
                    if (scannedPageValue == "single") {
                        pagenumber = $("#pagenumber").val();
                    }
                    else if (scannedPageValue == "range") {
                        fromRange = $("#fromrange").val();
                        toRange = $("#torange").val();
                    }
                    connectionObject["pagenumber"] = pagenumber;
                    connectionObject["fromrange"] = fromRange;
                    connectionObject["torange"] = toRange;
                    connectionObject["scannedpagevalue"] = scannedPageValue;
                }
                connectionObject["connection_access_name"] = $('.connectionname').val();
                connectionjson["connectionname"] = this.state.schemaName;
                connectionObject["connection_id"] = this.state.schemaId;
                if (this.state.isFromEdit) {
                    var entityname;
                    if (this.props.schemaName.toLowerCase() == "xls") {
                        entityname = this.props.entityName;
                        var connectionData = JSON.parse(this.state.connectiondata);
                        var connectionjson = JSON.parse(connectionData.connectionjson);
                        var oldsheetName = connectionjson.sheets[this.props.entityName];
                        connectionObject["oldsheetName"] = oldsheetName;
                    } else {
                        entityname = this.state.fileName.replace(".csv", "").replace(".txt", "").replace(".tsv", "").replace(".json", "").replace(".pdf", "").replace(".log", "");
                    }
                    connectionObject["entityId"] = this.props.entityId;
                    connectionObject["entityname"] = entityname;
                    connectionObject["connection_access_id"] = this.state.connectionId;
                    connectionObject["oldcolumns"] = this.props.metadata[this.state.connectionId + "&&&" + entityname];
                } else if (this.props.isFromEditFlatFiles) {
                    var entityname = this.props.editfilename.replace(".csv", "").replace(".txt", "").replace(".tsv", "").replace(".json", "").replace(".pdf", "").replace(".log", "");
                    connectionObject["entityId"] = this.props.entityId;
                    connectionObject["entityname"] = entityname;
                    connectionObject["connection_access_id"] = this.state.connectionId;
                    //	connectionObject["oldcolumns"] = this.options.metadata[this.connectionId+"&&&"+entityname];
                }

                else {
                    connectionObject["connection_access_id"] = null;
                }
                connectionObject["isFromEdit"] = this.state.isFromEdit;
                connectionObject["connection_details"] = JSON.stringify(connectionjson);
               
                var form_data = new FormData();
                form_data.append("isFromModel", 0);
                form_data.append("file0", file_data);
                form_data.append("connectionName", this.state.schemaName);
                form_data.append("name", file_data.name);
                form_data.append("connectionObject", JSON.stringify(connectionObject));

                SendRequest({
                    url: "saveFileConnectionDetails",
                    body: form_data,
                    queryString: "",
                    sucessFunction: (data) => {
                        if (data.hasOwnProperty('error')) {
                            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                            $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                            $('.birdmessagebuttons').removeClass('show');
                            $('.birdmessagebuttons').addClass('hide');
                            $('.birdmessage-center').addClass("message-top-in");
                            $('.details').removeClass('hide');
                            $('.birdmessage h2').empty().append('Error : ');
                            $('.birdmessage h2').append('<span class="errorcode">');
                            $('.birdmessage .errorcode').empty().append(data.errorCode);
                            $('.birdmessage-info').empty().text(data.error);
                            $('.details').addClass('show');
                            $('.messagedetails xmp').empty().append(data.error)
                            $('.spinner').hide();
                            setTimeout(function () {
                                $('.birderror').remove();
                            }, 3000);
                        } else if (data.success == false) {
                            $(".spinner").hide();
                            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                            $('.birdmessagebuttons').removeClass('show');
                            $('.birdmessagebuttons').addClass('hide');
                            $('.birdmessage-center').addClass("message-top-in");
                            $('.details').removeClass('show');
                            $('.details').addClass('hide');
                            $('.birdmessage h2').empty().append('Warning');
                            $('.birdmessage-info').empty().text(getMessage('BirdWarning124'));
                            sessionStorage.setItem("changesdone", 0);
                            $('.browselogfiles').removeClass('disabled');
                            $(".connection-center").removeClass("connection-in");
                            setTimeout(function () { $("body").removeClass("connection-open creating-new-connection"); }, 800);
                        } else if (data.csvFrom = "addexistingreport" && data.status == undefined) {
                            $(".spinner").hide();
                            $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                            $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                            $('.birdmessagebuttons').removeClass('show');
                            $('.birdmessagebuttons').addClass('hide');
                            $('.birdmessage-center').addClass("message-top-in");
                            $('.details').removeClass('show');
                            $('.details').addClass('hide');
                            $('.birdmessage h2').empty().append('Success');
                            $('.birdmessage-info').empty().text(getMessage('BirdSuccess51'));
                            sessionStorage.setItem("changesdone", 0);
                            $('.browselogfiles').removeClass('disabled');
                            $(".connection-center").removeClass("connection-in");
                            setTimeout(function () { $("body").removeClass("connection-open creating-new-connection"); }, 800);
                            $('.closesettingscontainer').click();
                            /*	require(['views/datahub/datahubview'], function (DataHubView) {
                                    new DataHubView({selectedConnectionId:data.connectionAccessId});
                                });*/
                            //that.broker.trigger("loadConnections",data.connectionAccessId)
                            this.datahub=new Datahub();
                            this.datahub.loadConnections(data.connectionAccessId);
                        } else {
                            $(".spinner").hide();
                            $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                            $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                            $('.birdmessagebuttons').removeClass('show');
                            $('.birdmessagebuttons').addClass('hide');
                            $('.birdmessage-center').addClass("message-top-in");
                            $('.details').removeClass('show');
                            $('.details').addClass('hide');
                            $('.birdmessage h2').empty().append('Success');
                            $('.birdmessage-info').empty().text(getMessage('BirdSuccess41'));
                            sessionStorage.setItem("changesdone", 0);
                            $('.browselogfiles').removeClass('disabled');
                            $(".connection-center").removeClass("connection-in");
                            setTimeout(function () { $("body").removeClass("connection-open creating-new-connection"); }, 800);
                            //that.broker.trigger("loadConnections", data.connectionAccessId)
                            this.datahub=new Datahub();
                            this.datahub.loadConnections(data.connectionAccessId);
                        }
                    },
                    rejectFunction: (error) => {
                        console.log("can't process /saveFileConnectionDetails request" + error.message);
                    },
                    headers: {
                        method: "post",
                        headers: new Headers({
                            "Content-Type": "application/json"
                        })
                    }
                });
            }
        } catch (e) {
            $('.spinner').hide();
        }
    }
    dbsavenewconnection = (e) => {
        e.preventDefault();
        $(".spinner").show();
        var connectionObj = {};
        if (this.isValid()) {
            $('.spinner').show();
            var connectionObj = this.databaseconnectiondetails;
            connectionObj["connectionaccessid"] = this.state.connectionId;
            connectionObj["command"] = "testConnection";
            if(this.databaseconnectiondetails.connectionname.length > 100){
						$(".spinner").hide();
						$(".gridspinner").hide();
						$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
						$('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
						$('.birdmessagebuttons').removeClass('show');
						$('.birdmessagebuttons').addClass('hide');
						$('.birdmessage-center').addClass("message-top-in");
						$('.details').removeClass('show');
						$('.details').addClass('hide');
					    $('.birdmessage h2').empty().append('Warning');
						$('.birdmessage-info').empty().text(lang["Datahub.connectionchar"]);
						return false;
					}
            var data=new FormData();
            data.append("connectionObject",JSON.stringify(connectionObj));
            SendRequest({
                url: "tables",
                queryString: "",
                body: data,
                sucessFunction: (response) => {
                    if (response.success != true) {
                        this.showDatabaseErrorMessage(response, true);
                        $('.spinner').hide();
                    } else {
                        connectionObj["connectionAccessName"] = this.state.schemaName;
                        connectionObj["connectionName"] = this.state.schemaName;
                        connectionObj["connectionId"] = this.state.schemaId;
                        connectionObj["connection_id"] = parseInt(this.state.schemaId);
                        connectionObj["connection_access_name"] = this.databaseconnectiondetails.connectionname;
                        connectionObj["connection_access_id"] = this.state.connectionId;
                        connectionObj["connection_details"] = JSON.stringify(this.databaseconnectiondetails);
                        var info=new FormData();
                        info.append("connectionObject",JSON.stringify(connectionObj));
                        info.append("isFromModel",0);
                        SendRequest({
                            url: "saveConnectionDetails",
                            queryString: "",
                            body: info,
                            sucessFunction: (collection) => {
                                $(".connection-center").remove()
                                if (collection.hasOwnProperty('error')) {
                                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                                    $('.birdmessagebuttons').removeClass('show');
                                    $('.birdmessagebuttons').addClass('hide');
                                    $('.birdmessage-center').addClass("message-top-in");
                                    $('.details').removeClass('hide');
                                    $('.birdmessage h2').empty().append('Error : ');
                                    $('.birdmessage h2').append('<span class="errorcode">');
                                    $('.birdmessage .errorcode').empty().append(collection.errorCode);
                                    $('.birdmessage-info').empty().text(collection.error);
                                    $('.details').addClass('show');
                                    $('.messagedetails xmp').empty().append(collection.error)
                                    setTimeout(function () {
                                        $('.birderror').remove();
                                    }, 3000);
                                    $('.spinner').hide();
                                } else {
                                    $(".spinner").hide();
                                    $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                                    $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                                    $('.birdmessagebuttons').removeClass('show');
                                    $('.birdmessagebuttons').addClass('hide');
                                    $('.birdmessage-center').addClass("message-top-in");
                                    $('.details').removeClass('show');
                                    $('.details').addClass('hide');
                                    $('.birdmessage h2').empty().append('Success');
                                    $('.birdmessage-info').empty().text(getMessage('BirdSuccess41'));
                                    sessionStorage.setItem("changesdone", 0);
                                    $('.browselogfiles').removeClass('disabled');
                                    $(".connection-center").removeClass("connection-in");
                                    setTimeout(function () { $("body").removeClass("connection-open creating-new-connection"); }, 800);
                                   // if(this.state.connectionId!==undefined){
                                        this.state.connectionId=collection.connectionAccessId;
                                   // }
                                   this.datahub=new Datahub();
                                    this.datahub.loadConnections(this.state.connectionId);
                                    //that.broker.trigger("loadConnections",that.connectionId!=undefined?that.connectionId:collection.connectionAccessId)
                                }
                            },
                            rejectFunction: (error) => { },
                            headers: {
                                method: "post",
                                headers: new Headers({
                                    "Content-Type": "application/json"
                                })
                            }
                        });
                    }
                },
                rejectFunction: (error) => { },
                headers: {
                    method: "post",
                    headers: new Headers({
                        "Content-Type": "application/json"
                    })
                }
            });
        }
            if (this.isValid() == false) {
                $('.spinner').hide();
            }
        
    }
    addnewaddress = (e) => {
        e.preventDefault();
        var today = new Date();
        var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
        $('.appendnewaddress').append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
        ReactDom.render(
            <AddNewAddressHtml url="" port="" removeaddresskafka={this.removeaddresskafka} />, document.getElementById(dynamicdiv)
        );
    }
    removeaddresskafka = (e) => {
        e.preventDefault();
        var $this = e.currentTarget;
        $($this).parent().parent().parent().remove();
    }
    isValid = () => {
        var patt = new RegExp("^[a-zA-Z0-9\ \_$]+$");
        var result = true;
        var bool = false;
        var windowsAuth = false;
        windowsAuth = $(".windowsauth").is(":checked");
        this.databaseconnectiondetails = {};
        this.databaseconnectiondetails["dbType"] = this.state.schemaName;
        var result = true;
        if(this.state.schemaName.toLowerCase() == "servicenow"){
            var tablesType = "Standard";
            if($("#Custom").is(":checked")){
                tablesType= "Custom";
            }
            var customTableName="";
            customTableName=$(".tabname").val();
            this.databaseconnectiondetails["customTableName"] =customTableName;
            this.databaseconnectiondetails["tablesType"] =tablesType;
        }
        if (this.state.schemaName.toLowerCase() == "kafka") {
            $(".help-block").addClass("hide");
            this.databaseconnectiondetails["connectionname"] = $(".kafkaform").find(".connectionname").val();
            var servers = "";
            _.each($(".appendnewaddress .form-group"), function (html, index) {
                servers += $(html).find(".url").val() + ":" + $(html).find(".port").val() + ",";
                if ($(html).find(".url").val().length == 0) {
                    $(html).find("#url").closest(".form-group").addClass('has-error');

                    $(html).find("#url").removeClass("hide").text(getMessage("ValidationMessage1").replace("@replace", 'IP Address'));
                    result = false;
                } else {
                    $("#dbconnectionscontainer #url").closest(".form-group").removeClass('has-error');
                }
                if ($(html).find(".port").val().length == 0) {
                    $(html).find("#port").closest(".form-group").addClass('has-error');
                    $(html).find("#port").removeClass("hide").text(getMessage("ValidationMessage1").replace("@replace", 'Port'));
                    result = false;
                } else {
                    $("#dbconnectionscontainer #port").closest(".form-group").removeClass('has-error');
                }
            });
            servers = servers.substring(0, servers.length - 1);
            this.databaseconnectiondetails["servers"] = servers;
            this.databaseconnectiondetails["group"] = $(".kafkaform").find(".groupid").val();
            if (this.databaseconnectiondetails["group"].length == 0) {
                $("#dbconnectionscontainer #groupid").closest(".form-group").addClass('has-error');
                $(".kafkaform").find("#groupid").removeClass("hide").text(getMessage("ValidationMessage1").replace("@replace", 'Group ID'));
                result = false;
            } else {
                $("#dbconnectionscontainer #groupid").closest(".form-group").removeClass('has-error');
            }
            if ($(".kafkaform").find(".connectionname")===""|| /[^a-zA-Z0-9_ ]/.test( $(".connectionname").val())) {
                $("#dbconnectionscontainer #connectionname").closest(".form-group").addClass('has-error');
                $(".kafkaform").find("#connectionname").removeClass("hide").text(getMessage("ValidationMessage2").replace("@replace", 'Connection Name'));
                result = false;
            } else {
                $("#dbconnectionscontainer #connectionname").closest(".form-group").removeClass('has-error');
            }
        } else {
            _.each(this.state.connectiondetails,  (obj, index)=> {
                var value = $(".databaseconnectioncontainer ." + obj.id).val();
                this.databaseconnectiondetails[obj.id] = value;
                if( obj.id=="connectionname"){
                 if(value===""||/[^a-zA-Z0-9_ ]/.test( $(".connectionname").val())){
                    result = false;
                    $(".databaseconnectioncontainer #" + obj.id).closest(".form-group").addClass('has-error');
                    $(".databaseconnectioncontainer #" + obj.id).text(getMessage("ValidationMessage2").replace("@replace", obj.name)).removeClass("hide");
                    return
                }
            }

                if (obj.required) {
                    if (windowsAuth) {
                        if (obj.id == "dbuser" || obj.id == "dbPassword") {
                            bool = true;
                        }
                    }
                    if (!bool && value != undefined && value.length == 0) {
                        result = false;
                        $(".databaseconnectioncontainer #" + obj.id).closest(".form-group").addClass('has-error');
                        $(".databaseconnectioncontainer #" + obj.id).text(getMessage("ValidationMessage1").replace("@replace", obj.name)).removeClass("hide");
                    } else if (obj.pattern && !patt.test(value)) {
                        result = false;
                        $(".databaseconnectioncontainer #" + obj.id).closest(".form-group").addClass('has-error');
                        $(".databaseconnectioncontainer #" + obj.id).text(getMessage("ValidationMessage2").replace("@replace", obj.name)).removeClass("hide");
                    } else {
                        $(".databaseconnectioncontainer #" + obj.id).closest(".form-group").removeClass('has-error');
                        $(".databaseconnectioncontainer #" + obj.id).empty().addClass("hide");
                    }
                }
            });
        }
        return result;
    }
    testconnection = (e) => {
        e.preventDefault();
        if (this.isValid()) {
            $('.spinner').show();
            var connectionObj = this.databaseconnectiondetails;
            connectionObj["connectionaccessid"] = this.props.connectionId;
            connectionObj["command"] = "testConnection";
            var data=new FormData();
            data.append("connectionObject",JSON.stringify(connectionObj));
            SendRequest({
                url: "tables",
                queryString: "",
                body: data,
                sucessFunction: (response) => {
                    if (response.success === true) {
                        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Success');
                        $('.birdmessage-info').empty().text(getMessage('BirdSuccess41'));
                        $('.spinner').hide();
                    } else {
                        this.showDatabaseErrorMessage(response, true);
                    }
                },
                rejectFunction: (error) => { },
                headers: {
                    method: "post",
                    headers: new Headers({ "Content-Type": "application/json" })
                }
            });
        }
    }
    showDatabaseErrorMessage = (response, stepBack) => {
        $(".spinner").hide();
        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
        $('.birdmessagebuttons').removeClass('show');
        $('.birdmessagebuttons').addClass('hide');
        $('.birdmessage-center').addClass("message-top-in");
        $('.details').removeClass('hide');
        $('.birdmessage h2').empty().append('Error : ');
        $('.birdmessage h2').append('<span class="errorcode">');
        $('.birdmessage .errorcode').empty().append(response.errorCode);
        $('.birdmessage-info').empty().text(response.error);
        $('.details').addClass('show').text("Details");
        $('.messagedetails xmp').empty().append(response.errorTrace);
    }
   
    render() {
        return (
            <>
            <div className="closenewscopeconnection" id="closeconnectionpop" onClick={this.closeconnectionpop}><i className="fa fa-times"></i></div>
            <div className="datasourceconnection-container"></div>
            </>
        );
    }
}