import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'underscore';
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import SendRequest from '../../SendRequest';
import AutovizSettingsViewTemplate from './autovizsettings.jsx';
import AxisSettingView from './autovizattraxissettingview';
import YAxisAttrView from '../yaxisattributesview';

const $ = window.$;
export default class AutovizSettingsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.state.dynamicdiv = this.props.dynamicdiv;
        this.state.measures = this.props.measures;
        this.state.dimensions = this.props.dimensions;
        this.state.targetColumn = this.props.targetColumn;
        this.state.totalRecords = this.props.totalRecords;
        this.state.totalDBRecords = this.props.totalDBRecords;
        this.state.schemaType = this.props.schemaType;
        this.state.chartnumber = this.props.chartnumber;
        this.state.dataSource = this.props.dataSource;
        this.state.tabid = this.props.tabid;
        this.render();
    }
    render() {
        ReactDOM.render(<AutovizSettingsViewTemplate runsmartinsights={this.runsmartinsights} smartinsightoptions={this.props.smartinsightoptions} columnSize={this.state.dimensions.length} totalRecords={this.state.totalRecords} totalDBRecords={this.state.totalDBRecords} schemaType={this.state.schemaType.toUpperCase()} dataSource={this.state.dataSource != null && this.state.dataSource != '' ? this.state.dataSource.toUpperCase() : ""} />, document.getElementById(this.state.dynamicdiv));
        var today = new Date();
        var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
        $(".smartinsightaxissetting").append("<div className='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
        var axisContent = new AxisSettingView({
            dynamicdiv: dynamicdiv,
            measures: this.state.measures,
            dimensions: this.state.dimensions,
            targetColumn: this.state.targetColumn,
            chartnumber: this.state.chartnumber
        });
        $(".smartinsightaxissetting").html(document.getElementById(dynamicdiv));
        var columnDisplayName = this.props.targetColumn;
        sessionStorage.setItem('currentChartNumberForSort', '');
        var that = this;
        _.each(that.props.measures, function (value) {
            if (value.columnName == that.props.targetColumn) {
                columnDisplayName = value.columnDisplayName;
            }
        });

        var columnName = this.props.targetColumn;
        var datatype = "number";
        var aggregation = "";
        var tableName = this.props.targetColumn;
        var percentileValue = "";
        //	var data = JSON.parse(ChartInfo.getChartDetails(this.props.chartnumber));
        var activeChartType = $('.charttype.active').attr("id");
        if (this.props.targetColumn != undefined && this.props.targetColumn != '') {
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $(".smartyaxisattributes").append("<div className='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");

            var yAxisAttrView = new YAxisAttrView({
                dynamicdiv: dynamicdiv,
                yaxiscolumnDisplayName: columnDisplayName,
                yaxiscolumnName: columnName,
                yaxiscolumnType: datatype,
                yaxisaggregation: aggregation,
                yaxistableName: tableName,
                percentileValue: percentileValue,
                measures: this.state.measures,
                viewmode: 'smartvalues'
            });
            $(".smartyaxisattributes").html(document.getElementById(dynamicdiv));
        }
        return (<div></div>);
    }
    runsmartinsights = () => {
        var that = this;
        that.props.runAutoViz();
    }
}
