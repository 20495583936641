import React from 'react';
import jstz from 'jstz';
import ReactDOM from 'react-dom';
import _ from 'underscore';
import PubSub from 'pubsub-js';
import { getMessage } from '../js/helpers/utils/BirdMessage';
import SinglereportsavemodalTemplate from './singlereportsavemodal.jsx';
import * as collection from "../js/helpers/utils/SavedReportCollection";
import * as ChartInfo from '../js/helpers/utils/chartinfo';
import { lang } from "../js/helpers/utils/languages/language";
import SendRequest from '../SendRequest';
const $ = window.$;

export default class Singlereportsavemodal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
     //   $("#savereportgraph").remove();
        this.state.isFromStoryboard = this.props.isFromStoryboard!=undefined?this.props.isFromStoryboard:false;
        this.state.el="updatesinglereportdiv";
        if(this.state.isFromStoryboard){
        this.state.el="savesinglereport"+this.props.chartnumber;
        }
        this.state.reportId = this.props.reportId;
        this.state.datamodelId = this.props.datamodelId;
        this.state.reportcolumns = props.reportcolumns;
        this.state.schemaType = this.props.schemaType;
        this.state.reportname = this.props.reportname;
        this.state.reportfolder = this.props.reportfolder;
        this.state.viewreportCollection = this.props.viewreportcollection;
        this.state.columnsArray = this.props.columnsArray;
        this.state.reportdesc = this.props.reportdesc;
        this.state.details = this.props.details;
        this.state.isSyncEnable = true;
        this.state.isDirect = this.props.isDirect;
        this.state.isstoredprocedure = ChartInfo.functions.getIsStoredProcedure();
        this.state.exportSchemaId = "";
        if (this.state.maxage != "" && this.state.maxageunits != "") {
            this.state.checked = true;
        } else {
            this.state.checked = false;
        }
        if (sessionStorage.getItem("isImportedReport") || window.location.pathname.indexOf("importreport") >= 0) {
            this.state.reportId = 0;
        }
        if (this.state.schemaType == "pdf" || this.state.schemaType == "xls" || this.state.schemaType == "csv" || this.state.schemaType == "json"
            || this.state.schemaType == "eventhub" || this.state.schemaType == "kafka" || this.state.schemaType == "websocket"
            || this.state.schemaType == "log" || this.state.schemaType == 'rabbitmq' || this.state.schemaType == 'birdinmemory') {
            this.state.isSyncEnable = false;
        }
        this.state.syncType = "";
        this.state.syncVal = "";
        if (this.state.isSyncEnable == true) {
            if (this.state.details != undefined && sessionStorage.getItem('syncType' + this.props.chartnumber) == null) {
                this.state.syncType = this.props.details.syncType;
                this.state.syncVal = this.props.details.syncVal;
                this.state.syncTime = this.props.details.syncValuesObj;
            } else if (this.state.reportId != undefined) {
                this.state.syncType = sessionStorage.getItem('syncType' + this.props.chartnumber);
                this.state.syncVal = sessionStorage.getItem('syncVal' + this.props.chartnumber);
                this.state.syncTime = JSON.parse(sessionStorage.getItem('syncTime' + this.props.chartnumber));
            }
        }
        this.state.syncConfigData = this.props.syncConfigData;
        //this.collection = new SaveCollection();
        //this.collection.on('reportsaved',$.proxy(this.onreportsave, this));
        this.state.chartnumber = this.props.chartnumber;
        //this.broker = this.options.broker;
        //this.viewUsersFolderCollection=new ViewUsersFolderCollection();
        //this.viewUsersFolderCollection.fetch({reset: true})
        //this.template = _.template(SingleReportSaveModalTemplate);
        this.render();
        //this.listenTo(this.viewUsersFolderCollection, "reset",this.displayFolders);
    }
    render() {
        var that = this;
        var jsonArray = ChartInfo.functions.getAlerts();
        var auditMetaDataArray = ChartInfo.functions.getAuditMetaDataOptions()
        var chartData = ChartInfo.functions.getChartDetails(this.state.chartnumber);
        var yaxisArray = JSON.parse(chartData)["yAxis"];
        var count = 1;
        _.each(jsonArray, function (value, index) {
            var conditionsArray = jsonArray[0]["conditions"];
            for (var i = 0; i < yaxisArray.length; i++) {
                var temp = $.grep(conditionsArray, function (e) { return e.displayName == yaxisArray[i]["displayName"] && e.aggregation == yaxisArray[i]["aggregation"] })
                if (temp == undefined || temp.length == 0) {
                    count = 0
                    return 0
                }
            }
        });
        sessionStorage.setItem('syncTypeForHtml', this.state.syncVal);
        if (sessionStorage.getItem("isImportedReport") || window.location.pathname.indexOf("importreport") >= 0) {
            this.state.isImportedReport = true;
        } else {
            this.state.isImportedReport = false;
        }

        var columns = [];
        var istempreport = false;
        var fragmentArray = window.location.pathname.split("/");
        if (fragmentArray.length > 2) {/*
				columns = this.viewreportCollection.toJSON();
				if(this.reportId!= undefined){
					this.syncConfigData = syncDataObj;
					if(this.syncConfigData != undefined){
						var syncdata={};
						try{
						 syncdata = JSON.parse(this.syncConfigData);
						}catch(err){
							syncdata = this.syncConfigData;
						}
						if(syncdata.refreshType == "incrementalrefresh"){
							selectedDateFileds = syncdata["columnNameObjs"].split(",");
							istempreport =true;
						}
					}
				}
			*/}
        else {
            if (this.state.isImportedReport) {
                var that = this;
                var exportid = fragmentArray[1];
                // var getSchemaidCollection = new GetSchemaidCollection;
                SendRequest({
                    url: "getSchemaIdByExportId",
                    queryString: "?exportId=" + exportid,
                    body: "",
                    sucessFunction: (response) => {
                        this.state.exportSchemaId = response.schemaId
                    },
                    rejectFunction: () => { },
                    headers: {
                        method: "get",
                        headers: new Headers({
                            "Content-Type": "application/json"
                        })
                    }
                });
            }
            if (fragmentArray[0] != "customviewreport") {
                columns = this.state.reportcolumns;
                if (this.state.reportId == undefined && !this.state.isImportedReport) {
                    this.state.syncConfigData = JSON.parse(this.state.viewreportCollection.toJSON()[0]["reportdata"])[1].details.syncConfigData;
                    if (this.syncConfigData != undefined) {
                        var syncdata = {};
                        try {
                            syncdata = JSON.parse(this.state.syncConfigData);
                        } catch (err) {
                            syncdata = this.state.syncConfigData;
                        }

                        if (syncdata.refreshType == "incrementalrefresh") {
                            selectedDateFileds = syncdata["columnNameObjs"].split(",");
                            istempreport = true;
                        }
                    }
                } else {
                    istempreport = true;
                }
            }
            else {
                columns = this.state.viewreportCollection.toJSON();
            }
        }

        /*if(this.reportId== undefined){
            columns =this.viewreportCollection.toJSON();
        }else{
            columns = this.options.reportcolumns;
        }*/
        columns = $.grep(columns, function (e) { return e.customField != true });
        this.state.dateColums = $.grep(columns, function (e) { return e.tableDisplayType == "date" });
        this.state.numberColumns = $.grep(columns, function (e) { return e.isPartitionKey != "yes" && e.tableDisplayType == "number" });
        var purgedConfig = ChartInfo.functions.getPurgeConfig();
        var maxage = purgedConfig["maxage"];
        var timeZoneType = purgedConfig["timetimeZoneType"];
        var maxageunits = purgedConfig["maxageunits"];
        var maxpurgeindex = purgedConfig["maxpurgeindex"];
        var purgetime = purgedConfig["purgetime"];
        var checked = false;
        var localtimezone = new Date().toString().match(/([A-Z]+[\+-][0-9]+.*)/)[1].substr(0, 8) + "(" + jstz.determine().name() + ")";
        if (maxage != undefined && maxage != "") {
            checked = true;
        }
        var timeZoneType1 = undefined;

        if (this.state.details != undefined && this.state.details != "{}" && this.state.details.syncConfigData != undefined) {
            try {
                timeZoneType1 = JSON.parse(this.state.details.syncConfigData)["timeZoneType"];
            }
            catch (err) {
                timeZoneType1 = this.state.details.syncConfigData["timeZoneType"];
            }
        }
        var issynconfig = false;
        if (this.state.details != undefined && this.state.details["isclonedsyncconfig"] == true) {
            issynconfig = true;
        }
        if(this.state.reportfolder==="Default"){
            this.state.reportfolder="";
        }
        ReactDOM.render(
            <SinglereportsavemodalTemplate
                reportId={this.state.reportId}
                name={this.state.reportname}
                folder={this.state.reportfolder}
                reportdesc={this.state.reportdesc}
                syncType={this.state.syncType}
                syncVal={this.state.syncVal}
                isSyncEnable={this.state.isSyncEnable}
                dateColumns={this.state.dateColums.concat(this.state.numberColumns)}
                istempreport={istempreport}
                isimportreport={this.state.isImportedReport}
                schematype={this.state.schemaType}
                syncTime={this.state.syncTime}
                maxageunits={maxageunits}
                checked={checked}
                maxage={maxage}
                isDirect={this.state.isDirect}
                localtimezone={localtimezone}
                servertimezone={this.state.servertimezone}
                purgetime={purgetime}
                maxpurgeindex={maxpurgeindex}
                timeZoneType11={timeZoneType1}
                issynconfig={issynconfig}
                isstoredprocedure={this.state.isstoredprocedure}
                savereport={this.savereport}
                cancelmodal={this.cancelmodal}
            />,
            document.getElementById(this.state.el)
        );

        var selectedDateFileds = [];
        // var refreshType = "";
        // if (this.state.syncConfigData != undefined) {
        //     var syncdata = {};
        //     try {
        //         syncdata = JSON.parse(this.state.details.syncConfigData)
        //     }
        //     catch (err) {
        //         syncdata = this.state.details.syncConfigData;
        //     }

        //     if (syncdata.refreshType == "incrementalrefresh") {
        //         selectedDateFileds = syncdata["columnNameObjs"].split(",");
        //     }
        //     refreshType = syncdata["refreshType"];
        // }


        /*var that = this;
        setTimeout(function () {
            that.updateDateSyncConfiguration(selectedDateFileds, columns);
            if (refreshType == "selecteddata") {
                $("#savereportgraph .datasyncconfigtype[id=" + refreshType + "]").prop("checked", true);
                if (that.syncConfigData != undefined) {
                    var syncdata = "";
                    try {
                        syncdata = JSON.parse(that.syncConfigData)["columnNameObjs"];
                    } catch (err) {
                        syncdata = that.syncConfigData["columnNameObjs"];
                    }
                    if (ChartInfo.getDateRangeObj(that.options.chartnumber) != undefined) {
                        syncdata = ChartInfo.getDateRangeObj(that.options.chartnumber)["columnNameObjs"];
                    }
                    _.each(syncdata, function (value, index) {
                        $("#savereportgraph .datasyncconfigtype[id=selecteddata]").click();
                        require(["text!templates/viewreport/customsyncdaterange.html"], function (template) {
                            var template = _.template(template);
                            var dateRange = value.daterange.split("/");
                            $(".append_customsyncdatarangediv").append(template({ datecolumns: that.dateColums, id: index }));
                            $('.selectdatasyncdaterange[data-id=' + index + ']').daterangepicker({ "autoApply": true, startDate: dateRange[0], endDate: dateRange[1], locale: { format: "YYYY-MM-DD", "separator": '/' }, "parentEl": "#savereportgraph .filtercontent" });
                            $("#savereportgraph .customsyncdatarangediv[data-num=" + index + "] select option[tablename=" + value.tableName + "][columnname=" + value.columnName + "]").prop('selected', true)
                            $(".selectdatasyncdaterange[data-id=" + index + "]").val(value.daterange);
                        });
                    });
                }
            } else if (refreshType == "selectednumericrange") {
                $("#savereportgraph .datasyncconfigtype[id=" + refreshType + "]").prop("checked", true);
                if (that.syncConfigData != undefined) {
                    var syncdata = {};
                    try {
                        syncdata = JSON.parse(that.syncConfigData)["columnNameObjs"];
                    } catch (err) {
                        syncdata = that.syncConfigData["columnNameObjs"];
                    }
                    if (ChartInfo.getDateRangeObj(-1) != undefined) {
                        syncdata = ChartInfo.getDateRangeObj(-1)["columnNameObjs"];
                    }
                    _.each(syncdata, function (value, index) {
                        require(["text!templates/viewreport/customsyncnumericrange.html"], function (template) {
                            var template = _.template(template);
                            $("#savereportgraph .datasyncconfigtype[id=selectednumericrange]").click();
                            $(".append_customnumericsyncdatarangediv").append(template({ datecolumns: that.numberColumns, id: index, from: value.daterange.split("/")[0], to: value.daterange.split("/")[1] }));
                            $("#savereportgraph .customsyncnumericrangediv[data-num=" + index + "] select option[tablename='" + value.tableName + "'][columnname='" + value.columnName + "']").prop('selected', true)
                        });
                    });
                }
            } else if (refreshType != "") {
                $("#savereportgraph .alldatacontainer [id=" + refreshType + "]").prop("checked", true);
            }
            if (refreshType != "") {
                $(".datasyncconfiguration_singlereport#" + refreshType).click();
            }
            $("#maxageunits").trigger("click");
            if (that.options.isDirect == 1 && $('#foldername').val() == "") {
                $('#foldername').val("Direct Query");
            }
            if (refreshType == undefined && that.schemaType == "elasticsearch") {
                $("#savereportgraph .alldatacontainer [id='fullrefresh']").prop("checked", true);
            }
            				// $("#savereportgraph #servertimezone").prop("checked", true);
            var timeZoneValue = ChartInfo.getSingleReportSaveTimeZoneSettings(that.chartNumber);
            if (timeZoneValue == "localtimezone") {
                $('#localtimezone').prop("checked", true);
            } else if (timeZoneValue == "servertimezone") {
                $('#servertimezone').prop("checked", true);
            }
        }, 100);*/
        return <></>
    }
    isFloat = (n) => {
        return n % 1 !== 0;
    }
    savereport = (e) => {
        e.stopPropagation();
        var isSingleReport = $(".fullscreenmain").hasClass("singlereportview");
        if (isSingleReport) {
            sessionStorage.setItem("changesdone", 0);
        }
        var storyboardname = $('#'+this.state.el).find("#reportname").val().trim();
        var reservedwordslist = JSON.parse(sessionStorage.getItem("reservedwordslist"));
		if (storyboardname == undefined || storyboardname == "" || storyboardname.length == 0 || /[~`!@#$%^*+\=\[\]{};':\\|†.<>\/?"""`]/.test(storyboardname)) {
			$("#myreportname-help-block").html(lang["reports.validname"])
			return;
		} else if (storyboardname.length > 70) {
			$("#myreportname-help-block").html(lang["reports.reportnameexceeds"])
			return;
		} else if (reservedwordslist!=undefined && reservedwordslist.indexOf(storyboardname.toLowerCase()) != -1) {
			$("#myreportname-help-block").html(lang["reports.noreservewords"])
			return;
		} else {
			$("#myreportname-help-block").html('')
		}
        var storyboardfolder = $('#'+this.state.el).find("#reportFolder").val();
        var storyboarddesc = $('#'+this.state.el).find("#description").val();
        var timeZoneType = $("#savereportgraph .timezonedatatoggle:checked").attr("synctype");
        var syncValueObj = {};
        var isSyncValid = true;
        var maxage = $('#'+this.state.el).find("#maxage").val();
        var maxageunits = $('#'+this.state.el).find("#maxageunits option:selected").val();
        var purgetime = $(".purgetime").find("input").val();
        var maxpurgeindex = $('#'+this.state.el).find("#maxpurgeindex").val();
        var isChecked = $('#'+this.state.el).find("#maxDataCheck").is(':checked');
        if(storyboardfolder===""){
			storyboardfolder = "Default"
		}
        var singlereportChartType;
        if (timeZoneType != undefined) {
            ChartInfo.functions.setSingleReportSaveTimeZoneSettings(this.state.chartNumber, timeZoneType);
        }
        var timeZone = "";
        var TimeZoneSettingsValueForstoryboard = ChartInfo.functions.getSingleReportSaveTimeZoneSettings(this.state.chartNumber);
        if (TimeZoneSettingsValueForstoryboard == "localtimezone") {
            timeZone = jstz.determine().name();
        }
        if (isChecked && (maxage == "" || maxage < 1 || this.isFloat(maxage))) {
            $('.maxage').text(lang["reports.maxageerror"]);
            $('.maxage').removeClass("hide");
            isSyncValid = false;
        } else {
            $('.maxage').addClass("hide");
        }

        if (isChecked && (maxpurgeindex == "" || maxpurgeindex < 1 || this.isFloat(maxpurgeindex))) {
            $('.maxpurgeindex').text(lang["reports.purgeindices"]);
            $('.maxpurgeindex').removeClass("hide");
            isSyncValid = false;
        } else {
            $('.maxpurgeindex').addClass("hide");
        }

        if (isChecked && maxageunits == undefined) {
            $('.maxageunits').text(lang["reports.maxunits"]);
            $('.maxageunits').removeClass("hide");
            isSyncValid = false;
        } else {
            $('.maxageunits').addClass("hide");
        }


        if (this.state.isSyncEnable == true) {
            var dataSyncType = $('#'+this.state.el).find('.datasynctype:checked').attr("id");
            if (dataSyncType == 'defaultcontainer') {
                this.state.syncType = "default";
                this.state.syncVal = $('#'+this.state.el).find('.defaultcontainer input').val();
            } else {
                this.state.syncType = $('#'+this.state.el).find('.syncreport').find("option:selected").val();
                this.state.syncVal = $('#'+this.state.el).find('.' + this.syncType + '').find('input').val();
            }
            if (this.state.syncType == "") {
                this.state.syncType = "default";
            }
            if (this.state.syncVal == "") {
                this.state.syncVal = 30;
            }
            if (this.state.syncType == 'minutes') {
                if (this.state.syncVal < 1) {
                    $('.syncerror').text(lang["reports.minminutes"]);
                    $('.syncerror').removeClass("hide");
                    isSyncValid = false;
                } else if (this.state.syncVal > 59) {
                    $('.syncerror').text(lang["reports.maxminutes"] );
                    $('.syncerror').removeClass("hide");
                    isSyncValid = false;
                } else {
                    $('.syncerror').addClass("hide");
                }
            } else if (this.state.syncType == 'hours') {
                if (this.state.syncVal < 1) {
                    $('.syncerror').text(lang["reports.minhr"]);
                    $('.syncerror').removeClass("hide");
                    isSyncValid = false;
                } else if (this.state.syncVal > 23) {
                    $('.syncerror').text(lang["reports.maxhr"]);
                    $('.syncerror').removeClass("hide");
                    isSyncValid = false;
                } else {
                    $('.syncerror').addClass("hide");
                }
            } else if (this.state.syncType == 'days') {
                if (this.state.syncVal < 1) {
                    $('.syncerror').text(lang["reports.minday"]);
                    $('.syncerror').removeClass("hide");
                    isSyncValid = false;
                } else if (this.state.syncVal > 31) {
                    $('.syncerror').text(lang["reports.maxday"]);
                    $('.syncerror').removeClass("hide");
                    isSyncValid = false;
                } else {
                    var timeSplit = $(".sync-day-time-single input").val();
                    if (timeSplit == undefined || timeSplit.length <= 0) {
                        timeSplit = "00:00";
                    }
                    syncValueObj["time"] = timeSplit;
                    $('.syncerror').addClass("hide");
                }
            } else if (this.state.syncType == 'seconds') {
                if (this.state.syncVal < 5) {
                    $('.syncerror').text(lang["reports.minsec"]);
                    $('.syncerror').removeClass("hide");
                    isSyncValid = false;
                } else if (this.state.syncVal > 59) {
                    $('.syncerror').text(lang["reports.maxsec"]);
                    $('.syncerror').removeClass("hide");
                    isSyncValid = false;
                } else {
                    $('.syncerror').addClass("hide");
                }
            }
        }
        sessionStorage.setItem('syncType' + this.state.chartnumber, this.state.syncType);
        sessionStorage.setItem('syncVal' + this.state.chartnumber, this.state.syncVal);
        sessionStorage.setItem('syncTime' + this.state.chartnumber, JSON.stringify(syncValueObj));
        var model = new FormData();
        model.set('storyboardname', storyboardname);
        model.set('singlereporttimezone', timeZone);
        model.set('storyboardfolder', storyboardfolder);
        model.set('storyboarddesc', storyboarddesc);
        model.set('syncType', this.state.syncType);
        model.set('syncVal', this.state.syncVal);
        model.set('maxage', maxage);
        model.set('maxageunits', maxageunits);
        model.set('purgetime', this.state.purgetime);
        model.set('maxpurgeindex', this.state.maxpurgeindex);
        if (true/*this.options.model.isValid(true) && isSyncValid==true*/) {
            //				$("#savereportgraph").modal("hide");
            var reporttitle = $(".boc-viewreport-title[data-num=" + this.state.chartnumber + "]").text();
            //			Sync Configuration	
            var syncConfigData = {};
            if ($("#savereportgraph .datasyncconfigtype:checked").attr("id") == "alldata") {
                if ($("#savereportgraph .alldatacontainer").find("input[type=radio]:checked").attr("id") == "incrementalrefresh") {
                    var str = "";
                    _.each($("#savereportgraph .alldatacontainer .incrementalrefreshcontainer #incrementalrefreshfield option:checked"), function (value, index) {
                        str += $(value).attr("columnname") + ",";
                    });
                    str = str.substring(0, str.length - 1);
                    if (str.length == 0) {
                        $(".incrementalsync_error").removeClass("hidden");
                        return;
                    }

                    if (this.state.schemaType == "cassandra" && str.split(",").length > 1) {
                        $(".incrementalsync_multiple_column_error").removeClass("hidden");
                        return;
                    }

                    $("#savereportgraph").modal("hide");
                    syncConfigData["columnNameObjs"] = str;
                    syncConfigData["refreshType"] = "incrementalrefresh";
                } else {	//Full Sync
                    syncConfigData["refreshType"] = "fullrefresh";
                }
            } else if ($("#savereportgraph .datasyncconfigtype:checked").attr("id") == "selectednumericrange") {
                var obj = []
                var stopRequest = false;
                _.each($("#savereportgraph .customsyncnumericrangediv"), function (i, j) {
                    var array = {};
                    array["columnName"] = $(i).find("select option:selected").attr("columnname");
                    array["tableName"] = $(i).find("select option:selected").attr("tablename");
                    array["daterange"] = $(i).find(".numericsyncrangefrom").val() + "/" + $(i).find(".numericsyncrangeto").val();
                    obj.push(array);
                    if ($(i).find(".numericsyncrangefrom").val().length == 0) {
                        $(i).find(".numericsyncrangefrom").addClass('bird-has-error');
                        stopRequest = true;
                    } else {
                        $(i).find(".numericsyncrangefrom").removeClass('bird-has-error');
                    }
                    if ($(i).find(".numericsyncrangeto").val().length == 0) {
                        $(i).find(".numericsyncrangeto").addClass('bird-has-error');
                        stopRequest = true;
                    } else {
                        $(i).find(".numericsyncrangeto").removeClass('bird-has-error');
                    }
                });
                if (stopRequest) {
                    return;
                }
                if (obj.length == 0) {
                    $(".numericdatasync_error").removeClass("hidden");
                    return;
                }

                if (this.state.schemaType == "cassandra" && obj.length > 1) {
                    $(".numericdatasync_multiple_column_error").removeClass("hidden");
                    return;
                }

                $(".numericdatasync_error").addClass("hidden");
                syncConfigData["columnNameObjs"] = obj;
                syncConfigData["refreshType"] = "selectednumericrange";

            } else if ($("#savereportgraph .datasyncconfigtype:checked").attr("id") != undefined) {
                var obj = []
                _.each($("#savereportgraph .customsyncdatarangediv"), function (i, j) {
                    var array = {};
                    array["columnName"] = $(i).find("select option:selected").attr("columnname");
                    array["tableName"] = $(i).find("select option:selected").attr("tablename");
                    array["daterange"] = $(i).find(".selectdatasyncdaterange").val();
                    obj.push(array);
                });
                if (obj.length == 0) {
                    $(".selectdatasync_error").removeClass("hidden");
                    return;
                }

                var hascolumn = false;
                $.each(obj, function (i, j) {
                    if (j.columnName == undefined) {
                        hascolumn = true;
                        return;
                    }
                });
                if (hascolumn) {
                    $(".selectdatasync_error").removeClass("hidden");
                    return;
                }

                if (this.state.schemaType == "cassandra" && obj.length > 1) {
                    $(".selectdatasync_multiple_column_error").removeClass("hidden");
                    return;
                }

                syncConfigData["columnNameObjs"] = obj;
                syncConfigData["refreshType"] = "selecteddata";
            }
            syncConfigData["timeZoneType"] = timeZoneType;
            $(".spinner").show();
            var syncDataObj = JSON.stringify(syncConfigData);
            ChartInfo.functions.setDateRangeObj(this.state.chartnumber, syncConfigData);
            var reportDataArray = [];
            var reportdetails = { "name": storyboardname, "folder": storyboardfolder, "desc": storyboarddesc, "title": reporttitle, "syncType": this.state.syncType, "syncVal": this.state.syncVal, syncValuesObj: syncValueObj, "syncConfigData": JSON.stringify(syncConfigData), "maxage": maxage, "maxageunits": maxageunits, "purgetime": purgetime, "maxpurgeindex": maxpurgeindex, "singlereporttimezone": timeZone, isclonedsyncconfig: true };
            ChartInfo.functions.setPurgeConfig(maxage, maxageunits, purgetime, maxpurgeindex);
            var axisDataArray = [];
            var chartInfoDetails = ChartInfo.functions.getAllChartDetails();
            var that = this;
            _.each(chartInfoDetails, function (i, j) {
                if (j === that.state.chartnumber) {
                    var storedObject = chartInfoDetails[j];
                    var storedParsedObject = JSON.parse(storedObject);
                    var xAxisData = storedParsedObject["xAxis"];
                    var legend = storedParsedObject["legend"];
                    var yAxisData = storedParsedObject["yAxis"];
                    var tooltipData = storedParsedObject["tooltip"];
                    var chartType = storedParsedObject["chartType"];
                    var colLimit = storedParsedObject["colLimit"];
                    var orderType = storedParsedObject["orderType"];
                    var sortType = storedParsedObject["sortType"];
                    var sortingColumn = storedParsedObject["sortingColumn"];
                    var sortingColumnAggregation = storedParsedObject["sortingColumnAggregation"];
                    var rankingColumn = storedParsedObject["rankingColumn"];
                    var rankingColumnAggregation = storedParsedObject["rankingColumnAggregation"];
                    var dobyselectedcolumns = storedParsedObject["dobyselectedcolumns"];
                    var dobyrowlimit = storedParsedObject["dobyrowlimit"];
                    var metricdatetype = storedParsedObject["metricdatetype"];
                    var metricdatefield = storedParsedObject["metricdatefield"];
                    var metricdatefielddisplay = storedParsedObject["metricdatefielddisplay"];
                    var metricprefix = storedParsedObject["metricprefix"];
                    var metricsuffix = storedParsedObject["metricsuffix"];
                    var metricfont = storedParsedObject["metricfont"];
                    var solidgaugemin = storedParsedObject["solidgaugemin"];
                    var solidgaugemax = storedParsedObject["solidgaugemax"];
                    var pivotcolumnvalues = storedParsedObject["columns"];
                    var pivotrowvalues = storedParsedObject["rows"];
                    var pivotvalues = storedParsedObject["values"];
                    var richtextcontent = storedParsedObject["richtextcontent"];
                    var colorThemeIndex = storedParsedObject["colorThemeIndex"];
                    var reportcolortheme = storedParsedObject["reportcolortheme"];
                    var chartSettings = storedParsedObject["chartSettings"];
                    var percentileValue = storedParsedObject["percentileValue"];
                    var zAxis = storedParsedObject["zAxis"];
                    var predictionChartDetails = storedParsedObject["predictionArray"];
                    var customSolidGauge = storedParsedObject["customSolidGauge"];
                    var summarytablecolumns = storedParsedObject['summarytablecolumns'];
                    var summarytablegroupby = storedParsedObject['summarytablegroupbys'];
                    var summarytablevalues = storedParsedObject['summarytablevalues'];
                    var summarytableaggregationColumns = storedParsedObject['summarytableaggregationColumns'];
                    var summarytablegroupDataTypes = storedParsedObject['summarytablegroupDataTypes'];
                    var combinationchartslist = storedParsedObject["combinationchartslist"];
                    var compareDateRangeObj = storedParsedObject["compareDateRangeObj"];
                    var reference = storedParsedObject["reference"]
                    var trendLine = storedParsedObject["trendLine"]
                    var periodsvalue = storedParsedObject["periodsvalue"];
                    var searchText = storedParsedObject["searchText"];
                    var nlpfiltercols = storedParsedObject["nlpfiltercols"]; 
                    var nlpFilters = storedParsedObject["nlpFilter"];
                    var nlprowlimit = storedParsedObject["nlprowlimit"];
                    var nlptableordertype = storedParsedObject["nlptableordertype"]
                    var shareFilterDrillDown = ChartInfo.functions.getSharedFilterObjectArray(j);
                    var legendfilterall = [];
                    if (storedParsedObject["legendfilterall"] != undefined && storedParsedObject["legendfilterall"].length != 0) {
                        legendfilterall = storedParsedObject["legendfilterall"];
                    }
                    var legendfilter = [];
                    if (storedParsedObject["legendfilter"] != undefined && storedParsedObject["legendfilter"].length != 0) {
                        legendfilter = storedParsedObject["legendfilter"];
                    }
                    if (ChartInfo.functions.getLegendFilterobj(j) != undefined && ChartInfo.functions.getLegendFilterobj(j).length != 0) {
                        legendfilter = ChartInfo.functions.getLegendFilterobj(j);
                    }
                    if (ChartInfo.functions.getLegendFilterobjForAllCharts() != undefined && ChartInfo.functions.getLegendFilterobjForAllCharts().length != 0) {
                        legendfilterall = ChartInfo.functions.getLegendFilterobjForAllCharts();
                    }
                    if (legendfilterall != undefined && legendfilterall.length != 0) {
                        legendfilter = JSON.parse(JSON.stringify(legendfilter)).concat(legendfilterall);
                    }
                    var savedDrillDown = [];
                    if (pivotcolumnvalues == undefined && pivotrowvalues == undefined && pivotvalues == undefined && chartType == "pivot") {
                        singlereportChartType = chartType;
                        return false;
                    }
                    if (summarytablecolumns == undefined && summarytablegroupby == undefined && summarytablevalues == undefined && chartType == "summarytable") {
                        singlereportChartType = chartType;
                        return false;
                    }
                    if (storedParsedObject["drillDownAllFilter"] != undefined) {
                        _.each(storedParsedObject["drillDownAllFilter"], function (value, i) {
                            savedDrillDown[i] = value;
                            savedDrillDown[i]["isDrillDownAll"] = false;
                        });
                    }
                    if (storedParsedObject['drillDownFilter'] != undefined) {
                        savedDrillDown = savedDrillDown.concat(storedParsedObject['drillDownFilter']);
                    }
                    //var drillDown= undefined;			    
                    var drillDown = drillDown = JSON.parse(sessionStorage.getItem("drilldown" + j));
                    var drillDownFilter = undefined;
                    if (drillDown != null) {
                        _.each(drillDown, function (value) {
                            drillDownFilter = JSON.parse(sessionStorage.getItem("drilldown" + j));
                            drillDownFilter["isDrillDownAll"] = false;
                        });
                    }
                    if (savedDrillDown != undefined && drillDownFilter != undefined) {
                        drillDownFilter = savedDrillDown.concat(drillDownFilter);
                    }
                    else if (savedDrillDown != undefined) {
                        drillDownFilter = savedDrillDown;
                    }
                    if (shareFilterDrillDown != undefined) {
                        drillDownFilter.concate(shareFilterDrillDown);
                    }
                    var datehistogramstate = ChartInfo.functions.getDateHistogramState(j);
                    if (datehistogramstate == undefined) {
                        datehistogramstate = storedParsedObject['datehistogramstate']
                    }
                    var summaryTableDateHistogramState = ChartInfo.functions.getSummaryTableDateHistogramState(j)
                    var xaxisDrilldownState = ChartInfo.functions.getXaxisDetails(j);
                    if (xaxisDrilldownState != undefined && drillDownFilter != undefined && drillDownFilter.length != 0) {
                        if ((chartType == "countrymap" || chartType == "indiamap") && (xaxisDrilldownState['columnName'] != xAxisData[0].columnName || xAxisData.length > 1)) {
                            chartType = "pie";
                        } else if ((chartType == "world" || chartType == "srilankamap" || chartType == "leaflet") &&
                            (xaxisDrilldownState['columnName'] != xAxisData[0].columnName || xAxisData.length > 1)) {
                            chartType = "bar";
                        }
                    }

                    if (xaxisDrilldownState != undefined && drillDownFilter != undefined && drillDownFilter.length != 0 && xaxisDrilldownState.columnName != "Measure Names") {
                        xAxisData[0].columnName = xaxisDrilldownState['columnName'];
                        xAxisData[0].dataType = xaxisDrilldownState['dataType'];
                        xAxisData[0].displayName = xaxisDrilldownState['displayname'];
                        if (xAxisData[1] != undefined && xAxisData[1].xaxisidentity != undefined) {
                            xAxisData = xAxisData[0];
                        }
                    }
                    var summarytabledrilldownfilters = sessionStorage.getItem('customhierarchy' + j)
                    var getSummarytableAxisFieldsArray = ChartInfo.functions.getSummarytableAxisFieldsArray(j);
                    var tempSummaryTableFilterValues = ChartInfo.functions.getTempSummaryTableFilterValues(j);
                    if (chartType == "summarytable") {
                        if (tempSummaryTableFilterValues != undefined && tempSummaryTableFilterValues.length != 0 && tempSummaryTableFilterValues.columnname != "Measure Names"
                            && getSummarytableAxisFieldsArray != undefined && getSummarytableAxisFieldsArray.length > 0) {
                            summarytablecolumns = getSummarytableAxisFieldsArray[getSummarytableAxisFieldsArray.length - 1].textcolumns;
                            summarytablegroupby = getSummarytableAxisFieldsArray[getSummarytableAxisFieldsArray.length - 1].group;
                        }
                    }
                    var cfFilter = [];
                    _.each(drillDownFilter, function (drillDownData) {
                        if (ChartInfo.functions.getCustomFieldsFilterObject(j) != undefined && ChartInfo.functions.getCustomFieldsFilterObject(j).length > 0) {
                            _.each(ChartInfo.functions.getCustomFieldsFilterObject(j), function (filter) {
                                if (filter.customfield != undefined && (filter.customfield.name == drillDownData.columnname)) {
                                    cfFilter.push(filter);
                                }
                            })
                        }
                    });
                    var customfieldsDrillDownObject = cfFilter;

                    var xaxisDataArray = [];
                    var jsonObject = {};
                    if (!Array.isArray(xAxisData)) {
                        xaxisDataArray.push(xAxisData);
                        jsonObject["xaxis"] = xaxisDataArray;
                    } else {
                        jsonObject["xaxis"] = xAxisData;
                    }
                    jsonObject["yaxis"] = yAxisData;
                    jsonObject["tooltip"] = tooltipData;
                    jsonObject["legend"] = legend;
                    jsonObject["chartType"] = chartType;
                    jsonObject["chartnumber"] = j;
                    jsonObject["colLimit"] = colLimit;
                    jsonObject["orderType"] = orderType;
                    jsonObject["sortType"] = sortType;
                    jsonObject["sortingColumn"] = sortingColumn;
                    jsonObject["sortingColumnAggregation"] = sortingColumnAggregation;
                    jsonObject["rankingColumn"] = rankingColumn;
                    jsonObject["rankingColumnAggregation"] = rankingColumnAggregation;
                    jsonObject["dobyselectedcolumns"] = dobyselectedcolumns
                    jsonObject["dobyrowlimit"] = dobyrowlimit;
                    jsonObject["metricdatetype"] = metricdatetype;
                    jsonObject["metricdatefield"] = metricdatefield;
                    jsonObject["metricdatefielddisplay"] = metricdatefielddisplay;
                    jsonObject["metricprefix"] = metricprefix;
                    jsonObject["metricsuffix"] = metricsuffix;
                    jsonObject["metricfont"] = metricfont;
                    jsonObject["solidgaugemin"] = solidgaugemin;
                    jsonObject["solidgaugemax"] = solidgaugemax;
                    jsonObject["pivotrowvalues"] = pivotrowvalues;
                    jsonObject["pivotcolumnvalues"] = pivotcolumnvalues;
                    jsonObject["pivotvalues"] = pivotvalues;
                    jsonObject["richtextcontent"] = richtextcontent;
                    jsonObject["colorThemeIndex"] = colorThemeIndex;
                    jsonObject["reportcolortheme"] = reportcolortheme;
                    jsonObject["chartSettings"] = chartSettings;
                    jsonObject["percentileValue"] = percentileValue;
                    jsonObject["zaxis"] = zAxis;
                    jsonObject["predictionArray"] = predictionChartDetails;
                    jsonObject["customSolidGauge"] = customSolidGauge;
                    jsonObject['summarytablecolumns'] = summarytablecolumns;
                    jsonObject['summarytablegroupbys'] = summarytablegroupby;
                    jsonObject['summarytablevalues'] = summarytablevalues;
                    jsonObject['summarytableaggregationColumns'] = summarytableaggregationColumns;
                    jsonObject['summarytablegroupDataTypes'] = summarytablegroupDataTypes;
                    jsonObject['alertArray'] = ChartInfo.functions.getAlerts();
                    jsonObject["combinationchartslist"] = combinationchartslist;
                    jsonObject["compareDateRangeObj"] = compareDateRangeObj;
                    jsonObject["reference"] = ChartInfo.functions.getreferenceLineValues(j);
                    jsonObject["trendLine"] = trendLine;
                    jsonObject["periodsvalue"] = periodsvalue;
                    jsonObject["drillDownFilter"] = drillDownFilter;
                    jsonObject["summarytabledrilldownname"] = ChartInfo.functions.getSummarytabledrilldownname();
                    jsonObject["summarytabledrilldowndisplayname"] = ChartInfo.functions.getSummarytabledrilldownname();
                    jsonObject["summarytabledrilldowndatatype"] = ChartInfo.functions.getSummarytabledrilldowndatatype();
                    jsonObject["customfieldFilterObject"] = customfieldsDrillDownObject;
                    jsonObject["customseriescolors"] = ChartInfo.functions.getCustomSeriesColorsDetails(j);
                    jsonObject["summarytableFilterObject"] = ChartInfo.functions.getTempSummaryTableFilterValues(j);
                    jsonObject["datehistogramstate"] = datehistogramstate;
                    jsonObject["summaryTableDateHistogramState"] = summaryTableDateHistogramState;
                    if (summarytabledrilldownfilters != undefined && summarytabledrilldownfilters != null && summarytabledrilldownfilters != "undefined" && summarytabledrilldownfilters.length > 0) {
                        jsonObject["summarytabledrilldownstates"] = summarytabledrilldownfilters;
                    }
                    if (getSummarytableAxisFieldsArray != undefined && getSummarytableAxisFieldsArray != null && getSummarytableAxisFieldsArray != "undefined" && getSummarytableAxisFieldsArray.length > 0) {
                        jsonObject["getsummarytableaxisfieldsarray"] = ChartInfo.functions.getSummarytableAxisFieldsArray(j);
                    }
                    jsonObject['tempSummaryTableFilterValues'] = tempSummaryTableFilterValues;
                    jsonObject["conditionalFormateData"] = ChartInfo.functions.getConditionalFormatData(j);
                    jsonObject["legendfilter"] = legendfilter;
                    jsonObject["searchText"] = searchText;
                    jsonObject["nlpfiltercols"] = nlpfiltercols!=undefined?nlpfiltercols:"";
                    jsonObject["nlpFilter"] = nlpFilters!=undefined?nlpFilters:"";
                    jsonObject["nlprowlimit"] = nlprowlimit!=undefined?nlprowlimit:"";
                    jsonObject["nlptableordertype"] = nlptableordertype!=undefined?nlptableordertype:"";
                    axisDataArray.push(jsonObject);
                }
            });
            if (axisDataArray.length == 0 && ((singlereportChartType != undefined && singlereportChartType == "pivot") || (singlereportChartType != undefined && singlereportChartType == "summarytable"))) {
                $(".spinner").hide();
                $(".gridspinner").hide();
                $(".gridspinner").hide();
                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Warning');
                $('.birdmessage-info').empty().text(getMessage('BirdWarning111').replace('@replace', singlereportChartType == "summarytable" ? "summary" : singlereportChartType));
                return false;
            }
            var axisDataObject = {};
            axisDataObject["axis"] = axisDataArray;
            reportDataArray.push(axisDataObject);
            var detailsObject = {};
            detailsObject["details"] = reportdetails;
            reportDataArray.push(detailsObject);
            var filterArray = [];
            var filterObject;
            var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(this.state.chartnumber));
            if (chartDetails["filtersadded"] != true && chartDetails["filtersadded"] != undefined) {
                filterArray = collection.getFilterObject(this.state.chartnumber, this.state.viewreportmodel);
            }
            else {
                filterArray = ChartInfo.functions.getFilters(false,"save");
            }
            var renameMeasuresorDimensions = sessionStorage.getItem('renameMeasuresorDimensions');
            var parsedData = JSON.parse(renameMeasuresorDimensions);
            if (filterArray != undefined && parsedData != null && parsedData != undefined) {
                _.each(filterArray, function (obj) {
                    if (parsedData[obj.columnname] != undefined) {
                        obj['columndisplayname'] = parsedData[obj.columnname];
                    }
                });
            }
            filterObject = { "filter": filterArray };
            reportDataArray.push(filterObject);
            var gridStackObject = { "grid": "" };
            reportDataArray.push(gridStackObject);
            var convertedValues = sessionStorage.getItem("convertedvalues");
            var convertedObject = { "convertedvalues": convertedValues };
            reportDataArray.push(convertedObject);
            var customFields = sessionStorage.getItem("customFields");
            var customFieldsObject = { "customFields": customFields };
            reportDataArray.push(customFieldsObject);
            var customFieldsScript = sessionStorage.customFields;
            var customFiltersScript = sessionStorage.newreportCustomFilters;
            var drillThroughReports = ChartInfo.functions.getDrillThroughReports();
            var filterFields = sessionStorage.getItem('applicablefilters');
            var filterValues = { "filterValues": filterFields };
            reportDataArray.push(filterValues);
            var customHierarchy = JSON.stringify(ChartInfo.functions.getcustomhierarchy());
            var customHierarchyObject = { "customHierarchy": customHierarchy }
            reportDataArray.push(customHierarchyObject);
            var customRange = JSON.stringify(ChartInfo.functions.getRange());
            var customRangeObject = { "customRange": customRange };
            reportDataArray.push(customRangeObject);
            var customMeasure = JSON.stringify(ChartInfo.functions.getCustomMeasureField());
            var customMeasureObject = { "customMeasure": customMeasure };
            reportDataArray.push(customMeasureObject);
            var customParameter = JSON.stringify(ChartInfo.functions.getCustomParameter());
            var customParameter = { "customParameter": customParameter };
            reportDataArray.push(customParameter);
            var customParameterFilterArray = ChartInfo.functions.getCustomParametersFilters();
            var customParameterfilterObject = { "customParameterFilters": customParameterFilterArray };
            reportDataArray.push(customParameterfilterObject);
            reportDataArray.push({ "customMeasureHierarchy": JSON.stringify(ChartInfo.functions.getMeasureHierarchy()) });
            var isExcludeNulls = ChartInfo.functions.getIsExcludeNulls();
            reportDataArray.push({ "isExcludeNulls": isExcludeNulls });
            var livedatasyncconfig = {};
            var livedataconfigObj = ChartInfo.functions.getliveDataconfig();
            if (livedataconfigObj[this.state.chartnumber] != undefined) {
                livedatasyncconfig[this.state.chartnumber] = livedataconfigObj[this.state.chartnumber];
            }
            reportDataArray.push({ "livedatasyncconfig": JSON.stringify(livedatasyncconfig) });
            var timeZoneSettings = JSON.stringify(ChartInfo.functions.getListofTimeZoneSettings());
            reportDataArray.push({ "timeZoneSettings": timeZoneSettings });
            var auditMetaDataOptions = JSON.stringify(ChartInfo.functions.getAuditMetaDataOptions());
            reportDataArray.push({ "auditMetaDataOptions": auditMetaDataOptions });

            var isFilterCollapsed = ($("body").hasClass("filters-hidden"));
            reportDataArray.push({ "isFilterCollapsed": isFilterCollapsed });

            if (this.state.reportId == undefined || this.state.reportId === 0) {
                var reportcolumns;
                var finalReportcolumns;
                if (this.state.isImportedReport) {
                    if (this.state.reportcolumns != undefined) {
                        reportcolumns = JSON.stringify(this.state.reportcolumns);
                    }
                } else {
                    var renameMeasuresorDimensions = sessionStorage.getItem('renameMeasuresorDimensions');

                    if (this.state.viewreportCollection != undefined) {
                        // if (this.state.viewreportCollection.isfavorite == undefined) {
                        //     reportcolumns = JSON.stringify(this.state.viewreportCollection);
                        // } else {
                        //     reportcolumns = this.state.viewreportCollection.toJSON()[0].reportcolumns
                        // }
                        reportcolumns=this.state.viewreportCollection.getColumns();
                        finalReportcolumns = JSON.parse(reportcolumns);
                        $.each(this.state.viewreportCollection.getMeasures(), function (index, measure) {
                            $.each(finalReportcolumns, function (index, column) {
                                if (measure.columnName == column["columnName"]) {
                                    column["aggregation"] = measure.aggregation;
                                }
                            });
                        });
                    } else {
                        finalReportcolumns = this.state.reportcolumns;
                    }
                    if (this.state.isImportedReport) {
                        finalReportcolumns = JSON.parse(finalReportcolumns[0].reportcolumns);
                    }
                    if (renameMeasuresorDimensions != null && renameMeasuresorDimensions != "null") {
                        var parsedData = JSON.parse(renameMeasuresorDimensions);
                        if (finalReportcolumns != undefined) {
                            _.each(finalReportcolumns, function (obj) {
                                if (parsedData[obj.columnName] != undefined) {
                                    obj['columnDisplayName'] = parsedData[obj.columnName];
                                    obj['columnssrename'] = "yes";
                                }
                            });
                        }
                    }

                    _.each(finalReportcolumns, function (column, i) {
                        var isPriorityColumn = false;
                        _.each(ChartInfo.functions.getPriorityColumns(), function (priorityObj, j) {
                            if (priorityObj.columnName == column.columnName) {
                                isPriorityColumn = priorityObj.priorityColumn;
                            }
                        });
                        column["priorityColumn"] = isPriorityColumn;
                    });
                    reportcolumns = JSON.stringify(finalReportcolumns);
                }
                /*var usercustomScript = ChartInfo.getUserCustomScript(this.reportId);
                reportDataArray.push({"usercustomScript": usercustomScript});*/
                var isSavedShareReport = false;
                if (this.state.details != undefined && this.state.details["issharedreportsaved"] != undefined && this.state.details["issharedreportsaved"] == true) {
                    isSavedShareReport = true
                }
                var linkreportId = $(".navigatereporturl option:selected").val();
                this.saveReport(this.state.reportId, JSON.stringify(reportDataArray), reportcolumns, linkreportId, this.state.exportSchemaId, customFieldsScript, customFiltersScript, drillThroughReports, isSavedShareReport);

            }
            else {
                var renameMeasuresorDimensions = sessionStorage.getItem('renameMeasuresorDimensions');
                var reportcolumns;;
                if (renameMeasuresorDimensions != null && renameMeasuresorDimensions != "null") {
                    var parsedData = JSON.parse(renameMeasuresorDimensions);
                    if ((this.state.reportId != undefined || this.state.reportId != 0) && this.state.reportcolumns == undefined && this.state.columns == undefined) {
                        reportcolumns = this.state.viewreportCollection.toJSON()
                    }
                    else if (this.state.reportcolumns != undefined) {
                        reportcolumns = this.state.reportcolumns;
                    }
                    else {
                        reportcolumns = JSON.parse(this.state.columns);
                    }

                    if (reportcolumns != undefined) {
                        _.each(reportcolumns, function (obj) {
                            if (parsedData[obj.columnName] != undefined) {
                                obj['columnDisplayName'] = parsedData[obj.columnName];
                                obj['columnssrename'] = "yes";
                            }
                        });
                    }
                    else {
                        reportcolumns = JSON.parse(this.state.columns);
                        _.each(reportcolumns, function (obj) {
                            if (parsedData[obj.columnName] != undefined) {
                                obj['columnDisplayName'] = parsedData[obj.columnName];
                                obj['columnssrename'] = "yes";
                            }
                        });
                    }
                }
                else {
                    reportcolumns = this.state.reportcolumns;
                }
                _.each(reportcolumns, function (column, i) {
                    var isPriorityColumn = (column.priorityColumn != undefined) ? column.priorityColumn : false;
                    _.each(ChartInfo.functions.getPriorityColumns(), function (priorityObj, j) {
                        if (priorityObj.columnName == column.columnName) {
                            isPriorityColumn = priorityObj.priorityColumn;
                        }
                    });
                    column["priorityColumn"] = isPriorityColumn;
                });

                /*var usercustomScript = ChartInfo.getUserCustomScript(this.reportId);
                reportDataArray.push({"usercustomScript": usercustomScript});*/
                var isSavedShareReport = false;
                if (this.state.details != undefined && this.state.details["issharedreportsaved"] != undefined && this.state.details["issharedreportsaved"] == true) {
                    isSavedShareReport = true
                }

                var linkreportId = $(".navigatereporturl option:selected").val();
                this.saveReport(this.state.reportId, JSON.stringify(reportDataArray), JSON.stringify(reportcolumns), linkreportId, this.state.exportSchemaId, customFieldsScript, customFiltersScript, drillThroughReports, isSavedShareReport);

            }
        }
        if (this.state.isImportedReport) {
            $('.filtercontent .savereport-border').removeClass("disabled");
        }
    }
    saveReport = (reportId, reportdata, columnsData, linkedreportId, exportSchemaId, customFieldsScript, customFiltersScript, drillThroughReports, isSavedShareReport) => {
       var that = this;
        var parentreportId = 0;
        var fragmentArray = window.location.pathname.split("/");
        var isSharedReport = false;
        var datamodelId  = "";
        if (fragmentArray.length >5 && fragmentArray[5]!="") {
           
            var entities = "";
            var tableName = fragmentArray[7];
            var reg = new RegExp('^[0-9]+$');
            if(fragmentArray[4]!="" && fragmentArray[4]!=undefined && !reg.test(fragmentArray[4])){
                datamodelId = atob(fragmentArray[4]);
            }else{
                datamodelId = fragmentArray[4];	
            }
            if(fragmentArray[5]!="" && fragmentArray[5]!=undefined && !reg.test(fragmentArray[5])){
                entities = atob(fragmentArray[5]);
            }else{
                entities = fragmentArray[5];	
            }
        }
        else {
            if (fragmentArray[3] != "customviewreport") {
                var reg = new RegExp('^[0-9]+$');
                var parentreportId = 0;
                if (fragmentArray[4] != "" && fragmentArray[4] != undefined && !reg.test(fragmentArray[4])) {
                    parentreportId = atob(fragmentArray[4]);
                } else {
                    parentreportId = fragmentArray[4];
                }
            }
            else {
                entities = sessionStorage.getItem("entities");
            }
            if (fragmentArray.length == 6 && fragmentArray[3] === "sharereport") {
                isSharedReport = true;
            }

        }
        var isImportedReport = false;
        if (window.location.pathname.indexOf("importreport") >= 0) {
            datamodelId = ChartInfo.functions.getImportreportdatamodelId();
            isImportedReport = true;
        }
        if (window.location.pathname.indexOf("sharereport") >= 0) {
            isSharedReport = true;
        }
        var modeldata = {};
        var delimiter = sessionStorage.getItem("delimiter");
        // var viewreportsavestoryboardmodel = new FormData();
        //sessionStorage.removeItem("isImportedReport");
        modeldata["reportdata"] = reportdata;
        modeldata["columns"] = columnsData;
        modeldata["entities"] = entities;
        modeldata["tables"] = tableName;
        modeldata["datamodelId"] = datamodelId;
        modeldata["parentreportId"] = parentreportId;
        modeldata["delimiter"] = delimiter;
        modeldata["customFieldsScript"] = customFieldsScript;
        modeldata["customFiltersScript"] = customFiltersScript;
        modeldata["drillThroughReports"] = drillThroughReports;
        var convertedTypes = sessionStorage.getItem("convertedtypes");

        var viewreportsavestoryboardmodel = modeldata;
        //this.reset();
        //this.add(viewreportsavestoryboardmodel);
        var reportMetaData={};
		reportMetaData["sharemanage"] ="false";
		var url = window.location.search;
		if (url.length > 0) {
			url = url.replace('?', "").split("&");
			for (var i = 0; i < url.length; i++) {
				var key = url[i].split("=")[0];
				if (key.indexOf("sharemanage") != -1 && url[i].split("=")[1]!=undefined && url[i].split("=")[1] == "true") {
					reportMetaData["sharemanage"] ="true";
				}
			}
		}

        var bodydata = new FormData();
        bodydata.set("reportId", reportId!=undefined?reportId:0);
        bodydata.set("reportdata", reportdata);
        bodydata.set("columns", columnsData);
        bodydata.set("entities", "");
        bodydata.set("datamodelId", datamodelId);
        bodydata.set("tables", "");
        bodydata.set("parentreportId", parentreportId);
        bodydata.set("convertedTypes", (convertedTypes != undefined) ? convertedTypes : "");
        bodydata.set("delimiter", (delimiter != undefined) ? delimiter : "");
        bodydata.set("linkreportId", (linkedreportId != undefined) ? linkedreportId : "");
        bodydata.set("isImportedReport", isImportedReport);
        bodydata.set("customFieldsScript", (customFieldsScript != undefined) ? customFieldsScript : null);
        bodydata.set("customFiltersScript", (customFiltersScript != undefined) ? customFiltersScript : "");
        bodydata.set("drillThroughReports ", drillThroughReports);
        bodydata.set("isSharedReport ", isSharedReport);
        bodydata.set("isSavedShareReport", isSavedShareReport);
        bodydata.set("reportMetaData", JSON.stringify(reportMetaData));

        SendRequest({
            url: "savesinglereport",
            body: bodydata,
            sucessFunction: (response) => {
                if (response.hasOwnProperty('error')) {
                    $(".spinner").hide();
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('hide');
                    $('.birdmessage h2').empty().append('Error : ');
                    $('.birdmessage h2').append('<span class="errorcode">');
                    $('.birdmessage .errorcode').empty().append(response.errorCode);
                    $('.birdmessage-info').empty().text(response.error);
                    $('.details').addClass('show').text("Details");
                    $('.messagedetails xmp').empty().append(response.errorTrace)
                    //$('.fullscreenmain').prepend('<div class="alert alert-danger birderror alert-dismissable affix-top" style="margin:0 auto;width: 60%;top: 44px;position: fixed;z-index: 1;left: 20%;">'+response.error+'</div>');
                }else if (this.state.reportId != 0) {
                    $(".spinner").hide();
                    $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                    $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('show');
                    $('.details').addClass('hide');
                    $('.birdmessage h2').empty().append('Success');
                    $('.birdmessage-info').empty().text(getMessage('BirdSuccess01'));
                    viewreportsavestoryboardmodel["reportId"] =response.reportId;
                    this.onreportsave(viewreportsavestoryboardmodel);
                    this. cancelmodal();
                } else if (this.state.reportId == 0 || this.state.reportId == "") {
                    $(".spinner").hide();
                    $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                    $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('show');
                    $('.details').addClass('hide');
                    $('.birdmessage h2').empty().append('Success');
                    $('.birdmessage-info').empty().text(getMessage('BirdSuccess01'));

                    setTimeout(function () {
                        var href = "/viewreport";
                        if (response != undefined && response.reportId != undefined) {
                            $('.birdmessage-container .close').click();
                            href =  "/" + window.localStorage.getItem("appName") + "/welcome/viewreport/" + btoa(response.reportId);
                            that.cancelmodal();
                            window.open(href, '_self')
                            //that.props.history.push(`/${window.localStorage.getItem("appName")}/welcome/viewreport/${btoa(response.reportId)}`);
                           // window.location.reload();
                        }
                    }, 2000);
                } 
            },
            rejectFunction: () => { },
            headers: {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            }
        });

    }
    onreportsave = (model) => {
        if (this.state.reportId != 0) {
            if (true == true) { /*model.get("success") == true*/
                $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Success');
                $('.birdmessage-info').empty().text(getMessage('BirdSuccess01'));
            } else if (model.success == "fail") {
                $(".spinner").hide();
                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Warning');
                $('.birdmessage-info').empty().text("Report" + "" + getMessage('BirdWarning147'));
                $(".spinner").hide();
                return;
            }

        }

        var reportdata = JSON.parse(model["reportdata"]);
        var details = reportdata[1]["details"];
        var propObj = {};
        propObj["reportId"] = this.state.reportId;
        propObj["reportname"] = details["name"];
        propObj["reportfolder"] = details["folder"];
        propObj["reportdesc"] = details["desc"];
        propObj["chartnumber"] = this.state.chartnumber;
        propObj["syncConfigData"] = details["syncConfigData"];
        propObj["maxage"] = details["maxage"];
        propObj["maxageunits"] = details["maxageunits"];
        propObj["maxpurgeindex"] = details["maxpurgeindex"];
        propObj["timeZoneType"] = details["singlereporttimezone"];
        collection.updatereportchanges(model.reportId,details["name"], details["folder"], details["desc"],this.state.chartnumber,details["syncConfigData"]);

        PubSub.publish("savesinglereportchanges", propObj)

        $(".spinner").hide();
        //this.destroyview();
    }
    cancelmodal=()=>{
        if(document.getElementById(this.state.el)!=null){
            ReactDOM.unmountComponentAtNode(document.getElementById(this.state.el));
        }
    }
} 