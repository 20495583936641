import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import MyReportsListTemplate from "./MyReportsListHtml.jsx";
import SendRequest from '../SendRequest';
import _ from 'underscore';
import { getMessage } from '../js/helpers/utils/BirdMessage';
import VersionListPopup from '../ViewStoryboard/VersionListPopup'
import VersionsListView from '../ViewStoryboard/VersionsListView';
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;

class MyReportsListView extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.render(); 
    }

    getReportslist(page, search, pageDisplay, order, colName) {
        var index = 0;
        var noOfRecords = $(".reportrowlimtbutton .page-size").text() == "" ? 25: $(".reportrowlimtbutton .page-size").text();
		var foldername = $(".reportfolders-select option:selected").val() == undefined || $(".reportfolders-select option:selected").val()==''?'Default':$(".reportfolders-select option:selected").val();
		var totalRecords = 0;
        if (page != undefined && page > 1) {
            index = (page - 1) * noOfRecords;
        }
        if (order == undefined) {
            order = 'asc';
        }
        if (colName == undefined) {
            colName = '';
        }
        this.dataFieldValue = colName;
        this.sortingOrder = order;
        this.page = page;
        var that = this
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getdashboardreportslist",
            queryString: "?fromDate= " + "" + "&toDate= " + "" + "&index= " + index + "&noOfRecords=" + noOfRecords + "&searchCriteria=" + search + "&order=" + order + "&colName=" + colName + "&folderName=" + foldername + "&urltype=" + "getUserPaginationReports",
            sucessFunction: (response) => {
                if (response.hasOwnProperty('error')) {
                    $(".spinner").hide();
                } else {
                    var headerlist = response["header"];
                    var data = response["data"];
                    totalRecords = response["totalRecords"];
                    var keys = Object.keys(headerlist);
                    var totalPages;
                    if (pageDisplay == undefined) {
                        pageDisplay = 25;
                    }
                    if (totalRecords > 25) {
                        totalPages = Math.ceil(totalRecords / pageDisplay);
                    } else {
                        totalPages = 1;
                    }

                    _.each(data, function (val, index) {
                        val.viewReport = <a href={`${that.props.url}/viewreport/${btoa(val.reportId)}`} data-num={val.reportId} class="boc-snapshot-thumbnail reportview" tab-id="0" tab-name="" id="reportstoryboardview"><i class="fa fa-bar-chart"></i></a>;
      
                        val.deleteReport = <button type="button" onClick={that.deleteReport} id="myreportslist" data-num={val.reportId} tab-id="0" tab-name="" is-shared={val.isShared} is-favourite={val.isUserFavourite} class="btn btn-transparent aldelete"><i class="fa fa-trash-o"></i></button>;
                        val.version = <button type="button" onClick={that.versionsviewclick} id="versionliststoryboardview" data-num={val.reportId} tab-id="0" tab-name="" is-shared={val.isShared} is-favourite={val.isUserFavourite} class="btn btn-transparent"><i class="fa fa-th-list"></i></button>;  
                    });
 
                    ReactDOM.unmountComponentAtNode(document.getElementById("myreporttable"));
                    ReactDOM.render(<MyReportsListTemplate foldersselectchange={this.foldersselectchange} versionsviewclick={that.versionsviewclick} foldersArray={that.props.foldersArray} deleteReport={that.deleteReport} searchkeyup={this.searchkeyup} deleteFavourite={this.deleteFavourite} search={search} url={that.props.url} totalRecords={totalRecords} totalRecords={totalRecords} sizePerPage={pageDisplay} headerlist={headerlist} data={data} />,document.querySelector("#myreporttable"));
                    $(".reportfolders-select").chosen({
						no_results_text: lang["visual.favourites.oops"],
			            width: "100%",
			            search_contains: true
			        });
					$(".reportfolders-select option[value='"+foldername+"']").attr("selected","selected")
                    $(".reportfolders-select").trigger("chosen:updated");
                    if (search != '') {
                    $(".searchreporttable").focus();
                    }
                    $(".spinner").hide();

                }
            },
            rejectFunction: () => { },
            headers: requestHeaders

        });


    }
    versionsviewclick = (e) => {
      var $this = e.currentTarget;
        var reportId = $($this).attr("data-num");
	  $("body").append("<div id='datatablepopupmodal'></div>");
		ReactDOM.unmountComponentAtNode(document.getElementById("datatablepopupmodal"));
		ReactDOM.render(<VersionListPopup/>,document.getElementById("datatablepopupmodal"));
        ReactDOM.render(<VersionsListView url={this.props.url} urltype="singlereport" reportId={reportId} from="list" />, document.getElementById("pivotdataview"));
      }
    deleteReport = (e) => {
        var $this = e.currentTarget;
	    var reportId = $($this).attr("data-num");
        console.log("control comes to delete report")
			var that=this;
			$(".deleteyes").unbind( "click" );
			$(".deleteno").unbind( "click" );
			$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
			$('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
			$('.birdmessagebuttons').addClass('show');
			$('.birdmessage-center').addClass("message-top-in");
			$('.details').removeClass('show');
			$('.details').addClass('hide');
			$('.birdmessage h2').empty().append('Warning');
			$('.birdmessage-info').empty().text(getMessage('BirdWarning18'));
			$( ".deleteyes" ).click(function() {
				$('.birdmessage-center').removeClass("message-top-in");
				var $this = e.currentTarget;
				$('.spinner').show();
				var requestbody = {};
				requestbody["reportId"] = reportId;
				requestbody["isFavourite"] = false;
				requestbody["isShared"] = false;
                requestbody["isReportOwner"] = true;
                let requestHeaders = {
                    method: 'post',
                    headers: new Headers({
                        "Content-Type": "application/json"
                    })
                };
				SendRequest({
                    url: "deletereport",
                    queryString: "?reportId= "+requestbody["reportId"]+"&isFavourite ="+requestbody["isFavourite"]+"&isSharedReport="+requestbody["isShared"] +"&isReportOwner="+requestbody["isReportOwner"] ,
                    sucessFunction: (response) => {
							if (response.hasOwnProperty('error')) {
								$(".spinner").hide();
								$(".gridspinner").hide();
								$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
								$('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
								$('.birdmessagebuttons').removeClass('show');
								$('.birdmessagebuttons').addClass('hide');
								$('.birdmessage-center').addClass("message-top-in");
								$('.details').removeClass('hide');
								$('.birdmessage h2').empty().append('Error : ');
								$('.birdmessage h2').append('<span class="errorcode">');
								$('.birdmessage .errorcode').empty().append(response.errorCode);
								$('.birdmessage-info').empty().text(response.error);
								$('.details').addClass('show');
								$('.messagedetails xmp').empty().append(response.errorTrace);
							} else {
								$('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
								$('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
								$('.birdmessagebuttons').removeClass('show');
								$('.birdmessagebuttons').addClass('hide');
								$('.birdmessage-center').addClass("message-top-in");
								$('.details').removeClass('show');
								$('.details').addClass('hide');
								$('.birdmessage h2').empty().append('Success');
								$('.birdmessage-info').empty().text(getMessage('BirdSuccess42'));
                                that.getReportslist(undefined, "", 25, 'asc', undefined);
							}
						},
						rejectFunction: () => { },
						headers: requestHeaders
					})
                });
			$( ".deleteno" ).click(function() {
				$('.birdmessage-center').removeClass("message-top-in");
			});
		
    }
    searchkeyup = (e) => {
        //console.log("in search block");
        var that = this;
        var delay = (function () {
            var timer = 0;
            return function (callback, ms) {
                clearTimeout(timer);
                timer = setTimeout(callback, ms);
            };
        })();
        var search = $(e.currentTarget).val().toLowerCase();
        var pageDisplaySearch = $("#pageDropDown").text();
        delay(function () {
            that.searchValue = $(".searchreporttable").val();
            that.getReportslist(undefined, that.searchValue, pageDisplaySearch, that.sortingOrder, that.dataFieldValue);
        }, 100);

        $(".searchreporttable").focus();
    }
    foldersselectchange= (e) => {
        var pageDisplaySearch = $("#pageDropDown").text();
        this.getReportslist(undefined, "", pageDisplaySearch, 'asc', undefined);
    }
    render() {
        this.getReportslist(undefined, "", 25, 'asc', undefined);
        return(
            <div className="col-xs-12 marg-top-10">
        <div className="notifications-table-container data-item">
          <div className="bootstrap-table">
            <div className="fixed-table-toolbar">
              <div className="pull-right search">
                <input className="form-control searchreporttable" type="text" placeholder={lang["visual.favourites.search"]} defaultValue={this.search!=undefined?this.search:""} onKeyUp={this.searchkeyup}/>
              </div>
              <div className="pull-left">
                <select className="reportfolders-select" data-placeholder={lang["visual.slctfolders"]} onChange={this.foldersselectchange}>
                  <optgroup label="Folders">
                  <React.Fragment>
                        {
                          this.props.foldersArray.map((val, index) => {
                            return (
                              <option value={val.Folder}>{val.Folder}</option>
                            )
                          })
                        }
                      </React.Fragment>
                  </optgroup>
                </select>
              </div>
            </div>
            <div id="myreporttable">
            </div>
          </div>
        </div>
      </div>
        )
    }
}

export default MyReportsListView;