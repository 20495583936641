import React from 'react';
import { lang } from "../js/helpers/utils/languages/language";
export default class DatamodelModifyentitiesHtml extends React.Component {
    render() {
        return (
            <div className="table-responsive">
                <table className="table custom">
                    <thead>
                        <tr>
                            <th>{lang["models.entityname"]}</th>
                            <th>{lang["models.columnname"]}</th>
                            <th>{lang["models.colmndisply"]}</th>
                            <th>{lang["models.datatype"]}</th>
                            <th>{lang["models.visibility"]}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.reportdata.map((data, index) => {
                                var modelDisplayName = data.columnDisplayName.split(".");
                                modelDisplayName = (modelDisplayName.length == 2) ? modelDisplayName[1] : data.columnDisplayName;
                                var replacedOriginalName = data.orginalColumnName.replace(/([-_;?&.,%#+*~\ '"!^$[\]()<=>`|\/@])/g, "");
                                return (
                                    <tr key={index}>
                                        {
                                            (this.props.entityName === "webdataconnector") ? <td>{this.props.entityName}</td> : <td>{data.tablename}</td>
                                        }
                                        <td>{data.orginalColumnName}</td>
                                        <td>
                                            <input type="text" id={data.columnName} defaultValue={modelDisplayName} isPartitionKey={data.isPartitionKey} tablealiasname={data.tableAliasName} tablename={data.tableName != '' ? data.tablename : data.tableName} columnname={data.columnName} orginalcolumnname={data.orginalColumnName} onBlur={this.props.validateColName} className={`csv-form-control display-col tabledisplayname ${this.props.type == 'existing' ? 'disabled' : ''}`} />
                                            <span replacedOriginalName={replacedOriginalName} orginalcolumnname={data.orginalColumnName} name={data.columnName} id={data.columnName} className="error-block pull-left csvdisplayname-error hide">{lang["models.colspclcharact"]}</span>
                                            <span replacedOriginalName={replacedOriginalName} orginalcolumnname={data.orginalColumnName} name={data.columnName} className="displayrename-error pull-left  hide">{lang["models.colnameexists"]}</span>
                                            <span style={{ color: "#FF0000", float: "right", fontWeight: 400 }} orginalcolumnname={data.orginalColumnName} name={data.columnName} id={data.columnName} className="error-block pull-left csvdisplaynameid-error hide">{lang["models.idnotalow"]}</span>
                                        </td>
                                        <td>
                                            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 isDirectDisabled">
                                                {
                                                    (this.props.schemaType != "fuse") ? (
                                                        <select className={`csv-form-control datecontroller ${this.props.dataTypeClass} disabled`} id={data.columnName} name={data.columnName}>
                                                            {
                                                                (data.tableDisplayType == 'string') ? (
                                                                    <>
                                                                        <option value="string" selected>{lang["models.strngtype"]}</option>
                                                                        <option value="date">{lang["models.datetype"]} </option>
                                                                        <option value="number">{lang["models.numtype"]}</option>
                                                                    </>
                                                                ) : (data.tableDisplayType == 'date') ? (
                                                                    //isdate=true;
                                                                    // if(importtempateflag){
                                                                    //     isDate=true;
                                                                    // }
                                                                    <>
                                                                        <option value="date" selected>{lang["models.datetype"]} </option>
                                                                        <option value="string">{lang["models.strngtype"]}</option>
                                                                        <option value="number">{lang["models.numtype"]}</option>
                                                                    </>
                                                                ) : (
                                                                            <>
                                                                                <option value="date">{lang["models.datetype"]}</option>
                                                                                <option value="string">{lang["models.strngtype"]}</option>
                                                                                <option value="number" selected>{lang["models.numtype"]}</option>
                                                                            </>
                                                                        )
                                                            }
                                                        </select>
                                                    ) : (
                                                            <select className={`csv-form-control datecontroller ${this.props.dataTypeClass} disabled`} id={data.columnName} name={data.columnName}>
                                                                {
                                                                    (data.tableDisplayType == 'string') ? (
                                                                        <>
                                                                            <option value="string" selected>{lang["models.strngtype"]}</option>
                                                                            <option value="date">{lang["models.datetype"]}</option>
                                                                            <option value="number">{lang["models.numtype"]}</option>
                                                                        </>
                                                                    ) : (data.tableDisplayType == 'date') ? (
                                                                        <>
                                                                            <option value="date" selected>{lang["models.datetype"]} </option>
                                                                            <option value="string">{lang["models.strngtype"]}</option>
                                                                            <option value="number">{lang["models.numtype"]}</option>
                                                                        </>
                                                                    ) : (
                                                                                <>
                                                                                    <option value="date">{lang["models.datetype"]} </option>
                                                                                    <option value="string">{lang["models.strngtype"]}</option>
                                                                                    <option value="number" selected>{lang["models.numtype"]}</option>
                                                                                </>
                                                                            )
                                                                }
                                                            </select>
                                                        )
                                                }
                                            </div>
                                            <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                                                <input type="text" name={data.columnName} className="csv-form-control customdate-others" style={{ display: "none" }} />
                                            </div>
                                            <span id={data.columnName} className="error-block customdate-error hide"> {lang["models.curntformat"]}</span>
                                        </td>
                                        <td>
				{
				(data.visible =='yes')?( 
                    <>
					<label class="width-100 nomargin"><input type="checkbox" name={data.columnName} class="csv-form-control modelitemvisible <%=type=='existing'?'disabled':''%>" disabled  defaultChecked="checked" /></label>
				</>
                ):(
					<label class="width-100 nomargin"><input type="checkbox" name={data.columnName} class="csv-form-control modelitemvisible <%=type=='existing'?'disabled':''%> " disabled /></label>
                )
                }
			</td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            </div>
        );
    }
}