import React from "react";
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;

export default class AdvancedStoeyboardSharePermissionsTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        $('.permissionfiltercheckbox').click(function (e) {
            e.stopPropagation();
            var currentTarget = $(e.currentTarget);
            var id = currentTarget.attr("data-userid");
            if ($(".permissionfiltercheckbox[data-userid=" + id + "]:checked").length == 0) {
                currentTarget.prop("checked", true);
            }
        });
        $('.drilldowntogglecheckbox').click(function (e) {
            e.stopPropagation();
        });
        $('#columnpermissions').click(function (e) {
            $("#advanceedclose").removeClass('hide');
            $("#columnpermissions").addClass('hide');
            $('.columnspermissiondiv').removeClass("hide");
            $('.datapermissiondiv').addClass("hide");
        });
    }
    render() {
        var that = this.props;
        var hideClass = "";
        return (
            <div className="modal fade in" id="advancedsharestoryboardpermissionmodal" role="dialog" aria-labelledby="advancedsharestoryboardpermissionmodal" aria-hidden="true" style={{ display: "block" }}>
                {/* <!-- advancedstoryboardsharepermissions.js --> */}
                {/* <div className="modal-backdrop fade in"></div> */}
                <div className="modal-dialog modal-sm modal-md-400 filterleft modal-dialog-center" style={{ zIndex: "1050" }}>
                    <div className="modal-content">
                        <div className="modal-header boc-modal-hf-padding">
                            <h4 className="modal-title" id="mySmallModalLabel"><i className="fa fa-lock"></i> {lang["storyboard.advancedpermison"]}
                           <button type="button" className="close white" id="advanceedclose" aria-hidden="true" data-number="" data-dismiss="modal"><i className="fa fa-arrow-left"></i></button>
                                <button type="button" className="close white hide" id="columnpermissions"><i className="fa fa-arrow-left"></i></button>
                            </h4>
                        </div>
                        <div className="modal-body scrollset">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="row-same-height">
                                        {/* <!-- Columns Window --> */}
                                        <div className="col-xs-12 col-xs-height settingscontent columnspermissiondiv">
                                            <div className="modal-height-fix">
                                                <div className="col-xs-12 marg-bot-10"><i className="fa fa-columns"></i> {lang["storyboard.restrictedcolumns"]}</div>
                                                <div className="clear"></div>
                                                <div className="permissionscroller">
                                                    {that.groupcollection.map((group, index) => {
                                                        return that.selectedgrouplists.map((selectedgroup, index) => {
                                                            if (selectedgroup.groupid == group.groupId) {
                                                                return <div className="permissioncontainer dobyfilteritems" data-userid={group.groupId} user-type="groups">
                                                                    <div className="col-xs-12"><i className="fa fa-users username"></i>{group.groupName}<i className="fa fa-lock pull-right"></i></div>
                                                                    <div className="clear"></div>
                                                                    <div className="col-xs-12 text-right permission-options">
                                                                        {/* <!-- 										<button className="btn btn-link btn-sm nopadding marg-righ-5 personalcomment"><i className="fa fa-comment marg-righ-5"></i>Comment</button>                                            
           --> */}
                                                                        {(that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                                                                            <button className="btn btn-link btn-sm nopadding marg-righ-5 editallcheckeditems" onClick={that.editallcheckeditems} id="editallcheckeds" user-name={group.groupName}><i className="fa fa-pencil marg-righ-5" ></i>{lang["storyboard.editchecked"]}</button>
                                                                        ) : null}
                                                                        <button className="btn btn-link btn-sm nopadding marg-righ-5 checkallshareitems" onClick={that.checkAllShareItems} id="checkallshares" data-userid={group.groupId} ><i className="fa fa-check marg-righ-5"></i>{lang["storyboard.checkall"]}</button>
                                                                        <button className="btn btn-link btn-sm nopadding uncheckallshareitems" onClick={that.uncheckAllShareItems} id="uncheckallshares" data-userid={group.groupId}><i className="fa fa-times marg-righ-5"></i>{lang["storyboard.uncheckall"]}</button>
                                                                    </div>
                                                                    <div className="col-xs-12 sharecommentbox hide">
                                                                        <textarea placeholder={`Comment for ${group.groupName} Group`} name="comments" className="modal-form-fields resize-vertical"></textarea>
                                                                        <button type="button" className="btn btn-bird btn-sm marg-righ-5 pull-right" id="btn-birdapply">{lang["storyboard.applybtn"]}</button>
                                                                        <button type="button" className="btn btn-bird btn-sm marg-righ-5 pull-right" id="btn-birdcancel">{lang["storyboard.cancelbtn"]}</button>
                                                                    </div>
                                                                    <div className="clear"></div>
                                                                    <div className="sharedviewonly scrollsetulviewer">
                                                                        <legend className="sharedviwer"><i className="fa fa-eye-slash" aria-hidden="true"></i> {lang["storyboard.viewaccess"]}</legend>
                                                                        <div className="drilldowntogglecheckbox">
                                                                            <div className="checkbox checkbox-slider--c checkbox-slider-sm">
                                                                                <label> <input type="checkbox" id="btnReadonlyReport" className="btnReadonlyReport" data-userid={group.groupId} user-name={group.groupName} user-type="groups" /><span>&nbsp;</span></label>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <ul className="list-group scrollsetul permissionlist entitytype">
                                                                        {that.dimensions.map((dimension, i) => {
                                                                            if (dimension.unique) {
                                                                                hideClass = "hide"
                                                                            }
                                                                            if (dimension.tableDisplayType == 'date' || dimension.tableDisplayType == 'datetime') {
                                                                                return <li key={i} className="boc-list-item xs-list-item" data-type="date">
                                                                                    <label title={dimension.columnDisplayName} className={hideClass}><input type="checkbox" className="permissionfiltercheckbox" data-id={dimension.id} data-keycolumn={dimension.keycolumn} data-agg={dimension.aggregation} data-type={dimension.tableDisplayType} data-parent={dimension.tableName} data-child={dimension.columnName} name={dimension.columnName} data-colrename={dimension.columnssrename} data-unique={dimension.unique} data-visible={dimension.visible} data-tableAliasName={dimension.tableAliasName} data-dateFormat={dimension.dateFormat} data-orginalColumnName={dimension.orginalColumnName} data-userid={group.groupId} defaultChecked /><span className="itemtype"><i className="fa fa-calendar"></i></span>{dimension.columnDisplayName}</label>
                                                                                    {(that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                                                                                        <span className={`permitcolumndata ${hideClass}`} onClick={that.permitcolumndata} title={lang["storyboard.edit"]} user-name={group.groupName}><i className="fa fa-pencil"></i></span>
                                                                                    ) : null}
                                                                                </li>
                                                                            } else if (dimension.tableDisplayType == 'number') {
                                                                                return <li key={i} className="boc-list-item xs-list-item" data-type="number">
                                                                                    <label title={dimension.columnDisplayName} className={hideClass}><input type="checkbox" className="permissionfiltercheckbox" data-id={dimension.id} data-keycolumn={dimension.keycolumn} data-agg={dimension.aggregation} data-type={dimension.tableDisplayType} data-parent={dimension.tableName} data-child={dimension.columnName} name={dimension.columnName} data-colrename={dimension.columnssrename} data-unique={dimension.unique} data-visible={dimension.visible} data-tableAliasName={dimension.tableAliasName} data-dateFormat={dimension.dateFormat} data-orginalColumnName={dimension.orginalColumnName} data-userid={group.groupId} defaultChecked /><span className="itemtype">123</span>{dimension.columnDisplayName}</label>
                                                                                    {(that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                                                                                        <span className={`permitcolumndata ${hideClass}`} onClick={that.permitcolumndata} title={lang["storyboard.edit"]} user-name={group.groupName}><i className="fa fa-pencil"></i></span>
                                                                                    ) : null}
                                                                                </li>
                                                                            } else if (dimension.tableDisplayType == 'month') {
                                                                                return <li key={i} className="boc-list-item xs-list-item" data-type="date">
                                                                                    <label title={dimension.columnDisplayName} className={hideClass}><input type="checkbox" className="permissionfiltercheckbox" data-id={dimension.id} data-keycolumn={dimension.keycolumn} data-agg={dimension.aggregation} data-type={dimension.tableDisplayType} data-parent={dimension.tableName} data-child={dimension.columnName} name={dimension.columnName} data-colrename={dimension.columnssrename} data-unique={dimension.unique} data-visible={dimension.visible} data-tableAliasName={dimension.tableAliasName} data-dateFormat={dimension.dateFormat} data-orginalColumnName={dimension.orginalColumnName} data-userid={group.groupId} defaultChecked /><span className="itemtype"><i className="fa fa-calendar-o"></i></span>{dimension.columnDisplayName}</label>
                                                                                    {(that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                                                                                        <span className={`permitcolumndata ${hideClass}`} onClick={that.permitcolumndata} title={lang["storyboard.edit"]} user-name={group.groupName}><i className="fa fa-pencil"></i></span>
                                                                                    ) : null}
                                                                                </li>
                                                                            } else if (dimension.tableDisplayType == 'day') {
                                                                                return <li key={i} className="boc-list-item xs-list-item" data-type="date">
                                                                                    <label title={dimension.columnDisplayName} className={hideClass}><input type="checkbox" className="permissionfiltercheckbox" data-id={dimension.id} data-keycolumn={dimension.keycolumn} data-agg={dimension.aggregation} data-type={dimension.tableDisplayType} data-parent={dimension.tableName} data-child={dimension.columnName} name={dimension.columnName} data-colrename={dimension.columnssrename} data-unique={dimension.unique} data-visible={dimension.visible} data-tableAliasName={dimension.tableAliasName} data-dateFormat={dimension.dateFormat} data-orginalColumnName={dimension.orginalColumnName} data-userid={group.groupId} defaultChecked /><span className="itemtype"><i className="fa fa-calendar-o"></i></span>{dimension.columnDisplayName}</label>
                                                                                    {(that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                                                                                        <span className={`permitcolumndata ${hideClass}`} onClick={that.permitcolumndata} title={lang["storyboard.edit"]} user-name={group.groupName}><i className="fa fa-pencil"></i></span>
                                                                                    ) : null}
                                                                                </li>
                                                                            } else {
                                                                                return <li key={i} className="boc-list-item xs-list-item" data-type="string">
                                                                                    <label title={dimension.columnDisplayName} className={hideClass}><input type="checkbox" className="permissionfiltercheckbox" data-id={dimension.id} data-keycolumn={dimension.keycolumn} data-agg={dimension.aggregation} data-type={dimension.tableDisplayType} data-parent={dimension.tableName} data-child={dimension.columnName} name={dimension.columnName} data-colrename={dimension.columnssrename} data-unique={dimension.unique} data-visible={dimension.visible} data-tableAliasName={dimension.tableAliasName} data-dateFormat={dimension.dateFormat} data-orginalColumnName={dimension.orginalColumnName} data-userid={group.groupId} defaultChecked /><span className="itemtype">{lang["storyboard.abc"]}</span>{dimension.columnDisplayName}</label>
                                                                                    {(that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                                                                                        <span className={`permitcolumndata ${hideClass}`} onClick={that.permitcolumndata} title={lang["storyboard.edit"]} user-name={group.groupName}><i className="fa fa-pencil"></i></span>
                                                                                    ) : null}
                                                                                </li>
                                                                            }
                                                                        })}
                                                                    </ul>
                                                                </div>
                                                            }
                                                        })
                                                    })}

                                                    {that.userscollection.map((user, index) => {
                                                        return that.selecteduserslist.map((selecteduser, index) => {
                                                            if (selecteduser.userid == user.userid) {
                                                                return <div className="permissioncontainer dobyfilteritems" data-userid={user.userid} user-type="users">
                                                                    <div className="col-xs-12"><i className="fa fa-user username"></i>{user.loginname}<i className="fa fa-lock pull-right"></i></div>
                                                                    <div className="clear"></div>
                                                                    <div className="col-xs-12 text-right permission-options">
                                                                        {/* <!--		<button className="btn btn-link btn-sm nopadding marg-righ-5 personalcomment"><i className="fa fa-comment marg-righ-5"></i>Comment</button> -->  */}
                                                                        {(that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                                                                            <button className="btn btn-link btn-sm nopadding marg-righ-5 editallcheckeditems" onClick={that.editallcheckeditems} id="editallcheckeds" user-name={user.loginname}><i className="fa fa-pencil marg-righ-5" ></i>{lang["storyboard.editchecked"]}</button>
                                                                        ) : null}
                                                                        <button className="btn btn-link btn-sm nopadding marg-righ-10 checkallshareitems" onClick={that.checkAllShareItems} id="checkallshares" data-userid={user.userid}><i className="fa fa-check marg-righ-5"></i>{lang["storyboard.checkall"]}</button>
                                                                        <button className="btn btn-link btn-sm nopadding uncheckallshareitems" onClick={that.uncheckAllShareItems} id="uncheckallshares" data-userid={user.userid}><i className="fa fa-times marg-righ-5"></i>{lang["storyboard.uncheckall"]}</button>
                                                                    </div>
                                                                    <div className="col-xs-12 sharecommentbox hide">
                                                                        <textarea placeholder={`Comment for ${user.loginname}`} name="comments" className="modal-form-fields resize-vertical"></textarea>
                                                                        <button type="button" className="btn btn-bird btn-sm marg-righ-5 pull-right" id="btn-birdapply">{lang["storyboard.applybtn"]}</button>
                                                                        <button type="button" className="btn btn-bird btn-sm marg-righ-5 pull-right" id="btn-birdcancel">{lang["storyboard.cancelbtn"]}</button>
                                                                    </div>
                                                                    <div className="clear"></div>
                                                                    <div className="sharedviewonly scrollsetulviewer">
                                                                        <legend className="sharedviwer"><i className="fa fa-eye-slash" aria-hidden="true"></i> {lang["storyboard.viewaccess"]}</legend>
                                                                        <div className="drilldowntogglecheckbox">
                                                                            <div className="checkbox checkbox-slider--c checkbox-slider-sm">
                                                                                <label> <input type="checkbox" id="btnReadonlyReport" className="btnReadonlyReport" data-userid={user.userid} user-name={user.loginname} user-type="users" /><span>&nbsp;</span></label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <ul className="list-group scrollsetul permissionlist entitytype">
                                                                        {that.dimensions.map((dimension, i) => {
                                                                            if (dimension.unique) {
                                                                                hideClass = "hide"
                                                                            }
                                                                            if (dimension.tableDisplayType == 'date' || dimension.tableDisplayType == 'datetime') {
                                                                                return <li key={i} className="boc-list-item xs-list-item" data-type="date">
                                                                                    <label title={dimension.columnDisplayName} className={hideClass} ><input type="checkbox" className="permissionfiltercheckbox" data-id={dimension.id} data-keycolumn={dimension.keycolumn} data-agg={dimension.aggregation} data-type={dimension.tableDisplayType} data-parent={dimension.tableName} data-child={dimension.columnName} name={dimension.columnName} data-colrename={dimension.columnssrename} data-unique={dimension.unique} data-visible={dimension.visible} data-tableAliasName={dimension.tableAliasName} data-dateFormat={dimension.dateFormat} data-orginalColumnName={dimension.orginalColumnName} data-userid={user.userid} defaultChecked /><span className="itemtype"><i className="fa fa-calendar"></i></span>{dimension.columnDisplayName}</label>
                                                                                    {(that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                                                                                        <span className={`permitcolumndata ${hideClass}`} onClick={that.permitcolumndata} data-userid={user.userid} data-child={dimension.columnName} user-name={user.loginname} title={lang["storyboard.edit"]}><i className="fa fa-pencil"></i></span>
                                                                                    ) : null}
                                                                                </li>
                                                                            } else if (dimension.tableDisplayType == 'number') {
                                                                                return <li key={i} className="boc-list-item xs-list-item" data-type="number">
                                                                                    <label title={dimension.columnDisplayName} className={hideClass}><input type="checkbox" className="permissionfiltercheckbox" data-id={dimension.id} data-keycolumn={dimension.keycolumn} data-agg={dimension.aggregation} data-type={dimension.tableDisplayType} data-parent={dimension.tableName} data-child={dimension.columnName} name={dimension.columnName} data-colrename={dimension.columnssrename} data-unique={dimension.unique} data-visible={dimension.visible} data-tableAliasName={dimension.tableAliasName} data-dateFormat={dimension.dateFormat} data-orginalColumnName={dimension.orginalColumnName} data-userid={user.userid} defaultChecked /><span className="itemtype">123</span>{dimension.columnDisplayName}</label>
                                                                                    {(that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                                                                                        <span className={`permitcolumndata ${hideClass}`} onClick={that.permitcolumndata} data-userid={user.userid} data-child={dimension.columnName} user-name={user.loginname} title={lang["storyboard.edit"]}><i className="fa fa-pencil"></i></span>
                                                                                    ) : null}
                                                                                </li>
                                                                            } else if (dimension.tableDisplayType == 'month') {
                                                                                return <li key={i} className="boc-list-item xs-list-item" data-type="date">
                                                                                    <label title={dimension.columnDisplayName} className={hideClass}><input type="checkbox" className="permissionfiltercheckbox" data-id={dimension.id} data-keycolumn={dimension.keycolumn} data-agg={dimension.aggregation} data-type={dimension.tableDisplayType} data-parent={dimension.tableName} data-child={dimension.columnName} name={dimension.columnName} data-colrename={dimension.columnssrename} data-unique={dimension.unique} data-visible={dimension.visible} data-tableAliasName={dimension.tableAliasName} data-dateFormat={dimension.dateFormat} data-orginalColumnName={dimension.orginalColumnName} data-userid={user.userid} defaultChecked /><span className="itemtype"><i className="fa fa-calendar"></i></span>{dimension.columnDisplayName}</label>
                                                                                    {(that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                                                                                        <span className={`permitcolumndata ${hideClass}`} onClick={that.permitcolumndata} data-userid={user.userid} data-child={dimension.columnName} user-name={user.loginname} title={lang["storyboard.edit"]}><i className="fa fa-pencil"></i></span>
                                                                                    ) : null}
                                                                                </li>
                                                                            } else if (dimension.tableDisplayType == 'day') {
                                                                                return <li key={i} className="boc-list-item xs-list-item" data-type="date">
                                                                                    <label title={dimension.columnDisplayName} className={hideClass}><input type="checkbox" className="permissionfiltercheckbox" data-id={dimension.id} data-keycolumn={dimension.keycolumn} data-agg={dimension.aggregation} data-type={dimension.tableDisplayType} data-parent={dimension.tableName} data-child={dimension.columnName} name={dimension.columnName} data-colrename={dimension.columnssrename} data-unique={dimension.unique} data-visible={dimension.visible} data-tableAliasName={dimension.tableAliasName} data-dateFormat={dimension.dateFormat} data-orginalColumnName={dimension.orginalColumnName} data-userid={user.userid} defaultChecked /><span className="itemtype"><i className="fa fa-calendar"></i></span>{dimension.columnDisplayName}</label>
                                                                                    {(that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                                                                                        <span className={`permitcolumndata ${hideClass}`} onClick={that.permitcolumndata} data-userid={user.userid} data-child={dimension.columnName} data-child={dimension.columnName} user-name={user.loginname} title={lang["storyboard.edit"]}><i className="fa fa-pencil"></i></span>
                                                                                    ) : null}
                                                                                </li>
                                                                            } else {
                                                                                return <li key={i} className="boc-list-item xs-list-item" data-type="string">
                                                                                    <label title={dimension.columnDisplayName} className={hideClass}><input type="checkbox" className="permissionfiltercheckbox" data-id={dimension.id} data-keycolumn={dimension.keycolumn} data-agg={dimension.aggregation} data-type={dimension.tableDisplayType} data-parent={dimension.tableName} data-child={dimension.columnName} name={dimension.columnName} data-colrename={dimension.columnssrename} data-unique={dimension.unique} data-visible={dimension.visible} data-tableAliasName={dimension.tableAliasName} data-dateFormat={dimension.dateFormat} data-orginalColumnName={dimension.orginalColumnName} data-userid={user.userid} defaultChecked /><span className="itemtype">{lang["storyboard.abc"]}</span>{dimension.columnDisplayName}</label>
                                                                                    {(that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                                                                                        <span className={`permitcolumndata ${hideClass}`} onClick={that.permitcolumndata} data-userid={user.userid} data-child={dimension.columnName} user-name={user.loginname} title={lang["storyboard.edit"]}><i className="fa fa-pencil"></i></span>
                                                                                    ) : null}
                                                                                </li>
                                                                            }
                                                                        })}
                                                                    </ul>
                                                                </div>
                                                            }
                                                        })
                                                    })}
                                                </div>
                                            </div>
                                            {/* <!-- /Modal Height Fix --> */}
                                        </div>
                                        {/* <!-- /Columns Window --> */}
                                        {/* <!-- Data Window --> */}
                                        <div className="col-xs-6 col-xs-height settingscontent datapermissiondiv hide">
                                            <div className="modal-height-fix">
                                                <div className="col-xs-12 marg-bot-10"><i className="fa fa-shield"></i> {lang["storyboard.restricteddata"]}</div>
                                                <div className="clear"></div>
                                                <div className="permissionscroller">
                                                    <div className="permissioncontainer dobyfilteritems rightpermissioncontainer">
                                                        <div className="col-xs-12"><i className="fa fa-users" data-userid=""></i> </div>
                                                        <div className="clear"></div>
                                                        {/* <!-- Sample data --> */}
                                                        <div className="panel-group permissioncontainerrightfilter"></div>
                                                        {/* <!--/ Sample Data -->		 */}
                                                    </div>

                                                </div>
                                                {/* <!-- /PermissionScroll --> */}
                                                <button type="button" onClick={that.datapermissionupdate} className="btn btn-bird pull-right btn-sm marg-righ-10 marg-top-5 datapermissionupdate hide">{lang["storyboard.update"]}</button>
                                            </div>
                                        </div>
                                        {/* <!-- /Data Window --> */}

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer boc-modal-hf-padding">
                            <button type="button" className="btn btn-bird cancelaccesspermisions" id="btn-bird" data-dismiss="modal" aria-hidden="true"><i className="fa fa-times"></i> {lang["storyboard.cancelbtn"]}</button>
                            <button type="button" className="btn btn-bird applyaccesspermisions" onClick={that.applyaccesspermisions} id="applyaccesspermision" data-num="" tab-id="0" tab-name="" aria-hidden="true"><i className="fa fa-check"></i> {lang["storyboard.applybtn"]}</button>
                        </div>
                    </div>
                    {/* <!-- /.modal-content --> */}
                </div>
                {/* <!-- /.modal-dialog --> */}
            </div>);
    }
}