import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { lang } from "../js/helpers/utils/languages/language";
import SendRequest from '../SendRequest';
import _ from 'underscore';
const $ = window.$;
const columns = [
    { dataField: 'id', text: 'S.No.', sort: false, headerAlign: 'center', align: 'center' },
    { dataField: 'workspace_name', text: 'Business Workspace Name', sort: true },
    { dataField: 'workspace_desc', text: 'Description', sort: true },
    { dataField: 'create_date', text: 'Created Date', sort: true },
    { dataField: 'update_date', text: 'Updated Date', sort: true },
    { dataField: 'createBusinessModel', text: 'Create Model', sort: false, headerAlign: 'center', align: 'center' },
    { dataField: 'editWorkspace', text: 'Edit Business Workspace', sort: false, headerAlign: 'center', align: 'center' },
    { dataField: 'deleteWorkspace', text: 'Delete Business Workspace', sort: false, headerAlign: 'center', align: 'center' }
];
const RemotePagination = ({ data, page, sizePerPage, onTableChange, totalSize }) => (
    <div className="notifications-table-container">
        <BootstrapTable
            remote
            keyField="id"
            data={data}
            columns={columns}
            pagination={paginationFactory({ page, sizePerPage, totalSize })}
            onTableChange={onTableChange}
        />
    </div>
);
export default class WorkspaceListTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            data: props.data,
            sizePerPage: props.sizePerPage,
            totalRecords: props.totalRecords,
            search: props.search
        };
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, data, totalRecords }) => {
        const currentIndex = (page - 1) * sizePerPage;
        {
            var that = this;
            type == "sort" ?
                setTimeout(() => {
                    let requestHeaders = {
                        method: "get",
                        headers: new Headers({
                            "Content-Type": "application/json"
                        })
                    };
                    SendRequest({
                        url: "getworkspaceslist",
                        queryString: "?fromDate= " + " " + "&toDate= " + " " + "&index= " + currentIndex + "&noOfRecords=" + sizePerPage + "&searchCriteria=" + that.state.search + "&order=" + sortOrder + "&colName=" + sortField +  "&urltype=" + "getUserPaginationWorkspaces",
                        sucessFunction: (response) => {
                            if (response.hasOwnProperty('error')) {
                                $(".spinner").hide();
                            } else {
                                var headerlist = response["header"];
                                var data = response["data"];
                                _.each(data, function (val, index) {
                                    val.editWorkspace = <a href={`${that.props.url}/workspace/${btoa(val.workspace_id)}`} data-num={val.workspace_id} class="boc-snapshot-thumbnail reportview viewworkspace" tab-id="0" tab-name="" id="reportstoryboardview"><i class="fa fa-edit"></i></a>;
                                    val.deleteWorkspace = <button type="button" onClick={(e) => { that.props.deleteworkspace(index, e) }} id="deleteworkspacelistiew" workspace-id={val.workspace_id} datahub-id={val.hub_id} workspace-title={val.workspace_name} data-num={val.workspace_id} tab-id="0" tab-name="" class="btn btn-transparent aldelete"><i class="fa fa-trash-o"></i></button>;	
                                    val.createBusinessModel=<button type="button" class="btn btn-transparent createmodel" id={val.workspace_id} title={lang["workspace.createmodel"]}  onClick={that.props.createBusinessModel}><i class="fa fa-plus"></i></button>                                });

                                var keys = Object.keys(headerlist);
                                this.setState(() => ({
                                    page,
                                    data: data,
                                    sizePerPage,
                                    totalSize: totalRecords
                                }));
                            }
                        },
                        rejectFunction: () => { },
                        headers: requestHeaders
                    })
                }, 10) :
                setTimeout(() => {
                    let requestHeaders = {
                        method: "get",
                        headers: new Headers({
                            "Content-Type": "application/json"
                        })
                    };
                    SendRequest({
                        url: "getworkspaceslist",
                        queryString: "?fromDate= " + "" + "&toDate= " + "" + "&index= " + currentIndex + "&noOfRecords=" + sizePerPage + "&searchCriteria=" + that.state.search + "&order=" + sortOrder + "&colName=" + "" + "&urltype=" + "getUserPaginationWorkspaces",
                        sucessFunction: (response) => {
                            if (response.hasOwnProperty('error')) {
                                $(".spinner").hide();
                            } else {
                                var headerlist = response["header"];
                                var data = response["data"];
                                _.each(data, function (val, index) {
                                    val.editWorkspace = <a href={`${that.props.url}/workspace/${btoa(val.workspace_id)}`} data-num={val.workspace_id} class="boc-snapshot-thumbnail reportview viewworkspace" tab-id="0" tab-name="" id="reportstoryboardview"><i class="fa fa-edit"></i></a>;
                                    val.deleteWorkspace = <button type="button" onClick={(e) => { that.props.deleteworkspace(index, e) }} id="deleteworkspacelistiew" workspace-id={val.workspace_id} datahub-id={val.hub_id} workspace-title={val.workspace_name} data-num={val.workspace_id} tab-id="0" tab-name="" class="btn btn-transparent aldelete"><i class="fa fa-trash-o"></i></button>;	
                                    val.createBusinessModel=<button type="button" class="btn btn-transparent createmodel" id={val.workspace_id} title={lang["workspace.createmodel"]} onClick={that.props.createBusinessModel}><i class="fa fa-plus"></i></button>
                                });
                                var keys = Object.keys(headerlist);
                                this.setState(() => ({
                                    page,
                                    data: data,
                                    sizePerPage,
                                    totalSize: totalRecords
                                }));
                            }
                        },
                        rejectFunction: () => { },
                        headers: requestHeaders
                    })

                }, 10);
        }
    }

    render() {
        const { data, sizePerPage, page, totalRecords } = this.state;
        return (
                        <RemotePagination
                            data={data}
                            page={page}
                            sizePerPage={sizePerPage}
                            totalSize={totalRecords}
                           
                            onTableChange={this.handleTableChange}
                        />
                       
        );
    }
}