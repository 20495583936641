import _ from "underscore";
import * as ChartInfo from "../js/helpers/utils/chartinfo";
export var selectedworkspaceid = 0;
export var selectedWorkspaceMetaData = null;
export var entities = {};
export var allentitynames = [];
export function updateSelectedworkspaceId(wid) {
	selectedworkspaceid = wid;
}
export function getSelectedworkspaceId() {
	return selectedworkspaceid;
}
export function updateSelectedWorkspaceMetaData(metadata) {
	selectedWorkspaceMetaData = metadata;
}
export function getSelectedWorkspaceMetaData() {
	return selectedWorkspaceMetaData;
}
export function updateData(data) {
	var that = this;
	_.each(data, function (entity) {
		var entityName = entity.entityname;
		var columns = entity.columns;
		var customfields = entity.customfields;
		var connection_access_id = entity.connection_access_id;
		var entityType = entity.entitytype;
		var fact = entity.fact;
		var filters = entity.filters;
		var query = entity.query;
		allentitynames.push(entityName);
		var workspaceentityid = entity.workspaceentityid;
		if (entities[entityName + "&&&" + workspaceentityid] == undefined) {
			entities[entityName + "&&&" + workspaceentityid] = {};
		}
		that.updateEntityColumns(columns, entityName, workspaceentityid);
		that.updateEntityFilters(filters, entityName, workspaceentityid);
		that.updateEntityCustomFeilds(customfields, entityName, workspaceentityid);
		that.updateFact(fact, entityName, workspaceentityid);
		that.updateQuery(query, entityName, workspaceentityid);
		that.updateConnectionId(connection_access_id, entityName, workspaceentityid);
		that.updateEntityType(entityType, entityName, workspaceentityid);

	});
	try{
		var dataModelMetaDatavar = ChartInfo.functions.getDatamodelMetaData();
		if(dataModelMetaDatavar && !_.isEmpty(dataModelMetaDatavar) && dataModelMetaDatavar.entities){
			_.each(dataModelMetaDatavar.entities,function(i){
				if (i.entityType != undefined && i.entityType != "customquery") {
					that.updateEntityColumns(JSON.parse(i.columns), i.entityname, i.workspaceentityid);
				}
			});
		}
	}catch(e){}
}

export function updateFact(fact, entityName, workspaceentityid) {
	entities[entityName + "&&&" + workspaceentityid]["fact"] = fact;
}
export function updateQuery(query, entityName, workspaceentityid) {
	entities[entityName + "&&&" + workspaceentityid]["query"] = query;
}
export function updateConnectionId(connectionID, entityName, workspaceentityid) {
	entities[entityName + "&&&" + workspaceentityid]["connectionId"] = connectionID;
}
export function updateEntityType(entityType, entityName, workspaceentityid) {
	entities[entityName + "&&&" + workspaceentityid]["entityType"] = entityType;
}
export function updateEntityColumns(columns, entityName, workspaceentityid) {
	entities[entityName + "&&&" + workspaceentityid]["columns"] = columns;
}
export function updateEntityFilters(filters, entityName, workspaceentityid) {
	entities[entityName + "&&&" + workspaceentityid]["filters"] = filters;
}
export function updateEntityCustomFeilds(customfeilds, entityName, workspaceentityid) {
	entities[entityName + "&&&" + workspaceentityid]["customfeilds"] = customfeilds;
}
export function getQuery(entityName, workspaceentityid) {
	if (entities[entityName + "&&&" + workspaceentityid] == undefined) {
		entities[entityName + "&&&" + workspaceentityid] = {};
		return undefined;
	}
	else {
		return entities[entityName + "&&&" + workspaceentityid]["query"];
	}
}
export function getEntityType(entityName, workspaceentityid) {
	if (entities[entityName + "&&&" + workspaceentityid] == undefined) {
		entities[entityName + "&&&" + workspaceentityid] = {};
		return undefined;
	}
	else {
		return entities[entityName + "&&&" + workspaceentityid]["entityType"];
	}
}
export function getConnectionId(entityName, workspaceentityid) {
	if (entities[entityName + "&&&" + workspaceentityid] == undefined) {
		entities[entityName + "&&&" + workspaceentityid] = {};
		return undefined;
	}
	else {
		return entities[entityName + "&&&" + workspaceentityid]["connectionId"];
	}
}

export function getFact(entityName, workspaceentityid) {
	if (entities[entityName + "&&&" + workspaceentityid] == undefined) {
		entities[entityName + "&&&" + workspaceentityid] = {};
		return undefined;
	}
	else {
		return entities[entityName + "&&&" + workspaceentityid]["fact"];
	}
}
export function getEntityColumns(entityName, workspaceentityid) {
	if (entities[entityName + "&&&" + workspaceentityid] == undefined) {
		entities[entityName + "&&&" + workspaceentityid] = {};
		return undefined;
	}
	else {
		return entities[entityName + "&&&" + workspaceentityid]["columns"];
	}

}

export function getEntityFilters(entityName, workspaceentityid) {
	if (entities[entityName + "&&&" + workspaceentityid] == undefined) {
		entities[entityName + "&&&" + workspaceentityid] = {};
		return undefined;
	}
	else {
		return entities[entityName + "&&&" + workspaceentityid]["filters"];
	}
}

export function getEntityCustomFeilds(entityName, workspaceentityid) {
	if (entities[entityName + "&&&" + workspaceentityid] == undefined) {
		entities[entityName + "&&&" + workspaceentityid] = {};
		return undefined;
	}
	else {
		return entities[entityName + "&&&" + workspaceentityid]["customfeilds"];
	}
}
export function getAllEntityNames() {
	return allentitynames;
}

