import _ from 'lodash';
import React, { Component } from "react";
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import ReactDOM from "react-dom";
import CustomRangeTemplate from "./customrange.jsx"
const $ = window.$;

export default class CustomRangeDropdownView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicdiv: props.dynamicdiv,
            addcustomrangevalue: props.addcustomrangevalue,
            columnDisplayName: props.columnDisplayName,
            columnName: props.columnName,
            dataType: props.dataType,
            tableName: props.tableName,
            iscustomfunction: props.iscustomfunction,
        };
        this.render();
    }
    render() {
        ReactDOM.render(
            <CustomRangeDropDownTemplate
                dynamicdiv={this.state.dynamicdiv}
                addcustomrangevalue={this.state.addcustomrangevalue}
                columnDisplayName={this.state.columnDisplayName}
                columnName={this.state.columnName}
                dataType={this.state.dataType}
                tableName={this.state.tableName}
                iscustomfunction={this.state.iscustomfunction}
            ></CustomRangeDropDownTemplate>, document.getElementById(this.state.dynamicdiv));
        return (<div></div>);
    }
}
class CustomRangeDropDownTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        var that = this.props;
        return (<>
            {(that.dataType === 'number') ?
                // <!-- customrangedropdownview.js -->
                <li key={that.dynamicdiv} className="xs-list-item hierarchyitem" onClick={that.addcustomrangevalue} data-name={that.columnName} data-display-name={that.columnDisplayName} iscustomfunction={that.iscustomfunction} title={that.columnDisplayName} table-name={that.tableName} data-type={that.dataType}>
                    <span className="itemtype">123</span>
                    <span className="itemname">{that.columnDisplayName}</span>
                </li>
                : null}
        </>);
    }

}