import React from "react";
import SendRequest from '../SendRequest';
import $ from "jquery/dist/jquery";
import Properties from '../Properties/ServerProperties'

export default class ByPassLoginPageview extends React.Component {
    constructor(props) {
        super(props);
        // if ( window.location !== window.parent.location ) {
        // The page is in an iframe
        //     } else {
        // The page is not in an iframe
        //     }
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        var queryString = "?";
        var page = "";
        var urlparameters = window.location.search.replace("?", "").split("&");
        $.each(urlparameters, function (i, u) {
            var parameter = u.split("=");
            if (parameter[0] == "user") {
                queryString += parameter[0] + "=" + parameter[1];
            }
            if (parameter[0] == "page") {
                page = parameter[1];
            }
        });
        // if (queryString.length > 0 && queryString != "?") {
        //     queryString = queryString.substring(0, queryString.length - 1);
        // }

        SendRequest({
            url: "authenticate/bypassloginpage",
            queryString: queryString,
            sucessFunction: result => {
                window.localStorage.clear();
                window.localStorage.setItem("refreshToken", result.refreshToken);
                window.localStorage.setItem("accessToken", result.accessToken);
                window.localStorage.setItem("accessTokenExpiration", result.accessTokenExpiration);
                window.localStorage.setItem("serverTimezone", result.timezoneId);
                sessionStorage.setItem("accessToken", result.accessToken);

                var reg = new RegExp('^[0-9]+$');
                // if(reportId != "" && reportId != undefined && !reg.test(reportId)){
                //     reportId = atob(reportId)
                // }
                $("body").removeClass("datamainpage datahubpage dataworkspacepage datamodelspage datagovernancepage searchpage");
                $("body").removeClass("rightpanelopen");
                $("#main").removeClass("newreportpagescrolling");
                sessionStorage.removeItem("convertedtypes");
                sessionStorage.removeItem("isconvettedtypesapplied");
                sessionStorage.removeItem("pos");
                sessionStorage.removeItem("renameMeasuresorDimensions");
                sessionStorage.setItem("changesdone", 0);
                $('body').addClass('embedreportbdoy day');
                if (page != null && page != undefined && page != "businessapp") {
                    var href = "/" + Properties.appName + "/welcome/" + page + "?topheader=false";
                    window.open(href, '_self')
                }

            },
            rejectFunction: this.loginRejectFunction,
            headers: requestHeaders,
        });
    }
    render() {
        return <React.Fragment></React.Fragment>
    }
}
