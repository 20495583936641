import React, { Component } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import SendRequest from '../../SendRequest';
import { lang} from "../../js/helpers/utils/languages/language";
const $ = window.$;
/** classes added for numeric fields */
const columns = [
    { dataField: 'reportId', text:  lang["admin.reports.reportid"], sort: true ,align:'right',headerClasses:'align-right'},
    { dataField: 'reportName', text: lang["admin.reports.reportname"] , sort: true },
    { dataField: 'reportType', text: lang["admin.reports.reporttype"], sort: true },
    { dataField: 'FolderName', text: lang["admin.reports.foldername"], sort: true },
    { dataField: 'createdBy', text: lang["admin.reports.createdby"], sort: true },
    { dataField: 'sharedTo', text: lang["admin.reports.sharedto"] , sort: true },
    { dataField: 'dataModelName', text: lang["admin.reports.modelname"], sort: true },
    { dataField: 'workspaceName', text:lang["admin.reports.workspacename"], sort: true },
];
const RemotePagination = ({ data, page, sizePerPage, onTableChange, totalSize }) => (
    <div className="notifications-table-container" >
        <BootstrapTable
            remote
            keyField="reportId"
            data={data}
            columns={columns}
            pagination={paginationFactory({ page, sizePerPage, totalSize })}
            onTableChange={onTableChange}
        />
    </div>
);
export default class ReportManagementTable extends Component {
    constructor(props) {
      super(props);
      this.state = {
        page: 1,
        data: props.data,
        sizePerPage: props.sizePerPage,
        totalRecords: props.totalRecords,
        search: props.search,
      };
      this.state.search=props.search;
      var searchtext=this.state.search
    }
  
    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, data ,totalRecords}) => {
      const currentIndex = (page - 1) * sizePerPage;
      {
        var that= this;
        if (that.state.search == undefined){
          that.state.search  ="";
        }
        if(type != 'sort'){
              let requestHeaders = {
                method: "get",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
               SendRequest({
                url: "getReportsConfigDetails",
                queryString: "?index=" + currentIndex + "&noOfRecords=" + sizePerPage + "&searchCriteria=" + that.state.search + "&order=" + sortOrder + "&colName=" + "",
                sucessFunction: (response) => {
                    if (response.hasOwnProperty('error')) {
                        $(".spinner").hide();
                    } else {
                      var headerlist = response["header"];
                      var data = response["data"];
                    var keys = Object.keys(headerlist);
                    this.setState(() => ({
                      page,
                      data: data,
                      sizePerPage,
                      totalSize:totalRecords
                    }));
                    }
                  },
                  rejectFunction: () => { },
                  headers: requestHeaders
                })
        }
       if(type=="sort"){        
        let requestHeaders = {
          method: "get",
          headers: new Headers({
              "Content-Type": "application/json"
          })
      };
         SendRequest({
          url: "getReportsConfigDetails",
          queryString: "?index=" + currentIndex + "&noOfRecords=" + sizePerPage + "&searchCriteria=" + that.state.search + "&order=" + sortOrder + "&colName=" + sortField,
          sucessFunction: (response) => {
              if (response.hasOwnProperty('error')) {
                  $(".spinner").hide();
              } else {
                var headerlist = response["header"];
                var data = response["data"];

              var keys = Object.keys(headerlist);
              this.setState(() => ({
                page,
                data: data,
                sizePerPage,
                totalSize:totalRecords
              }));
              }
            },
            rejectFunction: () => { },
            headers: requestHeaders
          })

         
      }
    }
    }
  render() {
    const { data, sizePerPage, page,totalRecords } = this.state;
      var that = this.props;
      return (
            <RemotePagination
              data={data}
            page={page}
            sizePerPage={sizePerPage}
            totalSize={totalRecords}
            onTableChange={this.handleTableChange}
            />         
      );
    }
  }