import React from "react";
import _ from 'underscore';
import ReactDOM from 'react-dom';
import SaveUserDetails from "./saveuserdetails.js";
import { lang} from "../../js/helpers/utils/languages/language";
import SendRequest from '../../SendRequest';
const $ =window.$;

export default class UsersAccountFormHtml extends React.Component{
  constructor(props){
super(props);
this.state={};
  }
  saveuserdetails=(e)=>{
    var serialized = $('.row.in-back form').serializeArray();
    var s = '';
       var data = {};
       for(s in serialized){
           data[serialized[s]['name']] = serialized[s]['value']
       }
      var result=JSON.stringify(data);
      var finaldata=JSON.parse(result); 
	  var isValid = this.isValid(finaldata);
	  if(isValid) {
		var saveaccount = new SaveUserDetails();
	  }
}
isValid=(data)=>{
	var isValid = true;
	if($.trim($.trim(data.groupName))==""){
		$(".groupnameerror").removeClass('hidden');
		$(".groupnameerror").text(lang["admin.groups.namerror"]);
		isValid = false;
	}
	return isValid;
}
render(){
    var that=this.props;
    return (
      <div class="container-fluid nopadding">
        {/* <div class="alert alert-success usercreationsuccess"
                   role="alert">User Created Successfully</div>
      <div class="alert alert-danger usercreationerror" 
                  role="alert">Error, Please Contact your System Administrator.</div>
      <div class="alert alert-danger userexists" 
                  role="alert">User Login Name Already Exist.</div> */}
          <div class="row in-back">
          <div class="max-height-30vh ">
              <h2 class="boc-main-title">{lang["admin.groups.createtitle"]}</h2>
              <form class="form-horizontal col-xs-12 submitform">
				<div class="form-group form-group-sm form-group">
					<label for="groupName" class="col-sm-3 control-label important">{lang["admin.groups.createname"]}</label>
					<div class="col-sm-6">
						<input type="name" class="form-control" id="groupName"
							placeholder="" name="groupName" />
								<span class="help-block groupnameerror hidden"></span>
					</div>
				</div>
                <div class="form-group form-group-sm">
					<label for="groupDesc" class="col-sm-3 control-label ">{lang["admin.groups.createdescrptn"]}</label>
					<div class="col-sm-6">
						<textarea class="form-control textarea" rows="2" id="groupDesc" name="groupDesc" placeholder=""></textarea>
					</div>
				</div>
                
                <div class="form-group form-group-sm">
					<label for="" class="col-sm-3 control-label">{lang["admin.groups.status"]}</label>
					<div class="col-sm-8">
						<div>
							<input type="radio" id="1" name="status" value="A" defaultChecked/>{lang["admin.groups.statusact"]}&nbsp;&nbsp;&nbsp; 
							<input type="radio" id="2" name="status" value="I" />{lang["admin.groups.statusdact"]}
						</div>
					</div>
				</div>
                
                <div class="form-group form-group-sm">
					<div class="col-sm-offset-3 col-sm-6">
						<button type="button" class="btn btn-bird saveuser" onClick={this.saveuserdetails} id="saveusers"><i class="fa fa-check marg-righ-5"></i>{lang["admin.groups.addbtn"]}</button>
					</div>
				</div>
                </form>
        </div>
        
        </div>
  </div>
      );
  };
  }
