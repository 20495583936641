import React from "react";
import ReactDOM from 'react-dom';
import _ from 'lodash';
import CustomStroyBoardnewTileModelTemplate from './viewcustomstoryboardnewtabmodal.jsx'
import { lang } from "../js/helpers/utils/languages/language";
import SendRequest from '../SendRequest';
import * as ChartInfo from "../js/helpers/utils/chartinfo";
const $ = window.$;



export default class CustomStoryboardPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state={ }
  }
  renderpopup =()=>{
    var that= this;
    let requestHeaders = {
      method: "get",
      headers: new Headers({
          "Content-Type": "application/json"
      })
  };
  SendRequest({
      url: "getuserreports",
      queryString: "?isFromDashboard="+false,
      sucessFunction: reports => {
          that.state.reportCollection = reports;
          var previousReportIds = [];
          var allTabsDetails = ChartInfo.functions.getAllCSBchartdetails();
          if (allTabsDetails != undefined && allTabsDetails.length > 0) {
              _.each(allTabsDetails, function (i) {
                if(i!=undefined){
                  previousReportIds.push(i.reportid);
                }
              });
          }
          ReactDOM.render(<CustomStroyBoardnewTileModelTemplate closeNewTabModel={that.props.closeNewTabModel} addnewtab={that.props.newcharttile} reports={this.state.reportCollection} previousReportIds={previousReportIds} newtabname={""} />, document.querySelector('.popupcsb'));
        
        $(".report-select").chosen({
            no_results_text: lang["customboard.oops"],
            width: "100%",
            max_selected_options: 1,
            search_contains: true
        });
      }, rejectFunction: () => { },
      headers: requestHeaders
  });
  }
  render(){
      return(
          <div></div>
      )
  }
}