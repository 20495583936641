import React from "react";
import SendRequest from '../SendRequest';
import Storyboardview from './Storyboard.jsx'
import CustomStoryboards from '../CustomStoryboard/customstoryboards.jsx';
import $ from "jquery/dist/jquery";

export default class PublicStoryboardview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            renderReq: false
        }
        let requestHeaders = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        let queryString =window.location.search;

        SendRequest({
            url: "authenticate/public",
            queryString: queryString,
            sucessFunction: result => {
                //const history = useHistory();
                window.localStorage.clear();
                window.localStorage.setItem("refreshToken", result.refreshToken);
                window.localStorage.setItem("accessToken", result.accessToken);
                window.localStorage.setItem("accessTokenExpiration", result.accessTokenExpiration);
                window.localStorage.setItem("serverTimezone", result.timezoneId);
                sessionStorage.setItem("accessToken", result.accessToken);

                var reg = new RegExp('^[0-9]+$');
                // if(reportId != "" && reportId != undefined && !reg.test(reportId)){
                //     reportId = atob(reportId)
                // }
                $("body").removeClass("datamainpage datahubpage dataworkspacepage datamodelspage datagovernancepage searchpage");
                $("body").removeClass("rightpanelopen");
                $("#main").removeClass("newreportpagescrolling");
                sessionStorage.removeItem("convertedtypes");
                sessionStorage.removeItem("isconvettedtypesapplied");
                sessionStorage.removeItem("pos");
                sessionStorage.removeItem("renameMeasuresorDimensions");
                sessionStorage.setItem("changesdone", 0);
                $('body').addClass('embedreportbdoy day');
               this.setState({ renderReq: true })
            },
            rejectFunction: this.loginRejectFunction,
            headers: requestHeaders,
        });
    }
    render() {
        if(this.props.isFromCustomStoryboard){
            return (<div>{(this.state.renderReq) ? (
                <CustomStoryboards {...this.props} />
            ) : null}
            </div>);
        }
        else{
        return (<div>{(this.state.renderReq) ? (
            <Storyboardview {...this.props} />
        ) : null}
        </div>);
        }
    }
}
