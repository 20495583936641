import React, { Component } from "react";
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import { lang } from "../js/helpers/utils/languages/language";
import _ from 'lodash';
import ReactDOM from "react-dom";
const $ = window.$;


export default class DrilldownHierarchyDropDown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicdiv: props.dynamicdiv,
            columnDisplayName: props.columnDisplayName,
            columnName: props.columnName,
            dataType: props.dataType,
            multifact: props.multifact,
            facttables: props.facttables,
            multifactentities: props.multifactentities,
            iscustomfunction: props.iscustomfunction,
            tableName: props.tableName,
        }
        this.render();
    };
    render() {
        var that = this;
        var disable = false;
        if (this.state.multifact == "true" && $($(".customhierarchyattributes #itemdisplay")).length != 0 && $($(".customhierarchyattributes #itemdisplay")[0]).attr('data-name') != undefined) {
            var name = [];
            _.each($(".customhierarchyattributes #itemdisplay"), function (i) {
                name.push($(i).attr('data-name'));
            });
            var yentities = [];

            var customFields = JSON.parse(sessionStorage.getItem("customFields"));
            if (customFields != null && customFields != undefined) {
                _.each(name, function (nameval) {
                    var customFieldObject = $.grep(customFields, function (e) { return e.name == nameval });
                    if (customFieldObject != undefined && customFieldObject.length > 0) {
                        _.each(customFieldObject[0].usedFields, function (hierarchy) {
                            yentities.push(hierarchy);
                        });
                    } else {
                        yentities.push(nameval);

                    }
                })
            } else {
                yentities = name;
            }
            var show = false;
            var cfFields = [];
            if (that.state.columnName != undefined && customFields != null && customFields != undefined) {
                var customFieldObject = $.grep(customFields, function (e) { return e.name == that.state.columnName });
                if (customFieldObject != undefined && customFieldObject.length > 0) {
                    var isvalid = true;
                    _.each(customFieldObject[0].usedFields, function (hierarchy) {
                        cfFields.push(hierarchy.split(".")[0]);
                        disable = ChartInfo.functions.getAllowedEntitiesForInstantFilters(JSON.parse(that.state.multifactentities), hierarchy, undefined, yentities, undefined);
                        if (disable) {
                            isvalid = false;
                        }
                    });
                    if (isvalid) {
                        show = true;
                    }

                } else {
                    disable = ChartInfo.functions.getAllowedEntitiesForInstantFilters(JSON.parse(that.state.multifactentities), that.state.columnName, undefined, yentities, undefined);
                    if (!disable) {
                        show = true;
                    }

                }
            } else {
                disable = ChartInfo.functions.getAllowedEntitiesForInstantFilters(JSON.parse(that.state.multifactentities), that.state.columnName, undefined, yentities, undefined);
                if (!disable) {
                    show = true;
                }
            }

            var facttables = that.state.facttables;
            var ftable = undefined;
            _.each(yentities, function (y) {
                var tablenameTobeDisplayed = y.split(".")[0];
                if (facttables.indexOf(tablenameTobeDisplayed) != -1) {
                    ftable = tablenameTobeDisplayed;
                }
            });

            if (ftable != undefined && cfFields.length > 0) {
                _.each(cfFields, function (cf) {
                    if (cf != ftable && facttables.indexOf(cf) != -1) {
                        show = false;
                    }
                });

            } else if (ftable != undefined) {
                var tb = that.state.columnName.split(".")[0];
                if (tb != ftable && facttables.indexOf(tb) != -1) {
                    show = false;
                }
            }

            if (show) {
                ReactDOM.render(<HierarchyDropDownTemplate dynamicdiv={that.state.dynamicdiv} addcustomhierarchyvalue1={that.props.addcustomhierarchyvalue1}
                    columnDisplayName={that.state.columnDisplayName} columnName={that.state.columnName}
                    dataType={that.state.dataType} tableName={that.state.tableName} iscustomfunction={that.state.iscustomfunction}
                ></HierarchyDropDownTemplate>, document.getElementById(that.state.dynamicdiv));

            }
        } else {
            ReactDOM.render(<HierarchyDropDownTemplate addcustomhierarchyvalue1={this.props.addcustomhierarchyvalue1} dynamicdiv={this.state.dynamicdiv}
                columnDisplayName={this.state.columnDisplayName} columnName={this.state.columnName}
                dataType={this.state.dataType} tableName={this.state.tableName} iscustomfunction={this.state.iscustomfunction}
            ></HierarchyDropDownTemplate>, document.getElementById(this.state.dynamicdiv));
        }
        return (<div></div>);
    }
}

class HierarchyDropDownTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        var that = this.props;
        var dataIcon = lang["storyboard.abc"];
        if (that.dataType == "number") {
            dataIcon = "123";
        } else if (that.dataType == "date" || that.dataType == "datetime" || that.dataType === 'month' || that.dataType === 'day') {
            dataIcon = <i className='fa fa-calendar'></i>
        } else if (that.dataType == "customhierarchy") {
            dataIcon = <i className='fa fa-list'></i>
        }
        return (
            <li key={that.dynamicdiv} className="xs-list-item hierarchyitem" onClick={that.addcustomhierarchyvalue1} iscustomfunction={that.iscustomfunction} data-name={that.columnName} data-display-name={that.columnDisplayName} title={that.columnDisplayName} table-name={that.tableName} data-type={that.dataType}>
                <span className="itemtype">{dataIcon}</span>
                <span className="itemname">{that.columnDisplayName}</span>
            </li>
        );
    }
}