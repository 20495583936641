import React from "react";
import ReactDOM from "react-dom"
import * as collection from "../js/helpers/utils/SavedReportCollection";
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import _ from 'lodash';
import PubSub from 'pubsub-js'
import $ from 'jquery/dist/jquery';
import ViewReportAccordianView from "./viewreportaccordianview"


export default class ViewreportFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            features: props.features,
            filterArray: props.filterArray,
            accordian: true,
            isFromStoryBook: props.isFromStoryBook == undefined ? false : props.isFromStoryBook,
            tabid: props.tabid == undefined ? 0 : props.tabid,
            isCustomParameter: props.isCustomParameter
        };
        this.addfilteritemEve = PubSub.subscribe('addfilteritem', this.addfilteritemEve.bind(this));
        this.render();
    }
    componentDidMount(){
        //PubSub.unsubscribe(this.addfilteritemEvee);
    }
    render() {
        this.href = window.location.pathname;
        var that = this;
        if (this.href.toLowerCase().indexOf("sharereport") >= 0) {
            that.state.accordian = false;
            $.each(this.state.features, function (index, featuresModule) {
                if (featuresModule.moduleName == 'story_board') {
                    $.each(featuresModule.features, function (index, feature) {
                        if (feature.featureName == 'viewreport_filters' && feature.allow == true) {
                            that.state.accordian = true;
                        }
                    });
                }
            });
        }
        return (<div></div>)
    }
    addfilteritemEve(msg, data) {
        this.addfilteritem(data.filterDisplayName, data.filterIgnore, data.tableName, data.columnName, data.columnDisplayName, data.columnType, data.fromrange, data.torange, data.filterObject, data.datamodelId, data.entities, data.daterange,
            data.parametervaluetype, data.elelement, data.className, data.sharedFilters, data.filterid, data.indexName, data.esType);
        }
    addfilteritem = (filterDisplayName, filterIgnore, tableName, columnName, columnDisplayName, columnType, fromrange, torange, filterObject, datamodelId, entities, daterange,
        parametervaluetype, elelement, className, sharedFilters, filterid, indexName, esType) => {
        if (this.state.accordian) {
            var formula = null;
            var sqlFormula = null;
            var customFieldObj = [];
            var isCustomField = false;
            var customFieldIndex = 0;
            var isFromGovernance = false;
            if (window.location.pathname.indexOf("governance")>=0) {
                isFromGovernance = true;
            }
            var customFieldsArray = sessionStorage.getItem("customFields");
            var temp = JSON.parse(customFieldsArray);
            var res;
            _.each(temp, function (customfield) {
                _.each(customfield.parametrFieldsArray, function (value) {
                    res = $.grep(ChartInfo.functions.getCustomParameter(), function (e) {
                        return e.parametername == value;
                    })
                    var formula = customfield.formula;
                    var scriptcondition = customfield.scriptcondition;
                    var fields = customfield.fields;
                    var sqlformula = customfield.sqlformula;
                    if (res.length != 0) {
                        customfield.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
                        customfield.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
                        customfield.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
                        customfield.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
                    } else {
                        _.each(ChartInfo.functions.getCustomParameter(), function (values) {
                            if (customfield.parametrFieldsArray.indexOf(values.parametername) >= 0) {
                                customfield.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                customfield.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                customfield.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
                                customfield.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                            }
                        });
                    }
                });
            });
            customFieldsArray = JSON.stringify(temp);
            var customFieldsParsedArray = [];
            if (customFieldsArray != "null" && customFieldsArray != undefined) {
                customFieldsParsedArray = JSON.parse(customFieldsArray);
            }
            $.each(customFieldsParsedArray, function (index, customField) {
                if (customField.name == columnName) {
                    isCustomField = true;
                    customFieldIndex = index;
                }
            });
            if (isCustomField == true) {
                var customFieldObject = customFieldsParsedArray[customFieldIndex];
                formula = customFieldObject["formula"];
                sqlFormula = customFieldObject["sqlformula"];
                customFieldObj.push(customFieldObject);
            }
            var viewReportAccordianView = new ViewReportAccordianView({
                tableName: tableName,
                columnName: columnName,
                filterDisplayName: filterDisplayName,
                filterIgnore: filterIgnore,
                columnDisplayName: columnDisplayName,
                columnType: columnType,
                fromrange: fromrange,
                torange: torange,
                filterArray: this.state.filterArray,
                isFromStoryBook: this.state.isFromStoryBook,
                tabid: this.state.tabid,
                id: columnDisplayName,
                schedule: this.props.schedule,
                filterObject: filterObject,
                script: formula,
                datamodelId: datamodelId,
                entities: entities,
                daterange: daterange,
                parametervaluetype: parametervaluetype,
                elelement: elelement,
                className: className,
                sharedFilters: sharedFilters,
                filterid: filterid,
                indexName: indexName,
                esType: esType,
                isFromGovernance: isFromGovernance,
                sqlFormula: sqlFormula,
                customFields: JSON.stringify(customFieldObj),
                mlrequestfrom: this.props.mlrequestfrom
            });
        }
    }
}