import React, { Component } from "react";
import SendRequest from '../../SendRequest';
import { getMessage } from '../../js/helpers/utils/BirdMessage';
import { render } from "@testing-library/react";
const $ =window.$;

export default class DeleteRolesView extends Component {
  constructor(props) {
    super(props);
      this.state = { };
      this.state.roleId=props.roleId;
      this.deleterole();
      this.render();
  }
  deleterole (){
    var that=this;
    $(".deleteyes").unbind( "click" );
    $(".deleteno").unbind( "click" );
    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
    $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
    $('.birdmessagebuttons').addClass('show');
    $('.birdmessage-center').addClass("message-top-in");
    $('.details').removeClass('show');
    $('.details').addClass('hide');
    $('.birdmessage h2').empty().append('Warning');
    $('.birdmessage-info').empty().text(getMessage('BirdWarning22'));
     $( ".deleteyes" ).click(function() {
     
        let requestHeaders = {
            method: 'post',
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
           SendRequest({
            url: "deleterole",
            queryString: "?roleId="+that.state.roleId,
            sucessFunction: (response) => {
                if (response.hasOwnProperty('error')) {
                    $(".spinner").hide();
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('hide');
                    $('.birdmessage h2').empty().append('Error : ');
                    $('.birdmessage h2').append('<span class="errorcode">');
                    $('.birdmessage .errorcode').empty().append(response.errorCode);
                    $('.birdmessage-info').empty().text(response.error);
                    $('.details').addClass('show');
                    $('.messagedetails xmp').empty().append(response.errorTrace)
                } else {
               $(".spinner").hide();
               $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
               $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
               $('.birdmessagebuttons').removeClass('show');
               $('.birdmessagebuttons').addClass('hide');
               $('.birdmessage-center').addClass("message-top-in");
               $('.details').removeClass('show');
               $('.details').addClass('hide');
               $('.birdmessage h2').empty().append('Success');
               $('.birdmessage-info').empty().text(getMessage('BirdSuccess14'));
               setTimeout(function() {
                $('.birdmessage-container .close').click();	
                }, 3000);
                setTimeout(function() {
                  window.location.reload(false);
                   },3000);
                }
              },
              rejectFunction: () => { },
              headers: requestHeaders
            })
     })
     $( ".deleteno" ).click(function() {
        $('.birdmessage-center').removeClass("message-top-in");
       });
  }
  render(){
      return (<div></div>)
  }
}