import React from 'react';
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;

export default class ScheduleReportModel extends React.Component {
    componentDidMount() {
        $('.weeks').click(function (e) {
            var currentTarget = $(e.currentTarget);
            if ($(".weeks:checked").length == 0) {
                currentTarget.prop("checked", true);
            }
        })
    }
    render() {
        let that = this.props;
        var name = lang["storyboard.schedulereport"];
        if($(".singlereportview.maxanim1").length>0){
            name = lang["reports.schedulereport"];
        }
        return (
            // <!-- viewschedulereport.js -->
            <div className="modal fade schedulereportmodal in" id="schedulereportmodal" tabIndex="-1" role="dialog" aria-labelledby="schedulereportmodal" aria-hidden="true" style={{ display: "block" }}>
                <div className="modal-backdrop fade in"></div>
                <div className="modal-dialog modal-lg modal-dialog-lg-center modal-dialog-center" style={{ zIndex: "1050" }}>
                    <div className="modal-content">
                        <div className="modal-header boc-modal-hf-padding">
                            <button type="button" className="close white" id="scheduleclose" onClick={this.props.close} aria-hidden="true" data-dismiss="modal">&times;</button>
                            <h4 className="modal-title"><i className="fa fa-clock-o"></i> {name}</h4>
                        </div>
                        <div className="modal-body scrollsetnew">
                            <div className="alert alert-success sendschedulereportsuccess" style={{ display: "none" }} role="alert">{lang["storyboard.reportsuccess"]}</div>
                            <div className="alert alert-danger dateselection" style={{ display: "none" }} role="alert">{lang["storyboard.reporterror"]}</div>
                            <div className="alert alert-danger sendschedulereporterror" style={{ display: "none" }} role="alert">{lang["storyboard.reportfailed"]}</div>
                            <div className="alert alert-danger mailconfigerrmessage" style={{ display: "none" }} role="alert">{lang["storyboard.mailconfig"]}</div>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="row-same-height">
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-xs-height settingscontent">
                                            <div className="modal-height-fix">
                                                <div className="col-xs-12">
                                                    <span className="required right legend"></span>
                                                    <legend className="subhead">{lang["storyboard.to"]}</legend>
                                                    <div className="scheduletousers" id="selectemailuser">
                                                        <ul className="tagit-select tag-select tagit ui-sortable"></ul>
                                                        <span className="users-select-chosen">
                                                        <select name="grouping" data-placeholder={lang["storyboard.slctuser"]} className="users-select  modal-form-fields" id="groupings"  multiple tabIndex="6">
                                                            <optgroup className="userids" label={lang["storyboard.users"]}>
                                                             {this.props.users.map((user, index) => {
                                                                 if (!user.isLoginUser) {
                                                                        return <option key={index} value={user.userid} userEmail={user.email}>{`${user.username} (${user.email})`}</option>
                                                                    }
                                                             })}
                                                            </optgroup>
                                                        </select>
                                                        <span class="help-block grouperror hidden"></span>
                                                        <button class="btn btn-link pull-right btn-sm showadvanced" onClick={that.showadvanced}>{lang["admin.users.advncd"]}</button>
						                                <div class="row marg-bot-10 filtersearching " style={{ display: "none" }}>
                                                            <div className="stringfilters searching ">
                                                                {lang["storyboard.startswith"]} <input type="text" id="searchnamestartswith" data-id="searchnamestartswith" className="searchword" />
                            								</div>
                            								<div className="stringfilters searching">
                                                                {lang["storyboard.endswith"]} <input type="text" id="searchnameendswith" data-id="searchnameendswith"/>
                            								</div>
                            								<div className="stringfilters searching col-lg-10 col-md-10 col-sm-12 col-xs-12" style={{marginLeft:"-5px"}}>
                                                                {lang["storyboard.contains"]} <input type="text" id="searchnamecontains" data-id="searchnamecontains"/>
                            								</div>
                                                            <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12" style={{marginTop: "1px"}}>
                            									<button type="button" id="searchnames" className="btn btn-sm btn-bird marg-top-20 btn-highlight" onClick={that.searchusersgroups}>{lang["storyboard.go"]}</button>
                            								  </div>
                                                        </div>
                                                        </span>
                                                        <div className="scheduleerror ">
                                                            <div className="toemailusers" style={{ display: "none", marginTop: "-15px", marginLeft: "-35px", color: "#a94442" }} role="alert">{lang["storyboard.plzslctuser"]}</div>
                                                            <div className="customemails" style={{ display: "none", marginTop: "-15px", marginLeft: "-35px", color: "#a94442" }} role="alert">{lang["storyboard.validemail"]}</div>
                                                        </div>
                                                        <span className="help-block hidden"></span>
                                                    </div>
                                                    <div className="clear"></div>
                                                    <legend className="subhead">{lang["storyboard.subject"]}</legend>
                                                    <input type="name" id="titlenamesub" className="modal-form-fields titlename" placeholder={lang["storyboard.schedulereport"]} defaultValue={this.props.reportName} />
                                                    <input type="hidden" className="form-control emailfrom" id="frommail" placeholder="" data-name={this.props.scheduleReportModel.fromEmail} disabled="disabled" />
                                                    <div className="clear"></div>
                                                    <div className="form-group">
                                                        <label for="urlaccess" className="subhead">{lang["storyboard.permission"]}</label>
                                                        <div className="col-xs-12">
                                                            <div className="marg-left-10 marg-top-10">
                                                                <label className="marg-righ-10"><input type="radio" id="1" name="with login" onClick={this.changPublishurlForSchedule} className="publishurlforschedule login" data-value="with login" defaultChecked="checked" value="A" purl={this.props.scheduleReportModel.private} /> {lang["storyboard.login"]}</label>
                                                                <label><input type="radio" id="2" name="with login" onClick={this.changPublishurlForSchedule} className="publishurlforschedule login" data-value="without login" value="I" purl={this.props.scheduleReportModel.public} /> {lang["storyboard.outlogin"]}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="clear"></div>
                                                    <legend className="subhead">{lang["storyboard.message"]}</legend>
                                                    <textarea placeholder={lang["storyboard.descriptionhere"]} disabled="disabled" id="scheduleurl" className="modal-form-fields resize-vertical"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="gap"></div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-xs-height settingscontent">
                                            <div className="modal-height-fix">
                                                <div className="col-xs-12">
                                                    <div className="modalaxiscontainer">
                                                        <legend className="subhead">{lang["storyboard.schedule"]}</legend>
                                                        <select className="modal-form-fields schedule" onChange={this.props.scheduleDropDown} id="schedules">
                                                            <option value="once" selected="selected">{lang["storyboard.once"]}</option>
                                                            <option value="daily">{lang["storyboard.daily"]}</option>
                                                            <option value="weekly" >{lang["storyboard.weekly"]}</option>
                                                            <option value="monthly">{lang["storyboard.monthly"]}</option>
                                                        </select>
                                                        <div className="clear"></div>
                                                    </div>
                                                    <div className="scheduleatefields hide">
                                                        <div className="modalaxiscontainer nomargin">
                                                            <span className="right legend"></span>
                                                            <legend className="subhead">{lang["storyboard.datefield"]}
												<button
                                                                    className="pull-right btn btn-bird btn-sm tooltip-left dropdown-toggle"
                                                                    data-tooltip={lang["storyboard.add"]} id="scheduledatefielddropdown" onClick={this.props.scheduledatefield}
                                                                    data-toggle="dropdown" aria-expanded="false" style={{ top: "-6px" }}>
                                                                    <i className="fa fa-plus"></i>
                                                                </button>
                                                                <ul className="list-group dropdown-menu scrollsetul dropdown-menu-right scheduledatedropdown" id="scheduledatedropdowns" aria-labelledby="dropdownxaxis" role="menu"></ul>
                                                            </legend>
                                                            <div className="alert alert-danger scheduledatefield" style={{ display: "none", padding: "5px" }} role="alert">{lang["storyboard.dateerror"]}</div>
                                                            <div className="clear"></div>
                                                            <div className="">
                                                                <ul className="list-group scheduledatefieldsAttributes entitytype"></ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="clear"></div>
                                                    {/* <!-- Once Container --> */}
                                                    <div className="schedulecontainer once ">
                                                        <div className="modalaxiscontainer">
                                                            <form>
                                                                <span className="required right legend"></span>
                                                                <legend className="subhead marg-top-5">{lang["storyboard.date"]}</legend>
                                                                <div className="input-group schedule-oncedate input-group-sm">
                                                                    <input type="text" name="startdate" id="startdate" placeholder={lang["storyboard.date"]} />
                                                                    <div className="input-group-addon"><i className="fa fa-calendar"></i></div>
                                                                </div>
                                                                <div className="scheduleerror ">
                                                                    <div className="alert alert-danger sendschedulereportdate" style={{ display: "none" }} role="alert">{lang["storyboard.slctdate"]}</div>
                                                                </div>
                                                                <div className="clear"></div>
                                                                <span className="required right legend"></span>
                                                                <legend className="subhead marg-top-5">{lang["storyboard.time"]}</legend>
                                                                <div className="input-group schedule-oncetime input-group-sm marg-bot-10">
                                                                    <input type="text" name="starttime" id="starttime" placeholder={lang["storyboard.time"]} className="form-control" />
                                                                    <div className="input-group-addon"><i className="fa fa-clock-o"></i></div>
                                                                </div>
                                                                <div className="scheduleerror">
                                                                    <div className="alert alert-danger sendschedulereporttime" style={{ display: "none" }} role="alert">{lang["storyboard.slcttime"]}</div>
                                                                </div>
                                                            </form>
                                                            <div className="clear"></div>
                                                        </div>
                                                    </div>{/*<!--/Once Container -->*/}
                                                    {/* <!-- Daily Container -->	 */}
                                                    <div className="schedulecontainer daily ">
                                                        <div className="modalaxiscontainer">
                                                            <form>
                                                                <span className="appetitle">
                                                                    <input type="radio" name="daysoption" id="everydayy" className="appetitle" defaultChecked="checked" data-name="every" />
                                                                   {lang["storyboard.every"]}
                                                    </span>
                                                                <input type="number" max="10" min="1" value="1" id="selecteddays" className="modal-form-fields scheduleevery" />
                                                                <span className="appetitle">{lang["storyboard.days"]}</span>
                                                                <div className="clear"></div>
                                                                <span className="appetitle">
                                                                    <input type="radio" name="daysoption" id="everydayy" className="appetitle" data-name="everyweekday" />
                                                                    {lang["storyboard.everyweekday"]}
                                                    </span>
                                                                <div className="clear"></div>
                                                                <span className="required right legend"></span>
                                                                <legend className="subhead">{lang["storyboard.startime"]}</legend>
                                                                <div className="input-group starttime input-group-sm">
                                                                    <input type="text" className="subhead" name="dailystartdate" id="dailystartdate" placeholder={lang["storyboard.date"]} />
                                                                    <div className="input-group-addon"><i className="fa fa-calendar"></i></div>
                                                                </div>
                                                                <div className="input-group starttime input-group-sm">
                                                                    <input type="text" className="subhead form-control" name="dailystarttime" id="dailystarttime" placeholder={lang["storyboard.time"]} />
                                                                    <div className="input-group-addon"><i className="fa fa-clock-o"></i></div>
                                                                </div>
                                                                <div className="clear"></div>
                                                                <div className="scheduleerror" style={{ width: "100%" }}>
                                                                    <div className="alert alert-danger sendschedulereportdailystartdate" style={{ display: "none", width: "auto !important", float: "left", marginRight: "20px", marginBottom: "0 !important" }} role="alert">{lang["storyboard.slctdate"]}</div>
                                                                    <div className="alert alert-danger sendschedulereportdailystarttime" style={{ display: "none", width: "auto !important", float: "left", marginBottom: "0 !important" }} role="alert">{lang["storyboard.slcttime"]}</div>
                                                                </div>
                                                                <div className="clear"></div>
                                                                <span className="required right legend"></span>
                                                                <legend className="subhead marg-top-5">{lang["storyboard.endtime"]}</legend>
                                                                <div className="input-group endtime input-group-sm">
                                                                    <input type="text" className="subhead" name="dailyenddate" id="dailyenddate" placeholder={lang["storyboard.date"]} />
                                                                    <div className="input-group-addon"><i className="fa fa-calendar"></i></div>
                                                                </div>
                                                                <div className="input-group endtime input-group-sm">
                                                                    <input type="text" className="subhead form-control" name="dailyendtime" id="dailyendtime" placeholder={lang["storyboard.time"]}/>
                                                                    <div className="input-group-addon"><i className="fa fa-clock-o"></i></div>
                                                                </div>
                                                                <div className="clear"></div>
                                                                <div className="scheduleerror " style={{ width: "100%" }}>
                                                                    <div className="alert alert-danger sendschedulereportdailyenddate" style={{ display: "none", width: "auto !important", float: "left", marginRight: "20px", marginBottom: "0 !important" }} role="alert">{lang["storyboard.slctdate"]}</div>
                                                                    <div className="alert alert-danger sendschedulereportdailyendtime" style={{ display: "none", width: "auto !important", float: "left", marginBottom: "0 !important" }} role="alert">{lang["storyboard.slcttime"]}</div>
                                                                </div>
                                                            </form>
                                                            <div className="clear"></div>
                                                        </div>
                                                    </div>{/* <!--/Daily Container -->*/}
                                                    {/* <!-- Weekly Container --> */}
                                                    <div className="schedulecontainer weekly">
                                                        <div className="modalaxiscontainer">
                                                            <form>
                                                                {/* <!-- <span className="appetitle every">Every</span>
													<input type="number" max="10" min="1" value="1" id="selectedeveryday" className="modal-form-fields scheduleevery">
													<span className="appetitle">Week(s)</span> --> */}
                                                                <div className="clear "></div>
                                                                <label><input type="checkbox" className="appetitle weeks" data-name="SUN" /><span className="days">{lang["storyboard.sunday"]}</span></label>
                                                                <label><input type="checkbox" className="appetitle weeks" data-name="MON" /><span className="days">{lang["storyboard.monday"]}</span></label>
                                                                <label><input type="checkbox" className="appetitle weeks" data-name="TUE" /><span className="days">{lang["storyboard.tuesday"]}</span></label>
                                                                <label><input type="checkbox" className="appetitle weeks" data-name="WED" /><span className="days">{lang["storyboard.wednesday"]}</span></label>
                                                                <div className="clear"></div>
                                                                <label>
                                                                    <input type="checkbox" className="appetitle weeks" data-name="THU" />
                                                                    <span className="days">{lang["storyboard.thursday"]}</span>
                                                                </label>
                                                                <label>
                                                                    <input type="checkbox" className="appetitle weeks" data-name="FRI" />
                                                                    <span className="days">{lang["storyboard.friday"]}</span>
                                                                </label>
                                                                <label>
                                                                    <input type="checkbox" className="appetitle weeks" data-name="SAT" />
                                                                    <span className="days">{lang["storyboard.saturday"]}</span>
                                                                </label>
                                                                <div className="clear"></div>
                                                                <span className="required right legend"></span>
                                                                <legend className="subhead">{lang["storyboard.startime"]}</legend>
                                                                <div className="input-group starttime input-group-sm">
                                                                    <input type="text" className="" id="weeklystartdate" placeholder="Date" />
                                                                    <div className="input-group-addon"><i className="fa fa-calendar"></i></div>
                                                                </div>
                                                                <div className="input-group starttime input-group-sm">
                                                                    <input type="text" className="form-control" id="weeklystarttime" placeholder="Time" />
                                                                    <div className="input-group-addon"><i className="fa fa-clock-o"></i></div>
                                                                </div>
                                                                <div className="clear"></div>
                                                                <div className="scheduleerror" style={{ width: "100%" }}>
                                                                    <div className="alert alert-danger sendschedulereportweeklystartdate" style={{ display: "none", width: "auto !important", float: "left", marginRight: "20px", marginBottom: "0 !important" }} role="alert">{lang["storyboard.slctdate"]}</div>
                                                                    <div className="alert alert-danger sendschedulereportweeklystarttime" style={{ display: "none", width: "auto !important", float: "left", marginBottom: "0 !important" }} role="alert">{lang["storyboard.slcttime"]}</div>
                                                                </div>
                                                                <div className="clear"></div>
                                                                <span className="required right legend"></span>
                                                                <legend className="subhead marg-top-5">{lang["storyboard.endtime"]}</legend>
                                                                <div className="input-group endtime input-group-sm">
                                                                    <input type="text" className="" id="weeklyenddate" placeholder={lang["storyboard.date"]}/>
                                                                    <div className="input-group-addon"><i className="fa fa-calendar"></i></div>
                                                                </div>
                                                                <div className="input-group endtime input-group-sm">
                                                                    <input type="text" className="form-control" id="weeklyendtime" placeholder={lang["storyboard.time"]} />
                                                                    <div className="input-group-addon"><i className="fa fa-clock-o"></i></div>
                                                                </div>
                                                                <div className="clear"></div>
                                                                <div className="scheduleerror" style={{ width: "100%" }}>
                                                                    <div className="alert alert-danger sendschedulereportweeklyenddate" style={{ display: "none", width: "auto !important", float: "left", marginRight: "20px", marginBottom: "0 !important" }} role="alert">{lang["storyboard.slctdate"]}</div>
                                                                    <div className="alert alert-danger sendschedulereportweeklyendtime" style={{ display: "none", width: "auto !important", float: "left", marginBottom: "0 !important" }} role="alert">{lang["storyboard.slcttime"]}</div>
                                                                </div>
                                                            </form>
                                                            <div className="clear"></div>
                                                        </div>
                                                    </div>{/* <!--/Weekly Container --> */}
                                                    {/* <!-- Monthly Container --> */}
                                                    <div className="schedulecontainer monthly">
                                                        <div className="modalaxiscontainer">
                                                            <form>
                                                                <span className="appetitle">{lang["storyboard.day"]}</span>
                                                                <select className="modal-form-fields scheduleevery" id="dayofmonth" data-name="dayofmonth" type="number">
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                    <option value="6">6</option>
                                                                    <option value="7">7</option>
                                                                    <option value="8">8</option>
                                                                    <option value="9">9</option>
                                                                    <option value="10">10</option>
                                                                    <option value="11">11</option>
                                                                    <option value="12">12</option>
                                                                    <option value="13">13</option>
                                                                    <option value="14">14</option>
                                                                    <option value="15">15</option>
                                                                    <option value="16">16</option>
                                                                    <option value="17">17</option>
                                                                    <option value="18">18</option>
                                                                    <option value="19">19</option>
                                                                    <option value="20">20</option>
                                                                    <option value="21">21</option>
                                                                    <option value="22">22</option>
                                                                    <option value="23">23</option>
                                                                    <option value="24">24</option>
                                                                    <option value="25">25</option>
                                                                    <option value="26">26</option>
                                                                    <option value="27">27</option>
                                                                    <option value="28">28</option>
                                                                    <option value="29">29</option>
                                                                    <option value="30">30</option>
                                                                    <option value="31">31</option>
                                                                </select>
                                                                <span className="appetitle">{lang["storyboard.everymonth"]}</span>
                                                                <div className="clear"></div>
                                                                <span className="required right legend"></span>
                                                                <legend className="subhead">{lang["storyboard.startime"]}</legend>
                                                                <div className="input-group starttime input-group-sm">
                                                                    <input type="text" className="" id="monthlystartdate" placeholder={lang["storyboard.date"]} />
                                                                    <div className="input-group-addon"><i className="fa fa-calendar"></i></div>
                                                                </div>
                                                                <div className="input-group starttime input-group-sm">
                                                                    <input type="text" className="form-control" id="monthlystarttime" placeholder={lang["storyboard.time"]} />
                                                                    <div className="input-group-addon"><i className="fa fa-clock-o"></i></div>
                                                                </div>
                                                                <div className="clear"></div>
                                                                <div className="scheduleerror" style={{ width: "100%" }}>
                                                                    <div className="alert alert-danger monthlystartdates" style={{ display: "none", width: "auto !important", float: "left", marginRight: "20px", marginBottom: "0 !important" }} role="alert">{lang["storyboard.slctdate"]}</div>
                                                                    <div className="alert alert-danger monthlystarttimes" style={{ display: "none", width: "auto !important", float: "left", marginBottom: "0 !important" }} role="alert">{lang["storyboard.slcttime"]}</div>
                                                                </div>
                                                                <span className="required right legend"></span>
                                                                <legend className="subhead marg-top-5">{lang["storyboard.endtime"]}</legend>
                                                                <div className="input-group endtime input-group-sm">
                                                                    <input type="text" className="" id="monthlyenddate" placeholder={lang["storyboard.date"]} />
                                                                    <div className="input-group-addon"><i className="fa fa-calendar"></i></div>
                                                                </div>
                                                                <div className="input-group endtime input-group-sm">
                                                                    <input type="text" className="form-control" id="monthlyendtime" placeholder={lang["storyboard.time"]}/>
                                                                    <div className="input-group-addon"><i className="fa fa-clock-o"></i></div>
                                                                </div>
                                                                <div className="clear"></div>
                                                                <div className="scheduleerror" style={{ width: "100%" }}>
                                                                    <div className="alert alert-danger monthlyenddates" style={{ display: "none", width: "auto !important", float: "left", marginRight: "20px", marginBottom: "0 !important" }} role="alert">{lang["storyboard.slctdate"]}</div>
                                                                    <div className="alert alert-danger monthlyendtimes" style={{ display: "none", width: "auto !important", float: "left", marginBottom: "0 !important" }} role="alert">{lang["storyboard.slcttime"]}</div>
                                                                </div>
                                                            </form>
                                                            <div className="clear"></div>
                                                        </div>
                                                    </div>
                                                    {/* <!--/Monthly Container --> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer boc-modal-hf-padding">
                            <button type="button" className="btn btn-bird" onClick={this.props.close} id="btn-birdviewschedule" data-dismiss="modal"><i className="fa fa-times"></i> {lang["storyboard.cancelbtn"]}</button>
                            <button type="button" className="btn btn-bird applysetting" onClick={this.props.applyScheduleReportModel} id="schedulereportbutton"><i className="fa fa-check"></i> {lang["storyboard.applybtn"]}</button>
                        </div>
                    </div>
                    {/* <!-- /.modal-content --> */}
                </div>
                {/* <!-- /.modal-dialog --> */}
            </div>
            // <!-- /.Page Settings Modal -->
        );
    }
    changPublishurlForSchedule = (e) => {
        var $this = e.currentTarget;
        $('#scheduleurl').html($($this).attr('purl'));
        if($(".publishurlforschedule.login:checked").attr('data-value')=="with login"){
            $("#selectemailuser ul.tagit-select.tag-select.tagit.ui-sortable").hide();
            $(".users-select-chosen").show();
        }else if($(".publishurlforschedule.login:checked").attr('data-value')=="without login"){
            $(".users-select-chosen").hide()
            $("#selectemailuser ul.tagit-select.tag-select.tagit.ui-sortable").show();
        }
    }
}