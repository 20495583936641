import React from "react";

export default class FutureValuesForForecastingTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        var that=this.props;
        return (
            <div className="form-inline" data-name={that.columnName}>
                <div className="form-group">
                    <span className="itemname " displayName={that.displayName}
                        columnName={that.columnName}
                        dataType={that.dataType}
                        tableName={that.tableName}
                        value={that.columnName}
                        title={that.displayName}><i className="fa fa-sort-numeric-asc marg-righ-10"></i>{that.displayName}</span>

                </div>
                <div className="form-group futurevalue">
                    <input type="number" name="title"
                        defaultValue={(that.value==undefined) ? '':that.value }
                        className="form-control singlevalue input-sm" autocomplete="off" />
                </div>
            </div>
        )
    }
}