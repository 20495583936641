import React from "react";
import { lang } from "../../js/helpers/utils/languages/language";
export default class ExcludeColumnsDropdownTemplate extends React.Component{
    constructor(props){
        super(props);
        this.state={};
    }
    render(){
        var that = this.props;
        return(
            <li key={that.dynamicdiv} id="itemdisplay" className={`boc-list-item singleliitems ${this.props.classNameToAppend}`} data-name={this.props.columnName}  xaxisidentity={this.props.xaxisidentity}  iscustomfunction={this.props.customfunction} title={this.props.columnDisplayName} table-name={this.props.tableName} data-type={this.props.dataType} onClick={this.props.addTextvalue!=undefined?this.props.addTextvalue:this.props.addDatevalue}>
            <span className="itemtype">
                {that.customfunction===1 && that.customfunction!=undefined?
                    <span className="function">{lang["storyboard.fx"]}</span>
                :    
                that.customfunction===3?	
                    <i className="fa fa-arrows-h"></i>
                :
                that.dataType === 'number'?
                        <React.Fragment>123</React.Fragment>
                   :(that.dataType === 'date' || that.dataType === 'datetime' || that.dataType === 'month' || that.dataType === 'day') ?
                        <i className="fa fa-calendar"></i>
                    :that.dataType == "customhierarchy"?
                        <i className="fa fa-list"></i>
                    :
                    <React.Fragment>{lang["storyboard.abc"]}</React.Fragment>
                    }
                	
            </span>
            <span className="itemname">{that.columnDisplayName}</span>
            {that.customfunction===1 && that.customfunction!=undefined && that.chartType != 'mlmodel'?
                <span className="itemedit callcustommeasure" data-edit="edit" data-name={that.columnName} title={lang["mlreports.edit"]}><i className="fa fa-pencil"></i></span>
            :that.customfunction=== 3 && that.chartType != 'mlmodel'?
                <span className="itemedit createrange" data-edit="edit" data-name={that.columnName} title={lang["mlreports.edit"]}><i className="fa fa-pencil"></i></span>
            :that.dataType == "customhierarchy" && that.chartType != 'mlmodel'?
                    <span className="itemedit callcustomhierarchy" data-edit="edit" data-name={that.columnName} title={lang["mlreports.edit"]}><i className="fa fa-pencil"></i></span>
              :<React.Fragment/>
                }
        </li>
        );
    }
}