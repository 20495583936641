import React from 'react';
import { lang } from "../js/helpers/utils/languages/language";

export default class SingleSavedReportFilterTemplate extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div class="modal fade singlesavedreportmodal" id="singlreportfiltermodal" tabIndex="-1" role="dialog" aria-labelledby="innerfiltermodallabel" aria-hidden="true">
                <div class="modal-dialog modal-sm modal-dialog-center instant-floating-filters">
                    <div class="modal-content singlefiltermodal">
                        <div class="modal-header boc-modal-hf-padding panel-heading">
                            <button type="button" class="close white" id="singlefilterclose" aria-hidden="true" data-number="1"><i class="fa fa-times-circle"></i></button>
                            {/* <!-- <a href="javascript:void(0)" class="white marg-righ-10 dropdown-toggle" id="excludenullfilter" data-toggle="dropdown" aria-expanded="false" style="display: block;float: right;" title="Exclude NULL Values"><i class="fa fa-circle-thin"></i></a> --> */}
                            <ul class="dropdown-menu excludenulldropdown" role="menu">
                                <li class="aggregation-li-item">
                                    <label><input type="checkbox" class="isexcludenulls" id="viewsisexcludenulls" />&nbsp;{lang["reports.exclude"]} <b>{lang["reports.null"]}</b> {lang["reports.values"]}</label>
                                </li>
                            </ul>
                            <h4 class="modal-title filtershead" id="mySmallModalLabel" data-toggle="collapse" data-target="#collapseOne">{lang["reports.filters"]}</h4>
                        </div>
                        <div id="collapseOne" class="panel-collapse collapse in" aria-expanded="true">
                            <div class="modal-body scrollset nopadding">
                                <div class="container-fluid">
                                    <div class="row filtercontent0 dobyfilteritems accordian-container">
                                        <div class="marg-bot-10">
                                            <button class="btn btn-bird btn-sm singlefilterdropdown tooltip-right" data-tooltip={lang["reports.add"]} id="singlefilterdropdownviews" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["reports.addnewfiltrer"]}>
                                                <i class="fa fa-plus marg-righ-5"></i>{lang["reports.addnewfilter"]}
											</button>
                                            {
                                                (this.props.isdirect == 0) ? (
                                                    <button class="btn btn-bird btn-sm singlecustomparameterfilterdropdown tooltip-left" onClick={this.props.addCustomParamerFilter} data-tooltip={lang["reports.add"]} id="singlecustomparameterfilterdropdownview" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["reports.addparameter"]}>
                                                        <i class="fa fa-plus marg-righ-5"></i>{lang["reports.addparameter"]}
                                                    </button>
                                                ) : null
                                            }
                                            <button class="btn btn-bird btn-sm pull-right applysavedfilter tooltip-left" data-tooltip="APPLY" id="applysavedfilterview" type="button" aria-hidden="true" title={lang["reports.applyfilters"]}><i class="fa fa-check marg-righ-5"></i>{lang["reports.applyfilters"]}</button>
                                        </div>
                                        {/* <!-- <div class="col-xs-12 text-left marg-top-5">
																						<label><input type="checkbox" class="isexcludenulls">&nbsp;Exclude 'null' Values</label>
																					</div> --> */}
                                        {/* <!-- Instant Filters --> */}
                                        <div class="instant-filter-container hide">
                                            <div class="row">
                                                <div class="container-fluid">
                                                    <div class="allscopeslistcontainer">
                                                        <div class="subhead">{lang["reports.instantfilters"]} <div class="instant-filter-title"><span><i class="fa fa-circle"></i>{lang["reports.exclude"]}</span>&nbsp;/<span><i class="fa fa-circle"></i>{lang["reports.keeponly"]}</span></div></div>
                                                        <div class="allscopeslistinnercontainer">
                                                            <div class="instant-filter-tags addInstanceFilterData">

                                                            </div>
                                                        </div>
                                                        <div class="parameterfiltersgroupdiv hide">
                                                            <div class="subhead">{lang["reports.parameterfilters"]} </div>
                                                            <div class="instancefilterlistinnercontainer">
                                                                <div class="instant-filter-tags addURLInstanceFilterData"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- \Instant Filters --> */}
                                        <div class="clear"></div>
                                        <div class="rightfilter" id="filteraccordion"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer boc-modal-hf-padding">

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}