import SendRequest from '../../SendRequest';
import {Component} from 'react'

export default class saveOrUpdateUsers extends Component{
  constructor(props) {
    super(props)
    this.state={
    previousstate:props
    }
  }
editAUserAccount =(event)=>{
    const queryString=`?userId=${event.currentTarget.value}`
    let labelName = event.currentTarget.getAttribute("addOrUpdateLabelName");
    SendRequest({
        url: "userDetails",
        queryString: queryString,        
        headers: {
          method: "get",
          headers: new Headers({
            "Content-Type": "application/json"
          })
        },
        sucessFunction: (userDetails) => {
          this.state.previousstate.updateUserDeatilstoForm(userDetails);
      },
      rejectFunction: () => {},
      });
    
}
deleteUserFunction = (event)=>{
    let formaData = new FormData();
    formaData.append('id', parseInt(event.currentTarget.value));
    SendRequest({
        url: "deleteuser",
        queryString: "",
        body:formaData,
        headers: {
          method: "post",
          headers: new Headers({
          })
        },
        sucessFunction: () => {
            this.state.previousstate.UNSAFE_componentWillMount()
      },
      rejectFunction: () => {},
      });
}
   resetUserFunction= (event)=>{
       let formaData = new FormData();
       formaData.append('userId',event.currentTarget.value);
       SendRequest({
        url: "resetpasswordfromadmin",
        queryString: "",
        body:formaData,
        headers: {
          method: "post",
          headers: new Headers({
          })
        },
        sucessFunction: (userDetails) => {
          
      },
      rejectFunction: () => {},
      });
  }
  addUserFunction =(event) => {
    let userId = null;
    
    var formdata = new FormData();
    formdata.append("firstName", document.getElementById("firstname").value);
    formdata.append("lastName", document.getElementById("lastname").value);
    formdata.append("userEmail", document.getElementById("loginemail").value);
    formdata.append("groupId",document.getElementById("usergroup").selectedOptions[0].value);
    formdata.append("role",document.getElementById("role").selectedOptions[0].value);
    formdata.append("status",document.querySelector("input[type='radio'][name='status']:checked").value);
    var path= window.location.origin+"/"+window.localStorage.appName;
    formdata.append("path",path);
    if(event.currentTarget.getAttribute("userid") != null &&
        event.currentTarget.getAttribute("userid") != undefined && 
        event.currentTarget.getAttribute("userid") != "null" ){
        
            userId = event.currentTarget.getAttribute("userid"); 
            formdata.append('userId',userId)
    }
    

    var that = this;
    SendRequest({
      url: "saveUser",
      queryString: "",
      body: formdata,
      headers: {
        method: "post",
        headers: new Headers({
          "Content-Type": "application/json"
        })
      },
      sucessFunction: () => {
        console.log(that.state.previousstate.UNSAFE_componentWillMount());
    },
    rejectFunction: () => {},
    });
  };
}
