import React from 'react';
import { lang} from "../../js/helpers/utils/languages/language";
export default class AddSyncConfigTemplate extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        var that = this.props;
        return (
            <div className="row in-back">
                <div id="example-form" className="max-height-30vh">
                    <h2 className="boc-main-title">{lang["admin.synctitle"]}</h2>
                    <section>
                        <form className="form-horizontal col-xs-12 editsyncconfigform">
                            <div className="form-group form-group-sm">
                                <label htmlFor="syncconfigname"
                                    className="col-sm-3 control-label important">{lang["admin.syncname"]}</label>
                                <div className="col-sm-4">
                                    <input type="text" className="form-control" id="syncconfignameid" onKeyUp={that.validateAddSyncConfigName}
                                        name="syncconfigname" placeholder={lang["admin.pholdsyncname"]} defaultValue="" /> <span
                                            className="help-block syncconfignameerror hidden" style={{ color: "red" }}></span>
                                </div>
                            </div>

                            <div className="form-group form-group-sm">
                                <label htmlFor="syncconfigcategory"
                                    className="col-sm-3 control-label">{lang["admin.synccategory"]}</label>
                                <div className="col-sm-4">
                                    <select className="modal-form-field syncCategory synccategory" id="synccategorys">
                                        <option value="Hub" selected>{lang["admin.synchub"]}</option>
                                        <option value="Workspace">{lang["admin.syncworkspace"]}</option>
                                    </select>
                                </div>
                            </div>

                            <div className="form-group form-group-sm datasyncschedulecontrollers">
                                <label htmlFor="syncconfigname"
                                    className="col-sm-3 control-label "></label>
                                <div className="control-group col-lg-6 nopadding">
                                    <div className="col-lg-12 marg-top-5">
                                        <div className="col-xs-5 col-lg-4 nopadding">
                                            <label className="labelname hide"><input type="radio" name="datasync" id="defaultcontainer" className="datasynctype hide" defaultChecked onChange={(e) => { this.props.changeSyncType(e); }} />&nbsp;{lang["admin.sync.default"]}</label>
                                        </div>
                                        <div className="col-xs-7 col-lg-4 nopadding">
                                            <label className="labelname hide"><input type="radio" name="datasync" id="custom" className="datasynctype hide" onChange={(e) => { this.props.changeSyncType(e); }} />&nbsp;{lang["admin.sync.custom"]}</label>
                                        </div>
                                    </div>
                                    <div className="datasync-container defaultcontainer">
                                        <div className="col-xs-3 nopadding">
                                            <span className="prefix">{lang["admin.syncevery"]}</span>
                                        </div>
                                        <div className="col-xs-5">
                                            <input type="number" value="30" className="modal-form-fields syncdomelement" disabled="disabled" />
                                        </div>
                                        <div className="col-xs-3">
                                            <span className="suffix">{lang["admin.sync.min"]}</span>
                                        </div>
                                    </div>
                                    <div className="datasync-container custom hide ">
                                        <div className="col-xs-12">
                                            <div class="col-xs-8 nopadding">
                                                <select className="modal-form-fields syncreport syncdomelement" id="syncdomelements" onChange={(e) => { this.props.changeSyncOption(e) }}>
                                                    <option value="days">{lang["admin.syncdays"]}</option>
                                                    <option value="hours">{lang["admin.synchrs"]}</option>
                                                    <option value="minutes" selected>{lang["admin.syncmin"]}</option>
                                                    <option value="seconds" id="secondsdisable">{lang["admin.syncsec"]}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="synccontainer seconds hide">
                                            <div className="col-xs-3 nopadding">
                                                <span className="prefix">{lang["admin.syncevery"]}</span>
                                            </div>
                                            <div className="col-xs-5">
                                                <input type="number" name="seconds" id="seconds" max="59" min="5" defaultValue="30" className="modal-form-fields syncdomelement" />
                                            </div>
                                            <div className="col-xs-3">
                                                <span className="suffix">{lang["admin.sync.sec"]}</span>
                                            </div>
                                        </div>
                                        <div className="synccontainer minutes">
                                            <div className="col-xs-3 nopadding">
                                                <span className="prefix">{lang["admin.syncevery"]}</span>
                                            </div>
                                            <div className="col-xs-5">
                                                <input type="number" name="minutes" id="minutes" max="59" min="1" defaultValue="30" className="modal-form-fields syncdomelement" />
                                            </div>
                                            <div className="col-xs-3">
                                                <span className="suffix">{lang["admin.sync.min"]}</span>
                                            </div>
                                        </div>
                                        <div className="synccontainer hours hide">
                                            <div className="col-xs-3 nopadding">
                                                <span className="prefix">{lang["admin.syncevery"]}</span>
                                            </div>
                                            <div className="col-xs-5">
                                                <input type="number" name="hours" id="hours" max="23" min="1" defaultValue="1" className="modal-form-fields syncdomelement" />
                                            </div>
                                            <div className="col-xs-3">
                                                <span className="suffix">{lang["admin.sync.hrs"]}</span>
                                            </div>
                                        </div>
                                        <div className="synccontainer days hide">
                                            <div className="col-xs-3 nopadding">
                                                <span className="prefix">{lang["admin.syncevery"]}</span>
                                            </div>
                                            <div className="col-xs-5">
                                                <input type="number" name="days" id="days" max="31" min="1" defaultValue="1" className="modal-form-fields syncdomelement" />
                                            </div>
                                            <div className="col-xs-3">
                                                <span className="suffix">{lang["admin.sync.days"]}</span>
                                            </div>
                                            <div style={{ color: "red", fontSize: "14px", top: "-10px" }} className="col-xs-12  syncerrordays hide"></div>
                                            <div className="clear"></div>
                                            <div className="col-xs-3 marg-top-10 text-right nopadding">
                                                <span className="subhead">{lang["admin.synctime"]}</span>
                                            </div>
                                            <div className="col-xs-8 nopadding">
                                                <div className="input-group sync-day-time input-group-sm">
                                                    <input type="text" name="sync-day-time-start" id="sync-day-time-start" placeholder={lang["admin.pholdtime"]} className="form-control marg-left-5" defaultValue="" maxLength="5" />
                                                    <div className="input-group-addon"><i className="fa fa-clock-o"></i></div>
                                                </div>
                                            </div>
                                            <div style={{ color: "red", fontSize: "14px", top: "-1px" }} className="col-xs-12 syncdaytimeerror" id="errormsg"></div>
                                        </div>
                                    </div>
                                    <div style={{ color: "red" }} className="col-xs-12 syncerror hide"></div>
                                </div>

                            </div>
                            <div className="form-group form-group-sm">
                                <label for="" className="col-sm-3 control-label">{lang["admin.syncstatus"]}</label>
                                <div className="col-sm-6">
                                    <div>
                                        <><input type="radio" id="statusactive" name="status" value="A" className="statusactive" defaultChecked="checked"/>{lang["admin.syncstatusact"]}&nbsp;&nbsp;&nbsp;
							              <input type="radio" id="statusinactive" name="status" value="I" />{lang["admin.syncstatusdact"]} </>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group form-group-sm">
                                <label htmlFor="syncconfigname" class="col-sm-3 control-label "></label>
                                <div className="col-sm-5">
                                    <button type="button"
                                        className="btn btn-bird  marg-righ-5" id="addSyncConfig" onClick={that.addSyncConfig}><i className="fa fa-check marg-righ-5"></i>{lang["admin.syncaddbtn"]}</button>
                                    <button type="button"
                                        className="btn btn-bird  marg-righ-5" id="cancelsyncconfig" onClick={that.cancelsyncconfig}><i></i>{lang["admin.synccanclbtn"]}</button>

                                </div>
                            </div>

                        </form>
                    </section>
                </div>
            </div>

        );
    }
}