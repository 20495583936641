import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import FavouritesListTemplate from "./FavoritesListHtml.jsx";
import SendRequest from '../SendRequest';
import _ from 'underscore';
import { lang } from "../js/helpers/utils/languages/language";
import { getMessage } from '../js/helpers/utils/BirdMessage';
const $ = window.$;

class FavoritesListView extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.render();
    }

    getFavoriteslist(page, search, pageDisplay, order, colName) {
        var index = 0;
        var noOfRecords = $(".favrowlimtbutton .page-size").text() == "" ? 25 : $(".favrowlimtbutton .page-size").text();
        var foldername = $(".favfolders-select option:selected").val() == undefined || $(".favfolders-select option:selected").val() == '' ? 'Default' : $(".favfolders-select option:selected").val();
        var totalRecords = 0;
        if (page != undefined && page > 1) {
            index = (page - 1) * noOfRecords;
        }
        if (order == undefined) {
            order = 'asc';
        }
        if (colName == undefined) {
            colName = '';
        }
        this.dataFieldValue = colName;
        this.sortingOrder = order;
        this.page = page;
        var that = this
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getdashboardreportslist",
            queryString: "?fromDate= " + "" + "&toDate= " + "" + "&index= " + index + "&noOfRecords=" + noOfRecords + "&searchCriteria=" + search + "&order=" + order + "&colName=" + colName + "&folderName=" + foldername + "&urltype=" + "getUserPaginationFavorites",
            sucessFunction: (response) => {
                if (response.hasOwnProperty('error')) {
                    $(".spinner").hide();
                } else {
                    var headerlist = response["header"];
                    var data = response["data"];
                    totalRecords = response["totalRecords"];
                    var keys = Object.keys(headerlist);
                    var totalPages;
                    if (pageDisplay == undefined) {
                        pageDisplay = 25;
                    }
                    if (totalRecords > 25) {
                        totalPages = Math.ceil(totalRecords / pageDisplay);
                    } else {
                        totalPages = 1;
                    }

                    _.each(data, function (val, index) {
                        if (val.type == "ml_storyboard") {
                            val.viewReport = <a href={`${that.props.url}/viewmodel/${btoa(val.reportId)}/${btoa(val.dataModelId)}`} data-num={val.reportId} tab-id="0" tab-name="" class="boc-snapshot-thumbnail reportview" id="reportstoryboardview"><i class="fa fa-bar-chart"></i></a>;
                        } else if (val.type == "smartinsight_storyboard") {
                            val.viewReport = <a href={`${that.props.url}/viewsmartinsight/${btoa(val.reportId)}/${btoa(val.dataModelId)}`} data-num={val.reportId} class="boc-snapshot-thumbnail reportview" tab-id="0" tab-name="" id="reportstoryboardview"><i class="fa fa-bar-chart"></i></a>;
                        } else {
                            val.viewReport = <a href={`${that.props.url}/viewreport/${btoa(val.reportId)}`} data-num={val.reportId} class="boc-snapshot-thumbnail reportview" tab-id="0" tab-name="" id="reportstoryboardview"><i class="fa fa-bar-chart"></i></a>;
                        }
                        val.deleteReport = <button type="button" onClick={that.deleteFavourite} id="favreportlistview" data-num={val.reportId} tab-id="0" tab-name="" is-shared={val.isShared} is-favourite={val.isUserFavourite} class="btn btn-transparent aldelete"><i class="fa fa-trash-o"></i></button>;
                    });
                    // $("#favlisttable").remove();
                    // $("#favouritesdiv .data-items-container").append('<div id="favlisttable">')
                    ReactDOM.unmountComponentAtNode(document.getElementById("favtable"));
                    ReactDOM.render(<FavouritesListTemplate foldersArray={that.props.foldersArray} foldersselectchange={this.foldersselectchange} deleteFavourite={this.deleteFavourite} url={that.props.url} totalRecords={totalRecords} sizePerPage={pageDisplay} headerlist={headerlist} search={search} data={data} />, document.querySelector("#favtable"));
                    $(".favfolders-select").chosen({
                        no_results_text: lang["visual.favourites.oops"],
                        width: "100%",
                        search_contains: true
                    });
                    $(".favfolders-select option[value='" + foldername + "']").attr("selected", "selected")
                    $(".favfolders-select").trigger("chosen:updated");
                    if (search != '') {
                        $(".searchfavstoryboardtable").focus();
                    }
                    $(".spinner").hide();

                }
            },
            rejectFunction: () => { },
            headers: requestHeaders

        });


    }
    
    deleteFavourite = (e) => {
        var $this = e.currentTarget;
        console.log("control comes to delete report")
        var reportId = $($this).attr("data-num");
        var that = this;
        $(".deleteyes").unbind("click");
        $(".deleteno").unbind("click");
        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
        $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
        $('.birdmessagebuttons').addClass('show');
        $('.birdmessage-center').addClass("message-top-in");
        $('.details').removeClass('show');
        $('.details').addClass('hide');
        $('.birdmessage h2').empty().append('Warning');
        $('.birdmessage-info').empty().text(getMessage('BirdWarning18'));
        $(".deleteyes").click(function () {
            $('.birdmessage-center').removeClass("message-top-in");
            var $this = e.currentTarget;
            $('.spinner').show();
            var requestbody = {};
            requestbody["reportId"] = reportId;
            requestbody["isFavourite"] = true;
            requestbody["isShared"] = false;
            requestbody["isReportOwner"] = false;
            var data = new FormData();
            data.set("reportId", requestbody.reportId);
             data.set("isFavourite", requestbody.isFavourite);
             data.set("isSharedReport", requestbody.isShared);
             data.set("isReportOwner", requestbody.isReportOwner)
            let requestHeaders = {
                method: 'post',
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            SendRequest({
                url: "deletereport",
                body: data,
                sucessFunction: (response) => {
                    if (response.hasOwnProperty('error')) {
                        $(".spinner").hide();
                        $(".gridspinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.errorTrace);
                    } else {
                        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Success');
                        $('.birdmessage-info').empty().text(getMessage('BirdSuccess42'));
                        that.getFavoriteslist(undefined, "", 25, 'asc', undefined);
                    }
                },
                rejectFunction: () => { },
                headers: requestHeaders
            })
        });
        $(".deleteno").click(function () {
            $('.birdmessage-center').removeClass("message-top-in");
        });

    }
    searchkeyup = (e) => {
        //console.log("in search block");
        var that = this;
        var delay = (function () {
            var timer = 0;
            return function (callback, ms) {
                clearTimeout(timer);
                timer = setTimeout(callback, ms);
            };
        })();
        var search = $(e.currentTarget).val().toLowerCase();
        var pageDisplaySearch = $("#pageDropDown").text();
        delay(function () {
            that.searchValue = $(".searchfavstoryboardtable").val();
            that.getFavoriteslist(undefined, that.searchValue, pageDisplaySearch, that.sortingOrder, that.dataFieldValue);
        }, 100);

        $(".searchfavstoryboardtable").focus();
    }
    foldersselectchange = (e) => {
        var pageDisplaySearch = $("#pageDropDown").text();
        this.getFavoriteslist(undefined, "", pageDisplaySearch, 'asc', undefined);
    }
    render() {
        this.getFavoriteslist(undefined, "", 25, 'asc', undefined);
        return (
            <>
        <div className="col-xs-12 marg-top-10">
          <div className="notifications-table-container data-item">
            <div className="bootstrap-table">
              <div className="fixed-table-toolbar">
                <div className="pull-right search">
                  <input className="form-control searchfavstoryboardtable" type="text" placeholder={lang["visual.favourites.search"]} defaultValue={this.search} onKeyUp={this.searchkeyup} />
                </div>
                <div className="pull-left">
                  <select className="favfolders-select" data-placeholder={lang["visual.slctfolders"]} onChange={this.foldersselectchange}>
                    <optgroup label="Folders">
                      <React.Fragment>
                        {
                          this.props.foldersArray.map((val, index) => {
                            return (
                              <option value={val.Folder}>{val.Folder}</option>
                            )
                          })
                        }
                      </React.Fragment>
                    </optgroup>
                  </select>
                </div>
              </div>
              <div id="favtable">
              </div>
            </div>
          </div>
        </div>
      </>
        )
    }
}

export default FavoritesListView;