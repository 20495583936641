import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'underscore';
import * as ChartInfo from '../js/helpers/utils/chartinfo';
import { getMessage } from '../js/helpers/utils/BirdMessage';
import SendRequest from '../SendRequest';
import DatahubHtml from './DatahubHtml.jsx';
import PubSub from 'pubsub-js'
import DatahubConnections from './DatahubConnections';
import DatabaseConnections from './DatabaseConnections';
import DataHubSyncConfig from './DatahubSyncConfig';
import CreateConnection from './CreateConnection';
import ExistingEntitiesHtml from './ExistingEntitiesHtml.jsx';
import DatahubItemSettings from './DatahubItemsSettings';
import DatahubCustomQuery from './DatahubCustomQuery';
import StoreProcedureParameters from './StoreProcedureParameters';
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;

export default class Datahub extends React.Component {
    constructor(props) {
        $(".spinner").show();
        super(props);
        this.state = {
            datahubConnectionsList: [],
            datahubEntitiesCollection: [],
            entityMetaDataCollection: []
        }
        if (props !== undefined) {
            this.selectedConnectionId = props.selectedConnectionId;
        }
        this.displayFooter = true;
        this.datahubdetails = {};
        this.tables = [];
        this.query = {};
        this.datahubentities = {};
        this.reportcolumns = {};
        this.syncConfigs = [];
        sessionStorage.removeItem("customqueryEdited");
        PubSub.unsubscribe("applydatahubmodifyentities",this.applydatahubmodifyentitiesEve.bind(this));
        PubSub.subscribe("applydatahubmodifyentities",this.applydatahubmodifyentitiesEve.bind(this));
    }
    applydatahubmodifyentitiesEve(msg, Objectval){
            this.applydatahubmodifyentities(Objectval["e"],Objectval["message"]);
    }
    requestHeaders = {
        method: "get",
        headers: new Headers({
            "Content-Type": "application/json"
        })
    };
    UNSAFE_componentWillMount() {
        document.body.className = '';
        document.body.classList.add('day', 'datahubpage');
        $('.spinner').show();
    }
    componentDidMount() {
        $(".spinner").show();
        var that = this.state;
        SendRequest({
            url: "gethubentities",
            queryString: "?isFromWorkspace=" + false,
            sucessFunction: (resp) => {
                that.datahubEntitiesCollection = resp;
                if(resp.length != 0){
                $('.spinner').show();
                $("a[href='#next']").parent().addClass("disabled");
                }
                SendRequest({
                    url: "gethubconnections",
                    queryString: "",
                    sucessFunction: (resp) => {
                        that.datahubConnectionsList = resp;
                        if(resp.length != 0){
                        $("a[href='#next']").parent().removeClass("disabled")
                        $('.spinner').show();
                        }
                        var width;
                        if ($(window).width() < 800) { width = '150'; }
                        else { width = '300'; }
                        $("#datahubconnectionscontainer").w2destroy('splitter');
                        var connectionsdata = [];
                        this.connectionaccessdata = {};
                        var currentselectedConnection = {};
                        _.each(this.state.datahubConnectionsList, (i, j) => {
                            var data = {};

                            data['text'] = i.connectionAccessName;
                            data['connectionName'] = i.connectionName.toLowerCase();
                            data['value'] = i.connectionAccessId;
                            data['contype'] = i.connectionType;
                            data['selected'] = false;
                            data['schemaId'] = i.connectionId;
                            data['connectionjson'] = i.connectionJson;
                            data['description'] = i.connectionAccessName;
                            data['imageSrc'] = "../../images/birdimages/" + i.connectionName.toLowerCase() + ".png";
                            if (this.selectedConnectionId != undefined && i.connectionAccessId == this.selectedConnectionId) {
                                data['selected'] = true;
                                currentselectedConnection = data;
                            } else if (j === 0 && this.selectedConnectionId == undefined) {
                                data['selected'] = true;
                                currentselectedConnection = data;
                            }
                            connectionsdata.push(data);
                            this.connectionaccessdata[i.connectionAccessId] = data;
                        });
                        ChartInfo.functions.setExistingEntitiesMetaData(this.state.datahubEntitiesCollection);
                        $("#datahubconnectionscontainer").w2layout({
                            name: 'splitter',
                            padding: 5,
                            panels: [
                                { type: 'left', size: width, resizable: true, style: this.pstyle, content: "<div id='datahubsidebar'></div>" },
                                { type: 'main', style: this.pstyle, content: '<div id="datahubentityfilters"><input class="form-control search-image" name="selectedentitysearch" placeholder= '+lang["datahub.search"]+' id="selectedentitysearch" type="text"><button   class="btn btn-sm btn-transparent showallselectedentities">'+lang["datahub.showentity"]+'<div class="form-check checkbox-slider--b-flat checkbox-slider-sm" title="'+lang["datahub.showentity"]+'"><label><input class="showallentitycheck" type="checkbox"><span></span></label></div></button></div><div id="datahubentitycontainer"></div>' },
                            ]
                        })
                        $(".showallentitycheck").click(function () {
                            $("div#datahubentitycontainer li.viewreport-list-item").toggleClass("showall");

                        });
                        $("#selectedentitysearch").keyup(function (e) {
                            var $this = e.currentTarget;
                            /*Delay function for to wait until user stops typing*/
                            var delay = (function () {
                                var timer = 0;
                                return function (callback, ms) {
                                    clearTimeout(timer);
                                    timer = setTimeout(callback, ms);
                                };
                            })();
                            /*Delay function for to wait until user stops typing*/
                            //   $("#selectedentitysearch").keyup(function(){
                            var valThis = $(this).val().toLowerCase();
                            if (valThis === "") { valThis = null; }
                            var searchin = $('#datahubentitycontainer');
                            delay(function () {
                                $(searchin).find(".viewreport-list-item").each(function () {
                                    var text = $(this).find('label').text().trim().toLowerCase();
                                    (text.indexOf(valThis) !== -1) ? $(this).addClass('search-success') : $(this).removeClass('search-success');
                                });
                            }, 400);
                        });
                        // });

                        //   setTimeout(()=>{
                        ChartInfo.functions.setExistingEntitiesMetaData(this.state.datahubEntitiesCollection);
                        //   this.onEntitiesLoad(true);
                        // },500);
                        ReactDOM.render(
                            <DatahubConnections
                                createnewconnection={this.createnewconnection}
                                exisitingConnections={this.state.datahubConnectionsList}
                                connectionsdata={connectionsdata}
                                connectionaccessdata={this.connectionaccessdata}
                                currentselectedConnection={currentselectedConnection}
                                exisitingEntites={this.state.datahubEntitiesCollection}
                                joinremove={this.joinremove}
                                editdatahubitem={this.editdatahubitem}
                                onEntitiesLoad={this.onEntitiesLoad}
                                loadcustomquerydetails={this.loadcustomquerydetails}
                                loadstoredproceduredetails={this.loadstoredproceduredetails}
                            />,
                            document.getElementById('datahubsidebar')
                        );
                        this.onEntitiesLoad(true);
                    },
                    rejectFunction: () => { },
                    headers: this.requestHeaders
                });
            },
            rejectFunction: () => { },
            headers: this.requestHeaders
        });

        //        this.urlparameters = this.props.location.search.replace("?","").split("&");
        $("#datahubstepscontainer").steps({
            headerTag: "h3",
            bodyTag: "section",
            enableKeyNavigation: false,
            transitionEffect: "slideLeft",
            autoFocus: true,
            labels: { finish: 'Save' },
            onStepChanging: (event, currentIndex, newIndex) => {
                $('.spinner').show();
                if (currentIndex === 0 && newIndex === 1 || currentIndex === 2 && newIndex === 3) {
                    if ($('.applydatahubmodifyentities').length > 0 && $('.applydatahubmodifyentities').attr("name") !== undefined && $('.applydatahubmodifyentities').attr("id") !== undefined) {
                        if (this.connectionaccessdata[$('.applydatahubmodifyentities').attr("id")] == undefined) {
                            this.getHubConlist();
                        } else {
                            this.applydatahubmodifyentities(undefined, false);
                        }
                    } else {
                        this.loadSyncConfiguration();
                    }
                }
                if(($("#datahubentitycontainer li").length <=0) ){
                    $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-infor').addClass('birdmessage-warning');
                    $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-info-circle').addClass('fa-exclamation-triangle');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('show');
                    $('.details').addClass('hide');
                    $('.birdmessage h2').empty().append('Warning');                        
                    $('.birdmessage-info').empty().text(getMessage('BirdWarning134'));
                    setTimeout(()=>{
                        $("#datahubstepscontainer").steps("previous");
                        $('.spinner').hide();
                    },800);
                   return false;
                }
                if ($("#datahubentitycontainer li").length > 0) {
                    var emptyNameCount = 0;
                    $("#datahubentitycontainer>li").removeClass('warning');
                    _.each($("#datahubentitycontainer li"), function (ent, index) {
                        var entity = $(ent).find("input.scopeitem");
                        if ($(entity).hasClass("customquery") && ($(entity).attr("name") == 'Custom Query' || $(entity).attr("name") == "")) {
                            $(ent).removeClass("active");
                            $(ent).addClass("warning");
                            emptyNameCount++;
                        }
                    });
                    if (emptyNameCount > 0) {
                        $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-infor').addClass('birdmessage-warning');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-info-circle').addClass('fa-exclamation-triangle');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Warning');                        
                        $('.birdmessage-info').empty().text(getMessage('BirdWarning135'));
                        setTimeout(()=>{
                            $("#datahubstepscontainer").steps("previous");
                            $('.spinner').hide();
                        },800);
                       return false;
                    }
                }
                return true
            },
            onStepChanged: (event, currentIndex, newIndex) => {
                if (currentIndex === 0) {
                    $("#datahubentitycontainer li").removeClass('active');
                    $("#datahubentitycontainer").removeClass('settingsopened');
                    $("#settingscontainer").removeClass("showsettings");
                    $("#datahubconnectionscontainer").w2layout('resize');
                    $('.spinner').hide();
                }
            },
            onFinishing: (event, currentIndex) => {
                this.saveDataHub(event, currentIndex);
            },
            onFinished: (event, currentIndex) => {
                return true
            }
        });
        $(window).on("resize", function () {
            $("#datahubconnectionscontainer").w2layout('resize');
        });
        // $(".spinner").hide();
        SendRequest({
            url: "getSyncConfigDetails",
            queryString: "?category=Hub",
            sucessFunction: (response) => {
                if (response.hasOwnProperty('error')) {
                    $(".spinner").hide();
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('hide');
                    $('.birdmessage h2').empty().append('Error : ');
                    $('.birdmessage h2').append('<span class="errorcode">');
                    $('.birdmessage .errorcode').empty().append(response.errorCode);
                    $('.birdmessage-info').empty().text(response.error);
                    $('.details').addClass('show');
                    $('.messagedetails xmp').empty().append(response.errorTrace)
                } else {
                    this.syncConfigs = response;
                }
            },
            rejectFunction: () => { },
            headers: this.requestHeaders

        });
    }
    connectionchange = (e) => {
    }

    createnewconnection = (e) => {
        e.preventDefault();
        $(".addconnection").remove();
        $("#settingscontainer").removeClass("showsettings");
        $("#settingscontainer").remove();
        $("#datahubentitycontainer").removeClass('settingsopened');
        $("#root").append("<div class='connection-center addconnection connection-in'></div>");
        $("body").addClass("connection-open creating-new-connection");
        ReactDOM.render(<CreateConnection />, document.querySelector('.addconnection'));
    }
    getHubConlist = () => {
        SendRequest({
            url: "gethubconnections",
            queryString: "",
            sucessFunction: (resp) => {
                _.each(resp, (i, j) => {
                    var data = {};
                    data['text'] = i.connectionAccessName;
                    data['connectionName'] = i.connectionName.toLowerCase();
                    data['value'] = i.connectionAccessId;
                    data['contype'] = i.connectionType;
                    data['schemaId'] = i.connectionId;
                    data['connectionjson'] = i.connectionJson;
                    data['description'] = i.connectionAccessName;
                    data['imageSrc'] = "../../images/birdimages/" + i.connectionName.toLowerCase() + ".png";
                    data['selected'] = false;
                    this.connectionaccessdata[i.connectionAccessId] = data;
                });
                this.applydatahubmodifyentities(undefined, false);
            },
            rejectFunction: (error) => {
            },
            headers: this.requestHeaders
        });
    }
    loadConnections = (selectedConnectionId) => {
        var connectionsdata = [];
        this.connectionaccessdata = {};
        var currentselectedConnection = {};
        if (selectedConnectionId !== undefined) {
            SendRequest({
                url: "gethubentities",
                queryString: "?isFromWorkspace=" + false,
                sucessFunction: (resp) => {
                    this.state.datahubEntitiesCollection = resp;
                    $("a[href='#next']").parent().addClass("disabled");
                    $('.spinner').show();
                    SendRequest({
                        url: "gethubconnections",
                        queryString: "",
                        sucessFunction: (response) => {
                            this.state.datahubConnectionsList = response;
                            $('.spinner').show();
                            $("a[href='#next']").parent().removeClass("disabled");
                            _.each(response, (i, j) => {
                                var data = {};
                                data['text'] = i.connectionAccessName;
                                data['connectionName'] = i.connectionName.toLowerCase();
                                data['value'] = i.connectionAccessId;
                                data['contype'] = i.connectionType;
                                data['schemaId'] = i.connectionId;
                                data['connectionjson'] = i.connectionJson;
                                data['description'] = i.connectionAccessName;
                                data['imageSrc'] = "../../images/birdimages/" + i.connectionName.toLowerCase() + ".png";
                                data['selected'] = false;
                                if (selectedConnectionId != undefined && i.connectionAccessId == selectedConnectionId) {
                                    data['selected'] = true;
                                    currentselectedConnection = data;
                                }
                                connectionsdata.push(data);
                                this.connectionaccessdata[i.connectionAccessId] = data;
                            });

                            ReactDOM.unmountComponentAtNode(document.getElementById('datahubsidebar'));

                            ReactDOM.render(
                                <DatahubConnections
                                    createnewconnection={this.createnewconnection}
                                    exisitingConnections={this.state.datahubConnectionsList}
                                    connectionsdata={connectionsdata}
                                    connectionaccessdata={this.connectionaccessdata}
                                    currentselectedConnection={currentselectedConnection}
                                    exisitingEntites={this.state.datahubEntitiesCollection}
                                    onEntitiesLoad={this.onEntitiesLoad}
                                    joinremove={this.joinremove}
                                    editdatahubitem={this.editdatahubitem}
                                    loadstoredproceduredetails={this.loadstoredproceduredetails}
                                />,
                                document.getElementById('datahubsidebar')
                            );


                            $("#datahubconnectionscontainer").w2layout('resize');
                            this.onEntitiesLoad(true);
                        },
                        rejectFunction: (error) => { },
                        headers: this.requestHeaders
                    })
                },
                rejectFunction: () => { },
                headers: this.requestHeaders
            });

        }
    }
    onEntitiesLoad = (addExisitngEntities) => {
        $("#datahubentitycontainer").sortable({ revert: "100" });
        $("#scopeItem ul.list-group>li").draggable({
            connectToSortable: "#datahubentitycontainer",
            revert: "100",
            scroll: false,
            helper: 'clone',
            addClasses: true,
            drag: (event, ui) => {
                $(ui.helper.context).draggable({ disabled: true }).addClass('disabled');
            },
            stop: (event, ui) => {
                var dragText = $(ui.helper.context).find("input").attr("data-value");
                var connectionId = $(ui.helper.context).find("input").attr("data_parent");
                setTimeout(() => {
                    if ($("#datahubentitycontainer li:not('.ui-draggable-dragging')").find("input[data-value='" + dragText + "'][data_parent='" + connectionId + "']").length != 1) {
                        $(".filtercontent input[data-value='" + dragText + "'][data_parent='" + connectionId + "']").parent().parent().draggable({ disabled: false }).removeClass("disabled");
                        $(".filtercontent input[data-value='" + dragText + "'][data_parent='" + connectionId + "']").prop('checked', false);
                    }
                }, 500);
            }
        });

        $("ul, li").disableSelection();
        $("#datahubentitycontainer").droppable({
            hoverClass: "highlight",
            activeClass: "highlight",
            out: (event, ui) => {
                var self = ui;
                var dragText = ui.draggable.find("input").attr("data-value");
            },
            drop: (event, ui) => {
                $('#settingscontainer').removeClass("showsettings");
                $("#datahubentitycontainer>li").removeClass('active');
                $("#datahubentitycontainer").removeClass('settingsopened');
                var dragText = ui.draggable.find("input").attr("data-value");
                var connectionId = ui.draggable.find("input").attr("data_parent");
                setTimeout(() => {
                    $('#settingscontainer').remove();
                    if ($("#datahubentitycontainer li:not('.ui-draggable-dragging')").find("input[data-value='" + dragText + "'][data_parent='" + connectionId + "']").length === 1) {
                        $(".filtercontent input[data-value='" + dragText + "'][data_parent='" + connectionId + "']").parent().parent().draggable({ disabled: true }).addClass("disabled");
                        $(".filtercontent input[data-value='" + dragText + "'][data_parent='" + connectionId + "']").prop('checked', true);
                    }
                }, 500);
            },
            over: (event, ui) => {
            }
        });
        if (addExisitngEntities) {
            this.allSavedEntities = {};
            var entitiesArray = [];
            sessionStorage.removeItem("customquerySessionObj");
            this.state.datahubEntitiesCollection.map((i, j) => {
                var connAccessId = i.connAccessId;
                var entityName = i.entityname;
                var entityType = i.entityType;
                var entityId = i.entityId;
                var conAccessName=i.connName
                if (entityType === "storedprocedure") {
                    var savedquery = JSON.parse(i.savedquery);
                    var storedPrams = JSON.parse(savedquery[0]['storedprocedureparams']);
                    var entityName = i.entityname;
                    sessionStorage.setItem(entityName.trim(), JSON.stringify(storedPrams));
                    var storedProcs = sessionStorage.getItem("storedprocs");
                    if (storedProcs != undefined) {
                        storedProcs = storedProcs + "," + entityName;
                    }
                    else {
                        storedProcs = entityName;
                    }
                    sessionStorage.setItem("storedprocs", storedProcs);
                    entitiesArray.push(
                        <ExistingEntitiesHtml
                            item={entityName}
                            conAccessName={conAccessName}
                            connectionaccessid={connAccessId}
                            showsettings={true}
                            entity_type={entityType}
                            entityId={entityId}
                            entityType={entityType}
                            joinremove={this.joinremove}
                            editdatahubitem={this.editdatahubitem}
                            loadstoredproceduredetails={this.loadstoredproceduredetails}
                        />
                    );
                } else if (entityType == "customqueryentity") {
                    var customquery = i.actualquery;
                    var entityName = i.entityname.replace("_CQ", "");
                    var entityValue = i.entityname;
                    var connAccessId = i.connAccessId;
                    var connectionAccessName = i.connName;
                    var entityId = i.entityId;
                    var finalObj = {}
                    finalObj["query"] = customquery
                    finalObj["entityName"] = entityValue
                    finalObj["connAccessId"] = connAccessId
                    sessionStorage.setItem(entityValue.trim(), JSON.stringify(finalObj));
                    var customqueries = sessionStorage.getItem("customquerySessionObj");
                    if (customqueries != undefined) {
                        customqueries = customqueries + "," + entityValue;
                    }
                    else {
                        customqueries = entityValue;
                    }
                    sessionStorage.setItem("customquerySessionObj", customqueries);
                    var width = $("#connections-tables-data-container").width() - 43;
                    entitiesArray.push(
                        <li
                            className="viewreport-list-item datahub-customquery-item is-saved-entity"
                            data_parent={connAccessId}
                            style={{ width: `${width}px` }}
                        >
                            <label title={lang["datahub.customqry"]}>
                                <i className="fa fa-code">
                                    <input
                                        type="checkbox"
                                        className="scopeitem customquery"
                                        data_parent={connAccessId}
                                        conAccessName={connectionAccessName}
                                        name={entityName}
                                        id={entityValue}
                                        data-value={entityValue}
                                        is-valid="true"
                                        is-saved="true"
                                    />
                                </i> {entityName}</label>
                            <button className="btn btn-transparent btn-sm editdatahubcustomquery" data_parent={connAccessId} name={entityName} issaved="true" type="button" title={lang["Datahub.editcustomquery"]} onClick={this.loadcustomquerydetails}><i className="fa fa-cog"></i></button>
                            <button className="btn btn-transparent btn-sm joinremove" onClick={this.joinremove} type="button"><i className="fa fa-times"></i></button></li>
                    );
                    // $("#datahubentitycontainer").append('<li class="viewreport-list-item datahub-customquery-item" data_parent="'+connAccessId+'" style="width:'+width+'px"><label title="Custom Query"><i class="fa fa-code"><input type="checkbox" class="scopeitem customquery" data_parent="'+connAccessId+'" name="'+entityName+'" id="'+entityValue+'" data-value="'+entityValue+'" is-valid="true" is-saved="true"></i> '+entityName+'</label><button class="btn btn-transparent btn-sm editdatahubcustomquery" data_parent="'+connAccessId+'" name="'+entityName+'" isSaved=true type="button" title="Edit Custom Query"><i class="fa fa-cog"></i></button><button class="btn btn-transparent btn-sm joinremove" type="button"><i class="fa fa-times"></i></button></li>');
                } else {            
                    entitiesArray.push(
                        <ExistingEntitiesHtml
                            item={entityName}
                            connectionaccessid={connAccessId}
                            conAccessName={conAccessName}
                            showsettings={false}
                            entityId={entityId}
                            entityType={entityType}
                            editdatahubitem={this.editdatahubitem}
                            joinremove={this.joinremove}
                        />
                    );
                }

                $(".filtercontent input[data-value='" + entityName + "'][data_parent='" + connAccessId + "']").parent().parent().draggable({ disabled: true }).addClass("disabled");
                $(".filtercontent input[data-value='" + entityName + "'][data_parent='" + connAccessId + "']").prop('checked', true);
                var entitiesInfoObj = {};
                if (typeof i.entityColumns === 'string' && i.entityColumns != null) {
                    entitiesInfoObj["entities"] = JSON.parse(i.entityColumns)
                }
                if (typeof i.connJson === 'string' && i.connJson != null) {
                    if (entityType == "storedprocedure") {
                        entitiesInfoObj["dbType"] = "storedprocedure";
                    } else {
                        var connectionJsonObj = JSON.parse(i.connJson);
                        var dbType = connectionJsonObj["dbType"];
                        entitiesInfoObj["dbType"] = dbType;
                    }
                }
                if (i.actualquery != null) {
                    entitiesInfoObj["sqlquery"] = i.actualquery;
                }
                if (i.dataPushStatus != null) {
                    entitiesInfoObj["dataPushStatus"] = i.dataPushStatus;
                }
                if(i.datasynctype!=undefined && i.datasynctype!=""){
                        entitiesInfoObj["datasynctype"]=i.datasynctype;
                }
                this.allSavedEntities[i.connAccessId + "&&&" + i.entityname] = JSON.stringify(entitiesInfoObj);

            });
            ReactDOM.render(
                <>{entitiesArray}</>, document.getElementById('datahubentitycontainer')
            );

        } else {
            _.each($("#datahubentitycontainer .scopeitem"), (i, j) => {
                var $this = $(i);
                $(".filtercontent input[data-value='" + $this.attr('id') + "'][data_parent='" + $this.attr('data_parent') + "']").parent().parent().draggable({ disabled: true }).addClass("disabled");
                $(".filtercontent input[data-value='" + $this.attr('id') + "'][data_parent='" + $this.attr('data_parent') + "']").prop('checked', true);
            });
        }
        this.hideHubEntities();
    }
    hideHubEntities() {
        $("#datahubentitycontainer>li").addClass("hiddenentity");
        var curretItem = $('#existing-connections-list option:checked').val();
        $("#datahubentitycontainer>li>label input[data_parent='" + curretItem + "']").closest("li").removeClass("hiddenentity");
    }
    saveDataHub = (event, currentIndex) => {
        //this.dataHubSyncConfigView.saveDataHub();
    }
    loadSyncConfiguration = () => {
        $(".spinner").show();
        var that = this;
        that.connectionaccessdata = {};

        SendRequest({
            url: "gethubconnections",
            queryString: "",
            sucessFunction: (response) => {
                this.state.datahubConnectionsList = response;
                _.each(response, (i, j) => {
                    var data = {};
                    data['text'] = i.connectionAccessName;
                    data['connectionName'] = i.connectionName.toLowerCase();
                    data['value'] = i.connectionAccessId;
                    data['contype'] = i.connectionType;
                    data['schemaId'] = i.connectionId;
                    data['connectionjson'] = i.connectionJson;
                    data['description'] = i.connectionAccessName;
                    data['imageSrc'] = "../../images/birdimages/" + i.connectionName.toLowerCase() + ".png";
                    data['selected'] = false;
                    that.connectionaccessdata[i.connectionAccessId] = data;
                });


                var selectedEntities = {};
                var allEntities = {};
                var selectedStoredProcs = {};
                var selectedCustomQueries = {};
                var storedProcEntities = {};
                var flatFileEntities = {};
                var otherEntities = {};
                var tempEntities = {};
                var storedProcs = sessionStorage.getItem("storedprocs");
                var customQueries = sessionStorage.getItem("customquerySessionObj");
                var storedProcNames = undefined;
                if (storedProcs != undefined) {
                    storedProcNames = storedProcs.split(",").map((item) => {
                        return item.trim();
                    });
                }
                var customQueryNames = undefined;
                if (customQueries != undefined && customQueries != null) {
                    customQueryNames = customQueries.split(",").map((item) => {
                        return item.trim();
                    });
                }
                var storedProceduresEmptyParamCount = 0;
                $("#datahubentitycontainer>li").removeClass('warning');
                _.each($("#datahubentitycontainer .scopeitem"), (i, j) => {
                    var $this = $(i);
                    if ($($this).hasClass("storedprocedure")) {
                        if (storedProcNames != undefined && storedProcNames.indexOf($this.attr('name')) >= 0) {
                            var connectionData = _.clone(that.connectionaccessdata[$this.attr('data_parent')]);
                            connectionData['type'] = 'storedprocedure';
                            var tempObj = {};
                            var mainStoredProc = [];
                            tempObj["params"] = JSON.parse(sessionStorage.getItem($this.attr('name').trim()));
                            mainStoredProc.push(tempObj);
                            connectionData['storedprocparams'] = mainStoredProc;
                            selectedStoredProcs[$this.attr('data_parent') + "&&&" + $this.attr('id')] = connectionData;
                            allEntities[$this.attr('data_parent') + "&&&" + $this.attr('id')] = connectionData;
                            if ($this.attr("is-saved") != 'true') {
                                tempEntities[$this.attr('data_parent') + "&&&" + $this.attr('id')] = connectionData;
                            }
                            _.each($(".datahubstoredprocedurecontainer table tbody tr"), (html, index) => {
                                var parameterValue = $(html).find('.spparametervalue').val();
                                if (parameterValue.length == 0) {
                                    $(html).find('.spparametervalue').addClass('bird-has-error');
                                    storedProceduresEmptyParamCount++;
                                }
                            });
                        } else {
                            $($this).closest("li").addClass('warning');
                            storedProceduresEmptyParamCount++;
                        }
                    }
                    else if ($($this).attr('name') != undefined && (that.connectionaccessdata[$this.attr('data_parent')].contype == 'F' || that.connectionaccessdata[$this.attr('data_parent')].contype == 'FR' || that.connectionaccessdata[$this.attr('data_parent')].contype == 'JSON' || that.connectionaccessdata[$this.attr('data_parent')].contype == 'LOG' || that.connectionaccessdata[$this.attr('data_parent')].contype == 'pdf')) {
                        flatFileEntities[$this.attr('data_parent') + "&&&" + $this.attr('id')] = that.connectionaccessdata[$this.attr('data_parent')];
                        allEntities[$this.attr('data_parent') + "&&&" + $this.attr('id')] = that.connectionaccessdata[$this.attr('data_parent')];
                        if ($this.attr("is-saved") != 'true') {
                            tempEntities[$this.attr('data_parent') + "&&&" + $this.attr('id')] = that.connectionaccessdata[$this.attr('data_parent')];
                        }
                    }
                    else if (customQueryNames != undefined && $this.attr('data-value') != undefined && customQueryNames.indexOf($this.attr('data-value')) >= 0) {
                        // var cqentityname = $this.attr('data-value');
                        // if (cqentityname.indexOf("_CQ") < 0) {
                             var customquerydata = JSON.parse(sessionStorage.getItem($this.attr('data-value')))
                        // }else{
                        //     cqentityname = cqentityname.replaceAll("_CQ", "");
                        //     cqentityname = cqentityname+"_CQ";
                        //     var customquerydata = JSON.parse(sessionStorage.getItem(cqentityname));
                        // }
                        var connectionData = _.clone(that.connectionaccessdata[$this.attr('data_parent')]);
                        var connectionParseData = JSON.parse(connectionData.connectionjson)
                        connectionParseData["query"] = customquerydata.query
                        connectionParseData["isCustomQuery"] = true
                        connectionData.connectionjson = JSON.stringify(connectionParseData);
                        connectionData["isCustomQuery"] = true
                        allEntities[$this.attr('data_parent') + "&&&" + $this.attr('data-value')] = connectionData;
                        if ($this.attr("is-saved") != 'true') {
                            tempEntities[$this.attr('data_parent') + "&&&" + $this.attr('data-value')] = connectionData;
                        }
                    }
                    else {
                        selectedEntities[$this.attr('data_parent') + "&&&" + $this.attr('id')] = that.connectionaccessdata[$this.attr('data_parent')];
                        allEntities[$this.attr('data_parent') + "&&&" + $this.attr('id')] = that.connectionaccessdata[$this.attr('data_parent')];
                        if ($this.attr("is-saved") != 'true') {
                            tempEntities[$this.attr('data_parent') + "&&&" + $this.attr('id')] = that.connectionaccessdata[$this.attr('data_parent')];
                        }
                    }
                });
                if (storedProceduresEmptyParamCount > 0) {
                    $(".spinner").hide();
                    $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-infor').addClass('birdmessage-warning');
                    $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-info-circle').addClass('fa-exclamation-triangle');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('show');
                    $('.details').addClass('hide');
                    $('.birdmessage h2').empty().append('Warning');
                    $('.birdmessage-info').empty().text(lang["Datahub.enterstoredparamts"]);
                    setTimeout(() => {
                        $("#datahubstepscontainer").steps("previous");
                        $('.spinner').hide();
                    }, 800);
                    return false;
                }
                var datahubsyncform = new DataHubSyncConfig(
                    {
                        ...this.props,
                        selectedCustomQueries: selectedCustomQueries,
                        selectedStoredProcs: { selectedStoredProcs },
                        flatFileEntities: flatFileEntities,
                        selectedEntities: selectedEntities,
                        allEntities: JSON.stringify(allEntities),
                        tempEntities: JSON.stringify(tempEntities),
                        savedEntities: that.allSavedEntities,
                        dataHubEntitiesCollection: this.state.datahubEntitiesCollection,
                        getUpdatedEntities: this.getUpdatedEntities,
                        getUpdatedreportcolumns: this.getUpdatedreportcolumns,
                        syncConfigs: this.syncConfigs
                    });
                //$(".spinner").hide();
            },
            rejectFunction: (error) => { },
            headers: this.requestHeaders
        })
    }
    loadcustomquerydetails = (e) => {
        e.stopPropagation();
        var $this = e.currentTarget;
        var that = this;
        var connId = $($this).attr("data_parent");
        var entityName = $($this).attr('name').trim();
        $("#datahubentitycontainer>li").removeClass('active');
        $("#datahubentitycontainer").after('<div id="settingscontainer"></div>');
        var connectiondata = _.clone(this.connectionaccessdata[connId]);
        // ReactDOM.render(
        //     <DatabaseConnections
        //         options={this.props}
        //         dataHubEntitiesCollection={this.state.datahubEntitiesCollection}
        //         entityName={entityName}
        //         connectionjson={JSON.stringify(connectiondata)}
        //         isCustomQuery={true}
        //     />,
        //     document.getElementById('connections-tables-data-container')
        // );
        this.loadcustomquerymodal($this, connectiondata, entityName);
        // this.broker.trigger("loadcustomquerymodal", $this, connectiondata );
        $($this).closest("li").addClass('active');
        $("#datahubentitycontainer").addClass('settingsopened');
        setTimeout(function () {
            var container = $("#datahubentitycontainer"),
                scrollTo = $($this).closest("li");
            container.animate({
                scrollTop: scrollTo.offset().top - container.offset().top + container.scrollTop() - container.height() / 2
            });
        }, 1000);
    }
    loadcustomquerymodal = (element, connectiondata, entityName) => {
        var $this = element;
        var connId = $($this).attr("data_parent");
        var customqueryName = $($this).attr("name");
        var isSavedCustomQuery = $($this).attr("isSaved") != undefined ? $($this).attr("isSaved") : false
        var existingCustomqueryDetails;
        var customqueryNames = sessionStorage.getItem("customquerySessionObj");
        if (customqueryNames != undefined && customqueryNames != null) {
            var customqueryNamesArray = customqueryNames.split(",").map(function (item) {
                return item.trim();
            });
            if (customqueryNamesArray.indexOf(customqueryName.trim() + "_CQ") >= 0) {
                // if (customqueryName.indexOf("_CQ") < 0) {
                     var customqueryObj = JSON.parse(sessionStorage.getItem(customqueryName.trim() + "_CQ"));
                // }else{
                //     var customqueryObj = JSON.parse(sessionStorage.getItem(customqueryName.trim())); 
                // }
                connectiondata["value"] = customqueryObj.connAccessId;
                connectiondata["customqueryname"] = customqueryObj.entityName.replace("_CQ", "")
                connectiondata["query"] = customqueryObj.query
            }
        }
        ReactDOM.render(
            <DatahubCustomQuery
                connId={connId}
                entityName={entityName}
                customqueryName={customqueryName}
                connectiondata={connectiondata}
                dataHubEntitiesCollection={this.state.dataHubEntitiesCollection}
                existingCustomqueryDetails={existingCustomqueryDetails}
                isSavedCustomQuery={isSavedCustomQuery}
                element={$this}
            />, document.getElementById('settingscontainer')
        );
    }
    closeconnectionpop = (e) => {
        $(".connection-center").removeClass("connection-in");
    }
    loadstoredproceduredetails = (e) => {
        var that = this
        var $this = e.currentTarget;
        var isSaved = $($this).attr('is-saved') != undefined ? $($this).attr('is-saved') : ''
        $("#datahubentitycontainer>li").removeClass('active');
        $($this).closest("li").removeClass('warning');
        var connId = $($this).attr("data_parent");
        var connectiondata = this.connectionaccessdata[connId];
        // ReactDOM.render(
        //     <DatabaseConnections 
        //     options={this.props}
        //     loadstoredprocedure={true}
        //     connectionjson={JSON.stringify(connectiondata)}
        //     isSaved={isSaved}
        //     joinremove={this.joinremove}
        //     editdatahubitem={this.editdatahubitem}
        //     loadstoredproceduredetails={this.loadstoredproceduredetails}
        //     onEntitiesLoad={this.onEntitiesLoad}
        //     />,
        //     document.getElementById('connections-tables-data-container')
        // );
        this.loadstoredproceduredetails1($this, connectiondata, isSaved);
        // this.broker.trigger("loadstoredproceduremodal", $this, connectiondata );
        $($this).closest("li").addClass('active');
        $("#datahubentitycontainer").addClass('settingsopened');
        setTimeout(function () {
            var container = $("#datahubentitycontainer"),
                scrollTo = $($this).closest("li");
            container.animate({
                scrollTop: scrollTo.offset().top - container.offset().top + container.scrollTop() - container.height() / 2
            });
        }, 1000);

    }
    loadstoredproceduredetails1 = (element, connectiondata, isSaved) => {
        var $this = element;
        var connId = $($this).attr("data_parent");
        var storedProcName = $($this).attr("name");
        var existingStoredProcDetails;
        var storedProcs = sessionStorage.getItem("storedprocs");
        if (storedProcs !== undefined && storedProcs != null) {
            var storedProcsArray = storedProcs.split(",").map(function (item) {
                return item.trim();
            });
            if (storedProcsArray.indexOf(storedProcName) >= 0) {
                existingStoredProcDetails = JSON.parse(sessionStorage.getItem(storedProcName));
            }
        }
        $("#settingscontainer").remove();
        $("#datahubentitycontainer").after('<div id="settingscontainer"></div>');
        ReactDOM.render(
            <StoreProcedureParameters
                connId={connId}
                storedProcName={storedProcName}
                connectiondata={connectiondata}
                existingStoredProcDetails={existingStoredProcDetails}
                isSaved={isSaved}
                applydatahubmodifyentities={this.applydatahubmodifyentities}
            />,
            document.getElementById('settingscontainer')
        );
    }
    joinremove = (e) => {
        e.preventDefault();
        var $this = e.currentTarget;
        var tablevalue = $($this).parent().find("input").attr("data-value");
        var isSaved = $($this).parent().find("input").attr("is-saved")
        var connectionId = $($this).parent().find("input").attr("data_parent");
        var isCustomQuery = $($this).parent().find("input").hasClass('customquery');
        var isdeleteindex = true;
        if (isSaved !== undefined && isSaved === "true") {
            isdeleteindex = false;
            $(".deleteyes").unbind("click");
            $(".deleteno").unbind("click");
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').addClass('show');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(getMessage('BirdWarning125'));
            $(".deleteyes").click(() => {
                isdeleteindex = true;
                $('.birdmessage-center').removeClass("message-top-in");
                var $this = e.currentTarget;
                $('.spinner').show();
                let params = new FormData();
                params.append('connectionId', connectionId);
                params.append('tablevalue', tablevalue);
                SendRequest({
                    url: "deleteentityfordatahub",
                    queryString: "",
                    body: params,
                    sucessFunction: (response) => {
                        if (response.success == true) {
                            $('.spinner').hide();
                            $(".filtercontent input[data-value='" + tablevalue + "']").parent().parent().draggable({ disabled: false }).removeClass("disabled");
                            $(".filtercontent input[data-value='" + tablevalue + "']").prop('checked', false);
                            _.each($("#datahubentitycontainer .scopeitem"), (i, j) => {
                                var $this1 = $(i);
                                if (connectionId == $($this1).attr('data_parent') && tablevalue == $($this1).attr('data-value')) {
                                    $($this1).parent().parent().remove();
                                    if ($($this1).hasClass("customquery")) {
                                        if (tablevalue.indexOf("_CQ") > 0) {
                                            tablevalue = tablevalue.replace("_CQ", "")
                                        }
                                        var customuqueryval = tablevalue.trim() + "_CQ";
                                        $(".datahub-customquery-item").find("button[data_parent='" + connectionId + "'][name='" + tablevalue + "']").parent().remove()
                                        $("#settingscontainer").remove();
                                        $("#datahubentitycontainer").removeClass('settingsopened');
                                        sessionStorage.removeItem(customuqueryval)
                                        var customQueries = sessionStorage.getItem("customquerySessionObj")
                                        if (customQueries != undefined && customQueries != null) {
                                            sessionStorage.removeItem("customquerySessionObj")
                                            if (customQueries.indexOf(customuqueryval + ",") >= 0) {
                                                var updatedCustomquery = customQueries.replace(customuqueryval + ",", "");
                                                sessionStorage.setItem("customquerySessionObj", updatedCustomquery);
                                            } else if (customQueries.indexOf("," + customuqueryval) >= 0) {
                                                var updatedCustomquery = customQueries.replace("," + customuqueryval, "");
                                                sessionStorage.setItem("customquerySessionObj", updatedCustomquery);
                                            } else if (customQueries.indexOf(customuqueryval) >= 0) {
                                                var updatedCustomquery = customQueries.replace(customuqueryval, "");
                                            }

                                        }
                                    } else if ($($this1).hasClass("storedprocedure")) {
                                        $($this1).parent().parent().remove();
                                        sessionStorage.removeItem($($this1).attr('data-value'));
                                    }
                                    if ($('.datahubitemsettingstabscontainer').attr("name") != undefined && $('.datahubitemsettingstabscontainer').attr("name").trim() == $($this1).attr('data-value') && $('.datahubitemsettingstabscontainer').attr("data-id") == $($this1).attr('data_parent')) {
                                        $('.datahubitemsettingstabscontainer').empty();
                                        $('.datahubitemsettingstabscontainer').remove();
                                        $("#datahubentitycontainer").removeClass('settingsopened');
                                    }
                                }
                            });

                            if (this.allSavedEntities != undefined && this.allSavedEntities != '') {
                                if (this.allSavedEntities[connectionId + "&&&" + tablevalue] != undefined && this.allSavedEntities[connectionId + "&&&" + tablevalue] != '') {
                                    delete this.allSavedEntities[connectionId + "&&&" + tablevalue]
                                } else if (this.allSavedEntities[connectionId + "&&&" + tablevalue + "_CQ"] != undefined && this.allSavedEntities[connectionId + "&&&" + tablevalue + "_CQ"] != '') {
                                    delete this.allSavedEntities[connectionId + "&&&" + tablevalue + "_CQ"]
                                }
                            }
                            var updatedExistingMetaData = [];
                            var existingMetaData = ChartInfo.functions.getExistingEntitiesMetaData();
                            _.each(existingMetaData, (val, index) => {
                                if (val.connAccessId != connectionId || val.entityname != tablevalue) {
                                    updatedExistingMetaData.push(val)
                                }
                            });
                            ChartInfo.functions.setExistingEntitiesMetaData(updatedExistingMetaData)

                            this.resetReportColumns(connectionId + "&&&" + tablevalue);

                            _.each(this.state.datahubEntitiesCollection, (k, l) => {
                                if (connectionId + "&&&" + tablevalue == k.connAccessId + "&&&" + k.entityname) {
                                    var array = [...this.state.datahubEntitiesCollection]; // make a separate copy of the array
                                    var index = array.indexOf(l);
                                    if (index !== -1) {
                                        array.splice(index, 1);
                                        this.setState({ datahubEntitiesCollection: array });
                                    }
                                }
                            });

                            $("#datahubentitycontainer").removeClass('settingsopened');
                        } else {
                            $(".spinner").hide();
                            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                            $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                            $('.birdmessagebuttons').removeClass('show');
                            $('.birdmessagebuttons').addClass('hide');
                            $('.birdmessage-center').addClass("message-top-in");
                            $('.details').removeClass('hide');
                            $('.birdmessage h2').empty().append('Error : ');
                            $('.birdmessage h2').append('<span class="errorcode">');
                            $('.birdmessage .errorcode').empty().append(response.errorCode);
                            $('.birdmessage-info').empty().text(response.error);
                            $('.details').addClass('show');
                            $('.messagedetails xmp').empty().append(response.errorTrace);
                        }
                    },
                    rejectFunction: (error) => { },
                    headers: {
                        method: "post",
                        headers: new Headers({
                            "Content-Type": "application/json"
                        })
                    }
                })
            });
            $(".deleteno").click(() => {
                $('.birdmessage-center').removeClass("message-top-in");
            });
        }
        if (isdeleteindex && !isCustomQuery) {
            $(".filtercontent input[data-value='" + tablevalue + "']").parent().parent().draggable({ disabled: false }).removeClass("disabled");
            $(".filtercontent input[data-value='" + tablevalue + "']").prop('checked', false);
            _.each($("#datahubentitycontainer .scopeitem"), (i, j) => {
                var $this1 = $(i);
                if (connectionId == $($this1).attr('data_parent') && tablevalue == $($this1).attr('data-value')) {
                    $($this1).parent().parent().remove();
                    if ($($this1).hasClass("customquery")) {
                        if ($($this1).attr('is-valid') == 'true') {
                            if (tablevalue.indexOf("_CQ") > 0) {
                                tablevalue = tablevalue.replace("_CQ", "")
                            }
                            var customuqueryval = tablevalue.trim() + "_CQ";
                            $(".datahub-customquery-item").find("button[data_parent='" + connectionId + "'][name='" + tablevalue + "']").parent().remove();
                            $("#settingscontainer").remove();
                            $("#datahubentitycontainer").removeClass('settingsopened');
                            sessionStorage.removeItem(customuqueryval);
                            var customQueries = sessionStorage.getItem("customquerySessionObj");
                            if (customQueries != undefined && customQueries != null) {
                                sessionStorage.removeItem("customquerySessionObj")
                                if (customQueries.indexOf(customuqueryval + ",") >= 0) {
                                    var updatedCustomquery = customQueries.replace(customuqueryval + ",", "");
                                    sessionStorage.setItem("customquerySessionObj", updatedCustomquery);
                                } else if (customQueries.indexOf("," + customuqueryval) >= 0) {
                                    var updatedCustomquery = customQueries.replace("," + customuqueryval, "");
                                    sessionStorage.setItem("customquerySessionObj", updatedCustomquery);
                                } else if (customQueries.indexOf(customuqueryval) >= 0) {
                                    var updatedCustomquery = customQueries.replace(customuqueryval, "");
                                }
                            }
                        } else {
                            $(".datahub-customquery-item").find("button[data_parent='" + connectionId + "'][name='" + tablevalue + "']").parent().remove()
                            $("#settingscontainer").remove();
                            $("#datahubentitycontainer").removeClass('settingsopened');
                        }

                    } else if ($($this1).hasClass("storedprocedure")) {
                        $($this1).parent().parent().remove();
                        sessionStorage.removeItem($($this1).attr('data-value'));
                    }
                    if ($('.datahubitemsettingstabscontainer').attr("name") != undefined && $('.datahubitemsettingstabscontainer').attr("name").trim() == $($this1).attr('data-value') && $('.datahubitemsettingstabscontainer').attr("data-id") == $($this1).attr('data_parent')) {
                        $('.datahubitemsettingstabscontainer').remove();
                        $("#datahubentitycontainer").removeClass('settingsopened');
                    }
                    this.resetReportColumns(connectionId + "&&&" + tablevalue);
                    this.resetCollection();
                    $("#datahubentitycontainer").removeClass('settingsopened');

                }

            });
        }
        if (isCustomQuery && (isSaved == undefined || isSaved != "true")) {
            $($this).parent().addClass("removequery");
            $(".deleteyes").unbind("click");
            $(".deleteno").unbind("click");
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').addClass('show');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(getMessage('BirdWarning139'));
            $(".deleteno").click(() => {
                $('.birdmessage-center').removeClass("message-top-in");
                $("#datahubentitycontainer li.datahub-customquery-item").removeClass("removequery");
            });
            $(".deleteyes").click(() => {
                if (isdeleteindex) {
                    $(".filtercontent input[data-value='" + tablevalue + "']").parent().parent().draggable({ disabled: false }).removeClass("disabled");
                    $(".filtercontent input[data-value='" + tablevalue + "']").prop('checked', false);
                    _.each($("#datahubentitycontainer .scopeitem"), (i, j) => {
                        var $this1 = $(i);
                        if (connectionId == $($this1).attr('data_parent') && tablevalue == $($this1).attr('data-value')) {
                           // $($this1).parent().parent().remove();
                            if ($($this1).hasClass("customquery")) {
                                if ($($this1).attr('is-valid') == 'true') {
                                    if (tablevalue.indexOf("_CQ") > 0) {
                                        tablevalue = tablevalue.replace("_CQ", "")
                                    }
                                    var customuqueryval = tablevalue.trim() + "_CQ";
                                    $(".datahub-customquery-item").find("button[data_parent='" + connectionId + "'][name='" + tablevalue + "']").parent().remove()
                                    $("#settingscontainer").remove();
                                    $("#datahubentitycontainer").removeClass('settingsopened');
                                    sessionStorage.removeItem(customuqueryval)
                                    var customQueries = sessionStorage.getItem("customquerySessionObj")
                                    if (customQueries != undefined && customQueries != null) {
                                        sessionStorage.removeItem("customquerySessionObj")
                                        if (customQueries.indexOf(customuqueryval + ",") >= 0) {
                                            var updatedCustomquery = customQueries.replace(customuqueryval + ",", "");
                                            sessionStorage.setItem("customquerySessionObj", updatedCustomquery);
                                        } else if (customQueries.indexOf("," + customuqueryval) >= 0) {
                                            var updatedCustomquery = customQueries.replace("," + customuqueryval, "");
                                            sessionStorage.setItem("customquerySessionObj", updatedCustomquery);
                                        } else if (customQueries.indexOf(customuqueryval) >= 0) {
                                            var updatedCustomquery = customQueries.replace(customuqueryval, "");
                                        }
                                    }
                                } else {
                                   // $(".datahub-customquery-item").find("button[data_parent='" + connectionId + "'][name='" + tablevalue + "']").parent().remove()
                                    $("#datahubentitycontainer li.datahub-customquery-item.removequery").remove();
                                    $("#datahubentitycontainer li.datahub-customquery-item").removeClass("removequery"); 
                                    $("#settingscontainer").remove();
                                    $("#datahubentitycontainer").removeClass('settingsopened');
                                }

                            } else if ($($this1).hasClass("storedprocedure")) {
                                $($this1).parent().parent().remove();
                                sessionStorage.removeItem($($this1).attr('data-value'));
                                $("#datahubentitycontainer").removeClass('settingsopened');
                            }
                            if ($('.datahubitemsettingstabscontainer').attr("name") != undefined && $('.datahubitemsettingstabscontainer').attr("name").trim() == $($this1).attr('data-value') && $('.datahubitemsettingstabscontainer').attr("data-id") == $($this1).attr('data_parent')) {
                                $('.datahubitemsettingstabscontainer').remove();
                                $("#datahubentitycontainer").removeClass('settingsopened');
                            }
                            this.resetReportColumns(connectionId + "&&&" + tablevalue);
                            this.resetCollection();
                            $("#datahubentitycontainer").removeClass('settingsopened');
                        }
                    });
                    
                }
                $('.birdmessage-center').removeClass("message-top-in");
            });
        }
    }
    editdatahubitem = (e) => {
        e.preventDefault();
        $(".spinner").show();
        var $this = e.currentTarget;
        var that = this.state;
        var connId = $($this).attr("data_parent");
        var entityType = $($this).attr("entityType");
        var entityName = $($this).attr('name').trim();
        var connectiondata = this.connectionaccessdata[connId];
        var cjson = JSON.parse(connectiondata.connectionjson);
        var schemaName = connectiondata.connectionName;
        var entityId;

        connectiondata["entitytype"] = entityType;
        var isSaved = $($this).attr('is-saved') != undefined ? $($this).attr('is-saved') : '';
        $("#datahubentitycontainer>li").removeClass('active');
        that.datahubEntitiesCollection.map((i, j) => {
            if (i.entityname === entityName && i.connAccessId === parseInt(connId)) {
                entityId = i.entityId
            }
        })
        var selectedEntities = {};
        selectedEntities[$($this).attr('data_parent') + "&&&" + $($this).attr('name').trim()] = connectiondata;
        $("#settingscontainer").remove();
        $("#datahubentitycontainer").removeClass('settingsopened');
        $("#datahubentitycontainer").after('<div id="settingscontainer"></div>');
        let x = new FormData();
        x.append('entities', JSON.stringify(selectedEntities));

        SendRequest({
            url: "getentitiesmetadata",
            queryString: "",
            body: x,
            sucessFunction: (response) => {
                if (response.success == false || response.hasOwnProperty("error")) {
                    if (response.status == "fail") {
                        $(".spinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Warning');
                        $('.birdmessage-info').empty().text("The Column Name '" + response.columnName + "'" + "" + getMessage('BirdWarning149'));
                        $(".spinner").hide();
                        return;
                    }
                    else {
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(lang["Datahub.unablefetch"]);
                        $('.details').addClass('show');
                        if (response.success == false || response.hasOwnProperty("error")) {
                            $('.messagedetails xmp').empty().append(response.errorTrace);
                        }
                        else {
                            $('.messagedetails xmp').empty().append(response.error);
                        }
                        $('.spinner').hide();
                        return
                    }
                }
                this.state.entityMetaDataCollection = response
                ReactDOM.render(
                    <DatahubItemSettings
                        metadata={this.state.entityMetaDataCollection}
                        connectionName={connectiondata.connectionName}
                        schemaId={connectiondata.schemaId}
                        schemaName={connectiondata.connectionName}
                        isFromEdit={true}
                        schemaDisplayName={connectiondata.connectionName}
                        filename={cjson.fileName}
                        connectiondata={JSON.stringify(connectiondata)}
                        connectionId={connId}
                        entityName={entityName}
                        entityId={entityId}
                        entityMetaDataCollection={this.state.entityMetaDataCollection}
                        dataHubEntitiesCollection={this.state.datahubEntitiesCollection}
                        isSaved={isSaved}
                        applydatahubmodifyentities={this.applydatahubmodifyentities}
                    />,
                    document.getElementById('settingscontainer')
                );
                $($this).closest("li").addClass('active');
                if (isSaved == "true" || isSaved == true) {
                    $('.applydatahubmodifyentities').addClass('disabled');
                } else {
                    $('.applydatahubmodifyentities').removeClass('disabled');
                }
                $("#settingscontainer").addClass("showsettings");
                $("#datahubentitycontainer").addClass('settingsopened');
                setTimeout(function () {
                    var container = $("#datahubentitycontainer"),
                        scrollTo = $($this).closest("li");
                    container.animate({
                        scrollTop: scrollTo.offset().top - container.offset().top + container.scrollTop() - container.height() / 2
                    });
                }, 1000);
            },
            rejectFunction: (error) => { },
            headers: {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            }
        })
    }
    applydatahubmodifyentities = (e, showMessage) => {
        var that = this;
        if (e !== undefined) {
            e.preventDefault();
        }
        $(".spinner").show();
        var columns = [];
        var selectedEntities = {};
        var conAccessId = $('.applydatahubmodifyentities').attr("id");
        var entity = $('.applydatahubmodifyentities').attr("name").trim();
        var isCustomQuery = $('.applydatahubmodifyentities').attr("data-entityIsCustomQuery") !== undefined ? $('.applydatahubmodifyentities').attr("data-entityIsCustomQuery") : false;
        var connectiondata = JSON.parse(JSON.stringify(that.connectionaccessdata[$('.applydatahubmodifyentities').attr("id")]));
        if (entity === "webdataconnector") {
            columns = this.CreateReportColumnsForWebdata();
        } else {
            columns = this.CreateReportColumns();
        }
        var storedProcs = sessionStorage.getItem("storedprocs");
        var storedProcNames = undefined;
        if (storedProcs !== undefined && storedProcs != null) {
            storedProcNames = storedProcs.split(",").map((item) => {
                return item.trim();
            });
        }
        if ($('#datahubstoredprocedureparameterstab').length > 0) {
            if (storedProcNames !== undefined && storedProcNames.indexOf(entity) >= 0) {
                connectiondata['type'] = 'storedprocedure';
                var tempObj = {};
                var mainStoredProc = [];
                tempObj["params"] = JSON.parse(sessionStorage.getItem(entity));
                mainStoredProc.push(tempObj);
                connectiondata['storedprocparams'] = mainStoredProc;
            } else {
                // for constructing stored procedure object when we open the parameters pop up(when there are no session values)
                var storedProceduresEmptyParamCount = 0;
                var storedProc = [];
                $('.spparametervalue').removeClass('bird-has-error');
                _.each($(".datahubstoredprocedurecontainer table tbody tr"), (html, index) => {
                    var storedProcedureName = $(html).attr('name');
                    var paramType = $(html).attr('paramtype');
                    var parameterName = $(html).find('.spparametername').text();
                    var dataType = $(html).find('.spdatatype').text();
                    var parameterValue = $(html).find('.spparametervalue').val();
                    var storedProcParameters = {};
                    storedProcParameters['name'] = parameterName;
                    storedProcParameters['datatype'] = dataType;
                    storedProcParameters['value'] = parameterValue;
                    if (parameterValue.length === 0) {
                        $(html).find('.spparametervalue').addClass('bird-has-error');
                        storedProceduresEmptyParamCount++;
                    }
                    storedProc.push(storedProcParameters);
                });
                if (storedProceduresEmptyParamCount > 0) {
                    setTimeout(function () {
                        $("#datahubstepscontainer").steps("previous");
                        $('.spinner').hide();
                    }, 800);
                    $('.spinner').hide();
                    return false;
                }
                if (storedProc.length > 0) {
                    sessionStorage.setItem(entity.trim(), JSON.stringify(storedProc));
                    var storedProcs = sessionStorage.getItem("storedprocs");
                    if (storedProcs != undefined) {
                        storedProcs = storedProcs + "," + entity;
                    }
                    else {
                        storedProcs = entity;
                    }
                    sessionStorage.setItem("storedprocs", storedProcs);
                    connectiondata['type'] = 'storedprocedure';
                    var tempObj = {};
                    var mainStoredProc = [];
                    tempObj["params"] = storedProc;
                    mainStoredProc.push(tempObj);
                    connectiondata['storedprocparams'] = mainStoredProc;
                }
            }
        }
        if (isCustomQuery) {
            if (entity.indexOf("_CQ") < 0) {
                entity = entity + "_CQ"
            }
            var customQueryObj = JSON.parse(sessionStorage.getItem(entity.trim()));
            if (customQueryObj === null || customQueryObj === "") {
                setTimeout(() => {
                    $("#datahubstepscontainer").steps("previous");
                    $('.spinner').hide();
                }, 800);
                $('.spinner').hide();
                return false;
            }
            connectiondata = _.clone(this.connectionaccessdata[$('.applydatahubmodifyentities').attr("id")])
            var connectiondetailsobj = JSON.parse(connectiondata.connectionjson)
            connectiondetailsobj["query"] = customQueryObj.query;
            connectiondetailsobj["isCustomQuery"] = isCustomQuery
            connectiondata.connectionjson = JSON.stringify(connectiondetailsobj);
            connectiondata = connectiondata;
        }
        //$('.applydatahubmodifyentities').attr("name")!=undefined  $('.applydatahubmodifyentities').attr("id")!=undefined
        sessionStorage.setItem(conAccessId + "&&&" + entity.trim(), connectiondata.entitytype);
        selectedEntities[conAccessId + "&&&" + entity.trim()] = connectiondata;
        _.each(selectedEntities, (i, j) => {
            i.command = "validateEntities";
            if (columns.length > 0) {
                i.report_columns = columns;
            }
        })
        var dbType = JSON.parse(this.connectionaccessdata[conAccessId].connectionjson).dbType;
        if ((dbType === "rabbitmq") || (dbType === "kafka") || (dbType === "websocket")) {
            this.connectionaccessdata[conAccessId]["report_columns"] = columns;
        }
        var dat = new FormData();
        dat.append("entities", JSON.stringify(selectedEntities));
        SendRequest({
            url: "getentitiesmetadata",
            queryString: "",
            body: dat,
            sucessFunction: (response) => {
                var errorCode = response.errorCode;
                var error = response.error;
                var errorTrace = response.errorTrace;
                var isError = false;
                var key = conAccessId + "&&&" + entity;
                if (response[key] !== undefined && JSON.parse(response[key]).success === false) {
                    var resp =
                        isError = true;
                    errorCode = JSON.parse(response[key]).errorCode;
                    error = JSON.parse(response[key]).error;
                    errorTrace = JSON.parse(response[key]).errorTrace;
                }
                if (response.success === false || isError) {
                    _.each(this.selectedEntities, (i, j) => {
                        if (i["report_columns"] !== undefined) {
                            delete i["report_columns"];
                        }
                        delete i["command"];
                    });
                    $(".spinner").hide();
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('hide');
                    $('.birdmessage h2').empty().append('Error : ');
                    $('.birdmessage h2').append('<span class="errorcode">');
                    $('.birdmessage .errorcode').empty().append(errorCode);
                    $('.birdmessage-info').empty().text(error);
                    $('.details').addClass('show').text("Details");
                    $('.messagedetails xmp').empty().append(errorTrace);
                    setTimeout(() => {
                        $("#datahubstepscontainer").steps("previous");
                        $('.spinner').hide();
                    }, 800);
                } else {
                    _.each(this.selectedEntities, (i, j) => {
                        delete i["command"];
                        if (i["report_columns"] != undefined) {
                            delete i["report_columns"];
                        }
                    });
                    this.updateDataHubEntitiesObject(conAccessId, entity, columns);
                    // this.entityMetaDataCollection= entityMetaDataCollection
                    //  this.entityMetaDataCollection.updateDataHubEntitiesObject(conAccessId,entity,columns);
                    if (showMessage !== undefined && showMessage === false) {
                        this.loadSyncConfiguration();
                    } else {
                        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Success');
                        $('.birdmessage-info').empty().text(lang["Datahub.validsuccess"]);
                        $('.spinner').hide();
                    }
                }
            },
            rejectFunction: (error) => { },
            headers: {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            }
        })
    }
    CreateReportColumns = () => {
        var columnsObj = {};
        var columns = [];
        var dateTypeFormatArray = [];
        var success = true
        var that = this;
        var Visibilitycount = 0;
        var isUnitAvailable = false;
        _.each($('.datahubmodifyentitiestablecontainer tbody tr'), (html, index) => {
            var temp = {};
            var dateTypeFormatMap = {};
            var dateFormat = "";
            temp["id"] = index;
            temp["columnName"] = $(html).find('.tabledisplayname').val().trim();
            temp["tableAliasName"] = $(html).find('input[type=text]').attr("tablealiasname");
            temp["orginalColumnName"] = $(html).find('input[type=text]').attr("orginalcolumnname").trim();
            temp["columnDisplayName"] = $(html).find('.tabledisplayname').val().trim();
            temp["tableDisplayType"] = $(html).find('.datecontroller option:selected').val();

            if (temp["tableDisplayType"] == "date") {
                if ($(html).find('.csvdateformatsdroppdown select option:selected').val() == "others") {
                    dateFormat = $(html).find(".customdate-others").val().trim();
                    if ($(html).find(".customdate-others").val().length == 0) {
                        $(html).find(".customdate-error").removeClass("hide");
                        success = false;
                    } else {
                        $(html).find(".customdate-error").addClass("hide");
                    }
                } else {
                    dateFormat = $(html).find('.csvdateformatsdroppdown select option:selected').text().trim();
                }
            }
            temp["dateFormat"] = dateFormat;
            temp["tableDisplayName"] = $(html).find('input[type=text]').attr("tablename");
            temp["tableName"] = $(html).find('input[type=text]').attr("tablename");
            if ($(html).find('input[type=text]').attr("isPartitionKey")) {
                temp["isPartitionKey"] = 'yes';
            }
            temp["visible"] = 'yes';
            columns.push(temp);
        });
        return columns;
    }
    CreateReportColumnsForWebdata = () => {
        var columnsObj = {};
        var columns = [];
        var dateTypeFormatArray = [];
        var success = true;
        var that = this;
        var Visibilitycount = 0;
        var isUnitAvailable = false;
        _.each($('.datahubmodifyentitiestablecontainer tbody tr'), (html, index) => {
            var temp = {};
            var dateTypeFormatMap = {};
            var dateFormat = "";
            temp["id"] = index;
            temp["columnName"] = $(html).find('.tabledisplayname').val().trim();
            temp["tableAliasName"] = $(html).find('input[type=text]').attr("tablealiasname");
            temp["orginalColumnName"] = $(html).find('input[type=text]').attr("orginalcolumnname").trim();
            temp["columnDisplayName"] = $(html).find('.tabledisplayname').val().trim();
            temp["tableDisplayType"] = $(html).find('.datecontroller option:selected').val();

            if (temp["tableDisplayType"] === "date" || temp["tableDisplayType"] === "datetime") {
                if ($(html).find('.csvdateformatsdroppdown select option:selected').val() === "others") {
                    dateFormat = $(html).find(".customdate-others").val().trim();
                    if ($(html).find(".customdate-others").val().length === 0) {
                        $(html).find(".customdate-error").removeClass("hide");
                        success = false;
                    }
                } else {
                    dateFormat = $(html).find('.csvdateformatsdroppdown.' + temp["tableDisplayType"] + ' select option:selected').text().trim();
                }
            }
            temp["dateFormat"] = dateFormat;
            temp["tableDisplayName"] = $(html).find('input[type=text]').attr("tablename");
            temp["tableName"] = $(html).find('input[type=text]').attr("tablename");
            if ($(html).find('input[type=text]').attr("isPartitionKey")) {
                temp["isPartitionKey"] = 'yes';
            }
            temp["visible"] = 'yes';
            columns.push(temp);
        });
        return columns;
    }
    changedatatypes = (event) => {
        var $this = event.currentTarget;
        var id = $this.name;
        var name = $($this).attr("id");
        var tablename = $this.id.replace(/([#;?&,%. +*~\':"!^$%[\]()<=>|\/@])/g, "\\$1");
        if ($($this).attr("schemaType") !== "mysql" && $($this).attr("schemaType") !== "postgres" && $($this).attr("schemaType") !== "sqlserver" && $($this).attr("schemaType") !== "oracle" && $($this).attr("schemaType") !== "xls" && ($($this).attr("schemaType")&&$($this).attr("schemaType").toLowerCase() !== "peoplesoft")) {
            if ($($this).find("option:selected").attr("value") === "datetime") {
                $(".additionaloptions#" + tablename).not(".datetime#" + tablename).hide();
                $(".datetime#" + tablename).show();
                if ($(".csv-form-control[name='datetime'][tablename='" + tablename + "']").val() === "others") {
                    $(".csvcustomdatefield[name='" + name + "']").show();
                } else {
                    $(".csvcustomdatefield[name='" + name + "']").hide();
                }
            } else if ($($this).find("option:selected").attr("value") === "date") {
                $(".additionaloptions#" + tablename).not(".date#" + tablename).hide();
                $(".date#" + tablename).show();
                if ($(".csv-form-control[name='date'][tablename='" + tablename + "']").val() === "others") {
                    $(".csvcustomdatefield[name='" + name + "']").show();
                } else {
                    $(".csvcustomdatefield[name='" + name + "']").hide();
                }
            } else if ($($this).find("option:selected").attr("value").toLowerCase() === "string" || $($this).find("option:selected").attr("value").toLowerCase() === "number" || $($this).find("option:selected").attr("value") === "Boolean" ||
                $($this).find("option:selected").attr("value") === "month" || $($this).find("option:selected").attr("value") === "day") {
                $(".date#" + tablename).hide();
                $(".datetime#" + tablename).hide();
                $(".csvcustomdatefield[name='" + name + "']").hide();
                $(".customdate-others[name='" + tablename + "']").hide();
                $(".customdate-error").addClass("hide");
                $(".csvdateformatsdroppdown.date[id='" + name + "'] option[value='others']").prop("selected", false);
            } else {
                $(".additionaloptions#" + tablename).hide();
                $(".customdate-others[name='" + tablename + "']").hide();
                $(".csvdateformatsdroppdown.date[id='" + name + "'] option[value='others']").prop("selected", false);
            }
        }
    }
    showCustomFieldForDate = (e) => {
        var $this = e.currentTarget;
        var name = $($this).attr("id");
        if ($($this).find("option:selected").val() == "others") {
            $(".csvcustomdatefield[name='" + name + "']").show();
            $(".customdate-others[name='" + name + "']").show();
        } else {
            $(".csvcustomdatefield[name='" + name + "']").hide();
            $(".customdate-others[name='" + name + "']").hide();
        }
    }
    getUpdatedEntities = (connectionidAndEntity) => {
        return this.datahubentities[connectionidAndEntity];
    }
    getUpdatedreportcolumns = (connectionidAndEntity) => {
        return this.reportcolumns[connectionidAndEntity];
    }
    updateDataHubEntitiesObject = (connectionid, entity, entities) => {
        this.datahubentities[connectionid + "&&&" + entity] = entities;
        this.reportcolumns[connectionid + "&&&" + entity] = entities;
    }
    resetCollection = () => {
        this.datahubdetails = {};
        this.tables = [];
        this.query = {};
        this.datahubentities = {};
    }
    resetReportColumns = (connIdAndEntityName) => {
        this.reportcolumns[connIdAndEntityName] = [];
    }
    render() {
        _.each(this.urlparameters, function (i, u) {
            var parameter = i.split("=");
            if (parameter[0].toLowerCase() === "bottomfooter" && parameter[1].toLowerCase() === "false") {
                this.displayFooter = false;
            }
        });
        return (<DatahubHtml displayFooter={this.displayFooter} url={this.props.url} history={this.props.history} />);
    }
}