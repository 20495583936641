import _ from 'lodash';
import React, { Component } from "react";
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import { lang } from "../js/helpers/utils/languages/language";
import ReactDOM from "react-dom";
const $ = window.$;


export default class CreateRangeFieldView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicdiv: props.dynamicdiv,
            columnDisplayName: props.columnDisplayName,
            columnName: props.columnName,
            aggregation: props.aggregation
        };
        this.render();
    }
    render() {
        ReactDOM.render(<CustomRangeFieldViewTemplate
            dynamicdiv={this.state.dynamicdiv}
            columnDisplayName={this.state.columnDisplayName}
            columnName={this.state.columnName}
        ></CustomRangeFieldViewTemplate>, document.getElementById(this.state.dynamicdiv));
        return (<div></div>);
    }
}

class CustomRangeFieldViewTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        var that = this.props;
        return (
            <li key={that.dynamicdiv} className="boc-list-item singleliitems  customrangefields attributesdimensions" data-type="customrange" data-name={that.columnName} title={that.columnDisplayName}>
                <span className="itemtype">
                    <i className="fa fa-arrows-h"></i>
                </span>
                <span className="itemname">{that.columnDisplayName}</span> <span type="button" data-name={that.columnName} title={that.columnDisplayName} className="opacity-effect createrange" data-edit={lang["storyboard.edit"]}>
                    <i className="fa fa-pencil"></i></span>
                <span type="button" data-name={that.columnName} title={that.columnDisplayName} onClick={this.deletecustomrangefield} className="deletecustomrangefield opacity-effect pull-right marg-left-10">
                    <i className="fa fa-trash"></i>
                </span>
            </li>
        );
    }
    deletecustomrangefield(e) {
        var $this = e.currentTarget;
        var result = $.grep(ChartInfo.functions.getRange(), function (e) { return e.name != $($this).parent().attr("data-name") });
        ChartInfo.functions.setRange(result);
        $('.xaxisattributes li[data-name=' + $($this).parent().attr("data-name") + ']').remove();
        $('.yaxisattributes li[data-name=' + $($this).parent().attr("data-name") + ']').remove();
        $('.legendAttributes li[data-name=' + $($this).parent().attr("data-name") + ']').remove();
        $('.tooltipattributes li[data-name=' + $($this).parent().attr("data-name") + ']').remove();
        $('.zaxisattributes li[data-name=' + $($this).parent().attr("data-name") + ']').remove();
        $(e.currentTarget).parent().parent().remove();
    }
}