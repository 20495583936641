import React,{ useState } from 'react'
import { lang } from "../../js/helpers/utils/languages/language";
const $ = window.$;


export default class AddCustomParameterFilterTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.render();
    }
    componentDidMount(){
        $(".customparameterfiltercheckbox").on("click",function(e){
           e.stopPropagation();
           //e.preventDefault();
        })
    }
    render() {
        var that = this.props;
        return (
            //    <!-- Modal viewreportaddcustomparameterfilter.js-->
            <div className="modal fade in" id="mySmallModal0" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" style={{ display: "block" }}>
                <div className="modal-backdrop fade in"></div>
                <div className="modal-dialog modal-sm modal-sm-400 modal-dialog-sm-center modal-dialog-center" style={{ zIndex: "1050" }}>
                    <div className="modal-content">
                        <div className="modal-header boc-modal-hf-padding">
                            <button type="button" className="close white" id="close" onClick={that.closeParameterModal} data-dismiss="modal" aria-hidden="true">&times;</button>
                            <h4 className="modal-title"><i className="fa fa-sliders fa-rotate-270 fa-solid"></i> {lang["storyboard.chooseparameter"]}</h4>
                        </div>

                        <div className="modal-body scrollsetnew">
                            {(that.customparamterfields != 0) ? (
                                <div className="container-fluid marg-top-5 nopadding">
                                    <input className="form-control form-control-single-filters search-image" onKeyUp={that.singleSavedFiltersSearch} placeholder={lang["storyboard.search"]} id="searchinput" type="text" />
                                </div>
                            ) : null}
                            <div className="container-fluid">
                                {(that.customparamterfields != 0) ? (
                                    <div className="row singlefiltercontent filtercontent dobyfilteritems">
                                        <ul className="list-group scrollsetul">
                                            {that.customparamterfields.map((customparamterfields, index) => {
                                                return <li key={index} className="boc-list-item filterliitem" tite={customparamterfields.parametername} data-type="number" data-valuetype={customparamterfields.parametervaluetype}>
                                                    <label title={customparamterfields.parametername}>
                                                        <input type="checkbox" className="customparameterfiltercheckbox" name={customparamterfields.parametername} data-valuetype={customparamterfields.parametervaluetype} data-value={customparamterfields.parametercurrentvalue} />
                                                        <span className="itemtype">123</span>{customparamterfields.parametername}</label>
                                                </li>
                                            })}
                                        </ul>
                                    </div>
                                ) :
                                    <div className="confirm-dailog queryinfo ">{lang["storyboard.noparameters"]}</div>
                                }
                            </div>
                        </div>
                        <div className="modal-footer boc-modal-hf-padding">
                            <button type="button" className="btn btn-bird" id="btn-bird" onClick={that.closeParameterModal} data-dismiss="modal"><i className="fa fa-times"></i> {lang["storyboard.cancelbtn"]}</button>
                            <button type="button" className="btn btn-bird customparameterfiltermodal" onClick={that.addCustomParameterFilter} id="customparameterfiltermodals"><i className="fa fa-check"></i>{lang["storyboard.applybtn"]}</button>
                        </div>
                    </div>
                </div>
            </div>);
    }
}