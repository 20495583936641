import React from "react";
//import { useScreenshot } from "use-screenshot-hook";
import ReactDOM from 'react-dom'
import SendRequest from "../SendRequest";
import * as ModalProperties from "../js/helpers/utils/modalproperties";
import { getMessage } from '../js/helpers/utils/BirdMessage';
import PubSub from 'pubsub-js'
import jstz from 'jstz';
import * as TabInfo from "../js/helpers/utils/tabinfo";
import ViewReportInfo from '../ViewStoryboard/ViewReportInfo.jsx';
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import * as collection from "../js/helpers/utils/SavedReportCollection";
import SaveCustomStoryboardModal from './SaveCustomStoryboardModal.jsx';
import userCustomScript from '../ViewStoryboard/UserCustomScript';
import QuickTemplate from '../ViewStoryboard/drilldownfilters.jsx';
import { lang } from "../js/helpers/utils/languages/language";
import _ from 'underscore';
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
const $ = window.$;

// export default (props) => {
//   //const classes = useStyles();
//   const { image, takeScreenshot } = useScreenshot();
//   return (
//       <ViewReportHeaderTemplate  {...props} classes={ {image, takeScreenshot} } />
//   )
// }
export default class CustomStoryboardHeaderTemplate extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      isEmbeddedReport: props.isEmbeddedReport,
      isEmbeddedCSReport:props.isEmbeddedCSReport,
      datamodelId: props.datamodelId,
      reportId: props.reportId,
      allfeatures: props.allfeatures,
      reportInfo: props.reportInfo,
      isSharedReport: props.isSharedReport,
      isScheduleReport: false,
      isDirect: 0,
      isDrilldownallChecked: true,
      isStoredProcedure: 0,
      isMultiFact: props.multifact,
      reportRequestFrom: props.reportRequestFrom,
      schematype: "customstoryboard",
      isPurgedReport: false,
      schemaTypeFlag1: "",
      isfavorite: props.isfavorite,
      isMicrosoftEdge: props.isMicrosoftEdge,
      isStoreboardTitleEditClick: false,
      isQuickInfoDisplay: false,
      isFilterModalActive: false,
      isScheduleReportActive: false,
      isSavestoryboardActive: false,
      isFromSmartInsigt: props.isFromSmartInsigt != undefined ? props.isFromSmartInsigt : false
    };
    this.saveStoryboardOpen = this.saveStoryboardOpen.bind(this);
    this.saveStoryboardClose = this.saveStoryboardClose.bind(this);
    this.customScriptOpen = this.customScriptOpen.bind(this);
    this.quickInfoClose = this.quickInfoClose.bind(this);
    
  }
  downloadFunction () {
    //var node = document.getElementById('chart0');
    //var node = document.getElementsByTagName("body")[0];
    var node = document.getElementsByClassName("leftpane")[0];
 $(".spinner").show();
  htmlToImage.toPng(node)
    .then(function (dataUrl) {
      // var img = new Image();
      // img.src = dataUrl;
      // document.body.appendChild(img);

      var a = document.createElement("a");
      a.id = "downloadimage";
      a.href = dataUrl;
      a.target = '_blank';
      a.download = "Storyboard.png";
      a.click();
      $(".spinner").hide();
      

    })
    .catch(function (error) {
      console.error('oops, something went wrong!', error);
      $(".spinner").hide();
    });
  }
  quickInfoClose = () => {
    this.setState({
      isQuickInfoDisplay: false
    })
  }

  saveStoryboardOpen = () => {
    this.setState({
      isSavestoryboardActive: true
    })
  }
  storyboardEmbedLogout(){
    let requestHeaders1 = {
      method: "get",
      headers: new Headers({
        "Content-Type": "application/json"
      })
    };
    SendRequest({
      url: "logoutbird",
      queryString: "",
      sucessFunction: (props) => {
        sessionStorage.removeItem("accessToken");
        sessionStorage.removeItem("reservedwordslist");
        window.localStorage.clear();
        window.location.replace("/");
      },
      rejectFunction: () => { },
      headers: requestHeaders1,
    })
  }
  saveStoryboardClose = () => {
    this.setState({
      isSavestoryboardActive: false
    })
  }
  customScriptOpen = (e) => {
    var chartnumber = $(e.currentTarget).attr('data-num') != undefined ? $(e.currentTarget).attr('data-num') : -1;
    var that = this;
    var usercustomscript = new userCustomScript({
      reportId: that.props.reportId,
      datamodelId: that.props.datamodelId,
      chartnumber: chartnumber,
      // worker:worker,
      features: that.state.allfeatures,
      facttables: that.props.multifact,
      multifact: that.props.multifact,
      multifactentities: that.props.multifactentities
    });
    // $(".usercustomscriptmodaldisplay #usercustomscriptmodal").modal("show");
    ModalProperties.resize();
  }
  maxfiltersdropdownold() {
    PubSub.publish("maxfiltersdropdownold")
  }
  maxcustomparameterfiltersdropdownold() {
    PubSub.publish('maxcustomparameterfiltersdropdownold')
  }
  render() {
   // const classes = this.props.classes;
    var titlemode = "";
    if (this.state.isEmbeddedReport !== true) {
      this.props.allfeatures.map((featuresModule, index) => {
        if (featuresModule.moduleName === "story_board") {
          if (this.state.isSharedReport === false) {
            if (this.state.isScheduleReport !== true) {
              featuresModule.features.map((feature, index) => {
                if (feature.featureName === "viewreport_edit_title" && feature.allow === true) {
                  titlemode = "settingmodal titleeditmode";
                }
              });
            }
          } else {
            featuresModule.features.map((feature, index) => {
              if (feature.featureName === "viewreport_save_shared_report" && feature.allow === true) {
                featuresModule.features.map((feature, index) => {
                  if (feature.featureName === "viewreport_edit_title" && feature.allow === true) {
                    titlemode = "settingmodal titleeditmode";
                  }
                });
              }
            });
          }
        }
      });
    }
    var QuickInformation = <></>;
    this.props.allfeatures.map((featuresModule, index) => {
      featuresModule.features.map((feature, index) => {
        if (feature.featureName === "viewreport_quick_information" && feature.allow === false) {
          QuickInformation = <button type="button" className="btn btn-bird-group quickinfotoggle" id="" title={lang["customboard.quickinfo"]} onClick={() => {
            this.setState({
              isQuickInfoDisplay: !this.state.isQuickInfoDisplay
            })
          }}>
            <i className="fa fa-info-circle"></i>
          </button>;
        }
      });
    });
    var Filters = <></>;
    var EmbedLogout=<></>;
    var Search = <></>;
    var More = <></>;
    var Refresh = <></>;
    var General = <></>;
    var SaveStoryboard = <></>;
    var CloneStoryboard = <></>;
    var LiveStoryboard = <></>;
    var DrilldownAll = <></>;
    var DrilldownSettings = <></>;
    var Audit = <></>;
    var CustomScript = <></>;
    var Customize = <></>;
    var StoryboardTheme = <></>;
    var EditTitle = <></>;
    var NewChartTile = <></>;
    var Favourite = <></>;
    var Collaborate = <></>;
    var ShareStoryboard = <></>;
    var EmbedStoryboard = <></>;
    var ScheduleStoryboard = <></>;
    var Download = <></>;
    var Export = <></>;

    if (this.state.isEmbeddedReport !== true) {
      this.props.allfeatures.map((featuresModule, index) => {
        if (featuresModule.moduleName === "story_board") {
          if (this.state.isSharedReport === false) {
            if (this.state.isScheduleReport === true) {
              featuresModule.features.map((feature, index) => {
                if ((this.state.isDirect === 0 || this.state.isStoredProcedure === 0) &&
                  feature.featureName === "viewreport_embed_report_and_email" && feature.allow === true) {
                  Filters =
                    <span
                      style={{
                        float: "left",
                        position: "relative"
                      }}
                    >
                      <button
                        type="button"
                        className="dropdown-toggle btn btn-bird-group"
                        data-direction="right"
                        data-toggle="dropdown"
                        aria-expanded="true"
                        title={lang["customboard.filters"]}
                      >
                        <i className="fa fa-filter"></i>
                      </button>
                      <ul className="dropdown-menu dropdown-menu-right">
                        <li>
                          <a
                            href="javascript:void(0)"
                            className="modalfilter maxfiltersdropdown"
                            id="maxfiltersdropdowns"
                            onClick={this.maxfiltersdropdownold}
                            data-direction="left"
                            data-toggle="modal"
                            data-target="#mySmallModal"
                            title={lang["customboard.addfilter"]}
                          >
                            <i className="fa fa-plus marg-righ-10"></i>
                            {lang["customboard.addfilter"]}
                                              </a>
                        </li>
                        <li>
                          <a
                            href="javascript:void(0)"
                            className="modalfilter maxcustomparameterfiltersdropdown"
                            id="maxcustomparameterfiltersdropdowns"
                            onClick={this.maxcustomparameterfiltersdropdownold}
                            data-direction="left"
                            data-toggle="modal"
                            data-target="#mySmallModal"
                            title={lang["customboard.addparameter"]}
                          >
                            <i className="fa fa-plus marg-righ-10"></i>
                            {lang["customboard.addparameter"]}
                                              </a>
                        </li>
                      </ul>
                    </span>;
                }
              })
            } else {
              featuresModule.features.map((feature, index) => {
                if ((this.state.isDirect === 0 || this.state.isStoredProcedure === 0) &&
                  feature.featureName === "viewreport_smart_insights" && feature.allow === true) {
                  // <!-- 	<button className="btn btn-bird-group smartinsights" title="Smart Insights"><i className="fa fa-smartinsights"></i></button> -->
                }
                if ((this.state.isDirect === 0 || this.state.isStoredProcedure === 0) &&
                  feature.featureName === "viewreport_ml_storyboard" && feature.allow === true) {
                  // <!-- <button className="btn btn-bird-group mlreport" title="ML Report"><i className="fa fa-mlreport"></i></button> -->
                }
                if ((this.state.isDirect === 0 || this.state.isStoredProcedure === 0) &&
                  feature.featureName === "viewreport_nlp_search" && feature.allow === true &&
                  this.state.isMultiFact === "false") {
                  Search =
                    <button
                      type="button"
                      className="btn btn-bird-group tooltip-bottom intelligentsearch"
                      id="intelligentsearchicon"
                      title={lang["customboard.intelligentsearch"]}
                    >
                      <i className="fa fa-search"></i>
                    </button>;
                }
                if ((this.state.isDirect === 0 || this.state.isStoredProcedure === 0) &&
                  feature.featureName === "viewreport_filters" && feature.allow === false) {
                  // <!-- <button type="button" className="btn btn-bird-group tooltip-bottom addFiltermodal modalfilter" title="Filters" data-toggle="modal" data-target="#mySmallModal"><i className="fa fa-filter"></i></button> -->
                  // <!-- Removed Filters As per New Filters Theme1-->
                  // <!-- <button type="button" className="btn btn-bird-group tooltip-bottom togglefiltersmenu" id="togglefiltersmenunew" title="Filters" data-show="0"><i className="fa fa-filter"></i></button> -->
                  Filters =
                    <span
                      style={{
                        float: "left",
                        position: "relative"
                      }}
                    >
                      <button
                        type="button"
                        className="dropdown-toggle btn btn-bird-group"
                        data-direction="right"
                        data-toggle="dropdown"
                        aria-expanded="true"
                        title={lang["customboard.filters"]}
                      >
                        <i className="fa fa-filter"></i>
                      </button>
                      <ul className="dropdown-menu dropdown-menu-right">
                        <li>
                          <a
                            href="javascript:void(0)"
                            className="modalfilter maxfiltersdropdown"
                            id="maxfiltersdropdowns"
                            onClick={this.maxfiltersdropdownold}
                            data-direction="left"
                            data-toggle="modal"
                            data-target="#mySmallModal"
                            title={lang["customboard.addfilter"]}
                          >
                            <i className="fa fa-plus marg-righ-10"></i>
                            {lang["customboard.addfilter"]}
                        </a>
                        </li>
                        <li>
                          <a
                            href="javascript:void(0)"
                            className="modalfilter maxcustomparameterfiltersdropdown"
                            id="maxcustomparameterfiltersdropdowns"
                            onClick={this.maxcustomparameterfiltersdropdownold}
                            data-direction="left"
                            data-toggle="modal"
                            data-target="#mySmallModal"
                            title={lang["customboard.addparameter"]}
                          >
                            <i className="fa fa-plus marg-righ-10"></i>
                            {lang["customboard.addparameter"]}
                        </a>
                        </li>
                      </ul>
                    </span>;
                }
                if (this.state.isDirect === 0 && this.state.reportRequestFrom === "viewsavedreportview" &&
                  feature.featureName === "viewreport_updatedata" && feature.allow === true) {
                  // <!-- This button should be displayed only in saved storyboard -->
                  // <!-- 														<button type="button" className="btn btn-bird-group tooltip-bottom updateesdata" id="storyboarddataupdateicon" title="Update Data"><i className="fa fa-pencil"></i></button> -->
                }
              });
              if (this.state.schematype === "customstoryboard") {
                Refresh = <button
                  type="button"
                  className="btn btn-bird-group tooltip-bottom customstoryboard refreshcharts"
                  id="refreshchartsicon"
                  onClick={this.redrawchart}
                  title={lang["customboard.refresh"]}
                >
                  <i className="fa fa-refresh"></i>
                </button>;
              } else {
                Refresh = <button
                  type="button"
                  className="btn btn-bird-group tooltip-bottom refreshcharts"
                  onClick={this.redrawchart}
                  id="refreshchartsicon"
                  title={lang["customboard.refresh"]}
                >
                  <i className="fa fa-refresh"></i>
                </button>
              }
            }
          } else if (this.state.isSharedReport === true) {
            featuresModule.features.map((feature, index) => {
              if (feature.featureName === "viewreport_save_shared_report" &&
                feature.allow === true) {
                featuresModule.features.map((feature, index) => {
                  if ((this.state.isDirect === 0 || this.state.isStoredProcedure === 0) &&
                    feature.featureName === "viewreport_filters" && feature.allow === false) {
                    Filters = <span
                      style={{
                        float: "left",
                        position: "relative"
                      }}
                    >
                      <button
                        type="button"
                        className="dropdown-toggle btn btn-bird-group"
                        data-direction="right"
                        data-toggle="dropdown"
                        aria-expanded="true"
                      >
                        <i className="fa fa-filter"></i>
                      </button>
                      <ul className="dropdown-menu dropdown-menu-right">
                        <li>
                          <a
                            href="javascript:void(0)"
                            className="modalfilter maxfiltersdropdown"
                            id="maxfiltersdropdowns"
                            onClick={this.maxfiltersdropdownold}
                            data-direction="left"
                            data-toggle="modal"
                            data-target="#mySmallModal"
                            title={lang["customboard.addfilter"]}
                          >
                            <i className="fa fa-plus marg-righ-10"></i>
                            {lang["customboard.addfilter"]}
                        </a>
                        </li>
                        <li>
                          <a
                            href="javascript:void(0)"
                            className="modalfilter maxcustomparameterfiltersdropdown"
                            id="maxcustomparameterfiltersdropdowns"
                            onClick={this.maxcustomparameterfiltersdropdownold}
                            data-direction="left"
                            data-toggle="modal"
                            data-target="#mySmallModal"
                            title={lang["customboard.addparameter"]}
                          >
                            <i className="fa fa-plus marg-righ-10"></i>
                            {lang["customboard.addparameter"]}
                        </a>
                        </li>
                      </ul>
                    </span>;
                  } else {
                    Refresh = <button
                      type="button"
                      className="btn btn-bird-group tooltip-bottom refreshcharts"
                      onClick={this.redrawchart}
                      id="refreshchartsstory"
                      title={lang["customboard.refresh"]}
                    >
                      <i className="fa fa-refresh"></i>
                    </button>;
                  }
                });
              }
            })
          }
        } else {
          featuresModule.features.map((feature, index) => {
            if (feature.featureName === "viewreport_save_shared_report" &&
              feature.allow === true) {
              featuresModule.features.map((feature, index) => {
                if (feature.featureName === "viewreport_filters" &&
                  feature.allow === false) {
                  Filters = <button
                    type="button"
                    className="btn btn-bird-group tooltip-bottom togglefiltersmenu"
                    id="togglefiltersmenufilter"
                    title={lang["customboard.filters"]}
                    data-show="0"
                  >
                    <i className="fa fa-filter"></i>
                  </button>;
                }
              })
            } else {
              Refresh = <button
                type="button"
                className="btn btn-bird-group tooltip-bottom customstoryboard refreshcharts"
                onClick={this.redrawchart}
                id="filterrefreshcharts"
                title={lang["customboard.refresh"]}
              >
                <i className="fa fa-refresh"></i>
              </button>;
            }
          })
        }
      }
      )
    }
    if (this.state.isEmbeddedReport !== true && this.state.isPurgedReport === false) {
      this.props.allfeatures.map((featuresModule, index) => {
        if (featuresModule.moduleName === "story_board") {
          if (this.state.isSharedReport === false) {
            if (this.state.isScheduleReport === true) {
              featuresModule.features.map((feature, index) => {
                if (feature.featureName === "viewreport_embed_report_and_email" && feature.allow === true) {
                  {/* <!-- <button type="button" className="btn btn-bird-group tooltip-bottom addFiltermodal" title="Filters" data-toggle="modal" data-target="#mySmallModal"><i className="fa fa-filter"></i></button> -->
        <!-- 	<span style="float: left;position: relative;">
                <button type="button" className="dropdown-toggle btn btn-bird-group" data-direction="right" data-toggle="dropdown" aria-expanded="true"><i className="fa fa-filter"></i></button>
                <ul className="dropdown-menu dropdown-menu-right">
                  <li><a href="javascript:void(0)" className="modalfilter maxfiltersdropdown " id="maxfiltersdropdowns" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title="Add Filter"><i className="fa fa-plus marg-righ-10"></i>Add Filter</a></li>
                  <li><a href="javascript:void(0)" className="modalfilter maxcustomparameterfiltersdropdown " id="maxcustomparameterfiltersdropdowns" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title="Add Parameter"><i className="fa fa-plus marg-righ-10"></i>Add Parameter</a></li>
                </ul>
              </span> -->  */}
                }
              }
              );
            } else {
              featuresModule.features.map((feature, index) => {
                if ((feature.featureName === "viewreport_query_info" && feature.allow === true) ||
                  (feature.featureName === "viewreport_save_report" && feature.allow === true) ||
                  (feature.featureName === "viewreport_clone_report" && feature.allow === true) ||
                  (feature.featureName === "viewreport_colortheme" && feature.allow === true) ||
                  (feature.featureName === "viewreport_edit_title" && feature.allow === true) ||
                  (feature.featureName === "viewreport_add_new_tile" && feature.allow === true) ||
                  (feature.featureName === "viewreport_favourites" && feature.allow === true) ||
                  (feature.featureName === "viewreport_share_report" && feature.allow === true) ||
                  (feature.featureName === "viewreport_embed_report_and_email" && feature.allow === true) ||
                  (feature.featureName === "viewreport_schedule_reports" && feature.allow === true)) {
                  featuresModule.features.map((feature, index) => {
                    if ((feature.featureName === "viewreport_query_info" && feature.allow === true) ||
                      (feature.featureName === "viewreport_save_report" && feature.allow === true) ||
                      (feature.featureName === "viewreport_clone_report" && feature.allow === true)) {
                      General =
                        <li className="dropdown-header">
                          {lang["customboard.general"]}
                                </li>
                    }
                  }
                  );
                  featuresModule.features.map((feature, index) => {
                    if (feature.featureName === "viewreport_save_report" && feature.allow === true) {
                      if (this.state.isSharedReport === false) {
                      SaveStoryboard =
                        <li>
                          <a
                            className="savestoryboard"
                            title={lang["customboard.savecustomboard"]}
                            id="dropdownsave"
                            onClick={this.saveStoryboardOpen}
                          >
                            <i className="fa fa-floppy-o marg-righ-10"></i>
                            {lang["customboard.savecustomboard"]}
                                  </a>
                        </li>
                    }
                  }
                  }
                  )
                  featuresModule.features.map((feature, index) => {
                    if (feature.featureName === "viewreport_clone_report" && feature.allow === false) {
                      CloneStoryboard =
                        <li>
                          <a
                            href="javascript:void(0)"
                            className="clonereport"
                            onClick={this.cloneReport}
                            id="clonereports"
                            title={lang["customboard.cloneboard"]}
                          >
                            <i className="fa fa-files-o marg-righ-10"></i>
                            {lang["customboard.cloneboard"]}
                                  </a>
                        </li>
                    }
                  }
                  )
                  featuresModule.features.map((feature, index) => {
                    if (feature.featureName === "live_storyboard" && feature.allow === false) {
                      LiveStoryboard =
                        <li>
                          <a
                            onClick={this.props.liveReportOpen}
                            className={`livestoryboard ${this.state.schemaTypeFlag1}`}
                            id="livestoryboards"
                            title={lang["customboard.liveboard"]}
                          >
                            <i className="fa fa-clock-o fa-flip-horizontal marg-righ-10"></i>
                            {lang["customboard.liveboard"]}
                                  </a>
                        </li>
                    }
                  }
                  )
                  featuresModule.features.map((feature, index) => {
                    if (feature.featureName === "audit_meta_data" && feature.allow === false) {
                      Audit =
                        <li>
                          <a
                            href="javascript:void(0)"
                            className="auditmetadata"
                            id="auditmetadata"
                            data-num="-1"
                            tab-id="0"
                            tab-name=""
                            title={lang["customboard.audit"]}
                            onClick={this.props.auditmetadata}
                          >
                            <span
                              className="fa-stack marg-righ-10"
                              aria-hidden="true"
                            >
                              <i className="fa fa-desktop fa-stack-2x"></i>
                              <i className="fa fa-search fa-stack-1x"></i>
                            </span>
                            {lang["customboard.audit"]}
                                  </a>
                        </li>
                    }
                  }
                  )
                  featuresModule.features.map((feature, index) => {
                    if ((this.state.isDirect === 0 || this.state.isStoredProcedure === 0) &&
                      feature.featureName === "viewreport_drill_down" && feature.allow === true) {
                      DrilldownAll =
                        <li>
                          <a
                            href="javascript:void(0)"
                            className="drilldownall"
                            id="drilldownalls"
                            title={lang["customboard.drilldownall"]}
                          >
                            <i className="fa fa-arrow-down marg-righ-10"></i>
                            {lang["customboard.drilldownall"]}
                                    <div className="drilldowntogglecheckbox">
                              <div className="checkbox checkbox-slider--c checkbox-slider-sm">
                                <label>
                                  <input
                                    type="checkbox"
                                    id="drilldownallchartsinstoryboard"
                                    className=""
                                    checked={(this.state.isDrilldownallChecked) ? "checked" : ""}
                                    onChange={() => {
                                      this.setState({
                                        isDrilldownallChecked: !this.state.isDrilldownallChecked
                                      })
                                    }}
                                  />
                                  <span>
                                    &nbsp;
                                          </span>
                                </label>
                              </div>
                            </div>
                          </a>
                        </li>
                    }
                  }
                  )
                  featuresModule.features.map((feature, index) => {
                    if (feature.featureName === "viewreport_drilldown_settings" && feature.allow === false) {
                      DrilldownSettings =
                        <li>
                          <a
                            onClick={this.drilldownsettings}
                            className="drilldownsettings"
                            title={lang["customboard.drilldownsettings"]}
                          >
                            <i className="fa fa-cog marg-righ-10"></i>
                            {lang["customboard.drilldownsettings"]}
                                  </a>
                        </li>
                    }
                  }
                  )
                  featuresModule.features.map((feature, index) => {
                    if (feature.featureName === "viewreport_custom_script" && feature.allow === false) {
                      CustomScript =
                        <li>
                          <a
                            className="usercustomscript"
                            id="usercustomscript"
                            data-num="-1"
                            tab-id="0"
                            tab-name=""
                            title={lang["customboard.customscript"]}
                            onClick={this.customScriptOpen}
                          >
                            <i className="fa fa-code-fork marg-righ-10"></i>{" "}
                            {lang["customboard.customscript"]}
                                  </a>
                        </li>
                    }
                  }
                  )
                  featuresModule.features.map((feature, index) => {
                    if ((feature.featureName === "viewreport_colortheme" && feature.allow === true) ||
                      (feature.featureName === "viewreport_edit_title" && feature.allow === true) ||
                      (feature.featureName === "viewreport_add_new_tile" && feature.allow === true) ||
                      (feature.featureName === "viewreport_favourites" && feature.allow === true)) {
                      Customize =
                        <>
                          <li className="divider"></li>
                          <li className="dropdown-header">
                          {lang["customboard.customize"]}
                                  </li>
                        </>
                    }
                  }
                  )
                  // featuresModule.features.map((feature, index) => {
                  //   if (feature.featureName === "viewreport_colortheme" && feature.allow === true) {
                  //     StoryboardTheme =
                  //       <li>
                  //         <a
                  //           href="javascript:void(0)"
                  //           id="storyboardcolortheme"
                  //           onClick={this.props.storyboardcolortheme}
                  //           title="Storyboard Theme"
                  //           data-toggle="modal"
                  //           data-target="#storyboardtheme"
                  //         >
                  //           <i className="fa fa-paint-brush marg-righ-10 fa-violet"></i>
                  //                   Storyboard Theme
                  //                 </a>
                  //       </li>
                  //   }
                  // }
                  // )
                  featuresModule.features.map((feature, index) => {
                    if (feature.featureName === "viewreport_edit_title" && feature.allow === true) {
                      EditTitle =
                        <li>
                          <a
                            href="javascript:void(0)"
                            className="settingmodal"
                            id="settingmodals"
                            title={lang["customboard.edittitle"]}
                            onClick={() => {
                              this.setState({
                                isStoreboardTitleEditClick: true
                              });
                            }}
                          >
                            <i className="fa fa fa-pencil marg-righ-10"></i>
                            {lang["customboard.edittitle"]}
                                  </a>
                        </li>
                    }
                  }
                  )
                  featuresModule.features.map((feature, index) => {
                    if (feature.featureName === "viewreport_add_new_tile" && feature.allow === false) {
                      NewChartTile =
                        <li>
                          <a
                            href="javascript:void(0)"
                            className="newcharttile"
                            onClick={this.newcharttile}
                            id="newcharttiles"
                            title={lang["customboard.newcharttile"]}
                          >
                            <i className="fa fa-th marg-righ-10"></i>
                            {lang["customboard.newcharttile"]}
                                  </a>
                        </li>
                    }
                  }
                  )
                  featuresModule.features.map((feature, index) => {
                    if (feature.featureName === "viewreport_favourites" && feature.allow === true) {
                      if (this.state.isfavorite === "Y") {
                        Favourite =
                          <li>
                            <a
                              href="javascript:void(0)"
                              id="setstoryboardasfavourite"
                              onClick={this.props.setAsFavourite}
                              title={lang["customboard.favorite"]}
                            >
                              <i className="fa fa-star fa-yellow marg-righ-10"></i>
                              {lang["customboard.favorite"]}
                                    </a>
                          </li>
                      } else {
                        Favourite =
                          <li>
                            <a
                              href="javascript:void(0)"
                              id="setstoryboardasfavourite"
                              onClick={this.props.setAsFavourite}
                              title={lang["customboard.favorite"]}
                            >
                              <i className="fa fa-star marg-righ-10"></i>
                              {lang["customboard.favorite"]}
                                    </a>
                          </li>
                      }
                    }
                  }
                  )
                  featuresModule.features.map((feature, index) => {
                    if ((feature.featureName === "viewreport_share_report" && feature.allow === true) ||
                      (feature.featureName === "viewreport_embed_report_and_email" && feature.allow === true) ||
                      (feature.featureName === "viewreport_schedule_reports" && feature.allow === true)) {
                      Collaborate =
                        <>
                          <li className="divider"></li>
                          <li className="dropdown-header">
                          {lang["customboard.collaborate"]}
                                  </li>
                        </>
                    }
                  })
                  featuresModule.features.map((feature, index) => {
                    if (feature.featureName === "viewreport_share_report" && feature.allow === true) {
                      ShareStoryboard =
                        <li>
                          <a
                            href="javascript:void(0)"
                            id="sharemodal"
                            title={lang["customboard.shareboard"]}
                            onClick={this.props.showsharestroyboardmodal}
                          >
                            <i className="fa fa-users marg-righ-10"></i>
                            {lang["customboard.sharecustomboard"]}
                                  </a>
                        </li>
                    }
                  }
                  )
                  featuresModule.features.map((feature, index) => {
                    if (feature.featureName === "viewreport_embed_report_and_email" && feature.allow === true) {
                      EmbedStoryboard =
                        <li>
                          <a
                            href="javascript:void(0)"
                            id="embedreportmodal"
                            onClick={this.props.viewEmbedreport}
                            title={lang["customboard.embedboard"]}
                          >
                            <i className="fa fa-code marg-righ-10"></i>
                            {lang["customboard.embedcustomboard"]}
                                  </a>
                        </li>
                    }
                  }
                  )
                  // featuresModule.features.map((feature, index) => {
                  //   if (feature.featureName === "viewreport_schedule_reports" && feature.allow === true) {
                  //     ScheduleStoryboard =
                  //       <li>
                  //         <a
                  //           id="schedulestoryboardreport"
                  //           title="Schedule Storyboard"
                  //           onClick={this.props.showschedulemodal}
                  //         >
                  //           <i className="fa fa-clock-o marg-righ-10"></i>
                  //                   Schedule Storyboard
                  //                 </a>
                  //       </li>
                  //   }
                  // }
                 // )
                  // featuresModule.features.map((feature, index) => {
                  //   if (feature.featureName === "download_storyboard" && feature.allow === true &&
                  //     !this.state.isMicrosoftEdge) {
                  //     Download = <li>
                  //       <a
                  //         type="button"
                  //         className="download"
                  //         id="download"
                  //         title="Download"
                  //         //onClick={(e) => { PubSub.publish("storyboard-download", e) }}
                  //         //onClick={() => classes.takeScreenshot()}
                  //         onClick={this.downloadFunction}
                  //       >
                  //         <i className="fa fa-download fa-flip-horizontal marg-righ-10"></i>
                  //                   Download
                  //                 </a>
                  //     </li>
                  //   }
                  // })
                 
                  // featuresModule.features.map((feature) => {
                  //   if (feature.featureName == 'viewreport_export_report' && feature.allow == true) {
                  //     Export = <li><a href="javascript:void(0)" id="exportstoryboardreport" onClick={this.props.exportstoryboardreport} title="Export"><i class="fa fa-external-link marg-righ-10 " aria-hidden="true"></i>Export</a></li>
                  //   }
                  // })
                  if (this.state.isSharedReport === false && this.state.isEmbeddedCSReport === false) {
                  More =
                    <span>
                      <button
                        type="button"
                        className="btn btn-bird-group tooltip-bottom dropdown-toggle dropdown"
                        id="dropdownmore"
                        title={lang["customboard.more"]}
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="fa fa-ellipsis-v"></i>
                      </button>
                      <ul className="dropdown-menu dropdown-menu-right megadropdown" style={{ overflowX: "hidden" }}>
                        {General}
                        {SaveStoryboard}
                        {CloneStoryboard}
                        {LiveStoryboard}
                        {/* <!-- <%_.each(featuresModule.features,function(feature){
            if(isDirect == 0 && feature.featureName=='change_timezone_storyboard'&& feature.allow==true){%>
              <li><a href="javascript:void(0)" className="changetimezone" id="changetimezones" data-num = "-1" title="TimeZone"><i className="fa fa-globe fa-flip-horizontal marg-righ-10"></i>Time Zone</a></li>
            <%}})%> -->*/}
                        {DrilldownAll}
                        {DrilldownSettings}
                        {Audit}
                        {/* <!-- User Custom Script  --> */}
                        {CustomScript}
                        {/* Save Hash Criteria*/}
                        {/* <!-- <li><a href="javascript:void(0)" className="savehashcriteria" id="savehashcriteria" data-num = "-1" title="Save Hash Criteria"><i className="fa fa-code-fork marg-righ-10"></i> Save Hash Criteria</a></li> --> */}
                        {Customize}
                        {StoryboardTheme}
                        {EditTitle}
                        {NewChartTile}
                        {featuresModule.features.map(
                          (feature, index) => {
                            if (feature.featureName === "viewreport_filters" && feature.allow === true) {
                              // <!-- <li><a href="javascript:void(0)" className="addFiltermodal" title="Filters" data-toggle="modal" data-target="#mySmallModal"><i className="fa fa-filter marg-righ-10"></i>Filters</a> -->
                            }
                          }
                        )}
                        {/* <!-- <li><a href="javascript:void(0)" className="refreshcharts" title="Refresh"><i className="fa fa-refresh marg-righ-10"></i>Refresh</a></li> --> */}
                        {Favourite}
                        {Collaborate}
                        {ShareStoryboard}
                        {EmbedStoryboard}
                        {ScheduleStoryboard}
                        {Download}
                        {Export}

                      </ul>
                    </span>
                }
              }
              }
              );
            }
          } else {
            featuresModule.features.map((feature, index) => {
              if (feature.featureName === "viewreport_save_shared_report" && feature.allow === true) {
                featuresModule.features.map((feature, index) => {
                  if ((feature.featureName === "viewreport_colortheme" && feature.allow === true) ||
                    (feature.featureName === "viewreport_query_info" && feature.allow === true) ||
                    (feature.featureName === "viewreport_edit_title" && feature.allow === true) ||
                    (feature.featureName === "viewreport_add_new_tile" && feature.allow === true) ||
                    (feature.featureName === "viewreport_save_report" && feature.allow === true)) {

                    featuresModule.features.map((feature, index) => {
                      if ((feature.featureName === "viewreport_query_info" && feature.allow === true) ||
                        (feature.featureName === "viewreport_save_report" && feature.allow === true)) {
                        General =
                          <li className="dropdown-header">
                            {lang["customboard.general"]}
                                  </li>
                      }
                    })
                    featuresModule.features.map((feature, index) => {
                      if (feature.featureName === "viewreport_save_report" && feature.allow === true) {
                        if (this.state.isSharedReport === false) {
                        SaveStoryboard =
                          <li>
                            <a
                              className="savestoryboard"
                              title={lang["customboard.savecustomboard"]}
                              id="dropdownsave"
                              onClick={this.saveStoryboardOpen}
                            >
                              <i className="fa fa-floppy-o marg-righ-10"></i>
                              {lang["customboard.savecustomboard"]}
                                      </a>
                          </li>
                      }
                    }
                    }
                    )
                    featuresModule.features.map((feature, index) => {
                      if ((feature.featureName === "viewreport_colortheme" && feature.allow === true) ||
                        (feature.featureName === "viewreport_edit_title" && feature.allow === true) ||
                        (feature.featureName === "viewreport_add_new_tile" && feature.allow === true)) {
                        Customize =
                          <>
                            <li className="divider"></li>
                            <li className="dropdown-header">
                            {lang["customboard.customize"]}
                                    </li>
                          </>
                      }
                    }
                    )
                    featuresModule.features.map((feature, index) => {
                      if (feature.featureName === "viewreport_colortheme" && feature.allow === true) {
                        StoryboardTheme =
                          <li>
                            <a
                              href="javascript:void(0)"
                              id="storyboardcolortheme"
                              onClick={this.props.storyboardcolortheme}
                              title={lang["customboard.boardtheme"]}
                              data-toggle="modal"
                              data-target="#storyboardtheme"
                            >
                              <i className="fa fa-paint-brush marg-righ-10 fa-violet"></i>
                              {lang["customboard.boardtheme"]}
                                    </a>
                          </li>
                      }
                    }
                    )
                    featuresModule.features.map((feature, index) => {
                      if (feature.featureName === "viewreport_edit_title" && feature.allow === true) {
                        EditTitle =
                          <li>
                            <a
                              href="javascript:void(0)"
                              className="settingmodal"
                              title={lang["customboard.edittitle"]}
                              onClick={() => {
                                this.setState({
                                  isStoreboardTitleEditClick: true
                                });
                              }}
                            >
                              <i className="fa fa fa-pencil marg-righ-10"></i>
                              {lang["customboard.edittitle"]}
                                </a>
                          </li>
                      }
                    }
                    )
                    featuresModule.features.map((feature, index) => {
                      if (feature.featureName === "viewreport_add_new_tile" && feature.allow === true) {
                        NewChartTile =
                          <li>
                            <a
                              href="javascript:void(0)"
                              className="newcharttile"
                              onClick={this.newcharttile}
                              title={lang["customboard.newcharttile"]}
                            >
                              <i className="fa fa-th marg-righ-10"></i>
                              {lang["customboard.newcharttile"]}
                              </a>
                          </li>
                      }
                    }
                    )
                    if (this.state.isSharedReport === false && this.state.isEmbeddedCSReport== false) {
                    More = <>
                      <button
                        type="button"
                        className="btn btn-bird-group tooltip-bottom dropdown-toggle dropdown"
                        title={lang["customboard.more"]}
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="fa fa-ellipsis-v"></i>
                      </button>
                      <ul className="dropdown-menu dropdown-menu-right megadropdown">
                        {General}
                        {SaveStoryboard}
                        {Customize}
                        {StoryboardTheme}
                        {EditTitle}
                        {NewChartTile}
                      </ul>
                    </>
                  }
                }
                }
                );
              }
            });
          }
        }
      })
    }
    else {
      this.props.allfeatures.map((featuresModule, index) => {
        if (featuresModule.moduleName === "story_board") {
          featuresModule.features.map((feature, index) => {
            if ((this.state.isDirect === 0 ||
              this.state.isStoredProcedure === 0) &&
              feature.featureName ===
              "viewreport_embeded_filters" &&
              feature.allow === true &&
              this.state.isPurgedReport === false
            ) {
              Filters =
                <>
                  {/* <!--<button type="button" className="btn btn-bird-group tooltip-bottom togglefiltersmenu" title="Filters" data-show="0"><i className="fa fa-filter"></i></button> -->	 */}
                  <span
                    style={{
                      float: "left",
                      position: "relative"
                    }}
                  >
                    <button
                      type="button"
                      className="dropdown-toggle btn btn-bird-group"
                      data-direction="right"
                      data-toggle="dropdown"
                      aria-expanded="true"
                      title={lang["customboard.filters"]}
                    >
                      <i className="fa fa-filter"></i>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-right">
                      <li>
                        <a
                          href="javascript:void(0)"
                          className="modalfilter maxfiltersdropdown"
                          id="maxfiltersdropdowns"
                          onClick={this.maxfiltersdropdownold}
                          data-direction="left"
                          data-toggle="modal"
                          data-target="#mySmallModal"
                          title={lang["customboard.addfilter"]}
                        >
                          <i className="fa fa-plus marg-righ-10"></i>
                          {lang["customboard.addfilter"]}
                        </a>
                      </li>
                      <li>
                        <a
                          href="javascript:void(0)"
                          className="modalfilter maxcustomparameterfiltersdropdown"
                          id="maxcustomparameterfiltersdropdowns"
                          onClick={this.maxcustomparameterfiltersdropdownold}
                          data-direction="left"
                          data-toggle="modal"
                          data-target="#mySmallModal"
                          title={lang["customboard.addparameter"]}
                        >
                          <i className="fa fa-plus marg-righ-10"></i>
                          {lang["customboard.addparameter"]}
                        </a>
                      </li>
                    </ul>
                  </span>
                </>
            }
          });
          if (window.location.pathname.split("/")[3] === "private") {
              EmbedLogout= <button
                type="button"
                className="btn btn-bird-group"
                id="embedlogout"
                title={lang["customboard.logout"]}
                onClick={this.storyboardEmbedLogout}
              >
                <i className="fa fa-power-off"></i>
              </button>
          }
        }
      })
    }

    return (
      <div className="container-fluid">
        <div className="row controlbar-main">
          <div className="col-lg-7 col-xs-7 flex-baseline-div">
            {/* <!-- Edit Title --> */}
            <h2
              className={`boc-viewreport-title ${
                this.state.isEmbeddedReport !== true ? titlemode : null
                }`}
              id="viewreport"
              data-num="storyboard"
              tab-id="0"
              tab-name=""
              title={this.state.title != undefined ? this.state.title : "Custom Storyboard"}
              onClick={() => {
                this.setState({
                  isStoreboardTitleEditClick: true
                });
              }}
            >
              {this.state.title != undefined ? this.state.title : "Custom Storyboard"}
            </h2>
            {/* <!--\Edit Title  --> */}
            <div className="datacount storyboarddatacount hide">
              <span className="publishinfo"></span>
              <span className="filterdatacount"></span>
              <span className="lastexecutedtimestamp"></span>
            </div>
          </div>
          <div className="pull-right marg-left-auto">
            <div className="">
              <div className="col-xs-12 storyboardtoolbarfull">
                <div className="row">
                  <div
                    className="btn-group pull-right dropdown bgcommon"
                    role="group"
                    aria-label="..."
                  >
                    {/* <!-- Quick Info -->  */}
                    {
                    (this.state.isSharedReport === false && this.state.isEmbeddedCSReport === false) ?
                    <button type="button" className="btn btn-bird-group createnewstorybooktab" onClick={this.props.renderpopup} id="" title={lang["customboard.addreport"]}><i className="fa fa-plus"></i></button>
                    :null}
                    {QuickInformation}
                    {/* <!--  Filter &  Refresh Buttons--> */}
                    {Filters}
                    {EmbedLogout}
                    {Search}
                    {Refresh}
                    {More}
                    {this.state.isEmbeddedReport !== true ? (
                      <>
                        {/* <!-- <button type="button" className="btn btn-bird-group fullscreenstoryboard" id="fullscreenstoryboardview" title="Toggle FullScreen"><i className="fa fa-fullscreen fa-full"></i></button> --> */}
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="quickinfotogglecontainer hide">
            {this.props.allfeatures.map((featuresModule, index) => {
              featuresModule.features.map((feature, index) => {
                if (feature.featureName === "viewreport_quick_information" && feature.allow === true) {
                  return (
                    <span className="quickinfotoggle" title={lang["customboard.quickinfo"]}>
                      <i className="fa fa-chevron-down"></i>
                    </span>
                  );
                }
              });
            })}
          </div>
        </div>

        <div id="viewreportinfoview">
          {(!this.state.isFromSmartInsigt) ?
            <ViewReportInfo
              reportInfo={this.props.Report_Info}
              sharedInfo={this.props.sharedInfo}
              scheduleInfo={this.props.scheduleInfo}
              alertsInfo={this.props.alertsInfo}
              filtersArray={this.props.filtersArray}
              isDisplay={this.state.isQuickInfoDisplay}
              close={this.quickInfoClose}
            /> : null}
        </div>
        <div className="append_drilldownallsettingspop"></div>
        <div className="livereportmodaldisplay"></div>
        <div className="usercustomscriptmodaldisplay"></div>
        <div className="savehashcriteriamodaldisplay"></div>
        <div id="changetimezonemodelview"></div>
        <div id="metadataauditview"></div>
        <div id="purgedindicesmodelview"></div>
        <div className="queryinfomodaldisplay"></div>
        <div className="settingmodalheaderdisplay">
          {/* <!-- viewreportsettingview.js --> */}
          <div
            className={`modal fade ${
              this.state.isStoreboardTitleEditClick ? "in" : ""
              }`}
            id="pagesettingsmodal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="pagesettingsmodal"
            aria-hidden="true"
            style={{
              display: this.state.isStoreboardTitleEditClick ? "block" : "none"
            }}
          >
            {/* <div
              className="modal-backdrop fade in"
              style={{ height: "100vh" }}
              onClick={() => {
                this.setState({
                  isStoreboardTitleEditClick: false
                });
              }}
            ></div> */}
            <div className="modal-dialog modal-sm modal-sm-400 modal-dialog-sm-center">
              <div className="modal-content">
                <div className="modal-header boc-modal-hf-padding">
                  <button
                    type="button"
                    className="close white"
                    id="viewreportsettingclose"
                    aria-hidden="true"
                    data-dismiss="modal"
                    onClick={() => {
                      this.setState({
                        isStoreboardTitleEditClick: false
                      });
                    }}
                  >
                    &times;
                  </button>
                  <h4 className="modal-title">
                    <i className="fa fa-pencil"></i> {lang["customboard.edittitle"]}
                  </h4>
                </div>
                <div className="modal-body scrollset">
                  <div className="container-fluid">
                    <div className="row filtercontent">
                      <div className="validationgroup">
                        <span className="required right legend"></span>
                        <legend className="subhead">{lang["customboard.boardtitle"]}</legend>
                        {/* <!-- <input type="name" id="titlename" value="<%=title%>" name="titlename" className="modal-form-fields" placeholder="Storyboard Title"> --> */}
                        <div className="col-xs-12 nopadding">
                          <textarea
                            id="titlename"
                            defaultValue={this.props.title}
                            rows="1"
                            name="titlename"
                            className="modal-form-fields resize-vertical"
                            placeholder={lang["customboard.boardtitle"]}
                          ></textarea>
                        </div>
                        <span className="help-block hidden marg-left-10"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer boc-modal-hf-padding">
                  <button
                    type="button"
                    className="btn btn-bird"
                    id="btn-birdstorytitle"
                    data-dismiss="modal"
                    onClick={() => {
                      this.setState({
                        isStoreboardTitleEditClick: false
                      });
                    }}
                  >
                    <i className="fa fa-times"></i>{lang["customboard.cancelbtn"]}
                  </button>
                  <button
                    type="button"
                    className="btn btn-bird applysetting"
                    id="applysettings"
                    onClick={this.applyedittitle}
                  >
                    <i className="fa fa-check"></i> {lang["customboard.applybtn"]}
                  </button>
                </div>
              </div>
              {/* <!-- /.modal-content --> */}
            </div>
            {/* <!-- /.modal-dialog --> */}
          </div>
          {/* <!-- /.Page Settings Modal --> */}
        </div>
        <div id="filtermodal"></div>
        <div className="tilefilterscontainer"></div>
        <div className="savestoryboarddiv">
          <SaveCustomStoryboardModal
            {...this.props}
            features={this.props.allfeatures}
            datamodelId={this.props.datamodelId}
            reportName={this.props.reportName}
            reportDesc={this.props.reportDesc}
            isDisplay={this.state.isSavestoryboardActive}
            close={this.saveStoryboardClose}
          />
        </div>
        {/* {classes.image && <img src={classes.image} />} */}
        <div className="sharediv"></div>
        <div className="addreportthememodal"></div>
        <div className="addstoryboardcolorthememodal"></div>
        <div className="embedreportdiv"></div>
        <div id="schedulereportdiv" className="schedulereportdiv"></div>
        <div id="advancedaccesspermissonscontainer"></div>
        <div id="advancedaccesspermissonscontainer"></div>
        <div
          className="alert alert-success clonesinglereportsuccess"
          style={{ display: "none" }}
          role="alert"
        >
         {lang["customboard.clonemsg"]}
        </div>
        <div
          className="alert alert-danger clonesinglereporterror"
          style={{ display: "none" }}
          role="alert"
        >
          {lang["customboard.errormsg"]}
        </div>
        <div id="singlereportchartsereiscolors"></div>
      </div>
    );
  }
  redrawchart = () => {
    var that = this;
    var isSmartInsightsEnable = $('.storyboardtabs .smartinsights').hasClass('active')
    if (!isSmartInsightsEnable) {
      $('.instant-floating-filters').hide();
      //$('.addInstanceFilterData').empty();
      $('.quickfiltercontainer').empty();
      $(".clonetile").removeClass("disabled");
      if ($('.acc-content').length == 0 && $('.addInstanceFilterData').text().trim().length == 0) {
        $('.instant-floating-filters').hide();
      }
      //sessionStorage.removeItem("instantExcludeFilters");
      //sessionStorage.removeItem("instantIncludeFilters");
      //sessionStorage.removeItem("instantExcludeCustomFilters");
      //ChartInfo.setInstanceIncludeFilterData("");
      //ChartInfo.setInstanceFilterData("");
      //ChartInfo.setIncludeFilterData("");
      //ChartInfo.setInstanceFilterData("");
      var drilldown = [];
      ChartInfo.functions.setdrillfitlerArray(drilldown);
      if(window.location.pathname.indexOf("viewstorybook")<0){
        TabInfo.functions.updateDrilldownFilterFromSession(TabInfo.functions.getCurrentTabId(), undefined);
      }
      //ChartInfo.getFilters(false);
      var timeZone;
      var TimeZoneSettingsValueForstoryboard = ChartInfo.functions.getTimeZoneSettings("-1");
      if (TimeZoneSettingsValueForstoryboard == "localtimezone") {
        timeZone = jstz.determine().name();
      }
      var isPurgedReport = false;
      if (this.props.multifact == false) {
        let requestHeaders = {
          method: "get",
          headers: new Headers({
            "Content-Type": "application/json"
          })
        };
        SendRequest({
          url: "gettotaldatacount",
          queryString: "?reportId=" + this.props.reportInfo[0].axis[0].mainreportid
          + " &datamodelId=" + that.props.datamodelId + " &timeZone=" + timeZone + "&isPurgedReport=" + isPurgedReport + "&isImportedReport=" + that.props.isImportedReport + "&isPublished=" + 'false',
          sucessFunction: response => {
            that.state.datacount = response;
            $(".publishinfo").text("Dataset Count: " + response.size);
            if (response.lastexecutedtimestamp != undefined && response.lastexecutedtimestamp.length > 0) {
              $(".lastexecutedtimestamp").text("Last Data Sync: " + response.lastexecutedtimestamp);
            }
          },
          rejectFunction: () => { },
          headers: requestHeaders
        });
      }
      $('.gridspinner').show();
      $(".insightavgsuggestion1 #spinnerchart9").hide();
      $(".simulationselectioncontainer #spinnerchart10").hide();
      $('.showtrending').removeClass('active');
      $('.trendtype').removeClass('showactive');
      //ChartInfo.getFilters(false);

      var tilecount = $('.tileview').length;
      setTimeout(function () {
        _.each(tilecount, function (tilecount, i) {
          if ($('.fullscreenmain .maxanim8').length == 1) {
          } else {
            if ($('.tileview[data-num=' + i + ']').length == 1) {
              var elem = $('.tileview[data-num=' + i + ']');
              var eastbar = elem.find(".ui-resizable-handle.ui-resizable-se").first();
              var pageX = eastbar.offset().left;
              var pageY = eastbar.offset().top;

              (eastbar.trigger("mouseover")
                .trigger({ type: "mousedown", which: 1, pageX: pageX, pageY: pageY })
                .trigger({ type: "mousemove", which: 1, pageX: pageX - 1, pageY: pageY })
                .trigger({ type: "mousemove", which: 1, pageX: pageX, pageY: pageY })
                .trigger({ type: "mouseup", which: 1, pageX: pageX, pageY: pageY }));
            }
          }
        });
        $('.ui-resizable-handle').css("display", "none");
      }, 2000);
      var charts = ChartInfo.functions.getAllChartDetails();
      var legendfilter = [];
      ChartInfo.functions.setLegendFilterobjForAllCharts(legendfilter);
      _.each(charts, function (chart, j) {
        var chart = JSON.parse(chart);
        ChartInfo.functions.setLegendFilterobj(legendfilter, j);
        sessionStorage.removeItem("drilldown" + j);
        ChartInfo.functions.setTempSummaryTableFilterValues(chart["savedSummaryFilterArray"], j);
      });
      PubSub.publish('applyfiltesandredrawchart');
      sessionStorage.removeItem("smartinsightsfilter");
      $(".pie-menu").remove();
      $(".fullscreensample").removeClass("nopie");
      var firstChartNumber = Object.keys(ChartInfo.functions.getAllChartDetails())[0];
      var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(firstChartNumber));
      var drillDownAllFilter = _.unique(chartDetails["drillDownAllFilter"],'columnname');
      $(".quickfiltercontainer").empty();
      $(".boc-dark-background>.container-fluid>.row>.quickfiltercontainer").attr("drilled-chart", this.props.chartnumber);
      _.each(drillDownAllFilter, function (i, j) {
        var name = decodeURIComponent(drillDownAllFilter[j].columndisplayname);
        if (name === null) {
          name = decodeURIComponent(drillDownAllFilter[j].name);
        }
        else if (name == "undefined") {
          name = decodeURIComponent(drillDownAllFilter[j].displayName);
          if (drillDownAllFilter[j].customfield != undefined) {
            name = drillDownAllFilter[j].customfield.name;
          }
        }
        if (drillDownAllFilter[j].type == "date") {
          name = $(name).find('li:first').attr('column-display-name');
          if (name === undefined) {
            name = drillDownAllFilter[j].displayName;
          }
        }

        var today = new Date();
        var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
        $(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
        ReactDOM.render(<QuickTemplate dynamicdiv={dynamicdiv} columnDisplayName={name} filterValues={drillDownAllFilter[j].data} />, document.getElementById(dynamicdiv));
        $(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "]").append(document.getElementById("drilldownfiltersjsxid" + dynamicdiv))
        $("#" + dynamicdiv).remove();
        $(".quickfiltercontainer[tab-id=" + TabInfo.functions.getCurrentTabId() + "] ."+dynamicdiv).remove();
        // ReactDOM.render(<QuickTemplate columnDisplayName={name} filterValues={drillDownAllFilter[j].data} />,
        //   document.querySelector('.quickfiltercontainer')
        // );
      });
      if (that.state.isSharedReport != true && ChartInfo.functions.getisViewOnlyFlag() != true) {
        $(".quickfilterremove").html("<i title="+lang["customboard.remove"]+" i class='fa fa-times'></i>");
        $('.quickfilterremove').click((e) => { PubSub.publish("quickfilterremove", e) });
      }
    } else {
      var that = this;
      if (that.props.reportId == null || that.props.reportId == undefined) {
        $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
        $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
        $('.birdmessagebuttons').removeClass('show');
        $('.birdmessagebuttons').addClass('hide');
        $('.birdmessage-center').addClass("message-top-in");
        $('.details').removeClass('show');
        $('.details').addClass('hide');
        $('.birdmessage h2').empty().append('Information');
        $('.birdmessage-info').empty().text(getMessage('BirdInformation01'));
      } else {
        $(".spinner").show();
        $(".cancelmodal").click();
        setTimeout(function () {
          $(".smartinsightsclick").click();
          $(".storyboardtabs ul.tabcontainer").removeClass("hide");
          $(".smartinsightintro-container").removeClass("hide");
          $(".smartinsightdatacontainer").removeClass("hide");
          var globalfilterArray = ChartInfo.functions.getFilterArray();
          // require(['views/viewreport/smartinsights/smartinsightsview'], function(SmartInsightsView){
          // 	$(".smartinsightintro-container").removeClass("hide");
          // 	var smartInsightsView = new SmartInsightsView({
          // 		dimensions : that.props.viewreportCollection.getDimensions(),
          // 		measures:that.props.viewreportCollection.getMeasures(),
          // 		reportId : that.props.reportId,
          // 		datamodelId: that.props.datamodelId,
          // 		features:that.props.features,
          // 		viewreportCollection:that.props.viewreportCollection,
          // 		isPurgedReport:false,
          // 		schemaType:that.state.schemaType,
          // 		globalfilterArray:JSON.stringify(globalfilterArray)
          // 	});		
          // });
          $(".spinner").hide();
        }, 1500);
      }
    }
    //Execute Custom Script
    var userCustomScript = ChartInfo.functions.getUserCustomScript("all");
    userCustomScript = userCustomScript[Object.keys(userCustomScript)];

    function executeUserCustomCode() {
      var emptycharts = $(".maxanim10:empty").length;
      if (emptycharts == 0) {
        setTimeout(function () {
          eval(decodeURI(userCustomScript));
          // try {
          //   Function(decodeURI(userCustomScript))();
          // } catch (e) {
          //   console.log(e.message);
          // }
          $(".doby-grid-viewport").on("scroll", function () {
            eval(decodeURI(userCustomScript));
            // try {
            //   Function(decodeURI(userCustomScript))();
            // } catch (e) {
            //   console.log(e.message);
            // }
          });
        }, 1000);
      } else {
        setTimeout(function () {
          executeUserCustomCode();
        }, 2000);
      }
    }
    setTimeout(function () { executeUserCustomCode(); }, 2000);
  }
  cloneReport = (e) => {
    var that = this;
    if (this.state.reportId == null || this.state.reportId == undefined || this.state.reportId == "" || window.location.pathname.indexOf("importreport") >= 0 || window.location.pathname.indexOf("sharereport") >= 0) {
      $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
      $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
      $('.birdmessagebuttons').removeClass('show');
      $('.birdmessagebuttons').addClass('hide');
      $('.birdmessage-center').addClass("message-top-in");
      $('.details').removeClass('show');
      $('.details').addClass('hide');
      $('.birdmessage h2').empty().append('Information');
      $('.birdmessage-info').empty().text(getMessage("BirdInformation01"));
    } else {
      let requestHeaders = {
        method: "post",
        headers: new Headers({
          "Content-Type": "application/json"
        })
      };

      var requestbody = new FormData();
      requestbody.set("reportId", that.state.reportId);
      SendRequest({
        url: "clonereport",
        body: requestbody,
        sucessFunction: (response) => {
          if (response.status == 'success') {
            $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
            $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Success');
            $('.birdmessage-info').empty().text(getMessage('BirdSuccess02'));
          } else if (response.hasOwnProperty('error')) {
            $(".spinner").hide();
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('hide');
            $('.birdmessage h2').empty().append('Error : ');
            $('.birdmessage h2').append('<span class="errorcode">');
            $('.birdmessage .errorcode').empty().append(response.errorCode);
            $('.birdmessage-info').empty().text(response.error);
            $('.details').addClass('show');
            $('.messagedetails xmp').empty().append(response.errorTrace)
          }
        },
        rejectFunction: this.reportDataError,
        headers: requestHeaders
      });
    }
  }
  drilldownsettings = () => {
    PubSub.publish('drilldownsettings');
  }
  newcharttile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    PubSub.publish('newcharttile');
  }
  applyedittitle = () => {
    var titlename = $('.settingmodalheaderdisplay').find("#titlename").val();
    if(collection.model!="" && collection.model!=undefined){
    collection.model.title = titlename;
    }
    $(".boc-viewreport-title[data-num=storyboard]").text(titlename).attr("title", titlename);
    this.setState({
      isStoreboardTitleEditClick: false
    });
  }
}
