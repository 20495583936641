import React from 'react';
import _ from 'underscore';
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;
export default class DatamodelExistingEntitiesHtml extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <>
                {
                    this.props.entites.map((entity, index) => {
                        if (entity.entityType != "customquery") {
                            return (
                                <li key={index} item-expanded="true" className={`viewreport-list-item ui-draggable ui-draggable-handle ${(entity.fact == 1) ? "activefact" : ""}`}>
                                    <label title={entity.entityname}>
                                        <i className="fa fa-table"></i>
                                        <input type="checkbox" durable="false" data_type="existing" className="scopeitem mysqltablevalue" data_parent={entity.workspaceentityid} id={entity.entityname} data-value={entity.entityname} model-entity-Id={entity.datamodelEntityId} />{entity.entityname}
                                    </label>
                                    <button className="btn btn-transparent btn-sm editdatamodelitem" data_type="existing" data_parent={entity.workspaceentityid} data-value={entity.entityname} model-entity-Id={entity.datamodelEntityId} type="button" title={lang["models.settings"]} onClick={this.props.editdatamodelitem}><i className="fa fa-cog"></i></button>
                                    <button className="btn btn-transparent btn-sm joinremove" data_type="existing" type="button" data_parent={entity.workspaceentityid} data-value={entity.entityname} model-entity-Id={entity.datamodelEntityId} onClick={this.props.joinremove}><i className="fa fa-times"></i></button>
                                </li>
                            );
                        }
                    })
                }
            </>
        );
    }
}