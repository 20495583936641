import React from 'react';
import ReactDOM from 'react-dom'
import PubSub from 'pubsub-js'
import $ from 'jquery/dist/jquery';
import { lang } from "../js/helpers/utils/languages/language";


export default class StoryboardSmartInsightsChooseColumnTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.OnCancle = this.OnCancle.bind(this);
        this.search = this.search.bind(this);
    }
    OnCancle() {
        ReactDOM.unmountComponentAtNode(document.querySelector("#smartinsightchoosefielddiv.smartinsightchoosefielddiv" + this.props.chartnumber));
    }
    search(e) {
        var $this = e.currentTarget;
        var valThis = $($this).val().toLowerCase();
        $("input:checkbox[class='drilldowncheckbox']").parents(".boc-list-item").each(function () {
            var text = $(this).text().toLowerCase();
            (text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
        });
    }
    render() {
        var that = this;
        var convertedTypes = this.props.convertedTypes;
        return (

            //   StoryboardSmartInsightsView.js
            <div className="modal fade in" id="smartinsight-choosefields" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" style={{ display: "block" }}>
                <div className="modal-backdrop fade in"></div>
                <div className="modal-dialog modal-sm modal-sm-400 modal-dialog-sm-center modal-dialog-center" style={{ zIndex: "1050" }}>
                    <div className="modal-content">
                        <div className="modal-header boc-modal-hf-padding">
                            <button type="button" className="close white" id="close" onClick={this.OnCancle} aria-hidden="true">&times;</button>
                            <h4 className="modal-title" id="mySmallModalLabel"><i className="fa fa-arrow-down"></i> {lang["storyboard.choosefields"]}</h4>
                        </div>
                        <div className="modal-body scrollset">
                            <div className="container-fluid marg-top-5 nopadding">
                                <label><span className="targetcolumn-text"></span></label>
                            </div>
                            <div className="container-fluid marg-top-5 nopadding">
                                <input className="form-control search-image" onKeyUp={this.search} placeholder={lang["storyboard.search"]} id="searchinput" type="text"></input>
                            </div>
                            <div className="container-fluid">
                                <div className="row filtercontent dobyfilteritems">
                                    <ul className="list-group scrollsetul">
                                        {/* <li className="dropdown-header prioritycol prioritycolumns"><i className="fa fa-magic fa-solid" aria-hidden="true"></i> &nbsp;{lang["storyboard.priorityclmns"]}</li> */}
                                        {(that.props.pos == "max") ? (
                                            that.props.columns.map((valueObject, index) => {
                                                if (valueObject.formula != undefined) {
                                                }/* else if (valueObject.priorityColumn) {
                                                    return (
                                                        <li key={index} className="boc-list-item drilldownitem" tite={valueObject.tablename} data-type={valueObject.tableDisplayType}>
                                                            <label><input type="checkbox" name="drilldown" className="drilldowncheckbox" data-type={valueObject.tableDisplayType} data-parent={valueObject.tableName} data-child={valueObject.columnName} data-name={valueObject.columnDisplayName} data-formula="" ></input>
                                                                {(valueObject.tableDisplayType == 'date' || valueObject.tableDisplayType == 'datetime' || valueObject.tableDisplayType == 'month' || valueObject.tableDisplayType == 'day') ? (
                                                                    <><span className="itemtype"><i className="fa fa-calendar"></i></span>{valueObject.columnDisplayName}</>
                                                                ) : (valueObject.tableDisplayType == 'string') ? (
                                                                    <><span className="itemtype">{lang["storyboard.abc"]}</span>{valueObject.columnDisplayName}</>
                                                                ) : (valueObject.tableDisplayType == 'number') ? (
                                                                    <><span className="itemtype">123</span>{valueObject.columnDisplayName}</>
                                                                ) : ""}
                                                            </label>
                                                        </li>
                                                    );
                                                }*/
                                            })
                                        ) : (
                                                that.props.columns.map((valueObject, index) => {
                                                    if (valueObject.formula != undefined) {
                                                    } /*else if (valueObject.priorityColumn) {
                                                        return (
                                                            <li key={index} className="boc-list-item drilldownitem" tite={valueObject.tablename} data-type={valueObject.tableDisplayType}>
                                                                <label><input type="checkbox" name="drilldown" className="drilldowncheckbox" id="drilldowncheckboxes1" data-type={valueObject.tableDisplayType} data-parent={valueObject.tableName} data-child={valueObject.columnName} data-name={valueObject.columnDisplayName} data-formula="" ></input>
                                                                    {(valueObject.tableDisplayType == 'date' || valueObject.tableDisplayType == 'datetime' || valueObject.tableDisplayType == 'month' || valueObject.tableDisplayType == 'day') ? (
                                                                        <><span className="itemtype"><i className="fa fa-calendar"></i></span>{valueObject.columnDisplayName}</>
                                                                    ) : (valueObject.tableDisplayType == 'string') ? (
                                                                        <><span className="itemtype">{lang["storyboard.abc"]}</span>{valueObject.columnDisplayName}</>
                                                                    ) : (valueObject.tableDisplayType == 'number') ? (
                                                                        <><span className="itemtype">123</span>{valueObject.columnDisplayName}</>
                                                                    ) : ""}
                                                                </label>
                                                            </li>
                                                        );
                                                    }*/
                                                })
                                            )}
                                        {/* TO Add calculatedfields in drillDown modal */}
                                        {that.props.calculatedFileds.map((valueObject, index) => {
                                            /*if (convertedTypes != null && valueObject.priorityColumn) {
                                                if (convertedTypes[valueObject.name] != undefined) {
                                                    return (
                                                        <li key={index} className="boc-list-item drilldownitem" tite={valueObject.name} data-type={convertedTypes[valueObject.name]}>
                                                            <label>
                                                                <input type="checkbox" name="drilldown" className="drilldowncheckbox" data-type={convertedTypes[valueObject.name]} data-parent={valueObject.name} data-child={valueObject.name} data-name={valueObject.name} data-formula="" ></input>
                                                                {(convertedTypes[valueObject.name] == 'date' || convertedTypes[valueObject.name] == 'datetime' || convertedTypes[valueObject.name] == 'month' || convertedTypes[valueObject.name] == 'day') ? (
                                                                    <><span className="itemtype"><i className="fa fa-calendar"></i></span>{valueObject.name}</>
                                                                ) : (convertedTypes[valueObject.name] == 'string') ? (
                                                                    <><span className="itemtype">{lang["storyboard.abc"]}</span>{valueObject.name}</>
                                                                ) : (convertedTypes[valueObject.name] == 'number') ? (
                                                                    <><span className="itemtype">123</span>{valueObject.name}</>
                                                                ) : ""}
                                                            </label>
                                                        </li>
                                                    );
                                                } else if (valueObject.functiontype != "raw") {
                                                    return (
                                                        <li key={index} className="boc-list-item drilldownitem" tite={valueObject.name} data-type={valueObject.dataType}>
                                                            <label><input type="checkbox" name="drilldown" className="drilldowncheckbox" data-type={valueObject.dataType} data-parent={valueObject.name} data-child={valueObject.name} data-name={valueObject.name} data-formula="" ></input>
                                                                {(valueObject.dataType == 'date' || valueObject.dataType == 'datetime' || valueObject.dataType == 'month' || valueObject.dataType == 'day') ? (
                                                                    <><span className="itemtype"><i className="fa fa-calendar"></i></span>{valueObject.name}</>
                                                                ) : (valueObject.dataType == 'string') ? (
                                                                    <><span className="itemtype">{lang["storyboard.abc"]}</span>{valueObject.name}</>
                                                                ) : (valueObject.dataType == 'number') ? (
                                                                    <><span className="itemtype">123</span>{valueObject.name}</>
                                                                ) : ""}</label>
                                                        </li>
                                                    );
                                                } else {
                                                    return (
                                                        <li key={index} className="boc-list-item drilldownitem" tite={valueObject.name} data-type="string">
                                                            <label><input data="hello" type="checkbox" name="drilldown" className="drilldowncheckbox" data-type="string" data-parent={valueObject.name} data-child={valueObject.name} data-name={valueObject.name} data-formula="" ></input>
                                                                <span className="itemtype">{lang["storyboard.abc"]}</span>{valueObject.name}</label>
                                                        </li>
                                                    );
                                                }
                                            }  if (valueObject.functiontype != "raw" && valueObject.priorityColumn) {
                                                return (
                                                    <li key={index} className="boc-list-item drilldownitem" tite={valueObject.name} data-type={valueObject.dataType}>
                                                        <label><input type="checkbox" name="drilldown" className="drilldowncheckbox" data-type={valueObject.dataType} data-parent={valueObject.name} data-child={valueObject.name} data-name={valueObject.name} data-formula="" ></input>
                                                            {(valueObject.dataType == 'date' || valueObject.dataType == 'datetime' || valueObject.dataType == 'month' || valueObject.dataType == 'day') ? (
                                                                <><span className="itemtype"><i className="fa fa-calendar"></i></span>{valueObject.name}</>
                                                            ) : (valueObject.dataType == 'string') ? (
                                                                <><span className="itemtype">{lang["storyboard.abc"]}</span>{valueObject.name}</>
                                                            ) : (valueObject.dataType == 'number') ? (
                                                                <><span className="itemtype">123</span>{valueObject.name}</>
                                                            ) : ""}</label>
                                                    </li>
                                                );
                                            }
                                            else if (valueObject.priorityColumn) {
                                                return (
                                                    <li key={index} className="boc-list-item drilldownitem" tite={valueObject.name} data-type={valueObject.dataType}>
                                                        <label><input type="checkbox" name="drilldown" className="drilldowncheckbox" data-type={valueObject.dataType} data-parent={valueObject.name} data-child={valueObject.name} data-name={valueObject.name} data-formula="" ></input>
                                                            {(valueObject.dataType == 'date' || valueObject.dataType == 'datetime' || valueObject.dataType == 'month' || valueObject.dataType == 'day') ? (
                                                                <><span className="itemtype"><i className="fa fa-calendar"></i></span>{valueObject.name}</>
                                                            ) : (valueObject.dataType == 'string') ? (
                                                                <><span className="itemtype">{lang["storyboard.abc"]}</span>{valueObject.name}</>
                                                            ) : (valueObject.dataType == 'number') ? (
                                                                <><span className="itemtype">123</span>{valueObject.name}</>
                                                            ) : ""}</label>
                                                    </li>
                                                );
                                            }*/
                                        })}
                                        <li className="dropdown-header prioritycol availablecolumns"><i className="fa fa-columns" aria-hidden="true"></i> &nbsp;{lang["storyboard.availcolums"]}</li>
                                        {(that.props.pos == "max") ? (
                                            that.props.columns.map((valueObject,index) => {
                                                if (valueObject.formula != undefined) {
                                                } else {
                                                    return (
                                                        <li key={index} className="boc-list-item drilldownitem" tite={valueObject.tablename} data-type={valueObject.tableDisplayType}>
                                                            <label><input type="checkbox" name="drilldown" className="drilldowncheckbox" data-type={valueObject.tableDisplayType} data-parent={valueObject.tableName} data-child={valueObject.columnName} data-name={valueObject.columnDisplayName} data-formula="" data-originalname={valueObject.orginalColumnName} data-columnname={valueObject.columnName}></input>
                                                                {(valueObject.tableDisplayType == 'date' || valueObject.tableDisplayType == 'datetime' || valueObject.tableDisplayType == 'month' || valueObject.tableDisplayType == 'day') ? (
                                                                    <><span className="itemtype"><i className="fa fa-calendar"></i></span>{valueObject.columnDisplayName}</>
                                                                ) : (valueObject.tableDisplayType == 'string') ? (
                                                                    <><span className="itemtype">{lang["storyboard.abc"]}</span>{valueObject.columnDisplayName}</>
                                                                ) : (valueObject.tableDisplayType == 'number') ? (
                                                                    <><span className="itemtype">123</span>{valueObject.columnDisplayName}</>
                                                                ) : ""}</label>
                                                        </li>
                                                    );
                                                }
                                            })
                                        ) : (
                                                that.props.columns.map((valueObject, index) => {
                                                    if (valueObject.formula != undefined) {
                                                    } else {
                                                        return (
                                                            <li key={index} className="boc-list-item drilldownitem" tite={valueObject.tablename} data-type={valueObject.tableDisplayType} >
                                                                <label><input type="checkbox" name="drilldown" className="drilldowncheckbox" id="drilldowncheckboxes1" data-type={valueObject.tableDisplayType} data-parent={valueObject.tableName} data-child={valueObject.columnName} data-name={valueObject.columnDisplayName} data-formula="" data-originalname={valueObject.orginalColumnName} data-columnname={valueObject.columnName}></input>
                                                                    {(valueObject.tableDisplayType == 'date' || valueObject.tableDisplayType == 'datetime' || valueObject.tableDisplayType == 'month' || valueObject.tableDisplayType == 'day') ? (
                                                                        <><span className="itemtype"><i className="fa fa-calendar"></i></span>{valueObject.columnDisplayName}</>
                                                                    ) : (valueObject.tableDisplayType == 'string') ? (
                                                                        <><span className="itemtype">{lang["storyboard.abc"]}</span>{valueObject.columnDisplayName}</>
                                                                    ) : (valueObject.tableDisplayType == 'number') ? (
                                                                        <><span className="itemtype">123</span>{valueObject.columnDisplayName}</>
                                                                    ) : ""}</label>
                                                            </li>
                                                        );
                                                    }
                                                })
                                            )}
                                        {/* TO Add calculatedfields in drillDown modal */}
                                        {that.props.calculatedFileds.map((valueObject, index) => {
                                            if (convertedTypes != null) {
                                                if (convertedTypes[valueObject.name] != undefined) {
                                                    return (
                                                        <li className="boc-list-item drilldownitem" tite={valueObject.name} data-type={convertedTypes[valueObject.name]}>
                                                            <label><input type="checkbox" name="drilldown" className="drilldowncheckbox" data-type={convertedTypes[valueObject.name]} data-parent={valueObject.name} data-child={valueObject.name} data-name={valueObject.name} data-formula="" data-originalname={valueObject.orginalColumnName} data-columnname={valueObject.columnName} ></input>
                                                                {(convertedTypes[valueObject.name] == 'date' || convertedTypes[valueObject.name] == 'datetime' || convertedTypes[valueObject.name] == 'month' || convertedTypes[valueObject.name] == 'day') ? (
                                                                    <><span className="itemtype"><i className="fa fa-calendar"></i></span>{valueObject.name}</>
                                                                ) : (convertedTypes[valueObject.name] == 'string') ? (
                                                                    <><span className="itemtype">{lang["storyboard.abc"]}</span>{valueObject.name}</>
                                                                ) : (convertedTypes[valueObject.name] == 'number') ? (
                                                                    <><span className="itemtype">123</span>{valueObject.name}</>
                                                                ) : ""}</label>
                                                        </li>
                                                    );
                                                } else if (valueObject.functiontype != "raw") {
                                                    return (
                                                        <li className="boc-list-item drilldownitem" tite={valueObject.name} data-type={valueObject.dataType}>
                                                            <label><input type="checkbox" name="drilldown" className="drilldowncheckbox" data-type={valueObject.dataType} data-parent={valueObject.name} data-child={valueObject.name} data-name={valueObject.name} data-formula="" ></input>
                                                                {(valueObject.dataType == 'date' || valueObject.dataType == 'datetime' || valueObject.dataType == 'month' || valueObject.dataType == 'day') ? (
                                                                    <><span className="itemtype"><i className="fa fa-calendar fx"></i></span>{valueObject.name}</>
                                                                ) : (valueObject.dataType == 'string') ? (
                                                                    <><span className="itemtype fx">{lang["storyboard.abc"]}</span>{valueObject.name}</>
                                                                ) : (valueObject.dataType == 'number') ? (
                                                                    <><span className="itemtype fx">123</span>{valueObject.name}</>
                                                                ) : ""}</label>
                                                        </li>
                                                    );
                                                } else {
                                                    return (
                                                        <li className="boc-list-item drilldownitem" tite={valueObject.name} data-type={valueObject.dataType}>
                                                            <label><input data="hello" type="checkbox" name="drilldown" className="drilldowncheckbox" data-type={valueObject.dataType} data-parent={valueObject.name} data-child={valueObject.name} data-name={valueObject.name} data-formula="" ></input>
                                                                {(valueObject.dataType == 'date' || valueObject.dataType == 'datetime' || valueObject.dataType == 'month' || valueObject.dataType == 'day') ? (
                                                                    <> <span className="itemtype"><i className="fa fa-calendar"></i></span>{valueObject.name}</>
                                                                ) : (valueObject.dataType == 'string') ? (
                                                                    <><span className="itemtype">{lang["storyboard.abc"]}</span>{valueObject.name}</>
                                                                ) : (valueObject.dataType == 'number') ? (
                                                                    <><span className="itemtype">123</span>{valueObject.name}</>
                                                                ) : ""}</label>
                                                        </li>
                                                    );
                                                }
                                            } else if (valueObject.functiontype != "raw") {
                                                return (
                                                    <li className="boc-list-item drilldownitem" tite={valueObject.name} data-type={valueObject.dataType}>
                                                        <label><input type="checkbox" name="drilldown" className="drilldowncheckbox" data-type={valueObject.dataType} data-parent={valueObject.name} data-child={valueObject.name} data-name={valueObject.name} data-formula="" data-originalname={valueObject.orginalColumnName} data-columnname={valueObject.columnName}></input>
                                                            {(valueObject.dataType == 'date' || valueObject.dataType == 'datetime' || valueObject.dataType == 'month' || valueObject.dataType == 'day') ? (
                                                                <><span className="itemtype"><i className="fa fa-calendar"></i></span>{valueObject.name}</>
                                                            ) : (valueObject.dataType == 'string') ? (
                                                                <><span className="itemtype">{lang["storyboard.abc"]}</span>{valueObject.name}</>
                                                            ) : (valueObject.dataType == 'number') ? (
                                                                <><span className="itemtype">123</span>{valueObject.name}</>
                                                            ) : ""}</label>
                                                    </li>
                                                );
                                            }
                                            else {
                                                return (
                                                    <li className="boc-list-item drilldownitem" tite={valueObject.name} data-type={valueObject.dataType}>
                                                        <label><input type="checkbox" name="drilldown" className="drilldowncheckbox" data-type={valueObject.dataType} data-parent={valueObject.name} data-child={valueObject.name} data-name={valueObject.name} data-formula="" data-originalname={valueObject.orginalColumnName} data-columnname={valueObject.columnName}></input>
                                                            {(valueObject.dataType == 'date' || valueObject.dataType == 'datetime' || valueObject.dataType == 'month' || valueObject.dataType == 'day') ? (
                                                                <><span className="itemtype"><i className="fa fa-calendar"></i></span>{valueObject.name}</>
                                                            ) : (valueObject.dataType == 'string') ? (
                                                                <><span className="itemtype">{lang["storyboard.abc"]}</span>{valueObject.name}</>
                                                            ) : (valueObject.dataType == 'number') ? (
                                                                <><span className="itemtype">123</span>{valueObject.name}</>
                                                            ) : ""}</label>
                                                    </li>
                                                );
                                            }
                                        })}
                                    </ul>

                                </div>
                            </div>
                        </div>
                        {(that.props.mapType != undefined && that.props.mapType) ? (
                            <div className="modal-footer boc-modal-hf-padding">
                                <button type="button" className="btn btn-bird" id="btn-bird" onClick={this.OnCancle}><i className="fa fa-times"></i> {lang["storyboard.cancelbtn"]}</button>
                                <button type="button" className="btn btn-bird applysmartinsightrunmap" id="applysmartinsightrun" onClick={that.props.isfromcomponent!="summary-table"?that.props.smartinsightsrun:that.props.summarysmartinsightrun} data-dismiss="modal"><i className="fa fa-check"></i> RUN</button>
                            </div>
                        ) : (
                                <div className="modal-footer boc-modal-hf-padding">
                                    <button type="button" className="btn btn-bird" id="btn-bird" onClick={this.OnCancle}><i className="fa fa-times"></i> {lang["storyboard.cancelbtn"]}</button>
                                    <button type="button" className="btn btn-bird applysmartinsightrun" id="applysmartinsightrun" onClick={that.props.isfromcomponent!="summary-table"?that.props.smartinsightsrun:that.props.summarysmartinsightrun} data-dismiss="modal"><i className="fa fa-check"></i> RUN</button>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        );
    }
}