import React from 'react'
import { lang } from "../js/helpers/utils/languages/language";
import { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
const $ = window.$;
const columns = [
  { dataField: 'DMTitle', text: 'Business Model', sort: true },
  { dataField: 'dataModelIndex', text: 'Data Index Name', sort: true },
  { dataField: 'DMCreatedOn', text: 'Created On', sort: true },
  { dataField: 'updatedBy', text: 'Created by', sort: true },
  { dataField: 'modelshare', text: 'Share Model', sort: false, headerAlign: 'center', align: 'center' },
];
const RemotePagination = ({ data, page, sizePerPage, onTableChange, totalSize }) => (
  <div className="notifications-table-container">
    <BootstrapTable
      remote
      keyField="DMTitle"
      data={data}
      columns={columns}
      pagination={paginationFactory({ page, sizePerPage, totalSize })}
      onTableChange={onTableChange}
    />
  </div>
);
export default class GovernanceViewTemplate extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    var that = this.props;
    return (<>
      {/* <!-- governanceview.js --> */}
      <section className="datamain-section-container">
        <div className="boc-home">
          <div className="datamain-fixed-head-container">
            <span className="subhead"><i className="fa fa-shield marg-righ-10"></i><span>{lang["models.goverance"]}</span></span>
          </div>
          <div className="datamain-fixed-content-container">
            <div className="col-xs-12">
              <div id="datagovernancemaincontainer">
                <h4 className="marg-top-20"><i className="fa fa-cube fa-solid marg-righ-10"></i> <span>{lang["models.business"]}</span></h4>
                <div className="pull-left col-sm-6 nopadding">
                                    <div id="custom-search-input col-md-4">
                                        <div className="input-group">
                                            <input type="text" placeholder={lang["models.searchmodels"]}
                                                className="form-control input-sm datamodelsearch" onKeyUp={this.props.searchKeyup}/>                                                 
                                        </div>
                                    </div>
                                </div>
                {/* <div className="notifications-table-container data-governance-table-container"> */}
                <GovernanceViewTemplateTable dataModelsList={that.dataModelsList} />
                {/* </div> */}
              </div>
              <div id="datamodelcustomfieldscontainer"></div>
              <div id="datamodelsharecontainer"></div>
            </div>
          </div>
        </div>
      </section>
      {(that.displayFooter != false) ? (
        <footer className="footer">
          <div className="container-fluid">
            {/* <!-- <p className="text-muted"><%=whitelableMap["footertext"]%></p> --> */}
          </div>
        </footer>
      ) : null}

    </>);
  }
}

class GovernanceViewTemplateTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      data: props.dataModelsList.slice(0, 10),
      sizePerPage: 10
    };
  }

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, data }) => {
    const currentIndex = (page - 1) * sizePerPage;
    {
      type == "sort" ?
        setTimeout(() => {
          let result;
          if (sortOrder === 'asc') {
            result = data.sort((a, b) => {
              if (a[sortField] > b[sortField]) {
                return 1;
              } else if (b[sortField] > a[sortField]) {
                return -1;
              }
              return 0;
            });
          } else {
            result = data.sort((a, b) => {
              if (a[sortField] > b[sortField]) {
                return -1;
              } else if (b[sortField] > a[sortField]) {
                return 1;
              }
              return 0;
            });
          }
          this.setState(() => ({
            data: result
          }));
        }, 10) :
        setTimeout(() => {
          this.setState(() => ({
            page,
            data: this.props.dataModelsList.slice(currentIndex, currentIndex + sizePerPage),
            sizePerPage
          }));

        }, 10);
    }
  }
  componentDidMount() {
    var that = this;
    // $('.data-modal-share').click(function (e) {
    //   that.props.datamodalshare(e);
    // })
  }
  render() {
    const { data, sizePerPage, page } = this.state;
    return (
      <div className="col-xs-12 marg-top-10 nopadding">
        <div className="notifications-table-container data-item">
          <RemotePagination
            data={data}
            page={page}
            sizePerPage={sizePerPage}
            totalSize={this.props.dataModelsList.length}
            onTableChange={this.handleTableChange}
          />
        </div>
      </div>
    );
  }
}