import React from 'react';
import _ from 'underscore';
import PubSub from "pubsub-js";
import ReactDOM from 'react-dom';
import SendRequest from '../../SendRequest';
import * as ModalProperties from "../../js/helpers/utils/modalproperties";
import { lang } from "../../js/helpers/utils/languages/language";
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import { getMessage } from '../../js/helpers/utils/BirdMessage';
import * as ChartUtils from "../../js/helpers/utils/chartutil";
import SummaryTableSettings from './summarytablesettings';
// import jQuery from 'jquery';
import * as viewcollection from "../../js/helpers/utils/SavedReportCollection";
const $ = require('jquery');
$.DataTable = require('datatables.net');

var getBreadCrumpListFromFilterArray;

export default class SummaryTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
        PubSub.unsubscribe("showSummaryModal", this.summaryModalShowEve.bind(this));
        $("#chartmetric" + this.props.chartNumber).remove();
        this.chartDetails = this.props.chartDetails;
        this.reportId = this.props.reportId;
        this.customFields = this.props.customFields
        this.entities = this.props.entities;
        this.indexName = this.props.indexname;
        this.chartnumber = this.props.chartNumber;
        this.drilldownlevel = this.props.drilldownlevel;
        this.drilldownName = this.props.drilldownName;
        this.drilldownDataType = this.props.drilldownDataType;
        this.drilldowndisplayName = this.props.drilldowndisplayName;
        this.features = this.props.features;
        this.groupdataType = {};
        getBreadCrumpListFromFilterArray = [];
        this.filterArray = this.props.filterArray;
        this.multifact = this.props.multifact;
        this.facttables=this.props.facttables;
        this.multifactentities = this.props.multifactentities;
        this.isDirect = ChartInfo.functions.getIsDirect();
        //this.response={};
        this.getdata();
        this.summarymodalshoweve = PubSub.subscribe("showSummaryModal", this.summaryModalShowEve.bind(this));
    }
    render1() {

        $("#spinnerchart" + this.props.chartNumber).show();
        $("#spinner" + this.props.chartNumber).show();
        var chartDetails_numformat = this.props.chartDetails;
        var dataSet = this.response['data'];
        var columns = this.response['columns'];
        var xAxisLength = this.props.chartDetails['summarytablecolumns'].length;
        var yAxisLength = this.props.chartDetails['summarytablevalues'].length;
        var legendLength = this.props.chartDetails['summarytablegroupbys'] == undefined ? 0 : 1;
        if (xAxisLength == 0) {
            $(".singlereport_summarymodal").click();
            $(".addpivotrows-error help-block marg-left-10").removeClass("hide");
            $("#spinnerchart" + this.props.chartNumber).hide();
            $("#spinner" + this.props.chartNumber).hide();
            return;
        }
        var totalStart = xAxisLength + legendLength + 1;
        var totalEnd = xAxisLength + yAxisLength + 1;

        var chartNumber = this.chartnumber;
        $('.summartable' + this.chartnumber + '').remove();
        // ChartUtils.unbindEvents(this.chartnumber);
        $("#chart" + this.chartnumber).empty();
        $("#chart" + this.chartnumber).css({ "overflow": '' });
        $("#chart" + this.chartnumber).append('<div class="summartable' + this.chartnumber + ' summartytablecontainer"></div>');
        $(".summartable" + this.chartnumber).append('<table id="summartable' + this.chartnumber + '" data-num=' + this.chartnumber + ' class="table table-striped table-bordered summarytablestyling" cellspacing="0" width="100%">');
        var groupCol = 1;

        var $tableEle = $('#summartable' + this.chartnumber + '');
        var rows_rightalighn = [];
        var rows_rightalighn_legend = [];
        var length = yAxisLength + xAxisLength;
        var lengthwithlegend = yAxisLength + xAxisLength + legendLength;
        var summarytableViewdatapopup = "summarytable-viewdatapopup";
        var summarytableSmartInsightMore = "summarytable-smartinsights-viewdata-more";
        this.isSharedReport = false;
        this.isCustomstoryboard = false;
        if(this.props.multifact=="true"){
            summarytableSmartInsightMore = "summarytable-viewdatapopup";
        }
        if (this.href.toLowerCase().indexOf("viewcustomstoryboard") >= 0) {
            this.isCustomstoryboard = true;
        }
        
        if (this.href.toLowerCase().indexOf("sharereport") >= 0) {
            this.isSharedReport = true;
        }
        ChartUtils.checkDrillDownEnable(this.features, sessionStorage.pos, this.isSharedReport, this.isCustomstoryboard);
        var isSmartInsightEnable = ChartUtils.checkSmartInsightsEnable(this.features,sessionStorage.pos,this.isSharedReport,this.isCustomstoryboard);
        if(!isSmartInsightEnable){
            summarytableSmartInsightMore = "summarytable-viewdatapopup";
        }else if(isSmartInsightEnable && this.props.multifact!="true"){
            summarytableViewdatapopup = "";
        }
        if (this.isDirect == 1 && ChartInfo.functions.getIsStoredProcedure() == 1) {
            summarytableViewdatapopup = "";
            summarytableSmartInsightMore = "";
        }
        if (this.isSharedReport == true && ChartInfo.functions.getisViewOnlyFlag() == true) {
            summarytableViewdatapopup = "notpermitted";
            summarytableSmartInsightMore = "notpermitted";
        }
        for (var i = 1; i <= yAxisLength; i++) {
            rows_rightalighn.push(length--);
            rows_rightalighn_legend.push(lengthwithlegend--);
        }
        var columnTypemap={}
        _.each(columns,function(val,index){
            if(val!=null && val["columnTypeName"]==='number'){
            if(rows_rightalighn_legend.indexOf(index)!=-1) {   
                 val["className"]="align-right " +  summarytableSmartInsightMore+ " is-numericcolumn"+index;
            }
            else{
                val["className"]="align-right";
            }
                columnTypemap[val["title"]]=val["columnTypeName"];
            }else if(val!=null){
                if(rows_rightalighn_legend.indexOf(index)!=-1) { 
                    val["className"] = "align-right " + summarytableViewdatapopup + "";
                }
                else{
                    val["className"]="dt-body-left";
                }
                    columnTypemap[val["title"]]=val["columnTypeName"]
            }
            if(val!=null && legendLength>0){
                val["orderable"] = false
            }
        })
        //Initiate DataTable
        if (legendLength != 0) {
            $("#chart" + chartNumber + " .summartytablecontainer").addClass("summaryhasgroupby");
            var $tableList = $tableEle.DataTable({
                data: dataSet,
                columns: columns,
                "lengthMenu": [[10, 25, 50, 100], [10, 25, 50, 100]],
                "autoWidth": true,
                "retrieve": true,
                "paging": true,
                "scrollY": "100%",
                "scrollX": true,
                "dom": 'lBftrip',
                "buttons": [{ extend: 'csv', text: '<i class="fa fa-download"></i>', className: 'btn btn-bird btn-sm summarytableexport', titleAttr: lang["storyboard.downloadcsv"], title:lang["storyboard.summarytable"], exportOptions: { columns: ':visible' } }],
                "deferRender": true,
                "scroller": true,
                //"orderFixed": [[groupCol, 'asc']],
                "columnDefs": [{
                    "orderable": false,
                    "targets": [0]
                }, {
                    "visible": false,
                    "targets": [0, 1]
                }, {
                    "className": "dt-body-left " + summarytableViewdatapopup + "", "targets": rows_rightalighn_legend
                }],
                "drawCallback": function (settings) {
                    var that = this;
                    if (settings.bSorted || settings.bFiltered) {
                        this.$('td:first-child', {
                            "filter": "applied"
                        }).each(function (i) {
                            that.fnUpdate(i + 1, this.parentNode, 0, false, false);
                        });
                    }

                    var api = this.api();
                    var rows = api.rows({
                        page: 'current'
                    }).nodes();
                    var rowsData = api.rows({
                        page: 'current'
                    }).data();

                    var last = null;
                    var subTotal = new Array();
                    var grandTotal = new Array();
                    var groupID = -1;
                   _.each(columns,function(val,index){
                       if(val!=null&&val["columnTypeName"]=="number"){
                        api.column(index, {
                            page: 'current'
                        }).data().each(function (group, i) {
                            $(rows).find('td.is-numericcolumn'+index).attr('data-columnname', val.title)
                        });
                       }
                   })

                    api.column(groupCol, {
                        page: 'current'
                    }).data().each(function (group, i) {
                        if (last !== group) {
                            groupID++;
                            var alignRight = "";
                            var groupTitleval = group.split(":")[0];
                            if(groupTitleval!=undefined && columnTypemap[groupTitleval]==="number"){
                                alignRight = "align-right" 
                            }
                            $(rows).eq(i).before("<tr class='groupTR' group-no='" + groupID + "' group-name='" + group + "' data-num=" + chartNumber + "><td colspan='" + xAxisLength + "' class='groupTitle "+alignRight+"'>" + group + "</td></tr>");
                            last = group;
                        }

                        //Sub-total of each column within the same grouping
                        var val = api.row(api.row($(rows).eq(i)).index()).data(); //Current order index
                        $.each(val, function (colIndex, colValue) {
                            if (typeof subTotal[groupID] == 'undefined') {
                                subTotal[groupID] = new Array();
                            }
                            if (typeof subTotal[groupID][colIndex] == 'undefined') {
                                subTotal[groupID][colIndex] = 0;
                            }
                            if (typeof grandTotal[colIndex] == 'undefined') {
                                grandTotal[colIndex] = 0;
                            } if (colValue != null) {
                                colValue = (typeof colValue == "number") ? colValue : colValue.replace(/,/g, '');
                            }
                            var value = colValue ? parseFloat(colValue) : 0;
                            subTotal[groupID][colIndex] += parseFloat(value);
                            grandTotal[colIndex] += parseFloat(value);
                        });
                    });
                    var sumArray = [];
                    for (var a1 = totalStart; a1 <= totalEnd; a1++) {
                        var sum = 0;
                        api.data().each(function (val) {
                            sum = parseFloat(sum) + parseFloat(val[a1] + "".replace(/,/g, ''))
                        });
                        sumArray[a1] = sum;
                    }
                    $('tbody').find('.groupTR[data-num=' + chartNumber + ']').each(function (i, v) {
                        var rowCount = $(this).nextUntil('.groupTR').length;
                        $(this).nextUntil('.groupTR').attr("group-no", i);//add group number to each row
                        $(this).nextUntil('.groupTR').attr("data-num", chartNumber);//add chartno to each row
                        var subTotalInfo = "";
                        for (var a = totalStart; a <= totalEnd; a++) {
                            if (subTotal[i][a] != undefined)
                                if (chartDetails_numformat.chartSettings != undefined) {
                                    if (chartDetails_numformat.chartSettings.numberformat != undefined) {
                                        var subtotal_num = ChartInfo.functions.addFormatting(subTotal[i][a], chartDetails_numformat.chartSettings.numberformat.abbrevation, chartDetails_numformat.chartSettings.numberformat.decimals, chartDetails_numformat.chartSettings.numberformat.seperator);
                                        var subArray_num = ChartInfo.functions.addFormatting(sumArray[a], chartDetails_numformat.chartSettings.numberformat.abbrevation, chartDetails_numformat.chartSettings.numberformat.decimals, chartDetails_numformat.chartSettings.numberformat.seperator);
                                        subTotalInfo += "<td class='groupTD align-right'>" + subtotal_num.toLocaleString() + "/" + subArray_num.toLocaleString() + "</td>";
                                    } else {
                                        subTotalInfo += "<td class='groupTD align-right'>" + subTotal[i][a].toLocaleString() + "/" + sumArray[a].toLocaleString() + "</td>";
                                    }
                                } else {
                                    subTotalInfo += "<td class='groupTD align-right'>" + subTotal[i][a].toLocaleString() + "/" + sumArray[a].toLocaleString() + "</td>";
                                }
                        }
                        $(this).append(subTotalInfo);
                    });
                    $('.summartytablecontainer td').unbind().click((e) => { PubSub.publish("viewsummarytabledrilldown", e) });
                }
            });
            $("#spinnerchart" + this.chartnumber).hide();
            $("#spinner" + this.props.chartNumber).hide();
        } else {
            $("#chart" + chartNumber + " .summartytablecontainer").removeClass("summaryhasgroupby");
            var appendTH = "";
            for (var i = 0; i < columns.length - 2; i++) {
                appendTH += "<th class=text-right></th>"
            }
            var summarytableViewdatapopup = "summarytable-viewdatapopup";
            var summarytableSmartInsightMore = "summarytable-smartinsights-viewdata-more";
            if (this.isDirect == 1 && ChartInfo.functions.getIsStoredProcedure() == 1) {
                summarytableViewdatapopup = "";
                summarytableSmartInsightMore= "";
            }
            if (this.isSharedReport == true && ChartInfo.functions.getisViewOnlyFlag() == true) {
                summarytableViewdatapopup = "notpermitted";
                summarytableSmartInsightMore = "notpermitted";
            }
            if(!isSmartInsightEnable){
                summarytableSmartInsightMore = "summarytable-viewdatapopup";
            }
            $('#summartable' + this.chartnumber + '').append('<tfoot class="tFooterTotal"><tr>'
                + '<th></th><th>Grand Total</th>' + appendTH + '</tr></tfoot>');
            $tableEle.DataTable({
                data: dataSet,
                columns: columns,
                "paging": true,
                "lengthMenu": [[10, 25, 50, 100], [10, 25, 50, 100]],
                "scrollY": "100%",
                "scrollX": true,
                "dom": 'lBftrip',
                "buttons": [{ extend: 'csv', text: '<i class="fa fa-download"></i>', className: 'btn btn-bird btn-sm summarytableexport', titleAttr: 'Download CSV', title: 'SummaryTable', exportOptions: { columns: ':visible' } }],
                "initComplete": function (settings, json) {
                    for (var i = 1; i <= yAxisLength; i++) {
                        $('.summarytablestyling thead tr th:nth-last-child(' + i + ')').addClass('sum');
                    }
                    this.api().columns('.sum').every(function () {
                        var column = this;
                        if (column.data().length > 0) {
                            var sum = column
                                .data()
                                .reduce(function (a, b) {
                                    a = (a == null) ? 0 : (typeof a == "number") ? a : a.replace(/,/g, '');
                                    b = (b == null) ? 0 : (typeof b == "number") ? b : b.replace(/,/g, '');
                                    return parseFloat(a, 10) + parseFloat(b, 10);
                                });
                            if (sum != null && sum != undefined) {
                                $(column.footer()).html(sum.toLocaleString());
                            }
                        }
                    });
                },
                "columnDefs": [{
                    "targets": [0],
                    "visible": false,
                    "searchable": false,
                    "paging": false,
                }, {
                    "className": "dt-body-left " + summarytableViewdatapopup + "", "targets": rows_rightalighn
                }],
                "drawCallback": function (settings) {
                    $('.summartytablecontainer td').unbind().click((e) => { PubSub.publish("viewsummarytabledrilldown", e) });
                    var api = this.api();
                    var rows = api.rows({
                        page: 'current'
                    }).nodes();
                     _.each(columns,function(val,index){
                            if(val!=null&&val["columnTypeName"]=="number"){
                                api.column(index, {
                                 page: 'current'
                                 }).data().each(function (group, i) {
                             $(rows).find('td.is-numericcolumn'+index).attr('data-columnname', val.title)
                             });
                            }
                         })
                }
            })
            //$("#spinnerchart"+this.options.chartNumber).hide();
        }
        var isSummaryTableExportEnable = false;
        var href = window.location.pathname;//Backbone.history.getFragment();
        var isSharedReport = href.split("/");
        _.each(this.features, function (index, featuresModule) {
            if (featuresModule.moduleName == 'my_report') {
                _.each(featuresModule.features, function (inddex2, feature) {
                    if ((feature.featureName == 'viewreport_maximize_summary_table_export' && feature.allow == true)) {
                        if (isSharedReport[0] === 'sharereport') {
                            isSharedReport = true;
                        }
                        if (ChartInfo.functions.getisViewOnlyFlag() == true) {
                            isSummaryTableExportEnable = false;
                            //isDrillDownEnable=false;
                        } else {
                            isSummaryTableExportEnable = true;
                        }
                    }
                });
            }
        });
        if (isSummaryTableExportEnable == false) {
            $(".summarytableexport").hide();
        }


        //To initiate summary table re-size on load
        setTimeout(function () {
            if ($(".tileview[data-num=" + chartNumber + "]").length != 0) {
                var parentHeight = $(".tileview[data-num=" + chartNumber + "]").height() - 42;
                $("#chart" + chartNumber + " .summartytablecontainer").css("height", parentHeight);
                $("#chart" + chartNumber + " .summartytablecontainer").parent().css("height", "");
                var tfootTotal = $("#chart" + chartNumber + " .summartytablecontainer .dataTables_scrollFoot").height();
                if(tfootTotal!=undefined){
                    tfootTotal = tfootTotal + 100
                }else{
                    tfootTotal = 100
                }
                var breadcrumbHeight = $("ul.breadcrumb[data-num="+chartNumber+"]").outerHeight();
                if(breadcrumbHeight==undefined){breadcrumbHeight = 0}
                parentHeight = parentHeight - (tfootTotal + breadcrumbHeight);
                $("#chart" + chartNumber + " .dataTables_scrollBody").css("height", parentHeight);
                if ($("#chart" + chartNumber + " .dataTables_scrollBody table").height() < $("#chart" + chartNumber + " .dataTables_scrollBody").height()) {
                    $("#chart" + chartNumber + " .dataTables_scrollBody").css("height", $("#chart" + chartNumber + " .dataTables_scrollBody table").height() + 1);
                }
            }
            if ($(".singlereportview[data-num=" + chartNumber + "]").length == 1 || $(".tileview[data-num=" + chartNumber + "]").length == 0) {
                var navh = $("nav.navbar.navbar-bird.navbar-fixed-top").height();
                var controlh = $(".reporttoolbar[data-num=" + chartNumber + "] .controlbar-main").height();
                var charcontrolh = $(".maxanim9[data-num=" + chartNumber + "]").height();
                var quickfilterh = $(".quickfiltercontainer[data-num=" + chartNumber + "]").height();
                var breadcrumbh = $(".breadcrumb[data-num=" + chartNumber + "]").outerHeight() + 3;
                var footerh = $("footer.footer").height();
                var newFilterh = $('.tab-filters-container').outerHeight();

                var summarcontrolh = $(".summartable" + chartNumber + ".summartytablecontainer .dataTables_length").height();
                var summarheaderh = $(".summartable" + chartNumber + ".summartytablecontainer .dataTables_scrollHeadInner").height();
                var summarfooterh = $(".summartable" + chartNumber + ".summartytablecontainer .dataTables_scrollFoot").height();
                var summarpagingh = $("#summartable" + chartNumber + "_paginate").innerHeight();

                var finalh = navh + controlh + charcontrolh + newFilterh + quickfilterh + breadcrumbh + footerh + summarcontrolh + summarheaderh + summarfooterh + summarpagingh + 15;
                $("#chart" + chartNumber + " .dataTables_scrollBody").css("max-height", "calc(100vh - " + finalh + "px)");
            }
            // (function ($) {
            //     $.fn.hasScrollBar = function () {
            //         return this.get(0) ? this.get(0).scrollHeight > this.innerHeight() : false;
            //     }
            // })(jQuery);

            // if ($("#chart" + chartNumber + " .dataTables_scrollBody").hasScrollBar()) {
            //     $("#chart" + chartNumber + " .summartytablecontainer").addClass("summaryhasscroll");
            // } else {
            //     $("#chart" + chartNumber + " .summartytablecontainer").removeClass("summaryhasscroll");
            // }
        }, 1000);

        // function hidespinner() {
        //    var isSummaryDone = $(".summartable" + chartNumber + ".summartytablecontainer .dataTables_scrollHead").length;

        //     if (isSummaryDone > 0) {
        //         setTimeout(function () {
        //             $("#spinnerchart" + chartNumber).hide();
        //         }, 500);
        //     } else {
        //         hidespinner();
        //     }
        // }
        // hidespinner();

        // $(document).on("click", ".paginate_button", function (e) {
        //     var $this = e.currentTarget;
        //     var NchartNumber = $($this).attr("aria-controls").replace("summartable", "");

        //     if ($("#chart" + NchartNumber + " .dataTables_scrollBody").hasScrollBar()) {
        //         $("#chart" + NchartNumber + " .summartytablecontainer").addClass("summaryhasscroll");
        //     } else {
        //         $("#chart" + NchartNumber + " .summartytablecontainer").removeClass("summaryhasscroll");
        //     }

        // });

        $(document).on("click", ".summarytableexport", function (e) {
            e.preventDefault();
            e.stopPropagation();
            var ReportId = e.currentTarget.baseURI.split("/").pop();
            var msg = "Summary Table Exported to CSV";
            if (ReportId != undefined) {
                  ChartUtils.auditMessage(ReportId, msg);
            }

        });
       // $('.summartytablecontainer td').click((e) => { PubSub.publish("viewsummarytabledrilldown", e) })

        /*Date Histogram UI for Summary Table*/
        var dateHistogramAdded = false;
        if (this.props.chartDetails["summarytablecolumns"] != undefined) {
            var that = this;
            _.each(this.props.chartDetails["summarytablecolumns"], function (e) {
                if (e.dataType == "date" && that.isDirect == 0) {
                    if (isSharedReport == true && ChartInfo.functions.getisViewOnlyFlag() == true) {

                    } else if (ChartInfo.functions.getIsDirect() == 0) { 
                        // $("#summartable"+chartNumber+"_length")
                        // $("#summartable"+chartNumber+"_wrapper").append('<div class="dt-buttons"></div>');
                        $(".summartable" + chartNumber + " .dt-buttons").append('' +
                            '<div class="btn-group bgcommon">' +
                            '<button type="button" class="btn btn-bird-group btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fa fa-calendar"></i>' + e.displayName + '</button>' +
                            '<button type="button" class="btn btn-bird-group btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" title='+lang["storyboard.more"]+'><span class="caret"></span><span class="sr-only">'+lang["storyboard.toggle"]+'</span></button>' +
                            '<ul class="dropdown-menu">' +
                            '<li><a href="javascript:void(0)" class="boc-maxscreen-left-item drawsummarytabledatehistogram" column-name="' + e.columnName + '" column-display-name="' + e.displayName + '" column-type="' + e.dataType + '" data-interval="y" data-num="' + chartNumber + '">'+lang["storyboard.years"]+'</a></li>' +
                            '<li><a href="javascript:void(0)" class="boc-maxscreen-left-item drawsummarytabledatehistogram" column-name="' + e.columnName + '" column-display-name="' + e.displayName + '" column-type="' + e.dataType + '" data-interval="q" data-num="' + chartNumber + '">'+lang["storyboard.quarters"]+'</a></li>' +
                            '<li><a href="javascript:void(0)" class="boc-maxscreen-left-item drawsummarytabledatehistogram" column-name="' + e.columnName + '" column-display-name="' + e.displayName + '" column-type="' + e.dataType + '"  data-interval="M" data-num="' + chartNumber + '">'+lang["storyboard.months"]+'</a></li>' +
                            '<li><a href="javascript:void(0)" class="boc-maxscreen-left-item drawsummarytabledatehistogram" column-name="' + e.columnName + '" column-display-name="' + e.displayName + '" column-type="' + e.dataType + '" data-interval="w" data-num="' + chartNumber + '">'+lang["storyboard.weeks"]+'</a></li>' +
                            '<li><a href="javascript:void(0)" class="boc-maxscreen-left-item drawsummarytabledatehistogram" column-name="' + e.columnName + '" column-display-name="' + e.displayName + '" column-type="' + e.dataType + '" data-interval="d" data-num="' + chartNumber + '">'+lang["storyboard.day's"]+'</a></li>' +
                            '<li><a href="javascript:void(0)" class="boc-maxscreen-left-item drawsummarytabledatehistogram" column-name="' + e.columnName + '" column-display-name="' + e.displayName + '" column-type="' + e.dataType + '" data-interval="h" data-num="' + chartNumber + '">'+lang["storyboard.hours"]+'</a></li>' +
                            '<li><a href="javascript:void(0)" class="boc-maxscreen-left-item drawsummarytabledatehistogram" column-name="' + e.columnName + '" column-display-name="' + e.displayName + '" column-type="' + e.dataType + '" data-interval="m" data-num="' + chartNumber + '">'+lang["storyboard.minutes"]+'</a></li>' +
                            '<li><a href="javascript:void(0)" class="boc-maxscreen-left-item drawsummarytabledatehistogram" column-name="' + e.columnName + '" column-display-name="' + e.displayName + '" column-type="' + e.dataType + '" data-interval="s" data-num="' + chartNumber + '">'+lang["storyboard.seconds"]+'</a></li>' +
                            '<li><a href="javascript:void(0)" class="boc-maxscreen-left-item drawsummarytabledatehistogram" column-name="' + e.columnName + '" column-display-name="' + e.displayName + '" column-type="' + e.dataType + '" data-interval="default" data-num="' + chartNumber + '">'+lang["storyboard.default"]+'</a></li>' +
                            '</ul>' +
                            '</div>');
                        $('.drawsummarytabledatehistogram').click((e) => { PubSub.publish("drawsummarytabledatehistogram", e) })
                        if (that.props.dateHistomgramColName != undefined) {
                            $('#chart' + chartNumber).find('.drawsummarytabledatehistogram[column-name="' + that.props.dateHistomgramColName["dateHistogramColName"] + '"][data-interval="' + that.props.dateHistomgramColName["histogramInterval"] + '"]').addClass('showactive');
                        }
                    }
                    dateHistogramAdded = true;
                }
            });
        }
        if (this.props.chartDetails["summarytablegroupbys"] != undefined) {
            var groupbyData = [];
            var that = this;
            groupbyData.push(this.props.chartDetails["summarytablegroupbys"]);
            _.each(groupbyData, function (e) {
                if (e.dataType == "date" && that.isDirect == 0) {
                    if (isSharedReport == true && ChartInfo.functions.getisViewOnlyFlag() == true) {

                    } else if (ChartInfo.functions.getIsDirect() == 0) {
                        $(".summartable" + chartNumber + " .dt-buttons").append('' +
                            '<div class="btn-group bgcommon">' +
                            '<button type="button" class="btn btn-bird-group btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fa fa-calendar"></i>' + e.displayName + '</button>' +
                            '<button type="button" class="btn btn-bird-group btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" title='+lang["storyboard.more"]+'><span class="caret"></span><span class="sr-only">'+lang["storyboard.toggle"]+'</span></button>' +
                            '<ul class="dropdown-menu">' +
                            '<li><a href="javascript:void(0)" class="boc-maxscreen-left-item drawsummarytabledatehistogram" column-name="' + e.columnName + '" column-display-name="' + e.displayName + '" column-type="' + e.dataType + '" data-interval="y" data-num="' + chartNumber + '">'+lang["storyboard.years"]+'</a></li>' +
                            '<li><a href="javascript:void(0)" class="boc-maxscreen-left-item drawsummarytabledatehistogram" column-name="' + e.columnName + '" column-display-name="' + e.displayName + '" column-type="' + e.dataType + '" data-interval="q" data-num="' + chartNumber + '">'+lang["storyboard.quarters"]+'</a></li>' +
                            '<li><a href="javascript:void(0)" class="boc-maxscreen-left-item drawsummarytabledatehistogram" column-name="' + e.columnName + '" column-display-name="' + e.displayName + '" column-type="' + e.dataType + '"  data-interval="M" data-num="' + chartNumber + '">'+lang["storyboard.months"]+'</a></li>' +
                            '<li><a href="javascript:void(0)" class="boc-maxscreen-left-item drawsummarytabledatehistogram" column-name="' + e.columnName + '" column-display-name="' + e.displayName + '" column-type="' + e.dataType + '" data-interval="w" data-num="' + chartNumber + '">'+lang["storyboard.weeks"]+'</a></li>' +
                            '<li><a href="javascript:void(0)" class="boc-maxscreen-left-item drawsummarytabledatehistogram" column-name="' + e.columnName + '" column-display-name="' + e.displayName + '" column-type="' + e.dataType + '" data-interval="d" data-num="' + chartNumber + '">'+lang["storyboard.day's"]+'</a></li>' +
                            '<li><a href="javascript:void(0)" class="boc-maxscreen-left-item drawsummarytabledatehistogram" column-name="' + e.columnName + '" column-display-name="' + e.displayName + '" column-type="' + e.dataType + '" data-interval="h" data-num="' + chartNumber + '">'+lang["storyboard.hours"]+'</a></li>' +
                            '<li><a href="javascript:void(0)" class="boc-maxscreen-left-item drawsummarytabledatehistogram" column-name="' + e.columnName + '" column-display-name="' + e.displayName + '" column-type="' + e.dataType + '" data-interval="m" data-num="' + chartNumber + '">'+lang["storyboard.minutes"]+'</a></li>' +
                            '<li><a href="javascript:void(0)" class="boc-maxscreen-left-item drawsummarytabledatehistogram" column-name="' + e.columnName + '" column-display-name="' + e.displayName + '" column-type="' + e.dataType + '" data-interval="s" data-num="' + chartNumber + '">'+lang["storyboard.seconds"]+'</a></li>' +
                            '<li><a href="javascript:void(0)" class="boc-maxscreen-left-item drawsummarytabledatehistogram" column-name="' + e.columnName + '" column-display-name="' + e.displayName + '" column-type="' + e.dataType + '" data-interval="default" data-num="' + chartNumber + '">'+lang["storyboard.default"]+'</a></li>' +
                            '</ul>' +
                            '</div>');
                        $('.drawsummarytabledatehistogram').click((e) => { PubSub.publish("drawsummarytabledatehistogram", e) })
                        if (that.props.dateHistomgramColName != undefined) {
                            $('#chart' + chartNumber).find('.drawsummarytabledatehistogram[column-name="' + that.props.dateHistomgramColName["dateHistogramColName"] + '"][data-interval="' + that.props.dateHistomgramColName["histogramInterval"] + '"]').addClass('showactive');
                        }
                    }
                    dateHistogramAdded = true;
                }
            })
        }
        if (!dateHistogramAdded) {
            ChartInfo.functions.setSummaryTableDateHistogramStatus(chartNumber, false);
        }
       // $('.summartytablecontainer td').click((e) => { PubSub.publish("viewsummarytabledrilldown", e) })
    }
    getdata() {
        var that = this;
        var groupByArray = [];
        var groupByDisplayName = [];
        var isGroupsContainCustomHirarchy = true;
        var isPublished='false';
		if(that.props!=undefined){
			isPublished=that.props.isPublished;
		}
        this.isImportedReport = false;
        if (sessionStorage.getItem("isImportedReport") || window.location.pathname.indexOf("importreport") >= 0) {
            this.isImportedReport = true;
        }
        this.href = "";// Backbone.history.getFragment();
        this.isPurgedReport = false;
        if (this.href.toLowerCase().indexOf("viewpurgereport") >= 0) {
            this.isPurgedReport = true;
        }
        var levelmapping;
        if (sessionStorage.getItem('customhierarchy' + this.chartnumber) != undefined && sessionStorage.getItem('customhierarchy' + this.chartnumber) != null
            && sessionStorage.getItem('customhierarchy' + this.chartnumber) != "undefined") {
            levelmapping = JSON.parse(sessionStorage.getItem('customhierarchy' + this.chartnumber));
        }
        var levelMappingObj = that.props.levelMapping;
        if (levelmapping != null && levelmapping != undefined && levelmapping.length > 0) {
            levelMappingObj = JSON.parse(JSON.stringify(levelmapping[levelmapping.length - 1][0]));
        }
        var groupbyData = [];
        if (this.props.chartDetails["summarytablegroupbys"] != undefined) {
            var legendColumnDisplayName = this.props.chartDetails["summarytablegroupbys"].displayName;
            var legendColumnName = this.props.chartDetails["summarytablegroupbys"].columnName;
            if (ChartInfo.functions.getSummarytableAxisFieldsArray(this.chartnumber) != undefined && ChartInfo.functions.getSummarytableAxisFieldsArray(this.chartnumber).length > 0 && isGroupsContainCustomHirarchy) {
                isGroupsContainCustomHirarchy = false;
                var summarytabledetails = ChartInfo.functions.getSummarytableAxisFieldsArray(this.chartnumber);
                var details = summarytabledetails[summarytabledetails.length - 1];
                var temp = $.grep(ChartInfo.functions.getcustomhierarchy(), function (e) { return e.name == details["group"].columnName });
                if(that.props.isCustomstoryboard && that.props.isCustomstoryboard !=undefined){
                    temp = ChartInfo.functions.getCustomHierarchyReportId(that.props.chartDetails.reportid);
                }
                if(ChartInfo.functions.getCustomHierarchyReportId(that.props.chartDetails.reportid)!=undefined){
                    temp = ChartInfo.functions.getCustomHierarchyReportId(that.props.chartDetails.reportid);
                }
                var dataTypes = {};
                if (temp != undefined && temp.length > 0) {
                    var columnName = temp[0].hierarchelist[levelMappingObj[legendColumnName]].columnName
                    var columnDislayName = temp[0].hierarchelist[levelMappingObj[legendColumnName]].columnDisplayName;
                    var columnDataType = temp[0].hierarchelist[levelMappingObj[legendColumnName]].dataType;
                    groupByArray.push(columnName);
                    groupByDisplayName.push(columnDislayName);
                    groupbyData.push(temp[0].hierarchelist[levelMappingObj[legendColumnName]]);
                    if (that.drilldownDataType != undefined) {
                        dataTypes["columnName"] = columnName;
                        dataTypes["dataType"] = columnDataType;
                        that.groupdataType[that.drilldownName] = dataTypes;
                    }
                    else {
                        dataTypes["columnName"] = this.props.chartDetails["summarytablegroupbys"].columnName;
                        dataTypes["dataType"] = this.props.chartDetails["summarytablegroupbys"].dataType;
                        that.groupdataType[legendColumnName] = dataTypes;
                    }
                } else {
                    groupByArray.push(details["group"].columnName);
                    groupByDisplayName.push(details["group"].displayName);
                    var dataTypes = {};
                    dataTypes["columnName"] = details["group"].columnName;
                    dataTypes["dataType"] = details["group"].dataType;
                    that.groupdataType[details["group"].columnName] = dataTypes;
                    groupbyData.push(details["group"]);
                }
            } else if (isGroupsContainCustomHirarchy) {
                groupByArray.push(legendColumnName);
                groupByDisplayName.push(this.props.chartDetails["summarytablegroupbys"].displayName);
                var dataTypes = {};
                dataTypes["columnName"] = this.props.chartDetails["summarytablegroupbys"].columnName;
                dataTypes["dataType"] = this.props.chartDetails["summarytablegroupbys"].dataType;
                that.groupdataType[legendColumnName] = dataTypes;
                groupbyData.push(this.props.chartDetails["summarytablegroupbys"]);
            }
        }
        _.each(this.props.chartDetails["summarytablecolumns"], function (xAxisObject, i) {
            var bool = true;
            if (ChartInfo.functions.getSummarytableAxisFieldsArray(that.chartnumber) != undefined && ChartInfo.functions.getSummarytableAxisFieldsArray(that.chartnumber).length > 0 && bool) {
                bool = false;
                var summarytabledetails = ChartInfo.functions.getSummarytableAxisFieldsArray(that.chartnumber);
                var details = summarytabledetails[summarytabledetails.length - 1];
                var temp = $.grep(ChartInfo.functions.getcustomhierarchy(), function (e) { return e.name == details["textcolumns"][i].columnName });
                if(that.props.isCustomstoryboard && that.props.isCustomstoryboard !=undefined){
                    temp = ChartInfo.functions.getCustomHierarchyReportId(that.props.chartDetails.reportid);
                }
                if(ChartInfo.functions.getCustomHierarchyReportId(that.props.chartDetails.reportid)!=undefined){
                    temp = ChartInfo.functions.getCustomHierarchyReportId(that.props.chartDetails.reportid);
                }
                if (temp != undefined && temp.length > 0) {
                    bool = false;
                    if (temp[0].hierarchelist[levelMappingObj[xAxisObject.columnName]] != undefined) {
                        var columnName = temp[0].hierarchelist[levelMappingObj[xAxisObject.columnName]].columnName;
                        var displayName = temp[0].hierarchelist[levelMappingObj[xAxisObject.columnName]].columnDisplayName;
                        var columnDataType = temp[0].hierarchelist[levelMappingObj[xAxisObject.columnName]].dataType;
                        groupByArray.push(columnName);
                        groupByDisplayName.push(displayName);
                        var dataTypes = {};
                        if (that.drilldownDataType != undefined) {
                            dataTypes["columnName"] = columnName;
                            dataTypes["dataType"] = columnDataType;
                            that.groupdataType[that.drilldownName] = dataTypes;
                        }
                    }
                } else {
                    groupByArray.push(details["textcolumns"][i].columnName);
                    groupByDisplayName.push(details["textcolumns"][i].displayName);
                    var dataTypes = {};
                    dataTypes["columnName"] = details["textcolumns"][i].columnName;
                    dataTypes["dataType"] = details["textcolumns"][i].dataType;
                    that.groupdataType[details["textcolumns"][i].columnName] = dataTypes;
                }
            } else if (bool) {
                groupByArray.push(xAxisObject.columnName);
                groupByDisplayName.push(xAxisObject.displayName);
                var dataTypes = {};
                dataTypes["columnName"] = xAxisObject.columnName;
                dataTypes["dataType"] = xAxisObject.dataType;
                that.groupdataType[xAxisObject.columnName] = dataTypes;
            }
        });
        if (this.props.filterArray != undefined) {
            getBreadCrumpListFromFilterArray = $.grep(JSON.parse(this.props.filterArray), function (e) { return e.breadcrump == true });
        }
        $('.breadcrumb[data-num=' + that.chartnumber + ']').remove();
        $("#chart" + that.chartnumber).before('<ul class="breadcrumb summary" data-num=' + that.chartnumber + ' style="display:block;"></ul>');
        _.each(getBreadCrumpListFromFilterArray, (value, index) => {
            if (value.hasOwnProperty("customfield")) {
                var customfieldObject = value.customfield;
                $('.breadcrumb[data-num=' + that.chartnumber + ']').append('<li data-num="' + that.chartnumber + '" summary-table="xaxis" level-displayName="' + customfieldObject.name + '"  level-columnName="' + customfieldObject.name + '" level=' + value.filterid + '>'
                    + '<a href="javascript:void(0)" class="resetsummarytable">' + customfieldObject.name + ' [' + customfieldObject.parameter + ']</a></li>');
            } else {
                $('.breadcrumb[data-num=' + that.chartnumber + ']').append('<li data-num="' + that.chartnumber + '" summary-table="xaxis" level-displayName="' + value.columndisplayname + '"  level-columnName="' + value.columnname + '" level=' + value.filterid + '>'
                    + '<a href="javascript:void(0)" class="resetsummarytable">' + value.columndisplayname + ' [' + value.data + ']</a></li>');
            }
        });
        if (getBreadCrumpListFromFilterArray != 0 && that.props.appendbreadcrumb && this.props.filterArray != undefined && this.props.filterArray != '[]' && this.props.filterArray.length != 0) {
            $('.breadcrumb[data-num=' + that.chartnumber + ']').append('<li data-num="' + that.chartnumber + '" summary-table="xaxis" level-displayName="' + that.drilldowndisplayName + '" level-columnName="' + that.drilldownName + '" level=' + this.props.filterArray.length + '>'
                + '<a href="javascript:void(0)" class="resetsummarytable">' + this.props.drilldowndisplayName + '</a></li>');
        }
        /*if($("#drilldownallchartsinstoryboard").prop("checked") != true && this.chartDetails["drillDownAllFilter"]!=undefined && this.chartDetails["drillDownAllFilter"].length!=0){
             $('.breadcrumb[data-num='+that.chartnumber+']').show();
        }*/
        if ($("#drilldownallchartsinstoryboard").prop("checked") == true && this.props.chartDetails["drillDownAllFilter"] != undefined && this.props.chartDetails["drillDownAllFilter"].length != 0) {
            var chartNo = sessionStorage.getItem("currentsummarytbaledrilldown");
            if (chartNo != null && chartNo != that.chartnumber) {
                $('.breadcrumb[data-num=' + that.chartnumber + ']').addClass('disabled');
            }

            //$('.breadcrumb[data-num='+that.chartnumber+']').show();
            //            $(".tileview[data-num=5]").height() - ($(".maxanim4[data-num=5]").outerHeight() + $(".breadcrumb[data-num=5]").outerHeight() + 30)
            if ($(".tileview[data-num=" + that.chartnumber + "]").length != 0) {
                var parentHeight = $(".tileview[data-num=" + that.chartnumber + "]").height() - 40;
                $("#chart" + that.chartnumber + " .summartytablecontainer").css("height", parentHeight);
                $("#chart" + that.chartnumber + " .summartytablecontainer").parent().css("height", "");
                var tfootTotal = $("#chart" + that.chartnumber + " .summartytablecontainer .dataTables_scrollFoot").height() + 90;
                parentHeight = parentHeight - tfootTotal;
                $("#chart" + that.chartnumber + " .dataTables_scrollBody").css("height", parentHeight);
                if ($("#chart" + that.chartnumber + " .dataTables_scrollBody table").height() < $("#chart" + that.chartnumber + " .dataTables_scrollBody").height()) {
                    $("#chart" + that.chartnumber + " .dataTables_scrollBody").css("height", $("#chart" + that.chartnumber + " .dataTables_scrollBody table").height() + 1);
                }
            }
        }
        else {
            var flag = true;
            if (sessionStorage.getItem("drilldown" + that.chartnumber) != undefined) {
                var fiterdata = JSON.parse(sessionStorage.getItem("drilldown" + that.chartnumber));
                _.each(fiterdata, function (data) {
                    if (data["isDrillDownAll"] == true) {
                        flag = false;
                        return;
                    }
                });
            }
            _.each($(".tileview[data-num!=" + that.chartnumber + "]"), function (i) {
                var $this = i
                var chartnumber = $($this).attr("data-num");
                if (that.chartnumber != chartnumber) {
                    if (!flag) {
                        $(".pie-menu").remove();
                        $(".fullscreensample").removeClass("nopie");
                    }
                }
            });
        }
        if (this.props.filterArray == '[]') {
            $('.breadcrumb[data-num=' + that.chartnumber + ']').remove();
        }

        ChartInfo.functions.setSummarytabledrilldownname(that.drilldownName);
        ChartInfo.functions.setSummarytabledrilldowndatatype(that.drilldownDataType);
        ChartInfo.functions.setSummarytabledrilldowndisplayname(that.drilldowndisplayName);
        ChartInfo.functions.setSummaryTableCustomHierarchyDrilldownName(that.drilldownName);
        var aggregationObjects = {};
        var aggregationColumns = [];
        var aggregationOriginalColumns = [];
        var customFields = sessionStorage.getItem("customFields") != null && sessionStorage.getItem("customFields") != 'null' && sessionStorage.getItem("customFields") != "null" ? sessionStorage.getItem("customFields") : this.customFields;
        if (that.props.isCustomstoryboard == true && that.props.isCustomstoryboard != undefined) {
            if (ChartInfo.functions.getCustomFieldsObjectReportedId(that.reportId) != undefined) {
                customFields = ChartInfo.functions.getCustomFieldsObjectReportedId(that.reportId);
            }
        }
        if (customFields != null && customFields != undefined) {
            temp = JSON.parse(customFields);
            var res;
            var customParameterFilterArray = ChartInfo.functions.getCustomParametersFilters(this.props.applyattributes);
            var defaultCustomParameterFilter = ChartInfo.functions.getDefaultCustomParameterFilterValues();
            _.each(temp, function (customfields) {
                _.each(customfields.parametrFieldsArray, function (value) {
                    var customparameter = ChartInfo.functions.getCustomParameter();
                    if (customParameterFilterArray != undefined && customParameterFilterArray.length != 0) {
                        customparameter = customParameterFilterArray;
                    } else if (defaultCustomParameterFilter != undefined && defaultCustomParameterFilter.length > 0) {
                        customparameter = defaultCustomParameterFilter;
                    }
                    res = $.grep(customparameter, function (e) {
                        return e.parametername == value;
                    })
                    var formula = customfields.formula;
                    var scriptcondition = customfields.scriptcondition;
                    var fields = customfields.fields;
                    var sqlformula = customfields.sqlformula;
                    if (res.length != 0) {
                        customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
                        customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
                        customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
                        customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
                    } else {
                        _.each(ChartInfo.functions.getCustomParameter(), function (values) {
                            if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
                                customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
                                customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                            }
                        });
                    }
                });
            });
            customFields = JSON.stringify(temp);
        }
        _.each(this.props.chartDetails["summarytablevalues"], function (yaxisObject) {
            var yAxisJson = {};
            yAxisJson['name'] = yaxisObject.columnName;
            yAxisJson['agg'] = yaxisObject.aggregation;
            yAxisJson['aggDisplayName'] = yaxisObject.aggregationDisplayName;
            yAxisJson['displayname'] = yaxisObject.displayName;
            yAxisJson['dataType'] = yaxisObject.dataType;
            yAxisJson['percentileValue'] = yaxisObject.percentilevalue;
            yAxisJson['displayDataType'] = yaxisObject.displayDataType;
            aggregationObjects[yaxisObject.displayName] = yAxisJson;
            aggregationColumns.push(yaxisObject.displayName);
            aggregationOriginalColumns.push(yaxisObject.columnName);
        });
        var aggregatedCustomFields = ChartInfo.functions.getCustomMeasureField();
        if(this.props.isCustomstoryboard){
            if(ChartInfo.functions.getAggCustomFieldMeasureReportId(this.props.chartDetails.mainreportid)!=undefined){
                aggregatedCustomFields=ChartInfo.functions.getAggCustomFieldMeasureReportId(this.props.chartDetails.mainreportid);
            }
        }
        var chartData = ChartInfo.functions.getChartDetails(this.chartnumber);
        var data = JSON.parse(chartData);
        data['summarytableaggregationColumns'] = aggregationColumns;
        data['summarytableaggregationOriginalColumns'] = aggregationOriginalColumns;
        data['summarytablegroupDataTypes'] = this.groupdataType;
        // data['dobyselectedcolumns']=undefined
        ChartInfo.functions.updateChartDetails(this.chartnumber, JSON.stringify(data));
        var isExcludeNulls = ChartInfo.functions.getIsExcludeNulls();
        var isDateHistogram = this.props.isDateHistogram;
        if (isDateHistogram != undefined) {
            ChartInfo.functions.setSummaryTableDateHistogramState(this.props.dateHistomgramColName, this.chartnumber);

            ChartInfo.functions.setSummaryTableDateHistogramStatus(this.chartnumber, isDateHistogram)
        }
        var dateHistomgramColName = this.props.dateHistomgramColName != undefined && this.props.dateHistomgramColName != "" ? JSON.stringify(this.props.dateHistomgramColName) : "";
        var groupby = false;
        if (this.props.chartDetails["summarytablegroupbys"] != undefined) {
            groupby = true;
        }
        if (this.props.chartDetails["summarytablegroupbys"] != undefined) {
            //groupbyData.push(this.chartDetails["summarytablegroupbys"]);

        } else {
            groupbyData = ''
        }

        //var model = new SummaryTableModel();
        var aggregatedCustomField = ChartInfo.functions.getCustomMeasureField();
        if(this.props.isCustomstoryboard){
            if(ChartInfo.functions.getAggCustomFieldMeasureReportId(this.props.chartDetails.mainreportid)!=undefined){
                aggregatedCustomField=ChartInfo.functions.getAggCustomFieldMeasureReportId(this.props.chartDetails.mainreportid);
            }
        }
        // this.collection = new SummaryTableCollection(SummaryTableModel);
        var that = this;
        var numformat = {};
        if (this.props.chartDetails.chartSettings != undefined && this.props.chartDetails.chartSettings.numberformat != undefined) {
            numformat = this.props.chartDetails.chartSettings.numberformat;
        }
        var filterArray=that.props.filterArray;
        if (aggregationObjects != undefined && groupByArray != undefined && groupByArray.length != 0) {
            if (that.props.filterArray != undefined && that.props.filterArray != "[]") {
                filterArray = JSON.stringify(ChartInfo.functions.generateFilterWithEmbededFilters(JSON.parse(that.props.filterArray)));
            }
            var $that = this;
            var formData = new FormData();
            if($that.reportId!=undefined && $that.reportId!='' && $that.reportId!=null){
                var nlprowlimitobj = ChartInfo.functions.getNLPTableRowLimitDetails($that.reportId);
                if(nlprowlimitobj!=undefined && nlprowlimitobj!="" && nlprowlimitobj!=null)
                formData.append("nlpDetailsObj", JSON.stringify(nlprowlimitobj));   
            }
            formData.append("groups", JSON.stringify(groupByArray));
            formData.append("values", JSON.stringify(aggregationObjects));
            formData.append("reportId", $that.reportId);
            formData.append("indexName", ($that.indexName != undefined) ? $that.indexName : "");
            formData.append("datamodelId", $that.props.datamodelId);
            formData.append("filterArray", filterArray);
            formData.append("groupBy", groupby);
            formData.append("isExcludeorInclude", ($that.props.isExcludeorInclude != undefined) ? $that.props.isExcludeorInclude : "");
            formData.append("entities", ($that.props.entities != undefined) ? $that.props.entities : "");
            formData.append("script", (customFields != undefined) ? customFields : "");
            formData.append("aggregationColumns", JSON.stringify(aggregationColumns));
            formData.append("groupDataTypes", JSON.stringify($that.groupdataType));
            formData.append("isExcludeNulls", (isExcludeNulls != undefined) ? isExcludeNulls : "");
            formData.append("isDateHistogram", isDateHistogram);
            formData.append("customaggregatedscriptfield", JSON.stringify(aggregatedCustomField));
            formData.append("dateHistomgramColName", (dateHistomgramColName != undefined) ? dateHistomgramColName : "");
            formData.append("groupbycolumn", JSON.stringify(groupbyData));
            formData.append("groupsbydisplaynames", JSON.stringify(groupByDisplayName));
            formData.append("timeZone", (that.props.timeZone != undefined) ? that.props.timeZone : "");
            formData.append("isPurgedReport", that.isPurgedReport);
            formData.append("isImportedReport", that.isImportedReport);
            formData.append("numformat", JSON.stringify(numformat));
            formData.append("isPublished", ChartInfo.functions.getisPublished());

            let requestHeaders = {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            SendRequest({
                url: "getsummarytabledata",
                body: formData,
                sucessFunction: (response) => {
                    try {
                        var parsedData = response;
                        if (parsedData != undefined) {
                            response = JSON.parse(decodeURIComponent(parsedData));
                        }
                    } catch (e) {
                    }
                    this.state.response = response;
                    this.response = response;
                    if (response != undefined && response.hasOwnProperty("error")) {
                        $(".spinnerchart").hide();
                        $(".gridspinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.errorTrace)
                        $(".summarytablemessage[data-num=" + that.chartnumber + "]").remove();
                        $(".summarymodal[data-num=" + that.chartnumber + "]").before("<div class='summarytablemessage' data-num='" + that.chartnumber + "'>"+lang["storyboard.configuresummary"]+"</div>");
                        $(".singlereport_summarymodal").before("<div class='summarytablemessage' data-num='" + that.chartnumber + "'>"+lang["storyboard.configuresummary"]+"</div>");
                        $('.summarymodal[data-num=' + that.chartnumber + ']').click();
                        $('.singlereport_summarymodal').click();
                        $("#spinnerchart" + $that.chartnumber).hide();
                        $("#spinner" + this.props.chartNumber).hide();
                        $(".spinner").hide();
                        // ChartUtils.unbindEvents(that.chartnumber);
                    } else {
                        if (response.filterCount != undefined) {
                            $(".maxscreendatacount[data-num=" + that.chartnumber + "] .filterdatacount").text("Filtered Count: " + response.filterCount);
                        }
                        //$that.render();
                        $that.render1();
                        $(".spinnerchart").hide();
                        $(".gridspinner").hide();
                        if(this.props.isDateHistogram =="true"){
                        $(".spinner").hide();
                        }
                        if(this.props.dateHistomgramColName && this.props.dateHistomgramColName.histogramInterval && this.props.dateHistomgramColName.histogramInterval==="default"){
                            $(".spinner").hide();
                        }
                        ReactDOM.unmountComponentAtNode(document.querySelector('.append_summarytablemodal' + that.chartnumber));
                    }
                },
                rejectFunction: (error) => { },
                headers: requestHeaders
            });
        } else {
            $('#spinnerchart' + that.chartnumber).hide();
            $("#spinner" + this.props.chartNumber).hide();
        }
        if (this.props.chartDetails["summarytablecolumns"] != undefined) {
            $(".summarytablemessage[data-num=" + that.chartnumber + "]").remove();
        } else {
            $(".summarytablemessage[data-num=" + that.chartnumber + "]").remove();
            $(".summarymodal[data-num=" + that.chartnumber + "]").before("<div class='summarytablemessage' data-num='" + that.chartnumber + "'>"+lang["storyboard.configuresummary"]+"</div>");
            $(".singlereport_summarymodal").before("<div class='summarytablemessage' data-num='" + that.chartnumber + "'>"+lang["storyboard.configuresummary"]+"</div>");
            $('.summarymodal[data-num=' + that.chartnumber + ']').click();
            setTimeout(function(e){
                $('.singlereport_summarymodal').click();
            },100);
            $('#spinnerchart' + that.chartnumber).hide();
            $("#spinner" + this.props.chartNumber).hide();
        }
        $('.resetsummarytable').click((e) => { PubSub.publish("resetsummarytable", e) });
    }
    componentDidMount() {
        //this.getdata();
        //this.render1();
    }
    render() {
        this.getdata();
        return (<></>);
    }
    summaryModalShowEve(msg, data) {
        this.summarymodalshow(data);
    }
    summarymodalshow(e) {
        e.stopPropagation();
        e.preventDefault();
        var that = this;
        var $this = e.currentTarget;
        var currentChartNumber = $($this).attr("data-num");
        // var currentChartNumber=0;
        ReactDOM.render(
            <SummaryTableSettings viewsummarytabe={this.props.viewsummarytabe} chartnumber={currentChartNumber} measures={viewcollection.getMeasures()} dimensions={viewcollection.getDimensions()} facttables={that.facttables} multifact={that.multifact} multifactentities={that.multifactentities} />,
            document.querySelector('.append_summarytablemodal' + currentChartNumber)
        );
        document.body.classList.add("modal-open");
        ModalProperties.resize();
    }
}