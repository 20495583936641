import React from "react";
import ReactDOM from 'react-dom';
import SendRequest from '../../SendRequest';
import ViewMLParametersTemplate from "./viewmlparametermodal.jsx";
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import _ from "underscore";
import YAxisDropDownView from '../yaxisdropdownview';
import YAxisAttrView from '../yaxisattributesview';
import XAxisDropDownView from "../xaxisdropdownview";
import XAxisAttrView from "../xaxisattributesview";
import ExcludeColumnsDropDownView from "./execludecolumnsdropdownview";
import { getMessage } from '../../js/helpers/utils/BirdMessage';
import { lang } from "../../js/helpers/utils/languages/language";
import ViewMLBodyView from "./viewmlbodyview";
import FutureValuesForForecastingTemplate from "./futurevaluesforforecastingtemplate.jsx";
import AutoMLSummaryView from "./automlsummaryview";
const $ = window.$;

export default class ViewMLParametersView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modelsParameterResponse: {},
            dimensions: this.props.dimensions,
            automlmodeldata:{}
        };
        this.getModelsParameterSettings();
    }
    getModelsParameterSettings() {
        var that = this;
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getallmlmodelsname",
            queryString: "",
            sucessFunction: props => {
                that.state.modelsParameterResponse = props;
                that.render();
                if (that.props.model != undefined) {
                    var model = that.props.model;
                    var mlreportdata,mlSavedModeldata;
                    var isSharedReport = false;
                    if(window.location.pathname.indexOf( "sharereport")>=0){
                    	isSharedReport=true;
                    }
                    if (model.mlreportdata != null && model.mlreportdata != undefined && model.mlreportdata.length > 0) {
                        mlreportdata = JSON.parse(model.mlreportdata);
                    }
                    if(model.ml_model_data!=null&&model.ml_model_data != undefined && model.ml_model_data.length > 0) {
                        mlSavedModeldata =  JSON.parse(model.ml_model_data)
                    }
                    if (mlreportdata != undefined && mlreportdata != null && mlreportdata.length > 0) {
                        this.appendSavedModelData(mlreportdata, isSharedReport,mlSavedModeldata);
                    }
                    setTimeout(function () {
                        var isShowingParameters = $(".mlmodelparameterscontainer").css("display");
                        var isShowingFeature = $(".featureengineeringcontainer").css("display");
                        if (isShowingParameters == "block" || isShowingFeature == "block") {
                            $("section.boc-viewreport-section.mlreport").addClass("modalopen");
                        } else {
                            $("section.boc-viewreport-section.mlreport").removeClass("modalopen");
                        }
                    }, 500);
                    if($(".mlstoryboardtabs.fill-height.loadingmlreport").length==0){
                        $(".spinner").hide();
                    }
                }
            },
            rejectFunction: () => { },
            headers: requestHeaders
        })
    }
    mlPredicttionmodalChange = (e) => {
        var $this = e.currentTarget;
        var that = this;
        var changeValue = $($this).val();
        that.isCorrelationApplied = false;
        $(".mlparameterlists.ml" + changeValue).removeClass("hide");
        $(".mlparameterlists").not(".ml" + changeValue).addClass("hide");
        $(".mlxandyaxis").addClass("hide");
        $(".mltextvals").addClass("hide");
        $(".mldatevals").addClass("hide");
        $(".mlxaxisforparametermodel").addClass("hide");
        $(".automlsetting").addClass('hide')
        $(".actions.clearfix a[href='#next']").removeClass('disabled')
        $(".automlsettingcontent ").addClass('hide');
        $(".mlpreidctxaxisattributes li").parent().parent().remove();
        $(".mlpredictyaxisattributes li").parent().parent().remove();
        if(changeValue!="textanalysis"){
            $(".mlyaxis .attraxisnames").text(lang["mlreports.Y-axis"])
        }else{
            $(".mlyaxis .attraxisnames").text("Target Column");
            $(".sentiment-analysis-type").val("Knowledge-based")
            $(".statisticval").addClass('hide');
            $(".knowledgeval").removeClass('hide');
        }
        if (changeValue != "clustering" && changeValue != "textanalysis" && changeValue != "mlselect") {
            $(".mlxandyaxis").removeClass("hide");
            $('.mlyaxis').removeClass("hide");
        }
        var fromVal = $('.nfolds').attr("min");
        if (that.modelObject != undefined && Object.keys(that.modelObject).length > 0) {
            fromVal = that.modelObject.nfolds;
        }
        if (changeValue == "clustering") {
            that.isCorrelationApplied = true;
            $(".mlxandyaxis").addClass("hide");
            var classname = "kvalue";
            fromVal = $("." + classname).attr("from");
            if (that.modelObject != undefined && Object.keys(that.modelObject).length > 0) {
                fromVal = that.modelObject.kvalue;
            }
            this.addIonRangeSlider(e, classname, fromVal);

            classname = "max_iterations";
            fromVal = $("." + classname).attr("min");
            if (that.modelObject != undefined && Object.keys(that.modelObject).length > 0) {
                fromVal = that.modelObject.max_iterations;
            }
            this.addIonRangeSlider(e, classname, fromVal);

            classname = "max_runtime_secs";
            fromVal = $("." + classname).attr("min");
            if (that.modelObject != undefined && Object.keys(that.modelObject).length > 0) {
                fromVal = that.modelObject.max_runtime_secs;
            }
            this.addIonRangeSlider(e, classname, fromVal);

        } else if (changeValue == "decisiontree") {
            that.isCorrelationApplied = true;
            var classname = "decisiontreenumber";
            fromVal = $("." + classname).attr("min");
            if (that.modelObject != undefined && Object.keys(that.modelObject).length > 0) {
                fromVal = that.modelObject.fitParameter.decisiontreenumber;
            }
            this.addIonRangeSlider(e, classname, fromVal);

            /*classname = "decisiontreerepeats";
            fromVal = $("." + classname).attr("min");
            if (that.modelObject != undefined && Object.keys(that.modelObject).length > 0) {
                fromVal = that.modelObject.controlParameter.decisiontreerepeats;
            }
            this.addIonRangeSlider(e, classname, fromVal);*/

            classname = "decisiontreetunelength";
            fromVal = $("." + classname).attr("min");
            if (that.modelObject != undefined && Object.keys(that.modelObject).length > 0) {
                fromVal = that.modelObject.fitParameter.decisiontreetunelength;
            }
            this.addIonRangeSlider(e, classname, fromVal);

        } else if (changeValue == "generalizedlinearmodeling") {
            that.isCorrelationApplied = true;
            var classname = "nfolds";
            fromVal = $("." + classname).attr("min");
            if (that.modelObject != undefined && Object.keys(that.modelObject).length > 0) {
                fromVal = that.modelObject.max_runtime_secs;
            }
            this.addIonRangeSlider(e, classname, fromVal);
        } else if (changeValue == "textanalysis") {
            $(".mltextvals").removeClass("hide");
            var classname = "nstart";
            fromVal = $("." + classname).attr("from");
            if (that.modelObject != undefined && Object.keys(that.modelObject).length > 0) {
                fromVal = that.modelObject.nstart;
            }
            this.addIonRangeSlider(e, classname, fromVal);

            classname = "burnin";
            fromVal = $("." + classname).attr("min");
            if (that.modelObject != undefined && Object.keys(that.modelObject).length > 0) {
                fromVal = that.modelObject.burnin;
            }
            this.addIonRangeSlider(e, classname, fromVal);

            classname = "iter";
            fromVal = $("." + classname).attr("min");
            if (that.modelObject != undefined && Object.keys(that.modelObject).length > 0) {
                fromVal = that.modelObject.iter;
            }
            this.addIonRangeSlider(e, classname, fromVal);
            $(".mltextcolumns").empty()
        } else if (changeValue == "logisticregression") {
            that.isCorrelationApplied = true;
        } else if (changeValue == "forecasting") {
            $(".mldatevals").removeClass("hide");
            $('.mlyaxis').addClass("hide");
            $(".mldatecolumns").empty();
            $(".mlxaxisforparametermodel").removeClass("hide");
            $('.futurevalues_forecast').empty();
            $('.periods_forecast').val("");
            $(".mlxandyaxis .attraxisnames").text("Target Column")

        }else if(changeValue ==="automl"){
            $(".mlyaxis .attraxisnames").text("Target Variable")
            $(".automlsetting").removeClass('hide')
            var fromVal = $('.timeinterval').attr("min");
            classname = "timeinterval";
            $(".actions.clearfix a[href='#next']").addClass('disabled')
            this.addIonRangeSlider(e, classname, fromVal);
            var fromVal = $('.trainedtestsplit').attr("min");
            classname = "trainedtestsplit";
             this.addIonRangeSlider(e, classname, fromVal);
        }
        //Prompt User to Run Model
        $(".runmodelparameters").addClass("tooltip-highlight");
        setTimeout(function () { $(".runmodelparameters").removeClass("tooltip-highlight"); }, 2500);

    }
    addIonRangeSlider(e, classname, fromVal) {
        $("input[name='" + classname + "']").ionRangeSlider({
            type: "single",
            grid: true,
            min: $("." + classname).attr("min"),
            max: $("." + classname).attr("max"),
            from: fromVal,
            force_edges: true,
            onStart: function (data) {
            },
            onChange: function (data) {
            },
            onFinish: function (data) {
            },
            onUpdate: function (data) {
            }
        });
    }
    showyAxisDropDown = (e) => {
        var that = this;
        that.missingColumns = ChartInfo.functions.getReportArrayMapOfMissingCol(that.props.reportId);
        var selectedModel = $('.mlpredicttionmodal').val();
        var modelDataType = $(".modeldatatype ." + selectedModel).val();
        $(".yaxisdropdown").empty();
        $(".yaxisdropdown").append('<li class="list-title">'+lang["mlreports.Y-axis"]+'</li><li class="xs-list-item"> <input class="form-control form-control-yaxisdropdown search-image" placeholder='+lang["mlreports.search"]+' id="searchinput" type="text"></li>');
        $(".yaxisdropdown").append('<span class="axis-list-items"></span>');
        var columnsList = [];
        var customFields = sessionStorage.getItem("customFields");
        if (customFields != undefined && customFields.length > 0) {
            var customFeildsArray = JSON.parse(customFields);
            _.each(customFeildsArray, function (customField) {
                if (modelDataType == undefined || modelDataType == "") {
                    modelDataType = customField.dataType;
                }
                if (modelDataType == customField.dataType && customField.name.indexOf("id") == -1 && customField.name.indexOf("Id") == -1 && customField.name.indexOf("ID") == -1 && that.props.selectedColumnNamesList.indexOf(customField.name) != -1) {
                    let today = new Date();
                    let dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                    dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                    $(".yaxisdropdown>.axis-list-items").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                    var yAxisDropDown = new YAxisDropDownView({
                        addyaxisvalue: that.addyaxisvalue,
                        dynamicdiv: dynamicdiv,
                        columnDisplayName: customField.name,
                        columnName: customField.name,
                        dataType: "customaggregatedfield",
                        tableName: "",
                        chartType: "mlmodel"
                    });
                    if (selectedModel == "logisticregression") {
                        _.each(ChartInfo.functions.getColumnHavingTwoDistinctValues(), function (value, key) {
                            if (value.columnName == customField.name) {
                                if (that.isCorrelationApplied && that.missingColumns.indexOf(value.columnName) == -1) {
                                    columnsList.push(customField.name);
                                    $(".yaxisdropdown>.axis-list-items").append($("#" + dynamicdiv));
                                }
                            }
                        });
                    } else {
                        if (that.isCorrelationApplied) {
                            if (that.missingColumns != undefined && that.missingColumns.indexOf(customField.name) == -1) {
                                columnsList.push(customField.name);
                                $(".yaxisdropdown>.axis-list-items").append($("#" + dynamicdiv));
                            }
                        } else {
                            columnsList.push(customField.name);
                            $(".yaxisdropdown>.axis-list-items").append($("#" + dynamicdiv));
                        }
                    }
                    modelDataType = $(".modeldatatype ." + selectedModel).val();
                }
                $('.form-control-yaxisdropdown').click(function (e) {
                    e.stopPropagation();
                });
            });
        }
        _.each(that.state.dimensions, function (dimension) {
            if (columnsList.indexOf(dimension.columnDisplayName) == -1) {
                if (modelDataType == undefined || modelDataType == "") {
                    modelDataType = dimension.tableDisplayType != undefined ? dimension.tableDisplayType : dimension.type;
                }
                if (modelDataType == dimension.tableDisplayType && dimension.columnName.indexOf("id") == -1 && dimension.columnName.indexOf("Id") == -1 && dimension.columnName.indexOf("ID") == -1 && that.props.selectedColumnNamesList.indexOf(dimension.columnName) != -1) {
                    if(selectedModel === "automl" && (dimension.tableDisplayType == "date" || dimension.tableDisplayType == "datetime")){
                        return
                    }
                    let today = new Date();
                    let dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                    dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                    $(".yaxisdropdown>.axis-list-items").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                    var yAxisDropDown = new YAxisDropDownView({
                        addyaxisvalue: that.addyaxisvalue,
                        dynamicdiv: dynamicdiv,
                        columnDisplayName: dimension.columnDisplayName,
                        columnName: dimension.columnName,
                        targerColumn: that.targerColumn,
                        dataType: dimension.tableDisplayType != undefined ? dimension.tableDisplayType : dimension.type,
                        tableName: dimension.tableName,
                        aggregation: dimension.aggregation != undefined ? dimension.aggregation : "count",
                        chartType: "mlmodel"
                    })
                    if (selectedModel == "logisticregression") {
                        _.each(ChartInfo.functions.getColumnHavingTwoDistinctValues(), function (value, key) {
                            if (value.columnName == dimension.columnName) {
                                if (that.isCorrelationApplied && that.missingColumns != undefined && that.missingColumns.indexOf(dimension.columnName) == -1) {
                                    columnsList.push(dimension.columnName);
                                    $(".yaxisdropdown>.axis-list-items").append($("#" + dynamicdiv));
                                }
                            }
                        });
                    } else {
                        if (that.isCorrelationApplied) {
                            if (that.missingColumns != undefined && that.missingColumns.indexOf(dimension.columnName) == -1) {
                                columnsList.push(dimension.columnName);
                                $(".yaxisdropdown>.axis-list-items").append($("#" + dynamicdiv));
                            }
                        } else {
                            columnsList.push(dimension.columnName);
                            $(".yaxisdropdown>.axis-list-items").append($("#" + dynamicdiv));
                        }
                    }
                }
                $('.form-control-yaxisdropdown').click(function (e) {
                    e.stopPropagation();
                });
                $('.form-control-yaxisdropdown').keyup(function (e) {
                   that.yAxisDropDownSearch(e);
                })
            }
            modelDataType = $(".modeldatatype ." + selectedModel).val();
        });
    }
    addyaxisvalue = (event) => {
        var that = this;
        var $this = event.currentTarget;
        if (!$($this).hasClass('active')) {
            var columnName = $($this).attr("data-name");
            var columnDisplayName = $($this).find('.itemname').text();
            var datatype = $($this).attr("data-type");
            var aggregation = $($this).find(".boclabel-info").text().replace(/[^A-Z0-9]/ig, "");
            var tableName = $($this).attr("table-name");
            var percentileValue = $($this).attr("percentile-value");
            var activeChartType = $('.charttype.active').attr("id");
            let today = new Date();
            let dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $(".mlpredictyaxisattributes").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            var yAxisAttrView = new YAxisAttrView({
                addyaxisvalue: that.addyaxisvalue,
                dynamicdiv: dynamicdiv,
                yaxiscolumnDisplayName: columnDisplayName,
                yaxiscolumnName: columnName,
                yaxiscolumnType: datatype,
                yaxisaggregation: aggregation,
                yaxistableName: tableName,
                percentileValue: percentileValue,
                measures: this.dimensions,
                chartType: "mlmodel"
            });
            $(".mlpredictyaxisattributes").html($("#" + dynamicdiv));
        }
    }
    showXAxisDropDown = (e) => {
        var that = this;
        var selectedModel = $('.mlpredicttionmodal').val();
        $(".xaxisdropdown").empty();
        $(".xaxisdropdown").append('<li class="list-title">'+lang["mlreports.X-axis"]+'<span class="dimensionoptions"></span></li><li class="xs-list-item"> <input class="form-control form-control-xaxisdropdown search-image" placeholder='+lang["mlreports.search"]+' id="searchinput" type="text"></li>');
        $(".xaxisdropdown").append('<span class="axis-list-items"></span>');
        that.missingColumns = ChartInfo.functions.getReportArrayMapOfMissingCol(that.reportId);
        var mincount = 20;
        var maxcount = 40;
        var xAxisList = [];
        var customFields = sessionStorage.getItem("customFields");
        _.each($(".mlpreidctxaxisattributes .singleliitems"), function (listObj) {
            var columnName = $(listObj).attr("data-name");
            xAxisList.push(columnName);
        });
        if (customFields != undefined && customFields.length > 0) {
            var customFeildsArray = JSON.parse(customFields);
            _.each(customFeildsArray, function (customField) {
                if (customField.name.indexOf("id") == -1 && customField.name.indexOf("Id") == -1 &&
                    customField.name.indexOf("ID") == -1 && xAxisList.indexOf(customField.name) == -1 && that.props.selectedColumnNamesList.indexOf(customField.name) != -1) {
                    if ((selectedModel == "decisiontree" && customField.dataType == "date") || (selectedModel == "forecasting" && customField.dataType != "number")) {
                    } else {
                        let today = new Date();
                        let dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                        $(".xaxisdropdown>.axis-list-items").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                        var xAxisDropDown = new XAxisDropDownView({
                            addxaxisvalue: that.addxaxisvalue,
                            dynamicdiv: dynamicdiv,
                            columnDisplayName: customField.name,
                            columnName: customField.name,
                            dataType: customField.dataType,
                            xaxisidentity: customField.dataType,
                            tableName: "",
                            customfield: 1,
                            chartType: "mlmodel"
                        });
                        if (that.isCorrelationApplied) {
                            if (that.missingColumns != undefined && that.missingColumns.indexOf(customField.name) == -1) {
                                $(".xaxisdropdown>.axis-list-items").append($("#" + dynamicdiv));
                            }
                        } else {
                            $(".xaxisdropdown>.axis-list-items").append($("#" + dynamicdiv));
                        }
                    }
                }
                $('.form-control-xaxisdropdown').click(function (e) {
                    e.stopPropagation();
                });
            });
        }
        _.each(that.state.dimensions, function (dimension) {
            if (dimension.columnName.indexOf("id") == -1 && dimension.columnName.indexOf("Id") == -1 &&
                dimension.columnName.indexOf("ID") == -1 && xAxisList.indexOf(dimension.columnName) == -1 && that.props.selectedColumnNamesList.indexOf(dimension.columnName) != -1) {
                if ((selectedModel == "decisiontree" && dimension.tableDisplayType == "date") || (selectedModel == "forecasting" && dimension.tableDisplayType != "number")) {
                } else {
                    let today = new Date();
                    let dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                    dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                    $(".xaxisdropdown>.axis-list-items").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                    var xAxisDropDown = new XAxisDropDownView({
                        addxaxisvalue: that.addxaxisvalue,
                        dynamicdiv: dynamicdiv,
                        columnDisplayName: dimension.columnDisplayName,
                        columnName: dimension.columnName,
                        dataType: dimension.tableDisplayType,
                        xaxisidentity: dimension.tableDisplayType,
                        tableName: dimension.tableName,
                        customfield: 0,
                        chartType: "mlmodel",
                        reportId: that.props.reportId,
                        datamodelId: that.props.datamodelId,
                    });
                    if (that.isCorrelationApplied) {
                        if (that.missingColumns != undefined && that.missingColumns.indexOf(dimension.columnName) == -1) {
                            $(".xaxisdropdown>.axis-list-items").append($("#" + dynamicdiv));
                        }
                    } else {
                        $(".xaxisdropdown>.axis-list-items").append($("#" + dynamicdiv));
                    }
                }
            }
            $('.form-control-xaxisdropdown').click(function (e) {
                e.stopPropagation();
            });
        });
    }
    addxaxisvalue = (event) => {
        var $this = event.currentTarget;
        var that = this;
        if (!$($this).hasClass('active')) {
            var columnName = $($this).attr("data-name");
            var columnDisplayName = $($this).find(".itemname").text().trim();
            var datatype = $($this).attr("data-type");
            var tableName = $($this).attr("table-name");
            let today = new Date();
            let dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $(".mlpreidctxaxisattributes").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            if ($(".mlpreidctxaxisattributes .singleliitems").length < 1) {
                var xaxisAttrView = new XAxisAttrView({
                    addxaxisvalue: that.addxaxisvalue,
                    dynamicdiv: dynamicdiv,
                    xaxiscolumnDisplayName: columnDisplayName,
                    xaxiscolumnName: columnName,
                    xaxiscolumnType: datatype,
                    xaxistableName: tableName,
                    viewmode:"smartvalues",
                });
                $(".mlpreidctxaxisattributes").append($("#" + dynamicdiv));
                if ($('.mlpredicttionmodal').val() == "forecasting") {
                    // ReactDOM.render(<FutureValuesForForecastingTemplate
                    //     columnName={columnName} displayName={columnDisplayName} dataType={datatype} tableName={tableName} value='' />,
                    //     $(".futurevalues_forecast"));
                }
            } else {
                $(".spinner").hide();
                $(".gridspinner").hide();
                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Warning');
                $('.birdmessage-info').empty().text("Only One X-Field is allowed.");
            }
        }
    }
    runmodelparameters(selectedColumns1, isRunModel, mlSavedModeldata,isFromAutoML) {
        $(".spinner").show();
        var that = this;
        var selectedModel = $('.mlpredicttionmodal').val();
        var modelObject = {};
        var textColumnsArray = [];
        var xAxisArray = [];
        var yAxisArray = [];
        var dateColumnsArray = [];
        var customFields = JSON.parse(sessionStorage.getItem("customFields"));
        var selectedModelsData = {};
        var temp = customFields;
        var res;
        if (mlSavedModeldata != "" && mlSavedModeldata.length > 0 && !isRunModel && selectedModel=="automl") {
            isFromAutoML=true
        }
        if(!isFromAutoML&&selectedModel == "automl"&&ChartInfo.functions.getIsFilterAppliedForAutoML()){
            isFromAutoML =  ChartInfo.functions.getIsFilterAppliedForAutoML();
        }
        if (selectedColumns1.length <= 3 && selectedModel != "textanalysis") {
            $(".spinner").hide();
            $(".gridspinner").hide();
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Information');
            $('.birdmessage-info').empty().text(getMessage('BirdInformation57'));
            setTimeout(function () {
                $("#mlsplitcontainer").steps("previous");
                $(".mlstoryboardtabs.fill-height").removeClass("loadingmlreport");
            }, 1000);
            return false;
        }
        _.each(temp, function (customfields) {
            _.each(customfields.parametrFieldsArray, function (value) {
                var customparameter = ChartInfo.functions.getCustomParameter();
                var customParameterFilterArray = ChartInfo.functions.getCustomParametersFilters(undefined);
                if (customParameterFilterArray != undefined && customParameterFilterArray.length != 0) {
                    customparameter = customParameterFilterArray;
                }
                res = $.grep(customparameter, function (e) {
                    return e.parametername == value;
                })
                var formula = customfields.formula;
                var scriptcondition = customfields.scriptcondition;
                var fields = customfields.fields;
                var sqlformula = customfields.sqlformula;
                if (res.length != 0) {
                    customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
                    customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
                    customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
                    customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
                } else {
                    _.each(ChartInfo.functions.getCustomParameter(), function (values) {
                        if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
                            customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                            customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                            customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
                            customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                        }
                    });
                }
            });
        });
        customFields = temp;
        if (selectedModel == "generalizedlinearmodeling") {
            _.each($(".mlpredictyaxisattributes .singleliitems"), function (listObj) {
                var columnName = $(listObj).attr("data-name");
                var yAxis = {};
                var columnName = $(listObj).attr("data-name");
                var columnDisplayName = $(listObj).attr("title");
                var columnDataType = $(listObj).attr("data-type");
                var aggregation = $(listObj).attr("data-agg");
                var tableName = $(listObj).attr("table-name");
                yAxis["columnName"] = columnName;
                yAxis["displayName"] = columnDisplayName;
                yAxis["dataType"] = columnDataType;
                yAxis["aggregation"] = aggregation;
                yAxis["tableName"] = tableName;
                yAxisArray.push(yAxis);
            });

            if (yAxisArray.length == 0) {
                $(".spinner").hide();
                $(".gridspinner").hide();
                $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
                $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Information');
                $('.birdmessage-info').empty().text(getMessage('BirdInformation55'));
                setTimeout(function () {
                    $("#mlsplitcontainer").steps("previous");
                    $(".mlstoryboardtabs.fill-height").removeClass("loadingmlreport");
                }, 1000);
                return false;
            }
            var nFolds = $('.nfolds').val();
            var gmlFamily = $('.gmlfamily').val();
            // var glmBalanceClasses = $('.glmbalanceclasses').val();
            modelObject["nfolds"] = parseInt(nFolds);
            modelObject["family"] = gmlFamily;
            // modelObject["balance_classes"] = glmBalanceClasses;
            selectedModelsData = {};
            selectedModelsData["selectedModel"] = selectedModel;
            selectedModelsData["xAxisArray"] = xAxisArray;
            selectedModelsData["yValue"] = yAxisArray;
            selectedModelsData["parametersObj"] = modelObject;
        } else if (selectedModel == "clustering") {
            var kvalue = $('.kvalue').val();
            var categoricalencoding = $('.categoricalencoding').val();
            // var ignore_const_cols = $('.ignore_const_cols').val();
            // var score_each_iteration = $('.score_each_iteration').val();
            // var estimate_k = $('.estimate_k').val();
            var max_iterations = $('.max_iterations').val();
            var standardize = $('.standardize').val();
            var max_runtime_secs = $('.max_runtime_secs').val();
            modelObject["kvalue"] = parseInt(kvalue);
            modelObject["categoricalencoding"] = categoricalencoding;
            // modelObject["ignore_const_cols"] = ignore_const_cols;
            // modelObject["score_each_iteration"] = score_each_iteration;
            // modelObject["estimate_k"] = estimate_k;
            modelObject["max_iterations"] = parseInt(max_iterations);
            modelObject["standardize"] = standardize;
            modelObject["max_runtime_secs"] = parseInt(max_runtime_secs);
            selectedModelsData = {};
            selectedModelsData["selectedModel"] = selectedModel;
            selectedModelsData["parametersObj"] = modelObject;
        } else if (selectedModel == "decisiontree") {
            _.each($(".mlpredictyaxisattributes .singleliitems"), function (listObj) {
                var columnName = $(listObj).attr("data-name");
                var yAxis = {};
                var columnName = $(listObj).attr("data-name");
                var columnDisplayName = $(listObj).attr("title");
                var columnDataType = $(listObj).attr("data-type");
                var aggregation = $(listObj).attr("data-agg");
                var tableName = $(listObj).attr("table-name");
                yAxis["columnName"] = columnName;
                yAxis["displayName"] = columnDisplayName;
                yAxis["dataType"] = columnDataType;
                yAxis["aggregation"] = aggregation;
                yAxis["tableName"] = tableName;
                yAxisArray.push(yAxis);
            });
            if (yAxisArray.length == 0) {
                $(".spinner").hide();
                $(".gridspinner").hide();
                $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
                $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Information');
                $('.birdmessage-info').empty().text(getMessage('BirdInformation55'));
                setTimeout(function () {
                    $("#mlsplitcontainer").steps("previous");
                    $(".mlstoryboardtabs.fill-height").removeClass("loadingmlreport");
                }, 1000);
                return false;
            }
            // var method = $('.decisiontreemethod').val();
            var decisiontreenumber = $('.decisiontreenumber').val();
            // var decisiontreerepeats = $('.decisiontreerepeats').val();
            var split = $('.decisiontreesplit').val();
            var decisiontreetunelength = $('.decisiontreetunelength').val();
            var controlParameter = {};
            var fitParameter = {};
            // controlParameter["method"] = method;
            fitParameter["decisiontreenumber"] = parseInt(decisiontreenumber);
            // controlParameter["decisiontreerepeats"] = parseInt(decisiontreerepeats);
            fitParameter["split"] = split;
            fitParameter["decisiontreetunelength"] = parseInt(decisiontreetunelength);
            // modelObject["controlParameter"] = controlParameter;
            modelObject["fitParameter"] = fitParameter;
            selectedModelsData = {};
            selectedModelsData["selectedModel"] = selectedModel;
            selectedModelsData["xAxisArray"] = xAxisArray;
            selectedModelsData["yValue"] = yAxisArray;
            selectedModelsData["parametersObj"] = modelObject;
        } else if (selectedModel == "textanalysis") {
            if ($(".mltextcolumns .singleliitems").length == 0) {
                $(".spinner").hide();
                $(".gridspinner").hide();
                $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
                $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Information');
                $('.birdmessage-info').empty().text(getMessage('BirdInformation51'));
                setTimeout(function () {
                    $("#mlsplitcontainer").steps("previous");
                    $(".mlstoryboardtabs.fill-height").removeClass("loadingmlreport");
                }, 1000);
                return false;
            } else {
                _.each($(".mltextcolumns .singleliitems"), function (listObj) {
                    var textdata = {};
                    var columnName = $(listObj).attr("data-name");
                    var columnDisplayName = $(listObj).attr("title");
                    var columnDataType = $(listObj).attr("data-type");
                    var aggregation = $(listObj).attr("data-agg");
                    var tableName = $(listObj).attr("table-name");
                    textdata["columnName"] = columnName;
                    textdata["displayName"] = columnDisplayName;
                    textdata["dataType"] = columnDataType;
                    textdata["aggregation"] = aggregation;
                    textdata["tableName"] = tableName;
                    textColumnsArray.push(textdata);
                });

                /*var nstart = $('.nstart').val();
                var burnin = $('.burnin').val();
                var iter = $('.burnin').val();
                var method = $('.method').val();
                var best = $('.best').val();
                modelObject["nstart"] = parseInt(nstart);
                modelObject["burnin"] = parseInt(burnin);
                modelObject["iter"] = parseInt(iter);
                modelObject["method"] = method;
                modelObject["best"] = best;*/
                var sentiment_analysis_type = $(".sentiment-analysis-type").val();
                let model_type = $(".sentiment-model-type:not(.hide)").val();
                modelObject["text_col"] = textColumnsArray[0].displayName;
                modelObject["sentiment_col"] = "";
                modelObject["model"] = model_type;
                selectedModelsData = {};
                selectedModelsData["selectedModel"] = selectedModel;
                selectedModelsData["parametersObj"] = modelObject;
                selectedModelsData["sentiment_analysis_type"] = sentiment_analysis_type;
                selectedModelsData["textColumnsArray"] = textColumnsArray;
            }
        } else if (selectedModel == "forecasting") {
            var errorFlag = false;
            _.each($(".alertcondition .form-inline").find(".futurevalue .singlevalue"), function (obj, index) {
                if ($(obj).val().length == 0) {
                    $(obj).addClass("bird-has-error");
                    errorFlag = true;
                } else {
                    $(obj).removeClass("bird-has-error");
                }
            })
            if ($(".mlpreidctxaxisattributes .singleliitems").length === 0 || $(".mlpreidctxaxisattributes .singleliitems").length == 0) {
                $(".spinner").hide();
                $(".gridspinner").hide();
                $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
                $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Information');
                $('.birdmessage-info').empty().text(getMessage('BirdInformation50'));
                errorFlag = true;
            } else if ($(".mldatecolumns .singleliitems").length == 0) {
                $(".spinner").hide();
                $(".gridspinner").hide();
                $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
                $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Information');
                $('.birdmessage-info').empty().text(getMessage('BirdInformation54'));
                errorFlag = true;
            }
            if ($('.periods_forecast').val().length == 0) {
                $('.periods_forecast').addClass("bird-has-error");
                errorFlag = true;
            } else if($('.periods_forecast').val()!=undefined&&$('.periods_forecast').val()!=""&&parseInt($('.periods_forecast').val())<=0){
                $('.periods_forecast').addClass("bird-has-error");
                $(".spinner").hide();
                $(".gridspinner").hide();
                $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
                $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Information');
                $('.birdmessage-info').empty().text("No of Periods to Forecast should be > 0");
                errorFlag = true;
            }else {
                $('.periods_forecast').removeClass("bird-has-error");
            }
            if (errorFlag) {
                setTimeout(function () {
                    $("#mlsplitcontainer").steps("previous");
                    $(".mlstoryboardtabs.fill-height").removeClass("loadingmlreport");
                }, 1000);
                $(".spinner").hide();
                return false;
            } else {
                _.each($(".mlpreidctxaxisattributes .singleliitems"), function (listObj) {
                    var xAxis = {};
                    var columnName = $(listObj).attr("data-name");
                    var columnDisplayName = $(listObj).attr("title");
                    var columnDataType = $(listObj).attr("data-type");
                    var aggregation = $(listObj).attr("data-agg");
                    var tableName = $(listObj).attr("table-name");
                    xAxis["columnName"] = columnName;
                    xAxis["displayName"] = columnDisplayName;
                    xAxis["dataType"] = columnDataType;
                    xAxis["aggregation"] = aggregation;
                    xAxis["tableName"] = tableName;
                    xAxisArray.push(xAxis);
                });
                _.each($(".mlpredictyaxisattributes .singleliitems"), function (listObj) {
                    var columnName = $(listObj).attr("data-name");
                    var yAxis = {};
                    var columnName = $(listObj).attr("data-name");
                    var columnDisplayName = $(listObj).attr("title");
                    var columnDataType = $(listObj).attr("data-type");
                    var aggregation = $(listObj).attr("data-agg");
                    var tableName = $(listObj).attr("table-name");
                    yAxis["columnName"] = columnName;
                    yAxis["displayName"] = columnDisplayName;
                    yAxis["dataType"] = columnDataType;
                    yAxis["aggregation"] = aggregation;
                    yAxis["tableName"] = tableName;
                    yAxisArray.push(yAxis);
                });
                _.each($(".mldatecolumns .singleliitems"), function (listObj) {
                    var textdata = {};
                    var columnName = $(listObj).attr("data-name");
                    var columnDisplayName = $(listObj).attr("title");
                    var columnDataType = $(listObj).attr("data-type");
                    var aggregation = $(listObj).attr("data-agg");
                    var tableName = $(listObj).attr("table-name");
                    textdata["columnName"] = columnName;
                    textdata["displayName"] = columnDisplayName;
                    textdata["dataType"] = columnDataType;
                    textdata["aggregation"] = aggregation;
                    textdata["tableName"] = tableName;
                    dateColumnsArray.push(textdata);
                });
                var independentVariables = [];
                var futureValues = [];
                _.each($('.futurevalues_forecast .form-inline'), function (obj) {
                    var columnname = $(obj).attr("data-name");
                    var value = $(obj).find(".futurevalue .singlevalue").val();
                    independentVariables.push(columnname);
                    futureValues.push(value);
                });

                modelObject["no_of_periods_to_forecast"] = parseInt($('.periods_forecast').val());
                modelObject["forecastingfamily"] = $('.forecastingfamily').val();
                modelObject["aggregation"] = $(".forecastselectperiod").val();
                selectedModelsData = {};
                selectedModelsData["selectedModel"] = selectedModel;
                selectedModelsData["xAxisArray"] = xAxisArray;
                selectedModelsData["yValue"] = yAxisArray;
                selectedModelsData["dateColumnsArray"] = dateColumnsArray;
                selectedModelsData["parametersObj"] = modelObject;
            }

        } else if (selectedModel == "logisticregression") {
            _.each($(".mlpreidctxaxisattributes .singleliitems"), function (listObj) {
                var xAxis = {};
                var columnName = $(listObj).attr("data-name");
                var columnDisplayName = $(listObj).attr("title");
                var columnDataType = $(listObj).attr("data-type");
                var aggregation = $(listObj).attr("data-agg");
                var tableName = $(listObj).attr("table-name");
                xAxis["columnName"] = columnName;
                xAxis["displayName"] = columnDisplayName;
                xAxis["dataType"] = columnDataType;
                xAxis["aggregation"] = aggregation;
                xAxis["tableName"] = tableName;
                xAxisArray.push(xAxis);
            });
            _.each($(".mlpredictyaxisattributes .singleliitems"), function (listObj) {
                var columnName = $(listObj).attr("data-name");
                var yAxis = {};
                var columnName = $(listObj).attr("data-name");
                var columnDisplayName = $(listObj).attr("title");
                var columnDataType = $(listObj).attr("data-type");
                var aggregation = $(listObj).attr("data-agg");
                var tableName = $(listObj).attr("table-name");
                yAxis["columnName"] = columnName;
                yAxis["displayName"] = columnDisplayName;
                yAxis["dataType"] = columnDataType;
                yAxis["aggregation"] = aggregation;
                yAxis["tableName"] = tableName;
                yAxisArray.push(yAxis);
            });
            selectedModelsData = {};
            selectedModelsData["selectedModel"] = selectedModel;
            selectedModelsData["xAxisArray"] = xAxisArray;
            selectedModelsData["yValue"] = yAxisArray;
            selectedModelsData["parametersObj"] = modelObject;
        }
        else if (selectedModel == "automl"&&isFromAutoML) {
            _.each($(".mlpreidctxaxisattributes .singleliitems"), function (listObj) {
                var xAxis = {};
                var columnName = $(listObj).attr("data-name");
                var columnDisplayName = $(listObj).attr("title");
                var columnDataType = $(listObj).attr("data-type");
                var aggregation = $(listObj).attr("data-agg");
                var tableName = $(listObj).attr("table-name");
                xAxis["columnName"] = columnName;
                xAxis["displayName"] = columnDisplayName;
                xAxis["dataType"] = columnDataType;
                xAxis["aggregation"] = aggregation;
                xAxis["tableName"] = tableName;
                xAxisArray.push(xAxis);
            });
            _.each($(".mlpredictyaxisattributes .singleliitems"), function (listObj) {
                var columnName = $(listObj).attr("data-name");
                var yAxis = {};
                var columnName = $(listObj).attr("data-name");
                var columnDisplayName = $(listObj).attr("title");
                var columnDataType = $(listObj).attr("data-type");
                var aggregation = $(listObj).attr("data-agg");
                var tableName = $(listObj).attr("table-name");
                yAxis["columnName"] = columnName;
                yAxis["displayName"] = columnDisplayName;
                yAxis["dataType"] = columnDataType;
                yAxis["aggregation"] = aggregation;
                yAxis["tableName"] = tableName;
                yAxisArray.push(yAxis);
            });
            if (yAxisArray.length == 0) {
                $(".spinner").hide();
                $(".gridspinner").hide();
                $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
                $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Information');
                $('.birdmessage-info').empty().text(getMessage('BirdInformation62'));
                setTimeout(function () {
                    /*if(!isFromAutoML){
                        $("#mlsplitcontainer").steps("previous");
                    }*/
                    $(".mlstoryboardtabs.fill-height").removeClass("loadingmlreport");
                }, 1000);
                return false;
            }
            var time_limit = $('.timeinterval ').val();
            modelObject["time_limit"] = parseInt(time_limit);
            var train_split = $('.trainedtestsplit ').val();
            modelObject["train_split"] = parseInt(train_split);
            selectedModelsData = {};
            selectedModelsData["selectedModel"] = selectedModel;
            selectedModelsData["xAxisArray"] = xAxisArray;
            selectedModelsData["yValue"] = yAxisArray;
            selectedModelsData["parametersObj"] = modelObject;
        }
        if ((JSON.stringify(selectedModelsData) != JSON.stringify(ChartInfo.functions.getMLModelDetails()))) {
            isRunModel = true;
            $('.runmodelbtn').addClass("disabled");
            $('.mlloadingbtn').removeClass("hide");
        }
        if(selectedModel==="automl"&&isFromAutoML){
         ChartInfo.functions.setMLModelDetails(selectedModelsData);
        }else if(selectedModel!="automl"){
            ChartInfo.functions.setMLModelDetails(selectedModelsData);
        }
        var FilterData = ChartInfo.functions.getFilterArray();
        if (mlSavedModeldata != "" && mlSavedModeldata.length > 0 && !isRunModel) {
            $('#mlcharts').empty();
            if($(".mlstoryboardtabs.fill-height.loadingmlreport").length==0){
                $(".spinner").hide();
            }
            if(selectedModel==="automl"){
               let automl_data = JSON.parse(mlSavedModeldata); 
               let automlSelectedModel="";
               automlSelectedModel = automl_data["modelsSummaryData"][0]["modelsnames"]
               $('#trained-models-summarytable table tbody tr').eq(0).addClass('highlight')
               $('#mlcharts').empty();
                new ViewMLBodyView({
                    data: automl_data,
                    selectedModel: selectedModel,
                    features: that.props.features,
                    reportId: that.props.reportId,
                    modelData: that.props.viewreportCollection,
                    dimensions: that.props.dimensions,
                    datamodelId: that.props.datamodelId,
                    automlSelectedModel:automlSelectedModel,
                    automlmodeltype:automl_data["model_type"]
                });
            }else{
            $('.runmodelbtn').removeClass("disabled");
            new ViewMLBodyView({
                data: JSON.parse(mlSavedModeldata),
                selectedModel: selectedModel,
                features: that.props.features,
                reportId: that.props.reportId,
                modelData: that.props.model,
                dimensions: that.props.dimensions,
                datamodelId: that.props.datamodelId,
            });
        }
        } else {
            if(!_.isEmpty(this.state.automlmodeldata) && selectedModel==="automl"&&!isFromAutoML){
             if(selectedModel==="automl"&&!isFromAutoML){
               let automl_data = this.state.automlmodeldata; 
               let automlSelectedModel="";
               if($('#trained-models-summarytable table tbody tr').hasClass('highlight')){
                automlSelectedModel =  $('#trained-models-summarytable table tbody tr.highlight td').eq(0).text();   
               }else{
                   automlSelectedModel = automl_data["modelsSummaryData"][0]["modelsnames"]
                   $('#trained-models-summarytable table tbody tr').eq(0).addClass('highlight')
               }
               $('#mlcharts').empty();
                new ViewMLBodyView({
                    data: automl_data,
                    selectedModel: selectedModel,
                    features: that.props.features,
                    reportId: that.props.reportId,
                    modelData: that.props.viewreportCollection,
                    dimensions: that.props.dimensions,
                    datamodelId: that.props.datamodelId,
                    automlSelectedModel:automlSelectedModel,
                    automlmodeltype:automl_data["model_type"]
                });
                return;
             }
            }else if(selectedModel==="automl"&&!isFromAutoML&&_.isEmpty(this.state.automlmodeldata)){
              return;
            }
            $('.mlloadingbtn').removeClass("hide");
            let requestHeaders = {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
        if(selectedModel){
            var formdata = new FormData();
            formdata.set('selectedModel', selectedModel);
            formdata.set('reportId', that.props.reportId != undefined ? that.props.reportId : "");
            formdata.set('datamodelId', that.props.datamodelId);
            formdata.set('parametersObj', JSON.stringify(modelObject));
            formdata.set('yValue', JSON.stringify(yAxisArray));
            formdata.set('xAxisArray', JSON.stringify(xAxisArray));
            formdata.set('customFields', JSON.stringify(customFields));
            formdata.set('filterObject', JSON.stringify(FilterData));
            formdata.set('selectedColumns', JSON.stringify(selectedColumns1));
            formdata.set('textColumnsArray', JSON.stringify(textColumnsArray));
            formdata.set('dateColumnsArray', JSON.stringify(dateColumnsArray));
            formdata.set('isRunModel', isRunModel != undefined ? isRunModel : false);
            formdata.set('sentiment_analysis_type',  sentiment_analysis_type!= undefined ? sentiment_analysis_type : null);
            SendRequest({
                url: "runmlmodel",
                body: formdata,
                sucessFunction: responseData => {
                    $('#mlcharts').empty();
                    if($(".mlstoryboardtabs.fill-height.loadingmlreport").length==0){
                        $(".spinner").hide();
                    }
                    var response = responseData.data != null && responseData.data != '' ? JSON.parse(responseData.data) : '';
                    $('.runmodelbtn').removeClass("disabled");
                    $('.mlwarning').addClass("hide");
                    $('.mlloadingbtn').addClass("hide");
                    if (responseData.isMLSaved == 1) {
                        $('.mlloadingbtn').addClass("hide");
                    } else if (responseData.isMLSaved == -1) {
                        $('.mlwarning').removeClass("hide");
                        if (responseData.errorInfo != "") {
                            var info = lang["mlreports.modelfailed"] + responseData.errorInfo;
                            $('.mlwarning').attr("title", info);
                        }
                    }
                    if (that.props.datamodelId != undefined) {
                        $('.refreshmlcharts').removeClass("disabled");
                    } else {
                        $('.refreshmlcharts').addClass("disabled");
                        $('.mlloadingbtn').addClass("hide");
                    }
                    if (response != null && response.hasOwnProperty('error')) {
                        $(".spinner").hide();
                        $(".gridspinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.errorTrace)
                        if(selectedModel==="automl"){
                            if(!$(".automlsettingcontent ").hasClass('hide'))
                                $(".automlsettingcontent ").addClass('hide');
                                
                            if(!$(".actions.clearfix a[href='#next']").hasClass('disabled'))
                                $(".actions.clearfix a[href='#next']").addClass('disabled');
                        }
                    } else if(selectedModel==="automl"){
                        this.state.automlmodeldata = response;
                       new AutoMLSummaryView({
                        data: response,
                        selectedModel: selectedModel,
                        features: that.props.features,
                        reportId: that.props.reportId,
                        modelData: that.props.viewreportCollection,
                        dimensions: that.props.dimensions,
                        datamodelId: that.props.datamodelId,
                       })
                    }else {
                        new ViewMLBodyView({
                            data: response,
                            selectedModel: selectedModel,
                            features: that.props.features,
                            reportId: that.props.reportId,
                            modelData: that.props.viewreportCollection,
                            dimensions: that.props.dimensions,
                            datamodelId: that.props.datamodelId,
                        });
                    }
                    $(".smartinsightintro-container").addClass("hide");
                },
                rejectFunction: () => { },
                headers: requestHeaders
            })
        }
        }
    }
    appendSavedModelData(mlreportdata, isSharedReport,mlSavedModeldata) {
        $(".spinner").show();
        var that = this;
        that.dimensions=that.props.dimensions;
        var selectedModel;
        this.modelObject = {};
        var xAxisArray = [];
        var yAxisArray = [];
        var dateColumnsArray = [];
        var savedModelsData = mlreportdata[5];
        if (Object.keys(savedModelsData).length > 0) {
            selectedModel = savedModelsData["selectedModel"];
            this.modelObject = savedModelsData["parametersObj"];
            $('.mlpredicttionmodal option[value=' + selectedModel + ']').prop('selected', true).trigger("change");
            function triggerChange(selector,evt) {
                let ev = new Event(evt, { bubbles: true });
                    let el = $(selector)[0]
                    el.dispatchEvent(ev);
              };
            triggerChange('.mlpredicttionmodal','change');
            $('.mlpredicttionmodal').addClass("disabled");
            if (selectedModel == "generalizedlinearmodeling") {
                yAxisArray = savedModelsData["yValue"];
                setTimeout(function () {
                    _.each(yAxisArray, function (obj, index) {
                        _.each(ChartInfo.functions.getMLSelectedColumns(), function (value, key) {
                            if (value.columnName == obj.columnName) {
                                let today = new Date();
                                let dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                                $(".mlpredictyaxisattributes").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                                var yAxisAttrView = new YAxisAttrView({
                                    dynamicdiv:dynamicdiv,
                                    yaxiscolumnDisplayName: obj.displayName,
                                    yaxiscolumnName: obj.columnName,
                                    yaxiscolumnType: obj.dataType,
                                    yaxisaggregation: "",
                                    yaxistableName: obj.tableName,
                                    percentileValue: "",
                                    measures: that.dimensions,
                                    chartType: "mlmodel"
                                });
                                $(".mlpredictyaxisattributes").html($("#"+dynamicdiv));
                            }
                        });
                    });
                    $('.gmlfamily').val(that.modelObject.family);
                    $('.glmbalanceclasses').val(that.modelObject.balance_classes);
                    if (isSharedReport) {
                        $('.gmlfamily').addClass("disabled");
                        $('.glmbalanceclasses').addClass("disabled");
                        $('.nfolds').addClass("disabled");
                        $('.irs-with-grid').addClass("disabled");
                        $('.mlpreidctxaxisattributes').addClass("disabled");
                        $('.mlpredictyaxisattributes').addClass("disabled");
                    }
                }, 100);
            } else if (selectedModel == "clustering") {
                setTimeout(function () {
                    $('.kvalue').val(that.modelObject.kvalue);
                    $('.categoricalencoding').val(that.modelObject.categoricalencoding);
                    $('.ignore_const_cols').val(that.modelObject.ignore_const_cols);
                    $('.score_each_iteration').val(that.modelObject.score_each_iteration);
                    $('.estimate_k').val(that.modelObject.estimate_k);
                    $('.max_iterations').val(that.modelObject.max_iterations);
                    $('.standardize').val(that.modelObject.standardize);
                    $('.max_runtime_secs').val(that.modelObject.max_runtime_secs);
                });
                if (isSharedReport) {
                    $(".kvalue").addClass("disabled");
                    $('.irs-with-grid').addClass("disabled");
                    $('.categoricalencoding').addClass("disabled");
                    $('.ignore_const_cols').addClass("disabled");
                    $('.score_each_iteration').addClass("disabled");
                    $('.irs-estimate_k-grid').addClass("disabled");
                    $('.max_iterations').addClass("disabled");
                    $('.standardize').addClass("disabled");
                    $('.max_runtime_secs').addClass("disabled");
                }
            } else if (selectedModel == "textanalysis") {
                var textColumnsArray = [];
                textColumnsArray = savedModelsData["textColumnsArray"];
                setTimeout(function () {
                    _.each(textColumnsArray, function (obj, index) {
                        _.each(ChartInfo.functions.getMLSelectedColumns(), function (value, key) {
                            if (value.columnName == obj.columnName) {
                                let today = new Date();
                                let dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                                $(".mltextcolumns").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                                var xaxisAttrView = new XAxisAttrView({
                                   dynamicdiv:dynamicdiv,
                                    xaxiscolumnDisplayName: obj.displayName,
                                    xaxiscolumnName: obj.columnName,
                                    xaxiscolumnType: obj.dataType,
                                    xaxistableName: obj.tableName,
                                    viewmode:"smartvalues",
                                });
                                $(".mltextcolumns").append($("#"+dynamicdiv));
                            }
                        });
                    });
                    $('.sentiment-analysis-type').val(savedModelsData.sentiment_analysis_type);
                    $(".sentiment-model-type:not(.hide)").val(that.modelObject.model);
                    if (isSharedReport) {
                        $('.sentiment-model-type').addClass("disabled");
                        $(".sentiment-model-type:not(.hide)").addClass("disabled");
                    }
                }, 100);
            } else if (selectedModel == "forecasting") {
                dateColumnsArray = savedModelsData["dateColumnsArray"];
                xAxisArray = savedModelsData["xAxisArray"];
                yAxisArray = savedModelsData["yValue"];
                setTimeout(function () {
                    _.each(yAxisArray, function (obj, index) {
                        _.each(ChartInfo.functions.getMLSelectedColumns(), function (value, key) {
                            if (value.columnName == obj.columnName) {
                                let today = new Date();
                                let dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                                $(".mlpredictyaxisattributes").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                                var yAxisAttrView = new YAxisAttrView({
                                    dynamicdiv:dynamicdiv,
                                    yaxiscolumnDisplayName: obj.displayName,
                                    yaxiscolumnName: obj.columnName,
                                    yaxiscolumnType: obj.dataType,
                                    yaxisaggregation: "",
                                    yaxistableName: obj.tableName,
                                    percentileValue: "",
                                    measures: that.dimensions,
                                    chartType: "mlmodel"
                                });
                                $(".mlpredictyaxisattributes").html($("#"+dynamicdiv));
                            }
                        });
                    });
                    var futureValues = that.modelObject.futureValues;
                    _.each(xAxisArray, function (obj, index) {
                        _.each(ChartInfo.functions.getMLSelectedColumns(), function (value, key) {
                            if (value.columnName == obj.columnName) {
                                let today = new Date();
                                let dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                                $(".mlpreidctxaxisattributes").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                                var xaxisAttrView = new XAxisAttrView({
                                    dynamicdiv:dynamicdiv,
                                    xaxiscolumnDisplayName: obj.displayName,
                                    xaxiscolumnName: obj.columnName,
                                    xaxiscolumnType: obj.dataType,
                                    xaxistableName: obj.tableName,
                                    viewmode:"smartvalues",
                                });
                                $(".mlpreidctxaxisattributes").append($("#"+dynamicdiv));
                                //$(".futurevalues_forecast").append(that.futureValuesForForecastingTemplate({columnName:obj.columnName,displayName:obj.displayName,dataType:obj.dataType,tableName:obj.tableName,value:futureValues[index]}))
                            }
                        });
                    });
                    _.each(dateColumnsArray, function (obj, index) {
                        _.each(ChartInfo.functions.getMLSelectedColumns(), function (value, key) {
                            if (value.columnName == obj.columnName) {
                                let today = new Date();
                                let dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                                $(".mldatecolumns").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                               var xaxisAttrView = new XAxisAttrView({
                                    dynamicdiv:dynamicdiv,
                                    xaxiscolumnDisplayName: obj.displayName,
                                    xaxiscolumnName: obj.columnName,
                                    xaxiscolumnType: obj.dataType,
                                    xaxistableName: obj.tableName,
                                    viewmode:"smartvalues",
                                });
                                $(".mldatecolumns").append($("#"+dynamicdiv));
                            }
                        });
                    });
                    $('.periods_forecast').val(that.modelObject.no_of_periods_to_forecast);
                    $('.forecastingfamily').val(that.modelObject.forecastingfamily);
                    $(".forecastselectperiod").val(that.modelObject.aggregation)
                    if (isSharedReport) {
                        $('.periods_forecast').addClass("disabled");
                        $('.forecastingfamily').addClass("disabled");
                        $(".forecastselectperiod").addClass("disabled");
                    }
                }, 100);

            } else if (selectedModel == "decisiontree") {
                yAxisArray = savedModelsData["yValue"];
                setTimeout(function () {
                    _.each(yAxisArray, function (obj, index) {
                        _.each(ChartInfo.functions.getMLSelectedColumns(), function (value, key) {
                            if (value.columnName == obj.columnName) {
                                let today = new Date();
                                let dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                                $(".mlpredictyaxisattributes").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                               
                                var yAxisAttrView = new YAxisAttrView({
                                    dynamicdiv:dynamicdiv,
                                    yaxiscolumnDisplayName: obj.displayName,
                                    yaxiscolumnName: obj.columnName,
                                    yaxiscolumnType: obj.dataType,
                                    yaxisaggregation: "",
                                    yaxistableName: obj.tableName,
                                    percentileValue: "",
                                    measures: that.dimensions,
                                    chartType: "mlmodel"
                                });
                               $(".mlpredictyaxisattributes").html($("#"+dynamicdiv));
                            }
                        });
                    });
                    // $('.decisiontreemethod').val(that.modelObject.controlParameter.method);
                    $('.decisiontreenumber').val(that.modelObject.fitParameter.decisiontreenumber);
                    // $('.decisiontreerepeats').val(that.modelObject.controlParameter.decisiontreerepeats);
                    $('.decisiontreesplit').val(that.modelObject.fitParameter.split);
                    $('.decisiontreetunelength').val(that.modelObject.fitParameter.decisiontreetunelength);
                    if (isSharedReport) {
                        $('.gmlfamily').addClass("disabled");
                        $('.glmbalanceclasses').addClass("disabled");
                        $('.nfolds').addClass("disabled");
                        $('.irs-with-grid').addClass("disabled");
                        $('.mlpreidctxaxisattributes').addClass("disabled");
                        $('.mlpredictyaxisattributes').addClass("disabled");
                    }
                }, 100);
            } else if (selectedModel == "logisticregression") {
                xAxisArray = savedModelsData["xAxisArray"];
                yAxisArray = savedModelsData["yValue"];
                setTimeout(function () {
                    _.each(yAxisArray, function (obj, index) {
                        _.each(ChartInfo.functions.getMLSelectedColumns(), function (value, key) {
                            if (value.columnName == obj.columnName) {
                                let today = new Date();
                                let dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                                $(".mlpredictyaxisattributes").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                               
                                var yAxisAttrView = new YAxisAttrView({
                                    dynamicdiv:dynamicdiv,
                                    yaxiscolumnDisplayName: obj.displayName,
                                    yaxiscolumnName: obj.columnName,
                                    yaxiscolumnType: obj.dataType,
                                    yaxisaggregation: "",
                                    yaxistableName: obj.tableName,
                                    percentileValue: "",
                                    measures: that.dimensions,
                                    chartType: "mlmodel"
                                });
                                $(".mlpredictyaxisattributes").html($("#"+dynamicdiv));
                            }
                        });
                    });
                    _.each(xAxisArray, function (obj, index) {
                        _.each(ChartInfo.functions.getMLSelectedColumns(), function (value, key) {
                            if (value.columnName == obj.columnName) {
                                let today = new Date();
                                let dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                                $(".mlpreidctxaxisattributes").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                               
                                var xaxisAttrView = new XAxisAttrView({
                                   dynamicdiv:dynamicdiv,
                                    xaxiscolumnDisplayName: obj.displayName,
                                    xaxiscolumnName: obj.columnName,
                                    xaxiscolumnType: obj.dataType,
                                    xaxistableName: obj.tableName,
                                    viewmode:"smartvalues",
                                });
                                $(".mlpreidctxaxisattributes").append($("#"+dynamicdiv));
                            }
                        });
                    });
                    $('.gmlfamily').val(that.modelObject.family);
                    $('.glmbalanceclasses').val(that.modelObject.balance_classes);
                    if (isSharedReport) {
                        $('.gmlfamily').addClass("disabled");
                        $('.glmbalanceclasses').addClass("disabled");
                        $('.nfolds').addClass("disabled");
                        $('.irs-with-grid').addClass("disabled");
                        $('.mlpreidctxaxisattributes').addClass("disabled");
                        $('.mlpredictyaxisattributes').addClass("disabled");
                    }
                }, 100);
            } else if (selectedModel == "automl") {
                yAxisArray = savedModelsData["yValue"];
                setTimeout(function () {
                    _.each(yAxisArray, function (obj, index) {
                        _.each(ChartInfo.functions.getMLSelectedColumns(), function (value, key) {
                            if (value.columnName == obj.columnName) {
                                let today = new Date();
                                let dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                                $(".mlpredictyaxisattributes").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                               
                                var yAxisAttrView = new YAxisAttrView({
                                    dynamicdiv:dynamicdiv,
                                    yaxiscolumnDisplayName: obj.displayName,
                                    yaxiscolumnName: obj.columnName,
                                    yaxiscolumnType: obj.dataType,
                                    yaxisaggregation: "",
                                    yaxistableName: obj.tableName,
                                    percentileValue: "",
                                    measures: that.dimensions,
                                    chartType: "mlmodel"
                                });
                               $(".mlpredictyaxisattributes").html($("#"+dynamicdiv));
                            }
                        });
                    });
                    $('.timeinterval').data("ionRangeSlider").update({from:that.modelObject.time_limit})
                    $('.timeinterval').val(that.modelObject.time_limit);
                    $('.trainedtestsplit').data("ionRangeSlider").update({from:that.modelObject.train_split})
                    $('.trainedtestsplit').val(that.modelObject.train_split);
                    that.state.automlmodeldata = mlSavedModeldata;
                    new AutoMLSummaryView({
                        data: mlSavedModeldata,
                        selectedModel: selectedModel,
                        features: that.props.features,
                        reportId: that.props.reportId,
                        modelData: that.props.viewreportCollection,
                        dimensions: that.props.dimensions,
                        datamodelId: that.props.datamodelId,
                       })
                    if (isSharedReport) {
                        $('.timeinterval').addClass("disabled");
                        $('.trainedtestsplit').addClass("disabled");
                    }
                }, 100);
            }
        }
        if (isSharedReport) {
            $('.featureengineeringcontainer').addClass("disabled");
        }
        if($(".mlstoryboardtabs.fill-height.loadingmlreport").length==0){
            $(".spinner").hide();
        }
    }
    forecastingfamily = () => {
        if ($('.forecastingfamily').val() == "nnetar") {
            $('.mlpreidctxaxisattributes').hide();
            $('.mlxaxis').hide();
        } else {
            $('.mlpreidctxaxisattributes').show();
            $('.mlxaxis').show();
        }
    }
    showTextColumnsDropDown = (e) => {
        var that = this;
        $(".textcolsdropdown").empty();
        $(".textcolsdropdown").append('<li class="list-title">'+lang["mlreports.TEXTCOLMS"]+'<span class="dimensionoptions"></span></li><li class="xs-list-item"> <input class="form-control form-control-textcolsdropdown search-image" placeholder='+lang["mlreports.search"]+' id="searchinput" type="text"></li>');
        $(".textcolsdropdown").append('<span class="axis-list-items"></span>');
        var mincount = 20;
        var maxcount = 40;
        var textColumnsList = [];
        var customFields = sessionStorage.getItem("customFields");
        _.each($(".mltextcolumns .singleliitems"), function (listObj) {
            var columnName = $(listObj).attr("data-name");
            textColumnsList.push(columnName);
        });
        if (customFields != undefined && customFields.length > 0) {
            var customFeildsArray = JSON.parse(customFields);
            _.each(customFeildsArray, function (customField) {
                if (customField.name.indexOf("id") == -1 && customField.name.indexOf("Id") == -1 &&
                    customField.name.indexOf("ID") == -1 && textColumnsList.indexOf(customField.name) == -1 && customField.dataType == "string" && that.props.selectedColumnNamesList.indexOf(customField.name) != -1) {
                    let today = new Date();
                    let dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                    dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                    $(".textcolsdropdown>.axis-list-items").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                    var excludeColumnsDropDownView = new ExcludeColumnsDropDownView({
                        addTextvalue: that.addTextvalue,
                        dynamicdiv: dynamicdiv,
                        broker: that.broker,
                        columnDisplayName: customField.name,
                        columnName: customField.name,
                        dataType: customField.dataType,
                        xaxisidentity: customField.dataType,
                        tableName: "",
                        customfield: 1,
                        chartType: "mlmodel",
                        classNameToAppend: "textcolumnitem"
                    });
                    $(".textcolsdropdown>.axis-list-items").append($('#' + dynamicdiv));
                }
                $('.form-control-textcolsdropdown').click(function (e) {
                    e.stopPropagation();
                });
            });
        }
        _.each(that.state.dimensions, function (dimension) {
            if (dimension.columnName.indexOf("id") == -1 && dimension.columnName.indexOf("Id") == -1 &&
                dimension.columnName.indexOf("ID") == -1 && textColumnsList.indexOf(dimension.columnName) == -1 && dimension.tableDisplayType == "string" && that.props.selectedColumnNamesList.indexOf(dimension.columnName) != -1) {
                let today = new Date();
                let dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                $(".textcolsdropdown>.axis-list-items").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                var excludeColumnsDropDownView = new ExcludeColumnsDropDownView({
                    addTextvalue: that.addTextvalue,
                    dynamicdiv: dynamicdiv,
                    columnDisplayName: dimension.columnDisplayName,
                    columnName: dimension.columnName,
                    dataType: dimension.tableDisplayType,
                    xaxisidentity: dimension.tableDisplayType,
                    tableName: dimension.tableName,
                    customfield: 0,
                    chartType: "mlmodel",
                    classNameToAppend: "textcolumnitem"
                });
                $(".textcolsdropdown>.axis-list-items").append($("#" + dynamicdiv));
            }
            $('.form-control-textcolsdropdown').click(function (e) {
                e.stopPropagation();
            });
        });

        $(".textcolsdropdown>.axis-list-items").scroll(function () {
            var searchVal = $(".form-control-textcolsdropdown").val();
            if (($(".textcolsdropdown>.axis-list-items").scrollTop() + $(".textcolsdropdown>.axis-list-items").height() + 25 >= $(".textcolsdropdown>.axis-list-items")[0].scrollHeight) && searchVal == "") {
                _.each(that.state.dimensions.slice(mincount, maxcount), function (dimension) {
                    if (dimension.columnName.indexOf("id") == -1 && dimension.columnName.indexOf("Id") == -1 &&
                        dimension.columnName.indexOf("ID") == -1 && textColumnsList.indexOf(dimension.columnName) == -1 && dimension.tableDisplayType == "string" && that.props.selectedColumnNamesList.indexOf(dimension.columnName) != -1) {
                        let today = new Date();
                        let dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                        $(".textcolsdropdown>.axis-list-items").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                        var excludeColumnsDropDownView = new ExcludeColumnsDropDownView({
                            addTextvalue: that.addTextvalue,
                            dynamicdiv: dynamicdiv,
                            columnDisplayName: dimension.columnDisplayName,
                            columnName: dimension.columnName,
                            dataType: dimension.tableDisplayType,
                            xaxisidentity: dimension.tableDisplayType,
                            tableName: dimension.tableName,
                            customfield: 0,
                            chartType: "mlmodel",
                            classNameToAppend: "textcolumnitem"
                        });
                        $(".textcolsdropdown>.axis-list-items").append($("#" + dynamicdiv));
                    }
                    $('.form-control-textcolsdropdown').click(function (e) {
                        e.stopPropagation();
                    });
                });
                mincount = mincount + 20;
                maxcount = maxcount + 20;
            }
        });
        $('.form-control-textcolsdropdown').click(function (e) {
            e.stopPropagation();
        });
    }
    showDateColumnsDropDown = (e) => {
        var that = this;
        $(".datecolsdropdown").empty();
        $(".datecolsdropdown").append('<li class="list-title">'+lang["mlreports.DATECOLMS"]+'<span class="dimensionoptions"></span></li><li class="xs-list-item"> <input class="form-control form-control-datecolsdropdown search-image" placeholder='+lang["mlreports.search"]+' id="searchinput" type="text"></li>');
        $(".datecolsdropdown").append('<span class="axis-list-items"></span>');
        var mincount = 20;
        var maxcount = 40;
        var textColumnsList = [];
        var customFields = sessionStorage.getItem("customFields");
        _.each($(".mldatecolumns .singleliitems"), function (listObj) {
            var columnName = $(listObj).attr("data-name");
            textColumnsList.push(columnName);
        });
        if (customFields != undefined && customFields.length > 0) {
            var customFeildsArray = JSON.parse(customFields);
            _.each(customFeildsArray, function (customField) {
                if (customField.name.indexOf("id") == -1 && customField.name.indexOf("Id") == -1 &&
                    customField.name.indexOf("ID") == -1 && textColumnsList.indexOf(customField.name) == -1 && customField.dataType == "date" && that.props.selectedColumnNamesList.indexOf(customField.name) != -1) {
                    let today = new Date();
                    let dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                    dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                    $(".datecolsdropdown>.axis-list-items").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                    var excludeColumnsDropDownView = new ExcludeColumnsDropDownView({
                        addDatevalue: that.addDatevalue,
                        dynamicdiv: dynamicdiv,
                        columnDisplayName: customField.name,
                        columnName: customField.name,
                        dataType: customField.dataType,
                        xaxisidentity: customField.dataType,
                        tableName: "",
                        customfield: 1,
                        chartType: "mlmodel",
                        classNameToAppend: "datecolumnitem"
                    });
                    $(".datecolsdropdown>.axis-list-items").append($("#" + dynamicdiv));
                }
                $('.form-control-datecolsdropdown').click(function (e) {
                    e.stopPropagation();
                });
            });
        }
        _.each(that.state.dimensions, function (dimension) {
            if (dimension.columnName.indexOf("id") == -1 && dimension.columnName.indexOf("Id") == -1 &&
                dimension.columnName.indexOf("ID") == -1 && textColumnsList.indexOf(dimension.columnName) == -1 && dimension.tableDisplayType == "date" && that.props.selectedColumnNamesList.indexOf(dimension.columnName) != -1) {
                let today = new Date();
                let dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                $(".datecolsdropdown>.axis-list-items").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                var excludeColumnsDropDownView = new ExcludeColumnsDropDownView({
                    addDatevalue: that.addDatevalue,
                    dynamicdiv: dynamicdiv,
                    columnDisplayName: dimension.columnDisplayName,
                    columnName: dimension.columnName,
                    dataType: dimension.tableDisplayType,
                    xaxisidentity: dimension.tableDisplayType,
                    tableName: dimension.tableName,
                    customfield: 0,
                    chartType: "mlmodel",
                    classNameToAppend: "datecolumnitem"
                });
                $(".datecolsdropdown>.axis-list-items").append($("#" + dynamicdiv));
            }
            $('.form-control-datecolsdropdown').click(function (e) {
                e.stopPropagation();
            });
        });

        $(".datecolsdropdown>.axis-list-items").scroll(function () {
            var searchVal = $(".form-control-datecolsdropdown").val();
            if (($(".datecolsdropdown>.axis-list-items").scrollTop() + $(".datecolsdropdown>.axis-list-items").height() + 25 >= $(".datecolsdropdown>.axis-list-items")[0].scrollHeight) && searchVal == "") {
                _.each(that.state.dimensions.slice(mincount, maxcount), function (dimension) {
                    if (dimension.columnName.indexOf("id") == -1 && dimension.columnName.indexOf("Id") == -1 &&
                        dimension.columnName.indexOf("ID") == -1 && textColumnsList.indexOf(dimension.columnName) == -1 && dimension.tableDisplayType == "date" && that.props.selectedColumnNamesList.indexOf(dimension.columnName) != -1) {
                        let today = new Date();
                        let dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                        $(".datecolsdropdown>.axis-list-items").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                        var excludeColumnsDropDownView = new ExcludeColumnsDropDownView({
                            addDatevalue: that.addDatevalue,
                            dynamicdiv: dynamicdiv,
                            columnDisplayName: dimension.columnDisplayName,
                            columnName: dimension.columnName,
                            dataType: dimension.tableDisplayType,
                            xaxisidentity: dimension.tableDisplayType,
                            tableName: dimension.tableName,
                            customfield: 0,
                            chartType: "mlmodel",
                            classNameToAppend: "datecolumnitem"
                        });
                        $(".datecolsdropdown>.axis-list-items").append($("#" + dynamicdiv));
                    }
                    $('.form-control-datecolsdropdown').click(function (e) {
                        e.stopPropagation();
                    });
                });
                mincount = mincount + 20;
                maxcount = maxcount + 20;
            }
        });
        $('.form-control-datecolsdropdown').click(function (e) {
            e.stopPropagation();
        });
    }
    addTextvalue = (event) => {
        var $this = event.currentTarget;
        if (!$($this).hasClass('active')) {
            var columnName = $($this).attr("data-name");
            var columnDisplayName = $($this).find(".itemname").text().trim();
            var datatype = $($this).attr("data-type");
            var tableName = $($this).attr("table-name");
            // if ($(".mltextcolumns .singleliitems").length < 3) {
                let today = new Date();
                let dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                $(".mltextcolumns").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                var xaxisAttrView = new XAxisAttrView({
                    broker: this.broker,
                    dynamicdiv: dynamicdiv,
                    xaxiscolumnDisplayName: columnDisplayName,
                    xaxiscolumnName: columnName,
                    xaxiscolumnType: datatype,
                    xaxistableName: tableName,
                    viewmode:"smartvalues",
                });
                $(".mltextcolumns").html($("#" + dynamicdiv));
            /*} else {
                $(".spinner").hide();
                $(".gridspinner").hide();
                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Warning');
                $('.birdmessage-info').empty().text("Only Three Text Fields are allowed.");
            }*/
        }
    }
    addDatevalue = (event) => {
        var $this = event.currentTarget;
        if (!$($this).hasClass('active')) {
            var columnName = $($this).attr("data-name");
            var columnDisplayName = $($this).find(".itemname").text().trim();
            var datatype = $($this).attr("data-type");
            var tableName = $($this).attr("table-name");
            if ($(".mldatecolumns .singleliitems").length < 1) {
                let today = new Date();
                let dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                $(".mldatecolumns").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                var xaxisAttrView = new XAxisAttrView({
                    broker: this.broker,
                    dynamicdiv: dynamicdiv,
                    xaxiscolumnDisplayName: columnDisplayName,
                    xaxiscolumnName: columnName,
                    xaxiscolumnType: datatype,
                    xaxistableName: tableName,
                    viewmode:"smartvalues",
                });
                $(".mldatecolumns").append($("#" + dynamicdiv));
            } else {
                $(".spinner").hide();
                $(".gridspinner").hide();
                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Warning');
                $('.birdmessage-info').empty().text("Only One Date Field is allowed.");
            }
        }
    }
    xAxisDropDownSearch = (e) => {
        // Delay function for to wait until user stops typing
        var delay = (function () {
            var timer = 0;
            return function (callback, ms) {
                clearTimeout(timer);
                timer = setTimeout(callback, ms);
            };
        })();
        // Delay function for to wait until user stops typing
        $(".form-control-xaxisdropdown").keyup(function () {
            var valThis = $(this).val().toLowerCase();
            delay(function () {
                $(".itemname").parents(".dimensionitem").each(function () {
                    var text = $(this).text().toLowerCase();
                    (text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
                });
            }, 400);
        });
    }
    yAxisDropDownSearch = (e) => {
        // Delay function for to wait until user stops typing
        var delay = (function () {
            var timer = 0;
            return function (callback, ms) {
                clearTimeout(timer);
                timer = setTimeout(callback, ms);
            };
        })();
        // Delay function for to wait until user stops typing
        $(".form-control-yaxisdropdown").keyup(function () {
            var valThis = $(this).val().toLowerCase();
            delay(function () {
                $(".itemname").parents(".measureitem").each(function () {
                    var text = $(this).text().toLowerCase();
                    (text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
                });
            }, 400);
        });
    }
    textColumnsDropDownSearch = (e) => {
        // Delay function for to wait until user stops typing
        var delay = (function () {
            var timer = 0;
            return function (callback, ms) {
                clearTimeout(timer);
                timer = setTimeout(callback, ms);
            };
        })();
        // Delay function for to wait until user stops typing
        $(".form-control-textcolsdropdown").keyup(function () {
            var valThis = $(this).val().toLowerCase();
            delay(function () {
                $(".itemname").parents(".dimensionitem").each(function () {
                    var text = $(this).text().toLowerCase();
                    (text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
                });
            }, 400);
        });
    }
    dateColumnsDropDownSearch = (e) => {
        // Delay function for to wait until user stops typing
        var delay = (function () {
            var timer = 0;
            return function (callback, ms) {
                clearTimeout(timer);
                timer = setTimeout(callback, ms);
            };
        })();
        // Delay function for to wait until user stops typing
        $(".form-control-datecolsdropdown").keyup(function () {
            var valThis = $(this).val().toLowerCase();
            delay(function () {
                $(".itemname").parents(".dimensionitem").each(function () {
                    var text = $(this).text().toLowerCase();
                    (text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
                });
            }, 400);
        });
    }
    autommlrun = (e)=>{
        var that = this;
        var selectedColumns = ChartInfo.functions.getMLSelectedColumns();
        that.runmodelparameters(selectedColumns, true, "",true);
    }
    mlsentimentmodalChange = (e) => {
        var $this = e.currentTarget;
        var that = this;
        var changeValue = $($this).val();
        if(changeValue === "Knowledge-based"){
            $(".knowledgeval").removeClass('hide');
            $(".statisticval").addClass('hide');
        }else if(changeValue === "statistical"){
            $(".knowledgeval").addClass('hide');
            $(".statisticval").removeClass('hide');
        }
    }    
    render() {
        ReactDOM.unmountComponentAtNode(document.getElementById("mlmodelparameterscontainer"))
        ReactDOM.render(<ViewMLParametersTemplate
            modelNames={this.state.modelsParameterResponse.modelNameObj}
            inputParameters={this.state.modelsParameterResponse.inputParameterObj}
            outputParameters={this.state.modelsParameterResponse.outputParameterObj}
            mlPredicttionmodalChange={this.mlPredicttionmodalChange}
            showyAxisDropDown={this.showyAxisDropDown} showXAxisDropDown={this.showXAxisDropDown}
            showTextColumnsDropDown={this.showTextColumnsDropDown} showDateColumnsDropDown={this.showDateColumnsDropDown}
            xAxisDropDownSearch={this.xAxisDropDownSearch} textColumnsDropDownSearch={this.textColumnsDropDownSearch}
            yAxisDropDownSearch={this.yAxisDropDownSearch} dateColumnsDropDownSearch={this.dateColumnsDropDownSearch}
            autommlrun={this.autommlrun} 
            mlsentimentmodalChange = {this.mlsentimentmodalChange}
            />,
            document.getElementById("mlmodelparameterscontainer"));
        setTimeout(function () {
            var isShowingParameters = $(".mlmodelparameterscontainer").css("display");
            var isShowingFeature = $(".featureengineeringcontainer").css("display");
            if (isShowingParameters == "block" || isShowingFeature == "block") {
                $("section.boc-viewreport-section.mlreport").addClass("modalopen");
            } else {
                $("section.boc-viewreport-section.mlreport").removeClass("modalopen");
            }
        }, 500);
        if($(".mlstoryboardtabs.fill-height.loadingmlreport").length==0){
            $(".spinner").hide();
        }
        return (
            <React.Fragment />
        );
    }
}