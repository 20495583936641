import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'underscore';
import { getMessage } from '../js/helpers/utils/BirdMessage';
import SendRequest from '../SendRequest';
import * as ChartInfo from '../js/helpers/utils/chartinfo';
import DatahubSyncConfigHtml from './DatahubSyncconfigHtml.jsx';
import CustomSyncDateRangeHtml from './CustomSyncDateRangeHtml.jsx';
import CustomSyncNumericRangeHtml from './CustomSyncNumericRangeHtml.jsx';
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;

export default class DatahubSyncConfig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            response: {}
        };
        this.selectedStoredProcs = props.selectedStoredProcs;
        this.selectedCustomQueries = props.selectedCustomQueries;
        this.dataHubEntitiesCollection = props.dataHubEntitiesCollection;
        this.flatFileEntities = props.flatFileEntities;
        this.allEntities = JSON.parse(props.allEntities);
        this.tempEntities = JSON.parse(props.tempEntities);
        this.savedEntities = props.savedEntities;
        this.syncConfigs = props.syncConfigs;
        _.each(this.allEntities, (i, j) => {
            var data = i;
            //for getting saved report columns
            _.each(this.dataHubEntitiesCollection, (k, l) => {
                if (j == k.connAccessId + "&&&" + k.entityname) {
                    data.report_columns = JSON.parse(k.entityColumns);
                }
            });
            //for getting updated report columns
            var datahubentities = props.getUpdatedEntities(j);
            this.reportcolumns = props.getUpdatedreportcolumns(j);

            data.isFromModifiedEntities = true;
            if (datahubentities != undefined && datahubentities.length > 0) {
                data.report_columns = datahubentities;
            }
        });
        this.datahubdetails = {};
        this.tables = [];
        this.query = {};
        this.datahubentities = {};
        this.groupedConnections = {};
        this.finalEntitiesSourcesFullList = [];
        this.counti = 0
        if (!_.isEmpty(this.tempEntities) && this.tempEntities != undefined) {
            var data = new FormData();
            data.append("entities", JSON.stringify(this.tempEntities));
            SendRequest({
                url: "getentitiesmetadata",
                queryString: "",
                body: data,
                sucessFunction: (response) => {
                    this.state.response = response;
                    this.loadSyncPage()
                },
                rejectFunction: (error) => { },
                headers: {
                    method: "post",
                    headers: new Headers({
                        "Content-Type": "application/json"
                    })
                }
            });
        }
        else {
            this.loadSyncPage();
        }
        $('a[href*="#finish"]').click(() => {
            this.saveDataHub();
        });
    }
    componentDidMount() {
        _.each(this.tables, (entity) => {
            $("#incrementalrefreshfield[data-id='" + entity.connaccessid + "&&&" + entity.name + "'] ").chosen({ 'width': '100%' });
            $("#uniqueselect[data-id='" + entity.connaccessid + "&&&" + entity.name + "'] ").chosen({ 'width': '100%' });
            $("#streamingstatus[data-id='" + entity.connaccessid + "'][data-name='" + entity.name + "'] ").prop('checked', true)
            if (entity.dataPushStatus != null && entity.dataPushStatus == '0') {
                $("#streamingstatus[data-id='" + entity.connaccessid + "'][data-name='" + entity.name + "'] ").prop('checked', false)
            }
        });
    }
    getNumericAttributes = (connectionid, entity) => {
        var tableDetails = this.datahubdetails[connectionid + "&&&" + entity];
        var tableEntities = tableDetails.entities;
        var numberData = [];
        _.each(tableEntities, function (i, j) {
            var dataType = i.dataType;
            if (i.dataType == undefined && i.tableDisplayType != undefined) {
                dataType = i.tableDisplayType;
            }
            if (dataType == 'number' && i.isPartitionKey != "yes") {
                var entity = {};
                entity['name'] = i.columnName;
                entity['type'] = i.dataType;
                entity['connaccessid'] = connectionid;
                entity['tablename'] = i.tableName;
                numberData.push(entity);
            }

        });
        numberData.sort(function (a, b) {
            var x = a.name.toLowerCase();
            var y = b.name.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
        });
        return numberData;
    }
    getNumericAndDateAttributes = (connectionid, entity) => {
        var tableDetails = this.datahubdetails[connectionid + "&&&" + entity];
        var tableEntities = tableDetails.entities;
        var numberAndDateData = [];
        _.each(tableEntities, function (i, j) {
            var dataType = i.dataType;
            if (i.dataType == undefined && i.tableDisplayType != undefined) {
                dataType = i.tableDisplayType;
            }
            if ((dataType.toLowerCase() == 'number' && i.isPartitionKey != "yes") || dataType == 'date' || dataType == 'datetime') {
                var entity = {};
                entity['name'] = i.columnName;
                entity['type'] = dataType;
                entity['connaccessid'] = connectionid;
                entity['tablename'] = i.tableName;
                numberAndDateData.push(entity);
            }

        });
        numberAndDateData.sort(function (a, b) {
            var x = a.name.toLowerCase();
            var y = b.name.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
        });
        return numberAndDateData;
    }
    getQuery = (connectionid, entity) => {
        var tableDetails = this.datahubdetails[connectionid + "&&&" + entity];
        if (tableDetails != undefined && tableDetails.sqlquery != undefined) {
            return tableDetails.sqlquery;
        } else {
            return "";
        }
    }
    getAllFeildsJson = (connectionid, entity) => {
        var tableDetails = this.datahubdetails[connectionid + "&&&" + entity];
        var tableEntities = tableDetails.entities;
        var feildData = [];
        _.each(tableEntities, (i, j) => {
            var entity = {};
            var dataType = i.dataType;
            if (i.dataType == undefined && i.tableDisplayType != undefined) {
                dataType = i.tableDisplayType;
            }
            if (dataType === "double" || dataType === "long") {
                dataType = "number"
            }
            if (i.dataType === "double" || i.dataType === "long") {
                i.dataType = "number"
            }
            if (i.dataType === 'date' || i.tableDisplayType === 'date') {
                if (i.dateFormat != undefined && i.dateFormat != null && i.dateFormat != null) {
                    entity['dateFormat'] = i.dateFormat;
                }
            }
            entity['visible'] = "yes";
            entity['tableAliasName'] = i.tableName;
            entity['columnDisplayName'] = i.columnName.trim();
            entity['dateFormat'] = i.dateFormat;
            entity['tableDisplayName'] = i.tableName;
            entity['tableName'] = i.tableName;
            entity['orginalColumnName'] = i.columnName.trim();
            entity['columnName'] = i.columnName.trim();
            entity['tableDisplayType'] = dataType;
            if (i.isPartitionKey != undefined) {
                entity['isPartitionKey'] = i.isPartitionKey;
            }
            entity['name'] = i.columnName.trim();
            entity['type'] = dataType;
            entity['connaccessid'] = connectionid;
            entity['tablename'] = i.tableName;
            feildData.push(entity);

        });
        return feildData;
    }
    getAllFeilds = (connectionid, entity) => {
        var tableDetails = this.datahubdetails[connectionid + "&&&" + entity];
        var tableEntities = tableDetails.entities;
        var feildData = [];
        _.each(tableEntities, function (i, j) {
            var entity = {};
            entity['name'] = i.columnName;
            entity['type'] = i.dataType;
            entity['connaccessid'] = connectionid;
            entity['tablename'] = i.tableName;
            feildData.push(entity);

        });
        feildData.sort(function (a, b) {
            var x = a.name.toLowerCase();
            var y = b.name.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
        });
        return feildData;
    }
    getDateAttributes = (connectionid, entity) => {
        var tableDetails = this.datahubdetails[connectionid + "&&&" + entity];
        var tableEntities = tableDetails.entities;
        var dateData = [];
        _.each(tableEntities, (i, j) => {
            var dataType = i.dataType;
            if (i.dataType == undefined && i.tableDisplayType != undefined) {
                dataType = i.tableDisplayType;
            }
            if (dataType == 'date' || dataType == 'datetime') {
                var entity = {};
                entity['name'] = i.columnName;
                entity['type'] = dataType;
                entity['connaccessid'] = connectionid;
                entity['tablename'] = i.tableName;
                dateData.push(entity);
            }

        });
        dateData.sort(function (a, b) {
            var x = a.name.toLowerCase();
            var y = b.name.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
        });
        return dateData;
    }
    syncFinalConnectionsSearch(e) {
        var $this = e.currentTarget;
        /*Delay function for to wait until user stops typing*/
        var delay = (function () {
            var timer = 0;
            return function (callback, ms) {
                clearTimeout(timer);
                timer = setTimeout(callback, ms);
            };
        })();
        /*Delay function for to wait until user stops typing*/
        $("#syncfinalconnectionssearch").keyup(function () {
            var valThis = $(this).val().toLowerCase();
            var searchin = $('#finalconnectionlistcontainer');
            delay(function () {
                $(searchin).find("li.finalconnectionlist-item").each(function () {
                    var text = $(this).text().trim().toLowerCase();
                    if (text.indexOf(valThis) >= 0) {
                        $(this).addClass('search-success');
                        $(this).removeClass('hide');
                    } else {
                        $(this).removeClass('search-success');
                        $(this).addClass('hide');
                    }
                });
                _.each($("#finalconnectionlistcontainer h4.panelboc-title"), function (i) {
                    var targetContainer = $(i).attr("href").replace("#", "");
                    var successLength = $("#" + targetContainer + " .finalconnectionlist-item.search-success").length
                    if (successLength > 0) {
                        $(i).removeClass("hide");
                        $(i).removeClass("collapsed");
                        $(i).attr("aria-expanded", true);
                        $("#" + targetContainer + "").css("height", "auto")
                        $("#" + targetContainer + "").addClass("in");
                        $("#" + targetContainer + "").attr("aria-expanded", true);
                    } else {
                        $(i).addClass("hide");
                        $(i).addClass("collapsed");
                        $(i).attr("aria-expanded", false);
                        $("#" + targetContainer + "").css("height", "0px")
                        $("#" + targetContainer + "").removeClass("in");
                        $("#" + targetContainer + "").attr("aria-expanded", false);
                    }
                });
            }, 400);
        });
    }
    syncFinalEntitySearch(e) {
        var $this = e.currentTarget;
        /*Delay function for to wait until user stops typing*/
        var delay = (function () {
            var timer = 0;
            return function (callback, ms) {
                clearTimeout(timer);
                timer = setTimeout(callback, ms);
            };
        })();
        /*Delay function for to wait until user stops typing*/
        $("#syncfinalentitysearch").keyup(function () {
            var valThis = $(this).val().toLowerCase();
            var searchin = $('#synctablescontainer .panel');
            delay(function () {
                $(searchin).find("h4.panelboc-title").each(function () {
                    var text = $(this).text().trim().toLowerCase();
                    var targetContainer = $(this).attr("href").replace("#", "");
                    if (text.indexOf(valThis) >= 0) {
                        $(this).addClass('search-success');
                        $(this).removeClass('hide');
                        $("#" + targetContainer + "").removeClass('hide');
                    } else {
                        $(this).removeClass('search-success');
                        $(this).addClass('hide');
                        $("#" + targetContainer + "").addClass('hide');
                    }
                });
            }, 400);
        });
    }
    showSelectedEntities = (e) => {
        var $this = e.currentTarget;
        $("#finalconnectionlistcontainer li.finalconnectionlist-item").removeClass("active");
        $($this).addClass("active");
        this.showSelectedConnectionEntities();
        // $("#synctablescontainer h4.panelboc-title:not('.hiddenentity')").eq(0).trigger("click");
    }
    showSelectedConnectionEntities = () => {
        var currentConnection = $("#finalconnectionlistcontainer li.finalconnectionlist-item.active").attr("source-id");
        var currentConnectionName = $("#finalconnectionlistcontainer li.finalconnectionlist-item.active").attr("title");
        $("#synctablescontainer h2 span").html(currentConnectionName);
        $("#synctablescontainer h4").removeClass("hiddenentity");
        $("#synctablescontainer h4[data-id!='" + currentConnection + "']").addClass("hiddenentity");
        $("#synctablescontainer div.panelboc-content").removeClass("hiddenentity");
        $("#synctablescontainer div.panelboc-content[data-id!='" + currentConnection + "']").addClass("hiddenentity");
        $("#synctablescontainer h4.panelboc-title").eq(0).trigger("click");
        this.showSelected($("#synctablescontainer h4.panelboc-title").eq(0));
        $('.spinner').hide();
    }
    changeSyncConfig = (e) => {
        var that = this;
        var $this = "";
        var val1 = "";
        var val2 = "";
        if (e.currentTarget != undefined) {
            $this = e.currentTarget;
            val1 = $this.attributes["data-name"].value;
            val2 = $this.attributes["data-id"].value;
        } else {
            $this = e;
            val1 = $this.attr("data-name");
            val2 = $this.attr("data-id");
        }
        var existingEntitiesMetaData = ChartInfo.functions.getExistingEntitiesMetaData();
        var groupedConnections3 = _.groupBy(existingEntitiesMetaData, 'entityname');
        _.each(groupedConnections3[val1], function (val, index) {
            if (val.connAccessId == val2) {
                val.datasynctype = $(".syncdomelementhub[data-name='" + val.entityname + "'][data-id=" + val.connAccessId + "]").val();
                // if($(".syncdomelementhub[data-name='" + val.entityname + "'][data-id=" + val.connAccessId + "]").val()!=undefined && 
                // $(".syncdomelementhub[data-name='" + val.entityname + "'][data-id=" + val.connAccessId + "] option[value="+$(".syncdomelementhub[data-name='" + val.entityname + "'][data-id=" + val.connAccessId + "]").val()+"]").attr('status')!=undefined && 
                // $(".syncdomelementhub[data-name='" + val.entityname + "'][data-id=" + val.connAccessId + "] option[value="+$(".syncdomelementhub[data-name='" + val.entityname + "'][data-id=" + val.connAccessId + "]").val()+"]").attr('status')=="Inactive" ){
                //  //   $(".syncdomelementhub[data-name='" + val.entityname + "'][data-id=" + val.connAccessId + "] option[value="+$(".syncdomelementhub[data-name='" + val.entityname + "'][data-id=" + val.connAccessId + "]").val()+"]").attr('status')
                // }
            }
        })
    }
    showSelected = (e) => {
        // if we click on entity dropdown in the datahub sync page,this function will execute
        var that = this;
        var $this = "";
        var val1 = "";
        var val2 = "";
        if (e.currentTarget != undefined) {
            $this = e.currentTarget;
            $("#finalconnectionlistcontainer li.finalconnectionlist-item").removeClass("active");
            $($this).addClass("active");
            $($this).addClass("changed");
            val1 = $this.attributes["title"].value;
            val2 = $this.attributes["data-id"].value;
        } else {
            $this = e;
            $("#finalconnectionlistcontainer li.finalconnectionlist-item").removeClass("active");
            $($this).addClass("active");
            $($this).addClass("changed");
            val1 = $this.attr("title");
            val2 = $this.attr("data-id");
        }

        var finalEntitiesSources = that.tables;
        var groupedConnections1 = _.groupBy(finalEntitiesSources, 'name');
        var existingEntitiesMetaData = ChartInfo.functions.getExistingEntitiesMetaData();
        //for fetching first entity details when loading datahubsync page
        var groupedConnections2 = _.groupBy(existingEntitiesMetaData, 'connAccessId');
        if (groupedConnections2.length == undefined) {
            groupedConnections2 = "";
        }
        if (groupedConnections2.length != undefined) {

            _.each(groupedConnections1[val1], function (feild) {
                if (val2 == feild.connaccessid) {
                    var numericAndDateFeilds = that.getNumericAndDateAttributes(feild.connaccessid, feild.name);
                    if ($("#uniqueselect[data-id='" + feild.connaccessid + "'][name='" + feild.name + "'] optgroup.uniqueselectfield_group option").length <= 0) {
                        $("#incrementalrefreshfield[data-id='" + feild.connaccessid + "'][name='" + feild.name + "'] optgroup.incrementalrefreshfield_group option").remove()
                        _.each(numericAndDateFeilds, function (numericfeild) {
                            if (feild.type == "quickbooks") {
                                $("#incrementalrefreshfield[data-id='" + feild.connaccessid + "'][name='" + feild.name + "'] .incrementalrefreshfield_group").append("<option columnname='" + numericfeild.orginalColumnName + "' value='" + numericfeild.orginalColumnName + "'>" + numericfeild.orginalColumnName + "</option>");
                            }
                            else {
                                $("#incrementalrefreshfield[data-id='" + feild.connaccessid + "'][name='" + feild.name + "'] .incrementalrefreshfield_group").append("<option columnname='" + numericfeild.name + "' value='" + numericfeild.name + "'>" + numericfeild.name + "</option>");
                            }
                        });

                        var allfeilds = that.getAllFeilds(feild.connaccessid, feild.name);
                        $("#uniqueselect[data-id='" + feild.connaccessid + "'][name='" + feild.name + "'] optgroup.uniqueselectfield_group option").remove()
                        _.each(allfeilds, function (allfeild) {
                            $("#uniqueselect[data-id='" + feild.connaccessid + "'][name='" + feild.name + "'] .uniqueselectfield_group").append("<option columnname='" + allfeild.name + "' value='" + allfeild.name + "'>" + allfeild.name + "</option>");
                        });
                        $("#incrementalrefreshfield[data-id='" + feild.connaccessid + "'][name='" + feild.name + "']").chosen({ 'width': '100%', search_contains: true });
                        $("#uniqueselect[data-id='" + feild.connaccessid + "'][name='" + feild.name + "']").chosen({ 'width': '100%', search_contains: true });
                    }
                }
            });
        }
        var groupedConnections3 = _.groupBy(existingEntitiesMetaData, 'entityname');

        _.each(groupedConnections3[val1], function (val, index) {
            if (val.connAccessId == val2) {
                that.syncConfigData = val.syncSelectedType
                that.uniqueselectedcolumn = val.uniqueColumns
                var selectedDateFileds = [];
                var refreshType = "";
                if (that.syncConfigData != undefined && that.syncConfigData != "") {
                    var syncdata = {};
                    try {
                        syncdata = JSON.parse(that.syncConfigData);
                    } catch (err) {
                        syncdata = that.syncConfigData;
                    }

                    refreshType = syncdata["refreshType"];
                    that.updateSyncConfigDetails(val, syncdata, selectedDateFileds, refreshType);
                    $('.spinner').hide();
                }
            }
        })
        //this.showSelectedConnectionEntities(false);
        //$("#synctablescontainer h4.panelboc-title:not('.hiddenentity')").eq(0).trigger("click");
    }

    saveDataHub = () => {
        var that = this;
        var syncDetails = [];
        var syncDatagiven = [];
        var existingsyncDetails = {}
        var k = 0;
        var isSyncValid = true;
        _.each(JSON.parse(this.props.allEntities), (i, j) => {
            if (i.type == "storedprocedure") {
                return;
            }
            syncDatagiven[k] = false
            var syncConfigData = {};
            var syncObj = {};
            var totalsyncObj = {};
            var data = j.split("&&&");
            var conAccessId = data[0];
            var entity = data[1];
            var checkedId = $(".datasyncconfigtype[data-id='" + conAccessId + "'][name='" + entity + conAccessId + "']:checked").attr("id");
            var syncFilters = $(".synfilterscselector .datasyncconfigtype[data-id='" + conAccessId + "'][name='" + entity + conAccessId + "']:checked").attr("id");
            if (checkedId == undefined) {
                checkedId = "alldata";
            }
            var isUniqueIdEnabled = $(".checkbox-slider--c input[data-name='" + entity + "'][data-id='" + conAccessId + "']").is(':checked');
            if ($("#streamingstatus[data-id='" + conAccessId + "'][data-name='" + entity + "']").length > 0) {
                syncConfigData['data_push_status'] = $("#streamingstatus[data-id='" + conAccessId + "'][data-name='" + entity + "']").prop('checked');
                isUniqueIdEnabled = false;
            } else {
                syncConfigData['data_push_status'] = null;
            }
            syncConfigData['entity'] = entity;
            syncConfigData['isUniqueIdEnabled'] = isUniqueIdEnabled;
            if(sessionStorage.getItem(j) != null && sessionStorage.getItem(j) != undefined && sessionStorage.getItem(j) != "undefined"){
                syncConfigData['type'] =  sessionStorage.getItem(j);
            }else{
            syncConfigData['type'] = i.isCustomQuery != undefined && i.isCustomQuery == true ? "customqueryentity" : "dbentity";
            }
            syncConfigData['conAccessId'] = conAccessId;
            syncConfigData['conAccessDetails'] = i;
            var entityModified = false
            //if there any modified entities while saving 
            if ($("#conns[data-id='" + conAccessId + "'][title='" + entity + "']").hasClass("changed")) {
                entityModified = true
                $("#conns[data-id='" + conAccessId + "'][title='" + entity + "']").removeClass("changed")
            }
            syncConfigData['syncType'] = $(".syncdomelementhub[data-name='" + entity + "'][data-id=" + conAccessId + "]").val();
            syncConfigData['syncVal'] = $(".syncdomelementhub[data-name='" + entity + "'][data-id=" + conAccessId + "]").val();
            //for constructing syncconfig obj if the entities are already present
            var existingEntitiesMetaData = ChartInfo.functions.getExistingEntitiesMetaData();
            var groupedEntityMetadataName = _.groupBy(existingEntitiesMetaData, 'uniqueEntityName');
            if ((groupedEntityMetadataName[j] != undefined) && entityModified == false) {
                var uniqueFeildValue = "";
                var str = ""
                var uniquecolumns = groupedEntityMetadataName[j][0].uniqueColumns
                var columnNameObjs = JSON.parse(groupedEntityMetadataName[j][0].syncSelectedType)
                var incrementalFields = columnNameObjs.columnNameObjs;
                var iFields = columnNameObjs.columnNameObjs;
                checkedId = columnNameObjs.checkedId;
                syncConfigData['syncType'] = groupedEntityMetadataName[j][0].datasynctype;
                syncConfigData['syncVal'] = groupedEntityMetadataName[j][0].datasynctype;
                if (columnNameObjs.filterRefreshType != undefined && (columnNameObjs.filterRefreshType == "selecteddata" || columnNameObjs.filterRefreshType == "selectednumericrange")) {
                    var columnNameObjs1 = groupedEntityMetadataName[j][0].syncSelectedType
                    syncFilters = columnNameObjs.filterRefreshType;
                    var arrayNew1 = JSON.parse(columnNameObjs1)
                    var arrayNew = JSON.parse(arrayNew1.filterColumnNameObjs)
                }
                // if (checkedId == "incrementalrefresh") {
                //     checkedId = "alldata"
                // } else if (checkedId == "fullrefresh") {
                //     checkedId = "alldata"
                // }
            }

            if ($("#incrementalrefreshfield[data-id='" + conAccessId + "'][name='" + entity + "'] optgroup.incrementalrefreshfield_group option:checked")[0] != undefined) {
                iFields = $("#incrementalrefreshfield[data-id='" + conAccessId + "'][name='" + entity + "'] optgroup.incrementalrefreshfield_group option:checked")[0].value
            }
            if (entityModified && checkedId != undefined && checkedId != "loadmetadata" && $(".alldatacontainer[data-id='" + conAccessId + "'][name='" + entity + "']").find("input[type=radio]:checked").attr("id") != undefined &&
                $(".alldatacontainer[data-id='" + conAccessId + "'][name='" + entity + "']").find("input[type=radio]:checked").attr("id") == "incrementalrefresh" && (iFields == undefined || iFields == "")) {
                $("#synctablescontainer h4.panelboc-title[aria-controls='" + entity + "'][data-id='" + conAccessId + "']").addClass("error");
                $(".incrementalsync_error[name='" + entity + "'][data-id='" + conAccessId + "']").removeClass("hidden");
                $("#finalconnectionlistcontainer h4.panelboc-title[href='#" + $("#finalconnectionlistcontainer li.finalconnectionlist-item[source-id='" + conAccessId + "']").closest('.panelboc-content').attr("id") + "']").addClass("error");
                $("#finalconnectionlistcontainer li.finalconnectionlist-item[source-id='" + conAccessId + "']").addClass("error");
                isSyncValid = false;
                return
            } else {
                $("#synctablescontainer h4.panelboc-title[aria-controls='" + entity + "'][data-id='" + conAccessId + "']").removeClass("error");
                $(".incrementalsync_error[name='" + entity + "'][data-id='" + conAccessId + "']").addClass("hidden");
                // $("#finalconnectionlistcontainer h4.panelboc-title[href='#" + $("#finalconnectionlistcontainer li.finalconnectionlist-item[source-id='" + conAccessId + "']").closest('.panelboc-content').attr("id") + "']").removeClass("error");
                // $("#finalconnectionlistcontainer li.finalconnectionlist-item[source-id='" + conAccessId + "']").removeClass("error");
            }
            var sqlQuery = this.getQuery(conAccessId, entity);
            syncConfigData['query'] = sqlQuery;
            //if there are new entities while saving it will go it into the below block
            if (groupedEntityMetadataName[j] == undefined || entityModified == true) {
                if ($("#uniqueselect[data-id='" + conAccessId + "'][name='" + entity + "']").length > 0) {
                    var uniqueFeildValue = "";
                    _.each($(".uniquefieldcontainer[data-id='" + conAccessId + "'][name='" + entity + "']  #uniqueselect option:checked"), function (value, index) {
                        uniqueFeildValue += $(value).attr("columnname") + ",";
                    });
                    uniqueFeildValue = uniqueFeildValue.substring(0, uniqueFeildValue.length - 1);
                }
            } else {
                uniqueFeildValue = uniquecolumns;
            }
            if ($("#uniqueselect[data-id='" + conAccessId + "'][name='" + entity + "']").length > 0) {
                if (uniqueFeildValue!=null && uniqueFeildValue!=undefined && uniqueFeildValue.length == 0 && uniqueFeildValue == "" && isUniqueIdEnabled) {
                    $("#synctablescontainer h4.panelboc-title[aria-controls='" + entity + "'][data-id='" + conAccessId + "']").addClass("error");
                    $(".uniqueselect_error[name='" + entity + "'][data-id='" + conAccessId + "']").removeClass("hidden");

                    $("#finalconnectionlistcontainer h4.panelboc-title[href='#" + $("#finalconnectionlistcontainer li.finalconnectionlist-item[source-id='" + conAccessId + "']").closest('.panelboc-content').attr("id") + "']").addClass("error");
                    $("#finalconnectionlistcontainer li.finalconnectionlist-item[source-id='" + conAccessId + "']").addClass("error");

                    isSyncValid = false;
                    return
                }
            }
            syncConfigData['_idcolumn'] = uniqueFeildValue;
            //}
            syncConfigData['columns'] = that.getAllFeildsJson(conAccessId, entity);
            isSyncValid = that.validatesyncSchedule(syncConfigData, entity, conAccessId, isSyncValid, j, groupedEntityMetadataName, entityModified);
            if (!isSyncValid) {
                return
            }
           
            if (checkedId == 'alldata' || checkedId == "loadalldata") {
                syncConfigData["checkedId"] = "loadalldata";
                if (($(".alldatacontainer[data-id='" + conAccessId + "'][name='" + entity + "']").find("input[type=radio]:checked").attr("id") == "incrementalrefresh") && iFields != undefined) {
                    if (groupedEntityMetadataName[j] == undefined || entityModified == true) {
                        var str = "";
                        _.each($(".alldatacontainer[data-id='" + conAccessId + "'][name='" + entity + "'] .incrementalrefreshcontainer #incrementalrefreshfield option:checked"), function (value, index) {
                            str += $(value).attr("columnname") + ",";
                            syncDatagiven[k] = true
                        });
                        str = str.substring(0, str.length - 1);
                        console.log(str);
                    }
                    else {
                        str = incrementalFields;
                        syncDatagiven[k] = true
                    }

                    if (str.length == 0 && str == "") {

                        $("#synctablescontainer h4.panelboc-title[aria-controls='" + entity + "'][data-id='" + conAccessId + "']").addClass("error");
                        $(".incrementalsync_error[name='" + entity + "'][data-id='" + conAccessId + "']").removeClass("hidden");
                        $("#finalconnectionlistcontainer h4.panelboc-title[href='#" + $("#finalconnectionlistcontainer li.finalconnectionlist-item[source-id='" + conAccessId + "']").closest('.panelboc-content').attr("id") + "']").addClass("error");
                        $("#finalconnectionlistcontainer li.finalconnectionlist-item[source-id='" + conAccessId + "']").addClass("error");
                        isSyncValid = false;
                        return
                    }
                    //	$(".incrementalsync_error").addClass("hidden");
                    //	$("h4.panelboc-title.accordion-toggle").removeClass("error");
                    syncConfigData["columnNameObjs"] = str;
                    syncConfigData["refreshType"] = "incrementalrefresh";
                    syncDetails.push(syncConfigData);
                    existingsyncDetails[j] = syncConfigData;

                }
                else {
                    syncConfigData["refreshType"] = "fullrefresh";
                    syncDetails.push(syncConfigData);
                    existingsyncDetails[j] = syncConfigData;
                    syncDatagiven[k] = true
                }


                if (syncFilters == "selecteddata") {
                    var obj = []
                    if (groupedEntityMetadataName[j] == undefined || entityModified == true) {
                        _.each($(".selectdatacontainer[data-id='" + conAccessId + "'][name='" + entity + "'] .customsyncdatarangediv"), function (i, j) {
                            var array = {};
                            array["columnName"] = $(i).find("select option:selected").attr("columnname");
                            array["tableName"] = $(i).find("select option:selected").attr("tablename");
                            var daterange = $(i).find(".selectdatasyncdaterange").val();
                            array["daterange"] = daterange;
                            obj.push(array);
                            if (daterange != undefined && array["columnName"] != undefined) {
                                syncDatagiven[k] = true
                            }
                        });
                    }
                    else {
                        obj.push(arrayNew[0]);
                        syncDatagiven[k] = true
                    }
                    syncConfigData["filterColumnNameObjs"] = obj;
                    syncConfigData["filterRefreshType"] = "selecteddata";
                    syncDetails.push(syncConfigData);
                    existingsyncDetails[j] = syncConfigData;

                } else if (syncFilters == "selectednumericrange") {
                    var obj = []
                    if (groupedEntityMetadataName[j] == undefined || entityModified == true) {
                        _.each($(".numericdatacontainer[data-id='" + conAccessId + "'][name='" + entity + "'] .customsyncnumericrangediv"), function (i, j) {
                            var stopRequest = false;
                            var array = {};
                            array["columnName"] = $(i).find("select option:selected").attr("columnname");
                            array["tableName"] = $(i).find("select option:selected").attr("tablename");
                            array["daterange"] = $(i).find(".numericsyncrangefrom").val() + "/" + $(i).find(".numericsyncrangeto").val();
                            obj.push(array);
                            if ($(i).find(".numericsyncrangefrom").val().length == 0) {
                                $(i).find(".numericsyncrangefrom").addClass('bird-has-error');
                                $("#finalconnectionlistcontainer h4.panelboc-title[href='#" + $("#finalconnectionlistcontainer li.finalconnectionlist-item[source-id='" + conAccessId + "']").closest('.panelboc-content').attr("id") + "']").addClass("error");
                                $("#finalconnectionlistcontainer li.finalconnectionlist-item[source-id='" + conAccessId + "']").addClass("error");
                                $("#synctablescontainer h4.panelboc-title[aria-controls='" + $(i).attr("name") + "'][data-id='" + $(i).attr("data-id") + "']").addClass("error");
                                stopRequest = true;
                            } else {
                                $(i).find(".numericsyncrangefrom").removeClass('bird-has-error');
                                $("#finalconnectionlistcontainer h4.panelboc-title[href='#" + $("#finalconnectionlistcontainer li.finalconnectionlist-item[source-id='" + conAccessId + "']").closest('.panelboc-content').attr("id") + "']").removeClass("error");
                                $("#finalconnectionlistcontainer li.finalconnectionlist-item[source-id='" + conAccessId + "']").removeClass("error");
                                $("#synctablescontainer h4.panelboc-title[aria-controls='" + $(i).attr("name") + "'][data-id='" + $(i).attr("data-id") + "']").removeClass("error");
                            }
                            if ($(i).find(".numericsyncrangeto").val().length == 0) {
                                $(i).find(".numericsyncrangeto").addClass('bird-has-error');
                                $("#finalconnectionlistcontainer h4.panelboc-title[href='#" + $("#finalconnectionlistcontainer li.finalconnectionlist-item[source-id='" + conAccessId + "']").closest('.panelboc-content').attr("id") + "']").addClass("error");
                                $("#finalconnectionlistcontainer li.finalconnectionlist-item[source-id='" + conAccessId + "']").addClass("error");
                                $("#synctablescontainer h4.panelboc-title[aria-controls='" + $(i).attr("name") + "'][data-id='" + $(i).attr("data-id") + "']").addClass("error");
                                stopRequest = true;
                            } else {
                                $(i).find(".numericsyncrangeto").removeClass('bird-has-error');
                                $("#finalconnectionlistcontainer h4.panelboc-title[href='#" + $("#finalconnectionlistcontainer li.finalconnectionlist-item[source-id='" + conAccessId + "']").closest('.panelboc-content').attr("id") + "']").removeClass("error");
                                $("#finalconnectionlistcontainer li.finalconnectionlist-item[source-id='" + conAccessId + "']").removeClass("error");
                                $("#synctablescontainer h4.panelboc-title[aria-controls='" + $(i).attr("name") + "'][data-id='" + $(i).attr("data-id") + "']").removeClass("error");
                            }
                            syncDatagiven[k] = stopRequest ? false : true;
                        });
                    }
                    else {
                        obj.push(arrayNew[0]);
                        syncDatagiven[k] = true
                        $(i).find(".numericsyncrangefrom").removeClass('bird-has-error');
                        $("#finalconnectionlistcontainer h4.panelboc-title[href='#" + $("#finalconnectionlistcontainer li.finalconnectionlist-item[source-id='" + conAccessId + "']").closest('.panelboc-content').attr("id") + "']").removeClass("error");
                        $("#finalconnectionlistcontainer li.finalconnectionlist-item[source-id='" + conAccessId + "']").removeClass("error");
                        $("#synctablescontainer h4.panelboc-title[aria-controls='" + $(i).attr("name") + "'][data-id='" + $(i).attr("data-id") + "']").removeClass("error");
                        $(i).find(".numericsyncrangeto").removeClass('bird-has-error');
                        $("#finalconnectionlistcontainer h4.panelboc-title[href='#" + $("#finalconnectionlistcontainer li.finalconnectionlist-item[source-id='" + conAccessId + "']").closest('.panelboc-content').attr("id") + "']").removeClass("error");
                        $("#finalconnectionlistcontainer li.finalconnectionlist-item[source-id='" + conAccessId + "']").removeClass("error");
                        $("#synctablescontainer h4.panelboc-title[aria-controls='" + $(i).attr("name") + "'][data-id='" + $(i).attr("data-id") + "']").removeClass("error");
                    }
                    syncConfigData["filterColumnNameObjs"] = obj;
                    syncConfigData["filterRefreshType"] = "selectednumericrange";
                    syncDetails.push(syncConfigData);
                    existingsyncDetails[j] = syncConfigData;
                }
            } else if (checkedId == "loadmetadata") {
                syncConfigData["checkedId"] = "loadmetadata";
                syncConfigData["refreshType"] = "fullrefresh";
                syncConfigData["syncType"] = "0";
                syncConfigData["syncVal"] = "0";
                syncDetails.push(syncConfigData);
                existingsyncDetails[j] = syncConfigData;
                syncDatagiven[k] = true
            }
            k++
        });
        _.each(this.selectedStoredProcs.selectedStoredProcs, (i, j) => {
            var existingEntitiesMetaData = ChartInfo.functions.getExistingEntitiesMetaData();
            var groupedEntityMetadataName = _.groupBy(existingEntitiesMetaData, 'uniqueEntityName');
            var entityModified = false
            //if there any modified entities while saving 

            var syncConfigData = {};
            var data = j.split("&&&");
            var conAccessId = data[0];
            var entity = data[1];
            if ($("#conns[data-id='" + conAccessId + "'][title='" + entity + "']").hasClass("changed")) {
                entityModified = true
                $("#conns[data-id='" + conAccessId + "'][title='" + entity + "']").removeClass("changed")
            }
            if (entity != undefined) {
                syncConfigData['conAccessId'] = conAccessId;
                syncConfigData['conAccessDetails'] = i;
                syncConfigData['entity'] = entity;
                syncConfigData['type'] = "storedProc";
                syncConfigData['conAccessDetails'] = i;
                syncConfigData['columns'] = this.getAllFeildsJson(conAccessId, entity);
                var type = $(".alldatacontainer[data-id='" + conAccessId + "'][name='" + entity + "']").attr("data-name");
                isSyncValid = that.validatesyncSchedule(syncConfigData, entity, conAccessId, isSyncValid, j, groupedEntityMetadataName, entityModified);
                if (!isSyncValid) {
                    return
                }
                if ($(".alldatacontainer[data-id='" + conAccessId + "'][name='" + entity + "']").find("input[type=radio]:checked").attr("id") == "fullrefresh") {
                    syncConfigData["refreshType"] = "fullrefresh";
                    if (type == 'storedprocedure') {

                        var storedProcParams = sessionStorage.getItem(entity);
                        syncConfigData['type'] = 'storedprocedure';
                        syncConfigData['storedprocparams'] = storedProcParams;
                        syncDetails.push(syncConfigData);
                        syncDatagiven.push(true)
                        sessionStorage.removeItem(entity);
                    }
                }
            }
        });
        if (syncDatagiven.indexOf(false) < 0 && isSyncValid) {
            $(".spinner").show();
            var data = new FormData();
            data.append("syncConfig", JSON.stringify(syncDetails));
            data.append("isFromModel", false);
            data.append("selectedWSdata", null);
            SendRequest({
                url: "savedatahub",
                queryString: "",
                body: data,
                sucessFunction: (response) => {
                    if (response.hasOwnProperty('error')) {
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.errorTrace);
                        $('.spinner').hide();
                    } else {
                        $(".spinner").hide();
                        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Success');
                        $('.birdmessage-info').empty().text(getMessage('BirdSuccess64'));
                        sessionStorage.removeItem("customqueryEdited");
                        setTimeout(() => {
                            $(".spinner").hide();
                            this.props.history.push('/' + window.localStorage.getItem("appName") + '/welcome/businessworkspaces');
                            $("body").removeClass("datahubpage").addClass("datamainpage");
                        }, 2000);
                    }
                },
                rejectFunction: (error) => { },
                headers: {
                    method: "post",
                    headers: new Headers({
                        "Content-Type": "application/json"
                    })
                }
            })
        }
    }
    // loadsync = () => {
    //     // load previous sync if available
    //     _.each(this.tables, (feild) => {
    //         var numericAndDateFeilds = this.getNumericAndDateAttributes(feild.connaccessid, feild.name);
    //         _.each(numericAndDateFeilds, (numericfeild) => {
    //             $("#incrementalrefreshfield[data-id='" + feild.connaccessid + "'][name='" + feild.name + "'] .incrementalrefreshfield_group").append("<option columnname='" + numericfeild.name + "' value='" + numericfeild.name + "'>" + numericfeild.name + "</option>");
    //         });

    //         var allfeilds = this.getAllFeilds(feild.connaccessid, feild.name);
    //         _.each(allfeilds, (allfeild) => {
    //             $("#uniqueselect[data-id='" + feild.connaccessid + "'][name='" + feild.name + "'] .uniqueselectfield_group").append("<option columnname='" + allfeild.name + "' value='" + allfeild.name + "'>" + allfeild.name + "</option>");
    //         });
    //         $("#incrementalrefreshfield[data-id='" + feild.connaccessid + "'][name='" + feild.name + "']").chosen({ 'width': '100%' });
    //         $("#uniqueselect[data-id='" + feild.connaccessid + "'][name='" + feild.name + "']").chosen({ 'width': '100%' });
    //     });
    //     var existingEntitiesMetaData = ChartInfo.functions.getExistingEntitiesMetaData();
    //     _.each(existingEntitiesMetaData, (val, index) => {
    //         this.syncConfigData = val.syncSelectedType
    //         this.uniqueselectedcolumn = val.uniqueColumns
    //         var selectedDateFileds = [];
    //         var refreshType = "";
    //         if (this.syncConfigData != undefined && this.syncConfigData != "") {
    //             var syncdata = {};
    //             try {
    //                 syncdata = JSON.parse(this.syncConfigData);
    //             } catch (err) {
    //                 syncdata = this.syncConfigData;
    //             }

    //             refreshType = syncdata["refreshType"];
    //             //  this.updateSyncConfigDetails(val, syncdata, selectedDateFileds, refreshType);
    //         }
    //     })

    //     $(".spinner").hide();
    // }
    // changedatasyncconfigtype = (e) => {
    //     var $this = e.currentTarget;
    //     var activesynconfigtype = $($this).attr('id');
    //     if (activesynconfigtype === "alldata") {
    //         var numericAndDateFeilds = this.getNumericAndDateAttributes($($this).attr("data-id"), $($this).attr("data-name"));
    //         if ($(".incrementalrefreshcontainer li").length < 0) {
    //             _.each(numericAndDateFeilds, (feild) => {
    //                 $("#incrementalrefreshfield[data-id='" + $($this).attr("data-id") + "'][name='" + $($this).attr("data-name") + "'] .incrementalrefreshfield_group").append("<option columnname='" + feild.name + "' value='" + feild.name + "'>" + feild.name + "</option>");
    //             })
    //         }
    //         $("fieldset .alldatacontainer[data-id='" + $($this).attr("data-id") + "'][name='" + $($this).attr("data-name") + "']").show('slow');
    //         $("fieldset .selectdatacontainer, fieldset .numericdatacontainer[data-id='" + $($this).attr("data-id") + "'][name='" + $($this).attr("data-name") + "']").hide('slow');
    //         $("#incrementalrefreshfield[data-id='" + $($this).attr("data-id") + "'][name='" + $($this).attr("data-name") + "']").chosen('destroy');
    //         $("#incrementalrefreshfield[data-id='" + $($this).attr("data-id") + "'][name='" + $($this).attr("data-name") + "']").chosen({ 'width': '100%' });
    //     } else if (activesynconfigtype === "selecteddata") {
    //         var numericAndDateFeilds = this.getDateAttributes($($this).attr("data-id"), $($this).attr("data-name"));
    //         _.each(numericAndDateFeilds, (feild) => {
    //             $(".datefield[data-id='" + $($this).attr("data-id") + "'][name='" + $($this).attr("data-name") + "']").append("<option columnname='" + feild.name + "' value='" + feild.name + "'>" + feild.name + "</option>");
    //         })

    //         $("fieldset .alldatacontainer[data-id='" + $($this).attr("data-id") + "'][name='" + $($this).attr("data-name") + "'], fieldset .numericdatacontainer[data-id='" + $($this).attr("data-id") + "'][name='" + $($this).attr("data-name") + "']").hide('slow');
    //         $("fieldset .selectdatacontainer[data-id='" + $($this).attr("data-id") + "'][name='" + $($this).attr("data-name") + "']").show('slow');
    //         $(".selectdatasyncdaterange[data-id='" + $($this).attr("data-id") + "'][name='" + $($this).attr("data-name") + "']").daterangepicker({ "autoApply": true, locale: { format: "YYYY-MM-DD", "separator": '/' }, "parentEl": "#savestoryboard .filtercontent" });
    //     } else if (activesynconfigtype === "selectednumericrange") {
    //         var numericFeilds = this.getNumericAttributes($($this).attr("data-id"), $($this).attr("data-name"));
    //         _.each(numericFeilds, (feild) => {
    //             $(".numericfeild[data-id='" + $($this).attr("data-id") + "'][name='" + $($this).attr("data-name") + "']").append("<option columnname='" + feild.name + "' value='" + feild.name + "'>" + feild.name + "</option>");
    //         })
    //         $("fieldset .alldatacontainer[data-id='" + $($this).attr("data-id") + "'][name='" + $($this).attr("data-name") + "'], fieldset .selectdatacontainer[data-id='" + $($this).attr("data-id") + "'][name='" + $($this).attr("data-name") + "']").hide('slow');
    //         $("fieldset .numericdatacontainer[data-id='" + $($this).attr("data-id") + "'][name='" + $($this).attr("data-name") + "']").show('slow');
    //     }
    // }
    changedatasyncconfigtype = (e) => {
        var $this = e.currentTarget;
        var activesynconfigtype = $($this).attr('id');
        if (activesynconfigtype === "selecteddata") {
            $(".datasyncconfiguration#selectednumericrange[data-id='" + $($this).attr("data-id") + "'][data-name='" + $($this).attr("data-name") + "']").prop('checked', false);
            $(".datefield[data-id='" + $($this).attr("data-id") + "'][name='" + $($this).attr("data-name") + "']").empty();
            var numericAndDateFeilds = this.getDateAttributes($($this).attr("data-id"), $($this).attr("data-name"));
            _.each(numericAndDateFeilds, (feild) => {
                $(".datefield[data-id='" + $($this).attr("data-id") + "'][name='" + $($this).attr("data-name") + "']").append("<option columnname='" + feild.name + "' value='" + feild.name + "'>" + feild.name + "</option>");
            })

            $("fieldset .numericdatacontainer[data-id='" + $($this).attr("data-id") + "'][name='" + $($this).attr("data-name") + "']").hide('slow');
            $("fieldset .selectdatacontainer[data-id='" + $($this).attr("data-id") + "'][name='" + $($this).attr("data-name") + "']").show('slow');
            $(".selectdatasyncdaterange[data-id='" + $($this).attr("data-id") + "'][name='" + $($this).attr("data-name") + "']").daterangepicker({ "autoApply": true, locale: { format: "YYYY-MM-DD", "separator": '/' }, "parentEl": "#savestoryboard .filtercontent" });

            if (!$(".datasyncconfiguration#selecteddata[data-id='" + $($this).attr("data-id") + "'][data-name='" + $($this).attr("data-name") + "']").prop('checked')) {
                $("fieldset .alldatacontainer[data-id='" + $($this).attr("data-id") + "'][name='" + $($this).attr("data-name") + "']").show('slow');
               // $("fieldset .selectdatacontainer, fieldset .numericdatacontainer[data-id='" + $($this).attr("data-id") + "'][name='" + $($this).attr("data-name") + "']").hide('slow');
                $("#incrementalrefreshfield[data-id='" + $($this).attr("data-id") + "'][name='" + $($this).attr("data-name") + "']").chosen('destroy');
                $("#incrementalrefreshfield[data-id='" + $($this).attr("data-id") + "'][name='" + $($this).attr("data-name") + "']").chosen({ 'width': '100%' });
            }
        } else if (activesynconfigtype === "selectednumericrange") {
            $(".datasyncconfiguration#selecteddata[data-id='" + $($this).attr("data-id") + "'][data-name='" + $($this).attr("data-name") + "']").prop('checked', false);
            var numericFeilds = this.getNumericAttributes($($this).attr("data-id"), $($this).attr("data-name"));
            $(".numericfeild[data-id='" + $($this).attr("data-id") + "'][name='" + $($this).attr("data-name") + "']").empty();
            _.each(numericFeilds, (feild) => {
                $(".numericfeild[data-id='" + $($this).attr("data-id") + "'][name='" + $($this).attr("data-name") + "']").append("<option columnname='" + feild.name + "' value='" + feild.name + "'>" + feild.name + "</option>");
            })
            $("fieldset .selectdatacontainer[data-id='" + $($this).attr("data-id") + "'][name='" + $($this).attr("data-name") + "']").hide('slow');
            $("fieldset .numericdatacontainer[data-id='" + $($this).attr("data-id") + "'][name='" + $($this).attr("data-name") + "']").show('slow');

            if (!$(".datasyncconfiguration#selectednumericrange[data-id='" + $($this).attr("data-id") + "'][data-name='" + $($this).attr("data-name") + "']").prop('checked')) {
                $("fieldset .alldatacontainer[data-id='" + $($this).attr("data-id") + "'][name='" + $($this).attr("data-name") + "']").show('slow');
                $("fieldset .selectdatacontainer, fieldset .numericdatacontainer[data-id='" + $($this).attr("data-id") + "'][name='" + $($this).attr("data-name") + "']").hide('slow');
                $("#incrementalrefreshfield[data-id='" + $($this).attr("data-id") + "'][name='" + $($this).attr("data-name") + "']").chosen('destroy');
                $("#incrementalrefreshfield[data-id='" + $($this).attr("data-id") + "'][name='" + $($this).attr("data-name") + "']").chosen({ 'width': '100%' });
            }
        }
    }
    changedataloadconfigtype = (e) => {
        var $this = e.currentTarget;
        var activesynconfigtype = $($this).attr('id');
        if (activesynconfigtype === "loadmetadata") {
            $(".fa-red#loadmetadatanoteitem[data-id='" + $($this).attr("data-id") + "'][data-name='" + $($this).attr("data-name") + "']").removeClass('hide');
            $(".synfilterscselector[data-id='" + $($this).attr("data-id") + "'][data-name='" + $($this).attr("data-name") + "']").addClass('hide');
            $("fieldset .alldatacontainer[data-id='" + $($this).attr("data-id") + "'][name='" + $($this).attr("data-name") + "']").show('slow');
            $("fieldset .selectdatacontainer, fieldset .numericdatacontainer[data-id='" + $($this).attr("data-id") + "'][name='" + $($this).attr("data-name") + "']").hide('slow');
            $("#incrementalrefreshfield[data-id='" + $($this).attr("data-id") + "'][name='" + $($this).attr("data-name") + "']").chosen('destroy');
            $("#incrementalrefreshfield[data-id='" + $($this).attr("data-id") + "'][name='" + $($this).attr("data-name") + "']").chosen({ 'width': '100%' });
            $(".alldatacontainer[data-id='" + $($this).attr("data-id") + "'][name='" + $($this).attr("data-name") + "']").addClass('disabled');
            $(".datasyncschedulecontrollers[data-id='" + $($this).attr("data-id") + "'][data-name='" + $($this).attr("data-name") + "']").addClass('disabled');
        } else {
            $(".fa-red#loadmetadatanoteitem[data-id='" + $($this).attr("data-id") + "'][data-name='" + $($this).attr("data-name") + "']").addClass('hide');
            $(".datasyncconfiguration#selecteddata[data-id='" + $($this).attr("data-id") + "'][data-name='" + $($this).attr("data-name") + "']").prop('checked', false);
            $(".datasyncconfiguration#selectednumericrange[data-id='" + $($this).attr("data-id") + "'][data-name='" + $($this).attr("data-name") + "']").prop('checked', false);
            $(".synfilterscselector[data-id='" + $($this).attr("data-id") + "'][data-name='" + $($this).attr("data-name") + "']").removeClass('hide');
            $(".alldatacontainer[data-id='" + $($this).attr("data-id") + "'][name='" + $($this).attr("data-name") + "']").removeClass('disabled');
            $(".datasyncschedulecontrollers[data-id='" + $($this).attr("data-id") + "'][data-name='" + $($this).attr("data-name") + "']").removeClass('disabled');
        }
    }

    changeSyncType = (e) => {
        $('.syncerror').addClass("hide");
        var $this = e.currentTarget;
        var entity = $($this).attr('data-name');
        //			var name=entity.replace(/([-_;?&.,%#+*~\ '"!^$[\]()<=>`|\/@])/g, "");
        var name = entity
        var conAccessId = $($this).attr('data-id');
        var datasyncchecked = $(".datasynctype:checked[data-name='" + name + "'][data-id=" + conAccessId + "]").attr("id");
        if (datasyncchecked == "defaultcontainer") {
            $(".custom[data-name='" + name + "'][data-id=" + conAccessId + "]").addClass('hide').removeClass('show');
            $(".defaultcontainer[data-name='" + name + "'][data-id=" + conAccessId + "]").addClass('show').removeClass('hide');
        }
        else if (datasyncchecked == "custom") {
            $(".defaultcontainer[data-name='" + name + "'][data-id=" + conAccessId + "]").addClass('hide').removeClass('show');
            $(".custom[data-name='" + name + "'][data-id=" + conAccessId + "]").addClass('show').removeClass('hide');
            if ($(".minutes[data-id='" + conAccessId + "'][data-name='" + name + "']").find("input").attr('value') == "") {
                $(".minutes[data-id='" + conAccessId + "'][data-name='" + name + "']").find("input").attr("value", "30");
            }
        }
        else {
            $(".datasync-container[data-name='" + name + "'][data-id=" + conAccessId + "]").hide();
        }
    }
    changeSyncValue = (e) => {
        var $this = e.currentTarget;
        var entity = $($this).attr('data-name');
        //			var name=entity.replace(/([-_;?&.,%#+*~\ '"!^$[\]()<=>`|\/@])/g, "");
        var name = entity;
        var conAccessId = $($this).attr('data-id');
        $(".syncerror[data-name='" + name + "'][data-id=" + conAccessId + "]").addClass("hide");
    }
    changeSyncOption = (e) => {
        $('.syncerror').addClass("hide");
        var $this = e.currentTarget;
        var entity = $($this).attr('data-name');
        //			var name=entity.replace(/([-_;?&.,%#+*~\ '"!^$[\]()<=>`|\/@])/g, "");
        var name = entity
        var conAccessId = $($this).attr('data-id');
        $($this).find("option:selected").each(function () {
            if ($(this).attr("value") == "minutes") {
                $(".synccontainer[data-name='" + name + "'][data-id=" + conAccessId + "]").not(".minutes").hide();
                $(".minutes[data-name='" + name + "'][data-id=" + conAccessId + "]").removeClass('hide');
                $(".minutes[data-name='" + name + "'][data-id=" + conAccessId + "]").show();
                if ($(".minutes[data-id='" + conAccessId + "'][data-name='" + name + "']").find("input").attr('value') == "") {
                    $(".minutes[data-id='" + conAccessId + "'][data-name='" + name + "']").find("input").attr("value", "30");
                }
            } else if ($(this).attr("value") == "seconds") {
                $(".synccontainer[data-name='" + name + "'][data-id=" + conAccessId + "]").not(".seconds").hide();
                $(".seconds[data-name='" + name + "'][data-id=" + conAccessId + "]").removeClass('hide');
                $(".seconds[data-name='" + name + "'][data-id=" + conAccessId + "]").show();
                if ($(".seconds[data-id='" + conAccessId + "'][data-name='" + name + "']").find("input").attr('value') == "") {
                    $(".seconds[data-id='" + conAccessId + "'][data-name='" + name + "']").find("input").attr("value", "30");
                }
            } else if ($(this).attr("value") == "hours") {
                $(".synccontainer[data-name='" + name + "'][data-id=" + conAccessId + "]").not(".hours").hide();
                $(".hours[data-name='" + name + "'][data-id=" + conAccessId + "]").removeClass('hide');
                $(".hours[data-name='" + name + "'][data-id=" + conAccessId + "]").show();
                if ($(".hours[data-id='" + conAccessId + "'][data-name='" + name + "']").find("input").attr('value') == "") {
                    $(".hours[data-id='" + conAccessId + "'][data-name='" + name + "']").find("input").attr("value", "1");
                }
            }
            else if ($(this).attr("value") == "days") {
                $(".synccontainer[data-name='" + name + "'][data-id=" + conAccessId + "]").not(".days").hide();
                $(".days[data-name='" + name + "'][data-id=" + conAccessId + "]").removeClass('hide');
                $(".days[data-name='" + name + "'][data-id=" + conAccessId + "]").show();
                if ($(".days[data-id='" + conAccessId + "'][data-name='" + name + "']").find("#days").attr('value') == "") {
                    $(".days[data-id='" + conAccessId + "'][data-name='" + name + "']").find("#days").attr("value", "1");
                }
            }
            else {
                $(".synccontainer[data-name='" + name + "'][data-id=" + conAccessId + "]").hide();
            }
            $(".sync-day-time[data-name='" + name + "'][data-id=" + conAccessId + "]").clockpicker({
                "default": 'now',
                placement: 'top',
                align: 'left',
                autoclose: true,
            }).on('keyup', function (e) {
                //if the letter is not digit then display error and don't type anything
                $('.sync-day-time').clockpicker('hide');
                var timeaftersplitting;
                var hours;
                var minutes;
                var timeSplit = $(".sync-day-time[data-name='" + name + "'][data-id=" + conAccessId + "] input").val();
                var errorbox;
                errorbox = ".syncdaytimeerror[data-name='" + name + "'][data-id=" + conAccessId + "]";
                $(errorbox).html("").hide()
                var letters = /^[A-Za-z]+$/;
                if (letters.test(timeSplit) || letters.test(timeSplit.valueOf().charAt(1)) || letters.test(timeSplit.valueOf().charAt(4)) || letters.test(timeSplit.valueOf().charAt(0))) {
                    $(errorbox).html(lang["Datahub.sync.digitsonly"]).show().fadeOut(3000);
                    $(".sync-day-time[data-name='" + name + "'][data-id=" + conAccessId + "] input").val("");
                    return false;
                } else if ((timeSplit.valueOf().charAt(0) == ":" || timeSplit.valueOf().charAt(1) == ":" || timeSplit.valueOf().charAt(3) == ":" || timeSplit.valueOf().charAt(4) == ":") && timeSplit.length != 4) {
                    $(errorbox).html("").hide()
                    $(errorbox).html(lang["Datahub.sync.timeformat"]).show().fadeOut(3000);
                    $(".sync-day-time[data-name='" + name + "'][data-id=" + conAccessId + "] input").val("");
                    return false;
                } else if (timeSplit.match(/([#;?&,%.+*~\'"!^$%[\]()<=>|\/@])/g)) {
                    $(errorbox).html("").hide()
                    $(errorbox).html(lang["Datahub.sync.spclchars"]).show().fadeOut(3000);
                    $(".sync-day-time[data-name='" + name + "'][data-id=" + conAccessId + "] input").val("");
                    return false;
                } else if (timeSplit.match(/([-_{}])/g)) {
                    $(errorbox).html("").hide()
                    $(errorbox).html(lang["Datahub.sync.spclchars"]).show().fadeOut(3000);
                    $(".sync-day-time[data-name='" + name + "'][data-id=" + conAccessId + "] input").val("");
                    return false;
                } else if (timeSplit.indexOf(":") > -1 && timeSplit.indexOf(":") != 2) {
                    $(errorbox).html("").hide()
                    $(errorbox).html(lang["Datahub.sync.timeformat"]).show();
                    return false;
                } else if (timeSplit.length > 0) {
                    if ((timeSplit.length == 5 || timeSplit.length == 4) && timeSplit.indexOf(":") < 0) {
                        $(errorbox).html("").hide()
                        $(errorbox).html(lang["Datahub.sync.timeformat"]).show().fadeOut(3000);
                        $(".sync-day-time[data-name='" + name + "'][data-id=" + conAccessId + "] input").val("");
                        return false;
                    } else if (timeSplit.length == 1 && timeSplit <= 2) {
                        $(errorbox).html("").hide()
                        $(errorbox).html(lang["Datahub.sync.timeformat"]).show();
                        return false;
                    } else if (timeSplit.length == 1 && timeSplit > 2) {
                        timeSplit = 2;
                        $(".sync-day-time[data-name='" + name + "'][data-id=" + conAccessId + "] input").val(timeSplit);
                        $(errorbox).html("").hide()
                        $(errorbox).html(lang["Datahub.sync.maxhrshould"]).show();
                        return false;
                    } else if (timeSplit.length == 2 && timeSplit > 23) {
                        timeSplit = 23;
                        $(".sync-day-time[data-name='" + name + "'][data-id=" + conAccessId + "] input").val(timeSplit);
                        $(errorbox).html("").hide()
                        $(errorbox).html(lang["Datahub.sync.maxhrtimeformat"]).show();
                        return false;
                    } else if (timeSplit.length == 2 && timeSplit <= 23) {
                        $(".sync-day-time[data-name='" + name + "'][data-id=" + conAccessId + "] input").val(timeSplit);
                        $(errorbox).html("").hide()
                        $(errorbox).html(lang["Datahub.sync.timeformat"]).show();
                        return false;
                    } else if (timeSplit.length == 4) {
                        if (timeSplit.indexOf(":") == 2) {
                            timeaftersplitting = timeSplit.split(":");
                            hours = timeaftersplitting[0];
                            minutes = timeaftersplitting[1];
                            if (hours.length < 2) {
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["Datahub.sync.timeformat"]).show();
                                return false;
                            } else if (hours > 23) {
                                hours = 23;
                                timeSplit = hours + ":" + minutes;
                                $(".sync-day-time[data-name='" + name + "'][data-id=" + conAccessId + "] input").val(timeSplit);
                            } else if (minutes.length == 1 && minutes > 5) {
                                minutes = 5;
                                timeSplit = hours + ":" + minutes;
                                $(".sync-day-time[data-name='" + name + "'][data-id=" + conAccessId + "] input").val(timeSplit);
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["Datahub.sync.maxminshould"]).show();
                                return false;
                            } else if (minutes.length == 1 && minutes <= 5) {
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["Datahub.sync.timeformat"]).show();
                                return false;
                            } else if (minutes.length == 2 && minutes > 59) {
                                minutes = 59;
                                timeSplit = hours + ":" + minutes;;
                                $(".sync-day-time[data-name='" + name + "'][data-id=" + conAccessId + "] input").val(timeSplit);
                            } else if (letters.test(minutes.valueOf().charAt(0)) || letters.test(minutes.valueOf().charAt(1))) {
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["Datahub.sync.digitsonly"]).show();
                                $(".sync-day-time[data-name='" + name + "'][data-id=" + conAccessId + "] input").val("");
                                return false;
                            } else if (letters.test(hours.valueOf().charAt(0)) || letters.test(hours.valueOf().charAt(1))) {
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["Datahub.sync.digitsonly"]).show();
                                $(".sync-day-time[data-name='" + name + "'][data-id=" + conAccessId + "] input").val("");
                                return false;
                            } else if (minutes.length == 0) {
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["Datahub.sync.timeformat"]).show();
                                return false;
                            } else {
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["Datahub.sync.timeformat"]).show();
                                return false;
                            }

                        } else {
                            var timeSplit1 = timeSplit.valueOf().charAt(0) + timeSplit.valueOf().charAt(1);
                            var timeSplit2 = timeSplit.valueOf().charAt(3) + timeSplit.valueOf().charAt(4);
                            timeSplit = timeSplit1 + ":" + timeSplit2;
                            $(".sync-day-time[data-name='" + name + "'][data-id=" + conAccessId + "] input").val(timeSplit);
                            $(errorbox).html("").hide()
                            $(errorbox).html(lang["Datahub.sync.timeformat"]).show();
                            return false;
                        }
                    } else if (timeSplit.length == 5 && timeSplit.indexOf(":") == 2) {
                        timeaftersplitting = timeSplit.split(":");
                        hours = timeaftersplitting[0];
                        minutes = timeaftersplitting[1];
                        if (hours > 23) {
                            hours = 23;
                            timeSplit = hours + ":" + minutes;
                            $(".sync-day-time[data-name='" + name + "'][data-id=" + conAccessId + "] input").val(timeSplit);
                        } else if (minutes > 59) {
                            minutes = 59;
                            timeSplit = hours + ":" + minutes;
                            $(".sync-day-time[data-name='" + name + "'][data-id=" + conAccessId + "] input").val(timeSplit);
                        } else if (hours.match(/([#;?&,%. +*~\'"!^$%[\]()<=>|\/@])/g) || hours.match(/([-_{}])/g)) {
                            $(errorbox).html("").hide()
                            $(errorbox).html(lang["Datahub.sync.spclchars"]).show().fadeOut(3000);
                            $(".sync-day-time[data-name='" + name + "'][data-id=" + conAccessId + "] input").val("");
                            return false;
                        } else if (minutes.match(/([#;?&,%. +*~\'"!^$%[\]()<=>|\/@])/g) || minutes.match(/([-_{}])/g)) {
                            $(errorbox).html("").hide()
                            $(errorbox).html(lang["Datahub.sync.spclchars"]).show().fadeOut(3000);
                            $(".sync-day-time[data-name='" + name + "'][data-id=" + conAccessId + "] input").val("");
                            return false;
                        } else if (letters.test(minutes.valueOf().charAt(0)) || letters.test(minutes.valueOf().charAt(1))) {
                            $(errorbox).html("").hide()
                            $(errorbox).html(lang["Datahub.sync.digitsonly"]).show().fadeOut(3000);
                            $(".sync-day-time[data-name='" + name + "'][data-id=" + conAccessId + "] input").val("");
                            return false;
                        } else if (letters.test(hours.valueOf().charAt(0)) || letters.test(hours.valueOf().charAt(1))) {
                            $(errorbox).html("").hide()
                            $(errorbox).html(lang["Datahub.sync.digitsonly"]).show().fadeOut(3000);
                            $(".sync-day-time[data-name='" + name + "'][data-id=" + conAccessId + "] input").val("");
                            return false;
                        }
                    } else if (timeSplit.length == 3) {
                        if (timeSplit.indexOf(":") == 2) {
                            timeaftersplitting = timeSplit.split(":");
                            hours = timeaftersplitting[0];
                            minutes = timeaftersplitting[1];
                            if (hours > 23) {
                                hours = 23;
                                timeSplit = hours + ":" + minutes;
                                $(".sync-day-time[data-name='" + name + "'][data-id=" + conAccessId + "] input").val(timeSplit);
                            } else if (hours.length < 2) {
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["Datahub.sync.timeformat"]).show();
                                return false;
                            } else if (letters.test(hours.valueOf().charAt(0)) || letters.test(hours.valueOf().charAt(1))) {
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["Datahub.sync.digitsonly"]).show().fadeOut(3000);
                                $(".sync-day-time[data-name='" + name + "'][data-id=" + conAccessId + "] input").val("");
                                return false;
                            } else if (minutes.length < 2) {
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["Datahub.sync.timeformat"]).show();
                                return false;
                            } else if (hours.match(/([#;?&,%. +*~\'"!^$%[\]()<=>|\/@])/g) || hours.match(/([-_])/g)) {
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["Datahub.sync.spclchars"]).show().fadeOut(3000);
                                $(".sync-day-time[data-name='" + name + "'][data-id=" + conAccessId + "] input").val("");
                                return false;
                            } else if (minutes.match(/([#;?&,%. +*~\'"!^$%[\]()<=>|\/@])/g) || minutes.match(/([-_])/g)) {
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["Datahub.sync.spclchars"]).show().fadeOut(3000);
                                $(".sync-day-time[data-name='" + name + "'][data-id=" + conAccessId + "] input").val("");
                                return false;
                            } else if (letters.test(minutes.valueOf().charAt(0)) || letters.test(minutes.valueOf().charAt(1))) {
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["Datahub.sync.digitsonly"]).show().fadeOut(3000);
                                $(".sync-day-time[data-name='" + name + "'][data-id=" + conAccessId + "] input").val("");
                                return false;
                            }

                        } else if ((timeSplit.valueOf().charAt(0) + timeSplit.valueOf().charAt(1)) <= 23) {
                            var timeSplit1 = timeSplit.valueOf().charAt(0) + timeSplit.valueOf().charAt(1);
                            if (timeSplit.valueOf().charAt(2) > 5) {
                                var minute = 5;
                                timeSplit = timeSplit1 + ":" + minute;
                                $(".sync-day-time[data-name='" + name + "'][data-id=" + conAccessId + "] input").val(timeSplit);
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["Datahub.sync.timeformat"]).show();
                                return false;
                            } else {
                                timeSplit = timeSplit1 + ":" + timeSplit.valueOf().charAt(2);
                                $(".sync-day-time[data-name='" + name + "'][data-id=" + conAccessId + "] input").val(timeSplit);
                                $(errorbox).html("").hide()
                                $(errorbox).html(lang["Datahub.sync.timeformat"]).show();
                                return false;
                            }
                        } else {
                            $(errorbox).html("").hide()
                            $(errorbox).html(lang["Datahub.sync.timeformat"]).show();
                            return false;
                        }
                    } else {
                        if (timeSplit.length == 1) {
                            $(errorbox).html("").hide()
                            $(errorbox).html(lang["Datahub.sync.timeformat"]).show();
                            return false;
                        }
                    }
                }

            });
        });
    }
    addnewselecteddatesyncrange = (e) => {
        var $this = e.currentTarget;
        var connectionAccessId = $($this).attr("data-id");
        var tablename = $($this).attr("data-name");
        var currentdatano = $(".customsyncdatarangediv[data-id='" + connectionAccessId + "'][name='" + tablename + "']").attr('data-num')!=undefined ? $(".customsyncdatarangediv[data-id='" + connectionAccessId + "'][name='" + tablename + "']").attr('data-num'):"0";
        var newdatano = parseInt(currentdatano) + 1;
        if (currentdatano === undefined) {
            $(".append_customsyncdatarangediv[data-id='" + connectionAccessId + "'][data-name='" + tablename + "']").append(
                '<div class="control-group form-inline marg-top-10 customsyncdatarangediv" data-num="0" tab-id="0" tab-name="" data-id=' + connectionAccessId + ' name=' + tablename + '>' +
                '<div class="validationgroup form-group">' +
                '<label>'+lang["datahub.datecolumn"]+'&nbsp;</label>' +
                '<select class="form-control input-sm" data-id="0" name="" style="max-width:200px">' +
                '<optgroup class="selectdatafield_group">' +
                '</optgroup>' +
                '</select>&nbsp;' +
                '</div>' +
                '<div class="validationgroup form-group">' +
                '<label>'+lang["datahub.daterange"]+'&nbsp;</label>' +
                '<input type="text" class="selectdatasyncdaterange form-control input-sm" value="" data-id="0" data-cid=' + connectionAccessId + ' name=' + tablename + ' />' +
                '</div>' +
                '<button class="btn btn-transparent marg-righ-10 dropdown-toggle tooltip-right removenewselecteddatesyncrange" id="removenewselecteddatestoryboard" data-tooltip='+lang["datahub.remove"]+'>' +
                '<i class="fa fa-minus-circle"></i>' +
                '</button>' +
                '</div>'
            );
            // ReactDOM.render(
            //     <CustomSyncDateRangeHtml
            //         connectionAccessId={connectionAccessId}
            //         entityName={tablename}
            //         datecolumns={this.getDateAttributes(connectionAccessId, tablename)}
            //         id={0}
            //     />,
            //     document.querySelector(".append_customsyncdatarangediv[data-id='" + connectionAccessId + "'][data-name='" + tablename + "']")
            // );
            $('.selectdatasyncdaterange[data-id="0"][data-cid="' + connectionAccessId + '"][name="' + tablename + '"]').daterangepicker({ "autoApply": true, locale: { format: "YYYY-MM-DD", "separator": '/' }, "parentEl": "#synctablescontainer .filtercontent" });
        } else {
            $(".append_customsyncdatarangediv[data-id='" + connectionAccessId + "'][data-name='" + tablename + "']").append(
                '<div class="control-group form-inline marg-top-10 customsyncdatarangediv" data-num="' + newdatano + '" tab-id="0" tab-name="" data-id=' + connectionAccessId + ' name=' + tablename + '>' +
                '<div class="validationgroup form-group">' +
                '<label>'+lang["datahub.datecolumn"]+' &nbsp;</label>' +
                '<select class="form-control input-sm datefield" data-id="' + newdatano + '" name="' + tablename + '" style="max-width:200px">' +
                '</select>&nbsp;' +
                '</div>' +
                '<div class="validationgroup form-group">' +
                '<label>'+lang["datahub.daterange"]+'&nbsp;</label>' +
                '<input type="text" class="selectdatasyncdaterange form-control input-sm" value="" data-id=' + newdatano + ' data-cid=' + connectionAccessId + ' name=' + tablename + ' />' +
                '</div>' +
                '<button class="btn btn-transparent marg-righ-10 dropdown-toggle tooltip-right removenewselecteddatesyncrange" id="removenewselecteddatestoryboard" data-tooltip='+lang["datahub.remove"]+'>' +
                '<i class="fa fa-minus-circle"></i>' +
                '</button>' +
                '</div>'
            );
            // ReactDOM.render(
            //     <CustomSyncDateRangeHtml
            //         connectionAccessId={connectionAccessId}
            //         entityName={tablename}
            //         datecolumns={this.getDateAttributes(connectionAccessId, tablename)}
            //         id={newdatano}
            //     />,
            //     document.querySelector(".append_customsyncdatarangediv[data-id='" + connectionAccessId + "'][data-name='" + tablename + "']")
            // );
            $('.selectdatasyncdaterange[data-id="' + newdatano + '"][data-cid="' + connectionAccessId + '"][name="' + tablename + '"]').daterangepicker({ "autoApply": true, locale: { format: "YYYY-MM-DD", "separator": '/' }, "parentEl": "#synctablescontainer .filtercontent" });

            $(".datefield[data-id='" + newdatano + "'][name='" + tablename + "']").empty();
            var numericAndDateFeilds = this.getDateAttributes(connectionAccessId, tablename);
            _.each(numericAndDateFeilds, (feild) => {
                $(".datefield[data-id='" + newdatano + "'][name='" + tablename + "']").append("<option columnname='" + feild.name + "' value='" + feild.name + "'>" + feild.name + "</option>");
            })

        }
        $('.removenewselecteddatesyncrange').click((e) => {
            this.removenewselecteddatesyncrange(e);
        });
    }
    addnewnumericdatasyncrange = (e) => {
        var $this = e.currentTarget;
        var connectionAccessId = $($this).attr("data-id");
        var tablename = $($this).attr("data-name");
        var currentdatano = $(".customsyncnumericrangediv[data-id='" + connectionAccessId + "'][name='" + tablename + "']").last().attr('data-num')!=undefined ? $(".customsyncnumericrangediv[data-id='" + connectionAccessId + "'][name='" + tablename + "']").last().attr('data-num'):"0";
        var newdatano = parseInt(currentdatano) + 1;
        if (currentdatano === undefined) {
            $(".append_customnumericsyncdatarangediv[data-id='" + connectionAccessId + "'][data-name='" + tablename + "']").append(
                '<div class="control-group form-inline marg-top-10 customsyncnumericrangediv" data-num=' + newdatano + 'tab-id="0" tab-name="" data-id=' + connectionAccessId + ' name=' + tablename + '>' +
                '<div class="validationgroup form-group">' +
                '<label>'+lang["datahub.numericcolumn"]+'&nbsp;</label>' +
                '<select class="form-control input-sm" data-id=' + newdatano + ' name="" style="max-width: 90px;">' +
                '<optgroup class="selectdatafield_group">' +
                '</optgroup>' +
                '</select>&nbsp;' +
                '</div>' +
                '<div class="validationgroup form-group">' +
                '<label>'+lang["datahub.rangefm"]+'&nbsp;</label>' +
                '<input type="number" class="form-control input-sm numericsyncrangefrom" value="" data-id=' + newdatano + ' />' +
                '<label>'+lang["datahub.rangeto"]+'&nbsp;</label>' +
                '<input type="number" class="form-control input-sm numericsyncrangeto" value="" data-id=' + newdatano + ' />' +
                '</div>' +
                '<button class="btn btn-transparent marg-righ-10 dropdown-toggle tooltip-right removenewnumericsyncrange" id="removenewnumericstoryboard" data-tooltip='+lang["datahub.remove"]+'>' +
                '<i class="fa fa-minus-circle"></i>' +
                '</button>' +
                '</div>'
            )
            // ReactDOM.render(
            //     <CustomSyncNumericRangeHtml
            //         connectionAccessId={connectionAccessId}
            //         entityName={tablename}
            //         datecolumns={this.getNumericAttributes(connectionAccessId, tablename)}
            //         id={newdatano}
            //         from=""
            //         to=""
            //     />,
            //     document.querySelector(".append_customnumericsyncdatarangediv[data-id='" + connectionAccessId + "'][data-name='" + tablename + "']")
            // );
        } else {
            $(".append_customnumericsyncdatarangediv[data-id='" + connectionAccessId + "'][data-name='" + tablename + "']").append(
                '<div class="control-group form-inline marg-top-10 customsyncnumericrangediv" data-num="' + newdatano + '" tab-id="0" tab-name="" data-id=' + connectionAccessId + ' name=' + tablename + '>' +
                '<div class="validationgroup form-group">' +
                '<label>Numeric Column&nbsp;</label>' +
                '<select class="form-control input-sm numericfeild" data-id="' + newdatano + '" name="' + tablename + '" style="max-width: 90px;">' +
                '</select>&nbsp;' +
                '</div>' +
                '<div class="validationgroup form-group">' +
                '<label>'+lang["datahub.rangefm"]+'&nbsp;</label>' +
                '<input type="number" class="form-control input-sm numericsyncrangefrom" value="" data-id=' + newdatano + ' />' +
                '<label>'+lang["datahub.rangeto"]+'&nbsp;</label>' +
                '<input type="number" class="form-control input-sm numericsyncrangeto" value="" data-id=' + newdatano + ' />' +
                '</div>' +
                '<button class="btn btn-transparent marg-righ-10 dropdown-toggle tooltip-right removenewnumericsyncrange" id="removenewnumericstoryboard" data-tooltip="Remove">' +
                '<i class="fa fa-minus-circle"></i>' +
                '</button>' +
                '</div>'
            )
            // ReactDOM.render(
            //     <CustomSyncNumericRangeHtml
            //         connectionAccessId={connectionAccessId}
            //         entityName={tablename}
            //         datecolumns={this.getNumericAttributes(connectionAccessId, tablename)}
            //         id={newdatano}
            //         from=""
            //         to=""
            //     />,
            //     document.querySelector(".append_customnumericsyncdatarangediv[data-id='" + connectionAccessId + "'][data-name='" + tablename + "']")
            // );
        }
        var numericFeilds = this.getNumericAttributes(connectionAccessId, tablename);
        $(".customsyncnumericrangediv[data-num='"+newdatano+"'][data-id='"+ connectionAccessId+"'][name='"+tablename+"'] .numericfeild[data-id='" + newdatano + "'][name='" + tablename + "']").empty();
        _.each(numericFeilds, (feild) => {
            $(".customsyncnumericrangediv[data-num='"+newdatano+"'][data-id='"+ connectionAccessId+"'][name='"+tablename+"'] .numericfeild[data-id='" + newdatano + "'][name='" + tablename + "']").append("<option columnname='" + feild.name + "' value='" + feild.name + "'>" + feild.name + "</option>");
        })
        $('.removenewnumericsyncrange').click((e) => {
            this.removenewselecteddatesyncrange(e);
        })
    }
    removenewselecteddatesyncrange = (e) => {
        var $this = e.currentTarget;
        $($this).parent().remove();
        $(".selectdatasync_multiple_column_error").addClass("hidden");
        $(".numericdatasync_multiple_column_error").addClass("hidden");
    }
    updateSyncConfigDetails = (entitiesDetails, syncdata, selectedDateFileds, refreshType) => {
        var that = this;
        var datasyncdetails = entitiesDetails.datasynctype;
        //   var datasyncdetailsobj=JSON.parse(datasyncdetails);
        var tempentityname = entitiesDetails.entityname;
        if (tempentityname != undefined) {
            //				tempentityname=tempentityname.replace(/([-_;?&.,%#+*~\ '"!^$[\]()<=>`|\/@])/g, "");
        }
        if (syncdata != undefined && syncdata.checkedId != undefined) {
            if (syncdata.checkedId != "loadmetadata") {
                $(".fa-red#loadmetadatanoteitem[data-id='" + entitiesDetails.connAccessId + "'][data-name='" + tempentityname + "']").addClass('hide');
                $(".datasyncconfigtype#loadmetadata[data-name='" + tempentityname + "'][data-id='" + entitiesDetails.connAccessId + "']").removeAttr("checked");
                $(".datasyncconfigtype#loadalldata[data-name='" + tempentityname + "'][data-id='" + entitiesDetails.connAccessId + "']").prop('checked', true);
                $(".alldatacontainer[data-id='" + entitiesDetails.connAccessId + "'][name='" + tempentityname + "']").removeClass('disabled');
                $(".datasyncschedulecontrollers[data-id='" + entitiesDetails.connAccessId + "'][data-name='" + tempentityname + "']").removeClass('disabled');
                $(".synfilterscselector[data-id='" + entitiesDetails.connAccessId + "'][data-name='" + tempentityname + "']").removeClass('hide');
            } else {
                $(".datasyncconfigtype#loadmetadata[data-name='" + tempentityname + "'][data-id='" + entitiesDetails.connAccessId + "']").prop('checked', true);
            }
        }
        if (datasyncdetails != undefined && datasyncdetails != "0") {
            $(".syncdomelementhub[data-name='" + tempentityname + "'][data-id='" + entitiesDetails.connAccessId + "']").val(datasyncdetails).show();
        } else {
            $(".syncdomelementhub[data-name='" + tempentityname + "'][data-id='" + entitiesDetails.connAccessId + "']").val($(".syncdomelementhub[data-name='" + tempentityname + "'][data-id='" + entitiesDetails.connAccessId + "'] option:first").val()).show();
        }

        $(".sync-day-time[data-name='" + tempentityname + "'][data-id='" + entitiesDetails.connAccessId + "']").clockpicker({
            "default": 'now',
            placement: 'top',
            align: 'left',
            autoclose: true,
        });
        $("#removeduplicatedataid[data-id='" + entitiesDetails.connAccessId + "'][data-name='" + entitiesDetails.entityname + "']").addClass('disabled');
        $(".uniquefieldcontainer[data-id='" + entitiesDetails.connAccessId + "'][name='" + entitiesDetails.entityname + "']").addClass('disabled');
        $(".synfilterscselector[data-id='" + entitiesDetails.connAccessId + "'][data-name='" + entitiesDetails.entityname + "']").addClass('disabled');
        $(".datasyncconfigtype#loadmetadata[data-name='" + entitiesDetails.entityname + "'][data-id='" + entitiesDetails.connAccessId + "']").parent().addClass('disabled');
        $("#addnewselecteddatesyncrange[data-name='" + entitiesDetails.entityname + "'][data-id='" + entitiesDetails.connAccessId + "']").parent().addClass('disabled');
        $("#addnewnumericdatasyncrange[data-name='" + entitiesDetails.entityname + "'][data-id='" + entitiesDetails.connAccessId + "']").parent().addClass('disabled');


        if (entitiesDetails.uniqueColumns != null && entitiesDetails.uniqueColumns != 'null' && entitiesDetails.uniqueColumns != '') {
            var selectedUniqueColumns = entitiesDetails.uniqueColumns.split(",");
            _.each(selectedUniqueColumns, (selectedData, index) => {
                if ($("#uniqueselect[data-id='" + entitiesDetails.connAccessId + "'][name='" + entitiesDetails.entityname + "'] optgroup.uniqueselectfield_group option").length < selectedUniqueColumns.length) {
                    $("#uniqueselect[data-id='" + entitiesDetails.connAccessId + "'][name='" + entitiesDetails.entityname + "'] .uniqueselectfield_group").append("<option columnname='" + selectedData + "' value='" + selectedData + "'>" + selectedData + "</option>");
                }
                $("#uniqueselect[data-id='" + entitiesDetails.connAccessId + "'][name='" + entitiesDetails.entityname + "'] option[value='" + selectedData + "']").attr('selected', true)
                $("#uniqueselect[data-id='" + entitiesDetails.connAccessId + "'][name='" + entitiesDetails.entityname + "']").trigger("chosen:updated");
            });
        }
        var filterRefreshType = "";
        if (syncdata != undefined && syncdata.filterRefreshType != undefined) {
            filterRefreshType = syncdata.filterRefreshType;
        }
        if (filterRefreshType == "selecteddata") {
            if (this.syncConfigData != undefined) {
                var filtersyncdata = {};
                try {
                    filterSyncdata = JSON.parse(JSON.parse(this.syncConfigData)["filterColumnNameObjs"]);
                }
                catch (err) {
                    filterSyncdata = this.syncConfigData["filterColumnNameObjs"];
                }
                if (ChartInfo.functions.getDateRangeObj(-1) != undefined) {
                    filterSyncdata = ChartInfo.functions.getDateRangeObj(-1)["filterColumnNameObjs"];
                }
                $(".append_customsyncdatarangediv[data-name='" + entitiesDetails.entityname + "'][data-id='" + entitiesDetails.connAccessId + "']").empty();
                _.each(filterSyncdata, (value, index) => {
                    var today = new Date();
                    var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds() + index;
                    dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                    $(".append_customsyncdatarangediv[data-name='" + entitiesDetails.entityname + "'][data-id='" + entitiesDetails.connAccessId + "']").append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");

                    $("#synctablescontainer .datasyncconfigtype[id=selecteddata][data-name='" + entitiesDetails.entityname + "'][data-id='" + entitiesDetails.connAccessId + "']").click();
                    if ($(".append_customsyncdatarangediv[data-name='" + entitiesDetails.entityname + "'][data-id='" + entitiesDetails.connAccessId + "'] .customsyncdatarangediv").length < filterSyncdata.length) {
                        ReactDOM.render(
                            <CustomSyncDateRangeHtml
                                connectionAccessId={entitiesDetails.connAccessId}
                                entityName={entitiesDetails.entityname}
                                datecolumns={this.getDateAttributes(entitiesDetails.connAccessId, entitiesDetails.entityname)}
                                id={index}
                            />,
                            document.getElementById(dynamicdiv)
                        );
                    }
                    var dateRange = value.daterange.split("/");
                    $(".selectdatacontainer[data-id='" + entitiesDetails.connAccessId + "'][name='" + entitiesDetails.entityname + "']").daterangepicker({ "autoApply": true, startDate: dateRange[0], endDate: dateRange[1], locale: { format: "YYYY-MM-DD", "separator": '/' }, "parentEl": "#synctablescontainer .filtercontent" });
                    $(".customsyncdatarangediv[data-num='" + index + "'][data-id='" + entitiesDetails.connAccessId + "'][name='" + entitiesDetails.entityname + "']").find("select option[columnname='" + value.columnName + "']").prop('selected', true)
                    $(".selectdatasyncdaterange[data-id='" + index + "']").val(value.daterange);
                    $(".selectdatacontainer[data-id='" + entitiesDetails.connAccessId + "'][name='" + entitiesDetails.entityname + "']").show();
                });
                $(".datasyncconfiguration#selecteddata[data-id='" + entitiesDetails.connAccessId + "'][data-name='" + entitiesDetails.entityname + "']").prop('checked', true);
            }
        } else if (filterRefreshType == "selectednumericrange") {
            if (this.syncConfigData != undefined) {
                var filterSyncdata = {};
                try {
                    filterSyncdata = JSON.parse(JSON.parse(this.syncConfigData)["filterColumnNameObjs"]);
                } catch (err) {
                    filterSyncdata = this.syncConfigData["filterColumnNameObjs"];
                }
                if (ChartInfo.functions.getDateRangeObj(-1) != undefined) {
                    filterSyncdata = ChartInfo.functions.getDateRangeObj(-1)["filterColumnNameObjs"];
                }
                $(".append_customnumericsyncdatarangediv[data-name='" + entitiesDetails.entityname + "'][data-id='" + entitiesDetails.connAccessId + "']").empty();
                _.each(filterSyncdata, (value, index) => {
                    var today = new Date();
                var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                $(".append_customnumericsyncdatarangediv[data-name='" + entitiesDetails.entityname + "'][data-id='" + entitiesDetails.connAccessId + "']").append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
                    $("#synctablescontainer .datasyncconfigtype[id=selectednumericrange][data-name='" + entitiesDetails.entityname + "'][data-id='" + entitiesDetails.connAccessId + "']").click();
                    if ($(".append_customnumericsyncdatarangediv[data-name='" + entitiesDetails.entityname + "'][data-id='" + entitiesDetails.connAccessId + "'] .customsyncnumericrangediv").length < filterSyncdata.length) {
                        ReactDOM.render(
                            <CustomSyncNumericRangeHtml
                                connectionAccessId={entitiesDetails.connAccessId}
                                entityName={entitiesDetails.entityname}
                                datecolumns={this.getNumericAttributes(entitiesDetails.connAccessId, entitiesDetails.entityname)}
                                id={index}
                                from={value.daterange.split("/")[0]}
                                to={value.daterange.split("/")[1]}
                            />,
                            document.getElementById(dynamicdiv)
                        )
                    }
                    $(".customsyncnumericrangediv[data-num='" + index + "'][data-id='" + entitiesDetails.connAccessId + "'][name='" + entitiesDetails.entityname + "']").find("select option[columnname='" + value.columnName + "']").prop('selected', true)
                    $(".numericdatacontainer[data-id='" + entitiesDetails.connAccessId + "'][name='" + entitiesDetails.entityname + "']").show();
                });
                $(".datasyncconfiguration#selectednumericrange[data-id='" + entitiesDetails.connAccessId + "'][data-name='" + entitiesDetails.entityname + "']").prop('checked', true);
            }
        }
        if (refreshType != "") {
            $("input[name='datasyncrefreshtype" + entitiesDetails.connAccessId + entitiesDetails.entityname + "'][id='" + syncdata.refreshType + "'][class='datasyncconfiguration']").prop("checked", true);
            if (syncdata.refreshType == "incrementalrefresh") {
                if (syncdata["columnNameObjs"] != undefined) {
                    selectedDateFileds = syncdata["columnNameObjs"].split(",");
                }
                _.each(selectedDateFileds, function (selectedData, index) {
                    if ($("#incrementalrefreshfield[data-id='" + entitiesDetails.connAccessId + "'][name='" + entitiesDetails.entityname + "'] optgroup.incrementalrefreshfield_group option").length < selectedDateFileds.length) {
                        $("#incrementalrefreshfield[data-id='" + entitiesDetails.connAccessId + "'][name='" + entitiesDetails.entityname + "'] .incrementalrefreshfield_group").append("<option columnname='" + selectedData + "' value='" + selectedData + "'>" + selectedData + "</option>");
                    }
                    $("#incrementalrefreshfield[data-id='" + entitiesDetails.connAccessId + "'][name='" + entitiesDetails.entityname + "'] option[value='" + selectedData + "']").attr('selected', true)
                    $("#incrementalrefreshfield[data-id='" + entitiesDetails.connAccessId + "'][name='" + entitiesDetails.entityname + "']").trigger("chosen:updated");
                });

            }

        }
        $(".sync-day-time[data-name='" + tempentityname + "'][data-id=" + entitiesDetails.connAccessId + "]").clockpicker({
            "default": 'now',
            placement: 'top',
            align: 'left',
            autoclose: true,
        });
        if (entitiesDetails.isuniqueidenabled) {
            $(".checkbox-slider--c input[data-name='" + entitiesDetails.entityname + "'][data-id='" + entitiesDetails.connAccessId + "']").prop('checked', true);
            $(".uniquefieldcontainer[name='" + entitiesDetails.entityname + "'][data-id='" + entitiesDetails.connAccessId + "']").removeClass("hide");
        } else {
            $(".checkbox-slider--c input[data-name='" + entitiesDetails.entityname + "'][data-id='" + entitiesDetails.connAccessId + "']").prop('checked', false);
            $(".uniquefieldcontainer[name='" + entitiesDetails.entityname + "'][data-id='" + entitiesDetails.connAccessId + "']").addClass("hide");
        }
    }
    validatesyncSchedule = (syncConfigData, entity, conAccessId, isSyncValid, j, groupedEntityMetadataName, entityModified) => {
        //var name=entity.replace(/([-_;?&.,%#+*~\ '"!^$[\]()<=>`|\/@])/g, "");
        if ((groupedEntityMetadataName[j] != undefined) && entityModified == false) {
            var name = entity;
            var datasyncdetails = JSON.parse(groupedEntityMetadataName[j][0].datasynctype);
            var dataSyncType = "";
            if (datasyncdetails == null || datasyncdetails == undefined) {
                datasyncdetails = {};
            }
            dataSyncType = datasyncdetails.dataSyncType;
            var syncType;
            var syncVal;
            syncConfigData['dataSyncType'] = dataSyncType;
            var syncTime = datasyncdetails.synctime;
            if (dataSyncType == 'defaultcontainer') {
                syncType = "default";
                syncVal = datasyncdetails.syncVal
                // syncConfigData['syncType'] = syncType;
                // syncConfigData['syncVal'] = syncVal;
            } else {
                syncType = datasyncdetails.syncType;
                syncVal = datasyncdetails.syncVal;
                // syncConfigData['syncType'] = syncType;
                // syncConfigData['syncVal'] = syncVal;
            }
        }
        else {
            var name = entity;
            var dataSyncType = $(".datasynctype:checked[data-name='" + name + "'][data-id=" + conAccessId + "]").attr("id");
            var syncType;
            var syncVal;
            syncConfigData['dataSyncType'] = dataSyncType;
            var syncTime = $("#sync-day-time-start[data-name='" + name + "'][data-id=" + conAccessId + "]").val();
            if (dataSyncType == 'defaultcontainer') {
                syncType = "default";
                syncVal = $(".defaultcontainer[data-name='" + name + "'][data-id=" + conAccessId + "] input").val();
                // syncConfigData['syncType'] = syncType;
                // syncConfigData['syncVal'] = syncVal;
            } else {
                syncType = $(".syncreport[data-name='" + name + "'][data-id=" + conAccessId + "]").find("option:selected").val();
                syncVal = $("." + syncType + "[data-name='" + name + "'][data-id=" + conAccessId + "]").find("input").val();
                // syncConfigData['syncType'] = syncType;
                // syncConfigData['syncVal'] = syncVal;
            }
        }
        if (syncType == 'days' && syncVal == "") {
            $(".syncerrordays[data-name='" + name + "'][data-id=" + conAccessId + "]").text(lang["Datahub.inputdata"]);
            $(".syncerrordays[data-name='" + name + "'][data-id=" + conAccessId + "]").removeClass("hide");
            isSyncValid = false;
            return isSyncValid
        } else {
            if (syncType == "") {
                syncType = "default";
             //   syncConfigData['syncType'] = syncType;
            }
        }
        if (syncType == 'minutes') {
            if (syncVal.length == "") {
                $(".syncerror[data-name='" + name + "'][data-id=" + conAccessId + "]").text(lang["Datahub.enternumber"]);
                $(".syncerror[data-name='" + name + "'][data-id=" + conAccessId + "]").removeClass("hide");
                isSyncValid = false;
                return isSyncValid
            }
            else if (syncVal < 1) {
                $(".syncerror[data-name='" + name + "'][data-id=" + conAccessId + "]").text(lang["Datahub.minminutes"]);
                $(".syncerror[data-name='" + name + "'][data-id=" + conAccessId + "]").removeClass("hide");
                isSyncValid = false;
                return isSyncValid
            } else if (syncVal > 59) {
                $(".syncerror[data-name='" + name + "'][data-id=" + conAccessId + "]").text(lang["Datahub.maxminutes"] );
                $(".syncerror[data-name='" + name + "'][data-id=" + conAccessId + "]").removeClass("hide");
                isSyncValid = false;
                return isSyncValid
            }
            else if (syncVal.indexOf(".") != -1) {
                $(".syncerror[data-name='" + name + "'][data-id=" + conAccessId + "]").text(lang["Datahub.decimalval"]);
                $(".syncerror[data-name='" + name + "'][data-id=" + conAccessId + "]").removeClass("hide");
                isSyncValid = false;
                return isSyncValid
            }
            else {
                $(".syncerror[data-name='" + name + "'][data-id=" + conAccessId + "]").addClass("hide");
            }
        } else if (syncType == 'hours') {
            if (syncVal.length == "") {
                $(".syncerror[data-name='" + name + "'][data-id=" + conAccessId + "]").text(lang["Datahub.enternumber"]);
                $(".syncerror[data-name='" + name + "'][data-id=" + conAccessId + "]").removeClass("hide");
                isSyncValid = false;
                return isSyncValid
            }
            else if (syncVal < 1) {
                $(".syncerror[data-name='" + name + "'][data-id=" + conAccessId + "]").text(lang["Datahub.minhr"]);
                $(".syncerror[data-name='" + name + "'][data-id=" + conAccessId + "]").removeClass("hide");
                isSyncValid = false;
                return isSyncValid
            } else if (syncVal > 23) {
                $(".syncerror[data-name='" + name + "'][data-id=" + conAccessId + "]").text(lang["Datahub.maxhr"]);
                $(".syncerror[data-name='" + name + "'][data-id=" + conAccessId + "]").removeClass("hide");
                isSyncValid = false;
                return isSyncValid
            }
            else if (syncVal.indexOf(".") != -1) {
                $(".syncerror[data-name='" + name + "'][data-id=" + conAccessId + "]").text(lang["Datahub.decimalval"]);
                $(".syncerror[data-name='" + name + "'][data-id=" + conAccessId + "]").removeClass("hide");
                isSyncValid = false;
                return isSyncValid
            }
            else {
                $(".syncerror[data-name='" + name + "'][data-id=" + conAccessId + "]").addClass("hide");
            }
        } else if (syncType == 'days') {
            if (syncVal.length == "") {
                $(".syncerrordays[data-name='" + name + "'][data-id=" + conAccessId + "]").text(lang["Datahub.enternumber"]);
                $(".syncerrordays[data-name='" + name + "'][data-id=" + conAccessId + "]").removeClass("hide");
                isSyncValid = false;
                return isSyncValid
            }
            else if (syncVal < 1) {
                $(".syncerrordays[data-name='" + name + "'][data-id=" + conAccessId + "]").text(lang["Datahub.minday"]);
                $(".syncerrordays[data-name='" + name + "'][data-id=" + conAccessId + "]").removeClass("hide");
                isSyncValid = false;
                return isSyncValid
            } else if (syncVal > 31) {
                $(".syncerrordays[data-name='" + name + "'][data-id=" + conAccessId + "]").text(lang["Datahub.maxday"]);
                $(".syncerrordays[data-name='" + name + "'][data-id=" + conAccessId + "]").removeClass("hide");
                isSyncValid = false;
                return isSyncValid
            }
            else if (syncVal.indexOf(".") != -1) {
                $(".syncerrordays[data-name='" + name + "'][data-id=" + conAccessId + "]").text(lang["Datahub.decimalval"]);
                $(".syncerrordays[data-name='" + name + "'][data-id=" + conAccessId + "]").removeClass("hide");
                isSyncValid = false;
                return isSyncValid
            }
            else {
                if ((groupedEntityMetadataName[j] != undefined) && entityModified == false) {
                    syncConfigData['syncTime'] = syncTime;
                }
                else {
                    var timeSplit = $(".sync-day-time[data-name='" + name + "'][data-id=" + conAccessId + "] input").val();
                    if (timeSplit == undefined || timeSplit.length <= 0) {
                        timeSplit = "00:00";
                    }
                    var errormsg = $("#errormsg")["0"].innerHTML.length;
                    if (errormsg > 0) {
                        return false;
                    }
                    syncTime = timeSplit;
                    syncConfigData['syncTime'] = syncTime;
                }
                $(".syncerrordays[data-name='" + name + "'][data-id=" + conAccessId + "]").addClass("hide");
            }
        } else if (syncType == 'seconds') {
            if (syncVal.length == "") {
                $(".syncerror[data-name='" + name + "'][data-id=" + conAccessId + "]").text(lang["Datahub.enternumber"]);
                $(".syncerror[data-name='" + name + "'][data-id=" + conAccessId + "]").removeClass("hide");
                isSyncValid = false;
                return isSyncValid
            }
            else if (syncVal < 5) {
                $(".syncerror[data-name='" + name + "'][data-id=" + conAccessId + "]").text(lang["Datahub.minsec"]);
                $(".syncerror[data-name='" + name + "'][data-id=" + conAccessId + "]").removeClass("hide");
                isSyncValid = false;
                return isSyncValid
            } else if (syncVal > 59) {
                $(".syncerror[data-name='" + name + "'][data-id=" + conAccessId + "]").text(lang["Datahub.maxsec"]);
                $(".syncerror[data-name='" + name + "'][data-id=" + conAccessId + "]").removeClass("hide");
                isSyncValid = false;
                return isSyncValid
            }
            else if (syncVal.indexOf(".") != -1) {
                $(".syncerror[data-name='" + name + "'][data-id=" + conAccessId + "]").text(lang["Datahub.decimalval"] );
                $(".syncerror[data-name='" + name + "'][data-id=" + conAccessId + "]").removeClass("hide");
                isSyncValid = false;
                return isSyncValid
            }
            else {
                $(".syncerror[data-name='" + name + "'][data-id=" + conAccessId + "]").addClass("hide");
            }
        }
        return isSyncValid
    }
    createDataHubEntitiesObject = (response) => {
        if (response.success == false || response.hasOwnProperty("error")) {
            if (response.status != "fail") {
                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('hide');
                $('.birdmessage h2').empty().append('Error : ');
                $('.birdmessage h2').append('<span class="errorcode">');
                $('.birdmessage .errorcode').empty().append(response.errorCode);
                $('.birdmessage-info').empty().text(lang["Datahub.unablefetch"]);
                $('.details').addClass('show');
                if (response.success == false || response.hasOwnProperty("error")) {
                    $('.messagedetails xmp').empty().append(response.errorTrace);
                }
                else {
                    $('.messagedetails xmp').empty().append(response.error);
                }
            }
            setTimeout(function () {
                $("#datahubstepscontainer").steps("previous");
                //	$('.spinner').hide();
            }, 1000);
            //$('.spinner').hide();			
            if ($("#datahubentitycontainer li").length > 0) {
                var emptyNameCount = 0;
                $('.spinner').show();
                $("#datahubentitycontainer>li").removeClass('warning');
                _.each($("#datahubentitycontainer li"), function (ent, index) {
                    var entity = $(ent).find("input.scopeitem");
                    var entityid = $(entity).attr("data_parent");
                    var entityname = $(entity).attr("data-value")
                    if (entityname.includes('.')) {
                        entityname = $(entity).attr("data-value").split(".")[1];
                    }
                    if (entityname == "Twitter") {
                        entityname = "twitter";
                    }
                    var errorTablename = response.tablename.replace(/[`"]/g, '');
                    if (entityname == errorTablename && entityid == response.connId) {
                        setTimeout(function () {
                            $(ent).removeClass("active");
                            $(ent).addClass("warning");
                            emptyNameCount++;
                        }, 2000);
                    }
                });
                setTimeout(function () {
                    if (emptyNameCount > 0) {
                        $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-infor').addClass('birdmessage-warning');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-info-circle').addClass('fa-exclamation-triangle');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Warning');
                        $("#datahubstepscontainer").steps("previous");
                        if (response.error == "No Data") {
                            $('.birdmessage-info').empty().text(lang["Datahub.nodataentity"]);
                        }
                        else {
                            $('.birdmessage-info').empty().text("The Column Name '" + response.columnName + "'" + "" + getMessage('BirdWarning149'));
                        }
                        $('.spinner').hide();
                        return;
                    }
                }, 2000);
            }
            return
        }
        this.datahubdetails = {};
        this.tables = [];
        var k = 0;
        _.each(response, (i, j) => {
            var data = JSON.parse(i);
            if (this.datahubentities !== undefined && Object.keys(this.datahubentities).length > 0 && this.datahubentities[j] !== undefined && this.datahubentities[j].length > 0) {
                data.entities = this.datahubentities[j];
            }
            this.reportcolumns = this.props.getUpdatedreportcolumns(j);
            if (this.reportcolumns !== undefined && Object.keys(this.reportcolumns).length > 0) {
                data.entities = this.reportcolumns;
            }
            this.datahubdetails[j] = data;
            var key = j.split("&&&");
            var connAccessId = key[0];
            var entity = key[1];
            var connectionEntity = {};
            connectionEntity['connaccessid'] = connAccessId;
            connectionEntity['name'] = entity;
            var dupname = entity.replace(/([-_;?&.,%#+*~\ '"!^$[\]()<=>`|\/@])/g, "");
            k++;
            dupname = dupname + k + new Date().getTime();
            connectionEntity['dupname'] = dupname;
            /*	if($(".viewreport-list-item.datahub-customquery-item[data_parent='"+connAccessId+"']").hasClass('query-exists')){
                    $(".viewreport-list-item.datahub-customquery-item").closest("li.query-exists").addClass('error');
                    $("#datahubstepscontainer").steps("previous");
                    $('.spinner').hide();
                }*/
            if (data.dbType != null && data.dbType != undefined) {
                connectionEntity['type'] = data.dbType;
            }

            this.tables.push(connectionEntity);
            window.localStorage.setItem("hubtables", this.tables)
        });
    }
    setSavedEntities = (tablesResponse) => {
        var m = 0;
        _.each(tablesResponse, (i, j) => {
            var data = JSON.parse(i);
            var key = j.split("&&&");
            var connAccessId = key[0];
            var entity = key[1];
            if (this.datahubentities != undefined && Object.keys(this.datahubentities).length > 0 && this.datahubentities[j] != undefined && this.datahubentities[j].length > 0) {
                data.entities = this.datahubentities[j];
            }
            var existingEntities = ChartInfo.functions.getExistingEntitiesMetaData();
            if (existingEntities != undefined && sessionStorage.getItem("customqueryEdited") != undefined && sessionStorage.getItem("customqueryEdited") != null) {
                var csqEdited = sessionStorage.getItem("customqueryEdited").split(",");
                if (csqEdited.indexOf(entity) != -1) {
                    var groupedConnections3 = _.groupBy(existingEntities, 'entityname');
                    if (groupedConnections3[entity] != undefined) {
                        _.each(groupedConnections3[entity], function (k, l) {
                            if (connAccessId == k.connAccessId && entity == k.entityname) {
                                data.sqlquery = k.actualquery;
                                data.entities = JSON.parse(k.entityColumns);
                            }
                        });
                    }
                }
            }

            this.datahubdetails[j] = data;

            var connectionEntity = {};
            connectionEntity['connaccessid'] = connAccessId;
            connectionEntity['name'] = entity;
            var dupname = entity.replace(/([-_;?&.,%#+*~\ '"!^$[\]()<=>`|\/@])/g, "");
            m++;
            dupname = dupname + m + new Date().getTime();
            connectionEntity['dupname'] = dupname;
            if (data.dbType != null && data.dbType != undefined) {
                connectionEntity['type'] = data.dbType;
            }
            if (data.dataPushStatus != null && data.dataPushStatus != undefined) {
                connectionEntity['dataPushStatus'] = data.dataPushStatus;
            }
            if (data.datasynctype != undefined && data.datasynctype != null) {
                if (this.syncConfigs != undefined && this.syncConfigs != null) {
                    this.syncConfigs.map(function (sync, i) {
                        if(sync.sync_id != undefined && sync.sync_id ==data.datasynctype){
                            connectionEntity['syncStatus'] = sync.status;
                        }
                         if(data.datasynctype=="0"){
                            connectionEntity['syncStatus'] = "Inactive";
                        }
                    })
                }
            }
            this.tables.push(connectionEntity);
        });
    }
    loadSyncPage = () => {
        if (this.state.response != undefined && Object.keys(this.state.response).length > 0) {
            this.createDataHubEntitiesObject(this.state.response);
        }
        if (this.savedEntities != undefined && Object.keys(this.savedEntities).length > 0) {
            this.setSavedEntities(this.savedEntities);
        }
        if (this.state.response == undefined || this.state.response.success != false || !this.state.response.hasOwnProperty("error")) {
            this.otherEntities = [];
            this.ExistingTablesyncArray = [];
            var stit = 0, ffit = 0;
            if (this.selectedStoredProcs != undefined) {
                _.each(this.selectedStoredProcs, (i, j) => {
                    var details = j.split("&&&");
                    var connId = details[0];
                    var name = details[1];
                    if (name != undefined) {
                        var otherEntity = {};
                        otherEntity['connaccessid'] = connId;
                        otherEntity['name'] = name;
                        var dupname = name.replace(/([-_;?&.,%#+*~\ '"!^$[\]()<=>`|\/@])/g, "");
                        stit++;
                        dupname = dupname + stit + new Date().getTime();
                        otherEntity['dupname'] = dupname
                        otherEntity['type'] = "storedprocedure";
                        this.otherEntities.push(otherEntity);
                    }
                });
            }
            if (this.flatFileEntities != undefined) {
                _.each(this.flatFileEntities, (i, j) => {
                    var details = j.split("&&&");
                    var connId = details[0];
                    var name = details[1];
                    if (name != undefined) {
                        var otherEntity = {};
                        otherEntity['connaccessid'] = connId;
                        otherEntity['name'] = name;
                        var dupname = name.replace(/([-_;?&.,%#+*~\ '"!^$[\]()<=>`|\/@])/g, "");
                        ffit++
                        dupname = dupname + ffit + new Date().getTime();
                        otherEntity['dupname'] = dupname;
                        otherEntity['type'] = "F";
                        this.otherEntities.push(otherEntity);
                    }
                });
            }
            if (this.tables != undefined) {
                _.each(this.tables, (ent, j) => {
                    if (ent.type != undefined && ent.type != null) {
                        ent.type = ent.type.toLowerCase()
                    }
                    if (this.flatFileEntities != undefined) {
                        _.each(this.flatFileEntities, (fent, k) => {
                            var details = k.split("&&&");
                            var connId = details[0];
                            if (ent.connaccessid == connId) {
                                ent.type = "F";
                            }
                        });
                    }
                    if (ent.type != undefined && (ent.type == 'birdinmemory' || ent.type == 'rabbitmq' || ent.type == 'websocket' || ent.type == 'kafka' || ent.type == 'eventhub')) {
                        this.otherEntities.push(ent);
                    }
                });
            }

            //Unique connections

            var finalEntitiesSources = this.tables;

            var uniqueSourcesList = _.chain(finalEntitiesSources).map(function (item) { return item.connaccessid }).uniq().value();
            this.finalEntitiesSourcesFullList = [];
            var finalEntitiesSourcesFullList = this.finalEntitiesSourcesFullList;

            _.each(uniqueSourcesList, function (sourceid) {
                var list = { sourceID: sourceid }
                var sourcetype = $("select#existing-connections-list option[value='" + sourceid + "']").attr("data-connectionname"),
                    sourcename = $("select#existing-connections-list option[value='" + sourceid + "']").attr("label");
                list.sourceType = sourcetype
                list.sourceName = sourcename;
                finalEntitiesSourcesFullList.push(list);

            });
            this.groupedConnections = _.groupBy(finalEntitiesSourcesFullList, 'sourceType');

            setTimeout(() => {
                //this.loadsync();
                this.componentDidMount();
                this.showSelectedConnectionEntities();
                this.syncUniqueIdConfig();
                this.streamselectConfig();
            }, 2000);
        }
        if (this.otherEntities != undefined) {
            ReactDOM.unmountComponentAtNode(document.getElementById("datahubsyncconfigcontainer"));
            return (
                ReactDOM.render(<DatahubSyncConfigHtml
                    entities={this.tables}
                    syncConfigs={this.syncConfigs}
                    otherentities={this.otherEntities}
                    groupedConnections={this.groupedConnections}
                    showSelectedEntities={this.showSelectedEntities}
                    finalEntitiesSourcesFullList={this.finalEntitiesSourcesFullList}
                    changeSyncOption={this.changeSyncOption}
                    changedatasyncconfigtype={this.changedatasyncconfigtype}
                    changedataloadconfigtype={this.changedataloadconfigtype}
                    changeSyncType={this.changeSyncType}
                    changeSyncValue={this.changeSyncValue}
                    addnewselecteddatesyncrange={this.addnewselecteddatesyncrange}
                    removenewselecteddatesyncrange={this.removenewselecteddatesyncrange}
                    addnewnumericdatasyncrange={this.addnewnumericdatasyncrange}
                    removenewnumericsyncrange={this.removenewselecteddatesyncrange}
                    showSelected={this.showSelected}
                    changeSyncConfig={this.changeSyncConfig}
                    syncFinalConnectionsSearch={this.syncFinalConnectionsSearch}
                    syncFinalEntitySearch={this.syncFinalEntitySearch}
                    counti={this.counti}
                    datasyncduplicatefields={this.datasyncduplicatefields}
                    streamselectConfig={this.streamselectConfig}
                    streamingstatus={this.streamingstatus}

                />, document.getElementById('datahubsyncconfigcontainer')
                )
            );
        }
    }
    datasyncduplicatefields = (e) => {
        var $this = e.currentTarget;
        var selectedid = $($this).attr("data-id");
        var selectname = $($this).attr("data-name");
        var isChecked = $($this).is(':checked');
        if (!isChecked) {
            $(".uniquefieldcontainer[name='" + selectname + "'][data-id='" + selectedid + "']").addClass("hide");
            $(".checkbox-slider--c input[data-name='" + selectname + "'][data-id='" + selectedid + "']").prop('checked', false);

        } else {
            $(".uniquefieldcontainer[name='" + selectname + "'][data-id='" + selectedid + "']").removeClass("hide");
            $(".checkbox-slider--c input[data-name='" + selectname + "'][data-id='" + selectedid + "']").prop('checked', true);
        }

    }
    syncUniqueIdConfig = () => {
        //For Appending Remove duplicate fields State/ on or off
        var existingEntitiesMetaData = ChartInfo.functions.getExistingEntitiesMetaData();
        _.each(existingEntitiesMetaData, function (val, index) {
            if (val.isuniqueidenabled) {
                $(".checkbox-slider--c input[data-name='" + val.entityname + "'][data-id='" + val.connAccessId + "']").prop('checked', true);
                $(".uniquefieldcontainer[name='" + val.entityname + "'][data-id='" + val.connAccessId + "']").removeClass("hide");
            } else {
                $(".checkbox-slider--c input[data-name='" + val.entityname + "'][data-id='" + val.connAccessId + "']").prop('checked', false);
                $(".uniquefieldcontainer[name='" + val.entityname + "'][data-id='" + val.connAccessId + "']").addClass("hide");
            }
        })
    }
    streamingstatus = (e) => {
        var $this = e.currentTarget;
        var that = this;
        var entityname = $($this).attr("data-name");
        var connId = $($this).attr("data-id")
        var savedentitiesarray = _.isEmpty(this.props.savedEntities) ? [] : Object.keys(this.props.savedEntities)
        if (savedentitiesarray.indexOf(connId + "&&&" + entityname) == -1 || savedentitiesarray.length == 0) {
            $($this).prop('checked', true)
        }
    }
    streamselectConfig = () => {
        var existingEntitiesMetaData = ChartInfo.functions.getExistingEntitiesMetaData();
        _.each(existingEntitiesMetaData, function (val, index) {
            $("#streamingstatus[data-id='" + val.connAccessId + "'][data-name='" + val.entityname + "'] ").prop('checked', true)
            if (val.dataPushStatus != null && val.dataPushStatus == '0') {
                $("#streamingstatus[data-id='" + val.connAccessId + "'][data-name='" + val.entityname + "'] ").prop('checked', false)
            }
        })
    }
    render() {
        return (
            <div></div>
        )
    }
}