import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SendRequest from '../../../SendRequest';
import EmailDisclaimerTemplate from './EmailDisclaimerTemplate';
import {getMessage} from '../../../js/helpers/utils/BirdMessage';
const $ = window.$;

export default class EmailDisclaimerView extends Component{
    constructor(props){
        super(props);
        this.getEmailDisclaimer();
    }
    getEmailDisclaimer=()=>{
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
		SendRequest({
            url: "getemaildisclaimer",
            queryString: "",		
            sucessFunction: (response) => {
                this.render(response)
            },
            rejectFunction: () => { },
            headers: requestHeaders
        
        });
    }
    saveEmailDisclaimer=(e)=>{
        var result = $("#emaildisclaimer").val();
        let requestHeaders = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
		var reqdata = new FormData();
		reqdata.set("formdata",result)
		SendRequest({
            url: "saveemaildisclaimer",
            body: reqdata,		
            sucessFunction: (response) => {
                if(response.status=='success'){
								$('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
								$('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
								$('.birdmessagebuttons').removeClass('show');
								$('.birdmessagebuttons').addClass('hide');
								$('.birdmessage-center').addClass("message-top-in");
								$('.details').removeClass('show');
								$('.details').addClass('hide');
								$('.birdmessage h2').empty().append('Success');
								$('.birdmessage-info').empty().text(getMessage('BirdSuccess55'));
								setTimeout(function() {
									$('.birdmessage-container .close').click();	
								}, 2000);
								}else{
								$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
								$('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
								$('.birdmessagebuttons').removeClass('show');
								$('.birdmessagebuttons').addClass('hide');
								$('.birdmessage-center').addClass("message-top-in");
								$('.details').removeClass('hide');
								$('.birdmessage h2').empty().append('Error : ');
								$('.birdmessage h2').append('<span class="errorcode">');
								$('.birdmessage .errorcode').empty().append(response.errorCode);
								$('.birdmessage-info').empty().text(response.error);
								$('.details').addClass('show');
								$('.messagedetails xmp').empty().append(response.errorTrace)
							}
            },
            rejectFunction: () => { },
            headers: requestHeaders
        
        });

    }
    render(response){
        ReactDOM.render(<EmailDisclaimerTemplate saveEmailDisclaimer={this.saveEmailDisclaimer}/>,document.querySelector(".othersettingscontainer"));
        $('#emaildisclaimer').summernote({
            height : 300,
            focus : false,
            dialogsInBody: true,
            disableDragAndDrop : true,
            toolbar : [
                [ 'style', [ 'style' ] ],
                ['font',['bold','italic','underline','clear' ] ],
                [ 'fontname',[ 'fontname' ] ],
                [ 'color', [ 'color' ] ],
                ['para',[ 'ul', 'ol','paragraph' ] ],
                [ 'height',[ 'height' ] ],
                [ 'table', [ 'table' ] ],
                ['insert',['link','picture','hr' ] ],
                ['view',[ 'fullscreen','codeview' ] ]
            ], 
            tooltip: false
           // insertText : response.emaildisclaimer
        });

        if (response.emaildisclaimer != undefined) {
			$('#emaildisclaimer').html(response.emaildisclaimer);
			$('#emaildisclaimer').summernote('code',response.emaildisclaimer);
		}
        return(<div></div>);
    }
}