import React from 'react';
import { lang } from "../js/helpers/utils/languages/language";
import _ from 'underscore';

export default class CustomSyncNumericRangeHtml extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <React.Fragment>
                <div className="control-group form-inline marg-top-10 customsyncnumericrangediv" data-num={this.props.id} tab-id="0" tab-name="" data-id={this.props.connectionAccessId} name={this.props.entityName}>
                    <div className="validationgroup form-group">
                        <label>{lang["datahub.numericcolumn"]}&nbsp;</label>
                        <select className="form-control input-sm" data-id={this.props.id} name="" style={{ maxWidth: "90px" }}>
                            <optgroup className="selectdatafield_group">
                                {
                                    this.props.datecolumns.map((value, index) => {
                                        return (
                                            <option tablename={this.props.entityName} columnname={value.name}>{value.name}</option>
                                        );
                                    })
                                }
                            </optgroup>
                        </select>&nbsp;
	                </div>
                    <div className="validationgroup form-group">
                        <label>{lang["datahub.rangefm"]}&nbsp;</label> <input type="number" className="form-control input-sm numericsyncrangefrom" value={this.props.from} data-id={this.props.id} />
                        <label>{lang["datahub.rangeto"]}&nbsp;</label> <input type="number" className="form-control input-sm numericsyncrangeto" value={this.props.to} data-id={this.props.id} />
                    </div>
                    <button className="btn btn-transparent marg-righ-10 dropdown-toggle tooltip-right removenewnumericsyncrange" id="removenewnumericstoryboard" data-tooltip={lang["datahub.remove"]}>
                        <i className="fa fa-minus-circle"></i>
                    </button>
                </div>
            </React.Fragment>
        );
    }
}