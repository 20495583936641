import React, { Component } from "react";
import ReactDOM from 'react-dom'
import _ from 'underscore'
import RolesTemplate from "./roleshtml.jsx";
import RolesListTemplate from "./roleslist.jsx";
import NewRolesView from "./newrolesview.js";
import DeleteRolesView from "./deleterolesview.js";
import SendRequest from '../../SendRequest';
import EditRoleView from './editRoleView';
import { lang } from "../../js/helpers/utils/languages/language";
const $ = window.$;
class RolesView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    let featuresString = sessionStorage.getItem("features");
    featuresString = featuresString.slice(0, 3) + featuresString.slice(4);
    let decryptedString = window.atob(featuresString);
    this.state.parsedFeatureJson = JSON.parse(decryptedString);
    this.state.features = JSON.parse(decryptedString);
    this.state.rolesresponse = {};
    this.render();
    var path = '<li><a class="navDashboard" href="#"><i class="fa  fa-home"></i>'+lang["admin.header.dashtitle"]+'</a></li><li><a href="#admin"><i class="fa fa-user-secret"></i> ' + lang["admin.header.admintitle"] + '</a></li><li><a><i class="fa fa-user-secret"></i> ' + lang["admin.roles.title"] + '</a></li>';
		$(".navbar.navbar-bird ul.nav-path").html(path);
  }
  getRoleslist(page, search, pageDisplay, order, colName, features) {
    var index = 0;
    var noOfRecords = $(".usersrowlimitbutton .page-size").text() == "" ? 10 : $(".usersrowlimitbutton .page-size").text();
    var totalRecords = 0;
    if (page != undefined && page > 1) {
      index = (page - 1) * noOfRecords;
    }
    if (order == undefined) {
      order = 'asc';
    }
    if (colName == undefined) {
      colName = '';
    }
    this.dataFieldValue = colName;
    this.sortingOrder = order;
    this.page = page;
    var that = this
    let requestHeaders = {
      method: "get",
      headers: new Headers({
        "Content-Type": "application/json"
      })
    };
    SendRequest({
      url: "getRoles",
      queryString: "",
      sucessFunction: (response) => {
        if (response.hasOwnProperty('error')) {
          $(".spinner").hide();
        } else {
          var data = response;
          _.each(data, function (val, index) {
           if(val.parentRoleId==null){
              _.each(that.state.features[0].features, function (feature) {
                if (feature.featureName =='edit_role' && feature.allow == true) {
                  val.edit = <span  title="Edit is Disabled"><button class="btn btn-admin"  type="button"  disabled="disabled"><i class="fa fa-pencil"></i></button></span>;
                }
                if (feature.featureName == 'delete_role' && feature.allow == true) {
                  val.delete = <span  title="Delete is Disabled"><button class="btn btn-admin" type="button"   disabled="disabled"><i class="fa fa-close"></i> </button></span>;
  
                }
              });
            }else{
            _.each(that.state.features[0].features, function (feature) {
              if (feature.featureName == 'add_role' && feature.allow == true) {
                val.edit = <span title="Edit"><button class="btn btn-admin editaccount3" onClick={that.editRoleView} id="edituser"  type="button" value={val.roleId}><i class="fa fa-pencil"></i></button></span>;
              }
              if (feature.featureName == 'edit_role' && feature.allow == true) {
                val.delete = <span title="Delete"><button class="btn btn-admin  deleterole" onClick={that.deleterolesView} id="deleterole"  type="button" value={val.roleId}><i class="fa fa-close"></i> </button></span>;

              }
            });
          }
          });
          
          this.state.rolesresponse = data;
          ReactDOM.unmountComponentAtNode(document.getElementById("rolestable"));
          ReactDOM.render(<RolesListTemplate totalRecords={totalRecords} sizePerPage={pageDisplay} search={search} data={this.state.rolesresponse} />, document.getElementById("rolestable"));
        }
      },
      rejectFunction: () => { },
      headers: requestHeaders
    })
  }
  newrolesView = () => {
    var newrolesView = new NewRolesView({ roles: this.state.rolesresponse });
  }
  deleterolesView = (e) => {
    var val = $(e.currentTarget).val();
    var deleterolesView = new DeleteRolesView({ roleId: val });
  }
  editRoleView = (e) => {
    var val = $(e.currentTarget).val();
    var editRoleView = new EditRoleView({ roleId: val, roles: this.state.rolesresponse });
  }
  searchkeyup = (e) => {
    var $this = e.currentTarget;
        var valThis = $($this).val().toLowerCase();
        $('#rolestable tbody tr').each(function () {
            var text = $(this).find('td:first-child').text().toLowerCase();
            (text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
        });

    $(".searchrolestable").focus();
  }
  render() {
    this.getRoleslist(undefined, "", 10, 'asc', undefined, this.state.features);
    $('a.boc-maxscreen-left-item.Roles').addClass('showactive')
    $('.boc-admin-main .maxscreen-lft-filter-items a').not('.Roles').removeClass('showactive');
    return (
      <RolesTemplate roles={this.state.rolesresponse} newrolesView={this.newrolesView} features={this.state.features} searchKeyup={this.searchkeyup}/>
    )
  }
}
export default RolesView