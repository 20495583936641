import React from 'react';
import ReactDOM from 'react-dom';
import SendRequest from '../../SendRequest';
import FeaturesTemplate from './featureshtml';
const $ = window.$;

export default class FeatuesView extends React.Component{
    constructor(props){
        super(props);
        this.getFeatures();
    }
    getFeatures(){
        var roleId=this.props.roleId;
			if(roleId!=""){
        let requestHeaders = {
          method: "get",
          headers: new Headers({
              "Content-Type": "application/json"
          })
      };
         SendRequest({
          url: "getfeatures",
          queryString: "?roleId= " + roleId ,
          sucessFunction: (response) => {
              if (response.hasOwnProperty('error')) {
                  $(".spinner").hide();
              } else {
               // var that= this;
               // that.state.features=response;
               $("#role-accordion:nth-child(1n)").accordion("destroy");
                ReactDOM.render(<FeaturesTemplate allfeatures={response}/>,document.getElementById("role-accordiondiv"));
                // $("#role-accordion:nth-child(1n)").accordion("destroy").then(function(){
                
                  $("#role-accordion:nth-child(1n)").accordion({
                    heightStyle: "content",
                    active: false,
                    collapsible: true,
                    header: "h3"
                  });
                  //Collapse the first accordion onLoad
                  $( "#role-accordion:nth-child(1n)" ).accordion( "option", "active", 0 );
                // });
                
              }
            },
            rejectFunction: () => { },
            headers: requestHeaders
          })
				}else{
					$('#role-accordion').empty();
				}
    }

}