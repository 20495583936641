import React, { Component } from "react";
import SendRequest from '../../SendRequest';
import { getMessage } from '../../js/helpers/utils/BirdMessage';
import RoleView from './rolesview'
import { lang} from "../../js/helpers/utils/languages/language";
const $ =window.$;

export default class AddnewRole extends Component {
  constructor(props) {
    super(props);
      this.state = { };
    let featuresString = sessionStorage.getItem("features");
    featuresString = featuresString.slice(0, 3) + featuresString.slice(4);
    let decryptedString = window.atob(featuresString);
    this.state.parsedFeatureJson = JSON.parse(decryptedString);
    this.state.features= JSON.parse(decryptedString);
    this.state.formData = $(".newroleform").serializeArray();
    var serialized = $('.newroleform').serializeArray();
    // var s = '';
    //    var data = {};
    //    for(s in serialized){
    //        data[serialized[s]['name']] = serialized[s]['value']
    //    }
      var result=JSON.stringify(serialized);
    this.state.result=result;
	this.state.roleName=$('#rolename').val();
    this.state.roleDesc=$('#roledesc').val();
	this.state.parentRoleId=$('#parentroleid').val();
	this.state.status=$('input[name=status]:checked').val();
		//    this.state.data = {'rolename':roleName,'parentroleid':parentRoleId,'status':status}
     
    this.addrole();
  }
  isValid=(data)=>{
    var isValid = true;
    if($.trim($.trim(data.get("roleName")))==""){
    $(".rolenameerror").removeClass('hidden');
    $(".rolenameerror").text(lang["admin.roles.namerqd"] );
    isValid = false;
    }
    if($.trim($.trim(data.get("parentRoleId")))==""){
    $(".roleerror").removeClass('hidden');
    $(".roleerror").text(lang["admin.roles.chooserole"]);
    isValid = false;
    }
    return isValid;
  }
  addrole=(e)=>{
    var that =this;
    var reqdata = new FormData();
    reqdata.set("roleName", that.state.roleName);
    reqdata.set("roleDesc", that.state.roleDesc);
    reqdata.set("parentRoleId", that.state.parentRoleId);
    reqdata.set("status", that.state.status);
    reqdata.set("formData", that.state.result); 
    var isValid = this.isValid(reqdata);
    if(isValid){
        let requestHeaders = {
        method: "post",
        headers: new Headers({
            "Content-Type": "application/json"
        })
    };
                      
     SendRequest({
      url: "createrole",
      body: reqdata,
      //queryString: "?roleName="+that.state.roleName+"&roleDesc="+that.state.roleDesc+"&parentRoleId="+that.state.parentRoleId +"&status="+that.state.status+"&formData="+ that.state.result,
      sucessFunction: (response) => {
          that.render(response);
        },
        rejectFunction: () => { },
        headers: requestHeaders
      })
    }
    }
render(response){
  if(response.status=='success'){
    $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
    $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
    $('.birdmessagebuttons').removeClass('show');
    $('.birdmessagebuttons').addClass('hide');
    $('.birdmessage-center').addClass("message-top-in");
    $('.details').removeClass('show');
    $('.details').addClass('hide');
    $('.birdmessage h2').empty().append('Success');
    $('.birdmessage-info').empty().text(getMessage('BirdSuccess12'));
    setTimeout(function() {
            $('.birdmessage-container .close').click();	
            }, 3000);
    setTimeout(function() {
      window.location.reload(false);
       },3000);
  }else if(response.hasOwnProperty('error')){
      $(".spinner").hide();
      $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
    $('.birdmessagebuttons').removeClass('show');
    $('.birdmessagebuttons').addClass('hide');
    $('.birdmessage-center').addClass("message-top-in");
    $('.details').removeClass('hide');
    $('.birdmessage h2').empty().append('Error : ');
    $('.birdmessage h2').append('<span class="errorcode">');
    $('.birdmessage .errorcode').empty().append(response.errorCode);
    $('.birdmessage-info').empty().text(response.error);
    $('.details').addClass('show');
    $('.messagedetails xmp').empty().append(response.errorTrace)
    // setTimeout(function() {
    //         $('.birdmessage-container .close').click();	
    //         }, 3000
  }
    return(
        <div></div>
        )
}
}
