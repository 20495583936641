import React from "react";
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import { getMessage } from '../js/helpers/utils/BirdMessage';
import _ from 'lodash';
import DrilldownHierarchyModalTempltate from "./drilldownhierarchymodaltemplate.jsx"
import ReactDOM from "react-dom";
import HierarchyDropDownView from "./hierarchydropdownview"
import CustomHierarchyFieldView from "./customhierarchyfieldview"
import AddDimensionToCustomHierarchyModal from "./adddimensiontohierarchymodal"
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;


export default class DrilldownHierarchyModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            multifact: props.multifact,
            facttables: props.facttables,
            multifactentities: props.multifactentities,
            chartnumber: props.chartnumber,
        };
        this.render();
    }
    render() {
        var that = this;
        this.state.el = ".customfieldhierarchymodalcontainer" + this.state.chartnumber;
        var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(this.props.chartnumber));
        var convertedNames = JSON.parse(sessionStorage.getItem("convertedtypes"));
        var jsonObject = {};
        _.each(convertedNames, function (array) {
            jsonObject[array.columnName] = array.dataType;
        });
        var result = $.grep(ChartInfo.functions.getcustomhierarchy(), function (e) { return e.name == that.props.columnName });
        if (result != undefined && result.length > 0) {
            this.state.oldName = result[0].name;
        }
        var convertedNames = sessionStorage.getItem('renameMeasuresorDimensions') != null ? JSON.parse(sessionStorage.getItem('renameMeasuresorDimensions')) : [];
        ReactDOM.render(<DrilldownHierarchyModalTempltate applycustomhierarchy={this.applycustomhierarchy} closehierarchymodal={this.closehierarchymodal} dropdownhierarchylist={this.dropdownhierarchylist}
            chartnumber={this.state.chartnumber} existinghierarchy={result} convertedTypes={jsonObject} convertedNames={convertedNames}
        ></DrilldownHierarchyModalTempltate>, document.querySelector(".customfieldhierarchymodalcontainer" + this.state.chartnumber));
        $('.customhierarchyattributes').sortable();
        return (<div></div>);
    }
    closehierarchymodal = () => {
        ReactDOM.unmountComponentAtNode(document.querySelector(".customfieldhierarchymodalcontainer" + this.state.chartnumber));
    }
    dropdownhierarchylist = (e) => {
        e.preventDefault();
        var that = this;
        $(".dropdownhierarchylist1").empty();
        $(".dropdownhierarchylist1").append('<li class="xs-list-item"> <input class="form-control form-control-hierarchydropdown search-image" placeholder='+lang["storyboard.search"]+' id="searchinput" type="text"></li>');
        _.each($(".attributesdimensions"), function (e) {
            var $this = e;
            if (!$($this).hasClass("customhierarchyfields") && $($this).attr("data-type") != "customrange") {
                var today = new Date();
                var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                $(".dropdownhierarchylist1").append("<div className='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
                var xAxisDropDown = new HierarchyDropDownView({
                    dynamicdiv: dynamicdiv,
                    addcustomhierarchyvalue1: that.addcustomhierarchyvalue1,
                    columnDisplayName: $($this).attr('title'),
                    columnName: $($this).attr("data-name"),
                    dataType: $($this).attr("data-type"),
                    tableName: $($this).attr("table-name"),
                    iscustomfunction: $($this).attr("iscustomfunction"),
                    multifact: that.state.multifact,
                    facttables: that.state.facttables,
                    multifactentities: that.state.multifactentities,
                });
                $(".dropdownhierarchylist1").append($("#" + dynamicdiv));
                $('.form-control-hierarchydropdown').click(function (e) {
                    e.stopPropagation();
                });
                $('.form-control-hierarchydropdown').keyup(function (e) {
                    var $this = e.currentTarget;
                    var valThis = $($this).val().toLowerCase();
                    $(".itemname").parents(".hierarchyitem").each(function () {
                        var text = $(this).attr("title").toLowerCase();
                        (text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
                    });
                });
            }
        });
    }
    addcustomhierarchyvalue1 = (e) => {
        e.preventDefault();
        var $this = e.currentTarget;
        var isAlreadyExist = false;
        var chartno = this.props.chartnumber;
        _.each($(".customfieldhierarchymodalcontainer" + chartno + ' .customhierarchyattributes .singleliitems'), function (list) {
            if ($(list).attr("data-name") == $($this).attr("data-name")) {
                isAlreadyExist = true;
            }
        });
        if (isAlreadyExist == false) {
            var columnName = $($this).attr("data-name");
            var columnDisplayName = $($this).attr("data-display-name");
            var datatype = $($this).attr("data-type");
            var tableName = $($this).attr("table-name");
            var iscustomfunction = $($this).attr("iscustomfunction");
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $(".customhierarchyattributes").append("<div className='" + dynamicdiv + "hide' id='" + dynamicdiv + "'></div>");
            var xaxisAttrView = new AddDimensionToCustomHierarchyModal({
                dynamicdiv: dynamicdiv,
                xaxiscolumnDisplayName: columnDisplayName,
                xaxiscolumnName: columnName,
                xaxiscolumnType: datatype,
                xaxistableName: tableName,
                iscustomfunction: iscustomfunction
            });
            $(".customhierarchyattributes").append($("#" + dynamicdiv));
        }
    }
    applycustomhierarchy = (e) => {
        e.preventDefault();
        var hierarchyName = $(this.state.el).find('#hierarchyname').val().trim().replace(/([#;?&,%. +*~\':"!^$%[\]()<=>|\/@])/g, "\\$1");
        var reservedwordslist=JSON.parse(sessionStorage.getItem("reservedwordslist"));
			var reservedwordCheck=hierarchyName.toLowerCase();
			var status=false;
			_.each(reservedwordslist,function(reservedword,j){
				if(reservedwordCheck==reservedword){
				status=true
				}
			});					
		if(status== true){
			$(".spinner").hide();
			$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
			$('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
			$('.birdmessagebuttons').removeClass('show');
			$('.birdmessagebuttons').addClass('hide');
			$('.birdmessage-center').addClass("message-top-in");
			$('.details').removeClass('show');
			$('.details').addClass('hide');
			$('.birdmessage h2').empty().append('Warning');
			$('.birdmessage-info').empty().text("DrillDown Hierarachy "+ ""+getMessage('BirdWarning147'));
			$(".spinner").hide();
			//$('.applyattributes').addClass("disabled");
			return;
		}
        var columnName = hierarchyName.replace(/([#;?&,%. +*~\':"!^$%[\]()<=>|\/@])/g, "\\$1");
        this.state.closemodel = true;
        if (this.state.oldName != hierarchyName) {
            if (!hierarchyName.trim().length > 0 || $('.attributesdimensions[title="' + columnName + '"]').length > 0
                || $('.customhierarchyfields[title="' + columnName + '"]').length > 0 || $('.customaggregatedfields[title="' + columnName + '"]').length > 0
                || $('.customrangefields[title="' + columnName + '"]').length > 0 || $('.customparameterfield[title="' + columnName + '"]').length > 0
                || $('.custommeasurehierarchyfields[title="' + columnName + '"]').length > 0) {
                    this.state.closemodel = false;
                $('.help-block.customhierarchy').removeClass('hide');
                $('.help-block.customhierarchy_li_items').addClass('hide');
                return;
            } else if (!$('.customhierarchyattributes li').length > 0) {
                this.state.closemodel= false;
                $('.help-block.customhierarchy_li_items').removeClass('hide');
                $('.help-block.customhierarchy').addClass('hide');
            } else {
                this.createCustomHierarchyField(e);
                var existingHeirarchyObject = [];
                var jsonArr = [];
                var levelMappingObject = {};
                levelMappingObject[hierarchyName] = 0;
                jsonArr.push(levelMappingObject);
                jsonArr.push([]);
                existingHeirarchyObject.push(jsonArr);
                sessionStorage.setItem('customhierarchy' + this.state.chartnumber, JSON.stringify(existingHeirarchyObject));
                var chartDetails = ChartInfo.functions.getChartDetails(this.state.chartnumber);

                //    this.options.broker.trigger('applyfiltesandredrawchart', "", chartDetails , "yes",this.state.chartnumber,undefined,undefined,undefined,undefined,undefined);
            }
        } else {
            if (!$('.customhierarchyattributes li').length > 0) {
                $('.help-block.customhierarchy_li_items').removeClass('hide');
                $('.help-block.customhierarchy').addClass('hide');
                return false
            } else {
                this.createCustomHierarchyField(e);
            }
        }
        if (this.state.closemodel) {
            this.closehierarchymodal();
        }
    }
    createCustomHierarchyField(e) {
        var hierarchyName = $(this.state.el).find('#hierarchyname').val();
        var fieldNameTest = hierarchyName
        var chartno = this.props.chartnumber;
        if ((/[!@#$%^&*()+\=\[\]{};':\\|,.<>\/?"`]/.test(fieldNameTest)) || (fieldNameTest.toLowerCase().trim() == "min"
            || fieldNameTest.toLowerCase().trim() == "max"
            || fieldNameTest.toLowerCase().trim() == "avg" || fieldNameTest.toLowerCase().trim() == "sum"
            || fieldNameTest.toLowerCase().trim() == "count" || fieldNameTest.toLowerCase().trim() == "avgerage")) {
            this.state.closemodel = false;
            $(".spinner").hide();
            $(".gridspinner").hide();
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Information');
            $('.birdmessage-info').empty().text(getMessage('BirdInformation46'));
            return false;
        }
        var that = this;
        var jsonobj = {};
        var hierarchyListArray = [];
        var columnName = hierarchyName.replace(/([#;?&,%. +*~\':"!^$%[\]()<=>|\/@])/g, "\\$1");
        if (ChartInfo.functions.isScriptData(columnName)) {
            return true;
        }
        $('.help-block.customhierarchy').addClass('hide');
        $('.help-block.customhierarchy_li_items').addClass('hide');
        var deletevalue = $.grep(ChartInfo.functions.getcustomhierarchy(), function (e) { return e.name != that.state.oldName });
        if (that.state.oldName != undefined) {
            that.state.oldName = that.state.oldName.replace(/([#;?&,%.+*~\':"!^$%[\]()<=>|\/@])/g, "\\$1")
        }
        if (deletevalue.length >= 0) {
            $('.customhierarchyfields[title="' + this.state.oldName + '"]').remove();
            ChartInfo.functions.setcustomhierarchy(deletevalue);
        }
        var jsonarray = $.grep(ChartInfo.functions.getcustomhierarchy(), function (e) { return e.name != hierarchyName });
        jsonobj['name'] = hierarchyName
        $('.customhierarchyfields[data-name="' + columnName + '"]').remove();
        $(".customfieldhierarchymodalcontainer" + chartno + ' .customhierarchyattributes #itemdisplay').each(function (k, list) {
            var jsonobjlist = {};
            jsonobjlist['columnDisplayName'] = $(list).attr('title');
            jsonobjlist["columnName"] = $(list).attr('data-name');
            jsonobjlist['tableName'] = $(list).attr('table-name');
            jsonobjlist['dataType'] = $(list).attr('data-type');
            hierarchyListArray.push(jsonobjlist);
        });
        jsonobj['hierarchelist'] = hierarchyListArray
        jsonarray.push(jsonobj);
        var today = new Date();
        var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
        $(".customhierarchydimensions").append("<div className='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
        var customfieldView = new CustomHierarchyFieldView({
            dynamicdiv: dynamicdiv,
            columnDisplayName: hierarchyName,
            columnName: hierarchyName
        });
        $(".attributescustomfields[data-name='" + that.state.oldName + "']").remove();
        var fieldName = hierarchyName
        var renameXaxisName = $('.xaxisattributes li[data-name="' + that.state.oldName + '"]');
        $(renameXaxisName).attr('data-name', fieldName);
        $(renameXaxisName).attr('title', fieldName);
        $(renameXaxisName).find('.itemname').text(fieldName);
        if (that.state.oldName != undefined && that.state.oldName != fieldName) {
            $('.callcustomhierarchy [data-edit="edit"], [data-name="' + that.state.oldName + '"]').attr("data-name", fieldName);
        }
        ChartInfo.functions.updateAxisName(that.state.oldName, fieldName, "xAxis");
        var tempObject = sessionStorage.getItem("renameMeasuresorDimensions") == null ? {} : JSON.parse(sessionStorage.getItem("renameMeasuresorDimensions"));
        tempObject[that.state.oldName] = fieldName;
        sessionStorage.setItem("renameMeasuresorDimensions", JSON.stringify(tempObject))
        $(".customhierarchydimensions").append($("#" + dynamicdiv));
        $(".dimensioncount").text(parseInt($('.dimensioncount').text()) + 1);
        if (that.props.multifact == "true") {
            $("ul.xaxisattributes").empty();
        }
        ChartInfo.functions.setcustomhierarchy(jsonarray);
        $(".customfieldhierarchy" + this.state.chartnumber + "").modal("hide");
    }
}