import React from "react";
import _ from 'underscore';
import { lang} from "../../js/helpers/utils/languages/language";
const $ =window.$;
export default class GroupsViewHtml extends React.Component{
  constructor(props){
super(props);
this.state={};
  }
  hideleft = ()=> {
    $('.leftarea').addClass('hide');

    $('.hideleft').removeClass('hide');
    $('.showleft').addClass('hide');

    $('.leftshow').removeClass('hide');
    $('.lefthide').addClass('hide');

    $('.w2ui-resizer').resize(); 
    $(".boc-admin-main .notifications-table-container table").bootstrapTable('resetView');
  }
  leftshow  = ()=>  {
    $('.leftarea').removeClass('hide');

    $('.hideleft').removeClass('hide');
    $('.showleft').addClass('hide');
    
    $('.leftshow').addClass('hide');
    $('.lefthide').removeClass('hide');
    
    $('.w2ui-resizer').resize();
    $(".boc-admin-main .notifications-table-container table").bootstrapTable('resetView');
  }
  lefthide = ()=> {
    $('#icontent').addClass('hide');
    $('.leftarea').addClass('shownsingle');
    $('.editrole').addClass('disabled');
    $('.editgroup').addClass('disabled');
    $('.editaccount3').addClass('disabled');
    $('.editschedule').addClass('disabled');
    $('.editmail').addClass('disabled');
    $('.viewhistory').addClass('disabled');
    
    $('.hideleft').addClass('hide');
    $('.showleft').removeClass('hide');
    
    $('.leftshow').addClass('hide');
    $('.lefthide').removeClass('hide');
    
    $('.w2ui-resizer').resize();
    $(".boc-admin-main .notifications-table-container table").bootstrapTable('resetView');
  }
  showleft = ()=>  {
    $('#icontent').removeClass('hide');
    $('.leftarea').removeClass('shownsingle');
    $('.editrole').removeClass('disabled');
    $('.editgroup').removeClass('disabled');
    $('.editaccount3').removeClass('disabled');
    $('.editschedule').removeClass('disabled');
    $('.editmail').removeClass('disabled');
    $('.viewhistory').removeClass('disabled');
    
    $('.hideleft').removeClass('hide');
    $('.showleft').addClass('hide');
    
    $('.leftshow').addClass('hide');
    $('.lefthide').removeClass('hide');
    
    $('.w2ui-resizer').resize();
    $(".boc-admin-main .notifications-table-container table").bootstrapTable('resetView');
  }
  render(){
var that = this.props;    
    return(
     <div class="container-fluid">
       <div class="row">
         <h1 class="boc-main-title">
           <i class="fa fa-users fa-admin-sm"></i>{lang["admin.groups.title"]}
         </h1>
     
     
         <div class="container-fluid">
           <div class="row in-back">
             <div class="row-same-height-inner">
               <div class="col-xs-4 col-xs-height in-panes leftarea">
               <div class="btn btn-transparent hideleft toggleleft" onClick={this.hideleft}><i class="fa fa-grey fa-angle-left"></i></div>
               <div class="btn btn-transparent showleft toggleleft hide" onClick={this.showleft}><i class="fa fa-grey fa-angle-left"></i></div>
               
                  
                
                   <div class="pull-right">
                 < button class="btn btn-bird btn-sm adduser btn-highlight" onClick={that.createUsersView} id="addgroups"><i class="fa fa-plus marg-righ-5"></i>{lang["admin.groups.grpbtn"]}</button>
                 </div>
                 <div class="listcontainer col-xs-12">
                 
                   <div class="notifications-table-container">
                   <div class="pull-left search marg-top-10">
					<input class="form-control searchuserstable" type="text" onKeyUp={that.searchkeyup} placeholder={lang["admin.groups.search"]} value={that.search} />
				</div>  
                     <div id = "userstable"></div>
                   </div>
                 </div>
                 <div class="clear" class="icontent"></div>
     
               </div>
               <div class="col-xs-6 col-xs-height in-panes" id="icontent">
               <div class="btn btn-transparent leftshow toggleright hide" onClick={this.leftshow}><i class="fa fa-grey fa-angle-right"></i></div>
               <div class="btn btn-transparent lefthide toggleright" onClick={this.lefthide}><i class="fa fa-grey fa-angle-right"></i></div>
               <div class="inneraccountscontentarea newuser passwordsresetfromadmin"></div>
               </div>
     
             </div>
           </div>
         </div>
     
     
       </div>
     </div>
     
     );
  }
}

