import React, { isValidElement } from 'react';
import _ from 'lodash';
import $ from 'jquery/dist/jquery';
import SendRequest from '../SendRequest';
import { getMessage } from '../js/helpers/utils/BirdMessage';
import { lang } from "../js/helpers/utils/languages/language";

export default class LicenseRenewModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
        this.applylicensechanges = this.applylicensechanges.bind(this);
    }
    render() {

        return (
            // <!-- licenserenewview.js -->

            <div className="modal fade in" id="licenserenewmodel" tabIndex="-1" role="dialog" aria-labelledby="licenserenewmodel" aria-hidden="false" style={{ display: "block" }}>
                	<div className="modal-backdrop fade in" onClick={this.props.close}></div>
                <div className="modal-dialog modal-sm modal-sm-400 modal-dialog-sm-center modal-dialog-center" style={{ zIndex: "1050" }}>
                    <div className="modal-content">
                        <div className="modal-header boc-modal-hf-padding">
                            <button type="button" className="close white" id="licenseclose" aria-hidden="true" data-dismiss="modal" onClick={this.props.close}>&times;</button>
                            <h4 className="modal-title"><i className="fa fa-certificate"></i>{lang["admin.license.renewtitle"]}</h4>	
                        </div>
                        <div className="modal-body" style={{maxHeight:"calc(100vh - 180px)",overflow:"auto"}}>
                            <div className="container-fluid">
                                <div className="row filtercontent">
                                    <div className="validationgroup">
                                        <span className="required right legend"></span>
                                        <legend className="subhead">{lang["admin.license.renewkey"]}</legend>
                                        <div className="col-xs-12 nopadding">
                                            {/* <input type="name" id="licensekey" name="licensekey" className="modal-form-fields" placeholder={lang["admin.license.renewkey"]}/> */}
                                            <textarea rows="3" type="name" id="licensekey" name="licensekey" className="modal-form-fields" placeholder={lang["admin.license.renewkey"]} style={{ resize:'vertical' }}></textarea>
                                            <span className="licenseerrormsg ">{lang["admin.license.keyrqd"]} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer boc-modal-hf-padding">
                            <button type="button" className="btn btn-bird" id="licensecancel" data-dismiss="modal" onClick={this.props.close}><i className="fa fa-times"></i>{lang["admin.license.cnclbtn"]}</button>
                            <button type="button" className="btn btn-bird renewlicense" id="renewlicense"  onClick={this.applylicensechanges}><i className="fa fa-refresh"></i>{lang["admin.license.renew"]}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    applylicensechanges(e) {
        var that = this;
        var licenseKey = $('#licensekey').val();
        var licenseData  = new FormData();
        licenseData.set("license",licenseKey);
        if(licenseKey!=undefined && licenseKey!=""){
            let requestHeaders = {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            SendRequest({
                headers: requestHeaders,
                url: "renewlicensekey",
                body: licenseData,    
                sucessFunction: (response) => {
                    if (response.hasOwnProperty('error')) {
                        $(".spinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.errorTrace);
                    } else {
                        if(response.status=='success'){
                        //$("#licenserenewmodel").modal("hide");
						$('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
						$('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
						$('.birdmessagebuttons').removeClass('show');
						$('.birdmessagebuttons').addClass('hide');
						$('.birdmessage-center').addClass("message-top-in");
						$('.details').removeClass('show');
						$('.details').addClass('hide');
						$('.birdmessage h2').empty().append('Success');
                        $('.birdmessage-info').empty().text(getMessage('BirdSuccess40'));
                        that.props.close();
                        }
                    }
                }
            });
        }
        //this.props.close();

    }
}