import React, { Component } from "react";
import ReactDOM from 'react-dom'
import SendRequest from '../SendRequest';
import _ from 'lodash';
import * as ChartInfo from "../js/helpers/utils/chartinfo";
const $ = window.$;


export default class DrillThroughOptionsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.state.elelement = ".maxanim7[data-num=" + this.props.currentChartNumber + "] .pie-menu";
        this.render();
    }
    render() {
        var drillthroughreports = ChartInfo.functions.getDrillThroughReports();
        var today = new Date();
        var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
        $(this.state.elelement).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
        $("ul.drillthroughoptions").remove();
        ReactDOM.render(<DrillThroughOptionsTemplate drillthroughreport={this.drillthroughreport} reports={JSON.parse(drillthroughreports)} />, document.getElementById(dynamicdiv));
        var diffwidth = window.screen.width - this.props.posleft;
        var actwidth = this.props.posleft;
        if (diffwidth < 160) {
            this.props.posleft = this.props.posleft - (160 - diffwidth + 20);
        }
        $("ul.drillthroughoptions").css("left", this.props.posleft);
        $("ul.drillthroughoptions").css("top", this.props.postop);
        var that = this;

        return (<div></div>)
    }
    drillthroughreport = (e) => {
        e.preventDefault();
        e.stopPropagation();
        var $this = e.currentTarget;
        var that = this;
        var reportId = $($this).val();
        console.log(reportId);
        var filters = ChartInfo.functions.getFilters();
        var drillthroughcheckcolumnsArray = ChartInfo.functions.getDrillthroughColumnNamesArray();
        var location = window.location;
        var excludeFiltersData = ChartInfo.functions.getExcludeFilterData();
        var columnName = excludeFiltersData[0].columnname;
        var drillthroughcolumnarray=[];
        if (columnName.split(",").length == 2) {
            var filterData = columnName.split(",")[0] + "=" + encodeURIComponent(excludeFiltersData[0].data.split(",")[0]);
            filterData += "&" + columnName.split(",")[1] + "=" + encodeURIComponent(excludeFiltersData[0].data.split(",")[1]);
        }
        else {
            var filterData = columnName + "=" + encodeURIComponent(excludeFiltersData[0].data.replace(/,/g, "``"));
            if (excludeFiltersData[1] != undefined) {
                var colName = excludeFiltersData[1].columnname;
                filterData += "&" + colName + "=" + encodeURIComponent(excludeFiltersData[1].data.replace(/,/g, "``"));
            }
        }
        var filterscolumns=[]
			_.each(drillthroughcheckcolumnsArray,function(val){
				_.each(filters,function(val1){
					if(val1.columndisplayname===val && drillthroughcolumnarray.indexOf(val1.columndisplayname)==-1){
						if(val1.dataType==='number'){
                            if(val1.fromRange!=undefined&&val1.toRange!=undefined){
							    filterData+="&"+ val+"="+encodeURIComponent("["+val1.fromRange+","+val1.toRange+"]");
                             }else if(val1.data!=undefined){
								filterData+="&"+ val+"="+encodeURIComponent(val1.data.replace(/,/g,"``"));
                             }else if(val1.filtergreaterthanvalue!=undefined&&val1.filterlessthanvalue!=undefined){
								filterData+="&"+ val+"="+encodeURIComponent("["+val1.filtergreaterthanvalue+","+val1.filterlessthanvalue+"]");
                             }
						}else if(val1.dataType==='string'){
							if(val1.data!=undefined){
								filterData+="&"+ val+"="+encodeURIComponent(val1.data.replace(/,/g,"``"));
								}else if(val1.data==undefined && !_.isEmpty(val1.customfield)){
									filterData+="&"+ val+"="+encodeURIComponent(val1.customfield.sqlcondition.sqldata.replace(/,/g,"``"));
								}
						}else if(val1.dataType==='date'){
							if(filterscolumns.indexOf(val1.columndisplayname)==-1){
								filterData+="&"+ val+"="+encodeURIComponent("["+new Date(val1.fromRange.toString()).getTime()+","+new Date(val1.toRange.toString()).getTime()+"]");
								filterscolumns.push(val1.columndisplayname)
							}
						}
					}
				})
			})
        var search = location.search;
        if (location.search != undefined && location.search.length > 0) {
            search = search + "&" + filterData;
        } else {
            search = "?" + filterData;
        }
        var href = location.origin + "/" + window.localStorage.getItem("appName") + "/welcome/viewreport/" + btoa(reportId) + search;
        console.log(href);
        window.open(href, '_blank');
        $(".drillthroughreport").addClass("disabled");
        setTimeout(function () {
            $(".drillthroughreport").removeClass("disabled");
        }, 5000);
    }
}
class DrillThroughOptionsTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        var that = this.props;
        return (
            <ul className="dropdown-menu dropdown-menu nlpdropdown pull-right drillthroughoptions">
                {that.reports.map((report, index) => {
                    return (report != undefined && report != null) ? (
                        <li key={index} className="nlpbuttonli drillthroughreport" onClick={that.drillthroughreport} value={report.report_id} title={report.report_name}><i className="fa fa-file-text-o"></i> &nbsp;{report.report_name}</li>
                    ) : null
                })}
            </ul>
        );
    }
}