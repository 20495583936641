import React, { Fragment, Component } from 'react'
import ReactDOM from 'react-dom'
import { whitelableMap } from "../js/helpers/utils/whitelable";
import { lang } from "../js/helpers/utils/languages/language";
export default class AboutBird extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collection: props.collection != undefined ? props.collection : []
        };
        this.render();
    }
    render() {
        ReactDOM.render(<AboutBirdTemplate close={this.close} about={this.state.collection} />, document.getElementById("aboutBirdid"));
        return (<div></div>)
    }
    close = () => {
        ReactDOM.unmountComponentAtNode(document.getElementById("aboutBirdid"));
    }
}
class AboutBirdTemplate extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            //    <!-- aboutBirdview.js -->
            <div className="modal fade in" id="aboutBirdmodal" tabIndex="-1" role="dialog" aria-labelledby="aboutBirdmodalNew" aria-hidden="true" style={{ display: "block" }}>
                <div className="modal-backdrop fade in" onClick={this.props.close}></div>
                <div className="modal-dialog modal-lg modal-dialog-lg-center modal-dialog-center" style={{ zIndex: "1050" }}>
                    <div className="modal-content">
                        <div className="modal-header boc-modal-hf-padding">
                            <button type="button" className="close white" onClick={this.props.close} data-dismiss="modal" aria-hidden="true">&times;</button>
                            <h4 className="modal-title"><i className="fa fa-lightbulb-o"></i> {whitelableMap["aboutetext"]}</h4>
                        </div>
                        <div className="modal-body scrollsetnew">
                            <div className="container-fluid">
                                <div className="row filtercontent">
                                    <ul className="list-group">
                                        <li className="boc-list-item">
                                        {lang["admin.header.version"]}
                                      {this.props.about.map((data) => {
                                            return <>{data.buildnumber}</>
                                        })}
                                        </li>
                                        <li className="boc-list-item">
                                            {this.props.about.map((data) => {
                                                return <> {data.data}</>
                                            })}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer boc-modal-hf-padding">
                            <button type="button" className="btn btn-bird" onClick={this.props.close} data-dismiss="modal"><i className="fa fa-times"></i> {lang["admin.cnclbtn"]}</button>
                        </div>
                    </div>
                    {/* <!-- /.modal-content --> */}
                </div>
                {/* <!-- /.modal-dialog --> */}
            </div>
            //    <!-- /.Page Settings Modal -->
        );
    }
}