import React from "react";
import ReactDOm from 'react-dom';
import _ from 'lodash';
import ViewReportHeaderTemplate from '../ViewStoryboard/ViewReportHeader.jsx';
import ViewReportHeader from '../ViewStoryboard/viewreportheader'
import LiveReportsView from '../ViewStoryboard/livereportsview'
import SendRequest from '../SendRequest';
import CustomStoryBoardViews from './CustomStoryboardView';
import SingleReportView from '../ViewStoryboard/SingleReportView'
import * as collection from "../js/helpers/utils/SavedReportCollection";
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import { lang } from "../js/helpers/utils/languages/language";
import PubSub from 'pubsub-js'
import $ from 'jquery/dist/jquery';
import * as CustomStoryBoardInfo from "../js/helpers/utils/customstoryboardinfo";
import worker_script from "../js/helpers/utils/worker";
import { getMessage } from '../js/helpers/utils/BirdMessage';



var worker;
export default class CustomStoryboards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderreturn: false,
      rendersingle: false,
      allfeatures: [],
      reportInfo: [],
      reportcolumns: [],
      view_Only_Flag: false,
      reportNumber: null,
      reportName: "",
      scheduleReportModel: {},
      sharedInfo: [],
      scheduleInfo: [],
      Report_Info: [],
      alertsInfo: [],
      filtersArray: [],
      datamodelidlist: [],
      reportcolumnslist: [],
      reportdetailsInfo: [],
      reportId: null,
      isTempStoryBoard: false,
      isDirect: 0,
      tabid: 0,
      multifact:undefined,
      multifactentities:undefined,
      facttables:undefined,
      isFromStoryBook: false,
      isFromTabClick: false,
      el: "main",
      isFromSmartInsigt: false,
      isFromSavedSmartInsight: false,
      isImportedReport: props!=undefined && props.isImportedReport != undefined ? props.isImportedReport : false,
      storybookId:"",
      customstoryboard_id: "",
      isSharedStoryBook:false,
      isSharedCustomStoryBoard:false
    }
    if(window.location.pathname.indexOf("sharecustomstoryboard")>0){
    this.state.isSharedCustomStoryBoard=true;
    }
    this.state.customstoryboard_id = this.props.match.params.customstoryboard_id;

      if(this.props.match.params.reportId != undefined){
      this.state.reportId = atob(this.props.match.params.reportId);
      }
      sessionStorage.setItem("state", "viewreport");
    
    this.unbindEvents = this.unbindEvents.bind(this);
    ChartInfo.functions.resetChartDetails();

    if (window.location.pathname.indexOf("schedulereport") >= 0 && window.location.search != undefined && window.location.search != "") {
      var url = window.location.search;
      var queryArr = [];
      if (url.length > 0) {
        queryArr = url.replace('?', "").split("&");
        for (var i = 0; i < queryArr.length; i++) {
          var key = queryArr[i].split("=")[0];
          var value = queryArr[i].split("=")[1];
          if (key == "scheduleid" || key == "date") {
            this.state[key] = value;
          }
        }
      }
    }
  }

  componentDidMount() {
    document.body.classList.add("morning");
    this.unbindEvents();
    sessionStorage.setItem("stroyboardcolortheme", "");
    ChartInfo.functions.setIsStoredProcedure(0);
    ChartInfo.functions.setIsDirect(0);
    var that = this;

    let requestHeaders = {
      method: "get",
      headers: new Headers({
        "Content-Type": "application/json"
      })
    };
    SendRequest({
      url: "getfeatures",
      queryString: "",
      sucessFunction: props => {
        this.state.allfeatures = props;
        ChartInfo.functions.setAllFeatures(props);
      
    // To check Temp storyboard or not
    if (this.props.match != undefined && this.props.match.params != undefined && this.props.match.params.customstoryboard_id == undefined) {
      //For Temp storyboard
      that.state.reportId = "";
      this.state.isTempStoryBoard = true;
      this.state.renderreturn = true;
      var dummydata = ChartInfo.functions.getDummyDataForReport();
     // collection.model.title = "Custom Storyboard";
      if(collection.model!="" && collection.model!=undefined){
        collection.model.title =lang["customboard.customboard"];
        }
      var reportdataa = {};
      reportdataa["reportdata"] = dummydata;
      collection.updateLocalStorage("",false);
      that.setState({ reportInfo: JSON.parse(dummydata) });
   //   ChartInfo.functions.setDrillThroughReports(array);
  
    } else {
      //For saved storyboard
      
      SendRequest({
        url: "getCustomStoryboardInfo",
        queryString:
          "?reportId=" + atob(this.state.customstoryboard_id)+ "&isFromStoryBook=" + false + "&tabid=" + this.state.tabid + "&isImportedReport=" + false+"&isSharedCustomStoryBoard="+this.state.isSharedCustomStoryBoard+"&storybookId="+0,
        sucessFunction: props => {
          if (props.hasOwnProperty("error")) {
            $(".spinner").hide();
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('hide');
            $('.birdmessage h2').empty().append('Error : ');
            $('.birdmessage h2').append('<span class="errorcode">');
            $('.birdmessage .errorcode').empty().append(props.errorCode);
            $('.birdmessage-info').empty().text(props.error);
            $('.details').addClass('show').text("Details");
            $('.messagedetails xmp').empty().append(props.errorTrace);
            return;
        }
        this.state.isTempStoryBoard = false;
          var reportDataFromProps = JSON.parse(props.reportinfo);
          var modelDataFromProps = props.datamodelinfo;
        //  collection.updateLocalStorage(reportDataFromProps,true);
     //     collection.createDataTypeBuckets(modelDataFromProps);
          this.state.model = reportDataFromProps;
          if (!that.state.isFromSavedSmartInsight) {
            that.state.reportInfo = reportDataFromProps.reportdata != undefined && reportDataFromProps.reportdata != "" ? JSON.parse(reportDataFromProps.reportdata) : [];
          }
         CustomStoryBoardInfo.functions.deleteCustomStoryboardchartdetails();
          that.state.reportcolumns = ""
          //JSON.parse(reportDataFromProps.reportcolumns);
          if(reportDataFromProps.view_Only_Flag!=undefined && reportDataFromProps.view_Only_Flag=="1"){
            that.state.view_Only_Flag = true;
          }
          ChartInfo.functions.setCustomStoryBoardChartDetails(that.state.reportInfo[0].axis);
          that.state.reportId = reportDataFromProps.id;
          that.state.reportName = reportDataFromProps.reportname;
          that.state.reportDesc = reportDataFromProps.reportdesc;
          that.state.title = reportDataFromProps.title;
          that.state.reportfolder = reportDataFromProps.reportfolder;
          this.state.renderreturn = true;
          that.state.reportdetailsInfo=that.state.reportInfo[0].axis;
          that.state.reportInfo[0].axis=[];
          var griddetails=this.state.reportInfo[3].grid
          this.state.reportInfo[3].grid=[];
          var count1=0;
          var tilesLength=that.state.reportdetailsInfo.length;
          _.each(that.state.reportdetailsInfo, function (child, index) {
           var reportId=child.mainreportid;
             let requestHeaders = {
            method: "get",
            headers: new Headers({
              "Content-Type": "application/json"
            })
          };
          SendRequest({
            url: "getReporDatatInfo",
            queryString:
              "?reportId=" + reportId + "&isFromStoryBook=" + false + "&visualizationtype=" + "cb" + "&tabid=" + 0 + "&isImportedReport=" + false + "&isSharedStoryBook=" + false + "&storybookId=" + 0
              +"&isEmbedReport="+false+"&isScheduleReport="+false,
            sucessFunction: props => {
              if (props.hasOwnProperty("error")) {
                if(props.error=='One of Existing report is deleted'){
                  $(".spinner").hide();
                  $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                  $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                  $('.birdmessagebuttons').removeClass('show');
                  $('.birdmessagebuttons').addClass('hide');
                  $('.birdmessage-center').addClass("message-top-in");
                  $('.details').removeClass('show');
                  $('.details').addClass('hide');
                  $('.birdmessage h2').empty().append('Warning');
                  $('.birdmessage-info').empty().text(getMessage('BirdWarning97'));
                  tilesLength--
                }else{
                $(".spinner").hide();
                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('hide');
                $('.birdmessage h2').empty().append('Error : ');
                $('.birdmessage h2').append('<span class="errorcode">');
                $('.birdmessage .errorcode').empty().append(props.errorCode);
                $('.birdmessage-info').empty().text(props.error);
                $('.details').addClass('show').text("Details");
                $('.messagedetails xmp').empty().append(props.errorTrace);
                return;
                }
              }
              else{
              var chartnumber=child.chartnumber;
              CustomStoryBoardInfo.functions.setCustomStoryboardInfo(reportId, JSON.parse(props.reportinfo));
              CustomStoryBoardInfo.functions.setCustomStoryboardInfoWithchartnumber(chartnumber, JSON.parse(props.reportinfo));
              //	ChartInfo.setCsbRefChartNum(chartno); 
              var reportDataFromProps = JSON.parse(props.reportinfo);
              var modelDataFromProps = props.datamodelinfo;
              var model = reportDataFromProps;
              var view_Only_Flag = false;
              var reportInfo = "";
              if (!that.state.isFromSavedSmartInsight) {
                reportInfo = reportDataFromProps.reportdata != undefined && reportDataFromProps.reportdata != "" ? JSON.parse(reportDataFromProps.reportdata) : [];
              }
              that.state.reportcolumns = JSON.parse(reportDataFromProps.reportcolumns);
              if (reportDataFromProps.view_Only_Flag != undefined && reportDataFromProps.view_Only_Flag == "1") {
                that.state.view_Only_Flag = true;
              }
              
              var chartDetails = (reportInfo)[0].axis[0]
              var datamodelidlist={};
              datamodelidlist[chartnumber]=reportDataFromProps.datamodelId;
              chartDetails["datamodelidlist"] = datamodelidlist;
              chartDetails["xAxis"] = chartDetails["xaxis"];
              chartDetails["yAxis"] = chartDetails["yaxis"];
              chartDetails["zAxis"] = chartDetails["zaxis"];
              chartDetails["mainreportid"] = reportDataFromProps.id;
              chartDetails["datamodelId"] = reportDataFromProps.datamodelId;
              chartDetails["chartnumber"] = chartnumber
              chartDetails["filterArray"] = reportInfo[2].filter;
              chartDetails["ismultifact"] = modelDataFromProps.multifact != undefined ? JSON.stringify(modelDataFromProps.multifact) : "false";
              chartDetails["multifactentities"]=modelDataFromProps.entities;
              chartDetails["facttables"]=modelDataFromProps.facttables;
              var Obj = {};
              Obj["ismultifact"] = modelDataFromProps.multifact != undefined ? JSON.stringify(modelDataFromProps.multifact) : "false";
              Obj["multifactentities"]=modelDataFromProps.entities;
              Obj["facttables"]=modelDataFromProps.facttables;
              that.state.multifact = modelDataFromProps.multifact != undefined ? JSON.stringify(modelDataFromProps.multifact) : "false";
              that.state.multifactentities = modelDataFromProps.entities;
              that.state.facttables = modelDataFromProps.facttables;
              ChartInfo.functions.setCustomstroyboardMultifactObj(chartDetails["chartnumber"],Obj);
				      ChartInfo.functions.setCustomStoryBoardFilterDetails(reportDataFromProps.id,reportInfo[2].filter);
              var reportcolumnslist={};
              
              var reportcolumnslist1=[];
              _.each(JSON.parse(reportDataFromProps.reportcolumns), function (column) {
                var reportcolumnslistnew = {};
                reportcolumnslistnew["tableName"] = column["tableName"];
                reportcolumnslistnew["columnDisplayType"] = column["tableDisplayType"];
                reportcolumnslistnew["tableDisplayType"] = column["tableDisplayType"];
                reportcolumnslistnew["columnName"] = column["columnName"];
                reportcolumnslistnew["columnDisplayName"] = column["columnDisplayName"];
                reportcolumnslistnew["priorityColumn"] = column["priorityColumn"];
                reportcolumnslist1.push(reportcolumnslistnew);
               });
               reportcolumnslist[chartnumber]=reportcolumnslist1
              chartDetails["reportcolumnslist"] = reportcolumnslist1
              //var customseriescolors = chartDetails["customseriescolors"];
              chartDetails["reporttitle"]=reportDataFromProps.title;
              chartDetails["reportid"] = reportDataFromProps.id;
              delete chartDetails["xaxis"];
              delete chartDetails["yaxis"];
              delete chartDetails["zaxis"];
              var customparameter='';
              var isExcludeNulls="false";
              var customseriescolors = chartDetails["customseriescolors"];
              if(chartDetails["chartType"]=="summarytable"){
                var tempsummarytabledetails = [];
                var temparray={};
                temparray["group"] = chartDetails["summarytablegroupbys"];
                temparray["textcolumns"] = chartDetails["summarytablecolumns"];
                tempsummarytabledetails.push(temparray);
                ChartInfo.functions.setSummarytableAxisFieldsArray(tempsummarytabledetails, chartDetails["chartnumber"]);
              }
              if(JSON.parse(reportDataFromProps.reportdata)[10]!=undefined){
                customparameter=JSON.parse(reportDataFromProps.reportdata)[10].customParameter;
                ChartInfo.functions.setCustomParameterId(JSON.parse(customparameter),chartDetails['reportid']);                
              }
              
              if(JSON.parse(reportDataFromProps.reportdata)[13]!=undefined){
                isExcludeNulls=JSON.parse(reportDataFromProps.reportdata)[13].isExcludeNulls;
                ChartInfo.functions.setIsExcludeNulls(isExcludeNulls);
              }										
            //	ChartInfo.functions.setCustomStoryBoardDetails(ChartDetails["chartnumber"],ChartDetails)
          //	chartDetails["customstroyboardreportId"]=that.props.reportId;
            //	ChartInfo.functions.updateChartDetails(ChartDetails["chartnumber"],JSON.stringify(ChartDetails));														
              ChartInfo.functions.setCustomstroyboardReportColumns(chartDetails["chartnumber"],reportcolumnslist1);		
              var customFields=JSON.parse(reportDataFromProps.reportdata)[5].customFields;
              if(customFields!=undefined){
                ChartInfo.functions.setCustomFieldsObjectReportedId(customFields,chartDetails['reportid']);
              }															
              var customHierarchyReportId=JSON.parse(reportDataFromProps.reportdata)[7].customHierarchy;
              if(customHierarchyReportId!=undefined){
                ChartInfo.functions.setCustomHierarchyReportId(JSON.parse(customHierarchyReportId),chartDetails['reportid']);
              }
              var rangeMap=JSON.parse(reportDataFromProps.reportdata)[8].customRange;
                if(rangeMap!=undefined){
                  ChartInfo.functions.setCustomRangeReportId(JSON.parse(rangeMap),chartDetails['reportid']);
                }
                var aggCustomMeasureFiled= JSON.parse(reportDataFromProps.reportdata)[9].customMeasure;
                if(aggCustomMeasureFiled!=undefined){
                  ChartInfo.functions.setAggCustomFieldMeasureReportId(JSON.parse(aggCustomMeasureFiled),chartDetails['reportid']);
                }	
                ChartInfo.functions.setConditionalFormatData(chartnumber,chartDetails["conditionalFormateData"]);
                ChartInfo.functions.updateCustomSeriesColorsDetails(chartnumber,customseriescolors);
                ChartInfo.functions.setMeasureReportId(JSON.parse(reportDataFromProps.reportdata)[12].customMeasureHierarchy,chartDetails['reportid']);
              CustomStoryBoardInfo.functions.setCustomstroyboardReportColumns(reportcolumnslist);    
              ChartInfo.functions.updateChartDetails(chartnumber, JSON.stringify(chartDetails));
              CustomStoryBoardInfo.functions.setCustomStoryboardchartdetails(chartDetails);
              ChartInfo.functions.setCSBchartdetails(chartnumber,chartDetails);
              that.state.reportInfo[0].axis.push(chartDetails);
              var gridInfo = griddetails;            
                count1++;
                // for constructing grids based on chart number
              if(tilesLength==count1){
                var count=0;
               var axisdets= that.state.reportInfo[0].axis;
                _.each(axisdets, function (child, index2) {
                  gridInfo.map((axis, index1) => {
                    if((child.chartnumber)==axis.num){
                    that.state.reportInfo[3].grid.push(axis);
                    count++
                    }
                  })
                  //calling render at the end of the loop
                  if(tilesLength==count){
                        that.setState({ renderreturn: true });  
                  }
                })
                            
              }
            }
             },
			rejectFunction: () => { },
			headers: requestHeaders
		});
            
  })
   //    CustomStoryBoardInfo.functions.setCustomStoryboardInfo(reportId, JSON.parse(props.reportinfo));
	//			CustomStoryBoardInfo.functions.setCustomStoryboardInfoWithchartnumber(chartnumber, JSON.parse(props.reportinfo));
         
        },
        rejectFunction: () => { },
        headers: requestHeaders
      });

    }
  },
  rejectFunction: () => { },
  headers: requestHeaders
});
  }

  render() {
    if (worker != undefined) {
      worker.terminate();
      worker = undefined;
    }
    worker = new Worker(worker_script);
    return (<div>
      {/* // Storyboard.jsx */}
      {(this.state.renderreturn) ? (
        <>{this.state.reportInfo.length > 0 || this.state.isFromSavedSmartInsight ?
          <CustomStoryBoardViews {...this.props} isFromSavedSmartInsight={this.state.isFromSavedSmartInsight} isFromSmartInsigt={this.state.isFromSmartInsigt} tabid={this.state.tabid} el={this.state.el} isFromTabClick={this.state.isFromTabClick} isFromStoryBook={this.state.isFromStoryBook} headerdata={this.state} model={this.state.model} isTempStoryBoard={this.state.isTempStoryBoard} reportInformation={this.state.reportInfo} features={this.state.allfeatures} view_Only_Flag={this.state.view_Only_Flag}
            scheduleid={this.state.scheduleid} date={this.state.date} reportId={this.state.reportId} datamodelId={this.state.datamodelId} reportcolumns={this.state.reportcolumns} isDirect={this.state.isDirect}
            multifact={this.state.multifact} multifactentities={this.state.multifactentities} facttables={this.state.facttables} collection={collection} worker={worker} datamodelidlist={this.state.datamodelidlist} reportcolumnslist={this.state.reportcolumnslist} /> : null
        }</>
      ) : null} </div>);
  }

  startliveupdate(chartnumber, liveFrom) {
    var that = this;
    var livereport = new LiveReportsView({
      worker: worker,
      reportId: that.state.reportId,
      datamodelId: that.state.datamodelId,
      features: that.state.allfeatures,
      liveFrom: liveFrom,
      facttables: that.state.facttables,
      multifact: that.state.multifact,
      multifactentities: that.state.multifactentities,
      display: "none"
    });
    var liveObj = {};
    liveObj["chartnumber"] = chartnumber;
    liveObj["liveFrom"] = liveFrom;
    PubSub.publish('startliveupdate', chartnumber, liveFrom);

  }
  unbindEvents() {
    ChartInfo.functions.resetChartDetails();
    sessionStorage.removeItem("convertedvalues");
    sessionStorage.removeItem("customFields");
    sessionStorage.removeItem("customfieldentities");
    sessionStorage.removeItem("renameMeasuresorDimensions");
    sessionStorage.removeItem("datatable_popup_yaxis");
    sessionStorage.removeItem("convertedvalues");
    sessionStorage.removeItem("changesdone");
    sessionStorage.removeItem('isconvettedvaluesapplied');
    sessionStorage.removeItem('isconvettedtypesapplied');
    sessionStorage.removeItem("renameMeasuresorDimensions");
    sessionStorage.removeItem("datatable_popup_filterdata");
    sessionStorage.removeItem("instantExcludeFilters");
    sessionStorage.removeItem("instantIncludeFilters");
    sessionStorage.removeItem("instantExcludeCustomFilters");

    for (var i = 0; i < sessionStorage.length; i++) {
      var key = sessionStorage.key(i);
      if (key.match(/drilldown/g) != null) {
        sessionStorage.removeItem(key);
      }
    }
  }
}
