import React, { Component } from "react";
import _ from 'underscore';
import { lang } from "../js/helpers/utils/languages/language";
export default class DatahubSyncLogBookTemplate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			features: []
		}
	}
	render() {
		var that = this.props;
		let featuresString = sessionStorage.getItem("features");
		if (featuresString != null && featuresString != undefined && featuresString != "") {
			featuresString = featuresString.slice(0, 3) + featuresString.slice(4);
			let decryptedString = window.atob(featuresString);
			this.state.features = JSON.parse(decryptedString);
		}
		return (
			this.state.features.map((feature, index) => {
				if (feature.moduleName === "data_hub") {
					return feature.features.map((feature, index) => {
						if (feature.featureName === "sync_log_book" && feature.allow === true) {
							return (
								<section className="boc-main-section">
									<div className="boc-home">
										<div className="row-same-height rightside ">
											<div className="container-fluid">
												<div className="row">
													<h1 className="boc-main-title">
														<i class="fa fa-file-archive-o fa-admin-sm"></i>{lang["datahub.synopsis"]}
		                                        	</h1>
													<div className="marg-top-10">
														<div className="clear"></div>
														<div className="container-fluid synclogbookcontainer"></div>
														<div className="container-fluid synclogbookhistorycontainer"></div>
													</div>
												</div>
												<div className="append_alertmodal append_alertmodal"></div>
											</div>
										</div>
									</div>
								</section>
							)
						}
					})
				}
			})
		);
	}
}