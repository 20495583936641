import React from 'react';
import { lang } from "../js/helpers/utils/languages/language";
import _ from 'underscore';
const $ =window.$;
export default class FactTableReferencesHtml extends React.Component {
    componentDidMount(){
        $('.xls-select').change(function(e){
			var $this = e.currentTarget;
			var tablename = $($this).find('option:selected').attr('tablename');
            $($($this).parent().parent().parent().children()[0]).text(tablename);
        });
    }
    render() {
        let factTable = _.isEmpty(this.props.factTables) ? this.props.tables : this.props.factTables;
        let isFact = _.isEmpty(this.props.factTables) ? 0 : 1;
        return (
            <>
                {
                    this.props.relations.map((value, index) => {
                        return (
                            <div className="xls-sheet-relation-box">
                                <div className="pull-right">
                                    <button type="button" entity_RelationId={this.props.entityRelationId != undefined ? this.props.entityRelationId : ''} className="btn btn-transparent xls-join-close" title={lang["models.removejoin"]} onClick={this.props.xlsjoinclose}><i className="fa fa-trash-o"></i></button>
                                </div>
                                <div className="container-fluid">
                                    <div className="connecting-line xls-line"></div>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <div className="xls-connect-right-container">
                                                <div className="xls-connect-primary-one" target="primary" data-num={this.props.id == undefined ? 0 : this.props.id}>{value.leftTableName == undefined ? (_.isEmpty(this.props.factTables) ? Object.keys(this.props.tables)[0] : Object.keys(this.props.factTables)[0]) : value.leftTableName}</div>
                                                <div className="xls-connect-primary-column">
                                                    {(isFact==1)?
                                                    <legend className="subhead">{lang["models.factcolmn"]}</legend>
                                                    :
                                                    <legend className="subhead">{lang["models.slctcolmn"]}</legend>
                    }
                                                    <select className="xls-select xls-select-primary" id="primaryselectdrop" source="primary" data-num={this.props.id == undefined ? 0 : this.props.id} entity_RelationId={this.props.entityRelationId != undefined ? this.props.entityRelationId : ''}>
                                                        {
                                                            Object.keys(factTable).map((item, index) => {
                                                                return (
                                                                    <optgroup label={item}>
                                                                        {
                                                                            factTable[item].map((table, index) => {
                                                                                var tableonly = item.split('.');
                                                                                if (tableonly.length >= 2) {
                                                                                    tableonly = tableonly[1];
                                                                                } else {
                                                                                    tableonly = item;
                                                                                }
                                                                                if (table.name == value.leftColumnName && item == value.leftTableName) {
                                                                                    return (<option data_fact={isFact} tableschema={value.leftTableSchema} tablename={item} tablenameonly={value.leftTableNameOnly == undefined ? tableonly : value.leftTableNameOnly} data-parent={table.workspaceentityid} columntype={table.type} columnname={table.name} value={`${item}${table.name}`}  selected>{table.name}</option>);
                                                                                } else {
                                                                                    return (<option data_fact={isFact} tableschema={value.leftTableSchema} tablename={item} tablenameonly={value.leftTableNameOnly == undefined ? tableonly : value.leftTableNameOnly} data-parent={table.workspaceentityid} columntype={table.type} columnname={table.name} value={`${item}${table.name}`}>{table.name}</option>);
                                                                                }
                                                                            })
                                                                        }
                                                                    </optgroup>
                                                                );
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <div className="xls-connect-right-container">
                                                <div className="xls-join-type">{lang["models.jointype"]}<span className="no-join hide"><i className="fa fa-info-circle"></i>&nbsp;{lang["models.nojoins"]}</span></div>
                                                <div className="xls-connect-primary-column jointypecontainer">
                                                    <select className="definejointype" id="jointypedefine"></select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <div className="xls-connect-right-container">
                                                <div className="xls-connect-primary-one" target="secondary" data-num={this.props.id == undefined ? 0 : this.props.id}>{value.rightTableName == undefined ? Object.keys(this.props.tables)[0] : value.rightTableName}</div>
                                                <div className="xls-connect-primary-column">
                                                    <legend className="subhead">{lang["models.slctcolmn"]}</legend>
                                                    <select className="xls-select xls-select-connected" id="selectconnectdropdown" source="secondary" data-num={this.props.id == undefined ? 0 : this.props.id}>
                                                        {
                                                            Object.keys(this.props.tables).map((item, index) => {
                                                                return (
                                                                    <optgroup label={item} key={index}>
                                                                        {
                                                                            this.props.tables[item].map((table, key) => {
                                                                                var isFact = 0;
                                                                                var tableonly = item.split('.');
                                                                                if (tableonly.length >= 2) {
                                                                                    tableonly = tableonly[1];
                                                                                } else {
                                                                                    tableonly = item;
                                                                                }

                                                                                if (_.isEmpty(this.props.factTables) == false) {
                                                                                    _.each(this.props.factTables, function (table, factname) {
                                                                                        if (factname === item) {
                                                                                            isFact = 1;
                                                                                        }
                                                                                    });
                                                                                }
                                                                                if (table.name == value.rightColumnName && item == value.rightTableName) {
                                                                                    return (<option data_fact={isFact} tableschema={value.rightTableSchema} tablename={item} tablenameonly={value.rightTableNameOnly == undefined ? tableonly : value.rightTableNameOnly} data-parent={table.workspaceentityid} columntype={table.type}  columnname={table.name} value={`${item}${table.name}`} selected>{table.name}</option>);
                                                                                } else {
                                                                                    return (<option data_fact={isFact} tableschema={value.rightTableSchema} tablename={item} tablenameonly={value.rightTableNameOnly == undefined ? tableonly : value.rightTableNameOnly} data-parent={table.workspaceentityid} columntype={table.type} columnname={table.name} value={`${item}${table.name}`}>{table.name}</option>);
                                                                                }
                                                                            })
                                                                        }
                                                                    </optgroup>
                                                                );
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
            </>
        );
    }
}