import React from 'react'
import _ from 'lodash';
import PubSub from 'pubsub-js'
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import * as ModalProperties from "../js/helpers/utils/modalproperties";
import ChartSeriesColorsModalViewTempl from './chartseriescolorsmodal.jsx'
import { lang } from "../js/helpers/utils/languages/language";
import ReactDOM from "react-dom";
const $ = window.$;

export default class ChartSeriesColorsModalView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chartnumber: props.chartnumber,
            defaultseriesnames: props.defaultserieslist
        };
        $('.spinner').show();
        this.state.customseriesnames = ChartInfo.functions.getCustomSeriesColorsDetails(this.props.chartnumber);
        this.render();
    }
    render() {
        this.state.el = "singlereportchartsereiscolors";
        ReactDOM.render(<ChartSeriesColorsModalViewTempl applychartseriescolors={this.applychartseriescolors} search={this.search} removeseriescoloritem={this.removeseriescoloritem} addsereiescoloritem={this.addsereiescoloritem} closeModal={this.closeModal} chartnumber={this.state.chartnumber} defaultseries={this.state.defaultseriesnames} customseries={this.state.customseriesnames} />, document.getElementById(this.state.el));
        $('.singleliitems input.colorpicker-component').colorpicker({ format: "rgba" }).on('changeColor.colorpicker', function (event) {
            var $this = event.currentTarget;
            var dataname = $($this).parent().attr("data-name");
            $($this).parent().find(".maxtheme3").css('color', $this.value);
            //	$(".dropdownseries .sereiescoloritem[data-name='"+dataname+"']").find(".maxtheme3").css('color',$this.value);
        });
        ModalProperties.resize();
        $(window).on("resize", function () {
            ModalProperties.resize();
        });
        $('.spinner').hide();
        return (<div></div>);
    }
    closeModal = () => {
        ReactDOM.unmountComponentAtNode(document.getElementById(this.state.el));
    }
    addsereiescoloritem = (e) => {
        e.preventDefault();
        var $this = e.currentTarget;
        var that = this;
        var isseriesalreadyExist = false;
        if (isseriesalreadyExist == false) {
            var charnum = $($this).closest("#chartseriescolorsmodal").attr("data-num");
            var seriesName = $($this).attr("data-name");
            var seriesColor = $($this).find(".maxtheme3").css("color");
            var seriesul = $("#chartseriescolorsmodal[data-num=" + charnum + "] .selecteddropdownseries");
            var datanumyaxis = $($this).attr("data-numyaxis");
            var seriesli = '<li class="boc-list-item singleliitems seriescolorliitem" title="' + seriesName + '" data-name="' + seriesName + '" data-numyaxis="' + datanumyaxis + '">'
                + '<span class="fa-stack fa-sm marg-righ-5">'
                + '<i class="fa fa-circle fa-stack-2x maxtheme3" style="color:' + seriesColor + '"></i>'
                + '</span>'
                + '<span class="itemname">' + seriesName + '</span>'
                + '<span class="btn-group pull-right">'
                + '<button class="btn btn-transparent removeseriescoloritem" type="button" title='+lang["storyboard.remove"]+'>'
                + '<i class="fa fa-trash"></i>'
                + '</button>'
                + '</span>'
                + '<input type="text" class="form-control input-sm colorpicker-component" value="' + seriesColor + '"/>'
                + '</li>';
            $(seriesul).append(seriesli);

            $('.selecteddropdownseries .seriescolorliitem input.colorpicker-component').colorpicker({ format: "rgba" }).on('changeColor.colorpicker', function (event) {
                var $this = event.currentTarget;
                var dataname = $($this).parent().attr("data-name");
                $($this).parent().find(".maxtheme3").css('color', $this.value);
                //		$(".dropdownseries .sereiescoloritem[data-name='"+dataname+"']").find(".maxtheme3").css('color',$this.value);
            });
        }
        $('.removeseriescoloritem').click(function (e) {
            that.removeseriescoloritem(e);
        });
    }
    removeseriescoloritem = (e) => {
        e.preventDefault();
        var $this = e.currentTarget;
        $($this).parent().parent().remove();
    }
    search = (e) => {
        var $this = e.currentTarget;
        /*Delay function for to wait until user stops typing*/
        var delay = (function () {
            var timer = 0;
            return function (callback, ms) {
                clearTimeout(timer);
                timer = setTimeout(callback, ms);
            };
        })();
        /*Delay function for to wait until user stops typing*/
        $(".form-control").keyup(function () {
            var valThis = $(this).val().toLowerCase();
            var searchin = $(this).parent().parent();
            delay(function () {
                $(searchin).find(".sereiescoloritem").each(function () {
                    var text = $(this).text().toLowerCase();
                    (text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
                });
            }, 400);
        });
    }
    applychartseriescolors = (e) => {
        e.preventDefault();
        var $this = e.currentTarget;
        var chartno = $($this).attr("data-num");
        var data = $("#chartseriescolorsmodal[data-num=" + chartno + "] .selecteddropdownseries>li");
        var chartposition = $(".fullscreenmain[data-num=" + chartno + "]").length
        if (chartposition > 0) {
            sessionStorage.setItem("pos", "max")
        }
        if (data.length > 0) {
            var customseriescolors = [];
            _.each(data, function (i, k) {
                var iname = $(i).attr("data-name");
                var icolor = $(i).find(".colorpicker-component").val();
                var uniqueid = $(i).attr("data-numyaxis") != undefined ? $(i).attr("data-numyaxis") : k;
                customseriescolors[k] = { name: iname, color: icolor, uniqueid: uniqueid };
            });
            ChartInfo.functions.updateCustomSeriesColorsDetails(chartno, customseriescolors);
        } else {
            var customseriescolors;
            ChartInfo.functions.updateCustomSeriesColorsDetails(chartno, customseriescolors);
        }
        $("#spinnerchart" + chartno + "").show()
        if ($(".singlereportview.fullscreenmain").length > 0) {
            $(".refreshsinglecharts").trigger("click");
        } else {
            var applyObj = {};
            applyObj["pos"] = undefined;
            applyObj["currentchartnum"] = chartno;
            applyObj["applyattributes"] = "applyattributes";
            applyObj["dateHistogramInterval"] = undefined;
            applyObj["calculation"] = undefined;
            applyObj["isExcludeorIncludeFilters"] = undefined;
            applyObj["isChartSummaryData"] = undefined;
            applyObj["selecteddobycolumns"] = undefined;
            applyObj["filterArray"] = [];
            applyObj["navigatefrom"] = "drilldownview";
            applyObj["isCustomparameterFilter"] = undefined;
            applyObj["tableInfo"] = undefined;
            PubSub.publish('applyfiltesandredrawchart', applyObj);
            var acd = {};
            acd["element"] = $this;
            acd["pos"] = sessionStorage.getItem("pos");
            PubSub.publish('adjustchartheight', acd);
        }
        var isDrillDown = false;
        _.each(ChartInfo.functions.getAllChartDetails(), function (data, index) {
            if (sessionStorage.getItem("drilldown" + index) != undefined) {
                sessionStorage.removeItem("drilldown" + index);
                isDrillDown = true;
            }
        });
        if (isDrillDown && $("#drilldownallchartsinstoryboard").prop("checked") == true) {
            $(".refreshcharts").trigger("click");
        }
        else if (isDrillDown) {
            $(".refreshsinglecharts").trigger("click");
        }
        $(".clonetile").removeClass("disabled");
        sessionStorage.setItem("changesdone", 1);
        this.closeModal();
    }
}