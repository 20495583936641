import React, { Component } from "react";
import SendRequest from '../SendRequest';
import { getMessage } from '../js/helpers/utils/BirdMessage';
import { lang } from "../js/helpers/utils/languages/language";
const $ =window.$;

export default class SaveChangePasswordView extends Component {
  constructor(props) {
    super(props);
    var serialized = $('.changepasswordform').serializeArray();
    var s = '';
    var data = {};
    for (s in serialized) {
        data[serialized[s]['name']] = serialized[s]['value']
    }
    var result = JSON.stringify(data);
    var finaldata = JSON.parse(result);
    this.validatePassword(finaldata.oldpassword,finaldata.newpassword,finaldata.repeatnewpasssword,"","","","","","");
}
validatePassword(currentPwd,newPwd,confrmPwd,securityqn1,answer1,securityqn2,answer2,securityqn3,answer3) {
	var formdata = new FormData();
    formdata.append("newPassword", newPwd);
	formdata.append("currentPassword", currentPwd);
	formdata.append("securityQn1", securityqn1);
	formdata.append("answer1", answer1);
	formdata.append("securityQn2", securityqn2);
	formdata.append("answer2", answer2);
	formdata.append("securityQn3", securityqn3);
    formdata.append("answer3", answer3);
	
	let requestHeaders = {
		method: "post",
		headers: new Headers({
			"Content-Type": "application/json"
		})
	};
	SendRequest({
		url: "changeusersettings",
		queryString: "",
		body:formdata,
		sucessFunction: (response) => {	
			//var result=JSON.parse(data);
			if(response.hasOwnProperty('error')){
                $(".spinner").hide();
                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('hide');
                $('.birdmessage h2').empty().append('Error : ');
                $('.birdmessage h2').append('<span class="errorcode">');
                $('.birdmessage .errorcode').empty().append(response.errorCode);
                $('.birdmessage-info').empty().text(response.error);
                $('.details').addClass('show');
                $('.messagedetails xmp').empty().append(response.errorTrace)
                /*$('#main').prepend('<div class="alert alert-danger birderror alert-dismissable affix-top" style="margin:0 auto;width: 60%;top: 44px;position: fixed;z-index: 1;left: 20%;">'+response.error+'</div>');
                    setTimeout(function() {
                        $('.birderror').remove();	
                    }, 3000);*/
            }else if(response.success=='invalid'){
                 if(response.message!=undefined &&  response.message!=null){
                         $(".reenterPassword").hide();
                        $(".invalidpassword").show();
                        $(".invalidpassword").text(response.message[0]);
                        setTimeout(function() {
                            $(".invalidpassword").hide();
                        }, 6000);
                 }else{
                     $(".oldPassword").show();
                        setTimeout(function() {
                            $(".oldPassword").hide();
                        }, 3000);
                 }	
                $('.spinner').hide();
            }else if(response.success=='SSO'){
                $(".spinner").hide();
                $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-infor').addClass('birdmessage-warning');
                $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-info-circle').addClass('fa-exclamation-triangle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Warning');
                $('.birdmessage-info').empty().text(getMessage('BirdWarning117'));
                return;
            }else{
                setTimeout(function(){
                    $("#changepasswordmodel").remove();
                    $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                    $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('show');
                    $('.details').addClass('hide');
                    $('.birdmessage h2').empty().append('Success');
                    $('.birdmessage-info').empty().text(getMessage('BirdSuccess38'));
                    $('.birdmessage-center').addClass("message-top-in").show();
                },2000)
                setTimeout(function() {
                    $('#main').remove();
                    let requestHeaders1 = {
                        method: "get",
                        headers: new Headers({
                          "Content-Type": "application/json"
                        })
                      };
                      SendRequest({
                        url: "logoutbird",
                        queryString: "",
                        sucessFunction: (response) => {
                          sessionStorage.removeItem("accessToken");
                          window.localStorage.clear();
                          window.location.replace("/")
                          if(response.hasOwnProperty('error')){
                            $(".spinner").hide();
                            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                            $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                            $('.birdmessagebuttons').removeClass('show');
                            $('.birdmessagebuttons').addClass('hide');
                            $('.birdmessage-center').addClass("message-top-in");
                            $('.details').removeClass('hide');
                            $('.birdmessage h2').empty().append('Error : ');
                            $('.birdmessage h2').append('<span class="errorcode">');
                            $('.birdmessage .errorcode').empty().append(response.errorCode);
                            $('.birdmessage-info').empty().text(response.error);
                            $('.details').addClass('show');
                            $('.messagedetails xmp').empty().append(response.errorTrace)
                            /*$('#main').prepend('<div class="alert alert-danger birderror alert-dismissable affix-top" style="margin:0 auto;width: 60%;top: 44px;position: fixed;z-index: 1;left: 20%;">'+response.error+'</div>');
                                setTimeout(function() {
                                    $('.birderror').remove();	
                                }, 3000);*/
                        }else{
                       // location.reload();
                        $('.spinner').hide();
                        }
                        },
                        rejectFunction: () => { },
                        headers: requestHeaders1,
                      })
                   
                }, 3000);

            }
		},
			rejectFunction: () => { 
				$("#spinner").hide();
				alert(lang["user.header.pwdchangefail"]);
				
			},
  headers: requestHeaders,
})
	
}
    render(){
return(
    <div></div>
)
    }
}