import React from 'react';
import _ from 'lodash';
import $ from 'jquery';
import Highcharts from 'highcharts/highmaps'
import HighchartsReact from 'highcharts-react-official'
import PubSub from 'pubsub-js'
import drilldow from "highcharts/modules/drilldown";
import dataModule from "highcharts/modules/data";
import sankeyModule from 'highcharts/modules/sankey';
import * as ChartInfo from "../helpers/utils/chartinfo";
import * as ChartUtil from "../../js/helpers/utils/chartutil";
import { whitelableMap } from "../helpers/utils/whitelable";
import { getMessage } from '../helpers/utils/BirdMessage';
import HC_exporting from 'highcharts/modules/exporting'
import ReactDOM from "react-dom";

sankeyModule(Highcharts);
HC_exporting(Highcharts);
var charts = {};
export default class SankeyChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.chartsizeevent = PubSub.subscribe('adjustchartsize', this.adjustchartsize.bind(this));
    }
    componentDidMount() {
        this.modifyChartHeightOnResize();
    }
    componentWillUnmount() {
        PubSub.unsubscribe(this.chartsizeevent)
    }
    adjustchartsize(msg, data) {
        var chartnumber = this.props.chartid.replace("chart", "");
        if (data == chartnumber) {
            this.modifyChartHeightOnResize();
        }
    }
    modifyChartHeightOnResize() {
        var that = this.state;
        var compos = {};
        var offsetheight = document.querySelector(".maxanim1[data-num='" + that.chartnumber + "']").offsetHeight;
        var offsetwidth = document.querySelector(".maxanim1[data-num='" + that.chartnumber + "']").offsetWidth - 2;
        offsetheight = offsetheight - 40;
        const component = this.refs.chartComponent;
        const divcomponent = this.refs.chartparent;
        compos["chartComponent"] = this.refs.chartComponent;
        compos["chartparent"] = this.refs.chartparent;
        ChartInfo.functions.setChartComponents(that.chartnumber, compos);
        $("#chart"+that.chartnumber).height(offsetheight + "px");
        divcomponent.style.height = offsetheight + "px !important";
        divcomponent.style.width = offsetwidth + "px";
        component.container.current.style.height = "100%";
        component.container.current.style.width = "100%";
        component.chart.reflow();
    }
    render() {
        var data = this.props.data, xaxisname = this.props.xaxisname, xaxisColumnName = this.props.xaxisColumnName, xaxisDataType = this.props.xaxisDataType,
            yaxisnameArray = this.props.yaxisnameArray, chartid = this.props.chartid, chartType = this.props.chartType, legend = this.props.legend,
            position = this.props.position, features = this.props.features, isSharedReport = this.props.isSharedReport, colorThemeIndex = this.props.colorThemeIndex,
            chartSettings = this.props.chartSettings, tooltipNameArray = this.props.tooltipNameArray;
        
        var that = this.state;
        //  charts = {};
        that.chartid = chartid;  
        if(charts!=undefined && chartid!=undefined && charts[chartid]!=undefined &&  charts[chartid].refs!=undefined &&  charts[chartid].refs.chartComponent!=undefined &&  charts[chartid].refs.chartComponent.chart!=undefined){
            charts[chartid].refs.chartComponent.chart.destroy();
        }  
        var chartnumber = chartid.replace("chart", "");
        if ($('.tileview[data-num=' + chartnumber + ']').length === 1) {
            var setheight = $('.tileview[data-num=' + chartnumber + ']').height() - 38;
            $("#chart" + chartnumber).height(setheight);
        }
        that.chartnumber = chartnumber;
        var colorThemesArray = ChartInfo.functions.getColorThemes();
        var bgcolor = $('.maxanim7[data-num=' + chartnumber + ']').css('background-color');
        if (bgcolor == undefined || bgcolor === 'rgba(0, 0, 0, 0)') { bgcolor = 'rgba(255, 255, 255, 1)' }
        var isExportChartEnable = false;
        isExportChartEnable = ChartUtil.checkExportChartEnable(features, position, isSharedReport);
        if (data != undefined && data.length == 0) {
            $(".spinner").hide();
            $(".gridspinner").hide();
            $("#" + chartid).html('<div class="nodata">No data to display</div>')
            // $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
            // $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
            // $('.birdmessagebuttons').removeClass('show');
            // $('.birdmessagebuttons').addClass('hide');
            // $('.birdmessage-center').addClass("message-top-in");
            // $('.details').removeClass('show');
            // $('.details').addClass('hide');
            // $('.birdmessage h2').empty().append('Information');
            // $('.birdmessage-info').empty().text(getMessage('BirdInformation53'));
            return;
        }
        sessionStorage.setItem('pos', position);
        var chartnumber = chartid.replace("chart", "");
        //This is to remove previous chart from DOM
        //this.unbindEvents(chartnumber);
        var result = {};
        result["links"] = data[0];
        result["nodes"] = data[1];
        if (data.length > 2) {
            _.each(data, function (i, j) {
                if (j & 1 == 1 && j > 1) {
                    result["links"] = result["links"].concat(data[j - 1]);
                    result["nodes"] = result["nodes"].concat(data[j]);
                }
            });
        }
        if (data.length > 2) {//To Fix nodes issue when more than two y-axis items selected
            var totalnodes = [];
            $.each(result["nodes"], function (i, e) {
                var matchingItems = $.grep(totalnodes, function (item) {
                    return item.name === e.name;
                });
                if (matchingItems.length === 0) {
                    totalnodes.push(e);
                }
            });
            result["nodes"] = totalnodes;
        }
        var resultArray = [];
        resultArray.push(result);
        /*Define its a sankey chart for CSS Settings*/
        $("#" + chartid).addClass('sankeychart');
        //$("#" + chartid).empty();
        /*Set Chart height to fix resize issues */

        // if ($('.tileview[data-num=' + chartnumber + ']').length === 1) {
        //     var setheight = $('.tileview[data-num=' + chartnumber + ']').height() - 42;
        //     $("#chart" + chartnumber).height(setheight);
        // } else {

        // }
        // $("#" + chartid + " svg").remove();
        // var width = $("#" + chartid).width();
        // var height = $("#" + chartid).height() - 20;
        // var margin = {
        //     top: 1,
        //     right: 1,
        //     bottom: 6,
        //     left: 1
        // },
        //     width = width - margin.left - margin.right,
        //     height = height - margin.top - margin.bottom;
        // var formatNumber = d3.format(",.0f"),
        //     format = function (d) {
        //         return formatNumber(d) + " ";
        //     },
        //     color = d3.scaleOrdinal(colorThemesArray[colorThemeIndex]);
        // var zoom = d3.zoom()
        //     .scaleExtent([1, 10])
        //     .on("zoom", zoomed);

        // function zoomed() {
        //     svg.attr("transform", "translate(" + d3.event.translate + ")scale(" + d3.event.scale + ")");
        // }
        // var svg = d3.select("#" + chartid).append("svg")
        //     .attr("width", width + margin.left + margin.right)
        //     .attr("height", height + margin.top + margin.bottom)
        //     .append("g")
        //     //.call(zoom)
        //     .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        // var Sankey = sankey()
        //     .nodeWidth(15)
        //     .nodePadding(10)
        //     .size([width, height]);

        // var path = sankey().link();
        // _.each(resultArray, function (graph) {

        //     var nodeMap = {};
        //     graph.nodes.forEach(function (x) { nodeMap[x.name] = x; });
        //     graph.links = graph.links.map(function (x) {
        //         return {
        //             source: nodeMap[x.source],
        //             target: nodeMap[x.target],
        //             value: x.value
        //         };
        //     });

        //     sankey
        //         .nodes(graph.nodes)
        //         .links(graph.links)
        //         .layout(32);

        //     // add in the links
        //     var link = svg.append("g").selectAll(".link")
        //         .data(graph.links)
        //         .enter().append("path")
        //         .attr("class", "link")
        //         .attr("d", path)
        //         .style("stroke-width", function (d) { return Math.max(1, d.dy); })
        //         .sort(function (a, b) { return b.dy - a.dy; });

        //     // add the link titles
        //     link.append("title")
        //         .text(function (d) {
        //             return d.source.name + ' → ' + d.target.name + ': ' + format(d.value)
        //         });

        //     // add in the nodes
        //     var node = svg.append("g").selectAll(".node")
        //         .data(graph.nodes)
        //         .enter().append("g")
        //         .attr("class", "node")
        //         .attr("transform", function (d) {
        //             return "translate(" + d.x + "," + d.y + ")";
        //         })
        //         .call(d3.drag()
        //             .subject(function (d) {
        //                 return d;
        //             })
        //             .on("start", function () {
        //                 this.parentNode.appendChild(this);
        //             })
        //             .on("drag", dragmove));

        //     // add the rectangles for the nodes
        //     node.append("rect")
        //         .attr("height", function (d) { return d.dy; })
        //         .attr("width", sankey.nodeWidth())
        //         .style("fill", function (d) {
        //             return d.color = color(d.name.replace(/ .*/, ""));
        //         })
        //         .style("stroke", function (d) {
        //             return d3.rgb(d.color).darker(2);
        //         })
        //         .append("title")
        //         .text(function (d) {
        //             return d.name + "\n" + format(d.value);
        //         });

        //     // add in the title for the nodes
        //     if ($(window).width() < 480) {
        //         node.append("text")
        //             .attr("x", -3)
        //             .attr("y", function (d) { return d.dy / 2.5; })
        //             .attr("dy", "1.25em")
        //             .attr("text-anchor", "end")
        //             .attr("transform", null)
        //             .text(function (d) { return d.name; })
        //             .filter(function (d) { return d.x < width / 1.5; })
        //             .attr("dy", ".35em")
        //             .attr("x", 3 + sankey.nodeWidth())
        //             .attr("text-anchor", "start");
        //     } else {
        //         node.append("text")
        //             .attr("x", -6)
        //             .attr("y", function (d) { return d.dy / 2; })
        //             .attr("dy", ".35em")
        //             .attr("text-anchor", "end")
        //             .attr("transform", null)
        //             .text(function (d) { return d.name; })
        //             .filter(function (d) { return d.x < width / 2; })
        //             .attr("x", 6 + sankey.nodeWidth())
        //             .attr("text-anchor", "start");
        //     }
        //     // the function for moving the nodes
        //     function dragmove(d) {
        //         d3.select(this).attr("transform",
        //             "translate(" + (
        //                 d.x = Math.max(0, Math.min(width - d.dx, d3.event.x))
        //             ) + "," + (
        //                 d.y = Math.max(0, Math.min(height - d.dy, d3.event.y))
        //             ) + ")");
        //         sankey.relayout();
        //         link.attr("d", path);
        //     }
        // });

        // if ($(".singlereportview.fullscreenmain").length === 1) {
        //     $(window).on("resize", function () {
        //         $(".refreshsinglecharts").click();
        //     });
        // }
        var newdata = [];
        _.each(result.links,function(i){
            var singledata = [];
            singledata.push(i.source,i.target,JSON.parse(i.value))
            newdata.push(singledata);
        })
        var options = {
            chart:{
                renderTo:chartid,
                spacingTop:23
            },
            lang: {
                drillUpText: '\u25c1 Back',
                contextButtonTitle: 'Download',
                decimalPoint: '.',
                thousandsSep: ',',
                BacktoMap_key: 'Back to Map'
            },
            title: {
                text: ''
            },
            series: [{
                keys: ['from', 'to', 'weight'],
                data: newdata,
                type: 'sankey',
               // name: 'Sankey'
            }],
            colors: colorThemesArray[colorThemeIndex],
            plotOptions: {
                series: {
                    animation:false,
                    dataLabels: {
                        enabled: true,
                    }
                }
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false,
                buttons: {
                    contextButton: {
                        y: -23,
                        symbol: 'download',
                        symbolFill:"#fff",
                        symbolStrokeWidth:0,
                        theme:{
                            fill:'#ffffff',
                            padding:5,
                            stroke:'none',
                            cursor:'pointer'
                        },
                        menuItems: [{
                            textKey: 'printChart',
                            onclick: function () {
                                //console.log("printchart");
                                var chartId = this.userOptions.chart.renderTo;
                                var reportId = this.userOptions.chart.reportId;
                                if (chartId == "nlpchart") {
                                    var msg = "NLP chart printed";

                                } else {
                                    var msg = "Chart printed"
                                }
                                if (reportId != undefined) {
                                    ChartUtil.auditMessage(reportId, msg);
                                }
                                this.print();
                            }
                        }, {
                            separator: true
                        }, {
                            textKey: 'downloadPNG',
                            onclick: function () {
                                //console.log("downloadPNG");
                                var chartId = this.userOptions.chart.renderTo;
                                var reportId = this.userOptions.chart.reportId;
                                if (chartId == "nlpchart") {
                                    var msg = "NLP chart downloaded in PNG format";
                                } else {
                                    var msg = "Chart downloaded in PNG format"
                                }
                                if (reportId != undefined) {
                                    ChartUtil.auditMessage(reportId, msg);
                                }
                                this.exportChart();

                            }
                        }, {
                            textKey: 'downloadJPEG',
                            onclick: function () {
                                //console.log("downloadJPEG");
                                var chartId = this.userOptions.chart.renderTo;
                                var reportId = this.userOptions.chart.reportId;
                                if (chartId == "nlpchart") {
                                    var msg = "NLP chart downloaded in JPEG format";
                                } else {
                                    var msg = "Chart downloaded in JPEG format";
                                }
                                if (reportId != undefined) {
                                    ChartUtil.auditMessage(reportId, msg);
                                }
                                this.exportChart({
                                    type: 'image/jpeg'
                                });
                            }
                        }, {
                            separator: true
                        }, {
                            textKey: 'downloadPDF',
                            onclick: function () {
                                //console.log("downloadPDF");
                                var chartId = this.userOptions.chart.renderTo;
                                var reportId = this.userOptions.chart.reportId;
                                if (chartId == "nlpchart") {
                                    var msg = "NLP chart downloaded in PDF format";
                                } else {
                                    var msg = "Chart downloaded in PDF format";
                                }
                                if (reportId != undefined) {
                                    ChartUtil.auditMessage(reportId, msg);
                                }
                                this.exportChart({
                                    type: 'application/pdf'
                                });
                            }
                        }, {
                            textKey: 'downloadSVG',
                            onclick: function () {
                                //console.log("downloadSVG");
                                var chartId = this.userOptions.chart.renderTo;
                                var reportId = this.userOptions.chart.reportId;
                                if (chartId == "nlpchart") {
                                    var msg = "NLP chart downloaded in SVG format";
                                } else {
                                    var msg = "Chart downloaded in SVG format";
                                }
                                if (reportId != undefined) {
                                    ChartUtil.auditMessage(reportId, msg);
                                }
                                this.exportChart({
                                    type: 'image/svg+xml'
                                });
                            }
                        }]

                    }
                },
                chartOptions: {
                    chart: {
                        width: 800,
                        backgroundColor: bgcolor
                    },
                    xAxis: {
                        title: {
                            text: xaxisname,
                            style: {
                                fontSize: '6px'
                            }
                        },
                        labels: {
                            style: {
                                fontSize: '6px',
                                maxWidth: '8px'
                            }
                        }
                    },
                    yAxis: {
                        title: {
                            style: {
                                fontSize: '6px'
                            }
                        },
                        labels: {
                            style: {
                                fontSize: '6px',
                            }
                        }
                    },
                    legend: {
                        itemStyle: {
                            font: '9px ' + whitelableMap["fontfamily"],
                            fontWeight: 'normal',
                        }
                    }
                }
            },
        }
        
        /*-------Chart Settings------*/
        //options.exporting.enabled = true;
        if (isExportChartEnable) {
            options.exporting.enabled = true;
        }
        if (chartSettings['dataLabel'] == true) {
            options.plotOptions.series.dataLabels.enabled = true;
        } else {
            options.plotOptions.series.dataLabels.enabled = false;
        }
        options.plotOptions.series.dataLabels.enabled = true;
        var chart = this;
        charts[chartid] = chart;

        Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
            var path = [
                // Arrow stem
                'M', x + w * 0.5, y,
                'L', x + w * 0.5, y + h * 0.7,
                // Arrow head
                'M', x + w * 0.3, y + h * 0.5,
                'L', x + w * 0.5, y + h * 0.7,
                'L', x + w * 0.7, y + h * 0.5,
                // Box
                'M', x, y + h * 0.9,
                'L', x, y + h,
                'L', x + w, y + h,
                'L', x + w, y + h * 0.9
            ];
            return path;
        };

        return (
            <div style={{ height: "360px" }} ref="chartparent" >
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                    ref="chartComponent"
                />
            </div>
        );
    }
}