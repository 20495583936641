import React from 'react';
import ReactDOM from "react-dom";
import _ from 'underscore';
import { getMessage } from '../js/helpers/utils/BirdMessage';
import * as ChartInfo from '../js/helpers/utils/chartinfo';
import { lang } from "../js/helpers/utils/languages/language";
import SendRequest from '../SendRequest';
import NLPReportSearchTemplate from "./nlpsearchview.jsx";
import MetricChart from '../js/charts/metric'
import BarChart from "../js/charts/bar";
import TimeseriesChart from "../js/charts/timeseries";
import BasicPieChart from '../js/charts/basicPie';
import * as ChartUtils from "../js/helpers/utils/chartutil";
import BootStrapTable from "../js/charts/bootstraptable";
import SummaryView from "../js/charts/summaryview";
import twbsPagination from 'twbs-pagination';
import ReportBody from './singlereportbody';
const $ = window.$;
export default class NLPReportView extends React.Component {
    constructor(props) {
        super(props);
        this.state = props.propsVal;
        this.state.dimensions = this.state.reportcolumns;
        this.state.storedSearchText = this.props.storedSearchText
        this.render();
    }
    formatquery = (e) => {
        var that = this;
        if (e == "fromtyping") {
            var pastedText = $('#birdintelligentsearch').text().trim();
        } else {
            var pastedText = (e.originalEvent || e).clipboardData.getData('text/plain');
        }
        var finalText = pastedText;
        _.each(that.state.currentData, function (item) {
            var word = item.columnDisplayName;
            var type = item.type;
            if (type == undefined) { type = item.tableDisplayType }
            var color = "#2737a3";
            if (type == "number") { color = "#005aff" } else if (type == "string") { color = "#b628cb" } else if (type == "date" || type == "datetime") { color = "#02b328" }
            var rgxp = new RegExp('(?<![\w\-\\d])' + word + '(?![\w\d]|[^<]*>*<\/)', 'gmi');
            var repl = '<span contenteditable="false" class="" displayname="' + word + '" columnname="' + item.columnName + '" style="color:' + color + '">' + word + '</span>';
            finalText = finalText.replace(rgxp, repl);
        });
        var spacer = new RegExp("</span> ", 'g');
        finalText = finalText.replace(spacer, " </span>");
        pastedText = pastedText.replace(/  +/g, ' ');
        setTimeout(function () {
            $('#birdintelligentsearch').html(finalText);
            setTimeout(function () {
                $.fn.focusEnd = function () {
                    $(this).focus();
                    var tmp = $('<span />').appendTo($(this)),
                        node = tmp.get(0),
                        range = null,
                        sel = null;

                    if (document.selection) {
                        range = document.body.createTextRange();
                        range.moveToElementText(node);
                        range.select();
                    } else if (window.getSelection) {
                        range = document.createRange();
                        range.selectNode(node);
                        sel = window.getSelection();
                        sel.removeAllRanges();
                        sel.addRange(range);
                    }
                    tmp.remove();
                    return this;
                }
                $('#birdintelligentsearch').focusEnd();
            }, 0);
            $('#clickclearbutton').removeClass('hide');
        }, 0);
    }
    clearQuery = () => {
        $('#clickclearbutton').addClass('hide');
        $("#birdintelligentsearch").empty();
    }
    textAreaAutoComplete(response) {
        var that = this;
        var matchedAttributeList = [];
        var currentSearch = $("#birdintelligentsearch").text();
        that.state.currentData = response;
        var columnDisplayNameMap = {};
        var columnDisplayTypeMap = {};
        var columnDisplaynameArray = [];
        var multifactentities = [];
        var multifact = false;

        _.each(response, function (mitem) {
            columnDisplayNameMap[mitem["columnDisplayName"]] = mitem["columnName"];
            columnDisplayTypeMap[mitem["columnDisplayName"]] = mitem["tableDisplayType"];
            columnDisplaynameArray.push(mitem["columnDisplayName"]);
        });
        var words = [];

        var multifact = (that.props.multifact == 'true');
        var multifactentities = (that.props.multifact == 'true') ? JSON.parse(that.props.multifactentities) : that.props.multifactentities;
        var facttables = that.props.facttables;
        var displayNameMap = columnDisplayNameMap;
        $('#birdintelligentsearch').on("mousedown change", function () {
            $('.customfieldlist:empty').remove();
        });
        $('#birdintelligentsearch').empty();
        $('#birdintelligentsearch').textcomplete('destroy');
        $('#birdintelligentsearch').textcomplete([
            {
                words: words,
                match: /\b(\w{1,}\.{0,1}\w{0,})$/,
                index: 1,
                search: function (term, callback) {
                    if (multifact) {
                        var allowedTableNames = [];
                        var previoustablename;
                        _.each($('.customfieldlist'), function (elemtnt) {
                            if ($(elemtnt).not(':empty').length > 0) {
                                var columnName = $(elemtnt).attr('columnname');
                                var tableName = columnName.split(".")[0];
                                previoustablename = tableName;
                                allowedTableNames = multifactentities[tableName];
                                allowedTableNames.push(tableName);
                            }
                        });

                        if (previoustablename != undefined && facttables.indexOf(previoustablename) != -1) {
                            _.each(facttables, function (ft) {
                                if (ft != previoustablename) {
                                    _.each(allowedTableNames, function (allowed) {
                                        var index = allowedTableNames.indexOf(ft);
                                        if (index > -1) {
                                            allowedTableNames.splice(index, 1);
                                        }
                                    });
                                }
                            });
                        }

                        if (allowedTableNames.length > 0) {
                            var allowedColumnNames = [];
                            _.each(columnDisplaynameArray, function (columnDisplayName) {
                                var actualName = displayNameMap[columnDisplayName];
                                var actualTableName = actualName.split(".")[0];

                                if (allowedTableNames.indexOf(actualTableName) >= 0) {
                                    allowedColumnNames.push(columnDisplayName);
                                }
                            });
                            var customparameters = ChartInfo.functions.getCustomParameter();
                            _.each(customparameters, function (cp, i) {
                                var cpname = customparameters[i].parametername;
                                allowedColumnNames.push(cpname)
                            })
                            this.words = words;
                            this.words = this.words.concat(allowedColumnNames.sort());
                        }
                        else {
                            this.words = this.words.concat(columnDisplaynameArray.sort());
                        }

                    }
                    else {
                        this.words = this.words.concat(columnDisplaynameArray.sort());
                    }
                    callback($.map(this.words.sort(), function (word) {
                        return word.toLowerCase().indexOf(term.toLowerCase()) >= 0 ? word : null;
                    }));
                },
                template: function (word) {
                    if (word.indexOf('()') < 0 && word.indexOf('(,)') < 0 && word.indexOf('(,,)') < 0 && word.indexOf('(){}') < 0 && word.indexOf('{}') < 0 && word.indexOf('(,"")') < 0 && word.indexOf('(,,"")') < 0 && word.indexOf('( in )') < 0) {
                        var type = columnDisplayTypeMap[word];
                        var result = '';
                        if (type == 'number' || type == 'parameter') {
                            result = "<span class='itemtype number'>123</span>  <span class='itemname' title='" + word + "'>" + word + "</span>";
                        }
                        else if (type == 'date') {
                            result = "<span class='itemtype date'><i class='fa fa-calendar'></i></span>  <span class='itemname' title='" + word + "'>" + word + "</span>";
                        }
                        else if (type == 'day' || type == 'month') {
                            result = "<span class='itemtype date'><i class='fa fa-calendar'></i></span>  <span class='itemname' title='" + word + "'>" + word + "</span>";
                        }
                        else {
                            result = "<span class='itemtype string'>"+lang["storyboard.abc"]+"</span>  <span class='itemname' title='" + word + "'>" + word + "</span>";
                        }
                        return result;
                    } else {
                        return "<span class='fa-orange marg-righ-5'><i>f</i> <small>(x)</small></span> " + word;
                    }
                },
                replace: function (word) {
                    var tempWord = word;
                    var type = columnDisplayTypeMap[word];
                    if (word.indexOf('()') < 0 && word.indexOf('(,)') < 0 && word.indexOf('(,,)') < 0 && word.indexOf('(){}') < 0 && word.indexOf('{}') < 0 && word.indexOf("(,'')") < 0 && word.indexOf("(,,'')") < 0 && word.indexOf('( in )') < 0) {
                        var columnName = columnDisplayNameMap[word];
                        var color = "#000"
                        if (type == "number") { color = "#005aff" } else if (type == "string") { color = "#b628cb" } else if (type == "date" || type == "datetime") { color = "#02b328" }
                        tempWord = "<span contenteditable='false' class='customfieldlist' displayName = '" + word + "' columnName='" + columnName + "' style='color:" + color + "'>" + word + " </span>"
                        if (type == 'parameter') {
                            that.parametrFieldsArray.push(word);
                            tempWord = "[<span class='fa-orange customfieldparameterlist' data-word ='" + word + "'>" + word + "</span>]";
                        }
                    }
                    else {
                        if (word == "case()") {
                            var dataword = "CASE WHEN ";
                            var dataWithInbrackets = "[<span class='fa-blue'>Condition</span>] THEN  [<span class='fa-blue'>Statement</span>] ELSE [<span class='fa-blue'>Statement</span>] END";
                            tempWord = "<span class='customfieldaggrlist' data-word ='" + word + "' contenteditable=false>" + dataword + "</span>" + dataWithInbrackets + "<span></span>";
                            that.showhelp(this, word);
                        } else {
                            var dataword = word.substring(0, word.indexOf('('));
                            var dataWithInbrackets = word.substring(word.indexOf('(') + 1, word.indexOf(')'));
                            tempWord = "<span class='customfieldaggrlist' data-word ='" + word + "' contenteditable=false>" + dataword + "(</span>" + dataWithInbrackets + "<span>)</span>";
                            that.showhelp(this, word);
                        }
                    }
                    return tempWord;
                }
            }
        ], { maxCount: 50 });
    }
    render() {
        $('.is-container').remove();
        var that = this;
        var today = new Date();
        var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
        $('.singlereportview .maxanim8').after('<div id="' + dynamicdiv + '">');
        ReactDOM.render(<NLPReportSearchTemplate clearQuery={this.clearQuery} addChart={this.addChart} queryTyping={this.queryTyping} getnlpqueryresult={this.getnlpqueryresult} formatquery={this.formatquery} storedSearchText={this.state.storedSearchText}/>, document.getElementById(dynamicdiv))
        $('body').addClass('nlp-open');
        if (this.state.dimensions != undefined) {
            _.each(this.state.dimensions, function (mitem) {
                var dataType = mitem.tableDisplayType,
                    dataIcon = lang["storyboard.abc"];
                if (dataType == "number") {
                    dataIcon = "123"
                } else if (dataType == "date" || dataType == "datetime" || dataType === 'month' || dataType === 'day') {
                    dataIcon = "<i class='fa fa-calendar'></i>"
                }
                $('ul.nlp-available-columns').append('<li class="boc-list-item singleliitems" title="' + mitem.columnDisplayName + '" data-type="' + dataType + '">'
                    + '<span class="itemtype">' + dataIcon + '</span>'
                    + '<span class="itemname">' + mitem.columnDisplayName + '</span>'
                    + '</li>');
            });
        }
        this.textAreaAutoComplete(this.state.dimensions);
        $("#birdintelligentsearch").text(this.state.storedSearchText);
        that.formatquery("fromtyping");
        $("#birdintelligentsearch").keypress(function (e) {
            if (e.which == 13) {
                console.log('You pressed enter!');
                $("#clicksearchbutton").click();
                that.getnlpqueryresult(e);
            }
        });
        $(".is-close").on("click", function () {
            $('body').removeClass('nlp-open');
            $('.is-container').fadeOut('slow');
            var intelligentfilter = sessionStorage.getItem("intelligentfilter");
            if ($(".filteritem-container .filter-on").length != 0 && intelligentfilter === "1") {
                $(".refreshcharts").click();
            }
            sessionStorage.setItem("intelligentfilter", 0);
        });
        $('.is-container').fadeIn('slow');
        $(window).on("resize",function(){
            that.resizeChart()
        })
        return (
            <React.Fragment />
        )
    }
    getnlpqueryresult = (event) => {
        event.stopPropagation();
        var that = this;
        var $this = event.currentTarget;
        var searchText = $("#birdintelligentsearch").text().trim();
        $("#birdintelligentsearch span.customfieldlist>br").remove();
        if ($($this).hasClass("searchbutton") || event.keyCode === 13) {
            that.intelligencySearchString(searchText);
        }
        if (($($this).hasClass("searchbutton") || event.keyCode === 13) && $("#birdintelligentsearch").text().length > 0) {
            $(".spinner").show();
            $("#Intelligencyerror").hide();
            searchText = $("#birdintelligentsearch").text().trim();
            var x = escape(searchText);
            searchText = x.replace(/%A0/g, "%20");
            searchText = unescape(searchText);
            var columnNames = this.state.dimensions;
            var schemaId = that.props.schemaId;
            if (window.location.pathname.indexOf("importreport") > 0) {
                that.props.reportId = "" 
            }
            var reportId = that.props.reportId;
            var offset = 0;
            var rowLimit = 25;
            that.state.rowLimit = 25;
            var tableInfo;
            $(".page-size").html(rowLimit);
            var datamodelId = that.props.datamodelId

            var formdata = new FormData();
            formdata.set("columnNames", JSON.stringify(that.state.dimensions));
            formdata.set("schemaId", schemaId != undefined ? schemaId : "");
            formdata.set("reportId", reportId != undefined ? reportId : "");
            formdata.set("datamodelId", datamodelId);
            formdata.set("offset", offset);
            formdata.set("rowLimit", rowLimit);
            formdata.set("searchText", searchText);
            formdata.set("filterObject", JSON.stringify(ChartInfo.functions.getFilters()));
            let requestHeaders = {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };

            SendRequest({
                url: "getnlpreportdata",
                body: formdata,
                sucessFunction: (response) => {
                    if (response.hasOwnProperty("error")) {
                        if (response.errorCode == "CI-DI-012") {
                            $(".spinner").hide();
                            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                            $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                            $('.birdmessagebuttons').removeClass('show');
                            $('.birdmessagebuttons').addClass('hide');
                            $('.birdmessage-center').addClass("message-top-in");
                            $('.details').removeClass('hide');
                            $('.birdmessage h2').empty().append('Error : ');
                            $('.birdmessage h2').append('<span class="errorcode">');
                            $('.birdmessage .errorcode').empty().append(response.errorCode);
                            $('.birdmessage-info').empty().text(lang["storyboard.serverconnect"]);
                            $('.details').addClass('show');
                            $('.messagedetails xmp').empty().append("java.net.ConnectException:"+lang["storyboard.serverconnect"])
                            return;
                        }
                        else if (response.errorCode == "CI-DI-006") {
                            $(".spinner").hide();
                            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                            $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                            $('.birdmessagebuttons').removeClass('show');
                            $('.birdmessagebuttons').addClass('hide');
                            $('.birdmessage-center').addClass("message-top-in");
                            $('.details').removeClass('hide');
                            $('.birdmessage h2').empty().append('Error : ');
                            $('.birdmessage h2').append('<span class="errorcode">');
                            $('.birdmessage .errorcode').empty().append(response.errorCode);
                            $('.birdmessage-info').empty().text(response.message);
                            $('.details').addClass('show');
                            $('.messagedetails xmp').empty().append(response.message)
                            return;
                        }
                        else if (response.errorCode == "CI-DI-013" || response.errorCode == "ERR_9019") {
                            $(".spinner").hide();
                            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                            $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                            $('.birdmessagebuttons').removeClass('show');
                            $('.birdmessagebuttons').addClass('hide');
                            $('.birdmessage-center').addClass("message-top-in");
                            $('.details').removeClass('hide');
                            $('.birdmessage h2').empty().append('Error : ');
                            $('.birdmessage h2').append('<span class="errorcode">');
                            $('.birdmessage .errorcode').empty().append(response.errorCode);
                            $('.birdmessage-info').empty().text(response.error);
                            $('.details').addClass('show');
                            $('.messagedetails xmp').empty().append(lang["storyboard.datahandlerconnect"])
                            return;
                        }
                        else {
                            $(".spinner").hide();
                            $(".gridspinner").hide();
                            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                            $('.birdmessagebuttons').removeClass('show');
                            $('.birdmessagebuttons').addClass('hide');
                            $('.birdmessage-center').addClass("message-top-in");
                            $('.details').removeClass('show');
                            $('.details').addClass('hide');
                            $('.birdmessage h2').empty().append('Warning');
                            $('.birdmessage-info').empty().text(lang["storyboard.searchcriteria"]);
                            return false;
                        }
                    } else {
                        that.chartnumber = 0;
                        $("#nlpchart").empty();
                        $("#addintelligentchart").removeClass('hide');
                        that.state.nlpdata = response;
                        var data = JSON.parse(response['chartdata']);
                        var chartType = response['chartType'];
                        var xaxisName = response['xaxisName'];
                        var xaxisColumnName = response['xaxisColumnName'];
                        var xaxisdataType = response['xaxisdataType'];
                        var yAxisNameArray = response['yAxisNameArray'];
                        var legend = response['legend'];
                        var legendObject = {};
                        legendObject['columnName'] = "";
                        if (legend != undefined) {
                            legendObject['columnName'] = legend;
                            legendObject['displayname'] = legend;
                        }
                        var chartSettings = { 'chartTitle': true, 'legend': true, 'xAxisLabel': true, 'yAxisLabel': true, 'dataLabel': false, 'crossHairs': false, 'gridLine': true };

                        $(".intelligentsearchchart").removeClass("hidden")
                        $(".notifications-table-container").addClass("hidden")
                        if (chartType == "datatable") {
                            $("#nlpchart").empty();
                            var headers = [];
                            $(".notifications-table-container").removeClass("hidden")
                            $(".intelligentsearchchart").addClass("hidden")
                            for (var i = 0; i < data[data.length - 1]["tablecolumns"].length; i++) {
                                var columnName = data[data.length - 1]["tablecolumns"][i];
                                headers.push({
                                    id: i + "",
                                    name: columnName,
                                    field: columnName,
                                    sortable: true,
                                    removable: true,
                                    tooltip: columnName
                                });
                            }
                            var opts = {};
                            opts["columns"] = headers;
                            if (data[data.length - 1].hasOwnProperty('data')) {
                                opts["data"] = data;
                            }
                            opts['exportFileName'] = "Doby";
                            opts['autoColumnWidth'] = true;
                            opts['quickFilter'] = true;
                            opts['contextMenu'] = 'header';
                            opts['canvasFocus'] = false;
                            opts['rowHeight'] = 25;
                            var totalPages = 1;
                            var page;
                            var pageDisplay;
                            var len = data.length - 1;
                            var limit = response['limitvalue'];
                            var totalRecords = 0;
                            if (limit != "" && limit != undefined) {
                                totalRecords = parseInt(limit);
                            } else {
                                totalRecords = data[len].datacount;
                            }
                            if (pageDisplay == undefined) {
                                pageDisplay = 25;
                            }
                            var showPageList;
                            if (totalRecords > 25) {
                                showPageList = true;
                                totalPages = Math.ceil(totalRecords / pageDisplay);
                            } else {
                                totalPages = 1;
                                showPageList = false;
                            }
                            var currentpage = page;
                            if (currentpage == undefined) {
                                currentpage = 1;
                            }
                            if ($('#datatable-pagination-view').data("twbs-pagination")) {
                                $('#datatable-pagination-view').twbsPagination('destroy');
                            }
                            ChartUtils.drawBootstrapTableForNLP(headers, data);
                            $('#datatable-pagination-view').twbsPagination({
                                totalPages: totalPages,
                                visiblePages: 5,
                                startPage: currentpage,
                                first: "«",
                                prev: "‹",
                                next: "›",
                                last: "»"
                            }).on('page', function (evt, pageSelected) {

                                var tableInfo = {
                                    page: pageSelected,
                                    pageLimit: that.state.rowLimit,
                                    paginationdiv: tableInfo != undefined && tableInfo.paginationdiv != undefined ? tableInfo.paginationdiv : undefined
                                };
                                that.dataTableServerRequest(tableInfo)
                            });
                            $(".btablerowlimit1").unbind().click(function (e) {
                                var $this = e.currentTarget;
                                var pageLimit = $($this).find('a').text();
                                $(".btablerowlimit li").removeClass("active");
                                var tableInfo = {
                                    page: 1,
                                    pageLimit: pageLimit,
                                    // paginationdiv:tableInfo!= undefined &&  tableInfo.paginationdiv != undefined ? tableInfo.paginationdiv:undefined
                                };
                                that.dataTableServerRequest(tableInfo)
                            });

                        }
                        if (chartType == "bar") {
                            $(".notifications-table-container").addClass("hidden")
                            $(".intelligentsearchchart").removeClass("hidden")
                            var today = new Date();
                            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                            $("#nlpchart").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                            ReactDOM.render(<BarChart data={data} xaxisname={xaxisName} xaxisColumnName={xaxisName} xaxisDataType={xaxisdataType}
                                yaxisnameArray={yAxisNameArray} chartid={"nlpchart"} chartType={chartType} legend={legendObject} position={"min"} features={that.state.features} isSharedReport={false}
                                colorThemeIndex={0} chartSettings={chartSettings} tooltipNameArray={[]} algorithm={undefined} periodsvalue={undefined}
                                trendstats={undefined} yAxisOriginalNameArray={that.yAxisNameArray} referenceLine={undefined} iscustomstoryboard={false}
                                yAxisDataTypeArray={[]} yAxisAggArray={[]} isMLReport={undefined} columnsData={undefined} ismultifact={undefined} multifactentities={undefined} datamodelId={undefined} reportid={undefined} facttables={undefined} ></BarChart>, document.getElementById(dynamicdiv));
                            that.resizeChart();
                        } else if (chartType == "linetimeseries") {
                            $(".notifications-table-container").addClass("hidden")
                            $(".intelligentsearchchart").removeClass("hidden")
                            var today = new Date();
                            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                            $("#nlpchart").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                            ReactDOM.render(<TimeseriesChart data={data} xaxisName={xaxisName} xaxisColumnName={xaxisName} xaxisDataType={xaxisdataType}
                                yaxisnameArray={yAxisNameArray} chartId={"nlpchart"} chartType={chartType} legend={legendObject} position={"min"} features={that.state.features} isSharedReport={false}
                                colorThemeIndex={0} chartSettings={chartSettings} tooltipNameArray={[]} algorithm={undefined} periodsvalue={undefined}
                                trendstats={undefined} yAxisOriginalNameArray={that.yAxisNameArray} referenceLine={undefined} iscustomstoryboard={false}
                                yAxisDataTypeArray={[]} yAxisAggArray={[]} isMLReport={undefined} columnsData={undefined} ismultifact={undefined} multifactentities={undefined} datamodelId={undefined} reportid={undefined} facttables={undefined} />, document.getElementById(dynamicdiv));
                            that.resizeChart();
                        } else if (chartType == "pie") {
                            $(".notifications-table-container").addClass("hidden")
                            $(".intelligentsearchchart").removeClass("hidden")
                            var today = new Date();
                            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                            $("#nlpchart").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                            ReactDOM.render(<BasicPieChart data={data} xaxisname={xaxisName} xaxisColumnName={xaxisName} xaxisDataType={xaxisdataType}
                                yaxisnameArray={yAxisNameArray} chartid={"nlpchart"} chartType={chartType} legend={legendObject} position={"min"} features={that.state.features} isSharedReport={false}
                                colorThemeIndex={0} chartSettings={chartSettings} tooltipNameArray={undefined} algorithm={undefined} periodsvalue={undefined}
                                trendstats={undefined} yAxisOriginalNameArray={yAxisNameArray} referenceLine={undefined} iscustomstoryboard={false}
                                yAxisDataTypeArray={[]} yAxisAggArray={[]} isMLReport={undefined} columnsData={undefined}
                                ismultifact={undefined} multifactentities={undefined} datamodelId={undefined} reportid={undefined}
                                facttables={undefined} />, document.getElementById(dynamicdiv));
                            that.resizeChart()
                        }
                        else if (chartType == "metric") {
                            $(".notifications-table-container").addClass("hidden")
                            $(".intelligentsearchchart").removeClass("hidden")
                            var today = new Date();
                            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                            $("#nlpchart").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                            ReactDOM.render(<MetricChart data={data} xaxisname={xaxisName} xaxisColumnName={xaxisName} xaxisDataType={xaxisdataType}
                                yaxisnameArray={yAxisNameArray} chartid={"nlpchart"} chartType={chartType} legend={legendObject} position={"max"}
                                metrictype={"All"} metricdatefield={""} tileno={that.chartnumber} colorThemeIndex={0}
                                chartSettings={chartSettings} metricprefix={""} metricsuffix={""} iscustomstoryboard={false} trendstats={undefined} metricfont={undefined}
                            ></MetricChart>, document.getElementById(dynamicdiv));
                        }
                        else if (chartType == "summarytable") {

                            $(".spinner").hide();
                            var dataSet = response['chartdata'];
                            var columns = JSON.parse(dataSet).columns;
                            //var legendLength = 1;
                            this.chartnumber = 0
                            var xAxisLength = response.xcount;
                            var yAxisLength = response.ycount;
                            var legendLength = 1;
                            if (xAxisLength == 0) {
                                $(".singlereport_summarymodal").click();
                                $(".addpivotrows-error help-block marg-left-10").removeClass("hide");
                                $("#spinnerchart" + this.props.chartNumber).hide();
                                return;
                            }
                            var totalStart = xAxisLength + legendLength + 1;
                            var totalEnd = xAxisLength + yAxisLength + 1;

                            var chartNumber = this.chartnumber;
                            $('.summartable' + this.chartnumber + '').remove();
                            //   ChartUtils.unbindEvents(this.chartnumber);
                            //			$("#chart"+this.chartnumber).empty();
                            $("#nlpchart").css({ "overflow": '' });
                            $("#nlpchart").append('<div class="summartable' + this.chartnumber + ' summartytablecontainer"></div>');
                            var $tableEle = $('#summartable' + this.chartnumber + '');
                            $(".summartable" + this.chartnumber).append('<table id="summartable' + this.chartnumber + '" data-num=' + this.chartnumber + ' class="table table-striped table-bordered summarytablestyling" cellspacing="0" width="100%">');
                            var groupCol = 1;

                            var $tableEle = $('#summartable' + this.chartnumber + '');
                            var rows_rightalighn = [];
                            var rows_rightalighn_legend = [];
                            var length = yAxisLength + xAxisLength;
                            var lengthwithlegend = yAxisLength + xAxisLength + legendLength;
                            var summarytableViewdatapopup = "summarytable-viewdatapopup";
                            for (var i = 1; i <= yAxisLength; i++) {
                                rows_rightalighn.push(length--);
                                rows_rightalighn_legend.push(lengthwithlegend--);
                            }
                            //Initiate DataTable
                            if (legendLength != 0) {
                                $("#nlpchart .summartytablecontainer").addClass("summaryhasgroupby");
                                var $tableList = $tableEle.DataTable({
                                    data: JSON.parse(dataSet).data,
                                    columns: columns,
                                    "lengthMenu": [[10, 25, 50, 100], [10, 25, 50, 100]],
                                    "autoWidth": true,
                                    "retrieve": true,
                                    "paging": true,
                                    "scrollY": "100%",
                                    "scrollX": true,
                                    "dom": 'lBftrip',
                                    "buttons": [{ extend: 'csv', text: '<i class="fa fa-download"></i>', className: 'btn btn-bird btn-sm summarytableexport', titleAttr: lang["storyboard.downloadcsv"], title: lang["storyboard.summarytable"], exportOptions: { columns: ':visible' } }],
                                    "deferRender": true,
                                    "scroller": true,
                                    //"orderFixed": [[groupCol, 'asc']],
                                    "columnDefs": [{
                                        "orderable": false,
                                        "targets": [0]
                                    }, {
                                        "visible": false,
                                        "targets": [0, 1]
                                    }, {
                                        "className": "dt-body-center " + summarytableViewdatapopup + "", "targets": rows_rightalighn_legend
                                    }],
                                    "drawCallback": function (settings) {
                                        var that = this;
                                        if (settings.bSorted || settings.bFiltered) {
                                            this.$('td:first-child', {
                                                "filter": "applied"
                                            }).each(function (i) {
                                                that.fnUpdate(i + 1, this.parentNode, 0, false, false);
                                            });
                                        }

                                        var api = this.api();
                                        var rows = api.rows({
                                            page: 'current'
                                        }).nodes();
                                        var rowsData = api.rows({
                                            page: 'current'
                                        }).data();

                                        var last = null;
                                        var subTotal = new Array();
                                        var grandTotal = new Array();
                                        var groupID = -1;
                                        this.chartnumber = 0
                                        api.column(groupCol, {
                                            page: 'current'
                                        }).data().each(function (group, i) {
                                            if (last !== group) {
                                                groupID++;
                                                $(rows).eq(i).before("<tr class='groupTR' group-no='" + groupID + "' group-name='" + group + "' data-num=" + that.chartnumber + "><td colspan='" + xAxisLength + "' class='groupTitle'>" + group + "</td></tr>");
                                                last = group;
                                            }

                                            //Sub-total of each column within the same grouping
                                            var val = api.row(api.row($(rows).eq(i)).index()).data(); //Current order index
                                            $.each(val, function (colIndex, colValue) {
                                                if (typeof subTotal[groupID] == 'undefined') {
                                                    subTotal[groupID] = new Array();
                                                }
                                                if (typeof subTotal[groupID][colIndex] == 'undefined') {
                                                    subTotal[groupID][colIndex] = 0;
                                                }
                                                if (typeof grandTotal[colIndex] == 'undefined') {
                                                    grandTotal[colIndex] = 0;
                                                } if (colValue != null) {
                                                    colValue = (typeof colValue == "number") ? colValue : colValue.replace(/,/g, '');
                                                }
                                                var value = colValue ? parseFloat(colValue) : 0;
                                                subTotal[groupID][colIndex] += parseFloat(value);
                                                grandTotal[colIndex] += parseFloat(value);
                                            });
                                        });
                                        var sumArray = [];
                                        for (var a1 = totalStart; a1 <= totalEnd; a1++) {
                                            var sum = 0;
                                            api.data().each(function (val) {
                                                sum = parseFloat(sum) + parseFloat(val[a1] + "".replace(/,/g, ''))
                                            });
                                            sumArray[a1] = sum;
                                        }
                                        $('tbody').find('.groupTR[data-num=' + this.chartnumber + ']').each(function (i, v) {
                                            var rowCount = $(this).nextUntil('.groupTR').length;
                                            $(this).nextUntil('.groupTR').attr("group-no", i);//add group number to each row
                                            $(this).nextUntil('.groupTR').attr("data-num", this.chartnumber);//add chartno to each row
                                            var subTotalInfo = "";
                                            for (var a = totalStart; a <= totalEnd; a++) {
                                                if (subTotal[i][a] != undefined)
                                                    subTotalInfo += "<td class='groupTD dt-body-center'>" + subTotal[i][a].toLocaleString() + "/" + sumArray[a].toLocaleString() + "</td>";
                                            }
                                            $(this).append(subTotalInfo);
                                        });
                                    }
                                });
                                // $("#spinnerchart"+this.props.chartNumber).hide();
                            } else {
                                this.chartnumber = 0
                                $("#nlpchart" + this.chartnumber + " .summartytablecontainer").removeClass("summaryhasgroupby");
                                var appendTH = "";
                                for (var i = 0; i < columns.length - 2; i++) {
                                    appendTH += "<th class=text-right></th>"
                                }
                                var summarytableViewdatapopup = "summarytable-viewdatapopup";
                                if (this.isDirect == 1 && ChartInfo.functions.getIsStoredProcedure() == 1) {
                                    summarytableViewdatapopup = "";
                                }
                                if (this.isSharedReport == true && ChartInfo.functions.getisViewOnlyFlag() == true) {
                                    summarytableViewdatapopup = "notpermitted";
                                }
                                $('#summartable' + this.chartnumber + '').append('<tfoot class="tFooterTotal"><tr>'
                                    + '<th></th><th>Grand Total</th>' + appendTH + '</tr></tfoot>');
                                var $tableList = $tableEle.DataTable({
                                    data: JSON.parse(dataSet).data,
                                    columns: columns,
                                    "paging": true,
                                    "lengthMenu": [[10, 25, 50, 100], [10, 25, 50, 100]],
                                    "scrollY": "100%",
                                    "scrollX": true,
                                    "dom": 'lBftrip',
                                    "buttons": [{ extend: 'csv', text: '<i class="fa fa-download"></i>', className: 'btn btn-bird btn-sm summarytableexport', titleAttr: lang["storyboard.downloadcsv"], title: lang["storyboard.summarytable"], exportOptions: { columns: ':visible' } }],
                                    "initComplete": function (settings, json) {
                                        for (var i = 1; i <= yAxisLength; i++) {
                                            $('.summarytablestyling thead tr th:nth-last-child(' + i + ')').addClass('sum');
                                        }
                                        this.api().columns('.sum').every(function () {
                                            var column = this;
                                            if (column.data().length > 0) {
                                                var sum = column
                                                    .data()
                                                    .reduce(function (a, b) {
                                                        a = (a == null) ? 0 : (typeof a == "number") ? a : a.replace(/,/g, '');
                                                        b = (b == null) ? 0 : (typeof b == "number") ? b : b.replace(/,/g, '');
                                                        return parseFloat(a, 10) + parseFloat(b, 10);
                                                    });
                                                if (sum != null && sum != undefined) {
                                                    $(column.footer()).html(sum.toLocaleString());
                                                }
                                            }
                                        });
                                    },
                                    "columnDefs": [{
                                        "targets": [0],
                                        "visible": false,
                                        "searchable": false,
                                        "paging": false,
                                    }, {
                                        "className": "dt-body-right " + summarytableViewdatapopup + "", "targets": rows_rightalighn
                                    }],
                                })

                                //$("#spinnerchart"+this.props.chartNumber).hide();
                            }
                        }
                        $(".spinner").hide();
                    }
                },
                rejectFunction: response => {
                    $(".spinner").hide();
                },
                headers: requestHeaders
            });
        }
        if ($("#birdintelligentsearch").text().length > 0) {
            $('#clickclearbutton').removeClass('hide');
        } else {
            $('#clickclearbutton').addClass('hide');
        }
    }
    intelligencySearchString(searchText) {
        if (searchText == "" || searchText == undefined) {
            $('#Intelligencyerror').empty().text(getMessage('ValidationMessage6'));
            $('#Intelligencyerror').show();
            $("#addintelligentchart").addClass("hide");
            return false;
        }
    }
    resizeChart() {
        if ($(window).width() > 1200) {
            $("#nlpchart").closest(".col-xs-12").css("height","");
            if ($("#nlpchart .highcharts-container:not(.metric-container)").length > 0) {
                var compos = ChartInfo.functions.getChartComponents("nlp");
                //var width = $(".row.height-100").width() - ($(".is-search-group-container").width() + 141);
                var width = $("#nlpchart").width();
                var  height = $(".row.height-100").height() - 50;
                compos.chartComponent.chart.setSize(width,height)
            }
        } else {
            $("#nlpchart").closest(".col-xs-12").css("height","");
            if ($("#nlpchart .highcharts-container:not(.metric-container)").length > 0) {
                var compos = ChartInfo.functions.getChartComponents("nlp");
                var width = $(".row.height-100").width() - 10,
                    height = $("#nlpchart").height();
                compos.chartComponent.chart.setSize(width, height);
                $("#nlpchart").closest(".col-xs-12").css("height",height);
            }
        }
    }
    dataTableServerRequest(tableInfo) {
        $(".spinner").show();
        $("#Intelligencyerror").hide();
        var that = this
        $(".page-size").html(tableInfo.pageLimit);
        that.state.rowLimit = tableInfo.pageLimit;
        var searchText = $("#birdintelligentsearch").text().trim();
        var x = escape(searchText);
        searchText = x.replace(/%A0/g, "%20");
        searchText = unescape(searchText);
        var columnNames = this.dimensions;
        var schemaId = that.props.schemaId;
        var reportId = that.props.reportId;
        var offset = 0;
        var rowLimit = 10;
        if (tableInfo != undefined && tableInfo.pageLimit != undefined) {
            rowLimit = tableInfo.pageLimit;
        }
        if (tableInfo != undefined && tableInfo.page != undefined && tableInfo.page > 1) {
            offset = (tableInfo.page - 1) * rowLimit;
        }
        var datamodelId = that.props.datamodelId

        var formdata = new FormData();
        formdata.set("columnNames", JSON.stringify(that.state.dimensions));
        formdata.set("schemaId", schemaId != undefined ? schemaId : "");
        formdata.set("reportId", reportId != undefined ? reportId : "");
        formdata.set("datamodelId", datamodelId);
        formdata.set("offset", offset);
        formdata.set("rowLimit", rowLimit);
        formdata.set("searchText", searchText);
        formdata.set("filterObject", JSON.stringify(ChartInfo.functions.getFilters()));
        let requestHeaders = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };

        SendRequest({
            url: "getnlpreportdata",
            body: formdata,
            sucessFunction: (response) => {
                if (response.hasOwnProperty("error")) {
                    $(".spinner").hide();
                } else {
                    var data = JSON.parse(response['chartdata']);
                    that.state.nlpdata = response;
                    var chartType = response['chartType'];
                    var xaxisName = response['xaxisName'];
                    var xaxisColumnName = response['xaxisColumnName'];
                    var xaxisdataType = response['xaxisdataType'];
                    var yAxisNameArray = response['yAxisNameArray'];
                    var legend = response['legend'];
                    var legendObject = {};
                    legendObject['columnName'] = "";
                    if (legend != undefined) {
                        legendObject['columnName'] = legend;
                        legendObject['displayname'] = legend;
                    }
                    var chartSettings = { 'chartTitle': true, 'legend': true, 'xAxisLabel': true, 'yAxisLabel': true, 'dataLabel': false, 'crossHairs': false, 'gridLine': true };
                    $("#nlpchart").empty();
                    if (chartType == "datatable") {
                        var headers = [];
                        for (var i = 0; i < data[data.length - 1]["tablecolumns"].length; i++) {
                            var columnName = data[data.length - 1]["tablecolumns"][i];
                            headers.push({
                                id: i + "",
                                name: columnName,
                                field: columnName,
                                sortable: true,
                                removable: true,
                                tooltip: columnName
                            });
                        }
                        var opts = {};
                        opts["columns"] = headers;
                        if (data[data.length - 1].hasOwnProperty('data')) {
                            opts["data"] = data;
                        }
                        opts['exportFileName'] = "Doby";
                        opts['autoColumnWidth'] = true;
                        opts['quickFilter'] = true;
                        opts['contextMenu'] = 'header';
                        opts['canvasFocus'] = false;
                        opts['rowHeight'] = 25;
                        var totalPages = 1;
                        var page;
                        var pageDisplay;
                        var len = data.length - 1;
                        var limit = response['limitvalue'];
                        var totalRecords = 0;
                        if (limit != "" && limit != undefined) {
                            totalRecords = parseInt(limit);
                        } else {
                            totalRecords = data[len].datacount;
                        }
                        if (tableInfo != undefined && tableInfo.pageLimit != undefined) {
                            pageDisplay = parseInt(tableInfo.pageLimit);
                        }
                        if (pageDisplay == undefined) {
                            pageDisplay = 10;
                        }
                        var showPageList;
                        if (totalRecords > 10) {
                            showPageList = true;
                            totalPages = Math.ceil(totalRecords / pageDisplay);
                        } else {
                            totalPages = 1;
                            showPageList = false;
                        }
                        var currentpage = tableInfo.page;
                        if (currentpage == undefined) {
                            currentpage = 1;
                        }
                        if ($('#datatable-pagination-view').data("twbs-pagination")) {
                            $('#datatable-pagination-view').twbsPagination('destroy');
                        }
                        ChartUtils.drawBootstrapTableForNLP(headers, data);
                        $('#datatable-pagination-view').twbsPagination({
                            totalPages: totalPages,
                            visiblePages: 5,
                            startPage: currentpage,
                            first: "«",
                            prev: "‹",
                            next: "›",
                            last: "»"
                        }).on('page', function (evt, pageSelected) {

                            var tableInfo = {
                                page: pageSelected,
                                pageLimit: that.state.rowLimit,
                                paginationdiv: tableInfo != undefined && tableInfo.paginationdiv != undefined ? tableInfo.paginationdiv : undefined
                            };
                            that.dataTableServerRequest(tableInfo)
                        })
                    }


                    $(".spinner").hide();
                }
            },
            rejectFunction: response => {
                $(".spinner").hide();
            },
            headers: requestHeaders
        });
    }
    addChart = (event) => {
        $('body').removeClass('nlp-open');
        sessionStorage.setItem("changesdone", 1);
        var that = this;
        var filtersArray = []
        if (ChartInfo.functions.getFilters(true) != undefined && ChartInfo.functions.getFilters(true).length != 0) {
            filtersArray = JSON.parse(JSON.stringify(filtersArray)).concat(ChartInfo.functions.getFilters(true))
        }
        var details = JSON.parse(ChartInfo.functions.getChartDetails(that.props.chartnumber));
        var currentChartDetails = {}
        let searchText = $("#birdintelligentsearch").text();
        currentChartDetails['chartSettings'] = details['chartSettings'];
        var chartData = JSON.parse(that.state.nlpdata['chartdata']);
        var chartType = that.state.nlpdata['chartType'];
        var xaxisName = that.state.nlpdata['xaxisName']!=undefined?that.state.nlpdata['xaxisName']:"";
        var xaxisColumnName = that.state.nlpdata['xaxisColumnName']!=undefined? that.state.nlpdata['xaxisColumnName']:"";
        var xaxisdataType = that.state.nlpdata['xaxisdataType']!=undefined?that.state.nlpdata['xaxisdataType']:"";
        var yAxisNameArray = that.state.nlpdata['yAxisNameArray']!=undefined?that.state.nlpdata['yAxisNameArray']:[];
        var yAxisDataTypeArray = that.state.nlpdata["yAxisDataTypeArray"]!=undefined?that.state.nlpdata["yAxisDataTypeArray"]:[]
        var legend = that.state.nlpdata['legend'];
        var legendDataType = that.state.nlpdata["legendDataType"];
        var sortType = that.state.nlpdata["sortType"];
        var colLimit = that.state.nlpdata["colLimit"];
        var nlpFilters = that.state.nlpdata["nlpfilters"];
        var nlpfilterscols = that.state.nlpdata["nlpfilterscols"];
        var nlprowlimit = that.state.nlpdata["nlprowLimit"];
        var nlptableordertype = that.state.nlpdata["sortType"];
        let nlprowlimitobj = {}
        var dobyselectedcolumns = [];
        var yaxisFinalArray = [];
        var tempObj = {}
        var newChartnumber = that.props.chartnumber;
        _.each(yAxisDataTypeArray, function (val, index) {
            tempObj = {}
            tempObj["displayName"] = yAxisNameArray[index];
            tempObj["columnName"] = yAxisNameArray[index];
            tempObj["dataType"] = val.ydataType
            tempObj["aggregation"] = val.yaggType;
            tempObj["uniqueid"] = index;
            tempObj["tableName"] = "";
            tempObj["percentileValue"] = "25";
            yaxisFinalArray.push(tempObj);
        })
        var xaxisFinalArray = [];
        if (xaxisColumnName != undefined && xaxisColumnName != '') {
            tempObj = {};
            tempObj["displayName"] = xaxisName;
            tempObj["columnName"] = xaxisColumnName;
            tempObj["dataType"] = xaxisdataType;
            tempObj["xaxisidentity"] = xaxisdataType;
            tempObj["tableName"] = "";
            xaxisFinalArray.push(tempObj);
        }
        var legendObj = {};
        if (legend != undefined && legend != '') {
            tempObj = {};
            tempObj["displayName"] = legend;
            tempObj["columnName"] = legend;
            tempObj["dataType"] = legendDataType;
            tempObj["tableName"] = "";
            legendObj = tempObj;
        }
        if (chartType == "datatable") {
            chartType="doby";
            dobyselectedcolumns = JSON.parse(that.state.nlpdata["dobyselectedColumns"]);
            if(nlprowlimit!=undefined && nlprowlimit!="" && nlprowlimit!=null){
                currentChartDetails["nlprowlimit"] = nlprowlimit;
                nlprowlimitobj["nlprowLimit"]=nlprowlimit;
               }
               if(nlptableordertype!=undefined && nlptableordertype!="" && nlptableordertype!=null){
                currentChartDetails["nlptableordertype"] = nlptableordertype;
                nlprowlimitobj["nlptableordertype"] = nlptableordertype;
               }
        }
        var summarytablecolumns = [],summarytablegroupbys={},summarytablevalues=[],getsummarytableaxisfieldsarray=[];
        if(chartType == "summarytable"){
            var aggregationcolumns = JSON.parse(that.state.nlpdata["summaryaggColumns"]);
            var aggregationdisplaynames = JSON.parse(that.state.nlpdata["summaryaggregationColumns"]);
            var groupbycolumns = JSON.parse(that.state.nlpdata["summarygroupByColumns"]);
            var groupbydisplaynames = JSON.parse(that.state.nlpdata["summarygroupsbydisplaynames"]);
            let i=0;
            _.each(aggregationcolumns,function(val,index){
                summarytablevalues.push(aggregationcolumns[aggregationdisplaynames[i]])
                i++;
            })
             i=0;
            _.each(groupbycolumns,function(val,index){
                if(i!=0){
                summarytablecolumns.push(groupbycolumns[groupbydisplaynames[i]])
                }
                if(i==0){
                    summarytablegroupbys = groupbycolumns[groupbydisplaynames[i]];
                }
                i++;
            })
            let tempsummaryobj = {}
            tempsummaryobj["textcolumns"] = summarytablecolumns;
            tempsummaryobj["group"] = summarytablegroupbys;
            getsummarytableaxisfieldsarray.push(tempsummaryobj);
            currentChartDetails["summarytablevalues"] = summarytablevalues;
            currentChartDetails["summarytablecolumns"] = summarytablecolumns;
            currentChartDetails["summarytablegroupbys"] = summarytablegroupbys;
            currentChartDetails["getsummarytableaxisfieldsarray"] = getsummarytableaxisfieldsarray;
            if(nlprowlimit!=undefined && nlprowlimit!="" && nlprowlimit!=null){
                currentChartDetails["nlprowlimit"]=nlprowlimit;
                nlprowlimitobj["nlprowLimit"]=nlprowlimit;
               }
               if(nlptableordertype!=undefined && nlptableordertype!="" && nlptableordertype!=null){
                currentChartDetails["nlptableordertype"] = nlptableordertype;
                nlprowlimitobj["nlptableordertype"] = nlptableordertype;
               }
        }
        currentChartDetails["dobyselectedcolumns"] = dobyselectedcolumns;
        currentChartDetails.chartType = chartType;
        currentChartDetails['yAxis'] = yaxisFinalArray;
        currentChartDetails['xAxis'] = xaxisFinalArray;
        if (!_.isEmpty(legendObj)) {
            currentChartDetails['legend'] = legendObj;
        }
        currentChartDetails['reporttitle'] = details['reporttitle'];
        currentChartDetails['metricdatefield'] = "";
        currentChartDetails['metricdatetype'] = details['metricdatetype'];
        //currentChartDetails['metricdatefield'] = details['metricdatefield'];
        currentChartDetails["tooltip"] = [];
        currentChartDetails["combinationchartslist"] = details['combinationchartslist'];
        currentChartDetails["customSolidGauge"] = details['customSolidGauge'];;
        currentChartDetails["customfieldFilterObject"] = details['customfieldFilterObject'];;
        currentChartDetails["drillDownFilter"] = ChartInfo.functions.getSavedDrilldownAll();
        currentChartDetails["zAxis"] = [];
        currentChartDetails["colLimit"] = colLimit;
        currentChartDetails["orderType"] = sortType == 'asc' ? 'bottom' : "top";
        currentChartDetails["rankingColumnAggregation"] = yAxisDataTypeArray.length>0?yAxisDataTypeArray[0].yaggType:"";
        currentChartDetails["rankingColumn"] = yAxisNameArray.length>0?yAxisNameArray[0]:"";
        currentChartDetails["trendLine"] = "";
        currentChartDetails["searchText"] = searchText;
        currentChartDetails["datehistogramstate"] = undefined;
        currentChartDetails.chartnumber = that.props.chartnumber;
        if(nlpfilterscols!=undefined && nlpfilterscols!="" && nlpfilterscols!=null){
            currentChartDetails["nlpfiltercols"] =nlpfilterscols;
            nlprowlimitobj["nlpfilterscols"] =nlpfilterscols;
           }
           if(nlpFilters!=undefined && nlpFilters!="" && nlpFilters!=null){
            currentChartDetails["nlpFilter"] =nlpFilters;
            nlprowlimitobj["nlpfilters"] =nlpFilters;
           }
        ChartInfo.functions.updateChartDetails(that.props.chartnumber, JSON.stringify(currentChartDetails));
        ChartInfo.functions.setNLPTableRowLimitDetails(this.state.reportId,nlprowlimitobj)
        this.reportbody = new ReportBody({
            reportId: this.state.reportId,
            tabid: this.state.tabid,
            isFromStoryBook: this.state.isFromStoryBook,
            isFromTabClick: this.state.isFromTabClick,
            storyBookel: this.state.dynamicdiv,
            datamodelId: this.state.datamodelId,
            chartnumber: this.state.chartnumber,
            filterObject: ChartInfo.functions.getFilters(),
            dimensions: this.state.dimensions,
            features: this.state.features,
            schedule: this.state.schedule,
            scheduleid: this.state.scheduleid,
            date: this.state.date,
            viewcollection: this.props.collection,
            facttables: this.state.facttables,
            viewreportmodel: this.state.reportmodel,
            multifactentities: this.state.multifactentities,
            multifact: this.state.multifact
        });
        $('.is-container').fadeOut('slow');
    }
}