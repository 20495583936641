import React, { Component } from "react";
//import $ from "jquery/dist/jquery";
import SendRequest from "../SendRequest";
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import HeaderPage from "./HeaderHtml";
import NotificationBadgeView from "./notificationbadgeview";
const $ = window.$;
export default class Home extends Component {
  constructor(props) {
    // $(".spinner").show();
    super(props);
    this.state = {
      loggedInUserDetails: {},
      features:[]
    };
    let featuresString = sessionStorage.getItem("features");
    if (
      featuresString != null &&
      featuresString != undefined &&
      featuresString != ""
    ) {
      featuresString = featuresString.slice(0, 3) + featuresString.slice(4);
      let decryptedString = window.atob(featuresString);
      this.state.features = JSON.parse(decryptedString);
    }
    if (
      window.localStorage.getItem("accessToken") == null ||
      window.localStorage.getItem("accessToken") == undefined
    ) {
      window.location.replace("/");
    }
    if (
      sessionStorage.getItem("2factorauthwelcome") != null &&
      sessionStorage.getItem("2factorauthwelcome")
    ) {
      window.location.replace("/");
    }
  }
  UNSAFE_componentWillMount() {
    document.body.className = '';
    document.body.setAttribute("data-spy", "scroll");
    document.body.setAttribute("data-target", "#myScrollspy");
    document.body.classList.add("day", "datamainpage");
  }

  componentDidMount() {
    var that = this;
    let requestHeaders = {
      method: "get",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    };
    SendRequest({
      url: "getfeatures",
      queryString: "",
      sucessFunction: (features) => {
        let featuresString = window.btoa(JSON.stringify(features));
        ChartInfo.functions.setAllFeatures(features);
        featuresString = featuresString.slice(0, 3) + "a" + featuresString.slice(3);
        sessionStorage.setItem("features", featuresString);
        featuresString = featuresString.slice(0, 3) + featuresString.slice(4);
        let decryptedString = window.atob(featuresString);
        this.setState({features:JSON.parse(decryptedString)})
      },
      rejectFunction: () => {},
      headers: requestHeaders,
    });
    SendRequest({
      url: "getLoginUserName",
      queryString: "",
      sucessFunction: (response) => {
        this.setState({ loggedInUserDetails: response });
        var notificationBadgeView = new NotificationBadgeView({
          url: that.props.url,
          loggedInUserDetails:that.state.loggedInUserDetails
        });
        SendRequest({
          url: "getcurrentuserrole",
          queryString: "",
          sucessFunction: (response) => {
            this.state.currentuserrole = response.current_user_role;
            this.state.parentuserrole = response.parent_user_role;
            this.setState({ parentuserrole: response.parent_user_role });
            var userRoleArray = [];
            userRoleArray.push(response.current_user_role);
            userRoleArray.push(response.parent_user_role);
            if(response.parent_user_role === 'ROLE_Viewer'
            ||response.parent_user_role==='ROLE_Power_User'
            ||response.parent_user_role === 'ROLE_ReadOnly_User'){
              if(that.props.location==undefined && window.location.pathname.indexOf("admin")!=-1){
                 window.location.replace("/" + window.localStorage.getItem("appName") + "/welcome");
              }
              if(response.parent_user_role === "ROLE_ReadOnly_User"&&
              (that.props.location!=undefined && (that.props.location.pathname.indexOf("datahub")!=-1
              ||that.props.location.pathname.indexOf("workspace")!=-1
              ||(that.props.location.pathname.indexOf("businessmodels")==-1
              &&that.props.location.pathname.indexOf("models")!=-1)
              ||that.props.location.pathname.indexOf("businessworkspaces")!=-1))){
                that.props.history.push("/" + window.localStorage.getItem("appName") + "/welcome");
              }
              if(response.parent_user_role === 'ROLE_Viewer'&&(that.props.location!=undefined && (that.props.location.pathname.indexOf("datahub")!=-1
              ||that.props.location.pathname.indexOf("workspace")!=-1||that.props.location.pathname.indexOf("models")!=-1||
              that.props.location.pathname.indexOf("businessmodels")!=-1
              ||that.props.location.pathname.indexOf("businessworkspaces")!=-1
              ||that.props.location.pathname.indexOf("search")!=-1))){
                that.props.history.push("/" + window.localStorage.getItem("appName") + "/welcome");
              }
            }
            if(response.parent_user_role === 'ROLE_Admin'){
              if(window.location.pathname.indexOf("admin")==-1){
                window.location.replace("/" + window.localStorage.getItem("appName") + "/welcome/admin");
             }
            }
            
            ChartInfo.functions.setUserRole(userRoleArray);
            setTimeout(function (e) {
              if (
                that.state.parentuserrole != undefined &&
                that.state.parentuserrole != null &&
                (that.state.parentuserrole == "ROLE_Super_Admin" ||
                  that.state.parentuserrole == "ROLE_Client_Admin" ||
                  that.state.parentuserrole == "ROLE_Admin")
              ) {
                $("#Notifications").addClass("hide");
              }
            }, 300);
          },
          rejectFunction: () => {},
          headers: requestHeaders,
        });
      },
      rejectFunction: () => {},
      headers: requestHeaders,
    });

   
    // setTimeout(function(){$(".spinner").hide();},1500);
  }
  componentWillUnmount() {
    //   document.body.removeAttribute("data-spy");
    //   document.body.removeAttribute("data-target");
    //   document.body.removeAttribute("className");
  }
  render() {
    return (
      <HeaderPage
        {...this.props}
        parentuserrole={this.state.parentuserrole}
        features={this.state.features}
        loggedInUserDetails={this.state.loggedInUserDetails.UserName}
        url={this.props.url}
        pageType={this.props.pageType}
      />
    );
  }
}
