import React from "react";
import ReactDOM from "react-dom";
import _ from 'lodash';
import SendRequest from '../SendRequest';
import PubSub from 'pubsub-js'
import { getMessage } from '../js/helpers/utils/BirdMessage';
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import CustomFieldHierarchyTemplate from "./custommeasurehierarchymodal.jsx"
import hierarchyDropDownView from "./custommeasurehierarchyorderdropdownview"
import addMeasureToMeasureCustomHierarchyModal from './addmeasuretomeasurehierarchymodal'
import customHierarchyFieldView from './custommeasurehierarchyfieldview'
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;

export default class CustomMeasureHierarchyModalView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chartnumber: props.chartnumber,
            multifactentities: props.multifactentities,
            facttables: props.facttables,
            multifact: props.multifact,
            measure: props.measure,
        };
        this.render();
    }
    render() {
        this.state.el = ".customfieldhierarchymodalcontainer" + this.props.chartnumber;
        var that = this;
        var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(this.props.chartnumber));
        var convertedNames = JSON.parse(sessionStorage.getItem("convertedtypes"));
        var jsonObject = {};
        _.each(convertedNames, function (array) {
            jsonObject[array.columnName] = array.dataType;
        });
        var result = $.grep(ChartInfo.functions.getMeasureHierarchy(), function (e) { return e.name == that.props.columnName });
        if (result != undefined && result.length > 0) {
            this.state.oldName = result[0].name;
        }
        var parsedcustomfields = [];
        var convertedvalues = sessionStorage.getItem("convertedvalues");
        if (convertedvalues != null && convertedvalues != "null" && convertedvalues != undefined && convertedvalues.length > 0) {
            parsedcustomfields = JSON.parse(convertedvalues);
        }
        var convertedNames = JSON.parse(sessionStorage.getItem('renameMeasuresorDimensions'));
        ReactDOM.render(<CustomFieldHierarchyTemplate closeHierarchyModal={this.closeHierarchyModal} dropdownhierarchylist={this.dropdownhierarchylist}
            applycustomhierarchy={this.applycustomhierarchy} chartnumber={this.state.chartnumber} existinghierarchy={result} convertedTypes={jsonObject} convertedNames={convertedNames} measure={this.state.measure} convertedvalues={parsedcustomfields}></CustomFieldHierarchyTemplate>, document.querySelector(this.state.el));
        return (<div></div>)
    }
    closeHierarchyModal = () => {
        ReactDOM.unmountComponentAtNode(document.querySelector(this.state.el));
    }
    dropdownhierarchylist = (e) => {
        e.preventDefault();
        var that = this;
        $(".dropdowncustomhierarchylist").empty();
        $(".dropdowncustomhierarchylist").append('<li class="xs-list-item"> <input class="form-control form-control-hierarchydropdown search-image" placeholder='+lang["storyboard.search"]+' id="searchinput" type="text"></li>');
        _.each($(".attributesmeasures"), function (e) {
            var $this = e;
            if (!$($this).hasClass("customaggregatedfields") && $($this).attr("data-name") != that.state.oldName) {
                var today = new Date();
                var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                $('.dropdowncustomhierarchylist').append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
                var xAxisDropDown = new hierarchyDropDownView({
                    dynamicdiv: dynamicdiv,
                    columnDisplayName: $($this).attr('title'),
                    columnName: $($this).attr("data-name"),
                    dataType: $($this).attr("data-type"),
                    tableName: $($this).attr("table-name"),
                    multifactentities: that.state.multifactentities,
                    facttables: that.state.facttables,
                    multifact: that.state.multifact,
                    agg: "Count",//default value
                    addcustomhierarchyvalue1: that.addcustomhierarchyvalue1,
                    percentilevalue: $($this).find(".percentilebox").val()
                });
                $(".dropdowncustomhierarchylist").append($("#" + dynamicdiv));
                $('.form-control-hierarchydropdown').click(function (e) {
                    e.stopPropagation();
                });
                $('.form-control-hierarchydropdown').keyup(function (e) {
                    e.stopPropagation();
                    var $this = e.currentTarget;
                    var valThis = $($this).val().toLowerCase();
                    $(".itemname").parents(".hierarchyitem").each(function () {
                        var text = $(this).attr("title").toLowerCase();
                        (text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
                    });

                });
            }
        });
    }
    addcustomhierarchyvalue1 = (e) => {
        e.preventDefault();
        var $this = e.currentTarget;
        var isAlreadyExist = false;
        var chartno = this.props.chartnumber;
        _.each($('.customfieldhierarchymodalcontainer' + chartno + ' .customhierarchyattributes .singleliitems'), function (list) {
            if ($(list).attr("data-name") == $($this).attr("data-name")) {
                isAlreadyExist = true;
            }
        });
        if (isAlreadyExist == false) {
            var columnName = $($this).attr("data-name");
            var columnDisplayName = $($this).attr("title");
            var datatype = $($this).attr("data-type");
            var tableName = $($this).attr("table-name");
            var percentilevalue = $($this).attr("percentilevalue")
            var aggregation = $($this).attr("agg");

            var customfields = sessionStorage.getItem("convertedvalues");
            var parsedcustomfields;
            var parsedcustomfields;
            var selectedcustomfield;
            if (customfields != null && customfields != "null" && customfields != undefined && customfields.length > 0) {
                parsedcustomfields = JSON.parse(customfields);
                selectedcustomfield = $.grep(parsedcustomfields, function (e) {
                    return (e.columnName == columnName)
                });
            }

            if (selectedcustomfield != undefined && selectedcustomfield.length > 0 && selectedcustomfield != undefined) {
                //measureValues =selectedcustomfield[0];
                aggregation = selectedcustomfield[0].aggregation;
            }
            var xaxisAttrView = new addMeasureToMeasureCustomHierarchyModal({
                xaxiscolumnDisplayName: columnDisplayName,
                xaxiscolumnName: columnName,
                xaxiscolumnType: datatype,
                xaxistableName: tableName,
                agg: aggregation,
                percentilevalue: percentilevalue
            });
        }
    }
    applycustomhierarchy = (e) => {
        e.preventDefault();
        var that = this;
        this.state.close = true;
        var chartno = this.state.chartnumber;
        var columnName = $('.customfieldhierarchymodalcontainer' + chartno + ' #hierarchyname').val().replace(/([#;?&,%. +*~\':"!^$%[\]()<=>|\/@])/g, "\\$1");
        var firstElementDataType = $($('.customfieldhierarchymodalcontainer' + chartno + ' .customhierarchyattributes #itemdisplay')[0]).attr("data-type");
        if (firstElementDataType == 'custommeasurehierarchy') {
            that.state.close = false;
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(getMessage('BirdWarning65'));
        }
        else {
            if ($('.customhierarchyattributes li').length == 0 && this.state.xaxiscolumnType == 'custommeasurehierarchy') {
                that.state.close = false;
                $(".spinner").hide();
                $(".gridspinner").hide();
                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Warning');
                $('.birdmessage-info').empty().text(getMessage('BirdWarning65'));
            }
            var hierarchyname = $('.customfieldhierarchymodalcontainer' + chartno + ' #hierarchyname').val().trim().replace(/([#;?&,%. +*~\':"!^$%[\]()<=>|\/@])/g, "\\$1");
            var status = false;
            var reservedwordCheck = hierarchyname.toLowerCase();
            var reservedwordslist = JSON.parse(sessionStorage.getItem("reservedwordslist"));
            _.each(reservedwordslist, function (reservedword, j) {
                if (reservedwordCheck == reservedword) {
                    status = true
                }
            });
            if (status == true) {
                that.state.close = false;
                $(".spinner").hide();
                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Warning');
                $('.birdmessage-info').empty().text(lang["storyboard.measurehierarchy"] + "" + getMessage('BirdWarning147'));
                $(".spinner").hide();
                //$('.applyattributes').addClass("disabled");
                return;
            }
            if (this.state.oldName != hierarchyname) {
                if (!hierarchyname.trim().length > 0 || $('.attributesdimensions[title="' + columnName + '"]').length > 0
                    || $('.customhierarchyfields[title="' + columnName + '"]').length > 0 || $('.customaggregatedfields[title="' + columnName + '"]').length > 0 ||
                    $('.custommeasurehierarchyfields[title="' + columnName + '"]').length > 0 || $('.customparameterfield[title="' + columnName + '"]').length > 0
                    || $('.customrangefields[title="' + columnName + '"]').length > 0) {
                    that.state.close = false;
                    $('.help-block.customhierarchy').removeClass('hide');
                    $('.help-block.customhierarchy_li_items').addClass('hide');
                } else if (!$('.customhierarchyattributes.hierarchyattributes li').length > 0) {
                    that.state.close = false;
                    $('.help-block.customhierarchy_li_items').removeClass('hide');
                    $('.help-block.customhierarchy').addClass('hide');
                } else {
                    this.createCustomHierarchyField(e, chartno);
                }
                var existingHeirarchy = sessionStorage.getItem('customhierarchy' + this.state.chartnumber);
                var existingHeirarchyObject = JSON.parse(existingHeirarchy);
                var jsonArr = [];
                var levelMappingObject = {};
                levelMappingObject[hierarchyname] = 0;
                jsonArr.push(levelMappingObject);
                jsonArr.push([]);
                if (existingHeirarchy != null) {
                    existingHeirarchyObject.push(jsonArr);
                    sessionStorage.setItem('customhierarchy' + this.state.chartnumber, JSON.stringify(existingHeirarchyObject));
                }
            } else {
                if (!$('.customhierarchyattributes.hierarchyattributes li').length > 0) {
                    that.state.close = false;
                    $('.help-block.customhierarchy_li_items').removeClass('hide');
                    $('.help-block.customhierarchy').addClass('hide');
                    return false;
                } else {
                    this.createCustomHierarchyField(e, chartno);
                    if (this.props.multifact == "true") {
                        _.each($("ul.yaxisattributes li.singleliitems"), function (i, j) {
                            var chartData = i.dataset;
                            _.each(chartData, function (value, index) {
                                if (value == "custommeasurehierarchy") {
                                    $("ul.yaxisattributes li.singleliitems[data-type='custommeasurehierarchy']").parent().remove()
                                }
                            });
                        });
                    }
                }
            }
        }
        if (that.state.close) {
            setTimeout(function () {
                that.closeHierarchyModal();
            }, 1000);
        }
    }
    createCustomHierarchyField(e, chartno) {
        var that = this;
        var fieldNameTest = $('.customfieldhierarchymodalcontainer' + chartno + ' #hierarchyname').val();
        if ((/[!@#$%^&*()+\=\[\]{};':\\|,.<>\/?`"]/.test(fieldNameTest)) ||
            (fieldNameTest.toLowerCase().trim() == "min" || fieldNameTest.toLowerCase().trim() == "max"
                || fieldNameTest.toLowerCase().trim() == "avg" || fieldNameTest.toLowerCase().trim() == "sum"
                || fieldNameTest.toLowerCase().trim() == "count" || fieldNameTest.toLowerCase().trim() == "avgerage")) {
            that.state.close = false;
            $(".spinner").hide();
            $(".gridspinner").hide();
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Information');
            $('.birdmessage-info').empty().text(getMessage('BirdInformation46'));
            return;
        }
        var that = this;
        var jsonobj = {};
        var hierarchyListArray = [];
        var columnName = $('.customfieldhierarchymodalcontainer' + chartno + ' #hierarchyname').val().replace(/([#;?&,%.+*~\':"!^$%[\]()<=>|\/@])/g, "\\$1");
        if (ChartInfo.functions.isScriptData(columnName)) {
            that.state.close = false;
            return true;
        }
        $('.help-block.customhierarchy').addClass('hide');
        $('.help-block.customhierarchy_li_items').addClass('hide');
        var deletevalue = $.grep(ChartInfo.functions.getMeasureHierarchy(), function (e) { return e.name != that.state.oldName });
        if (that.state.oldName != undefined) {
            that.state.oldName = that.state.oldName.replace(/([#;?&,%.+*~\':"!^$%[\]()<=>|\/@])/g, "\\$1")
        }
        if (deletevalue.length >= 0) {
            $('.custommeasurehierarchyfields[title="' + this.state.oldName + '"]').remove();
            ChartInfo.functions.setMeasureHierarchy(deletevalue);
        }
        var jsonarray = $.grep(ChartInfo.functions.getMeasureHierarchy(), function (e) { return e.name != $('.customfieldhierarchymodalcontainer' + chartno + ' #hierarchyname').val() });
        if (that.state.oldName != undefined && columnName != undefined) {
            _.each(ChartInfo.functions.getMeasureHierarchy(), function (data, i) {
                _.each(data.hierarchelist, function (list, j) {
                    if (that.state.oldName == list.columnName) {
                        list.columnDisplayName = columnName;
                        list.columnName = columnName;
                    }
                })
            });
        }
        jsonobj['name'] = $('.customfieldhierarchymodalcontainer' + chartno + ' #hierarchyname').val();
        $('.custommeasurehierarchyfields[data-name="' + columnName + '"]').remove();
        $(".customfieldhierarchymodalcontainer" + chartno + ' .customhierarchyattributes #itemdisplay').each(function (k, list) {
            var jsonobjlist = {};
            jsonobjlist['columnDisplayName'] = $(list).attr('title');
            jsonobjlist["columnName"] = $(list).attr('data-name');
            jsonobjlist['tableName'] = $(list).attr('table-name');
            jsonobjlist['dataType'] = $(list).attr('data-type');
            jsonobjlist['aggregation'] = $(list).attr('agg');
            jsonobjlist['percentilevalue'] = $(list).attr('percentilevalue');
            hierarchyListArray.push(jsonobjlist);
        });
        jsonobj['hierarchelist'] = hierarchyListArray
        jsonarray.push(jsonobj);
        $(".combinationchartslistattributes").find(".singleliitems[data-name='" + that.state.oldName + "']").find("span.itemname").text(columnName);
        $(".combinationchartslistattributes").find(".singleliitems[data-name='" + that.state.oldName + "']").attr("title", columnName);
        $(".combinationchartslistattributes").find(".singleliitems[data-name='" + that.state.oldName + "']").attr("data-name", columnName);
        var today = new Date();
        var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
        $('.custommeasurehierarchydimension').append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
        var customfieldView = new customHierarchyFieldView({
            dynamicdiv: dynamicdiv,
            columnDisplayName: $('.customfieldhierarchymodalcontainer' + chartno + ' #hierarchyname').val(),
            columnName: $('.customfieldhierarchymodalcontainer' + chartno + ' #hierarchyname').val()
        });
        var fieldName = $('.customfieldhierarchymodalcontainer' + chartno + ' #hierarchyname').val();
        $(".attributescustomfields[data-name='" + that.state.oldName + "']").remove();
        var renameXaxisName = $('.xaxisattributes li[data-name="' + that.state.oldName + '"]');
        $(renameXaxisName).attr('data-name', fieldName);
        $(renameXaxisName).attr('title', fieldName);
        $(renameXaxisName).find('.itemname').text(fieldName);

        var renameXaxisName = $('.yaxisattributes li[data-name="' + that.state.oldName + '"]');
        $(renameXaxisName).attr('data-name', fieldName);
        $(renameXaxisName).attr('title', fieldName);
        $(renameXaxisName).find('.itemname').text(fieldName);
        if (that.state.oldName != undefined && that.state.oldName != fieldName) {
            $('.createcustomhierarchymeasure [data-edit="edit"], [data-name="' + that.state.oldName + '"]').attr("data-name", fieldName)
        }
        ChartInfo.functions.updateAxisName(that.state.oldName, fieldName, 'yAxis');
        $(".custommeasurehierarchydimension").append($("#" + dynamicdiv));
        $(".measurecount").text(parseInt($('.measurecount').text()) + 1);
        ChartInfo.functions.setMeasureHierarchy(jsonarray);
        $(".custommeasurehierarchy" + this.state.chartnumber + "").modal("hide");
    }
}