import React from 'react';
import ReactDOM from 'react-dom';
import PasswordPolicyTemplate from './PasswordPolicyTemplate';
import { lang} from "../../../js/helpers/utils/languages/language";
import SendRequest from '../../../SendRequest';
import {getMessage} from '../../../js/helpers/utils/BirdMessage';
const $ = window.$;

export default class PasswordPolicyView extends React.Component{
    constructor(props){
        super(props);
        this.getPasswordPolicy();
    }
    getPasswordPolicy=()=>{
    let requestHeaders = {
        method: "get",
        headers: new Headers({
            "Content-Type": "application/json"
        })
    };
    SendRequest({
        url: "getPasswordPolicyInAdmin",
        queryString: "",		
        sucessFunction: (response) => {
            this.render(response)
        },
        rejectFunction: () => { },
        headers: requestHeaders
    
    });
    }
    isValid=(data)=>{
        var isValid = true;
        var lowercase= parseInt(data.get("lowercasechars"));
					if(data.get("lowercasechars")==""){
						lowercase=0;
					}
					var uppercase= parseInt(data.get("uppercasechars"))
					if(data.get("uppercasechars") ==""){
						uppercase=0;
					}
					var minimum= parseInt(data.get("minimumdigits"))
					if(data.get("minimumdigits")==""){
						minimum=0;
					}
					var specialchar= parseInt(data.get("specialchars"))
					if(data.get("specialchars") ==""){
						specialchar=0;	 
					}
					var sum=lowercase+uppercase+minimum+specialchar;
                    var value = parseInt(data.get("minlengthchars"));
                    var value1 = parseInt(data.get("maxlengthchars"));
					if($.trim($.trim(data.get("minlengthchars")))==""){
					$(".minlengthcharserror").removeClass('hidden');
					$(".minlengthcharserror").text(lang["admin.pwd.minlenrqd"]);
					isValid = false;
					}
					else if($.isNumeric($.trim(data.get("minlengthchars"))) == false){
					$(".minlengthcharserror").removeClass('hidden');
					$(".minlengthcharserror").text(lang["admin.pwd.numonly"]);
					isValid = false;
					}
					else if($.trim(data.get("minlengthchars")).indexOf(".")!=-1){
					$(".minlengthcharserror").removeClass('hidden');
					$(".minlengthcharserror").text(lang["admin.pwd.decmlsn'tallow"]);
					isValid = false;
					}
					else if(/^\d*$/.test($.trim(data.get("minlengthchars")))== false){
					$(".minlengthcharserror").removeClass('hidden');
					$(".minlengthcharserror").text(lang["admin.pwd.+venumonly"]);
					isValid = false;
					}
					else if(value<=0 ){
					$(".minlengthcharserror").removeClass('hidden');
					$(".minlengthcharserror").text(lang["admin.pwd.minchar"]);
					isValid = false;
					}
					else if(value>16 ){
					$(".minlengthcharserror").removeClass('hidden');
					$(".minlengthcharserror").text(lang["admin.pwd.minchar16"]);
					isValid = false;
					}
					else if(/^(?:[1-9]\d*|\d)$/.test(data.get("minlengthchars")) == false){
					$(".minlengthcharserror").removeClass('hidden');
					$(".minlengthcharserror").text(lang["admin.pwd.enternum"]);
					isValid = false;
					}
					else if(sum==0 || sum>value){
					$(".minlengthcharserror").removeClass('hidden');
					$(".minlengthcharserror").text(lang["admin.pwd.sumofpwd"]);
					isValid = false;
					}
                    if($.trim($.trim(data.get("maxlengthchars")))==""){
                        $(".maxlengthcharserror").removeClass('hidden');
                        $(".maxlengthcharserror").text(lang["admin.pwd.maxlenrqd"]);
                        isValid = false;
                        }
                        else if($.isNumeric($.trim(data.get("maxlengthchars"))) == false){
                        $(".maxlengthcharserror").removeClass('hidden');
                        $(".maxlengthcharserror").text(lang["admin.pwd.numonly"]);
                        isValid = false;
                        }
                        else if($.trim(data.get("maxlengthchars")).indexOf(".")!=-1){
                        $(".maxlengthcharserror").removeClass('hidden');
                        $(".maxlengthcharserror").text(lang["admin.pwd.decmlsn'tallow"]);
                        isValid = false;
                        }
                        else if(/^\d*$/.test($.trim(data.get("maxlengthchars")))== false){
                        $(".maxlengthcharserror").removeClass('hidden');
                        $(".maxlengthcharserror").text(lang["admin.pwd.+venumonly"]);
                        isValid = false;
                        }
                        else if(value1>16 ){
                        $(".maxlengthcharserror").removeClass('hidden');
                        $(".maxlengthcharserror").text(lang["admin.pwd.maxchar16"] );
                        isValid = false;
                        }
                        else if(value>value1){
                        $(".maxlengthcharserror").removeClass('hidden');
                        $(".maxlengthcharserror").text(lang["admin.pwd.minchar"]);
                        isValid = false;
                        }
                        else if(/^(?:[1-9]\d*|\d)$/.test($.trim(data.get("maxlengthchars"))) == false){
                        $(".maxlengthcharserror").removeClass('hidden');
                        $(".maxlengthcharserror").text(lang["admin.pwd.enternum"]);
                        isValid = false;
                        }
                        if(data.get("lowercasechars")==""){
						var lowercasechars=0;  
					    }else if(data.get("lowercasechars")!=""){
                            if($.isNumeric($.trim(data.get("lowercasechars"))) == false){
                            $(".lowercasecharserror").removeClass('hidden');
                            $(".lowercasecharserror").text(lang["admin.pwd.numonly"]);
                            isValid = false;
                            }						
                            else if($.trim(data.get("lowercasechars")).indexOf(".")!=-1){
                            $(".lowercasecharserror").removeClass('hidden');
                            $(".lowercasecharserror").text(lang["admin.pwd.decmlsn'tallow"]);
                            isValid = false;
                            }
                            else if(/^\d*$/.test($.trim(data.get("lowercasechars")))== false){
                            $(".lowercasecharserror").removeClass('hidden');
                            $(".lowercasecharserror").text(lang["admin.pwd.+venumonly"]);
                            isValid = false;
                            }
                            else if(/^(?:[1-9]\d*|\d)$/.test($.trim(data.get("lowercasechars"))) == false){
                            $(".lowercasecharserror").removeClass('hidden');
                            $(".lowercasecharserror").text(lang["admin.pwd.enternum"]);
                            isValid = false;
                            }
					    }
                        if(data.get("uppercasechars")==""){
                            var uppercasechars=0;  
                        }else if(data.get("uppercasechars")!=""){
                            if($.isNumeric($.trim(data.get("uppercasechars"))) == false){
                            $(".uppercasecharserror").removeClass('hidden');
                            $(".uppercasecharserror").text(lang["admin.pwd.numonly"]);
                            isValid = false;
                            }						
                            else if($.trim(data.get("uppercasechars")).indexOf(".")!=-1){
                            $(".uppercasecharserror").removeClass('hidden');
                            $(".uppercasecharserror").text(lang["admin.pwd.decmlsn'tallow"]);
                            isValid = false;
                            }
                            else if(/^\d*$/.test($.trim(data.get("uppercasechars")))== false){
                            $(".uppercasecharserror").removeClass('hidden');
                            $(".uppercasecharserror").text(lang["admin.pwd.+venumonly"]);
                            isValid = false;
                            }
                            else if(/^(?:[1-9]\d*|\d)$/.test($.trim(data.get("uppercasechars"))) == false){
                            $(".uppercasecharserror").removeClass('hidden');
                            $(".uppercasecharserror").text(lang["admin.pwd.enternum"]);
                            isValid = false;
                            }
                        }
                        if(data.get("minimumdigits")==""){
                            var uppercasechars=0;  
                        }else if(data.get("minimumdigits")!=""){
                            if($.isNumeric($.trim(data.get("minimumdigits"))) == false){
                            $(".minimumdigitserror").removeClass('hidden');
                            $(".minimumdigitserror").text(lang["admin.pwd.numonly"]);
                            isValid = false;
                            }						
                            else if($.trim(data.get("minimumdigits")).indexOf(".")!=-1){
                            $(".minimumdigitserror").removeClass('hidden');
                            $(".minimumdigitserror").text(lang["admin.pwd.decmlsn'tallow"]);
                            isValid = false;
                            }
                            else if(/^\d*$/.test($.trim(data.get("minimumdigits")))== false){
                            $(".minimumdigitserror").removeClass('hidden');
                            $(".minimumdigitserror").text(lang["admin.pwd.+venumonly"]);
                            isValid = false;
                            }
                            else if(/^(?:[1-9]\d*|\d)$/.test($.trim(data.get("minimumdigits"))) == false){
                            $(".minimumdigitserror").removeClass('hidden');
                            $(".minimumdigitserror").text(lang["admin.pwd.enternum"]);
                            isValid = false;
                            }
                        }
                        if(data.get("specialchars")==""){
                            var uppercasechars=0;  
                        }else if(data.get("specialchars")!=""){
                            if($.isNumeric($.trim(data.get("specialchars"))) == false){
                            $(".specialcharserror").removeClass('hidden');
                            $(".specialcharserror").text(lang["admin.pwd.numonly"]);
                            isValid = false;
                            }						
                            else if($.trim(data.get("specialchars")).indexOf(".")!=-1){
                            $(".specialcharserror").removeClass('hidden');
                            $(".specialcharserror").text(lang["admin.pwd.decmlsn'tallow"]);
                            isValid = false;
                            }
                            else if(/^\d*$/.test($.trim(data.get("specialchars")))== false){
                            $(".specialcharserror").removeClass('hidden');
                            $(".specialcharserror").text(lang["admin.pwd.+venumonly"]);
                            isValid = false;
                            }
                            else if(/^(?:[1-9]\d*|\d)$/.test($.trim(data.get("specialchars"))) == false){
                            $(".specialcharserror").removeClass('hidden');
                            $(".specialcharserror").text(lang["admin.pwd.enternum"]);
                            isValid = false;
                            }
                        }
                            var whitespases=parseInt(data.get("whitespaces"));
                        var sum=lowercase+uppercase+minimum+specialchar+whitespases;
                        if(sum==value && whitespases==0 ){
                        $(".whitespaceserror").removeClass('hidden');
                        $(".whitespaceserror").text(lang["admin.pwd.lenexceeds"]);
                            isValid = false;
                      }
                      if($.trim(data.get("retentionperiode"))==""){
						$(".retentionperioderror").removeClass('hidden');
						$(".retentionperioderror").text(lang["admin.pwd.retentionrqd"]);
						isValid = false;
					}else{
						if($.isNumeric($.trim(data.get("retentionperiode"))) == false){
						$(".retentionperioderror").removeClass('hidden');
						$(".retentionperioderror").text(lang["admin.pwd.numonly"]);
						isValid = false;
						}						
						else if($.trim(data.get("retentionperiode")).indexOf(".")!=-1){
						$(".retentionperioderror").removeClass('hidden');
						$(".retentionperioderror").text(lang["admin.pwd.decmlsn'tallow"]);
						isValid = false;
						}
						else if(/^\d*$/.test($.trim(data.get("retentionperiode")))== false){
						$(".retentionperioderror").removeClass('hidden');
						$(".retentionperioderror").text(lang["admin.pwd.+venumonly"]);
						isValid = false;
						}
						else if(/^(?:[1-9]\d*|\d)$/.test($.trim(data.get("retentionperiode"))) == false){
						$(".retentionperioderror").removeClass('hidden');
						$(".retentionperioderror").text(lang["admin.pwd.enternum"]);
						isValid = false;
						}
						else if(parseInt(data.get("retentionperiode"))==0){
						$(".retentionperioderror").removeClass('hidden');
						$(".retentionperioderror").text(lang["admin.pwd.periodatleast"]);
						isValid = false;
						}
						else if(parseInt(data.get("retentionperiode"))>2147483647){
						$(".retentionperioderror").removeClass('hidden');
						$(".retentionperioderror").text(lang["admin.pwd.periodlessthan"]);
						isValid = false;
						}
                    }
                    if(data.get("loginfailattempts")!=""){
						if($.isNumeric($.trim(data.get("loginfailattempts"))) == false){
						$(".loginfailattemptserror").removeClass('hidden');
						$(".loginfailattemptserror").text(lang["admin.pwd.numonly"]);
						isValid = false;
						}
						else if($.trim(data.get("loginfailattempts")).indexOf(".")!=-1){
						$(".loginfailattemptserror").removeClass('hidden');
						$(".loginfailattemptserror").text(lang["admin.pwd.decmlsn'tallow"]);
						isValid = false;
						}
						else if(/^\d*$/.test($.trim(data.get("loginfailattempts")))== false){
						$(".loginfailattemptserror").removeClass('hidden');
						$(".loginfailattemptserror").text(lang["admin.pwd.+venumonly"]);
						isValid = false;
						}
						else if(/^(?:[1-9]\d*|\d)$/.test($.trim(data.get("loginfailattempts"))) == false){
						$(".loginfailattemptserror").removeClass('hidden');
						$(".loginfailattemptserror").text(lang["admin.pwd.enternum"]);
						isValid = false;
						}
						else if(parseInt(data.get("loginfailattempts"))>100){
						$(".loginfailattemptserror").removeClass('hidden');
						$(".loginfailattemptserror").text(lang["admin.pwd.failattemptslessthan"]);
						isValid = false;
						}
					}
                    
                    return isValid;
                    }
    updatePasswordPolicy=(e)=>{
        var serialized = $('form').serializeArray();
			var s = '';
			var data = {};
			for(s in serialized){
				data[serialized[s]['name']] = serialized[s]['value'].trim()
            }
            var reqdata = new FormData();
                reqdata.set("minlengthchars", data.minlengthchars);
                reqdata.set("maxlengthchars", data.maxlengthchars);
                reqdata.set("lowercasechars", data.lowercasechars);
                reqdata.set("uppercasechars", data.uppercasechars);
                reqdata.set("minimumdigits", data.minimumdigits);
                reqdata.set("specialchars", data.specialchars);
                reqdata.set("whitespaces", data.whitespaces);
                reqdata.set("twofactorauth", data.twofactorauth);
                reqdata.set("retentionperiode", data.retentionperiode);
                reqdata.set("loginfailattempts", data.loginfailattempts);
            var isValid = this.isValid(reqdata);
            if(isValid){
            let requestHeaders = {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
                SendRequest({
                    url: "updatePasswordPolicyInAdmin",
                    body: reqdata,
                    sucessFunction: (response) => {
                       if(response.status === 'success'){
                        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Success');
                        $('.birdmessage-info').empty().text(getMessage('BirdSuccess54'));
                        setTimeout(function() {
                            $('.birdmessage-container .close').click();	
                            }, 2000);
                    }else{
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.errorTrace);
                        setTimeout(function() {
                            $('.birdmessage-container .close').click();	
                            }, 2000);
                    }
                    },
                    rejectFunction:() => { },
                    headers: requestHeaders
    
                });
            }
    }   

    render(response){
        ReactDOM.render(<PasswordPolicyTemplate editpasswordpolicydetails={response} updatePasswordPolicy={this.updatePasswordPolicy} />,document.querySelector(".othersettingscontainer"))
        return(<div></div>);
    }
}