import React from 'react';

export default class ShowLicenseTemplate extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        var subClientDetail = this.props.subClientsDetails[0];
        return(<div class="container-fluid nopadding">
        <div class="row in-back">
            <div class="max-height-30vh">
                <h2 class="boc-main-title">License Details</h2>
                <form class="form-horizontal col-xs-12 licenseform">
                 <div class="form-group form-group-sm">
                        <label for="creators" class="col-sm-4 control-label">Edition</label>
                        <div class="col-sm-8">
                            <p>{subClientDetail.edition}</p>
                        </div>
                    </div>
                    <div class="form-group form-group-sm">
                        <label for="creators" class="col-sm-4 control-label">Creators</label>
                        <div class="col-sm-8">						
                        <p>{subClientDetail.registeredPowerUsers}/{subClientDetail.powerUsers ==  "&infin;" ? <span>&infin;</span> : subClientDetail.powerUsers}</p>
                        </div>
                    </div>
                    <div class="form-group form-group-sm">
                        <label for="explorers" class="col-sm-4 control-label">Explorers</label>
                        <div class="col-sm-8">						
                        <p>{subClientDetail.registeredReadOnlyUsers}/{subClientDetail.readOnlyUsers ==  "&infin;" ? <span>&infin;</span> : subClientDetail.readOnlyUsers}</p>
                        </div>
                    </div>
                    <div class="form-group form-group-sm">
                        <label for="viewers" class="col-sm-4 control-label">Viewers</label>
                        <div class="col-sm-8">						
                        <p>{subClientDetail.registeredViewers}/{subClientDetail.viewers ==  "&infin;" ? <span>&infin;</span> : subClientDetail.viewers}</p>
                        </div>
                    </div>
                    <div class="form-group form-group-sm">
                        <label for="storyboards" class="col-sm-4 control-label">Storyboards</label>
                        <div class="col-sm-8">						
                        <p>{subClientDetail.usedStoryBoards}/{subClientDetail.allowedStoryBoards ==  "&infin;" ? <span>&infin;</span> : subClientDetail.allowedStoryBoards}</p>
                        </div>
                    </div>
                    <div class="form-group form-group-sm">
                        <label for="myreports" class="col-sm-4 control-label">MyReports</label>
                        <div class="col-sm-8">						
                        <p>{subClientDetail.usedMyReports}/{subClientDetail.allowedMyReports ==  "&infin;" ? <span>&infin;</span> : subClientDetail.allowedMyReports}</p>
                        </div>
                    </div>
                    <div class="form-group form-group-sm">
                        <label for="datasize" class="col-sm-4 control-label">Data
                            Size (GB)</label>
                        <div class="col-sm-8">
                            <p>{subClientDetail.usedDataSize == "<span class='fa fa-angle-left'></span> 1GB" ? <span class='fa fa-angle-left'>1GB</span>  : subClientDetail.usedDataSize}/{subClientDetail.dataSize.replace("\"","")}</p>
                        </div>
                    </div>
                    <div class="form-group form-group-sm marg-top-5">
                        <label class="col-sm-4 control-label ">Expiry Date</label>
                        <div class="col-sm-8 form-inline">
                            <div class="input-group input-group-sm">
                                <p>{subClientDetail.expiryDate}</p>
                            </div>
                        </div>
                    </div>
                    <div class="form-group form-group-sm">
                        <label for="multitenant" class="col-sm-4 control-label">Allow Api</label>
                        <div class="col-sm-8">
                            <p>
                                 {subClientDetail.allowApi}
                            </p>
                        </div>
                    </div>
                    
                    <div class="form-group form-group-sm">
                        <label for="multitenant" class="col-sm-4 control-label">Allow ML</label>
                        <div class="col-sm-8">
                            <p>
                                 {subClientDetail.allowML}
                            </p>
                        </div>
                    </div>
                    
                    <div class="form-group form-group-sm">
                        <label for="multitenant" class="col-sm-4 control-label">Allow SmartInsights</label>
                        <div class="col-sm-8">
                            <p>
                                 {subClientDetail.allowSmartInsights}
                            </p>
                        </div>
                    </div>
                    
                    <div class="form-group form-group-sm">
                        <label for="multitenant" class="col-sm-4 control-label">Allow WhiteLabel</label>
                        <div class="col-sm-8">
                            <p>
                                 {subClientDetail.allowWhiteLable}
                            </p>
                        </div>
                    </div>
                    
                        <div class="form-group form-group-sm">
                        <label for="multitenant" class="col-sm-4 control-label">Connectors</label>
                        <div class="col-sm-8">
                            <p>
                                 {subClientDetail.connectors}
                            </p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>)
    }
}