import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;

export default class ReportColorThemeModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.render();
    }
    render() {
        ReactDOM.render(<ReportThemeModalTemplate tiledatacount={this.tiledatacount} storesinglereportcolortheme={this.storesinglereportcolortheme} cancelsinglereportcolortheme={this.cancelsinglereportcolortheme} chartnumber={this.props.chartnumber} />, document.querySelector('.addreportthememodal'));
        $('.theme-myreport-title').colorpicker({ format: "rgba" }).on('changeColor.colorpicker', function (event) {
            var $this = event.currentTarget;
            $('.maxanim9 h2.boc-viewreport-title[data-num=' + $($this).attr("data-num") + ']').css('color', $($this).children('input.form-control').val());
            $('.maxscreendatacount[data-num=' + $($this).attr("data-num") + ']').css('color', $($this).children('input.form-control').val());
            $('.maxanim4[data-num=' + $($this).attr("data-num") + ']').css('color', $($this).children('input.form-control').val());
        });
        $('.theme-myreport-background').colorpicker({ format: "rgba" }).on('changeColor.colorpicker', function (event) {
            var $this = event.currentTarget;
            $('.maxanim7[data-num=' + $($this).attr("data-num") + ']').css('background-color', $($this).children('input.form-control').val());
        });
        $('.theme-myreport-header-background').colorpicker({ format: "rgba" }).on('changeColor.colorpicker', function (event) {
            var $this = event.currentTarget;
            /* $('.maxanim9[data-num='+$($this).attr("data-num")+']').css('background-color',$($this).children('input.form-control').val()); */
            $('.reporttoolbar[data-num=' + $($this).attr("data-num") + '] .controlbar-main').css('background-color', $($this).children('input.form-control').val());
            $('.maxanim4[data-num=' + $($this).attr("data-num") + ']').parent().css('background-color', $($this).children('input.form-control').val());
        });
        var chartData = ChartInfo.functions.getChartDetails(this.props.chartnumber);
        var data = JSON.parse(chartData);
        if (data["reportcolortheme"] != undefined) {
            var reportcolortheme = JSON.parse(data["reportcolortheme"]);

            $('.tiledatacount[data-name=' + reportcolortheme.datacount + ']').prop('checked', true);
            $('.titletoolbar[data-name=' + reportcolortheme.toolbar + ']').prop('checked', true);
            if (reportcolortheme.titlecolor == "") { }
            else
                $('.theme-myreport-title').colorpicker('setValue', reportcolortheme.titlecolor);
            if (reportcolortheme.headerbackground == "") { }
            else
                $('.theme-myreport-header-background').colorpicker('setValue', reportcolortheme.headerbackground);
            if (reportcolortheme.reporttilebackground == "") { }
            else
                $('.theme-myreport-background').colorpicker('setValue', reportcolortheme.reporttilebackground);
        }
        return (<div></div>);
    }
    storesinglereportcolortheme = (e) => {
        var $this = e.currentTarget;
        var chartnumber = $($this).attr('data-num');
        var chartData = ChartInfo.functions.getChartDetails(chartnumber);
        var data = JSON.parse(chartData);
        var reporttheme = {};

        reporttheme['datacount'] = $(
            '.tiledatacount:checked').attr('data-name');
        reporttheme['toolbar'] = $('.titletoolbar:checked')
            .attr('data-name');
        reporttheme['titlecolor'] = $(
            '.theme-myreport-title input').val();
        reporttheme['headerbackground'] = $(
            '.theme-myreport-header-background input')
            .val();
        reporttheme['reporttilebackground'] = $(
            '.theme-myreport-background input').val();

        data['reportcolortheme'] = JSON
            .stringify(reporttheme);
        ChartInfo.functions.updateChartDetails(chartnumber, JSON.stringify(data));
        sessionStorage.setItem("changesdone", 1);
        ReactDOM.unmountComponentAtNode(document.querySelector('.addreportthememodal'));
    }
    cancelsinglereportcolortheme = (e) => {
        var $this = e.currentTarget;
        var chartnumber = $($this).attr('data-num');
        var chartData = ChartInfo.functions.getChartDetails(chartnumber);
        var data = JSON.parse(chartData);
        if (data['reportcolortheme'] == undefined) {
            $(
                '.maxscreendatacount[data-num='
                + chartnumber + ']')
                .addClass('hide');
            $('.reporttitletoolbar' + chartnumber)
                .addClass('col-lg-12 col-md-12');
            $('.reporttitlehamburger' + chartnumber)
                .addClass('hidden-lg hidden-md');

            $('.reporttitletoolbar' + chartnumber)
                .removeClass('hidden-lg hidden-md');
            $('.reporttitlehamburger' + chartnumber)
                .removeClass('col-lg-12 col-md-12');

            $(
                '.maxleftpanecustomhidden[data-num='
                + chartnumber + ']')
                .removeClass('hide');
            // title color
            $(
                '.maxanim9 h2.boc-viewreport-title[data-num='
                + chartnumber + ']').css(
                    'color', '#424242');
            $(
                '.maxscreendatacount[data-num='
                + chartnumber + ']').css(
                    'color', '#424242');
            $('.maxanim4[data-num=' + chartnumber + ']')
                .css('color', '#424242');
            // title background
            $('.maxanim7[data-num=' + chartnumber + ']')
                .css('background-color', 'transparent');
            // report background
            $('.reporttoolbar[data-num=' + chartnumber + '] .controlbar-main').css('background-color', '#ebebeb');
            $('.maxanim4[data-num=' + chartnumber + ']').parent().css('background-color', 'transparent');
        } else {
            if (data["reportcolortheme"] != undefined)
                var reportcolortheme = JSON
                    .parse(data["reportcolortheme"]);

            if (reportcolortheme.datacount == 'show'
                || reportcolortheme.datacount == undefined) {
                $(
                    '.maxscreendatacount[data-num='
                    + chartnumber + ']')
                    .removeClass('hide');
            } else {
                $(
                    '.maxscreendatacount[data-num='
                    + chartnumber + ']')
                    .addClass('hide');
            }
            if (reportcolortheme.toolbar == 'full'
                || reportcolortheme.toolbar == undefined) {
                $('.reporttitletoolbar' + chartnumber)
                    .addClass('col-lg-12 col-md-12');
                $('.reporttitlehamburger' + chartnumber)
                    .addClass('hidden-lg hidden-md');

                $('.reporttitletoolbar' + chartnumber)
                    .removeClass('hidden-lg hidden-md');
                $('.reporttitlehamburger' + chartnumber)
                    .removeClass('col-lg-12 col-md-12');

                $(
                    '.maxleftpanecustomhidden[data-num='
                    + chartnumber + ']')
                    .removeClass('hide');
            } else {
                $('.reporttitletoolbar' + chartnumber)
                    .removeClass('col-lg-12 col-md-12');
                $('.reporttitlehamburger' + chartnumber)
                    .removeClass('hidden-lg hidden-md');

                $('.reporttitletoolbar' + chartnumber)
                    .addClass('hidden-lg hidden-md');
                $('.reporttitlehamburger' + chartnumber)
                    .addClass('col-lg-12 col-md-12');
                $(
                    '.maxleftpanecustomhidden[data-num='
                    + chartnumber + ']')
                    .addClass('hide');
            }
            // title color
            if (reportcolortheme.titlecolor == "") {
                $(
                    '.maxanim9 h2.boc-viewreport-title[data-num='
                    + chartnumber + ']').css(
                        'color', '#424242');
                $(
                    '.maxscreendatacount[data-num='
                    + chartnumber + ']').css(
                        'color', '#424242');
                $('.maxanim4[data-num=' + chartnumber + ']')
                    .css('color', '#424242');
            } else {
                $(
                    '.maxanim9 h2.boc-viewreport-title[data-num='
                    + chartnumber + ']').css(
                        'color',
                        reportcolortheme.titlecolor);
                $(
                    '.maxscreendatacount[data-num='
                    + chartnumber + ']').css(
                        'color',
                        reportcolortheme.titlecolor);
                $('.maxanim4[data-num=' + chartnumber + ']')
                    .css('color',
                        reportcolortheme.titlecolor);
            }
            // title background
            if (reportcolortheme.reporttilebackground == "") {
                $('.maxanim7[data-num=' + chartnumber + ']')
                    .css('background-color',
                        'transparent');
            } else {
                $('.maxanim7[data-num=' + chartnumber + ']')
                    .css(
                        'background-color',
                        reportcolortheme.reporttilebackground);
            }
            // report background
            if (reportcolortheme.headerbackground == "") {
                $('.reporttoolbar[data-num=' + chartnumber + '] .controlbar-main').css('background-color', '#ebebeb');
                $('.maxanim4[data-num=' + chartnumber + ']').parent().css('background-color', 'transparent');
            } else {
                $('.reporttoolbar[data-num=' + chartnumber + '] .controlbar-main').css('background-color', reportcolortheme.headerbackground);
                $('.maxanim4[data-num=' + chartnumber + ']').parent().css('background-color', reportcolortheme.headerbackground);
            }
        }
        ReactDOM.unmountComponentAtNode(document.querySelector('.addreportthememodal'));
    }
    tiledatacount=(e)=>{
        var $this=e.currentTarget;
        var chartnumber=$($this).attr('data-num');
        if($($this).attr('id')=='tiledatacountshow'){
            $('.maxscreendatacount[data-num='+chartnumber+']').removeClass('hide');
        }
        if($($this).attr('id')=='tiledatacounthide'){
            $('.maxscreendatacount[data-num='+chartnumber+']').addClass('hide');
        }
    }
}
class ReportThemeModalTemplate extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            //    <!--reportthememodal.js  -->
            <div className="modal fade in" id="tiletheme" tabIndex="-1" data-num={this.props.chartnumber} tab-id="0" tab-name="" role="dialog" aria-labelledby="tiletheme" aria-hidden="true" style={{ display: "block" }}>
                {/* <div className="modal-backdrop fade in"></div> */}
                <div className="modal-dialog modal-sm modal-sm-400 modal-dialog-sm-center modal-dialog-center" style={{ zIndex: "1050" }}>
                    <div className="modal-content">
                        <div className="modal-header boc-modal-hf-padding">
                            <button type="button" className="close white" id="singlereportthemeclose" data-num={this.props.chartnumber} onClick={this.props.cancelsinglereportcolortheme} aria-hidden="true" >&times;</button>
                            <h4 className="modal-title"><i className="fa fa-paint-brush fa-violet"></i> {lang["storyboard.reporttheme"]}</h4>
                        </div>

                        <div className="modal-body scrollset">
                            <div className="container-fluid">
                                <div className="row filtercontent">
                                    <div className="col-xs-12 nopadding tiledatacount">
                                        <legend className="subhead">{lang["storyboard.datacounttitle"]}</legend>
                                        <div className="marg-left-10">
                                            <label><input type="radio" value="1" className="control-form tiledatacount" onClick={this.props.tiledatacount} id="tiledatacountshow" data-name="show" data-num={this.props.chartnumber} tab-id="0" tab-name="" name="tiledatacount" />&nbsp;{lang["storyboard.show"]}&nbsp;&nbsp;</label>
                                            <label><input type="radio" value="2" className="control-form tiledatacount" onClick={this.props.tiledatacount} id="tiledatacounthide" data-name="hide" data-num={this.props.chartnumber} tab-id="0" tab-name="" name="tiledatacount" defaultChecked="checked" />&nbsp;{lang["storyboard.hide"]} </label>
                                        </div>
                                    </div>
                                    {/* <!-- <div className="col-xs-12 nopadding">
                                   <legend className="subhead">Toolbar</legend>
                                   <div className="marg-left-10">
                                       <label><input type="radio" value="1" className="control-form titletoolbar" id="titletoolbarfull" data-name="full" data-num={this.props.chartnumber} checked="checked" name="tiletoolbar">&nbsp;Full&nbsp;&nbsp;</label>
                                       <label><input type="radio" value="2" className="control-form titletoolbar" id="titletoolbarhamburger" data-name="hamburger" data-num={this.props.chartnumber} name="tiletoolbar">&nbsp;Hamburger </label>
                                   </div>
                               </div> --> */}
                                    <div className="col-xs-12 nopadding">
                                        <legend className="subhead">{lang["storyboard.titlecolor"]}</legend>
                                        <div className="col-xs-12 nopadding">
                                            <div className="input-group theme-myreport-title" data-num={this.props.chartnumber} tab-id="0" tab-name="">
                                                <input type="text" placeholder={lang["storyboard.placeholdtitle"]} className="form-control" id="myreporttitles" />
                                                <span className="input-group-addon" id="myreporttitlescolor"><i style={{ backgroundColor: "#424242" }}></i></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 nopadding">
                                        <legend className="subhead marg-top-5">{lang["storyboard.header"]}</legend>
                                        <div className="col-xs-12 nopadding">
                                            <div className="input-group theme-myreport-header-background" data-num={this.props.chartnumber} tab-id="0" tab-name="">
                                                <input type="text" placeholder={lang["storyboard.placeholdheader"]} className="form-control" id="myheaderbackgrounds" />
                                                <span className="input-group-addon" id="myheaderbackgroundscolor"><i style={{ backgroundColor: "#ebebeb" }}></i></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 nopadding">
                                        <legend className="subhead marg-top-5">{lang["storyboard.reportbg"]}</legend>
                                        <div className="col-xs-12 nopadding">
                                            <div className="input-group theme-myreport-background" data-num={this.props.chartnumber} tab-id="0" tab-name="">
                                                <input type="text" placeholder={lang["storyboard.placeholdbackgrnd"]} className="form-control" id="myreportbackgrounds" />
                                                <span className="input-group-addon" id="myreportbackgroundscolor"><i style={{ backgroundColor: "#FFFFFF" }}></i></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer boc-modal-hf-padding">
                            <button type="button" className="btn btn-bird" onClick={this.props.cancelsinglereportcolortheme} id="cancelsinglereportcolortheme" data-num={this.props.chartnumber} tab-id="0" tab-name=""><i className="fa fa-times"></i>{lang["storyboard.cancelbtn"]}</button>
                            <button type="button" className="btn btn-bird" onClick={this.props.storesinglereportcolortheme} id="applysinglereportcolortheme" data-num={this.props.chartnumber} tab-id="0" tab-name=""><i className="fa fa-check"></i> {lang["storyboard.applybtn"]}</button>
                        </div>
                    </div>
                    {/* <!-- /.modal-content --> */}
                </div>
                {/* <!-- /.modal-dialog --> */}
            </div>
            //    <!-- /.Page Settings Modal -->
        );
    }
}