import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'underscore';
import CreateConnection from './CreateConnection';
import DataSourceConnection from './DatasourceConnection';
import DatahubConnectionslistHtml from './DatahubConnectionslistHtml.jsx';
import { lang } from "../js/helpers/utils/languages/language";
import CsvConnection from './CsvConnection';
import "bootstrap-multiselect/dist/js/bootstrap-multiselect.js";
import DatabaseConnections from './DatabaseConnections';
import {getMessage} from '../js/helpers/utils/BirdMessage';

const $=window.$;

export default class DatahubConnections extends React.Component{
    constructor(props){
        super(props);
        this.state={};
        this.currentselectedConnection = this.props.currentselectedConnection;
        this.features = this.props.features
        this.exisitingEntities = this.props.exisitingConnections;
        this.existingTables = this.props.exisitingEntites;    
        this.databaseConnectionView=null;
    }
    componentDidMount(){
        // var today = new Date();
        //             var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
        //                     dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
        // $(".connectionslist-title").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
        //  ReactDOM.render(
        //      <DatahubConnectionslistHtm  l  />,document.getElementById(dynamicdiv)
        //   );
        //   this.$el={dynamicdiv};
      //  <DatahubConnectionslistHtml createnewconnection={this.props.createnewconnection} editconnection={this.editConnection} />
        if(this.props.connectionsdata.length>0){
            // $("#existing-connections-list").ddslick({
            //     data:this.props.connectionsdata,
            //     width: "100%",
            //     imagePosition: "left",
            //     background:"#FFF",
            //     onSelected: (data)=>{
            //         this.loadSelectedConnection(data);
            //         this.selectedConnectionData = data
            //     }
            // });
            var connectionsdata=[];
            var that = this;
            _.each(that.props.connectionsdata,function(i){
                var newObj = {attributes:{}}
                newObj.connectionName = i.connectionName;
                newObj.label = i.text;
                newObj.value = i.value;
                newObj.selected = i.selected;
                newObj.attributes.imageSrc = i.imageSrc;
                newObj.attributes.connectionjson = i.connectionjson;
                newObj.attributes.contype = i.contype;
                newObj.attributes.description = i.description;
                newObj.attributes.schemaId = i.schemaId;
                newObj.attributes.connectionName = i.connectionName;
                
                connectionsdata.push(newObj);
            });
            connectionsdata.sort(function(a, b){
                var x = a.connectionName.toLowerCase();
                var y = b.connectionName.toLowerCase();
                if (x < y) {return -1;}
                if (x > y) {return 1;}
                return 0;
                });
            
				var groupedConnections  = _.groupBy(connectionsdata, 'connectionName');
				var finalGroupedConnections= [];
			
				_.each(groupedConnections,function(j){
						/*if(j.length>1){
							var test  = {};
							test.label = j[0].connectionName;
							test.children = j;
							finalGroupedConnections.push(test)
						}else{
							finalGroupedConnections.push(j[0]);
						}*/
						var test  = {};
						test.label = j[0].connectionName;
                        test.children = j;
                        test.children.sort(function(a, b){
                            var x = a.label.toLowerCase();
                            var y = b.label.toLowerCase();
                            if (x < y) {return -1;}
                            if (x > y) {return 1;}
                            return 0;
                            });
						finalGroupedConnections.push(test);
                    })
                    	
                    $('#connections-tables-data-container').parent().find('#existing-connections-list').multiselect({
                    enableCollapsibleOptGroups: true,
                    collapseOptGroupsByDefault: true,
                    enableCaseInsensitiveFiltering:true,
                    enableFiltering: true,
                    enableHTML: true,
                    optionLabel: function(element) {
                        //return '<img src="'+ $(element).attr('data-imagesrc')+'"> '+  $(element).attr('label');
                        return ''+$(element).attr('label');
                    },
                    templates: {
                        filterClearBtn: '<span class="input-group-btn"><button class="btn btn-default multiselect-clear-filter" type="button"><i class="fa fa-times"></i></button></span>',
                    },
                    onChange: function(option, checked) {
                        $("ul.multiselect-container>li").removeClass('active');
                        $("ul.multiselect-container>li input[type='checkbox']").prop("checked",false);
                        $("ul.multiselect-container>li input[type='checkbox'][value='"+$(option).val()+"']").prop("checked",true);
                        $("ul.multiselect-container>li input[type='checkbox'][value='"+$(option).val()+"']").closest("li").addClass("active");
                        $('#existing-connections-list option:checked').prop("selected",false);
                        $(option).prop("selected",true);
                        
                        var $selectedItem = $('#existing-connections-list option:checked');
                        var data = {selectedData:{}};
                        data.selectedData.connectionName = $($selectedItem).attr("data-connectionname");
                        data.selectedData.text = $($selectedItem).attr("label");
                        data.selectedData.value = $($selectedItem).attr("value");
                        data.selectedData.selected = true;
                        data.selectedData.imageSrc = $($selectedItem).attr("data-imagesrc");
                        data.selectedData.connectionjson = $($selectedItem).attr("data-connectionjson");
                        data.selectedData.contype = $($selectedItem).attr("data-contype");
                        data.selectedData.description = $($selectedItem).attr("data-description");
                        data.selectedData.schemaId = $($selectedItem).attr("data-schemaid");
                        
                        $("span.multiselect-native-select div.btn-group>button.multiselect>img").attr("src",data.selectedData.imageSrc);
                        that.loadSelectedConnection(data);
                        that.selectedConnectionData = data;
                        that.hideHubEntities();
                        $(".connections-dropdown-container button.multiselect").click();
                    }
              });
             
            
              $('#connections-tables-data-container').parent().find('#existing-connections-list').multiselect('dataprovider', finalGroupedConnections);
            
            //Initialize the dropdown
            var $selectedItem = $('#connections-tables-data-container').parent().find('#existing-connections-list option:checked');
            var data = {selectedData:{}};
            data.selectedData.connectionName = $($selectedItem).attr("data-connectionname");
            data.selectedData.text = $($selectedItem).attr("label");
            data.selectedData.value = $($selectedItem).attr("value");
            data.selectedData.selected = true;
            data.selectedData.imageSrc = $($selectedItem).attr("data-imagesrc");
            data.selectedData.connectionjson = $($selectedItem).attr("data-connectionjson");
            data.selectedData.contype = $($selectedItem).attr("data-contype");
            data.selectedData.description = $($selectedItem).attr("data-description");
            data.selectedData.schemaId = $($selectedItem).attr("data-schemaid");
            
            that.loadSelectedConnection(data);
            that.selectedConnectionData = data;
            
            $('#connections-tables-data-container').parent().find("span.multiselect-native-select div.btn-group>button.multiselect").prepend("<img src='"+data.selectedData.imageSrc+"'>")
            $('#connections-tables-data-container').parent().find(".multiselect-item.multiselect-group .caret-container").on("click",function(e){
                var $this = e.currentTarget;
                $($this).toggleClass("collapsed");
                $($this).closest(".multiselect-item.multiselect-group").toggleClass("collapsed");
            });
            
            _.each($('#connections-tables-data-container').parent().find("span.multiselect-native-select li.multiselect-item.multiselect-group"),function(i){
                $(i).find('label').prepend("<img src='../../images/birdimages/"+$(i).find('b').text().trim()+".png'>")
            })           

        }else{
            $(".addconnection").remove();
            $("#root").append("<div class='connection-center addconnection connection-in'></div>");
            $("body").addClass("connection-open creating-new-connection");
            ReactDOM.render(<CreateConnection />,document.querySelector('.addconnection'));
            $(".connections-dropdown-container").empty();
            $(".spinner").hide();
            //$(".connection-center.addconnection").addClass("connection-in"); 
        }
    }
    onconnectionchange=(e)=>{
    }
    hideHubEntities(){
        $("#datahubentitycontainer>li").addClass("hiddenentity");
        var curretItem = $('#existing-connections-list option:checked').val();
        $("#datahubentitycontainer>li>label input[data_parent='"+curretItem+"']").closest("li").removeClass("hiddenentity");
    }
    datahubaddcustomquery=(e)=>{
        var totalEntitiesAdded = $("#datahubentitycontainer>li").length;
        var totalTempEntitiesAdded = $("#datahubentitycontainer>li:not(.is-saved-entity)").length;
        if(totalEntitiesAdded>=1000){
                $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-infor').addClass('birdmessage-warning');
                $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-info-circle').addClass('fa-exclamation-triangle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Warning');
                $("#datahubstepscontainer").steps("previous");
                $('.birdmessage-info').empty().text(getMessage('BirdWarning151'));
                $('.birdmessage-center').addClass("message-top-in");
                $('.spinner').hide();
                return ;
        }
        else if(totalTempEntitiesAdded>=100){
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-infor').addClass('birdmessage-warning');
                $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-info-circle').addClass('fa-exclamation-triangle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Warning');
                $("#datahubstepscontainer").steps("previous");
                $('.birdmessage-info').empty().text(getMessage('BirdWarning157'));
                $('.birdmessage-center').addClass("message-top-in");
                $('.spinner').hide();
                return ;
        }
        var $this = e.currentTarget;
        var connId = $($this).attr('data_parent');
        var width  = $("#connections-tables-data-container").width() - 43;
        $("#datahubentitycontainer").append('<li class="viewreport-list-item datahub-customquery-item" data_parent="'+connId+'" style="width:'+width+'px"><label title='+lang["datahub.customqry"]+'><i class="fa fa-code"><input type="checkbox" class="scopeitem customquery" data_parent="'+connId+'" name="Custom Query" id="Custom Query" data-value="Custom Query"></i>'+lang["datahub.customqry"]+'</label><button class="btn btn-transparent btn-sm editdatahubcustomquery" data_parent="'+connId+'" name="Custom Query" type="button" title='+lang["Datahub.editcustomquery"]+'><i class="fa fa-cog"></i></button><button class="btn btn-transparent btn-sm joinremove" type="button"><i class="fa fa-times"></i></button></li>');
        $('.joinremove').click((e)=>{
            e.stopPropagation();
            e.stopImmediatePropagation();
            this.props.joinremove(e);
        });
        $('.editdatahubcustomquery').click((e)=>{
            e.stopPropagation();
            e.stopImmediatePropagation();
            this.props.loadcustomquerydetails(e);
        })
    }
    editConnection=(e)=>{
        this.connectionJsonArray = [];
        e.preventDefault();
        $(".connection-center").remove();
        $("#main").append('<div class="connection-center addconnection"><div class="connection-backdrop"></div><div class="connection-container"><div class="closenewscopeconnection" id="closeconnectionpop"><i class="fa fa-times"></i></div></div></div>');
        $("body").addClass("connection-open creating-new-connection");
        $(".connection-container").addClass("adding-new-source");
        $(".commonconnections, .otherconnections").hide();
        var editSchemaName="";
        _.each(this.exisitingEntities,(value,index)=>{
            if(value.connectionAccessId===this.selectedConnectionData.selectedData.value){
                if(value.connectionName.toLowerCase()==='kafka' || value.connectionName.toLowerCase()==='webdataconnector'){
                    this.connectionJsonArray  = (((JSON.parse(value.connectionJson))))
                    editSchemaName=value.connectionName;
                }else{
                this.connectionJsonArray  = (((JSON.parse(value.connectionDetailsJson))));
                editSchemaName=value.connectionName;
            }
            }
        });
        var connectionData = JSON.parse(this.selectedConnectionData.selectedData.connectionjson);
        ReactDOM.render(
            <DataSourceConnection
                schemaId={this.selectedConnectionData.selectedData.schemaId}
                schemaName={editSchemaName}
                schemaDisplayName={connectionData.connectionname}
                filename={connectionData.filename}
                scopename={connectionData.scopeName}
                isFromEditFlatFiles={true}
                editfilename={connectionData.fileName}
                connectionjson={connectionData}
                connectionId={this.selectedConnectionData.selectedData.value}
                connectiondetails={this.connectionJsonArray}
                connectiondata={this.connectionJsonArray}
            />,document.querySelector('.connection-container'));
            $(".connection-center.addconnection").addClass("connection-in");
            if(connectionData!=undefined){
                if(connectionData.dbType.toLowerCase()=="servicenow"){
                if(connectionData.tablesType.toLowerCase()== "custom"){
                $("#Custom").val(connectionData.tablesType).prop("checked",true);
                $(".tablestype").click();
            }}}
    }
    loadSelectedConnection=(data)=>{
        if(this.databaseConnectionView!==null){
            this.databaseConnectionView.remove();
            $('#datahubsidebar').find("#connections-tables-data-container").empty();
        }
        if(data!==undefined && data.selectedData!==undefined &&  (data.selectedData.connectionName==="csv" || data.selectedData.connectionName==="json" || data.selectedData.connectionName==="pdf" || data.selectedData.connectionName==="xls" || data.selectedData.connectionName==="log")){
        //	$('.btn-editconnection-abp').addClass("hide");
        if(data.selectedData.connectionName==="log"){
            $('.btn-editconnection-abp').removeClass("hide");
        }
        this.selectedData = data.selectedData;
        ReactDOM.render(
            <CsvConnection
                connectionjson={data.selectedData.connectionjson}
                connectionAccessId={data.selectedData.value}
                connectionName={data.selectedData.text}
                connectionType={data.selectedData.contype}
                exisitingEntities={this.exisitingEntities}
                search={this.search}
                joinremove={this.props.joinremove}
                editdatahubitem={this.props.editdatahubitem}
            />
            ,document.getElementById('connections-tables-data-container'));
        } else{
            $('.btn-editconnection-abp').removeClass("hide");
            this.selectedData = data.selectedData;
            ReactDOM.unmountComponentAtNode(document.getElementById('connections-tables-data-container'));
            ReactDOM.render(
                <DatabaseConnections
                    connectionjson={data.selectedData.connectionjson}
                    connectionAccessId={data.selectedData.value}
                    connectionName={data.selectedData.text}
                    connectionType={data.selectedData.contype}
                    exisitingEntities={this.exisitingEntities}
                    datahubaddcustomquery={this.datahubaddcustomquery}
                    search={this.search}
                    joinremove={this.props.joinremove}
                    editdatahubitem={this.props.editdatahubitem}
                    onEntitiesLoad={this.props.onEntitiesLoad}
                    connectionaccessdata={this.props.connectionaccessdata}
                    loadstoredproceduredetails={this.props.loadstoredproceduredetails}
                />,
                document.getElementById('connections-tables-data-container')
            );
        }
        
    }
    search=(e)=>{
        var $this = e.currentTarget;
        /*Delay function for to wait until user stops typing*/
        var delay = (function(){
              var timer = 0;
              return function(callback, ms){
                clearTimeout (timer);
                timer = setTimeout(callback, ms);
              };
            })();
        /*Delay function for to wait until user stops typing*/
        $(".form-control").keyup(function(){
            var valThis = $(this).val().toLowerCase();
            var searchin = $(this).parent().parent().parent().find("ul");
            delay(function(){
                $(searchin).find(".viewreport-list-item").each(function(){
                    var text = $(this).text().toLowerCase();
                        (text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
                });
            }, 400 );
        });
    }
    render(){
        return(
            <React.Fragment>
                <DatahubConnectionslistHtml createnewconnection={this.props.createnewconnection} editconnection={this.editConnection} />
            </React.Fragment>
        );
    }
}