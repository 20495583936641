import React, { Component } from 'react';
import { lang} from "../../../js/helpers/utils/languages/language";

export default class EmailDisclaimerTemplate extends Component{
    constructor(props){
        super(props);
    }

    render(){
        var that = this.props;
        return(
            <div className="row in-back">
	<div className="row-same-height-inner">
		<div className="col-xs-6 col-xs-height in-panes" id="icontent">
			<div className="innerothersarea disclaimerform">
				<div className="row in-back max-height-40vh">
					<div className="max-height-30vh">
						<h2 className="boc-main-title">{lang["admin.emaild.title"]}</h2>
						<form className="form-horizontal col-xs-12 emaildisclaimerform">
							<div className="form-group form-group-sm">
								{/* <div className="col-sm-4 col-sm-offset-3" style={{width: "800px", marginTop: "-34px", marginLeft: "149px"}}> */}
								<div className="col-lg-8 col-lg-offset-2">
									<textarea rows="4" cols="75" className="form-control textarea" name="emaildisclaimer"  id="emaildisclaimer"></textarea>
									<span className="help-block hidden" style={{color: "red"}}></span>
								</div>
							</div>
							<div className="form-group form-group-sm">
								<div className="col-lg-8 col-lg-offset-2">
									<button type="button"
										className="btn btn-bird pull-left marg-righ-5" 
										id="saveemaildisclaimer" onClick={that.saveEmailDisclaimer}><i className="fa fa-check marg-righ-5"></i>{lang["admin.emaild.applybtn"]}</button>
								</div>
							</div>
							
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
        );
    }
}