
import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash';
import PubSub from 'pubsub-js'
import SendRequest from '../SendRequest';
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import * as TabInfo from "../js/helpers/utils/tabinfo";
import ViewStoryBookReportView from "../ViewStoryboard/Storyboard.jsx"
import StroyBookNewTabModelTemplate from './viewstorybooknewtabmodal.jsx'
import MLSavedReportView from "../ViewStoryboard/viewMLStoryboard/viewsavedmlmodel";
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;
export default class StrorybookNewTabModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
        var that = this;
        this.state.isFromEdit = this.props.isFromEdit;
        this.state.features = ChartInfo.functions.getAllFeatures();
        this.state.url = TabInfo.functions.getURL();

        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getuserstoryboards",
            queryString: "?isFromDashboard="+false,
            sucessFunction: storyboards => {
                that.state.storyBoardCollection = storyboards;
                SendRequest({
                    url: "getuserreports",
                    queryString: "?isFromDashboard="+false,
                    sucessFunction: reports => {
                        that.state.reportCollection = reports;
                        that.render();
                    }, rejectFunction: () => { },
                    headers: requestHeaders
                });
            }, rejectFunction: () => { },
            headers: requestHeaders
        });
    }
    render() {
        ReactDOM.unmountComponentAtNode(document.querySelector('.newstorytabdiv'));
        var previousReportIds = [];
        var allTabsDetails = ChartInfo.functions.getTempStoryBookDetails();
        if (allTabsDetails != undefined && allTabsDetails.length > 0) {
            _.each(allTabsDetails, function (i) {
                previousReportIds.push(i.reportId);
            });
        }

        if (this.state.isFromEdit != undefined && this.state.isFromEdit) {
            this.state.tabData = this.props.tabData;
            previousReportIds.splice(previousReportIds.indexOf(this.props.tabData.reportId), 1);
            ReactDOM.render(<StroyBookNewTabModelTemplate closeNewTabModel={this.closeNewTabModel} addnewtab={this.addnewtab} storyboards={this.state.storyBoardCollection} reports={this.state.reportCollection} previousReportIds={previousReportIds} newtabname={this.props.tabData.name} />, document.querySelector('.newstorytabdiv'));
        } else {
            ReactDOM.render(<StroyBookNewTabModelTemplate closeNewTabModel={this.closeNewTabModel} addnewtab={this.addnewtab} storyboards={this.state.storyBoardCollection} reports={this.state.reportCollection} previousReportIds={previousReportIds} newtabname={""} />, document.querySelector('.newstorytabdiv'));
        }
        $(".report-select").chosen({
            no_results_text: lang["storybook.oops"],
            width: "100%",
            max_selected_options: 1,
            search_contains: true
        });

        if (this.state.isFromEdit != undefined && this.state.isFromEdit) {
            var that = this;
            that.state.tabData = that.props.tabData;
            var tempvalue = that.state.tabData.reportId + "&&&&" + that.state.tabData.reportType + "&&&&" + that.state.tabData.dataModelId;
            $('.storyboardids option[value=' + that.state.tabData.reportId + ']').attr("selected", "selected");
            $(".report-select").trigger("chosen:updated");
            $('.storyboardids option[value=' + that.state.tabData.reportId + ']').attr("data", tempvalue);
        }

        return (<div></div>);
    }
    addnewtab = (e) => {
        var that = this;
        var reportid;
        var reportType;
        var dataModelId = "";
        var tabid = 0;
        var allTabsDetails = ChartInfo.functions.getTempStoryBookDetails();
        var selectValue = $('.report-select .storyboardids option:selected').attr('data');
        if (selectValue != undefined && selectValue != null && selectValue != "") {
            reportid = selectValue.split("&&&&")[0];
            reportType = selectValue.split("&&&&")[1];
            if (selectValue.split("&&&&")[2] != undefined) {
                dataModelId = selectValue.split("&&&&")[2];
            }
        }
        var tabname = $('.newstorytabdiv').find("#tabname").val();
        if (tabname.trim() == "" || tabname == undefined || (/[~`!@#$%^&*()+\=\[\]{};':\\|†,.<>\/?"`]/.test(tabname.trim()))) {
            $('.help-block.tabname-error').removeClass('hide');
            return;
        } else {
            tabname = tabname.trim();
            $('.help-block.tabname-error').addClass('hide');
        }
        var isAlreadyExists = false;
        if (allTabsDetails != undefined && allTabsDetails.length > 0) {
            _.each(allTabsDetails, function (i) {
                if (tabname.toLowerCase() == i.name.toLowerCase()) {
                    isAlreadyExists = true;
                    if (that.state.isFromEdit != undefined && that.state.isFromEdit && tabname.toLowerCase() == that.props.tabData.name.toLowerCase()) {
                        isAlreadyExists = false;
                    }
                }
            });
        }
        if (isAlreadyExists) {
            $('.help-block.tabname-error').removeClass('hide');
            return;
        }
        if (reportid == undefined || reportid == "") {
            $('.help-block.reportid-error').removeClass('hide');
            return;
        } else {
            $('.help-block.reportid-error').addClass('hide');
        }

        console.log("tabname:" + tabname + "   reportid:" + reportid);

        var obj = {};
        if (allTabsDetails != undefined && allTabsDetails.length != 0) {
            var popObj = allTabsDetails[allTabsDetails.length - 1];
            tabid = popObj["id"] + 1;
        }
        if (this.state.isFromEdit != undefined && this.state.isFromEdit) {
            this.state.tabData = this.props.tabData;
            tabid = this.state.tabData.id;
            TabInfo.functions.updateDrilldownFilterFromSession(tabid, {});
        }

        obj["id"] = tabid;
        obj["name"] = tabname;
        obj["reportId"] = reportid;
        obj["dataModelId"] = dataModelId;
        obj["reportType"] = reportType;

        if (this.state.isFromEdit != undefined && this.state.isFromEdit) {
            _.each(allTabsDetails, function (index, i) {
                if (index.id == tabid) {
                    allTabsDetails[i] = obj;
                }
            });
        } else {
            allTabsDetails.push(obj);
        }
        //getTempStoryBookDetails();
        ChartInfo.functions.updateTempStoryBookDetails(allTabsDetails);
        //$('#newtabcontainerid').find("li").removeClass("active");
        //	$(".tab-pane").removeClass("active");

        TabInfo.functions.setCurrentTabNumber(tabid);

        if (this.state.isFromEdit != undefined && this.state.isFromEdit) {
            $('#storybooktab' + tabid).text(tabname)
            $('#storybooktab' + tabid).attr('reportid', reportid);
            var href = this.state.url + "/viewreport/" + btoa(reportid);
            $('.editstorybookreport[tab-id=' + tabid + ']').attr('href', href);
        } else {
            $("#newtabcontentid").append('<div id="storybooktabcontent' + tabid + '" role="tabpanel" class="tab-pane active storybookpagecontent"></div>');
            if (reportType === 'ml_storyboard')
                $("#newtabcontainerid").append('<li role="presentation" class="tabheadli active" tab-id=' + tabid + '><a href="#storybooktabcontent' + tabid + '" id="storybooktab' + tabid + '" class="tabclick" tab-id=' + tabid + ' reportid=' + reportid + ' role="tab" data-toggle="tab">' + tabname + '</a><span class="dropdown-toggle storybooktabsettings" data-direction="left" data-toggle="dropdown" aria-expanded="false" title='+lang["storybook.tabsettings"]+'><i class="fa fa-cog"></i></span><ul class="dropdown-menu dropdown-menu-left" style="left:5px;"><li><a href="javascript:void(0)" class="editstorybooktab" data-toggle="modal" tab-id=' + tabid + ' data-target="#mySmallModal" title='+lang["storybook.modifytab"]+'><i class="fa fa-refresh marg-righ-10"></i>'+lang["storybook.modifytab"]+'</a></li><li><a href="#viewmodel/' + btoa(reportid) + '/' + btoa(dataModelId) + '" target="_blank" class="editstorybookreport" tab-id=' + tabid + ' title='+lang["storybook.modifyreport"]+'><i class="fa fa-pencil marg-righ-10"></i>'+lang["storybook.modifyreport"]+'</a></li><li><a href="javascript:void(0)" class="deletestorybooktab" tab-id=' + tabid + ' data-toggle="modal" data-target="#mySmallModal" title='+lang["storybook.deltab"]+'><i class="fa fa-trash marg-righ-10"></i>'+lang["storybook.deltab"]+'</a></li></ul></li>');
            else if (reportType === 'smartinsight_storyboard')
                $("#newtabcontainerid").append('<li role="presentation" class="tabheadli active" tab-id=' + tabid + '><a href="#storybooktabcontent' + tabid + '" id="storybooktab' + tabid + '" class="tabclick" tab-id=' + tabid + ' reportid=' + reportid + ' role="tab" data-toggle="tab">' + tabname + '</a><span class="dropdown-toggle storybooktabsettings" data-direction="left" data-toggle="dropdown" aria-expanded="false" title='+lang["storybook.tabsettings"]+'><i class="fa fa-cog"></i></span><ul class="dropdown-menu dropdown-menu-left" style="left:5px;"><li><a href="javascript:void(0)" class="editstorybooktab" data-toggle="modal" tab-id=' + tabid + ' data-target="#mySmallModal" title='+lang["storybook.modifytab"]+'><i class="fa fa-refresh marg-righ-10"></i>'+lang["storybook.modifytab"]+'</a></li><li><a href="/viewsmartinsight/' + btoa(reportid) + '/' + btoa(dataModelId) + '" target="_blank" class="editstorybookreport" tab-id=' + tabid + ' title='+lang["storybook.modifyreport"]+'><i class="fa fa-pencil marg-righ-10"></i>'+lang["storybook.modifyreport"]+'</a></li><li><a href="javascript:void(0)" class="deletestorybooktab" tab-id=' + tabid + ' data-toggle="modal" data-target="#mySmallModal" title='+lang["storybook.deltab"]+'><i class="fa fa-trash marg-righ-10"></i>'+lang["storybook.deltab"]+'</a></li></ul></li>');
            else
                $("#newtabcontainerid").append('<li role="presentation" class="tabheadli active" tab-id=' + tabid + '><a href="#storybooktabcontent' + tabid + '" id="storybooktab' + tabid + '" class="tabclick" tab-id=' + tabid + ' reportid=' + reportid + ' role="tab" data-toggle="tab">' + tabname + '</a><span class="dropdown-toggle storybooktabsettings" data-direction="left" data-toggle="dropdown" aria-expanded="false" title='+lang["storybook.tabsettings"]+'><i class="fa fa-cog"></i></span><ul class="dropdown-menu dropdown-menu-left" style="left:5px;"><li><a href="javascript:void(0)" class="editstorybooktab" data-toggle="modal" tab-id=' + tabid + ' data-target="#mySmallModal" title='+lang["storybook.modifytab"]+'><i class="fa fa-refresh marg-righ-10"></i>'+lang["storybook.modifytab"]+'</a></li><li><a href="/viewreport/' + btoa(reportid) + '" target="_blank" class="editstorybookreport" tab-id=' + tabid + ' title='+lang["storybook.modifyreport"]+'><i class="fa fa-pencil marg-righ-10"></i>'+lang["storybook.modifyreport"]+'</a></li><li><a href="javascript:void(0)" class="deletestorybooktab" tab-id=' + tabid + ' data-toggle="modal" data-target="#mySmallModal" title='+lang["storybook.deltab"]+'><i class="fa fa-trash marg-righ-10"></i>'+lang["storybook.deltab"]+'</a></li></ul></li>');
        }
        $("#newtabcontainerid .tabheadli").removeClass("active");
        $('a#storybooktab' + tabid).parent().addClass("active");
        $(".tab-pane.storybookpagecontent").removeClass("active");
        $('#storybooktabcontent' + tabid).addClass("active");
        $(".tab-pane.storybookpagecontent .rightfilter").attr('id', "filteraccordiontemp");
        $(".spinner").addClass("forceshow");
        if (reportType != undefined && reportType == "ml_storyboard") {
            ReactDOM.render(<MLSavedReportView reportId={reportid}
                isDirect={0}
                features={that.state.features}
                datamodelId={dataModelId}
                el={'storybooktabcontent' + tabid}
                isFromStoryBook={true}
                tabid={tabid}
            />, document.getElementById('storybooktabcontent' + tabid));
            $('#storybooktabcontent' + parseInt(tabid) + ' .quickfiltercontainer').attr("tab-id", tabid);
            $(".tab-pane.storybookpagecontent .rightfilter").attr('id', "filteraccordiontemp");
            $('#storybooktabcontent' + parseInt(tabid) + '.tab-pane.storybookpagecontent.active .tab-filters-container>.rightfilter').attr('id', "filteraccordion");

        } else if (reportType != undefined && reportType == "smartinsight_storyboard") {

            ReactDOM.unmountComponentAtNode(document.getElementById('storybooktabcontent' + tabid));
            ReactDOM.render(<ViewStoryBookReportView
                reportId={reportid}
                isDirect={0}
                features={that.state.features}
                el={'storybooktabcontent' + tabid}
                isFromStoryBook={true}
                tabid={tabid}
                isFromSavedSmartInsight={true}
                isFromSmartInsigt={true}
                datamodelId={dataModelId}
            />, document.getElementById('storybooktabcontent' + tabid));

            $('#storybooktabcontent' + parseInt(tabid) + ' .quickfiltercontainer').attr("tab-id", tabid);
            $(".tab-pane.storybookpagecontent .rightfilter").attr('id', "filteraccordiontemp");
            $('#storybooktabcontent' + parseInt(tabid) + '.tab-pane.storybookpagecontent.active .tab-filters-container>.rightfilter').attr('id', "filteraccordion");
        } else {
            ReactDOM.unmountComponentAtNode(document.getElementById('storybooktabcontent' + tabid));
            ReactDOM.render(<ViewStoryBookReportView
                reportId={reportid}
                isDirect={0}
                features={that.state.features}
                el={'storybooktabcontent' + tabid}
                isFromStoryBook={true}
                tabid={tabid} />, document.getElementById('storybooktabcontent' + tabid));

            //  TabInfo.functions.updateStoryBookViews(tabid, viewStoryBookReportView);
            $('#storybooktabcontent' + parseInt(tabid) + ' .quickfiltercontainer').attr("tab-id", tabid);
        }

        TabInfo.functions.updateChartInfoForTab(tabid, ChartInfo);
        this.closeNewTabModel();
        this.closeSpinner();
        $('.tabclick').click(function (e) {
            PubSub.publish('tabclick', e);
        })
        $('.deletestorybooktab').click(function (e) {
            PubSub.publish('deletestorybooktab', e);
        })
        $('.editstorybooktab').click(function (e) {
            PubSub.publish('editstorybooktab', e);
        })
        $('.editstorybookreport').click(function (e) {
            var href = window.location.origin + that.state.url + e.currentTarget.getAttribute("href");
            window.open(href, '_blank');
        })

    }
    closeNewTabModel = () => {
        ReactDOM.unmountComponentAtNode(document.querySelector('.newstorytabdiv'));
    }
    closeSpinner() {
        //$(".spinner").addClass("forceshow");
        var loadedTiles = $(".storybookpagecontent.active .maxanim10").length;
        if (loadedTiles > 0) {
            var activeCharts = $(".storybookpagecontent.active .maxanim10:empty").length;
            if (activeCharts > 0) {
                function executecloseSpinner() {
                    var emptycharts = $(".storybookpagecontent.active .maxanim10:empty").length;
                    if (emptycharts == 0) {
                        setTimeout(function () {
                            $(".spinner").removeClass("forceshow");
                        }, 1500);
                    } else {
                        setTimeout(function () {
                            executecloseSpinner();
                        }, 2000);
                    }
                }
                setTimeout(function () { executecloseSpinner(); }, 2000);
            } else {
                $(".spinner").removeClass("forceshow");
            }
        } else {
            var that = this;
            setTimeout(function () { that.closeSpinner(); }, 2000);
            setTimeout(function () {
                if (!loadedTiles > 0) {
                    $(".spinner").removeClass("forceshow");
                }
            }, 4000);

        }
    }
}
