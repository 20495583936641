import React from 'react'
import ReactDOM from 'react-dom'
import PubSub from 'pubsub-js'
import _ from 'underscore'
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import TileFiltersTemplate from './tilefiltersmodal.jsx'
import StoryboardFilterTemplate from './storyboardfilterslist.jsx'
import StoryboardFiltersListItemTemplate from './storyboardfilterslistitem.jsx'

const $ = window.$;
export default class TileFiltersModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.state.el = ".tilefilterscontainer";
        this.state.currentTile = this.props.tileNumber;
        this.state.StoryboardFilters = $(".tab-filters-container #filteraccordion .acc-content");
        this.render();
    }

    render() {
        ReactDOM.render(<TileFiltersTemplate tilefilterapply={this.tilefilterapply} changeTile={this.changeTile} tilefiltersclose={this.tilefiltersclose} chartid={this.state.currentTile} />, document.querySelector(this.state.el));
        this.resizeTileOnModelToggle(this.state.currentTile);
        this.updateTilesList();
        this.updateStoryboardFiltersList();
        this.updateToggleStatusofStoryBoardFilters();
    //    $("#tilefiltersmodal").modal("show");
        $("body").addClass("rightpanelopen");
        $(".leftpane").addClass("slidetoleft");
        $(".tileview[data-num='" + this.state.currentTile + "']").addClass("highlight");
        //ModalProperties.resize();
        setTimeout(function () {
           // $('.modal-backdrop').remove();
        }, 500);
        $('#tilefiltersmodal').on('hide.bs.modal', function (event) {
            $("body").removeClass("rightpanelopen");
            $(".leftpane").removeClass("slidetoleft");
            $(".tileview").removeClass("highlight");
            ReactDOM.unmountComponentAtNode(document.querySelector('.tilefilterscontainer'));
        });
        return (<div></div>);
    }
    tilefiltersclose = () => {
        var that = this;
        var currentTile = $(".fullscreenmain");
        $("body").removeClass("rightpanelopen");
        $(".leftpane").removeClass("slidetoleft");
        $(".tileview").removeClass("highlight");
        //$('.modal-backdrop').remove();
        ReactDOM.unmountComponentAtNode(document.querySelector(this.state.el));
        this.resizeTileOnModelToggle(currentTile);
    }
    updateTilesList() {
        var $select = $("select.selectcharttile");
        _.each($(".tileview"), function (tile) {
            var title = $(tile).find(".maxanim4").attr("title");
            var id = $(tile).attr("data-num");
            $($select).append("<option value='" + id + "'>" + title + "</option>")
        });
        $("select.selectcharttile").val(this.state.currentTile);
    }
    updateStoryboardFiltersList() {
        var storyboardFiltersListContainer = $(this.state.el).find(".storyboardfilterslistcontainer");
        $(storyboardFiltersListContainer).empty();
        var today = new Date();
        var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
        $(storyboardFiltersListContainer).append("<div className='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
        ReactDOM.render(<StoryboardFilterTemplate storyboardfilterstoggle={this.storyboardfilterstoggle} chartid={this.state.currentTile} />, document.getElementById(dynamicdiv));
        var that = this;
        var reportFilterObject = [];
        var storlyboardFilterLiveList = $(".tab-filters-container #filteraccordion .acc-content");
        _.each($(storlyboardFilterLiveList), function (i) {
            var tempfilterlist = {};
            tempfilterlist.columnname = $(i).attr("data-id");
            tempfilterlist.columndisplayname = $(i).find(".panelboc-title").text();
            tempfilterlist.dataType = $(i).find(".panelboc-heading").attr("data-type");
            tempfilterlist.chartid = that.state.currentTile;
            tempfilterlist.checked = true;
            reportFilterObject.push(tempfilterlist);
        });
        var tileLevelFilters = ChartInfo.functions.getTileLevelFilters(this.state.currentTile);
        _.each(reportFilterObject, function (filter, i) {
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $(storyboardFiltersListContainer).find('ul.storyboardfilterslist').append("<div className='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
            ReactDOM.render(<StoryboardFiltersListItemTemplate filteritem={filter} />, document.getElementById(dynamicdiv));
            if (filter.checked === true) {
                $(".storyboardfiltertoggleitem input[chart-id='" + filter.chartid + "'][data-columndisplayname='" + filter.columndisplayname + "']").prop('checked', true);
            }
            if (tileLevelFilters != undefined && tileLevelFilters[0] != undefined && tileLevelFilters[0].isStoryboardFiltersEnabled != undefined && tileLevelFilters[0].isStoryboardFiltersEnabled == false) {
                $(".storyboardfiltertoggleitem input[chart-id='" + filter.chartid + "'][data-columndisplayname='" + filter.columndisplayname + "']").prop('checked', false);
            }
        });

        var that = this;
        if (tileLevelFilters != undefined && tileLevelFilters[0].storyboardFilters != undefined) {
            _.each(tileLevelFilters[0].storyboardFilters, function (stfv, index1) {
                $(".storyboardfiltertoggleitem input[chart-id='" + stfv.chartid + "'][data-columndisplayname='" + stfv.columndisplayname + "']").prop('checked', stfv.checked);
            });
        }
        if (tileLevelFilters != undefined && tileLevelFilters[0] != undefined && tileLevelFilters[0].isStoryboardFiltersEnabled != undefined) {
            $("#storyboardfilterstoggle").attr('checked', tileLevelFilters[0].isStoryboardFiltersEnabled);
        }
    }

    updateToggleStatusofStoryBoardFilters() {
        var that = this;
        $('.storyboardfiltertoggleitem').removeClass('disabled');
        var tileLevelFilters = ChartInfo.functions.getTileLevelFilters(this.state.currentTile);
        if (tileLevelFilters != undefined && tileLevelFilters[1].tileFiltersList != undefined) {
            _.each(tileLevelFilters[1].tileFiltersList, function (stfv, index1) {
                $(".storyboardfiltertoggleitem input[chart-id='" + tileLevelFilters[1].chartid + "'][data-columndisplayname='" + stfv.columndisplayname + "']").prop('checked', false);
                $(".storyboardfiltertoggleitem[chartid='" + tileLevelFilters[1].chartid + "'][columndisplayname='" + stfv.columndisplayname + "']").addClass('disabled')
            });
        }
    }
    changeTile = (e) => {
        this.state.currentTile = $(".selectcharttile").val();
        $("#tilefiltersmodal").attr("data-id", this.state.currentTile);
        $('.tilefilterslistcontainer').attr("tile-id", this.state.currentTile);
        $(".tileview").removeClass("highlight");
        $("#tilefilterslist").attr("tile-id", this.state.currentTile);
        $(".tileview[data-num='" + this.state.currentTile + "']").addClass("highlight");
        var tileLevelFilters = ChartInfo.functions.getTileLevelFilters(this.state.currentTile)
        if (tileLevelFilters != undefined && tileLevelFilters[0].storyboardFilters != undefined) {
            this.state.savedStoryboardFiltersList = tileLevelFilters[0].storyboardFilters;
        } else {
            this.state.savedStoryboardFiltersList = undefined;
        }
        this.updateStoryboardFiltersList();
        this.updateTileFiltersList();
        PubSub.publish('appendfilterstotiles', this.state.currentTile);
        this.updateToggleStatusofStoryBoardFilters();
    }
    updateTileFiltersList = () => {
        var tileFiltersListContainer = $(this.state.el).find("#tilefilterslist");
        $(tileFiltersListContainer).empty();
    }
    tilefilterapply = (e) => {
        e.preventDefault();
        var that = this;
        //Update Filter Text
        var filterAccordianLength = $('#tilefilterslist .acc-content').length;
        for (var i = 0; i < filterAccordianLength; i++) {
            var $this = $('#tilefilterslist .acc-content').eq(i);
            var fromRangeVal = $($this).find('.irs-from').text();
            var toRangeVal = $($this).find('.irs-to').text();
            var greaterThanValue = $($this).find('#greaterthanvalue').val();
            var lessThanValue = $($this).find('#lessthanvalue').val();
            $($this).find('#tilefilterslist .filtergreaterthanvalue.filter-error').addClass("hide");
            $($this).find('#tilefilterslist .filterlessthanvalue.filter-error').addClass("hide");
            if (fromRangeVal != undefined && toRangeVal != undefined && $($this).attr('id') != undefined) {
                $('#tilefilterslist .acc-content[data-id="' + $($this).attr('id') + '"] .filteredvalues').text(fromRangeVal + " - " + toRangeVal).attr("title", fromRangeVal + " - " + toRangeVal);
            }
        }
        var totalfilterArray = ChartInfo.functions.getFilters(false, "tilefilterslist");
        if (totalfilterArray != undefined && totalfilterArray != null && totalfilterArray != "" && totalfilterArray.length != 0) {
            _.each(totalfilterArray, function (filter, index) {
                if (filter.dataType != undefined) {
                    var filtercolumndisplayname = filter.columndisplayname.replace(/([;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "");
                    if (filter.dataType == "string") {
                        if (filter.customfield == undefined) {
                            var filtereddatatext = filter["data"].replace(/\@@/g, ', ');
                        } else {
                            var filtereddatatext = filter.customfield.sqlcondition.sqldata.replace(/\@@/g, ', ')
                        }
                        var TotalFilterItems = $("#tilefilterslist .filtervalue[data-name='" + filtercolumndisplayname + "']").length;
                        var unCheckedFilterItems = $("#tilefilterslist .filtervalue[data-name='" + filtercolumndisplayname + "']:not(:checked)").length;
                        if (unCheckedFilterItems == 0) {
                            $('#tilefilterslist .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text("All").attr("title", "All");
                        }
                        //  else if (TotalFilterItems <= 5 || (unCheckedFilterItems > 5 && TotalFilterItems > 5)) {
                        //     $('#tilefilterslist .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text(filtereddatatext).attr("title", filtereddatatext);
                        // } else if (unCheckedFilterItems <= 5) {
                        //     var unCheckedItems = [];
                        //     _.each($("#tilefilterslist .filtervalue[data-name='" + filtercolumndisplayname + "']:not(:checked)"), function (i) {
                        //         unCheckedItems.push($(i).attr("data-value"));
                        //     });
                        //     unCheckedItems = unCheckedItems.join(", ")
                        //     $('#tilefilterslist .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text("Excluding : " + unCheckedItems + "").attr("title", "Excluding : " + unCheckedItems + "");
                        // }
                        else {
                            $('#tilefilterslist .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text(filtereddatatext).attr("title", filtereddatatext);
                        }
                    } else if (filter.dataType == "number") {
                        var fromRange = filter.fromRange,
                            toRange = filter.toRange,
                            customRange = filter.customfiltervalue;
                        if (fromRange == undefined && toRange == undefined) {
                            fromRange = filter.filtergreaterthanvalue;
                            toRange = filter.filterlessthanvalue;
                        }
                        if (customRange == undefined || customRange === "" && (fromRange != undefined && toRange != undefined)) {
                            $('#tilefilterslist .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text(fromRange + " - " + toRange).attr("title", fromRange + " - " + toRange);
                        }
                    } else if (filter.dataType == "date") {
                        var fromDateRange = filter.fromRange,
                            toDateRange = filter.toRange,
                            dateRange = filter.daterange;
                        var dates = $('#tilefilterslist .daterange-center[displayname="' + filter.columndisplayname + '"] span').text();
                        //if(customRange== undefined || customRange===""){
                        $('#tilefilterslist .acc-content[data-id="' + filtercolumndisplayname + '"] .filteredvalues').text(dates).attr("title", dates);
                        //}
                    }
                } else if (filter.parametername != undefined && filter.parametername != "") {
                    if (filter.valueType == "all" || filter.valueType == "range") {
                        $('#tilefilterslist .acc-content[data-id="' + filter.parametername + '"] .filteredvalues').text(filter.parametercurrentvalue).attr("title", filter.parametercurrentvalue);
                    } else if (filter.valueType == "list") {
                        var selectedplistvalue = $('.filtercustomparamerelist[data-id="acc' + filter.parametername + '"] select').val()
                        $('#tilefilterslist .acc-content[data-id="' + filter.parametername + '"] .filteredvalues').text(selectedplistvalue).attr("title", selectedplistvalue);
                    }
                }

                //Update Filter Display Name
                if (filter.filterDisplayName != null && filter.filterDisplayName != undefined) {
                    var targetName = filter.columndisplayname;
                    if (filter.parametername != undefined && filter.parametername != "") {
                        targetName = filter.parametername;
                    }
                    if (filter.filterDisplayName == "") {
                        $("#tilefilterslist .acc-content[data-id='" + targetName.replace(/([;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "") + "'] h4.panelboc-title").text(filter.columndisplayname).attr("title", filter.columndisplayname);
                        $("#tilefilterslist .itemname[data-name='" + targetName.replace(/([;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "") + "']").val(filter.columndisplayname);
                    } else {
                        $("#tilefilterslist .acc-content[data-id='" + targetName.replace(/([;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "") + "'] h4.panelboc-title").text(filter.filterDisplayName).attr("title", filter.filterDisplayName);
                    }
                }
            });
        }
        //Saving Start
        var saveTileFilters = [{ "storyboardFilters": [], "isStoryboardFiltersEnabled": true, chartid: 0 }, { "tileFiltersList": [], chartid: 0 }];

        saveTileFilters[0].chartid = this.state.currentTile;
        saveTileFilters[1].chartid = this.state.currentTile;

        var filterArray = ChartInfo.functions.getFilters(false, "tilefilterslist");
        ChartInfo.functions.getFilters(false);
        if (filterArray != undefined && filterArray.length > 0) {
            saveTileFilters[1].tileFiltersList = filterArray;
        }
        ChartInfo.functions.updateTileLevelFilters(this.state.currentTile, saveTileFilters);
        this.updateToggleStatusofStoryBoardFilters();

        //StoryboardFilters
        saveTileFilters[0].isStoryboardFiltersEnabled = $("#storyboardfilterstoggle").is(':checked');
        _.each($("ul.storyboardfilterslist li.storyboardfiltertoggleitem"), function (i) {
            var tempfilterlist = {};
            tempfilterlist.columnname = $(i).find("input").attr("data-columnname");
            tempfilterlist.columndisplayname = $(i).find("input").attr("data-columndisplayname");
            tempfilterlist.dataType = $(i).find("input").attr("data-datatype");
            tempfilterlist.chartid = that.state.currentTile;
            tempfilterlist.checked = $(i).find("input").is(':checked');
            saveTileFilters[0].storyboardFilters.push(tempfilterlist);

        });
        ChartInfo.functions.updateTileLevelFilters(this.state.currentTile, saveTileFilters);
        var applyObj = {};
        applyObj["pos"] = undefined;
        applyObj["currentchartnum"] = that.state.currentTile;
        applyObj["applyattributes"] = "applyattributes";
        applyObj["dateHistogramInterval"] = undefined;
        applyObj["calculation"] = undefined;
        applyObj["isExcludeorIncludeFilters"] = undefined;
        applyObj["isChartSummaryData"] = undefined;
        applyObj["selecteddobycolumns"] = undefined;
        applyObj["filterArray"] = [];
        applyObj["navigatefrom"] = undefined;
        applyObj["isCustomparameterFilter"] = undefined;
        applyObj["tableInfo"] = undefined;
        PubSub.publish('applyfiltesandredrawchart', applyObj);
    }
    storyboardfilterstoggle = (e) => {
        _.each($("ul.storyboardfilterslist li.storyboardfiltertoggleitem"), function (i) {
            $(".storyboardfiltertoggleitem input[chart-id='" + $(i).find("input").attr('chart-id') + "'][data-columndisplayname='" + $(i).find("input").attr("data-columndisplayname") + "']").prop('checked', $("#storyboardfilterstoggle").is(':checked'));
        })
        this.updateToggleStatusofStoryBoardFilters();
    }
    resizeTileOnModelToggle(currentTile) {
        setTimeout(function () {
           // $(".maxanim1").removeClass("highlight");
            var time = 10
            if ($(".fullscreenmain").length != 1) {
                _.each($(".tileview"), function (i, j) {
                    setTimeout(function () {
                        var acd = {};
                        acd["element"] = i;
                        acd["pos"] = "min";
                        PubSub.publish('adjustchartheight', acd);
                    }, time)
                    time = time + 10;
                });
            } else {
                if ($(".singlereportview.fullscreenmain").length === 1) {
                    $(window).trigger('resize');
                } else {
                    var acd = {};
                    acd["element"] = currentTile;
                    acd["pos"] = "max";
                    PubSub.publish('adjustchartheight', acd);
                }
            }
        }, 150);
    }
}