import React from 'react';
import SummaryTableColumnAttributeHtml from './summarytablecolumnattribute.jsx';
import _ from 'underscore';
import ReactDOM from 'react-dom';
const $=window.$;

export default class SummaryTableColumnAttribute extends React.Component{
    constructor(props){
        super(props);
        this.columnName = props.columnName;
        this.columnDisplayName = props.columnDisplayName;
        this.aggregation = props.aggregation;
        this.dataType = props.dataType;
        this.tableName = props.tableName;
        this.chartnumber = props.chartnumber;
        this.render();
    }
    render(){
        var datatype = this.props.dataType
        var convertedValues = JSON.parse(sessionStorage.getItem("convertedtypes"));
        var that = this;
        if(convertedValues!= null && convertedValues != undefined){
            _.each(convertedValues,(convertValue)=>{
                if(convertValue.columnName == that.props.columnDisplayName){
                    datatype = convertValue.dataType;
                }
            });
        }
        var today = new Date();
        var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
        $('.summarytable-row').append("<div className='" + dynamicdiv + "hide' id='" + dynamicdiv + "'></div>");

        ReactDOM.render(
            <SummaryTableColumnAttributeHtml remove={this.props.remove} chartnumber={this.props.chartnumber} columnName={this.props.columnName} aggregation={this.props.aggregation} dataType={datatype} tableName={this.props.tableName} columnDisplayName={this.props.columnDisplayName} />,document.getElementById(dynamicdiv)
        );
        return(<></>);
    }
    componentDidUpdate(){
        
    }

}