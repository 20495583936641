import React from "react";
import ReactDOM from 'react-dom';
import $ from "jquery/dist/jquery";
import SendRequest from './SendRequest';
import Login from './Login';
import { whitelableMap } from "./js/helpers/utils/whitelable";

export default class RootLicense extends React.Component{
    constructor(props){
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    UNSAFE_componentWillMount() {
        document.body.classList.add("loginbody");
    }
    componentDidMount() {
        $('title').text(whitelableMap["metatitle"]);
        $('meta[name="Keywords"]').attr('content', whitelableMap["metakeyword"]);
        $('meta[name="Description"]').attr('content', whitelableMap["metadescription"]);
        $('meta[name="Author"]').attr('content', whitelableMap["metaauthor"]);
        $('meta[name="theme-color"]').attr('content', whitelableMap["metatheme"]);
        $('link[name="Favicon"]').attr('href', whitelableMap["metafavicon"]);
        $('#birdimg').attr('alt', whitelableMap["alttextbrand"]);
        $('#birdimg').attr('src', whitelableMap["loginlogo"]);
        $('.copyright-footer').text(whitelableMap["loginfootertext"]);
    }
    handleSubmit=(event)=>{
        event.preventDefault();
    let requestHeaders = {
      method: "post",
      headers: new Headers({
        "Content-Type": "application/json"
      })
    }
    let licensekey = $("#licensekey").val()
    var data = new FormData();
    data.append("license", licensekey);
    SendRequest({
      url: "authenticate/updaterootlicensekey",
      queryString: "",
      body: data,
      sucessFunction: props => {
        if(props.rootlicense === "false"){
              this.render();
              $("#licensekey").val("");
          }else if(props.rootlicense === "true"){
            ReactDOM.render(<Login />,document.getElementById("root"));
          }
      },
      rejectFunction: () => { },
      headers: requestHeaders,
    });

    }
    
    render(){

        return (<React.Fragment>
            {/* <div class="rightbg"></div>
            <div id="connections-animation"></div> */}
            <div className="container-fluid height-100 logincontainer-fluid">
                <div className="row height-100 logincontainerrow">
                    <div className="col-md-6 loginrightcontainer">
                        <div className="panelcontainer">
                            <div className="logincontainer">
                                <div className="logo">
                                    <img alt="BIRDonCloud" src="img/whitelable/bird-logo.png" id="birdimg" />
                                    <span></span>
                                </div>

                                <div className="form">
                                    <div className="tab-content">
                                        <div id="otpconformation" style={{ display: "block" }}>
                                            <span className="header-message"></span>
                                            <h1>Update License</h1>
                                            <p className="error"></p>
                                            <form>
                                                <div className="field-wrap">
                                                    <label>License Key:<span className="req">*</span></label>
                                                    <input type="text" autoComplete="off" id="licensekey" name="license" required />
                                                </div>
                                                <button className="button button-block" onClick={this.handleSubmit}>Submit</button>
                                            </form>
                                        </div>

                                    </div>
                                    
                                </div>
                                {/* <!-- /form --> */}
                            </div>
                        </div>
                        <div className="footer copyright-footer">{whitelableMap["footertext"]}</div>
                    </div>
                    <div class="col-md-6 loginleftcontainer"></div>
                    {/* <!-- /left --> */}
                </div>
            </div>
        </React.Fragment>);
    }
}