import React from "react";
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;

export default class CustomMeasureHierarchyModalTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        var that = this.props;
        return (
                // {/* <!-- createcustommeasurehierarchymodalview.js --> */}
            <div className={`modal fade custommeasurehierarchy${that.chartnumber} in`} id="custommeasurehierarchy" tabindex="-1" role="dialog" aria-labelledby="summarytablesettingsmodal" aria-hidden="true" style={{ display: "block" }}>
                <div className="modal-backdrop fade in"></div>
                <div className="modal-dialog modal-sm modal-sm-400 modal-dialog-sm-center modal-dialog-center" style={{ zIndex: "1050" }}>
                    <div className="modal-content">
                        <div className="modal-header boc-modal-hf-padding">
                            <button type="button" className="close white" id="hierarchyclose" onClick={that.closeHierarchyModal} aria-hidden="true" data-number={that.chartnumber} data-dismiss="modal">&times;</button>
                            <h4 className="modal-title" id="mySmallModalLabel"><i className="fa fa-list"></i> {lang["storyboard.measurehierarchy"]}</h4>
                        </div>
                        <div className="modal-body scrollset">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-xs-12 marg-top-10 marg-bot-10">
                                        <div className="modal-height-fix">

                                            <legend className="subhead">{lang["storyboard.hierarchyname"]}</legend>
                                            <div className="col-xs-12 nopadding marg-bot-10">
                                                <input type="text" id="hierarchyname" defaultValue={(that.existinghierarchy.length > 0) ? that.existinghierarchy[0].name : ''} className="modal-form-fields" name="hierarchyname" placeholder={lang["storyboard.hierarchyname"]} />
                                                <span className="help-block hide customhierarchy">{lang["storyboard.validname"]}</span>
                                            </div>
                                            <div className="clear"></div>

                                            <legend className="subhead">
                                            {lang["storyboard.hierarchyorder"]}
								<button className="pull-right btn btn-transparent dropdown-toggle tooltip-left" data-tooltip={lang["storyboard.add"]} id="dropdowncustomhierarchylist" onClick={that.dropdownhierarchylist} aria-expanded="false" data-toggle="dropdown">
                                                    <i className="fa fa-plus-circle"></i>
                                                </button>
                                                <ul className="list-group dropdown-menu axis-add-menu-fix dropdowncustomhierarchylist entitytype" aria-labelledby="dropdownhierarchylist" role="menu"></ul>
                                            </legend>
                                            <span className="help-block hide customhierarchy_li_items marg-left-10">{lang["storyboard.hierarchyerror"]}</span>
                                            <div className="marg-top-10">
                                                <ul className="list-group customhierarchyattributes hierarchyattributes entitytype">
                                                    {(that.existinghierarchy.length > 0) ? (
                                                        that.existinghierarchy[0].hierarchelist.map((list, index) => {
                                                            var dataType = list.dataType;
                                                            var displayName = list.columnDisplayName;
                                                            var agg = list.aggregation;
                                                            if (that.convertedTypes.hasOwnProperty(list.columnName)) {
                                                                dataType = that.convertedTypes[list.columnName];
                                                            }
                                                            if (that.convertedNames != null) {
                                                                if (that.convertedNames.hasOwnProperty(list.columnName)) {
                                                                    displayName = that.convertedNames[list.columnName];
                                                                }
                                                            }
                                                            var dataIcon =  lang["storyboard.abc"];
                                                            if (dataType == "number") {
                                                                dataIcon = "123"
                                                            } else if (dataType == "date" || dataType == "datetime" || dataType === "month" || dataType === "day") {
                                                                dataIcon = <i className='fa fa-calendar'></i>
                                                            } else if (dataType == "customhierarchy" || dataType === "custommeasurehierarchy") {
                                                                dataIcon = <i className='fa fa-list'></i>
                                                            }
                                                           return <li key={index} className="boc-list-item singleliitems" id="itemdisplay" data-name={list.columnName} percentilevalue={list.percentilevalue} title={displayName} data-type={dataType} table-name={list.tableName} agg={agg}>
                                                                <span className="itemtype">{dataIcon}</span>
                                                                <span className="itemname">{displayName}</span>
                                                                {(dataType != 'custommeasurehierarchy') ? (
                                                                    <>
                                                                        <span className="boclabel boclabel-right-5 marg-righ-15 aggregationtext" aria-expanded="false" data-toggle="dropdown" type="button" id="dropdownaggregation"><span>{agg}</span>&nbsp;<i className="fa fa-angle-down"></i></span>
                                                                        <ul role="menu" className="dropdown-menu dropdown-inline ">
                                                                            {(dataType != 'string' && dataType != 'date' && dataType != 'month' && dataType != 'day') ? (
                                                                                <>
                                                                                    <li className="aggregation-li-item aggsum" onClick={this.changeyAxisAggregation} title={lang["storyboard.sum"]}>{lang["storyboard.sum"]}</li>
                                                                                    <li className="aggregation-li-item aggmin" onClick={this.changeyAxisAggregation} title={lang["storyboard.minimum"]}>{lang["storyboard.minimum"]}</li>
                                                                                    <li className="aggregation-li-item aggmax" onClick={this.changeyAxisAggregation} title={lang["storyboard.max"]}>{lang["storyboard.max"]}</li>
                                                                                    <li className="aggregation-li-item aggavg" onClick={this.changeyAxisAggregation} title={lang["storyboard.avg"]}>{lang["storyboard.avg"]}</li>
                                                                                    <li className="aggregation-li-item aggstandarddeviation" onClick={this.changeyAxisAggregation} title={lang["storyboard.standard"]}>{lang["storyboard.standard"]}</li>
                                                                                    <li className="aggregation-li-item aggpercentile" onClick={this.changeyAxisAggregation} title={lang["storyboard.percentile"]} >{lang["storyboard.percentile"]}
															<span className="opacity-effect" ><input type="number" className="percentilebox" placeholder="1" min="25" max="100" defaultValue={list.percentilevalue} /></span>
                                                                                    </li>
                                                                                    <li className="aggregation-li-item aggmedian" onClick={this.changeyAxisAggregation} title={lang["storyboard.median"]}>{lang["storyboard.median"]}</li>
                                                                                </>
                                                                            ) : null}
                                                                            {(dataType == 'date') ? (
                                                                                <>
                                                                                    <li className="aggregation-li-item aggmin" onClick={this.changeyAxisAggregation} title={lang["storyboard.minimum"]}>{lang["storyboard.minimum"]}</li>
                                                                                    <li className="aggregation-li-item aggmax" onClick={this.changeyAxisAggregation} title={lang["storyboard.max"]}>{lang["storyboard.max"]}</li>
                                                                                </>
                                                                            ) : null}
                                                                            <li className="aggregation-li-item aggcountdistinct" onClick={this.changeyAxisAggregation} title={lang["storyboard.countdis"]}>{lang["storyboard.countdistinct"]}</li>
                                                                            <li className="aggregation-li-item aggcount" onClick={this.changeyAxisAggregation} title={lang["storyboard.count"]}>{lang["storyboard.countall"]}</li>
                                                                        </ul>
                                                                    </>
                                                                ) : null}
                                                                <span className="btn-group pull-right">
                                                                    <button className="btn btn-transparent deletecustomhierarchyfield deletehierarchyfield" onClick={this.deleteelement} type="button">
                                                                        <i className="fa fa-trash"></i>
                                                                    </button>
                                                                </span>
                                                            </li>
                                                        })
                                                    ) : null}</ul>
                                            </div>


                                        </div>
                                        {/* <!-- /Modal Height Fix --> */}
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="modal-footer boc-modal-hf-padding">
                            <button type="button" className="btn btn-bird" id="birdhierarchycancel" onClick={that.closeHierarchyModal} data-dismiss="modal" aria-hidden="true"><i className="fa fa-times"></i> {lang["storyboard.cancelbtn"]}</button>
                            <button type="button" className="btn btn-bird customhierarchy_apply" onClick={that.applycustomhierarchy} id="customhierarchyapply" data-num={that.chartnumber} tab-id="0" tab-name="" aria-hidden="true"><i className="fa fa-check"></i> {lang["storyboard.applybtn"]}</button>
                        </div>
                    </div>
                    {/* <!-- /.modal-content --> */}
                </div>
                {/* <!-- /.modal-dialog --> */}
            </div>
        );
    }
    deleteelement(e) {
        var $this = e.currentTarget;
        $($this).parent().parent().remove()
    }
    changeyAxisAggregation(e) {
        $(e.currentTarget).parent().parent().find(".aggregation-li-item").removeClass("active");
        $(e.currentTarget).addClass("active");
        $(e.currentTarget).parent().parent().find(".aggregationtext>span").text($(e.currentTarget).text());
        var percentileValue = $(e.currentTarget).parent().parent().find(".percentilebox").val();
        $(e.currentTarget).parent().parent().attr("percentile-value", percentileValue);
        $(e.currentTarget).parent().parent().attr("percentilevalue", percentileValue);
        $(e.currentTarget).parent().parent().attr("data-agg", $(e.currentTarget).attr("title"));
        $(e.currentTarget).parent().parent().attr("agg", $(e.currentTarget).text().trim());
    }
}