import React from "react";
import { lang } from "../js/helpers/utils/languages/language";
import PubSub from 'pubsub-js'

export default class YaxisAttributesTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sortType: props.sortType
        };
        this.createCustomFiled = this.createCustomFiled.bind(this);
        this.sortingorder = this.sortingorder.bind(this);
    }

    render() {
        var that = this.props;
        var columnicon;
        if (that.iscustomfunction == 1 && that.iscustomfunction != undefined) {
            columnicon = <span className="function">{lang["storyboard.fx"]}</span>
        } else if (that.yaxiscolumnType === 'customaggregatedfield') {
            columnicon = <span className="function">{lang["storyboard.fx"]}</span>;
        } else if (that.yaxiscolumnType === 'custommeasurehierarchy') {
            columnicon = <i className="fa fa-list"></i>
        } else if (that.yaxiscolumnType === 'string') {
            columnicon = <span>{lang["storyboard.abc"]}</span>;
        }
        else if (that.yaxiscolumnType === 'number') {
            columnicon = <>123</>;
        } else if (that.yaxiscolumnType === 'date') {
            columnicon = <i className="fa fa-calendar"></i>;
        }
        var chartType = that.chartType;
        var conditionstate = '';
        if (chartType == "pie" || chartType == "donut" || chartType == "funnel" || chartType == "pyramid" || chartType == "sankey" || chartType == "wordcloud" || chartType == "boxplot" || chartType == "world" || chartType == "countrymap" || chartType == "indiamap" || chartType == "leaflet" || chartType == "srilankamap" || chartType == "multicolorbar" || chartType == "trellis" || chartType == "waterfall" || chartType == "heatmap" || chartType == "textchart" || chartType == "semicircle" || chartType == "3dpie" || chartType == "sparkline") {
            conditionstate = "hide";
        }
        var compareDateState = '';
        var yAxisAlreadyExist = false;
        if(window.$(".yaxisattributes .singleliitems").length>0){
            yAxisAlreadyExist = true;
        }
        var comparedaterangecharttpes =  ["bar", "stackedbar", "line", "spline", "area", "stackedarea"];
        if (comparedaterangecharttpes.indexOf(chartType)==-1||yAxisAlreadyExist){
            compareDateState = "hide";
        }
        var dropup = "";
        if (that.viewmode === 'sortingvalues') {
            /*dropup="dropup"*/
        }

        return (<div>
            {/* <!-- yaxisattributesview.js --> */}
            {((that.viewmode === 'values' || that.viewmode === 'sortingvalues') && that.viewmode != undefined) ? (
                <li className="boc-list-item singleliitems yaxisdropdownlist" id="Yitemdisplay" data-name={that.yaxiscolumnName} iscustomfunction={that.iscustomfunction} data-agg={that.aggregation} title={that.yaxiscolumnDisplayName} data-type={that.yaxiscolumnType} table-name={that.yaxistableName} percentile-value={that.percentileValue} data-num-yaxis={that.id}>
                    <span className="itemcontainer">
                        {(that.iscustomfunction == 1 && that.iscustomfunction != undefined) ? (
                            <span className="itemtype callcustommeasure" id="callcustommeasures" onClick={this.createCustomFiled} data-edit="edit" iscustomfunction={that.iscustomfunction} data-name={that.yaxiscolumnName} title={lang["storyboard.editfunction"]}>{columnicon}</span>
                        ) : (that.yaxiscolumnType === 'customaggregatedfield') ? (
                            <span className="itemtype function createcustommeasure" onClick={this.createcustommeasure} data-name={that.yaxiscolumnName} title={lang["storyboard.editmeasure"]} data-edit="edit">{columnicon}</span>
                        ) : (that.yaxiscolumnType === 'custommeasurehierarchy') ? (
                            <span className="itemtype createcustomhierarchymeasure" onClick={this.createcustomhierarchymeasure} data-name={that.yaxiscolumnName} title={lang["storyboard.edithierarchy"]} data-edit="edit">{columnicon}</span>
                        ) : (
                                        <span className="itemtype">{columnicon}</span>
                                    )
                        }
                        <span className="itemname" onDoubleClick={that.editname} title={that.yaxiscolumnDisplayName}>{that.yaxiscolumnDisplayName}</span>
                        {(that.viewmode != 'sortingvalues') ? (
                            <>
                                <span className="itemedit" title="" aria-expanded="false" data-toggle="dropdown">
                                    <span type="button" data-name={that.yaxiscolumnDisplayName} className="" >
                                        <i className="expandinghamburger"></i>
                                    </span>
                                </span>
                                <ul role="menu" className="dropdown-menu dropdown-menu-right">
                                    {(that.iscustomfunction == 1 && that.iscustomfunction != undefined) ? (
                                        <li className="cfrename" onClick={that.editcfname}>{lang["storyboard.rename"]}</li>
                                    ) :
                                        that.features.map((featuresModule, index) => {

                                            if (that.is_storyboard == "N") {
                                                if (featuresModule.moduleName == 'my_report') {
                                                    return featuresModule.features.map((feature, index) => {
                                                        if (feature.featureName == 'viewreport_maximize_rename_column' && feature.allow == true) {
                                                            return <li key={index} className="axisrename" onClick={that.editname} title={lang["storyboard.rename"]}>{lang["storyboard.rename"]}</li>
                                                        }
                                                    })
                                                }
                                            }

                                            if (that.is_storyboard == "Y") {
                                                if (featuresModule.moduleName == 'story_board') {
                                                    return featuresModule.features.map((feature, index) => {
                                                        if (feature.featureName == 'viewreport_rename_column' && feature.allow == true) {
                                                            return <li className="axisrename" onClick={that.editname} title={lang["storyboard.rename"]}>{lang["storyboard.rename"]}</li>
                                                        }
                                                    })
                                                }
                                            }
                                        })
                                    }
                                    <li className="axisduplicate" onClick={that.axisduplicate} title={lang["storyboard.duplicate"]}>{lang["storyboard.duplicate"]}</li>
                                    <li className={`conditionalshow ${conditionstate}`} onClick={that.conditionalshow} title={lang["storyboard.conditionalformat"]} data-name={that.yaxiscolumnDisplayName} data-num={that.chartNumber} tab-id="0" tab-name="" data-num-yaxis={that.id}>{lang["storyboard.conditionalformat"]}</li>
                                    <li className={`comaredateshow ${compareDateState}`} onClick={that.compareDateShow} title="compareDateRange" data-name={that.yaxiscolumnDisplayName} data-num={that.chartNumber} tab-id="0" tab-name="" data-num-yaxis={that.id}>Add Comparision Series</li>
                                </ul>
                            </>
                        ) : null}
                    </span>
                    <span className="itemoptions">
                        <>
                            <span className="itemremove">
                                <button className="btn btn-transparent deleteyaxis" onClick={that.deleteelement} type="button" title={lang["storyboard.remove"]}><i className="fa fa-trash-o"></i></button>
                            </span>

                            <span className={`itemaggregation ${dropup}`}>
                                <>
                                    {(that.yaxiscolumnType != 'customaggregatedfield' && that.yaxiscolumnType != 'custommeasurehierarchy' && chartType != 'mlmodel') ? (
                                        <>
                                            {(chartType == "predictionchart") ? (
                                                (that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                                                    <span className="aggregationtext" aria-expanded="false" data-toggle="dropdown">[ {that.aggregation.toLowerCase() == "none" ? "count" : that.aggregation} ]</span>
                                                ) : null
                                            ) : (
                                                    <>{(that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                                                        <span className="aggregationtext" aria-expanded="false" data-toggle="dropdown">[ {
                                                            (that.aggregation === 'Countdistinct') ? "Count (distinct)" : (that.aggregation == 'Countall') ? "Count (all)" : (that.aggregation == 'Percentile') ? "Percentile (" + that.percentileValue + ")" :that.aggregation
                                                        } ]</span>
                                                    ) : null}</>
                                                )}

                                            <ul role="menu" className="dropdown-menu">
                                                {(that.yaxiscolumnType != 'string' && that.yaxiscolumnType != 'date' && that.yaxiscolumnType != 'month' && that.yaxiscolumnType != 'day') ? (
                                                    <>{(that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                                                        <>
                                                            <li className="aggregation-li-item aggsum" onClick={that.changeyAxisAggregation} title={lang["storyboard.sum"]}>{lang["storyboard.sum"]}</li>
                                                            <li className="aggregation-li-item aggmin" onClick={that.changeyAxisAggregation} title={lang["storyboard.minimum"]}>{lang["storyboard.minimum"]}</li>
                                                            <li className="aggregation-li-item aggmax" onClick={that.changeyAxisAggregation} title={lang["storyboard.max"]}>{lang["storyboard.max"]}</li>
                                                            <li className="aggregation-li-item aggavg" onClick={that.changeyAxisAggregation} title={lang["storyboard.avg"]}>{lang["storyboard.avg"]}</li>
                                                        </>
                                                    ) : null}
                                                        {(that.isDirect == 0) ? (
                                                            <li className="aggregation-li-item aggstandarddeviation" onClick={that.changeyAxisAggregation} title={lang["storyboard.standard"]}>{lang["storyboard.standard"]}</li>
                                                        ) : null}
                                                        {(that.isDirect == 0) ? (
                                                            <li className="aggregation-li-item aggpercentile" onClick={that.changeyAxisAggregation} title={lang["storyboard.percentile"]}>{lang["storyboard.percentile"]}
                                                                <span className="opacity-effect" ><input type="number" className="percentilebox" placeholder="1" min="25" max="100" defaultValue={that.percentileValue} /></span>
                                                            </li>
                                                        ) : null}
                                                        {(that.isDirect == 0) ? (
                                                            <li className="aggregation-li-item aggmedian" onClick={that.changeyAxisAggregation} title={lang["storyboard.median"]}>{lang["storyboard.median"]}</li>
                                                        ) : null}
                                                        {(that.isDirect == 0) ? (
                                                             <li className="aggregation-li-item aggnone" onClick={that.changeyAxisAggregation} title={lang["storyboard.none"]}>{lang["storyboard.none"]}</li> 
                                                        ) : null}</>
                                                ) : null}

                                                {(that.yaxiscolumnType == 'date') ? (
                                                    (that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                                                       <> <li className="aggregation-li-item aggmin" onClick={that.changeyAxisAggregation} title={lang["storyboard.minimum"]}>{lang["storyboard.minimum"]}</li>
                                                        <li className="aggregation-li-item aggmax" onClick={that.changeyAxisAggregation} title={lang["storyboard.max"]}>{lang["storyboard.max"]}</li>
                                                    </>) : null
                                                ) : null}

                                                {(that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                                                    <li className="aggregation-li-item aggcountdistinct" onClick={that.changeyAxisAggregation} title={lang["storyboard.countdistinct"]}>{lang["storyboard.countdistinct"]}</li>
                                                ) : null}
                                                {(that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                                                    <li className="aggregation-li-item aggcount" onClick={that.changeyAxisAggregation} title={lang["storyboard.count"]}>{lang["storyboard.countall"]}</li>
                                                ) : null}
                                                {(that.yaxiscolumnType != 'string' && that.yaxiscolumnType != 'date' && that.yaxiscolumnType != 'month' && that.yaxiscolumnType != 'day' && that.isDirect == 1) ? (
                                                    <li className="aggregation-li-item aggraw" onClick={that.changeyAxisAggregation} title={lang["storyboard.Raw"]}>{lang["storyboard.Raw"]}</li>
                                                ) : null}
                                            </ul>
                                        </>
                                    ) : null}

                                    {(that.viewmode === 'sortingvalues') ? (
                                        <>
                                            <span className="dropdown">
                                                <>
                                                    <span className="sortingorder" aria-expanded="false" data-toggle="dropdown" title={lang["storyboard.sortingorder"]}>{this.state.sortType == "desc" ? "[ DSC ]" : "[ ASC ]"}</span>
                                                    <ul className="orderby dropdown-menu" role="menu">

                                                        {(this.state.sortType === "desc") ? (
                                                            <><li><label><input type="radio" value="asc" onClick={this.sortingorder} name="sortby" id="sortby2" /> {lang["storyboard.ascending"]}</label></li>
                                                                <li className="active"><label><input type="radio" onClick={this.sortingorder} value="desc" name="sortby" id="sortby1" checked="checked" /> {lang["storyboard.descending"]}</label></li></>
                                                        ) : (
                                                                <><li className="active"><label><input type="radio" onClick={this.sortingorder} value="asc" name="sortby" id="sortby2" checked="checked" /> {lang["storyboard.ascending"]}</label></li>
                                                                    <li><label><input type="radio" value="desc" onClick={this.sortingorder} name="sortby" id="sortby1" /> {lang["storyboard.descending"]}</label></li></>
                                                            )}

                                                    </ul>
                                                </>
                                            </span>
                                        </>
                                    ) : null}
                                </>
                            </span>
                        </>
                    </span>
                    <span className="itemconditions">
                        <div className="attrabutesformatting hide" data-name={that.yaxiscolumnDisplayName} data-num-yaxis={that.id}>
                            <span className="conditionaltitle">{lang["storyboard.conditionalformat"]} <button className={`btn btn-bird tooltip-left addconditionbtn pull-right ${that.yaxiscolumnDisplayName}`} onClick={that.addconditionbtn} data-tooltip={lang["storyboard.add"]} data-name={that.yaxiscolumnDisplayName} data-num-yaxis={that.id} title={lang["storyboard.Addcondition"]}><i className="fa fa-plus-circle"></i></button></span>
                            <div className="conditionalformat1 " data-name={that.yaxiscolumnDisplayName} data-num-yaxis={that.id}>
                            </div>
                            <span className="conditionbutton">
                                <button type="button" className="btn btn-bird btn-sm conditioncancel" onClick={that.conditioncancel} data-name={that.yaxiscolumnDisplayName} data-num-yaxis={that.id} title={lang["storyboard.cancelbtn"]}>{lang["storyboard.cancelbtn"]}</button>
                                <button type="button" className="btn btn-bird btn-sm btn-highlight marg-left-15 applyattributes" onClick={this.applyattributes} id="applyattributesatyle" aria-hidden="true" data-num={that.chartNumber} tab-id="0" tab-name="" data-num-yaxis={that.id} title={lang["storyboard.applybtn"]}>{lang["storyboard.applybtn"]}</button>
                            </span>
                        </div>
                    </span>
                </li>

            ) : ((that.viewmode !== undefined && that.viewmode === 'smartvalues')||(that.chartType!=undefined && that.chartType === 'mlmodel')) ? (

                <li className="boc-list-item singleliitems" data-name={that.yaxiscolumnName} data-agg={that.aggregation} data-num-yaxis={that.id}
                    data-type={that.yaxiscolumnType} title={that.yaxiscolumnDisplayName} table-name={that.yaxistableName} percentile-value={that.percentileValue}>
                    <span className="itemtype">{columnicon}</span>
                    <span className="itemname" >{that.yaxiscolumnDisplayName}</span>
                    <span className="btn-group pull-right">
                        <button className="btn btn-transparent deleteyaxis" onClick={that.deleteelement} type="button">
                            <i className="fa fa-trash"></i>
                        </button>
                    </span>
                    <>
                        {(that.yaxiscolumnType != 'customaggregatedfield' && that.yaxiscolumnType != 'custommeasurehierarchy' && chartType != 'mlmodel') ? (
                            <>
                                {(chartType == "predictionchart") ? (
                                    (that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                                        <span className="boclabel boclabel-right-10 opacity-effect aggregationtext" aria-expanded="false" data-toggle="dropdown">{that.aggregation.toLowerCase() == "none" ? "count" : that.aggregation}&nbsp;<i className="fa fa-angle-down"></i></span>
                                    ) : null
                                ) : (
                                        (that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                                            <span className="boclabel boclabel-right-10 opacity-effect aggregationtext" aria-expanded="false" data-toggle="dropdown">{that.aggregation}&nbsp;<i className="fa fa-angle-down"></i></span>
                                        ) : null
                                    )}

                                <ul role="menu" className="dropdown-menu dropdown-inline ">
                                    {(that.yaxiscolumnType != 'string' && that.yaxiscolumnType != 'date' && that.yaxiscolumnType != 'month' && that.yaxiscolumnType != 'day') ? (
                                        (that.isDirect == 0 || that.isStoredProcedure == 0) ? (<li className="aggregation-li-item aggsum" onClick={that.changeyAxisAggregation} title={lang["storyboard.sum"]}>{lang["storyboard.sum"]}</li>) : null
                                            (that.isDirect == 0 || that.isStoredProcedure == 0) ? (<li className="aggregation-li-item aggmin" onClick={that.changeyAxisAggregation} title={lang["storyboard.minimum"]}>{lang["storyboard.minimum"]}</li>) : null
                                                (that.isDirect == 0 || that.isStoredProcedure == 0) ? (<li className="aggregation-li-item aggmax" onClick={that.changeyAxisAggregation} title={lang["storyboard.max"]}>{lang["storyboard.max"]}</li>) : null
                                                    (that.isDirect == 0 || that.isStoredProcedure == 0) ? (<li className="aggregation-li-item aggavg" onClick={that.changeyAxisAggregation} title={lang["storyboard.avg"]}>{lang["storyboard.avg"]}</li>) : null
                                                        // <!-- <li className="aggregation-li-item aggrunningsum" title="Running Sum">Running Sum</li>
                                                        // <li className="aggregation-li-item aggrunningaverage" title="Running Average">Running Average</li> -->
                                                        // <!-- <%if(that.isDirect ==0){ %><li className="aggregation-li-item aggvariance" title="Variance">Variance</li><%}%> -->
                                                        (that.isDirect == 0 || that.isStoredProcedure == 0) ? (<li className="aggregation-li-item aggstandarddeviation" onClick={that.changeyAxisAggregation} title={lang["storyboard.standard"]}>{lang["storyboard.standard"]}</li>) : null
                                                            // <!-- <%if(that.isDirect ==0){ %><li className="aggregation-li-item aggsumofsquares" title="Sum of Squares">Sum of Squares</li><%}%> -->
                                                            (that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                                                                <li className="aggregation-li-item aggpercentile" onClick={that.changeyAxisAggregation} title={lang["storyboard.percentile"]} >{lang["storyboard.percentile"]}
                                                                    <span className="opacity-effect" ><input type="number" className="percentilebox" placeholder="1" min="25" max="100" value={that.percentileValue} /></span>
                                                                </li>
                                                            ) : null
                                                                (that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                                                                    <li className="aggregation-li-item aggmedian" onClick={that.changeyAxisAggregation} title={lang["storyboard.median"]}>{lang["storyboard.median"]}</li>
                                                                ) : null

                                    ) : null}

                                    {(that.yaxiscolumnType == 'date') ? (
                                        (that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                                            <li className="aggregation-li-item aggmin" onClick={that.changeyAxisAggregation} title={lang["storyboard.minimum"]}>{lang["storyboard.minimum"]}</li>
                                        ) : null
                                            (that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                                                    <li className="aggregation-li-item aggmax" onClick={that.changeyAxisAggregation} title={lang["storyboard.max"]}>{lang["storyboard.max"]}</li>
                                                ) : null
                                    ) : null}

                                    {(that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                                        <li className="aggregation-li-item aggcountdistinct" onClick={that.changeyAxisAggregation} title={lang["storyboard.countdis"]}>{lang["storyboard.countdis"]}</li>
                                    ) : null}
                                    {(that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                                        <li className="aggregation-li-item aggcount" onClick={that.changeyAxisAggregation} title={lang["storyboard.count"]}>{lang["storyboard.countall"]}</li>
                                    ) : null}
                                </ul>
                            </>
                        ) : null}
                    </>
                </li>
            ) : (

                        <li className="boc-list-item singleliitems" data-name={that.yaxiscolumnName} data-agg={that.aggregation} data-num-yaxis={that.id}
                            data-type={that.yaxiscolumnType} title={that.yaxiscolumnDisplayName} table-name={that.yaxistableName} percentile-value={that.percentileValue}>
                            <span className="fa-stack fa-sm marg-righ-5">
                                {(that.yaxiscolumnType === 'custommeasurehierarchy') ? (
                                    <> <i className="fa fa-circle fa-stack-2x maxtheme6"></i>
                                        <i className="fa fa-list-ol fa-stack-1x fa-inverse"></i></>
                                ) : (
                                        <>  <i className="fa fa-circle fa-stack-2x maxtheme3"></i>
                                            <i className="fa fa-list fa-stack-1x fa-inverse"></i></>
                                    )}
                            </span>
                            <span className="itemname">{that.yaxiscolumnDisplayName}</span>
                            <span className="btn-group pull-right">
                                <button className="btn btn-transparent deleteyaxis" onClick={that.deleteelement} type="button">
                                    <i className="fa fa-times fa-inverse"></i>
                                </button>
                            </span>
                            {(that.yaxiscolumnType != 'customaggregatedfield' && that.yaxiscolumnType != 'custommeasurehierarchy' && chartType != 'mlmodel') ? (
                                <>
                                    {(chartType == "predictionchart") ? (
                                        (that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                                            <span className="boclabel boclabel-right-10 opacity-effect aggregationtext" aria-expanded="false" data-toggle="dropdown">{that.aggregation.toLowerCase() == "none" ? "count" : that.aggregation}&nbsp;<i className="fa fa-angle-down"></i></span>
                                        ) : null
                                    ) : (
                                            (that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                                                <span className="boclabel boclabel-right-10 opacity-effect aggregationtext" aria-expanded="false" data-toggle="dropdown">{that.aggregation}&nbsp;<i className="fa fa-angle-down"></i></span>
                                            ) : null
                                        )}


                                    < ul role="menu" className="dropdown-menu dropdown-inline ">
                                        {(that.yaxiscolumnType != 'string' && that.yaxiscolumnType != 'date' && that.yaxiscolumnType != 'month' && that.yaxiscolumnType != 'day') ? (
                                            (that.isDirect == 0 || that.isStoredProcedure == 0) ? (<li className="aggregation-li-item aggsum" onClick={that.changeyAxisAggregation} title={lang["storyboard.sum"]}>{lang["storyboard.sum"]}</li>) : null
                                                (that.isDirect == 0 || that.isStoredProcedure == 0) ? (<li className="aggregation-li-item aggmin" onClick={that.changeyAxisAggregation} title={lang["storyboard.minimum"]}>{lang["storyboard.minimum"]}</li>) : null
                                                    (that.isDirect == 0 || that.isStoredProcedure == 0) ? (<li className="aggregation-li-item aggmax" onClick={that.changeyAxisAggregation} title={lang["storyboard.max"]}>{lang["storyboard.max"]}</li>) : null
                                                        (that.isDirect == 0 || that.isStoredProcedure == 0) ? (<li className="aggregation-li-item aggavg" onClick={that.changeyAxisAggregation} title={lang["storyboard.avg"]}>{lang["storyboard.avg"]}</li>) : null
                                                            // <!-- <li className="aggregation-li-item aggrunningsum" title="Running Sum">Running Sum</li>
                                                            // <li className="aggregation-li-item aggrunningaverage" title="Running Average">Running Average</li> -->
                                                            // <!-- <%if(that.isDirect ==0){ %><li className="aggregation-li-item aggvariance" title="Variance">Variance</li><%}%> -->
                                                            (that.isDirect == 0) ? (<li className="aggregation-li-item aggstandarddeviation" onClick={that.changeyAxisAggregation} title={lang["storyboard.standard"]}>{lang["storyboard.standard"]}</li>) : null
                                                                (that.isDirect == 0) ? (
                                                                    <><li className="aggregation-li-item aggpercentile" onClick={that.changeyAxisAggregation} title={lang["storyboard.percentile"]} >{lang["storyboard.percentile"]}
                    <span className="opacity-effect" ><input type="number" className="percentilebox" placeholder="1" min="25" max="100" value={that.percentileValue} /></span>
                                                                    </li></>
                                                                ) : null
                                                                    (that.isDirect == 0) ? (<li className="aggregation-li-item aggmedian" onClick={that.changeyAxisAggregation} title={lang["storyboard.median"]}>{lang["storyboard.median"]}</li>) : null

                                        ) : null}
                                        {(that.yaxiscolumnType == 'date') ? (
                                            (that.isDirect == 0 || that.isStoredProcedure == 0) ? (<li className="aggregation-li-item aggmin" onClick={that.changeyAxisAggregation} title={lang["storyboard.minimum"]}>{lang["storyboard.minimum"]}</li>) : null
                                                (that.isDirect == 0 || that.isStoredProcedure == 0) ? (<li className="aggregation-li-item aggmax" onClick={that.changeyAxisAggregation} title={lang["storyboard.max"]}>{lang["storyboard.max"]}</li>) : null
                                        ) : null}
                                        {(that.isDirect == 0 || that.isStoredProcedure == 0) ? (<li className="aggregation-li-item aggcountdistinct" onClick={that.changeyAxisAggregation} title={lang["storyboard.countdis"]}>{lang["storyboard.countdistinct"]}</li>) : null}
                                        {(that.isDirect == 0 || that.isStoredProcedure == 0) ? (<li className="aggregation-li-item aggcount" onClick={that.changeyAxisAggregation} title={lang["storyboard.count"]}>{lang["storyboard.countall"]}</li>) : null}
                                    </ul></>
                            ) : null}
                        </li>
                    )
            }
        </div>);
    }
    sortingorder(e) {
        var $this = e.currentTarget;
        this.setState({ sortType: $this.getAttribute("value") })
    }
    createCustomFiled(e) {
        PubSub.publish('createCustomFiled', e.currentTarget);
    }
    createcustommeasure(e) {
        PubSub.publish('createcustommeasure', e.currentTarget);
    } 
    createcustomhierarchymeasure(e) {
        PubSub.publish('createcustomhierarchymeasure', e.currentTarget);
    }
    applyattributes(e) {
        PubSub.publish('applyattributes', e);
    }
    
}