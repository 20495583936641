import React from "react";
import { Link } from "react-router-dom";
import FullScreen from "react-request-fullscreen";
import { whitelableMap } from "../js/helpers/utils/whitelable";
import * as ChartInfo from '../js/helpers/utils/chartinfo';
import HamburgerWorkspacesList from '../Datamain/HamburgerWorkspacesList.jsx';
import HamburgerDatamodelsList from "../Datamain/HamburgerDatamodelsList.jsx"
import HamburgerReportsList from "../Visualization/HamburgerReportsList.jsx"
import ChangePasswordTemplate from './changepasswordtemplate.jsx';
import { lang } from "../js/helpers/utils/languages/language";
import NewBirdReportView from './aboutbird'
import PageNavigation from './pagenavigation'
import ReactDOM from 'react-dom';
import PubSub from 'pubsub-js'
import SendRequest from '../SendRequest';
const $ = window.$;

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      features: props.features,
      isFullscreenActive: false,
      pageType: this.props.pageType,
      displayHamburger: true,
      isHamburgerClicked: false,
      isChangePasswordClicked: false,
      displayHeader: true,
      displayFooter: true,
      parentuserrole: this.props.parentuserrole != undefined ? this.props.parentuserrole : "ROLE_Power_User",
      isAdminEnable: false,

      notificationsCount: 0,
      notifications: [
        {
          data: [
            {
              notification_status: null,
              vizType: "story",
              vizid: null,
              dataModelId: null,
              reportName: null,
              sharedUserName: null,
              create_date: Date(),
              user_comments: null,
              reportId: null,
              alertNotificationCollection: [
                {
                  reportId: null,
                  alertname: null,
                  createDate: null,
                  message: null,
                  notification_status: null,
                  publishinfo_id: null
                }
              ]
            }
          ]
        }
      ],
      securityQuestions: [

      ]
    };
    this.aboutBird = this.aboutBird.bind(this);
    this.checkForChangesdone = this.checkForChangesdone.bind(this);
    this.logOut = this.logOut.bind(this);
    var that = this;
    if (window.location.pathname.indexOf("admin") != -1) {
      that.state.isAdminEnable = true;
    }
    var urlparameters = window.location.search.replace("?", "").split("&");
    $.each(urlparameters, function (i, u) {
      var parameter = u.split("=");
      if (parameter[0].toLowerCase() == "topheader" && parameter[1].toLowerCase() == "false") {
        that.state.displayHeader = false;
        $("body").addClass("disabledheader")
      }else if (parameter[0].toLowerCase() == "topheader" && parameter[1].toLowerCase() == "true") {
        $("body").removeClass("disabledheader")
      }
      if (parameter[0].toLowerCase() == "leftpanel" && parameter[1].toLowerCase() == "false") {
        that.state.displayHamburger = false;
      }
      if (parameter[0].toLowerCase() == "bottomfooter" && parameter[1].toLowerCase() == "false") {
        that.state.displayFooter = false;
      }
    });
  }
  checkForChangesdone(e) {
    $(".connection-center").removeClass("connection-in");
    var $this = e.currentTarget;
    e.preventDefault();
    if ($(".filtercustomfield").is(':visible')) {
      $(".filtercustomfield").hide();
    }
    var id = $($this).attr("id");
    var href = "/" + window.localStorage.getItem("appName") + "/welcome";
    if (id == "datapageid") {
      this.state.pageType = "datapage"
      href = href + "/businessmodels";
    } else if (id == "visualizationid") {
      this.state.pageType = "dashboardpage"
      href = href + "/visualization";
    } else if (id == "notificationsid") {
      this.state.pageType = "notificationspage"
      href = href + "/notifications";
    } else if (id == "searchid") {
      this.state.pageType = "notificationspage"
      href = href + "/search";
    }
    var currentpath = window.location.pathname;
    if (currentpath.indexOf("viewreport") > 0 || currentpath.indexOf("viewstoryboard") > 0 || currentpath.indexOf("customqueryView") > 0 || currentpath.indexOf("sharereport") > 0 || currentpath.indexOf("embeddedreport") > 0 || currentpath.indexOf("schedulereport") > 0 || currentpath.indexOf("customviewreport") > 0 || currentpath.indexOf("customquery") > 0 || currentpath.indexOf("viewcustomstoryboardreport") > 0 || currentpath.indexOf("viewmodel") > 0 || currentpath.indexOf("alertreport") >0) {
      var changesdone = sessionStorage.getItem("changesdone");
      if (changesdone == 1) {
        var pageNavigation = new PageNavigation({
          href: href,
          history: this.props.history,
          logout: this.logout,
          navigatedfrom: this.navigatedfrom,
          id: id
        });
      } else {
        this.navigatedfrom(id);
        sessionStorage.removeItem("customStoryBoardReportId");
        this.props.history.push(href);
      }
    } else {
      this.navigatedfrom(id);
      this.props.history.push(href);
    }
  }
  navigatedfrom(id) {
    $(".spinner").show();
    PubSub.unsubscribe('dropdownyaxis');
    PubSub.unsubscribe('dropdownxaxis');
    PubSub.unsubscribe('legenddropdown');
    PubSub.unsubscribe('dropdowntooltip');
    PubSub.unsubscribe('dropdownreferenceline');
    PubSub.unsubscribe('dropdownmetricdatefield');
    PubSub.unsubscribe('dropdowncustomsolidgauge');
    PubSub.unsubscribe('showyAxisDropDowninSorting');
    PubSub.unsubscribe('createCustomFiled');
    PubSub.unsubscribe('createDillDownHierarchy');
    PubSub.unsubscribe('createrange');
    PubSub.unsubscribe('createcustommeasure');
    PubSub.unsubscribe('createcustomhierarchymeasure');
    PubSub.unsubscribe('applyfiltesandredrawchart');
    PubSub.unsubscribe('viewSummaryTabe');
    PubSub.unsubscribe('viewDataTable');
    PubSub.unsubscribe('togglevisualizationsettings');
    PubSub.unsubscribe('addfilteritem');
    $('body').removeClass('rightpanelopen');
    $(".rightattributes").removeClass("open");
    $("body").removeClass("multifact_enabled morning");
    $('body').removeClass('nlp-open');
    $("body").removeClass("maximized");
    $("body").removeClass("filters-hidden");
    $("body").removeClass("modal-open");
    $("body").removeClass("storybookpage");
    var keys = Object.keys(sessionStorage);
    for (var i = 0; i < keys.length; i++) {
      var key = keys[i];
      if (key.indexOf("customhierarchy") >= 0) {
        sessionStorage.removeItem(key);
      }
    }
    if (id == "datapageid") {
      $("body").addClass("datamainpage").removeClass("datahubpage dataworkspacepage datamodelspage datagovernancepage searchpage");
      $("body").removeClass("rightpanelopen");
      $('#ui-datepicker-div').hide();
      $('.clockpicker-popover').remove();
      sessionStorage.setItem("changesdone", 0);
      sessionStorage.removeItem("convertedtypes");
      sessionStorage.removeItem("isconvettedtypesapplied");
      sessionStorage.removeItem("pos");
      sessionStorage.removeItem("renameMeasuresorDimensions");
      sessionStorage.removeItem("linkedreports");
      $(".pivotdataview").remove();
    } else if (id == "visualizationid") {
      $("body").removeClass("datamainpage datahubpage dataworkspacepage datamodelspage datagovernancepage searchpage");
      $("body").removeClass("rightpanelopen");
      $("body").removeClass("storybookpage");
      //this.navigatedfrom();
      $('#ui-datepicker-div').hide();
      $('.clockpicker-popover').remove();
      sessionStorage.setItem("changesdone", 0);
      sessionStorage.removeItem("convertedtypes");
      sessionStorage.removeItem("isconvettedtypesapplied");
      sessionStorage.removeItem("pos");
      sessionStorage.removeItem("renameMeasuresorDimensions");
      sessionStorage.removeItem("linkedreports");
      $(".pivotdataview").remove();
    } else if (id == "notificationsid") {
      $("body").removeClass("datamainpage datahubpage dataworkspacepage datamodelspage datagovernancepage searchpage");
      $("body").removeClass("rightpanelopen");
      $("#main").removeClass("newreportpagescrolling");
      sessionStorage.setItem("changesdone", 0);
      $(".pivotdataview").remove();
    } else if (id == "searchid") {
      $("body").addClass("searchpage").removeClass("datahubpage dataworkspacepage datamodelspage datagovernancepage datamainpage");
      $("body").removeClass("rightpanelopen");
      $('#ui-datepicker-div').hide();
      $('.clockpicker-popover').remove();
      sessionStorage.setItem("changesdone", 0);
      sessionStorage.removeItem("convertedtypes");
      sessionStorage.removeItem("isconvettedtypesapplied");
      sessionStorage.removeItem("pos");
      sessionStorage.removeItem("renameMeasuresorDimensions");
      sessionStorage.removeItem("linkedreports");
      $(".pivotdataview").remove();
    }
    setTimeout(function(){$(".spinner").hide();},1000);
  }
  logOut(e) {
    var currentpath = window.location.pathname;
    if (currentpath.indexOf("viewreport") > 0 || currentpath.indexOf("viewstoryboard") > 0 || currentpath.indexOf("customqueryView") > 0 || currentpath.indexOf("sharereport") > 0 || currentpath.indexOf("embeddedreport") > 0 || currentpath.indexOf("schedulereport") > 0 || currentpath.indexOf("customviewreport") > 0 || currentpath.indexOf("customquery") > 0 || currentpath.indexOf("viewcustomstoryboardreport") > 0 || currentpath.indexOf("viewmodel") > 0 || currentpath.indexOf("alertreport") > 0) {
      var changesdone = sessionStorage.getItem("changesdone");
      if (changesdone == 1) {
        var pageNavigation = new PageNavigation({
          href: "logout",
          history: this.props.history,
          logout: this.logout
        });
      } else {
        this.logout();
      }
    } else {
      this.logout();
    }
  }
  logout() {
    let requestHeaders1 = {
      method: "get",
      headers: new Headers({
        "Content-Type": "application/json"
      })
    };
    SendRequest({
      url: "logoutbird",
      queryString: "",
      sucessFunction: (props) => {
        sessionStorage.removeItem("accessToken");
        sessionStorage.removeItem("reservedwordslist");
        window.localStorage.clear();
        window.location.replace("/");
      },
      rejectFunction: () => { },
      headers: requestHeaders1,
    })
  }
  changepassword = (e) => {
    // $(this.el).undelegate('.changepasswordsubmit', 'click');
    //  $(this.el).undelegate('.passwordhint', 'click');
    $("#changepasswordmodel").remove();
    var that = this;
    //this.model = new emaildisclaimermodel();
    //$('.spinner').show();
    var resp = {}
    let requestHeaders = {
      method: "get",
      headers: new Headers({
        "Content-Type": "application/json"
      })
    };
    SendRequest({
      url: "getsecurityquestions",
      queryString: "",
      sucessFunction: (response) => {
        if (response.hasOwnProperty('error')) {
          $(".spinner").hide();
        } else {
          resp = response;
          ReactDOM.render(<ChangePasswordTemplate getPasswordHint={this.getPasswordHint} securityQuestions={resp} />, document.getElementById("changepasswordmodelview"));
          $("#changepasswordmodel").show();
          $(".passwordhint").tooltip();
          $('.spinner').hide();
          //this.template = _.template(EmailDisclaimerTemplate);

          //this.getPasswordHint();

        }
      },
      rejectFunction: () => { },
      headers: requestHeaders
    })

  }
  aboutBird = (e) => {
    let requestHeaders = {
      method: "get",
      headers: new Headers({
        "Content-Type": "application/json"
      })
    };
    SendRequest({
      url: "aboutSriyam",
      queryString: "",
      sucessFunction: (response) => {
        if (!response.hasOwnProperty("error")) {
          var newBirdReportView = new NewBirdReportView({
            collection: response != undefined && response != "" ? response : []
          });
        } else {
          var newBirdReportView = new NewBirdReportView({
            collection: []
          });
        }
      },
      rejectFunction: () => { },
      headers: requestHeaders
    })
  }
  passwordhint(events) {
    events.preventDefault();
    $(".phint").slideToggle("slow");
  }

  search = (e) => {
    var $this = e.currentTarget;
    /*Delay function for to wait until user stops typing*/
    var delay = (function () {
      var timer = 0;
      return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
      };
    })();
    /*Delay function for to wait until user stops typing*/
    //$(".form-control").keyup(function(){
    var valThis = $($this).val().toLowerCase();
    var searchin = $($this).parent().parent().parent().find("ul");
    delay(function () {
      $(searchin).find(".viewreport-list-item").each(function () {
        var text = $(this).text().toLowerCase();
        (text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
      });
    }, 400);
    //});
  }
  componentDidMount() {
    $("link[name='Favicon']").attr("href",whitelableMap["metafavicon"]);
    $(".spinner").show();
    var that = this;
    $('.hamburger-menu-wrapper').click(function (e) {
      e.preventDefault();
      $(".maxleftpane").toggleClass("hidenav");
      $(".hamburger-menu").toggleClass("animate");
      that.setState({
        isHamburgerClicked: !that.state.isHamburgerClicked
      });
    });
    $(document).on('click','.hamburger-menu-content li>a',function (e) {
      $(".spinner").show();
      setTimeout(function(){$(".spinner").hide();},1000);
    });
    let requestHeaders = {
      method: "get",
      headers: new Headers({
        "Content-Type": "application/json"
      })
    };
    SendRequest({
      url: "getServerDateTime",
      queryString: "",
      sucessFunction: (d) => {
        $(".servertimezone").text(d.serverDateTime).attr("title", "Server Time : " + d.serverDateTime);
        var dt = new Date();
        $(".localtimezone").text(dt).attr("title", "Local Time : " + dt);
        $(".timezone").removeClass("hide");
      },
      rejectFunction: () => { },
      headers: requestHeaders
    })
    $('title').text(whitelableMap["metatitle"]);
  }
  onFullScreenChange(isFullScreen) {
    this.setState({
      isFullScreen
    });
  }
  requestOrExitFullScreen() {
    this.fullScreenRef.fullScreen();
  }
  render() {
    var that= this.props;
    return (
      <React.Fragment>
      <header id="header">
          <nav className="navbar navbar-bird navbar-fixed-top">
            <div className="container-fluid">
              <div className="row">
                <ul className="header-items">
                  {this.state.displayHamburger !== false ? (
                    <li className="hamburger-trigger">
                      <a
                        className={
                          this.state.pageType === "adminpage"
                            ? "navhidebutton"
                            : "hamburgermenu"
                        }
                        href="javascript:void(0)"
                        title={lang["user.header.menu"]}
                        onClick={() => {
                          var hasChildDiv =document.getElementById("urlnavigationmenu").querySelector("#pagenavigation");
                       if(hasChildDiv!=null){
                          }else{
                            $(".maxleftpane").toggleClass("hidenav");
                            $(".hamburger-menu").toggleClass("animate");
                          this.setState({
                            isHamburgerClicked: !this.state.isHamburgerClicked
                           });
                        }}
                      }
                      >
                        <span
                          className="menu-wrapper"

                        >
                          <span
                            className={`hamburger-menu ${this.state.isHamburgerClicked ? "animate" : null
                              }`}
                          ></span>
                        </span>
                      </a>
                    </li>
                  ) : null}
                  <li className="pull-left">
                    <ul className="nav-path">
                      {this.state.pageType === "datahubpage" ? (
                        <>
                          <li>
                            <a className="navDashboard" href="#data">
                              <i className="fa fa-database fa-solid"></i>{lang["user.header.data"]}
                            </a>
                          </li>
                          <li>
                            <a className="navDashboard" href="#datahub">
                              <i className="fa fa-database fa-solid"></i> {lang["user.header.datahub"]}
                            </a>
                          </li>
                        </>
                      ) : null}
                      {this.state.pageType === "workspacepage" ? (
                        <>
                          <li>
                            <a className="navDashboard" href="#data">
                              <i className="fa fa-database fa-solid"></i> {lang["user.header.data"]}
                            </a>
                          </li>
                          <li>
                            <a className="navDashboard" href="#workspace">
                              <i className="fa fa-cubes fa-solid"></i>{lang["user.header.workspace"]}
                            </a>
                          </li>
                        </>
                      ) : null}
                      {this.state.pageType === "modelspage" ? (
                        <>
                          <li>
                            <a className="navDashboard" href="#data">
                              <i className="fa fa-database fa-solid"></i> {lang["user.header.data"]}
                            </a>
                          </li>
                          <li>
                            <a className="navDashboard" href="#models">
                              <i className="fa fa-cube fa-solid"></i> {lang["user.header.models"]}
                            </a>
                          </li>
                        </>
                      ) : null}
                      {this.state.pageType === "governancepage" ? (
                        <>
                          <li>
                            <a className="navDashboard" href="#data">
                              <i className="fa fa-database fa-solid"></i> {lang["user.header.data"]}
                            </a>
                          </li>
                          <li>
                            <a className="navDashboard" href="">
                              <i className="fa fa-shield"></i> {lang["user.header.governce"]}
                            </a>
                          </li>
                        </>
                      ) : null}
                      {this.props.pageType === "datapage" ? (
                        <li>
                          <a className="navDashboard" href="#data">
                            <i className="fa fa-database fa-solid"></i> {lang["user.header.data"]}
                          </a>
                        </li>
                      ) : null}
                      {this.props.pageType === "dashboardpage" ? (
                        <li>
                          <a className="navDashboard" href="#visualization">
                            <i className="fa fa-bar-chart fa-solid"></i>{" "}
                            {lang["user.header.visual"]}
                          </a>
                        </li>
                      ) : null}
                      {this.state.pageType === "newreportpage" ? (
                        <>
                          <li>
                            <a className="navDashboard" href="#">
                              <i className="fa fa-home"></i> {lang["user.header.dashboard"]}
                            </a>
                          </li>
                          <li>
                            <a href="#newreport">
                              <i className="fa fa-plus"></i> {lang["user.header.newrprt"]}
                            </a>
                          </li>
                        </>
                      ) : null}
                      {this.state.pageType === "storybookpage" ? (
                        <>
                          <li>
                            <a className="navDashboard" href="#visualization">
                              <i className="fa fa-bar-chart fa-solid"></i>{" "}
                              {lang["user.header.visual"]}
                            </a>
                          </li>
                          <li>
                            <a href="#viewstorybook">
                              <i className="fa fa-book"></i> {lang["user.header.newstory"]}
                            </a>
                          </li>
                        </>
                      ) : null}
                      {this.state.pageType === "customstoryboardpage" ? (
                        <>
                          <li>
                            <a className="navDashboard" href="#visualization">
                              <i className="fa fa-bar-chart fa-solid"></i>{" "}
                              {lang["user.header.visual"]}
                            </a>
                          </li>
                          <li>
                            <a href="#viewcustomstoryboard">
                              <i className="fa fa-book"></i> {lang["user.header.newcustombd"]}
                            </a>
                          </li>
                        </>
                      ) : null}
                      {this.state.pageType === "adminpage" ? (
                        <>
                          <li>
                            <a className="navDashboard" href="#">
                              <i className="fa fa-home"></i> {lang["admin.header.dashtitle"]}
                            </a>
                          </li>
                          <li>
                            <a href="#admin" title={lang["user.header.adminstn"]}>
                              <i className="fa fa-user-secret"></i>{" "}
                              {lang["admin.header.admintitle"]}
                            </a>
                          </li>
                        </>
                      ) : null}
                    </ul>
                  </li>
                  {this.state.displayHeader != false ? (
                    <li>
                      <ul className="nav pull-right othernav">
                        <li>
                          <FullScreen
                            ref={ref => {
                              this.fullScreenRef = ref;
                            }}
                            onFullScreenChange={this.onFullScreenChange.bind(
                              this
                            )}
                          >
                            <a
                              className="rq fullscreen"
                              onClick={this.requestOrExitFullScreen.bind(this)}
                              title={lang["user.header.fullscreen"]}
                            >
                              <i className="fa fa fa-fullscreen fa-full"></i>
                            </a>
                          </FullScreen>
                        </li>
                        <li className="dropdown notifybadge" id="Notifications" title={lang["user.header.notify"]} ></li>
                        <li className="dropdown">
                          <a
                            className="userinfo dropdown-toggle"
                            id="userinfo"
                            title={lang["user.header.userinfo"]}
                            data-toggle="dropdown"
                            href="javascript:void(0)"
                          >
                            <i className="fa fa-user"></i>{" "}
                            <span className="navbar-text" title={lang["user.header.user"]}>
                              {this.props.loggedInUserDetails}
                            </span>
                          </a>
                          <ul className="dropdown-menu dropdown-menu-right">
                            <li className="admin-control navbar-text mobile-username">
                              {this.props.loggedInUserDetails}
                            </li>
                            <li>
                              <a
                                href="javascript:void(0)"
                                id="changepassword"
                                data-i18n="home.changePassword"
                                onClick={this.changepassword}
                              >
                                <i className="fa fa-key marg-righ-10"></i>{lang["admin.header.chngpwd"]}
                              </a>
                            </li>
                            <li>
                              <a
                                href="javascript:void(0)"
                                id="about"
                                data-i18n="home.about"
                                onClick={this.aboutBird}
                              >
                                <i className="fa fa-lightbulb-o marg-righ-10"></i>
                                {whitelableMap["aboutetext"]}
                              </a>
                            </li>
                            <li onClick={this.logOut}>
                              <a href="javascript:void(0)" id="logout" data-i18n="home.logout">
                                <i className="fa fa-power-off marg-righ-10"></i>
                                {lang["admin.header.logout"]}
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  ) : null}
                </ul>
              </div>
              <div className="mainnav">
                <div className="maninnavlistcontainer">
                  {this.state.pageType !== "adminpage" ? (
                    <ul className="maninnavlist">
                      {that.parentuserrole === "ROLE_Power_User" ||
                        that.parentuserrole === "ROLE_ReadOnly_User" ||
                        that.parentuserrole !== "ROLE_Viewer" ? (
                          <li>
                            <a
                              className={`navModels ${(this.props.pageType == "datapage" || this.props.pageType == "governancepage") ? "active" : ""}`}
                              //href={this.props.url}
                              id="datapageid"
                              onClick={this.checkForChangesdone}
                            >
                              <i className="fa fa-database fa-solid"></i>{" "}
                              <span>{lang["user.header.data"]}</span>
                            </a>
                          </li>
                        ) : null}
                      <li>
                        <a
                          className={`navDashboard ${(this.props.pageType == "dashboardpage" || this.props.pageType == "storybookpage" || this.props.pageType == "customstoryboardpage") ? "active" : ""}`}
                          //href={`${this.props.url}/visualization`}
                          id="visualizationid"
                          onClick={this.checkForChangesdone}
                        >
                          <i className="fa fa-bar-chart fa-solid"></i>{" "}
                          <span>{lang["user.header.visual"]}</span>
                        </a>
                      </li>
                      <li>
                        <a
                          className={`navAlerts ${(this.props.pageType == "notificationspage") ? "active" : ""}`}
                          // href={`${this.props.url}/notifications`}
                          id="notificationsid"
                          onClick={this.checkForChangesdone}
                        >
                          <i className="fa fa-exclamation-triangle fa-solid"></i>{" "}
                          <span>{lang["user.header.alerts"]}</span>
                        </a>
                      </li>
                      {this.props.features.map((featuresModule) => {
                        if (featuresModule.moduleName == 'business_model') {
                          return featuresModule.features.map((feature) => {
                            if (feature.featureName == 'advance_search' && feature.allow == true) {
                              return <li><a className={`navSearch ${(this.props.pageType == "searchpage") ? "active" : ""}`}
                                //href={`${this.props.url}/search`}
                                id="searchid"
                                onClick={this.checkForChangesdone}
                              ><i className="fa fa-search fa-solid"></i> <span>{lang["user.header.search"]}</span></a></li>
                            }
                          })
                        }
                      })}
                    </ul>
                  ) : null}
                </div>
              </div>
            </div>
          </nav>
          <div
            className={`hamburger-menu-container ${(this.state.isHamburgerClicked && this.state.pageType !== "adminpage") ? "open" : null
              }`}
          >
            <div className="hamburger-menu-content">
              <div className="hamburger-logo-container">
                {this.state.displayHamburger !== false ? (
                  <a
                    className={`${this.state.pageType === "adminpage"
                        ? "navhidebutton"
                        : "hamburgermenu"
                      }`}
                    href="javascript:void(0)"
                    title={lang["user.header.menu"]}
                    onClick={() => {
                      $(".maxleftpane").toggleClass("hidenav");
                      $(".hamburger-menu").toggleClass("animate");
                      this.setState({
                        isHamburgerClicked: !this.state.isHamburgerClicked
                      });
                    }}

                  >
                    <span
                      className="menu-wrapper"

                    >
                      <span
                        className={`hamburger-menu ${this.state.isHamburgerClicked ? "animate" : null
                          }`}
                      ></span>
                    </span>
                  </a>
                ) : null}
                {this.state.displayHeader !== false ? (
                  <a
                    className="branding homenavigation"
                    id="homenavigation1"
                    href=""
                    title={whitelableMap["alttextbrand"]}
                    onClick={this.checkForChangesdone}
                  >
                    <img src={whitelableMap["headerlogo"]} />
                  </a>
                ) : null}
              </div>
              {this.state.isHamburgerClicked ?
                (<ul className="hamburger-menu-list">
                  <li>
                    <a
                      className="branding homenavigation"
                      id="homenavigation1"
                      href=""
                      title={whitelableMap["alttextbrand"]}
                      onClick={this.checkForChangesdone}
                    >
                      <i className="fa fa-home"></i>{lang["user.header.home"]}
                  </a>
                  </li>
                  <li className={`${this.state.isAdminEnable ? "" : "disabled"}`}>
                    <a
                      href=""
                      id="administration"
                      data-i18n="home.administration"
                    >
                      <i className="fa fa-user-secret marg-righ-10"></i>{lang["user.header.adminstn"]}
                  </a>
                  </li>
                  {this.state.features.map((feature, index) => {
                    if (feature.moduleName === "data_hub") {
                      return feature.features.map((feature, index) => {
                        if (
                          feature.featureName === "new_data_hub" &&
                          feature.allow === true
                        ) {
                          return (
                            <li>
                              <a
                                key={index}
                                className="newreport ready"
                                id="navigatetodatahub"
                                href={`/${window.localStorage.getItem("appName")}/welcome/datahub`}
                                title={lang["user.header.datahub"]}
                              >
                                <i className="fa fa-database fa-solid"></i>{lang["user.header.datahub"]}
                            </a>
                            </li>
                          );
                        }
                      });
                    }
                  })}
                  <li className="hamburger-reports-container">
                    <div role="tabpanel">
                      <div className="tab-content">
                        <div role="tabpanel" className="tab-pane boc-snapshot-container tabviewitems boc-gray-inner active" id="dashboardtab">
                          <div className="panel-group panel">
                            <React.Fragment>
                              {this.state.features.map((feature, index) => {
                                if (feature.moduleName === "work_space") {
                                  return feature.features.map((feature, index) => {
                                    if (feature.featureName === "new_work_space" && feature.allow === true) {
                                      return (
                                        <HamburgerWorkspacesList url={this.props.url} search={this.search} />
                                      )
                                    }
                                  })
                                }
                              })}
                              {this.state.features.map((feature, index) => {
                                if (feature.moduleName === "business_model") {
                                  return <HamburgerDatamodelsList url={this.props.url} search={this.search} />
                                }
                              })}
                            </React.Fragment>
                            <HamburgerReportsList url={this.props.url} search={this.search} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>)
                : <React.Fragment />}
              {this.state.displayFooter !== false ? (
                <div
                  className="hamburger-menu-footer"
                  title={whitelableMap["loginfootertext"]}
                >
                  <div className="timezone hide">
                    <div>
                      <span className="servertime">
                        <i className="fa fa-cloud"></i>
                      </span>{" "}
                      <span className="servertimezone">
                      {lang["admin.time"]} {" "}
                      </span>{" "}
                    </div>
                    <div>
                      {" "}
                      <span className="localtime">
                        <i className="fa fa-desktop"></i>
                      </span>{" "}
                      <span className="localtimezone">
                        {" "}
                        {lang["admin.time"]} {" "}
                      </span>
                    </div>
                  </div>
                  {whitelableMap["loginfootertext"]}
                </div>
              ) : null}
            </div>
          </div>
          <div
            className={`hamburger-menu-wrapper ${(this.state.isHamburgerClicked && this.state.pageType !== "adminpage") ? "open" : null
              }`}
          ></div>
          <div id="aboutBirdid"></div>
          <div id="changepasswordmodelview"></div>

        </header>
      </React.Fragment>
    );
  }
}
