import React from 'react';
import { lang } from "../js/helpers/utils/languages/language";
export default class DatamodelsHtml extends React.Component {
    render() {
        return (
            <>
                <section className="datamodels-section-container">
                    <div className="boc-home">
                        <div className="datamain-fixed-head-container">
                            <span className="subhead"><i className="fa fa-cube fa-solid marg-righ-10"></i><span>{lang["models.business"]}</span></span>
                        </div>
                        <div className="datamain-fixed-content-container">
                            <div id="datamodelsmaincontainer">
                                <div id="datamodelsstepscontainer">
                                    <h3>{lang["models.entities"]}</h3>
                                    <section>
                                        <div id="datamodelsconnectionscontainer"></div>
                                    </section>
                                    <h3>{lang["models.joins"]}</h3>
                                    <section>
                                        <div className="col-xs-12"><button className="btn btn-bird btn-highlight pull-right createreference" type="button"><i className="fa fa-plus"></i> {lang["models.createjoin"]}</button></div>
                                        <div id="datamodelfactreferencecontainer" className="col-xs-12 col-md-12 col-sm-12 col-lg-6 center-block">
                                            <span className="intromessage hide">No Joins Available.<br/>Click '+' to <button className="btn btn-bird btn-highlight pull-right createreference" type="button">{lang["models.createjoin"]}</button></span>
                                        </div>
                                    </section>
                                    <h3>{lang["models.previewmodel"]}</h3>
                                    <section>
                                        <div id="datamodelpreviewcontainer"></div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {(this.props.displayFooter !== false) ? (
                    <footer className="footer">
                        <div className="container-fluid"></div>
                    </footer>
                ) : null}
                <div id="savedatamodaldialog"></div>
            </>
        );
    }
}