import React from 'react';
import FeaturesView from './featuresView';
import UpdateRolesView from './updateRoleView';
import { lang} from "../../js/helpers/utils/languages/language";
import { getMessage } from '../../js/helpers/utils/BirdMessage';
const $ = window.$;

export default class EditRoleTemplate extends React.Component{
    constructor(props){
	 super(props); 
    }
	
    getFeatures(){
        var roleId = $('.inheritedrole').val();
        var getFeatures = new FeaturesView({roleId : roleId});
    }
	inactiveuser=()=>{
		$(".spinner").hide();
		   $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
		   $('.messageicon i').removeClass('fa-check  fa-info-circle fa-times-circle-o').addClass('fa-exclamation-triangle');
		   $('.birdmessagebuttons').removeClass('show');
		   $('.birdmessagebuttons').addClass('hide');
		   $('.birdmessage-center').addClass("message-top-in");
		   $('.details').removeClass('show');
		   $('.details').addClass('hide');
		   $('.birdmessage h2').empty().append('Warning');
		   $('.birdmessage-info').empty().text(getMessage('BirdWarning155'));
		   
	}
    updateRole(){
        var addGroupFormData = $(".newroleform").serializeArray();
		var roleId=$('#roleid').val();
		var roleName=$('#rolename').val();
		var roleDesc=$('#roledesc').val();
		var parentRoleId=$('#parentroleid').val();
        var status=$('input[name=status]:checked').val();
        new UpdateRolesView({roleId:roleId,roleName:roleName,roleDesc:roleDesc,parentRoleId:parentRoleId,status:status,formData:addGroupFormData});
		
	}
	
    render(){
        var that = this.props;
		var roleAndFeatures = that.roleAndFeatures;
		var roles = that.roles;
        var temp = roles.map((role)=>{
            if(role.roleName!='ROLE_Admin' && role.roleName!='ROLE_Viewer'){
                if(role.roleId==roleAndFeatures.parentRoleId){
                    return <option value={role.roleId} selected="selected">{role.roleDisplayName}</option>
                } else if(role.parentRoleId==null){
                    return <option value={role.roleId}>{role.roleDisplayName}</option>
                }
            }
            })
        return(
            <div className="container-fluid nopadding">
	<div className="alert alert-success roleupdatesuccess" style={{display: "none"}} role="alert">{lang["admin.roles.updatesuccess"]}</div>
	<div className="alert alert-danger roleupdateerror" style={{display: "none"}} role="alert">{lang["admin.roles.updateerror"]}</div>
	<div className="alert alert-danger inactiverole" style={{display: "none"}} role="alert">{lang["admin.roles.inactive"]}</div>
	<div className="row in-back">
		<div className="max-height-30vh">
			<h2 className="boc-main-title">{lang["admin.roles.edittitle"]}</h2>
			<form className="form-horizontal col-xs-12 newroleform">
				<div className="form-group form-group-sm">
					<label for="rolename" className="col-sm-3 control-label important">{lang["admin.roles.createname"]}</label>
					<div className="col-sm-6">
						<input type="hidden" id="roleid" value={roleAndFeatures.roleId} />
						<input type="name" className="form-control" id="rolename" name="roleName"
							placeholder="" defaultValue={roleAndFeatures.roleName}/>
						<span className="help-block rolenameerror hidden"></span>
					</div>
				</div>
				<div className="form-group form-group-sm">
					<label for="roledescription" className="col-sm-3 control-label" >{lang["admin.roles.createdescrptn"]}</label>
					<div className="col-sm-6">
						<textarea className="form-control textarea" rows="2"
							id="roledesc" placeholder="" >{roleAndFeatures.roleDesc}</textarea>
					</div>
				</div>
				<div className="form-group form-group-sm">
					<label for="roleinheritedgroup" className="col-sm-3 control-label important" >{lang["admin.roles.createinherit"]}</label>
					<div className="col-sm-6">
						<select placeholder="" className="form-control inheritedrole" id="parentroleid" name="parentroleid" onChange={this.getFeatures}>
							<option value="">{lang["admin.roles.chooserole"]} </option>
							{temp}
						</select>
						<span className="help-block roleerror hidden"></span>
					</div>
				</div>
				<div className="form-group form-group-sm">
					<label for="" className="col-sm-3 control-label">{lang["admin.roles.createstatus"]}</label>
					<div className="col-sm-8">
						<div>
							{(roleAndFeatures.status=='A')?(<>
								<input type="radio" id="statusa"  value="A" name="status" defaultChecked/>{lang["admin.roles.statusact"]}&nbsp;&nbsp;&nbsp; 
								<input type="radio" id="statusi" value="I" name="status" onClick={this.inactiveuser}/>{lang["admin.roles.statusdact"]}
							</>): (<>
								<input type="radio" id="statusa"  value="A" name="status"/>{lang["admin.roles.statusact"]}&nbsp;&nbsp;&nbsp; 
								<input type="radio" id="statusi" value="I" name="status" onClick={this.inactiveuser} defaultChecked/>{lang["admin.roles.statusdact"]}
								</>)
							}
							<span className="help-block hidden"></span>
						</div>
					</div>
				</div>
				<div id="role-accordiondiv" className="col-md-offset-3 col-sm-12 col-md-8 col-lg-6 col-xs-12">

				</div>
				<div className="form-group form-group-sm marg-top-10">
					<div className="col-sm-offset-3 col-sm-6">
						<button type="button" className="btn btn-bird updaterolebutton" id="updaterole" onClick={this.updateRole}><i className="fa fa-refresh marg-righ-5"></i>{lang["admin.roles.updatebtn"]}</button>
					</div>
				</div>
			</form>

		</div>
	</div>
</div>
        );
    }
}