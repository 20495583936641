import React from 'react';
import ReactDOM from 'react-dom'
import EditRoleTemplate from './EditRoleTemplate';
import SendRequest from '../../SendRequest';
import FeaturesView from './featuresView';
const $ = window.$;

export default class EditRoleView extends React.Component{
    constructor(props){
        super(props);
        this.getRolesandFeatures();
    }
    getRolesandFeatures(){
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getroleandfeatures",
            queryString: "?roleId=" + this.props.roleId,
            sucessFunction: (response) => {
                if(response.hasOwnProperty('error')){
                    $(".spinner").hide();
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('hide');
                    $('.birdmessage h2').empty().append('Error : ');
                    $('.birdmessage h2').append('<span class="errorcode">');
                    $('.birdmessage .errorcode').empty().append(response.errorCode);
                    $('.birdmessage-info').empty().text(response.error);
                    $('.details').addClass('show');
                    $('.messagedetails xmp').empty().append(response.errorTrace)
                    
                }else{
                this.render(response);
                }
            },
            rejectFunction:() => { },
            headers: requestHeaders            
        });

    }

    render(response){
        ReactDOM.unmountComponentAtNode(document.querySelector('.newroleform'));
        ReactDOM.render(<EditRoleTemplate roles={this.props.roles} roleAndFeatures={response} />,document.querySelector('.newroleform'));
        new FeaturesView({roleId : this.props.roleId})
        return(<></>);
    }
}