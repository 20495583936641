import React from "react";
import $ from 'jquery/dist/jquery';
import * as MLCharts from "./mlcharts";
import ViewMLBodyView from "./viewmlbodyview";
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
export default class AutoMLSummaryView extends React.Component{
    constructor(props){
        super(props);
        this.render()
    }
    render(){
        var that = this;
        $(".automlsettingcontent ").removeClass('hide');
        $(".actions.clearfix a[href='#next']").removeClass('disabled');
       var  data = this.props.data["modelsSummaryData"];
        if (data != null && data != undefined && data.length > 0) {
            var keysArray = Object.keys(data["0"]);
            var keys = [];
            for (var j1 = 0; j1 < keysArray.length; j1++) {
                if (keys.indexOf(keysArray[j1]) === -1) {
                    keys.push(keysArray[j1]);
                }
            }
            var header = "",modelheader="",scoreval="",fittime="",predtime="";
            for (var j = 0; j < keys.length; j++) {
                switch (keys[j]) {
                    case "modelsnames":
                        modelheader= '<th class="text-center" data-field="' + keys[j] + '"><b> Model </b></th>'
                        break;
                    case "fit_time":
                        fittime= '<th class="text-center" data-field="' + keys[j] + '"><b> Fit time (sec)</b></th>'
                        break;
                    case "prediction_time":
                        predtime= '<th class="text-center" data-field="' + keys[j] + '"><b> Prediction time (sec)</b></th>'
                        break;
                    case "score_val":
                        scoreval= '<th class="text-center" data-field="' + keys[j] + '"><b> '+(that.props.data["model_type"]=="numeric"?'R2':'Log loss')+'</b></th>'
                        break;    
                    default:
                        break;
                }
            }
            header = modelheader+scoreval+fittime+predtime;
            $("#trained-models-summarytable").empty();
            $("#trained-models-summarytable").append('<div class="outliertable notifications-table-container" ><table class="table" id="outliertable" data-toggle="table" data-pagination="true" data-page-size="10" data-smart-display="true" data-search="true" data-pagination-v-align="bottom" data-search-align="right"><thead></thead><tbody></tbody></table></div>');
            $("#trained-models-summarytable table>thead").append('<tr>' + header + '</tr>');
            $("#trained-models-summarytable table").bootstrapTable({ height: 300, data: data });
            $('#trained-models-summarytable table tbody tr').eq(0).addClass('highlight')
            $('#trained-models-summarytable').on('click', 'tbody tr', function(e) {
                e.stopPropagation();
                $(this).addClass('highlight').siblings().removeClass('highlight');
                window.$("#mlsplitcontainer").steps("next");
                 /*$('#mlcharts').empty();
                new ViewMLBodyView({
                    data: that.props.data,
                    selectedModel: that.props.selectedModel,
                    features: that.props.features,
                    reportId: that.props.reportId,
                    modelData: that.props.viewreportCollection,
                    dimensions: that.props.dimensions,
                    datamodelId: that.props.datamodelId,
                    automlSelectedModel:e.currentTarget.cells[0].innerText,
                    automlmodeltype:that.props.data["model_type"]
                });*/
              });
            let metricData = this.props.data["noOfModels"],chartType="metric",chartnum=0;
            //MLCharts.charts.drawMetricChart(metricData, "trained-models", chartType, "", this.pos, "", "", 0, 0, "", "Trained Models ", this.metricsuffix, this.broker);
            $("#trained-models").html('<div class="details-container"><span class="title">Trained Models</span><span class="result">'+metricData+'</span></div>');
            metricData = this.props.data["trainTime"]
            //MLCharts.charts.drawMetricChart(metricData, "training-time-taken", chartType, "", this.pos, "", "", 1, 0, "", "Time Taken ", " sec", this.broker);
            $("#training-time-taken").html('<div class="details-container"><span class="title">Time Taken</span><span class="result">'+metricData+'<span class="suffix"> sec</span></span></div>');
            metricData = this.props.data["total_len"]
            //MLCharts.charts.drawMetricChart(metricData,"trained-data", chartType, "", this.pos, "", "", 2, 0, "", "Total Data ", this.metricsuffix, this.broker);
            $("#trained-data").html('<div class="details-container"><span class="title">Total Data</span><span class="result">'+metricData+'</span></div>');
            metricData = this.props.data["train_test_split"]
            //MLCharts.charts.drawMetricChart(metricData, "automl-test-data", chartType, "", this.pos, "", "", 3, 0, "", "Train-Test Split ", this.metricsuffix, this.broker);
            $("#automl-test-data").html('<div class="details-container"><span class="title">Train-Test Split (%)</span><span class="result">'+metricData+'</span></div>');
            if(ChartInfo.functions.getIsFilterAppliedForAutoML()){
                ChartInfo.functions.setIsFilterAppliedForAutoML(false);
                $('#mlcharts').empty();
                new ViewMLBodyView({
                    data: that.props.data,
                    selectedModel: that.props.selectedModel,
                    features: that.props.features,
                    reportId: that.props.reportId,
                    modelData: that.props.viewreportCollection,
                    dimensions: that.props.dimensions,
                    datamodelId: that.props.datamodelId,
                    automlSelectedModel:$('#trained-models-summarytable table tbody tr.highlight .text-center').eq(0).text(),
                    automlmodeltype:that.props.data["model_type"]
                });
            }
        }
        return(
            <React.Fragment/>
        )
    }
}