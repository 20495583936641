import React from 'react';
import ReactDOM from 'react-dom';
import SendRequest from '../../SendRequest';
import { getMessage } from '../../js/helpers/utils/BirdMessage';
import { lang} from "../../js/helpers/utils/languages/language";
import RoleView from './rolesview';
const $ = window.$;

export default class UpdateRoleView extends React.Component{
    constructor(props){
        super(props);
        this.updateRole();
    }
    isValid=(data)=>{
        var isValid = true;
        if($.trim($.trim(data.get("roleName")))==""){
        $(".rolenameerror").removeClass('hidden');
        $(".rolenameerror").text(lang["admin.roles.namerqd"]);
        isValid = false;
        }
        if($.trim($.trim(data.get("parentRoleId")))==""){
        $(".roleerror").removeClass('hidden');
        $(".roleerror").text(lang["admin.roles.chooserole"] );
        isValid = false;
        }
        return isValid;
      }

    updateRole=(e)=>{
        let requestHeaders = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        var reqdata = new FormData();        
        reqdata.set("roleId",this.props.roleId);
        reqdata.set("roleName",this.props.roleName);
        reqdata.set("roleDesc",this.props.roleDesc);
        reqdata.set("parentRoleId",this.props.parentRoleId);
        reqdata.set("status",this.props.status);
        reqdata.set("formData",JSON.stringify(this.props.formData));
        var isValid = this.isValid(reqdata);
        if(isValid){
        SendRequest({
            url: "updaterole",
            body: reqdata,
            sucessFunction: (response) => {
                this.render(response);
              },
              rejectFunction: () => { },
              headers: requestHeaders
        });
        }
    }
    render(response){
        if(response.status=='success'){
            $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
            $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Success');
            $('.birdmessage-info').empty().text(getMessage('BirdSuccess13'));
            setTimeout(function() {
            $('.birdmessage-container .close').click();	
            }, 3000);
            setTimeout(function() {
                window.location.reload(false);
                 },3000);
        }
        else if(response.hasOwnProperty('error')){
            $(".spinner").hide();
              $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('hide');
            $('.birdmessage h2').empty().append('Error : ');
            $('.birdmessage h2').append('<span class="errorcode">');
            $('.birdmessage .errorcode').empty().append(response.errorCode);
            $('.birdmessage-info').empty().text(response.error);
            $('.details').addClass('show');
            $('.messagedetails xmp').empty().append(response.errorTrace)
            setTimeout(function() {
            $('.birdmessage-container .close').click();	
            }, 3000);
        }
        return(<></>);
    }
}