import React from 'react';
import _ from 'underscore';
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;
export default class ConnectionItemsDataContainerHtml extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        var that = this;
        return (
            <div className="col-xs-12 nopadding">
                <div className="panel-group">
                    {/* Removed Add new Source from Model
                     <div className="acc-content">
                        <div className="panelboc">
                            <div className="panelboc-heading">
                                <h4 className="panelboc-title accordion-toggle" title={lang["models.addnewsource"]} data-toggle="collapse">
                                    <i className="fa fa-file-text"></i> {lang["models.addnewsource"]}
                                    <button className="btn btn-transparent datamodel-addnewsource" id="createnewsource" onClick={(e)=>{ this.props.createnewconnection(e) }}><i className="fa fa-plus"></i></button>
                                </h4>
                            </div>
                        </div>
                    </div> */}
                    <div className="acc-content">
                        <div className="panelboc">
                            <div className="panelboc-heading">
                                <h4 className="panelboc-title accordion-toggle" title={lang["models.addcusquery"]} current-id="0" data-toggle="collapse" onClick={(e) => { this.props.datamodeladdcustomquery(e) }}>
                                    <i className="fa fa-code"></i>{lang["models.customquery"]}
                                    <button className="btn btn-transparent datamodel-addcustomquery" current-id="0"><i className="fa fa-plus"></i></button>
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div className="acc-content data-models-tables-list-container">
                        <div className="panelboc">
                            <div className="panelboc-heading">
                                <h4 className="panelboc-title accordion-toggle" title="" id="tablecolllapse" data-toggle="" href="#dbscopetables" aria-expanded=""><i className="fa fa-table"></i>{lang["models.entities"]}</h4>
                            </div>
                            <div id="dataworkspacetables" className="panel-collapse panelboc-content newreportdbitems collapse in" aria-expanded="true">
                                <div className="panelboc-body">
                                    <div className="filtercontent" id="scopeItem">
                                        <div className="container-fluid marg-bot-10">
                                            <div className="row">
                                                <input className="form-control search-image" name="Scope_Production" onChange={this.props.search} placeholder={lang["models.search"]} id="searchinput" type="text" />
                                            </div>
                                        </div>
                                        <ul className="list-group scrollsetul checkhelpitems" id="dataworkspacetablesnew">
                                            {
                                                this.props.entityDetails.map((entity, index) => {
                                                    return (
                                                        <li key={index} item-expanded="true" className={`viewreport-list-item ui-draggable ui-draggable-handle ${(entity.fact == 1) ? "activefact" : ""}`}>
                                                            <label title={entity.entityname}><span className="source"><i className="fa fa-link"></i>{entity.connName}</span>
                                                                <i className="fa fa-table"></i>
                                                                <input type="checkbox" durable="false" className="scopeitem mysqltablevalue" data_parent={entity.workspaceentityid} id={entity.entityname} data-value={entity.entityname} />{entity.entityname}
                                                            </label>
                                                            <button className="btn btn-transparent btn-sm editdatamodelitem" id="editdatamodelitem" data_parent={entity.workspaceentityid} data-value={entity.entityname} onClick={that.props.editdatamodelitem} type="button" title={lang["models.settings"]}><i className="fa fa-cog"></i></button>
                                                            <button className="btn btn-transparent btn-sm joinremove" type="button" data_parent={entity.workspaceentityid} data-value={entity.entityname}><i className="fa fa-times"></i></button>

                                                        </li>
                                                    );
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}