import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SendRequest from '../../../SendRequest';
import SamlTemplate from './samltemplate';
import {getMessage} from '../../../js/helpers/utils/BirdMessage';
const $ = window.$;

export default class SamlView extends Component{
    constructor(props){
        super(props);
        this.getSamlDetails();
    }
    getSamlDetails(){
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "saveandretrivesinglesignondetails",
            queryString: "?loginurl=" + "" + "&logouturl=" + "" + "&certificate=" + "" + "&signonType=" + ""+ "&isupdate=" + "no",
            sucessFunction: (response) => {
                this.render(response.config);
            },
            rejectFunction: () => { },
            headers: requestHeaders
        });
    }
    isValid=(data)=>{
        var isValid = true;
        var patt = new RegExp('/(\b)(on\S+)(\s*)=|javascript:|(<\s*)(\/*)script|style(\s*)=|(<\s*)meta/ig');
					// if($.trim($.trim(data.loginurl))==""){
                    //     $(".loginurlerror").removeClass('hidden');
                    //     $(".loginurlerror").text("Login URL is required");
                    //     isValid = false;
                    // } else if(patt.test(data.loginurl)){
					// 	$(".loginurlerror").removeClass('hidden');
                    //     $(".loginurlerror").text("Invalid Data");
                    //     isValid = false;
                    // }
                    // if($.trim($.trim(data.logouturl))==""){
                    //     $(".logouturlerror").removeClass('hidden');
                    //     $(".logouturlerror").text("Logout URL is required");
                    //     isValid = false;
                    // } else if(patt.test(data.logouturl)){
					// 	$(".logouturlerror").removeClass('hidden');
                    //     $(".logouturlerror").text("Invalid Data");
                    //     isValid = false;
                    // }
                    // if($.trim($.trim(data.certificate))==""){
                    //     $(".certificateerror").removeClass('hidden');
                    //     $(".certificateerror").text("Certificate is required");
                    //     isValid = false;
                    // } else if(patt.test(data.certificate)){
					// 	$(".certificateerror").removeClass('hidden');
                    //     $(".certificateerror").text("Invalid Data");
                    //     isValid = false;
                    // }
                    return isValid;

    }
    signUp=(e)=>{
        var serialized = $('.oauth2configform').serializeArray();
			var s = '';
			var data = {};
			for(s in serialized){
				data[serialized[s]['name']] = serialized[s]['value']
            }
            var isValid = this.isValid(data);
            if(isValid){
            let requestHeaders = {
                method: "get",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            SendRequest({
                url: "saveandretrivesinglesignondetails",
                queryString: "?loginurl=" + data.loginurl + "&logouturl=" + data.logouturl + "&certificate=" + data.certificate + "&signonType=" + "SSO" + "&isupdate=" + "yes",
                sucessFunction: (response) => { 
                    if(response.hasOwnProperty('error')){
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.errorTrace)
                    }else{
                        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Success');
                        $('.birdmessage-info').empty().text(getMessage('BirdSuccess53'));
                        setTimeout(function() {
                            $('.birdmessage-container .close').click();	
                        }, 2000);
                    }  
                  },
                  rejectFunction: () => { },
                  headers: requestHeaders
                });
            }
    }
    render(response){
        ReactDOM.render(<SamlTemplate response={response} saveSamlDetails={this.signUp} />,document.querySelector(".othersettingscontainer"))
        return(<div></div>);
    }
}