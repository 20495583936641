import React from 'react'
import SendRequest from '../../SendRequest';
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import _ from 'underscore';
import * as Highcharts from 'highcharts/highstock';
import { whitelableMap } from "../../js/helpers/utils/whitelable";
import SmartInsightsFilterDrillDown from './smartinsightsfilterdrilldown'

const $ = window.$;
export default class SamrtInsightsDrilldown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        if (!this.props.isFromSave) {
            this.state.xaxis = this.props.categoryname;
            this.state.yaxisDisplay = this.props.targetColumn;
            this.state.yaxis = this.props.targetColumnOriginal;
            this.state.xaxisDisplay = this.props.categoryOriginalNames;
            this.state.filter = this.props.filter;
            this.state.htmlnlgdata = this.props.htmlnlgdata;
            this.state.categoryType=this.props.categoryType;
            var fragmentArray = window.location.pathname.split("/");
            this.state.reportId = "";
            // this.state.entities = fragmentArray[2];
            if (fragmentArray.length == 2) {
                if (fragmentArray[0] === "viewreport" || fragmentArray[0] === "sharereport" || fragmentArray[0] === "embeddedreport") {
                    //	this.state.reportId = atob(fragmentArray[1]);	
                }
                if (fragmentArray[0] === "customviewreport") {
                    this.state.entities = sessionStorage.getItem("entities");
                }
            }
            if(window.location.pathname.indexOf('viewstorybook')<0){
                if (fragmentArray.length == 5) {
                    this.state.datamodelId = atob(fragmentArray[4]);
                } else if (fragmentArray.length > 6) {
                    this.state.datamodelId = atob(fragmentArray[6]);
                }else if (fragmentArray.length > 5) {
                    this.state.datamodelId = atob(fragmentArray[5]);
                }
            }
            var filter = "";
            if (this.state.filter) {
                filter = sessionStorage.getItem("smartinsightsfilter");
            }
            this.state.tabid = 0
            this.state.isFromStoryBook = this.props.isFromStoryBook
            if (this.state.isFromStoryBook != undefined && this.state.isFromStoryBook) {
                this.state.reportId = this.props.reportId;
                this.state.datamodelId = this.props.datamodelId;
                this.state.tabid = this.props.tabid
            }
            var that = this;
            let requestHeaders = {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            var storyboardSmartInsightFilterArray = ChartInfo.functions.getStoryboardSmartInsightFilterArray();
            
            var formData = new FormData();
            formData.set("datamodelId", this.state.datamodelId);
            formData.set("reportId", this.state.reportId);
            formData.set("xAxis", encodeURIComponent(this.state.xaxis));
            formData.set("yaxis", encodeURIComponent(this.state.yaxis));
            formData.set("filter", filter);
            formData.set("isSmartInsights", true);
            formData.set("storyboardfilters", JSON.stringify(storyboardSmartInsightFilterArray));
            formData.set("customFields",sessionStorage.getItem("customFields"))

            SendRequest({
                url: "getsmartinsightsdrilldown",
                body: formData,
                sucessFunction: (response) => {
                    that.state.collection = response;
                    that.render();
                },
                rejectFunction: this.reportDataError,
                headers: requestHeaders
            });
        }
    }

    render() {
        var renderto;
        var smartInsightObj = ChartInfo.functions.getSmartinsightDataObj();
        var smartData = smartInsightObj["smarinsightData"] != undefined && smartInsightObj["smarinsightData"] != '' ? JSON.parse(smartInsightObj["smarinsightData"]) : {};
        var keyInfluencerObj = {};
        if (this.state.filter) {
            renderto = $(".insightavgsuggestion2[tabid='" + this.state.tabid + "'] .influenceschart");
            $(".insightavgsuggestion2[tabid='" + this.state.tabid + "']>h3").html(this.state.yaxisDisplay + " and <span>" + this.state.xaxisDisplay + "</span>");
        }
        else {
            renderto = $(".insightavgsuggestion0[tabid='" + this.state.tabid + "'] .influenceschart");
            $(".insightavg[tabid='" + this.state.tabid + "']>h3").html(this.state.yaxisDisplay + " and <span>" + this.state.xaxisDisplay + "</span>");

        }
        // $(".insightavg h3")[0].innerText = this.state.yaxis +" and
        // <span>"+this.state.xaxis+"</span>"
        // $(".insightavg>h3").html(this.state.yaxis +" and
        // <span>"+this.state.xaxis+"</span>");

        var categories = [];
        _.each(this.state.collection[0].data, function (data) {
            categories.push(data.name);
            data.drilldown = false;
        });
        var yaxisname = this.state.collection[0].name;
        if (this.state.filter) {
            this.drawSmartAVGBar2(this.state.collection[0].data, this.state.collection[0].avgValue, categories, renderto, yaxisname, this.state.yaxisDisplay);
        }
        else {
            $(".insightavgsuggestion1[tabid='" + this.state.tabid + "'] .influenceschart").empty().removeAttr("data-highcharts-chart");
            $(".insightavgsuggestion1[tabid='" + this.state.tabid + "']>h3").html("Key Influencers");
            $(".insightavgsuggestion2[tabid='" + this.state.tabid + "'] .influenceschart").empty().removeAttr("data-highcharts-chart");
            $(".insightavgsuggestion2[tabid='" + this.state.tabid + "']>h3").html(this.state.yaxisDisplay);
            this.drawSmartAVGBar(this.state.collection[0].data, this.state.collection[0].avgValue, categories, renderto, yaxisname, this.state.htmlnlgdata, this.state.xaxisDisplay, this.state.yaxisDisplay, this.state.tabid, this.state.reportId, this.state.datamodelId, this.state.isFromStoryBook);
        }
        if (this.state.filter) {
            keyInfluencerObj["keyInfluencesdata"] = this.state.collection
            keyInfluencerObj["categories"] = categories
            keyInfluencerObj["yaxisname"] = yaxisname
            keyInfluencerObj["yaxisDisplay"] = this.state.yaxisDisplay
            keyInfluencerObj["xaxisDisplay"] = this.state.xaxisDisplay
            keyInfluencerObj["htmlnlgdata"] = this.state.htmlnlgdata
            //  keyInfluencerObj["renderto"] = renderto
            keyInfluencerObj["xaxis"] = this.state.xaxis
            keyInfluencerObj["yaxis"] = this.state.yaxis
            smartData[9] = keyInfluencerObj;
        } else {
            keyInfluencerObj["keyInfluencesdata"] = this.state.collection
            keyInfluencerObj["categories"] = categories
            keyInfluencerObj["yaxisname"] = yaxisname
            keyInfluencerObj["yaxisDisplay"] = this.state.yaxisDisplay
            keyInfluencerObj["xaxisDisplay"] = this.state.xaxisDisplay
            keyInfluencerObj["htmlnlgdata"] = this.state.htmlnlgdata
            //  keyInfluencerObj["renderto"] = renderto
            keyInfluencerObj["xaxis"] = this.state.xaxis
            keyInfluencerObj["yaxis"] = this.state.yaxis
            smartData[7] = keyInfluencerObj;
            smartData[8] = {};
            smartData[9] = {};
        }
        var smartInsightDataObj = {};
        smartInsightDataObj["excludeColumn"] = smartInsightObj["excludeColumn"];
        smartInsightDataObj["targeColumn"] = smartInsightObj["targeColumn"];
        smartInsightDataObj["smarinsightData"] = JSON.stringify(smartData);
        ChartInfo.functions.setSmartinsightDataObj(smartInsightDataObj);
        //$(".insightavgsuggestion1 #spinnerchart9").hide();
        return (<div></div>);
    }
    drawSmartAVGBar2(data, avgValue, categories, renderto, yaxisname, yaxisDisplay) {
        var that = this;
        var colorThemesArray = [
            ["#00bcd4","#37c2d9","#50c8dd","#64cee2","#76d4e7","#86daeb","#95e0f0","#a3e6f4","#b1ecf9"],
            ["#00bcd4","#00CECC","#42DEB9","#7FEB9E","#BBF484"],
            ["#4e79a7", "#fc7d0b", "#a3acb9", "#57606c", "#5fa2ce", "#c85200", "#7b848f", "#a3cce9", "#ffbc79", "#c8d0d9"],
        ["#4e79a7", "#58606c", "#a2abb8"],
        ["#4e79a7", "#f28e2b", "#e15759", "#76b7b2", "#59a14f", "#edc948", "#b07aa1", "#ff9da7", "#9c755f", "#bab0ac"]

        ];
        var options = {
            chart: {
                renderTo: renderto[0],
                type: 'bar',
                zoomType: 'xy',
                backgroundColor: 'transparent',
                style: {
                    fontFamily: whitelableMap["fontfamily"],
                    fontWeight: '400',
                },
                animation: false,
                plotBorderWidth: 0,
                spacing: [15, 25, 10, 25]
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: categories,
                title: {
                    text: that.state.xaxis,
                    enabled: false
                },
                lineWidth: 0.3,
                tickWidth: 0.3,
                gridLineWidth: 0,
                labels: {
                    step: 1,
                    useHTML:true,
                    style:{
                        width:renderto[0].offsetWidth/3,
                        display: 'block',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    },
                    formatter: function () {
                        var lab = '<span title="'+this.value+'">'+this.value+'</span>'
                        return lab;
                    }
                }
            },
            yAxis: {

                title: {
                    text: that.state.yaxis,
                    enabled: false
                },

                lineWidth: 0,
                tickWidth: 0,
                gridLineWidth: 0,
                plotLines: [{
                    color: 'red',
                    value: avgValue,
                    width: '1',
                    zIndex: 4,
                    dashStyle: 'LongDash',
                    label: {
                        text: "Overall Average (" + avgValue + ")",
                        verticalAlign: 'top',
                        textAlign: 'center',
                        rotation: 0,
                        y: -3,
                        x: -30
                    }
                }]
            },
            tooltip: {
                useHTML:true
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: false
                    },
                },
                series: {
                    pointPadding: 0,
                    states: {
                        hover: {
                            color: ['#fff']
                        },
                        select: {
                            color: colorThemesArray[0][0]
                        }
                    },
                    slicedOffset: 0,
                }
            },
            legend: {
                enabled: true,
                align: 'center',
                symbolHeight: 12,
                symbolWidth: 12,
                symbolRadius: 6
            },
            series: [{
                xaxis: that.state.xaxis,
                yaxis: that.state.yaxis,
                name: "Average " + yaxisDisplay + "",
                data: data,
                states: {
                    hover: {
                        color: Highcharts.color(colorThemesArray[0][0]).brighten(0.2).get()
                    },
                    select: {
                        color: Highcharts.color(colorThemesArray[0][0]).brighten(-0.4).get()
                    }
                }
            }],
            colors: ['#d8d8d8'],
            exporting: {
                enabled: false,
            },
            credits: {
                enabled: false
            }
        }
       // var newColor = $.merge($.merge([], []), colorThemesArray[0]);
       // newColor.splice(0, 1);
        options.colors=colorThemesArray[0];
        options.series[0].colorByPoint=true;

        if(this.state.categoryType!=undefined && this.state.categoryType=="number"){
            options.chart.type='spline';
            options.plotOptions.series.color = colorThemesArray[0][0];
            options.series[0].colorByPoint=false;
            options.plotOptions.spline ={lineWidth:2,marker:{lineWidth:0,radius: 4,fillColor:colorThemesArray[0][0],states:{select:{lineWidth:0,radius: 8,fillColor:Highcharts.color(colorThemesArray[0][0]).brighten(-0.4).get()}}}}
        }
        var insightkeyinfluencersavg = new Highcharts.Chart(options);
    }
    drawSmartAVGBar(data, avgValue, categories, renderto, yaxisname, htmlnlgdata, xaxisDisplay, yaxisDisplay, tabid, reportId, datamodelId, isFromStoryBook) {
        var that = this;
        var colorThemesArray = [
            ["#00bcd4","#37c2d9","#50c8dd","#64cee2","#76d4e7","#86daeb","#95e0f0","#a3e6f4","#b1ecf9"],
            ["#00bcd4","#00CECC","#42DEB9","#7FEB9E","#BBF484"],
            ["#4e79a7", "#fc7d0b", "#a3acb9", "#57606c", "#5fa2ce", "#c85200", "#7b848f", "#a3cce9", "#ffbc79", "#c8d0d9"],
            ["#4e79a7", "#58606c", "#a2abb8"],
            ["#4e79a7", "#f28e2b", "#e15759", "#76b7b2", "#59a14f", "#edc948", "#b07aa1", "#ff9da7", "#9c755f", "#bab0ac"]

        ];
        var options = {
            chart: {
                renderTo: renderto[0],
                type: 'bar',
                zoomType: 'xy',
                backgroundColor: 'transparent',
                style: {
                    fontFamily: whitelableMap["fontfamily"],
                    fontWeight: '400',
                },
                animation: false,
                plotBorderWidth: 0,
                spacing: [15, 25, 10, 25]
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: categories,
                title: {
                    text: that.state.xaxis,
                    enabled: false
                },
                lineWidth: 0.3,
                tickWidth: 0.3,
                gridLineWidth: 0,
                labels: {
                    step: 1
                }
            },
            yAxis: {

                title: {
                    text: that.state.yaxis,
                    enabled: false
                },

                lineWidth: 0,
                tickWidth: 0,
                gridLineWidth: 0,
                plotLines: [{
                    color: 'red',
                    value: avgValue,
                    width: '1',
                    zIndex: 4,
                    dashStyle: 'LongDash',
                    label: {
                        text: "Overall Average (" + avgValue + ")",
                        verticalAlign: 'top',
                        textAlign: 'center',
                        rotation: 0,
                        y: -3,
                        x: -30
                    }
                }]
            },
            tooltip: {
                    useHTML:true
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: false
                    },
                },
                series: {
                    cursor: 'pointer',
                    pointPadding: 0,
                    // groupPadding: 0.1,
                    states: {
                        hover: {
                            color: ['#fff']
                        },
                        select: {
                            color: colorThemesArray[0][0]
                        }
                    },
                    slicedOffset: 0,
                    point: {
                        events: {
                            click: function (event) {
                                if (this.series.chart.getSelectedPoints().length > 0) {
                                    this.series.chart.getSelectedPoints()[0].select(false, true)
                                }
                                this.select(null, true);

                                var xaxis = this.series.chart.series[0].xAxis.userOptions.title.text;
                                var yaxis = this.series.chart.series[0].yAxis.userOptions.title.text;
                                var pointname = event.point.x_name!=undefined&&event.point.x_name!=''?event.point.x_name:event.point.name;
                                var filterArray = [];
                                var filterObject = {};
                                //$(".insightavgsuggestion1 #spinnerchart9").show();
                                filterObject["dataType"] = "number";
                                if (isNaN(pointname) == true) {
                                    filterObject["dataType"] = "string";
                                }
                                filterObject["tablename"] = "";
                                filterObject["columndisplayname"] = xaxis!=undefined?xaxis:xaxisDisplay;
                                filterObject["columnname"] = xaxis!=undefined?xaxis:xaxisDisplay;
                                filterObject["data"] = pointname;
                                filterArray.push(filterObject);
                                var samrtInsightFilterDrillDown = new SmartInsightsFilterDrillDown(
                                    {
                                        xaxis: xaxis,
                                        yaxis: yaxis,
                                        filter: filterArray,
                                        htmlnlgdata: htmlnlgdata,
                                        xaxisDisplay: xaxisDisplay,
                                        yaxisDisplay: yaxisDisplay,
                                        tabid: tabid,
                                        reportId: reportId,
                                        datamodelId: datamodelId,
                                        isFromStoryBook: isFromStoryBook,

                                    });
                            }
                        }
                    }
                }
            },
            legend: {
                enabled: true,
                align: 'center',
                symbolHeight: 12,
                symbolWidth: 12,
                symbolRadius: 6
            },
            series: [{
                xaxis: that.state.xaxis,
                yaxis: that.state.yaxis,
                name: "Average " + yaxisDisplay + "",
                data: data,
                states: {
                    hover: {
                        color: Highcharts.color(colorThemesArray[0][0]).brighten(0.2).get()
                    },
                    select: {
                        color: Highcharts.color(colorThemesArray[0][0]).brighten(-0.4).get()
                    }
                }
            }],
            colors: ['#d8d8d8'],
            exporting: {
                enabled: false,
            },
            credits: {
                enabled: false
            }
        }

        //var newColor = $.merge($.merge([], []), colorThemesArray[0]);
        //newColor.splice(0, 1);
        options.colors=colorThemesArray[0];
        options.series[0].colorByPoint=true;

        if(this.state.categoryType!=undefined && this.state.categoryType=="number"){
            options.chart.type='spline';
            options.plotOptions.series.color = colorThemesArray[0][0];
            options.series[0].colorByPoint=false;
            options.plotOptions.spline ={lineWidth:2,marker:{lineWidth:0,radius: 4,fillColor:colorThemesArray[0][0],states:{select:{lineWidth:0,radius: 8,fillColor:Highcharts.color(colorThemesArray[0][0]).brighten(-0.4).get()}}}}
        }

        var insightkeyinfluencersavg = new Highcharts.Chart(options);
    }

}