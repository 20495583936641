import React from "react";
import { lang } from "../js/helpers/utils/languages/language";

export default class CombinationChartAttributes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        var that = this.props;
        return (
            //    <!-- combinationchartsview.js -->
            <li key={that.dynamicdiv} className="boc-list-item singleliitems combinationchart" data-name={that.yaxiscolumnName} data-charttype={that.aggregation} data-selectedcharttype={that.selectedChartType} data-type={that.yaxiscolumnType} title={that.yaxiscolumnDisplayName} table-name={that.yaxistableName} percentile-value={that.percentileValue} data-axisposition={that.chartaxisposintion} data-num-yaxis={that.id}>
                <span className="itemcontainer">
                    <span className="itemtype">
                        {(that.yaxiscolumnType === 'customaggregatedfield') ? (
                            <span className="function">{lang["storyboard.fx"]}</span>
                        ) : (that.yaxiscolumnType === 'custommeasurehierarchy') ? (
                            <i className="fa fa-list"></i>
                        ) : (that.yaxiscolumnType === 'string') ? (
                            <span>{lang["storyboard.abc"]}</span>
                        ) : (that.yaxiscolumnType === 'number') ? (
                            <>123</>
                        ) : (that.yaxiscolumnType === 'date') ? (
                            <i className="fa fa-calendar"></i>
                        ) : null}
                    </span>
                    <span className="itemname">{that.yaxiscolumnDisplayName}</span>
                </span>
                <span className="itemoptions">
                    <span className="itemremove">
                        <button className="btn btn-transparent disabled" type="button" title={lang["storyboard.remove"]}><i className="fa fa-trash-o"></i></button>
                    </span>
                    <span className="itemaggregation">
                        <span className="comboaxistext" aria-expanded="false" data-toggle="dropdown">{that.chartaxisposintion}</span>
                        <ul role="menu" className="dropdown-menu ">
                            <li className="combochart-li-item combinationchartleft conbiantionchartaxisposintion" onClick={that.changeCombinationAxisPosition} title={lang["storyboard.smleft"]}>{lang["storyboard.smleft"]}</li>
                            <li className="combochart-li-item combinationchartright conbiantionchartaxisposintion" onClick={that.changeCombinationAxisPosition} title={lang["storyboard.smright"]}>{lang["storyboard.smright"]}</li>
                        </ul>
                        <span className="dropdown">
                            <span className="aggregationtext" aria-expanded="false" data-toggle="dropdown">{that.aggregation}</span>
                            <ul role="menu" className="dropdown-menu">
                                <li className="aggregation-li-item combinationcharttypebar" onClick={that.changeCombinationCharttype}  title={lang["storyboard.smcolumn"]}>{lang["storyboard.bar"]}</li>
                                <li className="aggregation-li-item combinationcharttypeline" onClick={that.changeCombinationCharttype} title={lang["storyboard.line"]}>{lang["storyboard.line"]}</li>
                                <li className="aggregation-li-item combinationcharttypeline" onClick={that.changeCombinationCharttype} title={lang["storyboard.smspline"]}>{lang["storyboard.smspline"]}</li>
                                <li className="aggregation-li-item combinationcharttypeline" onClick={that.changeCombinationCharttype} title={lang["storyboard.area"]}>{lang["storyboard.area"]}</li>
                            </ul>
                        </span>
                    </span>
                </span>
            </li>);
    }
}