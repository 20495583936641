import _ from 'lodash';
import $ from 'jquery/dist/jquery';
import React, { Component } from "react";
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import { lang } from "../js/helpers/utils/languages/language";
import ReactDOM from "react-dom";

export default class CustomSolidGuageDropDown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicdiv: props.dynamicdiv,
            addcustomsolidgaugevalue: props.addcustomsolidgaugevalue,
            columnDisplayName: props.columnDisplayName,
            columnName: props.columnName,
            dataType: props.dataType,
            tableName: props.tableName,
            aggregation: props.aggregation,
            percentileValue: props.percentileValue,
            multifact: (props.multifact == 'true'),
            multifactentities: (props.multifact == 'true') ? JSON.parse(props.multifactentities) : props.multifactentities,
        };
        this.render();
    }
    render() {
        var disable = false;
        var facttables = this.props.facttables;
        if (this.state.multifact) {
            disable = ChartInfo.functions.getAllowedEntities(this.state.multifactentities, this.state.columnName, this.props.customfield, undefined, undefined, facttables, "xdropdown");
        }
        ReactDOM.render(<CustomSolidGaugeTemplate
            dynamicdiv={this.state.dynamicdiv}
            addcustomsolidgaugevalue={this.state.addcustomsolidgaugevalue}
            columnDisplayName={this.state.columnDisplayName}
            columnName={this.state.columnName}
            dataType={this.state.dataType}
            aggregation={this.state.aggregation}
            tableName={this.state.tableName}
            percentileValue={this.state.percentileValue}
            disable={disable}
        ></CustomSolidGaugeTemplate>, document.getElementById(this.state.dynamicdiv));
        return (<div></div>);
    }
}
class CustomSolidGaugeTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        var that = this.props;
        return (<div key={that.dynamicdiv}>
            {(that.dataType != 'customaggregatedfield' && that.dataType != 'custommeasurehierarchy') ? (
                <li key={that.dynamicdiv} title={that.columnDisplayName} data-name={that.columnName} table-name={that.tableName} data-type={that.dataType} className={`xs-list-item customsolidgaugeitem ${that.disable ? " hide" : ""}`} onClick={that.addcustomsolidgaugevalue} percentile-value={that.percentileValue}>
                    <>
                        <span className="itemtype">
                            {(that.dataType === 'number') ? (
                                <>123</>
                            ) : (that.dataType === 'date' || that.dataType === 'datetime') ? (
                                <i className="fa fa-calendar"></i>
                            ) : (that.dataType === 'month') ? (
                                <i className="fa fa-calendar"></i>
                            ) :
                                        <>{lang["storyboard.abc"]}</>
                            }
                        </span>
                        <span className="itemname">{that.columnDisplayName}</span>
                        <span className="boclabel boclabel-right-5 opacity-effect boclabel-info">[ {that.aggregation} ]</span>
                    </> </li>
            ) : null}
        </div>);
    }
}