import React, { Component } from "react";
import ReactDOM from 'react-dom';
import SendRequest from '../../SendRequest';
import SyncConfiguarationsMain from './SyncConfiguarationsMain';
import { lang } from '../../js/helpers/utils/languages/language';
const $ = window.$;

export default class SyncConfigurationView extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount(){
        this.syncConfiguarations();
        var path = '<li><a class="navDashboard" href="#"><i class="fa  fa-home"></i>'+lang["admin.header.dashtitle"]+'</a></li><li><a href="#admin"><i class="fa fa-user-secret"></i> ' + lang["admin.header.admintitle"] + '</a></li><li><a> <i class="fa fa-refresh"></i>' + lang["admin.syncsettings.title"] + '</a></li>';
		$(".navbar.navbar-bird ul.nav-path").html(path);
    }
    syncConfiguarations = () => {

        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getSyncConfigDetails",
            queryString: "",
            sucessFunction: (response) => {
                if (response.hasOwnProperty('error')) {
                    $(".spinner").hide();
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('hide');
                    $('.birdmessage h2').empty().append('Error : ');
                    $('.birdmessage h2').append('<span class="errorcode">');
                    $('.birdmessage .errorcode').empty().append(response.errorCode);
                    $('.birdmessage-info').empty().text(response.error);
                    $('.details').addClass('show');
                    $('.messagedetails xmp').empty().append(response.errorTrace)
                } else {
                    var syncConfiguarationsMain = new SyncConfiguarationsMain({
                        configs: response
                    });
                }
            },
            rejectFunction: () => { },
            headers: requestHeaders

        });

    }
    render(){
        $('a.boc-maxscreen-left-item.syncsettings').addClass('showactive');
        $('.boc-admin-main .maxscreen-lft-filter-items a').not('.syncsettings').removeClass('showactive');
        return(<div class="container-fluid">
        <div class="row">
          <h1 class="boc-main-title">
            <i class="fa fa-refresh fa-admin-sm"></i>{lang["admin.syncsettings.title"]}
          </h1>
      
      
          <div class="container-fluid">
            <div id="syncconfig">
              
            </div>
      
        </div>
        </div>
      </div>);
    }
}