import React from 'react';
import { lang } from "../js/helpers/utils/languages/language";
import _ from 'underscore';

export default class LogfilesTables extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <React.Fragment>
                {(this.props.isFromEditFlatFiles === true) ? (
                    <table id="logfiletable" data-toggle="table" className="marg-top-10" data-pagination="true" data-page-size="5" data-smart-display="true" data-search="true" data-pagination-v-align="bottom" data-search-align="right">
                        <thead>
                            <tr>
                                <th>{lang["datahub.slct"]}</th>
                                <th data-sortable="true">{lang["datahub.filename"]}</th>
                                <th data-sortable="true">{lang["datahub.filesize"]}</th>
                                <th data-sortable="true">{lang["datahub.lastupdtd"]}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="centertext">
                                    <input type="radio" name="selectedlogfile" className="selectedlogfile" value={this.props.fileName} checked="checked" />
                                </td>
                                <td title={this.props.fileName}>{this.props.fileName}</td>
                                <td title={this.props.fileSize}>{this.props.fileSize}</td>
                                <td title={this.props.fileCreatedDate}>{this.props.fileCreatedDate}</td>
                            </tr>
                        </tbody>
                    </table>
                ) : (
                        <table id="logfiletable" data-toggle="table" className="marg-top-10" data-pagination="true" data-page-size="5" data-smart-display="true" data-search="true" data-pagination-v-align="bottom" data-search-align="right">
                            <thead>
                                <tr>
                                    <th>{lang["datahub.slct"]}</th>
                                    <th data-sortable="true">{lang["datahub.filename"]}</th>
                                    <th data-sortable="true">{lang["datahub.filesize"]}</th>
                                    <th data-sortable="true">{lang["datahub.lastupdtd"]}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.props.filesCollection.map((file,index)=>{
                                        return(
                                            <tr>
                                            <td className="centertext">
                                                <input type="radio" name="selectedlogfile" className="selectedlogfile" value={file.fileName} />
                                            </td>
                                            <td title={file.fileName}>{file.fileName}</td>
                                            <td title={file.fileSize}>{file.fileSize}</td>
                                            <td title={file.fileCreatedDate}>{file.fileCreatedDate}</td>
                                        </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    )}
            </React.Fragment>
        );
    }
}