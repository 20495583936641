import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash';
import * as ChartInfo from '../js/helpers/utils/chartinfo';
import { getMessage } from '../js/helpers/utils/BirdMessage';
import SendRequest from "../SendRequest";
import DataShareTemplate from './datamodelshare.jsx'
import DataModelsAdvancedShareView from './datamodelsadvancedshareview'
import { lang } from "../js/helpers/utils/languages/language";

const $ = window.$;
export default class DataModelShareView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.state.dataModelInfo = this.props.dataModelInfo[0];
        this.state.modelId = this.state.dataModelInfo.dataModelId;
        this.state.multifact = this.state.dataModelInfo.multifact;
        this.state.modelColumns = this.props.modelColumns;
        var that = this;

        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };


        SendRequest({
            url: "getsharedusersformodel",
            queryString: "?dataModelId=" + that.state.dataModelInfo.dataModelId,
            sucessFunction: (response) => {
                that.state.sharedUsersForModelCollection = response;

                SendRequest({
                    url: "getsharedgroupsformodel",
                    queryString: "?dataModelId=" + that.state.dataModelInfo.dataModelId,
                    sucessFunction: (response1) => {
                        that.state.sharedGroupsForModelCollection = response1;

                        SendRequest({
                            url: "getAllGroups",
                            queryString: "",
                            sucessFunction: groupdetails => {
                                that.state.groupcollection = groupdetails;
                                SendRequest({
                                    url: "getUsersForSharing",
                                    queryString: "?isFromGovernance=" + true,
                                    sucessFunction: userResponse => {
                                        that.state.collection = userResponse;
                                        that.render();
                                    },
                                    rejectFunction: () => { },
                                    headers: requestHeaders
                                });
                            },
                            rejectFunction: () => { },
                            headers: requestHeaders
                        });
                    },
                    rejectFunction: () => { },
                    headers: requestHeaders,
                });
            },
            rejectFunction: () => { },
            headers: requestHeaders,
        });
    }
    render() {
        var usersList = this.state.collection;
        usersList.sort(function (a, b) {
            var x = a.username.toLowerCase();
            var y = b.username.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
        });
        var groups = this.state.groupcollection;
        groups.sort(function (a, b) {
            var x = a.groupName.toLowerCase();
            var y = b.groupName.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
        });

        ReactDOM.render(<DataShareTemplate sharemodelapply={this.sharemodelapply} datapermissionupdate={this.datapermissionupdate} bactosharemodel={this.bactosharemodel} advancedsharemodels={this.advancedsharemodels} showadvanced={this.showadvanced} searchusersgroups={this.searchusersgroups} sharemodelclose={this.sharemodelclose} dataModelName={this.state.dataModelInfo.dataModelName} users={usersList} groups={groups} />, document.getElementById("datamodelsharecontainer"));

        $(".group-select").chosen({
            no_results_text: lang["models.oops"],
            width: "100%",
            search_contains: true
        });
        $("body .datamain-fixed-content-container").addClass("shareopen")
        this.displaygroups();
        $('#groupings').on('change', function (evt, params) {
            if (params.deselected != undefined && params.deselected != '') {
                var value = $(".accordion-toggle[aria-controls='user" + params.deselected + "']").length > 0 ? $(".accordion-toggle[aria-controls='user" + params.deselected + "']").attr("href") : $(".accordion-toggle[aria-controls='group" + params.deselected + "']").attr("href")
                if (value != undefined && value != '') {
                    $(".accordion-toggle[href='" + value + "']").remove();
                    $(value).remove();
                }
            }
        });
        return (<div></div>);
    }
    showadvanced = (e) => {
        e.preventDefault();
        var $this = e.currentTarget;
        $($this).parent().find('.filtersearching').toggle('slow');
    }
    searchusersgroups = (e) => {
        e.preventDefault();
   			var startswith = $('#searchnamestartswith').val();
			var endswith = $("#searchnameendswith").val();
			var contains = $("#searchnamecontains").val();
    
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getSearchedGroupsList",
            queryString: "?startswith=" + startswith + "&endswith=" + endswith + "&contains=" + contains,
            sucessFunction: (response) => {
               var groupOptionArray = [];
            $.each(response, function(i, group)
            {
                groupOptionArray[i] = "<option key='" + i + "' value='" + group.groupId + "'>" + group.groupName + "</option>";
            });
            $('.group-select>optgroup.groupids').find('option').not(':selected').remove()
            $('.group-select>optgroup.groupids').prepend(groupOptionArray.join(''));
            $('.group-select').trigger("chosen:updated");
            $('.group-select').trigger('chosen:open');
            },
            rejectFunction: () => { },
            headers: requestHeaders
        });
        SendRequest({
            url: "getsearchedusersforsharing",
            queryString: "?startswith=" + startswith + "&endswith=" + endswith + "&contains=" + contains +"&isFromGovernance=" + true,
            sucessFunction: (response) => {
                var userOptionsArray = [];
                $.each(response, function(i, user)
                        {	if(!user.isLoginUser){
                            userOptionsArray[i] = "<option key='" + i + "' value='" + user.userid + "'>" + user.username+" ("+user.email+")" + "</option>";
                            }
                        });
                $('.group-select>optgroup.userids').find('option').not(':selected').remove()
                $('.group-select>optgroup.userids').prepend(userOptionsArray.join(''));
                $('.group-select').trigger("chosen:updated");
                $('.group-select').trigger('chosen:open'); 
            },
            rejectFunction: () => { },
            headers: requestHeaders
        });

        
    }
    sharemodelclose = (e) => {
        $("body .datamain-fixed-content-container").removeClass("shareopen");
        ReactDOM.unmountComponentAtNode(document.getElementById("datamodelsharecontainer"));
    }
    bactosharemodel = (e) => {
        $("#showadvanceditems, .basicshareinfo, .sharemodelapply").removeClass('hide');
        $('.datapermissiondiv, .datapermissionupdate').addClass("hide");
    }
    displaygroups() {
        _.each(this.state.sharedGroupsForModelCollection, function (model, index) {
            if (model.isshared != "N") {
                $('.groupids option[value=' + model.groupid + ']').attr("selected", "selected");
                $(".group-select").trigger("chosen:updated");
            }
        })

        _.each(this.state.sharedUsersForModelCollection, function (model, index) {
            $('.userids option[value=' + model.userid + ']').attr("selected", "selected");
            $(".group-select").trigger("chosen:updated");
        })
    }
    advancedsharemodels = (e) => {
        var that = this;
        var columns = this.state.modelColumns;
        var dimensionArray = [];
        //_.each(JSON.parse(columns), function(column,i){
        /*_.each(columns, function(column,i){	
            var obj={};
            obj["aggregation"]=column.aggregation,
            obj["columnDisplayName"]=column.columnDisplayName,
            obj["columnName"]=column.columnName,
            obj["id"]=column.id,
            obj["keycolumn"]=column.keycolumn,
            obj["tableDisplayName"]=column.tableDisplayName,
            obj["tableDisplayType"]=column.tableDisplayType,
            obj["tableName"]=column.tableName,
            obj["columnssrename"]=column.columnssrename,
            obj["unique"]=column.unique,
            obj["visible"] = column.visible,
            obj["tableAliasName"] = column.tableAliasName,
            obj["dateFormat"] = column.dateFormat,
            obj["orginalColumnName"] = column.orginalColumnName
            dimensionArray.push(obj); 
        });*/
        var isAdvancedShown = $("#showadvanceditems").is(':empty');
        if (!isAdvancedShown) {
            ReactDOM.unmountComponentAtNode(document.getElementById("showadvanceditems"));
            //$(".basicshareinfo").removeClass("hide");
        } else {
            //$(".basicshareinfo").addClass("hide");
            var choosengroupNames = $('.group-select .groupids option:selected');
            var choosenUserNames = $('.group-select .userids option:selected');
            var DataGovernanceViewContainer = new DataModelsAdvancedShareView({
                selectedgrouplist: choosengroupNames,
                selectuserslist: choosenUserNames,
                groupcollection: that.state.groupcollection,
                userscollection: that.state.collection,
                datamodelId: that.state.modelId,
                sharedtogroups: that.state.sharedGroupsForModelCollection,
                sharedtousers: that.state.sharedUsersForModelCollection,
                dimensions: columns
            });
        }
    }
    datapermissionupdate = (e) => {
        e.preventDefault();
        // for checking the min and max value for numeric values in advanced filters
        if (!this.validateRangeValues()) {
            return false;
        }
        // 			Updating previous users filters before showing current user filter
        if ($('.datapermissiondiv').is(":visible")) {
            $(".datapermissionupdatecl").trigger("click");
            this.datapermissionupdatecl(e);
        }
        var that = this;
        var userObject = {};
        var viewOnlyObject = {};
        var userType;
        var isViewOnlyOptionArray = [];
        $(".btnReadonlyReport").each(function (j, element) {

            var userobj = {};
            var obj = {}
            userType = $(this).attr("user-type");
            if ($(element).is(":checked")) {
                obj["id"] = $(this).attr("data-userid");
                obj["checkedValue"] = $(element).is(":checked");


                if ($(this).attr("user-type") == "users" || $(this).attr("user-type") == "groups") {
                    userobj[userType] = obj;
                }
            }
            else {
                obj["id"] = $(this).attr("data-userid");
                obj["checkedValue"] = $(element).is(":checked");//	
                if ($(this).attr("user-type") == "users" || $(this).attr("user-type") == "groups") {
                    userobj[userType] = obj;
                }
            }
            isViewOnlyOptionArray.push(userobj);
        });
        viewOnlyObject["viewonlyobject"] = isViewOnlyOptionArray;

        var filterArray = ChartInfo.functions.getUserFilterArray();
        var userFilterArray = {
            "users": {},
            "groups": {}
        };
        $(".dobyfilteritems").each(function (e) {
            var selectedColumnsArray = [];
            var $this = this;
            var id = $($this).attr("data-userid");
            var modelid = $($this).attr("data-modelid");
            var usertype = $($this).closest('.permissioncontainer').attr('user-type');
            $(".dobyfilteritems[data-userid=" + id + "] .permissionfiltercheckbox").each(function (j, element) {
                var obj = {};
                if ($(element).attr("data-unique") == "true") {
                    $(element).prop('checked', true);
                }
                if ($(element).is(":checked") || $(element).attr("data-unique") == "true") {
                    obj["tableName"] = $($($(this))).attr("data-parent");
                    obj["tableDisplayName"] = $($($(this))).attr("data-parent");
                    obj["name"] = $($($(this))).attr("data-child");
                    obj["columnName"] = $($($(this))).attr("data-child");
                    obj["tableDisplayType"] = $($($(this))).attr("data-type");
                    obj["type"] = $($($(this))).attr("data-type");
                    obj["orginalColumnName"] = $($($(this))).attr("data-orginalColumnName");
                    obj["columnDisplayName"] = $($($(this))).parent().text().trim();
                    obj["tableAliasName"] = $($($(this))).attr("data-tableAliasName");
                    obj["visible"] = $($($(this))).attr("data-visible");
                    selectedColumnsArray.push(obj);
                }
            });
            if (id != undefined && id != "" && id != null) {
                userObject[id + '_' + modelid] = selectedColumnsArray;
            }
            if (usertype != undefined && id != undefined) {
                var existingFilterArray = filterArray[usertype][id];
                if (existingFilterArray != undefined && existingFilterArray.length > 0) {
                    var newFilter = [];
                    _.each(selectedColumnsArray, function (column) {
                        newFilter = $.grep(filterArray[usertype][id], function (e, index) {
                            return e.columndisplayname == column.columnDisplayName;
                        })
                        if (newFilter != undefined && newFilter.length > 0) {
                            if (userFilterArray[usertype] != undefined) {
                                if (userFilterArray[usertype][id] != undefined && userFilterArray[usertype][id].length > 0) {
                                    userFilterArray[usertype][id] = newFilter.concat(userFilterArray[usertype][id]);
                                } else {
                                    userFilterArray[usertype][id] = newFilter;
                                }
                            }
                        }
                    });
                }
            }
            // for combining the saved shared filters with current filters
            var parsedSharedfilters;
            if (that.state.sharedFilters != undefined && that.state.sharedFilters.length > 2) {
                parsedSharedfilters = JSON.parse(that.state.sharedFilters);
            }
            if (parsedSharedfilters != undefined && parsedSharedfilters.length > 0) {
                _.each(parsedSharedfilters, function (obj, index) {
                    _.each(existingFilterArray, function (newFilterArray) {
                        parsedSharedfilters = $.grep(parsedSharedfilters, function (e) {
                            return e.columnname != newFilterArray.columnname;

                        });
                        parsedSharedfilters.push(newFilterArray);
                    });
                });
                if (userFilterArray[usertype] != undefined && userFilterArray[usertype][id] != undefined && userFilterArray[usertype][id].length > 0) {
                    userFilterArray[usertype][id] = parsedSharedfilters;
                }
            } else {
                if (userFilterArray[usertype] != undefined && userFilterArray[usertype][id] != undefined && userFilterArray[usertype][id].length > 0) {
                    userFilterArray[usertype][id] = existingFilterArray;
                }
            }
        });
        if (userFilterArray != undefined) {
            ChartInfo.functions.setUserFilterArray(userFilterArray);
            ChartInfo.functions.setAppliedSharedFilterArray(userFilterArray);
        }
        //	ChartInfo.functions.setisViewOnlyOptions(viewOnlyObject);
        ChartInfo.functions.setSelectedColumnsForSharedReports(userObject);
        that.bactosharemodel();
    }
    datapermissionupdatecl(e, fromEdit) {
        e.preventDefault();
        var $this = e.currentTarget;
        var userId = $($this).attr("data-userid");
        var isadded = true;
        var userid = $('.permissioncontainerrightfilter').attr('data-userid');
        var usertype = $('.permissioncontainerrightfilter').attr('data-usertype');
        var filterArray = ChartInfo.functions.getFilters(false, "advancedSharedReport", fromEdit);

        var tempFilterArray = [];
        var userFiltersArray = ChartInfo.functions.getUserFilterArray();
        var filterObject = {};
        var min;
        var max;
        var existingFilterArray = userFiltersArray[usertype][userid];
        if (existingFilterArray != undefined && existingFilterArray.length > 0) {
            _.each(existingFilterArray, function (obj, index) {
                _.each(filterArray, function (newFilterArray) {
                    if (newFilterArray.filtergreaterthanvalue != undefined) {
                        max = $(" .intfiltervalue .filtergreaterthanvalue[columndisplayname='rental_duration']").attr("max");
                        min = $(" .intfiltervalue .filterlessthanvalue[columndisplayname='rental_duration']").attr("min");
                        console.log(min);
                    }
                    existingFilterArray = $.grep(existingFilterArray, function (e) {
                        return e.columnname != newFilterArray.columnname;

                    });
                });
            });
            userFiltersArray[usertype][userid] = existingFilterArray.concat(filterArray);
        } else {
            userFiltersArray[usertype][userid] = filterArray;
        }
        ChartInfo.functions.setUserFilterArray(userFiltersArray);
    }
    validateRangeValues(e) {
        var filterAccordianLength = $('.acc-content').length;
        var flag = false;
        for (var i = 0; i < filterAccordianLength; i++) {
            var $this = $('.acc-content').eq(i);
            var fromRangeVal = $($this).find('.irs-min').text();
            var toRangeVal = $($this).find('.irs-max').text();
            var greaterThanValue = $($this).find('#greaterthanvalue').val();
            var lessThanValue = $($this).find('#lessthanvalue').val();
            $($this).find('.filtergreaterthanvalue.filter-error').addClass("hide");
            $($this).find('.filterlessthanvalue.filter-error').addClass("hide");
            /*if(greaterThanValue != undefined && greaterThanValue !="" &&
                (!(parseFloat(greaterThanValue) >= parseFloat(fromRangeVal)) || !(parseFloat(greaterThanValue) <= parseFloat(toRangeVal)))){
                $($this).find('.filtergreaterthanvalue.filter-error').removeClass("hide").text(getMessage('BirdWarning108'));
                $($this).find('#greaterthanvalue').focus();
                flag = true;
            }
            if(lessThanValue != undefined && lessThanValue !="" &&
                (!(parseFloat(lessThanValue) >= parseFloat(fromRangeVal)) || !(parseFloat(lessThanValue) <= parseFloat(toRangeVal)))){
                $($this).find('.filterlessthanvalue.filter-error').removeClass("hide").text(getMessage('BirdWarning108'));
                $($this).find('#lessthanvalue').focus();
                flag = true;
            }*/
        }
        /*if(flag){
            return false;
        }*/
        return true;
    }
    sharemodelapply = (e) => {
        var that = this;
        let requestHeaders = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        //	this.advancedsharemodel();
        var grouping;
        $('.accordion-toggle').removeClass("error");
        if ($('.group-select .groupids option:selected').val() == undefined || $('.group-select .groupids option:selected').val() != undefined) {
            grouping = $('.group-select .groupids option:selected').val();
        }
        if ($('.group-select .groupids option:selected').val() == undefined || $('.group-select .userids option:selected').val() != undefined) {
            grouping = $('.group-select .userids option:selected').val();
        }
        var selectedUserNames = $('.group-select .userids option:selected');
        var selectedGroupNames = $('.group-select .groupids option:selected');
        if (selectedUserNames.length == 0 && selectedGroupNames.length == 0 && this.state.sharedUsersForModelCollection.length == 0 && this.state.sharedGroupsForModelCollection.length == 0) {
            $(".spinner").hide();
            $(".gridspinner").hide();
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(lang["models.slctatleastgrp"]);
            return false;
        }

        var comments = $("#datamodelsharecontainer").find("#sharemodelcomments").val();
        this.datapermissionupdate(e);
        var userfilterArray = ChartInfo.functions.getAppliedSharedFilterArray();
        if (this.state.sharedUsersForModelCollection.length == 0 && this.state.sharedGroupsForModelCollection.length == 0) {
            if (true) {
                var choosenUserNames = $('.group-select .userids option:selected');
                var choosenUserIds = "";
                _.each(choosenUserNames, function (groupId, i) {
                    choosenUserIds += $($('.group-select .userids option:selected')[i]).val() + ",";
                });
                choosenUserIds = choosenUserIds.substring(0, choosenUserIds.length - 1);
                var choosengroupNames = $('.group-select .groupids option:selected');
                var choosenGroupIds = "";
                _.each(choosengroupNames, function (groupId, i) {
                    choosenGroupIds += $($('.group-select .groupids option:selected')[i]).val() + ",";

                });
                choosenGroupIds = choosenGroupIds.substring(0, choosenGroupIds.length - 1);
                var that = this;
                var selectedcolumns = ChartInfo.functions.getSelectedColumnsForSharedReports();
                if (_.isEmpty(selectedcolumns)) {
                    selectedcolumns = "";
                }
                else {
                    if (that.state.multifact == "true") {
                        var showWarning = false;
                        _.each(selectedcolumns, function (cols, id) {
                            var factsincols = [];
                            _.each(cols, function (colobj, id) {
                                var tableName = colobj.columnName.split(".")[0];
                                if (that.props.facttables.indexOf(tableName) >= 0 && factsincols.indexOf(tableName) == -1) {
                                    factsincols.push(tableName);
                                }
                            });
                            if (factsincols.length < 2) {
                                showWarning = true;
                                $('#showfacterror' + id).addClass("error");
                            }
                        });
                        if (showWarning) {
                            $(".spinner").hide();
                            $(".gridspinner").hide();
                            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                            $('.birdmessagebuttons').removeClass('show');
                            $('.birdmessagebuttons').addClass('hide');
                            $('.birdmessage-center').addClass("message-top-in");
                            $('.details').removeClass('show');
                            $('.details').addClass('hide');
                            $('.birdmessage h2').empty().append('Warning');
                            $('.birdmessage-info').empty().text(getMessage('BirdWarning142'));
                            return;
                        }
                    }
                    selectedcolumns = JSON.stringify(selectedcolumns)
                }

                // var count = this.state.collection.length;
                // for (var i = count - 1; i > -1; i--) {
                //     this.state.collection.remove(this.state.collection.at(i));
                // }
                var sharecomments = $("#sharemodelcomments").val();
                var reqdata = new FormData();
                reqdata.set("modelId", that.state.modelId);
                reqdata.set("multifact", that.state.multifact);
                reqdata.set("comment", sharecomments);
                reqdata.set("userIds", choosenUserIds);
                reqdata.set("groupIds", choosenGroupIds);
                reqdata.set("selectedcolumns", selectedcolumns);
                reqdata.set("userfilterArray", JSON.stringify(userfilterArray));
                SendRequest({
                    url: "shareDataModel",
                    body: reqdata,
                    sucessFunction: (response) => {
                        if (response.hasOwnProperty('error')) {
                            $(".spinner").hide();
                            $(".gridspinner").hide();
                            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                            $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                            $('.birdmessagebuttons').removeClass('show');
                            $('.birdmessagebuttons').addClass('hide');
                            $('.birdmessage-center').addClass("message-top-in");
                            $('.details').removeClass('hide');
                            $('.birdmessage h2').empty().append('Error : ');
                            $('.birdmessage h2').append('<span class="errorcode">');
                            $('.birdmessage .errorcode').empty().append(response.errorCode);
                            $('.birdmessage-info').empty().text(response.error);
                            $('.details').addClass('show');
                            $('.messagedetails xmp').empty().append(response.errorTrace)
                        } else {
                            $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                            $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                            $('.birdmessagebuttons').removeClass('show');
                            $('.birdmessagebuttons').addClass('hide');
                            $('.birdmessage-center').addClass("message-top-in");
                            $('.details').removeClass('show');
                            $('.details').addClass('hide');
                            $('.birdmessage h2').empty().append('Success');
                            $('.birdmessage-info').empty().text(getMessage('BirdSuccess61'));
                            $("#sharestoryboard").modal("hide");
                            that.sharemodelclose();
                        }
                    },
                    rejectFunction: this.reportDataError,
                    headers: requestHeaders
                });
            }
        }
        else {
            var choosenUserNames = $('.group-select .userids option:selected');
            var choosenUserIds = "";
            _.each(choosenUserNames, function (groupId, i) {
                choosenUserIds += $($('.group-select .userids option:selected')[i]).val() + ",";

            });
            choosenUserIds = choosenUserIds.substring(0, choosenUserIds.length - 1);
            var choosengroupNames = $('.group-select .groupids option:selected');
            var choosenGroupIds = "";
            _.each(choosengroupNames, function (groupId, i) {
                choosenGroupIds += $($('.group-select .groupids option:selected')[i]).val() + ",";

            });
            choosenGroupIds = choosenGroupIds.substring(0, choosenGroupIds.length - 1);

            // var count = this.state.collection.length;
            // for (var i = count - 1; i > -1; i--) {
            //     this.state.collection.remove(this.state.collection.at(i));
            // }
            var sharecomments = $("#sharemodelcomments").val();
            var that = this;
            var selectedcolumns = ChartInfo.functions.getSelectedColumnsForSharedReports();
            //  isViewOnly=ChartInfo.getisViewOnlyOptions();
            if (_.isEmpty(selectedcolumns)) {
                selectedcolumns = "";
            }
            else {
                if (that.state.multifact == "true") {
                    var showWarning = false;
                    _.each(selectedcolumns, function (cols, id) {
                        var factsincols = [];
                        _.each(cols, function (colobj, id) {
                            var tableName = colobj.columnName.split(".")[0];
                            if (that.props.facttables.indexOf(tableName) >= 0 && factsincols.indexOf(tableName) == -1) {
                                factsincols.push(tableName);
                            }
                        });
                        if (factsincols.length < 2) {
                            showWarning = true;
                            $('#showfacterror' + id).addClass("error");
                        }
                    });
                    if (showWarning) {
                        $(".spinner").hide();
                        $(".gridspinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Warning');
                        $('.birdmessage-info').empty().text(getMessage('BirdWarning142'));
                        return;
                    }
                }
                selectedcolumns = JSON.stringify(selectedcolumns)
            }
            var reqdata = new FormData();
            reqdata.set("modelId", that.state.modelId);
            reqdata.set("multifact", that.state.multifact);
            reqdata.set("comment", sharecomments);
            reqdata.set("userIds", choosenUserIds);
            reqdata.set("groupIds", choosenGroupIds);
            reqdata.set("selectedcolumns", selectedcolumns);
            reqdata.set("userfilterArray", JSON.stringify(userfilterArray));

            SendRequest({
                url: "shareDataModel",
                body: reqdata,
                sucessFunction: (response) => {
                    if (response.hasOwnProperty('error')) {
                        $(".spinner").hide();
                        $(".gridspinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.errorTrace)
                    } else {
                        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Success');
                        $('.birdmessage-info').empty().text(getMessage('BirdSuccess33'));
                        $("#sharestoryboard").modal("hide");
                        that.sharemodelclose();
                    }
                },
                rejectFunction: this.reportDataError,
                headers: requestHeaders

            });
        }
    }
}