import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import _ from 'underscore';
import SendRequest from '../../SendRequest';
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import * as SmartCharts from './smartinsightcharts'
import { lang } from "../../js/helpers/utils/languages/language";


const $ = window.$;
export default class SamrtInsightsSimulationView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.state.dynamicdiv = this.props.dynamicdiv;
        this.state.measures = this.props.measures;
        this.state.dimensions = this.props.dimensions;
        this.state.reportId = this.props.reportId;
        this.state.datamodelId = this.props.datamodelId;
        this.state.features = this.props.features;
        this.state.targetColumn = this.props.targetColumn;
        this.state.data = this.props.data;
        this.state.simulationData = this.state.data.simulationData;
        this.state.predictedValue = this.state.data.predictedvalue;
        this.state.reportId = "";
        this.state.tabid = this.props.tabid
        var fragmentArray = window.location.pathname.split("/");
        this.state.entities = fragmentArray[2];
        this.state.isFromStoryBook = this.props.isFromStoryBook == undefined ? false : this.props.isFromStoryBook;
        if (fragmentArray.length == 2) {
            if (fragmentArray[0] === "viewreport" || fragmentArray[0] === "sharereport" || fragmentArray[0] === "embeddedreport") {
                //   this.state.reportId = atob(fragmentArray[1]);
            }
            if (fragmentArray[0] === "customviewreport") {
                this.state.entities = sessionStorage.getItem("entities");
            }
        }
        // if (fragmentArray.length > 2 && !this.state.isFromStoryBook) {
        //     this.state.datamodelId = atob(fragmentArray[2]);
        // } else if (!this.state.isFromStoryBook) {
        //     this.state.datamodelId = atob(fragmentArray[1]);
        // }
        if (this.state.isFromStoryBook) {
            this.state.datamodelId = this.props.datamodelId;
            this.state.reportId = this.props.reportId;
        }
        this.state.keyinfluencersList = this.props.keyinfluencersList
        this.render();
    }
    render() {
        ReactDOM.render(<SmartInsightsSimulationTemplate dropdownitemselect={this.dropdownitemselect} sisimulationdropsearch={this.sisimulationdropsearch} updatesisimulationvalue={this.updatesisimulationvalue} simulationdata={this.state.simulationData} tabid={this.state.tabid} keyinfluencersList ={this.state.keyinfluencersList}/>, document.getElementById(this.state.dynamicdiv));
        $(".expectedvalue[tabid='" + this.state.tabid + "']").html(this.state.predictedValue);
        $('.expectedtitle').remove();
        $(".expectedvalue[tabid='" + this.state.tabid + "']").prepend('<h2 class="expectedtitle">Expected <span>'+this.state.targetColumn+'</span></h2>')
        $(".simulationcselections[tabid='" + this.state.tabid + "'] .mltooltip").removeClass('hide')
        $(".simulationcselections[tabid='" + this.state.tabid + "'] .mltooltip").attr("title", lang["smartsights.simulation-tooltip"]);
        return (<div></div>);
    }
    updatesisimulationvalue = (e) => {
        e.stopPropagation();
        var $this = e.currentTarget;
        var that = this;
        var smartInsightDataObj = ChartInfo.functions.getSmartinsightDataObj();
        $(".simulationselectioncontainer[tabid='" + that.state.tabid + "'] #spinnerchart10").show();
        var valThis = $($this).parent().find("input.sisimulationvalue").val();
        var targets = $($this).parent().parent().parent().find(".btn.btn-transparent.dropdown-toggle");
        if (valThis === "") {
            valThis = targets.parent().find(".minvalue").text();
            targets.parent().find(".sisimulationvalue").val(valThis);
        }
        targets.text(valThis);

        var simulationData = [];
        _.each($(".simulationitem[tabid='" + that.state.tabid + "']"), function (simulationItem) {
            var columnData = {};
            columnData.name = $(simulationItem).attr("columnName");
            columnData.displayname = $(simulationItem).text();
            columnData.value = $(simulationItem).next().text()
            simulationData.push(columnData);
        });
        var finalSimulationData = JSON.stringify(simulationData);
        var smartSimulationData = smartInsightDataObj["smarinsightData"]
        var parsedsmartSimulationData = JSON.parse(smartSimulationData)[6]
        var finalSimulationPredictData = JSON.stringify(parsedsmartSimulationData["simulationdata"]);
        var finalSimulationPredictDataFrame = JSON.stringify(parsedsmartSimulationData["simulationdataframe"]);
        let requestHeaders = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };

        var formdata = new FormData();
        formdata.set("simulationData", finalSimulationData);
        formdata.set("reportId", that.state.reportId);
        formdata.set("datamodelId", that.state.datamodelId);
        formdata.set("modelName", that.props.modelName);
        formdata.set("frameName", that.props.frameName);
        formdata.set("targetColumn", that.props.targetColumn);
        formdata.set("mapForGetDuplicateName", that.props.data.mapForGetDuplicateName != undefined ? JSON.stringify(that.props.data.mapForGetDuplicateName) : "");
        formdata.set("simultationPredictData", finalSimulationPredictData);
        formdata.set("simulationPredictionDataFrame",finalSimulationPredictDataFrame);
        let selectedColumnsval = ChartInfo.functions.getSmartinsigtSelectedColumns();
        formdata.set("selectedColumns",JSON.stringify(selectedColumnsval));
        let storyboardSmartInsightFilterArray = ChartInfo.functions.getStoryboardSmartInsightFilterArray();
        formdata.set("filter",JSON.stringify(storyboardSmartInsightFilterArray));
        formdata.set("customFields",sessionStorage.getItem("customFields"))

        SendRequest({
            url: "getsmartinsightspredicteddata",
            body: formdata,
            sucessFunction: (response) => {
                var renderto;
                $("#smartinsights0").show()
                let currentvalue = parseFloat($(".expectedvalue[tabid='" + that.state.tabid + "'] .expectednumber").text());
                // for negative values
                let currentexpectedsymbol = $(".expectedvalue[tabid='" + that.state.tabid + "'] .expectedsymbol").text();
                if(currentexpectedsymbol&&currentexpectedsymbol.trim()=="-"){
                    currentvalue = 0-currentvalue;
                }
                $(".expectedvalue[tabid='" + that.state.tabid + "']").html(response.predictedvalue);
                $('.expectedtitle').remove();
                $(".expectedvalue[tabid='" + that.state.tabid + "']").prepend('<h2 class="expectedtitle">Expected <span>'+that.state.targetColumn+'</span></h2>');
                let predictionvalue = parseFloat($(".expectedvalue[tabid='" + that.state.tabid + "'] .expectednumber").text());
                let predictionexpectedsymbol = $(".expectedvalue[tabid='" + that.state.tabid + "'] .expectedsymbol").text();
                if(predictionexpectedsymbol&&predictionexpectedsymbol.trim()=="-"){
                    predictionvalue = 0-predictionvalue;
                }
                let resultantvalue = ((predictionvalue*100)/currentvalue) - 100
                let tempresponsepredictedvalue = response.predictedvalue; 
                if(resultantvalue>0){
                    tempresponsepredictedvalue += "<span class=\"label-metric metric-increase\" data-toggle=\"tooltip\" data-placement=\"top\" title=\"\"><i class=\"fa fa-caret-up fa-solid\"></i>&nbsp;"+Math.abs((resultantvalue).toFixed(3))+"%</span>";
                }else if(resultantvalue<0){
                    tempresponsepredictedvalue += "<span class=\"label-metric metric-decrease\" data-toggle=\"tooltip\" data-placement=\"top\" title=\"\"><i class=\"fa fa-caret-down fa-solid\"></i>&nbsp;"+Math.abs((resultantvalue).toFixed(3))+"%</span>"
                }else if(resultantvalue==0){
                    tempresponsepredictedvalue += "<span class=\"label-metric metric-stable\" data-toggle=\"tooltip\" data-placement=\"top\" title=\"\"><i class=\"fa fa-sort fa-solid\"></i>&nbsp;"+Math.abs((resultantvalue).toFixed(3))+"%</span>";
                }
                $(".expectedvalue[tabid='" + that.state.tabid + "']").html(tempresponsepredictedvalue);
                $('.expectedtitle').remove();
                $(".expectedvalue[tabid='" + that.state.tabid + "']").prepend('<h2 class="expectedtitle">Expected <span>'+that.state.targetColumn+'</span></h2>')
                renderto = $(".simulationselectioncontainer[tabid='" + that.state.tabid + "'] .simulationwaterfall");
                SmartCharts.charts.drawSmartSimulationWaterfall(response.data, response.data, renderto, "", "", "");
                $(".simulationselectioncontainer[tabid='" + that.state.tabid + "'] #spinnerchart10").hide();
            },
            rejectFunction: this.reportDataError,
            headers: requestHeaders
        });
    }
    sisimulationdropsearch = (e) => {
        e.stopPropagation();
        var $this = e.currentTarget;
        var that = this;
        /*Delay function for to wait until user stops typing*/
        var delay = (function () {
            var timer = 0;
            return function (callback, ms) {
                clearTimeout(timer);
                timer = setTimeout(callback, ms);
            };
        })();
        /*Delay function for to wait until user stops typing*/
        $($this).keyup(function () {
            var valThis = $(this).val().toLowerCase();
            var targets = $(this).parent().parent().find(".itemname").parents(".dropdown-item");
            delay(function (e) {
                targets.each(function () {
                    var text = $(this).text().toLowerCase();
                    (text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
                });
            }, 400);
        });
    }
    dropdownitemselect = (e) => {
        e.preventDefault();
        var that = this;
        var $this = e.currentTarget;
        $(".simulationselectioncontainer[tabid='" + that.state.tabid + "'] #spinnerchart10").show();
        var smartInsightDataObj = ChartInfo.functions.getSmartinsightDataObj();
        var selecteditem = $($this).text();
        //$($this).parent().parent().parent().find(".btn").text(selecteditem);
        $($this).closest('.simulationbox').find('.btn').text(selecteditem);
        var simulationData = [];
        _.each($(".simulationitem[tabid='" + that.state.tabid + "']"), function (simulationItem) {
            var columnData = {};
            columnData.name = $(simulationItem).attr("columnName");
            columnData.displayname = $(simulationItem).text();
            columnData.value = $(simulationItem).next().text()
            simulationData.push(columnData);
        });
        var finalSimulationData = JSON.stringify(simulationData);
        var smartSimulationData = smartInsightDataObj["smarinsightData"]
        var parsedsmartSimulationData = JSON.parse(smartSimulationData)[6]
        var finalSimulationPredictData = JSON.stringify(parsedsmartSimulationData["simulationdata"]);
        var finalSimulationPredictDataFrame = JSON.stringify(parsedsmartSimulationData["simulationdataframe"]);
        let requestHeaders = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };

        var formdata = new FormData();
        formdata.set("simulationData", finalSimulationData);
        formdata.set("reportId", that.state.reportId);
        formdata.set("datamodelId", that.state.datamodelId);
        formdata.set("modelName", that.props.modelName);
        formdata.set("frameName", that.props.frameName);
        formdata.set("targetColumn", that.props.targetColumn);
        formdata.set("mapForGetDuplicateName", that.props.data.mapForGetDuplicateName != undefined ? JSON.stringify(that.props.data.mapForGetDuplicateName) : "");
        formdata.set("simultationPredictData", finalSimulationPredictData);
        formdata.set("simulationPredictionDataFrame",finalSimulationPredictDataFrame);
        let selectedColumnsval = ChartInfo.functions.getSmartinsigtSelectedColumns();
        formdata.set("selectedColumns",JSON.stringify(selectedColumnsval));
        let storyboardSmartInsightFilterArray = ChartInfo.functions.getStoryboardSmartInsightFilterArray();
        formdata.set("filter",JSON.stringify(storyboardSmartInsightFilterArray));
        formdata.set("customFields",sessionStorage.getItem("customFields"))
        
        SendRequest({
            url: "getsmartinsightspredicteddata",
            body: formdata,
            sucessFunction: (response) => {
                var renderto;
                $("#smartinsights0").show()
                let currentvalue = parseFloat($(".expectedvalue[tabid='" + that.state.tabid + "'] .expectednumber").text());
                // for negative values
                let currentexpectedsymbol = $(".expectedvalue[tabid='" + that.state.tabid + "'] .expectedsymbol").text();
                if(currentexpectedsymbol&&currentexpectedsymbol.trim()=="-"){
                    currentvalue = 0-currentvalue;
                }
                $(".expectedvalue[tabid='" + that.state.tabid + "']").html(response.predictedvalue);
                $('.expectedtitle').remove();
                $(".expectedvalue[tabid='" + that.state.tabid + "']").prepend('<h2 class="expectedtitle">Expected <span>'+that.state.targetColumn+'</span></h2>')
                let predictionvalue = parseFloat($(".expectedvalue[tabid='" + that.state.tabid + "'] .expectednumber").text());
                let predictionexpectedsymbol = $(".expectedvalue[tabid='" + that.state.tabid + "'] .expectedsymbol").text();
                if(predictionexpectedsymbol&&predictionexpectedsymbol.trim()=="-"){
                    predictionvalue = 0-predictionvalue;
                }
                let resultantvalue = predictionvalue-currentvalue
                let tempresponsepredictedvalue = response.predictedvalue; 
                if(resultantvalue>0){
                    tempresponsepredictedvalue += "<span class=\"label-metric metric-increase\" data-toggle=\"tooltip\" data-placement=\"top\" title=\"\"><i class=\"fa fa-caret-up fa-solid\"></i>&nbsp;"+Math.abs((resultantvalue/100).toFixed(3))+"%</span>";
                }else if(resultantvalue<0){
                    tempresponsepredictedvalue += "<span class=\"label-metric metric-decrease\" data-toggle=\"tooltip\" data-placement=\"top\" title=\"\"><i class=\"fa fa-caret-down fa-solid\"></i>&nbsp;"+Math.abs((resultantvalue/100).toFixed(3))+"%</span>"
                }else if(resultantvalue==0){
                    tempresponsepredictedvalue += "<span class=\"label-metric metric-stable\" data-toggle=\"tooltip\" data-placement=\"top\" title=\"\"><i class=\"fa fa-sort fa-solid\"></i>&nbsp;"+Math.abs((resultantvalue/100).toFixed(3))+"%</span>";
                }
                $(".expectedvalue[tabid='" + that.state.tabid + "']").html(tempresponsepredictedvalue);
                $('.expectedtitle').remove();
                $(".expectedvalue[tabid='" + that.state.tabid + "']").prepend('<h2 class="expectedtitle">Expected <span>'+that.state.targetColumn+'</span></h2>')
                renderto = $(".simulationselectioncontainer[tabid='" + that.state.tabid + "'] .simulationwaterfall");
                SmartCharts.charts.drawSmartSimulationWaterfall(response.data, response.data, renderto, "", "", "");
                $(".simulationselectioncontainer[tabid='" + that.state.tabid + "'] #spinnerchart10").hide();
            },
            rejectFunction: this.reportDataError,
            headers: requestHeaders

        });
    }


}

class SmartInsightsSimulationTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        var that = this.props;
        return (<>
            <h2 style={{ marginTop: "30px", marginBottom: "30px" }} className="simulationselection" tabid={that.tabid}>{lang["smartsights.simulation"]}<button className="btn btn-transparent mltooltip hide" style={{ color: '#0a98f3', fontSize: '20px',top:'30px' }} data-num={that.chartnumber} tab-id="0" tab-name="" title={lang["mlreports.tooltiploading"]} aria-describedby="ui-id-8"><i className="fa fa-lightbulb-o"></i></button></h2>
            {/* <div aria-label="..." role="group" className="btn-group pull-right dropdown" style={{ opacity: 1 }}>
                <button className="btn btn-transparent mltooltip hide" style={{ color: '#0a98f3', fontSize: '20px' }} data-num={that.chartnumber} tab-id="0" tab-name="" title={lang["mlreports.tooltiploading"]} aria-describedby="ui-id-8"><i className="fa fa-lightbulb-o"></i></button>
            </div> */}
            {that.simulationdata.map((i, index) => {
                return <div key={index} className="col-md-2 nopadding">
                    <div className="simulationbox">
                    {that.keyinfluencersList&&that.keyinfluencersList.indexOf(i.name)>=0?
                        <span className="col-xs-12 text-left nopadding simulationitem" title={i.name} columnName={i.columnName} tabid={that.tabid} style={{color:"#00bcd4"}}>{i.name}</span>
                        :<span className="col-xs-12 text-left nopadding simulationitem" title={i.name} columnName={i.columnName} tabid={that.tabid}>{i.name}</span>}
                        <button className="btn btn-transparent dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{i.data[0]}</button>
                        <ul className="dropdown-menu">
                            {(i.dataType === "string") ? (
                                <>
                                    <li className="xs-list-item"><input className="form-control search-image sisimulationdropsearch" onKeyUp={that.sisimulationdropsearch} placeholder={lang["smartsights.search"]} type="text" tabid={that.tabid} /></li>
                                    <span class="axis-list-items">
                                    {i.data.map((i1, index) => {
                                        return <li key={index} className="xs-list-item"><a className="dropdown-item" onClick={that.dropdownitemselect} href="javascript:void(0)" title={i1}><span className="itemname">{i1}</span></a></li>
                                    })}
                                    </span>
                                </>
                            ) : (i.dataType === "number") ? (
                                <>
                                    <li className="simulationinteger"><h6 className="pull-left">{lang["smartsights.Min"]}<span className="minvalue">{i.data[0]}</span></h6><h6 className="pull-right">{lang["smartsights.Max"]}<span className="maxvalue">{i.data[1]}</span></h6></li>
                                    <li className="simulationinteger"><input className="form-control sisimulationvalue" defaultValue={i.data[0]} type="number" /><button onClick={that.updatesisimulationvalue} className="btn btn-transparent pull-right updatesisimulationvalue"><i className="fa fa-refresh"></i> {lang["smartsights.updatebtn"]}</button></li>
                                </>) : null}
                        </ul>
                    </div>
                </div>
            })}
        </>);
    }
}