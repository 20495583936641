import React, { Component } from "react";
import ReactDOM from 'react-dom';
import SendRequest from '../../SendRequest';
import AuditSettingsTemplate from './auditSettingsTemplate';
import { lang } from "../../js/helpers/utils/languages/language";
const $ = window.$;

export default class AuditingTemplate extends Component {
	constructor(props) {
		super(props);
	}
	auditSettingsPopup = (e) => {
		e.preventDefault();
		var $this = e.currentTarget;
		var chartnumber = $($this).attr("data-num");
		let requestHeaders = {
			method: "get",
			headers: new Headers({
				"Content-Type": "application/json"
			})
		};
		SendRequest({
			url: "getAuditconfig",
			queryString: "",
			sucessFunction: (response) => {
				if (response.hasOwnProperty('error')) {
					$(".spinner").hide();
				} else {
					if (document.querySelector(".append_alertmodal") != null) {
						ReactDOM.unmountComponentAtNode(document.querySelector(".append_alertmodal"))
					}
					ReactDOM.render(<AuditSettingsTemplate data_age_number={response[0].data_age_number} data_age_duration={response[0].data_age_duration} />, document.querySelector(".append_alertmodal"))
					$(".alertsmodal" + chartnumber).show();
					//ModalProperties.resize();
					if (response[0].audit_enabled === "1") {
						$('#btnReadonlyReport').attr('checked', true);
					}
				}
			},
			rejectFunction: () => { },
			headers: requestHeaders

		});
	}
	render() {
		var that = this.props;
		var showClientlist = that.showClientlist;
		var clientsList = that.clientsList.map((list, index) =>
			<option value={list.client_id}>{list.client_name}</option>
		);
		if (showClientlist) {
			var clientSelect = <div class="pull-left marg-righ-10">
				<select class="form-control clientselect " onChange={that.clientSelect}>
					{clientsList}
				</select>
			</div>
		}

		return (
			<div className="container-fluid auditingcontainer">
				<div className="row">
					<h1 className="boc-main-title">
						<span className="fa-stack" aria-hidden="true">
							<i className="fa fa-desktop fa-stack-2x"></i>
							<i className="fa fa-search fa-stack-1x "></i>
						</span>  {lang["admin.audit.title"]}
        </h1>
					<div className="container-fluid">
						<div className="row in-back">
							<div className="row-same-height-inner">
								<div className="col-xs-12 col-xs-height in-panes">
									<div className="col-xs-12 nopadding form-group-sm">
										{clientSelect}
										<div className="pull-left">
											<select className="form-control auditselect" id="adminauditselecting" onChange={that.auditSelect}>
												<option value="account" selected>{lang["admin.audit.account"]}</option>
												<option value="users" >{lang["admin.audit.users"]}</option>
												<option value="mail">{lang["admin.audit.mail"]}</option>
												<option value="ldap">{lang["admin.audit.ldap"]}</option>
												<option value="reports">{lang["admin.audit.reports"]}</option>
												<option value="login">{lang["admin.audit.login"]}</option>
												<option value="connections">{lang["admin.audit.conection"]}</option>
												<option value="role">{lang["admin.audit.role"]}</option>
												<option value="alerts">{lang["admin.audit.alerts"]}</option>
												<option value="datahub">{lang["admin.audit.datahub"]}</option>
												<option value="workspace">{lang["admin.audit.workspace"]}</option>
												<option value="datamodel">{lang["admin.audit.datamodel"]}</option>
												<option value="governance">{lang["admin.audit.goverance"]}</option>
												<option value="customstoryboard">{lang["admin.audit.customstoryboard"]}</option>												
											</select>
										</div>
										<div className="pull-right">
											<button type="button" className="btn btn-bird btn-sm marg-righ-5 auditsettingpopup" id="auditsettingsbutton" title={lang["admin.audit.settings"]} data-num=" " onClick={this.auditSettingsPopup}><i className="fa fa-cogs marg-righ-5"></i> {lang["admin.audit.settings"]}</button>
											<button type="button" className="btn btn-bird btn-sm refreshaudit" id="auditrefreshbutton" title={lang["admin.audit.refresh"]} onClick={that.refreshTable}><i className="fa fa-refresh marg-righ-5"></i>{lang["admin.audit.refresh"]}</button>
										</div>

									</div>
									<div className="col-xs-4 col-xs-height in-panes leftarea">
										<div class="pull-left search marg-top-10 marg-left-10">
											<input class="form-control searchaudittable" type="text" onKeyUp={that.searchkeyup} placeholder={lang["admin.audit.search"]} value={that.search} />
										</div>
										 <div className="panel panel-default panel-bird auditpanels usersgroupsaudit nomargin hide">
										</div> 
									</div>
								</div>
							</div>
						</div>
						<div className="append_alertmodal append_alertmodal"></div>
					</div>
				</div>
			</div>

		);
	}
}