import React, { Component } from "react";
import _ from 'lodash';
import $ from 'jquery';
import * as Highcharts from 'highcharts/highstock';
import SendRequest from '../../SendRequest';
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import HighchartsReact from 'highcharts-react-official'
import PubSub from 'pubsub-js'
import ReactDOM from "react-dom";
import BootStrapTableHeaderTemplate from './summaryViewHeaderTemplate.jsx';

class SummaryView extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    componentWillUnmount() {
    }
    drawBootstrapTable(currentChartNumber, jsonData, tableInfo, pos, popupwindow, reporttype, $this, isFromPagenation) {

        var that = this.state;
        var popupwindow = that.popupwindow;
        var currentChartNumber = that.currentChartNumber;
        var len = jsonData.length - 1;
        var totalRecords = jsonData[len].datacount;
        var headerlist = jsonData[len].tablecolumns;
        var headerDataTypeslist = jsonData[len].tableColumnDataTypes;
        var data;
        var showTitle;
        var tempDataArray = [];
        _.each(jsonData, function (dataObject, i) {
            if (dataObject["data"] != undefined) {
                tempDataArray.push(dataObject["data"]);
            }
        });
        data = tempDataArray;
        var columns = [];
        _.each(headerlist, function (col, i) {
            var temp = {};
            temp["dataField"] = col;
            temp["text"] = col;
            temp["sort"] = true;
            if(i === 0 ){
                temp["footer"] = "Total";
            }else{
                if(temp.dataField.indexOf("[")>=0){
                    var totalofdata=columnData => columnData.reduce((acc, item) => (+acc + +item).toFixed(2), 0);
                    temp["footer"] = totalofdata
                }else{
                    temp["footer"]=""
                }
            }
            temp["align"] =  headerDataTypeslist[i]=='number'?'right':'left'
            temp["headerClasses"] = headerDataTypeslist[i]=='number'?'align-right':''
            columns.push(temp);
        });
        showTitle = tableInfo != undefined ? tableInfo.showTitle : false;
        if (popupwindow === "popupview") {
            $(".pivotdataview .ball-pulse").hide();
        }
        if (!isFromPagenation) {
            $("#chart" + currentChartNumber).empty();
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $("#chart" + currentChartNumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            ReactDOM.render(<BootStrapTableHeaderTemplate columns={columns} data={data} chartnumber={currentChartNumber} totalRecords={totalRecords}></BootStrapTableHeaderTemplate>, document.getElementById(dynamicdiv));
            if (jsonData[len] != undefined && jsonData[len].filterCount != undefined) {
                $(".filterdatacount").text("Filtered Count: " + jsonData[len].filterCount);
                $(".filterDatacount").text(jsonData[len].filterCount);
                //  ChartInfo.functions.setFilterDataCount(jsonData[len].filterCount);
                $('.filterdatacountrow').removeClass("hide");
            } else {
                $(".filterdatacount").text(" ");
                //  ChartInfo.functions.setFilterDataCount(0);
                $('.filterdatacountrow').addClass("hide");
            }
            if (tableInfo != undefined && tableInfo.showTitle) {
                $(".boc-viewreport-title").removeClass("hide");
                $(".boc-viewreport-title").text(tableInfo.title).attr("title", tableInfo.title);
            } else {
                //$(".boc-viewreport-title").addClass("hide");
            }
            $(".chartadvancedoptions[data-num='chart" + currentChartNumber + "']").hide();
        }
        if (tableInfo != undefined && tableInfo.chartType === "pivot") {
            $(".pivot_filtered_datasetcount").text(totalRecords);
        }
        if (popupwindow != "popupview") {
            $(".predictmodelsummary" + currentChartNumber).addClass("hide")
            sessionStorage.removeItem("datatable_popup_filterdata");
            $('.richtextcontrolschart' + currentChartNumber).hide();
            $(".pivotnavigationbuttons" + currentChartNumber).hide();
            $(".pivotcharticon" + currentChartNumber).hide();
            $('.singlereport_summarymodal[data-num=' + currentChartNumber + ']').addClass('hide');
            $(".summarytablemessage").remove();

        }
        if (tableInfo != undefined && tableInfo.chartType === "doby") {
            $(".showtrending").hide();
        }
        $(".btablerowlimit li").removeClass('active');
        _.each($(".btablerowlimit li"), function (value) {
            if (that.rowLimit == value.textContent) {
                $(value).addClass("active")
            }
        });
        $($this).addClass('active');

        //Maximised stoary Board should have return button
        if (sessionStorage.getItem("customStoryBoardReportId") != null && sessionStorage.getItem("customStoryBoardReportId") != "null") {
            if ($('.redirectcustomstoryboard').length == 0) {
                $('.reporttitletoolbar' + currentChartNumber + ' .pull-right.bgcommon').prepend('<button class="btn btn-bird-group redirectcustomstoryboard" title="Back to Custom Storyboard"><i class="fa fa-share fa-flip-horizontal" aria-hidden="true"></i></button>')
            }

        }
        if (popupwindow === "popupview") {
            that.reporttype = "pop" + that.reporttype;
        }
        $(".btablerowlimit[data-num=" + currentChartNumber + "]").addClass(that.reporttype);
        $("th").addClass(that.reporttype + "sortby");
        if (tableInfo != undefined && tableInfo.paginationdiv === false) {
            $('.paginationdiv' + currentChartNumber).hide();
            $(".sinbleviewchartdata").show();
            $(".sinbleviewchartfromchartdata").show();
        }
        $(".page-size" + currentChartNumber).html(that.rowLimit);
        if (popupwindow != "popupview") {
            $(window).on("resize", function () {
                clearTimeout(window.resizedFinished);
                window.resizedFinished = setTimeout(function () {
                }, 1000);
            });
            $("#spinnerchart" + currentChartNumber).hide();
        } else {
            $(window).on("resize", function () {
                clearTimeout(window.resizedFinished);
                window.resizedFinished = setTimeout(function () {
                }, 1000);
            });
            $("#spinnerchart" + currentChartNumber).hide();
        }
    }
    resizeTilesBootstrapTable(chartnumber, table) {
        var $table = table;
        var baseHeight = $("#chart" + chartnumber).height(),
            headerHeight = $("#chart" + chartnumber + " .notifications-table-container:nth(0) table>thead").height(),
            dataHeight = $("#chart" + chartnumber + " .notifications-table-container:nth(0) table>tbody").height(),
            paginationHeight = $("#chart" + chartnumber + " .notifications-table-container:nth(1)").height(),
            breadcrumbheight = $(".breadcrumb[data-num='" + chartnumber + "']").outerHeight();
        var chartHeight = baseHeight - (headerHeight + paginationHeight + 0) + 22 - breadcrumbheight;
        if (dataHeight != null && chartHeight >= dataHeight) { chartHeight = chartHeight - (chartHeight - dataHeight) + 33 };
        //   $table.bootstrapTable('resetView' ,{height:chartHeight});
    }
    resizePopupBootstrapTable(chartnumber, table) {
        var $table = table;
        var baseHeight = $(".pivotdataview[data-number='" + chartnumber + "']").outerHeight(),
            headerHeight = $(".pivotdataview[data-number='" + chartnumber + "'] .summarydatacountcontainer").outerHeight(),
            dataHeight = $(".pivotdataview[data-number='" + chartnumber + "'] .drawbootstraptable" + chartnumber + " .notifications-table-container:nth(0) table>tbody").height(),
            paginationHeight = $("#chart" + chartnumber + " .notifications-table-container:nth(1)").height();
        var chartHeight = baseHeight - (headerHeight + 120);
        if (dataHeight != null && chartHeight >= dataHeight) { chartHeight = dataHeight + 41 };
        //    $table.bootstrapTable('resetView' ,{height:chartHeight});
    }
    render() {
        var that = this.state;
        that.currentChartNumber = this.props.currentChartNumber;
        that.response = this.props.response;
        this.drawBootstrapTable(that.currentChartNumber, that.response);
        return (
            <div></div>
        );
    }


    componentDidMount() {
        //this.modifyChartHeightOnResize();
    }

};
export default SummaryView;
