import React, { Component } from "react";
import ReactDOM from 'react-dom'
import AuditingTemplate from './auditing';
import moment from 'moment';
import SendRequest from '../../SendRequest';
import AuditingHeaderTemplate from './auditingHeaderTemplate';
import { lang } from "../../js/helpers/utils/languages/language";
const $ = window.$;
export default class AuditingView extends Component{
    constructor(props){
        super(props);
        this.state = {
            response: null,
            showClientlist:false
        }
        
    }
    componentDidMount(){
        this.getAccounts();
        this.showClientlist();
        var path = '<li><a class="navDashboard" href="#"><i class="fa  fa-home"></i>'+lang["admin.header.dashtitle"]+'</a></li><li><a href="#admin"><i class="fa fa-user-secret"></i> ' + lang["admin.header.admintitle"] + '</a></li><li><a><span class="fa-stack" aria-hidden="true"><i class="fa fa-desktop fa-stack-2x"></i><i class="fa fa-search fa-stack-1x"></i></span> ' + lang["admin.audit.title"] + '</a></li>';
		$(".navbar.navbar-bird ul.nav-path").html(path);
    }
    getAccounts(){
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        setTimeout(() => {
		SendRequest({
            url: "getallaccounts",
            queryString: "",
            sucessFunction: (response) => { 
                this.setState({
                    response: response
                });
                // var showClientlist = this.showClientlist();
                // this.render(response,showClientlist)
            },
            rejectFunction: () => { },
            headers: requestHeaders
        
        }); 
    },1000);
    }
    showClientlist(){
        var showClientlist = false;
        var leftNavigationOptions;
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        setTimeout(() => {
        SendRequest({
            url: "leftNavigationOptionsForAdmin",
            queryString: "",
            sucessFunction: (accountsinfo) => {
                leftNavigationOptions = accountsinfo;
                showClientlist = $.grep(leftNavigationOptions, function(e){return e.id == "Accounts"}).length >0;
                this.setState({                    
                    showClientlist : showClientlist
                });
            },
            rejectFunction: () => { },
            headers: requestHeaders,
        });
    },1000);
        
    }
    auditSelectChange=(e)=>{
        var that =this;
			that.searchValue = null;
			$("#pageDropDown").text(10);
			this.auditselect(e, undefined, that.startDate, that.endDate, undefined, 10,undefined,undefined);
         }
    refreshTable=(e)=>{
        $("#pageDropDown").text(10);
		this.searchValue = null;
		this.auditselect(e, undefined, this.startDate, this.endDate, undefined, undefined, undefined, undefined);
        
    }
    clientSelect=(e)=>{
        var pageDisplay = $("#pageDropDown").text();
        $(".auditselect option").eq(0).prop('selected', true);
        this.auditselect(e, undefined, undefined, undefined, undefined, pageDisplay, undefined, undefined);
    }
    auditselect(e,page,startDate,endDate,search,pageDisplay,order,colName){
        var index=0;
        var noOfRecords = $("#pageDropDown").text() == "" ? 10: $("#pageDropDown").text();
        var totalRecords=0;
        if(startDate == undefined){
            startDate  = moment().subtract(29, 'days');
        }
        if(endDate == undefined){
            endDate = moment();
        }
        if(page != undefined && page >1)
        {
            index =(page-1)*noOfRecords;
        }
        if(order == undefined){
            order = 'asc';
        }
        if(colName == undefined){
            colName = '';
        }
        if(search == undefined){
            search = '';
        }
        this.dataFieldValue = colName;
        this.sortingOrder = order;
        this.startDate = startDate;
        this.endDate = endDate;
        this.page = page;
        var auditType = $('.auditselect').val();
        if(auditType == undefined){
            auditType = 'account';
        }
        var clientId = $(".clientselect option:selected").val();
        if(clientId == undefined){
            clientId = '';
        }
        var that = this;
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
           SendRequest({
            url: "getAudits",
            queryString: "?auditType=" + auditType + "&fromDate=" + startDate.format() + "&toDate=" + endDate.format() + "&index= " + index + "&noOfRecords=" + noOfRecords + "&searchCriteria=" + search + "&order=" + order + "&colName=" + colName + "&clientId=" + clientId,
            sucessFunction: (response) => {
                if (response.hasOwnProperty('error')) {
                    $(".spinner").hide();
                } else {
                  var headerlist = response["header"];
                  var data = response["data"];
                totalRecords = response["totalRecords"];
                var keys = Object.keys(headerlist);
                var dataTypeslist = response["dataTypes"];
                var totalPages;
                if(pageDisplay == undefined){
                  pageDisplay = 10;
                }
                if( totalRecords > 10 ) {
                  totalPages =Math.ceil(totalRecords/pageDisplay);
                  }else {
                  totalPages=1;
                  }
                   
                    ReactDOM.unmountComponentAtNode(document.querySelector(".auditpanels"));
                    ReactDOM.render(<AuditingHeaderTemplate keys={keys} search={search} clientId={clientId} auditType={auditType} startDate = {startDate.format()} endDate = {endDate.format()} totalRecords={totalRecords} sizePerPage={pageDisplay} headerlist={headerlist} data={data} dataTypeslist={dataTypeslist} />, document.querySelector(".auditpanels"));
                    $('#daterange-filter:nth-child(1n) span').html(startDate.format('MMMM D, YYYY') + ' - ' + endDate.format('MMMM D, YYYY'));
						$("#daterange-filter").daterangepicker({
					        format: 'MM/DD/YYYY',
					        startDate: startDate,
					        endDate: endDate,
					        minDate: '01/01/1970',
					        maxDate: '',
					        showDropdowns: true,
					        showWeekNumbers: true,
					        timePicker: false,
					        timePickerIncrement: 1,
					        timePicker12Hour: true,
					        linkedCalendars: false,
					        ranges: {
					           'Today': [moment(), moment()],
					           'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
					           'Last 7 Days': [moment().subtract(6, 'days'), moment()],
					           'Last 30 Days': [moment().subtract(29, 'days'), moment()],
					           'This Month': [moment().startOf('month'), moment().endOf('month')],
					           'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
					        },
					        opens: 'right',
					        drops: 'down',
					        buttonClasses: ['btn'],
					        applyClass: 'btn-bird',
					        cancelClass: 'btn-bird',
					        separator: ' to ',
					        locale: {
					            applyLabel: lang["admin.audit.submitbtn"],
					            cancelLabel: lang["admin.audit.canclbtn"],
					            fromLabel: 'From',
					            toLabel: 'To',
					            customRangeLabel: 'Custom',
					            daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr','Sa'],
					            monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
					            firstDay: 1,
							}
					    }, function(start, end, label) {
					        $('#daterange-filter:nth-child(1n) span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
					        var pageDisplay = $("#pageDropDown").text();
					        that.auditselect(e, undefined,start,end,that.searchValue,pageDisplay,that.sortingOrder,that.dataFieldValue);
					    });
						$('.daterangepicker.dropdown-menu').addClass("daterangefixright");
                $(".spinner").hide();
        
                }
            },
            rejectFunction: () => { },
            headers: requestHeaders
        
        });
    }
    search=(e)=>{
        //console.log("in search block");
        var that=this;
        var delay = (function(){
         var timer = 0;
         return function(callback, ms){
         clearTimeout (timer);
         timer = setTimeout(callback, ms);
         };
       })();
        var search = $(e.currentTarget).val().toLowerCase();
                     var pageDisplaySearch = $("#pageDropDown").text();
                     delay(function(){
                         that.searchValue = $(".searchaudittable").val();
                         $(".searchaudittable").text(that.searchValue);
                         that.auditselect(e,undefined,that.startDate,that.endDate,that.searchValue,pageDisplaySearch,that.sortingOrder,that.dataFieldValue);
                     }, 100 );
             
                 $(".searchaudittable").focus();
      }
    render(){
        $('a.boc-maxscreen-left-item.auditing').addClass('showactive')
        $('.boc-admin-main .maxscreen-lft-filter-items a').not('.auditing').removeClass('showactive')
        if(this.state.response !== null){
        this.auditSelectChange();

        }
        // if(response==undefined){
        //     response=[];
        //     showClientlist=false;
        // }
        return(
            this.state.response === null ? null : <AuditingTemplate clientsList={this.state.response} 
            showClientlist={this.state.showClientlist} clientSelect = {this.clientSelect} auditSelect = {this.auditSelectChange} searchkeyup={this.search} refreshTable = {this.refreshTable}/>
        );
    }
}