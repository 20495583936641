import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'underscore';
import { lang } from "../../js/helpers/utils/languages/language";
export default class PasswordsResetTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            entityMetaDataCollection: [],
        }
    }
    render(){
        return(
            <div class="notifications-table-container">
	<table id="passwordsresetusersdata" data-toggle="table"
		data-pagination="true" data-page-size="5"
		data-id-field="userloginname" data-detail-formatter="detailFormatter"
		data-smart-display="true" data-search="false"
		data-row-attributes="rowAttributes" data-pagination-v-align="bottom"
		data-search-align="right">
		<thead>
			<tr>
				<th data-checkbox="true"></th>
				<th data-sortable="true" data-content="username" data-field="username">{lang["admin.users.rsetuser"]}</th>
				<th data-sortable="true" data-selected="userloginname"	data-field="userloginname">{lang["admin.users.rsetlgin"]}</th>
				<th data-sortable="true" data-content="groupname" data-field="groupname">Group</th>
				<th data-sortable="true" data-content="role" data-field="role">{lang["admin.users.role"]}</th>
			</tr>
		</thead>
	</table>
	<div class="col-sm-7" class="text-center">
		<button type="button"	class="btn btn-bird pull-right passwordsresetbyadmin" onClick={this.props.resetpasswords}	id="passwordsresetbyadmin">{lang["admin.users.rsetbtn"]}</button>
	</div>
</div>
        )
    }
}
