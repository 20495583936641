import React from "react";
import ReactDOM from 'react-dom'
import _ from 'lodash';
import jstz from 'jstz';
import SendRequest from '../SendRequest';
import { getMessage } from '../js/helpers/utils/BirdMessage';
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import * as ChartUtil from "../js/helpers/utils/chartutil";
import LiveReportTemplate from './LiveReportsModal.jsx';
import PubSub from 'pubsub-js'
const $ = window.$;

export default class LiveReportsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reportId: props.reportId,
            datamodelId: props.datamodelId,
            worker: props.worker,
            features: props.features,
            display:props.display,
            isStoryboard: props.isStoryboard,
        };
        this.startliveupdateEvee = PubSub.subscribe('startliveupdate', this.startliveupdateEve.bind(this));
        this.state.worker.addEventListener('message', this.webworkerdata, false);
        this.state.isDirect = ChartInfo.functions.getIsDirect();
        this.state.isImportedReport = false;
        if (sessionStorage.getItem("isImportedReport")|| window.location.pathname.indexOf("importreport") >= 0) {
            this.state.isImportedReport = true;
        }
        this.render();
        var livedatasyncconfig = ChartInfo.functions.getliveDataconfig();
        var configObj = livedatasyncconfig[this.props.chartnumber];
        if (configObj != undefined && (configObj["syncType"] == "realtimedata" || configObj["syncType"] == "refreshonupdate")) {
            $(".realtimedatatimetype option[value=" + configObj["intervalType"] + "]").prop("selected", true);
            $(".reltimedatasynctime").val(configObj['intervalTime']);
            $(".realtimedatatoggle[syncType=" + configObj["syncType"] + "]").prop("checked", true);
        }
        if (configObj != undefined && configObj["syncType"] == "refreshonupdate") {
            $(".message-streaming").removeClass("hide");
        }
    }
    render() {
        ReactDOM.render(<LiveReportTemplate display={this.state.display} isStoryboard={this.state.isStoryboard}  close={this.close} applylivedatasetting={this.applylivedatasetting} showinformationmessages={this.showinformationmessages} chartnumber={this.props.chartnumber} streamingnote={getMessage("BirdInformation37")} isDirect={this.state.isDirect} />, document.querySelector('.livereportmodaldisplay'));
        return (<div></div>);
    }
    close = () => {
        ReactDOM.unmountComponentAtNode(document.querySelector('.livereportmodaldisplay'));
    }
    webworkerdata = (e, chartnumber) => {
        if (e.data["isChart"]) {
            ChartUtil.addpointforliveData(e.data["response"], e.data["chartnumber"], e.data.requestData.reportId);
        }
        if (e.data["isDataCount"]) {
            if (e.data["lastexecutedtimestamp"] != undefined && e.data["lastexecutedtimestamp"].length > 0) {
                $(".lastexecutedtimestamp").text("Last Data Sync: " + e.data["lastexecutedtimestamp"]);
            }
        }
    }
    showinformationmessages = (e) => {
        var $this = e.currentTarget;
        var syncType = $($this).attr('synctype');
        if (syncType == "refreshonupdate") {
            $(".message-streaming").removeClass("hide");
        } else {
            $(".message-streaming").addClass("hide");
        }
    }
    applylivedatasetting = (e) => {
        $(".itslive").addClass("hide");
        $('.reltimedatasynctime-error').addClass("hide");
        var chartnumber = $(e.currentTarget).attr("data-num");
        var that = this;
        var obj = {};
        var syncConfig = {};
        var WebSocketExistsWithReportId = ChartInfo.functions.getWebSocketForReportID(this.state.reportId);
        syncConfig["syncType"] = $(".realtimedatatoggle:checked").attr('synctype');
        if (syncConfig["syncType"] == "none") {
            clearInterval(ChartInfo.functions.getIntervalValue(chartnumber));
            ChartInfo.functions.setIntervalValue(chartnumber, undefined);
            var listOfChartNumbers = ChartInfo.functions.getwebsocketwithchartnumber(this.state.reportId);
            if (listOfChartNumbers != undefined) {
                listOfChartNumbers = _.without(listOfChartNumbers, chartnumber);
                if (listOfChartNumbers.length == 0) {
                    ChartInfo.functions.setwebsocketwithchartnumber(this.state.reportId, null);
                    WebSocketExistsWithReportId.close();
                    ChartInfo.functions.setWebSocketForReportID(this.state.reportId, undefined);
                } else {
                    $(".itslive").removeClass("hide");
                    ChartInfo.functions.setwebsocketwithchartnumber(this.state.reportId, null);
                    var that = this;
                    _.each(listOfChartNumbers, function (i) {
                        ChartInfo.functions.setwebsocketwithchartnumber(that.state.reportId, i);
                    });
                }
            }
        }
        if ($(".realtimedatatoggle:checked").attr('synctype') == 'realtimedata' && this.state.reportId != undefined) {
            clearInterval(ChartInfo.functions.getIntervalValue(chartnumber));
            ChartInfo.functions.setIntervalValue(chartnumber, undefined);
            var listOfChartNumbers = ChartInfo.functions.getwebsocketwithchartnumber(this.state.reportId);
            if (listOfChartNumbers != undefined) {
                listOfChartNumbers = _.without(listOfChartNumbers, chartnumber);
                if (listOfChartNumbers.length == 0) {
                    ChartInfo.functions.setwebsocketwithchartnumber(this.state.reportId, null);
                    WebSocketExistsWithReportId.close();
                    ChartInfo.functions.setWebSocketForReportID(this.state.reportId, undefined);
                }
            }

            var time = $(".reltimedatasynctime").val();
            var timeunits = $(".realtimedatatimetype option:checked").val();
            var millis;
            if (timeunits == 'hours') {
                millis = parseInt(time) * 60 * 60 * 1000;
            } else if (timeunits == 'minutes') {
                millis = parseInt(time) * 60 * 1000;
            }
            else {
                millis = parseInt(time) * 1000;
            }
            syncConfig["intervalType"] = timeunits;
            syncConfig["intervalTimeinMilliseconds"] = millis;
            syncConfig["intervalTime"] = time;
            if (time < 5 && timeunits == "seconds") {
                var errorMesssage = getMessage('ValidationMessage5').replace('@replace', 5) + " " + timeunits;
                $('.reltimedatasynctime-error').removeClass("hide").text(errorMesssage);
                $(".itslive").removeClass("hide");
                return false;
            }
            else if (time < 5 && timeunits == "hours") {
                var errorMesssage = getMessage('ValidationMessage5').replace('@replace', 5) + " " + timeunits;
                $('.reltimedatasynctime-error').removeClass("hide").text(errorMesssage);
                $(".itslive").removeClass("hide");
                return false;
            }
            else if (time < 5 && timeunits == "minutes") {
                var errorMesssage = getMessage('ValidationMessage5').replace('@replace', 5) + " " + timeunits;
                $('.reltimedatasynctime-error').removeClass("hide").text(errorMesssage);
                $(".itslive").removeClass("hide");
                return false;
            }
        } else {
            if ($(".realtimedatatoggle:checked").attr('synctype') == 'refreshonupdate' && this.state.reportId != undefined) {
                clearInterval(ChartInfo.functions.getIntervalValue(chartnumber));
                ChartInfo.functions.setIntervalValue(chartnumber, undefined);

                this.createWebSocket(this.state.reportId, chartnumber);
            }
        }

        ChartInfo.functions.setliveDataconfig(chartnumber, syncConfig);
        if (syncConfig["syncType"] != undefined && syncConfig["syncType"] != "none" && syncConfig["syncType"] != "refreshonupdate") {
            var that = this;
            if (chartnumber == -1) {
                var AllChartDetails = ChartInfo.functions.getAllChartDetails();
                var intervalVarable = setInterval(() => {
                    _.each(AllChartDetails, function (value, key) {
                        if (ChartInfo.functions.getIntervalValue(key) == undefined) {
                            var data = that.createReportModel(key, that.state.reportId);
                            that.state.worker.postMessage(data);
                        }
                    });
                }, millis);
            } else {
                var intervalVarable = setInterval(() => {
                    var data = that.createReportModel(chartnumber, that.state.reportId);
                    that.state.worker.postMessage(data);
                }, millis);
            }
            ChartInfo.functions.setIntervalValue(chartnumber, intervalVarable);
            $(".itslive").removeClass("hide");
        }
       this.close();
       $('.modal-backdrop').remove();
    }
    createWebSocket(reportId, chartnumber) {
        var WebSocketExistsWithReportId = ChartInfo.functions.getWebSocketForReportID(reportId);
        var url = window.location.host;
        var protocol = window.location.protocol === "https:" ? "https" : "http";
        var websockehost = "ws";
        if (protocol.toLowerCase() == "https") {
            websockehost = "wss";
        }
        var finalURL = websockehost + "://" + url + "/api/refreshonupdate?api_key=" + window.localStorage.getItem("accessToken") + "&reportId=" + reportId;
        if (WebSocketExistsWithReportId == undefined) {
            var wbsocket = new WebSocket(finalURL);
            wbsocket.addEventListener('open', this.onOpen, false);
            wbsocket.addEventListener("message", this.onMessage, false);
            wbsocket.addEventListener('close', this.onClose, false);
            ChartInfo.functions.setWebSocketForReportID(reportId, wbsocket);
            ChartInfo.functions.setwebsocketwithchartnumber(reportId, chartnumber + "");
        } else {
            ChartInfo.functions.setwebsocketwithchartnumber(reportId, chartnumber + "");
        }
        this.getDataCount();
        $(".itslive").removeClass("hide");
    }
    onOpen() {
    }
    onMessage = (evt) => {
        var data = JSON.parse(evt.data);
        ChartUtil.addpointOnStreaming(data.data, data.reportId);
    }
    onClose(event) {
        console.log("closed");
    }
    getDataCount() {
        var that = this;
        if (this.props.multifact == "false") {
            let requestHeaders = {
                method: "get",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            SendRequest({
                url: "gettotaldatacount",
                queryString: "?reportId=" + that.state.reportId + " &datamodelId=" + that.props.datamodelId + " &timeZone=" + "" + "&isPurgedReport=" + false + "&isImportedReport=" + false,
                sucessFunction: response => {
                    if (response.hasOwnProperty('error')) {
                        $(".spinner").hide();
                        $(".gridspinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.errorTrace)
                    }
                    that.state.datacount = response.size;
                    ChartInfo.functions.setDataSetCount(response.size);
                    $(".publishinfo").text("Dataset Count: " + response.size);
                },
                rejectFunction: () => { },
                headers: requestHeaders
            });
        }
    }
    createReportModel(chartnumber, reportId) {
        var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(chartnumber));
        var isExcludeNulls = ChartInfo.functions.getIsExcludeNulls() == undefined ? "" : ChartInfo.functions.getIsExcludeNulls();
        var isExcludeorIncludeFilters = isExcludeNulls == undefined ? "" : "yes";
        var xAxisData = "";
        var yAxisData = "";
        var yAxisDisplayNameData = "";
        var zAxisData = [];
        var combinationchartsData = [];
        var customSolidGaugeData = [];
        var toolTipData = [];
        var xAxisDataType = "";
        var yAxisDataType = "";
        var rangefieldMap = {};
        var xaxisAggregation = "";
        var sortingColumn = chartDetails["sortingColumn"] == undefined ? "" : chartDetails["sortingColumn"];
        var sortingColumnAggregation = chartDetails["sortingColumnAggregation"] == undefined ? "" : chartDetails["sortingColumnAggregation"];
        var rankingColumn = chartDetails["rankingColumn"] == undefined ? "" : chartDetails["rankingColumn"];
        var orderType = chartDetails["orderType"] == undefined ? "" : chartDetails["orderType"];
        var colLimit = chartDetails["colLimit"];
        var rankingColumnAggregation = chartDetails["rankingColumnAggregation"] == undefined ? "" : chartDetails["rankingColumnAggregation"];
        var aggregation = "";
        var percentileValue = "";
        var sortType = chartDetails["sortType"] == undefined ? "" : chartDetails["sortType"];
        var metricdatefield = chartDetails["metricdatefield"];
        var metricprefix = chartDetails["metricprefix"];
        var metricsuffix = chartDetails["metricsuffix"];
        var metricfont = chartDetails["metricfont"];
        var solidgaugemin = chartDetails["solidgaugemin"];
        var solidgaugemax = chartDetails["solidgaugemax"];
        var metricdatetype = chartDetails["metricdatetype"];
        var referenceLine = chartDetails["reference"];
        var convertedValues = JSON.parse(sessionStorage.getItem("convertedvalues"));
        _.each(chartDetails["yAxis"], function (val) {
            var value = val.columnName;
            yAxisData += encodeURIComponent(val.columnName) + ",";
            yAxisDisplayNameData += encodeURIComponent(val.displayName) + ",";
            yAxisDataType += val.dataType + ",";
            if (val.dataType == "customaggregatedfield") {
                aggregation += "customaggregatedfield,";
            } else {
                aggregation += val.aggregation.replace(/[^A-Z0-9]/ig, "") + ",";
            }
            percentileValue += val.percentileValue + ",";
        });
        yAxisData = yAxisData.substring(0, yAxisData.length - 1);
        yAxisDataType = yAxisDataType.substring(0, yAxisDataType.length - 1);
        yAxisDisplayNameData = yAxisDisplayNameData.substring(0, yAxisDisplayNameData.length - 1);
        aggregation = aggregation.substring(0, aggregation.length - 1);
        percentileValue = percentileValue.substring(0, percentileValue.length - 1);
        if (percentileValue == undefined) {
            percentileValue = "";
        }
        _.each(chartDetails["xAxis"], function (val1) {
            var temp = $.grep(ChartInfo.functions.getcustomhierarchy(), function (e) { return e.name == val1.columnName });
            var ramgeArray = $.grep(ChartInfo.functions.getRange(), function (e) { return e.name == val1.columnName });
            if (temp.length > 0) {
                xAxisData += encodeURIComponent(temp[0]["hierarchelist"][0].columnName) + ",";
                xAxisDataType += temp[0]["hierarchelist"][0].dataType + ",";
            } else if (ramgeArray.length > 0) {
                xAxisData += encodeURIComponent(ramgeArray[0].name) + ",";
                rangefieldMap[ramgeArray[0].name] = ramgeArray[0];
                xaxisAggregation = "range";
            } else {
                xAxisData += encodeURIComponent(val1.columnName) + ",";
                xAxisDataType += val1.dataType + ",";
            }
        });
        xAxisData = xAxisData.substring(0, xAxisData.length - 1);
        xAxisDataType = xAxisDataType.substring(0, xAxisDataType.length - 1);
        var legendSeries = chartDetails["legend"];
        var chartType;
        if (chartDetails["oldcharttype"] == "heatmap") {
            chartType = chartDetails["oldcharttype"];
        }
        else {
            chartType = chartDetails["chartType"];
        }
        _.each(chartDetails["tooltip"], function (val) {
            var toolTipObject = {};
            toolTipObject["columnName"] = val.columnName;
            toolTipObject["aggType"] = val.aggregation.replace(/[^A-Z0-9]/ig, "");
            toolTipObject["percentileValue"] = val.percentileValue;
            toolTipData.push(toolTipObject);
        });
        _.each(chartDetails["combinationchartslist"], function (val) {
            var combinationchartslist = {};
            combinationchartslist["columnName"] = val.columnName;
            combinationchartslist["chartType"] = val.selectedChartType;
            combinationchartslist["chartaxisposintion"] = val.chartaxisposintion;
            combinationchartsData.push(combinationchartslist);
        });
        _.each(chartDetails["zAxis"], function (val) {
            var zAxisObject = {};
            zAxisObject["columnName"] = val.columnName;
            zAxisObject["aggType"] = val.aggregation.replace(/[^A-Z0-9]/ig, "");
            zAxisObject["percentileValue"] = val.percentileValue;
            zAxisData.push(zAxisObject);
        });
        _.each(chartDetails["customSolidGauge"], function (val) {
            var customSolidGaugeObject = {};
            customSolidGaugeObject["columnName"] = val.columnName;
            customSolidGaugeObject["aggType"] = val.aggregation.replace(/[^A-Z0-9]/ig, "");
            customSolidGaugeObject["percentileValue"] = val.percentileValue;
            customSolidGaugeData.push(customSolidGaugeObject);
        });
        var legendcolumnName = "";
        var legendDataType = "";
        if (legendSeries == undefined || legendSeries == null) {
            legendcolumnName = "";
            legendDataType = "";
        }
        else {
            legendcolumnName = legendSeries.columnName;
            legendDataType = legendSeries.dataType;
            var ramgeArray = $.grep(ChartInfo.functions.getRange(), function (e) { return e.name == legendSeries.columnName });
            if (ramgeArray.length > 0) {
                rangefieldMap[ramgeArray[0].name] = ramgeArray[0];
                xaxisAggregation = "range";
            }
        }
        if (chartType == "sankey" && (legendSeries == undefined || legendSeries == null)) {
            legendcolumnName = chartDetails["yAxis"][0].columnName;
            legendDataType = chartDetails["yAxis"][0].dataType;
        }
        if (chartType == 'boxplot' && (legendSeries != undefined || legendSeries != null)) {
            xAxisData = legendcolumnName;
            legendcolumnName = chartDetails["xAxis"][0].columnName;
            if (chartDetails["xAxis"][0] != undefined && chartDetails["xAxis"][0].dataType) {
                legendDataType = chartDetails["xAxis"][0].dataType;
            } else {
                legendDataType = xAxisDataType;
            }
            xAxisDataType = legendSeries.dataType;
        }
        var aggregatedCustomField = ChartInfo.functions.getCustomMeasureField();
        var customFields = sessionStorage.getItem("customFields");
        var temp = JSON.parse(customFields);
        var res;
        _.each(temp, function (customfields) {
            _.each(customfields.parametrFieldsArray, function (value) {
                res = $.grep(ChartInfo.functions.getCustomParameter(), function (e) {
                    return e.parametername == value;
                })
                var formula = customfields.formula;
                var scriptcondition = customfields.scriptcondition;
                var fields = customfields.fields;
                var sqlformula = customfields.sqlformula;
                if (res.length != 0) {
                    customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
                    customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
                    customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
                    customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
                } else {
                    _.each(ChartInfo.functions.getCustomParameter(), function (values) {
                        if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
                            customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                            customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                            customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
                            customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                        }
                    });
                }
            });
        });
        customFields = JSON.stringify(temp);
        var custommeasurehierarachyarray = ChartInfo.functions.getMeasureHierarchy();
        var timeZone;
        var TimeZoneSettingsValueForstoryboard = ChartInfo.functions.getTimeZoneSettings("-1");
        var TimeZoneSettingsValueForEachChart = ChartInfo.functions.getTimeZoneSettings(chartnumber);

        if (TimeZoneSettingsValueForEachChart == undefined) {
            if (TimeZoneSettingsValueForstoryboard == "localtimezone") {
                timeZone = jstz.determine().name();
            }
        } else {
            if (TimeZoneSettingsValueForEachChart == "localtimezone") {
                timeZone = jstz.determine().name();
            }
        }
        var globalfilterArray = ChartInfo.functions.getFilterArray();
        if (this.props.iscustomstoryboard) {
            globalfilterArray = ChartInfo.functions.getCustomStoryBoardFilterDetails(reportId);
        }
        var parsedSharedFilters = ChartInfo.functions.getSharedFiltersForLiveReport();
        if (parsedSharedFilters != undefined && parsedSharedFilters.length > 2) {
            var tempfilterArray = [];
            if (globalfilterArray.length > 0) {
                _.each(globalfilterArray, function (value, index) {
                    parsedSharedFilters = $.grep(parsedSharedFilters, function (e) { return e.columnname != value.columnname });
                });
            }
            globalfilterArray = globalfilterArray.concat(parsedSharedFilters);
        }

        //Appending tilefilters if any
			var mainFilterCopy=globalfilterArray;
			if(mainFilterCopy!=undefined){
				globalfilterArray=[];
				_.each(mainFilterCopy,function(mfvalue,index1){
					var mfvalueC = _.clone(mfvalue);
					globalfilterArray.push(mfvalueC);
				})
			}
			
			var mainFiltersList=[];
			var tileLevelFilters= ChartInfo.functions.getTileLevelFilters(chartnumber);
			if(tileLevelFilters!=undefined && tileLevelFilters[1].chartid==chartnumber){
				var tileFiltersList= tileLevelFilters[1].tileFiltersList;
				var storyboardFilters=tileLevelFilters[0].storyboardFilters;
				var isStoryboardFiltersEnabled =tileLevelFilters[0].isStoryboardFiltersEnabled;
				if(globalfilterArray!=undefined && storyboardFilters!=undefined && storyboardFilters.length>0){
					_.each(storyboardFilters,function(sfvalue,index){
						_.each(globalfilterArray,function(mfvalue,index1){
							if(sfvalue!=undefined && mfvalue!=undefined && mfvalue.filterIgnore!=undefined && mfvalue.columnname==sfvalue.columnname){
									mfvalue.filterIgnore=!sfvalue.checked;
							}
						});
					});
				}
				
				if(isStoryboardFiltersEnabled!=undefined && globalfilterArray!=undefined && isStoryboardFiltersEnabled==false){
					_.each(globalfilterArray,function(mfvalue,index1){
						if(mfvalue!=undefined && mfvalue.filterIgnore!=undefined){
							mfvalue.filterIgnore=true;
						}
					});
				}
				
				if(tileFiltersList!=undefined && globalfilterArray!=undefined){
					_.each(tileFiltersList,function(sfvalue,index){
						_.each(globalfilterArray,function(mfvalue,index1){
							if(sfvalue!=undefined && mfvalue!=undefined && mfvalue.filterIgnore!=undefined && mfvalue.columnname==sfvalue.columnname){
								mfvalue.filterIgnore=true;
							}
						});
					});
				}
				
				if(globalfilterArray!=undefined && tileFiltersList!=undefined){
					var mainFiltersList=[];
					var instanceFilteresList=[];
					_.each(globalfilterArray,function(mfvalue,index1){
						if(mfvalue!=undefined && mfvalue.filterIgnore!=undefined && !mfvalue.filterIgnore){
							mainFiltersList.push(mfvalue.columnname);
						}else if(mfvalue!=undefined && mfvalue.filterIgnore == undefined && mfvalue.isdrilldownfilter!=undefined){
							instanceFilteresList.push(mfvalue.columnname);
						}
					})
					var tileFiltersListClone= [];
					_.each(tileFiltersList,function(tfv,index){
						var tfvCopy= _.clone(tfv);
						if(instanceFilteresList.indexOf(tfvCopy.columnname)==-1){
							tileFiltersListClone.push(tfvCopy);
						}
					});
					globalfilterArray=globalfilterArray.concat(tileFiltersListClone);
				}
			}

        var requestData = {};
        requestData["aggregation"] = aggregation;
        requestData["xaxis"] = xAxisData;
        requestData["xaxisdataType"] = xAxisDataType;
        requestData["yaxis"] = yAxisData;
        requestData["yAxisDisplayName"] = yAxisDisplayNameData;
        requestData["script"] = customFields;
        requestData["legend"] = legendcolumnName;
        requestData["legendDataType"] = legendDataType;
        requestData["datamodelId"] = this.state.datamodelId == undefined ? "" : this.state.datamodelId;
        requestData["indexName"] = this.state.indexName == undefined ? "" : this.state.indexName;
        requestData["filterObject"] = JSON.stringify(globalfilterArray);
        requestData["reportId"] = reportId;
        requestData["sortingColumn"] = sortingColumn;
        requestData["sortingColumnAggregation"] = sortingColumnAggregation;
        requestData["rankingColumn"] = rankingColumn;
        requestData["rankingColumnAggregation"] = rankingColumnAggregation;
        requestData["sortType"] = sortType;
        requestData["orderType"] = orderType;
        requestData["colLimit"] = colLimit;
        requestData["chartType"] = chartType;
        requestData["chartnumber"] = chartnumber;
        requestData["percentileValue"] = percentileValue;
        requestData["toolTips"] = JSON.stringify(toolTipData);
        requestData["zaxis"] = JSON.stringify(zAxisData);
        requestData["customSolidGaugeField"] = JSON.stringify(customSolidGaugeData);
        requestData["calculation"] = "";
        requestData["entities"] = "";
        requestData["rangefieldMap"] = JSON.stringify(rangefieldMap);
        requestData["customaggregatedscriptfield"] = JSON.stringify(aggregatedCustomField);
        requestData["combinationchartsData"] = JSON.stringify(combinationchartsData);
        var comparedaterangecharttpes =  ["bar", "stackedbar", "line", "spline", "area", "stackedarea"];
        let tempYaxis = chartDetails['yAxis'];
        let finalCompareDateObj = {};
        let compareDateRangeObjtemp = chartDetails["compareDateRangeObj"];
        if(tempYaxis.length>1&&compareDateRangeObjtemp!=null&&compareDateRangeObjtemp["0"]!=null){
            finalCompareDateObj = this.getMutiyComapreDateObj(finalCompareDateObj,chartDetails["compareDateRangeObj"],chartDetails['yAxis']);
         }else{
             finalCompareDateObj =  chartDetails["compareDateRangeObj"];
         }
         let tempXAxis = chartDetails['xAxis'];
         if(compareDateRangeObjtemp!=null&&compareDateRangeObjtemp["0"]!=null){
            let yjsObj = compareDateRangeObjtemp["0"];
            let customhierarchy = ChartInfo.functions.getcustomhierarchy();
            var key = Object.keys(yjsObj);
            let xObj = tempXAxis.length>0?tempXAxis[0]:{};
            if (yjsObj != null &&!_.isEmpty(xObj)){
                var ycompareDetailsObj = yjsObj[key];
                let datehistogramstateval = ycompareDetailsObj["compareDateHistogramInterval"] ;
                if(datehistogramstateval==""&&xObj.dataType.toLowerCase()=="date"){
                    finalCompareDateObj = null; 
                } 
                if (datehistogramstateval==""&&xObj.dataType.toLowerCase()=='customhierarchy') {
                    let hierarchy = $.grep(customhierarchy, function (e) { return e.name == xObj.displayName }); 
                    if(hierarchy[0].hierarchelist[0].dataType =="date"){
                        finalCompareDateObj = null; 
                    }
                }
            }
         }
	    requestData["compareDateRangeJson"] = JSON.stringify(comparedaterangecharttpes.indexOf(chartType)!=-1?finalCompareDateObj:null);
        requestData["custommeasurehierarchyarray"] = JSON.stringify(custommeasurehierarachyarray) == undefined ? "" : JSON.stringify(custommeasurehierarachyarray);
        requestData["isExcludeNulls"] = isExcludeNulls;
        requestData["yAxisDataType"] = yAxisDataType;
        requestData["metricdatefield"] = metricdatefield;
        requestData["metrictype"] = metricdatetype;
        requestData["isExcludeorIncludeFilters"] = isExcludeorIncludeFilters;
        requestData["timeZone"] = timeZone;
        if (xaxisAggregation == "range") {
            requestData["xaxisAggregation"] = "range";
        } else {
            requestData["xaxisAggregation"] = "group";
        }
        let urlString = `${window.location.protocol}//${window.location.host}/api`;
        requestData["urlString"] = urlString;
        requestData["api_key"] = window.localStorage.getItem("accessToken");

        return requestData;
    }
    startliveupdateEve(msg, data) {
        this.close();
        this.setIntervalForWebWorker(data.chartnumber, data.liveFrom);
    }
    setIntervalForWebWorker(chartnumber, liveFrom) {
        var livedatasyncconfig = ChartInfo.functions.getliveDataconfig();
        var AllChartDetails = ChartInfo.functions.getAllChartDetails();
        var that = this;
        var featureModuleName = "story_board"
        var featureName = "live_storyboard"
        if (liveFrom == "singlereport") {
            featureModuleName = "my_report";
            featureName = "live_report";
        }
        $.each(this.state.features, function (index, featuresModule) {
            if (featuresModule.moduleName == featureModuleName) {
                $.each(featuresModule.features, function (index, feature) {
                    if (feature.featureName == featureName && feature.allow == true) {
                        _.each(livedatasyncconfig, function (obj, chartnumber) {
                            if (obj.syncType != undefined && obj.syncType != 'none' && obj.syncType != "refreshonupdate") {
                                var milliseconds = obj["intervalTimeinMilliseconds"];
                                if (chartnumber == -1) {
                                    var intervalVarable = setInterval(() => {
                                        _.each(AllChartDetails, function (value, key) {
                                            if (ChartInfo.functions.getIntervalValue(key) == undefined) {
                                                var data = that.createReportModel(key, that.state.reportId);
                                                that.state.worker.postMessage(data);
                                            }
                                        });
                                    }, milliseconds);
                                } else {
                                    var intervalVarable = setInterval(() => {
                                        var data = that.createReportModel(chartnumber, that.state.reportId);
                                        that.state.worker.postMessage(data);
                                    }, milliseconds);
                                }
                                ChartInfo.functions.setIntervalValue(chartnumber, intervalVarable);
                                $(".itslive").removeClass("hide");
                            } else if (obj.syncType == "refreshonupdate") {
                                that.createWebSocket(that.state.reportId, chartnumber);
                            }
                        });
                    }
                })
            }
        })

        var href = window.location.href;
        var reportType = href.split("/");
        if ((href.toLowerCase().indexOf("embeddedreport") >= 0 || href.toLowerCase().indexOf("alertreport") >= 0 ) && featureModuleName == "my_report" &&
            reportType != undefined && reportType != null && reportType.length == "3" && reportType[2] == "live" && livedatasyncconfig != undefined) {
            _.each(livedatasyncconfig, function (obj, chartnumber) {
                if (obj.syncType != undefined && obj.syncType != 'none' && obj.syncType != "refreshonupdate") {
                    var milliseconds = obj["intervalTimeinMilliseconds"];
                    if (chartnumber == -1) {
                        var intervalVarable = setInterval(() => {
                            _.each(AllChartDetails, function (value, key) {
                                if (ChartInfo.functions.getIntervalValue(key) == undefined) {
                                    var data = that.createReportModel(key, that.state.reportId);
                                    that.state.worker.postMessage(data);
                                }
                            });
                        }, milliseconds);
                    } else {
                        var intervalVarable = setInterval(() => {
                            var data = that.createReportModel(chartnumber, that.state.reportId);
                            that.state.worker.postMessage(data);
                        }, milliseconds);
                    }
                    ChartInfo.functions.setIntervalValue(chartnumber, intervalVarable);
                    $(".itslive").removeClass("hide");
                } else if (obj.syncType == "refreshonupdate") {
                    that.createWebSocket(that.state.reportId, chartnumber);
                }
            });
        }
    }
    getMutiyComapreDateObj(finalCompareDateObj,tempcompareDateObj,tempYaxis){
        let yjsObj = tempcompareDateObj["0"]; 
        let matchedYDisplay = "",matchedIndex=0;
        _.each(tempYaxis,function(val,index){
            if(val.displayName!=undefined&&val.displayName!=null&&yjsObj.hasOwnProperty(val.displayName)){
                finalCompareDateObj[index+""]=yjsObj;
                matchedYDisplay = val.displayName;
                matchedIndex = index;
            }
        });
        let clonedObj = yjsObj[matchedYDisplay]
         if (yjsObj != null && yjsObj.hasOwnProperty(matchedYDisplay)&&tempYaxis.length>1) {
             _.each(tempYaxis,function(val,index){
                 if(index!=matchedIndex){
                     let tempObj = {}
                     tempObj[val.displayName] = _.clone(clonedObj)
                    finalCompareDateObj[index+""] = tempObj;
                 }
                })
            }
            return finalCompareDateObj;
    }
    unbindEvents = () =>{
        let live_config = ChartInfo.functions.getliveDataconfig()
        _.each(live_config,function(value,key){
            clearInterval(ChartInfo.functions.getIntervalValue(key));
            ChartInfo.functions.setIntervalValue(key, undefined);
        })
    }
}
