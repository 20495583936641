import React from "react";
import ReactDOM from "react-dom";
import { lang } from "../js/helpers/utils/languages/language";
import * as TabInfo from "../js/helpers/utils/tabinfo";
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import _ from "underscore";
import StoryboardSmartInsightsChooseColumnTemplate from "./storyboardsmartinsightschoosecolumnmodal.jsx";
import * as ModalProperties from "../js/helpers/utils/modalproperties";
import { getMessage } from '../js/helpers/utils/BirdMessage';
import jstz from 'jstz';
import SendRequest from '../SendRequest';

const $ = window.$;
export default class StoryboardSmartInsightsView extends React.Component {
    constructor(props) {
        super(props)
        this.state = {};
        this.data = this.props.columns;
        this.chartId = this.props.chartId;
        this.chartnumber = this.props.chartnumber;
        this.xaxisName = this.props.xaxisName;
        this.xaxisDisplayName = this.props.xaxisDisplayName;
        this.xaxisType = (this.props.xaxisType == undefined) ? "string" : this.props.xaxisType;
        this.value = this.props.value;
        this.chart = this.props.chart;
        this.datamodelId = this.props.datamodelId;
        this.point = this.props.point;
        this.pos = this.props.pos;
        this.drilldownType = this.props.drilldownType;
        this.iscuststroyboard = this.props.iscuststroyboard;
        this.displayName = this.props.displayName;
        this.seriesCount = 1;
        this.drillupCount = 1;
        this.sourceType = this.props.sourceType;
        this.multifact = (this.props.ismultifact == 'true');
        this.multifactentities = (this.props.ismultifact == 'true') ? JSON.parse(this.props.multifactentities) : this.props.multifactentities;
        this.facttables = this.props.facttables
        this.yaxisnameArray = this.props.yaxisnameArray
        this.drilldownyAxisSeries = this.props.drilldownyAxisSeries
        this.seriesId = "";
        this.isDrildownDataSaved = false;
        this.categoriesDrillDown = [];
        this.DrillDownData = [];
        this.isFromStoryBook = this.props.isFromStoryBook == undefined ? false : this.props.isFromStoryBook;
        this.tabid = this.props.tabid == undefined ? 0 : this.props.tabid;
        this.isfrom = this.props.isfrom ? this.props.isfrom : "charts";
        if (window.location.pathname.indexOf("viewstorybook") > 0) {
            if (TabInfo.functions.getCurrentTabId() != undefined) {
                this.isFromStoryBook = true;
                this.tabid = TabInfo.functions.getCurrentTabId();
                if (TabInfo.functions.getDrilldownStatusForTab(this.tabid) != undefined) {
                    this.drilldownallstatus = TabInfo.functions.getDrilldownStatusForTab(this.tabid);
                }
            }
        }
        this.render();
    }
    render() {
        if ($(".maxanim1.highlight").length === 1) {
            $(".maxanim1.highlight").find(".attributes").click();
        }
        var that = this;
        var disable = false;
        var xaxisNameArray = [];
        xaxisNameArray.push(this.xaxisName)
        var chartDetails = {};
        if (this.sourceType == "logs") {
            chartDetails = ChartInfo.functions.getChartDetailsForLogReport(this.chartnumber)["chartdetails"];
        }
        else if (ChartInfo.functions.getChartDetails(this.chartnumber) != undefined) {
            chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(this.chartnumber));
        }
        var chartType = chartDetails["chartType"];
        var yaxisFields = chartDetails["yAxis"];
        // ChartInfo.functions.setDrillup(this.chartnumber, yaxisFields);
        // ChartInfo.functions.setDrillupCounter(this.chartnumber, this.drillupCount);

        var renameMeasuresorDimensions = sessionStorage.getItem('renameMeasuresorDimensions');
        var priorityColumns = ChartInfo.functions.getPriorityColumns();
        if (priorityColumns != undefined && priorityColumns != null && priorityColumns.length > 0) {
            this.data = priorityColumns;
        } else {
            this.data = JSON.parse(this.data);
        }
        if (renameMeasuresorDimensions != null && renameMeasuresorDimensions != "null") {
            var parsedData = JSON.parse(renameMeasuresorDimensions);
            _.each(this.data, function (obj) {
                if (parsedData[obj.columnName] != undefined) {
                    obj['columnDisplayName'] = parsedData[obj.columnName];
                }
            });
        }
        this.data.sort(function (a, b) {
            var x = a.columnDisplayName.toLowerCase();
            var y = b.columnDisplayName.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
        });
        var jsonData = this.data;
        var customFields = [];
        var temp;
        if (this.iscuststroyboard) {
            if (ChartInfo.functions.getCustomFieldsObjectReportedId(chartDetails['reportid']) != "null" && ChartInfo.functions.getCustomFieldsObjectReportedId(chartDetails['reportid']) != undefined && ChartInfo.functions.getCustomFieldsObjectReportedId(chartDetails['reportid']) != null) {
                temp = ChartInfo.functions.getCustomFieldsObjectReportedId(chartDetails['reportid']);
                temp = JSON.parse(temp);
                customFields = temp;
            }
        } else {
            customFields = JSON.parse(sessionStorage.getItem("customFields"));
            var temp = [];
            if (this.multifact == true) {
                _.each(customFields, function (val, index) {
                    var isvalid = true;
                    if (val.usedFields.length > 0) {
                        _.each(val.usedFields, function (used, index) {
                            disable = ChartInfo.functions.getAllowedEntitiesForInstantFilters(that.multifactentities, used, that.props.customfield, that.yaxisnameArray, xaxisNameArray, that.props.facttables, "xdropdown");
                            if (disable) {
                                isvalid = false;
                            }
                        });
                        if (isvalid) {
                            temp.push(val);
                        }
                    } else {
                        temp.push(val);
                    }
                });
            } else {
                temp = customFields;
            }
        }
        var res;
        _.each(temp, function (customfields) {
            _.each(customfields.parametrFieldsArray, function (value) {
                res = $.grep(ChartInfo.functions.getCustomParameter(), function (e) {
                    return e.parametername == value;
                })
                var formula = customfields.formula;
                var scriptcondition = customfields.scriptcondition;
                var fields = customfields.fields;
                var sqlformula = customfields.sqlformula;
                if (res.length != 0) {
                    customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
                    customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
                    customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
                    customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
                } else {
                    _.each(ChartInfo.functions.getCustomParameter(), function (values) {
                        if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
                            customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                            customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                            customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
                            customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                        }
                    });
                }
            });
        });
        customFields = temp;
        if (customFields instanceof Array) {
            if (customFields == null || customFields == undefined || customFields.length == 0) {
                customFields = "";
            }
        }
        if (typeof customFields === 'string') {
            if (customFields == null || customFields == undefined || customFields.toLowerCase().indexOf("null") >= 0) {
                customFields = "";
            }
        }
        var customfieldsarray = [];
        var columnarray = [];
        var convertedTypes = {};
        this.levelmapping = {};
        _.each(JSON.parse(sessionStorage.getItem("convertedtypes")), function (list) {
            convertedTypes[list["columnName"]] = list["dataType"]
        })

        var columnarray = [];
        var that = this;
        if (this.multifact) {
            if (this.drilldownType != "measuredrilldown") {
                _.each(jsonData, function (val, index) {
                    if (val.columnName != undefined) {
                        disable = ChartInfo.functions.getAllowedEntitiesForInstantFilters(that.multifactentities, val.columnName, that.props.customfield, that.yaxisnameArray, xaxisNameArray, that.props.facttables, "xdropdown");
                        if (!disable) {
                            columnarray.push(val);
                        }
                    }
                });
            }
        } else {
            columnarray = jsonData;
        }
        if (customFields != null && customFields != undefined && customFields != "") {
            customFields.sort(function (a, b) {
                var x = a.name.toLowerCase();
                var y = b.name.toLowerCase();
                return x < y ? -1 : x > y ? 1 : 0;
            });
        }
        customFields = customFields != undefined && customFields != null && customFields != "" ? customFields : [];
        ReactDOM.render(<StoryboardSmartInsightsChooseColumnTemplate smartinsightsrun={that.smartinsightsrun} chartnumber={that.chartnumber}
            columns={columnarray} pos={that.pos}
            calculatedFileds={customFields} convertedTypes={convertedTypes}
            iscuststroyboard={that.iscuststroyboard} mapType={false} disabled={disable}
            isfromcomponent={this.isfrom} summarysmartinsightrun={this.summarysmartinsightrun} />, document.querySelector("#smartinsightchoosefielddiv.smartinsightchoosefielddiv" + that.chartnumber));
        $(".targetcolumn-text").text("Chosen Target Variable: " + that.props.drilldownyAxisSeries)
        if (this.point) {
            if (that.iscuststroyboard) {
                var customhierarchy = [];
                if (ChartInfo.functions.getCustomHierarchyReportId(chartDetails.reportid) != undefined) {
                    customhierarchy = ChartInfo.functions.getCustomHierarchyReportId(chartDetails.reportid);
                }
                this.measureHierarchy = []
                if (ChartInfo.functions.getMeasureReportId(chartDetails.reportid) != undefined) {
                    this.measureHierarchy = ChartInfo.functions.getMeasureHierarchyDetailsCSB(this.drilldownType, this.chartnumber, this.point.name, chartDetails.reportid);
                }
            }
            else {
                var customhierarchy = ChartInfo.functions.getcustomhierarchy()
                this.measureHierarchy = ChartInfo.functions.getMeasureHierarchyDetails(this.drilldownType, this.chartnumber, this.point.name);
            }
            var dhTemp;
            if (chartDetails['xAxis'] != undefined && chartDetails['xAxis'][0] != undefined) {
                dhTemp = $.grep(customhierarchy, function (e) { return e.name == chartDetails['xAxis'][0].columnName });
            }
        }
        $("input:checkbox[class='drilldowncheckbox']").parents(".boc-list-item").each(function () {
            var text = $(this).find('input').attr("data-name").toLowerCase();
            var datatype = $(this).find("input").attr("data-type");
            (text.indexOf("id") >= 0 && datatype == "number") ? $(this).find("input").prop('checked', false) : $(this).find('input').prop('checked', true)
        });
        var i = 0;
        var j = 0;
        _.each(jsonData, function (obj) {
            if (obj.priorityColumn) {
                i++;
            }
        });
        _.each(customFields, function (obj) {
            if (obj.priorityColumn) {
                j++;
            }
        });
        if (i + j == 0) {
            $('.prioritycolumns').addClass("hide");
        } else {
            $('.prioritycolumns').removeClass("hide");
        }
        if (customFields != null && jsonData != null && customFields.length + jsonData.length == j + i) {
            $('.availablecolumns').addClass("hide");
        } else {
            $('.availablecolumns').removeClass("hide");
        }
        ModalProperties.resize();
        return (<React.Fragment />);
    }
    smartinsightsrun = (e) => {
        if (e != undefined && $(".drilldowncheckbox:checked").attr("data-child") == undefined) {
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Information');
            $('.birdmessage-info').empty().text(getMessage('BirdInformation20'));
            return
        }
        var that = this;
        var customFields = sessionStorage.getItem("customFields");
        var temp = JSON.parse(customFields);
        var chartDetails = {};
        var columnsArray = []
        if (that.sourceType == "logs") {
            chartDetails = ChartInfo.functions.getChartDetailsForLogReport(that.chartnumber)["chartdetails"];
        } else {
            chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(that.chartnumber));
        }
        if (this.iscuststroyboard) {
            if (ChartInfo.functions.getCustomFieldsObjectReportedId(chartDetails.reportid) != undefined) {
                customFields = JSON.parse(ChartInfo.functions.getCustomFieldsObjectReportedId(chartDetails.reportid));
            }
            temp = customFields;
            var mesureHierarchy = JSON.parse(ChartInfo.functions.getMeasureReportId(chartDetails.reportid));
            var customRange = ChartInfo.functions.getCustomRangeReportId(chartDetails['reportid'])
            var customhierarchy = ChartInfo.functions.getCustomHierarchyReportId(chartDetails.reportid);
            var aggregatedCustomField = ChartInfo.functions.getAggCustomFieldMeasureReportId(chartDetails.reportid)

        }
        else {
            var customRange = ChartInfo.functions.getRange();
            var mesureHierarchy = ChartInfo.functions.getMeasureHierarchy()
            var customhierarchy = ChartInfo.functions.getcustomhierarchy();
            var aggregatedCustomField = ChartInfo.functions.getCustomMeasureField();

        }
        var res;
        _.each(temp, function (customfields) {
            _.each(customfields.parametrFieldsArray, function (value) {
                var customparameter = ChartInfo.functions.getCustomParameter();
                var customParameterFilterArray = ChartInfo.functions.getCustomParametersFilters('applyattributes');
                if (customParameterFilterArray != undefined && customParameterFilterArray.length != 0) {
                    customparameter = customParameterFilterArray;
                }
                res = $.grep(customparameter, function (e) {
                    return e.parametername == value;
                })
                var formula = customfields.formula;
                var scriptcondition = customfields.scriptcondition;
                var fields = customfields.fields;
                var sqlformula = customfields.sqlformula;
                if (res.length != 0) {
                    customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
                    customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
                    customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
                    customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
                } else {
                    _.each(ChartInfo.functions.getCustomParameter(), function (values) {
                        if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
                            customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                            customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                            customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
                            customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                        }
                    });
                }
            });
        });
        customFields = JSON.stringify(temp);
        if (e != undefined && $(".drilldowncheckbox:checked").attr("data-child") == undefined) {
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Information');
            $('.birdmessage-info').empty().text(getMessage('BirdInformation20'));

        } else {
            if ($('.fullscreenmain[data-num=' + this.chartnumber + ']').length > 0) {
                var cuurenth = $('#chart' + this.chartnumber).height();
                var finalh = cuurenth - 43;
                var finalw = $('#chart' + this.chartnumber + ' .highcharts-container').width();
                this.chart.setSize(finalw, finalh);
            }
            // this.chart.showLoading("Loading");

            $(".drilldowncheckbox:checked").each(function () {
                let columnObj = {}
                columnObj["visible"] = "yes";
                columnObj["tableAliasName"] = $(this).attr("data-parent");
                columnObj["columnDisplayName"] = $(this).attr("data-name");
                columnObj["orginalColumnName"] = $(this).attr("data-originalname");
                columnObj["tableDisplayType"] = $(this).attr("data-type");
                columnObj["name"] = $(this).attr("data-columnname");
                columnObj["tableDisplayName"] = $(this).attr("data-parent");
                columnObj["type"] = $(this).attr("data-type");
                columnObj["tablename"] = $(this).attr("data-parent");
                columnObj["tableName"] = $(this).attr("data-parent");
                columnObj["columnName"] = $(this).attr("data-columnname");
                columnsArray.push(columnObj)

            });
            // ReactDOM.unmountComponentAtNode(document.querySelector("#smartinsightchoosefielddiv.smartinsightchoosefielddiv" + this.chartnumber));
            var drillDown = [];
            if (sessionStorage.getItem("drilldown" + this.chartnumber) != undefined) {
                var storedData = JSON.parse(sessionStorage.getItem("drilldown" + this.chartnumber));
                drillDown = storedData;
            }
            if (that.isFromStoryBook != undefined && that.isFromStoryBook) {
                var tabid = TabInfo.functions.getCurrentTabId();
                if (TabInfo.functions.getDrilldownFilterFromSession(tabid) != undefined && Object.keys(TabInfo.functions.getDrilldownFilterFromSession(tabid)).length != 0) {
                    if (drillDown == undefined || drillDown == null || drillDown == "null" || drillDown == "" || drillDown == "[]" || drillDown.length == 0) {
                        if (TabInfo.functions.getDrilldownFilterFromSession(tabid)[that.chartnumber] != undefined) {
                            drillDown = JSON.parse(TabInfo.functions.getDrilldownFilterFromSession(tabid)[that.chartnumber]);
                        }
                    }
                }
            }
            var tempData = {};
            if (this.isFromStoryBook != undefined && this.isFromStoryBook && sessionStorage.getItem('pos') != 'max') {
                this.drilldownallstatus = true;
                drillDownAllCharts = this.drilldownallstatus;
            }
            tempData = { "name": this.xaxisName, "columnname": this.xaxisName, "type": this.xaxisType, "dataType": this.xaxisType, "value": this.value, "data": this.value, "displayName": this.xaxisDisplayName, "columnDisplayName": this.displayName, "columndisplayname": this.displayName, "isDrillDownAll": true };
            if (tempData.columnname != undefined && tempData.columnname != null && tempData.columnname != "Measure Names") {
                drillDown.push(tempData);
            }
            if (that.isFromStoryBook != undefined && that.isFromStoryBook) {
                var tabid = TabInfo.functions.getCurrentTabId();
                var tempObj = {};
                tempObj[this.chartnumber] = JSON.stringify(drillDown)
                TabInfo.functions.updateDrilldownFilterFromSession(tabid, tempObj);
            }
            var filterArray = [];
            var drilldownFilterArray = [];
            this.chartDetails = chartDetails;
            var that = this;
            _.each(drillDown, function (drillDownData1) {
                var filterObject = {};
                var customhierarchy = [];
                filterObject["dataType"] = "string";
                if (drillDownData1.type != undefined && drillDownData1.type.length > 0) {
                    filterObject["dataType"] = drillDownData1.type;
                }
                if (customFields != undefined && customFields != null && customFields != "null" && customFields != "[]") {
                    var customObject = {};
                    var tempcustomObject = JSON.parse(customFields);
                    var rangeObject = $.grep(customRange, function (e) { return e.name == drillDownData1.name });
                    if (that.iscuststroyboard) {
                        customhierarchy = ChartInfo.functions.getCustomHierarchyReportId(that.chartDetails.reportid);
                    }
                    else {
                        customhierarchy = ChartInfo.functions.getcustomhierarchy()
                    }
                    var drilldownHierarchyObj = $.grep(customhierarchy, function (e) { return e.name == decodeURIComponent(drillDownData1.name) });

                    _.each(tempcustomObject, function (customfield) {
                        if (customfield.name == decodeURIComponent(drillDownData1.name)) {
                            var sqlFilterObject = {};
                            sqlFilterObject["sqldata"] = drillDownData1.value;
                            customfield["sqlcondition"] = sqlFilterObject;
                            customObject = customfield;
                            customObject["parameter"] = drillDownData1.value;
                            filterObject["customfield"] = customObject;
                            filterObject["dataType"] = drillDownData1.type;
                            filterObject["data"] = tempData.data;
                            filterObject["columnDisplayName"] = customfield.name;
                            var scriptFilterCondition = customObject['scriptcondition'];
                            customObject['scriptcondition'] = "; if((" + scriptFilterCondition + ").equals(parameter)) return true else return false";
                            if (filterObject["dataType"] == "date") {
                                customObject['scriptcondition'] = "; if(new Date(" + scriptFilterCondition + ")==new Date(parameter)) return true else return false";
                                if (customObject["parameter"].length > 0) {
                                    var newDate = customObject["parameter"];//Date.parse(customObject["parameter"].replace(/-/g,'/'));
                                    if (isNaN(newDate)) {
                                        //var newdate1=moment(myDate,dateFormat);
                                        customObject["parameter"] = customObject["parameter"];
                                    }
                                    else {
                                        customObject["parameter"] = newDate;//new Date(newDate).getTime();
                                    }
                                }
                            }
                            if (filterObject["dataType"] == "number") {
                                customObject['scriptcondition'] = "; if((" + scriptFilterCondition + ")==(parameter)) return true else return false";
                            }
                        } if (drilldownHierarchyObj.length > 0) {
                            filterObject["tablename"] = "";
                            filterObject["columndisplayname"] = drilldownHierarchyObj[0].columnDisplayName;
                            filterObject["columnDisplayName"] = drilldownHierarchyObj[0].columnDisplayName;
                            filterObject["columnname"] = drilldownHierarchyObj[0].columnName;
                            filterObject["dataType"] = drilldownHierarchyObj[0].dataType;
                            filterObject["isDrillDownAll"] = drilldownHierarchyObj[0].isDrillDownAll;
                            var value = drillDownData1.value;
                            if (filterObject["dataType"] == "date") {
                                if (value.length > 0) {
                                    var newDate = value;//Date.parse(value.replace(/-/g,'/'));
                                    if (isNaN(newDate)) {
                                        //var newdate1=moment(myDate,dateFormat);
                                        value = value;
                                    }
                                    else {
                                        value = newDate;//new Date(newDate).getTime();
                                    }

                                }
                            }
                            filterObject["data"] = value;
                        } else if (rangeObject.length > 0) {
                            filterObject["tablename"] = "";
                            filterObject["columndisplayname"] = rangeObject[0]["xAxisName"][0].columnDisplayName;
                            filterObject["columnname"] = rangeObject[0]["xAxisName"][0].columnName;
                            filterObject["columnDisplayName"] = rangeObject[0]["xAxisName"][0].columnDisplayName;;
                            var value = drillDownData1.value;
                            filterObject["data"] = value;
                        } else {
                            filterObject["tablename"] = "";
                            filterObject["columndisplayname"] = drillDownData1.columndisplayname;
                            filterObject["columnname"] = drillDownData1.name;
                            filterObject["isDrillDownAll"] = drillDownData1.isDrillDownAll;
                            filterObject["columnDisplayName"] = drillDownData1.columndisplayname;
                            var value = drillDownData1.value;
                            if (filterObject["dataType"] == "date") {
                                if (value.length > 0) {
                                    var newDate = value;//Date.parse(value.replace(/-/g,'/'));
                                    if (isNaN(newDate)) {
                                        //var newdate1=moment(myDate,dateFormat);
                                        value = value;
                                    }
                                    else {
                                        value = newDate;//new Date(newDate).getTime();
                                    }
                                }
                            }
                            filterObject["data"] = value;
                        }
                    });
                } else {
                    var chartDetails = {};
                    if (that.sourceType == "logs") {
                        chartDetails = ChartInfo.functions.getChartDetailsForLogReport(that.chartnumber)["chartdetails"];
                    }
                    else if (ChartInfo.functions.getChartDetails(that.chartnumber) != undefined) {
                        chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(that.chartnumber));
                    }
                    if (that.iscuststroyboard) {
                        var customhierarchy = ChartInfo.functions.getCustomHierarchyReportId(chartDetails.reportid);
                    }
                    else {
                        var customhierarchy = ChartInfo.functions.getcustomhierarchy()
                    }
                    var Object = $.grep(customhierarchy, function (e) { return e.name == that.xaxisName });
                    var rangeObject = $.grep(customRange, function (e) { return e.name == drillDownData1.name });
                    if (Object.length > 0) {
                        filterObject["tablename"] = "";
                        filterObject["columndisplayname"] = Object[0].columnDisplayName;
                        filterObject["columnname"] = Object[0].columnName;
                        filterObject["dataType"] = Object[0].dataType;
                        filterObject["isDrillDownAll"] = Object[0].isDrillDownAll;
                        filterObject["columnDisplayName"] = Object[0].columnDisplayName;
                        var value = drillDownData1.value;
                        if (filterObject["dataType"] == "date") {
                            if (value.length > 0) {
                                var newDate = value;//Date.parse(value.replace(/-/g,'/'));
                                if (isNaN(newDate)) {
                                    //var newdate1=moment(myDate,dateFormat);
                                    value = value;
                                }
                                else {
                                    value = newDate;//new Date(newDate).getTime();
                                }

                            }
                        }
                        filterObject["data"] = value;
                    } else if (rangeObject.length > 0) {
                        filterObject["tablename"] = "";
                        filterObject["columndisplayname"] = rangeObject[0]["xAxisName"][0].columnDisplayName;
                        filterObject["columnDisplayName"] = rangeObject[0]["xAxisName"][0].columnDisplayName;
                        filterObject["columnname"] = rangeObject[0]["xAxisName"][0].columnName;
                        var value = drillDownData1.value;
                        filterObject["data"] = value;
                    } else {
                        filterObject["tablename"] = "";
                        filterObject["columndisplayname"] = drillDownData1.columndisplayname;
                        filterObject["columnDisplayName"] = drillDownData1.columndisplayname;
                        filterObject["columnname"] = drillDownData1.name;
                        filterObject["isDrillDownAll"] = drillDownData1.isDrillDownAll;
                        var value = drillDownData1.value;
                        if (filterObject["dataType"] == "date") {
                            if (value.length > 0) {
                                var newDate = value;// Date.parse(value.replace(/-/g,'/'));
                                if (isNaN(newDate)) {
                                    //var newdate1=moment(myDate,dateFormat);
                                    value = value;
                                }
                                else {
                                    value = newDate;//new Date(newDate).getTime();
                                }

                            }
                        }
                        filterObject["data"] = value;
                    }
                }
                if (drillDownData1.name != 'Measure Names') {
                    filterArray.push(filterObject);
                    drilldownFilterArray.push(filterObject);
                }
            });

            var chartDetails = {};
            if (this.sourceType == "logs") {
                chartDetails = ChartInfo.functions.getChartDetailsForLogReport(this.chartnumber)["chartdetails"];
            }
            else if (ChartInfo.functions.getChartDetails(this.chartnumber) != undefined) {
                chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(this.chartnumber));
            }
            var xaxisData = chartDetails["xAxis"];
            var yAxisData = "";
            var yAxisDisplayName = "";
            var rangefieldMap = {};
            var yAxisDataType = "";
            var toolTipData = [];
            var xaxisAggregation = "";
            var combinationchartsData = [];
            var zAxisData = [];
            var aggregation = "";
            var percentileValue = "";
            var convertedValues = JSON.parse(sessionStorage.getItem("convertedvalues"));
        }
        if (this.drilldownType != undefined && this.drilldownType == 'measuredrilldown' && this.measureHierarchy != null && this.measureHierarchy.length > 0) {


            // For Funnel & Pyramid Charts data lables overlpapping when Y-Drilldown applied
            var measureHierarchy = this.measureHierarchy


            _.each(measureHierarchy, function (val) {
                yAxisData += encodeURIComponent(val.columnName) + ",";
                if (val.displayName != undefined && val.displayName != null) {
                    yAxisDisplayName += val.displayName + ",";
                }
                if (val.columnDisplayName != undefined && val.columnDisplayName != null) {
                    yAxisDisplayName += val.columnDisplayName + ",";
                }
                yAxisDataType += "custommeasurehierarchy,";
                if (val.dataType == "custommeasurehierarchy") {
                    aggregation += "custommeasurehierarchy" + ",";
                } else {
                    aggregation += val.aggregation.replace(/[^A-Z0-9]/ig, "") + ",";
                }
                percentileValue += "25" + ",";
            });
        }
        else {
            var yaxisArray = chartDetails["yAxis"];
            _.each(yaxisArray, function (val) {

                if (chartDetails["xAxis"][0] != undefined) {
                    var xaxisName = chartDetails["xAxis"][0]["columnName"];
                }
                else {
                    xaxisName = "Measure Names";
                }
                var yaxisName = that.point.series.options.yaxisname;
                if (that.chart.options.chart.type == 'map' && that.point.yaxisname != undefined) {
                    yaxisName = that.point.yaxisname;
                }
                if (yaxisName == val.columnName || xaxisName == 'Measure Names') {
                    var value = val.columnName;
                    yAxisData += encodeURIComponent(val.columnName) + ",";
                    if (val.displayName != undefined && val.displayName != null) {
                        yAxisDisplayName += val.displayName + ",";
                    }
                    yAxisDataType += val.dataType + ",";
                    if (val.dataType == "customaggregatedfield") {
                        aggregation += "customaggregatedfield,";
                    } else {
                        aggregation += val.aggregation.replace(/[^A-Z0-9]/ig, "") + ",";
                    }
                    percentileValue += val.percentileValue + ",";
                }
            });
        }
        yAxisData = yAxisData.substring(0, yAxisData.length - 1);
        if (yAxisDisplayName != undefined && yAxisDisplayName.length > 0)
            yAxisDisplayName = yAxisDisplayName.substring(0, yAxisDisplayName.length - 1);

        yAxisDataType = yAxisDataType.substring(0, yAxisDataType.length - 1);
        _.each(chartDetails["tooltip"], function (val) {
            var toolTipObject = {};
            toolTipObject["columnName"] = val.columnName;
            toolTipObject["aggType"] = val.aggregation.replace(/[^A-Z0-9]/ig, "");
            toolTipObject["percentileValue"] = val.percentileValue;
            toolTipData.push(toolTipObject);
        });
        _.each(chartDetails["combinationchartslist"], function (val) {
            var combinationchartslist = {};
            combinationchartslist["columnName"] = val.columnName;
            combinationchartslist["chartType"] = val.selectedChartType;
            combinationchartslist["chartaxisposintion"] = val.chartaxisposintion;
            combinationchartsData.push(combinationchartslist);
        });
        _.each(chartDetails["zAxis"], function (val) {
            var zAxisObject = {};
            zAxisObject["columnName"] = val.columnName;
            zAxisObject["aggType"] = val.aggregation.replace(/ /g, '').replace('(', '').replace(')', '');;
            zAxisObject["percentileValue"] = val.percentileValue;
            zAxisData.push(zAxisObject);
        });
        aggregation = aggregation.substring(0, aggregation.length - 1);
        percentileValue = percentileValue.substring(0, percentileValue.length - 1);
        var legendSeries = chartDetails["legend"];
        //If legend is enabled Add bar level filter
        if (legendSeries != undefined ? true : false) {
            var filterObject = {};
            var bool = true;
            var rangeObject = $.grep(customRange, function (e) { return e.name == legendSeries.columnName });
            if (customFields != undefined && customFields != null && customFields != "null") {
                var customObject = {};
                var tempcustomObject = JSON.parse(customFields);
                _.each(tempcustomObject, function (customfield) {
                    if (customfield.name == legendSeries.columnName) {
                        customObject = customfield;
                        bool = false;
                        var sqlFilterObject = {};
                        sqlFilterObject["sqldata"] = that.point.series.name.split('[')[0];
                        customObject["sqlcondition"] = sqlFilterObject;
                        customObject["parameter"] = that.point.series.name.split('[')[0];
                        filterObject["customfield"] = customObject;
                        filterObject["dataType"] = legendSeries.dataType;
                        filterObject["tablename"] = legendSeries.tableName;
                        filterObject["columndisplayname"] = legendSeries.displayName;
                        filterObject["columnname"] = legendSeries.columnName
                        filterObject["islegendobj"] = true;
                        var scriptFilterCondition = customObject['scriptcondition'];
                        customObject['scriptcondition'] = "; if((" + scriptFilterCondition + ").equals(parameter)) return true else return false";
                        if (filterObject["dataType"] == "date") {
                            customObject['scriptcondition'] = "; if(new Date(" + scriptFilterCondition + ")==new Date(parameter)) return true else return false";
                            if (customObject["parameter"].length > 0) {
                                var newDate = customObject["parameter"];//Date.parse(customObject["parameter"].replace(/-/g,'/'));
                                if (isNaN(newDate)) {
                                    //var newdate1=moment(myDate,dateFormat);
                                    customObject["parameter"] = customObject["parameter"];
                                }
                                else {
                                    customObject["parameter"] = newDate;//new Date(newDate).getTime();
                                }
                            }
                        }
                        if (filterObject["dataType"] == "number") {
                            customObject['scriptcondition'] = "; if((" + scriptFilterCondition + ")==(parameter)) return true else return false";
                        }
                    }
                });
            }
            if (bool) {
                filterObject["dataType"] = legendSeries.dataType;
                filterObject["tablename"] = legendSeries.tableName;
                filterObject["columndisplayname"] = legendSeries.displayName;
                filterObject["columnname"] = legendSeries.columnName
                filterObject["data"] = this.point.series.name.split('[')[0];
                filterObject["islegendobj"] = true;
                if (filterObject["dataType"] == "date") {
                    if (filterObject["data"].length > 0) {
                        var newDate = filterObject["data"];//Date.parse(filterObject["data"].replace(/-/g,'/'));
                        if (isNaN(newDate)) {
                            //var newdate1=moment(myDate,dateFormat);
                            filterObject["data"] = filterObject["data"];
                        }
                        else {
                            filterObject["data"] = newDate;//new Date(newDate).getTime();
                        }

                    }
                }
                if (rangeObject.length > 0) {
                    filterObject["columndisplayname"] = rangeObject[0]["xAxisName"][0].columnDisplayName;
                    filterObject["columnDisplayName"] = rangeObject[0]["xAxisName"][0].columnDisplayName;
                    filterObject["columnname"] = rangeObject[0]["xAxisName"][0].columnName;
                }
            }
            var drillDownAllCharts = $("#drilldownallchartsinstoryboard").prop("checked");
            if (this.isFromStoryBook != undefined && this.isFromStoryBook && sessionStorage.getItem('pos') != 'max') {
                this.drilldownallstatus = true;
                drillDownAllCharts = this.drilldownallstatus;
            }
            if (drillDownAllCharts == true) {
                filterObject["isDrillDownAll"] = true;
                ChartInfo.functions.setLegendFilterobjForAllCharts(filterObject);
            }
            else {
                ChartInfo.functions.setLegendFilterobj(filterObject, this.chartnumber);
            }
            filterArray.push(filterObject);
            drilldownFilterArray.push(filterObject);
        }
        var xaxisname = (xaxisData != undefined) ? xaxisData.displayName : undefined;
        if (convertedValues != null && convertedValues != undefined) {
            _.each(convertedValues, function (convertValue) {
                if (convertValue.columnDisplayName == xaxisData.displayName) {
                    xaxisname = convertValue.columnName;
                }
            });
        }
        var reportId = "";

        var legendcolumnName = "";
        var legendDataType = "";
        if (legendSeries == undefined || legendSeries == null) {
            legendcolumnName = "";
            legendDataType = "";
        }
        else {
            legendcolumnName = legendSeries.columnName;
            legendDataType = legendSeries.dataType;
            var ramgeArray = $.grep(customRange, function (e) { return e.name == legendSeries.columnName });
            if (ramgeArray.length > 0) {
                rangefieldMap[ramgeArray[0].name] = ramgeArray[0];
                xaxisAggregation = "range";
            }
        }
        var dhTemp;
        if (chartDetails["xAxis"] != undefined && chartDetails["xAxis"][0] != undefined) {
            dhTemp = $.grep(customhierarchy, function (e) { return e.name == chartDetails["xAxis"][0].columnName });
        }
        // if (dhTemp != null && dhTemp.length > 0) {
        //     var levelmapping = parseInt(sessionStorage.getItem("customhierarchyforchartnum" + that.chartnumber)) + 1;
        //     if (dhTemp[0].hierarchelist != undefined) {
        //         drilldownaxisname = dhTemp[0].hierarchelist[levelmapping].columnName;
        //         drilldownaxisdisplayname = dhTemp[0].hierarchelist[levelmapping].columnDisplayName;
        //         drilldownaxisDataType = dhTemp[0].hierarchelist[levelmapping].dataType;
        //         //sessionStorage.setItem("customhierarchyforchart",levelmapping);
        //         sessionStorage.setItem("customhierarchyforchartnum" + that.chartnumber, levelmapping);
        //     }
        // }
        var customFields = sessionStorage.getItem("customFields");
        var temp = JSON.parse(customFields);
        if (this.iscuststroyboard) {
            customFields = ChartInfo.functions.getCustomFieldsObjectReportedId(chartDetails.reportid);
            if (customFields != undefined) {
                temp = JSON.parse(customFields);
            }
        }
        var res;
        _.each(temp, function (customfields) {
            _.each(customfields.parametrFieldsArray, function (value) {
                var customparameter = ChartInfo.functions.getCustomParameter();
                var customParameterFilterArray = ChartInfo.functions.getCustomParametersFilters('applyattributes');
                if (customParameterFilterArray != undefined && customParameterFilterArray.length != 0) {
                    customparameter = customParameterFilterArray;
                }
                res = $.grep(customparameter, function (e) {
                    return e.parametername == value;
                })
                var formula = customfields.formula;
                var scriptcondition = customfields.scriptcondition;
                var fields = customfields.fields;
                var sqlformula = customfields.sqlformula;
                if (res.length != 0) {
                    customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
                    customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
                    customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
                    customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
                } else {
                    _.each(ChartInfo.functions.getCustomParameter(), function (values) {
                        if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
                            customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                            customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                            customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
                            customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                        }
                    });
                }
            });
        });
        if (temp != "null") {
            customFields = temp;
        }
        var timeZone;
        var TimeZoneSettingsValueForstoryboard = ChartInfo.functions.getTimeZoneSettings("-1");
        var TimeZoneSettingsValueForEachChart = ChartInfo.functions.getTimeZoneSettings(this.props.chartnumber);
        if (TimeZoneSettingsValueForEachChart == undefined) {
            if (TimeZoneSettingsValueForstoryboard == "localtimezone") {
                timeZone = jstz.determine().name();
                this.timeZone = timeZone;
            }
        } else {
            if (TimeZoneSettingsValueForEachChart == "localtimezone") {
                timeZone = jstz.determine().name();
                this.timeZone = timeZone;
            }
        }

        this.isPurgedReport = false;
        if (window.location.pathname.indexOf("viewpurgereport") >= 0) {
            this.isPurgedReport = true;
        }
        this.isImportedReport = false;
        if (sessionStorage.getItem("isImportedReport") || window.location.pathname.indexOf("importreport") >= 0) {
            this.isImportedReport = true;
        }

        var bool = JSON.parse(JSON.stringify(customFields));
        if (bool != null) {
            if (typeof bool === 'string') {
                if (customFields == null || customFields == undefined || customFields.toLowerCase().indexOf("null") >= 0) {
                    customFields = "";
                }
            }
        }
        var data = {};
        if (that.sourceType == "logs") {
            chartDetails = ChartInfo.functions.getChartDetailsForLogReport(that.chartnumber)["chartdetails"];
        }
        else if (ChartInfo.functions.getChartDetails(that.props.chartnumber) != undefined) {
            data = JSON.parse(ChartInfo.functions.getChartDetails(that.props.chartnumber));
        }
        if (ChartInfo.functions.getTrendType() == undefined) {
            ChartInfo.functions.setTrendType(data["trendLine"]);
        }

        var legendfilterArray = [];
        var legendFilter = data["legendfilter"];
        var legendFilterAll = chartDetails["legendfilterall"];
        if (legendFilter !== undefined && legendFilter.length != 0) {
            legendfilterArray = JSON.parse(JSON.stringify(legendfilterArray)).concat(legendFilter);
        }
        if (legendFilterAll !== undefined && legendFilterAll.length != 0) {
            legendfilterArray = JSON.parse(JSON.stringify(legendfilterArray)).concat(legendFilterAll);
        }
        var globalFilterArray = [];
        if (data["drillDownFilter"] != undefined && data["drillDownFilter"].length > 0) {
            _.each(data["drillDownFilter"], function (value, index) {
                globalFilterArray.push(value);
            });
        }
        if (data["drillDownAllFilter"] != undefined && data["drillDownAllFilter"].length > 0) {
            globalFilterArray = globalFilterArray.concat(data["drillDownAllFilter"]);
        }
        globalFilterArray = ChartInfo.functions.generateFilterWithEmbededFilters(globalFilterArray, undefined);
        if (globalFilterArray != null && globalFilterArray[0] != undefined) {
            _.each(globalFilterArray, function (value, index) {
                filterArray.push(value);
            });
        }
        filterArray = filterArray.concat(ChartInfo.functions.getFilterArray());
        //Added code to handle tile level filters
        var mainFilterCopy = filterArray;
        if (mainFilterCopy != undefined) {
            filterArray = [];
            _.each(mainFilterCopy, function (mfvalue, index1) {
                var mfvalueC = _.clone(mfvalue);
                filterArray.push(mfvalueC);
            })
        }


        var mainFiltersList = [];
        var tileLevelFilters = ChartInfo.functions.getTileLevelFilters(that.props.chartnumber);
        if (tileLevelFilters != undefined && tileLevelFilters[1].chartid == that.props.chartnumber) {
            var tileFiltersList = tileLevelFilters[1].tileFiltersList;
            var storyboardFilters = tileLevelFilters[0].storyboardFilters;
            var isStoryboardFiltersEnabled = tileLevelFilters[0].isStoryboardFiltersEnabled;
            if (filterArray != undefined && storyboardFilters != undefined && storyboardFilters.length > 0) {
                _.each(storyboardFilters, function (sfvalue, index) {
                    _.each(filterArray, function (mfvalue, index1) {
                        if (sfvalue != undefined && mfvalue != undefined && mfvalue.filterIgnore != undefined && mfvalue.columnname == sfvalue.columnname) {
                            mfvalue.filterIgnore = !sfvalue.checked;
                        }
                    });
                });
            }

            if (isStoryboardFiltersEnabled != undefined && filterArray != undefined && isStoryboardFiltersEnabled == false) {
                _.each(filterArray, function (mfvalue, index1) {
                    if (mfvalue != undefined && mfvalue.filterIgnore != undefined) {
                        mfvalue.filterIgnore = true;
                    }
                });
            }

            if (tileFiltersList != undefined && filterArray != undefined) {
                _.each(tileFiltersList, function (sfvalue, index) {
                    _.each(filterArray, function (mfvalue, index1) {
                        if (sfvalue != undefined && mfvalue != undefined && mfvalue.filterIgnore != undefined && mfvalue.columnname == sfvalue.columnname) {
                            mfvalue.filterIgnore = true;
                        }
                    });
                });
            }

            if (filterArray != undefined && tileFiltersList != undefined) {
                var mainFiltersList = [];
                var instanceFilteresList = [];
                _.each(filterArray, function (mfvalue, index1) {
                    if (mfvalue != undefined && mfvalue.filterIgnore != undefined && !mfvalue.filterIgnore) {
                        mainFiltersList.push(mfvalue.columnname);
                    } else if (mfvalue != undefined && mfvalue.filterIgnore == undefined && mfvalue.isdrilldownfilter != undefined) {
                        instanceFilteresList.push(mfvalue.columnname);
                    }
                })
                var tileFiltersListClone = [];
                _.each(tileFiltersList, function (tfv, index) {
                    var tfvCopy = _.clone(tfv);
                    //						if(mainFiltersList.indexOf(tfvCopy.columnname)!=-1){
                    //							tfvCopy["isTileFilterExists"]=true;
                    //						}
                    if (instanceFilteresList.indexOf(tfvCopy.columnname) == -1) {
                        tileFiltersListClone.push(tfvCopy);
                    }
                });
                filterArray = filterArray.concat(tileFiltersListClone);
            }
        }
        if (ChartInfo.functions.getshredFilterArrayForDrillDown() != undefined && ChartInfo.functions.getshredFilterArrayForDrillDown().length > 0) {
            var parsedSharedFilters = ChartInfo.functions.getshredFilterArrayForDrillDown();
            if (filterArray.length > 0) {
                _.each(filterArray, function (value, index) {
                    if (value != undefined) {
                        if (value.excludeFilterData != "excludeFilterData") {
                            parsedSharedFilters = $.grep(parsedSharedFilters, function (e) { return e.columnname != value.columnname });
                        }
                    }
                });
            }
            filterArray = filterArray.concat(parsedSharedFilters);
        }
        var tempArrayval = [];
        if (customFields != null && customFields != "null" && customFields != undefined) {
            $(".drilldowncheckbox:checked").each(function () {
                var thatval = this;
                tempArrayval = tempArrayval.concat($.grep(customFields, function (e) {
                    return e.name === $(thatval).attr('data-name')
                }));
            })
        } else {
            tempArrayval = customFields
        }
        ReactDOM.unmountComponentAtNode(document.querySelector("#smartinsightchoosefielddiv.smartinsightchoosefielddiv" + this.chartnumber));
        window.localStorage.setItem('smartinsights-columnsdata', JSON.stringify(columnsArray));
        window.localStorage.setItem('smartinsights-filtersdata', JSON.stringify(filterArray))
        window.localStorage.setItem('smartinsights-targetColumn', that.drilldownyAxisSeries);
        window.localStorage.setItem('smartinsights-customFieldata', JSON.stringify(tempArrayval));
        window.localStorage.setItem('isStoryboardSmartInsight', true);
        var bodyParams = new FormData();
        bodyParams.set("datamodelid", that.datamodelId);
        bodyParams.set("columns", JSON.stringify(columnsArray));
        bodyParams.set("type", "report");
        SendRequest({
            url: "createsmartreportfromstoryboard",
            body: bodyParams,
            sucessFunction: (response) => {
                var tempReportId = response['tempreportid'];
                // sessionStorage.setItem("isSharedModel" + datamodelId, isShared);
                var location = window.location;
                var href = location.origin + "/" + window.localStorage.getItem("appName") + "/welcome/viewsmartinsight/" + btoa(that.datamodelId);
                window.open(href, '_blank');
            },
            rejectFunction: () => { },
            headers: {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            }
        });
        console.log("run smartinsights");
    }
    summarysmartinsightrun = (e) => {
        if (ChartInfo.functions.getSummaryTableDateHistogramStatus(this.props.chartnumber) != "true") {
            if (e != undefined && $(".drilldowncheckbox:checked").attr("data-child") == undefined) {
                $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
                $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Information');
                $('.birdmessage-info').empty().text(getMessage('BirdInformation20'));
                return
            }
            var $this = this.props.$this;
            var indexPosition = $($this).index();
            var name = $("#summartable" + chartnumber + " thead tr th").eq(indexPosition).text();
            var measureHierarachy = $.grep(ChartInfo.functions.getMeasureHierarchy(), function (e) { return e.name == name });
            var drilldownvalue = "";
            var chartnumber = this.props.chartnumber;
            var columnsArray = [];
            var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(this.props.chartnumber));
            var customFields = sessionStorage.getItem("customFields");
            var temp, filterArray, details;
            if (sessionStorage.getItem('customhierarchy' + this.props.chartnumber) != undefined && sessionStorage.getItem('customhierarchy' + this.props.chartnumber).length != 0
                && sessionStorage.getItem('customhierarchy' + this.props.chartnumber) !== "[]") {
                var sessionValue = JSON.parse(sessionStorage.getItem('customhierarchy' + this.props.chartnumber));

                this.levelmapping = JSON.parse(JSON.stringify(sessionValue[sessionValue.length - 1][0]));
            }

            var tempCF = JSON.parse(customFields);
            var res;
            var customParameterFilterArray = ChartInfo.functions.getCustomParametersFilters(this.props.applyattributes);
            _.each(tempCF, function (customfields) {
                _.each(customfields.parametrFieldsArray, function (value) {
                    var customparameter = ChartInfo.functions.getCustomParameter();
                    if (customParameterFilterArray != undefined && customParameterFilterArray.length != 0) {
                        customparameter = customParameterFilterArray;
                    }
                    res = $.grep(customparameter, function (e) {
                        return e.parametername == value;
                    })
                    var formula = customfields.formula;
                    var scriptcondition = customfields.scriptcondition;
                    var fields = customfields.fields;
                    var sqlformula = customfields.sqlformula;
                    if (res.length != 0) {
                        customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
                        customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
                        customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
                        customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
                    } else {
                        _.each(ChartInfo.functions.getCustomParameter(), function (values) {
                            if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
                                customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
                                customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                            }
                        });
                    }
                });
            });
            customFields = tempCF;
            if (customFields != undefined && customFields != null && customFields != "null") {
                temp = customFields;
            }
            var summarytabledetails = ChartInfo.functions.getSummarytableAxisFieldsArray(chartnumber);
            var that = this;
            if (summarytabledetails != undefined && summarytabledetails.length > 0) {
                details = summarytabledetails[summarytabledetails.length - 1];
            }
            var summarytableDrilldownValues = ChartInfo.functions.getTempSummaryTableFilterValues(chartnumber) || [];
            filterArray = summarytableDrilldownValues.concat(JSON.parse(JSON.stringify(this.state.dobyfilterArray || [])));
            if (details["textcolumns"].length > 0) {
                _.each(details["textcolumns"], function (value, j) {
                    var filtervalues = {};
                    if (details["textcolumns"][j].dataType != undefined && details["textcolumns"][j].dataType == "customhierarchy") {
                        var customhierarchy = ChartInfo.functions.getcustomhierarchy();
                        var summaryTableCustomHierarchyDrilldownName = ChartInfo.functions.getSummaryTableCustomHierarchyDrilldownName();
                        if (summaryTableCustomHierarchyDrilldownName != undefined) {
                            _.each(customhierarchy, function (val1, i) {
                                if (val1.name == details["textcolumns"][j].columnName) {
                                    filtervalues["columnname"] = val1.hierarchelist[that.levelmapping[value.columnName]].columnName;
                                    filtervalues["columndisplayname"] = val1.hierarchelist[that.levelmapping[value.columnName]].columnDisplayName;
                                    filtervalues["dataType"] = val1.hierarchelist[that.levelmapping[value.columnName]].dataType;
                                    filtervalues["tablename"] = "";
                                    if ($($this).closest("tr.groupTR").length == 0) {
                                        filtervalues["data"] = $($this).closest("tr").find("td")[j].textContent.trim();
                                        filtervalues["datevalue"] = $($this).closest("tr").find("td")[j].textContent.trim();
                                        filterArray.push(filtervalues);
                                    }
                                }
                            });
                        }
                    }
                    else {
                        filtervalues["dataType"] = details["textcolumns"][j].dataType;
                        filtervalues["tablename"] = "";
                        filtervalues["columndisplayname"] = details["textcolumns"][j].displayName;
                        filtervalues["columnname"] = details["textcolumns"][j].columnName;
                        var customFieldObj = $.grep(customFields || [], function (e) { return e.name == details["textcolumns"][j].columnName }) || [];
                        if ($($this).closest("tr.groupTR").length == 0) {
                            filtervalues["data"] = $($this).closest("tr").find("td")[j].textContent.trim();
                            filtervalues["datevalue"] = $($this).closest("tr").find("td")[j].textContent.trim();
                            if (customFieldObj.length > 0) {
                                let customObject = customFieldObj[0];
                                var sqlFilterObject = {};
                                sqlFilterObject["sqldata"] = $($this).closest("tr").find("td")[j].textContent.trim();
                                customObject["sqlcondition"] = sqlFilterObject;
                                customObject["parameter"] = $($this).closest("tr").find("td")[j].textContent.trim();
                                filtervalues["customfield"] = customObject;
                                var scriptFilterCondition = customObject['scriptcondition'];
                                customObject['scriptcondition'] = "; if((" + scriptFilterCondition + ").equals(parameter)) return true else return false";
                                if (filtervalues["dataType"] == "date") {
                                    customObject['scriptcondition'] = "; if(new Date(" + scriptFilterCondition + ")==new Date(parameter)) return true else return false";
                                    if (customObject["parameter"].length > 0) {
                                        var newDate = customObject["parameter"];//Date.parse(customObject["parameter"].replace(/-/g,'/'));
                                        if (isNaN(newDate)) {
                                            //var newdate1=moment(myDate,dateFormat);
                                            customObject["parameter"] = customObject["parameter"];
                                        }
                                        else {
                                            customObject["parameter"] = newDate;//new Date(newDate).getTime();
                                        }
                                    }
                                }
                                if (filtervalues["dataType"] == "number") {
                                    customObject['scriptcondition'] = "; if((" + scriptFilterCondition + ")==(parameter)) return true else return false";
                                }
                            }
                            filterArray.push(filtervalues);
                        }
                    }

                });
            }
            if (chartDetails["summarytablegroupbys"] != undefined && chartDetails["summarytablegroupbys"] != null) {
                var groupNo = $($this).parent().attr('group-no');
                let parsedColumns = JSON.parse(that.props.columns);
                var groupName = $('.groupTR[group-no=' + groupNo + '][data-num=' + chartnumber + ']').attr('group-name');
                var Object1 = $.grep(parsedColumns, function (e) { return e.columnDisplayName == groupName.split(':')[0] });
                var customFieldObj = $.grep(customFields || [], function (e) { return e.name == groupName.split(':')[0] }) || [];
                var filterObject = {};
                if (Object1 != undefined && Object1.length > 0 && Object1[0] != undefined) {
                    filterObject["dataType"] = Object1[0].tableDisplayType;
                    filterObject["columndisplayname"] = Object1[0].columnDisplayName;
                    filterObject["columnname"] = Object1[0].columnName;
                    filterObject["data"] = groupName.split(": ")[1].trim();
                    filterObject["datevalue"] = groupName.split(": ")[1].trim();
                    filterArray.push(filterObject);
                } else if (customFieldObj.length > 0) {
                    let customObject = customFieldObj[0];
                    var sqlFilterObject = {};
                    sqlFilterObject["sqldata"] = groupName.split(": ")[1].trim();
                    customObject["sqlcondition"] = sqlFilterObject;
                    customObject["parameter"] = groupName.split(": ")[1].trim();
                    filterObject["customfield"] = customObject;
                    filterObject["dataType"] = customFieldObj[0].dataType;
                    filterObject["columndisplayname"] = customFieldObj[0].name;
                    filterObject["columnname"] = customFieldObj[0].name;
                    filterObject["data"] = groupName.split(": ")[1].trim();
                    filterObject["datevalue"] = groupName.split(": ")[1].trim();
                    var scriptFilterCondition = customObject['scriptcondition'];
                    customObject['scriptcondition'] = "; if((" + scriptFilterCondition + ").equals(parameter)) return true else return false";
                    if (filterObject["dataType"] == "date") {
                        customObject['scriptcondition'] = "; if(new Date(" + scriptFilterCondition + ")==new Date(parameter)) return true else return false";
                        if (customObject["parameter"].length > 0) {
                            var newDate = customObject["parameter"];//Date.parse(customObject["parameter"].replace(/-/g,'/'));
                            if (isNaN(newDate)) {
                                //var newdate1=moment(myDate,dateFormat);
                                customObject["parameter"] = customObject["parameter"];
                            }
                            else {
                                customObject["parameter"] = newDate;//new Date(newDate).getTime();
                            }
                        }
                    }
                    if (filterObject["dataType"] == "number") {
                        customObject['scriptcondition'] = "; if((" + scriptFilterCondition + ")==(parameter)) return true else return false";
                    }
                    filterArray.push(filterObject);
                }
            }
            if (this.state.dobyfilterArray == undefined || this.state.dobyfilterArray.length == 0) {

            }
            else {
                filterArray = this.state.dobyfilterArray.concat(filterArray);
            }
            var globalFilterArray = [];
        globalFilterArray = ChartInfo.functions.generateFilterWithEmbededFilters(globalFilterArray, undefined);
        if (globalFilterArray != null && globalFilterArray[0] != undefined) {
            _.each(globalFilterArray, function (value, index) {
                filterArray.push(value);
            });
        }
        filterArray = filterArray.concat(ChartInfo.functions.getFilterArray());
            $(".drilldowncheckbox:checked").each(function () {
                let columnObj = {}
                columnObj["visible"] = "yes";
                columnObj["tableAliasName"] = $(this).attr("data-parent");
                columnObj["columnDisplayName"] = $(this).attr("data-name");
                columnObj["orginalColumnName"] = $(this).attr("data-originalname");
                columnObj["tableDisplayType"] = $(this).attr("data-type");
                columnObj["name"] = $(this).attr("data-columnname");
                columnObj["tableDisplayName"] = $(this).attr("data-parent");
                columnObj["type"] = $(this).attr("data-type");
                columnObj["tablename"] = $(this).attr("data-parent");
                columnObj["tableName"] = $(this).attr("data-parent");
                columnObj["columnName"] = $(this).attr("data-columnname");
                columnsArray.push(columnObj)

            });
            var tempArrayval = [];
            if (customFields != null && customFields != "null" && customFields != undefined) {
                $(".drilldowncheckbox:checked").each(function () {
                    var thatval = this;
                    tempArrayval = tempArrayval.concat($.grep(customFields, function (e) {
                        return e.name === $(thatval).attr('data-name')
                    }));
                })
            } else {
                tempArrayval = customFields;
            }
            ReactDOM.unmountComponentAtNode(document.querySelector("#smartinsightchoosefielddiv.smartinsightchoosefielddiv" + this.chartnumber));
            window.localStorage.setItem('smartinsights-columnsdata', JSON.stringify(columnsArray));
            window.localStorage.setItem('smartinsights-filtersdata', JSON.stringify(filterArray))
            window.localStorage.setItem('smartinsights-targetColumn', that.drilldownyAxisSeries);
            window.localStorage.setItem('smartinsights-customFieldata', JSON.stringify(tempArrayval));
            window.localStorage.setItem('isStoryboardSmartInsight', true);
            var that = this;
            let requestHeaders = {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            var bodyParams = new FormData();
            bodyParams.set("datamodelid", that.datamodelId);
            bodyParams.set("columns", JSON.stringify(columnsArray));
            bodyParams.set("type", "report");
            SendRequest({
                url: "createsmartreportfromstoryboard",
                body: bodyParams,
                sucessFunction: response => {
                    var tempReportId = response['tempreportid'];
                    // sessionStorage.setItem("isSharedModel" + datamodelId, isShared);
                    var location = window.location;
                    var href = location.origin + "/" + window.localStorage.getItem("appName") + "/welcome/viewsmartinsight/" + btoa(that.datamodelId);
                    window.open(href, '_blank');
                },
                rejectFunction: error => { },
                headers: requestHeaders
            });
        }
        console.log("run summarysmartinsights");
    }
}