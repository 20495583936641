import React from 'react';
import { lang } from "../js/helpers/utils/languages/language";
import _ from 'underscore';
const $ = window.$;
export default class WebDataConnection extends React.Component {
    componentDidMount() {
        if (this.props.connectionjson != undefined) {
            if (this.props.connectionjson.method == "get") {
                $("#webdatarequestmethod1").val(this.props.connectionjson.method).prop("checked", true);

            } else if (this.props.connectionjson.method == "post") {
                $("#webdatarequestmethod2").val(this.props.connectionjson.method).prop("checked", true);

            }
            if (this.props.connectionjson.authmethod == "get") {
                $("#authdatarequestmethod1").val(this.props.connectionjson.authmethod).prop("checked", true);
            } else if (this.props.connectionjson.authmethod == "post") {
                $("#authdatarequestmethod2").val(this.props.connectionjson.authmethod).prop("checked", true);
            }
            if (this.props.connectionjson.authenticationtype == "2step") {
                $('.twostepwebdata').show();
            }
            if (this.props.connectionjson["parameters"] != undefined) {
                if (this.props.connectionjson["parameters"].length > 0) {
                    _.each(this.props.connectionjson["parameters"], (value, index) => {
                        var webkey = Object.keys(this.props.connectionjson["parameters"][index]);
                        var webValue = Object.values(this.props.connectionjson["parameters"][index]);
                        $('.append_webdataconnectorsdiv').append('<div class="control-group form-inline marg-top-10 webdataconnectorqueryparameterdiv">' +
                            '<div class="validationgroup form-group col-xs-6">' +
                            '<input type="text" class="selectdatasyncdaterange form-control input-sm webdataqueryparameterkey" value="' + webkey + '" style="width:100%">' +
                            '<span class="fa-help-light zendeskhelpblock hide"></span>' +
                            '</div>' +
                            '<div class="validationgroup form-group col-xs-5">' +
                            '<input type="password" class="selectdatasyncdaterange form-control input-sm webdataqueryparametervalue" value="' + webValue + '" style="width:100%">' +
                            '<span class="fa-help-light zendeskhelpblock hide"></span>' +
                            '</div>' +
                            '<div class="col-xs-1">' +
                            '<button type="submit" class="btn btn-transparent marg-righ-10 dropdown-toggle tooltip-top removewebdataconnectorparameter" data-tooltip=' + lang["datahub.remove"] + '>' +
                            '	<i class="fa fa-minus-circle"></i>' +
                            '</button>' +
                            '</div>' +
                            '</div>');
                        $(".removewebdataconnectorparameter").click(function (e) {
                            e.preventDefault();
                            var $this = e.currentTarget;
                            $($this).parent().parent().remove();
                        })
                    });
                }
            }
            if (this.props.connectionjson["headers"] != undefined) {
                if (this.props.connectionjson["headers"].length > 0) {
                    _.each(this.props.connectionjson["headers"], (value, index) => {
                        var webkey = Object.keys(this.props.connectionjson["headers"][index]);
                        var webValue = Object.values(this.props.connectionjson["headers"][index]);
                        $('.append_webdataconnectorheadersdiv').append('<div class="control-group form-inline marg-top-10 webdataconnectorheaderparameterdiv">' +
                            '<div class="validationgroup form-group col-xs-6">' +
                            '<input type="text" class="selectdatasyncdaterange form-control input-sm webdataheaderparameterkey" value="' + webkey + '" style="width:100%">' +
                            '<span class="fa-help-light zendeskhelpblock hide"></span>' +
                            '</div>' +
                            '<div class="validationgroup form-group col-xs-5">' +
                            '<input type="password" class="selectdatasyncdaterange form-control input-sm webdataheaderparametervalue" value="' + webValue + '" style="width:100%">' +
                            '<span class="fa-help-light zendeskhelpblock hide"></span>' +
                            '</div>' +
                            '<div class="col-xs-1">' +
                            '<button type="submit" class="btn btn-transparent marg-righ-10 dropdown-toggle tooltip-top removewebdataconnectorheaderparameter" data-tooltip=' + lang["datahub.remove"] + '>' +
                            '	<i class="fa fa-minus-circle"></i>' +
                            '</button>' +
                            '</div>' +
                            '</div>');
                        $(".removewebdataconnectorheaderparameter").click(function (e) {
                            e.preventDefault();
                            var $this = e.currentTarget;
                            $($this).parent().parent().remove();
                        })
                    });
                }
            }
        }
    }
    render() {
        var stepu2sernameKey = "";
        var stepu2pwdKey = "";
        var stepu2sernameValue = "";
        var stepu2pwdValue = "";
        var authtype = "none";

        if (this.props.connectionjson != undefined) {
            authtype = this.props.connectionjson.authenticationtype;
            if (this.props.connectionjson["twostepAuthParameters"] != undefined) {
                if (this.props.connectionjson["twostepAuthParameters"].length > 0) {
                    stepu2sernameKey = Object.keys(this.props.connectionjson["twostepAuthParameters"][0]);
                    stepu2sernameValue = Object.values(this.props.connectionjson["twostepAuthParameters"][0]);
                    if (this.props.connectionjson["twostepAuthParameters"].length > 1) {
                        stepu2pwdKey = Object.keys(this.props.connectionjson["twostepAuthParameters"][1]);
                        stepu2pwdValue = Object.values(this.props.connectionjson["twostepAuthParameters"][1]);
                    }
                }
            }
        }

        return (
            <>
                {/* <!-- webdataconnectionview.js --> */}
                <div className="modal-header boc-modal-hf-padding">
                    <h4 className="modal-title">
                        <i className="fa fa-link marg-righ-10"></i>
                        {lang["datahub.filestitle"]}
                    </h4>
                </div>
                <div className="modal-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xs-12" id="webdataconnectorcontainer" style={{ border: 0 }}>
                                {/* <!-- <h3>Connect MySQL</h3> --> */}
                                <form className="form-horizontal">
                                    <div className="form-group form-group-sm">
                                        <label htmlFor="" className="col-sm-4 control-label ">
                                            <img className="pull-right" src={require(`../images/birdimages/${this.props.schemaName.toLowerCase()}.png`)} />
                                        </label>
                                        <div className="col-sm-6">
                                            <h3>{this.props.schemaDisplayName}</h3>
                                        </div>
                                    </div>
                                    <div className="form-group form-group-sm required">
                                        <label htmlFor="" className="col-sm-4 control-label">{lang["datahub.cnctnname"]}</label>
                                        <div className="col-sm-6">
                                            <input
                                                type="text"
                                                className="form-control connectionname"
                                                placeholder=""
                                                defaultValue={this.props.connectionjson !== undefined ? this.props.connectionjson.connectionname : ''} />
                                            <span id="connectionname" className="help-block hide"></span>
                                        </div>
                                    </div>
                                    <div className="form-group form-group-sm">
                                        <label for="webdataconnectorurl" className="col-sm-4 control-label important">{lang["datahub.weburl"]}</label>
                                        <div className="col-sm-6">
                                            <input
                                                type="text"
                                                className="form-control webdataconnectorurl"
                                                name="webdataconnectorurl"
                                                id="webdataconnectorurlscopes"
                                                defaultValue={this.props.connectionjson !== undefined ? this.props.connectionjson.host : ''}
                                            />
                                            <span className="fa-help-light zendeskhelpblock hide"></span>
                                        </div>
                                    </div>
                                    <div className="form-group form-group-sm">
                                        <label for="webdataconnectorendpoint" id="webdataconnectorendpoints" className="col-sm-4 control-label">{lang["datahub.endpoint"]}</label>
                                        <div className="col-sm-6">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="webdataconnectorendpoint"
                                                name="webdataconnectorendpoint"
                                                defaultValue={this.props.connectionjson !== undefined ? this.props.connectionjson.endpoint : ''}
                                            />
                                            <span className="fa-help-light zendeskhelpblock hide"></span>
                                        </div>
                                    </div>
                                    <div className="form-group form-group-sm">
                                        <label className="col-sm-4 control-label important">{lang["datahub.rqstformat"]}</label>
                                        <div className="col-sm-6">
                                            <div className="webdataconnectorreqformat">
                                                <label for="webdataconnectorsrequestformat3" className="control-label">
                                                    <input
                                                        type="radio"
                                                        id="webdataconnectorsrequestformat3"
                                                        name="webdataconnectorsrequestformat"
                                                        defaultValue="json"
                                                        checked="checked"
                                                    /> {lang["datahub.json"]}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group form-group-sm">
                                        <label className="col-sm-4 control-label important">{lang["datahub.rqstmetod"]}</label>
                                        <div className="col-sm-6">
                                            <div className="webdataconnectorreqmethod">
                                                <label htmlFor="webdatarequestmethod1" className="control-label marg-righ-10">
                                                    <input
                                                        type="radio"
                                                        className="webdatarequestmethod"
                                                        id="webdatarequestmethod1"
                                                        name="webdatarequestmethod"
                                                        defaultValue="get"
                                                        defaultChecked
                                                        onClick={this.props.webdatarequestmethod}
                                                    /> {lang["datahub.get"]}
                                                </label>
                                                <label htmlFor="webdatarequestmethod2" className="control-label">
                                                    <input
                                                        type="radio"
                                                        className="webdatarequestmethod"
                                                        id="webdatarequestmethod2"
                                                        name="webdatarequestmethod"
                                                        defaultValue="post"

                                                        onClick={this.props.webdatarequestmethod}
                                                    /> {lang["datahub.post"]}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-offset-4 col-xs-8 webdataheaderparameters nopadding">
                                        <div className="panel panel-default panel-webdata">
                                            <div className="panel-heading">
                                                <h4 className="panel-title">
                                                    <a data-toggle="collapse" href="#headerParameterCollapse" id="headerParameterscopes">{lang["datahub.headerpmts"]}</a>
                                                </h4>
                                            </div>
                                            <div id="headerParameterCollapse" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <div className="col-sm-12">
                                                        <button type="submit" className="btn btn-bird btn-sm addwebdataconnectorheaderparameter" onClick={this.props.addwebdataconnectorheaderparameter}>
                                                            <i className="fa fa-plus-circle fa-1x "></i> {lang["datahub.addpmtr"]}
                                                        </button>
                                                        <div className="control-group form-inline marg-top-10 append_webdataconnectorheadersdiv">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-offset-4 col-xs-8 webdataqueryparameters nopadding">
                                        <div className="panel panel-default panel-webdata">
                                            <div className="panel-heading">
                                                <h4 className="panel-title">
                                                    <a data-toggle="collapse" href="#queryParameterCollapse" id="ueryParameterscopes">{lang["datahub.querypmts"]}</a>
                                                </h4>
                                            </div>
                                            <div id="queryParameterCollapse" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <div className="col-sm-12">
                                                        <button type="submit" className="btn btn-bird btn-sm addwebdataconnectorparameter" onClick={this.props.addwebdataconnectorparameter}>
                                                            <i className="fa fa-plus-circle fa-1x "></i> {lang["datahub.addpmtr"]}
                                                        </button>
                                                        <div className="control-group form-inline marg-top-10 append_webdataconnectorsdiv">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-offset-4 col-xs-8 webdataqueryformdata nopadding" style={{ display: "none" }}>
                                        <div className="panel panel-default panel-webdata">
                                            <div className="panel-heading">
                                                <h4 className="panel-title">
                                                    <a data-toggle="collapse" href="#queryFormDataCollapse" id="dataformdata">{lang["datahub.formdata"]}</a>
                                                </h4>
                                            </div>
                                            <div id="queryFormDataCollapse" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <div className="control-group">
                                                        <textarea className="form-control resize-vertical form-data"></textarea>
                                                        <span className="fa-help-light zendeskhelpblock hide"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group form-group-sm">
                                        <label htmlFor="webdataauthentication" className="col-sm-4 control-label">{lang["datahub.auth"]}</label>
                                        <div className="col-sm-4">
                                            <select className="form-control webdataauthentication" id="webdataauthenticationscopes" onChange={this.props.webdataauthentication}>
                                                {(authtype == 'none') ? (
                                                    <>  <option value="none" selected>{lang["datahub.none"]}</option>
                                                        <option value="ntlm">{lang["datahub.windauth"]}</option>
                                                        <option value="2step">{lang["datahub.2stpauth"]}</option></>
                                                ) :
                                                    (authtype == 'ntlm') ? (
                                                        <>  <option value="none">{lang["datahub.none"]}</option>
                                                            <option value="ntlm" selected>{lang["datahub.windauth"]}</option>
                                                            <option value="2step">{lang["datahub.2stpauth"]}</option></>
                                                    ) :
                                                        (authtype == '2step') ? (
                                                            <>  <option value="none">{lang["datahub.none"]}</option>
                                                                <option value="ntlm">{lang["datahub.windauth"]}</option>
                                                                <option value="2step" selected>{lang["datahub.2stpauth"]}</option></>
                                                        ) :
                                                            <>  <option value="none" selected>{lang["datahub.none"]}</option>
                                                                <option value="ntlm">{lang["datahub.windauth"]}</option>
                                                                <option value="2step">{lang["datahub.2stpauth"]}</option></>

                                                }
                                            </select>
                                            <span className="fa-help-light zendeskhelpblock hide"></span>
                                        </div>
                                    </div>
                                    {/* <!-- Windows Authentication --> */}
                                    <div className="webdataauthenticate windowswebdata" style={{ margin: "0 auto", float: "none", display: "none" }}>
                                        <div className="form-group form-group-sm">
                                            <label htmlFor="windowswebdatausername" className="col-sm-4 control-label important">{lang["datahub.username"]}</label>
                                            <div className="col-sm-6">
                                                <input
                                                    type="text"
                                                    className="form-control windowswebdatausername"
                                                    name="windowswebdatausername"
                                                    id="windowswebdatausernamescopes"
                                                    defaultValue=""
                                                />
                                                <span className="fa-help-light zendeskhelpblock hide"></span>
                                            </div>
                                        </div>
                                        <div className="form-group form-group-sm">
                                            <label for="windowswebdatapassword" className="col-sm-4 control-label important">{lang["datahub.pwd"]}</label>
                                            <div className="col-sm-6">
                                                <input
                                                    type="password"
                                                    className="form-control windowswebdatapassword"
                                                    name="windowswebdatapassword"
                                                    id="windowswebdatapasswordscopes"
                                                    defaultValue=""
                                                />
                                                <span className="fa-help-light zendeskhelpblock hide"></span>
                                            </div>
                                        </div>
                                        <div className="form-group form-group-sm">
                                            <label for="windowswebdatadomain" className="col-sm-4 control-label important">{lang["datahub.domain"]}</label>
                                            <div className="col-sm-6">
                                                <input
                                                    type="text"
                                                    className="form-control windowswebdatadomain"
                                                    name="windowswebdatadomain"
                                                    id="windowswebdatadomainscopes"
                                                    defaultValue=""
                                                />
                                                <span className="fa-help-light zendeskhelpblock hide"></span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!--/ Windows Authentication --> */}

                                    {/* <!-- 2 Step Authentication --> */}
                                    <div className="webdataauthenticate twostepwebdata" style={{ margin: "0 auto", float: "none", display: "none" }}>
                                        <div className="form-group form-group-sm">
                                            <label for="twostepwebdataurl" className="col-sm-4 control-label important">{lang["datahub.authurl"]}</label>
                                            <div className="col-sm-6">
                                                <input
                                                    type="text"
                                                    className="form-control twostepwebdataurl"
                                                    name="twostepwebdataurl"
                                                    defaultValue={this.props.connectionjson !== undefined ? this.props.connectionjson.twostepurl : ''}
                                                />
                                                <span className="fa-help-light zendeskhelpblock hide"></span>
                                            </div>
                                        </div>
                                        <div className="form-group form-group-sm">
                                            <label for="twosteptokenname" className="col-sm-4 control-label important">{lang["datahub.tokename"]}</label>
                                            <div className="col-sm-6">
                                                <input
                                                    type="text"
                                                    className="form-control tokenname"
                                                    name="twosteptokenname"
                                                    id="twosteptokennamescopes"
                                                    defaultValue={this.props.connectionjson !== undefined ? this.props.connectionjson.twoStepTokenName : ''}
                                                />
                                                <span className="fa-help-light zendeskhelpblock hide"></span>
                                            </div>
                                        </div>
                                        <div className="form-group form-group-sm">
                                            <label for="twostepapikey" className="col-sm-4 control-label important">{lang["datahub.apikey"]}</label>
                                            <div className="col-sm-6">
                                                <input
                                                    type="text"
                                                    className="form-control apikey"
                                                    name="twostepapikey"
                                                    id="twostepapiscopes"
                                                    defaultValue={this.props.connectionjson !== undefined ? this.props.connectionjson.twoStepApiKey : ''}
                                                />
                                                <span className="fa-help-light zendeskhelpblock hide"></span>
                                            </div>
                                        </div>
                                        <div className="form-group form-group-sm">
                                            <label className="col-sm-4 control-label important">{lang["datahub.rqstmetod"]}</label>
                                            <div className="col-sm-6">
                                                <div className="authconnectorreqmethod">
                                                    <label for="authdatarequestmethod1" className="control-label marg-righ-10">
                                                        <input
                                                            type="radio"
                                                            className="authrequestmethod"
                                                            id="authdatarequestmethod1"
                                                            name="authdatarequestmethod"
                                                            defaultValue="get"
                                                            defaultChecked
                                                        />{lang["datahub.get"]}
                                                    </label>
                                                    <label for="authdatarequestmethod2" className="control-label">
                                                        <input
                                                            type="radio"
                                                            className="authrequestmethod"
                                                            id="authdatarequestmethod2"
                                                            name="authdatarequestmethod"
                                                            defaultValue="post"

                                                        /> {lang["datahub.post"]}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-offset-4 col-xs-8 nopadding">
                                            <div className="panel panel-default panel-webdata">
                                                <div className="panel-heading">
                                                    <h4 className="panel-title">
                                                        <a data-toggle="collapse" href="#authenticateParameterCollapse" id="authenticateParameterscopes">{lang["datahub.authpmts"]}</a>
                                                    </h4>
                                                </div>
                                                <div id="authenticateParameterCollapse" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                        <div className="col-sm-12 nopadding">
                                                            <button type="submit" className="btn btn-bird btn-sm addwebdataconnectorparameterauth" id="addparameterscopes" onClick={this.props.addwebdataconnectorparameterauth}>
                                                                <i className="fa fa-plus-circle fa-1x "></i> {lang["datahub.addpmtr"]}
                                                            </button>
                                                            <div className="control-group form-inline marg-top-10 append_webdataconnectorsdivauth">
                                                                {/* <!-- Dynamic Content --> */}
                                                                <div className="control-group form-inline marg-top-10 webdataconnectorqueryparameterdivauth">
                                                                    <div className="validationgroup form-group col-xs-5">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control input-sm 2stepauthparameter"
                                                                            defaultValue={stepu2sernameKey !== undefined ? stepu2sernameKey : ''}
                                                                            style={{ width: "100%" }}
                                                                            placeholder={lang["datahub.paramtr"]}
                                                                        />
                                                                        <span className="fa-help-light zendeskhelpblock hide"></span>
                                                                    </div>
                                                                    <div className="validationgroup form-group col-xs-4">
                                                                        <input
                                                                            type="password"
                                                                            className="form-control input-sm 2stepauthparametervalue"
                                                                            defaultValue={stepu2sernameValue !== undefined ? stepu2sernameValue : ''}
                                                                            style={{ width: "100%" }}
                                                                            placeholder={lang["datahub.value"]}
                                                                        />
                                                                        <span className="fa-help-light zendeskhelpblock hide"></span>
                                                                    </div>

                                                                    <div className="col-xs-1">
                                                                        <button type="submit" className="btn btn-transparent marg-righ-10 dropdown-toggle tooltip-top removewebdataconnectorparameter" id="removewebdataconnectorparameterscopes2" data-tooltip={lang["datahub.remove"]} onClick={this.props.removewebdataconnectorparameter}>
                                                                            <i className="fa fa-minus-circle"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className="control-group form-inline marg-top-10 webdataconnectorqueryparameterdivauth">
                                                                    <div className="validationgroup form-group col-xs-5">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control input-sm 2stepauthparameter"
                                                                            defaultValue={stepu2pwdKey !== undefined ? stepu2pwdKey : ''}
                                                                            style={{ width: "100%" }}
                                                                            placeholder={lang["datahub.paramtr"]}
                                                                        />
                                                                        <span className="fa-help-light zendeskhelpblock hide"></span>
                                                                    </div>
                                                                    <div className="validationgroup form-group col-xs-4">
                                                                        <input
                                                                            type="password"
                                                                            className="form-control input-sm 2stepauthparametervalue"
                                                                            defaultValue={stepu2pwdValue !== undefined ? stepu2pwdValue : ''}
                                                                            style={{ width: "100%" }}
                                                                            placeholder={lang["datahub.value"]}
                                                                        />
                                                                        <span className="fa-help-light zendeskhelpblock hide"></span>
                                                                    </div>

                                                                    <div className="col-xs-1">
                                                                        <button type="submit" className="btn btn-transparent marg-righ-10 dropdown-toggle tooltip-top removewebdataconnectorparameter" id="removewebdataconnectorparameterscopes2" data-tooltip={lang["datahub.remove"]} onClick={this.props.removewebdataconnectorparameter}>
                                                                            <i className="fa fa-minus-circle"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                {/* <!--/ Dynamic Content --> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!--/ 2 Step Authentication --> */}
                                    {/* <!-- <div className="form-group form-group-sm">
						<div className="col-sm-4"></div>
						<div className="col-sm-6">
							<button type="submit" className="btn btn-bird pull-left esvalidate" id="esvalidatescopes">Validate</button>
						</div>
					</div> --> */}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer marg-top-20">
                    <button type="button" className="btn btn-bird pull-left esvalidate" id="esvalidatescopes" onClick={this.props.testconnection}>
                        <i className="fa fa-play"></i>{lang["datahub.validatebtn"]}
                    </button>
                    <button type="button" className="btn btn-bird" id="closeconnectionpop" onClick={this.props.close}>
                        <i className="fa fa-times"></i> {lang["datahub.canclbtn"]}
                    </button>
                    <button type="button" className="btn btn-bird active savewebdataconnection" id="savenewconnection" onClick={this.props.savewebdataconnection}>
                        <i className="fa fa-floppy-o"></i> {lang["datahub.savebtn"]}
                    </button>
                </div>
                {/* <!-- /webdataconnectionview.js --> */}
            </>
        );
    }
}