import React, { Component } from 'react';
import { lang } from "../js/helpers/utils/languages/language";
import ReactDOM from 'react-dom'
const $ = window.$;

export default class StoryboardColorThemeModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.render();
	}
	cancelColorThemeModal = (e) => {
		if (sessionStorage.getItem('stroyboardcolortheme') == "") {
			$('.boc-viewreport-title[data-num=storyboard]')
				.css('color', '#424242');
			$('.storyboarddatacount').css('color',
				'#424242');
			// Storyboard background
			$('.leftpane').css('background-color',
				'#FFFFFF');
			$('.maxanim1').css('background-color',
				'#FFFFFF');
			$('.maxanim2').css('background-color',
				'#FFFFFF');
			// Storyboard background
			$('#reportheader .controlbar-main').css('background-color',
				'#ebebeb');
			// Storyboard footer background
			$('.footer').css('background-color', '#e2e3e4');
			// storyboard footer text
			$('.text-muted').css('color', '#424242');
		} else {
			var storyboardcolortheme = JSON
				.parse(sessionStorage
					.getItem('stroyboardcolortheme'))[0];
			if (storyboardcolortheme.storyboarddatacount == 'show'
				|| storyboardcolortheme.storyboarddatacount == undefined)
				$('.storyboarddatacount').removeClass(
					'hide');
			else
				$('.storyboarddatacount').addClass('hide');
			// Stroyboard titlecolor
			if (storyboardcolortheme.storyboardtitlecolor == "") {
				$(
					'.boc-viewreport-title[data-num=storyboard]')
					.css('color', '#424242');
				$('.storyboarddatacount').css('color',
					'#424242');
			} else {
				$(
					'.boc-viewreport-title[data-num=storyboard]')
					.css(
						'color',
						storyboardcolortheme.storyboardtitlecolor);
				$('.storyboarddatacount')
					.css(
						'color',
						storyboardcolortheme.storyboardtitlecolor);
			}
			// Storyboard backgroundcolor
			if (storyboardcolortheme.storyboardbackground == "") {
				$('.leftpane').css('background-color',
					'#FFFFFF');
				$('.maxanim1').css('background-color',
					'#FFFFFF');
				$('.maxanim2').css('background-color',
					'#FFFFFF');

			} else {
				$('.leftpane')
					.css(
						'background-color',
						storyboardcolortheme.storyboardbackground);
				$('.maxanim1')
					.css(
						'background-color',
						storyboardcolortheme.storyboardbackground);
				$('.maxanim2')
					.css(
						'background-color',
						storyboardcolortheme.storyboardbackground);
			}
			if (storyboardcolortheme.storyboardheaderbackground == '') {
				$('#reportheader .controlbar-main').css(
					'background-color', '#ebebeb');
			} else {
				$('#reportheader .controlbar-main')
					.css(
						'background-color',
						storyboardcolortheme.storyboardheaderbackground);
			}
			if (storyboardcolortheme.storyboardfooterbackground == '')
				$('.footer').css('background-color',
					'#e2e3e4');
			else
				$('.footer')
					.css(
						'background-color',
						storyboardcolortheme.storyboardfooterbackground);
			if (storyboardcolortheme.storyboardfootertext == "")
				$('.text-muted').css('color', '#424242');
			else
				$('.text-muted')
					.css(
						'color',
						storyboardcolortheme.storyboardfootertext);
		}
		ReactDOM.unmountComponentAtNode(document.querySelector('.addstoryboardcolorthememodal'))
	}
	render() {
		ReactDOM.render(< StoryboardColorThemeModalTemplate storyboarddatacount={this.storyboarddatacount} applystoryboardcolortheme={this.applystoryboardcolortheme} cancelColorThemeModal={this.cancelColorThemeModal} chartnumber={this.props.chartnumber} />, document.querySelector('.addstoryboardcolorthememodal'));
		$('.theme-storyboard-title').colorpicker({ format: "rgba" }).on('changeColor.colorpicker', function (event) {
			var $this = event.currentTarget;
			$('.boc-viewreport-title[data-num=storyboard]').css('color', $($this).children('input.form-control').val());
			$('.storyboarddatacount').css('color', $($this).children('input.form-control').val());
		});

		$('.theme-storyboard-background').colorpicker({ format: "rgba" }).on('changeColor.colorpicker', function (event) {
			var $this = event.currentTarget;
			$('.leftpane').css('background-color', $($this).children('input.form-control').val());
			$('.maxanim1').css('background-color', $($this).children('input.form-control').val());
			$('.maxanim2').css('background-color', $($this).children('input.form-control').val());
			//	$('.fullscreensample').css('background-color',event.color.toHex());
		});

		$('.theme-storyboard-header-background').colorpicker({ format: "rgba" }).on('changeColor.colorpicker', function (event) {
			var $this = event.currentTarget;
			$('#reportheader .controlbar-main').css('background-color', $($this).children('input.form-control').val());
		});
		$('.theme-storyboard-footer-background').colorpicker({ format: "rgba" }).on('changeColor.colorpicker', function (event) {
			var $this = event.currentTarget;
			$('.footer').css('background-color', $($this).children('input.form-control').val());
		});
		$('.theme-storyboard-footer-text').colorpicker({ format: "rgba" }).on('changeColor.colorpicker', function (event) {
			var $this = event.currentTarget;
			$('.text-muted').css('color', $($this).children('input.form-control').val());
		});
		if (sessionStorage.getItem('stroyboardcolortheme') != "") {
			var stroyboardcolortheme = JSON.parse(sessionStorage.getItem('stroyboardcolortheme'))[0];

			$('.storyboarddatacountvisibility[data-name=' + stroyboardcolortheme.storyboarddatacount + ']').prop('checked', true);
			$('.storyboardtoolbarvisibility[data-name=' + stroyboardcolortheme.storyboardtoolbar + ']').prop('checked', true);
			if (stroyboardcolortheme.storyboardtitlecolor == "") { }
			else
				$('.theme-storyboard-title').colorpicker('setValue', stroyboardcolortheme.storyboardtitlecolor);
			if (stroyboardcolortheme.storyboardheaderbackground == "") { }
			else
				$('.theme-storyboard-header-background').colorpicker('setValue', stroyboardcolortheme.storyboardheaderbackground);
			if (stroyboardcolortheme.storyboardbackground == "") { }
			else
				$('.theme-storyboard-background').colorpicker('setValue', stroyboardcolortheme.storyboardbackground);
			if (stroyboardcolortheme.storyboardfooterbackground == "") { }
			else
				$('.theme-storyboard-footer-background').colorpicker('setValue', stroyboardcolortheme.storyboardfooterbackground);
			if (stroyboardcolortheme.storyboardfootertext == "") { }
			else
				$('.theme-storyboard-footer-text').colorpicker('setValue', stroyboardcolortheme.storyboardfootertext);
		}
		return (<div></div>);
	}
	applystoryboardcolortheme = (e) => {
		sessionStorage.setItem("changesdone", 1);
		var temparray = {};
		var reportthemeobject = [];
		temparray['storyboarddatacount'] = $('.storyboarddatacountvisibility:checked').attr('data-name');
		temparray['storyboardtoolbar'] = $('.storyboardtoolbarvisibility:checked').attr('data-name');
		temparray['storyboardtitlecolor'] = $('.theme-storyboard-title input').val();
		temparray['storyboardheaderbackground'] = $('.theme-storyboard-header-background input').val();
		temparray['storyboardbackground'] = $('.theme-storyboard-background input').val();
		temparray['storyboardfooterbackground'] = $('.theme-storyboard-footer-background input').val();
		temparray['storyboardfootertext'] = $('.theme-storyboard-footer-text input').val();
		reportthemeobject.push(temparray);
		sessionStorage.setItem('stroyboardcolortheme', JSON.stringify(reportthemeobject));
		ReactDOM.unmountComponentAtNode(document.querySelector('.addstoryboardcolorthememodal'))
	}
	storyboarddatacount=(e)=>{
		var $this = e.currentTarget;
		if ($($this).attr('data-name') == 'show') {
			$('.storyboarddatacount').removeClass('hide');
		}
		if ($($this).attr('data-name') == 'hide') {
			$('.storyboarddatacount').addClass('hide');
		}
	}
}

class StoryboardColorThemeModalTemplate extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		var that = this.props;
		return (
			// <!-- Storyboard Theme Modal Start
			//     storyboardcolorthememodal.js
			// -->
			<div className="modal fade in" id="storyboardtheme" tabIndex="-1" role="dialog" aria-labelledby="storyboardtheme" aria-hidden="true" style={{ display: "block" }}>
				<div className="modal-backdrop fade in"></div>
				<div className="modal-dialog modal-sm modal-sm-400 modal-dialog-sm-center modal-dialog-center" style={{ zIndex: "1050" }}>
					<div className="modal-content">
						<div className="modal-header boc-modal-hf-padding">
							<button type="button" className="close white" id="colorthemeclose" onClick={that.cancelColorThemeModal} aria-hidden="true" data-dismiss="modal">&times;</button>
							<h4 className="modal-title">
								<i className="fa fa-paint-brush fa-violet"></i> {lang["storyboard.boardtheme"]}
                </h4>
						</div>
						<div className="modal-body scrollset">
							<div className="container-fluid">
								<div className="row filtercontent">
									<div className="col-xs-12 nopadding">
										<legend className="subhead">{lang["storyboard.datacounttitle"]}</legend>
										<div className="marg-left-10">
											<label>
												<input type="radio" value="1" className="control-form storyboarddatacountvisibility" onClick={that.storyboarddatacount} id="storyboarddatacountshow" data-name="show" name="datacountvisibility" />
                            &nbsp;{lang["storyboard.show"]}&nbsp;&nbsp;
                        </label>
											<label>
												<input type="radio" value="2" className="control-form storyboarddatacountvisibility" onClick={that.storyboarddatacount} id="storyboarddatacounthide" data-name="hide" name="datacountvisibility" defaultChecked="checked" />
                            &nbsp;{lang["storyboard.hide"]}
                        </label>
										</div>
									</div>
									{/* <div className="col-xs-12 nopadding">
						<legend className="subhead">Toolbar</legend>
						<div className="marg-left-10">
							<label> <input type="radio" value="1" className="control-form storyboardtoolbarvisibility" id="storyboardtoolbarfull" checked="checked" data-name="full" name="storyboardtoolbar" />&nbsp;Full&nbsp;&nbsp; </label>
						<label>	<input type="radio" value="2" className="control-form storyboardtoolbarvisibility" id="storyboardtoolbarhamburger" data-name="hamburger" name="storyboardtoolbar" />&nbsp;Hamburger </label>
						</div>
						</div> */}
									<div className="col-xs-12 nopadding">
										<legend className="subhead">{lang["storyboard.titlecolor"]}</legend>
										<div className="col-xs-12 nopadding">
											<div className="input-group theme-storyboard-title">
												<input type="text" placeholder={lang["storyboard.placeholdtitle"]} className="form-control" id="storyboardtitles" />
												<span className="input-group-addon" id="storyboardtitlescolor"><i style={{ backgroundColor: "#424242" }}></i></span>
											</div>
										</div>
									</div>
									<div className="col-xs-12 nopadding">
										<legend className="subhead marg-top-5">{lang["storyboard.header"]}</legend>
										<div className="col-xs-12 nopadding">
											<div className="input-group theme-storyboard-header-background">
												<input type="text" placeholder={lang["storyboard.placeholdheader"]} className="form-control" id="headerbackgrounds" />
												<span className="input-group-addon" id="headerbackgroundscolor"><i style={{ backgroundColor: "#ebebeb" }}></i></span>
											</div>
										</div>
									</div>
									<div className="col-xs-12 nopadding">
										<legend className="subhead marg-top-5">{lang["storyboard.background"]}</legend>
										<div className="col-xs-12 nopadding">
											<div className="input-group theme-storyboard-background">
												<input type="text" placeholder={lang["storyboard.placeholdbackgrnd"]} className="form-control" id="storyboardbackgrounds" />
												<span className="input-group-addon" id="storyboardbackgroundscolor"><i style={{ backgroundColor: "#ffffff" }}></i></span>
											</div>
										</div>
									</div>
									<div className="col-xs-12 nopadding">
										<legend className="subhead marg-top-5">{lang["storyboard.footer"]}</legend>
										<div className="col-xs-12 nopadding">
											<div className="input-group theme-storyboard-footer-background">
												<input type="text" placeholder={lang["storyboard.placeholdfooter"]} className="form-control" id="footerbackgrounds" />
												<span className="input-group-addon" id="footerbackgroundscolor"><i style={{ backgroundColor: "#e2e3e4" }}></i></span>
											</div>
										</div>
									</div>
									<div className="col-xs-12 nopadding">
										<legend className="subhead marg-top-5">{lang["storyboard.footertext"]} </legend>
										<div className="col-xs-12 nopadding">
											<div className="input-group theme-storyboard-footer-text">
												<input type="text" placeholder={lang["storyboard.placeholdfoottext"]} className="form-control" id="footertexts" />
												<span className="input-group-addon" id="footertextscolor"><i style={{ backgroundColor: "#424242" }}></i></span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="modal-footer boc-modal-hf-padding">
							<button type="button" className="btn btn-bird" onClick={that.cancelColorThemeModal} id="cancelstoryboardcolortheme" ><i className="fa fa-times"></i> {lang["storyboard.cancelbtn"]}</button>
							<button type="button" className="btn btn-bird" onClick={that.applystoryboardcolortheme} id="applystoryboardcolortheme" data-num={that.chartnumber} tab-id="0" tab-name=""><i className="fa fa-check"></i> {lang["storyboard.applybtn"]}</button>
						</div>
					</div>
					{/* <!-- /.modal-content --> */}
				</div>
				{/* <!-- /.modal-dialog --> */}
			</div>
			// <!-- /.Page Settings Modal -->
			// <!-- Storyboard Theme Modal End -->
		);
	}
}