import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'underscore';
import PubSub from 'pubsub-js'
import { DataSet, Network } from 'vis';
import { getMessage } from '../js/helpers/utils/BirdMessage';
import SendRequest from '../SendRequest';
import DatamodelsHtml from './DatamodelsHtml.jsx';
import DatamodelExistingEntitiesHtml from './DatamodelExistingEntitiesHtml.jsx';
import { lang } from "../js/helpers/utils/languages/language";
import DatamodelsConnections from './DatamodelsConnections';
import FactTableReferencesHtml from './FactTableReferencesHtml.jsx';
import Savedatamodel from './Savedatamodel';
import DatamodelItemSettings from './DatamodelItemSettings';
import "bootstrap-multiselect/dist/js/bootstrap-multiselect.js";
import DataModelNewItemSettings from './Datamodelnewconitemsettings';
import * as workspaceExistingEntityMetaDataCollection from "./WorkspaceExisitngEntityMetaDataCollection";
import { format } from 'sql-formatter';
import * as ChartInfo from "../js/helpers/utils/chartinfo";
const $ = window.$;
const ace = require('brace');

export default class Datamodels extends React.Component {
    constructor(props) {
        $(".spinner").show()
        super(props);
        this.state = {
            dataModelMetaData: {}
        }
        this.workspaceExistingEntityMetaDataCollection = workspaceExistingEntityMetaDataCollection;
        this.workSpaceEntityMetaDataCollection = workspaceExistingEntityMetaDataCollection;
        if (this.props !== undefined) {
            this.modelId = this.props.match.params.modelId;
        }
        var reg = new RegExp('^[0-9]+$');
        if (this.modelId != "" && this.modelId != undefined && !reg.test(this.modelId)) {
            this.modelId = atob(this.modelId);
        }
        this.isEdit = false;
        this.displayFooter = true;
        this.dataModelName = undefined;
        this.dataModelDesc = undefined;
        this.metadataentities = [];
        this.metadatajoins = [];
        this.custqueentities = {};
        this.wscqnames = [];
        this.wscsqQueries = {};
        this.connections = {};
        this.availableworkspaces = [];
    }
    getRequestHeaders = {
        method: "get",
        headers: new Headers({
            "Content-Type": "application/json"
        })
    }
    postRequestHeaders = {
        method: "post",
        headers: new Headers({
            "Content-Type": "application/json"
        })
    }
    UNSAFE_componentWillMount() {
        document.body.className = '';
        document.body.classList.add('day', 'datamodelspage');
    }
    datamodelMetaDataCollection = {
        resetCollection: () => {
            this.metadataentities = [];
            this.metadatajoins = [];
        },
        updateMetadataEntities: (entitiesArray) => {
            this.metadataentities = entitiesArray;
        },
        updateMetadataJoins: (joinsArray) => {
            this.metadatajoins = joinsArray;
        },
        getMetadataEntities: () => {
            return this.metadataentities;
        },
        getMetadataJoins: () => {
            return this.metadatajoins;
        }
    }
    workspaceCustomQueryCollection = {
        resetCollection: () => {
            this.custqueentities = {};
            this.wscqnames = [];
            this.wscsqQueries = {};
        },
        updatewsCustomQueryEntityColumns: (columns, entityName, connectionId) => {
            this.custqueentities[entityName + "&&&" + connectionId] = columns;
        },
        getwsCustomQueryEntityColumns: (entityName, connectionId) => {
            if (this.custqueentities[entityName + "&&&" + connectionId] == undefined) {
                this.custqueentities[entityName + "&&&" + connectionId] = {};
                return undefined;
            }
            else {
                return this.custqueentities[entityName + "&&&" + connectionId];
            }
        },
        updatewsCustomQueryQueries: (query, entityName, connectionId) => {
            this.wscsqQueries[entityName + "&&&" + connectionId] = query;
        },
        getwsCustomQueryQueries: (entityName, connectionId) => {
            if (this.wscsqQueries[entityName + "&&&" + connectionId] == undefined) {
                this.wscsqQueries[entityName + "&&&" + connectionId] = {};
                return undefined;
            }
            else {
                return this.wscsqQueries[entityName + "&&&" + connectionId];
            }
        },
        addWsCustomQueryName: (name) => {
            this.wscqnames.push(name);
        },
        getWsCustomQueryNames: () => {
            return this.wscqnames;
        }
    }
    dataModelConnectionsDetailsCollection = {
        resetCollecion: () => {
            this.connections = {};
            this.availableworkspaces = [];
        },
        updateDataModelConnections: (entityname, conassId, connectiondata) => {
            var data = {};
            var text = connectiondata.connectionname;
            var conjosn = {};
            if (connectiondata.connectionjson != undefined) {
                conjosn = JSON.parse(connectiondata.connectionjson);
                text = conjosn.connectionname;
            }
            data['text'] = text;
            data['connectionName'] = connectiondata.connectionname.toLowerCase();
            data['value'] = connectiondata.connectionAccessId;
            data['contype'] = connectiondata.connectionType;
            data['selected'] = false;
            data['schemaId'] = connectiondata.connectionId;
            data['connectionjson'] = connectiondata.connectionjson;
            data['description'] = connectiondata.connectionname;

            this.connections[entityname + "&&&" + conassId] = data;
        },
        getDataModelConnectionData: (entityname, conassId) => {
            return this.connections[entityname + "&&&" + conassId];
        },
        deleteDataModelConnectionData: (entityname, conassId) => {
            delete this.connections[entityname + "&&&" + conassId];
        },
        getAllDataModelConnectionData: () => {
            return this.connections;
        },
        updateAvailableWorkSpacesData: (workspaces) => {
            this.availableworkspaces = workspaces;
        },
        getAvailableWorkSpacesData: () => {
            return this.availableworkspaces;
        }
    }

    componentDidMount() {
        $(".spinner").show();
        var that = this;
        $("#datamodelsstepscontainer").steps({
            headerTag: "h3",
            bodyTag: "section",
            transitionEffect: "slideLeft",
            enableKeyNavigation: false,
            autoFocus: true,
            labels: { finish: 'Save' },
            onStepChanging: (event, currentIndex, newIndex) => {
                if (currentIndex == 0 && newIndex == 1 || currentIndex === 2 && newIndex === 3) { } else { }

                if (currentIndex == 0 && newIndex == 1) {
                    var tables = {};
                    var factObj = {};
                    if ($('#savescustomquery').length > 0) {
                        that.vaidatequery(event, true);
                        if ($('.applydatamodelsymanticnames').length > 0) {
                            var emptyNameCount = 0;
                            var emptyNameCountTemp = 0;
                            $("#datamodelsentitycontainer>li").removeClass('warning');
                            _.each($("#datamodelsentitycontainer li"), function (ent, index) {
                                var entity = $(ent).find("input.scopeitem");
                                if ($(entity).hasClass("datamodelcustomqueryscope") && ($(entity).attr("dmcqname") == undefined || $(entity).attr("dmcqname") == "")) {
                                    $(ent).removeClass("active");
                                    $(ent).addClass("warning");
                                    emptyNameCount++;
                                }
                            });
                            if (emptyNameCount > 0) {
                                $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-infor').addClass('birdmessage-warning');
                                $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-info-circle').addClass('fa-exclamation-triangle');
                                $('.birdmessagebuttons').removeClass('show');
                                $('.birdmessagebuttons').addClass('hide');
                                $('.birdmessage-center').addClass("message-top-in");
                                $('.details').removeClass('show');
                                $('.details').addClass('hide');
                                $('.birdmessage h2').empty().append('Warning');
                                $('.birdmessage-info').empty().text(getMessage('BirdWarning135'));
                                return false;
                            }
                        }
                        if ($('.applydatamodelsymanticnames').length > 0) {
                            $('.applydatamodelsymanticnames').click();
                        }
                    } else {
                        if ($('.applydatamodelsymanticnames').length > 0) {
                            $('.applydatamodelsymanticnames').click();
                        }
                        if ($("#datamodelsentitycontainer li").length > 0) {
                            var emptyNameCount = 0;
                            var emptyNameCountTemp = 0;
                            $("#datamodelsentitycontainer>li").removeClass('warning');
                            _.each($("#datamodelsentitycontainer li"), function (ent, index) {
                                var entity = $(ent).find("input.scopeitem");
                                if ($(entity).hasClass("datamodelcustomqueryscope") && ($(entity).attr("dmcqname") == undefined || $(entity).attr("dmcqname") == "")) {
                                    $(ent).removeClass("active");
                                    $(ent).addClass("warning");
                                    emptyNameCount++;
                                }
                                if ($(entity).hasClass("mysqltablevaluetemp")) {
                                    $(ent).removeClass("active");
                                    $(ent).addClass("warning");
                                    emptyNameCountTemp++;
                                }
                            });
                            if (emptyNameCount > 0) {
                                $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-infor').addClass('birdmessage-warning');
                                $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-info-circle').addClass('fa-exclamation-triangle');
                                $('.birdmessagebuttons').removeClass('show');
                                $('.birdmessagebuttons').addClass('hide');
                                $('.birdmessage-center').addClass("message-top-in");
                                $('.details').removeClass('show');
                                $('.details').addClass('hide');
                                $('.birdmessage h2').empty().append('Warning');
                                $('.birdmessage-info').empty().text(getMessage('BirdWarning135'));
                                return false;
                            }
                            if (emptyNameCountTemp > 0) {
                                $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-infor').addClass('birdmessage-warning');
                                $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-info-circle').addClass('fa-exclamation-triangle');
                                $('.birdmessagebuttons').removeClass('show');
                                $('.birdmessagebuttons').addClass('hide');
                                $('.birdmessage-center').addClass("message-top-in");
                                $('.details').removeClass('show');
                                $('.details').addClass('hide');
                                $('.birdmessage h2').empty().append('Warning');
                                $('.birdmessage-info').empty().text(getMessage('BirdWarning146'));
                                return false;
                            }

                            var isSpecialFound = false;
                            _.each($("#datamodelsentitycontainer li").find("input.scopeitem"), (entity) => {
                                var workspaceentityid = $(entity).attr("data_parent");
                                var entityname = $(entity).attr("data-value");
                                var entityObject = that.getEntityObjectForJoins(workspaceentityid, entityname);
                                _.each(entityObject, function (entObj) {
                                    if (entObj != undefined && entObj.name != undefined && (/[!@#$%^&*()+\=\[\]{};':\\|,.<>\/?"]/.test(entObj.name)) ||
                                        (entObj.name.toLowerCase().trim() == "min" || entObj.name.toLowerCase().trim() == "max"
                                            || entObj.name.toLowerCase().trim() == "avg" || entObj.name.toLowerCase().trim() == "sum"
                                            || entObj.name.toLowerCase().trim() == "count" || entObj.name.toLowerCase().trim() == "avgerage")) {
                                        isSpecialFound = true;
                                        if ($("#datamodelsentitycontainer li").find("input.scopeitem[data_parent=" + workspaceentityid + "]").hasClass("datamodelcustomqueryscope")) {
                                            $("#datamodelsentitycontainer li").find("input.scopeitem[data_parent=" + workspaceentityid + "]").parent().parent().parent().addClass("warning");
                                        } else {
                                            $("#datamodelsentitycontainer li").find("input.scopeitem[data_parent=" + workspaceentityid + "]").parent().parent().addClass("warning");
                                        }
                                        return;
                                    }
                                });
                            });
                            if (isSpecialFound) {
                                $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-infor').addClass('birdmessage-warning');
                                $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-info-circle').addClass('fa-exclamation-triangle');
                                $('.birdmessagebuttons').removeClass('show');
                                $('.birdmessagebuttons').addClass('hide');
                                $('.birdmessage-center').addClass("message-top-in");
                                $('.details').removeClass('show');
                                $('.details').addClass('hide');
                                $('.birdmessage h2').empty().append('Warning');
                                $('.birdmessage-info').empty().text(getMessage('BirdInformation46'));
                                return false;
                            }
                        }
                    }
                    if ($("#datamodelsentitycontainer li").find("input.scopeitem") != undefined && $("#datamodelsentitycontainer li").find("input.scopeitem").length == 0) {
                        $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-infor').addClass('birdmessage-warning');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-info-circle').addClass('fa-exclamation-triangle');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Warning');
                        $('.birdmessage-info').empty().text(getMessage('BirdWarning134'));
                        return;
                    }
                    if (that.isEdit) {
                        if (that.datamodelMetaDataCollection.getMetadataJoins().length > 0 || that.datamodelMetaDataCollection.getMetadataJoins() != undefined) {
                            var joinsArray = that.datamodelMetaDataCollection.getMetadataJoins();
                            //$("#datamodelfactreferencecontainer").empty()
                            $("#datamodelfactreferencecontainer>div").remove();
                            $("#datamodelfactreferencecontainer>.intromessage").removeClass("hide");
                            if (joinsArray != undefined && joinsArray.length > 0) {
                                $("#datamodelfactreferencecontainer>.intromessage").addClass("hide");
                                _.each(joinsArray, (val, index) => {
                                    _.each($("#datamodelsentitycontainer li").find("input.scopeitem"), function (entity) {
                                        var workspaceentityid = $(entity).attr("data_parent");
                                        var entityname = $(entity).attr("data-value");
                                        var entityObject = that.getEntityObjectForJoins(workspaceentityid, entityname);
                                        var isFact = that.getEntityFact(workspaceentityid, entityname);
                                        tables[entityname] = entityObject;

                                        if (isFact == '1') {
                                            factObj[entityname] = entityObject;
                                        }
                                    });
                                    _.each(tables, function (i) {
                                        var sortOrder = ["number", "string", "date"];
                                        i.sort(function (a, b) {
                                            return sortOrder.indexOf(a.type) - sortOrder.indexOf(b.type);
                                        });
                                    });
                                    $('#datamodelfactreferencecontainer').append('<div id=' + "factreference" + index + '></div>');
                                    ReactDOM.render(<FactTableReferencesHtml xlsjoinclose={this.xlsjoinclose} relations={[{}]} tables={tables} id={index} entityRelationId={val.dataModelRelationId} factTables={factObj} />, document.getElementById('factreference' + index));
                                    $(".xls-connect-primary-one[data-num='" + index + "'][target=primary]").text(val.primaryTable)
                                    $(".xls-connect-primary-one[data-num='" + index + "'][target=secondary]").text(val.secondaryTable)
                                    $(".xls-select-primary[data-num='" + index + "'] option[tablename='" + val.primaryTable + "'][columnname='" + val.primaryColumn + "']").attr("selected", true);
                                    $(".xls-select-connected[data-num='" + index + "'] option[tablename='" + val.secondaryTable + "'][columnname='" + val.secondaryColumn + "']").attr("selected", true);

                                    that.generateddslick(val.joinType);
                                })
                                that.$el = $("#main");
                                that.$el.find(".xls-select-primary, .xls-select-connected").multiselect({
                                    enableCaseInsensitiveFiltering: true,
                                    enableFiltering: true,
                                    enableHTML: true,
                                    templates: {
                                        filterClearBtn: '<span class="input-group-btn"><button class="btn btn-default multiselect-clear-filter" type="button"><i class="fa fa-times"></i></button></span>',
                                    },
                                    optionLabel: function (element) {
                                        var attrType = $(element).attr('columntype');
                                        var colName = $(element).attr('columnname');
                                        if (attrType == "string") {
                                            return '<span class="itemtype" data-type="string" data-name="' + colName + '">' + lang["models.abc"] + '</span>' + colName;
                                        } else if (attrType == "number") {
                                            return '<span class="itemtype" data-type="number" data-name="' + colName + '">123</span>' + colName;
                                        } else if (attrType == "date") {
                                            return '<span class="itemtype" data-type="date" data-name="' + colName + '"><i class="fa fa-calendar"></i></span>' + colName;
                                        } else {
                                            return '' + $(element).attr('columnname');
                                        }

                                    }
                                });
                                _.each(that.$el.find("#datamodelfactreferencecontainer span.multiselect-native-select li.multiselect-item.multiselect-group"), function (i) {
                                    $(i).find('label>b').remove("i.fa");
                                    $(i).find('label>b').prepend('<i class="fa fa-table marg-left-5"></i>');
                                });
                                _.each(that.$el.find("#datamodelfactreferencecontainer span.multiselect-native-select li:not(.multiselect-item.multiselect-group)"), function (i) {
                                    $(i).find('label').attr("title", $(i).find('.itemtype').attr('data-name'));
                                });
                            }
                        }
                    }

                    if (!that.isEdit) {
                        var references = [];
                        _.each($("#datamodelfactreferencecontainer .row"), function (item) {
                            var primaryTableName = $(item).find("#primaryselectdrop option:selected").attr('tablename');
                            var primaryColumnName = $(item).find("#primaryselectdrop option:selected").attr("columnname");
                            var primaryWorspaceEntityId = $(item).find("#primaryselectdrop option:selected").attr("data-parent");

                            var jointype = $(item).find("#jointypedefine .dd-option-selected").text().trim();

                            var relationId = $(item).find("#primaryselectdrop").attr("entity_RelationId");

                            var secondaryTableName = $(item).find("#selectconnectdropdown option:selected").attr('tablename');
                            var secondaryColumnName = $(item).find("#selectconnectdropdown option:selected").attr("columnname");
                            var secondaryWorspaceEntityId = $(item).find("#selectconnectdropdown option:selected").attr("data-parent");


                            var refernceObject = {};
                            refernceObject['primarytable'] = primaryTableName;
                            refernceObject['primarycolumn'] = primaryColumnName;
                            refernceObject['primaryworkspaceentityid'] = primaryWorspaceEntityId;
                            refernceObject['jointype'] = jointype;
                            refernceObject['relationId'] = relationId;

                            refernceObject['secondarytable'] = secondaryTableName;
                            refernceObject['secondarycolumn'] = secondaryColumnName;
                            refernceObject['secondaryworkspaceentityid'] = secondaryWorspaceEntityId;

                            references.push(refernceObject);

                        });

                        var joinsArray = references;

                        //$("#datamodelfactreferencecontainer").empty();
                        $("#datamodelfactreferencecontainer>div").remove();
                        $("#datamodelfactreferencecontainer>.intromessage").removeClass("hide");
                        if (joinsArray != undefined && joinsArray.length > 0) {
                            $("#datamodelfactreferencecontainer>.intromessage").addClass("hide");
                            _.each(joinsArray, (val, index) => {
                                _.each($("#datamodelsentitycontainer li").find("input.scopeitem"), function (entity) {
                                    var workspaceentityid = $(entity).attr("data_parent");
                                    var entityname = $(entity).attr("data-value");
                                    var entityObject = that.getEntityObjectForJoins(workspaceentityid, entityname);
                                    var isFact = that.getEntityFact(workspaceentityid, entityname);
                                    tables[entityname] = entityObject;

                                    if (isFact == '1') {
                                        factObj[entityname] = entityObject;
                                    }
                                });
                                _.each(tables, function (i) {
                                    var sortOrder = ["number", "string", "date"];
                                    i.sort(function (a, b) {
                                        return sortOrder.indexOf(a.type) - sortOrder.indexOf(b.type);
                                    });
                                });
                                $('#datamodelfactreferencecontainer').append('<div id=' + "factreference" + index + '></div>');
                                ReactDOM.render(<FactTableReferencesHtml xlsjoinclose={this.xlsjoinclose} relations={[{}]} tables={tables} id={index} entityRelationId={val.dataModelRelationId} factTables={factObj} />, document.getElementById('factreference' + index));
                                $(".xls-connect-primary-one[data-num='" + index + "'][target=primary]").text(val.primarytable)
                                $(".xls-connect-primary-one[data-num='" + index + "'][target=secondary]").text(val.secondarytable)
                                $(".xls-select-primary[data-num='" + index + "'] option[tablename='" + val.primarytable + "'][columnname='" + val.primarycolumn + "']").attr("selected", true);
                                $(".xls-select-connected[data-num='" + index + "'] option[tablename='" + val.secondarytable + "'][columnname='" + val.secondarycolumn + "']").attr("selected", true);

                                that.generateddslick(val.jointype);
                            })
                            that.$el = $("#main");
                            that.$el.find(".xls-select-primary, .xls-select-connected").multiselect({
                                enableCaseInsensitiveFiltering: true,
                                enableFiltering: true,
                                enableHTML: true,
                                templates: {
                                    filterClearBtn: '<span class="input-group-btn"><button class="btn btn-default multiselect-clear-filter" type="button"><i class="fa fa-times"></i></button></span>',
                                },
                                optionLabel: function (element) {
                                    var attrType = $(element).attr('columntype');
                                    var colName = $(element).attr('columnname');
                                    if (attrType == "string") {
                                        return '<span class="itemtype" data-type="string" data-name="' + colName + '">' + lang["models.abc"] + '</span>' + colName;
                                    } else if (attrType == "number") {
                                        return '<span class="itemtype" data-type="number" data-name="' + colName + '">123</span>' + colName;
                                    } else if (attrType == "date") {
                                        return '<span class="itemtype" data-type="date" data-name="' + colName + '"><i class="fa fa-calendar"></i></span>' + colName;
                                    } else {
                                        return '' + $(element).attr('columnname');
                                    }

                                }
                            });
                            _.each(that.$el.find("#datamodelfactreferencecontainer span.multiselect-native-select li.multiselect-item.multiselect-group"), function (i) {
                                $(i).find('label>b').remove("i.fa");
                                $(i).find('label>b').prepend('<i class="fa fa-table marg-left-5"></i>');
                            });
                            _.each(that.$el.find("#datamodelfactreferencecontainer span.multiselect-native-select li:not(.multiselect-item.multiselect-group)"), function (i) {
                                $(i).find('label').attr("title", $(i).find('.itemtype').attr('data-name'));
                            });
                        }
                    }
                }

                if (currentIndex == 1 && newIndex == 2) {
                    //Sankey

                    var data = [];
                    var nodes = [];
                    var edges = [];
                    var entities = [];
                    _.each($("#datamodelfactreferencecontainer .row"), function (item, i) {
                        //console.log($(item).find("#primaryselectdrop option:selected").attr('tablename'));
                        //console.log($(item).find("#primaryselectdrop option:selected").attr("columnname"));

                        var primaryTableName = $(item).find("#primaryselectdrop option:selected").attr('tablename');
                        var primaryColumnName = $(item).find("#primaryselectdrop option:selected").attr("columnname");
                        var primaryWorspaceEntityId = $(item).find("#primaryselectdrop option:selected").attr("data-parent");
                        var primaryIsFact = $(item).find("#primaryselectdrop option:selected").attr("data_fact");

                        var jointype = $(item).find("#jointypedefine .dd-option-selected").text().trim();

                        var secondaryTableName = $(item).find("#selectconnectdropdown option:selected").attr('tablename');
                        var secondaryColumnName = $(item).find("#selectconnectdropdown option:selected").attr("columnname");
                        var secondaryWorspaceEntityId = $(item).find("#selectconnectdropdown option:selected").attr("data-parent");
                        var secondaryIsFact = $(item).find("#selectconnectdropdown option:selected").attr("data_fact");

                        //var innerArray = {primaryTableName:primaryTableName,primaryColumnName:primaryColumnName,secondaryTableName:secondaryTableName,secondaryColumnName:secondaryColumnName,data:[secondaryTableName,primaryTableName,1]};
                        //var innerArray = [secondaryTableName,primaryTableName,1];
                        //data.push(innerArray);

                        var idPrimaryExists = 0,
                            idSecondaryExists = 0,
                            pfactcolor = { border: '#9d00ff', background: '#fff' },
                            pfactfont = { color: '#666' },
                            sfactcolor = { border: '#9d00ff', background: '#fff' },
                            sfactfont = { color: '#666' };


                        if (nodes.length > 0) {
                            _.each(nodes, function (i) {
                                if (i.id != primaryTableName) {
                                    //idPrimaryExists=0;
                                } else {
                                    idPrimaryExists = 1;
                                }
                            })
                            if (idPrimaryExists == 0) {
                                if (primaryIsFact == 0) { pfactcolor = null; pfactfont = null; }
                                var svg =
                                    '<svg xmlns="http://www.w3.org/2000/svg" width="100px" height="20px" style="padding: 0px;background: #fff;">' +
                                    '<rect x="5px" y="0" width="100%" height="100%" fill="#fff" stroke-width="1" style="stroke: #999;width: calc(100% - 5px);"></rect>' +
                                    '<rect x="0" y="0" width="6px" height="100%" fill="#9d00ff" stroke-width="0"></rect>' +
                                    '<foreignObject x="10px" y="3px" width="90%" height="100%">' +
                                    '<div xmlns="http://www.w3.org/1999/xhtml" style="font-size: 10px;">' +
                                    '<span style="color: #999;">' + primaryTableName + '</span>' +
                                    '</div>' +
                                    '</foreignObject>' +
                                    '</svg>'
                                var url = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(svg);

                                var node = { image: url, shape: "image", id: primaryTableName, label: primaryTableName, color: pfactcolor, font: pfactfont, margin: { top: 10, right: 20, bottom: 10, left: 20 }, x: 0, y: 0 }
                                nodes.push(node);
                            }
                            _.each(nodes, function (i) {
                                if (i.id != secondaryTableName) {
                                    //idSecondaryExists=0;
                                } else {
                                    idSecondaryExists = 1;
                                }
                            })
                            if (idSecondaryExists == 0) {
                                if (secondaryIsFact == 0) { sfactcolor = null; sfactfont = null; }
                                var svg =
                                    '<svg xmlns="http://www.w3.org/2000/svg" width="100px" height="20px" style="padding: 0px;background: #fff;">' +
                                    '<rect x="5px" y="0" width="100%" height="100%" fill="#fff" stroke-width="1" style="stroke: #999;width: calc(100% - 5px);"></rect>' +
                                    '<rect x="0" y="0" width="6px" height="100%" fill="#00bcd4" stroke-width="0"></rect>' +
                                    '<foreignObject x="10px" y="3px" width="90%" height="100%">' +
                                    '<div xmlns="http://www.w3.org/1999/xhtml" style="font-size: 10px;">' +
                                    '<span style="color: #999;">' + secondaryTableName + '</span>' +
                                    '</div>' +
                                    '</foreignObject>' +
                                    '</svg>'
                                var url = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(svg);

                                var node2 = { image: url, shape: "image", id: secondaryTableName, label: secondaryTableName, color: sfactcolor, font: sfactfont, margin: { top: 10, right: 20, bottom: 10, left: 20 }, x: 0, y: 0 }
                                nodes.push(node2);
                            }
                        } else {
                            if (primaryTableName === secondaryTableName) {
                                if (primaryIsFact == 0) { pfactcolor = null; pfactfont = null; }
                                var svg =
                                    '<svg xmlns="http://www.w3.org/2000/svg" width="100px" height="20px" style="padding: 0px;background: #fff;">' +
                                    '<rect x="5px" y="0" width="100%" height="100%" fill="#fff" stroke-width="1" style="stroke: #999;width: calc(100% - 5px);"></rect>' +
                                    '<rect x="0" y="0" width="6px" height="100%" fill="#9d00ff" stroke-width="0"></rect>' +
                                    '<foreignObject x="10px" y="3px" width="90%" height="100%">' +
                                    '<div xmlns="http://www.w3.org/1999/xhtml" style="font-size: 10px;">' +
                                    '<span style="color: #999;">' + primaryTableName + '</span>' +
                                    '</div>' +
                                    '</foreignObject>' +
                                    '</svg>'
                                var url = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(svg);

                                var node = { image: url, shape: "image", id: primaryTableName, label: primaryTableName, color: pfactcolor, font: pfactfont, margin: { top: 10, right: 20, bottom: 10, left: 20 }, x: 0, y: 0 }
                                nodes.push(node);
                            } else {
                                if (primaryIsFact == 0) { pfactcolor = null; pfactfont = null; }
                                if (secondaryIsFact == 0) { sfactcolor = null; sfactfont = null; }
                                var svg =
                                    '<svg xmlns="http://www.w3.org/2000/svg" width="100px" height="20px" style="padding: 0px;background: #fff;">' +
                                    '<rect x="5px" y="0" width="100%" height="100%" fill="#fff" stroke-width="1" style="stroke: #999;width: calc(100% - 5px);"></rect>' +
                                    '<rect x="0" y="0" width="6px" height="100%" fill="#9d00ff" stroke-width="0"></rect>' +
                                    '<foreignObject x="10px" y="3px" width="90%" height="100%">' +
                                    '<div xmlns="http://www.w3.org/1999/xhtml" style="font-size: 10px;">' +
                                    '<span style="color: #999;">' + primaryTableName + '</span>' +
                                    '</div>' +
                                    '</foreignObject>' +
                                    '</svg>'
                                var url1 = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(svg);

                                var svg =
                                    '<svg xmlns="http://www.w3.org/2000/svg" width="100px" height="20px" style="padding: 0px;background: #fff;">' +
                                    '<rect x="5px" y="0" width="100%" height="100%" fill="#fff" stroke-width="1" style="stroke: #999;width: calc(100% - 5px);"></rect>' +
                                    '<rect x="0" y="0" width="6px" height="100%" fill="#00bcd4" stroke-width="0"></rect>' +
                                    '<foreignObject x="10px" y="3px" width="90%" height="100%">' +
                                    '<div xmlns="http://www.w3.org/1999/xhtml" style="font-size: 10px;">' +
                                    '<span style="color: #999;">' + secondaryTableName + '</span>' +
                                    '</div>' +
                                    '</foreignObject>' +
                                    '</svg>'
                                var url2 = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(svg);


                                var node = { image: url1, shape: "image", id: primaryTableName, label: primaryTableName, color: pfactcolor, font: pfactfont, margin: { top: 10, right: 20, bottom: 10, left: 20 }, x: 0, y: 0 }
                                var node2 = { image: url2, shape: "image", id: secondaryTableName, label: secondaryTableName, color: sfactcolor, font: sfactfont, margin: { top: 10, right: 20, bottom: 10, left: 20 }, x: 0, y: 0 }
                                nodes.push(node);
                                nodes.push(node2);
                            }
                        }
                        var edge = { from: secondaryTableName, to: primaryTableName }
                        edges.push(edge);

                    });

                    setTimeout(function () {
                        var chartid = 'datamodelpreviewcontainer';

                        $("#datamodelpreviewcontainer").empty();
                        if ($("#datamodelfactreferencecontainer .row") != undefined && $("#datamodelfactreferencecontainer .row").length >= 0) {
                            var entities = [];
                            _.each($("#datamodelsentitycontainer li").find("input.scopeitem"), function (entity) {
                                var entityname = $(entity).attr("data-value");
                                var id = entityname;
                                /*if(entities.indexOf(entityname)>=0){
                                    const result = entities.filter(i => i == entityname).length;
                                    id = entityname+""+(result+1);
                                }*/
                                entities.push(entityname);
                                var idPrimaryExists = 0;
                                _.each(nodes, function (i) {
                                    if (i.id != entityname) {
                                        //idPrimaryExists=0;
                                    } else {
                                        idPrimaryExists = 1;
                                    }
                                })
                                if (idPrimaryExists == 0) {
                                    var svg =
                                        '<svg xmlns="http://www.w3.org/2000/svg" width="100px" height="20px" style="padding: 0px;background: #fff;">' +
                                        '<rect x="5px" y="0" width="100%" height="100%" fill="#fff" stroke-width="1" style="stroke: #eee;width: calc(100% - 5px);"></rect>' +
                                        '<rect x="0" y="0" width="6px" height="100%" fill="#00bcd4" stroke-width="0"></rect>' +
                                        '<foreignObject x="10px" y="3px" width="90%" height="100%">' +
                                        '<div xmlns="http://www.w3.org/1999/xhtml" style="font-size: 10px;">' +
                                        '<span style="color: #999;">' + entityname + '</span>' +
                                        '</div>' +
                                        '</foreignObject>' +
                                        '</svg>'
                                    var url = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(svg);
                                    var node = { image: url, shape: "image", id: id, label: entityname, margin: { top: 10, right: 20, bottom: 10, left: 20 }, x: 0, y: 0 }
                                    nodes.push(node);
                                }

                            });
                        }

                        //Start Visualization
                        var Nodes = new DataSet(nodes);
                        var Edges = new DataSet(edges);
                        var data = {
                            nodes: Nodes,
                            edges: Edges
                        };
                        var container = document.getElementById("datamodelpreviewcontainer");
                        var options = {
                            layout: {
                                /*hierarchical: {
                                    enabled: true,
                                    direction: "LR",
                                    levelSeparation: 500
                                }*/
                                hierarchical: {
                                    enabled: true,
                                    nodeSpacing: 425,
                                    blockShifting: false,
                                    edgeMinimization: false,
                                    sortMethod: "directed",
                                    direction: "RL",
                                    levelSeparation: 500
                                }
                            },
                            /*physics: {
                                    hierarchicalRepulsion: {
                                        nodeDistance: 300
                                    }
                            },*/
                            edges: {
                                color: {
                                    color: '#999'
                                }
                            },
                            nodes: {
                                shape: "square",
                                shapeProperties: {
                                    borderRadius: 0,
                                    useImageSize: false,
                                    useBorderWithImage: false,
                                },
                                // imagePadding: {
                                //     left: 1,
                                //     top: 1,
                                //     bottom: 1,
                                //     right: 1
                                //   },
                                widthConstraint: {
                                    minimum: 100,
                                    //maximum: 100
                                },
                                borderWidth: 0,
                                color: {
                                    border: '#999',
                                    background: '#fff',
                                },
                                font: {
                                    color: '#666',
                                    size: 0
                                }
                            },
                        };
                        var network = new Network(container, data, options);


                        //ChartUtils.drawdataModelSankeyChart(chartid,data);
                    }, 500);
                }
                return true
            },
            onStepChanged: (event, currentIndex, newIndex) => {
                if (currentIndex == 0) {
                    $("#datamodelsentitycontainer li").removeClass('active');
                    $("#settingscontainer").removeClass("showsettings");
                    $("#datamodelsentitycontainer").removeClass('settingsopened');
                    $("#datamodelsconnectionscontainer").w2layout('resize');
                }
            },
            onFinishing: (event, currentIndex) => {
                that.saveDataModel(event, currentIndex);
            },
            onFinished: (event, currentIndex) => {
                return
            }
        });
        var width;
        if ($(window).width() < 800) { width = '150'; }
        else { width = '300'; }
        $("#datamodelsconnectionscontainer").w2destroy('splitter');
        $("#datamodelsconnectionscontainer").w2layout({
            name: 'splitter',
            padding: 5,
            panels: [
                { type: 'left', size: width, resizable: true, style: this.pstyle, content: "<div id='datamodelsconnectionslist'></div>" },
                { type: 'main', style: this.pstyle, content: "<div id='datamodelsentitycontainer'></div>" },
            ]
        });
        ReactDOM.render(
            <DatamodelsConnections
                editdatamodelitem={this.editdatamodelitem}
                joinremove={this.joinremove}
                workspaceExistingEntityCollection={that.workspaceExistingEntityMetaDataCollection}
                workspaceCustomQueryCollection={that.workspaceCustomQueryCollection}
                modelId={this.modelId}
                dataModelConnectionsDetailsCollection={that.dataModelConnectionsDetailsCollection}
                dataModelMetaDataCollection={that.datamodelMetaDataCollection}
                onModelEntitiesLoad={this.onModelEntitiesLoad}
                datamodeladdcustomquery={this.datamodeladdcustomquery}
            />
            , document.getElementById('datamodelsconnectionslist')
        );

        this.onModelEntitiesLoad(true);
        $(window).on("resize", function () {
            $("#datamodelsconnectionscontainer").w2layout('resize');
        });
        $("#datamodelsentitycontainer").sortable({
            revert: "100",
        });
        $("#data-models-connections-tables-data-container ul.list-group>li").draggable({
            connectToSortable: "#datamodelsentitycontainer",
            revert: "100",
            scroll: false,
            helper: 'clone',
            addClasses: true,
            drag: function (event, ui) {
                $(ui.helper.context).draggable({ disabled: true }).addClass('disabled')
            },
            stop: function (event, ui) {
                var dragText = $(ui.helper.context).find("input").attr("data-value");
                var connectionId = $(ui.helper.context).find("input").attr("data_parent");
                setTimeout(function () {
                    if ($("#datamodelsentitycontainer li:not('.ui-draggable-dragging')").find("input[data-value='" + dragText + "'][data_parent='" + connectionId + "']").length != 1) {
                        $(".filtercontent input[data-value='" + dragText + "'][data_parent='" + connectionId + "']").parent().parent().draggable({ disabled: false }).removeClass("disabled");
                        $(".filtercontent input[data-value='" + dragText + "'][data_parent='" + connectionId + "']").prop('checked', false);
                    }
                }, 500);
            }
        });
        $("ul, li").disableSelection();
        $("#datamodelsentitycontainer").droppable({
            hoverClass: "highlight",
            activeClass: "highlight",
            out: function (event, ui) {
                var self = ui;
                var dragText = ui.draggable.find("input").attr("data-value");
            },
            drop: function (event, ui) {
                var dragText = ui.draggable.find("input").attr("data-value");
                var connectionId = ui.draggable.find("input").attr("data_parent");
                //ui.draggable.find(".fa.fa-table").removeClass("fa fa-table").addClass("fa fa-lightbulb-o");
                setTimeout(function () {
                    if ($("#datamodelsentitycontainer").find("input[data-value='" + dragText + "'][data_parent='" + connectionId + "']").length === 1) {
                        $(".filtercontent input[data-value='" + dragText + "'][data_parent='" + connectionId + "']").parent().parent().draggable({ disabled: true }).addClass("disabled");
                        $(".filtercontent input[data-value='" + dragText + "'][data_parent='" + connectionId + "']").prop('checked', true);
                    } else {
                        $(".filtercontent input[data-value='" + dragText + "'][data_parent='" + connectionId + "']").parent().parent().draggable({ disabled: false }).removeClass("disabled");
                        $(".filtercontent input[data-value='" + dragText + "'][data_parent='" + connectionId + "']").prop('checked', false);
                    }
                }, 500);

            },
            over: function (event, ui) {
            }
        });

        // setTimeout(function(){$(".spinner").hide();},1500);
        $(".createreference").click((e) => { this.createreference(e); });
    }
    render() {
        return (
            <DatamodelsHtml displayFooter={this.displayFooter} />
        );
    }
    createnewconnection = (e) => {
        e.preventDefault();
        var that = this;
        $(".addconnection").remove();
        $("body").append("<div class='connection-center addconnection'></div>");
        // require(["views/datamodels/createconnection"],function(createConnectionView){
        //     new createConnectionView({
        //         featuresModule : that.features,
        //         dataModelConnectionsDetailsCollection:that.dataModelConnectionsDetailsCollection
        //     });
        // })
    }
    editdatamodelitem = (e) => {
        e.preventDefault();
        this.editModelItem(e.currentTarget, false);
    }
    editdatamodelcustomquery = (e) => {
        e.preventDefault();
        this.dmcustomquelemet = e.currentTarget;
        this.editModelItem(e.currentTarget, true);
    }
    editdatamodelconnectionitem = (e) => {
        e.preventDefault();
        e.stopPropagation();
        var $this = e.currentTarget;
        this.conentityname = $($this).attr('data-value');
        this.conaccesid = $($this).attr('data_parent');
        var that = this;
        var entityName = $($this).attr('data-value');
        var workspaceentityid = $($this).attr('data_parent');
        $("#datamodelsentitycontainer>li").removeClass("active");
        $("#settingscontainer").remove();
        $("#datamodelsentitycontainer").after('<div id="settingscontainer"></div>');
        $(".spinner").show();
        var connectiondata = this.dataModelConnectionsDetailsCollection.getDataModelConnectionData(entityName, workspaceentityid);
        var cjson = JSON.parse(connectiondata.connectionjson);
        var schemaName = connectiondata.connectionName;
        var selectedEntities = {};
        selectedEntities[workspaceentityid + "&&&" + entityName.trim()] = connectiondata;
        var data = new FormData();
        data.append("entities", JSON.stringify(selectedEntities));
        SendRequest({
            url: "getentitiesmetadata",
            queryString: "",
            body: data,
            sucessFunction: (response) => {
                if (response.success == false || response.hasOwnProperty("error")) {
                    if (response.status == "fail") {
                        $(".spinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Warning');
                        $('.birdmessage-info').empty().text("The Column Name '" + response.columnName + "'" + "" + getMessage('BirdWarning149'));
                        $(".spinner").hide();
                        return;
                    }
                    else {
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(lang["models.unablefetch"]);
                        $('.details').addClass('show');
                        if (response.success == false || response.hasOwnProperty("error")) {
                            $('.messagedetails xmp').empty().append(response.errorTrace);
                        }
                        else {
                            $('.messagedetails xmp').empty().append(response.error);
                        }
                        $('.spinner').hide();
                        return
                    }
                }
                this.entityMetaDataCollection = response;
                this.dataModelItemSettingsView = new DataModelNewItemSettings({
                    metadata: response,
                    connectionName: connectiondata.connectionName,
                    schemaId: connectiondata.schemaId,
                    schemaName: connectiondata.connectionName,
                    isFromEdit: true,
                    schemaDisplayName: connectiondata.connectionName,
                    filename: cjson.fileName,
                    connectiondata: JSON.stringify(connectiondata),
                    connectionId: connectiondata.schemaId,
                    entityName: entityName,
                    entityMetaDataCollection: response,
                    applydatamodelconmodifyentities: this.applydatamodelconmodifyentities,
                    isSaved: false
                });
            },
            rejectFunction: () => { },
            headers: {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            }
        });
        $('.spinner').hide()
        $($this).closest("li").removeClass('warning');
        $($this).closest("li").addClass('active');
        $("#settingscontainer").addClass("showsettings");
        $("#datamodelsentitycontainer").addClass('settingsopened');
        setTimeout(function () {
            var container = $("#datamodelsentitycontainer"),
                scrollTo = $($this).closest("li");
            container.animate({
                scrollTop: scrollTo.offset().top - container.offset().top + container.scrollTop() - container.height() / 2
            });
        }, 1000);
        //this.dataModelsConnectionsListView.editModelConnectionItem(e.currentTarget);
    }
    applydatamodelconmodifyentities = (e) => {
        if (e != undefined) {
            e.preventDefault();
        }
        $(".spinner").show();
        var that = this;
        var columns = [];
        var selectedEntities = {};
        var conAccessId = this.conaccesid;
        var entity = this.conentityname.trim();
        var isCustomQuery = false;
        var connectiondata = this.dataModelConnectionsDetailsCollection.getDataModelConnectionData(entity, conAccessId);
        if (entity == "webdataconnector") {
            columns = this.CreateReportColumnsForWebdata();
        } else {
            columns = this.CreateReportColumns();
        }
        selectedEntities[conAccessId + "&&&" + entity.trim()] = connectiondata;
        _.each(selectedEntities, function (i, j) {
            i.command = "validateEntities";
            if (columns.length > 0) {
                i.report_columns = columns;
            }
        })
        var data = new FormData();
        data.append("entities", JSON.stringify(selectedEntities));
        SendRequest({
            url: "getentitiesmetadata",
            queryString: "",
            body: data,
            sucessFunction: (response) => {
                var errorCode = response.errorCode;
                var error = response.error;
                var errorTrace = response.errorTrace;
                var isError = false;
                var key = conAccessId + "&&&" + entity
                if (response[key] != undefined && JSON.parse(response[key]).success == false) {
                    isError = true;
                    errorCode = JSON.parse(response[key]).errorCode;
                    error = JSON.parse(response[key]).error;
                    errorTrace = JSON.parse(response[key]).errorTrace;
                }
                if (response.success == false || isError) {
                    _.each(selectedEntities, function (i, j) {
                        if (i["report_columns"] != undefined) {
                            delete i["report_columns"];
                        }
                        delete i["command"];
                    });
                    $(".spinner").hide();
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('hide');
                    $('.birdmessage h2').empty().append('Error : ');
                    $('.birdmessage h2').append('<span class="errorcode">');
                    $('.birdmessage .errorcode').empty().append(errorCode);
                    $('.birdmessage-info').empty().text(error);
                    $('.details').addClass('show').text("Details");
                    $('.messagedetails xmp').empty().append(errorTrace);
                    setTimeout(function () {
                        $('.spinner').hide();
                    }, 800);
                } else {
                    _.each(selectedEntities, function (i, j) {
                        delete i["command"];
                        if (i["report_columns"] != undefined) {
                            delete i["report_columns"];
                        }
                    });
                    that.dataModelItemSettingsView.createDataHubEntitiesObject(response);
                    that.getAvailableWorkSpacesData();
                }
            },
            rejectFunction: () => { },
            headers: {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            }
        });

    }
    createreference = (e) => {
        var that = this;
        var tables = {};
        var factObj = {};
        var count = $('#datamodelfactreferencecontainer>div').length;
        $("#datamodelfactreferencecontainer>.intromessage").addClass("hide");
        _.each($("#datamodelsentitycontainer li").find("input.scopeitem"), (entity, i) => {
            var workspaceentityid = $(entity).attr("data_parent");
            var entityname = $(entity).attr("data-value");
            if ($(entity).hasClass("datamodelcustomqueryscope")) {
                entityname = $(entity).attr("dmcqname");
            }
            var entityObject = that.getEntityObjectForJoins(workspaceentityid, entityname);
            entityObject.sort(function (c, b) {
                var x = c.name.toLowerCase();
                var y = b.name.toLowerCase();
                return x < y ? -1 : x > y ? 1 : 0;
            });
            var isFact = that.getEntityFact(workspaceentityid, entityname);
            tables[entityname] = entityObject;
            if (isFact == '1') {
                factObj[entityname] = entityObject;
            }
        });
        _.each(tables, function (i) {
            // var sortOrder = ["number", "string", "date"];
            // i.sort(function (a, b) {
            //     return sortOrder.indexOf(a.type) - sortOrder.indexOf(b.type);
            // });
        });
        $('#datamodelfactreferencecontainer').append('<div id=' + "factreference" + count + '></div>');
        ReactDOM.render(
            <FactTableReferencesHtml xlsjoinclose={this.xlsjoinclose} relations={[{}]} tables={tables} id={0} entityRelationId={undefined} factTables={factObj} />,
            document.getElementById("factreference" + count)
        );
        this.$el = $("#main");
        this.$el.find(".xls-select-primary, .xls-select-connected").multiselect({
            enableCaseInsensitiveFiltering: true,
            enableFiltering: true,
            enableHTML: true,
            templates: {
                filterClearBtn: '<span class="input-group-btn"><button class="btn btn-default multiselect-clear-filter" type="button"><i class="fa fa-times"></i></button></span>',
            },
            optionLabel: function (element) {
                var attrType = $(element).attr('columntype');
                var colName = $(element).attr('columnname');
                if (attrType == "string") {
                    return '<span class="itemtype" data-type="string" data-name="' + colName + '">' + lang["models.abc"] + '</span>' + colName;
                } else if (attrType == "number") {
                    return '<span class="itemtype" data-type="number" data-name="' + colName + '">123</span>' + colName;
                } else if (attrType == "date") {
                    return '<span class="itemtype" data-type="date" data-name="' + colName + '"><i class="fa fa-calendar"></i></span>' + colName;
                } else {
                    return '' + $(element).attr('columnname');
                }

            }
        });
        _.each(this.$el.find("#datamodelfactreferencecontainer span.multiselect-native-select li.multiselect-item.multiselect-group"), function (i) {
            $(i).find('label>b i').remove();
            $(i).find('label>b').prepend('<i class="fa fa-table marg-left-5"></i>');
        });
        _.each(this.$el.find("#datamodelfactreferencecontainer span.multiselect-native-select li:not(.multiselect-item.multiselect-group)"), function (i) {
            $(i).find('label').attr("title", $(i).find('.itemtype').attr('data-name'));
        });
        this.generateddslick();
    }
    getEntityObjectForJoins = (workspaceentityid, entityname) => {
        var entityId = workspaceentityid;
        var columns = this.workspaceExistingEntityMetaDataCollection.getEntityColumns(entityname, workspaceentityid);
        var that = this;
        if (that.state.dataModelMetaData != undefined && (that.datamodelMetaDataCollection.getMetadataEntities().length > 0 || that.datamodelMetaDataCollection.getMetadataEntities() != undefined)) {
            var entitiesArray = that.datamodelMetaDataCollection.getMetadataEntities();
            _.each(entitiesArray, function (i, j) {
                if (i.workspaceentityid == workspaceentityid && i.entityname == entityname) {
                    var modcols = i.columns;
                    if (typeof (i.columns) == 'string') {
                        modcols = JSON.parse(i.columns);
                    }
                    var wscolumns = columns;
                    if (typeof (columns) == 'string') {
                        wscolumns = JSON.parse(columns);
                    }
                    var finalCols = [];
                    _.each(wscolumns, function (m, n) {
                        var colAdded = false;
                        _.each(modcols, function (k, l) {
                            var modelDisplayName = k.orginalColumnName.split(".");
                            var orgDisplayName = (modelDisplayName.length == 2) ? modelDisplayName[1] : k.orginalColumnName;
                            var wsDisplayName = m.orginalColumnName.split(".");
                            var wsorgDisplayName = (wsDisplayName.length == 2) ? wsDisplayName[1] : m.orginalColumnName;
                            if (orgDisplayName == wsorgDisplayName && m.isDisplayNameModified != undefined && m.isDisplayNameModified == "yes") {
                                finalCols.push(m);
                                colAdded = true;
                            } else if (orgDisplayName == wsorgDisplayName) {
                                k.visible = m.visible != undefined ? m.visible : "yes";
                                finalCols.push(k);
                                colAdded = true;
                            }
                        });
                        if (!colAdded) {
                            finalCols.push(m);
                        }
                    });
                    columns = JSON.stringify(finalCols);
                }
            });
        }
        var parsedColumns = [];
        if (typeof (columns) == "string") {
            parsedColumns = JSON.parse(columns)
        } else {
            parsedColumns = columns
        }
        var joinColumns = [];
        _.each(parsedColumns, function (parsedColumn) {
            var visible = parsedColumn.visible;
            if (visible == 'yes') {
                var columnname = parsedColumn.columnName;
                var modelDisplayName = parsedColumn.columnDisplayName.split(".");
                var columnDisplayName = (modelDisplayName.length == 2) ? modelDisplayName[1] : parsedColumn.columnDisplayName;
                var tableDisplayType = parsedColumn.tableDisplayType;
                var joinColumn = {};
                joinColumn['name'] = columnDisplayName;
                joinColumn['type'] = tableDisplayType;
                joinColumn['workspaceentityid'] = workspaceentityid;
                joinColumns.push(joinColumn);
            }
        });
        var entitycfs = this.workspaceExistingEntityMetaDataCollection.getEntityCustomFeilds(entityname, workspaceentityid);
        if (entitycfs != undefined && entitycfs != null && entitycfs != [] && entitycfs != "[]" && entitycfs != "") {
          var  parsedColumnsCfs = JSON.parse(entitycfs);
          _.each(parsedColumnsCfs, function (parsedColumn) {
                var columnname = parsedColumn.name;
                var tableDisplayType = parsedColumn.dataType;
                var joinColumn = {};
                joinColumn['name'] = columnname;
                joinColumn['type'] = tableDisplayType;
                joinColumn['workspaceentityid'] = workspaceentityid;
                joinColumns.push(joinColumn);
        });
        }
        return joinColumns;
    }
    getEntityFact = (workspaceentityid, entityname) => {
        return this.workspaceExistingEntityMetaDataCollection.getFact(entityname, workspaceentityid);
    }
    createnewconnection = (e) => {
        e.preventDefault();
        var that = this;
        $(".addconnection").remove();
        $("body").append("<div class='connection-center addconnection'></div>");
        // require(["views/datamodels/createconnection"],function(createConnectionView){
        //     new createConnectionView({
        //         featuresModule : that.features,
        //         dataModelConnectionsDetailsCollection:that.dataModelConnectionsDetailsCollection,
        //         broker:that.broker
        //     });
        // })
    }
    editModelItem = (item, isCustomQuery) => {
        var that = this;
        var entityName = $(item).attr('data-value');
        var data_type = $(item).attr("data_type");
        if (isCustomQuery) {
            entityName = $(item).attr('dmcqname');
        }
        var workspaceentityid = $(item).attr('data_parent');
        $("#datamodelsentitycontainer>li").removeClass("active");
        $("#settingscontainer").remove();
        $("#datamodelsentitycontainer").after('<div id="settingscontainer"></div>');
        ReactDOM.render(
            <DatamodelItemSettings
                entityName={entityName}
                workspaceentityid={workspaceentityid}
                entitycollection={that.workspaceExistingEntityMetaDataCollection}
                isCustomQuery={isCustomQuery}
                workspaceCustomQueryCollection={that.workspaceCustomQueryCollection}
                type={data_type}
                dataModelMetaDataCollection={that.datamodelMetaDataCollection}
                validatedatamodelcustomquery={this.validatedatamodelcustomquery}
                savedatamodelcustomquery={this.savedatamodelcustomquery}
            />,
            document.getElementById('settingscontainer')
        );
        $(item).closest("li").removeClass('warning');
        $(item).closest("li").addClass('active');
        $("#settingscontainer").addClass("showsettings");
        $("#datamodelsentitycontainer").addClass('settingsopened');
        setTimeout(function () {
            var container = $("#datamodelsentitycontainer"),
                scrollTo = $(item).closest("li");
            container.animate({
                scrollTop: scrollTo.offset().top - container.offset().top + container.scrollTop() - container.height() / 2
            });
        }, 1000);
        if (isCustomQuery) {
            $('#datamodelcustomquerytab').click();
        }
    }
    CreateReportColumns = () => {
        var columnsObj = {};
        var columns = [];
        var dateTypeFormatArray = [];
        var success = true
        var that = this;
        var Visibilitycount = 0;
        var isUnitAvailable = false;
        _.each($('.datamodelsymanticnamestablecontainer tbody tr'), function (html, index) {
            var temp = {};
            var dateTypeFormatMap = {};
            var dateFormat = "";
            temp["id"] = index;
            temp["columnName"] = $(html).find('.tabledisplayname').val().trim();
            temp["tableAliasName"] = $(html).find('input[type=text]').attr("tablealiasname");
            temp["orginalColumnName"] = $(html).find('input[type=text]').attr("orginalcolumnname").trim();
            temp["columnDisplayName"] = $(html).find('.tabledisplayname').val().trim();
            temp["tableDisplayType"] = $(html).find('.datecontroller option:selected').val();

            if (temp["tableDisplayType"] == "date") {
                if ($(html).find('.csvdateformatsdroppdown select option:selected').val() == "others") {
                    dateFormat = $(html).find(".customdate-others").val().trim();
                    if ($(html).find(".customdate-others").val().length == 0) {
                        $(html).find(".customdate-error").removeClass("hide");
                        success = false;
                    }
                } else {
                    dateFormat = $(html).find('.csvdateformatsdroppdown select option:selected').text().trim();
                }
            }
            temp["dateFormat"] = dateFormat;
            temp["tableDisplayName"] = $(html).find('input[type=text]').attr("tablename");
            temp["tableName"] = $(html).find('input[type=text]').attr("tablename");
            if ($(html).find('input[type=text]').attr("isPartitionKey")) {
                temp["isPartitionKey"] = 'yes';
            }
            temp["visible"] = 'yes';
            columns.push(temp);
        });
        return columns;
    }
    getAvailableWorkSpacesData = () => {
        SendRequest({
            url: "getavailableworkspaces",
            queryString: "?isFromModel=" + true,
            sucessFunction: (props) => {
                this.state.availableWorkspaces = props;

                var that = this;
                this.selectedWorkpaceId = 0;
                var connectionsdata = [];
                this.state.availableWorkspaces.map((wsdetails, i) => {
                    var wsObj = {};
                    this.datahubid = wsdetails['datahubid'];
                    var title = wsdetails['WSTitle'];
                    if (title != undefined && title == "Default Workspace_" + wsdetails['userId']) {
                        title = "Default Workspace";
                    }
                    var descr = wsdetails['WSDescription'];
                    var wsId = wsdetails['WSID'];
                    wsObj['hubId'] = that.datahubid;
                    wsObj['text'] = title;
                    wsObj['originaltext'] = wsdetails['WSTitle'];
                    wsObj['value'] = wsId;
                    wsObj['description'] = wsdetails['WSDescription'] != undefined && wsdetails['WSDescription'] != null ? wsdetails['WSDescription'] : "";
                    wsObj['imageSrc'] = '../../../images/birdimages/workspace.png';
                    wsObj['metadata'] = wsdetails['metadata'];
                    if (that.currentworkspaceId != undefined && wsdetails.WSID == that.currentworkspaceId) {
                        wsObj['selected'] = true;
                        this.selectedworkspaceid = wsdetails['WSID'];
                    }
                    else if (i === 0 && that.currentworkspaceId == undefined) {
                        wsObj['selected'] = true;
                        this.selectedworkspaceid = wsdetails['WSID'];
                    }
                    connectionsdata.push(wsObj);
                })
                this.dataModelConnectionsDetailsCollection.updateAvailableWorkSpacesData(connectionsdata);
                this.saveIntoDataHubFromModel();
            },
            rejectFunction: (error) => { },
            headers: this.getRequestHeaders
        });
    }
    saveIntoDataHubFromModel = () => {
        var that = this;
        var selectedEntities = {};
        var allEntities = {};
        var conAccessId = this.conaccesid;
        var selectWSdata = {};
        var selectdWS = $('.dd-selected-value').val();
        var availableWSdata = this.dataModelConnectionsDetailsCollection.getAvailableWorkSpacesData();
        _.each(availableWSdata, function (ws, inde) {
            if (ws.value == selectdWS) {
                selectWSdata = ws;
                if (selectWSdata.originaltext != undefined && selectWSdata.originaltext != selectWSdata.text) {
                    selectWSdata.text = selectWSdata.originaltext;
                }
            }
        });
        var entity = this.conentityname.trim();
        var dbType = "";
        var connectiondata = this.dataModelConnectionsDetailsCollection.getDataModelConnectionData(entity, conAccessId);
        var connectionJson = connectiondata.connectionjson != null ? connectiondata.connectionjson : ""
        if (connectionJson != undefined && connectionJson != '') {
            var parsedConnectionJson = JSON.parse(connectionJson);
            dbType = parsedConnectionJson["dbType"];
        }

        if (entity != undefined && (connectiondata.contype == 'F' || connectiondata.contype == 'FR' || connectiondata.contype == 'JSON' || connectiondata.contype == 'LOG' || connectiondata.contype == 'pdf')) {
            allEntities[conAccessId + "&&&" + entity] = connectiondata;
        }
        if (dbType != '' && (dbType.toLowerCase() == 'rabbitmq' || dbType.toLowerCase() == 'kafka' || dbType.toLowerCase() == 'eventhub' || dbType.toLowerCase() == 'websocket')) {
            allEntities[conAccessId + "&&&" + entity] = connectiondata;
        }

        var syncDetails = [];
        var syncDatagiven = [];
        var k = 0;
        _.each(allEntities, function (i, j) {
            syncDatagiven[k] = false
            var syncConfigData = {};
            var syncObj = {};
            var totalsyncObj = {};
            var data = j.split("&&&");
            var conAccessId = data[0];
            var entity = data[1];
            var checkedId = "alldata";
            syncConfigData['entity'] = entity;
            syncConfigData['type'] = i.isCustomQuery != undefined && i.isCustomQuery == true ? "customqueryentity" : "dbentity";
            syncConfigData['conAccessId'] = conAccessId;
            syncConfigData['conAccessDetails'] = i;
            var sqlQuery = that.dataModelItemSettingsView.getQuery(conAccessId, entity);
            syncConfigData['query'] = sqlQuery;
            syncConfigData['_idcolumn'] = undefined;
            syncConfigData['columns'] = that.dataModelItemSettingsView.getAllFeildsJson(conAccessId, entity);
            if (checkedId == 'alldata') {
                syncConfigData["refreshType"] = "fullrefresh";
                syncDetails.push(syncConfigData);
                syncDatagiven[k] = true
            }
            k++
        });
        var dataSyncType = "defaultcontainer";
        var syncType = "default";
        var syncVal = "30";
        var syncTime = "";
        if (syncDatagiven.indexOf(false) < 0) {
            $(".spinner").show();
            var data = new FormData();
            data.append("syncConfig", JSON.stringify(syncDetails));
            data.append("syncType", syncType);
            data.append("dataSyncType", dataSyncType);
            data.append("syncTime", syncTime);
            data.append("syncVal", syncVal);
            data.append("isFromModel", true);
            data.append("selectedWSdata", JSON.stringify(selectWSdata));
            SendRequest({
                url: "savedatahub",
                queryString: "",
                body: data,
                sucessFunction: (response) => {
                    if (response.hasOwnProperty('error')) {
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.error)
                        $('.spinner').hide();
                        setTimeout(function () {
                            $('.birderror').remove();
                        }, 3000);
                    } else {
                        ReactDOM.render(
                            <DatamodelsConnections
                                workspaceExistingEntityCollection={that.workspaceExistingEntityMetaDataCollection}
                                workspaceCustomQueryCollection={that.workspaceCustomQueryCollection}
                                modelId={this.modelId}
                                dataModelConnectionsDetailsCollection={that.dataModelConnectionsDetailsCollection}
                                currentworkspaceId={selectWSdata.value}
                                onModelEntitiesLoad={this.onModelEntitiesLoad}
                                editdatamodelitem={this.editdatamodelitem}
                                joinremove={this.joinremove}
                            />
                            , document.getElementById('datamodelsconnectionslist')
                        );

                        that.dataModelConnectionsDetailsCollection.deleteDataModelConnectionData(entity, conAccessId);
                        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Success');
                        $('.birdmessage-info').empty().text(getMessage('BirdSuccess57'));
                        $("#datamodelsentitycontainer>li.active").remove();
                        $("#datamodelsentitycontainer>li").removeClass("active");
                        $("#settingscontainer").removeClass("showsettings");
                        $('#datamodelsentitycontainer').removeClass('settingsopened');
                        setTimeout(function () { $("#settingscontainer").remove(); }, 1000);
                        $(".spinner").hide();
                    }
                },
                rejectFunction: () => { },
                headers: {
                    method: "post",
                    headers: new Headers({
                        "Content-Type": "application/json"
                    })
                }
            });

        }
    }
    getEnitityDetails = (workspaceentityid, entityname) => {
        var columns = this.workspaceExistingEntityMetaDataCollection.getEntityColumns(entityname, workspaceentityid);
        var fact = this.workspaceExistingEntityMetaDataCollection.getFact(entityname, workspaceentityid);
        var filters = this.workspaceExistingEntityMetaDataCollection.getEntityFilters(entityname, workspaceentityid);
        var customfields = this.workspaceExistingEntityMetaDataCollection.getEntityCustomFeilds(entityname, workspaceentityid);
        var entityType = this.workspaceExistingEntityMetaDataCollection.getEntityType(entityname, workspaceentityid);
        var query = this.workspaceExistingEntityMetaDataCollection.getQuery(entityname, workspaceentityid);
        var connectionId = this.workspaceExistingEntityMetaDataCollection.getConnectionId(entityname, workspaceentityid);
        var data = {};
        if (workspaceentityid != undefined && workspaceentityid <= 0) {
            query = this.workspaceCustomQueryCollection.getwsCustomQueryQueries(entityname, workspaceentityid);
            connectionId = null;
            filters = "";
            customfields = "";
            fact = "0";
            if (typeof (columns) != "string") {
                columns = JSON.stringify(columns);
            }
        }
        var that = this;
        if (workspaceentityid != -1) {
            if (that.state.dataModelMetaData != undefined && (that.datamodelMetaDataCollection.getMetadataEntities().length > 0 || that.datamodelMetaDataCollection.getMetadataEntities() != undefined)) {
                var entitiesArray = that.datamodelMetaDataCollection.getMetadataEntities();
                _.each(entitiesArray, function (i, j) {
                    if (i.workspaceentityid == workspaceentityid && i.entityname == entityname) {
                        var modcols = i.columns;
                        if (typeof i.columns == 'string') {
                            modcols = JSON.parse(i.columns);
                        }
                        var wscolumns = columns;
                        if (typeof columns == 'string') {
                            wscolumns = JSON.parse(columns)
                        }
                        var finalCols = [];
                        _.each(wscolumns, function (m, n) {
                            var colAdded = false;
                            _.each(modcols, function (k, l) {
                                var modelDisplayName = k.orginalColumnName.split(".");
                                var orgDisplayName = (modelDisplayName.length == 2) ? modelDisplayName[1] : k.orginalColumnName;
                                var wsDisplayName = m.orginalColumnName.split(".");
                                var wsorgDisplayName = (wsDisplayName.length == 2) ? wsDisplayName[1] : m.orginalColumnName;
                                if (k.orginalColumnName == m.orginalColumnName && orgDisplayName == wsorgDisplayName && m.isDisplayNameModified != undefined && m.isDisplayNameModified == "yes") {
                                    finalCols.push(m);
                                    colAdded = true;
                                } else if (k.orginalColumnName == m.orginalColumnName && orgDisplayName == wsorgDisplayName) {
                                    k.visible = m.visible != undefined ? m.visible : "yes";
                                    finalCols.push(k);
                                    colAdded = true;
                                }
                            });
                            if (!colAdded) {
                                finalCols.push(m);
                            }
                        });
                        columns = JSON.stringify(finalCols);
                    }
                });
            }
        }
        data['columns'] = columns;
        data['fact'] = fact;
        data['filters'] = filters;
        data['customfields'] = customfields;
        data['query'] = query;
        data['connectionId'] = connectionId;
        data['workspaceentityid'] = workspaceentityid;
        data['entityname'] = entityname;

        return data;



    }
    onsavedatamodel = (datamodelname, datamodeldescr) => {
        var that = this;
        var factCount = 0;
        var multifact = false;
        console.log(datamodelname, datamodeldescr);
        $(".spinner").show();
        if (datamodelname == undefined || datamodelname == "") {
            $(".spinner").hide();
            ReactDOM.render(
                <Savedatamodel datamodelname={datamodelname} datamodeldescr={datamodeldescr} onsavedatamodel={this.onsavedatamodel} />,
                document.getElementById('savedatamodaldialog')
            );
        }
        var reservedwordCheck = datamodelname.toLowerCase();
        var status = false;
        var reservedwordslist = JSON.parse(sessionStorage.getItem("reservedwordslist"));
        _.each(reservedwordslist, function (reservedword, j) {
            if (reservedwordCheck == reservedword) {
                status = true
            }
        });
        if (status) {
            $(".spinner").hide();
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text("Data Model" + "" + getMessage('BirdWarning147'));
            $(".spinner").hide();
            return;
        }
        var entityInfo = {};
        entityInfo['datamodelname'] = datamodelname;
        entityInfo['datamodeldescr'] = datamodeldescr;
        entityInfo['entities'] = [];
        _.each($("#datamodelsentitycontainer li").find("input.scopeitem"), function (entity) {
            var workspaceentityid = $(entity).attr("data_parent");
            var entityname = $(entity).attr("data-value");
            if ($(entity).hasClass("datamodelcustomqueryscope")) {
                entityname = $(entity).attr("dmcqname");
            }
            var entityDetails = that.getEnitityDetails(workspaceentityid, entityname);
            var fact = parseInt(entityDetails['fact']);
            factCount += fact;
            if (factCount > 1) {
                multifact = true;
            }
            entityInfo['entities'].push(entityDetails);
        });

        entityInfo['multifact'] = multifact;
        var references = [];
        _.each($("#datamodelfactreferencecontainer .row"), function (item) {
            var primaryTableName = $(item).find("#primaryselectdrop option:selected").attr('tablename');
            var primaryColumnName = $(item).find("#primaryselectdrop option:selected").attr("columnname");
            var primaryWorspaceEntityId = $(item).find("#primaryselectdrop option:selected").attr("data-parent");

            var jointype = $(item).find("#jointypedefine .dd-option-selected").text().trim();
            var relationId = $(item).find("#primaryselectdrop").attr("entity_RelationId");

            var secondaryTableName = $(item).find("#selectconnectdropdown option:selected").attr('tablename');
            var secondaryColumnName = $(item).find("#selectconnectdropdown option:selected").attr("columnname");
            var secondaryWorspaceEntityId = $(item).find("#selectconnectdropdown option:selected").attr("data-parent");

            var refernceObject = {};
            refernceObject['primarytable'] = primaryTableName;
            refernceObject['primarycolumn'] = primaryColumnName;
            refernceObject['primaryworkspaceentityid'] = primaryWorspaceEntityId;
            refernceObject['jointype'] = jointype;
            refernceObject['relationId'] = relationId;
            refernceObject['secondarytable'] = secondaryTableName;
            refernceObject['secondarycolumn'] = secondaryColumnName;
            refernceObject['secondaryworkspaceentityid'] = secondaryWorspaceEntityId;
            references.push(refernceObject);
        });
        entityInfo['references'] = references;
        var entitiesArray = entityInfo.entities;
        var count = 0;
        _.each(entitiesArray, function (entityi, i) {
            var entityColumnsArray = JSON.parse(entityi.columns);
            _.each(entityColumnsArray, function (y, z) {
                y["id"] = count;
                count++;
            })
            entityColumnsArray = JSON.stringify(entityColumnsArray);
            entitiesArray[i]["columns"] = entityColumnsArray;
        })
        //  var workspaceId = that.dataModelsConnectionsListView.getWorkspaceId();
        var workspaceId = this.workspaceExistingEntityMetaDataCollection.getSelectedworkspaceId();
        var workspaceMetaData = this.workspaceExistingEntityMetaDataCollection.getSelectedWorkspaceMetaData();
        var datamodelmetadata = {};
        if(workspaceMetaData && workspaceMetaData!=null && workspaceMetaData!="null"){
            let parsedWorkspaceMetaData  = JSON.parse(workspaceMetaData);
            datamodelmetadata["isWsLiveEnabled"] = parsedWorkspaceMetaData["isWsLiveEnabled"]?parsedWorkspaceMetaData["isWsLiveEnabled"]:false;
        }
        // var workspaceId=(this.state.dataModelMetaData.workspaceId!==undefined)?this.state.dataModelMetaData.workspaceId:0;
        var data = new FormData();
        data.append("entityInfo", JSON.stringify(entityInfo));
        data.append("datamodelname", datamodelname);
        data.append("datamodeldescr", datamodeldescr);
        data.append("multifact", multifact);
        data.append("workspaceId", workspaceId);
        data.append("datamodelId", this.modelId != undefined ? this.modelId : "");
        data.append("metadata", JSON.stringify(datamodelmetadata));

        SendRequest({
            url: "savedatamodel",
            queryString: "",
            body: data,
            sucessFunction: (response) => {
                $(".spinner").hide();
                if (response.hasOwnProperty('error')) {
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('hide');
                    $('.birdmessage h2').empty().append('Error : ');
                    $('.birdmessage h2').append('<span class="errorcode">');
                    $('.birdmessage .errorcode').empty().append(response.errorCode);
                    $('.birdmessage-info').empty().text(response.error);
                    $('.details').addClass('show');
                    $('.messagedetails xmp').empty().append(response.error)
                    $('.spinner').hide();
                    setTimeout(function () {
                        $('.birderror').remove();
                    }, 3000);
                } else {
                    $(".spinner").hide();
                    $("#savedatamodel").modal("hide");
                    $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                    $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('show');
                    $('.details').addClass('hide');
                    $('.birdmessage h2').empty().append('Success');
                    $('.birdmessage-info').empty().text(getMessage('BirdSuccess58'));

                    setTimeout(() => {
                        var href = "/" + window.localStorage.getItem("appName") + "/welcome/businessmodels";
                        $(".spinner").hide();
                        this.props.history.push(href);
                    }, 2000);
                }
            },
            rejectFunction: () => { },
            headers: this.postRequestHeaders
        });
    }
    onModelEntitiesLoad = (addExistingEntities) => {
        var that = this;

        $("#datamodelsentitycontainer").sortable({
            revert: "100",
        });
        $("#data-models-connections-tables-data-container ul.list-group>li").draggable({
            connectToSortable: "#datamodelsentitycontainer",
            revert: "100",
            scroll: false,
            helper: 'clone',
            addClasses: true,
            drag: function (event, ui) {
                $(ui.helper.context).draggable({ disabled: true }).addClass('disabled')
            },
            stop: function (event, ui) {
                var dragText = $(ui.helper.context).find("input").attr("data-value");
                var connectionId = $(ui.helper.context).find("input").attr("data_parent");
                setTimeout(function () {
                    if ($("#datamodelsentitycontainer li:not('.ui-draggable-dragging')").find("input[data-value='" + dragText + "'][data_parent='" + connectionId + "']").length != 1) {
                        $(".filtercontent input[data-value='" + dragText + "'][data_parent='" + connectionId + "']").parent().parent().draggable({ disabled: false }).removeClass("disabled");
                        $(".filtercontent input[data-value='" + dragText + "'][data_parent='" + connectionId + "']").prop('checked', false);
                    }
                }, 500);
            }
        });
        $("ul, li").disableSelection();
        $("#datamodelsentitycontainer").droppable({
            hoverClass: "highlight",
            activeClass: "highlight",
            out: function (event, ui) {
                var self = ui;
                var dragText = ui.draggable.find("input").attr("data-value");
            },
            drop: function (event, ui) {
                var dragText = ui.draggable.find("input").attr("data-value");
                var connectionId = ui.draggable.find("input").attr("data_parent");
                if ($('#datamodelsentitycontainer li').length > 0) {
                    $('.connections-dropdown-container').addClass('disabled')
                }
                //ui.draggable.find(".fa.fa-table").removeClass("fa fa-table").addClass("fa fa-lightbulb-o");
                setTimeout(function () {
                    if ($("#datamodelsentitycontainer").find("input[data-value='" + dragText + "'][data_parent='" + connectionId + "']").length === 1) {
                        $(".filtercontent input[data-value='" + dragText + "'][data_parent='" + connectionId + "']").parent().parent().draggable({ disabled: true }).addClass("disabled");
                        $(".filtercontent input[data-value='" + dragText + "'][data_parent='" + connectionId + "']").prop('checked', true);
                    } else {
                        $(".filtercontent input[data-value='" + dragText + "'][data_parent='" + connectionId + "']").parent().parent().draggable({ disabled: false }).removeClass("disabled");
                        $(".filtercontent input[data-value='" + dragText + "'][data_parent='" + connectionId + "']").prop('checked', false);
                    }
                }, 500);
                $(".editdmconnection").unbind().click(function (e) {
                    that.editdatamodelconnectionitem(e);
                })
                $(".editdatamodelitem").unbind().click(function (e) {
                    that.editdatamodelitem(e);
                })
                $(".joinremove").unbind().click(function (e) {
                    that.joinremove(e);
                })
            },
            over: function (event, ui) {
            }
        })
        if (addExistingEntities) {
            $(".spinner").show();
            var that = this;
            if (this.modelId != undefined) {
                SendRequest({
                    url: "getDataModelMetaData",
                    queryString: "?modelId=" + this.modelId,
                    sucessFunction: (response) => {
                        if (!response.hasOwnProperty('error')) {
                            that.isEdit = true
                            var metaDataModel = response;
                            this.setState({
                                dataModelMetaData: response
                            });
                            ChartInfo.functions.setDatamodelMetaData(response);
                            ReactDOM.render(
                                <DatamodelExistingEntitiesHtml entites={metaDataModel.entities} editdatamodelitem={this.editdatamodelitem} joinremove={this.joinremove} />,
                                document.getElementById('datamodelsentitycontainer')
                            );
                            _.each(metaDataModel.entities, (i) => {
                                if (i.entityType != undefined && i.entityType == "customquery") {
                                    that.workspaceCustomQueryCollection.addWsCustomQueryName(i.entityname);
                                    that.workspaceExistingEntityMetaDataCollection.getEntityColumns(i.entityname, i.workspaceentityid);
                                    that.workspaceCustomQueryCollection.updatewsCustomQueryQueries(i.query, i.entityname, i.workspaceentityid);
                                    that.workspaceCustomQueryCollection.updatewsCustomQueryEntityColumns(JSON.parse(i.columns), i.entityname, i.workspaceentityid);
                                    that.workspaceExistingEntityMetaDataCollection.updateEntityColumns(JSON.parse(i.columns), i.entityname, i.workspaceentityid);
                                    var compare_cq_id = sessionStorage.getItem("customqueryentityid");
                                    if (compare_cq_id != null && compare_cq_id != undefined) {
                                        if (compare_cq_id.replace("-", "") >= i.workspaceentityid.toString().replace("-", "")) {
                                            sessionStorage.setItem("customqueryentityid", compare_cq_id);
                                        } else if (i.workspaceentityid.toString().replace("-", "") > compare_cq_id.replace("-", "")) {
                                            sessionStorage.setItem("customqueryentityid", i.workspaceentityid);
                                        }
                                    } else {
                                        sessionStorage.setItem("customqueryentityid", i.workspaceentityid);
                                    }
                                    var width = $("#data-models-tables-list-container").width() - 43;
                                    $("#datamodelsentitycontainer").append('<li class="viewreport-list-item datamodel-customquery-item" style="width:' + width + 'px"><label title=' + lang["models.customquery"] + '><i class="fa fa-code"><input type="checkbox" class="scopeitem datamodelcustomqueryscope" data_type="existing" data_parent=' + i.workspaceentityid + ' data-value=' + i.entityname + ' dmcqname="' + i.entityname + '" model-entity-Id=' + i.datamodelEntityId + '></i> ' + i.entityname + '</label><button class="btn btn-transparent btn-sm editdatamodelcustomquery" data_type="existing" data_parent=' + i.workspaceentityid + ' type="button" dmcqname="' + i.entityname + '" title=' + lang["models.editcustomquery"] + ' model-entity-Id=' + i.datamodelEntityId + '><i class="fa fa-cog"></i></button><button class="btn btn-transparent btn-sm joinremove" type="button" data_type="existing" data_parent=' + i.workspaceentityid + ' data-value=' + i.entityname + ' model-entity-Id=' + i.datamodelEntityId + '><i class="fa fa-times"></i></button></li>');
                                    $('.editdatamodelcustomquery').click((e) => { this.editdatamodelcustomquery(e); });
                                    $('.joinremove').click((e) => { this.joinremove(e); });
                                }
                            });
                            if (that.datamodelMetaDataCollection.getMetadataEntities().length == 0 || that.datamodelMetaDataCollection.getMetadataEntities() == undefined) {
                                that.datamodelMetaDataCollection.updateMetadataEntities(metaDataModel.entities)
                            }
                            if (that.datamodelMetaDataCollection.getMetadataJoins().length == 0 || that.datamodelMetaDataCollection.getMetadataJoins() == undefined) {
                                that.datamodelMetaDataCollection.updateMetadataJoins(metaDataModel.joins)
                            }
                            that.currentworkspaceId = metaDataModel.workspaceId
                            that.dataModelName = metaDataModel.dataModelName;
                            that.dataModelDesc = metaDataModel.dataModelDesc;
                            if (that.modelId != undefined && that.modelId != '') {
                                setTimeout(function () {
                                    var path = '<li><a class="navDashboard" href="#data"><i class="fa fa-database fa-solid"></i> ' + lang["models.data"] + '</a></li><li><a class="navDashboard" href="#models"><i class="fa fa-cube fa-solid"></i> ' + lang["models.business"] + '</a></li><li><a href="#models/' + btoa(that.modelId) + '"><i class="fa fa-cube fa-solid"></i> ' + that.dataModelName + '</a></li>';
                                    $(".navbar.navbar-bird ul.nav-path").html(path)
                                }, 200);
                                $('.datamain-fixed-head-container .subhead').find('span').text(that.dataModelName)
                            }
                            SendRequest({
                                url: "getavailableworkspaces",
                                queryString: "?isFromModel=" + true,
                                sucessFunction: (response) => {
                                    $(".spinner").show();
                                    var connectionsdata = [];
                                    _.each(response, function (wsdetails, i) {
                                        var wsObj = {};
                                        that.datahubid = wsdetails['datahubid'];
                                        var title = wsdetails['WSTitle'];
                                        if (title != undefined && title == "Default Workspace_" + wsdetails['userId']) {
                                            title = "Default Workspace";
                                        }
                                        var descr = wsdetails['WSDescription'] != undefined && wsdetails['WSDescription'] != null ? wsdetails['WSDescription'] : "";
                                        var wsId = wsdetails['WSID'];
                                        wsObj['hubId'] = that.datahubid;
                                        wsObj['text'] = title;
                                        wsObj['originaltext'] = wsdetails['WSTitle'];
                                        wsObj['value'] = wsId;
                                        wsObj['description'] = descr;
                                        wsObj['imageSrc'] = '../../../images/birdimages/workspace.png';
                                        wsObj['metadata'] = wsdetails['metadata'];
                                        if (that.currentworkspaceId != undefined && wsdetails.WSID === that.currentworkspaceId) {
                                            wsObj['selected'] = true;
                                            that.selectedworkspaceid = wsdetails['WSID'];
                                        } else if (i === 0 && that.currentworkspaceId == undefined) {
                                            wsObj['selected'] = true;
                                            that.selectedworkspaceid = wsdetails['WSID'];
                                        }
                                        connectionsdata.push(wsObj);
                                    });
                                    PubSub.publish('loadworkspace', connectionsdata);
                                    setTimeout(function () { $(".spinner").hide(); }, 1500);
                                },
                                rejectFunction: (error) => { $(".spinner").hide(); },
                                headers: this.getRequestHeaders
                            });
                        } else {
                            setTimeout(function () { $(".spinner").hide(); }, 1500);
                        }
                    },
                    rejectFunction: (error) => { $(".spinner").hide(); },
                    headers: this.getRequestHeaders
                });
            }
        }
        if (sessionStorage.getItem("workspaceId") != undefined && sessionStorage.getItem("workspaceId") != '') {
            SendRequest({
                url: "getavailableworkspaces",
                queryString: "?isFromModel=" + true,
                sucessFunction: (response) => {
                    if (sessionStorage.getItem("workspaceId") != undefined && sessionStorage.getItem("workspaceId") != '') {
                        var data = response;
                        var connectionsdata = [];
                        _.each(data, function (wsdetails, i) {
                            var wsObj = {};
                            that.datahubid = wsdetails['datahubid'];
                            var title = wsdetails['WSTitle'];
                            if (title != undefined && title == "Default Workspace_" + wsdetails['userId']) {
                                title = "Default Workspace";
                            }
                            var descr = wsdetails['WSDescription'];
                            var wsId = wsdetails['WSID'];
                            wsObj['hubId'] = that.datahubid;
                            wsObj['description'] = wsdetails['WSDescription'] != undefined && wsdetails['WSDescription'] != null ? wsdetails['WSDescription'] : "";
                            wsObj['text'] = title;
                            wsObj['originaltext'] = wsdetails['WSTitle'];
                            wsObj['value'] = wsId;
                            wsObj['imageSrc'] = '../../../images/birdimages/workspace.png';
                            wsObj['metadata'] = wsdetails['metadata'];

                            if (wsdetails.WSID === parseInt(sessionStorage.getItem("workspaceId"))) {
                                wsObj['selected'] = true;
                                that.selectedworkspaceid = wsdetails['WSID'];
                            } else if (i === 0 && (sessionStorage.getItem("workspaceId") == undefined)) {
                                wsObj['selected'] = true;
                                that.selectedworkspaceid = wsdetails['WSID'];
                            }
                            connectionsdata.push(wsObj);
                        });
                        sessionStorage.removeItem("workspaceId")
                        PubSub.publish('loadworkspace', connectionsdata);
                    }
                    setTimeout(function () { $(".spinner").hide(); }, 1500);
                }, rejectFunction: (error) => { $(".spinner").hide(); },
                headers: this.getRequestHeaders
            });
        }
        if ($('#datamodelsentitycontainer li').length > 0) {
            $('.connections-dropdown-container').addClass('disabled')
        }
    }
    saveDataModel = (event, currentIndex) => {
        var that = this;
        if ($("#datamodelfactreferencecontainer .row") != undefined && $("#datamodelfactreferencecontainer .row").length == 0 && $("#datamodelsentitycontainer li").find("input.scopeitem") != undefined && $("#datamodelsentitycontainer li").find("input.scopeitem").length > 1) {
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-infor').addClass('birdmessage-warning');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-info-circle').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(getMessage('BirdWarning133'));
        } else {
            if (that.dataModelDesc == undefined) {
                that.dataModelDesc = "Workspace Name: " + $("#datamodelsconnectionslist .dd-selected-text").text();
            }
            ReactDOM.render(
                <Savedatamodel dataModelName={that.dataModelName} dataModelDesc={that.dataModelDesc} onsavedatamodel={this.onsavedatamodel} />,
                document.getElementById('savedatamodaldialog')
            );
        }

    }
    joinremove = (e) => {
        e.preventDefault();
        var $this = e.currentTarget;
        var tablevalue = $($this).parent().find("input").attr("data-value");
        var connectionId = $($this).parent().find("input").attr("data_parent")
        var type = $($this).parent().find("input").attr("data_type");
        var entityId = $($this).attr("model-entity-Id");
        var isDelete = true
        var that = this
        if (tablevalue != undefined && tablevalue != '') {
            _.each($("#datamodelsentitycontainer .scopeitem"), (i, j) => {
                var $this1 = $(i);
                if (connectionId === $($this1).attr('data_parent') && tablevalue === $($this1).attr('data-value')) {
                    if (type === 'existing') {
                        isDelete = false;

                        $(".deleteyes").unbind("click");
                        $(".deleteno").unbind("click");
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                        $('.birdmessagebuttons').addClass('show');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Warning');
                        $('.birdmessage-info').empty().text(getMessage('BirdWarning125'));
                        $(".deleteyes").click(() => {
                            $(".spinner").show();
                            isDelete = true
                            if (that.datamodelMetaDataCollection.getMetadataEntities().length > 0 || that.datamodelMetaDataCollection.getMetadataEntities() != undefined) {
                                var entities = [];
                                _.each(that.datamodelMetaDataCollection.getMetadataEntities(), function (val, index) {
                                    if (val.datamodelEntityId != parseInt(entityId)) {
                                        entities.push(val)
                                    }
                                })
                                that.datamodelMetaDataCollection.updateMetadataEntities(entities);
                            }
                            if (that.datamodelMetaDataCollection.getMetadataJoins().length > 0 || that.datamodelMetaDataCollection.getMetadataJoins() != undefined) {
                                var joins = [];
                                _.each(that.datamodelMetaDataCollection.getMetadataJoins(), function (val, index) {
                                    if (val.dataModelEntityId != parseInt(entityId) && val.parentEntityId != parseInt(entityId)) {
                                        joins.push(val)
                                    }
                                })
                                that.datamodelMetaDataCollection.updateMetadataJoins(joins);
                            }
                            SendRequest({
                                url: "deleteentityfordatamodel",
                                queryString: "?entityId=" + entityId + "&entityName=" + tablevalue,
                                sucessFunction: (response) => {
                                    if (isDelete) {
                                        $(".spinner").hide();
                                        if ($($this).closest("li").hasClass("datamodel-customquery-item")) {
                                            $($this).closest("li").remove();
                                            $("#settingscontainer").remove();
                                            $("#datamodelsentitycontainer>li").removeClass("active");
                                            $("#settingscontainer").removeClass("showsettings");
                                            $("#datamodelsentitycontainer").removeClass('settingsopened');
                                            if ($('#datamodelsentitycontainer li').length == 0) {
                                                $('.connections-dropdown-container').removeClass('disabled')
                                            }
                                        } else {
                                            $($this1).parent().parent().remove();
                                            $("#settingscontainer").remove();
                                            $("#datamodelsentitycontainer").removeClass('settingsopened');
                                            if ($('#datamodelsentitycontainer li').length == 0) {
                                                $('.connections-dropdown-container').removeClass('disabled')
                                            }
                                        }
                                    }
                                    if ($('#datamodelsentitycontainer li').length == 0) {
                                        $('#datamodelsentitycontainer').empty();
                                        $('.connections-dropdown-container').removeClass('disabled');
                                    }
                                },
                                rejectFunction: (error) => { },
                                headers: this.getRequestHeaders
                            });
                            $('.birdmessage-center').removeClass("message-top-in");
                            $(".filtercontent input[data-value='" + tablevalue + "']").parent().parent().draggable({ disabled: false }).removeClass("disabled");
                            $(".filtercontent input[data-value='" + tablevalue + "']").prop('checked', false);
                            $("a[href='#next']").parent().removeClass("disabled");
                        });
                        $(".deleteno").click(function () {
                            $('.birdmessage-center').removeClass("message-top-in");
                        });

                    } else {
                        $(".filtercontent input[data-value='" + tablevalue + "']").parent().parent().draggable({ disabled: false }).removeClass("disabled");
                        $(".filtercontent input[data-value='" + tablevalue + "']").prop('checked', false);
                        $("a[href='#next']").parent().removeClass("disabled");
                    }
                    if (isDelete) {
                        //$($this1).parent().parent().remove()
                        $($this1).closest("li").remove();
                        $("#datamodelsentitycontainer>li").removeClass("active");
                        $("#settingscontainer").removeClass("showsettings");
                        $("#datamodelsentitycontainer").removeClass('settingsopened');
                    }
                }
            });
        } else if ($($this).closest("li").hasClass("datamodel-customquery-item")) {
            if (isDelete) {
                $(".deleteyes").unbind("click");
                $(".deleteno").unbind("click");
                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                $('.birdmessagebuttons').addClass('show');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Warning');
                $('.birdmessage-info').empty().text(getMessage('BirdWarning139'));
                $(".deleteno").click(function () {
                    $('.birdmessage-center').removeClass("message-top-in");
                });
                $(".deleteyes").click(function () {
                    $($this).closest("li").remove();
                    $("#settingscontainer").remove();
                    $("#datamodelsentitycontainer>li").removeClass("active");
                    $("#settingscontainer").removeClass("showsettings");
                    $("#datamodelsentitycontainer").removeClass('settingsopened');
                    $('.birdmessage-center').removeClass("message-top-in");
                    if ($('#datamodelsentitycontainer li').length == 0) {
                        $('.connections-dropdown-container').removeClass('disabled')
                    }
                });
            }
        }
        if ($('#datamodelsentitycontainer li').length == 0) {
            $('#datamodelsentitycontainer').empty();
            $('.connections-dropdown-container').removeClass('disabled');
        }
    }
    generateddslick = (joinType) => {
        var referenceJoinData = [
            {
                text: "INNER JOIN",
                value: 0,
                selected: false,
                imageSrc: "../../../images/birdimages/innerjoin.png"
            },
            {
                text: "OUTER JOIN",
                value: 1,
                selected: false,
                imageSrc: "../../../images/birdimages/outerjoin.png"
            },
            {
                text: "LEFT JOIN",
                value: 2,
                selected: true,
                imageSrc: "../../../images/birdimages/leftjoin.png"
            },
            {
                text: "RIGHT JOIN",
                value: 3,
                selected: false,
                imageSrc: "../../../images/birdimages/rightjoin.png"
            }
        ];
        if (joinType != undefined) {
            _.each(referenceJoinData, function (val, index) {
                if (val.text === joinType) {
                    val.selected = true
                } else {
                    val.selected = false
                }
            });
        }
        $('.definejointype').ddslick({
            data: referenceJoinData,
            width: "100%",
            height: "60px",
            imagePosition: "left",
            background: "#FFF",
        });
    }
    xlsjoinclose = (e) => {
        var that = this;
        var $this = e.currentTarget;
        var isDelete = true
        var entityRelationID = $($this).attr('entity_RelationId') != undefined && $($this).attr('entity_RelationId') != "" ? parseInt($($this).attr('entity_RelationId')) : undefined
        if ((that.datamodelMetaDataCollection.getMetadataJoins().length > 0 || that.datamodelMetaDataCollection.getMetadataJoins() != undefined) && (entityRelationID != undefined)) {
            var joinsArray = that.datamodelMetaDataCollection.getMetadataJoins();
            var updatedArray = [];
            isDelete = false;
            _.each(joinsArray, (val, index) => {
                if (entityRelationID === val.dataModelRelationId) {
                    $(".deleteyes").unbind("click");
                    $(".deleteno").unbind("click");
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                    $('.birdmessagebuttons').addClass('show');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('show');
                    $('.details').addClass('hide');
                    $('.birdmessage h2').empty().append('Warning');
                    $('.birdmessage-info').empty().text(getMessage('BirdWarning132'));
                    $(".deleteyes").click(() => {
                        $(".spinner").show();
                        isDelete = true;
                        var data = new FormData();
                        data.append("modelId", val.DMID);
                        data.append("entityRelationId", val.dataModelRelationId);
                        SendRequest({
                            url: "deleteEntityRelation",
                            queryString: "",
                            body: data,
                            sucessFunction: (response) => {
                                if (isDelete) {
                                    $(".spinner").hide();
                                    $($this).closest('.xls-sheet-relation-box').parent().remove();
                                }
                            },
                            rejectFunction: () => { },
                            headers: this.postRequestHeaders
                        });
                        $('.birdmessage-center').removeClass("message-top-in");
                    });
                    $(".deleteno").click(function () {
                        $('.birdmessage-center').removeClass("message-top-in");
                    });
                }
            })
        }
        if (isDelete) {
            $($this).closest('.xls-sheet-relation-box').parent().remove();
        }
        if ($("#datamodelfactreferencecontainer>div").length == 0) {
            $("#datamodelfactreferencecontainer>.intromessage").removeClass("hide");
        }
    }
    dropdownchange = (e) => {
        var $this = e.currentTarget;
        var tablename = $($this).find('option:selected').attr('tablename');
        $($($this).parent().parent().parent().children()[0]).text(tablename);
    }
    datamodeladdcustomquery = (e) => {
        e.stopPropagation();
        e.preventDefault();
        var $this = e.currentTarget;
        var workspace_entity_id = "";
        var customquery_entity_id = sessionStorage.getItem("customqueryentityid");
        if (customquery_entity_id != undefined && customquery_entity_id != null && customquery_entity_id != "" && customquery_entity_id != NaN) {
            var temp = customquery_entity_id.replace("-", "");
            temp++;
            workspace_entity_id = -temp;
        } else {
            workspace_entity_id = $($this).attr("current-id") - 1;
        }
        sessionStorage.setItem("customqueryentityid", workspace_entity_id);
        //var workspace_entity_id=$($this).attr("current-id")- 1;
        var width = $("#data-models-tables-list-container").width() - 43;
        $("#datamodelsentitycontainer").append('<li class="viewreport-list-item datamodel-customquery-item" style="width:' + width + 'px"><label title=' + lang["models.customquery"] + '><i class="fa fa-code"><input type="checkbox" class="scopeitem datamodelcustomqueryscope" data_type="new" data_parent=' + workspace_entity_id + ' data-value=""></i> ' + lang["models.customquery"] + '</label><button class="btn btn-transparent btn-sm editdatamodelcustomquery" data_type="new" data_parent=' + workspace_entity_id + ' type="button" dmcqname="" title=' + lang["models.editcustomquery"] + '><i class="fa fa-cog"></i></button><button class="btn btn-transparent btn-sm joinremove" type="button"><i class="fa fa-times"></i></button></li>');
        $($this).attr("current-id", workspace_entity_id);
        if ($('#datamodelsentitycontainer li').length > 0) {
            $('.connections-dropdown-container').addClass('disabled')
        }
        $('.joinremove').click((e) => { this.joinremove(e) });
        $('.editdatamodelcustomquery').click((e) => { this.editdatamodelcustomquery(e) })
    }
    vaidatequery = (e, isSave) => {
        $('.spinner').show();
        var that = this;
        var connectionJsObj = {};
        var workspaceId = $('#savescustomquery').attr("workspaceentityid");
        let aceeditval = ace.edit("advancedcustomquery");
        aceeditval.getSession().setValue(format(aceeditval.getValue()));
        var customQueryVal = ace.edit("advancedcustomquery").getValue().trim().replace(/\s+/g, ' ');
        customQueryVal=customQueryVal.replace("! ","!")
        if (customQueryVal == "" || customQueryVal == undefined) {
            $(".dmadvancedcustomquery").addClass("error");
            setTimeout(function () {
                $("#datamodelsstepscontainer").steps("previous");
                $('.spinner').hide();
            }, 800);
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-infor').addClass('birdmessage-warning');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-info-circle').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(getMessage('BirdWarning135'));
            return;
        } else {
            $(".dmadvancedcustomquery").removeClass("error");
        }
        connectionJsObj["command"] = "validateQuery";
        connectionJsObj["query"] = customQueryVal;
        var data = new FormData();
        data.append("connectionObject", JSON.stringify(connectionJsObj));
        SendRequest({
            url: "validatecustomquery",
            queryString: "",
            body: data,
            sucessFunction: (response) => {
                if (response.success == true) {
                    if (isSave) {
                        var oldname = $(that.wscustomquelemet).attr('dmcqname');
                        var wscustomquname = $('.dmcustomqueryname').val();
                        var reservedwordCheck = wscustomquname.toLowerCase();
                        var status = false;
                        var reservedwordslist = JSON.parse(sessionStorage.getItem("reservedwordslist"));
                        _.each(reservedwordslist, function (reservedword, j) {
                            if (reservedwordCheck == reservedword) {
                                status = true
                            }
                        });
                        if (status) {
                            $(".spinner").hide();
                            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                            $('.birdmessagebuttons').removeClass('show');
                            $('.birdmessagebuttons').addClass('hide');
                            $('.birdmessage-center').addClass("message-top-in");
                            $('.details').removeClass('show');
                            $('.details').addClass('hide');
                            $('.birdmessage h2').empty().append('Warning');
                            $('.birdmessage-info').empty().text("Custom Query" + "" + getMessage('BirdWarning147'));
                            $(".spinner").hide();
                            return;
                        }
                        var wscqnames = that.workspaceCustomQueryCollection.getWsCustomQueryNames();
                        var allentitiesNames = that.workSpaceEntityMetaDataCollection.getAllEntityNames();
                        var isErrorExists = false;
                        if (wscustomquname != undefined && wscqnames.indexOf(wscustomquname) != -1) {
                            _.each($('#datamodelsentitycontainer li'), function (item, index) {
                                if ($(item).hasClass("datamodel-customquery-item") && !$(item).hasClass("active")) {
                                    if ($(item).find(".datamodelcustomqueryscope").attr("dmcqname") == wscustomquname) {
                                        $(".cscqnamevalidate").hide();
                                        $(".cscqnameexists").show();
                                        isErrorExists = true;
                                    }
                                }
                            });
                            if (isErrorExists) {
                                $('#datamodelcustomquerytab').parent().addClass("active");
                                $('.tab-content #datamodelcustomquerytabview').addClass("active");
                                $('#datamodelsymanticnamestab').parent().removeClass("active")
                                $('.tab-content #datamodelsymanticnamestabview').removeClass("active");
                                setTimeout(function () {
                                    $("#datamodelsstepscontainer").steps("previous");
                                    $('.spinner').hide();
                                }, 800);
                                return false;
                            }
                        }
                        if (wscustomquname == undefined || wscustomquname == "") {
                            $(".spinner").hide();
                            $(".cscqnamespecailcharacter").hide();
                            $(".cscqnamevalidate").show();
                            isErrorExists = true;
                        } else if (wscustomquname != undefined && wscustomquname != "" && /[~`!@#$%^&*()+\=\[\]{};':\\|†,.<>\/?" ""`]/.test(wscustomquname)) {
                            $(".spinner").hide();
                            $(".cscqnamevalidate").hide();
                            $(".cscqnameexists").hide();
                            $(".cscqnamespecailcharacter").show();
                        }
                        else if (allentitiesNames.indexOf(wscustomquname) != -1) {
                            $(".spinner").hide();
                            $(".cscqnamevalidate").hide();
                            $(".cscqnamespecailcharacter").hide();
                            $(".cscqnameexists").show();
                        } else {
                            $(".cscqnamespecailcharacter").hide();
                            $(".cscqnamevalidate").hide();
                            $(".cscqnameexists").hide();
                            var dmcustomquname = $('.dmcustomqueryname').val().trim();
                            if (that.dmcustomquelemet != undefined) {
                                $(that.dmcustomquelemet).closest("li").removeClass("warning");
                                $(that.dmcustomquelemet).closest("li").addClass("active");
                                $(that.dmcustomquelemet).parent().find('label').contents().last()[0].textContent = dmcustomquname;
                                $(that.dmcustomquelemet).parent().find('input').attr('id', dmcustomquname);
                                $(that.dmcustomquelemet).parent().find('input').attr('data-value', dmcustomquname);
                                $(that.dmcustomquelemet).parent().find('input').attr('dmcqname', dmcustomquname);
                                $(that.dmcustomquelemet).parent().find('input').attr('name', dmcustomquname);
                                $(that.dmcustomquelemet).attr('dmcqname', dmcustomquname);
                            }
                            if ($("#datamodelsentitycontainer li").length > 0 && e != undefined && $(e.currentTarget).attr("id") != "savescustomquery") {
                                var emptyNameCount = 0;
                                $("#datamodelsentitycontainer>li").removeClass('warning');
                                _.each($("#datamodelsentitycontainer li"), function (ent, index) {
                                    var entity = $(ent).find("input.scopeitem");
                                    if ($(entity).hasClass("datamodelcustomqueryscope") && ($(entity).attr("dmcqname") == undefined || $(entity).attr("dmcqname") == "")) {
                                        $(ent).removeClass("active");
                                        $(ent).addClass("warning");
                                        emptyNameCount++;
                                    }
                                });
                                if (emptyNameCount > 0) {
                                    $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-infor').addClass('birdmessage-warning');
                                    $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-info-circle').addClass('fa-exclamation-triangle');
                                    $('.birdmessagebuttons').removeClass('show');
                                    $('.birdmessagebuttons').addClass('hide');
                                    $('.birdmessage-center').addClass("message-top-in");
                                    $('.details').removeClass('show');
                                    $('.details').addClass('hide');
                                    $('.birdmessage h2').empty().append('Warning');
                                    $('.birdmessage-info').empty().text(getMessage('BirdWarning135'));
                                    setTimeout(function () {
                                        $("#datamodelsstepscontainer").steps("previous");
                                        $('.spinner').hide();
                                    }, 800);
                                }
                            }
                            connectionJsObj["command"] = "getEntities";
                            connectionJsObj["isCustomQuery"] = true;
                            connectionJsObj["entityname"] = dmcustomquname;
                            connectionJsObj["indexName"] = "dm_" + dmcustomquname;
                            connectionJsObj["isFromDataModel"] = true;
                            var dataValue = new FormData();
                            dataValue.append("connectionObject", JSON.stringify(connectionJsObj));
                            SendRequest({
                                url: "validatecustomquery",
                                queryString: "",
                                body: dataValue,
                                sucessFunction: (response) => {
                                    if (!response.hasOwnProperty('error')) {
                                        that.workspaceCustomQueryCollection.addWsCustomQueryName(dmcustomquname);
                                        var updatedColumns = that.workspaceExistingEntityMetaDataCollection.getEntityColumns(wscustomquname, workspaceId);
                                        that.workspaceCustomQueryCollection.updatewsCustomQueryQueries(customQueryVal, dmcustomquname, workspaceId);
                                        that.workspaceCustomQueryCollection.updatewsCustomQueryEntityColumns(response, dmcustomquname, workspaceId);
                                        // if (updatedColumns == undefined) {
                                        that.workspaceExistingEntityMetaDataCollection.updateEntityColumns(response.entities, dmcustomquname, workspaceId);
                                        //  }
                                        ReactDOM.unmountComponentAtNode(document.getElementById('settingscontainer'))
                                        ReactDOM.render(
                                            <DatamodelItemSettings
                                                entityName={dmcustomquname}
                                                workspaceentityid={workspaceId}
                                                entitycollection={that.workspaceExistingEntityMetaDataCollection}
                                                isCustomQuery={true}
                                                workspaceCustomQueryCollection={that.workspaceCustomQueryCollection}
                                                //   type={data_type}
                                                dataModelMetaDataCollection={that.datamodelMetaDataCollection}
                                                validatedatamodelcustomquery={this.validatedatamodelcustomquery}
                                                savedatamodelcustomquery={this.savedatamodelcustomquery}
                                                response={response}
                                            />,
                                            document.getElementById('settingscontainer')
                                        );

                                        $("#settingscontainer").addClass("showsettings");
                                        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                                        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                                        $('.birdmessagebuttons').removeClass('show');
                                        $('.birdmessagebuttons').addClass('hide');
                                        $('.birdmessage-center').addClass("message-top-in");
                                        $('.details').removeClass('show');
                                        $('.details').addClass('hide');
                                        $('.birdmessage h2').empty().append('Success');
                                        $('.birdmessage-info').empty().text(getMessage('BirdSuccess49'));
                                        $('.spinner').hide();
                                        $("#datamodelcustomquerytab").click();
                                        $('#datamodelcustomquerytab').parent().removeClass("active");
                                        $('.tab-content #datamodelcustomquerytabview').removeClass("active");
                                        $('#datamodelsymanticnamestab').parent().addClass("active")
                                        $('.tab-content #datamodelsymanticnamestabview').addClass("active");
                                        $('.spinner').hide();
                                    } else {
                                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                                        $('.birdmessagebuttons').removeClass('show');
                                        $('.birdmessagebuttons').addClass('hide');
                                        $('.birdmessage-center').addClass("message-top-in");
                                        $('.details').addClass('hide');
                                        $('.birdmessage h2').empty().append('Error');
                                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                                        $('.birdmessage-info').empty().text(response.error);
                                        $('.details').addClass('show').text("Details");
                                        $('.messagedetails xmp').empty().append(response.errorTrace);
                                        $(".spinner").hide();
                                    }
                                },
                                rejectFunction: (error) => { },
                                headers: this.postRequestHeaders
                            });
                        }
                        if (isErrorExists) {
                            setTimeout(function () {
                                $("#datamodelsstepscontainer").steps("previous");
                                $('.spinner').hide();
                            }, 800);
                        }
                    } else {
                        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Success');
                        $('.birdmessage-info').empty().text(getMessage('BirdSuccess49'));
                        $('.spinner').hide();
                    }
                } else {
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').addClass('hide');
                    $('.birdmessage h2').empty().append('Error');
                    $('.birdmessage .errorcode').empty().append(response.errorCode);
                    $('.birdmessage-info').empty().text(response.error);
                    $('.details').addClass('show').text("Details");
                    $('.messagedetails xmp').empty().append(response.errorTrace);
                    //$(".spinner").hide();
                    setTimeout(function () {
                        $("#datamodelsstepscontainer").steps("previous");
                        $('.spinner').hide();
                    }, 800);
                }
            },
            rejectFunction: (error) => { },
            headers: this.postRequestHeaders
        });
    }
    validatedatamodelcustomquery = (e) => {
        this.vaidatequery(e, false);
    }
    savedatamodelcustomquery = (e) => {
        this.vaidatequery(e, true);
    }
}