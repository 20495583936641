import React from 'react'
import { lang } from "../js/helpers/utils/languages/language";
import Header from "../Home/Header";
const $ = window.$;
export default class SearchViewTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        var that = this;
        $("#clicksearchbutton").click(function (e) {
            e.preventDefault();
            that.props.formatquery("fromtyping");
        });
    }
     collapseMenu = (e) => {
        e.preventDefault();
        $(".is-container.datamodelsearchcontainer").toggleClass("closemenu");
    }
    render() {
        var that = this.props;
        return (<>
        <Header {...this.props} url={this.props.url} pageType={this.props.pageType} />
            {/* <!-- datamainview.js --> */}
            <section className="datamain-section-container">
                <div className="boc-home">
                    <div className="datamain-fixed-content-container home">
                        <div className="is-container datamodelsearchcontainer closemenu">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-xs-12 nopadding">
                                        <div className="input-group is-search-group-container">
                                            <div className="is-title"><i className="fa fa-cube fa-solid"></i>&nbsp; {lang["search.businessmodel"]}</div>
                                            <select data-placeholder="Select a Model" className="chosen-select" id="selectdatamodel">
                                                {that.datamodelslist.map((modelitem, index) => {
                                                    if (!modelitem.multifact && modelitem.DMStatus != "Error") {
                                                        return <option key={index} value={modelitem.DMID} multifact={modelitem.multifact+""} is_shared={modelitem.is_shared+""}>{modelitem.DMTitle}</option>
                                                    }
                                                })}

                                            </select>
                                            <button class="btn btn-bird marg-top-5" id="collapsesidebar" title="Show / Hide" onClick={this.collapseMenu}><i class="fa fa-angle-double-right"></i></button>
                                        </div>
                                        <div className="input-group is-search-group-container">
                                            <div className="is-title"><i className="fa fa-search fa-flip-horizontal"></i>&nbsp; {lang["search.intelligent"]}</div>
                                            {/* <!-- <input id="birdintelligentsearch"/>
								<span className="input-group-addon is-get-results searchbutton" id="clicksearchbutton"><i className="fa fa-search"></i></span> -->
								<!-- <div className="easy-autocomplete" style="width: 1217px;"><input id="birdintelligentsearch" placeholder="Search" autocomplete="off"></div> --> */}
                                            <div contentEditable="true" id="birdintelligentsearch" onClick={that.getnlpqueryresult} onKeyUp={that.queryTyping} onPaste={that.formatquery} className="resize-vertical custommeasurequerydisplay easy-autocomplete" placeholder={lang["search.placeholder"]}></div>
                                            <span className="input-group-addon is-get-results hide" id="clickclearbutton" onClick={that.clearQuery} title={lang["search.clear"]}><i className="fa fa-times"></i></span>
                                            <span className="input-group-addon is-get-results searchbutton" onClick={that.getnlpqueryresult} id="clicksearchbutton"><i className="fa fa-search fa-flip-horizontal"></i></span>
                                        </div>
                                        <div id="Intelligencyerror" style={{ color: "red", marginLeft: "385px" }}></div>
                                        <div class="is-chart-message nomargin text-right" style={{width: '100%'}}>
                                            <span class="is-add">
                                                <button class="btn btn-bird pull-right addchart marg-top-5 hide" id="addintelligentchart" title={lang["search.savereport"]}><i class="fa fa-floppy-o"></i></button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid" style={{ height: "calc(100% - 140px)" }}>
                                <div className="row height-100">
                                    <div className="row-same-height height-100">
                                        <div className="col-xs-12 col-lg-2 col-xs-height is-fields-container">
                                            <div className="is-title">{lang["search.availfields"]}</div>
                                            <ul className="list-group nlp-available-columns scrollset" ></ul>
                                        </div>
                                        <div className="col-xs-12 col-lg-8 col-xs-height is-chart-main-container">
                                            <div className="is-chart-container">
                                                <div id="nlpchart" className="width-100 maxanim10 intelligentsearchchart"></div>
                                                <div className="drawbootstraptable">
                                                    <div id="datatableresults" className="notifications-table-container  hidden">
                                                        <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                                                            <table id="datasearch"
                                                                className="table drawbootstraptable"
                                                                data-toggle="table" data-page-size="10"
                                                                data-smart-display="true" data-search="true"
                                                                data-pagination-v-align="bottom"></table>
                                                        </div>
                                                    </div>
                                                    <div className="clear"></div>

                                                    <div id="paginate" className="notifications-table-container col-xs-12 hidden">

                                                        <div className="bootstrap-table">
                                                            <div className="fixed-table-pagination">

                                                                <div className="pull-left pagination-detail">
                                                                    <span className="pagination-info">{lang["search.showing"]} </span>
                                                                    <span className="page-list">
                                                                        <span className="btn-group dropup">
                                                                            <button type="button" className="btn btn-default  dropdown-toggle auditrowlimtbutton" id="auditingtablerecords" data-toggle="dropdown">
                                                                                <span className="page-size">10</span>
                                                                                <span className="caret"></span>
                                                                            </button>
                                                                            <ul className="dropdown-menu btablerowlimit" role="menu" data-num="" tab-id="0" tab-name="">
                                                                                <li className="btablerowlimit1" value="25"><a href="javascript:void(0)">25</a></li>
                                                                                <li className="btablerowlimit1" value="50"><a href="javascript:void(0)">50</a></li>
                                                                                <li className="btablerowlimit1" value="100"><a href="javascript:void(0)">100</a></li>
                                                                            </ul>
                                                                        </span> {lang["search.records"]}
					</span>
                                                                </div>
                                                                <div className="pull-right pagination">
                                                                    <ul id="datatable-pagination-view" className="pagination-sm"></ul>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- <div className="is-chart-container">
					<div id="nlpchart" className="width-100 maxanim10 intelligentsearchchart"></div>
				</div> --> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div id="updatesinglereportdiv"></div>
            </section>
            {(that.displayFooter != false) ? (
                <footer className="footer">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xs-7">
                                {/* <!-- <p className="text-muted"><%=whitelableMap["footertext"]%></p> -->
				<!-- <div className="timezone hide pull-right">
					<span className="servertime"><b>Server Time:</b></span> <span
						className="servertimezone" style="margin-right: 20px">Fri Sep
						27 2019 10:37:00 GMT+0530 </span> <span className="localtime"><b>Client
							Time:</b></span> <span className="localtimezone"> Fri Sep 27 2019
						10:37:00 GMT+0530 </span>
				</div> --> */}
                            </div>
                        </div>
                    </div>
                </footer>
            ) : null}
        </>);
    }
}