import React from "react";
import ReactDOM from 'react-dom'
import SendRequest from "../SendRequest";
import { getMessage } from '../js/helpers/utils/BirdMessage';
import PubSub from 'pubsub-js'
import jstz from 'jstz';
import ViewReportInfo from '../ViewStoryboard/ViewReportInfo.jsx';
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import * as ModalProperties from "../js/helpers/utils/modalproperties";
import ViewScheduleReportModal from '../ViewStoryboard/viewschedulereport';
import SaveStoryboardHtml from '../ViewStoryboard/SaveStoryboardModal.jsx';
import UserCustomscriptModal from '../ViewStoryboard/UserCustomscriptModal.jsx';
import StoryboardTheme from '../ViewStoryboard/StoryboardColorThemeModal.jsx'
import EmbedReportView from '../ViewStoryboard/embedreportview'
import ShareReportModal from '../ViewStoryboard/sharereportmodal'
import CustomStoryboardHeaderTemplate from './CustomStoryboardHeaderTemplate.jsx'
import { lang } from "../js/helpers/utils/languages/language";
import _ from 'lodash';
const $ = window.$;


export default class CustomStoryboardHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.state.reportId = this.props.reportId;
        this.state.datamodelId = this.props.datamodelId;
        this.state.features = this.props.features;
        this.state.name = this.props.name;
        this.state.timeZoneType = this.props.timeZoneType;
        this.state.folder = this.props.folder;
        this.state.syncConfigData = this.props.syncConfigData;
        this.state.desc = this.props.desc;
        this.state.schemaType = undefined;
        this.state.isStoredProcedure = undefined;
        var that = this;
        this.state.facttables = this.props.facttables;
        this.state.multifact = (this.props.multifact == 'true');
        this.state.multifactentities = this.props.multifact == 'true' ? JSON.parse(this.props.multifactentities) : this.props.multifactentities;
        this.updatembbedreportEvee = PubSub.subscribe('updatembbedreport', this.updatembbedreport.bind(this));
        this.state.isImportedReport = this.props.isImportedReport;
        this.state.isFromSmartInsigt = this.props.isFromSmartInsigt;
    }
    componentDidMount() {
        if (this.state.isImportedReport) {
            var folder = this.props.folder
            if (folder === "") { folder = "Default" }
            var path = '<li><a class="navDashboard" href="/'+window.localStorage.getItem("appName")+'/welcome/visualization"><i class="fa fa-bar-chart fa-solid"></i> '+lang["customboard.visualization"]+'</a></li><li><a href="javascript:void(0)"><i class="fa fa-bar-chart"></i> '+lang["customboard.newboard"]+'</a></li>'
        } else {
            var folder = this.props.folder
            if (folder === undefined || folder === "") { folder = "Default" }
            var path = '<li><a class="navDashboard" href="/'+window.localStorage.getItem("appName")+'/welcome/visualization"><i class="fa fa-bar-chart fa-solid"></i> '+lang["customboard.visualization"]+'</a></li><li><a href="javascript:void(0)"><i class="fa fa-bar-chart"></i> '+lang["customboard.newboard"]+'</a></li>'
            if (this.state.reportId != undefined && this.state.reportId != "") {
                var path = '<li><a class="navDashboard" href="/'+window.localStorage.getItem("appName")+'/welcome/visualization"><i class="fa fa-bar-chart fa-solid"></i> '+lang["customboard.visualization"]+'</a></li><li><a href="javascript:void(0)"><i class="fa fa-folder"></i> ' + folder + '</a></li><li><a><i class="fa fa-bar-chart"></i> ' + this.props.name + '</a></li>'
            }
        }
        $(".navbar.navbar-bird ul.nav-path").html(path);
    }
    render() {
        if (sessionStorage.getItem("isImportedReport") == "true" || window.location.pathname.indexOf("importreport") >= 0) {
            this.state.isImportedReport = true;
        }
        var tempColumns = [];
        var title = this.props.title;
        var schemaType;
        if (this.state.schemaType != undefined && this.state.schemaType != '' && this.state.schemaType != null)
            schemaType = (this.state.schemaType).toLowerCase();
        var schemaTypeFlag;
        var schemaTypeFlag1;
        var schemaTypeFlag2;
        var purgeHidden;
        if (title === undefined) {
            title = lang["customboard.board"];
        }
        var isSharedReport = false;
        if (window.location.pathname.indexOf("sharecustomstoryboard") >= 0) {
            isSharedReport = true;
        }
        var isViewModel = false;
        if (window.location.pathname.indexOf("viewmodel") >= 0) {
            isViewModel = true;
            title = lang["customboard.smartinsights"];
        }
        var isEmbeddedReport = false;
        var isEmbeddedCSReport = false;
        if (window.location.pathname.indexOf("embeddedreport") >= 0) {
            isEmbeddedReport = true;
        }
        if (window.location.pathname.indexOf("embeddedcustomstoryboard") >= 0) {
            isEmbeddedCSReport = true;
        }
        var isScheduleReport = false;
        if (window.location.pathname.indexOf("schedulereport") >= 0) {
            isScheduleReport = true;
        }
        var isPurgedReport = false;
        if (window.location.pathname.indexOf("viewpurgereport") >= 0) {
            isPurgedReport = true;
        }


        var isDirect = ChartInfo.functions.getIsDirect();
        if (this.state.isStoredProcedure != undefined) {
            ChartInfo.functions.setIsStoredProcedure(this.state.isStoredProcedure);
        }
        if (this.props.viewreportCollection != undefined && this.props.viewreportCollection.getSharedViewOnlyFlag != undefined &&
            this.props.viewreportCollection.getSharedViewOnlyFlag() == 1) {
            isEmbeddedReport = true;
            isPurgedReport = true;
        }
        var isMicrosoftEdge = false;
        if (/Edge\/\d./i.test(navigator.userAgent)) {
            isMicrosoftEdge = true
        }

        return (<div>
            <CustomStoryboardHeaderTemplate
                {...this.props}
                isfavorite={this.props.viewreportCollection.model.isfavorite}
                reportRequestFrom={this.props.reportRequestFrom}
                isMicrosoftEdge={isMicrosoftEdge}
                title={this.props.title}
                isScheduleReport={isScheduleReport}
                isSharedReport={isSharedReport}
                isEmbeddedReport={isEmbeddedReport}
                allfeatures={this.state.features}
                reportInfo={this.props.reportInfo}
                Report_Info={this.props.Report_Info}
                datamodelId={this.state.datamodelId}
                sharedInfo={this.props.sharedInfo}
                scheduleInfo={this.props.scheduleInfo}
                alertsInfo={this.props.alertsInfo}
                filtersArray={this.props.filtersArray}
                reportId={this.state.reportId}
                reportcolumns={this.props.reportcolumns}
                reportName={this.state.name}
                reportDesc={this.state.desc}
                isImportedReport={this.state.isImportedReport}
                multifact={this.state.multifact}
                scheduleReportModel={this.props.scheduleReportModel}
                storyboardcolortheme={this.storyboardcolortheme}
                setAsFavourite={this.setAsFavourite}
                viewEmbedreport={this.viewEmbedreport}
                showschedulemodal={this.showschedulemodal}
                showsharestroyboardmodal={this.showsharestroyboardmodal}
                liveReportOpen={this.liveReportOpen}
                isFromSmartInsigt={this.state.isFromSmartInsigt}
                exportstoryboardreport={this.exportstoryboardreport}
                auditmetadata={this.auditmetadata}
                isEmbeddedCSReport={isEmbeddedCSReport}
            />
        </div>);
    }
    viewEmbedreport = (e) => {
        if (this.state.reportId == null || this.state.reportId == undefined || this.state.reportId == "" || window.location.pathname.indexOf("importreport") >= 0 || window.location.pathname.indexOf("sharereport") >= 0) {
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Information');
            $('.birdmessage-info').empty().text(getMessage("BirdInformation01"));
        } else {
            e.preventDefault();
            var name = e.currentTarget;
            ModalProperties.resize();
            var that = this;
            let requestHeaders = {
                method: "get",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            SendRequest({
                url: "getServerTime",
                queryString: "",
                sucessFunction: response => {
                    var embedReportView = new EmbedReportView({
                        reportId: that.state.reportId,
                        reportName: that.state.name,
                        servertimezone: response.serverTimeZone,
                        features: that.state.features,
                        isCustomStoryboard:true
                    });
                },
                rejectFunction: () => { },
                headers: requestHeaders
            });
        }
    }

    storyboardcolortheme = (e) => {
        var $this = e.currentTarget;
        var reporttheme1 = new StoryboardTheme(
            {
                chartnumber: $($this).closest('.maxanim1').attr("data-num")
            });
        ModalProperties.resize();
    }
    setAsFavourite = () => {
        if (this.state.reportId === undefined || this.state.reportId === "" || window.location.pathname.indexOf("sharereport") >= 0 || window.location.pathname.indexOf("importreport") >= 0) {
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Information');
            $('.birdmessage-info').empty().text(getMessage('BirdInformation01'));
        }
        else {
            var favmodel = new FormData();
            favmodel.set("reportId", this.state.reportId);
            favmodel.set("isFromCustomStoryboard", true);
            
            let requestHeaders = {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            SendRequest({
                url: "setasfavourite",
                body: favmodel,
                sucessFunction: response => {
                    if (response.hasOwnProperty('error')) {
                        $(".spinner").hide();
                        $(".gridspinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.errorTrace)
                    } else
                        $('#setstoryboardasfavourite i').addClass("fa-yellow");
                },
                rejectFunction: this.reportDataError,
                headers: requestHeaders
            });
        }
    }
    updatembbedreport() {
        var dateSelection;
        var timeSelection;
        var dateSelected = $('#embedreportenddate').val();
        var timeSelected = $('#embedreportendTime').val();
        if (dateSelected !== "" && timeSelected === "") {
            $('.spinner').hide();
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(getMessage('BirdWarning75'));
            $("#embedurl").prop("disabled", true);
            $("#emailembedurlbutton").prop("disabled", true);
            return false;

        } else if (timeSelected !== "" && dateSelected === "") {
            $('.spinner').hide();
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(getMessage('BirdWarning74'));
            $("#embedurl").prop("disabled", true);
            $("#emailembedurlbutton").prop("disabled", true);
            return false;

        } else if (timeSelected !== "" && dateSelected !== "") {
            dateSelection = dateSelected;
            timeSelection = timeSelected;
            $("#embedurl").prop("disabled", false);
            $("#emailembedurlbutton").prop("disabled", false);
        } else if (timeSelected === "" && dateSelected === "") {
            $('#embedreportendTime').val("");
            dateSelection = null;
            timeSelection = null;
            $("#embedurl").prop("disabled", false);
            $("#emailembedurlbutton").prop("disabled", false);
        }

        var loginTypeReport = $(".reportLoginType input[type='radio']:checked").attr("id");
        var urlAccessingTypeReport = $(".reportUrlAccessing input[type='radio']:checked").attr("id");

        var that = this;
        var report;
        // if (that.state.reportId == undefined) {
        //     if (loginTypeReport != undefined && loginTypeReport == "1") {
        //         report = $('#embedurl').val().split('=')[1].split('&')[0];
        //     } else if (loginTypeReport != undefined && loginTypeReport == "2") {
        //         report = $('#embedurl').val().split('/').pop();
        //     }
        // } else {
        //     if (that.state.reportId != $('#embedurl').val().split('=').pop()) {
        //         report = $('#embedurl').val().split('=')[1].split('&')[0];
        //     }
        //     if (that.state.reportId != $('#embedurl').val().split('/').pop()) {
        //         if (loginTypeReport != undefined && loginTypeReport == "1") {
        //             report = $('#embedurl').val().split('=')[1].split('&')[0];
        //         }
        //         else if (loginTypeReport != undefined && loginTypeReport == "2") {
        //             report = $('#embedurl').val().split('/').pop();
        //         }
        //     } else {
        //         report = that.state.reportId;
        //     }
        // }

        report = that.state.reportId;
        var embedtimezone = $('.embedtimezone:checked').attr("id")
        if (embedtimezone == "localtimezone") {
            var localTimezone = jstz.determine().name();
        }
        var accessPermissionId = $('.publishurl:checked').attr("id");
        var formData = new FormData();
        formData.set("dateSelectionn", dateSelection);
        formData.set("timeSelectionn", timeSelection);
        formData.set("reportId", btoa(report));
        formData.set("embeddedLoginId", accessPermissionId);
        formData.set("localTimezone", localTimezone);
        formData.set("pathName", window.location.origin + "/"+window.localStorage.getItem("appName")+"/welcome")

        let requestHeaders = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "publishreport",
            body: formData,
            sucessFunction: (response) => {
                if (accessPermissionId == 1) {
                    $("#embedurl").html(response.private);
                    if ($('.publishurl:checked').attr("id") == "1") {
                        $('.publishurl:checked').attr("purl", response.private)
                    }
                } else if (accessPermissionId == 2) {
                    $("#embedurl").html(response.public);
                    if ($('.publishurl:checked').attr("id") == "2") {
                        $('.publishurl:checked').attr("purl", response.public)
                    }
                }
            },
            rejectFunction: this.reportDataError,
            headers: requestHeaders
        });
    }
    
    showschedulemodal = (e) => {
        if (this.state.reportId == null || this.state.reportId == undefined || this.state.reportId == "" || window.location.pathname.indexOf("importreport") >= 0 || window.location.pathname.indexOf("sharereport") >= 0) {
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Information');
            $('.birdmessage-info').empty().text(getMessage("BirdInformation01"));
        } else {
            e.preventDefault();
            var name = e.currentTarget;
            var that = this;
            let requestHeaders = {
                method: "get",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            SendRequest({
                url: "getServerTime",
                queryString: "",
                sucessFunction: response => {
                    var viewScheduleReportModal = new ViewScheduleReportModal({
                        reportId: that.state.reportId,
                        reportName: that.state.name,
                        servertimezone: response.serverTimeZone
                    });
                },
                rejectFunction: () => { },
                headers: requestHeaders
            });
            ModalProperties.resize();
        }
    }
  
    showsharestroyboardmodal = (e) => {
        if (this.state.reportId === undefined || this.state.reportId == "" || window.location.pathname.indexOf("sharereport") >= 0 || window.location.pathname.indexOf("importreport") >= 0) {
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Information');
            $('.birdmessage-info').empty().text(getMessage('BirdInformation01'));
        }
        else {
            var shareReportModel = new ShareReportModal({
                reportId: this.state.reportId,
                singlereport: false,
                isCustomStoryboard: true,
                // model:new ShareReportValidation(),
                dimensions: this.props.viewreportCollection.getDimensions(),
                sharedFilters: this.props.viewreportCollection.getSharedFilters(),
                datamodelId: this.props.datamodelId
            });
        }
    }
  
}