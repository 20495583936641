import React from 'react'
import { lang } from "../../js/helpers/utils/languages/language";
export default class SamrtInsightsViewTempalte extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        var that = this.props;
        return (
            <>
                <div className="container-fluid hide">
                    <div className="row">
                        <div className="col-xs-12 nopadding smartinsights-jumbotron">
                            <img className="pull-left marg-left-10 smartinsightlogo" alt={lang["smartsights.runinsights"]} src={require('../../images/birdimages/smartinsightspinner.png')} />
                            <div className="col-xs-11 nopadding" style={{ width: "calc(100% - 100px)" }}>
                                <h1>{lang["smartsights.title"]}</h1>
                            </div>
                        </div>
                    </div>
                    {/* <!-- /row --> */}
                </div>
                <div className="container-fluid smartinsightcontentcontainer" tabid={that.tabid}>
                    <div className="row">
                        <div className="col-xs-12">
                            <h2>{lang["smartsights.overview"]}</h2>
                        </div>
                        <div className="col-xs-12">
                            <div className="row">
                                <div className="row-same-height smartinsightskeyinfo" tabid={that.tabid}>

                                    <div className="col-md-2 col-xs-12 col-xs-height nopadding">
                                        <div className="insightquality" tabid={that.tabid}>
                                            <h3>{lang["smartsights.quality"]}</h3>
                                            {/* <div aria-label="..." role="group" className="btn-group pull-right dropdown" style={{ opacity: 1 }}> */}
                                                <button className="btn btn-transparent mltooltip hide" style={{ color: '#0a98f3', fontSize: '20px' }} data-num={that.chartnumber} tab-id="0" tab-name="" title={lang["mlreports.tooltiploading"]} aria-describedby="ui-id-8"><i className="fa fa-lightbulb-o"></i></button>
                                            {/* </div> */}
                                            <div className="overviewchart" tabid={that.tabid}>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5 col-xs-12 col-xs-height nopadding">
                                        <div className="insightcorrelatedcolumns" tabid={that.tabid}>
                                            <h3>{lang["smartsights.corelatecolumns"]}</h3>
                                            {/* <div aria-label="..." role="group" className="btn-group pull-right dropdown" style={{ opacity: 1 }}> */}
                                                <button className="btn btn-transparent mltooltip hide" style={{ color: '#0a98f3', fontSize: '20px' }} data-num={that.chartnumber} tab-id="0" tab-name="" title={lang["mlreports.tooltiploading"]} aria-describedby="ui-id-8"><i className="fa fa-lightbulb-o"></i></button>
                                            {/* </div> */}
                                            <div className="overviewchart" tabid={that.tabid}></div>
                                        </div>
                                    </div>
                                    <div className="col-md-5 col-xs-12 col-xs-height nopadding">
                                        {/* <div className="insightfindings" tabid={that.tabid}>
                                            <h3>{lang["smartsights.findings"]}</h3>
                                            <div className="overviewchart" tabid={that.tabid}></div>
                                        </div> */}
                                        <div className="insightquality mlchisquaretable">
                                            <h3>{lang["mlreports.chisquaretable"]}</h3>
                                            {/* <div aria-label="..." role="group" className="btn-group pull-right dropdown" style={{ opacity: 1 }}> */}
                                                <button className="btn btn-transparent mltooltip hide" style={{ color: '#0a98f3', fontSize: '20px' }} data-num={that.chartnumber} tab-id="0" tab-name="" title={lang["mlreports.tooltiploading"]} aria-describedby="ui-id-8"><i className="fa fa-lightbulb-o"></i></button>
                                            {/* </div> */}
                                            <div className="overviewchart"></div>
                                        </div>
                                    </div>
                                    
                                    {/* <!-- <div className="col-md-3 col-xs-12 col-xs-height nopadding">
						<div className="insightcovariance">
							<h3>Covariance</h3>
							<div className="overviewchart"></div>
						</div>
					</div> -->	 */}

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- /row --> */}
                    <div className="row marg-top-10">
                        <div className="col-xs-12">
                            <h2 className="whatinfluences" tabid={that.tabid}>{lang["smartsights.whatinfluences"]} <span></span></h2>
                        </div>
                        <div className="col-xs-12">
                            <div className="row">
                                <div className="row-same-height smartinsightsinfluences" tabid={that.tabid}>

                                    <div className="col-md-4 col-xs-12 col-xs-height nopadding">
                                        <div className="keyinfluencers" tabid={that.tabid}>
                                            <h3>{lang["smartsights.keyinfluences"]}</h3>
                                            {/* <div aria-label="..." role="group" className="btn-group pull-right dropdown" style={{ opacity: 1 }}> */}
                                                <button className="btn btn-transparent mltooltip hide" style={{ color: '#0a98f3', fontSize: '20px' }} data-num={that.chartnumber} tab-id="0" tab-name="" title={lang["mlreports.tooltiploading"]} aria-describedby="ui-id-8"><i className="fa fa-lightbulb-o"></i></button>
                                            {/* </div> */}
                                            <div className="influenceschart" tabid={that.tabid}>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5 col-xs-12 col-xs-height nopadding">
                                        <div className="insightavg nopadding" tabid={that.tabid}>
                                            <h3> <span> </span></h3>
                                            <div className="col-xs-12 insightavgsuggestion0 nopadding" tabid={that.tabid}>
                                                <div className="influenceschart" tabid={that.tabid}></div>
                                            </div>
                                            <div className="col-xs-12 col-md-6 insightavgsuggestion1 nopadding marg-top-10" tabid={that.tabid}>
                                                <h3>
                                                    <span> </span>
                                                </h3>
                                                <div className="influenceschart" tabid={that.tabid}></div>
                                                <div className="gridspinner" id="spinnerchart9" data-num="chart9"
                                                    style={{ display: "none" }} tabid={that.tabid}>
                                                    <div className="gridspinnercontent gridspinnermessage" tabid={that.tabid}></div>
                                                    <div className="gridspinnercontent" tabid={that.tabid}>
                                                        {/* <!-- <img alt="Loading..." src="img/loading9.gif"> -->
										<!-- <div className="semi-circle-spin"><div></div></div> --> */}
                                                        <div className="gridload">
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-md-6 insightavgsuggestion2 nopadding marg-top-10" tabid={that.tabid}>
                                                <h3> <span></span></h3>
                                                <div className="influenceschart" tabid={that.tabid}></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-xs-12 col-xs-height nopadding">
                                        <div className="insightnlg" tabid={that.tabid}>
                                            <h3>{lang["smartsights.insightson"]} <span></span></h3>
                                            {/* <div aria-label="..." role="group" className="btn-group pull-right dropdown" style={{ opacity: 1 }}> */}
                                                <button className="btn btn-transparent mltooltip hide" style={{ color: '#0a98f3', fontSize: '20px' }} data-num={that.chartnumber} tab-id="0" tab-name="" title={lang["mlreports.tooltiploading"]} aria-describedby="ui-id-8"><i className="fa fa-lightbulb-o"></i></button>
                                            {/* </div> */}
                                            <div className="influenceschart" tabid={that.tabid}>

                                                <ul className="nlg-list" tabid={that.tabid}>

                                                </ul>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- /row -->	 */}
                    <div className="row marg-top-10">
                        <div className="col-xs-12">
                            <h2>{lang["smartsights.predictvalues"]}</h2>
                        </div>
                        <div className="col-xs-12">
                            <div className="notifications-table-container">
                                <table className="outliertable" data-toggle="table" data-pagination="true" data-page-size="10" data-smart-display="true" data-search="true" data-pagination-v-align="bottom" data-show-columns="true" tabid={that.tabid}>
                                    <colgroup></colgroup>
                                    <thead>
                                        <tr></tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                    {/* <!-- /row -->	 */}

                    <div className="row marg-top-10 marg-bot-30">
                        <div className="col-xs-12 simulationcselections marg-top-20" tabid={that.tabid}></div>
                        <div className="col-xs-12">
                            <h2 className="simulationwith" tabid={that.tabid}>{lang["smartsights.simulationwith"]} <span></span></h2>
                        </div>
                        <div className="col-xs-12 marg-bot-30">
                            <div className="row">
                                <div className="row-same-height">
                                    <div className="col-xs-9 col-xs-height simulationselectioncontainer marg-top-15" tabid={that.tabid}>
                                        <div className="col-xs-12 nopadding">
                                            <div className="col-xs-12 simulationwaterfall marg-top-10" tabid={that.tabid}></div>
                                            <div className="gridspinner" id="spinnerchart10" data-num="chart10" style={{ display: "none" }} tabid={that.tabid}>
                                                <div className="gridspinnercontent gridspinnermessage" tabid={that.tabid}></div>
                                                <div className="gridspinnercontent" tabid={that.tabid}>
                                                    <div className="gridload">
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-xs-12 simulationcselections nopadding marg-top-20" tabid={that.tabid}></div> */}

                                    </div>
                                    <div className="col-xs-3 col-xs-height expectedvaluecontainer" tabid={that.tabid}>
                                        {/* <h3>{lang["smartsights.expected"]} <span></span></h3> */}
                                        <div className="col-xs-12"><div className="expectedvalue" tabid={that.tabid}></div></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- /row -->	 */}
                </div>
                {/* <!-- Attributes --> */}
                <div className="rightsmartattributes">
                    <div className="smartinsightoptions" onClick={that.smartinsightoptions}><i className="fa fa-smartinsights-small"></i></div>
                    <div className="rightsmartattributes_container"></div>
                </div>
            </>
        );
    }
}
