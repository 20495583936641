import React from "react";
import { lang } from "../../js/helpers/utils/languages/language";
import _ from "underscore";
export default class ViewMLReportBodyTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        var that = this.props;
        return (
            <div key={that.chartnumber} className="tileview col-xs-12 maxanim1 grid-stack-item" data-gs-y={that.y} data-gs-x={that.x} data-gs-width={that.gridwidth} data-gs-height={that.gridheight} data-num={that.chartnumber} tab-id="0" tab-name="">
                <div className="boc-snapshot-auto maxanim2" style={{ height: 'inherit' }}>
                    <div className="boc-snapshot-options maxanim3" data-num={that.chartnumber} title={that.title} tab-id="0" tab-name={that.title}>
                        <div className="dragme btn btn-transparent ui-draggable-handle" title={lang["mlreports.movetile"]}><i className="fa fa-arrows"></i></div>
                        <div aria-label="..." role="group" className="btn-group pull-right dropdown" style={{ opacity: 1 }}>
                            <button className="btn btn-transparent mltooltip hide" style={{ color: '#0a98f3', fontSize: '20px' }} data-num={that.chartnumber} tab-id="0" tab-name="" title={lang["mlreports.tooltiploading"]} aria-describedby="ui-id-8"><i className="fa fa-lightbulb-o"></i></button>
                        </div>
                        <div className="boc-snapshot-title  maxanim4 maximizesetting1 mlcharttitle" style={{ color: '#2737a3', fontWeight: 'bold' }} data-num={that.chartnumber} tab-id="0" tab-name="" title={that.title}>{that.title}</div>
                    </div>
                    <div className={`richtextcontrolschartinfo${that.chartnumber} pull-left"`} style={{ display: "none" }}>
								<span className="countcharacters" data-num={that.chartnumber}></span>
                    </div>
					<div className="opacitydiv">
								<div className={`btn-group pull-right richtextcontrolschart${that.chartnumber} marg-top-5 marg-righ-10`} style={{ display: "none" }}>
									<button type="button" className="btn btn-bird btn-sm editrichtext marg-righ-5" id="editopacity" data-num={that.chartnumber} onClick={(e) => { that.editrichtext(e); }}>{lang["storyboard.edit"]}</button>
									<button type="button" className="btn btn-bird btn-sm cancelrichtext marg-righ-5" id="cancelopacity" data-num={that.chartnumber} onClick={(e) => { that.cancelrichtext(e); }}>{lang["storyboard.cancelbtn"]}</button>
									<button type="button" className="btn btn-bird btn-sm saverichtext" id="applyopacity" data-num={that.chartnumber} onClick={(e) => { that.applyTextChart(e); }}>{lang["storyboard.applybtn"]}</button>
								</div>
					</div>
                    <div className={`col-xs-12 datedropdown${that.chartnumber}`} id={`datedropdown${that.chartnumber}`} data-num={that.chartnumber} tab-id="0" tab-name="" style={{ display: 'none' }}>
                        <select className="pull-right datelistformlchart">
                        {that.dateDropDown!=undefined?that.dateDropDown.map((list,index) =>{
                                return <option key={index} value={list.columnName}>{list.columnDisplayName}</option>
                            }):<React.Fragment/>}
                        </select>
                    </div>
                    <div className="clear"></div>
                    <div className="col-xs-12 nopadding maxanim5">
                        <div className="col-xs-12 nopadding maxanim7" data-num={that.chartnumber}>
                            <div className="container-fluid nopadding maxanim8">
                                <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12 fullscreensample nopadding">
                                    <div className="grid-stack-item-content width-100 maxanim10" id={`mlchart${that.chartnumber}`}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="gridspinner" id={`spinnerchart ${that.chartnumber}`} data-num={that.chartnumber} tab-id="0" tab-name="" style={{ display: 'none' }}>
                        <div className="gridspinnercontent gridspinnermessage"></div>
                        <div className="gridspinnercontent">
                            <div className="gridload"><div></div><div></div><div></div><div></div></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}