import React from 'react';
import { lang } from "../js/helpers/utils/languages/language";
export default class GenericJDBCViewHtml extends React.Component {

    render(){
        return(
            <React.Fragment>
                <>
    <div class="modal-header boc-modal-hf-padding">
        <h4 class="modal-title">
            <i class="fa fa-link marg-righ-10"></i>{lang["datahub.filestitle"]}
        </h4>
    </div>
    <div class="modal-body">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xs-12">
                <div class="databaseconnectioncontainer">
                <form class="form-horizontal marg-bot-20 kafkaform">
    
                            <div class="form-group form-group-sm">
                                <label for="" class="col-sm-4 control-label "> <img
                                    src={require(`../images/birdimages/${this.props.schemaName.toLowerCase()}.png`)}/>
                                </label>
                                <div class="col-sm-6">
                                    <h3>{this.props.schemaDisplayName} {lang["datahub.deatils"]}</h3>
                                </div>
                            </div>
                            <div class="form-group form-group-sm required"> 
                                    <label for="genericjdbcconnectionName" class="col-sm-3 control-label">{lang["datahub.scopename"]}</label>
                                    <div class="col-sm-6" style={{ marginTop: "2px" }}>
                                        <input type="text" className="form-control connectionname" class={this.props.isnewConnection==false ?'form-control webdataconnectorscopename disabled':'form-control webdataconnectorscopename'} name="genericjdbcconnectionName" id="genericjdbcconnectionscope" defaultValue={this.props.isnewConnection === false ? this.props.connectionjson.schemaname : ''}  />
                                        <span id="connectionname" className="help-block hide"></span>
                                                <span className="nameerror" style={{display: "none",color: "red"}}>
                                            {lang["datahub.namevalid"]}
                                            </span>
                                            <span class="nameerrors" style={{display: "none",color: "red"}}>{lang["datahub.nameerror"]}</span>
                                        </div>
                                </div>
                                <div class="form-group form-group-sm">
                                    <label for="connectionstringname" class="col-sm-3 control-label important">{lang["datahub.cnctnstrng"]}</label>
                                    <div class="col-sm-6">
                                        <input type="text" class="form-control connectionstring " name="connectionstringname"  id="connectionstringnamescope" required="required" defaultValue={this.props.isnewConnection === false ? this.props.connectionjson.connectionString : ''}/>
                                        <span id="connectionstring" class="help-block hide "><span></span></span>
                                    </div>
                                </div>
                                <div class="form-group form-group-sm">
                                    <label for="jarfile" id="webdataconnectorendpoints" class="col-sm-3 control-label important">{lang["datahub.jarfile"]}</label>
                                    
                                    <div class="col-sm-6">
                                        <input id="fileupload" type={this.props.isnewConnection==false ?'text':'file'} class={this.props.isnewConnection==false ?'disabled form-control ':'file'}  name="" data-show-preview="false" accept=".jar" required="required" defaultValue={this.props.isnewConnection === false ? this.props.connectionjson.jarfilename : ''}/>
                                        {/* if(isnewConnection==true){ */}
                                        <span class="csverror" 
                                         style={{
                                         display: "none",
                                         color: "red"
                                            }} >
                                         {lang["datahub.slctfile"]}</span>
                                        <span class="csvcustomerror"
                                         style={{
                                            display: "none",
                                            color: "red"
                                               }} >

                                         </span>
                                         </div>
                                    </div>
                                    <div class="form-group form-group-sm">
                                    <label for="driverclassname" class="col-sm-3 control-label important">{lang["datahub.drivername"]}</label>
                                    <div class="col-sm-6">
                                        <input type="text" class={this.props.isnewConnection==false ?'form-control driverclass disabled':'form-control driverclass'}  name="driverclassname" id="newdriverclass" required="required" defaultValue={this.props.isnewConnection === false ? this.props.connectionjson.driverClass : ''}/>
                                        <span id="driverclass" class="help-block hide "><span></span></span>
                                    </div>
                                </div>
                                
                                    <div class="form-group form-group-sm">
                                    <label for="username" class="col-sm-3 control-label important">{lang["datahub.username"]}</label>
                                    <div class="col-sm-6">
                                        <input type="text" class="form-control username" name="username" id="user" required="required" defaultValue={this.props.isnewConnection === false ? this.props.connectionjson.connectionUserName : ''}/>
                                        <span id="username" class="help-block hide "><span></span></span>
                                    </div>
                                </div>
    
                                <div class="form-group form-group-sm">
                                    <label for="password" class="col-sm-3 control-label important">{lang["datahub.pwd"]}</label>
                                    <div class="col-sm-6">
                                        <input type="password" class="form-control password" name="password" id="pass" required="required" defaultValue={this.props.isnewConnection === false ? this.props.connectionjson.connectionPassword : ''}/>
                                        <span id="password" class="help-block hide "><span></span></span>
                                    </div>
                                </div>
    
    </form>
    </div>
                </div>
                     </div>
                         </div>
                             </div>
    
    
    
            <div class="modal-footer">
        <button type="button" class="btn btn-bird pull-left testgenericjdbc" onClick={this.props.testconnection}>
            <i class="fa fa-play"></i> {lang["datahub.testcnctn"]}
        </button>
        <button type="button" class="btn btn-bird" id="closeconnectionpop" onClick={this.props.close}>
            <i class="fa fa-times"></i> {lang["datahub.canclbtn"]}
        </button>
        <button type="button" class="btn btn-bird active genericjdbcsavenewconnection" onClick={this.props.saveGenericDataBaseConnection}
            id="savenewconnection">
            <i class="fa fa-floppy-o"></i> {lang["datahub.savebtn"]}
        </button>
    </div>
    </>
    </React.Fragment>
        )
    }
}