import React from "react"
import { lang } from "../../js/helpers/utils/languages/language";
export default class PivotChartSettingsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        var that = this.props;
        return (
            //    <!-- pivotchartsettingsmodalview.js -->
            <div className={`modal fade pivotchartsettingsmodals${that.chartnumber} in`} id="pivotpagechartsettingsmodal" tabindex="-1" role="dialog" aria-labelledby="pivotpagechartsettingsmodal" aria-hidden="true" style={{ display: "block" }}>
                <div className="modal-backdrop fade in"></div>
                <div className="modal-dialog modal-sm modal-dialog-center" style={{ zIndex: "1050" }}>
                    <div className="modal-content">
                        <div className="modal-header boc-modal-hf-padding">
                            <button type="button" className="close white" id="pivotchartclose" aria-hidden="true" data-dismiss="modal" onClick={that.cancelSettingsModal} data-number={that.chartnumber}>&times;</button>
                            <h4 className="modal-title"><i className="fa fa-cogs"></i> {lang["storyboard.pivotchartsettings"]}</h4>
                        </div>
                        <div className="modal-body scrollset">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-xs-12 marg-top-10 marg-bot-10">
                                        <div className="modal-height-fix" style={{ display: "inline-block" }}>
                                            <div className="col-xs-12 nopadding">
                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-7">
                                                    <span className="toggle-title">{lang["storyboard.charttitle"]}</span>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-3">
                                                    <div className="checkbox checkbox-slider--c">
                                                        <label>
                                                            {(that.chartSettings.chartTitle == true) ? (
                                                                <> <input type="checkbox" defaultChecked="checked" id="chartTitle" className="pivotchart_settings" /><span>&nbsp;</span></>
                                                            ) :
                                                                <> <input type="checkbox" id="chartTitle" className="pivotchart_settings" /><span>&nbsp;</span></>
                                                            }
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xs-12 nopadding">
                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-7">
                                                    <span className="toggle-title">{lang["storyboard.legend"]}</span>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-3">
                                                    <div className="checkbox checkbox-slider--c">
                                                        <label>
                                                            {(that.chartSettings.legend == true) ? (
                                                                <> <input type="checkbox" defaultChecked="checked" id="legend" className="pivotchart_settings" /><span>&nbsp;</span></>
                                                            ) :
                                                                <>  <input type="checkbox" id="legend" className="pivotchart_settings" /><span>&nbsp;</span></>
                                                            }
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xs-12 nopadding">
                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-7">
                                                    <span className="toggle-title">{lang["storyboard.x-axis"]}</span>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-3">
                                                    <div className="checkbox checkbox-slider--c">
                                                        <label>
                                                            {(that.chartSettings.xAxisLabel == true) ? (
                                                                <> <input type="checkbox" defaultChecked="checked" id="xAxisLabel" className="pivotchart_settings" /><span>&nbsp;</span></>
                                                            ) :
                                                                <> <input type="checkbox" id="xAxisLabel" className="pivotchart_settings" /><span>&nbsp;</span></>
                                                            }
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xs-12 nopadding">
                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-7">
                                                    <span className="toggle-title">{lang["storyboard.y-axis"]}</span>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-3">
                                                    <div className="checkbox checkbox-slider--c">
                                                        <label>
                                                            {(that.chartSettings.yAxisLabel == true) ? (
                                                                <> <input type="checkbox" defaultChecked="checked" id="yAxisLabel" className="pivotchart_settings" /><span>&nbsp;</span></>
                                                            ) :
                                                                <>  <input type="checkbox" id="yAxisLabel" className="pivotchart_settings" /><span>&nbsp;</span></>
                                                            }
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xs-12 nopadding">
                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-7">
                                                    <span className="toggle-title">{lang["storyboard.datalabel"]}</span>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-3">
                                                    <div className="checkbox checkbox-slider--c">
                                                        <label>
                                                            {(that.chartSettings.dataLabel == true) ? (
                                                                <> <input type="checkbox" defaultChecked="checked" id="dataLabel" className="pivotchart_settings" /><span>&nbsp;</span></>
                                                            ) :
                                                                <> <input type="checkbox" id="dataLabel" className="pivotchart_settings" /><span>&nbsp;</span></>
                                                            }
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xs-12 nopadding">
                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-7">
                                                    <span className="toggle-title">{lang["storyboard.crosshair"]}</span>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-3">
                                                    <div className="checkbox checkbox-slider--c">
                                                        <label>
                                                            {(that.chartSettings.crossHairs == true) ? (
                                                                <><input type="checkbox" defaultChecked="checked" id="crossHairs" className="pivotchart_settings" /><span>&nbsp;</span></>
                                                            ) :
                                                                <>  <input type="checkbox" id="crossHairs" className="pivotchart_settings" /><span>&nbsp;</span></>
                                                            }

                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xs-12 nopadding">
                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-7">
                                                    <span className="toggle-title">{lang["storyboard.gridline"]}</span>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-3">
                                                    <div className="checkbox checkbox-slider--c">
                                                        <label>
                                                            {(that.chartSettings.gridLine == true) ? (
                                                                <> <input type="checkbox" defaultChecked="checked" id="gridLine" className="pivotchart_settings" /><span>&nbsp;</span></>
                                                            ) :
                                                                <><input type="checkbox" id="gridLine" className="pivotchart_settings" /><span>&nbsp;</span></>
                                                            }

                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="modal-footer boc-modal-hf-padding">
                            <button type="button" className="btn btn-bird" id="btn-birdpivot" onClick={that.cancelSettingsModal} data-dismiss="modal" aria-hidden="true"><i className="fa fa-times"></i> {lang["storyboard.cancelbtn"]}</button>
                            <button type="button" className="btn btn-bird applypivotchartsettingchanges" onClick={that.applyPivotChartSettingChanges} id="applypivotchartsettingchangespivot" data-num={that.chartnumber} tab-id="0" tab-name="" aria-hidden="true"><i className="fa fa-check"></i> {lang["storyboard.applybtn"]}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}