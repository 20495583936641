import _ from 'lodash';
import $ from 'jquery/dist/jquery';
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import { lang } from "../js/helpers/utils/languages/language";
import ReactDOM from "react-dom";
import React, { Component } from "react";


export default class MetricDateFieldAttributes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicdiv: props.dynamicdiv,
            columnDisplayName: props.columnDisplayName,
            columnName: props.columnName,
            columnType: props.columnType,
            viewmode: props.viewmode,
        };
        this.render();
    }
    render() {

        ReactDOM.render(<MetricDateFieldAttributesTemplate
            dynamicdiv={this.state.dynamicdiv}
            deleteelement={this.deleteelement}
            columnDisplayName={this.state.columnDisplayName}
            columnName={this.state.columnName}
            columnType={this.state.columnType}
            viewmode={this.state.viewmode}
        ></MetricDateFieldAttributesTemplate>, document.getElementById(this.state.dynamicdiv));
        return (<div></div>)
    }
    deleteelement(e) {
        var $this = e.currentTarget;
        $($this).closest("li").parent().parent().remove()
    }
}
class MetricDateFieldAttributesTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        var that = this.props;
        return (<div>
            {(that.viewmode === 'values' && that.viewmode != undefined) ? (
                // <!-- metricdatefieldattributesview.js -->
                <li key={that.dynamicdiv} className="boc-list-item singleliitems metricitems" id="metricitem" title={that.columnDisplayName} data-name={that.columnName} data-type={that.columnType}>
                    <span className="itemcontainer">
                        <span className="itemtype">
                            <i className="fa fa-calendar"></i>
                        </span>
                        <span className="itemname">{that.columnDisplayName}</span>
                    </span>
                    <span className="itemoptions">
                        <span className="itemremove">
                            <button className="btn btn-transparent deletemetricdatefield" onClick={that.deleteelement} type="button" title={lang["storyboard.remove"]}><i className="fa fa-trash-o"></i></button>
                        </span>
                        <span className="itemaggregation"></span>
                    </span>
                </li>
            ) :
                <li className="boc-list-item singleliitems metricitems" id="metricitem" title={that.columnDisplayName} data-name={that.columnName} data-type={that.columnType}>
                    <span className="itemtype">
                        <i className="fa fa-calendar"></i>
                    </span>
                    <span className="itemname">{that.columnDisplayName}</span>
                    <span className="btn-group pull-right">
                        <button className="btn btn-transparent deletemetricdatefield" onClick={that.deleteelement} type="button">
                            <i className="fa fa-trash"></i>
                        </button>
                    </span>
                </li>
            }
        </div>);
    }
}