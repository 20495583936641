import React from "react";
import ReactDOM from "react-dom";
import { lang } from "../js/helpers/utils/languages/language";
import $ from 'jquery/dist/jquery';

export default class VersionListPopup extends React.Component{
    constructor(props){
        super(props);
        this.closepopup = this.closepopup.bind(this);
    }
    
    closepopup() {
		$('.pivotdataviewwrap').remove();
		if(document.getElementById("datatablepopupmodal")!=null){
			$('#datatablepopupmodal').remove();
		}
	}
    render(){
        return(
            <div className='pivotdataviewwrap'>
					<div className="pivotdataview">
						<div className="ball-pulse"><div></div><div></div><div></div></div>
						<div className="pivotdataviewclose" onClick={this.closepopup} id="pivotdataviewdatapop">
							<i className='fa fa-times'></i>
						</div>
						<div className="boc-carousel-area-header-title pivottile" style={{ color: '#2737a3' }}>{lang["storyboard.versionlist"]}</div>
						<div className="container-fluid marg-bot-10">
							<div className="row">
								<div id="pivotdataview"></div>
							</div>
						</div>

					</div>
				</div>
        );
    }
}