import React, { Component } from "react";
import _ from 'underscore';
import { lang } from "../js/helpers/utils/languages/language";

export default class DataHubSyncLogBookBootstrapTemplate extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        var that = this.props;
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="marg-top-10">
                        <div className="clear"></div>
                        <div className="container-fluid synclogreportmanagementcontainer">
                            <div className="row in-back">
                                <div className="row-same-height-inner">
                                    <div className="col-xs-12 col-xs-height in-panes">
                                        <div className="pull-right">
                                            <button type="button" className="btn btn-bird btn-sm marg-righ-5 synclogsettingpopup" id="synclogsettingsbutton" title={lang["datahub.retentionperiod"]} data-num=" "><i className="fa fa-cogs marg-righ-5"></i>{lang["datahub.retentionperiod"]}</button>
                                            <button type="button" className="btn btn-bird btn-sm refreshsynclog" id="synclogrefreshbutton" title={lang["datahub.refresh"]}><i className="fa fa-refresh marg-righ-5"></i>{lang["datahub.refresh"]}</button>
                                        </div>
                                        <div className="panel-body">
                                            <div className="panel panel-default panel-bird auditpanels synclog">
                                                <div className="notifications-table-container">
                                                    <div className="bootstrap-table">
                                                        <div className="fixed-table-toolbar">
                                                            <div className="pull-left search">
                                                                <input className="form-control searchsynclogtable" type="text"
                                                                    placeholder={lang["datahub.search"]} defaultValue={that.search} />
                                                            </div>
                                                            {/* <span className="pull-right">
                                                                <span className="daterange-center" id="daterange-filter">
                                                                    <i className="glyphicon glyphicon-calendar fa fa-calendar"></i>
                                                                    <b className="caret"></b>
                                                                    <span></span>
                                                                </span>
                                                            </span> */}
                                                        </div>
                                                        <div className="notifications-table-container marg-top-10">
                                                            <table className="table table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        {that.keys.map((value) => {
                                                                            return (
                                                                                that.dataTypeslist[value] == 'number' ?
                                                                                    <th data-field={value} className="align-right">{that.headerlist[value]}</th> :
                                                                                    value==='sync_time_taken'?
                                                                                    <th data-field={value} className="align-right">{that.headerlist[value]}</th> :
                                                                                    <th data-field={value}>{that.headerlist[value]}</th>
                                                                            )
                                                                        })}
                                                                    </tr>
                                                                </thead>
                                                            </table>
                                                        </div>
                                                        <div className="notifications-table-container">
                                                            <div className="bootstrap-table">
                                                                <div className="fixed-table-pagination">
                                                                    <div className="pull-left pagination-detail">
                                                                        <span className="pagination-info">{lang["datahub.showing"]} </span> <span
                                                                            className="page-list"> <span className="btn-group dropup">
                                                                                <button type="button"
                                                                                    className="btn btn-default  dropdown-toggle synclogrowlimtbutton"
                                                                                    id="synclogtablerecords" data-toggle="dropdown">
                                                                                    <span className="page-size">25</span> <span className="caret"></span>
                                                                                </button>
                                                                                <ul className="dropdown-menu synclogrowlimtul" role="menu">
                                                                                    <li value="10"><a href="javascript:void(0)">10</a></li>
                                                                                    <li className="active" value="25"><a
                                                                                        href="javascript:void(0)">25</a></li>
                                                                                    <li value="50"><a href="javascript:void(0)">50</a></li>
                                                                                    <li value="100"><a href="javascript:void(0)">100</a></li>
                                                                                </ul>
                                                                            </span> {lang["datahub.records"]}</span>
                                                                    </div>
                                                                    <div className="pull-right pagination">
                                                                        <ul id="pagination-view" className="pagination-sm"></ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}