import React from 'react';
import  ReactDOM from 'react-dom';
import LdapViewTemplate from './ldapViewTemplate';
import NewLdapView from './NewLdapView';
import EditLdapView from './EditLdapView';
import DeleteLdapView from './DeleteLdapView';
import SendRequest from '../../../SendRequest';
import {getMessage} from '../../../js/helpers/utils/BirdMessage';
const $ = window.$;

export default class LdapView extends React.Component{
    constructor(props){
        super(props);
        this.getLdapSettings();
    }

    getLdapSettings(){
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getallldapsettings",
            queryString: "",
            sucessFunction: (response) => { 
                this.render(response);
              },
              rejectFunction: () => { },
              headers: requestHeaders
            });
    }
    newLdap=()=>{
        var newldap = new NewLdapView({});
    }
    editLdap=(event)=>{
        var data = $(event.currentTarget).val();
        new EditLdapView({ldapId:data});
        if($(window).width() < 767)
		    {
			   $('html').animate({scrollTop: $("#icontent").offset().top}, 2000);
		    } else {
		         
		    }
    }
    showldapuser=(event)=>{
        var data=$(event.currentTarget).val();
        //new GetLdapUsersUsingLdapIdView({ldapId:data});
    }
    deleteLdap=(event)=>{
        $(".deleteyes").unbind( "click" );
			$(".deleteno").unbind( "click" );
			$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
			$('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
			$('.birdmessagebuttons').addClass('show');
			$('.birdmessage-center').addClass("message-top-in");
			$('.details').removeClass('show');
			$('.details').addClass('hide');
			$('.birdmessage h2').empty().append('Warning');
			$('.birdmessage-info').empty().text(getMessage('BirdWarning88'));
			$( ".deleteyes" ).click(function() {
			$('.birdmessage-center').removeClass("message-top-in");
	    		var data=$(event.currentTarget).val();
	   			new DeleteLdapView({ldapId:data});
			});
			$( ".deleteno" ).click(function() {
				 $('.birdmessage-center').removeClass("message-top-in");
				});
    }

    render(response){
        ReactDOM.render(<LdapViewTemplate ldapSettings={response} features={this.props.features} newLdap={this.newLdap} 
            editLdap={this.editLdap} showldapuser={this.showldapuser} deleteLdap={this.deleteLdap}/>,document.querySelector(".othersettingscontainer"))
        return(<div></div>);
    }

}