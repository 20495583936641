import React from 'react'
import ReactDOM from 'react-dom'
import { lang } from "../js/helpers/utils/languages/language";
import PubSub from 'pubsub-js'

const $= window.$;
export default class TileFiltersTemplate extends React.Component {
	constructor(props) {
		super(props);
        this.state = {}
    }
render(){
    var that = this.props;
    return(
        <div className="modal fade in" id="tilefiltersmodal" data-id={that.chartid} tabindex="-1"  role="dialog" aria-labelledby="tilefiltersmodal" aria-hidden="true" style={{ display: "block" }}>
	<div className="modal-dialog modal-sm modal-dialog-center" style={{ zIndex: "1050", marginTop:"auto" }}>
		<div className="modal-content">
			<div className="modal-header boc-modal-hf-padding">
				<h4 className="modal-title"><i className="fa fa-filter"></i> {lang["storyboard.tilefilters"]}
					<button type="button" className="close white" id="tilefiltersclose" onClick={that.tilefiltersclose} aria-hidden="true" ><i className="fa fa-times"></i></button>
				</h4>	
			</div>
			<div className="modal-body">
				<div className="container-fluid">
					<div className="row filtercontent">
						<span className="required right legend"></span>
						<legend className="subhead">{lang["storyboard.slctcharttile"]}</legend>
						<div className="col-xs-12 nopadding">
							<select className="selectcharttile form-control" onChange={that.changeTile}></select>
						</div>
						<div className="clear"></div>
						<div className="permissionscroller storyboardfilterslistcontainer"></div>
						<div className="clear"></div>
						<div className="permissionscroller tilefilterslistcontainer" tile-id={that.chartid}>
							<div className="permissioncontainer dobyfilteritems">
								<div className="col-xs-12 head"><i className="fa fa-filter"></i> {lang["storyboard.tilefilters"]}
										<button className="btn btn-transparent pull-right btn-sm addnewtilefilter tooltip-left" onClick={this.addNewTileFilters} data-tooltip={lang["storyboard.add"]}><i className="fa fa-plus"></i></button>
								</div>
								<div className="clear"></div>
								{/* <!-- <ul className="list-group scrollsetul permissionlist entitytype tilefilterslist"></ul> --> */}
								<div className="panel-group rightfilter filteritem-container" id="tilefilterslist" tile-id={that.chartid}></div>
							</div>
						</div>
						
					</div>
				</div>
				
			</div>
			<div className="modal-footer boc-modal-hf-padding">
				<button type="button" className="btn btn-bird"  id="tilefiltersclose" onClick={that.tilefiltersclose} aria-hidden="true"><i className="fa fa-times"></i> {lang["storyboard.cancelbtn"]}</button>
				<button type="button" className="btn btn-bird" id="tilefilterapply" onClick={that.tilefilterapply} aria-hidden="true"><i className="fa fa-check"></i> {lang["storyboard.applybtn"]}</button>
			</div>
		</div>
	</div>
</div>
    );
}
addNewTileFilters(e){
	PubSub.publish("maxfiltersdropdownold");
}

}