import React from "react";
import ReactDOM from 'react-dom';
import * as viewreportCollection from "../js/helpers/utils/SavedReportCollection";
import { lang } from "../js/helpers/utils/languages/language";
import _ from 'lodash';
import $ from 'jquery/dist/jquery';
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import SendRequest from '../SendRequest';
import VersionListPopup from './VersionListPopup'
import VersionsListView from './VersionsListView';
import { getMessage } from '../js/helpers/utils/BirdMessage';


export default class VersionStoryboardHtml extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			features: props.features,
			datamodelId: props.datamodelId,
            issharedreport: false,
            versionname:props.versionname
		};
		this.applystoryboardchanges = this.applystoryboardchanges.bind(this);
	}
	componentDidMount(){
		$(".form-control").keyup(function(){
			var valThis = $(this).val().toLowerCase();
			  var valLength = valThis.length;
				var maxCount = 45;
				var errormsg="#errmsg"
				if(valLength>=maxCount){
					$(errormsg).html("");
					$(errormsg).html(lang["storyboard.namexceeds"]+maxCount+"" ).show();
					$(this).val($(this).val().substring(0, $(this).val().length - 1));
				}else{
					$(errormsg).html("");
				}
		});
	}
	render() {
		$("#versionstoryboardname-help-block").html('')
		this.state.features = this.props.features;
		this.state.datamodelId = this.props.datamodelId;
		$('#versionstoryboardname').val('');
		$('#versionstoryboardname').val(this.props.versionname)
		if (viewreportCollection != undefined && viewreportCollection.model != undefined && viewreportCollection.model != null) {
			if (viewreportCollection.model.reportfolder != undefined) {
				viewreportCollection.model.reportfolder = viewreportCollection.model.reportfolder == 'Default' ? "" : viewreportCollection.model.reportfolder
			}
			this.state.reportname = viewreportCollection.model.reportname != undefined ? viewreportCollection.model.reportname : "";
			this.state.reportdesc = viewreportCollection.model.reportdesc != undefined ? viewreportCollection.model.reportdesc : "";
			this.state.folder = viewreportCollection.model.reportfolder != undefined ? viewreportCollection.model.reportfolder : "";
			this.state.title = viewreportCollection.model.title != undefined ? viewreportCollection.model.title : "";
			this.state.reportId = viewreportCollection.model.id != undefined ? viewreportCollection.model.id : 0;
		}
		return (
			// <!-- savestoryboardmodal.js -->
			
			<div className={`modal fade ${this.props.isDisplay ? "in" : ""}`} id="versionsavestoryboard" tabIndex="-1" role="dialog" aria-labelledby="savestoryboard" aria-hidden={this.props.isDisplay ? "false" : "true"} style={{ display: this.props.isDisplay ? "block" : "none" }}>
				<div className="modal-backdrop fade in" onClick={this.props.close}></div>
				<div className="modal-dialog modal-lg modal-save-report modal-dialog-lg-center modal-dialog-center" style={{ zIndex: "1050" }}>
					<div className="modal-content">
						<div className="modal-header boc-modal-hf-padding">
							<button type="button" className="close white" id="storyboardclose" aria-hidden="true" data-dismiss="modal" onClick={this.props.close}>&times;</button>
							<h4 className="modal-title"><i className="fa fa-floppy-o"></i> {lang["storyboard.versionboard"]}</h4>
						</div>
						<div className="modal-body scrollset">
							<div className="container-fluid">
								<div className="row filtercontent">
									<fieldset className="savereport-border">
										<legend className="savereport-border">{lang["storyboard.reportdetails"]}</legend>
										<div className="control-group">
											<div className="validationgroup form-group" style={{ marginTop: "-2px" }}>
												<span className="required right legend"></span>
												<label className="subhead" for="versionstoryboardname">{lang["storyboard.name"]} </label>
												<input type="name" id="versionstoryboardname" name="versionstoryboardname" defaultValue={this.props.versionname} className="form-control input-sm" placeholder={lang["storyboard.versionname"]} />
												<span className="help-block  marg-left-10" id="versionstoryboardname-help-block"></span>
											</div>
											<div className="validationgroup form-group">
												<label className="subhead" for="versionstoryboardfolder">{lang["storyboard.folder"]} </label>
												<input type="folder" className="form-control input-sm"  id="versionstoryboardfolder" name="versionstoryboardfolder" list="folders" placeholder={lang["storyboard.slctfolder"]} />
												<span style={{color:"#ff0000"}} id="errmsg"></span>
												<span className="help-block hidden marg-left-10"></span>
												<datalist id="folders">
												</datalist>
											</div>
											<div className="validationgroup form-group ">
												<label className="subhead">{lang["storyboard.description"]} </label>
												<textarea id="versionstoryboarddesc" placeholder={lang["storyboard.versiondescribe"]} name="versionstoryboarddesc" className="form-control input-sm resize-vertical">{this.props.reportDesc}</textarea>
												<span className="help-block hidden"></span>
											</div>
										</div>
									</fieldset>
								</div>
                                <button
                                toggle="View More..."
                                className="btn btn-bird-group-transaprent viewallstoryboardreports  pull-right"
                                id="dashviewallfavoritesreports" onClick={this.versionsviewclick}
                              >
                                {lang["storyboard.versionlist"]}
                              </button>
							</div>
                            <div className="data-items-versioncontainer" ></div>
						</div>
						<div className="modal-footer boc-modal-hf-padding">
							<button type="button" className="btn btn-bird" id="btn-birdsave" data-dismiss="modal" onClick={this.props.close}><i className="fa fa-times"></i> {lang["storyboard.cancelbtn"]}</button>
							<button type="button" className="btn btn-bird applystoryboard" id="applystoryboards" onClick={this.applystoryboardchanges}><i className="fa fa-check"></i> {lang["storyboard.applybtn"]}</button>
						</div>
					</div>
					{/* <!-- /.modal-content --> */}
				</div>
			</div>
		);
	}
    versionsviewclick = (e) => {
        //this.setState({ isStoryBookListView: true });
      //  ReactDOM.render(<StoryboardListView  url={this.props.url}/>)
	  $("body").append("<div id='datatablepopupmodal'></div>");
		ReactDOM.unmountComponentAtNode(document.getElementById("datatablepopupmodal"));
		ReactDOM.render(<VersionListPopup/>,document.getElementById("datatablepopupmodal"));
        ReactDOM.render(<VersionsListView url={this.props.url} urltype="storyboard" reportId={this.props.reportId} from="reports" />, document.getElementById("pivotdataview"));

      }
	applystoryboardchanges = (e) => {
        var that = this;
        if (this.state.reportId == null || this.state.reportId == undefined || this.state.reportId == "" || window.location.pathname.indexOf("importreport") >= 0 || window.location.pathname.indexOf("sharereport") >= 0) {
          $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
          $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
          $('.birdmessagebuttons').removeClass('show');
          $('.birdmessagebuttons').addClass('hide');
          $('.birdmessage-center').addClass("message-top-in");
          $('.details').removeClass('show');
          $('.details').addClass('hide');
          $('.birdmessage h2').empty().append('Information');
          $('.birdmessage-info').empty().text(getMessage("BirdInformation01"));
        } else {
          let requestHeaders = {
            method: "post",
            headers: new Headers({
              "Content-Type": "application/json"
            })
          };
          var versionName = $("#versionstoryboardname").val().trim();
		  var foldername=$("#versionstoryboardfolder").val().trim();
		  var folderdesc=$("#versionstoryboarddesc").val().trim();
		  var reservedwordslist = JSON.parse(sessionStorage.getItem("reservedwordslist"));
		if (versionName == undefined || versionName == "" || versionName.length == 0 || /[~`!@#$%^&*()+\=\[\]{};':\\|†,.<>\/?"""`]/.test(versionName)) {
			$("#versionstoryboardname-help-block").html(lang["storyboard.validname"])
			return;
		} else if (versionName.length > 70) {
			$("#versionstoryboardname-help-block").html(lang["storyboard.reportnameexceeds"])
			return;
		} else if (reservedwordslist != null){ 
		if (reservedwordslist.indexOf(versionName.toLowerCase()) != -1) {
			$("#versionstoryboardname-help-block").html(lang["storyboard.noreservewords"])
			return;
			}
		} else {
			$("#versionstoryboardname-help-block").html('')
		}
		if (foldername === "") {
			foldername = "Default"
		}
          var requestbody = new FormData();
          requestbody.set("reportId", that.state.reportId);
          requestbody.set("modelId", that.state.datamodelId);
          requestbody.set("versionName", versionName);
		  requestbody.set("foldername", foldername);
          requestbody.set("folderdesc", folderdesc);
          SendRequest({
            url: "versionreport",
            body: requestbody,
            sucessFunction: (response) => {
              if (response.status == 'success') {
                $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Success');
                $('.birdmessage-info').empty().text(getMessage('BirdSuccess67'));
				$("#versionsavestoryboard").removeClass("in");

              } else if (response.hasOwnProperty('error')) {
                $(".spinner").hide();
                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('hide');
                $('.birdmessage h2').empty().append('Error : ');
                $('.birdmessage h2').append('<span class="errorcode">');
                $('.birdmessage .errorcode').empty().append(response.errorCode);
                $('.birdmessage-info').empty().text(response.error);
                $('.details').addClass('show');
                $('.messagedetails xmp').empty().append(response.errorTrace)
              }
            },
            rejectFunction: this.reportDataError,
            headers: requestHeaders
          });
        }
      }
}