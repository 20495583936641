import _ from 'lodash';
import $ from 'jquery/dist/jquery';
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import ReactDOM from "react-dom";
import React, { Component } from "react";


export default class MetricDateFieldDropDown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicdiv: props.dynamicdiv,
            addmetricdatefieldvalue: props.addmetricdatefieldvalue,
            columnDisplayName: props.columnDisplayName,
            columnName: props.columnName,
            dataType: props.dataType,
            multifact: (props.multifact == 'true'),
            multifactentities: (props.multifact == 'true') ? JSON.parse(props.multifactentities) : props.multifactentities,
        };
        this.render();
    }
    render() {
        var facttables = this.props.facttables;
        var disable = false;
        var that = this;
        if (this.state.multifact) {
            var customfield = 0;
            var customFields = JSON.parse(sessionStorage.getItem("customFields"));
            if (customFields != undefined && customFields != null) {
                //var tempcustomObject = JSON.parse(customFields);
                _.each(customFields, function (cf) {
                    if (cf.name === that.columnName) {
                        if (cf.dataType === "date" || cf.dataType === "datetime") {
                            customfield = 1
                        }
                    }
                });
            }
            if (customfield == "1") {
                //iscustomfunction
                var customFields = JSON.parse(sessionStorage.getItem("customFields"));
                var customFieldObject = $.grep(customFields, function (e) { return e.name == that.columnName });
                if (customFieldObject != undefined && customFieldObject.length > 0) {
                    var isallowed = true;
                    _.each(customFieldObject[0].usedFields, function (hierarchy) {
                        var tablename = hierarchy.split(".")[0];
                        disable = ChartInfo.functions.getAllowedEntities(that.multifactentities, hierarchy, 0, undefined, undefined, facttables, "xdropdown");
                        if (disable) {
                            isallowed = false;
                        }
                    });
                    if (!isallowed) {
                        disable = true;
                    }
                }
            } else {
                disable = ChartInfo.functions.getAllowedEntities(this.state.multifactentities, this.state.columnName, this.props.customfield, undefined, undefined, facttables, "xdropdown");
            }
        }
        ReactDOM.render(<MetricDateFieldDropDownTemplate
            addmetricdatefieldvalue={this.state.addmetricdatefieldvalue}
            dynamicdiv={this.state.dynamicdiv}
            columnDisplayName={this.state.columnDisplayName}
            columnName={this.state.columnName}
            dataType={this.state.dataType}
            disable={disable}
        ></MetricDateFieldDropDownTemplate>, document.getElementById(this.state.dynamicdiv));

        return (<div></div>);
    }
}
class MetricDateFieldDropDownTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        var that = this.props;
        return (<div>
            {/* <!-- metricdatefielddropdown.js --> */}
            {(that.dataType === 'date' || that.dataType === 'datetime') ? (
                <li key={that.dynamicdiv} className={`xs-list-item metricdatefielddropdownitem  ${that.disable ? " hide" : ""}`} onClick={that.addmetricdatefieldvalue} data-name={that.columnName} data-type={that.dataType}>
                    <span className="itemtype">
                        <i className="fa fa-calendar"></i>
                    </span>
                    <span className="itemname">{that.columnDisplayName}</span>
                </li>
            ) : null}
        </div>);
    }
}