import React, { Component } from "react";
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import { lang } from "../js/helpers/utils/languages/language";
import ReactDOM from "react-dom";
import PubSub from 'pubsub-js';
const $ = window.$;


export default class CustomHierarchyFieldView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicdiv: props.dynamicdiv,
            columnDisplayName: props.columnDisplayName,
            columnName: props.columnName,
            aggregation: props.aggregation
        };
        this.render();
    }
    render() {
        ReactDOM.render(<AttrMeasuresModalTemplate
                dynamicdiv={this.state.dynamicdiv}
            columnDisplayName={this.state.columnDisplayName}
            columnName= {this.state.columnName}
           > </AttrMeasuresModalTemplate>
            , document.getElementById(this.state.dynamicdiv));
        return (<div></div>);
    }
   
}

class AttrMeasuresModalTemplate extends Component {
    constructor(props) {
        super(props)
        this.state = {};
        this.createDillDownHierarchy = this.createDillDownHierarchy.bind(this);
    }
    render() {
        var that = this.props;
        return (
            <li key={that.dynamicdiv} className="boc-list-item singleliitems  customhierarchyfields attributesdimensions" data-type="customhierarchy" data-name={that.columnName} title={that.columnDisplayName}>
                <span className="itemtype">
                    <i className="fa fa-list"></i>
                </span>
                <span className="itemname">{that.columnDisplayName}</span>
                <span type="button" data-name={that.columnName} title={that.columnDisplayName} onClick={this.createDillDownHierarchy} className="opacity-effect callcustomhierarchy" data-edit={lang["storyboard.edit"]}>
                    <i className="fa fa-pencil"></i>
                </span>
                <span type="button" data-name={that.columnName} title={that.columnDisplayName} onClick={this.deletecustomhierarchyfield} className="deletecustomhierarchyfield opacity-effect pull-right marg-left-10">
                    <i className="fa fa-trash"></i>
                </span>
            </li>
        );
    }
    createDillDownHierarchy(e) {
        PubSub.publish('createDillDownHierarchy', e.currentTarget);
        document.body.classList.add("modal-open");
    }
    deletecustomhierarchyfield (e){
        var $this = e.currentTarget;
        var result = $.grep(ChartInfo.functions.getcustomhierarchy(), function (e) { return e.name != $($this).parent().attr("data-name") });
        ChartInfo.functions.setcustomhierarchy(result);
        $('.xaxisattributes li[data-name=' + $($this).parent().attr("data-name") + ']').remove();
        $('.yaxisattributes li[data-name=' + $($this).parent().attr("data-name") + ']').remove();
        $('.legendAttributes li[data-name=' + $($this).parent().attr("data-name") + ']').remove();
        $('.tooltipattributes li[data-name=' + $($this).parent().attr("data-name") + ']').remove();
        $('.zaxisattributes li[data-name=' + $($this).parent().attr("data-name") + ']').remove();
        $(e.currentTarget).parent().parent().remove();
    }
}