import React from "react";
import ReactDOm from 'react-dom';
import ViewReportHeaderTemplate from './ViewReportHeader.jsx';
import ViewReportHeader from './viewreportheader'
import LiveReportsView from './livereportsview'
import SendRequest from '../SendRequest';
import ReportView from './ReportView';
import SingleReportView from './SingleReportView'
import * as collection from "../js/helpers/utils/SavedReportCollection";
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import PubSub from 'pubsub-js'
import $ from 'jquery/dist/jquery';
import worker_script from "../js/helpers/utils/worker";


var worker;
export default class Storyboardview extends React.Component {
  constructor(props) {
    $(".spinner").show();
    super(props);
    this.state = {
      renderreturn: false,
      rendersingle: false,
      allfeatures: [],
      reportInfo: [],
      reportcolumns: [],
      view_Only_Flag: false,
      reportNumber: null,
      reportName: "",
      scheduleReportModel: {},
      sharedInfo: [],
      scheduleInfo: [],
      Report_Info: [],
      alertsInfo: [],
      filtersArray: [],
      reportId: null,
      isTempStoryBoard: false,
      isDirect: 0,
      tabid: 0,
      isFromStoryBook: false,
      isFromTabClick: false,
      isPublished:"false",
      el: "main",
      isFromSmartInsigt: false,
      isFromSavedSmartInsight: false,
      isImportedReport: props!=undefined && props.isImportedReport != undefined ? props.isImportedReport : false,
      storybookId:"",
      isSharedStoryBook:false,
      isNLPReport:false,
      isVersioned:false,
      enablepublish:false
    }
    if(window.location.pathname.indexOf("sharestorybook")>=0){
this.state.isSharedStoryBook=true;
var fragmentarray = window.location.pathname.split("/");
this.state.storybookId= atob(fragmentarray[4]);
    }
    if (this.props.isFromStoryBook != undefined && this.props.isFromStoryBook) {
      this.state.tabid = this.props.tabid;
      this.state.isFromStoryBook = this.props.isFromStoryBook;
      this.state.el = this.props.el;
      this.state.reportId = this.props.reportId;
      this.state.isFromTabClick = this.props.isFromTabClick != undefined ? this.props.isFromTabClick : false;
      sessionStorage.setItem("state", "viewstorybook");
      if (this.props.isFromSmartInsigt != undefined && this.props.isFromSmartInsigt) {
        this.state.isFromSmartInsigt = true;
        if (this.props.isFromSavedSmartInsight != undefined && this.props.isFromSavedSmartInsight) {
          this.state.isFromSavedSmartInsight = true;
          this.state.datamodelId = this.props.datamodelId;
          var dummydata = ChartInfo.functions.getDummyDataForReport();
          this.state.reportInfo = JSON.parse(dummydata);
        }
      }

    } else if (this.props.isFromSmartInsigt != undefined && this.props.isFromSmartInsigt) {
      sessionStorage.setItem("state", "other");
      this.state.isFromSmartInsigt = true;
      if (this.props.isFromSavedSmartInsight != undefined && this.props.isFromSavedSmartInsight) {
        this.state.isFromSavedSmartInsight = true;
        this.state.datamodelId = atob(this.props.match.params.datamodelId);
        this.state.reportId = atob(this.props.match.params.reportId);
        var dummydata = ChartInfo.functions.getDummyDataForReport();
        this.state.reportInfo = JSON.parse(dummydata);
      }
    } else {
      if(this.props.match.params.reportId != undefined){
      this.state.reportId = atob(this.props.match.params.reportId);
      }
      sessionStorage.setItem("state", "viewreport");
    }
    this.unbindEvents = this.unbindEvents.bind(this);
    ChartInfo.functions.resetChartDetails();
    if (window.location.pathname.indexOf("schedulereport") >= 0 && window.location.search != undefined && window.location.search != "") {
      var url = window.location.search;
      var queryArr = [];
      if (url.length > 0) {
        queryArr = url.replace('?', "").split("&");
        for (var i = 0; i < queryArr.length; i++) {
          var key = queryArr[i].split("=")[0];
          var value = queryArr[i].split("=")[1];
          if (key == "scheduleid" || key == "date") {
            this.state[key] = value;
          }
        }
      }
    }
  }

  componentDidMount() {
    document.body.classList.add("morning");
    this.unbindEvents();
    sessionStorage.setItem("stroyboardcolortheme", "");
    ChartInfo.functions.setIsStoredProcedure(0);
    ChartInfo.functions.setIsDirect(0);
    var that = this;

    let requestHeaders = {
      method: "get",
      headers: new Headers({
        "Content-Type": "application/json"
      })
    };
    SendRequest({
      url: "getfeatures",
      queryString: "",
      sucessFunction: props => {
        this.state.allfeatures = props;
        ChartInfo.functions.setAllFeatures(props);
       
      SendRequest({
          url: "getcurrentuserrole",
          queryString: "",
          sucessFunction: (response) => {
            this.state.currentuserrole = response.current_user_role;
            this.state.parentuserrole = response.parent_user_role;
          },
          rejectFunction: () => {},
          headers: requestHeaders,
      })
    // To check Temp storyboard or not
    if (this.props.match != undefined && this.props.match.params != undefined && this.props.match.params.datamodelId != undefined && !this.state.isFromSavedSmartInsight) {
      //For Temp storyboard
      that.state.datamodelId = atob(this.props.match.params.datamodelId);
      that.state.reportId = "";
      this.state.isTempStoryBoard = true;
      if (this.props.match.params.tempReportId != undefined) {
        that.state.tempReportId = atob(this.props.match.params.tempReportId);
        this.state.entities = atob(this.props.match.params.tempReportId);
      } else if (this.props.isFromSmartInsigt != undefined && this.props.isFromSmartInsigt) {
        that.state.reportId = null;
        that.state.isFromSmartInsigt = true;
        that.state.datamodelId = atob(this.props.match.params.datamodelId);
      }
      var array = [];
      ChartInfo.functions.setDrillThroughReports(array);
      SendRequest({
        url: "getdatamodelrelationinfo",
        queryString: "?reportId= &datamodelId=" + this.state.datamodelId,
        sucessFunction: props => {
          that.state.multifact = props.multifact;
          that.state.multifactentities = props.entities;
          that.state.facttables = props.facttables;
          var shredmodel = false;
          that.state.isFromSharedModel = sessionStorage.getItem("isSharedModel" + that.state.datamodelId);
          if (that.state.isFromSharedModel == "true") {
            shredmodel = "shredmodel";
          }
          if(props.metadata && props.metadata!=null && props.metadata!="null"){
            let metadata = JSON.parse(props.metadata)
            if(metadata.hasOwnProperty("isWsLiveEnabled")){
                ChartInfo.functions.setIsWorkspaceLiveEnabled(metadata["isWsLiveEnabled"])
            }
            if(metadata!=null && metadata.isWsLiveEnabled!=undefined && !metadata.isWsLiveEnabled){
              ChartInfo.functions.setisPublished('true');
            }
          }

          SendRequest({
            url: "getcolumns",
            queryString: "?datamodelId=" + that.state.datamodelId + " &multifact=" + that.state.multifact + "&isfrom=" + shredmodel,
            sucessFunction: cols => {
              that.state.reportcolumns = cols;
              collection.createDataTypeBucketsForTemp(this.state);
              var values = {};
              values["xAxis"] = [];
              values["yAxis"] = [];
              values["chartType"] = "bar";
              values["chartSettings"] = { 'chartTitle': true, 'legend': false, 'xAxisLabel': false, 'yAxisLabel': false, 'dataLabel': true, 'crossHairs': false, 'gridLine': false }
              values["colLimit"] = 20;
              values["orderType"] = "top";
              values["tabId"] = "0";
              values["tabName"] = "";
              ChartInfo.functions.updateChartDetails(0, JSON.stringify(values));
              var reportdataa = {};
              var dummydata = ChartInfo.functions.getDummyDataForReport();
              reportdataa["reportdata"] = dummydata;
              reportdataa["reportcolumns"] = cols;
              if(!this.props.tempReport){
                this.state.renderreturn = true;
              }else{
                var finalCustObject=ChartInfo.functions.getDummyDataForTempReport(cols,this.state.multifact,that.state.facttables);
                var obj={};
		        		obj["is_storyboard"]="N";
		        		obj["isDirect"]=0;
		        		obj["title"]="";
		        		obj["visualizationtype"]="bar";
		        		obj["reportcolumns"]=JSON.stringify(cols);
                obj["reportdata"]=finalCustObject.custObject;
                this.state.title=finalCustObject.title;
		        		var attributesobj={};
		        		attributesobj["attributes"]=obj;
		        	//	this.dummyobj=this.collection.at(0);
		        	//	this.dummyobj.attributes=obj;
                this.state.model=obj;
              }
              collection.updateLocalStorage(reportdataa,false);
              collection.model.title = "Storyboard";
              that.setState({ reportInfo: JSON.parse(dummydata) });
            },
            rejectFunction: () => { },
            headers: requestHeaders
          });
          if (that.state.multifact != null && that.state.multifact != undefined && that.state.multifact === "true") {
            $("body").addClass('multifact_enabled');
          } else {
            $("body").removeClass('multifact_enabled')
          }
        },
        rejectFunction: () => { },
        headers: requestHeaders
      });
    } else {
      //For saved storyboard
      let isEmbedReportWithLogin = false;
      let isScheduleReportWithLogin = false;
      if(window.location.pathname.indexOf("/welcome/private/schedulereport/")>=0){
        isScheduleReportWithLogin = true;
      }
      if(window.location.pathname.indexOf("/welcome/private/embeddedreport")>=0){
        isEmbedReportWithLogin = true;
      }
      SendRequest({
        url: "getReporDatatInfo",
        queryString:
          "?reportId=" + this.state.reportId + "&isFromStoryBook=" + this.state.isFromStoryBook + "&tabid=" + this.state.tabid + "&isImportedReport=" + this.state.isImportedReport+"&isSharedStoryBook="+this.state.isSharedStoryBook+"&storybookId="+this.state.storybookId
          +"&isEmbedReport="+isEmbedReportWithLogin+"&isScheduleReport="+isScheduleReportWithLogin,
        sucessFunction: props => {
          if (props.hasOwnProperty("error")) {
            $(".spinner").hide();
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('hide');
            $('.birdmessage h2').empty().append('Error : ');
            $('.birdmessage h2').append('<span class="errorcode">');
            $('.birdmessage .errorcode').empty().append(props.errorCode);
            $('.birdmessage-info').empty().text(props.error);
            $('.details').addClass('show').text("Details");
            $('.messagedetails xmp').empty().append(props.errorTrace);
            return;
        }
          var reportDataFromProps = JSON.parse(props.reportinfo);
          var modelDataFromProps = props.datamodelinfo;
          collection.updateLocalStorage(reportDataFromProps,false);
          collection.createDataTypeBuckets(modelDataFromProps);
          
          this.state.model = reportDataFromProps;
          if (!that.state.isFromSavedSmartInsight) {
            that.state.reportInfo = reportDataFromProps.reportdata != undefined && reportDataFromProps.reportdata != "" ? JSON.parse(reportDataFromProps.reportdata) : [];
          }
          that.state.reportcolumns = JSON.parse(reportDataFromProps.reportcolumns);
          if(reportDataFromProps.view_Only_Flag!=undefined && reportDataFromProps.view_Only_Flag=="1"){
            that.state.view_Only_Flag = true;
          }
          that.state.reportId = reportDataFromProps.id;
          that.state.isDirect = reportDataFromProps.isDirect;
          that.state.datamodelId = reportDataFromProps.datamodelId;
          that.state.multifact = modelDataFromProps.multifact != undefined ? JSON.stringify(modelDataFromProps.multifact) : "false";
          that.state.multifactentities = modelDataFromProps.entities;
          that.state.facttables = modelDataFromProps.facttables;
          that.state.reportName = reportDataFromProps.reportname;
          that.state.reportDesc = reportDataFromProps.reportdesc;
          that.state.title = reportDataFromProps.title;
          that.state.reportfolder = reportDataFromProps.reportfolder;
          that.state.reportId = this.state.reportId
          that.state.isPublished = reportDataFromProps.isPublished=='true'?'true':'false';
          ChartInfo.functions.setisPublishedOfDb(reportDataFromProps.isPublished=='true'?'true':'false');
          that.state.isNLPReport = reportDataFromProps.isNLPReport=='Y'?true:false;
          that.state.isVersioned = reportDataFromProps.isversioned=='Y'?true:false;
          ChartInfo.functions.setisversioned(reportDataFromProps.isversioned);
          ChartInfo.functions.setisPublished(that.state.isPublished);
          if(modelDataFromProps.metadata && modelDataFromProps.metadata!=null && modelDataFromProps.metadata!="null"){
            let metadata = JSON.parse(modelDataFromProps.metadata)
            if(metadata.hasOwnProperty("isWsLiveEnabled")){
                ChartInfo.functions.setIsWorkspaceLiveEnabled(metadata["isWsLiveEnabled"])
            }
            if(metadata!=null && metadata.isWsLiveEnabled!=undefined && !metadata.isWsLiveEnabled){
              ChartInfo.functions.setisPublished('true');
              that.state.isPublished ='true';
            }
          }
          if (!that.state.isImportedReport) {
            SendRequest({
              url: "schedulepublishreport",
              queryString:
                "?reportId=" + this.state.reportId,
              sucessFunction: props => {
                this.state.scheduleReportModel = props;
              },
              rejectFunction: () => { },
              headers: requestHeaders
            });
            SendRequest({
              url: "getReportInformation",
              queryString:
                "?reportId=" + this.state.reportId + "&isTemp=" + false + "&datamodelId=" + that.state.datamodelId + "&isImportedReport=" + that.state.isImportedReport,
              sucessFunction: props => {
                if (this.state.model != undefined && (this.state.model.visualizationtype === "story" || this.state.model.visualizationtype === "smartinsight_storyboard")) {
                  this.state.renderreturn = true;
                } else {
                  this.state.renderreturn = false;
                  this.state.rendersingle = true;
                }
                setTimeout(() => {
                  this.setState({
                    sharedInfo: props.sharedInfo,
                    scheduleInfo: props.scheduleInfo,
                    Report_Info: props.reportInfo,
                    alertsInfo: props.alertsInfo
                  });
                }, 1000)

                setTimeout(() => {
                  if (this.state.model != undefined && this.state.model.visualizationtype !== "story") {
                    var chartnumber = "0";
                      this.startliveupdate(chartnumber, "singlereport");
                  } else {
                    this.startliveupdate(-1, "storyboard");
                  }
                }, 1200)
              },
              rejectFunction: () => { },
              headers: requestHeaders
            });
          } else {
            if(this.state.model!=undefined && this.state.model.is_storyboard==="N"){
              this.state.rendersingle=true;
              this.state.reportId=atob(this.props.match.params.reportId);
            }else{
              this.state.renderreturn = true;
              this.state.reportId=atob(this.props.match.params.reportId);
            }
            this.state.isImportedReport=true;
            this.setState({
              isImportedReport: true
            })
          }
        },
        rejectFunction: () => { },
        headers: requestHeaders
      });
   
    }
  },
    rejectFunction: () => { },
    headers: requestHeaders
    });
  }

  render() {
    $(".spinner").show();
    if (worker != undefined) {
      worker.terminate();
      worker = undefined;
    }
    worker = new Worker(worker_script);
    return (<div>
      {/* // Storyboard.jsx */}
      {(this.state.renderreturn) ? (
        <>{this.state.reportInfo.length > 0 || this.state.isFromSavedSmartInsight ?
          <ReportView {...this.props} isFromSavedSmartInsight={this.state.isFromSavedSmartInsight} isFromSmartInsigt={this.state.isFromSmartInsigt} tabid={this.state.tabid} el={this.state.el} isFromTabClick={this.state.isFromTabClick} isFromStoryBook={this.state.isFromStoryBook} headerdata={this.state} model={this.state.model} isTempStoryBoard={this.state.isTempStoryBoard} reportInformation={this.state.reportInfo} features={this.state.allfeatures} view_Only_Flag={this.state.view_Only_Flag}
            scheduleid={this.state.scheduleid} date={this.state.date} reportId={this.state.reportId} datamodelId={this.state.datamodelId} reportcolumns={this.state.reportcolumns} isDirect={this.state.isDirect}
           userRole={this.state.currentuserrole} isPublished={this.state.isPublished} multifact={this.state.multifact} versionname={this.state.versionname} multifactentities={this.state.multifactentities} facttables={this.state.facttables} isVersioned={this.state.isVersioned} collection={collection} worker={worker} enablepublish={this.state.enablepublish}/> : null
        }</>
      ) : (this.state.rendersingle == true || this.props.tempReport==true) ? (
        <>
          {this.state.reportInfo.length > 0 ?
            <SingleReportView {...this.props} worker={worker} model={this.state.model} isTempStoryBoard={this.state.isTempStoryBoard} reportInformation={this.state.reportInfo} features={this.state.allfeatures} view_Only_Flag={this.state.view_Only_Flag} tabid={this.state.tabid} el={this.state.el} isFromTabClick={this.state.isFromTabClick} isFromStoryBook={this.state.isFromStoryBook}
              scheduleid={this.state.scheduleid} date={this.state.date} reportId={this.state.reportId} datamodelId={this.state.datamodelId} reportcolumns={this.state.reportcolumns} isDirect={this.state.isDirect}
              userRole={this.state.currentuserrole} isPublished={this.state.isPublished} multifact={this.state.multifact} versionname={this.state.versionname} multifactentities={this.state.multifactentities} facttables={this.state.facttables} title={this.state.title} isNLPReport={this.state.isNLPReport} isVersioned={this.state.isVersioned} currentUserRoleName={this.state.parentuserrole} enablepublish={this.state.enablepublish}/> : null
          }
        </>
      ) : null} </div>);
  }

  startliveupdate(chartnumber, liveFrom) {
    var that = this;
    var livereport = new LiveReportsView({
      worker: worker,
      reportId: that.state.reportId,
      datamodelId: that.state.datamodelId,
      features: that.state.allfeatures,
      liveFrom: liveFrom,
      facttables: that.state.facttables,
      multifact: that.state.multifact,
      multifactentities: that.state.multifactentities,
      display: "none"
    });
    this.state.livereportview = livereport;
    var liveObj = {};
    liveObj["chartnumber"] = chartnumber;
    liveObj["liveFrom"] = liveFrom;
    PubSub.publish('startliveupdate', chartnumber, liveFrom);

  }
  componentWillUnmount(){
        window.localStorage.removeItem('smartinsights-columnsdata');
        window.localStorage.removeItem('smartinsights-filtersdata')
        window.localStorage.removeItem('smartinsights-targetColumn');
        window.localStorage.removeItem('smartinsights-customFieldata');
        window.localStorage.removeItem('isStoryboardSmartInsight');
        if(this.state.livereportview){
          this.state.livereportview.unbindEvents();
        }
  }
  unbindEvents() {
    ChartInfo.functions.resetChartDetails();
    sessionStorage.removeItem("convertedvalues");
    sessionStorage.removeItem("customFields");
    sessionStorage.removeItem("customfieldentities");
    sessionStorage.removeItem("renameMeasuresorDimensions");
    sessionStorage.removeItem("datatable_popup_yaxis");
    sessionStorage.removeItem("convertedvalues");
    sessionStorage.removeItem("changesdone");
    sessionStorage.removeItem('isconvettedvaluesapplied');
    sessionStorage.removeItem('isconvettedtypesapplied');
    sessionStorage.removeItem("renameMeasuresorDimensions");
    sessionStorage.removeItem("datatable_popup_filterdata");
    sessionStorage.removeItem("instantExcludeFilters");
    sessionStorage.removeItem("instantIncludeFilters");
    sessionStorage.removeItem("instantExcludeCustomFilters");

    for (var i = 0; i < sessionStorage.length; i++) {
      var key = sessionStorage.key(i);
      if (key.match(/drilldown/g) != null) {
        sessionStorage.removeItem(key);
      }
    }
  }
}
