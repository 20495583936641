import React from 'react';
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;
export default class LiveReportsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state={};
    }
    render() {
        var that = this.props;
        return (
            // <!-- livereportsview.js -->
            <div className="modal fade in" id="livereportsmodal" tabIndex="-1" role="dialog" aria-labelledby="pagesettingsmodal" aria-hidden="true" style={{display:that.display}}>
                <div className="modal-backdrop fade in" onClick={(e) => { that.close(); $('body>.modal-backdrop').remove(); }}></div>
                <div className="modal-dialog modal-sm modal-sm-400 modal-dialog-sm-center" style={{ zIndex: "1050" }}>
                    <div className="modal-content">
                              {(that.isStoryboard == true) ? (
                                            <div className="modal-header boc-modal-hf-padding">
                                            <button type="button" className="close white" id="livemodalclose" aria-hidden="true" data-dismiss="modal" onClick={that.close}>&times;</button>
                                            <h4 className="modal-title"><i className="fa fa-clock-o fa-flip-horizontal"></i> {lang["storyboard.liveboard"]}</h4>
                                        </div>
                                        ) : <div className="modal-header boc-modal-hf-padding">
                                        <button type="button" className="close white" id="livemodalclose" aria-hidden="true" data-dismiss="modal" onClick={that.close}>&times;</button>
                                        <h4 className="modal-title"><i className="fa fa-clock-o fa-flip-horizontal"></i> {lang["storyboard.livereport"]}</h4>
                                    </div>}
                        <div className="modal-body scrollset">
                            <div className="container-fluid nopadding">
                                <div className="row filtercontent">
                                    <div className="container-fluid realtimedatahtmlcontainer">
                                        <div className="row">
                                            <div className="col-xs-12 nopadding">
                                                <label className="realtimedatalable">
                                                    <input type="radio" className="realtimedatatoggle" onClick={that.showinformationmessages} id="livemodals" name="livemodal" synctype="none" defaultChecked="checked" /> {lang["storyboard.none"]}
									</label>
                                            </div>
                                        </div>
                                        <div className="row nomargin">
                                            <div className="col-xs-12 nopadding">
                                                <label className="realtimedatalable nomargin">
                                                    <input type="radio" className="realtimedatatoggle" onClick={that.showinformationmessages} id="livemodales" name="livemodal" synctype="realtimedata" /> {lang["storyboard.updatefrequency"]}
									</label>
                                            </div>
                                        </div>
                                        <div className="row updatefrequencyrow">
                                            <div className="col-xs-12 nopadding">
                                                <h6 className="marg-left-15">{lang["storyboard.updatevery"]}</h6>
                                            </div>
                                            <div className="col-xs-5 nopadding marg-left-15">
                                                <input type="number" className="form-control reltimedatasynctime" id="reltimedatasynctimes" defaultValue="5" min="1" />
                                            </div>
                                            <div className="col-xs-1 nopadding"></div>
                                            <div className="col-xs-5 nopadding">
                                                <select className="form-control realtimedatatimetype" id="realtimedatatimetypes">
                                                    <option value="seconds" selected>{lang["storyboard.sec"]}</option>
                                                    <option value="minutes">{lang["storyboard.min"]}</option>
                                                    <option value="hours">{lang["storyboard.hrs"]}</option>
                                                </select>
                                            </div>
                                            <span className='help-block bird-has-error reltimedatasynctime-error hide'></span>
                                        </div>
                                        {(that.isDirect === 0) ? (
                                            <div className="row nomargin">
                                                <div className="col-xs-12 nopadding">
                                                    <label className="realtimedatalable nomargin streaming hide">
                                                        <input type="radio" className="realtimedatatoggle" onClick={that.showinformationmessages} id="realtimelivemodal" name="livemodal" synctype="refreshonupdate" /> {lang["storyboard.streaming"]}
									</label>
                                                </div>
                                            </div>
                                        ) : null}
                                        <div className="row message-streaming hide">
                                            <div className="col-xs-12 marg-top-10 nopadding">
                                                <p className="fa-red">{that.streamingnote}</p>
                                            </div>
                                        </div>
                                        {/* <!-- <div className="row">
								<div className="col-xs-12 nopadding">
									<label className="realtimedatalable">
										<input type="radio" className="realtimedatatoggle" name="livemodal" synctype="updateonsync"> Auto Refresh on Data Update
									</label>
								</div>
							</div> --> */}
                                        {/* <!-- <div className="row">
								<div className="col-xs-12 text-right nopadding">
									<button className="btn btn-bird btn-sm startliveupdate" data-num="1">Update</button>
								</div>
							</div> --> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer boc-modal-hf-padding">
                            <button type="button" className="btn btn-bird" id="btn-birdlivereport" data-dismiss="modal" onClick={that.close}><i className="fa fa-times"></i> {lang["storyboard.cancelbtn"]}</button>
                            <button type="button" className="btn btn-bird applylivedatasetting" onClick={that.applylivedatasetting} id="applylivedatasettings" data-num={that.chartnumber} tab-id="0" tab-name=""><i className="fa fa-check"></i> {lang["storyboard.applybtn"]}</button>
                        </div>
                    </div>{/*<!-- /.modal-content -->*/}
                </div>{/*<!-- /.modal-dialog -->*/}
            </div>//<!-- /.Page Settings Modal -->
        );
    }
}