import React from 'react';
import ReactDOM from 'react-dom';
import EditLdapTemplate from './EditLdap';
import LdapValidationView from './NewLdapValidationView';
import { lang} from "../../../js/helpers/utils/languages/language";
import SendRequest from '../../../SendRequest';
const $ = window.$;

export default class EditLdapView extends React.Component{
    constructor(props){
        super(props);
        this.getLdapDetails();
    }
    isValid=(data)=>{
        var isValid = true;
        var patt = new RegExp('/(\b)(on\S+)(\s*)=|javascript:|(<\s*)(\/*)script|style(\s*)=|(<\s*)meta/ig');
        if($.trim($.trim(data.ldapname))==""){
            $(".help-block1").removeClass('hide');
            $(".help-block1").text(lang["admin.ldap.nameerror"] );
            isValid = false;
        } else if(patt.test(data.ldapname)){
            $(".help-block1").removeClass('hide');
            $(".help-block1").text(lang["admin.ldap.invalidata"]);
            isValid = false;
        }
        if($.trim($.trim(data.ldapurl))==""){
            $(".help-block2").removeClass('hide');
            $(".help-block2").text(lang["admin.ldap.urlerror"]);
            isValid = false;
        } else if(patt.test(data.ldapurl)){
            $(".help-block2").removeClass('hide');
            $(".help-block2").text(lang["admin.ldap.invalidata"]);
            isValid = false;
        }
        if($.trim($.trim(data.ldapbinduser))==""){
            $(".help-block3").removeClass('hide');
            $(".help-block3").text(lang["admin.ldap.bindusererror"]);
            isValid = false;
        } else if(patt.test(data.ldapbinduser)){
            $(".help-block3").removeClass('hide');
            $(".help-block3").text(lang["admin.ldap.invalidata"]);
            isValid = false;
        }
        if($.trim($.trim(data.ldapbindpassword))==""){
            $(".help-block4").removeClass('hide');
            $(".help-block4").text(lang["admin.ldap.bindpwderror"]);
            isValid = false;
        } else if(patt.test(data.ldapbindpassword)){
            $(".help-block4").removeClass('hide');
            $(".help-block4").text(lang["admin.ldap.invalidata"]);
            isValid = false;
        } 
        if($.trim($.trim(data.ldapsearchbase))==""){
            $(".help-block5").removeClass('hide');
            $(".help-block5").text(lang["admin.ldap.searcherror"]);
            isValid = false;
        } else if(patt.test(data.ldapsearchbase)){
            $(".help-block5").removeClass('hide');
            $(".help-block5").text(lang["admin.ldap.invalidata"]);
            isValid = false;
        }
        return isValid; 
    }
    getLdapDetails=()=>{
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getldapsettings",
            queryString: "?ldapId="+this.props.ldapId,
            sucessFunction: (response) => {
               this.render(response);
            },
            rejectFunction:() => { },
            headers: requestHeaders

        });
    }
    getLdapUsersfromAd=(e)=>{
        var ldapFormData = $(".ldapform").serializeArray();
			var ldapModelData = {};
			$.each(ldapFormData, function(index, object) {
				ldapModelData[object.name] = object.value;
			});
            var isValid = this.isValid(ldapModelData);
			if (isValid) {
				new LdapValidationView({showUsers:true});
			}
    }
    validateLdap=(e)=>{
        var ldapFormData = $(".ldapform").serializeArray();
			var ldapModelData = {};
			$.each(ldapFormData, function(index, object) {
				ldapModelData[object.name] = object.value;
			});
			var isValid = this.isValid(ldapModelData);
			if (isValid) {
				new LdapValidationView();
			}
    }
    updateLdap=(e)=>{
        var ldapFormData = $(".ldapform").serializeArray();
			var ldapModelData = {};
			$.each(ldapFormData, function(index, object) {
				ldapModelData[object.name] = object.value;
			});		
			
			
			 if (ldapModelData["ldapname"]=== "") {
				 $(".help-block1").addClass("show").removeClass("hide");
					setTimeout(function() {
						$(".help-block1").addClass("hide").removeClass("show");
					}, 1000);
				}else if (ldapModelData["ldapurl"]=== "") {
				 $(".help-block2").addClass("show").removeClass("hide");
					setTimeout(function() {
						$(".help-block2").addClass("hide").removeClass("show");
					}, 1000);
				}else if (ldapModelData["ldapbinduser"]=== "") {
				 $(".help-block3").addClass("show").removeClass("hide");
					setTimeout(function() {
						$(".help-block3").addClass("hide").removeClass("show");
					}, 1000);
				}else if (ldapModelData["ldapbindpassword"]=== "") {
				 $(".help-block4").addClass("show").removeClass("hide");
					setTimeout(function() {
						$(".help-block4").addClass("hide").removeClass("show");
					}, 1000);
				}else if (ldapModelData["ldapsearchbase"]=== "") {
				 $(".help-block5").addClass("show").removeClass("hide");
					setTimeout(function() {
						$(".help-block5").addClass("hide").removeClass("show");
					}, 1000);
				}else{
					var isValid = this.isValid(ldapModelData);
                    if (isValid) {
						new LdapValidationView({isSave:true});
					}
				}
    }
    render(response){
        ReactDOM.render(<EditLdapTemplate ldapSettings={response} getLdapUsersfromAd = {this.getLdapUsersfromAd} updateLdap = {this.saveLdap} validateLdap = {this.validateLdap}/>,document.querySelector(".innerothersarea"));
        return(<div></div>);
    }
}