import React from 'react';
import ReactDOM from 'react-dom';
import SendRequest from '../SendRequest';
import jstz from 'jstz';
import * as ChartInfo from '../js/helpers/utils/chartinfo';
import ViewReportInfoTemplate from './ViewReportInfo.jsx';
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;

export default class ViewReportInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDisplay:false
        };
        this.state.alertsInfo = [];
       this.state.reportInfo = [];
        this.state.scheduleInfo =[];
       this.state.sharedInfo = [];
        this.state.el = "#viewreportinfoview";
        this.state.chartNumber = props.chartnumber;
        this.state.reportId = props.reportId;
        this.state.isTemp = props.isTemp;
        this.state.datamodelId = props.datamodelId;
        this.state.isImportedReport = props.isImportedReport;
        var that = this;
        if (this.state.isImportedReport) {
            this.state.schemaId = sessionStorage.getItem("importedReportConnectionId");
        }
        this.state.filterDataCount = undefined;
        this.state.totalDataCount = ChartInfo.functions.getDataSetCount();
        if ($('.singlebadge').text() > 0) {
            this.state.filterDataCount = ChartInfo.functions.getFilterDataCount();
        }
        if (this.state.reportId !=undefined && this.state.reportId!="" ) {
        }
        else{
            this.render()
        }
        SendRequest({
            url: "getReportInformation",
            queryString:
                "?reportId=" + this.state.reportId + "&isTemp=" + this.state.isTemp + "&datamodelId=" + that.state.datamodelId + "&isImportedReport=" + that.state.isImportedReport,
            sucessFunction: props => {
                this.state.alertsInfo = props.alertsInfo;
                this.state.reportInfo = props.reportInfo;
                this.state.scheduleInfo = props.scheduleInfo;
                this.state.sharedInfo = props.sharedInfo;
                this.render();
            },
            rejectFunction: () => { },
            headers: {
                method: "get",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            }
        });
    }
    quickInfoClose = () => {
        this.setState({
          isDisplay: false
        })
      }
    render() {
        ReactDOM.unmountComponentAtNode(document.querySelector(this.state.el));
        //this.$el.empty();
        var temp = this.state;
        var isStoryboard = $('.tileview').length > 0 ? true : false;
        // if (temp.reportInfo.Zone == null) {
        //     temp.reportInfo.timeZone = temp.reportInfo.timeZone;
        // } else {
        //     temp.reportInfo.timeZone = new Date().toString().match(/([A-Z]+[\+-][0-9]+.*)/)[1].substr(0, 8) + "(" + jstz.determine().name() + ")";
        // }
        if (this.state.isTemp) {
            var auditMetaDataOptions = ChartInfo.functions.getAuditMetaDataOptions();
            temp.reportInfo.metaDataAudit = auditMetaDataOptions.metaDataAudit == 1 ? true : false;
        }
        //Add Filter Info
        var time = 0;
        var isSingleReport = $(".fullscreenmain").hasClass("singlereportview");
        if (isSingleReport) {
            $("#singlerportfiltersview").trigger("click");
            $(".modal.fade.singlesavedreportmodal").css("opacity", "0");
            time = 2000;
            $(".spinner").show();
        }
        var that = this;
        setTimeout(() => {
            var filtersArray = ChartInfo.functions.getFilters();
            var filterinfo = [];
            if (filtersArray.length > 0) {
                var group_to_values = filtersArray.reduce(function (obj, item) {
                    obj[item.columnname] = obj[item.columnname] || [];
                    if (item.customfield != null && item.customfield != undefined && Object.keys(item.customfield).length > 0) {
                        if (item.dataType == "string") {
                            obj[item.columnname].push(' ' + item.customfield.sqlcondition.sqldata);
                        } else if (item.dataType == "number") {
                            if (item.fromRange != undefined) {
                                obj[item.columnname].push(item.customfield.sqlcondition.fromRange + ' To ' + item.customfield.sqlcondition.toRange);
                            } else {
                                obj[item.columnname].push(lang["storyboard.greater"]+': <b>' + item.customfield.sqlcondition.filtergreaterthanvalue + '</b><br>'+lang["storyboard.lesser"]+' : <b> ' + item.customfield.sqlcondition.filterlessthanvalue + '</b>');
                            }
                        }
                    } else {
                        if (item.dataType == "string") {
                            obj[item.columnname].push(' ' + item.data);
                        } else if (item.dataType == "number") {
                            if (item.fromRange != undefined) {
                                obj[item.columnname].push(item.fromRange + ' To ' + item.toRange);
                            } else {
                                obj[item.columnname].push(lang["storyboard.greater"]+' : <b>' + item.filtergreaterthanvalue + '</b><br> '+lang["storyboard.lesser"]+'  : <b> ' + item.filterlessthanvalue + '</b>');
                            }
                        }
                    }
                    if (item.dataType == "date") {
                        obj[item.columnname].push(item.fromRange + ' - ' + item.toRange);
                    }
                    return obj;
                }, {});

                var filterinfo = Object.keys(group_to_values).map(function (key) {
                    var filterdata = group_to_values[key]
                    filterdata = filterdata[0].replace(/@@/g, ",")
                    return { columnname: key, filterdata: filterdata };
                });
            }
            var isMLModel = false;
            var fragmentArray = window.location.pathname.split("/");
            if ((fragmentArray.length == 2 || fragmentArray.length == 3) && fragmentArray[0] === "viewmodel") {
                isMLModel = true;
            }
            ReactDOM.render(<ViewReportInfoTemplate close={this.quickInfoClose} isDisplay={this.state.isDisplay} reportInfo={temp.reportInfo} connectionInfo={temp.connectionInfo} sharedInfo={temp.sharedInfo} scheduleInfo={temp.scheduleInfo} filtersArray={filterinfo} alertsInfo={temp.alertsInfo} timeZone={temp.reportInfo.timeZone} totalDataCount={temp.totalDataCount} filterDataCount={temp.filterDataCount} isStoryboard={isStoryboard} buildnumber={$(document.getElementsByTagName('head')[0]).find("meta[name ='version']").attr("value")} isMLModel={isMLModel} />, document.querySelector(this.state.el));
            $(".quickinfocontainer").slideToggle(300);
            $("#main .boc-viewreport-section").animate({
                scrollTop: $(".boc-main").position().top
            });
            $(".quickinfotoggle>i").toggleClass("up");
            $(".boc-home").toggleClass("quickinfoup");
            $(".quikinfofilters").bootstrapTable({});
            $(".quikinfofilters").parent().parent().parent().find(".fixed-table-toolbar").prepend('<div class="pull-left downloads"><button class="btn btn-bird quickinfoexportcsv" title='+lang["storyboard.downloadcsv"]+'><i class="fa fa-download"></i></button></div>')

            $("table.table.quikinfofilters tbody td:last-child").tooltip({ tooltipClass: 'filterinfotooltip', container: 'body' });

            if (this.state.filterDataCount > 0) {
                $('.filterdatacountrow').removeClass("hide");
            }
            $(".spinner").hide();
            $(".modal.fade.singlesavedreportmodal").modal("hide")
        }, time);
        return (<></>);
    }
}