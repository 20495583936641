import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'underscore';
import SendRequest from '../SendRequest';
import DatahubItemSettings from './DatahubItemsSettings';
import { lang } from "../js/helpers/utils/languages/language";
import { getMessage } from '../js/helpers/utils/BirdMessage';
const $ = window.$;

export default class ConnectionItemsDataContainerHtml extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            entityMetaDataCollection: [],
        }
    }
    remove(e) {
        e.preventDefault();
        var $this = e.currentTarget;
        var tablevalue = $($this).parent().find("input").attr("data-value");
        var isSaved = $($this).parent().find("input").attr("is-saved")
        var connectionId = $($this).parent().find("input").attr("data_parent");
        var isCustomQuery = $($this).parent().find("input").hasClass('customquery');
        var isdeleteindex = true;
        $("#settingscontainer").remove();
        $("#datahubentitycontainer").removeClass('settingsopened');
        if (isdeleteindex && !isCustomQuery) {
            $(".filtercontent input[data-value='" + tablevalue + "']").parent().parent().draggable({ disabled: false }).removeClass("disabled");
            $(".filtercontent input[data-value='" + tablevalue + "']").prop('checked', false);
            _.each($("#datahubentitycontainer .scopeitem"), (i, j) => {
                var $this1 = $(i);
                if (connectionId == $($this1).attr('data_parent') && tablevalue == $($this1).attr('data-value')) {
                    $($this1).parent().parent().remove();
                    if ($($this1).hasClass("customquery")) {
                        if ($($this1).attr('is-valid') == 'true') {
                            if (tablevalue.indexOf("_CQ") > 0) {
                                tablevalue = tablevalue.replace("_CQ", "")
                            }
                            var customuqueryval = tablevalue.trim() + "_CQ";
                            $(".datahub-customquery-item").find("button[data_parent='" + connectionId + "'][name='" + tablevalue + "']").parent().remove();
                            $("#settingscontainer").remove();
                            $("#datahubentitycontainer").removeClass('settingsopened');
                            sessionStorage.removeItem(customuqueryval);
                            var customQueries = sessionStorage.getItem("customquerySessionObj");
                            if (customQueries != undefined && customQueries != null) {
                                sessionStorage.removeItem("customquerySessionObj")
                                if (customQueries.indexOf(customuqueryval + ",") >= 0) {
                                    var updatedCustomquery = customQueries.replace(customuqueryval + ",", "");
                                    sessionStorage.setItem("customquerySessionObj", updatedCustomquery);
                                } else if (customQueries.indexOf("," + customuqueryval) >= 0) {
                                    var updatedCustomquery = customQueries.replace("," + customuqueryval, "");
                                    sessionStorage.setItem("customquerySessionObj", updatedCustomquery);
                                } else if (customQueries.indexOf(customuqueryval) >= 0) {
                                    var updatedCustomquery = customQueries.replace(customuqueryval, "");
                                }
                            }
                        } else {
                            $(".datahub-customquery-item").find("button[data_parent='" + connectionId + "'][name='" + tablevalue + "']").parent().remove()
                            $("#settingscontainer").remove();
                            $("#datahubentitycontainer").removeClass('settingsopened');
                        }

                    } else if ($($this1).hasClass("storedprocedure")) {
                        $($this1).parent().parent().remove();
                        sessionStorage.removeItem($($this1).attr('data-value'));
                    }
                    if ($('.datahubitemsettingstabscontainer').attr("name") != undefined && $('.datahubitemsettingstabscontainer').attr("name").trim() == $($this1).attr('data-value') && $('.datahubitemsettingstabscontainer').attr("data-id") == $($this1).attr('data_parent')) {
                        $('.datahubitemsettingstabscontainer').remove();
                        $("#datahubentitycontainer").removeClass('settingsopened');
                    }
                    // this.resetReportColumns(connectionId+"&&&"+tablevalue);
                    // this.resetCollection();
                    $("#datahubentitycontainer").removeClass('settingsopened');

                }

            });
        }
    }
    editdatahubitem = (e) => {
        e.preventDefault();
        $(".spinner").show();
        var $this = e.currentTarget;
        // var that = this.state;
        var connId = $($this).attr("data_parent");
        var entityType = $($this).attr("entityType");
        var entityName = $($this).attr('name').trim();
        var connectiondata = this.props.connectionaccessdata[connId];
        var cjson = JSON.parse(connectiondata.connectionjson);
        var schemaName = connectiondata.connectionName;
        var entityId;

        connectiondata["entitytype"] = entityType;
        var isSaved = $($this).attr('is-saved') != undefined ? $($this).attr('is-saved') : '';
        $("#datahubentitycontainer>li").removeClass('active');
        entityId = this.props.exisitingEntities.map((i, j) => {
            if (i.entityname === entityName && i.connAccessId === connId) {
                return i.entityId
            }
        })
        var selectedEntities = {};
        selectedEntities[$($this).attr('data_parent') + "&&&" + $($this).attr('name').trim()] = connectiondata;
        $("#settingscontainer").remove();
        $("#datahubentitycontainer").removeClass('settingsopened');
        $("#datahubentitycontainer").after('<div id="settingscontainer"></div>');
        let x = new FormData();
        x.append('entities', JSON.stringify(selectedEntities));

        SendRequest({
            url: "getentitiesmetadata",
            queryString: "",
            body: x,
            sucessFunction: (response) => {
                if (response.success == false || response.hasOwnProperty("error")) {
                    if (response.status == "fail") {
                        $(".spinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Warning');
                        $('.birdmessage-info').empty().text("The Column Name '" + response.columnName + "'" + "" + getMessage('BirdWarning149'));
                        $(".spinner").hide();
                        return;
                    } else {
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(lang["Datahub.unablefetch"]);
                        $('.details').addClass('show');
                        if (response.success == false || response.hasOwnProperty("error")) {
                            $('.messagedetails xmp').empty().append(response.errorTrace);
                        }
                        else {
                            $('.messagedetails xmp').empty().append(response.error);
                        }
                        $('.spinner').hide();
                        return
                    }
                }
                this.setState({
                    entityMetaDataCollection: response
                });
                ReactDOM.render(
                    <DatahubItemSettings
                        metadata={this.state.entityMetaDataCollection}
                        connectionName={connectiondata.connectionName}
                        schemaId={connectiondata.schemaId}
                        schemaName={connectiondata.connectionName}
                        isFromEdit={true}
                        schemaDisplayName={connectiondata.connectionName}
                        filename={cjson.fileName}
                        connectiondata={JSON.stringify(connectiondata)}
                        connectionId={connId}
                        entityName={entityName}
                        entityId={entityId}
                        entityMetaDataCollection={this.state.entityMetaDataCollection}
                        dataHubEntitiesCollection={this.props.exisitingEntities}
                        isSaved={isSaved}
                    />,
                    document.getElementById('settingscontainer')
                );
                $($this).closest("li").addClass('active');
                $("#settingscontainer").addClass("showsettings");
                $("#datahubentitycontainer").addClass('settingsopened');
                setTimeout(function () {
                    var container = $("#datahubentitycontainer"),
                        scrollTo = $($this).closest("li");
                    container.animate({
                        scrollTop: scrollTo.offset().top - container.offset().top + container.scrollTop() - container.height() / 2
                    });
                }, 1000);
            },
            rejectFunction: (error) => { },
            headers: {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            }
        })
    }
    componentDidMount() {

        _.each($("#datahubentitycontainer .scopeitem"), function (i, j) {
            var $this = $(i);
            $(".filtercontent input[data-value='" + $this.attr('id') + "'][data_parent='" + $this.attr('data_parent') + "']").parent().parent().draggable({ disabled: true }).addClass("disabled");
            $(".filtercontent input[data-value='" + $this.attr('id') + "'][data_parent='" + $this.attr('data_parent') + "']").prop('checked', true);
        });
        $("ul, li").disableSelection();


    }
    addEntityItem = (e) => {
        var $this = e.currentTarget;
        if ($($this).prop('checked')) {
            var totalTempEntitiesAdded = $("#datahubentitycontainer>li:not(.is-saved-entity)").length;
            var totalEntitiesAdded = $("#datahubentitycontainer>li").length;
            if (totalEntitiesAdded >= 1000) {
                $($this).prop('checked', false);
                $($this).parent().parent().draggable({ disabled: false }).removeClass('disabled');

                $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-infor').addClass('birdmessage-warning');
                $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-info-circle').addClass('fa-exclamation-triangle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Warning');
                $("#datahubstepscontainer").steps("previous");
                $('.birdmessage-info').empty().text(getMessage('BirdWarning151'));
                $('.birdmessage-center').addClass("message-top-in");
                $('.spinner').hide();
                return;
            } else if (totalTempEntitiesAdded >= 100) {
                $($this).prop('checked', false);
                $($this).parent().parent().draggable({ disabled: false }).removeClass('disabled');
                $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-infor').addClass('birdmessage-warning');
                $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-info-circle').addClass('fa-exclamation-triangle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Warning');
                $("#datahubstepscontainer").steps("previous");
                $('.birdmessage-info').empty().text(getMessage('BirdWarning157'));
                $('.birdmessage-center').addClass("message-top-in");
                $('.spinner').hide();
                return;
            } else {
                var cloneEntity = $($this).parent().parent().clone();
                $("#datahubentitycontainer").append(cloneEntity);
                _.each(cloneEntity, function (i) {
                    if ($(i).hasClass("dbviewentity")) {
                        var viewentityname = $(i).children("button").attr("name");
                        var viewentitytype = $(i).children("button").attr("entitytype");
                        var connectionId = $(i).children("button").attr("data_parent");
                        sessionStorage.setItem(connectionId + "&&&" + viewentityname.trim(), viewentitytype);
                    }
                })

                $($this).parent().parent().draggable({ disabled: true }).addClass('disabled');
                $('.joinremove').unbind().click((e) => {
                    this.remove(e);
                });
                $('.editdatahubitem').unbind().click((e) => {
                    this.editdatahubitem(e);
                })
                $('.editstoredprocedure').click((e) => {
                    this.props.loadstoredproceduredetails(e);
                })
            }

        } else {

        }
        //	$("#datahubentitycontainer").append(existingEntities({item:entityName,connectionaccessid:connAccessId, showsettings:true,entity_type:entityType,entityId:entityId,entityType:entityType}));
    }
    render() {
        return (
            <React.Fragment>
                {/* <!-- databaseconnectionview.js --> */}
                <div className="col-xs-12 nopadding">
                    <div className="panel-group">
                        {(this.props.connectiontype.toLowerCase().indexOf('c') >= 0) ? (
                            <div className="acc-content">
                                <div className="panelboc">
                                    <div className="panelboc-heading">
                                        <h4 className="panelboc-title accordion-toggle" title={lang["datahub.customqry"]} data-toggle="collapse">
                                            <i className="fa fa-code"></i>{lang["datahub.customqry"]}
                                            <button className="btn btn-transparent datahub-addcustomquery" data_parent={this.props.connectionaccessid} onClick={this.props.datahubaddcustomquery}>
                                                <i className="fa fa-plus"></i>
                                            </button>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {(this.props.connectiontype.toLowerCase().indexOf('t') >= 0) ? (
                            <div className="acc-content">
                                <div className="panelboc">
                                    <div className="panelboc-heading">
                                        <h4 className="panelboc-title accordion-toggle" title="" id="tablecolllapse" data-toggle="collapse" href="#dbscopetables" aria-expanded="true">
                                            <i className="fa fa-table"></i>{lang["datahub.entities"]}
                                        </h4>
                                    </div>
                                    <div id="dbscopetables" className="panel-collapse panelboc-content filterStringvalues newreportdbitems collapse in" aria-expanded="true" style={{}}>
                                        <div className="panelboc-body">
                                            <div className="filtercontent" id="scopeItem">
                                                <div className="container-fluid marg-bot-10">
                                                    <div className="row">
                                                        <input className="form-control search-image" name="Scope_Production" placeholder={lang["datahub.search"]} id="searchinput" type="text" onChange={this.props.search} />
                                                    </div>
                                                </div>
                                                <ul className="list-group scrollsetul checkhelpitems">
                                                    {
                                                        this.props.scopeitems[0].tables.map((item, index) => {
                                                            var isDurable = false;
                                                            if (this.props.scopeitems[0].durability !== undefined) {
                                                                isDurable = this.props.scopeitems[0].durability[item];
                                                            }
                                                            return (
                                                                <li item-expanded='true' className="viewreport-list-item" key={index}>
                                                                    <label title={item}>
                                                                    <span className="source"><i className="fa fa-link"></i>{this.props.connectionname}</span>
                                                                        <i className="fa fa-table"></i>
                                                                        <input
                                                                            type="checkbox"
                                                                            durable={isDurable}
                                                                            onClick={this.addEntityItem}
                                                                            className={`scopeitem ${this.props.connectiontype.toLowerCase()}tablevalue`}
                                                                            data_parent={this.props.connectionaccessid}
                                                                            name={this.props.connectiontype}
                                                                            id={item}
                                                                            data-value={item}
                                                                        />
                                                                        {item}
                                                                    </label>
                                                                    <button className="btn btn-transparent btn-sm editdatahubitem" data_parent={this.props.connectionaccessid} name={item} type="button" title={lang["Datahub.settings"]} >
                                                                        <i className="fa fa-cog"></i>
                                                                    </button>
                                                                    <button className="btn btn-transparent btn-sm joinremove" type="button" onClick={this.props.joinremove}>
                                                                        <i className="fa fa-times"></i>
                                                                    </button>
                                                                </li>
                                                            );
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {(this.props.connectiontype.toLowerCase().indexOf('s') >= 0) ? (
                            <div className="acc-content storedprocedurediv">
                                <div className="panelboc">
                                    <div className="panelboc-heading">
                                        <h4 className="panelboc-title accordion-toggle" title="" id="storedprocedurescollapse" data-toggle="collapse" href="#dbstoreprocedures" aria-expanded="false">
                                            <i className="fa fa-list-alt"></i>{lang["datahub.storedproced"]}
                                        </h4>
                                    </div>
                                    <div id="dbstoreprocedures" className="panel-collapse collapse panelboc-content filterStringvalues newreportdbitems">
                                        <div className="panelboc-body">
                                            <div className="filtercontent" id="scopeItem">
                                                <div className="container-fluid marg-bot-10">
                                                    <div className="row">
                                                        <input className="form-control search-image" name="" placeholder={lang["datahub.search"]} id="searchinput" type="text" onChange={this.props.search} />
                                                    </div>
                                                </div>
                                                <ul className="list-group scrollsetul checkhelpitems">
                                                    {
                                                        this.props.scopeitems[0].procedures.map((item, index) => {
                                                            return (
                                                                <li className="viewreport-list-item" key={index}>
                                                                    <label title={item}>
                                                                    <span className="source"><i className="fa fa-link"></i>{this.props.connectionname}</span>
                                                                        <i className="fa fa-list-alt"></i>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="scopeitem storedprocedure"
                                                                            onClick={this.addEntityItem}
                                                                            data_parent={this.props.connectionaccessid}
                                                                            name={item}
                                                                            id={item}
                                                                            data-value={item}
                                                                        />
                                                                        {item}
                                                                    </label>
                                                                    <button className="btn btn-transparent btn-sm editstoredprocedure" data_parent={this.props.connectionaccessid} name={item} type="button" title={lang["Datahub.parasettings"]}>
                                                                        <i className="fa fa-cog"></i>
                                                                    </button>
                                                                    <button className="btn btn-transparent btn-sm joinremove" type="button">
                                                                        <i className="fa fa-times"></i>
                                                                    </button>
                                                                </li>
                                                            );
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {(this.props.connectiontype.toLowerCase().indexOf('v') >= 0) ? (
                            <div className="acc-content">
                                <div className="panelboc">
                                    <div className="panelboc-heading">
                                        <h4 className="panelboc-title accordion-toggle" title="" id="viewscollapse" data-toggle="collapse" href="#dbviews" aria-expanded="false">
                                            <i className="fa fa-search-plus"></i>{lang["datahub.views"]}
                                        </h4>
                                    </div>
                                    <div id="dbviews" className="panel-collapse collapse panelboc-content filterStringvalues newreportdbitems">
                                        <div className="panelboc-body">
                                            <div className="filtercontent" id="scopeItem">
                                                <div className="container-fluid marg-bot-10">
                                                    <div className="row">
                                                        <input className="form-control search-image" name="" placeholder={lang["datahub.search"]} id="searchinput" type="text" onChange={this.props.search} />
                                                    </div>
                                                </div>
                                                <ul className="list-group scrollsetul checkhelpitems">
                                                    {
                                                        this.props.scopeitems[0].views.map((item, index) => {
                                                            return (
                                                                <li className="viewreport-list-item dbviewentity" key={index}>
                                                                    <label title={item}>
                                                                    <span className="source"><i className="fa fa-link"></i>{this.props.connectionname}</span>
                                                                        <i className="fa fa-search-plus"></i>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="scopeitem"
                                                                            data_parent={this.props.connectionaccessid}
                                                                            name={item}
                                                                            onClick={this.addEntityItem}
                                                                            id={item}
                                                                            data-value={item}
                                                                        />
                                                                        {item}
                                                                    </label>
                                                                    <button className="btn btn-transparent btn-sm editdatahubitem" data_parent={this.props.connectionaccessid} entityType="views" name={item} type="button" title={lang["Datahub.settings"]}>
                                                                        <i className="fa fa-cog"></i>
                                                                    </button>
                                                                    <button className="btn btn-transparent btn-sm joinremove" type="button">
                                                                        <i className="fa fa-times"></i>
                                                                    </button>
                                                                </li>
                                                            );
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}