import React from 'react'
import ReactDOM from 'react-dom'
import PubSub from 'pubsub-js'
import ViewReportFilterView from '../ViewStoryboard/viewreportfilter'
import DataAdvancedShareTemplate from './datamodelsadvancedshare.jsx'
import * as ChartInfo from '../js/helpers/utils/chartinfo';
import _ from 'underscore'

const $ = window.$;
export default class DataModelAdvanceShareView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        var viewreportfilterview = new ViewReportFilterView({
        });
        this.render();
    }
    render() {
        var that = this;
        this.state.isStoredProcedure = ChartInfo.functions.getIsStoredProcedure();
        var groupsArray = [];
        _.each(this.props.selectedgrouplist, function (groupId, i) {
            var obj = {};
            obj["groupid"] = $($('.group-select .groupids option:selected')[i]).val();
            groupsArray.push(obj);

        });
        var finalGroupsArray = [];
        var unique = {};
        _.each(groupsArray, function (item) {
            if (!unique[item.groupid]) {
                finalGroupsArray.push(item);
                unique[item.groupid] = item;
            }
        });
        var usersArray = [];
        _.each(this.props.selectuserslist, function (userId, i) {
            var obj = {};
            obj["userid"] = $($('.group-select .userids option:selected')[i]).val();
            usersArray.push(obj);

        });
        var finalUsersArray = [];
        var unique = {};
        _.each(usersArray, function (item) {
            if (!unique[item.userid]) {
                finalUsersArray.push(item);
                unique[item.userid] = item;
            }
        });
        if (this.props.dimensions.length > 1) {
            this.props.dimensions.sort(function (a, b) {
                var x = a.columnDisplayName.toLowerCase();
                var y = b.columnDisplayName.toLowerCase();
                return x < y ? -1 : x > y ? 1 : 0;
            });
        }
        ReactDOM.render(<DataAdvancedShareTemplate editallcheckeditems={this.editallcheckeditems} permissionfiltercheckbox={this.permissionfiltercheckbox} permitcolumndata={this.permitcolumndata} checkallshareitems={this.checkallshareitems} uncheckallshareitems={this.uncheckallshareitems} datamodelId={this.props.datamodelId} groupcollection={this.props.groupcollection} userscollection={this.props.userscollection} selectedgrouplists={finalGroupsArray} selecteduserslist={finalUsersArray} isStoredProcedure={0} dimensions={this.props.dimensions} />, document.getElementById("showadvanceditems"));

        var columns = ChartInfo.functions.getSelectedColumnsForSharedReports();
        if (columns != null) {
            var userobj = [];
            ChartInfo.functions.setSelectedColumnsForSharedReports(userobj);
            columns = ChartInfo.functions.getSelectedColumnsForSharedReports();
        }

        //	var viewonlychecked=ChartInfo.functions.getisViewOnlyOptions();
        _.each(finalGroupsArray, function (groups, i) {
            if (columns.length != 0 && columns[groups.groupid + '_' + that.props.datamodelId]) {
                $(".dobyfilteritems[data-groupidandmodelid='" + groups.groupid + '_' + that.props.datamodelId + "'] .permissionfiltercheckbox").removeAttr('checked');
                //	$(".dobyfilteritems[data-userid='"+groups.groupid+"'] .permissionfiltercheckbox").removeAttr('checked');
                _.each(columns[groups.groupid + '_' + that.props.datamodelId], function (i, j) {
                    $(".dobyfilteritems[data-groupidandmodelid='" + groups.groupid + '_' + that.props.datamodelId + "'] .permissionfiltercheckbox[name='" + i.columnName + "']").prop('checked', true)
                    //$(".dobyfilteritems[data-userid='"+groups.groupid+"'] .permissionfiltercheckbox[name='"+i.columnName+"']").prop('checked',true)
                });
                /*_.each(viewonlychecked,function(groupObjects,j){
                    _.each(groupObjects,function(groupObject,k){
                        if(groupObject.groups!=undefined){
                            if(groupObject.groups.checkedValue){
                                $(".btnReadonlyReport[data-userid='"+groupObject.groups.id+"']").prop("checked",true);
                            }
                        }
                    });
                });*/
            }
            else {
                if (that.props.sharedtogroups.length != 0 && columns.length == 0) {
                    _.each(that.props.sharedtogroups, function (i, j) {
                        if (that.props.sharedtogroups[j].reportcolumns != null && that.props.sharedtogroups[j].reportcolumns != "null" && that.props.sharedtogroups[j].groupid == groups.groupid) {
                            //$(".dobyfilteritems[data-userid='"+groups.groupid+"'] .permissionfiltercheckbox").removeAttr('checked');
                            $(".dobyfilteritems[data-groupidandmodelid='" + groups.groupid + '_' + that.props.datamodelId + "'].permissionfiltercheckbox").removeAttr('checked');
                            _.each(JSON.parse(that.props.sharedtogroups[j].reportcolumns), function (value, k) {
                                //$(".dobyfilteritems[data-userid='"+groups.groupid+"'] .permissionfiltercheckbox[name='"+value.columnName+"']").prop('checked',true)
                                $(".dobyfilteritems[data-useridandmodelid='" + groups.groupid + '_' + that.props.datamodelId + "'].permissionfiltercheckbox[name='" + value.columnName + "']").prop('checked', true)
                            });
                            if (i.view_only == "1") {
                                $(".btnReadonlyReport[data-userid='" + i.groupid + "']").prop("checked", true);
                            }
                        }
                    });
                }
            }
        });
        _.each(finalUsersArray, function (users, i) {
            if (columns.length != 0 && columns[users.userid + '_' + that.props.datamodelId]) {
                $(".dobyfilteritems[data-useridandmodelid='" + users.userid + '_' + that.props.datamodelId + "'] .permissionfiltercheckbox").removeAttr('checked');
                _.each(columns[users.userid + '_' + that.props.datamodelId], function (i, j) {
                    $(".dobyfilteritems[data-useridandmodelid='" + users.userid + '_' + that.props.datamodelId + "'] .permissionfiltercheckbox[name='" + i.columnName + "']").prop('checked', true)
                    //$(".dobyfilteritems[data-userid='"+users.userid+"'] .permissionfiltercheckbox[name='"+i.columnName+"']").prop('checked',true)

                });
                /*_.each(viewonlychecked,function(userObjects,j){
                    _.each(userObjects,function(userObject,k){
                        if(userObject.users!=undefined){
                            if(userObject.users.checkedValue){
                                $(".btnReadonlyReport[data-userid='"+userObject.users.id+"']").prop("checked",true);
                            }
                        }
                    });
                });*/
            }
            else {
                if (that.props.sharedtousers.length != 0 && columns.length == 0) {
                    _.each(that.props.sharedtousers, function (i, j) {
                        if (that.props.sharedtousers[j].reportcolumns != null && that.props.sharedtousers[j].reportcolumns != "null" && that.props.sharedtousers[j].userid == users.userid) {
                            $(".dobyfilteritems[data-useridandmodelid='" + users.userid + '_' + that.props.datamodelId + "'] .permissionfiltercheckbox").removeAttr('checked');
                            _.each(JSON.parse(that.props.sharedtousers[j].reportcolumns), function (value, k) {
                                $(".dobyfilteritems[data-useridandmodelid='" + users.userid + '_' + that.props.datamodelId + "'] .permissionfiltercheckbox[name='" + value.columnName + "']").prop('checked', true)
                            });
                        }
                        if (i.view_only == "1") {
                            $(".btnReadonlyReport[data-userid='" + i.userid + "']").prop("checked", true);
                        }
                    });
                }
            }
        });
        this.createInitFilterObject();
        return (<div></div>);
    }
    createInitFilterObject() {
        var users = this.props.sharedtousers || [];
        var groups = this.props.sharedtogroups || [];
        var userFilterArray = {
            "users": {},
            "groups": {}
        };
        _.each(users, function (key, value) {
            if (key.filterobject != undefined && key.filterobject.length > 0) {
                userFilterArray["users"][key.userid] = JSON.parse(key.filterobject);
            }
        });
        _.each(groups, function (key, value) {
            if (key.filterobject != undefined && key.filterobject.length > 0) {
                userFilterArray["groups"][key.groupid] = JSON.parse(key.filterobject);
            }
        });
        ChartInfo.functions.setUserFilterArray(userFilterArray);
    }
    checkallshareitems = (e) => {
        var $this = e.currentTarget;
        $($this).parent().parent().find('ul.permissionlist input[type="checkbox"]').prop("checked", true);
    }
    uncheckallshareitems = (e) => {
        var $this = e.currentTarget;
        var id = $($this).attr("data-userid");
        $($this).parent().parent().find('ul.permissionlist input[type="checkbox"]').prop("checked", false);
        //Bellow Line is to Select atLeast one column
        $(".permissionfiltercheckbox[data-userid=" + id + "]:first").prop("checked", true);
        $(".permissioncontainerrightfilter").empty();
    }
    permitcolumndata = (e) => {
        e.preventDefault();
        e.stopPropagation();
        var $this = e.currentTarget;
        // for checking the min and max value for numeric values in advanced filters
        if (!this.validateRangeValues()) {
            return false;
        }
        // 			Updating previous users filters before showing current user filter
        if ($('.datapermissiondiv').is(":visible")) {
            $(".datapermissionupdate").trigger("click");
        }

        var parsedSharedfilters;
        if (this.state.sharedFilters != undefined && this.state.sharedFilters.length > 2) {
            parsedSharedfilters = JSON.parse(this.state.sharedFilters);
        }
        var dataid = $($this).attr("data-child");
        var that = this;
        var userid = $($this).closest('.permissioncontainer').attr('data-userid');
        var usertype = $($this).closest('.permissioncontainer').attr('user-type');
        var tableName = $($this).parent().find("input").attr("data-parent");
        var columnType = $($this).attr("data-child");

        $('.permissioncontainerrightfilter ').attr('data-userid', userid);
        $('.permissioncontainerrightfilter ').attr('data-usertype', usertype);
        $(".permissioncontainerrightfilter").empty();
        var username = $($this).attr("user-name");
        if (usertype == "users") {
            $(".rightpermissioncontainer>div.col-xs-12").html("<i class='fa fa-user'></i> " + username);
        } else {
            $(".rightpermissioncontainer>div.col-xs-12").html("<i class='fa fa-users'></i> " + username);
        }
        $("#showadvanceditems, .basicshareinfo, .sharemodelapply").addClass('hide');
        $('.datapermissiondiv, .datapermissionupdate').removeClass("hide");
        //$('#advancedsharestoryboardpermissionmodal .modal-dialog.filterleft').removeClass("modal-sm, modal-md-400").addClass("modal-lg");
        //$('.columnspermissiondiv').removeClass("col-xs-12").addClass("col-xs-6");
        $('.datapermissiondiv').removeClass("hide");
        var tableName = $($this).parent().find("input").attr("data-parent");
        var columnDisplayName = $($this).parent().find("input").attr("data-child");
        var columnName = $($this).parent().text().trim();
        var dataType = $($this).parent().find("input").attr("data-type");
        var daterange = $($this).parent().find("input").attr("date-range");
        var elelement = '.permissioncontainerrightfilter';

        var filterArray = ChartInfo.functions.getUserFilterArray();
        var UsersFilterArray = [];
        var filterObjColumnName = "";
        var toRange = "";
        var fromRange = "";
        $(".dobyfilteritems[data-userid='" + userid + "'] .permissionfiltercheckbox[data-child='" + columnName + "']").prop('checked', true);
        //For getting the previous filter values
        if (that.props.sharedtousers.length != 0) {
            _.each(that.props.sharedtousers, function (i, j) {
                if (that.props.sharedtousers[j].filterobject != null && that.props.sharedtousers[j].filterobject != "null" && that.props.sharedtousers[j].userid == userid) {
                    _.each(JSON.parse(that.props.sharedtousers[j].filterobject), function (value, k) {
                        $(".dobyfilteritems[data-userid='" + userid + "'] .permissionfiltercheckbox[name='" + value.data + "']").prop('checked', true);
                        filterObjColumnName = value.columnname;
                        toRange = value.toRange;
                        fromRange = value.fromRange;
                        daterange = value.daterange;
                    });
                }
                if (i.view_only == "1") {
                    $(".btnReadonlyReport[data-userid='" + i.userid + "']").prop("checked", true);
                }
            });
        }
        if (that.props.sharedtogroups.length != 0 && usertype == "groups") {
            _.each(that.props.sharedtogroups, function (i, j) {
                if (that.props.sharedtogroups[j].filterobject != null && that.props.sharedtogroups[j].filterobject != "null" && that.props.sharedtogroups[j].groupid == userid) {
                    _.each(JSON.parse(that.props.sharedtogroups[j].filterobject), function (value, k) {
                        $(".permissioncontainerrightfilter [data-userid='" + userid + "'] .permissionfiltercheckbox[name='" + value.data + "']").prop('checked', true);
                        filterObjColumnName = value.columnname;
                        toRange = value.toRange;
                        fromRange = value.fromRange;
                        daterange = value.daterange;
                    });
                }
                if (i.view_only == "1") {
                    $(".btnReadonlyReport[data-userid='" + i.userid + "']").prop("checked", true);
                }
            });
        }
        _.each(filterArray[usertype][userid], function (item) {
            if (item.columndisplayname == columnName) {
                filterObjColumnName = columnName;
                UsersFilterArray.push(item);
                toRange = item.toRange;
                fromRange = item.fromRange;
                daterange = item.daterange;
            }
        });
        var ClassName;
        if (dataType == "date") {
            ClassName = "advanced-access-daterange-center";
        } else if (dataType == "number") {
            ClassName = "advanced-access-filterrangevalues";
        } else {
            ClassName = "advanced-access-filtervalue";
        }
        var existingObj = that.isfilterAlreadyexists(parsedSharedfilters, columnName);
        if (existingObj != undefined && Object.keys(existingObj).length > 0) {
            var tempArray = [];
            tableName = existingObj.tablename;
            columnDisplayName = existingObj.columndisplayname;
            columnName = existingObj.columnname
            dataType = existingObj.dataType
            fromRange = existingObj.fromRange
            toRange = existingObj.toRange
            UsersFilterArray = tempArray.push(existingObj);
            daterange = existingObj.daterange

            var filterItemObj = {};
            filterItemObj["filterDisplayName"] = columnDisplayName;
            filterItemObj["filterIgnore"] = false;
            filterItemObj["tableName"] = tableName;
            filterItemObj["columnName"] = columnDisplayName;
            filterItemObj["columnDisplayName"] = columnName;
            filterItemObj["columnType"] = dataType;
            filterItemObj["fromrange"] = fromRange;
            filterItemObj["torange"] = toRange;
            filterItemObj["filterObject"] = UsersFilterArray;
            filterItemObj["datamodelId"] = that.props.datamodelId;
            filterItemObj["entities"] = that.props.entities;
            filterItemObj["daterange"] = daterange;
            filterItemObj["parametervaluetype"] = "";
            filterItemObj["elelement"] = elelement;
            filterItemObj["className"] = ClassName;
            filterItemObj["sharedFilters"] = that.state.sharedFilters;
            filterItemObj["filterid"] = userid;
            filterItemObj["indexName"] = undefined;
            filterItemObj["esType"] = undefined;
            PubSub.publish("addfilteritem", filterItemObj);

        } else if (filterObjColumnName == columnName) {
            var filterItemObj = {};
            filterItemObj["filterDisplayName"] = columnDisplayName;
            filterItemObj["filterIgnore"] = false;
            filterItemObj["tableName"] = tableName;
            filterItemObj["columnName"] = columnDisplayName;
            filterItemObj["columnDisplayName"] = columnName;
            filterItemObj["columnType"] = dataType;
            filterItemObj["fromrange"] = fromRange;
            filterItemObj["torange"] = toRange;
            filterItemObj["filterObject"] = UsersFilterArray;
            filterItemObj["datamodelId"] = that.props.datamodelId;
            filterItemObj["entities"] = that.props.entities;
            filterItemObj["daterange"] = daterange;
            filterItemObj["parametervaluetype"] = "";
            filterItemObj["elelement"] = elelement;
            filterItemObj["className"] = ClassName;
            filterItemObj["sharedFilters"] = that.state.sharedFilters;
            filterItemObj["filterid"] = userid;
            filterItemObj["indexName"] = undefined;
            filterItemObj["esType"] = undefined;
            PubSub.publish("addfilteritem", filterItemObj);

        } else {
            var filterItemObj = {};
            filterItemObj["filterDisplayName"] = columnDisplayName;
            filterItemObj["filterIgnore"] = false;
            filterItemObj["tableName"] = tableName;
            filterItemObj["columnName"] = columnDisplayName;
            filterItemObj["columnDisplayName"] = columnName;
            filterItemObj["columnType"] = dataType;
            filterItemObj["fromrange"] = null;
            filterItemObj["torange"] = null;
            filterItemObj["filterObject"] = that.props.dimensions;
            filterItemObj["datamodelId"] = that.props.datamodelId;
            filterItemObj["entities"] = that.props.entities;
            filterItemObj["daterange"] = daterange;
            filterItemObj["parametervaluetype"] = "";
            filterItemObj["elelement"] = elelement;
            filterItemObj["className"] = ClassName;
            filterItemObj["sharedFilters"] = that.state.sharedFilters;
            filterItemObj["filterid"] = userid;
            filterItemObj["indexName"] = undefined;
            filterItemObj["esType"] = undefined;
            PubSub.publish("addfilteritem", filterItemObj);
        }
    }
    isfilterAlreadyexists(parsedSharedfilters, columnName) {
        var obj;
        _.each(parsedSharedfilters, function (value) {
            if (value.columnname == columnName) {
                obj = value
            }
        });
        return obj;
    }
    validateRangeValues(e) {
        var filterAccordianLength = $('.acc-content').length;
        var flag = false;
        for (var i = 0; i < filterAccordianLength; i++) {
            var $this = $('.acc-content').eq(i);
            var fromRangeVal = $($this).find('.irs-min').text();
            var toRangeVal = $($this).find('.irs-max').text();
            var greaterThanValue = $($this).find('#greaterthanvalue').val();
            var lessThanValue = $($this).find('#lessthanvalue').val();
            $($this).find('.filtergreaterthanvalue.filter-error').addClass("hide");
            $($this).find('.filterlessthanvalue.filter-error').addClass("hide");
            /*if(greaterThanValue != undefined && greaterThanValue !="" &&
                (!(parseFloat(greaterThanValue) >= parseFloat(fromRangeVal)) || !(parseFloat(greaterThanValue) <= parseFloat(toRangeVal)))){
                $($this).find('.filtergreaterthanvalue.filter-error').removeClass("hide").text(getMessage('BirdWarning108'));
                $($this).find('#greaterthanvalue').focus();
                flag = true;
            }
            if(lessThanValue != undefined && lessThanValue !="" &&
                (!(parseFloat(lessThanValue) >= parseFloat(fromRangeVal)) || !(parseFloat(lessThanValue) <= parseFloat(toRangeVal)))){
                $($this).find('.filterlessthanvalue.filter-error').removeClass("hide").text(getMessage('BirdWarning108'));
                $($this).find('#lessthanvalue').focus();
                flag = true;
            }*/
        }
        /*if(flag){
            return false;
        }*/
        return true;
    }
    permissionfiltercheckbox = (e) => {
        e.stopPropagation();
        var currentTarget = $(e.currentTarget);
        var id = currentTarget.attr("data-userid");
        if ($(".permissionfiltercheckbox[data-userid=" + id + "]:checked").length == 0) {
            currentTarget.prop("checked", true);
        }
    }
    editallcheckeditems = (e) => {
        e.preventDefault();
        e.stopPropagation();
        var $this = e.currentTarget;
        // 			Updating previous users filters before showing current user filter
        if ($('.datapermissiondiv').is(":visible")) {
            $(".datapermissionupdate").trigger("click", 'fromEdit');
        }
        var parsedSharedfilters;
        if (this.state.sharedFilters != undefined && this.state.sharedFilters.length > 2) {
            parsedSharedfilters = JSON.parse(this.state.sharedFilters);
        }
        var userid = $($this.parentElement.parentElement).attr("data-userid");
        var usertype = $($this.parentElement.parentElement).attr("user-type");
        $('.permissioncontainerrightfilter').attr('data-userid', userid);
        $('.permissioncontainerrightfilter').attr('data-usertype', usertype);
        //$('#advancedsharestoryboardpermissionmodal .modal-dialog.filterleft').removeClass("modal-sm, modal-md-400").addClass("modal-lg");
        //$('.columnspermissiondiv').removeClass("col-xs-12").addClass("col-xs-6");
        $("#showadvanceditems, .basicshareinfo, .sharemodelapply").addClass('hide');
        $('.datapermissiondiv, .datapermissionupdate').removeClass("hide");
        var username = $($this).attr("user-name");
        if (usertype == "users") {
            $(".rightpermissioncontainer>div.col-xs-12").html("<i class='fa fa-user'></i> " + username);
        } else {
            $(".rightpermissioncontainer>div.col-xs-12").html("<i class='fa fa-users'></i> " + username);
        }
        var that = this;
        $(".permissionfiltercheckbox[data-userid=" + userid + "]").each(function (element) {
            var $this = this;
            var tableName = $($this).attr("data-parent");
            var columnDisplayName = $($this).attr("name").replace(/([ ;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "\\$1");
            //$('.permissioncontainerrightfilter #'+columnDisplayName).remove();
            if ($($this).is(":checked") && $($this).attr("data-unique") != "true") {
                var tableName = $($this).attr("data-parent");
                var columnDisplayName = $($this).attr("data-child");
                var columnName = $($this).parent().text().trim();
                var dataType = $(this).attr("data-type");
                var daterange = $(this).attr("date-range");
                var elelement = '.permissioncontainerrightfilter';
                var filterArray = ChartInfo.functions.getUserFilterArray();
                var UsersFilterArray = [];
                var filterObjColumnName = "";
                var toRange = "";
                var fromRange = "";
                //For getting the previous filter values
                if (that.props.sharedtousers.length != 0 && usertype == "users") {
                    _.each(that.props.sharedtousers, function (i, j) {
                        if (that.props.sharedtousers[j].filterobject != null && that.props.sharedtousers[j].filterobject != "null" && that.props.sharedtousers[j].userid == userid) {
                            _.each(JSON.parse(that.props.sharedtousers[j].filterobject), function (value, k) {
                                if (value.columndisplayname == columnName) {
                                    UsersFilterArray.push(value);
                                    filterObjColumnName = value.columnname;
                                    toRange = value.toRange;
                                    fromRange = value.fromRange;
                                    daterange = value.daterange;
                                }
                            });
                        }
                    });
                }
                if (that.props.sharedtogroups.length != 0 && usertype == "groups") {
                    _.each(that.props.sharedtogroups, function (i, j) {
                        if (that.props.sharedtogroups[j].filterobject != null && that.props.sharedtogroups[j].filterobject != "null" && that.props.sharedtogroups[j].groupid == userid) {
                            _.each(JSON.parse(that.props.sharedtogroups[j].filterobject), function (value, k) {
                                if (value.columndisplayname == columnName) {
                                    UsersFilterArray.push(value);
                                    filterObjColumnName = value.columnname;
                                    toRange = value.toRange;
                                    fromRange = value.fromRange;
                                    daterange = value.daterange;
                                };
                            });
                        }
                    });
                }
                //For getting old the filter values after clicking the update button
                _.each(filterArray[usertype][userid], function (item) {
                    if (item.columndisplayname == columnName) {
                        filterObjColumnName = columnName;
                        UsersFilterArray.push(item);
                        toRange = item.toRange;
                        fromRange = item.fromRange;
                        daterange = item.daterange;
                    }
                });
                var ClassName;
                if (dataType == "date") {
                    ClassName = "advanced-access-daterange-center";
                } else if (dataType == "number") {
                    ClassName = "advanced-access-filterrangevalues";
                } else {
                    ClassName = "advanced-access-filtervalue";
                }
                var existingObj = that.isfilterAlreadyexists(parsedSharedfilters, columnName);
                if (existingObj != undefined && Object.keys(existingObj).length > 0 && filterArray[usertype][userid] != undefined && filterArray[usertype][userid].length < 0) {
                    var tempArray = [];
                    tableName = existingObj.tablename;
                    columnDisplayName = existingObj.columndisplayname;
                    columnName = existingObj.columnname
                    dataType = existingObj.dataType
                    fromRange = existingObj.fromRange
                    toRange = existingObj.toRange
                    UsersFilterArray = tempArray.push(existingObj);
                    daterange = existingObj.daterange
                    var filterItemObj = {};
                    filterItemObj["filterDisplayName"] = columnDisplayName;
                    filterItemObj["filterIgnore"] = false;
                    filterItemObj["tableName"] = tableName;
                    filterItemObj["columnName"] = columnDisplayName;
                    filterItemObj["columnDisplayName"] = columnName;
                    filterItemObj["columnType"] = dataType;
                    filterItemObj["fromrange"] = fromRange;
                    filterItemObj["torange"] = toRange;
                    filterItemObj["filterObject"] = UsersFilterArray;
                    filterItemObj["datamodelId"] = that.props.datamodelId;
                    filterItemObj["entities"] = that.props.entities;
                    filterItemObj["daterange"] = daterange;
                    filterItemObj["parametervaluetype"] = "";
                    filterItemObj["elelement"] = elelement;
                    filterItemObj["className"] = ClassName;
                    filterItemObj["sharedFilters"] = that.state.sharedFilters;
                    filterItemObj["filterid"] = userid;
                    filterItemObj["indexName"] = undefined;
                    filterItemObj["esType"] = undefined;
                    PubSub.publish("addfilteritem", filterItemObj);


                } else if (filterObjColumnName == columnName) {
                    var filterItemObj = {};
                    filterItemObj["filterDisplayName"] = columnDisplayName;
                    filterItemObj["filterIgnore"] = false;
                    filterItemObj["tableName"] = tableName;
                    filterItemObj["columnName"] = columnDisplayName;
                    filterItemObj["columnDisplayName"] = columnName;
                    filterItemObj["columnType"] = dataType;
                    filterItemObj["fromrange"] = fromRange;
                    filterItemObj["torange"] = toRange;
                    filterItemObj["filterObject"] = UsersFilterArray;
                    filterItemObj["datamodelId"] = that.props.datamodelId;
                    filterItemObj["entities"] = that.props.entities;
                    filterItemObj["daterange"] = daterange;
                    filterItemObj["parametervaluetype"] = "";
                    filterItemObj["elelement"] = elelement;
                    filterItemObj["className"] = ClassName;
                    filterItemObj["sharedFilters"] = that.state.sharedFilters;
                    filterItemObj["filterid"] = userid;
                    filterItemObj["indexName"] = undefined;
                    filterItemObj["esType"] = undefined;
                    PubSub.publish("addfilteritem", filterItemObj);

                } else {
                    var filterItemObj = {};
                    filterItemObj["filterDisplayName"] = columnDisplayName;
                    filterItemObj["filterIgnore"] = false;
                    filterItemObj["tableName"] = tableName;
                    filterItemObj["columnName"] = columnDisplayName;
                    filterItemObj["columnDisplayName"] = columnName;
                    filterItemObj["columnType"] = dataType;
                    filterItemObj["fromrange"] = null;
                    filterItemObj["torange"] = null;
                    filterItemObj["filterObject"] = that.props.dimensions;
                    filterItemObj["datamodelId"] = that.props.datamodelId;
                    filterItemObj["entities"] = that.props.entities;
                    filterItemObj["daterange"] = daterange;
                    filterItemObj["parametervaluetype"] = "";
                    filterItemObj["elelement"] = elelement;
                    filterItemObj["className"] = ClassName;
                    filterItemObj["sharedFilters"] = that.state.sharedFilters;
                    filterItemObj["filterid"] = userid;
                    filterItemObj["indexName"] = undefined;
                    filterItemObj["esType"] = undefined;
                    PubSub.publish("addfilteritem", filterItemObj);
                }
            }
        });
    }
}