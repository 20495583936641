import React from 'react';
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;
export default class WorkspaceItemSettingsHtml extends React.Component {
    componentDidMount(){
        if(this.props.editworkspaceitem){
            this.props.loadFiltersData();
        }else{
            this.props.loadCustomQuery();
        }
    }
    render() {
        return (
            <div role="tabpanel" className="workspaceitemsettingstabscontainer">
                <div className="entityname">
                    {
                        (this.props.isCustomQuery === true) ? (<i className="fa fa-code"></i>) : (<i className="fa fa-table"></i>)
                    }
                    {
                        (this.props.isCustomQuery === true && this.props.entityName === '') ? (<>{lang["workspace.customquery"]}</>) : (<>{this.props.entityName}</>)
                    }
                    <span className="entityconnectionsource">{this.props.entitySourceName}</span>
                    <button type="button" className="btn btn-transparent pull-right closesettingscontainer" onClick={(e)=>{this.props.closesettingscontainer(e)}}><i className="fa fa-times"></i></button>
                </div>
                <ul className="nav nav-tabs tabcontainer" role="tablist">
                    {
                        (this.props.isCustomQuery === true) ? (
                            <li role="presentation" className="tabheadli active">
                                <a href="#workspacecustomquerytabview" id="workspacecustomquerytab" aria-controls="workspacecustomquerytabview" role="tab" data-toggle="tab" onClick={(e)=>{this.props.loadCustomQuery(e)}}><i className="fa fa-code"></i> {lang["workspace.customquery"]}</a>
                            </li>
                        ) : null
                    }
                    <li role="presentation" className={`tabheadli ${this.props.isCustomQuery == true ? '' : 'active'}`}>
                        <a href="#workspacefilterstabview" id="workspacefilterstab" aria-controls="workspacefilterstabview" role="tab" data-toggle="tab" onClick={(e)=>{this.props.loadFiltersData(e)}}><i className="fa fa-filter"></i> {lang["workspace.filters"]}</a>
                        <button className="btn btn-bird btn-sm addworkspacefilter" id="" data_parent={this.props.connId} entity_id={this.props.entityId} name={this.props.entityName} onClick={(e)=>{this.props.addworkspacefilter(e)}}><i className="fa fa-plus-circle"></i> {lang["workspace.addfilter"]}</button>
                    </li>
                    <li role="presentation" className="tabheadli">
                        <a href="#workspacecustomfieldstabview" id="workspacecustomfieldstab" aria-controls="workspacecustomfieldstabview" name={this.props.entityName} role="tab" data-toggle="tab" className="recommendtab" onClick={(e)=>{this.props.loadcustomfields(e)}}><i className="fa fa-code"></i> {lang["workspace.customfield"]}</a>
                        <button className="btn btn-bird btn-sm createworkspacecustomfield" id="" data_parent={this.props.connId} entity_id={this.props.entityId} name={this.props.entityName} onClick={(e)=>{this.props.createworkspacecustomfield(e)}}><i className="fa fa-plus-circle"></i> {lang["workspace.createcustom"]}</button>
                    </li>
                    <li role="presentation" className="tabheadli">
                        <a href="#workspacepreviewdatatabview" id="workspacepreviewdatatab" aria-controls="workspacepreviewdatatabview" role="tab" data-toggle="tab" className="recommendedvisualviewtab" onClick={(e)=>{$(".spinner").show();this.props.loadpreviewdata(e)}}><i className="fa fa-table"></i>{lang["workspace.previewdata"]}</a>
                    </li>
                    <li role="presentation" className="tabheadli hide">
                        <a href="#workspacecurrencytabview" id="workspacecurrencytab" aria-controls="workspacecurrencytabview" role="tab" data-toggle="tab" className="recommendedvisualviewtab" onClick={(e)=>{$(".spinner").show();this.props.loadCurrency(e)}}><i className="fa fa-dollar"></i>{`Currency`}</a>
                    </li>
                </ul>
                <div className="tab-content">
                    <div role="tabpanel" className={`tab-pane ${this.props.isCustomQuery === true ? 'active' : ''} tabviewitems`} id="workspacecustomquerytabview">
                        <div id="workspaceitemsettingscustomquerycontainer">
                            <div id="customqueryaccordion">
                                {/* <!-- Append CustomQuery Here--> */}
                            </div>
                        </div>
                    </div>
                    <div role="tabpanel" className={`tab-pane tabviewitems ${this.props.isCustomQuery === true ? '' : 'active'}`} id="workspacefilterstabview">
                        <div className="modal-body">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div id="workspaceitemsettingsfiltercontainer" className="marg-top-10">
                                            <div className="panel-group rightfilter" id="filteraccordion">
                                                {/* <!-- Append Filters Here--> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-bird pull-right applyworkspacefilters marg-righ-10 disabled" id="" data_parent={this.props.connId} entity_id={this.props.entityId} name={this.props.entityName} onClick={(e)=>{this.props.applyworkspacefilter(e)}}><i className="fa fa-check"></i> {lang["workspace.applyfilter"]}</button>
                        </div>
                    </div>
                    <div role="tabpanel" className="tab-pane tabviewitems" id="workspacecustomfieldstabview">
                        <div className="modal-body">
                            <div className="container-fluid height-100">
                                <div className="row height-100">
                                    <div className="col-xs-12 height-100">
                                        <div id="workspaceitemsettingsCustomFieldscontainer" className="height-100">
                                            <div className="panel-group rightfilter" id="customfieldscontainer">
                                                {/* <!-- Append CustomFieds Here--> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer"></div>
                    </div>
                    <div role="tabpanel" className="tab-pane tabviewitems" id="workspacepreviewdatatabview">
                        <div className="container-fluid marg-top-20" style={{ height: "calc(100% - 20px)" }}>
                            <div className="row" style={{height: "100%"}}>
                                <div className="col-xs-12 width-100" id="previewdata" style={{ height: "calc(100% - 10px)" }}></div>
                            </div>
                        </div>
                    </div>
                    <div role="tabpanel" className="tab-pane tabviewitems" id="workspacecurrencytabview">
                        <div className="modal-body">
                            <div className="container-fluid height-100">
                                <div className="row height-100">
                                    <div className="col-xs-12 height-100">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 axissetting settingscontent" id="axissetting">
                                        <div className="datevals col-xs-3">
                                                <legend className="subhead marg-top-10">
                                                    <div className="attraxisnames">{lang["mlreports.datecolums"]}</div>
                                                    <button className="pull-right btn btn-transparent dropdown-toggle tooltip-left" data-tooltip={lang["mlreports.add"]} id="dropdowndatecols" aria-expanded="false" data-toggle="dropdown" onClick={this.props.showDateColumnsDropDown}>
                                                        <i className="fa fa-plus-circle"></i>
                                                    </button>
                                                    <ul className="list-group dropdown-menu dropdown-menu-right datecolsdropdown" aria-labelledby="dropdowndatecols" role="menu">
                                                    </ul>
                                                </legend>
                                                <div className="marg-top-10">
                                                    <ul className="list-group datecolumns"></ul>
                                                </div>
                                        </div>
                                        <div className="basecurrency col-xs-3">
                                                <legend className="subhead marg-top-10">
                                                    <div className="attraxisnames">{`Base Currency`}</div>
                                                    <button className="pull-right btn btn-transparent dropdown-toggle tooltip-left" data-tooltip={lang["mlreports.add"]} id="dropdownbasecurrencycols" aria-expanded="false" data-toggle="dropdown" onClick={this.props.showBaseCurrencyDropdown}>
                                                        <i className="fa fa-plus-circle"></i>
                                                    </button>
                                                    <ul className="list-group dropdown-menu dropdown-menu-right basecurrencycolsdropdown" aria-labelledby="dropdownbasecurrencycols" role="menu">
                                                    </ul>
                                                </legend>
                                                <div className="marg-top-10">
                                                    <ul className="list-group basecurrencycolumns"></ul>
                                                </div>
                                        </div>
                                        <div className="currencycol col-xs-3">
                                                <legend className="subhead marg-top-10">
                                                    <div className="attraxisnames">{`Currency Column`}</div>
                                                    <button className="pull-right btn btn-transparent dropdown-toggle tooltip-left" data-tooltip={lang["mlreports.add"]} id="dropdowncurrencycols" aria-expanded="false" data-toggle="dropdown" onClick={this.props.showNumericColsDropdown}>
                                                        <i className="fa fa-plus-circle"></i>
                                                    </button>
                                                    <ul className="list-group dropdown-menu dropdown-menu-right currencycolsdropdown" aria-labelledby="currencycols" role="menu">
                                                    </ul>
                                                </legend>
                                                <div className="marg-top-10">
                                                    <ul className="list-group currencycolumns"></ul>
                                                </div>
                                        </div>
                                        <div className="outputcurrencycol col-xs-3">
                                                <legend className="subhead marg-top-10">
                                                    <div className="attraxisnames">{`Output Currency`}</div>
                                                    <button className="pull-right btn btn-transparent dropdown-toggle tooltip-left" data-tooltip={lang["mlreports.add"]} id="dropdownoutputcurrencycols" aria-expanded="false" data-toggle="dropdown" onClick={this.props.showOutputCurrencyDropDown}>
                                                        <i className="fa fa-plus-circle"></i>
                                                    </button>
                                                    <ul className="list-group dropdown-menu dropdown-menu-right outputcurrencycolsdropdown" aria-labelledby="outputcurrencycols" role="menu">
                                                    </ul>
                                                </legend>
                                                <div className="marg-top-10">
                                                    <ul className="list-group outputcurrencycolumns"></ul>
                                                </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-bird pull-right applyworkspacecurrency marg-righ-10 disabled" id="" data_parent={this.props.connId} entity_id={this.props.entityId} name={this.props.entityName} onClick={(e)=>{this.props.applycurrency(e)}}><i className="fa fa-check"></i> {`Apply`}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}