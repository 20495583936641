import React from "react";
import { lang } from "../js/helpers/utils/languages/language";
export default class ShareReportmodalTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        var that = this.props;
        return (<>
            <div className="modal fade in" id="sharestoryboard" tabIndex="-1" role="dialog" aria-labelledby="sharestoryboard" aria-hidden="true" style={{ display: "block" }}>
                {/* <!-- sharereportmodal.js --> */}
                <div className="modal-backdrop fade in"></div>
                <div className="modal-dialog modal-sm" style={{ zIndex: "1050", marginTop:"auto" }}>
                    <div className="modal-content">
                        <div className="modal-header boc-modal-hf-padding">
                            <h4 className="modal-title"><i className="fa fa-users"></i> {lang["storyboard.sharereport"]}
                                                               <button type="button" className="close white" id="sharereportclose" onClick={that.closesharemodal} aria-hidden="true" data-dismiss="modal"><i className="fa fa-times"></i></button>
                            </h4>
                        </div>
                        <div className="modal-body">
                            <div className="container-fluid">
                                <div className="row filtercontent">
                                    <span className="required right legend"></span>
                                    <legend className="subhead">{lang["storyboard.users"]}</legend>
                                    <div className="col-xs-12 nopadding">
                                        <div className="validationgroup">
                                            <select name="grouping" data-placeholder={lang["storyboard.slctuser"]} className="group-select modal-form-fields" id="groupings" dat multiple tabIndex="6">
                                                <optgroup className="groupids" label={lang["storyboard.groups"]}>
                                                    {that.groups.map((group, index) => {
                                                        return <option key={index} value={group.groupId}>{group.groupName}</option>
                                                    })}
                                                </optgroup>
                                                <optgroup className="userids" label={lang["storyboard.users"]}>
                                                    {that.users.map((user, index) => {
                                                        if (!user.isLoginUser) {
                                                            return <option key={index} value={user.userid}>{`${user.username} (${user.email})`}</option>
                                                        }
                                                    })}
                                                </optgroup>
                                                {/* <!-- </optgroup> --> */}
                                            </select>
                                            <span className="help-block hidden fa-modal-alert"></span>
                                            <span class="help-block hide sharereportselectuserserror fa-modal-alert text-center">{lang["storyboard.slctusers"]}</span>
                                            <button className="btn btn-link pull-right btn-sm showadvanced" onClick={that.showadvanced}>{lang["storyboard.advanced"]}</button>
                										<div className="row marg-bot-10 filtersearching " style={{display:"none"}}>
                            								   <div className="stringfilters searching ">
                                                               {lang["storyboard.startswith"]} <input type="text" id="searchnamestartswith" data-id="searchnamestartswith" className="searchword" />
                            									</div>
                            									<div className="stringfilters searching">
                                                                {lang["storyboard.endswith"]} <input type="text" id="searchnameendswith" data-id="searchnameendswith"/>
                            									</div>
                            									<div className="stringfilters searching col-lg-10 col-md-10 col-sm-12 col-xs-12" style={{marginLeft:"-5px"}}>
                                                                {lang["storyboard.contains"]} <input type="text" id="searchnamecontains" data-id="searchnamecontains"/>
                            									</div>
                            								  <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12" style={{marginTop: "1px"}}>
                            									<button type="button" id="searchnames" className="btn btn-sm btn-bird marg-top-20 btn-highlight" onClick={that.searchusersgroups}>{lang["storyboard.go"]}</button>
                            								  </div>
						            </div>
                                        </div>
                                    </div>
                                    <div className="clear"></div>
                                    <legend className="subhead">{lang["storyboard.comment"]}</legend>
                                    <div className="validationgroup">
                                        <div className="col-xs-12 nopadding"><textarea id="sharecomments" placeholder={lang["storyboard.placeholdcomment"]} name="comments" className="modal-form-fields resize-vertical"></textarea></div>
                                        <span className="help-block hidden"></span>
                                    </div>
                                    <div className="col-xs-12 nopadding">
                                        <button className="btn btn-link pull-right btn-sm showadvancedsharereport" onClick={that.ShowAdvancedShareReport} id="advancedsharereports"><i className="fa fa-lock marg-righ-5"></i>{lang["storyboard.advancedpermison"]}</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer boc-modal-hf-padding">
                            <button type="button" className="btn btn-bird" id="btn-birdsharecancel" onClick={that.closesharemodal} data-dismiss="modal" aria-hidden="true"><i className="fa fa-times"></i> {lang["storyboard.cancelbtn"]}</button>
                            <button type="button" className="btn btn-bird sharereport" onClick={that.sharereportapply} id="sharereportapply" aria-hidden="true"><i className="fa fa-check"></i> {lang["storyboard.applybtn"]}</button>
                        </div>
                    </div>
                </div>
            </div >
            <div id="advancedaccesspermissonscontainer"></div>
        </>);
    }
}