import React from "react";
import ReactDOM from 'react-dom';
import SendRequest from "../../SendRequest";
import AddAccountTemplate from './AddAccountTemplate';
import { getMessage } from '../../js/helpers/utils/BirdMessage';
const $ = window.$;

export default class AddAccount extends React.Component{
    constructor(props){
        super(props);
        this.render();
    }
    isValid=(data)=>{
        var isValid = true;
        if($.trim($.trim(data.accountname))==""){
            $(".accountnameerror").removeClass('hidden');
            $(".accountnameerror").text("Account Name is required");
            isValid = false;
        }
        if($.trim($.trim(data.adminname))==""){
            $(".adminnameerror").removeClass('hidden');
            $(".adminnameerror").text("Admin Name is required");
            isValid = false;
        }
        if($.trim($.trim(data.Email))==""){
            $(".emailerror").removeClass('hidden');
            $(".emailerror").text("Email is required");
            isValid = false;
        }
        return isValid;
    }
    saveAccount=(e)=>{
        var serialized = $('form').serializeArray();
			var s = '';
			var data = {};
            $("#spinner").show();
            $(".spinner").show();
			for (s in serialized) {
				data[serialized[s]['name']] = serialized[s]['value']
			}
			var result = JSON.stringify(data);
            var finaldata = JSON.parse(result);
            var isValid = this.isValid(finaldata);
            if(isValid) {
            let requestHeaders = {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            var path =window.location.origin+"/"+window.localStorage.appName;
            var reqdata = new FormData();
            reqdata.set("clientName", finaldata.accountname);
            reqdata.set("clientDesc", finaldata.accountdesc);
            reqdata.set("userFirstName", finaldata.adminname);
            reqdata.set("userEmail", finaldata.Email);
            reqdata.set("status", finaldata.status); 
            reqdata.set("path", path); 
            SendRequest({
                url: "createClient",
                body: reqdata,
                sucessFunction: (response) => {
                    if(response.status=='success'){
                        $("#spinner").hide();
                        $(".spinner").hide();
                        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Success');
                        $('.birdmessage-info').empty().text(getMessage('BirdSuccess28'));
                            setTimeout(function() {
                        $('.birdmessage-container .close').click();	
                        }, 3000);
                        setTimeout(function() {
                          window.location.reload(false);
                        },2000);
                    }else if(response.hasOwnProperty('error')){
                            $(".spinner").hide();
                            $("#spinner").hide();
                            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                            $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                            $('.birdmessagebuttons').removeClass('show');
                            $('.birdmessagebuttons').addClass('hide');
                            $('.birdmessage-center').addClass("message-top-in");
                            $('.details').removeClass('hide');
                            $('.birdmessage h2').empty().append('Error : ');
                            $('.birdmessage h2').append('<span class="errorcode">');
                            $('.birdmessage .errorcode').empty().append(response.errorCode);
                            $('.birdmessage-info').empty().text(response.error);
                            $('.details').addClass('show');
                            $('.messagedetails xmp').empty().append(response.errorTrace)
                                setTimeout(function() {
                            $('.birdmessage-container .close').click();	
                            }, 3000);
                    }
                    else{
                        $(".spinner").hide();
                        $("#spinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-warning');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.status);
                        $('.details').addClass('hide');
                    }
                  },
                  rejectFunction: () => { },
                  headers: requestHeaders
                })
            }

    }
    render(){
    ReactDOM.render(<AddAccountTemplate  saveAccount={this.saveAccount} />,document.querySelector(".adminaccountform"));
    return (<div></div>);
    }
}