import React from 'react';
import { lang} from "../../../js/helpers/utils/languages/language";
export default class NewMailConfigTemplate extends React.Component{
    constructor(props){
		super(props);
	}	   

    render(){
        return(
            <div className="row in-back">
	<div id="example-form"  className="max-height-30vh">
		<div>
			<h2 className="boc-main-title">{lang["admin.mail.configtitle"]}</h2>
			<section>
				<form className="form-horizontal col-xs-12 newmailconfigform" >
					<div className="form-group form-group-sm">
						<label for="smtppurl" className="col-sm-3 control-label important">{lang["admin.mail.smtpurl"]}</label>
						<div className="col-sm-6">
							<input type="text" className="form-control" id="smtppurl" name="smtpurl"
								placeholder={lang["admin.mail.pholdname"]} value="" />
								<span className="help-block smtpurlerror hidden"></span>
						</div>
					</div>
					<div className="form-group form-group-sm">
						<label for="portnumber" className="col-sm-3 control-label important">{lang["admin.mail.portno"]}</label>
						<div className="col-sm-3">
							<input type="name" className="form-control" id="portnumber" name="portnumber"
								placeholder={lang["admin.mail.pholdnum"]} value="" />
								<span className="help-block portnumbererror hidden"></span>
						</div>
					</div>
					<div className="form-group form-group-sm">
						<label for="mailconfigemail" className="col-sm-3 control-label important">{lang["admin.mail.email"]}</label>
						<div className="col-sm-6">
							<input type="email" className="form-control" id="mailconfigemail" name="email"
								placeholder={lang["admin.mail.pholdemail"]} value="" />
								<span className="help-block emailerror hidden"></span>
								<span className="help-message"> {lang["admin.mail.emailmsg"]}
					</span>
						</div>
					</div>
				
					<div className="form-group form-group-sm">
						<label for="mailconfigpassword" className="col-sm-3 control-label important">{lang["admin.mail.pwd"]}</label>
						<div className="col-sm-6">
							<input type="password" className="form-control" name="password"
								id="mailconfigpassword" placeholder={lang["admin.mail.pholdpwd"]} value="" />
								<span className="help-block passworderror hidden"></span>
						</div>
					</div>
					<div className="form-group form-group-sm">
						<label for="enableldap" className="col-sm-3 control-label">{lang["admin.mail.auth"]}
							</label>
						<div className="col-sm-6">
							<div>
								<input type="radio" id="1" name="mailauthenticate" value="true" checked="checked"/>
								{lang["admin.mail.authtrue"]}&nbsp;&nbsp;&nbsp; <input type="radio" id="2"
									name="mailauthenticate" value="false"  /> {lang["admin.mail.authfalse"]}
							</div>
						</div>
					</div>
					<div className="form-group form-group-sm">
						<label for="mailconnectiontype" className="col-sm-3 control-label">{lang["admin.mail.connect"]}
							</label>
						<div className="col-sm-6">
							<div>
								<input type="radio" id="1" name="mailconnectiontype" value="TLS" checked="checked"/>
								{lang["admin.mail.connectTLS"]}&nbsp;&nbsp;&nbsp; <input type="radio" id="2"
									name="mailconnectiontype" value="SSL"  />{lang["admin.mail.connectSSL"]}
							</div>
						</div>
					</div>
				</form>
			</section>
			<h2> {lang["admin.mail.otp"]}</h2>
			<section>
				<form className="form-horizontal col-xs-12">
					<div className="form-group form-group-sm">
					    <div className="col-xs-12 text-center marg-bot-10">
					       <span className="header-message"></span>
					    </div>
						<label for="otp" className="col-sm-3 control-label important" id="otps"> {lang["admin.mail.otp"]}</label>
						<div className="col-sm-6">
							<input type="text" className="form-control" id="mailconfig-otp" name="otp" placeholder={lang["admin.mail.pholdotp"]} />
						</div>
					</div>
				</form>
			</section>
		</div>
	</div>
</div>
				
        );
    }
}