import React from 'react';
import { lang } from "../js/helpers/utils/languages/language";
export default class DatamodelCustomqueryHtml extends React.Component {
    render() {
        return (
            <div className="datamodelcustomquery-container">
                <div className="modal-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xs-12">
                                <form className="form-horizontal marg-top-10 form-inline">
                                    <div className="form-group form-group-sm required col-xs-6 nopadding" style={{float: "none"}}>
                                        <span class="required right legend"></span>
										<label class="subhead marg-righ-10" for="customqueryname">{lang["models.plcholdrcustom"]} </label>
                                            <input type="text"  style={{display:"inline-block",width:"80%"}} defaultValue={this.props.dmcustomqueryName != undefined ? this.props.dmcustomqueryName : ''} className={`form-control dmcustomqueryname ${this.props.type == 'existing' ? 'disabled' : ''}`} placeholder={lang["models.plcholdrcustom"]}/>
                                            <span className="cscqnamevalidate" style={{ display: "none", color: "red" }}>{lang["models.cscqnamevalidate"]}</span>
                                            <span className="cscqnamespecailcharacter" style={{ display: "none", color: "red" }}>{lang["models.cscqspecalchara"]}</span>
                                            <span className="cscqnameexists" style={{ display: "none", color: "red" }}>{lang["models.cscqnameexists"]}</span>
                                        
                                    </div>
                                </form>
                                <form className="form-horizontal marg-top-10">    
                                    <div className="form-group form-group-sm">
                                        <div className="col-xs-12">
                                            <div id="advancedcustomquery" className={`querydisplay clear scrollset dmadvancedcustomquery`} contentEditable="true" style={{ minHeight: "calc(50vh - 135px)" }}>{this.props.dmcustomquery != undefined ? this.props.dmcustomquery : ''}</div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    (this.props.type == 'existing') ? (
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-bird pull-left" id="validatedatamodelcustomquery" workspaceentityid={this.props.workspaceentityid} onClick={(e) => { this.props.validatedatamodelcustomquery(e) }}><i className="fa fa-play"></i>{lang["models.validatebtn"]}</button>
                            <button class="btn btn-bird copycustomquery marg-left-10" id="copycustomquery" data-clipboard-text="<%=dmcustomquery!=undefined?dmcustomquery:''%>">
					     <i class="fa fa-clipboard" id="clipboards"></i>&nbsp;&nbsp;{lang["models.clipboard"]}
					        </button>
                            <button type="button" className="btn btn-bird active" id="savescustomquery" workspaceentityid={this.props.workspaceentityid} onClick={(e) => { this.props.savedatamodelcustomquery(e) }}><i className="fa fa-floppy-o"></i> {lang["models.savebtn"]}</button>
                        </div>
                    ) : (
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-bird pull-left" id="validatedatamodelcustomquery" workspaceentityid={this.props.workspaceentityid} onClick={(e) => { this.props.validatedatamodelcustomquery(e) }}><i className="fa fa-play"></i> {lang["models.validatebtn"]}</button>
                                <button class="btn btn-bird copycustomquery marg-left-10" id="copycustomquery" data-clipboard-text="<%=dmcustomquery!=undefined?dmcustomquery:''%>">
					     <i class="fa fa-clipboard" id="clipboards"></i>&nbsp;&nbsp;{lang["models.clipboard"]}
					            </button>
                                <button type="button" className="btn btn-bird active" id="savescustomquery" workspaceentityid={this.props.workspaceentityid} onClick={(e) => { this.props.savedatamodelcustomquery(e) }}><i className="fa fa-floppy-o"></i> {lang["models.savebtn"]}</button>
                            </div>
                        )
                }
            </div>
        );
    }
}