import React from 'react';
import { lang } from "../js/helpers/utils/languages/language";
export default class WorkspaceHtml extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        this.state.features = [];
        let featuresString = sessionStorage.getItem("features");
		if (featuresString != null && featuresString != undefined && featuresString != "") {
			featuresString = featuresString.slice(0, 3) + featuresString.slice(4);
			let decryptedString = window.atob(featuresString);
			this.state.features = JSON.parse(decryptedString);
		}
        return (
            <React.Fragment>
                {/* <!-- workspaceview.js --> */}
                <section className="datamain-section-container">
                    <div className="boc-home">
                        <div className="datamain-fixed-head-container">
                            <span className="subhead">
                                <i className="fa fa-cubes marg-righ-10 fa-solid"></i>
                                <span>{lang["workspace.business"]}</span>
                            </span>
                            {(this.props.workspaceId !== undefined && this.props.workspaceId != 0 && !this.props.wsmetadata.isWsLiveEnabled) ? (
                                this.state.features.map((feature, index) => {
                                    if (feature.moduleName === "data_hub") {
                                        return feature.features.map((feature, index) => {
                                            if (feature.featureName === "sync_log_book" && feature.allow === true) {
                                                return (
                                                    <button className="btn pull-right data-main-button" onClick={this.props.workspacesynclogs}><i className="fa fa-file-archive-o"></i> {lang["workspace.synopsis"]}</button>
                                                )
                                            }
                                        })
                                    }
                                 })
                                
                            ):<React.Fragment/>}
                         </div>
                        <div className="datamain-fixed-content-container">
                            <div id="workspacemaincontainer">
                                <div id="workspacestepscontainer">
                                    <div className="wizard clearfix">
                                        <div className="col-xs-12 pull-right nopadding steps clearfix">
                                            <ul role="tablist">
                                                <li role="tab" className="first current" aria-disabled="false" aria-selected="true">
                                                    <a id="datamodelsstepscontainer-t-0" aria-controls="datamodelsstepscontainer-p-0">
                                                        <span className="current-info audible">{lang["workspace.curntstp"]} </span>
                                                        <span className="number">1.</span> {lang["workspace.entities"]}
                                                    </a>
                                                </li>
                                            </ul>
                                            <button className="btn btn-transparent pull-right active white" id="saveworkspace" onClick={(e)=>{this.props.openSaveWorkspaceModal(e)}}>{lang["workspace.savespace"]}</button>
                                            {(this.props.workspaceId !== undefined && this.props.workspaceId != 0) ? (
                                                <button className="btn btn-transparent pull-right active white" workspaceId={this.props.workspaceId} id="wsforcesync" onClick={(e)=>{this.props.wsforcesync(e);}}>{lang["workspace.forcesync"]}</button>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div id="workspacesplitcontainer"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {(this.props.displayFooter !== false) ? (
                    <footer className="footer">
                        <div className="container-fluid"></div>
                    </footer>
                ) : null}
                <div id="saveworkspacemodal"></div>
                {/* <!-- /workspaceview.js --> */}
            </React.Fragment>
        );
    }
}