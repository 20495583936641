import React from "react"
import ReactDOM from 'react-dom'
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import UserCustomScriptModal from './UserCustomscriptModal.jsx'
const $ = window.$;
export default class UserCustomScript extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.state.reportId = this.props.reportId;
        this.state.schemaId = this.props.schemaId;
        this.state.worker = this.props.worker;
        this.state.features = this.props.features;
        this.render();
    }
    render() {
        ReactDOM.render(<UserCustomScriptModal applyusercustomscript={this.applyusercustomscript} close={this.close} chartnumber={this.props.chartnumber} />, document.querySelector(".usercustomscriptmodaldisplay"));
        var encodeText = ChartInfo.functions.getUserCustomScript(this.state.reportId);
        if (encodeText != undefined && encodeText != "") {
            $("#usercustomscriptdata").text(decodeURI(encodeText));
        }
        return (<div></div>);
    }
    close = () => {
        ReactDOM.unmountComponentAtNode(document.querySelector(".usercustomscriptmodaldisplay"));
    }
    applyusercustomscript = () => {
        sessionStorage.setItem("changesdone", 1);
        var encodeText = encodeURI($("#usercustomscriptdata").text());
        ChartInfo.functions.setUserCustomScript(this.state.reportId, encodeText);
        if ($(".singlereportview.fullscreenmain").length == 0) {
            $("#refreshchartsicon").click();
        } else {
            $("#refreshsinglechartsview").click();
        }
        /*setTimeout(function(){
            eval(decodeURI(encodeText));
            $(".doby-grid-viewport").on("scroll",function(){
                eval(decodeURI(encodeText));
            })
        },5000)*/

        function executeUserCustomCode() {
            var emptycharts = $(".maxanim10:empty").length;
            if (emptycharts == 0) {
                setTimeout(function () {
                    eval(decodeURI(encodeText));
                    // try {
                    //     Function(decodeURI(encodeText))();
                    // } catch (e) {
                    //     console.log(e.message);
                    // }
                    $(".doby-grid-viewport").on("scroll", function () {
                        eval(decodeURI(encodeText));
                        // try {
                        //     Function(decodeURI(encodeText))();
                        // } catch (e) {
                        //     console.log(e.message);
                        // }
                    });
                }, 1000);
            } else {
                setTimeout(function () {
                    executeUserCustomCode();
                }, 2000);
            }
        }
        setTimeout(function () { executeUserCustomCode(); }, 2000);
        this.close();
    }

}