import React from "react";
import _ from 'underscore';
import ReactDOM from 'react-dom';
import SendRequest from '../../SendRequest';
import { lang} from "../../js/helpers/utils/languages/language";
const $ =window.$;

export default class NewRolesViewTemplate extends React.Component{
  constructor(props){
super(props);
this.state={};
this.render();
  }

  render(){

    return(<div>
        <div id="role-accordion">
        
    {this.props.allfeatures.map((module)=>{ 
      return  <>
      <h3>
      <legend class="subhead" id="rolesubhead">
  {
    (module.moduleDisplayName=="Create Report") ? (	<i class="fa fa-plus marg-righ-5"></i>  ) : (
    (module.moduleDisplayName=="Story Board") ? ( <i class="fa fa-th-large marg-righ-5"></i> ) : (
    (module.moduleDisplayName=="My Report") ? ( <i class="fa fa-bar-chart marg-righ-5"></i> ) :(
    (module.moduleDisplayName=="Administration") ? ( <i class="fa fa-user-secret marg-righ-5"></i> ) : (
    (module.moduleDisplayName=="Data Hub") ? (<i class="fa fa-database fa-solid marg-righ-5"></i>) : (
    (module.moduleDisplayName=="Business Workspace")?(<i class="fa fa-cubes fa-solid marg-righ-5"></i>):(
    (module.moduleDisplayName=="Business Model")?(<i class="fa fa-cube fa-solid marg-righ-5"></i>):
    (module.moduleDisplayName=="Note Book")?(<i class="fa fa-file fa-solid fa-fw sidepin marg-righ-5"></i>):(null))))
    )
    ))
  }
  {module.moduleDisplayName}
  </legend>
      </h3>
      <div>
      <table class="table table-hover">
        <thead>
          <tr>
            <th>{lang["admin.roles.features"]}</th>
            <th>{lang["admin.roles.Allow"]}</th>
            <th>{lang["admin.roles.Deny"]}</th>
          </tr>
        </thead>
        <tbody>
          {module.features.map((feature)=>{
            {module.features.sort(function (c, b) {
              var x = c.featureDisplayName;
              var y = b.featureDisplayName;
              return x < y ? -1 : x > y ? 1 : 0;
          })}
          return <tr>
              <td>{feature.featureDisplayName}</td>
              {(feature.allow==true)? (<>
                <td><input type="radio" id="1" name={feature.featureName} value={module.moduleName + ',' +module.moduleDisplayName + ',' + feature.featureDisplayName + ',' + feature.parentFeatureId + ',' + true} defaultChecked /></td>
                <td><input type="radio" id="2" name={feature.featureName} value={module.moduleName + ','+ module.moduleDisplayName +','+feature.featureDisplayName + ',' +feature.parentFeatureId + ',' +false} /></td>
              </>) : (<>
                <td><input type="radio" id="1" name={feature.featureName} value={module.moduleName+','+module.moduleDisplayName+','+feature.featureDisplayName+','+feature.parentFeatureId+','+ true} /></td>
                <td><input type="radio" id="2" name={feature.featureName} value={module.moduleName+','+module.moduleDisplayName+','+feature.featureDisplayName+','+feature.parentFeatureId+','+ false} defaultChecked/>
                </td>
                </>)
              }
              
            </tr>
          })
          }
  
        </tbody>
      </table>
    </div>
		</>
    })}
  
    </div>
    </div>
    )
  }

}