import React from "react";
import PubSub from 'pubsub-js'
import $ from 'jquery/dist/jquery';
import { getMessage } from '../js/helpers/utils/BirdMessage';
import { lang } from "../js/helpers/utils/languages/language";

export default class AttrAxisContentModalTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.dropdownyaxis = this.dropdownyaxis.bind(this);
        this.dropdownxaxis = this.dropdownxaxis.bind(this);
        this.dropdownzaxis = this.dropdownzaxis.bind(this);
        this.legenddropdown = this.legenddropdown.bind(this);
        this.dropdowntooltip = this.dropdowntooltip.bind(this);
        this.dropdownreferenceline = this.dropdownreferenceline.bind(this);
        this.dropdownmetricdatefield = this.dropdownmetricdatefield.bind(this);
        this.dropdowncustomsolidgauge = this.dropdowncustomsolidgauge.bind(this);
    }
    render() {
        var that = this.props;
        return (
            // attraxissettingview.js
            <div className="modal-height-fix">
                <div className="zaxisattribute">
                    <div className="modalaxiscontainer zaxis_ct">
                        <legend className="subhead">
                            <div className="attraxisnames">{lang["storyboard.axisz"]}</div>
                            <button
                                className="pull-right btn btn-transparent dropdown-toggle tooltip-left"
                                data-tooltip={lang["storyboard.add"]} id="dropdownzaxis" onClick={this.dropdownzaxis} aria-expanded="false"
                                data-toggle="dropdown">
                                <i className="fa fa-plus-circle"></i>
                            </button>
                            <ul className="list-group dropdown-menu dropdown-menu-right zaxisdropdown" aria-labelledby="dropdownzaxis" role="menu"></ul>
                        </legend>
                        <div className="marg-top-10">
                            <ul className="list-group zaxisattributes"></ul>
                            <ul className="list-group zaxisattributesdummy hide"></ul>
                        </div>
                    </div>
                </div>
                <div className="mainattibutes">
                    <div className="modalaxiscontainer yaxis_ct">
                        <legend className="subhead">
                            <div className="attraxisnames">{lang["storyboard.axisy"]}</div>
                            <button
                                className="pull-right btn btn-transparent dropdown-toggle tooltip-left"
                                data-tooltip={lang["storyboard.add"]} id="dropdownyaxis" onClick={this.dropdownyaxis} aria-expanded="false"
                                data-toggle="dropdown">
                                <i className="fa fa-plus-circle"></i>
                            </button>
                            <ul className="list-group dropdown-menu dropdown-menu-right yaxisdropdown" aria-labelledby="dropdownyaxis" role="menu">

                            </ul>
                        </legend>
                        <div className="marg-top-10">
                            <ul className="list-group yaxisattributes"></ul>
                        </div>
                        {/* <!-- Show Axis in Percent --> */}
                        {/* <!-- <div className="col-xs-12 axispercentcontainer" style="display:none">
                   <div className="axisfeaturesbox">
                       <span>Show Axis in Percentage (%)</span>
                       <span>
                           <div className="checkbox checkbox-slider--b checkbox-slider-sm nomargin pull-right" style="">
                               <label><input type="checkbox" className="axisinpercent" data-num="<%=chartnumber%>"><span>&nbsp;</span></label> 
                           </div>
                       </span>
                   </div>
               </div> --> */}
                        {/* <!--\ Show Axis in Percent --> */}
                    </div>
                    <div className="commonattributes">
                        <div className="modalaxiscontainer xaxis_ct">
                            <legend className="subhead">
                                <div className="attraxisnames">{lang["storyboard.axisx"]}</div>
                                <button
                                    className="pull-right btn btn-transparent dropdown-toggle tooltip-left"
                                    data-tooltip={lang["storyboard.add"]} id="dropdownxaxis" onClick={this.dropdownxaxis} aria-expanded="false"
                                    data-toggle="dropdown">
                                    <i className="fa fa-plus-circle"></i>
                                </button>
                                <ul className="list-group dropdown-menu dropdown-menu-right xaxisdropdown" aria-labelledby="dropdownxaxis" role="menu">

                                </ul>
                            </legend>
                            <div className="marg-top-10">
                                <ul className="list-group xaxisattributes"></ul>
                                <ul className="list-group xaxisattributesdummy hide"></ul>
                            </div>
                        </div>
                        <div className="modalaxiscontainer legend_ct">
                            <legend className="subhead"><div className="attraxisnames">{lang["storyboard.legendseries"]}</div>
                                <button className="pull-right btn btn-transparent tooltip-left dropdown-toggle " data-tooltip={lang["storyboard.add"]} id="dropdownlegend" onClick={this.legenddropdown} data-toggle="dropdown" aria-expanded="false"><i className="fa fa-plus-circle"></i></button>
                                <ul className="list-group dropdown-menu dropdown-menu-right legenddropdown" aria-labelledby="dropdownxaxis" role="menu" >

                                </ul>
                            </legend>
                            <div className="marg-top-10">
                                <ul className="list-group legendAttributes"></ul>
                            </div>
                        </div>
                        <div className="combinationchartslist marg-bot-10">
                            <div className="modalaxiscontainer nonaxis_ct">
                                <legend className="subhead">
                                    <div className="attraxisnames">{lang["storyboard.chartlists"]}</div>
                                </legend>
                                <div className="marg-top-10">
                                    <ul className="list-group combinationchartslistattributes"></ul>
                                </div>
                            </div>
                        </div>
                        <div className="modalaxiscontainer nonaxis_ct">
                            <legend className="subhead attrmodal_tooltip dropup"><div className="attraxisnames">{lang["storyboard.tooltip"]}</div>
                                <button className="pull-right btn btn-transparent tooltip-left dropdown-toggle " data-tooltip={lang["storyboard.add"]} id="dropdowntooltip" onClick={this.dropdowntooltip} data-toggle="dropdown" aria-expanded="false"><i className="fa fa-plus-circle"></i></button>
                                <ul className="list-group dropdown-menu dropdown-menu-right tooltipdropdown" aria-labelledby="dropdowntooltip" role="menu" >

                                </ul>
                            </legend>
                            <div className="marg-top-10 attrmodal_tooltip">
                                <ul className="list-group tooltipattributes"></ul>
                            </div>
                        </div>
                        {(that.isDirect == 0) ? (
                            <div className="modalaxiscontainer nonaxis_ct">
                                <legend className="subhead dropup"><div className="attraxisnames">{lang["storyboard.referenceline"]}</div>
                                    <button className="pull-right btn btn-transparent tooltip-left"
                                        data-tooltip={lang["storyboard.add"]} id="dropdownreferenceline" onClick={this.dropdownreferenceline} aria-expanded="false"
                                        data-toggle="dropdown">
                                        <i className="fa fa-plus-circle"></i>
                                    </button>
                                    <ul className="list-group dropdown-menu dropdown-menu-right referencelinedropdown" aria-labelledby="dropdownreferenceline" role="menu">
                                        <li>{lang["storyboard.customline"]}</li>
                                    </ul>
                                </legend>
                                <div className="marg-top-10">
                                    <ul className="list-group referenceattributes"></ul>
                                    {/* <!-- <ul className="list-group referenceattributes">
                                   <div className="referencelinebox hide">
                                       <legend className="subhead">Label</legend>
                                       <div className="container-fluid marg-top-10 marg-bot-10">
                                           <input type="text" name="referencelabel" id="referencelabel" className="form-control input-sm" placeholder="Reference Line">
                                       </div>
                                       <legend className="subhead">Value</legend>
                                       <div className="container-fluid marg-top-10 marg-bot-10">
                                           <input type="number" name="referencevalue" id="referencevalue" className="form-control input-sm" placeholder="100000">
                                       </div>
                                       <legend className="subhead">Line Style</legend>
                                       <div className="container-fluid marg-top-10 marg-bot-10">
                                           <select className="form-control input-sm" id="referencestyle">
                                               <option value="Solid">Solid</option>
                                               <option value="ShortDot">Dotted</option>
                                               <option value="ShortDash">Dashed</option>
                                               <option value="DashDot">DashDot</option>
                                           </select>
                                       </div>
                                       <legend className="subhead">Line Color</legend>
                                       <div className="container-fluid marg-top-10 marg-bot-10">
                                           <div className="input-group reference-color">
                                               <input type="text" placeholder="#FF0000" value="#FF0000" className="form-control input-sm" id="referencecolor"/>
                                               <span className="input-group-addon" id="referencecolors"><i style="background-color: #FF0000;"></i></span>
                                           </div>
                                       </div>
                                   </div>
                               </ul> --> */}
                                </div>
                            </div>

                        ) : ""}

                    </div>
                </div>
                <div className="metricdatefields hide">
                    <div className="metricprefix hide">
                        <div className="modalaxiscontainer nonaxis_ct">
                            <legend className="subhead"><div className="attraxisnames">{lang["storyboard.layout"]}</div></legend>
                            <div className="metric-layouts">
                                <div className="metric-layout active" onClick={this.metricLayoutChange} layout-id="0" title={lang["storyboard.metriclayout1"]}><span><span> $ 123 </span></span></div>
                                <div className="metric-layout" onClick={this.metricLayoutChange} layout-id="1" title={lang["storyboard.metriclayout2"]}> <span><span>$ 120<br />$ 100<br />% 120 </span></span> </div>
                            </div>
                        </div>
                    </div>
                    <div className="hide metriclayout" layout-id="1">
                        <div className="modalaxiscontainer nonaxis_ct">
                            <legend className="subhead"><div className="attraxisnames">{lang["storyboard.targetvalue"]}</div></legend>
                            <div className="container-fluid marg-top-10 marg-bot-10">
                                <input type="text" name="targetvalue" id="metrictargetvalue" onKeyUp={this.validateMetricTargetValue} className="form-control" />
                            </div>
                        </div>
                    </div>
                    <div className="modalaxiscontainer nonaxis_ct metriclayout" layout-id="0">
                        <legend className="subhead"><div className="attraxisnames">{lang["storyboard.field"]}</div>
                            <button className="pull-right btn btn-transparent tooltip-left dropdown-toggle " data-tooltip={lang["storyboard.add"]} id="dropdownmetricdatefield" onClick={this.dropdownmetricdatefield} data-toggle="dropdown" aria-expanded="false"><i className="fa fa-plus-circle"></i></button>
                            <ul className="list-group dropdown-menu dropdown-menu-right metricdatedropdown" aria-labelledby="dropdownxaxis" role="menu" >

                            </ul>
                        </legend>
                        <div className="marg-top-10">
                            <ul className="list-group metricdatefieldsAttributes"></ul>
                        </div>

                        <div className="metricattributes hide">
                            <legend className="subhead"><div className="attraxisnames">{lang["storyboard.compare"]}</div></legend>
                            <div className="container-fluid metricvalues" data-type="date">
                                <select className="sort-attribute-name metriccoparision">
                                    {(that.metrictype == "Daily") ? (
                                        <> <option>{lang["storyboard.all"]}</option>
                                            <option selected="selected">{lang["storyboard.daily"]}</option>
                                            <option>{lang["storyboard.weekly"]}</option>
                                            <option>{lang["storyboard.monthly"]}</option>
                                            <option>{lang["storyboard.quarterly"]}</option>
                                            <option>{lang["storyboard.halfyearly"]}</option>
                                            <option>{lang["storyboard.yearly"]}</option></>
                                    ) : (that.metrictype == "Weekly") ? (
                                        <> <option>{lang["storyboard.all"]}</option>
                                            <option >{lang["storyboard.daily"]}</option>
                                            <option selected="selected">{lang["storyboard.weekly"]}</option>
                                            <option>{lang["storyboard.monthly"]}</option>
                                            <option>{lang["storyboard.quarterly"]}</option>
                                            <option>{lang["storyboard.halfyearly"]}</option>
                                            <option>{lang["storyboard.yearly"]}</option></>
                                    ) : (that.metrictype == "Monthly") ? (
                                        <>  <option>{lang["storyboard.all"]}</option>
                                            <option >{lang["storyboard.daily"]}</option>
                                            <option>{lang["storyboard.weekly"]}</option>
                                            <option selected="selected">{lang["storyboard.monthly"]}</option>
                                            <option>{lang["storyboard.quarterly"]}</option>
                                            <option>{lang["storyboard.halfyearly"]}</option>
                                            <option>{lang["storyboard.yearly"]}</option></>
                                    ) : (that.metrictype == "Quarterly") ? (
                                        <> <option>{lang["storyboard.all"]}</option>
                                            <option >{lang["storyboard.daily"]}</option>
                                            <option>{lang["storyboard.weekly"]}</option>
                                            <option>{lang["storyboard.monthly"]}</option>
                                            <option selected="selected">{lang["storyboard.quarterly"]}</option>
                                            <option>{lang["storyboard.halfyearly"]}</option>
                                            <option>{lang["storyboard.yearly"]}</option></>
                                    ) : (that.metrictype == "Half-Yearly") ? (
                                        <> <option>{lang["storyboard.all"]}</option>
                                            <option>{lang["storyboard.daily"]}</option>
                                            <option>{lang["storyboard.weekly"]}</option>
                                            <option>{lang["storyboard.monthly"]}</option>
                                            <option>{lang["storyboard.quarterly"]}</option>
                                            <option selected="selected">{lang["storyboard.halfyearly"]}</option>
                                            <option>{lang["storyboard.yearly"]}</option></>
                                    ) : (that.metrictype == "Yearly") ? (
                                        <><option>{lang["storyboard.all"]}</option>
                                            <option>{lang["storyboard.daily"]}</option>
                                            <option>{lang["storyboard.weekly"]}</option>
                                            <option>{lang["storyboard.monthly"]}</option>
                                            <option>{lang["storyboard.quarterly"]}</option>
                                            <option>{lang["storyboard.halfyearly"]}</option>
                                            <option selected="selected">{lang["storyboard.yearly"]}</option></>
                                    ) : (that.metrictype == "All") ? (
                                        <><option>{lang["storyboard.all"]}</option>
                                            <option>{lang["storyboard.daily"]}</option>
                                            <option>{lang["storyboard.weekly"]}</option>
                                            <option>{lang["storyboard.monthly"]}</option>
                                            <option>{lang["storyboard.quarterly"]}</option>
                                            <option>{lang["storyboard.halfyearly"]}</option>
                                            <option>{lang["storyboard.yearly"]}</option></>
                                    ) : (
                                                                    <> <option selected="selected">{lang["storyboard.all"]}</option>
                                                                        <option >{lang["storyboard.daily"]}</option>
                                                                        <option>{lang["storyboard.weekly"]}</option>
                                                                        <option>{lang["storyboard.monthly"]}</option>
                                                                        <option>{lang["storyboard.quarterly"]}</option>
                                                                        <option>{lang["storyboard.halfyearly"]}</option>
                                                                        <option>{lang["storyboard.yearly"]}</option></>
                                                                )}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- <div className="metricprefix hide">
               <div className="modalaxiscontainer nonaxis_ct">
                   <legend className="subhead"><div className="attraxisnames">Metric Prefix</div></legend>
                   <div className="container-fluid marg-top-10 marg-bot-10">
                       <input type="text" name="prifix" id="metricprefix" className="form-control metric-prifix"/>
                   </div>
                   <div className="metricsuffix hide">
                       <legend className="subhead"><div className="attraxisnames">Metric Suffix</div></legend>
                       <div className="container-fluid marg-top-10 marg-bot-10">
                           <input type="text" name="suffix" id="metricsuffix" className="form-control metric-prifix"/>
                       </div>
                   </div>
               </div>
           </div> --> */}
                {/* <!-- <div className="metricsuffix hide">
               <div className="modalaxiscontainer">
               <legend className="subhead">Suffix</legend>
               <div className="container-fluid marg-top-10 marg-bot-10">
                   <input type="text" name="suffix" id="metricsuffix" className="form-control metric-prifix"/>
               </div>
               </div>
           </div> --> */}

                <div className="solidgaugemin hide">
                    <div className="modalaxiscontainer nonaxis_ct">
                        <legend className="subhead"><div className="attraxisnames">{lang["storyboard.mincap"]}</div></legend>
                        <div className="container-fluid marg-top-10 marg-bot-10">
                            <input type="text" name="solidgaugemin" id="solidgaugemin" onChange={this.validateSolidGaugeValues} onKeyUp={this.validateSolidGauge} defaultValue="0" placeholder="0" className="form-control solidgaugemin" /><span style={{ color: "#ff0000" }} id="errmsgsolidgaugemin"></span>
                        </div>
                    </div>
                </div>
                <div className="solidgaugemax hide">
                    <div className="modalaxiscontainer nonaxis_ct">
                        <legend className="subhead"><div className="attraxisnames">{lang["storyboard.maxcap"]}</div></legend>
                        <div className="container-fluid marg-top-10 marg-bot-10">
                            <input type="text" name="solidgaugemax" id="solidgaugemax" onChange={this.validateSolidGaugeValues} onKeyUp={this.validateSolidGauge} className="form-control solidgaugemax" placeholder="Custom Value" /><span style={{ color: "#ff0000" }} id="errmsgsolidgaugemax"></span>
                        </div>
                        {/* <!-- Max Dynamic value for gauge charts --> */}
                        <div className="text-center marg-top-10 marg-bot-10">{lang["storyboard.or"]}</div>
                        <legend className="subhead dropup">
                            <div className="attraxisnames">{lang["storyboard.customvalue"]}</div>
                            <button
                                className="pull-right btn btn-transparent dropdown-toggle tooltip-left"
                                data-tooltip={lang["datahub.add"]} id="dropdowncustomsolidgauge" aria-expanded="false" onClick={this.dropdowncustomsolidgauge}
                                data-toggle="dropdown">
                                <i className="fa fa-plus-circle"></i>
                            </button>
                            <ul className="list-group dropdown-menu dropdown-menu-right customsolidgaugedropdown" aria-labelledby="dropdowncustomsolidgauge" role="menu">

                            </ul>
                        </legend>
                        <div className="marg-top-10">
                            <ul className="list-group customsolidgaugeattributes">
                            </ul>
                        </div>
                    </div>

                </div>
                {/* <!-- <div className="modalaxiscontainer nonaxis_ct">
               <legend className="subhead dropup"><div className="attraxisnames">Sort By</div>
                   <button
                       className="pull-right btn btn-transparent dropdown-toggle tooltip-left"
                       data-tooltip="ADD" id="dropdownsorting" aria-expanded="false"
                       data-toggle="dropdown">
                       <i className="fa fa-plus-circle"></i>
                   </button>
                   <ul className="list-group dropdown-menu dropdown-menu-right sortingdropdown" aria-labelledby="dropdownsorting" role="menu"></ul>
               </legend>
               <div className="marg-top-10">
                   <ul className="list-group sortingattributes"></ul>
                   <p className="marg-left-10" style="font-size:12px;padding: 10px 0 0 10px;color: #8f93b3;"><i>Note: Sorting of Data is Disabled when Legend Series is Applied</i></p>
               </div>
           </div>
           <div className="modalaxiscontainer nonaxis_ct">
               <legend className="subhead fa-grey"><div className="attraxisnames">Ranking</div></legend>
               <div className="marg-top-10">
                   <ul className="list-group">
                       <div className="row">
                           <div className="col-xs-12">
                               <select className="sort-attribute-name ordertype" id="ordertyperange">
                                   <option selected="selected">None</option>
                                   <option name="top" value="top">Top</option>
                                   <option name="bottom" value="bottom">Bottom</option>
                               </select>
                               <input type="number" max="999999999" min="1" value="20" className="sort-attribute-limit collimit" id="collimitrange">
                               <span className="valueExceedErrorForRanking hide" style="color:red">Value must be less than or equal to 999999999</span>
                               <select className="sort-attribute-name rankingitem" id="rankingitemsrange">
                               </select>
                           </div>
                       </div>
                   </ul>
               </div>
           </div> --> */}
                <div className="modalaxiscontainer nonaxis_ct hide">
                    <legend className="subhead fa-grey">
                        <div className="attraxisnames">{lang["storyboard.themesettings"]}</div>
                        <button className="pull-right btn btn-transparent tooltip-left" data-num={that.chartnumber} tab-id="0" tab-name="" data-tooltip={lang["storyboard.advancdchart"]} id="viewadvancedchartsettingsmodal">
                            <i className="fa fa-cog"></i>
                        </button>
                    </legend>
                    <div className="marg-top-10">
                        <ul className="list-group">
                            <li className="boc-list-item singleliitems chartthemeli">
                                <span className="itemcontainer dropup">
                                    <span className="itemtype imgshowcolormenu">
                                        <img src={require('../images/birdimages/attributes-theme-20.png')} alt="" />
                                    </span>
                                    <span className="itemname dropup">
                                        <span className="color-container" aria-expanded="false" data-toggle="dropdown">
                                            <span className="colors" style={{ backgroundColor: "#4e79a7" }}></span>
                                            <span className="colors" style={{ backgroundColor: "#f28e2b" }}></span>
                                            <span className="colors" style={{ backgroundColor: "#e15759" }}></span>
                                            <span className="colors" style={{ backgroundColor: "#76b7b2" }}></span>
                                            <span className="colors" style={{ backgroundColor: "#59a14f" }}></span>
                                        </span>
                                        <ul role="menu" className="dropdown-menu color-schemes-container">
                                            <li className="list-title nopadding marg-bot-10">{lang["storyboard.colorthemes"]}</li>
                                            <div className="row">
                                                {(that.colorThemes != undefined) ? (
                                                    that.colorThemes.map((i, index) => {
                                                        var lactive = "";
                                                        if ((that.colorThemeIndex == 0 || that.colorThemeIndex == undefined) && index == 0) {
                                                            lactive = "legendactive";
                                                        } else if (that.colorThemeIndex == index) {
                                                            lactive = "legendactive";
                                                        }
                                                        return (
                                                            <div key={index} className="col-lg-12">
                                                                <div className="legendcpalete selectlegend" onClick={this.selectlegend} id={`colorthemes${index}`}>
                                                                    <i className={`fa fa-circle legendcpselect ${lactive}`} theme-index={index}></i>
                                                                    <div className="col-lg-12">
                                                                        {Array.apply(0, Array(5)).map(function (x, c) {
                                                                            return <div key={c} className="colors" style={{ backgroundColor: i[c] }}></div>;
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div>);
                                                    })
                                                ) : ""}
                                            </div>
                                        </ul>
                                    </span>
                                    <span className="itemedit" title="" aria-expanded="false" data-toggle="dropdown">
                                        {/* <!-- <span type="button" className="">
                                            <i className="expandinghamburger"></i>
                                        </span> --> */}
                                        <i className="fa fa-wrench fa-flip-horizontal" style={{ fontSize: "18px", display: "block", color: "#bfc1cc" }}></i>
                                    </span>
                                    <ul className="dropdown-menu" role="menu">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-xs-12">
                                                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-7">
                                                        <span className="toggle-title">{lang["storyboard.charttitle"]}</span>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-3">
                                                        <div className="checkbox checkbox-slider--c">
                                                            <label>
                                                                {(that.chartSettings.chartTitle == true) ? (
                                                                    <><input type="checkbox" checked="checked" id="chartTitle" className="chart_settings"></input><span>&nbsp;</span></>
                                                                ) : (
                                                                        <><input type="checkbox" id="chartTitle" className="chart_settings"></input><span>&nbsp;</span></>
                                                                    )}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xs-12">
                                                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-7">
                                                        <span className="toggle-title">{lang["storyboard.legend"]}</span>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-3">
                                                        <div className="checkbox checkbox-slider--c">
                                                            <label>
                                                                {(that.chartSettings.legend == true) ? (
                                                                    <><input type="checkbox" checked="checked" id="legend" className="chart_settings" /><span>&nbsp;</span></>
                                                                ) : (
                                                                        <><input type="checkbox" id="legend" className="chart_settings" /><span>&nbsp;</span></>
                                                                    )}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xs-12">
                                                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-7">
                                                        <span className="toggle-title">{lang["storyboard.x-axis"]}</span>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-3">
                                                        <div className="checkbox checkbox-slider--c">
                                                            <label>
                                                                {(that.chartSettings.xAxisLabel == true) ? (
                                                                    <><input type="checkbox" checked="checked" id="xAxisLabel" className="chart_settings" /><span>&nbsp;</span></>
                                                                ) : (
                                                                        <><input type="checkbox" id="xAxisLabel" className="chart_settings" /><span>&nbsp;</span></>
                                                                    )}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xs-12">
                                                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-7">
                                                        <span className="toggle-title">{lang["storyboard.y-axis"]}</span>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-3">
                                                        <div className="checkbox checkbox-slider--c">
                                                            <label>
                                                                {(that.chartSettings.yAxisLabel == true) ? (
                                                                    <><input type="checkbox" checked="checked" id="yAxisLabel" className="chart_settings" /><span>&nbsp;</span></>
                                                                ) : (
                                                                        <><input type="checkbox" id="yAxisLabel" className="chart_settings" /><span>&nbsp;</span></>
                                                                    )}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xs-12">
                                                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-7">
                                                        <span className="toggle-title">{lang["storyboard.datalabel"]}</span>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-3">
                                                        <div className="checkbox checkbox-slider--c">
                                                            <label>
                                                                {(that.chartSettings.dataLabel == true) ? (
                                                                    <><input type="checkbox" checked="checked" id="dataLabel" className="chart_settings" /><span>&nbsp;</span></>
                                                                ) : (
                                                                        <> <input type="checkbox" id="dataLabel" className="chart_settings" /><span>&nbsp;</span></>
                                                                    )}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xs-12">
                                                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-7">
                                                        <span className="toggle-title">{lang["storyboard.crosshair"]}</span>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-3">
                                                        <div className="checkbox checkbox-slider--c">
                                                            <label>
                                                                {(that.chartSettings.crossHairs == true) ? (
                                                                    <><input type="checkbox" checked="checked" id="crossHairs" className="chart_settings" /><span>&nbsp;</span></>
                                                                ) : (
                                                                        <><input type="checkbox" id="crossHairs" className="chart_settings" /><span>&nbsp;</span></>
                                                                    )}

                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xs-12">
                                                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-7">
                                                        <span className="toggle-title">{lang["storyboard.gridline"]}</span>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-3">
                                                        <div className="checkbox checkbox-slider--c">
                                                            <label>
                                                                {(that.chartSettings.gridLine == true) ? (
                                                                    <><input type="checkbox" checked="checked" id="gridLine" className="chart_settings" /><span>&nbsp;</span></>
                                                                ) : (
                                                                        <><input type="checkbox" id="gridLine" className="chart_settings" /><span>&nbsp;</span></>
                                                                    )}

                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xs-12">
                                                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-7">
                                                        <span className="toggle-title">{lang["storyboard.referenceline"]}</span>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-3">
                                                        <div className="checkbox checkbox-slider--c">
                                                            <label>
                                                                {(that.chartSettings.referenceLine == true) ? (
                                                                    <> <input type="checkbox" checked="checked" id="referenceLine" className="chart_settings" /><span>&nbsp;</span></>
                                                                ) : (
                                                                        <> <input type="checkbox" id="referenceLine" className="chart_settings" /><span>&nbsp;</span></>
                                                                    )}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </ul>
                                </span>
                                <span className="itemoptions">
                                    <span className="itemremove">
                                        <button className="btn btn-transparent disabled" type="button" title={lang["storyboard.remove"]}><i className="fa fa-trash-o"></i></button>
                                    </span>
                                    <span className="itemaggregation"></span>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
    metricLayoutChange(e) {
        var $this = e.currentTarget,
            currentLayoutTheme = $($this).attr("layout-id");

        $(".metric-layout").removeClass("active");
        $($this).addClass("active");

        if (currentLayoutTheme === "1") {
            $('.metriclayout').addClass("hide");
            $('.metriclayout[layout-id="1"]').removeClass("hide");
            $('ul.metricdatefieldsAttributes').empty();
            $('select.metriccoparision').val("All");
            if ($('#metricsuffix').val() == "") { $('#metricsuffix').val("Actual") }
        } else {
            $('.metriclayout').removeClass("hide");
            $('.metriclayout[layout-id="1"]').addClass("hide");
            //	$("#metrictargetvalue").val("");
            if ($('#metricsuffix').val() == "Actual") { $('#metricsuffix').val("") }
        }
    }
    validateSolidGauge(e) {
        //if the letter is not digit then display error and don't type anything
        var $this = e.currentTarget;
        var target = $($this).attr('id');
        var errorbox;
        if (target === 'solidgaugemax') {
            errorbox = "#errmsgsolidgaugemax"
        } else if (target === 'solidgaugemin') {
            errorbox = "#errmsgsolidgaugemin"
        }
        var metrictargetvalue = $($this).val();
        var rgx = /^[0-9]*\.?[0-9]*$/;
        if (metrictargetvalue != "" && !metrictargetvalue.match(rgx)) {
            var tempmetrictargetvalue = metrictargetvalue.substring(0, metrictargetvalue.length - 1);
            $(errorbox).html("Digits Only").show().fadeOut(2000);
            $($this).val(tempmetrictargetvalue);
        }
    }

    validateSolidGaugeValues(e) {
        var $this = e.currentTarget,
            target = $($this).attr('id'),
            ebmi = "#errmsgsolidgaugemin",
            ebmx = "#errmsgsolidgaugemax",
            solidminvalue = parseFloat($("#solidgaugemin").val()),
            solidmaxvalue = parseFloat($("#solidgaugemax").val());
        if (target === 'solidgaugemax') {
            if (solidminvalue != "") {
                if (solidmaxvalue <= solidminvalue) {
                    $(ebmi).html("").hide();
                    $(ebmx).html(lang["storyboard.maxvalgreater"] + solidminvalue + "").show();
                } else {
                    $(ebmx).html("").hide();
                    $(ebmi).html("").hide();
                }
            }
        } else if (target === 'solidgaugemin') {
            if (solidmaxvalue != "") {
                if (solidminvalue >= solidmaxvalue) {
                    $(ebmx).html("").hide();
                    $(ebmi).html(lang["storyboard.minvallower"] + solidmaxvalue + "").show();
                    $(".spinner").hide();
                    $(".gridspinner").hide();
                    return;
                } else {
                    $(ebmi).html("").hide();
                    $(ebmx).html("").hide();
                }
            }
        }
    }
    selectlegend(e) {
        var $this = e.currentTarget;
        $('.selectlegend i.legendactive').removeClass('legendactive');
        $($this).find('i').addClass('legendactive');
        var newtheme = $($this).find("div.col-lg-12").html();
        $(".chartthemeli .color-container").empty().append(newtheme);
    }
    validateMetricTargetValue(e) {
        var $this = e.currentTarget;
        var metrictargetvalue = $("#metrictargetvalue").val();
        var rgx = /^[0-9]*\.?[0-9]*$/;
        if (metrictargetvalue != "" && !metrictargetvalue.match(rgx)) {
            var tempmetrictargetvalue = metrictargetvalue.substring(0, metrictargetvalue.length - 1);
            $("#metrictargetvalue").val(tempmetrictargetvalue);
        }
    }
    dropdownyaxis(e) {
        e.preventDefault();
        var chartType = $(".charttype.active").attr("id");
			if(chartType==="piewithlegend" || chartType==="funnel" || chartType==="pyramid" || chartType==="metric" || chartType==="solidgauge" || chartType==="fullcirclegauge" || chartType==="world" || chartType=="countrymap" || chartType=="indiamap"  || chartType=="srilankamap" || chartType=="leaflet"||chartType=='boxplot')
			{
				if ($('.yaxisattributes .singleliitems').length === 1){
					$(".spinner").hide();
					$(".gridspinner").hide();
					$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
					$('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
					$('.birdmessagebuttons').removeClass('show');
					$('.birdmessagebuttons').addClass('hide');
					$('.birdmessage-center').addClass("message-top-in");
					$('.details').removeClass('show');
					$('.details').addClass('hide');
					$('.birdmessage h2').empty().append('Warning');
					$('.birdmessage-info').empty().text(getMessage('BirdWarning08'));
					e.stopPropagation();
                    return
				};
			} else if (chartType==="pie" || chartType==="semicircle"  ||  chartType==="donut" || chartType==="3dpie" ){
				if ($('.yaxisattributes .singleliitems').length >= 3){
					$(".spinner").hide();
					$(".gridspinner").hide();
					$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
					$('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
					$('.birdmessagebuttons').removeClass('show');
					$('.birdmessagebuttons').addClass('hide');
					$('.birdmessage-center').addClass("message-top-in");
					$('.details').removeClass('show');
					$('.details').addClass('hide');
					$('.birdmessage h2').empty().append('Warning');
					$('.birdmessage-info').empty().text(getMessage('BirdWarning06'));
					e.stopPropagation();
                    return
				};
			}
        PubSub.publish('dropdownyaxis');
    }
    dropdownxaxis() {
        PubSub.publish('dropdownxaxis');
    }
    dropdownzaxis() {
        PubSub.publish('dropdownzaxis');
    }
    legenddropdown() {
        PubSub.publish('legenddropdown');
    }
    dropdowntooltip() {
        PubSub.publish('dropdowntooltip');
    }
    dropdownreferenceline() {
        PubSub.publish('dropdownreferenceline');
    }
    dropdownmetricdatefield() {
        PubSub.publish('dropdownmetricdatefield');
    }
    dropdowncustomsolidgauge() {
        PubSub.publish('dropdowncustomsolidgauge');
    }

}