import _ from 'lodash';
import React from "react";
import PubSub from 'pubsub-js'
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import * as savedReportCollection from "../js/helpers/utils/SavedReportCollection";
import { getMessage } from '../js/helpers/utils/BirdMessage';
import * as ModalProperties from "../js/helpers/utils/modalproperties";
import ReactDOM from "react-dom";
import AttrModalTemplate from "./reportattributesmodal.jsx"
import AxisSettingView from "./attraxissettingview"
import DimensionsView from "./attrmodaldimensionsview"
import MeasuresView from "./attrmodalmeasuresview"
import CombinationChartsView from "./combinationchartsview"
import CustomFieldView from "./attrmodalcustomfieldview"
import CustomHierarchyFieldView from "./customhierarchyfieldview"
import customRangeFieldView from './customrangefieldview'
import customMeasureFieldView from './custommeasurefieldview'
import Custommeasurehierarchyfieldview from './custommeasurehierarchyfieldview'
import CustomParameterView from './customparameterfield/customparametermodalview'
import customParameterFieldView from './customparameterfield/customparamteraddfieldview'
import AdvancedChartSettingsModalView from './advancedchartsettingsmodalview';
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;

export default class AttrModalView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			features: props.features,
			measures: props.measures,
			dimensions: props.dimensions,
			dimensioncount: 0,
			measurescount: 0,
			customparametercount: 0,
			chartnumber: props.chartnumber,
			reportId: props.reportId,
			datamodelId: props.datamodelId,
			dimensionsMappingResponse: [],
			isImportedReport: false,
			multifact: props.multifact,
			multifactentities: props.multifactentities,
			facttables: props.facttables,
			reportId: props.reportId,
			is_storyboard: props.is_storyboard

		};
		this.unbindEvents();
		this.customparameter = this.customparameter.bind(this);
		this.setcharttype = this.setcharttype.bind(this);
		this.applyreferenceline = PubSub.subscribe("applyreferenceline", this.applyRefLineEve.bind(this));
		this.addcustomfieldEve = PubSub.subscribe('addcustomfield', this.addcustomfieldEvee.bind(this));
		this.applyattributesEve = PubSub.subscribe('applyattributes', this.applyattributesEvee.bind(this));
		this.ViewAdvancedchartsettingsmodalEv = PubSub.subscribe('advancedchartsettingsmodal', this.viewadvancedchartsettingsmodalEve.bind(this));
		this.previewcolumndataEve = PubSub.subscribe("previewcolumndata", this.previewColumnDataEve.bind(this));
		this.render();

	}
	render() {
		
		$(".rightattributes").html('<div class="gridload"><div></div><div></div><div></div><div></div></div>');
		// $(".attributesmodaldiv").empty();
		if (sessionStorage.getItem("isImportedReport") || window.location.pathname.indexOf("importreport") >= 0) {
			this.state.isImportedReport = true;
		}
		sessionStorage.setItem('currentChartNumberForSort', this.state.chartnumber);
		if (sessionStorage.getItem('isconvettedtypesapplied')) {
			var convertedTypes = sessionStorage.getItem('convertedtypes');
			if (convertedTypes != undefined) {
				var parsedValues = JSON.parse(convertedTypes);
				var actualDimensions = this.state.dimensions;
				$.each(parsedValues, function (index, convertedType) {
					$.each(actualDimensions, function (index1, filter) {
						if (filter.columnName == convertedType.columnName) {
							filter.tableDisplayType = convertedType.dataType;
							actualDimensions.splice(index1, 1, filter)
						}
					});
					//update dataType for customfields
					var customFields = sessionStorage.getItem("customFields");
					if (customFields != undefined && customFields != null && customFields != "null" && customFields.length > 0) {
						customFields = JSON.parse(customFields);
						_.each(customFields, function (value, index) {
							if (value['name'] == convertedType.columnName) {
								if (customFields[index] != undefined) {
									customFields[index].dataType = convertedType.dataType;
								}
							}
						});
						sessionStorage.setItem("customFields", JSON.stringify(customFields));
					}
				});
				this.state.dimensions = actualDimensions;
			}
		}
		var that = this;
		// var converttoTypeCollection=new ConverttoTypeCollection();
		// converttoTypeCollection.fetch({reset: true,cache: false,data: $.param({ reportId: that.state.reportId}),
		//     success:function(collection, response){
		//         that.dimensionsMappingResponse=response;
		//     }});

		var that = this;
		$(document).on('click', '.yaxisdropdown .measureitem, .xaxisdropdown .dimensionitem,.legenddropdown .legenddropdownitem, .deleteyaxis, .deletexaxis, .deletelegendaxis', function (e) {
			setTimeout(function () {
				that.axisUIValidation();
			}, 500)
		});


		if (sessionStorage.getItem('isconvettedvaluesapplied')) {
			var deleteMeasureIndexes = [];
			var convertedValues = sessionStorage.getItem("convertedvalues");
			var parsedValues = JSON.parse(convertedValues);
			var that = this;
			_.each(parsedValues, function (convertedValue) {
				$.each(that.state.measures, function (i, model) {
					if (model.get('columnName') == convertedValue["columnName"]) {
						deleteMeasureIndexes.push(i);
					}
				});
			});
			$.each(deleteMeasureIndexes, function (i, deleteMeasureIndexe) {
				that.state.measures.splice(deleteMeasureIndexe, 1);
			});
		}
		this.state.isDirect = ChartInfo.functions.getIsDirect();
		this.state.isStoredProcedure = ChartInfo.functions.getIsStoredProcedure();
		var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(this.state.chartnumber));
		var chartSettings = chartDetails['chartSettings'];
		var themeIndex = this.state.colorThemeIndex;
		if (chartDetails.colorThemeIndex != undefined) {
			themeIndex = chartDetails.colorThemeIndex;
		}
		if (themeIndex == undefined) {
			themeIndex = 0;
		}
		if (chartSettings == undefined) {
			if (chartDetails.chartType == "pie" || chartDetails.chartType == "3dpie" || chartDetails.chartType == "semicircle" || chartDetails.chartType == "donut") {
				chartSettings = { 'chartTitle': true, 'legend': false, 'xAxisLabel': true, 'yAxisLabel': true, 'dataLabel': false, 'crossHairs': false, 'gridLine': true, 'referenceLine': false };
			}
			else {
				chartSettings = { 'chartTitle': true, 'legend': true, 'xAxisLabel': true, 'yAxisLabel': true, 'dataLabel': false, 'crossHairs': false, 'gridLine': true, 'referenceLine': false };
			}

		}
		var isCustomFieldButtonEnable = false;
		$.each(this.state.features, function (index, featuresModule) {
			if (featuresModule.moduleName == 'my_report') {
				$.each(featuresModule.features, function (inddex2, feature) {
					if ((feature.featureName == 'viewreport_maximize_custom_field' && feature.allow == true) || (feature.featureName == 'viewreport_maximize_custom_hierarchy' && feature.allow == true)) {
						isCustomFieldButtonEnable = true;
					}
				});
			}
		});
		this.state.measures.sort(function (a, b) {
			var x = a.columnDisplayName.toLowerCase();
			var y = b.columnDisplayName.toLowerCase();
			return x < y ? -1 : x > y ? 1 : 0;
		});
		this.state.dimensions.sort(function (a, b) {
			var x = a.columnDisplayName.toLowerCase();
			var y = b.columnDisplayName.toLowerCase();
			return x < y ? -1 : x > y ? 1 : 0;
		});
		try {
			// $("#newslideoutattributes").empty();
			// // $(".attributesmodaldiv" + this.state.chartnumber).empty();
			// if($(".attributesmodaldiv" + this.state.chartnumber+">div")!=null){
			// 	ReactDOM.unmountComponentAtNode($(".attributesmodaldiv" + this.state.chartnumber+">div"));
			// }
		} catch (e) { }
		var today = new Date();
		var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
		dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
		//$(".attributesmodaldiv" + this.state.chartnumber).append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
		$(".rightattributes.open").append("<div class='dynodiv " + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
		
		ReactDOM.render(<AttrModalTemplate applyAttributesAndDrawChart={this.applyAttributesAndDrawChart} customparameter={this.customparameter} searchParameters={this.searchParameters} searchDimensions={this.searchDimensions} searchMeasures={this.searchMeasures} setcharttype={this.setcharttype} measures={this.state.measures} dimensions={this.state.dimensions} chartnumber={this.state.chartnumber} colorThemeIndex={themeIndex} colorThemes={ChartInfo.functions.getColorThemes()} chartSettings={chartSettings} allfeatures={this.state.features} isCustomFieldButtonEnable={isCustomFieldButtonEnable} isDirect={this.state.isDirect} isStoredProcedure={this.state.isStoredProcedure} multifact={this.state.multifact} metricprefixCheck={this.metricprefixCheck} metricsuffixCheck={this.metricsuffixCheck} ></AttrModalTemplate>, document.getElementById(dynamicdiv));
		// $('#attributesmodaldiv').html(this.template({measures:this.state.measures,dimensions:this.state.dimensions,chartnumber:this.state.chartnumber,colorThemeIndex:themeIndex,colorThemes:ChartInfo.functions.getColorThemes(),chartSettings:chartSettings,allfeatures:this.state.features,isCustomFieldButtonEnable:isCustomFieldButtonEnable,isDirect:this.state.isDirect,isStoredProcedure:this.state.isStoredProcedure,multifact:this.state.multifact}));
		$('.charticons').click(function (e) {
			//$('.charticons').removeClass('active');
			$('.chartstyles').find('.collapse.in').collapse('hide');
		})
		return (<div></div>);
	}
	applyattributesEvee(msg, data) {
		this.applyAttributesAndDrawChart(data);
	}
	addContent() {
		var convertedValues = sessionStorage.getItem("convertedvalues");
		var parsedValues = JSON.parse(convertedValues);
		var that = this;
		var chartData = ChartInfo.functions.getChartDetails(this.state.chartnumber);
		var jsonData = JSON.parse(chartData);
		_.each(parsedValues, function (convertedValue) {
			var today = new Date();
			var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
			dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
			$('.dimensions').append("<div class='" + dynamicdiv + " ' id='" + dynamicdiv + "'></div>");
			if (convertedValue["type"] == 'dimension') {
				var dimensionsView = new DimensionsView({
					dynamicdiv: dynamicdiv,
					columnDisplayName: convertedValue["columnDisplayName"],
					columnName: convertedValue["columnName"],
					dataType: convertedValue["dataType"],
					converted: convertedValue["converted"],
					tableName: convertedValue["tableName"],
					features: that.state.features,
					multifact: that.state.multifact,
					multifactentities: that.state.multifactentities,
					reportId: that.state.reportId,
					datamodelId: that.state.datamodelId,
				});
			//	$(".dimensions").append($("#" + dynamicdiv));
				that.state.dimensioncount++;
			}

			else if (convertedValue["type"] == 'measure') {
				var today = new Date();
				var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
				dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
				$('.dimensions').append("<div class='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
				var measuresView = new MeasuresView({
					dynamicdiv: dynamicdiv,
					columnDisplayName: convertedValue["columnDisplayName"],
					columnName: convertedValue["columnName"],
					dataType: convertedValue["dataType"],
					aggregation: convertedValue["aggregation"],
					tableName: convertedValue["tableName"],
					features: that.state.features,
					jsonData: jsonData,
					measures: that.state.measures,
					dimensions: that.state.dimensions,
					multifact: that.state.multifact,
					multifactentities: that.state.multifactentities,
				});
				$(".measures").append($("#" + dynamicdiv));
				that.state.measurescount++;
			}

		});

		_.each(this.state.measures, function (measure) {
			var today = new Date();
			var dynamicdiv = "mitem"+today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
			dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
			$('.measures').append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
			var measuresView = new MeasuresView({
				dynamicdiv: dynamicdiv,
				columnDisplayName: measure.columnDisplayName,
				columnName: measure.columnName,
				dataType: measure.tableDisplayType,
				aggregation: measure.aggregation,
				tableName: measure.tableName,
				measures: that.state.measures,
				dimensions: that.state.dimensions,
				collection: that.state.collection,
				viewReportAddFilterCollection: that.state.filtercollection,
				features: that.state.features,
				jsonData: jsonData,
				multifact: that.state.multifact,
				multifactentities: that.state.multifactentities,
			});
			// $(".measures").append($("#" + dynamicdiv));
			that.state.measurescount++;
		});

		_.each(this.state.dimensions, function (dimension) {
			var today = new Date();
			var dynamicdiv = "ditem"+today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
			dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
			$('.dimensions').append("<div class='" + dynamicdiv + " ' id='" + dynamicdiv + "'></div>");
			setTimeout(function(){
			var dimensionsView = new DimensionsView({
				dynamicdiv: dynamicdiv,
				columnDisplayName: dimension.columnDisplayName,
				columnName: dimension.columnName,
				dataType: dimension.tableDisplayType,
				tableName: dimension.tableName,
				measures: that.state.measures,
				dimensions: that.state.dimensions,
				// collection:that.collection,
				//    viewReportAddFilterCollection:that.state.viewReportAddFilterCollection,
				features: that.state.features,
				jsonData: jsonData,
				multifact: that.state.multifact,
				multifactentities: that.state.multifactentities,
				reportId: that.state.reportId,
				datamodelId: that.state.datamodelId,
			});
			// $(".dimensions").append($("#" + dynamicdiv));
			that.state.dimensioncount++;
			},0);
		});

		var customfields = sessionStorage.getItem("customFields");
		var temp = JSON.parse(customfields);
		var res;
		_.each(temp, function (customfield) {
			_.each(customfield.parametrFieldsArray, function (value) {
				res = $.grep(ChartInfo.functions.getCustomParameter(), function (e) {
					return e.parametername == value;
				})
				var formula = customfield.formula;
				var scriptcondition = customfield.scriptcondition;
				var fields = customfield.fields;
				var sqlformula = customfield.sqlformula;
				if (res.length != 0) {
					customfield.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
					customfield.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
					customfield.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
					customfield.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
				} else {
					_.each(ChartInfo.functions.getCustomParameter(), function (values) {
						if (customfield.parametrFieldsArray.indexOf(values.parametername) >= 0) {
							customfield.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
							customfield.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
							customfield.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
							customfield.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
						}
					});
				}
			});
		});
		customfields = JSON.stringify(temp);
		var parsedCustomFields = JSON.parse(customfields);
		if (parsedCustomFields != undefined && parsedCustomFields.length != 0) {
			parsedCustomFields.sort(function (a, b) {
				var x = a.name.toLowerCase();
				var y = b.name.toLowerCase();
				return x < y ? -1 : x > y ? 1 : 0;
			});
		}
		_.each(parsedCustomFields, function (customField) {
			var today = new Date();
			var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
			dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
			$(".customdimensions").append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
			var customfieldView = new CustomFieldView({
				deletecustomfield: that.deletecustomfield,
				dynamicdiv: dynamicdiv,
				columnDisplayName: customField.name,
				columnName: customField.name,
				dataType: (customField.dataType == undefined) ? "string" : customField.dataType,
				aggregation: "Sum",
				multifact: that.state.multifact,
				multifactentities: that.state.multifactentities,
			});
			$(".customdimensions").append($("#" + dynamicdiv));
			that.state.dimensioncount++;
		});
		if (ChartInfo.functions.getcustomhierarchy() != undefined && ChartInfo.functions.getcustomhierarchy().length != 0) {
			ChartInfo.functions.getcustomhierarchy().sort(function (a, b) {
				var x = a.name.toLowerCase();
				var y = b.name.toLowerCase();
				return x < y ? -1 : x > y ? 1 : 0;
			});
		}
		_.each(ChartInfo.functions.getcustomhierarchy(), function (customField) {
			var today = new Date();
			var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
			dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
			$(".customhierarchydimensions").append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
			var customfieldView = new CustomHierarchyFieldView({
				dynamicdiv: dynamicdiv,
				columnDisplayName: customField.name,
				columnName: customField.name,
				multifact: that.state.multifact,
				multifactentities: that.state.multifactentities,
			});
			$(".customhierarchydimensions").append($("#" + dynamicdiv));
			$(".dimensioncount").text(++that.state.dimensioncount);
		});
		if (ChartInfo.functions.getRange() != undefined && ChartInfo.functions.getRange().length != 0) {
			ChartInfo.functions.getRange().sort(function (a, b) {
				var x = a.name.toLowerCase();
				var y = b.name.toLowerCase();
				return x < y ? -1 : x > y ? 1 : 0;
			});
		}
		_.each(ChartInfo.functions.getRange(), function (customField) {
			var today = new Date();
			var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
			dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
			$(".customrangedimension").append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
			var customrangefieldView = new customRangeFieldView({
				dynamicdiv: dynamicdiv,
				columnDisplayName: customField.name,
				columnName: customField.name,
				multifact: that.state.multifact,
				multifactentities: that.state.multifactentities,
			});
			$(".customrangedimension").append($("#" + dynamicdiv));
			$(".dimensioncount").text(++that.state.dimensioncount);
		});
		if (ChartInfo.functions.getCustomMeasureField() != undefined && ChartInfo.functions.getCustomMeasureField() != 0) {
			ChartInfo.functions.getCustomMeasureField().sort(function (a, b) {
				var x = a.name.toLowerCase();
				var y = b.name.toLowerCase();
				return x < y ? -1 : x > y ? 1 : 0;
			});
		}
		_.each(ChartInfo.functions.getCustomMeasureField(), function (field) {
			var today = new Date();
			var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
			dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
			$(".customaggregateddimension").append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
			var customaggregatedfieldView = new customMeasureFieldView({
				dynamicdiv: dynamicdiv,
				columnDisplayName: field.name,
				columnName: field.name,
				multifact: that.state.multifact,
				multifactentities: that.state.multifactentities,
			});
			$(".customaggregateddimension").append($("#" + dynamicdiv));
			$(".measurecount").text(++that.state.measurescount);
		});
		if (ChartInfo.functions.getMeasureHierarchy() != undefined && ChartInfo.functions.getMeasureHierarchy().length != 0) {
			ChartInfo.functions.getMeasureHierarchy().sort(function (a, b) {
				var x = a.name.toLowerCase();
				var y = b.name.toLowerCase();
				return x < y ? -1 : x > y ? 1 : 0;
			});
		}
		_.each(ChartInfo.functions.getMeasureHierarchy(), function (field) {
			var today = new Date();
			var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
			dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
			$('.custommeasurehierarchydimension').append("<div class='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
			var htmlelement = new Custommeasurehierarchyfieldview({
				dynamicdiv: dynamicdiv,
				columnDisplayName: field.name,
				columnName: field.name,
				multifact: that.state.multifact,
				multifactentities: that.state.multifactentities,
			});
			$(".custommeasurehierarchydimension").append($("#" + dynamicdiv));
			$(".measurecount").text(++that.state.measurescount);
		});
		if (ChartInfo.functions.getCustomParameter() != undefined && ChartInfo.functions.getCustomParameter().length != 0) {
			ChartInfo.functions.getCustomParameter().sort(function (a, b) {
				var x = a.parametername.toLowerCase();
				var y = b.parametername.toLowerCase();
				return x < y ? -1 : x > y ? 1 : 0;
			});
		}
		_.each(ChartInfo.functions.getCustomParameter(), function (field) {
			var today = new Date();
			var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
			dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
			$('.customparameterfields').append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
			var customparameterfieldview = new customParameterFieldView({
				dynamicdiv: dynamicdiv,
				customparameter: that.customparameter,
				columnDisplayName: field.parametername,
				columnName: field.parametername,
				multifact: that.state.multifact,
				multifactentities: that.state.multifactentities,
			});
			$(".customparameterfields").append($("#" + dynamicdiv));
			$(".parameterscount").text(++that.state.customparametercount);
		});
		var axisContent = new AxisSettingView({
			axisUIValidation: this.axisUIValidation,
			deletecustomfield: this.deletecustomfield,
			measures: this.state.measures,
			dimensions: this.state.dimensions,
			chartnumber: this.state.chartnumber,
			metricdatetype: jsonData["metricdatetype"],
			features: this.state.features,
			reportId: this.state.reportId,
			multifact: that.state.multifact,
			facttables: this.state.facttables,
			multifactentities: that.state.multifactentities,
			datamodelId: this.state.datamodelId,
			is_storyboard: this.state.is_storyboard,
		});

		//  $(".axissetting").append( document.getElementById('axissetting'));



		// $( "#accordion:nth-child(1n)" ).accordion({
		//     heightStyle: "content",
		//     active: false,
		//     collapsible: true
		// });

		if (chartData != undefined) {
			$(".charttype").removeClass("active");
			$('.attrmodal_tooltip').removeClass('hide');
			var jsonData = JSON.parse(chartData);
			this.dobycolumns = jsonData["dobyselectedcolumns"];
			var chartType = jsonData["chartType"];
			if(chartType=="world" || chartType=="countrymap"){
				$("input#gridLine").parents().eq(3).find(".toggle-title").text("Mono Color Legend");
			}else{
				$("input#gridLine").parents().eq(3).find(".toggle-title").text("Grid Line");
			}
			if (chartType == "combinationchart") {
				$('.yaxisattributes').sortable('disable');
			}
			if (chartType === "metric" || chartType === 'solidgauge' || chartType === 'fullcirclegauge') {
				if (chartType === "metric") {
					$(".charttype#" + chartType).addClass("active");
					//$('.commonattributes').addClass('hide');
					$('.commonattributes>div:not(:first-child)').addClass('hide');
					$('.xaxisattributes').parent().addClass('disabled');
					$('.commonattributes').removeClass('hide');
					$('.metricattributes').removeClass('hide');
					$('.metricdatefields').removeClass('hide');
					$('.metricprefix').removeClass('hide');
					$('.metricsuffix').removeClass('hide');
					//$(".othersettingsdiv .col-xs-12:not(:first-child)").addClass("hide");
					$(".othersettingsdiv .col-xs-12:not(:nth-child(-n + 2))").addClass("hide");
					$(".btn#viewadvancedchartsettingsmodal").addClass("hide")
				}
				else {
					$(".charttype#" + chartType).addClass("active");
					//$('.commonattributes').addClass('hide');
					$('.commonattributes>div:not(:first-child)').addClass('hide');
					$('.xaxisattributes').parent().addClass('disabled');
					$('.commonattributes').removeClass('hide');
					$('.metricattributes').removeClass('hide');
					$('.metricdatefields').removeClass('hide');
					$('.solidgaugemin').removeClass('hide');
					$('.solidgaugemax').removeClass('hide');
					//$(".othersettingsdiv .col-xs-12:not(:first-child)").removeClass("hide");
					$(".btn#viewadvancedchartsettingsmodal").removeClass("hide");
				}

			} else if (chartType === "textchart") {
				$(".charttype#" + chartType).addClass("active");
				//$('.settingscontent:nth(1)').addClass('disabled');
				//$('.settingscontent:last-child').addClass('disabled');
			} else if (chartType === "heatmap") {
				$(".charttype#" + chartType).addClass("active");
				$("#dropdownyaxis").parent().find('.attraxisnames').text("Values");
				$("#dropdownlegend").parent().find('.attraxisnames').text("Group By");
			} if (chartType === "bubble") {
				$(".charttype#" + chartType).addClass("active");
				/*Limit Only 1 Z Value*/
				if ($(".zaxisattributes li").length > 1) {
					$(".zaxisattributes div").not(':first').remove();
				}
				$('.zaxisattribute').removeClass('hide');
			}
			else {
				$(".charttype#" + chartType).addClass("active");
			}
			if (chartType === 'solidgauge' || chartType === 'fullcirclegauge') {
				$(".charttype#" + chartType).addClass("active");
				/*Limit Only 1 Z Value*/
				if ($(".customsolidgaugeattributes li").length > 1) {
					$(".customsolidgaugeattributes div").not(':first').remove();
				}
				$('.customsolidgaugeattributes').removeClass('hide');
			}
			else {
				$(".charttype#" + chartType).addClass("active");
			}
			/*Make Grouped Chart Type li Active*/
			if (chartType === 'bar' || chartType === 'multicolorbar' || chartType === 'multixbar' || chartType === 'invertedbar' || chartType === 'stackedbar' || chartType === 'invertedstackedbar' || chartType === 'multiaxisbar' || chartType === 'bartimeseries' || chartType === '3dbar' || chartType === 'trellis' || chartType === 'waterfall') {
				$('.charticons[data-toggle="collapse"]').not('[data-target="#barcharttypes"]').removeClass('active');
				$('.charticons[data-target="#barcharttypes"]').addClass('active');
			}
			else if (chartType === 'line' || chartType === "spline" || chartType === 'multixline' || chartType === 'invertedline' || chartType === 'linetimeseries') {
				$('.charticons[data-toggle="collapse"]').not('[data-target="#linecharttypes"]').removeClass('active');
				$('.charticons[data-target="#linecharttypes"]').addClass('active');
			}
			else if (chartType === 'pie' || chartType === 'donut' || chartType === 'semicircle' || chartType === '3dpie') {
				$('.charticons[data-toggle="collapse"]').not('[data-target="#piecharttypes"]').removeClass('active');
				$('.charticons[data-target="#piecharttypes"]').addClass('active');
			}
			else if (chartType === 'area' || chartType === 'stackedarea' || chartType === 'multixarea' || chartType === 'invertedarea' || chartType === 'areatimeseries') {
				$('.charticons[data-toggle="collapse"]').not('[data-target="#areacharttypes"]').removeClass('active');
				$('.charticons[data-target="#areacharttypes"]').addClass('active');
			}
			else if (chartType === 'world' || chartType === 'countrymap' || chartType === 'srilankamap' || chartType === 'leaflet' || chartType === 'indiamap') {
				$('.charticons[data-toggle="collapse"]').not('[data-target="#mapcharttypes"]').removeClass('active');
				$('.charticons[data-target="#mapcharttypes"]').addClass('active');
			}
			else if (chartType === 'solidgauge' || chartType === 'fullcirclegauge') {
				$('.charticons[data-toggle="collapse"]').not('[data-target="#gaugecharttypes"]').removeClass('active');
				$('.charticons[data-target="#gaugecharttypes"]').addClass('active');
			} else if (chartType === 'sankey' || chartType === 'sunburst' || chartType === 'wordcloud' || chartType === 'boxplot') {
				$('.charticons[data-toggle="collapse"]').not('[data-target="#advancedvisualizations"]').removeClass('active');
				$('.charticons[data-target="#advancedvisualizations"]').addClass('active');
			} else {
				$('.charticons[data-toggle="collapse"]').removeClass('active');
			}
			if (chartType === 'pie' || chartType === 'donut' || chartType === 'semicircle' || chartType === '3dpie' || chartType === 'world' || chartType === 'indiamap' || chartType === 'countrymap' || chartType === 'srilankamap'
				|| chartType === 'leaflet' || chartType === "funnel" || chartType == 'sunburst' || chartType == 'sankey' || chartType == 'wordcloud' || chartType == 'boxplot' || chartType == 'sparkline'
				|| chartType === "textchart" || chartType == 'sunburst' || chartType == 'sankey' || chartType == 'wordcloud' || chartType == 'boxplot' || chartType === "heatmap" || chartType === "funnel"
				|| chartType === "pyramid" || chartType == 'trellis' || chartType == 'multiaxisbar' || chartType == 'polar' || chartType == 'windrose' || chartType == 'spiderweb') {
				$("#dropdownreferenceline").parent().parent().hide();
			}
			if (chartType != "combinationchart") {
				$('.combinationchartslist').addClass('hide');
			}
			if (chartType != "bubble") {
				$('.zaxisattribute').addClass('hide');
			}
			if (chartType == 'sunburst' || chartType == 'sankey' || chartType == 'wordcloud' || chartType == 'boxplot' || chartType == 'sparkline') {
				$("#dropdowntooltip").parent().parent().hide();
				$(".tooltipattributes li").remove();
			}

			if (chartType === 'invertedbar' || chartType === 'invertedstackedbar' || chartType === 'multicolorbar' || chartType === 'multixbar' ||
				chartType === 'multiaxisbar' || chartType === 'bartimeseries' || chartType === '3dbar' || chartType === 'trellis' || chartType === 'waterfall' ||
				chartType === 'multixline' || chartType === 'invertedline' || chartType === 'linetimeseries' || chartType === 'semicircle' || chartType === '3dpie' ||
				chartType === 'multixarea' || chartType === 'invertedarea' || chartType === 'areatimeseries' || chartType === 'spiderweb' || chartType === 'polar' ||
				chartType === 'windrose' || chartType === 'sparkline' || chartType === 'heatmap' || chartType === 'textchart') {
				$('.charticons[data-toggle="collapse"]').not('[data-target="#othercharttypes"]').removeClass('active');
				$('.charticons[data-target="#othercharttypes"]').addClass('active');
			}
			/*---Make Grouped Chart Type li Active*/

		}
		_.each($('.yaxisattributes .singleliitems'), function (data) {
			$('.attributesmeasures[data-name="' + $($(data)).attr('data-name') + '"]')
				.find("#dropdownaggregation").text($('.yaxisattributes li[data-name="' + $($(data)).attr('data-name') + '"]').find('.aggregationtext').eq(0).text().replace("[", "").replace("]", ""))
			var yaxisagg = $(data).find('.aggregationtext').text().trim();
			$('.attributesmeasures[data-name="' + $($(data)).attr('data-name') + '"]').find("li.aggcount").removeClass("active");
			if (yaxisagg === "sum" || yaxisagg === "Sum") {
				$('.attributesmeasures[data-name="' + $($(data)).attr('data-name') + '"]').find("li.aggsum").addClass("active");
			}
			else if (yaxisagg === "max" || yaxisagg === "Max") {
				$('.attributesmeasures[data-name="' + $($(data)).attr('data-name') + '"]').find("li.aggmax").addClass("active");
			}
			else if (yaxisagg === "min" || yaxisagg === "Min") {
				$('.attributesmeasures[data-name="' + $($(data)).attr('data-name') + '"]').find("li.aggmin").addClass("active");
			}
			else if (yaxisagg === "Average" || yaxisagg === "average") {
				$('.attributesmeasures[data-name="' + $($(data)).attr('data-name') + '"]').find("li.aggavg").addClass("active");
			}
			else if (yaxisagg === "Count (all)" || yaxisagg === "count (all)") {
				$('.attributesmeasures[data-name="' + $($(data)).attr('data-name') + '"]').find("li.aggcount").addClass("active");
			}
			else if (yaxisagg === "count (distinct)" || yaxisagg === "Count (distinct)") {
				$('.attributesmeasures[data-name="' + $($(data)).attr('data-name') + '"]').find("li.aggcountdistinct").addClass("active");
			}
			else if (yaxisagg === "variance" || yaxisagg === "Variance") {
				$('.attributesmeasures[data-name="' + $($(data)).attr('data-name') + '"]').find("li.aggvariance").addClass("active");
			} else if (yaxisagg === "Standard Deviation" || yaxisagg === "standard deviation") {
				$('.attributesmeasures[data-name="' + $($(data)).attr('data-name') + '"]').find("li.aggstandarddeviation").addClass("active");
			} else if (yaxisagg === "Sum of Squares" || yaxisagg === "Sum of Squares") {
				$('.attributesmeasures[data-name="' + $($(data)).attr('data-name') + '"]').find("li.aggsumofsquares").addClass("active");
			} else if (yaxisagg === "Percentile" || yaxisagg === "percentile") {
				$('.attributesmeasures[data-name="' + $($(data)).attr('data-name') + '"]').find("li.aggpercentile").addClass("active");
			} else if (yaxisagg === "Median" || yaxisagg === "median") {
				$('.attributesmeasures[data-name="' + $($(data)).attr('data-name') + '"]').find("li.aggmedian").addClass("active");
			} else if (yaxisagg.toLowerCase() === "none") {
				$('.attributesmeasures[data-name="' + $($(data)).attr('data-name') + '"]').find("li.aggnone").addClass("active");
				$("#dropdowntooltip").addClass('disabled');
				$("#dropdownlegend").addClass('disabled');
				$("#dropdownsorting").addClass('disabled');
			}
		});
		$(".measurecount").text(this.state.measurescount);
		$(".dimensioncount").text(this.state.dimensioncount);
		// To Disable Legend If ChartType = mutil x
		var chartType = $(".charttype.active").attr("id");
		if (chartType != "multixbar" && chartType != "multixline" && chartType != "multixarea" && chartType != "leaflet" && chartType != "world" && chartType != "srilankamap" && chartType != "countrymap" && chartType != "indiamap") {
			if ($(".xaxisattributes .singleliitems").length > 1) {
				$(".xaxisattributes> div").not(':first').remove();
			}
			$("#dropdownlegend").parent().parent().show();
		}
		// To Add percentile value to yaxis field , yaxis droppdown and in measure
		_.each(jsonData.yAxis, function (yaxis, i) {
			$(".boc-list-item.singleliitems[data-name='" + yaxis.columnName + "']").attr("percentile-value", yaxis.percentileValue);
			$(".xs-list-item.measureitem[data-name='" + yaxis.columnName + "']").attr("percentile-value", yaxis.percentileValue);
			$(".boc-list-item.singleliitems.attributesmeasures[data-name='" + yaxis.columnName + "']").find(".percentilebox").val(yaxis.percentileValue);
		});
		if (chartType === "multixbar" || chartType === "multixline" || chartType === "multixarea" || chartType === "wordcloud") {
			$("#dropdownlegend").parent().parent().hide();
			$(".legendAttributes li").remove();
		}

		if (chartType == "pie" || chartType == "3dpie" || chartType == "semicircle" || chartType == "donut" || chartType == "heatmap" || chartType === "funnel" || chartType === "pyramid" || chartType === "trellis" || chartType == 'multiaxisbar' || chartType == "solidgauge" || chartType == "fullcirclegauge" || chartType == "sankey" || chartType == "wordcloud" || chartType == "boxplot" || chartType == "world" || chartType == "indiamap" || chartType == "countrymap" || chartType == "leaflet" || chartType == "srilankamap" || chartType == "metric" || chartType == 'polar' || chartType == 'windrose' || chartType == 'spiderweb') {
			$("#addreferenceline").parent().parent().hide();
		}

		/*Disable Attribute parameters based on chartType*/
		//Hide Legend
		if (chartType === 'multixbar' || chartType === 'multixline' || chartType === 'multixarea' || chartType === 'pie' || chartType === "wordcloud" || chartType === 'donut' || chartType === 'semicircle' || chartType === '3dpie' || chartType === "funnel" || chartType === "pyramid" || chartType === 'world' || chartType === 'countrymap' || chartType == "indiamap" || chartType == 'leaflet' || chartType === 'srilankamap') {
			$("ul.legendAttributes").parent().parent().hide();
		}
		/*--Disable Attribute parameters based on chartType*/

		/*Disable Reference Line based on chartType*/
		if (chartType == "pie" || chartType == "3dpie" || chartType == "semicircle" || chartType == "donut" || chartType == "heatmap" || chartType === "funnel" || chartType === "pyramid" || chartType === "trellis" || chartType == 'multiaxisbar' || chartType == "solidgauge" || chartType == "fullcirclegauge" || chartType == "sankey" || chartType == "wordcloud" || chartType == "boxplot" || chartType == "world" || chartType == "indiamap" || chartType == "countrymap" || chartType == "leaflet" || chartType == "srilankamap" || chartType == "metric" || chartType == 'polar' || chartType == 'windrose' || chartType == 'spiderweb') {
			$("#referenceLine").closest(".col-xs-12").addClass("disabled");
		}
		else {
			$("#referenceLine").closest(".col-xs-12").removeClass("disabled");
		}
		if (jsonData["orderType"] != undefined && jsonData["orderType"].length > 0) {
			$(".ordertype").trigger("change");
		}
		/*Disable Reference Line based on chartType*/

		//Initiate Disable other props when there is no axis fields selected
		this.axisUIValidation();
	}
	axisUIValidation() {
		//Disable other options when there is no axis fields selected in Visualization Settings
		var ct = $('.charttype.active').attr("id"),
			xX = $(".xaxisattributes .singleliitems").length,
			yX = $(".yaxisattributes .singleliitems").length,
			zX = $(".zaxisattributes .singleliitems").length,
			lL = $(".legendAttributes .singleliitems").length;

		if (ct != undefined && ct != null && ct != "") {
			if (ct != "sankey" && ct != "boxplot" && ct != "wordcloud" && ct != "bartimeseries" && ct != "linetimeseries" && ct != "areatimeseries" && ct != "heatmap" && ct != "textchart" && yX == 0) {
				$(".zaxis_ct, .legend_ct, .nonaxis_ct").addClass('disabled');
				$(".yaxis_ct, .xaxis_ct").removeClass('disabled');
			} else if ((ct == "sankey" || ct == "boxplot" || ct == "heatmap") && (xX == 0 || yX == 0 || lL == 0)) {
				$(".zaxis_ct, .nonaxis_ct").addClass('disabled');
				$(".xaxis_ct, .yaxis_ct, .legend_ct").removeClass('disabled');
			} else if ((ct == "bubble") && (xX == 0 || yX == 0)) {
				$(".zaxis_ct, .nonaxis_ct").addClass('disabled');
				$(".xaxis_ct, .yaxis_ct, .legend_ct").removeClass('disabled');
			} else if (ct == "wordcloud" && (xX == 0 || yX == 0)) {
				$(".zaxis_ct, .legend_ct, .nonaxis_ct").addClass('disabled');
				$(".xaxis_ct, .yaxis_ct").removeClass('disabled');
			} else if ((ct == "bartimeseries" || ct == "linetimeseries" || ct == "areatimeseries") && (xX == 0 || yX == 0)) {
				$(".zaxis_ct, .legend_ct, .nonaxis_ct").addClass('disabled');
				$(".xaxis_ct, .yaxis_ct").removeClass('disabled');
			} else if (ct == "textchart") {
				$(".xaxis_ct, .yaxis_ct, .zaxis_ct, .legend_ct, .nonaxis_ct").addClass('disabled');
			} else {
				$(".xaxis_ct, .yaxis_ct, .zaxis_ct, .legend_ct, .nonaxis_ct").removeClass('disabled');
			}
		} else if (ct == undefined) {
			$(".legend_ct, .nonaxis_ct").addClass('disabled');
		}

		if (ct == "metric" || ct == 'solidgauge' || ct == 'fullcirclegauge') {
			$(".xaxis_ct").addClass('hide');
			$(".xaxisattributes").empty();
		} else {
			$(".xaxis_ct").removeClass('hide');
		}
	}
	setcharttype = (e) => {
		$('.chartstyles').find('.collapse.in').collapse('hide');
		$('.charticons').find('.collapse.in').collapse('hide');
		$(".charttype").removeClass("active");
		$(e.currentTarget).addClass("active");
		var chartType = $(".charttype.active").attr("id");
		$('.attrmodal_tooltip').removeClass('hide');
		$("#dropdownreferenceline").parent().parent().show();
		$('.referencelinebox').addClass('hide');
		$("#dropdowntooltip").parent().parent().show();
		$("input#referenceLine[type='checkbox']").prop('checked', true);
		//$("#referenceLine").prop('checked',true);
		$("#referenceLine").prop('disabled', false);
		if(chartType=="world" || chartType=="countrymap"){
			$("input#gridLine").parents().eq(3).find(".toggle-title").text("Mono Color Legend");
		}else{
			$("input#gridLine").parents().eq(3).find(".toggle-title").text("Grid Line");
		}

		if (this.state.multifact != undefined && this.state.multifact == "true") {
			$('.metricdatefieldsAttributes').empty();
		}
		if (chartType == "combinationchart") {
			$('.yaxisattributes').sortable('disable');
		}
		else {
			$('.yaxisattributes').sortable();
		}
		/*Make Grouped Chart Type li Active*/
		if (chartType == "pie" || chartType == "3dpie" || chartType == "semicircle" || chartType == "donut" || chartType == "heatmap" || chartType === "funnel" || chartType === "pyramid" || chartType == "wordcloud" || chartType === 'boxplot' || chartType === "trellis" || chartType == 'multiaxisbar' || chartType == "indiamap" || chartType == "countrymap" || chartType == "world" || chartType == "srilankamap" || chartType == "leaflet" || chartType == "textchart" || chartType == "sankey" || chartType == "metric" || chartType == "sparkline" || chartType == "solidgauge" || chartType == "fullcirclegauge") {
			$('#referenceLine').prop('disabled', true);
			//$('#referenceLine').prop('checked',false);
			$("input#referenceLine[type='checkbox']").prop('checked', false);
			$(".xaxisattributes .singleliitems .aggregationtext").addClass("hidden");
		}
		if (chartType === 'bar' || chartType === 'multicolorbar' || chartType === 'multixbar' || chartType === 'invertedbar' || chartType === 'stackedbar' || chartType === 'invertedstackedbar' || chartType === 'multiaxisbar' || chartType === 'bartimeseries' || chartType === '3dbar' || chartType === 'trellis' || chartType === 'waterfall') {
			$('.charticons[data-toggle="collapse"]').not('[data-target="#barcharttypes"]').removeClass('active');
			$('.charticons[data-target="#barcharttypes"]').addClass('active');
			$(".xaxisattributes .singleliitems .aggregationtext").addClass("hidden");
		}
		else if (chartType === 'line' || chartType === "spline" || chartType === 'multixline' || chartType === 'invertedline' || chartType === 'linetimeseries') {
			$('.charticons[data-toggle="collapse"]').not('[data-target="#linecharttypes"]').removeClass('active');
			$('.charticons[data-target="#linecharttypes"]').addClass('active');
			$(".xaxisattributes .singleliitems .aggregationtext").addClass("hidden");
		}
		else if (chartType === 'pie' || chartType === 'donut' || chartType === 'semicircle' || chartType === '3dpie' || chartType == "polar" || chartType == "windrose" || chartType == "spiderweb") {
			$('.charticons[data-toggle="collapse"]').not('[data-target="#piecharttypes"]').removeClass('active');
			$('.charticons[data-target="#piecharttypes"]').addClass('active');
			$("#dropdownreferenceline").parent().parent().hide();
			$(".xaxisattributes .singleliitems .aggregationtext").addClass("hidden");
		}
		else if (chartType === 'area' || chartType === 'stackedarea' || chartType === 'multixarea' || chartType === 'invertedarea' || chartType === 'areatimeseries') {
			$('.charticons[data-toggle="collapse"]').not('[data-target="#areacharttypes"]').removeClass('active');
			$('.charticons[data-target="#areacharttypes"]').addClass('active');
			$(".xaxisattributes .singleliitems .aggregationtext").addClass("hidden");
		}
		else if (chartType === 'world' || chartType === 'countrymap' || chartType === 'srilankamap' || chartType === 'leaflet' || chartType == "indiamap") {
			$('.charticons[data-toggle="collapse"]').not('[data-target="#mapcharttypes"]').removeClass('active');
			$('.charticons[data-target="#mapcharttypes"]').addClass('active');
			$("#dropdownreferenceline").parent().parent().hide();
		}
		else if (chartType === 'solidgauge' || chartType === 'fullcirclegauge') {
			$('.charticons[data-toggle="collapse"]').not('[data-target="#gaugecharttypes"]').removeClass('active');
			$('.charticons[data-target="#gaugecharttypes"]').addClass('active');
			$(".xaxisattributes .singleliitems .aggregationtext").addClass("hidden");
		}
		else if (chartType === 'sankey' || chartType === 'sunburst' || chartType === 'wordcloud' || chartType === 'boxplot') {
			$('.charticons[data-toggle="collapse"]').not('[data-target="#advancedvisualizations"]').removeClass('active');
			$('.charticons[data-target="#advancedvisualizations"]').addClass('active');
			$(".xaxisattributes .singleliitems .aggregationtext").addClass("hidden");
		}
		else {
			$('.charticons[data-toggle="collapse"]').removeClass('active');
		}
		/*---Make Grouped Chart Type li Active*/

		if (chartType != "multixbar" && chartType != "multixline" && chartType != "multixarea" && chartType != "summarytable" && chartType != "heatmap" && chartType != "sunburst" && chartType != "leaflet" && chartType != "world" && chartType != "srilankamap" && chartType != "countrymap" && chartType != "indiamap") {
			if ($(".xaxisattributes .singleliitems").length > 1) {
				$(".xaxisattributes> div").not(':first').remove();
			}
			$("#dropdownyaxis").parent().find('.attraxisnames').text(lang["storyboard.axisy"]);
			$("#dropdownxaxis").parent().find('.attraxisnames').text(lang["storyboard.axisx"]);
			$("#dropdownlegend").parent().find('.attraxisnames').text(lang["storyboard.legendseries"]);
			$("#dropdownlegend").parent().parent().show();
			$('.settingscontent:nth(1)').removeClass('disabled');
			$('.settingscontent:last-child').removeClass('disabled');
		} else if (chartType === "sankey") {
			/*Limit Only 1 Y Value*/
			if ($('.legendAttributes .singleliitems').length == 0) {
				$('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
				$('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
				$('.birdmessagebuttons').removeClass('show');
				$('.birdmessagebuttons').addClass('hide');
				$('.birdmessage-center').addClass("message-top-in");
				$('.details').removeClass('show');
				$('.details').addClass('hide');
				$('.birdmessage h2').empty().append('Information');
				$('.birdmessage-info').empty().text(getMessage('BirdInformation33'));
			}
		}
		else if (chartType === "wordcloud") {
			if ($('.xaxisattributes li').attr("data-type") != "string") {
				$(".xaxisattributes div").remove();
			}
		}
		else if (chartType === "heatmap") {
			/*Limit Only 1 X Value*/
			if ($(".xaxisattributes .singleliitems").length > 1) {
				$(".xaxisattributes> div").not(':first').remove();
			}
			/*Limit Only 1 Y Value*/
			if ($('.legendAttributes .singleliitems').length == 0) {
				$('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
				$('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
				$('.birdmessagebuttons').removeClass('show');
				$('.birdmessagebuttons').addClass('hide');
				$('.birdmessage-center').addClass("message-top-in");
				$('.details').removeClass('show');
				$('.details').addClass('hide');
				$('.birdmessage h2').empty().append('Information');
				$('.birdmessage-info').empty().text(getMessage('BirdInformation26'));
			}
			/*if($(".yaxisattributes li").length>1){
				$(".yaxisattributes div").not(':first').remove();
			}*/
			if ($(".yaxisattributes li.singleliitems").length > 1) {
				$(".yaxisattributes>div").not(':first').remove();
			}
			$("#dropdownyaxis").parent().find('.attraxisnames').text(lang["storyboard.values"]);
			$("#dropdownlegend").parent().parent().show();
			$("#dropdownlegend").parent().find('.attraxisnames').text(lang["storyboard.grpby"]);
		} else if (chartType === "sunburst" || chartType === "boxplot") {
			/*Limit Only 1 Legend Value*/
			if ($('.legendAttributes .singleliitems').length == 0) {
				$('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
				$('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
				$('.birdmessagebuttons').removeClass('show');
				$('.birdmessagebuttons').addClass('hide');
				$('.birdmessage-center').addClass("message-top-in");
				$('.details').removeClass('show');
				$('.details').addClass('hide');
				$('.birdmessage h2').empty().append('Information');
				$('.birdmessage-info').empty().text(getMessage('BirdInformation33'));
				//$(e.currentTarget).removeClass("active");
			}
		} else if (chartType === "textchart") {
			//$('.settingscontent:nth(1)').addClass('disabled');
			$('.settingscontent:last-child').addClass('disabled');
			$("#dropdownreferenceline").parent().parent().hide();
		}/*else{
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(getMessage('BirdWarning46'));
            $("#dropdownlegend").parent().hide();
            $(".legendAttributes li").remove();
            $('.settingscontent:last-child').removeClass('disabled');
        }*/
		if (chartType === "world" || chartType === "countrymap" || chartType === "srilankamap" || chartType === "leaflet" || chartType === "indiamap") {

			if ($(".xaxisattributes .singleliitems").length > 2) {
				$(".xaxisattributes> div").last().remove();
			}
			if ($(".yaxisattributes .singleliitems").length > 0) {
				$(".yaxisattributes> div").not(':first').remove();
			}
			$(".spinner").hide();
			$(".gridspinner").hide();
			$("#dropdownlegend").parent().parent().hide();
			$(".legendAttributes li").remove();
			$(".singleliitems .aggregationtext").removeClass("hidden");
			$(".singleliitems .mapchartInfo").removeClass("hidden");
			$('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
			$('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
			$('.birdmessagebuttons').removeClass('show');
			$('.birdmessagebuttons').addClass('hide');
			$('.birdmessage-center').addClass("message-top-in");
			$('.details').removeClass('show');
			$('.details').addClass('hide');
			$('.birdmessage h2').empty().append('Information');
			$('.birdmessage-info').empty().text(getMessage('BirdInformation42'));
		}
		if (chartType === "bar" || chartType === "multicolorbar" || chartType === "invertedbar" || chartType === "stackedbar" || chartType === "invertedstackedbar" || chartType === "multiaxisbar" || chartType === "3dbar" || chartType === "multixbar" || chartType === "windrose" || chartType === "waterfall") {
			$("#chart" + this.props.chartnumber).removeClass("world");
			$("#chart" + this.props.chartnumber).removeClass("countrymap")
			$("#chart" + this.props.chartnumber).removeClass("srilankamap")
			$("#chart" + this.props.chartnumber).removeClass("leaflet")
			$("#chart" + this.props.chartnumber).removeClass("indiamap")
		}
		if (chartType === "textchart") {
			$("#dropdownreferenceline").parent().parent().hide();
		}
		if (chartType === "pie" || chartType === "wordcloud" || chartType === "piewithlegend" || chartType === "donut" || chartType === "semicircle" || chartType === "3dpie" || chartType === "funnel" || chartType === "pyramid") {
			/*if ($('.yaxisattributes .singleliitems').length > 1 && (chartType!="pie"  || chartType!="donut" || chartType!="semicircle" || chartType!="3dpie")){
				$(".spinner").hide();
				$(".gridspinner").hide();
				$('#main').prepend('<div class="alert alert-danger birderror alert-dismissable affix-top" style="margin:0 auto;width: 60%;top: 44px;position: fixed;z-index: 1;left: 20%;">Only One Y-Axis Attribute allowed for this Chart Type</div>');
					setTimeout(function() {
						$('.birderror').remove();	
					}, 3000);
				$(e.currentTarget).removeClass("active");
			};*/
			$("#dropdownlegend").parent().parent().hide();
			$(".legendAttributes li").remove();
			if ($('.yaxisattributes .singleliitems').length > 3 && (chartType == "pie" || chartType == "donut" || chartType == "semicircle" || chartType == "3dpie")) {
				$(".spinner").hide();
				$(".gridspinner").hide();
				$(".gridspinner").hide();
				$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
				$('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
				$('.birdmessagebuttons').removeClass('show');
				$('.birdmessagebuttons').addClass('hide');
				$('.birdmessage-center').addClass("message-top-in");
				$('.details').removeClass('show');
				$('.details').addClass('hide');
				$('.birdmessage h2').empty().append('Warning');
				$('.birdmessage-info').empty().text(getMessage('BirdWarning06'));
				/*$('#main').prepend('<div class="alert alert-danger birderror alert-dismissable affix-top" style="margin:0 auto;width: 60%;top: 44px;position: fixed;z-index: 1;left: 20%;">Only Three Y-Axis Attributes allowed for this Chart Type</div>');
					setTimeout(function() {
						$('.birderror').remove();	
					}, 3000);*/
				$(e.currentTarget).removeClass("active");
				$('.charticons[data-toggle="collapse"]').removeClass('active');
			};
			if ($('.legendAttributes .singleliitems').length > 0) {
				$(".spinner").hide();
				$(".gridspinner").hide();
				$(".gridspinner").hide();
				$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
				$('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
				$('.birdmessagebuttons').removeClass('show');
				$('.birdmessagebuttons').addClass('hide');
				$('.birdmessage-center').addClass("message-top-in");
				$('.details').removeClass('show');
				$('.details').addClass('hide');
				$('.birdmessage h2').empty().append('Warning');
				$('.birdmessage-info').empty().text(getMessage('BirdWarning09'));
				/*$('#main').prepend('<div class="alert alert-danger birderror alert-dismissable affix-top" style="margin:0 auto;width: 60%;top: 44px;position: fixed;z-index: 1;left: 20%;">This Chart Doesn\'t Require a Legend</div>');
					setTimeout(function() {
						$('.birderror').remove();	
					}, 3000);*/

				$(e.currentTarget).removeClass("active");
				$('.charticons[data-toggle="collapse"]').removeClass('active');
			};

		}
		if (chartType === 'sankey' || chartType === 'sunburst') {
			$('.number-format-container').addClass('hide');
		}
		else {
			$('.number-format-container').removeClass('hide');
		}
		if (chartType === "funnel" || chartType === "pyramid") {
			var isCustomMeasure = false;
			_.each($('.yaxisattributes .singleliitems'), function (e, i) {
				if ($(e).attr('data-type') == "customaggregatedfield") {
					isCustomMeasure = true;
				}
			})
			/*if(isCustomMeasure){
				$(e.currentTarget).removeClass("active")
				$(".spinner").hide();
				$(".gridspinner").hide();
				$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
				$('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
				$('.birdmessagebuttons').removeClass('show');
				$('.birdmessagebuttons').addClass('hide');
				$('.birdmessage-center').addClass("message-top-in");
				$('.details').removeClass('show');
				$('.details').addClass('hide');
				$('.birdmessage h2').empty().append('Warning');
				$('.birdmessage-info').empty().text(getMessage('BirdInformation32'));
			}*/
			if ($('.yaxisattributes .singleliitems').length > 1) {
				$(".spinner").hide();
				$(".gridspinner").hide();
				$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
				$('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
				$('.birdmessagebuttons').removeClass('show');
				$('.birdmessagebuttons').addClass('hide');
				$('.birdmessage-center').addClass("message-top-in");
				$('.details').removeClass('show');
				$('.details').addClass('hide');
				$('.birdmessage h2').empty().append('Warning');
				$('.birdmessage-info').empty().text(getMessage('BirdWarning08'));
				/*$('#main').prepend('<div class="alert alert-danger birderror alert-dismissable affix-top" style="margin:0 auto;width: 60%;top: 44px;position: fixed;z-index: 1;left: 20%;">Only One Y-Axis Attribute allowed for this Chart Type</div>');
					setTimeout(function() {
						$('.birderror').remove();	
					}, 3000);*/

				$(e.currentTarget).removeClass("active");
				$('.charticons[data-toggle="collapse"]').removeClass('active');
				$('#bar.charttype').addClass('active');
			};
			if ($('.legendAttributes .singleliitems').length > 0) {
				$(".spinner").hide();
				$(".gridspinner").hide();
				$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
				$('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
				$('.birdmessagebuttons').removeClass('show');
				$('.birdmessagebuttons').addClass('hide');
				$('.birdmessage-center').addClass("message-top-in");
				$('.details').removeClass('show');
				$('.details').addClass('hide');
				$('.birdmessage h2').empty().append('Warning');
				$('.birdmessage-info').empty().text(getMessage('BirdWarning09'));
				/*$('#main').prepend('<div class="alert alert-danger birderror alert-dismissable affix-top" style="margin:0 auto;width: 60%;top: 44px;position: fixed;z-index: 1;left: 20%;">This Chart Doesn\'t Require a Legend</div>');
					setTimeout(function() {
						$('.birderror').remove();	
					}, 3000);*/

				$("#legenditem").remove();
				$(e.currentTarget).removeClass("active");
				$('.charticons[data-toggle="collapse"]').removeClass('active');
			};

		}
		if (chartType == "metric" || chartType == 'solidgauge' || chartType == 'fullcirclegauge' || chartType == 'boxplot') {
			if ($('.yaxisattributes .singleliitems').length > 1) {
				$(".spinner").hide();
				$(".gridspinner").hide();
				$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
				$('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
				$('.birdmessagebuttons').removeClass('show');
				$('.birdmessagebuttons').addClass('hide');
				$('.birdmessage-center').addClass("message-top-in");
				$('.details').removeClass('show');
				$('.details').addClass('hide');
				$('.birdmessage h2').empty().append('Warning');
				$('.birdmessage-info').empty().text(getMessage('BirdWarning08'));
				$(e.currentTarget).removeClass("active");
				$('.charticons[data-toggle="collapse"]').removeClass('active');
			}
		}
		if (chartType != "metric" && chartType != 'solidgauge' && chartType != 'fullcirclegauge') {
			$('.mainattibutes .subhead span').first().text(lang["storyboard.legend"]);
			$('.commonattributes').removeClass('hide');
			$('.commonattributes>div:not(:first-child)').removeClass('hide');
			$('.xaxisattributes').parent().removeClass('disabled');
			$('.metricattributes').addClass('hide');
			$('.metricdatefields').addClass('hide');
			$('.metricprefix').addClass('hide');
			$('.metricsuffix').addClass('hide');
			$('.solidgaugemin').addClass('hide');
			$('.solidgaugemax').addClass('hide');
			$(".othersettingsdiv .col-xs-12:not(:first-child)").removeClass("hide");
			$(".btn#viewadvancedchartsettingsmodal").removeClass("hide");
		}
		else {
			if (chartType === "metric") {
				$(".metricdatefieldsAttributes").empty()
				$('.mainattibutes .subhead span').first().text(lang["storyboard.metricvalue"]);
				//$('.commonattributes').addClass('hide');
				$('.commonattributes>div:not(:first-child)').addClass('hide');
				$('.xaxisattributes').parent().addClass('disabled');
				$(".legendAttributes div").remove();
				$('.commonattributes').removeClass('hide');
				$('.metricattributes').removeClass('hide');
				$('.metricdatefields').removeClass('hide');
				$('.metricprefix').removeClass('hide');
				$('.metricsuffix').removeClass('hide');
				$('.solidgaugemin').addClass('hide');
				$('.solidgaugemax').addClass('hide');
				//	$(".othersettingsdiv .col-xs-12:not(:first-child)").addClass("hide");
				$(".othersettingsdiv .col-xs-12:not(:nth-child(-n + 2))").addClass("hide");
				$(".btn#viewadvancedchartsettingsmodal").addClass("hide");
				this.validatemetric(e);
				//$('.fullscreenmain').attr('data-gs-width',"3");
				//$('.fullscreenmain').attr('data-gs-height',"3");
			}
			else {
				if (chartType == 'solidgauge' || chartType == 'fullcirclegauge') {
					$(".legendAttributes div").remove();
					this.validatemetric(e);
				}
				$(".metricdatefieldsAttributes").empty()
				$('.mainattibutes .subhead span').first().text(lang["storyboard.metricvalue"]);
				//$('.commonattributes').addClass('hide');
				$('.commonattributes>div:not(:first-child)').addClass('hide');
				$('.xaxisattributes').parent().addClass('disabled');
				$('.commonattributes').removeClass('hide');
				$('.metricattributes').removeClass('hide');
				$('.metricdatefields').removeClass('hide');
				$('.solidgaugemin').removeClass('hide');
				$('.solidgaugemax').removeClass('hide');
				$('.metricprefix').addClass('hide');
				$('.metricsuffix').addClass('hide');
				$(".othersettingsdiv .col-xs-12:not(:first-child)").removeClass("hide");
				$(".btn#viewadvancedchartsettingsmodal").removeClass("hide");

			}
		}
		if (chartType != "metric") {
			$('.metriclayout').addClass('hide');
			if (chartType == 'solidgauge' || chartType == 'fullcirclegauge') {
				$(".metriclayout[layout-id='0']").removeClass("hide");
			}
		} else {
			$(".metric-layout").removeClass("active");
			$(".metric-layout[layout-id='0']").addClass("active");
			$('.metriclayout').removeClass("hide");
			$('.metriclayout[layout-id="1"]').addClass("hide");
			$("#metrictargetvalue").val("");
		}

		if (chartType === "multixbar" || chartType === "multixline" || chartType === "multixarea") {
			$("#dropdownlegend").parent().parent().hide();
			$(".legendAttributes li").remove();
		}
		if (chartType == "multicolorbar") {
			$('#legend.chart_settings').prop("checked", false);
		} else {
			$('#legend.chart_settings').prop("checked", true);
		}
		if (chartType === "wordcloud") {
			if ($('.xaxisattributes li').attr("data-type") != "string") {
				$(".xaxisattributes div").remove();

			}
		}
		if (chartType === "bubble") {
			/*Limit Only 1 Z Value*/
			if ($(".zaxisattributes li").length > 1) {
				$(".zaxisattributes div").not(':first').remove();
			}
			$('.zaxisattribute').removeClass('hide');
		} else if (chartType != "bubble") {
			$('.zaxisattribute').addClass('hide');
			$(".zaxisattributes div").remove();
		}
		if (chartType === "combinationchart") {
			$('.combinationchartslist').removeClass('hide');
		} else if (chartType != "combinationchart") {
			$('.combinationchartslist').addClass('hide');
		}
		if (chartType === 'solidgauge' || chartType === 'fullcirclegauge') {
			if ($(".customsolidgaugeattributes li").length > 1) {
				$(".customsolidgaugeattributes div").not(':first').remove();
			}
			$('.customsolidgaugeattributes').removeClass('hide');
		}
		else {
			$('.customsolidgaugeattributes').addClass("hide");
			$('.customsolidgaugeattributes div').remove();
		}
		if (chartType == 'sunburst' || chartType == 'sankey' || chartType == 'wordcloud' || chartType == 'boxplot' || chartType == 'sparkline') {
			$("#dropdowntooltip").parent().parent().hide();
			$("#dropdownreferenceline").parent().parent().hide();
			$(".tooltipattributes li").remove();
		}
		if (chartType === "heatmap" || chartType === "funnel" || chartType === "pyramid" || chartType == 'trellis' || chartType == 'multiaxisbar') {
			$("#dropdownreferenceline").parent().parent().hide();
		}
		if (chartType == "combinationchart") {
			var that = this;
			$(".combinationchartslistattributes").empty();
			var currentdatano = $('.combinationchartslist').last().attr('data-num-yaxis');
			_.each($(".yaxisattributes .singleliitems"), function (listObj) {
				var combinationchartlists = {};
				var columnName = $(listObj).attr("data-name");
				var columnDisplayName = $(listObj).attr("title");
				var columnDataType = $(listObj).attr("data-type");
				var charttype = $(listObj).attr("data-charttype");
				var selectedChartType = $(listObj).attr("data-selectedcharttype");
				var percentileValue = $(listObj).attr("percentile-value");
				var tableName = $(listObj).attr("table-name");
				var aggregationType = $(listObj).attr("data-agg");
				currentdatano = $(listObj).attr("data-num-yaxis");
				if (currentdatano === undefined) {
					currentdatano = 0;
				} else {
					currentdatano = parseInt(currentdatano);
				}
				var today = new Date();
				var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
				dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
				$(".combinationchartslistattributes").append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");

				var combinationChartsView = new CombinationChartsView({
					dynamicdiv: dynamicdiv,
					yaxiscolumnDisplayName: columnDisplayName,
					yaxiscolumnName: columnName,
					yaxiscolumnType: columnDataType,
					yaxisaggregation: "bar",
					yaxistableName: tableName,
					percentileValue: percentileValue,
					selectedChartType: "column",
					chartaxisposintion: 'left',
					aggregationType: aggregationType,
					id: currentdatano,
					multifact: that.state.multifact,
					multifactentities: that.state.multifactentities,
				});
				$(".combinationchartslistattributes").append($("#" + dynamicdiv));
			});

		}
		if (chartType == 'boxplot') {
			$("#dropdownlegend").parent().parent().show();
		}
		if (chartType != "world" && chartType != "countrymap" && chartType != "srilankamap" && chartType != "leaflet" && chartType != "indiamap") {
			$(".singleliitems .mapchartInfo").addClass("hidden");
			_.each($(".singleliitems ul"), function (value) {
				if ($(value).hasClass("mapchart")) {
					//   $(value).hide;
				}
			});
		}
		else {

		}
		//ShowAxis in Percent Feature Enable/Disable based on chartType
		if (chartType == "bar" || chartType == "invertedbar" || chartType == "line" || chartType == "invertedline" || chartType == "spline" || chartType == "area" || chartType == "stackedarea") {
			$(".axispercentcontainer").show("slow");
			//$(".axisinpercent[data-num="+chartDetails.chartnumber+"]").prop("checked",true);
		} else {
			$(".axispercentcontainer").hide("slow");
			$(".axisinpercent[data-num=" + this.props.chartnumber + "]").prop("checked", false);
		}

		//Conditional Formatting Feature Enable/Disable based on chartType
		if (chartType == "pie" || chartType == "donut" || chartType == "funnel" || chartType == "pyramid" || chartType == "sankey" || chartType == "wordcloud" || chartType == "boxplot" || chartType == "world" || chartType == "indiamap" || chartType == "countrymap" || chartType == "leaflet" || chartType == "srilankamap" || chartType == "multicolorbar" || chartType == "trellis" || chartType == "waterfall" || chartType == "heatmap" || chartType == "textchart" || chartType == "semicircle" || chartType == "3dpie" || chartType == "sparkline") {
			$(".conditionalshow").addClass("hide");
			$(".itemconditions>.attrabutesformatting").addClass("hide");
		} else {
			$(".conditionalshow").removeClass("hide")
		}
		var comparedaterangecharttpes =  ["bar", "stackedbar", "line", "spline", "area", "stackedarea"];
        if (comparedaterangecharttpes.indexOf(chartType)==-1){
            $(".comaredateshow").addClass("hide");
        }else{
			$(".comaredateshow").first().removeClass('hide')
		}
		//Initiate Disable other props when there is no axis fields selected
		this.axisUIValidation();

		//Condition for Chart Settings Narrative feature
		var narrativeAllowedChartTypes = ["bar", "stackedbar", "invertedbar", "invertedstackedbar", "multicolorbar", "3dbar", "line", "spline", "invertedline", "area", "stackedarea", "invertedarea", "spiderweb", "polar", "windrose"];

		if ($.inArray(chartType, narrativeAllowedChartTypes) >= 0) {
			//$("#chartNarrative").prop('checked',true);
			$("#chartNarrative").closest(".col-xs-12").removeClass("hide");
		} else {
			$("#chartNarrative").prop('checked', false);
			$("#chartNarrative").closest(".col-xs-12").addClass("hide");
		}

		//Trellis New Features
		if (chartType == "trellis") {
			$("#trellisDynamicScaling").closest(".col-xs-12").removeClass("hide");
			$("#trellisFitToWidth").closest(".col-xs-12").removeClass("hide");
		} else {
			$("#trellisDynamicScaling").prop('checked', false);
			$("#trellisDynamicScaling").closest(".col-xs-12").addClass("hide");

			$("#trellisFitToWidth").prop('checked', false);
			$("#trellisFitToWidth").closest(".col-xs-12").addClass("hide");
		}

		//Pareto feature
		var paretoAllowedChartTypes = ["bar"];
		if ($.inArray(chartType, paretoAllowedChartTypes) >= 0) {
			//$("#chartPareto").prop('checked',true);
			$("#chartPareto").closest(".col-xs-12").removeClass("hide");
		} else {
			$("#chartPareto").prop('checked', false);
			$("#chartPareto").closest(".col-xs-12").addClass("hide");
		}

		//Connect Null point feature
        var connectNullsAllowedChartTypes = ["line","spline","multixline","invertedline"];

        if ($.inArray(chartType, connectNullsAllowedChartTypes) >= 0) {
            //$("#chartNulls").prop('checked',true);
            $("#connectNulls").closest(".col-xs-12").removeClass("hide");
        } else {
            $("#connectNulls").prop('checked', false);
            $("#connectNulls").closest(".col-xs-12").addClass("hide");
        }

		//Heatmap Mono Color feature
		var HeatmapMonoColorAllowedChartTypes = ["heatmap"];
		if ($.inArray(chartType, HeatmapMonoColorAllowedChartTypes) >= 0) {
			//$("#chartHeatmapMonoColor").prop('checked',true);
			$("#chartHeatmapMonoColor").closest(".col-xs-12").removeClass("hide");
		} else {
			$("#chartHeatmapMonoColor").prop('checked', false);
			$("#chartHeatmapMonoColor").closest(".col-xs-12").addClass("hide");
		}
		//yAxis Scaling feature
		var yAxisAllowedChartTypes = ["bar", "stackedbar", "line", "spline", "area", "stackedarea", "invertedbar", "invertedstackedbar", "multicolorbar", "3dbar", "invertedline", "invertedarea","world","countrymap"];

		if ($.inArray(chartType, yAxisAllowedChartTypes) >= 0) {
			//$("#chartPareto").prop('checked',true);
			$(".y-axis-options").removeClass("hide");
		} else {
			$(".y-axis-options").addClass("hide");
		}
		//zoom x,y,xy allowed charts
		var zoomAllowedChartTypes = ["bar", "stackedbar", "line", "spline", "area", "stackedarea", "invertedbar", "invertedstackedbar", "multicolorbar", "3dbar", "invertedline", "invertedarea"];
		if ($.inArray(chartType, zoomAllowedChartTypes) >= 0) {
			$('#chartZoomSettings').closest(".col-xs-12").removeClass('hide');
		} else {
			$('#chartZoomSettings').closest(".col-xs-12").addClass('hide');
		}
		//Multiple Y-Axis Scaling feature (chartSplitYAxis)
		var chartSplitYAxisAllowedChartTypes = ["combinationchart"];
		if ($.inArray(chartType, chartSplitYAxisAllowedChartTypes) >= 0) {
			//$("#chartSplitYAxis").prop('checked',true);
			$("#chartSplitYAxis").closest(".col-xs-12").removeClass("hide");
		} else {
			$("#chartSplitYAxis").prop('checked', false);
			$("#chartSplitYAxis").closest(".col-xs-12").addClass("hide");
		}

		//Equal Y-Axis Scaling feature (chartEqualYAxisScale)
		var chartEqualYAxisScaleAllowedChartTypes = ["combinationchart"];
		if ($.inArray(chartType, chartEqualYAxisScaleAllowedChartTypes) >= 0) {
			//$("#chartEqualYAxisScale").prop('checked',true);
			$("#chartEqualYAxisScale").closest(".col-xs-12").removeClass("hide");
		} else {
			$("#chartEqualYAxisScale").prop('checked', false);
			$("#chartEqualYAxisScale").closest(".col-xs-12").addClass("hide");
		}

		if (chartType == "bartimeseries" || chartType == "linetimeseries" || chartType == "areatimeseries") {
			this.validatetimeseries(e);
		}
		if($('.yaxisattributes .singleliitems').first().hasClass("has-compare-date")){
			$("#chartNarrative").prop('checked', false);
       	 	$("#chartNarrative").closest(".col-xs-12").addClass("hide");
        	$("#chartPareto").prop('checked', false);
        	$("#chartPareto").closest(".col-xs-12").addClass("hide");
		}
	}
	validatemetric(e) {
		var yAxisArray = [];
		_.each($(".yaxisattributes .singleliitems"), function (listObj) {
			var yAxis = {};
			var columnDataType = $(listObj).attr("data-type");
			yAxis["dataType"] = columnDataType;
			yAxisArray.push(yAxis);
		});
		var chartType = $(".charttype.active").attr("id");
		if (yAxisArray[0] != undefined && yAxisArray[0].dataType == "custommeasurehierarchy" && chartType == "metric") {
			$(".spinner").hide();
			$(".gridspinner").hide();
			$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
			$('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
			$('.birdmessagebuttons').removeClass('show');
			$('.birdmessagebuttons').addClass('hide');
			$('.birdmessage-center').addClass("message-top-in");
			$('.details').removeClass('show');
			$('.details').addClass('hide');
			$('.birdmessage h2').empty().append('Warning');
			$('.birdmessage-info').empty().text(getMessage('BirdWarning90'));
			$(e.currentTarget).removeClass("active");
			$('.charticons[data-toggle="collapse"]').removeClass('active');
			//return;
		} else if (yAxisArray[0] != undefined && yAxisArray[0].dataType == "custommeasurehierarchy" && (chartType == "solidgauge" || chartType == "fullcirclegauge")) {
			$(".spinner").hide();
			$(".gridspinner").hide();
			$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
			$('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
			$('.birdmessagebuttons').removeClass('show');
			$('.birdmessagebuttons').addClass('hide');
			$('.birdmessage-center').addClass("message-top-in");
			$('.details').removeClass('show');
			$('.details').addClass('hide');
			$('.birdmessage h2').empty().append('Warning');
			$('.birdmessage-info').empty().text(getMessage('BirdWarning91'));
			$(e.currentTarget).removeClass("active");
			$('.charticons[data-toggle="collapse"]').removeClass('active');
			//return;
		}
	}
	validatetimeseries(e) {
		if ($('.xaxisattributes li').attr("data-type") != "date" && $('.xaxisattributes li').attr("data-type") != "datetime") {
			$(".spinner").hide();
			$(".gridspinner").hide();
			$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
			$('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
			$('.birdmessagebuttons').removeClass('show');
			$('.birdmessagebuttons').addClass('hide');
			$('.birdmessage-center').addClass("message-top-in");
			$('.details').removeClass('show');
			$('.details').addClass('hide');
			$('.birdmessage h2').empty().append('Warning');
			$('.birdmessage-info').empty().text(getMessage('BirdWarning10'));
			/*$('.fullscreenmain').prepend('<div class="alert alert-danger birderror alert-dismissable affix-top" style="margin:0 auto;width: 60%;top: 44px;position: fixed;z-index: 1;left: 20%;">Time Series Charts Requires Date Attribute on X-Axis</div>');
				setTimeout(function() {
					$('.birderror').remove();	
				}, 3000);*/
			$(e.currentTarget).removeClass("active");
			$('.charticons[data-toggle="collapse"]').removeClass('active');
			$('#bar.charttype').addClass('active');
		}
	}
	searchMeasures(e) {
		var $this = e.currentTarget;
		var valThis = $($this).val().toLowerCase();
		$(".itemname").parents(".attributesmeasures").each(function () {
			var text = $(this).attr("title").toLowerCase();
			(text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
		});
	}
	searchDimensions(e) {
		var $this = e.currentTarget;
		var valThis = $($this).val().toLowerCase();
		$(".itemname").parents(".attributesdimensions").each(function () {
			var text = $(this).text().toLowerCase();
			(text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
		});
	}
	//todo move all localstorage logic to utilty class
	applyAttributesAndDrawChart = (e) => {
		var that = this;
		var factatables = that.props.facttables;
		$(".streamingnow").removeClass("streamingnow");//remove class to select 15 mins range for Streaming charts
		sessionStorage.setItem("changesdone", 1);
		var currentCnartNum = that.props.chartnumber;
		$("#spinner" + currentCnartNum).show();
		$("#chartmetric" + currentCnartNum).remove();
		$(".maxanim1[data-num=" + currentCnartNum + "] .trend-stats-container").remove();
		$('.viewdatafromsinglechart').addClass('hide');
		$('.viewsinglechartsummary').addClass('hide');
		$('.viewdatafromchart').addClass('hide');
		$('.viewchartsummary').addClass('hide');
		$('.valueExceedErrorForRanking').addClass('hide');
		$('.negitiveValueErrorForRanking').addClass('hide');
		$('.decimalValueErrorForRanking').addClass('hide');
		if ($("#chart" + currentCnartNum).length > 0 && $("#chart" + currentCnartNum).next().hasClass("note-editor")) {
			$("#chart" + currentCnartNum).summernote('destroy');
		}
		var currentchartnum = $(e.currentTarget).attr("data-num");
		var currenttabid = $(e.currentTarget).attr("tab-id");
		var currenttabname = $(e.currentTarget).attr("tab-name");
		if ($("#decimalsC").prop("checked") == true) {
			var decimals = parseInt($("#decimalcustomvalue").val());
			var ebmi = "#errmsgmin";
			if (decimals > 5 || decimals == "" || isNaN(decimals)) {
				if (decimals != 0) {
					$(ebmi).html("Value must be between 0 and 5").show().fadeOut(3000);
					$(".spinner").hide();
					$(".gridspinner").hide();
					return;
				}
			}
		}
		if (ChartInfo.functions.getDateHistogramStatus(currentchartnum) === undefined) {
			ChartInfo.functions.setDateHistogramStatus(currentchartnum, false);
			ChartInfo.functions.setDateHistogramState(undefined, currentchartnum);
		}
		ChartInfo.functions.setSummaryTableDateHistogramStatus(currentchartnum, false);
		$(".viewchartfromchartdata.max-data-buttons[data-num=" + currentchartnum + "]").addClass("active");
		$(".viewchartdata.max-data-buttons[data-num=" + currentchartnum + "]").removeClass("active");
		if ($(window).width() < 650) {
			$('.cancelmodal').click();
		} else {
			// change functionality for larger screens
		}

		//Remove DrillDown All Filter and re-draw remaining charts.
		var that = this;
		if ($(".quickfiltercontainer>.btn-group").eq(0).length > 0) {
			$(".quickfiltercontainer").empty();
			$(".pie-menu").remove();
			$(".fullscreensample").removeClass("nopie");
			var drillfilterArray = [];
			ChartInfo.functions.setdrillfitlerArray(drillfilterArray);
			_.each($(".tileview[data-num!=" + currentchartnum + "]"), function (i) {
				var $this = i
				var chartnumber = $($this).attr("data-num");
				var applyObj = {};
				applyObj["pos"] = undefined;
				applyObj["currentchartnum"] = chartnumber;
				applyObj["applyattributes"] = "applyattributes";
				applyObj["dateHistogramInterval"] = undefined;
				applyObj["calculation"] = undefined;
				applyObj["isExcludeorIncludeFilters"] = undefined;
				applyObj["isChartSummaryData"] = undefined;
				applyObj["selecteddobycolumns"] = undefined;
				applyObj["filterArray"] = drillfilterArray;
				applyObj["navigatefrom"] = undefined;
				applyObj["isCustomparameterFilter"] = undefined;
				applyObj["tableInfo"] = undefined;
				PubSub.publish('applyfiltesandredrawchart', applyObj);
			});
		}

		var $this = e.currentTarget;
		var yaxisDataNum = $($this).attr("data-num-yaxis")
		var that = this;
		_.each($(".attributesmeasures"), function (measure, i) {
			$.each(that.state.measures, function (index, actualMeasure) {
				if ($(measure).attr("data-name") == actualMeasure.columnName) {
					actualMeasure.aggregation = $(measure).find('#dropdownaggregation').text().replace(/[^A-Z0-9]/ig, "");
					//that.state.measures.splice(index,1,actualMeasure);
				}
			});
		});
		savedReportCollection.updateMeasuresWithNewAggreations(this.state.measures);
		//	this.broker.trigger('updateMeasuresWithNewAggreations',this.state.measures);
		var chartType = $(".charttype.active").attr("id");
		if ((chartType == undefined || chartType != "metric") && chartType != "textchart") { $("#chart" + currentchartnum).css({ "margin-top": "", "overflow": "" }) }
		if (chartType === "multicolorbar" || chartType === "pie" || chartType === "3dpie" || chartType === "donut" || chartType === "semicircle" || chartType === "funnel" || chartType === "pyramid" || chartType === "trellis" || chartType === "sparkline" || chartType === "sankey" || chartType === "sunburst" || chartType === "wordcloud" || chartType === "boxplot" || chartType === "heatmap" || chartType === "textchart" || chartType === "doby" || chartType === "pivot" || chartType === "summarytable" || chartType == "predictionchart" || chartType === "leaflet" || chartType === "srilankamap" || chartType === "countrymap" || chartType == "indiamap" || chartType == "world") {
			$(".chartadvancedprops[data-num='chart" + currentchartnum + "']").hide();
		} else {
			$(".chartadvancedprops[data-num='chart" + currentchartnum + "']").show();
		}
		/*if($(".xaxisattributes li").length==0&&chartType=='metric' || $(".xaxisattributes li").length==0&&chartType=='solidgauge' || $(".xaxisattributes li").length==0&&chartType=='fullcirclegauge')
			{
			$(".xaxisdropdown").empty();
			$(".xaxisdropdown").append('<li class="xs-list-item"> <input class="form-control form-control-xaxisdropdown search-image" placeholder="Search" id="searchinput" type="text"></li>');
			_.each($(".attributesdimensions"), function(e,i){
				var $this = e;
				var xAxisDropDown = new XAxisDropDownView({
					broker : that.state.broker,
					columnDisplayName : $($this).attr('title'),
					columnName :$($this).attr("data-name"),
					dataType : $($this).attr("data-type"),
					tableName:$($this).attr("table-name")
				});
				if(i===0){
				$(".xaxisattributes").append(xAxisDropDown.el);
				$('.form-control-xaxisdropdown').click(function(e) {
					e.stopPropagation();
				});
			}
			});
			
			}*/
		var xaxisidentityinfo = "";
		var conditionalFormateArrayAxis = [];
		if ($('.legendAttributes .singleliitems').length > 0 && $(".xaxisattributes li").length == 0) {
			$(".spinner").hide();
			$(".gridspinner").hide();
			$('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
			$('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
			$('.birdmessagebuttons').removeClass('show');
			$('.birdmessagebuttons').addClass('hide');
			$('.birdmessage-center').addClass("message-top-in");
			$('.details').removeClass('show');
			$('.details').addClass('hide');
			$('.birdmessage h2').empty().append('Information');
			$('.birdmessage-info').empty().text(getMessage('BirdInformation14'));
			return false;
		}
		if ($(".xaxisattributes .singleliitems").length == 2 && chartType == "leaflet" || chartType == "world" || chartType == "srilankamap" || chartType == "countrymap" || chartType == "indiamap") {
			_.each($(".xaxisattributes .singleliitems"), function (listObj) {
				var xAxis = {};
				var xaxisidentity = $(listObj).attr("xaxisidentity");
				xaxisidentityinfo += $(listObj).attr("xaxisidentity") + ",";
			});
			xaxisidentityinfo = xaxisidentityinfo.substring(0, xaxisidentityinfo.length - 1);
		}
		if ((chartType === "sunburst" || chartType === "boxplot") && $('.legendAttributes .singleliitems').length == 0) {
			$("#spinner"+currentchartnum).hide();
			$('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
			$('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
			$('.birdmessagebuttons').removeClass('show');
			$('.birdmessagebuttons').addClass('hide');
			$('.birdmessage-center').addClass("message-top-in");
			$('.details').removeClass('show');
			$('.details').addClass('hide');
			$('.birdmessage h2').empty().append('Information');
			$('.birdmessage-info').empty().text(getMessage('BirdInformation33'));
			$(e.currentTarget).removeClass("active");
		} else if (chartType === "sankey" && $('.legendAttributes .singleliitems').length == 0) {
			$("#spinner"+currentchartnum).hide();
			$('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
			$('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
			$('.birdmessagebuttons').removeClass('show');
			$('.birdmessagebuttons').addClass('hide');
			$('.birdmessage-center').addClass("message-top-in");
			$('.details').removeClass('show');
			$('.details').addClass('hide');
			$('.birdmessage h2').empty().append('Information');
			$('.birdmessage-info').empty().text(getMessage('BirdInformation33'));
		}
		else if (chartType === "heatmap" && $('.legendAttributes .singleliitems').length == 0) {
			$("#spinner" + currentCnartNum).hide();
			$('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
			$('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
			$('.birdmessagebuttons').removeClass('show');
			$('.birdmessagebuttons').addClass('hide');
			$('.birdmessage-center').addClass("message-top-in");
			$('.details').removeClass('show');
			$('.details').addClass('hide');
			$('.birdmessage h2').empty().append('Information');
			$('.birdmessage-info').empty().text(getMessage('BirdInformation26'));
		} else if ((xaxisidentityinfo.indexOf("number") != -1) || xaxisidentityinfo == "latitude,latitude" || xaxisidentityinfo == "longitude,longitude" || xaxisidentityinfo == "longitude,string" || xaxisidentityinfo == "latitude,string" || xaxisidentityinfo == "string,longitude" || xaxisidentityinfo == "string,latitude" || xaxisidentityinfo == "string,string") {
			$('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
			$('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
			$('.birdmessagebuttons').removeClass('show');
			$('.birdmessagebuttons').addClass('hide');
			$('.birdmessage-center').addClass("message-top-in");
			$('.details').removeClass('show');
			$('.details').addClass('hide');
			$('.birdmessage h2').empty().append('Information');
			$('.birdmessage-info').empty().text(getMessage('BirdInformation43'));
		}
		else {
			//commented because view is notyet crated
			//	this.broker.trigger('updatefilter', sessionStorage.getItem("convertedtypes"));
			sessionStorage.setItem('isconvettedtypesapplied', true);
			sessionStorage.setItem('isconvettedvaluesapplied', true);
			sessionStorage.removeItem("customhierarchy" + currentchartnum);
			sessionStorage.removeItem("customhierarchy" + drilldownchartnumber);

			$("#spinnerchart" + this.props.chartnumber).show();
			var xAxis = {};
			var legends = {};
			var yAxisArray = [];
			var yAxisNamesArray = [];
			var xAxisArray = [];
			var tooltipsArray = [];
			var zAxisArray = [];
			var combinationChartslistArray = [];
			var customSolidGaugeArray = [];
			_.each($(".zaxisattributes .singleliitems"), function (listObj) {
				var zAxis = {};

				var columnName = $(listObj).attr("data-name");
				var columnDisplayName = $(listObj).attr("title");
				var columnDataType = $(listObj).attr("data-type");
				var aggregation = $(listObj).attr("data-agg");
				var percentileValue = $(listObj).find(".percentilebox").val();
				var tableName = $(listObj).attr("table-name");
				zAxis["columnName"] = columnName;
				zAxis["displayName"] = columnDisplayName;
				zAxis["dataType"] = columnDataType;
				zAxis["aggregation"] = aggregation;
				zAxis["percentileValue"] = percentileValue;
				zAxis["tableName"] = tableName;
				zAxisArray.push(zAxis);
			});
			_.each($(".customsolidgaugeattributes .singleliitems"), function (listObj) {
				var customSolidGauge = {};
				var columnName = $(listObj).attr("data-name");
				var columnDisplayName = $(listObj).attr("title");
				var columnDataType = $(listObj).attr("data-type");
				var aggregation = $(listObj).attr("data-agg");
				var percentileValue = $(listObj).attr("percentile-value");
				var tableName = $(listObj).attr("table-name");
				customSolidGauge["columnName"] = columnName;
				customSolidGauge["displayName"] = columnDisplayName;
				customSolidGauge["dataType"] = columnDataType;
				customSolidGauge["aggregation"] = aggregation;
				customSolidGauge["percentileValue"] = percentileValue;
				customSolidGauge["tableName"] = tableName;
				customSolidGaugeArray.push(customSolidGauge);
			});
			_.each($(".yaxisattributes .singleliitems"), function (listObj) {
				var yAxis = {};

				var columnName = $(listObj).attr("data-name");
				var columnDisplayName = $(listObj).attr("title");
				var columnDataType = $(listObj).attr("data-type");
				var aggregation = $(listObj).attr("data-agg");
				var percentileValue = $(listObj).attr("percentile-value");
				var tableName = $(listObj).attr("table-name");
				var dataNumYaxis = $(listObj).attr("data-num-yaxis");
				yAxis["columnName"] = columnName;
				yAxis["displayName"] = columnDisplayName;
				yAxis["dataType"] = columnDataType;
				yAxis["aggregation"] = aggregation;
				yAxis["percentileValue"] = percentileValue;
				yAxis["tableName"] = tableName;
				yAxis["uniqueid"] = dataNumYaxis;
				sessionStorage.setItem('yaxisDatacheck', columnName);
				yAxisArray.push(yAxis);
				yAxisNamesArray.push(columnName);
				var conditionalFormatArrayAxisObj = {};
				var conditionalFormatArray = [];
				// _.each($(".conditionalformat1[data-name='"+columnDisplayName+"']").find(".conditionalformat[data-name='"+columnDisplayName+"']"),function(val){
				_.each($(".conditionalformat1[data-num-yaxis='" + dataNumYaxis + "'][data-name='" + columnDisplayName + "']").find(".conditionalformat[data-name='" + columnDisplayName + "']"), function (val) {
					var conditionalFormatObj = {};
					var range = $(val).find('.conditionalformstselect :selected').text();
					var value = $(val).find(".conditionalfx").val();
					var color = $(val).find(".colorcodenumber").val();
					var conditionalFormatNum = $(val).parent().attr("data-num-yaxis");
					conditionalFormatObj["range"] = range;
					conditionalFormatObj["value"] = value;
					conditionalFormatObj["color"] = color;
					//if(dataNumYaxis == conditionalFormatNum){
					conditionalFormatArray.push(conditionalFormatObj);
					//}
				});
				if (conditionalFormatArray.length != 0) {
					conditionalFormatArrayAxisObj.name = columnDisplayName;
					conditionalFormatArrayAxisObj.conditions = conditionalFormatArray;
					conditionalFormatArrayAxisObj.dataNumYaxis = dataNumYaxis;
					conditionalFormatArrayAxisObj.agg = aggregation;
					conditionalFormateArrayAxis.push(conditionalFormatArrayAxisObj);

					/*if(yaxisDataNum == dataNumYaxis){
						conditionalFormateArrayAxis.push(conditionalFormatArrayAxisObj);
					}*/
				}

			});
			if (conditionalFormateArrayAxis == undefined || conditionalFormateArrayAxis.length == 0) {
				ChartInfo.functions.setConditionalFormatData(this.state.chartnumber, ChartInfo.functions.getConditionalFormatData(this.state.chartnumber));
			} else {
				/*var j;
				for(var i=conditionalFormateArrayAxis.length;i<ChartInfo.functions.getConditionalFormatData(this.state.chartnumber).length;i++){
					j=i;
					conditionalFormateArrayAxis.push(ChartInfo.functions.getConditionalFormatData(this.state.chartnumber)[j--]);
				}*/
				ChartInfo.functions.setConditionalFormatData(this.state.chartnumber, conditionalFormateArrayAxis);
			}
			var that = this;
			_.each($(".xaxisattributes .singleliitems"), function (listObj) {
				var xAxis = {};
				var columnName = $(listObj).attr("data-name");
				var columnDisplayName = $(listObj).attr("title");
				var columnDataType = $(listObj).attr("data-type");
				var xaxisidentity = $(listObj).attr("xaxisidentity");
				var tableName = $(listObj).attr("table-name");
				xAxis["columnName"] = columnName;
				xAxis["displayName"] = columnDisplayName;
				xAxis["dataType"] = columnDataType;
				xAxis["xaxisidentity"] = xaxisidentity;
				xAxis["tableName"] = tableName;
				var isChecked = $(listObj).find(".enabledrillthroughhiddenfilter").hasClass('active')
				xAxis["isHiddenMultixDrillthroughFilter"] = isChecked;
				sessionStorage.setItem('xaxisDatacheck', columnName);
				xAxisArray.push(xAxis);
				if (chartType != "multixbar") {
					var xAxisDetailsUpadte = [];
					xAxisDetailsUpadte["columnName"] = columnName;
					xAxisDetailsUpadte["dataType"] = columnDataType;
					xAxisDetailsUpadte["displayname"] = columnDisplayName;
					ChartInfo.functions.xaxisDrilldownState(that.state.chartnumber, xAxisDetailsUpadte);
				}
			});

			_.each($(".tooltipattributes .singleliitems"), function (listObj) {
				var tooltip = {};

				var columnName = $(listObj).attr("data-name");
				var columnDisplayName = $(listObj).attr("title");
				var columnDataType = $(listObj).attr("data-type");
				var aggregation = $(listObj).attr("data-agg");
				var percentileValue = $(listObj).attr("percentile-value");
				var tableName = $(listObj).attr("table-name");
				if (percentileValue == "" || percentileValue == undefined || percentileValue == null) {
					percentileValue = "25";
				}
				tooltip["columnName"] = columnName;
				tooltip["displayName"] = columnDisplayName;
				tooltip["dataType"] = columnDataType;
				tooltip["aggregation"] = aggregation;
				tooltip["percentileValue"] = percentileValue;
				tooltip["tableName"] = tableName;
				tooltipsArray.push(tooltip);
			});
			var chartaxisposintion = "";
			_.each($(".combinationchartslistattributes .singleliitems"), function (listObj) {
				var combinationchartlists = {};
				//var chartaxisposintion;
				var columnName = $(listObj).attr("data-name");
				var columnDisplayName = $(listObj).find(".itemname").text().trim();
				var columnDataType = $(listObj).attr("data-type");
				var charttype = $(listObj).attr("data-charttype");
				var selectedChartType = $(listObj).attr("data-selectedcharttype");
				var percentileValue = $(listObj).attr("percentile-value");
				var tableName = $(listObj).attr("table-name");
				var dataNumYaxis = $(listObj).attr("data-num-yaxis");
				if ($(listObj).attr('data-axisposition') == "") {
					chartaxisposintion = 'left';
				} else {
					chartaxisposintion = $(listObj).attr('data-axisposition');
				}
				combinationchartlists["columnName"] = columnName;
				combinationchartlists["displayName"] = columnDisplayName;
				combinationchartlists["dataType"] = columnDataType;
				combinationchartlists["chartType"] = charttype;
				combinationchartlists["percentileValue"] = percentileValue;
				combinationchartlists["tableName"] = tableName;
				combinationchartlists["uniqueid"] = dataNumYaxis;
				combinationchartlists["selectedChartType"] = selectedChartType;
				//combinationchartlists["chartyaxisposintion"]="";
				combinationchartlists["chartaxisposintion"] = chartaxisposintion;
				combinationChartslistArray.push(combinationchartlists);
			});

			if (combinationChartslistArray.length == 1) {
				combinationChartslistArray[0].chartyaxisposintion = chartaxisposintion;
				combinationChartslistArray[0].chartaxisposintion = chartaxisposintion;

			}

			var xaxisDatacheck = sessionStorage.getItem("xaxisDatacheck");
			var yaxisDatacheck = sessionStorage.getItem("yaxisDatacheck");
			var customFields = sessionStorage.getItem("customFields");
			var customFeildsArray = JSON.parse(customFields);
			var legendDatacheck = $(".legendAttributes li").attr("data-name");
			var counter = 0;
			this.applyReferenceLine();
			if ((this.state.multifact == "true")) {
				if (xAxisArray.length > 0) {
					//for checking Facts are plotted in X/Y axis
					var xaxisfact;
					_.each(xAxisArray, function (xaxis, i) {
						xaxisfact = xaxis.columnName.split(".").shift();
						_.each(factatables, function (fact, j) {
							if (fact == xaxisfact) {
								counter++;
							}
						});
					});

					//for comparing custom fields and  axis Names 
					if (counter <= 0) {
						if (customFields != undefined && customFields.length > 0) {
							var xcustomFieldCheck;
							_.each(customFeildsArray, function (i, j) {
								_.each(xAxisArray, function (xaxis, ix) {
									if (xaxis.columnName.split(".").shift() == i.name) {
										var usedFieldstest = i.usedFields;
										_.each(usedFieldstest, function (ii, jj) {
											xcustomFieldCheck = ii;
											xcustomFieldCheck = xcustomFieldCheck.split(".").shift();
											_.each(factatables, function (fact, j) {
												if (fact == xcustomFieldCheck) {
													counter++;
												}
											});
										});
									}
								});
							});
						}
					}
					//for comparing custom Range and X axis Name 
					if (counter <= 0) {
						var range = ChartInfo.functions.getRange();
						var xaxisRangeCheck;
						var customFieldCheckInRange;
						if (range != undefined && range != null) {
							_.each(range, function (object, index) {
								_.each(xAxisArray, function (xAxisobject, index) {
									var xaxiscolumn = xAxisobject.columnName
									if (object.name == xaxiscolumn) {
										xaxisRangeCheck = object["xAxisName"][0].columnName.split(".").shift();
										// for checking customfields in Customrange	
										if (customFields != undefined && customFields.length > 0) {
											_.each(customFeildsArray, function (i, j) {
												if (xaxisRangeCheck == i.name) {
													var usedFieldstest = i.usedFields;
													_.each(usedFieldstest, function (ii, jj) {
														customFieldCheckInRange = ii;
														customFieldCheckInRange = customFieldCheckInRange.split(".").shift();
													});
												}
											});
										}
									}
								});
							});
							_.each(factatables, function (fact, j) {
								if (fact == xaxisRangeCheck || fact == customFieldCheckInRange) {
									counter++;
								}
							});
						}
					}
					//					for comparing custom drilldownhierarchy and Y axis Name 
					if (counter <= 0) {
						var customdrilldownhierarchyArray = ChartInfo.functions.getcustomhierarchy();
						if (xAxisArray[0] != undefined && xAxisArray[0].columnName != undefined) {
							var hierarchy = $.grep(customdrilldownhierarchyArray, function (e) { return e.name == xAxisArray[0].columnName });
							if (hierarchy != undefined && hierarchy.length > 0) {
								var hierar = hierarchy[0].hierarchelist[0];
								var crusedname = hierar.columnName;

								var customFieldss = JSON.parse(sessionStorage.getItem("customFields"));
								if (customFieldss != null && customFieldss != undefined) {
									var customFieldObject = $.grep(customFieldss, function (e) { return e.name == crusedname });
									if (customFieldObject != undefined && customFieldObject.length > 0) {
										_.each(customFieldObject[0].usedFields, function (hierarchy) {
											var crusedname = hierarchy;
											var tablename = crusedname.split(".")[0];
											if (factatables.indexOf(tablename) != -1) {
												counter++;
											}
										});
									} else {
										var tablename = crusedname.split(".")[0];
										if (factatables.indexOf(tablename) != -1) {
											counter++;
										}
									}
								} else {
									var tablename = crusedname.split(".")[0];
									if (factatables.indexOf(tablename) != -1) {
										counter++;
									}
								}
							}
						}
					}
				}
				//checking facts in Yaxis fields
				var yaxisfact;
				var isvalidYaxis = true;
				_.each(yAxisArray, function (yaxis, i) {
					yaxisfact = yaxis.columnName.split(".").shift();
					_.each(factatables, function (fact, j) {
						if (fact == yaxisfact) {
							counter++;
						}
					});
				});
				//checking customField in Y axis field
				//	if(counter<=0){
				if (customFields != undefined && customFields.length > 0) {
					var ycustomFieldCheck;
					_.each(customFeildsArray, function (i, j) {
						_.each(yAxisArray, function (yaxis, iy) {
							if (yaxis.columnName.split(".").shift() == i.name) {
								var usedFieldstest = i.usedFields;
								if (usedFieldstest.length > 0) {
									_.each(usedFieldstest, function (ii, jj) {
										ycustomFieldCheck = ii;
										ycustomFieldCheck = ycustomFieldCheck.split(".").shift();
										_.each(factatables, function (fact, j) {
											if (fact == ycustomFieldCheck) {
												counter++;
											}
										});
									});
								} else {
									isvalidYaxis = false;
								}
							}
						});
					});
				}
				//	}
				//for comparing custom measure and Y axis Name 
				if (counter <= 0) {
					var cumstoMeasures = ChartInfo.functions.getCustomMeasureField();
					if (cumstoMeasures != undefined && cumstoMeasures.length > 0) {
						var customMeasureCheck;
						_.each(cumstoMeasures, function (i, j) {
							_.each(yAxisArray, function (yaxis, iy) {
								if (yaxis.columnName.split(".").shift() == i.name) {
									var usedcustommeasures = i.usedFields.split(",");
									_.each(usedcustommeasures, function (ii, jj) {
										customMeasureCheck = ii;
										// for checking customfields in Custommeasures
										if (customFields != undefined && customFields.length > 0) {
											var customFieldCheckInMeasure;
											_.each(customFeildsArray, function (i, j) {
												if (customMeasureCheck == i.name) {
													var usedFieldstest = i.usedFields;
													_.each(usedFieldstest, function (ii, jj) {
														customFieldCheckInMeasure = ii;
														customFieldCheckInMeasure = customFieldCheckInMeasure.split(".").shift();
													});
												}
											});
										}
										customMeasureCheck = customMeasureCheck.split(".").shift();
										_.each(factatables, function (fact, j) {
											if (fact == customMeasureCheck || fact == customFieldCheckInMeasure) {
												counter++;
											}
										});
									});
								}
							});
						});
					}
				}
				//for comparing custom measurehierarchy and Y axis Name 
				if (counter <= 0) {
					var custommeasurehierarchyArray = ChartInfo.functions.getMeasureHierarchy();
					var yaxismeasurehierarchycheck;
					var yaxismeasurehierarchycheckInmh;
					if (custommeasurehierarchyArray != undefined && custommeasurehierarchyArray != null) {
						_.each(custommeasurehierarchyArray, function (i, j) {
							_.each(yAxisArray, function (yaxis, iy) {
								var hierarchelist = custommeasurehierarchyArray[j].hierarchelist;
								_.each(hierarchelist, function (object, index) {
									if (custommeasurehierarchyArray[j].name == yaxis.columnName.split(".").shift()) {
										yaxismeasurehierarchycheck = hierarchelist[index].columnName.split(".").shift();
										// for checking custom measurehierarchy in custom measurehierarchy
										_.each(custommeasurehierarchyArray, function (ii, jj) {
											if (custommeasurehierarchyArray[jj].name == yaxismeasurehierarchycheck) {
												var hierarchelistmh = custommeasurehierarchyArray[jj].hierarchelist;
												_.each(hierarchelistmh, function (obj, ind) {
													yaxismeasurehierarchycheckInmh = hierarchelistmh[ind].columnName.split(".").shift();
												});
											}
										});
										_.each(factatables, function (fact, j) {
											if (fact == yaxismeasurehierarchycheck || fact == yaxismeasurehierarchycheckInmh) {
												counter++;
											}
										});
									}
								});
							});
						});
					}
				}

				if (!isvalidYaxis) {
					$(".spinner").hide();
					$(".gridspinner").hide();
					$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
					$('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
					$('.birdmessagebuttons').removeClass('show');
					$('.birdmessagebuttons').addClass('hide');
					$('.birdmessage-center').addClass("message-top-in");
					$('.details').removeClass('show');
					$('.details').addClass('hide');
					$('.birdmessage h2').empty().append('Warning');
					$('.birdmessage-info').empty().text(lang["storyboard.slcty-axisattr"]);
					return
				}


				if (counter <= 0 && (chartType == "metric" || chartType == "fullcirclegauge" || chartType == "solidgauge")) {
					var yFieldTables = [];
					var datefield = $(".metricitems").attr("data-name");
					if (datefield == undefined) {
						if (chartType == "metric") {
							counter++;
						} else {
							if (customSolidGaugeArray.length == 0) {
								counter++;
							} else {
								var customSolidGaugeTables = [];
								_.each(customSolidGaugeArray, function (k, l) {
									customSolidGaugeTables.push(k.tableName);
								});
								_.each(customSolidGaugeTables, function (table, i) {
									_.each(factatables, function (fact, j) {
										if (fact == table) {
											counter++;
										}
									});
								});
								if (counter <= 0) {
									yFieldTables = this.getYfieldtables(yAxisArray, customFields, customFeildsArray);
									if (yFieldTables != undefined) {
										_.each(customSolidGaugeTables, function (table, i) {
											_.each(yFieldTables, function (yFieldTable, j) {
												if (yFieldTable == table) {
													counter++;
												} else {
													counter = 0;
												}
											});
										});
									}
								}
							}
						}
					} else {
						var dateFieldTable = datefield.split(".")[0];
						_.each(factatables, function (fact, j) {
							if (fact == dateFieldTable) {
								counter++;
							}
						});
						if (counter <= 0) {
							var ycustomFieldCheck1;
							_.each(customFeildsArray, function (i, j) {
								if (datefield == i.name) {
									var usedFieldstest = i.usedFields;
									_.each(usedFieldstest, function (ii, jj) {
										ycustomFieldCheck1 = ii;
										ycustomFieldCheck1 = ycustomFieldCheck1.split(".").shift();
										_.each(factatables, function (fact, j) {
											if (fact == ycustomFieldCheck1) {
												counter++;
											}
										});
									});
								}
							});
						}

						if (counter <= 0 && chartType == "metric") {
							yFieldTables = this.getYfieldtables(yAxisArray, customFields, customFeildsArray);
							if (yFieldTables != undefined) {
								if ((yFieldTables.indexOf(dateFieldTable) > -1 || yFieldTables.indexOf(ycustomFieldCheck1) > -1)) {
									counter++;
								}
							}
						} else if (counter <= 0 && (chartType == "fullcirclegauge" || chartType == "solidgauge")) {
							if (customSolidGaugeArray.length == 0) {
								yFieldTables = this.getYfieldtables(yAxisArray, customFields, customFeildsArray);
								if (yFieldTables != undefined) {
									if ((yFieldTables.indexOf(dateFieldTable) > -1)) {
										counter++;
									}
								}
							} else {
								var customSolidGaugeTables = [];
								_.each(customSolidGaugeArray, function (k, l) {
									customSolidGaugeTables.push(k.tableName);
								});
								_.each(customSolidGaugeTables, function (table, i) {
									_.each(factatables, function (fact, j) {
										if (fact == table) {
											counter++;
										}
									});
								});
								if (counter <= 0) {
									yFieldTables = this.getYfieldtables(yAxisArray, customFields, customFeildsArray);
									var newCount = 0;
									if (yFieldTables != undefined) {
										if ((yFieldTables.indexOf(dateFieldTable) > -1)) {
											newCount++;
										}
										if (newCount > 0) {
											_.each(yFieldTables, function (table, i) {
												_.each(customSolidGaugeTables, function (gaugeTable, j) {
													if (gaugeTable == table) {
														counter++;
													} else {
														counter = 0;
													}
												});
											});
										}
									}
								}

							}

						}
					}
				}
				//For Checking Fact in ReferenceLine
				var reflineobj = ChartInfo.functions.getreferenceLineValues(this.state.chartnumber);
				if (counter <= 0 && reflineobj != undefined && reflineobj[0] != undefined && xAxisArray.length <= 0) {
					_.each(reflineobj, function (refline, i) {
						var refColumnName = reflineobj[i].columnname;
						var refTablename = refColumnName.split(".").shift();
						_.each(factatables, function (fact, j) {
							if (fact == refTablename) {
								counter++;
							}
						});
					});
				}
			}
			if (customFields != undefined && customFields.length > 0) {
				var customFeildsArray = JSON.parse(customFields);
				_.each(customFeildsArray, function (i, j) {
					if (xaxisDatacheck == i.name && i.usedFields.length == 1) {
						xaxisDatacheck = i.usedFields[0];
					}
					if (yaxisDatacheck == i.name && i.usedFields.length == 1) {
						yaxisDatacheck = i.usedFields[0];
					}
					if (legendDatacheck == i.name && i.usedFields.length == 1) {
						legendDatacheck = i.usedFields[0];
					}
				});
			}
			if ($(".yaxisattributes .singleliitems").length == 0 && chartType != "textchart") {
				$(".spinner").hide();
				$(".gridspinner").hide();
				$('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
				$('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
				$('.birdmessagebuttons').removeClass('show');
				$('.birdmessagebuttons').addClass('hide');
				$('.birdmessage-center').addClass("message-top-in");
				$('.details').removeClass('show');
				$('.details').addClass('hide');
				$('.birdmessage h2').empty().append('Information');
				$('.birdmessage-info').empty().text(getMessage('BirdInformation14'));
				return false;
			}
			if (chartType === "sankey" && (xaxisDatacheck === yaxisDatacheck)) {
				$(".spinner").hide();
				$(".gridspinner").hide();
				$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
				$('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
				$('.birdmessagebuttons').removeClass('show');
				$('.birdmessagebuttons').addClass('hide');
				$('.birdmessage-center').addClass("message-top-in");
				$('.details').removeClass('show');
				$('.details').addClass('hide');
				$('.birdmessage h2').empty().append('Warning');
				$('.birdmessage-info').empty().text(lang["storyboard.xyfieldsnotsame"]);
				return
			}
			if (chartType === "sankey" && (legendDatacheck === xaxisDatacheck)) {
				$(".spinner").hide();
				$(".gridspinner").hide();
				$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
				$('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
				$('.birdmessagebuttons').removeClass('show');
				$('.birdmessagebuttons').addClass('hide');
				$('.birdmessage-center').addClass("message-top-in");
				$('.details').removeClass('show');
				$('.details').addClass('hide');
				$('.birdmessage h2').empty().append('Warning');
				$('.birdmessage-info').empty().text(lang["storyboard.xlegendfieldsnotsame"]);
				return
			}
			if ((chartType === "sankey" || chartType === "wordcloud" || chartType === "boxplot") && $(".xaxisattributes li").length == 0) {
				$(".spinner").hide();
				$(".gridspinner").hide();
				$('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
				$('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
				$('.birdmessagebuttons').removeClass('show');
				$('.birdmessagebuttons').addClass('hide');
				$('.birdmessage-center').addClass("message-top-in");
				$('.details').removeClass('show');
				$('.details').addClass('hide');
				$('.birdmessage h2').empty().append('Information');
				$('.birdmessage-info').empty().text(getMessage('BirdInformation14'));
				return false;
			}
			var reference = {};
			var referencelineobj = ChartInfo.functions.getreferenceLineValues(this.state.chartnumber);
			/*var radioSelected=$('#referenceLine').prop('checked');
			if(radioSelected==true){
				var referenceName = $($this).parent().parent().find(".referencelinename").val();
				var referenceLabel = $($this).parent().parent().find(".referencelinelabel").val();
				var referenceValue = $($this).parent().parent().find(".referencelinevalue").val();
				var referenceStyle = $($this).parent().parent().find(".referencelinestyle").val();
				var referenceColor = $($this).parent().parent().find("#referencecolor").val();
				reference["referenceName"] =  referenceName;
				reference["referenceLabel"] =  referenceLabel;
				reference["referenceValue"] =  referenceValue;
				reference["referenceStyle"] =  referenceStyle;
				reference["referenceColor"] =  referenceColor;
			} else if(radioSelected==false){
				var referenceName = $($this).parent().parent().find(".referencelinename").val();
				var referenceLabel = $($this).parent().parent().find(".referencelinelabel").val();
				var referenceValue = $($this).parent().parent().find(".referencelinevalue").val();
				if(chartType!="heatmap" && chartType!="pyramid" && chartType!="boxplot" && chartType!="funnel"&& chartType!="wordcloud" &&chartType!="countrymap"&& chartType!="leaflet"&& chartType!="textchart"&& chartType!="sankey"&&chartType!="metric"&&chartType!="solidgauge"&&chartType!="fullcirclegauge"){
					if((referenceLabel!=""&&referenceLabel!=undefined)||(referenceValue!=""&&referenceValue!=undefined)){
						$(".spinner").hide();
						$(".gridspinner").hide();
						$(".gridspinner").hide();
						$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
						$('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
						$('.birdmessagebuttons').removeClass('show');
						$('.birdmessagebuttons').addClass('hide');
						$('.birdmessage-center').addClass("message-top-in");
						$('.details').removeClass('show');
						$('.details').addClass('hide');
						$('.birdmessage h2').empty().append('Warning');
						$('.birdmessage-info').empty().text(getMessage('BirdWarning78'));
						return false;
					}
				}
				var referenceName = ""
				var referenceLabel = ""
				var referenceValue = $($this).parent().parent().find(".referencelinevalue").val();
				var referenceStyle = $($this).parent().parent().find(".referencelinestyle").val();
				var referenceColor = $($this).parent().parent().find("#referencecolor").val();
				reference["referenceName"] =  referenceName;
				reference["referenceLabel"] =  referenceLabel;
				reference["referenceValue"] =  referenceValue;
				reference["referenceStyle"] =  referenceStyle;
				reference["referenceColor"] =  referenceColor;
				
			}*/
			var sortType = "";
			if ($('input[id="sortby1"]').prop("checked")) {
				sortType = $('input[id="sortby1"]').val()
			} if ($('input[id="sortby2"]').prop("checked")) {
				sortType = $('input[id="sortby2"]').val()
			}
			var sortingColumn = $('.sortingattributes .singleliitems').attr('data-name');
			var sortingColumnAggregation = $('.sortingattributes .singleliitems').attr('data-agg');
			var rankingColumn = $('.rankingitem option:selected').attr('data-name');
			var rankingValue = $('.collimit').val();
			var rankingColumnAggregation = $('.rankingitem option:selected').attr('data-agg');
			if (sortingColumnAggregation != undefined && sortingColumnAggregation != null) {
				sortingColumnAggregation = sortingColumnAggregation.replace(/[^A-Z0-9]/ig, "");
			}
			if (rankingValue > 999999999) {
				$('.valueExceedErrorForRanking').removeClass('hide');
				$("#spinnerchart" + this.props.chartnumber).hide();
				return;
			}
			if (rankingValue.indexOf('.') != -1) {
				$('.decimalValueErrorForRanking').removeClass('hide');
				$("#spinnerchart" + this.props.chartnumber).hide();
				return;
			}
			if (parseInt(rankingValue) < 1) {
				$('.negitiveValueErrorForRanking').removeClass('hide');
				$("#spinnerchart" + this.props.chartnumber).hide();
				return;
			}
			if (rankingColumnAggregation != undefined && rankingColumnAggregation != null) {
				rankingColumnAggregation = rankingColumnAggregation.replace(/[^A-Z0-9]/ig, "");
			}
			var values = {};
			var chartType = $(".charttype.active").attr("id");
			if ($(".charttype.active").attr("id") == "pivot") {
				chartType = $(".charttype.inactive").attr("id");
			}
			var chartDetails1 = JSON.parse(ChartInfo.functions.getChartDetails(this.props.chartnumber));
			var referencelineobj = ChartInfo.functions.getreferenceLineValues(this.state.chartnumber);
			var legendfilter = [];
			ChartInfo.functions.setLegendFilterobjForAllCharts(legendfilter);
			ChartInfo.functions.setLegendFilterobj(legendfilter, currentchartnum);
			sessionStorage.removeItem("drilldown" + currentchartnum);

			var numberformat = {};
			//for Abbrevations
			var countabbr = 0

			if ($("#abbrevationT").prop("checked") == true) {
				numberformat.abbrevation = "1T";
				countabbr++
			}
			if ($("#abbrevationB").prop("checked") == true) {
				if (countabbr > 0) {
					numberformat.abbrevation = numberformat.abbrevation + "," + "1B";
				}
				else {
					numberformat.abbrevation = "1B";
					countabbr++;
				}
			}
			if ($("#abbrevationM").prop("checked") == true) {
				if (countabbr > 0) {
					numberformat.abbrevation = numberformat.abbrevation + "," + "1M";
				}
				else {
					numberformat.abbrevation = "1M";
					countabbr++;
				}
			}
			if ($("#abbrevationK").prop("checked") == true) {
				if (countabbr > 0) {
					numberformat.abbrevation = numberformat.abbrevation + "," + "1K";
				}
				else {
					numberformat.abbrevation = "1K";
					countabbr++;
				}
			}
			//For Decimals
			if ($("#decimals0").prop("checked") == true) {
				numberformat.decimals = "0_1";
			} else if ($("#decimals00").prop("checked") == true) {
				numberformat.decimals = "0_2";
			} else if ($("#decimalsC").prop("checked") == true) {
				numberformat.decimals = $("#decimalcustomvalue").val();
			}
			//For Separators
			if ($("#thousands1").prop("checked") == true) {
				numberformat.seperator = $("#thousands1").val();
			} else if ($("#thousands2").prop("checked") == true) {
				numberformat.seperator = $("#thousands2").val();
			}

			var metricfont = {};
			metricfont.prefixfont = {};
			metricfont.suffixfont = {};
			metricfont.customcomparision = {};
			metricfont.customcomparision.prefixfont = {};
			metricfont.customcomparision.suffixfont = {};
			metricfont.customcomparision.achievement = {};
			metricfont.customcomparision.achievement.prefixfont = {};
			metricfont.customcomparision.achievement.suffixfont = {};

			metricfont.fontfamily = $("#metricfontfamily").val();
			metricfont.fontsize = $("#metricdatafontsize").val();
			metricfont.fontsizeparameter = $("#metricfontsizeparametertext").val();;
			if ($("input.metricfontweight").prop("checked") == true) { metricfont.fontweight = "bold" } else { metricfont.fontweight = "" };
			if ($("input.metricfontitalic").prop("checked") == true) { metricfont.fontitalic = "italic" } else { metricfont.fontitalic = "" };
			metricfont.fontcolor = $("#metricfontcolor").val();

			metricfont.prefixfont.fontfamily = $("#metricprefixfontfamily").val();
			metricfont.prefixfont.fontsize = $("#metricprefixdatafontsize").val();
			metricfont.prefixfont.fontsizeparameter = $("#metricprefixfontsizeparametertext").val();;
			if ($("input.metricprefixfontweight").prop("checked") == true) { metricfont.prefixfont.fontweight = "bold" } else { metricfont.prefixfont.fontweight = "" };
			if ($("input.metricprefixfontitalic").prop("checked") == true) { metricfont.prefixfont.fontitalic = "italic" } else { metricfont.prefixfont.fontitalic = "" };
			metricfont.prefixfont.fontcolor = $("#metricprefixfontcolor").val();

			metricfont.suffixfont.fontfamily = $("#metricsuffixfontfamily").val();
			metricfont.suffixfont.fontsize = $("#metricsuffixdatafontsize").val();
			metricfont.suffixfont.fontsizeparameter = $("#metricsuffixfontsizeparametertext").val();;
			if ($("input.metricsuffixfontweight").prop("checked") == true) { metricfont.suffixfont.fontweight = "bold" } else { metricfont.suffixfont.fontweight = "" };
			if ($("input.metricsuffixfontitalic").prop("checked") == true) { metricfont.suffixfont.fontitalic = "italic" } else { metricfont.suffixfont.fontitalic = "" };
			metricfont.suffixfont.fontcolor = $("#metricsuffixfontcolor").val();

			metricfont.layout = $(".metric-layout.active").attr("layout-id");
			metricfont.customcomparision.targetvalue = $("#metrictargetvalue").val();

			//Target Font
			metricfont.customcomparision.fontfamily = $("#metrictargetfontfamily").val();
			metricfont.customcomparision.fontsize = $("#metrictargetdatafontsize").val();
			metricfont.customcomparision.fontsizeparameter = $("#metrictargetfontsizeparametertext").val();;
			if ($("input.metrictargetfontweight").prop("checked") == true) { metricfont.customcomparision.fontweight = "bold" } else { metricfont.customcomparision.fontweight = "" };
			if ($("input.metrictargetfontitalic").prop("checked") == true) { metricfont.customcomparision.fontitalic = "italic" } else { metricfont.customcomparision.fontitalic = "" };
			metricfont.customcomparision.fontcolor = $("#metrictargetfontcolor").val();

			metricfont.customcomparision.prefixfont.value = $("#metrictargetprefix").val();
			metricfont.customcomparision.prefixfont.fontfamily = $("#metrictargetprefixfontfamily").val();
			metricfont.customcomparision.prefixfont.fontsize = $("#metrictargetprefixdatafontsize").val();
			metricfont.customcomparision.prefixfont.fontsizeparameter = $("#metrictargetprefixfontsizeparametertext").val();;
			if ($("input.metrictargetprefixfontweight").prop("checked") == true) { metricfont.customcomparision.prefixfont.fontweight = "bold" } else { metricfont.customcomparision.prefixfont.fontweight = "" };
			if ($("input.metrictargetprefixfontitalic").prop("checked") == true) { metricfont.customcomparision.prefixfont.fontitalic = "italic" } else { metricfont.customcomparision.prefixfont.fontitalic = "" };
			metricfont.customcomparision.prefixfont.fontcolor = $("#metrictargetprefixfontcolor").val();

			metricfont.customcomparision.suffixfont.value = $("#metrictargetsuffix").val();
			metricfont.customcomparision.suffixfont.fontfamily = $("#metrictargetsuffixfontfamily").val();
			metricfont.customcomparision.suffixfont.fontsize = $("#metrictargetsuffixdatafontsize").val();
			metricfont.customcomparision.suffixfont.fontsizeparameter = $("#metrictargetsuffixfontsizeparametertext").val();;
			if ($("input.metrictargetsuffixfontweight").prop("checked") == true) { metricfont.customcomparision.suffixfont.fontweight = "bold" } else { metricfont.customcomparision.suffixfont.fontweight = "" };
			if ($("input.metrictargetsuffixfontitalic").prop("checked") == true) { metricfont.customcomparision.suffixfont.fontitalic = "italic" } else { metricfont.customcomparision.suffixfont.fontitalic = "" };
			metricfont.customcomparision.suffixfont.fontcolor = $("#metrictargetsuffixfontcolor").val();

			//Achievement Font
			metricfont.customcomparision.achievement.fontfamily = $("#metricachievementfontfamily").val();
			metricfont.customcomparision.achievement.fontsize = $("#metricachievementdatafontsize").val();
			metricfont.customcomparision.achievement.fontsizeparameter = $("#metricachievementfontsizeparametertext").val();;
			if ($("input.metricachievementfontweight").prop("checked") == true) { metricfont.customcomparision.achievement.fontweight = "bold" } else { metricfont.customcomparision.achievement.fontweight = "" };
			if ($("input.metricachievementfontitalic").prop("checked") == true) { metricfont.customcomparision.achievement.fontitalic = "italic" } else { metricfont.customcomparision.achievement.fontitalic = "" };
			metricfont.customcomparision.achievement.fontcolor = $("#metricachievementfontcolor").val();

			metricfont.customcomparision.achievement.prefixfont.value = $("#metricachievementprefix").val();
			metricfont.customcomparision.achievement.prefixfont.fontfamily = $("#metricachievementprefixfontfamily").val();
			metricfont.customcomparision.achievement.prefixfont.fontsize = $("#metricachievementprefixdatafontsize").val();
			metricfont.customcomparision.achievement.prefixfont.fontsizeparameter = $("#metricachievementprefixfontsizeparametertext").val();;
			if ($("input.metricachievementprefixfontweight").prop("checked") == true) { metricfont.customcomparision.achievement.prefixfont.fontweight = "bold" } else { metricfont.customcomparision.achievement.prefixfont.fontweight = "" };
			if ($("input.metricachievementprefixfontitalic").prop("checked") == true) { metricfont.customcomparision.achievement.prefixfont.fontitalic = "italic" } else { metricfont.customcomparision.achievement.prefixfont.fontitalic = "" };
			metricfont.customcomparision.achievement.prefixfont.fontcolor = $("#metricachievementprefixfontcolor").val();

			metricfont.customcomparision.achievement.suffixfont.value = $("#metricachievementsuffix").val();
			metricfont.customcomparision.achievement.suffixfont.fontfamily = $("#metricachievementsuffixfontfamily").val();
			metricfont.customcomparision.achievement.suffixfont.fontsize = $("#metricachievementsuffixdatafontsize").val();
			metricfont.customcomparision.achievement.suffixfont.fontsizeparameter = $("#metricachievementsuffixfontsizeparametertext").val();;
			if ($("input.metricachievementsuffixfontweight").prop("checked") == true) { metricfont.customcomparision.achievement.suffixfont.fontweight = "bold" } else { metricfont.customcomparision.achievement.suffixfont.fontweight = "" };
			if ($("input.metricachievementsuffixfontitalic").prop("checked") == true) { metricfont.customcomparision.achievement.suffixfont.fontitalic = "italic" } else { metricfont.customcomparision.achievement.suffixfont.fontitalic = "" };
			
			var comparedaterangecharttpes =  ["bar", "stackedbar", "line", "spline", "area", "stackedarea"];
			metricfont.customcomparision.achievement.suffixfont.fontcolor = $("#metricachievementsuffixfontcolor").val();
			let yaxisMatChedCompare = false;
			let compareDateRangeObj = comparedaterangecharttpes.indexOf(chartType)!=-1?chartDetails1["compareDateRangeObj"]:null;
				if(Array.isArray(yAxisArray)&&compareDateRangeObj!=undefined&&compareDateRangeObj!=null&&compareDateRangeObj["0"]!=null){
					let yjsObj = compareDateRangeObj["0"]; 
				 _.each(yAxisArray,function(val,index){
					if(val.columnName!=undefined&&val.columnName!=null&&yjsObj.hasOwnProperty(val.columnName)){
						yaxisMatChedCompare = true;
					}
				  });
				}
				if(!yaxisMatChedCompare&&compareDateRangeObj!=undefined&&compareDateRangeObj!=null&&compareDateRangeObj["0"]!=null){
					compareDateRangeObj = null;
				}
			values["tabId"] = currenttabid;
			values["tabName"] = currenttabname;
			values["xAxis"] = xAxisArray;
			values["yAxis"] = yAxisArray;
			values["zAxis"] = zAxisArray;
			values["combinationchartslist"] = combinationChartslistArray;
			values["compareDateRangeObj"]= compareDateRangeObj;
			values["customSolidGauge"] = customSolidGaugeArray;
			values["tooltip"] = tooltipsArray;
			values["reference"] = referencelineobj;
			values["reporttitle"] = chartDetails1["reporttitle"];
			values["chartSettings"] = chartDetails1["chartSettings"]
			values["sortingColumn"] = sortingColumn;
			values["sortType"] = sortType;
			values["sortingColumnAggregation"] = sortingColumnAggregation;
			values["rankingColumn"] = rankingColumn;
			values["orderType"] = $('.ordertype').val();
			values["rankingColumnAggregation"] = rankingColumnAggregation;
			values["colLimit"] = $('.collimit').val();
			values["metricdatetype"] = $(".metriccoparision").val();
			values["metricprefix"] = $("#metricprefix").val();
			values["metricsuffix"] = $("#metricsuffix").val();
			values["numberformat"] = numberformat;
			values["metricfont"] = metricfont;
			values["solidgaugemin"] = $("#solidgaugemin").val();
			values["solidgaugemax"] = $("#solidgaugemax").val();
			values["metricdatefield"] = $(".metricitems").attr("data-name");
			values["metricdatefielddisplay"] = $(".metricitems").attr("title");
			values["richtextcontent"] = chartDetails1['richtextcontent'];
			values["reportcolortheme"] = chartDetails1['reportcolortheme'];
			values["filtersadded"] = chartDetails1["filtersadded"];
			values["summarytablecolumns"] = chartDetails1['summarytablecolumns'];
			values["summarytablegroupbys"] = chartDetails1['summarytablegroupbys'];
			values["summarytablevalues"] = chartDetails1['summarytablevalues'];
			values["colorThemeIndex"] = $('.selectlegend i.legendactive').attr('theme-index');
			values["percentileValue"] = $('.percentilebox').val();
			values['predictionArray'] = chartDetails1['predictionArray'];
			values['trendLine'] = chartDetails1['trendLine'];
			values['periodsvalue'] = chartDetails1['periodsvalue'];
			values['xaxisName'] = chartDetails1['xaxisName']
			values['drillDownFilter'] = chartDetails1["drillDownFilter"];
			values['drillDownAllFilter'] = chartDetails1['drillDownAllFilter']
			values['customfieldFilterObject'] = chartDetails1['customfieldFilterObject'];
			values['legendfilter'] = chartDetails1['legendfilter'];
			values['legendfilter'] = chartDetails1['legendfilter'];
			values["legendfilterall"] = chartDetails1["legendfilterall"];
            values["searchText"] = chartDetails1["searchText"];
			//Get TrendStats for Narrative Content
			if (chartDetails1 != undefined && chartDetails1.chartSettings != undefined && chartDetails1.chartSettings.chartNarrative != undefined && chartDetails1.chartSettings.chartNarrative == true) {
				values['trendLine'] = "trendstats"
			}

			if (chartType == undefined) {
				var oldChartType = JSON.parse(ChartInfo.functions.getChartDetails(this.props.chartnumber)).oldcharttype;
				if (oldChartType != undefined) {
					chartType = oldChartType;
				} else {
					chartType = "bar";
				}
			}
			if (values["orderType"] == "") {
				$('.ordertype option[name=empty]').prop('selected', true);
			}
			values["chartType"] = chartType;


			if (this.state.multifact == "true") {
				var that = this;
				var selecteddata = [];
				var yaxistablenames = [];
				_.each(yAxisNamesArray, function (yname) {
					if (that.state.facttables.indexOf(yname.split(".")[0]) != -1) {
						yaxistablenames.push(yname.split(".")[0]);
					}
				});
				$.each(this.state.dimensions, function (index1, filter) {
					if (selecteddata.length <= 10) {
						var name = filter.columnName;
						var temp = {};
						temp["actualnames"] = filter.columnName;
						temp["displaynames"] = filter.columnDisplayName;
						temp["datatype"] = filter.tableDisplayType;
						var disable = ChartInfo.functions.getAllowedEntitiesForInstantFilters(JSON.parse(that.state.multifactentities), name, undefined, yAxisNamesArray, undefined, that.state.facttables, "xdropdown");
						if (!disable && selecteddata.length <= 9) {
							selecteddata.push(temp);
						} else if (!disable && selecteddata.length > 9 && selecteddata.length <= 10) {
							_.each(that.state.facttables, function (fact) {
								if (fact == name.split(".")[0] && (yaxistablenames.indexOf(fact) >= 0 || yaxistablenames.length == 0)) {
									selecteddata.push(temp);
								}
							});
						}
					}
				});
				values["dobyselectedcolumns"] = selecteddata;
			} else {
				values["dobyselectedcolumns"] = this.state.dobycolumns;
			}
			values["dobyrowlimit"] = chartDetails1["dobyrowlimit"];
			if ($(".legendAttributes li").attr("data-name") != undefined) {
				_.each($(".legendAttributes li"), function (listObj) {
					var columnName = $(listObj).attr("data-name");
					var columnDisplayName = $(listObj).attr("title");
					var columnDataType = $(listObj).attr("data-type");
					var aggregation = $(listObj).attr("data-agg");
					var tableName = $(listObj).attr("table-name");
					legends["columnName"] = columnName;
					legends["displayName"] = columnDisplayName;
					legends["dataType"] = columnDataType;
					legends["aggregation"] = aggregation;
					legends["tableName"] = tableName;
				});
				values["legend"] = legends;
			}
			/*----- Setting Chart Settings values*/
			var chartSettings = values["chartSettings"];
			if (chartSettings == undefined) {
				chartSettings = {};
			}
			_.each($('.chart_settings'), function (listObject) {
				if (listObject.localName == "select") {
					chartSettings[$(listObject).attr('id')] = $(listObject).val();
				} else {
					if ($(listObject).prop('checked') == true) {
						chartSettings[$(listObject).attr('id')] = true;
					} else {
						chartSettings[$(listObject).attr('id')] = false;
					}
				}
			});
			if ($("#yaxisscaleminvalue").length > 0 && $("#yaxisscalemaxvalue").length > 0) {
				var min = $("#yaxisscaleminvalue").val(),
					max = $("#yaxisscalemaxvalue").val();
				chartSettings["yAxis"] = { min: min, max: max }
			}
			if (chartType != "multicolorbar") {
				//chartSettings['legend']=true;
			}
			if (numberformat != undefined) {
				chartSettings["numberformat"] = numberformat;
			}
			//Condition for Chart Settings Narrative feature
			var narrativeAllowedChartTypes = ["bar", "stackedbar", "invertedbar", "invertedstackedbar", "multicolorbar", "3dbar", "line", "spline", "invertedline", "area", "stackedarea", "invertedarea", "spiderweb", "polar", "windrose"];

			if ($.inArray(chartType, narrativeAllowedChartTypes) >= 0) {
				//$("#chartNarrative").prop('checked',true);
				$("#chartNarrative").closest(".col-xs-12").removeClass("hide");
			} else {
				$("#chartNarrative").prop('checked', false);
				$("#chartNarrative").closest(".col-xs-12").addClass("hide");
			}
			//Trellis New Features
			if (chartType == "trellis") {
				$("#trellisDynamicScaling").closest(".col-xs-12").removeClass("hide");
				$("#trellisFitToWidth").closest(".col-xs-12").removeClass("hide");
			} else {
				$("#trellisDynamicScaling").prop('checked', false);
				$("#trellisDynamicScaling").closest(".col-xs-12").addClass("hide");

				$("#trellisFitToWidth").prop('checked', false);
				$("#trellisFitToWidth").closest(".col-xs-12").addClass("hide");
			}

			//Pareto feature
			var paretoAllowedChartTypes = ["bar"];

			if ($.inArray(chartType, paretoAllowedChartTypes) >= 0) {
				//$("#chartPareto").prop('checked',true);
				$("#chartPareto").closest(".col-xs-12").removeClass("hide");
			} else {
				$("#chartPareto").prop('checked', false);
				$("#chartPareto").closest(".col-xs-12").addClass("hide");
			}
			if($('.yaxisattributes .singleliitems').first().hasClass("has-compare-date")){
				$("#chartNarrative").prop('checked', false);
				$("#chartNarrative").closest(".col-xs-12").addClass("hide");
				$("#chartPareto").prop('checked', false);
				$("#chartPareto").closest(".col-xs-12").addClass("hide");
			}

			//Connect Null point feature
			var connectNullsAllowedChartTypes = ["line","spline","multixline","invertedline"];

			if ($.inArray(chartType, connectNullsAllowedChartTypes) >= 0) {
				//$("#chartNulls").prop('checked',true);
				$("#connectNulls").closest(".col-xs-12").removeClass("hide");
			} else {
				$("#connectNulls").prop('checked', false);
				$("#connectNulls").closest(".col-xs-12").addClass("hide");
			}
			
			//Heatmap Mono Color feature
			var HeatmapMonoColorAllowedChartTypes = ["heatmap"];
			if ($.inArray(chartType, HeatmapMonoColorAllowedChartTypes) >= 0) {
				//$("#chartHeatmapMonoColor").prop('checked',true);
				$("#chartHeatmapMonoColor").closest(".col-xs-12").removeClass("hide");
			} else {
				$("#chartHeatmapMonoColor").prop('checked', false);
				$("#chartHeatmapMonoColor").closest(".col-xs-12").addClass("hide");
			}
			//yAxis Scaling feature
			var yAxisAllowedChartTypes = ["bar", "stackedbar", "line", "spline", "area", "stackedarea", "invertedbar", "invertedstackedbar", "multicolorbar", "3dbar", "invertedline", "invertedarea","world","countrymap"];

			if ($.inArray(chartType, yAxisAllowedChartTypes) >= 0) {
				$(".y-axis-options").removeClass("hide");
			} else {
				$(".y-axis-options").addClass("hide");
			}
			//zoom x,y,xy allowed charts
			var zoomAllowedChartTypes = ["bar", "stackedbar", "line", "spline", "area", "stackedarea", "invertedbar", "invertedstackedbar", "multicolorbar", "3dbar", "invertedline", "invertedarea"];
			if ($.inArray(chartType, zoomAllowedChartTypes) >= 0) {
				$('#chartZoomSettings').closest(".col-xs-12").removeClass('hide');
			} else {
				$('#chartZoomSettings').closest(".col-xs-12").addClass('hide');
			}
			//Multiple Y-Axis Scaling feature (chartSplitYAxis)
			var chartSplitYAxisAllowedChartTypes = ["combinationchart"];
			if ($.inArray(chartType, chartSplitYAxisAllowedChartTypes) >= 0) {
				//$("#chartSplitYAxis").prop('checked',true);
				$("#chartSplitYAxis").closest(".col-xs-12").removeClass("hide");
			} else {
				$("#chartSplitYAxis").prop('checked', false);
				$("#chartSplitYAxis").closest(".col-xs-12").addClass("hide");
			}

			//Equal Y-Axis Scaling feature (chartEqualYAxisScale)
			var chartEqualYAxisScaleAllowedChartTypes = ["combinationchart"];
			if ($.inArray(chartType, chartEqualYAxisScaleAllowedChartTypes) >= 0) {
				//$("#chartEqualYAxisScale").prop('checked',true);
				$("#chartEqualYAxisScale").closest(".col-xs-12").removeClass("hide");
			} else {
				$("#chartEqualYAxisScale").prop('checked', false);
				$("#chartEqualYAxisScale").closest(".col-xs-12").addClass("hide");
			}

			values["chartSettings"] = chartSettings;
			/*----- Setting 'ShowAxis in Percent' feature*/
			var showaxispercentval = false;
			showaxispercentval = $(".axisinpercent").is(":checked");
			//if(isshowaxisPercent){showaxispercentval=1} 
			values["chartSettings"].showaxisinpercent = showaxispercentval;
			var mingauge = parseFloat(values["solidgaugemin"]);
			var maxguage = parseFloat(values["solidgaugemax"]);
			var ebmi = "#errmsgsolidgaugemin"
			var ebmx = "#errmsgsolidgaugemax"
			if (maxguage <= mingauge) {
				$(ebmi).html("");
				$(ebmx).html("");
				$(ebmx).html("MAX value must be greater than " + mingauge + "").show();
				$(".spinner").hide();
				$(".gridspinner").hide();
				return;
			}
			if (this.state.multifact == "true") {
				if (counter <= 0 && xAxisArray.length > 0) {
					this.showValidation();
					return;
				} else if (counter <= 0 && (chartType == "metric" || chartType == "solidgauge" || chartType == "fullcirclegauge")) {
					this.showValidation();
					return;
				} else if (counter <= 0 && reflineobj != undefined && reflineobj[0] != undefined && xAxisArray.length <= 0) {
					this.showValidation();
					return;
				}
				else {
					ChartInfo.functions.updateChartDetails(this.props.chartnumber, JSON.stringify(values));
				}
			} else {
				ChartInfo.functions.updateChartDetails(this.props.chartnumber, JSON.stringify(values));
			}
			if(chartType=="textchart"){
				$('.showtrending[data-num=' + $($this).attr('data-num') + ']').addClass('hide');
				$('.richtextcontrolschart' + $($this).attr('data-num') + '').show();
			}else{
				$('.showtrending[data-num=' + $($this).attr('data-num') + ']').removeClass('remove');
				$('.richtextcontrolschart' + $($this).attr('data-num') + '').hide();
			}
			$('.showtrending[data-num=' + $($this).attr('data-num') + ']').removeClass('active');
			$('.trendtype[data-num=' + $($this).attr('data-num') + ']').removeClass('showactive');
			$('.singlereport_trendtype[data-num=' + $($this).attr('data-num') + ']').removeClass('showactive');
			$('.boc-maxscreen-left-item[data-num=' + $($this).attr('data-num') + ']').removeClass("showactive");
			$('.boc-maxscreen-left-item[data-num=' + $($this).attr('data-num') + '].previewChart').addClass('showactive');
			$('.boc-maxscreen-left-item[data-num=' + $($this).attr('data-num') + ']#showchart').addClass('showactive');
			if (ChartInfo.functions.getIsFiltersAdded() == true) {
				ChartInfo.functions.getFilterArray();
			} else {
				ChartInfo.functions.getFilters(true);
			}
			if (values["chartType"].indexOf("multi") >= 0 || values["chartType"] == "metric" || values["chartType"] == "bubble"
				|| values["chartType"] == "solidgauge" || values["chartType"] == "fullcirclegauge" || values["chartType"] == "sparkline"
				|| values["chartType"] == "heatmap") {
				var doreturn = false;
				_.each(yAxisArray, function (obj) {
					if (obj.aggregation.toLowerCase() == "none") {
						that.showErrorMessage(getMessage('BirdWarning85'));
						doreturn = true;
					} else if (obj.aggregation.toLowerCase() == "raw") {
						that.showErrorMessage(getMessage('BirdWarning97'));
						doreturn = true;
					}
				})
				if (doreturn) {
					return false;
				}
			}
			$('.gridspinner' + currentchartnum).show();
			var applyObj = {};
			applyObj["pos"] = undefined;
			applyObj["currentchartnum"] = currentchartnum;
			applyObj["applyattributes"] = "applyattributes";
			applyObj["dateHistogramInterval"] = undefined;
			applyObj["calculation"] = undefined;
			applyObj["isExcludeorIncludeFilters"] = undefined;
			applyObj["isChartSummaryData"] = undefined;
			applyObj["selecteddobycolumns"] = undefined;
			applyObj["filterArray"] = [];
			applyObj["navigatefrom"] = undefined;
			applyObj["isCustomparameterFilter"] = undefined;
			applyObj["tableInfo"] = undefined;
			PubSub.publish('applyfiltesandredrawchart', applyObj);
			//	this.broker.trigger('applyfiltesandredrawchart', undefined,currentchartnum,"applyattributes",undefined,undefined,undefined,undefined,undefined,undefined);
			var that = this;
			var convertedTypes = sessionStorage.getItem("convertedtypes");
			if (convertedTypes != undefined) {
				var parsedValues = JSON.parse(convertedTypes);
				_.each(parsedValues, function (parsedValue) {
					_.each($(".measures").find('.singleliitems'), function (measure) {
						if ($(measure).attr('data-name') == parsedValue["columnName"] && $(measure).attr('data-type') != parsedValue["dataType"]) {
							$(measure).closest("div").remove();
							var aggregation = "Count";
							var measuresView = new MeasuresView({
								broker: that.state.broker,
								columnDisplayName: $(measure).attr("title"),
								columnName: $(measure).attr('data-name'),
								dataType: parsedValue["dataType"],
								tableName: $(measure).attr("table-name"),
								aggregation: aggregation,
								features: that.state.features,
								measures: that.props.measures,
								dimensions: that.props.dimensions,
								multifact: that.state.multifact,
								multifactentities: that.state.multifactentities,
							});
							that.$(".measures").append(measuresView.el);
							this.state.measurescount++;
							$(".measurecount").text(this.measurescount);
							that.updateSessionStorage($(measure).attr("data-name"), $(measure).attr("title"), parsedValue["dataType"], aggregation, 'measure', "yes");
						}
					});
					//update dataType for customfields
					var customFields = sessionStorage.getItem("customFields");
					if (customFields != undefined && customFields != null && customFields != "null" && customFields.length > 0) {
						customFields = JSON.parse(customFields);
						_.each(customFields, function (value, index) {
							if (value['name'] == parsedValue.columnName) {
								if (customFields[index] != undefined) {
									customFields[index].dataType = parsedValue.dataType;
								}
							}
						});
						sessionStorage.setItem("customFields", JSON.stringify(customFields));
					}
				});
			}
			var drilldownchartnumber = ChartInfo.functions.getDrillDownChartNumber();
			if (drilldownchartnumber != undefined) {
				if ($("#drilldownallchartsinstoryboard").prop("checked") == true) {
					sessionStorage.removeItem("drilldown" + drilldownchartnumber);
				}
			}
			$(".breadcrumb[data-num=" + currentchartnum + "]").remove();
			$("#chart" + currentchartnum + "").before("<ul class='breadcrumb' data-num=" + currentchartnum + "></ul>");
			if (chartDetails1["drillDownFilter"] != undefined) {
				var breadCrumbElement = $('.breadcrumb[data-num=' + currentchartnum + ']')
				_.each(chartDetails1["drillDownFilter"], function (value, index) {
					var appendValue = value.data;
					var ulEle = "";
					var breadCrumpElements = ChartInfo.functions.getBreadCrumpSpanElement(value.data);
					if (breadCrumpElements != undefined && Object.keys(breadCrumpElements).length > 0) {
						appendValue = breadCrumpElements["span"];
						ulEle = breadCrumpElements["ulelement"];
					}
					var displayName = (value.columndisplayname != undefined) ? value.columndisplayname : value.displayName;
					if (value.dataType == "date") {
						displayName = value.columndisplayname;
					}
					if (value.columnname != undefined) {
						breadCrumbElement.append("<li  class=drilldown saved>" + displayName + " [" + appendValue + "] <a  columnname='" + value.columnname + "'  name=" + value.data + " class='close removedrilldown' title=" + lang["storyboard.remove"] + "><i class='fa fa-times'<i/></a>" + ulEle + "</li>");
					}
				});
			}
		}
		if ($('.birdmessage-center.message-top-in').length === 0) {
			//	$("#attributesmodal").modal("hide");				
		}
		if (ChartInfo.functions.getChartDetails(1) != undefined) {
			var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(1));
			var drillDownAllFilter = chartDetails["drillDownAllFilter"];
			$(".quickfiltercontainer").empty();
			$(".boc-dark-background>.container-fluid>.row>.quickfiltercontainer").attr("drilled-chart", this.props.chartnumber);
			_.each(drillDownAllFilter, function (i, j) {
				var name = decodeURIComponent(drillDownAllFilter[j].columndisplayname);
				if (name === null) {
					name = decodeURIComponent(drillDownAllFilter[j].name);
				}
				else if (name == "undefined") {
					name = decodeURIComponent(drillDownAllFilter[j].displayName);
					if (drillDownAllFilter[j].customfield != undefined) {
						name = drillDownAllFilter[j].customfield.name;
					}
				}
				if (drillDownAllFilter[j].type == "date") {
					name = $(name).find('li:first').attr('column-display-name');
					if (name === undefined) {
						name = drillDownAllFilter[j].displayName;
					}
				}
				$(".quickfiltercontainer").append(
					'<div class="btn-group bgcommon" title=' + name + ': ' + drillDownAllFilter[j].data + '>' +
					'<span class="btn btn-bird-group btn-sm quickfilterbg">' + name + '</span>' +
					'<div type="button" class="btn btn-bird-group btn-sm quickfiltervalues" title=' + lang["storyboard.more"] + '>' +
					'<span title=' + drillDownAllFilter[j].data + '>' + drillDownAllFilter[j].data + '</span>' +
					'<span class="quickfilterremove" columnname=' + name + ' data=' + drillDownAllFilter[j].data + '></span>' +
					'</div>' +
					'</div>'
				);
				$('.quickfilterremove').click((e) => { PubSub.publish("quickfilterremove", e) });
			});
			if (that.state.isSharedReport != true && ChartInfo.functions.getisViewOnlyFlag() != true) {
				$(".quickfilterremove").html("<i title=" + lang["storyboard.remove"] + " i class='fa fa-times'></i>")
			}
		}
		$(".maxanim1[data-num=" + currentCnartNum + "] #dropdownmorechart").removeClass("disabled")
	}
	applyRefLineEve(msg, data) {
		this.applyReferenceLine();
	}
	applyReferenceLine() {
		var that = this;
		var tempObj = {};
		$(".referenceattributes .singleliitems").each(function (index, value) {
			var reference = {};
			var id = $(value).attr("id");
			var dataType = $(value).attr("data-type");
			var columnname = $(value).attr("column-name")
			if (dataType == 'CustomLine') {
				reference["isCustomLine"] = true;
			} else {
				reference["isCustomLine"] = false;
			}
			reference["referenceName"] = $(value).find(".referencelinename").val();
			reference["referenceLabel"] = $(value).find(".referencelinelabel").val();
			reference["referenceValue"] = $(value).find(".referencelinevalue").val();
			reference["referenceLineagg"] = $(value).find(".agg").val();
			reference["referenceStyle"] = $(value).find(".referencelinestyle").val();
			reference["referenceColor"] = $(value).find("#referencecolor").val();
			reference["columnname"] = columnname
			if (reference["referenceValue"] != "") {
				tempObj[id] = reference;
			}
		});
		ChartInfo.functions.setreferenceLineValues(this.state.chartnumber, tempObj);
	}
	addcustomfieldEvee(msg, data) {
		this.addcustomfield(data);
	}
	addcustomfield(customObject) {
		var today = new Date();
		var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
		dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
		$(".customdimensions").append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
		var customfieldView = new CustomFieldView({
			deletecustomfield: this.deletecustomfield,
			dynamicdiv: dynamicdiv,
			columnDisplayName: customObject.name,
			columnName: customObject.name,
			dataType: (customObject.dataType == undefined) ? "string" : customObject.dataType,
			aggregation: "Sum",
			multifact: this.state.multifact,
			multifactentities: this.state.multifactentities,
		});
		$(".customdimensions").append($("#" + dynamicdiv));
		this.state.dimensioncount++;
		$(".dimensioncount").text(this.state.dimensioncount);
	}
	deletecustomfield = (event) => {
		var that = this;
		var removableitem = event.currentTarget.title;
		var deleteCustomField = false;
		var fieldName = "";
		var fieldTypeString = "";
		// check in custom hierarachy list
		var customhierarchy = ChartInfo.functions.getcustomhierarchy();
		if (customhierarchy != undefined && customhierarchy != null) {
			var temphier = [];
			_.each(customhierarchy, function (value, i) {
				temphier = $.grep(value.hierarchelist, function (val, index) {
					if (removableitem == value.hierarchelist[index].columnName) {
						deleteCustomField = true;
						fieldName = value.hierarchelist[index].columnName;
						fieldTypeString += "Measure Hierarchy/";
						return false;
					}
				});
			});
		}

		var cumstoMeasures = ChartInfo.functions.getCustomMeasureField();
		if (cumstoMeasures != undefined && cumstoMeasures != null) {
			_.each(cumstoMeasures, function (value, index) {
				var bool = false
				_.each(value.usedFields.split(","), function (usedcolumn) {
					if (usedcolumn == removableitem) {
						deleteCustomField = true;
						fieldName = usedcolumn;
						fieldTypeString += "Custom Measure/";
						bool = true;
					}
				})
				if (bool) {
					return true;
				}
			})
		}
		var range = ChartInfo.functions.getRange();
		if (range != undefined && range != null) {
			_.each(range, function (object, index) {
				if (object["xAxisName"][0].columnName == removableitem) {
					deleteCustomField = true;
					fieldName = object["xAxisName"][0].columnName;
					fieldTypeString += "Custom Range/";
				}
			})
		}
		if (deleteCustomField) {
			fieldTypeString = fieldTypeString.substring(0, fieldTypeString.length - 1);
			this.showWarningForCustomFields(fieldTypeString);
			return false
		}
		$(".deleteyes").unbind("click");
		$(".deleteno").unbind("click");
		$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
		$('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
		$('.birdmessage-info').attr('data-num', this.props.chartnumber);
		$('.birdmessagebuttons').addClass('show');
		$('.birdmessage-center').addClass("message-top-in");
		$('.details').removeClass('show');
		$('.details').addClass('hide');
		$('.birdmessage h2').empty().append('Warning');
		$('.birdmessage-info').empty().text(getMessage('BirdWarning51'));
		$(".deleteyes").click(function (e1) {
			e1.preventDefault();
			$('.birdmessage-center').removeClass("message-top-in");
			//	var removableitem = e.currentTarget.title;
			var customFields = JSON.parse(sessionStorage.customFields);
			for (var i = 0; i < customFields.length; i++) {
				if (customFields[i].name === removableitem) {
					customFields.splice(i, 1);
				}
			}
			var getAllTileLevelFilters = ChartInfo.functions.getAllTileLevelFilters();
			if (getAllTileLevelFilters != undefined) {
				for (var key in getAllTileLevelFilters) {
					if (getAllTileLevelFilters.hasOwnProperty(key)) {
						var tileLevelFilters = getAllTileLevelFilters[key];
						if (tileLevelFilters != undefined && tileLevelFilters[1] != undefined && tileLevelFilters[1].tileFiltersList != undefined) {
							var tileFiltersList = tileLevelFilters[1].tileFiltersList;
							$.each(tileFiltersList, function (index, filter) {
								if (filter["columnname"] == removableitem) {
									tileFiltersList.splice(index, 1);
								}
							});
						}
					}
				}
			}
			sessionStorage.customFields = JSON.stringify(customFields);
			var customfieldscount = JSON.parse(sessionStorage.customFields);
			if (customfieldscount.length === 0) {
				sessionStorage.removeItem('customFields');
			}
			$('.attributescustomfields[data-name="' + removableitem + '"]').remove();
			$('.attributesmeasures[data-name="' + removableitem + '"]').remove();
			$('.singleliitems[data-name="' + removableitem + '"]').remove();
			var convertedValues = sessionStorage.getItem("convertedvalues");
			var parsedValues = JSON.parse(convertedValues);
			if (parsedValues != null && parsedValues != undefined) {
				var res = $.grep(parsedValues, function (val) {
					return val.columnDisplayName != removableitem;
				});
				sessionStorage.setItem("convertedvalues", JSON.stringify(res));
			}
			if ($('.singlereportview ').length == 0) {
				var filterAccordianLength = $('.acc-content').length;
				var isAplyingFiltersRequired = false;
				$('.acc-content').each(function () {
					var $this = this;
					if ($($this).attr("id") == (removableitem.replace(/([#;?&,%.+*~\':"!^$%[\]()<=>`|\/@])/g, ""))) {
						var $deleteButton = $($this).find('#delete');
						$($deleteButton).click();
						if (filterAccordianLength > 1) {
							isAplyingFiltersRequired = true;
						}
					}
				});
				if (isAplyingFiltersRequired) {
					$('.rightfiltersapply').click();
				}
			} else {
				var filterAccordianLength = $('.acc-content').length;
				if (filterAccordianLength > 0) {
					var isAplyingFiltersRequired = false;
					$('.acc-content').each(function () {
						var $this = this;
						if ($($this).attr("id") == (removableitem.replace(/([#;?&,%.+*~\':"!^$%[\]()<=>`|\/@])/g, ""))) {
							var $deleteButton = $($this).find('#delete');
							$($deleteButton).click();
							if (filterAccordianLength > 1) {
								isAplyingFiltersRequired = true;
							}
						}
					});
					if (isAplyingFiltersRequired) {
						$(".applysavedfilter").click();
					}
				} else {
					that.broker.trigger('checkWheatherDeletedCustomFieldExistInFilter', removableitem);
					that.broker.trigger('checkWheatherDeletedCustomFieldExistInFilter1', removableitem);
				}
			}
		});
		$(".deleteno").click(function () {
			$('.birdmessage-center').removeClass("message-top-in");
		});
	}
	customparameter = (e) => {
		e.preventDefault();
		var $this = e.currentTarget;
		var currentChartNumber = this.props.chartnumber;
		var that = this;
		new CustomParameterView({
			customparameter: that.customparameter,
			chartnumber: currentChartNumber,
			columnName: $($this).attr('data-name'),
			multifact: that.state.multifact,
			multifactentities: that.state.multifactentities,
		});
		document.body.classList.add("modal-open");
		//	$(".customparametermodal"+currentChartNumber).modal("show");
		ModalProperties.resize();
	}
	searchParameters = (e) => {
		var $this = e.currentTarget;
		var valThis = $($this).val().toLowerCase();
		$(".itemname").parents(".attributescustomparameters").each(function () {
			var text = $(this).text().toLowerCase();
			(text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
		});
	}
	showWarningForCustomFields(fieldType) {
		$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
		$('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
		$('.birdmessagebuttons').removeClass('show');
		$('.birdmessagebuttons').addClass('hide');
		$('.birdmessage-center').addClass("message-top-in");
		$('.details').removeClass('show');
		$('.details').addClass('hide');
		$('.birdmessage h2').empty().append('Warning');
		$('.birdmessage-info').empty().text(getMessage('BirdWarning122').replace(/@@fieldType/ig, fieldType));
	}
	viewadvancedchartsettingsmodalEve(msg, data) {
		this.viewadvancedchartsettingsmodal(data);
	}
	viewadvancedchartsettingsmodal(chartnumber) {
		// var $this = e.currentTarget;
		// var chartNumber = $($this).attr("data-num");
		// console.log(chartNumber)
		var Advancedchartsettingsmodal = new AdvancedChartSettingsModalView({
			chartNumber: chartnumber
		});
		// new AdvancedChartSettingsModalView({
		// 	chartNumber:chartNumber
		// });
	}
	previewColumnDataEve(msg, data) {
		if (data != undefined) {
			this.previewColumnData(data.target, data.name, data.displayname, data.datatype);
		} else {
			this.previewColumnData();
		}
	}
	previewColumnData(e, Name, Displayname, Datatype) {
		e.preventDefault();
		e.stopPropagation();
		if (Name != undefined) {
			var name = Name;
			var displayname = Displayname;
			var dataType = Datatype;
			if (displayname == undefined || displayname == null || displayname == "") {
				displayname = name;
			}
			if (dataType == undefined || dataType == null || dataType == "") {
				dataType = "string";
			}
			var objProp = {};
			objProp["name"] = name;
			objProp["displayname"] = displayname;
			objProp["dataType"] = dataType;

			PubSub.publish("columnpreview", objProp);
		}
	}
	getYfieldtables(yAxisArray, customFields, customFeildsArray) {
		var yFieldTables = [];
		var yFieldType = yAxisArray[0].dataType;
		if (yFieldType == "customaggregatedfield") {
			var customMeasuresArray = ChartInfo.functions.getCustomMeasureField();
			var customMeasureField;
			_.each(customMeasuresArray, function (i, j) {
				if (yAxisArray[0].displayName == i.name) {
					var usedFieldsInCustomMeasure = i.usedFields.split(",");
					_.each(usedFieldsInCustomMeasure, function (k, l) {
						customMeasureField = k;
						// Custom fields in custom Measures
						if (customFields != undefined && customFields != "null" && customFields.length > 0) {
							var customFieldInCustomMeasure;
							_.each(customFeildsArray, function (i, j) {
								if (customMeasureField == i.name) {
									var usedFieldsInCustomField = i.usedFields;
									_.each(usedFieldsInCustomField, function (k, l) {
										customFieldInCustomMeasure = k;
										customFieldInCustomMeasure = customFieldInCustomMeasure.split(".").shift();
										yFieldTables.push(customFieldInCustomMeasure);
										customMeasureField = "";
									})
								}
							})
						}
						if (customMeasureField != undefined && customMeasureField != "") {
							customMeasureField = customMeasureField.split(".").shift();
							if (!(yFieldTables.indexOf(customMeasureField) > -1)) {
								yFieldTables.push(customMeasureField);
							}

						}

					})
				}
			});
		} else {
			var yField = yAxisArray[0].columnName;
			if (customFields != undefined && customFields != "null" && customFields.length > 0) {
				_.each(customFeildsArray, function (i, j) {
					if (yField == i.name) {
						var usedFieldsInCustomFields = i.usedFields;
						_.each(usedFieldsInCustomFields, function (k, l) {
							var customFieldField = k;
							customFieldField = customFieldField.split(".").shift();
							yFieldTables.push(customFieldField);
						})
						yField = "";
					}
				})
			}
			if (yField != undefined && yField != "") {
				yField = yField.split(".").shift();
				yFieldTables.push(yField);
			}
		}
		return yFieldTables;
	}
	showErrorMessage(message) {
		$(".spinner").hide();
		$(".gridspinner").hide();
		$(".gridspinner").hide();
		$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
		$('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
		$('.birdmessagebuttons').removeClass('show');
		$('.birdmessagebuttons').addClass('hide');
		$('.birdmessage-center').addClass("message-top-in");
		$('.details').removeClass('show');
		$('.details').addClass('hide');
		$('.birdmessage h2').empty().append('Warning');
		$('.birdmessage-info').empty().text(message);
	}
	showValidation() {
		//inner elements can not be hided hide with chartId
		// $('.gridload').hide();
		$(".spinner").hide();
		$(".gridspinner").hide();
		$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
		$('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
		$('.birdmessagebuttons').removeClass('show');
		$('.birdmessagebuttons').addClass('hide');
		$('.birdmessage-center').addClass("message-top-in");
		$('.details').removeClass('show');
		$('.details').addClass('hide');
		$('.birdmessage h2').empty().append('Warning');
		$('.birdmessage-info').empty().text(getMessage('BirdWarning138'));
	}
	metricprefixCheck = (e) => {
		var $this = e.currentTarget
		$(".metricprefixerror").addClass('hidden');
		if (($($this).val()).indexOf("<script>") != -1) {
			setTimeout(function(){
				$($this).val("");
			},100)
            $(".metricprefixerror").removeClass('hidden');
            $(".metricprefixerror").text(lang["metric.scripttagerror"]);
		}
	}
	metricsuffixCheck = (e) => {
		var $this = e.currentTarget
		$(".metricsuffixerror").addClass('hidden');
		if (($($this).val()).indexOf("<script>") != -1) {
			setTimeout(function(){
				$($this).val("");
			},100)
            $(".metricsuffixerror").removeClass('hidden');
            $(".metricsuffixerror").text(lang["metric.scripttagerror"]);
		}
	}
	unbindEvents() {
		PubSub.unsubscribe('addcustomfield', this.addcustomfieldEvee);
		PubSub.unsubscribe("previewcolumndata", this.previewColumnDataEve);
	}
}