import React from 'react'
import { lang } from "../../js/helpers/utils/languages/language";
export default class SaveMLModelTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        var that = this.props;

        return (
            <div className="modal fade in" id="savestoryboard" tabIndex="-1" role="dialog" aria-labelledby="savestoryboard" aria-hidden="true" style={{ display: "block" }}>
                <div className="modal-backdrop fade in" ></div>
                <div className="modal-dialog modal-lg modal-save-report modal-dialog-lg-center modal-dialog-center" style={{ zIndex: "1050" }}>
                    <div className="modal-content">
                        <div className="modal-header boc-modal-hf-padding">
                            <button type="button" className="close white" id="storyboardclose" aria-hidden="true" data-dismiss="modal" onClick={that.close}>&times;</button>
                            <h4 className="modal-title"><i className="fa fa-floppy-o"></i> {lang["mlreports.saveboard"]}</h4>
                        </div>
                        <div className="modal-body scrollset">
                            <div className="container-fluid">
                                <div className="row filtercontent">
                                    <fieldset className="savereport-border">
                                        <legend className="savereport-border">{lang["mlreports.reportdetails"]}</legend>
                                        <div className="control-group">
                                            <div className="validationgroup form-group" style={{ marginTop: "-2px" }}>
                                                <span className="required right legend"></span>
                                                <label className="subhead" for="storyboardname">{lang["mlreports.name"]}</label>
                                                <input type="name" id="storyboardname" name="storyboardname" defaultValue={that.reportname} className="form-control input-sm" placeholder={lang["mlreports.placeholdname"]} />
                                                <span className="help-block hidden marg-left-10"></span>
                                            </div>
                                            <div className="validationgroup form-group">
                                                <label className="subhead" for="storyboardfolder">{lang["mlreports.folder"]} </label>
                                                <input type="folder" className="form-control input-sm" defaultValue={that.folder} id="storyboardfolder" name="storyboardfolder" list="folders" placeholder={lang["mlreports.slctfolder"]} />
                                                <span className="help-block hidden marg-left-10"></span>
                                                <datalist id="folders">
                                                </datalist>
                                            </div>
                                            {/* <div className="validationgroup form-group hidden-lg hidden-md hidden-sm hidden-xs">
                                            <label className="subhead">Description </label>
                                            <textarea id="storyboarddesc" placeholder="Enter Your Storyboard Description Here" value={this.props.reportDesc} name="storyboarddesc" className="form-control input-sm resize-vertical">{this.props.reportDesc}</textarea>
                                            <span className="help-block hidden"></span>
                                        </div> */}
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer boc-modal-hf-padding">
                            <button type="button" className="btn btn-bird" id="btn-birdsave" data-dismiss="modal" onClick={that.close}><i className="fa fa-times"></i> {lang["mlreports.cancelbtn"]}</button>
                            <button type="button" className="btn btn-bird applystoryboard" id="applystoryboards" onClick={that.applymlstoryboardchanges}><i className="fa fa-check"></i> {lang["mlreports.applybtn"]}</button>
                        </div>
                    </div>
                    {/* <!-- /.modal-content --> */}
                </div>
            </div>
        );
    }
}