import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'underscore';
import CreateConnectionHtml from './CreateConnectionHtml';
import SendRequest from '../SendRequest';
import DataSourceConnection from  './DataSourceConnection';
const $ = window.$;

export default class createConnectionView extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            frequentlyused: {},
            notfrequentlyused : {}
        }
        this.render();
        this.getConnections();
        this.close=this.close.bind(this);
        this.createnewsource=this.createnewsource.bind(this);
        //this.dataModelConnectionsDetailsCollection = this.props.dataModelConnectionsDetailsCollection;
    }
    close=(e)=>{
        e.preventDefault();
        document.body.classList.remove('connection-open','creating-new-connection');
        document.querySelector('.addconnection').classList.remove('connection-in');
        ReactDOM.unmountComponentAtNode(document.querySelector('.addconnection'));
    }
    createnewsource=(e)=>{
        var $this = e.currentTarget;
        var ConnectionID = $($this).attr("connectionid");
        var DisplayName = $($this).attr("displayname");
        var SchemaName = $($this).attr("schemaname");
       // var connectionDetails=this.state.connectionJsonMap[ConnectionID];
        $(".connection-container").addClass("adding-new-source");
        $(".commonconnections, .otherconnections").hide();
        $('.connection-container').append("<div class='datasourceconnection-container'></div>");
        ReactDOM.render(<DataSourceConnection
            schemaId={ConnectionID}
            schemaName={SchemaName}
            schemaDisplayName={DisplayName}
            connectionjson={{}}
            // connectiondetails={connectionDetails}
            // connectiondata={connectionDetails}
        />,document.querySelector('.datasourceconnection-container'));
    }
    getConnections(){
        var frequentlyused = {};
		var notfrequentlyused = {};
        var that =this;
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getConnectionsList",
            queryString: "",
            sucessFunction: (response) => {
                var uniquetypes = _.unique(response[1],"name");
					_.each(uniquetypes, function(value, index){
						var temp = {};
						if(value.frequentlyused == true){
							frequentlyused[value.name] = [];
						}else{
							notfrequentlyused[value.name] = [];
						}
					});
					_.each(response[1],function(value,index){
						if(value.frequentlyused== true){
							frequentlyused[response[1][index].name].push(_.extend(response[1][index],response[0][index]));
						}else{
							notfrequentlyused[response[1][index].name].push(_.extend(response[1][index],response[0][index]));
						}
                    });
                    this.state.frequentlyused = frequentlyused;
                    this.state.notfrequentlyused = notfrequentlyused
                    ReactDOM.render(<CreateConnectionHtml notfrequentlyused1 = {this.state.notfrequentlyused} createnewsource = {this.createnewsource} close={this.close} />,document.querySelector('.addconnection'));
                    $(".connection-center.addconnection").addClass("connection-in");
					$("body").addClass("connection-open");
                },
            rejectFunction: () => { },
            headers: requestHeaders
    });
    }
render(){
    return(
        <div></div>
    );
}
}