import React, { Component } from "react";
import { lang } from "../js/helpers/utils/languages/language";
import ReactDOM from "react-dom";
import PubSub from "pubsub-js"

export default class CustomMeasureFieldView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicdiv: props.dynamicdiv,
            columnDisplayName: props.columnDisplayName,
            columnName: props.columnName,
            aggregation: props.aggregation
        };
        this.render();
    }
    render() {
        ReactDOM.render(<CustomMeasureFieldsViewTemplate dynamicdiv={this.state.dynamicdiv}
            columnDisplayName={this.state.columnDisplayName} columnName={this.state.columnName}
        />, document.getElementById(this.state.dynamicdiv));
        return (<div></div>);
    }

}

class CustomMeasureFieldsViewTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        var that = this.props;
        return (
            <li key={that.dynamicdiv} className="boc-list-item singleliitems  customaggregatedfields attributesmeasures" data-type="customaggregatedfield" data-name={that.columnName} title={that.columnDisplayName}>
                <span className="itemtype">
                    <span className="function">{lang["storyboard.fx"]}</span>
                </span>
                <span className="itemname">{that.columnDisplayName}</span>
                <span type="button" data-name={that.columnName} onClick={this.createcustommeasure} title={that.columnDisplayName} className="opacity-effect createcustommeasure" data-edit={lang["storyboard.edit"]}>
                    <i className="fa fa-pencil"></i>
                </span>
            </li>);
    }
    createcustommeasure(e) {
        PubSub.publish('createcustommeasure', e.currentTarget);
    }
}