import React from 'react';
import { lang} from "../../../js/helpers/utils/languages/language";
const $ =window.$;

export default class LdapViewTemplate extends React.Component{
    constructor(props){
        super(props);
	}
	hideleft = ()=> {
		$('.leftarea').addClass('hide');
	
		$('.hideleft').removeClass('hide');
		$('.showleft').addClass('hide');
	
		$('.leftshow').removeClass('hide');
		$('.lefthide').addClass('hide');
	
		$('.w2ui-resizer').resize(); 
		$(".boc-admin-main .notifications-table-container table").bootstrapTable('resetView');
	  }
	  leftshow  = ()=>  {
		$('.leftarea').removeClass('hide');
	
		$('.hideleft').removeClass('hide');
		$('.showleft').addClass('hide');
		
		$('.leftshow').addClass('hide');
		$('.lefthide').removeClass('hide');
		
		$('.w2ui-resizer').resize();
		$(".boc-admin-main .notifications-table-container table").bootstrapTable('resetView');
	  }
	  lefthide = ()=> {
		$('#icontent').addClass('hide');
		$('.leftarea').addClass('shownsingle');
		$('.editrole').addClass('disabled');
		$('.editgroup').addClass('disabled');
		$('.editaccount3').addClass('disabled');
		$('.editschedule').addClass('disabled');
		$('.editmail').addClass('disabled');
		$('.viewhistory').addClass('disabled');
		
		$('.hideleft').addClass('hide');
		$('.showleft').removeClass('hide');
		
		$('.leftshow').addClass('hide');
		$('.lefthide').removeClass('hide');
		
		$('.w2ui-resizer').resize();
		$(".boc-admin-main .notifications-table-container table").bootstrapTable('resetView');
	  }
	  showleft = ()=>  {
		$('#icontent').removeClass('hide');
		$('.leftarea').removeClass('shownsingle');
		$('.editrole').removeClass('disabled');
		$('.editgroup').removeClass('disabled');
		$('.editaccount3').removeClass('disabled');
		$('.editschedule').removeClass('disabled');
		$('.editmail').removeClass('disabled');
		$('.viewhistory').removeClass('disabled');
		
		$('.hideleft').removeClass('hide');
		$('.showleft').addClass('hide');
		
		$('.leftshow').addClass('hide');
		$('.lefthide').removeClass('hide');
		
		$('.w2ui-resizer').resize();
		$(".boc-admin-main .notifications-table-container table").bootstrapTable('resetView');
	  }
    render(){
        var that = this.props;
        var ldapSettings = that.ldapSettings;
        var features = that.features[0];
        var addLdap = features.features.map((feature)=>{
            if(feature.featureName=='add_ldap'&&feature.allow==true){
                return <div className="pull-right marg-bot-10">
                        <button className="btn btn-bird btn-sm btn-highlight addldap pull-right" id="addldaps" onClick={that.newLdap} ><i className="fa fa-plus marg-righ-5"></i>{lang["admin.ldap.addbtn"]}</button>
                </div>
            }
        })
        return(
            <div className="row in-back">
				<div className="row-same-height-inner">
					<div className="col-xs-4 col-xs-height in-panes leftarea">
					<div className="btn btn-transparent hideleft toggleleft" onClick={this.hideleft}><i className="fa fa-grey fa-angle-left"></i></div>
					<div className="btn btn-transparent showleft toggleleft hide" onClick={this.showleft}><i className="fa fa-grey fa-angle-left"></i></div>
                    {addLdap}
                    <div className="listcontainer col-xs-12">
							<div className="notifications-table-container">
                            <div className="pull-left search" style={{padding: "6px 14px 6px 10px"}}>
                            <input className="form-control" type="text" placeholder={lang["admin.ldap.search"]}/></div> 
								<table id="otherstable" data-toggle="table" className="marg-top-10" data-pagination="true" data-page-size="25" data-smart-display="true" data-search="true" data-pagination-v-align="bottom" data-search-align="left">
									<thead>
										<tr>
											<th data-sortable="true">{lang["admin.ldap.settingsname"]}</th>
											<th data-sortable="true">{lang["admin.ldap.status"]}</th>
											{
												features.features.map((feature)=>{
												if(feature.featureName=='view_ldap_users'&&feature.allow==true){
													return <th className="centertext" data-sortable="true">{lang["admin.ldap.showusers"]}</th>
												}
												})
											}
											{
												features.features.map((feature)=>{
												if(feature.featureName=='edit_ldap'&&feature.allow==true){
													return <th className="centertext">{lang["admin.ldap.edit"]}</th>
												}
												})
											}
											{
												features.features.map((feature)=>{
												if(feature.featureName=='delete_ldap'&&feature.allow==true){
													return <th className="centertext">{lang["admin.ldap.delete"]}</th>
												}
												})
											}
										</tr>
									</thead>
									<tbody>
										{ldapSettings.map((ldapSetting)=>{
										return <tr>
											<td>{ldapSetting.ldapName}</td>
											<td>{ldapSetting.status=='A'?"Active" : "Inactive"}</td>
											{features.features.map((feature)=>{
												if(feature.featureName=='view_ldap_users'&&feature.allow==true){
													return <td className="centertext"><button
														className="btn btn-admin showldapusers" id="showldapuser" onClick = {that.showldapuser}  value={ldapSetting.ldapId} type="button">
														<i className="fa fa-user"></i>
													</button></td>
												}
											})}
											{features.features.map((feature)=>{
												if(feature.featureName=='edit_ldap'&&feature.allow==true){
													return <td className="centertext"><button
														className="btn btn-admin editldap" id="editldaps" onClick = {that.editLdap} value={ldapSetting.ldapId} type="button">
														<i className="fa fa-pencil"></i>
													</button></td>
												}
											})}
											{features.feature.map((feature)=>{
												if(feature.featureName=='delete_ldap'&&feature.allow==true){
													return <td className="centertext"><button className="btn btn-admin deleteldap" id="deleteldaps" onClick = {that.deleteLdap} value={ldapSetting.ldapId} type="button">
														<i className="fa fa-close"></i>
													</button></td>
												}
											})}
										</tr>
									})}
									</tbody>
								</table>
							</div>
						</div>
						<div className="clear" className="icontent"></div>
					</div>
                    <div className="col-xs-6 col-xs-height in-panes" id="icontent">
					<div className="btn btn-transparent leftshow toggleright hide" onClick={this.leftshow}><i className="fa fa-grey fa-angle-right"></i></div>
					<div className="btn btn-transparent lefthide toggleright" onClick={this.lefthide}><i className="fa fa-grey fa-angle-right"></i></div>
					<div className="innerothersarea ldapformpage"> </div>
					</div>
				</div>
			</div>
		
        );
    }
}