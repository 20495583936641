import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'underscore';
import StoredProcedureParametersHtml from './StoredProcedureParametersHtml.jsx';
import SendRequest from '../SendRequest.js';
import DatahubModifyEntitiesHtml from './DatahubModifyEntitiesHtml.jsx';
import {getMessage} from '../js/helpers/utils/BirdMessage';
import DatahubItemsSettingsHtml from './DatahubItemSettingsHtml';
import { lang } from "../js/helpers/utils/languages/language";
const $=window.$;

export default class StoreProcedureParameters extends React.Component{
    constructor(props){
        super(props);
        this.state={
            storedProcMetaData:[]
        }
        this.connId = props.connId;
        this.storedProcName = props.storedProcName;
        this.connectiondata = JSON.stringify(props.connectiondata);
        this.isSaved  = props.isSaved;
        this.datahubdetails={};
        this.tables = [];
        this.query = {};
        this.datahubentities={};
        this.reportcolumns = {};
    }
    createDataHubEntitiesObject=(response)=>{
        if(response.success== false||response.hasOwnProperty("error")){
    		if(response.status!="fail"){
    		$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
			$('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
			$('.birdmessagebuttons').removeClass('show');
			$('.birdmessagebuttons').addClass('hide');
			$('.birdmessage-center').addClass("message-top-in");
			$('.details').removeClass('hide');
			$('.birdmessage h2').empty().append('Error : ');
			$('.birdmessage h2').append('<span class="errorcode">');
			$('.birdmessage .errorcode').empty().append(response.errorCode);
			$('.birdmessage-info').empty().text(lang["Datahub.unablefetch"]);
			$('.details').addClass('show');
			if(response.success== false||response.hasOwnProperty("error")){
				$('.messagedetails xmp').empty().append(response.errorTrace);
				}
				else{
					$('.messagedetails xmp').empty().append(response.error);
				}}
			setTimeout(function(){
				$("#datahubstepscontainer").steps("previous");
			//	$('.spinner').hide();
			},1000);
			//$('.spinner').hide();			
			if($("#datahubentitycontainer li").length>0){
				var emptyNameCount = 0;
				$('.spinner').show();
				$("#datahubentitycontainer>li").removeClass('warning');
				_.each($("#datahubentitycontainer li"),function(ent,index){
					var entity = $(ent).find("input.scopeitem");
					var entityid = $(entity).attr("data_parent");
					var entityname=	$(entity).attr("data-value")
					if(entityname.includes('.')){
						 entityname=$(entity).attr("data-value").split(".")[1];
					}
					if(entityname=="Twitter"){
						entityname="twitter";
					}
					var errorTablename=response.tablename.replace(/[`"]/g,'');
					if(entityname==errorTablename && entityid==response.connId){
						setTimeout(function(){
							$(ent).removeClass("active");
							$(ent).addClass("warning");
							emptyNameCount++;
						},2000);
											}
				});
				setTimeout(function(){if(emptyNameCount>0){
					$('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-infor').addClass('birdmessage-warning');
					$('.messageicon i').removeClass('fa-times-circle-o fa-check fa-info-circle').addClass('fa-exclamation-triangle');
					$('.birdmessagebuttons').removeClass('show');
					$('.birdmessagebuttons').addClass('hide');
					$('.birdmessage-center').addClass("message-top-in");
					$('.details').removeClass('show');
					$('.details').addClass('hide');
					$('.birdmessage h2').empty().append('Warning');
					$("#datahubstepscontainer").steps("previous");
					if(response.error=="No Data"){
					$('.birdmessage-info').empty().text(lang["Datahub.nodataentity"]);
					}
					else{
					$('.birdmessage-info').empty().text("The Column Name '"+response.columnName+"'" + ""+getMessage('BirdWarning149'));
					}
					$('.spinner').hide();
					return ;
				}
				},2000);
			}
			return
		}
    	this.datahubdetails={};
    	this.tables = [];
    	var k=0;
    	_.each(response,(i,j)=>{
    		var data = JSON.parse(i);
    		if(this.datahubentities!==undefined && Object.keys(this.datahubentities).length>0 && this.datahubentities[j]!==undefined && this.datahubentities[j].length>0 ){
    			data.entities=this.datahubentities[j];
    		}
    		if(this.reportcolumns!==undefined && Object.keys(this.reportcolumns).length>0 && this.reportcolumns[j]!==undefined && this.reportcolumns[j].length>0 ){
    			data.entities=this.reportcolumns[j];
    		}
    		this.datahubdetails[j] = data;
    		var key = j.split("&&&");
    		var connAccessId = key[0];
    		var entity = key[1];
    		var connectionEntity = {};
    		connectionEntity['connaccessid'] = connAccessId;
    		connectionEntity['name'] = entity;
    		var dupname = entity.replace(/([-_;?&.,%#+*~\ '"!^$[\]()<=>`|\/@])/g, "");
    		k++;
  		    dupname = dupname+k+new Date().getTime();
    		connectionEntity['dupname'] = dupname;
    	/*	if($(".viewreport-list-item.datahub-customquery-item[data_parent='"+connAccessId+"']").hasClass('query-exists')){
        		$(".viewreport-list-item.datahub-customquery-item").closest("li.query-exists").addClass('error');
    			$("#datahubstepscontainer").steps("previous");
    			$('.spinner').hide();
        	}*/
    		if(data.dbType!=null && data.dbType!=undefined){
    			connectionEntity['type'] = data.dbType;
            }
            
    		this.tables.push(connectionEntity);
    	});
    }
    getAllFeildsJson=(connectionid,entity)=>{
        var tableDetails = this.datahubdetails[connectionid+"&&&"+entity];
        var tableEntities = tableDetails.entities;
        var feildData = [];
        _.each(tableEntities,(i,j)=>{
                var entity = {};
                var dataType=i.dataType;
                if(i.dataType == undefined && i.tableDisplayType != undefined){
                    dataType= i.tableDisplayType;
                }
                if(dataType === "double" || dataType === "long"){
                    dataType = "number"
                }
                if(i.dataType === "double" || i.dataType === "long"){
                    i.dataType = "number"
                }
                if(i.dataType==='date'||i.tableDisplayType==='date'){
                    if(i.dateFormat!=undefined && i.dateFormat!=null && i.dateFormat!=null){
                        entity['dateFormat'] = i.dateFormat;
                    }
                }
                entity['visible'] = "yes";
                entity['tableAliasName'] = i.tableName;
                entity['columnDisplayName']=i.columnName;
                entity['dateFormat']=i.dateFormat;
                entity['tableDisplayName'] = i.tableName;
                entity['tableName'] = i.tableName;
                entity['orginalColumnName'] = i.columnName;
                entity['columnName'] = i.columnName;
                entity['tableDisplayType'] = dataType;
                if(i.isPartitionKey!=undefined){
                    entity['isPartitionKey'] = i.isPartitionKey;
                }
                entity['name']=i.columnName;
                entity['type']= dataType;
                entity['connaccessid'] = connectionid;
                entity['tablename'] = i.tableName;
                feildData.push(entity);
            
        });
        return feildData;
    }
    getStoredProcMetaData=()=>{
        var conjson=this.props.connectiondata.connectionjson;
        SendRequest({
            url:"getstoredprocmetadata",
            queryString:"?procedurename="+this.props.storedProcName+"&connAccessID="+parseInt(this.props.connId)+"&connectiondetails="+encodeURIComponent(conjson),
            sucessFunction:(response)=>{               
                this.state.storedProcMetaData=response              
            },
            rejectFunction:(error)=>{},
            headers:{
                method: "get",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            }
            
        });
    }
    componentDidMount(){
        this.getStoredProcMetaData();
        var conjson=this.props.connectiondata.connectionjson;
        if(this.props.existingStoredProcDetails !== undefined && this.props.existingStoredProcDetails!=null) {
            this.renderexisitingdata(this.props.existingStoredProcDetails);
        }else {
        SendRequest({
            url:"getstoredprocmetadata",
            queryString:"?procedurename="+this.props.storedProcName+"&connAccessID="+parseInt(this.props.connId)+"&connectiondetails="+encodeURIComponent(conjson),
            sucessFunction:(response)=>{               
                    this.state.storedProcMetaData=response  
                    if(this.state.storedProcMetaData.length===0){
                        $('#datahubstoredprocedureparameterstab').hide();
                        this.savestoredprocedureparameters();
                    }
                    $("#settingscontainer").addClass("showsettings");   
                    var entitySourceName="";
        if(this.props.connectiondata!==undefined && this.props.connectiondata!==null && this.props.connectiondata!==""){
            entitySourceName = this.props.connectiondata.text;
        }
			
        ReactDOM.render(
            <StoredProcedureParametersHtml
                connId={this.connId}
                storedProcName={this.storedProcName}
                response={this.state.storedProcMetaData}
                showvalues={false}
                isSaved={this.isSaved}
                entitySourceName={entitySourceName}
                savestoredprocedureparameters={this.savestoredprocedureparameters}
                closesettingscontainer={this.closesettingscontainer}
                applydatahubmodifyentities={this.props.applydatahubmodifyentities}
            />,document.getElementById('settingscontainer')
            );         
            },
                rejectFunction:(error)=>{},
                headers:{
                    method: "get",
                    headers: new Headers({
                        "Content-Type": "application/json"
                    })
                }
                
            });
        }
       
    }
    renderexisitingdata=(exisitingParameters)=>{
        var entitySourceName="";
        if(this.props.connectiondata!==undefined&&this.props.connectiondata!==null&&this.props.connectiondata!==""){
         entitySourceName = this.props.connectiondata.text
        }
        ReactDOM.render(
            <StoredProcedureParametersHtml
                connId={this.connId}
                storedProcName={this.storedProcName}
                response={exisitingParameters}
                showvalues={true}
                isSaved={this.isSaved}
                entitySourceName={entitySourceName}
                closesettingscontainer={this.closesettingscontainer}
            />,document.getElementById('settingscontainer')
        );
        if(exisitingParameters.length===0){
            $('#datahubstoredprocedureparameterstab').hide();
            this.savestoredprocedureparameters();
        }
        $("#settingscontainer").addClass("showsettings");
    }
    savestoredprocedureparameters=(e)=>{
        $(".spinner").show();
        if(e!=undefined){
            e.preventDefault();
        }
        var storedProc = [];
        var tempObj = {};
        var mainStoredProc =[];
        var StoredProceduresEmptyParamCount = 0;
        $('.spparametervalue').removeClass('bird-has-error');
        _.each($(".datahubstoredprocedurecontainer table tbody tr"),(html,index)=>{
            var storedProcedureName = $(html).attr('name');
            var paramType = $(html).attr('paramtype');
            var parameterName = $(html).find('.spparametername').text();
            var dataType =$(html).find('.spdatatype').text();
            var parameterValue = $(html).find('.spparametervalue').val();
            var storedProcParameters = {};
            storedProcParameters['name'] = parameterName;
            storedProcParameters['datatype'] = dataType;
            storedProcParameters['value'] = parameterValue;
            if(parameterValue.length===0){
                $(html).find('.spparametervalue').addClass('bird-has-error');
                StoredProceduresEmptyParamCount++;
            }
            storedProc.push(storedProcParameters);
        });
        if(StoredProceduresEmptyParamCount>0){
            $('.spinner').hide();
            return false;
        }
        tempObj["params"] = storedProc;
        mainStoredProc.push(tempObj);
        sessionStorage.setItem(this.storedProcName.trim(),JSON.stringify(storedProc));
        var storedProcs = sessionStorage.getItem("storedprocs");
        if(storedProcs!=undefined){
            storedProcs = storedProcs+","+this.storedProcName;
        }
        else {
            storedProcs = this.storedProcName;
        }
        sessionStorage.setItem("storedprocs", storedProcs);
        var selectedEntities={};
        var conData = JSON.parse(this.connectiondata);
        conData['type'] = 'storedprocedure';
        conData['storedprocparams'] = mainStoredProc;
        selectedEntities[this.connId+"&&&"+this.storedProcName.trim()] = conData;  
        var reqdata = new FormData();
        reqdata.set("entities", JSON.stringify(selectedEntities));
        SendRequest({
            url:"getentitiesmetadata",
            queryString:"",
            body: reqdata,
            sucessFunction:(response)=>{
                if(response.success==false){
                    $(".spinner").hide();
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('hide');
                    $('.birdmessage h2').empty().append('Error : ');
                    $('.birdmessage h2').append('<span class="errorcode">');
                    $('.birdmessage .errorcode').empty().append(response.errorCode);
                    $('.birdmessage-info').empty().text(response.error);
                    $('.details').addClass('show').text("Details");
                    $('.messagedetails xmp').empty().append(response.errorTrace);
                    $('#datahubmodifyentitiestab').parent().addClass("disabled")
                }else{
                    var metadata;
                    
                    _.each(response,(i,j)=>{
                        var data = j.split("&&&");
                        var conAccessId= data[0];
                        var entity= data[1];
                        this.createDataHubEntitiesObject(response);
                        metadata=this.getAllFeildsJson(conAccessId,entity);
                    })
                    //Append DatabseModify Entities
                    ReactDOM.render(
                        <DatahubModifyEntitiesHtml
                            reportdata={metadata}
                            dataTypeClass={""}
                            schemaType="mysql"
                            isCustomQuery={false}
                            isStoredProcedure={true}
                            importtempateflag={false}
                            isSaved={this.isSaved}
                        />,
                        document.querySelector('.datahubmodifyentitiestablecontainer'));
                    $('#datahubmodifyentitiestab').parent().removeClass("disabled");
                    $('#datahubstoredprocedureparameterstab').parent().removeClass("active");
                    $('.tab-content #datahubstoredprocedureparameterstabview').removeClass("active");
                    $('#datahubmodifyentitiestab').parent().addClass("active")
                    $('.tab-content #datahubmodifyentitiestabview').addClass("active");
                    $(".spinner").hide();
                }
            },
            rejectFunction:(error)=>{},
            headers:{
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            }
        });
    }
    applydatahubmodifyentities=(e,showMessage)=>{
        var that = this;
        if(e!=undefined){
            e.preventDefault();
        }
        var $this= e.currentTarget;		
        //this.broker.trigger("applydatahubmodifyentities",undefined,undefined);
    }
    closesettingscontainer=(e)=>{
        e.preventDefault();
        $("#settingscontainer").removeClass("showsettings");
        $("#datahubentitycontainer>li").removeClass('active');
        setTimeout(function(){$("#settingscontainer").remove();},1000);
    }
    render(){
        return(
            <div></div>
        )
    }
}