import React from 'react';
import ReactDOM from 'react-dom';
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import { lang } from "../../js/helpers/utils/languages/language";
import _ from 'underscore';
import SummaryTableValuesDropdownHtml from './summarytablevaluesdropdown.jsx';
const $=window.$;

export default class SummaryTableValuesDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.values = props.values;
        this.chartnumber = props.chartnumber;
        this.isDirect = ChartInfo.functions.getIsDirect();
        this.isStoredProcedure = ChartInfo.functions.getIsStoredProcedure();
        this.multifact = (props.multifact == 'true');
        this.multifactentities = props.multifact == 'true' ? JSON.parse(props.multifactentities) : props.multifactentities;
    }
    render() {
        $(".summarytable-valuesList").append("<li class='xs-list-item'><input class='form-control form-control-pivot-values search-image' placeholder="+lang['storyboard.search']+" id='searchinput' type=text'></li>");
        var customFields = JSON.parse(sessionStorage.getItem("customFields"));
        var custommeasurehierarachyarray = ChartInfo.functions.getCustomMeasureField();
        var facttables = this.props.facttables;
        var convertedTypes = {};
        _.each(JSON.parse(sessionStorage.getItem("convertedtypes")), function (list) {
            convertedTypes[list["columnName"]] = list["dataType"]
        });
        var tempvalues = _.clone(this.values);
        if (this.multifact) {
            var allowedEntities = ChartInfo.functions.getAllowedEntiesForSummaryTable(this.multifactentities, customFields, facttables, "aggdrp");
            if (allowedEntities != undefined && allowedEntities.length > 0) {
                tempvalues = [];
                var tesmpCustomFields = [];
                var tempcustomhierarchy = [];
                var tempcustommeasure = [];
                _.each(this.values, function (column) {
                    if (column != undefined) {
                        var tempColumn = _.clone(column);
                        var columnName = column.columnName;
                        var tablename = columnName.split(".")[0];
                        if (allowedEntities.indexOf(tablename) >= 0) {
                            tempvalues.push(tempColumn);
                        }
                    }
                });

                if (customFields != null && customFields != undefined) {
                    _.each(customFields, function (cfObj) {
                        var disable = false;
                        _.each(cfObj.usedFields, function (hierarchy) {
                            var tablename = hierarchy.split(".")[0];
                            if (allowedEntities.indexOf(tablename) < 0) {
                                disable = true;
                            }
                        });
                        if (!disable) {
                            tesmpCustomFields.push(cfObj);
                        }
                    });
                    customFields = tesmpCustomFields;
                }
                if (custommeasurehierarachyarray != null && custommeasurehierarachyarray != undefined) {
                    _.each(custommeasurehierarachyarray, function (cfObj) {
                        var disable = false;
                        _.each(cfObj.usedFields.split(","), function (hierarchy) {
                            var columnName = hierarchy;
                            var tablename = hierarchy.split(".")[0];
                            if (customFields != null && customFields != undefined) {
                                var customFieldObject = $.grep(customFields, function (e) { return e.name == columnName });
                                if (customFieldObject != undefined && customFieldObject.length > 0) {
                                    _.each(customFieldObject[0].usedFields, function (customFieldObj) {
                                        tablename = customFieldObj.split(".")[0];
                                        if (allowedEntities.indexOf(tablename) < 0) {
                                            disable = true;
                                        }
                                    });
                                }
                            }
                            if (allowedEntities.indexOf(tablename) < 0) {
                                disable = true;
                            }
                        });
                        if (!disable) {
                            tempcustommeasure.push(cfObj);
                        }
                    });
                    custommeasurehierarachyarray = tempcustommeasure;
                }
            }
        }
        tempvalues.sort(function(a, b){
            var x = a.columnDisplayName.toLowerCase();
            var y = b.columnDisplayName.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0 ;
            });
            if(customFields != undefined && customFields.length != 0){
            customFields.sort(function(a, b){
            var x = a.name.toLowerCase();
            var y = b.name.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0 ;
            });
            }
            if(custommeasurehierarachyarray != undefined && custommeasurehierarachyarray.length != 0){
            custommeasurehierarachyarray.sort(function(a, b){
            var x = a.name.toLowerCase();
            var y = b.name.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0 ;
            });
            }
        var today = new Date();
        var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
        $('.summarytable-valuesList').append("<div className='" + dynamicdiv + "hide' id='" + dynamicdiv + "'></div>");

        ReactDOM.render(
            <SummaryTableValuesDropdownHtml addValue={this.props.addValue} chartnumber={this.chartnumber} values={tempvalues} customFields={customFields} convertedTypes={convertedTypes} measurehierarachy={custommeasurehierarachyarray} isDirect={this.isDirect} isStoredProcedure={this.isStoredProcedure} />,document.getElementById(dynamicdiv)
        );
        return(<></>);
    }

}