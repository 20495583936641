import React from 'react';
import { lang } from "../js/helpers/utils/languages/language";
export default class AdvancedChartSettingsModalTemplate extends React.Component {
    render() {
        return (
            <div className="modal fade in left advancedviewremove" id="advancedchartsettingsviewmodal" tabIndex="-1" role="dialog" aria-labelledby="advancedsharestoryboardpermissionmodal" aria-hidden="false" style={{display:"block"}}>
                <div className="modal-backdrop fade in"></div>
                <div className="modal-dialog modal-lg modal-dialog-lg-center modal-dialog-center" style={{zIndex:"1050"}}>
                    <div className="modal-content">
                        <div className="modal-header boc-modal-hf-padding">
                            <button type="button" className="close white" id="close" aria-hidden="true" data-number="" onClick={this.props.close}>&times;</button>
                            <h4 className="modal-title" id="mySmallModalLabel"><i className="fa fa-cogs"></i> {lang["storyboard.advancdchart"]}</h4>
                        </div>
                        <div className="modal-body scrollset">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="row-same-height">
                                        <div className="col-xs-12 col-xs-height settingscontent columnspermissiondiv">
                                            <div className="modal-height-fix">
                                                <div className="col-xs-12" id="myGroup">
                                                    <div className="panel panel-transparent" id="paneltextsetting">
                                                        <div className="panel-heading" data-parent="#myGroup" data-toggle="collapse" href="#textsettingsCollapse" aria-expanded="true">
                                                            <h4 className="panel-title">
                                                                <i className="fa fa-chevron-circle-right" aria-hidden="false"></i> {lang["storyboard.textsettings"]}
                                                            </h4>
                                                        </div>
                                                        <div id="textsettingsCollapse" className="panel-collapse collapse in" aria-expanded="true">
                                                            <div className="panel-body">
                                                                <div className="col-xs-12 nopadding">
                                                                    <fieldset className="advancedchartsettings-fieldset adcsglobalfont">
                                                                        <legend className="savereport-border">{lang["storyboard.globalfont"]}</legend>
                                                                        <div className="col-xs-12 nopadding">
                                                                            <div className="table-responsive">
                                                                                <table className="table table-custom">
                                                                                    <tbody>
                                                                                        <tr className="fontstyle">
                                                                                            <td>{lang["storyboard.fontstyle"]}</td>
                                                                                            <td>
                                                                                                <select className="fontfamily" id="textsettingfontfamily">
                                                                                                    <option>{lang["storyboard.choosefont"]}</option>
                                                                                                    <option value='"Work Sans","Lato","Source Sans Pro",sans-serif' selected>{lang["storyboard.worksans"]}</option>
                                                                                                    <option value='"Lato","Source Sans Pro",sans-serif'>{lang["storyboard.lato"]}</option>
                                                                                                    <option value='"Source Sans Pro",sans-serif'>{lang["storyboard.sanspro"]}</option>
                                                                                                    <option value='verdana'>{lang["storyboard.verdana"]}</option>
                                                                                                    <option value='arial'>{lang["storyboard.arial"]}</option>
                                                                                                    <option value='tahoma'>{lang["storyboard.tahoma"]}</option>
                                                                                                    <option value='times new roman'>{lang["storyboard.timesroman"]}</option>
                                                                                                    <option value='cursive'>{lang["storyboard.cursive"]}</option>
                                                                                                    <option value='monospace'>{lang["storyboard.monospace"]}</option>
                                                                                                    <option vlaue='fantasy'>{lang["storyboard.fantasy"]}</option>
                                                                                                </select>
                                                                                                <div className="grouped-input">
                                                                                                    <input type="number" id="globalsmallfontsizes" className="small-input fontsize" defaultValue={this.props.advanced.chart.style.fontSize.replace('px','').replace('rem','').replace('em','')} />
                                                                                                    <select className="fontsizeparameter" id="fontsizeparametertext">
                                                                                                        <option value="px">{lang["storyboard.px"]}</option>
                                                                                                        <option value="em">{lang["storyboard.em"]}</option>
                                                                                                        <option value="rem">{lang["storyboard.rem"]}</option>
                                                                                                    </select>
                                                                                                </div>
                                                                                                <div className="btn-group" data-toggle="buttons">
                                                                                                    {
                                                                                                        (this.props.advanced.chart.style.fontWeight != 'bold') ? (
                                                                                                            <label className="btn btn-bird-group" id="boldtext" title={lang["storyboard.bold"]}>
                                                                                                                <input type="checkbox" className="fontweight" autoComplete="off" />
                                                                                                                <i className="fa fa-bold fa-solid"></i>
                                                                                                            </label>
                                                                                                        ) : (
                                                                                                                <label className="btn btn-bird-group active" title={lang["storyboard.bold"]}>
                                                                                                                    <input type="checkbox" className="fontweight" autoComplete="off" defaultChecked />
                                                                                                                    <i className="fa fa-bold fa-solid"></i>
                                                                                                                </label>
                                                                                                            )
                                                                                                    }
                                                                                                    {
                                                                                                        (this.props.advanced.chart.style.fontStyle != 'italic') ? (
                                                                                                            <label className="btn btn-bird-group" id="italictext" title={lang["storyboard.italic"]}>
                                                                                                                <input type="checkbox" className="fontitalic" autoComplete="off" />
                                                                                                                <i className="fa fa-italic fa-solid"></i>
                                                                                                            </label>
                                                                                                        ) : (
                                                                                                                <label className="btn btn-bird-group active" title={lang["storyboard.italic"]}>
                                                                                                                    <input type="checkbox" className="fontitalic" autoComplete="off" defaultChecked />
                                                                                                                    <i className="fa fa-italic fa-solid"></i>
                                                                                                                </label>
                                                                                                            )
                                                                                                    }
                                                                                                </div>
                                                                                                <div className="input-group advanced-color-selector">
                                                                                                    <input type="text" id="advancedselectors" placeholder={lang["storyboard.#424242"]} defaultValue={this.props.advanced.chart.style.color} className="form-control fontcolor" />
                                                                                                    <span className="input-group-addon" id="advancedselectorscolor">
                                                                                                        <i style={{backgroundColor: this.props.advanced.chart.style.color}}></i>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </fieldset>
                                                                    <fieldset className="advancedchartsettings-fieldset adcsoncharttitle">
                                                                        <legend className="savereport-border">{lang["storyboard.oncharttitle"]}</legend>
                                                                        <div className="col-xs-12 nopadding">
                                                                            <div className="table-responsive">
                                                                                <table className="table table-custom">
                                                                                    <tbody>
                                                                                        <tr className="maintitle">
                                                                                            <td>{lang["storyboard.maintitle"]}</td>
                                                                                            <td>
                                                                                                <span className="marg-righ-5">{lang["storyboard.title"]}</span>
                                                                                                <input type="text" className="marg-bot-10 input-width-50 title" id="entertitlehere" placeholder={lang["storyboard.entertitle"]} defaultValue={this.props.advanced.title.text} />
                                                                                                <span className="marg-left-10 marg-righ-10">{lang["storyboard.widthadjust"]}</span>
                                                                                                <input type="number" className="input-width-70px widthadjust" id="widthadjusts" defaultValue={this.props.advanced.title.widthAdjust} />
                                                                                                <br />
                                                                                                <span className="marg-righ-5">{lang["storyboard.font"]}</span>
                                                                                                <select className="marg-bot-10 fontfamily" id="oncharttitlefontfamily">
                                                                                                    <option>{lang["storyboard.choosefont"]}</option>
                                                                                                    <option value='"Work Sans","Lato","Source Sans Pro",sans-serif' selected>{lang["storyboard.worksans"]}</option>
                                                                                                    <option value='"Lato","Source Sans Pro",sans-serif'>{lang["storyboard.lato"]}</option>
                                                                                                    <option value='"Source Sans Pro",sans-serif'>{lang["storyboard.sanspro"]}</option>
                                                                                                    <option value='verdana'>{lang["storyboard.verdana"]}</option>
                                                                                                    <option value='arial'>{lang["storyboard.arial"]}</option>
                                                                                                    <option value='tahoma'>{lang["storyboard.tahoma"]}</option>
                                                                                                    <option value='times new roman'>{lang["storyboard.timesroman"]}</option>
                                                                                                    <option value='cursive'>{lang["storyboard.cursive"]}</option>
                                                                                                    <option value='monospace'>{lang["storyboard.monospace"]}</option>
                                                                                                    <option vlaue='fantasy'>{lang["storyboard.fantasy"]}</option>
                                                                                                </select>
                                                                                                <div className="grouped-input">
                                                                                                    <input type="number" id="mainsmallfontsizes" className="small-input fontsize" defaultValue={this.props.advanced.title.style.fontSize.replace('px','').replace('rem','').replace('em','')} />
                                                                                                    <select className="fontsizeparameter" id="onchartfontsizeparameters">
                                                                                                        <option value="px" selected>{lang["storyboard.px"]}</option>
                                                                                                        <option value="em">{lang["storyboard.em"]}</option>
                                                                                                        <option value="rem">{lang["storyboard.rem"]}</option>
                                                                                                    </select>
                                                                                                </div>
                                                                                                <div className="btn-group" data-toggle="buttons">
                                                                                                    {
                                                                                                        (this.props.advanced.title.style.fontWeight != 'bold') ? (
                                                                                                            <label className="btn btn-bird-group" id="onchartbold" title={lang["storyboard.bold"]}>
                                                                                                                <input type="checkbox" className="fontweight" autoComplete="off" />
                                                                                                                <i className="fa fa-bold fa-solid"></i>
                                                                                                            </label>
                                                                                                        ) : (
                                                                                                                <label className="btn btn-bird-group active" title={lang["storyboard.bold"]}>
                                                                                                                    <input type="checkbox" className="fontweight" autoComplete="off" defaultChecked />
                                                                                                                    <i className="fa fa-bold fa-solid"></i>
                                                                                                                </label>
                                                                                                            )
                                                                                                    }
                                                                                                    {
                                                                                                        (this.props.advanced.title.style.fontStyle != 'italic') ? (
                                                                                                            <label className="btn btn-bird-group" id="onchartitalic" title={lang["storyboard.italic"]}>
                                                                                                                <input type="checkbox" className="fontitalic" autoComplete="off" />
                                                                                                                <i className="fa fa-italic fa-solid"></i>
                                                                                                            </label>
                                                                                                        ) : (
                                                                                                                <label className="btn btn-bird-group active" title={lang["storyboard.italic"]}>
                                                                                                                    <input type="checkbox" className="fontitalic" autoComplete="off" defaultChecked />
                                                                                                                    <i className="fa fa-italic fa-solid"></i>
                                                                                                                </label>
                                                                                                            )
                                                                                                    }
                                                                                                </div>
                                                                                                <div className="input-group advanced-color-selector">
                                                                                                    <input type="text" id="onchartadvanceds" placeholder={lang["storyboard.#424242"]} defaultValue={this.props.advanced.title.style.color} className="form-control fontcolor" />
                                                                                                    <span className="input-group-addon" id="onchartadvancedscolor">
                                                                                                        <i style={{backgroundColor:"#424242"}}></i>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <br />
                                                                                                <span className="marg-righ-5">{lang["storyboard.align"]}</span>
                                                                                                <select className="align" id="onchartalign">
                                                                                                    <option value="center" selected>{lang["storyboard.center"]}</option>
                                                                                                    <option value="left">{lang["storyboard.left"]}</option>
                                                                                                    <option value="right">{lang["storyboard.right"]}</option>
                                                                                                </select>
                                                                                                <select className="marg-left-5 verticalalign" id="onchartverticalalign">
                                                                                                    <option value="top" selected>{lang["storyboard.top"]}</option>
                                                                                                    <option value="middle">{lang["storyboard.middle"]}</option>
                                                                                                    <option value="bottom">{lang["storyboard.bottom"]}</option>
                                                                                                </select>
                                                                                                <span className="marg-left-10 marg-righ-10 advanced-checkbox">
                                                                                                    <label className="advanced-checkbox-label" id="onchartlabels">
                                                                                                        {
                                                                                                            (this.props.advanced.title.floating != true) ? (
                                                                                                                <input type="checkbox" className="advanced-checkbox-input floatingtext" />
                                                                                                            ) : (
                                                                                                                    <input type="checkbox" className="advanced-checkbox-input floatingtext" defaultChecked />
                                                                                                                )
                                                                                                        }
                                                                                                        {lang["storyboard.floating"]}
                                                                                                    </label>
                                                                                                </span>
                                                                                                <span className="marg-left-5">{lang["storyboard.capx"]}</span>
                                                                                                <input type="number" className="input-width-70px xpostion" id="onchartxpostion" defaultValue={this.props.advanced.title.x} />
                                                                                                <span className="marg-left-5">{lang["storyboard.capy"]}</span>
                                                                                                <input type="number" className="input-width-70px ypostion" id="onchartypostion" defaultValue={this.props.advanced.title.y} />
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr className="subtitle">
                                                                                            <td>{lang["storyboard.subtitle"]}</td>
                                                                                            <td>
                                                                                                <span className="marg-righ-5">{lang["storyboard.title"]}</span>
                                                                                                <input type="text" className="marg-bot-10 input-width-50 title" id="entermaintitle" placeholder={lang["storyboard.entertitle"]} defaultValue={this.props.advanced.subtitle.text} />
                                                                                                <span className="marg-left-10 marg-righ-10">{lang["storyboard.widthadjust"]}</span>
                                                                                                <input type="number" className="input-width-70px widthadjust" id="mainwidthadjusts" value={this.props.advanced.subtitle.widthAdjust} />
                                                                                                <br />
                                                                                                <span className="marg-righ-5">{lang["storyboard.font"]}</span>
                                                                                                <select className="marg-bot-10 fontfamily" id="onchartmainfontfamily">
                                                                                                    <option>{lang["storyboard.choosefont"]}</option>
                                                                                                    <option value='"Work Sans","Lato","Source Sans Pro",sans-serif' selected>{lang["storyboard.worksans"]}</option>
                                                                                                    <option value='"Lato","Source Sans Pro",sans-serif'>{lang["storyboard.lato"]}</option>
                                                                                                    <option value='"Source Sans Pro",sans-serif'>{lang["storyboard.sanspro"]}</option>
                                                                                                    <option value='verdana'>{lang["storyboard.verdana"]}</option>
                                                                                                    <option value='arial'>{lang["storyboard.arial"]}</option>
                                                                                                    <option value='tahoma'>{lang["storyboard.tahoma"]}</option>
                                                                                                    <option value='times new roman'>{lang["storyboard.timesroman"]}</option>
                                                                                                    <option value='cursive'>{lang["storyboard.cursive"]}</option>
                                                                                                    <option value='monospace'>{lang["storyboard.monospace"]}</option>
                                                                                                    <option vlaue='fantasy'>{lang["storyboard.fantasy"]}</option>
                                                                                                </select>
                                                                                                <div className="grouped-input">
                                                                                                    <input type="number" id="mainsmallfontsizes" className="small-input fontsize" defaultValue={this.props.advanced.subtitle.style.fontSize.replace('px','').replace('rem','').replace('em','')} />
                                                                                                    <select className="fontsizeparameter" id="mainfontsizeparameters">
                                                                                                        <option value="px" selected>{lang["storyboard.px"]}</option>
                                                                                                        <option value="em">{lang["storyboard.em"]}</option>
                                                                                                        <option value="rem">{lang["storyboard.rem"]}</option>
                                                                                                    </select>
                                                                                                </div>
                                                                                                <div className="btn-group" data-toggle="buttons">
                                                                                                    {
                                                                                                        (this.props.advanced.subtitle.style.fontWeight != 'bold') ? (
                                                                                                            <label className="btn btn-bird-group" id="mainbold" title={lang["storyboard.bold"]}>
                                                                                                                <input type="checkbox" className="fontweight" autoComplete="off" />
                                                                                                                <i className="fa fa-bold fa-solid"></i>
                                                                                                            </label>
                                                                                                        ) : (
                                                                                                                <label className="btn btn-bird-group active" title={lang["storyboard.bold"]}>
                                                                                                                    <input type="checkbox" className="fontweight" autoComplete="off" defaultChecked />
                                                                                                                    <i className="fa fa-bold fa-solid"></i>
                                                                                                                </label>
                                                                                                            )
                                                                                                    }
                                                                                                    {
                                                                                                        (this.props.advanced.subtitle.style.fontStyle != 'italic') ? (
                                                                                                            <label className="btn btn-bird-group" id="mainitalic" title={lang["storyboard.italic"]}>
                                                                                                                <input type="checkbox" className="fontitalic" autoComplete="off" />
                                                                                                                <i className="fa fa-italic fa-solid"></i>
                                                                                                            </label>
                                                                                                        ) : (
                                                                                                                <label className="btn btn-bird-group active" title={lang["storyboard.italic"]}>
                                                                                                                    <input type="checkbox" className="fontitalic" autoComplete="off" defaultChecked />
                                                                                                                    <i className="fa fa-italic fa-solid"></i>
                                                                                                                </label>
                                                                                                            )
                                                                                                    }
                                                                                                </div>
                                                                                                <div className="input-group advanced-color-selector">
                                                                                                    <input type="text" id="mainadvancedselectors" placeholder={lang["storyboard.#424242"]} defaultValue={this.props.advanced.subtitle.style.color} className="form-control fontcolor" />
                                                                                                    <span className="input-group-addon" id="mainadvancedselectorscolor">
                                                                                                        <i style={{backgroundColor: this.props.advanced.title.style.color}}></i>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <br />
                                                                                                <span className="marg-righ-5">{lang["storyboard.align"]}</span>
                                                                                                <select className="align" id="subalign">
                                                                                                    <option value="center" selected>{lang["storyboard.center"]}</option>
                                                                                                    <option value="left">{lang["storyboard.left"]}</option>
                                                                                                    <option value="right">{lang["storyboard.right"]}</option>
                                                                                                </select>
                                                                                                <select className="marg-left-5 verticalalign" id="subverticalalign">
                                                                                                    <option value="top" selected>{lang["storyboard.top"]}</option>
                                                                                                    <option value="middle">{lang["storyboard.middle"]}</option>
                                                                                                    <option value="bottom">{lang["storyboard.bottom"]}</option>
                                                                                                </select>
                                                                                                <span className="marg-left-10 marg-righ-10 advanced-checkbox">
                                                                                                    <label className="advanced-checkbox-label" id="sublabels">
                                                                                                        {
                                                                                                            (this.props.advanced.subtitle.floating != true) ? (
                                                                                                                <input type="checkbox" className="advanced-checkbox-input floatingtext" />
                                                                                                            ) : (
                                                                                                                    <input type="checkbox" className="advanced-checkbox-input floatingtext" defaultChecked />
                                                                                                                )
                                                                                                        }
                                                                                                        {lang["storyboard.floating"]}
                                                                                                    </label>
                                                                                                </span>
                                                                                                <span className="marg-left-5">{lang["storyboard.capx"]}</span>
                                                                                                <input type="number" className="input-width-70px xpostion" id="subxpostion" defaultValue={this.props.advanced.subtitle.x} />
                                                                                                <span className="marg-left-5">{lang["storyboard.capy"]}</span>
                                                                                                <input type="number" className="input-width-70px ypostion" id="subypostion" defaultValue={this.props.advanced.subtitle.y} />
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </fieldset>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="panel panel-transparent" id="panelchartsettingpop">
                                                        <div className="panel-heading" data-parent="#myGroup" data-toggle="collapse" href="#chartSettingsCollapse" aria-expanded="false">
                                                            <h4 className="panel-title">
                                                                <i className="fa fa-chevron-circle-right" aria-hidden="true"></i> {lang["storyboard.chartsettings"]}
                                                            </h4>
                                                        </div>
                                                        <div id="chartSettingsCollapse" className="panel-collapse collapse" aria-expanded="false">
                                                            <div className="panel-body">
                                                                <div className="col-xs-12 nopadding">
                                                                    <fieldset className="advancedchartsettings-fieldset adcschartinteraction">
                                                                        <legend className="savereport-border">{lang["storyboard.chartintraction"]}</legend>
                                                                        <div className="col-xs-12 nopadding">
                                                                            <div className="table-responsive">
                                                                                <table className="table table-custom">
                                                                                    <tbody>
                                                                                        <tr className="chartzooing">
                                                                                            <td>{lang["storyboard.zooming"]}</td>
                                                                                            <td>
                                                                                                <select className="zoom" id="chartzooming">
                                                                                                    <option value="">{lang["storyboard.none"]}</option>
                                                                                                    <option value="xy" selected>{lang["storyboard.smxy"]}</option>
                                                                                                    <option value="x">{lang["storyboard.smx"]}</option>
                                                                                                    <option value="y">{lang["storyboard.smy"]}</option>
                                                                                                </select>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr className="polar">
                                                                                            <td>{lang["storyboard.polarprotect"]}</td>
                                                                                            <td>
                                                                                                <label className="advanced-checkbox-label" id="chartprojection">
                                                                                                    {
                                                                                                        (this.props.advanced.chart.polar != true) ? (
                                                                                                            <input type="checkbox" className="advanced-checkbox-input polarprojection" />
                                                                                                        ) : (
                                                                                                                <input type="checkbox" className="advanced-checkbox-input polarprojection" defaultChecked />
                                                                                                            )
                                                                                                    }
                                                                                                </label>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr className="hiddenseries">
                                                                                            <td>{lang["storyboard.ignorehidden"]}</td>
                                                                                            <td>
                                                                                                <label className="advanced-checkbox-label" id="chartignoreseries">
                                                                                                    {
                                                                                                        (this.props.advanced.chart.ignoreHiddenSeries != true) ? (
                                                                                                            <input type="checkbox" className="advanced-checkbox-input ignorehiddenseries" />
                                                                                                        ) : (
                                                                                                                <input type="checkbox" className="advanced-checkbox-input ignorehiddenseries" defaultChecked />
                                                                                                            )
                                                                                                    }
                                                                                                </label>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </fieldset>
                                                                    <fieldset className="advancedchartsettings-fieldset adcschartarea">
                                                                        <legend className="savereport-border">{lang["storyboard.chartarea"]}</legend>
                                                                        <div className="col-xs-12 nopadding">
                                                                            <div className="table-responsive">
                                                                                <table className="table table-custom">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>{lang["storyboard.bgcolor"]}</td>
                                                                                            <td>
                                                                                                <div className="input-group advanced-color-selector">
                                                                                                    <input type="text" id="chartareabackground" placeholder={lang["storyboard.transparent"]} defaultValue={this.props.advanced.chart.backgroundColor} className="form-control backgroundcolor" />
                                                                                                    <span className="input-group-addon" id="chartareabackgroundcolor">
                                                                                                        <i style={{backgroundColor:"transparent"}}></i>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>{lang["storyboard.brdrcolor"]}</td>
                                                                                            <td>
                                                                                                <div className="input-group advanced-color-selector">
                                                                                                    <input type="text" id="chartareaborder" placeholder={lang["storyboard.transparent"]} defaultValue={this.props.advanced.chart.borderColor} className="form-control bordercolor" />
                                                                                                    <span className="input-group-addon" id="chartareabordercolor">
                                                                                                        <i style={{backgroundColor:"transparent"}}></i>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>{lang["storyboard.brdrwidth"]}</td>
                                                                                            <td>
                                                                                                <input type="number" className="input-width-70px borderwidth" id="chartareaborderwidth" defaultValue={this.props.advanced.chart.borderWidth} />
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>{lang["storyboard.brdrradius"]}</td>
                                                                                            <td>
                                                                                                <input type="number" className="input-width-70px borderradius" id="chartareaborderradius" defaultValue={this.props.advanced.chart.borderRadius} />
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr className="chartspacing">
                                                                                            <td>{lang["storyboard.spacing"]}</td>
                                                                                            <td>
                                                                                                <span className="marg-righ-5">{lang["storyboard.top"]}</span>
                                                                                                <input type="number" className="input-width-70px topspacing" id="chartareatop" defaultValue={this.props.advanced.chart.spacing[0]} />
                                                                                                <span className="marg-left-5 marg-righ-5">{lang["storyboard.right"]}</span>
                                                                                                <input type="number" className="input-width-70px rightspacing" id="chartarearight" defaultValue={this.props.advanced.chart.spacing[1]} />
                                                                                                <span className="marg-left-5 marg-righ-5">{lang["storyboard.bottom"]}</span>
                                                                                                <input type="number" className="input-width-70px bottomspacing" id="chartareabottom" defaultValue={this.props.advanced.chart.spacing[2]} />
                                                                                                <span className="marg-left-5 marg-righ-5">{lang["storyboard.left"]}</span>
                                                                                                <input type="number" className="input-width-70px leftspacing" id="chartarealeft" defaultValue={this.props.advanced.chart.spacing[3]} />
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </fieldset>
                                                                    <fieldset className="advancedchartsettings-fieldset adcsplotarea">
                                                                        <legend className="savereport-border">{lang["storyboard.plotarea"]}</legend>
                                                                        <div className="col-xs-12 nopadding">
                                                                            <div className="table-responsive">
                                                                                <table className="table table-custom">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>{lang["storyboard.bgcolor"]}</td>
                                                                                            <td>
                                                                                                <div className="input-group advanced-color-selector">
                                                                                                    <input type="text" id="plotareabackground" placeholder={lang["storyboard.transparent"]} defaultValue={this.props.advanced.chart.plotBackgroundColor} className="form-control backgroundcolor" />
                                                                                                    <span className="input-group-addon" id="plotareabackgroundcolor">
                                                                                                        <i style={{backgroundColor:"transparent"}}></i>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>{lang["storyboard.brdrcolor"]}</td>
                                                                                            <td>
                                                                                                <div className="input-group advanced-color-selector">
                                                                                                    <input type="text" id="plotareaborder" placeholder={lang["storyboard.transparent"]} defaultValue={this.props.advanced.chart.plotBorderColor} className="form-control bordercolor" />
                                                                                                    <span className="input-group-addon" id="plotareabordercolor">
                                                                                                        <i style={{backgroundColor:"transparent"}}></i>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>{lang["storyboard.brdrwidth"]}</td>
                                                                                            <td>
                                                                                                <input type="number" className="input-width-70px borderwidth" id="plotareaborderwidth" defaultValue={this.props.advanced.chart.plotBorderWidth} />
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </fieldset>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="panel panel-transparent" id="axissettingspop">
                                                        <div className="panel-heading" data-parent="#myGroup" data-toggle="collapse" href="#chartAxesCollapse" aria-expanded="false">
                                                            <h4 className="panel-title">
                                                                <i className="fa fa-chevron-circle-right" aria-hidden="true"></i> {lang["storyboard.axessettings"]}
                                                            </h4>
                                                        </div>
                                                        <div id="chartAxesCollapse" className="panel-collapse collapse" aria-expanded="false">
                                                            <div className="panel-body">
                                                                <div className="col-xs-12 nopadding">
                                                                    <fieldset className="advancedchartsettings-fieldset adcsaxessetup">
                                                                        <legend className="savereport-border">{lang["storyboard.axesetup"]}</legend>
                                                                        <div className="col-xs-12 nopadding">
                                                                            <div className="table-responsive">
                                                                                <table className="table table-custom">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>{lang["storyboard.invertaxes"]}</td>
                                                                                            <td>
                                                                                                <label className="advanced-checkbox-label" id="axessetupinverted">
                                                                                                    {
                                                                                                        (this.props.advanced.chart.inverted != true) ? (
                                                                                                            <input type="checkbox" className="advanced-checkbox-input invertedaxes" />
                                                                                                        ) : (
                                                                                                                <input type="checkbox" className="advanced-checkbox-input invertedaxes" defaultChecked />
                                                                                                            )
                                                                                                    }
                                                                                                </label>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>{lang["storyboard.alignticks"]}<br />{lang["storyboard.mulaxes"]}</td>
                                                                                            <td>
                                                                                                <label className="advanced-checkbox-label" id="axessetupalignticks">
                                                                                                    {
                                                                                                        (this.props.advanced.chart.alignTicks != true) ? (
                                                                                                            <input type="checkbox" className="advanced-checkbox-input alignticks" />
                                                                                                        ) : (
                                                                                                                <input type="checkbox" className="advanced-checkbox-input alignticks" defaultChecked />
                                                                                                            )
                                                                                                    }
                                                                                                </label>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr className="stacking">
                                                                                            <td>{lang["storyboard.stacking"]}</td>
                                                                                            <td>
                                                                                                <div className="btn-group" data-toggle="buttons">
                                                                                                    {
                                                                                                        (this.props.advanced.plotOptions.series.stacking == "") ? (
                                                                                                            <label className="btn btn-bird-group active" id="axesstackingnone">
                                                                                                                <input type="radio" autoComplete="off" name="stacking" value="" defaultChecked />{lang["storyboard.none"]}
                                                                                                            </label>
                                                                                                        ) : (
                                                                                                                <label className="btn btn-bird-group" id="axesstackingnone">
                                                                                                                    <input type="radio" autoComplete="off" name="stacking" value="" />{lang["storyboard.none"]}
                                                                                                                </label>
                                                                                                            )
                                                                                                    }
                                                                                                    {
                                                                                                        (this.props.advanced.plotOptions.series.stacking == "normal") ? (
                                                                                                            <label className="btn btn-bird-group active" id="axesstackingnormal">
                                                                                                                <input type="radio" autoComplete="off" name="stacking" value="normal" defaultChecked />
                                                                                                                {lang["storyboard.normal"]}
                                                                                                            </label>
                                                                                                        ) : (
                                                                                                                <label className="btn btn-bird-group" id="axesstackingnormal">
                                                                                                                    <input type="radio" autoComplete="off" name="stacking" value="normal" />
                                                                                                                    {lang["storyboard.normal"]}
                                                                                                                </label>
                                                                                                            )
                                                                                                    }
                                                                                                    {
                                                                                                        (this.props.advanced.plotOptions.series.stacking == "percent") ? (
                                                                                                            <label className="btn btn-bird-group active" id="axesstackingpercent">
                                                                                                                <input type="radio" autoComplete="off" name="stacking" value="percent" defaultChecked />
                                                                                                                {lang["storyboard.per"]}
                                                                                                            </label>
                                                                                                        ) : (
                                                                                                                <label className="btn btn-bird-group" id="axesstackingpercent">
                                                                                                                    <input type="radio" autoComplete="off" name="stacking" value="percent" />
                                                                                                                    {lang["storyboard.per"]}
                                                                                                                </label>
                                                                                                            )
                                                                                                    }
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </fieldset>
                                                                    <fieldset className="advancedchartsettings-fieldset adcsxaxis">
                                                                        <legend className="savereport-border">{lang["storyboard.axisx"]}</legend>
                                                                        <div className="col-xs-12 nopadding">
                                                                            <div className="table-responsive">
                                                                                <table className="table table-custom">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>{lang["storyboard.visible"]}</td>
                                                                                            <td>
                                                                                                <label className="advanced-checkbox-label" id="setupxaxisvisible">
                                                                                                    {
                                                                                                        (this.props.advanced.xAxis[0].visible != true) ? (
                                                                                                            <input type="checkbox" className="advanced-checkbox-input xaxisvisible" />
                                                                                                        ) : (
                                                                                                                <input type="checkbox" className="advanced-checkbox-input xaxisvisible" defaultChecked />
                                                                                                            )
                                                                                                    }
                                                                                                </label>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>{lang["storyboard.reversed"]}</td>
                                                                                            <td>
                                                                                                <label className="advanced-checkbox-label" id="setupxaxisreversed">
                                                                                                    {
                                                                                                        (this.props.advanced.xAxis[0].reversed != true) ? (
                                                                                                            <input type="checkbox" className="advanced-checkbox-input xaxisreversed" />
                                                                                                        ) : (
                                                                                                                <input type="checkbox" className="advanced-checkbox-input xaxisreversed" defaultChecked />
                                                                                                            )
                                                                                                    }
                                                                                                </label>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>{lang["storyboard.opposite"]}</td>
                                                                                            <td>
                                                                                                <label className="advanced-checkbox-label" id="setupxaxisopposite">
                                                                                                    {
                                                                                                        (this.props.advanced.xAxis[0].opposite != true) ? (
                                                                                                            <input type="checkbox" className="advanced-checkbox-input xaxisopposite" />
                                                                                                        ) : (
                                                                                                                <input type="checkbox" className="advanced-checkbox-input xaxisopposite" defaultChecked />
                                                                                                            )
                                                                                                    }
                                                                                                </label>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>{lang["storyboard.axislabel"]}</td>
                                                                                            <td>
                                                                                                <span className="marg-righ-5">{lang["storyboard.color"]}</span>
                                                                                                <div className="input-group advanced-color-selector">
                                                                                                    <input type="text" id="xaxislinebackground" placeholder={this.props.advanced.xAxis[0].labels.style.color} value={this.props.advanced.xAxis[0].labels.style.color} className="form-control xaxislabelcolor" />
                                                                                                    <span className="input-group-addon" id="xaxislabelcolor">
                                                                                                        <i style={{backgroundColor:this.props.advanced.xAxis[0].labels.style.color}}></i>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>{lang["storyboard.axisline"]}</td>
                                                                                            <td>
                                                                                                <span className="marg-righ-5">{lang["storyboard.color"]}</span>
                                                                                                <div className="input-group advanced-color-selector">
                                                                                                    <input type="text" id="xaxislinebackground" placeholder={this.props.advanced.xAxis[0].lineColor} defaultValue={this.props.advanced.xAxis[0].lineColor} className="form-control xaxislinecolor" />
                                                                                                    <span className="input-group-addon" id="xaxislinebackgroundcolor">
                                                                                                        <i style={{backgroundColor: this.props.advanced.xAxis[0].lineColor}}></i>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <span className="marg-left-5 marg-righ-5">{lang["storyboard.linewidth"]}</span>
                                                                                                <input type="number" className="input-width-70px xaxislinewidth" id="xaxislinewidth" defaultValue={this.props.advanced.xAxis[0].lineWidth} />
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr className="xaxismajortick">
                                                                                            <td>{lang["storyboard.majortick"]}</td>
                                                                                            <td>
                                                                                                <span className="marg-bot-10 col-xs-12 nopadding">
                                                                                                    <span className="marg-righ-5">{lang["storyboard.width"]}</span>
                                                                                                    <input type="number" className="input-width-70px tickwidth" id="xaxismajorwidth" defaultValue={this.props.advanced.xAxis[0].tickWidth} />
                                                                                                    <span className="marg-left-5 marg-righ-5">{lang["storyboard.interval"]}</span>
                                                                                                    <input type="number" className="input-width-70px tickinterval" id="xaxismajorinterval" min="0" defaultValue={this.props.advanced.xAxis[0].tickInterval!=null?this.props.advanced.xAxis[0].tickInterval:""} step="any" />
                                                                                                    <span className="marg-left-5 marg-righ-5">{lang["storyboard.length"]}</span>
                                                                                                    <input type="number" className="input-width-70px ticklength" id="xaxismajorlength" defaultValue={this.props.advanced.xAxis[0].tickLength} />
                                                                                                </span>
                                                                                                <br />
                                                                                                <span className="marg-righ-5">{lang["storyboard.color"]}</span>
                                                                                                <div className="input-group advanced-color-selector">
                                                                                                    <input type="text" id="xaxismajorbackground" placeholder={this.props.advanced.xAxis[0].tickColor} defaultValue={this.props.advanced.xAxis[0].tickColor} className="form-control tickcolor" />
                                                                                                    <span className="input-group-addon" id="xaxismajorbackgroundcolor">
                                                                                                        <i style={{backgroundColor: this.props.advanced.xAxis[0].tickColor}}></i>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr className="xaxisminortick">
                                                                                            <td>{lang["storyboard.minortick"]}</td>
                                                                                            <td>
                                                                                                <span className="marg-bot-10 col-xs-12 nopadding">
                                                                                                    <span className="marg-righ-5">{lang["storyboard.width"]}</span>
                                                                                                    <input type="number" className="input-width-70px tickwidth" id="xaxisminorwidth" defaultValue={this.props.advanced.xAxis[0].minorTickWidth} />
                                                                                                    <span className="marg-left-5 marg-righ-5">{lang["storyboard.interval"]}</span>
                                                                                                    <input type="number" className="input-width-70px tickinterval" id="xaxisminorinterval" min="0" value={this.props.advanced.xAxis[0].minorTickInterval} step="any" />
                                                                                                    <span className="marg-left-5 marg-righ-5">{lang["storyboard.length"]}</span>
                                                                                                    <input type="number" className="input-width-70px ticklength" id="xaxisminorlength" defaultValue={this.props.advanced.xAxis[0].minorTickLength} />
                                                                                                </span>
                                                                                                <br />
                                                                                                <span className="marg-righ-5">{lang["storyboard.color"]}</span>
                                                                                                <div className="input-group advanced-color-selector">
                                                                                                    <input type="text" id="xaxisminorbackground" placeholder={this.props.advanced.xAxis[0].minorTickColor} defaultValue={this.props.advanced.xAxis[0].minorTickColor} className="form-control tickcolor" />
                                                                                                    <span className="input-group-addon" id="xaxisminorbackgroundcolor">
                                                                                                        <i style={{backgroundColor:this.props.advanced.xAxis[0].minorTickColor}}></i>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr className="xaxisgrid">
                                                                                            <td>{lang["storyboard.gridsettings"]}</td>
                                                                                            <td>
                                                                                                <span className="marg-bot-10 col-xs-12 nopadding">
                                                                                                    <span className="marg-righ-5">{lang["storyboard.width"]}</span>
                                                                                                    <input type="number" className="input-width-70px gridwidth" id="xaxisgridwidth" defaultValue={this.props.advanced.xAxis[0].gridLineWidth} />
                                                                                                    <span className="marg-left-5 marg-righ-5">{lang["storyboard.dashstyle"]}</span>
                                                                                                    <select className="griddashstyle" id="xaxisgriddashstyle">
                                                                                                        <option value="Solid" selected>{lang["storyboard.solid"]}</option>
                                                                                                        <option value="ShortDash">{lang["storyboard.shortdash"]}</option>
                                                                                                        <option value="ShortDot">{lang["storyboard.shortdot"]}</option>
                                                                                                        <option value="ShortDashDotDot" >{lang["storyboard.sdotdot"]}</option>
                                                                                                        <option value="Dot" >{lang["storyboard.dot"]}</option>
                                                                                                        <option value="Dash" >{lang["storyboard.dash"]}</option>
                                                                                                        <option value="LongDash" >{lang["storyboard.longdash"]}</option>
                                                                                                        <option value="DashDot" >{lang["storyboard.dashdot"]}</option>
                                                                                                        <option value="LongDashDot" >{lang["storyboard.ldashdot"]}</option>
                                                                                                        <option value="LongDashDotDot" >{lang["storyboard.ldotdot"]}</option>
                                                                                                    </select>
                                                                                                </span>
                                                                                                <br />
                                                                                                <span className="marg-righ-5">{lang["storyboard.color"]}</span>
                                                                                                <div className="input-group advanced-color-selector">
                                                                                                    <input type="text" id="xaxisgridbackground" placeholder={this.props.advanced.xAxis[0].gridLineColor} defaultValue={this.props.advanced.xAxis[0].gridLineColor} className="form-control gridcolor" />
                                                                                                    <span className="input-group-addon" id="xaxisgridbackgroundcolor">
                                                                                                        <i style={{backgroundColor: this.props.advanced.xAxis[0].gridLineColor}}></i>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </fieldset>
                                                                    <fieldset className="advancedchartsettings-fieldset adcsyaxis">
                                                                        <legend className="savereport-border">{lang["storyboard.axisy"]}</legend>
                                                                        <div className="col-xs-12 nopadding">
                                                                            <div className="table-responsive">
                                                                                <table className="table table-custom">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>{lang["storyboard.visible"]}</td>
                                                                                            <td>
                                                                                                <label className="advanced-checkbox-label" id="yaxisvisibles">
                                                                                                    {
                                                                                                        (this.props.advanced.yAxis[0].visible != true) ? (
                                                                                                            <input type="checkbox" className="advanced-checkbox-input yaxisvisible" />
                                                                                                        ) : (
                                                                                                                <input type="checkbox" className="advanced-checkbox-input yaxisvisible" defaultChecked />
                                                                                                            )
                                                                                                    }
                                                                                                </label>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>{lang["storyboard.reversed"]}</td>
                                                                                            <td>
                                                                                                <label className="advanced-checkbox-label" id="yaxisreversed">
                                                                                                    {
                                                                                                        (this.props.advanced.yAxis[0].reversed != true) ? (
                                                                                                            <input type="checkbox" className="advanced-checkbox-input yaxisreversed" />
                                                                                                        ) : (
                                                                                                                <input type="checkbox" className="advanced-checkbox-input yaxisreversed" defaultChecked />
                                                                                                            )
                                                                                                    }
                                                                                                </label>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>{lang["storyboard.opposite"]}</td>
                                                                                            <td>
                                                                                                <label className="advanced-checkbox-label" id="yaxisopposite">
                                                                                                    {
                                                                                                        (this.props.advanced.yAxis[0].opposite != true) ? (
                                                                                                            <input type="checkbox" className="advanced-checkbox-input yaxisopposite" />
                                                                                                        ) : (
                                                                                                                <input type="checkbox" className="advanced-checkbox-input yaxisopposite" defaultChecked />
                                                                                                            )
                                                                                                    }
                                                                                                </label>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>{lang["storyboard.axislabel"]}</td>
                                                                                            <td>
                                                                                                <span className="marg-righ-5">{lang["storyboard.prefix"]}</span>
                                                                                                <input type="text" className="input-width-70px yaxisaxislabelprefix" id="yaxisaxislabelprefix" defaultValue={this.props.advanced.yAxis[0].axisLabelPrefix} placeholder="$" />
                                                                                                <span className="marg-left-5 marg-righ-5">{lang["storyboard.suffix"]}</span>
                                                                                                <input type="text" className="input-width-70px yaxisaxislabelsuffix" id="yaxisaxislabelsuffix" defaultValue={this.props.advanced.yAxis[0].axisLabelSuffix} placeholder="%" />
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>{lang["storyboard.axislabel"]}</td>
                                                                                            <td>
                                                                                                <span className="marg-righ-5">{lang["storyboard.color"]}</span>
                                                                                                <div className="input-group advanced-color-selector">
                                                                                                    <input type="text" id="yaxislinebackground" placeholder={this.props.advanced.yAxis[0].labels.style.color} defaultValue={this.props.advanced.yAxis[0].labels.style.color} className="form-control yaxislabelcolor" />
                                                                                                    <span className="input-group-addon" id="yaxislabelcolor">
                                                                                                        <i style={{backgroundColor: this.props.advanced.yAxis[0].labels.style.color}}></i>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>{lang["storyboard.axisline"]}</td>
                                                                                            <td>
                                                                                                <span className="marg-righ-5">{lang["storyboard.color"]}</span>
                                                                                                <div className="input-group advanced-color-selector">
                                                                                                    <input type="text" id="yaxislinebackground" placeholder={this.props.advanced.yAxis[0].lineColor} defaultValue={this.props.advanced.yAxis[0].lineColor} className="form-control yaxislinecolor" />
                                                                                                    <span className="input-group-addon" id="yaxislinebackgroundcolor">
                                                                                                        <i style={{backgroundColor: this.props.advanced.yAxis[0].lineColor}}></i>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <span className="marg-left-5 marg-righ-5">{lang["storyboard.linewidth"]}</span>
                                                                                                <input type="number" className="input-width-70px yaxislinewidth" id="yaxislinewidth" defaultValue={this.props.advanced.yAxis[0].lineWidth} />
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr className="yaxismajortick">
                                                                                            <td>{lang["storyboard.majortick"]}</td>
                                                                                            <td>
                                                                                                <span className="marg-bot-10 col-xs-12 nopadding">
                                                                                                    <span className="marg-righ-5">{lang["storyboard.width"]}</span>
                                                                                                    <input type="number" className="input-width-70px tickwidth" id="yaxismajorwidth" defaultValue={this.props.advanced.yAxis[0].tickWidth} />
                                                                                                    <span className="marg-left-5 marg-righ-5">{lang["storyboard.interval"]}</span>
                                                                                                    <input type="number" className="input-width-70px tickinterval" id="yaxismajorinterval" min="0" defaultValue={this.props.advanced.yAxis[0].tickInterval} step="any" />
                                                                                                    <span className="marg-left-5 marg-righ-5">{lang["storyboard.length"]}</span>
                                                                                                    <input type="number" className="input-width-70px ticklength" id="yaxismajorlength" defaultValue={this.props.advanced.yAxis[0].tickLength} />
                                                                                                </span>
                                                                                                <br />
                                                                                                <span className="marg-righ-5">{lang["storyboard.color"]}</span>
                                                                                                <div className="input-group advanced-color-selector">
                                                                                                    <input type="text" id="yaxismajorbackground" placeholder={this.props.advanced.yAxis[0].tickColor} defaultValue={this.props.advanced.yAxis[0].tickColor} className="form-control tickcolor" />
                                                                                                    <span className="input-group-addon" id="yaxismajorbackgroundcolor">
                                                                                                        <i style={{backgroundColor: this.props.advanced.yAxis[0].tickColor}}></i>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr className="yaxisminortick">
                                                                                            <td>{lang["storyboard.minortick"]}</td>
                                                                                            <td>
                                                                                                <span className="marg-bot-10 col-xs-12 nopadding">
                                                                                                    <span className="marg-righ-5">{lang["storyboard.width"]}</span>
                                                                                                    <input type="number" className="input-width-70px tickwidth" id="yaxisminorwidth" defaultValue={this.props.advanced.yAxis[0].minorTickWidth} />
                                                                                                    <span className="marg-left-5 marg-righ-5">{lang["storyboard.interval"]}</span>
                                                                                                    <input type="number" className="input-width-70px tickinterval" id="yaxisminorinterval" min="0" defaultValue={this.props.advanced.yAxis[0].minorTickInterval} step="any" />
                                                                                                    <span className="marg-left-5 marg-righ-5">{lang["storyboard.length"]}</span>
                                                                                                    <input type="number" className="input-width-70px ticklength" id="yaxisminorlength" defaultValue={this.props.advanced.yAxis[0].minorTickLength} />
                                                                                                </span>
                                                                                                <br />
                                                                                                <span className="marg-righ-5">{lang["storyboard.color"]}</span>
                                                                                                <div className="input-group advanced-color-selector">
                                                                                                    <input type="text" id="yaxisminorcolor" placeholder={this.props.advanced.yAxis[0].minorTickColor} defaultValue={this.props.advanced.yAxis[0].minorTickColor} className="form-control tickcolor" />
                                                                                                    <span className="input-group-addon" id="yaxisminorbackgroundcolor">
                                                                                                        <i style={{backgroundColor: this.props.advanced.yAxis[0].minorTickColor}}></i>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr className="yaxisgrid">
                                                                                            <td>{lang["storyboard.gridsettings"]}</td>
                                                                                            <td>
                                                                                                <span className="marg-bot-10 col-xs-12 nopadding">
                                                                                                    <span className="marg-righ-5">{lang["storyboard.width"]}</span>
                                                                                                    <input type="number" className="input-width-70px gridwidth" id="yaxisminorwidth" defaultValue={this.props.advanced.yAxis[0].gridLineWidth} />
                                                                                                    <span className="marg-left-5 marg-righ-5">{lang["storyboard.dashstyle"]}</span>
                                                                                                    <select className="griddashstyle" id="yaxisminordashstyle">
                                                                                                        <option value="Solid" selected>{lang["storyboard.solid"]}</option>
                                                                                                        <option value="ShortDash">{lang["storyboard.shortdash"]}</option>
                                                                                                        <option value="ShortDot">{lang["storyboard.shortdot"]}</option>
                                                                                                        <option value="ShortDashDotDot" >{lang["storyboard.sdotdot"]}</option>
                                                                                                        <option value="Dot" >{lang["storyboard.dot"]}</option>
                                                                                                        <option value="Dash" >{lang["storyboard.dash"]}</option>
                                                                                                        <option value="LongDash" >{lang["storyboard.longdash"]}</option>
                                                                                                        <option value="DashDot" >{lang["storyboard.dashdot"]}</option>
                                                                                                        <option value="LongDashDot" >{lang["storyboard.ldashdot"]}</option>
                                                                                                        <option value="LongDashDotDot" >{lang["storyboard.ldotdot"]}</option>
                                                                                                    </select>
                                                                                                </span>
                                                                                                <br />
                                                                                                <span className="marg-righ-5">{lang["storyboard.color"]}</span>
                                                                                                <div className="input-group advanced-color-selector">
                                                                                                    <input type="text" id="yaxisgridcolor" placeholder={this.props.advanced.yAxis[0].gridLineColor} defaultValue={this.props.advanced.yAxis[0].gridLineColor} className="form-control gridcolor" />
                                                                                                    <span className="input-group-addon" id="yaxisgridbackgroundcolor">
                                                                                                        <i style={{backgroundColor: this.props.advanced.yAxis[0].gridLineColor}}></i>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </fieldset>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="panel panel-transparent" id="datalablessettingspop">
                                                        <div className="panel-heading" data-parent="#myGroup" data-toggle="collapse" href="#chartDataLabelsCollapse" aria-expanded="false">
                                                            <h4 className="panel-title">
                                                                <i className="fa fa-chevron-circle-right" aria-hidden="true"></i> {lang["storyboard.datalabelsettngs"]}
							                                </h4>
                                                        </div>
                                                        <div id="chartDataLabelsCollapse" className="panel-collapse collapse" aria-expanded="false">
                                                            <div className="panel-body">
                                                                <div className="col-xs-12 nopadding">
                                                                    <fieldset className="advancedchartsettings-fieldset adcsdatalabels">
                                                                        <legend className="savereport-border">{lang["storyboard.datalabels"]}</legend>
                                                                        <div className="col-xs-12 nopadding">
                                                                            <div className="table-responsive">
                                                                                <table className="table table-custom">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>{lang["storyboard.prefix"]}</td>
                                                                                            <td>
                                                                                                <input type="text" className="input-width-70px datalabelprefix" id="datalabelprefix" defaultValue={this.props.advanced.plotOptions.series.dataLabels.dataLabelPrefix} placeholder="$" />
                                                                                                <span className="marg-left-5 marg-righ-5">{lang["storyboard.suffix"]}</span>
                                                                                                <input type="text" className="input-width-70px datalabelsuffix" id="datalabelsuffix" defaultValue={this.props.advanced.plotOptions.series.dataLabels.dataLabelSuffix} placeholder="%" />
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr className="fontstyle">
                                                                                            <td>{lang["storyboard.fontstyle"]}</td>
                                                                                            <td>
                                                                                                <select className="fontfamily" id="datalablefontfamily">
                                                                                                    <option>{lang["storyboard.choosefont"]}</option>
                                                                                                    <option value='"Work Sans","Lato","Source Sans Pro",sans-serif' selected>{lang["storyboard.worksans"]}</option>
                                                                                                    <option value='"Lato","Source Sans Pro",sans-serif'>{lang["storyboard.lato"]}</option>
                                                                                                    <option value='"Source Sans Pro",sans-serif'>{lang["storyboard.sanspro"]}</option>
                                                                                                    <option value='verdana'>{lang["storyboard.verdana"]}</option>
                                                                                                    <option value='arial'>{lang["storyboard.arial"]}</option>
                                                                                                    <option value='tahoma'>{lang["storyboard.tahoma"]}</option>
                                                                                                    <option value='times new roman'>{lang["storyboard.timesroman"]}</option>
                                                                                                    <option value='cursive'>{lang["storyboard.cursive"]}</option>
                                                                                                    <option value='monospace'>{lang["storyboard.monospace"]}</option>
                                                                                                    <option vlaue='fantasy'>{lang["storyboard.fantasy"]}</option>
                                                                                                </select>
                                                                                                <div className="grouped-input">
                                                                                                    <input type="number" id="datalabelsmallsize" className="small-input fontsize" defaultValue={this.props.advanced.plotOptions.series.dataLabels.style.fontSize.replace('px','').replace('rem','').replace('em','')} />
                                                                                                    <select className="fontsizeparameter" id="datalabelsizeparameter">
                                                                                                        <option value="px" selected>{lang["storyboard.px"]}</option>
                                                                                                        <option value="em">{lang["storyboard.em"]}</option>
                                                                                                        <option value="rem">{lang["storyboard.rem"]}</option>
                                                                                                    </select>
                                                                                                </div>
                                                                                                <div className="btn-group marg-righ-5" data-toggle="buttons">
                                                                                                    {
                                                                                                        (this.props.advanced.plotOptions.series.dataLabels.style.fontWeight != "bold") ? (
                                                                                                            <label className="btn btn-bird-group" id="datalabelbold" title={lang["storyboard.bold"]}>
                                                                                                                <input type="checkbox" className="fontweight" autoComplete="off" />
                                                                                                                <i className="fa fa-bold fa-solid"></i>
                                                                                                            </label>
                                                                                                        ) : (
                                                                                                                <label className="btn btn-bird-group active" title={lang["storyboard.bold"]}>
                                                                                                                    <input type="checkbox" className="fontweight" autoComplete="off" defaultChecked />
                                                                                                                    <i className="fa fa-bold fa-solid"></i>
                                                                                                                </label>
                                                                                                            )
                                                                                                    }
                                                                                                    {
                                                                                                        (this.props.advanced.plotOptions.series.dataLabels.style.fontStyle != "italic") ? (
                                                                                                            <label className="btn btn-bird-group" id="datalabelitalic" title={lang["storyboard.italic"]}>
                                                                                                                <input type="checkbox" className="fontitalic" autoComplete="off" />
                                                                                                                <i className="fa fa-italic fa-solid"></i>
                                                                                                            </label>
                                                                                                        ) : (
                                                                                                                <label className="btn btn-bird-group active" title={lang["storyboard.italic"]}>
                                                                                                                    <input type="checkbox" className="fontitalic" autoComplete="off" defaultChecked />
                                                                                                                    <i className="fa fa-italic fa-solid"></i>
                                                                                                                </label>
                                                                                                            )
                                                                                                    }

                                                                                                </div>
                                                                                                <div className="input-group advanced-color-selector">
                                                                                                    <input type="text" id="datalabelbackground" placeholder={this.props.advanced.plotOptions.series.dataLabels.style.color} defaultValue={this.props.advanced.plotOptions.series.dataLabels.style.color} className="form-control fontcolor" />
                                                                                                    <span className="input-group-addon" id="datalabelbackgroundcolor">
                                                                                                        <i style={{backgroundColor: this.props.advanced.plotOptions.series.dataLabels.style.color}}></i>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>{lang["storyboard.bgcolor"]}</td>
                                                                                            <td>
                                                                                                <div className="input-group advanced-color-selector">
                                                                                                    <input type="text" id="datalabelselector" placeholder={this.props.advanced.plotOptions.series.dataLabels.backgroundColor} defaultValue={this.props.advanced.plotOptions.series.dataLabels.backgroundColor} className="form-control backgroundcolor" />
                                                                                                    <span className="input-group-addon" id="datalabelselectorbackground">
                                                                                                        <i style={{backgroundColor: this.props.advanced.plotOptions.series.dataLabels.backgroundColor}}></i>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <span className="marg-left-5 marg-righ-5">{lang["storyboard.textoutline"]}</span>
                                                                                                <input type="text" className="textoutline" id="datalabeltextoutline" defaultValue={this.props.advanced.plotOptions.series.dataLabels.style.textOutline} placeholder={lang["storyboard.pxcontrast"]} />
                                                                                                <span className="marg-left-5 marg-righ-5">{lang["storyboard.padding"]}</span>
                                                                                                <input type="number" className="input-width-70px datalablepadding" id="datalabelpadding" defaultValue={this.props.advanced.plotOptions.series.dataLabels.padding} />
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>{lang["storyboard.brdrcolor"]}</td>
                                                                                            <td>
                                                                                                <div className="input-group advanced-color-selector">
                                                                                                    <input type="text" id="datalablebordercolor" placeholder={this.props.advanced.plotOptions.series.dataLabels.borderColor} value={this.props.advanced.plotOptions.series.dataLabels.borderColor} className="form-control bordercolor" />
                                                                                                    <span className="input-group-addon" id="datalableborderbackgroundcolor">
                                                                                                        <i style={{backgroundColor: this.props.advanced.plotOptions.series.dataLabels.borderColor}}></i>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <span className="marg-left-5 marg-righ-5">{lang["storyboard.width"]}</span>
                                                                                                <input type="number" className="input-width-70px borderwidth" id="datalabelwidth" defaultValue={this.props.advanced.plotOptions.series.dataLabels.borderWidth} />
                                                                                                <span className="marg-left-5 marg-righ-5">{lang["storyboard.radius"]}</span>
                                                                                                <input type="number" className="input-width-70px borderradius" id="datalableradius" defaultValue={this.props.advanced.plotOptions.series.dataLabels.borderRadius} />
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>{lang["storyboard.algnment"]}</td>
                                                                                            <td>
                                                                                                <select className="align" id="datalablealignment">
                                                                                                    <option value="center" selected>{lang["storyboard.center"]}</option>
                                                                                                    <option value="left">{lang["storyboard.left"]}</option>
                                                                                                    <option value="right">{lang["storyboard.right"]}</option>
                                                                                                </select>
                                                                                                <select className="marg-left-5 verticalalign" id="datalabelverticalalign">
                                                                                                    <option value="top" selected>{lang["storyboard.top"]}</option>
                                                                                                    <option value="middle">{lang["storyboard.middle"]}</option>
                                                                                                    <option value="bottom">{lang["storyboard.bottom"]}</option>
                                                                                                </select>
                                                                                                <span className="marg-left-5 marg-righ-5">{lang["storyboard.rotation"]}</span>
                                                                                                <input type="number" className="input-width-70px rotation" id="datalabelrotation" defaultValue={this.props.advanced.plotOptions.series.dataLabels.rotation} />
                                                                                                <span className="marg-left-5">{lang["storyboard.capx"]}</span>
                                                                                                <input type="number" className="input-width-70px xpostion" id="datalabelrotationx" defaultValue={this.props.advanced.plotOptions.series.dataLabels.x} />
                                                                                                <span className="marg-left-5">{lang["storyboard.capy"]}</span>
                                                                                                <input type="number" className="input-width-70px ypostion" id="datalabelrotationy" defaultValue={this.props.advanced.plotOptions.series.dataLabels.y} />
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </fieldset>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="panel panel-transparent" id="legendsettingspop">
                                                        <div className="panel-heading" data-parent="#myGroup" data-toggle="collapse" href="#chartLegendCollapse" aria-expanded="false">
                                                            <h4 className="panel-title">
                                                                <i className="fa fa-chevron-circle-right" aria-hidden="true"></i> {lang["storyboard.legendsettings"]}
                                                            </h4>
                                                        </div>
                                                        <div id="chartLegendCollapse" className="panel-collapse collapse" aria-expanded="false">
                                                            <div className="panel-body">
                                                                <div className="col-xs-12 nopadding">
                                                                    <fieldset className="advancedchartsettings-fieldset adcslegend">
                                                                        <legend className="savereport-border">{lang["storyboard.legend"]}</legend>
                                                                        <div className="col-xs-12 nopadding">
                                                                            <div className="table-responsive">
                                                                                <table className="table table-custom">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>{lang["storyboard.legendlayout"]}</td>
                                                                                            <td>
                                                                                                <select className="legendlayout" id="legendlayoutdrop">
                                                                                                    <option value="horizontal" selected>{lang["storyboard.horizontal"]}</option>
                                                                                                    <option value="vertical">{lang["storyboard.vertical"]}</option>
                                                                                                </select>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>{lang["storyboard.align"]}</td>
                                                                                            <td>
                                                                                                <select className="align" id="legendlayoutalign">
                                                                                                    <option value="center">{lang["storyboard.center"]}</option>
                                                                                                    <option value="left">{lang["storyboard.left"]}</option>
                                                                                                    <option value="right" selected>{lang["storyboard.right"]}</option>
                                                                                                </select>
                                                                                                <select className="marg-left-5 verticalalign" id="legendverticalalign">
                                                                                                    <option value="top">{lang["storyboard.top"]}</option>
                                                                                                    <option value="middle">{lang["storyboard.middle"]}</option>
                                                                                                    <option value="bottom" selected>{lang["storyboard.bottom"]}</option>
                                                                                                </select>
                                                                                                <span className="marg-left-10 marg-righ-10 advanced-checkbox">
                                                                                                    <label className="advanced-checkbox-label" id="legendfloating">
                                                                                                        {
                                                                                                            (this.props.advanced.legend.floating != true) ? (
                                                                                                                <input type="checkbox" className="advanced-checkbox-input floatingtext" />
                                                                                                            ) : (
                                                                                                                    <input type="checkbox" className="advanced-checkbox-input floatingtext" defaultChecked />
                                                                                                                )
                                                                                                        }
                                                                                                        {lang["storyboard.floating"]}
                                                                                                    </label>
                                                                                                </span>
                                                                                                <span className="marg-left-5">{lang["storyboard.capx"]}</span>
                                                                                                <input type="number" className="input-width-70px xpostion" id="legendxpostion" defaultValue={this.props.advanced.legend.x} />
                                                                                                <span className="marg-left-5">{lang["storyboard.capy"]}</span>
                                                                                                <input type="number" className="input-width-70px ypostion" id="legendypostion" defaultValue={this.props.advanced.legend.y} />
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr className="legendfont">
                                                                                            <td>{lang["storyboard.fontstyle"]}</td>
                                                                                            <td>
                                                                                                <select className="fontfamily" id="legendfontfamily">
                                                                                                    <option>{lang["storyboard.choosefont"]}</option>
                                                                                                    <option value='"Work Sans","Lato","Source Sans Pro",sans-serif' selected>{lang["storyboard.worksans"]}</option>
                                                                                                    <option value='"Lato","Source Sans Pro",sans-serif'>{lang["storyboard.lato"]}</option>
                                                                                                    <option value='"Source Sans Pro",sans-serif'>{lang["storyboard.sanspro"]}</option>
                                                                                                    <option value='verdana'>{lang["storyboard.verdana"]}</option>
                                                                                                    <option value='arial'>{lang["storyboard.arial"]}</option>
                                                                                                    <option value='tahoma'>{lang["storyboard.tahoma"]}</option>
                                                                                                    <option value='times new roman'>{lang["storyboard.timesroman"]}</option>
                                                                                                    <option value='cursive'>{lang["storyboard.cursive"]}</option>
                                                                                                    <option value='monospace'>{lang["storyboard.monospace"]}</option>
                                                                                                    <option vlaue='fantasy'>{lang["storyboard.fantasy"]}</option>
                                                                                                </select>
                                                                                                <div className="grouped-input">
                                                                                                    <input type="number" className="small-input fontsize" id="legendsmallsize" defaultValue={this.props.advanced.legend.itemStyle.fontSize.replace('px','').replace('rem','').replace('em','')} />
                                                                                                    <select className="fontsizeparameter" id="lagendsizeparameter">
                                                                                                        <option value="px" selected>{lang["storyboard.px"]}</option>
                                                                                                        <option value="em">{lang["storyboard.em"]}</option>
                                                                                                        <option value="rem">{lang["storyboard.rem"]}</option>
                                                                                                    </select>
                                                                                                </div>
                                                                                                <div className="btn-group" data-toggle="buttons">
                                                                                                    {
                                                                                                        (this.props.advanced.legend.itemStyle.fontWeight != "bold") ? (
                                                                                                            <label className="btn btn-bird-group" id="legendbold" title={lang["storyboard.bold"]}>
                                                                                                                <input type="checkbox" className="fontweight" autoComplete="off" />
                                                                                                                <i className="fa fa-bold fa-solid"></i>
                                                                                                            </label>
                                                                                                        ) : (
                                                                                                                <label className="btn btn-bird-group active" title={lang["storyboard.bold"]}>
                                                                                                                    <input type="checkbox" className="fontweight" autoComplete="off" defaultChecked />
                                                                                                                    <i className="fa fa-bold fa-solid"></i>
                                                                                                                </label>
                                                                                                            )
                                                                                                    }
                                                                                                    {
                                                                                                        (this.props.advanced.legend.itemStyle.fontStyle != "italic") ? (
                                                                                                            <label className="btn btn-bird-group" id="legenditalic" title={lang["storyboard.italic"]}>
                                                                                                                <input type="checkbox" className="fontitalic" autoComplete="off" />
                                                                                                                <i className="fa fa-italic fa-solid"></i>
                                                                                                            </label>
                                                                                                        ) : (
                                                                                                                <label className="btn btn-bird-group active" title={lang["storyboard.italic"]}>
                                                                                                                    <input type="checkbox" className="fontitalic" autoComplete="off" defaultChecked />
                                                                                                                    <i className="fa fa-italic fa-solid"></i>
                                                                                                                </label>
                                                                                                            )
                                                                                                    }
                                                                                                </div>
                                                                                                <div className="input-group advanced-color-selector">
                                                                                                    <input type="text" id="legendcolorseletor" placeholder={this.props.advanced.legend.itemStyle.color} value={this.props.advanced.legend.itemStyle.color} className="form-control fontcolor" />
                                                                                                    <span className="input-group-addon" id="legendcolorseletors">
                                                                                                        <i style={{backgroundColor: this.props.advanced.legend.itemStyle.color}}></i>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr className="legendbackground">
                                                                                            <td>{lang["storyboard.bgcolor"]}</td>
                                                                                            <td>
                                                                                                <div className="input-group advanced-color-selector">
                                                                                                    <input type="text" id="legendbackground" placeholder={this.props.advanced.legend.backgroundColor} value={this.props.advanced.legend.backgroundColor} className="form-control backgroundcolor" />
                                                                                                    <span className="input-group-addon" id="legendbackgroundcolor">
                                                                                                        <i style={{backgroundColor: this.props.advanced.legend.backgroundColor}}></i>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <span className="marg-left-5 marg-righ-5">{lang["storyboard.margin"]}</span>
                                                                                                <input type="number" className="input-width-70px margin" id="legendmargin" defaultValue={this.props.advanced.legend.margin} />
                                                                                                <span className="marg-left-5 marg-righ-5">{lang["storyboard.padding"]}</span>
                                                                                                <input type="number" className="input-width-70px padding" id="legendPadding" defaultValue={this.props.advanced.legend.padding} />
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr className="legendborder">
                                                                                            <td>{lang["storyboard.brdrcolor"]}</td>
                                                                                            <td>
                                                                                                <div className="input-group advanced-color-selector">
                                                                                                    <input type="text" id="legendbordercolor" placeholder={this.props.advanced.legend.borderColor} value={this.props.advanced.legend.borderColor} className="form-control bordercolor" />
                                                                                                    <span className="input-group-addon" id="legendborderbackgroundcolor">
                                                                                                        <i style={{backgroundColor: this.props.advanced.legend.borderColor}}></i>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <span className="marg-left-5 marg-righ-5">{lang["storyboard.width"]}</span>
                                                                                                <input type="number" className="input-width-70px borderwidth" id="legendwidth" defaultValue={this.props.advanced.legend.borderWidth} />
                                                                                                <span className="marg-left-5 marg-righ-5">{lang["storyboard.radius"]}</span>
                                                                                                <input type="number" className="input-width-70px borderradius" id="legendradius" defaultValue={this.props.advanced.legend.borderRadius} />
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr className="legendsymbol">
                                                                                            <td>{lang["storyboard.symbolstyle"]}</td>
                                                                                            <td>
                                                                                                <span className="marg-righ-5">{lang["storyboard.width"]}</span>
                                                                                                <input type="number" className="input-width-70px symbolwidth" id="legendsymbolwidth" defaultValue={this.props.advanced.legend.symbolWidth} />
                                                                                                <span className="marg-left-5 marg-righ-5">{lang["storyboard.height"]}</span>
                                                                                                <input type="number" className="input-width-70px symbolheight" id="legendsymbolheight" defaultValue={this.props.advanced.legend.symbolHeight} />
                                                                                                <span className="marg-left-5 marg-righ-5">{lang["storyboard.padding"]}</span>
                                                                                                <input type="number" className="input-width-70px symbolpadding" id="legendsymbolpadding" defaultValue={this.props.advanced.legend.symbolPadding} />
                                                                                                <span className="marg-left-5 marg-righ-5">{lang["storyboard.radius"]}</span>
                                                                                                <input type="number" className="input-width-70px symbolradius" id="legendsymbolradius" defaultValue={this.props.advanced.legend.symbolRadius} />
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </fieldset>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="panel panel-transparent" id="tooltipsettingspop">
                                                        <div className="panel-heading" data-parent="#myGroup" data-toggle="collapse" href="#chartTooltipCollapse" aria-expanded="false">
                                                            <h4 className="panel-title">
                                                                <i className="fa fa-chevron-circle-right" aria-hidden="true"></i> {lang["storyboard.tooltipsettings"]}
                                                            </h4>
                                                        </div>
                                                        <div id="chartTooltipCollapse" className="panel-collapse collapse" aria-expanded="false">
                                                            <div className="panel-body">
                                                                <div className="col-xs-12 nopadding">
                                                                    <fieldset className="advancedchartsettings-fieldset adcstooltip">
                                                                        <legend className="savereport-border">{lang["storyboard.tooltip"]}</legend>
                                                                        <div className="col-xs-12 nopadding">
                                                                            <div className="table-responsive">
                                                                                <table className="table table-custom">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>{lang["storyboard.prefix"]}</td>
                                                                                            <td>
                                                                                                <input type="text" className="input-width-70px tooltipprefix" id="tooltipprefix" defaultValue={this.props.advanced.tooltip.tooltipPrefix} placeholder="$" />
                                                                                                <span className="marg-left-5 marg-righ-5">{lang["storyboard.suffix"]}</span>
                                                                                                <input type="text" className="input-width-70px tooltipsuffix" id="tooltipsuffix" defaultValue={this.props.advanced.tooltip.tooltipSuffix} placeholder="%" />
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr className="tooltipbackground">
                                                                                            <td>{lang["storyboard.bgcolor"]}</td>
                                                                                            <td>
                                                                                                <div className="input-group advanced-color-selector">
                                                                                                    <input type="text" id="tooltipbackgroundselector" placeholder={this.props.advanced.tooltip.backgroundColor} value={this.props.advanced.tooltip.backgroundColor} className="form-control backgroundcolor" />
                                                                                                    <span className="input-group-addon" id="tooltipbackgroundcolor">
                                                                                                        <i style={{backgroundColor:this.props.advanced.tooltip.backgroundColor}}></i>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr className="tooltipborder">
                                                                                            <td>{lang["storyboard.brdrcolor"]}</td>
                                                                                            <td>
                                                                                                <div className="input-group advanced-color-selector">
                                                                                                    <input type="text" id="tooltipborderbackground" placeholder={this.props.advanced.tooltip.borderColor} value={this.props.advanced.tooltip.borderColor} className="form-control bordercolor" />
                                                                                                    <span className="input-group-addon" id="tooltipborderbackgroundcolor">
                                                                                                        <i style={{backgroundColor:this.props.advanced.tooltip.borderColor}}></i>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <span className="marg-left-5 marg-righ-5">{lang["storyboard.width"]}</span>
                                                                                                <input type="number" className="input-width-70px borderwidth" id="tooltipwidth" defaultValue={this.props.advanced.tooltip.borderWidth} />
                                                                                                <span className="marg-left-5 marg-righ-5">{lang["storyboard.radius"]}</span>
                                                                                                <input type="number" className="input-width-70px borderradius" id="tooltipradius" defaultValue={this.props.advanced.tooltip.borderRadius} />
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </fieldset>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer boc-modal-hf-padding">
                            <button type="button" className="btn btn-bird resetadvancedchartsettingsitems pull-left" data-num="" tab-id="0" tab-name="" aria-hidden="true" onClick={this.props.reset}><i className="fa fa-trash"></i>{lang["storyboard.removesettings"]}</button>
                            <button type="button" className="btn btn-bird" id="btn-bird" aria-hidden="true" onClick={this.props.close}><i className="fa fa-times"></i> {lang["storyboard.cancelbtn"]}</button>
                            <button type="button" className="btn btn-bird applyadvancedchartsettingsitems" id="applyadvancedchartsettingsitemsbutton" data-num="" tab-id="0" tab-name="" aria-hidden="true" onClick={this.props.apply}><i className="fa fa-check"></i> {lang["storyboard.applybtn"]}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}