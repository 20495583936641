import React, { Component } from "react";
import SendRequest from '../SendRequest';
import ReactDOM from 'react-dom'
import _ from 'underscore';
import NotificationsBadgeTemplate from './notificationbadgetemplate.jsx'
import NotificationsBadgeListTemplate from './notificationbadgelisttemplate.jsx'
import Sessionexpiry from "./sessionexpirypopup";
import { getMessage } from "../js/helpers/utils/BirdMessage";
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;

var wsocket = undefined;
export default class NotificationBadgeView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            IDLE_TIMEOUT: 12,//60 seconds
            _idleSecondsTimer: null,
            _idleSecondsCounter: 0
        }
        var that = this;
        var url = window.location.host;
        var protocol = window.location.protocol === "https:" ? "https" : "http";
        var websockehost = "ws";
        if (protocol.toLowerCase() == "https") {
            websockehost = "wss";
        }
        var finalURL = websockehost + "://" + url + "/api/notificationalert";
        setTimeout(function (e) {
            wsocket = new WebSocket(finalURL + "?api_key=" + window.localStorage.getItem("accessToken"));
            wsocket.addEventListener('open', that.onOpen, false);
            wsocket.addEventListener("message", that.onMessage, false);
            wsocket.addEventListener('close', that.onClose, false);
        }, 1000);

        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getnotifications",
            queryString: "",
            sucessFunction: response => {
                that.state.collection = response;
                SendRequest({
                    url: "getalertnotifications",
                    queryString: "",
                    sucessFunction: response => {
                        that.state.alertNotificationCollection = response;
                        var filterid = window.localStorage.getItem("current")!=undefined ? atob(window.localStorage.getItem("current")):"";
                        if(filterid!=undefined && filterid!=""){
                            var filteredResponser = $.grep(response, function (e) { return e.filterid == filterid });
                            that.state.alertNotificationCollection =filteredResponser;
                        }
                        that.render();
                    },
                    rejectFunction: () => { },
                    headers: requestHeaders
                });
            },
            rejectFunction: () => { },
            headers: requestHeaders
        });

    }
    render() {
        var count = 0;
        _.each(this.state.collection.data, function (value, index) {
            if (value.notification_status != 1 && value.notification_status != 2) {
                count++;
            }
        });
        _.each(this.state.alertNotificationCollection, function (value, index) {
            if (value.notification_status != 1 && value.notification_status != 2) {
                count++;
            }
        });
        if($('.notifybadge').length > 0){
            ReactDOM.render(<NotificationsBadgeTemplate url={this.props.url} viewNotifications={this.viewNotifications} marknotificationsForHref={this.marknotificationsForHref} marknotifications={this.marknotifications} notifications={this.state.collection} alertNotificationCollection={this.state.alertNotificationCollection} count={count} />, document.querySelector('.notifybadge'));
        }
        return (<div></div>);
    }
    onOpen() {
        wsocket.send("Message to send");
        // alert("onOpen");
    }
    onMessage = (evt) => {
        var that = this;
        var received_msg = evt.data;
        var array = JSON.parse(received_msg);
        var alertObject = JSON.parse(array.alertObj);
        // var today = new Date();
        // var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
        // dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
        // $('.notifyer ul:first-child').append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
        // ReactDOM.render(<NotificationsBadgeListTemplate url={this.props.url} marknotifications={this.marknotifications} array={alertObject} />, document.getElementById(dynamicdiv));
        if (alertObject.length > 0) {
            if (alertObject === "[]") {
                $(".notification-ul").empty();
                $('.notifications .notifications-badge').text("");
            }
            else {
                that.fetchNotification();
                var existedcount = $('.notifications .notifications-badge').text() != "" ? $('.notifications .notifications-badge').text() : 0;
                var alertcount = alertObject.length + parseInt(existedcount);
                if (alertcount > 0) {
                    $('.notifications-empty').hide();
                }
                $('.notifications .notifications-badge').html(alertcount);
            }
        }
        /*session expiry warning*/
        window.$('html').on('click', function () {
            that.state._idleSecondsCounter = 0;
        });
        // window.$('html').on('mousemove', function () {
        //   _idleSecondsCounter = 0;
        // });
        window.$('html').on('keypress', function () {
            that.state._idleSecondsCounter = 0;
        });
        function CheckIdleTime() {
            that.state._idleSecondsCounter++;
            if (that.state._idleSecondsCounter >= that.state.IDLE_TIMEOUT) {
                let expiry = window.localStorage.getItem("accessTokenExpiration");
                let tzString = window.localStorage.getItem("serverTimezone");
                let expiry_time = (new Date(expiry).getTime() / 1000)
                let beforeexpiry_time = (new Date(expiry).getTime() / 1000) - 180
                let current_time;
                if (tzString != undefined && tzString != '') {
                    current_time = new Date(new Date().toLocaleString("en-US", { timeZone: tzString })).getTime() / 1000
                } else {
                    current_time = new Date().getTime() / 1000;
                } /*if (current_time >= expiry_time && that.state._idleSecondsCounter >= that.state.IDLE_TIMEOUT) {
                    that.state._idleSecondsCounter = 0;
                    setTimeout(function () {
                        that.onClose();
                    }, 60000);
                }*/
                if (current_time >= beforeexpiry_time && that.state._idleSecondsCounter >= that.state.IDLE_TIMEOUT) {
                    let $ = window.$;
                    if((current_time - beforeexpiry_time)>=240){
                        $('.birdmessage-container').hide();
                        $('.birdmessage-backdrop').hide();
                        that.onClose();
                        return;
                    }
                    $('.messageicon').addClass('birdmessage-success birdmessage-infor birdmessage-warning');
                    $('.messageicon i').addClass('fa-check fa-info-circle fa-exclamation-triangle');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').addClass('hide').removeClass('show');
                    $('.birdmessage h2').empty().append('Session Expire Warning');
                    $('.birdmessage-info').text(lang["user.header.notifysession"]);
                    $('.birdmessage .birdmessagebuttons').removeClass('hide');
                    that.state._idleSecondsCounter = 0;
                    $('.deleteno').on('click', function (e) {
                        let $ = window.$;
                        $('.birdmessage-container').hide();
                        $('.birdmessage-backdrop').hide();
                        setTimeout(function () {
                            that.onClose();
                        }, 60000);
                    });
                    $('.deleteyes').on('click', function (event) {
                        let $ = window.$;
                        event.preventDefault();
                        that.state._idleSecondsCounter = 0;
                        $('.birdmessage-container').hide();
                        $('.birdmessage-backdrop').hide();
                        $('.modal').modal("hide");
                        new Sessionexpiry({loggedInUserDetails:that.props.loggedInUserDetails});
                        return
                    });
                }
            }
        }
        CheckIdleTime()
        /*session expiry warning*/
        // if(array.sessionObj.isExpired=="logout"){
        //             require([ 'collections/logout/logoutcollection'], function(LogoutCollection){
        //                 var logoutcollection = new LogoutCollection();
        //                 logoutcollection.fetch({
        //                     success: function(collection,response){
        //                         location.replace("login");
        //                     }
        //                 });
        //             });
        // }
        // if(array.sessionObj.isExpired=="true"){
        //     $('.messageicon').addClass('birdmessage-success birdmessage-infor birdmessage-warning');
        //     $('.messageicon i').addClass('fa-check fa-info-circle fa-exclamation-triangle');
        //     $('.birdmessagebuttons').removeClass('show');
        //     $('.birdmessagebuttons').addClass('hide');
        //     $('.birdmessage-center').addClass("message-top-in");
        //     $('.details').addClass('hide').removeClass('show');
        //     $('.birdmessage h2').empty().append('Session Expire Warning');
        //     $('.birdmessage-info').text('Your session will expire in a minute. Do you want to extend the session?');
        //     $('.birdmessage .birdmessagebuttons').removeClass('hide');

        //     $('.deleteno').on('click', function (e) {
        //         $('.birdmessage-container').hide();
        //         $('.birdmessage-backdrop').hide();
        //         setTimeout(function(){
        //             require([ 'collections/logout/logoutcollection'], function(LogoutCollection){
        //                 var logoutcollection = new LogoutCollection();
        //                 logoutcollection.fetch({
        //                     success: function(collection,response){
        //                         location.replace("login");
        //                     }
        //                 });
        //             });
        //         }, 60000);
        //     });
        //     $('.deleteyes').on('click', function (e) {
        //         event.preventDefault();
        //         $('.birdmessage-container').hide();
        //         $('.birdmessage-backdrop').hide();
        //         $('.modal').modal("hide");
        //         new SessionExpiryPopup({
        //         });
        //     });
        // }
    }
    onClose() {
        var that = this;
        console.log("closed");
        let $ = window.$;
        $('.birdmessage-container').show();
        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
        $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
        $('.birdmessagebuttons').removeClass('show');
        $('.birdmessagebuttons').addClass('hide');
        $('.birdmessage-center').addClass("message-top-in");
        $('.details').removeClass('show');
        $('.details').addClass('hide');
        $('.birdmessage h2').empty().append('Warning');
        $('.birdmessage-info').empty().text(getMessage('BirdWarning28'));
        $('.birdmessage-backdrop').show();
        setTimeout(function () {
            let $ = window.$;
            $('.birderror').remove();
            window.location.replace("/");
            let requestHeaders1 = {
                method: "get",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            SendRequest({
                url: "logoutbird",
                queryString: "",
                sucessFunction: (props) => {
                    sessionStorage.removeItem("accessToken");
                    sessionStorage.removeItem("reservedwordslist");
                    window.localStorage.clear();
                    window.location.replace("/");
                },
                rejectFunction: () => {
                    sessionStorage.removeItem("accessToken");
                    sessionStorage.removeItem("reservedwordslist");
                    window.localStorage.clear();
                    window.location.replace("/");
                 },
                headers: requestHeaders1,
            })
        }, 100);
    }
    viewNotifications = () => {
        if ($('a').hasClass("viewallread")) {
            $('.is-container').fadeOut('slow');
        }
    }

    marknotifications = (e) => {
        e.stopPropagation();
        e.preventDefault();
        var $this = e.currentTarget;
        var that = this;
        var reportid = "";
        var isalert = false;
        var publishinfoid = "";
        var isFromNotificationModel = true;
        var notificationStatus = $($this).attr('status');
        if ($($this).attr('reportid') != undefined) {
            reportid = $($this).attr('reportid');
        }
        if ($($this).attr('isalert') != undefined) {
            isalert = $($this).attr('isalert');
        }
        if ($($this).attr('publishinfoid') != undefined) {
            publishinfoid = $($this).attr('publishinfoid');
        }
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "marknotifications",
            queryString: "?notificationStatus=" + notificationStatus + "&reportid=" + reportid + "&isAlert=" + isalert + "&publishInfoId=" + publishinfoid + "&isFromNotificationModel=" + isFromNotificationModel,
            sucessFunction: response => {
                that.fetchNotification();
            },
            rejectFunction: () => { },
            headers: requestHeaders
        });
    }
    marknotificationsForHref = (e) => {
        var $this = e;
        var that = this;
        var reportid = "";
        var isalert = false;
        var publishinfoid = "";
        var isFromNotificationModel = true;
        var notificationStatus = $($this).attr('status');
        if ($($this).attr('reportid') != undefined) {
            reportid = $($this).attr('reportid');
        }
        if ($($this).attr('isalert') != undefined) {
            isalert = $($this).attr('isalert');
        }
        if ($($this).attr('publishinfoid') != undefined) {
            publishinfoid = $($this).attr('publishinfoid');
        }
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "marknotifications",
            queryString: "?notificationStatus=" + notificationStatus + "&reportid=" + reportid + "&isAlert=" + isalert + "&publishInfoId=" + publishinfoid + "&isFromNotificationModel=" + isFromNotificationModel,
            sucessFunction: response => {
                that.fetchNotification();
            },
            rejectFunction: () => { },
            headers: requestHeaders
        });
    }
    fetchNotification() {
        var that = this;
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getnotifications",
            queryString: "",
            sucessFunction: response => {
                that.state.collection = response;
                SendRequest({
                    url: "getalertnotifications",
                    queryString: "",
                    sucessFunction: response => {
                        that.state.alertNotificationCollection = response;
                        var filterid = window.localStorage.getItem("current")!=undefined ? atob(window.localStorage.getItem("current")):"";
                        if(filterid!=undefined && filterid!=""){
                            var filteredResponser = $.grep(response, function (e) { return e.filterid == filterid });
                            that.state.alertNotificationCollection =filteredResponser;
                        }
                        that.render();
                    },
                    rejectFunction: () => { },
                    headers: requestHeaders
                });
            },
            rejectFunction: () => { },
            headers: requestHeaders
        });
    }
}