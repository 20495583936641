import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'underscore';
import PubSub from 'pubsub-js'
import SendRequest from '../SendRequest';
import DatamodelsConnectionsListHtml from './DatamodelConnectionsListHtml.jsx';
import createConnectionView from './CreateConnection';
import ConnectionItemsDataContainerHtml from './ConnectionItemsDataContainerHtml.jsx';
import { lang } from "../js/helpers/utils/languages/language";
import * as workspaceExistingEntityMetaDataCollection from "./WorkspaceExisitngEntityMetaDataCollection";
const $ = window.$;

export default class DatamodelsConnections extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            availableWorkspaces: [],
            hubEntities: [],
            workspaceEntities: []
        }
        this.loadworkspaceEvee = PubSub.subscribe('loadworkspace', this.loadworkspaceEve.bind(this));
        this.workspaceExistingEntityCollection = workspaceExistingEntityMetaDataCollection;
        this.entities = {};
        this.allentitynames = [];
        this.custqueentities = {};
        this.wscqnames = [];
        this.wscsqQueries = {};
        this.metadataentities = [];
        this.metadatajoins = [];
        this.connections = {};
        this.availableworkspaces = [];
        if(this.props != undefined){
        this.modelId = this.props.modelId;
        this.currentworkspaceId = this.props.currentworkspaceId
        }
        
    }
    getRequestHeaders = {
        method: "get",
        headers: new Headers({
            "Content-Type": "application/json"
        })
    }
    postRequestHeaders = {
        method: "post",
        headers: new Headers({
            "Content-Type": "application/json"
        })
    }
    workspaceCustomQueryCollection = {
        resetCollection: () => {
            this.custqueentities = {};
            this.wscqnames = [];
            this.wscsqQueries = {};
        },
        updatewsCustomQueryEntityColumns: (columns, entityName, connectionId) => {
            this.custqueentities[entityName + "&&&" + connectionId] = columns;
        },
        getwsCustomQueryEntityColumns: (entityName, connectionId) => {
            if (this.custqueentities[entityName + "&&&" + connectionId] == undefined) {
                this.custqueentities[entityName + "&&&" + connectionId] = {};
                return undefined;
            }
            else {
                return this.custqueentities[entityName + "&&&" + connectionId];
            }
        },
        updatewsCustomQueryQueries: (query, entityName, connectionId) => {
            this.wscsqQueries[entityName + "&&&" + connectionId] = query;
        },
        getwsCustomQueryQueries: (entityName, connectionId) => {
            if (this.wscsqQueries[entityName + "&&&" + connectionId] == undefined) {
                this.wscsqQueries[entityName + "&&&" + connectionId] = {};
                return undefined;
            }
            else {
                return this.wscsqQueries[entityName + "&&&" + connectionId];
            }
        },
        addWsCustomQueryName: (name) => {
            this.wscqnames.push(name);
        },
        getWsCustomQueryNames: () => {
            return this.wscqnames;
        }
    }
    datamodelMetaDataCollection = {
        resetCollection: () => {
            this.metadataentities = [];
            this.metadatajoins = [];
        },
        updateMetadataEntities: (entitiesArray) => {
            this.metadataentities = entitiesArray;
        },
        updateMetadataJoins: (joinsArray) => {
            this.metadatajoins = joinsArray;
        },
        getMetadataEntities: () => {
            return this.metadataentities;
        },
        getMetadataJoins: () => {
            return this.metadatajoins;
        }
    }
    dataModelConnectionsDetailsCollection = {
        resetCollecion: () => {
            this.connections = {};
            this.availableworkspaces = [];
        },
        updateDataModelConnections: (entityname, conassId, connectiondata) => {
            var data = {};
            var text = connectiondata.connectionname;
            var conjosn = {};
            if (connectiondata.connectionjson != undefined) {
                conjosn = JSON.parse(connectiondata.connectionjson);
                text = conjosn.connectionname;
            }
            data['text'] = text;
            data['connectionName'] = connectiondata.connectionname.toLowerCase();
            data['value'] = connectiondata.connectionAccessId;
            data['contype'] = connectiondata.connectionType;
            data['selected'] = false;
            data['schemaId'] = connectiondata.connectionId;
            data['connectionjson'] = connectiondata.connectionjson;
            data['description'] = connectiondata.connectionname;

            this.connections[entityname + "&&&" + conassId] = data;
        },
        getDataModelConnectionData: (entityname, conassId) => {
            return this.connections[entityname + "&&&" + conassId];
        },
        deleteDataModelConnectionData: (entityname, conassId) => {
            delete this.connections[entityname + "&&&" + conassId];
        },
        getAllDataModelConnectionData: () => {
            return this.connections;
        },
        updateAvailableWorkSpacesData: (workspaces) => {
            this.availableworkspaces = workspaces;
        },
        getAvailableWorkSpacesData: () => {
            return this.availableworkspaces;
        }
    }
    componentDidMount() {
        if (this.currentworkspaceId == undefined) {
            this.onExistingEntitesRecieved();
        }
        SendRequest({
            url: "gethubentities",
            queryString: "?isFromWorkspace=" + true,
            sucessFunction: (props) => {
                this.setState({
                    hubEntities: props
                });
            },
            rejectFunction: (error) => { },
            headers: this.getRequestHeaders
        });
        SendRequest({
            url: "getavailableworkspaces",
            queryString: "?isFromModel=" + true,
            sucessFunction: (props) => {
                this.state.availableWorkspaces = props;

                var that = this;
                this.selectedWorkpaceId = 0;
                var connectionsdata = [];
                this.state.availableWorkspaces.map((wsdetails, i) => {
                    var wsObj = {};
                    this.datahubid = wsdetails['datahubid'];
                    var title = wsdetails['WSTitle'];
                    if (title != undefined && title == "Default Workspace_" + wsdetails['userId']) {
                        title = "Default Workspace";
                    }
                    var descr = wsdetails['WSDescription'];
                    var wsId = wsdetails['WSID'];
                    wsObj['hubId'] = that.datahubid;
                    wsObj['text'] = title;
                    wsObj['originaltext'] = wsdetails['WSTitle'];
                    wsObj['value'] = wsId;
                    wsObj['description'] = wsdetails['WSDescription'] != undefined && wsdetails['WSDescription'] != null ? wsdetails['WSDescription'] : "";
                    wsObj['imageSrc'] = '../../../images/birdimages/workspace.png';
                    wsObj['metadata'] = wsdetails['metadata'];
                    if (that.currentworkspaceId != undefined && wsdetails.WSID == that.currentworkspaceId) {
                        wsObj['selected'] = true;
                        this.selectedworkspaceid = wsdetails['WSID'];
                    //    this.workspaceExistingEntityCollection.updateSelectedworkspaceId(this.selectedworkspaceid);
                    }
                    else if (i === 0 && that.currentworkspaceId == undefined) {
                        wsObj['selected'] = true;
                        this.selectedworkspaceid = wsdetails['WSID'];
                    //    this.workspaceExistingEntityCollection.updateSelectedworkspaceId(this.selectedworkspaceid);
                    }
                    connectionsdata.push(wsObj);
                })
                this.loadWorkspace(connectionsdata);
                that.onExistingEntitesRecieved();
            },
            rejectFunction: (error) => { },
            headers: this.getRequestHeaders
        });
    }
    render() {
        return (<DatamodelsConnectionsListHtml datamodeladdcustomquery={this.props.datamodeladdcustomquery} />);
    }
    loadworkspaceEve(msg, data) {
       // sessionStorage.removeItem("workspaceId")
        ReactDOM.unmountComponentAtNode(document.getElementById('datamodelsconnectionslist'))
        this.loadWorkspace(data);
        var that=this;
        _.each(data,function(val){
            if(val.selected!=undefined&&val.selected){
               that.currentworkspaceId=val.value;
            }
        })
    }
    loadWorkspace = (connectionsdata) => {
        connectionsdata.sort(function(a, b){
            var x = a.text.toLowerCase();
            var y = b.text.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0 ;
            });
        var that = this;
        this.dataModelConnectionsDetailsCollection.updateAvailableWorkSpacesData(connectionsdata);
        ReactDOM.render(
            <DatamodelsConnectionsListHtml datamodeladdcustomquery={this.props.datamodeladdcustomquery} />
            , document.getElementById('datamodelsconnectionslist')
        );
        $("#data-models-existing-connections-list").ddslick({
            data: connectionsdata,
            width: "100%",
            imagePosition: "left",
            background: "#FFF",
            onSelected: (data) => {
                that.loadSelectedConnection(data);
            }
        });
        setTimeout(function(){$(".spinner").hide();},2000);
    }
    editModelConnectionItem = (item) => {
        var that = this;
        var entityName = $(item).attr('data-value');
        var workspaceentityid = $(item).attr('data_parent');
        $("#datamodelsentitycontainer>li").removeClass("active");
        $("#settingscontainer").remove();
        $("#datamodelsentitycontainer").after('<div id="settingscontainer"></div>');
        $(".spinner").show();
        var connectiondata = this.dataModelConnectionsDetailsCollection.getDataModelConnectionData(entityName, workspaceentityid);
        var cjson = JSON.parse(connectiondata.connectionjson);
        var schemaName = connectiondata.connectionName;
        var selectedEntities = {};
        selectedEntities[workspaceentityid + "&&&" + entityName.trim()] = connectiondata;
        // require(['views/datamodels/datamodelnewconitemsettionsview','collections/data/datahub/entitymetadatacollection',],function(DataModelNewItemSettingsView,EntityMetaDataCollection){
        //     that.entityMetaDataCollection = new EntityMetaDataCollection();
        //     Backbone.sync('create', that.entityMetaDataCollection,{
        //         reset: true,cache: false,
        //         data: $.param({
        //             entities: JSON.stringify(selectedEntities)
        //         }),
        //         success:function(response){

        //             var datahubItemSettingsView = new DataModelNewItemSettingsView({
        //                 broker:that.broker,
        //                 metadata:response,
        //                 connectionName:connectiondata.connectionName,
        //                 schemaId:connectiondata.schemaId,
        //                 schemaName:connectiondata.connectionName,
        //                 isFromEdit:true,
        //                 schemaDisplayName:connectiondata.connectionName,
        //                 filename:cjson.fileName,
        //                 connectiondata:JSON.stringify(connectiondata),
        //                 connectionId:connectiondata.schemaId,
        //                 entityName:entityName,
        //                 entityMetaDataCollection:that.entityMetaDataCollection,
        //                 dataHubEntitiesCollection:that.dataHubEntitiesCollection.toJSON(),
        //                 isSaved:false
        //             });
        //         }
        //     });
        // });
        $('.spinner').hide()
        $(item).closest("li").removeClass('warning');
        $(item).closest("li").addClass('active');
        $("#settingscontainer").addClass("showsettings");
        $("#datamodelsentitycontainer").addClass('settingsopened');
        setTimeout(function () {
            var container = $("#datamodelsentitycontainer"),
                scrollTo = $(this).closest("li");
            container.animate({
                scrollTop: scrollTo.offset().top - container.offset().top + container.scrollTop() - container.height() / 2
            });
        }, 1000);
    }
    editModelItem = (item, isCustomQuery) => {
        var that = this;
        var entityName = $(item).attr('data-value');
        var data_type = $(item).attr("data_type");
        if (isCustomQuery) {
            entityName = $(item).attr('dmcqname');
        }
        var workspaceentityid = $(item).attr('data_parent');
        $("#datamodelsentitycontainer>li").removeClass("active");
        $("#settingscontainer").remove();
        $("#datamodelsentitycontainer").after('<div id="settingscontainer"></div>');
        // require(['views/datamodels/datamodelitemsettingsview'],function(DatamodelItemSettingsView){
        //     var datamodelItemSettingsView = new DatamodelItemSettingsView({
        //         broker:that.broker,
        //         entityName:entityName,
        //         workspaceentityid:workspaceentityid,
        //         entitycollection:that.workspaceExistingEntityCollection,
        //         isCustomQuery:isCustomQuery,
        //         workspaceCustomQueryCollection:that.options.workspaceCustomQueryCollection,
        //         type:data_type,
        //         dataModelMetaDataCollection:that.dataModelMetaDataCollection
        //     });
        //     $(item).closest("li").removeClass('warning');
        //     $(item).closest("li").addClass('active');
        //     $("#settingscontainer").addClass("showsettings");
        //     $("#datamodelsentitycontainer").addClass('settingsopened');
        //     setTimeout(function(){
        //         var container = $("#datamodelsentitycontainer"),
        //             scrollTo = $(item).closest("li");
        //         container.animate({
        //             scrollTop: scrollTo.offset().top - container.offset().top + container.scrollTop() - container.height() / 2
        //         });
        //     },1000);
        //     if(isCustomQuery){
        //         $('#datamodelcustomquerytab').click();
        //     }
        // });
    }
    getEntityFact = (workspaceentityid, entityname) => {
        return this.workspaceExistingEntityCollection.getFact(entityname, workspaceentityid);
    }
    getEntityObjectForJoins = (workspaceentityid, entityname) => {
        var entityId = workspaceentityid;
        var columns = this.workspaceExistingEntityCollection.getEntityColumns(entityname, workspaceentityid);
        var that = this;
        if (that.dataModelMetaDataCollection != undefined && (that.dataModelMetaDataCollection.getMetadataEntities().length > 0 || that.dataModelMetaDataCollection.getMetadataEntities() != undefined)) {
            var entitiesArray = that.dataModelMetaDataCollection.getMetadataEntities();
            _.each(entitiesArray, function (i, j) {
                if (i.workspaceentityid == workspaceentityid && i.entityname == entityname) {
                    columns = i.columns;
                }
            });
        }
        var parsedColumns = [];
        if (typeof (columns) == "string") {
            parsedColumns = JSON.parse(columns)
        } else {
            parsedColumns = columns
        }
        var joinColumns = [];
        _.each(parsedColumns, function (parsedColumn) {
            var visible = parsedColumn.visible;
            if(visible=='yes') {
                var columnname = parsedColumn.columnName;
                var modelDisplayName = parsedColumn.columnDisplayName.split(".");
                var columnDisplayName = (modelDisplayName.length==2)?modelDisplayName[1]:parsedColumn.columnDisplayName;
                var tableDisplayType = parsedColumn.tableDisplayType;
                var joinColumn = {};
                joinColumn['name'] = columnDisplayName;
                joinColumn['type'] = tableDisplayType;
                joinColumn['workspaceentityid'] = workspaceentityid;
                joinColumns.push(joinColumn);
            }
        });
        return joinColumns;
    }
    getEnitityDetails = (workspaceentityid, entityname) => {
        var columns = this.workspaceExistingEntityCollection.getEntityColumns(entityname, workspaceentityid);
        var fact = this.workspaceExistingEntityCollection.getFact(entityname, workspaceentityid);
        var filters = this.workspaceExistingEntityCollection.getEntityFilters(entityname, workspaceentityid);
        var customfields = this.workspaceExistingEntityCollection.getEntityCustomFeilds(entityname, workspaceentityid);
        var entityType = this.workspaceExistingEntityCollection.getEntityType(entityname, workspaceentityid);
        var query = this.workspaceExistingEntityCollection.getQuery(entityname, workspaceentityid);
        var connectionId = this.workspaceExistingEntityCollection.getConnectionId(entityname, workspaceentityid);
        var data = {};
        if (workspaceentityid != undefined && workspaceentityid <= 0) {
            query = this.options.workspaceCustomQueryCollection.getwsCustomQueryQueries(entityname, workspaceentityid);
            connectionId = null;
            filters = "";
            customfields = "";
            fact = "0";
            if (typeof (columns) != "string") {
                columns = JSON.stringify(columns);
            }
        }
        var that = this;
        if (that.dataModelMetaDataCollection != undefined && (that.dataModelMetaDataCollection.getMetadataEntities().length > 0 || that.dataModelMetaDataCollection.getMetadataEntities() != undefined)) {
            var entitiesArray = that.dataModelMetaDataCollection.getMetadataEntities();
            _.each(entitiesArray, function (i, j) {
                if (i.workspaceentityid == workspaceentityid && i.entityname == entityname) {
                    columns = i.columns;
                }
            });
        }
        data['columns'] = columns;
        data['fact'] = fact;
        data['filters'] = filters;
        data['customfields'] = customfields;
        data['query'] = query;
        data['connectionId'] = connectionId;
        data['workspaceentityid'] = workspaceentityid;
        data['entityname'] = entityname;

        return data;



    }
    getWorkspaceId = () => {
        return this.selectedworkspaceid;
    }
    createnewconnection = (e) => {
        e.preventDefault();
        var that = this;
        $(".addconnection").remove();
        $("body").append("<div class='connection-center addconnection'></div>");
        //ReactDOM.render(<createConnectionView />,document.querySelector('.addconnection'));
        var createconnection = new createConnectionView({});

    }

    onExistingEntitesRecieved = () => {
        var that = this;
        this.state.workspaceEntities.sort(function(l,m){
            var x = l.entityname.toLowerCase();
            var y = m.entityname.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0 ;
        });
        ReactDOM.render(
            <ConnectionItemsDataContainerHtml search={this.search} createnewconnection={this.createnewconnection} editdatamodelitem={this.props.editdatamodelitem} entityDetails={this.state.workspaceEntities} datamodeladdcustomquery={this.props.datamodeladdcustomquery} />,
            document.getElementById('data-models-connections-tables-data-container')
        );
        var tempConData = that.dataModelConnectionsDetailsCollection.getAllDataModelConnectionData();
        _.each(tempConData, function (l, con) {
            var data = con.split("&&&");
            var entityname = data[0];
            var conAccessId = data[1];
            $("#dataworkspacetablesnew").append('<li item-expanded="true" class="viewreport-list-item ui-draggable ui-draggable-handle"><label title=' + entityname + '><i class="fa fa-link"></i><input type="checkbox" durable="false" class="scopeitem mysqltablevalue" data_parent=' + conAccessId + '  id=' + entityname + ' data-value="' + entityname + '">' + entityname + '</label><button class="btn btn-transparent btn-sm editdmconnection" data_parent=' + conAccessId + ' data-value="' + entityname + '" type="button" title='+lang["models.settings"]+'><i class="fa fa-cog"></i></button><button class="btn btn-transparent btn-sm joinremove" type="button" data_parent=' + conAccessId + ' data-value="' + entityname + '"><i class="fa fa-times"></i></button></li>');
        });
        this.props.onModelEntitiesLoad();
        if (that.datamodelMetaDataCollection != undefined && (that.datamodelMetaDataCollection.getMetadataEntities().length > 0 || that.datamodelMetaDataCollection.getMetadataEntities() != undefined)) {
            var entitiesArray = that.datamodelMetaDataCollection.getMetadataEntities();
            _.each(entitiesArray, function (i, j) {
                that.currentworkspaceId = i.workspaceId
                $(".filtercontent input[data-value='" + i.entityname + "'][data_parent='" + i.workspaceentityid + "']").parent().parent().draggable({ disabled: true }).addClass("disabled");
                $(".filtercontent input[data-value='" + i.entityname + "'][data_parent='" + i.workspaceentityid + "']").prop('checked', true);
            });
        }
        if ($("#datamodelsentitycontainer li").find("input.scopeitem") != undefined && $("#datamodelsentitycontainer li").find("input.scopeitem").length > 0) {
            _.each($("#datamodelsentitycontainer li").find("input.scopeitem"), function (entity) {
                var workspaceentityid = $(entity).attr("data_parent");
                var entityname = $(entity).attr("data-value");
                $(".filtercontent input[data-value='" + entityname + "'][data_parent='" + workspaceentityid + "']").parent().parent().draggable({ disabled: true }).addClass("disabled");
                $(".filtercontent input[data-value='" + entityname + "'][data_parent='" + workspaceentityid + "']").prop('checked', true);
            });
        }
    }
    loadSelectedConnection = (data) => {
        var that = this;
        var workspacename = data.selectedData.text;
        var workspaceid = data.selectedData.value;
        var worksaceMetaData = data.selectedData.metadata;
        this.selectedworkspaceid = workspaceid;
        this.workspaceExistingEntityCollection.updateSelectedworkspaceId(workspaceid);
        this.workspaceExistingEntityCollection.updateSelectedWorkspaceMetaData(worksaceMetaData);

        if (workspaceid != 0) {
            SendRequest({
                url: "getworkspaceentities",
                queryString: "?workspaceId=" + workspaceid + "&isFromBmodel=" + true,
                sucessFunction: (props) => {
                    this.state.workspaceEntities = props;
                    that.workspaceExistingEntityCollection.updateData(props);
                    that.onExistingEntitesRecieved();

                    ReactDOM.render(
                        <ConnectionItemsDataContainerHtml search={this.search} createnewconnection={this.createnewconnection} editdatamodelitem={this.props.editdatamodelitem} entityDetails={props} datamodeladdcustomquery={this.props.datamodeladdcustomquery} />,
                        document.getElementById('data-models-connections-tables-data-container')
                    );
                    $("#datamodelsentitycontainer").sortable({
                        revert: "100",
                    });
                    $("#data-models-connections-tables-data-container ul.list-group>li").draggable({
                        connectToSortable: "#datamodelsentitycontainer",
                        revert: "100",
                        scroll: false,
                        helper: 'clone',
                        addClasses: true,
                        drag: function (event, ui) {
                            $(ui.helper.context).draggable({ disabled: true }).addClass('disabled')
                        },
                        stop: function (event, ui) {
                            var dragText = $(ui.helper.context).find("input").attr("data-value");
                            var connectionId = $(ui.helper.context).find("input").attr("data_parent");
                            setTimeout(function () {
                                if ($("#datamodelsentitycontainer li:not('.ui-draggable-dragging')").find("input[data-value='" + dragText + "'][data_parent='" + connectionId + "']").length != 1) {
                                    $(".filtercontent input[data-value='" + dragText + "'][data_parent='" + connectionId + "']").parent().parent().draggable({ disabled: false }).removeClass("disabled");
                                    $(".filtercontent input[data-value='" + dragText + "'][data_parent='" + connectionId + "']").prop('checked', false);
                                }
                            }, 500);
                        }
                    });
                    $("ul, li").disableSelection();
                    $("#datamodelsentitycontainer").droppable({
                        hoverClass: "highlight",
                        activeClass: "highlight",
                        out: function (event, ui) {
                            var self = ui;
                            var dragText = ui.draggable.find("input").attr("data-value");
                        },
                        drop: function (event, ui) {
                            var dragText = ui.draggable.find("input").attr("data-value");
                            var connectionId = ui.draggable.find("input").attr("data_parent");
                            //ui.draggable.find(".fa.fa-table").removeClass("fa fa-table").addClass("fa fa-lightbulb-o");
                            setTimeout(function () {
                                if ($("#datamodelsentitycontainer").find("input[data-value='" + dragText + "'][data_parent='" + connectionId + "']").length === 1) {
                                    $(".filtercontent input[data-value='" + dragText + "'][data_parent='" + connectionId + "']").parent().parent().draggable({ disabled: true }).addClass("disabled");
                                    $(".filtercontent input[data-value='" + dragText + "'][data_parent='" + connectionId + "']").prop('checked', true);
                                } else {
                                    $(".filtercontent input[data-value='" + dragText + "'][data_parent='" + connectionId + "']").parent().parent().draggable({ disabled: false }).removeClass("disabled");
                                    $(".filtercontent input[data-value='" + dragText + "'][data_parent='" + connectionId + "']").prop('checked', false);
                                }
                            }, 500);
                            if ($('#datamodelsentitycontainer li').length > 0) {
                                $('.connections-dropdown-container').addClass('disabled')
                            }
                            $(".editdatamodelitem").click(function (e) {
                                that.props.editdatamodelitem(e);
                            })
                            $(".joinremove").click(function (e) {
                                that.props.joinremove(e);
                            })
                        },
                        over: function (event, ui) {
                        }
                    });
                },
                rejectFunction: (error) => { },
                headers: this.getRequestHeaders
            });

        }
        else {
            that.onExistingEntitesRecieved();
        }
    }
    search = (e) => {
        var $this = e.currentTarget;
        var valThis = $($this).val().toLowerCase();
        $(".filtercontent").find("li.viewreport-list-item").each(function () {
            var text = $(this).text().toLowerCase();
            (text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
        });
    }
}