import React, { Component } from "react";
import ReactDOM from 'react-dom';
import SendRequest from '../../SendRequest';
import ReportManagementTemplate from './ReportmanagementTable';
import ReportManagementTable from './reportmanagement';
import { lang } from "../../js/helpers/utils/languages/language";
const $ = window.$;
export default class ReportManagementView extends React.Component{
    constructor(props){
        super(props);
        this.state = { };
        this.render();
        var path = '<li><a class="navDashboard" href="#"><i class="fa  fa-home"></i>'+lang["admin.header.dashtitle"]+'</a></li><li><a href="#admin"><i class="fa fa-user-secret"></i> ' + lang["admin.header.admintitle"] + '</a></li><li><a><i class="fa fa-bar-chart"></i> ' + lang["admin.reports.title"] + '</a></li>';
		$(".navbar.navbar-bird ul.nav-path").html(path);
    }
    // componentDidMount(){
    //     this.getReportConfigDetails(undefined, "", 25, 'asc', undefined);
    // }
    getReportConfigDetails(page, search, pageDisplay, order, colName){
        var index = 0;
        var noOfRecords = $("#pageDropDown").text() == "" ? 10: $("#pageDropDown").text();
        var totalRecords = 0;
        if (page != undefined && page > 1) {
        index = (page - 1) * noOfRecords;
        }
        if (order == undefined) {
        order = 'asc';
        }
        if (colName == undefined) {
        colName = '';
        }
        this.dataFieldValue = colName;
        this.sortingOrder = order;
        this.page = page;
        var that = this;
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getReportsConfigDetails",
            queryString: "?index=" + index + "&noOfRecords=" + noOfRecords + "&searchCriteria=" + search + "&order=" + order + "&colName=" + colName,
            sucessFunction: (response) => {
                if (response.hasOwnProperty('error')) {
                    $(".spinner").hide();
                  } else {
                    var headerlist = response["header"];
                    var data = response["data"];
                    totalRecords = response["totalRecords"];
                    var keys = Object.keys(headerlist);
                    var totalPages;
                    if (pageDisplay == undefined) {
                      pageDisplay = 10;
                    }
                    if (totalRecords > 10) {
                      totalPages = Math.ceil(totalRecords / pageDisplay);
                    } else {
                      totalPages = 1;
                    }
                    ReactDOM.unmountComponentAtNode(document.getElementById("reportmanagementtable"));
                    ReactDOM.render(<ReportManagementTable keys={keys} search={search} totalRecords={totalRecords} sizePerPage={pageDisplay} headerlist={headerlist} data={data} />, document.getElementById("reportmanagementtable"));
          
                    $(".spinner").hide();
          
                  }
            },
            rejectFunction: () => { },
            headers: requestHeaders,
        })
    }
    searchkeyup = (e) => {
        var that = this;
        var delay = (function () {
        var timer = 0;
        return function (callback, ms) {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        };
        })();
        var search = $(e.currentTarget).val().toLowerCase();
        var pageDisplaySearch = $("#pageDropDown").text();
        delay(function () {
        that.searchValue = $(".searchreportmanagementtable").val();
        that.getReportConfigDetails(undefined, that.searchValue, pageDisplaySearch, that.sortingOrder, that.dataFieldValue);
        }, 100);
            $(".searchreportmanagementtable").focus();
    }
    render() {
        this.getReportConfigDetails(undefined, "", 10, 'asc', undefined);
        $('a.boc-maxscreen-left-item.report_management').addClass('showactive')
        $('.boc-admin-main .maxscreen-lft-filter-items a').not('.report_management').removeClass('showactive'); 
        return (
            <ReportManagementTemplate searchKeyup={this.searchkeyup}/>
        );
    }
}