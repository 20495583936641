import React from 'react'
import PubSub from 'pubsub-js'
import SendRequest from '../../SendRequest';
import { lang } from "../../js/helpers/utils/languages/language";
import _ from 'underscore';
const $ = window.$;
export default class SamrtInsightsHeaderViewTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.logOut = this.logOut.bind(this);
    }
    logOut(e) {
        let requestHeaders1 = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "logoutbird",
            queryString: "",
            sucessFunction: (props) => {
                window.localStorage.clear();
                sessionStorage.removeItem("accessToken");
                window.location.replace("/")
            },
            rejectFunction: () => { },
            headers: requestHeaders1,
        })

    };
    componentDidMount() {
        var that = this;
        $("#maxfiltersdropdowns").click(function () {
            PubSub.publish("maxfiltersdropdownold");
        })
        $("#maxcustomparameterfiltersdropdowns").click(function () {
            PubSub.publish('maxcustomparameterfiltersdropdownold')
        })
        $(".settingmodal").click(function () {
            that.props.showaddsettingmodal();
        })
        $(".refreshcharts").click(function (e) {
            e.preventDefault();
            var fragmentArray = window.location.pathname.split("/");
            if (fragmentArray != undefined && fragmentArray.length > 5) {
                PubSub.publish('refreshsavedcharts');
            } else if (fragmentArray != undefined && fragmentArray.length == 5) {
                PubSub.publish('refreshcharts');
            }
        })
    }
    render() {
        var thiscopy = this;
        var that = this.props;
        var isPrivate = window.location.pathname.indexOf("private") > 0 ? true : false;

        if (that.isEmbeddedReport != true) {
            var titlemode = "";
            _.each(that.allfeatures, function (featuresModule) {
                if (featuresModule.moduleName == 'story_board') {
                    if (that.isSharedReport == false) {
                        if (that.isScheduleReport === true) {
                        } else {
                            _.each(featuresModule.features, function (feature) {
                                if (feature.featureName == 'viewreport_edit_title' && feature.allow == true) {
                                    titlemode = "settingmodal titleeditmode";
                                }
                            })
                        }
                    } else {
                        _.each(featuresModule.features, function (feature) {
                            if (feature.featureName == 'viewreport_save_shared_report' && feature.allow == true) {
                                _.each(featuresModule.features, function (feature) {
                                    if (feature.featureName == 'viewreport_edit_title' && feature.allow == true) {
                                        titlemode = "settingmodal titleeditmode";
                                    }
                                })
                            }
                        })
                    }
                }
            })
        }
        return (

            // <!-- smartinsightheaderview.js -->
            <div className="container-fluid">
                <div className="row controlbar-main">
                    <div className="col-lg-7 col-xs-7 flex-baseline-div">
                        {/* <!-- Edit Title --> */}
                        <h2 className={`boc-viewreport-title ${titlemode}`} id="viewreport" data-num="storyboard" tab-id="0" tab-name="" title={that.title}>{that.title}</h2>
                        {/* <!--\Edit Title  --> */}
                        <div className="datacount storyboarddatacount hide">
                            <span className="publishinfo"></span><span className="filterdatacount"></span>
                            <span className="lastexecutedtimestamp"></span>
                        </div>
                    </div>
                    <div className="pull-right marg-left-auto">
                        <div className="">
                            <div className="col-xs-12 storyboardtoolbarfull">

                                <div className="row">
                                    <div className="btn-group pull-right dropdown bgcommon" role="group" aria-label="...">
                                        {/* <!--  Filter &  Refresh Buttons--> */}
                                        {(that.isEmbeddedReport != true) ? (
                                            that.allfeatures.map((featuresModule, index) => {
                                                if (featuresModule.moduleName == 'story_board') {
                                                    if (that.isSharedReport == false) {
                                                        if (that.isScheduleReport === true) {
                                                            featuresModule.features.map((feature) => {
                                                                if ((that.isDirect == 0 || that.isStoredProcedure == 0) && feature.featureName == 'viewreport_embed_report_and_email' && feature.allow == true) {
                                                                    // <!-- <button type="button" className="btn btn-bird-group tooltip-bottom addFiltermodal modalfilter" title="Filters" data-toggle="modal" data-target="#mySmallModal"><i className="fa fa-filter"></i></button> -->
                                                                    // <!-- <button type="button" className="btn btn-bird-group tooltip-bottom togglefiltersmenu" id="togglefiltersmenus" title="Filters" data-show="0"><i className="fa fa-filter"></i></button> -->
                                                                    return <span style={{ float: "left", position: "relative" }}>
                                                                        <button type="button" className="dropdown-toggle btn btn-bird-group" data-direction="right" data-toggle="dropdown" aria-expanded="true" title={lang["smartsights.filters"]}><i className="fa fa-filter"></i></button>
                                                                        <ul className="dropdown-menu dropdown-menu-right">
                                                                            <li><a href="javascript:void(0)" className="modalfilter maxfiltersdropdown " id="maxfiltersdropdowns" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["smartsights.addfilter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["smartsights.addfilter"]}</a></li>
                                                                            {/* <li><a href="javascript:void(0)" className="modalfilter maxcustomparameterfiltersdropdown " id="maxcustomparameterfiltersdropdowns" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["smartsights.addparameter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["smartsights.addparameter"]}</a></li> */}
                                                                        </ul>
                                                                    </span>
                                                                }
                                                            })
                                                        } else {
                                                            return <>
                                                                {featuresModule.features.map((feature) => {
                                                                    if ((that.isDirect == 0 || that.isStoredProcedure == 0) && feature.featureName == 'viewreport_smart_insights' && feature.allow == true) {
                                                                        // <!-- <button className="btn btn-bird-group smartinsights" title="Smart Insights"><i className="fa fa-smartinsights"></i></button> -->
                                                                    }
                                                                    if ((that.isDirect == 0 || that.isStoredProcedure == 0) && feature.featureName == 'viewreport_nlp_search' && feature.allow == true) {
                                                                        // <!-- <button type="button" className="btn btn-bird-group tooltip-bottom intelligentsearch" id="intelligentsearchicon" title="Intelligent Search"><i className="fa fa-search"></i></button> -->
                                                                    }
                                                                    if ((that.isDirect == 0 || that.isStoredProcedure == 0) && feature.featureName == 'viewreport_filters' && feature.allow == true) {
                                                                        // <!-- <button type="button" className="btn btn-bird-group tooltip-bottom addFiltermodal modalfilter" title="Filters" data-toggle="modal" data-target="#mySmallModal"><i className="fa fa-filter"></i></button> -->
                                                                        // <!-- <button type="button" className="btn btn-bird-group tooltip-bottom togglefiltersmenu" id="togglefiltersmenunew" title="Filters" data-show="0"><i className="fa fa-filter"></i></button> -->
                                                                        return <span style={{ float: "left", position: "relative" }}>
                                                                            <button type="button" className="dropdown-toggle btn btn-bird-group" data-direction="right" data-toggle="dropdown" aria-expanded="true" title={lang["smartsights.filters"]}><i className="fa fa-filter"></i></button>
                                                                            <ul className="dropdown-menu dropdown-menu-right">
                                                                                <li><a href="javascript:void(0)" className="modalfilter maxfiltersdropdown " id="maxfiltersdropdowns" onClick={this.maxfiltersdropdown} data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["smartsights.addfilter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["smartsights.addfilter"]}</a></li>
                                                                                {/* <li><a href="javascript:void(0)" className="modalfilter maxcustomparameterfiltersdropdown " id="maxcustomparameterfiltersdropdowns" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["smartsights.addparameter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["smartsights.addparameter"]}</a></li> */}
                                                                            </ul>
                                                                        </span>
                                                                    }
                                                                    // 													if((that.isDirect == 0) && reportRequestFrom == "viewsavedreportview" 
                                                                    // 													&& feature.featureName=='viewreport_updatedata'&& feature.allow==true){
                                                                    // 														// <!-- This button should be displayed only in saved storyboard -->
                                                                    // // <!-- 														<button type="button" className="btn btn-bird-group tooltip-bottom updateesdata" id="storyboarddataupdateicon" title="Update Data"><i className="fa fa-pencil"></i></button> -->
                                                                    // 													}
                                                                })}
                                                                {(that.schematype == "customstoryboard") ? (
                                                                    <button type="button" className="btn btn-bird-group tooltip-bottom customstoryboard refreshcharts" id="refreshchartsicon" title={lang["smartsights.refresh"]}><i className="fa fa-refresh"></i></button>
                                                                ) :
                                                                    <button type="button" className="btn btn-bird-group tooltip-bottom refreshcharts" id="refreshchartsicon" title={lang["smartsights.refresh"]}><i className="fa fa-refresh"></i></button>
                                                                }
                                                            </>
                                                        }
                                                    } else if (that.isSharedReport === true) {
                                                        featuresModule.features.map((feature, index) => {
                                                            if (feature.featureName == 'viewreport_save_shared_report' && feature.allow == true) {
                                                                return <> {featuresModule.features.map((feature, index1) => {
                                                                    if ((that.isDirect == 0 || that.isStoredProcedure == 0) && feature.featureName == 'viewreport_filters' && feature.allow == true) {
                                                                        // <!-- <button type="button" className="btn btn-bird-group tooltip-bottom togglefiltersmenu" id="newtogglefiltersmenu" title="Filters" data-show="0"><i className="fa fa-filter"></i></button> -->
                                                                        return <span key={index1} style={{ float: "left", position: "relative" }}>
                                                                            <button type="button" className="dropdown-toggle btn btn-bird-group" data-direction="right" data-toggle="dropdown" aria-expanded="true" title={lang["smartsights.filters"]}><i className="fa fa-filter"></i></button>
                                                                            <ul className="dropdown-menu dropdown-menu-right">
                                                                                <li><a href="javascript:void(0)" className="modalfilter maxfiltersdropdown " id="maxfiltersdropdowns" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["smartsights.addfilter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["smartsights.addfilter"]}</a></li>
                                                                                {/* <li><a href="javascript:void(0)" className="modalfilter maxcustomparameterfiltersdropdown " id="maxcustomparameterfiltersdropdowns" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["smartsights.addparameter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["smartsights.addparameter"]}</a></li> */}
                                                                            </ul>
                                                                        </span>
                                                                    }
                                                                })}
                                                                    <button key={index} type="button" className="btn btn-bird-group tooltip-bottom refreshcharts" id="refreshchartsstory" title={lang["smartsights.refresh"]}><i className="fa fa-refresh"></i></button>
                                                                </>
                                                            }
                                                        })
                                                    }

                                                } else {
                                                    featuresModule.features.map((feature) => {
                                                        if (feature.featureName == 'viewreport_save_shared_report' && feature.allow == true) {
                                                            return <>
                                                                {featuresModule.features.map((feature) => {
                                                                    if (feature.featureName == 'viewreport_filters' && feature.allow == true) {
                                                                        // <!-- <button type="button" className="btn btn-bird-group tooltip-bottom togglefiltersmenu" id="togglefiltersmenufilter" title="Filters" data-show="0"><i className="fa fa-filter"></i></button> -->
                                                                        return <span style={{ float: "left", position: "relative" }}>
                                                                            <button type="button" className="dropdown-toggle btn btn-bird-group" data-direction="right" data-toggle="dropdown" aria-expanded="true" title={lang["smartsights.filters"]}><i className="fa fa-filter"></i></button>
                                                                            <ul className="dropdown-menu dropdown-menu-right">
                                                                                <li><a href="javascript:void(0)" className="modalfilter maxfiltersdropdown " id="maxfiltersdropdowns" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["smartsights.addfilter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["smartsights.addfilter"]}</a></li>
                                                                                {/* <li><a href="javascript:void(0)" className="modalfilter maxcustomparameterfiltersdropdown " id="maxcustomparameterfiltersdropdowns" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["smartsights.addparameter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["smartsights.addparameter"]}</a></li> */}
                                                                            </ul>
                                                                        </span>
                                                                    }
                                                                })}
                                                                <button type="button" className="btn btn-bird-group tooltip-bottom refreshcharts" id="filterrefreshcharts" title={lang["smartsights.refresh"]}><i className="fa fa-refresh"></i></button>
                                                            </>
                                                        }
                                                    })
                                                }
                                            })

                                        ) : null}
                                        {/* <!--/  Filter &  Refresh Buttons--> */}
                                        {(that.isEmbeddedReport != true && that.isPurgedReport == false) ? (
                                            that.allfeatures.map((featuresModule) => {
                                                if (featuresModule.moduleName == 'story_board') {
                                                    var dropdowntog = false;
                                                    var General = false;
                                                    var Customize = false;
                                                    var Collaborate = false;
                                                    if (that.isSharedReport == false) {
                                                        if (that.isScheduleReport === true) {
                                                            featuresModule.features.map((feature) => {
                                                                if (feature.featureName == 'viewreport_embed_report_and_email' && feature.allow == true) {
                                                                    // <!-- <button type="button" className="btn btn-bird-group tooltip-bottom addFiltermodal" title="Filters" data-toggle="modal" data-target="#mySmallModal"><i className="fa fa-filter"></i></button> -->
                                                                }
                                                            })
                                                        } else {
                                                            featuresModule.features.map((feature) => {
                                                                if ((feature.featureName === 'viewreport_query_info' && feature.allow == true) || (feature.featureName === 'viewreport_save_report' && feature.allow == true) || (feature.featureName === 'viewreport_clone_report' && feature.allow == true) || (feature.featureName === 'viewreport_colortheme' && feature.allow == true) || (feature.featureName === 'viewreport_edit_title' && feature.allow == true) || (feature.featureName === 'viewreport_add_new_tile' && feature.allow == true) || (feature.featureName == 'viewreport_favourites' && feature.allow == true) || (feature.featureName === 'viewreport_share_report' && feature.allow == true) || (feature.featureName === 'viewreport_embed_report_and_email' && feature.allow == true) || (feature.featureName === 'viewreport_schedule_reports' && feature.allow == true)) {
                                                                    dropdowntog = true;
                                                                }
                                                            })
                                                            return (dropdowntog) ? (
                                                                <>  <button type="button" className="btn btn-bird-group tooltip-bottom dropdown-toggle dropdown" id="dropdownmore" title={lang["smartsights.more"]} data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v"></i></button>
                                                                    <ul className="dropdown-menu dropdown-menu-right megadropdown">
                                                                        {featuresModule.features.map((feature) => {
                                                                            if ((feature.featureName === 'viewreport_query_info' && feature.allow == true) || (feature.featureName === 'viewreport_save_report' && feature.allow == true) || (feature.featureName === 'viewreport_clone_report' && feature.allow == true)) {
                                                                                General = true;
                                                                            }
                                                                        })}
                                                                        {(General) ? (
                                                                            <li className="dropdown-header">{lang["smartsights.general"]}</li>
                                                                        ) : null}
                                                                        {featuresModule.features.map((feature) => {
                                                                            if (feature.featureName == 'viewreport_save_report' && feature.allow == true) {
                                                                                return <li><a href="javascript:void(0)" className="savestoryboard" onClick={that.savestoryboard} title={lang["smartsights.saveboard"]} id="dropdownsave"><i className="fa fa-floppy-o marg-righ-10"></i>{lang["smartsights.saveboard"]}</a></li>
                                                                            }
                                                                        })}
                                                                        {featuresModule.features.map((feature) => {
                                                                            if (feature.featureName == 'viewreport_clone_report' && feature.allow == true) {
                                                                                return <li><a href="javascript:void(0)" className="clonereport" onClick={that.clonereport} id="clonereports" title={lang["smartsights.cloneboard"]}><i className="fa fa-files-o marg-righ-10"></i>{lang["smartsights.cloneboard"]}</a></li>
                                                                            }
                                                                        })}

                                                                        {featuresModule.features.map((feature) => {
                                                                            if ((feature.featureName === 'viewreport_colortheme' && feature.allow == true) || (feature.featureName === 'viewreport_edit_title' && feature.allow == true) || (feature.featureName === 'viewreport_add_new_tile' && feature.allow == true) || (feature.featureName == 'viewreport_favourites' && feature.allow == true)) {
                                                                                Customize = true;
                                                                            }
                                                                        })}

                                                                        {(Customize) ? (
                                                                            <><li className="divider"></li>
                                                                                <li className="dropdown-header">{lang["smartsights.customize"]}</li></>
                                                                        ) : null}
                                                                        {(that.isViewModel != true) ? (
                                                                            <>
                                                                                {featuresModule.features.map((feature) => {
                                                                                    if (feature.featureName == 'viewreport_colortheme' && feature.allow == true) {
                                                                                        //     return <li><a href="javascript:void(0)" id="storyboardcolortheme" title="Storyboard Theme" data-toggle="modal" data-target="#storyboardtheme"><i className="fa fa-paint-brush marg-righ-10 fa-violet"></i>Storyboard Theme</a></li>
                                                                                    }
                                                                                })}
                                                                                {featuresModule.features.map((feature) => {
                                                                                    if (feature.featureName == 'viewreport_edit_title' && feature.allow == true) {
                                                                                        //     return <li><a href="javascript:void(0)" className="settingmodal" id="settingmodals" title="Edit Title"><i className="fa fa fa-pencil marg-righ-10"></i>Edit Title</a></li>
                                                                                    }
                                                                                })}
                                                                            </>
                                                                        ) : null}
                                                                        {featuresModule.features.map((feature) => {
                                                                            if (feature.featureName == 'viewreport_favourites' && feature.allow == true) {
                                                                                if (that.isfavorite === "Y") {
                                                                                    return <li><a href="javascript:void(0)" id="setstoryboardasfavourite" onClick={that.setAsFavourite} title={lang["smartsights.favorite"]}><i className="fa fa-star fa-yellow marg-righ-10"></i>{lang["smartsights.favorite"]}</a></li>
                                                                                } else {
                                                                                    return <li><a href="javascript:void(0)" id="setstoryboardasfavourite" onClick={that.setAsFavourite} title={lang["smartsights.favorite"]}><i className="fa fa-star marg-righ-10"></i>{lang["smartsights.favorite"]}</a></li>
                                                                                }
                                                                            }
                                                                        })}

                                                                        {featuresModule.features.map((feature) => {
                                                                            if ((feature.featureName === 'viewreport_share_report' && feature.allow == true) || (feature.featureName === 'viewreport_embed_report_and_email' && feature.allow == true) || (feature.featureName === 'viewreport_schedule_reports' && feature.allow == true)) {
                                                                                Collaborate = true;
                                                                            }
                                                                        })}
                                                                        {(Collaborate) ? (
                                                                            <><li className="divider"></li>
                                                                                <li className="dropdown-header">{lang["smartsights.collaborate"]}</li></>

                                                                        ) : null}

                                                                        {featuresModule.features.map((feature) => {
                                                                            if (feature.featureName == 'viewreport_share_report' && feature.allow == true) {
                                                                                return <li><a href="javascript:void(0)" id="sharemodal" onClick={that.sharemodal} title={lang["smartsights.shareboard"]}><i className="fa fa-users marg-righ-10"></i>{lang["smartsights.shareboard"]}</a></li>
                                                                            }
                                                                        })}
                                                                        {featuresModule.features.map((feature) => {
                                                                            if (feature.featureName == 'viewreport_embed_report_and_email' && feature.allow == true) {
                                                                                return <li><a href="javascript:void(0)" id="embedreportmodal" onClick={that.embedreportmodal} title={lang["smartsights.embedboard"]}><i className="fa fa-code marg-righ-10"></i>{lang["smartsights.embedboard"]}</a></li>
                                                                            }
                                                                        })}
                                                                        {(that.isViewModel != true) ? (
                                                                            <>
                                                                                {featuresModule.features.map((feature) => {
                                                                                    if (feature.featureName == 'viewreport_schedule_reports' && feature.allow == true) {
                                                                                        //    return <li><a href="javascript:void(0)" id="schedulestoryboardreport" title="Schedule Storyboard"><i className="fa fa-clock-o marg-righ-10"></i>Schedule Storyboard</a></li>
                                                                                    }
                                                                                })}
                                                                                {featuresModule.features.map((feature) => {
                                                                                    if (feature.featureName == 'download_storyboard' && feature.allow == true && !that.isMicrosoftEdge) {
                                                                                        //   return <li><a href="javascript:void(0)" className="download" id="download" title="Download"><i className="fa fa-download fa-flip-horizontal marg-righ-10"></i>Download</a></li>
                                                                                    }
                                                                                })}
                                                                            </>
                                                                        ) : null}

                                                                    </ul>
                                                                </>) : null

                                                        }
                                                    } else {
                                                        featuresModule.features.map((feature) => {
                                                            if (feature.featureName == 'viewreport_save_shared_report' && feature.allow == true) {
                                                                featuresModule.features.map((feature) => {
                                                                    if ((feature.featureName == 'viewreport_colortheme' && feature.allow == true) || (feature.featureName == 'viewreport_query_info' && feature.allow == true) || (feature.featureName == 'viewreport_edit_title' && feature.allow == true) || (feature.featureName == 'viewreport_add_new_tile' && feature.allow == true) || (feature.featureName == 'viewreport_save_report' && feature.allow == true)) {
                                                                        dropdowntog = true;
                                                                    }
                                                                })
                                                                {
                                                                    return (dropdowntog) ? (
                                                                        <> <button type="button" className="btn btn-bird-group tooltip-bottom dropdown-toggle dropdown" title={lang["smartsights.more"]} data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v"></i></button>
                                                                            <ul className="dropdown-menu dropdown-menu-right megadropdown">
                                                                                {featuresModule.features.map((feature) => {
                                                                                    if ((feature.featureName === 'viewreport_query_info' && feature.allow == true) || (feature.featureName === 'viewreport_save_report' && feature.allow == true)) {
                                                                                        General = true;
                                                                                    }
                                                                                })}
                                                                                {(General) ? (
                                                                                    <li className="dropdown-header">{lang["smartsights.general"]}</li>
                                                                                ) : null}

                                                                                {featuresModule.features.map((feature) => {
                                                                                    if (feature.featureName == 'viewreport_save_report' && feature.allow == true) {
                                                                                        return <li><a href="javascript:void(0)" className="savestoryboard" onClick={that.savestoryboard} title={lang["smartsights.save"]} id="dropdownsave"><i className="fa fa-floppy-o marg-righ-10"></i>{lang["smartsights.saveboard"]}</a></li>
                                                                                    }
                                                                                })}
                                                                                {(that.isViewModel != true) ? (
                                                                                    <>
                                                                                        {featuresModule.features.map((feature) => {
                                                                                            if ((feature.featureName == 'viewreport_colortheme' && feature.allow == true) || (feature.featureName == 'viewreport_edit_title' && feature.allow == true) || (feature.featureName == 'viewreport_add_new_tile' && feature.allow == true)) {
                                                                                                Customize = true;
                                                                                            }
                                                                                        })}
                                                                                        {(Customize) ? (
                                                                                            <><li className="divider"></li>
                                                                                                <li className="dropdown-header">{lang["smartsights.customize"]}</li></>
                                                                                        ) : null}

                                                                                        {featuresModule.features.map((feature) => {
                                                                                            if (feature.featureName == 'viewreport_colortheme' && feature.allow == true) {
                                                                                                return <li><a href="javascript:void(0)" id="storyboardcolortheme" title={lang["smartsights.boardtheme"]} data-toggle="modal" data-target="#storyboardtheme"><i className="fa fa-paint-brush marg-righ-10 fa-violet"></i>{lang["smartsights.boardtheme"]}</a></li>
                                                                                            }
                                                                                        })}

                                                                                        {featuresModule.features.map((feature) => {
                                                                                            if (feature.featureName == 'viewreport_edit_title' && feature.allow == true) {
                                                                                                return <li><a href="javascript:void(0)" className="settingmodal" title={lang["smartsights.settings"]}><i className="fa fa fa-pencil marg-righ-10"></i>{lang["smartsights.edittitle"]}</a></li>
                                                                                            }
                                                                                        })}
                                                                                        {featuresModule.features.map((feature) => {
                                                                                            if (feature.featureName == 'viewreport_add_new_tile' && feature.allow == true) {
                                                                                                return <li><a href="javascript:void(0)" className="newcharttile" title={lang["smartsights.newcharttile"]}><i className="fa fa-th marg-righ-10"></i>{lang["smartsights.newcharttile"]}</a></li>
                                                                                            }
                                                                                        })}
                                                                                    </>
                                                                                ) : null}
                                                                            </ul></>

                                                                    ) : null
                                                                }

                                                            }
                                                        })

                                                    }
                                                }
                                            })

                                        ) : (

                                                that.allfeatures.map((featuresModule) => {
                                                    if (featuresModule.moduleName == 'story_board') {
                                                        return <> {featuresModule.features.map((feature) => {
                                                            if ((that.isDirect == 0 || that.isStoredProcedure == 0) && feature.featureName == 'viewreport_embeded_filters' && feature.allow == true && that.isPurgedReport == false) {
                                                                return <span style={{ float: "left", position: "relative" }}>
                                                                    <button type="button" className="dropdown-toggle btn btn-bird-group" data-direction="right" data-toggle="dropdown" aria-expanded="true" title={lang["smartsights.filters"]}><i className="fa fa-filter"></i></button>
                                                                    <ul className="dropdown-menu dropdown-menu-right">
                                                                        <li><a href="javascript:void(0)" className="modalfilter maxfiltersdropdown " id="maxfiltersdropdowns" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["smartsights.addfilter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["smartsights.addfilter"]}</a></li>
                                                                        {/* <li><a href="javascript:void(0)" className="modalfilter maxcustomparameterfiltersdropdown " id="maxcustomparameterfiltersdropdowns" data-direction="left" data-toggle="modal" data-target="#mySmallModal" title={lang["smartsights.addparameter"]}><i className="fa fa-plus marg-righ-10"></i>{lang["smartsights.addparameter"]}</a></li> */}
                                                                    </ul>
                                                                </span>
                                                            }
                                                        })}
                                                            {(isPrivate) ? (
                                                                <button type="button" className="btn btn-bird-group" onClick={this.logOut} id="embedlogout" title={lang["smartsights.logout"]}><i className="fa fa-power-off"></i></button>
                                                            ) : null}
                                                        </>
                                                    }
                                                })
                                            )}
                                        {/* </ul>			 */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="quickinfotogglecontainer">
                        {that.allfeatures.map((featuresModule) => {
                            featuresModule.features.map((feature) => {
                                if (feature.featureName == 'viewreport_quick_information' && feature.allow == true) {
                                    return <span className="quickinfotoggle" title={lang["smartsights.quickinfo"]}><i className="fa fa-chevron-down"></i></span>
                                }
                            })
                        })}
                    </div>
                </div>
                <div id="viewreportinfoview"></div>
                <div className="append_drilldownallsettingspop"></div>
                <div className="livereportmodaldisplay"></div>
                <div className="usercustomscriptmodaldisplay"></div>
                <div id="changetimezonemodelview"></div>
                <div id="metadataauditview"></div>
                <div id="purgedindicesmodelview"></div>
                <div className="queryinfomodaldisplay"></div>
                <div className="settingmodalheaderdisplay"></div>
                <div id="filtermodal"></div>
                <div className="savestoryboarddiv"></div>
                <div className="sharediv"></div>
                <div className="addreportthememodal"></div>
                <div className="addstoryboardcolorthememodal"></div>
                <div className="embedreportdiv"></div>
                <div id="schedulereportdiv" className="schedulereportdiv"></div>
                <div id="advancedaccesspermissonscontainer"></div>
                <div id="advancedaccesspermissonscontainer"></div>
                <div className="alert alert-success clonesinglereportsuccess" style={{ display: "none" }} role="alert">{lang["smartsights.clonemsg"]}</div>
                <div className="alert alert-danger clonesinglereporterror" style={{ display: "none" }} role="alert">{lang["smartsights.errormsg"]}</div>
                <div id="singlereportchartsereiscolors"></div>
            </div>
        );
    }
}