import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'underscore';
import WorkspaceCustomfieldsPreviewHtml from './WorkspaceCustomfieldsPreviewHtml.jsx';
const $ = window.$;

export default class WorkspaceCustomfieldsPreview extends React.Component {
    constructor(props) {
        super(props);
        this.customfields = this.props.customfields;
        this.entityName = this.props.entityName;
        this.data_parent = this.props.data_parent;
        this.entityId = this.props.entityId;
    }
    componentDidMount() {
        this.loadcustomfields();
    }
    loadcustomfields = (e) => {
        var CFS = [];
        var that = this;
        if (this.customfields != undefined && this.customfields != null) {
            _.each(this.customfields, function (list, i) {
                var tempObj = {};
                tempObj["entityName"] = that.entityName;
                tempObj["customFieldName"] = list.name;
                tempObj["customFieldFormula"]=list.sqlExp!=undefined&&list.sqlExp!=''?list.sqlExp:list.sqlformula;
                tempObj["data_parent"] = that.data_parent;
                tempObj["entityId"] = that.entityId;
                tempObj["editcustomfields"] = <td><span className="editcustomfields" onClick={that.props.createworkspacecustomfield} data-edit={true} data_parent={that.data_parent} entity_id={that.entityId} name={that.entityName} id={list.name}><i class="fa fa-pencil"></i></span></td>
                tempObj["deletecustomfields"] = <td><span className="deletecustomfields" data-delete={true} onClick={that.props.createworkspacecustomfield} data_parent={that.data_parent} entity_id={that.entityId} name={that.entityName} id={list.name}><i class="fa fa fa-trash-o"></i></span></td>
                CFS.push(tempObj);
            });
        }
        if(CFS.length != 0){
            CFS.sort(function(a, b){
            var x = a.customFieldName.toLowerCase();
            var y = b.customFieldName.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0 ;
            });
        }
        ReactDOM.render(<WorkspaceCustomfieldsPreviewHtml data={CFS} createworkspacecustomfield={this.props.createworkspacecustomfield} />, document.getElementById('customfieldscontainer'));
    }
    render() {
        return (<></>);
    }
}