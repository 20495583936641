import React, { Component } from "react";
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import _ from 'lodash';
import ReactDOM from "react-dom";
import { getMessage } from '../js/helpers/utils/BirdMessage';
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;


export default class AddDimensionsToHierarchyModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicdiv: props.dynamicdiv,
            xaxiscolumnDisplayName: props.xaxiscolumnDisplayName,
            xaxiscolumnName: props.xaxiscolumnName,
            xaxiscolumnType: props.xaxiscolumnType,
            iscustomfunction: props.iscustomfunction,
            xaxistableName: props.xaxistableName
        };
        this.render();
    }
    render() {
        if (this.props.cameFrom != undefined && this.props.cameFrom == "rangelistdrop" && $(".rangeattributes li.singleliitems").length >= 1) {
            this.chechElements();
        }
        else {
            ReactDOM.render(<AddDimensionsToHierarchyModalTemplate
                dynamicdiv={this.state.dynamicdiv}
                xaxiscolumnDisplayName={this.state.xaxiscolumnDisplayName}
                xaxiscolumnName={this.state.xaxiscolumnName}
                xaxiscolumnType={this.state.xaxiscolumnType}
                xaxistableName={this.state.xaxistableName}
                iscustomfunction={this.state.iscustomfunction}
            ></AddDimensionsToHierarchyModalTemplate>, document.getElementById(this.state.dynamicdiv));
        }
        return (<div></div>);
    }
    chechElements() {
        $(".spinner").hide();
        $(".gridspinner").hide();
        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
        $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
        $('.birdmessagebuttons').removeClass('show');
        $('.birdmessagebuttons').addClass('hide');
        $('.birdmessage-center').addClass("message-top-in");
        $('.details').removeClass('show');
        $('.details').addClass('hide');
        $('.birdmessage h2').empty().append('Warning');
        $('.birdmessage-info').empty().text(getMessage('BirdWarning113'));
    }
}

class AddDimensionsToHierarchyModalTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.deleteelement = this.deleteelement.bind(this);
    }
    render() {
        var that = this.props;
        var dataType = that.xaxiscolumnType;
        var dataIcon = lang["storyboard.abc"];
        if (dataType == "number") {
            dataIcon = "123";
        } else if (dataType == "date" || dataType == "datetime" || dataType === 'month' || dataType === 'day') {
            dataIcon = <i className='fa fa-calendar'></i>
        } else if (dataType == "customhierarchy") {
            dataIcon = <i className='fa fa-list'></i>
        }
        return (
            <li key={that.dynamicdiv} className="boc-list-item singleliitems" id="itemdisplay" iscustomfunction={that.iscustomfunction} data-name={that.xaxiscolumnName} title={that.xaxiscolumnDisplayName} data-type={that.xaxiscolumnType} table-name={that.xaxistableName}>
                {/* <!-- adddimensiontohierarchymodal.js --> */}
                <span className="itemtype">{dataIcon}</span>
                <span className="itemname" >{that.xaxiscolumnDisplayName}</span>
                <span className="btn-group pull-right">
                    <button className="btn btn-transparent deletehierarchyfield" onClick={this.deleteelement} type="button">
                        <i className="fa fa-trash"></i>
                    </button>
                </span>
            </li>);
    }
    deleteelement(e) {
        var $this = e.currentTarget;
        $($this).parent().parent().parent().remove();
    }
}