import React from 'react';
import DatamodelCustomqueryHtml from './DatamodelCustomqueryHtml.jsx';
import 'brace/theme/sqlserver';
import 'brace/mode/sql';
import Clipboard from 'clipboard';
import { format } from 'sql-formatter';
const ace = require('brace');
const $=window.$;

export default class DatamodelCustomquery extends React.Component {
    constructor(props) {
        super(props);
        this.entityName = this.props.entityName;
        this.type = this.props.type
        this.workspaceentityid = this.props.workspaceentityid;
        this.query = this.props.query;
    }
    componentDidMount() {
        var that= this;
        var ua = window.navigator.userAgent;
        var edge = ua.indexOf("Edge");
        if (edge < 0) {
            ace.require("brace/ext/language_tools");
            this.editor = ace.edit("advancedcustomquery");
            this.editor.setTheme("ace/theme/sqlserver");
            this.editor.session.setMode("ace/mode/sql");
            this.editor.setOptions({
                enableBasicAutocompletion: true,
                enableSnippets: true,
                enableLiveAutocompletion: true
            });
            this.editor.setOption("wrap", true);
				this.editor.textInput.getElement().setAttribute('id','copyquery');
				that.editor.getSession().on('change', function() {
					$("#copycustomquery").attr('data-clipboard-text',that.editor.getSession().getValue())
				});
                $("#advancedcustomquery").on("paste",function(e){
                    that.editor.session.setValue(format(that.editor.session.getValue()))
                })
			}
			new Clipboard('.copycustomquery')
        
    }
    render() {
        if(this.query!=undefined&&this.query.length>0){
            this.query = format(this.query)
        }
        return (
            <DatamodelCustomqueryHtml
                dmcustomqueryName={this.entityName}
                dmcustomquery={this.query}
                workspaceentityid={this.workspaceentityid}
                type={this.type}
                validatedatamodelcustomquery={this.props.validatedatamodelcustomquery}
                savedatamodelcustomquery={this.props.savedatamodelcustomquery}
            />
        );
    }
}