
export var allentities = [];
export var allentitynames = [];

export function resetCollection() {
    allentities = [];
    allentitynames = [];
}
export function updateAllEntityDetails(entities) {
    allentities = entities;
}
export function getAllEntityDetails() {
    return allentities;
}
export function updateAllEntityNames(entName) {
    allentitynames.push(entName);
}
export function getAllEntityNames() {
    return allentitynames;
}

