import _, { set, get } from 'lodash';
import { getMessage } from '../utils/BirdMessage';
const $ = window.$;
export var importreportdatamodelId = "";
export var smartinsightDataObj = {};
export var mlChartData = {}
export var chartDetails = {};
export var tileLevelFilters={};
export var chartDetailsForLog = {};
export var mlChartDetails = {};
export var mlModelDetails = {};
export var shredFilterArrayForDrillDown = [];
export var mlGridData = [];
export var tempStoryBookDetails = [];
export var customStoryboardchartdetails=[]
export var mlSelectedColumns = [];
export var smartinsightSelectedColumns = [];
export var mlColumnHavingTwoDistinctValues = [];
export var dataSetCount = "";
export var customStoryboardRefChartNo = null;
export var isDirect;
export var smartInsightsTargetVal;
export var isStoredProcedure;
export var filterDataCount = "";
export var multixAxiscategorie = [];
export var exclueValues = '';
export var dateHistogramXaxisData = [];
export var dateHistogramXaxisDataForML = [];
export var filtersForDataBehindChart = [];
export var dateHistogramStatus = [];
export var dateHistogramStatusForML = [];
export var summaryTableDateHistogramStatus = [];
export var customhierarchy = [];
export var custommeasurehierarchyArray = [];
export var range = [];
export var custommeasurefield = [];
export var customparamerfield = [];
export var filterArray = [];
export var filterExcludeData = [];
export var filterIncludeData = [];
export var instanceFilterData = [];
export var instanceFilterDataForDrillDown = [];
export var instanceIncludeFilterData = [];
export var drillDownInstantFilterData = [];
export var drillDownInstantInlcudeFilterData = [];
export var isFilterModalOpen = false;
export var mValue = '';
export var bValue = '';
export var quadratic_aValue = "";
export var quadratic_bValue = "";
export var quadratic_cValue = "";
export var polnomial_aValue = "";
export var polnomial_bValue = "";
export var polnomial_dValue = "";
export var polnomial_eValue = "";
export var polnomial_fValue = "";
export var polnomial_gValue = "";
export var dateTime_ms = new Date().getTime();
export var customStoryBoardDetails = [];
export var customStoryBoardFilterDetails = [];
export var customStoryBoardChartDetails = [];
export var alert = [];
export var filterArrays = [];
export var reportArrayMapOfMissingCol = {};
export var reportCorrelationData = {};
export var mainFiterObject = [];
export var drilldownChartNumber;
export var userfilterArray = {
    "users": {},
    "groups": {}
};
export var appliedSharedReprotFilters = {
    "users": {},
    "groups": {}
};
export var summaryText = {};
export var chartComponent = {};
export var isExcludeNulls;
export var sharedReportsSelectedColumns = [];
export var isViewOnlyOptions = [];
export var isViewOnlyFlag = [];
export var summarytablefiltervalues = {};
export var summarytableaxisfieldsArraydetails = {};
export var livedatasettings = {};
export var intervalvalueslist = {};
export var listOfWebSockets = {};
export var websocket_chartnumber_reportId = {};
export var drillfitlerArray = [];
export var timezoneObj = {};
export var auditMetaDataObj = {};
export var sharedFiltersForLiveReports = [];
export var ListOfIndexesmetaData;
export var purgeConfig = {};
export var reportIds = {};
export var embedfilterArray = [];
export var defaultSeriesColors = [];
export var customSeriesColors = [];
export var userParentRole = [];
export var summarytable_drilldownname = "";
export var summarytable_drilldowndatatype = "";
export var summarytable_drilldowndisplayname = "";
export var xaxisDrillDownStateObj = {};
export var customFieldsFilterObject = {};
export var sharedFilterObjectArray = {};
export var customstroyboardReportColumns = [];
export var customstroyboardMultifactObj = [];
export var customFieldsObjectReportedId = {};
export var customMesureReportId = [];
export var customHierarchyReportId = [];
export var customParameterReportId = [];
export var dateHistogramState = {};
export var dateHistogramStateForML = {};
export var customRangeReportId = [];
export var aggCustomMeasureField = [];
export var dateRangeObj = {};
export var dateSummaryTableHistogramState = {};
export var mashupaddconnection = {};
export var mashupnodes = [];
export var mashupcolumns = {};
export var editCustomQueryMashupcolumns = {};
export var mashupjoinslist = [];
export var oldCustomQueryEntities = [];
export var mashupjoinnetworkreference = [];
export var dimensions = [];
export var drillThroughReportsArray = [];
export var priorityColumnsArray = [];
export var referenceLineArray = {};
export var allFeatures = [];
// export var exclueValues = '';
export var isFiltersAdded = false;
export var axisFieldIdentity = [];
export var charttypeMap = {};
export var duplicatecolumnsMap = {};
export var mashupgroupcolors = {};
export var drillThrough = [];
export var predictionResponseData = [];
export var mashupjoinnetwork = [];
export var storyboardtimezoneObj = {};
export var singlerepotimezoneObj = {};
export var dateHistogramStatForML = {};
export var userCustomScript = {};
export var conditionalFormatArray = {};
export var summaryTableCustomHierarchyDrilldownName = "";
export var trendType = "";
export var legendFilter = [];
export var legendFilterAllChart = [];
export var saveddrilldownAll = [];
export var drillupMap = {};
export var drillupMapCount = {};
export var mapForGetDuplicateName = "";
export var latestSyncConfigData = null;
export var chartdataformap = {};
export var parameterFilterValues = [];
export var topFiveKeyInfluencers = [];
export var topKeyInfluencers = {};
export var topTwoCategoricalKeyInfluencers = {};
export var topTwoNumericalKeyInfluencers = {};
export var topLeastCatagoricalKeyInfluencers = {};
export var usedFieldsInCustomFieldsForMashUp = [];
export var selectedTablesColumnMapForMashup = {};
export var existingEntitiesMetaData = [];
export var dataModelJoinArray = [];
export var meassurehierachyNamesMap = {}
export var datefunctionsMap = {};
export var mlChartComponent={};
export var drillthroughcheckcolumnsArray=[];
export var nlptablerowlimitdetails={};
export var versionchart='N';
export var isPublished='false';
export var isPublishedOfDb='false';
export var dropAndRecreate='false';
export var searchtext='';
export var storyboardSmartInsightFilterArray = [];
export var isStoryboardSmartInsight = false;
export var isFilterAppliedForAutoML = false;
export var datamodelMetaData = {}
export var isWorkspaceLiveEnabled = false;
export var colorThemesArray = [["#00bcd4","#37c2d9","#50c8dd","#64cee2","#76d4e7","#86daeb","#95e0f0","#a3e6f4","#b1ecf9"],
["#00bcd4","#00CECC","#42DEB9","#7FEB9E","#BBF484"],
["#2737a3", "#5e52b6", "#745cc0", "#9270d0", "#ae85df", "#c99cef", "#e3b3ff", "#e1a2fd", "#de90fb", "#dd7df7", "#dc67f3", "#db4eed", "#db2ae7"],
["#1170aa", "#fc7d0b", "#a3acb9", "#57606c", "#5fa2ce", "#c85200", "#7b848f", "#a3cce9", "#ffbc79", "#c8d0d9"],
["#4e79a7", "#f28e2b", "#e15759", "#76b7b2", "#59a14f", "#edc948", "#b07aa1", "#ff9da7", "#9c755f", "#bab0ac"],
["#4f6980", "#849db1", "#a2ceaa", "#638b66", "#bfbb60", "#f47942", "#fbb04e", "#b66353", "#d7ce9f", "#b9aa97", "#7e756d"],
["#8175aa", "#6fb899", "#31a1b3", "#ccb22b", "#a39fc9", "#94d0c0", "#959c9e", "#027b8e", "#9f8f12"],
["#57c17b", "#006e8a", "#6f87d8", "#663db8", "#bc52bc", "#9e3533", "#daa05d", "#bfaf40", "#4050bf", "#bf5040", "#40afbf", "#70bf40", "#8f40bf", "#bf40a7", "#40bf58", "#bf9740", "#4068bf", "#bf4048", "#40bfb7", "#87bf40", "#7840bf", "#bf4078", "#40bf87", "#b7bf40"],
["#a6cee3", "#1f78b4", "#b2df8a", "#33a02c", "#fb9a99", "#e31a1c", "#fdbf6f", "#ff7f00", "#cab2d6", "#6a3d9a", "#ffff99", "#b15928"],
["#1f77b4", "#aec7e8", "#ff7f0e", "#ffbb78", "#2ca02c", "#98df8a", "#d62728", "#ff9896"],
["#7d74fe", "#7dff26", "#f84f1b", "#28d8d5", "#fb95b6", "#9d9931", "#f12abf", "#27ea88", "#549ad5", "#fea526", "#7b8d8b", "#bb755f", "#432e16", "#d75cfb"],
["#f86eef", "#a36ef8", "#826ef8", "#6ebaf8", "#6edaf8", "#6ef8d8", "#6ef8b7", "#6ef877", "#c3f86e", "#e3f86e", "#f8cc6e", "#cccccc"],
["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#e5d200"],
["#5687d1", "#7b615c", "#de783b", "#6ab975", "#a173d1", "#bbbbbb"],
["#d84b2a", "#ee9586", "#e4b7b2", "#beccae", "#9caf84", "#7aa25c"],
["#b71c1c", "#c62828", "#d32f2f", "#e53935", "#f44336", "#ef5350", "#e57373", "#ef9a9a", "#ffcdd2", "#ffebee"],
["#004d40", "#00695c", "#00796b", "#00897b", "#009688", "#26a69a", "#4db6ac", "#80cbc4", "#b2dfdb", "#e0f2f1"],
["#aa1d01", "#c92100", "#e32500", "#ff4e00", "#ff5f19", "#ff7538", "#ff8149", "#fe9d73", "#ff5400", "#ff9b30", "#d38100", "#d3a400", "#af6700", "#d07100"],
["#2737a3", "#7c2da0", "#b31e93", "#dc1a7e", "#f73465", "#ff594a", "#ff802c", "#ffa600"],
["#2737a3", "#262f6b", "#1b998b", "#59c3c3", "#84dcc6", "#540d6e", "#fcd0a1", "#3e4e50"],
["#5899da", "#e8743b", "#19a979", "#ed4a7b", "#945ecf", "#13a4b4", "#525df4", "#bf399e", "#6c8893", "#ee6868", "#2f6497"],
["#673AB7", "#9C27B0", "#E91E63", "#F44336", "#3F51B5", "#2196F3", "#03A9F4", "#00BCD4", "#009688", "#4CAF50", "#8BC34A", "#CDDC39", "#FFEB3B", "#FFC107", "#FF9800", "#FF5722", "#795548", "#9E9E9E", "#607D8B"],
["#053061", "#2166ac", "#4393c3", "#92c5de", "#d1e5f0", "#f7f7f7", "#fddbc7", "#f4a582", "#d6604d", "#b2182b", "#67001f"],
["#2e2473", "#b591d9", "#7341a6", "#924ebf", "#4d0a8c", "#b492d3", "#8b08b1", "#954fd5"],
["#2837a3", "#bbc3fe", "#657bec", "#ffacec", "#c20da6", "#fe74fe", "#533f5e", "#ba689e", "#7c225f", "#803ef3"],
["#2837a3", "#79acfd", "#10558a", "#14bae1", "#7574f5", "#8711ac", "#ffa8ff", "#69345e", "#fb5de7", "#af6da7", "#3f16f9"],
["#37007a", "#4d1a70", "#5e1f88", "#742796", "#973490", "#b8428c", "#db5087", "#e96a8d", "#ee8b97", "#f3aca2", "#f9cdac"],
["#032994", "#3554a9", "#4f6ab4", "#d92c09", "#e1563a", "#e46c53", "#41d7c4", "#67dfd0", "#7ae3d6", "#007eff", "#3398ff", "#4da5ff", "#e90b93", "#ed3ca9", "#f055b4", "#e99d0b", "#edb13c", "#f0bb55", "#c14cec", "#ce61f6", "#db82fc", "#a5cf24", "#afdb2a", "#bbeb29"],
["#0096d5", "#016797", "#a5bd03", "#80af15", "#63a422", "#4d7616", "#385c14", "#d50076", "#a90075", "#69b38e", "#477f66", "#1c5d40"],
["#000a8b", "#eb0029", "#f678c4", "#be955b", "#ff9100", "#cd8bda", "#f8d44c", "#00a39c", "#939393", "#1d1d1b", "#00c18b", "#00c0d6"],
["#0038a8", "#3a75c4", "#8c60c1", "#e50099", "#fc2366", "#ff7247", "#ffcc1e", "#ffed38", "#008772", "#00b59b", "#60dd49", "#dde00f"],
["#5572ee", "#d77df8", "#52d2db", "#f18c7f", "#e6e846", "#8aeb4a", "#2de394", "#f979a0", "#ebac3f", "#86d0ef", "#49b1dd", "#f17385"]
];
export var functions = {

    setLatestSyncConfigData: function (syncConfigData) {
        latestSyncConfigData = syncConfigData;
    },
    getLatestSyncConfigData: function () {
        return latestSyncConfigData;
    },
    setCSBchartdetails: function (chartnumber,tabInfo) {
        customStoryboardchartdetails[chartnumber]=tabInfo
    },
    getCSBchartdetails: function (chartnumber) {
        return customStoryboardchartdetails[chartnumber];
    },
    getAllCSBchartdetails: function () {
        return customStoryboardchartdetails;
    },
    deleteCSBChartDetails: function (chartnumber) {
        delete customStoryboardchartdetails[chartnumber];
       // delete customstroyboardReportColumns[chartnumber];
        console.log("deletd");
    },
    updateAllChartDetails: function (chartdetails) {
        chartDetails = {};
        chartDetails = chartdetails;
    },
    updateChartDetails: function (chartnumber, chartvalues) {
        chartDetails[chartnumber] = chartvalues;
    },
    getChartDetails: function (chartnumber) {
        return chartDetails[chartnumber];
    },
    setChartDetailsForLogReport: function (chartnumber, chartvalues) {
        chartDetailsForLog[chartnumber] = chartvalues;
    },
    getChartDetailsForLogReport: function (chartnumber) {
        return chartDetailsForLog[chartnumber];
    },
    setshredFilterArrayForDrillDown: function (shredFilterArray) {
        shredFilterArrayForDrillDown = shredFilterArray;
    },
    getshredFilterArrayForDrillDown: function () {
        return shredFilterArrayForDrillDown;
    },
    updateMLChartDetails: function (chartnumber, chartvalues) {
        mlChartDetails[chartnumber] = chartvalues;
    },
    getMLChartDetails: function (chartnumber) {
        return mlChartDetails[chartnumber];
    },
    updateTileLevelFilters:function(chartnumber,chartFilters){
        tileLevelFilters[chartnumber] = chartFilters;
    },
    getTileLevelFilters:function(chartnumber){
        return tileLevelFilters[chartnumber];
    },
    updateAllTileLevelFilters:function(allTilesFilters){
        tileLevelFilters = allTilesFilters;
    },
    getAllTileLevelFilters:function(){
        return tileLevelFilters;
    },
    updateDefaultSeriesDetails: function (chartnumber, seriescolors) {
        defaultSeriesColors[chartnumber] = seriescolors;
    },
    getDefaultSeriesDetails: function (chartnumber) {
        return defaultSeriesColors[chartnumber];
    },
    updateCustomSeriesColorsDetails: function (chartnumber, seriescolors) {
        customSeriesColors[chartnumber] = seriescolors;
    },
    getCustomSeriesColorsDetails: function (chartnumber) {
        return customSeriesColors[chartnumber];
    },
    getColorTheme: function (chartnumber) {
        return colorThemesArray[chartnumber];
    },
    getColorThemes: function () {
        return colorThemesArray;
    },
    setTopFiveKeyInfluencersForML: function (keyInfluencers) {
        topFiveKeyInfluencers = keyInfluencers;
    },
    getTopFiveKeyInfluencersForML: function () {
        return topFiveKeyInfluencers;
    },
    setAlerts: function (array) {
        alert = array;
    },
    getAlerts: function () {
        return alert;
    },
    setdrillfitlerArray: function (array) {
        drillfitlerArray = array;
    },
    getdrillfitlerArray: function () {
        return drillfitlerArray;
    },
    cleardrillfitlerArray: function () {
        drillfitlerArray = [];
    },
    getAllChartDetails: function () {
        return chartDetails;
    },
    getAllMLChartDetails: function () {
        return mlChartDetails;
    },
    setAllMLChartDetails: function (chartData) {
        mlChartDetails = chartData;
    },
    getMLModelDetails: function () {
        return mlModelDetails;
    },
    setMLModelDetails: function (modelData) {
        mlModelDetails = modelData;
    },
    setisFilterModalOpen: function (modal) {
        isFilterModalOpen = modal;
    },
    setreferenceLineValues: function (chartnumber, value) {
        referenceLineArray[chartnumber] = value;
    },
    getreferenceLineValues: function (chartnumber) {
        return referenceLineArray[chartnumber];
    },
    setListOfIndexesData: function (array) {
        ListOfIndexesmetaData = array;
    },
    getListOfIndexesData: function (array) {
        return ListOfIndexesmetaData;
    },
    setPurgeConfig: function (maxage, maxageunits, purgetime, maxpurgeindex) {
        purgeConfig["maxage"] = maxage;
        purgeConfig["maxageunits"] = maxageunits;
        purgeConfig["purgetime"] = purgetime;
        purgeConfig["maxpurgeindex"] = maxpurgeindex;
    },
    getPurgeConfig: function () {
        return purgeConfig;
    },
    setReportIds: function (reportIdsObject, tempIdsObject) {
        reportIds["reportIdsObject"] = reportIdsObject;
        reportIds["tempIdsObject"] = tempIdsObject;
    },
    getReportIds: function () {
        return reportIds;
    },
    getSharedFiltersForLiveReport: function () {
        return sharedFiltersForLiveReports;
    },
    setSharedFiltersForLiveReport: function (array) {
        sharedFiltersForLiveReports = array;
    },
    getisFilterModalOpen: function () {
        return isFilterModalOpen;
    },
    deleteChartDetails: function (chartnumber) {
        delete chartDetails[chartnumber];
        console.log("deletd");
    },
    setSummaryTest: function (chartno, text) {
        summaryText[chartno] = text;
    },
    getSummaryTest: function (chartno) {
        return summaryText[chartno];
    },
    setSummarytableAxisFieldsArray: function (array, chartnumber) {
        summarytableaxisfieldsArraydetails[chartnumber] = array;
    },
    getSummarytableAxisFieldsArray: function (chartnumber) {
        return summarytableaxisfieldsArraydetails[chartnumber];
    },
    setTempSummaryTableFilterValues: function (summarytablefilterArray, chartnumber) {
        summarytablefiltervalues[chartnumber] = summarytablefilterArray || [];
    },
    getTempSummaryTableFilterValues: function (chartnumber) {
        return summarytablefiltervalues[chartnumber];
    },
    getAllTempSummaryTableFilterValues: function () {
        var filters = [];
        var keys = Object.keys(summarytablefiltervalues);
        _.each(keys, function (index) {
            filters = filters.concat(summarytablefiltervalues[index]);
        });
        return filters;
    },
    setSummarytabledrilldownname: function (name) {
        summarytable_drilldownname = name;
    },
    getSummarytabledrilldownname: function () {
        return summarytable_drilldownname;
    },

    getSummarytabledrilldowndatatype: function () {
        return summarytable_drilldowndatatype;
    },
    setSummarytabledrilldowndatatype: function (value) {
        summarytable_drilldowndatatype = value;
    },

    setSummarytabledrilldowndisplayname: function (name) {
        summarytable_drilldowndisplayname = name;
    },
    getSummarytabledrilldownname: function () {
        return summarytable_drilldowndisplayname;
    },
    setChartComponents: function (chartnumber, data) {
        chartComponent[chartnumber] = data;
    },
    getChartComponents: function (chartnumber) {
        return chartComponent[chartnumber];
    },
    setCustomstroyboardReportColumns: function (chartnumber, reportColumns) {
        customstroyboardReportColumns[chartnumber] = reportColumns;
    },
    getCustomstroyboardReportColumns: function (chartnumber) {
        return customstroyboardReportColumns[chartnumber];
    },
    setCustomstroyboardMultifactObj: function (chartnumber, multifactObj) {
         customstroyboardMultifactObj[chartnumber] = multifactObj;
    },
    getCustomstroyboarddMultifactObj: function (chartnumber) {
        return customstroyboardMultifactObj[chartnumber];
    },
    setisversioned:function(isversioned){
        versionchart=isversioned;
    },
    getisversioned:function(){
        return versionchart;
    },
    setisPublished:function(publish){
        isPublished=publish;
    },
    getisPublished:function(){
        return isPublished;
    },
    setisPublishedOfDb:function(publishdb){
        isPublishedOfDb=publishdb;
    },
    getisPublishedOfDb:function(){
        return isPublishedOfDb;
    },
    setdropAndRecreateState:function(drop){
        dropAndRecreate=drop;
    },
    getdropAndRecreateState:function(){
        return dropAndRecreate;
    },
    setsearch:function(issearch){
        searchtext=issearch;
    },
    getsearch:function(){
        return searchtext;
    },
    isTrendSupport: function (chartType) {
        var trendNotSupportCharts = ["doby", "summarytable", "pivot", "heatmap", "metric", "pie", "donut", "scatter", "metric", "funnel", "pyramid", "wordcloud", "3dpie", "fullcirclegauge", "solidgauge", "srilankamap", "world", "leaflet", "countrymap","indiamap","semicircle", "boxplot", "textchart", "trellis", "bubble", "sankey", "combinationchart"];
        if (trendNotSupportCharts.indexOf(chartType) >= 0) {
            return false;
        }
        else {
            return true;
        }
    },
    resetChartDetails: function () {
        latestSyncConfigData = null;
        chartDetails = {};
        customhierarchy = [];
        custommeasurehierarchyArray = [];
        shredFilterArrayForDrillDown = [];
        chartComponent = {};
        range = [];
        filterArray = [];
        mValue = '';
        bValue = '';
        customStoryboardRefChartNo = null;
        isViewOnlyOptions = [];
        quadratic_aValue = "";
        quadratic_bValue = "";
        quadratic_cValue = "";
        polnomial_aValue = "";
        polnomial_bValue = "";
        polnomial_dValue = "";
        polnomial_eValue = "";
        polnomial_fValue = "";
        polnomial_gValue = "";
        isFilterModalOpen = false;
        custommeasurefield = [];
        customparamerfield = [];
        filterExcludeData = [];
        filterIncludeData = [];
        instanceFilterData = [];
        instanceFilterDataForDrillDown = [];
        instanceIncludeFilterData = [];
        customStoryBoardDetails = [];
        customStoryBoardFilterDetails = [];
        customStoryBoardChartDetails = [];
        customStoryboardchartdetails=[];
        sharedReportsSelectedColumns = [];
        alert = [];
        topFiveKeyInfluencers = [];
        filterArrays = [];
        shredFilterArrayForDrillDown = [];
        summaryText = {};
        summarytablefiltervalues = {};
        summarytableaxisfieldsArraydetails = {};
        livedatasettings = {};
        intervalvalueslist = {};
        listOfWebSockets = {};
        websocket_chartnumber_reportId = {};
        drillfitlerArray = [];
        userfilterArray = {
            "users": {},
            "groups": {}
        };
        appliedSharedReprotFilters = {
            "users": {},
            "groups": {}
        };
        timezoneObj = {};
        storyboardtimezoneObj = {};
        singlerepotimezoneObj = {};
        purgeConfig = {};
        embedfilterArray = [];
        customstroyboardReportColumns = [];
        customstroyboardMultifactObj = []
        customFieldsObjectReportedId = {};
        customMesureReportId = [];
        customHierarchyReportId = [];
        dateHistogramState = {};
        dateHistogramStatForML = {};
        customRangeReportId = [];
        aggCustomMeasureField = [];
        exclueValues = '';
        smartInsightsTargetVal = '';
        dateRangeObj = {};
        customFieldsFilterObject = {};
        sharedFilterObjectArray = {};
        mashupaddconnection = {};
        mashupnodes = [];
        mashupcolumns = {};
        editCustomQueryMashupcolumns = {};
        mashupjoinslist = [];
        oldCustomQueryEntities = [];
        mashupjoinnetwork = [];
        predictionResponseData = [];
        priorityColumnsArray = [];
        drillThroughReportsArray = [];
        exclueValues = '';
        smartInsightsTargetVal = '';
        isFiltersAdded = false;
        referenceLineArray = {};
        charttypeMap = {};
        duplicatecolumnsMap = {};
        mashupgroupcolors = {};
        xaxisDrillDownStateObj = {};
        drillThrough = [];
        summaryTableCustomHierarchyDrilldownName = "";
        userCustomScript = {};
        conditionalFormatArray = {};
        trendType = "";
        defaultSeriesColors = [];
        customSeriesColors = [];
        legendFilter = [];
        legendFilterAllChart = [];
        saveddrilldownAll = [];
        drillupMap = {};
        drillupMapCount = {};
        chartDetailsForLog = {};
        chartDetails = {};
        mlChartDetails = {};
        mlModelDetails = {};
        mlGridData = [];
        //	tempStoryBookDetails=[];
        mlSelectedColumns = [];
        smartinsightSelectedColumns = [];
        storyboardSmartInsightFilterArray = [];
        isStoryboardSmartInsight = false;
        dateHistogramStatus = [];
        dateHistogramStatusForML = [];
        this.drilldownChartNumber = "";
        mapForGetDuplicateName = "";
        summarytable_drilldowndatatype = "";
        reportArrayMapOfMissingCol = {};
        reportCorrelationData = {};
        topKeyInfluencers = {};
        topTwoNumericalKeyInfluencers = {};
        topTwoCategoricalKeyInfluencers = {};
        topLeastCatagoricalKeyInfluencers = {};
        dateSummaryTableHistogramState = {};
        usedFieldsInCustomFieldsForMashUp = [];
        selectedTablesColumnMapForMashup = {};
        existingEntitiesMetaData = [];
        dataModelJoinArray = [];
        meassurehierachyNamesMap = {}
        datefunctionsMap = {};
        smartinsightDataObj = {};
        importreportdatamodelId = "";
        mlChartComponent={};
        drillthroughcheckcolumnsArray=[];
        versionchart='N';
        isPublished='false';
        isPublishedOfDb='false';
        dropAndRecreate='false';
        searchtext='';
        isFilterAppliedForAutoML = false;
        datamodelMetaData = {};
        tileLevelFilters={};
        $.each(this.getAllFeatures(), function (index, module) {
            if (module.moduleName == 'my_report') {
                $.each(module.features, function (index, feature) {
                    if (feature.featureName == 'viewreport_maximize_data_table_export' && feature.allow == true) {
                        sessionStorage.setItem('isDataTableDataExport', true);
                    } else if (feature.featureName == 'viewreport_maximize_data_table_export' && feature.allow == false) {
                        sessionStorage.setItem('isDataTableDataExport', false);
                    }
                });
            }
        });
    },
    setReportArrayMapOfMissingCol: function (reportId, colarray) {
        reportArrayMapOfMissingCol[reportId] = colarray;
    },
    getReportArrayMapOfMissingCol: function (reportId) {
        return reportArrayMapOfMissingCol[reportId];
    },
    setChartComponents: function (chartnumber, data) {
        chartComponent[chartnumber] = data;
    },
    getChartComponents: function (chartnumber) {
        return chartComponent[chartnumber];
    },
    setReportCorrelationData: function (reportId, colarray) {
        reportCorrelationData[reportId] = colarray;
    },
    getReportCorrelationData: function (reportId) {
        return reportCorrelationData[reportId];
    },
    setTopKeyInfluencers: function (reportId, colarray) {
        topKeyInfluencers[reportId] = colarray;
    },
    getTopKeyInfluencers: function (reportId) {
        return topKeyInfluencers[reportId];
    },
    setTopTwoCategoricalKeyInfluencers: function (reportId, colarray) {
        topTwoCategoricalKeyInfluencers[reportId] = colarray;
    },
    getTopTwoCategoricalKeyInfluencers: function (reportId) {
        return topTwoCategoricalKeyInfluencers[reportId];
    },
    setTopTwoNumericalKeyInfluencers: function (reportId, colarray) {
        topTwoNumericalKeyInfluencers[reportId] = colarray;
    },
    getTopTwoNumericalKeyInfluencers: function (reportId) {
        return topTwoNumericalKeyInfluencers[reportId];
    },
    setTopLeastCatagoricalKeyInfluencers: function (reportId, colarray) {
        topLeastCatagoricalKeyInfluencers[reportId] = colarray;
    },
    getTopLeastCatagoricalKeyInfluencers: function (reportId) {
        return topLeastCatagoricalKeyInfluencers[reportId];
    },
    setDataSetCount: function (count) {
        dataSetCount = count;
    },
    getDataSetCount: function () {
        return dataSetCount;
    },
    setCsbRefChartNum: function (chartNo) {
        customStoryboardRefChartNo = chartNo;
    },
    getCsbRefChartNum: function () {
        return customStoryboardRefChartNo;
    },

    setFilterDataCount: function (count) {
        filterDataCount = count;
    },
    setIntervalValue: function (chartnumber, value) {
        intervalvalueslist[chartnumber] = value;
    },
    getIntervalValue: function (chartnumber) {
        return intervalvalueslist[chartnumber];
    },
    getAllIntervalValue: function () {
        return intervalvalueslist;
    },
    setliveDataconfig: function (chartnumber, configObj) {
        livedatasettings[chartnumber] = configObj;
    },
    getliveDataconfig: function () {
        return livedatasettings;
    },
    setWebSocketForReportID: function (reportId, websocket) {
        listOfWebSockets[reportId] = websocket;
    },
    getWebSocketForReportID: function (reportId) {
        return listOfWebSockets[reportId];
    },
    setwebsocketwithchartnumber: function (reportId, chartnumber) {
        if (chartnumber == null) {
            delete websocket_chartnumber_reportId[reportId];
        } else if (websocket_chartnumber_reportId[reportId] == undefined) {
            websocket_chartnumber_reportId[reportId] = [chartnumber];
        } else {
            websocket_chartnumber_reportId[reportId].push(chartnumber);
        }
    },
    getwebsocketwithchartnumber: function (reportId) {
        return websocket_chartnumber_reportId[reportId];
    },

    getListOfReportIdsInWebSocket: function (reportId) {
        return listOfWebSockets;
    },
    setTimeZoneSettings: function (chartNumber, value) {
        timezoneObj[chartNumber] = value;
    },
    getTimeZoneSettings: function (chartNumber) {
        return timezoneObj[chartNumber];
    },
    setStoryboardSaveTimeZoneSettings: function (chartNumber, value) {
        storyboardtimezoneObj[chartNumber] = value;
    },
    getStoryBoardSaveTimeZoneSettings: function (chartNumber) {
        return storyboardtimezoneObj[chartNumber];
    },
    setSingleReportSaveTimeZoneSettings: function (chartNumber, value) {
        singlerepotimezoneObj[chartNumber] = value;
    },
    getSingleReportSaveTimeZoneSettings: function (chartNumber) {
        return singlerepotimezoneObj[chartNumber];
    },
    setAuditMetaDataOptions: function (value) {
        auditMetaDataObj = value;
    },
    getAuditMetaDataOptions: function () {
        return auditMetaDataObj;
    },
    getListofTimeZoneSettings: function (chartNumber) {
        return timezoneObj;
    },
    getFilterDataCount: function () {
        return filterDataCount;
    },
    setMultixAxisCategorie: function (dataArray) {
        multixAxiscategorie = dataArray
    },
    getMultixAxisCategorie: function () {
        return multixAxiscategorie;
    },
    setDateHistogramXaxisData: function (dateHistogramXaxisData1) {
        dateHistogramXaxisData = dateHistogramXaxisData1;
    },
    getDateHistogramXaxisData: function () {
        return dateHistogramXaxisData;
    },
    setDateHistogramXaxisDataForML: function (dateHistogramXaxisData) {
        dateHistogramXaxisDataForML = dateHistogramXaxisData;
    },
    getDateHistogramXaxisDataForML: function () {
        return dateHistogramXaxisDataForML;
    },
    setFiltersForDataBehindChart: function (chartnumber, chartvalues) {
        filtersForDataBehindChart[chartnumber] = chartvalues;
    },
    getFiltersForDataBehindChart: function (chartnumber) {
        return filtersForDataBehindChart[chartnumber];
    },
    setSummaryTableDateHistogramStatus: function (chartnumber, value) {
        summaryTableDateHistogramStatus[chartnumber] = value;
    },
    getSummaryTableDateHistogramStatus: function (chartnumber) {
        return summaryTableDateHistogramStatus[chartnumber];
    },
    setDateHistogramStatus: function (chartnumber, value) {
        dateHistogramStatus[chartnumber] = value;
    },
    getDateHistogramStatus: function (chartnumber) {
        return dateHistogramStatus[chartnumber];
    },
    setDateHistogramStatusForML: function (chartnumber, value) {
        dateHistogramStatusForML[chartnumber] = value;
    },
    getDateHistogramStatusForML: function (chartnumber) {
        return dateHistogramStatusForML[chartnumber];
    },
    setCustomStoryBoardDetails: function (chartnumber, value) {
        customStoryBoardDetails[chartnumber] = value;
    },
    getCustomStoryBoardDetails: function (chartnumber) {
        return customStoryBoardDetails[chartnumber];
    },
    setCustomStoryBoardChartDetails: function (chartnumber) {
        customStoryBoardChartDetails = chartnumber;
    },
    getCustomStoryBoardChartDetails: function () {
        return customStoryBoardChartDetails;
    },
    setCustomStoryBoardFilterDetails: function (reportid, values) {
        customStoryBoardFilterDetails[reportid] = values;
    },
    getCustomStoryBoardFilterDetails: function (reportid) {
        return customStoryBoardFilterDetails[reportid];
    },
    setTime: function (datetime_ms) {
        dateTime_ms = datetime_ms;
    },
    getTime: function () {
        return dateTime_ms;
    },
    setMeasureHierarchy: function (array) {
        custommeasurehierarchyArray = array;
    },
    getMeasureHierarchy: function () {
        return custommeasurehierarchyArray;
    },
    setcustomhierarchy: function (jsonarray) {
        customhierarchy = jsonarray
    },
    getcustomhierarchy: function () {
        return customhierarchy;
    },
    setRange: function (jsonarray) {
        range = jsonarray
    },
    getRange: function () {
        return range;
    },
    setCustomMeasureField: function (jsonobj) {
        custommeasurefield = jsonobj;
    },
    getCustomMeasureField: function () {
        return custommeasurefield;
    },
    setCustomParameter: function (jsonobj) {
        customparamerfield = jsonobj;
    },
    getCustomParameter: function () {
        return customparamerfield;
    },
    setMvalue: function (mvalue) {
        mValue = mvalue;
    },
    getMvalue: function () {
        return mValue;
    },
    setBvalue: function (bvalue) {
        bValue = bvalue;
    },
    setquadratic_aValue: function (value) {
        quadratic_aValue = value
    },
    getquadratic_aValue: function () {
        return quadratic_aValue;
    },
    setquadratic_bValue: function (value) {
        quadratic_bValue = value;
    },
    getquadratic_bValue: function () {
        return quadratic_bValue;
    },
    setquadratic_cValue: function (value) {
        quadratic_cValue = value
    },
    getquadratic_cValue: function () {
        return quadratic_cValue;
    },
    setpolnomial_aValue: function (value) {
        polnomial_aValue = value
    },
    getpolnomial_aValue: function () {
        return polnomial_aValue;
    },
    setpolnomial_bValue: function (value) {
        polnomial_bValue = value
    },
    getpolnomial_bValue: function () {
        return polnomial_bValue;
    },
    setpolnomial_dValue: function (value) {
        polnomial_dValue = value
    },
    getpolnomial_dValue: function () {
        return polnomial_dValue;
    },
    setpolnomial_eValue: function (value) {
        polnomial_eValue = value
    },
    getpolnomial_eValue: function () {
        return polnomial_eValue;
    },
    setpolnomial_fValue: function (value) {
        polnomial_fValue = value
    },
    getpolnomial_fValue: function () {
        return polnomial_fValue;
    },
    setpolnomial_gValue: function (value) {
        polnomial_gValue = value
    },
    getpolnomial_gValue: function () {
        return polnomial_gValue;
    },
    getBvalue: function () {
        return bValue;
    },
    setExcludeFilterData: function (data) {
        filterExcludeData = data
    },
    getExcludeFilterData: function () {
        return filterExcludeData;
    },
    setInstanceFilterData: function (data) {
        instanceFilterData = data
    },
    getInstanceFilterData: function () {
        return instanceFilterData;
    },
    setIncludeFilterData: function (data) {
        filterIncludeData = data
    },
    getIncludeFilterData: function () {
        return filterIncludeData;
    },
    setInstanceIncludeFilterData: function (data) {
        instanceIncludeFilterData = data
    },
    getInstanceIncludeFilterData: function () {
        return instanceIncludeFilterData;
    },
    setInstanceIncludeFilterDataForDrillDown: function (chartnumber, data) {
        instanceFilterDataForDrillDown[chartnumber] = data
    },
    getInstanceIncludeFilterDataForDrillDown: function (chartnumber) {
        return instanceFilterDataForDrillDown[chartnumber];
    },
    setDrillDownInstantFilterData: function (chartnumber, data) {
        drillDownInstantFilterData[chartnumber] = data
    },
    getDrillDownInstantFilterData: function (chartnumber) {
        return drillDownInstantFilterData[chartnumber];
    },
    setDrillDownInstantIncludeFilterData: function (chartnumber, data) {
        drillDownInstantInlcudeFilterData[chartnumber] = data
    },
    getDrillDownInstantIncludeFilterData: function (chartnumber) {
        return drillDownInstantInlcudeFilterData[chartnumber];
    },
    setSelectedColumnsForSharedReports: function (data) {
        sharedReportsSelectedColumns = data;
    },
    getSelectedColumnsForSharedReports: function (data) {
        return sharedReportsSelectedColumns;
    },
    setisViewOnlyOptions: function (data) {
        isViewOnlyOptions = data;
    },
    getisViewOnlyOptions: function () {
        return isViewOnlyOptions;
    },
    setisViewOnlyFlag: function (data) {
        isViewOnlyFlag = data;
    },
    getisViewOnlyFlag: function () {
        return isViewOnlyFlag;
    },
    setUserRole: function (role) {
        userParentRole = role;
    },
    getUserRole: function () {
        return userParentRole;
    },
    setIsDirect: function (value) {
        isDirect = value;
    },
    getIsDirect: function () {
        return (isDirect == undefined ? "0" : isDirect);
    },
    setIsStoredProcedure: function (value) {
        isStoredProcedure = value;
    },
    getIsStoredProcedure: function () {
        return isStoredProcedure;
    },
    getMeasureHierarchyDetails: function (drilldownType, chartnumber, pointName) {
        if (drilldownType != "measuredrilldown") {
            return null;
        }
        else {
            var chartDetails = JSON.parse(this.getChartDetails(chartnumber));
            var xAxisName = chartDetails["xAxis"];//[0]["columnName"];
            if (xAxisName != undefined && xAxisName.length > 0) {
                return null;
            }
            else {
                var measureHierarchy = this.getMeasureHierarchy();
                var matchedMeasureHierarchy = $.grep(measureHierarchy, function (e) {
                    return e.name == pointName.trim();
                });
                var mathcedMeasureHierarchyList = matchedMeasureHierarchy[0]["hierarchelist"];
                var finalList = [];
                for (var i = 1; i < mathcedMeasureHierarchyList.length; i++) {
                    finalList.push(mathcedMeasureHierarchyList[i]);
                }
                return finalList;
            }
        }
    },
    getMeasureHierarchyDetailsCSB: function (drilldownType, chartnumber, pointName,reportid) {
        if (drilldownType != "measuredrilldown") {
            return null;
        }
        else {
            var chartDetails = JSON.parse(this.getChartDetails(chartnumber));
            var xAxisName = chartDetails["xAxis"];//[0]["columnName"];
            if (xAxisName != undefined && xAxisName.length > 0) {
                return null;
            }
            else {
                var measureHierarchy = JSON.parse(this.getMeasureReportId(chartDetails.reportid));
                var matchedMeasureHierarchy = $.grep(measureHierarchy, function (e) {
                    return e.name == pointName.trim();
                });
                var mathcedMeasureHierarchyList = matchedMeasureHierarchy[0]["hierarchelist"];
                var finalList = [];
                for (var i = 1; i < mathcedMeasureHierarchyList.length; i++) {
                    finalList.push(mathcedMeasureHierarchyList[i]);
                }
                return finalList;
            }
        }
    },
    setFilterArray: function (array) {
        filterArrays = array;
    },
    getFilterArray: function () {
        return filterArrays;
    },
    setshredFilterArrayForDrillDown: function (shredFilterArray) {
        shredFilterArrayForDrillDown = shredFilterArray;
    },
    getshredFilterArrayForDrillDown: function () {
        return shredFilterArrayForDrillDown;
    },

    /*		finddrilldowninfilter:function(drillDown, value){
            var temp5=true;	
            _.each(drillDown,function(i,j){
                if(drillDown[j].isDrillDownAll===false){
                if(drillDown[j].displayName==value)
                    temp5= false;
                }else{
                if(drillDown[j].columndisplayname==value)
                    temp5= false;
                }
            });
            return temp5;
        },
        updatedFilterArray:function(globalfilterArray,mainFilter){
            var arrayToReturn=[];
        var that =this;
            if((globalfilterArray!=undefined && globalfilterArray.length>0)&&(mainFilter!=undefined && mainFilter.length>0)){
                _.each(mainFilter,function(i,j){
                    if(that.finddrilldowninfilter(globalfilterArray,mainFilter[j].columndisplayname)){
                        arrayToReturn.push(mainFilter[j]);
                    }
                });
            }else{
                arrayToReturn=arrayToReturn.concat(mainFilter);	
            }
        arrayToReturn=JSON.parse(JSON.stringify(arrayToReturn)).concat(globalfilterArray);	
            return arrayToReturn;
        },*/
    setEmbedFilterArray: function (array) {
        embedfilterArray = array;
    },
    getEmbedFilterArray: function () {
        return embedfilterArray;
    },
    setUserFilterArray: function (array) {
        userfilterArray = array;
    },
    getUserFilterArray: function () {
        return userfilterArray;
    },
    setAppliedSharedFilterArray: function (array) {
        appliedSharedReprotFilters = array;
    },
    getAppliedSharedFilterArray: function () {
        return appliedSharedReprotFilters;
    },
    setEmbedFilterArray: function (array) {
        embedfilterArray = array;
    },
    getEmbedFilterArray: function () {
        return embedfilterArray;
    },
    getFilters: function (isIncludeExcludeEnabled, requestedFrom, fromEdit) {
        //To Get String Filter
        var StringClassName = "#filteraccordion .filtervalue";
        var NumberClassName = "#filteraccordion .filterrangevalues";
        var DateClassName = "#filteraccordion .daterange-center";
        if (requestedFrom == "advancedSharedReport") {
            StringClassName = ".advanced-access-filtervalue";
            NumberClassName = ".advanced-access-filterrangevalues";
            DateClassName = ".advanced-access-daterange-center";
        }else if(requestedFrom == "tilefilterslist"){
            StringClassName  = "#tilefilterslist .filtervalue";
            NumberClassName = "#tilefilterslist .filterrangevalues";
            DateClassName = "#tilefilterslist .daterange-center";
       }else if(requestedFrom == "save"){
            var filterOrder = [];
            $("#storyboardtab .tab-filters-container #filteraccordion [classname]").each(function(index,a){
                var name = $(a).find('.acc-content').attr("data-id");
                filterOrder.push(name);
            });
            if($(".singlereportview.fullscreenmain.maxanim1").length>0){
                $(".tab-filters-container #filteraccordion [classname]").each(function(index,a){
                    var name = $(a).find('.acc-content').attr("data-id");
                    filterOrder.push(name);
                });
            }
       }
        var filterArray = [];
        var includeArray = [];
        if (isIncludeExcludeEnabled) {
            var instantExcludeCustomFilters = JSON.parse(sessionStorage.getItem("instantExcludeCustomFilters"));
            var InstanceExcludeFilterData = JSON.parse(sessionStorage.getItem("instantExcludeFilters"));
            if (isDirect == 1) {
                if (InstanceExcludeFilterData != null && InstanceExcludeFilterData != "null") {
                    _.each(InstanceExcludeFilterData, function (data) {
                        filterArray.push(data);
                    });
                }
            } else {
                if (InstanceExcludeFilterData != null && InstanceExcludeFilterData != "null") {
                    _.each(InstanceExcludeFilterData, function (data) {
                        filterArray.push(data);
                    });
                }
            }
            var InstanceIncludeFilterData = JSON.parse(sessionStorage.getItem("instantIncludeFilters"));
            if (InstanceIncludeFilterData != null && InstanceIncludeFilterData != "null") {
                _.each(InstanceIncludeFilterData, function (data) {
                    filterArray.push(data);
                    includeArray.push(data.columndisplayname);
                });
            }
            if (instantExcludeCustomFilters != null && instantExcludeCustomFilters != "null") {
                _.each(instantExcludeCustomFilters, function (data, i) {
                    if (data != undefined) {
                        filterArray.push(data);
                        includeArray.push(data.name);
                    }
                })
            }
        }
        var filtersKeyValueMap = {};
        var filtersCount = 0;
        $(StringClassName+":checked").each(function () {
            var $this = this;
            var filterObject = {};

            if ($($this).is(":checked")) {
                var columnName = $($this).attr("data-child");
                var isCustomField = false;
                var customFieldIndex = 0;
                var customFieldsArray = sessionStorage.getItem("customFields");
                var temp = JSON.parse(customFieldsArray);
                var res;
                _.each(temp, function (customfields) {
                    _.each(customfields.parametrFieldsArray, function (value) {
                        res = $.grep(customparamerfield, function (e) {
                            return e.parametername == value;
                        })
                        var formula = customfields.formula;
                        var scriptcondition = customfields.scriptcondition;
                        var fields = customfields.fields;
                        var sqlformula = customfields.sqlformula;
                        if (res.length != 0) {
                            customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
                            customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
                            customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
                            customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
                        } else {
                            _.each(customparamerfield, function (values) {
                                if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
                                    customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                    customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                    customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
                                    customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                }
                            });
                        }
                    });
                });
                customFieldsArray = JSON.stringify(temp);
                var customFieldsParsedArray = [];
                if (customFieldsArray != "null" && customFieldsArray != undefined) {
                    customFieldsParsedArray = JSON.parse(customFieldsArray);
                }
                $.each(customFieldsParsedArray, function (index, customField) {
                    if (customField.name == columnName) {
                        isCustomField = true;
                        customFieldIndex = index;
                    }
                });
                if (isCustomField == false) {
                    var myObjects = Object.keys(filtersKeyValueMap).map(function (itm) { return filtersKeyValueMap[itm]; });
                    if (filtersKeyValueMap != undefined && myObjects != undefined
                        && filtersKeyValueMap[$($this).attr("data-child")] != undefined && Object.keys(filtersKeyValueMap[$($this).attr("data-child")]).length > 0) {
                        filtersKeyValueMap[$($this).attr("data-child")]["data"] += "@@" + $($this).parent().text();
                    } else {
                        filterObject["dataType"] = $($this).attr("data-type");
                        filterObject["tablename"] = $($this).attr("data-parent");
                        filterObject["columndisplayname"] = $($this).attr("displayname");
                        filterObject["columnname"] = $($this).attr("data-child");
                        filterObject["filterDisplayName"] = $("input.filter-display-name[data-name='" + $($this).attr("data-name").replace(/([ ;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "") + "']").val();
                        filterObject["filterIgnore"] = $($this).closest('.acc-content').find(".filter-toggle").hasClass("filter-off");
                        filterObject["data"] = $($this).parent().text();
                        filterObject["reportfiltersid"] = filtersCount + 1;
                        if (filterObject["filterDisplayName"] == "") {
                            $("input.filter-display-name[data-name='" + $($this).attr("data-name") + "']").val($($this).attr("data-name"));
                        }
                        if (includeArray.indexOf($($this).attr("data-name")) < 0) {
                            filtersKeyValueMap[$($this).attr("data-child")] = filterObject;
                        }
                    }
                }
            }
        });
        if (filtersKeyValueMap != undefined && this.getValues(filtersKeyValueMap) != undefined && this.getValues(filtersKeyValueMap).length > 0) {
            var myObjects = Object.keys(filtersKeyValueMap).map(function (itm) { return filtersKeyValueMap[itm]; });
            filterArray = filterArray.concat(myObjects);
        }
        var filterObjectArray = [];
        $(StringClassName+":checked").each(function () {
            var $this = this;
            if ($($this).is(":checked")) {
                var columnName = $($this).attr("data-child");
                var isCustomField = false;
                var customFieldIndex = 0;
                var customFieldsArray = sessionStorage.getItem("customFields");
                var temp = JSON.parse(customFieldsArray);
                var res;
                _.each(temp, function (customfields) {
                    _.each(customfields.parametrFieldsArray, function (value) {
                        res = $.grep(customparamerfield, function (e) {
                            return e.parametername == value;
                        })
                        var formula = customfields.formula;
                        var scriptcondition = customfields.scriptcondition;
                        var fields = customfields.fields;
                        var sqlformula = customfields.sqlformula;
                        if (res.length != 0) {
                            customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
                            customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
                            customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
                            customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
                        } else {
                            _.each(customparamerfield, function (values) {
                                if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
                                    customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                    customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                    customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
                                    customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                }
                            });
                        }
                    });
                });
                customFieldsArray = JSON.stringify(temp);
                var customFieldsParsedArray = [];
                if (customFieldsArray != "null" && customFieldsArray != undefined) {
                    customFieldsParsedArray = JSON.parse(customFieldsArray);
                }
                $.each(customFieldsParsedArray, function (index, customField) {
                    if (customField.name == columnName) {
                        isCustomField = true;
                        customFieldIndex = index;
                    }
                });
                if (isCustomField == true) {
                    if (filterObjectArray.length > 0) {
                        var isNewField = true;
                        $.each(filterObjectArray, function (index, filterObject) {
                            if (filterObject["columnName"] == columnName) {
                                isNewField = false;
                                var dataArray = filterObject["dataArray"];
                                dataArray.push($($this).parent().text());
                                filterObject["filterIgnore"] = $($this).closest('.acc-content').find(".filter-toggle").hasClass("filter-off");
                                filterObject["dataArray"] = dataArray;
                                filterObjectArray.slice(index, filterObject);
                            }
                        });
                        if (isNewField) {
                            var jsonObject = { "columnName": columnName, "filterIgnore": $($this).closest('.acc-content').find(".filter-toggle").hasClass("filter-off"), "dataArray": [$($this).parent().text()] };
                            filterObjectArray.push(jsonObject);
                        }
                    } else {
                        var jsonObject = { "columnName": columnName, "filterIgnore": $($this).closest('.acc-content').find(".filter-toggle").hasClass("filter-off"), "dataArray": [$($this).parent().text()] };
                        filterObjectArray.push(jsonObject);
                    }

                }
            }
        });

        var customFieldsArray = sessionStorage.getItem("customFields");
        var temp = JSON.parse(customFieldsArray);
        var res;
        _.each(temp, function (customfields) {
            _.each(customfields.parametrFieldsArray, function (value) {
                res = $.grep(customparamerfield, function (e) {
                    return e.parametername == value;
                })
                var formula = customfields.formula;
                var scriptcondition = customfields.scriptcondition;
                var fields = customfields.fields;
                var sqlformula = customfields.sqlformula;
                if (res.length != 0) {
                    customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
                    customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
                    customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
                    customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
                } else {
                    _.each(customparamerfield, function (values) {
                        if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
                            customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                            customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                            customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
                            customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                        }
                    });
                }
            });
        });
        customFieldsArray = JSON.stringify(temp);
        var customFieldsParsedArray = [];
        if (customFieldsArray != null && customFieldsArray != "null" && customFieldsArray != undefined) {
            customFieldsParsedArray = JSON.parse(customFieldsArray);
        }

        $.each(filterObjectArray, function (index, filterActualObject) {
            $.each(customFieldsParsedArray, function (index1, customField) {
                var filterObject = {};
                if (filterActualObject["columnName"] == customField["name"]) {
                    filterObject["dataType"] = "string";
                    filterObject["filterIgnore"] = filterActualObject["filterIgnore"];
                    filterObject["tablename"] = customField["name"];
                    filterObject["columndisplayname"] = customField["name"];
                    filterObject["columnname"] = customField["name"];
                    var sqlFilterObject = {};
                    var scriptCondition = customField["scriptcondition"];
                    var finalScriptCondition = ";" + "if(";
                    var dataArray = filterActualObject["dataArray"];
                    $.each(dataArray, function (index2, data) {
                        if (index2 == 0) {
                            finalScriptCondition += "(" + scriptCondition + ").equals('" + data.replace("'", "\\'") + "')";
                        } else {
                            finalScriptCondition += " || " + "(" + scriptCondition + ").equals('" + data.replace("'", "\\'") + "')";
                        }
                        if (sqlFilterObject["sqldata"] != undefined) {
                            sqlFilterObject["sqldata"] += data + "@@";
                        }
                        else {
                            sqlFilterObject["sqldata"] = data + "@@";
                        }

                    });
                    finalScriptCondition += ") return true else false";
                    customField["scriptcondition"] = finalScriptCondition;
                    var data = sqlFilterObject.sqldata;
                    data = data.substring(0, data.length - 2);
                    sqlFilterObject["sqldata"] = data;
                    customField["sqlcondition"] = sqlFilterObject;
                    filterObject["customfield"] = customField;
                    if (includeArray.indexOf(customField["name"]) < 0) {
                        filterArray.push(filterObject);
                    }
                }
            });
        });

        // To Get Number Filter
        $(NumberClassName).each(function () {
            var filterObject = {};
            var $this = this;
            var th = $($this);
            var temp = [];
            temp = sessionStorage.applicablefilters.split(',');
            filterObject["filterDisplayName"] = $($this).find(".filter-display-name").val();
            filterObject["filterIgnore"] = $($this).find(".filter-toggle").hasClass("filter-off");
            var displayname = $($($this).find("#numberrangeslider input")).attr("displayname").replace(/([#;?&,%.+*~\':"!^$%[\]()<=>|\/@])/g, "");
            var filterlessthanvalue = $(NumberClassName + "[data-id='acc" + displayname + "']" + " .intfiltervalue .filterlessthanvalue").val();
            var filtergreaterthanvalue = $(NumberClassName + "[data-id='acc" + displayname + "']" + " .intfiltervalue .filtergreaterthanvalue").val();
            var equalvalue = $(NumberClassName + "[data-id='acc" + displayname + "']" + " .intfiltervalue .filterequalsvalue").val();
            var invalue = $(NumberClassName + "[data-id='acc" + displayname + "']" + " .intfiltervalue .filterinvalue").val();
            var columnName = $($($this).find("#numberrangeslider input")).attr("data-child");
            var isCustomField = false;
            var customFieldIndex = 0;
            var customFieldsArray = sessionStorage.getItem("customFields");
            var temp1 = JSON.parse(customFieldsArray);
            if (equalvalue != "" && equalvalue != undefined) {
                filterlessthanvalue = equalvalue;
                filtergreaterthanvalue = equalvalue;
            }
            var res;
            _.each(temp1, function (customfields) {
                _.each(customfields.parametrFieldsArray, function (value) {
                    res = $.grep(customparamerfield, function (e) {
                        return e.parametername == value;
                    })
                    var formula = customfields.formula;
                    var scriptcondition = customfields.scriptcondition;
                    var fields = customfields.fields;
                    var sqlformula = customfields.sqlformula;
                    if (res.length != 0) {
                        customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
                        customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
                        customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
                        customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
                    } else {
                        _.each(customparamerfield, function (values) {
                            if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
                                customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
                                customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                            }
                        });
                    }
                });
            });
            customFieldsArray = JSON.stringify(temp1);
            var customFieldsParsedArray = [];
            if (customFieldsArray != "null" && customFieldsArray != undefined) {
                customFieldsParsedArray = JSON.parse(customFieldsArray);
            }
            $.each(customFieldsParsedArray, function (index, customField) {
                if (customField.name == columnName) {
                    isCustomField = true;
                    customFieldIndex = index;
                }
            });
            if (isCustomField == false) {
                if ((filtergreaterthanvalue != "" || filterlessthanvalue != "") && (invalue == "" || invalue == undefined)) {
                    filterObject["dataType"] = $($($this).find("#numberrangeslider input")).attr("data-type");
                    filterObject["tablename"] = $($($this).find("#numberrangeslider input")).attr("data-parent");
                    filterObject["columndisplayname"] = $($($this).find("#numberrangeslider input")).attr("displayname");
                    filterObject["columnname"] = $($($this).find("#numberrangeslider input")).attr("data-child");

                    var fromRange = $(NumberClassName + "[data-id='acc" + displayname + "']" + " #numberrangeslider").data('ionRangeSlider').options.min;
                    var toRange = $(NumberClassName + "[data-id='acc" + displayname + "']" + " #numberrangeslider").data('ionRangeSlider').options.max;
                    if (filtergreaterthanvalue == "") {
                        filterObject["filtergreaterthanvalue"] = $(NumberClassName + "[data-id='acc" + displayname + "']" + " #numberrangeslider").data('ionRangeSlider').options.min;
                        filterObject["filterlessthanvalue"] = parseFloat($(NumberClassName + "[data-id='acc" + displayname + "']" + " .intfiltervalue .filterlessthanvalue").val());
                    } else if (filterlessthanvalue == "") {
                        filterObject["filtergreaterthanvalue"] = parseFloat($(NumberClassName + "[data-id='acc" + displayname + "']" + " .intfiltervalue .filtergreaterthanvalue").val());
                        filterObject["filterlessthanvalue"] = $(NumberClassName + "[data-id='acc" + displayname + "']" + " #numberrangeslider").data('ionRangeSlider').options.max;
                    } else if ((filtergreaterthanvalue != "" || filterlessthanvalue != "") && filtergreaterthanvalue < fromRange && filterlessthanvalue > toRange) {
                        filterObject["filtergreaterthanvalue"] = $(NumberClassName + "[data-id='acc" + displayname + "']" + " #numberrangeslider").data('ionRangeSlider').options.min;
                        filterObject["filterlessthanvalue"] = $(NumberClassName + "[data-id='acc" + displayname + "']" + " #numberrangeslider").data('ionRangeSlider').options.max;
                        /* filterObject["filtergreaterthanvalue"]= parseFloat($(NumberClassName+"[data-id='acc"+displayname +"']"+" .intfiltervalue .filtergreaterthanvalue").val());
                         filterObject["filterlessthanvalue"] =parseFloat($(NumberClassName+"[data-id='acc"+displayname +"']"+" .intfiltervalue .filterlessthanvalue").val());*/
                    } else if ((filtergreaterthanvalue != "" || filterlessthanvalue != "") && filtergreaterthanvalue < fromRange && filterlessthanvalue <= toRange) {
                        filterObject["filtergreaterthanvalue"] = $(NumberClassName + "[data-id='acc" + displayname + "']" + " #numberrangeslider").data('ionRangeSlider').options.min;
                        filterObject["filterlessthanvalue"] = parseFloat($(NumberClassName + "[data-id='acc" + displayname + "']" + " .intfiltervalue .filterlessthanvalue").val());
                    } else if ((filtergreaterthanvalue != "" || filterlessthanvalue != "") && filtergreaterthanvalue > fromRange && filterlessthanvalue > toRange) {
                        filterObject["filtergreaterthanvalue"] = parseFloat($(NumberClassName + "[data-id='acc" + displayname + "']" + " .intfiltervalue .filtergreaterthanvalue").val());
                        filterObject["filterlessthanvalue"] = $(NumberClassName + "[data-id='acc" + displayname + "']" + " #numberrangeslider").data('ionRangeSlider').options.max;
                    } else if ((filtergreaterthanvalue != "" || filterlessthanvalue != "") && filtergreaterthanvalue >= fromRange && filterlessthanvalue <= toRange) {
                        filterObject["filtergreaterthanvalue"] = parseFloat($(NumberClassName + "[data-id='acc" + displayname + "']" + " .intfiltervalue .filtergreaterthanvalue").val());
                        filterObject["filterlessthanvalue"] = parseFloat($(NumberClassName + "[data-id='acc" + displayname + "']" + " .intfiltervalue .filterlessthanvalue").val());
                    }
                    if (equalvalue != "" && equalvalue != undefined) {
                        filterObject["filtergreaterthanvalue"] = parseFloat(equalvalue);
                        filterObject["filterlessthanvalue"] = parseFloat(equalvalue);
                        filterObject["equalvalue"] = parseFloat(equalvalue);
                    }
                    filterObject["customfiltervalue"] = $($($this).find("input[type='text']")).val();
                    if (includeArray.indexOf($($($this).find("#numberrangeslider input")).attr("data-name")) <= 0) {
                        filterArray.push(filterObject);
                    }
                }
                else if (invalue != undefined && invalue != "") {
                    filterObject["dataType"] = $($($this).find("#numberrangeslider input")).attr("data-type");
                    filterObject["tablename"] = $($($this).find("#numberrangeslider input")).attr("data-parent");
                    filterObject["columndisplayname"] = $($($this).find("#numberrangeslider input")).attr("displayname");
                    filterObject["columnname"] = $($($this).find("#numberrangeslider input")).attr("data-child");
                    filterObject["invalue"] = invalue;
                    var dataString = "";
                    var inlinevalues = invalue.split(",");
                    _.each(inlinevalues, function (value, index) {
                        if (dataString.split("@@").indexOf(value) == -1) {
                            dataString += value + "@@"
                        }
                    })
                    dataString = dataString.substring(0, dataString.length - 2);
                    filterObject["data"] = dataString;
                    filterObject["isdrilldownfilter"] = true;
                    filterArray.push(filterObject);
                }
                else {
                    if (temp.indexOf(encodeURIComponent(displayname)) > -1 || $('#customfiltervalue' + displayname).val() != "") {
                        if ($($($this).find("#numberrangeslider input")).attr("data-type") === "number") {
                            filterObject["dataType"] = $($($this).find("#numberrangeslider input")).attr("data-type");
                            filterObject["tablename"] = $($($this).find("#numberrangeslider input")).attr("data-parent");
                            filterObject["columndisplayname"] = $($($this).find("#numberrangeslider input")).attr("displayname");
                            filterObject["columnname"] = $($($this).find("#numberrangeslider input")).attr("data-child");
                            filterObject["fromRange"] = $(NumberClassName + "[data-id='acc" + displayname + "']" + " #numberrangeslider").data('ionRangeSlider').old_from;
                            filterObject["toRange"] = $(NumberClassName + "[data-id='acc" + displayname + "']" + " #numberrangeslider").data('ionRangeSlider').old_to;
                            filterObject["customfiltervalue"] = $($($this).find("input[type='text']")).val();
                            if (includeArray.indexOf($($($this).find("#numberrangeslider input")).attr("data-name")) < 0) {
                                filterArray.push(filterObject);
                            }
                        }
                    }
                }
            } else {
                var customFieldObject = customFieldsParsedArray[customFieldIndex];
                var finalScriptCondition = customFieldObject.scriptcondition;
                if ((filtergreaterthanvalue != "" || filterlessthanvalue != "") && (invalue == "" || invalue == undefined)) {
                    if (filtergreaterthanvalue != "" && filterlessthanvalue != "") {
                        finalScriptCondition = ";" + "if(" + finalScriptCondition + ">=" + filtergreaterthanvalue + "&&" + finalScriptCondition + "<=" + filterlessthanvalue + ") return true else false";
                        var sqlFilterObject = {};
                        sqlFilterObject["filtergreaterthanvalue"] = parseFloat($(NumberClassName + "[data-id='acc" + displayname + "']" + " .intfiltervalue .filtergreaterthanvalue").val());
                        sqlFilterObject["filterlessthanvalue"] = parseFloat($(NumberClassName + "[data-id='acc" + displayname + "']" + " .intfiltervalue .filterlessthanvalue").val());
                        customFieldObject["sqlcondition"] = sqlFilterObject;
                        filterObject["filtergreaterthanvalue"] = parseFloat($(NumberClassName + "[data-id='acc" + displayname + "']" + " .intfiltervalue .filtergreaterthanvalue").val());
                        filterObject["filterlessthanvalue"] = parseFloat($(NumberClassName + "[data-id='acc" + displayname + "']" + " .intfiltervalue .filterlessthanvalue").val());
                        if (equalvalue != "" && equalvalue != undefined) {
                            sqlFilterObject["filtergreaterthanvalue"] = parseFloat(equalvalue);
                            sqlFilterObject["filterlessthanvalue"] = parseFloat(equalvalue);
                        }
                    } else if (filtergreaterthanvalue != "" && filterlessthanvalue == "") {
                        var lessthanvalue = $(NumberClassName + "[data-id='acc" + displayname + "']" + " #numberrangeslider").data('ionRangeSlider').options.max;
                        finalScriptCondition = ";" + "if(" + finalScriptCondition + ">=" + filtergreaterthanvalue + "&&" + finalScriptCondition + "<=" + lessthanvalue + ") return true else false";
                        var sqlFilterObject = {};
                        sqlFilterObject["filtergreaterthanvalue"] = parseFloat($(NumberClassName + "[data-id='acc" + displayname + "']" + " .intfiltervalue .filtergreaterthanvalue").val());
                        sqlFilterObject["filterlessthanvalue"] = $(NumberClassName + "[data-id='acc" + displayname + "']" + " #numberrangeslider").data('ionRangeSlider').options.max;
                        customFieldObject["sqlcondition"] = sqlFilterObject;
                        filterObject["filtergreaterthanvalue"] = parseFloat($(NumberClassName + "[data-id='acc" + displayname + "']" + " .intfiltervalue .filtergreaterthanvalue").val());
                        filterObject["filterlessthanvalue"] = $(NumberClassName + "[data-id='acc" + displayname + "']" + " #numberrangeslider").data('ionRangeSlider').options.max;
                    } else if (filtergreaterthanvalue == "" && filterlessthanvalue != "") {
                        var greaterthanvalue = $(NumberClassName + "[data-id='acc" + displayname + "']" + " #numberrangeslider").data('ionRangeSlider').options.min;
                        finalScriptCondition = ";" + "if(" + finalScriptCondition + ">=" + greaterthanvalue + "&&" + finalScriptCondition + "<=" + filterlessthanvalue + ") return true else false";
                        var sqlFilterObject = {};
                        sqlFilterObject["filtergreaterthanvalue"] = $(NumberClassName + "[data-id='acc" + displayname + "']" + " #numberrangeslider").data('ionRangeSlider').options.min;
                        sqlFilterObject["filterlessthanvalue"] = parseFloat($(NumberClassName + "[data-id='acc" + displayname + "']" + " .intfiltervalue .filterlessthanvalue").val());
                        customFieldObject["sqlcondition"] = sqlFilterObject;
                        filterObject["filtergreaterthanvalue"] = $(NumberClassName + "[data-id='acc" + displayname + "']" + " #numberrangeslider").data('ionRangeSlider').options.min;
                        filterObject["filterlessthanvalue"] = parseFloat($(NumberClassName + "[data-id='acc" + displayname + "']" + " .intfiltervalue .filterlessthanvalue").val());
                    }
                    if (filtergreaterthanvalue != "" || filterlessthanvalue != "") {
                        customFieldObject["scriptcondition"] = finalScriptCondition;
                        customFieldObject["parameter"] = null;
                        filterObject["tablename"] = $($($this).find("#numberrangeslider input")).attr("data-parent");
                        filterObject["columndisplayname"] = $($($this).find("#numberrangeslider input")).attr("displayname");
                        filterObject["columnname"] = $($($this).find("#numberrangeslider input")).attr("data-child");
                        filterObject["dataType"] = $($($this).find("#numberrangeslider input")).attr("data-type");
                        filterObject["customfield"] = customFieldObject;
                        if (includeArray.indexOf($($($this).find("#numberrangeslider input")).attr("data-child")) < 0) {
                            filterArray.push(filterObject);
                        }
                    }
                    if (equalvalue != "" && equalvalue != undefined) {
                        filterObject["filtergreaterthanvalue"] = parseFloat(equalvalue);
                        filterObject["filterlessthanvalue"] = parseFloat(equalvalue);
                        filterObject["equalvalue"] = parseFloat(equalvalue);
                    }
                } else if (invalue != "") {
                    var sqlFilterObject = {};
                    filterObject["dataType"] = $($($this).find("#numberrangeslider input")).attr("data-type");
                    filterObject["tablename"] = $($($this).find("#numberrangeslider input")).attr("data-parent");
                    filterObject["columndisplayname"] = $($($this).find("#numberrangeslider input")).attr("displayname");
                    filterObject["columnname"] = $($($this).find("#numberrangeslider input")).attr("data-child");
                    filterObject["customfield"] = customFieldObject;
                    filterObject["invalue"] = invalue;
                    var dataString = "";
                    var inlinevalues = invalue.split(",");
                    _.each(inlinevalues, function (value, index) {
                        if (dataString.split("@@").indexOf(value) == -1) {
                            dataString += value + "@@"
                        }
                    })
                    dataString = dataString.substring(0, dataString.length - 2);
                    filterObject["data"] = dataString;
                    sqlFilterObject["sqldata"] = dataString;
                    customFieldObject["sqlcondition"] = sqlFilterObject;
                    filterObject["isdrilldownfilter"] = true;
                    filterArray.push(filterObject);
                }
                else {
                    finalScriptCondition = ";" + "if(" + finalScriptCondition + ">=" + $(NumberClassName + "[data-id='acc" + displayname + "']" + " #numberrangeslider").data('ionRangeSlider').old_from + "&&" + finalScriptCondition + "<=" + $(NumberClassName + "[data-id='acc" + displayname + "']" + " #numberrangeslider").data('ionRangeSlider').old_to + ") return true else false";
                    customFieldObject["scriptcondition"] = finalScriptCondition;
                    customFieldObject["parameter"] = null;
                    var sqlFilterObject = {};
                    sqlFilterObject["fromRange"] = $(NumberClassName + "[data-id='acc" + displayname + "']" + " #numberrangeslider").data('ionRangeSlider').old_from;
                    sqlFilterObject["toRange"] = $(NumberClassName + "[data-id='acc" + displayname + "']" + " #numberrangeslider").data('ionRangeSlider').old_to;
                    customFieldObject["sqlcondition"] = sqlFilterObject;
                    filterObject["dataType"] = $($($this).find("#numberrangeslider input")).attr("data-type");
                    filterObject["tablename"] = $($($this).find("#numberrangeslider input")).attr("data-parent");
                    filterObject["columndisplayname"] = $($($this).find("#numberrangeslider input")).attr("displayname");
                    filterObject["columnname"] = $($($this).find("#numberrangeslider input")).attr("data-child");
                    filterObject["fromRange"] = $(NumberClassName + "[data-id='acc" + displayname + "']" + " #numberrangeslider").data('ionRangeSlider').old_from;
                    filterObject["toRange"] = $(NumberClassName + "[data-id='acc" + displayname + "']" + " #numberrangeslider").data('ionRangeSlider').old_to;
                    filterObject["customfiltervalue"] = $($($this).find("input[type='text']")).val();
                    filterObject["customfield"] = customFieldObject;
                    if (includeArray.indexOf($($($this).find("#numberrangeslider input")).attr("data-name")) < 0) {
                        filterArray.push(filterObject);
                    }
                }

            }
        });
        // TO get Date Filters
        $(DateClassName + "[data-type = 'date']").each(function () {
            var $this = this;
            var filterObject = {};
            filterObject["dataType"] = $($this).attr("data-type");
            filterObject["tablename"] = $($this).attr("data-parent");
            filterObject["columndisplayname"] = $($this).attr("displayname");
            filterObject["filterDisplayName"] = $($this).parent().parent().parent().find('input.filter-display-name').val();
            filterObject["filterIgnore"] = $($this).parent().parent().parent().find(".filter-toggle").hasClass("filter-off");
            filterObject["columnname"] = $($this).attr("data-child");
            filterObject["daterange"] = $($this).attr("date-range");
            var text = $($this).text();
            var dateArray = text.split("-");
            var fromdate = dateArray[0].trim();//Date.parse(dateArray[0].trim());
            var todate = dateArray[1].trim() //Date.parse(dateArray[1].trim());
            if (filterObject["daterange"] == "custom") {
                var fromdate = dateArray[0].trim() + " 00:00:00";
                var todate = dateArray[1].trim() + " 23:59:59";
            }
            filterObject["fromRange"] = fromdate;
            filterObject["toRange"] = todate;
            var columnName = $($this).attr("data-child");
            var isCustomField = false;
            var customFieldIndex = 0;
            var customFieldsArray = sessionStorage.getItem("customFields");
            var temp1 = JSON.parse(customFieldsArray);
            var res;
            _.each(temp1, function (customfields) {
                _.each(customfields.parametrFieldsArray, function (value) {
                    res = $.grep(customparamerfield, function (e) {
                        return e.parametername == value;
                    })
                    var formula = customfields.formula;
                    var scriptcondition = customfields.scriptcondition;
                    var fields = customfields.fields;
                    var sqlformula = customfields.sqlformula;
                    if (res.length != 0) {
                        customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
                        customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
                        customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
                        customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
                    } else {
                        _.each(customparamerfield, function (values) {
                            if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
                                customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
                                customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                            }
                        });
                    }
                });
            });
            customFieldsArray = JSON.stringify(temp1);
            var customFieldsParsedArray = [];
            if (customFieldsArray != "null" && customFieldsArray != undefined) {
                customFieldsParsedArray = JSON.parse(customFieldsArray);
            }
            $.each(customFieldsParsedArray, function (index, customField) {
                if (customField.name == columnName) {
                    isCustomField = true;
                    customFieldIndex = index;
                }
            });
            if (isCustomField == true) {
                var customFieldObject = customFieldsParsedArray[customFieldIndex];
                var finalScriptCondition = customFieldObject.scriptcondition;
                finalScriptCondition = ";" + "if( new Date(" + finalScriptCondition + ") >= new Date(" + fromdate + ")" + " &&  new Date(" + finalScriptCondition + ") <= new Date(" + todate + ")) return true else false";
                customFieldObject["scriptcondition"] = finalScriptCondition;
                customFieldObject["parameter"] = null;
                var sqlFilterObject = {};
                sqlFilterObject["fromRange"] = fromdate;
                sqlFilterObject["toRange"] = todate;
                customFieldObject["sqlcondition"] = sqlFilterObject;
                filterObject["customfield"] = customFieldObject;
            } else {
                filterArray.push(filterObject);
            }

            if ("fromEdit" != fromEdit) {
                if ((requestedFrom != "advancedSharedReport" || requestedFrom=="save") && isCustomField==true) {
                    if (includeArray.indexOf($($this).attr("data-name")) < 0) {
                        filterArray.push(filterObject);
                    }
                }
            }
        });

        if (requestedFrom != "advancedSharedReport") {
            filterArrays = filterArray
        }
        //TODO update unique filters.
        /*var tempFilterArray = filterArray;
        tempFilterArray = JSON.parse(JSON.stringify(tempFilterArray));*/

        //Filter Order
        if(filterOrder!=undefined && filterOrder.length!=0){
            filterArray = _.sortBy(filterArray, function(obj){ 
                                return _.indexOf(filterOrder, obj.columnname);
                            });
        }

        return filterArray;
    },
    setDefaultCustomParametersFilterValues: function (value) {
        parameterFilterValues = value
    },
    getDefaultCustomParameterFilterValues: function () {
        return parameterFilterValues;
    },
    getCustomParametersFilters: function (applyattributes) {
        var filterArray = [];
        $('.filtercustomparamerevalues').each(function () {
            var $this = this;
            var parametername = $($($this).find("input#parametervalue")).attr("displayname").replace(/([#;?&,%. +*~\':"!^$%[\]()<=>|\/@])/g, "");
            var valueType = $($($this).find("input#parametervalue")).attr("data-columnType");
            var value = $("#" + $($this).attr('id') + " #parametervalue").val();
            /*if(applyattributes!='applyattributes'){
                _.each(customparamerfield,function(fields,j){
                    if(fields.parametername==parametername){
                        fields.parametercurrentvalue=value;
                    }
                });
            }*/
            var filterObject = {};
            filterObject["parametername"] = parametername;
            filterObject["parameterrangevalues"] = "";
            filterObject["parametercurrentvalue"] = value;
            filterObject["valueType"] = valueType;
            filterObject["filterDisplayName"] = $("input.filter-display-name[data-name='" + parametername + "']").val();
            filterObject["filterIgnore"] = $(".acc-content[data-id='" + parametername + "']").find(".filter-toggle").hasClass("filter-off");
            filterArray.push(filterObject);
        });
        $('.filtercustomparamerelist').each(function () {
            var $this = this;
            var parameterlistvalues = '';
            var parametername = $($($this).find("input#parametervalue")).attr("displayname").replace(/([#;?&,%. +*~\':"!^$%[\]()<=>|\/@])/g, "");
            var valueType = $($($this).find("input#parametervalue")).attr("data-columnType");
            var value = $("#" + $($this).attr('id') + " .selectedparametervalue :selected").val();
            /*if(applyattributes!='applyattributes'){
                _.each(customparamerfield,function(fields,j){
                    if(fields.parametername==parametername){
                        fields.parametercurrentvalue=value;
                        parameterlistvalues=fields.parameterlistvalues;
                    }
                });
            }*/
            var filterObject = {};
            filterObject["parametername"] = parametername;
            filterObject["parameterlistvalues"] = parameterlistvalues;
            filterObject["parametercurrentvalue"] = value;
            filterObject["valueType"] = valueType;
            filterObject["filterDisplayName"] = $("input.filter-display-name[data-name='" + parametername + "']").val();
            filterObject["filterIgnore"] = $(".acc-content[data-id='" + parametername + "']").find(".filter-toggle").hasClass("filter-off");
            filterArray.push(filterObject);
        });
        $('.filtercustomparamererangevalues').each(function () {
            var $this = this;
            var parametername = $($($this).find("#numberrangeslider input")).attr("displayname").replace(/([#;?&,%. +*~\':"!^$%[\]()<=>|\/@])/g, "");
            var valueType = $($($this).find("#numberrangeslider input")).attr("data-columnType");
            var value = $("#acc" + $($this).attr('id') + " #parametervalue").val();
            var parameterrangevalues = '';
            if (valueType == "range") {
                value = $("#" + $($this).attr('id') + " #numberrangeslider").data('ionRangeSlider').old_from;
            }
            /*if(applyattributes!='applyattributes'){
                _.each(customparamerfield,function(fields,j){
                    if(fields.parametername==parametername){
                        fields.parametercurrentvalue=value;
                        parameterrangevalues=fields.parameterrangevalues;
                    }
                });
            }*/
            var filterObject = {};
            filterObject["parametername"] = parametername;
            filterObject["parameterrangevalues"] = parameterrangevalues;
            filterObject["parametercurrentvalue"] = value;
            filterObject["valueType"] = valueType;
            filterObject["filterDisplayName"] = $("input.filter-display-name[data-name='" + parametername + "']").val();
            filterObject["filterIgnore"] = $(".acc-content[data-id='" + parametername + "']").find(".filter-toggle").hasClass("filter-off");
            filterArray.push(filterObject);
        });
        return filterArray;
    },
    setIsExcludeNulls: function (value) {
        isExcludeNulls = value;
    },
    getIsExcludeNulls: function () {
        return isExcludeNulls;
    },
    getNewChartNumber: function () {
        // for (var i = 0; i <= Object.keys(chartDetails).length; i++) {
        //     if (chartDetails.hasOwnProperty(i)) {
        //     } else {
        //         break;
        //     }
        // };
        //var i = 0;
        var currentdatano = Math.max.apply([], $('.maxanim1').map(function () {
            return $(this).attr('data-num');
        }).get());
        if (currentdatano === undefined) {
            currentdatano = 0;
        } else {
            currentdatano = parseInt(currentdatano) + 1;
        }
        return currentdatano;
    },
    isScriptData: function (data) {
        var patt = new RegExp('/(\b)(on\S+)(\s*)=|javascript:|(<\s*)(\/*)script|style(\s*)=|(<\s*)meta/ig');
        var isScript = patt.test(data);
        if (isScript) {
            $(".spinner").hide();
            $(".gridspinner").hide();
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.birdmessage h2').empty().append('Error : ');
            $('.birdmessage h2').append('<span class="errorcode">');
            $('.birdmessage-info').empty().text(getMessage("BirdError12"));
        }
        return isScript;
    },
    getMapping: function (mappingObj) {
        var parsedJson = JSON.parse(mappingObj);
        var mainObj = parsedJson.properties;
        var keys = Object.keys(mainObj);
        this.string = {};
        var that = this;
        _.each(keys, function (value) {
            that.string[value] = "";
        })
        this.foundinneobjkeys = keys;
        this.mapping(mainObj, this.foundinneobjkeys);
    },
    mapping: function (mainObj, keys) {
        var that = this;
        var foundkeys = JSON.parse(JSON.stringify(that.foundinneobjkeys));
        _.each(foundkeys, function (key, i) {
            try {
                var value = that.getvalue(that.string[key] + key, mainObj);
            } catch (e) {
                console.log(that.string[key] + key);
                return 0;
            }
            if (Object.keys(value).indexOf("type") >= 0) {
                console.log(that.string[key] + key);
                console.log(value);
            } else {
                that.foundinneobjkeys = that.foundinneobjkeys.concat(Object.keys(value["properties"]));
                _.each(Object.keys(value["properties"]), function (value) {
                    if (that.string[key] == "") {
                        that.string[value] = key + ".properties.";
                    } else {
                        that.string[value] = that.string[key] + key + ".properties.";
                    }
                })
            }
            that.foundinneobjkeys.splice(0, 1);
        });
        if (that.foundinneobjkeys.length > 0) {
            this.mapping(mainObj, that.foundinneobjkeys, true);
        }
    },
    getvalue: function (keystring, mainObj) {
        var string = keystring.split(".");
        var value = mainObj;
        _.each(string, function (key) {
            value = value[key];
        });
        return value;
    },
    displaymetadata: function (auditMetaDataObj, auditMetaDataFeature, auditDataFeature) {
        $(".itsaudit").addClass("hide");
        $(".metadataauditlivecontainer").addClass("hide");
        $(".dataauditlivecontainer").addClass("hide");
        if (auditMetaDataFeature == true && auditDataFeature == true && auditMetaDataObj["metaDataAudit"] == 1) {
            $(".itsaudit").removeClass("hide");
            $(".metadataauditlivecontainer ").removeClass("hide");
            $(".dataauditlivecontainer").removeClass("hide");
        }
        if (auditDataFeature == true && auditMetaDataObj["metaDataAudit"] == 0) {
            $(".itsaudit").removeClass("hide");
            $(".dataauditlivecontainer").removeClass("hide");
        }
        if (auditMetaDataFeature == true && auditMetaDataObj["metaDataAudit"] == 1) {
            $(".itsaudit").removeClass("hide");
            $(".metadataauditlivecontainer ").removeClass("hide");
        }
    },
    xaxisDrilldownState: function (chartnumber, xAxisDetails) {

        xaxisDrillDownStateObj[chartnumber] = xAxisDetails;
    },
    getXaxisDetails: function (chartnumber) {
        return xaxisDrillDownStateObj[chartnumber];
    },
    getCustomFieldsFilterObject: function (chartNumber) {
        return customFieldsFilterObject[chartNumber];

    },
    setCustomFieldsFilterObject: function (chartnumber, filterObject) {
        customFieldsFilterObject[chartnumber] = filterObject;
    },
    getSharedFilterObjectArray: function (chartnumber) {
        return sharedFilterObjectArray[chartnumber];
    },
    setSharedFilterObjectArray: function (chartnumber, filterObjects) {
        sharedFilterObjectArray[chartnumber] = filterObjects;
    },
    setCustomFieldsObjectReportedId: function (customFieldsObject, reportid) {
        customFieldsObjectReportedId[reportid] = customFieldsObject;
    },
    getCustomFieldsObjectReportedId: function (reportid) {
        return customFieldsObjectReportedId[reportid];
    },
    setDateHistogramState: function (value, chartNo) {
        dateHistogramState[chartNo] = value;
        if(chartDetails[chartNo]!=undefined&&chartDetails[chartNo]!=null){
            let parsedChartDetails = JSON.parse(chartDetails[chartNo]);
            let chartType = parsedChartDetails.chartType;
            let comparedaterangecharttpes =  ["bar", "stackedbar", "line", "spline", "area", "stackedarea"];
            let  compareDateRangeObj= parsedChartDetails["compareDateRangeObj"] != undefined && parsedChartDetails["compareDateRangeObj"] != null && parsedChartDetails["compareDateRangeObj"] != "null" ? parsedChartDetails["compareDateRangeObj"] : {}
            if(compareDateRangeObj!=null&&comparedaterangecharttpes.indexOf(chartType)>=0&& compareDateRangeObj.hasOwnProperty("0") && compareDateRangeObj["0"]!=null){
                let yjsObj = compareDateRangeObj["0"];
                let yAxisDisplayName = parsedChartDetails['yAxis'][0]["columnName"];
                if (yjsObj != null && yjsObj.hasOwnProperty(yAxisDisplayName)) {
                    var ycompareDetailsObj = yjsObj[yAxisDisplayName];
                    ycompareDetailsObj["compareDateHistogramInterval"] = value!=null&&value!=undefined&&value!="null"?(value!="reset"?value:""):ycompareDetailsObj["compareDateHistogramInterval"];
                    chartDetails[chartNo] = JSON.stringify(parsedChartDetails);
                }
            }
        }
    },
    getDateHistogramState: function (chartNo) {
        return dateHistogramState[chartNo];
    },
    setDateHistogramStateForML: function (value, chartNo) {
        dateHistogramStateForML[chartNo] = value;
    },
    getDateHistogramStateForML: function (chartNo) {
        return dateHistogramStateForML[chartNo];
    },
    setSummaryTableDateHistogramState: function (value, chartNo) {
        dateSummaryTableHistogramState[chartNo] = value;
    },
    getSummaryTableDateHistogramState: function (chartNo) {
        return dateSummaryTableHistogramState[chartNo];
    },
    setMeasureReportId: function (customMesure, reportId) {
        return customMesureReportId[reportId] = customMesure;
    },
    getMeasureReportId: function (reportId) {
        return customMesureReportId[reportId];
    },
    getCustomHierarchyReportId: function (reportId) {
        return customHierarchyReportId[reportId];
    },
    setCustomHierarchyReportId: function (customMesure, reportId) {
        customHierarchyReportId[reportId] = customMesure;
    },
    getCustomRangeReportId: function (reportId) {
        return customRangeReportId[reportId];
    },
    setCustomRangeReportId: function (customRange, reportId) {
        customRangeReportId[reportId] = customRange;
    },
    setAggCustomFieldMeasureReportId: function (customField, reportId) {
        aggCustomMeasureField[reportId] = customField;
    },
    getAggCustomFieldMeasureReportId: function (reportId) {
        return aggCustomMeasureField[reportId];
    },
    getDateRangeObj: function (chartnumber) {
        return dateRangeObj[chartnumber];
    },
    setDateRangeObj: function (chartnumber, obj) {
        dateRangeObj[chartnumber] = obj;

    },
    setMashupAddConnectionObj: function (id, value) {
        mashupaddconnection[id] = value;
    },
    setAllMashupAddConnectionObj: function (value) {
        mashupaddconnection = value;
    },
    getAllMashupAddConnectionObj: function () {
        return mashupaddconnection;
    },
    getMashupAddConnectionObj: function () {
        return mashupaddconnection;
    },
    setNodesForMashUp: function (value) {
        mashupnodes = value;
    },
    getNodesForMashUp: function () {
        return mashupnodes;
    },
    setMashupSelectedreportColumns: function (id, value) {
        mashupcolumns[id] = value;
    },
    setAllMashupSelectedreportColumns: function (value) {
        mashupcolumns = value;
    },
    getMashupSelectedreportColumns: function () {
        return mashupcolumns;
    },
    setMashupSelectedreportColumnsEditCustomQuery: function (id, value) {
        editCustomQueryMashupcolumns[id] = value;
    },
    getMashupSelectedreportColumnsEditCustomQuery: function () {
        return editCustomQueryMashupcolumns;
    },
    resetMashupSelectedreportColumns: function () {
        mashupcolumns = {};
    },
    setMashupjoins: function (value) {
        mashupjoinslist = value;
    },
    getMashupjoins: function () {
        return mashupjoinslist;
    },
    setOldCustomQueryEntities: function (value) {
        oldCustomQueryEntities = value;
    },
    getOldCustomQueryEntities: function () {
        return oldCustomQueryEntities;
    },
    getMashupjoinsnetworkreference: function () {
        return mashupjoinnetworkreference;
    },
    setMashupjoinsnetworkreference: function (value) {
        mashupjoinnetworkreference = value;
    },
    setDuplicateColumnsMap: function (value) {
        duplicatecolumnsMap = value;
    },
    getDuplicateColumnsMap: function () {
        return duplicatecolumnsMap;
    },
    isTrendSupport: function(chartType){
            var trendNotSupportCharts = ["doby", "summarytable", "pivot", "heatmap","metric","pie","donut","scatter","metric","funnel","pyramid","wordcloud","3dpie","fullcirclegauge","solidgauge","srilankamap","world","leaflet","countrymap","indiamap","semicircle","boxplot","textchart","trellis","bubble","sankey","combinationchart"];
               if(trendNotSupportCharts.indexOf(chartType)>=0){
                    return false;
                }
               else{
                   return true;
                }
           },
    setGroupColorsMap: function (value) {
        mashupgroupcolors = value;
    },
    getGroupColorsmap: function () {
        return mashupgroupcolors;
    },
    setDrillThroughReports: function (drillThroughReports) {
        drillThroughReportsArray = drillThroughReports;
    },
    getDrillThroughReports: function () {
        return drillThroughReportsArray;
    },
    setPriorityColumns: function (columns) {
        priorityColumnsArray = columns;
    },
    getPriorityColumns: function () {
        return priorityColumnsArray;
    },
    updateAxisName: function (oldname, updatedname, axisName) {
        var chartInfoDetails = this.getAllChartDetails();
        var that = this;
        _.each(chartInfoDetails, function (i, j) {
            var storedObject = i;
            var storedParsedObject = JSON.parse(storedObject);
            var axisData = storedParsedObject[axisName];
            _.each(axisData, function (axis, index) {
                if (axisData[index].columnName == oldname) {
                    axisData[index].columnName = updatedname;
                    axisData[index].displayName = updatedname;
                    storedParsedObject[axisName] = axisData;
                    that.updateChartDetails(j, JSON.stringify(storedParsedObject));
                }
            });
            if (storedParsedObject.rankingColumn == oldname) {
                storedParsedObject.rankingColumn = updatedname;
            }
            if (storedParsedObject["metricdatefield"] != undefined && storedParsedObject["metricdatefield"] == oldname) {
                storedParsedObject["metricdatefield"] = updatedname;
                storedParsedObject["metricdatefielddisplay"] = updatedname;
            }
            var chartType = storedParsedObject["chartType"];
            if (chartType == "summarytable") {
                var summarytablecolumns = storedParsedObject["summarytablecolumns"];
                _.each(summarytablecolumns, function (summaryColumn) {
                    if (summaryColumn.columnName != undefined && summaryColumn.columnName == oldname) {
                        summaryColumn.displayName = updatedname;
                        summaryColumn.columnName = updatedname;
                    }
                });
                var summarytablevalues = storedParsedObject["summarytablevalues"];
                _.each(summarytablevalues, function (value) {
                    if (value.columnName != undefined && value.columnName == oldname) {
                        value.displayName = updatedname;
                        value.columnName = updatedname;
                    }
                });
                if (storedParsedObject["summarytablegroupbys"] != undefined && storedParsedObject["summarytablegroupbys"].columnName == oldname) {
                    storedParsedObject["summarytablegroupbys"].displayName = updatedname;
                    storedParsedObject["summarytablegroupbys"].columnName = updatedname;
                }
            } else if (chartType == "pivot") {
                var pivotrowvalues = storedParsedObject["rows"];
                _.each(pivotrowvalues, function (value) {
                    if (value.columnName != undefined && value.columnName == oldname) {
                        value.displayName = updatedname;
                        value.columnName = updatedname;
                    }
                });
                var pivotcolumnvalues = storedParsedObject["columns"];
                _.each(pivotcolumnvalues, function (value) {
                    if (value.columnName != undefined && value.columnName == oldname) {
                        value.displayName = updatedname;
                        value.columnName = updatedname;
                    }
                });
                var pivotvalues = storedParsedObject["values"];
                _.each(pivotvalues, function (value) {
                    if (value.columnName != undefined && value.columnName == oldname) {
                        value.displayName = updatedname;
                        value.columnName = updatedname;
                    }
                });
            }
            else if (chartType == "combinationchart") {
                var combinationChartObj = storedParsedObject["combinationchartslist"];
                _.each(combinationChartObj, function (val, i) {
                    if (val.columnName != undefined && val.columnName == oldname) {
                        val.displayName = updatedname;
                        val.columnName = updatedname;
                    }
                });
            }
            var comparedaterangecharttpes =  ["bar", "stackedbar", "line", "spline", "area", "stackedarea"];
            let compareDateRangeObj = comparedaterangecharttpes.indexOf(chartType)!=-1?storedParsedObject["compareDateRangeObj"]:null;
            if(compareDateRangeObj!=undefined&&compareDateRangeObj!=null && compareDateRangeObj.hasOwnProperty("0") && compareDateRangeObj["0"]!=null){
                let yjsObj = compareDateRangeObj["0"];
                if(yjsObj.hasOwnProperty(oldname)){
                    _.each(yjsObj,function(val,key){
                        let yjsval = yjsObj[key];
                        delete yjsObj[key];
                        yjsObj[updatedname] = yjsval;
                    })
                }

            }

            that.updateChartDetails(j, JSON.stringify(storedParsedObject));
        });

    },

    getDimensionscustomstoryBoard: function (chartNo, dimensionsArray) {
        dimensions[chartNo] = dimensionsArray
    },
    setDimensionscustomstoryBoard: function (chartNo) {
        return dimensions[chartNo];
    },
    setPredictionResponse: function (response, chartNo) {
        predictionResponseData[chartNo] = response;
    },
    getPredictionResponse: function (chartNo) {
        return predictionResponseData[chartNo];
    },
    getAllFeatures: function () {
        return allFeatures;
    },
    setAllFeatures: function (features) {
        allFeatures = features;
    },
    setSmartInsightsTargetVal: function (value) {
        smartInsightsTargetVal = value;
    },
    getSmartInsightsTargetVal: function () {
        return smartInsightsTargetVal;

    },
    setSmartInsightsExcludeValues: function (exclueColumns) {
        exclueValues = exclueColumns;
    },
    getSmartInsightsExcludeValues: function () {
        return exclueValues;
    },
    setIsFiltersAdded: function (filtersAdded) {
        isFiltersAdded = filtersAdded;
    },
    getIsFiltersAdded: function () {
        return isFiltersAdded;
    },
    setaxisFieldIdentity: function (arr, chartnum) {
        axisFieldIdentity[chartnum] = arr;
    },
    getaxisFieldIdentity: function (chartnum) {
        return axisFieldIdentity[chartnum];
    },
    setChartTypeForMap: function (chartno, type) {
        charttypeMap[chartno] = type;
    },
    getChartTypeForMap: function (chartno) {
        return charttypeMap[chartno];
    },
    setDrillThrough: function (drillThroughArry) {
        drillThrough = drillThroughArry;
    },
    getDrillThrough: function () {
        return drillThrough;
    },
    setSummaryTableCustomHierarchyDrilldownName: function (name) {
        summaryTableCustomHierarchyDrilldownName = name;
    },
    getSummaryTableCustomHierarchyDrilldownName: function () {
        return summaryTableCustomHierarchyDrilldownName;
    },
    setUserCustomScript: function (reportId, textScript) {
        userCustomScript[reportId] = textScript;
    },
    getUserCustomScript: function (reportId) {
        if (reportId == "all") {
            return userCustomScript
        } else {
            return userCustomScript[reportId];
        }
    },
    setConditionalFormatData: function (chartno, array) {
        conditionalFormatArray[chartno] = array;
    },
    getConditionalFormatData: function (chartno) {
        return conditionalFormatArray[chartno];
    },
    generateFilterWithEmbededFilters: function (reportFilterObject, sharedFilters) {
        var urlParameterFilters = embedfilterArray;
        if (reportFilterObject != undefined) {
            reportFilterObject = JSON.parse(JSON.stringify(reportFilterObject));
        } else {
            reportFilterObject = [];
        }
        if (urlParameterFilters.length != 0 && (sharedFilters == undefined || sharedFilters.length <= 2)) {
            _.each(urlParameterFilters, function (drilldownfiltervaue) {
                var columnNameNotFound = true;
                _.each(reportFilterObject, function (value, index) {
                    if (value.columndisplayname == drilldownfiltervaue.columndisplayname && value.hasOwnProperty("reportfiltersid") && drilldownfiltervaue != undefined && !(drilldownfiltervaue.hasOwnProperty("isembeddedfilter"))) {
                        columnNameNotFound = false;
                        if (((drilldownfiltervaue.hasOwnProperty("excludeFilterData") && value.hasOwnProperty("excludeFilterData")) || !(drilldownfiltervaue.hasOwnProperty("excludeFilterData") && !value.hasOwnProperty("excludeFilterData")))) {
                            if (value.dataType == "string" && (value.data + "").startsWith('@@')) {
                                value.data += drilldownfiltervaue.data;
                            } else if ((value.dataType == "number" || value.dataType == "date") && drilldownfiltervaue.hasOwnProperty("data") && value.hasOwnProperty("data")) {
                                value.data = drilldownfiltervaue.data;
                            } else if ((value.dataType == "number" || value.dataType == "date") && drilldownfiltervaue.hasOwnProperty("fromRange")) {
                                value["embededFromRange"] = drilldownfiltervaue.fromRange;
                                value["embededToRange"] = drilldownfiltervaue.toRange;
                            } else {
                                if (value.data != undefined && drilldownfiltervaue.data != undefined) {
                                    value.data += "@@" + drilldownfiltervaue.data;
                                } else {
                                    reportFilterObject = reportFilterObject.concat(drilldownfiltervaue);
                                }
                            }
                        } else {
                            value.data += "@@" + drilldownfiltervaue.data;
                            reportFilterObject = reportFilterObject.concat(drilldownfiltervaue);
                        }
                    }
                });
                if (columnNameNotFound) {
                    reportFilterObject = reportFilterObject.concat(drilldownfiltervaue);
                }
            });
        } else {
            if (sharedFilters != undefined && sharedFilters != null && sharedFilters != "[]" && sharedFilters.length > 0 && embedfilterArray != undefined) {
                reportFilterObject = JSON.parse(sharedFilters).concat(embedfilterArray).concat(reportFilterObject);
            } else if (embedfilterArray != undefined) {
                reportFilterObject = reportFilterObject.concat(embedfilterArray)
            }
        }
        return reportFilterObject;
    },
    getBreadCrumpSpanElement: function (data) {
        var jsonObject = {};
        try {
            if (data != undefined && data.indexOf("@@") >= 0) {
                jsonObject["span"] = '<span class="drillmoreitemslist"><i class="fa fa-ellipsis-v"></i></span>';
                var ulEle = '<ul class="quickfilterslist hide">';
                _.each(data.split("@@"), function (value) {
                    ulEle += '<li title="' + value + '">' + value + '</li>'
                })
                ulEle += "</ul>";
                jsonObject["ulelement"] = ulEle;
            }
        } catch (e) { }
        return jsonObject;
    },

    getValues: function (obj) {
        var values = Object.keys(obj).map(function (e) {
            return obj[e]
        });
        return values;
    },
    setTrendType: function (type) {
        trendType = type;
    },
    getTrendType: function () {
        return trendType;
    },
    setMainFilerObject: function (mainFilerObject) {
        this.mainFiterObject = mainFilerObject;
    },
    getMainFilerObject: function () {
        return this.mainFiterObject;
    },
    setDrillDownChartNumber: function (chartnumber) {
        this.drilldownChartNumber = chartnumber;
    },
    getDrillDownChartNumber: function () {
        return this.drilldownChartNumber;
    },
    setLegendFilterobj: function (filter, chartnum) {
        legendFilter[chartnum] = filter;
    },
    getLegendFilterobj: function (chartnum) {
        return legendFilter[chartnum]
    },
    setLegendFilterobjForAllCharts: function (filter) {
        legendFilterAllChart = filter;
    },
    getLegendFilterobjForAllCharts: function () {
        return legendFilterAllChart;
    },
    setChartSize: function (chartnumber, chart) {
        if ($('.fullscreenmain[data-num=' + chartnumber + ']').length > 0) {
            var cuurenth = $('#chart' + chartnumber).height();
            var breadcrumbh = $(".breadcrumb[data-num=" + chartnumber + "]").outerHeight() + 10;
            var finalh = cuurenth - breadcrumbh;
            var finalw = $('#chart' + chartnumber + ' .highcharts-container').width();
            if (chart.setSize != undefined) {
                chart.setSize(finalw, finalh);
            }
        }
    },
    setMainFilerObject: function (mainFilerObject) {
        this.mainFiterObject = mainFilerObject;
    },
    getMainFilerObject: function () {
        return this.mainFiterObject;
    },
    setMLChartData: function (chartnumber, value) {
        mlChartData[chartnumber] = value;
    },
    getMLChartData: function (chartnumber) {
        return mlChartData[chartnumber];
    },
    setSavedDrilldownAll: function (filter) {
        saveddrilldownAll = filter;
    },
    getSavedDrilldownAll: function () {
        return saveddrilldownAll;
    },
    setDrillup: function (chartnumber, yaxisfields) {
        var length = yaxisfields.length;
        drillupMap[chartnumber] = length;
    },
    getDrillup: function (chartnumber) {
        return drillupMap[chartnumber];
    },

    setDrillupCounter: function (chartnumber, drillupCount) {
        drillupMapCount[chartnumber] = drillupCount;

    },
    getDrillupCounter: function (chartnumber) {
        return drillupMapCount[chartnumber];
    },
    setMLGridDetails: function (gridData) {
        mlGridData = gridData;
    },
    updateTempStoryBookDetails: function (tabData) {
        tempStoryBookDetails = tabData;
    },
    getTempStoryBookDetails: function () {
        return tempStoryBookDetails;
    },
    getStoryBookTabDetails: function (tabid) {
        var tabData = {};
        if (tempStoryBookDetails != undefined && tempStoryBookDetails.length > 0 && tabid != undefined && tabid != null) {
            _.each(tempStoryBookDetails, function (i) {
                if (tabid == i.id) {
                    tabData = i;
                }
            });
        }
        return tabData;
    },
    getMLGridDetails: function () {
        return mlGridData;
    },
    setMLSelectedColumns: function (columns) {
        mlSelectedColumns = columns;
    },
    getMLSelectedColumns: function () {
        return mlSelectedColumns;
    },
    setSmartinsightSelectedColumns: function (columns) {
        smartinsightSelectedColumns = columns;
    },
    getSmartinsigtSelectedColumns: function () {
        return smartinsightSelectedColumns;
    },
    setColumnHavingTwoDistinctValues: function (columns) {
        mlColumnHavingTwoDistinctValues = columns;
    },
    getColumnHavingTwoDistinctValues: function () {
        return mlColumnHavingTwoDistinctValues;
    },
    setRdisplyColumnMap: function (mapForGetDuplicateNamedata) {
        mapForGetDuplicateName = mapForGetDuplicateNamedata;
    },
    getRdisplyColumnMap: function (reportid) {
        return mapForGetDuplicateName;
    },
    setChartDataformap: function (chart, chartnum) {
        chartdataformap[chartnum] = chart;
    },
    getChartDataformap: function (chartnum) {
        return chartdataformap[chartnum];
    },
    setUsedFieldsInCustomFieldsForMashUp: function (usedFiledsList) {
        usedFieldsInCustomFieldsForMashUp = usedFiledsList;
    },
    getUsedFieldsInCustomFieldsForMashUp: function (chartnum) {
        return usedFieldsInCustomFieldsForMashUp;
    },
    setSelectedTablesColumnMapForMashup: function (value) {
        selectedTablesColumnMapForMashup = value;
    },
    getSelectedTablesColumnMapForMashup: function () {
        return selectedTablesColumnMapForMashup;
    },
    setExistingEntitiesMetaData: function (value) {
        existingEntitiesMetaData = value;
    },
    getExistingEntitiesMetaData: function () {
        return existingEntitiesMetaData;
    },
    setDataModelJoinArray: function (value) {
        dataModelJoinArray = value;
    },
    getDataModelJoinArray: function () {
        return dataModelJoinArray;
    },

    getAllowedEntities: function (multifactentities, columnName, customfunction, yaxisNameArray, xaxisNameArray, factables, isFrom) {
        var arrays = [];
        var actualtablenames = [];
        var disable = false;
        var facttables = [];
        var yfacttables = [];
        var xfacttables;
        var ycount = 0;
        var yselection = 0;
        var dimensionFound = false;
        if (factables != undefined) {
            facttables = factables;
        }

        _.each($('.yaxisattributes #Yitemdisplay'), function (element) {
            yselection++;
            var name = $(element).attr("data-name");
            var dataType = $(element).attr("data-type");
            var customFields = JSON.parse(sessionStorage.getItem("customFields"));
            if (customFields != null && customFields != undefined) {
                var customFieldObject = $.grep(customFields, function (e) { return e.name == name });
                if (customFieldObject != undefined && customFieldObject.length > 0) {
                    _.each(customFieldObject[0].usedFields, function (hierarchy) {
                        var tablename = hierarchy.split(".")[0];
                        if (facttables.indexOf(tablename) != -1) {
                            yfacttables.push(tablename);
                            ycount++;
                        }
                        else {
                            dimensionFound = true;
                        }
                        arrays.push(multifactentities[tablename]);
                        actualtablenames.push(tablename);
                    });
                } else if (dataType != undefined && dataType != 'custommeasurehierarchy' && dataType != 'customaggregatedfield') {
                    var tablename = name.split(".")[0];
                    if (facttables.indexOf(tablename) != -1) {
                        yfacttables.push(tablename);
                        ycount++;
                    }
                    else {
                        dimensionFound = true;
                    }
                    arrays.push(multifactentities[tablename]);
                    actualtablenames.push(tablename);
                }
            } else if (dataType != undefined && dataType != 'custommeasurehierarchy' && dataType != 'customaggregatedfield') {
                var tablename = name.split(".")[0];
                if (facttables.indexOf(tablename) != -1) {
                    yfacttables.push(tablename);
                    ycount++;
                }
                else {
                    dimensionFound = true;
                }
                arrays.push(multifactentities[tablename]);
                actualtablenames.push(tablename);
            }
            if (custommeasurehierarchyArray != undefined && custommeasurehierarchyArray.length > 0) {
                var meassureHierarchyObj = $.grep(custommeasurehierarchyArray, function (e) { return e.name == name });
                if (meassureHierarchyObj != undefined && meassureHierarchyObj.length > 0 && meassureHierarchyObj[0].hierarchelist != undefined) {
                    _.each(meassureHierarchyObj[0].hierarchelist, function (hierarchy) {
                        if (customFields != null && customFields != undefined && hierarchy.dataType != undefined && hierarchy.dataType != 'custommeasurehierarchy') {
                            var customFieldObject = $.grep(customFields, function (e) { return e.name == hierarchy.columnName });
                            if (customFieldObject != undefined && customFieldObject.length > 0) {
                                _.each(customFieldObject[0].usedFields, function (customFieldVal) {
                                    var tablename = customFieldVal.split(".")[0];
                                    if (facttables.indexOf(tablename) != -1) {
                                        yfacttables.push(tablename);
                                        ycount++;
                                    }
                                    else {
                                        dimensionFound = true;
                                    }
                                    arrays.push(multifactentities[tablename]);
                                    actualtablenames.push(tablename);
                                });
                            } else {
                                var tablename = hierarchy.columnName.split(".")[0];
                                if (facttables.indexOf(tablename) != -1) {
                                    yfacttables.push(tablename);
                                    ycount++;
                                }
                                else {
                                    dimensionFound = true;
                                }
                                arrays.push(multifactentities[tablename]);
                                actualtablenames.push(tablename);
                            }
                        } else if (hierarchy.dataType != undefined && hierarchy.dataType === 'custommeasurehierarchy') {
                            var innermeassureHierarchyObj = $.grep(custommeasurehierarchyArray, function (e) { return e.name == hierarchy.columnName });
                            if (innermeassureHierarchyObj != undefined && innermeassureHierarchyObj.length > 0 && innermeassureHierarchyObj[0].hierarchelist != undefined) {
                                _.each(innermeassureHierarchyObj[0].hierarchelist, function (innerhierarchy) {
                                    if (customFields != null && customFields != undefined) {
                                        var customFieldObject = $.grep(customFields, function (e) { return e.name == innerhierarchy.columnName });
                                        if (customFieldObject != undefined && customFieldObject.length > 0) {
                                            _.each(customFieldObject[0].usedFields, function (innerHierarchyCustomFieldVal) {
                                                var tablename = innerHierarchyCustomFieldVal.split(".")[0];
                                                if (facttables.indexOf(tablename) != -1) {
                                                    yfacttables.push(tablename);
                                                    ycount++;
                                                }
                                                else {
                                                    dimensionFound = true;
                                                }
                                                arrays.push(multifactentities[tablename]);
                                                actualtablenames.push(tablename);
                                            });
                                        } else {
                                            var tablename = innerhierarchy.columnName.split(".")[0];
                                            if (facttables.indexOf(tablename) != -1) {
                                                yfacttables.push(tablename);
                                                ycount++;
                                            }
                                            else {
                                                dimensionFound = true;
                                            }
                                            arrays.push(multifactentities[tablename]);
                                            actualtablenames.push(tablename);
                                        }
                                    } else {
                                        var tablename = innerhierarchy.columnName.split(".")[0];
                                        if (facttables.indexOf(tablename) != -1) {
                                            yfacttables.push(tablename);
                                            ycount++;
                                        }
                                        else {
                                            dimensionFound = true;
                                        }
                                        arrays.push(multifactentities[tablename]);
                                        actualtablenames.push(tablename);
                                    }
                                });
                            }
                        } else {
                            var tablename = hierarchy.columnName.split(".")[0];
                            if (facttables.indexOf(tablename) != -1) {
                                yfacttables.push(tablename);
                                ycount++;
                            }
                            else {
                                dimensionFound = true;
                            }
                            arrays.push(multifactentities[tablename]);
                            actualtablenames.push(tablename);
                        }
                    });
                }
            }
            if (custommeasurefield != undefined && custommeasurefield.length > 0) {
                var customMeassureObj = $.grep(custommeasurefield, function (e) { return e.name == name });
                if (customMeassureObj != undefined && customMeassureObj.length > 0 && customMeassureObj[0].usedFields != undefined) {
                    _.each(customMeassureObj[0].usedFields.split(","), function (meassure) {
                        if (customFields != null && customFields != undefined) {
                            var customFieldObject = $.grep(customFields, function (e) { return e.name == meassure });
                            if (customFieldObject != undefined && customFieldObject.length > 0) {
                                _.each(customFieldObject[0].usedFields, function (customFieldObj) {
                                    var tablename = customFieldObj.split(".")[0];
                                    if (facttables.indexOf(tablename) != -1) {
                                        yfacttables.push(tablename);
                                        ycount++;
                                    } else {
                                        dimensionFound = true;
                                    }
                                    arrays.push(multifactentities[tablename]);
                                    actualtablenames.push(tablename);
                                });
                            } else {
                                var tablename = meassure.split(".")[0];
                                if (facttables.indexOf(tablename) != -1) {
                                    yfacttables.push(tablename);
                                    ycount++;
                                } else {
                                    dimensionFound = true;
                                }
                                arrays.push(multifactentities[tablename]);
                                actualtablenames.push(tablename);
                            }
                        } else {
                            var tablename = meassure.split(".")[0];
                            if (facttables.indexOf(tablename) != -1) {
                                yfacttables.push(tablename);
                                ycount++;
                            }
                            arrays.push(multifactentities[tablename]);
                            actualtablenames.push(tablename);
                        }

                    });
                }
            }
        });

        _.each($('.xaxisattributes #itemdisplay'), function (element) {
            var name = $(element).attr("data-name");
            var dataType = $(element).attr("data-type");
            var tablename = name.split(".")[0];
            if (facttables.indexOf(tablename) != -1) {
                xfacttables = tablename;
            }
            var customFields = JSON.parse(sessionStorage.getItem("customFields"));
            if (customFields != null && customFields != undefined) {
                var customFieldObject = $.grep(customFields, function (e) { return e.name == name });
                if (customFieldObject != undefined && customFieldObject.length > 0) {
                    _.each(customFieldObject[0].usedFields, function (hierarchy) {
                        var tablename = hierarchy.split(".")[0];
                        if (facttables.indexOf(tablename) != -1) {
                            xfacttables = tablename;
                        }
                        arrays.push(multifactentities[tablename]);
                        actualtablenames.push(tablename);
                    });
                } else if (dataType != undefined && dataType != 'customrange' && dataType != "customhierarchy") {
                    var tablename = name.split(".")[0];
                    if (facttables.indexOf(tablename) != -1) {
                        xfacttables = tablename;
                    }
                    arrays.push(multifactentities[tablename]);
                    actualtablenames.push(tablename);
                }
            } else if (dataType != undefined && dataType != 'customrange' && dataType != "customhierarchy") {
                var tablename = name.split(".")[0];
                if (facttables.indexOf(tablename) != -1) {
                    xfacttables = tablename;
                }
                arrays.push(multifactentities[tablename]);
                actualtablenames.push(tablename);
            }

            if (dataType != undefined && dataType == 'customrange') {
                var rangeObject = $.grep(range, function (e) { return e.name == name });
                if (rangeObject != undefined && rangeObject.length > 0) {
                    var customFields = JSON.parse(sessionStorage.getItem("customFields"));
                    if (customFields != null && customFields != undefined) {
                        var customFieldObject = $.grep(customFields, function (e) { return e.name == rangeObject[0]["xAxisName"][0].columnName });
                        if (customFieldObject != undefined && customFieldObject.length > 0) {
                            _.each(customFieldObject[0].usedFields, function (hierarchy) {
                                var crusedname = hierarchy;
                                var tablename = crusedname.split(".")[0];
                                if (facttables.indexOf(tablename) != -1) {
                                    xfacttables = tablename;
                                }
                                arrays.push(multifactentities[tablename]);
                                actualtablenames.push(tablename);
                            });
                        } else {
                            var crusedname = rangeObject[0]["xAxisName"][0].columnName;
                            var tablename = crusedname.split(".")[0];
                            if (facttables.indexOf(tablename) != -1) {
                                xfacttables = tablename;
                            }
                            arrays.push(multifactentities[tablename]);
                            actualtablenames.push(tablename);
                        }
                    } else {
                        var crusedname = rangeObject[0]["xAxisName"][0].columnName;
                        var tablename = crusedname.split(".")[0];
                        if (facttables.indexOf(tablename) != -1) {
                            xfacttables = tablename;
                        }
                        arrays.push(multifactentities[tablename]);
                        actualtablenames.push(tablename);
                    }
                }
            }

            if (dataType != undefined && dataType == 'customhierarchy') {
                var hierarchy = $.grep(customhierarchy, function (e) { return e.name == name });
                if (hierarchy != undefined) {
                    _.each(hierarchy[0].hierarchelist, function (hierar) {
                        var crusedname = hierar.columnName;
                        var customFields = JSON.parse(sessionStorage.getItem("customFields"));
                        if (customFields != null && customFields != undefined) {
                            var customFieldObject = $.grep(customFields, function (e) { return e.name == crusedname });
                            if (customFieldObject != undefined && customFieldObject.length > 0) {
                                _.each(customFieldObject[0].usedFields, function (hierarchy) {
                                    var crusedname = hierarchy;
                                    var tablename = crusedname.split(".")[0];
                                    if (facttables.indexOf(tablename) != -1) {
                                        xfacttables = tablename;
                                    }
                                    arrays.push(multifactentities[tablename]);
                                    actualtablenames.push(tablename);
                                });
                            } else {
                                var tablename = crusedname.split(".")[0];
                                if (facttables.indexOf(tablename) != -1) {
                                    xfacttables = tablename;
                                }
                                arrays.push(multifactentities[tablename]);
                                actualtablenames.push(tablename);
                            }
                        } else {
                            var tablename = crusedname.split(".")[0];
                            if (facttables.indexOf(tablename) != -1) {
                                xfacttables = tablename;
                            }
                            arrays.push(multifactentities[tablename]);
                            actualtablenames.push(tablename);
                        }
                    });
                }
            }
        });

        _.each($('.zaxisattributes .singleliitems'), function (element) {
            var name = $(element).attr("data-name");
            var dataType = $(element).attr("data-type");
            var tablename = name.split(".")[0];
            if (facttables.indexOf(tablename) != -1) {
                xfacttables = tablename;
            }
            var customFields = JSON.parse(sessionStorage.getItem("customFields"));
            if (customFields != null && customFields != undefined) {
                var customFieldObject = $.grep(customFields, function (e) { return e.name == name });
                if (customFieldObject != undefined && customFieldObject.length > 0) {
                    _.each(customFieldObject[0].usedFields, function (hierarchy) {
                        var tablename = hierarchy.split(".")[0];
                        if (facttables.indexOf(tablename) != -1) {
                            xfacttables = tablename;
                        }
                        arrays.push(multifactentities[tablename]);
                        actualtablenames.push(tablename);
                    });
                } else {
                    var tablename = name.split(".")[0];
                    if (facttables.indexOf(tablename) != -1) {
                        xfacttables = tablename;
                    }
                    arrays.push(multifactentities[tablename]);
                    actualtablenames.push(tablename);
                }
            } else {
                var tablename = name.split(".")[0];
                if (facttables.indexOf(tablename) != -1) {
                    xfacttables = tablename;
                }
                arrays.push(multifactentities[tablename]);
                actualtablenames.push(tablename);
            }
        });
        _.each($('.legendAttributes #legenditem'), function (element) {
            var name = $(element).attr("data-name");
            var dataType = $(element).attr("data-type");
            var tablename = name.split(".")[0];
            if (facttables.indexOf(tablename) != -1) {
                xfacttables = tablename;
            }
            var customFields = JSON.parse(sessionStorage.getItem("customFields"));
            if (customFields != null && customFields != undefined) {
                var customFieldObject = $.grep(customFields, function (e) { return e.name == name });
                if (customFieldObject != undefined && customFieldObject.length > 0) {
                    _.each(customFieldObject[0].usedFields, function (hierarchy) {
                        var tablename = hierarchy.split(".")[0];
                        if (facttables.indexOf(tablename) != -1) {
                            xfacttables = tablename;
                        }
                        arrays.push(multifactentities[tablename]);
                        actualtablenames.push(tablename);
                    });
                } else if (dataType != undefined && dataType != 'customrange' && dataType != "customhierarchy") {
                    var tablename = name.split(".")[0];
                    if (facttables.indexOf(tablename) != -1) {
                        xfacttables = tablename;
                    }
                    arrays.push(multifactentities[tablename]);
                    actualtablenames.push(tablename);
                }
            } else if (dataType != undefined && dataType != 'customrange' && dataType != "customhierarchy") {
                var tablename = name.split(".")[0];
                if (facttables.indexOf(tablename) != -1) {
                    xfacttables = tablename;
                }
                arrays.push(multifactentities[tablename]);
                actualtablenames.push(tablename);
            }
        });
        _.each($('.metricdatefieldsAttributes #metricitem'), function (element) {
            var name = $(element).attr("data-name");
            var dataType = $(element).attr("data-type");
            var tablename = name.split(".")[0];
            if (facttables.indexOf(tablename) != -1) {
                xfacttables = tablename;
            }
            var customFields = JSON.parse(sessionStorage.getItem("customFields"));
            if (customFields != null && customFields != undefined) {
                var customFieldObject = $.grep(customFields, function (e) { return e.name == name });
                if (customFieldObject != undefined && customFieldObject.length > 0) {
                    _.each(customFieldObject[0].usedFields, function (hierarchy) {
                        var tablename = hierarchy.split(".")[0];
                        if (facttables.indexOf(tablename) != -1) {
                            xfacttables = tablename;
                        }
                        arrays.push(multifactentities[tablename]);
                        actualtablenames.push(tablename);
                    });
                } else if (dataType != undefined && dataType != 'customrange' && dataType != "customhierarchy") {
                    var tablename = name.split(".")[0];
                    if (facttables.indexOf(tablename) != -1) {
                        xfacttables = tablename;
                    }
                    arrays.push(multifactentities[tablename]);
                    actualtablenames.push(tablename);
                }
            } else if (dataType != undefined && dataType != 'customrange' && dataType != "customhierarchy") {
                var tablename = name.split(".")[0];
                if (facttables.indexOf(tablename) != -1) {
                    xfacttables = tablename;
                }
                arrays.push(multifactentities[tablename]);
                actualtablenames.push(tablename);
            }
        });
        _.each($(".referenceattributes .singleliitems"), function (element) {
            var name = $(element).attr("data-name");
            var dataType = $(element).attr("data-type");
            var tablename = name.split(".")[0];
            if (facttables.indexOf(tablename) != -1) {
                xfacttables = tablename;
            }
            if (dataType != undefined && dataType != 'customrange' && dataType != "customhierarchy" && dataType != "CustomLine") {
                var tablename = name.split(".")[0];
                if (facttables.indexOf(tablename) != -1) {
                    xfacttables = tablename;
                }
                arrays.push(multifactentities[tablename]);
                actualtablenames.push(tablename);
            }
        });
        _.each($(".sortingattributes .singleliitems"), function (element) {
            var name = $(element).attr("data-name");
            var dataType = $(element).attr("data-type");
            var tablename = name.split(".")[0];
            if (facttables.indexOf(tablename) != -1) {
                xfacttables = tablename;
            }
            if (dataType != undefined && dataType != 'customrange' && dataType != "customhierarchy") {
                var tablename = name.split(".")[0];
                if (facttables.indexOf(tablename) != -1) {
                    xfacttables = tablename;
                }
                arrays.push(multifactentities[tablename]);
                actualtablenames.push(tablename);
            }
        });
        _.each($(".customsolidgaugeattributes .singleliitems"), function (element) {
            var name = $(element).attr("data-name");
            var dataType = $(element).attr("data-type");
            var tablename = name.split(".")[0];
            if (facttables.indexOf(tablename) != -1) {
                xfacttables = tablename;
            }
            if (dataType != undefined && dataType != 'customrange' && dataType != "customhierarchy") {
                var tablename = name.split(".")[0];
                if (facttables.indexOf(tablename) != -1) {
                    xfacttables = tablename;
                }
                arrays.push(multifactentities[tablename]);
                actualtablenames.push(tablename);
            }
        });
        if (arrays.length > 0) {
            var result = arrays.shift().filter(function (v) {
                return arrays.every(function (a) {
                    return a.indexOf(v) !== -1;
                });
            });

            for (var index = 0; index < actualtablenames.length; index++) {
                if (result.indexOf(actualtablenames[index]) < 0) {
                    result.push(actualtablenames[index]);
                }
            }

            var tablenameTobeDisplayed = columnName.split(".")[0];
            if (result.indexOf(tablenameTobeDisplayed) < 0) {
                disable = true;
            }

            var fcount = [];
            _.each(facttables, function (ft) {
                if (yfacttables.indexOf(ft) != -1 && fcount.indexOf(ft) == -1) {
                    fcount.push(ft);
                }
            });
            yfacttables = fcount;

            if (xfacttables != undefined && facttables.indexOf(xfacttables) != -1 && yfacttables.length == 0) {
                if (xfacttables != tablenameTobeDisplayed && facttables.indexOf(tablenameTobeDisplayed) >= 0) {
                    disable = true;
                }
            } else if (xfacttables != undefined && facttables.indexOf(xfacttables) != -1 && yfacttables.length > 0) {
                if (xfacttables != tablenameTobeDisplayed && facttables.indexOf(tablenameTobeDisplayed) >= 0 && yfacttables.indexOf(tablenameTobeDisplayed) < 0) {
                    disable = true;
                }
            } else if (yfacttables.length == 1 && facttables.indexOf(tablenameTobeDisplayed) >= 0 && yfacttables.indexOf(tablenameTobeDisplayed) == -1 && isFrom == "xdropdown") {
                disable = true;
            } else if (yfacttables.length == 1 && facttables.indexOf(tablenameTobeDisplayed) >= 0 && yfacttables.indexOf(tablenameTobeDisplayed) == -1 && isFrom == "zdropdown") {
                disable = true;
            } else if (yfacttables.length > 1 && facttables.indexOf(tablenameTobeDisplayed) >= 0 && isFrom == "xdropdown") {
                disable = true;
            } else if (yfacttables.length > 1 && facttables.indexOf(tablenameTobeDisplayed) >= 0 && isFrom == "zdropdown") {
                disable = true;
            } else if (yfacttables.length > 1 && facttables.indexOf(tablenameTobeDisplayed) == -1 && isFrom == "ydropdown") {
                disable = true;
            } else if (yfacttables.length == 1 && ycount == 1 && yselection > 1 && yfacttables.indexOf(tablenameTobeDisplayed) == -1 && facttables.indexOf(tablenameTobeDisplayed) >= 0 && isFrom == "ydropdown") {
                disable = true;
            }
            else if (yfacttables.length == 1 && ycount > 1 && yselection > 1 && yfacttables.indexOf(tablenameTobeDisplayed) == -1 && facttables.indexOf(tablenameTobeDisplayed) >= 0 && dimensionFound == true && isFrom == "ydropdown") {
                disable = true;
            }
        }
        return disable;
    },
    getAllowedEntitiesForInstantFilters: function (multifactentities, columnName, customfunction, yaxisNameArray, xaxisNameArray, factables, isFrom) {
        var arrays = [];
        var actualtablenames = [];
        var disable = false;
        var facttables = [];
        var yfacttables = [];
        var xfacttables;
        var ycount = 0;
        var yselection = 0;
        if (factables != undefined) {
            facttables = factables;
        }
        if (yaxisNameArray != undefined) {
            _.each(yaxisNameArray, function (val) {
                yselection++;
                var name = val
                var hasMatch = false;
                var customFields = JSON.parse(sessionStorage.getItem("customFields"));
                if (customFields != null && customFields != undefined) {
                    var customFieldObject = $.grep(customFields, function (e) { return e.name == name });
                    if (customFieldObject != undefined && customFieldObject.length > 0) {
                        _.each(customFieldObject[0].usedFields, function (hierarchy) {
                            var tablename = hierarchy.split(".")[0];
                            if (facttables.indexOf(tablename) != -1) {
                                yfacttables.push(tablename);
                                ycount++;
                            }
                            arrays.push(multifactentities[tablename]);
                            actualtablenames.push(tablename);
                            hasMatch = true;
                        });
                    }
                }
                if (custommeasurehierarchyArray != undefined && custommeasurehierarchyArray.length > 0) {
                    var meassureHierarchyObj = $.grep(custommeasurehierarchyArray, function (e) { return e.name == name });
                    if (meassureHierarchyObj != undefined && meassureHierarchyObj.length > 0 && meassureHierarchyObj[0].hierarchelist != undefined) {
                        _.each(meassureHierarchyObj[0].hierarchelist, function (hierarchy) {
                            if (customFields != null && customFields != undefined && hierarchy.dataType != undefined && hierarchy.dataType != 'custommeasurehierarchy') {
                                var customFieldObject = $.grep(customFields, function (e) { return e.name == hierarchy.columnName });
                                if (customFieldObject != undefined && customFieldObject.length > 0) {
                                    _.each(customFieldObject[0].usedFields, function (customFieldVal) {
                                        var tablename = customFieldVal.split(".")[0];
                                        if (facttables.indexOf(tablename) != -1) {
                                            yfacttables.push(tablename);
                                            ycount++;
                                        }
                                        arrays.push(multifactentities[tablename]);
                                        actualtablenames.push(tablename);
                                    });
                                } else {
                                    var tablename = hierarchy.columnName.split(".")[0];
                                    if (facttables.indexOf(tablename) != -1) {
                                        yfacttables.push(tablename);
                                        ycount++;
                                    }
                                    arrays.push(multifactentities[tablename]);
                                    actualtablenames.push(tablename);
                                }
                            } else if (hierarchy.dataType != undefined && hierarchy.dataType === 'custommeasurehierarchy') {
                                var innermeassureHierarchyObj = $.grep(custommeasurehierarchyArray, function (e) { return e.name == hierarchy.columnName });
                                if (innermeassureHierarchyObj != undefined && innermeassureHierarchyObj.length > 0 && innermeassureHierarchyObj[0].hierarchelist != undefined) {
                                    _.each(innermeassureHierarchyObj[0].hierarchelist, function (innerhierarchy) {
                                        if (customFields != null && customFields != undefined) {
                                            var customFieldObject = $.grep(customFields, function (e) { return e.name == innerhierarchy.columnName });
                                            if (customFieldObject != undefined && customFieldObject.length > 0) {
                                                _.each(customFieldObject[0].usedFields, function (innerHierarchyCustomFieldVal) {
                                                    var tablename = innerHierarchyCustomFieldVal.split(".")[0];
                                                    if (facttables.indexOf(tablename) != -1) {
                                                        yfacttables.push(tablename);
                                                        ycount++;
                                                    }
                                                    arrays.push(multifactentities[tablename]);
                                                    actualtablenames.push(tablename);
                                                });
                                            } else {
                                                var tablename = innerhierarchy.columnName.split(".")[0];
                                                if (facttables.indexOf(tablename) != -1) {
                                                    yfacttables.push(tablename);
                                                    ycount++;
                                                }
                                                arrays.push(multifactentities[tablename]);
                                                actualtablenames.push(tablename);
                                            }
                                        } else {
                                            var tablename = innerhierarchy.columnName.split(".")[0];
                                            if (facttables.indexOf(tablename) != -1) {
                                                yfacttables.push(tablename);
                                                ycount++;
                                            }
                                            arrays.push(multifactentities[tablename]);
                                            actualtablenames.push(tablename);
                                        }
                                    });
                                }
                            } else {
                                var tablename = hierarchy.columnName.split(".")[0];
                                if (facttables.indexOf(tablename) != -1) {
                                    yfacttables.push(tablename);
                                    ycount++;
                                }
                                arrays.push(multifactentities[tablename]);
                                actualtablenames.push(tablename);
                            }
                        });
                        hasMatch = true;
                    }
                }
                if (custommeasurefield != undefined && custommeasurefield.length > 0) {
                    var customMeassureObj = $.grep(custommeasurefield, function (e) { return e.name == name });
                    if (customMeassureObj != undefined && customMeassureObj.length > 0 && customMeassureObj[0].usedFields != undefined) {
                        _.each(customMeassureObj[0].usedFields.split(","), function (meassure) {
                            if (customFields != null && customFields != undefined) {
                                var customFieldObject = $.grep(customFields, function (e) { return e.name == meassure });
                                if (customFieldObject != undefined && customFieldObject.length > 0) {
                                    _.each(customFieldObject[0].usedFields, function (customFieldObj) {
                                        var tablename = customFieldObj.split(".")[0];
                                        if (facttables.indexOf(tablename) != -1) {
                                            yfacttables.push(tablename);
                                            ycount++;
                                        }
                                        arrays.push(multifactentities[tablename]);
                                        actualtablenames.push(tablename);
                                    });
                                }
                                else {
                                    var tablename = meassure.split(".")[0];
                                    if (facttables.indexOf(tablename) != -1) {
                                        yfacttables.push(tablename);
                                        ycount++;
                                    }
                                    arrays.push(multifactentities[tablename]);
                                    actualtablenames.push(tablename);
                                }
                            } else {
                                var tablename = meassure.split(".")[0];
                                if (facttables.indexOf(tablename) != -1) {
                                    yfacttables.push(tablename);
                                    ycount++;
                                }
                                arrays.push(multifactentities[tablename]);
                                actualtablenames.push(tablename);
                            }

                        });
                        hasMatch = true;
                    }
                }
                if (!hasMatch) {
                    var tablename = name.split(".")[0];
                    if (facttables.indexOf(tablename) != -1) {
                        yfacttables.push(tablename);
                        ycount++;
                    }
                    arrays.push(multifactentities[tablename]);
                    actualtablenames.push(tablename);
                }
            });
        }
        if (xaxisNameArray != undefined && xaxisNameArray != "Measure Names") {
            _.each(xaxisNameArray, function (val) {
                var name = val
                var tablename = name.split(".")[0];
                var customFields = JSON.parse(sessionStorage.getItem("customFields"));
                if (customFields != null && customFields != undefined) {
                    var customFieldObject = $.grep(customFields, function (e) { return e.name == name });
                    if (customFieldObject != undefined && customFieldObject.length > 0) {
                        _.each(customFieldObject[0].usedFields, function (hierarchy) {
                            var tablename = hierarchy.split(".")[0];
                            if (facttables.indexOf(tablename) != -1) {
                                xfacttables = tablename;
                            }
                            arrays.push(multifactentities[tablename]);
                            actualtablenames.push(tablename);
                        });
                    } else {
                        if (facttables.indexOf(tablename) != -1) {
                            xfacttables = tablename;
                        }
                        arrays.push(multifactentities[tablename]);
                        actualtablenames.push(tablename);
                    }
                } else {
                    if (facttables.indexOf(tablename) != -1) {
                        xfacttables = tablename;
                    }
                    arrays.push(multifactentities[tablename]);
                    actualtablenames.push(tablename);
                }
            });
        }
        if (arrays.length > 0) {
            var result = arrays.shift().filter(function (v) {
                return arrays.every(function (a) {
                    return a.indexOf(v) !== -1;
                });
            });

            for (var index = 0; index < actualtablenames.length; index++) {
                if (result.indexOf(actualtablenames[index]) < 0) {
                    result.push(actualtablenames[index]);
                }
            }

            var tablenameTobeDisplayed = columnName.split(".")[0];
            if (result.indexOf(tablenameTobeDisplayed) < 0) {
                disable = true;
            }

            var fcount = [];
            _.each(facttables, function (ft) {
                if (yfacttables.indexOf(ft) != -1 && fcount.indexOf(ft) == -1) {
                    fcount.push(ft);
                }
            });
            yfacttables = fcount;

            if (xfacttables != undefined && facttables.indexOf(xfacttables) != -1 && yfacttables.length == 0 && isFrom != undefined) {
                if (xfacttables != tablenameTobeDisplayed && facttables.indexOf(tablenameTobeDisplayed) >= 0) {
                    disable = true;
                }
            } else if (xfacttables != undefined && facttables.indexOf(xfacttables) != -1 && yfacttables.length > 0 && isFrom != undefined) {
                if (xfacttables != tablenameTobeDisplayed && facttables.indexOf(tablenameTobeDisplayed) >= 0 && yfacttables.indexOf(tablenameTobeDisplayed) < 0) {
                    disable = true;
                }
            } else if (yfacttables.length == 1 && facttables.indexOf(tablenameTobeDisplayed) >= 0 && yfacttables.indexOf(tablenameTobeDisplayed) == -1 && isFrom != undefined && isFrom == "xdropdown") {
                disable = true;
            } else if (yfacttables.length == 1 && facttables.indexOf(tablenameTobeDisplayed) >= 0 && yfacttables.indexOf(tablenameTobeDisplayed) == -1 && isFrom != undefined && isFrom == "zdropdown") {
                disable = true;
            } else if (yfacttables.length == 1 && facttables.indexOf(tablenameTobeDisplayed) >= 0 && yfacttables.indexOf(tablenameTobeDisplayed) == -1 && yselection > 1 && isFrom != undefined && isFrom == "ydropdown") {
                disable = true;
            } else if (yfacttables.length > 1 && facttables.indexOf(tablenameTobeDisplayed) >= 0 && isFrom != undefined && isFrom == "xdropdown") {
                disable = true;
            } else if (yfacttables.length > 1 && facttables.indexOf(tablenameTobeDisplayed) == -1 && isFrom != undefined && isFrom == "ydropdown") {
                disable = true;
            }
        }
        return disable;
    },
    getAllowedEntiesForSummaryTable: function (multifactentities, customfunction, factables, isFrom) {
        var arrays = [];
        var actualtablenames = [];
        var facttables = [];
        if (factables != undefined) {
            facttables = factables;
        }
        var aggfacttables = [];
        var textfacttables;
        var grpfacttables;
        var textcount = 0;
        var aggcount = 0;
        var aggfactcount = 0;
        var customFields = JSON.parse(sessionStorage.getItem("customFields"));
        _.each($(".summarytabledisplayvalues"), function (element) {
            aggcount++;
            var name = $(element).attr("data-child");
            var tablename = name.split(".")[0];
            var hasMatch = false;
            if (facttables.indexOf(tablename) != -1) {
                aggfacttables.push(tablename);
                aggfactcount++;
            }
            if (customFields != null && customFields != undefined) {
                var customFieldObject = $.grep(customFields, function (e) { return e.name == name });
                if (customFieldObject != undefined && customFieldObject.length > 0) {
                    _.each(customFieldObject[0].usedFields, function (hierarchy) {
                        var tablename = hierarchy.split(".")[0];
                        if (facttables.indexOf(tablename) != -1) {
                            aggfacttables.push(tablename);
                            aggfactcount++;
                        }
                        arrays.push(multifactentities[tablename]);
                        actualtablenames.push(tablename);
                    });
                    hasMatch = true;
                }
            }
            if (custommeasurefield != undefined && custommeasurefield.length > 0) {
                var customMeassureObj = $.grep(custommeasurefield, function (e) { return e.name == name });
                if (customMeassureObj != undefined && customMeassureObj.length > 0 && customMeassureObj[0].usedFields != undefined) {
                    _.each(customMeassureObj[0].usedFields.split(","), function (meassure) {
                        if (customFields != null && customFields != undefined) {
                            var customFieldObject = $.grep(customFields, function (e) { return e.name == meassure });
                            if (customFieldObject != undefined && customFieldObject.length > 0) {
                                _.each(customFieldObject[0].usedFields, function (customFieldObj) {
                                    var tablename = customFieldObj.split(".")[0];
                                    if (facttables.indexOf(tablename) != -1) {
                                        aggfacttables.push(tablename);
                                        aggfactcount++;
                                    }
                                    arrays.push(multifactentities[tablename]);
                                    actualtablenames.push(tablename);
                                });
                            }
                            else {
                                var tablename = meassure.split(".")[0];
                                if (facttables.indexOf(tablename) != -1) {
                                    aggfacttables.push(tablename);
                                    aggfactcount++;
                                }
                                arrays.push(multifactentities[tablename]);
                                actualtablenames.push(tablename);
                            }
                        } else {
                            var tablename = meassure.split(".")[0];
                            if (facttables.indexOf(tablename) != -1) {
                                aggfacttables.push(tablename);
                                aggfactcount++;
                            }
                            arrays.push(multifactentities[tablename]);
                            actualtablenames.push(tablename);
                        }

                    });
                    hasMatch = true;
                }
            }
            if (!hasMatch) {
                arrays.push(multifactentities[tablename]);
                actualtablenames.push(tablename);
            }
        });

        _.each($(".summarytable-row  .singleliitems"), function (element) {
            textcount++;
            var name = $(element).attr("data-child");
            var tablename = name.split(".")[0];
            var hasMatch = false
            if (facttables.indexOf(tablename) != -1) {
                textfacttables = tablename;
            }
            if (customFields != null && customFields != undefined) {
                var customFieldObject = $.grep(customFields, function (e) { return e.name == name });
                if (customFieldObject != undefined && customFieldObject.length > 0) {
                    _.each(customFieldObject[0].usedFields, function (hierarchy) {
                        var tablename = hierarchy.split(".")[0];
                        if (facttables.indexOf(tablename) != -1) {
                            textfacttables = tablename;
                        }
                        arrays.push(multifactentities[tablename]);
                        actualtablenames.push(tablename);
                    });
                    hasMatch = true;
                }
            }
            if (customhierarchy != undefined && customhierarchy.length > 0) {
                var customHierarchyObj = $.grep(customhierarchy, function (e) { return e.name == name });
                if (customHierarchyObj != undefined && customHierarchyObj.length > 0 && customHierarchyObj[0].hierarchelist != undefined) {
                    _.each(customHierarchyObj[0].hierarchelist, function (hierarchy) {
                        if (customFields != null && customFields != undefined) {
                            var customFieldObject = $.grep(customFields, function (e) { return e.name == hierarchy.columnName });
                            if (customFieldObject != undefined && customFieldObject.length > 0) {
                                _.each(customFieldObject[0].usedFields, function (customFieldVal) {
                                    var tablename = customFieldVal.split(".")[0];
                                    if (facttables.indexOf(tablename) != -1) {
                                        textfacttables = tablename;
                                    }
                                    arrays.push(multifactentities[tablename]);
                                    actualtablenames.push(tablename);
                                });
                            } else {
                                var tablename = hierarchy.columnName.split(".")[0];
                                if (facttables.indexOf(tablename) != -1) {
                                    textfacttables = tablename;
                                }
                                arrays.push(multifactentities[tablename]);
                                actualtablenames.push(tablename);
                            }
                        } else {
                            var tablename = hierarchy.columnName.split(".")[0];
                            if (facttables.indexOf(tablename) != -1) {
                                textfacttables = tablename;
                            }
                            arrays.push(multifactentities[tablename]);
                            actualtablenames.push(tablename);
                        }
                    });
                    hasMatch = true;
                }
            }
            if (!hasMatch) {
                arrays.push(multifactentities[tablename]);
                actualtablenames.push(tablename);
            }
        });

        _.each($(".summarytable-groupby  .singleliitems"), function (element) {
            var name = $(element).attr("data-child");
            var tablename = name.split(".")[0];
            if (facttables.indexOf(tablename) != -1) {
                grpfacttables = tablename;
            }
            var hasMatch = false
            if (customFields != null && customFields != undefined) {
                var customFieldObject = $.grep(customFields, function (e) { return e.name == name });
                if (customFieldObject != undefined && customFieldObject.length > 0) {
                    _.each(customFieldObject[0].usedFields, function (hierarchy) {
                        var tablename = hierarchy.split(".")[0];
                        if (facttables.indexOf(tablename) != -1) {
                            grpfacttables = tablename;
                        }
                        arrays.push(multifactentities[tablename]);
                        actualtablenames.push(tablename);
                    });
                    hasMatch = true;
                }
            }
            if (customhierarchy != undefined && customhierarchy.length > 0) {
                var customHierarchyObj = $.grep(customhierarchy, function (e) { return e.name == name });
                if (customHierarchyObj != undefined && customHierarchyObj.length > 0 && customHierarchyObj[0].hierarchelist != undefined) {
                    _.each(customHierarchyObj[0].hierarchelist, function (hierarchy) {
                        if (customFields != null && customFields != undefined) {
                            var customFieldObject = $.grep(customFields, function (e) { return e.name == hierarchy.columnName });
                            if (customFieldObject != undefined && customFieldObject.length > 0) {
                                _.each(customFieldObject[0].usedFields, function (customFieldVal) {
                                    var tablename = customFieldVal.split(".")[0];
                                    if (facttables.indexOf(tablename) != -1) {
                                        grpfacttables = tablename;
                                    }
                                    arrays.push(multifactentities[tablename]);
                                    actualtablenames.push(tablename);
                                });
                            } else {
                                var tablename = hierarchy.columnName.split(".")[0];
                                if (facttables.indexOf(tablename) != -1) {
                                    grpfacttables = tablename;
                                }
                                arrays.push(multifactentities[tablename]);
                                actualtablenames.push(tablename);
                            }
                        } else {
                            var tablename = hierarchy.columnName.split(".")[0];
                            if (facttables.indexOf(tablename) != -1) {
                                grpfacttables = tablename;
                            }
                            arrays.push(multifactentities[tablename]);
                            actualtablenames.push(tablename);
                        }
                    });
                    hasMatch = true;
                }
            }
            if (!hasMatch) {
                arrays.push(multifactentities[tablename]);
                actualtablenames.push(tablename);
            }
        });
        if (arrays.length > 0) {
            var result = arrays.shift().filter(function (v) {
                return arrays.every(function (a) {
                    return a.indexOf(v) !== -1;
                });
            });

            for (var index = 0; index < actualtablenames.length; index++) {
                if (result.indexOf(actualtablenames[index]) < 0) {
                    result.push(actualtablenames[index]);
                }
            }

            var fcount = [];
            _.each(facttables, function (ft) {
                if (aggfacttables.indexOf(ft) != -1 && fcount.indexOf(ft) == -1) {
                    fcount.push(ft);
                }
            });

            if (fcount.length > 1 && isFrom != "aggdrp") {
                _.each(result, function (allowed) {
                    _.each(facttables, function (ft) {
                        var index = result.indexOf(ft);
                        if (index > -1) {
                            result.splice(index, 1);
                        }
                    });
                });
            } else if (fcount.length == 1 && isFrom != "aggdrp") {
                var fact = aggfacttables[0];
                _.each(result, function (allowed) {
                    _.each(facttables, function (ft) {
                        if (fact != ft) {
                            var index = result.indexOf(ft);
                            if (index > -1) {
                                result.splice(index, 1);
                            }
                        }
                    });
                });
            } else if (fcount.length > 1 && aggcount > 1 && isFrom == "aggdrp") {
                _.each(result, function (allowed) {
                    if (facttables.indexOf(allowed) == -1) {
                        var index = result.indexOf(allowed);
                        delete result[index];
                    }
                });
            } else if (fcount.length == 1 && aggfactcount == 1 && aggcount > 1 && isFrom == "aggdrp") {
                _.each(result, function (allowed) {
                    if (facttables.indexOf(allowed) != -1 && fcount.indexOf(allowed) == -1) {
                        var index = result.indexOf(allowed);
                        delete result[index];
                    }
                });

            } else if (fcount.length == 1 && aggcount > 1 && (aggfactcount != aggcount) && isFrom == "aggdrp") {
                _.each(result, function (allowed) {
                    if (facttables.indexOf(allowed) != -1 && fcount.indexOf(allowed) == -1) {
                        var index = result.indexOf(allowed);
                        delete result[index];
                    }
                });

            }

            if (textfacttables != undefined) {
                var fact = textfacttables;
                _.each(result, function (allowed) {
                    _.each(facttables, function (ft) {
                        if (fact != ft) {
                            var index = result.indexOf(ft);
                            if (index > -1) {
                                result.splice(index, 1);
                            }
                        }
                    });
                });
            }

            if (grpfacttables != undefined) {
                var fact = grpfacttables;
                _.each(result, function (allowed) {
                    _.each(facttables, function (ft) {
                        if (fact != ft) {
                            var index = result.indexOf(ft);
                            if (index > -1) {
                                result.splice(index, 1);
                            }
                        }
                    });
                });
            }

            return result;
        }
        var tempArray = [];
        return tempArray;
    },
    getAllowedEntiesForPivotTable: function (multifactentities, customfunction, facttables) {
        var arrays = [];
        var actualtablenames = [];
        _.each($(".pivotvalues .singleliitems"), function (element) {
            var name = $(element).attr("data-child");
            var tablename = name.split(".")[0];
            var customFields = JSON.parse(sessionStorage.getItem("customFields"));
            if (customFields != null && customFields != undefined) {
                var customFieldObject = $.grep(customFields, function (e) { return e.name == name });
                if (customFieldObject != undefined && customFieldObject.length > 0) {
                    _.each(customFieldObject[0].usedFields, function (hierarchy) {
                        var tablename = hierarchy.split(".")[0];
                        arrays.push(multifactentities[tablename]);
                        actualtablenames.push(tablename);
                    });
                } else {
                    arrays.push(multifactentities[tablename]);
                    actualtablenames.push(tablename);
                }
            } else {
                arrays.push(multifactentities[tablename]);
                actualtablenames.push(tablename);
            }
        });
        _.each($(".pivotcolumn .singleliitems"), function (element) {
            var name = $(element).attr("data-child");
            var tablename = name.split(".")[0];
            var customFields = JSON.parse(sessionStorage.getItem("customFields"));
            if (customFields != null && customFields != undefined) {
                var customFieldObject = $.grep(customFields, function (e) { return e.name == name });
                if (customFieldObject != undefined && customFieldObject.length > 0) {
                    _.each(customFieldObject[0].usedFields, function (hierarchy) {
                        var tablename = hierarchy.split(".")[0];
                        arrays.push(multifactentities[tablename]);
                        actualtablenames.push(tablename);
                    });
                } else {
                    arrays.push(multifactentities[tablename]);
                    actualtablenames.push(tablename);
                }
            } else {
                arrays.push(multifactentities[tablename]);
                actualtablenames.push(tablename);
            }

        });

        _.each($(".pivotrow .singleliitems"), function (element) {
            var name = $(element).attr("data-child");
            var tablename = name.split(".")[0];
            var customFields = JSON.parse(sessionStorage.getItem("customFields"));
            if (customFields != null && customFields != undefined) {
                var customFieldObject = $.grep(customFields, function (e) { return e.name == name });
                if (customFieldObject != undefined && customFieldObject.length > 0) {
                    _.each(customFieldObject[0].usedFields, function (hierarchy) {
                        var tablename = hierarchy.split(".")[0];
                        arrays.push(multifactentities[tablename]);
                        actualtablenames.push(tablename);
                    });
                } else {
                    arrays.push(multifactentities[tablename]);
                    actualtablenames.push(tablename);
                }
            } else {
                arrays.push(multifactentities[tablename]);
                actualtablenames.push(tablename);
            }
        });
        if (arrays.length > 0) {
            var result = arrays.shift().filter(function (v) {
                return arrays.every(function (a) {
                    if (a != undefined)
                        return a.indexOf(v) !== -1;
                });
            });

            for (var index = 0; index < actualtablenames.length; index++) {
                if (result.indexOf(actualtablenames[index]) < 0) {
                    result.push(actualtablenames[index]);
                }
            }

            var previoustablename;
            _.each(facttables, function (ft) {
                if (actualtablenames.indexOf(ft) != -1) {
                    previoustablename = ft;
                }
            });

            if (previoustablename != undefined) {
                _.each(facttables, function (ft) {
                    if (ft != previoustablename) {
                        _.each(result, function (allowed) {
                            var index = result.indexOf(ft);
                            if (index > -1) {
                                result.splice(index, 1);
                            }
                        });
                    }
                });
            }

            return result;
        }
        var tempArray = [];
        return tempArray;
    },
    getMeassurehierachyMap: function () {
        meassurehierachyNamesMap = {};
        if (custommeasurehierarchyArray != undefined && custommeasurehierarchyArray.length > 0) {
            _.each(custommeasurehierarchyArray, function (val) {
                meassurehierachyNamesMap[val.name] = [];
                _.each(val.hierarchelist, function (val1) {
                    meassurehierachyNamesMap[val.name].push(val1.columnName.split(".")[0])
                })

            });
        }
        return meassurehierachyNamesMap;
    }, setDatefunctionsMap: function (datefunctionsMapvalue) {
        datefunctionsMap = datefunctionsMapvalue;
    },
    getDatefunctionsMap: function () {
        return datefunctionsMap;
    },
    setSmartinsightDataObj: function (smartinsightDataObjVal) {
        smartinsightDataObj = smartinsightDataObjVal;
    },
    getSmartinsightDataObj: function () {
        return smartinsightDataObj;
    },
    setImportreportdatamodelId: function (importReportdatamodelId) {
        importreportdatamodelId = importReportdatamodelId;
    },
    getImportreportdatamodelId: function () {
        return importreportdatamodelId;
    },
    getDummyDataForReport: function () {
        var dummydata = "[{\"axis\":[{\"tabId\":\"0\",\"xaxis\":[],\"chartnumber\":\"0\",\"orderType\":\"top\",\"tabName\":\"\",\"chartType\":\"bar\",\"colLimit\":20,\"drillDownAllFilter\":[],\"drillDownFilter\":[],\"yaxis\":[],\"summarytablehierarchyArray\":[],\"legendfilterallchart\":[]}]},{\"details\":{\"syncValuesObj\":{},\"folder\":\"\",\"storyboardtimezone\":\"\",\"syncConfigData\":\"{}\",\"name\":\"\",\"title\":\"Storyboard\",\"iscloned\":false,\"desc\":\"\"}},{\"filter\":[]},{\"grid\":[{\"num\":\"0\",\"x\":0,\"width\":5,\"y\":0,\"height\":6}]},{\"convertedvalues\":null},{\"customFields\":null},{\"stroyboardcolortheme\":\"\"},{\"customHierarchy\":\"[]\"},{\"customRange\":\"[]\"},{\"customMeasure\":\"[]\"},{\"customParameter\":\"[]\"},{\"customParameterFilters\":[]},{\"customMeasureHierarchy\":\"[]\"},{},{\"livedatasyncconfig\":\"{}\"},{\"timeZoneSettings\":\"{}\"},{\"auditMetaDataOptions\":\"{\\\"metaDataAudit\\\":0}\"},{\"isDrillDownAllenable\":true},{}]";
        return dummydata;
    },
    getDummyDataForTempReport: function (columns,multifact,facttables) {
        var custObject = "[{\"axis\":[{\"tabId\":\"0\",\"xaxis\":[],\"chartnumber\":\"0\",\"orderType\":\"top\",\"tabName\":\"\",\"chartType\":\"bar\",\"colLimit\":20,\"drillDownAllFilter\":[],\"drillDownFilter\":[],\"yaxis\":[],\"summarytablehierarchyArray\":[],\"legendfilterallchart\":[]}]},{\"details\":{\"syncValuesObj\":{},\"folder\":\"\",\"storyboardtimezone\":\"\",\"syncConfigData\":\"{}\",\"name\":\"\",\"title\":\"Storyboard\",\"iscloned\":false,\"desc\":\"\"}},{\"filter\":[]},{\"grid\":[{\"num\":\"0\",\"x\":0,\"width\":5,\"y\":0,\"height\":6}]},{\"convertedvalues\":null},{\"customFields\":null},{\"stroyboardcolortheme\":\"\"},{\"customHierarchy\":\"[]\"},{\"customRange\":\"[]\"},{\"customMeasure\":\"[]\"},{\"customParameter\":\"[]\"},{\"customParameterFilters\":[]},{\"customMeasureHierarchy\":\"[]\"},{},{\"livedatasyncconfig\":\"{}\"},{\"timeZoneSettings\":\"{}\"},{\"auditMetaDataOptions\":\"{\\\"metaDataAudit\\\":0}\"},{\"isDrillDownAllenable\":true},{}]";
        var xAxis = {};
        var rankingColumn = "";
        var xAxisArray=[];
        var title="";
        if(columns!=undefined && columns[0]!=undefined && multifact!=undefined && multifact=="true"){
            var res=$.grep(columns,function(e){
                return e.tableName==facttables[0];
            })
            rankingColumn=res[0].columnName;
            xAxis["columnName"] =  res[0].columnName;
            xAxis["displayName"] =  res[0].columnDisplayName;
            xAxis["dataType"] =  res[0].tableDisplayType;
            xAxis["aggregation"] =  "count";
            xAxis["tableName"]=res[0].tableName;
            xAxisArray.push(xAxis);
            title= res[0].columnDisplayName+" by "+res[0].columnDisplayName;
         }else if(columns!=undefined && columns[0]!=undefined){
            rankingColumn=columns[0].columnName;
            xAxis["columnName"] =  columns[0].columnName;
            xAxis["displayName"] =  columns[0].columnDisplayName;
            xAxis["dataType"] =  columns[0].tableDisplayType;
            xAxis["aggregation"] =  "count";
            xAxis["tableName"]=columns[0].tableName;
            xAxisArray.push(xAxis);
            title= columns[0].columnDisplayName+" by "+columns[0].columnDisplayName;
        }
        var rptdtls=JSON.parse(custObject);
        rptdtls[0].axis[0].rankingColumn=rankingColumn;
        rptdtls[0].axis[0].rankingColumnAggregation="count";
        rptdtls[0].axis[0].xaxis=xAxisArray;
        rptdtls[0].axis[0].yaxis=xAxisArray;
        custObject=JSON.stringify(rptdtls);
        var finalCustObject={};
        finalCustObject["custObject"]=custObject;
        finalCustObject["title"]=title;
        return finalCustObject;
    },
    setDrillthroughColumnNamesArray:function(drillthroughColumnNamesArray){
        drillthroughcheckcolumnsArray=drillthroughColumnNamesArray;
    },
    getDrillthroughColumnNamesArray:function(){
        return drillthroughcheckcolumnsArray;
    },
    addFormatting: function (n, abbr, decimals, seperator) {
        if (decimals == "0_1") {
            decimals = 1;
        } else if (decimals == "0_2") {
            decimals = 2;
        }
        if (n >= 0) {
            var newnumber = "";
            if (n < 1e3) {
                n = parseFloat(n)
                return n.toFixed(decimals);
            }

            if (abbr != undefined) {
                if (abbr.indexOf("K") > 0) {
                    if (seperator == "thousands2") {
                        var fnum = (n / 1e3).toFixed(decimals);
                        var num_parts = fnum.toString().split(".");
                        num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        newnumber = num_parts.join(".");
                        newnumber = newnumber + "K";
                    } else {
                        newnumber = +(n / 1e3).toFixed(decimals)
                        newnumber = newnumber + "K";
                    }

                }

                if (abbr.indexOf("M") > 0) {
                    if (newnumber.indexOf("K") < 0 || n >= 1e6) {
                        if (seperator == "thousands2") {
                            var fnum = (n / 1e6).toFixed(decimals);
                            var num_parts = fnum.toString().split(".");
                            num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            newnumber = num_parts.join(".");
                            newnumber = newnumber + "M";
                        } else {
                            newnumber = +(n / 1e6).toFixed(decimals)
                            newnumber = newnumber + "M";
                        }
                        //newnumber= +(n / 1e6).toFixed(decimals) + "M"
                    };
                }

                if (abbr.indexOf("B") > 0) {
                    if ((newnumber.indexOf("K") < 0 && newnumber.indexOf("M") < 0) || n >= 1e9) {
                        if (seperator == "thousands2") {
                            var fnum = (n / 1e9).toFixed(decimals);
                            var num_parts = fnum.toString().split(".");
                            num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            newnumber = num_parts.join(".");
                            newnumber = newnumber + "B";
                        } else {
                            newnumber = +(n / 1e9).toFixed(decimals)
                            newnumber = newnumber + "B";
                        }
                        //newnumber= +(n / 1e9).toFixed(decimals) + "B";
                    }
                }

                if (abbr.indexOf("T") > 0) {
                    if ((newnumber.indexOf("K") < 0 && newnumber.indexOf("M") < 0 && newnumber.indexOf("B") < 0) || n >= 1e12) {
                        if (seperator == "thousands2") {
                            var fnum = (n / 1e12).toFixed(decimals);
                            var num_parts = fnum.toString().split(".");
                            num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            newnumber = num_parts.join(".");
                            newnumber = newnumber + "T";
                        } else {
                            newnumber = +(n / 1e12).toFixed(decimals)
                            newnumber = newnumber + "T";
                        }
                        //newnumber= +(n / 1e12).toFixed(decimals) + "T";
                    }
                }
                n = newnumber;
                return n;
            }
            else {
                if (seperator == "thousands2") {
                    var fnum = n.toFixed(decimals);
                    var num_parts = fnum.toString().split(".");
                    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    n = num_parts.join(".");
                    var fnum_new = n;
                    return fnum_new;
                } else {
                    n = parseFloat(n)
                    var fnum = n.toFixed(decimals);
                    return fnum;
                }
            }
        }
        else {
            var newnumber = "";
            n = -1 * n
            if (n < 1e3) {
                return "-" + n.toFixed(decimals);
            }

            if (abbr != undefined) {
                if (abbr.indexOf("K") > 0) {
                    if (seperator == "thousands2") {
                        var fnum = (n / 1e3).toFixed(decimals);
                        var num_parts = fnum.toString().split(".");
                        num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        newnumber = num_parts.join(".");
                        newnumber = "-" + newnumber + "K";
                    } else {
                        newnumber = +(n / 1e3).toFixed(decimals)
                        newnumber = "-" + newnumber + "K";
                    }
                    //newnumber= "-"+(n / 1e3).toFixed(decimals) + "K";
                }
                if (abbr.indexOf("M") > 0) {
                    if (newnumber.indexOf("K") < 0 || n >= 1e6) {
                        if (seperator == "thousands2") {
                            var fnum = (n / 1e6).toFixed(decimals);
                            var num_parts = fnum.toString().split(".");
                            num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            newnumber = num_parts.join(".");
                            newnumber = "-" + newnumber + "M";
                        } else {
                            newnumber = +(n / 1e6).toFixed(decimals)
                            newnumber = "-" + newnumber + "M";
                        }
                        //newnumber= '-'+(n / 1e6).toFixed(decimals) + "M";
                    }
                }

                if (abbr.indexOf("B") > 0) {
                    if ((newnumber.indexOf("K") < 0 && newnumber.indexOf("M") < 0) || n >= 1e9) {
                        if (seperator == "thousands2") {
                            var fnum = (n / 1e9).toFixed(decimals);
                            var num_parts = fnum.toString().split(".");
                            num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            newnumber = num_parts.join(".");
                            newnumber = "-" + newnumber + "B";
                        } else {
                            newnumber = +(n / 1e9).toFixed(decimals)
                            newnumber = "-" + newnumber + "B";
                        }
                        //newnumber= '-'+(n / 1e9).toFixed(decimals) + "B";
                    }
                }

                if (abbr.indexOf("T") > 0) {
                    if ((newnumber.indexOf("K") < 0 && newnumber.indexOf("M") < 0 && newnumber.indexOf("B") < 0) || n >= 1e12) {
                        if (seperator == "thousands2") {
                            var fnum = (n / 1e12).toFixed(decimals);
                            var num_parts = fnum.toString().split(".");
                            num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            newnumber = num_parts.join(".");
                            newnumber = "-" + newnumber + "T";
                        } else {
                            newnumber = +(n / 1e12).toFixed(decimals)
                            newnumber = "-" + newnumber + "T";
                        }
                        //newnumber= '-'+(n / 1e12).toFixed(decimals) + "T";
                    }
                }
                n = newnumber;
                return n;
            }
            else {
                if (seperator == "thousands2") {
                    var fnum = n.toFixed(decimals);
                    var num_parts = fnum.toString().split(".");
                    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    n = num_parts.join(".");
                    var fnum_new = n;
                    return "-" + fnum_new;
                } else {
                    n = parseFloat(n)
                    var fnum = n.toFixed(decimals);
                    return '-' + fnum;
                }
            }
        }
    },
    setMLChartComponent:function(mlchartdatacomp,chartnumber){
        mlChartComponent[chartnumber]=mlchartdatacomp;
    },
    getMLChartComponent:function(chartnumber){
        return mlChartComponent[chartnumber];
    },
    setNLPTableRowLimitDetails:function(reportId,nlprowlimitdetails){
        nlptablerowlimitdetails[reportId]=nlprowlimitdetails;
    },
    getNLPTableRowLimitDetails:function(reportId){
        return nlptablerowlimitdetails[reportId];
    },
    getCustomParameterId: function (reportId) {
        return customParameterReportId[reportId];
    },
    setCustomParameterId: function (customParameter, reportId) {
        customParameterReportId[reportId] = customParameter;
    },
    getStoryboardSmartInsightFilterArray: function(){
        return storyboardSmartInsightFilterArray;
    },
    setStoryboardSmartInsightFilterArray: function(filterArrayData){
        storyboardSmartInsightFilterArray = filterArrayData;
    },
    getIsStoryboardSmartInsight: function(){
        return isStoryboardSmartInsight;
    },
    setIsStoryboardSmartInsight: function(isStorySmartInsight){
        isStoryboardSmartInsight = isStorySmartInsight;
    },
    getIsFilterAppliedForAutoML: function(){
        return isFilterAppliedForAutoML;
    },
    setIsFilterAppliedForAutoML: function(isfilterAppliedForAutoML){
        isFilterAppliedForAutoML = isfilterAppliedForAutoML;
    },
    setDatamodelMetaData : function(dataModelData){
        datamodelMetaData = dataModelData;
    },getDatamodelMetaData : function(){
        return datamodelMetaData;
    },
    setIsWorkspaceLiveEnabled : function(is_workspace_live_enabled){
        isWorkspaceLiveEnabled = is_workspace_live_enabled;
    },getIsWorkspaceLiveEnabled : function(){
        return isWorkspaceLiveEnabled;
    }
};


