import React from "react";
import _ from 'underscore';
import ReactDOM from 'react-dom';
import SaveUserDetails from "./saveuserdetails.js";
import SendRequest from '../../SendRequest';
import { lang } from "../../js/helpers/utils/languages/language";
const $ =window.$;

export default class UsersAccountFormHtml extends React.Component{
  constructor(props){
super(props);
this.state={};
  }
  saveuserdetails=(e)=>{
    var serialized = $('form').serializeArray();
    var s = '';
       var data = {};
       for(s in serialized){
           data[serialized[s]['name']] = serialized[s]['value']
       }
      var result=JSON.stringify(data);
	  var finaldata=JSON.parse(result);
	  var isValid = this.isValid(finaldata);
	  if(isValid) {
		var saveaccount = new SaveUserDetails();
	  }
}
isValid=(data)=>{
	var isValid = true;
	if($.trim($.trim(data.firstname))==""){
		$(".firstnameerror").removeClass('hidden');
		$(".firstnameerror").text(lang["admin.users.firstnamerqd"]);
		isValid = false;
	}
	if($.trim($.trim(data.Email))==""){
		$(".emailerror").removeClass('hidden');
		$(".emailerror").text(lang["admin.users.emailrqd"]);
		isValid = false;
	}
	if($('.group-select').val() == null){
		$(".grouperror").removeClass('hidden');
		$(".grouperror").text(lang["admin.users.smgroup"]);
		isValid = false;
	}
	if($.trim($.trim(data.role))==""){
		$(".roleerror").removeClass('hidden');
		$(".roleerror").text(lang["admin.users.rolerqd"]);
		isValid = false;
	}
	return isValid;
}
showadvanced(e){
	e.preventDefault();
	var $this = e.currentTarget;
	$($this).parent().find('.filtersearching').toggle('slow');
}
searchfilterdata(e){
	e.preventDefault();
	var startswith = $('#groupnamestart').val();
	var endswith = $("#groupnameend").val();
	var contains = $("#groupnamecontains").val();
	let requestHeaders = {
		method: "get",
		headers: new Headers({
			"Content-Type": "application/json"
		})
	};
	   SendRequest({
		url: "getSearchedGroupsList",
		queryString: "?startswith="+startswith + "&endswith="+endswith+"&contains="+contains,
		sucessFunction: (response) => {
			if (response.hasOwnProperty('error')) {
				$(".spinner").hide();
			} else {
				var optionArray = [];
				$.each(response, function(i, option)
				{
					optionArray[i] = "<option value='" + option.groupId + "'>" + option.groupName + "</option>";
				});

				$('.group-select>optgroup').find('option').not(':selected').remove()
				 $('.group-select>optgroup').prepend(optionArray.join(''));
				$('.group-select').trigger("chosen:updated");
				$('.group-select').trigger('chosen:open');
				$(".spinner").hide();
	
			}
		  },
		  rejectFunction: () => { },
		  headers: requestHeaders
		})
}
  render(){
    var that=this.props;
    return (
      <div class="container-fluid nopadding">
        {/* <div class="alert alert-success usercreationsuccess"
                   role="alert">User Created Successfully</div>
      <div class="alert alert-danger usercreationerror" 
                  role="alert">Error, Please Contact your System Administrator.</div>
      <div class="alert alert-danger userexists" 
                  role="alert">User Login Name Already Exist.</div> */}
          <div class="row in-back">
          <div class="max-height-30vh ">
              <h2 class="boc-main-title">{lang["admin.users.createtitle"]}</h2>
              <form class="form-horizontal col-xs-12 submitform">
				<div class="form-group form-group-sm form-group">
					<label for="firstname" class="col-sm-3 control-label important">{lang["admin.users.fname"]}</label>
					<div class="col-sm-6">
						<input type="name" class="form-control" id="firstname"
							placeholder="" name="firstname" />
								<span class="help-block firstnameerror hidden"></span>
					</div>
				</div>
                <div class="form-group form-group-sm form-group">
					<label for="lastname" class="col-sm-3 control-label">{lang["admin.users.lname"]}</label>
					<div class="col-sm-6">
						<input type="name" class="form-control" id="lastname"
							placeholder="" name="lastname"/>
								<span class="help-block lastnameerror hidden"></span>
					</div>
				</div>
				<div class="form-group form-group-sm form-group">
					<label for="loginemail" class="col-sm-3 control-label important">{lang["admin.users.email"]}</label>
					<div class="col-sm-6">
						<input type="email" class="form-control" id="loginemail"
							placeholder="" name="Email" />
								<span class="help-block emailerror hidden"></span>
					</div>
				</div>
                <div class="form-group form-group-sm form-group">
					<label for="usergroup" class="col-sm-3 control-label important">{lang["admin.users.grp"]}</label>
					<div class="col-sm-6 grouplistcontainer">
						<select name="usergroup" id="usergroup"  data-placeholder={lang["admin.users.chooseagrp"]} class="group-select selectedgroups form-control" dat multiple tabindex="6">
						<optgroup class="groupids"  label={lang["admin.users.chooseagrp"]}>
                        {this.props.groupsresponse.map((data, index)=>{           
							if(data.status=="A"){
                                return <option  key={index} value={data.groupId}>{data.groupName}</option>
							}
							})
                        }
						</optgroup>
						</select>						
						<span class="help-block grouperror hidden"></span>
						<button class="btn btn-link pull-right btn-sm showadvanced" onClick={this.showadvanced}>{lang["admin.users.advncd"]}</button>
						<div class="row marg-bot-10 filtersearching " style={{ display: "none" }}>
                            <div class="stringfilters searching ">
							{lang["admin.users.strtw"]} <input type="text" id="groupnamestart" data-id="groupnamestart" class="searchword" />
                            </div>
                            <div class="stringfilters searching">
							{lang["admin.users.endw"]} <input type="text" id="groupnameend" data-id="groupnameend" />
                            </div>
                            <div class="stringfilters searching col-lg-10 col-md-10 col-sm-12 col-xs-12" style={{marginLeft: "-5px"}}>
							{lang["admin.users.contains"]} <input type="text" id="groupnamecontains" data-id="groupnamecontains" />
                            </div>
                            <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12" style={{marginTop: "1px"}}>
                            <button type="button" id="searchfiltersdata" onClick={this.searchfilterdata} class="btn btn-sm btn-bird marg-top-20 btn-highlight">{lang["admin.users.gobtn"]}</button>
                            </div>
                        </div>
					</div>
				</div>
                <div class="form-group form-group-sm">
					<label for="" class="col-sm-3 control-label">{lang["admin.users.status"]}</label>
					<div class="col-sm-8">
						<div>
							<input type="radio" id="1" name="status" value="A" defaultChecked/>{lang["admin.users.statusact"]}&nbsp;&nbsp;&nbsp; <input
								type="radio" id="2" name="status" value="I" />{lang["admin.users.statusdact"]}
						</div>
					</div>
				</div>
                <div class="form-group form-group-sm form-group">
					<label for="" class="col-sm-3 control-label important">{lang["admin.users.crtrole"]}</label>
					<div class="col-sm-6">
						<div>
							<select placeholder="" class="form-control selectedroles" id="role" name="role">
								<option value="">{lang["admin.users.chooserole"]}</option>
                                {this.props.rolesresponse.map((role)=>{
								
								 if(role.roleDisplayName!="Admin"&&(role.status=="A"||role.status=="Active")){ 
								return	<option value={role.roleName}>{role.roleDisplayName}</option>
                                 }
                                })
                            }
								
							</select>
							<span class="help-block roleerror hidden"></span>
						</div>
					</div>
				</div>
                <div class="form-group form-group-sm">
					<div class="col-sm-offset-3 col-sm-6">
						<button type="button" class="btn btn-bird saveuser" onClick={this.saveuserdetails} id="saveusers"><i class="fa fa-check marg-righ-5"></i>{lang["admin.users.addbtn"]}</button>
					</div>
				</div>
                </form>
        </div>
        
        </div>
  </div>
      );
  };
  }
