import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'underscore';
import PubSub from 'pubsub-js'
import SendRequest from '../SendRequest';
import WorkspaceItemSettingsHtml from './WorkspaceItemSettingsHtml.jsx';
import PreviewDataTable from './PreviewDataTable.jsx';
import WorkspaceCustomQuery from './WorkspaceCustomQuery';
import AddFilters from './AddFilters';
import WorkspaceAccordian from './WorkspaceAccordian';
import WorkspaceCustomfieldsPreview from './WorkspaceCustomfieldsPreview';
import { lang } from "../js/helpers/utils/languages/language";
import ExcludeColumnsDropDownView from "../ViewStoryboard/viewMLStoryboard/execludecolumnsdropdownview";
import XAxisAttrView from "../ViewStoryboard/xaxisattributesview";
const $ = window.$;

export default class WorkspaceItemSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            entities: []
        }
        this.entityId = this.props.entityId;
        this.entitySourceName = this.props.entitySourceName;
        this.entityName = this.props.entityName;
        this.type = this.props.type;
        this.filters = this.props.filters;
        this.connId = this.props.connId;
        this.isCustomQuery = this.props.isCustomQuery;
        this.customfields = this.props.customfields;
        this.collection = this.props.collection;
        this.workspaceCustomQueryCollection = this.props.workspaceCustomQueryCollection;
        this.workspaceId = this.props.workspaceId;
        // this.existingFilters=this.props.existingFilters;
        this.columns = this.props.columns;
        this.updateCustomFieldsList = PubSub.subscribe('updateCustomFieldsList', this.updateCustomFieldsList.bind(this));
    }
    componentDidMount() {
        $(".spinner").hide();
        $("#settingscontainer").addClass("showsettings");
        SendRequest({
            url: "getworkspaceentities",
            queryString: "?workspaceId=" + this.props.workspaceId + "&&isFromBmodel=" + false,
            sucessFunction: (response) => {
                this.setState({
                    entities: response
                });
            },
            rejectFunction: (error) => { },
            headers: {
                method: "get",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            }
        })
    }
    getEntityCustomFeilds = (entityName, connectionId) => {
        var customfeilds = [];
        this.state.entities.map((val, index) => {
            if (val.entityname === entityName) {
                customfeilds.push(JSON.parse(val.customfeilds));
            }
        });
        return customfeilds;
        // if (this.state.entities[entityName] == undefined) {
        //     this.state.entities[entityName] = {};
        //     return undefined;
        // }
        // else {
        //     return this.state.entities[entityName]["customfeilds"];
        // }
    }
    closesettingscontainer = (e) => {
        e.preventDefault();
        $("#settingscontainer").removeClass("showsettings");
        $("#workspaceentitycontainer>li").removeClass('active');
        $("#workspaceentitycontainer").removeClass('settingsopened');
        setTimeout(function () { $("#settingscontainer").remove(); }, 1000);
    }
    applyworkspacefilter = (e) => {
        e.preventDefault();
        var that = this;
        var $this = e.currentTarget;
        if (this.isCustomQuery) {
            this.props.applyworkspacefilters("wscsq", that.entityName);
        } else {
            this.props.applyworkspacefilters(that.props.connId, that.entityName);
        }
    }
    addworkspacefilter = (e) => {
        e.preventDefault();
        var that = this;
        if (this.isCustomQuery) {
            var wscustomquname = $('.wscustomqueryname').val().trim();
            this.existingFilters = this.collection.getEntityFilters(wscustomquname, "wscsq");
            this.columns = this.collection.getEntityColumns(wscustomquname, "wscsq");
            this.entityName = wscustomquname;
        }

        $('#main').append('<div id="workspacefiltermodal"></div>');
        ReactDOM.render(
            <AddFilters
                data_type={this.props.data_type}
                isCustomQuery={that.isCustomQuery}
                columns={this.columns}
                filters={that.existingFilters}
                connId={that.connId}
                entityId={that.entityId}
                entityName={that.entityName}
                entitycollection={that.collection}
            />,
            document.querySelector('#workspacefiltermodal')
        );
        $("body>.modal-backdrop.fade.in").remove();
        $("#workspaceitemsfiltermodal").show().addClass('in');
    }
    loadCustomQuery = (e) => {
        var that = this;
        var query = "";
        if (this.entityName !== undefined && this.entityName !== "") {
            query = this.workspaceCustomQueryCollection.getwsCustomQueryQueries(this.entityName, "wscsq");
        }
        ReactDOM.render(
            <WorkspaceCustomQuery
                entityName={this.entityName}
                workspaceId={this.workspaceId}
                query={query}
                type={that.type}
                validateworkspacecustomquery={this.props.validateworkspacecustomquery}
                saveworkspacecustomquery={this.props.saveworkspacecustomquery}
            />, document.getElementById('customqueryaccordion')
        )
    }
    loadFiltersData = (e) => {
        var that = this;
        var filtersData;
        if (this.isCustomQuery) {
            filtersData = that.collection.getEntityFilters(that.entityName, "wscsq");
        } else {
            filtersData = that.collection.getEntityFilters(that.entityName, that.props.connId)
        }
        if (filtersData !== undefined) {
            _.each(filtersData, (val, index) => {
                var type = val.dataType;
                var tableName = val.tablename;
                var columnName = val.columnname;
                var columnDisplayName = val.columndisplayname;
                $('#filteraccordion').append("<div id=" + 'accordion' + index + "></div>");
                ReactDOM.render(
                    <WorkspaceAccordian
                        index={index}
                        isCustomQuery={that.isCustomQuery}
                        tableName={tableName}
                        columnName={columnName}
                        columnDisplayName={columnDisplayName}
                        columnType={type}
                        entityId={that.entityId}
                        fromrange={val.fromRange}
                        torange={val.toRange}
                        filterArray={filtersData}
                        id={columnDisplayName}
                        filtergreaterthanarequalvalue={val.filtergreaterthanarequalvalue}
                        filterlessthanarequalvalue={val.filterlessthanarequalvalue}
                        filterObject={''}
                        script={null}
                        connId={that.connId}
                        daterange={val.daterange}
                        customFields="[]"
                        workspaceId={that.workspaceId}
                        entityName={this.entityName}
                        entitycollection={that.collection}
                    />,
                    document.getElementById(`accordion${index}`));
            });
            $(".applyworkspacefilters").removeClass("disabled");
            $("#workspaceitemsfiltermodal").modal("hide");
        }
    }
    updateCustomFieldsList() {
        ReactDOM.unmountComponentAtNode(document.getElementById('customfieldscontainer'))
        this.loadcustomfields();
    }
    loadcustomfields = (e) => {
        var that = this;
        if (this.isCustomQuery) {
            this.customfields = that.collection.getEntityCustomFeilds(that.entityName, "wscsq");
        } else {
            this.customfields = that.collection.getEntityCustomFeilds(that.entityName, that.connId);
        }
        ReactDOM.render(
            <WorkspaceCustomfieldsPreview
                customfields={that.customfields}
                entityName={that.entityName}
                data_parent={that.connId}
                entityId={that.entityId}
                createworkspacecustomfield={this.props.createworkspacecustomfield}
            />,
            document.getElementById('customfieldscontainer')
        );
    }
    loadpreviewdata = (e) => {
        $(".spinner").show();
        var that = this;
        var columns;
        var customfields;
        var tablename;
        var filters;
        if (this.isCustomQuery && that.entityName != undefined) {
            tablename = "ws_" + that.entityName;
            this.customfields = that.collection.getEntityCustomFeilds(that.entityName, "wscsq");
            this.filters = that.collection.getEntityFilters(that.entityName, "wscsq");
        } else {
            this.customfields = that.collection.getEntityCustomFeilds(that.entityName, that.connId);
            this.filters = that.collection.getEntityFilters(that.entityName, that.connId);
        }
        if (that.props.columns != undefined) {
            columns = JSON.stringify(that.props.columns);
        }
        if (that.customfields != undefined) {
            customfields = JSON.stringify(that.customfields);
        }
        if (that.filters != undefined) {
            filters = JSON.stringify(that.filters);
        }
        var data = new FormData();
        data.append("entityname", that.entityName);
        data.append("tablename", tablename);
        data.append("hubId", (that.props.hubId !== undefined) ? that.props.hubId : "");
        data.append("filters", (filters !== undefined) ? filters : "");
        data.append("customfields", (customfields !== undefined) ? customfields : "");
        data.append("connId", (that.props.connId !== undefined) ? that.props.connId : "");
        data.append("workspaceId", that.props.workspaceId);
        data.append("customquery", that.isCustomQuery ? this.isCustomQuery : false);
        data.append("columns", columns);
        SendRequest({
            url: "getworkspacePreviewData",
            queryString: "",
            body: data,
            sucessFunction: (response) => {
                if (response.hasOwnProperty('error')) {
                    $(".spinner").hide();
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('hide');
                    $('.birdmessage h2').empty().append('Error : ');
                    $('.birdmessage h2').append('<span class="errorcode">');
                    $('.birdmessage-info').empty().text(response.error);
                    $('.details').addClass('show');
                    $('.messagedetails xmp').empty().append(response.error)
                    setTimeout(function () {
                        $('.birderror').remove();
                    }, 3000);
                } else {
                    that.plotDataTable(response);
                }
            },
            rejectFunction: (error) => {$(".spinner").hide();},
            headers: {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            }
        });
    }
    plotDataTable = (response) => {
        var headers = [];
        ReactDOM.unmountComponentAtNode(document.getElementById('previewdata'));
        $("#previewdata").empty();
        for (var i = 0; i < response[response.length - 1]["tablecolumns"].length; i++) {
            var columnName = response[response.length - 1]["tablecolumns"][i];
            var columnDataTypes = response[response.length - 1]["tableColumnDataTypes"][i];
            headers.push({
                id: i + "",
                name: columnName,
                field: columnName,
                sortable: true,
                removable: true,
                tooltip: columnName,
                type: columnDataTypes
            });
        }
        headers.sort(function (a, b) {
            var x = a.name.toLowerCase();
            var y = b.name.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
        });
        var opts = {};
        opts["columns"] = headers;
        if (response[response.length - 1].hasOwnProperty('data')) {
            opts["data"] = response;
        }
        var dataArray = [];
        response.map((data, idex) => {
            dataArray.push(response[idex].data)
        })

        opts['exportFileName'] = "Doby";
        opts['autoColumnWidth'] = true;
        opts['quickFilter'] = true;
        opts['contextMenu'] = 'header';
        opts['canvasFocus'] = false;
        opts['rowHeight'] = 25;
        ReactDOM.render(
            <PreviewDataTable opts={opts} dataArray={dataArray} />,
            document.getElementById('previewdata')
        );
        
        $(".spinner").hide();
        // if(response!=undefined){
        //     $("#previewdata").prepend("<h6 style='margin: 0;padding: 0 8px;color:#00abab' id='previewRowsText'>Previewing "+$("table tbody tr").length+" Rows</h6>")
        // }
    }
    loadCurrency = (e) => {
        var that = this;
        var customFields,columns,currency ;
        if (this.isCustomQuery) {
            customFields = that.collection.getEntityCustomFeilds(that.entityName, "wscsq");
            currency = that.collection.getEntityCurrency(that.entityName, "wscsq")
        } else {
            customFields = that.collection.getEntityCustomFeilds(that.entityName, that.connId);
            currency = that.collection.getEntityCurrency(that.entityName, that.connId)
        }
        if (that.props.columns != undefined) {
            if(typeof that.props.columns === "string")
                columns = JSON.parse(that.props.columns);
            else 
                columns = that.props.columns;
        }
        if(currency && currency !=null && currency != "null"){
            if(typeof currency === "string"){
                currency = JSON.parse(currency);
            }
            let searchedDateColumn = columns.filter(val=>val.columnName==currency["date_columns"]);
            if(searchedDateColumn.length==0){
                let searchedDateCfColumn = customFields.filter(val=>val.name==currency["date_columns"])
                let searchedDateCfColumnObj = {};
                searchedDateCfColumnObj["columnName"] = searchedDateCfColumn[0].name;
                searchedDateCfColumnObj["columnDisplayName"] = searchedDateCfColumn[0].name;
                searchedDateCfColumnObj["tableDisplayType"] = searchedDateCfColumn[0].dataType;
                searchedDateCfColumnObj["tableName"] = that.entityName;
                that.addSavedValue(searchedDateCfColumnObj,"date");
            }else{
                that.addSavedValue(searchedDateColumn[0],"date");
            }
            let currencymap ={"USD":"United States Dollar","AOA":"Angolan Kwanza","AUD":"Australian Dollar","ARS":"Argentine Peso","AZN":"Azerbaijani Manat","BRL":"Brazilian Real","CAD":"Canadian Dollar","CHF":"Swiss Franc","CNY":"Chinese Yuan","DKK":"Danish Krone","EUR":"Euro","GBP":"British Pound Sterling","HKD":"Hong Kong Dollar","INR":"Indian Rupee","KRW":"South Korean Won","MXN":"Mexican Peso","NOK":"Norwegian Krone","NZD":"New Zealand Dollar","SEK":"Swedish Krona","SGD":"Singapore Dollar","THB":"Thai Baht","AFN":"Afghan Afghani","CLP":"Chilean Peso","COP":"Colombian Peso","MYR":"Malaysian Ringgit","PHP":"Philippine Peso","RUB":"Russian Ruble","SAR":"Saudi Riyal","BHD":"Bahraini Dinar","BDT":"Bangladeshi Taka","BYN":"New Belarusian Ruble","BYR":"Belarusian Ruble","BGN":"Bulgarian Lev","IQD":"Iraqi Dinar","IRR":"Iranian Rial","FJD":"Fijian Dollar","RSD":"Serbian Dinar","LKR":"Sri Lankan Rupee","LBP":"Lebanese Pound","KWD":"Kuwaiti Dinar","ILS":"Israeli New Sheqel","JOD":"Jordanian Dinar","JPY":"Japanese Yen","MUR":"Mauritian Rupee","MVR":"Maldivian Rufiyaa","VND":"Vietnamese Dong","UYU":"Uruguayan Peso","UZS":"Uzbekistan Som","SYP":"Syrian Pound","NPR":"Nepalese Rupee"};
            let currencyvalues = ["AED","AFN","ALL","AMD","ANG","AOA","ARS","AUD","AWG","AZN","BAM","BBD","BDT","BGN","BHD","BIF","BMD","BND","BOB","BRL","BSD","BTC","BTN","BWP","BYN","BYR","BZD","CAD","CDF","CHF","CLF","CLP","CNY","COP","CRC","CUC","CUP","CVE","CZK","DJF","DKK","DOP","DZD","EGP","ERN","ETB","EUR","FJD","FKP","GBP","GEL","GGP","GHS","GIP","GMD","GNF","GTQ","GYD","HKD","HNL","HRK","HTG","HUF","IDR","ILS","IMP","INR","IQD","IRR","ISK","JEP","JMD","JOD","JPY","KES","KGS","KHR","KMF","KPW","KRW","KWD","KYD","KZT","LAK","LBP","LKR","LRD","LSL","LTL","LVL","LYD","MAD","MDL","MGA","MKD","MMK","MNT","MOP","MRO","MUR","MVR","MWK","MXN","MYR","MZN","NAD","NGN","NIO","NOK","NPR","NZD","OMR","PAB","PEN","PGK","PHP","PKR","PLN","PYG","QAR","RON","RSD","RUB","RWF","SAR","SBD","SCR","SDG","SEK","SGD","SHP","SLL","SOS","SRD","STD","SVC","SYP","SZL","THB","TJS","TMT","TND","TOP","TRY","TTD","TWD","TZS","UAH","UGX","USD","UYU","UZS","VEF","VND","VUV","WST","XAG","XAU","XCD","XDR","XOF","XPF","YER","ZAR","ZMK","ZMW","ZWL"];
            let  searchedBaseCurrencyColumn = {};
            let currency_value = currencyvalues.filter(val=>val==currency["base_currency"])
            searchedBaseCurrencyColumn["columnName"] = currency_value;
            searchedBaseCurrencyColumn["columnDisplayName"] = currencymap[currency_value];
            searchedBaseCurrencyColumn["tableDisplayType"] = "string";
            searchedBaseCurrencyColumn["tableName"] = "";
            that.addSavedValue(searchedBaseCurrencyColumn,"base_currency");
            $(".currencycolumns").empty();
            let currency_columns = currency["currency_columns"].split(",")
            _.each(currency_columns,function(col){
               let  searchedNumberedColumn = columns.filter(val=>val.columnName==col)
               if(searchedNumberedColumn.length==0){
                let searchedNumberedCfColumn = customFields.filter(val=>val.name==col)
                let searchedNumberedColumnObj = {};
                searchedNumberedColumnObj["columnName"] = searchedNumberedCfColumn[0].name;
                searchedNumberedColumnObj["columnDisplayName"] = searchedNumberedCfColumn[0].name;
                searchedNumberedColumnObj["tableDisplayType"] = searchedNumberedCfColumn[0].dataType;
                searchedNumberedColumnObj["tableName"] = that.entityName;
                that.addSavedValue(searchedNumberedColumnObj,"number");
            }else{
                that.addSavedValue(searchedNumberedColumn[0],"number");
            }
            })
            $(".outputcurrencycolumns").empty();
            let output_currency = currency["output_currency"].split(",")
            _.each(output_currency,function(col){
                let  searchedOutputCurrencyColumn = {};
            let currency_value = currencyvalues.filter(val=>val==col)
            searchedOutputCurrencyColumn["columnName"] = currency_value;
            searchedOutputCurrencyColumn["columnDisplayName"] = currencymap[currency_value];
            searchedOutputCurrencyColumn["tableDisplayType"] = "string";
            searchedOutputCurrencyColumn["tableName"] = "";
                that.addSavedValue(searchedOutputCurrencyColumn,"output_currency");
            })
        }
        if($(".datecolumns .singleliitems").length != 0 && $(".basecurrencycolumns .singleliitems").length != 0 &&
                    $(".currencycolumns .singleliitems").length != 0 && $(".outputcurrencycolumns .singleliitems").length != 0){
                    if($(".applyworkspacecurrency").hasClass('disabled')){
                        $(".applyworkspacecurrency").removeClass('disabled')
                    }
        }            
        $(".spinner").hide();
    }
    addSavedValue = (searchedColumn,isFrom) =>{
        var appendingClass ;
        if(isFrom==="output_currency"){
            appendingClass = ".outputcurrencycolumns";
        }else if(isFrom==="number"){
            appendingClass = ".currencycolumns";
        }else if(isFrom==="base_currency"){
            appendingClass = ".basecurrencycolumns";
        }else if(isFrom==="date"){
            appendingClass = ".datecolumns";
        }
        var columnName = searchedColumn.columnName
            var columnDisplayName = searchedColumn.columnDisplayName;
            var datatype = searchedColumn.tableDisplayType;
            var tableName = searchedColumn.tableName;
            let today = new Date();
            let dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            if(isFrom==="date" || isFrom==="base_currency"){
                $(appendingClass).html("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
            }else{
                $(appendingClass).append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
            }
            var xaxisAttrView = new XAxisAttrView({
                broker: this.broker,
                dynamicdiv: dynamicdiv,
                xaxiscolumnDisplayName: columnDisplayName,
                xaxiscolumnName: columnName,
                xaxiscolumnType: datatype,
                xaxistableName: tableName,
                viewmode:"smartvalues",
            });
    }
    showDateColumnsDropDown = (e) => {
        var that = this;
        var customFields,columns ;
        if (this.isCustomQuery) {
            customFields = that.collection.getEntityCustomFeilds(that.entityName, "wscsq");
        } else {
            customFields = that.collection.getEntityCustomFeilds(that.entityName, that.connId);
        }
        if (that.props.columns != undefined) {
            if(typeof that.props.columns === "string")
                columns = JSON.parse(that.props.columns);
            else 
                columns = that.props.columns;
        }
        $(".datecolsdropdown").empty();
        $(".datecolsdropdown").append('<li class="xs-list-item"> <input class="form-control form-control-datecolsdropdown search-image" placeholder='+lang["mlreports.search"]+' id="searchinput" type="text"></li>');
        $(".datecolsdropdown").append('<span class="axis-list-items"></span>');
        var mincount = 20;
        var maxcount = 40;
        var textColumnsList = [];
        _.each($(".datecolumns .singleliitems"), function (listObj) {
            var columnName = $(listObj).attr("data-name");
            textColumnsList.push(columnName);
        });
        if (customFields != undefined && customFields.length > 0) {
            var customFeildsArray = customFields;
            _.each(customFeildsArray, function (customField) {
                if (textColumnsList.indexOf(customField.name) == -1 && customField.dataType == "date") {
                    let today = new Date();
                    let dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                    dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                    $(".datecolsdropdown>.axis-list-items").append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
                    var excludeColumnsDropDownView = new ExcludeColumnsDropDownView({
                        addDatevalue: that.addDatevalue,
                        dynamicdiv: dynamicdiv,
                        columnDisplayName: customField.name,
                        columnName: customField.name,
                        dataType: customField.dataType,
                        xaxisidentity: customField.dataType,
                        tableName: "",
                        customfield: 1,
                        chartType: "mlmodel",
                        classNameToAppend: "dimensionitem datecolumnitem"
                    });
                    $(".datecolsdropdown>.axis-list-items").append($("#" + dynamicdiv));
                }
                $('.form-control-datecolsdropdown').keyup(function (e) {
                    e.stopPropagation();
                    that.currencyDropDownSearch(e,"date")
                });
                $('.form-control-datecolsdropdown').click(function (e) {
                    e.stopPropagation();
                });
            });
        }
        _.each(columns, function (dimension) {
            if (textColumnsList.indexOf(dimension.columnName) == -1 && dimension.tableDisplayType == "date") {
                let today = new Date();
                let dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                $(".datecolsdropdown>.axis-list-items").append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
                var excludeColumnsDropDownView = new ExcludeColumnsDropDownView({
                    addDatevalue: that.addDatevalue,
                    dynamicdiv: dynamicdiv,
                    columnDisplayName: dimension.columnDisplayName,
                    columnName: dimension.columnName,
                    dataType: dimension.tableDisplayType,
                    xaxisidentity: dimension.tableDisplayType,
                    tableName: dimension.tableName,
                    customfield: 0,
                    chartType: "mlmodel",
                    classNameToAppend: "dimensionitem datecolumnitem"
                });
                $(".datecolsdropdown>.axis-list-items").append($("#" + dynamicdiv));
            }
            $('.form-control-datecolsdropdown').keyup(function (e) {
                e.stopPropagation();
                that.currencyDropDownSearch(e,"date")
            });
            $('.form-control-datecolsdropdown').click(function (e) {
                e.stopPropagation();
            });
        });

        $(".datecolsdropdown>.axis-list-items").scroll(function () {
            var searchVal = $(".form-control-datecolsdropdown").val();
            if (($(".datecolsdropdown>.axis-list-items").scrollTop() + $(".datecolsdropdown>.axis-list-items").height() + 25 >= $(".datecolsdropdown>.axis-list-items")[0].scrollHeight) && searchVal == "") {
                _.each(columns.slice(mincount, maxcount), function (dimension) {
                    if (dimension.columnName.indexOf("id") == -1 && dimension.columnName.indexOf("Id") == -1 &&
                        dimension.columnName.indexOf("ID") == -1 && textColumnsList.indexOf(dimension.columnName) == -1 && dimension.tableDisplayType == "date" && that.props.selectedColumnNamesList.indexOf(dimension.columnName) != -1) {
                        let today = new Date();
                        let dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                        $(".datecolsdropdown>.axis-list-items").append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
                        var excludeColumnsDropDownView = new ExcludeColumnsDropDownView({
                            addDatevalue: that.addDatevalue,
                            dynamicdiv: dynamicdiv,
                            columnDisplayName: dimension.columnDisplayName,
                            columnName: dimension.columnName,
                            dataType: dimension.tableDisplayType,
                            xaxisidentity: dimension.tableDisplayType,
                            tableName: dimension.tableName,
                            customfield: 0,
                            chartType: "mlmodel",
                            classNameToAppend: "dimensionitem datecolumnitem"
                        });
                        $(".datecolsdropdown>.axis-list-items").append($("#" + dynamicdiv));
                    }
                    $('.form-control-datecolsdropdown').keyup(function (e) {
                        e.stopPropagation();
                        that.currencyDropDownSearch(e,"date")
                    });
                    $('.form-control-datecolsdropdown').click(function (e) {
                        e.stopPropagation();
                    });
                });
                mincount = mincount + 20;
                maxcount = maxcount + 20;
            }
        });
        $('.form-control-datecolsdropdown').click(function (e) {
            e.stopPropagation();
        });
    }
    showBaseCurrencyDropdown = (e) =>{
        let that = this;
        let currencymap ={"USD":"United States Dollar","AOA":"Angolan Kwanza","AUD":"Australian Dollar","ARS":"Argentine Peso","AZN":"Azerbaijani Manat","BRL":"Brazilian Real","CAD":"Canadian Dollar","CHF":"Swiss Franc","CNY":"Chinese Yuan","DKK":"Danish Krone","EUR":"Euro","GBP":"British Pound Sterling","HKD":"Hong Kong Dollar","INR":"Indian Rupee","KRW":"South Korean Won","MXN":"Mexican Peso","NOK":"Norwegian Krone","NZD":"New Zealand Dollar","SEK":"Swedish Krona","SGD":"Singapore Dollar","THB":"Thai Baht","AFN":"Afghan Afghani","CLP":"Chilean Peso","COP":"Colombian Peso","MYR":"Malaysian Ringgit","PHP":"Philippine Peso","RUB":"Russian Ruble","SAR":"Saudi Riyal","BHD":"Bahraini Dinar","BDT":"Bangladeshi Taka","BYN":"New Belarusian Ruble","BYR":"Belarusian Ruble","BGN":"Bulgarian Lev","IQD":"Iraqi Dinar","IRR":"Iranian Rial","FJD":"Fijian Dollar","RSD":"Serbian Dinar","LKR":"Sri Lankan Rupee","LBP":"Lebanese Pound","KWD":"Kuwaiti Dinar","ILS":"Israeli New Sheqel","JOD":"Jordanian Dinar","JPY":"Japanese Yen","MUR":"Mauritian Rupee","MVR":"Maldivian Rufiyaa","VND":"Vietnamese Dong","UYU":"Uruguayan Peso","UZS":"Uzbekistan Som","SYP":"Syrian Pound","NPR":"Nepalese Rupee"};
        $(".basecurrencycolsdropdown").empty();
        $(".basecurrencycolsdropdown").append('<li class="xs-list-item"> <input class="form-control form-control-basecurrencycolsdropdown search-image" placeholder='+lang["mlreports.search"]+' id="searchinput" type="text"></li>');
        $(".basecurrencycolsdropdown").append('<span class="axis-list-items"></span>');
        let textColumnsList = [];
        _.each($(".basecurrencycolumns .singleliitems"), function (listObj) {
            var columnName = $(listObj).attr("data-name");
            textColumnsList.push(columnName);
        });
        _.each(currencymap, function (value,key) {
            if (textColumnsList.indexOf(key) == -1) {
                let today = new Date();
                let dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                $(".basecurrencycolsdropdown>.axis-list-items").append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
                var excludeColumnsDropDownView = new ExcludeColumnsDropDownView({
                    addDatevalue: that.addBaseCurrencyValue,
                    dynamicdiv: dynamicdiv,
                    columnDisplayName: value,
                    columnName: key,
                    dataType: "string",
                    xaxisidentity: "string",
                    tableName: "",
                    customfield: 0,
                    chartType: "mlmodel",
                    classNameToAppend: "dimensionitem"
                });
                $(".basecurrencycolsdropdown>.axis-list-items").append($("#" + dynamicdiv));
            }
            $('.form-control-basecurrencycolsdropdown').keyup(function (e) {
                e.stopPropagation();
                that.currencyDropDownSearch(e,"base_currency")
            });
            $('.form-control-basecurrencycolsdropdown').click(function (e) {
                e.stopPropagation();
            });
        });
    }
    showNumericColsDropdown = (e) =>{
        var that = this;
        var customFields,columns ;
        if (this.isCustomQuery) {
            customFields = that.collection.getEntityCustomFeilds(that.entityName, "wscsq");
        } else {
            customFields = that.collection.getEntityCustomFeilds(that.entityName, that.connId);
        }
        if (that.props.columns != undefined) {
            if(typeof that.props.columns === "string")
                columns = JSON.parse(that.props.columns);
            else 
                columns = that.props.columns;
        }
        $(".currencycolsdropdown").empty();
        $(".currencycolsdropdown").append('<li class="xs-list-item"> <input class="form-control form-control-currencycolsdropdown search-image" placeholder='+lang["mlreports.search"]+' id="searchinput" type="text"></li>');
        $(".currencycolsdropdown").append('<span class="axis-list-items"></span>');
        var mincount = 20;
        var maxcount = 40;
        var textColumnsList = [];
        _.each($(".currencycolumns .singleliitems"), function (listObj) {
            var columnName = $(listObj).attr("data-name");
            textColumnsList.push(columnName);
        });
        if (customFields != undefined && customFields.length > 0) {
            var customFeildsArray = customFields;
            _.each(customFeildsArray, function (customField) {
                if (textColumnsList.indexOf(customField.name) == -1 && customField.dataType == "number") {
                    let today = new Date();
                    let dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                    dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                    $(".currencycolsdropdown>.axis-list-items").append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
                    var excludeColumnsDropDownView = new ExcludeColumnsDropDownView({
                        addDatevalue: that.addNumericValue,
                        dynamicdiv: dynamicdiv,
                        columnDisplayName: customField.name,
                        columnName: customField.name,
                        dataType: customField.dataType,
                        xaxisidentity: customField.dataType,
                        tableName: "",
                        customfield: 1,
                        chartType: "mlmodel",
                        classNameToAppend: "dimensionitem"
                    });
                    $(".currencycolsdropdown>.axis-list-items").append($("#" + dynamicdiv));
                }
                $('.form-control-currencycolsdropdown').keyup(function (e) {
                    e.stopPropagation();
                    that.currencyDropDownSearch(e,"number")
                });
                $('.form-control-currencycolsdropdown').click(function (e) {
                    e.stopPropagation();
                });
            });
        }
        _.each(columns, function (dimension) {
            if (textColumnsList.indexOf(dimension.columnName) == -1 && dimension.tableDisplayType == "number") {
                let today = new Date();
                let dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                $(".currencycolsdropdown>.axis-list-items").append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
                var excludeColumnsDropDownView = new ExcludeColumnsDropDownView({
                    addDatevalue: that.addNumericValue,
                    dynamicdiv: dynamicdiv,
                    columnDisplayName: dimension.columnDisplayName,
                    columnName: dimension.columnName,
                    dataType: dimension.tableDisplayType,
                    xaxisidentity: dimension.tableDisplayType,
                    tableName: dimension.tableName,
                    customfield: 0,
                    chartType: "mlmodel",
                    classNameToAppend: "dimensionitem"
                });
                $(".currencycolsdropdown>.axis-list-items").append($("#" + dynamicdiv));
            }
            $('.form-control-currencycolsdropdown').keyup(function (e) {
                e.stopPropagation();
                that.currencyDropDownSearch(e,"number")
            });
            $('.form-control-currencycolsdropdown').click(function (e) {
                e.stopPropagation();
            });
        });

        $(".currencycolsdropdown>.axis-list-items").scroll(function () {
            var searchVal = $(".form-control-datecolsdropdown").val();
            if (($(".currencycolsdropdown>.axis-list-items").scrollTop() + $(".datecolsdropdown>.axis-list-items").height() + 25 >= $(".datecolsdropdown>.axis-list-items")[0].scrollHeight) && searchVal == "") {
                _.each(columns.slice(mincount, maxcount), function (dimension) {
                    if (dimension.columnName.indexOf("id") == -1 && dimension.columnName.indexOf("Id") == -1 &&
                        dimension.columnName.indexOf("ID") == -1 && textColumnsList.indexOf(dimension.columnName) == -1 && dimension.tableDisplayType == "date" && that.props.selectedColumnNamesList.indexOf(dimension.columnName) != -1) {
                        let today = new Date();
                        let dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                        $(".currencycolsdropdown>.axis-list-items").append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
                        var excludeColumnsDropDownView = new ExcludeColumnsDropDownView({
                            addDatevalue: that.addNumericValue,
                            dynamicdiv: dynamicdiv,
                            columnDisplayName: dimension.columnDisplayName,
                            columnName: dimension.columnName,
                            dataType: dimension.tableDisplayType,
                            xaxisidentity: dimension.tableDisplayType,
                            tableName: dimension.tableName,
                            customfield: 0,
                            chartType: "mlmodel",
                            classNameToAppend: "dimensionitem"
                        });
                        $(".currencycolsdropdown>.axis-list-items").append($("#" + dynamicdiv));
                    }
                    $('.form-control-currencycolsdropdown').keyup(function (e) {
                        e.stopPropagation();
                        that.currencyDropDownSearch(e,"number")
                    });
                    $('.form-control-currencycolsdropdown').click(function (e) {
                        e.stopPropagation();
                    });
                });
                mincount = mincount + 20;
                maxcount = maxcount + 20;
            }
        });
        $('.form-control-currencycolsdropdown').click(function (e) {
            e.stopPropagation();
        });
    }
    showOutputCurrencyDropDown = (e) =>{
        let that = this;
        let currencymap ={"USD":"United States Dollar","AOA":"Angolan Kwanza","AUD":"Australian Dollar","ARS":"Argentine Peso","AZN":"Azerbaijani Manat","BRL":"Brazilian Real","CAD":"Canadian Dollar","CHF":"Swiss Franc","CNY":"Chinese Yuan","DKK":"Danish Krone","EUR":"Euro","GBP":"British Pound Sterling","HKD":"Hong Kong Dollar","INR":"Indian Rupee","KRW":"South Korean Won","MXN":"Mexican Peso","NOK":"Norwegian Krone","NZD":"New Zealand Dollar","SEK":"Swedish Krona","SGD":"Singapore Dollar","THB":"Thai Baht","AFN":"Afghan Afghani","CLP":"Chilean Peso","COP":"Colombian Peso","MYR":"Malaysian Ringgit","PHP":"Philippine Peso","RUB":"Russian Ruble","SAR":"Saudi Riyal","BHD":"Bahraini Dinar","BDT":"Bangladeshi Taka","BYN":"New Belarusian Ruble","BYR":"Belarusian Ruble","BGN":"Bulgarian Lev","IQD":"Iraqi Dinar","IRR":"Iranian Rial","FJD":"Fijian Dollar","RSD":"Serbian Dinar","LKR":"Sri Lankan Rupee","LBP":"Lebanese Pound","KWD":"Kuwaiti Dinar","ILS":"Israeli New Sheqel","JOD":"Jordanian Dinar","JPY":"Japanese Yen","MUR":"Mauritian Rupee","MVR":"Maldivian Rufiyaa","VND":"Vietnamese Dong","UYU":"Uruguayan Peso","UZS":"Uzbekistan Som","SYP":"Syrian Pound","NPR":"Nepalese Rupee"};
        $(".outputcurrencycolsdropdown").empty();
        $(".outputcurrencycolsdropdown").append('<li class="xs-list-item"> <input class="form-control form-control-outputcurrencycolsdropdown search-image" placeholder='+lang["mlreports.search"]+' id="searchinput" type="text"></li>');
        $(".outputcurrencycolsdropdown").append('<span class="axis-list-items"></span>');
        let textColumnsList = [];
        _.each($(".outputcurrencycolumns .singleliitems"), function (listObj) {
            var columnName = $(listObj).attr("data-name");
            textColumnsList.push(columnName);
        });
        _.each(currencymap, function (value,key) {
            if (textColumnsList.indexOf(key) == -1) {
                let today = new Date();
                let dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                $(".outputcurrencycolsdropdown>.axis-list-items").append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
                var excludeColumnsDropDownView = new ExcludeColumnsDropDownView({
                    addDatevalue: that.addOutputCurrencyValue,
                    dynamicdiv: dynamicdiv,
                    columnDisplayName: value,
                    columnName: key,
                    dataType: "string",
                    xaxisidentity: "string",
                    tableName: "",
                    customfield: 0,
                    chartType: "mlmodel",
                    classNameToAppend: "dimensionitem"
                });
                $(".outputcurrencycolsdropdown>.axis-list-items").append($("#" + dynamicdiv));
            }
            $('.form-control-outputcurrencycolsdropdown').keyup(function (e) {
                e.stopPropagation();
                that.currencyDropDownSearch(e,"output_currency")
            });
            $('.form-control-datecolsdropdown').click(function (e) {
                e.stopPropagation();
            });
        });
    }
    addOutputCurrencyValue = (e) =>{
        this.addMultiValue(e,"output_currency")
    }
    addNumericValue = (e) =>{
        this.addMultiValue(e,"number")
    }
    addBaseCurrencyValue = (e) =>{
        this.addSingleValue(e,"base_currency")
    }
    addDatevalue = (e) =>{
        this.addSingleValue(e,"date")
    }
    addMultiValue = (event,isFrom) => {
        var $this = event.currentTarget;
        var appendingClass ;
        if(isFrom==="output_currency"){
            appendingClass = ".outputcurrencycolumns";
        }else if(isFrom==="number"){
            appendingClass = ".currencycolumns";
        }
        if (!$($this).hasClass('active')) {
            var columnName = $($this).attr("data-name");
            var columnDisplayName = $($this).find(".itemname").text().trim();
            var datatype = $($this).attr("data-type");
            var tableName = $($this).attr("table-name");
                let today = new Date();
                let dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                $(appendingClass).append("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
                var xaxisAttrView = new XAxisAttrView({
                    broker: this.broker,
                    dynamicdiv: dynamicdiv,
                    xaxiscolumnDisplayName: columnDisplayName,
                    xaxiscolumnName: columnName,
                    xaxiscolumnType: datatype,
                    xaxistableName: tableName,
                    viewmode:"smartvalues",
                });
                $(appendingClass).append($("#" + dynamicdiv));
                if($(".datecolumns .singleliitems").length != 0 && $(".basecurrencycolumns .singleliitems").length != 0 &&
                    $(".currencycolumns .singleliitems").length != 0 && $(".outputcurrencycolumns .singleliitems").length != 0){
                        if($(".applyworkspacecurrency").hasClass('disabled')){
                        $(".applyworkspacecurrency").removeClass('disabled')
                    }
                }
        }
    }
    addSingleValue = (event,isFrom) => {
        var $this = event.currentTarget;
        var appendingClass ;
        if(isFrom==="base_currency"){
            appendingClass = ".basecurrencycolumns";
        }else if(isFrom==="date"){
            appendingClass = ".datecolumns";
        }
        if (!$($this).hasClass('active')) {
            var columnName = $($this).attr("data-name");
            var columnDisplayName = $($this).find(".itemname").text().trim();
            var datatype = $($this).attr("data-type");
            var tableName = $($this).attr("table-name");
                let today = new Date();
                let dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
                dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
                $(appendingClass).html("<div class='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
                var xaxisAttrView = new XAxisAttrView({
                    broker: this.broker,
                    dynamicdiv: dynamicdiv,
                    xaxiscolumnDisplayName: columnDisplayName,
                    xaxiscolumnName: columnName,
                    xaxiscolumnType: datatype,
                    xaxistableName: tableName,
                    viewmode:"smartvalues",
                });
                $(appendingClass).html($("#" + dynamicdiv));
                if($(".datecolumns .singleliitems").length != 0 && $(".basecurrencycolumns .singleliitems").length != 0 &&
                    $(".currencycolumns .singleliitems").length != 0 && $(".outputcurrencycolumns .singleliitems").length != 0){
                    if($(".applyworkspacecurrency").hasClass('disabled')){
                        $(".applyworkspacecurrency").removeClass('disabled')
                    }
                }
        }
    }
    currencyDropDownSearch = (e,isFrom) => {
        var appendingClass;
        if(isFrom==="base_currency"){
            appendingClass = ".form-control-basecurrencycolsdropdown";
        }else if(isFrom==="date"){
            appendingClass = ".form-control-datecolsdropdown";
        }else if(isFrom==="output_currency"){
            appendingClass = ".form-control-outputcurrencycolsdropdown";
        }else if(isFrom==="number"){
            appendingClass = ".form-control-currencycolsdropdown";
        }
        // Delay function for to wait until user stops typing
        var delay = (function () {
            var timer = 0;
            return function (callback, ms) {
                clearTimeout(timer);
                timer = setTimeout(callback, ms);
            };
        })();
        // Delay function for to wait until user stops typing
        $(appendingClass).keyup(function () {
            var valThis = $(this).val().toLowerCase();
            delay(function () {
                $(".itemname").parents(".dimensionitem").each(function () {
                    var text = $(this).text().toLowerCase();
                    (text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
                });
            }, 400);
        });
    }
    applycurrency = (e) =>{
        e.preventDefault();
        var that = this;
        var $this = e.currentTarget;
        if (this.isCustomQuery) {
            this.props.applyworkspacecurrency("wscsq", that.entityName);
        } else {
            this.props.applyworkspacecurrency(that.props.connId, that.entityName);
        }
    }
    render() {
        var that = this;
        var entitySourceName = "";
        if (that.entitySourceName !== undefined && that.entitySourceName !== null && that.entitySourceName !== "") {
            entitySourceName = <><i className='fa fa-link'></i>{that.entitySourceName}</>
        }
        return (
            <WorkspaceItemSettingsHtml
                isCustomQuery={that.isCustomQuery}
                entityId={that.entityId}
                entityName={that.entityName}
                connId={that.connId}
                entitySourceName={entitySourceName}
                closesettingscontainer={this.closesettingscontainer}
                addworkspacefilter={this.addworkspacefilter}
                applyworkspacefilter={this.applyworkspacefilter}
                loadCustomQuery={this.loadCustomQuery}
                loadpreviewdata={this.loadpreviewdata}
                loadFiltersData={this.loadFiltersData}
                loadcustomfields={this.loadcustomfields}
                createworkspacecustomfield={(e) => { this.props.createworkspacecustomfield(e, this.props.data_type) }}
                editworkspaceitem={this.props.editworkspaceitem}
                loadCurrency = {this.loadCurrency}
                showDateColumnsDropDown = {this.showDateColumnsDropDown}
                showNumericColsDropdown = {this.showNumericColsDropdown}
                showBaseCurrencyDropdown = {this.showBaseCurrencyDropdown}
                showOutputCurrencyDropDown = {this.showOutputCurrencyDropDown}
                applycurrency = {this.applycurrency}
            />
        );
    }
}