import React from "react";
import _ from 'underscore';
import { whitelableMap } from "../js/helpers/utils/whitelable";
import { lang } from "../js/helpers/utils/languages/language";
import AdminFooter from './adminfooter';

const AdminPage = (options) => {
    function licenceView() {
        window.location.reload(false);
    }
    return (
        <div>
            <div className="container-fluid boc-admin-main">
                <div className="row">
                    <div className="row-same-height">

                        <div className="col-xs-height nopadding maxleftpane hidenav">
                            <div className="maxscreen-lft-filter-items">
                                {
                                    options.accountsinfo.map(function (data, index) {
                                        let reportManagement = false;
                                        let schaduleFlag = false;
                                        let result = Object.keys(options.features).map(function (featuresKey, index) {
                                            if (data.id === "licensemanagement") {
                                                return options.features[featuresKey].features.map(function (feature) {
                                                    if (feature.featureName == 'license_info' && feature.allow == true) {
                                                        return <a key={index} className={`boc-maxscreen-left-item ${data.id}`} onClick={options.licenseview} id="adminlicense">
                                                            <i className={`fa ${data.icon} fa-max-lft-ico`}></i>
                                                            <span className="maxscreen-lft-filtertitle">
                                                                {data.name}
                                                            </span>
                                                        </a>
                                                    }
                                                })
                                            }
                                            if (data.id === 'Accounts') {
                                                return options.features[featuresKey].features.map((feature) => {
                                                    if (feature.featureName == 'accounts_view' && feature.allow == true) {
                                                        return <a key={index} className={`boc-maxscreen-left-item ${data.id}`} onClick={options.accountsView} id="adminaccounts">
                                                            <i className={`fa ${data.icon} fa-max-lft-ico`}></i>
                                                            <span className="maxscreen-lft-filtertitle">
                                                                {data.name}
                                                            </span>
                                                        </a>
                                                    }
                                                });

                                            }
                                            if (data.id === 'Roles') {
                                                return options.features[featuresKey].features.map((feature) => {
                                                    if (feature.featureName == 'roles_view' && feature.allow == true) {
                                                        return <a key={index} className={`boc-maxscreen-left-item ${data.id}`} onClick={options.rolesView} id="adminroles">
                                                            <i className={`fa ${data.icon} fa-max-lft-ico`}></i>
                                                            <span className="maxscreen-lft-filtertitle" id="rolestooltip">
                                                                {data.name}
                                                            </span>
                                                        </a>
                                                    }
                                                })
                                            }
                                            if (data.id === 'Groups') {
                                                return options.features[featuresKey].features.map((feature) => {
                                                    if (feature.featureName == 'groups_view' && feature.allow == true) {
                                                        return <a key={index} className={`boc-maxscreen-left-item ${data.id}`} onClick={options.groupsView} id="anmingroups">
                                                            <i className={`fa ${data.icon} fa-max-lft-ico`}></i>
                                                            <span className="maxscreen-lft-filtertitle" id="groupstooltip">
                                                                {data.name}
                                                            </span>
                                                        </a>
                                                    }
                                                })
                                            }
                                            if (data.id === 'Users') {
                                                return options.features[featuresKey].features.map((feature) => {
                                                    if (feature.featureName == 'users_view' && feature.allow == true) {
                                                        return <a key={index} className={`boc-maxscreen-left-item ${data.id}`} onClick={options.usersView} id="adminusers" >
                                                            <i className={`fa ${data.icon} fa-max-lft-ico`}></i>
                                                            <span className="maxscreen-lft-filtertitle" id="userstooltip">
                                                                {data.name}
                                                            </span>
                                                        </a>
                                                    }
                                                });
                                            }
                                            if (data.id === 'report_management') {
                                                return options.features[featuresKey].features.map((feature) => {
                                                    if (feature.featureName == "reportmanagement_reports" && feature.allow == true) {
                                                        return <a key={index} className={`boc-maxscreen-left-item ${data.id}`} onClick={options.reportManagementView} id="reportmanagement">
                                                            <i className={`fa ${data.icon} fa-max-lft-ico`}></i>
                                                            <span className="maxscreen-lft-filtertitle" id="reportmanagementtooltip">
                                                                {data.name}
                                                            </span>
                                                        </a>
                                                    }
                                                });
                                            }
                                            // if (schaduleFlag === false && data.id === 'Schedule' && ((options.features[featuresKey].featureName === 'forcesync' && options.features[featuresKey].allow === true) ||
                                            //     (options.features[featuresKey].featureName === 'schedule_view' && options.features[featuresKey].allow === true))) {
                                            //     schaduleFlag = true;
                                            //     return <a key={index} href={`/${data.id}`} className={`boc-maxscreen-left-item ${data.id}`} id="adminschedule">
                                            //         <i className={`fa ${data.icon} fa-max-lft-ico`}></i>
                                            //         <span className="maxscreen-lft-filtertitle" id="rolestooltip">
                                            //             {data.name}
                                            //         </span>
                                            //     </a>
                                            // }

                                            if (data.id === 'auditing') {
                                                return options.features[featuresKey].features.map((feature) => {
                                                    if (feature.featureName == 'auditing' && feature.allow == true) {
                                                        return <a key={index} className={`boc-maxscreen-left-item ${data.id}`} onClick={options.auditingView} id="adminauditing">
                                                            <span className="fa-stack" aria-hidden="true">
                                                                <i className="fa fa-desktop fa-stack-2x" />
                                                                <i className="fa fa-search fa-stack-1x" />
                                                            </span>
                                                            <span className="maxscreen-lft-filtertitle" id="auditingtooltip">
                                                                {data.name}
                                                            </span>
                                                        </a>
                                                    }
                                                });
                                            }
                                            if (data.id === 'other_settings') {
                                                return options.features[featuresKey].features.map((feature) => {
                                                    if (feature.featureName == 'settings_view' && feature.allow == true) {
                                                        return <a key={index} className={`boc-maxscreen-left-item ${data.id}`} onClick={options.otherSettingsView} id="adminothersettings">
                                                            <i className={`fa ${data.icon} fa-max-lft-ico`}></i>
                                                            <span className="maxscreen-lft-filtertitle" id="rolestooltip">
                                                                {data.name}
                                                            </span>
                                                        </a>
                                                    }
                                                });
                                            }
                                            if (data.id === 'syncsettings') {
                                                return <a key={index} className={`boc-maxscreen-left-item ${data.id}`} onClick={options.syncsettingsView} id="adminsyncsettings">
                                                            <i className={`fa ${data.icon} fa-max-lft-ico`}></i>
                                                            <span className="maxscreen-lft-filtertitle" id="syncsettingstooltip">
                                                                {data.name}
                                                            </span>
                                                        </a>
                                            }
                                            if (data.id === 'monitoring') {
                                                // result = <a key={index} href={`${data.id}`} className={`boc-maxscreen-left-item ${data.id}`} id="systemmonitorning">
                                                //     <span className="fa-stack" aria-hidden="true">
                                                //         <i className="fa fa-desktop fa-stack-2x" />
                                                //         <i className="fa fa-signal fa-stack-1x" />
                                                //     </span>
                                                //     <span className="maxscreen-lft-filtertitle" id="rolestooltip">
                                                //         {data.name}
                                                //     </span>
                                                // </a>
                                            }
                                        });

                                        return result;
                                    })
                                }
                                {/* <a href="log" className="boc-maxscreen-left-item logging" id="adminlogging">
                                    <i className="fa fa-file-text-o fa-max-lft-ico"></i>
                                    <span className="maxscreen-lft-filtertitle" id="auditingtooltip">Log Information</span>
                                </a> */}
                            </div>
                        </div>
                        <div className="clear"></div>
                        <div className="col-xs-12 col-xs-height rightside contentarea" id="rightmajor">
                            {options.maincontent()}
                        </div>

                    </div>
                    <div className="noadmin">
                    {lang["admin.viewdisplay"]}
		            </div>
                    {options.displayFooter?
                    <AdminFooter />
                    :null}
                </div>
            </div>

        </div>
    );
};
export default AdminPage

