import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'underscore';
import SendRequest from '../SendRequest';
import {getMessage} from '../js/helpers/utils/BirdMessage';
import DataHub from './Datahub';
import WebDataConnectionHtml from './WebDataConnectionHtml.jsx';
import WebDataConnectorFinalPreviewHtml from './WebDataConnectorFinalPreviewHtml.jsx';
import { lang } from "../js/helpers/utils/languages/language";
const $=window.$;

export default class WebDataConnection extends React.Component {
    constructor(props) {
        super(props);
        this.schemaName = props.schemaName;
		this.schemaDisplayName= props.schemaDisplayName;
		this.connection_access_id=props.connection_access_id;
		this.isTwoStepAuthParamterValid=false;
		this.schemaId= props.schemaId;
		this.value= props.value;
        this.entityResponse =props.response;
        this.connectionjson=props.connectionjson;
    }
    loaddatafinalview=(entityResponse)=>{
        var that = this;
        var key = that.value+"&&&"+that.schemaName;
        var response = entityResponse[key];
        var response = JSON.parse(response)["entities"];
        $(".spinner").show();
        $('.datahubmodifyentitiestablecontainer').empty();
        $('.additionaloptions').empty();
        ReactDOM.render(
            <WebDataConnectorFinalPreviewHtml columnslist={response} />,
            document.querySelector('.datahubmodifyentitiestablecontainer')
        );
        $(".spinner").hide();
        _.each(response, (value, index)=>{
            var dataType = value.dataType;
            this.CreateReportColumns=response;
            $(".csvcustomdatefield[name='"+value.orginalColumnName+"']").hide();
            if(dataType == "date"){
                $(".datecontroller[name='"+value.orginalColumnName+"']").trigger("change");
                var getDateFormat = $.grep(that.props.columns, function(e){ return (e.orginalColumnName == value.orginalColumnName)});
                if(getDateFormat != undefined && getDateFormat.length>0){
                    _.each(getDateFormat, function(obj){
                        if(obj.dateTypeFormat != undefined && getDateFormat.length>0){
                            $(".date .csv-form-control[id='"+obj.orginalColumnName+"']").html("<option>"+obj.dateTypeFormat+"</option>");
                        }
                    })
                }
            }
        });
    }
    webdatarequestmethod=(e)=>{
        var $this = e.currentTarget;
        var requestmethod = $($this).val();
        if(requestmethod =="get"){
            $('.webdataqueryformdata').hide();
        }else if(requestmethod =="post"){
            $('.webdataqueryformdata').show();
        }
    }
    webdataauthentication=(e)=>{
        var $this = e.currentTarget;
        var currentvalue = $($this).val();
        if(currentvalue==="none"){
            $(".webdataauthenticate").hide();
        }else if(currentvalue==="ntlm"){
            $(".webdataauthenticate").not('.windowswebdata').hide();
            $('.windowswebdata').show();
        }else if(currentvalue==="2step"){
            $(".webdataauthenticate").not('.twostepwebdata').hide();
            $('.twostepwebdata').show();
        }
    }
    removewebdataconnectorparameter=(e)=>{
        e.preventDefault();
        var $this = e.currentTarget;
        $($this).parent().parent().remove();
    }
    addwebdataconnectorheaderparameter=(e)=>{
        e.preventDefault();
        $('.append_webdataconnectorheadersdiv').append('<div class="control-group form-inline marg-top-10 webdataconnectorheaderparameterdiv">'+
                '<div class="validationgroup form-group col-xs-6">'+
                '<input type="text" class="selectdatasyncdaterange form-control input-sm webdataheaderparameterkey" value="" style="width:100%">'+
                '<span class="fa-help-light zendeskhelpblock hide"></span>'+
            '</div>'+
            '<div class="validationgroup form-group col-xs-5">'+
                '<input type="password" class="selectdatasyncdaterange form-control input-sm webdataheaderparametervalue" value="" style="width:100%">'+
                '<span class="fa-help-light zendeskhelpblock hide"></span>'+
            '</div>'+
            '<div class="col-xs-1">'+
            '<button type="submit" class="btn btn-transparent marg-righ-10 dropdown-toggle tooltip-top removewebdataconnectorheaderparameter" data-tooltip='+lang["datahub.remove"]+'>'+
            '	<i class="fa fa-minus-circle"></i>'+
            '</button>'+
            '</div>'+
        '</div>');
        $(".removewebdataconnectorheaderparameter").click(function(e){
            e.preventDefault();
            var $this = e.currentTarget;
            $($this).parent().parent().remove();
        })
    }
    addwebdataconnectorparameter=(e)=>{
        e.preventDefault();
        $('.append_webdataconnectorsdiv').append('<div class="control-group form-inline marg-top-10 webdataconnectorqueryparameterdiv">'+
                '<div class="validationgroup form-group col-xs-6">'+
                '<input type="text" class="selectdatasyncdaterange form-control input-sm webdataqueryparameterkey" value="" style="width:100%">'+
                '<span class="fa-help-light zendeskhelpblock hide"></span>'+
            '</div>'+
            '<div class="validationgroup form-group col-xs-5">'+
                '<input type="password" class="selectdatasyncdaterange form-control input-sm webdataqueryparametervalue" value="" style="width:100%">'+
                '<span class="fa-help-light zendeskhelpblock hide"></span>'+
            '</div>'+
            '<div class="col-xs-1">'+
            '<button type="submit" class="btn btn-transparent marg-righ-10 dropdown-toggle tooltip-top removewebdataconnectorparameter" data-tooltip='+lang["datahub.remove"]+'>'+
            '	<i class="fa fa-minus-circle"></i>'+
            '</button>'+
            '</div>'+
        '</div>');
        $(".removewebdataconnectorparameter").click(function(e){
            e.preventDefault();
            var $this = e.currentTarget;
            $($this).parent().parent().remove();
        })
    }
    addwebdataconnectorparameterauth=(e)=>{
        e.preventDefault();
        $('.append_webdataconnectorsdivauth').append('<div class="control-group form-inline marg-top-10 webdataconnectorqueryparameterdivauth">'+
                '<div class="validationgroup form-group col-xs-5">'+
                '<input type="text" class="form-control input-sm 2stepauthparameter"  value="" style="width:100%" placeholder='+lang["datahub.tokename"]+'>'+
                '<span class="fa-help-light zendeskhelpblock hide"></span>'+
            '</div>'+
            '<div class="validationgroup form-group col-xs-4">'+
                '<input type="password" class="form-control input-sm 2stepauthparametervalue"  value="" style="width:100%" placeholder='+lang["datahub.paramtr"]+'>'+
                '<span class="fa-help-light zendeskhelpblock hide"></span>'+
            '</div>'+
            '<div class="col-xs-1">'+
                '<button type="submit" class="btn btn-transparent marg-righ-10 dropdown-toggle tooltip-top removewebdataconnectorparameter" data-tooltip='+lang["datahub.remove"]+'>'+
                    '<i class="fa fa-minus-circle"></i>'+
                '</button>'+
            '</div>'+
        '</div>');
    }
    testconnection=(e,bool)=>{
        e.preventDefault();
        var isTestConnectionSuccess=false;
        var isTwoStepAuthParamterValid =false;
        this.scopename=$('.webdataconnectorscopename').val();
        this.connection_access_name = $(".connectionname").val();
        this.host=$('.webdataconnectorurl').val();
        this.webdataconnectorendpoint=$('#webdataconnectorendpoint').val();
        this.requestformat=$(".webdataconnectorreqformat input[type='radio']:checked").val();
        this.requestmethod=$(".webdataconnectorreqmethod input[type='radio']:checked").val();
        var queryparameterArray=[];
        var headerparameterArray=[];
        var isparametervalid=false;
        var that = this;
        _.each($('.webdataqueryparameterkey'),function(i,j){
            var parameterObj={};
            parameterObj[$(i).val().trim()]=$($('.webdataqueryparametervalue')[j]).val();
            if(parameterObj[$(i).val().trim()].length>0){
                queryparameterArray.push(parameterObj);
            }
        });
        _.each($('.webdataheaderparameterkey'),function(i,j){
            var parameterObj={};
            parameterObj[$(i).val().trim()]=$($('.webdataheaderparametervalue')[j]).val();
            if(parameterObj[$(i).val().trim()].length>0){
                headerparameterArray.push(parameterObj);
            }
        });
        if($('.webdataqueryparameterkey').length==0){
            isparametervalid=true;
        }
        this.queryparameters=queryparameterArray;
        this.headerparameters=headerparameterArray;
        this.formdata = $(".form-data").val();
        this.authenticationtype = $(".webdataauthentication").val();
        this.ntlmusername = $(".windowswebdatausername").val();
        this.ntlmpassword = $(".windowswebdatapassword").val();
        this.ntlmdomain = $(".windowswebdatadomain").val();
        this.authmethod=$(".authconnectorreqmethod input[type='radio']:checked").val();
        this.twostepurl = $(".twostepwebdataurl").val();
        this.twoStepTokenName = $(".tokenname").val();
        this.twoStepApiKey = $(".apikey").val();
        var twostepAuthParameterArray =[];
        this.isTwoStepAuthParamterValid=false;
        var isvalid=false;
        if(this.authenticationtype==='none'){
             isvalid = this.isdatavalid(this.connection_access_name,this.host,this.requestformat,this.requestmethod);
        }else if(this.authenticationtype==='ntlm'){
             isvalid = this.isntlmvalid(this.host,this.ntlmusername,this.ntlmpassword,this.ntlmdomain);
        }else{
             isvalid = this.is2authvalid(this.host,this.twostepurl,this.twoStepTokenName,this.twoStepApiKey);
        }
        if(this.authenticationtype === 'ntlm'){
            isTwoStepAuthParamterValid = true;
        }
        else {
        _.each($('.2stepauthparameter'),function(i,j){
            var parameterObj={};
            isTwoStepAuthParamterValid=true;
            $($('.2stepauthparametervalue')[j]).next().addClass("hide");
            $(i).next().addClass("hide");
            parameterObj[$(i).val().trim()]=$($('.2stepauthparametervalue')[j]).val();
            twostepAuthParameterArray.push(parameterObj);
         });
        }
        var result="";
        var that=this;
        if(isvalid){
            $(".spinner").show();
            isTestConnectionSuccess=true;
            var connectionproperties = {};
            var jsonObject ={};
            connectionproperties["scopename"] = that.scopename;
            connectionproperties["endpoint"] = that.webdataconnectorendpoint;
            connectionproperties["format"] = that.requestformat;
            connectionproperties["host"] = that.host;
            connectionproperties["method"] = that.requestmethod;
            connectionproperties["parameters"] = that.queryparameters;
            connectionproperties["headers"] = that.headerparameters;
            connectionproperties["authenticationtype"] = that.authenticationtype;
            connectionproperties["ntlmusername"] = that.ntlmusername;
            connectionproperties["ntlmpassword"] = that.ntlmpassword;
            connectionproperties["ntlmdomain"] = that.ntlmdomain;
            connectionproperties["twostepurl"] = that.twostepurl;
            connectionproperties["twoStepTokenName"] = that.twoStepTokenName;
            connectionproperties["twoStepApiKey"] = that.twoStepApiKey;
            connectionproperties["twostepAuthParameters"] = twostepAuthParameterArray;
            connectionproperties["formdata"] = that.formdata;
            connectionproperties["authmethod"] = that.authmethod;
            connectionproperties["dbType"]="webdataconnector";
            connectionproperties["connectionname"]=$(".connectionname").val();
            jsonObject["connection_access_name"] = $(".connectionname").val();
            jsonObject["connection_access_id"]=that.connection_access_id;
            jsonObject["dbType"]="webdataconnector";
            jsonObject["connection_id"]=that.schemaId;
            jsonObject["connection_details"] =JSON.stringify(connectionproperties);
            this.connectionDetails=jsonObject;
            var data=new FormData();
            data.append("connectionDetails",JSON.stringify(this.connectionDetails));
            data.append("schemaId",that.schemaId);
            SendRequest({
                url:"createservicescope",
                queryString: "",
                body:data,
                sucessFunction:(response)=>{
                    if(response.length>0 || !response.hasOwnProperty("error")){
                        if(bool){
                            this.savewebdataconnectiontodb();
                        }
                        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Success');
                        $('.birdmessage-info').empty().text(getMessage('BirdSuccess41'));
                    }else{
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Error');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show').text("Details");
                        $('.messagedetails xmp').empty().append(response.errorTrace)
                        $('.elasticsearchform').find('.first a').click();//To click back to first page is validation failed;
                    }
                    $(".spinner").hide();
                },
                rejectFunction:(error)=>{},
                headers:{
                    method: "post",
                    headers: new Headers({
                        "Content-Type": "application/json"
                    })
                }
            });
        }
        if(isvalid === false){
            $('.spinner').hide();
        }
    }
    isdatavalid=(scopename,host,requestformat,requestmethod)=>{
        var isvalid = false;
     
        if(scopename!=="" && host!=="" && requestformat!=="" && requestmethod!==""){
            if(scopename.replace(/\s+/g, '').length == 0){
                $('.connectionname').next().text(lang["datahub.nameerror"]).removeClass("hide");
                $(".spinner").hide();
                return
            }
            $('.webdataconnectorurl').next().addClass("hide");
            $('#cassandradbname').next().addClass("hide");
            //var result = isUrlValid(zdurl);
            isvalid=true;
        }else{
            if(scopename===""|| /[^a-zA-Z0-9_ ]/.test( $(".connectionname").val())){
                $('.connectionname').next().text(lang["datahub.nameerror"]).removeClass("hide");
            }
            else{
                $('.connectionname').next().addClass("hide");
            }
            if(host===""){
                $('.webdataconnectorurl').next().text(lang["Datahub.urlerror"]).removeClass("hide");
            }else{
                $('.webdataconnectorurl').next().addClass("hide");
            }
        }
        function isUrlValid(url) {
            return new RegExp("^((http|https|ftp)\://)*([a-zA-Z0-9\.\-]+(\:[a-zA-Z0-9\.&amp;%\$\-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(\:[0-9]+)*(/($|[a-zA-Z0-9\.\,\?\'\\\+&amp;%\$#\=~_\-]+))*$").test(url);
        }
        return isvalid;
    }
    isntlmvalid=(host,username,password,domain)=>{
        var isvalid = false;
        if(host!=="" &&  username!==""&& password!=="" && domain!==""){
            $('.windowswebdatausername').next().addClass("hide");
            $('.webdataconnectorurl').next().addClass("hide");
            $('.windowswebdatapassword').next().addClass("hide");
            $('.windowswebdatadomain').next().addClass("hide");
            //var result = isUrlValid(zdurl);
            isvalid=true;
        }else{
            if(username===""){
                $('.windowswebdatausername').next().text(lang["Datahub.usernameerror"]).removeClass("hide");
            }
            else{
                $('.windowswebdatausername').next().addClass("hide");
            }
            if(host===""){
                $('.webdataconnectorurl').next().text(lang["Datahub.urlerror"]).removeClass("hide");
            }else{
                $('.webdataconnectorurl').next().addClass("hide");
            }
            if(password===""){
                $('.windowswebdatapassword').next().text(lang["Datahub.pwderror"]).removeClass("hide");
            }else{
                $('.windowswebdatapassword	').next().addClass("hide");
            }
            if(domain===""){
                $('.windowswebdatadomain').next().text(lang["Datahub.domainrqrd"] ).removeClass("hide");
            }else{
                $('.windowswebdatadomain').next().addClass("hide");
            }
        }
        function isUrlValid(url) {
            return new RegExp("^((http|https|ftp)\://)*([a-zA-Z0-9\.\-]+(\:[a-zA-Z0-9\.&amp;%\$\-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(\:[0-9]+)*(/($|[a-zA-Z0-9\.\,\?\'\\\+&amp;%\$#\=~_\-]+))*$").test(url);
        }
        return isvalid;
    }
    is2authvalid=(host,url,token,apikey)=>{
        var isvalid = false;
        
        if(host!=="" &&  url!==""&& token!=="" && apikey!=""){
            $('.tokenname').next().addClass("hide");
            $('.webdataconnectorurl').next().addClass("hide");
            $('.twostepwebdataurl').next().addClass("hide");
            $('.apikey').next().addClass("hide");
            //var result = isUrlValid(zdurl);
            isvalid=true;
            
        }else{
            if(token===""){
                $('.tokenname').next().text(lang["Datahub.tokenrqrd"]).removeClass("hide");
            }
            else{
                $('.tokenname').next().addClass("hide");
            }
            if(apikey===""){
                $('.apikey').next().text(lang["Datahub.apirqrd"]).removeClass("hide");
            }
            else{
                $('.apikey').next().addClass("hide");
            }
            if(host===""){
                $('.webdataconnectorurl').next().text(lang["Datahub.hostrqrd"]).removeClass("hide");
            }else{
                $('.webdataconnectorurl').next().addClass("hide");
            }
            if(url===""){
                $('.twostepwebdataurl').next().text(lang["Datahub.urlerror"]).removeClass("hide");
            }else{
                $('.twostepwebdataurl').next().addClass("hide");
            }
        }
        function isUrlValid(url) {
            return new RegExp("^((http|https|ftp)\://)*([a-zA-Z0-9\.\-]+(\:[a-zA-Z0-9\.&amp;%\$\-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(\:[0-9]+)*(/($|[a-zA-Z0-9\.\,\?\'\\\+&amp;%\$#\=~_\-]+))*$").test(url);
        }
        return isvalid;
    }
    isParameterValid=(parameterkey,parametervalue,value)=>{
        var isParameterValid=false;
        if($(parameterkey).val()!=="" && $(parametervalue).val()!==""){
            $(parameterkey).next().addClass("hide");
            $(parametervalue).next().addClass("hide");
            isParameterValid=true;
        }else{
            if($(parameterkey).val()===""){
                $(parameterkey).next().addClass("hide");
                $(parameterkey).next().text(lang["Datahub.keyrqrd"]).removeClass("hide");
            }
            else{
                $(parameterkey).next().addClass("hide");
            }
            if($(parametervalue).val()===""){
                $(parametervalue).next().addClass("hide");
                $(parametervalue).next().text(lang["Datahub.valuerqrd"]).removeClass("hide");
            }
            else{
                $(parametervalue).next().addClass("hide");
            }
        }
    }
    savewebdataconnection=(e)=>{
        $(".spinner").show();
        var that =this;
        this.testconnection(e,true);
    }
    savewebdataconnectiontodb=()=>{
        var that =this;
        $(".spinner").show();
        var data=new FormData();
        data.append("connectionObject",JSON.stringify(that.connectionDetails));
        data.append("isFromModel",0);
        SendRequest({
            url:"saveConnectionDetails",
            queryString:"",
            body:data,
            sucessFunction:(collection)=>{
                $(".connection-center").remove()
                    if(collection.hasOwnProperty('error')){
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(data.errorCode);
                        $('.birdmessage-info').empty().text(data.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(data.error)
                        setTimeout(function() {
                            $('.birderror').remove();	
                        }, 3000);
                    }
                    else{
                        $(".spinner").hide();
                        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Success');
                        $('.birdmessage-info').empty().text(getMessage('BirdSuccess41'));
                        sessionStorage.setItem("changesdone",0);
                        $('.browselogfiles').removeClass('disabled');
                        $(".connection-center").removeClass("connection-in");
                        setTimeout(function(){$("body").removeClass("connection-open creating-new-connection");},800);
                        this.datahub=new DataHub();
                        this.datahub.loadConnections(collection.connectionAccessId);
                      //  ReactDOM.render(<DataHub selectedConnectionId={collection.connectionAccessId} />,document.querySelector("#main"));
                    }
            },
            rejectFunction:(error)=>{},
            headers:{
                method: "post",
                headers: new Headers({
                        "Content-Type": "application/json"
                    })
                }
        });
    }
    componentDidMount(){
        if(this.entityResponse!==undefined){
            this.loaddatafinalview(this.entityResponse);
        }
    }
    render() {
        return (
            <WebDataConnectionHtml
                schemaName={this.schemaName}
                schemaDisplayName={this.schemaDisplayName}
                connectionjson={this.connectionjson}
                webdatarequestmethod={this.webdatarequestmethod}
                webdataauthentication={this.webdataauthentication}
                savewebdataconnection={this.savewebdataconnection}
                testconnection={this.testconnection}
                addwebdataconnectorparameter={this.addwebdataconnectorparameter}
                removewebdataconnectorparameter={this.removewebdataconnectorparameter}
                addwebdataconnectorparameterauth={this.addwebdataconnectorparameterauth}
                addwebdataconnectorheaderparameter={this.addwebdataconnectorheaderparameter}
                close={this.props.close}
            />
        );
    }
}