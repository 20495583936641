import React from "react";
import 'jquery-validation/dist/jquery.validate';
import SendRequest from './SendRequest';
// import "./js/loginpageanimation";
import BirdLogo from './images/whitelable/bird-logo-night.png'
import './Login.css';
import { whitelableMap } from "./js/helpers/utils/whitelable";
import _ from 'underscore';
const $=window.$;

export default class ResetPassword extends React.Component {
  constructor(props) {
	super(props);
	this.state = {
		firstlogin : "no"
	}
  }
  componentDidMount(){
	this.getPasswordHint();
	$(".passwordhint").click(function(e){
		   e.preventDefault();
		   $(".phint").slideToggle("slow");
	});
	$(".spinner").hide();
  }
validatePassword(currentPwd,newPwd,confrmPwd,securityqn1,answer1,securityqn2,answer2,securityqn3,answer3) {
	var formdata = new FormData();
    formdata.append("newPassword", newPwd);
	formdata.append("currentPassword", currentPwd);
	formdata.append("securityQn1", securityqn1);
	formdata.append("answer1", answer1);
	formdata.append("securityQn2", securityqn2);
	formdata.append("answer2", answer2);
	formdata.append("securityQn3", securityqn3);
    formdata.append("answer3", answer3);
	
	let requestHeaders = {
		method: "post",
		headers: new Headers({
			"Content-Type": "application/json"
		})
	};
	SendRequest({
		url: "changeusersettings",
		queryString: "",
		body:formdata,
		sucessFunction: (data) => {	
			//var result=JSON.parse(data);
			var result=data;
			 $("#spinner").hide();
			 if(result.success=='invalid'){
				 if(result.message!=undefined &&  result.message!=null){
						 $(".reenterPassword").hide();
						$(".invalidpassword").show();
						$(".invalidpassword").text(result.message[0]);
						setTimeout(function() {
							$(".invalidpassword").hide();
						}, 6000);
					}else{
						 $(".currentPassword").show();
							setTimeout(function() {
								$(".currentPassword").hide();
						}, 3000);
					} 
			 }else{
				 this.passwordChange(data);
			 }
		},
			rejectFunction: () => { 
				$("#spinner").hide();
				alert("Password Change Failed ");
				
			},
  headers: requestHeaders,
})
	
}
passwordChange=(data)=>{
var that= this;
	$('#change_password').hide();
	//setTimeout(function() {
	//	that.props.history.push(`/${Properties.appName}/login`);
		//	}, 2000);
		window.location.replace("/")
		
}
contactAdmin=(e)=>{
	e.preventDefault();
	var that= this;
	let requestHeaders = {
		method: "post",
		headers: new Headers({
			"Content-Type": "application/json"
		})
	};
	SendRequest({
		url: "contactadmin",
		queryString: "",
		sucessFunction: (data) => {	
			var result=data;
			if(result.hasOwnProperty('success')){
				$(".emailtoadmin").show();
				setTimeout(function() {
					//that.props.history.push(`/${Properties.appName}/login`);
					window.location.replace("/")
						}, 2000);
			}
			
		},
			rejectFunction: () => { 
				$("#spinner").hide();
				
			},
  			headers: requestHeaders,
})

}
 getPasswordHint() {
	let requestHeaders = {
		method: "get",
		headers: new Headers({
			"Content-Type": "application/json"
		})
	};
	SendRequest({
		url: "getPasswordPolicyRulesHint",
		queryString: "",
		sucessFunction: (data) => {	
			//var result=JSON.parse(data);
			var result=data;
			 $("#spinner").hide();
			 if(result!=null && result!=undefined){
				 var passwordhint =[];
				 if(result.min_max_length!=undefined && result.min_max_length.length>0){
					 var length = [];
					 length= JSON.parse(result.min_max_length);
					 passwordhint.push('<li> Minimum of '+length[0]+' Characters in length. </li>');
					 passwordhint.push('<li> Maximum of '+length[1]+' Characters in length. </li>');
				 }
				 if(result.min_no_of_digits!=undefined && result.min_no_of_digits>0){
					 passwordhint.push('<li> Must contain '+result.min_no_of_digits+' or more Digits.</li>');
				 }
				 if(result.min_no_of_lowercase_characters!=undefined && result.min_no_of_lowercase_characters>0){
					 passwordhint.push('<li> Must contain '+result.min_no_of_lowercase_characters+' or more Lowercase Characters. </li>');
				 }
				 if(result.min_no_of_uppercase_characters!=undefined && result.min_no_of_uppercase_characters>0){
					 passwordhint.push('<li> Must contain '+result.min_no_of_uppercase_characters+' or more Uppercase Characters. </li>');
				 }
				 if(result.min_no_of_special_characters!=undefined && result.min_no_of_special_characters>0){
					 passwordhint.push('<li> Must contain '+result.min_no_of_special_characters+' or more Special Characters. </li>');
				 }
				 if(result.allow_whitespaces!=undefined && result.allow_whitespaces==1){
					 passwordhint.push('<li> Must not contain Whitespaces. </li>');
				 }else{
					 passwordhint.push('<li> Can contain Whitespaces. </li>');
				 }
				// $(".passwordhint").append('<div class="phint" style="display: block;"><h4> Password Hints</h4><ul class="phint-list" ></ul></div>');
				$(".phint>ul.phint-list").html(passwordhint);

			 }
			
		},
			rejectFunction: () => { 
				$("#spinner").hide();
				
			},
  			headers: requestHeaders,
})

}
	
	
  changePasswordbutton=(event)=>{
	event.preventDefault();
	 $("#spinner").show();
	 $(".phint").slideUp("slow");
		var currentPwd = $('#currPassword').val();
		var newPwd = $('#newPassword').val();
		var confrmPwd = $('#reenterPassword').val();
		var securityqn1 = $('#sq1 option:selected').text().trim();
		var answer1 = $('#ans1').val();
		answer1 = $.trim(answer1);
		var securityqn2 = $('#sq2 option:selected').text().trim();
		var answer2 = $('#ans2').val();
		answer2 = $.trim(answer2);
		var securityqn3 = $('#sq3 option:selected').text().trim();
		var answer3 = $('#ans3').val();
		answer3 = $.trim(answer3);
		var realanswer1 = $('#realanswer1').val();
		var realanswer2 = $('#realanswer2').val();
		var realanswer3 = $('#realanswer3').val();
		var isEmpty = false;
		if(currentPwd==""){
			isEmpty = true;
			$(".currenPassword").show();
			setTimeout(function() {
				$(".currenPassword").hide();
			}, 3000);
		}
		if(newPwd==""){
			isEmpty = true;
			$(".newPassword").show();
			setTimeout(function() {
				$(".newPassword").hide();
			}, 3000);
		}
		if(confrmPwd==""){
			isEmpty = true;
			$(".confirmPassword").show();
			setTimeout(function() {
				$(".confirmPassword").hide();
			}, 3000);
		}
		if(securityqn1=="Choose Security Question1"){
			isEmpty = true;
			$(".answer1").show();
			setTimeout(function() {
				$(".answer1").hide();
			}, 3000);
		}
		if(answer1=="" && $('#ans1').is(":visible")){
			isEmpty = true;
			$(".answer1").show();
			setTimeout(function() {
				$(".answer1").hide();
			}, 3000);
		}else if(answer1==undefined){
			answer1="";
		}else if(answer1.match(/([#;?&,%.+*~\'"!^$%[\]()<=>|\/])/g)){
			isEmpty = true;
			$(".specialchars1").html("Special characters are not allowed").show().css('color', '#d62020').fadeOut(3000);
		}
		if(securityqn2=="Choose Security Question2"){
			isEmpty = true;
			$(".answer2").show();
			setTimeout(function() {
				$(".answer2").hide();
			}, 3000);
		}
		if(answer2=="" && $('#ans2').is(":visible")){
			isEmpty = true;
			$(".answer2").show();
			setTimeout(function() {
				$(".answer2").hide();
			}, 3000);
		}else if(answer2==undefined){
			answer2="";
		}else if(answer2.match(/([#;?&,%.+*~\'"!^$%[\]()<=>|\/])/g)){
			isEmpty = true;
			$(".specialchars2").html("Special characters are not allowed").show().css('color', '#d62020').fadeOut(3000);
		}
		if(securityqn3=="Choose Security Question3"){
			isEmpty = true;
			$(".answer3").show();
			setTimeout(function() {
				$(".answer3").hide();
			}, 3000);
		}
		if(answer3=="" && $('#ans3').is(":visible")){
			isEmpty = true;
			$(".answer3").show();
			setTimeout(function() {
				$(".answer3").hide();
			}, 3000);
		}else if(answer3==undefined){
			answer3="";
		}else if(answer3.match(/([#;?&,%.+*~\'"!^$%[\]()<=>|\/])/g)){
			isEmpty = true;
			$(".specialchars3").html("Special characters are not allowed").show().css('color', '#d62020').fadeOut(3000);
		}
		if(answer1 != undefined && realanswer1 != undefined ){
			var match = answer1.toLowerCase() === realanswer1.toLowerCase();
			if(!match && answer1 !="" && $('#ans1').is(":visible")){
				isEmpty = true;
				$(".answermatch1").show();
				setTimeout(function() {
					$(".answermatch1").hide();
					$("#question1").hide();
					$("#ans1").hide();
					$("#ans1").val('')
					$("#question2").show();
					$("#ans2").show();	
				}, 2000);
			}
		}
		if(answer2 != undefined && realanswer2 != undefined ){
			var match = answer2.toLowerCase() === realanswer2.toLowerCase();
			if(!match && answer2 !="" && $('#ans2').is(":visible")){
			isEmpty = true;
			$(".answermatch2").show();
			setTimeout(function() {
				$(".answermatch2").hide();
				$("#question1").hide();
				$("#ans1").hide();
				$("#question2").hide();
				$("#ans2").hide();
				$("#ans2").val('')
				$("#question3").show();
				$("#ans3").show();
			}, 2000);
		}
		}
		
		if(answer3 != undefined && realanswer3 != undefined ){
			var match = answer3.toLowerCase() === realanswer3.toLowerCase();
			if(!match && answer3 !="" && $('#ans3').is(":visible")){
			isEmpty = true;
			$(".answermatch3").show();
			setTimeout(function() {
				$(".answermatch3").hide();
				$(".answermatch2").hide();
				$("#question1").show();
				$("#ans1").show();
				$("#question2").hide();
				$("#ans2").hide();
				$("#question3").hide();
				$("#ans3").hide();
				$("#ans3").val('')
			}, 2000);
		}
		}
		if(!isEmpty){
			 if(currentPwd==newPwd){
					if(newPwd==""){
						$(".newPassword").show();
						setTimeout(function() {
							$(".newPassword").hide();
						}, 3000);
					}
					else{
					$(".checkOldPassword").show();
					setTimeout(function() {
						$(".checkOldPassword").hide();
					}, 3000);
					}
				}else if(currentPwd!=newPwd ) {
					if(newPwd != confrmPwd || newPwd=="" || confrmPwd==""){
						$(".invalidpassword").hide();
						$(".reenterPassword").show();
						setTimeout(function() {
							$(".reenterPassword").hide();
						}, 3000);
					}
					else{
						if((securityqn1==securityqn2 || securityqn2==securityqn3 || securityqn3==securityqn1)&&(securityqn1 != '')&&(securityqn1 != undefined)){
							$(".differentsq").show();
							setTimeout(function() {
								$(".differentsq").hide();
							}, 3000);
						}
						else{
							this.validatePassword(currentPwd,newPwd,confrmPwd,securityqn1,answer1,securityqn2,answer2,securityqn3,answer3);
						}
						
					}
				}
		}
	
}
render(){
	var arr=[];
	arr.push(this.props.questionslist);
	if(this.props.firsttimelogin == 'true'){
		this.state.firstlogin = "first"
	}
    return(
        <React.Fragment>
        <>
        {/* <div id="connections-animation"></div>
	<div class="rightbg"></div> */}
	<div class="container-fluid height-100 logincontainer-fluid">
		<div class="row height-100 logincontainerrow">
			<div class="col-md-6 loginrightcontainer">
				<div class=" panelcontainer" id="change_password">
					<div class="logincontainer animation-delay1" id={this.state.firstlogin}>	
						<div class="logo">
							<img alt="BIRDonCloud" src={whitelableMap["loginlogo"]} id="birdimg"/>
							<span></span>
						</div>
						
						<div class="form">
							<div class="tab-content">
							<h1>Change Password</h1>
							{
								(this.props.passwordexpired!=null) ? (	
                                    <p class="error active">{this.props.passwordexpired}</p>
									) : (
										""
										   )
                                    }
								  {/* <c:if test="${not empty passwordexpired}">
										<p class="error active">${passwordexpired}</p>
								  </c:if> */}
							<form id="myform" class="form-login"  method='POST'>
							
								<div class="field-wrap">
									<label>Current Password<span class="req">*</span></label>
									<input type="password"  class="bounceIn animation-delay2" placeholder="" id="currPassword" name="currPassword" autofocus="autofocus"/>
									<div class="currenPassword changePassword-fields"  style={{display: "none"}} role="alert">Old Password is required</div>
									<div class="currentPassword changePassword-fields"   style={{display: "none"}} role="alert">Old Password is Invalid</div>
								</div>
								<div class="field-wrap">
									<label>New Password<span class="req">*</span></label>
									<input type="password" class="bounceIn animation-delay4" placeholder="" id="newPassword" name="newPassword"/>
									<div class="checkOldPassword changePassword-fields"   style={{display: "none"}} role="alert">Current Password must be different from Old Password</div>
									<div class="newPassword changePassword-fields"   style={{display: "none"}}  role="alert">New Password is required</div>
									<button class="btn btn-transparent pull-right passwordhint" onClick={this.getPasswordHint} title=''><span class=""><i class="fa fa-info"></i></span></button>
									<div class="phint"><h4> Password Rules</h4><ul class="phint-list" ></ul></div>
								</div>
								
								<div class="field-wrap">
									<label>Confirm New Password<span class="req">*</span></label>
									<input type="password" class="bounceIn animation-delay4" placeholder="" id="reenterPassword" name="reenterPassword"/>
									 <div class="confirmPassword changePassword-fields"   style={{display: "none"}}  role="alert">Confirm New Password is required</div>
									<div class="reenterPassword changePassword-fields"   style={{display: "none"}} role="alert">The Passwords does not match</div>
									<div class="invalidpassword changePassword-fields"   style={{display: "none"}}  role="alert"></div> 
								</div>
                                {/* {
									(this.props.firsttimelogin!=null) ? (	
                                <p class="error active">
                                    {this.props.firsttimelogin}</p>
									) : (
										""
										   )
                                    } */}
                                  {
									  (this.props.firsttimelogin == 'true' || this.props.resetpasswordfromadmin == 'true') ? (	
									<>
									<div class="field-wrap" style={{marginBottom:"-1px"}}> 
                                        
								<select name="sq1" id="sq1">
										<option value="">Choose Security Question1</option>
                                   {
								  	Object.entries( this.props.questionslist).map((questionslist,k)=>{
										return	<option value={questionslist[0]}>
												{questionslist[1]}</option>
											})
								  }
                								</select>
                                        
								</div>
								<div class="field-wrap">
									<input
										type="text" autocomplete="on" id="ans1" name="ans1" placeholder="Answer"
										autofocus="autofocus" spellcheck="false" autocorrect="off" />
								{/* <!-- <div class="securityqn1 changePassword-fields"  style="display: none" role="alert">Select security question1</div> --> */}
								<div class="answer1 changePassword-fields"  style={{display: "none"}} role="alert">Question/Answer required</div>
								<div class="specialchars1 changePassword-fields"  style={{display: "none"}} role="alert"></div>
								</div>
								<div class="field-wrap" style={{marginBottom:"-1px"}}>
											<select name="sq2" id="sq2">
											<option value="">Choose Security Question2</option>
								  {
								             	
													Object.entries( this.props.questionslist).map((questionslist,k)=>{
															return	<option value={questionslist[0]}>
																	{questionslist[1]}</option>
																})
															}
								  
											</select>
								</div>
								<div class="field-wrap">
									 <input
										type="text" autocomplete="on" id="ans2" name="ans2" placeholder="Answer"
										autofocus="autofocus" spellcheck="false" autocorrect="off"
										required />
								{/* <!-- <div class="securityqn2 changePassword-fields"  style="display: none" role="alert">Select security question2</div> --> */}
								<div class="answer2 changePassword-fields"  style={{display: "none"}}  role="alert">Question/Answer required</div>
								<div class="specialchars2 changePassword-fields"  style={{display: "none"}}  role="alert"></div>
								</div>
								<div class="field-wrap" style={{marginBottom:"-1px"}}>
								<select name="sq3" id="sq3">
										<option value="">Choose Security Question3</option>
										{
                                        Object.entries( this.props.questionslist).map((questionslist,k)=>{
												return	<option value={questionslist[0]}>
														{questionslist[1]}</option>
                                                    })
												}
													</select>
								
								</div>
								<div class="field-wrap">
									<input
										type="text" autocomplete="on" id="ans3" name="ans3" placeholder="Answer"
										autofocus="autofocus" spellcheck="false" autocorrect="off"
										required />
								{/* <!-- <div class="securityqn3 changePassword-fields"  style="display: none" role="alert">Select security question3 </div> ;--> */}
								<div class="answer3 changePassword-fields"  style={{display: "none"}} role="alert">Question/Answer required</div>
								<div class="specialchars3 changePassword-fields"  style={{display: "none"}} role="alert"></div>
								<div class="differentsq changePassword-fields"  style={{display: "none"}} role="alert">Please choose 3 different security questions</div>
								</div>
								</>	
								) : (
									""
									   )
                            }
								{/* <!-- </div> -->
								<!-- <div class="securitylist_forgot"> --> */}
								{/* {
									(this.props.forgotpassword!=null) ? (
                                    <p class="error active">{this.props.forgotpassword}</p>
                                    ) : (
										""
										   )
									} */}
									{
									(this.props.forgotpassword == 'true' && this.props.resetpasswordfromadmin != 'true') ? (	
											<>
									 <div class="field-wrap">
									<label for="ans1" class="active" id="question1">{this.props.sq1}</label> 							
									<input type="text" autocomplete="on" id="ans1" name="ans1" autofocus="autofocus" spellcheck="false" autocorrect="off" style={{marginTop: "40px"}}/>
										<div class="answer1 changePassword-fields"  style={{display: "none"}}  role="alert">Answer is required</div>
										<div class="answermatch1 changePassword-fields"  style={{display: "none"}}  role="alert">Answer is not matched</div>
										<input type="hidden" id="realanswer1" name="realanswer1" value={this.props.ans1}/>
								   </div>
								   <div class="field-wrap">
									<label for="ans2" class="active" id="question2" style={{display: "none"}} >{this.props.sq2}</label>
									<input
										type="text" autocomplete="on" id="ans2" name="ans2" autofocus="autofocus" spellcheck="false" autocorrect="off" style={{display: "none",marginTop: "40px"}}/>
										 <div class="answer2 changePassword-fields"  style={{display: "none"}}  role="alert">Answer is required</div>
										<div class="answermatch2 changePassword-fields"  style={{display: "none"}}  role="alert">Answer is not matched</div>
										<input type="hidden" id="realanswer2" name="realanswer2" value={this.props.ans2}/>
								   </div>
								   <div class="field-wrap">
									<label for="ans3" class="active" id="question3" style={{display: "none"}} >{this.props.sq3}</label>
									<input
										type="text" autocomplete="on" id="ans3" name="ans3" autofocus="autofocus" spellcheck="false" autocorrect="off" style={{ display: "none", marginTop: "40px" }}/>
										 <div class="answer3 changePassword-fields"  style={{display: "none"}}  role="alert">Answer is required</div>
										<div class="answermatch3 changePassword-fields"  style={{display: "none"}}  role="alert">Answer is not matched</div>
										<input type="hidden" id="realanswer3" name="realanswer3" value={this.props.ans3}/>
								   </div>
								   <div class="field-wrap">
								   <a href="javascript:void(0)" id="contactadmin" onClick={this.contactAdmin} style={{textDecoration: "underline" ,marginLeft: "84px", color: "#3545ff"}}>Contact Admin</a>
								   <div class="emailtoadmin changePassword-fields"  style={{display: "none"}}  role="alert">Email has been sent to your administrator</div>
								   </div>
								   </>

								) : (
									""
									   )		
								}			
								
								<div class="submitbutton">
								    <button type="submit" class="button button-block changePasswordbutton" onClick={this.changePasswordbutton}>Change Password<div class="ripples buttonRipples"><span class="ripplesCircle"></span></div></button>
									{/* <!-- <button type="button" class="btn btn-bird" data-dismiss="modal">Close</button> --> */}
										
								</div>
								
							</form>
							</div>
						</div>
					</div>
				</div>
				<div class="footer copyright-footer">{whitelableMap["footertext"]}</div>
			</div>
			<div class="col-md-6 loginleftcontainer"></div>
		</div>
	</div>	
    </>
    </React.Fragment>
   
    )
}
}