import React from 'react';
import _ from 'lodash';
import $ from 'jquery';
import * as Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official'
import * as ChartUtil from "../../js/helpers/utils/chartutil";
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import dataModule from "highcharts/modules/data";
import HighchartsMore from 'highcharts/highcharts-more'
import { whitelableMap } from "../../js/helpers/utils/whitelable";
import { getMessage } from '../../js/helpers/utils/BirdMessage';

dataModule(Highcharts);
var charts = {};

export default class TrellisChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    componentDidMount() {
        var compos = {};
        compos["chartComponent"] = this.refs.chartComponent;
        compos["chartparent"] = this.refs.chartparent;
        compos["chart"] = charts[this.state.chartid];
        compos["chartType"] = this.state.chartType;
        ChartInfo.functions.setChartComponents(this.state.chartnumber, compos);
        if (this.state.chartid != undefined && this.state.chartid == "nlpchart") {
            this.refs.chartComponent.chart.setSize($("#nlpchart").width(),$("#nlpchart").height(),false);
        }
        else {
            if(this.refs!=undefined && this.refs.chartComponent!=undefined && this.refs.chartComponent.chart!=undefined){
                ChartUtil.charts[this.state.chartid]=this.refs.chartComponent.chart;
            }
            //ChartUtil.modifyChartHeightOnResize(this.state.chartnumber);
        }
    }
    render() {
        var data = this.props.data, xaxisname = this.props.xaxisname, xaxisColumnName = this.props.xaxisColumnName, xaxisDataType = this.props.xaxisDataType,
            yaxisnameArray = this.props.yaxisnameArray, chartid = this.props.chartid, chartType = this.props.chartType, legend = this.props.legend, position = this.props.legend, features = this.props.features, isSharedReport = this.props.isSharedReport, colorThemeIndex = this.props.colorThemeIndex, chartSettings = this.props.chartSettings, tooltipNameArray = this.props.tooltipNameArray,
            algorithm = this.props.algorithm, periodsvalue = this.props.periodsvalue, trendstats = this.props.trendstats, iscustomstoryboard = this.props.iscustomstoryboard, yAxisDataTypeArray = this.props.yAxisDataTypeArray, yAxisAggArray = this.props.yAxisAggArray, yAxisOriginalNameArray = this.props.yAxisOriginalNameArray, ismultifact = this.props.ismultifact, multifactentities = this.props.multifactentities, datamodelId = this.props.datamodelId, facttables = this.props.facttables;
            if(charts!=undefined && chartid!=undefined && charts[chartid]!=undefined){
                _.each(charts[chartid], function (cobj, j) {
                    if(cobj!=undefined){
                        cobj.destroy();
                    }
                });
            }

        this.state.chartid = chartid;
        this.state.chartType = chartType;
        var chartPoints = {};
        var columnsData = this.props.columnsData;
        var isDrillDownEnable = false;
        var isViewDataEnable = false;
        var isExportChartEnable = false;
        var isFilterEnable = false;
        var isDrillThroughEnable = false;
        var chart;
        var isDirect = 0;
        var isStoredProcedure = 0;
        var colorThemesArray = ChartInfo.functions.getColorThemes();
        var x, y;
        if (chartSettings.numberformat == undefined) {
            var numberformat = {};
            numberformat.decimals = "0_2";
            chartSettings["numberformat"] = numberformat;
        }
        //sessionStorage.setItem('pos', position);
        sessionStorage.setItem('pos', undefined);
        var chartnumber = chartid.replace("chart", "");
        this.state.chartnumber = chartnumber;
        //This is to remove previous chart from DOM
        //this.unbindEvents(chartnumber);

        //Prefix/Suffix from Advanced Chart Settings
        var yaxislabelprefix = "", yaxislabelsuffix = "", datalabelprefix = "", datalabelsuffix = "", tooltipprefix = "", tooltipsuffix = "";
        if (chartSettings["AdvancedChartSettings"] != undefined) {
            var advanced = chartSettings["AdvancedChartSettings"];
            yaxislabelprefix = advanced.yAxis[0].axisLabelPrefix;
            yaxislabelsuffix = advanced.yAxis[0].axisLabelSuffix;

            datalabelprefix = advanced.plotOptions.series.dataLabels.dataLabelPrefix;
            datalabelsuffix = advanced.plotOptions.series.dataLabels.dataLabelSuffix;

            tooltipprefix = advanced.tooltip.tooltipPrefix;
            tooltipsuffix = advanced.tooltip.tooltipSuffix;

            if (chartType != "invertedstackedbar" && chartType != "invertedbar") {
                advanced.chart.inverted = false;
            } else if (chartType === "invertedstackedbar" || chartType === "invertedbar") {
                advanced.chart.inverted = true;
            }
        }

        if ($('.tileview[data-num=' + chartnumber + ']').length === 1) {
            var setheight = $('.tileview[data-num=' + chartnumber + ']').height() - 50;
            $("#chart" + chartnumber).height(setheight);
        }
        var attropen = $(".rightattributes.open").length;
        var attrwidth = $(".rightattributes.open").width();
        if (attropen > 0) { attrwidth = attrwidth + 0 }
        $(".fullscreenmain #chart" + chartnumber + "").css("max-width", "calc(100vw - " + attrwidth + "px)");

        $("#chart" + chartnumber + " .highcharts-container").remove();
        isExportChartEnable=ChartUtil.checkExportChartEnable(features, position, isSharedReport);
        //var userLang = navigator.language || navigator.userLanguage;
        //var locale = getLocaleName(userLang);
        var locale = "en_za";
        // var that = this;
        var categories = [];
        var trendstatsdata = data;
        var legendExceedDataInfo = false;
        if (legend != undefined && legend.columnName.length > 0) {
            _.each(data, function (dataObject, i) {
                if (dataObject.length > 0 && dataObject[0] != undefined && dataObject[0]["legendExceedDataInfo"] != undefined && dataObject[0]["legendExceedDataInfo"]) {
                    legendExceedDataInfo = true;
                }
            });
        }
        if (trendstats == "trendstats") {
            var tempDataArray = [];
            _.each(data, function (dataObject, i) {
                if (legend == undefined) {
                    if (dataObject["data"] != undefined) {
                        tempDataArray.push(dataObject);
                    }
                }
                else {
                    _.each(yaxisnameArray, function (yAxisObject, j) {
                        if (dataObject["data"] == undefined && dataObject[yAxisObject] != undefined) {
                            tempDataArray.push(dataObject);
                        }
                        else {
                            if (j == 0) {
                                tempDataArray.push(dataObject);
                            }

                        }
                    })
                }
            });
            data = tempDataArray;

        }
        var referenceLineMap = {};
        if (legend != undefined && legend.columnName.length > 0) {
            categories = data[0];
            var tempdata = [];
            var drilldowndata = [];
            drilldowndata.push($.extend({}, data[0]));
            sessionStorage.setItem("drilldowndata" + chartid.split("chart")[1], JSON.stringify(drilldowndata));
            if (data.length > 1) {
                _.each(data, function (i, j) {
                    if (j & 1 == 1) {
                        tempdata = tempdata.concat(data[j]);
                    }
                });
                data = tempdata;
            } else {
                data = data[1];
            }
        }
        if (chartType === "multixbar") {
            categories = data[0].categories;
        }
        var appendTD = "";
        for (var i = 0; i < data.length; i++) {
            appendTD += "<td></td>"
        }
        $('#' + chartid).append('<table id="trellis" data-num=' + chartnumber + '><tr>' + appendTD + '</tr></table>');

        var $containers = $('#trellis[data-num=' + chartnumber + '] td');
        var acwidth = $('#chart' + chartnumber).width() - 10;
        var acheight = $('#chart' + chartnumber).height() - 20;
        var twidth = acwidth / data.length;
        // if (twidth < 300) { twidth = 300; }
        $('#' + chartid).css({ 'overflow-x': 'auto', 'overflow-y': 'hidden' });
        if (data.length > 4) {
            if (chartSettings != undefined && chartSettings.trellisFitToWidth != undefined && chartSettings.trellisFitToWidth == true) {
                var twidthfirst = twidth + 100;
                var twidthfirst = twidthfirst - (100 / data.length);
                var twidth = twidth - (100 / data.length);
                if (twidth < 100) { twidth = 100; }
            } else {
                var twidthfirst = twidth + 100;
                if (twidth < 300) { twidth = 300; }
            }
        } else {
            var twidthfirst = twidth;
        }
        $('#trellis[data-num=' + chartnumber + '] td:first-child').width(twidthfirst + "px");
        $('#trellis[data-num=' + chartnumber + '] td:not(:first-child)').width(twidth + "px");

        var options = {};
        var chart = {};
        var trellicharts = {};
        var maxval = [];
        /*Calcuate Max Y value var maxval = Math.max.apply(Math,data[0].data.map(function(o){return o.y;}));*/
        $.each(data, function (i, datasingle) {
            maxval[i] = Math.max.apply(Math, datasingle.data.map(function (o) { return o.y; }));
        });
        maxval = Math.max.apply(Math, maxval);
        maxval = Math.ceil(maxval);
        //					var dummydata1 = [];
        //					_.each(data, function(datadata,index){
        //						if(index>0){
        //							for(var d0 = 0; d0<data[0].data.length; d0++){
        //								for(var d1 = 0; d1<data[index].data.length; d1++){
        //									if(data[index].data[d1].name == data[0].data[d0].name){
        //										dummydata1.push(data[index].data[d1]);
        //									}
        //								}
        //							}
        //							data[index].data = dummydata1;
        //						}
        //					})
        var datasets = data;

        $.each(datasets, function (i, dataset) {
            if (chartSettings != undefined && chartSettings.trellisDynamicScaling != undefined && chartSettings.trellisDynamicScaling == true) {
                maxval = Math.max.apply(Math, dataset.data.map(function (o) { return o.y; }));
            }
            options[i] = {
                chart: {
                    events: {
                        drilldown: function (e) {
                            $('.messageicon').removeClass('birdmessage-success  birdmessage-warning birdmessage-error').addClass('birdmessage-infor');
                            $('.messageicon i').removeClass('fa-check fa-exclamation-triangle fa-times-circle-o ').addClass('fa-info-circle');
                            $('.birdmessagebuttons').removeClass('show');
                            $('.birdmessagebuttons').addClass('hide');
                            $('.birdmessage-center').addClass("message-top-in");
                            $('.details').removeClass('show');
                            $('.details').addClass('hide');
                            $('.birdmessage h2').empty().append('Information');
                            $('.birdmessage-info').empty().text(getMessage('BirdWarning17'));
                        }
                    },
                    renderTo: $containers[i],
                    type: 'bar',
                    marginLeft: i === 0 ? 140 : 10,
                    animation: false,
                    width: i === 0 ? twidthfirst : twidth,
                    height: acheight,
                    zoomType: 'xy',
                    className: 'invertedyaxis',
                    backgroundColor: 'transparent',
                    //height:'auto'
                },

                title: {
                    text: dataset.name,
                    align: 'left',
                    x: i === 0 ? 90 : 0,
                    useHTML: true
                },
                xAxis: {
                    // categories: categories,
                    type: 'category',
                    labels: {
                        enabled: i == 0 ? true : false,
                        formatter: function () {
                            return '<span title="' + this.value + '">' + this.value + '</span>';
                        },
                        useHTML: true,
                        style: {
                            maxWidth: '120px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }
                    },
                    title: {
                        text: xaxisname,
                        orginaltextName: xaxisColumnName,
                        style: {
                            width: '80px',
                            whiteSpace: 'nowrap'
                        },
                        useHTML: true,
                    },
                },

                yAxis: [{
                    allowDecimals: false,
                    title: {
                        text: null,
                        useHTML: true,
                        style: {
                            color: "#757575",
                            background: "transparent",
                            borderRadius: "0px",
                            maxWidth: "200px",
                            overflow: "hidden"
                        }
                    },
                    labels: {
                        formatter: function () {
                            if (yAxisDataTypeArray != undefined && yAxisDataTypeArray.length == 1 && yAxisDataTypeArray.indexOf('date') >= 0 && yAxisAggArray.toLocaleString().indexOf('count') < 0) {
                                var d = new Date(this.value);
                                return d.toLocaleString();
                            } else if (chartSettings["AdvancedChartSettings"] != undefined) {
                                if (chartSettings["AdvancedChartSettings"].plotOptions.series.stacking === "percent") {
                                    return this.value + '%';
                                } else {
                                    var num = ChartInfo.functions.addFormatting(this.value, chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator)
                                    return yaxislabelprefix + num + yaxislabelsuffix;
                                }
                            } else {
                                var num = ChartInfo.functions.addFormatting(this.value, chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator)
                                return yaxislabelprefix + num + yaxislabelsuffix;
                            }
                        },
                    },
                    max: maxval,
                    plotLines: []
                }],
                drilldown: {
                    // activeAxisLabelStyle: {
                    //     cursor: 'pointer',
                    //     textDecoration: 'none',
                    //     color: '#000',
                    //     fontWeight: '400'
                    // },
                    // activeDataLabelStyle: {
                    //     cursor: 'pointer',
                    //     textDecoration: 'none',
                    //     color: '#000',
                    //     fontWeight: '400'
                    // },
                    series: []
                },
                tooltip: {
                    formatter: function () {
                        var name = '<span style="font-size:10px">' + this.key + '</span><table>'
                        var details = JSON.parse(ChartInfo.functions.getChartDetails(chartnumber));
                        var that = this;
                        var aggrigationName = that.series.options.aggType;
                        if(aggrigationName==='customaggregatedfield'){
                            aggrigationName = 'raw'
                        }
                        if (details != undefined && details['yAxis'].length != 0) {
                            var seriesName = (this.series.options.displayname == undefined) ? this.series.name : this.series.options.displayname;
                            $.each(that.series.options.data, function (i, j) {
                                if (j.name == that.key && j.y == that.y && j.aggType != undefined && j.aggType == that.point.aggType) {
                                    aggrigationName = j.aggType;
                                }

                            });
                            name += '<tr><td style="color:' + that.series.color + ';padding:0">' + seriesName + ' <span class=bocaggtype>[' + aggrigationName + '] </span>' + ':&nbsp; </td>';
                        }
                        if (this.point.ydatatype == 'date' && this.series.options.aggType.toLowerCase().indexOf("count") < 0) {
                            var date = new Date(this.y);
                            name += '<td style="padding:0"><b>' + date.toLocaleString() + '</b></td></tr>';
                        }
                        else {
                            var num = ChartInfo.functions.addFormatting(this.y, chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator)
                            name += '<td style="padding:0"><b>' + tooltipprefix + num + tooltipsuffix + '</b></td></tr>';
                        }
                        var that = this;
                        if (tooltipNameArray.length != 0) {
                            $.each(tooltipNameArray, function (i) {
                                var tooltipnow = tooltipNameArray[i];
                                var tooltipvalue = "t" + i;
                                var tooltipagg = "ta" + i;
                                if (that.point != undefined && that.point[tooltipagg] != undefined) {
                                    name +='<tr><td style="color:'+that.series.color+';padding:0">'+tooltipnow + ' <span class=bocaggtype>[';
                                    name +=that.point[tooltipagg]=='customaggregatedfield'?'raw':that.point[tooltipagg]+'] </span>' + ': </td>';
                                }
                                if (that.point[tooltipvalue] == 0) {
                                    name += '<td style="padding:0"><b>' + tooltipprefix + that.point[tooltipvalue] + tooltipsuffix + '</b></td></tr>'
                                } else {
                                    var num = ChartInfo.functions.addFormatting(that.point[tooltipvalue], chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator)
                                    name += '<td style="padding:0"><b>' + tooltipprefix + num + tooltipsuffix + '</b></td></tr>'
                                }
                            });
                        }
                        return name;
                    },
                    shared: false,
                    useHTML: true,
                },
                plotOptions: {
                    column: {
                        colorByPoint: true
                    },
                    series: {
                        dataLabels: {
                            enabled: false,
                            style: { textShadow: 'none' },
                            formatter: function () {
                                if (this.point.ydatatype == 'date' && this.series.options.aggType.toLowerCase().indexOf("count") < 0) {
                                    var date = new Date(this.y);
                                    return date.toLocaleString();
                                } else {
                                    var num = ChartInfo.functions.addFormatting(this.point.y, chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator)
                                    return datalabelprefix + num + datalabelsuffix;
                                }
                            }
                        }
                    },
                },
                legend: {
                    enabled: false
                },
                boost: {
                    enabled: false
                },
                series: [dataset],
                colors: colorThemesArray[colorThemeIndex],
                exporting: {
                    enabled: false,
                },
                credits: {
                    enabled: false
                }
            }

            /*-------Chart Settings------*/
            if ($(window).width() < 780) {
                options[i].chart.zoomType = 'none';
            }

            if (chartSettings['legend'] == true) {
                options[i].legend.enabled = true;
            } else {
                options[i].legend.enabled = false;
            }

            if (chartSettings['xAxisLabel'] == true) {
                options[i].xAxis.title.text = i == 0 ? xaxisname : null;
            } else {
                options[i].xAxis.title.text = null;
            }

            if (chartSettings['yAxisLabel'] == true) {
                options[i].yAxis[0].title.text = dataset.displayname;
            } else {
                options[i].yAxis[0].title.text = null;
            }

            if (chartSettings['crossHairs'] == true) {
                options[i].tooltip.crosshairs = [{
                    width: 2,
                    color: 'gray',
                    dashStyle: 'shortdot'
                }, {
                    width: 2,
                    color: 'gray',
                    dashStyle: 'shortdot'
                }];
            } else {
                options[i].tooltip.crosshairs = false;
            }

            if (chartSettings['gridLine'] == true) {
                options[i].yAxis[0].gridLineWidth = 1;
            } else {
                options[i].yAxis[0].gridLineWidth = 0;
            }

            if (chartSettings['dataLabel'] == true) {
                options[i].plotOptions.series.dataLabels.enabled = true;
            } else {
                options[i].plotOptions.series.dataLabels.enabled = false;
            }
            var currentColors = colorThemesArray[colorThemeIndex]

            if (currentColors[i] === undefined) {
                currentColors = currentColors.concat(currentColors)
            }

            options[i].series[0].color = currentColors[i]

            //Add Advanced Chart Settings
            if (chartSettings["AdvancedChartSettings"] != undefined) {
                var advanced = chartSettings["AdvancedChartSettings"];
                advanced.title.text = options[i].title.text;
                var xAxis = options[i]["xAxis"]
                var advancedxaxis = advanced["xAxis"];
                var yAxis = options[i]["yAxis"]
                var advancedyaxis = advanced["yAxis"];
                $.extend(true, options[i], advanced);
                options[i]["xAxis"] = $.extend(true, xAxis, advancedxaxis);
                options[i]["yAxis"] = $.extend(true, yAxis, advancedyaxis);

            }

            chart[i] = new Highcharts.Chart(options[i]);
            trellicharts = chart
            charts[chartid] = trellicharts;
            var chartnum = chartid.replace("chart", "");
            //that.setTreillsChartSize(chartnum)

        });
        if (legendExceedDataInfo) {
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-infor').addClass('birdmessage-warning');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-info-circle').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(getMessage("BirdWarning110"));
            /*options.subtitle={text:"Series Exceeds"}; 
            options.subtitle.style={color:"red"} */
        }
        //Fix for textOutline for DataLabels
        if (chartSettings["AdvancedChartSettings"] != undefined) {
            var advanced = chartSettings["AdvancedChartSettings"];
            //advanced.chart.type=chartType
            ChartUtil.customTextOutline(advanced, chartnumber);
        }
        return (
            <></>
        );
    }
}