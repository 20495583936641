import React from "react";

export default class AddAccountTemplate extends React.Component{
    constructor(props){
        super(props);
    }
    
    render(){
        return(
            <div className="container-fluid">
            <div className="row in-back">
                <h2 className="boc-main-title">Create a New Account</h2>
                <form className="form-horizontal submitform">
                    <div className="form-group form-group-sm form-group">
                        <label for="accountname" className="col-sm-3 control-label important">Account Name</label>
                        <div className="col-sm-6">
                            <input type="name" className="form-control" id="accountname" name="accountname" placeholder=""></input>
                        
                            <span className="help-block accountnameerror hidden"></span>
                        </div>
                    </div>
                    <div className="form-group form-group-sm">
                        <label for="accountdesc" className="col-sm-3 control-label">Account Description</label>
                        <div className="col-sm-6">
                            <textarea placeholder="" id="accountdesc" rows="1" name="accountdesc" className="form-control textarea"></textarea>
                        </div>
                    </div>
                    
                    <div className="form-group form-group-sm form-group">
                        <label for="accountadmin" className="col-sm-3 control-label important">Admin Name</label>
                        <div className="col-sm-6">
                            <input type="name" className="form-control" id="accountadmin" name="adminname" placeholder=""></input>
                            <span className="help-block adminnameerror hidden"></span>
                        </div>
                    </div>
                    <div className="form-group form-group-sm form-group">
                        <label for="accountemail" className="col-sm-3 control-label important">Username (Email)</label>
                        <div className="col-sm-6">
                            <input type="email" className="form-control" id="accountemail" name="Email" placeholder=""></input>
                            <span className="help-block emailerror hidden"></span>
                        </div>
                    </div>
                    <div className="form-group form-group-sm">
                        <label for="password" className="col-sm-3 control-label">Status</label>
                        <div className="col-sm-9">
                            <div>
                                <input type="radio" id="1" name="status" value="A" checked="checked" />Active
                                <input type="radio" id="2" name="status" value="I" />Inactive
                            </div>
                        </div>
                    </div>
                    <div className="form-group form-group-sm">
                        <div className="col-sm-7">
                            <button type="button" className="btn btn-bird pull-right saveaccountform" onClick={this.props.saveAccount}>Add</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        );
    }
    
}