import React from "react";
import { lang } from "../js/helpers/utils/languages/language";
export default class StrorybookViewTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        var that = this.props;
        var active = "";
        var isSharedStorybook = false;
        if(window.location.pathname.indexOf("sharestorybook")>0){
            isSharedStorybook=true;
        }
        return (
            <section className="boc-viewreport-section storybooksection" >
                {/* <!-- viewstorybookview.js --> */}
                <div className="boc-home boc-dark-background">
                    <div id="reportheader">
                        {/* <!-- Report Title & options Goes Here (3_report_titlenoptions)--> */}
                    </div>
                    <div className="container-fluid height-100">
                        <div className="row height-100">
                            {/* <!-- left div starts --> */}
                            <div className="storybooktabs">
                                <div role="tabpanel" id="reportcontainertabs">
                                    <ul className="nav nav-tabs tabcontainer" role="tablist" id="newtabcontainerid">
                                        {/* <!-- Tabs list --> */}
                                        {that.tabslist.map((tab, index) => {
                                            active = ""
                                            if (tab.id == 0) {
                                                active = "active"
                                            }
                                            return <li key={index} role="presentation" className={`tabheadli ${active}`} tab-id={tab.id}>
                                                <a href={`#storybooktabcontent${tab.id}`} id={`storybooktab${tab.id}`} className="tabclick" onClick={that.tabclick} tab-id={tab.id} reportid={tab.reportId} role="tab" data-toggle="tab">{tab.name}</a>
                                                {!isSharedStorybook?
                                               <> <span className="dropdown-toggle storybooktabsettings" data-direction="left" data-toggle="dropdown" aria-expanded="false" title={lang["storybook.tabsettings"]}><i className="fa fa-cog"></i></span>
                                                <ul className="dropdown-menu dropdown-menu-left" style={{ left: "5px" }}>
                                                    <li><a href="javascript:void(0)" className="editstorybooktab" onClick={that.editstorybooktab} data-toggle="modal" tab-id={tab.id} data-target="#mySmallModal" title={lang["storybook.modifytab"]}><i className="fa fa-refresh marg-righ-10"></i>{lang["storybook.modifytab"]}</a></li>
                                                    {(tab.reportType == 'ml_storyboard') ? (
                                                        <li><a href="#viewmodel/<%=btoa(tab.reportId)%>/<%=btoa(tab.dataModelId)%>" target="_blank" className="editstorybookreport" onClick={this.editstorybookreport} tab-id={tab.id} title={lang["storybook.modifyreport"]}><i className="fa fa-pencil marg-righ-10"></i>{lang["storybook.modifyreport"]}</a></li>
                                                    ) : (tab.reportType == 'smartinsight_storyboard') ? (
                                                        <li><a href={`${that.url}/viewsmartinsight/${btoa(tab.reportId)}/${btoa(tab.dataModelId)}`} target="_blank" className="editstorybookreport" onClick={this.editstorybookreport} tab-id={tab.id} title={lang["storybook.modifyreport"]}><i className="fa fa-pencil marg-righ-10"></i>{lang["storybook.modifyreport"]}</a></li>
                                                    ) :
                                                            <li><a href={`${that.url}/viewreport/${btoa(tab.reportId)}`} target="_blank" className="editstorybookreport" onClick={this.editstorybookreport} tab-id={tab.id} title={lang["storybook.modifyreport"]}><i className="fa fa-pencil marg-righ-10"></i>{lang["storybook.modifyreport"]}</a></li>
                                                    }
                                                    <li><a href="javascript:void(0)" className="deletestorybooktab" onClick={that.deletestorybooktab} tab-id={tab.id} data-toggle="modal" data-target="#mySmallModal" title={lang["storybook.deltab"]}><i className="fa fa-trash marg-righ-10"></i>{lang["storybook.deltab"]}</a></li>
                                                </ul>
                                                 </>:null}
                                            </li>
                                        })}
                                    </ul>
                                    <div className="tab-content" id="newtabcontentid">
                                        {/* <!--- Tab Content Containers--> */}
                                        {that.tabslist.map((tab, i) => {
                                            active = ""
                                            if (i === 0) {
                                                active = "active"
                                            }
                                            return <div key={i} id={`storybooktabcontent${tab.id}`} role="tabpanel" className={`tab-pane ${active} storybookpagecontent`}></div>
                                        })}

                                    </div>
                                    {/* <!--\TabContent --> */}
                                </div>
                                {/* <!--\Tab panel --> */}
                            </div>
                            {/* <!--\Tab viewer --> */}

                        </div>
                    </div>
                    {(that.displayFooter != false) ? (
                        <></>
                        // <!-- <footer className="footer">
                        //     <div className="container-fluid">
                        //         <div className="row">
                        //             <div className="col-xs-7"></div>
                        //             <div className="col-xs-5">			 		
                        //                  <div className="itsaudit hide">
                        //                     <span className="livecirclecontainer dataauditlivecontainer hide">
                        //                         <span className="livecircle"></span> Data Audit
                        //                     </span>
                        //                     <span className="livecirclecontainer metadataauditlivecontainer hide">
                        //                         <span className="livecircle"></span>   Meta-Data Audit
                        //                     </span>
                        //                 </div>
                        //                 <div className="itslive hide"><span className="liveupdatetime"></span><span className="livecirclecontainer"><span className="livecircle"></span>   LIVE</span></div> 
                        //             </div>
                        //         </div>
                        //     </div>
                        // </footer> -->
                    ) : null}
                </div>
            </section>);
    }
    editstorybookreport(e){
      var href=window.location.origin+e.currentTarget.getAttribute("href");
      window.open(href, '_blank');
    }
}