import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import _ from 'underscore';
import PubSub from "pubsub-js"
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import ViewMLDataSnapShotView from "./viewmldatasnapshotview";
import SendRequest from '../../SendRequest';
import { getMessage } from '../../js/helpers/utils/BirdMessage';
const $=window.$;
export default class ViewMLReportBodyView extends React.Component {
    constructor(props) {
        super(props);
        this.options=props;
        this.state = {};
        this.render()
    }
    render() {
        var isRunModel = false;
        if (this.options.reportId == undefined) {
            isRunModel = true;
        }
        this.applyfiltesandredrawmlcharteve=PubSub.subscribe('applyfiltesandredrawmlchart',this.applyfiltesandredrawmlcharteve.bind(this));
        this.applyfiltesandredrawmlchart(isRunModel);
        return(<React.Fragment/>);
    }
    applyfiltesandredrawmlcharteve=(message,data)=>{
      this.applyfiltesandredrawmlchart(data.isRunModel,data.isReload);
    }
    applyfiltesandredrawmlchart(isRunModel, isReload) {
        var that = this;
        //Load SnapShot Data
        var globalfilterArray = [];
        var mainFilter = ChartInfo.functions.getFilterArray();
        //var customfield=JSON.parse(sessionStorage.getItem("customFields"));
        var customFields = sessionStorage.getItem("customFields");
        var temp = JSON.parse(customFields);
        var res;
        _.each(temp, function (customfields) {
            _.each(customfields.parametrFieldsArray, function (value) {
                var customparameter = ChartInfo.functions.getCustomParameter();
                var customParameterFilterArray = ChartInfo.functions.getCustomParametersFilters(undefined);
                if (customParameterFilterArray != undefined && customParameterFilterArray.length != 0) {
                    customparameter = customParameterFilterArray;
                }
                res = $.grep(customparameter, function (e) {
                    return e.parametername == value;
                })
                var formula = customfields.formula;
                var scriptcondition = customfields.scriptcondition;
                var fields = customfields.fields;
                var sqlformula = customfields.sqlformula;
                if (res.length != 0) {
                    customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
                    customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
                    customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
                    customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
                } else {
                    _.each(ChartInfo.functions.getCustomParameter(), function (values) {
                        if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
                            customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                            customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                            customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
                            customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                        }
                    });
                }
            });
        });
        customFields = temp;
        globalfilterArray = JSON.parse(JSON.stringify(globalfilterArray)).concat(mainFilter);
        var fromIndex = 0;
        var toIndex = 5;
        if (this.options.mlSavedSnapshotData != "" && this.options.mlSavedSnapshotData.length > 0 && !isRunModel && !isReload) {
            new ViewMLDataSnapShotView({
                data: JSON.parse(that.options.mlSavedSnapshotData),
                datamodelId: that.options.datamodelId,
                reportId: that.options.reportId!=undefined?that.options.reportId:"",
                customFields: JSON.stringify(customFields),
                filterObject: JSON.stringify(globalfilterArray),
                columnsArray: that.options.columnsArray,
                fromIndex: fromIndex,
                toIndex: toIndex,
                pageLimit: toIndex,
            });
        } else {
            let requestHeaders = {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            let formdata=new FormData();
            formdata.set("reportId",that.options.reportId!=undefined?that.options.reportId:"");
            formdata.set("customFields", that.options.reportId!=undefined?JSON.stringify(customFields):null);
            formdata.set("filterObject",that.options.reportId!=undefined?JSON.stringify(globalfilterArray):JSON.stringify([]));
            formdata.set("fromIndex",fromIndex);
            formdata.set("toIndex",toIndex);
            formdata.set("isDataTableChange",false);
            formdata.set("isRunModel",isRunModel);
            formdata.set("datamodelId",that.options.datamodelId);
            SendRequest({
                url: "getsnapshotdata",
                body:formdata, 
                sucessFunction: response => {
                    $('.mlloadingbtn').addClass("hide");
                    $('.refreshmlcharts').removeClass("hide");
                    $('.refreshmlcharts').removeClass("disabled");
                    if (that.options.reportId == undefined || that.options.reportId == '') {
                        $('.refreshmlcharts').addClass("hide");
                    }
                    if (response.hasOwnProperty('error')) {
                        $(".spinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show').text("Details");
                        $('.messagedetails xmp').empty().append(response.errorTrace)
                    } else {
                        new ViewMLDataSnapShotView({
                            data: response,
                            datamodelId: that.options.datamodelId,
                            reportId: that.options.reportId!=undefined?that.options.reportId:"",
                            customFields: JSON.stringify(customFields),
                            filterObject: JSON.stringify(globalfilterArray),
                            columnsArray: that.options.columnsArray,
                            fromIndex: fromIndex,
                            toIndex: toIndex,
                            pageLimit: toIndex,
                        });
                    }
                },
                rejectFunction: () => { },
                headers: requestHeaders
            })
        }

    }
}