import React, { Component } from "react";
import ReactDOM from 'react-dom';
import ResetPasswordHtml from "./resetpasswordhtml.jsx";
import {getMessage} from '../../js/helpers/utils/BirdMessage';
import PasswordsResetTable from "./passwordsresettablehtml.jsx";
import SendRequest from '../../SendRequest';
const $ =window.$;
class ResetPasswordView extends Component {
  constructor(props) {
    super(props);
      this.state = { };
    let featuresString = sessionStorage.getItem("features");
    featuresString = featuresString.slice(0, 3) + featuresString.slice(4);
    let decryptedString = window.atob(featuresString);
    this.state.parsedFeatureJson = JSON.parse(decryptedString);
    this.state.features= JSON.parse(decryptedString); 
    this.state.groupsresponse= {};
    this.state.rolesresponse= {};
    this.getGroupslist();
    this.getRoleslist();

    
  }
getGroupslist(){
  let requestHeaders = {
    method: "get",
    headers: new Headers({
        "Content-Type": "application/json"
    })
};
   SendRequest({
    url: "getAllGroups",
    queryString: "",
    sucessFunction: (response) => {
        if (response.hasOwnProperty('error')) {
            $(".spinner").hide();
        } else {
          this.state.groupsresponse=response;

        }
      },
      rejectFunction: () => { },
      headers: requestHeaders
    })
}
getRoleslist(){
  let requestHeaders = {
    method: "get",
    headers: new Headers({
        "Content-Type": "application/json"
    })
};
   SendRequest({
    url: "getRoles",
    queryString: "",
    sucessFunction: (response) => {
        if (response.hasOwnProperty('error')) {
            $(".spinner").hide();
        } else {
       this.state.rolesresponse=response;
       this.render();
        }
      },
      rejectFunction: () => { },
      headers: requestHeaders
    })
}
applyforusersdetails =(events)=> {
 // $('.spinner').show();
  events.preventDefault();
  var serialized = $('form').serializeArray();
			var s = '';
      var data = {};
      var that= this;
			for(s in serialized){
				data[serialized[s]['name']] = serialized[s]['value']
			}
			var result=JSON.stringify(data);
      var finaldata=JSON.parse(result);
      var passwordresetgroup= finaldata.passwordresetgroup;
      var role=finaldata.role;
      let x = new FormData();
      x.append('passwordresetgroup', passwordresetgroup);
      x.append('role',role);
			if(finaldata.passwordresetgroup=="" && finaldata.role==""){
		//		$(".showwarning").show();
			//	$('.spinner').hide();
				setTimeout(function() {
		       		 $('.showwarning').remove();	
		       	 }, 3000);
				return false;
      }
      
      let requestHeaders = {
        method: "post",
        headers: new Headers({
            "Content-Type": "application/json"
        })
    };
       SendRequest({
        url: "getuserstoresetpasswords",
        queryString: "",
        body:x,
        sucessFunction: (response) => {
            if (response.hasOwnProperty('error')) {
                $(".spinner").hide();
            } else {
              var data=response;
              if(response.length==0){
                response.push({userloginname:"No matching records found"});
              }
              if(response.length!=0 && response!=""){
           //   ReactDOM.render(<PasswordsResetTable />,document.getElementById("passwordsresettable")).empty();
               ReactDOM.render(<PasswordsResetTable resetpasswords={this.resetpasswords} />,document.getElementById("passwordsresettable"));
               $("#passwordsresetusersdata").bootstrapTable({data:response});
                $("#passwordsresetusersdata").bootstrapTable('load',response);
                $('.spinner').hide();
              }
            }
            
          },
          rejectFunction: () => { },
          headers: requestHeaders
        })
		
					
}
resetpasswords=()=>{
  var serialized = $('form').serializeArray();
  var s = '';
  var data = {};
  var dataarray=[];
  for(s in serialized){
    if(serialized[s].name=="btSelectItem"){
      dataarray.push(data[serialized[s]['data-id-field']] = serialized[s]['value'])
    }
  }
  var result=JSON.stringify(dataarray);
  var finaldata=JSON.parse(result);

  let x = new FormData();
  x.append('usersdata', result);
  if(finaldata[0]=='No matching records found' || finaldata.length==0){
    $('.spinner').hide();
    return false;
  }
  let requestHeaders = {
    method: "post",
    headers: new Headers({
        "Content-Type": "application/json"
    })
};
   SendRequest({
    url: "bulkpasswordsreset",
    queryString: "",
    body:x,
    sucessFunction: (response) => {
      if(response.hasOwnProperty('error')){
        $(".spinner").hide();
        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
        $('.birdmessagebuttons').removeClass('show');
        $('.birdmessagebuttons').addClass('hide');
        $('.birdmessage-center').addClass("message-top-in");
        $('.details').removeClass('hide');
        $('.birdmessage h2').empty().append('Error : ');
        $('.birdmessage h2').append('<span class="errorcode">');
        $('.birdmessage .errorcode').empty().append(response.errorCode);
        $('.birdmessage-info').empty().text(response.error);
        $('.details').addClass('show');
        $('.messagedetails xmp').empty().append(response.errorTrace)
      }else {
        $("#licenserenewmodel").modal("hide");
        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
        $('.birdmessagebuttons').removeClass('show');
        $('.birdmessagebuttons').addClass('hide');
        $('.birdmessage-center').addClass("message-top-in");
        $('.details').removeClass('show');
        $('.details').addClass('hide');
        $('.birdmessage h2').empty().append('Success');
        $('.birdmessage-info').empty().text(getMessage('BirdSuccess59'));
      }
        
      },
      rejectFunction: () => { },
      headers: requestHeaders
    })

}
  render(){
    var that=this.props;
   
    ReactDOM.render(<ResetPasswordHtml groupsresponse={this.state.groupsresponse} rolesresponse={this.state.rolesresponse} applyforusersdetails={this.applyforusersdetails}  features={this.state.features}/>,document.querySelector(".passwordsresetfromadmin"));
  return (
<div></div>
    );
};
}
export default ResetPasswordView;