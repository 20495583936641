import _ from 'lodash';
import React, { Component } from "react";
import ReactDOM from "react-dom";
import { lang } from "../../js/helpers/utils/languages/language";
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
const $ = window.$;


export default class PivotValueAttribute extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columnName: props.columnName,
            columnDisplayName: props.columnDisplayName,
            aggregation: props.aggregation,
            dataType: props.dataType,
            tableName: props.tableName,
            chartnumber: props.chartnumber,
            percentilevalue: props.percentilevalue,
        }
        this.state.isStoredProcedure = ChartInfo.functions.getIsStoredProcedure();
        this.state.isDirect = ChartInfo.functions.getIsDirect();
        this.render();
    }
    render() {
        this.state.el = ".pivotvalues";
        var datatype = this.state.dataType;
        var convertedValues = JSON.parse(sessionStorage.getItem("convertedtypes"));
        var that = this;
        if (convertedValues != null && convertedValues != undefined) {
            _.each(convertedValues, function (convertValue) {
                if (convertValue.columnName == that.state.columnDisplayName) {
                    datatype = convertValue.dataType;
                }
            });
        }

        var today = new Date();
        var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
        $(this.state.el).append("<div className='" + dynamicdiv + "hide' id='" + dynamicdiv + "'></div>");

        ReactDOM.render(<PivotValueAttributeTemplate changeAggregation={this.changeAggregation} deletevalue={this.deletevalue} dynamicdiv={dynamicdiv} chartnumber={this.state.chartnumber} columnName={this.state.columnName}
            aggregation={this.state.aggregation} dataType={datatype} tableName={this.state.tableName} columnDisplayName={this.state.columnDisplayName} isDirect={this.state.isDirect} isStoredProcedure={this.state.isStoredProcedure}
        />, document.getElementById(dynamicdiv));
        $(this.state.el).find('.percentilebox').val(this.state.percentilevalue == undefined ? 25 : this.state.percentilevalue);
        return (<div></div>);
    }
    deletevalue = (e) => {
        var $this = e.currentTarget;
        if (this.state.isDirect == 1 && this.state.isStoredProcedure == 1) {
            var currentvalue = $($this).closest('span').prev().text();
        } else {
            var currentvalue = $($this).closest('span').prev().prev().text();
        }
        $(".pivotvalues li[title='" + currentvalue + "']").parent().remove();
    }
    changeAggregation = (e) => {
        $(this.state.el).find(".aggregation-li-item").removeClass("active");
        $(e.currentTarget).addClass("active");
        $(this.state.el).find("#pivotdropdownaggregation>span").text($(e.currentTarget).text());
        var columnName = $(e.currentTarget).closest(".pivotvalueattribute").attr("data-name");
        var columnDisplayName = $(e.currentTarget).closest(".pivotdisplayvalues").attr("title");
        var aggregation = $(e.currentTarget).attr("title");
        var aggregationtext = $(e.currentTarget).text();
    }
}

class PivotValueAttributeTemplate extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        var that = this.props;
        return (
            <li className="boc-list-item singleliitems pivotdisplayvalues" title={that.columnDisplayName} data-parent={that.tableName} data-child={that.columnName} data-type={that.dataType}>
                {/* <!-- pivotvalueattribute.js --> */}
                <span className="itemtype">
                    {(that.dataType === 'number') ? (
                        123
                    ) : (that.dataType === 'date' || that.dataType === 'datetime' || that.dataType === 'month' || that.dataType === 'day') ? (
                        <i className="fa fa-calendar"></i>
                    ) : (that.dataType === 'customhierarchy' || that.dataType === 'custommeasurehierarchy') ? (
                        <i className="fa fa-list"></i>
                    ) : lang["storyboard.abc"]
                    }</span>
                <span className="itemname pivotvalueattribute" data-parent={that.tableName} data-child={that.columnName} data-type={that.dataType}>{that.columnDisplayName}</span>
                {(that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                    <span className="boclabel boclabel-right-5 dropdown-toggle marg-righ-15 agrregation-sm" type="button" id="pivotdropdownaggregation" aria-expanded="false" data-toggle="dropdown"><span>{that.aggregation}</span>&nbsp;<i className="fa fa-angle-down"></i></span>
                ) : null}
                <span className="btn-group pull-right"><button className="btn btn-transparent deletevalue" onClick={that.deletevalue} type="button"><i className="fa fa-trash"></i></button></span>
                <ul aria-labelledby="dropdownaggregation" role="menu" className="dropdown-menu dropdown-inline">
                    {(that.dataType != 'string' && that.dataType != 'date') ? (
                        (that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                            <><li className="aggregation-li-item pivotaggregation aggsum" onClick={that.changeAggregation} title={lang["storyboard.sum"]}>{lang["storyboard.sum"]}</li>
                                <li className="aggregation-li-item pivotaggregation aggmin" onClick={that.changeAggregation} title={lang["storyboard.minimum"]}>{lang["storyboard.minimum"]}</li>
                                <li className="aggregation-li-item pivotaggregation aggmax" onClick={that.changeAggregation} title={lang["storyboard.max"]}>{lang["storyboard.max"]}</li>
                                <li className="aggregation-li-item pivotaggregation aggavg" onClick={that.changeAggregation} title={lang["storyboard.avg"]}>{lang["storyboard.avg"]}</li>
                                <li className="aggregation-li-item pivotaggregation aggstandarddeviation" onClick={that.changeAggregation} title={lang["storyboard.standard"]}>{lang["storyboard.standard"]}</li>
                                <li className="aggregation-li-item pivotaggregation aggmedian" onClick={that.changeAggregation} title={lang["storyboard.median"]}>{lang["storyboard.median"]}</li>
                                <li className="aggregation-li-item pivotaggregation aggpercentile" onClick={that.changeAggregation} title={lang["storyboard.percentile"]} >{lang["storyboard.percentile"]}
        <span className="opacity-effect"><input type="number" className="percentilebox" placeholder="1" min="25" max="100" defaultValue="25" /></span>
                                </li></>
                        ) : null

                    ) : null}
                    {(that.isDirect == 0 || that.isStoredProcedure == 0) ? (
                        <> <li className="aggregation-li-item pivotaggregation aggcountdistinct" onClick={that.changeAggregation} title={lang["storyboard.countdis"]}>{lang["storyboard.countdistinct"]}</li>
                            <li className="aggregation-li-item pivotaggregation aggcount" onClick={that.changeAggregation} title={lang["storyboard.count"]}>{lang["storyboard.countall"]}</li></>
                    ) : null}

                </ul>
            </li>
        );
    }

}