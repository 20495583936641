import React from "react";
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import ReactDOM from "react-dom";
import ZaxisDropDownTemplate from "./zaxisdropdown.jsx"

export default class ZaxisDropDownView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicdiv: props.dynamicdiv,
            addzaxisvalue: props.addzaxisvalue,
            columnDisplayName: props.columnDisplayName,
            columnName: props.columnName,
            dataType: props.dataType,
            tableName: props.tableName,
            aggregation: props.aggregation,
            percentileValue: props.percentileValue,
            multifact: (props.multifact == 'true'),
            multifactentities: (props.multifact == 'true') ? JSON.parse(props.multifactentities) : props.multifactentities,
        };
        this.render();
    }
    render() {
        var disable = false;
        var facttables = this.props.facttables;
        if (this.state.multifact) {
            disable = ChartInfo.functions.getAllowedEntities(this.state.multifactentities, this.state.columnName, this.props.customfield, undefined, undefined, facttables, "zdropdown");
        }
        ReactDOM.render(<ZaxisDropDownTemplate
            addzaxisvalue={this.state.addzaxisvalue}
            dynamicdiv={this.state.dynamicdiv}
            columnDisplayName={this.state.columnDisplayName}
            columnName={this.state.columnName}
            dataType={this.state.dataType}
            aggregation={this.state.aggregation}
            tableName={this.state.tableName}
            percentileValue={this.state.percentileValue}
            disable={disable}
        ></ZaxisDropDownTemplate>, document.getElementById(this.state.dynamicdiv));
        return (<div></div>);
    }
}
