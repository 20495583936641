export var whitelableMap = window.getWhiteLabel();


//Demo Mode
// whitelableMap["DemoMode"] = false;
// //Meta Tag Info
// whitelableMap["metatitle"]= "Your Company"; //Meta Title
// whitelableMap["metakeyword"]= "version 2.2"; //Meta Keywords
// whitelableMap["metadescription"]= "version 2.2"; //Meta Description
// whitelableMap["metaauthor"]= "Your Company"; //Meta Author
// whitelableMap["metatheme"]= "#00bcd4"; //Meta Theme Color - Used to change Android Chrome Browser navigation
// whitelableMap["metafavicon"]= "../../../images/whitelable/logo-mini-default.svg"; //Favicon Path (Size: 32x32px)

// //Font-Family
// whitelableMap["fontfamily"]='"Work Sans", "Lato", "Source Sans Pro",sans-serif';//Font Family for Charts

// //Text
// whitelableMap["footertext"]="Your Comapny\u00AE. All Rights Reserved."; //Footer Text - Use UNICODES for special symbols
// whitelableMap["alttextbrand"]="Your Company"; //Use for image alt text of Brand/Product Name in Login Page, Dashboard


// //Login Page
// whitelableMap["loginfootertext"]="Your Company\u00AE\. All Rights Reserved."; //Footer Text - Use UNICODES for special symbols
// whitelableMap["loginlogo"]="../../../images/whitelable/logo-mini-default.svg"; //Login Page Logo (Size: 233x129px - Minimum size to avoid blurry images: 144x80px)

// //Header Navigation
// whitelableMap["aboutetext"]="About Your Company"; //Top navbar drop-down About branding & About Product Modal Title

// whitelableMap["headerlogo"]="../../../images/whitelable/bird-logo-default.svg"; //Logo to show in header (Size:132x68 - Minimum size to avoid blurry images: 66x34px) 










// DemoLablesMap["metatitle"]= "BIRD Analytics";
// DemoLablesMap["metakeyword"]= "version 2.2";
// DemoLablesMap["metadescription"]= "version 2.2";
// DemoLablesMap["metaauthor"]= "Bird";
// DemoLablesMap["metatheme"]= "#00bcd4";
// DemoLablesMap["metafavicon"]= "../../../images/whitelable/logo-mini.svg";
// DemoLablesMap["fontfamily"]='"Work Sans", "Lato", "Source Sans Pro",sans-serif';
// DemoLablesMap["footertext"]="BIRD Analytics\u00AE. All Rights Reserved.";
// DemoLablesMap["alttextbrand"]="BIRD Analytics";
// DemoLablesMap["loginfootertext"]="BIRD Analytics\u00AE\. All Rights Reserved.";
// DemoLablesMap["loginlogo"]="../../../images/whitelable/logo-mini.svg";
// DemoLablesMap["aboutetext"]="About BIRD Analytics";
// DemoLablesMap["headerlogo"]="../../../images/whitelable/bird-logo-night.svg";

// function getText(message){
// 	if(whitelableMap["DemoMode"]==true){
// 		if(DemoLablesMap[message]){
// 			return DemoLablesMap[message];
// 		} else {
// 			return '';
// 		}
// 	} else {
// 		if(whitelableMap[message]){
// 			return whitelableMap[message];
// 		} else {
// 			return '';
// 		}
// 	}
// }

// function init (){
// 	if(whitelableMap["DemoMode"]==true){
// 		whitelableMap = DemoLablesMap
// 	}
// }
// init();