import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import SendRequest from '../SendRequest';
import { getMessage } from '../js/helpers/utils/BirdMessage';
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import { Tab } from 'react-bootstrap';
import * as TabInfo from "../js/helpers/utils/tabinfo";
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;

export default class SaveStoryBookModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            storyBookId: 0,
            savedcollection: props.savedcollection
        };
        this.render();
    }
    render() {
        ReactDOM.unmountComponentAtNode(document.querySelector(".savestorybookdiv"));
        if (this.state.savedcollection != undefined && this.state.savedcollection.length > 0) {
            var storyBookData = this.state.savedcollection;
            this.state.storyBookId = storyBookData[0].storybook_id;
            ReactDOM.render(<SaveStoryBookTemplate applystorybook={this.applystorybook} storybookname={storyBookData[0].storybook_name} storybookdesc={storyBookData[0].storybook_desc} />, document.querySelector(".savestorybookdiv"));
        } else {
            ReactDOM.render(<SaveStoryBookTemplate applystorybook={this.applystorybook} storybookname={""} storybookdesc={""} />, document.querySelector(".savestorybookdiv"));
        }
        $(".savestorybookdiv #savestorybook").modal("show");
        return (<div></div>);
    }
    applystorybook = () => {
        var that = this;
        var bookname = $('.savestorybookdiv').find("#storybookname").val();
        var bookdesc = $('.savestorybookdiv').find("#storybookdesc").val();
        if (bookname.trim() == "" || bookname == undefined || (/[~`!@#$%^&*()+\=\[\]{};':\\|†,.<>\/?"`]/.test(bookname.trim()))) {
            $('.help-block.bookname-error').removeClass('hide');
            return;
        } else {
            bookname = bookname.trim();
            $('.help-block.bookname-error').addClass('hide');
        }

        var reportsdata = ChartInfo.functions.getTempStoryBookDetails();
        var formdata = new FormData();
        formdata.set("bookname", bookname);
        formdata.set("bookdesc", bookdesc);
        formdata.set("storyBookId", that.state.storyBookId == undefined ? 0 : that.state.storyBookId);
        formdata.set("reportsdata", JSON.stringify(reportsdata));

        let requestHeaders = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "savestorybook",
            body: formdata,
            sucessFunction: (collection) => {
                if (collection.hasOwnProperty('error')) {
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('hide');
                    $('.birdmessage h2').empty().append('Error : ');
                    $('.birdmessage h2').append('<span class="errorcode">');
                    $('.birdmessage .errorcode').empty().append(collection.errorCode);
                    $('.birdmessage-info').empty().text(collection.error);
                    $('.details').addClass('show');
                    $('.messagedetails xmp').empty().append(collection.errorTrace)
                    $(".savestorybookdiv #savestorybook").modal("show");
                    $(".spinner").hide();
                    return;
                } else {
                    $(".spinner").hide();
                    $(".savestorybookdiv #savestorybook").modal("hide");
                    $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                    $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('show');
                    $('.details').addClass('hide');
                    $('.birdmessage h2').empty().append('Success');
                    $('.birdmessage-info').empty().text(getMessage('BirdSuccess62'));
                    setTimeout(function () {
                        var href = "/visualization";
                        if (collection != undefined && collection.reportId != undefined && collection.reportId != null && (that.state.storyBookId == undefined || that.state.storyBookId == 0)) {
                            href = TabInfo.functions.getURL() + "/viewstorybook/" + btoa(collection.reportId);
                            window.open(href, '_self')
                        }
                    }, 2000);
                }
            },
            rejectFunction: this.reportDataError,
            headers: requestHeaders
        });
    }
}

class SaveStoryBookTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        var that = this.props;
        return (
            <div className="modal fade in" id="savestorybook" tabindex="-1" role="dialog" aria-labelledby="pagesettingsmodal" aria-hidden="true" style={{ display: "block" }}>
                {/* <!-- viewsavestorybookmodel.js --> */}
                <div className="modal-backdrop fade in"></div>
                <div className="modal-dialog modal-lg modal-save-report modal-dialog-lg-center modal-dialog-center" style={{ zIndex: "1050" }}>
                    <div className="modal-content">
                        <div className="modal-header boc-modal-hf-padding">
                            <button type="button" className="close white" id="storyboardclose" aria-hidden="true" data-dismiss="modal">&times;</button>
                            <h4 className="modal-title"><i className="fa fa-floppy-o"></i> {lang["storybook.savebook"]}</h4>
                        </div>
                        <div className="modal-body scrollset">
                            <div className="container-fluid">
                                <div className="row filtercontent">
                                    <fieldset className="savereport-border">
                                        <legend className="savereport-border">{lang["storybook.bookdetails"]}</legend>
                                        <div className="control-group">
                                            <div className="validationgroup form-group" style={{ marginTop: "-2px" }}>
                                                <span className="required right legend"></span>
                                                <label className="subhead" for="storybookname">{lang["storybook.name"]} </label>
                                                <input type="name" id="storybookname" name="storybookname" defaultValue={that.storybookname} className="form-control input-sm" placeholder={lang["storybook.placeholdname"]} />
                                                <span className='bookname-error help-block marg-left-10 hide'>{lang["storybook.validname"]}</span>
                                            </div>
                                            {/* <!-- 	<div className="validationgroup form-group hidden-lg hidden-md hidden-sm hidden-xs"> --> */}
                                            <label className="subhead">{lang["storybook.description"]} </label>
                                            <textarea id="storybookdesc" placeholder={lang["storybook.placeholdescription"]} defaultValue={that.storybookdesc} name="storybookdesc" className="form-control input-sm resize-vertical"></textarea>
                                            <span className="help-block hidden"></span>
                                            {/* <!-- </div> --> */}
                                        </div>
                                    </fieldset>

                                </div>
                            </div>
                        </div>
                        <div className="modal-footer boc-modal-hf-padding">
                            <button type="button" className="btn btn-bird" id="btn-birdsave" data-dismiss="modal"><i className="fa fa-times"></i> {lang["storybook.cancelbtn"]}</button>
                            <button type="button" className="btn btn-bird applystorybook" onClick={that.applystorybook} id="applystorybook"><i className="fa fa-check"></i>{lang["storybook.applybtn"]}</button>
                        </div>
                    </div>
                    {/* <!-- /.modal-content --> */}
                </div>
            </div>);
    }
}
