import React from "react";
import ReactDOM from "react-dom";
import ExcludeColumnsDropdownTemplate from "./excludecolumnsdropdownview.jsx"
export default class ExcludeColumnsDropDownView extends React.Component{
    constructor(props){
        super(props);
        this.state=this.props;
        this.render()
    }
    render(){
        ReactDOM.render(<ExcludeColumnsDropdownTemplate  dynamicdiv={this.state.dynamicdiv} columnDisplayName={this.state.columnDisplayName} columnName={this.state.columnName}
            dataType={this.state.dataType} aggregation={this.state.aggregation} tableName={this.state.tableName} chartType={this.state.chartType}
            xaxisidentity={this.state.xaxisidentity} addTextvalue={this.state.addTextvalue} addDatevalue={this.state.addDatevalue} classNameToAppend={this.state.classNameToAppend} customfunction={this.state.customfield}></ExcludeColumnsDropdownTemplate>, document.getElementById(this.state.dynamicdiv));

        return(
            <React.Fragment/>
        )
    }
}    