import React from "react";
import { lang } from "../js/helpers/utils/languages/language";
import SendRequest from '../SendRequest';
const $= window.$;
export default class adminfooter extends React.Component{
    componentDidMount() {
        let requestHeaders = {
          method: "get",
          headers: new Headers({
            "Content-Type": "application/json"
          })
        };
        SendRequest({
          url: "getServerDateTime",
          queryString: "",
          sucessFunction: (d) => {
            $(".servertimezone").text(d.serverDateTime).attr("title", "Server Time : " + d.serverDateTime);
            var dt = new Date();
            $(".localtimezone").text(dt).attr("title", "Local Time : " + dt);
            $(".timezone").removeClass("hide");
          },
          rejectFunction: () => { },
          headers: requestHeaders
        });
        $(".spinner").hide();
    }
    render(){
        return(
            <footer class="footer">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xs-12">
                                    {/* <!-- <p class="text-muted"><%=whitelableMap["footertext"]%></p> --> */}
                                    <div className="timezone">
                                        <span className="servertime" title={lang["admin.servertime"]}><i className="fa fa-cloud"></i> <span className="servertimezone" style={{ marginRight: "20px" }}>{lang["admin.time"]} </span> </span>
                                        <span className="localtime" title={lang["admin.localtime"]}><i className="fa fa-desktop"></i> <span className="localtimezone"> {lang["admin.time"]} </span> </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
        );
    }
}