import React from 'react';
import { lang} from "../../../js/helpers/utils/languages/language";
export default class EditLdapTemplate extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
    var that = this.props;
    var ldapSettings = that.ldapSettings;
    return(
        <div className="row in-back">
	<div className="max-height-30vh">
		<h2 className="boc-main-title">{lang["admin.ldap.edittitle"]}</h2>

		<form className="form-horizontal col-xs-12 ldapform">
			<div className="form-group form-group-sm">
				<label for="enableldap" className="col-sm-3 control-label">{lang["admin.ldap.enable"]}</label>
				<div className="col-sm-6">
					<div>
                        {
                        (ldapSettings.status=='A')?(<>
						<input type="radio" id="1" name="status" value="A" checked="checked"/>
						{lang["admin.ldapact"]}&nbsp;&nbsp;&nbsp; 
						<input type="radio" id="2" name="status" value="I"/> {lang["admin.ldapdact"]}</>
						):(<>
						<input type="radio" id="1" name="status" value="A"/> {lang["admin.ldapact"]}&nbsp;&nbsp;&nbsp; 
						<input type="radio" id="2" name="status" value="I" checked="checked"/> {lang["admin.ldapdact"]} </>)
						}
					</div>
				</div>
			</div>
			<div className="form-group form-group-sm">
				<label for="ldapurl" className="col-sm-3 control-label important">{lang["admin.ldapname"]}</label>
				<div className="col-sm-6">
					<input type="url" className="form-control" id="ldapname"
						placeholder={lang["admin.pholdname"]} value={ldapSettings.ldapName} name="ldapname" />
					<span className="help-block1 fa-modal-alert hide"  style={{color:"red !important"}}>{lang["admin.ldapnamerqd"]}</span>
				</div>
			</div>
			<div className="form-group form-group-sm">
				<label for="ldapurl" className="col-sm-3 control-label important">{lang["admin.ldapurl"]}</label>
				<div className="col-sm-6">
					<input type="url" className="form-control" id="ldapurl"
						placeholder={lang["admin.pholdurl"]} value={ldapSettings.url} name="ldapurl"/>
					<span className="help-block2 fa-modal-alert hide"  style={{color:"red !important"}}>{lang["admin.ldapurlrqd"]}</span>
				</div>
			</div>
			<div className="form-group form-group-sm">
				<label for="ldapbinduser" className="col-sm-3 control-label important">{lang["admin.ldapuser"]}</label>
				<div className="col-sm-6">
					<input type="hidden" className="form-control" id="ldapid"
						placeholder="ldapid" value={ldapSettings.ldapId} name="ldapid"/>
					<input type="name" className="form-control" id="ldapbinduser"
						placeholder={lang["admin.pholduser"]} value={ldapSettings.bindUser} name="ldapbinduser"/>
					<span className="help-block3 fa-modal-alert hide"  style={{color:"red !important"}}>{lang["admin.ldapuserrqd"]}</span>
				</div>
			</div>
			<div className="form-group form-group-sm">
				<label for="ldapbindpassword" className="col-sm-3 control-label important">{lang["admin.ldappwd"]}</label>
				<div className="col-sm-6">
					<input type="password" className="form-control" id="ldapbindpassword"
						placeholder={lang["admin.pholdpwd"]} value={ldapSettings.bindPassword} name="ldapbindpassword"/>
					<span className="help-block4 fa-modal-alert hide"  style={{color:"red !important"}}>{lang["admin.ldapspwdrqd"]}</span>
				</div>
			</div>
			<div className="form-group form-group-sm">
				<label for="ldapsearchbase" className="col-sm-3 control-label important">{lang["admin.ldapsearch"]}</label>
				<div className="col-sm-6">
					<input type="text" className="form-control" id="ldapsearchbase"
						placeholder={lang["admin.pholdbase"]} value={ldapSettings.searchBase} name="ldapsearchbase"/>
					<span className="help-block5 fa-modal-alert hide"  style="color:red !important">{lang["admin.ldapsearchrqd"]}</span>
					<span className="help-message"> {lang["admin.ldapsearchobj"]}
					</span>
				</div>
			</div>
			<div className="form-group form-group-sm">
				<label className="col-sm-3 control-label" for="ldapquery">{lang["admin.ldapquery"]}</label>
				<div className="col-sm-6">
					<textarea placeholder={lang["admin.pholdquery"]} id="ldapquery"
						rows="2" className="form-control textarea" name="ldapquery">{ldapSettings.query}</textarea>
					<span className="help-message">{lang["admin.ldapqueryobj"]}</span>
				</div>
			</div>
			<div className="form-group form-group-sm">
				<label class="col-sm-3 control-label" for=""></label>
				<div className="col-sm-7">
					<button type="button" className="btn btn-bird  ldapvalidate marg-righ-5"
						id="ldapvalidate" onClick={that.ldapValidate}><i className="fa fa-play marg-righ-5"></i>{lang["admin.ldapvalidbtn"]}</button>
					<button type="button" className="btn btn-bird  updateldap marg-righ-5"
						id="updateldap" onClick={that.updateLdap}><i className="fa fa-refresh marg-righ-5"></i>{lang["admin.ldap.updatebtn"]}</button>
					<button type="button" className="btn btn-bird  getldapusersfromad"
						id="getldapusersfromad" onClick={that.getLdapUsersfromAd}><i className="fa fa-users marg-righ-5"></i>{lang["admin.ldapshowusersbtn"]}</button>
					
					
				</div>
			</div>

		</form>

	</div>
</div>
    );
    }
}