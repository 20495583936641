import React from 'react';
import { lang} from "../../../js/helpers/utils/languages/language";
export default class PasswordPolicyTemplate extends React.Component{
    constructor(props){
        super(props);
        this.state={
            minlengthchars : this.props.editpasswordpolicydetails.minlengthchars,
            maxlengthchars : this.props.editpasswordpolicydetails.maxlengthchars,
            lowercasechars : this.props.editpasswordpolicydetails.lowercasechars,
            uppercasechars : this.props.editpasswordpolicydetails.uppercasechars,
            minimumdigits : this.props.editpasswordpolicydetails.minimumdigits,
            specialchars : this.props.editpasswordpolicydetails.specialchars,
            whitespaces : this.props.editpasswordpolicydetails.whitespaces,
            twofactorauth : this.props.editpasswordpolicydetails.twofactorauth,
            retentionperiode : this.props.editpasswordpolicydetails.retentionperiode,
            loginfailattempts : this.props.editpasswordpolicydetails.loginfailattempts
        };
        this.handleChange = this.handleChange.bind(this);
        //this.state.minlengthchars = this.props.editpasswordpolicydetails.minlengthchars;
    }
    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }
    render(){
        var that = this.props;
        var data = that.editpasswordpolicydetails;
        return(
            <div className="row in-back">
            <div className="row-same-height-inner">
                <div className="col-xs-6 col-xs-height in-panes" id="icontent">
                    <div className="innerothersarea mailconfigform">
                        <div className="row in-back max-height-40vh">
                            <div className="max-height-30vh">
                                <h2 className="boc-main-title">{lang["admin.pwd.title"]}</h2>
                                <form className="form-horizontal col-xs-12 editpasswordpolicyform">
                                    <div className="form-group form-group-sm">
                                        <label for="minlengthchars"
                                            className="col-sm-3 control-label important">{lang["admin.pwd.minlen"]}</label>
                                        <div className="col-sm-4">
                                            <input type="text" className="form-control" id="minlengthchars"
                                                name="minlengthchars" placeholder="0"
                                                value={this.state.minlengthchars} onChange={this.handleChange}/> <span
                                                className="help-block minlengthcharserror hidden" style={{color: "red"}}></span>
                                        </div>
                                    </div>
                                    <div className="form-group form-group-sm">
                                        <label for="maxlengthchars"
                                            className="col-sm-3 control-label important">{lang["admin.pwd.maxlen"]}</label>
                                        <div className="col-sm-4">
                                            <input type="text" className="form-control" id="maxlengthchars"
                                                name="maxlengthchars" placeholder="0"
                                                value={this.state.maxlengthchars} onChange={this.handleChange}/> <span
                                                className="help-block maxlengthcharserror hidden" style={{color: "red"}}></span>
                                        </div>
                                    </div>
                                    <div className="form-group form-group-sm">
                                        <label for="lowercasechars"
                                            className="col-sm-3 control-label important">{lang["admin.pwd.minlowch"]}</label>
                                        <div className="col-sm-4">
                                            <input type="text" className="form-control" id="lowercasechars"
                                                name="lowercasechars" placeholder="0"
                                                value={this.state.lowercasechars} onChange={this.handleChange}/> <span
                                                className="help-block lowercasecharserror hidden" style={{color: "red"}}></span>
                                        </div>
                                    </div>
                                    <div className="form-group form-group-sm">
                                        <label for="uppercasechars"
                                            className="col-sm-3 control-label important">{lang["admin.pwd.minupch"]}</label>
                                        <div className="col-sm-4">
                                            <input type="text" className="form-control" id="uppercasechars"
                                                name="uppercasechars" placeholder="0"
                                                value={this.state.uppercasechars} onChange={this.handleChange}/> <span
                                                className="help-block uppercasecharserror hidden" style={{color: "red"}}></span>
                                        </div>
                                    </div>
                                    <div className="form-group form-group-sm">
                                        <label for="minimumdigits"
                                            className="col-sm-3 control-label important">{lang["admin.pwd.mindig"]}</label>
                                        <div className="col-sm-4">
                                            <input type="text" className="form-control" id="minimumdigits"
                                                name="minimumdigits" placeholder="0"
                                                value={this.state.minimumdigits} onChange={this.handleChange}/> <span
                                                className="help-block minimumdigitserror hidden" style={{color: "red"}}></span>
                                        </div>
                                    </div>
                                    <div className="form-group form-group-sm">
                                        <label for="specialchars"
                                            className="col-sm-3 control-label important">{lang["admin.pwd.spclchar"]}</label>
                                        <div className="col-sm-4">
                                            <input type="text" className="form-control" id="specialchars"
                                                name="specialchars" placeholder="0"
                                                value={this.state.specialchars} onChange={this.handleChange}/> <span
                                                className="help-block specialcharserror hidden" style={{color: "red"}}></span>
                                        </div>
                                    </div>
                                    <div className="form-group form-group-sm">
                                        <label for="whitespaces"
                                            className="col-sm-3 control-label important"> {lang["admin.pwd.whtespce"]}</label>
                                        <div className="col-sm-4">
        
                                            <select className="form-control" id="whitespaces" name="whitespaces">  
                                                {(this.state.whitespaces == "0")?<>										
                                                <option value="0" id="whitespaces" name="whitespaces" selected>{lang["admin.pwd.spcetrue"]}</option>
                                                <option value="1" id="whitespaces" name="whitespaces">{lang["admin.pwd.spcefalse"]}</option></>
                                                 : 
                                                <><option value="0" id="whitespaces" name="whitespaces">{lang["admin.pwd.spcetrue"]}</option>
                                                <option value="1" id="whitespaces" name="whitespaces" selected>{lang["admin.pwd.spcefalse"]}</option></>
                                                 } 
                                            </select> <span className="help-block whitespaceserror hidden" style={{color: "red"}}></span>
        
                                        </div>
                                    </div>
                                    <div className="form-group form-group-sm">
                                        <label for="retentionperiode"
                                            className="col-sm-3 control-label important"> {lang["admin.pwd.period"]} </label>
                                        <div className="col-sm-4">
                                            <input type="" className="form-control" id="retentionperiode"
                                                name="retentionperiode" placeholder="0"
                                                value={this.state.retentionperiode} onChange={this.handleChange}/> <span
                                                className="help-block retentionperioderror hidden" style={{color: "red"}}></span>
                                        </div>
                                    </div>
                                    <div className="form-group form-group-sm">
                                        <label for="loginfailattempts"
                                            className="col-sm-3 control-label ">{lang["admin.pwd.loginfail"]}
                                            </label>
                                        <div className="col-sm-4">
                                            <input type="text" className="form-control" id="loginfailattempts"
                                                name="loginfailattempts" placeholder="0"
                                                value={this.state.loginfailattempts} onChange={this.handleChange}/> <span
                                                className="help-block loginfailattemptserror hidden" style={{color: "red"}}></span>
                                        </div>
                                    </div>
                                    <div className="form-group form-group-sm">
                                        <label for="twofactorauthentication"
                                            className="col-sm-3 control-label ">{lang["admin.pwd.auth"]}</label>
                                        <div className="col-sm-5">
                                         {(this.state.twofactorauth == "1") ? 
                                            <input type="checkbox" name="twofactorauth" id="twofactorauth" defaultChecked="checked"/>
                                             : 
                                            <input type="checkbox" name="twofactorauth" id="twofactorauth" />
                                             } 
                                        </div>
                                    </div>
                                    <div className="form-group form-group-sm">
                                    <label for="savepasswordpolicy" class="col-sm-3 control-label "></label>
                                        <div className="col-sm-5">
                                            <button type="button"
                                                className="btn btn-bird pull-left marg-righ-5"
                                                id="savepasswordpolicy"  onClick={that.updatePasswordPolicy}><i className="fa fa-refresh marg-righ-5"></i>{lang["admin.pwd.updatebtn"]}</button>
                                        </div>
                                    </div> 
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        );
    }
}