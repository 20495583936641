import React from 'react';
import ReactDOM from 'react-dom';
import SendRequest from '../../SendRequest';
import EditAccountTemplate from './editAccountTemplate';

export default class EditAccount extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            response:[]
        }
        this.getRegisteredClients();
    }
    getRegisteredClients(){
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "showRegisterdClient",
            queryString: "?clientId="+this.props.clientId,
            sucessFunction: (response) => {
                this.render(response)
            },
            rejectFunction: () => { },
            headers: requestHeaders,
        });
    }
    render(response){
        ReactDOM.unmountComponentAtNode(document.querySelector(".adminaccountform"));
        ReactDOM.render(<EditAccountTemplate  editaccountdetails={response} updateAccount={this.updateAccount} />,document.querySelector(".adminaccountform"));
        return (<div></div>);
    }
}