import React from 'react';
import { render } from 'react-dom';
import Router from './Router';
import './index.css';
import 'vis/dist/vis.min.js';
import 'vis/dist/vis.min.css';

const $=window.$;
window.jQuery=$;
require('react-bootstrap-table/dist/react-bootstrap-table-all.min.css')
require('react-bootstrap-table/dist/react-bootstrap-table.min.js');
require( 'datatables.net-buttons/js/dataTables.buttons' )();
window.JSZip = require('jszip');
require( 'datatables.net-buttons/js/buttons.html5.js' )();
render(<Router />,document.getElementById('root'));
