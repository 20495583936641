import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import FoldersListTemplate from "./FoldersListHtml.jsx";
import { lang } from "../js/helpers/utils/languages/language";
import SendRequest from '../SendRequest';
import _ from 'underscore';
import { getMessage } from '../js/helpers/utils/BirdMessage';
const $ = window.$;

class FoldersListView extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.render();
    }

    folderslistselect(page, search, pageDisplay, order, colName) {
        var index = 0;
        var foldername = this.props.foldername;        
        var noOfRecords = $(".foldersrowlimtbutton[data-foldername='"+foldername+"'] .page-size").text() == "" ? 25: $(".foldersrowlimtbutton[data-foldername='"+foldername+"'] .page-size").text();
        var totalRecords = 0;
        if (page != undefined && page > 1) {
            index = (page - 1) * noOfRecords;
        }
        if (order == undefined) {
            order = 'asc';
        }
        if (colName == undefined) {
            colName = '';
        }
        this.dataFieldValue = colName;
        this.sortingOrder = order;
        this.page = page;
        var that = this
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getdashboardreportslist",
            queryString: "?fromDate= " + "" + "&toDate= " + "" + "&index= " + index + "&noOfRecords=" + noOfRecords + "&searchCriteria=" + search + "&order=" + order + "&colName=" + colName + "&folderName=" + foldername + "&urltype=" + "getUserPaginationReportsByFolder",
            sucessFunction: (response) => {
                if (response.hasOwnProperty('error')) {
                    $(".spinner").hide();
                } else {
                    var headerlist = response["header"];
                    var data = response["data"];
                    totalRecords = response["totalRecords"];
                    var keys = Object.keys(headerlist);
                    var totalPages;
                    if (pageDisplay == undefined) {
                        pageDisplay = 25;
                    }
                    if (totalRecords > 25) {
                        totalPages = Math.ceil(totalRecords / pageDisplay);
                    } else {
                        totalPages = 1;
                    }

                    _.each(data, function (val, index) {
                        if (val.type == "ml_storyboard") {
                            val.viewReport = <a href={`${that.props.url}/viewmodel/${btoa(val.reportId)}/${btoa(val.dataModelId)}`} data-num={val.reportId} tab-id="0" tab-name="" class="boc-snapshot-thumbnail reportview" id="reportstoryboardview"><i class="fa fa-bar-chart"></i></a>;
                          } else if (val.type == "smartinsight_storyboard") {
                            val.viewReport = <a href={`${that.props.url}/viewsmartinsight/${btoa(val.reportId)}/${btoa(val.dataModelId)}`} data-num={val.reportId} class="boc-snapshot-thumbnail reportview" tab-id="0" tab-name="" id="reportstoryboardview"><i class="fa fa-bar-chart"></i></a>;
                          } else {
                            val.viewReport = <a href={`${that.props.url}/viewreport/${val.reportId}`} data-num={val.reportId} class="boc-snapshot-thumbnail reportview" tab-id="0" tab-name="" id="reportstoryboardview"><i class="fa fa-bar-chart"></i></a>;
                          }        
                        val.deleteReport = <button type="button" onClick={() => { that.deleteReport(val.reportId, val.isUserFavourite, val.isShared) }} id="favreportlistview" data-num={val.reportId} tab-id="0" tab-name="" is-shared={val.isShared} is-favourite={val.isUserFavourite} class="btn btn-transparent aldelete"><i class="fa fa-trash-o"></i></button>;
                    });
                    //$(".folderviewtableelement[data-foldername='"+foldername+"']").remove();           
                    //$(".foldersgridsmaincontainer[data-foldername='"+foldername+"'] .data-items-container").append('<div class="folderviewtableelement" data-foldername="'+foldername+'" ></div>')
                    ReactDOM.unmountComponentAtNode(document.querySelector("#folderstable[data-foldername='"+foldername+"']"));
                    ReactDOM.render(<FoldersListTemplate deleteReport={that.deleteReport} foldername={foldername} searchkeyup={that.searchkeyup} deleteReport={that.deleteReport} url={that.props.url} search={search}  totalRecords={totalRecords}  sizePerPage={pageDisplay} headerlist={headerlist} search={search} data={data} />,document.querySelector("#folderstable[data-foldername='"+foldername+"']"));
                    if (search != '') {
                    $(".searchfolderstable[data-foldername='"+foldername+"']").focus();
                    }
                    $(".spinner").hide();

                }
            },
            rejectFunction: () => { },
            headers: requestHeaders

        });


    }
    deleteReport(reportid, isFavourite, isShared) {
        let requestHeaders = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        var that = this;
        $(".deleteyes").unbind("click");
        $(".deleteno").unbind("click");
        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
        $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
        $('.birdmessagebuttons').addClass('show');
        $('.birdmessage-center').addClass("message-top-in");
        $('.details').removeClass('show');
        $('.details').addClass('hide');
        $('.birdmessage h2').empty().append('Warning');
        $('.birdmessage-info').empty().text(getMessage('BirdWarning18'));
        $(".deleteyes").click(function () {
            $('.birdmessage-center').removeClass("message-top-in");
            var modeldata = {};
            $('.spinner').show();
            modeldata["reportId"] = reportid;
            if (isFavourite == "Y") {
                modeldata["isFavourite"] = true;
            } else {
                modeldata["isFavourite"] = false;
            }
            if (isShared == "Y") {
                modeldata["isShared"] = true;
            } else {
                modeldata["isShared"] = false;
            }
            modeldata["isReportOwner"] = true;

            var data = new FormData();

            data.set("reportId", modeldata.reportId);
            data.set("isFavourite", modeldata.isFavourite);
            data.set("isSharedReport", modeldata.isShared);
            data.set("isReportOwner", modeldata.isReportOwner);

            SendRequest({
                url: "deletereport",
                body: data,
                sucessFunction: response => {
                    if (response.hasOwnProperty('error')) {
                        $(".spinner").hide();
                        $(".gridspinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.errorTrace)
                    } else {
                        $(".spinner").hide();
                        $(".gridspinner").hide();
                        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Success');
                        $('.birdmessage-info').empty().text(getMessage('BirdSuccess42'));
                        that.folderslistselect(undefined, "", 25, 'asc', undefined);
                    }
                },
                rejectFunction: () => { },
                headers: requestHeaders
            });


        });
        $(".deleteno").click(function () {
            $('.birdmessage-center').removeClass("message-top-in");
        });
        $('.navDashboard').click();
    }
    searchkeyup = (e) => {
        //console.log("in search block");
        var $this = e.currentTarget
        var foldername = $($this).attr('data-foldername');
        var that = this;
        var delay = (function () {
            var timer = 0;
            return function (callback, ms) {
                clearTimeout(timer);
                timer = setTimeout(callback, ms);
            };
        })();
        var search = $(e.currentTarget).val().toLowerCase();
        var pageDisplaySearch = $("#pageDropDown").text();
        delay(function () {
            that.searchValue = $(".searchfolderstable[data-foldername='"+foldername+"']").val();
            that.folderslistselect(undefined, that.searchValue, pageDisplaySearch, that.sortingOrder, that.dataFieldValue);
        }, 100);

        $(".searchfolderstable[data-foldername='"+foldername+"']").focus();
    }
    render() {
        this.folderslistselect(undefined, "", 25, 'asc', undefined);
        return(
           <>
        <div className="col-xs-12 marg-top-10" data-foldername={this.props.foldername}>
          <div className="notifications-table-container data-item" id="folderslisttable" data-foldername={this.props.foldername}>
            <div className="bootstrap-table">
              <div className="fixed-table-toolbar">
                <div className="pull-right search">
                  <input className="form-control searchfolderstable" type="text" placeholder={lang["visual.favourites.search"]} defaultValue={this.search} onKeyUp={this.searchkeyup} data-foldername={this.props.foldername}/>
                </div>
              </div>
              <div id="folderstable" data-foldername={this.props.foldername}>
                
              </div>
            </div>
          </div>
        </div>
      </>
        )
    }
}

export default FoldersListView;