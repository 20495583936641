import React, { Component } from "react";
import ReactDOM from "react-dom"
import * as ChartUtil from "../js/helpers/utils/chartutil";
import * as TabInfo from "../js/helpers/utils/tabinfo";
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import _ from 'lodash';
import PubSub from 'pubsub-js'
import * as ModalProperties from "../js/helpers/utils/modalproperties";
import ViewReportAddFilterModal from "./ViewReportaddFilterModal.jsx"
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;


export default class ViewReportAddFilterView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collection: props.collection,
            isFromStoryBook: props.isFromStoryBook == undefined ? false : props.isFromStoryBook,
            tabid: props.tabid == undefined ? 0 : props.tabid,
            isfromCustomStoryboard: props.isfromCustomStoryboard == undefined ? false : props.isfromCustomStoryboard
        }
        this.state.el = "filtermodal";
        this.state.sharedFilters = this.props.viewreportCollection.getSharedFilters();
        var columnsData = [];
        if (this.props.viewreportcollection != undefined) {
            ChartUtil.addColumnsData(JSON.stringify(this.props.viewreportcollection));
            columnsData = JSON.stringify(this.props.viewreportcollection);
        } else {
            ChartUtil.addColumnsData(JSON.stringify(this.state.collection));
            columnsData = JSON.stringify(this.state.collection);
        }
        if (this.props.isFromStoryBook != undefined && this.props.isFromStoryBook) {
            TabInfo.functions.updatecolumnsDataForTab(this.state.tabid, columnsData);
        }
        this.state.schemaType = "";
    }
    render() {
        var renameMeasuresorDimensions = sessionStorage.getItem('renameMeasuresorDimensions');
        var that=this;
        if(this.state.isfromCustomStoryboard || window.location.pathname.indexOf("customstoryboard") >= 0 ){
            var tilenum=$("#tilefiltersmodal").attr("data-id");
            var chartInfoDetails=   ChartInfo.functions.getCSBchartdetails(tilenum);
       var reportcolumnslist="";
       that.state.collection=chartInfoDetails.reportcolumnslist;
      
        }

        if (renameMeasuresorDimensions != null && renameMeasuresorDimensions != "null") {
            var parsedData = JSON.parse(renameMeasuresorDimensions);
           
            _.each(this.state.collection, function (obj) {

                if (obj.columnname !== undefined && parsedData[obj.columnname] != undefined) {
                    obj['columnDisplayName'] = parsedData[obj.columnname];
                }
            });
        }
       
        this.state.collection.sort(function(a, b){
            var x = a.columnDisplayName.toLowerCase();
            var y = b.columnDisplayName.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0 ;
            });

        var parsedCustomFields = [];
        var customfields = sessionStorage.getItem("customFields");
        if (customfields != "null" && customfields != null && customfields != undefined) {
            parsedCustomFields = JSON.parse(customfields);
        }
        $.each(parsedCustomFields, function (index1, customField) {
            customField["columnDisplayType"] = (customField.dataType == undefined) ? "string" : customField.dataType;
            parsedCustomFields.slice(index1, customField);
        });
        parsedCustomFields.sort(function(a, b){
            var x = a.name.toLowerCase();
            var y = b.name.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0 ;});
        var filterObjectArray = this.props.filterArray;
        if (filterObjectArray != undefined && filterObjectArray != "null" && filterObjectArray != null) {
            $.each(filterObjectArray, function (index, filterObject) {
                $.each(parsedCustomFields, function (index1, customField) {
                    if (customField["name"] == filterObject["columnname"]) {
                        customField["columnDisplayType"] = filterObject["dataType"];
                        parsedCustomFields.slice(index1, customField);
                    }
                });
            });
        }
        var convertedTypes = sessionStorage.getItem("convertedtypes");
        var parsedConvertedTypes = [];
        if (convertedTypes != undefined && convertedTypes != "null" && convertedTypes != null) {
            parsedConvertedTypes = JSON.parse(convertedTypes);
        }
        $.each(parsedConvertedTypes, function (index, convertedType) {
            $.each(parsedCustomFields, function (index1, customField) {
                if (customField['name'] == convertedType.columnName) {
                    customField['columnDisplayType'] = convertedType.dataType;
                    parsedCustomFields.slice(index1, customField);
                }
            });
        });

        // var fragmentArray = Backbone.history.fragment.split("/");
        var fragmentArray = "";
        if (fragmentArray[0] === "customviewreport") {
            if (this.state.schemaType == "mysql") {
                var tableNamesArray = [];
                var finaltableNamesArray = [];
                var coll1 = this.props.viewreportcollection;
                $.each(coll1, function (index, data) {
                    if (tableNamesArray.indexOf(data.tableName) < 0) {
                        tableNamesArray.push(data.tableName);
                    }
                });
                $.each(tableNamesArray, function (index, data) {
                    var tableObj = [];
                    $.each(coll1, function (index, data1) {
                        if (data == data1.tableName) {
                            tableObj.push(data1);
                        }
                    });
                    finaltableNamesArray.push(tableObj);
                });
                console.log(finaltableNamesArray);
                ReactDOM.unmountComponentAtNode(document.getElementById("filtermodal"));
                ReactDOM.render(<ViewReportAddFilterModal addFilter={this.addFilter} cancelFilterModal={this.cancelFilterModal} reportId={-1} filterfields={finaltableNamesArray} hierarchy={"true"} customfield={parsedCustomFields} />, document.getElementById("filtermodal"));
            } else {
                ReactDOM.unmountComponentAtNode(document.getElementById("filtermodal")); 
                ReactDOM.render(<ViewReportAddFilterModal addFilter={this.addFilter} cancelFilterModal={this.cancelFilterModal} reportId={-1} filterfields={this.props.viewreportcollection} hierarchy={"false"} customfield={parsedCustomFields} />, document.getElementById("filtermodal"));
            }

        }
        // else if (this.props.reportId === undefined) {
        //     ReactDOM.render(<ViewReportAddFilterModal addFilter={this.addFilter} cancelFilterModal={this.cancelFilterModal} reportId={1} filterfields={this.state.collection} customfield={parsedCustomFields} />, document.getElementById("filtermodal"));
        // }
        else {
            if (this.state.schemaType == "mysql") {
                var tableNamesArray = [];
                var finaltableNamesArray = [];
                var coll1 = this.state.collection;
                $.each(coll1, function (index, data) {
                    if (tableNamesArray.indexOf(data.tableName) < 0) {
                        tableNamesArray.push(data.tableName);
                    }
                });
                $.each(tableNamesArray, function (index, data) {
                    var tableObj = [];
                    $.each(coll1, function (index, data1) {
                        if (data == data1.tableName) {
                            tableObj.push(data1);
                        }
                    });
                    if (renameMeasuresorDimensions != null && renameMeasuresorDimensions != "null") {
                        $.each(coll1, function (index, data) {
                            if (parsedData[data.columnName] != undefined) {
                                data['columnDisplayName'] = parsedData[data.columnName];
                            }
                        });
                    }
                    finaltableNamesArray.push(tableObj);
                });
                console.log(finaltableNamesArray);
                ReactDOM.unmountComponentAtNode(document.getElementById("filtermodal"));
                ReactDOM.render(<ViewReportAddFilterModal addFilter={this.addFilter} cancelFilterModal={this.cancelFilterModal} reportId={this.props.reportId != undefined ? this.props.reportId : 1} filterfields={finaltableNamesArray} hierarchy={"true"} customfield={parsedCustomFields} />, document.getElementById("filtermodal"));
            } else {
                var coll1 = this.state.collection;
                if (renameMeasuresorDimensions != null && renameMeasuresorDimensions != "null") {
                    $.each(coll1, function (index, data) {
                        if (parsedData[data.columnName] != undefined) {
                            data['columnDisplayName'] = parsedData[data.columnName];
                        }
                    });
                }
                ReactDOM.unmountComponentAtNode(document.getElementById("filtermodal"));
                ReactDOM.render(<ViewReportAddFilterModal addFilter={this.addFilter} cancelFilterModal={this.cancelFilterModal} reportId={this.props.reportId != undefined ? this.props.reportId : 1} filterfields={coll1} hierarchy={"false"} customfield={parsedCustomFields} />, document.getElementById("filtermodal"));
            }
        }

        if ($("#tilefiltersmodal").length == 0) {
            _.each($(".rightfilter .acc-content"), function (element) {
                var child = $(element).attr('id').replace(/([;?&,%.+*~\':"!^$[\]()<=>`|\/@])/g, "");
                var parent = $(element).attr("data-parent")
                $(".reportfiltercheckbox[name='" + child + "']").prop('checked', true);
            });
        } else if ($("#tilefiltersmodal").length > 0) {
            _.each($("#tilefilterslist .acc-content"), function (element) {
                var child = $(element).attr('id').replace(/([;?&,%.+*~\':"!^$[\]()<=>`|\/@])/g, "");
                var parent = $(element).attr("data-parent")
                $(".reportfiltercheckbox[name='" + child + "']").prop('checked', true);
            });
        }
        return (<div></div>)
    }
    cancelFilterModal = () => {
        ReactDOM.unmountComponentAtNode(document.getElementById("filtermodal"));
        $(".spinner").hide();
    }
    addFilter = () => {
        var parsedSharedfilters;
        if (this.state.sharedFilters != undefined && this.state.sharedFilters.length > 2) {
            parsedSharedfilters = JSON.parse(this.state.sharedFilters);
        }

        var initialfiterCount = ChartInfo.functions.getFilters();
        var that = this;
        if(that.state.isfromCustomStoryboard || window.location.pathname.indexOf("customstoryboard") >= 0 ){
        var tilenum=$("#tilefiltersmodal").attr("data-id")    
        var chartInfoDetails= ChartInfo.functions.getCSBchartdetails(tilenum);
        that.props.datamodelId=chartInfoDetails.datamodelId;
        }
        $(".showbutton").trigger("click");
        var isChecked = false;
        $(".reportfiltercheckbox").each(function (element) {
            var $this = this;
            if ($($this).is(":checked")) {
                isChecked = true;
                var tableName = $($this).attr("data-parent");
                var columnDisplayName = $($this).attr("data-child");
                var filterDisplayName = $("input.filter-display-name[data-name='" + $($this).attr("data-child") + "']").val();
                var filterIgnore = false;
                var columnName = $($this).parent().attr("title").trim();
                var columntype = $(this).attr("data-type");
                var daterange = $(this).attr("date-range")
                var existingObj = that.isfilterAlreadyexists(parsedSharedfilters, columnName);
                if (existingObj != undefined && Object.keys(existingObj).length > 0 && existingObj.filterIgnore != null && existingObj.filterIgnore != undefined) { filterIgnore = existingObj.filterIgnore }
                if (existingObj != undefined && Object.keys(existingObj).length > 0) {
                    var tempArray = [];
                    var dataType = "";
                    var UsersFilterArray = [];
                    var filterObjColumnName = "";
                    var toRange = "";
                    var fromRange = "";
                    tableName = existingObj.tablename;
                    columnDisplayName = existingObj.columndisplayname;
                    columnName = existingObj.columnname
                    dataType = existingObj.dataType
                    fromRange = existingObj.fromRange
                    toRange = existingObj.toRange
                    UsersFilterArray = tempArray.push(existingObj);
                    daterange = existingObj.daterange

                    var filterItemObj = {};
                    filterItemObj["filterDisplayName"] = filterDisplayName;
                    filterItemObj["filterIgnore"] = filterIgnore;
                    filterItemObj["tableName"] = tableName;
                    filterItemObj["columnName"] = columnDisplayName;
                    filterItemObj["columnDisplayName"] = columnName;
                    filterItemObj["columnType"] = dataType;
                    filterItemObj["fromrange"] = fromRange;
                    filterItemObj["torange"] = toRange;
                    filterItemObj["filterObject"] = UsersFilterArray;
                    filterItemObj["datamodelId"] = that.props.datamodelId;
                    filterItemObj["entities"] = that.props.entities;
                    filterItemObj["daterange"] = daterange;
                    filterItemObj["parametervaluetype"] = undefined;
                    filterItemObj["elelement"] = undefined;
                    filterItemObj["className"] = undefined;
                    filterItemObj["sharedFilters"] = that.state.sharedFilters;
                    filterItemObj["filterid"] = undefined;
                    filterItemObj["indexName"] = undefined;
                    filterItemObj["esType"] = undefined;
                    PubSub.publish("addfilteritem", filterItemObj);
                } else {
                    var filterItemObj = {};
                    filterItemObj["filterDisplayName"] = filterDisplayName;
                    filterItemObj["filterIgnore"] = filterIgnore;
                    filterItemObj["tableName"] = tableName;
                    filterItemObj["columnName"] = columnDisplayName;
                    filterItemObj["columnDisplayName"] = columnName;
                    filterItemObj["columnType"] = columntype;
                    filterItemObj["fromrange"] = null;
                    filterItemObj["torange"] = null;
                    filterItemObj["filterObject"] = that.state.collection;
                    filterItemObj["datamodelId"] = that.props.datamodelId;
                    filterItemObj["entities"] = that.props.entities;
                    filterItemObj["daterange"] = daterange;
                    filterItemObj["parametervaluetype"] = undefined;
                    filterItemObj["elelement"] = undefined;
                    filterItemObj["className"] = undefined;
                    filterItemObj["sharedFilters"] = that.state.sharedFilters;
                    filterItemObj["filterid"] = undefined;
                    filterItemObj["indexName"] = undefined;
                    filterItemObj["esType"] = undefined;
                    PubSub.publish("addfilteritem", filterItemObj);
                }
            }
            else {
                var tableName = $($this).attr("data-parent");
                var columnDisplayName = $($this).attr("name");//$($this).attr("name").replace(/([ ;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "");
                if ($("#tilefiltersmodal").length == 0) {
                    $(".tab-filters-container .acc-content[data-id='" + columnDisplayName + "']").remove()
                    //$('#'+columnDisplayName).remove();
                } else if ($("#tilefiltersmodal").length > 0) {
                    $("#tilefiltersmodal .acc-content[data-id='" + columnDisplayName + "']").remove()
                }
            }

        });
        $(".customparameterfiltercheckbox").each(function (element) {
            var $this = this;
            if ($($this).is(":checked")) {
                isChecked = true;
                var tableName = '';
                var columnDisplayName = $($this).attr("name");
                var filterDisplayName = $("input.filter-display-name[data-name='" + $($this).attr("name") + "']").val();
                var columnName = $($this).attr("name");
                var valuetype = $(this).attr("data-valuetype");
                var value = $(this).attr("data-value");

                var filterItemObj = {};
                filterItemObj["filterDisplayName"] = filterDisplayName;
                filterItemObj["filterIgnore"] = false;
                filterItemObj["tableName"] = '';
                filterItemObj["columnName"] = columnDisplayName;
                filterItemObj["columnDisplayName"] = columnName;
                filterItemObj["columnType"] = valuetype;
                filterItemObj["fromrange"] = null;
                filterItemObj["torange"] = null;
                filterItemObj["filterObject"] = that.state.collection;
                filterItemObj["datamodelId"] = that.props.datamodelId;
                filterItemObj["entities"] = that.props.entities;
                filterItemObj["daterange"] = value;
                filterItemObj["parametervaluetype"] = undefined;
                filterItemObj["elelement"] = undefined;
                filterItemObj["className"] = undefined;
                filterItemObj["sharedFilters"] = undefined;
                filterItemObj["filterid"] = undefined;
                filterItemObj["indexName"] = undefined;
                filterItemObj["esType"] = undefined;
                PubSub.publish("addfilteritem", filterItemObj);
            }
            else {
                var tableName = $($this).attr("data-parent");
                var columnDisplayName = $($this).attr("name").replace(/([ ;?&,%#.+*~\':"!^$[\]()<=>`|\/@])/g, "");
                $('#' + columnDisplayName).remove();
            }

        });
        setTimeout(function () {
            var fcount = $('.rightfilter .acc-content').length;
            if (fcount > 0) {
                $('.filters-applied').remove();
                $('.leftpane').append('<div class="filters-applied"><i class="fa fa-filter"></i>' + fcount + lang["storyboard.filtersapplied"]+' </div>');
                $('.singlebadge').remove();
                /*$('.modalfilter').append('<span class="singlebadge badge notifications-badge">'+fcount+'</span>');*/
                $('.togglefiltersmenu, .togglefiltersmenunew').append('<span class="singlebadge badge notifications-badge">' + fcount + '</span>');
                $('.singlerportfilters').append('<span class="singlebadge badge notifications-badge">' + fcount + '</span>');
            } else {
                $('.hidebutton').trigger('click');
                $('.filters-applied').remove();
                $('.singlebadge').remove();
            }
        }, 3000);
        if (isChecked) {
            //Force user to click on Apply Filters Button
            /*$(".instant-floating-filters.rightpane").addClass("changesdone");
            $("#rightfiltersapplys").addClass("btn-highlight");
            setTimeout(function(){$("#rightfiltersapplys").addClass("tooltip-highlight");setTimeout(function(){$("#rightfiltersapplys").removeClass("tooltip-highlight");},2500)},500);*/
        }
        $("#mySmallModal").modal('hide');
        $('#mySmallModal0').modal('hide');
        ModalProperties.resize();
        var finalfiterCount = ChartInfo.functions.getFilters();
        if (initialfiterCount != undefined && initialfiterCount.length > 0 && finalfiterCount != undefined && finalfiterCount.length == 0) {
            ChartInfo.functions.setIsFiltersAdded(false);
            sessionStorage.setItem("changesdone", 1);
            $('.trend-stats-container').remove();
            $('.addInstanceFilterData').empty();
            if ($('.acc-content').length == 0 && $('.addInstanceFilterData').text().trim().length == 0) {
                if ($('.addURLInstanceFilterData').text().trim().length == 0) {
                    $('.instant-floating-filters').hide();
                    $('.allscopeslistcontainer').hide();
                }
            }
            sessionStorage.removeItem("instantExcludeFilters");
            sessionStorage.removeItem("instantIncludeFilters");
            sessionStorage.removeItem("instantExcludeCustomFilters");
            ChartInfo.functions.setInstanceIncludeFilterData("");
            ChartInfo.functions.setInstanceFilterData("");
            ChartInfo.functions.setIncludeFilterData("");
            ChartInfo.functions.setdrillfitlerArray("");
            $('.refreshsinglecharts').trigger('click');
            ChartInfo.functions.getFilters(true);
            PubSub.publish('applyfiltesandredrawchart');
        }
    
        this.cancelFilterModal();
    }
    isfilterAlreadyexists(parsedSharedfilters, columnName) {
        var obj;
        _.each(parsedSharedfilters, function (value) {
            if (value.columnname == columnName) {
                obj = value
            }
        });
        return obj;
    }

}