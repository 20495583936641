import React from 'react';
import _ from 'lodash';
import $ from 'jquery';
import Highcharts from 'highcharts/highmaps';
import Exporting from 'highcharts/modules/exporting';
import noDataDisplay from 'highcharts/modules/no-data-to-display'
import HighchartsReact from 'highcharts-react-official';
import Viewchartdrilldownmodal from "../../ViewStoryboard/ChartDrillDownModal";
import drilldow from "highcharts/modules/drilldown";
import dataModule from "highcharts/modules/data";
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import * as ChartUtil from "../../js/helpers/utils/chartutil";
import PubSub from 'pubsub-js';
import { whitelableMap } from "../../js/helpers/utils/whitelable";
import { getMessage } from '../../js/helpers/utils/BirdMessage';
import Leaflet from './leaflet';
import ReactDOM from 'react-dom';
import { getCountryName, getStateName } from '../helpers/utils/countryCode';
import * as usAll from '../../js/helpers/utils/us-all'
import * as wrold from '../../js/helpers/utils/world'
import * as lanka from '../../js/helpers/utils/lk-all'
import { lang } from "../../js/helpers/utils/languages/language";
// (function (H) {
//     H.addEvent(H.Axis, 'afterInit', function () {
//         const logarithmic = this;

//         if (logarithmic) {

//             // Avoid errors on negative numbers on a log axis
//             this.positiveValuesOnly = false;

//             // Override the converter functions
//             logarithmic.log2lin = num => {
//                 const isNegative = num < 0;

//                 let adjustedNum = Math.abs(num);

//                 if (adjustedNum < 10) {
//                     adjustedNum += (10 - adjustedNum) / 10;
//                 }

//                 const result = Math.log(adjustedNum) / Math.LN10;
//                 return isNegative ? -result : result;
//             };

//             logarithmic.lin2log = num => {
//                 const isNegative = num < 0;

//                 let result = Math.pow(10, Math.abs(num));
//                 if (result < 10) {
//                     result = (10 * (result - 1)) / (10 - 1);
//                 }
//                 return isNegative ? -result : result;
//             };
//         }
//     });
//     H.wrap(H.ColorAxis.prototype, 'toColor', function (proceed, value, point) {
// 		if(typeof value === 'string' || value < 0)
// 			return 'rgb(255,105,180)';
// 		else
// 			return proceed.apply(this, Array.prototype.slice.call(arguments, 1));
// 	});
// }(Highcharts));
Exporting(Highcharts);
dataModule(Highcharts);
drilldow(Highcharts);
//Highmaps(Highcharts);
noDataDisplay(Highcharts);
var charts = {};

export default class WorldMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        var compos = {};
        compos["chartComponent"] = this.refs.chartComponent;
        compos["chartparent"] = this.refs.chartparent;
        compos["chart"] = this.state.chart;
        compos["chartType"] = this.state.chartType;
        ChartInfo.functions.setChartComponents(this.state.chartnumber, compos);
        // ChartUtil.modifyChartHeightOnResize(this.state.chartnumber);
        if(this.refs!=undefined && this.refs.chartComponent!=undefined && this.refs.chartComponent.chart!=undefined){
            ChartUtil.charts[this.state.chartid]=this.refs.chartComponent.chart;
        }
    }
    render() {
        var data = this.props.data, xaxisName = this.props.xaxisName, xaxisColumnName = this.props.xaxisColumnName, xaxisDataType = this.props.xaxisDataType, yaxisnameArray = this.props.yaxisnameArray, chartId = this.props.chartId, chartType = this.props.chartType, legend = this.props.legend, position = this.props.position, features = this.props.features, isSharedReport = this.props.isSharedReport, colorThemeIndex = this.props.colorThemeIndex, chartSettings = this.props.chartSettings, tooltipNameArray = this.props.tooltipNameArray, trendstats = this.props.trendstats, iscustomstoryboard = this.props.iscustomstoryboard, xaxisidentity = this.props.xaxisidentity, yAxisDataTypeArray = this.props.yAxisDataTypeArray, ismultifact = this.props.ismultifact, multifactentities = this.props.multifactentities, datamodelId = this.props.datamodelId, reportid = this.props.reportid, facttables = this.props.facttables;

        var that = this.state;
        that.chartType = this.props.chartType;
        //  charts = {};
        that.chartid = chartId;
        if(charts!=undefined && chartId!=undefined && charts[chartId]!=undefined &&  charts[chartId].refs!=undefined &&  charts[chartId].refs.chartComponent!=undefined &&  charts[chartId].refs.chartComponent.chart!=undefined){
            charts[chartId].refs.chartComponent.chart.destroy();
        }
        var chartPoints = {};
        var columnsData = this.props.columnsData;
        var viewChartDrillDownModal = null;
        var isDrillDownEnable = true;
        var isViewDataEnable = true;
        var isExportChartEnable = false;
        var isFilterEnable = true;
        var isDrillThroughEnable = false;
        var chart;
        var isDirect = 0;
        var isStoredProcedure = 0;
        var colorThemesArray = ChartInfo.functions.getColorThemes();
        var x, y;
        if (chartSettings.numberformat == undefined) {
            var numberformat = {};
            numberformat.decimals = "0_2";
            chartSettings["numberformat"] = numberformat;
        }
        var xaxisNameArray = xaxisName.split(",");
        var xaxisdataTypes = xaxisDataType.split(",");
        if (xaxisNameArray.length == 2 && xaxisdataTypes.length == 2 && xaxisdataTypes[0] == "number" && xaxisdataTypes[1] == "number") {
            ReactDOM.render(
                <Leaflet data={data} xaxisName={xaxisName} xaxisColumnName={xaxisColumnName} xaxisDataType={xaxisDataType} yaxisnameArray={yaxisnameArray} chartId={chartId} chartType={chartType} legend={legend} position={position} features={features} isSharedReport={isSharedReport} colorThemeIndex={colorThemeIndex} chartSettings={chartSettings} tooltipNameArray={tooltipNameArray} trendstats={trendstats} iscustomstoryboard={iscustomstoryboard} xaxisidentity={xaxisidentity} yAxisDataTypeArray={yAxisDataTypeArray} ismultifact={ismultifact} multifactentities={multifactentities} datamodelId={datamodelId} facttables={facttables} columnsData={columnsData} />
                , document.getElementById(this.props.dynamicdiv)
            )
            //this.drawLeaflet();
        }
        else {
            /*Set Chart height to fix resize issues */
            var chartnumber = chartId.replace("chart", "");
            that.chartnumber = chartnumber;
            //This is to remove previous chart from DOM
            //this.unbindEvents(chartnumber);
            //Prefix/Suffix from Advanced Chart Settings
            var tooltipprefix = "", tooltipsuffix = "";

            if ($('.tileview[data-num=' + chartnumber + ']').length === 1) {
                var setheight = $('.tileview[data-num=' + chartnumber + ']').height() - 42;
                $("#chart" + chartnumber).height(setheight);
            }

            var bgcolor = $('.maxanim7[data-num=' + chartnumber + ']').css('background-color');
            if (bgcolor == undefined || bgcolor === 'rgba(0, 0, 0, 0)') { bgcolor = 'rgba(255, 255, 255, 1)' }

            sessionStorage.setItem('pos', position);
            isExportChartEnable = ChartUtil.checkExportChartEnable(features, position, isSharedReport);
            //var userLang = navigator.language || navigator.userLanguage;
            //var locale = getLocaleName(userLang);
            var locale = "en_za";
            var that = this;
            var mapData = [];
            var map;
            var statecodeCount = 0;
            if (chartType == "countrymap") {
                $.map(data[0].data, function (i, j) {
                    var seriesData = {};
                    var stateNames = data[0].data[j].name;
                    try {
                        var stateName = stateNames.toLowerCase().replace(
                            /\b\w/g, function (m) {
                                return m.toUpperCase();
                            })
                    }
                    catch (e) {
                        $(".gridspinner").hide();
                    }
                    var stateCode = getStateName(stateName);
                    if (stateCode != undefined) {
                        statecodeCount = statecodeCount + 1
                        seriesData["hc-key"] = stateCode;
                        seriesData["value"] = data[0].data[j].y;
                        seriesData['drilldown'] = stateCode;
                        seriesData['yaxisname'] = data[0].yaxisname;
                        seriesData['actualname'] = data[0].data[j].name;
                        seriesData['name'] = data[0].data[j].name;
                        $.each(tooltipNameArray, function (index, tooltip) {
                            seriesData["t" + index] = data[0].data[j]["t" + index];
                            seriesData["ta" + index] = data[0].data[j]["ta" + index];
                        });
                        mapData.push(seriesData);
                    }
                    /*
                     * if(measure_check_arr.length > 1){
                     * seriesData[measure_check_arr[1]] = unique_y_val[0][j] }
                     */

                });
                if (mapData[0] != undefined && mapData[0]['hc-key'] != undefined) {
                    var statecodes = mapData[0]['hc-key'].split('-')
                    if (statecodes[0] == 'lk') {
                        map = lanka;
                    }
                    else if (statecodes[0] == 'us') {
                        map = usAll;
                    }
                }
            }
            else {
                $.map(data[0].data, function (i, j) {
                    var seriesData = {};
                    var countrynames = data[0].data[j].name;
                    try {
                        var countryname = countrynames.toLowerCase().replace(
                            /\b\w/g, function (m) {
                                return m.toUpperCase();
                            })
                    }
                    catch (e) {
                        $(".gridspinner").hide();
                    }
                    seriesData["hc-key"] = getCountryName(countryname);
                    //sseriesData["hc-key"] = countryname;
                    seriesData["value"] = data[0].data[j].y;
                    $.each(tooltipNameArray, function (index, tooltip) {
                        seriesData["t" + index] = data[0].data[j]["t" + index];
                        seriesData["ta" + index] = data[0].data[j]["ta" + index];
                    });
                    /*
                     * if(measure_check_arr.length > 1){
                     * seriesData[measure_check_arr[1]] = unique_y_val[0][j] }
                     */
                    mapData.push(seriesData);
                    map = wrold;
                });
                /*seriesData["hc-key"] ="GF";
                seriesData["value"] = 500;
                mapData.push(seriesData);*/
            }

            var options = {
                lang: {
                    drillUpText: '\u25c1 Back',
                    contextButtonTitle: 'Download',
                    decimalPoint: '.',
                    thousandsSep: ',',
                    BacktoMap_key: 'Back to Map'
                },
                chart: {
                    events: {
                        click: function (event) {
                            var $this = event.currentTarget;
                            if (event.target.hasAttribute('href')) {
                                var bgcolor = $('.maxanim7[data-num=' + chartnumber + ']').css('background-color');
                                if (bgcolor == undefined || bgcolor === 'rgba(0, 0, 0, 0)') { bgcolor = 'rgba(255, 255, 255, 1)' }
                                this.options.exporting.chartOptions.chart.backgroundColor = bgcolor;
                            }
                        },
                        drillup: function (e) {
                            var chartId = e.target.userOptions.chart.renderTo;
                            var chartnumber = chartId.replace("chart",
                                "");
                            viewChartDrillDownModal.drillup(chartId,
                                chartnumber, charts["chart"
                                + chartnumber]);
                            ChartUtil.navCorrection(e);
                        },
                        drilldown: function (e) {
                            isDrillDownEnable = ChartUtil.checkDrillDownEnable(features, position, isSharedReport, iscustomstoryboard);
                            isViewDataEnable = ChartUtil.viewDataEnable(features, position, isSharedReport, iscustomstoryboard);
                            isFilterEnable = ChartUtil.checkFilterEnable(features, position, isSharedReport, iscustomstoryboard);
                            isDrillThroughEnable = ChartUtil.checkDrillThroughEnable(features, position, isSharedReport, iscustomstoryboard);
                            /*if(iscustomstoryboard){
                            }*/
                            if (!e.seriesOptions) {

                                var chartId = e.target.userOptions.chart.renderTo;
                                var chartnumber = chartId.replace(
                                    "chart", "");
                                chartPoints[chartnumber] = e.point;
                                var jsonobj;
                                var jsonArray = [];
                                var instantFilterArray = [];
                                if (legend != undefined && legend.columnName.length > 0) {
                                    jsonobj = {}
                                    jsonobj["columnname"] = legend.columnName;
                                    jsonobj["dataType"] = legend.dataType;
                                    jsonobj["data"] = chartPoints[chartnumber].series.name.split('[')[0];
                                    jsonobj["columndisplayname"] = legend.displayName;
                                    jsonobj["isdrilldownfilter"] = true;
                                    var rangeObject = $.grep(ChartInfo.functions.getRange(), function (e) { return e.name == legend.columnName });
                                    if (rangeObject.length > 0) {
                                        jsonobj["columnname"] = rangeObject[0]["xAxisName"][0].columnName;
                                    }
                                    jsonArray.push(jsonobj);
                                }
                                jsonobj = {};
                                jsonobj["columnname"] = e.target.userOptions.xAxis[0].title.orginaltextName;
                                //											jsonobj["dataType"]=e.target.userOptions.xAxis[0].type;
                                jsonobj["dataType"] = (e.target.userOptions.xAxis[0].ctype == undefined) ? "string" : e.target.userOptions.xAxis[0].ctype;
                                var rangeObject = $.grep(ChartInfo.functions.getRange(), function (e) { return e.name == jsonobj["columnname"] });
                                if (rangeObject.length > 0) {
                                    jsonobj["columnname"] = rangeObject[0]["xAxisName"][0].columnName;
                                }
                                if (e.point.actualname == undefined) {
                                    e.point.actualname = e.point.name;
                                }
                                var value = e.point.actualname;
                                if (jsonobj["dataType"] == "date") {
                                    if (value.length > 0) {
                                        var newDate = value;//Date.parse(value.replace(/-/g,'/'));
                                        if (isNaN(newDate)) {
                                            value = value;
                                        }
                                        else {
                                            value = newDate;//new Date(newDate).getTime();
                                        }
                                    }
                                }
                                jsonobj["data"] = value;
                                jsonobj["isdrilldownfilter"] = true;
                                jsonobj["columndisplayname"] = e.target.userOptions.xAxis[0].title.text;
                                jsonArray.push(jsonobj);
                                sessionStorage.setItem('datatable_popup_filterdata', JSON.stringify(jsonArray));
                                ChartInfo.functions.setInstanceIncludeFilterDataForDrillDown(chartnumber, jsonArray);
                                instantFilterArray.push(jsonobj);
                                _.each(instantFilterArray, function (data, i) {
                                    data["data"] = e.point.actualname;
                                    if (ChartInfo.functions.getExcludeFilterData() != undefined && ChartInfo.functions.getExcludeFilterData().length != 0) {
                                        _.each(ChartInfo.functions.getExcludeFilterData(), function (ExcludeFilterData, j) {
                                            ChartInfo.functions.setExcludeFilterData(instantFilterArray);
                                        });
                                    }
                                    else {
                                        ChartInfo.functions.setExcludeFilterData(instantFilterArray);
                                    }

                                });

                                var top = this.pointer.mouseDownY;
                                var left = this.pointer.mouseDownX + 16;
                                var chartwidth = this.pointer.chart.chartWidth - 160;
                                var chartheight = this.pointer.chart.chartHeight - 130;
                                if (top > chartheight) {
                                    top = chartheight;
                                }
                                if (left > chartwidth) {
                                    left = chartwidth;
                                }
                                $('#chart' + chartnumber + ' .highcharts-container .pie-menu').remove();
                                $('.pie-menu').remove();

                                var isDrillDownVisible = "disabled";
                                var isDrillDownLabel = "disabled";
                                if (xaxisDataType == "customrange") {
                                    isDrillDownEnable = false;
                                    isKeepOnlyVisible = false;
                                    isExcludeVisible = false;
                                }

                                if ((xaxisDataType == "" || xaxisDataType == undefined || xaxisColumnName == "Measure Names") || xaxisDataType == "customrange") {
                                    isDrillDownEnable = false;
                                }

                                if (isDrillDownEnable) {
                                    isDrillDownVisible = "pie-drilldown";
                                    isDrillDownLabel = "";
                                }

                                var isViewDataVisible = "disabled";
                                var isViewDataLabel = "disabled";
                                if (isViewDataEnable) {
                                    isViewDataVisible = "pie-viewdata";
                                    isViewDataLabel = "";
                                }

                                var isDrillThroughVisible = "disabled";
                                var isDrillThroughLabel = "disabled";
                                if (xaxisDataType == "customrange") {
                                    isDrillThroughEnable = false;
                                }
                                if (isDrillThroughEnable) {
                                    isDrillThroughVisible = "pie-drillthrough";
                                    isDrillThroughLabel = "";
                                }

                                var dataLength = data[0].data.length;
                                var isExcludeVisible = "disabled";
                                var isKeepOnlyLabel = "disabled";
                                var isKeepOnlyVisible = "disabled";
                                var isExcludeLabel = "disabled";
                                if (isFilterEnable && dataLength > 1 && xaxisDataType !== "customrange") {
                                    isKeepOnlyVisible = "keeponly-point";
                                    isKeepOnlyLabel = "";
                                    isExcludeVisible = "exclude-point";
                                    isExcludeLabel = "";
                                }
                                var isydrilldownenable = "disabled";
                                var yaxisname = e.point.name;
                                var drilldownmeasurearray = $.grep(ChartInfo.functions.getMeasureHierarchy(), function (e1) { return e1.name == yaxisname });
                                var chartDetails= ChartInfo.functions.getChartDetails(chartnumber) != undefined ? JSON.parse(ChartInfo.functions.getChartDetails(chartnumber)) : undefined;

                                if(iscustomstoryboard){
                                    if(ChartInfo.functions.getMeasureReportId(chartDetails.reportid)!=undefined){
                                        drilldownmeasurearray=$.grep(JSON.parse(ChartInfo.functions.getMeasureReportId(chartDetails.reportid)), function (e1) { return e1.name == yaxisname });
                                    }                                }
                                var hierarchylist = [];
                                if (drilldownmeasurearray.length > 0) {
                                    isydrilldownenable = "measuredilldown";
                                }

                                var isthreedpie;
                                if (chartType == "3dpie") {
                                    isthreedpie = "disabled";
                                }
                                //if(isDrillDownEnable || isViewDataEnable || isFilterEnable){
                                if (sessionStorage.getItem("drilldown" + chartnumber) != null && sessionStorage.getItem("drilldown" + chartnumber) != "null") {
                                    $('#chart' + chartnumber + '').before('<div class="pie-menu" style="top:' + top + 'px;left:' + left + 'px;"><div class="boc-pie-menu-start"></div><div class="boc-pie-menu-end"></div><div class="boc-piemenu-close"><i class="fa fa-times"></i></div>'
                                        + '<div class="pie-list-wrapper">'
                                        + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut " data-num="' + chartnumber + '"> <div class="boc-pie-menu-center">  </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                        + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isViewDataVisible + '" data-num="' + chartnumber + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label ' + isViewDataLabel + '"><span class="pietooltip">View<br>Data</span><i class="fa fa-table"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                        + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isydrilldownenable + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label"><span class="pietooltip">Y-Drill<br>Down</span><i class="fa fa-level-down"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                        + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isDrillThroughVisible + '" data-num="' + chartnumber + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label ' + isDrillThroughLabel + '" id="isdrillthroughlabel1""><span class="pietooltip">Drill<br>Through</span><i class="fa fa-level-down fa-flip-horizontal"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                        + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isDrillDownVisible + ' ' + isthreedpie + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label ' + isDrillDownLabel + '"><span class="pietooltip">Drill<br>Down</span><i class="fa fa-arrow-down"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                        + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut "data-num="' + chartnumber + '"> <div class="boc-pie-menu-center">  </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                        /*+'<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut"> <div class="boc-pie-menu-center">  </div></div>'*/
                                        + '<span class="piemenuwrapper"></span>'
                                        + '</div>'
                                        + '<span class="wrapper"></span>'
                                    );
                                }
                                else {
                                    $('#chart' + chartnumber + '').before('<div class="pie-menu" style="top:' + top + 'px;left:' + left + 'px;"><div class="boc-pie-menu-start"></div><div class="boc-pie-menu-end"></div><div class="boc-piemenu-close"><i class="fa fa-times"></i></div>'
                                        + '<div class="pie-list-wrapper">'
                                        + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isKeepOnlyVisible + '" data-num="' + chartnumber + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label keeponly ' + isKeepOnlyLabel + '"><span class="pietooltip"> '+lang["storyboard.keep"]+' <br>'+lang["storyboard.only"]+'</span><i class="fa fa-check"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                        + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isViewDataVisible + '" data-num="' + chartnumber + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label ' + isViewDataLabel + '"><span class="pietooltip">'+lang["storyboard.view"]+'<br>'+lang["storyboard.data"]+'</span><i class="fa fa-table"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                        + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isydrilldownenable + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label"><span class="pietooltip">'+lang["storyboard.y-drill"]+'<br>'+lang["storyboard.down"]+'</span><i class="fa fa-level-down"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                        + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isDrillThroughVisible + '" data-num="' + chartnumber + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label ' + isDrillThroughLabel + '" id="isdrillthroughlabel1""><span class="pietooltip">'+lang["storyboard.drill"]+'<br>'+lang["storyboard.through"]+'</span><i class="fa fa-level-down fa-flip-horizontal"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                        + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isDrillDownVisible + ' ' + isthreedpie + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label ' + isDrillDownLabel + '"><span class="pietooltip">'+lang["storyboard.drill"]+'<br>'+lang["storyboard.down"]+'</span><i class="fa fa-arrow-down"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                        + '<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut ' + isExcludeVisible + '" data-num="' + chartnumber + '"> <div class="boc-pie-menu-center"> <span class="boc-pie-menu-label excludeonly ' + isExcludeLabel + '"><span class="pietooltip">'+lang["storyboard.exclude"]+'</span><i class="fa fa-times"></i></span> </div> </div> <div class="boc-pie-menu-filler"></div> </div>'
                                        /*+'<div class="boc-pie-menu-filter"> <div class="boc-pie-menu-donut"> <div class="boc-pie-menu-center">  </div></div>'*/
                                        + '<span class="piemenuwrapper"></span>'
                                        + '</div>'
                                        + '<span class="wrapper"></span>'
                                    );
                                }

                                //}
                                setTimeout(function () {
                                    $('.boc-pie-menu-start, .boc-pie-menu-end, .boc-pie-menu-filter').toggleClass("openpie");
                                }, 200);
                                $('.boc-piemenu-close, .piemenuwrapper').on("click", function () {
                                    $('.boc-pie-menu-start, .boc-pie-menu-end, .boc-pie-menu-filter').toggleClass("openpie");
                                    setTimeout(function () {
                                        $('.pie-menu').remove();
                                    }, 800);
                                });
                                $('.wrapper').on("click", function () {
                                    $('.pie-menu').remove();
                                });
                                $('.pie-drilldown').on("click", function () {
                                    $('.boc-pie-menu-start, .boc-pie-menu-end, .boc-pie-menu-filter').toggleClass("openpie");
                                    setTimeout(function () {
                                        $('.pie-menu').remove();
                                    }, 800);
                                    if (iscustomstoryboard) {
                                        columnsData = ChartInfo.functions.getCustomstroyboardReportColumns(chartnumber);
                                    }
                                    charts["chart" + chartnumber] = e.target;
                                    chartPoints[chartnumber] = e.point;
                                    viewChartDrillDownModal = new Viewchartdrilldownmodal(
                                        {
                                            chartId: chartId,
                                            chartnumber: chartnumber,
                                            columns: JSON.stringify(columnsData),
                                            xaxisName: e.target.userOptions.xAxis[0].title.orginaltextName,
                                            xaxisDisplayName: e.target.userOptions.xAxis[0].title.text,
                                            displayName: e.target.userOptions.xAxis[0].title.displayname,
                                            xaxisType: e.target.userOptions.xAxis[0].ctype,
                                            value: e.point.actualname,
                                            chart: charts["chart"
                                                + chartnumber],
                                            point: chartPoints[chartnumber],
                                            pos: position,
                                            //broker: broker,
                                            iscuststroyboard: iscustomstoryboard,
                                            ismultifact: ismultifact,
                                            multifactentities: multifactentities,
                                            yaxisnameArray: yaxisnameArray,
                                            datamodelId: datamodelId,
                                            facttables: facttables,
                                            drilldownyAxisSeries: yaxisname
                                        });
                                });
                                $('.measuredilldown').on("click", function (event) {
                                    event.preventDefault();
                                    $('.boc-pie-menu-start, .boc-pie-menu-end, .boc-pie-menu-filter').toggleClass("openpie");
                                    setTimeout(function () {
                                        $('g.highcharts-tooltip').css('opacity', '1');
                                        $('.pie-menu').remove();
                                    }, 800);
                                    charts["chart" + chartnumber] = e.target;
                                    chartPoints[chartnumber] = e.point;
                                    viewChartDrillDownModal = new Viewchartdrilldownmodal(
                                        {
                                            chartId: chartId,
                                            chartnumber: chartnumber,
                                            columns: JSON.stringify(columnsData),
                                            xaxisName: e.target.userOptions.xAxis[0].title.orginaltextName,
                                            xaxisDisplayName: e.target.userOptions.xAxis[0].title.text,
                                            displayName: e.target.userOptions.xAxis[0].title.displayname,
                                            xaxisType: e.target.userOptions.xAxis[0].ctype,
                                            value: e.point.actualname,
                                            chart: charts["chart"
                                                + chartnumber],
                                            point: chartPoints[chartnumber],
                                            pos: position,
                                            //broker: broker,
                                            drilldownType: "measuredrilldown",
                                            iscuststroyboard: iscustomstoryboard,
                                            ismultifact: ismultifact,
                                            multifactentities: multifactentities,
                                            yaxisnameArray: yaxisnameArray,
                                            datamodelId: datamodelId,
                                            facttables: facttables,
                                            drilldownyAxisSeries: yaxisname
                                        });
                                });
                                $('.pie-viewdata').on("click", function (e) {
                                    e.preventDefault();
                                    PubSub.publish('viewDataTablePopUp', e);
                                    setTimeout(function () {
                                        $('g.highcharts-tooltip').css('opacity', '1');
                                    }, 1000);
                                });
                                $('.exclude-point').on("click", function (e) {
                                    e.preventDefault();
                                    PubSub.publish('exclude-point', e);
                                });
                                $('.keeponly-point').on("click", function (e) {
                                    e.preventDefault();
                                    PubSub.publish('keeponly-point', e);
                                });
                                $('.pie-drillthrough').on("click", function (e) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    PubSub.publish('pie-drillthrough', e);
                                });
                            }

                        }
                    },
                    renderTo: chartId,
                    reportId: reportid,
                    backgroundColor: 'transparent',
                    animation: false,
                    //height:'auto'
                },
                subtitle: {
                    text: null
                },
                title: {
                    text: ''
                },
                mapNavigation: {
                    enabled: false,
                    buttonOptions: {
                        verticalAlign: 'bottom'
                    }
                },
                legend: {
                    layout: 'vertical',
                    align: 'left',
                    verticalAlign: 'bottom',
                    itemStyle: {},
                    enabled: false
                },
                colorAxis: {
                    // type: 'logarithmic',
                    // allowNegativeLog: true,
                    enabled: true,
                   // min: 0,
                   // stops: [[0, colorThemesArray[colorThemeIndex][0]], [0.5, colorThemesArray[colorThemeIndex][1]], [1, colorThemesArray[colorThemeIndex][2]]]
                },
                series: [{
                    data: mapData,
                    color:colorThemesArray[colorThemeIndex][0],
                    mapData: map != undefined && map.default != undefined ? map.default : "",
                    joinBy: 'hc-key',
                    /*dataLabels: {
                        enabled: true,
                        useHTML : true,
                        style: {color:'#1b1a1a',textShadow:'1px 1px 1px #fff',textOutline:'1px #000000'},
                        formatter : function() {
                            if(this.point.value!=null && this.point.name!=undefined)
                            return  '<b>' + this.point.name + '</b>:'
                            + this.point.value;
                        }
                    },*/
                    states: {
                        hover: {
                            color: '#BADA55'
                        }
                    },


                }],

                drilldown: {
                    activeDataLabelStyle: {
                        color: '#333',
                        fontWeight: '300',
                        textDecoration: 'none',
                        textOutline: '1px #000000'
                    },
                    drillUpButton: {
                        relativeTo: 'spacingBox',
                        position: {
                            x: 0,
                            y: 60
                        },
                        theme: {
                            width: 40,
                            height: 6,
                            class: "highcharts-drillup-button",
                            'stroke-width': 1,
                            stroke: '#e8eaf1',
                            r: 0,
                            fill: '#fff',
                            cursor: 'pointer',
                            states: {
                                hover: {
                                    fill: '#f9f9fd',
                                    stroke: '#e8eaf1'
                                },
                                select: {
                                    fill: '#f9f9fd',
                                    stroke: '#e8eaf1'
                                }
                            }
                        },
                        useHTML: true
                    }
                },
                plotOptions: {
                    map: {
                        states: {
                            hover: {
                                color: '#EEDD66'
                            }
                        }
                    },
                    series: {
                        nullColor:'#fff',
                        dataLabels: {
                            enabled: false,
                            useHTML: true,
                            style: { color: '#1b1a1a', textShadow: '1px 1px 1px #fff', textOutline: '1px #000000' },
                            formatter: function () {
                                var num = ChartInfo.functions.addFormatting(this.point.value, chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator)
                                if (this.point.value != null && this.point.name != undefined)
                                    return '<b>' + this.point.name + '</b>:'
                                        + num;
                            }
                        }
                    }
                },
                tooltip: {
                    formatter: function () {
                        $("#chart" + chartnumber + " .highcharts-tooltip>span").css({ "background-color": chart.tooltip.options.backgroundColor, "borderStyle": "solid", "borderWidth": chart.tooltip.options.borderWidth, "border-color": chart.tooltip.options.borderColor });
                        if ($("#chart" + chartnumber + " .highcharts-tooltip>span").length == 0) {
                            setTimeout(function () { $("#chart" + chartnumber + " .highcharts-tooltip>span").css({ "background-color": chart.tooltip.options.backgroundColor, "borderStyle": "solid", "borderWidth": chart.tooltip.options.borderWidth, "border-color": chart.tooltip.options.borderColor }); }, 200)
                        }
                        //								return '<b>' + this.point.name + '</b>:'
                        //										+ this.point.value;
                        var num = ChartInfo.functions.addFormatting(this.point.value, chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator)
                        var name = '<span style="font-size:10px">' + this.key + '</b></span><table>';
                        name += '<tr><td style="color:' + this.series.color + ';padding:0">' + yaxisnameArray[0] + ': </td>';
                        name += '<td style="padding:0"><b>' + num + '</b></td></tr>';                        /*name +='<tr><td style="color:'+this.series.color+';padding:0">'+this.key + ': </td>'
                        + '<td style="padding:0"><b>'+ $.formatNumber(this.point.value,{format:"#,###.##",locale:locale})+'</b></td></tr>'*/
                        var that = this;
                        if (this.series.type == 'map') {
                            if (tooltipNameArray.length != 0) {
                                $.each(tooltipNameArray, function (i) {
                                    var tooltipnow = tooltipNameArray[i];
                                    var tooltipvalue = "t" + i;
                                    var tooltipagg = "ta" + i;
                                    name += '<tr><td style="color:' + that.series.color + ';padding:0">' + tooltipnow + ' <span class=bocaggtype>[';
                                    name += that.point[tooltipagg] == 'customaggregatedfield' ? 'raw' : that.point[tooltipagg] + '] </span>' + ': </td>';
                                    if (that.point[tooltipvalue] == 0) {
                                        name += '<td style="padding:0"><b>' + that.point[tooltipvalue] + '</b></td></tr>'
                                    } else {
                                        var num = ChartInfo.functions.addFormatting(that.point[tooltipvalue], chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator)
                                        name += '<td style="padding:0"><b>' + num + '</b></td></tr>'
                                    }
                                });
                            }
                        }
                        else {
                            name = '<span style="font-size:10px">' + this.key + '</span><table>'
                            name += '<tr><td style="color:' + this.color + ';padding:0">' + this.series.name + ' <span class=bocaggtype>[' + this.series.options.aggType + '] </span>' + ':&nbsp; </td>'
                            if (this.point.ydatatype == 'date' && this.series.options.aggType.toLowerCase().indexOf("count") < 0) {
                                var date = new Date(this.y);
                                name += '<td style="padding:0"><b>' + date.toLocaleString() + '</b></td></tr>';
                            }
                            else if (this.y != 0 && this.y != undefined && this.y != null) {
                                var num = ChartInfo.functions.addFormatting(this.y, chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator)
                                name += '<td style="padding:0"><b>' + tooltipprefix + num + tooltipsuffix + '</b></td></tr>';
                            }
                            else {
                                name += '<td style="padding:0"><b>' + tooltipprefix + this.y + tooltipsuffix + '</b></td></tr>';
                            }
                            var that = this;
                            if (tooltipNameArray.length != 0) {
                                $.each(tooltipNameArray, function (i) {
                                    var tooltipnow = tooltipNameArray[i];
                                    var tooltipvalue = "t" + i;
                                    var tooltipagg = "ta" + i;
                                    name += '<tr><td style="color:' + that.series.color + ';padding:0">' + tooltipnow + ' <span class=bocaggtype>[';
                                    name += that.point[tooltipagg] == 'customaggregatedfield' ? 'raw' : that.point[tooltipagg] + '] </span>' + ': </td>';
                                    if (that.point[tooltipvalue] == 0) {
                                        name += '<td style="padding:0"><b>' + that.point[tooltipvalue] + '</b></td></tr>'
                                    } else {
                                        var num = ChartInfo.functions.addFormatting(that.point[tooltipvalue], chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator)
                                        name += '<td style="padding:0"><b>' + num + '</b></td></tr>'
                                    }
                                });
                            }
                        }

                        return name;
                    },
                    shared: false,
                    useHTML: true

                },
                xAxis: [{
                    ctype: xaxisDataType,
                    type: "category",
                    title: {
                        text: xaxisName,
                        orginaltextName: xaxisColumnName,
                        displayname: xaxisName,
                        style: {}
                    },
                    labels: {
                        style: {}
                    },
                    visible: false
                }],
                boost: {
                    enabled: false
                },
                exporting: {
                    enabled: false,
                    buttons: {
                        contextButton: {
                            symbol: 'download',
                            symbolFill: "#fff",
                            symbolStrokeWidth: 0,
                            theme: {
                                fill: '#ffffff',
                                padding: 5,
                                stroke: 'none',
                                cursor: 'pointer'
                            },
                            menuItems: [{
                                textKey: 'printChart',
                                onclick: function () {
                                    var chartId = this.userOptions.chart.renderTo;
                                    var reportId = this.userOptions.chart.reportId;
                                    var msg = "Chart printed"
                                    if (reportId != undefined) {
                                        ChartUtil.auditMessage(reportId, msg);
                                    }
                                    this.print();
                                }
                            }, {
                                separator: true
                            }, {
                                textKey: 'downloadPNG',
                                onclick: function () {
                                    var chartId = this.userOptions.chart.renderTo;
                                    var reportId = this.userOptions.chart.reportId;
                                    var msg = "Chart downloaded in PNG format"
                                    if (reportId != undefined) {
                                        ChartUtil.auditMessage(reportId, msg);
                                    }
                                    this.exportChart();

                                }
                            }, {
                                textKey: 'downloadJPEG',
                                onclick: function () {
                                    var chartId = this.userOptions.chart.renderTo;
                                    var reportId = this.userOptions.chart.reportId;
                                    var msg = "Chart downloaded in JPEG format";
                                    if (reportId != undefined) {
                                        ChartUtil.auditMessage(reportId, msg);
                                    }
                                    this.exportChart({
                                        type: 'image/jpeg'
                                    });
                                }
                            }, {
                                separator: true
                            }, {
                                textKey: 'downloadPDF',
                                onclick: function () {
                                    var chartId = this.userOptions.chart.renderTo;
                                    var reportId = this.userOptions.chart.reportId;
                                    var msg = "Chart downloaded in PDF format";
                                    if (reportId != undefined) {
                                        ChartUtil.auditMessage(reportId, msg);
                                    }
                                    this.exportChart({
                                        type: 'application/pdf'
                                    });
                                }
                            }, {
                                textKey: 'downloadSVG',
                                onclick: function () {
                                    var chartId = this.userOptions.chart.renderTo;
                                    var reportId = this.userOptions.chart.reportId;
                                    var msg = "Chart downloaded in SVG format";
                                    if (reportId != undefined) {
                                        ChartUtil.auditMessage(reportId, msg);
                                    }
                                    this.exportChart({
                                        type: 'image/svg+xml'
                                    });
                                }
                            }]

                        }
                    },
                    chartOptions: {
                        chart: {
                            width: 800,
                            backgroundColor: bgcolor
                        },
                        yAxis: {
                            title: {
                                style: {
                                    fontSize: '6px',
                                    textShadow: 'none'
                                }
                            },
                            labels: {
                                style: {
                                    fontSize: '6px',
                                    textShadow: 'none'
                                }
                            }
                        },
                        plotOptions: {
                            series: {
                                dataLabels: {
                                    style: { color: '#1b1a1a', textShadow: '0px 0px 0px #fff', textOutline: '0px #000000', fontSize: '6px', fontWeight: '400' },
                                }
                            }
                        },
                        legend: {
                            itemStyle: {
                                font: '9px ' + whitelableMap["fontfamily"],
                                fontWeight: 'normal',
                            }
                        }
                    }
                },
                credits: {
                    enabled: false
                }
            };
            //options.exporting.enabled = true;
            if (isExportChartEnable) {
                options.exporting.enabled = true;
            }
            if ($(window).width() < 780) {
                options.chart.zoomType = 'none';
            }

            if(chartSettings['dataLabel']==true){
                options.plotOptions.series.dataLabels.enabled = true;
            }else{
                options.plotOptions.series.dataLabels.enabled = false;
            }

            if(chartSettings['legend']==true){
                options.legend.enabled = true;
            }else{
                options.legend.enabled = false;
            }
            
            if(chartSettings['gridLine']==true){
                options.colorAxis.minColor = '#fdfdfd';
                options.colorAxis.maxColor = colorThemesArray[colorThemeIndex][0];
                //options.colorAxis.stops = undefined;
                options.series[0].states.hover.color = colorThemesArray[colorThemeIndex][0];
            }else{
                options.colorAxis.stops = [[0, colorThemesArray[colorThemeIndex][0]], [0.5, colorThemesArray[colorThemeIndex][1]], [1, colorThemesArray[colorThemeIndex][2]]]
                options.series[0].states.hover.color = colorThemesArray[colorThemeIndex][0];
            }

            // if(chartSettings['referenceLine']==true){
            //     options.colorAxis.type = 'logarithmic';
            //     options.colorAxis.allowNegativeLog= true;
            // }

            if(chartSettings['yAxis']!=null && chartSettings['yAxis']!=undefined){
               // options.yAxis = [{min:undefined,max:undefined}];
                if(chartSettings['yAxis'].min!=""){
                    options.colorAxis.min = JSON.parse(chartSettings['yAxis'].min);
                    options.colorAxis.startOnTick = false
                    //options.yAxis[0].min = chartSettings['yAxis'].min
                }
                if(chartSettings['yAxis'].max!=""){
                    options.colorAxis.max = JSON.parse(chartSettings['yAxis'].max);
                    options.colorAxis.endOnTick = false
                    //options.yAxis[0].max = chartSettings['yAxis'].max
                }
            }

            //Add Advanced Chart Settings
            if(chartSettings["AdvancedChartSettings"]!=undefined){
                var advanced = chartSettings["AdvancedChartSettings"];
                var mapAdvanced = {}
                mapAdvanced["legend"] = advanced.legend
                $.extend(true,options,mapAdvanced);
            }
            //var chart = new Highcharts.Map(options);
            Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
                var path = [
                    // Arrow stem
                    'M', x + w * 0.5, y,
                    'L', x + w * 0.5, y + h * 0.7,
                    // Arrow head
                    'M', x + w * 0.3, y + h * 0.5,
                    'L', x + w * 0.5, y + h * 0.7,
                    'L', x + w * 0.7, y + h * 0.5,
                    // Box
                    'M', x, y + h * 0.9,
                    'L', x, y + h,
                    'L', x + w, y + h,
                    'L', x + w, y + h * 0.9
                ];
                return path;
            };
            var chart = new Highcharts.Map(options);
            this.state.chart = chart;
            charts[chartId] = chart;
            $("#" + chartId + " svg>title").remove();
            $('#' + chartId + ' .highcharts-container').addClass('worldmaparea');
            ChartInfo.functions.setChartTypeForMap(chartnumber, undefined);
        }
        return (
            <div style={{ height: "360px" }} ref="chartparent" >
                {/* <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                    ref="chartComponent"

                /> */}
            </div>
        );
    }
}