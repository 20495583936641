import React from 'react';
import { lang } from "../js/helpers/utils/languages/language";
export default class WorkspaceExistingEntitiesHtml extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <React.Fragment>
                {
                    this.props.entities.map((entity, index) => {
                        var icon = "fa-table";
                        if (entity.entitytype === "storedprocedure") {
                            icon = "fa-list-alt";
                        }
                        if (entity.entitytype !== "customquery"&&entity.fact==1) {
                            return (
                                <li key={index} className={`viewreport-list-item ui-draggable ui-draggable-handle activefact`} style={{ position: "relative", width: "241px", right: "auto", height: "36px", bottom: "auto", left: "0px", top: "0px" }}>
                                    <label title={entity.entityname}>
                                        <span className="source"><i className="fa fa-link"></i>{entity.connName}</span>
                                        <i className={`fa ${icon}`}></i>
                                        <input type="checkbox" durable="false" data_type="existing" className="scopeitem" entity_id={entity.entityId} hub_id={entity.datahubid} data_parent={entity.connection_access_id} name={entity.entityname} id={entity.entityname} data-value={entity.entityname} />
                                        {entity.entityname}
                                    </label>
                                    <button className="btn btn-transparent btn-sm editworkspaceitem" data_type="existing" entity_id={entity.entityId} hub_id={entity.datahubid} data_parent={entity.connection_access_id} name={entity.entityname} type="button" title={lang["workspace.settings"]}>
                                        <i className="fa fa-cog"></i>
                                    </button>
                                    <button className="btn btn-transparent btn-sm joinremove" data_type="existing" entity_id={entity.entityId} hub_id={entity.datahubid} data_parent={entity.connection_access_id} name={entity.entityname} workspace_entity_id={entity.workspaceentityid} type="button">
                                        <i className="fa fa-times"></i>
                                    </button>
                                    <div className="form-check checkbox-slider--b-flat checkbox-slider-sm" title={lang["workspace.facttable"]}>
                                        <label>
                                            <input className="fact" type="checkbox" data_type="existing" entity_id={entity.entityId} hub_id={entity.datahubid} data_parent={entity.connection_access_id} name={entity.entityname} />
                                            <span></span>
                                        </label>
                                    </div>
                                </li>
                            );
                        }else if(entity.entitytype !== "customquery"&&entity.fact!==1){
                            return(
                                <li key={index} className={`viewreport-list-item ui-draggable ui-draggable-handle`} style={{ position: "relative", width: "241px", right: "auto", height: "36px", bottom: "auto", left: "0px", top: "0px" }}>
                                    <label title={entity.entityname}>
                                        <span className="source"><i className="fa fa-link"></i>{entity.connName}</span>
                                        <i className={`fa ${icon}`}></i>
                                        <input type="checkbox" durable="false" data_type="existing" className="scopeitem" entity_id={entity.entityId} hub_id={entity.datahubid} data_parent={entity.connection_access_id} name={entity.entityname} id={entity.entityname} data-value={entity.entityname} />
                                        {entity.entityname}
                                    </label>
                                    <button className="btn btn-transparent btn-sm editworkspaceitem" data_type="existing" entity_id={entity.entityId} hub_id={entity.datahubid} data_parent={entity.connection_access_id} name={entity.entityname} type="button" title={lang["workspace.settings"]}>
                                        <i className="fa fa-cog"></i>
                                    </button>
                                    <button className="btn btn-transparent btn-sm joinremove" data_type="existing" entity_id={entity.entityId} hub_id={entity.datahubid} data_parent={entity.connection_access_id} name={entity.entityname} workspace_entity_id={entity.workspaceentityid} type="button">
                                        <i className="fa fa-times"></i>
                                    </button>
                                    <div className="form-check checkbox-slider--b-flat checkbox-slider-sm" title={lang["workspace.facttable"]}>
                                        <label>
                                            <input className="fact" type="checkbox" data_type="existing" entity_id={entity.entityId} hub_id={entity.datahubid} data_parent={entity.connection_access_id} name={entity.entityname} />
                                            <span></span>
                                        </label>
                                    </div>
                                </li>
                            );
                        }
                    })
                }
            </React.Fragment>
        );
    }
}