import React from 'react'
import { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import SendRequest from '../../SendRequest';
import { lang} from "../../js/helpers/utils/languages/language";
import _ from 'underscore';
const $ = window.$;
const columns = [
    { dataField: 'userName', text: lang["admin.users.rsetuser"], sort: true },
    { dataField: 'userLoginName', text: lang["admin.users.rsetlgin"], sort: true },
    { dataField: 'roleDisplayName', text:lang["admin.users.role"], sort: true },
    { dataField: 'status', text: lang["admin.users.status"], sort: true },
    { dataField: 'CreateDate', text: lang["admin.users.createdate"], sort: true },
    { dataField: 'UpdateDate', text: lang["admin.users.modifydate"], sort: true },
    { dataField: 'edit', text: lang["admin.users.edit"], sort: false},
    { dataField: 'resetpassword', text: lang["admin.users.resetpwd"], sort: false },
    { dataField: 'delete', text: lang["admin.users.delete"], sort: false },
  ];
  const RemotePagination = ({ data, page, sizePerPage, onTableChange, totalSize }) => (
    // <div className="notifications-table-container">
      <BootstrapTable
        remote
        keyField="userName"
        data={data}
        columns={columns}
        pagination={paginationFactory({ page, sizePerPage, totalSize })}
        onTableChange={onTableChange}
      />
    // </div>
  );
 
  export default class UserListViewTemplate extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        page: 1,
        data: props.data,
        sizePerPage: props.sizePerPage,
        totalRecords: props.totalRecords,
        search: props.search,
      };
      let featuresString = sessionStorage.getItem("features");
      featuresString = featuresString.slice(0, 3) + featuresString.slice(4);
      let decryptedString = window.atob(featuresString);
      this.state.parsedFeatureJson = JSON.parse(decryptedString);
      this.state.features= JSON.parse(decryptedString);
      this.state.search=props.search;
      var searchtext=this.state.search
    }
    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, data ,totalRecords}) => {
        const currentIndex = (page - 1) * sizePerPage;
        {
          var that= this;
          if (that.state.search == undefined){
            that.state.search  ="";
          }
          if(type != 'sort'){
                let requestHeaders = {
                  method: "get",
                  headers: new Headers({
                      "Content-Type": "application/json"
                  })
              };
                 SendRequest({
                  url: "getusers",
                  queryString: "?index= " + currentIndex + "&noOfRecords=" + sizePerPage + "&searchCriteria=" + that.state.search  + "&order=" + sortOrder + "&colName=" + "",
                  sucessFunction: (response) => {
                      if (response.hasOwnProperty('error')) {
                          $(".spinner").hide();
                      } else {
                        var headerlist = response["header"];
                        var data = response["data"];
                        _.each(data,function(val,index)	{				
                          _.each(that.state.features[0].features,function(feature){
                            if(feature.featureName=='edit_user' && feature.allow==true){
                              val.edit = <button class="btn btn-admin editaccount3" onClick={that.props.editUsersView} id="edituser" type="button" value={val.userId}><i class="fa fa-pencil"></i></button>;          
                          }	
                            if(feature.featureName=='delete_user' && feature.allow==true){
                             val.delete = <button class="btn btn-admin  deleteuserform" onClick={that.props.deleteUsersView}  id="deleteuser" type="button" value={val.userId}><i class="fa fa-close"></i> </button>;                
                            }
                            if(feature.featureName=='reset_password' && feature.allow==true){
                              val.resetpassword = <button class="btn btn-admin resetpasswordfromadmin" onClick={that.props.resetpasswordfromadminview} id="resetpassword" type="button" value={val.userId}><i class="fa fa-repeat"></i> </button>
                              }
                          });
                        });
                      var keys = Object.keys(headerlist);
                      this.setState(() => ({
                        page,
                        data: data,
                        sizePerPage,
                        totalSize:totalRecords
                      }));
                      }
                    },
                    rejectFunction: () => { },
                    headers: requestHeaders
                  })
                }
         // type == "sort" ? 
         if(type=="sort"){
          
          let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
           SendRequest({
            url: "getusers",
            queryString: "?index= " + currentIndex + "&noOfRecords=" + sizePerPage + "&searchCriteria=" + that.state.search  + "&order=" + sortOrder + "&colName=" + sortField,
            sucessFunction: (response) => {
                if (response.hasOwnProperty('error')) {
                    $(".spinner").hide();
                } else {
                  var headerlist = response["header"];
                  var data = response["data"];
                  _.each(data,function(val,index)	{				
                    _.each(that.state.features[0].features,function(feature){
                      if(feature.featureName=='edit_user' && feature.allow==true){
                        val.edit = <button class="btn btn-admin editaccount3" onClick={that.props.editUsersView} id="edituser" type="button" value={val.userId}><i class="fa fa-pencil"></i></button>;          
                    }	
                      if(feature.featureName=='delete_user' && feature.allow==true){
                       val.delete = <button class="btn btn-admin  deleteuserform" onClick={that.props.deleteUsersView}  id="deleteuser" type="button" value={val.userId}><i class="fa fa-close"></i> </button>;                
                      }
                      if(feature.featureName=='reset_password' && feature.allow==true){
                        val.resetpassword = <button class="btn btn-admin resetpasswordfromadmin" onClick={that.props.resetpasswordfromadminview} id="resetpassword" type="button" value={val.userId}><i class="fa fa-repeat"></i> </button>
                        }
                    });
                  });

                var keys = Object.keys(headerlist);
                this.setState(() => ({
                  page,
                  data: data,
                  sizePerPage,
                  totalSize:totalRecords
                }));
                }
              },
              rejectFunction: () => { },
              headers: requestHeaders
            })

           
        }
      }
      }
    render() {
        const { data, sizePerPage, page,totalRecords } = this.state;
      var that = this.props;
      return (<>
       
{/* <div class="notifications-table-container"> */}

		<div class="bootstrap-table">
			<div class="fixed-table-toolbar">
            <RemotePagination
            data={data}
            page={page}
            sizePerPage={sizePerPage}
            totalSize={totalRecords}
            onTableChange={this.handleTableChange}
          />
				 
			</div>
		</div>
{/* </div> */}



      </>);
    }
  }