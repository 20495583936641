import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'underscore';
import SendRequest from '../SendRequest';
import WorkspaceDatahubItemsContainerHtml from './WorkspaceDatahubItemsContainerHtml.jsx';
import * as dataHubEntitiesCollection from '../Hub/DatahubEntitiesCollection'
const $ = window.$;

export default class WorkspaceDatahubTables extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataHubEntitiesCollection: []
        }
        this.allentities = [];
        this.allentitynames = [];
    }
    requestHeaders = {
        method: "get",
        headers: new Headers({
            "Content-Type": "application/json"
        })
    };
    componentDidMount() {
        SendRequest({
            url: "gethubentities",
            queryString: "?isFromWorkspace=" + true,
            sucessFunction: (resp) => {
                this.state.dataHubEntitiesCollection = resp;
                dataHubEntitiesCollection.updateAllEntityDetails(this.state.dataHubEntitiesCollection);
                if (this.state.dataHubEntitiesCollection[0] !== undefined) {
                    this.datahubid = this.state.dataHubEntitiesCollection[0].datahubid;
                    _.each(this.state.dataHubEntitiesCollection, (source, j) => {
                        if (source != undefined && source.connectionsData != undefined && source.connectionsData.length != 0) {
                            _.each(source.connectionsData, (connection, k) => {
                                if (connection != undefined && connection.entitiesData != undefined && connection.entitiesData.length != 0) {
                                    _.each(connection.entitiesData, (entity, l) => {
                                        dataHubEntitiesCollection.updateAllEntityNames(entity.entityname);
                                        dataHubEntitiesCollection.updateAllEntityNames(entity.entityviewname);
                                    });
                                }
                            });
                        }
                    });
                    // _.each(this.state.dataHubEntitiesCollection, (entity, j) => {
                    //     dataHubEntitiesCollection.updateAllEntityNames(entity.entityname);
                    // });
                }
                var dataHubConnections = this.state.dataHubEntitiesCollection;
                dataHubConnections.sort(function (a, b) {
                    var x = a.sourceDisplayName.toLowerCase();
                    var y = b.sourceDisplayName.toLowerCase();
                    return x < y ? -1 : x > y ? 1 : 0;
                });
                _.each(dataHubConnections, function (obj, i) {
                    obj.connectionsData.sort(function (a, b) {
                        var x = a.connectionDisplayName.toLowerCase();
                        var y = b.connectionDisplayName.toLowerCase();
                        return x < y ? -1 : x > y ? 1 : 0;
                    });
                    _.each(obj.connectionsData, function (entity, j) {
                        entity.entitiesData.sort(function (k, l) {
                            var a1 = k.entityname.toLowerCase();
                            var b1 = l.entityname.toLowerCase();
                            return a1 < b1 ? -1 : a1 > b1 ? 1 : 0;
                        });
                    });
                });
                ReactDOM.render(
                    <WorkspaceDatahubItemsContainerHtml
                        entities={dataHubConnections}
                        datahubid={this.datahubid}
                        search={this.props.search}
                        joinremove={this.props.joinremove}
                        workspaceaddcustomquery={this.props.workspaceaddcustomquery}
                        editworkspaceitem={this.props.editworkspaceitem}
                    />, document.getElementById('workspaceselectentitycontainer')
                );
                this.props.onWorkSpaceEntitiesLoad(true);
                setTimeout(function(){$(".spinner").hide();},500);
            },
            rejectFunction: (error) => {$(".spinner").hide();},
            headers: this.requestHeaders
        });
    }
    render() {
        return (<></>);
    }
}