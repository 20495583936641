import React, { Component } from "react";
import AdminView from "./adminhtml.jsx";
class Admin extends Component {

  render() {
    return (
      <div>
        <AdminView displayHamburger="true" displayHeader="true" pageType="dashboardpage" features="" whitelableMap="" />
      </div>
    );
  }
}
export default Admin;