import React from 'react';
import ReactDOM from 'react-dom';
import SendRequest from '../../SendRequest';
import NewLicenseForm from './NewLicenseForm';
import UpdateLicenseForm from './UpdateLicenseForm';
const $ = window.$;

export default class AddLicenseView extends React.Component{
    constructor(props){
        super(props);
        this.clientId=this.props.clientId;
			this.userId = this.props.userId;
			this.editions=['Bronze','Silver','Gold','Enterprise'];
			this.licenseTypeOptions=['Bronze(Start-up)','Silver(Grow)','Gold(Team+)','Enterprise'];
			this.connectorOptions=['Standard','Premium'];
        this.getLicensedetails();
    }
    getLicensedetails(){
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "getlicensedetails",
            queryString: "?clientId="+this.props.clientId,
            sucessFunction: (response) => {
                if(response.hasOwnProperty('error')){
					$(".spinner").hide();
					$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
					$('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
					$('.birdmessagebuttons').removeClass('show');
					$('.birdmessagebuttons').addClass('hide');
					$('.birdmessage-center').addClass("message-top-in");
					$('.details').removeClass('hide');
					$('.birdmessage h2').empty().append('Error : ');
					$('.birdmessage h2').append('<span class="errorcode">');
					$('.birdmessage .errorcode').empty().append(response.errorCode);
					$('.birdmessage-info').empty().text(response.error);
					$('.details').addClass('show');
					$('.messagedetails xmp').empty().append(response.errorTrace)
                }
                SendRequest({
                    url: "getremainingcountoffeaturesfromlicensekeys",
                    queryString: "",
                    sucessFunction: (response1) => {
                        if(response1.hasOwnProperty('error')){
                            $(".spinner").hide();
                            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                            $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                            $('.birdmessagebuttons').removeClass('show');
                            $('.birdmessagebuttons').addClass('hide');
                            $('.birdmessage-center').addClass("message-top-in");
                            $('.details').removeClass('hide');
                            $('.birdmessage h2').empty().append('Error : ');
                            $('.birdmessage h2').append('<span class="errorcode">');
                            $('.birdmessage .errorcode').empty().append(response1.errorCode);
                            $('.birdmessage-info').empty().text(response1.error);
                            $('.details').addClass('show');
                            $('.messagedetails xmp').empty().append(response1.errorTrace)
                        }
                        this.render(response,response1)
                    },
                    rejectFunction: () => { },
                    headers: requestHeaders,
                });
            },
            rejectFunction: () => { },
            headers: requestHeaders,
        });
    }
    render(currentadminlicensedetails,superadminlicensedetails){
        currentadminlicensedetails["editions"]=this.editions;
        ReactDOM.unmountComponentAtNode(document.querySelector(".adminaccountform"));   

        if(currentadminlicensedetails.data !== false){        
            ReactDOM.render(<UpdateLicenseForm  clientId = {this.clientId} userId = {this.userId}  licenseDetails={currentadminlicensedetails} superadminlicensedetails={superadminlicensedetails} licenseTypeOptions={this.licenseTypeOptions} connectorOptions={this.connectorOptions} />,document.querySelector(".adminaccountform"));
        }else{
            ReactDOM.render(<NewLicenseForm  clientId = {this.clientId} userId = {this.userId} licenseDetails={currentadminlicensedetails} superadminlicensedetails={superadminlicensedetails} licenseTypeOptions={this.licenseTypeOptions} connectorOptions={this.connectorOptions} />,document.querySelector(".adminaccountform"));
        }
        $('#expirydate').datepicker({minDate:new Date(),maxDate:new Date(superadminlicensedetails.expiryDate)});
        $("#expirydate" ).datepicker( "option", "dateFormat", "mm-dd-yy");
        var obj = currentadminlicensedetails;
			if(currentadminlicensedetails.data !== false){
				$(".licensetype").val(obj.edition);
				$(".licenseconnectoroptions").val(obj.connectors);
				if(obj.allowApi=="true"){
					$("#allowapi").prop('checked', true);
				}
				if(obj.allowML=="true"){
					$("#allowml").prop('checked', true);
				}
				if(obj.allowSmartInsights=="true"){
					$("#allowsmartinsights").prop('checked', true);
				}
				if(obj.allowWhiteLable=="true"){
					$("#allowwhitelabel").prop('checked', true);
				}
			}
			
			var curdate = currentadminlicensedetails.expiryDate;
			if(curdate!=undefined){
				$("#expirydate" ).datepicker("setDate", curdate);
			}
        return (<div></div>);
    }
}