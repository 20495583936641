import React from 'react';
import _ from 'underscore';
import { lang } from "../js/helpers/utils/languages/language";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import * as ModalProperties from '../js/helpers/utils/modalproperties';
const $=window.$;

export default class WorkspaceCustomfieldModal extends React.Component {
    constructor(props){
        super(props);
        this.state={
            functionsArray:this.props.numberfunctionsArray
        }
        this.functionsArray = this.props.numberfunctionsArray;
    }
    getFormula = (e) => {
        e.preventDefault();
        var $this = e.currentTarget;
        var datatype = $($this).val();
       
        if (datatype == "number") {
            this.setState({
                functionsArray:this.props.numberfunctionsArray
            });
        } else if (datatype == "string") {
            this.setState({
                functionsArray:this.props.stringfunctionsArray
            });
        } else if (datatype == "date") {
            this.setState({
                functionsArray:this.props.datefunctionsArray
            });
        } else if(datatype=="logical"){
            this.setState({
                functionsArray:this.props.logicalfunctionsArray
            });
        }
        // var that = this;
        // $("#customfunctionstable").bootstrapTable('load', this.functionsArray);
    }
    close=()=>{
        $('.append_filtercustomfield').remove();
    }
    componentDidMount(){
        this.props.textAreaAutoComplete();
        ModalProperties.resize();
        $(window).on("resize",function(){
            ModalProperties.resize();
        })
    }
    render() {
        return (
            <div className="modal fade in filtercustomfield" id="filtercustomfield" tabIndex="-1" role="dialog" aria-labelledby="filtercustomfield" aria-hidden="true" style={{display:"block"}}>
                <div class="modal-backdrop fade in"></div>
                <div className="modal-dialog modal-lg modal-save-report modal-dialog-lg-center modal-dialog-center" style={{zIndex:"1050"}}>
                    <div className="modal-content">
                        <div className="modal-header boc-modal-hf-padding">
                            <button type="button" className="close white" aria-hidden="true" data-dismiss="modal" onClick={this.close}>&times;</button>
                            <h4 className="modal-title"><i className="fa fa-code"></i> {lang["workspace.createcustom"]}</h4>
                        </div>
                        <div className="modal-body scrollset">
                            <div className="container-fluid">
                                <div className="row filtercontent">
                                    <div className="col-lg-12 col-xs-12">
                                        <legend className="subhead nopadding">{lang["workspace.fieldname"]}</legend>
                                        <input type="name" className="form-control newreportcustomfieldname input-sm" value={this.props.fieldname} placeholder={lang["workspace.customname"]} />
                                        <span className='customaggregatedfieldname-error help-block marg-left-10 hide'>{lang["workspace.cscqnamevalidate"]}</span>
                                        <span className='custommeasurename-error help-block marg-left-10 hide'>{lang["workspace.cscqnameexists"]}</span>
                                        <div className="clear"></div>
                                        <legend className="subhead nopadding marg-top-10">{lang["workspace.formulaedit"]}</legend>
                                        <div className="marg-top-10">
                                            <div contenteditable="true" className="resize-vertical custommeasurequerydisplay" id="custommeasurequerydisplay" ></div>
                                            <button type="button" className="btn pull-right testformula marg-top-5 marg-left-5" onClick={this.props.testformula}><i className="fa fa-play"></i>{lang["workspace.validatebtn"]}</button>
                                            <button type="button" className="btn pull-right clearcustommeasuretext marg-top-5" onClick={this.props.clearQueryArea}><i className="fa fa-cut fa-rotate-270"></i>{lang["workspace.clear"]}</button>
                                        </div>
                                        <div className="clear"></div>
                                        <div className="notifications-table-container" id="customfieldtable">
                                            <div className="pull-left col-xs-4 nopadding hintdropdowncontainer">
                                                <table className="table nomargin">
                                                    <tbody>
                                                        <tr>
                                                            <td><span>{lang["workspace.formula"]}</span> {lang["workspace.hint"]}:</td>
                                                            <td>
                                                                <select className="function-type-dropdown" onChange={(e)=>{this.getFormula(e)}}>
                                                                    <option value="number" selected>{lang["workspace.numbertype"]}</option>
                                                                    <option value="string">{lang["workspace.strngtype"]}</option>
                                                                    <option value="date">{lang["workspace.datetype"]}</option>
                                                                    <option value="logical">{lang["workspace.logical"]}</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <BootstrapTable data={this.state.functionsArray} hover height='200px'>
                                                <TableHeaderColumn isKey dataField='type' className="newfilterwidth">{lang["workspace.type"]}</TableHeaderColumn>
                                                <TableHeaderColumn dataField='function' className="newfilterwidth">{lang["workspace.function"]}</TableHeaderColumn>
                                                <TableHeaderColumn dataField='hint'>{lang["workspace.hint"]}</TableHeaderColumn>
                                            </BootstrapTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer boc-modal-hf-padding">
                            <button type="button" className="btn btn-bird" data-dismiss="modal" onClick={this.close}><i className="fa fa-times"></i> {lang["workspace.cancelbtn"]}</button>
                            <button type="button" className="btn btn-bird applycustomfield" id="applycustomfieldpop" onClick={this.props.createCustomField}><i className="fa fa-check"></i> {lang["workspace.applybtn"]}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}