import React, { Component } from "react";
import _ from 'lodash';
import * as Highcharts from 'highcharts/highstock';
import solidGauge from "highcharts/modules/solid-gauge"
import HighchartsReact from 'highcharts-react-official'
import highcharts3d from 'highcharts/highcharts-3d';
import drilldow from "highcharts/modules/drilldown";
import dataModule from "highcharts/modules/data";
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import * as ChartUtil from "../../js/helpers/utils/chartutil";
import HighchartsMore from 'highcharts/highcharts-more'
import { whitelableMap } from "../../js/helpers/utils/whitelable";
const $ = window.$;

highcharts3d(Highcharts);
drilldow(Highcharts);
dataModule(Highcharts);
HighchartsMore(Highcharts)
solidGauge(Highcharts);

var charts = {};
export default class GuageChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        var data = this.props.data, xaxisname = this.props.xaxisname, xaxisColumnName = this.props.xaxisColumnName, xaxisDataType = this.props.xaxisDataType, yaxisnameArray = this.props.yaxisnameArray, chartid = this.props.chartid, chartType = this.props.chartType, legend = this.props.legend, position = this.props.position, metrictype = this.props.metrictype, metricdatefield = this.props.metricdatefield, isSharedReport = this.props.isSharedReport, colorThemeIndex = this.props.colorThemeIndex, chartSettings = this.props.chartSettings, solidgaugemin = this.props.solidgaugemin, solidgaugemax = this.props.solidgaugemax, iscustomstoryboard = this.props.iscustomstoryboard, features = this.props.features, isMLReport = this.props.isMLReport, ismultifact = this.props.ismultifact, multifactentities = this.props.multifactentities, datamodelId = this.props.datamodelId, reportid = this.props.reportid, facttables = this.props.facttables;
        var solidguage_compare_value = "";
        var colorThemesArray = ChartInfo.functions.getColorThemes();
        var isExportChartEnable = false;
        if(chartSettings.numberformat==undefined){
            var numberformat={};
            numberformat.decimals="0_2";
            chartSettings["numberformat"]=numberformat;
            }
            if(charts!=undefined && chartid!=undefined && charts[chartid]!=undefined &&  charts[chartid].refs!=undefined &&  charts[chartid].refs.chartComponent!=undefined &&  charts[chartid].refs.chartComponent.chart!=undefined){
            charts[chartid].refs.chartComponent.chart.destroy();
        }
        sessionStorage.setItem('pos', position);
        /*Set Chart height to fix resize issues */
        var chartnumber = chartid.replace("chart", "");
        this.state.chartnumber = chartnumber;
        isExportChartEnable = ChartUtil.checkExportChartEnable(features, position, isSharedReport);
        ChartUtil.streamingcharts.push(chartnumber);

        //Prefix/Suffix from Advanced Chart Settings
        var yaxislabelprefix = "", yaxislabelsuffix = "", datalabelprefix = "", datalabelsuffix = "", tooltipprefix = "", tooltipsuffix = "";
        if (chartSettings["AdvancedChartSettings"] != undefined) {
            var advanced = chartSettings["AdvancedChartSettings"];
            yaxislabelprefix = advanced.yAxis[0].axisLabelPrefix;
            yaxislabelsuffix = advanced.yAxis[0].axisLabelSuffix;

            datalabelprefix = advanced.plotOptions.series.dataLabels.dataLabelPrefix;
            datalabelsuffix = advanced.plotOptions.series.dataLabels.dataLabelSuffix;

            tooltipprefix = advanced.tooltip.tooltipPrefix;
            tooltipsuffix = advanced.tooltip.tooltipSuffix;

            if (chartType != "invertedstackedbar" && chartType != "invertedbar") {
                advanced.chart.inverted = false;
            } else if (chartType === "invertedstackedbar" || chartType === "invertedbar") {
                advanced.chart.inverted = true;
            }
        }

        if ($('.tileview[data-num=' + chartnumber + ']').length === 1) {
            var setheight = $('.tileview[data-num=' + chartnumber + ']').height() - 42;
            $("#chart" + chartnumber).height(setheight);
        }

        var bgcolor = $('.maxanim7[data-num=' + chartnumber + ']').css('background-color');
        if (bgcolor == undefined || bgcolor === 'rgba(0, 0, 0, 0)') { bgcolor = 'rgba(255, 255, 255, 1)' }

        if (solidgaugemin == "" || solidgaugemin == undefined) {
            solidgaugemin = 0;
        }
        var solid_compare_status;
        var solid_compare_value;
        if (metrictype != "All") {
            if (metricdatefield === undefined || metricdatefield === "") {
                solidguage_compare_value = "";
            }
            else if (data[0].metric === 0 || data[0].metric === null || data[0].y === null || data[0].y === 0) {
                solidguage_compare_value = "";
            }
            else if (0 < data[0].metric) {
                solid_compare_status = "increase";
                solid_compare_value = data[0].metric;
            }
            if (0 > data[0].metric) {
                solid_compare_status = "decrease";
                solid_compare_value = data[0].metric;
            }
        } else {
            solidguage_compare_value = "";
        }
        var that = this;
        var aggType = data[0].aggType;
        var userLang = navigator.language || navigator.userLanguage;
        var locale = "en_za";
        var solidgaugemax;
        var name = data[0].name;
        if (data[0].customgaugevalue != undefined && data[0].customgaugevalue != null && data[0].customgaugevalue != 0) {
            solidgaugemax = data[0].customgaugevalue;
        }
        else if (solidgaugemax === "" || solidgaugemax === undefined) {
            solidgaugemax = data[0].y * 3;
        }
        else {
            solidgaugemax = solidgaugemax
        }
        if (solidgaugemax === 0 || solidgaugemax === null || solidgaugemax === "" || solidgaugemax === undefined) {
            solidgaugemax = 100;
        }
        //var data=data[0].y;
        if ( solidgaugemin != undefined && solidgaugemin != "") {
            solidgaugemin = parseFloat(solidgaugemin);
        }
        var solidmiddlevalue = (solidgaugemin + parseFloat(solidgaugemax)) / 2;
        var solidfirstvalue = (solidgaugemin + solidmiddlevalue) / 2;
        var solidsecondvalue = (solidmiddlevalue + parseFloat(solidgaugemax)) / 2;
        /*if(data[0].y>=solidgaugemax){
            solidgaugemax=(parseInt(solidgaugemax) + data[0].y);
        }*/
        var options = {
            lang: {
                drillUpText: '\u25c1 Back',
                contextButtonTitle: 'Download',
                decimalPoint: '.',
                thousandsSep: ',',
                BacktoMap_key: 'Back to Map'
            },
            chart: {
                events: {
                    click: function (event) {
                        var $this = event.currentTarget;
                        if (event.target.hasAttribute('href')) {
                            var bgcolor = $('.maxanim7[data-num=' + chartnumber + ']').css('background-color');
                            if (bgcolor == undefined || bgcolor === 'rgba(0, 0, 0, 0)') { bgcolor = 'rgba(255, 255, 255, 1)' }
                            this.options.exporting.chartOptions.chart.backgroundColor = bgcolor;
                        }
                    },
                },
                renderTo: chartid,
                reportId: reportid,
                backgroundColor: 'transparent',
                style: {
                    fontFamily: whitelableMap["fontfamily"],
                    fontWeight: '400',
                },
                type: 'solidgauge',
                //margin: '-20',
                animation: false,
                spacing: [15, 15, 15, 15],
                //height:'auto'
            },
            title: {
                text: '',
                align: 'left'
            },
            subtitle: {
                text: '',
                align: 'left'
            },
            pane: {
                center: ['50%', '70%'],
                size: '90%',
                startAngle: -90,
                endAngle: 90,
                background: {
                    backgroundColor: '#EEE',
                    innerRadius: '60%',
                    outerRadius: '100%',
                    shape: 'arc',
                    borderWidth: 0
                }
            },
            yAxis: [{

                /*stops: [
                    [0.1, colorThemesArray[colorThemeIndex][0]], // green
                    [0.5, colorThemesArray[colorThemeIndex][1]], // yellow
                    [0.9, colorThemesArray[colorThemeIndex][2]] // red
                ],*/
                lineWidth: 0,
                minorTickInterval: null,
                tickPixelInterval: 400,
                tickWidth: 0,
                title: {
                    y: -70
                },
                labels: {
                    //y: 16
                    distance: 25,
                    formatter: function () {
                        var details = JSON.parse(ChartInfo.functions.getChartDetails(chartnumber));
                        if (this.value != "") {
                            if (details.customSolidGauge.length != 0 || details.metricdatefield != undefined) {
                                this.value = parseInt(this.value);
                            }
                            if (chartSettings.numberformat.abbrevation != undefined) {
                                var num = ChartInfo.functions.addFormatting(this.value, chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator);
                                return num;
                            } else {
                                return this.value;
                            }
                        }
                        else {
                            return 0;
                        }
                    }
                },
                min: solidgaugemin,
                max: solidgaugemax,
            }],
            tooltip: {
                formatter: function () {
                    var details = JSON.parse(ChartInfo.functions.getChartDetails(chartnumber));
                    var yaxisdisplayName = details["yAxis"][0].displayName;
                    if (aggType === 'customaggregatedfield') {
                        aggType = 'raw'
                    }
                    var num = ChartInfo.functions.addFormatting(this.y, chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator)
                    var name = '<tr>' + yaxisdisplayName + ' <span class=bocaggtype>[' + aggType + '] </span>' + ':&nbsp;'
                        + '<td style="padding:0"><b>' + num + '</b></td></tr>'
                    return name;
                },
                useHTML: true,
            },
            plotOptions: {
                solidgauge: {
                    animation:false,
                    dataLabels: {
                        enabled: true,
                        // y: -10,
                        borderWidth: 0,
                        useHTML: true,
                        //   format: '<div style="text-align:center;text-decoration:none;font-size:200%">{y}</div>'
                    },
                },
                series: {
                    stickyTracking: false,
                    animation: false,
                }
            },
            drilldown: {
                // activeAxisLabelStyle: {
                //     cursor: 'pointer',
                //     textDecoration: 'none',
                //     color: '#000',
                //     fontWeight: '400'
                // },
                // activeDataLabelStyle: {
                //     cursor: 'pointer',
                //     textDecoration: 'none',
                //     color: '#000',
                //     fontWeight: '400'
                // },
                series: []
            },
            legend: {
                layout: 'horizontal',
                align: 'right',
                verticalAlign: 'bottom',
                borderWidth: 0
            },
            boost: {
                enabled: (isMLReport != undefined) ? isMLReport : false
            },
            series: [{
                data: data,
                name: [name],
                dataLabels: {
                    y: -5,
                    align:'center',
                    verticalAlign:'middle',
                    x:0,
                    // format: '<div style="text-align:center"><span style="font-size:25px;color:' +  ((Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black') + '">{y}</span></div>'

                    formatter: function () {
                        var cn = chartid.replace("chart", "");
                        var fontpercentwidth = "";
                        var gswidth = $('.tileview[data-num="' + cn + '"]').attr('data-gs-width');
                        var gsheight = $('.tileview[data-num="' + cn + '"]').attr('data-gs-height');
                        if (gswidth <= "2") {
                            fontpercentwidth = "150%"
                        } else if (gswidth >= "2" && gsheight < "4") {
                            fontpercentwidth = "150%"
                        } else {
                            fontpercentwidth = "250%"
                        }
                        var gagugepercent = '<span style="font-size:' + fontpercentwidth + ';display:block;width:100%;"> ' + ((this.y / solidgaugemax) * 100).toFixed(2) + '%' + '</span>';
                        if (this.y == "0") {
                            gagugepercent = '<span style="font-size:' + fontpercentwidth + ';display:block;width:100%;"> ' + 0 + '</span>';
                        }
                        else {
                            gagugepercent = '<span style="font-size:' + fontpercentwidth + ';display:block;width:100%;"> ' + ((this.y / solidgaugemax) * 100).toFixed(2) + '%' + '</span>';
                        }
                        var value = ChartInfo.functions.addFormatting(this.point.y, chartSettings.numberformat.abbrevation, chartSettings.numberformat.decimals, chartSettings.numberformat.seperator)
                        var gauge_compare = "";
                        if (solid_compare_status === "increase") {
                            var gauge_compare = '<span class="label-metric metric-increase" style="font-size:110%;left:-2px;"><i class="fa fa-caret-up"></i> ' + solid_compare_value + '%' + '</span>';
                        } else if (solid_compare_status === "decrease") {
                            var gauge_compare = '<span class="label-metric metric-decrease" style="font-size:110%;left:-2px;"><i class="fa fa-caret-down"></i> ' + solid_compare_value + '%' + '</span>';
                        }
                        var prefixcontent = '<span class="prefix">'+datalabelprefix+'</span>';
                        var sufffixcontent = '<span class="suffix">'+datalabelsuffix+'</span>'   
                        return '<div style="font-weight:300;text-align:center;" class="gauge' + chartid + '">'+prefixcontent+'' + gauge_compare + '' + gagugepercent + '<span style="display:block;width:100%;font-size:150%;color:#666;">' + value + '</span>'+sufffixcontent+'</div>';
                    }
                },
            }],
            colors: colorThemesArray[colorThemeIndex],
            exporting: {
                enabled: false,
                buttons: {
                    contextButton: {
                        symbol: 'download',
                        symbolFill:"#fff",
                        symbolStrokeWidth:0,
                        theme:{
                            fill:'#ffffff',
                            padding:5,
                            stroke:'none',
                            cursor:'pointer'
                        },
                        menuItems: [{
                            textKey: 'printChart',
                            onclick: function () {
                                var chartId = this.userOptions.chart.renderTo;
                                var reportId = this.userOptions.chart.reportId;
                                var msg = "Chart printed"
                                if (reportId != undefined) {
                                    ChartUtil.auditMessage(reportId, msg);
                                }
                                this.print();
                            }
                        }, {
                            separator: true
                        }, {
                            textKey: 'downloadPNG',
                            onclick: function () {
                                var chartId = this.userOptions.chart.renderTo;
                                var reportId = this.userOptions.chart.reportId;
                                var msg = "Chart downloaded in PNG format"
                                if (reportId != undefined) {
                                    ChartUtil.auditMessage(reportId, msg);
                                }
                                this.exportChart();

                            }
                        }, {
                            textKey: 'downloadJPEG',
                            onclick: function () {
                                var chartId = this.userOptions.chart.renderTo;
                                var reportId = this.userOptions.chart.reportId;
                                var msg = "Chart downloaded in JPEG format";
                                if (reportId != undefined) {
                                    ChartUtil.auditMessage(reportId, msg);
                                }
                                this.exportChart({
                                    type: 'image/jpeg'
                                });
                            }
                        }, {
                            separator: true
                        }, {
                            textKey: 'downloadPDF',
                            onclick: function () {
                                var chartId = this.userOptions.chart.renderTo;
                                var reportId = this.userOptions.chart.reportId;
                                var msg = "Chart downloaded in PDF format";
                                if (reportId != undefined) {
                                    ChartUtil.auditMessage(reportId, msg);
                                }
                                this.exportChart({
                                    type: 'application/pdf'
                                });
                            }
                        }, {
                            textKey: 'downloadSVG',
                            onclick: function () {
                                var chartId = this.userOptions.chart.renderTo;
                                var reportId = this.userOptions.chart.reportId;
                                var msg = "Chart downloaded in SVG format";
                                if (reportId != undefined) {
                                    ChartUtil.auditMessage(reportId, msg);
                                }
                                this.exportChart({
                                    type: 'image/svg+xml'
                                });
                            }
                        }]

                    }
                },
                chartOptions: {
                    chart: {
                        width: 800,
                        backgroundColor: bgcolor
                    },
                }
            },
            credits: {
                enabled: false
            }
        };
        //For Negative Data Lables
        if(solidgaugemax<0){
            options.yAxis[0].min = solidgaugemax;
            options.yAxis[0].max = solidgaugemin;
        }
        var diff = solidmiddlevalue - data[0].y
        diff = diff / solidmiddlevalue * 100

        if (diff < 10 && diff > 0) {
            solidmiddlevalue = "";
        }

        solidmiddlevalue = solidmiddlevalue;
        if(solidmiddlevalue!=undefined && solidmiddlevalue!=""){
            solidmiddlevalue=Number((solidmiddlevalue).toFixed(2));
        }
        solidfirstvalue = solidfirstvalue;
        solidsecondvalue = solidsecondvalue;
        solidgaugemin = solidgaugemin;
        solidgaugemax = solidgaugemax;

        if (chartType === 'fullcirclegauge') {
            options.pane.center = ['50%', '50%'];
            options.pane.startAngle = 0;
            options.pane.endAngle = 360
            //options.series[0].dataLabels.y = -30;
            options.yAxis[0].tickPositions = [solidgaugemin, solidfirstvalue, solidmiddlevalue, solidsecondvalue, data[0].y];
            //options.yAxis.labels.y=0;
            options.chart.spacingBottom = 20;
        }
        if (chartType === 'solidgauge') {
           // options.series[0].dataLabels.y = 50;
            if (diff < 0) {
                options.yAxis[0].tickPositions = [solidgaugemin, solidmiddlevalue, solidgaugemax];
            }
            else {
                options.yAxis[0].tickPositions = [solidgaugemin, solidmiddlevalue, solidgaugemax, data[0].y];

            }
        }
        if (chartSettings['dataLabel'] == true) {
            options.plotOptions.solidgauge.dataLabels.enabled = true;
        } else {
            options.plotOptions.solidgauge.dataLabels.enabled = false;
        }
        if (chartSettings['yAxisLabel'] == true) {
            options.yAxis[0].labels.enabled = true;
        } else {
            options.yAxis[0].labels.enabled = false;
        }
        if (isExportChartEnable) {
            options.exporting.enabled = true;
        }
        if ($(window).width() < 780) {
            options.chart.zoomType = 'none';
        }

        /*-------Chart Settings------*/
        //Add Advanced Chart Settings
        if (chartSettings["AdvancedChartSettings"] != undefined) {
            var advanced = chartSettings["AdvancedChartSettings"];
            $.extend(true, options, advanced);
        }

        //Conditional Formating
        var conditionalFormatData = [];
        if (ChartInfo.functions.getConditionalFormatData(chartnumber) != undefined) {
            conditionalFormatData = ChartInfo.functions.getConditionalFormatData(chartnumber);
        }
        if (conditionalFormatData != undefined && conditionalFormatData.length > 0) {
            var datavalue = options.series[0].data[0].y;
            _.each(conditionalFormatData, function (conditions) {
                if (yaxisnameArray[0] == conditions.name) {
                    _.each(conditions.conditions, function (i) {
                        var value = i.value;
                        var range = i.range;
                        if (range == "<") {
                            if (datavalue < value) {
                                options.series[0].data[0].color = i.color;
                            }
                        } else {
                            if (datavalue > value) {
                                options.series[0].data[0].color = i.color;
                            }
                        }
                    });
                }
            });
        }

        var chart = this;
        charts[chartid] = chart;
        $("#" + chartid + " svg>title").remove();
        var chartnum = chartid.replace("chart", "");
        ChartInfo.functions.setChartSize(chartnum, chart);
        var embedenable = $('.viewembedreportmodal .in').length;
        if (embedenable == 1) {
            setTimeout(function () {
                var chartno = chartid
                chartno = chartno.replace("chart", "");
                var acwidth = $('#' + chartid + ' .highcharts-container').width();

                if ($('.fullscreenmain .maxanim8').length == 1) {
                    var acheight = $('.maxanim1[data-num=' + chartno + ']').height() - 150;
                    $('#' + chartid).append("<img id='imageshot" + chartid + "' class='thumbimage' crossOrigin='Anonymous' style='z-index:9999;display: none;float: left;position: relative;top: 0;left: 0;width:" + acwidth + "px;max-height:" + acheight + "px;' width=" + acwidth + "   '>");
                } else {
                    var acheight = $('.maxanim1[data-num=' + chartno + ']').height() - 50;
                    $('#' + chartid).append("<img id='imageshot" + chartid + "' class='thumbimage' crossOrigin='Anonymous' style='z-index:9999;display: none;float: left;position: relative;top: 0;left: 0;width:" + acwidth + "px;max-height:" + acheight + "px;' width=" + acwidth + "   '>");
                }


                // URL to Highcharts export server
                var exportUrl = '//export.highcharts.com/';

                var thumbchart = chart.options;
                thumbchart.plotOptions.pie.dataLabels.format = "<b>{point.name}</b>: {point.percentage:.1f} %<br>({point.y:,.0f})"
                if ($('.fullscreenmain .maxanim8').length == 1) {

                    thumbchart.xAxis[0].labels.style.fontSize = '6px';
                    // thumbchart.xAxis[0].title.style.fontSize='8px';

                    thumbchart.yAxis[0].labels.style.fontSize = "6px";
                    // thumbchart.yAxis[0].title.style.fontSize='8px';

                    thumbchart.legend.itemStyle.fontSize = '6px';
                    setTimeout(function () {
                        thumbchart.xAxis[0].labels.style.fontSize = '11px';
                        thumbchart.yAxis[0].labels.style.fontSize = "11px";
                    }, 100);

                }
                else {

                    thumbchart.xAxis[0].labels.style.fontSize = '11px';
                    // thumbchart.xAxis[0].title.style.fontSize='8px';

                    thumbchart.yAxis[0].labels.style.fontSize = "11px";
                    // thumbchart.yAxis[0].title.style.fontSize='8px';

                    thumbchart.legend.itemStyle.fontSize = '8px';
                }


                // POST parameter for Highcharts export server
                var object = {
                    options: JSON.stringify(thumbchart),
                    type: 'image/png',
                    async: true,
                    width: acwidth,
                    //scale:0
                    //Height: acheight
                };
                $.ajax({
                    type: 'post',
                    url: exportUrl,
                    data: object,
                    success: function (data) {
                        // Update "src" attribute with received image URL
                        $('#imageshot' + chartid).attr('src', exportUrl + data);
                    }
                });
            }, 200);
        }
        return (
            <div style={{ height: "360px" }} ref="chartparent" >
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                    ref="chartComponent"
                />
            </div>
        );
    }
    componentDidMount() {
        var compos = {};
        compos["chartComponent"] = this.refs.chartComponent;
        compos["chartparent"] = this.refs.chartparent;
        ChartInfo.functions.setChartComponents(this.state.chartnumber, compos);
        if (this.state.chartid != undefined && this.state.chartid == "nlpchart") {
            this.refs.chartComponent.chart.setSize($("#nlpchart").width(),$("#nlpchart").height(),false);
        } else {
            if(this.refs!=undefined && this.refs.chartComponent!=undefined && this.refs.chartComponent.chart!=undefined){
                ChartUtil.charts[this.state.chartid]=this.refs.chartComponent.chart;
            }
            //ChartUtil.modifyChartHeightOnResize(this.state.chartnumber);
        }
    }
}