import React from 'react'
import { lang } from "../js/helpers/utils/languages/language";
export default class AdvancedMLStoeyboardShare extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        var that = this.props;
        return (
            //    <!-- advancedstoryboardsharepermissions.js -->
            <div className="modal fade in" id="advancedsharestoryboardpermissionmodal" tabindex="-1" role="dialog" aria-labelledby="advancedsharestoryboardpermissionmodal" aria-hidden="true" style={{ display: "block" }}>
                <div className="modal-backdrop fade in"></div>
                <div className="modal-dialog modal-sm modal-md-400 filterleft modal-dialog-center" style={{ zIndex: "1050" }}>
                    <div className="modal-content">
                        <div className="modal-header boc-modal-hf-padding">
                            <button type="button" className="close white" id="advanceedclose" aria-hidden="true" data-number="" data-dismiss="modal">&times;</button>
                            <h4 className="modal-title" id="mySmallModalLabel"><i className="fa fa-lock"></i> Advanced Access Permissions</h4>
                        </div>
                        <div className="modal-body scrollset">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="row-same-height">
                                        {/* <!-- Columns Window --> */}
                                        <div className="col-xs-12 col-xs-height settingscontent columnspermissiondiv">
                                            <div className="modal-height-fix">
                                                <div className="col-xs-12 marg-bot-10"><i className="fa fa-columns"></i> {lang["storyboard.restrictedviewaccess"]}</div>
                                                <div className="clear"></div>
                                                <div className="permissionscroller">
                                                    {that.groupcollection.map((group) => {
                                                      return  that.selectedgrouplists.map((selectedgroup) => {
                                                            return (selectedgroup.groupid == group.groupId) ? (
                                                                <div className="permissioncontainer dobyfilteritems" data-userid={group.groupId} user-type="groups">
                                                                    <div className="col-xs-12"><i className="fa fa-users username"></i>{group.groupName}<i className="fa fa-lock pull-right"></i></div>
                                                                    <div className="clear"></div>
                                                                    <div className="sharedviewonly scrollsetulviewer">
                                                                        <legend className="sharedviwer"><i className="fa fa-eye-slash" aria-hidden="true"></i> {lang["storyboard.viewaccess"]}</legend>
                                                                        <div className="drilldowntogglecheckbox">
                                                                            <div className="checkbox checkbox-slider--c checkbox-slider-sm">
                                                                                <label> <input type="checkbox" id="btnReadonlyReport " className="btnReadonlyReport" data-userid={group.groupId} user-name={group.groupName} user-type="groups" /><span>&nbsp;</span></label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : null
                                                        })
                                                    })}
                                                    {that.userscollection.map((user) => {
                                                     return   that.selecteduserslist.map((selecteduser) => {
                                                            return (selecteduser.userid == user.userid) ? (
                                                                <div className="permissioncontainer dobyfilteritems" data-userid={user.userid} user-type="users">
                                                                    <div className="col-xs-12"><i className="fa fa-user username"></i> {user.loginname}<i className="fa fa-lock pull-right"></i></div>
                                                                    <div className="clear"></div>
                                                                    <div className="sharedviewonly scrollsetulviewer">
                                                                        <legend className="sharedviwer"><i className="fa fa-eye-slash" aria-hidden="true"></i> {lang["storyboard.viewaccess"]}</legend>
                                                                        <div className="drilldowntogglecheckbox">
                                                                            <div className="checkbox checkbox-slider--c checkbox-slider-sm">
                                                                                <label> <input type="checkbox" id="btnReadonlyReport " className="btnReadonlyReport" data-userid={user.userid} user-name={user.loginname} user-type="users" /><span>&nbsp;</span></label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : null
                                                        })
                                                    })}
                                                </div>
                                            </div>
                                            {/* <!-- /Modal Height Fix --> */}
                                        </div>
                                        {/* <!-- /Columns Window --> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer boc-modal-hf-padding">
                            <button type="button" className="btn btn-bird cancelaccesspermisions" id="btn-bird" data-dismiss="modal" aria-hidden="true"><i className="fa fa-times"></i> {lang["storyboard.cancelbtn"]}</button>
                            <button type="button" className="btn btn-bird applyaccesspermisions" onClick={that.applyaccesspermisions} id="applyaccesspermision" data-num="" aria-hidden="true"><i className="fa fa-check"></i> {lang["storyboard.applybtn"]}</button>
                        </div>
                    </div>
                    {/* <!-- /.modal-content --> */}
                </div>
                {/* <!-- /.modal-dialog --> */}
            </div>);
    }
}