import React from 'react';
import AceEditor from 'react-ace';
import { lang } from "../js/helpers/utils/languages/language";

export default class CustomQueryHtml extends React.Component {
	constructor(props){
		super(props);
		this.state={
			customqueryName:props.customqueryName
		}
	}
	render() {
		return (
			<>
				{/* <!-- datahubcustomqueryview.js --> */}
				<div role="tabpanel" className="datahubitemsettingstabscontainer" data-id={this.props.connId !== undefined ? this.props.connId : ''} name={this.props.entityname !== undefined ? this.props.entityname : ''}>
					<div className="entityname">
						<i className="fa fa-code"></i>
						{(this.props.entityname === '') ? <>{lang["datahub.customqry"]}</> : <>{this.props.entityname}</>}
						<span className="entityconnectionsource">{this.props.entitySourceName}</span>
						<button type="button" className="btn btn-transparent pull-right closesettingscontainer" onClick={this.props.close}>
							<i className="fa fa-times"></i>
						</button>
					</div>
					<ul className="nav nav-tabs tabcontainer customquerynavtab" role="tablist">
						<li role="presentation" className="tabheadli active">
							<a href="#datahubcustomquerytabview" id="datahubcustomquerytab" aria-controls="datahubcustomquerytabview" role="tab" data-toggle="tab"><i className="fa fa-code"></i> {lang["datahub.customqry"]}</a>
						</li>
					</ul>
					<div className="tab-content">
						<div role="tabpanel" className="tab-pane tabviewitems active" id="datahubcustomquerytabview">
							<div className="datahubcustomquery-container">
								<div className="modal-body">
									<div className="container-fluid">
										<div className="row">
											<div className="col-xs-12">
												<form className="form-horizontal marg-top-10 form-inline">
													<div className="form-group form-group-sm required col-xs-6 nopadding" style={{float: "none"}}>
														<span class="required right legend"></span>
														<label class="subhead marg-righ-10" for="customqueryname">{lang["datahub.plcholdrcustom"]} </label>
														<input type="text" style={{display:"inline-block",width:"80%"}} defaultValue={this.state.customqueryName} data-parent={this.props.connId != undefined ? this.props.connId : ''} data-name={this.props.entityname != undefined ? this.props.entityname : ''} is-saved={this.props.isSaved ? this.props.isSaved : 'false'} disabled={this.props.isSaved? true:false} className="form-control  customqueryname" placeholder={lang["datahub.plcholdrcustom"]} onChange={(e)=>{
															
															this.state.customqueryName=e.target.value
														
														}} />
														<span className="cscqnamevalidate" style={{ display: "none", color: "red" }}>{lang["datahub.cscqnamevalidate"]}</span>
														<span className="cscqnamespecailcharacter" style={{ display: "none", color: "red" }}>{lang["datahub.cscqnamespecailcharacter"]}</span>
														<span className="cscqnameexists" style={{ display: "none", color: "red" }}>{lang["datahub.cscqnameexists"]}</span>
														<span id="connectionname" className="help-block hide"></span>
														
													</div>
												</form>
												<form className="form-horizontal marg-top-10">
													<div className="form-group form-group-sm">
														<div className="col-xs-12">
															<div id="advancedcustomquery" className="querydisplay clear scrollset advancedcustomquery" data-parent={this.props.connId != undefined ? this.props.connId : ''} data-name={this.props.entityname != undefined ? this.props.entityname : ''} contentEditable="true" style={{ minHeight: `calc(50vh - 135px)` }}>
														
															</div>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<button type="submit" className="btn btn-bird pull-left" id="validatedatahubcustomquery" onClick={this.props.validatedatahubcustomquery}><i className="fa fa-play"></i> {lang["datahub.validatebtn"]}</button>
									<button type="button" className="btn btn-bird active pull-right" id="savescustomquery" onClick={this.props.savescustomquery}>
										{(this.props.customqueryName === "") ? (
											<><i className="fa fa-floppy-o"></i> {lang["datahub.savebtn"]}</>
										) : (
												<><i className="fa fa-refresh"></i> {lang["datahub.updatebtn"]}</>
											)}
									</button>
									<button class="btn btn-bird copycustomquery marg-left-10" id="copycustomquery"  data-clipboard-text="">
					     <i class="fa fa-clipboard" id="clipboards"></i>&nbsp;&nbsp;{lang["datahub.clipboard"]}
					</button>
								</div>
							</div>
						</div>
						<div role="tabpanel" className="tab-pane tabviewitems" id="datahubmodifyentitiestabview">
							<div className="modal-body">
								<div className="container-fluid">
									<div className="row">
										<div className="col-xs-12">
											<div className="notifications-table-container marg-top-10 datahubmodifyentitiestablecontainer">

											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-bird active pull-right applydatahubmodifyentities" onClick={this.props.applydatahubmodifyentities} id={this.props.connId != undefined ? this.props.connId : ''} name={this.props.customqueryName != undefined ? this.props.customqueryName : ''} data-entityIsCustomQuery={this.props.isCustomQuery != undefined ? this.props.isCustomQuery : false}>
									<i className="fa fa-refresh"></i> {lang["datahub.updatebtn"]}
                				</button>
							</div>
						</div>
					</div>
				</div>
				{/* <!-- /datahubcustomqueryview.js --> */}
			</>
		);
	}
}