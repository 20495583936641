import React, { Component } from "react";
import ReactDOM from 'react-dom'
import _ from 'underscore'
import CreateUsersView from "./createuserhtml.js";
import EditUserAccountForm from "./edituseraccountform.js"
import ResetPasswordFromAdminView from "./resetpasswordfromadminview.js"
import DeleteGroupForm from "./deletegroupform.js"
import {
  BootstrapTable, TableHeaderColumn,
  ButtonGroup, Button, InsertButton
} from 'react-bootstrap-table';
import UserViewTemplate from "./Groupshtml.jsx";
import UserListViewTemplate from "./groupsList.jsx";
import ResetPasswordView from "./resetpasswordview.js";
import SendRequest from '../../SendRequest';
import { lang } from "../../js/helpers/utils/languages/language";
import saveOrUpdateUsers from "./saveOrUpdateUsers.js";
import Adminhtml from '../adminhtml'
const $ = window.$;

export default class UsersView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    let featuresString = sessionStorage.getItem("features");
    featuresString = featuresString.slice(0, 3) + featuresString.slice(4);
    let decryptedString = window.atob(featuresString);
    this.state.parsedFeatureJson = JSON.parse(decryptedString);
    this.state.features = JSON.parse(decryptedString);
    this.createUsersView =this.createUsersView.bind(this);
    this.editUsersView =this.editUsersView.bind(this);
    this.deleteUsersView =this.deleteUsersView.bind(this);
    this.resetpasswordView =this.resetpasswordView.bind(this);
    this.resetpasswordfromadminview =this.resetpasswordfromadminview.bind(this);
    this.searchkeyup =this.searchkeyup.bind(this);
    this.render();
    var path = '<li><a class="navDashboard" href="#"><i class="fa  fa-home"></i>'+lang["admin.header.dashtitle"]+'</a></li><li><a href="#admin"><i class="fa fa-user-secret"></i> ' + lang["admin.header.admintitle"] + '</a></li><li><a><i class="fa fa-users"></i> ' + lang["admin.groups.title"] + '</a></li>';
    $(".navbar.navbar-bird ul.nav-path").html(path);
  }

  getGroupslist(page, search, pageDisplay, order, colName, features) {
    var index = 0;
    var noOfRecords = $(".usersrowlimitbutton .page-size").text() == "" ? 10 : $(".usersrowlimitbutton .page-size").text();
    var totalRecords = 0;
    if (page != undefined && page > 1) {
      index = (page - 1) * noOfRecords;
    }
    if (order == undefined) {
      order = 'asc';
    }
    if (colName == undefined) {
      colName = '';
    }
    this.dataFieldValue = colName;
    this.sortingOrder = order;
    this.page = page;
    var that = this
    let requestHeaders = {
      method: "get",
      headers: new Headers({
        "Content-Type": "application/json"
      })
    };
    SendRequest({
      url: "getGroupsList",
      queryString: "?index=" + index + "&noOfRecords=" + noOfRecords + "&searchCriteria=" + search + "&order=" + order + "&colName=" + colName,
      sucessFunction: (response) => {
        if (response.hasOwnProperty('error')) {
          $(".spinner").hide();
        } else {
          var headerlist = response["header"];
          var data = response["data"];
          totalRecords = response["totalRecords"];
          var keys = Object.keys(headerlist);
          var totalPages;
          if (pageDisplay == undefined) {
            pageDisplay = 10;
          }
          if (totalRecords > 10) {
            totalPages = Math.ceil(totalRecords / pageDisplay);
          } else {
            totalPages = 1;
          }

          _.each(data, function (val, index) {
            _.each(features[0].features, function (feature) {
              if (feature.featureName == 'edit_user' && feature.allow == true) {
                val.edit = <button class="btn btn-admin editaccount3" onClick={that.editUsersView} id="edituser" type="button" value={val.groupId}><i class="fa fa-pencil"></i></button>;
              }
              if (feature.featureName == 'delete_user' && feature.allow == true) {
                val.delete = <button class="btn btn-admin  deleteuserform" onClick={that.deleteUsersView} id="deleteuser" type="button" value={val.groupId}><i class="fa fa-close"></i> </button>;
              }
            });
          });
          ReactDOM.unmountComponentAtNode(document.getElementById("userstable"));
          ReactDOM.render(<UserListViewTemplate editUsersView={this.editUsersView} deleteUsersView={this.deleteUsersView} keys={keys} search={search} storedprops={this.props} totalRecords={totalRecords} totalRecords={totalRecords} sizePerPage={pageDisplay} headerlist={headerlist} search={search} data={data} />, document.getElementById("userstable"));

          $(".spinner").hide();

        }
      },
      rejectFunction: () => { },
      headers: requestHeaders

    });


  }
  createUsersView = () => {
    var createUsersView = new CreateUsersView({});
  }
  editUsersView = (e) => {
    var val = $(e.currentTarget).val();
    var editUserAccountForm = new EditUserAccountForm({ userId: val });
  }
  deleteUsersView = (e) => {
    var val = $(e.currentTarget).val();
    var deleteGroupForm = new DeleteGroupForm({ groupId: val });
  }
  resetpasswordView = () => {
    var resetpasswordView = new ResetPasswordView({});
  }
  resetpasswordfromadminview = (e) => {
    var val = $(e.currentTarget).val();
    var resetpasswordfromadminview = new ResetPasswordFromAdminView({ userId: val });
  }
  searchkeyup = (e) => {
    //console.log("in search block");
    var that = this;
    var delay = (function () {
      var timer = 0;
      return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
      };
    })();
    var search = $(e.currentTarget).val().toLowerCase();
    var pageDisplaySearch = $("#pageDropDown").text();
    delay(function () {
      that.searchValue = $(".searchuserstable").val();
      that.getGroupslist(undefined, that.searchValue, pageDisplaySearch, that.sortingOrder, that.dataFieldValue, that.state.features);
    }, 100);

    $(".searchuserstable").focus();
  }
  render() {
    this.getGroupslist(undefined, "", 10, 'asc', undefined, this.state.features);
    $('a.boc-maxscreen-left-item.Groups').addClass('showactive')
    $('.boc-admin-main .maxscreen-lft-filter-items a').not('.Groups').removeClass('showactive');
    return (
      <UserViewTemplate searchkeyup={this.searchkeyup} createUsersView={this.createUsersView} resetpasswordView={this.resetpasswordView} features={this.state.features} />
    )
  }
}


