import React from 'react';
import { getMessage } from '../js/helpers/utils/BirdMessage';
import { lang } from "../js/helpers/utils/languages/language";
import SendRequest from '../SendRequest';
import ReactDOM from 'react-dom';
import VersionsListView from './VersionsListView';
import VersionListPopup from './VersionListPopup';


const $ = window.$;

export default class VersionMyReportHtml extends React.Component {
  constructor(props) {
    super(props);
    // this.render();
    //this.componentDidMount();
  }
  componentDidMount() {
    // $("#modal-backdrop").addClass('modal-backdrop fade in');
    $(".form-control").keyup(function () {
      var valThis = $(this).val().toLowerCase();
      var valLength = valThis.length;
      var maxCount = 45;
      var errormsg = "#errmsg"
      if (valLength >= maxCount) {
        $(errormsg).html("");
        $(errormsg).html(lang["reports.namexceeds"] + maxCount + "").show();
        $(this).val($(this).val().substring(0, $(this).val().length - 1));
      } else {
        $(errormsg).html("");
      }
    });
  }
  versionReport = (e) => {
    var that = this;
    if (this.props.reportId == null || this.props.reportId == undefined || this.props.reportId == "" || window.location.pathname.indexOf("importreport") >= 0 || window.location.pathname.indexOf("sharereport") >= 0) {
      $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
      $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
      $('.birdmessagebuttons').removeClass('show');
      $('.birdmessagebuttons').addClass('hide');
      $('.birdmessage-center').addClass("message-top-in");
      $('.details').removeClass('show');
      $('.details').addClass('hide');
      $('.birdmessage h2').empty().append('Information');
      $('.birdmessage-info').empty().text(getMessage("BirdInformation01"));
    } else {
      let requestHeaders = {
        method: "post",
        headers: new Headers({
          "Content-Type": "application/json"
        })
      };
      var versionName = $("#versionreportname").val().trim();
      var foldername = $("#versionstoryboardfolder").val().trim();
      var folderdesc = $("#versionstoryboarddesc").val().trim();
      var reservedwordslist = JSON.parse(sessionStorage.getItem("reservedwordslist"));
      if (versionName == undefined || versionName == "" || versionName.length == 0 || /[~`!@#$%^&*()+\=\[\]{};':\\|†,.<>\/?"""`]/.test(versionName)) {
        $("#storyboardname-help-block").html(lang["reports.validname"])
        return;
      } else if (versionName.length > 70) {
        $("#storyboardname-help-block").html(lang["reports.reportnameexceeds"])
        return;
      } else if (reservedwordslist != null) {
        if (reservedwordslist.indexOf(versionName.toLowerCase()) != -1) {
          $("#storyboardname-help-block").html(lang["reports.noreservewords"])
          return;
        }
      } else {
        $("#storyboardname-help-block").html('')
      }
		if (foldername === "") {
      foldername = "Default"
    }
      var requestbody = new FormData();
      requestbody.set("reportId", that.props.reportId);
      requestbody.set("modelId", that.props.datamodelId);
      requestbody.set("versionName", versionName);
      requestbody.set("foldername", foldername);
      requestbody.set("folderdesc", folderdesc);
      SendRequest({
        url: "versionreport",
        body: requestbody,
        sucessFunction: (response) => {
          if (response.status == 'success') {
            $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
            $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Success');
            $('.birdmessage-info').empty().text(getMessage('BirdSuccess67'));
            $("#savereportgraph").removeClass("in");
          } else if (response.hasOwnProperty('error')) {
            $(".spinner").hide();
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('hide');
            $('.birdmessage h2').empty().append('Error : ');
            $('.birdmessage h2').append('<span class="errorcode">');
            $('.birdmessage .errorcode').empty().append(response.errorCode);
            $('.birdmessage-info').empty().text(response.error);
            $('.details').addClass('show');
            $('.messagedetails xmp').empty().append(response.errorTrace)
          }
        },
        rejectFunction: this.reportDataError,
        headers: requestHeaders
      });
    }
  }
  render() {
    return (
      <div className="modal fade in" id="savereportgraph" tabIndex="-1" role="dialog" aria-labelledby="savereportgraph" aria-hidden="true" style={{ display: "block !important" }}>
        <div className="modal-backdrop fade in" id="modal-backdrop" onClick={this.cancelmodal}></div>
        <div className="modal-dialog modal-lg modal-save-report modal-dialog-lg-center modal-dialog-center" style={{ zIndex: "1050" }}>
          <div className="modal-content">
            <div className="modal-header boc-modal-hf-padding">
              <button type="button" className="close white" onClick={this.props.cancelmodal} id="singlestoryboardclose" data-dismiss="modal" aria-hidden="true">&times;</button>
              <h4 className="modal-title"><i className="fa fa-floppy-o"></i> {lang["reports.saveversion"]}</h4>
            </div>
            <div className="modal-body scrollset">
              <div className="container-fluid">
                <div className="row filtercontent">
                  <fieldset className="savereport-border">
                    <legend className="savereport-border">{lang["reports.versiondetails"]}</legend>
                    <div className="control-group ">
                      <div className="validationgroup form-group" style={{ marginTop: "-2px" }}>
                        <span className="required right legend"></span>
                        <label className="subhead">{lang["reports.name"]} </label>
                        <input type="name" id="versionreportname" name="versionstoryboardname" defaultValue={this.props.versionname} className="form-control input-sm" placeholder={lang["reports.versionname"]}/>
                        <span className="help-block marg-left-10" id="storyboardname-help-block"></span>
                      </div>
                      <div className="validationgroup form-group">
                        <label className="subhead" for="versionstoryboardfolder">{lang["reports.folder"]} </label>
                        <input type="folder" className="form-control input-sm"  id="versionstoryboardfolder" name="versionstoryboardfolder" list="folders" placeholder={lang["reports.slctfolder"]} />
                        <span style={{ color: "#ff0000" }} id="errmsg"></span>
                        <span className="help-block hidden marg-left-10"></span>
                        <datalist id="folders">
                        </datalist>
                      </div>
                      <div className="validationgroup form-group ">
                        <label className="subhead">{lang["reports.description"]}</label>
                        <textarea id="versionstoryboarddesc" placeholder={lang["reports.placeholdescription"]} name="versionstoryboarddesc" className="form-control input-sm resize-vertical">{this.props.reportDesc}</textarea>
                        <span className="help-block hidden"></span>
                      </div>
                    </div>
                  </fieldset>
                </div>
                <button
                  toggle="View More..."
                  className="btn btn-bird-group-transaprent viewallstoryboardreports  pull-right"
                  id="dashviewallfavoritesreports" onClick={this.versionsviewclick}
                >
                 {lang["reports.versionlist"]}
                              </button>
              </div>
              <div className="data-items-versioncontainer" ></div>

            </div>
            <div className="modal-footer boc-modal-hf-padding">
              <button type="button" className="btn btn-bird" onClick={this.cancelmodal} id="btn-birdsingle"><i className="fa fa-times"></i> {lang["reports.cancelbtn"]}</button>
              <button type="button" className="btn btn-bird savesinglereport" id="savesinglereportes" onClick={this.versionReport}><i className="fa fa-check"></i> {lang["reports.applybtn"]}</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  versionsviewclick = (e) => {
    $("body").append("<div id='datatablepopupmodal'></div>");
    ReactDOM.unmountComponentAtNode(document.getElementById("datatablepopupmodal"));
    ReactDOM.render(<VersionListPopup />, document.getElementById("datatablepopupmodal"));
    ReactDOM.render(<VersionsListView url={this.props.url} urltype="singlereport" reportId={this.props.reportId} from="reports"/>, document.getElementById("pivotdataview"));

  }
  cancelmodal = () => {
    if (document.getElementById("updatesinglereportdiv") != null) {
      ReactDOM.unmountComponentAtNode(document.getElementById("updatesinglereportdiv"));
    }
  }
}