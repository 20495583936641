import React from "react";
import ReactDOM from 'react-dom'
import PubSub from 'pubsub-js'
import _ from 'lodash';
import { getMessage } from '../js/helpers/utils/BirdMessage';
import * as ChartInfo from "../js/helpers/utils/chartinfo";
import * as TabInfo from "../js/helpers/utils/tabinfo";
import SendRequest from '../SendRequest';
import ViewStoryBookReportView from "../ViewStoryboard/Storyboard.jsx"
import ViewStorybookTemplate from './viewstorybook.jsx'
import ViewStorybookHeaderView from './viewstorybookheaderview'
import ViewStoryBookNewTabModel from './viewstorybooknewtabmodel'
import MLSavedReportView from "../ViewStoryboard/viewMLStoryboard/viewsavedmlmodel";
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;
export default class StrorybookView extends React.Component {
	constructor(props) {
		$(".spinner").show();
		super(props);
		this.state = {
			storyBookId: props.storyBookId,
			savedcollection: props.savedcollection,
			features: props.features

		};
		this.tabclickEvee = PubSub.subscribe('tabclick', this.tabclickEve.bind(this));
		this.deletestorybooktabEvee = PubSub.subscribe('deletestorybooktab', this.deletestorybooktabEve.bind(this));
		this.editstorybooktabEvee = PubSub.subscribe('editstorybooktab', this.editstorybooktabEve.bind(this));
		//$("#main").empty();
		TabInfo.functions.resetTabInfo();
		this.render();
		//Control Footer UI Based on Parameters in URL
		var that = this
		that.state.displayFooter = true;
		var urlparameters = window.location.search.replace("?", "").split("&");
		$.each(urlparameters, function (i, u) {
			var parameter = u.split("=");
			if (parameter[0].toLowerCase() == "bottomfooter" && parameter[1].toLowerCase() == "false") {
				that.state.displayFooter = false;
			}
		});
		if (this.state.storyBookId == undefined || this.state.storyBookId == null || this.state.storyBookId == 0) {
			this.viewStoryBookNewTabModel = new ViewStoryBookNewTabModel({
				props: this.props
			});
		}

	}
	render() {
		$(".spinner").show();
		var that = this;
		var emptyArray = [];
		ChartInfo.functions.updateTempStoryBookDetails(emptyArray);
		if (this.state.storyBookId != undefined && this.state.storyBookId != null && this.state.storyBookId != 0) {
			var savedData = this.state.savedcollection == undefined ? this.state.savedcollection : this.state.savedcollection;
			if (savedData != undefined && savedData != null && savedData[0] != undefined && savedData[0].visualization_details != undefined) {
				var tabs = JSON.parse(savedData[0].visualization_details);
				ChartInfo.functions.updateTempStoryBookDetails(tabs);

				var today = new Date();
				var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
				dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
				$("#main").append("<div className='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
				ReactDOM.render(<ViewStorybookTemplate editstorybooktab={this.editstorybooktab} url={this.props.url} deletestorybooktab={this.deletestorybooktab} tabclick={this.tabclick} displayFooter={this.state.displayFooter} reportname={"Storybook"} tabslist={tabs} />, document.getElementById(dynamicdiv));

				var today = new Date();
				var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
				dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
				$("#reportheader").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
				this.viewStorybookHeaderView = new ViewStorybookHeaderView({
					dynamicdiv: dynamicdiv,
					title: savedData[0].storybook_name,
					isSaved: true,
					savedcollection: that.state.savedcollection
				});
				var path = '<li><a class="navDashboard" href="#visualization"><i class="fa fa-bar-chart fa-solid"></i> '+lang["storybook.visualization"]+'</a></li><li><a href="javascript:void(0)">'+lang["storybook.title"]+'</i></a></li><li><a><i class="fa fa fa-book"></i> ' + savedData[0].storybook_name + '</a></li>'
				$(".navbar.navbar-bird ul.nav-path").html(path);

				if (tabs != undefined && tabs.length > 0) {
					$(".spinner").addClass("forceshow");
					var reportid = tabs[0].reportId;
					var dataModelId = tabs[0].dataModelId
					var tabid = tabs[0].id;
					var reportType = tabs[0].reportType;
					TabInfo.functions.setCurrentTabNumber(tabid);
					$("#newtabcontainerid .tabheadli").removeClass("active");
					$('a#storybooktab' + tabid).parent().addClass("active");
					$(".tab-pane.storybookpagecontent").removeClass("active");
					$('#storybooktabcontent' + tabid).addClass("active");

					if (reportType != undefined && reportType == "ml_storyboard") {
						ReactDOM.render(<MLSavedReportView reportId={reportid}
							isDirect={0}
							features={that.state.features}
							datamodelId={dataModelId}
							el={'storybooktabcontent' + tabid}
							isFromStoryBook={true}
							tabid={tabid}
						/>, document.getElementById('storybooktabcontent' + tabid));
						$('#storybooktabcontent' + parseInt(tabid) + ' .quickfiltercontainer').attr("tab-id", tabid);
						$(".tab-pane.storybookpagecontent .rightfilter").attr('id', "filteraccordiontemp");
						$('#storybooktabcontent' + parseInt(tabid) + '.tab-pane.storybookpagecontent.active .tab-filters-container>.rightfilter').attr('id', "filteraccordion");

					} else if (reportType != undefined && reportType == "smartinsight_storyboard") {

						ReactDOM.render(<ViewStoryBookReportView
							reportId={reportid}
							isDirect={0}
							features={that.state.features}
							el={'storybooktabcontent' + tabid}
							isFromStoryBook={true}
							tabid={tabid}
							isFromSavedSmartInsight={true}
							isFromSmartInsigt={true}
							datamodelId={dataModelId}
						/>, document.getElementById('storybooktabcontent' + tabid));

						$('#storybooktabcontent' + parseInt(tabid) + ' .quickfiltercontainer').attr("tab-id", tabid);
						$(".tab-pane.storybookpagecontent .rightfilter").attr('id', "filteraccordiontemp");
						$('#storybooktabcontent' + parseInt(tabid) + '.tab-pane.storybookpagecontent.active .tab-filters-container>.rightfilter').attr('id', "filteraccordion");
					} else {
						ReactDOM.render(<ViewStoryBookReportView
							reportId={reportid}
							isDirect={0}
							features={that.state.features}
							el={'storybooktabcontent' + tabid}
							isFromStoryBook={true}
							tabid={tabid} />, document.getElementById('storybooktabcontent' + tabid));
						//	TabInfo.functions.updateStoryBookViews(tabid,viewStoryBookReportView);
						$('#storybooktabcontent' + parseInt(tabid) + ' .quickfiltercontainer').attr("tab-id", tabid);
					}
					that.closeSpinner();
				}

			} else {
				var tabs = [];
				var today = new Date();
				var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
				dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
				$("#main").append("<div className='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");
				ReactDOM.render(<ViewStorybookTemplate editstorybooktab={this.editstorybooktab} url={this.props.url} deletestorybooktab={this.deletestorybooktab} tabclick={this.tabclick} displayFooter={this.state.displayFooter} reportname={"Storybook"} tabslist={tabs} />, document.getElementById(dynamicdiv));

				var today = new Date();
				var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
				dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
				$("#reportheader").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
				this.viewStorybookHeaderView = new ViewStorybookHeaderView({
					dynamicdiv: dynamicdiv,
					title: "Storybook",
					isSaved: false,
					savedcollection: that.state.savedcollection
				});
			}

		} else {
			var tabs = [];
			var today = new Date();
			var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
			dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
			$("#main").append("<div className='" + dynamicdiv + "' id='" + dynamicdiv + "'></div>");

			ReactDOM.render(<ViewStorybookTemplate editstorybooktab={this.editstorybooktab} url={this.props.url} deletestorybooktab={this.deletestorybooktab} tabclick={this.tabclick} displayFooter={this.state.displayFooter} reportname={"Storybook"} tabslist={tabs} />, document.getElementById(dynamicdiv));

			var today = new Date();
			var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
			dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
			$("#reportheader").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
			this.viewStorybookHeaderView = new ViewStorybookHeaderView({
				dynamicdiv: dynamicdiv,
				title: "Storybook",
				isSaved: false,
				savedcollection: that.state.savedcollection
			});
		}
		$(".storybooktabs>#reportcontainertabs ul.nav-tabs").sortable().disableSelection();
		setTimeout(function(){$(".spinner").hide();},1500)
		
		return (<div></div>);
	}
	closeSpinner() {
		//$(".spinner").addClass("forceshow");
		var loadedTiles = $(".storybookpagecontent.active .maxanim10").length;
		if (loadedTiles > 0) {
			var activeCharts = $(".storybookpagecontent.active .maxanim10:empty").length;
			if (activeCharts > 0) {
				function executecloseSpinner() {
					var emptycharts = $(".storybookpagecontent.active .maxanim10:empty").length;
					if (emptycharts == 0) {
						setTimeout(function () {
							$(".spinner").removeClass("forceshow");
						}, 1500);
					} else {
						setTimeout(function () {
							executecloseSpinner();
						}, 2000);
					}
				}
				setTimeout(function () { executecloseSpinner(); }, 2000);
			} else {
				$(".spinner").removeClass("forceshow");
			}
		} else {
			var that = this;
			setTimeout(function () { that.closeSpinner(); }, 2000);
			setTimeout(function () {
				if (!loadedTiles > 0) {
					$(".spinner").removeClass("forceshow");
				}
			}, 4000);
		}
	}
	tabclickEve(msg, data) {
		this.tabclick(data);
	}
	tabclick = (e) => {
		//e.preventDefault();
		var that = this;
		var tabid = e.currentTarget.getAttribute("tab-id");
		$(".tab-pane.storybookpagecontent").removeClass("active");
		$('#storybooktabcontent' + tabid).addClass("active");
		$(".tab-pane.storybookpagecontent").find("#filtermodal").remove();

		//For Re-size
		if ($("#storybooktabcontent" + tabid + ":empty").length == 1) {
			$("#storybooktabcontent" + tabid + "").addClass("reportloading");
		}else{
			$("#storybooktabcontent" + tabid + "").removeClass("reportloading").addClass("reportloaded");
		}

		//getReportInfo
		if (tabid != undefined && tabid != null) {
			TabInfo.functions.setCurrentTabNumber(tabid);
			var tabData = ChartInfo.functions.getStoryBookTabDetails(tabid);
			var reportid = tabData.reportId;
			if ($("#storybooktabcontent" + tabid + ":empty").length == 1) {
				if (tabData != undefined) {
					$(".spinner").addClass("forceshow");
					var dataModelId = tabData.dataModelId
					var tabid = tabData.id;
					var reportType = tabData.reportType;

					$("#newtabcontainerid .tabheadli").removeClass("active");
					$('a#storybooktab' + tabid).parent().addClass("active");
					$(".tab-pane.storybookpagecontent").removeClass("active");
					$('#storybooktabcontent' + tabid).addClass("active");
					$(".tab-pane.storybookpagecontent .rightfilter").attr('id', "filteraccordiontemp");

					if (reportType != undefined && reportType == "ml_storyboard") {
						ReactDOM.render(<MLSavedReportView reportId={reportid}
							isDirect={0}
							features={that.state.features}
							datamodelId={dataModelId}
							el={'storybooktabcontent' + tabid}
							isFromStoryBook={true}
							tabid={tabid}
						/>, document.getElementById('storybooktabcontent' + tabid));
						$('#storybooktabcontent' + parseInt(tabid) + ' .quickfiltercontainer').attr("tab-id", tabid);
						$(".tab-pane.storybookpagecontent .rightfilter").attr('id', "filteraccordiontemp");
						$('#storybooktabcontent' + parseInt(tabid) + '.tab-pane.storybookpagecontent.active .tab-filters-container>.rightfilter').attr('id', "filteraccordion");

					} else if (reportType != undefined && reportType == "smartinsight_storyboard") {
						ReactDOM.unmountComponentAtNode(document.getElementById('storybooktabcontent' + tabid));
						ReactDOM.render(<ViewStoryBookReportView
							reportId={reportid}
							isDirect={0}
							features={that.state.features}
							el={'storybooktabcontent' + tabid}
							isFromStoryBook={true}
							isFromSavedSmartInsight={true}
							isFromSmartInsigt={true}
							datamodelId={dataModelId}
							tabid={tabid} />, document.getElementById('storybooktabcontent' + tabid));

						$('#storybooktabcontent' + parseInt(tabid) + ' .quickfiltercontainer').attr("tab-id", tabid);
						$(".tab-pane.storybookpagecontent .rightfilter").attr('id', "filteraccordiontemp");
						$('#storybooktabcontent' + parseInt(tabid) + '.tab-pane.storybookpagecontent.active .tab-filters-container>.rightfilter').attr('id', "filteraccordion");
					} else {
						ReactDOM.unmountComponentAtNode(document.getElementById('storybooktabcontent' + tabid));
						ReactDOM.render(<ViewStoryBookReportView
							reportId={reportid}
							isDirect={0}
							features={that.state.features}
							el={'storybooktabcontent' + tabid}
							isFromStoryBook={true}
							tabid={tabid} />, document.getElementById('storybooktabcontent' + tabid));

						//	TabInfo.functions.updateStoryBookViews(tabid,viewStoryBookReportView);
						$('#storybooktabcontent' + parseInt(tabid) + ' .quickfiltercontainer').attr("tab-id", tabid);
					}
					that.closeSpinner();
				}
			}
			else {
				$(".tab-pane.storybookpagecontent .rightfilter").attr('id', "filteraccordiontemp");
				$('#storybooktabcontent' + parseInt(tabid) + '.tab-pane.storybookpagecontent.active .tab-filters-container>.rightfilter').attr('id', "filteraccordion");
				$('#storybooktabcontent' + parseInt(tabid) + ' .quickfiltercontainer').attr("tab-id", tabid);
				$('#storybooktabcontent' + parseInt(tabid) + '.tab-pane.storybookpagecontent.active .tab-filters-container>.rightfilter .rightfiltersapply').attr('tab-id', tabid)

				//	$("#storybooktabcontent"+tabid+".tab-pane.storybookpagecontent.active .tab-filters-container>.rightfilter").attr('id',"filteraccordion");
				//   $(".tab-pane.storybookpagecontent.active .rightfilter").attr('id',"filteraccordion");
				//	$("#storybooktabcontent"+tabid+".tab-pane.storybookpagecontent.active .tab-filters-container>.rightfilter")
				//var view = TabInfo.functions.getStoryBookViews(tabid);
				//console.log("test");
				// if (view != undefined) {
				// 	view.updateChartInfo(tabid);
				// }
				//	PubSub.publish("updateChartInfo",tabid);
				var isFromSavedSmartInsight = false;
				var isFromSmartInsigt = false;
				var datamodelId = tabData.dataModelId;
				if (tabData != undefined && tabData.reportType != undefined && tabData.reportType == "smartinsight_storyboard") {
					isFromSmartInsigt = true;
					isFromSavedSmartInsight = true;
				}
				var today = new Date();
				var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
				dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
				$('#storybooktabcontent' + tabid).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
				ReactDOM.render(<ViewStoryBookReportView
					reportId={reportid}
					isDirect={0}
					features={that.state.features}
					el={dynamicdiv}
					isFromStoryBook={true}
					isFromTabClick={true}
					isFromSavedSmartInsight={isFromSavedSmartInsight}
					isFromSmartInsigt={isFromSmartInsigt}
					datamodelId={datamodelId}
					tabid={tabid} />, document.getElementById(dynamicdiv));
				//	ReactDOM.unmountComponentAtNode(document.getElementById(dynamicdiv));

				//Resize Summarytables
				if($(".tileview[tab-id="+tabid+"] .maxanim10>.summartytablecontainer").length>0){
					_.each($(".tileview[tab-id="+tabid+"] .maxanim10>.summartytablecontainer"),function(i){
						var $this = i;
						//var chartid = $($this).parent().attr("id").replace("chart","");
						var acd = {};
							acd["element"] = $($this).parent();
							acd["pos"] = "min";
							PubSub.publish('adjustchartheight', acd);
					})
				}
				
			}
		}
	}

	deletestorybooktabEve(msg, data) {
		this.deletestorybooktab(data);
	}

	deletestorybooktab = (e) => {
		var that = this;
		var edata = _.clone(e);
		var tabid = edata.currentTarget.getAttribute("tab-id");
		if (tabid != undefined) {
			$(".deleteyes").unbind("click");
			$(".deleteno").unbind("click");
			$('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
			$('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
			$('.birdmessagebuttons').addClass('show');
			$('.birdmessage-center').addClass("message-top-in");
			$('.details').removeClass('show');
			$('.details').addClass('hide');
			$('.birdmessage h2').empty().append('Warning');
			$('.birdmessage-info').empty().text(getMessage('BirdWarning144'));
			$(".deleteyes").click(function () {
				$('#storybooktabcontent' + parseInt(tabid)).remove();
				$("#newtabcontainerid .tabheadli.active").remove();
				var allTabsDetails = ChartInfo.functions.getTempStoryBookDetails();
				_.each(allTabsDetails, function (index, i) {
					if (index != undefined && index.id == tabid) {
						allTabsDetails.splice(i, 1);
					}
				});
				$("#newtabcontainerid .tabheadli").removeClass("active");
				$(".tab-pane.storybookpagecontent").removeClass("active");
				$(".tab-pane.storybookpagecontent .rightfilter").attr('id', "filteraccordiontemp");
				$('.birdmessage-center').removeClass("message-top-in");
				if (allTabsDetails != undefined && allTabsDetails.length > 0) {
					var tabData = allTabsDetails[0];
					tabid = tabData.id;
					TabInfo.functions.setCurrentTabNumber(tabid);
					$('a#storybooktab' + tabid).parent().addClass("active");
					$('#storybooktabcontent' + tabid).addClass("active");
					$('#storybooktabcontent' + parseInt(tabid) + '.tab-pane.storybookpagecontent.active .tab-filters-container>.rightfilter').attr('id', "filteraccordion");
					edata.currentTarget.setAttribute("tab-id", tabid);
					that.tabclick(edata);
				}
			});
			$(".deleteno").click(function () {
				$('.birdmessage-center').removeClass("message-top-in");
			});
		}
	}
	editstorybooktabEve(msg, data) {
		this.editstorybooktab(data);
	}
	editstorybooktab = (e) => {
		var tabid = e.currentTarget.getAttribute("tab-id");
		var that = this;
		var tabData = ChartInfo.functions.getStoryBookTabDetails(tabid);
		TabInfo.functions.setCurrentTabNumber(tabid);
		that.viewStoryBookNewTabModel = new ViewStoryBookNewTabModel({
			options: that.props,
			tabid: tabid,
			isFromEdit: true,
			tabData: tabData
		});
	}
}
