import React from 'react';
import _ from 'underscore';
import ReactDOM from 'react-dom';
import ConnectionTableItemsDataContainerHtml from './ConnectionTableitemsDatacontainerHtml.jsx';
const $=window.$;

export default class CsvConnection extends React.Component{
    constructor(props){
        super(props);
        this.state={}
        this.connectionJson = JSON.parse(props.connectionjson);
        this.connectionJson.connectionaccessid = props.connectionAccessId;
        this.response=[];
        this.selectedTables = [];
        if(this.connectionJson!==undefined && this.connectionJson.dbType==="log" && (this.connectionJson.uploadFileType==="log_sftp" || this.connectionJson.uploadFileType==="log_ftp")){
            this.connectionJson.fileName=this.connectionJson.ftpfilename;
        }
        if(this.connectionJson!==undefined && this.connectionJson.dbType==="xls" && this.connectionJson.tables!==undefined){
            this.selectedTables=JSON.parse(this.connectionJson.tables);
        }else{
            this.selectedTables.push(this.connectionJson.fileName.replace(".csv","").replace(".txt","").replace(".tsv","").replace(".json","").replace(".pdf","").replace(".log",""));
        }
        this.response["tables"]=this.selectedTables;
    }
    componentDidMount(){
        $("a[href='#next']").parent().removeClass("disabled")
       // $(".spinner").hide()
        ReactDOM.render(
            <ConnectionTableItemsDataContainerHtml
                connectionaccessid={this.props.connectionAccessId}
                connectiontype={this.props.connectionType}
                connectionname={this.props.connectionName}
                scopeitems={[this.response]}
                joinremove={this.props.joinremove}
                editdatahubitem={this.props.editdatahubitem}
                search={this.props.search}
            />,document.getElementById('connections-tables-data-container')
        );
        setTimeout(function(){$(".spinner").hide();},1500);
    }
    render(){
        return(
            <></>
        );
    }
}