import React from 'react';
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;

export default class DatahubModifyEntitiesHtml extends React.Component{
    constructor(props){
        super(props);
        this.state={
            schemaType:props.schemaType,
            isCustomQuery:props.isCustomQuery,
            isStoredProcedure:props.isStoredProcedure,
            reportdata:props.reportdata,
            importtempateflag:props.importtempateflag,
            dataTypeClass:props.dataTypeClass,
            entity:props.entity,
            isSaved:props.isSaved
        }
    }
    changedatatypes=(event)=>{
        var $this=event.currentTarget;
        var id=$this.name;
        var name = $($this).attr("id");
        var tablename=$this.id.replace(/([#;?&,%. +*~\':"!^$%[\]()<=>|\/@])/g, "\\$1");
        if($($this).attr("schemaType")!="mysql" && $($this).attr("schemaType")!="postgres" && $($this).attr("schemaType")!="sqlserver" && $($this).attr("schemaType")!="oracle" && $($this).attr("schemaType")!="xls"&& $($this).attr("schemaType")!="cache"&& $($this).attr("schemaType")!="db2"&& $($this).attr("schemaType")!="vertica"&& $($this).attr("schemaType")!="neo4j"&& $($this).attr("schemaType")!="hsqldb"&& $($this).attr("schemaType")!="snowflake"&& $($this).attr("schemaType")!="presto"&& $($this).attr("schemaType")!="hana"&& $($this).attr("schemaType")!="sybase"){
            if($($this).find("option:selected").attr("value")=="datetime"){
                $(".additionaloptions#"+tablename).not(".datetime#"+tablename).hide();
                $(".datetime#"+tablename).show();
                if($(".csv-form-control[name='datetime'][tablename='"+tablename+"']").val() == "others" ){
                    $(".csvcustomdatefield[name='"+name+"']").show();
                }else{
                    $(".csvcustomdatefield[name='"+name+"']").hide();
                }
            }else if($($this).find("option:selected").attr("value")=="date"){
                $(".additionaloptions#"+tablename).not(".date#"+tablename).hide();
                $(".date#"+tablename).show();
                if($(".csv-form-control[name='date'][tablename='"+tablename+"']").val() == "others" ){
                    $(".csvcustomdatefield[name='"+name+"']").show();
                }else{
                    $(".csvcustomdatefield[name='"+name+"']").hide();
                }
            }else if($($this).find("option:selected").attr("value").toLowerCase()=="string" || $($this).find("option:selected").attr("value").toLowerCase()=="number" || $($this).find("option:selected").attr("value")=="Boolean" || 
                    $($this).find("option:selected").attr("value")=="month" || $($this).find("option:selected").attr("value")=="day"){
                $(".date#"+tablename).hide();
                $(".datetime#"+tablename).hide();
                $(".csvcustomdatefield[name='"+name+"']").hide(); 
                $(".customdate-others[name='"+tablename+"']").hide();
                $(".customdate-error").addClass("hide");
                $(".csvdateformatsdroppdown.date[id='"+name+"'] option[value='others']").prop("selected", false);
            }else {
                $(".additionaloptions#"+tablename).hide();
                $(".customdate-others[name='"+tablename+"']").hide();
                $(".csvdateformatsdroppdown.date[id='"+name+"'] option[value='others']").prop("selected", false);
            }
        }
    }
    showCustomFieldForDate=(e)=>{
        var $this= e.currentTarget;
        var name = $($this).attr("id");
        if($($this).find("option:selected").val()=="others"){
            $(".csvcustomdatefield[name='"+name+"']").show();
            $(".customdate-others[name='"+name+"']").show();
        }else{
            $(".csvcustomdatefield[name='"+name+"']").hide();
            $(".customdate-others[name='"+name+"']").hide();
        }
    }
    render(){
        return(
            <React.Fragment> 
{/* <!-- datahubmodifyentitesview.js --> */}
<div className="table-responsive">
<table className="table custom">
	<thead>
		<tr>	
		    {(this.state.schemaType != "websocket" && this.state.schemaType!="eventhub" && this.state.schemaType!="kafka" && this.state.schemaType!="rabbitmq" && !this.state.isCustomQuery && !this.state.isStoredProcedure && this.state.schemaType != "fuse")? (
                <th>{lang["datahub.entityname"]}</th>
            ):null}
			<th>{lang["datahub.columnname"]}</th>
			<th class="hide">{lang["datahub.colmndisply"]}</th>
			<th>{lang["datahub.datatype"]}</th>
		</tr>
	</thead>
	<tbody>
        {this.state.reportdata.length>=0?this.state.reportdata.map((data,index)=>{
            let isDate=false;
            let display="none";
            let columnInput=<></>;
            if(data.customField){
                columnInput=<td class="hide">
                    <input type="text" id={data.columnName} value={data.columnDisplayName} tablealiasname={data.tableAliasName} tablename = {data.tableName} columnname={data.columnName} orginalcolumnname={data.orginalColumnName} className="csv-form-control display-col tabledisplayname disabled" isoldcustomfield={true} />
                    <span name={data.columnName} id={data.columnName} className="error-block pull-left csvdisplayname-error hide">{lang["datahub.colspclcharact"]}</span>
                    <span className="displayrename-error pull-left hide">{lang["datahub.colnameexists"]}</span>
                </td>
         }else if(this.state.importtempateflag){
            columnInput= <td class="hide"><input type="text" id={data.columnName} value={data.columnName} isPartitionKey={data.isPartitionKey} tablealiasname={data.tableAliasName} tablename ={data.tableName} columnname={data.columnName} orginalcolumnname={data.orginalColumnName} className="csv-form-control display-col tabledisplayname disabled" />
                <span name={data.columnName} id={data.columnName} className="error-block pull-left csvdisplayname-error hide">{lang["datahub.colspclcharact"]}</span>
                <span name={data.columnName} className="displayrename-error pull-left  hide">{lang["datahub.colnameexists"]}</span>
                </td>
        }else{
            columnInput=  <td class="hide">
                    <input type="text" id={data.columnName} defaultValue={data.columnDisplayName} isPartitionKey={data.isPartitionKey} tablealiasname={data.tableAliasName} tablename = {data.tableName} columnname={data.columnName} orginalcolumnname={data.orginalColumnName} className="csv-form-control display-col tabledisplayname disabled" />
                <span name={data.columnName} id={data.columnName} className="error-block pull-left csvdisplayname-error hide">{lang["datahub.colspclcharact"]}</span>
                <span name={data.columnName}  className="displayrename-error pull-left  hide">{lang["datahub.colnameexists"]}</span>
                <span style={{color: "#FF0000", float: "right",fontWeight: 400}} name={data.columnName} id={data.columnName} className="error-block pull-left csvdisplaynameid-error hide">{lang["datahub.idnotalow"]}</span>
                </td>
        }
        var dataType=data.tableDisplayType;
        var tableDataType=<></>;
        if(dataType =='string'){ 
              tableDataType=<><option value="string" selected>{lang["datahub.strngtype"]}</option>
                                <option value="date">{lang["datahub.datetype"]} </option>
                                <option value="number">{lang["datahub.numtype"]}</option>
                            </>
        }else if(dataType==="date"){
            isDate = true;
            if(this.state.importtempateflag){isDate = true;}
            tableDataType=  <>
                                <option value="date" selected>{lang["datahub.datetype"]}</option>
                                <option value="string">{lang["datahub.strngtype"]}</option>
                                <option value="number">{lang["datahub.numtype"]}</option>
                            </>;
        }else{
            tableDataType=<>
                            <option value="date">{lang["datahub.datetype"]} </option>
                            <option value="string">{lang["datahub.strngtype"]}</option>
                            <option value="number" selected>{lang["datahub.numtype"]}</option></>;
        }
            return(
                <tr key={index}>
                    {(this.state.schemaType != "websocket"&& this.state.schemaType!="eventhub"&& this.state.schemaType!="kafka"&& this.state.schemaType!="rabbitmq"&&
                    !this.state.isCustomQuery &&!this.state.isStoredProcedure && this.state.schemaType != "fuse" && this.state.schemaType != "webdataconnector")?(
                    <td>{data.tablename}</td>
                    ):null}
                    {(this.state.schemaType==="webdataconnector")? (
                        <td>{this.state.entity}</td>
                    ):null}
                    <td>{data.orginalColumnName}</td>
                    {columnInput}
                    <td>
                        <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 isDirectDisabled nopadding">
                            {(this.state.schemaType!=="fuse")? (
                            <select onChange={this.changedatatypes} className={`csv-form-control datecontroller ${this.state.isSaved?'disabled':''} ${this.state.dataTypeClass} ${(this.state.schemaType!= undefined && this.state.schemaType=='birdinmemory') && 'disabled'}`} id={`${data.columnName}`} name={`${data.columnName}`} schemaType={`${this.state.schemaType}`}>
                                {tableDataType}
                            </select> 
                            ):(
                                <select onChange={this.changedatatypes} className={`csv-form-control datecontroller ${this.state.dataTypeClass} disabled`} id={`${data.columnName}`} name={`${data.columnName}`} schemaType={`${this.state.schemaType}`}>
					        {tableDataType}
				            </select>
                            )}
                        </div>
                        <div onChange={this.showCustomFieldForDate} className="additionaloptions csvdateformatsdroppdown date col-lg-3 col-md-12 col-sm-12 col-xs-12" id={data.columnName} style={{display:(isDate && this.state.dataTypeClass == "datecontroller_streaming")? "show":"none"}}>
				<select className="csv-form-control" name="date">
					<option value="mdy" selected>{lang["Datahub.d/m/y"]}</option>
					<option value="mdy">{lang["Datahub.m/d/y"]}</option>
					<option value="mdy">{lang["Datahub.y/m/d"]}</option>
					<option value="mdy">{lang["Datahub.d-m-y"]}</option>
					<option value="mdy">{lang["Datahub.m-d-y"]}</option>
					<option value="mdy">{lang["Datahub.y-m-d"]}</option>
					<option value="mdy">{lang["Datahub.dd/MM/yyyy"]}</option>
					<option value="mdy">{lang["Datahub.MM/dd/yyyy"]}</option>
					<option value="ymd">{lang["Datahub.yyyy/MM/dd"]}</option>
					<option value="mdy">{lang["Datahub.dd-MM-yyyy"]}</option>
					<option value="mdy">{lang["Datahub.MM-dd-yyyy"]}</option>
					<option value="ymd">{lang["Datahub.yyyy-MM-dd"]}</option>
					<option value="dmy">{lang["Datahub.dd-MM-yy"]}</option>
					<option value="mdy">{lang["Datahub.dd/MM/yy"]}</option>
					<option value="mdy">{lang["Datahub.d/M/yy"]}</option>
					<option value="mdy">{lang["Datahub.yyyy/MM/dd"]}</option>
					<option value="mdy">{lang["Datahub.dd/MM/yyyy"]}</option>
					<option value="mdy">{lang["Datahub.MM/dd/yyyy"]}</option>
					<option value="others">{lang["datahub.others"]}</option>
				</select>
			</div>
            <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
				<input type="text" name={data.columnName} className="csv-form-control customdate-others" style={{display: "none"}} />
			</div>
			<span id={data.columnName} className="error-block customdate-error hide " style={{color: "red"}}>{lang["datahub.curntformat"]}</span>
                    </td>
                </tr>
            );
        }):<React.Fragment/>}
	</tbody>
</table>
</div>
{/* <!--\ datahubmodifyentitesview.js --> */}
            </React.Fragment>
        );
    }
}