import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'underscore';
import PubSub from 'pubsub-js'
import * as ModalProperties from '../js/helpers/utils/modalproperties';
import SendRequest from '../SendRequest';
import * as ChartInfo from '../js/helpers/utils/chartinfo';
import { getMessage } from '../js/helpers/utils/BirdMessage';
import WorkspaceCustomfieldModal from './WorkspaceCustomfieldModal.jsx';
import WorkspaceCustomfieldsPreview from './WorkspaceCustomfieldsPreview';
const $ = window.$;

export default class WorkspaceCustomfields extends React.Component {
    constructor(props) {
        super(props);
        this.columnDisplayNameMap = {};
        this.columnDisplayMaps = {};
        this.columnDisplayTypeMap = {};
        this.columnDisplaynameArray = [];
        this.stringfunctionsArray = [];
        this.numberfunctionsArray = [];
        this.datefunctionsArray = [];
        this.logicalfunctionsArray = [];
        this.formulaTemp = null;
        this.dimensions = this.props.dimensions;
        this.isCustomQuery = this.props.isCustomQuery;
        this.entityName = this.props.entityName;
        this.data_parent = this.props.data_parent;
        this.entityId = this.props.entityId;
        this.workSpaceEntityMetaDataCollection = this.props.workSpaceEntityMetaDataCollection;
    }
    componentDidMount() {
        this.updateColumnDisplayNameMap();
        this.customFunctions();
        this.functionsArray = this.numberfunctionsArray;
        if (!this.props.isDeleteCustomFiled) {
            var that = this;
            var fieldname;
            var userFormula;
            if (that.props.editField != undefined) {
                var customFields = that.workSpaceEntityMetaDataCollection.getEntityCustomFeilds(that.entityName, that.data_parent);
                _.each(customFields, function (value, i) {
                    if (that.props.editField != undefined && that.props.editField == value.name) {
                        fieldname = value.name;
                        userFormula = value.userFormula;
                    }
                });
            }
            var functionsArray = [];
            ReactDOM.render(
                <WorkspaceCustomfieldModal
                    getFormula={this.getFormula}
                    numberfunctionsArray={this.numberfunctionsArray}
                    stringfunctionsArray={this.stringfunctionsArray}
                    datefunctionsArray={this.datefunctionsArray}
                    logicalfunctionsArray={this.logicalfunctionsArray}
                    textAreaAutoComplete={this.textAreaAutoComplete}
                    testformula={this.testformula}
                    clearQueryArea={this.clearQueryArea}
                    createCustomField={this.createCustomField}
                />,
                document.querySelector('.append_filtercustomfield')
            );
            $("#customfunctionstable").bootstrapTable({ height: 220 });
            $(".filtercustomfield").modal("show");
            ModalProperties.resize();
        }
        if (this.props.isEditCustomFiled) {
            var that = this;
            setTimeout(function (e) {
                that.addData();
            }, 800);
        }
        if (this.props.isDeleteCustomFiled) {
            $(".deleteyes").unbind("click");
            $(".deleteno").unbind("click");
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').addClass('show');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(getMessage('BirdWarning126'));
            $(".deleteyes").click(() => {
                this.deleteData();
                $('.birdmessage-center').removeClass("message-top-in");
            });
            $(".deleteno").click(function () {
                $('.birdmessage-center').removeClass("message-top-in");
            });
        }
        this.textAreaAutoComplete();
    }
    getFormula = (e) => {
        e.preventDefault();
        var $this = e.currentTarget;
        var datatype = $($this).val();

        if (datatype == "number") {
            this.functionsArray = this.numberfunctionsArray;
        } else if (datatype == "string") {
            this.functionsArray = this.stringfunctionsArray;
        } else if (datatype == "date") {
            this.functionsArray = this.datefunctionsArray;
        } else {
            this.functionsArray = this.logicalfunctionsArray;
        }
        var that = this;
        $("#customfunctionstable").bootstrapTable('load', this.functionsArray);
    }
    customFunctions = () => {
        this.stringfunctionsArray = [];
        this.numberfunctionsArray = [];
        this.datefunctionsArray = [];
        this.logicalfunctionsArray = [];
        //String
        var functionObj = {};
        functionObj["type"] = "String";
        functionObj["function"] = "concat(field_name, field_name)";
        functionObj["hint"] = "(ex:concat([title],[name]) NOTE: For non-string fields use toString(), ex:concat(toString(field_name), ...)))";
        this.stringfunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "String";
        functionObj["function"] = "empty(field_name)";
        functionObj["hint"] = "(ex:empty([name]))";
        this.stringfunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "String";
        functionObj["function"] = "notEmpty(field_name)";
        functionObj["hint"] = "(ex:notEmpty([name]))";
        this.stringfunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "String";
        functionObj["function"] = "length(field_name)";
        functionObj["hint"] = "(ex:length([name]))";
        this.stringfunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "String";
        functionObj["function"] = "reverse(field_name)";
        functionObj["hint"] = "(ex:reverse([name]))";
        this.stringfunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "String";
        functionObj["function"] = "substring(field_name, int start, int end)";
        functionObj["hint"] = "(ex:substring([name],1,7))";
        this.stringfunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "String";
        functionObj["function"] = "appendtrailingcharifabsent(field_name, 'ch')";
        functionObj["hint"] = "(ex:appendTrailingCharIfAbsent([name],'C'))";
        this.stringfunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "String";
        functionObj["function"] = "position(field_name, 'string/ch')";
        functionObj["hint"] = "(ex:position([name],'DON'))";
        this.stringfunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "String";
        functionObj["function"] = "match(field_name, 'string/ch')";
        functionObj["hint"] = "(ex:match([name],'DON'))";
        this.stringfunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "String";
        functionObj["function"] = "lower(field_name)";
        functionObj["hint"] = "(ex:lower([name]))";
        this.stringfunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "String";
        functionObj["function"] = "upper(field_name)";
        functionObj["hint"] = "(ex:upper([name]))";
        this.stringfunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "String";
        functionObj["function"] = "like(field_name, 'string/ch')";
        functionObj["hint"] = "(ex:like([name],'DON'))";
        this.stringfunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "String";
        functionObj["function"] = "notLike(field_name, 'string/ch')";
        functionObj["hint"] = "(ex:notLike([name],'DON'))";
        this.stringfunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "String";
        functionObj["function"] = "replaceAll(field_name, 'string/ch','Replacement')";
        functionObj["hint"] = "(ex:replaceAll([name],'DON','HERO'))";
        this.stringfunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "String";
        functionObj["function"] = "replaceOne((field_name, 'string/ch','Replacement'))";
        functionObj["hint"] = "(ex:replaceOne([name],'DON','HERO'))";
        this.stringfunctionsArray.push(functionObj);
        //numbers
        var functionObj = {};
        functionObj["type"] = "Number";
        functionObj["function"] = "e()";
        functionObj["hint"] = "(ex:e())";
        this.numberfunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Number";
        functionObj["function"] = "pi()";
        functionObj["hint"] = "(ex:pi())";
        this.numberfunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Number";
        functionObj["function"] = "exp(field_name)";
        functionObj["hint"] = "(ex:exp([rental_rate]))";
        this.numberfunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Number";
        functionObj["function"] = "log(field_name)";
        functionObj["hint"] = "(ex:log([rental_rate]))";
        this.numberfunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Number";
        functionObj["function"] = "exp2(field_name)";
        functionObj["hint"] = "(ex:exp2([rental_rate]))";
        this.numberfunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Number";
        functionObj["function"] = "log2(field_name)";
        functionObj["hint"] = "(ex:log2([rental_rate]))";
        this.numberfunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Number";
        functionObj["function"] = "exp10(field_name)";
        functionObj["hint"] = "(ex:exp10([rental_rate]))";
        this.numberfunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Number";
        functionObj["function"] = "log10(field_name)";
        functionObj["hint"] = "(ex:log10([rental_rate]))";
        this.numberfunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Number";
        functionObj["function"] = "sqrt(field_name)";
        functionObj["hint"] = "(ex:sqrt([rental_rate]))";
        this.numberfunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Number";
        functionObj["function"] = "cbrt(field_name)";
        functionObj["hint"] = "(ex:([rental_rate]))";
        this.numberfunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Number";
        functionObj["function"] = "erf(field_name)";
        functionObj["hint"] = "(ex:erf([rental_rate]))";
        this.numberfunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Number";
        functionObj["function"] = "erfc(field_name)";
        functionObj["hint"] = "(ex:erfc([rental_rate]))";
        this.numberfunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Number";
        functionObj["function"] = "sin(field_name)";
        functionObj["hint"] = "(ex:sin([rental_rate]))";
        this.numberfunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Number";
        functionObj["function"] = "cos(field_name)";
        functionObj["hint"] = "(ex:cos([rental_rate]))";
        this.numberfunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Number";
        functionObj["function"] = "tan(field_name)";
        functionObj["hint"] = "(ex:tan([rental_rate]))";
        this.numberfunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Number";
        functionObj["function"] = "asin(field_name)";
        functionObj["hint"] = "(ex:asin([rental_rate]))";
        this.numberfunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Number";
        functionObj["function"] = "acos(field_name)";
        functionObj["hint"] = "(ex:acos([rental_rate]))";
        this.numberfunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Number";
        functionObj["function"] = "atan(field_name)";
        functionObj["hint"] = "(ex:atan([rental_rate]))";
        this.numberfunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Number";
        functionObj["function"] = "pow(field_name, Number)";
        functionObj["hint"] = "(ex: pow([rental_rate] ,2))";
        this.numberfunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Number";
        functionObj["function"] = "abs(field_name)";
        functionObj["hint"] = "(ex:abs([rental_rate]))";
        this.numberfunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Number";
        functionObj["function"] = "round(field_name)";
        functionObj["hint"] = "(ex:round([rental_rate]))";
        this.numberfunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Number";
        functionObj["function"] = "ceil(field_name)";
        functionObj["hint"] = "(ex:ceil([rental_rate]))";
        this.numberfunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Number";
        functionObj["function"] = "floor(field_name)";
        functionObj["hint"] = "(ex:floor([rental_rate]))";
        this.numberfunctionsArray.push(functionObj);
        //Date
        var functionObj = {};
        functionObj["type"] = "Date";
        functionObj["function"] = "toYear(field_name)";
        functionObj["hint"] = "(ex: toYear([payment_date])";
        this.datefunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Date";
        functionObj["function"] = "toDayOfMonth(field_name)";
        functionObj["hint"] = "(ex: toDayOfMonth([rental_rate])";
        this.datefunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Date";
        functionObj["function"] = "toMonth(field_name)";
        functionObj["hint"] = "(ex: toMonth([rental_rate])";
        this.datefunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Date";
        functionObj["function"] = "now()";
        functionObj["hint"] = "Example:Return current Date and Time";
        this.datefunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Date";
        functionObj["function"] = "today()";
        functionObj["hint"] = "Example:Return current Date and Time";
        this.datefunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Date";
        functionObj["function"] = "toDayOfWeek(field_name)";
        functionObj["hint"] = "(ex: toDayOfWeek([rental_rate])";
        this.datefunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Date";
        functionObj["function"] = "toHour(field_name)";
        functionObj["hint"] = "(ex: toHour([rental_rate])";
        this.datefunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Date";
        functionObj["function"] = "toMinute(field_name)";
        functionObj["hint"] = "(ex: toMinute([rental_rate])";
        this.datefunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Date";
        functionObj["function"] = "tosecond(field_name)";
        functionObj["hint"] = "(ex: tosecond([rental_rate])";
        this.datefunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Date";
        functionObj["function"] = "yesterday(field_name)";
        functionObj["hint"] = "Example:Return yesterday Date";
        this.datefunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Date";
        functionObj["function"] = "toMonday(field_name)";
        functionObj["hint"] = "(ex:toMonday([payment_date]))";
        this.datefunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Date";
        functionObj["function"] = "tostartofmonth(field_name)";
        functionObj["hint"] = "(ex:toStartOfMonth([payment_date]))";
        this.datefunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Date";
        functionObj["function"] = "tostartofquarter(field_name)";
        functionObj["hint"] = "(ex:toStartOfQuarter([payment_date]))";
        this.datefunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Date";
        functionObj["function"] = "toStartOfYear(field_name)";
        functionObj["hint"] = "(ex:toStartOfYear([payment_date]))";
        this.datefunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Date";
        functionObj["function"] = "toStartOfMinute(field_name)";
        functionObj["hint"] = "(ex:toStartOfMinute([payment_date]))";
        this.datefunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Date";
        functionObj["function"] = "toStartOfFiveMinute(field_name)";
        functionObj["hint"] = "(ex:toStartOfFiveMinute([payment_date]))";
        this.datefunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Date";
        functionObj["function"] = "toStartOfHour(field_name)";
        functionObj["hint"] = "(ex:toStartOfHour([payment_date]))";
        this.datefunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Date";
        functionObj["function"] = "toStartOfDay(field_name)";
        functionObj["hint"] = "(ex:toStartOfDay([payment_date]))";
        this.datefunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Date";
        functionObj["function"] = "toTime(field_name)";
        functionObj["hint"] = "(ex:toTime([payment_date]))";
        this.datefunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Date";
        functionObj["function"] = "toRelativeYearNum(field_name)";
        functionObj["hint"] = "(ex:toRelativeYearNum([payment_date]))";
        this.datefunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Date";
        functionObj["function"] = "toRelativeMonthNum(field_name)";
        functionObj["hint"] = "(ex:toRelativeMonthNum([payment_date]))";
        this.datefunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Date";
        functionObj["function"] = "toRelativeWeekNum(field_name)";
        functionObj["hint"] = "(ex:toRelativeWeekNum([payment_date]))";
        this.datefunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Date";
        functionObj["function"] = "toRelativeDayNum(field_name)";
        functionObj["hint"] = "(ex:toRelativeDayNum([payment_date]))";
        this.datefunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Date";
        functionObj["function"] = "toRelativeHourNum(field_name)";
        functionObj["hint"] = "(ex:toRelativeHourNum([payment_date]))";
        this.datefunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Date";
        functionObj["function"] = "toRelativeMinuteNum(field_name)";
        functionObj["hint"] = "(ex:toRelativeMinuteNum([payment_date]))";
        this.datefunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Date";
        functionObj["function"] = "toRelativeSecondNum(field_name)";
        functionObj["hint"] = "(ex:toRelativeSecondNum([payment_date]))";
        this.datefunctionsArray.push(functionObj);
        //logical
        var functionObj = {};
        functionObj["type"] = "Logical";
        functionObj["function"] = "If" + "(Boolean_expression," +
            "Executes when the Boolean expression is true" +
            ",Executes when the Boolean expression is false);  ";
        functionObj["hint"] = "ex:(film_id>45,'yes','no')";
        this.logicalfunctionsArray.push(functionObj);
        var functionObj = {};
        functionObj["type"] = "Logical";
        functionObj["function"] = "CASE WHEN condition THEN statement ELSE default_value END  ";
        functionObj["hint"] = "(ex:CASE WHEN [film_id] < 45 THEN 0 ELSE 1 END )";
        this.logicalfunctionsArray.push(functionObj);
    }
    addData = () => {
        var that = this;
        $(".stringdiv").show();
        if (this.props.isEditCustomFiled) {
            $(".newreportcustomfieldname").val(this.props.editField);
            var parsedCustomFields = that.workSpaceEntityMetaDataCollection.getEntityCustomFeilds(that.entityName, that.data_parent);
            var name = this.props.editField;
            _.each(parsedCustomFields, (customField) => {
                if (customField.name == name) {
                    var fields = customField.htmltext;
                    this.fieldsArray = customField.fieldsArray;
                    this.funcArray = customField.funcArray;
                    var usedFields = customField.usedFields;
                    if (that.funcArray != undefined) {
                        fields = customField.fields
                        _.each(that.funcArray, function (func) {
                            var fullfunc = func; //.replace("\'\'","\\\\'\\\\'");
                            func = func.substring(0, func.indexOf('('));
                            fields = fields.replace(func + "(", ' <span class="customfieldaggrlist" data-word ="' + fullfunc + '" contenteditable=false>' + func + '(</span> ')
                        });
                    }
                    if (that.fieldsArray != undefined) {
                        _.each(that.fieldsArray, (func) => {
                            var columnName = this.columnDisplayNameMap[func];
                            fields = fields.replace("[" + func + "]", " [<span class='fa-orange customfieldlist' displayName = '" + func + "' columnName='" + columnName + "'>" + func + "</span>] ")
                        });
                    }
                    // for updating the renamed dimensions in the CUSTOM FIELD  formula editor
                    if (usedFields != undefined && usedFields.length > 0) {
                        _.each(usedFields, (columnName) => {
                            var columnDisplayName = _.invert(this.columnDisplayNameMap)[columnName];
                            if (columnDisplayName != undefined) {
                                $(".renamedfields").empty();
                                $(".renamedfields").append(fields);
                                _.each($('.customfieldlist'), function (field, i) {
                                    if ($(field).attr("columnName") == columnName) {
                                        var oldFunction = field.outerHTML;
                                        $(field).attr("displayName", columnDisplayName);
                                        $(field).text(columnDisplayName);
                                        var newFunction = field.outerHTML;
                                        fields = fields.replace("[" + oldFunction + "]", "[" + newFunction + "]");
                                    }
                                });
                                $(".renamedfields").empty();
                            }
                        });
                    }
                    if (customField.parametrFieldsArray != undefined) {
                        _.each(customField.parametrFieldsArray, function (func) {
                            fields = fields.replace("[" + func + "]", " [<span class='fa-orange customfieldparameterlist' data-word ='" + func + "'>" + func + "</span>] ")
                        });
                    }
                    this.parametrFieldsArray = customField.parametrFieldsArray;
                    $('.functiontypes option[value=' + customField.functiontype + ']').attr("selected", "selected");

                    $('#custommeasurequerydisplay').html(fields);
                }
            });
        }
    }
    testformula = (e) => {
        var textareaData = $("#custommeasurequerydisplay").text();
        var formula = this.validateFormula()[0];
        var sqlFormula = this.validateFormula()[2];
        var customparameters = this.customparameters;
        var temp;
        var that = this;
        var isFinalPreview = true;
        this.isImportedReport = false;
        if (sessionStorage.getItem("isImportedReport") || (this.props.isImportReport != undefined && this.props.isImportReport == true)) {
            this.isImportedReport = true;
        }
        _.each($('.customfieldparameterlist'), function (value, i) {
            value = $(value).attr("data-word");
            temp = $.grep(customparameters, function (e) {
                return e.parametername == value;
            })
            if (temp.length != 0) {
                formula = formula.replace("[" + value + "]", temp[0].parametercurrentvalue);
                sqlFormula = sqlFormula.replace("[" + value + "]", temp[0].parametercurrentvalue);
            }
        });
        if (formula != "") {

            var that = this;
            // var testCustomFieldFormulamodel = new queryinfomodel();
            // var testCustomFieldFormula = new TestCustomFieldFormulaCollection(testCustomFieldFormulamodel); //testwscustomformula
            var data = new FormData();
            data.append("reportId", that.props.entityId != undefined ? that.props.entityId : "");
            data.append("script", formula);
            data.append("entities", that.props.entities);
            data.append("schemaId", that.props.schemaId);
            data.append("customType", "customfield");
            data.append("isImportedReport", that.isImportedReport);
            data.append("sqlformula", sqlFormula);
            data.append("customFormulaFieldsArray", JSON.stringify(that.customFormulaFieldsArray));
            data.append("isFinalPreview", isFinalPreview);
            data.append("isCustomQuery", that.props.isCustomQuery);
            data.append("tableName", that.props.tableName);

            SendRequest({
                url: "testwscustomformula",
                queryString: "",
                body: data,
                sucessFunction: (response) => {
                    if (response.hasOwnProperty('error')) {
                        $(".spinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.errorTrace);
                        setTimeout(function () {
                            $('.birderror').remove();
                        }, 3000);
                    } else if (response.valid) {
                        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Success');
                        $('.birdmessage-info').empty().text(getMessage('BirdSuccess32'));
                        setTimeout(function () {
                            $('.birdsuccess').remove();
                        }, 3000);
                    } else {
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Warning');
                        $('.birdmessage-info').empty().text(getMessage('BirdWarning30'));
                        setTimeout(function () {
                            $('.birderror').remove();
                        }, 3000);
                    }
                },
                rejectFunction: (error) => { },
                headers: {
                    method: "post",
                    headers: new Headers({
                        "Content-Type": "application/json"
                    })
                }
            });
        }
        else {
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(getMessage('BirdWarning30'));
        }
    }
    getStartEndBrackets = () => {
        var startEndBrackets = {};
        if (this.isDirect == 1) {
            startEndBrackets["leftBracket"] = "`";
            startEndBrackets["rightBracket"] = "`";
            if (this.schemaType == 'mysql') {
                startEndBrackets["leftBracket"] = "`";
                startEndBrackets["rightBracket"] = "`";
            }
            else if (this.schemaType == 'sqlserver') {
                startEndBrackets["leftBracket"] = "[";
                startEndBrackets["rightBracket"] = "]";
            }
            else if (this.schemaType == 'oracle' || this.schemaType == 'postgres') {
                startEndBrackets["leftBracket"] = "\"";
                startEndBrackets["rightBracket"] = "\"";
            }
        } else {
            startEndBrackets["leftBracket"] = "\"";
            startEndBrackets["rightBracket"] = "\"";
        }
        return startEndBrackets;
    }
    validateFormula = () => {
        var textareaData = $("#custommeasurequerydisplay").text();
        textareaData = encodeURIComponent(textareaData);
        textareaData = textareaData.replace(/%C2%A0/g, ' ');
        textareaData = decodeURIComponent(textareaData);
        this.customFormulaFieldsArray = [];
        var that = this;
        var tempExp = "";
        var tempsqlexpval = "";
        var sqlExp = textareaData;
        var expressionObject = {};
        if ($('.functiontypes').val() != "raw") {
            _.each($('.customfieldlist'), (field, i) => {
                var bool = false;
                var fieldDisplayName = $(field).attr("displayName");
                field = $(field).attr("columnName");

                if (!bool) {
                    var type = this.columnDisplayTypeMap[field];
                    if (type != 'parameter') {
                        tempExp += "def val" + i + " = doc['" + this.columnDisplayNameMap[fieldDisplayName] + "'].value; ";
                        if (type == 'number') {
                            tempExp += "if(val" + i + " == null ) { val" + i + " = 0};";
                        }
                        else {
                            tempExp += "if(val" + i + " == null ) { val" + i + " = ''};";
                        }
                        expressionObject[fieldDisplayName + '_' + i] = "val" + i;
                    }
                    var columnName = this.columnDisplayNameMap[fieldDisplayName];
                    var customFieldsArray = [];
						if(that.workSpaceEntityMetaDataCollection.getEntityCustomFeilds(that.entityName,that.data_parent)!=undefined){
							customFieldsArray = $.grep(that.workSpaceEntityMetaDataCollection.getEntityCustomFeilds(that.entityName,that.data_parent),function(e){
								return e.name== columnName;
							});
						}
						var startEndBrackets = that.getStartEndBrackets();
						if(customFieldsArray.length>0){
							_.each(customFieldsArray[0].usedFields,function(val){
								that.customFormulaFieldsArray.push(val);
							});
							var sqlformula = customFieldsArray[0].sqlformula
							var tempcolumnName = startEndBrackets["leftBracket"]+ columnName + startEndBrackets["rightBracket"];
							tempsqlexpval =  textareaData;
							sqlExp = sqlExp.replace('['+fieldDisplayName+']',sqlformula+"");
                            tempsqlexpval = tempsqlexpval.replace('/[/g','"');
							tempsqlexpval = tempsqlexpval.replace('/]/g','"');
						}else{
							that.customFormulaFieldsArray.push(columnName);
							columnName = startEndBrackets["leftBracket"]+ columnName + startEndBrackets["rightBracket"];
							sqlExp = sqlExp.replace('['+fieldDisplayName+']',columnName+"");
						}
                }

            });
            var tempExpObject = expressionObject;
            _.each(tempExpObject, function (val, field) {
                field = field.substring(0, field.lastIndexOf('_'));

                textareaData = textareaData.replace('[' + field + ']', val + "");
            });
            var foundList = [];
            _.each($('.customfieldaggrlist'), function (list) {
                list = $(list).attr("data-word");
            });
            var scriptCondition = textareaData.replace(/%C2%A0/g, '');
            if (textareaData.indexOf("/") !== -1) {
                textareaData = "try{Double.valueOf(" + textareaData + ")}catch(Exception e){" + textareaData + "}";
            }
            return [tempExp+textareaData,scriptCondition, sqlExp,tempsqlexpval];
        } else {
            var textareaData = encodeURIComponent($("#custommeasurequerydisplay").text());
            textareaData = textareaData.replace(/%C2%A0/g, ' ');
            textareaData = decodeURIComponent(textareaData);
            //textareaData = textareaData.replace(/ /g,'');
            _.each($('.customfieldlist'), (field, i) => {
                field = $(field).attr("displayName");
                textareaData = textareaData.replace(' [' + field + '] ', this.columnDisplayNameMap[field]);
            });
            return [textareaData,textareaData, "",""]
        }
    }
    clearQueryArea = (e) => {
        $('#custommeasurequerydisplay').text("");
    }
    updateColumnDisplayNameMap = () => {
        var that = this;
        var selectedcolumns = [];
        this.columnDisplaynameArray = [];
        var reportColumnObj=[];       
            if (typeof (this.props.reportColumns) == "string") {
                reportColumnObj = JSON.parse(this.props.reportColumns);
            } else {
                reportColumnObj = this.props.reportColumns;

            }
        _.each(reportColumnObj, (reportColmn, i) => {
            if (reportColmn.visible.toLowerCase() == "yes" || reportColmn.visible.toLowerCase() == "y") {
                this.columnDisplayMaps[reportColmn.columnDisplayName] = reportColmn.columnDisplayName;
                this.columnDisplayNameMap[reportColmn.columnDisplayName] = reportColmn.columnName;
                this.columnDisplayTypeMap[reportColmn.columnDisplayName] = reportColmn.tableDisplayType.toLowerCase();
                this.columnDisplaynameArray.push(reportColmn.columnDisplayName);
            }
        });
        var customfieldsarray = that.workSpaceEntityMetaDataCollection.getEntityCustomFeilds(that.entityName,that.data_parent);
			_.each(customfieldsarray,function(val){
				that.columnDisplayMaps[val.name] =  val.name;
				that.columnDisplayNameMap[val.name] =  val.name;
				that.columnDisplayTypeMap[val.name] =  val.dataType.toLowerCase();
				that.columnDisplaynameArray.push(val.name);
			});
    }
    textAreaAutoComplete = () => {
        var that = this;
        var schemaType = that.props.schemaType;
        //this.schemaType = schemaType.toLowerCase();
        var words = ['empty()', 'notEmpty()', 'length()', 'reverse()', 'concat()', 'substring(,,)', 'appendTrailingCharIfAbsent(,)', 'position(,)', 'match(,)', 'lower()', 'upper()', 'like(,)', 'notLike(,)', 'replaceAll(,,)', 'toString()'
            , 'e()', 'pi()', 'exp()', 'log()', 'exp2()', 'log2()', 'exp10()', 'log10()', 'sqrt()', 'cbrt()', 'erf()', 'erfc()', 'sin()', 'cos()', 'tan()', 'atan()', 'acos()', 'asin()',
            'pow(,)', 'abs()', 'round()', 'ceil()', 'floor()'
            , 'toDateTime()', 'toYear()', 'toMonth()', 'toDayOfMonth()', 'toDayOfWeek()', 'toHour()', 'toMinute()', 'toSecond()', 'toMonday()', 'toStartOfMonth()', 'toStartOfQuarter()', 'toStartOfYear()', 'toStartOfMinute()',
            'toStartOfFiveMinute()', 'toStartOfHour()', 'toStartOfDay()', 'toTime()', 'toRelativeYearNum()', 'toRelativeMonthNum()', 'toRelativeWeekNum()', 'toRelativeDayNum()',
            'toRelativeHourNum()', 'toRelativeMinuteNum()', 'toRelativeSecondNum()', 'today()', 'now()', 'yesterday()', 'if(,,)', 'case()',
            'toFloat64OrZero()', 'toFloat32OrZero()', 'toUInt64OrZero()', 'toUInt32OrZero()', 'toInt64OrZero()', 'toInt32OrZero()', 'toTypeName()', 'replaceOne(,,)'];
        $('#custommeasurequerydisplay').textcomplete([
            {
                words: words,
                match: /\b(\w{1,})$/,
                index: 1,
                search: (term, callback) => {
                    words = words.concat(this.columnDisplaynameArray);
                    callback($.map(words.sort(), function (word) {
                        return word.toLowerCase().indexOf(term.toLowerCase()) >= 0 ? word : null;
                    }));
                },
                template: (word) => {
                    if (word.indexOf('()') < 0 && word.indexOf('(,)') < 0 && word.indexOf('(,,)') < 0 && word.indexOf('(){}') < 0 && word.indexOf('{}') < 0 && word.indexOf('(,"")') < 0 && word.indexOf('(,,"")') < 0 && word.indexOf('( in )') < 0) {
                        var type = this.columnDisplayTypeMap[word];
                        var result = '';
                        if (type == 'number' || type == 'parameter') {
                            result = "<i class='fa fa-sort-numeric-asc'></i>  <span class='itemname' title='" + word + "'>" + word + "</span>";
                        }
                        else if (type == 'date') {
                            result = "<i class='fa fa-calendar'></i>  <span class='itemname' title='" + word + "'>" + word + "</span>";
                        }
                        else if (type == 'day' || type == 'month') {
                            result = "<i class='fa fa-calendar-o '></i>  <span class='itemname' title='" + word + "'>" + word + "</span>";
                        }
                        else {
                            result = "<i class='fa fa-sort-alpha-asc'></i>  <span class='itemname'  title='" + word + "'>" + word + "</span>";
                        }
                        return result;
                    } else {
                        return "<i>f</i> <small>(x)</small> " + word;
                    }
                },
                replace: (word) => {
                    var tempWord = word;
                    var type = this.columnDisplayTypeMap[word];
                    if (word.indexOf('()') < 0 && word.indexOf('(,)') < 0 && word.indexOf('(,,)') < 0 && word.indexOf('(){}') < 0 && word.indexOf('{}') < 0 && word.indexOf("(,'')") < 0 && word.indexOf("(,,'')") < 0 && word.indexOf('( in )') < 0) {
                        var columnName = this.columnDisplayNameMap[word];
                        tempWord = "[<span class='fa-orange customfieldlist' displayName = '" + word + "' columnName='" + columnName + "'>" + word + "</span>]"
                        if (type == 'parameter') {
                            that.parametrFieldsArray.push(word);
                            tempWord = "[<span class='fa-orange customfieldparameterlist' data-word ='" + word + "'>" + word + "</span>]";
                        }
                    }
                    else {
                        if (word == "case()") {
                            var dataword = "CASE WHEN ";
                            //var dataWithInbrackets = "[Condition] THEN  [Statement] ELSE [Statement] END";
                            var dataWithInbrackets = "[<span class='fa-blue'>Condition</span>] THEN  [<span class='fa-blue'>Statement</span>] ELSE [<span class='fa-blue'>Statement</span>] END";
                            tempWord = "<span class='customfieldaggrlist' data-word ='" + word + "' contenteditable=false>" + dataword + "</span>" + dataWithInbrackets + "<span></span>";
                            //that.showhelp(this,word);
                        } else {
                            var dataword = word.substring(0, word.indexOf('('));
                            var dataWithInbrackets = word.substring(word.indexOf('(') + 1, word.indexOf(')'));
                            tempWord = "<span class='customfieldaggrlist' data-word ='" + word + "' contenteditable=false>" + dataword + "(</span>" + dataWithInbrackets + "<span>)</span>";
                            //			                                            				  that.showhelp(this,word);
                        }
                    }
                    return tempWord;
                }
            }
        ], { maxCount: 50 });
    }
    createCustomField = (e) => {
        $('.custommeasurename-error').addClass('hide');
        var that = this;
        that.sameNameExists = false;
        this.isImportedReport = false;
        if (sessionStorage.getItem("isImportedReport") || (this.props.isImportReport != undefined && this.props.isImportReport == true)) {
            this.isImportedReport = true;
        }
        var textareaData = $("#custommeasurequerydisplay").text();
        var columnName = $(".newreportcustomfieldname").val().trim(); 
        var reservedwordCheck =$(".newreportcustomfieldname").val().trim().replace(/([#;?&,%•. +*~\':"!^$%[\]()<=>|\/@])/g, "");
        reservedwordCheck = reservedwordCheck.toLowerCase();
        var sqlFormula = this.validateFormula()[2];
        var tempsqlexpval = this.validateFormula()[3];
        var formula = "";
        var resultArray = this.validateFormula();
        formula = resultArray[0];
        var customparameters = this.customparameters;
        var temp;
        _.each($('.customfieldparameterlist'), function (value, i) {
            value = $(value).attr("data-word");
            temp = $.grep(customparameters, function (e) {
                return e.parametername == value;
            })
            if (temp.length != 0) {
                formula = formula.replace("[" + value + "]", temp[0].parametercurrentvalue);
                sqlFormula = sqlFormula.replace("[" + value + "]", temp[0].parametercurrentvalue);
            }
        });
        if (formula != "") {
            var that = this;
            // var testCustomFieldFormulamodel = new queryinfomodel();
            // var testCustomFieldFormula = new TestCustomFieldFormulaCollection(testCustomFieldFormulamodel);
            var data = new FormData();
            data.append("reportId", that.props.entityId != undefined ? that.props.entityId : "");
            data.append("script", formula);
            data.append("entities", that.props.entities);
            data.append("schemaId", that.props.schemaId);
            data.append("customType", "customfield");
            data.append("isImportedReport", that.isImportedReport);
            data.append("sqlformula", sqlFormula);
            data.append("customFormulaFieldsArray", JSON.stringify(that.customFormulaFieldsArray));
            data.append("isFinalPreview", true);
            data.append("isCustomQuery", that.props.isCustomQuery);
            data.append("tableName", that.props.tableName);
            data.append("fieldName", reservedwordCheck);
            
            SendRequest({
                url: "testwscustomformula",
                queryString: "",
                body: data,
                sucessFunction: (response) => {
                    if (response.hasOwnProperty('error')) {
                        $(".spinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.errorTrace)
                        setTimeout(function () {
                            $('.birderror').remove();
                        }, 3000);
                    }
                    else if (response.valid=="isreservedword") {
                        $(".spinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Warning');
                        $('.birdmessage-info').empty().text("Custom Field " + "" + getMessage('BirdWarning147'));
                        $(".spinner").hide();
                        return;
                    }
                     else if (response.valid) {
                        var fieldName = $(".newreportcustomfieldname").val().trim();
                        var textareaData = $("#custommeasurequerydisplay").text();
                        var htmlTextAreaData = $("#custommeasurequerydisplay").html();
                        if (fieldName != undefined && fieldName.length <= 0) {
                            $(".spinner").hide();
                            $(".gridspinner").hide();
                            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
                            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
                            $('.birdmessagebuttons').removeClass('show');
                            $('.birdmessagebuttons').addClass('hide');
                            $('.birdmessage-center').addClass("message-top-in");
                            $('.details').removeClass('show');
                            $('.details').addClass('hide');
                            $('.birdmessage h2').empty().append('Information');
                            $('.birdmessage-info').empty().text(getMessage('BirdInformation22'));
                            return;
                        }
                        if ((/[!@#$%^&*()+\=\[\]{};':\\|,.<>\/?"]/.test(fieldName.trim())) ||
                            (fieldName.toLowerCase().trim() == "min" || fieldName.toLowerCase().trim() == "max"
                                || fieldName.toLowerCase().trim() == "avg" || fieldName.toLowerCase().trim() == "sum"
                                || fieldName.toLowerCase().trim() == "count" || fieldName.toLowerCase().trim() == "avgerage")) {
                            $(".spinner").hide();
                            $(".gridspinner").hide();
                            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
                            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
                            $('.birdmessagebuttons').removeClass('show');
                            $('.birdmessagebuttons').addClass('hide');
                            $('.birdmessage-center').addClass("message-top-in");
                            $('.details').removeClass('show');
                            $('.details').addClass('hide');
                            $('.birdmessage h2').empty().append('Information');
                            $('.birdmessage-info').empty().text(getMessage('BirdInformation46'));
                            return;

                        }
                        if (!that.props.isEditCustomFiled) {
                            if (that.workSpaceEntityMetaDataCollection.getEntityCustomFeilds(that.entityName, that.data_parent) != null &&
                                that.workSpaceEntityMetaDataCollection.getEntityCustomFeilds(that.entityName, that.data_parent) != undefined && that.workSpaceEntityMetaDataCollection.getEntityCustomFeilds(that.entityName, that.data_parent).length > 0) {
                                that.sameNameExists = false;
                                var parsedCustomFields = that.workSpaceEntityMetaDataCollection.getEntityCustomFeilds(that.entityName, that.data_parent);
                                _.each(parsedCustomFields, function (customFieldObj, index) {
                                    if (fieldName == customFieldObj.name) {
                                        that.sameNameExists = true;
                                        return;
                                    }
                                });
                            }
                        } else {
                            if (that.workSpaceEntityMetaDataCollection.getEntityCustomFeilds(that.entityName, that.data_parent) != null &&
                                that.workSpaceEntityMetaDataCollection.getEntityCustomFeilds(that.entityName, that.data_parent) != undefined &&
                                that.workSpaceEntityMetaDataCollection.getEntityCustomFeilds(that.entityName, that.data_parent).length > 0) {
                                var parsedCustomFields = $.grep(that.workSpaceEntityMetaDataCollection.getEntityCustomFeilds(that.entityName, that.data_parent), function (e) {
                                    return e.name != that.props.editField;
                                });
                                _.each(parsedCustomFields, function (customFieldObj, index) {
                                    if (fieldName.trim() == customFieldObj.name) {
                                        that.sameNameExists = true;
                                        return;
                                    }
                                });
                            }
                        }
                        _.each(that.props.reportColumns, function (reportColumnObj, index) {
                            if (fieldName == reportColumnObj.columnDisplayName) {
                                that.sameNameExists = true;
                                return;
                            }
                        });
                        _.each(that.props.mashupColums, function (column, index) {
                            if (fieldName == column.columnName) {
                                that.sameNameExists = true;
                                return;
                            }
                        });

                        if (that.sameNameExists) {
                            $('.custommeasurename-error').removeClass('hide');
                            return;
                        }
                        if (textareaData != undefined && textareaData.length <= 0) {
                            return;
                        }
                        var customFields = that.workSpaceEntityMetaDataCollection.getEntityCustomFeilds(that.entityName, that.data_parent);
                        var customObjectArray = [];
                        var scriptFilterArray = [];
                        var tempDimensionArray = [];
                        if (customFields != undefined && customFields != null) {
                            var tempcustomObject = customFields;
                            /*_.each(tempcustomObject, function(customfield){
                                tempDimensionArray.push(customfield.name);
                                if(customfield.name != that.options.editField){
                                    customObjectArray.push(customfield);
                                }
                            });*/
                            var res;
                            _.each(tempcustomObject, function (customfields) {
                                tempDimensionArray.push(customfields.name);
                                _.each(customfields.parametrFieldsArray, function (value) {
                                    var customparameter = ChartInfo.functions.getCustomParameter();
                                    /*if(customParameterFilterArray!=undefined && customParameterFilterArray.length!=0){
                                        customparameter=customParameterFilterArray;
                                    }*/
                                    res = $.grep(customparameter, function (e) {
                                        return e.parametername == value;
                                    })
                                    var formula = customfields.formula;
                                    var scriptcondition = customfields.scriptcondition;
                                    var fields = customfields.fields;
                                    var sqlformula = customfields.sqlformula;
                                    if (res.length != 0) {
                                        customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
                                        customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
                                        customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
                                        customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
                                    } else {
                                        _.each(ChartInfo.functions.getCustomParameter(), function (values) {
                                            if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
                                                customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                                customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                                customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
                                                customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                            }
                                        });
                                    }
                                });
                                if (customfields.name != that.props.editField) {
                                    customObjectArray.push(customfields);
                                }
                            });
                        }
                        var formula = "";
                        var scriptFormula = "";
                        var scriptcondition = "";
                        var resultArray = that.validateFormula();
                        formula = resultArray[0];
                        that.formulaTemp = formula;
                        var userFormula = resultArray[2];
                        scriptcondition = resultArray[1];
                        var datatype = (response.datatype == undefined) ? "String" : response.datatype;
                        if (customFields != undefined && customFields != null && that.props.isEditCustomFiled) {
                            var tempcustomObject = customFields;
                            _.each(tempcustomObject, function (customfield) {
                                tempDimensionArray.push(customfield.name)
                                if (customfield.name == that.props.editField && customfield.formula == that.formulaTemp) {
                                    datatype = (customfield.dataType == undefined) ? "String" : customfield.dataType;
                                }
                            });
                        }
                        /*var tempCustomObject = {"name":fieldName,"dataType": datatype,"type":"dimension", "formula": formula, "htmltext":htmlTextAreaData, "fields":textareaData,"sqlformula":sqlformula, "scriptcondition":scriptcondition,
                            "priorityColumn":priorityColumn,"functiontype":$('.functiontypes').val(), "parametrFieldsArray":that.parametrFieldsArray,"usedFields":that.customFormulaFieldsArray};*/
                        var customFieldObj = {
                            "name": fieldName, "dataType": datatype, "type": "dimension", "formula": formula, "htmltext": htmlTextAreaData, "fields": textareaData, "sqlformula": sqlFormula, "scriptcondition": scriptcondition,
                            "priorityColumn": false, "functiontype": $('.functiontypes').val(), "parametrFieldsArray": [], "usedFields": that.customFormulaFieldsArray,"sqlExp":tempsqlexpval
                        };
                        if (formula.length >= 0) {
                            customObjectArray.push(customFieldObj);
                        }
                        that.workSpaceEntityMetaDataCollection.updateEntityCustomFeilds(customObjectArray, that.entityName, that.data_parent);


                        var tempObj = { "tableName": "", "columnName": fieldName, "columnDisplayName": fieldName, "dataType": datatype, "dataTypeClass": datatype };
                        //calling preview data
                        //	that.callPreview();
                        that.loadCustomFields(customObjectArray);
                        if (that.props.isEditCustomFiled) {
                            var oldName = that.props.editField;
                            var customFieldsObj = customFields;
                            var values = [];
                            if (customFieldsObj != undefined) {
                                _.each(customFieldsObj, function (value, i) {
                                    if (value.name == oldName) {
                                        customFieldsObj.name = fieldName;
                                        $("input.visible[name='" + oldName + "']").closest("tr").remove()
                                    }
                                    values.push(customFieldsObj);
                                });
                            }
                        }
                        PubSub.publish("updateCustomFieldsList");
                        $('.filtercustomfield').modal("hide");

                        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Success');
                        $('.birdmessage-info').empty().text(getMessage('BirdSuccess52'));
                        setTimeout(function () {
                            $('.birdsuccess').remove();
                        }, 3000);
                        $('.append_filtercustomfield').remove();
                    } else {
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Warning');
                        $('.birdmessage-info').empty().text(getMessage('BirdWarning30'));
                        setTimeout(function () {
                            $('.birderror').remove();
                        }, 3000);
                    }
                    
                },
                rejectFunction: (error) => { },
                headers: {
                    method: "post",
                    headers: new Headers({
                        "Content-Type": "application/json"
                    })
                }
            });
        }
        else {
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(getMessage('BirdWarning30'));
        }
    }
    loadCustomFields = (customfields) => {
        var that = this;
        new WorkspaceCustomfieldsPreview({
            customfields: customfields,
            entityName: that.entityName,
            data_parent: that.data_parent,
            entityId: that.entityId
        });
    }
    deleteData = () => {
        var that = this;
        if (this.props.isDeleteCustomFiled) {
            var that = this;
            var customFieldsObj = that.workSpaceEntityMetaDataCollection.getEntityCustomFeilds(that.entityName, that.data_parent);
            var values = [];
            if (customFieldsObj != undefined) {
                var oldName = that.props.deleteField;
                _.each(customFieldsObj, function (obj, i) {
                    if (obj.name != that.props.deleteField) {
                        values.push(customFieldsObj[i]);
                    }
                })
            }
            if (values != undefined && values.length > 0) {
                that.workSpaceEntityMetaDataCollection.updateEntityCustomFeilds(values, that.entityName, that.data_parent);
                //that.loadCustomFields(values);
            } else {
                that.workSpaceEntityMetaDataCollection.updateEntityCustomFeilds(undefined, that.entityName, that.data_parent);
                // that.loadCustomFields(values);
            }
        }
        PubSub.publish("updateCustomFieldsList");
    }
    render() {
        return (<></>);
    }
}