import React, { Component } from "react"
import ReactDOM from 'react-dom'
import $ from 'jquery/dist/jquery';
import { getMessage } from '../../js/helpers/utils/BirdMessage';
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import { lang } from "../../js/helpers/utils/languages/language";
export default class CustomHierarchyFieldView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicdiv: props.dynamicdiv,
            customparameter: props.customparameter,
            columnDisplayName: props.columnDisplayName,
            columnName: props.columnName,
            aggregation: props.aggregation
        };
        this.render();
    }
    render() {
        ReactDOM.render(<CustomParameterAddFieldViewTemplate
            dynamicdiv={this.state.dynamicdiv}
            columnDisplayName={this.state.columnDisplayName}
            columnName={this.state.columnName}
            deletecustomparameterfield={this.deletecustomparameterfield}
            customparameter={this.state.customparameter}
        ></CustomParameterAddFieldViewTemplate>, document.getElementById(this.state.dynamicdiv))

        return (<div></div>);
    }
    deletecustomparameterfield = (e) => {
        var $this = e.currentTarget;
        var that = this;
        var removableitem = $(e.currentTarget).attr('data-name');
        var bool = false;
        var customFields;
        var filterAccordianLength = $('.acc-content').length;
        if (sessionStorage.customFields != null && sessionStorage.customFields != "null" && sessionStorage.customFields != undefined) {
            customFields = JSON.parse(sessionStorage.customFields);
            for (var i = 0; i < customFields.length; i++) {
                var formula = customFields[i].formula;
                if (customFields[i].sqlformula.indexOf("[" + removableitem + "]") > -1) {
                    bool = true;
                }
            }
        }
        $('.acc-content').each(function () {
            var $this = this;
            if ($($this).attr("id") == removableitem) {
                if (filterAccordianLength > 0) {
                    bool = true;
                }
            }
        });
        if (bool) {
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text(getMessage('BirdWarning62'));
        }
        else {
            that.deletecustomparameter(e);
        }
    }
    deletecustomparameter(e) {
        var $this = e.currentTarget;
        var removableitem = e.currentTarget.title;
        var result = $.grep(ChartInfo.functions.getCustomParameter(), function (e) { return e.parametername != $($this).closest("li").attr("data-name") });
        ChartInfo.functions.setCustomParameter(result);
        $(".parameterscount").text(ChartInfo.functions.getCustomParameter().length);
        var filterAccordianLength = $('.acc-content').length;
        $('.acc-content').each(function () {
            var $this = this;
            if ($($this).attr("id") == removableitem) {
                if (filterAccordianLength > 0) {
                    var $deleteButton = $($this).find('#delete');
                    $($deleteButton).click();
                }
            }
        });
        $('.customparameterfields li[data-name="' + $($this).closest("li").attr("data-name") + '"]').parent().remove();
        $('.birdmessage-center').removeClass("message-top-in");
    }
}
class CustomParameterAddFieldViewTemplate extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        var that = this.props;
        return (<li key={that.dynamicdiv} className="boc-list-item singleliitems  customparameterfield attributescustomparameters" data-type="customparamterfield" data-name={that.columnName} title={that.columnDisplayName}>
            {/* <!-- customparamteraddfieldview.js --> */}
            <span className="itemtype">123</span>
            <span className="itemname">{that.columnDisplayName}</span>
            <span type="button" className="opacity-effect customparameterfield" onClick={that.customparameter} data-name={that.columnName} id="customparameter" title={lang["storyboard.edit"]} data-edit="edit">
                <i className="fa fa-pencil"></i>
            </span>
            <span type="button" onClick={that.deletecustomparameterfield} className="opacity-effect deletecustomparameterfield pull-right marg-left-10" data-name={that.columnName} title={`Delete ${that.columnDisplayName}`}>
                <i className="fa fa-trash-o"></i>
            </span>
        </li>)
    }

}