import React from 'react';
import { lang } from "../../js/helpers/utils/languages/language";
export default class SummaryTableColumnDropdownHtml extends React.Component {
    render() {
        return (
            <>
                {
                    this.props.rowvalues.map((row, index) => {
                        var dataType = row.tableDisplayType;
                        return (
                            <li key={index} title={row.columnDisplayName} className={`xs-list-item summarytablecolumnitem ${row.visible == 'no' ? 'hide' : ''}`} onClick={(e) => { this.props.addRowValue(e) }} data-parent={row.tableName} data-child={row.columnName} data-type={row.tableDisplayType} data-name={row.columnDisplayName}>
                                <span className="itemtype">
                                    {
                                        (dataType === 'number') ? (
                                            <>123</>
                                        ) : (dataType === 'date' || dataType === 'datetime' || dataType === 'month' || dataType === 'day') ? (
                                            <i className="fa fa-calendar"></i>
                                        ) : (dataType === 'customhierarchy' || dataType === 'custommeasurehierarchy') ? (
                                            <i className="fa fa-list"></i>
                                        ) : (<>{lang["storyboard.abc"]}</>)
                                    }
                                </span>
                                <span className="itemname " data-parent={row.tableName} data-child={row.columnName} data-type={row.tableDisplayType}>{row.columnDisplayName}</span>
                            </li>
                        )
                    })
                }
                {
                    (this.props.customFields != undefined) ? (
                        <>
                            {
                                this.props.customFields.map((row, index) => {
                                    var dataType = row.dataType;
                                    return (
                                        <li key={index} title={row.name} data-parent={row.name} data-child={row.name} data-type={row.dataType == undefined ? 'string' : row.dataType} data-name={row.name} class="xs-list-item summarytablecolumnitem" onClick={(e) => { this.props.addRowValue(e) }}>
                                            <span class="itemtype fx">
                                                {
                                                    (dataType === 'number') ? (
                                                        <>123</>
                                                    ) : (dataType === 'date' || dataType === 'datetime' || dataType === 'month' || dataType === 'day') ? (
                                                        <i className="fa fa-calendar"></i>
                                                    ) : (dataType === 'customhierarchy' || dataType === 'custommeasurehierarchy') ? (
                                                        <i className="fa fa-list"></i>
                                                    ) : (<>{lang["storyboard.abc"]}</>)
                                                }
                                            </span>
                                            <span class="itemname" data-parent={row.name} data-child={row.name} data-type={row.dataType == undefined ? 'string' : row.dataType}>{row.name}</span>
                                        </li>
                                    )
                                })
                            }
                        </>
                    ) : null
                }
                {
                    (this.props.customHierarchy != undefined) ? (
                        <>
                            {
                                this.props.customHierarchy.map((column, index) => {
                                    return (
                                        <li key={index} title={column.name} data-parent={column.name} data-child={column.name} data-type="customhierarchy" data-name={column.name} className="xs-list-item summarytablecolumnitem" onClick={(e) => { this.props.addRowValue(e) }}>
                                            <span className="itemtype"><i className="fa fa-list"></i></span>
                                            <span className="itemname" data-parent={column.name} data-child={column.name} data-type="customhierarchy">{column.name}</span>
                                        </li>
                                    )
                                })
                            }
                        </>
                    ) : null

                }
            </>
        )
    }
}