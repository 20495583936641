import React, { Component } from 'react';
import { lang } from "../js/helpers/utils/languages/language";
import { render } from 'react-dom';
const $ = window.$;
export default class DrildownFilters extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        $('.drillmoreitemslist').click(function (e) {
            e.stopPropagation();
            var $this = e.currentTarget;
            var top = e.pageY + 14;
            if (top < 100) { top = 100; }
            var left = e.pageX - 10;
            $("body>.quickfilterslist, body>span.quickfiltermask").remove();
            var clonedEle = $($this).parent().find(".quickfilterslist").clone();
            $("body").append(clonedEle.removeClass("hide").css({ top: top, left: left })).append("<span class='quickfiltermask'></span>");
            $("body span.quickfiltermask").on("click", function (e) {
                $("body>.quickfilterslist, body>span.quickfiltermask").remove();
            });
        });
    }

    render() {
        var that = this.props;
        return (
            <div className="btn-group bgcommon" id={`drilldownfiltersjsxid${that.dynamicdiv}`} title={`${that.columnDisplayName}:${that.filterValues}`}>
                <button type="button" className="btn btn-bird-group btn-sm quickfilterbg">{that.columnDisplayName}</button>
                <div type="button" className="btn btn-bird-group btn-sm quickfiltervalues" title={lang["storyboard.more"]}>
                    {that.filterValues.indexOf("@@") >= 0 ?
                        <>	<span className="drillmoreitemslist" parent-column={that.columnDisplayName}>
                            <i className="fa fa-ellipsis-v"></i>
                        </span>
                            <ul className="quickfilterslist hide">
                                {that.filterValues.split("@@").map((value, index) => {
                                    return <li key={index} title={value}>{value}</li>
                                })}
                            </ul></>
                        // <!-- <span className="quickfiltermask"></span> -->
                        :
                        <span title={that.filterValues}>{that.filterValues}</span>
                    }
                    <span className='quickfilterremove' columnname={that.columnDisplayName} data={that.filterValues}></span>
                </div>

            </div>
        );
    }
}