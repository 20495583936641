import _ from 'lodash';
import React from "react";
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import { lang } from "../../js/helpers/utils/languages/language";
import ReactDOM from "react-dom";
import { getMessage } from '../../js/helpers/utils/BirdMessage';
import CustomParameterModalTemplate from './customparametermodal.jsx'
import customParamterAddFieldView from "./customparamteraddfieldview"
const $ = window.$;


export default class CustomParameterModalView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            customparameter: props.customparameter,
            chartnumber: props.chartnumber
        };
        this.changeAllowableValues = this.changeAllowableValues.bind(this);
        this.render();
    }
    render() {
        this.state.el = ".customparametermodalcontainer" + this.props.chartnumber;
        var that = this;
        var parameterName = "";
        var parameterValue = "";
        var parameterValueType = "";
        var parametercurrentvalue = "";
        var parameterrangevalues = '';
        this.state.isEdit = false;
        var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(this.props.chartnumber));
        var convertedNames = JSON.parse(sessionStorage.getItem("convertedtypes"));
        var jsonObject = {};
        _.each(convertedNames, function (array) {
            jsonObject[array.columnName] = array.dataType;
        });
        var result = $.grep(ChartInfo.functions.getCustomParameter(), function (e) { return e.parametername == that.props.columnName });
        if (result != undefined && result.length > 0) {
            this.state.isEdit = true;
            this.state.oldName = result[0].parametername;
            parameterName = result[0].parametername;
            parameterValue = result[0].parameterlistvalues;
            parameterrangevalues = result[0].parameterrangevalues;
            parametercurrentvalue = result[0].parametercurrentvalue;
            parameterValueType = result[0].parametervaluetype;
        }
        var convertedNames = JSON.parse(sessionStorage.getItem('renameMeasuresorDimensions'));
        ReactDOM.render(<CustomParameterModalTemplate changeAllowableValues={this.changeAllowableValues} addParameterListItem={this.addParameterListItem} removeParameterListItem={this.removeParameterListItem}
            createcustomparameter={this.createcustomparameter} closeParameterModal={this.closeParameterModal} chartnumber={this.state.chartnumber} existinghierarchy={result} convertedTypes={jsonObject} convertedNames={convertedNames} parameterrangevalues={parameterrangevalues}
            parameterName={parameterName} parameterValue={parameterValue} parameterValueType={parameterValueType} parametercurrentvalue={parametercurrentvalue} isEdit={this.state.isEdit}
        ></CustomParameterModalTemplate>, document.querySelector(this.state.el))
        this.changeAllowableValues();
        return (<div></div>)
    }
    closeParameterModal = () => {
        document.body.classList.remove('modal-open');
        ReactDOM.unmountComponentAtNode(document.querySelector(this.state.el));
    }
    changeAllowableValues = (e) => {
        var activeValue = $('.allowablevalues:checked').attr('id');
        if (activeValue === "1") {
            $('.avcontainer').addClass('hide');
        } else if (activeValue === "2") {
            $('.avcontainer').not('.avlist').addClass('hide');
            $('.avlist').removeClass('hide');
        } else if (activeValue === "3") {
            $('.avcontainer').not('.avrange').addClass('hide');
            $('.avrange').removeClass('hide');
        }
    }
    addParameterListItem = (e) => {
        var that = this;
        var $this = e.currentTarget;
        var parameterlistvalue = $(".parameterlistvalue").val();
        var parameterlistname = $(".parameterlistdisplayname").val();
        var previousrow = $($this).parent().parent();
        var rowsCount = $('tbody tr').length;
        $('.parameterlistdisplayname').removeClass('bird-has-error');
        $('.parameterlistvalue').removeClass('bird-has-error');
        $('.parametererror').remove();
        rowsCount++;
        if (parameterlistname === "") {
            parameterlistname = parameterlistvalue;
        }
        if (parameterlistvalue != "") {
            $(previousrow).after('<tr><td class=parameterlistfield' + rowsCount + '>' + parameterlistvalue + '</td><td>' + parameterlistname + '</td><td align="center" class=parameterdisplayfield' + rowsCount + '><button class="btn btn-transparent removeparameterlistitem"><i class="fa fa-trash"></i></button></td></tr>');
            $(".parameterlistfield0 .parameterlistvalue, .parameterdisplayfield0 .parameterlistdisplayname").val("");
        } else {
            $(previousrow).after('<tr class="parametererror"><td colspan=3 class="text-danger">'+lang["storyboard.enteravalue"]+'</td></tr>');
            //$('.parametererror').fadeOut(4000).queue(function() { $(this).remove(); });
        }
        $('.removeparameterlistitem').click(function (e) {
            that.removeParameterListItem(e);
        })
    }
    removeParameterListItem = (e) => {
        var $this = e.currentTarget;
        $($this).parent().parent().remove();
    }
    createcustomparameter = (e) => {
        e.preventDefault();
        var that = this;
        that.state.close = true;
        var parametertype = $("input[name='allowablevalues']:checked").val()
        var columnName = $('#parametername').val().replace(/([#;?&,%. +*~\':"!^$%[\]()<=>|\/@])/g, "\\$1");
        var status = false;
        var reservedwordCheck = columnName.toLowerCase();
        var reservedwordslist = JSON.parse(sessionStorage.getItem("reservedwordslist"));
        _.each(reservedwordslist, function (reservedword, j) {
            if (reservedwordCheck == reservedword) {
                status = true
            }
        });
        if (status == true) {
            $(".spinner").hide();
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Warning');
            $('.birdmessage-info').empty().text("Custom Parameter " + "" + getMessage('BirdWarning147'));
            $(".spinner").hide();
            //$('.applyattributes').addClass("disabled");
            return;
        }
        if (/[~`!@#$%^&*()+\=\[\]{};':\\|,.<>\/?"]/.test(columnName)) {
            $('.help-block.customhierarchy').removeClass('hide');
            $('.help-block.customparameter').addClass('hide');
            that.state.close = false;
            return false;
        } else if (columnName.toLowerCase().trim() == "min" || columnName.toLowerCase().trim() == "max"
            || columnName.toLowerCase().trim() == "avg" || columnName.toLowerCase().trim() == "sum"
            || columnName.toLowerCase().trim() == "count" || columnName.toLowerCase().trim() == "avgerage") {
            $('.help-block.customhierarchy').removeClass('hide');
            $('.help-block.customparameter').addClass('hide');
            that.state.close = false;
            return false;
        }
        if (this.state.oldName != $('#parametername').val() || $('#parametercurrentvalue').val() == "" || $('.rangemin').val() == "" || $('.rangemax').val() == "" || $('.rangestersize').val() == "" || $('.parameterlistvalue').val() == "" || $('.parameterlistdisplayname ').val() == "") {
            if (!$('#parametername').val().length > 0) {
                $('.help-block.customhierarchy').removeClass('hide');
                $('.help-block.customparameter').addClass('hide');
                that.state.close = false;
            }
            else if (this.state.isEdit != true && $('.attributescustomparameters[title=' + columnName + ']').length > 0 || $('.attributesdimensions[title=' + columnName + ']').length > 0 || $('.attributesmeasures[title=' + columnName + ']').length > 0
                || $('.customrangefields[title=' + columnName + ']').length > 0 || $('.customhierarchyfields[title=' + columnName + ']').length > 0) {
                $('.help-block.customparameter').removeClass('hide');
                $('.help-block.customhierarchy').addClass('hide');
                that.state.close = false;

            } else if ($('.customparameterfield[data-name="' + columnName + '"]').length != 0 && $('#parametercurrentvalue').val() != "" && $('.rangemin').val() != "" && $('.rangemax').val() != "" && $('.rangestersize').val() != "" && $('.parameterlistvalue').val() == "" && $('.parameterlistdisplayname ').val() != "") {
                $('.help-block.customparameter').removeClass('hide');
                $('.help-block.customhierarchy').addClass('hide');
                that.state.close = false;

            } else if ($('#parametercurrentvalue').val() == "") {
                $('.help-block.customparametervalueerror').removeClass('hide');
                $('.help-block.customparameter').addClass('hide');
                $('.help-block.customhierarchy').addClass('hide');
                that.state.close = false;

            } else if ($('.rangemin').val() == "" && parametertype == "range") {
                $('.rangemin').addClass('bird-has-error');
                $('.help-block.customparameter').addClass('hide');
                $('.help-block.customhierarchy').addClass('hide');
                $('.parameterlistvalue').removeClass('bird-has-error');
                $('.parameterlistitems').removeClass('bird-has-error');
                $('.rangestersize').removeClass('bird-has-error');
                $('.rangemax').removeClass('bird-has-error');
                that.state.close = false;

            } else if ($('.rangemax').val() == "" && parametertype == "range") {
                $('.rangemin').removeClass('bird-has-error');
                $('.rangestersize').removeClass('bird-has-error');
                $('.parameterlistvalue').removeClass('bird-has-error');
                $('.parameterlistitems').removeClass('bird-has-error');
                $('.help-block.customparameter').addClass('hide');
                $('.help-block.customhierarchy').addClass('hide');
                $('.rangemax').addClass('bird-has-error');
                that.state.close = false;

            } else if ($('.rangestersize').val() == "" && parametertype == "range") {
                $('.rangemax').removeClass('bird-has-error');
                $('.rangemin').removeClass('bird-has-error');
                $('.parameterlistvalue').removeClass('bird-has-error');
                $('.parameterlistitems').removeClass('bird-has-error');
                $('.help-block.customparameter').addClass('hide');
                $('.help-block.customhierarchy').addClass('hide');
                $('.rangestersize').addClass('bird-has-error');
                that.state.close = false;

            } else if ($('.parameterlistvalue').val() == "" && parametertype == "list" && $(".parameter-list-box table tbody tr").length == 1) {
                $('.parameterlistdisplayname').removeClass('bird-has-error');
                $('.rangemin').removeClass('bird-has-error');
                $('.rangemax').removeClass('bird-has-error');
                $('.rangestersize').removeClass('bird-has-error');
                $('.parameterlistvalue').addClass('bird-has-error');
                $('.help-block.customparameter').addClass('hide');
                $('.help-block.customhierarchy').addClass('hide');
                that.state.close = false;

            } else if ($('.parameterlistdisplayname').val() == "" && parametertype == "list" && $(".parameter-list-box table tbody tr").length == 1) {
                /*$('.parameterlistvalue').removeClass('bird-has-error');
                $('.rangemin').removeClass('bird-has-error');
                $('.rangemax').removeClass('bird-has-error');
                $('.rangestersize').removeClass('bird-has-error');
                $('.parameterlistdisplayname').addClass('bird-has-error');
                $('.help-block.customparameter').addClass('hide');
                $('.help-block.customhierarchy').addClass('hide');*/
                $('.parameterlistdisplayname').val($('.parameterlistvalue').val());
                $('.addparameterlistitem').click();
                this.createCustomParameterField(e);
            } else if ($('.parameterlistvalue').val() != "" && parametertype == "list") {
                if ($('.parameterlistdisplayname').val() == "") {
                    $('.parameterlistdisplayname').val($('.parameterlistvalue').val());
                }
                $('.addparameterlistitem').click();
                this.createCustomParameterField(e);
            } else {
                $('.rangemin').removeClass('bird-has-error');
                $('.rangemax').removeClass('bird-has-error');
                $('.rangestersize').removeClass('bird-has-error');
                $('.parameterlistvalue').removeClass('bird-has-error');
                $('.parameterlistitems').removeClass('bird-has-error');
                $('.help-block.customparameter').addClass('hide');
                $('.help-block.customhierarchy').addClass('hide');
                this.createCustomParameterField(e);
            }
        } else {
            $('.rangemin').removeClass('bird-has-error');
            $('.rangemax').removeClass('bird-has-error');
            $('.rangestersize').removeClass('bird-has-error');
            $('.parameterlistvalue').removeClass('bird-has-error');
            $('.parameterlistitems').removeClass('bird-has-error');
            $('.help-block.customparameter').addClass('hide');
            $('.help-block.customhierarchy').addClass('hide');
            this.createCustomParameterField(e);
        }
        if (that.state.close) {
            this.closeParameterModal();
        }
    }

    createCustomParameterField() {
        var that = this;
        var jsonobj = {};
        var hierarchyListArray = [];
        var parameterListArray = [];
        var oldName = this.state.oldName;
        var columnName = $('#parametername').val().replace(/([#;?&,%. +*~\':"!^$%[\]()<=>|\/@])/g, "\\$1");
        $('.help-block.customhierarchy').addClass('hide');
        $('.help-block.customhierarchy_li_items').addClass('hide');
        var deletevalue = $.grep(ChartInfo.functions.getCustomParameter(), function (e) { return e.parametername != that.state.oldName });
        if (deletevalue.length >= 0) {
            $('.customparameterfield[title="' + this.state.oldName + '"]').remove();
            ChartInfo.functions.setCustomParameter(deletevalue);
        }
        if (that.state.oldName != undefined) {
            that.state.oldName = that.state.oldName.replace(/([#;?&,%. +*~\':"!^$%[\]()<=>|\/@])/g, "\\$1")
        }
        var jsonarray = $.grep(ChartInfo.functions.getCustomParameter(), function (e) { return e.name != $('#parametername').val() });
        var functionMapping = [];
        $('.customparameterfields[data-name="' + columnName + '"]').remove();

        var parametername = $('#parametername').val();
        var parametercurrentvalue = $('#parametercurrentvalue').val();
        var parametervaluetype = $("input[name='allowablevalues']:checked").val();
        var rangeObject = {}
        /*if(parametervaluetype=="all"){
            valueObj["value"]=parametercurrentvalue;
        }*/
        if (parametervaluetype == "list") {
            $('.parametererror').remove();
            $(".parameter-list-box tbody tr:not(:first-child)").each(function (index, list) {
                var valueObj = {};
                valueObj["parameterlistvalue"] = $($($(list)).find('td .parameterlistvalue')).val();
                valueObj["parameterlistdisplayname"] = $($($(list)).find('td .parameterlistdisplayname')).val()
                if (valueObj["parameterlistvalue"] == undefined) {
                    valueObj["parameterlistvalue"] = $($($(list)).find('td')[0]).text();
                    valueObj["parameterlistdisplayname"] = $($($(list)).find('td')[1]).text();
                }

                parameterListArray.push(valueObj);
            });
        }
        if (parametervaluetype == "range") {
            rangeObject["rangemin"] = $('.rangemin').val();
            rangeObject["rangemax"] = $('.rangemax').val();
            rangeObject["rangestersize"] = $('.rangestersize').val();
        }
        jsonobj['parametername'] = parametername;
        jsonobj['parametercurrentvalue'] = parametercurrentvalue;
        jsonobj['parameterlistvalues'] = parameterListArray;
        jsonobj['parameterrangevalues'] = rangeObject;
        jsonobj['parametervaluetype'] = parametervaluetype;
        jsonarray.push(jsonobj);
        var that = this;
        var today = new Date();
        var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
        dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
        $(".customparameterfields").append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
        var customparamteraddfieldview = new customParamterAddFieldView({
            dynamicdiv: dynamicdiv,
            customparameter: that.state.customparameter,
            columnDisplayName: $('#parametername').val(),
            columnName: $('#parametername').val()
        });
        $(".customparameterfields").append($("#" + dynamicdiv));
        if ($('.parameterscount').text() == '') {
            $(".parameterscount").text(parseInt(1));
        } else {
            $(".parameterscount").text(parseInt($('.attributescustomparameters').length));
        }
        ChartInfo.functions.setCustomParameter(jsonarray);
        var temp = ChartInfo.functions.getCustomParametersFilters()
        _.each(temp, function (e) {
            if (e.parametername == parametername) {
                // var viewreportfilterview = new ViewReportFilterView({
                //     broker: that.options.broker,
                // });
                $('#' + oldName).remove();
                //  viewreportfilterview.addfilteritem(parametername, false, parametername, parametername, parametername, parametervaluetype, '', "", "", "", "", parametercurrentvalue, parametervaluetype);
            }
        });
        $(".customparametermodal" + this.state.chartnumber + "").modal("hide");
    }
}