import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
const $ = window.$;

const columns = [
    { dataField: 'clientName', text: 'Account Name', sort: true },
    { dataField: 'status', text: 'Status', sort: true },
    { dataField: 'editaccount', text: 'Edit', sort: false },
    { dataField: 'addlicense', text: 'License', sort: false },
    { dataField: 'showlicense', text: 'View License', sort: false },
    { dataField: 'resetpassword', text: 'Reset Password', sort: false },
    { dataField: 'deleteaccount', text: 'Delete', sort: false }
];
const RemotePagination = ({ data, page, sizePerPage, onTableChange, totalSize }) => (
    <div className="notifications-table-container">
        <BootstrapTable
            remote
            keyField="clientName"
            data={data}
            columns={columns}
            pagination={paginationFactory({ page, sizePerPage, totalSize })}
            onTableChange={onTableChange}
        />
    </div>
);
export default class AccountsTemplate extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        var that = this.props;
        return(<div className="container-fluid">
        <div className="row">
            <h1 className="boc-main-title">
                <i className="fa fa-key fa-admin-sm"></i>Manage Accounts
            </h1>
            <div className="container-fluid">
                <div className="row in-back">
                    <div className="row-same-height-inner">
                        <div className="col-xs-4 col-xs-height in-panes leftarea">
                            <div className="btn btn-transparent hideleft toggleleft"><i className="fa fa-grey fa-angle-left"></i></div>
                            <div className="btn btn-transparent showleft toggleleft hide"><i className="fa fa-grey fa-angle-left"></i></div>
                            <div className="pull-left col-sm-6 nopadding">
                                    <div id="custom-search-input col-md-4">
                                        <div className="input-group">
                                            <input type="text" placeholder="Search Accounts"
                                                className="form-control input-sm accountssearch" onKeyUp={this.props.searchKeyup}/> 
                                                
                                        </div>
                                    </div>
                                </div>
                                <div className="pull-right">
                                {that.features[0].features.map((feature)=>{ 
                    
                                if(feature.featureName=='add_account' && feature.allow==true){
                                return	<button className="btn btn-bird addaccount pull-right" onClick={that.AddAccount}>Add Account</button>						                      
                                }
                            })
                        }
                    </div>
                            <div className="listcontainer col-xs-12" id="accountstable">
                            <AccountsTable accountsList={that.accountsList} />
                            </div>
                            <div className="clear" className="icontent"></div>
                        </div>
                        <div className="col-xs-6 col-xs-height in-panes" id="icontent">
                            <div className="btn btn-transparent leftshow toggleright hide"><i className="fa fa-grey fa-angle-right"></i></div>
                            <div className="btn btn-transparent lefthide toggleright"><i className="fa fa-grey fa-angle-right"></i></div>
                            <div className="inneraccountscontentarea adminaccountform" ></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>);
    }
}

class AccountsTable extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        page: 1,
        data: props.accountsList.slice(0, 10),
        sizePerPage: 10
      };
    }
  
    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, data }) => {
      const currentIndex = (page - 1) * sizePerPage;
      {
        type == "sort" ?
          setTimeout(() => {
            let result;
            if (sortOrder === 'asc') {
              result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                  return 1;
                } else if (b[sortField] > a[sortField]) {
                  return -1;
                }
                return 0;
              });
            } else {
              result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                  return -1;
                } else if (b[sortField] > a[sortField]) {
                  return 1;
                }
                return 0;
              });
            }
            this.setState(() => ({
              data: result
            }));
          }, 10) :
          setTimeout(() => {
            this.setState(() => ({
              page,
              data: this.props.accountsList.slice(currentIndex, currentIndex + sizePerPage),
              sizePerPage
            }));
  
          }, 10);
      }
    }
  render() {
      const { data, sizePerPage, page } = this.state;
      return (
            <RemotePagination
              data={data}
              page={page}
              sizePerPage={sizePerPage}
              totalSize={this.props.accountsList.length}
              onTableChange={this.handleTableChange}
            />         
      );
    }
  }
