import React, { Component } from "react";
import ReactDOM from 'react-dom';
import EditUserAccountFormHtml from "./edituseraccountformhtml.jsx";
import { lang} from "../../js/helpers/utils/languages/language";
import SendRequest from '../../SendRequest';
const $ =window.$;
class EditUserAccountForm extends Component {
  constructor(props) {
    super(props);
      this.state = { };
    let featuresString = sessionStorage.getItem("features");
    featuresString = featuresString.slice(0, 3) + featuresString.slice(4);
    let decryptedString = window.atob(featuresString);
    this.state.parsedFeatureJson = JSON.parse(decryptedString);
    this.state.features= JSON.parse(decryptedString); 
    this.state.groupsresponse= {};
    this.state.rolesresponse= {};
    this.state.userdetailsresponse= {};
    this.state.userId=props.userId;
    this.getGroupslist();
    this.getRoleslist();
    this.getUserDetails();
    
  }
getGroupslist(){
  let requestHeaders = {
    method: "get",
    headers: new Headers({
        "Content-Type": "application/json"
    })
};
   SendRequest({
    url: "getAllGroups",
    queryString: "",
    sucessFunction: (response) => {
        if (response.hasOwnProperty('error')) {
            $(".spinner").hide();
        } else {
          this.state.groupsresponse=response;

        }
      },
      rejectFunction: () => { },
      headers: requestHeaders
    })
}
getRoleslist(){
  let requestHeaders = {
    method: "get",
    headers: new Headers({
        "Content-Type": "application/json"
    })
};
   SendRequest({
    url: "getRoles",
    queryString: "",
    sucessFunction: (response) => {
        if (response.hasOwnProperty('error')) {
            $(".spinner").hide();
        } else {
       this.state.rolesresponse=response;
       this.render();
        }
      },
      rejectFunction: () => { },
      headers: requestHeaders
    })
}
getUserDetails(){
  let requestHeaders = {
    method: "get",
    headers: new Headers({
        "Content-Type": "application/json"
    })
};
   SendRequest({
    url: "userDetails",
    queryString: "?userId= "+this.state.userId+"  ",
    sucessFunction: (response) => {
        if (response.hasOwnProperty('error')) {
            $(".spinner").hide();
        } else {
          this.state.userdetailsresponse=response;

        }
      },
      rejectFunction: () => { },
      headers: requestHeaders
    })
}
  render(){
    var that=this.props;
    
   setTimeout(() => {
  //  console.log(this.state.userdetailsresponse)
  //  console.log(  this.state.groupsresponse)
  ReactDOM.unmountComponentAtNode(document.querySelector('.newuser'));
    ReactDOM.render(<EditUserAccountFormHtml edituserdetails={this.state.userdetailsresponse} groupsresponse={this.state.groupsresponse} rolesresponse={this.state.rolesresponse} features={this.state.features}/>,document.querySelector(".newuser"));
    $(".group-select").chosen({
      no_results_text: lang["admin.users.oops"],
      width: "100%",
      search_contains: true
    });
    var userGroups = this.state.userdetailsresponse.userGroups;
    $.each(userGroups,function(index,group){
      $('.groupids option[value='+group.groupId+']').attr("selected","selected")
      $(".group-select").trigger("chosen:updated");
    })
  
   }, 500);
  return (
<div></div>
    );
};
}
export default EditUserAccountForm;