import React,{ useState }  from 'react';
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;


export default class ViewReportaddFilterModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    componentDidMount(){
        $("input.reportfiltercheckbox").on("click",function(e){e.stopPropagation();});
        $(".spinner").hide();
    }
    render() {
        $(".spinner").show();
        var that = this.props;
        return (
            // <!-- viewreportaddfilterview.js -->
            <div className="modal fade in" id="mySmallModal" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" style={{ display: "block" }}>
                <div className="modal-backdrop fade in"></div>
                <div className="modal-dialog modal-sm modal-sm-400 modal-dialog-sm-center modal-dialog-center" style={{ zIndex: "1050" }}>
                    <div className="modal-content">
                        <div className="modal-header boc-modal-hf-padding">
                            <button type="button" className="close white" id="close" data-dismiss="modal" onClick={that.cancelFilterModal} aria-hidden="true">&times;</button>
                            <h4 className="modal-title">
                                <i className="fa fa-filter"></i> {lang["storyboard.choosefilter"]}
                            </h4>
                        </div>
                        <div className="modal-body scrollsetnew">
                            <div className="container-fluid marg-top-5 nopadding">
                                <input className="form-control search-image" onKeyUp={this.search} placeholder={lang["storyboard.search"]} id="searchinput" type="text" />
                            </div>
                            <div className="container-fluid">
                                <div className="row filtercontent dobyfilteritems">
                                    {(that.reportId === undefined) ? (
                                        that.filterfields.map((values, index) => {
                                            return (
                                                <ul className="list-group scrollsetul">
                                                    {values.map((valueObject, index) => {
                                                        var displayname = valueObject.columnDisplayName.replace(/([;?&,%.+*~\':"!^$[\]()<=>`|\/@])/g, "");
                                                        if (index === 0) {
                                                            if (valueObject.tablename != null && valueObject.tablename != undefined && valueObject.tablename != "") {
                                                                return (
                                                                    <li className="boc-list-item">
                                                                        <label>{valueObject.tablename}</label>
                                                                    </li>
                                                                );
                                                            }
                                                        }
                                                        return (
                                                            <li className="boc-list-item filterliitem" data-type={valueObject.columnDisplayType}>
                                                                <label title={valueObject.columnDisplayName}>
                                                                    <input
                                                                        type="checkbox"
                                                                        className="reportfiltercheckbox"
                                                                        id="reportfiltercheckboxs"
                                                                        data-type={valueObject.columnDisplayType}
                                                                        data-parent={valueObject.tablename}
                                                                        data-child={valueObject.columnname}
                                                                        name={displayname}
                                                                    />
                                                                    {(valueObject.columnDisplayType == 'date' ||
                                                                        valueObject.columnDisplayType == 'datetime' ||
                                                                        valueObject.columnDisplayType == 'month' ||
                                                                        valueObject.columnDisplayType == 'day') ? (
                                                                            <span className="itemtype">
                                                                                <i className="fa fa-calendar"></i>
                                                                            </span>
                                                                        ) : null}
                                                                    {(valueObject.columnDisplayType == 'string') ? (
                                                                        <span className="itemtype">{lang["storyboard.abc"]}</span>
                                                                    ) : null}
                                                                    {(valueObject.columnDisplayType == 'number') ? (
                                                                        <span className="itemtype">123</span>
                                                                    ) : null}
                                                                    {valueObject.columnDisplayName}</label></li>
                                                        );
                                                    })}
                                                </ul>
                                            );
                                        })
                                    ) : null}
                                    {(that.reportId === -1 && that.hierarchy == "true") ? (
                                        that.filterfields.map((values, index) => {
                                            return (
                                                <ul className="list-group scrollsetul">
                                                    {values.map((valueObject, index) => {
                                                        var displayname = valueObject.columnDisplayName.replace(/([;?&,%.+*~\':"!^$[\]()<=>`|\/@])/g, "");
                                                        if (index === 0) {
                                                            if (valueObject.tablename != null && valueObject.tablename != undefined && valueObject.tablename != "") {
                                                                return (<li className="boc-list-item"><label>{valueObject.tableName}</label></li>);
                                                            }
                                                        }
                                                        return (
                                                            <li className="boc-list-item filterliitem" data-type={valueObject.tableDisplayType}>
                                                                <label title={valueObject.columnDisplayName}>
                                                                    <input
                                                                        type="checkbox"
                                                                        className="reportfiltercheckbox"
                                                                        id="reportfiltercheckboxs"
                                                                        data-type={valueObject.tableDisplayType}
                                                                        data-parent={valueObject.tableName}
                                                                        data-child={valueObject.columnName}
                                                                        name={displayname} />
                                                                    {(valueObject.tableDisplayType == 'date' ||
                                                                        valueObject.tableDisplayType == 'datetime' ||
                                                                        valueObject.tableDisplayType == 'month') ? (
                                                                            <span className="itemtype">
                                                                                <i className="fa fa-calendar fa-filter-icon"></i>
                                                                            </span>
                                                                        ) : null}
                                                                    {(valueObject.tableDisplayType == 'string') ? (
                                                                        <span className="itemtype">{lang["storyboard.abc"]}</span>
                                                                    ) : null}
                                                                    {(valueObject.tableDisplayType == 'number') ? (
                                                                        <span className="itemtype">123</span>
                                                                    ) : null}
                                                                    {valueObject.columnDisplayName}
                                                                </label>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            );
                                        })
                                    ) : null}
                                    {(that.reportId === -1 && that.hierarchy == "false") ? (
                                        <ul className="list-group scrollsetul">
                                            {
                                                that.filterfields.map((valueObject, index) => {
                                                    var displayname = valueObject.columnDisplayName.replace(/([;?&,%.+*~\':"!^$[\]()<=>`|\/@])/g, "");
                                                    // <!--  <% if(i===0) { i++;%>
                                                    //     <li className="boc-list-item"><%=valueObject.tablename%></li>
                                                    //     <% } %>  -->
                                                    return (
                                                        <li className="boc-list-item filterliitem" tite={valueObject.tablename} data-type={valueObject.tableDisplayType}>
                                                            <label title={valueObject.columnDisplayName}>
                                                                <input
                                                                    type="checkbox"
                                                                    className="reportfiltercheckbox"
                                                                    id="reportfiltercheckboxs"
                                                                    data-type={valueObject.tableDisplayType}
                                                                    data-parent={valueObject.tableName}
                                                                    data-child={valueObject.columnName}
                                                                    name={displayname} />
                                                                {(valueObject.tableDisplayType == 'date' ||
                                                                    valueObject.tableDisplayType == 'datetime' ||
                                                                    valueObject.tableDisplayType == 'month' ||
                                                                    valueObject.tableDisplayType == 'day') ? (
                                                                        <span className="itemtype">
                                                                            <i className="fa fa-calendar"></i>
                                                                        </span>
                                                                    ) : null}
                                                                {(valueObject.tableDisplayType == 'string') ? (
                                                                    <span className="itemtype">{lang["storyboard.abc"]}</span>
                                                                ) : null}
                                                                {(valueObject.tableDisplayType == 'number') ? (
                                                                    <span className="itemtype">123</span>
                                                                ) : null}
                                                                {valueObject.columnDisplayName}
                                                            </label>
                                                        </li>
                                                    );
                                                })
                                            }
                                        </ul>
                                    ) : null}
                                    {(that.reportId != -1 && that.hierarchy == "true") ? (
                                        that.filterfields.map((values, index) => {
                                            return (<ul className="list-group scrollsetul">
                                                {values.map((valueObject, index) => {
                                                    var displayname = valueObject.columnDisplayName.replace(/([;?&,%.+*~\':"!^$[\]()<=>`|\/@])/g, "");
                                                    if (index === 0) {
                                                        if (valueObject.tablename != null && valueObject.tablename != undefined && valueObject.tablename != "") {
                                                            return (
                                                                <li className="boc-list-item">
                                                                    <label>{valueObject.tableName}</label>
                                                                </li>
                                                            );
                                                        }
                                                    }
                                                    return (
                                                        <li className="boc-list-item filterliitem" data-type={valueObject.columnDisplayType}>
                                                            <label title={valueObject.columnDisplayName}>
                                                                <input
                                                                    type="checkbox"
                                                                    className="reportfiltercheckbox"
                                                                    id="reportfiltercheckboxs"
                                                                    data-type={valueObject.columnDisplayType}
                                                                    data-parent={valueObject.tableName}
                                                                    data-child={valueObject.columnName}
                                                                    name={displayname}
                                                                />
                                                                {(valueObject.columnDisplayType == 'date' ||
                                                                    valueObject.columnDisplayType == 'datetime' ||
                                                                    valueObject.columnDisplayType == 'month' ||
                                                                    valueObject.columnDisplayType == 'day') ? (
                                                                        <span className="itemtype">
                                                                            <i className="fa fa-calendar fa-filter-icon"></i>
                                                                        </span>
                                                                    ) : null}
                                                                {(valueObject.columnDisplayType == 'string') ? (
                                                                    <span className="itemtype">{lang["storyboard.abc"]}</span>
                                                                ) : null}
                                                                {(valueObject.columnDisplayType == 'number') ? (
                                                                    <span className="itemtype">123</span>
                                                                ) : null}
                                                                {valueObject.columnDisplayName}
                                                            </label>
                                                        </li>
                                                    );
                                                })}
                                            </ul>);
                                        })
                                    ) : null}
                                    {(that.reportId != -1 && that.hierarchy == "false") ? (
                                        <ul className="list-group scrollsetul">
                                            {that.filterfields.map((valueObject, index) => {
                                                var displayname = valueObject.columnDisplayName.replace(/([;?&,%.+*~\':"!^$[\]()<=>`|\/@])/g, "");
                                                //    <!--  <% if(i===0) { i++;%>
                                                //        <li className="boc-list-item"><%=valueObject.tablename%></li>
                                                //        <% } %>  -->
                                                return (
                                                    <li key={index} className="boc-list-item filterliitem" tite={valueObject.tablename} data-type={valueObject.columnDisplayType}>
                                                        <label title={valueObject.columnDisplayName}>
                                                            <input
                                                                type="checkbox"
                                                                className="reportfiltercheckbox"
                                                                id="reportfiltercheckboxs"
                                                                data-type={valueObject.columnDisplayType}
                                                                data-parent={valueObject.tableName}
                                                                data-child={valueObject.columnName}
                                                                name={displayname} />
                                                            {(valueObject.columnDisplayType == 'date' ||
                                                                valueObject.columnDisplayType == 'datetime' ||
                                                                valueObject.columnDisplayType == 'month' ||
                                                                valueObject.columnDisplayType == 'day') ? (
                                                                    <span className="itemtype"><i className="fa fa-calendar"></i></span>
                                                                ) : null}
                                                            {(valueObject.columnDisplayType == 'string') ? (
                                                                <span className="itemtype">{lang["storyboard.abc"]}</span>
                                                            ) : null}
                                                            {(valueObject.columnDisplayType == 'number') ? (
                                                                <span className="itemtype">123</span>
                                                            ) : null}
                                                            {valueObject.columnDisplayName}</label></li>
                                                );
                                            })
                                            }
                                        </ul>
                                    ) : null}
                                    {(that.customfield != 0) ? (
                                        <ul className="list-group scrollsetul">
                                            {that.customfield.map((customfield, index) => {
                                                var displayname = customfield.name.replace(/([;?&,%.+*~\':"!^$[\]()<=>`|\/@])/g, "");
                                                return (
                                                    <li key={index}
                                                        className="boc-list-item filterliitem"
                                                        tite={customfield.name}
                                                        data-type={customfield.columnDisplayType == undefined ? 'string' : customfield.columnDisplayType}>
                                                        <label title={customfield.name}>
                                                            <input
                                                                type="checkbox"
                                                                className="reportfiltercheckbox"
                                                                id="reportfiltercheckboxs"
                                                                data-type={customfield.columnDisplayType == undefined ? 'string' : customfield.columnDisplayType}
                                                                data-parent={customfield.name}
                                                                data-child={customfield.name}
                                                                name={displayname} />
                                                            {(customfield.columnDisplayType == 'date' ||
                                                                customfield.columnDisplayType == 'datetime' ||
                                                                customfield.columnDisplayType == 'month' ||
                                                                customfield.columnDisplayType == 'day') ? (
                                                                    <span className="itemtype fx"><i className="fa fa-calendar"></i></span>
                                                                ) : (customfield.columnDisplayType == 'string') ? (
                                                                <span className="itemtype fx">{lang["storyboard.abc"]}</span>
                                                            ) : (customfield.columnDisplayType == 'number') ? (
                                                                <span className="itemtype fx">123</span>
                                                            ) : (
                                                                    <span className="itemtype fx">{lang["storyboard.abc"]}</span>
                                                                )}
                                                            {customfield.name}</label></li>
                                                );
                                            })}
                                        </ul>
                                    ) : null}
                                    {/* <!-- <ul className="list-group">
							<li className="boc-list-item">address</li>
							<li className="boc-list-item">&nbsp;<i className="fa fa-check-square-o fa-fw"></i>&nbsp;address</li>
						</ul> --> */}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer boc-modal-hf-padding">
                            <button type="button" className="btn btn-bird" onClick={that.cancelFilterModal} id="btn-bird" data-dismiss="modal">
                                <i className="fa fa-times"></i> {lang["storyboard.cancelbtn"]}
                            </button>
                            <button type="button" onClick={that.addFilter} className="btn btn-bird modalApply" id="modalApply">
                                <i className="fa fa-check"></i> {lang["storyboard.applybtn"]}
                            </button>
                        </div>
                    </div>
                    {/* // <!-- /.modal-content --> */}
                </div>
                {/* // <!-- /.modal-dialog --> */}
            </div>
            // <!-- /.modal -->
        );
    }
   
    search = (e) => {
        var $this = e.currentTarget;
        var valThis = $($this).val().toLowerCase();
        $("input:checkbox[class='reportfiltercheckbox']").parents(".boc-list-item").each(function () {
            var text = $(this).text().toLowerCase();
            (text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
        });
    }
}