import React from "react";
import ReactDOM from 'react-dom';
import _ from 'underscore';
import PubSub from 'pubsub-js';
import ViewSavedReportBodyViewTemplate from './singlereportbody.jsx';
import * as collection from "../js/helpers/utils/SavedReportCollection";
import * as ChartUtils from '../js/helpers/utils/chartutil';
import * as TabInfo from '../js/helpers/utils/tabinfo';
import * as ChartInfo from '../js/helpers/utils/chartinfo';
import jstz from 'jstz';
import SendRequest from '../SendRequest';
import { getMessage } from '../js/helpers/utils/BirdMessage';
import BarChart from '../js/charts/bar';
import AreaChart from "../js/charts/area";
import DataTablePopupView from "./DataTablePopupView";
import BootStrapTable from "../js/charts/bootstraptable";
import SummaryView from "../js/charts/summaryview";
import LineChart from "../js/charts/line";
import TextChart from "../js/charts/textchart";
import SparkLineChart from "../js/charts/sparkline"
import HeatMapChart from '../js/charts/heatmap';
import WordCloudChart from "../js/charts/wordcloud"
import CombinationChart from '../js/charts/combinationchart'
import GuageChart from "../js/charts/guage"
import SankeyChart from '../js/charts/sankey';
import BasicPieChart from '../js/charts/basicPie';
import DonutChart from '../js/charts/donutChart';
import BubbleChart from '../js/charts/bubbleChart';
import Leaflet from '../js/charts/leaflet';
import MetricChart from '../js/charts/metric'
import ScatterChart from '../js/charts/scatterChart';
import FunnelChart from '../js/charts/funnel';
import SummaryTableView from '../ViewStoryboard/summarytable/summarytable';
import WorldMap from '../js/charts/worldMap';
import PivotView from './pivot/pivotview'
import BoxplotChart from "../js/charts/boxplot";
import TimeseriesChart from "../js/charts/timeseries";
import Indiamap from '../js/charts/Indiamap';
import SrilankaChart from '../js/charts/srilankachart';
import TrellisChart from '../js/charts/TrellisChart';
const $ = window.$;

export default class SingleReportBody extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.state.chartnumber = this.props.chartnumber;
        this.state.features = this.props.features;
        this.state.datacount = 0;
        this.state.viewcollection = this.props.viewcollection
        this.state.schedule = this.props.schedule;
        this.state.multifact = this.props.multifact;
        this.state.facttables=this.props.facttables;
        this.state.multifactentities = this.props.multifactentities;
        this.state.entities = this.props.entities;
        this.state.scheduleid = this.props.scheduleid;
        this.state.reportcolumns=this.props.dimensions;
        this.state.date = this.props.date;
        this.state.datamodelId = this.props.datamodelId;
        this.state.reportId = this.props.reportId;
        this.state.isPublished = this.props.isPublished;
        this.state.userRole=this.props.userRole;
        this.state.filterObject = this.props.filterObject;
        this.state.isFromStoryBook = this.props.isFromStoryBook == undefined ? false : this.props.isFromStoryBook;
        this.state.tabid = this.props.tabid == undefined ? 0 : this.props.tabid;
        this.state.viewreportmodel = this.props.viewreportmodel;
        var mainFilter = this.props.filterObject;
        ChartUtils.addColumnsData(JSON.stringify(this.props.dimensions));
        if (this.props.isFromStoryBook != undefined && this.props.isFromStoryBook) {
            TabInfo.functions.updatecolumnsDataForTab(this.state.tabid, JSON.stringify(this.props.dimensions));
        }
        this.isSharedReport = false;
        if (window.location.pathname.indexOf("sharereport") >= 0) {
            this.isSharedReport = true;
        }
        this.isPurgedReport = false;
        if (window.location.pathname.indexOf("viewpurgereport") >= 0) {
            this.isPurgedReport = true;
        }
        this.isImportedReport = false;
        if (sessionStorage.getItem("isImportedReport") || window.location.pathname.indexOf("importreport") >= 0) {
            this.isImportedReport = true;
            this.state.isImportedReport = true;
        }
        if (this.props.isFromUpdateStoryBookView) {
            return false;
        }
        this.unbindEvents();
        this.viewChartEve = PubSub.subscribe('viewChart', this.viewChartEve.bind(this));
        this.viewsummaryTabeEve = PubSub.subscribe('viewSummaryTabe', this.viewSummaryTabeEve.bind(this));
        this.viewpivot = PubSub.subscribe("viewPivotTable", this.viewPivotTableEve.bind(this));
        this.viewdatatableForSummaryTableEve = PubSub.subscribe("viewDataTableForSummaryTable", this.viewDataTableForSummaryTableEve.bind(this));
        this.summaryTableDrilldown = PubSub.subscribe("summarytabledrilldown", this.symmarytabledrilldownEve.bind(this));
        this.summarytabledrilldownwithbreadcrumbEve = PubSub.subscribe("summarytabledrilldownwithbreadcrumb", this.symmarytabledrilldown_breadcrumbEve.bind(this));
        this.render();
        this.afterRender();
        //this.componentDidMount();
        // setTimeout(function () {
        //     $('.gridspinner').attrchange({
        //         trackValues: true,
        //         callback: function (event) {
        //             var $this = event.target;
        //             var chartid = $($this).attr('data-num');
        //             $('#spinnerchart' + chartid + ' .gridspinnermessage').empty();
        //             $('#spinnerchart' + chartid + ' .gridspinnermessage').removeClass('showopacity')
        //             $('#spinnerchart' + chartid + ' .gridspinnermessage').removeClass('oops');
        //             $('#spinnerchart' + chartid + ' .gridspinnercontent img').removeClass('hide');

        //             setTimeout(function () {
        //                 var state = $('#spinnerchart' + chartid).css('display');
        //                 if (state === 'block') {
        //                     var currenttext = $('#spinnerchart' + chartid + ' .gridspinnermessage').html();
        //                     if (currenttext != 'The server response is taking time. Do you want to <div class="btn btn-transparent gridspinnercontinue singlespinnerwait' + chartid + '"><i class="fa fa-check-circle"></i>&nbsp;Wait</div>&nbsp;(or)&nbsp;<div class="btn btn-transparent singlespinnerclose' + chartid + ' gridspinnerclose"><i class="fa fa-times-circle"></i>&nbsp;CLOSE</div>') {
        //                         $('#spinnerchart' + chartid + ' .gridspinnermessage').text('We are processing your request...');
        //                     }
        //                     $('#spinnerchart' + chartid + ' .gridspinnermessage').addClass('showopacity');
        //                     setTimeout(function () {
        //                         $('#spinnerchart' + chartid + ' .gridspinnercontent img').addClass('hide');
        //                         $('#spinnerchart' + chartid + ' .gridspinnermessage').html('The server response is taking time. Do you want to <div class="btn btn-transparent gridspinnercontinue singlespinnerwait' + chartid + '"><i class="fa fa-check-circle"></i>&nbsp;Wait</div>&nbsp;(or)&nbsp;<div class="btn btn-transparent singlespinnerclose' + chartid + ' gridspinnerclose"><i class="fa fa-times-circle"></i>&nbsp;CLOSE</div>');
        //                         $('#spinnerchart' + chartid + ' .gridspinnermessage').addClass('oops');

        //                         $('.singlespinnerclose' + chartid).on("click", function () {
        //                             $('#spinnerchart' + chartid + ' .gridspinnermessage').empty();
        //                             $('#spinnerchart' + chartid + ' .gridspinnermessage').removeClass('showopacity')
        //                             $('#spinnerchart' + chartid + ' .gridspinnermessage').removeClass('oops');
        //                             $('#spinnerchart' + chartid + ' .gridspinnercontent img').removeClass('hide');
        //                             $('#spinnerchart' + chartid).hide();
        //                         });
        //                         $('.singlespinnerwait' + chartid).on("click", function () {
        //                             $('#spinnerchart' + chartid + ' .gridspinnermessage').empty();
        //                             $('#spinnerchart' + chartid + ' .gridspinnermessage').removeClass('showopacity')
        //                             $('#spinnerchart' + chartid + ' .gridspinnermessage').removeClass('oops');
        //                             $('#spinnerchart' + chartid + ' .gridspinnercontent img').removeClass('hide');
        //                             $('#spinnerchart' + chartid).hide();
        //                             $('#spinnerchart' + chartid).show();
        //                         });
        //                     }, 12000);
        //                 } else {
        //                     $('#spinnerchart' + chartid + ' .gridspinnermessage').empty();
        //                     $('#spinnerchart' + chartid + ' .gridspinnermessage').removeClass('showopacity');
        //                     $('#spinnerchart' + chartid + ' .gridspinnermessage').removeClass('oops');
        //                     $('#spinnerchart' + chartid + ' .gridspinnercontent img').removeClass('hide');
        //                 }

        //             }, 10000);
        //         }
        //     });
        // }, 0);
        this.adjustChartHeightEve = PubSub.subscribe('adjustchartheight', this.adjustChartHeightEveCap.bind(this));
        this.applyfilters = PubSub.subscribe("applyfiltesandredrawchart", this.applyfiltesandredrawchartEve.bind(this));
        this.viewdatatale = PubSub.subscribe("viewDataTable", this.viewDataTableEve.bind(this));
        this.columnPreviewEve = PubSub.subscribe("columnpreview", this.ColumnPreview.bind(this));
        this.addtrendlineEve = PubSub.subscribe("trendline", this.addtrendlineEv.bind(this));
        this.drawHistogramEven = PubSub.subscribe("drawhistogram", this.drawHistogramEve.bind(this));
    }
    afterRender() {
        $('.summarymodal').click((e) => { PubSub.publish("showSummaryModal", e) });
        $('.singlereport_summarymodal').click((e) => { PubSub.publish("showSummaryModal", e) });
    }
    componentDidMount() {
        $('.summarymodal').click((e) => { PubSub.publish("showSummaryModal", e) });
        $('.singlereport_summarymodal').click((e) => { PubSub.publish("showSummaryModal", e) });
    }
    render() {
        //this.$el.append(this.template());       
        if(!this.props.isFromTabClick){
            this.applyfiltesandredrawchart();
            this.colortheme();
        }else{
            $('#' + this.props.storyBookel).remove();
        }
        return (<div></div>);
    }
    applyfiltesandredrawchartEve(msg, data) {
        if (data != undefined) {
            this.applyfiltesandredrawchart(data.dateHistogramInterval, data.currentchartnum, data.applyattributes, data.calculation, data.isExcludeorIncludeFilters, data.isChartSummaryData,
                data.selecteddobycolumns, data.filterArray, data.navigatefrom, data.isCustomparameterFilter, data.tableInfo);
        } else {
            this.applyfiltesandredrawchart();
        }
        this.colortheme();
    }
    applyfiltesandredrawchart(dateHistogramInterval, chartno, applyattributes, calculation, isExcludeorIncludeFilters, isChartSummaryData, selectedDobyColumns, filterArray, tableInfo) {
        $(".streamingnow").removeClass("streamingnow");//remove class to select 15 mins range for Streaming chart				
        var globalFilterArray = [];
        var isPublished='false';
        if(this.state.viewcollection!=undefined){
            if(this.state.viewcollection.model!=undefined){
            isPublished= this.state.viewcollection.model.isPublished;
            if(isPublished ==undefined){
                isPublished='false';
            }
        }
    }
    if(this.props.viewcollection !=undefined){
        if(this.props.viewcollection.cols!=undefined && this.props.dimensions!=undefined)
            if(this.props.dimensions.length != this.props.viewcollection.cols.length){
                return
             }  
            }      		


        //				ChartInfo.setFilterArray(ChartInfo.getFilterArray().concat(ChartInfo.getDrillThrough()));
        var fragmentArray = window.location.pathname.split("/");
        if (fragmentArray != undefined && fragmentArray[0] != undefined && fragmentArray[0] == "viewstorybook") {
            if (TabInfo.functions.getCurrentTabId() != undefined) {
                var tabid = TabInfo.functions.getCurrentTabId();
                if (this.props.chartnumber != undefined && tabid != undefined && tabid != this.props.chartnumber.split("_")[0]) {
                    TabInfo.functions.updateTabFiltersBeforeRefresh(tabid, ChartInfo.functions.getFilters(false));
                    $('.refreshtabs').click();
                    return;
                }
            }
        }
        var data = JSON.parse(ChartInfo.functions.getChartDetails(this.props.chartnumber));
        if (data.chartType != "doby" && data.chartType != "summarytable" && data.chartType != "pivot" && data.chartType != "textchart") {
            if (data.yAxis.length == 0) {
                $(".pivotsettingsmain" + this.props.chartnumber).hide();
                $(".pivotcharticon" + this.props.chartnumber).hide();
                $(".pivotnavigationbuttons" + this.props.chartnumber).hide();
                var that = this;
                setTimeout(function () {
                    $(".spinner").hide();
                    $(".gridspinner").hide();
                    $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
                    $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('show');
                    $('.details').addClass('hide');
                    $('.birdmessage h2').empty().append('Information');
                    $('.birdmessage-info').empty().text(getMessage('BirdInformation56'));
                    setTimeout(function () {
                        var Props = {};
                        Props["target"] = undefined;
                        Props["showpopup"] = false;
                        PubSub.publish("showfilterdailouge", Props);
                    }, 100);
                    return false;
                }, 10);
                return false;
            }
        } else if (data.chartType == "summarytable" && data.summarytablecolumns != undefined && data.summarytablevalues != undefined && (data.summarytablecolumns.length == 0 || data.summarytablevalues.length == 0)) {
            var that = this;
            setTimeout(function () {
                $(".spinner").hide();
                $(".gridspinner").hide();
                $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
                $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Information');
                $('.birdmessage-info').empty().text(getMessage('BirdInformation56'));
                setTimeout(function () {
                    var Props = {};
                    Props["target"] = undefined;
                    Props["showpopup"] = false;
                    PubSub.publish("showfilterdailouge", Props);
                }, 100);
                return false;
            }, 10);
            return false;
        } else if (data.chartType == "pivot" && data.values != undefined && data.columns != undefined && data.rows != undefined && (data.values.length == 0 || data.columns.length == 0 || data.rows.length == 0)) {
            var that = this;
            setTimeout(function () {
                $(".spinner").hide();
                $(".gridspinner").hide();
                $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
                $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('show');
                $('.details').addClass('hide');
                $('.birdmessage h2').empty().append('Information');
                $('.birdmessage-info').empty().text(getMessage('BirdInformation56'));
                setTimeout(function () {
                    var Props = {};
                    Props["target"] = undefined;
                    Props["showpopup"] = false;
                    PubSub.publish("showfilterdailouge", Props);
                }, 100);
                return false;
            }, 10);
            return false;
        }
        this.state.chartnumber = this.props.chartnumber;
        //dateHistogramInterval = ChartInfo.functions.getDateHistogramState(this.props.chartnumber);
        if (dateHistogramInterval != undefined && dateHistogramInterval != null && dateHistogramInterval != "reset") {
            ChartInfo.functions.setDateHistogramStatus(this.props.chartnumber, true);
        }
        var datehistogramstate = data['datehistogramstate'];
        if (dateHistogramInterval == undefined && datehistogramstate != undefined) {
            if (datehistogramstate == "reset" || ChartInfo.functions.getDateHistogramState(this.props.chartnumber) == "reset") {
                dateHistogramInterval = undefined;
            }
            else {
                ChartInfo.functions.setDateHistogramStatus(this.props.chartnumber, true);
                ChartInfo.functions.setDateHistogramXaxisData(data['xAxis']);
                /*ChartInfo.getFilters(false);*/
                dateHistogramInterval = datehistogramstate;
            }
        } 

        if (this.state.filterObject != null && this.state.filterObject != undefined && this.state.filterObject.length != 0) {
            ChartInfo.functions.setMainFilerObject(this.state.filterObject);
            //	 data=this.filterObject;
            // globalFilterArray=globalFilterArray.concat(this.filterObject);
        } else {
            ChartInfo.functions.setMainFilerObject(data);
        }
        var drilldownChartnumber = ChartInfo.functions.getDrillDownChartNumber();
        if (drilldownChartnumber != undefined && sessionStorage.getItem("drilldown" + drilldownChartnumber) != undefined) {
            _.each(JSON.parse(sessionStorage.getItem("drilldown" + drilldownChartnumber)), (drilldown, index) => {
                if (drilldown.isDrillDownAll) {
                    globalFilterArray = JSON.parse(JSON.stringify(globalFilterArray)).concat(JSON.parse(sessionStorage.getItem("drilldown" + drilldownChartnumber)));
                }
            });
        }
        if (data["drillDownFilter"] != undefined) {
            if (data["drillDownFilter"].length > 0) {
                _.each(data["drillDownFilter"], function (value, index) {
                    globalFilterArray.push(value);
                });
            }
        }
        //				var drillDownFilter=data["drillDownFilter"];
        //				if(drillDownFilter==undefined || drillDownFilter.length==0){
        //					globalFilterArray= ChartInfo.getFilterArray();
        //				}
        if (ChartInfo.functions.getFilterArray() != undefined && ChartInfo.functions.getFilterArray().length!=undefined && ChartInfo.functions.getFilterArray().length!=0) {
            globalFilterArray = globalFilterArray.concat(ChartInfo.functions.getFilterArray());
        }
        var customfieldFilterObject = data['customfieldFilterObject'];
        if (customfieldFilterObject != undefined) {
            _.each(customfieldFilterObject, function (custfield) {
                globalFilterArray = $.grep(globalFilterArray, function (value) {
                    if (custfield.customfield != undefined) {
                        if (custfield.customfield.parameter == value.data) {
                            return false;
                        } else {
                            return true;
                        }
                    } else {
                        return true;
                    }
                });
            });

            ChartInfo.functions.setCustomFieldsFilterObject(this.state.chartnumber, customfieldFilterObject);
            globalFilterArray = globalFilterArray.concat(customfieldFilterObject);
        }
        var legendFilter = data["legendfilter"];
        if (legendFilter !== undefined && legendFilter.length != 0) {
            globalFilterArray = JSON.parse(JSON.stringify(globalFilterArray)).concat(legendFilter);
        }
        if (data['drillDownFilter'] != undefined && data['drillDownFilter'].length != 0) {
            ChartInfo.functions.setSavedDrilldownAll(data['drillDownFilter']);
        }
        var customParameterFilterArray = ChartInfo.functions.getCustomParametersFilters(applyattributes);

        //In Filter Ignore Disabled Parameters
        if (customParameterFilterArray != undefined && customParameterFilterArray != "" && customParameterFilterArray.length > 0) {
            customParameterFilterArray = _.filter(customParameterFilterArray, function (customParameterFilter) {
                return !customParameterFilter['filterIgnore'];
            });
        }

        var isExcludeNulls = ChartInfo.functions.getIsExcludeNulls();
        var legendfilter = [];
        ChartInfo.functions.setLegendFilterobjForAllCharts(legendfilter);
        ChartInfo.functions.setLegendFilterobj(legendfilter, this.state.chartnumber);
        sessionStorage.removeItem("drilldown" + this.state.chartnumber);
        sessionStorage.removeItem("drilldowndata" + this.state.chartnumber);
        $('#spinnerchart' + this.state.chartnumber).show()
        var convertedValues = null;
        if (convertedValues != null) {
            var customhierarchy = ChartInfo.functions.getcustomhierarchy();
            _.each(customhierarchy, function (value, index) {
                _.each(value.hierarchelist, function (value1, index1) {
                    if (convertedValues.hasOwnProperty(value1.columnName)) {
                        value1["displayName"] = convertedValues[value1.columnName];
                    }
                });
            });
            ChartInfo.functions.setcustomhierarchy(customhierarchy);
            var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(this.state.chartnumber));
            _.each(chartDetails['xAxis'], function (value, index) {
                if (convertedValues.hasOwnProperty(value.columnName)) {
                    value["displayName"] = convertedValues[value.columnName];
                }
            });
            _.each(chartDetails['yAxis'], function (value, index) {
                if (convertedValues.hasOwnProperty(value.columnName)) {
                    value["displayName"] = convertedValues[value.columnName];
                }
            });
            _.each(chartDetails['tooltip'], function (value, index) {
                if (convertedValues.hasOwnProperty(value.columnName)) {
                    value["displayName"] = convertedValues[value.columnName];
                }
            });
            //TODO Update zAxis in chartInfo
            if (chartDetails['legend'] != undefined) {
                var columnName = chartDetails['legend']["columnName"];
                if (convertedValues.hasOwnProperty(chartDetails['legend'].columnName)) {
                    chartDetails['legend']["displayName"] = convertedValues[columnName];
                }
            }
            //TODO Update combination chart display name
            if (chartDetails['combinationchartslist'] != undefined) {
                _.each(chartDetails['combinationchartslist'], function (value, index) {
                    if (convertedValues.hasOwnProperty(value.columnName)) {
                        value["displayName"] = convertedValues[value.columnName];
                    }
                });
            }
            // for updating summary table
            _.each(chartDetails["summarytablecolumns"], function (xAxisObject) {
                if (xAxisObject.columnName != undefined && convertedValues[xAxisObject.columnName] != undefined) {
                    xAxisObject.displayName = convertedValues[xAxisObject.columnName];
                }
            });
            if (chartDetails["summarytablegroupbys"] != undefined && convertedValues[chartDetails["summarytablegroupbys"].columnName] != undefined) {
                chartDetails["summarytablegroupbys"].displayName = convertedValues[chartDetails["summarytablegroupbys"].columnName];
            }
            _.each(chartDetails["summarytablevalues"], function (yaxisObject) {
                if (convertedValues[yaxisObject.columnName] != undefined) {
                    yaxisObject.displayName = convertedValues[yaxisObject.columnName];
                }
            });
            //for updating pivot table
            _.each(chartDetails["rows"], function (row, i) {
                if (convertedValues[row.columnName] != undefined) {
                    row.displayName = convertedValues[row.columnName];
                }
            });
            _.each(chartDetails["columns"], function (xAxisObject, i) {
                if (convertedValues[xAxisObject.columnName] != undefined) {
                    xAxisObject.displayName = convertedValues[xAxisObject.columnName];
                }
            });
            _.each(chartDetails["values"], function (yaxisObject) {
                if (convertedValues[yaxisObject.columnName] != undefined) {
                    yaxisObject.displayName = convertedValues[yaxisObject.columnName];
                }
            });
            ChartInfo.functions.updateChartDetails(this.state.chartnumber, JSON.stringify(chartDetails));
        }
        convertedValues = JSON.parse(sessionStorage.getItem("renameMeasuresorDimensions"));
        $('.dobypagination').hide();
        $("#singlesavedchartdiv").empty();
        var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(this.state.chartnumber));
        var that = this;
        if (globalFilterArray != undefined && globalFilterArray.length > 0) {
            $.each(this.state.features, function (index, featuresModule) {
                if (featuresModule.moduleName == 'story_board') {
                    $.each(featuresModule.features, function (index, feature) {
                        if (feature.featureName == 'viewreport_filters' && feature.allow == false && globalFilterArray.length == 0 && that.state.viewcollection.getSharedFilters(that.state.viewreportmodel) != undefined
                            && that.state.viewcollection.getSharedFilters(that.state.viewreportmodel).length == 0) {
                            globalFilterArray = that.state.viewcollection.getFilterObject(that.state.chartnumber, that.state.viewreportmodel);
                        }
                    });
                }
            });
        }
        if (chartDetails["filtersadded"] != true && that.state.viewcollection.getSharedFilters(that.state.viewreportmodel) != undefined && that.state.viewcollection.getSharedFilters(that.state.viewreportmodel).length > 0) {
            globalFilterArray = globalFilterArray.concat(that.state.viewcollection.getFilterObject(that.state.chartnumber, that.state.viewreportmodel));
            customParameterFilterArray = that.state.viewcollection.getCustomParameterFilterObject(that.state.chartnumber, that.state.viewreportmodel);
        }
        if (chartDetails["filtersadded"] != true) {
            customParameterFilterArray = that.state.viewcollection.getCustomParameterFilterObject(that.state.chartnumber, that.state.viewreportmodel);
        }
        var InstanceExcludeFilterData = JSON.parse(sessionStorage.getItem("instantExcludeFilters"));
        if (InstanceExcludeFilterData != null && InstanceExcludeFilterData != "null") {
            if (InstanceExcludeFilterData != undefined) {
                _.each(InstanceExcludeFilterData, function (excludeData) {
                    globalFilterArray = $.grep(globalFilterArray, function (value) {
                        if (excludeData.excludeFilterData == "excludeFilterData") {
                            if (excludeData.data == value.data) {
                                return false;
                            } else {
                                return true;
                            }
                        } else {
                            return true;
                        }
                    });
                });
                globalFilterArray = globalFilterArray.concat(InstanceExcludeFilterData);
            }
        }
        var InstanceIncludeFilterData = JSON.parse(sessionStorage.getItem("instantIncludeFilters"));
        if (InstanceIncludeFilterData != null && InstanceIncludeFilterData != "null") {
            if (InstanceIncludeFilterData != undefined) {
                _.each(InstanceIncludeFilterData, function (excludeData) {
                    globalFilterArray = $.grep(globalFilterArray, function (value) {
                        if (excludeData.isdrilldownfilter == true) {
                            if (excludeData.data == value.data) {
                                return false;
                            } else {
                                return true;
                            }
                        } else {
                            return true;
                        }
                    });
                });
                globalFilterArray = globalFilterArray.concat(InstanceIncludeFilterData);
            }
        }
        var instantExcludeCustomFilters = JSON.parse(sessionStorage.getItem("instantExcludeCustomFilters"));
        if (instantExcludeCustomFilters != null && instantExcludeCustomFilters != "null") {
            _.each(instantExcludeCustomFilters, function (data, i) {
                if (data != undefined) {
                    globalFilterArray.push(data);
                }
            });
        }
        if (this.state.viewcollection.getSharedFilters(this.state.viewreportmodel) != undefined && this.state.viewcollection.getSharedFilters(this.state.viewreportmodel).length > 2) {
            var parsedSharedFilters = JSON.parse(this.state.viewcollection.getSharedFilters(that.state.viewreportmodel));
            var tempfilterArray = [];
            if (globalFilterArray.length > 0) {
                _.each(globalFilterArray, function (value, index) {
                    if (value.excludeFilterData != "excludeFilterData") {
                        parsedSharedFilters = $.grep(parsedSharedFilters, function (e) { return e.columnname != value.columnname });
                    }
                });
            }
            globalFilterArray = globalFilterArray.concat(parsedSharedFilters);
        }
        if(applyattributes === "applyattributes" && dateHistogramInterval === undefined){
            ChartInfo.functions.setDateHistogramState(undefined,that.state.chartnumber);
            ChartInfo.functions.setDateHistogramStatus(that.state.chartnumber,false);
        }
        /*if(ChartInfo.getEmbedFilterArray()!=undefined && ChartInfo.getEmbedFilterArray().length>0){
            globalFilterArray=globalFilterArray.concat(ChartInfo.getEmbedFilterArray());
        }*/
        //globalFilterArray=globalFilterArray.concat(ChartInfo.getEmbedFilterArray());
        var mainFilter = ChartInfo.functions.getFilterArray();
        if (this.state.viewcollection.getSharedFilters() != undefined) {
            var appliedSharedFilters = JSON.parse(this.state.viewcollection.getSharedFilters());
        }
        _.each(mainFilter, function (filter1, index) {
            if (filter1 != undefined) {
                var that = this
                _.each(appliedSharedFilters, function (filter, index1) {
                    var sharedfiltercolumndisplayname = filter.columndisplayname.replace(/([;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "");
                    if (filter1.columndisplayname == sharedfiltercolumndisplayname) {
                        if (filter.dataType != undefined) {
                            var filtercolumndisplayname = filter.columndisplayname.replace(/([;?&,%#.+*~\'"!^$[\]()<=>`|\/@])/g, "");
                            if (filter.dataType == "number") {
                                var fromRange = filter.fromRange,
                                    toRange = filter.toRange,
                                    customRange = filter.customfiltervalue;
                                var mainfromRange = filter1.fromRange,
                                    maintoRange = filter1.toRange,
                                    maincustomRange = filter1.customfiltervalue;
                                if (fromRange == undefined && toRange == undefined) {
                                    fromRange = filter.filtergreaterthanvalue;
                                    toRange = filter.filterlessthanvalue;
                                }
                                if (fromRange > maintoRange || toRange < maintoRange) {
                                    //appliedSharedFilters.splice(index1,1)
                                    globalFilterArray = appliedSharedFilters;
                                    console.log(globalFilterArray);
                                }
                            }
                            if (filter.dataType == "string") {
                                var sharedYes = false;
                                if (filter.customfield == undefined) {
                                    var filtereddatatext = filter["data"].replace(/\@@/g, ',');
                                    var newfiltereddatatext = filtereddatatext.split(",");
                                    var filtereddatatext1 = filter1["data"].replace(/\@@/g, ',');
                                    var newfiltereddatatext1 = filtereddatatext1.split(",");

                                    _.each(newfiltereddatatext, function (shared, i) {
                                        var filtereddatatext1 = filter1["data"].replace(/\@@/g, ',');
                                        if (filtereddatatext1.includes(shared)) {
                                            sharedYes = true
                                        }
                                    });
                                    if (sharedYes == false) {
                                        globalFilterArray = appliedSharedFilters;
                                        console.log(globalFilterArray);
                                    }
                                } else {
                                    var filtereddatatext = filter.customfield.sqlcondition.sqldata.replace(/\@@/g, ',');
                                    var newfiltereddatatext = filtereddatatext.split(",");
                                    var filtereddatatext1 = filter1.customfield.sqlcondition.sqldata.replace(/\@@/g, ',');
                                    var newfiltereddatatext1 = filtereddatatext1.split(",");
                                }
                            }
                            else if (filter.dataType == "date") {
                                var fromDateRange = filter.fromRange,
                                    toDateRange = filter.toRange,
                                    dateRange = filter.daterange;
                                var mainfromRange = filter1.fromRange,
                                    maintoRange = filter1.toRange,
                                    maindateRange = filter1.daterange;
                                var dates = $('.daterange-center[displayname="' + filter.columndisplayname + '"] span').text();
                                var sharedFrom = Date.parse(fromDateRange);
                                var mainTo = Date.parse(maintoRange);
                                var sharedTo = Date.parse(toDateRange);
                                var mainFrom = Date.parse(mainfromRange);
                                if (sharedFrom > mainTo || mainFrom > sharedTo) {
                                    globalFilterArray = appliedSharedFilters;
                                }
                                //if(customRange== undefined || customRange===""){
                                //$('.filteritem-container .acc-content[data-id="'+filtercolumndisplayname+'"] .filteredvalues').text(dates).attr("title",dates);
                                //}
                            }

                        }
                    }
                })
            }
        })

        var xAxisData = "";
        var yAxisData = "";
        var toolTipData = [];
        var combinationchartsData = [];
        var zAxisData = [];
        var customSolidGaugeData = [];
        var xAxisDataType = "";
        var yAxisDataType = "";
        var xAxisDisplayName = "";
        var yAxisDisplayName = "";
        var zAxisDisplayName = "";
        var toolTipDisplayName = "";
        var legendcolumnDisplayName = "";
        var rangefieldMap = {};
        var xaxisAggregation = "";
        var sortingColumn = chartDetails["sortingColumn"];
        var sortingColumnAggregation = chartDetails["sortingColumnAggregation"];
        var rankingColumn = chartDetails["rankingColumn"];
        var rankingColumnAggregation = chartDetails["rankingColumnAggregation"];
        var aggregation = "";
        var xAxisIdentity = "";
        var percentileValue = "";
        var sortType = chartDetails["sortType"];
        var orderType = chartDetails["orderType"];
        var colLimit = chartDetails["colLimit"];
        var metricdatetype = chartDetails["metricdatetype"];
        this.state.metricprefix = chartDetails["metricprefix"];
        this.state.metricsuffix = chartDetails["metricsuffix"];
        this.state.metricfont = chartDetails["metricfont"];
        this.state.solidgaugemin = chartDetails["solidgaugemin"];
        this.state.solidgaugemax = chartDetails["solidgaugemax"];
        var metricdatefield = chartDetails["metricdatefield"];
        this.trendLine = chartDetails["trendLine"];
        this.periodsvalue = chartDetails["periodsvalue"];
        var drillDownFilter = chartDetails["drillDownFilter"];


        _.each(chartDetails["yAxis"], function (val) {
            yAxisData += encodeURIComponent(val.columnName) + ",";
            yAxisDataType += val.dataType + ",";
            yAxisDisplayName += val.displayName + ",";
            if (val.dataType == "customaggregatedfield") {
                aggregation += "customaggregatedfield,";
            } else if (val.dataType == "custommeasurehierarchy") {
                aggregation += "custommeasurehierarchy,";
            } else {
                aggregation += val.aggregation.replace(/[^A-Z0-9]/ig, "") + ",";
            }
            percentileValue += val.percentileValue || "25";
            percentileValue += ",";
        });
        yAxisData = yAxisData.substring(0, yAxisData.length - 1);
        yAxisDataType = yAxisDataType.substring(0, yAxisDataType.length - 1);
        yAxisDisplayName = yAxisDisplayName.substring(0, yAxisDisplayName.length - 1);
        aggregation = aggregation.substring(0, aggregation.length - 1);
        percentileValue = percentileValue.substring(0, percentileValue.length - 1);
        _.each(chartDetails["sorting"], function (val) {
            // sortingData += val.columnName + ",";
            sortType = val.sortType;
        });
        if ($('.doby-offcanvas-columnlist').hasClass("show")) {
            $('.doby-offcanvas-columnlist').toggleClass("show");
        }

        _.each(chartDetails["tooltip"], function (val) {
            var toolTipObject = {};
            toolTipObject["columnName"] = val.columnName;
            toolTipObject["displayName"] = val.displayName;
            toolTipDisplayName += val.displayName + ",";
            toolTipObject["aggType"] = val.aggregation.replace(/[^A-Z0-9]/ig, "");
            toolTipObject["percentileValue"] = val.percentileValue;
            toolTipData.push(toolTipObject);
        });
        _.each(chartDetails["combinationchartslist"], function (val) {
            var combinationchartslist = {};
            combinationchartslist["columnName"] = val.columnName;
            combinationchartslist["chartType"] = val.selectedChartType;
            combinationchartslist["chartaxisposintion"] = val.chartaxisposintion;
            combinationchartsData.push(combinationchartslist);
        });
        _.each(chartDetails["customSolidGauge"], function (val) {
            var customSolidGaugeObj = {};
            customSolidGaugeObj["columnName"] = val.columnName;
            customSolidGaugeObj["aggType"] = val.aggregation.replace(/[^A-Z0-9]/ig, "");
            customSolidGaugeObj["percentileValue"] = val.percentileValue;
            customSolidGaugeData.push(customSolidGaugeObj);
        });
        _.each(chartDetails["zAxis"], function (val) {
            var zAxisObject = {};
            zAxisObject["columnName"] = val.columnName;
            zAxisDisplayName += val.displayName + ",";
            zAxisObject["aggType"] = val.aggregation.replace(/[^A-Z0-9]/ig, "");
            zAxisObject["percentileValue"] = val.percentileValue;
            zAxisData.push(zAxisObject);
        });


        if (dateHistogramInterval != undefined && dateHistogramInterval != null && dateHistogramInterval != "reset") {
            chartDetails["xAxis"] = ChartInfo.functions.getDateHistogramXaxisData();
            $(".maxanim1[data-num='" + this.state.chartnumber + "']").find('.saved-data-buttons').hide();
            $(".showtrending[data-num='" + this.state.chartnumber + "']").hide();
            $('.viewchartfromchartdata').hide();
        }
        else {
            $(".maxanim1[data-num='" + this.state.chartnumber + "']").find('.saved-data-buttons').show();
        }
        _.each(chartDetails["xAxis"], function (val1) {
            var temp = $.grep(ChartInfo.functions.getcustomhierarchy(), function (e) { return e.name == val1.columnName });
            var ramgeArray = $.grep(ChartInfo.functions.getRange(), function (e) { return e.name == val1.columnName });
            if (temp.length > 0) {
                xAxisData += encodeURIComponent(temp[0]["hierarchelist"][0].columnName) + ",";
                xAxisDataType += temp[0]["hierarchelist"][0].dataType + ",";
                xAxisDisplayName += temp[0]["hierarchelist"][0].displayName + ",";
            } else if (ramgeArray.length > 0) {
                xAxisData += encodeURIComponent(ramgeArray[0].name) + ",";
                rangefieldMap[ramgeArray[0].name] = ramgeArray[0];
                xaxisAggregation = "range";
            } else {
                xAxisData += encodeURIComponent(val1.columnName) + ",";
                xAxisDataType += val1.dataType + ",";
                xAxisDisplayName += val1.displayName + ",";
                xAxisIdentity += val1.xaxisidentity + ",";
            }
        });
        xAxisData = xAxisData.substring(0, xAxisData.length - 1);
        xAxisDataType = xAxisDataType.substring(0, xAxisDataType.length - 1);
        xAxisDisplayName = xAxisDisplayName.substring(0, xAxisDisplayName.length - 1);
        xAxisIdentity = xAxisIdentity.substring(0, xAxisIdentity.length - 1)
        var legendSeries = chartDetails["legend"];

        var that = this;
        var chartType = chartDetails["chartType"];
        if (chartDetails["oldcharttype"] == 'heatmap') {
            chartType = chartDetails["oldcharttype"];
        } else {
            chartType = chartDetails["chartType"];
        }
        var legendcolumnName = "";
        var legendDataType = "";
        if (legendSeries == undefined || legendSeries == null) {
            legendcolumnName = "";
            legendDataType = "";
            legendcolumnDisplayName = "";
        }
        else {
            legendcolumnName = legendSeries.columnName;
            legendcolumnDisplayName = legendSeries.displayName;
            legendDataType = legendSeries.dataType;
            var ramgeArray = $.grep(ChartInfo.functions.getRange(), function (e) { return e.name == legendSeries.columnName });
            if (ramgeArray.length > 0) {
                rangefieldMap[ramgeArray[0].name] = ramgeArray[0];
                xaxisAggregation = "range";
            }
        }
        if (chartType == "sankey" && (legendSeries == undefined || legendSeries == null)) {
            legendcolumnName = chartDetails["yAxis"][0].columnName;
            legendcolumnDisplayName = chartDetails["yAxis"][0].displayName;
            legendDataType = chartDetails["yAxis"][0].dataType;
            legendcolumnDisplayName = chartDetails["yAxis"][0].displayName;
        }
        if (chartType == 'boxplot' && (legendSeries == undefined || legendSeries == null)) {
            xAxisData = legendcolumnName;
            if (chartDetails["xAxis"][0] == undefined) {
                legendcolumnName = "Measure Names";
                legendcolumnDisplayName = "Measure Names";
                legendDataType = "String";
            }
            else {
                legendcolumnName = chartDetails["xAxis"][0].columnName;
                legendcolumnDisplayName = chartDetails["xAxis"][0].displayName;
                if (chartDetails["xAxis"][0] != undefined && chartDetails["xAxis"][0].dataType) {
                    legendDataType = chartDetails["xAxis"][0].dataType;
                } else {
                    legendDataType = xAxisDataType;
                }
            }

        }
        var that = this;
        var timeZone;
        var TimeZoneSettingsValueForstoryboard = ChartInfo.functions.getTimeZoneSettings("-1");

        var TimeZoneSettingsValueForEachChart = ChartInfo.functions.getTimeZoneSettings(this.state.chartnumber);
        if (TimeZoneSettingsValueForEachChart == undefined) {
            if (TimeZoneSettingsValueForstoryboard == "localtimezone") {
                timeZone = jstz.determine().name();
                that.state.timeZone = timeZone;
            } else {
                that.state.timeZone = undefined;
            }
        } else {
            if (TimeZoneSettingsValueForEachChart == "localtimezone") {
                timeZone = jstz.determine().name();
                that.state.timeZone = timeZone;
            } else {
                that.state.timeZone = undefined;
            }
        }
        if (this.state.multifact == "false") {
            SendRequest({
                url: "gettotaldatacount",
                queryString: "?reportId=" + this.state.reportId + "&&isPurgedReport=" + this.isPurgedReport + "&&timeZone=" + this.state.timeZone + "&&isImportedReport=" + this.isImportedReport+"&isPublished="+ChartInfo.functions.getisPublished(),
                sucessFunction: (response) => {
                    $(".spinner").hide();
                    //$(".gridspinner").hide();
                    var currentState = sessionStorage.getItem("state");
                    if (currentState != undefined && (currentState == 'viewreport' || currentState == 'viewstorybook')) {
                        if (response.hasOwnProperty('error')) {
                            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                            $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                            $('.birdmessagebuttons').removeClass('show');
                            $('.birdmessagebuttons').addClass('hide');
                            $('.birdmessage-center').addClass("message-top-in");
                            $('.details').removeClass('hide');
                            $('.birdmessage h2').empty().append('Error : ');
                            $('.birdmessage h2').append('<span class="errorcode">')
                            $('.birdmessage .errorcode').empty().append(response.errorCode);
                            $('.birdmessage-info').empty().text(response.error);
                            $('.details').addClass('show');
                            $('.messagedetails xmp').empty().append(response.errorTrace);
                            // ChartUtils.unbindEvents(that.state.chartnumber);
                            this.state.chartData = response;
                            //$('#main').prepend('<div class="alert alert-danger birderror alert-dismissable affix-top" style="margin:0 auto;width: 60%;top: 44px;position: fixed;z-index: 1;left: 20%;">'+response.error+'</div>');
                            setTimeout(function () {
                                $('.birderror').remove();
                            }, 3000);
                        }
                        //that.broker.trigger("showfilterdailouge", undefined, false);
                        var Props = {};
                        Props["target"] = undefined;
                        Props["showpopup"] = false;
                        PubSub.publish("showfilterdailouge", Props);

                        that.state.datacount = response.size;
                        $(".publishinfo").text("Dataset Count: " + response.size);
                        if (response.lastexecutedtimestamp != undefined && response.lastexecutedtimestamp.length > 0) {
                            $(".lastexecutedtimestamp").text("Last Data Sync: " + response.lastexecutedtimestamp);
                        }
                        ChartInfo.functions.setDataSetCount(response.size);
                    }
                },
                rejectFunction: () => { },
                headers: {
                    method: "get",
                    headers: new Headers({
                        "Content-Type": "application/json"
                    })
                }
            });
        } else {
            //that.broker.trigger("showfilterdailouge", undefined, false);

            function executeFilters() {
                var filteraccordianlength = $("#filteraccordion").length;
                if (filteraccordianlength == 1) {
                    setTimeout(function () {
                        var Props = {};
                        Props["target"] = undefined;
                        Props["showpopup"] = false;
                        PubSub.publish("showfilterdailouge", Props);
                        //that.broker.trigger("showfilterdailouge", undefined, false);
                    }, 100);
                } else {
                    setTimeout(function () {
                        executeFilters();
                    }, 200);
                }
            }
            setTimeout(function () { executeFilters(); }, 100);

        }
        var aggregatedCustomField = ChartInfo.functions.getCustomMeasureField();
        var customFields = sessionStorage.getItem("customFields");
        var temp = JSON.parse(customFields);
        var res;
        _.each(temp, function (customfields) {
            _.each(customfields.parametrFieldsArray, function (value) {
                var customparameter = ChartInfo.functions.getCustomParameter();
                if (customParameterFilterArray != undefined && customParameterFilterArray.length != 0) {
                    customparameter = customParameterFilterArray;
                }
                res = $.grep(customparameter, function (e) {
                    return e.parametername == value;
                })
                var formula = customfields.formula;
                var scriptcondition = customfields.scriptcondition;
                var fields = customfields.fields;
                var sqlformula = customfields.sqlformula;
                if (res.length != 0) {
                    customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
                    customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
                    customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
                    customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
                } else {
                    _.each(ChartInfo.functions.getCustomParameter(), function (values) {
                        if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
                            customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                            customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                            customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
                            customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                        }
                    });
                }
            });
        });
        customFields = JSON.stringify(temp);
        var TimeZoneSettingsValue = ChartInfo.functions.getTimeZoneSettings(this.state.chartnumber);
        var timeZone;
        if (TimeZoneSettingsValue == "localtimezone") {
            timeZone = jstz.determine().name();
            this.state.timeZone = timeZone;
        } else {
            this.state.timeZone = undefined;
        }
        if (this.state.chartnumber, JSON.stringify(chartDetails) != undefined) {
            var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(this.state.chartnumber, JSON.stringify(chartDetails)))
            if(ChartInfo.functions.getSummaryTableDateHistogramState(this.state.chartnumber) != undefined){
            chartDetails['summaryTableDateHistogramState'] = ChartInfo.functions.getSummaryTableDateHistogramState(this.state.chartnumber);
            }
            var trendLine = chartDetails["trendLine"];
            if (trendLine != undefined) {
                if (trendLine != "linear" || trendLine != "SMA" || trendLine != "EMA") {
                    calculation = trendLine;
                }
            }
            ChartInfo.functions.updateChartDetails(this.state.chartnumber, JSON.stringify(chartDetails));
        }
        _.each(ChartInfo.functions.getRange(), function (e) {
            if (xAxisData == encodeURIComponent(e.name)) {
                xAxisDataType = "customrange";
            }
        })
        if (!ChartUtils.isTrendSupport(chartType)) {
            calculation = undefined;
        }
        //globalFilterArray=	[{"dataType":"string","tablename":"","columndisplayname":"WorkOrderID","columnname":"WorkOrderID","data":"73958"}]		
        //[{"dataType":"string","tablename":"","columndisplayname":"WorkOrderID","columnname":"WorkOrderID","data":"73958"}]
        var custommeasurehierarachyarray = ChartInfo.functions.getMeasureHierarchy();
        var referencelineobj;
        if (chartType != "polar" && chartType != "windrose" && chartType != "spiderweb") {
            referencelineobj = ChartInfo.functions.getreferenceLineValues(this.state.chartnumber);
        }
        this.viewreportbodymodal = new FormData();
        this.viewreportbodymodal.set("aggregation", aggregation);
        if (xAxisIdentity != undefined) {
            this.viewreportbodymodal.set("xAxisIdentity", xAxisIdentity);
        } else {
            this.viewreportbodymodal.set("xAxisIdentity", "");
        }
        if (xAxisData == undefined || xAxisData == "") {
            this.viewreportbodymodal.set("xaxis", "Measure Names");
        } else {
            this.viewreportbodymodal.set("xaxis", xAxisData);
        }

        globalFilterArray = ChartInfo.functions.generateFilterWithEmbededFilters(globalFilterArray, this.state.viewcollection.getSharedFilters(this.state.viewreportmodel));
        this.filterArrayvalues = globalFilterArray;
        ChartInfo.functions.setTrendType(calculation);
        this.viewreportbodymodal.set("xaxisdataType", xAxisDataType);
        this.viewreportbodymodal.set("yaxis", yAxisData);
        this.viewreportbodymodal.set("legend", legendcolumnName);
        this.viewreportbodymodal.set("legendDataType", legendDataType);
        this.viewreportbodymodal.set("datamodelId", this.state.datamodelId);
        this.viewreportbodymodal.set("script", customFields);
        this.viewreportbodymodal.set("filterObject", JSON.stringify(globalFilterArray));
        this.viewreportbodymodal.set("reportId", this.state.reportId);
        this.viewreportbodymodal.set("sortingColumn", (sortingColumn != undefined) ? sortingColumn : "");
        this.viewreportbodymodal.set("sortType", sortType!=undefined? sortType:"");
        this.viewreportbodymodal.set("sortingColumnAggregation", (sortingColumnAggregation != undefined) ? sortingColumnAggregation : "");
        this.viewreportbodymodal.set("rankingColumn", rankingColumn!=undefined? rankingColumn:"");
        this.viewreportbodymodal.set("orderType", orderType);
        this.viewreportbodymodal.set("rankingColumnAggregation", rankingColumnAggregation!=undefined? rankingColumnAggregation:"");
        this.viewreportbodymodal.set("colLimit", colLimit);
        this.viewreportbodymodal.set("chartType", chartType);
        this.viewreportbodymodal.set("percentileValue", percentileValue);
        this.viewreportbodymodal.set("isPublished", ChartInfo.functions.getisPublished());
        this.viewreportbodymodal.set("toolTips", JSON.stringify(toolTipData));
        this.viewreportbodymodal.set("zAxis", JSON.stringify(zAxisData));
        this.viewreportbodymodal.set("customSolidGaugeField", JSON.stringify(customSolidGaugeData));
        this.viewreportbodymodal.set("calculation", calculation!=undefined? calculation:"");
        this.viewreportbodymodal.set("rangefieldMap", JSON.stringify(rangefieldMap));
        this.viewreportbodymodal.set("customaggregatedscriptfield", JSON.stringify(aggregatedCustomField));
        this.viewreportbodymodal.set("combinationchartsData", JSON.stringify(combinationchartsData));
        var comparedaterangecharttpes =  ["bar", "stackedbar", "line", "spline", "area", "stackedarea"];
        let tempYaxis = chartDetails['yAxis'];
        let tempXAxis = chartDetails['xAxis'];
        let finalCompareDateObj = {};
        let compareDateRangeObjtemp = chartDetails["compareDateRangeObj"];
        if(tempYaxis.length>1&&compareDateRangeObjtemp!=null&&compareDateRangeObjtemp["0"]!=null){
            finalCompareDateObj = this.getMutiyComapreDateObj(finalCompareDateObj,chartDetails["compareDateRangeObj"],chartDetails['yAxis']);
         }else{
             finalCompareDateObj =  chartDetails["compareDateRangeObj"];
         }
         if(compareDateRangeObjtemp!=null&&compareDateRangeObjtemp["0"]!=null){
            let yjsObj = compareDateRangeObjtemp["0"];
            let customhierarchy = ChartInfo.functions.getcustomhierarchy();
            let key = Object.keys(yjsObj);
            let xObj = tempXAxis.length>0?tempXAxis[0]:{};
            if (yjsObj != null&&!_.isEmpty(xObj)){
                var ycompareDetailsObj = yjsObj[key];
                let datehistogramstateval = ycompareDetailsObj["compareDateHistogramInterval"] ;
                if(datehistogramstateval==""&&xObj.dataType.toLowerCase()=="date"){
                    finalCompareDateObj = null; 
                }
                if (datehistogramstateval==""&&xObj.dataType.toLowerCase()=='customhierarchy') {
                    let hierarchy = $.grep(customhierarchy, function (e) { return e.name == xObj.displayName }); 
                    if(hierarchy[0].hierarchelist[0].dataType =="date"){
                        finalCompareDateObj = null; 
                    }
                }
            }
         }
        this.viewreportbodymodal.set("compareDateRangeJson", JSON.stringify(comparedaterangecharttpes.indexOf(chartType)!=-1?finalCompareDateObj:null));        
        this.viewreportbodymodal.set("custommeasurehierarchyarray", JSON.stringify(custommeasurehierarachyarray));
        this.viewreportbodymodal.set("isExcludeNulls", isExcludeNulls);
        this.viewreportbodymodal.set("timeZone", timeZone);
        this.viewreportbodymodal.set("xAxisDisplayName", xAxisDisplayName);
        this.viewreportbodymodal.set("yAxisDisplayName", yAxisDisplayName);
        this.viewreportbodymodal.set("zAxisDisplayName", zAxisDisplayName);
        this.viewreportbodymodal.set("toolTipDisplayName", toolTipDisplayName);
        this.viewreportbodymodal.set("legendcolumnDisplayName", legendcolumnDisplayName);
        this.viewreportbodymodal.set("referencelineobj", referencelineobj != undefined ? JSON.stringify(referencelineobj) : "");
        this.viewreportbodymodal.set("yAxisDataType", yAxisDataType);
        if (yAxisDataType == "custommeasurehierarchy") {
            this.viewreportbodymodal.set("yAxisDataType", "custommeasurehierarchy");
        } else if (yAxisDataType == "customaggregatedfield") {
            this.viewreportbodymodal.set("yAxisDataType", "customaggregatedfield");
        }
        if (dateHistogramInterval != undefined && dateHistogramInterval != null && dateHistogramInterval != 'reset' && xAxisDataType == "date") {
            this.viewreportbodymodal.set("xaxisAggregation", "dateHistogram");
            this.viewreportbodymodal.set("dateHistogramInterval", dateHistogramInterval);
        } else if (xaxisAggregation == "range") {
            this.viewreportbodymodal.set("xaxisAggregation", "range");
        } else {
            this.viewreportbodymodal.set("xaxisAggregation", "group");
        }
        if (chartType === "summarytable") {
            setTimeout(() => {
                that.redrawChart(chartDetails);
                ChartUtils.modifyChartHeightOnResize(this.state.chartnumber, "max");
            }, 100);
            $('.singlereport_summarymodal[data-num=' + that.state.chartnumber + ']').removeClass('hide');
            $(".maxanim1[data-num='" + that.state.chartnumber + "']").find('.saved-data-buttons').hide();
            $('.btn-toolbar').show();
        } else {
            $('.breadcrumb[data-num=' + that.state.chartnumber + ']').remove();
            $('.singlereport_summarymodal[data-num=' + that.state.chartnumber + ']').addClass('hide');
            $('.btn-toolbar').hide();
        }
        this.addSavedDrilldownBreadCrumbs(this.state.chartnumber)
        if (chartType == 'predictionchart' || chartType == "h2o_variableimportance" || chartType == "h2o_scorehistory" || chartType == "h2o_datatable") {
            //this.predictionChart(that.state.chartnumber);
            //				that.redrawChart(chartDetails);
            return true;
        }

        if ((chartType === "metric" || chartType === "solidgauge" || chartType === "fullcirclegauge") && chartType != "textchart" && chartType != "pivot") {
            var Bodydata = new FormData();
            Bodydata.set("aggregation", aggregation);
            Bodydata.set("yaxis", yAxisData);
            Bodydata.set("datamodelId", that.viewreportbodymodal.get("datamodelId"));
            Bodydata.set("reportId", that.viewreportbodymodal.get("reportId"));
            Bodydata.set("filterObject", JSON.stringify(globalFilterArray));
            Bodydata.set("orderType", orderType);
            Bodydata.set("chartType", chartType);
            Bodydata.set("metricdatefield", metricdatefield != undefined ? metricdatefield : "");
            Bodydata.set("metrictype", metricdatetype);
            Bodydata.set("percentileValue", that.viewreportbodymodal.get("percentileValue"));
            Bodydata.set("script", that.viewreportbodymodal.get("script"));
            Bodydata.set("customSolidGaugeField", that.viewreportbodymodal.get("customSolidGaugeField"));
            Bodydata.set("calculation", calculation);
            Bodydata.set("yAxisDataType", yAxisDataType);
            Bodydata.set("isExcludeNulls", isExcludeNulls);
            Bodydata.set("isPurgedReport", that.isPurgedReport);
            Bodydata.set("tableName", chartDetails.yAxis[0]["tableName"]);
            Bodydata.set("displayName", chartDetails.yAxis[0]["displayName"]);
            Bodydata.set("referencelineobj", that.viewreportbodymodal.get("referencelineobj"));
            Bodydata.set("customaggregatedscriptfield", that.viewreportbodymodal.get('customaggregatedscriptfield'));
            Bodydata.set("isImportedReport", that.isImportedReport);
            Bodydata.set("isPublished", ChartInfo.functions.getisPublished());
            SendRequest({
                url: "getMetricData",
                queryString: "",
                body: Bodydata,
                sucessFunction: (response) => {
                    var currentState = sessionStorage.getItem("state");
                    if (currentState != undefined && (currentState == 'viewreport' || currentState == 'viewstorybook')) {
                        if (response.hasOwnProperty('error')) {
                            $(".spinner").hide();
                            $(".gridspinner").hide();
                            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                            $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                            $('.birdmessagebuttons').removeClass('show');
                            $('.birdmessagebuttons').addClass('hide');
                            $('.birdmessage-center').addClass("message-top-in");
                            $('.details').removeClass('hide');
                            $('.birdmessage h2').empty().append('Error : ');
                            $('.birdmessage h2').append('<span class="errorcode">');
                            $('.birdmessage .errorcode').empty().append(response.errorCode);
                            $('.birdmessage-info').empty().text(response.error);
                            $('.details').addClass('show');
                            $('.messagedetails xmp').empty().append(response.errorTrace)
                            /*$('#main').prepend('<div class="alert alert-danger birderror alert-dismissable affix-top" style="margin:0 auto;width: 60%;top: 44px;position: fixed;z-index: 1;left: 20%;">'+response.error+'</div>');
                                setTimeout(function() {
                                    $('.birderror').remove();	
                                }, 3000);*/
                        } else {
                            that.state.chartData = response;
                            if (globalFilterArray != undefined && globalFilterArray.length != 0) {
                                if (chartDetails.legend != undefined) {
                                    try {
                                        $(".filterdatacount").text("Filtered Count: " + that.state.chartData[1][0].filterCount)
                                    } catch (e) {
                                        $(".filterdatacount").text("Filtered Count: " + that.state.chartData[0].filterCount)
                                    }
                                }
                                else {
                                    if (that.state.chartData[0] != undefined) {
                                        $(".filterdatacount").text("Filtered Count: " + that.state.chartData[0].filterCount)
                                    }
                                }
                                ChartInfo.functions.setFilterDataCount(that.state.chartData[0].filterCount);
                                var uniqueNames = [];
                                var i;
                                for (i = 0; i < globalFilterArray.length; i++) {
                                    if (globalFilterArray[i].saved != "true") {
                                        if (uniqueNames.indexOf(globalFilterArray[i].columndisplayname) === -1) {
                                            uniqueNames.push(globalFilterArray[i].columndisplayname);
                                        }
                                    }
                                }
                                var fcount = 0;
                                for (i = 0; i < uniqueNames.length; i++) {
                                    fcount++;
                                }
                                if (fcount > 0) {
                                    $('.singlebadge').remove();
                                    $('.singlerportfilters').append('<span class="singlebadge badge notifications-badge">' + fcount + '</span>');
                                } else {
                                    $('.singlebadge').remove();
                                }
                                var customparameteruniquenames = [];
                                for (i = 0; i < customParameterFilterArray.length; i++) {
                                    if (customparameteruniquenames.indexOf(customParameterFilterArray[i].parametername) === -1) {
                                        customparameteruniquenames.push(customParameterFilterArray[i].parametername);
                                    }
                                }
                                for (i = 0; i < customparameteruniquenames.length; i++) {
                                    fcount++;
                                }
                                if (fcount > 0) {
                                    $('.singlerportfilters').append('<span class="singlebadge badge notifications-badge">' + fcount + '</span>');
                                }
                                $('.filterdatacountrow').removeClass("hide");
                            }
                            else {
                                $(".filterdatacount").text("");
                                var customparameteruniquenames = [];
                                var fcount = 0;
                                var i;
                                for (i = 0; i < customParameterFilterArray.length; i++) {
                                    if (customparameteruniquenames.indexOf(customParameterFilterArray[i].parametername) === -1) {
                                        customparameteruniquenames.push(customParameterFilterArray[i].parametername);
                                    }
                                }
                                for (i = 0; i < customparameteruniquenames.length; i++) {
                                    fcount++;
                                }
                                if (fcount > 0) {
                                    $('.singlebadge').remove();
                                    $('.singlerportfilters').append('<span class="singlebadge badge notifications-badge">' + fcount + '</span>');
                                } else {
                                    $('.singlebadge').remove();
                                }
                            }
                            this.redrawChart(chartDetails, applyattributes, dateHistogramInterval);
                            ChartUtils.modifyChartHeightOnResize(this.state.chartnumber, "max");
                        }
                    }
                },
                rejectFunction: () => { },
                headers: {
                    method: "post",
                    headers: new Headers({
                        "Content-Type": "application/json"
                    })
                }
            });
        }
        if (this.state.schedule === "schedule" && chartType != "metric" && chartType !== "solidgauge" && chartType != "fullcirclegauge" && chartType != "textchart" && chartType != "pivot") {
            if (globalFilterArray.length === 0) {
                var formData = new FormData();
                formData.set("aggregation", aggregation);
                formData.set("yaxis", yAxisData);
                formData.set("datamodelId", this.state.datamodelId);
                formData.set("reportId", this.state.reportId);
                formData.set("filterObject", JSON.stringify(globalFilterArray));
                formData.set("orderType", orderType);
                formData.set("chartType", chartType);
                formData.set("datefield", metricdatefield);
                formData.set("scheduletype", metricdatetype);
                formData.set("schedule", this.state.schedule);
                formData.set("xaxisAggregation", that.viewreportbodymodal.get("xaxisAggregation"));
                formData.set("xAxisIdentity", that.viewreportbodymodal.get("xAxisIdentity"));
                formData.set("sortingColumn", that.viewreportbodymodal.get("sortingColumn"));
                formData.set("sortType", that.viewreportbodymodal.get("sortType"));
                formData.set("sortingColumnAggregation", that.viewreportbodymodal.get("sortingColumnAggregation"));
                formData.set("rankingColumn", that.viewreportbodymodal.get("rankingColumn"));
                formData.set("rankingColumnAggregation", that.viewreportbodymodal.get("rankingColumnAggregation"));
                formData.set("xaxis", that.viewreportbodymodal.get("xaxis"));
                formData.set("xaxisdataType", that.viewreportbodymodal.get("xaxisdataType"));
                formData.set("legend", that.viewreportbodymodal.get("legend"));
                formData.set("legendDataType", that.viewreportbodymodal.get("legendDataType"));
                formData.set("script", that.viewreportbodymodal.get("script"));
                formData.set("colLimit", that.viewreportbodymodal.get("colLimit"));
                formData.set("scheduleid", this.state.scheduleid?this.state.scheduleid:0);
                formData.set("date", this.state.date?this.state.date:0);
                formData.set("percentileValue", that.viewreportbodymodal.get("percentileValue"));
                formData.set("toolTips", that.viewreportbodymodal.get("toolTips"));
                formData.set("calculation", calculation);
                formData.set("customaggregatedscriptfield", that.viewreportbodymodal.get('customaggregatedscriptfield'));
                formData.set("custommeasurehierarchy", that.viewreportbodymodal.get("custommeasurehierarchyarray"));
                formData.set("isPurgedReport", that.isPurgedReport);
                formData.set("yAxisDataType", yAxisDataType);
                formData.set("referencelineobj", that.viewreportbodymodal.get("referencelineobj"));
                formData.set("combinationchartsData", JSON.stringify(combinationchartsData));
				formData.set("compareDateRangeJson", that.viewreportbodymodal.get("compareDateRangeJson"));
                formData.set("yAxisDisplayName", that.viewreportbodymodal.get("yAxisDisplayName"));
                formData.set("rangefieldMap", that.viewreportbodymodal.get("rangefieldMap"));
                formData.set("dateHistogramInterval", that.viewreportbodymodal.get("dateHistogramInterval"));
                formData.set("indexName", that.viewreportbodymodal.get("indexName"));
                formData.set("isExcludeorIncludeFilters", isExcludeorIncludeFilters);
                formData.set("zaxis", that.viewreportbodymodal.get("zaxis"));
                formData.set("timeZone", that.viewreportbodymodal.get("timeZone"));

                let requestHeaders = {
                    method: "post",
                    headers: new Headers({
                        "Content-Type": "application/json"
                    })
                };
                SendRequest({
                    url: "getScheduleReportURLData",
                    queryString: "",
                    body: formData,
                    sucessFunction: (response) => {
                        var currentState = sessionStorage.getItem("state");
                        if (currentState != undefined && (currentState == 'viewreport' || currentState == 'viewstorybook')) {
                            that.state.chartData = response;
                            if (globalFilterArray != undefined && globalFilterArray.length != 0) {
                                if (chartDetails.legend != undefined) {
                                    if (that.state.chartData.length == 3 && chartDetails["chartType"] == "heatmap") {
                                        $(".filterdatacount").text("Filtered Count: " + that.state.chartData[2][0].filterCount)
                                    }
                                    else {
                                        if (that.state.chartData[1][0] != undefined) {
                                            $(".filterdatacount").text("Filtered Count: " + that.state.chartData[1][0].filterCount)
                                        }
                                    }
                                }
                                else {
                                    $(".filterdatacount").text("Filtered Count: " + that.state.chartData[0].filterCount)
                                }
                                var uniqueNames = [];
                                var i;
                                for (i = 0; i < globalFilterArray.length; i++) {
                                    if (uniqueNames.indexOf(globalFilterArray[i].columndisplayname) === -1) {
                                        uniqueNames.push(globalFilterArray[i].columndisplayname);
                                    }
                                }
                                var fcount = 0;
                                for (i = 0; i < uniqueNames.length; i++) {
                                    fcount++;
                                }
                                if (fcount > 0) {
                                    $('.singlebadge').remove();
                                    $('.singlerportfilters').append('<span class="singlebadge badge notifications-badge">' + fcount + '</span>');
                                } else {
                                    $('.singlebadge').remove();
                                }
                                var customparameteruniquenames = [];
                                for (i = 0; i < customParameterFilterArray.length; i++) {
                                    if (customparameteruniquenames.indexOf(customParameterFilterArray[i].parametername) === -1) {
                                        customparameteruniquenames.push(customParameterFilterArray[i].parametername);
                                    }
                                }
                                for (i = 0; i < customparameteruniquenames.length; i++) {
                                    fcount++;
                                }
                                if (fcount > 0) {
                                    $('.singlerportfilters').append('<span class="singlebadge badge notifications-badge">' + fcount + '</span>');
                                }
                            }
                            else {
                                $(".filterdatacount").text("");
                                var customparameteruniquenames = [];
                                var fcount = 0;
                                var i;
                                for (i = 0; i < customParameterFilterArray.length; i++) {
                                    if (customparameteruniquenames.indexOf(customParameterFilterArray[i].parametername) === -1) {
                                        customparameteruniquenames.push(customParameterFilterArray[i].parametername);
                                    }
                                }
                                for (i = 0; i < customparameteruniquenames.length; i++) {
                                    fcount++;
                                }
                                if (fcount > 0) {
                                    $('.singlebadge').remove();
                                    $('.singlerportfilters').append('<span class="singlebadge badge notifications-badge">' + fcount + '</span>');
                                } else {
                                    $('.singlebadge').remove();
                                }
                            }
                            that.redrawChart(chartDetails, applyattributes, dateHistogramInterval);
                            ChartUtils.modifyChartHeightOnResize(this.state.chartnumber, "max");
                        }
                    },
                    rejectFunction: () => { },
                    headers: requestHeaders
                });
            } else {
                var bodyParams = new FormData();
                bodyParams.set("aggregation", that.viewreportbodymodal.get("aggregation"));
                bodyParams.set("xAxisIdentity", that.viewreportbodymodal.get("xAxisIdentity"));
                bodyParams.set("xaxis", that.viewreportbodymodal.get("xaxis"));
                bodyParams.set("xaxisdataType", that.viewreportbodymodal.get("xaxisdataType"));
                bodyParams.set("yaxis", that.viewreportbodymodal.get("yaxis"));
                bodyParams.set("legend", that.viewreportbodymodal.get("legend"));
                bodyParams.set("legendDataType", that.viewreportbodymodal.get("legendDataType"));
                bodyParams.set("datamodelId", that.viewreportbodymodal.get("datamodelId"));
                bodyParams.set("reportId", that.viewreportbodymodal.get("reportId"));
                bodyParams.set("script", that.viewreportbodymodal.get("script"));
                bodyParams.set("filterObject", that.viewreportbodymodal.get("filterObject"));
                bodyParams.set("sortingColumn", (that.viewreportbodymodal.get("sortingColumn") != undefined) ? that.viewreportbodymodal.get("sortingColumn") : "");
                bodyParams.set("sortType", that.viewreportbodymodal.get("sortType"));
                bodyParams.set("sortingColumnAggregation", (that.viewreportbodymodal.get("sortingColumnAggregation") != undefined) ? that.viewreportbodymodal.get("sortingColumnAggregation") : "");
                bodyParams.set("rankingColumn", that.viewreportbodymodal.get("rankingColumn"));
                bodyParams.set("rankingColumnAggregation", that.viewreportbodymodal.get("rankingColumnAggregation"));
                bodyParams.set("orderType", that.viewreportbodymodal.get("orderType"));
                bodyParams.set("colLimit", that.viewreportbodymodal.get("colLimit"));
                bodyParams.set("chartType", that.viewreportbodymodal.get("chartType"));
                bodyParams.set("percentileValue", that.viewreportbodymodal.get("percentileValue"));
                bodyParams.set("xaxisAggregation", that.viewreportbodymodal.get("xaxisAggregation"));
                bodyParams.set("dateHistogramInterval", (that.viewreportbodymodal.get("dateHistogramInterval") != undefined && that.viewreportbodymodal.get("dateHistogramInterval") != null) ? that.viewreportbodymodal.get("dateHistogramInterval") : "");
                bodyParams.set("toolTips", that.viewreportbodymodal.get("toolTips"));
                bodyParams.set("zaxis", that.viewreportbodymodal.get("zAxis"));
                bodyParams.set("calculation", that.viewreportbodymodal.get("calculation"));
                bodyParams.set("customaggregatedscriptfield", that.viewreportbodymodal.get('customaggregatedscriptfield'));
                bodyParams.set("custommeasurehierarchy", that.viewreportbodymodal.get("custommeasurehierarchyarray"));
                bodyParams.set("timeZone", that.viewreportbodymodal.get("timeZone"));
                bodyParams.set("isPurgedReport", that.isPurgedReport);
                bodyParams.set("isImportedReport", that.isImportedReport);
                bodyParams.set("isPublished", ChartInfo.functions.getisPublished());
                bodyParams.set("xAxisDisplayName", that.viewreportbodymodal.get("xAxisDisplayName"));
                bodyParams.set("yAxisDisplayName", that.viewreportbodymodal.get("yAxisDisplayName"));
                bodyParams.set("zAxisDisplayName", that.viewreportbodymodal.get("zAxisDisplayName"));
                bodyParams.set("toolTipDisplayName", that.viewreportbodymodal.get("toolTipDisplayName"));
                bodyParams.set("legendcolumnDisplayName", that.viewreportbodymodal.get("legendcolumnDisplayName"));
                bodyParams.set("referencelineobj", that.viewreportbodymodal.get("referencelineobj"));
                bodyParams.set("combinationchartsData", that.viewreportbodymodal.get("combinationchartsData"));
				bodyParams.set("compareDateRangeJson", that.viewreportbodymodal.get("compareDateRangeJson"));
                bodyParams.set("yAxisDataType", that.viewreportbodymodal.get("yAxisDataType"));
                bodyParams.set("rangefieldMap", that.viewreportbodymodal.get("rangefieldMap"));
                if(that.state.reportId!=null && that.state.reportId!=undefined && that.state.reportId!=''){
                    var nlprowlimitobj = ChartInfo.functions.getNLPTableRowLimitDetails(that.state.reportId);
                       if(nlprowlimitobj!=undefined && nlprowlimitobj!="" && nlprowlimitobj!=null){
                        bodyParams.set("nlpDetailsObj", JSON.stringify(nlprowlimitobj)); 
                       }
                }
                SendRequest({
                    url: "getPreviewData",
                    queryString: "",
                    body: bodyParams,
                    sucessFunction: (response) => {
                        var currentState = sessionStorage.getItem("state");
                        if (currentState != undefined && (currentState == 'viewreport' || currentState == 'viewstorybook')) {
                            try {
                                var parsedData = response;
                                if (parsedData != undefined) {
                                    response = JSON.parse(decodeURIComponent(parsedData));
                                }
                            } catch (e) {
                            }
                            that.state.chartData = response;
                            if (globalFilterArray != undefined && globalFilterArray.length != 0) {
                                if (chartDetails.legend != undefined) {
                                    if (that.state.chartData.length == 3 && chartDetails["chartType"] == "heatmap") {
                                        ChartInfo.functions.setFilterDataCount(that.state.chartData[2][0].filterCount);
                                        $(".filterdatacount").text("Filtered Count: " + that.state.chartData[2][0].filterCount)
                                    }
                                    else {
                                        ChartInfo.functions.setFilterDataCount(that.state.chartData[1][0].filterCount);
                                        if (that.state.chartData[1] != undefined && that.state.chartData[1][0] != undefined) {
                                            $(".filterdatacount").text("Filtered Count: " + that.state.chartData[1][0].filterCount)
                                        }
                                    }
                                } else {
                                    ChartInfo.functions.setFilterDataCount(that.state.chartData[0].filterCount);
                                    $(".filterdatacount").text("Filtered Count: " + that.state.chartData[0].filterCount)
                                }

                                var uniqueNames = [];
                                var i;
                                for (i = 0; i < globalFilterArray.length; i++) {
                                    if (globalFilterArray[i]["saved"] != "true") {
                                        if (uniqueNames.indexOf(globalFilterArray[i].columndisplayname) === -1) {
                                            uniqueNames.push(globalFilterArray[i].columndisplayname);
                                        }
                                    }
                                }
                                var fcount = 0;
                                for (i = 0; i < uniqueNames.length; i++) {
                                    fcount++;
                                }
                                if (fcount > 0) {
                                    $('.singlebadge').remove();
                                    $('.singlerportfilters').append('<span class="singlebadge badge notifications-badge">' + fcount + '</span>');
                                } else {
                                    $('.singlebadge').remove();
                                }
                                var customparameteruniquenames = [];
                                for (i = 0; i < customParameterFilterArray.length; i++) {
                                    if (customparameteruniquenames.indexOf(customParameterFilterArray[i].parametername) === -1) {
                                        customparameteruniquenames.push(customParameterFilterArray[i].parametername);
                                    }
                                }
                                for (i = 0; i < customparameteruniquenames.length; i++) {
                                    fcount++;
                                }
                                if (fcount > 0) {
                                    $('.singlerportfilters').append('<span class="singlebadge badge notifications-badge">' + fcount + '</span>');
                                }
                            } else {
                                $(".filterdatacount").text("");
                                var customparameteruniquenames = [];
                                var fcount = 0;
                                var i;
                                for (i = 0; i < customParameterFilterArray.length; i++) {
                                    if (customparameteruniquenames.indexOf(customParameterFilterArray[i].parametername) === -1) {
                                        customparameteruniquenames.push(customParameterFilterArray[i].parametername);
                                    }
                                }
                                for (i = 0; i < customparameteruniquenames.length; i++) {
                                    fcount++;
                                }
                                if (fcount > 0) {
                                    $('.singlebadge').remove();
                                    $('.singlerportfilters').append('<span class="singlebadge badge notifications-badge">' + fcount + '</span>');
                                } else {
                                    $('.singlebadge').remove();
                                }
                            }
                            that.redrawChart(chartDetails, applyattributes, dateHistogramInterval);
                            ChartUtils.modifyChartHeightOnResize(this.state.chartnumber, "max");
                        }
                    },
                    rejectFunction: () => { },
                    headers: {
                        method: "post",
                        headers: new Headers({
                            "Content-Type": "application/json"
                        })
                    }
                })
            }
        }

        if (chartType != "metric" && this.state.schedule != "schedule" && chartType != "solidgauge" && chartType != "fullcirclegauge" && chartType != "summarytable" && chartType != "doby" && chartType != "pivot" && chartType != "textchart") {
            var bodyProps = new FormData();
            bodyProps.set("aggregation", that.viewreportbodymodal.get("aggregation"));
            bodyProps.set("xAxisIdentity", that.viewreportbodymodal.get("xAxisIdentity"));
            bodyProps.set("xaxis", that.viewreportbodymodal.get("xaxis"));
            bodyProps.set("xaxisdataType", that.viewreportbodymodal.get("xaxisdataType"));
            bodyProps.set("yaxis", that.viewreportbodymodal.get("yaxis"));
            bodyProps.set("legend", that.viewreportbodymodal.get("legend"));
            bodyProps.set("legendDataType", that.viewreportbodymodal.get("legendDataType"));
            bodyProps.set("datamodelId", that.viewreportbodymodal.get("datamodelId"));
            bodyProps.set("reportId", that.viewreportbodymodal.get("reportId"));
            bodyProps.set("script", that.viewreportbodymodal.get("script"));
            bodyProps.set("filterObject", that.viewreportbodymodal.get("filterObject"));
            bodyProps.set("sortingColumn", (that.viewreportbodymodal.get("sortingColumn") === undefined) ? "" : that.viewreportbodymodal.get("sortingColumn"));
            bodyProps.set("sortType", that.viewreportbodymodal.get("sortType"));
            bodyProps.set("sortingColumnAggregation", (that.viewreportbodymodal.get("sortingColumnAggregation") != undefined && that.viewreportbodymodal.get("sortingColumnAggregation") != null) ? that.viewreportbodymodal.get("sortingColumnAggregation") : "");
            bodyProps.set("rankingColumn", that.viewreportbodymodal.get("rankingColumn"));
            bodyProps.set("rankingColumnAggregation", that.viewreportbodymodal.get("rankingColumnAggregation"));
            bodyProps.set("orderType", that.viewreportbodymodal.get("orderType"));
            bodyProps.set("colLimit", that.viewreportbodymodal.get("colLimit"));
            bodyProps.set("chartType", that.viewreportbodymodal.get("chartType"));
            bodyProps.set("percentileValue", that.viewreportbodymodal.get("percentileValue"));
            bodyProps.set("xaxisAggregation", that.viewreportbodymodal.get("xaxisAggregation"));
            bodyProps.set("dateHistogramInterval", (that.viewreportbodymodal.get("dateHistogramInterval") != undefined && that.viewreportbodymodal.get("dateHistogramInterval") != null) ? that.viewreportbodymodal.get("dateHistogramInterval") : "");
            bodyProps.set("toolTips", that.viewreportbodymodal.get("toolTips"));
            bodyProps.set("zaxis", that.viewreportbodymodal.get("zAxis"));
            bodyProps.set("isExcludeorIncludeFilters", (isExcludeorIncludeFilters != undefined && isExcludeorIncludeFilters != null) ? isExcludeorIncludeFilters : "");
            bodyProps.set("calculation", (that.viewreportbodymodal.get("calculation") != undefined && that.viewreportbodymodal.get("calculation") != null) ? that.viewreportbodymodal.get("calculation") : "");
            bodyProps.set("yAxisDataType", yAxisDataType);
            bodyProps.set("rangefieldMap", that.viewreportbodymodal.get("rangefieldMap"));
            bodyProps.set("customaggregatedscriptfield", that.viewreportbodymodal.get('customaggregatedscriptfield'));
            bodyProps.set("combinationchartsData", that.viewreportbodymodal.get('combinationchartsData'));
			bodyProps.set("compareDateRangeJson", that.viewreportbodymodal.get('compareDateRangeJson'));
            bodyProps.set("custommeasurehierarchy", that.viewreportbodymodal.get("custommeasurehierarchyarray"));
            bodyProps.set("isExcludeNulls", (that.viewreportbodymodal.get("isExcludeNulls") != undefined && that.viewreportbodymodal.get("isExcludeNulls") != null) ? that.viewreportbodymodal.get("isExcludeNulls") : "");
            bodyProps.set("isChartSummaryData", (isChartSummaryData != undefined && isChartSummaryData != null) ? isChartSummaryData : "");
            bodyProps.set("timeZone", (that.viewreportbodymodal.get("timeZone") != undefined && that.viewreportbodymodal.get("timeZone") != null) ? that.viewreportbodymodal.get("timeZone") : "");
            bodyProps.set("isPurgedReport", that.isPurgedReport);
            bodyProps.set("isPublished", ChartInfo.functions.getisPublished());
            bodyProps.set("isImportedReport", that.isImportedReport);
            bodyProps.set("xAxisDisplayName", that.viewreportbodymodal.get("xAxisDisplayName"));
            bodyProps.set("yAxisDisplayName", that.viewreportbodymodal.get("yAxisDisplayName"));
            bodyProps.set("zAxisDisplayName", that.viewreportbodymodal.get("zAxisDisplayName"));
            bodyProps.set("toolTipDisplayName", that.viewreportbodymodal.get("toolTipDisplayName"));
            bodyProps.set("legendcolumnDisplayName", that.viewreportbodymodal.get("legendcolumnDisplayName"));
            bodyProps.set("referencelineobj", that.viewreportbodymodal.get("referencelineobj"));
            if(that.state.reportId!=null && that.state.reportId!=undefined && that.state.reportId!=''){
                var nlprowlimitobj = ChartInfo.functions.getNLPTableRowLimitDetails(that.state.reportId);
                   if(nlprowlimitobj!=undefined && nlprowlimitobj!="" && nlprowlimitobj!=null){
                   bodyProps.set("nlpDetailsObj", JSON.stringify(nlprowlimitobj)); 
                   }
            }
            SendRequest({
                url: "getPreviewData",
                queryString: "",
                body: bodyProps,
                sucessFunction: (response) => {
                    var currentState = sessionStorage.getItem("state");
                    if (currentState != undefined && (currentState == 'viewreport' || currentState == 'viewstorybook')) {
                        try {
                            var parsedData = response;
                            if (parsedData != undefined) {
                                response = JSON.parse(decodeURIComponent(parsedData));
                            }
                        } catch (e) {
                        }
                        if (response.hasOwnProperty('error')) {
                            $(".spinner").hide();
                            $(".gridspinner").hide();
                            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                            $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                            $('.birdmessagebuttons').removeClass('show');
                            $('.birdmessagebuttons').addClass('hide');
                            $('.birdmessage-center').addClass("message-top-in");
                            $('.details').removeClass('hide');
                            $('.birdmessage h2').empty().append('Error : ');
                            $('.birdmessage h2').append('<span class="errorcode">');
                            $('.birdmessage .errorcode').empty().append(response.errorCode);
                            $('.birdmessage-info').empty().text(response.error);
                            $('.details').addClass('show');
                            $('.messagedetails xmp').empty().append(response.errorTrace);
                            //  ChartUtils.unbindEvents(that.state.chartnumber);
                            that.state.chartData = response;
                            /*$('#main').prepend('<div class="alert alert-danger birderror alert-dismissable affix-top" style="margin:0 auto;width: 60%;top: 44px;position: fixed;z-index: 1;left: 20%;">'+response.error+'</div>');
                               setTimeout(function() {
                                $('.birderror').remove();	
                                }, 3000);*/
                        } else {
                            if (isChartSummaryData == "yes") {
                                $("#chart" + that.state.chartnumber).empty();
                                $("#spinnerchart" + that.state.chartnumber).show();


                                var objectArray = [];
                                var xAxisObj = {
                                    "actualnames": that.viewreportbodymodal.get("xaxis"),
                                    "displaynames": that.viewreportbodymodal.get("xAxisDisplayName"),
                                    "datatype": that.viewreportbodymodal.get("xaxisdataType"),
                                    "columnName": that.viewreportbodymodal.get("xaxis")
                                };
                                _.each(chartDetails["yAxis"], function (val) {
                                    var yAxisObj = {
                                        "actualnames": val.columnName,
                                        "displaynames": val.displayName,
                                        "datatype": val.dataType,
                                        "columnName": val.columnName,
                                        "customaggregatedscriptfield": val.dataType == "customaggregatedfield" ? that.viewreportbodymodal.get('customaggregatedscriptfield') : ""
                                    };
                                    objectArray.push(yAxisObj);
                                });
                                objectArray.push(xAxisObj);

                                var dataModel = {};

                                that.viewreportbodymodal.set("selectedcolumns", JSON.stringify(objectArray));
                                that.viewreportbodymodal.set("xaxis", that.viewreportbodymodal.get("xaxis"));
                                that.viewreportbodymodal.set("showMoreOptions", true);
                                var bootObj = {};
                                bootObj["currentChartNumber"] = that.state.chartnumber;
                                bootObj["response"] = response;
                                var bootStrapTable = new SummaryView(bootObj);
                                bootStrapTable.render();
                                $('#dobyexportdownload').addClass('hide');
                                $('.singlereportselectedtablecolumns').addClass('hide')
                                // ChartUtils.drawDataTable(response, that.props.chartnumber, selectedDobyColumns,true,undefined,calculation);
                                $("#spinnerchart" + that.state.chartnumber).hide();
                                $(".spinner").hide();
                            } else {
                                $("#chart" + that.state.chartnumber).empty();
                                that.state.chartData = response;
                                var trendstats;
                                _.each(response, function (response, i) {
                                    _.each(chartDetails["yAxis"], function (data, j) {
                                        if (response[chartDetails["yAxis"][j].columnName] != undefined) {
                                            if (response[chartDetails["yAxis"][j].columnName][1] == "trendstats") {
                                                trendstats = "trendstats";
                                            }
                                        }
                                    });
                                });
                                var filtersForDataBehindChart = [];
                                //Below commented logic for custom field databehind chart
                                /*var customFields=sessionStorage.getItem("customFields");
                                var parsingCustomFields=[]
                                if(customFields!=undefined){
                                    parsingCustomFields=JSON.parse(customFields);
                                }
                                var isCustomFiled=false;
                                var customXaxisField={};
                                if(parsingCustomFields!=null && parsingCustomFields!=undefined){
                                    $.each(parsingCustomFields, function(inddex,custom) {
                                        if(custom["name"]==chartDetails.xAxis[0]["columnName"]){
                                            isCustomFiled=true;
                                            var test=parsingCustomFields[inddex]["scriptcondition"];
                                            var test2="; if(("+test+ ").equals(parameter)) return true else false";
                                            parsingCustomFields[inddex]["scriptcondition"]=test2;
                                            customXaxisField=parsingCustomFields[inddex];
                                        }
                                    });
                                }
                                if(isCustomFiled==false){*/
                                /*}else{
                                $.each(that.chartData[0].data,function(index,data){
                                      var filterObject = {};
                                        filterObject["dataType"] ="string";
                                        customXaxisField["parameter"]
                                        if(chartDetails.xAxis[0]["dataType"]=="date"){
                                            var dateString = data["name"];
                                            customXaxisField["parameter"] = dateString;//Date.parse(dateString, "yyyy-MM-dd HH:mm:ss");
                                        }else{
                                            customXaxisField["parameter"] = data["name"];
                                        }
                                        filterObject["customfield"] = customXaxisField;
                                        filtersForDataBehindChart.push(filterObject);
                                });
                            }*/
                                if (that.state.chartData.length != undefined) {
                                    if (chartDetails.xAxis != undefined && chartDetails.xAxis.length == 0) {
                                        var columnName = "Measure Names";
                                    } else {
                                        var tableName;
                                        var displayName;
                                        var columnName;
                                        var dataType;
                                        var temp;
                                        var rangeobject;
                                        if (chartDetails.xAxis != undefined && chartDetails.xAxis.length > 0) {
                                            tableName = chartDetails.xAxis[0]["tableName"];
                                            displayName = chartDetails.xAxis[0]["displayName"];
                                            columnName = chartDetails.xAxis[0]["columnName"];
                                            dataType = chartDetails.xAxis[0]["dataType"];
                                            temp = $.grep(ChartInfo.functions.getcustomhierarchy(), function (e) { return e.name == chartDetails.xAxis[0]["columnName"] });
                                            rangeobject = $.grep(ChartInfo.functions.getRange(), function (e) { return e.name == chartDetails.xAxis[0]["columnName"] });
                                        }
                                        if (temp != undefined && temp.length > 0) {
                                            //var levelmapping=sessionStorage.getItem("customhierarchyforchart");
                                            //levelmapping=levelmapping==null?0:levelmapping;
                                            var obj = temp[0].hierarchelist[0];
                                            tableName = obj.tableName;
                                            displayName = obj.displayName;
                                            columnName = obj.columnName;
                                            dataType = obj.dataType;
                                        }
                                        if (rangeobject != undefined && rangeobject.length > 0) {
                                            var obj = rangeobject[0]["xAxisName"][0];
                                            tableName = obj.tableName;
                                            displayName = obj.columnDisplayName;
                                            columnName = obj.columnName;
                                        }
                                    }
                                    if (chartDetails.legend == undefined && chartType != "sankey") {
                                        if (chartType == "boxplot") {
                                            dataArray = [that.state.chartData[0]];
                                            $.each(dataArray[0][0].categories, function (index, data) {
                                                if (data != null & data != undefined && columnName != 'Measure Names') {
                                                    var filterObject = {};
                                                    filterObject["dataType"] = dataType;
                                                    filterObject["tablename"] = tableName;//chartDetails.xAxis[0]["tableName"];
                                                    filterObject["columndisplayname"] = displayName;//chartDetails.xAxis[0]["displayName"];
                                                    filterObject["columnname"] = columnName;//chartDetails.xAxis[0]["columnName"]
                                                    if (dataType == "date") {
                                                        var dateString = data["name"];
                                                        filterObject["data"] = dateString;//Date.parse(dateString, "yyyy-MM-dd HH:mm:ss");
                                                    } else {
                                                        filterObject["data"] = data;
                                                    }
                                                    if (dataType == "customrange") {
                                                        filterObject["dataType"] = dataType;
                                                    }
                                                    filtersForDataBehindChart.push(filterObject);
                                                }
                                            });
                                        } else if (chartType == "heatmap") {
                                            dataArray = [that.state.chartData[0]];
                                            $.each(dataArray[0], function (index, data) {
                                                if (data != null & data != undefined && columnName != "Measure Names") {
                                                    var filterObject = {};
                                                    filterObject["dataType"] = dataType;
                                                    filterObject["tablename"] = tableName; //chartDetails.xAxis[0]["tableName"];
                                                    filterObject["columndisplayname"] = displayName; //chartDetails.xAxis[0]["displayName"];
                                                    filterObject["columnname"] = columnName; //chartDetails.xAxis[0]["columnName"]
                                                    if (dataType == "date") {
                                                        var dateString = data;
                                                        filterObject["data"] = dateString;//Date.parse(dateString, "yyyy-MM-dd HH:mm:ss");
                                                    } else {
                                                        filterObject["data"] = data;
                                                    }
                                                    if (dataType == "customrange") {
                                                        filterObject["dataType"] = dataType;
                                                    }
                                                    filtersForDataBehindChart.push(filterObject);
                                                }
                                            });
                                        } else {
                                            if (that.state.chartData.length != 0) {
                                                $.each(that.state.chartData[0].data, function (index, data) {
                                                    if (data != null & data != undefined && columnName != 'Measure Names') {
                                                        var filterObject = {};
                                                        filterObject["dataType"] = dataType;
                                                        filterObject["tablename"] = tableName;//chartDetails.xAxis[0]["tableName"];
                                                        filterObject["columndisplayname"] = displayName;//chartDetails.xAxis[0]["displayName"];
                                                        filterObject["columnname"] = columnName;//chartDetails.xAxis[0]["columnName"]
                                                        if (dataType == "date") {
                                                            var dateString = data["name"];
                                                            filterObject["data"] = dateString;//Date.parse(dateString, "yyyy-MM-dd HH:mm:ss");
                                                        } else {
                                                            filterObject["data"] = data["name"];
                                                        }
                                                        if (dataType == "customrange") {
                                                            filterObject["dataType"] = dataType;
                                                        }
                                                        filtersForDataBehindChart.push(filterObject);
                                                    }
                                                });
                                            }
                                        }
                                        if (ChartInfo.functions.getIsDirect() == 1) {
                                            if (ChartInfo.functions.getIsStoredProcedure() != 1) {
                                                ChartInfo.functions.setFiltersForDataBehindChart(that.state.chartnumber, filtersForDataBehindChart);
                                            } else {
                                                ChartInfo.functions.setFiltersForDataBehindChart(that.state.chartnumber, []);
                                            }
                                        } else {
                                            ChartInfo.functions.setFiltersForDataBehindChart(that.state.chartnumber, filtersForDataBehindChart);
                                        }
                                    } else {
                                        var filtersForDataBehindChart = [];
                                        var dataArray = [];
                                        if (that.state.chartData.length == 3) {
                                            dataArray = [that.state.chartData[0]];
                                        }
                                        else {
                                            if (that.state.chartData[1] != undefined) {
                                                $.each(that.state.chartData[1], function (index, data) {
                                                    if (data["data"] != undefined) {
                                                        $.each(data["data"], function (index, data1) {
                                                            if (data1["name"] != "") {
                                                                dataArray.push(data1["name"]);
                                                            }

                                                        });
                                                    }
                                                });
                                            }

                                        }

                                        dataArray = $.unique(dataArray);
                                        if (chartType == "boxplot") {
                                            dataArray = [that.state.chartData[0]];
                                            $.each(dataArray[0][0].categories, function (index, data) {
                                                if (data != null & data != undefined && columnName != 'Measure Names') {
                                                    var filterObject = {};
                                                    filterObject["dataType"] = chartDetails.xAxis[0]["dataType"];
                                                    filterObject["tablename"] = tableName;//chartDetails.xAxis[0]["tableName"];
                                                    filterObject["columndisplayname"] = displayName;//chartDetails.xAxis[0]["displayName"];
                                                    filterObject["columnname"] = columnName; //chartDetails.xAxis[0]["columnName"]
                                                    if (dataType == "date") {
                                                        var dateString = data;
                                                        filterObject["data"] = dateString;//Date.parse(dateString, "yyyy-MM-dd HH:mm:ss");
                                                    } else {
                                                        filterObject["data"] = data;
                                                    }
                                                    if (dataType == "customrange") {
                                                        filterObject["dataType"] = dataType;
                                                    }
                                                    filtersForDataBehindChart.push(filterObject);
                                                }
                                            });
                                        } else {
                                            $.each(dataArray, function (index, data) {
                                                if (data != null & data != undefined && columnName != 'Measure Names') {
                                                    if (chartDetails.legend != undefined) {

                                                        var filterObject = {};
                                                        filterObject["dataType"] = chartDetails.xAxis[0]["dataType"];
                                                        filterObject["tablename"] = tableName;//chartDetails.xAxis[0]["tableName"];
                                                        filterObject["columndisplayname"] = displayName;//chartDetails.xAxis[0]["displayName"];
                                                        filterObject["columnname"] = columnName; //chartDetails.xAxis[0]["columnName"]
                                                        if (dataType == "date") {
                                                            filterObject["data"] = data;//Date.parse(dateString, "yyyy-MM-dd HH:mm:ss");
                                                        } else {
                                                            filterObject["data"] = data;
                                                        }
                                                        if (dataType == "customrange") {
                                                            filterObject["dataType"] = dataType;
                                                        }
                                                        filtersForDataBehindChart.push(filterObject);

                                                    } else {
                                                        _.each(data, function (value) {
                                                            var filterObject = {};
                                                            filterObject["dataType"] = chartDetails.xAxis[0]["dataType"];
                                                            filterObject["tablename"] = tableName;// chartDetails.xAxis[0]["tableName"];
                                                            filterObject["columndisplayname"] = displayName;// chartDetails.xAxis[0]["displayName"];
                                                            filterObject["columnname"] = columnName; // chartDetails.xAxis[0]["columnName"]
                                                            if (dataType == "date") {
                                                                filterObject["data"] = value;// Date.parse(dateString, "yyyy-MM-dd HH:mm:ss");
                                                            } else {
                                                                filterObject["data"] = value;
                                                            }
                                                            if (dataType == "customrange") {
                                                                filterObject["dataType"] = dataType;
                                                            }
                                                            filtersForDataBehindChart.push(filterObject);
                                                        });

                                                    }
                                                    _.each(data, function (value) {
                                                        var filterObject = {};
                                                        filterObject["dataType"] = chartDetails.xAxis[0]["dataType"];
                                                        filterObject["tablename"] = tableName;//chartDetails.xAxis[0]["tableName"];
                                                        filterObject["columndisplayname"] = displayName;//chartDetails.xAxis[0]["displayName"];
                                                        filterObject["columnname"] = columnName; //chartDetails.xAxis[0]["columnName"]
                                                        if (dataType == "date") {
                                                            filterObject["data"] = value;//Date.parse(dateString, "yyyy-MM-dd HH:mm:ss");
                                                        } else {
                                                            filterObject["data"] = value;
                                                        }
                                                        if (dataType == "customrange") {
                                                            filterObject["dataType"] = dataType;
                                                        }
                                                        filtersForDataBehindChart.push(filterObject);
                                                    });
                                                }
                                            });
                                        }
                                        if (ChartInfo.functions.getIsDirect() == 1) {
                                            if (ChartInfo.functions.getIsStoredProcedure() != 1) {
                                                ChartInfo.functions.setFiltersForDataBehindChart(that.state.chartnumber, filtersForDataBehindChart);
                                            } else {
                                                ChartInfo.functions.setFiltersForDataBehindChart(that.state.chartnumber, []);
                                            }
                                        } else {
                                            ChartInfo.functions.setFiltersForDataBehindChart(that.state.chartnumber, filtersForDataBehindChart);
                                        }
                                    }
                                }

                                if (globalFilterArray != undefined && globalFilterArray.length != 0) {
                                    $('.filterdatacountrow').removeClass("hide");
                                    if (chartDetails.legend != undefined) {
                                        if (that.state.chartData.length == 3 && that.state.chartData[2][0] != undefined && chartDetails["chartType"] == "heatmap") {
                                            $(".filterdatacount").text("Filtered Count: " + that.state.chartData[2][0].filterCount)
                                            $('.filterDatacount').text(that.state.chartData[2][0].filterCount);
                                            ChartInfo.functions.setFilterDataCount(that.state.chartData[2][0].filterCount);
                                        }
                                        else if (chartDetails["chartType"] == "boxplot") {
                                            $(".filterdatacount").text("Filtered Count: " + that.state.chartData[0][0].filterCount)
                                            $('.filterDatacount').text(that.state.chartData[0][0].filterCount);
                                            ChartInfo.functions.setFilterDataCount(that.state.chartData[0][0].filterCount);
                                        }
                                        else if (chartDetails["chartType"] == "wordcloud") {
                                            $(".filterdatacount").text("Filtered Count: " + that.state.chartData[0].filterCount)
                                            $('.filterDatacount').text(that.state.chartData[0].filterCount);
                                            ChartInfo.functions.setFilterDataCount(that.state.chartData[0].filterCount);
                                        }
                                        else {
                                            if (that.state.chartData[1] != undefined && that.state.chartData[1][0] != undefined) {
                                                $(".filterdatacount").text("Filtered Count: " + that.state.chartData[1][0].filterCount)
                                                $('.filterDatacount').text(that.state.chartData[1][0].filterCount);
                                                ChartInfo.functions.setFilterDataCount(that.state.chartData[1][0].filterCount);
                                            }
                                        }
                                    }
                                    else {
                                        if (chartDetails["chartType"] == "sankey" && that.state.chartData[1] != undefined && that.state.chartData[1][0] != undefined) {
                                            $(".filterdatacount").text("Filtered Count: " + that.state.chartData[1][0].filterCount)
                                            $('.filterDatacount').text(that.state.chartData[1][0].filterCount);
                                            ChartInfo.functions.setFilterDataCount(that.state.chartData[1][0].filterCount);
                                        } else if (chartDetails["chartType"] == "boxplot") {
                                            $(".filterdatacount").text("Filtered Count: " + that.state.chartData[0][0].filterCount)
                                            $('.filterDatacount').text(that.state.chartData[0][0].filterCount);
                                            ChartInfo.functions.setFilterDataCount(that.state.chartData[0][0].filterCount);
                                        } else if (chartDetails["chartType"] == "wordcloud") {
                                            $(".filterdatacount").text("Filtered Count: " + that.state.chartData[0].filterCount)
                                            $('.filterDatacount').text(that.state.chartData[0].filterCount);
                                            ChartInfo.functions.setFilterDataCount(that.state.chartData[0].filterCount);
                                        } else {
                                            $(".filterdatacount").text("Filtered Count: " + that.state.chartData[0].filterCount)
                                            $('.filterDatacount').text(that.state.chartData[0].filterCount);
                                            ChartInfo.functions.setFilterDataCount(that.state.chartData[0].filterCount);
                                        }
                                    }

                                    var uniqueNames = [];
                                    var i;
                                    for (i = 0; i < globalFilterArray.length; i++) {
                                        if (globalFilterArray[i]["saved"] != "true") {
                                            if (uniqueNames.indexOf(globalFilterArray[i].columndisplayname) === -1) {
                                                uniqueNames.push(globalFilterArray[i].columndisplayname);
                                            }
                                        }
                                        /*if(excludefilters.indexOf(globalFilterArray[i].excludeFilterData) === -1){
                                            excludefilters.push(globalFilterArray[i].excludeFilterData);        
                                        } */
                                    }
                                    var fcount = 0;
                                    for (i = 0; i < uniqueNames.length; i++) {
                                        fcount++;
                                    }
                                    if (fcount > 0) {
                                        $('.singlebadge').remove();
                                        $('.singlerportfilters').append('<span class="singlebadge badge notifications-badge">' + fcount + '</span>');
                                    } else {
                                        $('.singlebadge').remove();
                                    }
                                    var customparameteruniquenames = [];
                                    for (i = 0; i < customParameterFilterArray.length; i++) {
                                        if (customparameteruniquenames.indexOf(customParameterFilterArray[i].parametername) === -1) {
                                            customparameteruniquenames.push(customParameterFilterArray[i].parametername);
                                        }
                                    }
                                    for (i = 0; i < customparameteruniquenames.length; i++) {
                                        fcount++;
                                    }
                                    /*if(fcount>0){
                                        $('.singlerportfilters').append('<span class="singlebadge badge notifications-badge">'+fcount+'</span>');
                                    } */
                                }
                                else {
                                    $('.filterdatacountrow').addClass("hide");
                                    $(".filterdatacount").text("");
                                    var uniqueNames = [];
                                    var i;
                                    for (i = 0; i < globalFilterArray.length; i++) {
                                        if (globalFilterArray[i]["saved"] != "true") {
                                            if (uniqueNames.indexOf(globalFilterArray[i].columndisplayname) === -1) {
                                                uniqueNames.push(globalFilterArray[i].columndisplayname);
                                            }
                                        }
                                    }
                                    var fcount = 0;
                                    for (i = 0; i < uniqueNames.length; i++) {
                                        fcount++;
                                    }
                                    if (fcount > 0) {
                                        $('.singlebadge').remove();
                                        $('.singlerportfilters').append('<span class="singlebadge badge notifications-badge">' + fcount + '</span>');
                                    } else {
                                        $('.singlebadge').remove();
                                    }
                                    var customparameteruniquenames = [];
                                    for (i = 0; i < customParameterFilterArray.length; i++) {
                                        if (customparameteruniquenames.indexOf(customParameterFilterArray[i].parametername) === -1) {
                                            customparameteruniquenames.push(customParameterFilterArray[i].parametername);
                                        }
                                    }
                                    for (i = 0; i < customparameteruniquenames.length; i++) {
                                        fcount++;
                                    }
                                    /*if(fcount>0){
                                        $('.singlebadge').remove();
                                        $('.singlerportfilters').append('<span class="singlebadge badge notifications-badge">'+fcount+'</span>');
                                    }  else {
                                        $('.singlebadge').remove();
                                    }*/
                                }
                                that.redrawChart(chartDetails, applyattributes, dateHistogramInterval, undefined, trendstats);
                                ChartUtils.modifyChartHeightOnResize(this.state.chartnumber, "max");
                                if (dateHistogramInterval != undefined && dateHistogramInterval != null && dateHistogramInterval != "reset") {
                                    chartDetails["xAxis"] = ChartInfo.functions.getDateHistogramXaxisData();
                                    if (chartType === "waterfall") {
                                        $(".maxanim1[data-num='" + that.state.chartnumber + "']").find('.saved-data-buttons').hide();
                                        $(".showtrending[data-num='" + that.state.chartnumber + "']").hide();
                                    }
                                }

                                _.each(chartDetails["yAxis"], function (val) {
                                    if (val.dataType == "custommeasurehierarchy") {
                                        $(".showtrending[data-num='" + that.state.chartnumber + "']").hide();
                                        return
                                    }
                                });
                            }
                        }
                    }
                },
                rejectFunction: () => { },
                headers: {
                    method: "post",
                    headers: new Headers({
                        "Content-Type": "application/json"
                    })
                }
            });
        }
        if (chartType === "doby" || chartType == "textchart") {
            setTimeout(() => {
                that.redrawChart(chartDetails, applyattributes, dateHistogramInterval, undefined, undefined, tableInfo);
            }, 100);
            // ChartUtils.modifyChartHeightOnResize(this.state.chartnumber, "max");
        }
        if (chartType == "pivot") {
            setTimeout(() => {
                that.redrawChart(chartDetails);
                ChartUtils.modifyChartHeightOnResize(this.state.chartnumber, "max");
            }, 100);
            $(".maxanim1[data-num='" + that.state.chartnumber + "']").find('.saved-data-buttons').hide();
        }

    }
    colortheme() {
        var that = this;
        setTimeout(() => {
            var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(this.state.chartnumber));
            if (chartDetails["reportcolortheme"] != undefined) {
                var reportcolortheme = JSON.parse(chartDetails["reportcolortheme"]);
                if (reportcolortheme.datacount == 'hide') {
                    $('.maxscreendatacount[data-num=' + that.state.chartnumber + ']').addClass('hide');
                } else if (reportcolortheme.datacount == 'show') {
                    $('.maxscreendatacount[data-num=' + that.state.chartnumber + ']').removeClass('hide');
                }
                if (reportcolortheme.toolbar == 'hamburger') {
                    $('.reporttitletoolbar' + that.state.chartnumber).removeClass('col-lg-12 col-md-12');
                    $('.reporttitlehamburger' + that.state.chartnumber).removeClass('hidden-lg hidden-md');

                    $('.reporttitletoolbar' + that.state.chartnumber).addClass('hidden-lg hidden-md');
                    $('.reporttitlehamburger' + that.state.chartnumber).addClass('col-lg-12 col-md-12');
                    $('.maxleftpanecustomhidden[data-num=' + that.state.chartnumber + ']').addClass('hide');
                }
                var path = window.location.pathname;
                var fragmentArray = path.split("/");
                // var fragmentArray = Backbone.history.fragment.split("/");
                if (fragmentArray != undefined && fragmentArray[0] != undefined && fragmentArray[0] == "viewstorybook") {
                    $('#storybooktabcontent' + TabInfo.functions.getCurrentTabId() + ' .maxanim7[data-num=' + that.state.chartnumber + ']').css('background-color', reportcolortheme.reporttilebackground);
                } else {
                    if (reportcolortheme.titlecolor != undefined) {
                        $('.maxanim9 h2.boc-viewreport-title[data-num=' + that.state.chartnumber + ']').css('color', reportcolortheme.titlecolor);
                        $('.maxscreendatacount[data-num=' + that.state.chartnumber + ']').css('color', reportcolortheme.titlecolor);
                        $('.maxanim4[data-num=' + that.state.chartnumber + ']').css('color', reportcolortheme.titlecolor);
                    }
                    if (reportcolortheme.reporttilebackground != undefined) {
                        $('.maxanim7[data-num=' + that.state.chartnumber + ']').css('background-color', reportcolortheme.reporttilebackground);
                    }
                    if (reportcolortheme.headerbackground != undefined) {
                        $('.reporttoolbar[data-num=' + that.state.chartnumber + '] .controlbar-main').css('background-color', reportcolortheme.headerbackground);
                        //$('.maxanim9[data-num='+that.props.chartnumber+']').css('background-color',reportcolortheme.headerbackground);
                        $('.maxanim4[data-num=' + that.state.chartnumber + ']').css('background-color', reportcolortheme.headerbackground);
                    }
                }
            }
        }, 100);
    }
    redrawChart(chartDetails, applyattributes, dateHistogramInterval, algorithm, trendstats, tableInfo) {
        var xaxisName = "";
        var title = "";
        if (chartDetails.xAxis != undefined && chartDetails.xAxis.length == 0) {
            var xaxisColumnName = "Measure Names";
        } else {
            var xaxisColumnName;
            var xaxisdataType;
            if (chartDetails["xAxis"] != undefined && chartDetails["xAxis"].length > 0) {
                xaxisColumnName = chartDetails["xAxis"][0].columnName;
                xaxisdataType = chartDetails["xAxis"][0].dataType;
            }
        }
        var metrictype = chartDetails["metricdatetype"];
        var metricdatefield = chartDetails["metricdatefield"];
        this.state.metricprefix = chartDetails["metricprefix"];
        this.state.metricsuffix = chartDetails["metricsuffix"];
        this.state.metricfont = chartDetails["metricfont"];
        this.state.solidgaugemin = chartDetails["solidgaugemin"];
        this.state.solidgaugemax = chartDetails["solidgaugemax"];
        var chartType = chartDetails["chartType"];
        this.state.referenceLine = chartDetails["reference"];
        var trendLine = chartDetails["trendLine"]
        var periodsvalue = chartDetails["periodsvalue"];
        if (trendLine != undefined && trendLine != "") {
            if (trendLine === "linear" || trendLine === "SMA" || trendLine === "EMA") {
                algorithm = trendLine;
            }
            $('.singlereport_trendtype[id=' + trendLine + '][data-num=' + chartDetails["chartnumber"] + ']').addClass("showactive");

        }
        var xAxisIdentity = "";
        _.each(chartDetails["xAxis"], function (val1) {
            xAxisIdentity += val1.xaxisidentity + ",";
        });
        xAxisIdentity = xAxisIdentity.substring(0, xAxisIdentity.length - 1);
        var colorThemeIndex = chartDetails["colorThemeIndex"];
        if (colorThemeIndex == undefined) {
            colorThemeIndex = 0;
        }
        var chartSettings = chartDetails['chartSettings'];
        var yAxisNameArray = [];
        var yAxisDataTypeArray = [];
        var yAxisAggArray = [];
        _.each(chartDetails["yAxis"], function (val) {
            yAxisNameArray.push(val.displayName);
            yAxisDataTypeArray.push(val.dataType);
            yAxisAggArray.push(val.aggregation.toLowerCase());
        });
        var yAxisOriginalNameArray = [];
        _.each(chartDetails["yAxis"], function (val) {
            yAxisOriginalNameArray.push(val.columnName);
        });
        var zAxisNameArray = [];
        _.each(chartDetails["zAxis"], function (val) {
            zAxisNameArray.push(val.columnName);
        });
        var zAxisAggArray = [];
        _.each(chartDetails["zAxis"], function (val) {
            zAxisAggArray.push(val.aggregation);
        });
        var tooltipNameArray = [];
        _.each(chartDetails["tooltip"], function (val) {
            tooltipNameArray.push(val.displayName);
        });
        var legend = chartDetails["legend"];

        var yAxisDisplayNameArray = [];
        _.each(chartDetails["yAxis"], function (val) {
            yAxisDisplayNameArray.push(val.displayName);
        });

        var data = this.state.chartData;
        var reportId = this.state.reportId;

        var chartType = chartDetails["chartType"];
        if (data != undefined && data.hasOwnProperty('error')) {
            $(".spinner").hide();
            $(".gridspinner").hide();
            $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
            $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('hide');
            $('.birdmessage h2').empty().append('Error : ');
            $('.birdmessage h2').append('<span class="errorcode">');
            $('.birdmessage .errorcode').empty().append(data.errorCode);
            $('.birdmessage-info').empty().text(data.error);
            $('.details').addClass('show');
            $('.messagedetails xmp').empty().append(data.errorTrace);
        }
        if (chartType === "metric" || chartType === "solidgauge" || chartType === "fullcirclegauge") {
            title = chartDetails['yAxis'][0].aggregation + " of ";

        } else {
            if (chartType == "srilankamap" || chartType == "world" || chartType == "leaflet" || chartType == "countrymap" || chartType == "indiamap") {
                xaxisdataType = "";
                xaxisColumnName = "";
                _.each(chartDetails["xAxis"], function (xaxisname) {
                    xaxisdataType += xaxisname.dataType + ",";
                    xaxisColumnName += xaxisname.columnName + ",";
                });
                xaxisdataType = xaxisdataType.substring(0, xaxisdataType.length - 1);
                xaxisColumnName = xaxisColumnName.substring(0, xaxisColumnName.length - 1);
            }

            var i = 0;
            var that = this;
            _.each(chartDetails["xAxis"], function (xaxisname) {
                var temp = $.grep(ChartInfo.functions.getcustomhierarchy(), function (e) { return e.name == xaxisname.columnName });
                if (temp.length > 0) {
                    var levelmapping = 0;
                    sessionStorage.setItem("customhierarchyforchartnum" + that.state.chartnumber, levelmapping);
                    if (i == 0) {
                        xaxisColumnName = temp[0]["hierarchelist"][0].columnName;
                        xaxisdataType = temp[0]["hierarchelist"][0].dataType;
                        i++;
                    }
                    xaxisName += temp[0]["hierarchelist"][0].columnDisplayName + ",";
                    title += temp[0]["hierarchelist"][0].columnDisplayName + ",";
                } else {
                    title += xaxisname.displayName + ",";
                    xaxisName += xaxisname.displayName + ",";
                }
            });
            title = title.substring(0, title.length - 1);
            title = title + " by "
            xaxisName = xaxisName.substring(0, xaxisName.length - 1);

        }

        _.each(yAxisNameArray, function (yAxisName) {
            title += yAxisName + ",";
        });
        title = title.substring(0, title.length - 1);
        if (chartDetails["reporttitle"] != undefined && chartDetails["reporttitle"].length > 0) {
            if (chartDetails["reporttitle"] == title) {
                title = chartDetails["reporttitle"];
            }
        }
        if (applyattributes != undefined) {
            var customtitle = $(".boc-viewreport-title[data-num=" + this.state.chartnumber + "]").attr("customtitle");
            if (chartSettings["chartTitle"] == true) {
                if (customtitle != 1) {
                    $(".boc-viewreport-title").removeClass("hide");
                    $(".boc-viewreport-title").text(title).attr("title", title);
                } else {
                    $(".boc-viewreport-title").removeClass("hide");
                }
            } else {
                $(".boc-viewreport-title").addClass("hide");
            }
        }
        else {
            if (chartDetails["reporttitle"] != undefined) {
                title = chartDetails["reporttitle"];
                if (chartSettings["chartTitle"] == true) {
                    $(".boc-viewreport-title").removeClass("hide");
                    $(".boc-viewreport-title").text(title).attr("title", title);
                } else {
                    $(".boc-viewreport-title").addClass("hide");
                }

            }
        }

        $(".predictmodelsummary" + this.state.chartnumber).addClass("hide");
        if (dateHistogramInterval != undefined && dateHistogramInterval != null && dateHistogramInterval != "reset") {
            $(".showtrending[data-num='" + this.state.chartnumber + "']").hide();
            $('.viewchartfromchartdata').hide();
        } else {
            $(".showtrending[data-num='" + this.state.chartnumber + "']").show();
            $('.viewchartfromchartdata').show();
        }

        if (!ChartUtils.isTrendSupport(chartType)) {
            $(".showtrending[data-num='" + this.state.chartnumber + "']").hide();
        }
        var comparedaterangecharttpes =  ["bar", "stackedbar", "line", "spline", "area", "stackedarea"];
		let compareDateRangeObj = chartDetails!=undefined && chartDetails["compareDateRangeObj"]!=null?chartDetails["compareDateRangeObj"]:null;
        let tempYaxis = chartDetails!=undefined?chartDetails["yAxis"]:[];
		let checkcondition = false;
		if(compareDateRangeObj!=null && compareDateRangeObj.hasOwnProperty("0") && compareDateRangeObj["0"]!=null){
			let yjsObj = compareDateRangeObj["0"]; 
			_.each(tempYaxis,function(val,index){
			if(val.columnName!=undefined&&val.columnName!=null&&yjsObj.hasOwnProperty(val.columnName)){
				checkcondition = true;
			}
		});
		}
		if(checkcondition&&comparedaterangecharttpes.indexOf(chartType)>=0){
			$(".showtrending[data-num='" + this.state.chartnumber + "']").hide();
			$(".chartfromdatabuttons" + this.state.chartnumber).hide();
			$(".sinbleviewchartdata[data-num='" + this.state.chartnumber + "']").hide();
		}
        if (chartType === "pivot") {
            $(".pivotsettingsmain" + this.state.chartnumber).show();
            $(".pivotcharticon" + this.state.chartnumber).show();
            $(".pivotnavigationbuttons" + this.state.chartnumber).show();
            $(".maxanim1[data-num='" + this.state.chartnumber + "']").find('.saved-data-buttons').hide();
        } else {
            $(".pivotsettingsmain" + this.state.chartnumber).hide();
            $(".pivotcharticon" + this.state.chartnumber).hide();
            $(".pivotnavigationbuttons" + this.state.chartnumber).hide();
        }
        if (chartType != "textchart") {
            //            $('#chart'+this.state.chartnumber).destroy();
            $('.richtextcontrolschart' + this.state.chartnumber).hide();
            $("#chart" + this.state.chartnumber).css({
                'display': '',
                'overflow-y': '',
                'overflow-x': '',
                'word-wrap': '',
                'max-width': ''
            });
        }
        $('.maxanim9 .btn-group button').removeClass('disabled');
        $('.paginationdiv' + this.state.chartnumber).hide();
        if (chartType != 'summarytable' && chartType != 'pivot' && chartType != 'doby')
            $('.boc-maxscreen-left-item[data-num=' + this.state.chartnumber + ']#showchart').addClass("showactive");
        //if(chartType!='pivot'){
        var ua = navigator.userAgent;
        var is_mobile_chrome = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua));

        var navbar = $('.navbar-fixed-top').height();
        var toolbar = $('.reporttoolbar[data-num="' + this.state.chartnumber + '"] .controlbar-main').height();
        var optionh = $('.fullscreenmain[data-num="' + this.state.chartnumber + '"] .maxanim9').height();
        var newfillterh = $('.tab-filters-container').height() + $(".filter-menu-toggle").outerHeight();
        var footer = $('.footer').height();
        var finalh = navbar + toolbar + optionh + newfillterh + footer + 22;
        if (is_mobile_chrome) {
            finalh = finalh + 60
        }
        if (chartType != 'pivot') {
            $("#chart" + this.state.chartnumber).css("height", "calc(100vh - " + finalh + "px)");
        } else if (chartType == 'pivot') {
            finalh = finalh - 40
            $("#chart" + this.state.chartnumber).css("height", "calc(100vh - " + finalh + "px)");
        }
        //}

        /*Series Colors*/
        var currentColorTheme = ChartInfo.functions.getColorTheme(colorThemeIndex);

        //Show Series Color option to selected charts
        if (chartType === "multicolorbar" || chartType === "pie" || chartType === "3dpie" || chartType === "donut" || chartType === "semicircle" || chartType === "funnel" || chartType === "pyramid" || chartType === "trellis" || chartType === "sparkline" || chartType === "solidgauge" || chartType === "fullcirclegauge" || chartType === "sankey" || chartType === "sunburst" || chartType === "wordcloud" || chartType === "boxplot" || chartType === "heatmap" || chartType === "textchart" || chartType === "doby" || chartType === "pivot" || chartType === "summarytable" || chartType == "predictionchart" || chartType === "leaflet" || chartType === "srilankamap" || chartType === "countrymap" || chartType == "indiamap" || chartType == "world" || chartType == "h2o_variableimportance" || chartType == "h2o_scorehistory" || chartType == "h2o_datatable") {
            $(".chartadvancedoptions[data-num='chart" + this.state.chartnumber + "']").hide();
        } else {
            $(".chartadvancedoptions[data-num='chart" + this.state.chartnumber + "']").show();
        }

        //Update default series colors in ChartUtil
        if (data != undefined && data.length > 0) {
            var seriesnames = [];
            if (data[0].name != undefined) {//Without Legend
                _.each(data, function (i, k) {
                    if (i.name != undefined) {
                        if (currentColorTheme[k] === undefined) {
                            currentColorTheme = currentColorTheme.concat(currentColorTheme)
                        }
                        seriesnames.push({ name: i.name, color: currentColorTheme[k] });
                    }
                });
                ChartInfo.functions.updateDefaultSeriesDetails(this.state.chartnumber, seriesnames);
            } else {//With Legend
                var serieslist = [];
                _.each(data, function (i, k) {
                    if (k % 2 != 0) {
                        serieslist = serieslist.concat(i);
                        _.each(serieslist, function (l, m) {
                            if (currentColorTheme[m] === undefined) {
                                currentColorTheme = currentColorTheme.concat(currentColorTheme)
                            }
                            seriesnames[m] = { name: l.name, color: currentColorTheme[m] };
                        });
                    }
                });
                ChartInfo.functions.updateDefaultSeriesDetails(this.state.chartnumber, seriesnames);
            }
        }

        //Update Custom series colors in ChartUtil
        var customseirescolors;
        if (chartType != "heatmap") {
            customseirescolors = ChartInfo.functions.getCustomSeriesColorsDetails(this.state.chartnumber);
        }
        if (data != undefined) {
            if (customseirescolors != undefined) {
                if (data[0].name != undefined) {//Without Legend
                    _.each(customseirescolors, function (i, k) {
                        var iname = i.name;
                        var icolor = i.color;
                        var uniqueid = i.uniqueid;
                        _.each(data, function (l, m) {
                            if (data[m].name == iname) {
                                if (data[m].name + m === iname + uniqueid) {
                                    data[m].color = icolor;
                                }
                            }
                        });
                    });

                } else {//With Legend
                    var matcharray = [];
                    _.each(customseirescolors, function (i, k) {
                        var iname = i.name;
                        var icolor = i.color;
                        _.each(data, function (l, m) {
                            if (m % 2 != 0) {
                                _.each(data[m], function (n, o) {
                                    if (n != undefined && n != "") {

                                        if (n.name === iname) {
                                            n.color = icolor;
                                            matcharray.push(n.name)
                                            return false;
                                        } else {
                                            if (matcharray.indexOf(n.name) === -1) {
                                                if (currentColorTheme[o] === undefined) {
                                                    currentColorTheme = currentColorTheme.concat(currentColorTheme)
                                                }
                                                if (n.color != undefined) {
                                                    n.color = currentColorTheme[o];
                                                }
                                            }
                                        }
                                    }
                                })
                            }
                        });
                    });
                }
            }
        }

        /*\Series Colors*/

        if (chartType === "piewithlegend") {
            //  ChartUtils.drawPieWithLegend(data,xaxisName, xaxisColumnName,xaxisdataType,yAxisNameArray,"chart"+this.options.chartnumber, chartType, legend, "max",this.features,this.isSharedReport,colorThemeIndex,chartSettings,trendstats,this.broker,tooltipNameArray,false,this.multifact,this.multifactentities,this.options.datamodelId,reportId,this.options.facttables);
            $("#spinnerchart" + this.state.chartnumber).hide();
            $(".spinner").hide();
        }
        else if (chartType === "pie" || chartType === "3dpie") {
            //  ChartUtils.drawPieChart(data,xaxisName, xaxisColumnName,xaxisdataType,yAxisNameArray,"chart"+this.options.chartnumber, chartType, legend, "max",this.features,this.isSharedReport,colorThemeIndex,chartSettings,tooltipNameArray,this.broker,false,false, yAxisDataTypeArray, yAxisAggArray,this.multifact,this.multifactentities,this.options.datamodelId,reportId,this.options.facttables,yAxisOriginalNameArray);
            $('#chart' + this.state.chartnumber).empty();
            var that = this.state;
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $("#chart" + this.state.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            ReactDOM.render(
                <BasicPieChart data={data} xaxisname={xaxisName} xaxisColumnName={xaxisColumnName} xaxisDataType={xaxisdataType}
                    yaxisnameArray={yAxisNameArray} chartid={"chart" + that.chartnumber} chartType={chartType} legend={legend} position={"max"} features={that.features} isSharedReport={that.isSharedReport}
                    colorThemeIndex={colorThemeIndex} chartSettings={chartSettings} tooltipNameArray={tooltipNameArray} algorithm={algorithm} periodsvalue={periodsvalue}
                    trendstats={trendstats} yAxisOriginalNameArray={yAxisOriginalNameArray} referenceLine={that.referenceLine} iscustomstoryboard={false}
                    yAxisDataTypeArray={yAxisDataTypeArray} yAxisAggArray={yAxisAggArray} isMLReport={undefined} columnsData={this.props.dimensions}
                    ismultifact={that.multifact} multifactentities={that.multifactentities} datamodelId={that.datamodelId} reportid={reportId}
                    facttables={that.facttables} />, document.getElementById(dynamicdiv)
            );
            $("#spinnerchart" + this.state.chartnumber).hide();
            $(".spinner").hide();
        }
        else if (chartType === "donut" || chartType === "semicircle") {
            //  ChartUtils.drawDonutChart(data,xaxisName, xaxisColumnName,xaxisdataType,yAxisNameArray,"chart"+this.options.chartnumber,chartType, legend, "max",this.features,this.isSharedReport,colorThemeIndex,chartSettings,tooltipNameArray,this.broker,trendstats,false, yAxisDataTypeArray, yAxisAggArray,this.multifact,this.multifactentities,this.options.datamodelId,reportId,this.options.facttables,yAxisOriginalNameArray);
            var that = this.state;
            $('#chart' + that.chartnumber).empty();
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            ReactDOM.render(
                <DonutChart data={data} xaxisname={xaxisName} xaxisColumnName={xaxisColumnName} xaxisDataType={xaxisdataType}
                    yaxisnameArray={yAxisNameArray} chartid={"chart" + that.chartnumber} chartType={chartType} legend={legend} position={"max"} features={that.features} isSharedReport={that.isSharedReport}
                    colorThemeIndex={colorThemeIndex} chartSettings={chartSettings} tooltipNameArray={tooltipNameArray} algorithm={algorithm} periodsvalue={periodsvalue}
                    trendstats={trendstats} yAxisOriginalNameArray={yAxisOriginalNameArray} referenceLine={that.referenceLine} iscustomstoryboard={false}
                    yAxisDataTypeArray={yAxisDataTypeArray} yAxisAggArray={yAxisAggArray} isMLReport={undefined} columnsData={this.props.dimensions}
                    ismultifact={that.multifact} multifactentities={that.multifactentities} datamodelId={that.datamodelId} reportid={reportId}
                    facttables={that.facttables} />, document.getElementById(dynamicdiv)
            );
            //$("#spinnerchart" + that.chartnumber).hide();  
            $("#spinnerchart" + this.state.chartnumber).hide();
            $('.spinner').hide();
        }
        else if (chartType === "line" || chartType === "spline" || chartType === "invertedline" || chartType === "multixline" || chartType === "spiderweb" || chartType === "polar") {
            //  ChartUtils.drawLineChart(data,xaxisName, xaxisColumnName,xaxisdataType,yAxisNameArray,"chart"+this.options.chartnumber, chartType, legend, "max",this.features,this.isSharedReport,colorThemeIndex,chartSettings,tooltipNameArray, algorithm,periodsvalue,this.broker,trendstats,yAxisOriginalNameArray,this.referenceLine,false, yAxisDataTypeArray, yAxisAggArray,undefined,this.multifact,this.multifactentities,this.options.datamodelId,reportId,this.options.facttables);
            var that = this.state;
            $('#chart' + that.chartnumber).empty();
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            ReactDOM.render(<LineChart data={data} xaxisName={xaxisName} xaxisColumnName={xaxisColumnName} xaxisDataType={xaxisdataType}
                yaxisnameArray={yAxisNameArray} chartid={"chart" + that.chartnumber} chartType={chartType} legend={legend} position={"max"} features={that.features} isSharedReport={that.isSharedReport}
                colorThemeIndex={colorThemeIndex} chartSettings={chartSettings} tooltipNameArray={tooltipNameArray} algorithm={algorithm}
                periodsvalue={periodsvalue} trendstats={trendstats} yAxisOriginalNameArray={yAxisOriginalNameArray} referenceLine={that.referenceLine} iscustomstoryboard={false}
                yAxisDataTypeArray={yAxisDataTypeArray} yAxisAggArray={yAxisAggArray} isMLReport={undefined} columnsData={this.props.dimensions} ismultifact={that.multifact} multifactentities={that.multifactentities} datamodelId={that.datamodelId} reportid={reportId} facttables={that.facttables} ></LineChart>, document.getElementById(dynamicdiv));

            $("#spinnerchart" + that.chartnumber).hide();
            $("#spinner" + that.chartnumber).hide();
        }
        else if (chartType === "bubble") {
            //   ChartUtils.drawBubbleChart(data,xaxisName, xaxisColumnName,xaxisdataType,yAxisNameArray,"chart"+this.options.chartnumber, chartType, legend, "max",this.features,this.isSharedReport,colorThemeIndex,chartSettings,tooltipNameArray,this.broker,trendstats,zAxisNameArray,false,this.referenceLine,yAxisDataTypeArray, yAxisAggArray,undefined,this.multifact,this.multifactentities,this.options.datamodelId,reportId,this.options.facttables,yAxisOriginalNameArray,zAxisAggArray);
            var that = this.state;
            $('#chart' + that.chartnumber).empty();
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            ReactDOM.render(
                <BubbleChart
                    data={data} xaxisname={xaxisName} xaxisColumnName={xaxisColumnName} xaxisDataType={xaxisdataType} yAxisNameArray={yAxisNameArray}
                    chartid={"chart" + that.chartnumber} chartType={chartType} legend={legend} position={"max"} features={that.features}
                    isSharedReport={that.isSharedReport} colorThemeIndex={colorThemeIndex} chartSettings={chartSettings}
                    tooltipNameArray={tooltipNameArray} trendstats={trendstats} zAxisNameArray={zAxisNameArray} zAxisAggArray={zAxisAggArray} iscustomstoryboard={false}
                    referenceLine={that.referenceLine} yAxisDataTypeArray={yAxisDataTypeArray} yAxisAggArray={yAxisAggArray}
                    isMLReport={undefined} ismultifact={that.multifact} multifactentities={that.multifactentities} columnsData={this.props.dimensions}
                    datamodelId={that.datamodelId} reportid={reportId} facttables={that.facttables} yAxisOriginalNameArray={yAxisOriginalNameArray}
                />, document.getElementById(dynamicdiv));
            $("#spinnerchart" + that.chartnumber).hide();
            $("#spinner" + that.chartnumber).hide();
        }
        else if (chartType === "stackedarea" || chartType === "area" || chartType === "invertedarea" || chartType === "arearange" || chartType === "multixarea") {
            //   ChartUtils.drawStackedAreaChart(data,xaxisName, xaxisColumnName,xaxisdataType,yAxisNameArray,"chart"+this.options.chartnumber, chartType, legend, "max",this.features,this.isSharedReport,colorThemeIndex,chartSettings,tooltipNameArray, algorithm, periodsvalue,this.broker,trendstats,yAxisOriginalNameArray,this.referenceLine,false,yAxisDataTypeArray, yAxisAggArray,this.multifact,this.multifactentities,this.options.datamodelId,reportId,this.options.facttables);
            var that = this.state;
            $('#chart' + that.chartnumber).empty();
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            ReactDOM.render(<AreaChart data={data} xaxisname={xaxisName} xaxisColumnName={xaxisColumnName} xaxisDataType={xaxisdataType}
                yaxisnameArray={yAxisNameArray} chartid={"chart" + that.chartnumber} chartType={chartType} legend={legend} position={"max"} features={that.features} isSharedReport={that.isSharedReport}
                colorThemeIndex={colorThemeIndex} chartSettings={chartSettings} tooltipNameArray={tooltipNameArray} algorithm={algorithm} periodsvalue={periodsvalue}
                trendstats={trendstats} yAxisOriginalNameArray={yAxisOriginalNameArray} referenceLine={that.referenceLine} iscustomstoryboard={false}
                yAxisDataTypeArray={yAxisDataTypeArray} yAxisAggArray={yAxisAggArray} isMLReport={undefined} columnsData={this.props.dimensions} ismultifact={that.multifact} multifactentities={that.multifactentities} datamodelId={that.datamodelId} reportid={reportId} facttables={that.facttables} ></AreaChart>, document.getElementById(dynamicdiv));
            $("#spinnerchart" + that.chartnumber).hide();
            $("#spinner" + that.chartnumber).hide();
        }
        else if (chartType === "bar" || chartType === "multicolorbar" || chartType === "invertedbar" || chartType === "stackedbar" || chartType === "invertedstackedbar" || chartType === "multiaxisbar" || chartType === "3dbar" || chartType === "multixbar" || chartType === "windrose" || chartType === "waterfall") {
            $('#chart' + this.state.chartnumber).empty();
            chartSettings.dThiddenFilters = chartDetails.xAxis;
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $("#chart" + this.state.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            //yAxisAggArray,undefined,this.multifact,this.multifactentities,this.options.datamodelId,reportId,this.options.facttables);
            ReactDOM.render(<BarChart data={data} xaxisname={xaxisName} xaxisColumnName={xaxisColumnName} xaxisDataType={xaxisdataType}
                yaxisnameArray={yAxisNameArray} chartid={"chart" + this.state.chartnumber} chartType={chartType} legend={legend} position={"max"} features={this.state.features} isSharedReport={this.state.isSharedReport}
                colorThemeIndex={colorThemeIndex} chartSettings={chartSettings} tooltipNameArray={tooltipNameArray} algorithm={algorithm} periodsvalue={periodsvalue}
                trendstats={trendstats} yAxisOriginalNameArray={yAxisOriginalNameArray} referenceLine={this.state.referenceLine} iscustomstoryboard={false}
                yAxisDataTypeArray={yAxisDataTypeArray} yAxisAggArray={yAxisAggArray} isMLReport={undefined} columnsData={this.props.dimensions} ismultifact={this.state.multifact} multifactentities={this.state.multifactentities} datamodelId={this.state.datamodelId} reportid={reportId} facttables={this.state.facttables} ></BarChart>, document.getElementById(dynamicdiv));
            $("#spinnerchart" + this.state.chartnumber).hide();
            $(".spinner").hide();
        }
        else if (chartType === "sankey") {
            var that = this.state;
            $('#chart' + that.chartnumber).empty();
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            //   ChartUtils.drawSankeyChart(data,xaxisName,xaxisColumnName,xaxisdataType,yAxisNameArray,"chart"+this.options.chartnumber, chartType, legend, "max",this.features,this.isSharedReport,colorThemeIndex,chartSettings,tooltipNameArray, algorithm,periodsvalue,this.broker,trendstats,yAxisOriginalNameArray,this.referenceLine);
            ReactDOM.render(
				<SankeyChart data={data} xaxisname={xaxisName} xaxisColumnName={xaxisColumnName} xaxisDataType={xaxisdataType} yaxisnameArray={yAxisNameArray} chartid={"chart" + that.chartnumber}
					chartType={chartType} legend={legend} position={"max"} features={that.features} isSharedReport={that.isSharedReport} colorThemeIndex={colorThemeIndex} chartSettings={chartSettings} tooltipNameArray={tooltipNameArray}
					trendstats={trendstats} iscustomstoryboard={false} yAxisOriginalNameArray={yAxisOriginalNameArray} reportid={reportId} facttables={that.facttables} datamodelId={that.datamodelId} isMLReport={undefined} multifactentities={that.multifactentities} ismultifact={that.multifact} columnsData={that.reportcolumns} />, document.getElementById(dynamicdiv)
			);
            $("#spinnerchart" + this.state.chartnumber).hide();
            $(".spinner").hide();
        } else if (chartType === "sunburst") {
            //  ChartUtils.drawSunburstChart(data,xaxisName,xaxisColumnName,xaxisdataType,yAxisNameArray,"chart"+this.options.chartnumber, chartType, legend, "max",this.features,this.isSharedReport,colorThemeIndex,chartSettings,tooltipNameArray, algorithm,periodsvalue,this.broker,trendstats,yAxisOriginalNameArray,this.referenceLine);
            $("#spinnerchart" + this.options.chartnumber).hide();
            $(".spinner").hide();
        }
        else if (chartType === "wordcloud") {
            //  ChartUtils.drawWordCloudChart(data,xaxisName, xaxisColumnName,xaxisdataType,yAxisNameArray,"chart"+this.options.chartnumber, chartType, legend, "max",this.features,this.isSharedReport,colorThemeIndex,chartSettings,tooltipNameArray,algorithm, periodsvalue,this.broker,trendstats,yAxisOriginalNameArray,this.options.chartnumber,undefined,undefined,false,this.multifact,this.multifactentities,this.options.datamodelId,this.options.facttables,yAxisOriginalNameArray);
            var that = this.state;
            $('#chart' + that.chartnumber).empty();
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            ReactDOM.render(<WordCloudChart data={data} xaxisname={xaxisName} xaxisColumnName={xaxisColumnName} xaxisDataType={xaxisdataType}
                yaxisnameArray={yAxisNameArray} chartid={"chart" + that.chartnumber} chartType={chartType} legend={legend} position={"max"} features={that.features} isSharedReport={that.isSharedReport}
                colorThemeIndex={colorThemeIndex} chartSettings={chartSettings} tooltipNameArray={tooltipNameArray} algorithm={algorithm} periodsvalue={periodsvalue}
                chartnumber={that.chartnumber} trendstats={trendstats} yAxisOriginalNameArray={yAxisOriginalNameArray} referenceLine={that.referenceLine} iscustomstoryboard={false}
                yAxisDataTypeArray={yAxisDataTypeArray} yAxisAggArray={yAxisAggArray} isMlChart={undefined} isMLReport={undefined} columnsData={this.props.dimensions} ismultifact={that.multifact}
                multifactentities={that.multifactentities} datamodelId={that.datamodelId} reportid={reportId} facttables={that.facttables} />, document.getElementById(dynamicdiv));
            $("#spinnerchart" + that.chartnumber).hide();
            $("#spinner" + that.chartnumber).hide();
        }
        else if (chartType === "boxplot") {
            //  ChartUtils.drawBoxPlotChart(data,xaxisName,xaxisColumnName,xaxisdataType,yAxisNameArray,"chart"+this.options.chartnumber, chartType, legend, "max",this.features,this.isSharedReport,colorThemeIndex,chartSettings,tooltipNameArray, algorithm,periodsvalue,this.broker,trendstats,yAxisOriginalNameArray,this.referenceLine,reportId);
            var that = this.state;
            $('#chart' + that.chartnumber).empty();
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            ReactDOM.render(<BoxplotChart data={data} xaxisname={xaxisName} xaxisColumnName={xaxisColumnName} xaxisDataType={xaxisdataType}
                yaxisnameArray={yAxisNameArray} chartid={"chart" + that.chartnumber} chartType={chartType} legend={legend} position={"max"} features={that.features} isSharedReport={that.isSharedReport}
                colorThemeIndex={colorThemeIndex} chartSettings={chartSettings} tooltipNameArray={tooltipNameArray} algorithm={algorithm} periodsvalue={periodsvalue}
                trendstats={trendstats} yAxisOriginalNameArray={yAxisOriginalNameArray} referenceLine={that.referenceLine} iscustomstoryboard={false}
                yAxisDataTypeArray={yAxisDataTypeArray} yAxisAggArray={yAxisAggArray} isMLReport={undefined} columnsData={that.reportcolumns} ismultifact={that.multifact} multifactentities={that.multifactentities} datamodelId={that.datamodelId} reportid={reportId} facttables={that.facttables} />, document.getElementById(dynamicdiv));
            $("#spinnerchart" + that.chartnumber).hide();
            $("#spinner" + that.chartnumber).hide();;
        }
        else if (chartType === "treemap") {
            //  ChartUtils.drawTreemapChart(data,xaxisName,xaxisColumnName,xaxisdataType,yAxisNameArray,"chart"+this.options.chartnumber, chartType, legend, "max",this.features,this.isSharedReport,colorThemeIndex,chartSettings,tooltipNameArray, algorithm,periodsvalue,this.broker,trendstats,yAxisOriginalNameArray,this.referenceLine);
            $("#spinnerchart" + this.options.chartnumber).hide();
        }
        else if (chartType === "combinationchart") {
            //   ChartUtils.drawCombinationChart(data,xaxisName, xaxisColumnName,xaxisdataType,yAxisNameArray,"chart"+this.options.chartnumber, chartType, legend, "max",this.features,this.isSharedReport,colorThemeIndex,chartSettings,tooltipNameArray,algorithm,periodsvalue, this.broker,trendstats,yAxisOriginalNameArray,this.referenceLine,false,yAxisDataTypeArray, yAxisAggArray,undefined,this.multifact,this.multifactentities,this.options.datamodelId,reportId,this.options.facttables);
            var that = this.state;
            $('#chart' + that.chartnumber).empty();
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");

            ReactDOM.render(<CombinationChart data={data} xaxisname={xaxisName} xaxisColumnName={xaxisColumnName} xaxisDataType={xaxisdataType}
                yaxisnameArray={yAxisNameArray} chartid={"chart" + that.chartnumber} chartType={chartType} legend={legend} position={"max"} features={that.features} isSharedReport={that.isSharedReport}
                colorThemeIndex={colorThemeIndex} chartSettings={chartSettings} tooltipNameArray={tooltipNameArray} algorithm={algorithm} periodsvalue={periodsvalue}
                trendstats={trendstats} yAxisOriginalNameArray={yAxisOriginalNameArray} referenceLine={that.referenceLine} iscustomstoryboard={false}
                yAxisDataTypeArray={yAxisDataTypeArray} yAxisAggArray={yAxisAggArray} isMLReport={undefined} columnsData={this.props.dimensions} ismultifact={that.multifact} multifactentities={that.multifactentities} datamodelId={that.datamodelId} reportid={reportId} facttables={that.facttables} />, document.getElementById(dynamicdiv));
            $("#spinnerchart" + that.chartnumber).hide();
            $("#spinner" + that.chartnumber).hide();
        }
        else if (chartType === "trellis") {
            //   ChartUtils.drawTrellisChart(data,xaxisName, xaxisColumnName,xaxisdataType,yAxisNameArray,"chart"+this.options.chartnumber, chartType, legend, "max",this.features,this.isSharedReport,colorThemeIndex,chartSettings,tooltipNameArray,algorithm,periodsvalue,this.broker,trendstats, false, yAxisDataTypeArray, yAxisAggArray, yAxisOriginalNameArray,this.multifact,this.multifactentities,this.options.datamodelId,this.options.facttables);
            $("#singlesavedchartdiv").hide();
            var that = this.state;
            $('#chart' + that.chartnumber).empty();
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "' style='height:0px !important'></div>");

            ReactDOM.render(<TrellisChart data={data} xaxisname={xaxisName} xaxisColumnName={xaxisColumnName} xaxisDataType={xaxisdataType}
                yaxisnameArray={yAxisNameArray} chartid={"chart" + that.chartnumber} chartType={chartType} legend={legend} position={"max"} features={that.features} isSharedReport={that.isSharedReport}
                colorThemeIndex={colorThemeIndex} chartSettings={chartSettings} tooltipNameArray={tooltipNameArray} algorithm={algorithm} periodsvalue={periodsvalue}
                trendstats={trendstats} iscustomstoryboard={false} yAxisOriginalNameArray={yAxisOriginalNameArray}
                yAxisDataTypeArray={yAxisDataTypeArray} yAxisAggArray={yAxisAggArray} ismultifact={that.multifact} multifactentities={that.multifactentities} datamodelId={that.datamodelId} />, document.getElementById(dynamicdiv));
            $("#spinnerchart" + that.chartnumber).hide();
            $("#spinner" + that.chartnumber).hide();
        } else if (chartType === "sparkline") {
            $("#singlesavedchartdiv").hide();
            $(".saved-data-buttons[data-num='" + this.state.chartnumber + "']").hide();
            $(".showtrending[data-num='" + this.state.chartnumber + "']").hide();
            //  ChartUtils.drawSparklineChart(data,xaxisName, xaxisColumnName,xaxisdataType,yAxisNameArray,"chart"+this.options.chartnumber, chartType, legend, "max",this.features,this.isSharedReport,colorThemeIndex,chartSettings,tooltipNameArray,algorithm, periodsvalue,this.broker,trendstats,yAxisDataTypeArray,yAxisAggArray,undefined,this.multifact,this.multifactentities,this.options.datamodelId,this.options.facttables);
            var that = this.state;
            $('#chart' + that.chartnumber).empty();
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "' style='height:0px !important'></div>");
            ReactDOM.render(<SparkLineChart data={data} xaxisname={xaxisName} xaxisColumnName={xaxisColumnName} xaxisDataType={xaxisdataType}
                yaxisnameArray={yAxisNameArray} chartid={"chart" + that.chartnumber} chartType={chartType} legend={legend} position={"max"} features={that.features} isSharedReport={that.isSharedReport}
                colorThemeIndex={colorThemeIndex} chartSettings={chartSettings} tooltipNameArray={tooltipNameArray} algorithm={algorithm} periodsvalue={periodsvalue}
                trendstats={trendstats} yAxisOriginalNameArray={yAxisOriginalNameArray} referenceLine={that.referenceLine} iscustomstoryboard={false}
                yAxisDataTypeArray={yAxisDataTypeArray} yAxisAggArray={yAxisAggArray} isMLReport={undefined} columnsData={this.props.dimensions} ismultifact={that.multifact} multifactentities={that.multifactentities} datamodelId={that.datamodelId} reportid={reportId} facttables={that.facttables} />, document.getElementById(dynamicdiv));
            $("#spinnerchart" + that.chartnumber).hide();
            $("#spinner" + that.chartnumber).hide();
        }
        else if (chartType === "heatmap") {
            //   ChartUtils.drawHeatMapChart(data,xaxisName, xaxisColumnName,xaxisdataType,yAxisNameArray,"chart"+this.options.chartnumber, chartType, legend, "max",this.features,this.isSharedReport,colorThemeIndex,chartSettings,tooltipNameArray,this.broker,trendstats,false,undefined,this.multifact,this.multifactentities,this.options.datamodelId,reportId,this.options.facttables);
            var that = this.state;
            $('#chart' + that.chartnumber).empty();
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            ReactDOM.render(
                <HeatMapChart data={data} xaxisname={xaxisName} xaxisColumnName={xaxisColumnName} xaxisDataType={xaxisdataType} yaxisnameArray={yAxisNameArray} chartid={"chart" + that.chartnumber}
                    chartType={chartType} legend={legend} position={"max"} features={that.features} isSharedReport={that.isSharedReport} colorThemeIndex={colorThemeIndex} chartSettings={chartSettings} tooltipNameArray={tooltipNameArray}
                    trendstats={trendstats} iscustomstoryboard={false} yAxisOriginalNameArray={yAxisOriginalNameArray} reportid={reportId} facttables={that.facttables} datamodelId={that.datamodelId} isMLReport={undefined} multifactentities={that.multifactentities} ismultifact={that.multifact} columnsData={this.props.dimensions} />, document.getElementById(dynamicdiv)
            );
            $("#spinnerchart" + that.chartnumber).hide();
            $("#spinner" + that.chartnumber).hide();
        }
        else if (chartType === "scatter") {
            var that = this.state;
            //   ChartUtils.drawscatterChart(data,xaxisName, xaxisColumnName,xaxisdataType,yAxisNameArray,"chart"+this.options.chartnumber, chartType, legend, "max",this.features,this.isSharedReport,colorThemeIndex,chartSettings,tooltipNameArray,this.broker,trendstats,false,this.referenceLine, yAxisDataTypeArray, yAxisAggArray,undefined,this.multifact,this.multifactentities,this.options.datamodelId,reportId,this.options.facttables,yAxisOriginalNameArray);
            $('#chart' + that.chartnumber).empty();
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            ReactDOM.render(
                <ScatterChart data={data} xaxisname={xaxisName} xaxisColumnName={xaxisColumnName} xaxisDataType={xaxisdataType} yaxisnameArray={yAxisNameArray} chartid={"chart" + that.chartnumber} columnsData={this.props.dimensions}
                    chartType={chartType} legend={legend} position={"max"} features={that.features} isSharedReport={that.isSharedReport} colorThemeIndex={colorThemeIndex} chartSettings={chartSettings} tooltipNameArray={tooltipNameArray}
                    trendstats={trendstats} iscustomstoryboard={false} referenceLine={that.referenceLine} yAxisDataTypeArray={yAxisDataTypeArray} yAxisAggArray={yAxisAggArray} isMLReport={undefined}
                    multifactentities={that.multifactentities} ismultifact={that.multifact} datamodelId={that.datamodelId} reportid={reportId} facttables={that.facttables} yAxisOriginalNameArray={yAxisOriginalNameArray} />, document.getElementById(dynamicdiv)
            );
            $("#spinnerchart" + that.chartnumber).hide();
            $("#spinner" + that.chartnumber).hide();
        }
        else if (chartType === "bartimeseries" || chartType === "linetimeseries" || chartType === "areatimeseries" || chartType === "multixbar") {
            //  ChartUtils.drawTimeSeries(data,xaxisName,xaxisColumnName,xaxisdataType,yAxisNameArray,"chart"+this.options.chartnumber, chartType, legend, "max",this.features,this.isSharedReport,colorThemeIndex,chartSettings,tooltipNameArray, algorithm,periodsvalue,this.broker,trendstats,yAxisOriginalNameArray, false,this.referenceLine, yAxisDataTypeArray, yAxisAggArray,undefined,reportId);
            var that = this.state;
            $('#chart' + that.chartnumber).empty();
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            ReactDOM.render(<TimeseriesChart data={data} xaxisName={xaxisName} xaxisColumnName={xaxisColumnName} xaxisDataType={xaxisdataType}
                yaxisnameArray={yAxisNameArray} chartId={"chart" + that.chartnumber} chartType={chartType} legend={legend} position={"max"} features={that.features} isSharedReport={that.isSharedReport}
                colorThemeIndex={colorThemeIndex} chartSettings={chartSettings} tooltipNameArray={tooltipNameArray} algorithm={algorithm} periodsvalue={periodsvalue}
                trendstats={trendstats} yAxisOriginalNameArray={yAxisOriginalNameArray} referenceLine={that.referenceLine} iscustomstoryboard={false}
                yAxisDataTypeArray={yAxisDataTypeArray} yAxisAggArray={yAxisAggArray} isMLReport={undefined} columnsData={this.props.dimensions} ismultifact={that.multifact} multifactentities={that.multifactentities} datamodelId={that.datamodelId} reportid={reportId} facttables={that.facttables} />, document.getElementById(dynamicdiv));
            $("#spinnerchart" + that.chartnumber).hide();
            $("#spinner" + that.chartnumber).hide();
        }
        else if (chartType === "funnel" || chartType === "pyramid") {
            //  ChartUtils.drawFunnelChart(data,xaxisName, xaxisColumnName,xaxisdataType,yAxisNameArray,"chart"+this.options.chartnumber, chartType, legend, "max",this.features,this.isSharedReport,colorThemeIndex,chartSettings,tooltipNameArray,this.broker,trendstats,false,undefined,this.multifact,this.multifactentities,this.options.datamodelId,reportId,this.options.facttables,yAxisOriginalNameArray);
            var that = this.state;
            $('#chart' + that.chartnumber).empty();
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            ReactDOM.render(
                <FunnelChart data={data} xaxisname={xaxisName} xaxisColumnName={xaxisColumnName} columnsData={this.props.dimensions} xaxisDataType={xaxisdataType} yaxisnameArray={yAxisNameArray} chartid={"chart" + that.chartnumber} chartType={chartType} legend={legend} position={"max"} features={that.features} isSharedReport={that.isSharedReport} colorThemeIndex={colorThemeIndex}
                    chartSettings={chartSettings} tooltipNameArray={tooltipNameArray} trendstats={trendstats} iscustomstoryboard={false} isMLReport={undefined} ismultifact={that.multifact} multifactentities={that.multifactentities} datamodelId={that.datamodelId} reportid={reportId} facttables={that.facttables} yAxisOriginalNameArray={yAxisOriginalNameArray} />, document.getElementById(dynamicdiv)
            );
            $("#spinnerchart" + that.chartnumber).hide();
            $("#spinner" + that.chartnumber).hide();
        }
        else if (chartType === "world" || chartType == "countrymap") {
            var that = this.state;
            $('#chart' + that.chartnumber).empty();
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            ReactDOM.render(
                <WorldMap dynamicdiv={dynamicdiv} data={data} xaxisName={xaxisName} xaxisColumnName={xaxisColumnName} columnsData={that.reportcolumns} xaxisDataType={xaxisdataType} yaxisnameArray={yAxisNameArray} chartId={"chart" + that.chartnumber} chartType={chartType} legend={legend} position={"max"} features={that.features} isSharedReport={that.isSharedReport} colorThemeIndex={colorThemeIndex} chartSettings={chartSettings} tooltipNameArray={tooltipNameArray} trendstats={trendstats} iscustomstoryboard={false} xaxisidentity={xAxisIdentity} yAxisDataTypeArray={yAxisDataTypeArray} ismultifact={that.multifact} multifactentities={that.multifactentities} datamodelId={that.datamodelId} reportid={reportId} facttables={that.facttables} />, document.getElementById(dynamicdiv)
            );
            $("#spinner" + that.chartnumber).hide();
            $("#spinnerchart" + that.chartnumber).hide();
            //  ChartUtils.drawWorldMap(data,xaxisName,xaxisColumnName, xaxisdataType,yAxisNameArray,"chart"+this.options.chartnumber, chartType, legend, "max",this.features,this.isSharedReport,colorThemeIndex,chartSettings,tooltipNameArray,this.broker,trendstats,false,xAxisIdentity,yAxisDataTypeArray,this.multifact,this.multifactentities,this.options.datamodelId,reportId,this.options.facttables);
        }
        else if (chartType === "indiamap") {
            var that = this.state;
            $('#chart' + that.chartnumber).empty();
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            ReactDOM.render(
                <Indiamap dynamicdiv={dynamicdiv} data={data} xaxisName={xaxisName} xaxisColumnName={xaxisColumnName} columnsData={that.reportcolumns} xaxisDataType={xaxisdataType} yaxisnameArray={yAxisNameArray} chartId={"chart" + that.chartnumber} chartType={chartType} legend={legend} position={"max"} features={that.features} isSharedReport={that.isSharedReport} colorThemeIndex={colorThemeIndex} chartSettings={chartSettings} tooltipNameArray={tooltipNameArray} trendstats={trendstats} iscustomstoryboard={false} xaxisidentity={xAxisIdentity} yAxisDataTypeArray={yAxisDataTypeArray} ismultifact={that.multifact} multifactentities={that.multifactentities} datamodelId={that.datamodelId} reportid={reportId} facttables={that.facttables} />, document.getElementById(dynamicdiv)
            );
            $("#spinner" + that.chartnumber).hide();
            $("#spinnerchart" + that.chartnumber).hide();
            //  ChartUtils.drawWorldMap(data,xaxisName,xaxisColumnName, xaxisdataType,yAxisNameArray,"chart"+this.options.chartnumber, chartType, legend, "max",this.features,this.isSharedReport,colorThemeIndex,chartSettings,tooltipNameArray,this.broker,trendstats,false,xAxisIdentity,yAxisDataTypeArray,this.multifact,this.multifactentities,this.options.datamodelId,reportId,this.options.facttables);
        }
        else if (chartType === "metric") {
            $(".saved-data-buttons[data-num='" + this.state.chartnumber + "']").hide();
            var that = this.state;
            //  ChartUtils.drawMetricChart(data,xaxisName,xaxisColumnName,xaxisdataType,yAxisNameArray,"chart"+this.options.chartnumber, chartType, legend, "max",metrictype,metricdatefield,this.isSharedReport,colorThemeIndex,chartSettings,this.metricprefix,this.metricsuffix,this.broker,trendstats,this.metricfont);
            $('#chart' + that.chartnumber).empty();
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            ReactDOM.render(<MetricChart data={data} xaxisname={xaxisName} xaxisColumnName={xaxisColumnName} xaxisDataType={xaxisdataType}
                yaxisnameArray={yAxisNameArray} chartid={"chart" + that.chartnumber} chartType={chartType} legend={legend} position={"max"}
                metrictype={metrictype} metricdatefield={metricdatefield} tileno={that.chartnumber} colorThemeIndex={colorThemeIndex}
                chartSettings={chartSettings} metricprefix={that.metricprefix} metricsuffix={that.metricsuffix} iscustomstoryboard={false} trendstats={trendstats} metricfont={that.metricfont}
            ></MetricChart>, document.getElementById(dynamicdiv));
            $("#spinner" + that.chartnumber).hide();
            $("#spinnerchart" + that.chartnumber).hide();
        }
        else if (chartType === "solidgauge" || chartType === "fullcirclegauge") {
            $(".saved-data-buttons[data-num='" + this.state.chartnumber + "']").hide();
            //  ChartUtils.drawSolidgaugeChart(data, xaxisName, xaxisColumnName,xaxisdataType, yAxisNameArray, "chart"+ this.options.chartnumber,chartType, legend, "max",metrictype,metricdatefield,this.isSharedReport,colorThemeIndex,chartSettings,this.solidgaugemin,this.solidgaugemax,this.broker,false,this.features,undefined,this.multifact,this.multifactentities,this.options.datamodelId,reportId,this.options.facttables);							
            var that = this.state;
            $('#chart' + that.chartnumber).empty();
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            ReactDOM.render(<GuageChart data={data} xaxisname={xaxisName} xaxisColumnName={xaxisColumnName} xaxisDataType={xaxisdataType}
                yaxisnameArray={yAxisNameArray} chartid={"chart" + that.chartnumber} chartType={chartType} legend={legend} position={"max"}
                metrictype={metrictype} metricdatefield={metricdatefield} isSharedReport={that.isSharedReport} tileno={that.chartnumber} colorThemeIndex={colorThemeIndex}
                chartSettings={chartSettings} solidgaugemin={that.solidgaugemin} solidgaugemax={that.solidgaugemax} iscustomstoryboard={false}
                features={that.features} isMLReport={undefined} ismultifact={that.multifact} multifactentities={that.multifactentities} datamodelId={that.datamodelId} reportid={that.reportId} facttables={that.facttables} />, document.getElementById(dynamicdiv));
            $("#spinner" + that.chartnumber).hide();
            $("#spinnerchart" + that.chartnumber).hide();
        }
        else if (chartType === "textchart") {
            $('.saved-data-buttons[data-num=' + this.state.chartnumber + ']').hide();
            var that = this.state;
            //  ChartUtils.textChart(chartDetails,"chart"+this.options.chartnumber, chartType, "max", this.features,this.isSharedReport,this.broker,trendstats);
            $('#chart' + that.chartnumber).empty();
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            if(chartDetails!=undefined && chartDetails['richtextcontent']!=undefined){
                $("#chart" + that.chartnumber).empty();
                $("#chart" + that.chartnumber).html(decodeURIComponent(chartDetails['richtextcontent']));
            }
           // $("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            ReactDOM.render(<TextChart data={chartDetails} chartId={"chart" + that.chartnumber} chartType={chartType} position={"max"} features={that.features} isSharedReport={that.isSharedReport} iscustomstoryboard={false} />, document.getElementById("chart" + that.chartnumber));
            $("#spinnerchart" + that.chartnumber).hide();
            //            $('#chart'+this.state.chartnumber).destroy();
            $('.editrichtext1[data-num=' + this.state.chartnumber + ']').removeClass('active');
            $('.saverichtext1[data-num=' + this.state.chartnumber + ']').removeClass('active');
            $("#chart" + this.state.chartnumber).css({
                'display': 'block',
                'height': 'calc(100vh - 240px)',
                'word-wrap': 'break-word',
                'overflow-y': 'auto',
                'overflow-x': 'auto',
                //'max-width': 'calc(100vw - 150px)'
            });

            $('.countcharacters').text("Characters Count Limit:" + (14000 - ($('#chart' + this.state.chartnumber).summernote('code') || "").replace(/(<([^>]+)>)/ig, "").replace(/( )/, " ").length));
            if ($('#chart' + this.state.chartnumber).is(':empty')) {
                $('.editrichtext1').click();
            }else{
                $('#chart' + this.state.chartnumber).summernote('destroy');
            }
            $("#spinnerchart" + this.state.chartnumber).hide();
            $('.spinner').hide();
        }
        else if (chartType === "doby") {
            var dobySelectedColumn = chartDetails["dobyselectedcolumns"]
            $('.boc-maxscreen-left-item.dobygrid1').addClass("showactive");
            $('.saved-data-buttons').hide();
            $('.pivotsettingsmain' + this.state.chartnumber).hide();
            $(".pivotcharticon" + this.state.chartnumber).hide();
            $(".pivotnavigationbuttons" + this.state.chartnumber).hide();
            var dobySelectedColumn = chartDetails["dobyselectedcolumns"]
            if (dobySelectedColumn == undefined) {
                dobySelectedColumn = xaxisName;
            }
            this.viewDataTable(this.state.chartnumber, dobySelectedColumn, "applymethod", "max", this.state.features, this.isSharedReport, colorThemeIndex, tableInfo);
           var that = this;
            setTimeout(function(e){
               $("#sinbleviewchartfromchartdataview[data-num='" + that.state.chartnumber + "']").hide();
               $("#sinbleviewchartdataview[data-num='" + that.state.chartnumber + "']").hide();
               $("#dobygrid1datatable").addClass("showactive");
           },1000)
            $('.paginationdiv' + this.state.chartnumber).show();
            //$("#spinnerchart"+this.options.chartnumber).hide();
            $(".spinner").hide();
        }
        else if (chartType === "pivot") {
            $('.boc-maxscreen-left-item.pivotgrid1').addClass("showactive");
            $(".pivotsettingsmain" + this.state.chartnumber).show();
            $(".pivotcharticon" + this.state.chartnumber).show();
            $(".pivotnavigationbuttons" + this.state.chartnumber).show();
            this.viewPivotTable(this.state.chartnumber, this.state.viewcollection.getMeasures(), this.state.viewcollection.getDimensions(), this.pos, applyattributes);
            $("#spinnerchart" + this.state.chartnumber).hide();
        }
        else if (chartType === "leaflet") {
            var that = this.state;
            $('#chart' + that.chartnumber).empty();
            var today = new Date();
            var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
            $("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
            ReactDOM.render(
                <Leaflet dynamicdiv={dynamicdiv} data={data} xaxisName={xaxisName} xaxisColumnName={xaxisColumnName} columnsData={that.reportcolumns} xaxisDataType={xaxisdataType} yaxisnameArray={yAxisNameArray} chartId={"chart" + that.chartnumber} chartType={chartType} legend={legend} position={"max"} features={that.features} isSharedReport={that.isSharedReport} colorThemeIndex={colorThemeIndex} chartSettings={chartSettings} tooltipNameArray={tooltipNameArray} trendstats={trendstats} iscustomstoryboard={false} xaxisidentity={xAxisIdentity} yAxisDataTypeArray={yAxisDataTypeArray} ismultifact={that.multifact} multifactentities={that.multifactentities} datamodelId={that.datamodelId} reportid={reportId} facttables={that.facttables} />, document.getElementById(dynamicdiv)
            );
            $("#spinner" + that.chartnumber).hide();
            //  ChartUtils.drawLeaflet(data,xaxisName,xaxisColumnName,xaxisdataType,yAxisNameArray,"chart"+this.state.chartnumber, chartType, legend, "max",this.features,this.isSharedReport,colorThemeIndex,chartSettings,tooltipNameArray,this.broker,trendstats,false,xAxisIdentity,yAxisDataTypeArray,this.multifact,this.multifactentities,this.state.datamodelId,this.state.facttables,reportId);
            $("#spinnerchart" + this.state.chartnumber).hide();
        }
        else if (chartType === "srilankamap") {
            var that = this.state;
            $("#spinnerchart" + that.chartnumber).show();
			$('#chart' + that.chartnumber).empty();
			var today = new Date();
			var dynamicdiv = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
			dynamicdiv = dynamicdiv + "" + Math.floor(Math.random() * 1000);
			$("#chart" + that.chartnumber).append("<div className='" + dynamicdiv + " hide' id='" + dynamicdiv + "'></div>");
			ReactDOM.render(<SrilankaChart data={data} xaxisName={xaxisName} xaxisColumnName={xaxisColumnName} xaxisDataType={xaxisdataType}
				yaxisnameArray={yAxisNameArray} chartId={"chart" + that.chartnumber} chartType={chartType} legend={legend}  features={that.features} isSharedReport={that.isSharedReport}
				colorThemeIndex={colorThemeIndex} chartSettings={chartSettings} tooltipNameArray={tooltipNameArray} trendstats={trendstats} iscustomstoryboard={false}
				xaxisidentity={xAxisIdentity} yAxisDataTypeArray={yAxisDataTypeArray} ismultifact={that.multifact} multifactentities={that.multifactentities} datamodelId={that.datamodelId} reportid={reportId} facttables={that.facttables} dynamicdiv={dynamicdiv} columnsData={that.reportcolumns} />, document.getElementById(dynamicdiv));
			$("#spinnerchart" + that.chartnumber).hide();
			$("#spinner" + that.chartnumber).hide();
        }
        else if (chartType === "summarytable") {
            $('.boc-maxscreen-left-item.singlereport_summary_table').addClass("showactive");
            this.viewSummaryTabe(this.state.chartnumber, this.state.viewcollection.getMeasures(), this.state.viewcollection.getDimensions(), this.pos, chartDetails, applyattributes);
            //$("#spinnerchart"+this.state.chartnumber).hide();
            $(".spinner").hide();
            $('.btn-toolbar').show();
        } else if (chartType == "predictionchart" || chartType == "h2o_variableimportance" || chartType == "h2o_scorehistory" || chartType == "h2o_datatable") {
            this.predictionChart(this.state.chartnumber);
            $(".spinner").hide();
        }
        else if (chartType == "pivotstackedbar" || chartType == "pivotstackedarea") {
            this.broker.trigger("showPivotchart", this.state.chartnumber, chartType);
            $(".spinner").hide();
        }
        else {//default
            //ChartUtils.drawBarChart(data,xaxisName, xaxisColumnName,xaxisdataType,yAxisNameArray,"chart"+this.state.chartnumber, "bar", legend, "max",this.features,this.isSharedReport,colorThemeIndex,chartSettings,tooltipNameArray,this.broker,trendstats,yAxisOriginalNameArray, false, false, yAxisDataTypeArray,undefined,undefined,this.multifact,this.multifactentities,this.state.datamodelId,reportId,this.state.facttables);
            $("#spinnerchart" + this.state.chartnumber).hide();
            $(".spinner").hide();
        }
        if (chartType != "trellis" && chartType != "sparkline") {
            $("#singlesavedchartdiv").show();
        }
        // fix for when x-axis labels are too long....
        if (chartType == "bar" || chartType == "stackedbar" || chartType == "line" || chartType == "spline" || chartType == "area" || chartType == "stackedarea" || chartType == "scatter" || chartType == "combinationchart" || chartType == "multicolorbar" || chartType == "multixbar" || chartType == "multiaxisbar" || chartType == "waterfall" || chartType == "3dbar" || chartType == "multixline" || chartType == "multixarea" || chartType == "heatmap") {
            var chart = $("#chart" + this.state.chartnumber).highcharts();
            if (chart != null && chart != undefined) {
                chart.update({ xAxis: [{ labels: { rotation: -45 } }] });
            }
        }
        $('.btn-toolbar').hide();
        if (sessionStorage.getItem("customStoryBoardReportId") != null && sessionStorage.getItem("customStoryBoardReportId") != "null") {
            if ($('.redirectcustomstoryboard').length == 0) {
                $('.reporttitletoolbar' + this.state.chartnumber + ' .pull-right.bgcommon').prepend('<button class="btn btn-bird-group redirectcustomstoryboard" title="Back to Custom Storyboard"><i class="fa fa-share fa-flip-horizontal" aria-hidden="true"></i></button>')
            }
        }
        if (chartType === "bartimeseries" || chartType === "linetimeseries" || chartType === "areatimeseries") {
            $(".timeseriesrealtimedatabuttons[data-num='" + this.state.chartnumber + "']").removeClass('hide');
        } else {
            $(".timeseriesrealtimedatabuttons[data-num='" + this.state.chartnumber + "']").addClass('hide');
        }
    }
    viewSummaryTabeEve(msg, data) {
        if (data != undefined) {
            this.viewSummaryTabe(data.currentChartNumber, data.measures, data.dimensions, data.pos, data.chartDetails, data.isExcludeorInclude, data.isDateHistogram, data.dateHistomgramColName, data.applyattributes);
        }
        this.viewSummaryTabe();
    }
    viewSummaryTabe = (currentChartNumber, measures, dimensions, pos, chartDetails, isExcludeorInclude, isDateHistogram, dateHistomgramColName, applyattributes) => {
        if (chartDetails != undefined) {
            if (chartDetails['summaryTableDateHistogram'] != undefined && dateHistomgramColName == undefined) {
                dateHistomgramColName = chartDetails['summaryTableDateHistogram'];
                if (dateHistomgramColName['histogramInterval'] != "default") {
                    isDateHistogram = "true";
                }
            }
            if(chartDetails['summaryTableDateHistogramState'] != undefined && chartDetails['summaryTableDateHistogramState'] != null ){
                dateHistomgramColName = chartDetails['summaryTableDateHistogramState'];
                isDateHistogram = "true";
            }
        }
        var that = this;
        if (currentChartNumber == this.state.chartnumber) {
            var drilldownname = "Home";
            var drilldowndisplayname = "";
            var drilldownDataType = "";
            var appendbreadcrumb = false;
            var filterArray;
            var mainFilters = JSON.parse(JSON.stringify(that.filterArrayvalues || []));
            if (that.filterArrayvalues != undefined) {
                filterArray = JSON.parse(JSON.stringify(that.filterArrayvalues));
            }
            var dateHistogramFound = false;
            $(".predictmodelsummary" + this.state.chartnumber).addClass("hide");
            $('.breadcrumb[data-num=' + currentChartNumber + ']').remove();
            $("#chart" + that.state.chartnumber).before('<ul class="breadcrumb" data-num=' + currentChartNumber + '></ul>');
            this.state.levelmapping = {};
            _.each(chartDetails["summarytablecolumns"], function (xAxisObject, i) {
                that.state.levelmapping[xAxisObject.columnName] = 0;
                if (dateHistomgramColName != undefined && dateHistomgramColName["dateHistogramColName"] != undefined && dateHistomgramColName["histogramInterval"] != undefined
                    && dateHistomgramColName["histogramInterval"] != "default" && dateHistomgramColName["dateHistogramColName"] == xAxisObject.columnName) {
                    dateHistogramFound = true;
                }
            });
            if (chartDetails["summarytablegroupbys"] != undefined) {
                that.state.levelmapping[chartDetails["summarytablegroupbys"].columnName] = 0;
                if (dateHistomgramColName != undefined && dateHistomgramColName["dateHistogramColName"] != undefined && dateHistomgramColName["histogramInterval"] != undefined
                    && dateHistomgramColName["histogramInterval"] != "default" && dateHistomgramColName["dateHistogramColName"] == chartDetails["summarytablegroupbys"].columnName) {
                    dateHistogramFound = true;
                }
            }
            if (dateHistogramFound) {
                isDateHistogram = "true";
            } else {
                isDateHistogram = "false";
            }
            if (chartDetails["getsummarytableaxisfieldsarray"] == undefined || sessionStorage.getItem("customhierarchy" + currentChartNumber) == undefined) {

                var summarytablefiltervalues = ChartInfo.functions.setTempSummaryTableFilterValues([], currentChartNumber);
                var jsonArr = [];
                var jsonarray = [];
                jsonArr.push(that.state.levelmapping);
                jsonArr.push(this.filterArrayvalues);
                jsonarray.push(jsonArr);
                if (chartDetails["summarytablecolumns"] != undefined) {
                    sessionStorage.setItem("customhierarchy" + currentChartNumber, JSON.stringify(jsonarray));
                }
            } else {
                if (dateHistogramFound) {
                    isDateHistogram = "true";
                } else {
                    isDateHistogram = "false";
                }
                var string = sessionStorage.getItem("customhierarchy" + currentChartNumber);
                if (string != undefined && string != "undefined" && string != null && string != "null"
                    && string.length > 0) {
                    appendbreadcrumb = true;
                    var parsedString = JSON.parse(string);
                    drilldownname = chartDetails["summarytabledrilldownname"];
                    drilldowndisplayname = chartDetails["summarytabledrilldowndisplayname"];
                    drilldownDataType = chartDetails["summarytabledrilldowndatatype"];
                    if (JSON.parse(string).length > 0) {
                        if (that.state.levelmapping != undefined && JSON.stringify(that.state.levelmapping) != JSON.stringify(JSON.parse(string)[0][0])) {
                            var jsonArr = [];
                            var jsonarray = [];
                            jsonArr.push(that.state.levelmapping);
                            jsonArr.push([]);
                            jsonarray.push(jsonArr);
                            if (chartDetails["summarytablecolumns"] != undefined) {
                                sessionStorage.removeItem("customhierarchy" + currentChartNumber);
                                sessionStorage.setItem("customhierarchy" + currentChartNumber, JSON.stringify(jsonarray));
                            }
                        }
                    }
                }
                var jsonArr = [];
                var jsonarray = [];
                jsonArr.push(that.state.levelmapping);
                jsonArr.push(this.filterArrayvalues);
                jsonarray.push(jsonArr);
                if (chartDetails["summarytablecolumns"] != undefined) {
                    //sessionStorage.setItem("customhierarchy"+currentChartNumber,JSON.stringify(jsonarray));
                }
            }
            var tempsummarytabledetails = [];
            var temparray = {};
            temparray["group"] = chartDetails["summarytablegroupbys"];
            temparray["textcolumns"] = chartDetails["summarytablecolumns"];
            tempsummarytabledetails.push(temparray);
            ChartInfo.functions.setSummarytableAxisFieldsArray(tempsummarytabledetails, currentChartNumber);
            filterArray = mainFilters.concat(ChartInfo.functions.getTempSummaryTableFilterValues(currentChartNumber));
            var summarytableDrilldownValues = ChartInfo.functions.getTempSummaryTableFilterValues(currentChartNumber) || [];
            summarytableDrilldownValues = summarytableDrilldownValues.concat(JSON.parse(JSON.stringify(this.filterArrayvalues || [])));

            $('.richtextcontrolschart' + that.state.chartnumber).hide();
            $(".pivotnavigationbuttons" + that.state.chartnumber).hide();
            $(".pivotcharticon" + that.state.chartnumber).hide();
            $('.paginationdiv' + that.state.chartnumber).hide();
            $('.singlereport_summarymodal[data-num=' + that.state.chartnumber + ']').removeClass('hide');
            $(".maxanim1[data-num='" + that.state.chartnumber + "']").find('.saved-data-buttons').hide();
            $("#chart" + that.state.chartnumber).empty();
            sessionStorage.setItem('pos','max');
            var summaryTableView = new SummaryTableView({
                chartNumber: that.state.chartnumber,
                measures: measures,
                dimensions: dimensions,
                // broker:that.broker,
                reportId: that.state.reportId,
                datamodelId: that.state.datamodelId,
                filterArray: JSON.stringify(summarytableDrilldownValues),
                viewsummarytabe: this.viewSummaryTabe,
                pos: that.state.pos,
                features: that.state.features,
                chartDetails: chartDetails,
                indexName: that.state.indexname,
                drilldownlevel: 0,
                drilldownName: drilldownname,
                levelMapping: that.state.levelmapping,
                appendbreadcrumb: appendbreadcrumb,
                isExcludeorInclude: isExcludeorInclude,
                entities: that.state.entities,
                isDateHistogram: isDateHistogram,
                dateHistomgramColName: dateHistomgramColName,
                timeZone: that.state.timeZone,
                isPurgedReport: that.isPurgedReport,
                isPublished: that.state.isPublished,
                isImportedReport: that.isImportedReport,
                drilldowndisplayName: drilldowndisplayname,
                applyattributes: applyattributes,
                drilldownDataType: drilldownDataType,
                multifact: that.state.multifact,
                facttables:that.state.facttables,
                multifactentities: that.state.multifactentities
            });
        }
        //				}
    }
    viewPivotTableEve = (msg, data) => {
        if (data != undefined) {
            this.viewPivotTable(data.currentChartNumber, data.measures, data.dimensions, data.pos, data.applyattributes);
        } else {
            this.viewPivotTable();
        }
    }
    viewPivotTable = (currentChartNumber, measures, dimensions, pos, applyattributes) => {
        if (currentChartNumber == this.state.chartnumber) {
            $('.richtextcontrolschart' + this.state.chartnumber).hide();
            $('.singlereport_summarymodal[data-num=' + this.state.chartnumber + ']').addClass('hide');
            $('.btn-toolbar').hide();
            $('.breadcrumb[data-num=' + currentChartNumber + ']').remove();
            //            $('#chart'+currentChartNumber).destroy();
            $(".predictmodelsummary" + currentChartNumber).addClass("hide");
            var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(this.state.chartnumber));
            var that = this;
            if (chartDetails["chartType"] != 'pivot') {
                this.redrawChart(chartDetails, "max");
            } else {
                that.filterArrayvalues = ChartInfo.functions.generateFilterWithEmbededFilters(that.filterArrayvalues, collection.getSharedFilters(this.state.viewreportmodel));
                var pivotview = new PivotView({
                    chartNumber: that.state.chartnumber,
                    measures: measures,
                    dimensions: dimensions,
                    // broker:that.broker,
                    reportId: that.state.reportId,
                    filterArray: JSON.stringify(that.filterArrayvalues),
                    pos: "max",
                    features: that.state.features,
                    timeZone: that.state.timeZone,
                    isPurgedReport: that.isPurgedReport,
                    isPublished: that.state.isPublished,
                    datamodelId: that.state.datamodelId,
                    facttables: that.state.facttables,
                    multifactentities: that.state.multifactentities,
                    multifact: that.state.multifact,
                    isImportedReport: that.isImportedReport,
                    applyattributes: applyattributes,
                });
                $('.pvtTable').wrap("<div class='pivotscroll'></div>");
            }
        }
    }
    viewDataTableEve(msg, data) {
        if (data != undefined) {
            this.viewDataTable(data.currentChartNumber, data.selectedcolumns, data.state, data.pos, data.popupwindow, data.target, data.applyattributes, data.filters, data.tableInfo);
        } else {
            this.viewDataTable();
        }
    }
    viewDataTable(currentChartNumber, selectedcolumns, state, pos, popupwindow, $this, applyattributes, filters, tableInfo){
        var that = this;
        this.state.features.map((module, index) => {
            if (module.moduleName == 'my_report') {
                module.features.map((feature, index) => {
                    if (feature.featureName == 'viewreport_maximize_data_table_export' && feature.allow == true) {
                        sessionStorage.setItem('isDataTableDataExport', true);
                    } else if (feature.featureName == 'viewreport_maximize_data_table_export' && feature.allow == false) {
                        sessionStorage.setItem('isDataTableDataExport', false);
                    }
                });
                /*$.each(module.features,function(index,feature){
                    if(feature.featureName=='viewreport_maximize_data_table_column_selection'&& feature.allow==true){
                        sessionStorage.setItem('isDataTableColumnSelection',true);
                    }else if(feature.featureName=='viewreport_maximize_data_table_column_selection'&& feature.allow==false){
                        sessionStorage.setItem('isDataTableColumnSelection',false);
                    }
                });*/
            }
        });
        if (currentChartNumber == this.state.chartnumber) {
            if (popupwindow != "popupview") {
                $(".predictmodelsummary" + that.state.chartnumber).addClass("hide");
                $('.paginationdiv' + that.state.chartnumber).show();
                $('#dobyexportdownload').removeClass('hide');
                $('.singlereportselectedtablecolumns').removeClass('hide')
                sessionStorage.removeItem("datatable_popup_filterdata");
                sessionStorage.removeItem("datatable_popup_yaxis");
                $('.richtextcontrolschart' + this.state.chartnumber).hide();
                $(".pivotnavigationbuttons" + this.state.chartnumber).hide();
                $(".pivotcharticon" + this.state.chartnumber).hide();
                $('.singlereport_summarymodal[data-num=' + this.state.chartnumber + ']').addClass('hide');
                $(".viewchartfromchartdata").hide();
                $(".summarytablemessage").remove();
            }
            var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(this.state.chartnumber));
            var rowLimit = chartDetails["dobyrowlimit"];
            if (popupwindow != undefined && popupwindow == "popupview" && rowLimit != undefined && rowLimit != "" && rowLimit.length > 0) {
                var limit = parseInt(rowLimit);
                if (limit > 10000) {
                    rowLimit = "10000"
                }
            }
            var xaxisData = chartDetails["xAxis"];
            var yAxisData = "";
            var yAxisDataType = "";
            var aggregation = "";
            var drilldownvalue = "";
            var showTitle = true;
            var selectedcolumns = chartDetails["dobyselectedcolumns"];
            _.each(chartDetails["yAxis"], function (val) {
                yAxisData += val.columnName + ",";
                yAxisDataType += val.dataType + ",";
                aggregation += val.aggregation + ",";
            });
            yAxisData = yAxisData.substring(0, yAxisData.length - 1);
            aggregation = aggregation.substring(0, aggregation.length - 1);
            yAxisDataType = yAxisDataType.substring(0, yAxisDataType.length - 1);

            if (!Array.isArray(selectedcolumns) || selectedcolumns == undefined) {
                var temp = {};
                selectedcolumns = [];
                temp["actualnames"] = xaxisData.columnName;
                temp["displaynames"] = xaxisData.displayName;
                temp["datatype"] = xaxisData.dataType;
                selectedcolumns.push(temp);
            }

            var isXaxisFieldValid = true;
            var that = this;
            var customParametersFilters = ChartInfo.functions.getCustomParametersFilters();
            var viewsinglereportbodymodal = new FormData();
            //                var viewReportDataTableCollection=new ViewReportDataTableCollection(viewsinglereportbodymodal); //getDataTable
            //					chartDetails["chartType"]="doby";
            var actualColumns = JSON.parse(this.state.viewreportmodel.reportcolumns);
            $.each(selectedcolumns, function (index, selectedcolumn) {
                $.each(actualColumns, function (index1, actualColumn) {
                    if (actualColumn.columnName == selectedcolumn.actualnames && actualColumn.columnssrename != undefined) {
                        selectedcolumn["displaynames"] = actualColumn.columnDisplayName;
                        selectedcolumns.splice(index, selectedcolumn);
                    }
                });
            });

            var renameMeasuresorDimensions = sessionStorage.getItem('renameMeasuresorDimensions');
            if (renameMeasuresorDimensions != null && renameMeasuresorDimensions != "null") {
                $.each(selectedcolumns, function (index, data) {
                    var parsedData = JSON.parse(renameMeasuresorDimensions);
                    if (parsedData[data.actualnames] != undefined && parsedData.hasOwnProperty(data.actualnames)) {
                        data['displaynames'] = parsedData[data.actualnames];
                    }
                });
            }
            chartDetails["dobyselectedcolumns"] = selectedcolumns;
            ChartInfo.functions.updateChartDetails(this.state.chartnumber, JSON.stringify(chartDetails));

            var convertedValues = JSON.parse(sessionStorage.getItem("convertedvalues"));
            var found = false;
            if (convertedValues != null) {
                for (var i = 0; i < convertedValues.length; i++) {
                    for (var int = 0; int < selectedcolumns.length; int++) {
                        if (convertedValues[i].columnDisplayName == selectedcolumns[int].displaynames) {
                            found = true;
                        }
                    }
                }
            }
            if (found == false) {
                _.each(convertedValues, function (val) {
                    if (val["type"] === "dimension") {
                        var temp = {};
                        temp["actualnames"] = val["columnName"];
                        temp["displaynames"] = val["columnDisplayName"];
                        temp["datatype"] = val["dataType"];
                        selectedcolumns.push(temp);
                    }
                });
            }

            var yaxisFeilds = sessionStorage.getItem("datatable_popup_yaxis");
            var filtervalue = JSON.parse(sessionStorage.getItem('datatable_popup_filterdata'));
            var customFields = JSON.parse(sessionStorage.getItem('customFields'));
            var cumstoMeasures = ChartInfo.functions.getCustomMeasureField();
            var measureHierarchy = ChartInfo.functions.getMeasureHierarchy();
            if (customFields != undefined && customFields != null && customFields.length > 0) {
                var yaxisCustomFieldCheck;
                _.each(customFields, function (i, j) {
                    if (yaxisFeilds == i.name) {
                        if (i.usedFields.length > 1) {
                            var usedFieldsArray = [];
                            for (let k = 0; k < i.usedFields.length; k++) {
                                usedFieldsArray.push(i.usedFields[k].split(".").shift());
                            }
                            yaxisFeilds = _.intersection(that.state.facttables, usedFieldsArray).length > 0 ? _.intersection(that.state.facttables, usedFieldsArray)[0] : i.usedFields[0];
                        } else {
                            yaxisCustomFieldCheck = i.usedFields[0];
                            yaxisFeilds = yaxisCustomFieldCheck.split(".").shift();
                        }
                    }
                });
            }
            if (cumstoMeasures != undefined && cumstoMeasures.length > 0) {
                var yaxisCustomMeasureCheck;
                var usedfield;
                _.each(cumstoMeasures, function (i, j) {
                    if (yaxisFeilds == i.name) {
                        yaxisCustomMeasureCheck = i.usedFields;
                        var customfieldcolumnname;
                        if (customFields != undefined && customFields.length > 0) {
                            _.each(customFields, function (obj, index) {
                                customfieldcolumnname = obj.name;
                                if (customfieldcolumnname == yaxisCustomMeasureCheck) {
                                    if (obj.usedFields.length > 1) {
                                        var usedFieldsArray = [];
                                        for (let k = 0; k < obj.usedFields.length; k++) {
                                            usedFieldsArray.push(obj.usedFields[k].split(".").shift());
                                        }
                                        usedfield = _.intersection(that.state.facttables, usedFieldsArray).length > 0 ? _.intersection(that.state.facttables, usedFieldsArray)[0] : obj.usedFields[0];
                                    } else {
                                        usedfield = obj.usedFields[0];
                                    }
                                }
                            });
                        }
                        if (usedfield != undefined) {
                            yaxisFeilds = usedfield;
                        } else {
                            yaxisFeilds = yaxisCustomMeasureCheck.split(".").shift();
                        }
                    }
                });
            }
            if (measureHierarchy != undefined && measureHierarchy.length > 0) {
                var yaxisMeasureHierarchyCheck;
                _.each(measureHierarchy, function (i, j) {
                    if (yaxisFeilds == i.name) {
                        yaxisMeasureHierarchyCheck = i.hierarchelist[0].columnName;
                        yaxisFeilds = yaxisMeasureHierarchyCheck.split(".").shift();
                    }
                });
            }
            if (that.state.multifact != undefined && that.state.multifact == 'true' && yaxisFeilds != undefined && yaxisFeilds != null) {
                selectedcolumns = [];
                var i = 0;
                _.each(this.state.viewcollection.getDimensions(), function (dimension) {
                    var tablename = dimension.columnName.split(".")[0];
                    var yaxisTableName = yaxisFeilds.split(".")[0];

                    if (tablename == yaxisTableName) {
                        if (i <= 10) {
                            var temp = {};
                            temp["actualnames"] = dimension.columnName;
                            temp["displaynames"] = dimension.columnDisplayName;
                            temp["datatype"] = dimension.tableDisplayType;
                            selectedcolumns.push(temp);
                            i++;
                        }
                        else {
                            var columnName = dimension.columnName;
                            if (yaxisFeilds == columnName) {
                                var temp = {};
                                temp["actualnames"] = dimension.columnName;
                                temp["displaynames"] = dimension.columnDisplayName;
                                temp["datatype"] = dimension.tableDisplayType;
                                selectedcolumns.push(temp);
                                i++;
                            }
                        }
                    }
                });

                _.each(filtervalue, function (filters) {
                    var temp = {};
                    temp["actualnames"] = filters['columnname'];
                    temp["displaynames"] = filters['columndisplayname'];
                    temp["datatype"] = filters['dataType'];
                    selectedcolumns.push(temp);
                });
                if (selectedcolumns.length == 0) {
                    var tempTableName;
                    _.each(tableInfo, function (tablInfo) {
                        var temp = {};
                        temp["actualnames"] = tablInfo['columnname'];
                        temp["displaynames"] = tablInfo['columndisplayname'];
                        temp["datatype"] = tablInfo['dataType'];
                        selectedcolumns.push(temp);
                        tempTableName = tablInfo['columnname'].split(".")[0];
                    });
                    var multifactObject = JSON.parse(that.state.multifactentities);
                    var selectedTableObject = multifactObject[tempTableName];
                    var selectedTableFact = selectedTableObject[0];
                    var i = 0;
                    _.each(this.state.viewcollection.getDimensions(), function (dimension) {
                        var tablename = dimension.columnName.split(".")[0];

                        if (selectedTableFact == tablename) {
                            if (i <= 10) {
                                var temp = {};
                                temp["actualnames"] = dimension.columnName;
                                temp["displaynames"] = dimension.columnDisplayName;
                                temp["datatype"] = dimension.tableDisplayType;
                                selectedcolumns.push(temp);
                                i++;
                            }
                        }
                    });
                }
                //Checking for selected columns contains Fact or not
                var selectedColumnsArray = [];
                var usedFieldFactCheck;
                _.each(selectedcolumns, function (i, j) {
                    selectedColumnsArray.push(i.actualnames.split(".")[0]);
                });
                if (_.intersection(that.state.facttables, selectedColumnsArray).length == 0) {
                    var yAxisFields;
                    if (chartDetails["chartType"] == "summarytable") {
                        yAxisFields = chartDetails["summarytablevalues"];
                    } else {
                        yAxisFields = chartDetails.yAxis;
                    }
                    _.each(yAxisFields, function (yAttributes, index) {
                        if (customFields != undefined && customFields.length > 0) {
                            _.each(customFields, function (i, j) {
                                if (yAttributes.columnName == i.name) {
                                    if (i.usedFields.length > 1) {
                                        var usedFieldsArray = [];
                                        for (let k = 0; k < i.usedFields.length; k++) {
                                            usedFieldsArray.push(i.usedFields[k].split(".").shift());
                                        }
                                        usedFieldFactCheck = _.intersection(that.state.facttables, usedFieldsArray).length > 0 ? _.intersection(that.state.facttables, usedFieldsArray)[0] : i.usedFields[0];
                                    } else {
                                        usedFieldFactCheck = i.usedFields[0];
                                    }
                                }
                            });
                        }
                        if (cumstoMeasures != undefined && cumstoMeasures.length > 0) {
                            var yaxisCustomMeasureCheck;
                            var usedfield;
                            _.each(cumstoMeasures, function (i, j) {
                                if (yAttributes.columnName == i.name) {
                                    yaxisCustomMeasureCheck = i.usedFields;
                                    var customfieldcolumnname;
                                    if (customFields != undefined && customFields.length > 0) {
                                        _.each(customFields, function (obj, index) {
                                            customfieldcolumnname = obj.name;
                                            if (customfieldcolumnname == yaxisCustomMeasureCheck) {
                                                if (obj.usedFields.length > 1) {
                                                    var usedFieldsArray = [];
                                                    for (let k = 0; k < obj.usedFields.length; k++) {
                                                        usedFieldsArray.push(obj.usedFields[k].split(".").shift());
                                                    }
                                                    usedfield = _.intersection(that.state.facttables, usedFieldsArray).length > 0 ? _.intersection(that.state.facttables, usedFieldsArray)[0] : obj.usedFields[0];
                                                } else {
                                                    usedfield = obj.usedFields[0];
                                                }
                                            }
                                        });
                                    }
                                    if (usedfield != undefined) {
                                        usedFieldFactCheck = usedfield;
                                    } else {
                                        usedFieldFactCheck = yaxisCustomMeasureCheck;
                                    }
                                }
                            });
                        }
                        if (measureHierarchy != undefined && measureHierarchy.length > 0) {
                            _.each(measureHierarchy, function (i, j) {
                                if (yAttributes.columnName == i.name) {
                                    _.each(i.hierarchelist, function (k, l) {
                                        if (that.state.facttables.indexOf(k.columnName.split(".")[0]) != -1) {
                                            usedFieldFactCheck = k.columnName;
                                        }
                                    });
                                }
                            });
                        }
                        if (usedFieldFactCheck == undefined || that.state.facttables.indexOf(yAttributes.columnName.split(".")[0]) != -1) {
                            usedFieldFactCheck = yAttributes.columnName;
                        }
                        if (that.state.facttables.indexOf(usedFieldFactCheck.split(".").shift()) != -1) {
                            _.each(that.state.viewcollection.getDimensions(), function (dimension) {
                                var tablename = dimension.columnName;
                                var yaxisTableName = usedFieldFactCheck;
                                if (tablename == yaxisTableName) {
                                    var tempFact = {};
                                    tempFact["actualnames"] = dimension.columnName;
                                    tempFact["displaynames"] = dimension.columnDisplayName;
                                    tempFact["datatype"] = dimension.tableDisplayType;
                                    selectedcolumns.push(tempFact);
                                }
                            });
                        }
                    });
                }
                chartDetails["dobyselectedcolumns"] = selectedcolumns;
                ChartInfo.functions.updateChartDetails(this.state.chartnumber, JSON.stringify(chartDetails));
            }
            var bool = true;
            var filterArray = [];
            if (this.filterArrayvalues != undefined) {
                filterArray = JSON.parse(JSON.stringify(this.filterArrayvalues))
                filterArray = ChartInfo.functions.generateFilterWithEmbededFilters(filterArray, this.state.viewcollection.getSharedFilters(this.viewreportmodel));
            }

            if (filtervalue != null && $.grep(filtervalue, function (e) { return e.columnname == "Measure Names" }).length > 0) {
                isXaxisFieldValid = false;
            }
            var summarytablefiltervalues = [];
            var temp1 = [];
            var xaxis_legend_Length = chartDetails["xAxis"].length;
            if (chartDetails["legend"] != undefined) {
                xaxis_legend_Length = xaxis_legend_Length + 1;
            }
            var customFields = sessionStorage.getItem("customFields");
            var temp = JSON.parse(customFields);
            var res;
            _.each(temp, function (customfields) {
                _.each(customfields.parametrFieldsArray, function (value) {
                    var customparameter = ChartInfo.functions.getCustomParameter();
                    if (customParametersFilters != undefined && customParametersFilters.length != 0) {
                        customparameter = customParametersFilters;
                    }
                    res = $.grep(customparameter, function (e) {
                        return e.parametername == value;
                    })
                    var formula = customfields.formula;
                    var scriptcondition = customfields.scriptcondition;
                    var fields = customfields.fields;
                    var sqlformula = customfields.sqlformula;
                    if (res.length != 0) {
                        customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
                        customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
                        customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
                        customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
                    } else {
                        _.each(ChartInfo.functions.getCustomParameter(), function (values) {
                            if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
                                customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
                                customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                            }
                        });
                    }
                });
            });
            customFields = JSON.stringify(temp);
            var that = this;
            if (chartDetails.chartType != "doby") {
                if (filtervalue != null && popupwindow == "popupview" && chartDetails.chartType != "summarytable" && isXaxisFieldValid) {
                    if (customFields != undefined && customFields != null && customFields != "null") {
                        var customObject = {};
                        var tempcustomObject = JSON.parse(customFields);
                        for (var i = 0; i < xaxis_legend_Length; i++) {
                            bool = true;
                            var filterObject = {};
                            _.each(tempcustomObject, function (customfield) {
                                var columnName = '';
                                var filterData = '';
                                var dataType = '';
                                var columndisplayname = '';
                                if (chartDetails["chartType"] == "multixbar" || chartDetails["chartType"] == "multixline" || chartDetails["chartType"] == "multixarea" ||
                                    chartDetails["chartType"] == "srilankamap" || chartDetails["chartType"] == "world" || chartDetails["chartType"] == "leaflet" || chartDetails["chartType"] == "countrymap" || chartDetails["chartType"] == "indiamap") {
                                    columnName = filtervalue[0].columnname;
                                    filterData = filtervalue[0].data;
                                    dataType = filtervalue[0].dataType;
                                    columndisplayname = filtervalue[0].columndisplayname;
                                }
                                else {
                                    columnName = filtervalue[i].columnname;
                                    filterData = filtervalue[i].data;
                                    dataType = filtervalue[i].dataType;
                                    columndisplayname = filtervalue[i].columndisplayname;
                                }
                                if (customfield.name == columnName) {
                                    var sqlFilterObject = {};
                                    sqlFilterObject["sqldata"] = filterData;
                                    customfield["sqlcondition"] = sqlFilterObject;
                                    that.excludingFilterArray(filterArray, customfield.name)
                                    bool = false;
                                    customObject = customfield;
                                    customObject["parameter"] = filterData;
                                    filterObject["customfield"] = customObject;
                                    filterObject["dataType"] = dataType;
                                    filterObject["isdrilldownfilter"] = true;
                                    filterObject["data"] = filterData;
                                    filterObject["columnDisplayName"] = columndisplayname;
                                    filterObject["tablename"] = "";
                                    filterObject["columndisplayname"] = columndisplayname;
                                    filterObject["columnname"] = columnName;
                                    var scriptFilterCondition = customObject['scriptcondition'];
                                    if (filterObject["dataType"] == "date") {
                                        customObject['scriptcondition'] = "; if(new Date(" + scriptFilterCondition + ")==new Date(parameter)){ return true } else{ return false}";
                                        if (customObject["parameter"].length > 0) {
                                            var newDate = customObject["parameter"];//Date.parse(customObject["parameter"].replace(/-/g,'/'));
                                            if (isNaN(newDate)) {
                                                //var newdate1=moment(myDate,dateFormat);
                                                customObject["parameter"] = customObject["parameter"];
                                            }
                                            else {
                                                customObject["parameter"] = newDate;//new Date(newDate).getTime();
                                            }
                                        }
                                    }
                                    if (filterObject["dataType"] == "number") {
                                        customObject['scriptcondition'] = "; if((" + scriptFilterCondition + ")==(parameter)) {return true }else{ return false}";
                                    } else {
                                        customObject['scriptcondition'] = "; if((" + scriptFilterCondition + ").equals(parameter)){ return true }else{ return false}";
                                    }
                                    //drilldownvalue += filtervalue[i].columnname+": "+filtervalue[i].data+", ";

                                    if (!that.findSameFilterisExists(filterArray, filterObject)) {
                                        filterArray.push(filterObject);
                                    }
                                }
                            });
                            _.each(filtervalue, function (value, k) {
                                if (that.isCustomFiledExists(tempcustomObject, value.columnname) && !that.findSameFilterisExists(filterArray, filtervalue[k])) {
                                    filterArray.push(filtervalue[k]);
                                }
                            });

                            if (bool) {
                                _.each(filtervalue, function (value, i) {
                                    var data = filtervalue[i].data;
                                    if (filtervalue[i].dataType == "date") {
                                        if (data.length > 0) {
                                            var newDate = data;//Date.parse(data.replace(/-/g,'/'));
                                            if (isNaN(newDate)) {
                                                //var newdate1=moment(myDate,dateFormat);
                                                data = data;
                                            }
                                            else {
                                                data = newDate;//new Date(newDate).getTime();
                                            }

                                        }
                                    }
                                    //	drilldownvalue += filtervalue[i].columnname+": "+filtervalue[i].data+", ";
                                    filtervalue[i].data = data;
                                    if (!that.findSameFilterisExists(filterArray, value)) {
                                        filterArray.push(value);
                                    }
                                });
                            }
                        }
                    } else if (bool) {
                        _.each(filtervalue, function (value, i) {
                            var data = filtervalue[i].data;
                            if (filtervalue[i].dataType == "date") {
                                if (data.length > 0) {
                                    var newDate = data;//Date.parse(data.replace(/-/g,'/'));
                                    if (isNaN(newDate)) {
                                        //	var newdate1=moment(myDate,dateFormat);
                                        data = data;
                                    }
                                    else {
                                        data = newDate;//new Date(newDate).getTime();
                                    }

                                }
                            }
                            //	drilldownvalue += filtervalue[i].columnname+": "+filtervalue[i].data+", ";
                            filtervalue[i].data = data;
                            filterArray.push(value);
                        });
                    }
                    var drillDown = JSON.parse(sessionStorage.getItem("drilldown" + currentChartNumber));
                    _.each(drillDown, function (drillDownData1) {
                        if (drillDownData1.name != "Measure Names") {
                            var filterObject = {};
                            filterObject["isdrilldownfilter"] = true;
                            if (drillDownData1.type == undefined) {
                                filterObject["dataType"] = "string";
                            } else {
                                filterObject["dataType"] = drillDownData1.type;
                                filterObject["columndisplayname"] = drillDownData1.name;
                                filterObject["columnname"] = drillDownData1.name;
                            }
                            if (customFields != undefined && customFields != null && customFields != "null" && customFields != "[]") {
                                var customObject = {};
                                var tempcustomObject = JSON.parse(customFields);
                                _.each(tempcustomObject, function (customfield) {
                                    if (customfield.name == drillDownData1.name) {
                                        var sqlFilterObject = {};
                                        sqlFilterObject["sqldata"] = drillDownData1.value;
                                        customfield["sqlcondition"] = sqlFilterObject;
                                        customObject = customfield;
                                        customObject["parameter"] = drillDownData1.value;
                                        filterObject["customfield"] = customObject;
                                        filterObject["dataType"] = drillDownData1.type;
                                        filterObject["isdrilldownfilter"] = true;
                                        var scriptFilterCondition = customObject['scriptcondition'];
                                        customObject['scriptcondition'] = "; if((" + scriptFilterCondition + ").equals(parameter)) return true else false";
                                        if (filterObject["dataType"] == "date") {
                                            customObject['scriptcondition'] = "; if(new Date(" + scriptFilterCondition + ")==new Date(parameter)) return true else false";
                                            if (customObject["parameter"].length > 0) {
                                                var newDate = customObject["parameter"];//Date.parse(customObject["parameter"].replace(/-/g,'/'));
                                                if (isNaN(newDate)) {
                                                    //var newdate1=moment(myDate,dateFormat);
                                                    customObject["parameter"] = customObject["parameter"];
                                                }
                                                else {
                                                    customObject["parameter"] = newDate;//new Date(newDate).getTime();
                                                }
                                            }
                                        }
                                        if (filterObject["dataType"] == "number") {
                                            customObject['scriptcondition'] = "; if((" + scriptFilterCondition + ")==(parameter)) return true else return false";
                                        }
                                        //		drilldownvalue += drillDownData1.name+" : "+drillDownData1.value+", ";
                                    } else {
                                        var Object = $.grep(ChartInfo.functions.getcustomhierarchy(), function (e) { return e.name == filtervalue.columnname });
                                        var rangeObject = $.grep(ChartInfo.functions.getRange(), function (e) { return e.name == drillDownData1.name });
                                        if (Object.length > 0) {
                                            filterObject["tablename"] = "";
                                            filterObject["columndisplayname"] = Object[0].columnDisplayName;
                                            filterObject["columnname"] = Object[0].columnName;
                                            filterObject["dataType"] = Object[0].dataType;
                                            filterObject["isdrilldownfilter"] = true;
                                            var value = drillDownData1.value;
                                            if (filterObject["dataType"] == "date") {
                                                if (value.length > 0) {
                                                    var newDate = value;//Date.parse(value.replace(/-/g,'/'));
                                                    if (isNaN(newDate)) {
                                                        //var newdate1=moment(myDate,dateFormat);
                                                        value = value;
                                                    }
                                                    else {
                                                        value = newDate;//new Date(newDate).getTime();
                                                    }

                                                }
                                            }
                                            filterObject["data"] = value;
                                            //	drilldownvalue += filterObject["columndisplayname"]+" : "+value+", ";

                                        } else if (rangeObject.length > 0) {
                                            filterObject["tablename"] = "";
                                            filterObject["columndisplayname"] = drillDownData1.name;
                                            filterObject["columnname"] = rangeObject[0]["xAxisName"][0].columnName;
                                            var value = drillDownData1.value;
                                            filterObject["data"] = value;
                                        } else {
                                            filterObject["tablename"] = "";
                                            filterObject["columndisplayname"] = drillDownData1.name;
                                            filterObject["columnname"] = drillDownData1.name;
                                            filterObject["isdrilldownfilter"] = true;
                                            var value = drillDownData1.value;
                                            if (filterObject["dataType"] == "date") {
                                                if (value.length > 0) {
                                                    var newDate = value;//Date.parse(value.replace(/-/g,'/'));
                                                    if (isNaN(newDate)) {
                                                        //var newdate1=moment(myDate,dateFormat);
                                                        value = value;
                                                    }
                                                    else {
                                                        value = newDate;//new Date(newDate).getTime();
                                                    }
                                                }
                                            }
                                            filterObject["data"] = value;
                                            //	drilldownvalue += filterObject["columndisplayname"]+" : "+value+", ";
                                        }
                                    }
                                });

                            } else {
                                var Object = $.grep(ChartInfo.functions.getcustomhierarchy(), function (e) { return e.name == filtervalue.columnname });
                                var rangeObject = $.grep(ChartInfo.functions.getRange(), function (e) { return e.name == filtervalue.name });
                                if (Object.length > 0) {
                                    filterObject["tablename"] = "";
                                    filterObject["columndisplayname"] = Object[0].columnDisplayName;
                                    filterObject["columnname"] = Object[0].columnName;
                                    filterObject["dataType"] = Object[0].dataType;
                                    filterObject["isdrilldownfilter"] = true;
                                    var value = drillDownData1.value;
                                    if (filterObject["dataType"] == "date") {
                                        if (value.length > 0) {
                                            var newDate = value;//Date.parse(value.replace(/-/g,'/'));
                                            if (isNaN(newDate)) {
                                                //var newdate1=moment(myDate,dateFormat);
                                                value = value;
                                            }
                                            else {
                                                value = newDate;//new Date(newDate).getTime();
                                            }

                                        }
                                    }
                                    filterObject["data"] = value;
                                    //	drilldownvalue += filterObject["columndisplayname"]+" : "+value+", ";

                                } else if (rangeObject.length > 0) {
                                    filterObject["tablename"] = "";
                                    filterObject["columndisplayname"] = drillDownData1.name;
                                    filterObject["columnname"] = rangeObject[0]["xAxisName"][0].columnName;
                                    var value = drillDownData1.value;
                                    filterObject["data"] = value;
                                } else {
                                    filterObject["tablename"] = "";
                                    filterObject["columndisplayname"] = drillDownData1.name;
                                    filterObject["columnname"] = drillDownData1.name;
                                    filterObject["isdrilldownfilter"] = true;
                                    var value = drillDownData1.value;
                                    if (filterObject["dataType"] == "date") {
                                        if (value.length > 0) {
                                            var newDate = value;//Date.parse(value.replace(/-/g,'/'));
                                            if (isNaN(newDate)) {
                                                //var newdate1=moment(myDate,dateFormat);
                                                value = value;
                                            }
                                            else {
                                                value = newDate;//new Date(newDate).getTime();
                                            }

                                        }
                                    }
                                    filterObject["data"] = value;
                                    //	drilldownvalue += filterObject["columndisplayname"]+" : "+value+", ";
                                }
                            }
                            filterArray.push(filterObject);
                        }

                    });
                } else if (isXaxisFieldValid) {
                    filterArray = [];
                    var sessionValue = JSON.parse(sessionStorage.getItem('customhierarchy' + this.state.chartnumber));
                    var summarytablefiltervalues = [];
                    var temp1 = [];
                    var summarytableDrilldownValues = ChartInfo.functions.getTempSummaryTableFilterValues(this.state.chartnumber) || [];
                    var filterArray = summarytableDrilldownValues.concat(JSON.parse(JSON.stringify(this.filterArrayvalues || [])));
                    if (chartDetails["summarytablegroupbys"] != undefined && chartDetails["summarytablegroupbys"] != null) {
                        var groupNo = $($this).parent().attr('group-no');
                        var groupName = $('.groupTR[group-no=' + groupNo + '][data-num=' + currentChartNumber + ']').attr('group-name');
                        var Object = $.grep(this.state.viewcollection.getDimensions(), function (e) { return e.columnDisplayName == groupName.split(':')[0] });
                        if (Object != undefined && Object.length > 0) {
                            Object = Object[0];
                            var filterObject = {};
                            filterObject["dataType"] = Object.tableDisplayType;
                            filterObject["tablename"] = Object.tableName;
                            filterObject["columndisplayname"] = Object.columnDisplayName;
                            filterObject["columnname"] = Object.columnName;
                            filterObject["isdrilldownfilter"] = true;
                            if (Object.tableDisplayType == 'date') {
                                filterObject["data"] = groupName.substring(groupName.indexOf(":") + 1, groupName.length).trim();
                            } else {
                                filterObject["data"] = groupName.substring(groupName.indexOf(":") + 1, groupName.length).trim();
                            }
                            if (filterObject["dataType"] == "date") {
                                if (filterObject["data"].length > 0) {
                                    var newDate = filterObject["data"];//Date.parse(filterObject["data"].replace(/-/g,'/'));
                                    if (isNaN(newDate)) {
                                        //var newdate1=moment(myDate,dateFormat);
                                        filterObject["data"] = filterObject["data"];
                                    }
                                    else {
                                        filterObject["data"] = newDate;//new Date(newDate).getTime();
                                    }

                                }
                            }
                            //drilldownvalue +=groupName+", ";
                            summarytablefiltervalues.push(filterObject);
                        }
                        var customFieldsViewData = sessionStorage.getItem("customFields");
                        var customFieldViewData = JSON.parse(customFieldsViewData);
                        if (customFieldViewData != undefined && customFieldViewData.length > 0) {
                            var Object = $.grep(customFieldViewData, function (e) {
                                return e.name == groupName.split(':')[0]
                            });
                            if (Object != undefined && Object.length > 0) {
                                var filterObject = {};
                                Object = Object[0];
                                filterObject["dataType"] = Object.dataType;
                                filterObject["tablename"] = Object.tableName;
                                filterObject["columndisplayname"] = Object.name;
                                filterObject["columnname"] = Object.name;
                                filterObject["isdrilldownfilter"] = true;
                                if (Object.dataType == 'date') {
                                    filterObject["data"] = groupName.substring(groupName.indexOf(":") + 1, groupName.length).trim();
                                } else {
                                    filterObject["data"] = groupName.substring(groupName.indexOf(":") + 1, groupName.length).trim();
                                }
                                if (filterObject["dataType"] == "date") {
                                    if (filterObject["data"].length > 0) {
                                        var newDate = filterObject["data"];//Date.parse(filterObject["data"].replace(/-/g,'/'));
                                        if (isNaN(newDate)) {
                                            //var newdate1=moment(myDate,dateFormat);
                                            filterObject["data"] = filterObject["data"];
                                        }
                                        else {
                                            filterObject["data"] = newDate;//new Date(newDate).getTime();
                                        }

                                    }
                                }
                                summarytablefiltervalues.push(filterObject);
                            }
                        }
                    }
                    if (!$($this).hasClass('groupTitle')) {
                        var drilldownColumnName = $('.summarytablestyling[data-num=' + currentChartNumber + '] thead tr th').eq($($this).index()).html();
                        var Object = $.grep(this.state.viewcollection.getDimensions(), function (e) { return e.columnDisplayName == drilldownColumnName });
                        if (Object != undefined && Object.length > 0) {
                            Object = Object[0];
                            var filterObject = {};
                            filterObject["dataType"] = Object.tableDisplayType;
                            filterObject["tablename"] = Object.tableName;
                            filterObject["columndisplayname"] = Object.columnDisplayName;
                            filterObject["columnname"] = Object.columnName;
                            filterObject["data"] = $($this).text().trim();
                            filterObject["isdrilldownfilter"] = true;
                            //drilldownvalue +=Object.columnDisplayName+": "+$($this).text().trim()+", ";
                            if (filterObject["dataType"] == "date") {
                                if (filterObject["data"].length > 0) {
                                    var newDate = filterObject["data"];//Date.parse(filterObject["data"].replace(/-/g,'/'));
                                    if (isNaN(newDate)) {
                                        //var newdate1=moment(myDate,dateFormat);
                                        filterObject["data"] = filterObject["data"];
                                    }
                                    else {
                                        filterObject["data"] = newDate;//new Date(newDate).getTime();
                                    }

                                }
                            }
                            if ($($this).closest("tr.groupTR").length == 0) {
                                summarytablefiltervalues.push(filterObject);
                            }
                        }
                        var customFieldsViewData = sessionStorage.getItem("customFields");
                        var customFieldViewData = JSON.parse(customFieldsViewData);
                        if (customFieldViewData != undefined && customFieldViewData.length > 0) {
                            var Object = $.grep(customFieldViewData, function (e) {
                                return e.name == drilldownColumnName
                            });
                            if (Object != undefined && Object.length > 0) {
                                var filterObject = {};
                                Object = Object[0];
                                filterObject["dataType"] = Object.dataType;
                                filterObject["tablename"] = Object.tableName;
                                filterObject["columndisplayname"] = Object.name;
                                filterObject["columnname"] = Object.name;
                                filterObject["isdrilldownfilter"] = true;
                                filterObject["data"] = $($this).text().trim();
                                if (filterObject["dataType"] == "date") {
                                    if (filterObject["data"].length > 0) {
                                        var newDate = filterObject["data"];//Date.parse(filterObject["data"].replace(/-/g,'/'));
                                        if (isNaN(newDate)) {
                                            filterObject["data"] = filterObject["data"];
                                        }
                                        else {
                                            filterObject["data"] = newDate;//new Date(newDate).getTime();
                                        }

                                    }
                                }
                                if ($($this).closest("tr.groupTR").length == 0) {
                                    summarytablefiltervalues.push(filterObject);
                                }
                            }
                        }
                    }

                    if (that.state.multifact != undefined && that.state.multifact == 'true') {
                        _.each(summarytablefiltervalues, function (summaryTableFilter) {
                            var temp = {};
                            temp["actualnames"] = summaryTableFilter.columnname;
                            temp["displaynames"] = summaryTableFilter.columndisplayname;
                            temp["datatype"] = summaryTableFilter.dataType;
                            selectedcolumns.push(temp);
                        })
                    }

                    var tempfilters = summarytablefiltervalues;
                    if (filterArray != undefined && filterArray != null) {
                        var filter1 = $.grep(filterArray, function (e) { return e.isdrilldownfilter == true })
                        tempfilters = tempfilters.concat(filter1);
                    }
                    var temp2 = [];
                    _.each(filterArray, function (value, index) {
                        var temp = $.grep(tempfilters, function (e) { return e.columnname == value.columnname });
                        if (temp.length == 0) {
                            temp2.push(value);
                        }
                    });
                    filterArray = tempfilters.concat(temp2);
                }
            }



            var tempfilters = [];
            if (filterArray != undefined && filterArray != null) {
                tempfilters = $.grep(filterArray, function (e) { return e.isdrilldownfilter == true })
            }
            var temp2 = [];
            _.each(filterArray, function (value, index) {
                var temp = $.grep(tempfilters, function (e) { return e.columnname == value.columnname });
                if (temp.length == 0) {
                    temp2.push(value);
                }
            });
            filterArray = tempfilters.concat(temp2);
            if (filterArray != undefined && filterArray != null) {
                _.each(filterArray, function (value) {
                    if (value.isdrilldownfilter) {
                        if (value.dataType == "date") {
                            if (value.customfield != undefined) {
                                drilldownvalue += value.customfield.name + ": " + value.customfield.parameter + ", ";
                            } else {
                                var breadCrumpElements = ChartInfo.functions.getBreadCrumpSpanElement(value["data"]);
                                var appendValue = value["data"];
                                var ulEle = "";
                                if (breadCrumpElements != undefined && breadCrumpElements.hasOwnProperty("span")) {
                                    appendValue = breadCrumpElements["span"];
                                    ulEle = breadCrumpElements["ulelement"];
                                    drilldownvalue += value["columndisplayname"] + ": [" + appendValue + "]" + ulEle + ", ";
                                    showTitle = false;
                                } else {
                                    drilldownvalue += value["columndisplayname"] + ": " + value["data"] + ", ";
                                }
                            }
                        } else {
                            if (value.customfield != undefined) {
                                drilldownvalue += value.customfield.name + ": " + value.customfield.parameter + ", ";
                            } else {
                                var breadCrumpElements = ChartInfo.functions.getBreadCrumpSpanElement(value["data"]);
                                var appendValue = value["data"];
                                var ulEle = "";
                                if (breadCrumpElements != undefined && breadCrumpElements.hasOwnProperty("span")) {
                                    appendValue = breadCrumpElements["span"];
                                    ulEle = breadCrumpElements["ulelement"];
                                    drilldownvalue += value["columndisplayname"] + ": [" + appendValue + "]" + ulEle + ", ";
                                    showTitle = false;
                                } else {
                                    drilldownvalue += value["columndisplayname"] + ": " + value["data"] + ", ";
                                }
                            }
                        }
                    }
                });
            }
            var InstanceExcludeFilterData = JSON.parse(sessionStorage.getItem("instantExcludeFilters"));
            if (InstanceExcludeFilterData != null && InstanceExcludeFilterData != "null") {
                _.each(InstanceExcludeFilterData, function (data) {
                    filterArray.push(data);
                });
            }
            var InstanceIncludeFilterData = JSON.parse(sessionStorage.getItem("instantIncludeFilters"));
            if (InstanceIncludeFilterData != null && InstanceIncludeFilterData != "null") {
                _.each(InstanceIncludeFilterData, function (data) {
                    filterArray.push(data);
                });
            }
            drilldownvalue = drilldownvalue.substring(0, drilldownvalue.length - 2);
            var responseCount = 0;
            var customFields = sessionStorage.getItem("customFields");
            var temp = JSON.parse(customFields);
            var res;
            _.each(temp, function (customfields) {
                _.each(customfields.parametrFieldsArray, function (value) {
                    var customparameter = ChartInfo.functions.getCustomParameter();
                    if (customParametersFilters != undefined && customParametersFilters.length != 0) {
                        customparameter = customParametersFilters;
                    }

                    res = $.grep(customparameter, function (e) {
                        return e.parametername == value;
                    })
                    var formula = customfields.formula;
                    var scriptcondition = customfields.scriptcondition;
                    var fields = customfields.fields;
                    var sqlformula = customfields.sqlformula;
                    if (res.length != 0) {
                        customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
                        customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
                        customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
                        customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
                    } else {
                        _.each(ChartInfo.functions.getCustomParameter(), function (values) {
                            if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
                                customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                                customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
                                customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                            }
                        });
                    }
                });
            });
            //					filterArray = $.grep(filterArray ,function(e){ return e.excludeFilterData == undefined || e.excludeFilterData != "excludeFilterData"});
            /*	if( ChartInfo.generateFilterWithEmbededFilters(filterArray, this.state.viewcollection.getSharedFilters(this.viewreportmodel)).length>0){
                    filterArray = ChartInfo.generateFilterWithEmbededFilters(filterArray, this.state.viewcollection.getSharedFilters(this.viewreportmodel));
                }
                */
            /*if(selectedcolumns.length>0){
                var selectedColumnsArray=[];
                for(let k=0;k<selectedcolumns.length;k++){
                    selectedColumnsArray.push(selectedcolumns[k].actualnames.split(".").shift());
                }
                var isFactExists=_.intersection(that.options.facttables,selectedColumnsArray)
                if(isFactExists!=undefined && isFactExists.length==0 && that.multifact == 'true'){
                    $('#spinnerchart'+this.options.chartnumber).hide();
                    $('.pivotdataviewwrap[data-number='+this.options.chartnumber+']').remove()
                    $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
                    $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
                    $('.birdmessagebuttons').removeClass('show');
                    $('.birdmessagebuttons').addClass('hide');
                    $('.birdmessage-center').addClass("message-top-in");
                    $('.details').removeClass('show');
                    $('.details').addClass('hide');
                    $('.birdmessage h2').empty().append('Warning');
                    $('.birdmessage-info').empty().text(getMessage('BirdWarning140'));
                    return false;
                }
            }*/
            customFields = JSON.stringify(temp);
            viewsinglereportbodymodal.set("aggregation", aggregation);
            viewsinglereportbodymodal.set("xaxis", xaxisData.columnName);
            viewsinglereportbodymodal.set("xaxisdataType", xaxisData.dataType);
            viewsinglereportbodymodal.set("yaxis", yAxisData);
            viewsinglereportbodymodal.set("yAxisDataType", yAxisDataType);
            viewsinglereportbodymodal.set("datamodelId", this.state.datamodelId);
            viewsinglereportbodymodal.set("customFields", customFields);
            viewsinglereportbodymodal.set("reportId", this.state.reportId);
            viewsinglereportbodymodal.set("filterObject", JSON.stringify(filterArray));
            viewsinglereportbodymodal.set("isImportedReport", that.isImportedReport != undefined && that.isImportedReport == true ? true : false);
            var rangefieldMap = {};
            var ramgeArray = [];
            if (chartDetails.xAxis != undefined && chartDetails.xAxis.length > 0) {
                ramgeArray = $.grep(ChartInfo.functions.getRange(), function (e) { return e.name == chartDetails.xAxis[0].columnName });
            }
            if (ramgeArray != undefined && ramgeArray.length > 0) {
                rangefieldMap[ramgeArray[0].name] = ramgeArray[0];
            }
            viewsinglereportbodymodal.set("rangefieldMap", JSON.stringify(rangefieldMap));
            if (selectedcolumns.length < 1) {
                viewsinglereportbodymodal.set("selectedcolumns", chartDetails["selectedcolumns"]);
            } else {
                viewsinglereportbodymodal.set("selectedcolumns", JSON.stringify(selectedcolumns));
            }
            viewsinglereportbodymodal.set("rowLimit", rowLimit);
            viewsinglereportbodymodal.set("script", customFields);
            var customMeasurefields = ChartInfo.functions.getCustomMeasureField();
            viewsinglereportbodymodal.set("customaggregatedscriptfield", JSON.stringify(customMeasurefields));
            var custommeasurehierarchyArray = ChartInfo.functions.getMeasureHierarchy();
            viewsinglereportbodymodal.set("custommeasurehierarchy", JSON.stringify(custommeasurehierarchyArray));

            var isSingleReport = $(".fullscreenmain").hasClass("singlereportview");

            if (isSingleReport) {
                viewsinglereportbodymodal.set("isStoryBoard", false);
            } else {
                viewsinglereportbodymodal.set("isStoryBoard", true);
            }
            if (typeof tableInfo === "undefined") {
                var tableInfo = {
                    chartType: chartDetails["chartType"]
                };
            } else {
                tableInfo.chartType = chartDetails["chartType"];
            }


            var chartSetting = chartDetails['chartSettings'];
            if (chartDetails["reporttitle"] != undefined && chartSetting["chartTitle"] == true) {
                tableInfo.showTitle = true;
                tableInfo.title = chartDetails["reporttitle"];
            }
            var bootObj = {};
            bootObj["currentChartNumber"] = currentChartNumber;
            bootObj["gridmodel"] = viewsinglereportbodymodal;
            bootObj["tableInfo"] = tableInfo;
            bootObj["pos"] = pos;
            bootObj["popupwindow"] = popupwindow;
            bootObj["reporttype"] = undefined;
            bootObj["target"] = undefined;
            bootObj["isFromPagenation"] = undefined;
            var bootStrapTable = new BootStrapTable(bootObj);
            bootStrapTable.render();
            // ChartUtils.drawBootstrapTable(currentChartNumber, viewsinglereportbodymodal,tableInfo,pos,popupwindow);

            //$(".gridspinner").hide();
            $(".spinner").hide();
            $('.summarytable_filtered_datasetcount').text(responseCount);
            $('.summarytable_datasetcount').text($('.datacount[data-num=' + currentChartNumber + ']  .publishinfo').text());
            if (showTitle == false) {
                $('.summarytable_filtervalues').html(drilldownvalue);
            } else {
                $('.summarytable_filtervalues').text(drilldownvalue).attr("title", drilldownvalue);
            }
        }
        if (window.location.pathname.indexOf("viewpurgereport") >= 0) {
            sessionStorage.setItem("isDataTableDataExport", false);
        }
        if (window.location.pathname.indexOf("embeddedreport") >= 0 || window.location.pathname.indexOf("alertreport") >= 0) {
            sessionStorage.setItem("isDataTableDataExport", false);
        }
        if (window.location.pathname.indexOf("schedulereport") >= 0) {
            sessionStorage.setItem("isDataTableDataExport", false);
        }
        $('.drillmoreitemslist').click(function (e) {
            e.stopPropagation();
            var $this = e.currentTarget;
            var top = e.pageY + 14;
            if (top < 100) { top = 100; }
            var left = e.pageX - 10;
            $("body>.quickfilterslist, body>span.quickfiltermask").remove();
            var clonedEle = $($this).parent().find(".quickfilterslist").clone();
            $("body").append(clonedEle.removeClass("hide").css({ top: top, left: left })).append("<span class='quickfiltermask'></span>");
            $("body span.quickfiltermask").on("click", function (e) {
                $("body>.quickfilterslist, body>span.quickfiltermask").remove();
            });
        });
    }
    drawHistogramEve(msg, data) {
		this.drawHistogram(data);
    }
    drawHistogram =(e)=>{
        $('.gridspinner').show();
        var $this = e.currentTarget;
        var selectedValue = $($this).attr('data-val');
        if(selectedValue=="reset"){
            var chartId=$($this).attr('chart-id');
            ChartInfo.functions.setDateHistogramStatus(chartId,false);
            var dateHistogramXaxisData=[{columnName:$($this).attr('column-name'),displayName:$($this).attr('column-display-name'),dataType:$($this).attr('column-type')}];
            ChartInfo.functions.setDateHistogramXaxisData(dateHistogramXaxisData);
            ChartInfo.functions.getFilters(false);
            ChartInfo.functions.setDateHistogramState(selectedValue, chartId);
            this.applyfiltesandredrawchart($($this).attr('data-val'), $($this).attr('chart-id'), undefined, undefined, undefined, undefined, undefined, undefined, undefined);
            setTimeout(function(){					$('#chart'+chartId).find('.drawhistogram[data-val="'+selectedValue+'"]').addClass("active");
            },2000);
        }else{
            var chartId=$($this).attr('chart-id');
            ChartInfo.functions.setDateHistogramStatus(chartId,true);
            var dateHistogramXaxisData=[{columnName:$($this).attr('column-name'),displayName:$($this).attr('column-display-name'),dataType:$($this).attr('column-type')}];
            ChartInfo.functions.setDateHistogramXaxisData(dateHistogramXaxisData);
            ChartInfo.functions.getFilters(false);
            ChartInfo.functions.setDateHistogramState(selectedValue,chartId);
            this.applyfiltesandredrawchart($($this).attr('data-val'), $($this).attr('chart-id'), undefined, undefined, undefined, undefined, undefined, undefined, undefined);
            setTimeout(function(){					
                $('#chart'+chartId).find('.drawhistogram[data-val="'+selectedValue+'"]').addClass("active");
            $(".showtrending[data-num="+chartId+"]").hide();
            },2000);
        }
        var	isSharedReport=false;
        if (window.location.pathname.indexOf("sharereport") >= 0) {
        isSharedReport = true;
        }
        var data=JSON.parse(ChartInfo.functions.getChartDetails(chartId));
        $("#chart"+chartId+" ").before("<ul class='breadcrumb' data-num="+chartId+"></ul>");
        if(chartId !=undefined){
            if(data["drillDownFilter"]!=undefined){	
                var	breadCrumbElement=$('.breadcrumb[data-num='+chartId+']')
                var that=this;
                    _.each(data["drillDownFilter"],function (value,index){
                        if(value["saved"]=="true"){
                             var appendValue = value.data;
                             var ulEle = "";
                             var breadCrumpElements = ChartInfo.functions.getBreadCrumpSpanElement(value.data);
                             if(breadCrumpElements!= undefined && Object.keys(breadCrumpElements).length>0){
                                 appendValue = breadCrumpElements["span"];
                                 ulEle = breadCrumpElements["ulelement"];
                             }
                             if(isSharedReport != true && ChartInfo.functions.getisViewOnlyFlag() != true){
                                 breadCrumbElement.append("<li  class=drilldown saved>"+value.columndisplayname+" ["+appendValue+"] <a columnname='"+value.columnname+"'  name='"+value.data+"' class='close removedrilldownsinglereport' title='Remove'><i class='fa fa-times'<i/></a>"+ulEle+"</li>");
                             }else{
                                 breadCrumbElement.append("<li  class=drilldown saved>"+value.columndisplayname+" ["+appendValue+"] <a columnname='"+value.columnname+"'  name='"+value.data+"' class='close removedrilldownsinglereport' ></a>"+ulEle+"</li>");
                             }
                        }
                    }); 
            }	
        }		
        }
    
    excludingFilterArray = (filterArray, value) => {
        var temp5 = true;
        var tempFilterArray = [];
        _.each(filterArray, function (i, j) {
            if (filterArray.length > 0) {
                if (filterArray[j].columnname != value) {
                    tempFilterArray.push(filterArray[j]);
                    temp5 = false;
                    /*} else {
                if(filterArray[j].columnname == value)	{
                    filterArray.splice(j);
                    temp5= false;*/
                } else if (filterArray[j].customfield != undefined && filterArray[j].customfield.name == value) {
                    tempFilterArray.push(filterArray[j]);
                    temp5 = false;
                }
            } else {
                temp5 = false;
            }
        });

        return temp5;
    }
    findSameFilterisExists = (filterArray, filtervalue) => {
        for (var i = 0; i < filterArray.length; i++) {
            if (JSON.stringify(filterArray[i]) == JSON.stringify(filtervalue)) {
                return true;
            }
        }
        return false;
    }
    isCustomFiledExists = (customFields, currentColumnName) => {
        for (var i = 0; i < customFields.length; i++) {
            if (customFields[i].name == currentColumnName) {
                return false;
            }
        };
        return true;
    }
    ColumnPreview(msg, data) {
        if (data != undefined) {
            this.columnpreview(data.name, data.displayname, data.dataType);
        } else {
            this.columnpreview();
        }
    }
    columnpreview = (name, displayname, dataType) => {
        var that = this;
        $('body #datatablepopupview0').remove();
        $('body').append('<div class=' + "datatablepopupview" + 0 + ' id=' + "datatablepopupview" + 0 + '></div>');
        ReactDOM.render(
            <DataTablePopupView
                dimensions={collection}
                currentChartNumber={0}
                target={undefined}
                multifactentities={this.state.multifactentities}
                multifact={this.state.multifact}
                facttables={this.state.facttables}
                isFromColumnPreview={true}
                appendheaders={false}
            >
            </DataTablePopupView>,
            document.querySelector(".datatablepopupview0"));

        var gridmodel = new FormData();
        var temp = {};
        var selectedcolumns = [];
        temp["actualnames"] = name;
        temp["displaynames"] = displayname;
        temp["datatype"] = dataType;
        selectedcolumns.push(temp);

        var customFields = sessionStorage.getItem("customFields");
        var tempCF = JSON.parse(customFields);
        var res;
        var customParameterFilterArray = ChartInfo.functions.getCustomParametersFilters(this.props.applyattributes);
        _.each(tempCF, function (customfields) {
            _.each(customfields.parametrFieldsArray, function (value) {
                var customparameter = ChartInfo.functions.getCustomParameter();
                if (customParameterFilterArray != undefined && customParameterFilterArray.length != 0) {
                    customparameter = customParameterFilterArray;
                }
                res = $.grep(customparameter, function (e) {
                    return e.parametername == value;
                })
                var formula = customfields.formula;
                var scriptcondition = customfields.scriptcondition;
                var fields = customfields.fields;
                var sqlformula = customfields.sqlformula;
                if (res.length != 0) {
                    customfields.formula = formula.replace("[" + value + "]", res[0].parametercurrentvalue);
                    customfields.scriptcondition = scriptcondition.replace("[" + value + "]", res[0].parametercurrentvalue);
                    customfields.fields = fields.replace("[" + value + "]", res[0].parametercurrentvalue);
                    customfields.sqlformula = sqlformula.replace("[" + value + "]", res[0].parametercurrentvalue);
                } else {
                    _.each(ChartInfo.functions.getCustomParameter(), function (values) {
                        if (customfields.parametrFieldsArray.indexOf(values.parametername) >= 0) {
                            customfields.formula = formula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                            customfields.scriptcondition = scriptcondition.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                            customfields.fields = fields.replace("[" + value + "]", values.parametercurrentvalue);
                            customfields.sqlformula = sqlformula.replace("[" + values.parametername + "]", values.parametercurrentvalue);
                        }
                    });
                }
            });
        });
        customFields = JSON.stringify(tempCF);

        var selectedcustomfields = [];
        if (customFields != undefined && customFields != null && customFields != "null") {
            var temp = JSON.parse(customFields);
            var getcustomfield = $.grep(temp, function (e) { return e.name == name });
            if (getcustomfield.length > 0) {
                selectedcustomfields.push(getcustomfield[0]);
            }
        }
        var parsedSharedFilters = [];
        if (collection.getSharedFilters() != undefined && collection.getSharedFilters() != null && collection.getSharedFilters() != "null" && collection.getSharedFilters().length > 2) {
            parsedSharedFilters = collection.getSharedFilters();
        }

        gridmodel.set("aggregation", "");
        gridmodel.set("xaxis", "");
        gridmodel.set("xaxisdataType", "");
        gridmodel.set("yAxisDataType", "");
        gridmodel.set("yaxis", "");
        gridmodel.set("datamodelId", this.state.datamodelId);
        gridmodel.set("indexName", (this.props.indexname != undefined) ? this.props.indexname : "");
        gridmodel.set("customFields", JSON.stringify(selectedcustomfields));
        gridmodel.set("reportId", this.props.reportId);
        gridmodel.set("filterObject", parsedSharedFilters);
        gridmodel.set("selectedcolumns", JSON.stringify(selectedcolumns));
        gridmodel.set("rangefieldMap", "");
        gridmodel.set("entities", (this.props.entities != undefined) ? this.props.entities : "");
        gridmodel.set("rowLimit", 100);
        gridmodel.set("timeZone", (this.props.timeZone != undefined) ? this.props.timeZone : "");
        gridmodel.set("script", JSON.stringify(selectedcustomfields));
        gridmodel.set("isImportedReport", this.isImportedReport != undefined ? this.isImportedReport : false);
        //			gridmodel.set("showMoreOptions",true);
        var tableInfo = {
            showTitle: false,
            drilldownvalue: undefined
        };

        var bootObj = {};
        bootObj["currentChartNumber"] = 0;
        bootObj["gridmodel"] = gridmodel;
        bootObj["tableInfo"] = tableInfo;
        bootObj["pos"] = undefined;
        bootObj["popupwindow"] = "popupview";
        bootObj["reporttype"] = undefined;
        bootObj["target"] = undefined;
        bootObj["isFromPagenation"] = undefined;
        bootObj["ForPreviewData"] = true;
        var bootStrapTable = new BootStrapTable(bootObj);
        bootStrapTable.render();
    }
    addtrendlineEv = (msg, data) => {
        this.addtrendline(data);
    }
    addtrendline = (eventTarget) => {
        var $this = eventTarget;
        var currentChartNumber = $($this).attr("data-num");
        var trendtype = $($this).attr("id");
        if (currentChartNumber == this.state.chartnumber) {
            var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(currentChartNumber));
            this.applyfiltesandredrawchart(undefined, this.state.chartnumber, undefined, trendtype, undefined, "no", undefined);
        }
        this.addSavedDrilldownBreadCrumbs(currentChartNumber)
    }
    adjustChartHeightEveCap(msg, data) {
        this.adjustChartHeight(data.element, data.pos);
    }
    adjustChartHeight(element, pos) {
        var ua = navigator.userAgent;
        var is_mobile_chrome = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua));
        if ($(element).attr("data-num") == this.state.chartnumber) {
            this.pos = pos;
            var that = this;
            setTimeout(function () {
                var parentHeight = /*$(element).closest(".tileview").height()*/ $(".maxanim1[data-num='" + that.state.chartnumber + "']").height() - 42;
                /*var parentWidth = $(element).closest(".tileview").width() - 40;*/
                var parentWidth = /*$(element).closest(".tileview").width()*/  $(".maxanim1[data-num='" + that.state.chartnumber + "']").width() - 2;
                var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(that.state.chartnumber));
                var chartType = chartDetails["chartType"];
                if (ChartInfo.functions.getChartTypeForMap(that.state.chartnumber) != undefined) {
                    chartType = ChartInfo.functions.getChartTypeForMap(that.state.chartnumber);
                }
                if ($(window).width() < 480) {
                    $(window).resize(function () {
                        $('.metric-container').css('display', 'inline');
                        var height = $("#chart" + that.state.chartnumber).height();
                        var width = $("#chart" + that.state.chartnumber).width();
                        //  ChartUtils.updateReport("chart"+that.state.chartnumber,null,height,width);
                    });
                }
                if (pos != "max") {
                    if (chartType === "doby" || chartType === "pivot" || chartType === "textchart" || chartType === "metric" || chartType === "solidgauge" || chartType === "fullcirclegauge" || chartType == "predictionchart" || chartType == "h2o_datatable" || chartType === "summarytable" || ChartInfo.functions.getDateHistogramStatus(that.state.chartnumber) == true || chartType == "h2o_variableimportance" || chartType == "h2o_scorehistory" || chartType == "sparkline") {
                        $(".maxanim1[data-num='" + that.state.chartnumber + "']").find('.storyboard-data-buttons').hide();
                    } else {
                        $(".maxanim1[data-num='" + that.state.chartnumber + "']").find('.storyboard-data-buttons').show();
                    }
                    if (chartType === "doby" || chartType == "h2o_datatable") {
                        $("#chart" + that.state.chartnumber).css("height", parentHeight);

                        var baseHeight = $("#chart" + that.state.chartnumber).height(),
                            headerHeight = $("#chart" + that.state.chartnumber + " .notifications-table-container:nth(0) table>thead").height(),
                            paginationHeight = $("#chart" + that.state.chartnumber + " .notifications-table-container:nth(1)").height();
                        var chartHeight = baseHeight - (headerHeight + paginationHeight + 0) + 22;
                        if (dataHeight != null && chartHeight >= dataHeight) { chartHeight = chartHeight - (chartHeight - dataHeight) + 33 };
                        $(".drawbootstraptable" + that.state.chartnumber + " .table").bootstrapTable('resetView', { height: chartHeight });
                    }
                    if (chartType === "pivot") {
                        $('#chart' + that.state.chartnumber + ' .pivotscroll').css("max-height", $(element).closest(".tileview").height() + -100 + "px");
                        //$(".pivotnavigationbuttons"+that.state.chartnumber).show();
                        $("#chart" + that.state.chartnumber).css("height", "");

                    }
                    if (chartType == "textchart") {
                        $('#chart' + that.state.chartnumber).empty();
                        //style="display: block;height: 200px;overflow-y: auto;overflow-x: hidden;"
                        var textheight = $("#tileview" + that.state.chartnumber).height() - $(".maxanim3[data-num=" + that.state.chartnumber + "]").height() - 14;
                        $("#chart" + that.state.chartnumber).css({
                            'display': 'block',
                            'overflow-y': 'auto',
                            'height': textheight,
                            'overflow-x': 'auto',
                            'word-wrap': 'break-word',
                            //'max-width': 'calc(100vw - 150px)'
                        });
                        //$('.richtextcontrolschart' + that.state.chartnumber).hide();
                    }
                    if (chartType != "doby" && chartType != "pivot" && chartType != "textchart" && chartType !== "trellis" && chartType != "h2o_datatable") {
                        //if($(".drawbootstraptable"+that.state.chartnumber).length===0){$("#chart"+that.state.chartnumber).css("height", "");}else {$("#chart"+that.state.chartnumber).css("height", parentHeight);}						
                    }
                    if (chartType == "solidgauge" || chartType == "fullcirclegauge") {
                        // parentHeight = parentHeight - 90;
                        parentHeight = parentHeight - 0;
                        parentWidth = $("#chart" + that.state.chartnumber).width()
                        //$("#chart"+that.state.chartnumber).css("height", parentHeight);						
                    }
                    $('.paginationdiv' + that.state.chartnumber).hide();
                    $(".pivotsettingsmain" + that.state.chartnumber).hide();
                    $(".pivotnavigationbuttons" + that.state.chartnumber).hide();
                    //					$("#spinnerchart"+that.state.chartnumber).show();
                    if (chartType !== "trellis" && chartType !== "sparkline") {
                        if (chartType == "metric") {
                            $(element).closest(".tileview").find(".highcharts-container").height($(element).closest(".tileview").height() - 2).css("height", $(element).closest(".tileview").height() - 2 + "px");
                            //$(element).closest(".tileview").find(".highcharts-container").width(parentWidth);
                        } else {
                            $(element).closest(".tileview").find(".highcharts-container").height(parentHeight);
                            $(element).closest(".tileview").find(".highcharts-container").width(parentWidth);
                        }
                    } else if (chartType === "sparkline") {
                        parentHeight = parentHeight - 10;
                        $("#chart" + that.state.chartnumber + " .sparklinecontainer").css("height", parentHeight);
                        $("#chart" + that.state.chartnumber + " .sparklinecontainer").parent().css("height", "");
                        parentHeight = parentHeight - 112;
                        $("#chart" + that.state.chartnumber + " .dataTables_scrollBody").css("height", parentHeight);
                        if ($("#chart" + that.state.chartnumber + " .dataTables_scrollBody table").height() < $("#chart" + that.state.chartnumber + " .dataTables_scrollBody").height()) {
                            $("#chart" + that.state.chartnumber + " .dataTables_scrollBody").css("height", $("#chart" + that.state.chartnumber + " .dataTables_scrollBody table").height() + 12);
                        }
                    } else {
                        var chartscount = $("#chart" + that.state.chartnumber + " #trellis td").length;
                        var parentwidth = $('#chart' + that.state.chartnumber).width() - 20;
                        parentWidth = parentwidth / chartscount;
                        if (parentWidth < 300) { parentWidth = 300; }
                        parentHeight = parentHeight - 10;
                        $(element).closest(".tileview").find("#chart" + that.state.chartnumber).height("");
                        $(element).closest(".tileview").find(".highcharts-container").height(parentHeight);
                    }
                    if (chartType === "summarytable") {
                        /*parentHeight=parentHeight-10;*/
                        $("#chart" + that.state.chartnumber + " .summartytablecontainer").css("height", parentHeight);
                        $("#chart" + that.state.chartnumber + " .summartytablecontainer").parent().css("height", "");
                        var tfootTotal = $("#chart" + that.state.chartnumber + " .summartytablecontainer .dataTables_scrollFoot").height() + 90;
                        parentHeight = parentHeight - tfootTotal;
                        $("#chart" + that.state.chartnumber + " .dataTables_scrollBody").css("height", parentHeight);
                        if ($("#chart" + that.state.chartnumber + " .dataTables_scrollBody table").height() < $("#chart" + that.state.chartnumber + " .dataTables_scrollBody").height()) {
                            $("#chart" + that.state.chartnumber + " .dataTables_scrollBody").css("height", $("#chart" + that.state.chartnumber + " .dataTables_scrollBody table").height() + 1);
                        }
                        var chartNumber = that.state.chartnumber;
                        setTimeout(function () {
                            (function ($) {
                                $.fn.hasScrollBar = function () {
                                    return this.get(0) ? this.get(0).scrollHeight > this.innerHeight() : false;
                                }
                            })($);

                            if ($("#chart" + chartNumber + " .dataTables_scrollBody").hasScrollBar()) {
                                $("#chart" + chartNumber + " .summartytablecontainer").addClass("summaryhasscroll");
                            } else {
                                $("#chart" + chartNumber + " .summartytablecontainer").removeClass("summaryhasscroll");
                            }
                        }, 1000);
                    }
                    if (chartType != "summarytable" && chartType != "pivot" && chartType != "textchart" && chartType != "doby" && chartType != "h2o_datatable" && chartType != "metric") {
                        $("#chart" + that.state.chartnumber).css("height", parentHeight);
                        //if($(".drawbootstraptable"+that.state.chartnumber).length===0){$("#chart"+that.state.chartnumber).css("height", "");}else {$("#chart"+that.state.chartnumber).css("height", parentHeight);}
                    }
                    if (chartType == "metric") {
                        var topmargin = $('.boc-snapshot-options.maxanim3[data-num=' + that.state.chartnumber + ']').outerHeight() + 12;
                        $("#chart" + that.state.chartnumber).css({ "height": $(element).closest(".tileview").height() - 2, "margin-top": "-" + topmargin + "px" });
                    }
                    if (chartType === "leaflet" || chartType === "srilankamap" || chartType === "wordcloud") {
                        $("#chart" + that.state.chartnumber).css("height", parentHeight);
                    }

                }
                else {
                    $("#chart" + that.state.chartnumber).css("margin-top", "");
                    if (chartType === "doby" || chartType == "h2o_datatable") {
                        $('.paginationdiv' + that.state.chartnumber).show();
                        //$("#chart"+that.state.chartnumber).css("height", "calc(100vh - 30vh)");
                        var navbar = $('.navbar-fixed-top').height();
                        var toolbar = $('.reporttoolbar[data-num="' + that.state.chartnumber + '"] .controlbar-main').height();
                        var optionh = $('.fullscreenmain[data-num="' + that.state.chartnumber + '"] .maxanim9').height();
                        var quickfilterh = $(".quickfiltercontainer[data-num=" + that.state.chartnumber + "]").height();
                        var footer = $('.footer').height();
                        var finalh = navbar + toolbar + optionh + quickfilterh + footer + 18;
                        if (is_mobile_chrome) {
                            finalh = finalh + 60
                        }
                        $("#chart" + that.state.chartnumber).css("height", "calc(100vh - " + finalh + "px)");

                        var baseHeight = $("#chart" + that.state.chartnumber).height(),
                            headerHeight = $("#chart" + that.state.chartnumber + " .notifications-table-container:nth(0) table>thead").height(),
                            dataHeight = $("#chart" + that.state.chartnumber + " .notifications-table-container:nth(0) table>tbody").height(),
                            paginationHeight = $("#chart" + that.state.chartnumber + " .notifications-table-container:nth(1)").height();
                        var chartHeight = baseHeight - (headerHeight + 10);
                        if (dataHeight != null && chartHeight >= dataHeight) { chartHeight = dataHeight + 41 };
                        $(".drawbootstraptable" + that.state.chartnumber + " .table").bootstrapTable('resetView', { height: chartHeight });

                    } else if (chartType === "pivot") {
                        var navbar = $('.navbar-fixed-top').height();
                        var toolbar = $('.reporttoolbar[data-num="' + that.state.chartnumber + '"] .controlbar-main').height();
                        var optionh = $('.fullscreenmain[data-num="' + that.state.chartnumber + '"] .maxanim9').height();
                        var quickfilterh = $(".quickfiltercontainer[data-num=" + that.state.chartnumber + "]").height();
                        var footer = $('.footer').height();
                        var finalh = navbar + toolbar + optionh + quickfilterh + footer + 50;
                        if (is_mobile_chrome) {
                            finalh = finalh + 60
                        }
                        $("#chart" + that.state.chartnumber).css("height", "calc(100vh - " + finalh + "px)");
                        $('#chart' + that.state.chartnumber + ' .pivotscroll').removeAttr('style');
                        $(".pivotsettingsmain" + that.state.chartnumber).show();
                        $(".pivotcharticon" + that.state.chartnumber).show();
                        $(".pivotnavigationbuttons" + that.state.chartnumber).show();

                        var attropen = $(".rightattributes.open").length;
                        var attrwidth = 0;
                        if (attropen > 0) { attrwidth = $(".rightattributes.open").width() + 60 }
                        $(".fullscreenmain #chart" + that.state.chartnumber + "").css("max-width", "calc(100vw - " + attrwidth + "px)");
                    }
                    if (chartType == "textchart") {
                        $('#chart' + that.state.chartnumber).empty();
                        $('.richtextcontrolschart' + that.state.chartnumber).show();
                        $("#chart" + that.state.chartnumber).css({
                            'display': 'block',
                            'height': 'calc(100vh - 200px)',
                            'word-wrap': 'break-word',
                            'overflow-y': 'auto',
                            'overflow-x': 'auto',
                            //'max-width': 'calc(100vw - 150px)'
                        });
                    } else if (chartType == 'summarytable') {
                        $("#chart" + that.state.chartnumber).css("height", "calc(100vh - 35vh)");
                        var chartNumber = that.state.chartnumber;
                        var navh = $("nav.navbar.navbar-bird.navbar-fixed-top").height();
                        var controlh = $(".reporttoolbar[data-num=" + chartNumber + "] .controlbar-main").height();
                        var charcontrolh = $(".maxanim9[data-num=" + chartNumber + "]").height();
                        var quickfilterh = $(".quickfiltercontainer[data-num=" + chartNumber + "]").height();
                        var newFilterh = $('.tab-filters-container').outerHeight();
                        var breadcrumbh = $(".breadcrumb[data-num=" + chartNumber + "]").outerHeight() + 3;
                        var footerh = $("footer.footer").height();
                        var summarcontrolh = $(".summartable" + chartNumber + ".summartytablecontainer .dataTables_length").height();
                        var summarheaderh = $(".summartable" + chartNumber + ".summartytablecontainer .dataTables_scrollHeadInner").height();
                        var summarfooterh = $(".summartable" + chartNumber + ".summartytablecontainer .dataTables_scrollFoot").height();
                        var summarpagingh = $("#summartable" + chartNumber + "_paginate").innerHeight();

                        var finalh = navh + controlh + charcontrolh + quickfilterh + breadcrumbh + newFilterh + footerh + summarcontrolh + summarheaderh + summarfooterh + summarpagingh + 15;
                        $("#chart" + chartNumber + " .dataTables_scrollBody").css("max-height", "calc(100vh - " + finalh + "px)");

                        var attropen = $(".rightattributes.open").length;
                        var attrwidth = 0;
                        if (attropen > 0) { attrwidth = $(".rightattributes.open").width() + 60 }
                        $(".fullscreenmain #chart" + chartNumber + "").css("max-width", "calc(100vw - " + attrwidth + "px)");

                        var chartNumber = that.state.chartnumber;
                        setTimeout(function () {
                            (function ($) {
                                $.fn.hasScrollBar = function () {
                                    return this.get(0) ? this.get(0).scrollHeight > this.innerHeight() : false;
                                }
                            })($);

                            if ($("#chart" + chartNumber + " .dataTables_scrollBody").hasScrollBar()) {
                                $("#chart" + chartNumber + " .summartytablecontainer").addClass("summaryhasscroll");
                            } else {
                                $("#chart" + chartNumber + " .summartytablecontainer").removeClass("summaryhasscroll");
                            }
                        }, 1000);
                    } else if (chartType != "doby" && chartType != "pivot" && chartType != "textchart" && chartType != "h2o_datatable" && chartType!="trellis") {

                        /*for Safari Browser Storyboard Issue Fixed*/
                        var browser = "";
                        var chrome = /chrom(e|ium)/.test(navigator.userAgent.toLowerCase());
                        var safari = /safari/.test(navigator.userAgent.toLowerCase());

                        if (chrome == false && safari == true) {
                            parentHeight = $(element).closest(".fullscreenmain").find(".maxanim2").height() - 160;
                            var maxWidth = $(element).closest(".fullscreenmain").find(".maxanim2").width();
                            $("#chart" + that.state.chartnumber).css("height", parentHeight);
                            $(element).closest(".fullscreenmain").find(".maxanim5").css("max-width", maxWidth);
                            $(element).closest(".fullscreenmain").find(".maxanim7").css("max-width", maxWidth);
                        } else {
                            /*$("#chart"+that.state.chartnumber).css("height", "calc(100vh - 30vh)");*/
                            //var totalh = $('.fullscreenmain[data-num="'+that.state.chartnumber+'"] .maxanim5').height();
                            var optionh = $('.fullscreenmain[data-num="' + that.state.chartnumber + '"] .maxanim9').height();
                            var quickfilterh = $('.quickfiltercontainer[data-num="' + that.state.chartnumber + '"]').height();
                            var finalh = 0;
                            if (is_mobile_chrome) {
                                finalh = finalh + 60
                            }

                            $("#chart" + that.state.chartnumber).css("height", "calc(100vh - " + finalh + "px)");
                        }
                        /*---for Safari Browser Storyboard Issue Fixed*/

                        //$("#chart"+that.state.chartnumber).css("height", "calc(100vh - 25vh)"); Previous Code for all Browsers
                    }

                    //					$("#spinnerchart"+that.state.chartnumber).show();
                    //					$("#spinnerchart"+that.state.chartnumber).show();
                    // fix for when x-axis labels are too long....
                    var chartnum = that.chartnumber;
                    if (chartType == "bar" || chartType == "stackedbar" || chartType == "line" || chartType == "spline" || chartType == "area" || chartType == "stackedarea" || chartType == "scatter" || chartType == "combinationchart" || chartType == "multicolorbar" || chartType == "multixbar" || chartType == "multiaxisbar" || chartType == "waterfall" || chartType == "3dbar" || chartType == "multixline" || chartType == "multixarea" || chartType == "heatmap") {
                        //   var chart = $("#chart"+chartnum).highcharts();
                        var chart = undefined;

                        if (chart != null && chart != undefined) {
                            chart.update({ xAxis: [{ labels: { rotation: -45 } }] })
                        }
                    }
                    parentHeight = $('.fullscreenmain[data-num=' + that.state.chartnumber + ']').find(".fullscreensample").height() - 10;
                    if ($('.breadcrumb[data-num=' + that.state.chartnumber + '] li').length > 0) {
                        parentHeight = parentHeight - 83;
                    }
                    var attrmodalwidth = 0;
                    if ($(".rightattributes.open").length > 0) {
                        attrmodalwidth = $(".rightattributes.open").width() + 60;
                        parentWidth = $(window).width() - attrmodalwidth;
                    } else {
                        parentWidth = $('.fullscreenmain[data-num=' + that.state.chartnumber + ']').find(".fullscreensample").width();
                    }

                    if (chartType != "sparkline" && chartType != "doby") {
                        $(element).closest(".fullscreenmain").find(".highcharts-container").height(parentHeight);
                        $(element).closest(".fullscreenmain").find(".highcharts-container").width(parentWidth);
                    }
                    if (chartType === "summarytable") {
                        $("#chart" + that.state.chartnumber + " .summartytablecontainer").css("height", "");
                        $("#chart" + that.state.chartnumber + " .dataTables_scrollBody").css("height", "");
                    }
                    if (chartType === "sparkline") {
                        $("#chart" + that.state.chartnumber + " .dataTables_scrollBody").css("height", "");
                    }
                }
                if (chartType == "sankey" || chartType == "sunburst") {
                    this.redrawChart(chartDetails);
                }
                if (chartType !== "doby" && chartType !== "metric" && chartType != "textchart" && chartType != "summarytable" && chartType != "sparkline" && chartType != "sankey" && chartType != "sunburst" && chartType != "h2o_datatable") {
                    if (chartType === "pivot") {
                        parentHeight = parentHeight - 40;
                        var curentpivottype = $(".maxanim1[data-num=" + that.state.chartnumber + "] .pivotcharttypes").attr('data-type');
                        if (curentpivottype != "pivot" && curentpivottype != undefined) {
                            //  ChartUtils.updateReport("chart"+that.state.chartnumber,that.state.chartnumber,parentHeight,parentWidth);

                            //Re-set Charts Navigator
                            //	var chartinstance = $("#chart" + that.state.chartnumber).highcharts();
                            var chartinstance;
                            if (chartinstance != undefined && chartinstance.navigator != undefined && chartinstance.navigation != undefined) { chartinstance.navigator.update(chartinstance.navigator.series); chartinstance.navigation.update(); }

                        } else {
                            $("#chart" + that.state.chartnumber + " .highcharts-container").remove();
                        }
                    } else if (chartType == "trellis") {
                        var attropen = $(".rightattributes.open").length;
                        var attrwidth = 0;
                        if (attropen > 0) { attrwidth = $(".rightattributes.open").width() + 80 }
                        var chartscount = $("#chart" + that.state.chartnumber + " #trellis > tbody > tr > td").length;
                        var parentwidth = $('#chart' + that.state.chartnumber).width() - 20;
                        var breadcrumb = $("ul.breadcrumb[data-num=" + that.state.chartnumber + "]").outerHeight()
                        parentWidth = parentwidth / chartscount;
                        if (parentWidth < 400) { parentWidth = 400; }
                        parentHeight = parentHeight - 20 + breadcrumb;
                        finalh = finalh + 20;
                        $(".fullscreenmain #chart" + that.state.chartnumber + "").css("max-width", "calc(100vw - " + attrwidth + "px)");
                        $("#chart" + that.state.chartnumber).css("height", "calc(100vh - " + finalh + "px)");
                        //  ChartUtils.updateReport("chart"+that.state.chartnumber,that.state.chartnumber,parentHeight,parentWidth);
                    } else if (chartType == "srilankamap" || chartType == "leaflet") {
                        //  ChartUtils.updateLeaflet("chart"+that.state.chartnumber,that.state.chartnumber,parentHeight,parentWidth);
                    }
                    else {
                        if ($("#chart" + that.state.chartnumber + " .databehindchart").length == 0) {
                            //     ChartUtils.updateReport("chart"+that.state.chartnumber,that.state.chartnumber,parentHeight,parentWidth);

                            //Re-set Charts Navigator
                            //	var chartinstance = $("#chart"+that.state.chartnumber).highcharts();
                            var chartinstance = undefined;
                            if (chartinstance != undefined && chartinstance.navigator != undefined && chartinstance.navigation != undefined) { chartinstance.navigator.update(chartinstance.navigator.series); chartinstance.navigation.update(); }

                        } else {
                            $("#chart" + that.state.chartnumber).css("height", parentHeight - 10);
                        }
                    }
                }
                if (chartType == "predictionchart" || chartType == "h2o_scorehistory" || chartType == "h2o_datatable") {
                    $(".predictionChart" + that.state.chartnumber).removeClass("hide");
                }
                //				$("#spinnerchart"+that.state.chartnumber).hide();
                if (chartType !== "textchart") {
                    ChartUtils.modifyChartHeightOnResize(that.state.chartnumber, pos);
                }

                $(window).trigger('resize');
            }, 500);
            $(".predictmodelsummary" + that.state.chartnumber).addClass('hide');
            var data = JSON.parse(ChartInfo.functions.getChartDetails(that.state.chartnumber));
            $(".quickfiltercontainer[data-num=" + that.state.chartnumber + "]").empty();
            $(".quickfiltercontainer[data-num=" + that.state.chartnumber + "]").append($(".header-quickfiltercontainer").html());
            if (data["drillDownFilter"] != undefined && data["drillDownFilter"].length != 0) {
                $(".breadcrumb[data-num=" + that.state.chartnumber + "]").remove();
                $("#chart" + that.state.chartnumber + "").before("<ul class='breadcrumb' data-num=" + that.state.chartnumber + "></ul>");
                var breadCrumbElement = $('.breadcrumb[data-num=' + that.state.chartnumber + ']')
                _.each(data["drillDownFilter"], function (value, index) {
                    if (value.isDrillDownAll != true) {
                        var name = "";
                        if (value.columndisplayname != undefined) {
                            name = value.columndisplayname;
                        }
                        else {
                            name = value.displayName;
                        }
                        var appendValue = value.data;
                        var ulEle = "";
                        var breadCrumpElements = ChartInfo.functions.getBreadCrumpSpanElement(value.data);
                        if (breadCrumpElements != undefined && Object.keys(breadCrumpElements).length > 0) {
                            appendValue = breadCrumpElements["span"];
                            ulEle = breadCrumpElements["ulelement"];
                        }
                        breadCrumbElement.append("<li class=drilldown saved>" + name + " [" + appendValue + "] <a  columnname='" + value.columnname + "' name='" + value.data + "' class='close removedrilldown' title='Remove'><i class='fa fa-times'<i/></a>" + ulEle + "</li>");
                        $('.removedrilldown').click((e) => { PubSub.publish("removedrilldown", e); });
                    }
                });
                if (sessionStorage.getItem("drilldown" + that.state.chartnumber) != undefined) {
                    _.each(JSON.parse(sessionStorage.getItem("drilldown" + that.state.chartnumber)), function (value, index) {
                        if (value["isDrillDownAll"] != true) {
                            var name = "";
                            if (value.columndisplayname != undefined) {
                                name = value.columndisplayname;
                            }
                            else {
                                name = value.displayName;
                            }
                            breadCrumbElement.append("<li>" + value.name + " [" + value.data + "]</li>");
                        }
                    });
                }
            }
        }
    }
    viewChartEve(msg, data) {
        this.viewChart(data);
    }
    viewChart(CurrentChartNumber) {
        var data = JSON.parse(ChartInfo.functions.getChartDetails(CurrentChartNumber));
        $("#chart" + this.props.chartnumber).empty();
        $(".predictmodelsummary" + this.props.chartnumber).addClass("hide");
        $('.singlereport_summarymodal[data-num=' + this.props.chartnumber + ']').addClass('hide');
        $('.showtrending[data-num=' + this.props.chartnumber + ']').removeClass('active');
        $('.singlereport_trendtype[data-num=' + this.props.chartnumber + ']').removeClass('showactive');
        if ($('.doby-offcanvas-columnlist').hasClass("show")) {
            $('.doby-offcanvas-columnlist').toggleClass("show");
        }
        if (data["oldcharttype"] != undefined) {
            data["chartType"] = data["oldcharttype"];
        }
        else if (data["chartType"] == "pivot" || data["chartType"] == "summarytable" || data["chartType"] == "chartionchart" || data["chartType"] == "doby") {
            data["chartType"] = "bar";
        }
        ChartInfo.functions.updateChartDetails(this.props.chartnumber, JSON.stringify(data));
        var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(this.props.chartnumber));
        //			this.redrawChart(chartDetails, "max");
        this.applyfiltesandredrawchart();
    }
    viewDataTableForSummaryTableEve(msg, data) {
		if (data != undefined) {
			this.viewDataTableForSummaryTable(data.chartnumber, data.selectedcolumn, data.popupwindow, data.$this, data.selectedname);
		} else {
			this.viewDataTableForSummaryTable();
		}
    }
    viewDataTableForSummaryTable(chartnumber, selectedcolumns,popupwindow,$this, selectedname){
        var drilldownvalue = "";
        if(chartnumber == this.props.chartnumber){				
            var responseCount=0;
            var gridmodel = new FormData();
            //var viewReportDataTableCollection = new ViewReportDataTableCollection(gridmodel);
            var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(this.props.chartnumber));
            var rowLimit = chartDetails["dobyrowlimit"];
            var customFields = sessionStorage.getItem("customFields");
            var tempCF=JSON.parse(customFields);
            var res;
            var customParameterFilterArray = ChartInfo.functions.getCustomParametersFilters(this.props.applyattributes);
            _.each(tempCF,function(customfields){
                _.each(customfields.parametrFieldsArray,function(value){
                    var customparameter=ChartInfo.functions.getCustomParameter();
                    if(customParameterFilterArray!=undefined && customParameterFilterArray.length!=0){
                        customparameter=customParameterFilterArray;
                    }
                    res=$.grep(customparameter,function(e){
                        return e.parametername==value;
                    })
                    var formula=customfields.formula;
                    var scriptcondition=customfields.scriptcondition;
                    var fields=customfields.fields;
                    var sqlformula = customfields.sqlformula;
                    if(res.length!=0){
                        customfields.formula=formula.replace("["+value+"]",res[0].parametercurrentvalue);
                        customfields.scriptcondition=scriptcondition.replace("["+value+"]",res[0].parametercurrentvalue);
                        customfields.fields=fields.replace("["+value+"]",res[0].parametercurrentvalue);
                        customfields.sqlformula = sqlformula.replace("["+value+"]",res[0].parametercurrentvalue);
                    }else{
                        _.each(ChartInfo.functions.getCustomParameter(), function(values){
                            if(customfields.parametrFieldsArray.indexOf(values.parametername)>=0){
                            customfields.formula=formula.replace("["+values.parametername+"]",values.parametercurrentvalue);
                            customfields.scriptcondition=scriptcondition.replace("["+values.parametername+"]",values.parametercurrentvalue);
                            customfields.fields=fields.replace("["+value+"]",values.parametercurrentvalue);
                            customfields.sqlformula = sqlformula.replace("["+values.parametername+"]",values.parametercurrentvalue);
                            }
                        });							
                    }
                });
            });
            customFields=JSON.stringify(tempCF);
            var selectedcustomfields = [];
            if(customFields!= undefined && customFields != null  && customFields != "null" && customFields != "[]"){
            var temp=JSON.parse(customFields);
            _.each(selectedcolumns,function(value){
                    var getcustomfield = $.grep(temp,function(e){ return e.name == value.actualnames});
                    if(getcustomfield.length>0){
                        selectedcustomfields.push(getcustomfield[0]);
                    }
                });
            }
            var summarytabledetails = ChartInfo.functions.getSummarytableAxisFieldsArray(chartnumber);
            var that = this;
            if(summarytabledetails !=undefined && summarytabledetails.length>0){
                var details = summarytabledetails[summarytabledetails.length-1];
                if(details["group"]!=undefined){
                    if(temp != undefined){
                        var getcustomfield = $.grep(temp,function(e){
                            return e.name == details["group"].columnName;
                        });
                    }
                    if(getcustomfield != undefined && getcustomfield.length>0){
                        selectedcustomfields.push(getcustomfield[0]);
                    }else{
                        var tempVal = {};
                        if( details["group"].dataType!=undefined &&  details["group"].dataType=="customhierarchy"){
                            var customhierarchy = ChartInfo.functions.getcustomhierarchy();
                            var summaryTableCustomHierarchyDrilldownName = ChartInfo.functions.getSummaryTableCustomHierarchyDrilldownName();
                            if(summaryTableCustomHierarchyDrilldownName!=undefined){
                                _.each(customhierarchy, function(val1) {
                                    if(val1.name==details["group"].columnName){
                                        if(temp != undefined){
                                            var getcustomfield = $.grep(temp,function(e){
                                                    return e.name == val1.hierarchelist[that.state.levelmapping[details["group"].columnName]].columnName;
                                            });
                                        }
                                        if(getcustomfield != undefined && getcustomfield.length>0){
                                            selectedcustomfields.push(getcustomfield[0]);
                                        }else{
                                            tempVal["actualnames"] = val1.hierarchelist[that.state.levelmapping[details["group"].columnName]].columnName;
                                            tempVal["displaynames"] = val1.hierarchelist[that.state.levelmapping[details["group"].columnName]].columnDisplayName;
                                            tempVal["datatype"] = val1.hierarchelist[that.state.levelmapping[details["group"].columnName]].dataType;
                                            selectedcolumns.push(tempVal);
                                        }
                                    }
                                });
                            }
                        }
                        else {
                            tempVal["actualnames"] = details["group"].columnName;
                            tempVal["displaynames"] = details["group"].displayName;
                            tempVal["datatype"] = details["group"].dataType;
                            selectedcolumns.push(tempVal);
                        }
                    }
                }
                
                
                if(that.state.multifact == "true" && selectedname!=undefined){
                    var selectedFeildName;
                    if(tempCF!=undefined && tempCF.length>0){
                        var yaxisCustomFieldCheck;
                        _.each(tempCF, function(i,j) {
                            console.log(selectedname+""+i.name);
                            if(selectedname == i.name){
                                if(i.usedFields.length>1){
                                    for(let k=0;k<i.usedFields.length;k++){
                                        for(let l=0;l<that.props.facttables.length;l++){
                                            if(i.usedFields[k].split(".")[0]==that.props.facttables[l]){
                                                yaxisCustomFieldCheck=i.usedFields[k];
                                                selectedname=yaxisCustomFieldCheck;
                                            }
                                        }
                                    }
                                }else{
                                    yaxisCustomFieldCheck = i.usedFields[0];
                                    selectedname=yaxisCustomFieldCheck;
                                }
                            }
                        });
                    }
                    _.each(this.state.viewcollection.getDimensions(), function(dimension) {
                           console.log(dimension.columnName+" "+dimension.columnDisplayName+" "+dimension.tableDisplayType);
                           if(selectedname==dimension.columnDisplayName){
                               selectedFeildName = dimension.columnName;
                           }
                        });
                    if(selectedFeildName!=undefined){
                        selectedcolumns = [];
                        var i = 0;
                        var columnFound = false;
                        _.each(this.state.viewcollection.getDimensions(), function(dimension) {
                             var dimensionTableName = dimension.columnName.split(".")[0];
                             var selectedTablename = selectedFeildName.split(".")[0];
                             var displayName = dimension.columnDisplayName;
                             if(dimensionTableName==selectedTablename ){
                                 i++;
                                 if(i<=10) {
                                 var tempVal = {};
                                 tempVal["actualnames"] = dimension.columnName;
                                 tempVal["displaynames"] = dimension.columnDisplayName;
                                 tempVal["datatype"] = dimension.tableDisplayType;
                                 selectedcolumns.push(tempVal);
                                 if(selectedname ==  displayName){
                                     columnFound = true;
                                 }
                                 }
                                 else if(!columnFound && selectedname ==  displayName){
                                     var tempVal = {};
                                     tempVal["actualnames"] = dimension.columnName;
                                     tempVal["displaynames"] = dimension.columnDisplayName;
                                     tempVal["datatype"] = dimension.tableDisplayType;
                                     selectedcolumns.push(tempVal);
                                     if(selectedname ==  displayName){
                                         columnFound = true;
                                     }
                                 }
                               }
                            
                        });
                    }
                    

                }
                if(details["textcolumns"].length>0){
                    _.each(details["textcolumns"],function(value,i){
                        if(temp != undefined){
                            var getcustomfield = $.grep(temp,function(e){ 
                                return e.name == details["textcolumns"][i].columnName;
                            });
                        }
                        if(getcustomfield != undefined && getcustomfield.length>0){
                            $.each(getcustomfield, function(index,custom) {
                                for(let i=0;i<custom.usedFields.length;i++){
                                    var object={
                                            "actualnames" : custom.usedFields[i],
                                            "displaynames" : getcustomfield[index].name,
                                            "datatype" :  getcustomfield[index].dataType
                                    };
                                    selectedcolumns.push(object);
                                }
                            });
                            selectedcustomfields.push(getcustomfield[0]);
                        }else{
                            var tempVal = {};
                            if( details["textcolumns"][i].dataType!=undefined &&  details["textcolumns"][i].dataType=="customhierarchy"){
                                var customhierarchy = ChartInfo.functions.getcustomhierarchy();
                                var summaryTableCustomHierarchyDrilldownName = ChartInfo.functions.getSummaryTableCustomHierarchyDrilldownName();
                                if(summaryTableCustomHierarchyDrilldownName!=undefined){
                                    _.each(customhierarchy, function(val1) {
                                        if(val1.name == details["textcolumns"][i].columnName){
                                            if(temp != undefined){
                                                var getcustomfield = $.grep(temp,function(e){
                                                    return e.name == val1.hierarchelist[that.state.levelmapping[value.columnName]].columnName;
                                                });
                                            }
                                            if(getcustomfield != undefined && getcustomfield.length>0){
                                                selectedcustomfields.push(getcustomfield[0]);
                                            }else{
                                                tempVal["actualnames"] = val1.hierarchelist[that.state.levelmapping[value.columnName]].columnName;
                                                tempVal["displaynames"] = val1.hierarchelist[that.state.levelmapping[value.columnName]].columnDisplayName;
                                                tempVal["datatype"] = val1.hierarchelist[that.state.levelmapping[value.columnName]].dataType;
                                                selectedcolumns.push(tempVal);
                                            }
                                        }
                                    });
                                }
                            }else{
                                tempVal["actualnames"] = details["textcolumns"][i].columnName;
                                tempVal["displaynames"] = details["textcolumns"][i].displayName;
                                tempVal["datatype"] = details["textcolumns"][i].dataType;
                                selectedcolumns.push(tempVal);
                            }
                        }
                    });
                }
                if(chartDetails["summarytablevalues"].length>0){
                    if(selectedFeildName != undefined){
                        var selectedtblname=selectedFeildName.split(".")[0];
                    }else{
                        var selectedtblname=selectedname.split(".")[0];
                    }
                    _.each(chartDetails["summarytablevalues"],function(value,i){
                        if(temp != undefined){
                            var getcustomfield = $.grep(temp,function(e){
                                return e.name == chartDetails["summarytablevalues"][i].columnName;
                            });
                        }
                        if(getcustomfield != undefined && getcustomfield.length>0){
                        //	var selectedtblname=selectedname.split(".")[0];
                            
                            var isCustomMeasureField=false;
                            for(let c=0;c<chartDetails["summarytablevalues"].length;c++){
                                if(selectedtblname== chartDetails["summarytablevalues"][c].columnName){
                                    if(chartDetails["summarytablevalues"][c].aggregation=="customaggregatedfield"){
                                        isCustomMeasureField=true;
                                    }
                                }
                            }
                            $.each(getcustomfield, function(index,custom) {
                                for(let i=0;i<custom.usedFields.length;i++){
                                    var object={
                                            "actualnames" : custom.usedFields[i],
                                            "displaynames" : getcustomfield[index].name,
                                            "datatype" :  getcustomfield[index].dataType
                                    };
                                    if(that.state.multifact=="true") {
                                        if(that.props.facttables.indexOf(custom.usedFields[i].split(".")[0])!=-1 && that.props.facttables.indexOf(selectedtblname)!=-1 && selectedtblname==custom.usedFields[i].split(".")[0]){
                                            selectedcolumns.push(object);
                                        }else if(that.props.facttables.indexOf(custom.usedFields[i].split(".")[0])!=-1 && that.props.facttables.indexOf(selectedtblname)==-1 && isCustomMeasureField!=true){
                                            selectedcolumns.push(object);
                                        }
                                    }
                                }
                            });
                            selectedcustomfields.push(getcustomfield[0]);
                        }else{
                            var tempVal = {};
                            if(chartDetails["summarytablevalues"][i].dataType!=undefined &&  chartDetails["summarytablevalues"][i].dataType=="customhierarchy"){
                                var customhierarchy = ChartInfo.functions.getcustomhierarchy();
                                var summaryTableCustomHierarchyDrilldownName = ChartInfo.functions.getSummaryTableCustomHierarchyDrilldownName();
                                if(summaryTableCustomHierarchyDrilldownName!=undefined){
                                    _.each(customhierarchy, function(val1) {
                                        if(val1.name==chartDetails["summarytablevalues"][i].columnName){
                                            if(temp != undefined){
                                                var getcustomfield = $.grep(temp,function(e){
                                                    return e.name == val1.hierarchelist[that.state.levelmapping[value.columnName]].columnName;
                                                });
                                            }
                                            if(getcustomfield != undefined && getcustomfield.length>0){
                                                selectedcustomfields.push(getcustomfield[0]);
                                            }else{
                                                tempVal["actualnames"] = val1.hierarchelist[that.state.levelmapping[value.columnName]].columnName;
                                                tempVal["displaynames"] = val1.hierarchelist[that.state.levelmapping[value.columnName]].columnDisplayName;
                                                tempVal["datatype"] = val1.hierarchelist[that.state.levelmapping[value.columnName]].dataType;
                                                selectedcolumns.push(tempVal);
                                            }
                                        }
                                    });
                                }
                            }else{
                                if(that.state.multifact!="true") {
                                    tempVal["actualnames"] = chartDetails["summarytablevalues"][i].columnName;
                                    tempVal["displaynames"] = chartDetails["summarytablevalues"][i].displayName;
                                    tempVal["datatype"] = chartDetails["summarytablevalues"][i].dataType;
                                    if(chartDetails["summarytablevalues"][i].aggregation != "customaggregatedfield"){
                                        selectedcolumns.push(tempVal);
                                    }
                                }else if(that.state.multifact=="true" && chartDetails["summarytablevalues"][i].columnName.indexOf(".")!=-1 && chartDetails["summarytablevalues"][i].aggregation != "customaggregatedfield" ){
                                    var tblname = chartDetails["summarytablevalues"][i].columnName.split(".")[0]
                                    //var selectedtblname = selectedname.split(".")[0];
                                    
                                    var isCustomMeasureField=false;
                                    for(let c=0;c<chartDetails["summarytablevalues"].length;c++){
                                        if(selectedtblname== chartDetails["summarytablevalues"][c].columnName){
                                            if(chartDetails["summarytablevalues"][c].aggregation=="customaggregatedfield"){
                                                isCustomMeasureField=true;
                                            }
                                        }
                                    }
                                    if(that.props.facttables.indexOf(tblname)!=-1 && that.props.facttables.indexOf(selectedtblname)!=-1 && selectedtblname==tblname){
                                        tempVal["actualnames"] = chartDetails["summarytablevalues"][i].columnName;
                                        tempVal["displaynames"] = chartDetails["summarytablevalues"][i].displayName;
                                        tempVal["datatype"] = chartDetails["summarytablevalues"][i].dataType;
                                        if(chartDetails["summarytablevalues"][i].aggregation != "customaggregatedfield"){
                                            selectedcolumns.push(tempVal);
                                        }	
                                    }else if(that.props.facttables.indexOf(tblname)!=-1 && that.props.facttables.indexOf(selectedtblname)==-1 && isCustomMeasureField !=true){
                                        tempVal["actualnames"] = chartDetails["summarytablevalues"][i].columnName;
                                        tempVal["displaynames"] = chartDetails["summarytablevalues"][i].displayName;
                                        tempVal["datatype"] = chartDetails["summarytablevalues"][i].dataType;
                                        if(chartDetails["summarytablevalues"][i].aggregation != "customaggregatedfield"){
                                            selectedcolumns.push(tempVal);
                                        }
                                    }
                                }else if(that.state.multifact=="true" && chartDetails["summarytablevalues"][i].aggregation=="customaggregatedfield"){

                                    var tempCMeasures=ChartInfo.functions.getCustomMeasureField();
                                    var selectednameIsFact=false;
                                    for(let  k=0;k<tempCMeasures.length;k++){
                                        if(tempCMeasures[k].name==selectedname){
                                            //selectedtblname=tempCMeasures[k].usedFields.split(".")[0];
                                            var CMUsedFieldsArray = tempCMeasures[k].usedFields.split(",");
                                            for(var uf=0;uf<CMUsedFieldsArray.length;uf++){
                                                //var isCustomField=false;
                                                let CMeasureUserField=CMUsedFieldsArray[uf];
                                                if(that.props.facttables.indexOf(CMeasureUserField.split(".")[0])!=-1){
                                                    selectednameIsFact=true;
                                                }
                                                if(tempCF!=undefined && tempCF.length>0){
                                                    for(let l=0;l<tempCF.length;l++){
                                                        if(tempCF[l].name==CMeasureUserField){
                                                            //isCustomField=true;
                                                            for(let m=0;m<tempCF[l].usedFields.length;m++){
                                                                if(that.props.facttables.indexOf(tempCF[l].usedFields[m].split(".")[0])!=-1){
                                                                    selectednameIsFact=true;
                                                                }

                                                            }
                                                        }
                                                    }											
                                                }
                                            }
                                        }
                                    }

                                    for(var j=0;j<tempCMeasures.length;j++){
                                        if(chartDetails["summarytablevalues"][i].columnName==tempCMeasures[j].name && chartDetails["summarytablevalues"][i].columnName == selectedname){
                                            var CMeasureUserField=tempCMeasures[j].usedFields;
                                            var CMeasureUserFieldsArray=CMeasureUserField.split(",");
                                            for(let n=0;n<CMeasureUserFieldsArray.length;n++){
                                                var cf = false;
                                                if(tempCF!=undefined && tempCF.length>0){
                                                    for(let l=0;l<tempCF.length;l++){
                                                        if(tempCF[l].name==CMeasureUserFieldsArray[n]){
                                                            cf = true;
                                                            for(let m=0;m<tempCF[l].usedFields.length;m++){
                                                                tempVal["actualnames"] = tempCF[l].usedFields[m];
                                                                tempVal["displaynames"] = chartDetails["summarytablevalues"][i].displayName;
                                                                tempVal["datatype"] = chartDetails["summarytablevalues"][i].dataType;
                                                                selectedcolumns.push(tempVal);
                                                            }
                                                        }
                                                    }											
                                                }
                                                if(cf == false){
                                                    tempVal["actualnames"] = CMeasureUserFieldsArray[n];
                                                    tempVal["displaynames"] = chartDetails["summarytablevalues"][i].displayName;
                                                    tempVal["datatype"] = chartDetails["summarytablevalues"][i].dataType;
                                                    selectedcolumns.push(tempVal);
                                                }
                                            }
                                        }
                                        else if(chartDetails["summarytablevalues"][i].columnName==tempCMeasures[j].name){
                                            var CMeasureUserField=tempCMeasures[j].usedFields;
                                            var CMeasureUserFieldsArray=CMeasureUserField.split(",");
                                            for(let n=0;n<CMeasureUserFieldsArray.length;n++){
                                                var cf = false;
                                                if(tempCF!=undefined && tempCF.length>0){
                                                    for(let l=0;l<tempCF.length;l++){
                                                        if(tempCF[l].name==CMeasureUserFieldsArray[n]){
                                                            cf = true;
                                                            for(let m=0;m<tempCF[l].usedFields.length;m++){
                                                                if(that.props.facttables.indexOf(tempCF[l].usedFields[m].split(".")[0])!=-1 && that.props.facttables.indexOf(selectedtblname)==-1 
                                                                        && selectednameIsFact!=true){
                                                                            tempVal["actualnames"] = tempCF[l].usedFields[m];
                                                                                    tempVal["displaynames"] = chartDetails["summarytablevalues"][i].displayName;
                                                                                    tempVal["datatype"] = chartDetails["summarytablevalues"][i].dataType;
                                                                                    selectedcolumns.push(tempVal);
                                                                        }
                                                            }
                                                        }
                                                    }											
                                                }
                                                if(cf = false){
                                                    if(that.props.facttables.indexOf(CMeasureUserFieldsArray[n].split(".")[0])!=-1 && that.props.facttables.indexOf(selectedtblname)==-1 && selectednameIsFact!=true){
                                                        let tempVal={};
                                                        tempVal["actualnames"] = CMeasureUserFieldsArray[n];
                                                        tempVal["displaynames"] = chartDetails["summarytablevalues"][i].displayName;
                                                        tempVal["datatype"] = chartDetails["summarytablevalues"][i].dataType;
                                                        selectedcolumns.push(tempVal);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                
                                }
                            }
                        }
                    });
                }
                if(chartDetails["summarytablegroupbys"]!=undefined && chartDetails["summarytablegroupbys"]!=null){
                    if(temp != undefined){
                        var getcustomfield = $.grep(temp,function(e){
                            return e.name == chartDetails["summarytablegroupbys"].columnName;
                        });
                    }
                    if(getcustomfield != undefined && getcustomfield.length>0){
                        $.each(getcustomfield, function(index,custom) {
                            for(let i=0;i<custom.usedFields.length;i++){
                                var object={
                                        "actualnames" : custom.usedFields[i],
                                        "displaynames" : getcustomfield[index].name,
                                        "datatype" :  getcustomfield[index].dataType
                                };
                                selectedcolumns.push(object);
                            }
                        });
                        selectedcustomfields.push(getcustomfield[0]);
                    }else{
                        var tempVal = {};
                        if( chartDetails["summarytablegroupbys"].dataType!=undefined && chartDetails["summarytablegroupbys"].dataType!="customhierarchy"){
                            if(that.state.multifact=="true" && chartDetails["summarytablegroupbys"].columnName.indexOf(".")!=-1 ){
                                var tblname = chartDetails["summarytablegroupbys"].columnName.split(".")[0]
                                var selectedtblname = selectedname.split(".")[0]
                                if(that.props.facttables.indexOf(tblname)!=-1 && that.props.facttables.indexOf(selectedtblname)!=-1 && selectedtblname==tblname){
                                    tempVal["actualnames"] = chartDetails["summarytablegroupbys"].columnName;
                                    tempVal["displaynames"] = chartDetails["summarytablegroupbys"].displayName;
                                    tempVal["datatype"] = chartDetails["summarytablegroupbys"].dataType;
                                    selectedcolumns.push(tempVal);	
                                }else if(that.props.facttables.indexOf(tblname)!=-1 && that.props.facttables.indexOf(selectedtblname)==-1){
                                    tempVal["actualnames"] = chartDetails["summarytablegroupbys"].columnName;
                                    tempVal["displaynames"] = chartDetails["summarytablegroupbys"].displayName;
                                    tempVal["datatype"] = chartDetails["summarytablegroupbys"].dataType;
                                    selectedcolumns.push(tempVal);
                                }
                            }
                        }
                    }
                }
            }
            var filtervalues =[];
    /*		var filtervalues = ChartInfo.functions.getTempSummaryTableFilterValues(chartnumber);
            var filterArray = [];
            var temp2 =[];
            _.each(this.state.dobyfilterArray,function(value,index){
                var temp = $.grep(filtervalues,function(e){ return e.columnname == value.columnname});
                if(temp.length==0){
                    temp2.push(value);
                }
            });
            filterArray= filtervalues.concat(temp2);*/
            //var filterArray = JSON.parse(JSON.stringify( ChartInfo.functions.getTempSummaryTableFilterValues(chartnumber) || [])) ;
            var summarytableDrilldownValues = ChartInfo.functions.getTempSummaryTableFilterValues(chartnumber) ||[];
            var filterArray = summarytableDrilldownValues.concat(JSON.parse(JSON.stringify(this.filterArrayvalues||[])));
            if(details["textcolumns"].length>0){
                _.each(details["textcolumns"],function(value,i){
                    var filtervalues = {};
                    if(details["textcolumns"][i].dataType!=undefined &&  details["textcolumns"][i].dataType=="customhierarchy"){
                        var customhierarchy=ChartInfo.functions.getcustomhierarchy();
                        var summaryTableCustomHierarchyDrilldownName = ChartInfo.functions.getSummaryTableCustomHierarchyDrilldownName();
                        if(summaryTableCustomHierarchyDrilldownName!=undefined){
                            _.each(customhierarchy, function(val1,l) {
                                if(val1.name == value.columnName){
                                    filtervalues["columnname"] = val1.hierarchelist[that.state.levelmapping[value.columnName]].columnName;
                                    filtervalues["columndisplayname"] = val1.hierarchelist[that.state.levelmapping[value.columnName]].columnDisplayName;
                                    filtervalues["dataType"] = val1.hierarchelist[that.state.levelmapping[value.columnName]].dataType;
                                    filtervalues["tablename"]="" ;
                                    if($($this).closest("tr.groupTR").length == 0){
                                        filtervalues["data"]= $($this).closest("tr").find("td")[i].textContent.trim();
                                        filtervalues["datevalue"] = $($this).closest("tr").find("td")[i].textContent.trim();
                                        if(!that.findSameFilterisExists(filterArray, filtervalues)){
                                            filterArray.push(filtervalues);
                                        }
                                    }
                                }
                            });
                        }
                    }
                    else{
                        filtervalues["dataType"] =details["textcolumns"][i].dataType;	
                        filtervalues["tablename"]="" ;
                        filtervalues["columndisplayname"] = details["textcolumns"][i].displayName;
                        filtervalues["columnname"] =  details["textcolumns"][i].columnName;
                        if($($this).closest("tr.groupTR").length == 0){
                            filtervalues["data"]= $($this).closest("tr").find("td")[i].textContent.trim();
                            filtervalues["datevalue"] = $($this).closest("tr").find("td")[i].textContent.trim();
                            if(!that.findSameFilterisExists(filterArray, filtervalues)){
                                filterArray.push(filtervalues);
                            }
                        }
                    }
                
                    
                });
            }
            if(chartDetails["summarytablegroupbys"]!=undefined && chartDetails["summarytablegroupbys"]!=null){
                var groupNo = $($this).parent().attr('group-no');
                var groupName =$('.groupTR[group-no='+groupNo+'][data-num='+chartnumber+']').attr('group-name');
                var Object =	$.grep(this.state.viewcollection.getDimensions(), function(e){ return e.columnDisplayName == groupName.split(':')[0] });
                var customFieldObj =	$.grep(temp || [], function(e){ return e.name == groupName.split(':')[0] }) || [];
                var filterObject = {};
                if(Object != undefined && Object.length>0 && Object[0]!=undefined){
                filterObject["dataType"] = Object[0].tableDisplayType;
                filterObject["columndisplayname"] = Object[0].columnDisplayName;
                filterObject["columnname"] = Object[0].columnName;
                filterObject["data"] = groupName.split(": ")[1].trim();
                filterObject["datevalue"] =groupName.split(": ")[1].trim();
                filterArray.push(filterObject);
                }else if(customFieldObj.length > 0){
                    filterObject["dataType"] = customFieldObj[0].dataType;
                    filterObject["columndisplayname"] = customFieldObj[0].name;
                    filterObject["columnname"] = customFieldObj[0].name;
                    filterObject["data"] = groupName.split(": ")[1].trim();
                    filterObject["datevalue"] =groupName.split(": ")[1].trim();
                    filterArray.push(filterObject);
                }
            }
            if (that.state.multifact == "true") {
				var selectedColumnsTblNameArray = [];
				_.each(selectedcolumns, function (i, j) {
					selectedColumnsTblNameArray.push(i.actualnames.split(".")[0]);
				});
				if (_.intersection(that.state.facttables, selectedColumnsTblNameArray).length == 0) {
					_.each(chartDetails["summarytablevalues"], function (val, i) {
						var tempCMeasures = ChartInfo.functions.getCustomMeasureField();
						for (var j = 0; j < tempCMeasures.length; j++) {
							var tempVal = {};
							if (val.columnName == tempCMeasures[j].name) {
								var CMeasureUserField = tempCMeasures[j].usedFields;
								var CMeasureUserFieldsArray = CMeasureUserField.split(",");
								for (let n = 0; n < CMeasureUserFieldsArray.length; n++) {
									var cf = false;
									if (tempCF != undefined && tempCF.length > 0) {
										for (let l = 0; l < tempCF.length; l++) {
											if (tempCF[l].name == CMeasureUserFieldsArray[n]) {
												cf = true;
												for (let m = 0; m < tempCF[l].usedFields.length; m++) {
													tempVal = {};
													if (that.facttables.indexOf(tempCF[l].usedFields[m].split(".")[0]) != -1) {
														tempVal["actualnames"] = tempCF[l].usedFields[m];
														tempVal["displaynames"] = chartDetails["summarytablevalues"][i].displayName;
														tempVal["datatype"] = chartDetails["summarytablevalues"][i].dataType;
														selectedcolumns.push(tempVal);
													}
												}
											}
										}
									}
									if (cf == false) {
										if (that.state.facttables.indexOf(CMeasureUserFieldsArray[n].split(".")[0]) != -1) {
											tempVal["actualnames"] = CMeasureUserFieldsArray[n];
											tempVal["displaynames"] = chartDetails["summarytablevalues"][i].displayName;
											tempVal["datatype"] = chartDetails["summarytablevalues"][i].dataType;
											selectedcolumns.push(tempVal);
										}
									}
								}
							}
						}

					});
				}
			}
            if(this.state.dobyfilterArray==undefined ||this.state.dobyfilterArray.length==0){
             gridmodel.set("filterObject",JSON.stringify(filterArray));
            }
            else{
                this.state.dobyfilterArray.push(filtervalues);
                gridmodel.set("filterObject",JSON.stringify(this.state.dobyfilterArray.concate(filterArray)));
            }
            gridmodel.set("datamodelId",this.state.datamodelId);
            gridmodel.set("indexName", this.state.indexName);
            gridmodel.set("customFields",JSON.stringify(selectedcustomfields));
            gridmodel.set("reportId",this.props.reportId);
            gridmodel.set("script",JSON.stringify(selectedcustomfields));
            gridmodel.set("selectedcolumns",JSON.stringify(selectedcolumns));
            gridmodel.set("entities",this.state.entities);
            gridmodel.set("rowLimit",rowLimit);
            gridmodel.set("script",JSON.stringify(selectedcustomfields));
            gridmodel.set("isImportedReport",this.state.isImportedReport);
            gridmodel.set("numberformat",chartDetails.chartSettings.numberformat);
            var that = this;
            
    try{	
        var tableInfo= {
                chartType:chartDetails["chartType"]
            };
            var bootObj = {};
				bootObj["currentChartNumber"] = chartnumber;
				bootObj["gridmodel"] = gridmodel;
				bootObj["tableInfo"] = tableInfo;
				bootObj["pos"] = null;
				bootObj["popupwindow"] = popupwindow;
				bootObj["reporttype"] = undefined;
				bootObj["target"] = undefined;
				bootObj["isFromPagenation"] = undefined;
				var bootStrapTable = new BootStrapTable(bootObj);
				bootStrapTable.render();
        $('.summarytable_datasetcount').text($('.datacount[data-num='+chartnumber+']  .publishinfo').text());
        $('.summarytable_filtervalues').text(drilldownvalue).attr("title",drilldownvalue);;	
        $('.spinner').hide();
        $('#spinnerchart'+chartnumber).hide();
        }catch (e) {
            $(".gridspinner").hide();
    }
        }
    }
    symmarytabledrilldownEve(msg, data) {
		if (data != undefined) {
			this.symmarytabledrilldown(data.currentTarget, data.filtervalues, data.drilldownon, data.currentchartno, data.index, data.isDateHistogram, data.dateHistomgramColName);
		} else {
			this.symmarytabledrilldown();
		}
    }
    symmarytabledrilldown(currentTarget,filtervalues, drilldownon,currentchartno, index, isDateHistogram,dateHistomgramColName){
        var $this = currentTarget;
        isDateHistogram = isDateHistogram || false;
        var that=this;
        if(currentchartno==this.props.chartnumber){
            var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(this.props.chartnumber));
            if(chartDetails['summarytablegroupbys']==undefined && currentchartno==undefined){
                currentchartno=$($this).closest('table').attr('data-num');
            }else if(currentchartno==undefined){
                currentchartno=$($this).parent().attr('data-num');
            }
            var jsonarray=[];
            var temp;
            var drilldownName,drilldowndisplayName;
            var drilldownDataType;
            var sessionValue=JSON.parse(sessionStorage.getItem('customhierarchy'+this.props.chartnumber));
            var filterArray =[];
            var summarytablefiltervalues = [];
            var temp1=[];
            var groupvalue="";
            var textcolumnvalue="";
            var drilldownongroup =false;
            drilldowndisplayName = ChartInfo.functions.getSummarytabledrilldownname();
            if(drilldownon==undefined && isDateHistogram!=undefined && isDateHistogram != "true" && dateHistomgramColName==undefined){
                var index=$($this).index();
                var xAxisColumnName=chartDetails['summarytablecolumns'][index].columnName;
                temp=$.grep(ChartInfo.functions.getcustomhierarchy(), function(e){ return e.name == xAxisColumnName });
                if(temp!=undefined && temp.length>0 && !$($this).hasClass("groupTitle")){
                    drilldownName=temp[0].hierarchelist[that.state.levelmapping[xAxisColumnName]+1].columnName;
                    drilldownDataType=temp[0].hierarchelist[that.state.levelmapping[xAxisColumnName]+1].dataType
                    drilldowndisplayName= temp[0].hierarchelist[that.state.levelmapping[xAxisColumnName]+1].columnName;
                    that.state.levelmapping[xAxisColumnName]=that.state.levelmapping[xAxisColumnName]+1;
                }else if(chartDetails["summarytablegroupbys"] != undefined){
                    var legendColumnName= chartDetails["summarytablegroupbys"].columnName;
                    temp=$.grep(ChartInfo.functions.getcustomhierarchy(), function(e){ return e.name == legendColumnName });
                    if(temp!=undefined && temp.length >0){
                         drilldownName=temp[0].hierarchelist[that.state.levelmapping[legendColumnName]+1].columnName;
                         drilldownDataType=temp[0].hierarchelist[that.state.levelmapping[legendColumnName]+1].dataType
                         drilldowndisplayName= temp[0].hierarchelist[that.state.levelmapping[legendColumnName]+1].columnName;
                        that.state.levelmapping[legendColumnName]=that.state.levelmapping[legendColumnName]+1;
                    }
                }
            }
            var tempfilters = filtervalues || [];
            if(drilldownon !=undefined){
                var selectedhtmlcolumn = $('.summarytable-filteritems .drilldowncheckbox:checked');
                var columnName = $(selectedhtmlcolumn).attr("data-child");
                var dataType = $(selectedhtmlcolumn).attr("data-type");
                var displayName = $(selectedhtmlcolumn).attr("data-name");
                var tableName = $(selectedhtmlcolumn).attr("data-parent");
                var summarytabledetails = ChartInfo.functions.getSummarytableAxisFieldsArray(currentchartno);
                var details = summarytabledetails[summarytabledetails.length-1];
                var temparray = {};
                if(details["group"]!=undefined){
                    var groupobj = JSON.parse(JSON.stringify(details["group"]));
                }
                var textcolumnobj = JSON.parse(JSON.stringify(details["textcolumns"]));
                if(drilldownon == "group"){
                    groupobj["columnName"] = columnName;
                    groupobj["displayName"] =displayName;
                    groupobj["dataType"] = dataType;
                    groupobj["tableName"] = tableName;
                    drilldownDataType = dataType;
                    drilldownName = columnName;
                    drilldowndisplayName= displayName;
                }else{
                    textcolumnobj[index]["columnName"] = columnName;
                    textcolumnobj[index]["displayName"] =displayName;
                    textcolumnobj[index]["dataType"] = dataType;
                    textcolumnobj[index]["tableName"] = tableName;
                    drilldownDataType = dataType;
                    drilldownName = columnName;
                    drilldowndisplayName= displayName;
                }
                
                temparray["group"] = groupobj;
                temparray["textcolumns"] = textcolumnobj;
                temparray["filterid"] = filtervalues[filtervalues.length-1]["filterid"]; 
                summarytabledetails.push(temparray);
                ChartInfo.functions.setSummarytableAxisFieldsArray(summarytabledetails, currentchartno);
            }
            var temp2 =[];
            _.each(filterArray,function(value,index){
                var temp = $.grep(tempfilters,function(e){ return e.columnname == value.columnname});
                if(temp.length==0){
                    temp2.push(value);
                }
            });
            filterArray=tempfilters.concat(temp2);
            var jsonArr=[];
            jsonArr.push(that.state.levelmapping);
            jsonArr.push(filterArray);
            sessionValue.push(jsonArr);
            sessionStorage.setItem("customhierarchy"+this.props.chartnumber,JSON.stringify(sessionValue));
            $("#chart"+this.props.chartnumber).empty();
            var summarytableDrilldownValues = ChartInfo.functions.getTempSummaryTableFilterValues(this.props.chartnumber) || [];
            summarytableDrilldownValues = summarytableDrilldownValues.concat(JSON.parse(JSON.stringify(this.state.filterArrayvalues||[])));
			if(that.state.isImportedReport){
                that.props.reportId = atob(window.location.pathname.split("/")[4])
            }		
            ReactDOM.render(
				<SummaryTableView
                    chartNumber={that.props.chartnumber}
                    measures={that.state.viewcollection.getMeasures()}
                    dimensions={that.state.viewcollection.getDimensions()}
                    reportId={that.props.reportId}
                    datamodelId={that.props.datamodelId}
                    filterArray={JSON.stringify(summarytableDrilldownValues)}
                    pos={that.state.pos}
                    features={that.state.features}
                    chartDetails={chartDetails}
                    indexName ={that.props.indexname}
                    drilldownName={drilldownName}
                    drilldowndisplayName={drilldowndisplayName}
                    levelMapping={that.state.levelmapping}
                    appendbreadcrumb={true}
                    entities={that.state.entities}
                    textcolumnvalue={textcolumnvalue}
                    groupvalue={groupvalue}
                    drilldownongroup={drilldownongroup}
                    drilldownDataType={drilldownDataType}
                    isPurgedReport={that.state.isPurgedReport}
                    isPublished={that.state.isPublished}
                    isImportedReport={that.state.isImportedReport}
                    isDateHistogram={isDateHistogram}
                    dateHistomgramColName={dateHistomgramColName}
                    multifact={that.state.multifact}
                    facttables={that.state.facttables}
                    multifactentities={that.state.multifactentities}
                    />,
                    document.getElementById(`chart${this.state.chartnumber}`)
                );
        }
    }
    symmarytabledrilldown_breadcrumbEve(msg, data) {
		this.symmarytabledrilldown_breadcrumb(data);
    }
    symmarytabledrilldown_breadcrumb(currentTarget){
        var $this = currentTarget;
        var that=this;
        var chartno = $($this).parent().attr("data-num");
        var appendbreadcrumb =true;
        if(chartno==this.props.chartnumber){
        var chartDetails = JSON.parse(ChartInfo.functions.getChartDetails(chartno));
        var drilldownlevel=$($this).parent().index();
        var sessionValue=JSON.parse(sessionStorage.getItem('customhierarchy'+chartno));
        var customhierarchy_level_data=sessionValue[drilldownlevel];
        var drilldownName= $($this).parent().attr("level-columnname");
        var drilldowndisplayName= $($this).parent().attr("level-displayName");
        if(customhierarchy_level_data!=undefined){
            this.state.levelmapping=customhierarchy_level_data[0];
            var level_filterobject=customhierarchy_level_data[1];
        }
        $("#chart"+chartno).empty();
        _.each(sessionValue,function(list,i){
            if(drilldownlevel>=i){
                $($this).parent().next().remove();
            }else{
                sessionValue.pop(i);
                $($this).parent().next().remove();
            }
        });
        if(drilldownlevel==0){
            $($this).parent().remove();
            appendbreadcrumb = false;
        }
        var summarytablefiltervalues = ChartInfo.functions.getTempSummaryTableFilterValues(chartno);
        var levelid = $($this).parent().attr("level");
        var filterArray = $.grep(summarytablefiltervalues,function(e){return e.filterid < levelid});
        ChartInfo.functions.setTempSummaryTableFilterValues(filterArray,chartno);
        
        var summarytabledetails = ChartInfo.functions.getSummarytableAxisFieldsArray(chartno);
        ChartInfo.functions.setSummarytableAxisFieldsArray($.grep(summarytabledetails,function(e){return e.filterid <levelid || e.filterid==undefined}), chartno);
        ChartInfo.functions.setSummaryTableDateHistogramStatus(chartno,"false");
        sessionStorage.setItem('customhierarchy'+chartno,JSON.stringify(sessionValue));
        var summarytableDrilldownValues = ChartInfo.functions.getTempSummaryTableFilterValues(chartno);
        summarytableDrilldownValues = summarytableDrilldownValues .concat(JSON.parse(JSON.stringify(this.state.filterArrayvalues||[])));
        ReactDOM.render(
            <SummaryTableView
                chartNumber={that.props.chartnumber}
                measures={that.state.viewcollection.getMeasures()}
                dimensions={that.state.viewcollection.getDimensions()}
                broker={that.state.broker}
                reportId={that.props.reportId}
                datamodelId={that.props.datamodelId}
                filterArray={JSON.stringify(summarytableDrilldownValues)}
                pos={that.state.pos}
                features={that.state.features}
                chartDetails={ chartDetails}
                indexName ={that.props.indexname}
                drilldownName={drilldownName}
                drilldowndisplayName={drilldowndisplayName}
                levelMapping={that.state.levelmapping}
                appendbreadcrumb={appendbreadcrumb}
                entities={that.state.entities}
                drilldownongroup={true}
                isPurgedReport={that.state.isPurgedReport}
                isPublished={that.state.isPublished}
                isImportedReport={that.state.isImportedReport}
                multifact={that.state.multifact}
                facttables={that.state.facttables}
                multifactentities={that.state.multifactentities}
                />,
                document.getElementById(`chart${this.state.chartnumber}`)
            );
        }
    }
    addSavedDrilldownBreadCrumbs(currentChartNumber){
      //calling procedure different for previous technology broker and pubsub
      if (currentChartNumber != undefined && currentChartNumber == this.state.chartnumber) {
        $(".breadcrumb[data-num=" + currentChartNumber + "]").remove();
        $("#chart" + currentChartNumber + "").before("<ul class='breadcrumb' data-num=" + currentChartNumber + "></ul>");
        var data = JSON.parse(ChartInfo.functions.getChartDetails(currentChartNumber));
            if (data["drillDownFilter"] != undefined) {
                var breadCrumbElement = $('.breadcrumb[data-num=' + currentChartNumber + ']')
                _.each(data["drillDownFilter"], function (value, index) {
                    var appendValue = value.data;
                    var ulEle = "";
                    var breadCrumpElements = ChartInfo.functions.getBreadCrumpSpanElement(value.data);
                    if (breadCrumpElements != undefined && Object.keys(breadCrumpElements).length > 0) {
                        appendValue = breadCrumpElements["span"];
                        ulEle = breadCrumpElements["ulelement"];
                    }
                    if (value["saved"] == "true") {
                        breadCrumbElement.append("<li class=drilldown saved>" + value.columndisplayname + " [" + appendValue + "] <a columnname='" + value.columnname + "'  name='" + value.data + "' class='close removedrilldownsinglereport' title='Remove'><i class='fa fa-times'<i/></a>" + ulEle + "</li>");
                    }
                });
            }
        }
    }
    unbindEvents(){
        PubSub.unsubscribe("trendline", this.addtrendlineEv);
    }
    getMutiyComapreDateObj(finalCompareDateObj,tempcompareDateObj,tempYaxis){
        let yjsObj = tempcompareDateObj["0"]; 
        let matchedYDisplay = "",matchedIndex=0;
        _.each(tempYaxis,function(val,index){
            if(val.columnName!=undefined&&val.columnName!=null&&yjsObj.hasOwnProperty(val.columnName)){
                finalCompareDateObj[index+""]=yjsObj;
                matchedYDisplay = val.columnName;
                matchedIndex = index;
            }
        });
        let clonedObj = yjsObj[matchedYDisplay]
         if (yjsObj != null && yjsObj.hasOwnProperty(matchedYDisplay)&&tempYaxis.length>1) {
             _.each(tempYaxis,function(val,index){
                 if(index!=matchedIndex){
                     let tempObj = {}
                     tempObj[val.columnName] = _.clone(clonedObj)
                    finalCompareDateObj[index+""] = tempObj;
                 }
                })
            }
            return finalCompareDateObj;
    }
}
