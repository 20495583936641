const workercode = () => {
onmessage = function (e) {
    var data=getData(e.data);
}; 
function sendrequest(data) {
    var xhr;
    var serverReqForDataCount;
    try {
        console.log("send Resquest");
          xhr = new XMLHttpRequest();
          serverReqForDataCount = new XMLHttpRequest();
          var timeZone = "";
          if(data.timeZone!= undefined){
              timeZone = data.timeZone;
          }
          if(data.metricdatefield == undefined){
              data.metricdatefield="";
          }
          if(data.metrictype == undefined){
              data.metrictype = "";
          }
           if(data.chartType === "metric" || data.chartType === "solidgauge" || data.chartType === "fullcirclegauge" ){
                  xhr.open('POST', data.urlString+"/getMetricData?api_key="+data.api_key+"&aggregation="+data.aggregation+"&yaxis="+ encodeURIComponent(data.yaxis)+"&script="+encodeURIComponent(data.script)
                               + "&datamodelId="+ data.datamodelId+"&reportId="+data.reportId+"&filterObject="+encodeURIComponent(data.filterObject)+ "&chartType="+ data.chartType+ 
                               "&percentileValue="+ data.percentileValue + "&entities="+ data.entities + "&metricdatefield="+data.metricdatefield+ "&metrictype="+data.metrictype +
                               "&customSolidGaugeField="+ encodeURIComponent(data.customSolidGaugeField)+"&calculation=''"+"&yAxisDataType="+data.yAxisDataType+ "&isExcludeNulls="+ data.isExcludeNulls+ "&customaggregatedscriptfield="+ encodeURIComponent(data.customaggregatedscriptfield)
                               ,false);
           }else{
                  xhr.open('POST', data.urlString+"/getPreviewData?api_key="+data.api_key+"&aggregation="+data.aggregation+"&xaxis="+ encodeURIComponent(data.xaxis)+ "&xaxisdataType="+ data.xaxisdataType+"&yaxis="+ encodeURIComponent(data.yaxis)+"&yAxisDisplayName="+ encodeURIComponent(data.yAxisDisplayName) +"&script="+encodeURIComponent(data.script)+
                               "&legend="+ data.legend+ "&legendDataType="+ data.legendDataType+ "&datamodelId="+ data.datamodelId+ "&indexName="+ data.indexName +"&reportId="+data.reportId+
                               "&filterObject="+encodeURIComponent(data.filterObject)+ "&sortingColumn="+ data.sortingColumn+ "&sortType="+ data.sortType+ "&sortingColumnAggregation="+ data.sortingColumnAggregation +
                               "&rankingColumn="+encodeURIComponent(data.rankingColumn)+"&orderType="+ data.orderType+ "&colLimit="+ data.colLimit+ "&chartType="+ data.chartType+ "&percentileValue="+ data.percentileValue +
                               "&toolTips="+encodeURIComponent(data.toolTips)+ "&zaxis="+ encodeURIComponent(data.zaxis) + "&isExcludeorIncludeFilters="+ data.isExcludeorIncludeFilters+ "&calculation="+ data.calculation+ "&entities="+ data.entities +
                               "&yAxisDataType="+data.yAxisDataType+"&rangefieldMap="+ encodeURIComponent(data.rangefieldMap) + "&xaxisAggregation="+ data.xaxisAggregation+ "&customaggregatedscriptfield="+ encodeURIComponent(data.customaggregatedscriptfield)+
                               "&combinationchartsData="+ encodeURIComponent(data.combinationchartsData) +"&custommeasurehierarchy="+encodeURIComponent(data.custommeasurehierarchy||[])+ "&isExcludeNulls="+ data.isExcludeNulls+
                               "&isChartSummaryData= &percentileValue="+ data.percentileValue +"&timeZone="+timeZone +"&rankingColumnAggregation="+data.rankingColumnAggregation+"&compareDateRangeJson="+encodeURIComponent(data.compareDateRangeJson), false);
                  
           }
           serverReqForDataCount.open('GET',  data.urlString+"/gettotaldatacount?api_key="+data.api_key+"&entities="+ data.entities+"&reportId="+data.reportId+"&datamodelId="+data.datamodelId+"&timeZone="+timeZone);
        xhr.onreadystatechange = function () {
            
            if (xhr.readyState == 4 && xhr.status == 200) {
                 if(data.chartType != "metric" && data.chartType != "solidgauge" && data.chartType != "fullcirclegauge" ){
                try {
                    var parsedData = xhr.responseText;
                    if(parsedData!=undefined){
                        xhr.responseText = parsedData;
                    }
                } catch (e) {
                }
                 }
                var result = JSON.parse(decodeURIComponent(xhr.responseText));
                var responseObj={};
                responseObj["response"] = result;
                responseObj["chartnumber"] = data["chartnumber"];
                responseObj["requestData"] = data;
                responseObj["isChart"] = true;
                postMessage(responseObj);
            }
        }; 
        serverReqForDataCount.onreadystatechange = function () {
            if (serverReqForDataCount.readyState == 4 && serverReqForDataCount.status == 200) {
                var result = JSON.parse(serverReqForDataCount.responseText);
                var responseObj={};
                responseObj["response"] = result;
                responseObj["size"] = result.size;
                responseObj["lastexecutedtimestamp"] = result.lastexecutedtimestamp;
                responseObj["isDataCount"] = true;
                postMessage(responseObj);
            }
        }; 
        xhr.send();
        serverReqForDataCount.send();
    } catch (e) {
        postMessage('Error occured in XMLHttpRequest: ' + xhr.statusText + '  ReadyState: ' + xhr.readyState + 
                 ' Status:' + xhr.status + ' E: ' +e+' Msg:'+e.message);
    }
}

function getData(data){
    var data=sendrequest(data);
}
};
let code = workercode.toString();
code = code.substring(code.indexOf("{")+1, code.lastIndexOf("}"));

const blob = new Blob([code], {type: "application/javascript"});
const worker_script = URL.createObjectURL(blob);

module.exports = worker_script;