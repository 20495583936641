import React from "react";
import ReactDOM from "react-dom";
import PubSub from 'pubsub-js';
import $ from 'jquery/dist/jquery';
import 'gridstack/dist/gridstack.all';
import _ from "underscore";
import ViewMLReportBodyTemplate from "./viewmlreportbody.jsx";
import * as ChartInfo from "../../js/helpers/utils/chartinfo";
import * as TabInfo from "../../js/helpers/utils/tabinfo";
import { lang } from "../../js/helpers/utils/languages/language";
import * as MLCharts from "./mlcharts";
import { Treant } from "treant-js/Treant";
import TextChart from "../../js/charts/textchart";
import download from 'downloadjs';
import SendRequest from '../../SendRequest';
export default class ViewMLBodyView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            grids: [],
            grid: null,
            gridInfo: [],
            downloadableJSONData: [],
            textstackedchartdata: [],
            catagories: [],
            textbarchartdata: [],
            selectedcategories: []
        }
        this.options = props;
        this.features = this.options.features;
        this.reportId = this.options.reportId;
        this.datamodelId = this.options.datamodelId;
        this.broker = this.options.broker;
        var modelData = this.options.modelData;
        this.dimensions = this.options.dimensions;
        this.selectedModel = this.options.selectedModel;
        this.options.tabid = TabInfo.functions.getCurrentTabId();
        this.tabid = this.options.tabid == undefined ? 0 : this.options.tabid;
        PubSub.subscribe('adjustMLChartHeight', this.adjustMLChartHeight.bind(this));
        if (this.selectedModel == "generalizedlinearmodeling") {
            $(".mloutcomehead[tabid='" + this.tabid + "']>span").text(lang["mlreports.linearregression"]);
        } else if (this.selectedModel == "clustering") {
            $(".mloutcomehead[tabid='" + this.tabid + "']>span").text(lang["mlreports.clustering"]);
        } else if (this.selectedModel == "decisiontree") {
            $(".mloutcomehead[tabid='" + this.tabid + "']>span").text(lang["mlreports.descisiontree"]);
        } else if (this.selectedModel == "textanalysis") {
            $(".mloutcomehead[tabid='" + this.tabid + "']>span").text(lang["mlreports.textanalysis"]);
        } else if (this.selectedModel == "logisticregression") {
            $(".mloutcomehead[tabid='" + this.tabid + "']>span").text(lang["mlreports.LogisticRegression"]);
        } else if (this.selectedModel == "forecasting") {
            $(".mloutcomehead[tabid='" + this.tabid + "']>span").text(lang["mlreports.Forecasting"]);
        } else if (this.selectedModel === "automl") {
            let title = this.props.automlmodeltype == "numeric" ? " (Regression Dashboard) " : " (Classification Dashboard) ";
            title = this.props.automlSelectedModel + title
            $(".mloutcomehead[tabid='" + this.tabid + "']>span").text(title);
        }
        this.cancelrichtext = this.cancelrichtext.bind(this);
        this.editrichtext = this.editrichtext.bind(this);
        this.applyTextChart = this.applyTextChart.bind(this);
        const options = {
            vertical_margin: 8,
            auto: true,
            float: false,
            draggable: {
                handle: '.dragme',
                scroll: true
            },
            resizable: {
                handles: 'se, sw'
            }
        };
        $(".mlloadingbtn").addClass('hide');
        if (!this.options.isFromStoryBook) {
            $("#mlcharts[tabid='" + this.tabid + "']").remove();
            $(".smartinsightdatacontainer>.col-xs-12.leftpane>section.boc-snapshot-container>.container-fluid").append('<div class="row grid-stack" id="mlcharts" tabid="' + this.tabid + '"></div>');
            $('.smartinsightdatacontainer .grid-stack').empty();
        } else {
            if ($("#storybooktabcontent" + this.tabid + "mlcharts").length > 0) {
                $("#storybooktabcontent" + this.tabid + "mlcharts").remove();
                $("#mlcharts[tabid='" + this.tabid + "']").remove();
                $(".smartinsightdatacontainer[tabid='" + this.tabid + "']>.col-xs-12.leftpane>section.boc-snapshot-container>.container-fluid").append('<div class="row grid-stack" id=storybooktabcontent' + this.tabid + 'mlcharts tabid="' + this.tabid + '"></div>');
            }
            $('.smartinsightdatacontainer .grid-stack[tabid="' + this.tabid + '"]').empty();
            $('#mlsplitcontainer[tabid="' + this.tabid + '"] .actions ul').remove();
        }
        var that = this;
        $('.grid-stack[tabid="' + this.tabid + '"]').on('resizestop', function (event, ui) {
            sessionStorage.setItem("changesdone", 1);
            var chartnumber;
            if (ui.element != undefined) {
                chartnumber = $(ui.element).attr("data-num");
            }
            that.adjustMLChartHeight(chartnumber, ui.element, "min");
        });
        this.data = this.options.data;
        //$(".mlstoryboardtabs.fill-height").removeClass("loadingmlreport");
        //$(".spinner").hide();
        if (this.data != undefined) {
            this.datedropdown = this.data.datefieldarr;
        }
        //var xyPositionObject = this.getXYPositionObject(this.options.chartnumber);

        if (that.reportId != undefined) {
            // for (var j = 0; j <= ChartInfo.functions.getMLGridDetails().length; j++) {
            // if (ChartInfo.functions.getMLGridDetails().length > 0) {
            _.each(ChartInfo.functions.getMLGridDetails(), function (obj, i) {
                var chartnumber = !that.options.isFromStoryBook ? obj.num : parseInt(obj.num.split("_")[1]);
                // if (chartnumber == j) {
                if (
                    (that.selectedModel == "clustering" && chartnumber != 10) ||
                    (that.selectedModel == "generalizedlinearmodeling" && chartnumber != 14) ||
                    (that.selectedModel == "forecasting" && chartnumber != 12) ||
                    (that.selectedModel == "decisiontree" && chartnumber != 4) ||
                    (that.selectedModel == "textanalysis") ||
                    (that.selectedModel == "logisticregression") ||
                    (that.selectedModel == "automl")
                ) {
                    var chartnumber = obj.num;
                    var gridInfoObj = {};
                    gridInfoObj["x"] = obj.x;
                    gridInfoObj["y"] = obj.y;
                    gridInfoObj["gridwidth"] = obj.width;
                    gridInfoObj["gridheight"] = obj.height;
                    gridInfoObj["chartnumber"] = chartnumber;
                    that.state.gridInfo.push(gridInfoObj);
                    that.state.grids.push(<ViewMLReportBodyTemplate chartid={"mlchart" + chartnumber} chartitle={""}
                        chartnumber={chartnumber} chartType={""} title={obj.title} dateDropDown={that.datedropdown}
                        x={obj.x} y={obj.y} gridwidth={obj.width} gridheight={obj.height} tabid={that.tabid}
                        editrichtext={that.editrichtext} cancelrichtext={that.cancelrichtext} applyTextChart={that.applyTextChart} />);
                }
                // }
            });
            // }
            // }
        } else {
            if (this.selectedModel == "generalizedlinearmodeling") {
                this.plotLinearRegressionCharts(this.data.keyinfluencers.data.length);
            } else if (this.selectedModel == "clustering") {
                this.plotClusteringModelCharts();
            } else if (this.selectedModel == "decisiontree") {
                this.plotDecisionTreeCharts();
            } else if (this.selectedModel == "textanalysis") {
                this.plotCoOccuranceModelCharts();
            } else if (this.selectedModel == "logisticregression") {
                this.plotLogisticRegressionCharts();
            } else if (this.selectedModel == "forecasting") {
                this.plotForecastingModelCharts();
            } else if (this.selectedModel == "automl") {
                this.plotAutoMLModelCharts(this.props.automlmodeltype);
            }
        }
        this.state.grids = this.state.grids.sort(function (a, b) {
            var x1 = a.props.chartnumber;
            var x2 = b.props.chartnumber;
            return x2 - x1;
        });
        this.state.gridInfo = this.state.gridInfo.sort(function (a, b) {
            var x1 = a.chartnumber;
            var x2 = b.chartnumber;
            return x2 - x1;
        })
        var targetElement = "mlcharts";
        if (!this.options.isFromStoryBook) {
            targetElement = "mlcharts"
        } else {
            targetElement = "storybooktabcontent" + this.tabid + "mlcharts";
        }
        ReactDOM.render(this.state.grids, document.getElementById(targetElement))
        this.state.grid = $('.grid-stack').gridstack(options).data('gridstack');
        var that = this;
        _.each(this.state.gridInfo, function (val) {
            that.state.grid.addWidget(document.getElementById("tileview" + val.chartnumber), val.x, val.y, val.gridwidth, val.gridheight, true);
        })
        this.render();
        var that = this;
        $(".datelistformlchart").click(function (e) {
            that.changeDateDropdown(e);
        })
        $(".histogramcontainer").click(function (e) {
            that.histogramcontainer(e);
        })
        $(".drawhistogram").click(function (e) {
            that.drawHistogram(e);
        })
    }
    render() {
        var gridStackData = _.map($('.grid-stack .grid-stack-item.tileview'), function (el) {
            try {
                el = $(el);
                var node = el.data('_gridstack_node');
                return {
                    id: el.attr('data-custom-id'),
                    num: el.attr('data-num'),
                    x: node.x,
                    y: node.y,
                    width: node.width,
                    height: node.height
                };
            } catch (e) {

            }
        });
        ChartInfo.functions.setMLGridDetails(gridStackData);
        var allChartDetails = ChartInfo.functions.getAllMLChartDetails();
        var that = this;
        var isFromStrybok = true;
        if (!this.options.isFromStoryBook) {
            isFromStrybok = false;
        }
        if (this.selectedModel == "generalizedlinearmodeling") {
            for (var i = 0; i <= 4; i++) {
                var incrementer = i;
                i = isFromStrybok ? that.options.tabid + "_" + i : i;
                if (ChartInfo.functions.getMLChartDetails(i) == undefined || ChartInfo.functions.getMLChartDetails(i) == null) {
                    break;
                }
                var mlChartDetails = JSON.parse(ChartInfo.functions.getMLChartDetails(i));
                var chartType = mlChartDetails.chartType;
                var chartnum = i;
                var chartPosition = isFromStrybok ? parseInt(chartnum.split("_")[1]) : i;
                if (chartPosition === 11) {
                    var titleData = lang["mlreports.predictvalues"];
                    $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                    $('.mlcharttitle[data-num="' + chartnum + '"]').text(titleData).attr("title", titleData);
                }
                if (chartPosition === 0) {
                    var data = this.data.mapeValue;
                    MLCharts.charts.drawSmartSolidgaugeChart(data, "mlchart" + chartnum);
                    $("#spinnerchart" + chartnum).hide();
                    var titleData = lang["mlreports.quality"];
                    $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                    $('.mlcharttitle[data-num="' + chartnum + '"]').text(titleData).attr("title", titleData);
                    that.adjustMLChartHeight(chartnum);
                } else if (chartPosition === 1) {
                    var data = this.data.ActualPredicted;
                    var seriesArray = [];
                    var seriesObj = {};
                    //seriesObj["name"] = "Sales";
                    seriesObj["color"] = 'rgba(210, 43, 228, 0.5)';
                    seriesObj["data"] = data;
                    seriesObj["turboThreshold"] = 500000;
                    seriesArray.push(seriesObj);
                    MLCharts.charts.drawSmartScatterChart(seriesArray, "mlchart" + chartnum, "Predicted Value", 'Actual Value', undefined, undefined, this.selectedModel, true);
                    $('.mltooltip[data-num=' + chartnum + ']').removeClass("hide");
                    $('.mltooltip[data-num=' + chartnum + ']').attr("title", lang["mlreports.predictiveaccuracy"]);
                    var titleData = lang["mlreports.actualbypredictd"];
                    $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                    $('.mlcharttitle[data-num="' + chartnum + '"]').text(titleData).attr("title", titleData);
                    $("#spinnerchart" + chartnum).hide();
                    that.adjustMLChartHeight(chartnum);
                } else if (chartPosition === 2) {
                    $("#spinnerchart" + chartnum).hide();
                    var data = [];
                    if (this.data.mapeValue != undefined && this.data.mapeValue[0] < 8) {
                        data.push("<li style='font-size: 18px ; font-weight: bold;text-align:left;margin-bottom:10px;color: #2737a3;'>" + lang["mlreports.quality"] + "</li>");
                        data.push("<li class=\"nlg-item\"><span class=\"nlgtext\" style='font-size: 16px;'>&#9755;" + lang["mlreports.rerunmodel"] + "</span></li>");
                    } else {
                        //data.push("<li style='font-size: 18px ; font-weight: bold;text-align:left;margin-bottom:10px;color: #2737a3;'>Insight Quality</li>");
                        data.push("<li class=\"nlg-item\"><span class=\"nlgtext\" style='font-size: 16px;>&#9755;" + lang["mlreports.modelbuilt"] + "</span></li> ");
                    }
                    var keyIncluencers = "";
                    var dataSetColumnsCount = that.dimensions.length;
                    var that = this;
                    var keyinfluencers = this.data.keyinfluencers.data;
                    _.each(keyinfluencers, function (obj, index) {
                        if (obj.name != keyinfluencers[0].name) {
                            var renamed_names = obj.name;
                            _.each(that.dimensions, function (dimension, index) {
                                if (dimension.columnName == obj.name) {
                                    renamed_names = dimension.columnDisplayName;
                                }
                            });

                            if (index == keyinfluencers.length - 2) {
                                keyIncluencers += renamed_names + " " + lang["mlreports.and"] + " ";
                            } else {
                                keyIncluencers += renamed_names + ", ";
                            }
                        }
                    });
                    keyIncluencers = keyIncluencers.substring(0, keyIncluencers.length - 2);
                    data.push("<li style='font-size: 18px ; font-weight: bold;text-align:left;margin-bottom:10px;color: #2737a3;'>" + lang["mlreports.summary"] + "</li>");
                    data.push("<li class=\"nlg-item\"><span class=\"nlgtext\" style='font-size: 16px;'>&#9755;" + lang["mlreports.keyinfluencechart"] + "<b>" + keyIncluencers + "</b>" + lang["mlreports.kpiinfluence"] + "<b>" + dataSetColumnsCount + "</b>" + lang["mlreports.factorsdataset"] + "</span></li>");
                    data.push("<li class=\"nlg-item\"><span class=\"nlgtext\" style='font-size: 16px;'>&#9755;" + lang["mlreports.kpiperform"] + "</span></li>");
                    if (data != undefined && data != null && data.length > 0) {
                        $("#mlchart" + chartnum).append('<div class="nlgdata-container"><ul class="nlg-list" style=""></ul></div>');
                        for (var k1 = 0; k1 < data.length; k1++) {
                            $("#mlchart" + chartnum + " .nlgdata-container>ul.nlg-list").append(data[k1]);
                        }
                        that.adjustMLChartHeight(chartnum);
                    }
                    $("#spinnerchart" + chartnum).hide();
                } else if (chartPosition === 3) {
                    var data = this.data.keyinfluencers;
                    var columnIdsToNamesMap = this.data.columnIdsToNamesMap;
                    if (data != undefined && data.data != undefined) {
                        if (data == lang["mlreports.can'tcalculate"]) {
                            $('#mlchart' + chartnum).text(data);
                        } else {
                            var keyinfluencers = data.data;
                            var keyinfluencersCategories = data.categories;
                            var targetColumn = data.targetColumn;
                            var targetColumnDisplayName = data.targetColumnDisplayName;
                            var datatableData = this.data.dataframe;
                            var isTargetColumnCustomField = data.isTargetColumnCustomField;
                            MLCharts.charts.drawSmartBar(keyinfluencers, keyinfluencersCategories, targetColumn, targetColumnDisplayName, "mlchart" + chartnum, "", this.features,
                                this.reportId, undefined, this.broker, this.datedropdown, datatableData, this.selectedModel, this.data.interceptForSimulation, isTargetColumnCustomField, this.dimensions, columnIdsToNamesMap, that.options.datamodelId);
                        }
                    }
                    var titleData = lang["mlreports.Keyinfluencers"];
                    $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                    $('.mlcharttitle[data-num="' + chartnum + '"]').text(titleData).attr("title", titleData);
                    $("#spinnerchart" + chartnum).hide();
                    that.adjustMLChartHeight(chartnum);
                }
                while (chartPosition > 3 && chartPosition < 15) {
                    chartPosition = chartPosition + 1;
                    chartnum = isFromStrybok ? that.options.tabid + "_" + chartPosition : (chartnum + 1)
                }
                if (chartPosition === 15) {
                    var titleData = "Explainable AI (Notes)";
                    $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                    $('.mlcharttitle[data-num="' + chartnum + '"]').text(titleData).attr("title", titleData);
                    $('.richtextcontrolschart' + chartnum).show();
                    var chartDetails = ChartInfo.functions.getMLChartDetails(chartnum) != undefined ? JSON.parse(ChartInfo.functions.getMLChartDetails(chartnum)) : {};
                    var pos = "min"
                    ReactDOM.render(<TextChart data={chartDetails} chartId={"mlchart" + chartnum} chartType={chartType} position={pos} features={that.features} isSharedReport={false} iscustomstoryboard={false} />, document.getElementById("mlchart" + chartnum + ""));
                    setTimeout(function () {
                        if (chartDetails != undefined && chartDetails['richtextcontent'] != undefined) {
                            $("#mlchart" + chartnum).empty();
                            $("#mlchart" + chartnum).html(decodeURIComponent(chartDetails['richtextcontent']));
                        }
                        $("#spinner" + chartnum).hide();
                        $("#spinner" + chartnum).hide();
                        $('.editrichtext[data-num=' + chartnum + ']').removeClass('active');
                        $('.saverichtext[data-num=' + chartnum + ']').removeClass('active');
                        $("#mlchart" + chartnum).css({
                            'display': 'block',
                            'overflow-y': 'auto',
                            'overflow-x': 'auto',
                            'word-wrap': 'break-word',
                            //'max-width': 'calc(100vw - 150px)'
                        });
                        $('.countcharacters').text("Characters Count Limit:" + (14000 - (window.$('#mlchart' + chartnum).summernote('code') || "").replace(/(<([^>]+)>)/ig, "").replace(/( )/, " ").length));
                        if ($('#mlchart' + chartnum).is(':empty')) {
                            $('#mlchart' + chartnum).hide();
                            $('.editrichtext[data-num=' + chartnum + ']').click();
                        } else {
                            window.$('#mlchart' + chartnum).summernote('destroy');
                        }
                        if (that.pos == "min" || that.pos == undefined) {
                            //$('.richtextcontrolschart' + that.chartnumber).hide();
                            var texttileheight = $("#tileview" + chartnum).height() - $(".maxanim3[data-num=" + chartnum + "]").height() - 14;
                            $("#mlchart" + chartnum).css({
                                'height': texttileheight
                            });
                        }
                        $("#spinner" + chartnum).hide();
                    }, 200);
                }
                i = incrementer;
            }
            // $(".mltooltip").tooltip({ tooltipClass: 'filterinfotooltip', container: 'body' });
        } else if (this.selectedModel == "clustering") {
            for (var i = 0; i < 3; i++) {
                var incrementer = i;
                i = isFromStrybok ? that.options.tabid + "_" + i : i;
                $("#spinnerchart" + i).show();
                var mlChartDetails = JSON.parse(ChartInfo.functions.getMLChartDetails(i));
                var chartType = mlChartDetails.chartType;
                var chartnum = i;
                var chartPosition = isFromStrybok ? parseInt(chartnum.split("_")[1]) : i;
                if (chartPosition === 0) {
                    var data = this.data.clusteredData;
                    var that = this;
                    var seriesArray = [];
                    if (Object.keys(this.data.clusteredData).length > 0) {
                        for (var m = 0; m < Object.keys(this.data.clusteredData).length; m++) {
                            var seriesObj = {};
                            var clusterNumber = m + 1
                            seriesObj["name"] = "Cluster " + clusterNumber;
                            seriesObj["data"] = that.data.clusteredData[m];
                            // seriesObj["turboThreshold"] = 500000;
                            seriesArray.push(seriesObj);
                        }
                    }
                    var clusterDataArray = [];
                    clusterDataArray.push("Betweeness");
                    clusterDataArray.push("Cluster Size");
                    // clusterDataArray.push("Total Sum of Square");
                    clusterDataArray.push("Withinness");
                    MLCharts.charts.drawSmartScatterChart(seriesArray, "mlchart" + chartnum, "PC1", "PC2", clusterDataArray, "9", this.selectedModel, false);
                    $('.mltooltip[data-num=' + chartnum + ']').removeClass("hide");
                    // $('.mltooltip[data-num=' + chartnum + ']').attr("title", lang["mlreports.datadistributed"] + " " + seriesArray.length + " " + lang["mlreports.clusters"]);
                    $('.mltooltip[data-num=' + chartnum + ']').attr("title", "Clusters of samples based on their similarity");
                    var titleData = lang["mlreports.pc1vspc2"];
                    $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                    $('.mlcharttitle[data-num="' + chartnum + '"]').text(titleData).attr("title", titleData);
                    that.adjustMLChartHeight(chartnum);
                } else if (chartPosition == 1) {
                    mlChartDetails.chartType = "nlgdata"
                    $("#spinnerchart" + chartnum).show();
                    var data = this.data.insightSummaryNlgData;
                    if (data != undefined && data != null && Object.keys(data).length > 0) {
                        $("#mlchart" + chartnum).append('<div class="nlgdata-container"><ul class="nlg-list"></ul></div>');
                        /*for (var k2 = 1; k2 <= Object.keys(data).length; k2++) {
                            if (data["Cluster " + k2] != null && data["Cluster " + k2] != undefined) {
                                var text = "<font style=\"color: #d800ff;\"> " + lang["mlreports.clustr"] + k2 + "</font> " + lang["mlreports.predominantly"];
                                _.each(data["Cluster " + k2], function (obj, i) {
                                    var columnName = that.data.columnIdsToNamesMap[obj.var_name];
                                    if (i == data["Cluster " + k2].length - 2) {
                                        if (obj.datatype == "number") {
                                            text += lang["mlreports.withanavg"] + " <font style=\"color: #0089ff;\">" + columnName + "</font>" + lang["mlreports.of"] + "<b> " + obj.means + "</b>" + lang["mlreports.and"];
                                        } else {
                                            text += "<font style=\"color: #0089ff;\">" + columnName + "</font>-" + obj.name + " " + lang["mlreports.and"] + " ";
                                        }
                                    } else {
                                        if (obj.datatype == "number") {
                                            text += lang["mlreports.withanavg"] + " <font style=\"color: #0089ff;\">" + columnName + "</font> " + lang["mlreports.of"] + "<b> " + obj.means + "</b>,";
                                        } else {
                                            text += "<font style=\"color: #0089ff;\">" + columnName + "</font>-" + obj.name + ",";
                                        }
                                    }
                                });
                                text = text.substring(0, text.length - 1);
                                $("#mlchart" + chartnum + " .nlgdata-container>ul.nlg-list").append("<li class=\"nlg-item\"><span class=\"nlgtext\" style='font-size: 16px;'>&#9755;" + text + ".</span></li>");
                            }
                        }*/
                        //nlg data adding from ml response
                        for (var k1 = 0; k1 < data.length; k1++) {
                            $("#mlchart" + chartnum + " .nlgdata-container>ul.nlg-list").append(data[k1]);
                        }
                        that.adjustMLChartHeight(chartnum);
                        var titleData = lang["mlreports.summaryofinsight"];
                        $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                        $('.mlcharttitle[data-num="' + chartnum + '"]').text(titleData);
                        $('.mlcharttitle[data-num=' + chartnum + ']').attr("title", lang["mlreports.summaryofinsight"]);
                        $('.mltooltip[data-num=' + chartnum + ']').removeClass("hide");
                        $('.mltooltip[data-num=' + chartnum + ']').attr("title", "Statistical distributions of the Key Influencers");
                    }
                } else if (chartPosition === 2) {
                    var data;
                    if (this.data.categoricalData != undefined && this.data.categoricalData.length > 0) {
                        for (var i1 = 0; i1 < this.data.categoricalData.length; i1++) {
                            $("#spinnerchart" + chartnum).show();
                            data = this.data.categoricalData[i1];
                            if (data != undefined && data != null && Object.keys(data).length > 0) {
                                var renamed_column = data.yAxis;
                                renamed_column = that.data.columnIdsToNamesMap[renamed_column]
                                // MLCharts.charts.drawSingleMLTreeMapChart("mlchart" + chartnum, data, lang["mlreports.clustering"], chartnum, "0", that.dimensions, that.data.columnIdsToNamesMap);
                                MLCharts.charts.drawColumnBar(data.data, data.categories, "mlchart" + chartnum, renamed_column, true, "column", true);
                                that.adjustMLChartHeight(chartnum);
                                chartPosition = chartPosition + 1;
                                chartnum = isFromStrybok ? that.options.tabid + "_" + chartPosition : (chartnum + 1)
                            }
                        }
                    }
                    if (this.data.numericData != undefined && this.data.numericData.length > 0) {
                        for (var i2 = 0; i2 < this.data.numericData.length; i2++) {
                            data = this.data.numericData[i2];
                            if (data != undefined && data != null && Object.keys(data).length) {
                                $("#spinnerchart" + chartnum).show();
                                var renamed_column = data.yAxis;
                                renamed_column = that.data.columnIdsToNamesMap[renamed_column];
                                MLCharts.charts.drawColumnBar(data.data, data.categories, "mlchart" + chartnum, renamed_column, true, "column");
                                var titleData = lang["mlreports.mean&median"] + "<b>" + renamed_column + "</b>" + lang["mlreports.eachcluster"];
                                $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                                $('.mlcharttitle[data-num="' + chartnum + '"]').html(titleData);
                                $('.mlcharttitle[data-num="' + chartnum + '"]').attr("title", titleData);
                                that.adjustMLChartHeight(chartnum);
                                chartPosition = chartPosition + 1;
                                chartnum = isFromStrybok ? that.options.tabid + "_" + chartPosition : (chartnum + 1)
                            }
                        }
                    }
                    while (chartPosition < 8) {
                        $('.tileview[data-num="' + chartnum + '"]').hide()
                        chartPosition += 1;
                        chartnum = isFromStrybok ? that.options.tabid + "_" + chartPosition : (chartnum + 1)
                    }
                    if (chartPosition == 8) {
                        $("#spinnerchart" + chartnum).show();
                        var data = this.data.keyinfluencers;
                        if (data != undefined && data.data != undefined) {
                            if (data == lang["mlreports.can'tcalculate"]) {
                                $('#mlchart' + chartnum).text(data);
                            } else {
                                var keyinfluencers = data.data;
                                var keyinfluencersCategories = data.categories;
                                var targetColumn = data.targetColumn;
                                var datatableData = "";
                                var isTargetColumnCustomField = data.isTargetColumnCustomField;
                                MLCharts.charts.drawSmartBar(keyinfluencers, keyinfluencersCategories, targetColumn, targetColumn, "mlchart" + chartnum, "", this.features, this.reportId, this.datamodelId, this.broker, this.datedropdown, datatableData, this.selectedModel, isTargetColumnCustomField);
                            }
                        }
                        var titleData = lang["mlreports.keydifferentiators"];
                        $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                        $('.mlcharttitle[data-num="' + chartnum + '"]').text(titleData).attr("title", titleData);
                        $('.mltooltip[data-num=' + chartnum + ']').removeClass("hide");
                        $('.mltooltip[data-num=' + chartnum + ']').attr("title", "Top 6 features attributable to the identification of clusters");
                        that.adjustMLChartHeight(chartnum);
                        chartPosition = chartPosition + 1;
                        chartnum = isFromStrybok ? that.options.tabid + "_" + chartPosition : (chartnum + 1)
                    }
                    if (chartPosition == 9) {
                        $("#mlchart" + chartnum).empty();
                        $("#mlchart" + chartnum).append('<div class="mlheading-container" style="color: #58595b;font-weight: bold;font-size: 18px;"><ul class="nlg-list" style=""></ul></div>');
                        $("#mlchart" + chartnum + " .mlheading-container>ul.nlg-list").append(lang["mlreports.clustersdifferent"]);
                        $("#mlchart" + chartnum).css("overflow", "visible");
                        $("#mlchart" + chartnum).parent().css("overflow", "visible");
                        $("#mlchart" + chartnum + " .mlheading-container").css("margin-top", "-25px");
                        chartPosition = chartPosition + 1;
                        chartnum = isFromStrybok ? that.options.tabid + "_" + chartPosition : (chartnum + 1)
                    }
                    if (chartPosition == 10) {
                        $("#mlchart" + chartnum).empty();
                        $("#mlchart" + chartnum).append('<div class="mlheading-container" style="color: #2737a3;font-size: 26px;"><ul class="nlg-list" style=""></ul></div>');
                        $("#mlchart" + chartnum + " .mlheading-container>ul.nlg-list").append(lang["mlreports.clusteringmodel"]);
                        chartPosition = chartPosition + 1;
                        chartnum = isFromStrybok ? that.options.tabid + "_" + chartPosition : (chartnum + 1)
                    }
                    if (chartPosition == 11) {
                        $("#spinnerchart" + chartnum).show();
                        if (data != undefined) {
                            var keyinfluencersCategories = data.categories;
                            var data = this.data.nlgData;
                            mlChartDetails.chartType = "nlgdata";
                            var titleData = lang["mlreports.outcomesummary"];
                            $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                            $('.mlcharttitle[data-num="' + chartnum + '"]').text(titleData);
                            $('.mlcharttitle[data-num="' + chartnum + '"]').attr("title", titleData);
                            $("#mlchart" + chartnum).append('<div class="nlgdata-container"><ul class="nlg-list"></ul></div>');
                            var clusterText = [];
                            var no_of_clusters = (data != undefined && data != null && Object.keys(data).length > 0) ? Object.keys(data).length : 1;
                            var no_of_key_differentiators = (keyinfluencersCategories != undefined) ? keyinfluencersCategories.length : 1;
                            clusterText.push(lang["mlreports.clusteringalgorithm"] + "<b>" + no_of_clusters + "</b>" + lang["mlreports.uniqueclusters"]);
                            clusterText.push(lang["mlreports.eachclustersdifferent"] + "<b>" + no_of_key_differentiators + "</b>" + lang["mlreports.factors"]);
                            if (clusterText != undefined && clusterText != null && clusterText.length > 0) {
                                for (var k1 = 0; k1 < clusterText.length; k1++) {
                                    $("#mlchart" + chartnum + " .nlgdata-container>ul.nlg-list").append("<li class=\"nlg-item\"><span class=\"nlgtext\" style='font-size: 16px;'>&#9755;" + clusterText[k1] + ".</span></li>")
                                }
                                that.adjustMLChartHeight(chartnum);
                            }
                        } else {
                            $("#spinnerchart" + chartnum).hide();
                        }
                        chartPosition = chartPosition + 1;
                        chartnum = isFromStrybok ? that.options.tabid + "_" + chartPosition : (chartnum + 1)
                    }
                    if (chartPosition == 12) {
                        var titleData = "Explainable AI (Notes)";
                        $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                        $('.mlcharttitle[data-num="' + chartnum + '"]').text(titleData).attr("title", titleData);
                        $('.richtextcontrolschart' + chartnum).show();
                        var chartDetails = ChartInfo.functions.getMLChartDetails(chartnum) != undefined ? JSON.parse(ChartInfo.functions.getMLChartDetails(chartnum)) : {};
                        var pos = "min"
                        ReactDOM.render(<TextChart data={chartDetails} chartId={"mlchart" + chartnum} chartType={chartType} position={pos} features={that.features} isSharedReport={false} iscustomstoryboard={false} />, document.getElementById("mlchart" + chartnum + ""));
                        setTimeout(function () {
                            if (chartDetails != undefined && chartDetails['richtextcontent'] != undefined) {
                                $("#mlchart" + chartnum).empty();
                                $("#mlchart" + chartnum).html(decodeURIComponent(chartDetails['richtextcontent']));
                            }
                            $("#spinner" + chartnum).hide();
                            $("#spinner" + chartnum).hide();
                            $('.editrichtext[data-num=' + chartnum + ']').removeClass('active');
                            $('.saverichtext[data-num=' + chartnum + ']').removeClass('active');
                            $("#mlchart" + chartnum).css({
                                'display': 'block',
                                'overflow-y': 'auto',
                                'overflow-x': 'auto',
                                'word-wrap': 'break-word',
                                //'max-width': 'calc(100vw - 150px)'
                            });
                            $('.countcharacters').text("Characters Count Limit:" + (14000 - (window.$('#mlchart' + chartnum).summernote('code') || "").replace(/(<([^>]+)>)/ig, "").replace(/( )/, " ").length));
                            if ($('#mlchart' + chartnum).is(':empty')) {
                                $('#mlchart' + chartnum).hide();
                                $('.editrichtext[data-num=' + chartnum + ']').click();
                            } else {
                                window.$('#mlchart' + chartnum).summernote('destroy');
                            }
                            if (that.pos == "min" || that.pos == undefined) {
                                //$('.richtextcontrolschart' + that.chartnumber).hide();
                                var texttileheight = $("#tileview" + chartnum).height() - $(".maxanim3[data-num=" + chartnum + "]").height() - 14;
                                $("#mlchart" + chartnum).css({
                                    'height': texttileheight
                                });
                            }
                            $("#spinner" + chartnum).hide();
                        }, 200);
                    }
                }
                i = incrementer;
            }
        } else if (this.selectedModel == "decisiontree") {
            try {
                for (var i = 0; i <= 6; i++) {
                    var incrementer = i;
                    i = isFromStrybok ? that.options.tabid + "_" + i : i;
                    var mlChartDetails = JSON.parse(ChartInfo.functions.getMLChartDetails(i));
                    var chartType = mlChartDetails.chartType;
                    var chartnum = i;
                    var chartPosition = isFromStrybok ? parseInt(chartnum.split("_")[1]) : i
                    var titleData = "";
                    if (chartPosition === 0) {
                        var data = that.data.accuracy;
                        titleData = lang["mlreports.accuracy"]
                        MLCharts.charts.drawSmartSolidgaugeChart(data, "mlchart" + chartnum);
                        $("#spinnerchart" + chartnum).hide();
                        that.adjustMLChartHeight(chartnum);
                        $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                        $('.mlcharttitle[data-num="' + chartnum + '"]').text(titleData).attr("title", titleData);
                    } else if (chartPosition === 1) {
                        var data = that.data.decisiontreedata;
                        $("#mlchart" + chartnum).empty();

                        MLCharts.charts.drawDecisionTreeChart("mlchart" + chartnum, data);
                        $("#spinnerchart" + chartnum).hide();
                        that.adjustMLChartHeight(chartnum);
                        titleData = "Decision Tree"
                        $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                        $('.mlcharttitle[data-num="' + chartnum + '"]').text(titleData).attr("title", titleData);
                    } else if (chartPosition === 2) {
                        /*var data = that.data.variableimportance;
                        if (data != undefined && data != null) {
                            //MLCharts.charts.drawSmartBar(data.data,data.catagories, "","mlchart"+chartnum,"",that.features,that.reportId,that.dataModelId,that.broker,"","");
                            MLCharts.charts.drawColumnBar(data.data, data.catagories, "mlchart" + chartnum, "", false, "bar");
                        }
                        $("#spinnerchart" + chartnum).hide();
                        that.adjustMLChartHeight(chartnum);
                        titleData = lang["mlreports.variableimportance"]
                        $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                        $('.mlcharttitle[data-num="' + chartnum + '"]').text(titleData).attr("title", titleData);*/
                        var data = that.data.f1score;
                        titleData = "F1-Score"
                        MLCharts.charts.drawSmartSolidgaugeChart(data, "mlchart" + chartnum);
                        $("#spinnerchart" + chartnum).hide();
                        that.adjustMLChartHeight(chartnum);
                        $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                        $('.mlcharttitle[data-num="' + chartnum + '"]').text(titleData).attr("title", titleData);
                    } else if (chartPosition === 3) {
                        var data = that.data.cmtable[0];
                        var categories = data.catagories;
                        var confusionmatrix = data.confusionmatrix;
                        var emptyarray = data.emptyarray;
                        var precisionarray = data.precisionarray;
                        var recallarray = data.recallarray;
                        if (data != undefined && data != null) {
                            MLCharts.charts.drawSmartHeatmap(confusionmatrix, categories, "mlchart" + chartnum, emptyarray, precisionarray, recallarray);
                        }
                        $("#spinnerchart" + chartnum).hide();
                        that.adjustMLChartHeight(chartnum);
                        titleData = lang["mlreports.confusionmatrix"]
                        $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                        $('.mlcharttitle[data-num="' + chartnum + '"]').text(titleData).attr("title", titleData);
                    } else if (chartPosition == 4) {
                        $("#mlchart" + chartnum).empty();
                        $("#mlchart" + chartnum).append('<div class="mlheading-container" style="color: #2737a3;font-weight: bold;font-size: 26px;"><ul class="nlg-list" style=""></ul></div>');
                        $("#mlchart" + chartnum + " .mlheading-container>ul.nlg-list").append(lang["mlreports.treemodeldashboard"]);
                    } else if (chartPosition === 5) {
                        var rocCurvedata = that.data["roccurve_values"];
                        var aucMetricdata = that.data["aucmetric_values"];
                        var seriesArray = [];
                        var count = 1;
                        for (var key in rocCurvedata) {
                            var seriesObj = {};
                            seriesObj["color"] = MLCharts.colorThemesArray[0][count]
                            seriesObj["data"] = rocCurvedata[key];
                            seriesObj["name"] = key;
                            seriesObj["turboThreshold"] = 500000;
                            seriesArray.push(seriesObj);
                            count++;
                        }
                        MLCharts.charts.drawRocCurveChart(seriesArray, "mlchart" + chartnum, "True Positive Rate", 'False Postive Rate', aucMetricdata);
                        $("#spinnerchart" + chartnum).hide();
                        that.adjustMLChartHeight(chartnum);
                        var titleData = "AUC -ROC Curve";
                        $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                        $('.mlcharttitle[data-num="' + chartnum + '"]').text(titleData).attr("title", titleData);
                    } else if (chartPosition == 6) {
                        var titleData = "Explainable AI (Notes)";
                        $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                        $('.mlcharttitle[data-num="' + chartnum + '"]').text(titleData).attr("title", titleData);
                        $('.richtextcontrolschart' + chartnum).show();
                        var chartDetails = ChartInfo.functions.getMLChartDetails(chartnum) != undefined ? JSON.parse(ChartInfo.functions.getMLChartDetails(chartnum)) : {};
                        var pos = "min"
                        ReactDOM.render(<TextChart data={chartDetails} chartId={"mlchart" + chartnum} chartType={chartType} position={pos} features={that.features} isSharedReport={false} iscustomstoryboard={false} />, document.getElementById("mlchart" + chartnum + ""));
                        setTimeout(function () {
                            if (chartDetails != undefined && chartDetails['richtextcontent'] != undefined) {
                                $("#mlchart" + chartnum).empty();
                                $("#mlchart" + chartnum).html(decodeURIComponent(chartDetails['richtextcontent']));
                            }
                            $("#spinner" + chartnum).hide();
                            $("#spinner" + chartnum).hide();
                            $('.editrichtext[data-num=' + chartnum + ']').removeClass('active');
                            $('.saverichtext[data-num=' + chartnum + ']').removeClass('active');
                            $("#mlchart" + chartnum).css({
                                'display': 'block',
                                'overflow-y': 'auto',
                                'overflow-x': 'auto',
                                'word-wrap': 'break-word',
                                //'max-width': 'calc(100vw - 150px)'
                            });
                            $('.countcharacters').text("Characters Count Limit:" + (14000 - (window.$('#mlchart' + chartnum).summernote('code') || "").replace(/(<([^>]+)>)/ig, "").replace(/( )/, " ").length));
                            if ($('#mlchart' + chartnum).is(':empty')) {
                                $('#mlchart' + chartnum).hide();
                                $('.editrichtext[data-num=' + chartnum + ']').click();
                            } else {
                                window.$('#mlchart' + chartnum).summernote('destroy');
                            }
                            if (that.pos == "min" || that.pos == undefined) {
                                //$('.richtextcontrolschart' + that.chartnumber).hide();
                                var texttileheight = $("#tileview" + chartnum).height() - $(".maxanim3[data-num=" + chartnum + "]").height() - 14;
                                $("#mlchart" + chartnum).css({
                                    'height': texttileheight
                                });
                            }
                            $("#spinner" + chartnum).hide();
                        }, 200);
                    }
                    i = incrementer;
                }
            } catch (e) {
                console.error(e)
            }
        } else if (this.selectedModel == "textanalysis") {
            var textColumnsLength = $(".mltextcolumns .singleliitems").length;
            /*for (var i = 0; i <= 2; i++) {
                $("#spinnerchart" + i).show();
                var mlChartDetails = JSON.parse(ChartInfo.functions.getMLChartDetails(i));
                var chartType = mlChartDetails.chartType;
                var chartnum = i;
                if (chartnum === 0) {
                    $("#mlchart" + chartnum).empty();
                    $("#mlchart" + chartnum).append('<div class="mlheading-container" style="color: #2737a3;font-weight: bold;font-size: 26px;"><ul class="nlg-list" style=""></ul></div>');
                    $("#mlchart" + chartnum + " .mlheading-container>ul.nlg-list").append(lang["mlreports.textanlysisdashboard"]);
                    $("#spinnerchart" + chartnum).hide();
                } else if (chartnum === 1) {
                    var data = this.data;
                    if (data.length > 0) {
                        if (data != undefined && data != null && Object.keys(this.data[0]).length > 0) {
                            if (this.data != undefined) {
                                var keys = Object.keys(this.data);
                                for (let m1 = 0; m1 < keys.length; m1++) {
                                    let colsdata = this.data[m1][Object.keys(this.data[m1])];
                                    if (colsdata[2] != undefined) {
                                        $("#spinnerchart" + chartnum).show();
                                        try {
                                            MLCharts.charts.drawWordCloudChart(colsdata[2], undefined, undefined, undefined,
                                                undefined, "mlchart" + chartnum, lang["mlreports.wordcloud"], undefined, undefined, this.features, false, "0", undefined, undefined, undefined, undefined, undefined, undefined, undefined, chartnum, true)
                                            var titleData = lang["mlreports.wordcloudfor"] + " " + Object.keys(this.data[m1]) + "";
                                        } catch (e) {
                                            console.log(e)
                                        }
                                        $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                                        $('.mlcharttitle[data-num="' + chartnum + '"]').text(titleData);
                                        that.adjustMLChartHeight(chartnum);
                                        mlChartDetails.chartType = lang["mlreports.wordcloud"]
                                        ChartInfo.functions.updateMLChartDetails(chartnum, JSON.stringify(mlChartDetails));
                                        chartnum = chartnum + 1;
                                    }
                                    if (colsdata[1] != undefined) {
                                        $("#spinnerchart" + chartnum).show();
                                        mlChartDetails.chartType = "packedbubble"
                                        ChartInfo.functions.updateMLChartDetails(chartnum, JSON.stringify(mlChartDetails));
                                        var positivewords = colsdata[1];
                                        try {
                                            var network = MLCharts.charts.drawPackedBubbleWithVis(positivewords, chartnum, "2");
                                        } catch (e) {
                                            console.log(e)
                                        }
                                        that.adjustMLChartHeight(chartnum);
                                        var titleData = lang["mlreports.positivewords"] + " " + Object.keys(this.data[m1]) + "";
                                        $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                                        $('.mlcharttitle[data-num="' + chartnum + '"]').text(titleData);
                                        chartnum = chartnum + 1;
                                    }
                                    if (colsdata[0] != undefined) {
                                        $("#spinnerchart" + chartnum).show();
                                        mlChartDetails.chartType = "packedbubble"
                                        ChartInfo.functions.updateMLChartDetails(chartnum, JSON.stringify(mlChartDetails));
                                        var newgativewords = colsdata[0];
                                        try {
                                            var network = MLCharts.charts.drawPackedBubbleWithVis(newgativewords, chartnum, "2");
                                        } catch (e) {
                                            console.log(e)
                                        }
                                        that.adjustMLChartHeight(chartnum);
                                        var titleData = lang["mlreports.negativewords"] + " " + Object.keys(this.data[m1]) + "";
                                        $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                                        $('.mlcharttitle[data-num="' + chartnum + '"]').text(titleData);
                                        chartnum = chartnum + 1;
                                    }
                                }
                            }
                            while (chartnum > keys.length && chartnum < 10) {
                                $('.tileview[data-num="' + chartnum + '"]').hide()
                                chartnum += 1;
                            }
                        }
                    }
                } else if (chartnum === 2) {
                    chartnum = textColumnsLength * 3 + 1;
                    $('.tileview[data-num="' + chartnum + '"]').show()
                    $('.richtextcontrolschart' + chartnum).show();
                    var chartDetails = ChartInfo.functions.getMLChartDetails(chartnum) != undefined ? JSON.parse(ChartInfo.functions.getMLChartDetails(chartnum)) : {};
                    var pos = "min"
                    ReactDOM.render(<TextChart data={chartDetails} chartId={"mlchart" + chartnum} chartType={chartType} position={pos} features={that.features} isSharedReport={false} iscustomstoryboard={false} />, document.getElementById("mlchart" + chartnum + ""));
                    setTimeout(function () {
                        if (chartDetails != undefined && chartDetails['richtextcontent'] != undefined) {
                            $("#mlchart" + chartnum).empty();
                            $("#mlchart" + chartnum).html(decodeURIComponent(chartDetails['richtextcontent']));
                        }
                        $("#spinner" + chartnum).hide();
                        $("#spinner" + chartnum).hide();
                        $('.editrichtext[data-num=' + chartnum + ']').removeClass('active');
                        $('.saverichtext[data-num=' + chartnum + ']').removeClass('active');
                        $("#mlchart" + chartnum).css({
                            'display': 'block',
                            'overflow-y': 'auto',
                            'overflow-x': 'auto',
                            'word-wrap': 'break-word',
                            //'max-width': 'calc(100vw - 150px)'
                        });
                        $('.countcharacters').text("Characters Count Limit:" + (14000 - (window.$('#mlchart' + chartnum).summernote('code') || "").replace(/(<([^>]+)>)/ig, "").replace(/( )/, " ").length));
                        if ($('#mlchart' + chartnum).is(':empty')) {
                            $('#mlchart' + chartnum).hide();
                            $('.editrichtext[data-num=' + chartnum + ']').click();
                        } else {
                            window.$('#mlchart' + chartnum).summernote('destroy');
                        }
                        if (that.pos == "min" || that.pos == undefined) {
                            //$('.richtextcontrolschart' + that.chartnumber).hide();
                            var texttileheight = $("#tileview" + chartnum).height() - $(".maxanim3[data-num=" + chartnum + "]").height() - 14;
                            $("#mlchart" + chartnum).css({
                                'height': texttileheight
                            });
                        }
                        $("#spinner" + chartnum).hide();
                    }, 200);
                }
            }*/
            for (let i = 0; i < 7; i++) {
                var chartnum = "";
                chartnum += i;
                let titleData = "";
                if (chartnum == 0) {
                    let data = that.data.words_sentiment_data;
                    MLCharts.charts.drawSmartPieChart(data, "mlchart" + chartnum, true);
                    titleData = "Sentiment by Words​";
                    $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                    $('.mlcharttitle[data-num="' + chartnum + '"]').text(titleData);
                    $('.mltooltip[data-num=' + chartnum + ']').removeClass("hide");
                    $('.mltooltip[data-num=' + chartnum + ']').attr("title", "Sentiment of words in a sentence");
                    that.adjustMLChartHeight(chartnum);
                } else if (chartnum == 1) {
                    titleData = "Sentiment by Text​";
                    let data = that.data.text_sentiment_data;
                    MLCharts.charts.drawSmartPieChart(data, "mlchart" + chartnum, true);
                    $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                    $('.mlcharttitle[data-num="' + chartnum + '"]').text(titleData);
                    $('.mltooltip[data-num=' + chartnum + ']').removeClass("hide");
                    $('.mltooltip[data-num=' + chartnum + ']').attr("title", "The overall sentiment of a sentence");
                    that.adjustMLChartHeight(chartnum);
                } else if (chartnum == 2) {
                    titleData = "Aspect Analysis​";
                    $('.mlcharttitle[data-num="' + chartnum + '"]').after('<button type="button" class="btn btn-bird btn-highlight marg-righ-5 pull-right" id="textfilter"  title="Filter">Filter</button>');
                    let data = _.clone(that.data.aspect_analysis);
                    that.state.textbarchartdata = _.clone(that.data.aspect_analysis);
                    that.state.catagories = data.categories;
                    if(data.data&&data.data.length>0){
                    const chartData = data.data.slice(0, 10);
                    const chartCategories = data.categories.slice(0, 10);
                    chartCategories.map(function (val) {
                        that.state.selectedcategories.push(val.columnDisplayName)
                    });
                    $("#textfilter").click(function () {
                        that.textfiltermodel()
                    })
                    MLCharts.charts.drawSmartBar(chartData, chartCategories, "", "", "mlchart" + chartnum, "", this.features, this.reportId, this.datamodelId, this.broker, "", "", this.selectedModel, "");
                    }
                    $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                    $('.mlcharttitle[data-num="' + chartnum + '"]').text(titleData);
                    $('.mltooltip[data-num=' + chartnum + ']').removeClass("hide");
                    $('.mltooltip[data-num=' + chartnum + ']').attr("title", "Aspects are topics that are being talked about most. User can select 10 aspects at a time");
                    that.adjustMLChartHeight(chartnum);
                } else if (chartnum == 3) {
                    titleData = "Sentiment by Aspect​";
                    let data = _.clone(that.data.sentiment_by_aspect);
                    that.state.textstackedchartdata = _.clone(that.data.sentiment_by_aspect);
                    const stackChartData = [];
                    _.each(data.data, function (val) {
                        let tempObj = {};
                        tempObj["data"] = val.data!=undefined&&val.data!=null?val.data.slice(0, 10):null;
                        tempObj["name"] = val.name;
                        stackChartData.push(tempObj);
                    })
                    MLCharts.charts.drawColumnBar(stackChartData, data.categories.slice(0, 10), "mlchart" + chartnum, "", true, "column", true,"percent");
                    $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                    $('.mlcharttitle[data-num="' + chartnum + '"]').text(titleData);
                    that.adjustMLChartHeight(chartnum);
                }else if (chartnum == 4) {
                    titleData = "Sentiment Simulation​";
                    that.simulationsentiment(chartnum);
                    setTimeout(function(){
                        $("#validatepop-sentiment").click(function (e) {
                            that.checkText(e)
                        });
                        $("#clearpop-sentiment").click(function (e) {
                            that.clearText(e)
                        })
                    },1000)
                    $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                    $('.mlcharttitle[data-num="' + chartnum + '"]').text(titleData);
                   
                }else if (chartnum == 5) {
                    titleData = "Word Cloud​";
                    try {
                        let data = that.data.sentiment_wordcloud_data;
                        MLCharts.charts.drawWordCloudChart(data, undefined, undefined, undefined,
                            undefined, "mlchart" + chartnum, lang["mlreports.wordcloud"], undefined, undefined, this.features, false, "0", undefined, undefined, undefined,
                             undefined, undefined, undefined, undefined, chartnum, true,true,undefined,undefined,undefined,this.datamodelId,undefined,undefined,this.selectedModel)
                    } catch (e) {

                    }
                    $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                    $('.mlcharttitle[data-num="' + chartnum + '"]').text(titleData);
                    that.adjustMLChartHeight(chartnum);
                    let mlChartDetails = JSON.parse(ChartInfo.functions.getMLChartDetails(chartnum));
                    mlChartDetails["chartType"] = lang["mlreports.wordcloud"]
                    ChartInfo.functions.updateMLChartDetails(chartnum, JSON.stringify(mlChartDetails));
                }  else if (chartnum == 6) {
                    $('.tileview[data-num="' + chartnum + '"]').show()
                    $('.richtextcontrolschart' + chartnum).show();
                    var chartDetails = ChartInfo.functions.getMLChartDetails(chartnum) != undefined ? JSON.parse(ChartInfo.functions.getMLChartDetails(chartnum)) : {};
                    var pos = "min"
                    ReactDOM.render(<TextChart data={chartDetails} chartId={"mlchart" + chartnum} chartType={chartType} position={pos} features={that.features} isSharedReport={false} iscustomstoryboard={false} />, document.getElementById("mlchart" + chartnum + ""));
                    setTimeout(function () {
                        try {
                            let pos = "min";
                            if (chartDetails != undefined && chartDetails['richtextcontent'] != undefined && !_.isEmpty(chartDetails['richtextcontent'])) {
                                $("#mlchart" + chartnum).empty();
                                $("#mlchart" + chartnum).html(decodeURIComponent(chartDetails['richtextcontent']));
                            }
                            $("#spinner" + chartnum).hide();
                            $("#spinner" + chartnum).hide();
                            $('.editrichtext[data-num=' + chartnum + ']').removeClass('active');
                            $('.saverichtext[data-num=' + chartnum + ']').removeClass('active');
                            $("#mlchart" + chartnum).css({
                                'display': 'block',
                                'overflow-y': 'auto',
                                'overflow-x': 'auto',
                                'word-wrap': 'break-word',
                                //'max-width': 'calc(100vw - 150px)'
                            });
                            $('.countcharacters').text("Characters Count Limit:" + (14000 - (window.$('#mlchart' + chartnum).summernote('code') || "").replace(/(<([^>]+)>)/ig, "").replace(/( )/, " ").length));
                            if ($('#mlchart' + chartnum).is(':empty')) {
                                $('#mlchart' + chartnum).hide();
                                $('.editrichtext[data-num=' + chartnum + ']').click();
                            } else {
                                window.$('#mlchart' + chartnum).summernote('destroy');
                            }
                            if (pos == "min" || pos == undefined) {
                                //$('.richtextcontrolschart' + that.chartnumber).hide();
                                var texttileheight = $("#tileview" + chartnum).height() - $(".maxanim3[data-num=" + chartnum + "]").height() - 14;
                                $("#mlchart" + chartnum).css({
                                    'height': texttileheight
                                });
                            }
                            $("#spinner" + chartnum).hide();
                        } catch (err) {
                            console.error(err);
                        }
                    }, 200);
                }
            }
        } else if (this.selectedModel == "forecasting") {
            var chartSettings;

            chartSettings = { 'chartTitle': true, 'legend': true, 'xAxisLabel': true, 'yAxisLabel': true, 'dataLabel': false, 'crossHairs': false, 'gridLine': true };
            let chartsettingsclone = _.clone(chartSettings);
            var modelDetails = ChartInfo.functions.getMLModelDetails();
            var y_value = "";
            var yaxisnameArray = [];
            var months = "";
            var dateColumn = "";
            var futureValues = [];
            var independentVariables = [];
            var xAxisName = "";
            if (modelDetails != undefined) {
                //y_value= modelDetails.yValue[0].displayName;
                xAxisName = modelDetails.xAxisArray[0].columnName;
                months = modelDetails.parametersObj.no_of_periods_to_forecast;
                dateColumn = modelDetails.dateColumnsArray[0]["displayName"];
                futureValues = modelDetails.parametersObj.futureValues
                independentVariables = modelDetails.parametersObj.independentVariables
            }
            for (var i = 0; i <= 13; i++) {
                var incrementer = i;
                i = isFromStrybok ? that.options.tabid + "_" + i : i;
                $("#spinnerchart" + i).show();
                var mlChartDetails = JSON.parse(ChartInfo.functions.getMLChartDetails(i));
                var chartnum = i;
                var chartPosition = isFromStrybok ? parseInt(chartnum.split("_")[1]) : i
                var data = this.data;
                var noOfPeriodsForecasted = data["no_of_periods_forecasted"];
                $("#spinnerchart" + chartnum).show();
                if (chartPosition === 0) {
                    mlChartDetails.chartType = "linetimeseries";
                    var timeSeriesData = data["timeseriesArray"];
                    var yaxisnameArray = [];
                    var yAxisAggArray = [];
                    if (timeSeriesData != undefined && timeSeriesData != null && timeSeriesData.length > 0) {
                        for (var v1 = 0; v1 < timeSeriesData.length; v1++) {
                            yaxisnameArray.push(timeSeriesData[v1]["displayname"]);
                            yAxisAggArray.push(timeSeriesData[v1]["aggType"]);
                        }
                    }
                    MLCharts.charts.drawTimeSeries(data["timeseriesArray"], dateColumn, dateColumn, "date",
                        yaxisnameArray, "mlchart" + chartnum, "linetimeseries", undefined, undefined, this.features, false, "1", chartSettings, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, true);
                    $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                    $('.mlcharttitle[data-num="' + chartnum + '"]').html(lang["mlreports.understand"] + " " + "<b>" + xAxisName + "</b>" + " " + lang["mlreports.trend"]);
                    $('.mlcharttitle[data-num=' + chartnum + ']').attr("title", lang["mlreports.understand"] + " " + xAxisName + " " + " " + lang["mlreports.trend"]);
                    that.adjustMLChartHeight(chartnum);
                } else if (chartPosition >= 1 && chartPosition <= 4) {
                    var metricData;
                    let metricFooterDateValue;
                    if (chartPosition == 1) {
                        if (data["MAPEValue"] != null && data["MAPEValue"] != undefined) {
                            let tempArrayval = data["MAPEValue"].toString().split(",");
                            metricData = tempArrayval[0].trim();
                            metricFooterDateValue = tempArrayval[1] != undefined ? tempArrayval[1].trim() : "";
                        }
                        $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                        $('.mlcharttitle[data-num="' + chartnum + '"]').text(lang["mlreports.mape"] + " " + xAxisName);
                        $('.mlcharttitle[data-num=' + chartnum + ']').attr("title", lang["mlreports.mape"] + " " + xAxisName);
                    } else if (chartPosition == 2) {
                        metricData = data["MSEValue"];
                        if (data["MSEValue"] != null && data["MSEValue"] != undefined) {
                            let tempArrayval = data["MSEValue"].toString().split(",");
                            metricData = tempArrayval[0].trim();
                            metricFooterDateValue = tempArrayval[1] != undefined ? tempArrayval[1].trim() : "";
                        }
                        $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                        $('.mlcharttitle[data-num="' + chartnum + '"]').text(lang["mlreports.mse"] + " " + xAxisName);
                        $('.mlcharttitle[data-num=' + chartnum + ']').attr("title", lang["mlreports.mse"] + " " + xAxisName);
                    } else if (chartPosition == 3) {
                        metricData = data["MEValue"];
                        if (data["MEValue"] != null && data["MEValue"] != undefined) {
                            let tempArrayval = data["MEValue"].toString().split(",");
                            metricData = tempArrayval[0].trim();
                            metricFooterDateValue = tempArrayval[1] != undefined ? tempArrayval[1].trim() : "";
                        }
                        $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                        $('.mlcharttitle[data-num="' + chartnum + '"]').text(lang["mlreports.me"] + " " + xAxisName);
                        $('.mlcharttitle[data-num=' + chartnum + ']').attr("title", lang["mlreports.me"] + " " + xAxisName);
                    } else if (chartPosition == 4) {
                        metricData = data["MAEValue"];
                        if (data["MAEValue"] != null && data["MAEValue"] != undefined) {
                            let tempArrayval = data["MAEValue"].toString().split(",");
                            metricData = tempArrayval[0].trim();
                            metricFooterDateValue = tempArrayval[1] != undefined ? tempArrayval[1].trim() : "";
                            $('.mltooltip[data-num=' + chartnum + ']').removeClass("hide");
                            $('.mltooltip[data-num=' + chartnum + ']').attr("title", "Measure of prediction accuracy")
                        }
                        $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                        $('.mlcharttitle[data-num="' + chartnum + '"]').text(lang["mlreports.mae"]);
                        $('.mlcharttitle[data-num=' + chartnum + ']').attr("title", lang["mlreports.mae"]);
                    }
                    MLCharts.charts.drawMetricChart(metricData, "mlchart" + chartnum, chartType, "", this.pos, "", "", chartnum, 0, "", this.metricprefix, this.metricsuffix, this.broker);
                    $("#spinnerchart" + chartnum).hide();
                    if (chartPosition != 4 && metricFooterDateValue != undefined && metricFooterDateValue != null) {
                        $("#mlchart" + chartnum + " .metricvaluecontainer").after('<span class="metricsuffixcontent" style="line-height: 1em;font-size: 14px;color: #666"><span style=" display: block;">' + metricFooterDateValue + '</span></span>')
                    }
                    that.adjustMLChartHeight(chartnum);
                } else if (chartPosition === 5) {
                    mlChartDetails.chartType = "nlgdata";
                    var nlgdata = data["nlgDataArray"];
                    if (nlgdata != undefined && nlgdata != null && nlgdata.length > 0) {
                        $("#spinnerchart" + chartnum).show();
                        $("#mlchart" + chartnum).append('<div class="nlgdata-container"><ul class="nlg-list"></ul></div>');
                        for (var k1 = 0; k1 < nlgdata.length; k1++) {
                            $("#mlchart" + chartnum + " .nlgdata-container>ul.nlg-list").append(nlgdata[k1]);
                        }
                        $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                        $('.mlcharttitle[data-num="' + chartnum + '"]').text(lang["mlreports.summaryofinsight"]);
                        $('.mlcharttitle[data-num=' + chartnum + ']').attr("title", lang["mlreports.summaryofinsight"]);
                        that.adjustMLChartHeight(chartnum);
                        $("#spinnerchart" + chartnum).hide();
                    }
                } else if (chartPosition === 6) {
                    var timeSeriesData = data["trendArray"];
                    var yaxisnameArray = [];
                    if (timeSeriesData != undefined && timeSeriesData != null && timeSeriesData.length > 0) {
                        for (var v1 = 0; v1 < timeSeriesData.length; v1++) {
                            yaxisnameArray.push(timeSeriesData[v1]["displayname"]);
                        }
                    }
                    mlChartDetails.chartType = "linetimeseries";
                    chartsettingsclone.yAxisLabel = false;
                    MLCharts.charts.drawTimeSeries(data["trendArray"], dateColumn, dateColumn, "date",
                        yaxisnameArray, "mlchart" + chartnum, "linetimeseries", undefined, undefined, this.features, false, "1", chartsettingsclone, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, true, undefined, undefined, true);
                    $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                    $('.mlcharttitle[data-num="' + chartnum + '"]').text(lang["mlreports.trend"]);
                    $('.mlcharttitle[data-num=' + chartnum + ']').attr("title", lang["mlreports.trend"]);
                    $('.mltooltip[data-num=' + chartnum + ']').removeClass("hide");
                    $('.mltooltip[data-num=' + chartnum + ']').attr("title", "Fluctuations in the target variable over a time period");
                    that.adjustMLChartHeight(chartnum);
                } else if (chartPosition === 7) {
                    var timeSeriesData = data["randomnessArray"];
                    var yaxisnameArray = [];
                    if (timeSeriesData != undefined && timeSeriesData != null && timeSeriesData.length > 0) {
                        for (var v1 = 0; v1 < timeSeriesData.length; v1++) {
                            yaxisnameArray.push(timeSeriesData[v1]["displayname"]);
                        }
                    }
                    mlChartDetails.chartType = "linetimeseries";
                    chartsettingsclone.yAxisLabel = false;
                    MLCharts.charts.drawTimeSeries(data["randomnessArray"], dateColumn, dateColumn, "date",
                        yaxisnameArray, "mlchart" + chartnum, "linetimeseries", undefined, undefined, this.features, false, "1", chartsettingsclone, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, true, undefined, undefined, true);
                    $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                    $('.mlcharttitle[data-num="' + chartnum + '"]').text(lang["mlreports.randomness"]);
                    $('.mlcharttitle[data-num=' + chartnum + ']').attr("title", lang["mlreports.randomness"]);
                    $('.mltooltip[data-num=' + chartnum + ']').removeClass("hide");
                    $('.mltooltip[data-num=' + chartnum + ']').attr("title", "Error present in the forecast model");
                    that.adjustMLChartHeight(chartnum);
                } else if (chartPosition === 8) {
                    mlChartDetails.chartType = "linetimeseries";
                    var timeSeriesData = data["seasonalityArray"];
                    var yaxisnameArray = [];
                    if (timeSeriesData != undefined && timeSeriesData != null && timeSeriesData.length > 0) {
                        for (var v1 = 0; v1 < timeSeriesData.length; v1++) {
                            yaxisnameArray.push(timeSeriesData[v1]["displayname"]);
                        }
                    }
                    chartsettingsclone.yAxisLabel = false;
                    MLCharts.charts.drawTimeSeries(data["seasonalityArray"], dateColumn, dateColumn, "date",
                        yaxisnameArray, "mlchart" + chartnum, "linetimeseries", undefined, undefined, this.features, false, "1", chartsettingsclone, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, true, undefined, undefined, true);
                    $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                    $('.mlcharttitle[data-num="' + chartnum + '"]').text(lang["mlreports.seasonality"]);
                    $('.mlcharttitle[data-num=' + chartnum + ']').attr("title", lang["mlreports.seasonality"]);
                    $('.mltooltip[data-num=' + chartnum + ']').removeClass("hide");
                    $('.mltooltip[data-num=' + chartnum + ']').attr("title", "Repeating patterns in the data over a time period");
                    that.adjustMLChartHeight(chartnum);
                } else if (chartPosition === 9) {

                    $("#mlchart" + chartnum).bootstrapTable("destroy")
                    data = that.data["ForecastTable"];
                    that.state.downloadableJSONData = data;
                    if (data != null && data != undefined && data.length > 0) {
                        var keysArray = Object.keys(data["0"]);
                        mlChartDetails.chartType = "datatable";
                        var keys = [];
                        if (keysArray.indexOf("Date") > -1) {
                            keys.push("Date");
                        }
                        if (keysArray.indexOf(xAxisName + " " + lang["mlreports.forecastvalues"]) > -1) {
                            keys.push(xAxisName + " " + lang["mlreports.forecastvalues"]);
                        }
                        for (var j1 = 0; j1 < keysArray.length; j1++) {
                            if (keys.indexOf(keysArray[j1]) === -1) {
                                keys.push(keysArray[j1]);
                            }
                        }
                        var header = "";
                        for (var j = 0; j < keys.length; j++) {
                            header = header + '<th class="text-center" data-field="' + keys[j] + '">' + keys[j] + '</th>'
                        }
                        $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                        if (noOfPeriodsForecasted != null && noOfPeriodsForecasted != undefined && noOfPeriodsForecasted != '') {
                            $('.mlcharttitle[data-num="' + chartnum + '"]').html(lang["mlreports.forecaston"] + " " + "<b>" + xAxisName + "</b>" + " " + lang["mlreports.fornext"] + " " + noOfPeriodsForecasted);
                            $('.mlcharttitle[data-num=' + chartnum + ']').attr("title", lang["mlreports.forecaston"] + " " + xAxisName + " " + lang["mlreports.fornext"] + " " + noOfPeriodsForecasted);
                        } else {
                            $('.mlcharttitle[data-num="' + chartnum + '"]').html(lang["mlreports.forecaston"] + " " + "<b>" + xAxisName + "</b>" + " " + lang["mlreports.fornext"] + " " + months + " " + lang["mlreports.months"] + " ");
                            $('.mlcharttitle[data-num=' + chartnum + ']').attr("title", lang["mlreports.forecaston"] + " " + xAxisName + " " + lang["mlreports.fornext"] + " " + months + " " + lang["mlreports.months"] + " ");
                        }
                        $("#mlchart" + chartnum).parent().removeClass("fullscreensample nopadding");
                        $("#mlchart" + chartnum).append('<div class="outliertable notifications-table-container" ><table class="table" id="outliertable' + chartnum + '" data-toggle="table" data-pagination="true" data-page-size="10" data-smart-display="true" data-search="true" data-pagination-v-align="bottom" data-search-align="right"><thead></thead><tbody></tbody></table></div>');
                        $("#mlchart" + chartnum + " table>thead").append('<tr>' + header + '</tr>');
                        $("#mlchart" + chartnum + " table").bootstrapTable({ height: 300, data: data });
                        ChartInfo.functions.updateMLChartDetails(chartnum, JSON.stringify(mlChartDetails));
                        $("#mlchart" + chartnum + " .outliertable .fixed-table-toolbar .search").addClass('pull-right')
                        $("#mlchart" + chartnum + " .outliertable .fixed-table-toolbar").prepend('<button class="btn btn-bird dobyexport dropdown-toggle pull-right downloadforcasttable marg-top-10 marg-bot-10 downloadforcasttable" id="dobyexportdownload" data-num=' + chartnum + ' tab-id="0" tab-name="" title="Download"><i class="fa fa-download"></i></button>');
                        $(".downloadforcasttable").click(function (e) {
                            that.downloadforcasttableascsv(e);
                        })
                        that.adjustMLChartHeight(chartnum, undefined, undefined, this.selectedModel);
                    }

                    /*$("#mlchart"+chartnum).bootstrapTable("destroy")
                    data = that.data["seasonalDataTable"];
                    if(data!=null && data!=undefined && data.length>0){
                        var keys = Object.keys(data["0"]);
                        mlChartDetails.chartType="datatable";
                        var header="";
                        for (var j = 0; j < keys.length; j++) {
                            header = header+'<th class="text-center" data-field='+keys[j]+'><b>'+keys[j]+'</b></th>'
                        }
                        $('.mlcharttitle[data-num="'+chartnum+'"]').empty();
                        $('.mlcharttitle[data-num="'+chartnum+'"]').text(that.data["seasonalDataTableTitle"][0]);
                        $("#mlchart"+chartnum).parent().removeClass("fullscreensample nopadding");
                        $("#mlchart"+chartnum).append('<div class="outliertable notifications-table-container" ><table class="table" id="outliertable'+chartnum+'" data-toggle="table" data-pagination="true" data-page-size="10" data-smart-display="true" data-search="true" data-pagination-v-align="bottom" data-search-align="right"><thead></thead><tbody></tbody></table></div>');
                        $("#mlchart"+chartnum+" table>thead").append('<tr>'+header+'</tr>');
                        $("#mlchart"+chartnum+" table").bootstrapTable({height:500,data:data});
                        ChartInfo.functions.updateMLChartDetails(chartnum, JSON.stringify(mlChartDetails));
                        that.adjustMLChartHeight(chartnum);
                    }*/
                } else if (chartPosition === 10) {
                    var timeSeriesData = data["forecastFinalDataArray"];
                    var yaxisnameArray = [];
                    if (timeSeriesData != undefined && timeSeriesData != null && timeSeriesData.length > 0) {
                        for (var v1 = 0; v1 < timeSeriesData.length; v1++) {
                            yaxisnameArray.push(timeSeriesData[v1]["displayname"]);
                        }
                    }
                    mlChartDetails.chartType = "linetimeseries";
                    MLCharts.charts.drawTimeSeries(data["forecastFinalDataArray"], dateColumn, dateColumn, "date",
                        yaxisnameArray, "mlchart" + chartnum, "linetimeseries", undefined, undefined, this.features, false, "1", chartSettings, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, true, undefined, true);
                    $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                    var titletext = "";
                    if (independentVariables != undefined && independentVariables.length > 0 && futureValues.length > 0) {
                        titletext = "for "
                        for (var j1 = 0; j1 < independentVariables.length; j1++) {
                            if (independentVariables.length - 2 == j1) {
                                titletext += independentVariables[j1] + " " + lang["mlreports.of"] + " " + futureValues[j1] + " " + lang["mlreports.unitsand"] + " "
                            } else {
                                titletext += independentVariables[j1] + " " + lang["mlreports.of"] + " " + futureValues[j1] + " " + lang["mlreports.units"] + ","
                            }
                        }
                        titletext = titletext.substring(0, titletext.length - 1);
                    }
                    if (noOfPeriodsForecasted != null && noOfPeriodsForecasted != undefined && noOfPeriodsForecasted != '') {
                        $('.mlcharttitle[data-num="' + chartnum + '"]').html(lang["mlreports.modelforecastedthe"] + " " + "<b>" + xAxisName + "</b>" + " " + lang["mlreports.fornext"] + " " + noOfPeriodsForecasted + " " + titletext);
                        $('.mlcharttitle[data-num=' + chartnum + ']').attr("title", lang["mlreports.modelforecastedthe"] + " " + xAxisName + " " + lang["mlreports.fornext"] + " " + noOfPeriodsForecasted + " " + titletext);
                    } else {
                        $('.mlcharttitle[data-num="' + chartnum + '"]').html(lang["mlreports.modelforecastedthe"] + " " + "<b>" + xAxisName + "</b>" + " " + lang["mlreports.fornext"] + " " + months + " " + lang["mlreports.months"] + " " + titletext);
                        $('.mlcharttitle[data-num=' + chartnum + ']').attr("title", lang["mlreports.modelforecastedthe"] + " " + xAxisName + " " + lang["mlreports.fornext"] + " " + months + " " + lang["mlreports.months"] + " " + titletext);
                    }
                    that.adjustMLChartHeight(chartnum, undefined, undefined, this.selectedModel);
                } else if (chartPosition === 11) {
                    $("#mlchart" + chartnum).empty();
                    $("#mlchart" + chartnum).append('<div class="mlheading-container" style="color: #58595b;font-weight: bold;font-size: 18px;"><ul class="nlg-list" style=""></ul></div>');
                    $("#mlchart" + chartnum + " .mlheading-container>ul.nlg-list").append(lang["mlreports.trendanalysison"] + " " + "<b>" + xAxisName + "</b>");
                    $("#mlchart" + chartnum).css("overflow", "visible");
                    $("#mlchart" + chartnum).parent().css("overflow", "visible");
                    $("#mlchart" + chartnum + " .mlheading-container").css("margin-top", "-25px");
                } else if (chartPosition === 12) {
                    $("#mlchart" + chartnum).empty();
                    $("#mlchart" + chartnum).append('<div class="mlheading-container" style="color: #2737a3;font-weight: bold;font-size: 26px;"><ul class="nlg-list" style=""></ul></div>');
                    $("#mlchart" + chartnum + " .mlheading-container>ul.nlg-list").append(lang["mlreports.forecastingmodel"]);
                } else if (chartPosition === 13) {
                    var titleData = "Explainable AI (Notes)";
                    $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                    $('.mlcharttitle[data-num="' + chartnum + '"]').text(titleData).attr("title", titleData);
                    $('.richtextcontrolschart' + chartnum).show();
                    var chartDetails = ChartInfo.functions.getMLChartDetails(chartnum) != undefined ? JSON.parse(ChartInfo.functions.getMLChartDetails(chartnum)) : {};
                    var pos = "min"
                    ReactDOM.render(<TextChart data={chartDetails} chartId={"mlchart" + chartnum} chartType={chartType} position={pos} features={that.features} isSharedReport={false} iscustomstoryboard={false} />, document.getElementById("mlchart" + chartnum + ""));
                    setTimeout(function () {
                        if (chartDetails != undefined && chartDetails['richtextcontent'] != undefined) {
                            $("#mlchart" + chartnum).empty();
                            $("#mlchart" + chartnum).html(decodeURIComponent(chartDetails['richtextcontent']));
                        }
                        $("#spinner" + chartnum).hide();
                        $("#spinner" + chartnum).hide();
                        $('.editrichtext[data-num=' + chartnum + ']').removeClass('active');
                        $('.saverichtext[data-num=' + chartnum + ']').removeClass('active');
                        $("#mlchart" + chartnum).css({
                            'display': 'block',
                            'overflow-y': 'auto',
                            'overflow-x': 'auto',
                            'word-wrap': 'break-word',
                            //'max-width': 'calc(100vw - 150px)'
                        });
                        $('.countcharacters').text("Characters Count Limit:" + (14000 - (window.$('#mlchart' + chartnum).summernote('code') || "").replace(/(<([^>]+)>)/ig, "").replace(/( )/, " ").length));
                        if ($('#mlchart' + chartnum).is(':empty')) {
                            $('#mlchart' + chartnum).hide();
                            $('.editrichtext[data-num=' + chartnum + ']').click();
                        } else {
                            window.$('#mlchart' + chartnum).summernote('destroy');
                        }
                        if (that.pos == "min" || that.pos == undefined) {
                            //$('.richtextcontrolschart' + that.chartnumber).hide();
                            var texttileheight = $("#tileview" + chartnum).height() - $(".maxanim3[data-num=" + chartnum + "]").height() - 14;
                            $("#mlchart" + chartnum).css({
                                'height': texttileheight
                            });
                        }
                        $("#spinner" + chartnum).hide();
                    }, 200);

                }
                $("#spinnerchart" + i).hide();
                i = incrementer
            }
        } else if (this.selectedModel == "logisticregression") {
            for (var i = 0; i < 5; i++) {
                $("#spinnerchart" + i).show();
                var mlChartDetails = JSON.parse(ChartInfo.functions.getMLChartDetails(i));
                var chartType = mlChartDetails.chartType;
                var chartnum = i;
                if (chartnum === 0) {
                    $("#mlchart" + chartnum).empty();
                    $("#mlchart" + chartnum).append('<div class="mlheading-container" style="color: #2737a3;font-weight: bold;font-size: 26px;"><ul class="nlg-list" style=""></ul></div>');
                    $("#mlchart" + chartnum + " .mlheading-container>ul.nlg-list").append(lang["mlreports.logisticregression"]);
                } else if (chartnum === 1) {
                    var data = that.data.accuracy;
                    MLCharts.charts.drawSmartSolidgaugeChart(data, "mlchart" + chartnum);
                    that.adjustMLChartHeight(chartnum);
                } else if (chartnum === 2) {
                    var data = this.data.keyinfluencers;
                    if (data != undefined && data.data != undefined) {
                        if (data == lang["mlreports.can'tcalculate"]) {
                            $('#mlchart' + chartnum).text(data);
                        } else {
                            var keyinfluencers = data.data;
                            var keyinfluencersCategories = data.categories;
                            var targetColumn = data.targetColumn;
                            var datatableData = this.data.dataframe;
                            var isTargetColumnCustomField = data.isTargetColumnCustomField;
                            MLCharts.charts.drawSmartBar(keyinfluencers, keyinfluencersCategories, targetColumn, targetColumn, "mlchart" + chartnum, "", this.features, this.reportId, this.datamodelId, this.broker, this.datedropdown, datatableData, this.selectedModel, isTargetColumnCustomField);
                        }
                    }
                } else if (chartnum === 3) {
                    var data = that.data.cmtable[0];
                    var categories = data.catagories;
                    var confusionmatrix = data.confusionmatrix;
                    var emptyarray = [];
                    if (data != undefined && data != null) {
                        MLCharts.charts.drawSmartHeatmap(confusionmatrix, categories, "mlchart" + chartnum, emptyarray, emptyarray, emptyarray);
                    }
                    $("#spinnerchart" + chartnum).hide();
                    that.adjustMLChartHeight(chartnum);
                } else if (chartnum === 4) {
                    $("#mlchart" + chartnum).bootstrapTable("destroy")
                    var data = that.data.datatable;
                    var keysArray = Object.keys(data["0"]);
                    var keys = [];
                    if (keysArray.indexOf("Actual") > -1) {
                        keys.push("Actual");
                    }
                    if (keysArray.indexOf("Predicted_values") > -1) {
                        keys.push("Predicted_values");
                    }
                    for (var j1 = 0; j1 < keysArray.length; j1++) {
                        if (keys.indexOf(keysArray[j1]) === -1) {
                            keys.push(keysArray[j1]);
                        }
                    }
                    var header = "";
                    for (var j = 0; j < keys.length; j++) {
                        header = header + '<th class="text-center" data-field=' + keys[j] + '><b>' + keys[j] + '</b></th>'
                    }
                    $("#mlchart" + chartnum).append('<div class="outliertable marg-top-10 notifications-table-container"><table class="table" id="outliertable' + chartnum + '" data-toggle="table" data-pagination="true" data-page-size="10" data-smart-display="true" data-search="true" data-pagination-v-align="bottom" data-search-align="right"><thead></thead><tbody></tbody></table></div>');
                    $("#mlchart" + chartnum + " table>thead").append('<tr>' + header + '</tr>');
                    $("#mlchart" + chartnum + " table").bootstrapTable({ height: 500, data: data });
                    $("#spinnerchart" + chartnum).hide();
                    that.adjustMLChartHeight(chartnum);
                }
                $("#spinnerchart" + chartnum).hide();
            }
        } else if (this.selectedModel == "automl") {
            for (var i = 0; i < 8; i++) {
                var incrementer = i;
                i = isFromStrybok ? that.options.tabid + "_" + i : i;
                $("#spinnerchart" + i).show();
                var mlChartDetails = JSON.parse(ChartInfo.functions.getMLChartDetails(i));
                var chartnum = i;
                var chartPosition = isFromStrybok ? parseInt(chartnum.split("_")[1]) : i
                var data = this.data;
                $("#spinnerchart" + chartnum).show();
                $("#spinner" + chartnum).show();
                if (chartPosition >= 0 && chartPosition <= 3) {
                    var metricData;
                    var firstTitle = "", secondTitle = "", thirdTitle = "", fourthTitle = "";
                    var firstChartKey = "", secondChartKey = "", thirdChartKey = "", fourthChartKey = "";
                    if (this.props.automlmodeltype != "numeric") {
                        firstTitle = "Log loss"; secondTitle = "Accuracy";
                        thirdTitle = "Precision"; fourthTitle = "Sensitivity";
                        firstChartKey = "logLoss"; secondChartKey = "accuracy";
                        thirdChartKey = "precision"; fourthChartKey = "sensitivity";
                    }
                    else {
                        firstTitle = "R2"; secondTitle = "RMSE";
                        thirdTitle = "MSE"; fourthTitle = "MAE";
                        firstChartKey = "r2"; secondChartKey = "rmse";
                        thirdChartKey = "mse"; fourthChartKey = "mae";
                    }
                    if (chartPosition == 0) {
                        metricData = data["metric_values"][this.props.automlSelectedModel][firstChartKey];
                        $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                        $('.mlcharttitle[data-num="' + chartnum + '"]').text(firstTitle);
                        $('.mlcharttitle[data-num=' + chartnum + ']').attr("title", firstTitle);
                    } else if (chartPosition == 1) {
                        metricData = data["metric_values"][this.props.automlSelectedModel][secondChartKey]
                        $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                        $('.mlcharttitle[data-num="' + chartnum + '"]').text(secondTitle);
                        $('.mlcharttitle[data-num=' + chartnum + ']').attr("title", secondTitle);
                    } else if (chartPosition == 2) {
                        metricData = data["metric_values"][this.props.automlSelectedModel][thirdChartKey]
                        $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                        $('.mlcharttitle[data-num="' + chartnum + '"]').text(thirdTitle);
                        $('.mlcharttitle[data-num=' + chartnum + ']').attr("title", thirdTitle);
                    } else if (chartPosition == 3) {
                        metricData = data["metric_values"][this.props.automlSelectedModel][fourthChartKey]
                        $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                        $('.mlcharttitle[data-num="' + chartnum + '"]').text(fourthTitle);
                        $('.mlcharttitle[data-num=' + chartnum + ']').attr("title", fourthTitle);
                    }
                    if (this.props.automlmodeltype === "numeric" && chartPosition == 0) {
                        var metricdataarray = [];
                        metricdataarray.push(Number((metricData).toFixed(2)))
                        MLCharts.charts.drawSmartSolidgaugeChart(metricdataarray, "mlchart" + chartnum);
                    } else {
                        MLCharts.charts.drawMetricChart(metricData, "mlchart" + chartnum, chartType, "", this.pos, "", "", chartnum, 0, "", this.metricprefix, this.metricsuffix, this.broker);
                    }
                    $("#spinnerchart" + chartnum).hide();
                    that.adjustMLChartHeight(chartnum);
                } else if (chartPosition == 4) {
                    if (this.props.automlmodeltype === "numeric") {
                        var data = this.data["ActualPredicted_values"][this.props.automlSelectedModel];
                        var seriesArray = [];
                        var seriesObj = {};
                        seriesObj["color"] = 'rgba(210, 43, 228, 0.5)';
                        seriesObj["data"] = data;
                        seriesObj["turboThreshold"] = 500000;
                        seriesArray.push(seriesObj);
                        MLCharts.charts.drawSmartScatterChart(seriesArray, "mlchart" + chartnum, "Predicted Value", 'Actual Value', undefined, undefined, this.selectedModel, true);
                        $('.mltooltip[data-num=' + chartnum + ']').removeClass("hide");
                        $('.mltooltip[data-num=' + chartnum + ']').attr("title", lang["mlreports.predictiveaccuracy"]);
                        var titleData = lang["mlreports.actualbypredictd"];
                        $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                        $('.mlcharttitle[data-num="' + chartnum + '"]').text(titleData).attr("title", titleData);
                        $("#spinnerchart" + chartnum).hide();
                        that.adjustMLChartHeight(chartnum);
                    } else {
                        var rocCurvedata = this.data["roccurve_values"][this.props.automlSelectedModel];
                        var aucMetricdata = this.data["aucmetric_values"][this.props.automlSelectedModel];
                        var seriesArray = [];
                        var count = 1;
                        for (var key in rocCurvedata) {
                            var seriesObj = {};
                            seriesObj["color"] = MLCharts.colorThemesArray[0][count]
                            seriesObj["data"] = rocCurvedata[key];
                            seriesObj["name"] = key;
                            seriesObj["turboThreshold"] = 500000;
                            seriesArray.push(seriesObj);
                            count++;
                        }
                        MLCharts.charts.drawRocCurveChart(seriesArray, "mlchart" + chartnum, "True Positive Rate", 'False Postive Rate', aucMetricdata);
                        $("#spinnerchart" + chartnum).hide();
                        that.adjustMLChartHeight(chartnum);
                        var titleData = "AUC -ROC Curve";
                        $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                        $('.mlcharttitle[data-num="' + chartnum + '"]').text(titleData).attr("title", titleData);
                    }
                } else if (chartPosition == 5) {
                    var keyinfluencerdata = this.data["keyinfluencer_values"][this.props.automlSelectedModel];
                    if (keyinfluencerdata != undefined && keyinfluencerdata != null) {
                        MLCharts.charts.drawColumnBar(keyinfluencerdata.data, keyinfluencerdata.categories, "mlchart" + chartnum, "", false, "bar");
                    }
                    $("#spinnerchart" + chartnum).hide();
                    that.adjustMLChartHeight(chartnum);
                    titleData = "Key Influencers"
                    $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                    $('.mlcharttitle[data-num="' + chartnum + '"]').text(titleData).attr("title", titleData);
                } else if (chartPosition == 6) {
                    if (this.props.automlmodeltype === "numeric") {
                        var data = that.data["Residual_values"][this.props.automlSelectedModel];
                        MLCharts.charts.drawHistogramChart(data, "mlchart" + chartnum);
                        $("#spinnerchart" + chartnum).hide();
                        that.adjustMLChartHeight(chartnum);
                        var titleData = "Residual Analysis";
                        $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                        $('.mlcharttitle[data-num="' + chartnum + '"]').text(titleData).attr("title", titleData);
                    } else {
                        var data = that.data["cmtable_values"][this.props.automlSelectedModel][0];
                        var categories = data.catagories;
                        var confusionmatrix = data.confusionmatrix;
                        var emptyarray = data.emptyarray;
                        var precisionarray = data.precisionarray;
                        var recallarray = data.recallarray;
                        if (data != undefined && data != null) {
                            MLCharts.charts.drawSmartHeatmap(confusionmatrix, categories, "mlchart" + chartnum, emptyarray, precisionarray, recallarray);
                        }
                        $("#spinnerchart" + chartnum).hide();
                        that.adjustMLChartHeight(chartnum);
                        titleData = lang["mlreports.confusionmatrix"]
                        $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                        $('.mlcharttitle[data-num="' + chartnum + '"]').text(titleData).attr("title", titleData);
                    }
                } else if (chartPosition == 7) {
                    var titleData = "Explainable AI (Notes)";
                    $('.mlcharttitle[data-num="' + chartnum + '"]').empty();
                    $('.mlcharttitle[data-num="' + chartnum + '"]').text(titleData).attr("title", titleData);
                    $('.richtextcontrolschart' + chartnum).show();
                    var chartDetails = ChartInfo.functions.getMLChartDetails(chartnum) != undefined ? JSON.parse(ChartInfo.functions.getMLChartDetails(chartnum)) : {};
                    var pos = "min"
                    ReactDOM.render(<TextChart data={chartDetails} chartId={"mlchart" + chartnum} chartType={chartType} position={pos} features={that.features} isSharedReport={false} iscustomstoryboard={false} />, document.getElementById("mlchart" + chartnum + ""));
                    setTimeout(function () {
                        if (chartDetails != undefined && chartDetails['richtextcontent'] != undefined) {
                            $("#mlchart" + chartnum).empty();
                            $("#mlchart" + chartnum).html(decodeURIComponent(chartDetails['richtextcontent']));
                        }
                        $("#spinner" + chartnum).hide();
                        $("#spinner" + chartnum).hide();
                        $('.editrichtext[data-num=' + chartnum + ']').removeClass('active');
                        $('.saverichtext[data-num=' + chartnum + ']').removeClass('active');
                        $("#mlchart" + chartnum).css({
                            'display': 'block',
                            'overflow-y': 'auto',
                            'overflow-x': 'auto',
                            'word-wrap': 'break-word',
                            //'max-width': 'calc(100vw - 150px)'
                        });
                        $('.countcharacters').text("Characters Count Limit:" + (14000 - (window.$('#mlchart' + chartnum).summernote('code') || "").replace(/(<([^>]+)>)/ig, "").replace(/( )/, " ").length));
                        if ($('#mlchart' + chartnum).is(':empty')) {
                            $('#mlchart' + chartnum).hide();
                            $('.editrichtext[data-num=' + chartnum + ']').click();
                        } else {
                            window.$('#mlchart' + chartnum).summernote('destroy');
                        }
                        if (that.pos == "min" || that.pos == undefined) {
                            //$('.richtextcontrolschart' + that.chartnumber).hide();
                            var texttileheight = $("#tileview" + chartnum).height() - $(".maxanim3[data-num=" + chartnum + "]").height() - 14;
                            $("#mlchart" + chartnum).css({
                                'height': texttileheight
                            });
                        }
                        $("#spinner" + chartnum).hide();
                    }, 200);
                }
                i = incrementer
            }
        }
        //$('.runmodelbtn').removeClass("disabled");
        //$('.runmodelbtn').html('Run Model');
        setTimeout(function () {
            $('[id=main]').each(function () {
                $('[id="' + this.id + '"]:gt(0)').remove();
            });
            $(".mlstoryboardtabs.fill-height").removeClass("loadingmlreport");
            $(".spinner").hide();
        }, 2000);
        return (
            <React.Fragment />
        )
    }
    changeDateDropdown = (e) => {
        var $this = e.currentTarget;
        var chartnumber = $($this).parent().attr("data-num");
        var selectedDate = $('.datedropdown5 .datelistformlchart').val();
        var dateHistogramXaxisData = [];
        _.each(this.datedropdown, function (value) {
            if (value.columnName == selectedDate) {
                dateHistogramXaxisData = [{ columnName: value.columnName, displayName: value.columnDisplayName, dataType: "date" }];
            }
        })
        ChartInfo.functions.setDateHistogramXaxisDataForML(dateHistogramXaxisData);
        $("#spinnerchart" + chartnumber).show()
        PubSub.publish("drawDateChartForML", chartnumber);
    }
    histogramcontainer = (e) => {
        var $this = e.currentTarget;
        var charttypehisto = $($this).attr('chart-type');
        var chartnohisto = $($this).attr('chart-no');
        chartnohisto = chartnohisto.replace("ml", "")
        $($this).find('.histogram').toggleClass('show');
        $($this).parent().toggleClass('displayinitial');
        var chartData = ChartInfo.functions.getChartDetails(chartnohisto);
        var currentSelectedValue = ChartInfo.functions.getDateHistogramStateForML(chartnohisto);
        if (chartData != undefined && JSON.parse(chartData)["datehistogramstate"] != undefined && currentSelectedValue == undefined) {
            var selectedValue = JSON.parse(chartData)["datehistogramstate"];
            setTimeout(function () {
                $('#mlchart' + chartnohisto).find('.drawhistogram[data-val="' + selectedValue + '"]').addClass("active");
                $(".showtrending[data-num=" + chartnohisto + "]").hide();
            }, 2000);
        }
    }
    drawHistogram = (e) => {
        var $this = e.currentTarget;
        var chartId = $($this).attr('chart-id');
        $("#spinnerchart" + chartId).show();
        var selectedValue = $($this).attr('data-val');
        if (selectedValue == "reset") {
            ChartInfo.functions.setDateHistogramStatusForML(chartId, false);
            var dateHistogramXaxisData = [{ columnName: $($this).attr('column-name'), displayName: $($this).attr('column-display-name'), dataType: $($this).attr('column-type') }];
            ChartInfo.functions.setDateHistogramXaxisDataForML(dateHistogramXaxisData);
            ChartInfo.functions.setDateHistogramStateForML(selectedValue, chartId);
            PubSub.publish("drawDateChartForML", chartId);
            setTimeout(function () {
                $('#mlchart' + chartId).find('.drawhistogram[data-val="' + selectedValue + '"]').addClass("active");
            }, 2000);
        } else {
            ChartInfo.functions.setDateHistogramStatusForML(chartId, true);
            var dateHistogramXaxisData = [{ columnName: $($this).attr('column-name'), displayName: $($this).attr('column-display-name'), dataType: $($this).attr('column-type') }];
            ChartInfo.functions.setDateHistogramXaxisDataForML(dateHistogramXaxisData);
            ChartInfo.functions.setDateHistogramStateForML(selectedValue, chartId);
            PubSub.publish("drawDateChartForML", chartId);
            setTimeout(function () {
                $('#mlchart' + chartId).find('.drawhistogram[data-val="' + selectedValue + '"]').addClass("active");
                $(".showtrending[data-num=" + chartId + "]").hide();
            }, 2000);
        }
    }
    plotLinearRegressionCharts(chartcount) {
        for (var i = 0; i <= 15; i++) {
            var plotDateChart = true;
            var chartDetails = {};
            var gridwidth;
            var gridheight;
            var x;
            var y;
            var title = "";
            if (i === 0) {
                x = 0;
                y = 0;
                gridwidth = 4;
                gridheight = 3;
                chartDetails["chartType"] = "metric";
                title = lang["mlreports.quality"]
            } else if (i === 1) {
                x = 0;
                y = 3;
                gridwidth = 4;
                gridheight = 5;
                chartDetails["chartType"] = "scatter";
                title = lang["mlreports.actualbypredictd"]
            } else if (i === 2) {
                x = 8;
                y = 0;
                gridwidth = 4;
                gridheight = 8;
                chartDetails["chartType"] = "nlgdata";
            } else if (i === 3) {
                x = 4;
                y = 0;
                gridwidth = 4;
                gridheight = 8;
                chartDetails["chartType"] = "bar";
                title = lang["mlreports.Keyinfluencers"]
            } else if (i === 4) {
                if (this.datedropdown.length > 0) {
                    x = 0;
                    y = 10;
                    gridwidth = 4;
                    gridheight = 7;
                    chartDetails["chartType"] = "virticalbar";
                    title = ""
                } else {
                    x = 0;
                    y = 10;
                    gridwidth = 8;
                    gridheight = 7;
                    chartDetails["chartType"] = "virticalbar";
                    title = ""
                }
            }
            else if (i === 5) {
                if (this.datedropdown.length > 0) {
                    x = 4;
                    y = 10;
                    gridwidth = 4;
                    gridheight = 7;
                    chartDetails["chartType"] = "line";
                } else {
                    plotDateChart = false;
                }
            } else if (i == 6) {
                x = 8;
                y = 10;
                gridwidth = 4;
                gridheight = 7;
                chartDetails["chartType"] = "nlgdata";
            } else if (i === 7) {
                x = 0;
                y = 19;
                gridwidth = 6;
                gridheight = 6;
                chartDetails["chartType"] = "";
            } else if (i === 8) {
                x = 6;
                y = 19;
                gridwidth = 6;
                gridheight = 6;
                chartDetails["chartType"] = "";
            } else if (i === 9) {
                x = 0;
                y = 25;
                gridwidth = 6;
                gridheight = 6;
                chartDetails["chartType"] = "";
            } else if (i === 10) {
                x = 6;
                y = 25;
                gridwidth = 6;
                gridheight = 6;
                chartDetails["chartType"] = "";
            } else if (i == 11) {
                x = 0;
                y = 31;
                gridwidth = 12;
                gridheight = 7;
                chartDetails["chartType"] = "datatable";
                title = lang["mlreports.predictvalues"];
            } else if (i == 12) {
                x = 0;
                y = 8;
                gridwidth = 12;
                gridheight = 1;
                chartDetails["chartType"] = "";
            } else if (i == 13) {
                x = 0;
                y = 17;
                gridwidth = 12;
                gridheight = 1;
                chartDetails["chartType"] = "";
            } else if (i == 14) {
                x = 0;
                y = 0;
                gridwidth = 12;
                gridheight = 2;
                chartDetails["chartType"] = "";
            } else if (i == 15) {
                x = 0;
                y = 36;
                gridwidth = 12;
                gridheight = 3;
                chartDetails["chartType"] = "";
            }/*else if(i==15){
                x=0;
                y=39;
                gridwidth=9;
                gridheight=6;
                chartDetails["chartType"] = "waterfall";
                title = "Simulation"
            }else if(i==16){
                x=10;
                y=39;
                gridwidth=3;
                gridheight=6;
                chartDetails["chartType"] = "metric";
                title = "Expected"
            }else if(i==17){
                x=0;
                y=45;
                gridwidth=12;
                gridheight=6;
                chartDetails["chartType"] = "";
            }  */
            if (plotDateChart) {
                var charttype = chartDetails["chartType"];
                ChartInfo.functions.updateMLChartDetails(i, JSON.stringify(chartDetails));
                var chartnumber = i;
                if (chartnumber != 14) {
                    var gridInfoObj = {};
                    gridInfoObj["x"] = x;
                    gridInfoObj["y"] = y;
                    gridInfoObj["gridwidth"] = gridwidth;
                    gridInfoObj["gridheight"] = gridheight;
                    gridInfoObj["chartnumber"] = chartnumber;
                    this.state.gridInfo.push(gridInfoObj);
                    this.state.grids.push(<ViewMLReportBodyTemplate chartid={"mlchart" + chartnumber} chartitle={""}
                        chartnumber={chartnumber} chartType={charttype} title={title} dateDropDown={this.datedropdown}
                        x={x} y={y} gridwidth={gridwidth} gridheight={gridheight}
                        editrichtext={this.editrichtext} cancelrichtext={this.cancelrichtext} applyTextChart={this.applyTextChart} />);
                }
            }
        }
    }
    plotClusteringModelCharts() {
        for (var i = 0; i < 13; i++) {
            var chartDetails = {};
            var gridwidth;
            var gridheight;
            var x;
            var y;
            var title = "";
            if (i === 0) {
                x = 0;
                y = 0;
                gridwidth = 4;
                gridheight = 7;
                chartDetails["chartType"] = "scatter";
                title = lang["mlreports.pc1vspc2"]
            } else if (i === 1) {
                x = 8;
                y = 0;
                gridwidth = 4;
                gridheight = 7;
                chartDetails["chartType"] = "nlgdata";
            } else if (i === 2) {
                x = 0;
                y = 7;
                gridwidth = 6;
                gridheight = 6;
                chartDetails["chartType"] = "";
                title = ""
            } else if (i === 3) {
                x = 6;
                y = 7;
                gridwidth = 6;
                gridheight = 6;
                chartDetails["chartType"] = "";
                title = ""
            } else if (i === 4) {
                x = 0;
                y = 13;
                gridwidth = 6;
                gridheight = 6;
                chartDetails["chartType"] = "";
                title = ""
            }
            else if (i === 5) {
                x = 6;
                y = 13;
                gridwidth = 6;
                gridheight = 6;
                chartDetails["chartType"] = "";
            } else if (i === 6) {
                x = 0;
                y = 19;
                gridwidth = 6;
                gridheight = 6;
                chartDetails["chartType"] = "";
            } else if (i === 7) {
                x = 6;
                y = 19;
                gridwidth = 6;
                gridheight = 6;
                chartDetails["chartType"] = "";
            } else if (i === 8) {
                x = 4
                y = 2;
                gridwidth = 4;
                gridheight = 4;
                chartDetails["chartType"] = "bar";
                title = lang["mlreports.keydifferentiators"]
            } else if (i == 9) {
                x = 0;
                y = 8;
                gridwidth = 12;
                gridheight = 1;
                chartDetails["chartType"] = "nlgdata";
            } else if (i == 10) {
                x = 0;
                y = 0;
                gridwidth = 12;
                gridheight = 2;
                chartDetails["chartType"] = "";
            } else if (i == 11) {
                x = 4;
                y = 6;
                gridwidth = 4;
                gridheight = 3;
                chartDetails["chartType"] = "nlgdata";
            } else if (i === 12) {
                x = 0;
                y = 26;
                gridwidth = 12;
                gridheight = 3;
            }
            var charttype = chartDetails["chartType"];
            chartDetails["selectedModel"] = this.selectedModel;
            ChartInfo.functions.updateMLChartDetails(i, JSON.stringify(chartDetails));
            var chartnumber = i;
            if (chartnumber != 10) {
                var gridInfoObj = {};
                gridInfoObj["x"] = x;
                gridInfoObj["y"] = y;
                gridInfoObj["gridwidth"] = gridwidth;
                gridInfoObj["gridheight"] = gridheight;
                gridInfoObj["chartnumber"] = chartnumber;
                this.state.gridInfo.push(gridInfoObj);
                this.state.grids.push(<ViewMLReportBodyTemplate chartid={"mlchart" + chartnumber} chartitle={""}
                    chartnumber={chartnumber} chartType={charttype} title={title} dateDropDown={this.datedropdown}
                    x={x} y={y} gridwidth={gridwidth} gridheight={gridheight}
                    editrichtext={this.editrichtext} cancelrichtext={this.cancelrichtext} applyTextChart={this.applyTextChart} />);
            }
        }
    }

    plotDecisionTreeCharts() {
        for (var i = 0; i <= 6; i++) {
            var chartDetails = {};
            var gridwidth;
            var gridheight;
            var x;
            var y;
            var title = "";
            if (i === 0) {
                x = 0;
                y = 0;
                gridwidth = 3;
                gridheight = 3;
                chartDetails["chartType"] = "metric";
                title = lang["mlreports.accuracy"]
            } else if (i === 1) {
                x = 0;
                y = 6;
                gridwidth = 12;
                gridheight = 11;
                chartDetails["chartType"] = "decisiontree";
                title = lang["mlreports.descisiontree"]
            } else if (i === 2) {
                x = 0;
                y = 3;
                gridwidth = 3;
                gridheight = 3;
                // chartDetails["chartType"] = "bar";
                // title = lang["mlreports.variableimportance"]
                chartDetails["chartType"] = "metric";
                title = "F2 Score"
            } else if (i === 3) {
                x = 3;
                y = 0;
                gridwidth = 4;
                gridheight = 6;
                chartDetails["chartType"] = "heatmap";
                title = lang["mlreports.confusionmatrix"]
            } else if (i == 4) {
                x = 0;
                y = 0;
                gridwidth = 12;
                gridheight = 2;
                chartDetails["chartType"] = "";
            } else if (i === 5) {
                x = 8;
                y = 0;
                gridwidth = 5;
                gridheight = 6;
                chartDetails["chartType"] = "";
                title = "ROC Curve"
            } else if (i == 6) {
                x = 0;
                y = 17;
                gridwidth = 12;
                gridheight = 3;
                chartDetails["chartType"] = "";
            }
            var charttype = chartDetails["chartType"];
            ChartInfo.functions.updateMLChartDetails(i, JSON.stringify(chartDetails));
            var chartnumber = i;
            if (chartnumber != 4) {
                var gridInfoObj = {};
                gridInfoObj["x"] = x;
                gridInfoObj["y"] = y;
                gridInfoObj["gridwidth"] = gridwidth;
                gridInfoObj["gridheight"] = gridheight;
                gridInfoObj["chartnumber"] = chartnumber;
                this.state.gridInfo.push(gridInfoObj);
                this.state.grids.push(<ViewMLReportBodyTemplate chartid={"mlchart" + chartnumber} chartitle={""}
                    chartnumber={chartnumber} chartType={charttype} title={title} dateDropDown={this.datedropdown}
                    x={x} y={y} gridwidth={gridwidth} gridheight={gridheight}
                    editrichtext={this.editrichtext} cancelrichtext={this.cancelrichtext} applyTextChart={this.applyTextChart} />);
            }
        }
    }
    plotForecastingModelCharts() {

        for (var i = 0; i <= 13; i++) {
            var chartDetails = {};
            var gridwidth;
            var gridheight;
            var x;
            var y;
            var title = "";
            if (i === 0) {
                x = 0;
                y = 13;
                gridwidth = 6;
                gridheight = 10;
            } else if (i === 1) {
                x = 8;
                y = 8;
                gridwidth = 2;
                gridheight = 2;
            } else if (i === 2) {
                x = 10;
                y = 8;
                gridwidth = 2;
                gridheight = 2;
            } else if (i === 3) {
                x = 8;
                y = 10;
                gridwidth = 2;
                gridheight = 2;
            } else if (i === 4) {
                x = 10;
                y = 10;
                gridwidth = 2;
                gridheight = 2;
            } else if (i === 5) {
                x = 9;
                y = 13;
                gridwidth = 3;
                gridheight = 5;
                chartDetails["chartType"] = "nlgdata";
            } else if (i === 6) {
                x = 6;
                y = 13;
                gridwidth = 3;
                gridheight = 5;
            } else if (i === 7) {
                x = 6;
                y = 18;
                gridwidth = 3;
                gridheight = 5;
            }
            else if (i === 8) {
                x = 9;
                y = 18;
                gridwidth = 3;
                gridheight = 5;
            } else if (i === 9) {
                x = 8;
                y = 0;
                gridwidth = 4;
                gridheight = 6;
                chartDetails["chartType"] = "datatable";
            } else if (i === 10) {
                x = 0;
                y = 2;
                gridwidth = 8;
                gridheight = 10;
            } else if (i === 11) {
                x = 0;
                y = 12;
                gridwidth = 12;
                gridheight = 1;
                chartDetails["chartType"] = "";
            } else if (i === 12) {
                x = 0;
                y = 0;
                gridwidth = 12;
                gridheight = 2;
                chartDetails["chartType"] = "";
            } else if (i === 13) {
                x = 0;
                y = 21;
                gridwidth = 12;
                gridheight = 3;
                chartDetails["chartType"] = "";
            }
            var charttype = chartDetails["chartType"];
            ChartInfo.functions.updateMLChartDetails(i, JSON.stringify(chartDetails));
            var chartnumber = i;
            if (chartnumber != 12) {
                var gridInfoObj = {};
                gridInfoObj["x"] = x;
                gridInfoObj["y"] = y;
                gridInfoObj["gridwidth"] = gridwidth;
                gridInfoObj["gridheight"] = gridheight;
                gridInfoObj["chartnumber"] = chartnumber;
                this.state.gridInfo.push(gridInfoObj);
                this.state.grids.push(<ViewMLReportBodyTemplate chartid={"mlchart" + chartnumber} chartitle={""}
                    chartnumber={chartnumber} chartType={charttype} title={title} dateDropDown={this.datedropdown}
                    x={x} y={y} gridwidth={gridwidth} gridheight={gridheight}
                    editrichtext={this.editrichtext} cancelrichtext={this.cancelrichtext} applyTextChart={this.applyTextChart} />);
            }
        }

    }
    plotAutoMLModelCharts(automlmodeltype) {
        for (var i = 0; i < 8; i++) {
            var chartDetails = {};
            var gridwidth;
            var gridheight;
            var x;
            var y;
            var title = "";
            if (i === 0) {
                x = 0;
                y = 0;
                gridwidth = 2;
                gridheight = 2;
            } else if (i === 1) {
                x = 2;
                y = 0;
                gridwidth = 2;
                gridheight = 2;
            } else if (i === 2) {
                x = 0;
                y = 2;
                gridwidth = 2;
                gridheight = 2;
            } else if (i === 3) {
                x = 2;
                y = 2;
                gridwidth = 2;
                gridheight = 2;
            } else if (i === 4) {
                x = 4;
                y = 0;
                gridwidth = 8;
                gridheight = 4;
            } else if (i === 5) {
                x = 0;
                y = 4;
                gridwidth = 6;
                gridheight = 5;
            } else if (i === 6) {
                x = 6;
                y = 4;
                gridwidth = 6;
                gridheight = 5;
            } else if (i === 7) {
                x = 0;
                y = 9;
                gridwidth = 12;
                gridheight = 3;
            }
            var charttype = chartDetails["chartType"];
            ChartInfo.functions.updateMLChartDetails(i, JSON.stringify(chartDetails));
            var chartnumber = i;
            if (chartnumber != 12) {
                var gridInfoObj = {};
                gridInfoObj["x"] = x;
                gridInfoObj["y"] = y;
                gridInfoObj["gridwidth"] = gridwidth;
                gridInfoObj["gridheight"] = gridheight;
                gridInfoObj["chartnumber"] = chartnumber;
                this.state.gridInfo.push(gridInfoObj);
                this.state.grids.push(<ViewMLReportBodyTemplate chartid={"mlchart" + chartnumber} chartitle={""}
                    chartnumber={chartnumber} chartType={charttype} title={title} dateDropDown={this.datedropdown}
                    x={x} y={y} gridwidth={gridwidth} gridheight={gridheight}
                    editrichtext={this.editrichtext} cancelrichtext={this.cancelrichtext} applyTextChart={this.applyTextChart} />);
            }
        }
    }
    plotLogisticRegressionCharts() {
        for (var i = 0; i < 5; i++) {
            var chartDetails = {};
            var gridwidth;
            var gridheight;
            var x;
            var y;
            var title = "";
            if (i == 0) {
                x = 0;
                y = 0;
                gridwidth = 12;
                gridheight = 2;
                chartDetails["chartType"] = "";
            } else if (i === 1) {
                x = 0;
                y = 2;
                gridwidth = 3;
                gridheight = 6;
                chartDetails["chartType"] = "metric";
                title = lang["mlreports.accuracy"]
            } else if (i === 2) {
                x = 3;
                y = 8;
                gridwidth = 5;
                gridheight = 6;
                chartDetails["chartType"] = "bar";
                title = lang["mlreports.variableimportance"]
            } else if (i === 3) {
                x = 8;
                y = 8;
                gridwidth = 4;
                gridheight = 6;
                chartDetails["chartType"] = "heatmap";
                title = lang["mlreports.confusionmatrix"]
            } else if (i === 4) {
                x = 0;
                y = 14;
                gridwidth = 12;
                gridheight = 7;
                chartDetails["chartType"] = "datatable";
                title = lang["mlreports.predictvalues"];
            }
            var charttype = chartDetails["chartType"];
            ChartInfo.functions.updateMLChartDetails(i, JSON.stringify(chartDetails));
            var chartnumber = i;
            // if (this.state.grid != null) {
            var gridInfoObj = {};
            gridInfoObj["x"] = x;
            gridInfoObj["y"] = y;
            gridInfoObj["gridwidth"] = gridwidth;
            gridInfoObj["gridheight"] = gridheight;
            gridInfoObj["chartnumber"] = chartnumber;
            this.state.gridInfo.push(gridInfoObj);
            this.state.grids.push(<ViewMLReportBodyTemplate chartid={"mlchart" + chartnumber} chartitle={""}
                chartnumber={chartnumber} chartType={charttype} title={title} dateDropDown={this.datedropdown}
                x={x} y={y} gridwidth={gridwidth} gridheight={gridheight}
                editrichtext={this.editrichtext} cancelrichtext={this.cancelrichtext} applyTextChart={this.applyTextChart} />);
            // }
        }
    }
    plotCoOccuranceModelCharts() {
        var textColumnsLength = $(".mltextcolumns .singleliitems").length;
        /*for (var i = 0; i <= textColumnsLength * 3 + 1; i++) {
            var chartDetails = {};
            var gridwidth;
            var gridheight;
            var x;
            var y;
            var title = "";
            if (i === textColumnsLength * 3 + 1) {
                x = 0;
                y = (textColumnsLength == 1 ? 9 : (textColumnsLength == 2 ? 16 : (textColumnsLength == 3 ? 23 : 16)));
                gridwidth = 12;
                gridheight = 3;
            } else {
                if (i === 0) {
                    x = 0;
                    y = 0;
                    gridwidth = 12;
                    gridheight = 2;
                    //title = "Text Analysis Model Dashboard";
                }
                else if (i === 1) {
                    x = 0;
                    y = 2;
                    gridwidth = 4;
                    gridheight = 7;
                } else if (i === 2) {
                    x = 4;
                    y = 2;
                    gridwidth = 4;
                    gridheight = 7;
                } else if (i === 3) {
                    x = 8;
                    y = 2;
                    gridwidth = 4;
                    gridheight = 7;
                } else if (i === 4) {
                    x = 0;
                    y = 9;
                    gridwidth = 4;
                    gridheight = 7;
                } else if (i === 5) {
                    x = 4;
                    y = 9;
                    gridwidth = 4;
                    gridheight = 7;
                } else if (i === 6) {
                    x = 8;
                    y = 9;
                    gridwidth = 4;
                    gridheight = 7;
                } else if (i === 7) {
                    x = 0;
                    y = 16;
                    gridwidth = 4;
                    gridheight = 7;
                } else if (i === 8) {
                    x = 4;
                    y = 16;
                    gridwidth = 4;
                    gridheight = 7;
                } else if (i === 9) {
                    x = 8;
                    y = 16;
                    gridwidth = 4;
                    gridheight = 7;
                }
            }
            var chartnumber = i;
            var charttype = chartDetails["chartType"];
            chartDetails["selectedModel"] = this.selectedModel;
            ChartInfo.functions.updateMLChartDetails(i, JSON.stringify(chartDetails));
            var gridInfoObj = {};
            gridInfoObj["x"] = x;
            gridInfoObj["y"] = y;
            gridInfoObj["gridwidth"] = gridwidth;
            gridInfoObj["gridheight"] = gridheight;
            gridInfoObj["chartnumber"] = chartnumber;
            if (chartnumber != 0) {
                this.state.gridInfo.push(gridInfoObj);
                this.state.grids.push(<ViewMLReportBodyTemplate chartid={"mlchart" + chartnumber} chartitle={""}
                    chartnumber={chartnumber} chartType={charttype} title={title} dateDropDown={this.datedropdown}
                    x={x} y={y} gridwidth={gridwidth} gridheight={gridheight}
                    editrichtext={this.editrichtext} cancelrichtext={this.cancelrichtext} applyTextChart={this.applyTextChart} />);
            }
        }*/
        for (let i = 0; i < 7; i++) {
            var chartDetails = {};
            var gridwidth;
            var gridheight;
            var x;
            var y;
            var title = "";
            if (i == 0) {
                x = 0;
                y = 0;
                gridwidth = 3;
                gridheight = 4;
                chartDetails["chartType"] = "pie";
            } else if (i == 1) {
                x = 0;
                y = 4;
                gridwidth = 3;
                gridheight = 4;
                chartDetails["chartType"] = "pie";
            } else if (i == 2) {
                x = 3;
                y = 0;
                gridwidth = 5;
                gridheight = 8;
                chartDetails["chartType"] = "";
            } else if (i == 3) {
                x = 8;
                y = 0;
                gridwidth = 4;
                gridheight = 8;
                chartDetails["chartType"] = "";
            } else if (i == 6) {
                x = 0;
                y = 14;
                gridwidth = 12;
                gridheight = 4;
                chartDetails["chartType"] = "";
            } else if (i == 5) {
                x = 0;
                y = 8;
                gridwidth = 6;
                gridheight = 6;
                chartDetails["chartType"] = "";
            } else if (i == 4) {
                x = 6;
                y = 8;
                gridwidth = 6;
                gridheight = 6;
                chartDetails["chartType"] = "";
            }
            var chartnumber = i;
            var charttype = chartDetails["chartType"];
            chartDetails["selectedModel"] = this.selectedModel;
            ChartInfo.functions.updateMLChartDetails(i, JSON.stringify(chartDetails));
            var gridInfoObj = {};
            gridInfoObj["x"] = x;
            gridInfoObj["y"] = y;
            gridInfoObj["gridwidth"] = gridwidth;
            gridInfoObj["gridheight"] = gridheight;
            gridInfoObj["chartnumber"] = chartnumber;
            // if (chartnumber != 0) {
            this.state.gridInfo.push(gridInfoObj);
            this.state.grids.push(<ViewMLReportBodyTemplate chartid={"mlchart" + chartnumber} chartitle={""}
                chartnumber={chartnumber} chartType={charttype} title={title} dateDropDown={this.datedropdown}
                x={x} y={y} gridwidth={gridwidth} gridheight={gridheight}
                editrichtext={this.editrichtext} cancelrichtext={this.cancelrichtext} applyTextChart={this.applyTextChart} />);
            // }
        }
    }
    applyTextChart(e) {
        var $this = e.currentTarget;
        var currentchartnumber = $($this).attr('data-num');
        $('.editrichtext[data-num=' + currentchartnumber + ']').removeClass('active');
        $('.saverichtext[data-num=' + currentchartnumber + ']').addClass('active');
        $(".richtextcontrolschartinfo" + currentchartnumber).hide();
        var chartData = ChartInfo.functions.getMLChartDetails(currentchartnumber);
        var data = JSON.parse(chartData);
        data['richtextcontent'] = encodeURIComponent(window.$('#mlchart' + currentchartnumber).summernote('code'));
        ChartInfo.functions.updateMLChartDetails(currentchartnumber, JSON.stringify(data));
        window.$('#mlchart' + currentchartnumber).summernote('destroy');
        $('.maxanim9 .btn-group button').removeClass('disabled');
    }
    editrichtext = (e) => {
        var $this = e.currentTarget;
        var currentchartnumber = $($this).attr('data-num');
        $('.maxanim9 .btn-group button').addClass('disabled');
        $('.editrichtext[data-num=' + currentchartnumber + ']').addClass('active');
        $('.saverichtext[data-num=' + currentchartnumber + ']').removeClass('active');
        $(".richtextcontrolschartinfo" + currentchartnumber).show();
        var chartnumber = currentchartnumber;
        var textChartHeight = $("#tileview" + chartnumber).height() - ($("#tileview" + chartnumber + " .note-toolbar.panel-heading").outerHeight() + $(".maxanim3[data-num=" + chartnumber + "]").outerHeight() + 44)

        if ($('.fullscreenmain[data-num=' + chartnumber + ']').length != null && $('.fullscreenmain[data-num=' + chartnumber + ']').length > 0) {
            textChartHeight = $("#mlchart" + chartnumber).height() - 200;
        }
        if ($('.maxanim1[data-num=' + chartnumber + ']').hasClass('singlereportview')) {
            textChartHeight = $("#mlchart" + chartnumber).height() - 100;
        }
        if (textChartHeight < 100) {
            textChartHeight = 100;
        }
        window.$('#mlchart' + currentchartnumber).summernote('destroy');
        window.$('#mlchart' + currentchartnumber).summernote({
            height: textChartHeight,
            focus: false,
            disableDragAndDrop: true,
            toolbar: [
                ['style', ['style']],
                ['font', ['bold', 'italic', 'underline', 'clear']],
                ['fontname', ['fontname']],
                ['color', ['color']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['height', ['height']],
                ['table', ['table']],
                ['insert', ['link', 'picture', 'hr']],
                ['view', ['fullscreen', 'codeview']],
            ],
            onKeyup: function (e) {
                setTimeout(function () {
                    var num = window.$('#mlchart' + currentchartnumber).summernote('code').replace(/(<([^>]+)>)/ig, "").replace(/( )/, " ").length;
                    var key = e.keyCode;
                    var allowed_keys = [37, 38, 39, 40]
                    if ($.inArray(key, allowed_keys) != -1)
                        return true
                    else if (num > 14000) {
                        e.preventDefault();
                        e.stopPropagation();
                        $('.countcharacters[data-num=' + currentchartnumber + ']').text("Characters Count Limit:" + (14000 - num));
                        $('.saverichtext[data-num=' + currentchartnumber + ']').addClass('disabled');
                    } else {
                        $('.countcharacters[data-num=' + currentchartnumber + ']').text("Characters Count Limit:" + (14000 - num));
                        $('.saverichtext[data-num=' + currentchartnumber + ']').removeClass('disabled');
                    }
                }, 150);
            },
            onPaste: function (e) {
                setTimeout(function () {
                    var num = window.$('#mlchart' + currentchartnumber).summernote('code').replace(/(<([^>]+)>)/ig, "").replace(/( )/, " ").length;
                    var key = e.keyCode;
                    var allowed_keys = [8, 37, 38, 39, 40, 46]
                    if ($.inArray(key, allowed_keys) != -1)
                        return true
                    else if (num > 14000) {
                        e.preventDefault();
                        e.stopPropagation();
                        $('.countcharacters[data-num=' + currentchartnumber + ']').text("Characters Count Limit:" + (14000 - num));
                        $('.saverichtext[data-num=' + currentchartnumber + ']').addClass('disabled');
                    } else {
                        $('.countcharacters[data-num=' + currentchartnumber + ']').text("Characters Count Limit:" + (14000 - num));
                        $('.saverichtext[data-num=' + currentchartnumber + ']').removeClass('disabled');
                    }
                }, 150);
            }
        });
        var chartData = ChartInfo.functions.getChartDetails(currentchartnumber);
        var data = JSON.parse(chartData);
        if (data['richtextcontent'] != undefined)
            window.$('#mlchart' + currentchartnumber).summernote('code', decodeURIComponent(data['richtextcontent']));
    }
    cancelrichtext(e) {
        var $this = e.currentTarget;
        var currentchartnumber = $($this).attr('data-num');
        var chartData = ChartInfo.functions.getMLChartDetails(currentchartnumber);
        var data = JSON.parse(chartData);
        $(".richtextcontrolschartinfo" + currentchartnumber).hide();
        if (data['richtextcontent'] != undefined) {
            window.$('#mlchart' + currentchartnumber).summernote('code', decodeURIComponent(data['richtextcontent']));
        } else if (data['richtextcontent'] === undefined) {
            window.$('#mlchart' + currentchartnumber).summernote('destroy');
            window.$('#mlchart' + currentchartnumber).empty();
        }
        window.$('#mlchart' + currentchartnumber).summernote('destroy');
        $('.editrichtext[data-num=' + currentchartnumber + ']').removeClass('active');
        $('.saverichtext[data-num=' + currentchartnumber + ']').removeClass('active');
        $('.maxanim9 .btn-group button').removeClass('disabled');
    }
    downloadforcasttableascsv = (e) => {
        var json = this.state.downloadableJSONData;
        var csv = this.JSON2CSV(json);
        var blob = new Blob(["\ufeff", csv]);
        download(blob, "my-csv-file.csv");
    }
    JSON2CSV = (objArray) => {
        var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        var str = '';
        var line = '';
        for (var index in array[0]) {
            line += index + ',';
        }
        line = line.slice(0, -1);
        str += line + '\r\n';
        for (var i = 0; i < array.length; i++) {
            var line = '';
            for (var index in array[i]) {
                if (line != '') line += ','

                line += array[i][index];
            }

            str += line + '\r\n';
        }
        return str;
    }
    adjustMLChartHeight = (chartnumber, element, pos, selectedModel) => {
        $("#spinnerchart" + chartnumber).show();
        var that = this;
        setTimeout(function () {
            if (chartnumber != undefined) {
                var chartType;
                if (ChartInfo.functions.getMLChartDetails(chartnumber) != undefined) {
                    var mlChartDetails = JSON.parse(ChartInfo.functions.getMLChartDetails(chartnumber));
                    chartType = mlChartDetails.chartType;
                }
                if (!that.options.isFromStoryBook) {
                    var el = $('#mlcharts .tileview[data-num="' + chartnumber + '"] ');
                } else {
                    var el = $('#storybooktabcontent' + that.tabid + 'mlcharts .tileview[data-num="' + chartnumber + '"] ');
                }
                var width = $(el).width();
                var datedropdowns = $("#datedropdown" + chartnumber + "[style!='display: none;']").height()
                if (datedropdowns == undefined) { datedropdowns = 0 }
                var height = $(el).height() - (42 + datedropdowns);
                var chart = ChartInfo.functions.getMLChartData(chartnumber)
                if (chart != undefined) {
                    try {
                        chart.setSize(width, height, true)
                    } catch (err) {

                    }
                }
                if (chartType != undefined && chartType == "pie") {
                    $('#mlchart' + chartnumber).height(height);
                    $('#mlchart' + chartnumber).width(width);
                }
                if (chartType != undefined && chartType == "datatable") {
                    var $table = $("#outliertable" + chartnumber);
                    var baseHeight = $(".tileview[data-num=" + chartnumber + "]").height(),
                        headerHeight = $("#mlchart" + chartnumber + " .fixed-table-header").height(),
                        dataHeight = $("#mlchart" + chartnumber + " .fixed-table-body>table>tbody").height(),
                        searchBarHeight = $(".outliertable .search").height(),
                        paginationHeight = $(".pagination-detail").eq(1).outerHeight();
                    //var chartHeight = baseHeight - (headerHeight + searchBarHeight + paginationHeight);
                    var chartHeight = baseHeight - headerHeight
                    if (selectedModel != undefined && selectedModel != "forecasting" && dataHeight != null && chartHeight >= dataHeight) {
                        chartHeight = dataHeight + 41;
                    }
                    $($table).bootstrapTable('resetView', { height: chartHeight });
                } else if (chartType != undefined && chartType == "nlgdata") {
                    var baseHeight = $(".tileview[data-num=" + chartnumber + "]").height();
                    var chartHeight = baseHeight - 50;
                    $(".smartinsightdatacontainer #mlchart" + chartnumber + " .nlgdata-container").css("height", chartHeight)
                } else if (chartType != undefined && chartType == "packedbubble") {
                    $('#mlchart' + chartnumber).height(height);
                    $('#mlchart' + chartnumber).width(width);
                } else if (chartType != undefined && chartType == "wordcloud") {
                    $('#mlchart' + chartnumber).height(height);
                    $('#mlchart' + chartnumber).width(width);
                } else if (chartType != undefined && chartType == "decisiontree") {
                    try {
                        height = $(el).height() - 42;
                        $('#mlchart' + chartnumber).height(height).width($(el).width());
                        var treeObj = ChartInfo.functions.getMLChartData(chartnumber);
                        var treeChart = treeObj["tree"];
                        var chart_config = treeObj["chart_config"];
                        treeChart.destroy();
                        var tree = new Treant(chart_config);
                        var treeObj = {};
                        treeObj["tree"] = tree;
                        treeObj["chart_config"] = chart_config;
                        ChartInfo.functions.setMLChartData(chartnumber, treeObj);
                        //treeChart.tree.reload();
                    } catch (e) {
                        console.error(e);
                    }
                }
            }
            $("#spinnerchart" + chartnumber).hide();
        }, 1500)
    }
    simulationsentiment = (chartId) => {
        var that = this;
        ReactDOM.render(<div className="col-xs-12">
        <div className="marg-top-10">
            <div contentEditable="true" id="sentiment-simulation" className="resize-vertical custommeasurequerydisplay sentimentquerydisplay" placeholder="Start Typing Here For Type-ahead Feature"></div>
            <span className="customfield_formula_errorblock help-block hide">
                Sentiment Text Cannot be Empty. Please Enter a Value
            </span>
            <button type="button" className="btn pull-right marg-top-5 marg-left-5 testformula" id="validatepop-sentiment">
                <i className="fa fa-play"></i>check
            </button>
            <button type="button" className="btn pull-right marg-top-5 clearformula" id="clearpop-sentiment">
                <i className="fa fa-cut fa-rotate-270"></i>clear
            </button>
        </div>
        <div className="clear"></div>
        <br/><br/><br/>
        {/* <label>Predicted Sentiment:<input type="text" className="predicted-sentiment" name="predicted-sentiment" disabled={true}/></label> */}
        <div class="input-group"><span class="input-group-addon" id="predictedsentiment">Predicted Sentiment&nbsp;&nbsp;:</span><input type="text" className="predicted-sentiment" name="predicted-sentiment" disabled={true} aria-describedby="predictedsentiment"/></div>
        </div>, document.getElementById("mlchart" + chartId));
    }
    textfiltermodel = () => {
        var that = this;
        $("#filtermodal").html(`<div class="modal fade in" id="textfiltermodal" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" style="display: block;"><div class="modal-backdrop fade in"></div><div class="modal-dialog modal-sm modal-sm-400 modal-dialog-sm-center modal-dialog-center" style="z-index: 1050; margin-top: -104.422px; margin-left: -331.594px;"><div class="modal-content" style="max-height: 555px;"><div class="modal-header boc-modal-hf-padding"><button type="button" class="close white canceltextfilter" id="close" data-dismiss="modal" aria-hidden="true">×</button><h4 class="modal-title"><i class="fa fa-filter"></i> Choose Filter</h4></div><div class="modal-body scrollsetnew" style="max-height: 428.156px;"><div class="container-fluid marg-top-5 nopadding"><input class="form-control search-image" placeholder="Search" id="searchsentimentinput" type="text"></div><div class="container-fluid"><div class="row filtercontent dobyfilteritems" id="mlchartdobyfilteritems">`
            + `</div></div></div><div class="modal-footer boc-modal-hf-padding"><button type="button" class="btn btn-bird canceltextfilter" id="btn-bird" data-dismiss="modal"><i class="fa fa-times"></i> Cancel</button><button type="button" class="btn btn-bird modalApply textfiltermodalApply" id="modalApply"><i class="fa fa-check"></i> Apply</button></div></div></div></div>`
        );
        ReactDOM.render(
        <ul class="list-group scrollsetul">
            {that.state.catagories.map(function (val, index) {
                return (
                    <li key={index} className="boc-list-item filterliitem" data-type="string"><label title={val.columnDisplayName}><input type="checkbox" className="reportfiltercheckbox" id="reportfiltercheckboxs" data-type="string" data-parent="allData" data-child={val.columnDisplayName} name={val.columnDisplayName} defaultChecked={that.state.selectedcategories.indexOf(val.columnDisplayName) < 0 ? false : true} onChange={that.textfiltercheckboxchange} /><span className="itemtype">ABC</span>{val.columnDisplayName}</label></li>)
                }
            )}
        </ul>
        , document.getElementById("mlchartdobyfilteritems"));
        $(".canceltextfilter").click(function () {
            $("#textfiltermodal").remove();
        });
        $(".textfiltermodalApply").click(function () {
            that.textfiltermodelApply();
        })
        $("#searchsentimentinput").change(function(e){
            that.searchSentimentFilterValues(e);
        })
    }
    searchSentimentFilterValues = (e) => {
        e.stopPropagation();
        var $this = e.currentTarget;
        var valThis = $($this).val().toLowerCase();
        $("input:checkbox[class='reportfiltercheckbox']").parents(".boc-list-item").each(function () {
            var text = $(this).text().toLowerCase();
            (text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
        });
    }
    textfiltermodelApply = () => {
        $("#spinnerchart2").show();
        $("#spinnerchart3").show();
        var that = this;
        that.state.selectedcategories = [];
        _.each($("#mlchartdobyfilteritems .reportfiltercheckbox:checked"), function (val) {
            that.state.selectedcategories.push($(val).attr("name"))
        })
        let categories = [], finalData = [], chartData = [], chartCategories = [], categoriesunique = [];
        that.state.textstackedchartdata.data.map(function (outerval) {
            let tempObj = {}
            let data = [];
            outerval.data.map(function (val) {
                if (that.state.selectedcategories.indexOf(val.name) >= 0) {
                    data.push(val)
                    if (categories.indexOf(val.name) == -1) {
                        categories.push(val.name)
                    }
                }
            })
            tempObj["data"] = data;
            tempObj["name"] = outerval.name
            finalData.push(tempObj);
        })
        that.state.textbarchartdata.data.map(function (val) {
            let tempObj = {}
            if (that.state.selectedcategories.indexOf(val.name) >= 0) {
                chartData.push(val)
                if (categoriesunique.indexOf(val.name) == -1) {
                    tempObj["columnDisplayName"] = val.name;
                    tempObj["columnName"] = val.name;
                    chartCategories.push(tempObj);
                    categoriesunique.push(val.name)
                }
            }
        })
        // $("#mlchart" + "3").empty();
        MLCharts.charts.drawColumnBar(finalData, categories, "mlchart" + "3", "", true, "column", true,"percent");
        that.adjustMLChartHeight("3");
        // $("#mlchart" + "2").empty();
        MLCharts.charts.drawSmartBar(chartData, chartCategories, "", "", "mlchart" + "2", "", this.features, this.reportId, this.datamodelId, this.broker, "", "", this.selectedModel, "");
        that.adjustMLChartHeight("2");
        $("#spinnerchart2").hide();
        $("#spinnerchart3").hide();
        $("#textfiltermodal").remove();
    }
    textfiltercheckboxchange = (e) => {
        var that = this;
        var $this = e.currentTarget;
        if ($("#mlchartdobyfilteritems .reportfiltercheckbox:checked").length > 10) {
            $($this).prop("checked", false);
        }else if($("#mlchartdobyfilteritems .reportfiltercheckbox:checked").length < 1){
            $($this).prop("checked", true);
        }
    }
    checkText = () => {
        let sentiment_text = $("#sentiment-simulation").text();
        var that = this;
        if (sentiment_text && sentiment_text != "") {
            var sentiment_analysis_type = $(".sentiment-analysis-type").val();
            let model_type = $(".sentiment-model-type:not(.hide)").val();
            let textColumnsArray = [];
            _.each($(".mltextcolumns .singleliitems"), function (listObj) {
                var textdata = {};
                var columnName = $(listObj).attr("data-name");
                var columnDisplayName = $(listObj).attr("title");
                var columnDataType = $(listObj).attr("data-type");
                var aggregation = $(listObj).attr("data-agg");
                var tableName = $(listObj).attr("table-name");
                textdata["columnName"] = columnName;
                textdata["displayName"] = columnDisplayName;
                textdata["dataType"] = columnDataType;
                textdata["aggregation"] = aggregation;
                textdata["tableName"] = tableName;
                textColumnsArray.push(textdata);
            });
            var modelObject = {};
            modelObject["text_col"] = textColumnsArray[0].displayName;
            modelObject["sentiment_col"] = "";
            modelObject["model"] = model_type;
            var selectedModelsData = {};
            selectedModelsData["selectedModel"] = that.selectedModel
            selectedModelsData["parametersObj"] = modelObject;
            selectedModelsData["sentiment_analysis_type"] = sentiment_analysis_type;
            selectedModelsData["textColumnsArray"] = textColumnsArray;
            var formdata = new FormData();
            formdata.set('selectedModel', that.selectedModel);
            formdata.set('reportId', that.reportId != undefined ? that.reportId : "");
            formdata.set('datamodelId', that.datamodelId);
            formdata.set('parametersObj', JSON.stringify(modelObject));
            formdata.set('yValue', JSON.stringify([]));
            formdata.set('xAxisArray', JSON.stringify([]));
            formdata.set('customFields', JSON.stringify(null));
            formdata.set('filterObject', JSON.stringify([]));
            formdata.set('selectedColumns', JSON.stringify([]));
            formdata.set('textColumnsArray', JSON.stringify(textColumnsArray));
            formdata.set('dateColumnsArray', JSON.stringify([]));
            formdata.set('isRunModel', true);
            formdata.set('sentiment_analysis_type', sentiment_analysis_type != undefined ? sentiment_analysis_type : null);
            formdata.set('sentimentAnalysisSentence',  sentiment_text );
            formdata.set('sentimentAnalysisPath', "");
            let requestHeaders = {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            SendRequest({
                url: "runmlmodel",
                body: formdata,
                sucessFunction: responseData => {
                    var response = responseData.data != null && responseData.data != '' ? JSON.parse(responseData.data) : '';
                    let predictedsentiment = response[0];
                    $("input.predicted-sentiment").val(predictedsentiment)
                    console.log(responseData);
                }, rejectFunction: () => { },
                headers: requestHeaders
            })
        }
    }
    clearText = (e) => {
        $("#sentiment-simulation").text("");
    }
}