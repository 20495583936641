import React from "react";
import { Link } from 'react-router-dom';
import { getMessage } from '../js/helpers/utils/BirdMessage';
import SendRequest from '../SendRequest';
import Properties from '../Properties/ServerProperties'
import moment from 'moment';
import BusinessModelsDropdown from '../BusinessModelsDropdown'
import ImportTemplateFile from './importtemplatefile'
import { lang } from "../js/helpers/utils/languages/language";

import _ from 'underscore';
import WorkspaceListView from "./WorkspaceListView";
import DataModelListView from "./DataModelListView";
import Header from "./Header";
const $ = window.$;

export default class Datamain extends React.Component {
    constructor(props) {
        $(".spinner").show()
        super(props);
        this.state = {
            models: [],
            workspaces: [],
            isFromModel: false,
            isWorkspaceListview: false,
            isDataModelListview: false,
            features: []
        }
        this.navdatahub = this.navdatahub.bind(this);
        this.navbusinessworkspaces = this.navbusinessworkspaces.bind(this);
        this.navbusinessmodels = this.navbusinessmodels.bind(this);
        this.createnewbusinessworkspace = this.createnewbusinessworkspace.bind(this);
        this.navigatetosearch = this.navigatetosearch.bind(this);
        this.navnotebooks = this.navnotebooks.bind(this);
    }
    spinnerShow(){
        $(".spinner").show()
    }
    spinnerHide(){
        $(".spinner").hide()
    }
    navbusinessworkspaces() {
        $(".spinner").show()
        this.props.history.push(`/${window.localStorage.getItem("appName")}/welcome/businessworkspaces`);
    }
    navdatahub() {
        $(".spinner").show()
        this.props.history.push(`/${window.localStorage.getItem("appName")}/welcome/datahub`);
    }
    navbusinessmodels() {
        $(".spinner").show()
        this.props.history.push(`/${window.localStorage.getItem("appName")}/welcome/businessmodels`);
    }
    navnotebooks(){
            window.open(Properties.sqlnotebook, '_blank');
    }
    createnewbusinessworkspace() {
        $(".spinner").show()
        this.props.history.push(`/${window.localStorage.getItem("appName")}/welcome/workspace`);
    }
    deleteworkspace = (index, e) => {
        var that = this;
        var $this = e.currentTarget;
        var workspaceId = $($this).closest('.item-main-container').attr("workspace-id");
        var workspaceTitle = $($this).closest('.item-main-container').attr("workspace-title");
        var datahubid = $($this).closest('.item-main-container').attr("datahub-id");
        $(".deleteyes").unbind("click");
        $(".deleteno").unbind("click");
        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
        $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
        $('.birdmessagebuttons').addClass('show');
        $('.birdmessage-center').addClass("message-top-in");
        $('.details').removeClass('show');
        $('.details').addClass('hide');
        $('.birdmessage h2').empty().append('Warning');
        $('.birdmessage-info').empty().text(getMessage('BirdWarning127'));
        $(".deleteyes").click(function () {
            $('.birdmessage-center').removeClass("message-top-in");
            $('.spinner').show();
            var data = new FormData();
            data.append("workspaceId", workspaceId);
            data.append("datahubId", datahubid);
            data.append("workspaceTitle", workspaceTitle);
            SendRequest({
                url: "deleteworkspace",
                queryString: "",
                body: data,
                sucessFunction: (response) => {
                    if (response.success == true) {
                        $('.spinner').hide();
                        // $($this).closest('.item-main-container').remove();
                        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Success');
                        $('.birdmessage-info').empty().text(getMessage('BirdWarning131'));
                        const workspacesArray = Object.assign([], that.state.workspaces);
                        workspacesArray.splice(index, 1);
                        that.setState({ workspaces: workspacesArray });
                        that.fetchDataModelsAndWorkspaces();
                    } else {
                        $(".spinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.errorTrace)
                    }
                },
                rejectFunction: (error) => { },
                headers: {
                    method: "post",
                    headers: new Headers({
                        "Content-Type": "application/json"
                    })
                }
            });
        });
        $(".deleteno").click(function () {
            $('.birdmessage-center').removeClass("message-top-in");
        });
    }
    componentDidMount() {
        $(".spinner").show();
        this.fetchDataModelsAndWorkspaces();
    }
    fetchDataModelsAndWorkspaces() {
        var that = this;
        let requestHeaders = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };

        SendRequest({
            url: "getfeatures",
            queryString: "",
            sucessFunction: (features) => {
                that.state.features = features;
                SendRequest({
                    url: "getavailabledatamodels",
                    queryString: "",
                    sucessFunction: (props) => {
                        that.setState({ models: props });
                    },
                    rejectFunction: () => { },
                    headers: requestHeaders,
                })
                SendRequest({
                    url: "getavailableworkspaces",
                    queryString: "?isFromModel=" + that.state.isFromModel,
                    sucessFunction: (props) => {
                        that.setState({ workspaces: props });
                        setTimeout(function(){$(".spinner").hide();},1000);
                    },
                    rejectFunction: () => { },
                    headers: requestHeaders,
                })
            },
            rejectFunction: () => { },
            headers: {
                method: "get",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            }
        });
    }
    createBusinessModel = (e) => {
        $(".spinner").show();
        var $this = e.currentTarget;
        sessionStorage.setItem('workspaceId', $($this).attr("id"));
        this.props.history.push("/" + window.localStorage.getItem("appName") + "/welcome/models");
    }
    importtemplatefile = (e) => {
        e.preventDefault();
        var $this = e.currentTarget;
        var datamodelId = $($this).attr("datamodel-id");
        var isShared = $($this).attr("is_shared");
        var that = this;
        var importTemplateFileView = new ImportTemplateFile({
            featuresModule: that.state.features,
            datamodelId: datamodelId,
            isFromSharedModel: isShared != undefined ? isShared : false,
            history: that.props.history
        });
    }
    createStoryBoard = (e) => {
        var $this = e.currentTarget;
        $('.spinner').show();
        console.log($this);
        var datamodelId = $($this).attr("datamodel-id");
        var isShared = $($this).attr("is_shared") != undefined ? $($this).attr("is_shared") : false;

        var requestbody = new FormData();
        requestbody.set("datamodelid", datamodelId);
        requestbody.set("type", "storyboard");
        let requestHeaders = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
        SendRequest({
            url: "createreportfrommodel",
            body: requestbody,
            headers: requestHeaders,
            sucessFunction: (props) => {
                var tempReportId = props.tempreportid;
                sessionStorage.setItem("isSharedModel" + datamodelId, isShared);
                var href = "/" + window.localStorage.getItem("appName") + "/welcome/viewstoryboard/" + btoa(datamodelId) + "/" + btoa(tempReportId);
                this.props.history.push(href);
                $('.spinner').hide();
            },
            rejectFunction: (props) => {
                var response = props;
                $(".spinner").hide();
                $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                $('.birdmessagebuttons').removeClass('show');
                $('.birdmessagebuttons').addClass('hide');
                $('.birdmessage-center').addClass("message-top-in");
                $('.details').removeClass('hide');
                $('.birdmessage h2').empty().append('Error : ');
                $('.birdmessage h2').append('<span class="errorcode">');
                $('.birdmessage .errorcode').empty().append(response.errorCode);
                $('.birdmessage-info').empty().text(response.error);
                $('.details').addClass('show').text("Details");
                $('.messagedetails xmp').empty().append(response.errorTrace)
            }
        });
    }
    datamodeldelete = (modelId, index) => {
        var that = this;
        //        var $this = e.currentTarget;
        //        var datamodelId=$($this).attr("id");
        $(".deleteyes").unbind("click");
        $(".deleteno").unbind("click");
        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
        $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
        $('.birdmessagebuttons').addClass('show');
        $('.birdmessage-center').addClass("message-top-in");
        $('.details').removeClass('show');
        $('.details').addClass('hide');
        $('.birdmessage h2').empty().append('Warning');
        $('.birdmessage-info').empty().text(getMessage('BirdWarning129'));
        $(".deleteyes").click(function () {
            $('.spinner').show();
            $('.birdmessage-center').removeClass("message-top-in");
            SendRequest({
                url: "deletebusinessmodel",
                queryString: "?modelId=" + modelId,

                sucessFunction: (response) => {
                    if (!response.hasOwnProperty('error')) {
                        $('.spinner').hide();
                        $('.birdmessage-center').removeClass("message-top-in");
                        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Success');
                        $('.birdmessage-info').empty().text(getMessage('BirdWarning130'));
                        const modelsArray = Object.assign([], that.state.models);
                        modelsArray.splice(index, 1);
                        that.setState({ models: modelsArray });
                        // that.collection.fetch({reset:true,cache:false});
                        // that.render();
                    } else {
                        $(".spinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.errorTrace)
                    }
                },
                rejectFunction: (error) => { },
                headers: {
                    method: "post",
                    headers: new Headers({
                        "Content-Type": "application/json"
                    })
                }
            });
        });
        $(".deleteno").click(function () {
            $('.birdmessage-center').removeClass("message-top-in");
        });
    }
    deletemodelcache = (modelId, index) => {
        var that = this;
       
        $(".deleteyes").unbind("click");
        $(".deleteno").unbind("click");
        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-error').addClass(' birdmessage-warning');
        $('.messageicon i').removeClass('fa-check fa-info-circle fa-times-circle-o ').addClass('fa-exclamation-triangle');
        $('.birdmessagebuttons').addClass('show');
        $('.birdmessage-center').addClass("message-top-in");
        $('.details').removeClass('show');
        $('.details').addClass('hide');
        $('.birdmessage h2').empty().append('Warning');
        $('.birdmessage-info').empty().text(getMessage('BirdWarning158'));
        $(".deleteyes").click(function () {
            $('.spinner').show();
            $('.birdmessage-center').removeClass("message-top-in");
            SendRequest({
                url: "deleteCacheForDataModel",
                queryString: "?modelId=" + modelId,

                sucessFunction: (response) => {
                    if (!response.hasOwnProperty('error')) {
                        $('.spinner').hide();
                        $('.birdmessage-center').removeClass("message-top-in");
                        $('.messageicon').removeClass('birdmessage-error birdmessage-infor birdmessage-warning').addClass('birdmessage-success');
                        $('.messageicon i').removeClass('fa-times-circle-o fa-info-circle fa-exclamation-triangle').addClass('fa-check');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('show');
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Success');
                        $('.birdmessage-info').empty().text(getMessage('BirdSuccess69'));
                        
                    } else {
                        $(".spinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').removeClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').addClass('show');
                        $('.messagedetails xmp').empty().append(response.errorTrace)
                    }
                },
                rejectFunction: (error) => { },
                headers: {
                    method: "post",
                    headers: new Headers({
                        "Content-Type": "application/json"
                    })
                }
            });
        });
        $(".deleteno").click(function () {
            $('.birdmessage-center').removeClass("message-top-in");
        });
    }
    render() {
        return (
            <React.Fragment>
            <Header {...this.props} url={this.props.url} pageType={this.props.pageType} />
            <div id="main">
                <div className="col-xs-12 sub-nav" style={{ position: 'fixed', top: '46px', zIndex: '9', background: '#fff', textAlign: 'center', maxHeight: '33px' }}>
                    <div className="scrollfix" id="myScrollspy">
                        <ul className="nav nav-tabs nav-stacked affix-top" data-spy="affix" data-offset-top="0">
                            {this.state.features.map((feature) => {
                                if (feature.moduleName == "data_hub") {
                                    return <li title={lang["workspace.datahubtitle"]} id="navdatahub" onClick={this.navdatahub}><a><i className="fa fa-database fa-solid fa-fw sidepin"></i><span>{lang["workspace.datahubtitle"]}</span></a></li>
                                }
                            })}
                            {this.state.features.map((feature) => {
                                if (feature.moduleName == "work_space") {
                                    return <li className={this.props.location.pathname.indexOf("businessworkspaces") != -1 ? "active" : ""} title={lang["workspace.workspcetitle"]} id="navbusinessworkspaces" onClick={this.navbusinessworkspaces}><a ><i className="fa fa-cubes fa-solid fa-fw sidepin"></i><span>{lang["workspace.workspcetitle"]}</span></a></li>
                                }
                            })}
                            <li className={this.props.location.pathname.indexOf("businessworkspaces") == -1 ? "active" : ""} title={lang["workspace.modeltitle"]} id="navbusinessmodels" onClick={this.navbusinessmodels}><a><i className="fa fa-cube fa-solid fa-fw sidepin"></i><span>{lang["workspace.modeltitle"]}</span></a></li>
                            
                           
                            {this.state.features.map((featuresModule) => {
                                return  featuresModule.moduleName == "note_book"?
                                    featuresModule.features.map((feature,index) => {
                                         return (feature.featureName == 'new_note_book' && feature.allow == true) ?
                                       <li key={index} id="navnotebooks" onClick={this.navnotebooks}><a><i className="fa fa-file fa-solid fa-fw sidepin"></i><span>{lang["datahub.notebook"]}</span></a></li>
                                       :<React.Fragment/>}):<React.Fragment/>})}
                        </ul>
                    </div>
                </div>
                <section className="datamain-section-container">
                    <div className="boc-home">
                        <div className="datamain-fixed-content-container home">
                            <div id="workspacecontentcontainer">
                                {this.props.location.pathname.indexOf("businessworkspaces") != -1 ?
                                    
                                    (<React.Fragment>{this.spinnerHide()}<div className="container-fluid">
                                        <h2 className="datamain-title pull-left">
                                            <i className="fa fa-cubes fa-solid"></i>&nbsp; {lang["workspace.title"]}
	                                </h2>
                                        <div className="btn-group pull-right marg-left-5 h2" role="group">
                                            <a className="btn btn-bird tooltip-left" data-tooltip={lang["workspace.createspace"]} id="createnewbusinessworkspace" onClick={this.createnewbusinessworkspace} type="button" title={lang["workspace.createspace"]}><i className="fa fa-plus"></i></a>
                                            <button
                                                className="btn btn-bird active workspacegridview"
                                                id="workspacegridview"
                                                type="button"
                                                title={lang["workspace.gridview"]}
                                                onClick={this.workspacegridviewclick}
                                            >
                                                <i className="fa fa-th-large"></i>
                                            </button>
                                            <button
                                                className="btn btn-bird workspacelistview"
                                                id="workspaceviewlist"
                                                type="button"
                                                title={lang["workspace.listview"]}
                                                onClick={this.workspacelistviewclick}
                                            >
                                                <i className="fa fa-th-list"></i>
                                            </button>
                                        </div>
                                    </div>
                                        <div className="data-items-container">
                                            {

                                                this.state.isWorkspaceListview ?
                                                    <WorkspaceListView url={this.props.url} history={this.props.history} />
                                                    :
                                                    this.state.workspaces.map((ws, index) => {
                                                        var wssaveStatus = 'ready';
                                                        var isWsLiveEnabled=false;
                                                        var wsmetadata ={};
                                                        if(ws!=undefined && ws.metadata!=undefined && ws.metadata!=null){
                                                            wsmetadata=JSON.parse(ws.metadata);
                                                            isWsLiveEnabled= wsmetadata.isWsLiveEnabled;
                                                        }
                                                        if (ws.wssaveStatus == 'completed') { wssaveStatus = 'ready'; }
                                                        else if (ws.wssaveStatus == 'inprogress') { wssaveStatus = 'processing'; }
                                                        else { wssaveStatus = 'error'; }
                                                        var editpermit = "", editid = "";
                                                        _.each(this.state.features, function (featuresModule) {
                                                            if (featuresModule.moduleName === 'work_space') {
                                                                _.each(featuresModule.features, function (feature) {
                                                                    if (feature.featureName === 'edit_workspace' && feature.allow === true) {
                                                                        editpermit = "editworkspace";
                                                                        editid = ws.WSID;
                                                                    }
                                                                });
                                                            }
                                                        });
                                                        return (
                                                            <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2 item-main-container" workspace-id={ws.WSID} workspace-title={ws.WSTitle} datahub-id={ws.datahubid}>
                                                                <div className="data-item" title={ws.WSTitle}>
                                                                    <div className="data-item-headers">
                                                                        <Link to={`/${window.localStorage.getItem("appName")}/welcome/workspace/${btoa(ws.WSID)}`} className={`data-item-name ${editpermit}`} id={editid} style={{ textDecoration: "none" }}>{ws.WSTitle}</Link>

                                                                        <div className="data-item-subtitle">{lang["workspace.createon"]} {ws.WSCreatedOn}</div>
                                                                        <div className="data-item-actions">
                                                                            <button type="button" className="btn btn-transparent dropdown-toggle dropdown" title={lang["workspace.more"]} data-toggle="dropdown" aria-expanded="true"><i className="fa fa-ellipsis-v"></i></button>
                                                                            <ul className="dropdown-menu dropdown-menu-right megadropdown" id={ws.WSID}>
                                                                                <li><button className="btn btn-data-item-actions editworkspace" id={ws.WSID} title={lang["workspace.editspace"]} role="button"><Link to={`/${window.localStorage.getItem("appName")}/welcome/workspace/${btoa(ws.WSID)}`} style={{ padding: "0 115px 0 0", textDecoration: "none" }}><i className="fa fa-pencil marg-righ-10"></i>{lang["workspace.edit"]}</Link></button></li>
                                                                                <li><button className="btn btn-data-item-actions createmodel" id={ws.WSID} title={lang["workspace.createmodel"]} role="button" onClick={this.createBusinessModel}><i className="fa fa-plus marg-righ-10"></i>{lang["workspace.createmodel"]}</button></li>
                                                                                <li><button className="btn btn-data-item-actions deleteworkspace" id={ws.WSID} title={lang["workspace.deletespace"]} role="button" onClick={(e) => { this.deleteworkspace(index, e) }}><i className="fa fa-trash-o marg-righ-10"></i>{lang["workspace.delete"]}</button></li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className="data-item-info">
                                                                        <div className="data-item-info-name"></div>
                                                                    </div>
                                                                    <div className="data-item-more-info">
                                                                        <div className="data-item-desc-box">
                                                                            <div className="data-item-info-description">{ws.WSDescription}</div>
                                                                        </div>
                                                                        <div className="data-item-img-box">
                                                                            <i className="fa fa-cubes fa-solid"></i>
                                                                        </div>
                                                                    </div>
                                                                    <div className="data-item-footer">
                                                                        <div className="data-sync-info"><i className="fa fa-refresh"></i> <span>{lang["workspace.updateon"]} {moment(ws.WSUpdatedOn).format("YYYY-MM-DD HH:mm:ss")}</span></div>
                                                                        <span className="data-item-sync-status-live">{isWsLiveEnabled ? (<i className="fa fa-clock-o fa-flip-horizontal fa-green"></i>) : null}</span>
                                                                        <div className={`data-item-sync-status ${wssaveStatus}`}><span className="data-item-sync-status-icon"></span></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                            }
                                        </div></React.Fragment>) : <React.Fragment />}
                            </div>
                            <div id="datamodelcontentcontainer">
                                {this.props.location.pathname.indexOf("businessworkspaces") == -1 ?
                                    
                                    (<React.Fragment>
                                        {this.spinnerHide()}
                                        <div className="container-fluid">
                                            <h2 className="datamain-title pull-left">
                                                <i className="fa fa-cube fa-solid"></i>&nbsp; {lang["workspace.models"]}
                                    </h2>
                                            <div className="btn-group pull-right marg-left-5 h2" role="group">
                                                {this.state.features.map((featuresModule) => {
                                                    if (featuresModule.moduleName == 'business_model') {
                                                        return <>
                                                            {featuresModule.features.map((feature) => {
                                                                if (feature.featureName == 'model_governance' && feature.allow == true) {
                                                                    return <a className="btn btn-bird tooltip-left" data-tooltip={lang["workspace.goverance"]} id="favoritelistviewlist" type="button" title={lang["workspace.goverance"]} onClick={() => { this.props.history.push("/" + window.localStorage.getItem("appName") + "/welcome/governance") }}><i className="fa fa-shield"></i></a>
                                                                }
                                                            })}
                                                            {featuresModule.features.map((feature) => {
                                                                if (feature.featureName == 'new_model' && feature.allow == true) {
                                                                    return <button className="btn btn-bird tooltip-left" data-tooltip={lang["workspace.createmodel"]} id="createbusinessmodel" type="button" title={lang["workspace.createmodel"]} onClick={this.createBusinessModel}><i className="fa fa-plus"></i></button>
                                                                }
                                                            })}
                                                            <button
                                                                className="btn btn-bird active datamodelgridview"
                                                                id="datamodelgridview"
                                                                type="button"
                                                                title={lang["models.gridview"]}
                                                                onClick={this.datamodelgridviewclick}
                                                            >
                                                                <i className="fa fa-th-large"></i>
                                                            </button>
                                                            <button
                                                                className="btn btn-bird datamodellistview"
                                                                id="datamodelviewlist"
                                                                type="button"
                                                                title={lang["models.listview"]}
                                                                onClick={this.datamodellistviewclick}
                                                            >
                                                                <i className="fa fa-th-list"></i>
                                                            </button>
                                                        </>

                                                    }
                                                })}
                                            </div>
                                        </div>
                                        <div className="data-items-container">
                                            {
                                                this.state.isDataModelListview ?
                                                <DataModelListView url={this.props.url} history={this.props.history} />
                                                :
                                                this.state.models.map((dm, index) => {
                                                    var DMStatus = 'ready';
                                                    var isDmLiveEnabled=false;
                                                        var dmmetadata ={};
                                                        if(dm!=undefined && dm.metadata!=undefined && dm.metadata!=null){
                                                            dmmetadata=JSON.parse(dm.metadata);
                                                            isDmLiveEnabled= dmmetadata.isWsLiveEnabled;
                                                        }
                                                    if (dm.DMStatus == 'Ready') {
                                                        DMStatus = 'ready'
                                                    } else if (dm.DMStatus == 'inprogress') {
                                                        DMStatus = 'processing'
                                                    } else {
                                                        DMStatus = 'error'
                                                    }
                                                    var editpermit = "";
                                                    var editid = "";
                                                    var modeldelete = false;
                                                    var modelstoryboard = false;
                                                    var modelreport = false;
                                                    var modelmlreport = false;
                                                    var modelsmartinsights = false;
                                                    var modelimporttemplate = false;
                                                    var modelautoviz = false;
                                                    var smartSearch = false;
                                                    var deletecache = false;
                                                    _.each(this.state.features, function (featuresModule) {
                                                        if (featuresModule.moduleName == 'business_model') {
                                                            _.each(featuresModule.features, function (feature) {
                                                                if (feature.featureName == 'edit_model' && feature.allow == true) {
                                                                    if (dm.is_shared != true) {
                                                                        editpermit = "editdatamodel";
                                                                        editid = dm.DMID;
                                                                    }
                                                                }
                                                                if (feature.featureName == 'delete_model' && feature.allow == true) {
                                                                    if (dm.is_shared != true) {
                                                                        modeldelete = true;
                                                                        deletecache = true;
                                                                    }
                                                                }
                                                                if (feature.featureName == 'create_model_storyboard' && feature.allow == true && DMStatus == 'ready') {
                                                                    modelstoryboard = true;
                                                                }
                                                                if (feature.featureName == 'create_model_report' && feature.allow == true && DMStatus == 'ready') {
                                                                    modelreport = true;
                                                                } if (feature.featureName == 'create_model_smartinsights' && feature.allow == true && !dm.multifact && DMStatus == 'ready') {
                                                                    modelsmartinsights = true;
                                                                } if (feature.featureName == 'create_model_mlreport' && feature.allow == true && !dm.multifact && DMStatus == 'ready') {
                                                                    modelmlreport = true;
                                                                }
                                                                if (feature.featureName == 'import_template_view' && feature.allow == true && DMStatus == 'ready') {
                                                                    modelimporttemplate = true;
                                                                }
                                                                if(feature.featureName == 'create_model_autovizreport' && feature.allow == true && !dm.multifact && DMStatus == 'ready'){
                                                                    modelautoviz = true;
                                                                }
                                                                if(feature.featureName == 'advance_search' && feature.allow == true && !dm.multifact && DMStatus == 'ready'){
                                                                    smartSearch = true;
                                                                }
                                                            });
                                                        }
                                                    });
                                                    return (
                                                        <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2 item-main-container" datamodel-id={dm.DMID} datamodel-title={dm.DMTitle}>
                                                            <div className="connection-center importtemplatefilecontainer" datamodel-id={dm.DMID} is_shared={dm.is_shared}></div>
                                                            <div className="data-item" title={dm.DMTitle}>
                                                                <div className="data-item-headers">
                                                                    {editpermit != "" ?
                                                                        <div className={`data-item-name ${editpermit}`} id={editid} onClick={() => { this.props.history.push(`/${window.localStorage.getItem("appName")}/welcome/models/${btoa(dm.DMID)}`) }}>{dm.DMTitle}</div>
                                                                        : <div className={`data-item-name ${editpermit}`} id={editid} >{dm.DMTitle}</div>}
                                                                    <div className="data-item-subtitle">{lang["models.createon"]} {dm.DMCreatedOn}</div>
                                                                    <div className="data-item-actions">
                                                                        <BusinessModelsDropdown {...this.props} modelstoryboard={modelstoryboard} modelreport={modelreport} modelmlreport={modelmlreport} modelsmartinsights={modelsmartinsights}
                                                                            modelimporttemplate={modelimporttemplate} modeldelete={modeldelete} DMID={dm.DMID} is_shared={dm.is_shared} importtemplatefile={this.importtemplatefile} createsmartinsights={this.createsmartinsights} createstoryboard={this.createStoryBoard} deletemodel={this.datamodeldelete} createreport={this.createReport} indexval={index} editpermit={editpermit} createMLReport={this.createMLReport} DMTitle={dm.DMTitle} navigatetosearch={this.navigatetosearch}  createAutoViz={this.createAutoViz} smartSearch={smartSearch}  modelautoviz={modelautoviz} deletemodelcache={this.deletemodelcache} deletecache={deletecache}/>
                                                                    </div>
                                                                </div>
                                                                <div className="data-item-info">
                                                                    <div className="data-item-info-name"></div>
                                                                </div>
                                                                <div className="data-item-more-info">
                                                                    <div className="data-item-desc-box">
                                                                        <div className="data-item-info-description">{dm.DMDescription}</div>
                                                                    </div>
                                                                    <div className="data-item-img-box">
                                                                        <i className="fa fa-cube fa-solid"></i>
                                                                    </div>
                                                                </div>
                                                                <div className="data-item-footer">
                                                                    <div className="data-sync-info"><i className="fa fa-refresh"></i> <span>{lang["models.updateon"]} {moment(dm.DMUpdatedOn).format("YYYY-MM-DD HH:mm:ss")}</span></div>
                                                                    <span className="data-item-sync-status-live marg-left-5">{isDmLiveEnabled ? (<i className="fa fa-clock-o fa-flip-horizontal fa-green"></i>) : null}</span>
                                                                    <div className={`data-item-sync-status ${DMStatus}`}><span className="data-item-sync-status-icon"></span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </React.Fragment>) : <React.Fragment />}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            </React.Fragment>
        );
    }
    createsmartinsights = (e) => {
        e.preventDefault();
        var that = this;
        var $this = e.currentTarget;
        var datamodelId = $($this).attr("datamodel-id");
        var datamodelTitle = $($this).attr("datamodel-title");
        if (datamodelId == null || datamodelId == undefined) {
            $('.messageicon').removeClass('birdmessage-error birdmessage-success birdmessage-warning').addClass('birdmessage-infor');
            $('.messageicon i').removeClass('fa-times-circle-o fa-check fa-exclamation-triangle').addClass('fa-info-circle');
            $('.birdmessagebuttons').removeClass('show');
            $('.birdmessagebuttons').addClass('hide');
            $('.birdmessage-center').addClass("message-top-in");
            $('.details').removeClass('show');
            $('.details').addClass('hide');
            $('.birdmessage h2').empty().append('Information');
            $('.birdmessage-info').empty().text(getMessage('BirdInformation01'));
        } else {
            $(".spinner").show();
            $(".cancelmodal").click();
            $(".smartinsightsclick").click();
            $(".storyboardtabs ul.tabcontainer").removeClass("hide");
            $(".smartinsightintro-container").removeClass("hide");
            $(".smartinsightdatacontainer").removeClass("hide");
            var globalfilterArray = [];
            var requestbody = new FormData();
            requestbody.set("datamodelid", datamodelId + "");
            let requestHeaders = {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            };
            SendRequest({
                url: "checkfordatatypes",
                body: requestbody,
                sucessFunction: (response) => {
                    if (!response.hasOwnProperty('error')) {
                        var reportrequestbody = new FormData();
                        reportrequestbody.set("datamodelid", datamodelId);
                        reportrequestbody.set("type", "");
                        SendRequest({
                            url: "createreportfrommodel",
                            body: reportrequestbody,
                            sucessFunction: (response) => {
                                var href = "/" + window.localStorage.getItem("appName") + "/welcome/viewsmartinsight/" + btoa(datamodelId)
                                sessionStorage.setItem("datamodelname", datamodelTitle);
                                that.props.history.push(href);
                            },
                            rejectFunction: this.reportDataError,
                            headers: requestHeaders
                        });
                        $(".spinner").hide();
                    } else {
                        $(".spinner").hide();
                        $('.messageicon').removeClass('birdmessage-success birdmessage-infor birdmessage-warning').addClass('birdmessage-error');
                        $('.messageicon i').removeClass('fa-check fa-info-circle fa-exclamation-triangle').addClass('fa-times-circle-o');
                        $('.birdmessagebuttons').removeClass('show');
                        $('.birdmessagebuttons').addClass('hide');
                        $('.birdmessage-center').addClass("message-top-in");
                        $('.details').addClass('hide');
                        $('.birdmessage h2').empty().append('Error : ');
                        $('.birdmessage h2').append('<span class="errorcode">');
                        $('.birdmessage .errorcode').empty().append(response.errorCode);
                        $('.birdmessage-info').empty().text(response.error);
                        $('.details').removeClass('show');
                    }
                },
                rejectFunction: this.reportDataError,
                headers: requestHeaders
            });
        }
    }
    createReport = (e) => {
        var $this = e.currentTarget;
        console.log($this);
        var datamodelId = $($this).attr("datamodel-id");
        var isShared = $($this).attr("is_shared");

        var bodyParams = new FormData();
        bodyParams.set("datamodelid", datamodelId);
        bodyParams.set("type", "report");

        SendRequest({
            url: "createreportfrommodel",
            body: bodyParams,
            sucessFunction: (response) => {
                var tempReportId = response['tempreportid'];
                sessionStorage.setItem("isSharedModel" + datamodelId, isShared);
                var href = "/" + window.localStorage.getItem("appName") + "/welcome/viewreport/" + btoa(datamodelId) + "/" + btoa(tempReportId);
                this.props.history.push(href);
            },
            rejectFunction: () => { },
            headers: {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            }
        });
    }
    createMLReport = (e) => {
        var $this = e.currentTarget;
        console.log($this);
        var datamodelId = $($this).attr("datamodel-id");
        var isShared = $($this).attr("is_shared");

        var bodyParams = new FormData();
        bodyParams.set("datamodelid", datamodelId);
        bodyParams.set("type", "report");

        SendRequest({
            url: "createreportfrommodel",
            body: bodyParams,
            sucessFunction: (response) => {
                var tempReportId = response['tempreportid'];
                sessionStorage.setItem("isSharedModel" + datamodelId, isShared);
                var location = window.location;
                var href = location.origin + "/" + window.localStorage.getItem("appName") + "/welcome/viewmodel/" + btoa(datamodelId);
                window.open(href, '_blank');
            },
            rejectFunction: () => { },
            headers: {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            }
        });
    }
    workspacegridviewclick = (e) => {
        this.setState({ isWorkspaceListview: false });
        $("#workspacelisttable").remove();
        $(".workspacelistview").removeClass('active');
        $(".workspacegridview").addClass('active');
    }
    workspacelistviewclick = (e) => {
        this.setState({ isWorkspaceListview: true })
        $("#workspacecontentcontainer").addClass("workspacelistviewsection");
        $(".workspacegridview").removeClass('active');
        $(".workspacelistview").addClass('active');
    }
    datamodelgridviewclick = (e) => {
        this.setState({ isDataModelListview: false });
        $("#datamodellisttable").remove();
        $(".datamodellistview").removeClass('active');
        $(".datamodelgridview").addClass('active');
    }
    datamodellistviewclick = (e) => {
        this.setState({ isDataModelListview: true })
        $("#datamodelcontentcontainer").addClass("datamodellistviewsection");
        $(".datamodelgridview").removeClass('active');
        $(".datamodellistview").addClass('active');
    }
    navigatetosearch=(e)=>{
        var $this = e.currentTarget;
        var datamodelId = $($this).attr("datamodel-id");
        var href = "/" + window.localStorage.getItem("appName") + "/welcome/search";
        this.props.history.push(href);
        setTimeout(function(){
            $(".is-container.datamodelsearchcontainer").removeClass("closemenu");
            $(".chosen-select#selectdatamodel").val(datamodelId).change()
            $(".chosen-select#selectdatamodel").val(datamodelId).trigger('chosen:updated');
        },500)
    }
    createAutoViz =(e)=>{
        var $this = e.currentTarget;
        console.log($this);
        var datamodelId = $($this).attr("datamodel-id");
        var isShared = $($this).attr("is_shared");
        var datamodelTitle = $($this).attr("datamodel-title");
        var bodyParams = new FormData();
        bodyParams.set("datamodelid", datamodelId);
        bodyParams.set("type", "report");

        SendRequest({
            url: "createreportfrommodel",
            body: bodyParams,
            sucessFunction: (response) => {
                var tempReportId = response['tempreportid'];
                sessionStorage.setItem("isSharedModel" + datamodelId, isShared);
                sessionStorage.setItem("report_navigated_datamodelname", datamodelTitle)
                var location = window.location;
                var href = location.origin + "/" + window.localStorage.getItem("appName") + "/welcome/viewautoviz_report/" + btoa(datamodelId);
                window.open(href, '_blank');
            },
            rejectFunction: () => { },
            headers: {
                method: "post",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            }
        });
    }
}
