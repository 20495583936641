import React, { Component } from "react";

export default class NotificationBadgeView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        var that = this.props;
        var hrefEl = "";
        return (<div>
            {that.array.map((value, index) => {
                return <li key={index} className="notifications-list clearfix notifications-new">
                    {(value.vizType == 'smartinsight_storyboard') ? (
                        hrefEl = that.url + "/sharesmartinsightreport/" + value.reportId + "/" + value.dataModelId
                    ) : (value.vizType == 'ml_storyboard') ? (
                        hrefEl = that.url + "/sharereport/" + value.reportId + "/" + value.dataModelId
                    ) :
                            hrefEl = that.url + "/sharereport/" + btoa(value.reportId)
                    }

                    <a href={hrefEl} className="sharereportnavigation">
                        <p className="name" title={value.alertname}>{value.alertname}</p>
                        <span className="datetime" title = {value.createDate.split(" ")[0]}>{value.createDate.indexOf(".") != -1 ? value.createDate.split(".")[0] : value.createDate}</span>
                        <p className="msg">
                            <span className="bird-alert"><i className="fa fa-exclamation-triangle"></i></span>
                            {value.message}
                        </p>
                        <div class="newmarkasread">
                           <div class="checkbox checkbox-slider--c checkbox-slider-sm"><span>Mark as Read</span>
                             <label><input type="checkbox" className="markread"
                                    onClick={that.marknotifications}  title="" status={value.notification_status} isalert="true" publishinfoid={value.publishinfo_id}/><span>&nbsp;</span>
                                  </label>
                                 </div>
                              </div>
                        {/* <p className="markread" onClick={that.marknotifications} title="" status={value.notification_status} isalert="true" 
                        publishinfoid={value.publishinfo_id}></p> */}
                    </a>
                </li>
            })}
        </div>);
    }
}