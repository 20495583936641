import React from 'react';
import { lang } from "../js/helpers/utils/languages/language";
import _ from 'underscore';

export default class CustomSyncDateRangeHtml extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <React.Fragment>
                <div className="control-group form-inline marg-top-10 customsyncdatarangediv" data-num={this.props.id} tab-id="0" tab-name="" data-id={this.props.connectionAccessId} name={this.props.entityName}>
                    <div className="validationgroup form-group">
                        <label>{lang["datahub.datecolumn"]}&nbsp;</label>
                        <select className="form-control input-sm" data-id={this.props.id} name="" style={{ maxWidth: "200px" }}>
                            <optgroup className="selectdatafield_group">
                                {
                                    this.props.datecolumns.map((value, index) => {
                                        if (value.isPartitionKey != 'yes') {
                                            return (
                                                <option key={index} tablename={this.props.entityName} columnname={value.name}>{value.name}</option>
                                            );
                                        }
                                    })
                                }
                            </optgroup>
                        </select>&nbsp;
	                </div>
                    <div className="validationgroup form-group">
                        <label>{lang["datahub.datecolumn"]}&nbsp;</label>
                        <input type="text" className="selectdatasyncdaterange form-control input-sm"  data-num={this.props.id} defaultValue="" data-id={this.props.id} data-cid={this.props.connectionAccessId} name={this.props.entityName} />
                    </div>
                    <button className="btn btn-transparent marg-righ-10 dropdown-toggle tooltip-right removenewselecteddatesyncrange" id="removenewselecteddatestoryboard" data-tooltip={lang["datahub.remove"]}>
                        <i className="fa fa-minus-circle"></i>
                    </button>
                </div>
            </React.Fragment>
        );
    }
}