import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { lang } from "../js/helpers/utils/languages/language";
import SendRequest from '../SendRequest';
import _ from 'underscore';
const $ = window.$;
const columns =  [
        { dataField: 'id', text: 'S.No.', sort: false, headerAlign: 'center', align: 'center' },
        { dataField: 'report_name', text: 'Report Name', sort: true },
        { dataField: 'report_desc', text: 'Description', sort: false },
        { dataField: 'folder_name', text: 'Folder', sort: true },
        { dataField: 'cus.create_date', text: 'Created Date',sort: true},
        { dataField: 'report_type', text: 'Report Type',sort: true},
        { dataField: 'viewReport', text: 'View Report', sort: false, headerAlign: 'center', align: 'center' },
        { dataField: 'deleteReport', text: 'Delete Report', sort: false, headerAlign: 'center', align: 'center' }
    ];
const RemotePagination = ({ data, page, sizePerPage, onTableChange, totalSize }) => (
  // <div className="notifications-table-container">
    <BootstrapTable
      remote
      keyField="id"
      data={data}
      columns={columns}
      pagination={paginationFactory({ page, sizePerPage, totalSize })}
      onTableChange={onTableChange}
      noDataIndication="No matching records found"
    />
  // </div>
);
export default class SharedReportsListTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      data: props.data,
      sizePerPage: props.sizePerPage,
      totalRecords: props.totalRecords,
      search: props.search
    };
  }

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, data,totalRecords }) => {
    const currentIndex = (page - 1) * sizePerPage;
    {
      var that = this;
      var foldername = $(".sharereportfolders-select option:selected").val() == undefined || $(".sharereportfolders-select option:selected").val()==''?'Default':$(".sharereportfolders-select option:selected").val();
      type == "sort" ?
        setTimeout(() => {
          let requestHeaders = {
            method: "get",
            headers: new Headers({
              "Content-Type": "application/json"
            })
          };
          SendRequest({
            url: "getdashboardreportslist",
            queryString: "?fromDate= " + " " + "&toDate= " + " " + "&index= " + currentIndex + "&noOfRecords=" + sizePerPage + "&searchCriteria=" + that.state.search + "&order=" + sortOrder + "&colName=" + sortField + "&folderName=" + foldername + "&urltype=" + "getUserPaginationSharedReports",
            sucessFunction: (response) => {
              if (response.hasOwnProperty('error')) {
                $(".spinner").hide();
              } else {
                var headerlist = response["header"];
                var data = response["data"];
                _.each(data,function(val,index){
                  if(val.type=="ml_storyboard"){
                    val.viewReport = <a href={`${that.props.url}/sharereport/${btoa(val.reportId)}/${btoa(val.dataModelId)}`} data-num={val.reportId} tab-id="0" tab-name="" class="boc-snapshot-thumbnail  sharedreportview  reportview"><i class="fa fa-bar-chart"></i></a>;
                  }else if(val.type=="smartinsight_storyboard"){
                    val.viewReport = <a href={`${that.props.url}/sharesmartinsightreport/${btoa(val.reportId)}/${btoa(val.dataModelId)}`} data-num={val.reportId} tab-id="0" tab-name="" class="boc-snapshot-thumbnail  sharedreportview  reportview"><i class="fa fa-bar-chart"></i></a>;
                  }else if(val.type=="storybook"){
                    val.viewReport = <a href={`${that.props.url}/sharestorybook/${btoa(val.storybook_id)}`} data-num={val.storybook_id} tab-id="0" tab-name="" class="boc-snapshot-thumbnail  sharedreportview  reportview"><i class="fa fa-bar-chart"></i></a>;
                  }
                  else{
                    val.viewReport = <a href={`${that.props.url}/sharereport/${btoa(val.reportId)}`} data-num={val.reportId} tab-id="0" tab-name="" class="boc-snapshot-thumbnail  sharedreportview  reportview"><i class="fa fa-bar-chart"></i></a>;
                  }
      
                  if(val.type!="storybook"){
                    val.deleteReport = <button type="button" onClick={that.props.deleteReport} id = "deletesharedreportslistview" data-num={val.reportId} tab-id="0" tab-name="" class="btn btn-transparent aldelete"><i class="fa fa-trash-o"></i></button>;
                  }
                });

                var keys = Object.keys(headerlist);
                this.setState(() => ({
                  page,
                  data: data,
                  sizePerPage,
                  totalSize: totalRecords
                }));
              }
            },
            rejectFunction: () => { },
            headers: requestHeaders
          })
        }, 10) :
        setTimeout(() => {
          let requestHeaders = {
            method: "get",
            headers: new Headers({
              "Content-Type": "application/json"
            })
          };
          SendRequest({
            url: "getdashboardreportslist",
            queryString: "?fromDate= " + "" + "&toDate= " + "" + "&index= " + currentIndex + "&noOfRecords=" + sizePerPage + "&searchCriteria=" + that.state.search + "&order=" + sortOrder + "&colName=" + "" + "&folderName=" + foldername + "&urltype=" + "getUserPaginationSharedReports",
            sucessFunction: (response) => {
              if (response.hasOwnProperty('error')) {
                $(".spinner").hide();
              } else {
                var headerlist = response["header"];
                var data = response["data"];
                _.each(data,function(val,index){
                  if(val.type=="ml_storyboard"){
                    val.viewReport = <a href={`${that.props.url}/sharereport/${btoa(val.reportId)}/${btoa(val.dataModelId)}`} data-num={val.reportId} tab-id="0" tab-name="" class="boc-snapshot-thumbnail  sharedreportview  reportview"><i class="fa fa-bar-chart"></i></a>;
                  }else if(val.type=="smartinsight_storyboard"){
                    val.viewReport = <a href={`${that.props.url}/sharesmartinsightreport/${btoa(val.reportId)}/${btoa(val.dataModelId)}`} data-num={val.reportId} tab-id="0" tab-name="" class="boc-snapshot-thumbnail  sharedreportview  reportview"><i class="fa fa-bar-chart"></i></a>;
                  }else if(val.type=="storybook"){
                    val.viewReport = <a href={`${that.props.url}/sharestorybook/${btoa(val.storybook_id)}`} data-num={val.storybook_id} tab-id="0" tab-name="" class="boc-snapshot-thumbnail  sharedreportview  reportview"><i class="fa fa-bar-chart"></i></a>;
                  }
                  else{
                    val.viewReport = <a href={`${that.props.url}/sharereport/${btoa(val.reportId)}`} data-num={val.reportId} tab-id="0" tab-name="" class="boc-snapshot-thumbnail  sharedreportview  reportview"><i class="fa fa-bar-chart"></i></a>;
                  }
      
                  if(val.type!="storybook"){
                    val.deleteReport = <button type="button" onClick={that.props.deleteReport} id = "deletesharedreportslistview" data-num={val.reportId} tab-id="0" tab-name="" class="btn btn-transparent aldelete"><i class="fa fa-trash-o"></i></button>;
                  }
                });
                var keys = Object.keys(headerlist);
                this.setState(() => ({
                  page,
                  data: data,
                  sizePerPage,
                  totalSize: totalRecords
                }));
              }
            },
            rejectFunction: () => { },
            headers: requestHeaders
          })

        }, 10);
    }
  }
  componentDidMount() {
    var that = this;
    $(".sharereportfolders-select").change(function (e) {
      that.props.foldersselectchange(e)
    })
  }
  render() {
    var that = this.props;
    const { data, sizePerPage, page,totalRecords } = this.state;
    return (
      <RemotePagination
      data={data}
      page={page}
      sizePerPage={sizePerPage}
      totalSize={totalRecords}
      onTableChange={this.handleTableChange} />
    );
  }
}