import React, { Component } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import SendRequest from '../../SendRequest'
const $ = window.$;
// const columns = [
//     { dataField: 'dataSize', text: 'Data Size (GB)', sort: true },
//     { dataField: 'event_by', text: 'Event By', sort: true },
//     { dataField: 'event_date', text: 'Event Date', sort: true },
//     { dataField: 'event_type', text: 'Event Type', sort: true },
//     { dataField: 'myReports', text: 'My Reports', sort: true },
//     { dataField: 'powerUsers', text: 'Creators', sort: true },
//     { dataField: 'readOnlyUsers', text: 'Explorers', sort: true },
//     { dataField: 'storyBoards', text: 'Story Boards', sort: true },
// 	{ dataField: 'viewerUsers', text: 'Viewers', sort: true },
//];
  const RemotePagination = ({ columns,data, page, sizePerPage, onTableChange, totalSize }) => (
    // <div className="notifications-table-container">
      <BootstrapTable
        remote
        keyField={columns[0].dataField}
        data={data}
        columns={columns}
        pagination={paginationFactory({ page, sizePerPage, totalSize })}
        onTableChange={onTableChange}
      />
    // </div>
  );
  export default class AuditingHeaderTemplate extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        page: 1,
        data: props.data,
        sizePerPage: props.sizePerPage,
        totalRecords: props.totalRecords,
        search: props.search,
        auditType: props.auditType,
        clientId:props.clientId,
        startDate: props.startDate,
        endDate: props.endDate
      };
      this.state.search=props.search;
      this.state.auditType = props.auditType;
      this.state.startDate = props.startDate;
      this.state.clientId = props.clientId;
      this.state.endDate = props.endDate;
      var searchtext=this.state.search
  }
  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, data ,totalRecords}) => {
    const currentIndex = (page - 1) * sizePerPage;
      var that= this;
      if (that.state.search == undefined){
        that.state.search  ="";
      }
      if (that.state.clientId == undefined){
        that.state.clientId  ="";
      }
  let requestHeaders = {
              method: "get",
              headers: new Headers({
                  "Content-Type": "application/json"
              })
          };
      if(type != 'sort'){                
             SendRequest({
              url: "getAudits",
      queryString: "?auditType=" + that.state.auditType + "&fromDate=" + that.state.startDate + "&toDate=" + that.state.endDate + "&index= " + currentIndex + "&noOfRecords=" + sizePerPage + "&searchCriteria=" + that.state.search + "&order=" + sortOrder + "&colName=" + "" + "&clientId=" + that.state.clientId,
              sucessFunction: (response) => {
                  if (response.hasOwnProperty('error')) {
                      $(".spinner").hide();
                  } else {
                    var headerlist = response["header"];
              var data = response["data"];
            totalRecords = response["totalRecords"];
            var keys = Object.keys(headerlist);
            var totalPages;
                  this.setState(() => ({
                    page,
                    data: data,
                    sizePerPage,
                    totalSize:totalRecords
                  }));
                  }
                },
                rejectFunction: () => { },
                headers: requestHeaders
              })
            } 
     if(type=="sort"){
       SendRequest({
   url: "getAudits",
   queryString: "?auditType=" + that.state.auditType + "&fromDate=" + that.state.startDate + "&toDate=" + that.state.endDate + "&index= " + currentIndex + "&noOfRecords=" + sizePerPage + "&searchCriteria=" + that.state.search + "&order=" + sortOrder + "&colName=" + sortField + "&clientId=" + that.state.clientId,
        sucessFunction: (response) => {
            if (response.hasOwnProperty('error')) {
                $(".spinner").hide();
            } else {
             var headerlist = response["header"];
              var data = response["data"];
            totalRecords = response["totalRecords"];
            var keys = Object.keys(headerlist);
            this.setState(() => ({
              page,
              data: data,
              sizePerPage,
              totalSize:totalRecords
            }));
            }
          },
          rejectFunction: () => { },
          headers: requestHeaders
        });
    }
}
	render() {
        $(".usersgroupsaudit").removeClass("hide");
        const { data, sizePerPage, page,totalRecords } = this.state;
      var that = this.props;
      var keys = that.keys;
      var headerlist = that.headerlist;
      var dataTypeslist = that.dataTypeslist;
      var columns = [];
      keys.map(function(key) { 
      var obj = {};
      obj["dataField"] = key; 
      obj["text"] = headerlist[key]; 
      obj["sort"] = true;
      if(dataTypeslist!=null && dataTypeslist!=undefined && dataTypeslist[key]!=null && dataTypeslist[key]!=undefined &&dataTypeslist[key]==='number'){ 
        obj["align"] = 'right'; 
        obj["headerClasses"] = 'align-right'; 
      }
      columns.push(obj);
       });
      return (
          <>
        <div className="panel-heading">
        <h3 className="panel-title searchtitle">
            {that.auditType}
            <span className="pull-right">
                <span className="daterange-center" id="daterange-filter">
                        <i className="glyphicon glyphicon-calendar fa fa-calendar"></i>
                        <span></span>
                        <b className="caret"></b>
                </span>
            </span>
        </h3>	
    </div>
    
    <div className="panel-body">     
<div className="notifications-table-container">
            <RemotePagination
            columns={columns}
            data={data}
            page={page}
            sizePerPage={sizePerPage}
            totalSize={totalRecords}
            onTableChange={this.handleTableChange}
          />
			</div>
            </div>
            </>



      );
    }
}