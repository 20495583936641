import React from 'react'
import { lang } from "../js/helpers/utils/languages/language";
const $ = window.$;

export default class ChartSeriesColorsModalViewTempl extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        var that = this.props;
        return (
            //    <!-- chartseriescolorsmodalview.js -->
            <div class="modal fade in" id="chartseriescolorsmodal" tabIndex="-1" role="dialog" aria-labelledby="chartseriescolors" aria-hidden="true" data-num={that.chartnumber} tab-id="0" tab-name="" style={{ display: "block" }}>
                <div className="modal-backdrop fade in"></div>
                <div class="modal-dialog modal-sm modal-sm-400 modal-dialog-sm-center" style={{ zIndex: "1050" }}>
                    <div class="modal-content">
                        <div class="modal-header boc-modal-hf-padding">
                            <button type="button" class="close white" onClick={that.closeModal} id="viewreportsettingclose" aria-hidden="true" data-dismiss="modal">&times;</button>
                            <h4 class="modal-title seriescolors"><i class="fa fa-th-list"></i> {lang["storyboard.editchartseries"]}</h4>
                        </div>
                        <div class="modal-body scrollset">
                            <div class="container-fluid">
                                <div class="row ">
                                    <div class="col-xs-12 marg-top-10">
                                        <div class="validationgroup">
                                            <div class="modalaxiscontainer nomargin">
                                                <legend class="subhead">
                                                {lang["storyboard.chooseseries"]}
                                           <button class="pull-right btn btn-transparent dropdown-toggle tooltip-left" data-tooltip={lang["storyboard.add"]} id="dropdownseries" aria-expanded="false" data-toggle="dropdown" style={{ top: "-6px" }}>
                                                        <i class="fa fa-plus-circle"></i>
                                                    </button>
                                                    <ul class="list-group dropdown-menu axis-add-menu-fix dropdownseries" role="menu">
                                                        <li class="xs-list-item"> <input class="form-control search-image" onKeyUp={that.search} placeholder={lang["storyboard.search"]} id="searchinput" type="text" /></li>
                                                        <div class="scrollsetul">
                                                            
                                                        {
                                    that.defaultseries!=undefined?
                                         that.defaultseries.map((i, k) => {
                                                                return <li key={k} title={that.defaultseries[k].name} class="xs-list-item sereiescoloritem" onClick={that.addsereiescoloritem} data-name={that.defaultseries[k].name} data-numyaxis={k}>
                                                                    <span class="fa-stack fa-sm marg-righ-5">
                                                                        <i class="fa fa-circle fa-stack-2x maxtheme3" style={{ color: that.defaultseries[k].color }}></i>
                                                                    </span>
                                                                    <span class="itemname">{that.defaultseries[k].name}</span>
                                                                </li>
                                                            })
                                                         :
                                                    null
                                                        }
                                                        </div>
                                                    </ul>
                                                </legend>

                                                {/* <!-- Selected Series --> */}
                                                <div class="marg-top-10">
                                                    <ul class="list-group selecteddropdownseries">
                                                        {(that.customseries != undefined) ? (
                                                            that.customseries.map((i, l) => {
                                                                return <li key={l} class="boc-list-item singleliitems seriescolorliitem" title={i.name} data-name={i.name} data-numyaxis={i.uniqueid}>
                                                                    <span class="fa-stack fa-sm marg-righ-5">
                                                                        <i class="fa fa-circle fa-stack-2x maxtheme3" style={{ color: i.color }}></i>
                                                                    </span>
                                                                    <span class="itemname">{i.name}</span>
                                                                    <span class="btn-group pull-right">
                                                                        <button class="btn btn-transparent removeseriescoloritem" onClick={that.removeseriescoloritem} type="button" title={lang["storyboard.remove"]}>
                                                                            <i class="fa fa-trash"></i>
                                                                        </button>
                                                                    </span>
                                                                    <input type="text" class="form-control input-sm colorpicker-component" value={i.color} />
                                                                </li>
                                                            })
                                                        ) : null}</ul>
                                                </div>
                                                {/* <!-- /Selected Series --> */}

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer boc-modal-hf-padding">
                            <button type="button" class="btn btn-bird" onClick={that.closeModal} data-dismiss="modal"><i class="fa fa-times"></i> {lang["storyboard.cancelbtn"]}</button>
                            <button type="button" class="btn btn-bird applychartseriescolors" onClick={that.applychartseriescolors} data-num={that.chartnumber} tab-id="0" tab-name=""><i class="fa fa-check"></i> {lang["storyboard.applybtn"]}</button>
                        </div>
                    </div>
                    {/* <!-- /.modal-content --> */}
                </div>
                {/* <!-- /.modal-dialog --> */}
                {/* <!-- /.Chart Series Colors Modal -->); */}
            </div>);
    }
}