import React from "react";
import _ from 'underscore';
import { lang } from "../../js/helpers/utils/languages/language";
const $ =window.$;
export default class RolesTemplate extends React.Component{
  constructor(props){
super(props);
this.state={};
  }
  hideleft = ()=> {
    $('.leftarea').addClass('hide');

    $('.hideleft').removeClass('hide');
    $('.showleft').addClass('hide');

    $('.leftshow').removeClass('hide');
    $('.lefthide').addClass('hide');

    $('.w2ui-resizer').resize(); 
    $(".boc-admin-main .notifications-table-container table").bootstrapTable('resetView');
  }
  leftshow  = ()=>  {
    $('.leftarea').removeClass('hide');

    $('.hideleft').removeClass('hide');
    $('.showleft').addClass('hide');
    
    $('.leftshow').addClass('hide');
    $('.lefthide').removeClass('hide');
    
    $('.w2ui-resizer').resize();
    $(".boc-admin-main .notifications-table-container table").bootstrapTable('resetView');
  }
  lefthide = ()=> {
    $('#icontent').addClass('hide');
    $('.leftarea').addClass('shownsingle');
    $('.editrole').addClass('disabled');
    $('.editgroup').addClass('disabled');
    $('.editaccount3').addClass('disabled');
    $('.editschedule').addClass('disabled');
    $('.editmail').addClass('disabled');
    $('.viewhistory').addClass('disabled');
    
    $('.hideleft').addClass('hide');
    $('.showleft').removeClass('hide');
    
    $('.leftshow').addClass('hide');
    $('.lefthide').removeClass('hide');
    
    $('.w2ui-resizer').resize();
    $(".boc-admin-main .notifications-table-container table").bootstrapTable('resetView');
  }
  showleft = ()=>  {
    $('#icontent').removeClass('hide');
    $('.leftarea').removeClass('shownsingle');
    $('.editrole').removeClass('disabled');
    $('.editgroup').removeClass('disabled');
    $('.editaccount3').removeClass('disabled');
    $('.editschedule').removeClass('disabled');
    $('.editmail').removeClass('disabled');
    $('.viewhistory').removeClass('disabled');
    
    $('.hideleft').removeClass('hide');
    $('.showleft').addClass('hide');
    
    $('.leftshow').addClass('hide');
    $('.lefthide').removeClass('hide');
    
    $('.w2ui-resizer').resize();
    $(".boc-admin-main .notifications-table-container table").bootstrapTable('resetView');
  }
  render(){
return(
<div class="container-fluid">
{/* <div class="alert alert-success roledeletesuccess" style="display: none" role="alert">Role Deleted Successfully</div>
	<div class="alert alert-danger roledeleteerror" style="display: none" role="alert">Error Occurred While Deleting Role.</div> */}
<div class="row">
	<h1 class="boc-main-title"><i class="fa fa-user-secret fa-admin-sm"></i>{lang["admin.roles.title"]}</h1>
	<div class="container-fluid">
		<div class="row in-back">
			<div class="row-same-height-inner">
            <div class="col-xs-4 col-xs-height in-panes leftarea">
            <div class="btn btn-transparent hideleft toggleleft" onClick={this.hideleft}><i class="fa fa-grey fa-angle-left"></i></div>
					<div class="btn btn-transparent showleft toggleleft hide" onClick={this.showleft}><i class="fa fa-grey fa-angle-left"></i></div>
          
                    {this.props.features[0].features.map((feature)=>{  
                    
                    if(feature.featureName=='add_role' && feature.allow==true){
                    return	<div class="pull-right marg-bot-10">
                            <button class="btn btn-bird btn-sm btn-highlight addrole pull-right" onClick={this.props.newrolesView} id="addrole"><i class="fa fa-plus marg-righ-10"></i>{lang["admin.roles.rolebtn"]}</button>
                        </div>
                    }
                })
            }
            	<div class="listcontainer col-xs-12">
						<div class="notifications-table-container">
            <div class="pull-left search marg-top-10">
					<input class="form-control searchrolestable" type="text" onKeyUp={this.props.searchKeyup} placeholder={lang["admin.roles.search"]} value={this.props.search} />
				</div> 
                        <div id = "rolestable"></div>
						</div>

					</div>
					<div class="clear" class="icontent"></div>
                </div>
                <div class="col-xs-6 col-xs-height in-panes" id="icontent">
					<div class="btn btn-transparent leftshow toggleright hide" onClick={this.leftshow}><i class="fa fa-grey fa-angle-right"></i></div>
					<div class="btn btn-transparent lefthide toggleright" onClick={this.lefthide}><i class="fa fa-grey fa-angle-right"></i></div>
					<div class="inneraccountscontentarea newroleform"></div>
                   
				</div>
            </div>
            </div>
            </div>
            </div>     
            </div>

)

}
}