import React from "react";
import { lang } from "../js/helpers/utils/languages/language";
import PubSub from 'pubsub-js';

export default class AttrModalMeasuresViewTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        var that = this.props;
        return (
            //    <!-- attrmodalmeasuresview.js -->
            <li key={that.dynamicdiv} className="boc-list-item singleliitems attributesmeasures" data-name={that.columnName} table-name={that.tableName} data-type={that.dataType} title={that.columnDisplayName}>
                <span className="itemtype">
                    {(that.dataType === 'number') ? (
                        <>123</>
                    ) : (that.dataType === 'date' || that.dataType === 'datetime' || that.dataType === 'month' || that.dataType === 'day') ? (
                        <i className="fa fa-calendar"></i>
                    ) : (
                                <> {lang["storyboard.abc"]}</>
                            )}
                </span>
                <span className="itemname">{that.columnDisplayName}</span>
                <span type="button" class="boclabel boclabel-right-5 opacity-effect previewcolumndata" data-displayname={that.columnDisplayName} data-type={that.dataType} data-name={that.columnName} title={lang["storyboard.previewdata"]} onClick={(e)=>{this.PreviewData(e,that.columnName,that.columnDisplayName,that.dataType)}}>
	                <i class="fa fa-table"></i>
	            </span>
                {/* <span type="button" className="boclabel boclabel-right-5 opacity-effect dropdown-toggle" id="dropdownaggregation" aria-expanded="false" data-toggle="dropdown" style={{display:"none !important"}}>{that.aggregation}</span> */}
                {that.allfeatures.map((featuresModule, index) => {
                    if (featuresModule.moduleName == 'my_report') {
                        return featuresModule.features.map((feature, index) => {
                            if (feature.featureName == 'viewreport_maximize_rename_column' && feature.allow == true) {
                                if (that.isCustomField) {
                                    if (that.isDirect == 0) {
                                        return <span key={index} type="button" data-name={that.columnDisplayName} title={that.columnDisplayName} className="opacity-effect" data-edit={lang["storyboard.edit"]}></span>
                                    }
                                } else {
                                    if (that.isDirect == 0) {
                                        return <span key={index} type="button" data-name={that.columnDisplayName} title={that.columnDisplayName} className="opacity-effect editmeasures" data-edit={lang["storyboard.edit"]}>
                                            <i className="fa fa-pencil"></i>
                                        </span>
                                    }
                                }
                            }
                        })
                    }
                })}
                <ul aria-labelledby="dropdownaggregation" role="menu" className="dropdown-menu dropdown-inline ">
                    {(that.dataType != 'string' && that.dataType != 'date' && that.dataType != 'month' && that.dataType != 'day') ? (
                        <>
                            <li className="aggregation-li-item aggsum" title={lang["storyboard.sum"]}>{lang["storyboard.sum"]}</li>
                            <li className="aggregation-li-item aggmin" title={lang["storyboard.minimum"]}>{lang["storyboard.minimum"]}</li>
                            <li className="aggregation-li-item aggmax" title={lang["storyboard.max"]}>{lang["storyboard.max"]}</li>
                            <li className="aggregation-li-item aggavg" title={lang["storyboard.avg"]}>{lang["storyboard.avg"]}</li>
                            {/* <!-- <li className="aggregation-li-item aggrunningsum" title="Running Sum">Running Sum</li>
                    <li className="aggregation-li-item aggrunningaverage" title="Running Average">Running Average</li> --> */}
                            {/* <!-- <li className="aggregation-li-item aggvariance" title="Variance">Variance</li> --> */}
                            <li className="aggregation-li-item aggstandarddeviation" title={lang["storyboard.standard"]}>{lang["storyboard.standard"]}</li>
                            {/* <!-- <li className="aggregation-li-item aggsumofsquares" title="Sum of Squares">Sum of Squares</li> --> */}
                            <li className="aggregation-li-item aggpercentile" title={lang["storyboard.percentile"]}>{lang["storyboard.percentile"]}
                       <span className="opacity-effect" ><input type="number" className="percentilebox" placeholder="1" min="25" max="100" defaultValue="25" /></span>
                            </li>
                            <li className="aggregation-li-item aggmedian" title={lang["storyboard.median"]}>{lang["storyboard.median"]}</li>
                        </>
                    ) : null}
                    <li className="aggregation-li-item aggcountdistinct" title={lang["storyboard.countdis"]}>{lang["storyboard.countdistinct"]}</li>
                    <li className="aggregation-li-item aggcount" title={lang["storyboard.count"]}>{lang["storyboard.countall"]}</li>
                </ul>
            </li>
        );
    }
    PreviewData(e,name,dispalyname,datatype){
        var appObj={};
        appObj["name"]=name;
        appObj["displayname"]=dispalyname;
        appObj["datatype"]=datatype;
        appObj["target"]=e;

        PubSub.publish("previewcolumndata",appObj);
    }
}